(function () {
    'use strict';

    angular
        .module('elogbooks')
        .provider('$resolves', $ResolvesProvider)
        .run(['$rootScope', '$resolves', function ($rootScope, $resolves) {
            $rootScope.$resolves = $resolves;
        }]);

    $ResolvesProvider.$inject = [];

    function $ResolvesProvider () {

        this.$get = $get;
        $get.$inject = ['$state'];

        function $get ($state) {
            return function () {
                return $state.$current.locals.globals;
            }
        }
    }
})();
