(function () {
    'use strict';

    angular
        .module('elogbooks.common.operatives')
        .controller('OperativeAddCreateModalController', OperativeAddCreateModalController);

    OperativeAddCreateModalController.$inject = ['$state', '$uibModalInstance', 'messenger', 'operativeCollectionResponse', 'apiClient', 'operativeUserCollection', 'serviceProviderCollectionResponse'];

    function OperativeAddCreateModalController($state, $uibModalInstance, messenger, operativeCollectionResponse, apiClient, operativeUserCollection, serviceProviderCollectionResponse) {
        var vm = this;
        vm.copy = false;
        vm.operatives = operativeCollectionResponse;
        vm.confirm = confirm;
        vm.close = close;
        vm.submit = submit;
        vm.copyOperativeSelect = copyOperativeSelect;
        vm.operativeCollection = operativeUserCollection;

        vm.serviceProviderSelect = {
            response: serviceProviderCollectionResponse,
            responseKeyPath: 'serviceproviders',
            required: true
        };

        vm.operativeSelect = {
            response: operativeCollectionResponse,
            responseKeyPath: 'operatives',
            itemHrefPath: '_links.user.href',
            itemValuePath: '_links.user.name'
        };

        function copyOperativeSelect() {
            vm.serviceProviderSelect.required = !vm.copy;
            vm.serviceProviderSelect.disabled = vm.copy;
            vm.serviceProviderSelect.selected = null;
        }

        function submit() {
            if (vm.operativeCollection.getLink('create-operative')) {
                if (!vm.copy && vm.serviceProviderSelect.selected) {
                    vm.data._links = { serviceProvider : { href: vm.serviceProviderSelect.selected.href } };
                } else {
                    vm.data._links = null;
                }

                if (vm.copy && vm.operativeSelect.selected) {
                    if (!vm.data._links) {
                        vm.data._links = {};
                    }
                    vm.data._links.copyFromUser = { href: vm.operativeSelect.selected.href };
                }

                apiClient.create(vm.operativeCollection.getLink('create-operative'), vm.data).then(function (response) {
                    if (response) {
                        messenger.success('OPERATIVE_CREATED');
                        confirm();
                    }
                });
            }
        }

        function close() {
            $uibModalInstance.dismiss();
        }

        function confirm() {
            $uibModalInstance.close();
        }
    }
})();
