(function () {
    'use strict';

    angular
        .module('elogbooks.user.preferences')
        .config(registerRoutes)
        .config(registerGeneralRoutes)
        .config(registerWebhooksRoutes)
    ;

    function registerRoutes($stateProvider) {
        $stateProvider
        .state('dashboard.user.preferences', {
            abstract: true,
            templateUrl: '/shared-templates/view.html',
            ncyBreadcrumb: {
                skip: true
            }
        })
        .state('dashboard.user.preferences.abstract', {
            url: '/preferences',
            abstract: true,
            parent: 'dashboard.user.preferences',
            views: {
                '@dashboard.user.preferences': {
                    templateUrl: '/modules/user/preferences/preferences.html',
                    controller: 'UserPreferencesController',
                    controllerAs: 'vm'
                }
            },
            ncyBreadcrumb: {
                label: '{{ ::"USER_PREFERENCES" | translate }}'
            }
        })
    }

    function registerGeneralRoutes($stateProvider) {
        $stateProvider
            .state('dashboard.user.preferences.abstract.general', {
                url: '/general',
                parent: 'dashboard.user.preferences.abstract',
                resolve: {
                    userResponse: function (user) {
                        return user;
                    },
                    userPreferencesCollection: function (userManager) {
                        return userManager.getPreferences(true);
                    },
                    ssoResponse: function (oktaService, user) {
                        return oktaService.isUserlinkedToOkta(user.getLink('sso-user'));
                    }
                },
                views: {
                    '@dashboard.user.preferences.abstract': {
                        templateUrl: '/modules/user/preferences/general/preferences-general.html',
                        controller: 'UserPreferencesGeneralController',
                        controllerAs: 'vm'
                    }
                },
                ncyBreadcrumb: {
                    label: '{{ ::"GENERAL" | translate }}'
                }
            })
            .state('dashboard.user.preferences.abstract.general.edit', {
                url: '/edit',
                parent: 'dashboard.user.preferences.abstract.general',
                resolve: {
                    userResponse: function (user) {
                        return user;
                    },
                    userPreferencesCollection: function (userManager) {
                        return userManager.getPreferences(true);
                    }
                },
                views: {
                    '@dashboard.user.preferences.abstract': {
                        templateUrl: '/modules/user/preferences/general/edit/preferences-general-edit.html',
                        controller: 'UserPreferencesGeneralEditController',
                        controllerAs: 'vm'
                    }
                },
                ncyBreadcrumb: {
                    label: '{{ ::"USER_PREFERENCES_EDIT" | translate }}'
                }
            });
    }

    function registerWebhooksRoutes($stateProvider) {
        $stateProvider
            .state('dashboard.user.preferences.abstract.webhooks', {
                url: '/webhooks?webhookPage&webhookLimit&webhookOrder&webhookName',
                parent: 'dashboard.user.preferences.abstract',
                views: {
                    '@dashboard.user.preferences.abstract': {
                        templateUrl: '/modules/common/webhooks/list/webhooks-list.html',
                        controller: 'WebhooksListController',
                        controllerAs: 'vm'
                    }
                },
                resolve: {
                    webhooksCollection: function(apiClient, user, $stateParams) {
                        return apiClient.get(user.getLink('webhooks'), angular.extend({}, $stateParams, {
                            page: $stateParams.webhookPage,
                            limit: $stateParams.webhookLimit,
                            order: $stateParams.webhookOrder,
                            name: $stateParams.webhookName
                        }));
                    },
                    webhookCreateType: function() {
                        return 'create-user-webhook';
                    }
                },
                ncyBreadcrumb: {
                    label: '{{ ::"WEBHOOKS" | translate }}'
                },
                params: {
                    webhookPage: '1',
                    webhookLimit: '30',
                    webhookOrder: '-id',
                    webhookName: null
                }
            })
            .state('dashboard.user.preferences.abstract.webhooks.add', {
                url: '/add',
                parent: 'dashboard.user.preferences.abstract.webhooks',
                views: {
                    '@dashboard.user.preferences.abstract': {
                        templateUrl: '/modules/common/webhooks/addedit/webhooks-addedit.html',
                        controller: 'WebhooksAddEditController',
                        controllerAs: 'vm'
                    }
                },
                resolve: {
                    webhookResponse: function() {
                        return {};
                    },
                    webhookCreateLink: function(webhooksCollection) {
                        return webhooksCollection.getLink('create-user-webhook');
                    },
                    config: function() {
                        return {
                            noBorder: false
                        };
                    }
                },
                ncyBreadcrumb: {
                    label: '{{ ::"ADD" | translate }}'
                }
            })
            .state('dashboard.user.preferences.abstract.webhooks.details', {
                url: '/details/{webhookResource}',
                abstract: true,
                parent: 'dashboard.user.preferences.abstract.webhooks',
                resolve: {
                    webhookResponse: function(apiClient, $stateParams) {
                        return apiClient.get($stateParams.webhookResource.decode());
                    }
                },
                ncyBreadcrumb: {
                    label: '{{ ::$resolves().webhookResponse.url }}'
                }
            })
            .state('dashboard.user.preferences.abstract.webhooks.details.webhooksinfo', {
                url: '/info',
                parent: 'dashboard.user.preferences.abstract.webhooks.details',
                views: {
                    '@dashboard.user.preferences.abstract': {
                        templateUrl: '/modules/common/webhooks/details/info/webhooks-info.html',
                        controller: 'WebhooksDetailsInfoController',
                        controllerAs: 'vm'
                    }
                },
                resolve: {
                    config: function() {
                        return {
                            noBorder: false
                        };
                    }
                },
                ncyBreadcrumb: {
                    label: '{{ ::"INFO" | translate }}'
                }
            })
            .state('dashboard.user.preferences.abstract.webhooks.details.webhooksinfo.edit', {
                url: '/edit',
                parent: 'dashboard.user.preferences.abstract.webhooks.details.webhooksinfo',
                views: {
                    '@dashboard.user.preferences.abstract': {
                        templateUrl: '/modules/common/webhooks/addedit/webhooks-addedit.html',
                        controller: 'WebhooksAddEditController',
                        controllerAs: 'vm'
                    }
                },
                resolve: {
                    webhookCreateLink: function(webhooksCollection) {
                        return null;
                    },

                    config: function() {
                        return {
                            noBorder: false
                        };
                    }
                },
                ncyBreadcrumb: {
                    label: '{{ ::"EDIT" | translate }}'
                }
            })
    }
})();
