(function () {
    'use strict';

    /**
     * @desc frequency units directive to allow user to select a unit value for frequencies
     * @example <frequency-units-select data-ng-model="vm.serviceprovider"></frequency-units-select>
     */
    angular
        .module('elogbooksDirectives')
        .directive('frequencyUnitsSelect', frequencyUnitsSelect);

    function frequencyUnitsSelect($translate) {
        var directive = {
            link: link,
            templateUrl: '/modules/directives/form-elements/frequency-unit-select/frequency-unit-select.html',
            restrict: 'E',
            scope: {
                selectedFrequency: '=ngModel',
                required: '=ngRequired',
                options: '='
            },
            require: ['ngModel'],
        };
        return directive;

        function link(scope, element, attrs) {
            if (scope.selectedFrequency) {
                scope.selectedFrequency = scope.selectedFrequency.toLowerCase();
            }

            scope.frequencyUnitOptions = {};

            if(typeof scope.options !== "undefined") {
                angular.forEach(scope.options, function(value, key) {
                    scope.frequencyUnitOptions[key] = value;
                })

            } else {
                scope.frequencyUnitOptions = {
                    daily: {value: "daily", title: $translate.instant("DAILY")},
                    weekly: {value: "weekly", title: $translate.instant("WEEKLY")},
                    monthly: {value: "monthly", title: $translate.instant("MONTHLY")},
                    yearly: {value: "yearly", title: $translate.instant("YEARLY")}
                };
            }
        }
    }

})();
