(function () {
    'use strict';

    angular
        .module('elogbooksControllers')
        .controller('SiteMembershipAddModalController', SiteMembershipAddModalController);

    SiteMembershipAddModalController.$inject = ['$uibModalInstance', '$state', 'siteMemebershipCollectionResponse', 'linkParams', 'apiClient', 'operativeResponse', 'messenger'];

    function SiteMembershipAddModalController($uibModalInstance, $state, siteMemebershipCollectionResponse, linkParams, apiClient, operativeResponse, messenger) {
        var vm = this;
        vm.confirm = confirm;
        vm.close = close;
        vm.submit = submit;

        vm.data = {
            _links: {}
        };

        vm.siteSelect = {
            response: siteMemebershipCollectionResponse,
            responseKeyPath: 'memberships',
            linkParameters: linkParams,
            itemHrefPath: '_links.site.href',
            itemValuePath: '_links.site.title',
            required: true
        };

        function submit() {
            vm.data._links = {
                user: { href: operativeResponse.getLink('self') }
            };

            if (vm.siteSelect.selected.object.getLink('create-membership')) {
                apiClient.create(vm.siteSelect.selected.object.getLink('create-membership'), vm.data).then(function (response) {
                    if (response) {
                        close();
                        $state.go('.', {}, { reload: true }).then(function() {
                            messenger.success('SITE_MEMBERSHIP_CREATED');
                        });
                    }
                });
            }
        }
        
        function close() {
            $uibModalInstance.dismiss(vm.instance);
        }

        function confirm() {
            $uibModalInstance.close(vm.instance);
        }
    }
})();
