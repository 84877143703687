(function () {
    'use strict';

    angular
        .module('elogbooks.admin.regions')
        .config(registerRoutes)
        .config(registerRoutesList)
        .config(registerRoutesAdd)
        .config(registerRoutesDetails)
        .config(registerRoutesEdit);

    function registerRoutes ($stateProvider) {
        $stateProvider
            .state('dashboard.admin.regions', {
                abstract: true,
                templateUrl: 'shared-templates/view.html',
                ncyBreadcrumb: {
                    skip: true
                },
                data: {
                    permissions: ['user_permission_view_regions', 'user_permission_client_admin_manage_regions']
                }
            });
    }

    function registerRoutesList ($stateProvider) {
        $stateProvider
            .state('dashboard.admin.regions.list', {
                url: '/regions?page&limit&order&name&id&active',
                parent: 'dashboard.admin.regions',
                data: {
                    permissions: ['user_permission_view_regions', 'user_permission_client_admin_manage_regions']
                },
                views: {
                    '@dashboard.admin.regions': {
                        templateUrl: '/modules/admin/regions/region-list.html',
                        controller: 'RegionsController',
                        controllerAs: 'vm'
                    }
                },
                resolve: {
                    regionCollectionResponse: function ($stateParams, apiClient, rootResourceResponse) {
                        return apiClient.get(rootResourceResponse.getLink('regions'), $stateParams).then(function (response) {
                            return response || apiClient.noResourceFound();
                        });
                    }
                },
                params: {
                    page: '1',
                    limit: '30',
                    order: 'id',
                    name: null,
                    id: null,
                    active: { value: null }
                },
                ncyBreadcrumb: {
                    label: '{{ ::"REGIONS" | translate }}'
                }
            });
    }

    function registerRoutesAdd ($stateProvider) {
        $stateProvider
            .state('dashboard.admin.regions.list.add', {
                url: '/add',
                parent: 'dashboard.admin.regions.list',
                data: {
                    permissions: ['user_permission_manage_regions', 'user_permission_client_admin_manage_regions']
                },
                views: {
                    "@dashboard.admin.regions": {
                        templateUrl: '/modules/admin/regions/add-edit/region-form.html',
                        controller: 'RegionsAddEditController',
                        controllerAs: 'vm'
                    }
                },
                resolve: {
                    regionResponse: function () {
                        return {};
                    }
                },
                ncyBreadcrumb: {
                    label: '{{ ::"ADD" | translate }}'
                }
            });
    }

    function registerRoutesDetails ($stateProvider) {
        $stateProvider
            .state('dashboard.admin.regions.list.details', {
                url: '/details/{resource}',
                abstract: true,
                parent: 'dashboard.admin.regions.list',
                data: {
                    permissions: ['user_permission_view_regions', 'user_permission_client_admin_manage_regions']
                },
                views: {
                    "@dashboard.admin.regions": {
                        templateUrl: '/modules/admin/regions/details/region-details.html',
                        controller: 'RegionDetailsController',
                        controllerAs: 'vm'
                    }
                },
                resolve: {
                    regionResponse: function (apiClient, $stateParams) {
                        return apiClient.get($stateParams.resource.decode()).then(function (response) {
                            return response || apiClient.noResourceFound();
                        });
                    }
                },
                ncyBreadcrumb: {
                    label: '{{ ::$resolves().regionResponse.name }}'
                }
            })
            .state('dashboard.admin.regions.list.details.info', {
                url: '/info',
                parent: 'dashboard.admin.regions.list.details',
                data: {
                    permissions: ['user_permission_view_regions', 'user_permission_client_admin_manage_regions']
                },
                views: {
                    "@dashboard.admin.regions.list.details": {
                        templateUrl: '/modules/admin/regions/details/info/region-info.html',
                        controller: 'RegionInfoController',
                        controllerAs: 'vm'
                    }
                },
                ncyBreadcrumb: {
                    label: '{{ ::"INFO" | translate }}'
                }
            })
            .state('dashboard.admin.regions.list.details.sites', {
                url: '/sites?sitePage&siteLimit&siteOrder&siteId&siteName&siteStatus',
                parent: 'dashboard.admin.regions.list.details',
                data: {
                    permissions: ['user_permission_view_regions', 'user_permission_client_admin_manage_regions']
                },
                views: {
                    "@dashboard.admin.regions.list.details": {
                        templateUrl: '/modules/admin/regions/details/sites/region-sites.html',
                        controller: 'RegionSitesController',
                        controllerAs: 'vm',
                    }
                },
                resolve: {
                    selectedSiteCollectionResponse: function ($stateParams, apiClient, regionResponse) {
                        return apiClient.get(regionResponse.getLink('sites'), angular.extend({}, $stateParams, {
                            page: $stateParams.sitePage,
                            limit: $stateParams.siteLimit,
                            order: $stateParams.siteOrder,
                            id: $stateParams.siteId,
                            name: $stateParams.siteName,
                            status: $stateParams.siteStatus
                        }));
                    }
                },
                params: {
                    sitePage: '1',
                    siteLimit: '30',
                    siteOrder: 'id',
                    siteId: null,
                    siteName: null,
                    siteStatus: null
                },
                ncyBreadcrumb: {
                    label: '{{ ::"SITES" | translate }}'
                }
            })
            .state('dashboard.admin.regions.list.details.sites.edit', {
                url: '/edit',
                parent: 'dashboard.admin.regions.list.details.sites',
                data: {
                    permissions: ['user_permission_manage_regions', 'user_permission_client_admin_manage_regions']
                },
                views: {
                    '@dashboard.admin.regions.list.details': {
                        templateUrl: '/modules/admin/regions/details/sites/region-sites-form.html',
                        controller: 'RegionsSitesEditController',
                        controllerAs: 'vm'
                    }
                },
                resolve: {
                    siteCollectionResponse: function ($stateParams, rootResourceResponse, apiClient, user, userManager) {
                        var params = angular.extend({}, $stateParams, {'datagroup': 'search', 'page': 1});

                        if (userManager.hasPermission('user_permission_manage_sites')) {
                            return apiClient.getPage(rootResourceResponse.getLink('sites'), params);
                        }

                        return apiClient.getPage(user.getLink('sites'), params);
                    },
                    selectedSiteCollectionResponse: function(apiClient, regionResponse) {
                        return apiClient.get(regionResponse.getLink('sites'), { limit: 999999 }) || null;
                    },
                },
                ncyBreadcrumb: {
                    label: '{{ ::"EDIT" | translate }}'
                }
            });
    }

    function registerRoutesEdit ($stateProvider) {
        $stateProvider
            .state('dashboard.admin.regions.list.details.info.edit', {
                url: '/edit',
                parent: 'dashboard.admin.regions.list.details.info',
                data: {
                    permissions: ['user_permission_manage_regions', 'user_permission_client_admin_manage_regions']
                },
                views: {
                    "@dashboard.admin.regions.list.details": {
                        templateUrl: '/modules/admin/regions/add-edit/region-form.html',
                        controller: 'RegionsAddEditController',
                        controllerAs: 'vm'
                    }
                },
                ncyBreadcrumb: {
                    label: '{{ ::"EDIT" | translate }}'
                }
            });
    }
})();
