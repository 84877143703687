(function() {
    'use strict';

    angular
        .module('elogbooks.common.dashboard-layout')
        .controller('QuickSiteActionModalController', QuickSiteActionModalController);

    function QuickSiteActionModalController($state, $uibModalInstance, base64, site) {
        var vm = this;

        vm.selectedSite = site;
        vm.canViewQuotes = !!vm.selectedSite.getLink('quoterequests');
        vm.canCreateQuotes = !!vm.selectedSite.getLink('create-quoterequest');
        vm.canViewJobs = !!vm.selectedSite.getLink('jobs');
        vm.canViewActions = !!vm.selectedSite.getLink('actions');
        vm.canCreateActions = !!vm.selectedSite.getLink('create-actions');

        if (vm.selectedSite.getLink('create-reactive-job')
            || vm.selectedSite.getLink('create-reactive-job-helpdesk')
            || vm.selectedSite.getLink('create-reactive-tenant-job')
        ) {
            vm.canCreateJobs = true;
        } else {
            vm.canCreateJobs = false;
        }

        vm.close = close;
        vm.view = view;

        function view() {
            $uibModalInstance.dismiss();
            $state.go('dashboard.user.sites.list.details.info', { resource: base64.encode(vm.selectedSite.getLink('self')) });
        }

        function close() {
            $uibModalInstance.dismiss('cancel');
        }
    }
})();
