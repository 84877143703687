(function() {
    'use strict';

    angular
        .module('elogbooks.admin.core-clients')
        .controller('CoreClientsMarkupController', CoreClientsMarkupController);

    CoreClientsMarkupController.$inject = [
        'coreClientMarkupCollectionResponse',
        '$stateParams',
        '$state'
    ];
    
    function CoreClientsMarkupController(
        coreClientMarkupCollectionResponse,
        $stateParams,
        $state
    ) {
        var vm = this;
        vm.coreClientMarkupCollectionResponse = coreClientMarkupCollectionResponse;
        vm.coreClientsMarkup = coreClientMarkupCollectionResponse.coreclientsmarkup;
        vm.order = orderAction;

        function orderAction(key) {
            $stateParams.markupOrder = key;
            $state.go('.', angular.extend({}, $stateParams), { reload: $state.current });
        }        
    }
})();
