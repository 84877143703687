/**
 * Client Billing list controller
 */
(function () {
    'use strict';

    angular
        .module('elogbooks.admin.client-billing')
        .controller('ClientBillingTabsController', ClientBillingTabsController);

    function ClientBillingTabsController(clientBillingResponse) {
        var vm = this;

        vm.clientBilling = clientBillingResponse;
    }
})();
