(function() {
    'use strict';

    angular
        .module('elogbooks.user.finance.purchase-orders')
        .controller('PurchaseOrderDetailsEventFeedController', PurchaseOrderDetailsEventFeedController);

    PurchaseOrderDetailsEventFeedController.$inject = [
        'eventCollectionResponse', 'messageCollectionResponse'
    ];

    function PurchaseOrderDetailsEventFeedController(eventCollectionResponse, messageCollectionResponse) {
        var vm = this;
        vm.feed = [];
        vm.eventCollectionResponse = eventCollectionResponse;
        vm.messageCollectionResponse = messageCollectionResponse;
    }
})();
