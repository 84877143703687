/**
 * User Add Survey Request Controller
 */
(function() {
    'use strict';

    angular
        .module('elogbooks.common.survey')
        .controller('SurveyRequestAddController', SurveyRequestAddController);

    SurveyRequestAddController.$inject = ['$scope', 'lodash', '$state', 'apiClient', 'messenger', 'userManager', 'surveyRequestService', 'surveyRequestCollectionResponse', 'siteResponse', 'siteCollectionResponse', 'partnershipsResponse', 'selectedSiteResponse', 'rootResourceResponse'];

    function SurveyRequestAddController($scope, lodash, $state, apiClient, messenger, userManager, surveyRequestService, surveyRequestCollectionResponse, siteResponse, siteCollectionResponse, partnershipsResponse, selectedSiteResponse, rootResourceResponse) {
        var vm = this;
        vm.surveyRequest = {'reportingYear' : new Date().getFullYear()};
        vm.create = create;
        vm.setForm = setForm;
        vm.isFM = userManager.hasPermission('site_permission_create_surveys');
        vm.surveyRequest.spEditable = true;

        vm.noBorder = (($state.current.name == "dashboard.user.sites.list.details.surveys.active.add") ? true : false);

        var selectedSite = null;
        var switchSiteResponse;

        if (!lodash.isEmpty(siteResponse)) {
            selectedSite = {
                href: siteResponse.getLink('self'),
                title: siteResponse.name,
                object: siteResponse
            };
        } else if (!lodash.isEmpty(selectedSiteResponse)) {
            selectedSite = {
                href: selectedSiteResponse.getLink('self'),
                title: selectedSiteResponse.name,
                object: selectedSiteResponse
            };
        }

        if(lodash.isEmpty(siteResponse)){
            siteResponse = null;
        }

        switchSiteResponse = lodash.isEmpty(selectedSiteResponse) ? siteResponse : selectedSiteResponse;

        vm.siteSelect = {
            response: siteCollectionResponse,
            link: siteCollectionResponse.getLink('self'),
            responseKeyPath: 'sites',
            itemHrefPath: '_links.self.href',
            itemValuePath: 'name',
            required: true,
            onSelect: changeSite,
            onRemove: removeSite,
            disabled: switchSiteResponse,
            selected: selectedSite,
            populateDisabled: true
        };

        vm.spSelect = {
            response: partnershipsResponse,
            responseKeyPath: 'partnerships',
            itemHrefPath: '_links.serviceprovider.href',
            itemValuePath: '_links.serviceprovider.title',
            onSelect: changeSP,
            onRemove: removeSP,
            required: true,
            disabled: lodash.isEmpty(siteResponse) && lodash.isEmpty(selectedSiteResponse)
        };

        vm.surveySelect = {
            responseKeyPath: 'surveys',
            itemHrefPath: '_links.self.href',
            itemValuePath: 'name',
            onSelect: function() {},
            onRemove: function() {},
            required: true,
            disabled: true
        };

        function changeSite() {
            apiClient.get(vm.siteSelect.selected.object.getLink('self')).then(function(response){
                vm.siteSelect.selected.object = response;
                vm.spSelect.disabled = true;
                vm.spSelect.selected = null;
                vm.surveySelect.disabled = true;
                vm.surveySelect.selected = null;
                apiClient.get(response.getLink('partnerships'), { serviceProvidersStatus: 'active' }).then(function(response){
                    vm.spSelect.response = response;
                    vm.spSelect.link = response.getLink('self');
                    vm.spSelect.disabled = false;
                    vm.spSelect.required = true;
                    vm.surveySelect.required = true;
                });
            });
        }

        function removeSite() {
            vm.spSelect.selected = null;
            vm.spSelect.disabled = true;
            vm.surveySelect.selected = null;
            vm.surveySelect.disabled = true;
        }

        function changeSP() {
            var params = {
                status: 'active',
                site: siteResponse ? siteResponse.id : vm.siteSelect.selected ? vm.siteSelect.selected.object.id : '',
                partnership : vm.spSelect.selected.object.getLinkAttribute('self', 'id'),
                serviceProvider: vm.spSelect.selected.object.getLinkAttribute('serviceprovider', 'id')
            };

            vm.surveySelect.disabled = true;
            vm.surveySelect.selected = null;

            apiClient.get(rootResourceResponse.getLink('surveys'), params).then(function(response){
                vm.surveySelect.response = response;
                if  (response.count > 0) {
                    vm.surveySelect.disabled = false;
                    vm.surveySelect.required = true;
                }
            });
        }

        function removeSP() {
            vm.surveySelect.disabled = true;
            vm.surveySelect.selected = null;
        }

        function setForm(form) {
            vm.form = form;
        }

        function create() {
            if (vm.surveySelect.selected) {
                var data = angular.extend({
                    'serviceprovider': vm.spSelect.selected.object.getLink('serviceprovider'),
                    'survey': vm.surveySelect.selected.object.getLink('self')
                    }, vm.surveyRequest);

                surveyRequestService.add(data, vm.siteSelect.selected.object.getLink('surveyrequests')).then(function (response) {
                    $state.go('^.details', { surveyResource: response.getLink('self').encode() }, { reload: $state.get('^.^') }).then(function () {
                        messenger.success('SURVEY_CREATED');
                    });
                }, function(errorMessage) { // Survey Request creation failed
                    messenger.error('REQUEST_ERROR');
                });
            }
        }
    }
})();
