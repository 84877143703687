(function() {
    'use strict';

    angular
        .module('elogbooks')
        .config(Translations);

    function Translations($translateProvider) {
        $translateProvider.translations('nl', {
            MESSAGE: 'Bericht',
            MESSAGES: 'Berichten',

            MESSAGE_ADD: 'Voeg een nieuw bericht toe',

            MESSAGE_VISIBILITY: 'Zichtbaarheid',
            MESSAGE_VISIBILITY_PUBLIC: 'Iedereen',
            MESSAGE_VISIBILITY_HELPDESK: 'Servicedesk',

            MESSAGE_DIRECTION: 'Richting',
            MESSAGE_DIRECTION_INBOUND: 'Inkomend',
            MESSAGE_DIRECTION_OUTBOUND: 'Uitgaand',

            MESSAGE_WRITE_NEW: 'Schrijf een bericht',

            MESSAGE_SEND: 'Verzenden'
        });
    }
})();
