(function (){
    'use strict';

    angular
        .module('elogbooks.user.master-tasks')
        .controller('UserMasterTaskDetailsController', UserMasterTaskDetailsController);

    UserMasterTaskDetailsController.$inject = ['recurrenceRulesService', 'masterTaskResponse'];

    function UserMasterTaskDetailsController(recurrenceRulesService, masterTaskResponse) {
        var vm = this;
        vm.masterTask = masterTaskResponse;
        vm.getRuleFrequencyAsString = recurrenceRulesService.getRuleFrequencyAsString;
    }
})();
