(function () {
    'use strict';

    angular
        .module('elogbooks.user.reports')
        .controller('UserReportsPpmGapAnalysisDetailsController', UserReportsPpmGapAnalysisDetailsController);

    UserReportsPpmGapAnalysisDetailsController.$inject = ['$filter', '$state', '$stateParams', 'siteCollectionResponse', 'serviceProviderCollectionResponse', 'regionCollectionResponse', 'ppmGapAnalysisCollectionResponse', 'moment', '$scope', 'lodash', '$translate', 'paramConverterService'];

    function UserReportsPpmGapAnalysisDetailsController($filter, $state, $stateParams, siteCollectionResponse, serviceProviderCollectionResponse, regionCollectionResponse, ppmGapAnalysisCollectionResponse, moment, $scope, lodash, $translate, paramConverterService) {
        var vm = this;
        vm.ppmGapAnalysis = ppmGapAnalysisCollectionResponse.report ? ppmGapAnalysisCollectionResponse.report : null;
        vm.ppmGapAnalysisResponse = ppmGapAnalysisCollectionResponse;
        vm.stateParams = $stateParams;

        vm.search = search;
        vm.order = orderAction;

        var findSelectedValue = function(collection, id) {
            if (!id) { return null; }
            var selected = lodash.find(collection, function(item) {
               return item.id === parseInt(id);
            });

            return selected ? {
                value: selected.id,
                title: selected.name
            } : null;
        };

        vm.siteSelect =  {
            response: siteCollectionResponse,
            link : siteCollectionResponse ? siteCollectionResponse.getLink('self') : null,
            responseKeyPath: 'sites',
            itemValuePath: 'id',
            itemTitlePath: 'name',
            selected: findSelectedValue(siteCollectionResponse.sites, $stateParams.siteId)
        };

        vm.spSelect = {
            response: serviceProviderCollectionResponse,
            link : serviceProviderCollectionResponse ? serviceProviderCollectionResponse.getLink('self') : null,
            responseKeyPath: 'serviceproviders',
            itemValuePath: 'id',
            itemTitlePath: 'name'
        };

        vm.regionSelect = {
            response: regionCollectionResponse,
            link : regionCollectionResponse ? regionCollectionResponse.getLink('self') : null,
            responseKeyPath: 'regions',
            itemValuePath: 'id',
            itemTitlePath: 'name',
            selected: findSelectedValue(regionCollectionResponse.regions, $stateParams.siteRegionId)
        };

        vm.criteria = {
            serviceProviderId: {
                type: 'jsonselectwidget',
                title: 'SERVICE_PROVIDER',
                options: vm.spSelect,
                value: JSON.parse($stateParams.serviceProviderId)
            },
            siteId: {
                type: 'selectwidget',
                title: 'SITE',
                options: vm.siteSelect
            },
            siteRegionId: {
                type: 'selectwidget',
                title: 'REGION',
                options: vm.regionSelect
            }
        };

        function search(params) {
            var override = {
                page: 1
            };

            $state.go('.', angular.extend({}, params, override), { reload: $state.current });
        }

        function orderAction (key) {
            $stateParams.order = key;
            $state.go('.', angular.extend({}, $stateParams), { reload: $state.current });
        }

    }
})();


