(function () {
    'use strict';

    /**
     * @desc file upload directive.
     * @example <file-upload ng-model="vm.file"></file-upload>
     */
    angular
        .module('elogbooksDirectives')
        .directive('fileUpload', fileUpload);

    fileUpload.$inject = ['apiClient', '$timeout', 'lodash', 'messenger', 'disableSubmitService', 'validationService'];

    function fileUpload(apiClient, $timeout, lodash, messenger, disableSubmitService, validationService) {
        var uniqueId = 0;
        var directive = {
            link: link,
            restrict: 'A',
            scope: {
                model: '=ngModel',
                uploading: '=fileUploading',
                title: '=',
                returnObject: '=?',
                fileTypes: '@',
                fileName: '=',
                required: '=ngRequired'
            },
            require: ['ngModel']
        };
        return directive;

        function link(scope, element, attrs, ngModelController) {
            var resource = null,
                frame = null,
                frameName = uniqueId++,
                object = null,
                submitButton = scope.required == true ? disableSubmitService.disableSubmit() : null;
            element.addClass('file-upload-element');
            // Create form used for posting this file input
            var form = document.createElement('form');
            form.setAttribute('method', 'post');
            form.setAttribute('enctype', 'multipart/form-data');
            form.setAttribute('class', 'file-upload-form');
            if(attrs.id) {
                form.setAttribute('id', attrs.id);
            }
            document.body.appendChild(form);

            // Create the file input
            var input = document.createElement('input');
            input.setAttribute('name', 'file');
            input.setAttribute('type', 'file');
            input.setAttribute('class', 'file-upload-input');

            form.appendChild(input);

            angular.element(input).bind('change', upload);
            element.bind('click', select);

            function select() {
                input.click();
            }

            function upload(ce) {

                // image not selected do not process request
                if (typeof ce.target.files[0] === 'undefined') {
                    return;
                }

                if (!validationService.isFileNameValid(ce.target.files[0].name)) {
                    messenger.error('INVALID_FILE_NAME');
                    return;
                }

                element.addClass('file-upload-pending');
                scope.$apply(function () {
                    scope.uploading = true;
                });

                var type = null;
                if (ce.target.files && ce.target.files[0]) {
                    type = ce.target.files[0].type;
                }

                var fileName = input.value.split(/(\\|\/)/g).pop();
                var newFileName = null;
                if (scope.fileName) {
                    newFileName = scope.fileName + '.' + fileName.split('.').pop();
                }

                // Ask the API for a place to post the file
                var request = {
                    title: scope.title || fileName,
                    filename: newFileName || fileName,
                    type: type
                };

                if (!allowedFileTypes(scope.fileTypes, request.type)) {
                    messenger.error('INCORRECT_FILE_TYPE');
                    return;
                }

                if(attrs.min && attrs.max) {
                    var reader = new FileReader();
                    reader.onload = function() {
                        var img = new Image;
                        img.onload = function() {
                            var imgAspectRatio = img.width / img.height;
                            if (imgAspectRatio > attrs.max || imgAspectRatio < attrs.min ) {
                                messenger.error('INCORRECT_FILE_ASPECT_RATIO');
                                scope.$apply();
                            } else {
                                createFile(request)
                            }
                        };
                        img.src = reader.result;
                    };
                    reader.readAsDataURL(ce.target.files[0]);

                } else {
                    createFile(request)
                }
            }

            function createFile(request) {
                apiClient.create('/files', request).then(function (data) {
                    if (!data) {
                        return;
                    }

                    submitButton = disableSubmitService.disableSubmit();

                    // Remove any existing form children
                    while (form.firstChild) {
                        form.removeChild(form.firstChild);
                    }

                    // Add new children
                    for (var name in data.policy) {
                        if (!data.policy.hasOwnProperty(name)) {
                            continue;
                        }
                        var dinput = document.createElement('input');
                        dinput.type = 'hidden';
                        dinput.name = name;
                        dinput.value = data.policy[name];

                        form.appendChild(dinput);
                    }

                    resource = data._links.resource;
                    object = {
                        _links: {
                            file: data._links.file,
                            resource: data._links.resource
                        },
                        title: request.title,
                        filename: request.filename,
                        type: request.type
                    };

                    // Create a new frame for the upload if we haven't made one already
                    if (!frame) {
                        frame = document.createElement('iframe');
                        frame.setAttribute('id', frameName);
                        frame.setAttribute('name', frameName);
                        frame.setAttribute('style', 'display: none');
                        document.body.appendChild(frame);

                        // Make the form target this frame
                        form.setAttribute('target', frameName);
                    }

                    // Bind the load event for the frame so we know when the file has been uploaded
                    angular.element(frame).bind('load', loaded);

                    // Set action and make sure the file input is at the bottom
                    form.setAttribute('action', data.getLink('remote'));
                    form.appendChild(input);

                    // Submit the form
                    form.submit();
                });
            }

            function loaded() {
                scope.$apply(function () {
                    scope.model = scope.returnObject ? object : resource;
                    scope.uploading = false;
                    disableSubmitService.enableSubmit(submitButton);

                    element.removeClass('file-upload-pending');
                });
            }

            function allowedFileTypes(allowedTypes, fileType) {
                if (!allowedTypes) {
                    return true;
                }
                allowedTypes = allowedTypes.split(",");
                for (var type in allowedTypes) {

                    if (allowedTypes[type] === fileType) {
                        return true;
                    }
                }
                return false;
            }
        }
    }
})();
