(function () {
    'use strict';

    angular
        .module('elogbooks.admin.labour-types')
        .controller('LabourTypeAddEditController', LabourTypeAddEditController);

    LabourTypeAddEditController.$inject = [
        '$state',
        'apiClient',
        'confirmationModal',
        'labourTypeResponse',
        'requestDataFactory',
        'messenger',
        'labourTypesCollectionResponse',
        '$translate'
    ];

    function LabourTypeAddEditController (
        $state,
        apiClient,
        confirmationModal,
        labourTypeResponse,
        requestDataFactory,
        messenger,
        labourTypesCollectionResponse,
        $translate
    ) {
        var vm = this;
        vm.labourType = labourTypeResponse;
        vm.data = requestDataFactory.createRequest(getDefaults());

        vm.create = createAction;
        vm.update = updateAction;
        vm.delete = deleteAction;

        vm.statusOptions = [
            { value: true, title: $translate.instant('STATUS_ACTIVE') },
            { value: false, title: $translate.instant('STATUS_INACTIVE')},
        ];

        function getDefaults() {
            return {
                id: vm.labourType.id || null,
                name: vm.labourType.name || null,
                isActive: typeof vm.labourType.isActive === 'undefined' ? true : vm.labourType.isActive
            };
        }

        function createAction() {
            return apiClient.create(labourTypesCollectionResponse.getLink('create'), vm.data).then(function (response) {
                if (response) {
                    $state.go('^', {}, { reload: $state.current.parent }).then(function () {
                        messenger.success('LABOUR_TYPE_CREATED');
                    });
                } else {
                    messenger.error('REQUEST_ERROR');
                }
            });
        }

        function updateAction() {
            return apiClient.update(vm.labourType.getLink('edit'), vm.data).then(function (response) {
                if (response) {
                    $state.go('^', {}, { reload: 'dashboard.admin.labour-types.list' }).then(function () {
                        messenger.success('LABOUR_TYPE_UPDATED');
                    });
                } else {
                    messenger.error('REQUEST_ERROR');
                }
            });
        }

        function deleteAction() {
            return confirmationModal.open().result.then(function() {
                return apiClient.delete(vm.labourType.getLink('delete')).then(function(response) {
                    if (response) {
                        $state.go('dashboard.admin.labour-types.list', {}, { reload: 'dashboard.admin.labour-types.list' })
                            .then(function() {
                                messenger.success('LABOUR_TYPE_DELETED');
                        });
                    } else {
                        messenger.error('REQUEST_ERROR');
                    }
                });
            });
        }
    }
})();
