(function () {
    'use strict';

    angular
        .module('elogbooks.admin.waste-management')
        .controller('UnitAddEditController', UnitAddEditController);

    UnitAddEditController.$inject = ['$state', 'apiClient', 'confirmationModal', 'unitResponse', 'requestDataFactory', 'messenger', 'unitCollectionResponse', '$translate'];

    function UnitAddEditController ($state, apiClient, confirmationModal, unitResponse, requestDataFactory, messenger, unitCollectionResponse, $translate) {
        var vm = this;
            vm.unit = unitResponse;
            vm.data = requestDataFactory.createRequest(getDefaults());

        vm.create = createAction;
        vm.update = updateAction;
        vm.delete = deleteAction;

        vm.statusOptions = [
            { value: true, title: $translate.instant('STATUS_ACTIVE') },
            { value: false, title: $translate.instant('STATUS_INACTIVE') }
        ];

        function getDefaults() {
            return {
                id: vm.unit.id || null,
                name: vm.unit.name || null,
                symbol: vm.unit.symbol || null,
                status: vm.unit.status === 1 || vm.unit.status === 0 ? !!vm.unit.status : true
            };
        }

        function createAction () {
            return apiClient.create(unitCollectionResponse.getLink('create'), vm.data).then(function (response) {
                if (response) {
                    $state.go('^', {}, { reload: $state.current.parent }).then(function () {
                        messenger.success('UNIT_CREATED');
                    });
                } else {
                    messenger.error('REQUEST_ERROR');
                }
            });
        }

        function updateAction () {
            return apiClient.update(vm.unit.getLink('edit'), vm.data).then(function (response) {
                if (response) {
                    $state.go('^', {}, { reload: 'dashboard.admin.waste-management.units' }).then(function () {
                        messenger.success('UNIT_UPDATED');
                    });
                } else {
                    messenger.error('REQUEST_ERROR');
                }
            });
        }

        function deleteAction () {
            return confirmationModal.open().result.then(function () {
                return apiClient.delete(vm.unit.getLink('delete')).then(function (response) {
                    if (response) {
                        $state.go('dashboard.admin.waste-management.units', {}, { reload: 'dashboard.admin.waste-management.units' }).then(function () {
                            messenger.success('UNIT_DELETED');
                        });
                    } else {
                        messenger.error('REQUEST_ERROR');
                    }
                });
            });
        }
    };
})();
