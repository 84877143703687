(function () {
    'use strict';

    angular
        .module('elogbooks.admin.service-provider-groups')
        .controller('ServiceProviderGroupsListController', ServiceProviderGroupsListController);

    ServiceProviderGroupsListController.$inject = ['$state', '$stateParams', '$filter', 'serviceProviderGroupCollectionResponse'];

    function ServiceProviderGroupsListController($state, $stateParams, $filter, serviceProviderGroupCollectionResponse) {

        var vm = this;
        vm.serviceProviderGroups = serviceProviderGroupCollectionResponse.groups;
        vm.serviceProviderGroupCollectionResponse = serviceProviderGroupCollectionResponse;

        vm.order = order;
        vm.search = search;

        vm.criteria = {
            id: {type: 'number', value: $stateParams.id ? parseInt($stateParams.id, 10) : null, title: 'ID', min: 1},
            name: {type: 'text', value: $stateParams.name, title: 'NAME'},
        };

        function order(key) {
            $stateParams.order = key;

            $state.go('.', angular.extend({}, $stateParams), {reload: $state.get('^.^')});
        }

        function search(params) {
            var override = {
                page: 1
            };

            $state.go('.', angular.extend({}, params, override), {reload: $state.get('^.^')});
        }
    }
})();
