(function() {
    'use strict';

    angular
        .module('elogbooks')
        .config(Translations);

    function Translations($translateProvider) {
        $translateProvider.translations('en-gb', {
            QUOTE_OPTION: 'Quote Option',
            QUOTE_OPTIONS: 'Quote Options',

            QUOTE_OPTION_LIST: 'Quote Options',
            QUOTE_OPTION_VIEW: 'Quote Option',

            QUOTE_OPTION_ADD: 'Add a new Quote Option',
            BUDGET_QUOTE_OPTION_ADD: 'Add a new Budget Quote Option',
            QUOTE_OPTION_EDIT: 'Edit Quote Option',

            QUOTE_OPTION_CREATED: 'Quote Option Created',
            QUOTE_OPTION_UPDATED: 'Quote Option Updated',
            QUOTE_OPTION_DELETED: 'Quote Option Deleted',

            QUOTE_OPTION_STATUS_ACCEPTED: 'Accepted',
            QUOTE_OPTION_STATUS_REJECTED: 'Rejected',
            QUOTE_OPTION_STATUS_DRAFT: 'Draft',
            QUOTE_OPTION_STATUS_SUBMITTED: 'Submitted',
            QUOTE_OPTION_STATUS_PENDING_INFORMATION: 'Pending Information',
            QUOTE_OPTION_VALUE_TOO_LOW: 'The Quote Option requires Value with a minimum of 0.01',

            PLEASE_SELECT_QUOTE_OPTIONS: 'Please select quote options',
            OPTION_REQUIRES_ESCLATION: 'This option\'s value is outside of your approver threshold, requires escalation',
            QUOTE_ALREADY_ACCEPTED: 'This quote has already been accepted',

            QUOTE_OPTION_LABOUR: 'Labour',
            QUOTE_OPTION_LABOURS: 'Labour',

            QUOTE_OPTION_LABOUR_LIST: 'Labour',

            QUOTE_OPTION_LABOUR_ADD: 'Add a new Labour Line',
            QUOTE_OPTION_LABOUR_EDIT: 'Edit',

            QUOTE_OPTION_LABOUR_CREATED: 'Labour Line Created',
            QUOTE_OPTION_LABOUR_UPDATED: 'Labour Line Updated',
            QUOTE_OPTION_LABOUR_DELETED: 'Labour Line Deleted',

            QUOTE_OPTION_LABOUR_LABOUR_HOURS: 'Labour Hours',
            QUOTE_OPTION_LABOUR_TRAVEL_HOURS: 'Travel Hours',
            QUOTE_OPTION_LABOUR_MILEAGE: 'Mileage',
            QUOTE_OPTION_LABOUR_VALUE: 'Value',
            QUOTE_OPTION_LABOUR_TYPE: 'Labour Type',

            QUOTE_OPTION_MATERIAL: 'Materials',
            QUOTE_OPTION_MATERIALS: 'Materials',

            QUOTE_OPTION_MATERIAL_LIST: 'Materials',

            QUOTE_OPTION_MATERIAL_ADD: 'Add a new Material',
            QUOTE_OPTION_MATERIAL_EDIT: 'Edit',

            QUOTE_OPTION_MATERIAL_CREATED: 'Material Created',
            QUOTE_OPTION_MATERIAL_UPDATED: 'Material Updated',
            QUOTE_OPTION_MATERIAL_DELETED: 'Material Deleted',

            QUOTE_OPTION_MATERIAL_QUANTITY: 'Quantity',
            QUOTE_OPTION_MATERIAL_QUOTED_VALUE: 'Quoted Value',
            QUOTE_OPTION_MATERIAL_QUOTED_TAX_VALUE: 'Quoted Tax Value',
            QUOTE_OPTION_MATERIAL_ACTUAL_VALUE: 'Actual Value',
            QUOTE_OPTION_MATERIAL_ACTUAL_TAX_VALUE: 'Actual Tax Value',

            QUOTE_OPTION_OTHER: 'Other',
            QUOTE_OPTION_OTHERS: 'Other',

            QUOTE_OPTION_OTHER_LIST: 'Other',

            QUOTE_OPTION_OTHER_ADD: 'Add a new Other Line',
            QUOTE_OPTION_OTHER_EDIT: 'Edit',

            QUOTE_OPTION_OTHER_CREATED: 'Other Line Created',
            QUOTE_OPTION_OTHER_UPDATED: 'Other Line Updated',
            QUOTE_OPTION_OTHER_DELETED: 'Other Line Deleted',

            QUOTE_OPTION_OTHER_VALUE: 'Value',

            QUOTE_VALUE_OPTIONS_CANNOT_BE_ACCEPTED: 'Quote Value Options Cannot be Accepted',

            QUOTE_OPTION_SELECT_FILES: 'Select files to attach to this quote option:',
            QUOTE_OPTION_FILE_PROCESSING: 'The file is processing',
            QUOTE_OPTION_FILE_INVALID: 'The file has incorrect extension or is invalid',
        });
    }
})();
