/**
 * Image Tab
 */
angular.module('elogbooksDirectives').directive('imageTab', ['$rootScope', 'iconService', function ($rootScope, iconService) {
    return {
        restrict: 'AE',
        replace: true,
        scope: {
            tabTitle: '@',
            tabIcon: '@',
            stateName: '@',
            sref: '@',
            stateCheck: '@'
        },
        templateUrl: '/modules/directives/layout/image-tab/image-tab.html',
        link: function($scope, $element, $attrs) {

            // Associate font awesome CSS with the tab title if no icon provided
            if (typeof $attrs.tabIcon === 'undefined') {
                $attrs.tabIcon = iconService.getMap($attrs.tabTitle);
            }

            // Monitor state change so correct tab can be set active
            $scope.stateName = $scope.$root.currentState;

            // The state to check to maker active
            $scope.stateCheck = $scope.stateCheck || $scope.sref;

            //Remove params from state name
            var paramsPos = $scope.stateCheck.indexOf('(');
            if(paramsPos > -1) {
                $scope.stateCheck = $scope.stateCheck.substring(0, paramsPos);
            }

            $rootScope.$on('$stateChangeSuccess', function() {
                $scope.stateName = $scope.$root.currentState;
            });
        }
    }
}]);
