
(function() {
    'use strict';
    angular
        .module('elogbooks.helpdesk.dashboard')
        .controller('HelpdeskDashboardQuotesController', HelpdeskDashboardQuotesController);

    function HelpdeskDashboardQuotesController(
        $state,
        $stateParams,
        quoteCollectionResponse,
        approversResponse,
        config,
        $timeout,
        $scope,
        moveAlarmsModal,
        lodash,
        userManager,
        $translate
    ) {
        var vm = this;
        vm.quotes = quoteCollectionResponse.quotes;
        vm.quotesCollection = quoteCollectionResponse;
        vm.config = config;
        vm.approversResponse = approversResponse;
        vm.quotesSelected = 0;
        vm.canBulkMoveAlarms = userManager.hasPermission('user_permission_bulk_move_alarms');

        vm.criteria = {
            id: { type: 'number', title: 'ID', value: $stateParams.id ? parseInt($stateParams.id, 10) : null },
            serviceProviderName: { type: 'text', title: 'SERVICE_PROVIDER', value: $stateParams.serviceProviderName },
            siteName: { type: 'text', title: 'SITE', value: $stateParams.siteName },
            approverName: { type: 'selectwidget', title: 'APPROVER', value: $stateParams.approverName,
                options: {
                    response : vm.approversResponse,
                    link : vm.approversResponse ? vm.approversResponse.getLink('self') : null,
                    responseKeyPath: 'users'
                }
            },
            escalationStatus: {
                type: 'options',
                title: 'ESCALATION_STATUS_HELPDESK_FILTER',
                multiple: false,
                value: $stateParams.escalationStatus,
                options: [
                    {title: $translate.instant('NONE_SELECTED'), value: null},
                    {title: $translate.instant('ESCALATED'), value: 'escalated'},
                    {title: $translate.instant('NOT_ESCALATED'), value: 'not_escalated'}
                ]
            },
            alarmAtDueRange: {
                type: 'date-time-range',
                title: 'ALARM_DUE_AT',
                startDateLabel: 'ALARM_AT_DUE_RANGE_START_DATE_LABEL',
                endDateLabel: 'ALARM_AT_DUE_RANGE_END_DATE_LABEL',
                value: $stateParams.alarmAtDueRange,
                startDateRequired: true,
                endDateRequired: true
            }
        };

        vm.search = search;
        vm.order = orderAction;
        vm.countSelected = countSelected;
        vm.toggleSelected = toggleSelected;
        vm.moveAlarms = moveAlarms;

        function orderAction(key) {
            $stateParams.order = key;

            $state.go('.', angular.extend({}, $stateParams), { reload: $state.current });
        }

        function search(params) {
            var override = {
                page: 1
            };

            $state.go('.', angular.extend({}, params, override), { reload: $state.current });
        }

        function toggleSelected(quote) {
            if ($scope.refreshStatsTimeout) {
                $timeout.cancel($scope.refreshStatsTimeout);
                $scope.refreshStatsTimeout = null;
            }

            return quote.selected ? quote.selected : false;
        }

        function countSelected(quote) {
            if (quote) {
                vm.quotesSelected = quote.selected ? vm.quotesSelected += 1 : vm.quotesSelected -= 1;
                if (vm.quotesSelected === 0) {
                    $state.go($state.current, $stateParams, {reload: 'dashboard.helpdesk.dashboard'});
                }
            }
        }

        function moveAlarms() {
            var moveAlarms = moveAlarmsModal.open(
                vm.quotesCollection,
                vm.quotesSelected
            );
            moveAlarms.result.then(function () {
                clearActionSelection();
            });
        }



        function clearActionSelection() {
            lodash.forEach(vm.quotesCollection.quotes, function (quote) {
                quote.selected = false;
            });
            vm.quotesSelected = 0;
            $state.go($state.current, $stateParams, {reload: 'dashboard.helpdesk.dashboard'});
        }
    }
})();
