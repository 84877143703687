angular.module('elogbooksDirectives').directive('serviceProviderGroup', ['apiClient', function (apiClient) {
    return {
        require: ['ngModel'],
        scope: {
            group: '=ngModel',
            required: '=ngRequired'
        },
        templateUrl: '/modules/directives/form-elements/service-provider-group/service-provider-group.html',
        link: function(scope){
            scope.options = [];
            apiClient.get('/serviceprovidergroups?page=1&limit=999999').then(function(Response) {
                scope.options = Response.getData().groups;
            });
        }
    }
}]);
