(function () {
    'use strict';

    angular
        .module('elogbooksControllers')
        .controller('ConfirmationModalController', ConfirmationModalController);

    ConfirmationModalController.$inject = ['config', '$uibModalInstance', '$rootScope'];

    function ConfirmationModalController (config, $uibModalInstance, $rootScope) {
        var vm = this;
            vm.config = config;

        if(!vm.config.forceDirtyConfirm) {
            $rootScope.skipDirtyCheck = true;
        }

        vm.close = function () {
            $rootScope.skipDirtyCheck = false;
            $uibModalInstance.dismiss();
        };

        vm.confirm = function () {
            $uibModalInstance.close({result: true});
        };
    };
})();
