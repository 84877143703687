(function () {
    'use strict';

    angular
        .module('elogbooks.admin.serviceroutines')
        .controller('SurveyDetailsController', SurveyDetailsController);

    SurveyDetailsController.$inject = ['surveyResponse', 'sitesResponse'];

    function SurveyDetailsController (surveyResponse, sitesResponse) {
        var vm = this;
        vm.survey = surveyResponse;
    }
})();
