angular.module('elogbooksDirectives').directive('elogbooksJobGroupSelect', ['lodash','messenger', '$uibModal', function (lodash) {
    return {
        restrict: 'AE',
        require: ['ngModel'],
        scope: {
            model: '=ngModel',
            group: '=',
            groups: '=',
            returnObject: '@',
            isRequired: '=',
            childRequired: '='
        },
        templateUrl: '/modules/directives/form-elements/job-group-select/job-group-select.html',
        link: function(scope, element, attrs) {
            // Events required
            scope.raiseEvents = lodash.has(attrs, 'raiseEvents');
        },
        controller: function ($scope, $sce, apiClient, $uibModal) {
            $scope.loading = false;
            $scope.loadPath = false;
            $scope.onSelect = onSelect;
            $scope.onLoadMore = onLoadMore;
            $scope.onClear = onClear;
            $scope.onSearch = onSearch;
            $scope.generateSelectedJobGroupRoute = generateSelectedJobGroupRoute;

            generateSelectedJobGroupRoute();

            if ($scope.group && typeof $scope.group.path === 'undefined') {
                apiClient.get($scope.group.href).then(function (response) {
                    $scope.group = response;
                    $scope.loadPath = true;
                });
            }

            $scope.$watch('groups', function($newVal) {
                $scope.jobGroupFormFields = [];
                $scope.jobGroupFormFields.push(createFormField($newVal, $scope.group));
            }, true);

            function onLoadMore($event, index) {
                if ($scope.loading) {
                    return;
                }

                $scope.jobGroupFormFields[index].noMore = false;

                if ($scope.jobGroupFormFields[index].page === $scope.jobGroupFormFields[index].pages) {
                }

                if ($scope.jobGroupFormFields[index].page < $scope.jobGroupFormFields[index].pages) {
                    $event.stopPropagation();
                    $event.preventDefault();

                    $scope.loading = true;

                    if ($scope.jobGroupFormFields[index]._links.next && $scope.jobGroupFormFields[index]._links.next.href) {
                        apiClient.get($scope.jobGroupFormFields[index]._links.next.href, {fetchHierarchy:false}).then(function (response) {
                            $scope.jobGroupFormFields[index].groups = $scope.jobGroupFormFields[index].groups.concat(response.jobgroups);
                            $scope.jobGroupFormFields[index].response = response;
                            $scope.jobGroupFormFields[index].page = response.page;
                            $scope.jobGroupFormFields[index].pages = response.pages;
                            $scope.jobGroupFormFields[index]._links = response._links;
                            $scope.jobGroupFormFields[index].noMore = $scope.jobGroupFormFields[index].page === $scope.jobGroupFormFields[index].pages;
                            $scope.loading = false;
                        });
                    } else {
                        $scope.loading = false;
                    }
                }
            }

            function onSearch(keyword, index) {
                // hide the load-more button whilst searching
                $scope.jobGroupFormFields[index].noMore = true;

                if ($scope.jobGroupFormFields[index].response
                    && $scope.jobGroupFormFields[index].response.getLink('self')
                    && !$scope.loading
                ) {
                    $scope.loading = true;
                    // Async function needs to access object properties from global scope
                    apiClient.get($scope.jobGroupFormFields[index].response.getLink('self'), {name: keyword, page:1}).then(function (response) {
                        $scope.loading = false;
                        $scope.keyword = keyword;
                        $scope.jobGroupFormFields[index].groups = response.jobgroups;
                        $scope.jobGroupFormFields[index].response = response;
                        $scope.jobGroupFormFields[index].page = response.page;
                        $scope.jobGroupFormFields[index].pages = response.pages;
                        $scope.jobGroupFormFields[index].response = response;
                        $scope.jobGroupFormFields[index].noMore = $scope.jobGroupFormFields[index].page === $scope.jobGroupFormFields[index].pages;
                    });
                } else {
                    $scope.loading = false;
                }
            }

            function onSelect($item, $index, $child) {
                $scope.jobGroupFormFields.splice($index + 1, $scope.jobGroupFormFields.length);

                if ($scope.returnObject) {
                    $scope.model = { href: $item };
                } else {
                    $scope.model = { href: $item._links.self.href };
                    $scope.jobGroupFormField = {
                        selected: $item._links.self.href
                    };
                }

                if ($item._links.children !== undefined) {
                    apiClient.get($item._links.children.href).then(function (response) {
                        if (response.count > 0) {
                            $scope.jobGroupFormFields.push(createFormField(response, $child));
                        }
                    });
                }
                if ($scope.raiseEvents) {
                    $scope.$emit('jobgroup:select:click', $scope.model);
                }
            }

            function onClear($index) {
                $scope.jobGroupFormFields.splice($index + 1, $scope.jobGroupFormFields.length);
                $scope.jobGroupFormFields[$index].selected = null;
                if ($scope.jobGroupFormFields[$index - 1]) {
                    $item = $scope.jobGroupFormFields[$index - 1];

                    if ($scope.returnObject) {
                        $scope.model.href = $item.selected;
                    } else {
                        $scope.model.href = $item.selected._links.self.href;
                    }
                } else {
                    delete $scope.model;
                    if ($scope.raiseEvents) {
                        $scope.$emit('jobgroup:select:clear', $scope);
                    }
                }
            }

            function createFormField($jobGroups, $jobGroup) {
                if ($jobGroup && typeof $jobGroup.title !== 'undefined') {
                    $jobGroup.name = $jobGroup.title;
                }

                var noMore = true;

                if (typeof $jobGroups !== 'undefined' && $jobGroups.hasOwnProperty('jobgroups')) {
                    if ($jobGroups.page !== $jobGroups.pages) {
                        noMore = false;
                    }

                    return {
                        selected: $jobGroup,
                        groups: $jobGroups.jobgroups,
                        limit: 30,
                        page: $jobGroups.page,
                        pages: $jobGroups.pages,
                        _links: $jobGroups._links,
                        response: $jobGroups,
                        noMore: noMore
                    };
                }

                return {
                    selected: $jobGroup,
                    groups: $jobGroups.jobgroups,
                    noMore: noMore
                };
            }

            /**
             * Generate the slash delimited route to display above the selected group
             */
            function generateSelectedJobGroupRoute() {
                $scope.jobGroupFormFields = [];
                $scope.jobGroupFormFields.push(createFormField($scope.groups, $scope.group));
            }

        }
    };
}]);
