(function() {

    angular
        .module('elogbooks.common.quote-requests')
        .controller('QuoteOperationSubmitController', Controller('SUBMIT', 'submit', false, false))
        .controller('QuoteOperationAbstainController', Controller('ABSTAIN', 'abstain', true, false))
        .controller('QuoteCancelController', Controller('CANCEL', 'cancel', true, false))
        .controller('QuoteReopenController', Controller('REOPEN', 'reopen', true, false))
        .controller('QuoteEscalateController', Controller('ESCALATE', 'escalate', true, false))
        .controller('QuoteAcceptanceMoreInformationController', Controller('REQUEST_MORE_INFORMATION', 'request-more-information', true))
        .controller('QuoteAcceptanceRejectController', Controller('REJECT', 'reject', false, false))
        .controller('QuoteAcceptanceRejectValuesController', Controller('REJECT_VALUES', 'reject-values', false, false))
        // .controller('QuoteAcceptanceAcceptController', Controller('ACCEPT', 'accept', false, 'accept'))
        .controller('QuoteAcceptanceHoldController', Controller('HOLD', 'put-on-hold', false, false))
        .controller('QuoteExtensionRequestController', Controller('REQUEST_EXTENSION', 'request-extension', true))
        .controller('QuoteExtensionAcceptController', Controller('ACCEPT_EXTENSION', 'accept-extension', false, false))
        .controller('QuoteExtensionRejectController', Controller('REJECT_EXTENSION', 'reject-extension', false, false))
        .controller('QuoteExtensionCancelController', Controller('CANCEL_EXTENSION', 'cancel-extension', true, false))
        .controller('QuoteApprovalEscalateController', Controller('ESCALATE_APPROVAL', 'escalate-approval', true, false))
        .controller('QuotePostponeReminderAtController', Controller('POSTPONE_REMINDER_AT', 'postpone-reminder-at', true, 'postpone-reminder-at'))
    ;

    function Controller(translation, action, noteRequired, partial) {
        return function(apiClient, $state, messenger, user, quoteResponse, requestDataFactory, $scope) {
            var vm = this;
            vm.quoteResponse = quoteResponse;
            vm.action = action;
            vm.translation = vm.quoteResponse.budgetQuote && translation === 'SUBMIT' ? 'QUOTE_WORKFLOW_BUDGET_QUOTE' : 'QUOTE_WORKFLOW_' + translation;
            vm.noteRequired = noteRequired;
            vm.partial = (typeof partial === 'undefined' ? vm.action : partial);
            vm.data = requestDataFactory.createRequest();
            vm.highlightedSubtitle = vm.quoteResponse.budgetQuote && translation === 'SUBMIT' ? 'BUDGET_QUOTE' : null;

            vm.submit = submitAction;

            vm.data.reminderAt = new Date(vm.quoteResponse.reminderAt);

            if (action === 'request-more-information') {
                vm.data.previouslyDueAt = new Date(vm.quoteResponse.completionDueAt);
            }

            function submitAction () {
                return apiClient.create(vm.quoteResponse.getLink(action), vm.data).then(function (response) {
                    if (response) {
                        $state.go('^', {}, { reload: $state.get('^.^.^.^') }).then(function () {
                            messenger.success(vm.translation + '_SUCCESS');
                        });
                    } else {
                        messenger.error('REQUEST_ERROR');
                    }
                });
            }
        }
    }
})();
