app.config(function ($translateProvider) {
    $translateProvider.translations('zh-hk', {
        GENERATE_NEW_PASSWORD: '發新密碼',
        RESET_PASSWORD: '重設密碼',
        RESET_PASSWORD_INSTRUCTIONS: '按下發新密碼.',
        MODIFY_PASSWORD: '修改密碼',
        CHANGE_PASSWORD: '更改密碼',
        NEW_PASSWORD: '新密碼',
        CONFIRM_PASSWORD: '確認密碼',
        NEW_PASSWORD_GENERATED: '新密碼已發出',
        LOGIN_AS_USER: '以此用戶身份登錄',
        RETURN_TO_ORIGINAL_USER_FIRST: '返回原來的用戶'
    });
});
