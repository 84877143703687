app.config(function ($translateProvider) {
    $translateProvider.translations('nl', {

        APPROVER: 'Goedkeurder',
        APPROVERS: 'Goedkeurders',
        APPROVED_BY: 'Goedgekeurd door',

        APPROVERS_UPDATED: 'Goedkeurders aangepast',

        APPROVAL_REQUIRED: 'Goedkeuring vereist',
        APPROVER_REQUIRED: 'Een goedkeurder moet worden toegevoegd aan de lijst omdat goedkeuring is vereist',
        APPROVER_NOT_ADDED: 'Geen goedkeurder toegevoegd',
        APPROVER_ADDED_TO_ALL_APPROVER_TYPES: 'Goedkeurder toegevoegd voor alle type goedkeuringen van deze locatie',
        APPROVER_REMOVED_FROM_ALL_APPROVER_TYPES: 'Goedkeurder verwijderd voor alle type goedkeuringen van deze locatie',
        USER_ALREADY_APPROVER: 'Gebruiker is al toegevoegd als goedkeurder voor dit type op deze locatie',

        APPROVAL_REQUIRED: 'Goedkeuring vereist',

        ADD_REMOVE_USER_ALL_APPROVER_TYPES: 'Toevoegen of Verwijderen gebruiker van alle type goedkeuringen',

        REACTIVE_JOB_APPROVER: 'Goedkeurder correctief onderhoud',
        REACTIVE_JOB_APPROVERS: 'Correctief onderhoud',
        REACTIVE_JOB_EXTENSIONS_APPROVERS: 'Correctief onderhoud uitstel',
        PLANNED_JOB_APPROVER: 'Planmatig onderhoud',
        PLANNED_JOB_APPROVERS: 'Planmatig onderhoud',
        PLANNED_JOB_EXTENSIONS_APPROVERS: 'Planmatig onderhoud uitstel',
        JOB_EXTENSION_APPROVER: 'Goedkeurder werkzaamheden boven mandaat',
        JOB_EXTENSION_APPROVERS: 'Werkzaamheden boven mandaat',
        QUOTE_APPROVER: 'Goedkeurder offerte',
        QUOTE_APPROVERS: 'Offerte',
        CONTRACT_APPROVER: 'Goedkeurder onderhoudscontract',
        CONTRACT_APPROVERS: 'Onderhoudscontract',
        SURVEY_APPROVER: 'Goedkeurder enquete',
        SURVEY_APPROVERS: 'Enquete',
        EXTENSION_APPROVER: 'Extension Approver',

        APPROVER_ADDED_BUT_NOT_REQUIRED: 'Er is een goedkeurder toegevoegd, maar de goedkeuring vereist optie is niet aangevinkt.',

        SITE_APPROVER_LIST: 'Lijst met goedkeurders voor deze locatie',
    });
});
