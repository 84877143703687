(function() {
    'use strict';

    angular
        .module('elogbooks.common.billing-settings')
        .controller('CommonBillingSettingsController', CommonBillingSettingsController);

    CommonBillingSettingsController.$inject = [
        'billingSettingsResponse',
        'relatedCoreClientBillingSettingsResponse',
        'approversResponse',
        'config',
        'lodash'
    ];
    function CommonBillingSettingsController(
        billingSettingsResponse,
        relatedCoreClientBillingSettingsResponse,
        approversResponse,
        config,
        lodash
    ) {
        var vm = this;
        vm.billingSettingsResponse = billingSettingsResponse;
        vm.billingSettings = billingSettingsResponse.billingsettings.length ? lodash.first(billingSettingsResponse.billingsettings) : {};
        vm.config = config;
        vm.costApproverDisplay = vm.billingSettings._links;
        vm.approversResponse = approversResponse;
        vm.approverTypes = {};

        if (vm.costApproverDisplay){
            vm.approverTypes = [
                {
                    title : 'CONTRACT_MANAGER_APPROVERS',
                    required: approversResponse.contractManagerApprovers.required,
                    approvers: approversResponse.contractManagerApprovers.approvers.approverUsers
                },
                {
                    title: 'CLIENT_APPROVERS',
                    required: approversResponse.clientApprovers.required,
                    approvers: approversResponse.clientApprovers.approvers.approverUsers
                }
            ]
        }

        if (vm.billingSettings.useCoreClientSettings && relatedCoreClientBillingSettingsResponse || !vm.billingSettings.id && relatedCoreClientBillingSettingsResponse) {
            vm.billingSettings = relatedCoreClientBillingSettingsResponse;
        }

        vm.canCreate = vm.billingSettingsResponse.canCreate;
        vm.billingSettingsbuttonTitle = vm.canCreate ? 'BILLING_SETTINGS_ADD' : 'BILLING_SETTINGS_EDIT';
        vm.billingSettingsbuttonAction = vm.canCreate ? 'add' : 'edit';

        vm.getEditLinkByType = function () {
            switch (config.type) {
                case 'site':
                    return 'dashboard.admin.sites.list.details.billing-settings.approvers.edit';
                case 'core-client':
                    return 'dashboard.admin.core-clients.list.details.billing-settings.approvers.edit';
            }
        }
    }
})();
