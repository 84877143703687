(function () {
    'use strict';

    angular
        .module('elogbooks.user.reports')
        .controller('UserReportsExtensionRequestsJobsController', UserReportsExtensionRequestsJobsController);

    UserReportsExtensionRequestsJobsController.$inject = ['$state', '$stateParams', 'jobsCollection', 'jobParams', 'modalConfig'];

    function UserReportsExtensionRequestsJobsController($state, $stateParams, jobsCollection, jobParams, modalConfig) {
        var vm = this;

        vm.jobsCollection = jobsCollection;
        vm.jobs = jobsCollection.jobs;
        vm.extensionRequestStatus = $stateParams.status;

        jobParams.reportType = 'sp_summary';
        vm.exportParams = jobParams;

        vm.modalConfig = modalConfig;
    }
})();