(function(){
    'use strict';

    angular.module('elogbooks.user.finance',
        [
            'elogbooks.user.finance.dashboard',
            'elogbooks.user.finance.suppliers',
            'elogbooks.user.finance.purchase-invoices',
            'elogbooks.user.finance.purchase-orders',
            'elogbooks.user.finance.sales-invoices',
            'elogbooks.user.finance.credit-notes'
        ]
    );
})();
