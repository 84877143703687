angular.module('elogbooksDirectives').directive('elbIframeOnload', [function(){
  return {
      scope: {
          callBack: '&elbIframeOnload'
      },
      link: function(scope, element, attrs){
          element.on('load', function(){
              return scope.callBack({
                element: element
              });
          })
      }
  }}])