(function() {
    'use strict';

    angular
        .module('elogbooks')
        .config(Translations);

    function Translations($translateProvider) {
        $translateProvider.translations('en-gb', {
            REQUIRE_ASSET_WHEN_LOGGING_REACTIVE_JOB: 'Require Asset for Reactive Jobs'
        });
    }
})();

app.config(function ($translateProvider) {
    $translateProvider.translations('en-gb', {
        SERVICE_PROVIDER: 'Service Provider',
        SERVICE_PROVIDERS: 'Service Providers',

        ADD_NEW_SERVICE_PROVIDER: 'Add a new Service Provider',

        SERVICE_PROVIDER_LIST: 'Service Providers',
        SERVICE_PROVIDER_ADD: 'Add a new Service Provider',
        SERVICE_PROVIDER_EDIT: 'Edit Service Provider',

        SERVICE_PROVIDER_CREATED: 'Service Provider Created',
        SERVICE_PROVIDER_UPDATED: 'Service Provider Updated',
        SERVICE_PROVIDER_DELETED: 'Service Provider Deleted',

        SERVICE_PROVIDERS_SELECT: 'Select Service Providers',
        SERVICE_PROVIDERS_ADD: 'Add Service Providers',
        SERVICE_PROVIDERS_UPDATE: 'Update Service Providers',
        SERVICE_PROVIDERS_UPDATED: 'Service Providers Updated',

        SERVICE_PROVIDER_PERMISSIONS_UPDATED: 'Service Provider permissions updated',
        SERVICE_PROVIDERS_IN_ESCALATION: 'Service Providers in Escalation',

        PRIMARY_OPERATIVES: 'Primary Operatives',
        PRIMARY_OPERATIVE_TYPE: 'Primary Operative Type',

        PRIMARY_OPERATIVE_TYPES_EDIT: 'Edit Primary Operatives',
        PRIMARY_OPERATIVES_UPDATED: 'Primary Operatives Updated',

        PRIMARY_JOB_OPERATIVE: 'Job',
        PRIMARY_ESCALATION_OPERATIVE: 'Escalation',
        PRIMARY_QUOTE_OPERATIVE: 'Quote',
        PRIMARY_PATROL_OPERATIVE: 'Patrol',
        PRIMARY_SURVEY_OPERATIVE: 'Survey',
        PRIMARY_AUDIT_OPERATIVE: 'Audit',
        PRIMARY_HEALTH_SAFETY_OPERATIVE: 'Health and Safety',
        PRIMARY_QUOTE_REVIEWER_OPERATIVE: 'Quote Reviewer',
        ACTIVE_AT: 'Active from',
        INACTIVE_AT: 'Inactive from',
        TRAINED_AT: 'Trained on',
        TRAINED_AT_START: 'Trained At Start',
        TRAINED_AT_END: 'Trained At End',
        ACTIVE_AT_START: 'Active At Start',
        ACTIVE_AT_END: 'Active At End',
        OPERATIVE_CREATED: 'Operative Created',
        PERMISSIONS_UPDATED: 'Permissions Updated',
        OPERATIVE_REMOVED: 'Operative Removed',
        SERVICE_PROVIDER_PARTNERSHIP_ADDED: 'Partnership Added',

        SERVICE_PROVIDER_STATUS: 'Service Provider Status',

        CONTRACTOR_APPROVED: 'Contractor Approved',
        CONTRACTORAPPROVED: 'Contractor Approved',
        NOT_APPROVED: 'Not Approved',
        SHARED: 'Shared',
        REQUIRELABOURLINEONJOBCOMPLETE: 'Require Labour Line When Completing Job',
        REQUIRESIGNATURE: 'Require Signature When Completing Job',

        MARKUPQUOTES: 'Quotes Markup',
        MARKUPLABOUR: 'Labour Markup',
        MARKUPMATERIALS: 'Materials Markup',

        PPM_REMINDERALERTOPTION: 'Upcoming PPM Email',
        PPM_REMINDERNOTIFICATIONPERIOD: 'PPM reminder notification period',
        PPM_REMINER_DISABLED: 'Disabled',
        PPM_REMINDER_STATUTORY_ONLY: 'Statutory only',
        PPM_REMINDER_ALL: 'Remind PPM',
        PPM_REMINDERNOTIFICATION_SUB_HEADER: 'Number of days in advance that the ppm reminder email should be sent',

        SAFECONTRACTOR_ID: 'SafeContractor ID',
        FEEDBACK_LIST: 'Feedback',
        CONTACTABLE: 'Contactable',
        DISABLE_JOB_APPROVAL: 'Disable Job Approval',
        DISABLE_JOB_APPROVAL_PARTNERSHIP: 'Disable Job Approval for this Partnership',

        MY_SERVICE_PROVIDERS: 'My Service Providers',
        ALL_SERVICE_PROVIDERS: 'All Service Providers',
        RATING: 'Rating',

        FEEDBACK_MODAL_PLACEHOLDER: 'Let us know if you have any comments...',
        REACTIVE_COMPLETED: 'Reactives completed:',
        PPM_COMPLETED: 'PPMs completed:',
        MODAL_TOOLTIP: 'Why am I seeing this? You are listed as a Reactive/Planned Job Approver on at least one Site and therefore we would like your feedback on the Service Provider',
        THANK_YOU_FOR_FEEDBACK: 'Thank you for your feedback.',
        FEEDBACK_NOT_EXPECTED: 'Feedback for this Service Provider Was not expected.',
        RATING_DETAILS: '{{ score }} stars based on {{ total }} feedback response(s)',
        RATINGS: 'ratings',
        RATING_SINGULAR: 'rating',

        FEEDBACK_AT_RANGE_START_DATE_LABEL: 'Feedback at Start',
        FEEDBACK_AT_RANGE_END_DATE_LABEL: 'Feedback at End',

        MANDATORY_LABOUR_LINE_OPTIONS: 'Mandatory Labour Line Options',
        SELECTED_MANDATORY_LABOUR_LINE_OPTIONS: 'Selected Mandatory Labour Line Options:',
        SELECT_MANDATORY_OPTION: 'Select mandatory option',
        MANDATORY_LABOUR_LINE_OPTION_START_DATE: 'Start Date',
        MANDATORY_LABOUR_LINE_OPTION_TIME_WORKED: 'Time Worked',
        MANDATORY_LABOUR_LINE_OPTION_TIME_TRAVELLED: 'Time Travelled',
        MANDATORY_LABOUR_LINE_OPTION_MILEAGE: 'Mileage',
        MANDATORY_LABOUR_LINE_OPTION_DESCRIPTION: 'Description',
    });
});
