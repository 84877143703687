(function(){
    'use strict';

    angular
        .module('elogbooks.admin.waste-management')
        .config(registerRoutes)
        .config(registerRoutesListWasteTypes)
        .config(registerRoutesAddWasteType)
        .config(registerRoutesEditWasteType)
        .config(registerRoutesEditTreatmentType)
        .config(registerRoutesListTreatmentTypes)
        .config(registerRoutesAddTreatmentType)
        .config(registerRoutesListUnits)
        .config(registerRoutesAddUnit)
        .config(registerRoutesEditUnit)
    ;

    function registerRoutes($stateProvider) {
        $stateProvider
            .state('dashboard.admin.waste-management', {
                url: '/waste-management',
                abstract: true,
                templateUrl: '/modules/admin/waste-management/waste-management.html',
                ncyBreadcrumb: {
                    label: '{{ ::"WASTE_MANAGEMENT" | translate }}'
                }
            })
    }

    function registerRoutesListWasteTypes ($stateProvider) {
        $stateProvider
            .state('dashboard.admin.waste-management.waste-types', {
                parent: 'dashboard.admin.waste-management',
                url: '/waste-types?page&limit&order&id&name&status&code',
                views: {
                    '@dashboard.admin.waste-management': {
                        templateUrl: '/modules/admin/waste-management/waste-types/waste-types-list.html',
                        controller: 'WasteTypesController',
                        controllerAs: 'vm'
                    }
                },
                resolve: {
                    wasteTypeCollectionResponse : function (apiClient, $stateParams, rootResourceResponse) {
                        return apiClient.get(rootResourceResponse.getLink('wastetypes'), $stateParams).then(function (response) {
                            return response || apiClient.noResourceFound();
                        });
                    }
                },
                params: {
                    page: '1',
                    limit: '30',
                    order: 'id',
                    id: null,
                    name: null,
                    code: null,
                    status: { value: null }
                },
                ncyBreadcrumb: {
                    label: '{{ ::"WASTE_TYPES" | translate }}'
                }
            });
    }

    function registerRoutesAddWasteType($stateProvider) {
        $stateProvider
            .state('dashboard.admin.waste-management.waste-types.add', {
                url: '/add',
                parent: 'dashboard.admin.waste-management.waste-types',
                views: {
                    '@dashboard.admin.waste-management': {
                        templateUrl: '/modules/admin/waste-management/waste-types/add-edit/waste-type-form.html',
                        controller: 'WasteTypeAddEditController',
                        controllerAs: 'vm'
                    }
                },
                resolve: {
                    wasteTypeResponse: function () {
                        return {};
                    }
                },
                ncyBreadcrumb: {
                    label: '{{ ::"ADD" | translate }}'
                }
            });
    }

    function registerRoutesEditWasteType($stateProvider) {
        $stateProvider
            .state('dashboard.admin.waste-management.waste-types.edit', {
                url: '/edit/{resource}',
                parent: 'dashboard.admin.waste-management.waste-types',
                views: {
                    '@dashboard.admin.waste-management': {
                        templateUrl: '/modules/admin/waste-management/waste-types/add-edit/waste-type-form.html',
                        controller: 'WasteTypeAddEditController',
                        controllerAs: 'vm'
                    }
                },
                resolve: {
                    wasteTypeResponse: function (apiClient, $stateParams) {
                        return apiClient.get($stateParams.resource.decode());
                    }
                },
                ncyBreadcrumb: {
                    label: '{{ ::"EDIT" | translate }}'
                }
            });
    }

    function registerRoutesListTreatmentTypes($stateProvider) {
        $stateProvider
            .state('dashboard.admin.waste-management.treatment-types', {
                parent: 'dashboard.admin.waste-management',
                url: '/treatment-types?page&limit&order&id&name&status',
                views: {
                    '@dashboard.admin.waste-management': {
                        templateUrl: '/modules/admin/waste-management/treatment-types/treatment-types-list.html',
                        controller: 'TreatmentTypesController',
                        controllerAs: 'vm'
                    }
                },
                resolve: {
                    treatmentTypeCollectionResponse : function (apiClient, $stateParams, rootResourceResponse) {
                        return apiClient.get(rootResourceResponse.getLink('treatmenttypes'), $stateParams).then(function (response) {
                            return response || apiClient.noResourceFound();
                        });
                    }
                },
                params: {
                    page: '1',
                    limit: '30',
                    order: 'id',
                    id: null,
                    name: null,
                    status: { value: null }
                },
                ncyBreadcrumb: {
                    label: '{{ ::"TREATMENT_TYPES" | translate }}'
                }
            });
    }

    function registerRoutesAddTreatmentType($stateProvider) {
        $stateProvider
            .state('dashboard.admin.waste-management.treatment-types.add', {
                url: '/add',
                parent: 'dashboard.admin.waste-management.treatment-types',
                views: {
                    '@dashboard.admin.waste-management': {
                        templateUrl: '/modules/admin/waste-management/treatment-types/add-edit/treatment-type-form.html',
                        controller: 'TreatmentTypeAddEditController',
                        controllerAs: 'vm'
                    }
                },
                resolve: {
                    treatmentTypeResponse: function () {
                        return {};
                    }
                },
                ncyBreadcrumb: {
                    label: '{{ ::"ADD" | translate }}'
                }
            });
    }

    function registerRoutesEditTreatmentType($stateProvider) {
        $stateProvider
            .state('dashboard.admin.waste-management.treatment-types.edit', {
                url: '/edit/{resource}',
                parent: 'dashboard.admin.waste-management.treatment-types',
                views: {
                    '@dashboard.admin.waste-management': {
                        templateUrl: '/modules/admin/waste-management/treatment-types/add-edit/treatment-type-form.html',
                        controller: 'TreatmentTypeAddEditController',
                        controllerAs: 'vm'
                    }
                },
                resolve: {
                    treatmentTypeResponse: function (apiClient, $stateParams) {
                        return apiClient.get($stateParams.resource.decode());
                    }
                },
                ncyBreadcrumb: {
                    label: '{{ ::"EDIT" | translate }}'
                }
            });
    }

    function registerRoutesListUnits($stateProvider) {
        $stateProvider
            .state('dashboard.admin.waste-management.units', {
                parent: 'dashboard.admin.waste-management',
                url: '/units?page&limit&order&id&name&status&symbol',
                views: {
                    '@dashboard.admin.waste-management': {
                        templateUrl: '/modules/admin/waste-management/units/unit-list.html',
                        controller: 'UnitsController',
                        controllerAs: 'vm'
                    }
                },
                resolve: {
                    unitCollectionResponse : function (apiClient, $stateParams, rootResourceResponse) {
                        return apiClient.get(rootResourceResponse.getLink('units'), $stateParams).then(function (response) {
                            return response || apiClient.noResourceFound();
                        });
                    }
                },
                params: {
                    page: '1',
                    limit: '30',
                    order: 'id',
                    id: null,
                    name: null,
                    symbol: null,
                    status: { value: null }
                },
                ncyBreadcrumb: {
                    label: '{{ ::"UNITS" | translate }}'
                }
            });
    }

    function registerRoutesEditUnit($stateProvider) {
        $stateProvider
            .state('dashboard.admin.waste-management.units.edit', {
                url: '/edit/{resource}',
                parent: 'dashboard.admin.waste-management.units',
                views: {
                    '@dashboard.admin.waste-management': {
                        templateUrl: '/modules/admin/waste-management/units/add-edit/unit-form.html',
                        controller: 'UnitAddEditController',
                        controllerAs: 'vm'
                    }
                },
                resolve: {
                    unitResponse: function (apiClient, $stateParams) {
                        return apiClient.get($stateParams.resource.decode());
                    }
                },
                ncyBreadcrumb: {
                    label: '{{ ::"EDIT" | translate }}'
                }
            });
    }

    function registerRoutesAddUnit($stateProvider) {
        $stateProvider
            .state('dashboard.admin.waste-management.units.add', {
                url: '/add',
                parent: 'dashboard.admin.waste-management.units',
                views: {
                    '@dashboard.admin.waste-management': {
                        templateUrl: '/modules/admin/waste-management/units/add-edit/unit-form.html',
                        controller: 'UnitAddEditController',
                        controllerAs: 'vm'
                    }
                },
                resolve: {
                    unitResponse: function () {
                        return {};
                    }
                },
                ncyBreadcrumb: {
                    label: '{{ ::"ADD" | translate }}'
                }
            });
    }
})();
