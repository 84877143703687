(function() {
    'use strict';

    angular
        .module('elogbooks')
        .config(Translations);

    function Translations($translateProvider) {
        $translateProvider.translations('en-gb', {
            CURRENCY_NONE: '',
            CURRENCY_GBP: '£',
            CURRENCY_EUR: '€'
        });
    }
})();
