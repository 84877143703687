app.config(function ($translateProvider) {
    $translateProvider.translations('zh-hk', {
        BUILDING: '樓宇 &amp;',
        BUILDINGS: '樓宇',

        ADD_NEW_BUILDING: '新增樓宇',
        EDIT_BUILDING: '修改樓宇',

        BUILDING_CREATED: '樓宇已建立',
        BUILDING_UPDATED: '樓宇已更新',
        BUILDING_DELETED: '樓宇已刪除',

        NO_BUILDING_RECORDS: '沒有樓宇資料',
    });
});
