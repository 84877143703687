(function (){
    'use strict';

    angular
        .module('elogbooks.common.waste-management')
        .controller('UserWasteManagementController', UserWasteManagementController);

    UserWasteManagementController.$inject = ['sectionResource'];

    function UserWasteManagementController(sectionResource) {
        var vm = this;
        vm.sectionResource = sectionResource;
    }
})();
