(function() {
    'use strict';

    angular
        .module('elogbooks')
        .config(Translations);

    function Translations($translateProvider) {
        $translateProvider.translations('nl', {
            ESCALATION: 'Escalatie',
            ESCALATIONS: 'Escalaties',
            ESCALATION_LIST: 'Escalaties',

            ESCALATION_ADD: 'Voeg nieuwe escalatie toe',
            ESCALATION_EDIT: 'Wijzig escalatie',

            ESCALATION_CREATED: 'Escalatie aangemaakt',
            ESCALATION_UPDATED: 'Escalatie gewijzigd',
            ESCALATION_DELETED: 'Escalatie verwijderd',

            ESCALATE: 'Escaleer',
            ESCALATED: 'Escaleer',
            ESCALATED_AT: 'Escaleer op',

            ESCALATION_DETAILS: 'Escalatie {{ id }} details',

            ESCALATION_STATUS: 'Status',

            DEESCALATED: 'Verwijderd uit escalatie',
        });
    }
})();

app.config(function ($translateProvider) {
    $translateProvider.translations('nl', {


        ESCALATION_SUCCESS: 'Escalatie successvol',
    });
});
