/**
 * View Satisfaction Survey Controller
 */
(function(){
    'use strict';

    angular
        .module('elogbooks.helpdesk.satisfactionsurveys')
        .controller('SatisfactionSurveyViewController', SatisfactionSurveyViewController);

    SatisfactionSurveyViewController.$inject = ['$scope', 'apiClient', 'messenger', 'satisfactionSurveyResponse', 'satisfactionSurveyLineCollectionResponse', 'satisfactionSurveyRequestLineCollectionResponse', 'jobResponse', 'siteResponse'];
    
    function SatisfactionSurveyViewController($scope, apiClient, messenger, satisfactionSurveyResponse, satisfactionSurveyLineCollectionResponse, satisfactionSurveyRequestLineCollectionResponse, jobResponse, siteResponse) {
        var vm = this;
        vm.satisfactionSurvey = satisfactionSurveyResponse.getData();
        vm.job = jobResponse.getData();
        vm.job.site = siteResponse.getData();

        var surveyLines = [];
        var requestLines = satisfactionSurveyRequestLineCollectionResponse.getData().satisfactionsurveyrequestlines;

        if (!vm.satisfactionSurvey.refused && requestLines.length === 0) {
            var activeLines = satisfactionSurveyLineCollectionResponse.getData().satisfactionsurveylines;

            angular.forEach(activeLines, function (value, key) {
                var line = {
                    id: null,
                    response: null,
                    satisfactionSurveyLine: {
                        id: value.id,
                        description: value.description,
                        displayOrder: value.displayOrder
                    }

                };
                surveyLines.push(line);
            });
        } else {
            angular.forEach(requestLines, function (value, key) {
                var line = {
                    id: value.id,
                    response: value.response,
                    satisfactionSurveyLine: {
                        id: value.line.id,
                        description: value.line.description,
                        displayOrder: value.line.displayOrder
                    }
                };
                surveyLines.push(line);
            });
        }

        vm.satisfactionSurveyRequestLines = surveyLines;
        
        vm.responseScores = ['N/A', 'Bad / No', 'Average / Indifferent', 'Good / Yes'];
    };

})();
