(function() {
    'use strict';

    angular
        .module('elogbooks.admin.timesheet-types')
        .controller('TimesheetTypesInfoController', TimesheetTypesInfoController);

    TimesheetTypesInfoController.$inject = ['$state', 'apiClient', 'confirmationModal', 'messenger', 'timesheetTypeResponse'];
    function TimesheetTypesInfoController($state, apiClient, confirmationModal, messenger, timesheetTypeResponse) {
        var vm = this;
        vm.timesheetType = timesheetTypeResponse;
    }
})();
