/**
 * Add Satisfaction Survey Controller
 */
(function(){
    'use strict';

    angular
        .module('elogbooks.helpdesk.satisfactionsurveys')
        .controller('SatisfactionSurveyEditController', SatisfactionSurveyEditController);

    SatisfactionSurveyEditController.$inject = ['$scope', 'apiClient', 'messenger', 'satisfactionSurveyResponse', 'satisfactionSurveyLineCollectionResponse', 'satisfactionSurveyRequestLineCollectionResponse', 'jobResponse', 'siteResponse'];
    
    function SatisfactionSurveyEditController($scope, apiClient, messenger, satisfactionSurveyResponse, satisfactionSurveyLineCollectionResponse, satisfactionSurveyRequestLineCollectionResponse, jobResponse, siteResponse) {
        var vm = this;
        vm.satisfactionSurvey = satisfactionSurveyResponse.getData();
        vm.job = jobResponse.getData();
        vm.job.site = siteResponse.getData();

        var surveyLines = [];
        var requestLines = satisfactionSurveyRequestLineCollectionResponse.getData().satisfactionsurveyrequestlines;

        if (!vm.satisfactionSurvey.refused && requestLines.length === 0) {
            var activeLines = satisfactionSurveyLineCollectionResponse.getData().satisfactionsurveylines;

            angular.forEach(activeLines, function (value, key) {
                var line = {
                    id: null,
                    response: null,
                    satisfactionSurveyLine: {
                        id: value.id,
                        description: value.description,
                        displayOrder: value.displayOrder
                    }

                };
                surveyLines.push(line);
            });
        } else {
            angular.forEach(requestLines, function (value, key) {
                var line = {
                    id: value.id,
                    response: value.response,
                    satisfactionSurveyLine: {
                        id: value.line.id,
                        description: value.line.description,
                        displayOrder: value.line.displayOrder
                    }
                };
                surveyLines.push(line);
            });
        }

        vm.satisfactionSurveyRequestLines = surveyLines;

        vm.responseScores = [
            {id: 0, name: 'N/A'},
            {id: 1, name: 'Bad / No'},
            {id: 2, name: 'Average / Indifferent'},
            {id: 3, name: 'Good / Yes'},
        ];

        vm.submit = function () {
            vm.satisfactionSurvey.requiresAttention = false;
            vm.satisfactionSurvey.requestLines = vm.satisfactionSurveyRequestLines;
            var updateLink = satisfactionSurveyResponse.getLink('self');
            apiClient.update(updateLink, vm.satisfactionSurvey).then(function (isSuccess) {
                if (isSuccess) {
                    $scope.$state.go('^', {}, {reload: true}).then(function(){
                        messenger.success('Satisfaction Survey ID ' + vm.satisfactionSurvey.id + ' updated successfully');
                    });
                } else {
                    messenger.error('Unable to process your request. Please try again later');
                }
            });
        };
        
    };

})();
