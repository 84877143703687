app.config(function ($translateProvider) {
    'use strict';

    $translateProvider.translations('zh-hk', {
        USERNAME: '用戶名',
        PASSWORD: '密碼',
        SIGN_IN: '登入',
        PLEASE_LOGIN: '請登入',
        WELCOME_TO_ELOGBOOKS: '歡迎來到Elogs CAFM',
        INVALID_CREDENTIALS: '認證無效',
        FORGOT_YOUR_PASSWORD: '忘記密碼?',
        PASSWORD_REQUEST_SUBMITTED: '如果系統中存在該用戶，您將很快收到一封電子郵件，並附上重置密碼的說明',
        CHOOSE_YOUR_NEW_PASSWORD: '選擇你的新密碼',
        PASSWORDS_DO_NOT_MATCH: '輸入密碼無效',
        YOUR_PASSWORD_HAS_BEEN_CHANGED: '您的密碼已被更改',
        BACK_TO_LOGIN_PAGE: '返回登錄頁面',
        SET_MY_NEW_PASSWORD: '設置我的新密碼',
        NEW_PASSWORD: '新密碼',
        CONFIRM_NEW_PASSWORD: '確認新密碼',
        USER_AUTH_TOO_MANY_ATTEMPTS: '帳戶被鎖定',
        USER_AUTH_BAD_CREDENTIALS: '認證無效',
        USER_AUTH_FAIL: '認證您的帳戶時出現問題，請再試一次'
    });
});
