(function() {
    'use strict';

    angular
        .module('elogbooks.admin.pricing-schedules')
        .controller('PricingSchedulesMarkupController', PricingSchedulesMarkupController);

    PricingSchedulesMarkupController.$inject = ['pricingScheduleMarkupResponse'];

    function PricingSchedulesMarkupController(pricingScheduleMarkupResponse)
    {
        var vm = this;
        vm.pricingScheduleMarkupResponse = pricingScheduleMarkupResponse;

        vm.pricingScheduleMarkup = pricingScheduleMarkupResponse.pricingschedulemarkup[0];
        vm.markups = [
            { costType: 'SUBCONTRACTOR', value: vm.pricingScheduleMarkup.subContractorMarkup },
            { costType: 'MATERIAL', value: vm.pricingScheduleMarkup.materialMarkup },
            { costType: 'EQUIPMENT', value: vm.pricingScheduleMarkup.equipmentMarkup }
        ];
    }
})();
