
angular.module('elogbooksDirectives').directive('quoteRequestStatus', ['$translate', 'elbSettings', function ($translate, elbSettings) {
    return {
        restrict: 'E',
        scope: {
            quoteRequest: '='
        },
        templateUrl: '/modules/directives/quote-request-status/quote-request-status.html',
        controller: function($scope) {

            $scope.statusCounts = {};
            $scope.colourCounts = {
                grey: { count: 0, tooltipData: []},
                green: { count: 0, tooltipData: []},
                amber: { count: 0, tooltipData: []},
                red: { count: 0, tooltipData: []},
                blue: { count: 0, tooltipData: []}
            };

            const statusToColour = {
                'requested': 'grey',
                'accepted': 'green',
                'budget_quote_submitted': 'green',
                'budget_quote_converted': 'green',
                'submitted': 'amber',
                'information_requested': 'amber',
                'pending': 'amber',
                'extension_requested': 'amber',
                'cancelled': 'red',
                'abstained': 'red',
                'expired': 'red',
                'rejected': 'red',
                'value_pending_approval': 'blue',
                'revision_requested': 'amber'
            };

            $scope.quoteRequest._embedded.quotes.quotes.forEach(quote => {
                if (!$scope.statusCounts.hasOwnProperty(quote.status)) {
                    $scope.statusCounts[quote.status] = 0;
                }
                $scope.statusCounts[quote.status]++;
                const colour = statusToColour[quote.status] || "grey";
                $scope.colourCounts[colour].count++;
                const data = {
                    serviceProviderName: quote._links.serviceprovider.title,
                    status: $translate.instant(
                        "QUOTE_STATUS_" + quote.status.toUpperCase()
                    ),
                };
                $scope.colourCounts[colour].tooltipData.push(data);
            });

            if ($scope.statusCounts.accepted && $scope.statusCounts.accepted > 0) {
                $scope.finalStatus = 'accepted';
            }

            if ($scope.statusCounts.cancelled && $scope.statusCounts.cancelled === $scope.quoteRequest._embedded.quotes.quotes.length) {
                $scope.finalStatus = 'cancelled';
            }

            if ($scope.statusCounts.expired && $scope.statusCounts.expired === $scope.quoteRequest._embedded.quotes.quotes.length) {
                $scope.finalStatus = 'expired';
            }

            if ($scope.quoteRequest.status === 'converted') {
                $scope.finalStatus = 'budget_quote_converted';
            }

            const setting = elbSettings.getSetting('quote_value_approval_enabled');
            if (!(setting && setting.value) && $scope.colourCounts.blue.count === 0) {
                delete $scope.colourCounts.blue;
            }
        }
    };
}]);
