app.config( function($translateProvider) {
    $translateProvider.translations('zh-hk', {
        METER_SUPPLIER_INFORMATION: '儀表供應商資料',

        ADD_NEW_METER_SUPPLIER: '新增儀表供應商',
        EDIT_METER_SUPPLIER: '修改儀表供應商',

        METER_SUPPLIER_LIST: '儀表供應商',
        METER_SUPPLIER_ADD: '新增儀表供應商',
        METER_SUPPLIER_EDIT: '修改儀表供應商',

        METER_SUPPLIER_CREATED: '儀表供應商已建立',
        METER_SUPPLIER_UPDATED: '儀表供應商已更新',
        METER_SUPPLIER_DELETED: '儀表供應商已刪除',
    });
});
