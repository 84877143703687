(function () {
    'use strict';

    angular
        .module('elogbooks.admin.asset-types')
        .controller('AssetTypeAddEditController', AssetTypeAddEditController);

    AssetTypeAddEditController.$inject = ['$state', 'apiClient', 'messenger', 'assetTypeResponse', 'requestDataFactory', 'assetTypeCollectionResponse', '$translate', 'parentResponse', 'confirmationModal'];

    function AssetTypeAddEditController ($state, apiClient, messenger, assetTypeResponse, requestDataFactory, assetTypeCollectionResponse, $translate, parentResponse, confirmationModal) {
        var vm = this;
            vm.assetType = assetTypeResponse;
            vm.assetTypeCollectionResponse = assetTypeCollectionResponse;
            vm.data = requestDataFactory.createRequest(vm.assetType);

        angular.forEach(vm.assetTypeCollectionResponse.assettypes, function (assetType, $index) {
            assetType.value = assetType._links.self;
            assetType.title = assetType.name;
        });

        vm.assetTypeSelect = {
            response: vm.assetTypeCollectionResponse,
            link: assetTypeCollectionResponse._links.self.href,
            required: false,
            responseKeyPath: 'assettypes',
            itemHrefPath: '_links.self.href',
            itemValuePath: 'name',
            isHierarchical: true,
            onSelect: changeAssetType,
            onRemove: changeAssetType
        };

        vm.statusOptions = [
            { value: true, title: $translate.instant('STATUS_ACTIVE') },
            { value: false, title: $translate.instant('STATUS_INACTIVE') }
        ];

        vm.create = createAction;
        vm.update = updateAction;
        vm.delete = deleteAction;

        if (parentResponse) {
            vm.assetTypeSelect.selected = {
                href: parentResponse.getLink('self'),
                title: parentResponse.name,
                object: parentResponse
            };
            changeAssetType();
        }

        function createAction () {
            return apiClient.create(assetTypeCollectionResponse.getLink('create'), vm.data).then(function (response) {
                if (response) {
                    $state.go('^', {}, { reload: $state.current.parent }).then(function () {
                        messenger.success('ASSET_TYPE_ADDED');
                    });
                } else {
                    messenger.error('REQUEST_ERROR');
                }
            });
        }

        function updateAction () {
            return apiClient.update(vm.assetType.getLink('edit'), vm.data).then(function (response) {
                if (response) {
                    $state.go('^', {}, { reload: $state.current.parent }).then(function (response) {
                        messenger.success('ASSET_TYPE_UPDATED');
                    });
                } else {
                    messenger.error('REQUEST_ERROR');
                }
            });
        }

        function deleteAction () {
            return confirmationModal.open().result.then(function (response) {
                return apiClient.delete(vm.assetType.getLink('delete')).then(function (response) {
                    if (response) {
                        $state.go('dashboard.admin.assettypes.list', {}, { reload: 'dashboard.admin.assettypes.list' }).then(function () {
                            messenger.success('ASSET_TYPE_DELETED');
                        });
                    } else {
                        messenger.error('REQUEST_ERROR');
                    }
                });
            });
        }

        function changeAssetType () {
            vm.data._links.parent = undefined;
            if (vm.assetTypeSelect.selected) {
                vm.data._links.parent = {href: vm.assetTypeSelect.selected.href};
            }
        }
    }
})();
