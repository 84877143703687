angular.module('elogbooksServices').service('daysOfWeek', function($filter, lodash) {

    this.getAll = getAll;
    this.getDayName = getDayName;
    this.getAllWithNoneSelected = getAllWithNoneSelected;
    this.getAllForSelect = getAllForSelect;

    var daysOfWeek = [
        { label: 1, name: $filter('translate')("BYDAY_MONDAY"), title: $filter('translate')("BYDAY_MONDAY") },
        { label: 2, name: $filter('translate')("BYDAY_TUESDAY"), title: $filter('translate')("BYDAY_TUESDAY") },
        { label: 3, name: $filter('translate')("BYDAY_WEDNESDAY"), title: $filter('translate')("BYDAY_WEDNESDAY") },
        { label: 4, name: $filter('translate')("BYDAY_THURSDAY"), title: $filter('translate')("BYDAY_THURSDAY") },
        { label: 5, name: $filter('translate')("BYDAY_FRIDAY"), title: $filter('translate')("BYDAY_FRIDAY") },
        { label: 6, name: $filter('translate')("BYDAY_SATURDAY"), title: $filter('translate')("BYDAY_SATURDAY") },
        { label: 7, name: $filter('translate')("BYDAY_SUNDAY"), title: $filter('translate')("BYDAY_SUNDAY") },
    ];

    function getAll() {
        return daysOfWeek;
    }

    function getAllForSelect() {
        return lodash.transform(daysOfWeek,
            function (result, n) {
                result.push({value: n.label, title: n.name});
            });
    }

    function getAllWithNoneSelected() {
        var days = [ { value: null, name: $filter('translate')('NONE_SELECTED'), title: $filter('translate')('NONE_SELECTED') } ];
        return days.concat(daysOfWeek);

    }

    function getDayName(value) {
        if (value < 1 || value > 7) {
            return null;
        }
        return daysOfWeek[value - 1].name;
    }

    return this;
});
