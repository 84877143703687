(function() {
    'use strict';

    angular
        .module('elogbooks.common.actions')
        .controller('CommonActionsController', CommonActionsController);

        CommonActionsController.$inject = ['sectionResource'];

    function CommonActionsController(sectionResource) {
        var vm = this;
        vm.sectionResource = sectionResource;
    }
})();