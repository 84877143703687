(function () {
    'use strict';

    angular
        .module('elogbooks.user.finance.purchase-orders')
        .controller('FinancePurchaseOrdersDetailsPurchaseInvoicesController', FinancePurchaseOrdersDetailsPurchaseInvoicesController);

    FinancePurchaseOrdersDetailsPurchaseInvoicesController.$inject = ['purchaseInvoicesCollectionResponse'];

    function FinancePurchaseOrdersDetailsPurchaseInvoicesController(purchaseInvoicesCollectionResponse) {
        var vm = this;

        vm.purchaseInvoicesCollectionResponse = purchaseInvoicesCollectionResponse;
        vm.invoices = purchaseInvoicesCollectionResponse.invoices;
    }
})();
