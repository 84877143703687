(function() {
    'use strict';

    angular
        .module('elogbooks.user.meter-units')
        .config(registerRoutes)
        .config(registerRoutesList)
        .config(registerRoutesAdd)
        .config(registerRoutesDetails)
        .config(registerRoutesEdit);

    function registerRoutes($stateProvider) {
        $stateProvider
            .state('dashboard.user.meters.units', {
                abstract: true,
                template: '<bookerize></bookerize>',
                ncyBreadcrumb: {
                    skip: true
                }
            });
    }

    function registerRoutesList($stateProvider) {
        $stateProvider
            .state('dashboard.user.meters.units.list', {
                url: '/meter-units?page&limit&order&id&name',
                parent: 'dashboard.user.meters.units',
                views: parseBook({
                    'elogbooks-left@dashboard.user.meters.units': {
                        templateUrl: '/modules/user/meters/meter-units/meter-units-list.html',
                        controller: 'MeterUnitsController',
                        controllerAs: 'vm'
                    }
                }),
                params: {
                    page: '1',
                    limit: '30',
                    order: 'id',
                    id: null,
                    name: null
                },
                resolve: {
                    meterUnitsCollectionResponse: function($stateParams, apiClient, rootResourceResponse) {
                        return apiClient.get(rootResourceResponse.getLink('meterunits'), $stateParams).then( function(response) {
                            return response || apiClient.noResourceFound();
                        });
                    }
                },
                ncyBreadcrumb: {
                    label: '{{ ::"METER_UNITS_LIST" | translate }}'
                }
            });
    }

    function registerRoutesAdd($stateProvider) {
        $stateProvider
            .state('dashboard.user.meters.units.list.add', {
                url: '/add',
                parent: 'dashboard.user.meters.units.list',
                views: parseBook({
                    'elogbooks-right@dashboard.user.meters.units': {
                        templateUrl: '/modules/user/meters/meter-units/add-edit/meter-unit-add-edit.html',
                        controller: 'MeterUnitAddEditController',
                        controllerAs: 'vm'
                    }
                }),
                resolve: {
                    meterUnitResponse: function() {
                        return null;
                    }
                },
                ncyBreadcrumb: {
                    label: '{{ ::"ADD" | translate }}'
                }
            });
    }

    function registerRoutesDetails($stateProvider) {
        $stateProvider
            .state('dashboard.user.meters.units.list.details', {
                url: '/details/{resource}',
                abstract: true,
                parent: 'dashboard.user.meters.units.list',
                views: parseBook({
                    'elogbooks-right@dashboard.user.meters.units': {
                        templateUrl: '/modules/user/meters/meter-units/details/meter-unit-details.html',
                        controller: 'MeterUnitDetailsController',
                        controllerAs: 'vm'
                    }
                }),
                resolve: {
                    meterUnitResponse: function(apiClient, $stateParams) {
                        return apiClient.get($stateParams.resource.decode()).then( function(response) {
                            return response || apiClient.noResourceFound();
                        });
                    }
                },
                ncyBreadcrumb: {
                    label: '{{ ::$resolves().meterUnitResponse.name }}'
                }
            })
             .state('dashboard.user.meters.units.list.details.info', {
                url: '/info',
                parent: 'dashboard.user.meters.units.list.details',
                views: parseBook({
                    'elogbooks-right@dashboard.user.meters.units.list.details': {
                        templateUrl: '/modules/user/meters/meter-units/details/info/meter-unit-info.html',
                        controller: 'MeterUnitInfoController',
                        controllerAs: 'vm'
                    }
                }),
                ncyBreadcrumb: {
                    label: '{{ ::"INFO" | translate }}'
                }
            });
    }

    function registerRoutesEdit($stateProvider) {
        $stateProvider
            .state('dashboard.user.meters.units.list.details.info.edit', {
                url: '/edit',
                parent: 'dashboard.user.meters.units.list.details.info',
                views: parseBook({
                    'elogbooks-right@dashboard.user.meters.units': {
                        templateUrl: '/modules/user/meters/meter-units/add-edit/meter-unit-add-edit.html',
                        controller: 'MeterUnitAddEditController',
                        controllerAs: 'vm'
                    }
                }),
                ncyBreadcrumb: {
                    label: '{{ ::"EDIT" | translate }}'
                }
            });
    }

})();
