app.config(function ($translateProvider) {
    $translateProvider.translations('en-gb', {
        ABSENCE_PLANNERS: 'Absence Planners',
        ABSENCE_PLANNER: 'Absence Planner',
        ABSENCE_PLANNER_LIST: 'Absence Planner',

        TYPE_HOLIDAY: 'Holiday',
        TYPE_SICKNESS: 'Sickness',
        TYPE_UNAUTHORISED: 'Unauthorised',
        TYPE_MATERNITY_PATERNITY_LEAVE: 'Maternity/Paternity leave',
        TYPE_COMPASSIONATE: 'Compassionate',
        TYPE_OTHER: 'Other',

        ABSENCE_PLANNER_ADD: 'Add Absence',
        ABSENCE_SUMMARY: 'Absence Summary',
        MORNING_AFTERNOON: 'Morning/Afernoon',
        LENGTH_OF_ABSENCE: 'Length of Absence (days)',
        ABSENCE_PLANNER_EDIT: 'Edit Absence Planner',
        ABSENCE_PLANNER_CREATED: 'Absence Planner Created',
        ABSENCE_PLANNER_UPDATED: 'Absence Planner Updated',
        END_DATE_MUST_BE_HIGHER_THAN_START_DATE: 'End Date must be higher than Start Date',
        RETURN_DATE: 'Return Date',

        USER_MUST_BELONG_TO_A_DEPARTMENT: 'User must belong to a Department',
        ABSENCE_ENTRY_VIEW: 'Absence Entry',
        ABSENCE_ENTRY: 'Absence Entry',
        ABSENCE: 'Absence',
        NUMBER_OF_DAYS: 'Number of Days',
        REQUESTED_AT: 'Requested At',
        USER_SUMMARY_VIEW: 'User Summary',
        TAKEN: 'Taken',
        REMAINING: 'Remaining',
        ABSENCE_PERIOD_FOR_CHOSEN_DATES_ALREADY_REQUESTED: 'Absence period for chosen dates already requested',
        ABSENCE_ENTRY_WORKFLOW_APPROVE_SUCCESS: 'Absence Approved',
        ABSENCE_ENTRY_WORKFLOW_REJECT_SUCCESS: 'Absence Rejected',

        // Events
        ABSENCE_ENTRY_CREATED_ACTION: '<strong>{{ event._links["user"].title }}</strong> requested absence',
        ABSENCE_ENTRY_EDITED_ACTION: '<strong>{{ event._links["user"].title }}</strong> edited the absence',
        ABSENCE_ENTRY_APPROVED_ACTION: '<strong>{{ event._links["user"].title }}</strong> approved the absence',
        ABSENCE_ENTRY_REJECTED_ACTION: '<strong>{{ event._links["user"].title }}</strong> rejected the absence'

    });
});
