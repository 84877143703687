(function () {
    'use strict';

    angular
        .module('elogbooks.admin.billing-types')
        .controller('BillingTypesController', BillingTypesController);

    BillingTypesController.$inject = ['$state', '$stateParams', 'billingTypesCollectionResponse', 'userManager', 'apiClient', 'messenger'];

    function BillingTypesController ($state, $stateParams, billingTypesCollectionResponse, userManager, apiClient, messenger) {
        var vm = this;
        vm.billingTypes = billingTypesCollectionResponse.billingTypes;
        vm.billingTypeCollectionResponse = billingTypesCollectionResponse;
        vm.pages = vm.billingTypeCollectionResponse.pages;
        vm.order = order;
        vm.search = search;
        vm.userManager = userManager;
        vm.updateDefault = updateDefault;
        vm.criteria = {
            id: { type: 'text', title: 'ID', value: $stateParams.id },
            name: { type: 'text', title: 'NAME', value: $stateParams.name }
        };

        angular.forEach(vm.billingTypes, function(value){
            if(value.isDefault) {
                vm.defaultBillingType = value;
            }
        });

        function order (key) {
            $stateParams.order = key;

            $state.go('.', angular.extend({}, $stateParams), { reload: true });
        }

        function search (params) {
            var override = {
                page: 1
            };

            $state.go('.', angular.extend({}, params, override), { reload: true });
        }

        function updateDefault(billingType) {
            return apiClient.update(billingType.getLink('self') + '/update-default', billingType).then(function (response) {
                if (!response) {
                    messenger.error('REQUEST_ERROR');
                }

                $state.go($state.current, {}, {reload: true}).then(function() {
                    messenger.success('BILLING_TYPE_UPDATED');
                });
            });
        }
    }
})();
