(function () {
    'use strict';

    angular
        .module('elogbooks.user.sites')
        .controller('UserSitesInfoController', UserSitesInfoController);

    UserSitesInfoController.$inject = ['siteResponse', 'siteFilesCollectionResponse','imageModal', 'lightBoxModal', '$uibModal'];

    function UserSitesInfoController(siteResponse, siteFilesCollectionResponse, imageModal, lightBoxModal, $uibModal) {
        var vm = this;
        vm.site = siteResponse;
        vm.file_data = siteFilesCollectionResponse.files;
        vm.files = [];
        vm.upload = upload;
        vm.lightBox = lightBox;
        vm.openSiteOccupancyHeadcountModal = openSiteOccupancyHeadcountModal;
        vm.modalData = {};
        vm.isSite = true;
        vm.address = siteResponse.contactInformation.addresses[0];

        if (vm.file_data.length > 0) {
            vm.defaultImage = vm.file_data[0]._links.resource ? vm.file_data[0]._links.resource.href : undefined;
        }

        if (vm.site._links.hasOwnProperty('images')) {
            vm.editable = vm.site._links.images.canCreate;
        }

        angular.forEach(vm.file_data, function(value, key) {
            if (value._links.resource) {
                if (value.defaultSiteImage) {
                    vm.defaultImage = value._links.resource.href;
                    vm.defaultImageIndex = key;
                    vm.modalData ={
                        href: value._links.resource.href,
                        title:value._links.resource.title
                    };
                }
                vm.files.push({
                    siteDefault: value.defaultSiteImage || null,
                    file_name: value._links.resource ? value._links.resource.title : null,
                    href: value._links.resource ? value._links.resource.href: null,
                    caption: value.caption
                });
            }
        });

        function lightBox(index) {
            vm.modalData = {
                href: vm.files[index].href,
                title:vm.files[index].title,
                caption:vm.files[index].caption
            };
            lightBoxModal.open(vm.modalData);
        }

        function upload() {
            imageModal.open(vm.file_data, vm.site, true, true);
        }

        function openSiteOccupancyHeadcountModal() {
            $uibModal.open({
                templateUrl: '/modules/user/sites/modal/occupancy-headcount-modal.html',
                controller: 'UserSiteOccuapncyHeadcountModalController',
                controllerAs: 'vm',
                resolve: {
                    site: function () {
                        return siteResponse;
                    }
                }
            });
        }
    }
})();
