(function() {
    'use strict';

    angular
        .module('elogbooks.common.quote-requests')
        .controller('CommonQuoteRequestAddDetailsController', CommonQuoteRequestAddDetailsController);

    CommonQuoteRequestAddDetailsController.$inject = [
        '$stateParams',
        '$rootScope',
        '$scope',
        '$state',
        'apiClient',
        'partnershipCollectionResponse',
        'priorityCollectionResponse',
        'priorityService',
        'siteResponse',
        'crudService',
        'locationCollectionResponse',
        'messenger',
        '$translate',
        'config',
        'confirmationModal',
        'lodash',
        'quoteCreateService',
        'serviceProviderService',
        'userOperativeCollectionResponse',
        'actionFilesCollection',
        'sourceQuoteRequestResponse',
        'sourceQuoteRequestFilesResponse'
    ];

    function CommonQuoteRequestAddDetailsController(
        $stateParams,
        $rootScope,
        $scope,
        $state,
        apiClient,
        partnershipCollectionResponse,
        priorityCollectionResponse,
        priorityService,
        siteResponse,
        crudService,
        locationCollectionResponse,
        messenger,
        $translate,
        config,
        confirmationModal,
        lodash,
        quoteCreateService,
        serviceProviderService,
        userOperativeCollectionResponse,
        actionFilesCollection,
        sourceQuoteRequestResponse,
        sourceQuoteRequestFilesResponse
    ) {
        $scope.form.$setPristine();
        $rootScope.forceDirtyConfirm = true;

        var vm = $scope.vm;
        vm.canNext = false;
        vm.canPrevious = true;
        vm.canSubmit = true;
        vm.showAssignToSelf = false;
        vm.noteConfig = config;

        vm.data.isByPlannedDate = false;
        vm.serviceProviderService = serviceProviderService;
        vm.title = $stateParams.budgetQuoteConversion ? 'QUOTE_WORKFLOW_REQUEST_FULL_QUOTE': 'QUOTE_REQUEST_ADD_DETAILS';

        vm.actionFilesCollection = actionFilesCollection ? actionFilesCollection : null;
        vm.actionFiles = [];
        vm.showActionFiles = false;
        vm.disableActionFilesButton = false;

        // If all files are expired do not show the button.
        vm.allFilesExpired = actionFilesCollection
            ? actionFilesCollection.files.every(file => file.expiresAt !== null && new Date(file.expiresAt) < new Date())
            : false;

        vm.partnershipCollectionResponse = partnershipCollectionResponse;
        vm.priorityCollectionResponse = priorityCollectionResponse;
        vm.locationCollectionResponse = locationCollectionResponse;
        vm.userOperativeCollectionResponse = userOperativeCollectionResponse;
        vm.sourceQuoteRequestResponse = sourceQuoteRequestResponse;
        vm.selectedServiceProviders = [];
        vm.removeServiceProvider = removeServiceProvider;
        vm.previous = previousAction;
        vm.submit = submitAction;
        vm.includeFilesFromAction = includeFilesFromAction;
        vm.removeActionFile = removeActionFile;
        vm.checkCompletionDate = checkCompletionDate;

        var priorityLinkParameters = {'active': true, 'entityTypes': 'entity_type_all,entity_type_quote'};

        vm.selectedPriorityModel = {
            response: priorityService.addExtraPriorities(priorityCollectionResponse, ['byPlannedDate']),
            link: null,
            linkParameters: priorityLinkParameters,
            required: true,
            responseKeyPath: 'priorities',
            itemHrefPath: '_links.self.href',
            itemValuePath: 'name',
            onSelect: changePriority,
            onRemove: changePriority
        };

        vm.selectedServiceProviderModel = {
            response: partnershipCollectionResponse,
            link: partnershipCollectionResponse.getLink('self'),
            linkParameters: {active:1},
            required: false,
            removeSelected: true,
            selectedItems: [],
            responseKeyPath: 'partnerships',
            itemHrefPath: '_links.serviceprovider.href',
            itemValuePath: '_links.serviceprovider.title',
            onSelect: changeServiceProvider,
            onRemove: changeServiceProvider,
            formatValue: serviceProviderService.formatServiceProviderName,
            headers: serviceProviderService.isProsureEnabled ? serviceProviderService.prosureStatusHeaders : null,
            reorder: serviceProviderService.prosureGroupReorder
        };

        if (serviceProviderService.isProsureEnabled) {
            vm.selectedServiceProviderModel.loadmoreEnabled = true;
        }

        if (! vm.siteNotesConfirmed) {
            $rootScope.skipDirtyCheck = true;
            $state.go(vm.getRoute('site'), {}, { reload: false });
        }

        function changePriority() {
            quoteCreateService.changePriority(
                vm.selectedPriorityModel,
                vm.data,
                $translate.instant('BY_PLANNED_DATE'),
                confirmationModal
            );
        }

        if ($stateParams.budgetQuoteConversion && vm.sourceQuoteRequestResponse) {
            if (vm.sourceQuoteRequestResponse._links.location) {
                vm.location = vm.data._links.location = vm.sourceQuoteRequestResponse._links.location;
            }

            vm.data.summary = vm.sourceQuoteRequestResponse.summary;
            vm.data.description = vm.sourceQuoteRequestResponse.description;

            const partnerships = partnershipCollectionResponse.partnerships.filter(
                partnership => $stateParams.budgetQuoteServiceProvidersLinks.includes(partnership._links.serviceprovider.href)
            );

            angular.forEach(partnerships, function (partnership) {
                vm.selectedServiceProviderModel.selected = {
                    title: partnership._links.serviceprovider.title,
                    value: partnership._links.serviceprovider.title,
                    object: partnership
                };

                changeServiceProvider();
            });

            if (sourceQuoteRequestFilesResponse?.files) {
                sourceQuoteRequestFilesResponse.files.forEach(file => {
                    const isFileExpired = file.expiresAt && new Date(file.expiresAt) < new Date();

                    if (!isFileExpired) {
                        const {title, href} = file._links.resource || {};

                        if (title && href) {
                            vm.data.files.links.push({
                                _links: {resource: {href: href, title: title}}
                            });
                        }
                    }
                });
            }
        }

        function changeServiceProvider() {
            quoteCreateService.changeServiceProvider(
                vm.selectedServiceProviderModel,
                vm.selectedServiceProviders
            );
            checkAssignToSelf();
            vm.selectedServiceProviderModel.selectedItems = vm.selectedServiceProviders;
        }

        function removeServiceProvider(serviceProvider){
            quoteCreateService.removeServiceProvider(
                serviceProvider,
                vm.selectedServiceProviders
            );
            checkAssignToSelf();
            vm.selectedServiceProviderModel.selectedItems = vm.selectedServiceProviders;
            vm.selectedServiceProviderModel.init();
        }

        function checkAssignToSelf() {
            if (
                $stateParams.isRemedial &&
                vm.selectedServiceProviders.length > 0 &&
                vm.userOperativeCollectionResponse.operatives &&
                vm.userOperativeCollectionResponse.operatives.length
            ) {
                var showAssignToSelf = true;
                angular.forEach(vm.selectedServiceProviders, function (serviceProvider) {
                    var serviceProviderOperative = lodash.find(vm.userOperativeCollectionResponse.operatives, function (operative) {
                        return serviceProvider.getLink('serviceprovider') === operative.getLink('serviceprovider')
                    });
                    if (serviceProviderOperative) {
                        var permissions = lodash.concat(
                            serviceProviderOperative.permissionDependencies,
                            serviceProviderOperative.permissions,
                            serviceProviderOperative.permissionsInherited,
                            serviceProviderOperative.permissionsInheritedDependencies
                        );
                        if (!permissions.includes('operative_permission_receive_quotes')) {
                            showAssignToSelf = false;
                        }
                    } else {
                        showAssignToSelf = false;
                    }
                });
                if (showAssignToSelf) {
                    vm.showAssignToSelf = true;
                    vm.data.assignToMe = true;
                } else {
                    vm.showAssignToSelf = false;
                    delete vm.data.assignToMe;
                }
            } else {
                vm.showAssignToSelf = false;
                delete vm.data.assignToMe;
            }
        }

        function includeFilesFromAction() {
            if (actionFilesCollection && actionFilesCollection.count > 0) {
                angular.forEach(actionFilesCollection.files, function(file) {
                    const isFileExpired = file.expiresAt !== null && new Date(file.expiresAt) < new Date();
                    if (!vm.actionFiles.some(element => element.id === file.id) && !isFileExpired) {
                        const resource = file._links.resource;
                        if (resource) {
                            vm.actionFiles.push({
                                id: file.id,
                                submitted: false,
                                title: resource.title,
                                type: resource.type,
                                href: resource.href
                            });
                            vm.showActionFiles = true;
                            vm.disableActionFilesButton = true;
                        }
                    }
                });
            }
        }

        function removeActionFile(file) {
            vm.actionFiles = vm.actionFiles.filter(element => element.id !== file.id);
            vm.disableActionFilesButton = false;
        }

        function submitAction() {
            if (vm.data.completionDueAt < new Date()) {
                messenger.error('COMPLETION_DATE_CANNOT_BE_IN_PAST');

                return;
            }

            vm.data.serviceProviders.links = [];
            angular.forEach(vm.selectedServiceProviders, function(value, index) {
                vm.data.serviceProviders.links.push({
                    href: value.getLink('serviceprovider')
                });
            });

            if ($stateParams.fromJob !== null) {
                vm.data._links.jobFrom = {
                    href:$stateParams.fromJob.decode()
                };
            }

            if ($stateParams.isRemedial !== null) {
                vm.data.isRemedial = $stateParams.isRemedial;
            }

            if ($stateParams.budgetQuoteConversion !== null) {
                vm.data.budgetQuoteConversion = true;
                vm.data.convertedFromQuoteRequest = $stateParams.sourceQuoteRequest;
            }

            if ($stateParams.sourceJob !== null) {
                vm.data._links.sourceJob = { href: $stateParams.sourceJob.decode() };
            }

            if (vm.actionFiles.length > 0) {
                angular.forEach(vm.actionFiles, function(file) {
                    if (!file.submitted) {
                        file.submitted = true;
                        vm.data.files.links.push({
                            title: file.title,
                            type: file.type,
                            href: file.href
                        });
                    }
                })
            }

            if (vm.data.serviceProviders.links.length > 0) {
                crudService.create(
                    vm.config.createLink,
                    vm.data,
                    null,
                    null,
                    null,
                    true
                ).then(function(response) {
                    if (response) {
                        var listState = $state.current.name.substring(0, $state.current.name.lastIndexOf('.list') + 5);
                        if ($stateParams.isRemedial) {
                            var successRoute = '', params = {};

                            if ($state.current.name.indexOf('dashboard.user.sites.list') > -1) {
                                successRoute = 'dashboard.user.sites.list.details.jobs.list.details.remedials';
                                params.resource = siteResponse.getLink('self').encode();
                                params.jobResource = $stateParams.sourceJob;
                            } else {
                                successRoute = 'dashboard.user.jobs.list.details.remedials';
                                params.resource = $stateParams.sourceJob;
                            }

                            $state.go(successRoute, params, { reload: successRoute }).then(function() {
                                messenger.success('QUOTE_REQUEST_CREATED');
                            });
                        } else if (vm.data.serviceProviders.links.length === 1) {
                            $state.go(listState + '.details.quote.workflow', {quoteResource: response._embedded.quotes.quotes[0].getLink('self').encode()}, { reload: listState }).then(function() {
                                messenger.success('QUOTE_REQUEST_CREATED');
                            });
                        } else {
                            $state.go(listState, {}, { reload: listState }).then(function() {
                                messenger.success('QUOTE_REQUEST_CREATED');
                            });
                        }
                    }
                });
            }
        }

        function previousAction() {
            var redirectRoute = vm.config.addReporter && !$stateParams.budgetQuoteConversion ? vm.getRoute('contact') : vm.getRoute('site');

            $rootScope.skipDirtyCheck = true;

            $state.go(redirectRoute, {}, {reload: false}).then(function () {
                vm.submitDisabled = false;
            });
        }

        function checkCompletionDate() {
            if (!vm.data.completionDueAt) {
                return true;
            }

            if (vm.data.completionDueAt && vm.data.completionDueAt < new Date()) {
                return true;
            }

            return false;
        }

        vm.locationNotesConfirmed = [];

        $scope.$on('location:select:click', function(event) {
            if (vm.noteConfig.showSiteNotes) {
                var selectedIndex = null;
                var selected = null;

                lodash.find(event.targetScope.locationFormFields, function(item, index) {
                    if (item.selected._links.self.href === event.targetScope.locationFormField.selected) {
                        selectedIndex = index;
                        selected = item.selected;
                    }
                });

                //if location is different prompt notes again
                if (vm.data._links.location && (vm.data._links.location.href !== selected._links.self.href)) {
                    vm.locationNotesConfirmed[selectedIndex] = false;
                }

                if (!vm.locationNotesConfirmed[selectedIndex] && selected && selected.getLink('notes')) {
                    apiClient.get(selected.getLink('notes'), {allowHelpdesk: true}).then(function (noteResponse) {
                        if (noteResponse && noteResponse.locationNotes.length > 0) {
                            var config = {
                                primaryClass: 'btn-primary',
                                primaryText: 'OK',
                                secondaryText: 'CANCEL',
                                titleMessage: 'LOCATION_NOTES',
                                template: '/modules/common/site/notes.html',
                                data: noteResponse.locationNotes
                            };

                            return confirmationModal.open(config).result.then(function() {
                                vm.locationNotesConfirmed[selectedIndex] = true;
                            }, function() {
                                //clear the location selection
                                event.targetScope.locationFormFields[selectedIndex].selected = null;
                                delete event.targetScope.locationFormFields[selectedIndex+1];

                                return;
                            });
                        }
                    });
                }
            }
        });

        $scope.$on('location:select:clear', function(event, index) {
            delete vm.data._links.location;
            vm.location = null;
            vm.locationNotesConfirmed[index] = false;
        });
    }
})();
