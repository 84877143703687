(function() {
    'use strict';

    angular
        .module('elogbooks.common.quote-requests')
        .controller('QuoteAcceptanceAcceptValuesController', QuoteAcceptanceAcceptValuesController);

    QuoteAcceptanceAcceptValuesController.$inject = [
        '$state',
        'quoteResponse',
        'quoteOptionCollectionResponse',
        'apiClient',
        'messenger'
    ];

    function QuoteAcceptanceAcceptValuesController(
        $state,
        quoteResponse,
        quoteOptionCollectionResponse,
        apiClient,
        messenger
    ) {
        var vm = this;
        vm.quoteResponse = quoteResponse;
        vm.quoteOptionCollectionResponse = quoteOptionCollectionResponse;
        vm.toggleDetails = toggleDetails;
        vm.hideDetails = hideDetails;
        vm.canSubmit = canSubmit;
        vm.submit = submitAction;

        vm.data = {options: []};

        angular.forEach(quoteOptionCollectionResponse.quoteoptions, function(value, $index) {
            vm.data.options[$index] = {
                _links: {
                    quoteoption: {
                        href: value._links.self.href
                    }
                },
                accepted: false,
                hideDetails: true,
            };
        });

        if (vm.data.options.length == 1) {
            vm.data.options[0].accepted = true;
            vm.data.options[0].hideDetails = false;
        }

        function canSubmit() {
            var accepted = false;
            angular.forEach(vm.data.options, function(value) {
                if (value.accepted) {
                    accepted = true;
                }
            });

            return accepted;
        }

        function toggleDetails(option) {
            option.hideDetails = !option.hideDetails;
        }

        function hideDetails(option) {
            option.hideDetails = true;
        }

        function submitAction() {
            apiClient.create(quoteResponse.getLink('accept-values'), vm.data).then(function (response) {
                if (response) {
                    messenger.success('QUOTE_VALUES_ACCEPTED');
                    $state.go('^', {}, {reload: $state.get('^.^.^.^')});
                } else {
                    messenger.error('REQUEST_ERROR');
                }
            });
        }
    }
})();
