(function() {
    'use strict';

    angular
        .module('elogbooks.admin.core-clients')
        .controller('CoreClientsDetailsController', CoreClientsDetailsController);

    CoreClientsDetailsController.$inject = ['coreClientResponse'];
    function CoreClientsDetailsController(coreClientResponse) {
        var vm = this;
        vm.coreClient = coreClientResponse;
    }
})();
