angular.module('elogbooksDirectives').directive('asset', ['apiClient', function (apiClient) {
    return {
        require: ['ngModel'],
        scope: {
            asset: '=ngModel',
            required: '=ngRequired'
        },
        templateUrl: '/modules/directives/form-elements/asset/asset.html',
        link: function(scope){
            scope.options = [];
            apiClient.get('/assets?page=1&limit=999999').then(function(Response) {
                scope.options = Response.getData().assets;
            });
        }
    }
}]);
