(function() {
    'use strict';

    angular
        .module('elogbooks.user.finance.credit-notes')
        .controller('FinanceCreditNoteStatusController', FinanceCreditNoteStatusController);

    FinanceCreditNoteStatusController.$inject = [
        'creditNoteResponse'
    ];

    function FinanceCreditNoteStatusController(
        creditNoteResponse
    ) {
        var vm = this;
        vm.creditNote = creditNoteResponse;
    }
})();
