(function() {
    'use strict';

    angular
        .module('elogbooks')
        .config(Translations);

    function Translations($translateProvider) {
        $translateProvider.translations('zh-hk', {

            QUOTE: '報價',
            QUOTES: '報價',

            QUOTE_WITH_ID: '報價 {{ id }}',

            QUOTE_LIST: '報價',
            QUOTE_VIEW: '報價',

            QUOTE_SUMMARY: '報價總結',
            QUOTE_DESCRIPTION: '報價描述',
            QUOTE_PRIORITY: '報價優先權',

            QUOTE_ADD: '新增報價',
            QUOTE_CREATED: '報價已建立',
            QUOTE_UPDATED: '報價已更新',
            QUOTE_DELETED: '報價已刪除',

            QUOTE_ID: '報價 {{ id }}',
            QUOTE_DETAILS: '報價詳細資料',
            QUOTE_STATUS: '狀態',
            QUOTE_CONTACTS: '報價聯絡人',

            QUOTE_AUDIT_TRAIL_SENT: '審計跟踪已發送',

            QUOTE_CONTACT: '報價聯絡人',

            QUOTE_CONTACT_LIST: '報價聯絡人',
            QUOTE_CONTACT_ADD: '增加',

            QUOTE_CONTACT_CREATED: '報價聯絡人已建立',
            QUOTE_CONTACT_UPDATED: '報價聯絡人已更新',
            QUOTE_CONTACT_DELETED: '報價聯絡人已刪除',

            QUOTE_WORKFLOW_ABSTAIN: '拒絕報價',
            QUOTE_WORKFLOW_ACCEPT: '接受',
            QUOTE_WORKFLOW_CANCEL: '取消',
            QUOTE_WORKFLOW_HOLD: '等候',
            QUOTE_WORKFLOW_REJECT: '拒絕',
            QUOTE_WORKFLOW_REQUEST_MORE_INFORMATION: '要求更多資料',
            QUOTE_WORKFLOW_SUBMIT: '遞交',
            QUOTE_WORKFLOW_REOPEN: '再開啟',
            QUOTE_WORKFLOW_ESCALATE: '上報',

            QUOTE_WORKFLOW_ABSTAIN_SUBMIT: '拒絕',
            QUOTE_WORKFLOW_ACCEPT_SUBMIT: '接受報價',
            QUOTE_WORKFLOW_CANCEL_SUBMIT: '取消報價',
            QUOTE_WORKFLOW_HOLD_SUBMIT: '等候',
            QUOTE_WORKFLOW_REJECT_SUBMIT: '拒絕報價',
            QUOTE_WORKFLOW_REQUEST_MORE_INFORMATION_SUBMIT: '要求更多資料',
            QUOTE_WORKFLOW_SUBMIT_SUBMIT: '遞交報價',
            QUOTE_WORKFLOW_REOPEN_SUBMIT: '再開啟',
            QUOTE_WORKFLOW_ESCALATE_SUBMIT: '上報',
            QUOTE_WORKFLOW_ESCALATE_APPROVAL_SUBMIT: '上報',

            QUOTE_WORKFLOW_SUBMIT_SUCCESS: '報價已遞交',

            QUOTE_WORKFLOW_ALARM: '修改警報',
            QUOTE_WORKFLOW_ALARM_SUCCESS: '警報已更新',
            QUOTE_WORKFLOW_ALARM_SUBMIT: '更新',

            QUOTE_WORKFLOW_ALARM_SETNEW: '設定警報',
            QUOTE_WORKFLOW_ALARM_SETNEW_SUCCESS: '警報設定',
            QUOTE_WORKFLOW_ALARM_SETNEW_SUBMIT: '設定',

            QUOTE_WORKFLOW_REJECT_EXTENSION: '拒絕延期',
            QUOTE_WORKFLOW_REJECT_EXTENSION_SUBMIT: '拒絕',
            QUOTE_WORKFLOW_ACCEPT_EXTENSION: '接受延期',
            QUOTE_WORKFLOW_ACCEPT_EXTENSION_SUBMIT: '接受',
            QUOTE_WORKFLOW_REQUEST_EXTENSION: '要求延期',
            QUOTE_WORKFLOW_REQUEST_EXTENSION_SUBMIT: '遞交',
            QUOTE_WORKFLOW_ACCEPT_EXTENSION_SUCCESS: '報價延期已接受',
            QUOTE_WORKFLOW_REJECT_EXTENSION_SUCCESS: '報價延期已拒絕',
            QUOTE_WORKFLOW_REQUEST_EXTENSION_SUCCESS: '報價延期已要求',
            REQUESTED_EXTENSION: '要求延期',
            QUOTE_WORKFLOW_ESCALATE_APPROVAL_SUCCESS: '報價審批上報',

            QUOTE_STATUS_CREATED: '已建立',
            QUOTE_STATUS_REQUESTED: '已要求',
            QUOTE_STATUS_SUBMITTED: '待定審批',
            QUOTE_STATUS_ACCEPTED: '已接受',
            QUOTE_STATUS_REJECTED: '已拒絕',
            QUOTE_STATUS_CANCELLED: '已取消',
            QUOTE_STATUS_EXPIRED: '已過期',
            QUOTE_STATUS_ABSTAINED: '拒絕報價',
            QUOTE_STATUS_PENDING: '等候',
            QUOTE_STATUS_INFORMATION_REQUESTED: '已要求更多資料',
            QUOTE_STATUS_EXTENSION_REQUESTED: '延期已要求',

            QUOTE_CREATED_ACTION: '<strong>{{ ::event._links["user"].title }}</strong> 已建立報價',
            QUOTE_SUBMITTED_ACTION: '<strong>{{ ::event._links["user"].title }}</strong> 已遞交報價.',
            QUOTE_MORE_INFORMATION_REQUESTED_ACTION: '<strong>{{ ::event._links["user"].title }}</strong> 已要求更多資料.',
            QUOTE_ABSTAINED_ACTION: '<strong>{{ ::event._links["user"].title }}</strong> 拒絕報價',
            QUOTE_REOPENED_ACTION: '<strong>{{ ::event._links["user"].title }}</strong> 再開啟報價.',
            QUOTE_PUT_ON_HOLD_ACTION: '<strong>{{ ::event._links["user"].title }}</strong> 把報價擱置.',
            QUOTE_APPROVAL_RESENT_ACTION: '<strong>{{ ::event._links["user"].title }}</strong> 再發送審批要求給 {{ ::event._links["approver"].title }}.',
            QUOTE_APPROVAL_ESCALATED_ACTION: '<strong>{{ ::event._links["user"].title }}</strong> 上報報價審批.',

            QUOTE_APPROVAL_RESENT: '報價審批要求已再發送.',
            QUOTE_WORKFLOW_RESEND_APPROVAL: '再發送審批要求',

            QUOTE_ESCALATION_ADDED_ACTION: '<strong>{{ ::event._links["user"].title }}</strong> 上報報價.',
            QUOTE_ESCALATION_ADDED_SYSTEM_ACTION: '系統自動上報報價.',
            QUOTE_ESCALATION_REMOVED_ACTION: '<strong>{{ ::event._links["user"].title }}</strong> 已從回報移除報價.',

            QUOTE_ESCALATED_ACTION: '<strong>{{ ::event._links["user"].title }}</strong> 上報報價.',
            QUOTE_ESCALATED_SYSTEM_ACTION: '系統自動上報報價.',
            QUOTE_DEESCALATED_ACTION: '<strong>{{ ::event._links["user"].title }}</strong> 已從回報移除報價.',
            QUOTE_DEESCALATED_SYSTEM_ACTION: '系統自動已從回報移除報價.',
            QUOTE_ESCALATION_CANCELLED_ACTION: '<strong>{{ ::event._links["user"].title }}</strong> 已取消上報.',

            QUOTE_WORKFLOW_ESCALATE_APPROVAL: '上報審批',

            QUOTE_CHASED_ACTION: '跟進 #{{ ::event.chaseSequence + 1 }} 通知已發送到 <strong>{{ ::event._links["user"].title }}</strong>.',

            QUOTE_ALARM_UPDATED_ACTION: '<strong>{{ ::event._links["user"].title }}</strong> 已更新警報.',

            QUOTE_MESSAGE_ACTION: '<strong>{{ ::message._links["sender"].title }}</strong> 已加訊息.',

            QUOTE_EXTENSION_REQUESTED_ACTION: '<strong>{{ ::event._links["user"].title }}</strong> 已接納延期.',
            QUOTE_EXTENSION_REJECTED_ACTION: '<strong>{{ ::event._links["user"].title }}</strong> 已拒絕延期要求.',
            QUOTE_EXTENSION_ACCEPTED_ACTION: '<strong>{{ ::event._links["user"].title }}</strong> 已接受延期要求.',

            QUOTE_AUDIT_TRAIL_SENT_ACTION: '<strong>{{ ::event._links["user"].title }}</strong> 已發送審計追踪.',

            QUOTE_APPROVAL_REQUIRED: '報價審批',
            REQUEST_MORE_INFORMATION_SUCCESS: '已要求更多資訊',

            QUOTE_REQUEST_REPORTER: '場地聯絡詳細資料',
            PLEASE_SELECT_QUOTE_OPTIONS: '請選擇報價選項',

            QUOTE_ACCEPTED: '報價已接受',
            CANCEL_SUCCESS: '報價已取消',
            REOPEN_SUCCESS: '報價已重開',
            ABSTAIN_SUCCESS: '報價已拒絕',
            SUBMIT_SUCCESS: '報價已遞交',
            REJECT_SUCCESS: '報價已拒絕',
            HOLD_SUCCESS: '報價已擱置',
        });
    }
})();


app.config(function ($translateProvider) {
    $translateProvider.translations('zh-hk', {


        DATE_DUE: '截止日期',
        DATE_IN: '輸入日期',
        NO_SERVICE_PROVIDERS_FOR_SITE: '沒有服務提供商在此場地',
        QUANTITY: '數量',
        PO_NUMBER: '訂單編號',

        JOBTO: '工作',

        QUOTE_ACCEPTED_ACTION: '報價由 <strong>{{event._links.user.title}}</strong>接受',
        QUOTE_ALARM_UPDATED_ACTION: '報價已更新.',
        QUOTE_CANCELLED_ACTION: '報價由 <strong>{{event._links.user.title}}</strong>取消',
        QUOTE_EXPIRED_SYSTEM_ACTION: '此報價已過期.',
        QUOTE_HOLD_ACTION: '報價由 <strong>{{event._links.user.title}}</strong>擱置',
        QUOTE_REJECTED_ACTION: '報價由 <strong>{{event._links.user.title}}</strong>拒絕',
        QUOTE_RFMI_ACTION: '<strong>{{event._links.user.title}}</strong>請求更多信息',
        QUOTE_SUBMIT_ACTION: '報價由 <strong>{{event._links.user.title}}</strong>遞交',
    });
});
