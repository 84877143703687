(function(){
    'use strict';

    angular
        .module('elogbooks.user.patrols')
        .controller('ManagePatrolsListController', ManagePatrolsListController);

    ManagePatrolsListController.$inject = ['$state', '$stateParams', '$filter', 'patrolsCollection'];

    function ManagePatrolsListController ($state, $stateParams, $filter, patrolsCollection) {
        var vm = this;
        vm.patrolCollectionResponse = patrolsCollection;
        vm.patrols = patrolsCollection.patrols;
        vm.patrols._links = patrolsCollection._links;
        vm.entityCount = patrolsCollection.count;
        vm.search = search;
        vm.order = order;

        var activeOptions = [
            {
                title: $filter('translate')('STATUS_ALL'),
                value: 'all'
            },
            {
                title: $filter('translate')('STATUS_ACTIVE'),
                value: 'active'
            },
            {
                title: $filter('translate')('STATUS_INACTIVE'),
                value: 'inactive'
            }
        ];

        vm.criteria = {
            name: { type: 'text', title: 'NAME', value: $stateParams.name },
            status: { type: 'options', title: 'STATUS', value: $stateParams.status, options: activeOptions, clearValue: 'active' }
        };

        function search (params) {
            var override = {
                page: 1
            };

            $state.go('.', angular.extend({}, params, override), { reload: true });
        };

        function order (key) {
            $stateParams.order = key;

            var override = {
                page: 1
            };

            $state.go('.', angular.extend({}, $stateParams, override), { reload: true });
        };
    }
})();
