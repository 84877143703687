app.config(function ($translateProvider) {
    $translateProvider.translations('en-gb', {
        GO_TO_JOB: 'Go to Job',
        GO_TO_QUOTE: 'Go to Quote',
        SITE_QUICK_ACTIONS: 'Site Quick Actions',
        RAISE_JOB: 'Raise Job',
        VIEW_JOBS: 'View Jobs',
        RAISE_QUOTE: 'Raise Quote',
        VIEW_QUOTES: 'View Quotes',
        NO_JOB_FOUND: 'No job found.',
        NO_QUOTE_FOUND: 'No quote found.'
    });
});