(function () {
    'use strict';

    angular
        .module('elogbooksFilters')
        .filter('financeCostTypes', financeCostTypes);

    function financeCostTypes ($filter) {
        var types = {
            0: 'TYPE_SUBCONTRACTOR',
            'subcontractor': 'TYPE_SUBCONTRACTOR',
            1: 'TYPE_MATERIALS',
            'materials': 'TYPE_MATERIALS',
            2: 'TYPE_EQUIPMENT_HIRE',
            'equipment_hire': 'TYPE_EQUIPMENT_HIRE',
            3: 'TYPE_OTHER_COST',
            'other_cost': 'TYPE_OTHER_COST'
        };

        return function (input) {
            return $filter('translate')(types[input]);
        }
    };
})();
