(function() {
    'use strict';

    angular
        .module('elogbooks')
        .config(Translations);

    function Translations($translateProvider) {
        $translateProvider.translations('en-gb', {
            REPORT_PERMISSIONS: 'Report Permissions',
            REPORT_PERMISSIONS_UPDATED: 'Report Permissions Updated',

            USER_PERMISSION_REPORT_SP_SUMMARY: 'Service Provider Summary',
            USER_PERMISSION_REPORT_SP_SUMMARY_DETAIL: 'The ability to access the Service Provider Summary Report',
            USER_PERMISSION_REPORT_SITE_ASSOCIATE_ACTIVITY:	'Site Associate Activity',
            USER_PERMISSION_REPORT_SITE_ASSOCIATE_ACTIVITY_DETAIL: 'The ability to access the Site Associate Activity Report',
            USER_PERMISSION_REPORT_APPROVAL_AGE: 'Age Of Approvals',
            USER_PERMISSION_REPORT_APPROVAL_AGE_DETAIL: 'The ability to access the Age Of Approval Report',
            USER_PERMISSION_REPORT_SUMMARY: 'Summary',
            USER_PERMISSION_REPORT_SUMMARY_DETAIL: 'The ability to access the Summary Report',
            USER_PERMISSION_REPORT_PPM_GAP_ANALYSIS: 'PPM Gap Analysis',
            USER_PERMISSION_REPORT_PPM_GAP_ANALYSIS_DETAIL: 'The ability to access the PPM Gap Analysis Report',
            USER_PERMISSION_REPORT_OPEN_CLOSE: 'Report Late Open / Early Close',
            USER_PERMISSION_REPORT_OPEN_CLOSE_DETAIL: 'The ability to access the Late Open / Early Close Report',
            USER_PERMISSION_REPORT_MONTHLY: 'Monthly',
            USER_PERMISSION_REPORT_MONTHLY_DETAIL: 'The ability to access the Monthly Report',
            USER_PERMISSION_REPORT_KPI_MONTHLY: 'KPI Monthly',
            USER_PERMISSION_REPORT_KPI_MONTHLY_DETAIL: 'The ability to access the KPI Monthly Report',
            USER_PERMISSION_REPORT_ENGINEERING_RISK: 'Engineering Risk Summary',
            USER_PERMISSION_REPORT_ENGINEERING_RISK_DETAIL: 'The ability to access the Engineering Risk Summary Report',
            USER_PERMISSION_REPORT_PORTFOLIO_COMPLIANCE: 'Portfolio Compliance Report',
            USER_PERMISSION_REPORT_PORTFOLIO_COMPLIANCE_DETAIL: 'The ability to access the Portfolio Compliance Report',
            USER_PERMISSION_REPORT_AUDIT_TREND: 'Audit Trend',
            USER_PERMISSION_REPORT_AUDIT_TREND_DETAIL: 'The ability to access the Audit Trend Report',
            USER_PERMISSION_REPORT_SP_USAGE: 'SP Usage Report',
            USER_PERMISSION_REPORT_SP_USAGE_DETAIL: 'The ability to access the SP Usage Report',
            USER_PERMISSION_REPORT_RESIDENTIAL_LOCATION: 'Residential Location Report',
            USER_PERMISSION_REPORT_RESIDENTIAL_LOCATION_DETAIL: 'The ability to access the Residential Location Report',
            USER_PERMISSION_REPORT_OPERATIVE_PERFORMANCE: 'Operative Performance Report',
            USER_PERMISSION_REPORT_OPERATIVE_PERFORMANCE_DETAIL: 'The ability to access the Operative Performance Report',
            
            USER_PERMISSION_REPORT_SERVICE_PROVIDER_FEEDBACK_RATINGS: 'Service Provider Feedback Ratings',
            USER_PERMISSION_REPORT_SERVICE_PROVIDER_FEEDBACK_RATINGS_DETAIL: 'The ability to access the Service Provider Feedback Ratings Report'
        });
    }
})();
