(function(){
    'use strict';

    angular
        .module('elogbooks')
        .config(registerRoutes);

    function registerRoutes($stateProvider) {

        $stateProvider
            .state('reporter-feedback', {
                url: "/reporter-feedback?details",
                templateUrl: '/modules/common/reporter-feedback/reporter-feedback.html',
                controller: 'ReporterFeedbackController',
                controllerAs: 'vm',
                resolve: {
                    reporterFeedbackResponse: function ($stateParams) {
                        var detailsParams = JSON.parse($stateParams.details);

                        return detailsParams;
                    }
                }
            });
    }
})();
