(function() {
    'use strict';

    angular
        .module('elogbooks.common.jobs.workflow')
        .controller('JobAlarmController', JobAlarmController);

    JobAlarmController.$inject = ['apiClient', '$state', 'messenger', 'jobResponse', 'user'];

    function JobAlarmController(apiClient, $state, messenger, jobResponse, user) {
        var vm = this;
        vm.job = jobResponse;
        vm.action = 'alarm';
        vm.translation = vm.job.alarmAt ? 'JOB_WORKFLOW_ALARM' : 'JOB_WORKFLOW_ALARM_SETNEW';
        vm.noteRequired = true;
        vm.submit = submitAction;
        vm.partial = vm.action;

        vm.data = {};
        vm.data.alarmLock = jobResponse.alarmLock;
        vm.data.alarmAt = jobResponse.alarmAt ? new Date(jobResponse.alarmAt) : null;

        function submitAction() {
            return apiClient.update(vm.job.getLink(vm.action), vm.data).then(function (response) {
                if (response) {
                    return $state.go('^.^', {}, { reload: $state.get('^.^.^') }).then(function () {
                        messenger.success(vm.translation + '_SUCCESS');
                    });
                }

                messenger.error('REQUEST_ERROR');
            });
        }
    }
})();
