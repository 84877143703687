(function () {
    'use strict';

    angular
        .module('elogbooks.user.reports')
        .controller('UserReportsSiteAssociatePpmMissedCompletionController', UserReportsSiteAssociatePpmMissedCompletionController);

    UserReportsSiteAssociatePpmMissedCompletionController.$inject = ['jobParams', 'jobsCollection', 'siteAssociateResponse', '$translate'];

    function UserReportsSiteAssociatePpmMissedCompletionController(jobParams, jobsCollection, siteAssociateResponse, $translate) {
        var vm = this;

        vm.jobsCollection = jobsCollection;
        vm.jobs = jobsCollection.jobs;
        vm.associate = siteAssociateResponse;
        vm.exportParams = jobParams;

        vm.listTitle = vm.associate.title + ' - ' + (
            (jobParams.overdueCompletionHistoric) ?
                $translate.instant('PPM_MISSED_COMPLETION') :
                $translate.instant('PPM_COMPLETION')
        );
    }
})();
