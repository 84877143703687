(function(){
    'use strict';

    angular
        .module('elogbooks.user.preferences')
        .controller('UserPreferencesController', UserPreferencesController);

    UserPreferencesController.inject = ['user', 'elbSettings'];

    function UserPreferencesController(user, elbSettings) {
        var vm = this;
        vm.webhookEnabled = user.getLink('webhooks') ? elbSettings.getSetting('webhook_job').value || elbSettings.getSetting('webhook_quote').value : false;
    }
})();
