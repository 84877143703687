(function(){
    'use strict';

    angular
        .module('elogbooks.helpdesk.sites.details')
        .controller('HelpdeskSiteDetailsController', HelpdeskSiteDetailsController);

    function HelpdeskSiteDetailsController($scope, siteResponse, reactiveJobsResponse) {

        $scope.site = siteResponse.getData();
        $scope.latestReactiveJobs = reactiveJobsResponse.getData().jobs;

    }
})();
