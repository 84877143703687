(function(){
    'use strict';

    app.run(function($rootScope,lodash) {
        $rootScope.trackresource = function(resource) {
            if (angular.isObject(resource)) {
                if (lodash.has(resource, "_links.self.href")) {
                    return resource._links.self.href;
                } else if (resource.hasOwnProperty('href')) {
                    return resource.href;
                } else if (resource.hasOwnProperty('getLink')) {
                    return resource.getLink('self');
                } else {
                    return JSON.stringify(resource);
                }
            } else if(angular.isString(resource)) {
                return resource;
            }

            return null;
        };
    });

    app.service('requestDataFactory', RequestDataFactory);

    function RequestDataFactory()
    {
        this.createRequest = function(data) {
            var request = angular.merge(new RequestData(), data);

            /*angular.forEach(request._links, function(value, key) {
                if (!angular.isObject(value)) {
                    value = { href: value || null };
                }

                request._links[key] = angular.extend(new Link(), value);
            });*/

            return request;
        };
    }

    function Link()
    {
        // Emulate ResponseData's getLink but for 'self' only
        /*this.getLink = function(rel) {
            if (rel === 'self') {
                return this.href;
            }
            return null;
        };*/
    }

    function RequestData()
    {
        this._links = {};
    }

    RequestData.prototype.toJSON = function()
    {
        function convert(links) {
            angular.forEach(links, function (value, key) {
                if (!value) {
                    return;
                }

                if (angular.isArray(value)) {
                    convert(value);
                } else if (angular.isObject(value)) {
                    if (value.hasOwnProperty('href')) {
                        links[key] = { href: value.href };
                    } else if (value.hasOwnProperty('getLink')) {
                        links[key] = { href: value.getLink('self') };
                    }
                } else {
                    links[key] = { href: value };
                }
            });
        }

        var copy = angular.copy(this);

        convert(copy._links);

        return copy;
    };

})();
