(function () {
    'use strict';

    angular
        .module('elogbooks.common.jobs')
        .controller('CommonJobDetailsMaterialsController', CommonJobDetailsMaterialsController);

    CommonJobDetailsMaterialsController.$inject = [
        'materialLineCollectionResponse',
        'supplierCollectionResponse',
        '$state',
        '$stateParams',
        '$filter'
    ];

    function CommonJobDetailsMaterialsController (
        materialLineCollectionResponse,
        supplierCollectionResponse,
        $state,
        $stateParams,
        $filter
    ) {
        var vm = this;
        vm.materialLineCollectionResponse = materialLineCollectionResponse;
        vm.materialLineCollectionResponse.canCreate = materialLineCollectionResponse.getLink('edit') ? true : false;
        var costTypeOptions = [
            {
                title: $filter('financeCostTypes')('subcontractor'),
                value: 0
            },
            {
                title: $filter('financeCostTypes')('materials'),
                value: 1
            },
            {
                title: $filter('financeCostTypes')('equipment_hire'),
                value: 2
            },
            {
                title: $filter('financeCostTypes')('other_cost'),
                value: 3
            }
        ];

        vm.criteria = {
            materialsDescription: { type: 'text', value: $stateParams.materialsDescription, title: 'DESCRIPTION' },
            purchaseOrder: { type: 'text', value: $stateParams.purchaseOrder, title: 'JOB_PURCHASE_ORDER' },
            costType: { type: 'options', title: 'TYPE', value: $stateParams.costType, options: costTypeOptions, clearValue: null }
        };

        vm.criteria.supplier = {
            type: 'jsonselectwidget',
            title: 'SUPPLIER',
            value: $stateParams.supplier ? JSON.parse($stateParams.supplier) : null ,
            options: {
                response : supplierCollectionResponse,
                link : supplierCollectionResponse? supplierCollectionResponse.getLink('self') : null,
                itemValuePath: 'id',
                itemTitlePath: 'name',
                responseKeyPath: 'suppliers'
            }
        };

        vm.search = searchAction;

        function searchAction(params) {
            var override = {
                materialsPage: 1
            };

            $state.go('.', angular.extend({}, params, override), { reload: $state.current });
        }
    }
})();
