(function() {
    'use strict';

    angular
        .module('elogbooks.user.meter-units')
        .controller('MeterUnitInfoController', MeterUnitInfoController);

    MeterUnitInfoController.$inject = ['meterUnitResponse'];
    function MeterUnitInfoController(meterUnitResponse) {
        var vm = this;
        vm.meterUnit = meterUnitResponse;
    }
})();
