(function () {
    'use strict';

    angular
        .module('elogbooksDirectives')
        .directive('loadFilterCriteria', loadFilterCriteria);

    function loadFilterCriteria () {
        return {
            restrict: 'AE',
            scope: {
                filterUser: '=',
                close: '='
            },
            templateUrl: '/modules/directives/saved-filters/load-filter-criteria.html',
            controller: 'loadFilterCriteriaController',
            link: function(scope, $element) {
                // if scrolled to bottom - load next page
                $element.find('.elb-saved-filters--content').scroll(function(e) {
                    if (scope.filtersResponse.page < scope.filtersResponse.pages &&
                        e.target.scrollTop === (e.target.scrollHeight - e.target.offsetHeight)
                    ) {
                        scope.loadMore(e.originalEvent);
                    }
                });
            }
        };
    }
})();
