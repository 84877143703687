app.config(function ($translateProvider) {
   $translateProvider.translations('zh-hk', {

        QUOTE_THRESHOLD: '報價門檻',
        QUOTE_THRESHOLDS: '報價門檻',

        QUOTE_THRESHOLD_ADD: '新增報價門檻',
        QUOTE_THRESHOLD_EDIT: '修改報價門檻',
        QUOTE_THRESHOLD_LIST: '報價門檻',

        FEWER_APPROVERS_THAN_THRESHOLDS: '更多報價審批者需要涵蓋所有報價門檻'
   })
});
