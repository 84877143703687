;(function() {
    'use strict';

    angular
        .module('elogbooks.common.forms')
        .controller('CommonFormsListController', CommonFormsListController);

    CommonFormsListController.$inject = [
        'crudService',
        'messenger',
        '$stateParams',
        '$state',
        'formVersionInstanceCollectionResponse',
        'jobResponse'
    ];

    function CommonFormsListController(
        crudService,
        messenger,
        $stateParams,
        $state,
        formVersionInstanceCollectionResponse,
        jobResponse
    ) {
        var vm = this;
        vm.formVersionInstanceCollectionResponse = formVersionInstanceCollectionResponse;
        vm.job = jobResponse;

        vm.delete = deleteAction;

        vm.shared = vm.job.attributes.down_share_id || vm.job.attributes.down_share_client || vm.job.attributes.up_share_id || vm.job.attributes.up_share_client;

        function deleteAction(formVersionInstance, $index) {
            return crudService.delete(
                formVersionInstance.getLink('delete'),
                null,
                null,
                {},
                true
            ).then(function(response) {
                $state.go('.', {}, {reload: $state.current.parent});
                messenger.success('FORM_INSTANCE_DELETED');
            });
        }
    }
})();
