angular.module('elogbooksDirectives').directive('userTitle', ['apiClient', function () {
    return {
        // Restrict to attributes only.
        restrict: 'A',
        require: ['ngModel'],
        scope: {
            title: '=ngModel'
        },
        templateUrl: '/modules/directives/form-elements/user-title/user-title.html',
        link: function (scope) {
            scope.roles = [
                "Mr",
                "Miss",
                "Mrs",
                "Ms",
                "Dr"
            ]
        }
    }
}]);
