(function() {
    'use strict';

    angular
        .module('elogbooks.admin.site-relationships')
        .controller('SiteRelationshipsController', SiteRelationshipsController);

    SiteRelationshipsController.$inject = ['$state', '$stateParams', 'siteRelationshipsCollectionResponse'];
    function SiteRelationshipsController($state, $stateParams, siteRelationshipsCollectionResponse) {
        var vm = this;
        vm.siteRelationships = siteRelationshipsCollectionResponse.siterelationships;
        vm.siteRelationshipsCollectionResponse = siteRelationshipsCollectionResponse;
        vm.criteria = {
            id: { type: 'number', value: $stateParams.id ? parseInt($stateParams.id, 10) : null, title: 'ID', min: 1 },
        };

        vm.order = orderAction;
        vm.search = searchAction;

        function searchAction(params) {
            var override = {
                page: 1
            };

            $state.go('.', angular.extend({}, params, override), { reload: $state.current });
        }

        function orderAction(key) {
            $stateParams.order = key;

            $state.go('.', angular.extend({}, $stateParams), { reload: $state.current });
        }
    }
})();
