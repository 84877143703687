(function () {
    'use strict';

    angular
        .module('elogbooks.user.reports')
        .controller('UserReportsMonthlyDetailsController', UserReportsMonthlyDetailsController);

    UserReportsMonthlyDetailsController.$inject = ['$scope', '$timeout', '$filter', '$state', '$window', '$stateParams', '$translate', 'apiClient', 'siteCollectionResponse', 'lodash', 'moment', 'coreClientCollectionResponse', 'siteGroupCollectionResponse', 'serviceProviderGroupCollectionResponse', 'serviceProviderCollectionResponse', 'regionCollectionResponse'];

    function UserReportsMonthlyDetailsController($scope, $timeout, $filter, $state, $window, $stateParams, $translate, apiClient, siteCollectionResponse, lodash, moment, coreClientCollectionResponse, siteGroupCollectionResponse, serviceProviderGroupCollectionResponse, serviceProviderCollectionResponse, regionCollectionResponse) {
        var vm = this;
        vm.stateParams = $stateParams;
        vm.orderDirection = 1;
        vm.offset = 0;
        vm.months = [];
        vm.reportData = [];
        vm.chartData = [];
        vm.rangeWarning = false;
        vm.chartMonths = [];
        vm.chartOptions = {
            chart: {
                type: 'lineChart',
                height: 350,
                width: 700,
                margin: {
                    top: 20,
                    right: 50,
                    bottom: 80,
                    left: 50
                },
                stacked: true,
                showControls: false,
                yDomain: [0,1],
                x: function (d) {
                    return d.x; //new moment(d.x, 'YYYYMMDD');
                },
                yAxis: {
                    tickFormat: function(d) {
                        return d3.format(".0%")(d);
                    }
                },
                xAxis: {
                    tickFormat: function(d) {
                        return vm.chartMonths[d];//d3.time.format('%b')(new Date(d));
                    },
                    ticks: 12,
                    showMaxMin: false,
                    margin: {
                        top: 10,
                        right: 20,
                        bottom: 10,
                        left: 20
                    }
                },
                legend: {
                    margin: {
                        top: 15,
                        right: 0,
                        bottom: 20,
                        left: 0
                    }
                },
                reduceXTicks: false,
                showLegend: true,
                legendPosition: 'bottom'
            }
        };

        vm.search = search;
        vm.openModal = openModal;

        // Make sure max date range is 12 months
        if ($stateParams.dateRange !== null && typeof $stateParams.dateRange === 'string'
            && moment(new Date($stateParams.dateRange.split(',')[1])) > moment(new Date($stateParams.dateRange.split(',')[0])).add(11, 'month').endOf('month').add(1, 'day')) {
            vm.rangeWarning = true;
        } else if ($stateParams.dateRange !== null && typeof $stateParams.dateRange === 'string'
            && moment(new Date($stateParams.dateRange.split(',')[1])) < moment(new Date($stateParams.dateRange.split(',')[0]))) {
            $stateParams.dateRange = moment().subtract(1, 'month').startOf('month').startOf('day').format()+','+moment().subtract(1, 'month').startOf('month').add(11, 'month').endOf('month').endOf('day').format();
        }

        vm.startDate = ($stateParams.dateRange !== null && typeof $stateParams.dateRange === 'string') ? moment(new Date($stateParams.dateRange.split(',')[0])) : null;
        vm.endDate = ($stateParams.dateRange !== null && typeof $stateParams.dateRange === 'string') ? moment(new Date($stateParams.dateRange.split(',')[1])) : null;

        // Filters
        vm.siteSelect = {
            response : siteCollectionResponse,
            link : siteCollectionResponse && siteCollectionResponse.count > 0 ? siteCollectionResponse.getLink('self') : null,
            responseKeyPath: 'sites',
            itemValuePath: 'id',
            itemTitlePath: 'name'
        };
        vm.serviceProviderSelect = {
            response : serviceProviderCollectionResponse,
            link : serviceProviderCollectionResponse ? serviceProviderCollectionResponse.getLink('self') : null,
            responseKeyPath: 'serviceproviders',
            itemValuePath: 'id',
            itemTitlePath: 'name'
        };
        vm.siteGroupSelect = {
            response : siteGroupCollectionResponse,
            link : siteGroupCollectionResponse ? siteGroupCollectionResponse.getLink('self') : null,
            responseKeyPath: 'sitegroups',
            itemValuePath: 'id',
            itemTitlePath: 'name'
        };
        vm.serviceProviderGroupSelect = {
            response : serviceProviderGroupCollectionResponse,
            link : serviceProviderGroupCollectionResponse ? serviceProviderGroupCollectionResponse.getLink('self') : null,
            responseKeyPath: 'groups',
            itemValuePath: 'id',
            itemTitlePath: 'name'
        };
        vm.siteRegionSelect = {
            response : regionCollectionResponse,
            link : regionCollectionResponse ? regionCollectionResponse.getLink('self') : null,
            responseKeyPath: 'regions',
            itemValuePath: 'id',
            itemTitlePath: 'name'
        };

        // Clean params for export and table data loading
        vm.cleanParams = {};
        vm.cleanParams.dateRange = $stateParams.dateRange;
        vm.cleanParams.coreClient = $stateParams.coreClient ? JSON.parse($stateParams.coreClient).v : null;
        vm.cleanParams.site = $stateParams.site ? JSON.parse($stateParams.site).v : null;
        vm.cleanParams.siteGroup = $stateParams.siteGroup ? JSON.parse($stateParams.siteGroup).v : null;
        vm.cleanParams.siteRegionId = $stateParams.siteRegionId ? JSON.parse($stateParams.siteRegionId).v : null;
        vm.cleanParams.serviceProviderGroup = $stateParams.serviceProviderGroup ? JSON.parse($stateParams.serviceProviderGroup).v : null;
        vm.cleanParams.serviceProvider = $stateParams.serviceProvider ? JSON.parse($stateParams.serviceProvider).v : null;

        function calculateMonthsBetween(startDate, endDate) {
            var date = moment(startDate).startOf('day');
            var months = [];

            while (date < endDate && months.length < 12) {
                months.push({'title': date.format('MMM').toUpperCase(), 'key': date.format('YYYYMM').toUpperCase()});
                date.add(1, 'month');
            }

            vm.months = months;
        }

        function loadData() {
            vm.loading = true;
            calculateMonthsBetween(vm.startDate, vm.endDate);

            apiClient.get('/reports/monthly', vm.cleanParams).then(function (response) {
                vm.reportData = response;
                vm.loading = false;
                refreshChartData();
            });
        }

        vm.searchCriteria = {
            dateRange: {
                type: 'date-range',
                title: 'BETWEEN_DATES',
                value: {
                    startDate:  vm.startDate,
                    endDate: vm.endDate
                },
                clearValue: {
                    startDate: null,
                    endDate: null
                }
            },
            site: { type: 'jsonselectwidget', title: 'SITE', value: JSON.parse($stateParams.site), options: vm.siteSelect },
            siteGroup: { type: 'jsonselectwidget', title: 'SITE_GROUP', value: JSON.parse($stateParams.siteGroup), options: vm.siteGroupSelect },
            serviceProviderGroup: { type: 'jsonselectwidget', title: 'SERVICE_PROVIDER_GROUP', value: JSON.parse($stateParams.serviceProviderGroup), options: vm.serviceProviderGroupSelect },
            serviceProvider: { type: 'jsonselectwidget', title: 'SERVICEPROVIDER', value: JSON.parse($stateParams.serviceProvider), options: vm.serviceProviderSelect },
            siteRegionId: { type: 'jsonselectwidget', title: 'REGION', value: JSON.parse($stateParams.siteRegionId), options: vm.siteRegionSelect },
        };

        if (coreClientCollectionResponse.count) {
            vm.coreClientSelect = {
                response : coreClientCollectionResponse,
                link : coreClientCollectionResponse ? coreClientCollectionResponse.getLink('self') : null,
                responseKeyPath: 'coreclients',
                itemValuePath: 'id',
                itemTitlePath: 'name'
            };

            vm.searchCriteria.coreClient = { type: 'jsonselectwidget', title: 'CORE_CLIENT', value: JSON.parse($stateParams.coreClient), options: vm.coreClientSelect };
        }

        function search(params) {
            var override = {
                page: 1
            };

            $state.go('.', angular.extend($stateParams, params, override), {reload: $state.current.name});
        }

        function openModal(type, yearMonth) {
            var startDate = moment(yearMonth, 'YYYYMM').startOf('month');

            if ($stateParams.dateRange !== null && typeof $stateParams.dateRange === 'string'
                && moment(new Date($stateParams.dateRange.split(',')[0])).format('YYYYMM') === yearMonth)
            {
                startDate = moment(new Date($stateParams.dateRange.split(',')[0]));
            }

            var params = {
                modalType: type,
                modalDateRange: startDate.format() + ',' + startDate.endOf('month').format()
            };

            $state.go('dashboard.user.reports.list.view.monthly.modal.attended-completed', angular.extend({}, $stateParams, params), {reload:false, notify: false});
        }

        function getColor(key) {
            switch (key) {
                case 'reactive_attendance':
                    return '#1f77b4';
                case 'reactive_completion':
                    return '#22987d';
                case 'planned_attendance':
                    return '#999999';
                case 'planned_completion':
                    return '#ff9800';
                case 'quotes':
                    return '#f44336';
            }
        }

        function refreshChartData() {
            var chartData = [];
            lodash.each(vm.reportData.data, function (value, key) {
                var values = [];
                for (var property in value) {
                    values.push(
                        {
                            x: property,
                            y: value[property].total > 0 ? value[property].value / value[property].total: 0
                        }
                    )
                }
                values = lodash.sortBy(values, ['x']);

                for (var i = 0; i < values.length; i++) {
                    vm.chartMonths[i+1] = moment(values[i].x, 'YYYYMM').format('MMM');
                    values[i].x = i+1;
                }

                chartData.push(
                    {
                        key: $translate.instant(key.toUpperCase()),
                        color: getColor(key),
                        values: values
                    }
                );
            });

            vm.chartData = chartData;
            $timeout(function(){
                var container = angular.element.find('.monthly-barchart');
                if (vm.chartOptions && container.length > 0) {
                    vm.chartOptions.chart.width = container[0].clientWidth;
                }
                $window.dispatchEvent(new Event('resize'));
            }, 100);
        }

        $scope.$watch('vm.stateParams.tab', function (newValue, oldValue) {
            if (newValue !== oldValue) {
                //Silent update of state
                $state.go('.', $stateParams, {reload: $state.current.name, notify: false}).then(function () {
                    refreshChartData();
                });
            }
        });

        loadData();
    }
})();


