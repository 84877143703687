(function() {
    'use strict';

    angular
        .module('elogbooks.common.meters', [
            'elogbooks.common.meter-meters',
        ]);
})();

/**
 * Note:
 *
 * This is a global function. It was intended to be a service that would then be injected
 * in to the routing files / functions, but it appears that services cannot be injected in
 * to the routing functions. An unknown provider error occurs.
 */
function elbMetersRouting() {
    var self = this,
        config = {},
        $stateProvider;

    this.addRoutes = addRoutes;

    function getView(view, viewObject) {
        var result = {};
        result[view] = viewObject;

        if (config.isBookable) {
            result = parseBook(result);
        }

        return result;
    }

    function getLabel(translationKey) {
        return { label: '{{ ::"' + translationKey + '" | translate }}' };
    }

    /**
     * Usage:
     *     elbMetersRouting().addRoutes(
     *         $stateProvider,
     *         'dashboard.user.meters',
     *         true || false,
     *         true || false
     *     )
     * @param {Object}  _$stateProvider
     * @param {String}  baseRoute
     * @param {Boolean} isBookable
     * @param {Boolean} showImportTab
     */
    function addRoutes(_$stateProvider, baseRoute, isBookable, showImportTab) {
        config = {
            "isBookable": isBookable,
            "showImportTab": showImportTab,
            "baseRoute": baseRoute,
            "baseView": '@' + baseRoute,
            "baseBookViewLeft": 'elogbooks-left@' + baseRoute,
            "baseBookViewRight": 'elogbooks-right@' + baseRoute,
            "baseBookViewCenter": 'elogbooks-center@' + baseRoute
        };
        if (!isBookable) {
            config.baseBookViewLeft = config.baseView;
            config.baseBookViewRight = config.baseView;
            config.baseBookViewCenter = config.baseView;
        }

        $stateProvider = _$stateProvider;

        registerRoutes();
        registerRoutesDetails();
        registerRoutesReadings();
        registerRoutesImports();
    }

    function registerRoutes() {
        var baseRouteObject = {
            url: '/meters',
            abstract: true,
            template: '<bookerize></bookerize>',
            ncyBreadcrumb: getLabel('METERS')
        };

        if (config.isBookable) {
            delete baseRouteObject.templateUrl;
            baseRouteObject.template = '<bookerize></bookerize>'
        }

        $stateProvider
            .state(config.baseRoute, baseRouteObject)
            .state(config.baseRoute + '.manage-meters', {
                url: '/manage',
                parent: config.baseRoute,
                abstract: true,
                views: getView(config.baseBookViewCenter, {
                    templateUrl: '/modules/common/meters/meters/meters.html',
                    controller : 'UserMetersController',
                    controllerAs: 'vm'
                }),
                resolve: {
                    sectionResource: function (user, userManager) {
                        return {
                            canImport: config.showImportTab && userManager.hasPermission('site_permission_meter_readings_user_imports'),
                            listSref: config.baseRoute + '.manage-meters.list',
                            importSref: config.baseRoute + '.manage-meters.imports.list',
                            currentView: 'elogbooks-right@' + config.baseRoute + '.manage-meters'
                        };
                    }
                },
                ncyBreadcrumb: {
                    skip: true
                }
            })
            .state(config.baseRoute + '.manage-meters.list', {
                parent: config.baseRoute + '.manage-meters',
                    active: '1',
                url: '/meters?meterPage&meterLimit&meterOrder&meterReference&meterSerialNumber&{meterServiceProviderName:any}&submeters&meterSupplyTypeId&meterLocationId&site&active&occupancyTypes',
                views: getView(config.baseBookViewRight  + '.manage-meters', {
                    templateUrl: '/modules/common/meters/meters/list.html',
                    controller: 'CommonMetersListController',
                    controllerAs: 'vm'
                }),
                params: {
                    meterPage: '1',
                    meterLimit: '30',
                    meterReference: null,
                    meterSerialNumber: null,
                    meterServiceProviderName: null,
                    submeters: '0',
                    meterSiteId: null,
                    meterLocationId: null,
                    meterSupplyTypeId: null,
                    site: null,
                    serviceProvider: null,
                    readingStatus: null,
                    readingDue: null,
                    active: '1',
                    occupancyTypes: { array: true },
                },
                resolve: {
                    siteCollectionResponse: function(apiClient, user, globalFilterParamsService) {
                        var params = {
                            datagroup: 'search'
                        };

                        params = angular.extend({}, params, globalFilterParamsService.getLocalStorageFilters());

                        return apiClient.get(user.getLink('sites'), params, 'long').then(function(response) {
                            return response || apiClient.noResourceFound();
                        });
                    },
                    meterCollectionResponse: function ($stateParams, apiClient, user, selectedSiteResponse, siteResponse, paramsService) {
                        var params = $stateParams;
                        params = angular.extend({}, params, paramsService);
                        params = angular.extend({}, params, {
                            'page': $stateParams.meterPage,
                            'limit': $stateParams.meterLimit,
                            'order': $stateParams.meterOrder,
                            'meterReference': $stateParams.meterReference,
                            'serialNumber': $stateParams.meterSerialNumber,
                            'serviceProviderName': $stateParams.meterServiceProviderName,
                            'location': $stateParams.meterLocationId,
                            'supplyType': $stateParams.meterSupplyTypeId,
                            'readingDue': $stateParams.readingDue,
                            'readingStatus': $stateParams.readingStatus,
                            'site': $stateParams.site,
                            'serviceProvider': $stateParams.serviceProvider,
                            'userId': $stateParams.userId,
                            'region': $stateParams.region,
                            'active': $stateParams.active,
                            'occupancyTypes[]': $stateParams.occupancyTypes
                        });

                        var resource = user.getLink('meters');

                        if (selectedSiteResponse) {
                            resource = selectedSiteResponse.getLink('meters');
                        }

                        if (!angular.equals(siteResponse, {})) {
                            resource = siteResponse.getLink('meters');
                        }

                        if ($stateParams.submeters === "0") {
                            params.parent = false;
                        }

                        if (typeof params.readingDue !== 'string') {
                            params.readingDue = null;
                        }

                        params.location =  params.location ? JSON.parse(params.location).v : null;
                        params.site = params.site ? JSON.parse(params.site).v : null;
                        params.supplyType = params.supplyType ? JSON.parse(params.supplyType).v : null;
                        params.serviceProvider = params.serviceProvider ? JSON.parse(params.serviceProvider).v : null;

                        return apiClient.getPage(resource, params);
                    },
                    locationsCollectionResponse: function ($stateParams, apiClient, selectedSiteResponse, siteResponse) {
                        var site = null;

                        if (typeof siteResponse.id !== 'undefined') {
                            site = siteResponse
                        } else if (selectedSiteResponse) {
                            site = selectedSiteResponse
                        }

                        if (site) {
                            return apiClient.get(site.getLink('locations'), {fetchHierarchy: true, datagroup: 'details'});
                        } else {
                            $stateParams.meterLocationId = null;
                        }

                        return null;
                    },
                    meterSupplyCollectionResponse: function (apiClient, $stateParams, rootResourceResponse) {
                        var params = {
                            type: 'supply_type'
                        };

                        return apiClient.get(rootResourceResponse.getLink('metertypes'), params).then( function(response) {
                            return response || apiClient.noResourceFound();
                        });
                    },
                    serviceProviderCollectionResponse: function (apiClient, rootResourceResponse, user, paramsService) {
                        var params = angular.extend({}, params, paramsService);

                        return apiClient.get(user.getLink('serviceproviders'), params).then( function(response) {
                            return response || apiClient.noResourceFound();
                        });
                    },
                    meterOccupancyTypeCollectionResponse: function (apiClient, rootResourceResponse) {
                        return apiClient.get(rootResourceResponse.getLink('metertypes'), {type: 'occupancy_type'});
                    },                    
                },
                ncyBreadcrumb: getLabel('MANAGE_METERS')
            })
            .state(config.baseRoute + '.manage-meters.list.add', {
                parent: config.baseRoute + '.manage-meters.list',
                url: '/add',
                views: getView(config.baseBookViewRight, {
                    templateUrl: '/modules/common/meters/meters/add-edit/meter-form.html',
                    controller: 'CommonMeterAddEditController',
                    controllerAs: 'vm'
                }),
                resolve: {
                    meterTypeCollectionResponse: function (apiClient, rootResourceResponse) {
                        return apiClient.get(rootResourceResponse.getLink('metertypes'), {type: 'meter_type'});
                    },
                    meterManufacturerCollectionResponse: function (apiClient, rootResourceResponse) {
                        return apiClient.get(rootResourceResponse.getLink('metermanufacturers'));
                    },
                    meterSupplierCollectionResponse: function (apiClient, rootResourceResponse) {
                        return apiClient.get(rootResourceResponse.getLink('metersuppliers'));
                    },
                    meterSupplyTypeCollectionResponse: function (apiClient, rootResourceResponse) {
                        return apiClient.get(rootResourceResponse.getLink('metertypes'), {type: 'supply_type'});
                    },
                    meterOccupancyTypeCollectionResponse: function (apiClient, rootResourceResponse) {
                        return apiClient.get(rootResourceResponse.getLink('metertypes'), {type: 'occupancy_type'});
                    },
                    meterUnitCollectionResponse: function (apiClient, rootResourceResponse) {
                        return apiClient.get(rootResourceResponse.getLink('meterunits'), {active: true});
                    },
                    serviceProviderCollectionResponse: function () {
                        return {};
                    },
                    parentResponse: function() {
                        return false;
                    },
                    meterResponse: function() {
                        return {};
                    },
                    siteResponse: function(apiClient, parentResponse, siteResponse) {
                        if (siteResponse) {
                            return siteResponse;
                        }

                        if (parentResponse) {
                            return apiClient.get(parentResponse.getLink('site'));
                        }

                        return false;
                    },
                    locationCollectionResponse: function(apiClient, selectedSiteResponse) {
                        return [];
                    },
                    locationResponse: function() {
                        return null;
                    }

                },
                ncyBreadcrumb: getLabel('ADD'),
                scroll: false
            })
            .state(config.baseRoute + '.manage-meters.list.details.submeters.add', {
                url: '/add?parent',
                views: getView(config.baseBookViewRight, {
                    templateUrl: '/modules/common/meters/meters/add-edit/meter-form.html',
                    controller: 'CommonMeterAddEditController',
                    controllerAs: 'vm'
                }),
                resolve: {
                    meterTypeCollectionResponse: function (apiClient, rootResourceResponse) {
                        return apiClient.get(rootResourceResponse.getLink('metertypes'), {type: 'meter_type'});
                    },
                    meterManufacturerCollectionResponse: function (apiClient, rootResourceResponse) {
                        return apiClient.get(rootResourceResponse.getLink('metermanufacturers'));
                    },
                    meterSupplierCollectionResponse: function (apiClient, rootResourceResponse) {
                        return apiClient.get(rootResourceResponse.getLink('metersuppliers'));
                    },
                    meterSupplyTypeCollectionResponse: function (apiClient, rootResourceResponse) {
                        return apiClient.get(rootResourceResponse.getLink('metertypes'), {type: 'supply_type'});
                    },
                    meterOccupancyTypeCollectionResponse: function (apiClient, rootResourceResponse) {
                        return apiClient.get(rootResourceResponse.getLink('metertypes'), {type: 'occupancy_type'});
                    },
                    meterUnitCollectionResponse: function (apiClient, rootResourceResponse) {
                        return apiClient.get(rootResourceResponse.getLink('meterunits'), {active: true});
                    },
                    meterResponse: function(apiClient, $stateParams) {
                        return {};
                    },
                    serviceProviderCollectionResponse: function () {
                        return {};
                    },
                    parentResponse: function(apiClient, $stateParams) {
                        if ($stateParams.parent) {
                            return apiClient.get($stateParams.parent.decode());
                        }

                        return false;
                    },
                    siteResponse: function() {
                        return false;
                    },
                    locationCollectionResponse: function() {
                        return [];
                    },
                    locationResponse: function() {
                        return null;
                    }
                },
                ncyBreadcrumb: getLabel('ADD'),
                scroll: false
            })
    }

    function registerRoutesDetails() {
        $stateProvider
            .state(config.baseRoute + '.manage-meters.list.details', {
                parent: config.baseRoute + '.manage-meters.list',
                abstract: true,
                url: '/{meterResource}',
                views: getView(config.baseBookViewRight, {
                    templateUrl: '/modules/common/meters/meters/details/details.html',
                    controller: 'CommonMeterDetailsController',
                    controllerAs: 'vm'
                }),
                params: {
                  submeterActive: '1'
                },
                resolve: {
                    baseRoute: function() {
                        return config.baseRoute + '.manage-meters.list.details';
                    },
                    meterResponse: function(apiClient, $stateParams) {
                        return apiClient.get($stateParams.meterResource.decode(), $stateParams);
                    },
                    submetersCollectionResponse: function ($stateParams, apiClient, meterResponse) {
                        var params = {
                            location: $stateParams.meterLocationId,
                            supplyType: $stateParams.meterSupplyTypeId,
                            active: $stateParams.submeterActive
                        };

                        if (params.location) {
                            params.location = JSON.parse(params.location).v;
                        }

                        if (params.supplyType) {
                            params.supplyType = JSON.parse(params.supplyType).v;
                        }

                        if (meterResponse._links.children) {
                            return apiClient.getPage(meterResponse._links.children.href, params);
                        }

                        return [];
                    },
                    meterFilesCollectionResponse: function (apiClient, meterResponse) {
                        return apiClient.get(meterResponse.getLink('images')).then(function (response) {
                            return response || apiClient.noResourceFound();
                        });
                    },
                    siteResponse: function(apiClient, meterResponse) {
                        return apiClient.get(meterResponse.getLink('site'));
                    },
                    locationsCollectionResponse: function (apiClient, siteResponse, $stateParams, meterResponse) {
                        return apiClient.get(siteResponse.getLink('locations'), {fetchHierarchy: true, datagroup: 'details'});
                    },
                    meterSupplyCollectionResponse: function (apiClient, $stateParams, rootResourceResponse) {
                        var params = {
                            type: 'supply_type'
                        };

                        return apiClient.get(rootResourceResponse.getLink('metertypes'), params).then( function(response) {
                            return response || apiClient.noResourceFound();
                        });
                    }
                },
                ncyBreadcrumb: {
                    label: '{{ ::$resolves().meterResponse.description || $resolves().meterResponse.referenceType + "-" + $resolves().meterResponse.reference }}'
                },
                scroll: false
            })
            .state(config.baseRoute + '.manage-meters.list.details.info', {
                url: '/info',
                templateUrl: '/modules/common/meters/meters/details/meter-info.html',
                controller: 'CommonMeterDetailsController',
                controllerAs: 'vm',
                ncyBreadcrumb: getLabel('INFO')
            })
            .state(config.baseRoute + '.manage-meters.list.details.history', {
                url: '/history',
                templateUrl: '/modules/common/meters/meters/details/meter-history.html',
                controller: 'CommonMeterDetailsController',
                controllerAs: 'vm',
                ncyBreadcrumb: getLabel('HISTORY')
            })
            .state(config.baseRoute + '.manage-meters.list.details.submeters', {
                url: '/submeters?submetersPage&submetersLimit',
                templateUrl: '/modules/common/meters/meters/details/meter-submeters.html',
                controller: 'CommonMeterDetailsController',
                controllerAs: 'vm',
                ncyBreadcrumb: getLabel('SUBMETERS'),
                resolve: {
                    submetersCollectionResponse: function (apiClient, submetersCollectionResponse, $stateParams) {
                        if (submetersCollectionResponse._links) {
                            var params = {
                                page: $stateParams.submetersPage,
                                limit: $stateParams.submetersLimit,
                                active: $stateParams.submeterActive
                            };

                            return apiClient.get(submetersCollectionResponse.getLink('self'), params);
                        } else {
                            return submetersCollectionResponse;
                        }
                    }
                },
                params: {
                    submetersPage: '1',
                    submetersLimit: '30'
                }
            })
            .state(config.baseRoute + '.manage-meters.list.details.info.edit', {
                url: '/edit',
                views: getView(config.baseBookViewRight, {
                    templateUrl: '/modules/common/meters/meters/add-edit/meter-form.html',
                    controller: 'CommonMeterAddEditController',
                    controllerAs: 'vm'
                }),
                resolve: {
                    meterTypeCollectionResponse: function (apiClient, rootResourceResponse) {
                        return apiClient.get(rootResourceResponse.getLink('metertypes'), {type: 'meter_type'});
                    },
                    meterManufacturerCollectionResponse: function (apiClient, rootResourceResponse) {
                        return apiClient.get(rootResourceResponse.getLink('metermanufacturers'));
                    },
                    meterSupplierCollectionResponse: function (apiClient, rootResourceResponse) {
                        return apiClient.get(rootResourceResponse.getLink('metersuppliers'));
                    },
                    meterSupplyTypeCollectionResponse: function (apiClient, rootResourceResponse) {
                        return apiClient.get(rootResourceResponse.getLink('metertypes'), {type: 'supply_type'});
                    },
                    meterOccupancyTypeCollectionResponse: function (apiClient, rootResourceResponse) {
                        return apiClient.get(rootResourceResponse.getLink('metertypes'), {type: 'occupancy_type'});
                    },
                    meterUnitCollectionResponse: function (apiClient, rootResourceResponse) {
                        return apiClient.get(rootResourceResponse.getLink('meterunits'), {active: true});
                    },
                    serviceProviderCollectionResponse: function (apiClient, user, userManager, meterResponse) {
                        if (user._links.hasOwnProperty('serviceproviders')) {
                            return apiClient.get(user._links.serviceproviders.href, {active:1, site: meterResponse._links.site.id}).then(function (response) {
                                return response.getData().serviceproviders;
                            });
                        } else {
                            return {};
                        }
                    },
                    parentResponse: function(apiClient, $stateParams) {
                        if ($stateParams.parent) {
                            return apiClient.get($stateParams.parent.decode());
                        }

                        return false;
                    },
                    siteResponse: function(apiClient, parentResponse) {
                        if (parentResponse) {
                            return apiClient.get(parentResponse.getLink('site'));
                        }

                        return false;
                    },
                    locationCollectionResponse: function() {
                        return [];
                    },
                    locationResponse: function(apiClient, meterResponse) {
                        if (meterResponse.getLink('location')) {
                            return apiClient.get(meterResponse.getLink('location'));
                        }

                        return null;
                    }
                },
                ncyBreadcrumb: getLabel('EDIT')
            });
    }

    function registerRoutesReadings() {
        $stateProvider
            .state(config.baseRoute + '.manage-meters.list.details.history.add-reading', {
                url: '/add-reading',
                views: getView(config.baseBookViewRight, {
                    templateUrl: '/modules/common/meters/meters/details/add-edit-reading/add-edit-reading.html',
                    controller: 'CommonMeterAddEditReadingController',
                    controllerAs: 'vm'
                }),
                resolve: {
                    meterReadingResponse: function () {
                        return {reading: null, readAt: null, type: 0};
                    }
                },
                ncyBreadcrumb: {
                    label: '{{ ::"ADD" | translate }}'
                }
            })
            .state(config.baseRoute + '.manage-meters.list.details.history.edit-reading', {
                url: '/edit-reading/{reading}',
                views: getView(config.baseBookViewRight, {
                    templateUrl: '/modules/common/meters/meters/details/add-edit-reading/add-edit-reading.html',
                    controller: 'CommonMeterAddEditReadingController',
                    controllerAs: 'vm'
                }),
                resolve: {
                    meterReadingResponse: function (apiClient, $stateParams) {
                        return apiClient.get($stateParams.reading.decode()).then(function (response) {
                            return response.getData();
                        });
                    }
                },
                ncyBreadcrumb: getLabel('EDIT')
            });
    }

    function registerRoutesImports() {
        $stateProvider
            .state(config.baseRoute + '.manage-meters.imports', {
                url: '/imports',
                parent: config.baseRoute + '.manage-meters',
                abstract: true,
                templateUrl: 'shared-templates/view.html',
                ncyBreadcrumb: {
                    skip: true
                }
            })
            .state(config.baseRoute + '.manage-meters.imports.list', {
                url: '/list?page&limit&order&type',
                parent: config.baseRoute + '.manage-meters.imports',
                views: getView(config.baseBookViewRight  + '.manage-meters', {
                    templateUrl: '/modules/common/imports/list/list.html',
                    controller : 'CommonImportListController',
                    controllerAs: 'vm'
                }),
                resolve: {
                    importsCollectionResponse: function (apiClient, rootResourceResponse, $stateParams) {
                        return apiClient.get(rootResourceResponse.getLink('imports'), $stateParams);
                    }
                },
                params: {
                    page: '1',
                    limit: '30',
                    type: 'meter_reading'
                },
                ncyBreadcrumb: getLabel('METER_READING_IMPORTS')
            })
            .state(config.baseRoute + '.manage-meters.imports.list.details', {
                url: '{importResource}/details?logsPage&logsLimit&logsOrder&logsType&logsMessage',
                parent: config.baseRoute + '.manage-meters.imports.list',
                views: getView(config.baseBookViewRight, {
                    templateUrl: '/modules/common/imports/details/details.html',
                    controller : 'CommonImportDetailsController',
                    controllerAs: 'vm',
                }),
                params: {
                    logsPage: '1',
                    logsLimit: '30',
                    logsType: null,
                    logsMessage: null
                },
                resolve: {
                    importResponse: function (apiClient, $stateParams) {
                        return apiClient.get($stateParams.importResource.decode());
                    },
                    importLogsCollectionResponse: function (apiClient, importResponse, $stateParams) {
                        var params = {
                            page: $stateParams.logsPage,
                            limit: $stateParams.logsLimit,
                            type: $stateParams.logsType,
                            message: $stateParams.logsMessage
                        };
                        return apiClient.get(importResponse.getLink('logs'), params);
                    }
                },
                ncyBreadcrumb: {
                    label: '{{ ::"IMPORT_DETAILS_LIST" | translate }}'
                }
            })
            .state(config.baseRoute +  '.manage-meters.imports.list.add', {
                url: '/add',
                parent: config.baseRoute + '.manage-meters.imports.list',
                views: getView(config.baseBookViewRight, {
                    templateUrl: '/modules/common/imports/add/import-add.html',
                        controller: 'CommonAddImportController',
                        controllerAs: 'vm'
                }),
                resolve: {
                    sectionResource: function() {
                        return {
                            type: 'meter_reading',
                            name: 'meter_reading'
                        };
                    }
                },
                ncyBreadcrumb: {
                    label: '{{ ::"IMPORT_ADD" | translate }}'
                }
            });
    }

    return this;
}
