(function () {
    'use strict';

    angular
        .module('elogbooks.user.notifications')
        .controller('NotificationsListController', NotificationsListController);

    NotificationsListController.$inject = [
        '$state',
        '$stateParams',
        'notificationsCollectionResponse',
        'notificationsService',
        'lodash',
        '$translate',
        'apiClient',
        '$rootScope'
    ];

    function NotificationsListController (
        $state,
        $stateParams,
        notificationsCollectionResponse,
        notificationsService,
        lodash,
        $translate,
        apiClient,
        $rootScope
    ) {
        var vm = this;
        vm.notifications = notificationsCollectionResponse.notifications;
        vm.notificationsCollectionResponse = notificationsCollectionResponse;
        vm.minDate = moment().add(-12, 'months').format('MM-DD-YYYY, 23:59:59');

        vm.criteria = {};

        const notificationsSelectedForRead = {};
        vm.notificationSelectedForRead = 0;
        vm.allAreMarkedForRead = false;
        vm.markAsReadLoading = false;
        vm.markAllAsReadDisabled = (() => {
            let isDisabled = true;

            for (let notification of vm.notifications) {
                if (notification.isRead === false) {
                    isDisabled = false;
                    break;
                }
            }

            return isDisabled;
        })();

        vm.viewNotification = (notification) => {
            notificationsService.markAsRead(notification);
            notificationsService.goToNotificationLink(notification);
        }

        vm.criteria.typePatterns = {
            type: 'options',
            title: 'TYPE',
            multiple: true,
            value: $stateParams.typePatterns === undefined ? null : $stateParams.typePatterns,
            options: lodash.map(notificationsService.getTypeCategories(), c => ({
                title: $translate.instant(c.name),
                value: c.pattern.source
            }))
        };

        vm.criteria.readStatus = {
            type: 'options',
            title: 'NOTIFICATION_READ_STATUS',
            value: $stateParams.readStatus ? parseInt($stateParams.readStatus) : null,
            options: [
                {title: $translate.instant('NOTIFICATION_STATUS_ALL'), value: null},
                {title: $translate.instant('NOTIFICATION_STATUS_READ'), value: 1},
                {title: $translate.instant('NOTIFICATION_STATUS_UNREAD'), value: 2}
            ]
        };

        const createdAtData = (() => {
            const data = {
                defaultRangeOption: 3,
                options: [
                    {title: $translate.instant('LAST_ONE_MONTH'), value: 1},
                    {title: $translate.instant('LAST_THREE_MONTHS'), value: 3},
                    {title: $translate.instant('LAST_TWELVE_MONTHS'), value: 12},
                    {title: $translate.instant('CUSTOM'), value: 0}
                ]
            };

            const startDate = moment().subtract(data.defaultRangeOption, 'months').startOf('day');
            const endDate = moment().endOf('day');
            data.clearDateRangeValue = startDate.toISOString() + ',' + endDate.toISOString();

            return data;
        })();

        vm.criteria.dateRange = {
            type: 'date-time-range',
            title: 'CREATED_AT',
            startDateLabel: 'CREATED_AT_START_DATE',
            endDateLabel: 'CREATED_AT_END_DATE',
            value: $stateParams.dateRange ? $stateParams.dateRange : createdAtData.clearDateRangeValue,
            startDateRequired: true,
            endDateRequired: true,
            enableTime: false,
            options: createdAtData.options,
            defaultOption: createdAtData.defaultRangeOption,
            optionParam: 'createdAtRangeOption',
            clearValue: createdAtData.clearDateRangeValue,
            isClear: $stateParams.dateRange === createdAtData.clearDateRangeValue || $stateParams.dateRange === null,
            minDate: vm.minDate
        }

        vm.search = (params) => {
            var override = {
                page: 1
            };

            $state.go('.', angular.extend({}, params, override), { reload: $state.current });
        };

        // Single notification checkbox - selects or remove notification for marking as read
        vm.toggleMarkAsRead = (notification) => {
            if (notification.isRead) {
                return;
            }

            if (notificationsSelectedForRead.hasOwnProperty(notification.id)) {
                delete notificationsSelectedForRead[notification.id];
                notification.markedForRead = false;
                vm.notificationSelectedForRead--;
            } else {
                notificationsSelectedForRead[notification.id] = notification;
                notification.markedForRead = true;
                vm.notificationSelectedForRead++;
            }

            if (vm.notificationSelectedForRead === 0) {
                vm.allAreMarkedForRead = false;
            }
        }

        // Checkbox on click toggle all notifications for read
        vm.markAllAsRead = () => {
            vm.allAreMarkedForRead = !vm.allAreMarkedForRead;

            for (let notification of vm.notifications) {
                let setAll = (!!!notification.markedForRead && vm.allAreMarkedForRead);
                let unsetAll = (!!notification.markedForRead && !vm.allAreMarkedForRead);

                if (setAll || unsetAll) {
                    vm.toggleMarkAsRead(notification);
                }
            }
        }

        // Button Mark As Read Click action
        vm.markSelectedAsRead = () => {
            if (vm.markAsReadLoading === true) {
                return;
            }

            const notificationsIds = Object.keys(notificationsSelectedForRead);

            if (notificationsIds.length === 0) {
                return;
            }

            vm.markAsReadLoading = true;

            const cloneNotificationsSelectedForRead = Object.assign({}, notificationsSelectedForRead);

            const resolve = () => {
                vm.markAsReadLoading = false;

                angular.forEach(notificationsSelectedForRead, (notification) => {
                    vm.toggleMarkAsRead(notification);
                    notification.isRead = true;
                });

                vm.markAllAsReadDisabled = (() => {
                    let isDisabled = true;

                    for (let notification of vm.notifications) {
                        if (notification.isRead === false) {
                            isDisabled = false;
                            break;
                        }
                    }

                    return isDisabled;
                })();

                $rootScope.$broadcast('NotificationsMarkedAsRead', cloneNotificationsSelectedForRead);
            };

            apiClient.update(notificationsCollectionResponse.getLink('markasread'), {
                notificationsIds
            }).then(resolve).catch(resolve);
        }
    }
})();
