(function(){
    'use strict';

    angular
        .module('elogbooks.common.jobs')
        .controller('CommonJobRequisitesWorkflowController', CommonJobRequisitesWorkflowController);

    CommonJobRequisitesWorkflowController.$inject = ['jobRequisiteAnswerResponse', '$stateParams', 'base64', '$state', 'apiClient', 'messenger'];

    function CommonJobRequisitesWorkflowController(jobRequisiteAnswerResponse, $stateParams, base64, $state, apiClient, messenger) {
        var vm = this;

        vm.action = $state.current.name.indexOf('approve') > -1 ? 'approve' : $state.current.name.indexOf('reject') > -1 ? 'reject' : 'escalate';
        vm.translation = 'JOB_REQUISITE_ANSWER_' + vm.action.toUpperCase();
        vm.jobRequisiteAnswer = jobRequisiteAnswerResponse;
        vm.noteRequired = vm.action === 'reject' || vm.action === 'escalate';

        vm.data = {
            note: ''
        };

        vm.submit = submit;

        function submit() {
            apiClient.create(vm.jobRequisiteAnswer.getLink(vm.action), vm.data).then(function(response) {
                return $state.go('^', {}, { reload: $state.get('^.^')}).then(function() {
                    messenger.success('JOB_REQUISITE_ANSWER_' + vm.action.toUpperCase() + '_SUCCESS');
                });
            })
        }
    }
})();
