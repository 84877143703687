(function () {
    'use strict';

    angular
        .module('elogbooks.common.jobs.workflow')
        .controller('JobAddAditionalOperative', JobAddAditionalOperative);

    JobAddAditionalOperative.$inject = ['apiClient', '$state', 'lodash', 'formData', 'scopeData', 'siteResponse', 'operativeCollectionResponse', 'serviceProviderResponse'];

    function JobAddAditionalOperative (apiClient, $state, lodash, formData, scopeData, siteResponse, operativeCollectionResponse, serviceProviderResponse) {
        var vm = this;
        angular.extend(vm, scopeData);
        vm.submit = submitAction;
        vm.changeServiceProvider = changeServiceProvider;
        vm.changeOperative = changeOperative;
        vm.selectedServiceProviderModel.onSelect = vm.selectedServiceProviderModel.onRemove = vm.changeServiceProvider;
        vm.selectedServiceProviderModel.itemDisabledKey = '_links.serviceprovider.contractorApproved';
        vm.selectedServiceProviderModel.itemDisabledKeyValue = false;
        vm.selectedServiceProviderModel.itemDisabledHint = 'NOT_APPROVED';

        vm.selectedOperativeModel = {
            response: operativeCollectionResponse ? operativeCollectionResponse : null,
            responseKeyPath: 'operatives',
            itemValuePath:'_links.self.title',
            onSelect: vm.changeOperative,
            onRemove: vm.changeOperative,
        };

        vm.data = formData;

        function submitAction () {
            return apiClient.create(vm.job.getLink(vm.action), vm.data).then(function (response) {
                if (response) {
                    return $state.go('^.^', {}, { reload: $state.get('^.^.^') }).then(function () {
                        messenger.success(vm.translation + '_SUCCESS');
                    });
                }

                messenger.error('REQUEST_ERROR');
            });
        }

        function changeServiceProvider () {
            if (vm.selectedServiceProviderModel && vm.selectedServiceProviderModel.selected) {
                vm.submitDisabled = true;

                apiClient.get(vm.selectedServiceProviderModel.selected.href).then(function (response) {
                    if (response) {
                        vm.selectedServiceProvider = response;
                        vm.selectedOperativeModel.selected = null;
                        vm.changeOperative();

                        if (vm.selectedServiceProvider.getLink('joboperatives')) {
                            var params = {
                                permission: 'operative_permission_receive_jobs',
                                includeServiceTypes: true
                            };

                            if (siteResponse) {
                                params.siteId = siteResponse.id;
                            }
                            
                            if (vm.job) {
                                params.excludeAdditionalOperatives = vm.job.id;
                            }

                            apiClient.get(vm.selectedServiceProvider.getLink('joboperatives'), params)
                                .then(function(response) {
                                    // Exlude job operative
                                    var operative = vm.job.getLinkAttribute('operative', 'title');
                                    
                                    if (operative) {
                                        lodash.remove(response.operatives, function (operativeObject) {
                                            return operativeObject.getLinkAttribute('user', 'name') === operative;
                                        });
                                    }

                                    if (response.operatives) {
                                        vm.selectedOperativeModel.response = response;
                                    } else {
                                        vm.selectedOperativeModel.response = null;
                                    }
                                });
                        } else {
                          vm.selectedOperativeModel.response = null;
                        }

                        vm.submitDisabled = false;

                        return;
                    }

                    return apiClient.noResourceFound();
                });
            } else {
                vm.selectedServiceProvider = null;
                vm.selectedOperativeModel.selected = null;
                changeOperative();
            }
        }

        function changeOperative () {
            if (
                vm.selectedOperativeModel.selected
                && typeof vm.selectedOperativeModel.selected !== 'undefined'
            ) {
                vm.data._links.operative = vm.selectedOperativeModel.selected;
            } else {
                vm.selectedOperativeModel.selected = null;
                delete vm.data._links.operative;
            }
        }
    }
})();
