(function () {
    'use strict';

    angular
        .module('elogbooks.user.contracts')
        .controller('CommonContractDetailsP2PController', CommonContractDetailsP2PController);

        CommonContractDetailsP2PController.$inject = ['nominalCodingsCollectionResponse', 'contractResponse'];

    function CommonContractDetailsP2PController (nominalCodingsCollectionResponse, contractResponse) {
        var vm = this;
        
        vm.nominalCodings = nominalCodingsCollectionResponse.nominalCodings;
        vm.nominalCodingsCollectionResponse = nominalCodingsCollectionResponse;
        vm.contract = contractResponse;
        vm.getProcessed = getProcessed;

        function getProcessed(nominalCoding) {
            var processed = nominalCoding.processed
                ? 'P2P_HEXAGON_NOMINAL_ANALYSIS_PROCESSED_YES'
                : 'P2P_HEXAGON_NOMINAL_ANALYSIS_PROCESSED_NO';
            if (nominalCoding.processingErrorMsg) {
                processed =  'P2P_HEXAGON_NOMINAL_ANALYSIS_PROCESSED_ERROR'
            }

            return processed;
        }
    }
})();
