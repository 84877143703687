(function () {
    'use strict';

    angular
        .module('elogbooksControllers')
        .controller('ConfirmationModalNotesController', ConfirmationModalNotesController);

    ConfirmationModalNotesController.$inject = ['config', '$uibModalInstance'];

    function ConfirmationModalNotesController (config, $uibModalInstance) {
        var vm = this;
        vm.confirm = confirm;
        vm.close = close;
        vm.config = config;
        vm.notes = '';
        vm.rows = config.rows | 6;
        
        function close() {
            $uibModalInstance.dismiss(vm.notes);
        }
        
        function confirm() {
            $uibModalInstance.close(vm.notes);
        };
    };
})();
