(function() {
    'use strict';

    angular
        .module('elogbooks.common', [
            'elogbooks.common.actions',
            'elogbooks.common.audit',
            'elogbooks.common.assets',
            'elogbooks.common.change-password',
            'elogbooks.common.contacts',
            'elogbooks.common.contracts',
            'elogbooks.common.dashboard-layout',
            'elogbooks.common.files',
            'elogbooks.common.forms',
            'elogbooks.common.form-associations',
            'elogbooks.common.jobs',
            'elogbooks.common.kpi-surveys',
            'elogbooks.common.partnership',
            'elogbooks.common.priorities',
            'elogbooks.common.quote-requests',
            'elogbooks.common.set-password',
            'elogbooks.common.site-notes',
            'elogbooks.common.sites',
            'elogbooks.common.service-routines',
            'elogbooks.common.labour-lines',
            'elogbooks.common.survey',
            'elogbooks.common.meters',
            'elogbooks.common.performance-statistics',
            'elogbooks.common.site-contacts',
            'elogbooks.common.spillage-types',
            'elogbooks.common.waste-treatment-types',
            'elogbooks.common.operatives',
            'elogbooks.common.serviceproviders',
            'elogbooks.common.waste-management',
            'elogbooks.common.tasks',
            'elogbooks.common.document-sets',
            'elogbooks.common.webhooks',
            'elogbooks.common.sso',
            'elogbooks.common.finance'
        ]);
})();
