(function () {
    'use strict';

    angular
        .module('elogbooks.common.document-sets')
        .controller('CommonDocumentSetsController', CommonDocumentSetsController);

    CommonDocumentSetsController.$inject = [
        'selectedDocumentSetCollectionResponse',
        '$stateParams',
        '$state',
        'config',
        'confirmationModal',
        'messenger',
        'crudService'
    ];

    function CommonDocumentSetsController (
        selectedDocumentSetCollectionResponse,
        $stateParams,
        $state,
        config,
        confirmationModal,
        messenger,
        crudService
    ) {
        var vm = this;
        vm.documentSetCollectionResponse = selectedDocumentSetCollectionResponse;
        vm.documentSets = selectedDocumentSetCollectionResponse.documentSets;
        vm.entityCount = selectedDocumentSetCollectionResponse.count;
        vm.config = config;
        vm.entityName = config.entityName;
        vm.addSref = config.addSref;

        vm.search = search;
        vm.order = order;
        vm.delete = deleteDocumentSet;

        vm.criteria = {
            dlId: { type: 'number', value: $stateParams.dlId ? parseInt($stateParams.dlId, 10) : null, title: 'ID' },
            dlName: { type: 'text', value: $stateParams.dlName, title: 'NAME' }
        };

        function search (params) {
            var override = {
                dlPage: 1
            };

            $state.go('.', angular.extend({}, params, override), { reload: true });
        }

        function order (key) {
            $stateParams.dlOrder = key;

            var override = {
                dlPage: 1
            };

            $state.go('.', angular.extend({}, $stateParams, override), { reload: true });
        }

        function deleteDocumentSet(documentSet) {
            return crudService.delete(
                documentSet.getLink('delete'),
                null,
                null,
                {},
                true
            ).then(function () {
                messenger.success('DOCUMENT_SET_REMOVED');
                $state.go('.', {}, {reload: $state.get('^.^')});
            });
        }
    }

})();
