(function () {
    'use strict';

    angular
        .module('elogbooks.admin.users')
        .controller( 'UserDetailsEventFeedController', UserDetailsEventFeedController);

    UserDetailsEventFeedController.$inject = ['eventCollectionResponse','config','userResponse'];

    function UserDetailsEventFeedController (eventCollectionResponse, config, userResponse) {
        var vm = this;
        vm.feed = [];
        vm.eventCollectionResponse = eventCollectionResponse;

        vm.config = config;
        vm.entityResponse = userResponse;
    }
})();
