angular.module('elogbooksServices').service('bulkEditPatrolSchedulesModal', ['$uibModal', '$filter', function ($uibModal, $filter) {
    return {
        open: function (siteResponse, rowCount, callback, config) {
            return $uibModal.open({
                templateUrl: '/modules/common/modals/form/modal.html',
                controller: 'BulkEditPatrolSchedulesModalController',
                controllerAs: 'vm',
                resolve: {
                    config: function () {
                        var defaultConfig = {
                            titleMessage: 'ARE_YOU_SURE_HEADER',
                            bodyMessage: 'ARE_YOU_SURE',
                            primaryText: 'YES',
                            primaryClass: 'btn-danger',
                            secondaryText: 'NO',
                            secondaryClass: 'btn-default'
                        };

                        return angular.extend({}, defaultConfig, config);
                    },
                    partnershipCollection: function(apiClient) {

                        return apiClient.get(siteResponse.getLink('partnerships'));
                    },
                    callback: function() { return callback; },
                    rowCount: rowCount

                }
            });
        }
    };
}]);
