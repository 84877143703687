angular.module('elogbooksDirectives').directive('status', ['Statuses', function(Statuses){
    return {
        // restrict to attributes only
        restrict: 'A',
        require: ['ngModel'],
        scope: {
            status: '=ngModel'
        },
        templateUrl: '/modules/directives/form-elements/status/status.html',
        link: function(scope){
            scope.options = Statuses.getAll();
        }
    }
}]);
