(function() {
    'use strict';

    angular
        .module('elogbooks.admin.request-logs')
        .controller('RequestLogsListController', RequestLogsListController);

    RequestLogsListController.$inject = ["$state", "$stateParams", "$translate", "requestLogsCollectionResponse"];

    function RequestLogsListController($state, $stateParams, $translate, requestLogsCollectionResponse) {
        var vm = this;

        vm.requestLogsResponse = requestLogsCollectionResponse;
        vm.requestLogs = requestLogsCollectionResponse.requestlogs;

        var typeOptions = [
            {
                title: $translate.instant('ALL'),
                value: 'all'
            },
            {
                title: $translate.instant('WEBHOOK'),
                value: 'webhook'
            },
            {
                title: $translate.instant('NON_WEBHOOK'),
                value: 'non-webhook'
            },
        ];

        var webhookTypeOptions = [
            {
                title: $translate.instant('ALL'),
                value: 'all'
            },
            {
                title: $translate.instant('SITE'),
                value: 'site'
            },
            {
                title: $translate.instant('PARTNERSHIP'),
                value: 'partnership'
            },
        ];


        vm.statuses = [
            'QUEUED',
            'COMPLETE',
            'ERROR'
        ];

        vm.criteria = {
            type: { type: 'options', title: 'TYPE', value: $stateParams.type, options: typeOptions},
            webhookType: {  type: 'options', title: 'WEBHOOK_TYPE', value: $stateParams.webhookType, options: webhookTypeOptions },
            responseStatusCode: { type: 'number', title: 'RESPONSE_STATUS', value: $stateParams.responseStatusCode ? parseInt($stateParams.responseStatusCode) : null},
            dateRange: {
                type: 'date-range',
                title: 'CREATED_BETWEEN',
                value: {
                    startDate: ($stateParams.dateRange !== null) ? moment(new Date($stateParams.dateRange.split(',')[0])) : moment().subtract(1, 'month'),
                    endDate: ($stateParams.dateRange !== null) ? moment(new Date($stateParams.dateRange.split(',')[1])) : moment(),
                    endMaxDate: moment().format('MM-DD-YYYY, 23:59:59')
                },
                clearValue: {
                    startDate: moment().subtract(1, 'month').startOf('day'),
                    endDate: moment().endOf('day')
                }
            },
        };

        vm.search = searchAction;

        function searchAction(params) {
            var override = {
                page: 1
            };

            $state.go('.', angular.extend({}, params, override), {reload: $state.current});
        }
    }
})();