(function () {
    'use strict';

    angular
        .module('elogbooks.user.reports')
        .controller('UserReportsSiteAssociateQuotesApprovedController', UserReportsSiteAssociateQuotesApprovedController);

    UserReportsSiteAssociateQuotesApprovedController.$inject = ['quotesCollection'];

    function UserReportsSiteAssociateQuotesApprovedController(quotesCollection) {
        var vm = this;

        vm.quotesCollection = quotesCollection;
        vm.quotes = quotesCollection.quotes;
    }
})();