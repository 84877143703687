(function(){
    'use strict';

    angular
        .module('elogbooksControllers')
        .controller('FormModalController', FormModalController);

    FormModalController.$inject = ['$uibModalInstance', 'config', 'requestDataFactory', '$q'];

    function FormModalController($uibModalInstance, config, requestDataFactory, $q) {
        var vm = this;

        vm.entityName = config.entityName;
        vm.data = config.data || requestDataFactory.createRequest(config.responseEntity);
        vm.responseEntity = config.responseEntity;
        vm.title = config.title;
        vm.submitButtonText = config.submitButtonText;
        vm.formControlsTemplate = config.formControlsTemplate;
        vm.statusOptions = config.statusOptions;

        vm.close = closeAction;
        vm.onClose = config.onClose;
        vm.create = vm.update = submitAction;

        function submitAction() {
            if (typeof vm.onClose === "function") {
                $q.resolve(vm.onClose()).then((res) => {
                    if (!res.error) {
                        $uibModalInstance.close(vm.data);
                    }
                })
            } else {
                $uibModalInstance.close(vm.data);
            }
        }

        function closeAction() {
            $uibModalInstance.dismiss('cancel');
        }
    }
})();
