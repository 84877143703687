app.config( function($translateProvider) {
    $translateProvider.translations('en-gb', {
        METER_SUPPLIER_INFORMATION: 'Meter Supplier Information',

        ADD_NEW_METER_SUPPLIER: 'Add a new Meter Supplier',
        EDIT_METER_SUPPLIER: 'Edit Meter Supplier',

        METER_SUPPLIER_LIST: 'Meter Suppliers',
        METER_SUPPLIER_ADD: 'Add a new Meter Supplier',
        METER_SUPPLIER_EDIT: 'Edit Meter Supplier',

        METER_SUPPLIER_CREATED: 'Meter Supplier created successfully',
        METER_SUPPLIER_UPDATED: 'Meter Supplier updated successfully',
        METER_SUPPLIER_DELETED: 'Meter Supplier deleted successfully',
    });
});
