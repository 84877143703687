angular.module('elogbooksServices')
    .service('lightBoxModal', ['$uibModal','apiClient', function ($uibModal) {
        return {
            open: function (modalData, config) {
                return $uibModal.open({
                    templateUrl: '/modules/common/modals/lightBox/lightbox.html',
                    controller: 'LightBoxModalController',
                    controllerAs: 'vm',
                    resolve: {
                        config: function () {
                            var defaultConfig = {
                                titleMessage: 'ARE_YOU_SURE_HEADER',
                                bodyMessage: 'ARE_YOU_SURE',
                                primaryText: 'YES',
                                primaryClass: 'btn-danger',
                                secondaryText: 'NO',
                                secondaryClass: 'btn-default'
                            };

                            return angular.extend({}, defaultConfig, config);
                        },
                        modalData: function(){
                            return modalData;
                        }
                    }
                });
            }
        };
    }]);
