(function() {
    'use strict';

    angular
        .module('elogbooks.user.finance.purchase-invoices')
        .controller('FinancePurchaseInvoicesInfoController', FinancePurchaseInvoicesInfoController);

    FinancePurchaseInvoicesInfoController.$inject = [
    ];

    function FinancePurchaseInvoicesInfoController() {
        var vm = this;
    }
})();
