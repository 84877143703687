angular.module('elogbooksDirectives').directive('contracthistory', [function () {
    return {
        restrict: 'AE',
        require: ['ngModel'],
        scope: {
            contract: '=ngModel',
        },
        templateUrl: '/modules/directives/contract/history/contracthistory.html',
        controller: function ($scope) {

            $scope.requestStatuses = {};

            angular.forEach($scope.contract._embedded.events.events, function (item, index) {

                if (item.type === 'contract_approval_accepted') {
                    $scope.requestStatuses[item._links.request.id] = 'ACCEPTED';
                } else if (item.type === 'contract_approval_rejected') {
                    $scope.requestStatuses[item._links.request.id] = 'REJECTED';
                } else if (item.type === 'contract_terminated') {
                    $scope.terminated = {
                        terminated: true,
                        terminatedAt: new Date(item.createdAt)
                    };
                }

            });
        }
    };
}]);
