(function() {
    'use strict';

    angular
        .module('elogbooks.admin.timesheet-types')
        .controller('TimesheetTypesAddEditController', TimesheetTypesAddEditController);

    TimesheetTypesAddEditController.$inject = ['$state', 'apiClient', 'confirmationModal', 'messenger', 'requestDataFactory', 'timesheetTypeResponse', 'timesheetTypesCollectionResponse'];
    function TimesheetTypesAddEditController($state, apiClient, confirmationModal, messenger, requestDataFactory, timesheetTypeResponse, timesheetTypesCollectionResponse) {

        var vm = this;
        vm.timesheetType = timesheetTypeResponse;
        vm.data = requestDataFactory.createRequest(vm.timesheetType);
        vm.timesheetTypesCollectionResponse = timesheetTypesCollectionResponse;
        vm.create = createAction;
        vm.update = updateAction;
        vm.delete = deleteAction;

        function updateAction() {
            return apiClient.update(vm.timesheetType.getLink('edit'), vm.data).then(function(response) {
                if (response) {
                    $state.go('dashboard.admin.timesheet-types.list', {}, {reload: 'dashboard.admin.timesheet-types.list'}).then(function() {
                        messenger.success('TIMESHEET_TYPE_UPDATED');
                    });
                } else {
                    messenger.error('REQUEST_ERROR');
                }
            });
        }

        function createAction() {
            return apiClient.create(vm.timesheetTypesCollectionResponse.getLink('create'), vm.data).then(function(response) {
                if (response) {
                    $state.go('dashboard.admin.timesheet-types.list', {resource: response.getLink('self').encode()}, {reload: $state.current.parent}).then(function() {
                        messenger.success('TIMESHEET_TYPE_CREATED');
                    });
                } else {
                    messenger.error('REQUEST_ERROR');
                }
            });
        }

        function deleteAction() {
            return confirmationModal.open().result.then(function(response) {
                if (response.result) {
                    apiClient.delete(vm.timesheetType.getLink('delete')).then(function(isSuccess) {
                        if (isSuccess) {
                            $state.go('dashboard.admin.timesheet-types.list', {}, {reload: 'dashboard.admin.timesheet-types.list'}).then(function() {
                                messenger.success('TIMESHEET_TYPE_DELETED');
                            });
                        } else {
                            messenger.error('REQUEST_ERROR');
                        }
                    });
                }
            });
        }
    }
})();
