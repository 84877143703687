(function() {
    'use strict';

    angular
        .module('elogbooks.common.quote-requests')
        .controller('CommonQuoteRequestDetailsQuoteDetailsController', CommonQuoteRequestDetailsQuoteDetailsController);

    CommonQuoteRequestDetailsQuoteDetailsController.$inject = [
        'quoteResponse',
        'quoteRequestResponse',
        'apiClient',
        'fileCollectionResponse',
        'elbSettings',
        'quoteLink'
    ];

    function CommonQuoteRequestDetailsQuoteDetailsController(
        quoteResponse,
        quoteRequestResponse,
        apiClient,
        fileCollectionResponse,
        elbSettings,
        quoteLink
    ) {
        var vm = this;
        vm.quoteResponse = quoteResponse;
        vm.quoteRequestResponse = quoteRequestResponse;
        vm.attributes = quoteResponse.attributes;
        vm.quoteLink = quoteLink;

        if (fileCollectionResponse) {
            vm.supportingDocsCount = fileCollectionResponse.count;
            vm.fileCollectionResponse = fileCollectionResponse;
        } else {
            apiClient.get(vm.quoteResponse.getLink('files')).then(function(response) {
                fileCollectionResponse = response;
                vm.fileCollectionResponse = response;
                vm.supportingDocsCount = response.count;
            });
        }
    }
})();
