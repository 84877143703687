(function() {
    'use strict';

    angular
        .module('elogbooks')
        .config(Translations);

    function Translations($translateProvider) {
        $translateProvider.translations('zh-hk', {
            ACTION_CREATED_ACTION: '<strong>{{ event._links["user"].title }}</strong> 指令已建立',
            ACTION_COMPLETED_ACTION: '<strong>{{ event._links["user"].title }}</strong> 指令已完成',
            ACTION_CANCELLED_ACTION: '<strong>{{ event._links["user"].title }}</strong> 指令已取消',
            ACTION_REOPENED_ACTION: '<strong>{{ event._links["user"].title }}</strong> 指令已重開',
            ACTION_REASSIGNED_ACTION: '<strong>{{ event._links["user"].title }}</strong> 指令已分配給 <strong>{{ event._links["assignee"].title }}</strong>',

            ACTION_MESSAGE_ACTION: '<strong>{{ message._links["sender"].title }}</strong> 訊息已新增',

            ACTION_JOB_CREATED_ACTION: '<strong>{{ event._links["user"].title }}</strong> 工作已建立',
            ACTION_QUOTE_REQUEST_CREATED_ACTION: '<strong>{{ event._links["user"].title }}</strong> 報價請求已建立',
        });
    }
})();
