app.config(function ($translateProvider) {
    $translateProvider.translations('en-gb', {
        OPERATIVE_PERMISSIONS: 'Operative Permissions',

        ADD_OPERATIVE: 'Add Operative',

        OPERATIVE_PERMISSION_VIEW_ASSETS: 'View Assets',
        OPERATIVE_PERMISSION_CREATE_QUOTES: 'Create Quotes',
        OPERATIVE_PERMISSION_VIEW_QUOTES:   'View Quotes',
        OPERATIVE_PERMISSION_EDIT_ASSETS: 'Edit Assets',
        OPERATIVE_PERMISSION_VIEW_TASKS: 'View Tasks',
        OPERATIVE_PERMISSION_EDIT_TASKS: 'Edit Tasks',
        OPERATIVE_PERMISSION_CREATE_TASKS: 'Create Tasks',
        OPERATIVE_PERMISSION_VIEW_FILES: 'View Files',
        OPERATIVE_PERMISSION_EDIT_FILES: 'Edit Files',
        OPERATIVE_PERMISSION_VIEW_PATROL_INSTANCES: 'View Patrol Instances',
        OPERATIVE_PERMISSION_EDIT_PATROL_INSTANCES: 'Edit Patrol Instances',
        OPERATIVE_PERMISSION_RECEIVE_PATROL_INSTANCES: 'Receive Patrol Instances',
        OPERATIVE_PERMISSION_REASSIGN_JOB_OPERATIVE: 'Reassign Job Operative',

        OPERATIVE_PERMISSION_CREATE_REACTIVE_JOBS: 'Create Reactive Jobs',
        OPERATIVE_PERMISSION_CREATE_REACTIVE_JOBS_FOR_OTHER_SERVICE_PROVIDERS: 'Create Reactive Jobs for other Service Providers',
        OPERATIVE_PERMISSION_EDIT_CONTRACTS: 'Edit Contracts',
        OPERATIVE_PERMISSION_EDIT_JOBS: 'Edit Jobs',
        OPERATIVE_PERMISSION_REJECT_INSIST_JOBS: 'Reject or Insist Shared Service Provider Jobs',
        OPERATIVE_PERMISSION_EDIT_METER_READINGS: 'Edit Meter Readings',
        OPERATIVE_PERMISSION_EDIT_METERS: 'Edit Meters',
        OPERATIVE_PERMISSION_MANAGE_OPERATIVES: 'Manage Operatives',
        OPERATIVE_PERMISSION_VIEW_AUDITS: 'View Audits',
        OPERATIVE_PERMISSION_EDIT_AUDIT_REFERENCE: 'Edit Audit Reference',
        OPERATIVE_PERMISSION_RECEIVE_AUDITS: 'Receive Audits',
        OPERATIVE_PERMISSION_RECEIVE_ALL_EMAILS: 'Receive All Emails',
        OPERATIVE_PERMISSION_RECEIVE_CONTRACTS: 'Receive Contracts',
        OPERATIVE_PERMISSION_RECEIVE_ESCALATIONS: 'Receive Escalations',
        OPERATIVE_PERMISSION_RECEIVE_JOBS: 'Receive Jobs',
        OPERATIVE_PERMISSION_REQUEST_PLANNED_JOBS: 'Request Planned Jobs',
        OPERATIVE_PERMISSION_VIEW_CONTRACTS: 'View Contracts',
        OPERATIVE_PERMISSION_VIEW_JOBS: 'View Jobs',
        OPERATIVE_PERMISSION_VIEW_LINK_JOBS: 'View Link for jobs',
        OPERATIVE_PERMISSION_REMOVE_LINK_JOBS: 'Delete Link for jobs',
        OPERATIVE_PERMISSION_VIEW_METERS: 'View Meters',
        OPERATIVE_PERMISSION_VIEW_SITES: 'View Sites',
        OPERATIVE_PERMISSION_VIEW_SURVEYS: 'View Surveys',
        OPERATIVE_PERMISSION_EDIT_SURVEYS: 'Edit Surveys',
        OPERATIVE_PERMISSION_CREATE_SURVEYS: 'Create Surveys',
        OPERATIVE_PERMISSION_VIEW_CONTACTS: 'View Contacts',
        OPERATIVE_PERMISSION_VIEW_APPROVERS: 'View Site Approvers',
        OPERATIVE_PERMISSION_ASSET_USER_IMPORTS: 'Import Assets',
        OPERATIVE_PERMISSION_TASK_USER_IMPORTS: 'Import Tasks',
        OPERATIVE_PERMISSION_ASSET_TASK_USER_IMPORTS: 'Planner Import',
        OPERATIVE_PERMISSION_FORCE_COMPLETE_AUDITS: 'Force Complete Audits',
        OPERATIVE_PERMISSION_FORCE_COMPLETE_AUDITS_DETAIL: 'The ability to Force Complete an Audit',
        OPERATIVE_PERMISSION_EDIT_AUDIT_REFERENCE_DETAIL: 'Edit Audit Reference When Audit Is Active',

        OPERATIVE_PERMISSION_VIEW_ASSETS_DETAIL: ' ',
        OPERATIVE_PERMISSION_CREATE_QUOTES_DETAIL: ' ',
        OPERATIVE_PERMISSION_VIEW_QUOTES_DETAIL:  ' ',
        OPERATIVE_PERMISSION_EDIT_ASSETS_DETAIL: ' ',
        OPERATIVE_PERMISSION_VIEW_TASKS_DETAIL: ' ',
        OPERATIVE_PERMISSION_EDIT_TASKS_DETAIL: ' ',
        OPERATIVE_PERMISSION_CREATE_TASKS_DETAIL: ' ',
        OPERATIVE_PERMISSION_VIEW_FILES_DETAIL: ' ',
        OPERATIVE_PERMISSION_EDIT_FILES_DETAIL: ' ',
        OPERATIVE_PERMISSION_RECEIVE_AUDITS_DETAIL: ' ',
        OPERATIVE_PERMISSION_VIEW_AUDITS_DETAIL: ' ',
        OPERATIVE_PERMISSION_VIEW_PATROL_INSTANCES_DETAIL: ' ',
        OPERATIVE_PERMISSION_EDIT_PATROL_INSTANCES_DETAIL: ' ',
        OPERATIVE_PERMISSION_RECEIVE_PATROL_INSTANCES_DETAIL: ' ',
        OPERATIVE_PERMISSION_VIEW_APPROVERS_DETAIL: 'View Site Approvers',

        OPERATIVE_PERMISSION_CREATE_REACTIVE_JOBS_DETAIL: '',
        OPERATIVE_PERMISSION_CREATE_REACTIVE_JOBS_FOR_OTHER_SERVICE_PROVIDERS_DETAIL: '',
        OPERATIVE_PERMISSION_EDIT_CONTRACTS_DETAIL: '',
        OPERATIVE_PERMISSION_EDIT_JOBS_DETAIL: '',
        OPERATIVE_PERMISSION_EDIT_METER_READINGS_DETAIL: 'The ability to create, update and delete Meter Readings for Meters associated with this Service Provider',
        OPERATIVE_PERMISSION_EDIT_METERS_DETAIL: 'The ability to create, update and delete Meters associated with this Service Provider',
        OPERATIVE_PERMISSION_MANAGE_OPERATIVES_DETAIL: '',
        OPERATIVE_PERMISSION_RECEIVE_ALL_EMAILS_DETAIL: '',
        OPERATIVE_PERMISSION_RECEIVE_CONTRACTS_DETAIL: '',
        OPERATIVE_PERMISSION_RECEIVE_JOBS_DETAIL: '',
        OPERATIVE_PERMISSION_REQUEST_PLANNED_JOBS_DETAIL: '',
        OPERATIVE_PERMISSION_VIEW_CONTRACTS_DETAIL: '',
        OPERATIVE_PERMISSION_VIEW_JOBS_DETAIL: '',
        OPERATIVE_PERMISSION_VIEW_METERS_DETAIL: 'The ability to view Meters associated with this Service Provider',
        OPERATIVE_PERMISSION_VIEW_SITES_DETAIL: 'Inherit Site Access from this Service Provider',
        OPERATIVE_PERMISSION_VIEW_SURVEYS_DETAIL: '',
        OPERATIVE_PERMISSION_EDIT_SURVEYS_DETAIL: '',
        OPERATIVE_PERMISSION_CREATE_SURVEYS_DETAIL: '',
        OPERATIVE_PERMISSION_VIEW_CONTACTS_DETAIL: 'The ability to see a Site\'s Contacts\'',
        OPERATIVE_PERMISSION_P2P: 'P2P',
        OPERATIVE_PERMISSION_P2P_DETAIL: '',
        OPERATIVE_PERMISSION_ASSET_USER_IMPORTS_DETAIL: 'Import Assets',
        OPERATIVE_PERMISSION_TASK_USER_IMPORTS_DETAIL: 'Import Tasks',
        OPERATIVE_PERMISSION_ASSET_TASK_USER_IMPORTS_DETAIL: 'The ability to import tasks and assets',
        OPERATIVE_PERMISSION_REASSIGN_JOB_OPERATIVE_DETAIL: 'The ability to reassign Job Operative',
        OPERATIVE_PERMISSION_JOB_OPERATIVE: 'View Operatives',
        OPERATIVE_PERMISSION_JOB_OPERATIVE_DETAIL: 'Ability to select an operative on job',

        OPERATIVE_PERMISSION_BULK_REASSIGN_OPERATIVE_JOBS: 'Bulk Reassign Job Operatives',
        OPERATIVE_PERMISSION_BULK_REASSIGN_OPERATIVE_JOBS_DETAIL: 'Ability to bulk reassign job operatives.',
        OPERATIVE_PERMISSION_BULK_CANCEL_JOBS: 'Bulk Cancel Jobs',
        OPERATIVE_PERMISSION_BULK_CANCEL_JOBS_DETAIL: 'Ability to bulk cancel jobs.',

        OPERATIVE_PERMISSION_EDIT_METER_REFERENCES: 'Edit Meter References',

        OPERATIVE_PERMISSION_NOTIFY_UPDATES: 'Notify Job/Quote Contacts',

        OPERATIVE_PERMISSION_CAN_SCORE_ASSETS: 'Can Score Assets',
        OPERATIVE_PERMISSION_CAN_SCORE_ASSETS_DETAIL: 'Ability to score an asset on job completion.',

        OPERATIVE_PERMISSION_EDIT_SCORE_TYPES_ON_ASSET: 'Edit Score Types On Asset',
        OPERATIVE_PERMISSION_EDIT_SCORE_TYPES_ON_ASSET_DETAIL: 'Edit Score Types when creating/editing Assets',
        OPERATIVE_PERMISSION_OPEN_CLOSE: 'Create open/close shop record',
        OPERATIVE_PERMISSION_OPEN_CLOSE_DETAIL: 'The ability to create open/close shop record',

        OPERATIVE_PERMISSION_VIEW_LABOUR_LINES: 'View Job Labour Lines',
        OPERATIVE_PERMISSION_EDIT_LABOUR_LINES: 'Edit Job Labour Lines',
        OPERATIVE_PERMISSION_CREATE_LABOUR_LINES: 'Create Job Labour Lines',

        OPERATIVE_PERMISSION_VIEW_LABOUR_LINES_DETAIL: 'View Job Labour Lines',
        OPERATIVE_PERMISSION_EDIT_LABOUR_LINES_DETAIL: 'Edit Job Labour Lines',
        OPERATIVE_PERMISSION_CREATE_LABOUR_LINES_DETAIL: 'Create Job Labour Lines',

        OPERATIVE_PERMISSION_CREATE_SPILLAGES_FOR_OTHERS: 'Report Spillages for Others',
        OPERATIVE_PERMISSION_CREATE_SPILLAGES_FOR_OTHERS_DETAIL: 'The ability to report spillages and assign other service providers',
        OPERATIVE_PERMISSION_RECEIVE_SPILLAGES: 'Receive Spillages',
        OPERATIVE_PERMISSION_RECEIVE_SPILLAGES_DETAIL: 'Operative can receive spillages',
        OPERATIVE_PERMISSION_CREATE_SPILLAGES: 'Create Spillages',
        OPERATIVE_PERMISSION_CREATE_SPILLAGES_DETAIL: 'The ability to create spillages',
        OPERATIVE_PERMISSION_EDIT_SPILLAGES: 'Edit reported Spillages',
        OPERATIVE_PERMISSION_EDIT_SPILLAGES_DETAIL: 'The ability to edit reported spillages',
        OPERATIVE_PERMISSION_VIEW_SPILLAGES: 'View Spillages',
        OPERATIVE_PERMISSION_VIEW_SPILLAGES_DETAIL: 'The ability to view spillages',

        OPERATIVE_PERMISSION_CREATE_SURVEY_SCHEDULES: 'Create Survey Schedules',
        OPERATIVE_PERMISSION_CREATE_SURVEY_SCHEDULES_DETAILS: 'The ability to create KPI survey schedules',
        OPERATIVE_PERMISSION_EDIT_SURVEY_SCHEDULES: 'Edit Survey Schedules',
        OPERATIVE_PERMISSION_EDIT_SURVEY_SCHEDULES_DETAILS: 'The ability to edit KPI survey schedules',

        OPERATIVE_PERMISSIONS_UPDATED: 'Operative Permissions Updated',

        OPERATIVE_PERMISSION_REJECT_INSIST_JOBS_DETAIL: 'The ability to reject or insist a job for a shared Service Provider',

        OPERATIVE_PERMISSION_VIEW_WASTE_ENTRIES: 'View Waste Entries',
        OPERATIVE_PERMISSION_VIEW_WASTE_ENTRIES_DETAIL: 'The ability to view waste entries',
        OPERATIVE_PERMISSION_CREATE_WASTE_ENTRIES: 'Create Waste Entries',
        OPERATIVE_PERMISSION_CREATE_WASTE_ENTRIES_DETAIL: 'The ability to create waste entries',
        OPERATIVE_PERMISSION_EDIT_WASTE_ENTRIES: 'Edit Waste Entries',
        OPERATIVE_PERMISSION_EDIT_WASTE_ENTRIES_DETAIL: 'The ability to edit waste entries',
        OPERATIVE_PERMISSION_IMPORT_WASTE_ENTRIES: 'Import Waste Entries',
        OPERATIVE_PERMISSION_IMPORT_WASTE_ENTRIES_DETAIL: 'The ability to import waste entries',

        OPERATIVE_PERMISSION_VIEW_LINK_JOBS_DETAIL: 'The ability to link jobs',
        OPERATIVE_PERMISSION_REMOVE_LINK_JOBS_DETAIL: 'The ability to remove link jobs',

        OPERATIVE_PERMISSION_WAIVE_MANAGEMENT_FEE: 'Waive Management Fee',
        OPERATIVE_PERMISSION_WAIVE_MANAGEMENT_FEE_DETAIL: 'Ability to waive management fee',
        OPERATIVE_PERMISSION_CREATE_WEBHOOKS: 'Create Webhooks',
        OPERATIVE_PERMISSION_CREATE_WEBHOOKS_DETAIL: 'The ability to create webhooks',
        OPERATIVE_PERMISSION_EDIT_WEBHOOKS: 'Edit Webhooks',
        OPERATIVE_PERMISSION_EDIT_WEBHOOKS_DETAIL: 'The ability to edit webhooks',
        OPERATIVE_PERMISSION_VIEW_WEBHOOKS: 'View Webhooks',
        OPERATIVE_PERMISSION_VIEW_WEBHOOKS_DETAIL: 'The ability to webhooks',
        OPERATIVE_PERMISSION_VIEW_ACTIONS: 'View Actions',
        OPERATIVE_PERMISSION_VIEW_ACTIONS_DETAIL: 'The ability to view actions where action type \'allow service provider access\' is enabled',
        OPERATIVE_PERMISSION_CREATE_ACTIONS: 'Create Actions',
        OPERATIVE_PERMISSION_CREATE_ACTIONS_DETAIL: 'The ability to create actions where action type \'allow service provider access\' is enabled',
        OPERATIVE_PERMISSION_EDIT_ACTIONS: 'Edit Actions',
        OPERATIVE_PERMISSION_EDIT_ACTIONS_DETAIL: 'The ability to edit actions where action type \'allow service provider access\' is enabled',
        OPERATIVE_PERMISSION_MANAGE_ACTIONS: 'Can Manage Actions',
        OPERATIVE_PERMISSION_MANAGE_ACTIONS_DETAIL: 'Ability to complete and cancel actions where action type \'Allow service provider access\' = Yes and raise Jobs / Quotes',
        OPERATIVE_PERMISSION_VIEW_PURCHASE_ORDERS: 'View Purchase Orders',
        OPERATIVE_PERMISSION_VIEW_PURCHASE_ORDERS_DETAIL: 'The ability to view Purchase Orders',
        OPERATIVE_PERMISSION_CREATE_PURCHASE_ORDERS: 'Create Purchase Orders',
        OPERATIVE_PERMISSION_CREATE_PURCHASE_ORDERS_DETAIL: 'The ability to create Purchase Orders',
        OPERATIVE_PERMISSION_EDIT_PURCHASE_ORDERS: 'Edit Purchase Orders',
        OPERATIVE_PERMISSION_EDIT_PURCHASE_ORDERS_DETAIL: 'The ability to edit Purchase Orders',
        OPERATIVE_PERMISSION_RECEIVE_QUOTES: 'Receive Quotes',
        OPERATIVE_PERMISSION_RECEIVE_QUOTES_DETAIL: ' ',
        OPERATIVE_PERMISSION_CAN_ADD_ADDITIONAL_OPERATIVES: 'Can Add Additional Operatives',
        OPERATIVE_PERMISSION_CAN_ADD_ADDITIONAL_OPERATIVES_DETAIL: 'The ability to add additional operatives',
    });
});
