(function () {
    'use strict';

    angular
        .module('elogbooksControllers')
        .controller('advancedServiceProviderFiltersModalController', advancedServiceProviderFiltersModalController);

    advancedServiceProviderFiltersModalController.$inject = ['$state', '$stateParams', 'config', '$uibModalInstance', '$rootScope',
        '$scope', '$translate', 'lodash', 'moment', 'serviceProviderGroups', 'sitesCollectionResponse', 'paramConverterService'];
    function advancedServiceProviderFiltersModalController($state, $stateParams, config, $uibModalInstance, $rootScope,
        $scope, $translate, lodash, moment, serviceProviderGroups, sitesCollectionResponse, paramConverterService) {

        var vm = this;
        vm.config = config;
        vm.close = close;
        vm.update = update;
        vm.saveParams = saveParams;

        var contractorApprovedOptions = [
            {title: $translate.instant('NONE_SELECTED'), value: null},
            {title: $translate.instant('YES'), value: '1'},
            {title: $translate.instant('NO'), value: '0'}
        ];

        var statusOptions = [
            {title: $translate.instant('NONE_SELECTED'), value: null},
            {title: $translate.instant('STATUS_ACTIVE'), value: 'active'},
            {title: $translate.instant('STATUS_INACTIVE'), value: 'inactive'},
            {title: $translate.instant('STATUS_TRAINING'), value: 'training'}
        ];

        vm.advancedCriteria = {
            name: {type: 'text', title: 'NAME', value: $stateParams.name},
            reference: {type: 'text', title: 'REFERENCE', value: $stateParams.reference},
            serviceProvidersTelephone: {type: 'text', title: 'TELEPHONE', value: $stateParams.serviceProvidersTelephone},
            serviceProvidersEmail: {type: 'text', title: 'EMAIL', value: $stateParams.serviceProvidersEmail},
            serviceProvidersAddress: {type: 'text', title: 'ADDRESS', value: $stateParams.serviceProvidersAddress},
            activeAtStart: {
                type: 'date',
                title: 'ACTIVE_AT_START',
                value: (typeof $stateParams.activeAtStart !== 'undefined' && $stateParams.activeAtStart !== null) ? moment(new Date($stateParams.activeAtStart)) : null
            },
            activeAtEnd: {
                type: 'date',
                title: 'ACTIVE_AT_END',
                value: (typeof $stateParams.activeAtEnd !== 'undefined' && $stateParams.activeAtEnd !== null) ? moment(new Date($stateParams.activeAtEnd)) : null
            }
        };

        if (!vm.config.allServiceProviderTab) {
            vm.advancedCriteria.trainedAtEnd = {
                type: 'date',
                title: 'TRAINED_AT_END',
                value: (typeof $stateParams.trainedAtEnd !== 'undefined' && $stateParams.trainedAtEnd !== null) ? moment(new Date($stateParams.trainedAtEnd)) : null
            };

            vm.advancedCriteria.trainedAtStart = {
                type: 'date',
                title: 'TRAINED_AT_START',
                value: (typeof $stateParams.trainedAtStart !== 'undefined' && $stateParams.trainedAtStart !== null) ? moment(new Date($stateParams.trainedAtStart)) : null
            };

            vm.advancedCriteria.contractorApproved = {
                type: 'options',
                title: 'CONTRACTOR_APPROVED',
                value: $stateParams.contractorApproved,
                options: contractorApprovedOptions,
                clearValue: null
            };

            if (sitesCollectionResponse) {
                vm.partnershipSelect = {
                    link: sitesCollectionResponse.getLink('self'),
                    responseKeyPath: 'sites',
                    itemValuePath: 'name',
                    itemHrefPath: 'id',
                    onRemove: vm.saveParams,
                    onSelect: vm.saveParams,
                    mapValue: 'partnerships',
                    autocompletemulti: true,
                    advanced: true,
                    disabled: $stateParams.selectedSiteResource
                };

                vm.advancedCriteria.partnerships = {
                    type: 'autocompletemulti',
                    multiple: true,
                    title: 'PARTNERSHIPS',
                    value: $stateParams.partnerships,
                    options: vm.partnershipSelect,
                    param: 'partnerships',
                    max: 50
                };
            }
        }

        if ($state.current.name === 'dashboard.admin.service-providers.list' || $state.current.name === 'dashboard.user.service-providers.list') {
            vm.advancedCriteria.status = {
                type: 'options',
                title: 'SERVICE_PROVIDER_STATUS',
                value: $stateParams.status,
                options: statusOptions,
                clearValue: null
            };
        }

        if ($state.current.name === 'dashboard.admin.sites.list.details.partnerships' || $state.current.name === 'dashboard.user.sites.list.details.partnerships') {
            vm.advancedCriteria.serviceProvidersStatus = {
                type: 'options',
                title: 'SERVICE_PROVIDER_STATUS',
                value: $stateParams.serviceProvidersStatus,
                options: statusOptions,
                clearValue: null
            }
        }

        if ('partnershipActive' in $stateParams) {
            var partnershipStatusOptions = [
                {title: $translate.instant('ALL'), value: null},
                {title: $translate.instant('STATUS_ACTIVE'), value: '1'},
                {title: $translate.instant('STATUS_INACTIVE'), value: '0'}
            ];

            vm.advancedCriteria.partnershipActive = {
                type: 'options',
                title: 'PARTNERSHIP_STATUS',
                value: $stateParams.partnershipActive,
                options: partnershipStatusOptions,
                clearValue: null
            };
        }

        if (serviceProviderGroups && serviceProviderGroups.groups.length !== 0) {
            vm.serviceProviderGroupsSelect = {
                link: serviceProviderGroups.getLink('self'),
                responseKeyPath: 'groups',
                itemValuePath: 'name',
                itemHrefPath: 'id',
                onRemove: vm.saveParams,
                onSelect: vm.saveParams,
                mapValue: 'serviceProviderGroups',
                autocompletemulti: true,
                advanced: true
            };

            vm.advancedCriteria.serviceProviderGroups = {
                type: 'autocompletemulti',
                multiple: true,
                title: 'SERVICE_PROVIDER_GROUPS',
                value: $stateParams.serviceProviderGroups,
                options: vm.serviceProviderGroupsSelect,
                param: 'serviceProviderGroups',
                max: 50
            };
        }

        var params = {};

        function saveParams(selectModel) {
            params = paramConverterService.saveParams(selectModel, params);
        }

        function close() {
            if (!$scope.form.$pristine) {
                if (!confirm($translate.instant('NAVIGATION_CONFIRMATION'))) {
                    return;
                }
            }
            $uibModalInstance.dismiss();
        }

        function update() {
            switch ($state.$current.self.name) {
                case 'dashboard.admin.sites.list.details.partnerships':
                case 'dashboard.user.sites.list.details.partnerships':
                    $stateParams.partnershipPage = 1;
                default:
                    $stateParams.page = 1;
            }
            $rootScope.skipDirtyCheck = true;
            $state.go('.', angular.extend({}, params), { reload: true });
            $uibModalInstance.dismiss();
        }
    }
})();
