(function(){
    'use strict';

    angular
        .module('elogbooks.admin.settings')
        .controller('AdminDisplaySettingsController', AdminDisplaySettingsController);

    function AdminDisplaySettingsController(
        lodash,
        settingsCollection,
        settingsFields,
        siteAssociateTypesCollection,
        $translate,
        recurrenceRulesService,
        assetScoreTypesCollection,
        contractManager,
        elbSettings,
        $state,
        module,
        modulesService
    ) {
        var vm = this;
        vm.settingsCollection = settingsCollection;
        vm.settings = loadSettings(settingsCollection.settings);
        vm.fromJson = fromJson;
        vm.stringToArray = stringToArray;
        vm.valueIsEmpty = valueIsEmpty;
        vm.recurrenceRulesService = recurrenceRulesService;
        vm.settingsCollection.count = settingsFields.length;
        vm.contractManager = contractManager;
        vm.meridianEnabled = modulesService.isEnabled('meridian');

        var associateType;

        if (module && !elbSettings.getSetting(module).value) {
            $state.go('dashboard.user.dashboard');
        }

        if (lodash.has(vm.settings.default_asset_score_types, 'value')) {
            vm.settings.default_asset_score_types.value = vm.settings.default_asset_score_types.value.map(function (item) {
                var scoreType = {};
                var newItem = {
                    name: item.name,
                    active: false
                };

                scoreType = lodash.find(assetScoreTypesCollection.scoreTypes, {'id': item.id});
                if (scoreType && scoreType.active) {
                    newItem.active = true;
                }

                return newItem;
            });
        }

        if (lodash.has(vm.settings.general_actionsiteassociatetype, 'value')) {
            associateType = vm.settings.general_actionsiteassociatetype;
        }

        if (lodash.has(vm.settings.spillage_escalation_siteassociate_type, 'value')) {
            associateType = vm.settings.spillage_escalation_siteassociate_type;
        }

        if (associateType) {
            var selectedAssociateType = lodash.find(siteAssociateTypesCollection.siteAssociateTypes, function(element) {
                return element.id === associateType.value;
            });

            if (typeof selectedAssociateType !== 'undefined') {
                associateType.value = selectedAssociateType.name;
            }
        }

        if (lodash.has(vm.settings['general_auditcompletionemailassociatetype'], 'value')) {
            siteAssociateTypesCollection.siteAssociateTypes.unshift({name:  $translate.instant('NONE_SELECTED'), id: null});

            var selectedAuditCompletionAssociateType = lodash.find(siteAssociateTypesCollection.siteAssociateTypes, function(element) {
                if (vm.settings['general_auditcompletionemailassociatetype'].value.length === 0) {
                    return element.id === null;
                }

                return element.id ===  vm.settings['general_auditcompletionemailassociatetype'].value;
            });

            if (typeof selectedAuditCompletionAssociateType !== 'undefined') {
                vm.settings['general_auditcompletionemailassociatetype'].value = selectedAuditCompletionAssociateType.name;
            }
        }

        function loadSettings(settingsArray) {
            var settings = {};

            for (var i in settingsFields) {
                settings[settingsFields[i]] = angular.copy(lodash.find(settingsArray, { name : settingsFields[i] }));
            }

            return settings;
        }

        function fromJson(value) {
            return value ? angular.fromJson(value) : [];
        }

        function stringToArray(value) {
            return value.length > 0 ? value.split(',') : [];
        }

        function valueIsEmpty(value) {
            return lodash.isEmpty(value) && !lodash.isNumber(value);
        }
    }
})();
