(function(){
    'use strict';

    angular
        .module('elogbooks.user.patrols')
        .controller('GenericPatrolPointsAddEditController', GenericPatrolPointsAddEditController);

    GenericPatrolPointsAddEditController.$inject = ['$state', '$filter', 'messenger', 'apiClient', 'patrolPointResponse', 'patrolPointRequirementTypes', 'patrolPointsCollection', 'requestDataFactory', 'siteCollectionResponse', 'locationsCollectionResponse', 'confirmationModal'];

    function GenericPatrolPointsAddEditController ($state, $filter, messenger, apiClient, patrolPointResponse, patrolPointRequirementTypes, patrolPointsCollection, requestDataFactory, siteCollectionResponse, locationsCollectionResponse, confirmationModal) {
        var vm = this;

        vm.requirementTypes = patrolPointRequirementTypes.getAll();
        vm.status = [
            { value: true, title: $filter('translate')("STATUS_ACTIVE") },
            { value: false, title: $filter('translate')("STATUS_INACTIVE") }
        ];
        vm.patrolPoint = patrolPointResponse;
        vm.data = requestDataFactory.createRequest(patrolPointResponse);
        vm.siteSelect = {
            response: siteCollectionResponse,
            link: siteCollectionResponse.getLink('self'),
            responseKeyPath: 'sites',
            onSelect: changeSite,
            required: true
        };

        vm.create = createAction;
        vm.update = updateAction;
        vm.delete = deleteAction;
        vm.showRequirementId = showRequirementId;

        if (locationsCollectionResponse) {
            vm.data._links.site.value = vm.data._links.site.name;
            vm.siteSelect.selected = vm.data._links.site;
            vm.locations = locationsCollectionResponse;
            vm.location = vm.data._links.location;
        }

        function showRequirementId() {
            return ['barcode', 'nfc'].indexOf(vm.data.requirementType) !== -1;
        }

        function createAction() {
            apiClient.create(patrolPointsCollection.getLink('create'), vm.data).then(function (response) {
                if (response) {
                    $state.go('^', {}, {reload: $state.get('^')}).then(function () {
                        messenger.success('PATROLPOINT_CREATED');
                    });
                } else {
                    messenger.error('REQUEST_ERROR');
                }
            });
        }

        function updateAction() {
            delete vm.data._links['get-site-locations'];

            if (!showRequirementId()) {
                vm.data.requirementId = null;
            }

            apiClient.update(vm.patrolPoint.getLink('self'), vm.data).then(function (response) {
                if (response) {
                    $state.go('^', {}, {reload: $state.get('^.^')}).then(function () {
                        messenger.success('PATROLPOINT_UPDATED');
                    });
                } else {
                    messenger.error('REQUEST_ERROR');
                }
            });
        }

        function deleteAction() {
            return confirmationModal.open().result.then(function (response) {
                if (response.result) {
                    apiClient.delete(vm.patrolPoint.getLink('delete')).then(function (isSuccess) {
                        if (isSuccess) {
                            $state.go('^.^.^', {}, {reload: true}).then(function () {
                                messenger.success('PATROLPOINT_DELETED');
                            });
                        } else {
                            messenger.error('REQUEST_ERROR');
                        }
                    });
                }
            });
        }

        function changeSite() {
            if (typeof vm.siteSelect.selected !== 'undefined') {
                vm.data._links.site = vm.siteSelect.selected.href;

                apiClient.get(vm.siteSelect.selected.href).then(function (response) {
                    vm.data.site = response;
                    apiClient.get(vm.data.site.getLink('locations')).then(function (response) {
                        vm.locations = response;
                    });
                });
            }
        }
    }
})();
