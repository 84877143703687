angular.module('elogbooksDirectives').directive('yesno', function(){
    return {
        // restrict to attributes only
        restrict: 'A',
        require: ['ngModel'],
        scope: {
            yesno: '=ngModel'
        },
        templateUrl: 'modules/directives/form-elements/yesno/yesno.html',
        link: function (scope) {
            scope.options = [{
                value: true,
                label: 'YES'
            },{
                value: false,
                label: 'NO'
            }]
        }
    }
});
