(function (){
    'use strict';

    angular
        .module('elogbooks.user.tasks')
        .controller('UserTaskGenerateController', UserTaskGenerateController);

    UserTaskGenerateController.$inject = ['$scope', '$state', '$stateParams', 'messenger', 'confirmationModal', 'apiClient', 'userResponse', 'tasksCollectionResource', 'taskResponse', 'taskGenerateRequestResource', 'moment', 'modulesService'];

    function UserTaskGenerateController($scope, $state, $stateParams, messenger, confirmationModal, apiClient, userResponse, tasksCollectionResource, taskResponse, taskGenerateRequestResource, moment, modulesService) {
        var vm = this;
        vm.task = taskResponse ? taskResponse : null;
        vm.taskGenerateRequest = taskGenerateRequestResource ? taskGenerateRequestResource : {allTasks : false};
        vm.taskSelect = {
            resource : tasksCollectionResource,
            link : tasksCollectionResource ? tasksCollectionResource.getLink('self') : null,
            responseKeyPath: 'tasks',
            onSelect: changeTask,
            required: !vm.taskGenerateRequest.allTasks
        };
        vm.lastYear = moment().year() - 1;
        vm.minDate = '01-01-' + vm.lastYear;
        vm.hideDelete = modulesService.isEnabled('advanced_ppm_planner') ? true : false

        vm.create = createAction;
        vm.update = updateAction;
        vm.delete = deleteAction;

        if (typeof vm.taskGenerateRequest._links !== 'undefined') {
            vm.taskGenerateRequest.start = new Date(vm.taskGenerateRequest.start);
            vm.taskGenerateRequest.end = vm.taskGenerateRequest.end ? new Date(vm.taskGenerateRequest.end) : null;
            vm.rules = vm.taskGenerateRequest.recurrenceSet.rules;
        } else if (vm.task) {
            vm.rules = vm.task.recurrenceSet.rules;
        }

        $scope.$watch('vm.taskGenerateRequest.start', function(newDate){
            if (newDate) {
                if (moment(newDate).isBefore(vm.minDate, 'day')) {
                    messenger.error('TASK_START_DATE_WARNING', { lastYear : vm.lastYear});
                }
                if (vm.taskGenerateRequest.end && moment(newDate).isAfter(vm.taskGenerateRequest.end, 'day')) {
                    vm.taskGenerateRequest.start = null;
                    messenger.error('START_DATE_ERROR', { date : vm.taskGenerateRequest.end});
                }
            }
        });

        $scope.$watch('vm.taskGenerateRequest.end', function(newDate){
            if (newDate && vm.taskGenerateRequest.start && moment(newDate).isBefore(vm.taskGenerateRequest.start, 'day')) {
                vm.taskGenerateRequest.end = null;
                messenger.error('END_DATE_ERROR', { date :  vm.taskGenerateRequest.start});
            }
        });

        /**
         * Load Task
         */
        function changeTask(){
            if (typeof vm.taskSelect.selected.href !== 'undefined') {
                apiClient.get(vm.taskSelect.selected.href).then(function (response) {
                    if (response) {
                        vm.task = response;

                        if (typeof vm.task.recurrenceSet !== 'undefined') {
                            vm.rules = vm.task.recurrenceSet.rules;
                        }
                    }
                });
            }
        }

        /**
         * Create task
         */
        function createAction() {
            if (typeof vm.taskSelect.selected !== 'undefined' || vm.taskGenerateRequest.allTasks || vm.task) {
                vm.taskGenerateRequest._links = {};

                if (typeof vm.taskSelect.selected !== 'undefined') {
                    vm.taskGenerateRequest._links.task = vm.taskSelect.selected;
                } else if(vm.task) {
                    vm.taskGenerateRequest._links.task = vm.task._links.self;
                }

                vm.taskGenerateRequest.rules = vm.rules;

                apiClient.create(userResponse.getLink('tasksgenerate'), vm.taskGenerateRequest).then(function (response) {
                    if (response) {
                        $state.go('^', {}, {reload: $state.current.parent}).then(function () {
                            messenger.success('ADD_SUCCESS');
                        });
                    }
                });
            } else {
                messenger.error('TASK_IS_REQUIRED');
            }
        }

        /**
         * Create task
         */
        function updateAction() {
            vm.taskGenerateRequest._links.task = vm.task._links.self;
            vm.taskGenerateRequest.rules = vm.rules;

            apiClient.update(vm.taskGenerateRequest.getLink('edit'), vm.taskGenerateRequest).then(function (response) {
                if (response) {
                    $state.go('^', {}, {reload: $state.current.parent}).then(function () {
                        messenger.success('ADD_SUCCESS');
                    });
                }
            });
        }

        function deleteAction() {
            return confirmationModal.open().result.then(
                function () {
                    return apiClient.delete(vm.taskGenerateRequest.getLink('delete')).then(function (response){
                        if (response) {
                            messenger.success('DELETE_SUCCESS');
                            $state.go('^', $stateParams, {reload: $state.current.parent});
                        }
                    });
                }
            );
        }
    }
})();
