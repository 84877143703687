(function () {
    'use strict';

    angular
        .module('elogbooks.user.reports')
        .controller('ReportsCommonReactiveMissedAttendanceController', UserReportsSiteAssociateReactiveMissedAttendanceController);

    UserReportsSiteAssociateReactiveMissedAttendanceController.$inject = [
        'jobParams',
        'jobsCollection',
        'siteAssociateResponse',
        'serviceProviderResponse',
        '$translate',
        'operativeResponse'
    ];

    function UserReportsSiteAssociateReactiveMissedAttendanceController(
        jobParams,
        jobsCollection,
        siteAssociateResponse,
        serviceProviderResponse,
        $translate,
        operativeResponse
    ) {
        var vm = this;

        vm.jobsCollection = jobsCollection;
        vm.jobs = jobsCollection.jobs;

        if (serviceProviderResponse) {
            vm.entityName = serviceProviderResponse.name;
        } else if (siteAssociateResponse) {
            vm.entityName = siteAssociateResponse.getLinkAttribute('user', 'title');
        } else {
            vm.entityName = operativeResponse._links.self.title;
        }

        vm.exportParams = jobParams;

        vm.listTitle = vm.entityName + ' - ' + (
            (jobParams.overdueAttendanceHistoric) ?
                $translate.instant('REACTIVE_MISSED_ATTENDANCE') :
                $translate.instant('REACTIVE_ATTENDANCE')
        );
    }
})();
