(function (){
    'use strict';

    angular
        .module('elogbooks.common.audit')
        .controller('AuditAnswerOptionTemplateDetailController', AuditAnswerOptionTemplateDetailController);

    AuditAnswerOptionTemplateDetailController.$inject = [
        'sortableOptions',
        '$state',
        'answerOptionTemplateCollectionResponse',
        'apiClient',
        'messenger',
        'answerTemplateResponse',
        'auditService'
    ];

    function AuditAnswerOptionTemplateDetailController(
        sortableOptions,
        $state,
        answerOptionTemplateCollectionResponse,
        apiClient,
        messenger,
        answerTemplateResponse,
        auditService
        ) {
        var vm = this;

        vm.data = {};
        vm.answerOptionTemplateCollectionResponse = answerOptionTemplateCollectionResponse;
        vm.data.answerOptions = answerOptionTemplateCollectionResponse.answerOptionTemplates;
        vm.answerTemplate = answerTemplateResponse;
        vm.haveAnswerOptionsChanged = false; // Order of sections has changed
        vm.resetAnswerOptions = resetAnswerOptions;
        vm.submit = updateAnswerOptions;

        var originalAnswerOptions = angular.copy(vm.data.answerOptions);
        
        // ng-sortable parameters
        vm.sortableOptions = sortableOptions.addProperty({
            orderChanged: function(event) {
                vm.haveAnswerOptionsChanged = auditService.updatePoints(vm.data.answerOptions, vm.haveAnswerOptionsChanged);
            }
        });

        // Reset the order of the answer Options back to original order
        function resetAnswerOptions () {
            vm.data.answerOptions = angular.copy(originalAnswerOptions);
            vm.haveAnswerOptionsChanged = false;
        };

        function updateAnswerOptions () {
            var update = {
                _links: { answerOptionTemplates: [] },
                data: []
            };

            // Build the record to be sent to the server
            angular.forEach(vm.data.answerOptions, function(value, key) {
               update._links.answerOptionTemplates.push(value._links.self);
               update.data.push(value.pointsValue);
            });

            apiClient.create(vm.answerTemplate.getLink('create-answer-options-template-changeset'), update).then(function (isSuccess) {
                if (isSuccess) {
                    $state.go('.', {}, {reload: $state.current.parent}).then(function(){
                        messenger.success('AUDIT_ANSWER_OPTIONS_ORDER_UPDATED');
                    });
                } else {
                    messenger.error('REQUEST_ERROR');
                }
            });
        }
    }
})();
