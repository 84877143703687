(function () {
    'use strict';

    angular
        .module('elogbooks.admin.service-providers')
        .controller('ServiceProviderOperativesListController', ServiceProviderOperativesListController);

    ServiceProviderOperativesListController.$inject = ['serviceProviderResponse', 'operativesCollection', 'apiClient', 'lodash', '$stateParams', '$state'];

    function ServiceProviderOperativesListController(serviceProviderResponse, operativesCollection, apiClient, lodash, $stateParams, $state)
    {
        var vm = this;
        vm.operativesCollection = operativesCollection;
        vm.operatives = operativesCollection.operatives;
        vm.operatives._links = operativesCollection._links;
        vm.entityCount = operativesCollection.count;
        vm.search = search;
        vm.order = order;

        vm.criteria = {
            operativeId: { type: 'number', value: $stateParams.operativeId ? parseInt($stateParams.operativeId, 10) : null, title: 'ID' },
            operativeName: { type: 'text', value: $stateParams.operativeName, title: 'NAME' }
        }

        function search (params) {
            var override = {
                operativePage: 1
            };

            $state.go('.', angular.extend({}, params, override), { reload: true });
        }

        function order (key) {
            $stateParams.operativeOrder = key;

            var override = {
                operativePage: 1
            };

            $state.go('.', angular.extend({}, $stateParams, override), { reload: true });
        }

    }
})();
