(function() {
    'use strict';

    angular
        .module('elogbooks')
        .config(Translations);

    function Translations($translateProvider) {
        $translateProvider.translations('zh-hk', {

            EVENT_FEED: '事件資訊提供',

            EVENT: '事件',
            EVENTS: '事件',

        });
    }
})();
