(function() {
    'use strict';

    angular
        .module('elogbooksDirectives')
        .directive('elbQuoteWorkflowButtons', ElogbooksQuoteWorkflowButtons);

    ElogbooksQuoteWorkflowButtons.$inject = ['confirmationModal', '$state', 'messenger', 'apiClient'];

    function ElogbooksQuoteWorkflowButtons(confirmationModal, $state, messenger, apiClient) {
        return {
            restrict: 'AE',
            scope: {
                quote: '=ngModel',
                routeBase: '@',
                routeParameters: '@'
            },
            templateUrl: '/modules/directives/elb-quote-workflow-buttons/quote-workflow-buttons.html',
            link: function($scope, element, attributes) {
                $scope.getLink = getLink;
                $scope.resendApprovalRequest = resendApprovalRequest;

                function getLink(route) {
                    if ($scope.routeBase) {
                        route = $scope.routeBase + route;
                    }

                    if ($scope.routeParameters) {
                        route = route + '(' + $scope.routeParameters + ')';
                    }

                    return route;
                }

                function resendApprovalRequest() {
                    return confirmationModal.open().result.then(function (response) {
                        if (response.result) {
                            apiClient.create($scope.quote.getLink('resend-approval')).then(function (isSuccess) {
                                if (isSuccess) {
                                    return $state.go('.', {}, { reload: $state.get('^.^') }).then(function () {
                                        messenger.success('QUOTE_APPROVAL_RESENT');
                                    });
                                } else {
                                    messenger.error('REQUEST_ERROR');
                                }
                            });
                        }

                        return;
                    });
                }
            }
        };
    }
})();
