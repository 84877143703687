(function () {
    'use strict';

    angular
        .module('elogbooks.admin.sectors')
        .controller('SectorAddEditController', SectorAddEditController);

    SectorAddEditController.$inject = ['$state', 'apiClient', 'confirmationModal', 'sectorResponse', 'requestDataFactory', 'messenger', 'sectorCollectionResponse'];

    function SectorAddEditController ($state, apiClient, confirmationModal, sectorResponse, requestDataFactory, messenger, sectorCollectionResponse) {
        var vm = this;
            vm.sector = sectorResponse;
            vm.data = requestDataFactory.createRequest(getDefaults());

        vm.create = createAction;
        vm.update = updateAction;
        vm.delete = deleteAction;

        function getDefaults() {
            return {
                id: vm.sector.id || null,
                name: vm.sector.name || null,
                description: vm.sector.description || null,
                active: vm.sector.active === true || vm.sector.active === false ? vm.sector.active : null
            };
        }

        function createAction () {
            return apiClient.create(sectorCollectionResponse.getLink('create'), vm.data).then(function (response) {
                if (response) {
                    $state.go('^', {}, { reload: $state.current.parent }).then(function () {
                        messenger.success('SECTOR_CREATED');
                    });
                } else {
                    messenger.error('REQUEST_ERROR');
                }
            });
        }

        function updateAction () {
            return apiClient.update(vm.sector.getLink('edit'), vm.data).then(function (response) {
                if (response) {
                    $state.go('^', {}, { reload: 'dashboard.admin.sectors.list' }).then(function () {
                        messenger.success('SECTOR_UPDATED');
                    });
                } else {
                    messenger.error('REQUEST_ERROR');
                }
            });
        }

        function deleteAction () {
            return confirmationModal.open().result.then(function () {
                return apiClient.delete(vm.sector.getLink('delete')).then(function (response) {
                    if (response) {
                        $state.go('dashboard.admin.sectors.list', {}, { reload: 'dashboard.admin.sectors.list' }).then(function () {
                            messenger.success('SECTOR_DELETED');
                        });
                    } else {
                        messenger.error('REQUEST_ERROR');
                    }
                });
            });
        }
    };
})();
