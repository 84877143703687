(function () {
    'use strict';

    angular
        .module('elogbooks.admin.roles')
        .controller('RolesController', RolesController);

    RolesController.$inject = [
        'roleCollectionResponse',
        '$stateParams',
        '$state',
        '$filter',
        'filterPermissions',
        'operativePermissions',
        'sitePermissions',
        'userPermissions'
    ];

    function RolesController (roleCollectionResponse, $stateParams, $state, $filter, filterPermissions, operativePermissions, sitePermissions, userPermissions) {
        var vm = this;
            vm.roleCollectionResponse = roleCollectionResponse;

        vm.roles = roleCollectionResponse.getData().roleAccesses;
        var allowedPermissions = [].concat.apply([], [userPermissions.getAll(), sitePermissions.getAll(), operativePermissions.getAll()]);

        for (var i = 0, rolesLength = vm.roles.length; i < rolesLength; i++) {
            vm.roles[i].permissions = filterPermissions.filterAllowedPermissions(vm.roles[i].permissions, allowedPermissions);
        }

        vm.order = order;
        vm.search = search;

        vm.criteria = {
            id: { type: 'number', value: $stateParams.id ? parseInt($stateParams.id, 10) : null, title: 'ID', min: 1 },
            name: { type: 'text', value: $stateParams.name, title: 'NAME' }
        };

        function search(params) {
            var override = {
                page: "1"
            };

            $state.go('.', angular.extend({}, params, override), { reload: true });
        }

        function order(key) {
            $stateParams.order = key;
            $state.go('.', angular.extend({}, $stateParams), { reload: true });
        }
    }
})();
