app.config(function ($translateProvider) {
    $translateProvider.translations('nl', {
        SITE_ASSOCIATE_TYPE: 'Type van objectpartners',
        SITE_ASSOCIATE_TYPES:           'Types van objectpartners',
        SITE_ASSOCIATE_TYPE_INFORMATION:'Informatie over deze objectpartner',
        ADD_NEW_SITE_ASSOCIATE_TYPE:    'Maak een nieuw type objectpartner',
        EDIT_SITE_ASSOCIATE_TYPE:       'Wijzig type objectpartner',

        SITE_ASSOCIATE_TYPE_LIST: 'Type objectpartners',
        SITE_ASSOCIATE_TYPE_ADD: 'Maak een nieuw type objectpartner',
        SITE_ASSOCIATE_TYPE_EDIT: 'Wijzig type objectpartner',

        SITE_ASSOCIATE_TYPE_CREATED: 'type objectpartner gemaakt',
        SITE_ASSOCIATE_TYPE_UPDATED: 'type objectpartner aangemaakt',
        SITE_ASSOCIATE_TYPE_DELETED: 'type objectpartner verwijderd',


        ASSOCIATES: 'Partners',
        SITE_ASSOCIATES: 'Objectpartners',
        SITE_ASSOCIATE_LIST: 'Lijst met objectpartners',

        NO_ASSOCIATES_ADDED: 'Geen objectpartners geselecteerd',
        CANNOT_DELETE_TYPE: 'Kan niet worden verwijderd.'
    });
});
