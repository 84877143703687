(function(){
    'use strict';

    angular
        .module('elogbooks.admin.site-associate-types')
        .controller('SiteAssociateTypesController', SiteAssociateTypesController);

    SiteAssociateTypesController.$inject = ['$state', '$stateParams', 'siteAssociateTypeCollectionResponse', 'messenger'];

    function SiteAssociateTypesController ($state, $stateParams, siteAssociateTypeCollectionResponse, messenger) {
        var vm = this;
            vm.siteAssociateTypes = siteAssociateTypeCollectionResponse.siteAssociateTypes;
            vm.siteAssociateTypeCollectionResponse = siteAssociateTypeCollectionResponse;
            vm.pages = siteAssociateTypeCollectionResponse.pages;

        vm.order = order;
        vm.search = search;

        vm.criteria = {
            id: { type: 'number', value: $stateParams.id ? parseInt($stateParams.id, 10) : null, title: 'ID', min: 1 },
            name: { type: 'text', value: $stateParams.name, title: 'NAME' },
        };

        function search (params) {
            var override = {
                page: 1
            };

            $state.go('.', angular.extend({}, params, override), { reload: true });
        };

        function order (key) {
            $stateParams.order = key;

            $state.go('.', angular.extend({}, $stateParams), { reload: true });
        };
    }
})();
