(function(){
    'use strict';

    angular
        .module('elogbooks.user.sites')
        .controller('UserSiteDetailsController', UserSiteDetailsController);

    UserSiteDetailsController.$inject= ['siteResponse', 'approversResponse', 'associatesResponse', 'associateTypeCollectionResponse', 'lodash', 'userManager'];

    function UserSiteDetailsController(siteResponse, approversResponse, associatesResponse, associateTypeCollectionResponse, lodash, userManager) {
        var vm = this;
        vm.site = siteResponse;

        vm.approvers = approversResponse;
        vm.associates = lodash.get(associatesResponse, 'associates', null);
        vm.associatesCollection = [];
        vm.associateTypes = associateTypeCollectionResponse.siteAssociateTypes;
        vm.userManager = userManager;

        var siteassociatetype = null;
        var siteMembership = null;

        angular.forEach(vm.associateTypes, function(associateType, key) {
            siteassociatetype = {'title' : associateType.name, 'href' : associateType._links.self.href, 'members' : []};

            angular.forEach(vm.associates, function(associate, key) {

                if(associate._links.type.href === associateType._links.self.href) {

                    siteMembership = {
                        'title' : associate._links.sitemembership.title,
                        'href' : associate._links.sitemembership.href,
                        'email' : associate._links.user.email,
                        'phone' : associate._links.user.phone
                    };
                    siteassociatetype.members.push(siteMembership);
                }
            });
            vm.associatesCollection.push(siteassociatetype);
        });
    }
})();
