(function () {
    'use strict';

    angular
        .module('elogbooks.admin.sites')
        .controller('SitesController', SitesController);

    SitesController.$inject = [
        '$state',
        '$stateParams',
        '$filter',
        'siteCollectionResponse',
        'siteGroupCollectionResponse',
        'regionCollectionResponse',
        'associateTypeCollectionResponse',
        'siteAssociateCollectionResponse',
        'user',
        'rootResourceResponse'];

    function SitesController(
        $state,
        $stateParams,
        $filter,
        siteCollectionResponse,
        siteGroupCollectionResponse,
        regionCollectionResponse,
        associateTypeCollectionResponse,
        siteAssociateCollectionResponse,
        user,
        rootResourceResponse) {

        var statusOptions = [
            {
                title: $filter('translate')('NONE_SELECTED'),
                value: null
            },
            {
                title: $filter('translate')('STATUS_ACTIVE'),
                value: 'active'
            },
            {
                title: $filter('translate')('STATUS_INACTIVE'),
                value: 'inactive'
            }
        ];

        var vm = this;
        vm.sites = siteCollectionResponse.sites;
        vm.siteCollectionResponse = siteCollectionResponse;
        vm.criteria = {
            id: {type: 'number', title: 'ID', value: $stateParams.id ? parseInt($stateParams.id, 10) : null, min: 1},
            siteName: {type: 'text', title: 'NAME', value: $stateParams.siteName},
            reference: {type: 'text', title: 'REFERENCE', value: $stateParams.reference},
            status: {type: 'options', title: 'STATUS', value: $stateParams.status, options: statusOptions},
            address: {type: 'text', title: 'ADDRESS', value: $stateParams.address},
            createdAtStart: { type: 'date', value: $stateParams.createdAtStart, title: 'CREATED_AT_START' },
            createdAtEnd: { type: 'date', value: $stateParams.createdAtEnd, title: 'CREATED_AT_END' }
        };

        vm.loadedCollections = [siteGroupCollectionResponse, regionCollectionResponse, siteAssociateCollectionResponse, associateTypeCollectionResponse];
        vm.resources = {
            rootResource: rootResourceResponse,
            stateParams: $stateParams,
            user: user
        };

        vm.isActive = isActive;
        vm.order = order;
        vm.search = search;

        function isActive(site) {
            var currentDate = new Date().toRFC3339String();
            return ((typeof site.activeAt !== 'undefined' && site.activeAt < currentDate) && (typeof site.inactiveAt === 'undefined' || site.inactiveAt > currentDate));
        };

        function search(params) {
            var override = {
                page: 1
            };

            $state.go('.', angular.extend({}, params, override), {reload: $state.current});
        };

        function order(key) {
            $stateParams.order = key;

            $state.go('.', angular.extend({}, $stateParams), {reload: $state.current});
        };
    }
})();
