(function() {
    'use strict';

    angular
        .module('elogbooks.common.jobs')
        .controller('CommonJobDetailsRequisitesController', CommonJobDetailsRequisitesController);

    CommonJobDetailsRequisitesController.$inject = ['jobRequisiteEventsResponse', 'elbFileModalService'];

    function CommonJobDetailsRequisitesController(jobRequisiteEventsResponse, elbFileModalService) {
        var vm = this;
        vm.jobRequisiteEventsResponse = jobRequisiteEventsResponse;
        vm.jobRequisiteEventsResponse['job-requisites-answers'].forEach(function(elem) {
            if(elem.hasOwnProperty('files')) {
                elem.answer = "ATTACHED_FILES";
            } else if (elem.notApplicable) {
                elem.answer = "ANSWERED_NA";
            } else if (elem.answeredYes) {
                elem.answer = "ANSWERED_YES";
            } else {
                elem.answer = "ANSWERED_NA";
            }
        });

        vm.showAction = function (file) {
            file.tags = [];
            return elbFileModalService.open(file, []);
        };
    }
})();
