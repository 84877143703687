(function() {
    'use strict';

    angular
        .module('elogbooks.common.assets')
        .controller('UserSubAssetsInfoController', UserSubAssetsInfoController);

    UserSubAssetsInfoController.$inject = ['sectionResource', 'subAssetResponse', 'locationResponse', 'config'];

    function UserSubAssetsInfoController(sectionResource, subAssetResponse, locationResponse, config) {
        var vm = this;
        vm.subAsset = subAssetResponse;
        vm.sectionResource = sectionResource;
        vm.location = locationResponse;
        vm.config = config;

        vm.readOnly = vm.config.entityName === 'JOB';
        vm.titleNoBorder = vm.config.entityName === 'JOB' ? 'hide' : null;
    }

})();
