/**
 * Integrations Service
 */
angular
    .module("elogbooksServices")
    .service(
        "integrationsService",
        function (
            apiClient,
            $q,
            elbSettings,
            $translate
        ) {
            this.update = updateIntegrations;
            this.cancel = cancelIntegration;
            this.getCompanyDetails = getCompanyDetails;
            this.getExistingIntegrations = getExistingIntegrations;
            this.checkIntegrations = checkIntegrations;
            this.preSelectDocumentType = preSelectDocumentType;
            this.addMeridianDefaultDocTypes = addMeridianDefaultDocTypes;

            function cancelIntegration() {
                if (
                    confirm(
                        $translate.instant('PROSURE_CANCEL_SETUP')
                    ) == true
                ) {
                    redirectToProsure(false);
                }
            }

            function getCompanyDetails(companyDataLink, companyEncodedData) {
                var deferred = $q.defer();

                apiClient
                    .get(companyDataLink, { encryptedData: companyEncodedData })
                    .then(function (response) {
                        if (response) {
                            return deferred.resolve(response);
                        } else {
                            return deferred.reject(
                                $translate.instant('PROSURE_UNABLE_TO_GET_COMPANY_INFO')
                            );
                        }
                    });

                return deferred.promise;
            }

            function getExistingIntegrations(linkedSpsLink, companyEncodedData) {
                var deferred = $q.defer();

                apiClient
                    .get(linkedSpsLink, { encryptedData: companyEncodedData })
                    .then(function (response) {
                        if (response) {
                            return deferred.resolve(response);
                        } else {
                            return deferred.reject(
                                $translate.instant('PROSURE_UNABLE_TO_GET_COMPANY_INFO')
                            );
                        }
                    });

                return deferred.promise;
            }

            function updateIntegrations(putCompanyDataLink, data) {
                var deferred = $q.defer();

                var serviceProvidersToAdd = data.sps
                    .filter(function (item) {
                        return item.selected === true;
                    })
                    .map(function (response) {
                        return {
                            href: response.href,
                        };
                    });

                var serviceProvidersToRemove = data.sps
                    .filter(function (item) {
                        return item.selected === false;
                    })
                    .map(function (response) {
                        return {
                            href: response.href,
                        };
                    });

                var putData = {
                    encryptedCompanyData: data.encryptedCompanyData,
                    serviceProvidersToAdd: {
                        links: serviceProvidersToAdd,
                    },
                    serviceProvidersToRemove: {
                        links: serviceProvidersToRemove,
                    },
                };

                return apiClient
                    .update(putCompanyDataLink, putData)
                    .then(function (response) {
                        if (response) {
                            redirectToProsure();

                            return deferred.resolve(response);
                        } else {
                            return deferred.reject(
                                $translate.instant('PROSURE_UNABLE_TO_UPDATE_LINKS')
                            );
                        }
                    });
            }

            function redirectToProsure(goToSetup) {
                goToSetup = typeof goToSetup !== 'undefined' ? goToSetup : true;
                window.location.replace(
                    elbSettings.getSetting("general_prosure_link").value + (goToSetup === true ? '/setup' : '')
                );
            }

            function checkIntegrations(integrations, entityResponse = null, preselectCallback = void 0)
            {
                try {
                    var hasIntegrations = false;
                    
                    angular.forEach(integrations, function(integration, index) {
                        //if system setting riskwise_type is 1 (FM lead), don't show the RiskWise integration fields here
                        if (integration.integration === 'riskwise' &&
                            integration.riskwiseType === 1
                            || integration.integration === 'meridian' && elbSettings.getSetting("general_meridian_workflow").value === true) // Disable meridian workflow if workflow enabled
                        {
                            integrations[index].showIntegration = false;
                        } else {
                            hasIntegrations = true;
                        }

                        integration.hierarchicalDocTypes = typeof integration.docTypes[Object.keys(integration.docTypes)[0]] !== 'string';

                        if (integration.integration === 'meridian' && entityResponse) {
                            addMeridianDefaultDocTypes(integrations[index], entityResponse);
                            preselectCallback(integrations[index]);
                        }
                    });
                } catch (e) {
                    console.trace(e);
                }

                return hasIntegrations;
            }  

            function preSelectDocumentType(integration, integrationDocCat, file) {
                integrationDocCat[file.index] = integration.defaultDocType;
                file.integration = {
                    meridian: integration.defaultDocType2
                };
            }

            function addMeridianDefaultDocTypes(integration, entity) {
                if (entity && entity._embedded && entity._embedded['meridian-doc-type']) {
                    
                    const defaultDocType = entity._embedded['meridian-doc-type'];

                    if (defaultDocType) {
                        for (const parentKey in integration.docTypes) {
                            if (Object.prototype.hasOwnProperty.call(integration.docTypes, parentKey)) {
                                const childKeys = Object.keys(integration.docTypes[parentKey]);
                                if (childKeys.includes(defaultDocType)) {
                                    integration.defaultDocType = integration.docTypes[parentKey];
                                    integration.defaultDocType2 = defaultDocType;
                                }
                            }
                        }
                    }
                }
            }
        }
    );
