/**
 * Edit Trade Type controller
 */
(function () {
    'use strict';

    angular
        .module('elogbooks.admin.trade-types')
        .controller('TradeTypesEditController', TradeTypesEditController);

    TradeTypesEditController.$inject = ['$scope', 'apiClient', 'messenger', 'tradeTypeResponse', 'lodash'];

    function TradeTypesEditController($scope, apiClient, messenger, tradeTypeResponse, lodash) {

        var vm = this;
        vm.tradetype = tradeTypeResponse.getData();

        vm.delete = function () {
            apiClient.delete(vm.tradetype._links.delete.href).then(function (isSuccess) {
                if (isSuccess) {
                    $scope.$state.go('dashboard.admin.tradetypes', {}, {reload: true}).then(function () {
                        messenger.success('Trade Type deleted successfully');
                    });
                } else {
                    messenger.error('Unable to delete');
                }
            });
        };

        vm.submit = function () {
            apiClient.update(vm.tradetype._links.self.href, lodash.cloneDeep(vm.tradetype)).then(function (success) {

                if (success) {
                    $scope.$state.go('^', {}, {reload: true}).then(function (){
                        messenger.success('Updated successfully');
                    });
                } else {
                    messenger.error('Unable to process your request. Please try again later');
                }
            });
        };

    };

})();
