(function () {
    'use strict';

    angular
        .module('elogbooks.user.service-providers')
        .controller('UserServiceProvidersDetailsInfoController', UserServiceProvidersDetailsInfoController);

    UserServiceProvidersDetailsInfoController.$inject = ['serviceProviderResponse', 'userManager', 'elbSettings'];

    function UserServiceProvidersDetailsInfoController(serviceProviderResponse, userManager, elbSettings) {
        var vm = this;
        vm.serviceProvider = serviceProviderResponse;
        vm.hasManageSPPermission = userManager.hasPermission('user_permission_manage_service_providers');
        vm.spFeedbackEnabled = elbSettings.getSetting('sp_feedback_enabled').value;
    }
})();
