(function (){
    'use strict';

    angular
        .module('elogbooks.user.sites.master-tasks')
        .controller('UserSiteTaskListController', UserSiteTaskListController);

    UserSiteTaskListController.$inject = ['$state', '$stateParams', '$filter', 'siteResponse', 'serviceProviderCollection', 'taskCollectionResource'];

    function UserSiteTaskListController($state, $stateParams, $filter, siteResponse, serviceProviderCollection, taskCollectionResource) {
        var vm = this;

        vm.tasks = taskCollectionResource ? taskCollectionResource.tasks : [];
        vm.serviceProviderCollection = serviceProviderCollection ? serviceProviderCollection : false;
        vm.tasksCollection = taskCollectionResource;
        vm.siteResource = siteResponse;
        vm.filter = filterAction;

        vm.tidyTables = {
            name: 'task',
            columns : {
                'SERVICE_PROVIDER': true
            }
        };

        vm.serviceProviderSelect = {
            response : serviceProviderCollection,
            link : serviceProviderCollection ? serviceProviderCollection.getLink('self') : null,
            responseKeyPath: 'serviceproviders'
        };

        vm.criteria = {
            tYear: { type: 'number', title: 'YEAR', value: parseInt($stateParams.tYear), min: 2000 },
            tName: { type: 'text', value: $stateParams.tName, title: 'TASK' },
            tServiceProviderName: { type: 'selectwidget', title: 'SERVICE_PROVIDER', value: $stateParams.tServiceProviderName, options: vm.serviceProviderSelect },
        };

        function filterAction (params) {
            $state.go('.', angular.extend({}, params), { reload: $state.current.name });
        }
    }
})();
