/**
 * Created by Michal.m on 09/09/2015.
 */
(function () {
    'use strict';

    angular
        .module('elogbooks.admin.users')
        .controller('UserPasswordResetModalController', UserPasswordResetModalController);

    function UserPasswordResetModalController ($uibModalInstance, userResource, apiClient, messenger) {

        var vm = this;

        vm.password = {
            new: null,
            repeated: null
        };

        vm.passwordMatch = true;

        vm.passwordsMatch = function() {
            vm.passwordMatch = true;
            if(vm.password.new && vm.password.repeated && vm.password.new != vm.password.repeated) {
                vm.passwordMatch = false;
                return false;
            }
            return true;
        }

        vm.change = function () {
            apiClient.update(userResource.getLink('change-password'), {
                password: vm.password.new,
            }).then(function (response) {
                if (response) {
                    $uibModalInstance.dismiss('cancel');
                    messenger.success('PASSWORD_CHANGED');
                } else {
                    messenger.error('REQUEST_ERROR');
                }
            });
        }

        vm.newPassword = null;
        vm.showPassword = false;

        vm.reset = function () {
            apiClient.create(userResource.getLink('passwordreset')).then(function (response) {
                vm.newPassword = response.password;
            });
        }

        vm.close = function () {
            $uibModalInstance.dismiss('cancel');
        };
    }

})();