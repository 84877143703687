(function () {
    'use strict';

    angular
        .module('elogbooks.user.bulk-actions')
        .controller('BulkActionsListController', BulkActionsListController);

    BulkActionsListController.$inject = ['bulkActionsCollectionResponse', '$state', '$stateParams', '$translate'];

    function BulkActionsListController(bulkActionsCollectionResponse, $state, $stateParams, $translate) {
        var vm = this;
        vm.bulkActionsCollectionResponse = bulkActionsCollectionResponse;
        vm.bulkActions = bulkActionsCollectionResponse.bulkActions;
        vm.search = searchAction;

        var typeOptions = [
            {
                title: $translate.instant('NONE_SELECTED'),
                value: null
            },
            {
                title: $translate.instant('REASSIGN'),
                value: 'reassign'
            },
            {
                title: $translate.instant('REASSIGN_OPERATIVE'),
                value: 'reassign-operatives'
            },
            {
                title: $translate.instant('CANCEL'),
                value: 'cancel'
            },
            {
                title: $translate.instant('CANCEL_TASKS'),
                value: 'cancel-tasks'
            },
            {
                title: $translate.instant('CANCEL_ACTIONS'),
                value: 'cancel-actions'
            }
        ];

        vm.criteria = {
            type: { type: 'options', title: 'TYPE', value: $stateParams.type, options: typeOptions }
        };

        function searchAction (params) {
            var override = {
                bulkActionPage: 1
            };
            $state.go('.', angular.extend({}, params, override), { reload: $state.current });
        }
    }
})();
