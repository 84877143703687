(function () {
    'use strict';

    angular
        .module('elogbooks.admin.request-logs')
        .controller('RequestLogDetailsController', RequestLogDetailsController);

    RequestLogDetailsController.$inject = ["requestLogResponse"];

    function RequestLogDetailsController(requestLogResponse) {
        var vm = this;
        vm.requestLog = requestLogResponse;
    }
})();