(function () {
    'use strict';

    angular
        .module('elogbooks.admin.sectors')
        .controller('SectorInfoController', SectorInfoController);

    SectorInfoController.$inject = ['sectorResponse'];

    function SectorInfoController(sectorResponse) {
        var vm = this;
        vm.sector = sectorResponse;
    };
})();
