(function () {
    'use strict';

    angular
        .module('elogbooks.user.finance.purchase-orders')
        .controller('FinancePurchaseOrdersDetailsController', FinancePurchaseOrdersDetailsController);

    FinancePurchaseOrdersDetailsController.$inject = ['purchaseOrderResponse'];

    function FinancePurchaseOrdersDetailsController (purchaseOrderResponse) {
        var vm = this;

        vm.purchaseOrderResponse = purchaseOrderResponse;
    }
})();
