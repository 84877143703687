(function() {
    'use strict';

    angular
        .module('elogbooks.admin.pricing-schedules')
        .config(registerRoutes)
        .config(registerRoutesList)
        .config(registerRoutesAdd)
        .config(registerRoutesDetails)
        .config(registerRoutesEdit)
        .config(registerRoutesLabourRatesAdd)
        .config(registerRoutesLabourRatesEdit)
        .config(registerRoutesStandardChargesList)
        .config(registerRoutesStandardChargesAdd)
        .config(registerRoutesStandardChargesEdit);

    function addDetailViews() {
        return {
            'details.info@dashboard.admin.pricing-schedules.list.details': {
                templateUrl: '/modules/admin/pricing-schedules/details/info/pricing-schedules-info.html',
                controller: 'PricingSchedulesInfoController',
                controllerAs: 'vm'
            },
            'status@dashboard.admin.pricing-schedules.list.details': {
                templateUrl: '/modules/admin/pricing-schedules/details/status/pricing-schedule-status.html',
                controller: 'PricingSchedulesStatusController',
                controllerAs: 'vm'
            },
            'feed@dashboard.admin.pricing-schedules.list.details': {
                templateUrl: '/modules/admin/pricing-schedules/details/event/feed.html',
                controller: 'PricingScheduleDetailsEventFeedController',
                controllerAs: 'vm'
            }
        }
    }

    function registerRoutes($stateProvider) {
        $stateProvider
            .state('dashboard.admin.pricing-schedules', {
                abstract: true,
                templateUrl: 'shared-templates/view.html',
                ncyBreadcrumb: {
                    skip: true
                }
            });
    }

    function registerRoutesList($stateProvider) {

        var pricingScheduleParams = {
            page: '1',
            limit: '30',
            order: 'id',
            summary: null,
            status: null,
            isDefault: null,
            createdAtRange: null,
            changeVersion: null,
            isActiveVersion: null
        };

        $stateProvider
            .state('dashboard.admin.pricing-schedules.list', {
                url: '/pricing-schedules?' + Object.keys(pricingScheduleParams).join('&'),
                parent: 'dashboard.admin.pricing-schedules',
                views: {
                    '@dashboard.admin.pricing-schedules': {
                        templateUrl: '/modules/admin/pricing-schedules/pricing-schedules-list.html',
                        controller: 'PricingSchedulesAdminController',
                        controllerAs: 'vm'
                    }
                },
                params: pricingScheduleParams,
                resolve: {
                    pricingSchedulesCollectionResponse: function($stateParams, apiClient, rootResourceResponse) {
                        return apiClient.get(rootResourceResponse.getLink('pricing-schedules'), $stateParams).then( function(response) {
                            return response || apiClient.noResourceFound();
                        });
                    }
                },
                ncyBreadcrumb: {
                    label: '{{ ::"PRICING_SCHEDULES_LIST" | translate }}'
                }
            });
    }

    function registerRoutesAdd($stateProvider) {
        $stateProvider
            .state('dashboard.admin.pricing-schedules.list.add', {
                url: '/add',
                parent:'dashboard.admin.pricing-schedules.list',
                views: {
                    '@dashboard.admin.pricing-schedules': {
                        templateUrl:'/modules/admin/pricing-schedules/add-edit/form.html',
                        controller: 'PricingSchedulesAddEditController',
                        controllerAs: 'vm'
                    }
                },
                resolve: {
                    rootPricingScheduleResponse: function () {
                        return {};
                    },
                    pricingScheduleResponse: function () {
                        return {};
                    }
                },
                ncyBreadcrumb: {
                    label: '{{ ::"ADD" | translate }}'
                }
            });
    }

    function registerRoutesDetails($stateProvider) {

        $stateProvider
            .state('dashboard.admin.pricing-schedules.list.details', {
                url: '/details/{pricingScheduleResource}',
                abstract: true,
                parent: 'dashboard.admin.pricing-schedules.list',
                views: {
                    '@dashboard.admin.pricing-schedules': {
                            templateUrl: '/modules/admin/pricing-schedules/details/pricing-schedules-details.html',
                            controller: 'PricingSchedulesDetailsController',
                            controllerAs: 'vm'
                    },
                    'feed@dashboard.admin.pricing-schedules.list.details': {
                        templateUrl: '/modules/admin/pricing-schedules/details/event/feed.html',
                        controller: 'PricingScheduleDetailsEventFeedController',
                        controllerAs: 'vm'
                    }
                },
                resolve: {
                    selectedVersionResponse: function(apiClient, $stateParams) {
                        return apiClient.get($stateParams.pricingScheduleResource.decode()).then( function(response) {
                            return response || apiClient.noResourceFound();
                        });
                    },
                    pricingScheduleVersionsResponse: function(apiClient, selectedVersionResponse) {
                        if (typeof selectedVersionResponse.getLink('versions') !== 'undefined') {
                            return apiClient.get(selectedVersionResponse.getLink('versions')).then(function (response) {
                                return response;
                            });
                        } else {
                            return {};
                        }
                    },
                    pricingScheduleResponse: function (apiClient, $stateParams, pricingScheduleVersionsResponse, selectedVersionResponse) {
                        if (
                            $stateParams.changeVersion ||
                            pricingScheduleVersionsResponse.pricingSchedules.length <= 1 ||
                            $stateParams.isActiveVersion === 'true'
                        ) {
                            return selectedVersionResponse
                        } else {
                            var link = pricingScheduleVersionsResponse.pricingSchedules[pricingScheduleVersionsResponse.pricingSchedules.length-1].getLink('self');
                            return apiClient.get(link).then(function(response) {
                                    return response || apiClient.noResourceFound();
                            })
                        }
                    },
                    rootPricingScheduleResponse: function(apiClient, pricingScheduleResponse) {
                        if (pricingScheduleResponse.getLink('self') !== pricingScheduleResponse.getLink('root')) {
                            return apiClient.get(pricingScheduleResponse.getLink('root')).then( function(response) {
                                return response || apiClient.noResourceFound();
                            });
                        } else {
                            return pricingScheduleResponse;
                        }
                    },
                    eventCollectionResponse: function(apiClient, pricingScheduleResponse) {
                        return apiClient.get(pricingScheduleResponse.getLink('events'), {datagroup: 'details'}).then(function(response) {
                            return response || apiClient.noResourceFound();
                        })
                    },
                    config: function($stateParams) {
                        // When enter to details you have to see the latest version
                        // This code will avoid to see the latest version if you change it in details view
                        if ($stateParams.changeVersion) {
                            return {changeVersion: true};
                        }
                    }
                },
                ncyBreadcrumb: {
                    label: '{{ ::$resolves().pricingScheduleResponse.summary }}'
                }
            })
            .state('dashboard.admin.pricing-schedules.list.details.labourRates', {
                url: '/labour-rates?labourRatesPage&labourRatesLimit',
                parent: 'dashboard.admin.pricing-schedules.list.details',
                views: angular.extend({
                    'info@dashboard.admin.pricing-schedules.list.details': {
                        templateUrl: '/modules/admin/pricing-schedules/details/labour-rates/pricing-schedules-labour-rates.html',
                        controller: 'PricingSchedulesLabourRatesController',
                        controllerAs: 'vm'
                }}, addDetailViews()),
                params: {
                    labourRatesPage: '1',
                    labourRatesLimit: '10',
                },
                resolve: {
                    pricingScheduleLabourRatesResponse: function(apiClient, pricingScheduleResponse, $stateParams) {
                        return apiClient.get(pricingScheduleResponse.getLink('labourrates'), angular.extend({}, $stateParams, {
                            page: $stateParams.labourRatesPage,
                            limit: $stateParams.labourRatesLimit
                        })).then( function(response) {
                            return response || apiClient.noResourceFound();
                        });
                    }
                },
                ncyBreadcrumb: {
                    label: '{{ ::"PRICING_SCHEDULES_LABOUR_RATES" | translate }}'
                }
            })
            .state('dashboard.admin.pricing-schedules.list.details.markup', {
                url: '/markup',
                parent: 'dashboard.admin.pricing-schedules.list.details',
                views: angular.extend({
                    'info@dashboard.admin.pricing-schedules.list.details': {
                        templateUrl: '/modules/admin/pricing-schedules/details/markup/pricing-schedules-markup.html',
                        controller: 'PricingSchedulesMarkupController',
                        controllerAs: 'vm'
                }}, addDetailViews()),
                resolve: {
                    pricingScheduleMarkupResponse: function(apiClient, pricingScheduleResponse) {
                        return apiClient.get(pricingScheduleResponse.getLink('markups'), {datagroup: 'details'}).then( function(response) {
                            return response || apiClient.noResourceFound();
                        });
                    }
                },
                ncyBreadcrumb: {
                    label: '{{ ::"PRICING_SCHEDULES_MARKUP" | translate }}'
                }
            })
    }

    function registerRoutesEdit($stateProvider) {
        $stateProvider
            .state('dashboard.admin.pricing-schedules.list.details.edit', {
                url: '/edit/{pricingScheduleResource}',
                parent: 'dashboard.admin.pricing-schedules.list.details',
                views: {
                    '@dashboard.admin.pricing-schedules': {
                        templateUrl: '/modules/admin/pricing-schedules/add-edit/form.html',
                        controller: 'PricingSchedulesAddEditController',
                        controllerAs: 'vm'
                    }
                },
                ncyBreadcrumb: {
                    label: '{{ ::"EDIT" | translate }}'
                }
            });
    }

    function registerRoutesStandardChargesList($stateProvider)
    {
        $stateProvider
            .state('dashboard.admin.pricing-schedules.list.details.standardCharges', {
                url: '/standard-charges?standardChargesPage&standardChargesLimit',
                params: {
                    standardChargesPage: '1',
                    standardChargesLimit: '10',
                },
                parent: 'dashboard.admin.pricing-schedules.list.details',
                views: angular.extend({
                    'info@dashboard.admin.pricing-schedules.list.details': {
                        templateUrl: '/modules/admin/pricing-schedules/details/standard-charges/pricing-schedules-standard-charges.html',
                        controller: 'PricingSchedulesStandardChargesController',
                        controllerAs: 'vm'
                }}, addDetailViews()),
                resolve: {
                    standardChargesCollectionResponse: function (apiClient, $stateParams, pricingScheduleResponse) {
                        return apiClient.getPage(pricingScheduleResponse.getLink('standardcharges'), angular.extend({}, $stateParams, {
                            page: $stateParams.standardChargesPage,
                            limit: $stateParams.standardChargesLimit
                        }));
                    },
                },
                ncyBreadcrumb: {
                    label: '{{ ::"PRICING_SCHEDULES_STANDARD_CHARGES" | translate }}'
                }
            });
    }

    function registerRoutesStandardChargesAdd($stateProvider)
    {
        $stateProvider
            .state('dashboard.admin.pricing-schedules.list.details.standardCharges.add', {
                url: '/standard-charges/add/{pricingScheduleStandardChargesResource}',
                parent:'dashboard.admin.pricing-schedules.list.details.standardCharges',
                views: {
                    '@dashboard.admin.pricing-schedules': {
                        templateUrl: '/modules/admin/pricing-schedules/details/standard-charges/add-edit/pricing-schedules-standard-charges-add-edit.html',
                        controller: 'PricingSchedulesStandardChargesAddEditController',
                        controllerAs: 'vm'
                    }
                },
                resolve: {
                    standardChargesResponse: function () {
                        return {};
                    }
                },
                ncyBreadcrumb: {
                    label: '{{ ::"ADD" | translate }}'
                }
            });
    }

    function registerRoutesStandardChargesEdit($stateProvider)
    {
        $stateProvider
            .state('dashboard.admin.pricing-schedules.list.details.standardCharges.edit', {
                url: '/standard-charges/edit/{pricingScheduleStandardChargesResource}',
                parent:'dashboard.admin.pricing-schedules.list.details.standardCharges',
                views: {
                    '@dashboard.admin.pricing-schedules': {
                        templateUrl: '/modules/admin/pricing-schedules/details/standard-charges/add-edit/pricing-schedules-standard-charges-add-edit.html',
                        controller: 'PricingSchedulesStandardChargesAddEditController',
                        controllerAs: 'vm'
                    }
                },
                resolve: {
                    standardChargesResponse: function (apiClient, $stateParams) {
                        return apiClient.get($stateParams.pricingScheduleStandardChargesResource.decode()).then( function(response) {
                            return response || apiClient.noResourceFound();
                        });
                    },
                },
                ncyBreadcrumb: {
                    label: '{{ ::"EDIT" | translate }}'
                }
            })
            .state('dashboard.admin.pricing-schedules.list.details.markup.edit', {
                url: '/edit/{markupResource}',
                parent: 'dashboard.admin.pricing-schedules.list.details.markup',
                views: {
                    '@dashboard.admin.pricing-schedules': {
                        templateUrl: '/modules/admin/pricing-schedules/details/markup/edit/markup-edit-form.html',
                        controller: 'PricingSchedulesMarkupEditController',
                        controllerAs: 'vm'
                    }
                },
                ncyBreadcrumb: {
                    label: '{{ ::"EDIT" | translate }}'
                }
            });
    }

    function registerRoutesLabourRatesAdd($stateProvider) {
        $stateProvider
            .state('dashboard.admin.pricing-schedules.list.details.labourRates.add', {
                url: '/labour-rates/add/{pricingScheduleLabourRateResource}',
                parent: 'dashboard.admin.pricing-schedules.list.details.labourRates',
                views: {
                    '@dashboard.admin.pricing-schedules': {
                        templateUrl: '/modules/admin/pricing-schedules/details/labour-rates/add-edit/pricing-schedules-labour-rates-add-edit.html',
                        controller: 'PricingSchedulesLabourRatesAddEditController',
                        controllerAs: 'vm'
                    }
                },
                resolve: {
                    pricingScheduleLabourRateResponse: function(apiClient, $stateParams) {
                        return {};
                    },
                    labourTypesResponse: function(apiClient, $stateParams, rootResourceResponse, pricingScheduleResponse) {
                        return apiClient.get(rootResourceResponse.getLink('labourtypes'), {
                            notPricingSchedule: pricingScheduleResponse.id
                        }).then(function(response) {
                                return response || apiClient.noResourceFound();
                            });
                    }
                },
                ncyBreadcrumb: {
                    label: '{{ ::"ADD" | translate }}'
                }
            });
    }

    function registerRoutesLabourRatesEdit($stateProvider) {
        $stateProvider
            .state('dashboard.admin.pricing-schedules.list.details.labourRates.edit', {
                url: '/labour-rates/edit/{pricingScheduleLabourRateResource}',
                parent: 'dashboard.admin.pricing-schedules.list.details.labourRates',
                views: {
                    '@dashboard.admin.pricing-schedules': {
                        templateUrl: '/modules/admin/pricing-schedules/details/labour-rates/add-edit/pricing-schedules-labour-rates-add-edit.html',
                        controller: 'PricingSchedulesLabourRatesAddEditController',
                        controllerAs: 'vm'
                    }
                },
                resolve: {
                    pricingScheduleLabourRateResponse: function(apiClient, $stateParams) {
                        return apiClient.get($stateParams.pricingScheduleLabourRateResource.decode()).then( function(response) {
                            return response || apiClient.noResourceFound();
                        });
                    },
                    labourTypesResponse: function(apiClient, $stateParams, rootResourceResponse, pricingScheduleResponse) {
                        return apiClient.get(rootResourceResponse.getLink('labourtypes'), {
                            notPricingSchedule: pricingScheduleResponse.id
                        }).then(function(response) {
                            return response || apiClient.noResourceFound();
                        });
                    }
                },
                ncyBreadcrumb: {
                    label: '{{ ::"EDIT" | translate }}'
                }
            });
    }
})();
