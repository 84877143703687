app.config(function ($translateProvider) {
    $translateProvider.translations('zh-hk', {
        SERVICE_PROVIDER_GROUP: '服務供應商組別',
        SERVICE_PROVIDER_GROUPS: '服務供應商組別',

        ADD_NEW_SERVICE_PROVIDER_GROUP: '新增服務供應商組別',

        SERVICE_PROVIDER_GROUP_LIST: '服務供應商組別',
        SERVICE_PROVIDER_GROUP_ADD: '新增服務供應商組別',
        SERVICE_PROVIDER_GROUP_EDIT: '修改服務供應商組別',

        SERVICE_PROVIDER_GROUP_CREATED: '服務供應商組別已建立',
        SERVICE_PROVIDER_GROUP_UPDATED: '服務供應商組別已更新',
        SERVICE_PROVIDER_GROUP_DELETED: '服務供應商組別已刪除',

        REQUIRESASSETSWHENLOGGINGREACTIVEJOB: '回應工作需要資產',
        REQUIRES_ASSETS_WHEN_LOGGING_REACTIVE_JOB: '需要資產',
        REQUIRES_ASSET: '需要資產',
        NOT_REQUIRES_ASSET: '不需要資產',

        TRAINEDAT: '訓練於',
        ACTIVEAT: '有效於',
        INACTIVEAT: '無效於',
    });
});
