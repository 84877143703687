(function () {
    /**
     * @desc select scroll widget
     * @example <select select-scroll data-ng-model="vm.model.selected"
     * data-ng-options="option as option.value for option in vm.model">
     */
    angular
        .module('elogbooksDirectives')
        .directive("selectScroll", function () {
            function link($scope, element, attributes) {
                element.bind("scroll", function(event) {
                    if (event.target.scrollTop + event.target.clientHeight + 10 > event.target.scrollHeight) {
                        angular.element(event.target).scope().vm.loadMore();
                        angular.element(event.target).scope().$apply();
                    }
                });
            }

            return ({
                scope: true,
                link: link,
                restrict: "AE"
            });
        });
})();
