(function () {
    'use strict';

    angular
        .module('elogbooksDirectives')
        .directive('elbExportFilesButton', ElogbooksExportFilesButton);

    function ElogbooksExportFilesButton() {
        return {
            restrict: 'E',
            scope: {
                exportLink: '=',
                exportSizeLink: '=',
                title: '@',
                filesSizeResponse: '=',
                count: '='
            },
            transclude: true,
            templateUrl: '/modules/directives/buttons/export-files/export.html',
            controller: function ($scope, apiClient, messenger, $translate, $uibModal, confirmationModal, fileSizeFormatter) {
                $scope.exportResources = exportResources;
                $scope.button = 'EXPORT_FILTERED';
                $scope.size = fileSizeFormatter.format($scope.filesSizeResponse.totalSize);

                if (typeof $scope.title !== "undefined") {
                    $scope.button = $scope.title;
                }

                function exportResources() {
                    confirmationModal.open({
                        titleMessage: 'EXPORT_FILES_MODAL_TITLE',
                        bodyMessage: $translate.instant('EXPORT_FILES_MODAL_MESSAGE', {
                            count: $scope.filesSizeResponse.filesCount,
                            sizeOfFiles: $scope.size.size,
                            unit: $scope.size.unit
                        }),
                        primaryText: 'SUBMIT',
                        primaryClass: 'btn-primary',
                        secondaryText: 'CANCEL',
                    }).result.then(function() {
                        apiClient.get($scope.exportLink).then(handleExportResponse);
                    });
                }

                function handleExportResponse(response) {
                    if (response.id) {
                        messenger.success('EXPORT_IN_PROCESS');
                    }
                }
            },
        };
    }
})();
