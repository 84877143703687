(function(){
    'use strict';

    angular
        .module('elogbooks.admin.sites')
        .controller('SiteContactsController', SiteContactsController);

    SiteContactsController.$inject = ['siteResponse', 'contactsResponse', 'contactInformationResponse', 'lodash', 'canEdit'];

    function SiteContactsController (siteResponse, contactsResponse, contactInformationResponse, lodash, canEdit) {
        var vm = this;
        vm.contactResponse = contactsResponse;
        vm.contacts = vm.contactResponse.contacts;
        vm.contactInformation = contactInformationResponse;
        vm.site = siteResponse;
        vm.addresses = vm.contactInformation.addresses;
        vm.contactInfoCollection = [];
        vm.canEdit = canEdit;

        angular.forEach(vm.contactInformation.fields, function(contact, key) {
            var siteContact = {'value' : contact.value, 'notes': contact.notes};
            var type = lodash.find(vm.contactInfoCollection, function(element) { return element.type ===  contact.type; });

            if ( type !== undefined) {
                type.siteContacts.push(siteContact);
            }
            else {
                type = {'type' : contact.type, 'siteContacts' : []};
                type.siteContacts.push(siteContact);
                vm.contactInfoCollection.push(type);
            }
        });
    }
})();
