(function () {
    'use strict';

    angular
        .module('elogbooksDirectives')
        .directive('multiItemTable', [multiItemTable]);

    function multiItemTable() {
        return {
            restrict: 'A',
            replace: true,
            require: [
                'ngModel'
            ],
            templateUrl: '/modules/directives/multi-item-table/multi-item-table.html',
            scope: {
                model: '=ngModel',
                key: '=key'
            },
            controller: controller
        };

        function controller($scope) {
            $scope.delete = function(item) {
                if (item.id) {
                    // item exists in backend, flag for deletion
                    item.delete = true;
                } else {
                    // item doesn't exist in backend, remove from model
                    var index = $scope.model.indexOf(item);
                    $scope.model.splice(index, 1);
                }
            };

            $scope.add = function() {
                if (!$scope.newItem) {
                    return;
                }

                var item = {};
                item[$scope.key] = $scope.newItem;

                $scope.model.push(item);
                $scope.newItem = '';
            };
        }
    }
})();