(function () {
    'use strict';

    angular
        .module('elogbooks')
        .controller('CertificateTypeInfoController', CertificateTypeInfoController);

    CertificateTypeInfoController.$inject = ['certificateTypeResponse'];

    function CertificateTypeInfoController (certificateTypeResponse) {
        var vm = this;
        vm.certificateType = certificateTypeResponse;
    }
})();
