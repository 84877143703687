(function() {
    'use strict';

    angular
        .module('elogbooks')
        .config(Translations);

    function Translations($translateProvider) {
        $translateProvider.translations('nl', {
            UPDATES_DUE: 'Update nodig',
            REACTIVE_UPDATES_DUE: 'Correctief',
            PLANNED_UPDATES_DUE: 'Contractwerk',
            QUOTE_UPDATES_DUE: 'Offertes',
            SITES_WITH_PPM_REQUIRING_APPROVAL: 'Objecten met contractwerk waar goedkeuring nodig is',
            PAPERWORK_FOR_REVIEW: 'Documenten upload ter beoordeling',
            AWAITING_CLIENT_REVIEW: 'In afwachting beoordeling Property Manager'
        });
    }
})();
