;(function() {
    'use strict';

    angular
        .module('elogbooks')
        .config(translations);

    translations.$inject = ['$translateProvider'];

    function translations($translateProvider) {
        $translateProvider
            .translations('en-gb', {
                FORM: 'Form',
                FORMS: 'Forms',
                FORM_LIST: 'Forms',
                FORMS_WITH_COUNT: 'Forms ({{ ::count || 0 | number:0 }})',

                FORM_REMOVE_COMPONENT: 'Remove',
                FORM_COPY_COMPONENT: 'Copy',

                FORM_ADD: 'Add a Form',
                FORM_EDIT: 'Edit a Form',

                FORM_TYPE: 'Type',
                FORM_VERSION: 'Version',

                FORM_TYPE_JOB: 'Job Form',
                FORM_TYPE_ASSET: 'Asset Form',
                FORM_TYPE_TASK: 'Task Form ',

                FORM_CREATED: 'Form Created',
                FORM_UPDATED: 'Form Updated',
                FORM_DELETED: 'Form Deleted',
                FORM_ASSOCIATION_CREATED: 'Form Added',
                FORM_ASSOCIATION_DELETED: 'Form Removed',

                FORM_INSTANCE_CREATED: 'Form Submitted',
                FORM_INSTANCE_UPDATED: 'Form Saved',
                FORM_INSTANCE_DELETED: 'Form Removed',

                FORM_STATUS_OPEN: 'Open',
                FORM_STATUS_IN_PROGRESS: 'In Progress',
                FORM_STATUS_COMPLETED: 'Completed',

                FORM_VERSION_UPDATED: 'Form Updated',

                FORM_VERSION_NUMBER: 'Version {{ ::version }}',
                FORM_VERSION_CHANGED_SIGNIFICANTLY: 'This form has changed significantly and a new version may be created upon saving',
                FORM_VERSION_PUBLISH: 'Publish Form',
                FORM_VERSION_STATUS: 'Form Version Status',
                FORM_VERSION_STATUS_ARCHIVED: 'Archived',
                FORM_VERSION_STATUS_DRAFT: 'Draft',
                FORM_VERSION_STATUS_PUBLISHED: 'Published',

                FORM_SETTINGS: 'Settings',
                FORM_BUILDER: 'Builder',
                FORM_RENDER_WEB: 'Web View',
                FORM_RENDER_APP: 'App View',

                FORM_SETTING_FONT_SIZE: 'Font Size',
                FORM_SETTING_FONT_COLOUR: 'Text Colour',
                FORM_SETTING_LINE_THICKNESS: 'Line Thickness',
                FORM_SETTING_COLOUR: 'Colour',
                FORM_SETTING_WIDTH: 'Width',
                FORM_SETTING_QUESTION_ALIGNMENT: 'Question Text Alignment',
                FORM_SETTING_QUESTION_ALIGNMENT_AUTO: 'Automatic',
                FORM_SETTING_QUESTION_ALIGNMENT_LEFT: 'To the left of the answer',
                FORM_SETTING_QUESTION_ALIGNMENT_ABOVE: 'Above the answer',
                FORM_SETTING_QUESTION_CHOICE_LABEL_ALIGNMENT: 'Choice Label Alignment',
                FORM_SETTING_QUESTION_CHOICE_LABEL_ALIGNMENT_LEFT: 'To the left of the checkbox',
                FORM_SETTING_QUESTION_CHOICE_LABEL_ALIGNMENT_RIGHT: 'To the right of the checkbox',

                FORM_CONTROL_HEADER: 'Header',
                FORM_CONTROL_FOOTER: 'Footer',
                FORM_CONTROL_QUESTION: 'Question',
                FORM_CONTROL_HORIZONTALRULE: 'Divider',
                FORM_CONTROL_PAGEBREAK: 'Page Break',
                FORM_CONTROL_TEXT: 'Text',
                FORM_CONTROL_MACRO: 'Macro',

                FORM_CONTROL_UNDO: 'Undo',
                FORM_CONTROL_REDO: 'Redo',

                FORM_CONTROL_HEADER_DETAIL: 'Header Text',
                FORM_CONTROL_FOOTER_DETAIL: 'Footer Text',
                FORM_CONTROL_QUESTION_DETAIL: 'Question',
                FORM_CONTROL_MACRO_DETAIL: 'Macro Item',

                FORM_CONTROL_QUESTION_TYPE_DETAIL: 'Question Type',
                FORM_CONTROL_QUESTION_TYPE_TEXT: 'Text (Single Line)',
                FORM_CONTROL_QUESTION_TYPE_TEXTAREA: 'Text (Multiple Lines)',
                FORM_CONTROL_QUESTION_TYPE_CHOICE_SELECT: 'Dropdown Select',
                FORM_CONTROL_QUESTION_TYPE_CHOICE_RADIO: 'Radio Select',
                FORM_CONTROL_QUESTION_TYPE_CHOICE_CHECKBOX: 'Checkbox Select',
                FORM_CONTROL_QUESTION_TYPE_SLIDING_SCALE: 'Sliding Scale',
                FORM_CONTROL_QUESTION_TYPE_DATEPICKER: 'Date Picker',
                FORM_CONTROL_QUESTION_TYPE_FILE: 'File Upload',

                FORM_CONTROL_OPTION_CONDITION: 'Condition',
                FORM_CONTROL_OPTION_CONDITION_ELEMENT: 'Question',
                FORM_CONTROL_OPTION_CONDITION_ELEMENT_SELECT: 'Select an element',
                FORM_CONTROL_OPTION_CONDITION_ELEMENT_SELECTING: 'Selecting an element',
                FORM_CONTROL_OPTION_CONDITION_MATCH_TYPE: 'Matching',
                FORM_CONTROL_OPTION_CONDITION_MATCH_TYPE_DOES_NOT_CONTAIN: 'Does not contain',
                FORM_CONTROL_OPTION_CONDITION_MATCH_TYPE_CONTAINS: 'Contains',
                FORM_CONTROL_OPTION_CONDITION_MATCH_TYPE_BEGINS: 'Begins with',
                FORM_CONTROL_OPTION_CONDITION_MATCH_TYPE_ENDS: 'Ends with',
                FORM_CONTROL_OPTION_CONDITION_MATCH_TYPE_EQUALS: 'Equals',
                FORM_CONTROL_OPTION_CONDITION_MATCH_TYPE_NOT_EQUALS: 'Does not equal',
                FORM_CONTROL_OPTION_CONDITION_MATCH_TYPE_GREATER_THAN: 'Greater than',
                FORM_CONTROL_OPTION_CONDITION_MATCH_TYPE_LESSER_THAN: 'Lesser than',
                FORM_CONTROL_OPTION_CONDITION_MATCH_TYPE_BETWEEN: 'Between',
                FORM_CONTROL_OPTION_CONDITION_VALUE: 'Value',

                FORM_CONTROL_OPTION_ALLOW_NA: 'Allow Not Applicable',
                FORM_CONTROL_OPTION_REQUIRED: 'Required',
                FORM_CONTROL_OPTION_PLACEHOLDER: 'Placeholder Text',
                FORM_CONTROL_OPTION_TEXT_ALIGN: 'Text Alignment',
                FORM_CONTROL_OPTION_ALLOW_TIME: 'Allow Time',
                FORM_CONTROL_OPTION_MIN_DATE_OFFSET: 'Min. Days Offset',
                FORM_CONTROL_OPTION_MAX_DATE_OFFSET: 'Max. Days Offset',
                FORM_CONTROL_OPTION_UNRESTRICTED: 'Unrestricted',

                FORM_CONTROL_TEXTAREA_ROWS: 'Number of Rows',

                FORM_CONTROL_CHOICE_OPTIONS: 'Options',
                FORM_CONTROL_CHOICE_OPTIONS_TABLE_COLUMNS: 'Columns',
                FORM_CONTROL_CHOICE_OPTIONS_SORT_ALPHABETICALLY: 'Sort Alphabetically',
                FORM_CONTROL_CHOICE_PLEASE_SELECT: 'Please select an option',

                FORM_CONTROL_VALIDATION_ALLOW_MULTIPLE: 'Allow Multiple',
                FORM_CONTROL_VALIDATION_MIN_LENGTH: 'Minimum Length',
                FORM_CONTROL_VALIDATION_MIN_LENGTH_ZERO_DETAIL: 'Zero removes the requirement',
                FORM_CONTROL_VALIDATION_MAX_LENGTH: 'Maximum Length',
                FORM_CONTROL_VALIDATION_MAX_LENGTH_ZERO_DETAIL: 'Zero removes the requirement',
                FORM_CONTROL_VALIDATION_MIN_VALUE: 'Minimum Value',
                FORM_CONTROL_VALIDATION_MAX_VALUE: 'Maximum Value',
                FORM_CONTROL_VALIDATION_INTERVAL: 'Interval',

                FORM_MACRO_JOB_SUMMARY: 'Job Summary',
                FORM_MACRO_JOB_DESCRIPTION: 'Job Description',
                FORM_MACRO_JOB_SITE_NAME: 'Site Name',
                FORM_MACRO_JOB_SERVICE_PROVIDER_NAME: 'Service Provider Name',
                FORM_MACRO_JOB_OPERATIVE_NAME: 'Operative Name',
                FORM_MACRO_JOB_ATTENDANCE_DUE_AT: 'Attendance Due Date',

                FORM_MACRO_ASSET_NAME: 'Asset Name',
                FORM_MACRO_ASSET_REFERENCE: 'Reference',
                FORM_MACRO_ASSET_MAKE: 'Make',
                FORM_MACRO_ASSET_MODEL: 'Model',
                FORM_MACRO_ASSET_BARCODE: 'Barcode',
                FORM_MACRO_ASSET_SERIAL_NUMBER: 'Serial Number',
                FORM_MACRO_ASSET_LOCATION_TITLE: 'Asset Location',

                FORM_MACRO_TASK_NAME: 'Task Name',
                FORM_MACRO_TASK_LOCATION_TITLE: 'Task Location',
                FORM_MACRO_TASK_ASSET_NAME: 'Asset Name',
                FORM_MACRO_TASK_ASSET_REFERENCE: 'Asset Reference',
                FORM_MACRO_TASK_ASSET_MAKE: 'Asset Make',
                FORM_MACRO_TASK_ASSET_MODEL: 'Asset Model',
                FORM_MACRO_TASK_ASSET_BARCODE: 'Asset Barcode',
                FORM_MACRO_TASK_ASSET_SERIAL_NUMBER: 'Asset Serial Number',
                FORM_MACRO_TASK_ASSET_LOCATION_TITLE: 'Asset Location',

                FORM_MACRO_VALUE_NOT_FOUND: 'Value Not Found',

                FORM_NO_ANSWER: 'No Answer',

                VIOLATION_FORM_ANSWER_CANNOT_BE_NOT_APPLICABLE: '{{ path }} -- Answer is not allowed to be marked as not applicable',
                VIOLATION_FORM_ANSWER_REQUIRED: '{{ path }} -- Answer is required',
                VIOLATION_FORM_ANSWER_MIN_LENGTH: '{{ path }} -- Minimum length not met',
                VIOLATION_FORM_ANSWER_MAX_LENGTH: '{{ path }} -- Maximum length exceeded',
                VIOLATION_FORM_ANSWER_MIN_VALUE: '{{ path }} -- Minimum value not met',
                VIOLATION_FORM_ANSWER_MAX_VALUE: '{{ path }} -- Maximum value exceeded',
                VIOLATION_FORM_ANSWER_INTERVAL: '{{ path }} -- Invalid interval used',
                VIOLATION_FORM_ANSWER_INVALID_CHOICE: '{{ path }} -- Invalid choice used',
                VIOLATION_FORM_ANSWER_MULTIPLE: '{{ path }} -- Expected array of answers',
                VIOLATION_FORM_ANSWER_MULTIPLE_NOT_ALLOWED: '{{ path }} -- Multiple answers not allowed',
                VIOLATION_FORM_ANSWER_MIN_DATE: '{{ path }} -- Minimum date exceeded',
                VIOLATION_FORM_ANSWER_MAX_DATE: '{{ path }} -- Maximum date exceeded',
                VIOLATION_FORM_WRONG_FONT_SIZE: '{{ path }} -- Invalid font size',
                VIOLATION_FORM_WRONG_LINE_THICKNESS: '{{ path }} -- Invalid line thickness',
                VIOLATION_INVALID_FORM: 'Invalid Form submitted',
                VIOLATION_REQUIRED_FIELD: 'Required Field Missing',

                UNABLE_TO_DELETE_COMPLETED_FORM: 'Unable to remove a completed form',
                INVALID_FORM_VERSION_STATUS: 'Invalid Form Version Status',
                VIOLATION_FORM_CONDITION_UPPER_LIMIT_LESS_THAN_LOWER_LIMIT: 'Upper limit cannot be less than or equal to the lower limit',

                PLEASE_SELECT_A_FORM: 'Please select a form'
            });
    }
})();
