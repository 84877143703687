(function () {
    'use strict';

    angular
        .module('elogbooks.admin.service-types')
        .controller('AdminServiceTypeAddEditController', AdminServiceTypeAddEditController);

    function AdminServiceTypeAddEditController($state, apiClient, messenger, confirmationModal, serviceTypesCollectionResponse, requestDataFactory, serviceTypeResponse) {

        var vm = this;

        vm.serviceType = serviceTypeResponse;
        vm.data = requestDataFactory.createRequest(vm.serviceType);

        vm.create = createAction;
        vm.update = updateAction;
        vm.delete = deleteAction;


        function createAction() {
            return apiClient.create(serviceTypesCollectionResponse.getLink('create'), vm.data).then(function (response) {
                if (response) {
                    $state.go('^', {}, {reload: $state.current.parent}).then(function () {
                        messenger.success('SERVICE_TYPE_ADDED');
                    });
                } else {
                    messenger.error('REQUEST_ERROR');
                }
            });
        }

        function updateAction() {
            return apiClient.update(vm.serviceType.getLink('edit'), vm.data).then(function (response) {
                if (response) {
                    $state.go('^', {}, {reload: $state.current.parent}).then(function () {
                        messenger.success('SERVICE_TYPE_UPDATED');
                    });
                } else {
                    messenger.error('REQUEST_ERROR');
                }
            });
        }

        function deleteAction() {
            return confirmationModal.open().result.then(function (response) {
                return apiClient.delete(vm.serviceType.getLink('delete')).then(function (response) {
                    if (response) {
                        $state.go('^', {}, {reload: $state.current.parent}).then(function () {
                            messenger.success('SERVICE_TYPE_DELETED');
                        });
                    } else {
                        messenger.error('REQUEST_ERROR');
                    }
                });
            });
        }
    }
})();
