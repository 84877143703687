(function () {
    'use strict';

    angular
        .module('elogbooks.user.reports')
        .controller('UserReportsSiteAssociateKPISurveysController', UserReportsSiteAssociateKPISurveysController);

    UserReportsSiteAssociateKPISurveysController.$inject = ['kpiSurveysCollection', 'siteAssociateResponse'];

    function UserReportsSiteAssociateKPISurveysController(kpiSurveysCollection, siteAssociateResponse) {
        var vm = this;

        vm.kpiSurveysCollection = kpiSurveysCollection;
        vm.surveys = kpiSurveysCollection.surveyrequests;
        vm.user = siteAssociateResponse.virtualSM.getLinkAttribute('user', 'title');
    }
})();