(function () {
    'use strict';

    angular
        .module('elogbooks.common.jobs')
        .controller('CommonJobDetailsEventFeedController', CommonJobDetailsEventFeedController);

    CommonJobDetailsEventFeedController.$inject = ['eventCollectionResponse', 'messageCollectionResponse', 'config', 'userManager', 'jobResponse'];

    function CommonJobDetailsEventFeedController (eventCollectionResponse, messageCollectionResponse, config, userManager, jobResponse) {
        var vm = this;
            vm.feed = [];
            vm.messageCollectionResponse = messageCollectionResponse;
            vm.eventCollectionResponse = eventCollectionResponse;

            if (vm.eventCollectionResponse) {
                vm.eventCollectionResponse.events = eventCollectionResponse.events.filter(function(value){
                    if (value.type !== 'job_requisites_answered') {
                        return value;
                    }
                });
            }

            vm.config = config;
            vm.isTenant = userManager.hasPermission('site_permission_tenant')
                            && !userManager.hasPermission('site_permission_helpdesk')
                            && !userManager.hasPermission('site_permission_create_jobs');
            vm.entityResponse = jobResponse;
    }
})();
