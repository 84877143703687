(function() {
    'use strict';

    angular
        .module('elogbooks.admin.core-clients')
        .controller('DocumentTypesInfoController', DocumentTypesInfoController);

    DocumentTypesInfoController.$inject = ['$state', 'apiClient', 'confirmationModal', 'messenger', 'documentTypeResponse'];
    function DocumentTypesInfoController($state, apiClient, confirmationModal, messenger, documentTypeResponse) {
        var vm = this;
        vm.documentType = documentTypeResponse;
    }
})();