(function (){
    'use strict';

    angular
        .module('elogbooks.user.tasks')
        .controller('UserMasterTaskAddEditController', UserMasterTaskAddEditController);
    UserMasterTaskAddEditController.$inject = ['$state', '$filter', 'confirmationModal', 'apiClient', 'messenger', 'masterTaskCollectionResource', 'masterTaskResponse', 'statutoryTypeCollectionResponse', 'masterAssetCollectionResponse'];

    function UserMasterTaskAddEditController($state, $filter, confirmationModal, apiClient, messenger, masterTaskCollectionResource, masterTaskResponse, statutoryTypeCollectionResponse, masterAssetCollectionResponse) {
        var vm = this;
        vm.masterTask = masterTaskResponse;
        vm.masterTask.isStatutory = false;
        vm.alreadyAdded = !(masterTaskResponse.type === 1 || masterTaskResponse.type === 0);
        vm.statuses = [
            { title: $filter('translate')('ACTIVE'), value: 1 },
            { title: $filter('translate')('INACTIVE'), value: 0 }
        ];
        vm.serviceType = [
            { value: 0, title: $filter('translate')('SOFT_SERVICE') },
            { value: 1, title: $filter('translate')('HARD_SERVICE') }
        ];
        vm.statutoryTypes = statutoryTypeCollectionResponse;
        vm.masterAssetCollectionResponse = masterAssetCollectionResponse;

        if (masterAssetCollectionResponse.count > 0) {
            vm.masterAssetSelect = {
                response : masterAssetCollectionResponse,
                allowAdd: true,
                link : masterAssetCollectionResponse._links.self.href,
                responseKeyPath: 'masterAssets',
                itemValuePath: 'summary',
                required: vm.masterTask.type
            };
        }

        vm.create = createAction;
        vm.update = updateAction;
        vm.delete = deleteAction;

        // Fill form data on edit action
        if (masterTaskResponse._links && typeof masterTaskResponse._links.self !== 'undefined') {
            masterTaskResponse.status = masterTaskResponse.status ? 1 : 0;

            if (typeof masterTaskResponse._links.masterasset !== 'undefined') {
                vm.masterAssetSelect.selected = masterTaskResponse._links.masterasset;
                vm.masterAssetSelect.selected.value = masterTaskResponse._links.masterasset.summary;
            }
        }

        /**
         * Create master task
         */
        function createAction() {
            if (vm.masterTask.frequencies.length === 0) {
                return false;
            }

            delete vm.masterTask.assetName;

            if (vm.masterTask.type) {
                if (vm.masterAssetSelect.selected && typeof vm.masterAssetSelect.selected.href !== 'undefined') {
                    vm.masterTask._links = {};
                    vm.masterTask._links.masterasset = { href : vm.masterAssetSelect.selected.href };
                } else if (vm.masterAssetSelect.selected.value.length > 0) {
                    vm.masterTask.assetName = vm.masterAssetSelect.selected.value;
                }
            }

            apiClient.create(masterTaskCollectionResource.getLink('create'), vm.masterTask).then(function (response) {
                if (response) {
                    $state.go('^', {}, {reload: $state.current.parent}).then(function () {
                        messenger.success('ADD_SUCCESS');
                    });
                } else {
                    messenger.error('REQUEST_ERROR');
                }
            });
        }

        /**
         * Update master task
         */
        function updateAction() {
            //Clear links
            var taskRequest = angular.copy(vm.masterTask);
            taskRequest._links = {};

            delete vm.masterTask.assetName;

            if (taskRequest.type && vm.masterAssetSelect.selected) {
                if (typeof vm.masterAssetSelect.selected.href !== 'undefined') {
                    taskRequest._links.masterasset = { href : vm.masterAssetSelect.selected.href };
                } else if (vm.masterAssetSelect.selected.value.length > 0) {
                    taskRequest.assetName = vm.masterAssetSelect.selected.value;
                }
            }

            apiClient.update(vm.masterTask.getLink('self'), taskRequest).then(function (response) {
                if (response) {
                    $state.go('^', {}, {reload: 'dashboard.user.master-tasks.list'}).then(function () {
                        messenger.success('UPDATE_SUCCESS');
                    });
                } else {
                    messenger.error('REQUEST_ERROR');
                }
            });
        }

        /**
         * Delete master task
         */
        function deleteAction() {
            return confirmationModal.open().result.then(
                function () {
                    return apiClient.delete(vm.masterTask.getLink('delete')).then(function (response) {
                        if (response) {
                            $state.go('dashboard.user.master-tasks.list', {}, {reload: 'dashboard.user.master-tasks.list'}).then(function () {
                                messenger.success('DELETE_SUCCESS');
                            });
                        } else {
                            messenger.error('REQUEST_ERROR');
                        }
                    });
                }
            );
        }
    }
})();
