(function() {
    'use strict';

    angular
        .module('elogbooks.common.audit')
        .controller('PartnershipTabsController', PartnershipTabsController);

    PartnershipTabsController.$inject = ['partnershipResponse', '$state', 'modulesService', 'userManager'];

    function PartnershipTabsController(partnershipResponse, $state, modulesService, userManager) {
        var vm = this;
        vm.partnership = partnershipResponse;
        vm.partnershipTitle = '';
        vm.surveysEnabled = modulesService.isEnabled('surveys');
        vm.webhooksEnabled = true;
        vm.isClientAdmin = userManager.hasPermission('user_permission_client_admin_edit_partnerships');

        if ($state.current.name.substring(0, 33) == "dashboard.admin.service-providers") {
            vm.webhooksEnabled = false;
            vm.infoState = "dashboard.admin.service-providers.list.details.partnerships.details.info";
            vm.prioritiesState = "dashboard.admin.service-providers.list.details.partnerships.details.priorities";
            vm.surveysState = "dashboard.admin.service-providers.list.details.partnerships.details.surveys";
            vm.serviceTypesState = "dashboard.admin.service-providers.list.details.partnerships.details.service-types";
        } else if ($state.current.name.substring(0, 20) == "dashboard.user.sites") {
            vm.infoState = "dashboard.user.sites.list.details.partnerships.details.info";
            vm.prioritiesState = "dashboard.user.sites.list.details.partnerships.details.priorities";
            vm.surveysState = "dashboard.user.sites.list.details.partnerships.details.surveys";
            vm.serviceTypesState = "dashboard.user.sites.list.details.partnerships.details.service-types";
            vm.contactsState = "dashboard.user.sites.list.details.partnerships.details.contacts";
            vm.performanceState = "dashboard.user.sites.list.details.partnerships.details.performance";
        } else {
            vm.infoState = "dashboard.admin.sites.list.details.partnerships.details.info";
            vm.prioritiesState = "dashboard.admin.sites.list.details.partnerships.details.priorities";
            vm.surveysState = "dashboard.admin.sites.list.details.partnerships.details.surveys";
            vm.serviceTypesState = "dashboard.admin.sites.list.details.partnerships.details.service-types";
        }
    }
})();
