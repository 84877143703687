(function () {
    'use strict';

    angular
        .module('elogbooks.admin.waste-management')
        .controller('WasteTypeAddEditController', WasteTypeAddEditController);

    WasteTypeAddEditController.$inject = ['$state', 'apiClient', 'confirmationModal', 'wasteTypeResponse', 'requestDataFactory', 'messenger', 'wasteTypeCollectionResponse', '$translate'];

    function WasteTypeAddEditController ($state, apiClient, confirmationModal, wasteTypeResponse, requestDataFactory, messenger, wasteTypeCollectionResponse, $translate) {
        var vm = this;
            vm.wasteType = wasteTypeResponse;
            vm.data = requestDataFactory.createRequest(getDefaults());

        vm.create = createAction;
        vm.update = updateAction;
        vm.delete = deleteAction;

        vm.statusOptions = [
            { value: true, title: $translate.instant('STATUS_ACTIVE') },
            { value: false, title: $translate.instant('STATUS_INACTIVE') }
        ];

        function getDefaults() {
            return {
                id: vm.wasteType.id || null,
                name: vm.wasteType.name || null,
                code: vm.wasteType.code || null,
                status: vm.wasteType.status === 1 || vm.wasteType.status === 0 ? !!vm.wasteType.status : true
            };
        }

        function createAction () {
            return apiClient.create(wasteTypeCollectionResponse.getLink('create'), vm.data).then(function (response) {
                if (response) {
                    $state.go('^', {}, { reload: $state.current.parent }).then(function () {
                        messenger.success('WASTE_TYPE_CREATED');
                    });
                } else {
                    messenger.error('REQUEST_ERROR');
                }
            });
        }

        function updateAction () {
            return apiClient.update(vm.wasteType.getLink('edit'), vm.data).then(function (response) {
                if (response) {
                    $state.go('^', {}, { reload: 'dashboard.admin.waste-management.waste-types' }).then(function () {
                        messenger.success('WASTE_TYPE_UPDATED');
                    });
                } else {
                    messenger.error('REQUEST_ERROR');
                }
            });
        }

        function deleteAction () {
            return confirmationModal.open().result.then(function () {
                return apiClient.delete(vm.wasteType.getLink('delete')).then(function (response) {
                    if (response) {
                        $state.go('dashboard.admin.waste-management.waste-types', {}, { reload: 'dashboard.admin.waste-management.waste-types' }).then(function () {
                            messenger.success('WASTE_TYPE_DELETED');
                        });
                    } else {
                        messenger.error('REQUEST_ERROR');
                    }
                });
            });
        }
    };
})();
