(function () {
    'use strict';

    angular
        .module('elogbooks.admin.labour-types')
        .controller('LabourTypesController', LabourTypesController);

    LabourTypesController.$inject = ['$state', '$stateParams', 'labourTypesCollectionResponse'];

    function LabourTypesController ($state, $stateParams, labourTypesCollectionResponse) {
        var vm = this;
        vm.labourTypes = labourTypesCollectionResponse.labourTypes;
        vm.labourTypesCollectionResponse = labourTypesCollectionResponse;

        vm.search = search;

        vm.criteria = {
            name: { type: 'text', value: $stateParams.name, title: 'NAME' },
        };
        function search(params) {
            var override = {
                page: 1
            };

            $state.go('.', angular.extend({}, params, override), {reload: $state.current});
        };
    }
})();
