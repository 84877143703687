(function() {
    'use strict';

    angular
        .module('elogbooks.common.quote-requests')
        .controller('CommonQuoteRequestAddServiceProviderController', CommonQuoteRequestAddServiceProviderController);

        CommonQuoteRequestAddServiceProviderController.$inject = [
        '$state',
        'apiClient',
        'confirmationModal',
        'config',
        'partnershipCollectionResponse',
        'requestDataFactory',
        'quoteRequestResponse',
        'serviceProviderService',
        'quoteCreateService',
        'priorityService',
        'elbSettings',
        '$stateParams',
        'lodash',
        'messenger',
        '$rootScope',
        '$window'
    ];

    function CommonQuoteRequestAddServiceProviderController(
        $state,
        apiClient,
        confirmationModal,
        config,
        partnershipCollectionResponse,
        requestDataFactory,
        quoteRequestResponse,
        serviceProviderService,
        quoteCreateService,
        priorityService,
        elbSettings,
        $stateParams,
        lodash,
        messenger,
        $rootScope,
        $window
    ) {
        var vm = this;
        vm.data = requestDataFactory.createRequest(
            {
                serviceProviders: {
                    links: []
                },
                files: {
                    links: []
                }
            }
        );
        vm.quoteRequestResponse = quoteRequestResponse;
        vm.serviceProviderService = serviceProviderService;
        vm.config = config;
        vm.submitDisabled = false;
        vm.uploading = false;
        vm.uploadedFile = null;
        vm.budgetQuoteEnabled = elbSettings.getSetting('budget_quotes').value && !$stateParams.budgetQuoteConversion;
        vm.existingServiceProviders = vm.quoteRequestResponse.serviceProviders.map(function(serviceProvider) {
            serviceProvider.existing = true;
            return serviceProvider;
        });
        vm.selectedServiceProviders = [];
        vm.cancel = cancelAction;
        vm.submit = submitAction;
        vm.removeServiceProvider = removeServiceProvider;
        vm.location = lodash.get(quoteRequestResponse, '_links.location');
        vm.completionDueAt = new Date(vm.quoteRequestResponse.completionDueAt);
        vm.isByPlannedDate = !lodash.has(vm.quoteRequestResponse, '_embedded.priority');
        const priorities  = vm.isByPlannedDate ? priorityService.addExtraPriorities([], ['byPlannedDate']) : [vm.quoteRequestResponse._embedded.priority];
        vm.priorities = priorities.map(function (priority) {
            return {href: priority._links.self, value: priority.name}
        })

        vm.selectedPriorityModel = {
            items: vm.priorities,
            selected: vm.priorities[0],
            readonly: true
        };

        vm.selectedServiceProviderModel = {
            response: partnershipCollectionResponse,
            link: partnershipCollectionResponse.getLink('self'),
            linkParameters: {active:1},
            required: false,
            responseKeyPath: 'partnerships',
            itemHrefPath: '_links.serviceprovider.href',
            itemValuePath: '_links.serviceprovider.title',
            onSelect: changeServiceProvider,
            onRemove: changeServiceProvider,
            removeSelected: true,
            selectedItems: vm.existingServiceProviders.concat(vm.selectedServiceProviders),
            formatValue: serviceProviderService.formatServiceProviderName,
            headers: serviceProviderService.isProsureEnabled ? serviceProviderService.prosureStatusHeaders : null,
            reorder: serviceProviderService.prosureGroupReorder
        };

        if (serviceProviderService.isProsureEnabled) {
            vm.selectedServiceProviderModel.loadmoreEnabled = true;
        }

        function changeServiceProvider() {
            quoteCreateService.changeServiceProvider(
                vm.selectedServiceProviderModel,
                vm.selectedServiceProviders
            );
            vm.selectedServiceProviderModel.selectedItems = vm.existingServiceProviders.concat(vm.selectedServiceProviders);
        }

        function removeServiceProvider(serviceProvider){
            quoteCreateService.removeServiceProvider(
                serviceProvider,
                vm.selectedServiceProviders
            );
            vm.selectedServiceProviderModel.selectedItems = vm.existingServiceProviders.concat(vm.selectedServiceProviders);
            vm.selectedServiceProviderModel.init();
        }

        function cancelAction() {
            return confirmationModal.open(config).result.then(function () {
                if($rootScope.previousState) {
                    $state.go($rootScope.previousState, $rootScope.previousStateParams, { reload: false });
                } else {
                    $window.history.back();
                }
            });
        }

        function submitAction() {
            if (vm.selectedServiceProviders.length === 0) {
                return;
            }
            vm.submitDisabled = true;
            vm.data.serviceProviders.links = vm.selectedServiceProviders.map(function(serviceProvider) {
                return {
                    href: serviceProvider.getLink('serviceprovider')
                };
            });

            apiClient.update(vm.quoteRequestResponse.getLink('add-service-provider'), vm.data).then(function(response) {
                if(response) {
                    messenger.success('QUOTE_SERVICE_PROVIDER_UPDATED');
                    if($rootScope.previousState) {
                        $state.go($rootScope.previousState, $rootScope.previousStateParams, { reload: true });
                    } else {
                        $window.history.back();
                    }
                }
            }, function() {
                vm.submitDisabled = false;
            });
        }
    }
})();
