angular.module('elogbooksDirectives').directive('user', ['apiClient', function(apiClient){
    return {
        // Restrict to attributes only.
        restrict: 'A',
        require: ['ngModel'],
        scope: {
            user: '=ngModel'
        },
        templateUrl: '/modules/directives/form-elements/user/user.html',
        link: function (scope) {
            apiClient.get('/users', {order: 'name'}).then(function (response) {
                scope.users = response.getData().users;
            });
        }
    }
}]);
