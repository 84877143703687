angular.module('elogbooksDirectives').directive('jobReassignOperativeAbsenceWarning', [function(){
    return {
        restrict: 'AE',
        require: ['ngModel'],
        scope: {
            absences: '=ngModel'
        },
        templateUrl: '/modules/directives/job-reassign-operative-absence-warning/warning.html',
        controller: function ($scope) {
            $scope.maxDates = 3;
            $scope.showMoreEntries = false;

            $scope.showMore = function() {
                $scope.showMoreEntries = true;
            }

            $scope.showLess = function() {
                $scope.showMoreEntries = false;
            }
        }
    }
}]);
