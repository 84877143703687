(function () {
    'use strict';

    angular
        .module('elogbooks.user.reports')
        .controller('UserReportsSPUsageController', UserReportsSPUsageController);

    UserReportsSPUsageController.$inject = ['$state', '$stateParams', '$filter', 'moment', 'lodash', '$translate', 'serviceProviderCollectionResponse', 'siteCollectionResponse', 'rootResourceResponse'];

    function UserReportsSPUsageController($state, $stateParams, $filter, moment, lodash, $translate, serviceProviderCollectionResponse, siteCollectionResponse, rootResourceResponse) {
        var vm = this;
        vm.stateParams = $stateParams;
        vm.exportParams = {};
        vm.showResults = showResults;

        vm.siteModel = {
            response: siteCollectionResponse,
            link: siteCollectionResponse.getLink('self'),
            responseKeyPath: 'sites',
            selected: findSelectedById(siteCollectionResponse.sites, $stateParams.site)
        };

        vm.serviceProviderModel = {
            response: serviceProviderCollectionResponse,
            link: serviceProviderCollectionResponse.getLink('self'),
            responseKeyPath: 'serviceproviders',
            selected: findSelectedById(serviceProviderCollectionResponse.serviceproviders, $stateParams.serviceProvider)
        };

        vm.dateRange = {
            title: 'BETWEEN_DATES',
            value: {
                startDate: ($stateParams.dateRange !== null && typeof $stateParams.dateRange === 'string') ? moment(new Date($stateParams.dateRange.split(',')[0])) : null,
                endDate: ($stateParams.dateRange !== null && typeof $stateParams.dateRange === 'string') ? moment(new Date($stateParams.dateRange.split(',')[1])) : null,
                endMaxDate: moment().subtract(1, 'day')
            }
        };

        function findSelectedById(collection, id) {
            if (!id) { return; }

            var selected = lodash.find(collection, function(item) {
                return item.id === parseInt(id);
            });

            return selected ? {
                value: selected.id,
                title: selected.name,
                object: selected
            } : null;
        }

        function showResults() {
            var params = {
                site: vm.siteModel.selected ? vm.siteModel.selected.object.id : null,
                serviceProvider: vm.serviceProviderModel.selected ? vm.serviceProviderModel.selected.object.id : null,
                dateRange: vm.dateRange.value ? vm.dateRange.value.startDate.toISOString(true) + ',' + vm.dateRange.value.endDate.endOf('day').toISOString(true) : null,
                reportTab: vm.stateParams.reportTab ? vm.stateParams.reportTab : 'missed-reactive'
            };

            var route = 'dashboard.user.reports.list.view.sp-usage.online.' + params.reportTab;
            $state.go(route, params);
        }
    }
})();


