(function() {
    'use strict';

    angular
        .module('elogbooks.user.sites')
        .controller('UserSitePartnershipDetailsController', UserSitePartnershipDetailsController);

    UserSitePartnershipDetailsController.$inject = ['partnershipResponse', 'serviceProviderResponse'];

    function UserSitePartnershipDetailsController(partnershipResponse, serviceProviderResponse) {
        var vm = this;
        vm.partnershipResponse = partnershipResponse;
        vm.serviceProviderResponse = serviceProviderResponse;
    }
})();
