(function () {
    'use strict';

    angular
        .module('elogbooks.user.reports')
        .controller('UserReportsSiteAssociateActivityDetailsController', UserReportsSiteAssociateActivityDetailsController);

    UserReportsSiteAssociateActivityDetailsController.$inject = ['$filter', '$state', '$stateParams', 'regions', 'siteAssociatesCollectionFMResponse', 'siteAssociatesCollectionRFMResponse',
        'siteAssociateAlias', 'siteAssociatesCollection', 'siteAssociatesApprovalsCollection', '$uibModal', 'moment', '$scope', 'lodash', '$location', 'paramConverterService', 'showPercentagesService',
        'coreClientsCollectionResponse'];

    function UserReportsSiteAssociateActivityDetailsController($filter, $state, $stateParams, regions, siteAssociatesCollectionFMResponse, siteAssociatesCollectionRFMResponse,
            siteAssociateAlias, siteAssociatesCollection, siteAssociatesApprovalsCollection, $uibModal, moment, $scope, lodash, $location, paramConverterService, showPercentagesService,
            coreClientsCollectionResponse) {

        var vm = this;
        vm.siteAssociatesResponse = siteAssociatesCollection;
        vm.siteAssociateApprovalsResponse = siteAssociatesApprovalsCollection;
        vm.associates = lodash.uniqBy(siteAssociatesCollection.associates, '_links.user.title');

        vm.siteAssociateAlias = siteAssociateAlias;
        vm.showPercentages = $stateParams.showPercentages === 'true' ? true : false;
        vm.stateParams = $stateParams;

        vm.showPercentages = showPercentagesService.getShowPercentageState();
        vm.exportParams = {showPercentages: vm.showPercentages};

        vm.search = search;
        vm.saveParams = saveParams;
        vm.order = orderAction;
        vm.updateShowPercentages = updateShowPercentages;

        var regionOptions = regions.regions.map(function (region) {
            return {
                title: region.name,
                value: region.id
            };
        });

        regionOptions.unshift({
            title: $filter('translate')('NONE_SELECTED'),
            value: null
        });

        var findAssociateValue = function(collection, id, user) {
            var associate = lodash.find(collection, function(associate) {
                return (user === 'user' ? associate._links.user.id === parseInt(id) : associate._links.self.id === parseInt(id));
            });

            return associate ? {
                value: (user === 'user' ? associate._links.user.id : associate._links.self.id),
                title: associate._links.user.title
            } : null;
        };

        if ($stateParams.coreClient){
            var params = angular.copy($stateParams);

            params.coreClient = JSON.parse($stateParams.coreClient).v;
        }

        vm.fmSelect = {
            response : siteAssociatesCollectionFMResponse,
            link : siteAssociatesCollectionFMResponse ? siteAssociatesCollectionFMResponse.getLink('self') : null,
            responseKeyPath: 'associates',
            itemValuePath: '_links.user.id',
            itemTitlePath: '_links.user.title',
            searchKey: 'siteAssociateName',
            selected: findAssociateValue(siteAssociatesCollectionFMResponse.associates, $stateParams.siteAssociateId, 'user')
        };

        vm.rfmSelect = {
            response : siteAssociatesCollectionRFMResponse,
            link : siteAssociatesCollectionRFMResponse ? siteAssociatesCollectionRFMResponse.getLink('self') : null,
            responseKeyPath: 'associates',
            itemValuePath: '_links.user.id',
            itemTitlePath: '_links.user.title',
            searchKey: 'siteAssociateName',
            selected: findAssociateValue(siteAssociatesCollectionRFMResponse.associates, $stateParams.rfmId, 'user')
        };

        vm.criteria = {
            siteAssociateId: {
                type: 'selectwidget',
                title: siteAssociateAlias,
                options: vm.fmSelect,
                hideUpdateButton: true
            },
            rfmId: {
                type: 'selectwidget',
                title: 'RFM',
                options: vm.rfmSelect,
                hideUpdateButton: true
            },
            siteRegion: {
                type: 'options',
                title: 'REGION',
                value: parseInt($stateParams.siteRegion) || null,
                options: regionOptions
            },
            dateRange: {
                type: 'date-range',
                title: 'BETWEEN_DATES',
                value: {
                    startDate: ($stateParams.dateRange !== null) ? moment(new Date($stateParams.dateRange.split(',')[0])) : null,
                    endDate: ($stateParams.dateRange !== null) ? moment(new Date($stateParams.dateRange.split(',')[1])) : null
                },
                clearValue: {
                    startDate: null,
                    endDate: null
                }
            }
        };

        if (coreClientsCollectionResponse.coreclients) {
            vm.criteria.coreClient = {
                type: 'jsonselectwidget',
                title: 'CORE_CLIENT',
                value: JSON.parse($stateParams.coreClient),
                options: {
                    response: coreClientsCollectionResponse,
                    link : coreClientsCollectionResponse && coreClientsCollectionResponse.count > 0 ? coreClientsCollectionResponse.getLink('self') : null,
                    responseKeyPath: 'coreclients',
                    itemValuePath: 'id',
                    itemTitlePath: 'name'
                }
            };
        }

        function saveParams (selectModel) {
            if (selectModel.mapValue) {
                if (selectModel.selected === undefined) {
                    params[selectModel.mapValue] = null;
                } else {
                    $stateParams[selectModel.mapValue] = selectModel.selected.object.id
                }
            }

            vm.search();
        }

        function search(params) {

            var override = {
                page: 1
            };
            $state.go('.', angular.extend({}, params, override), { reload: $state.current });
        }

        function updateShowPercentages() {
            vm.exportParams = {showPercentages: !!vm.showPercentages};
            showPercentagesService.saveShowPercentageState(!!vm.showPercentages);
        }

        function orderAction (key) {
            $stateParams.order = key;
            $state.go('.', angular.extend({}, $stateParams), { reload: $state.current });
        }

    }
})();
