(function () {
    'use strict';

    angular
        .module('elogbooksDirectives')
        .directive('saveFilterCriteria', saveFilterCriteria);

    function saveFilterCriteria () {
        return {
            restrict: 'AE',
            scope: {
                filterUser: '=',
                showSaveFiltersDirective: '='
            },
            controller: 'saveFilterCriteriaController'
        };
    }
})();
