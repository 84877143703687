(function () {
    'use strict';

    angular
        .module('elogbooks.user.patrols')
        .controller('PatrolOccurrenceBulkAddController', PatrolOccurrenceBulkAddController);

    PatrolOccurrenceBulkAddController.$inject = [
        'lodash', '$state', 'apiClient', 'messenger', 'requestDataFactory', 'patrolOccurrenceCollection',
        'patrolScheduleResponse', 'partnershipsCollection', 'daysOfWeek', 'confirmationModal'];

    function PatrolOccurrenceBulkAddController (
        lodash, $state, apiClient, messenger, requestDataFactory, patrolOccurrenceCollection,
        patrolScheduleResponse, partnershipsCollection, daysOfWeek, confirmationModal)
    {
        var vm = this;
        vm.serviceProviders = partnershipsCollection.partnerships;
        vm.daysOfWeek = daysOfWeek.getAll();
        vm.selectedDaysOfWeek = [];
        vm.data = requestDataFactory.createRequest(getDefaults());
        vm.create = createAction;
        vm.init = init;
        vm.minFrequency = new Date(0,0,0,0,1);

        vm.spSelect = {
            response : partnershipsCollection,
            link : partnershipsCollection.getLink('self'),
            responseKeyPath: 'partnerships',
            itemHrefPath: '_links.serviceprovider.href',
            itemValuePath: '_links.serviceprovider.title',
            required: true
        };

        function init() {
            if (vm.data.frequency === null) {
                vm.data.frequency = new Date(0, 0, 0, 0, 0);
            }
        }

        function getDefaults() {
            return {
                _links: {
                    schedule: patrolScheduleResponse._links.self,
                    serviceprovider: null
                },
                daysOfWeek: [],
                startTime: new Date(0, 0, 0, 8, 0),
                endTime: new Date(0, 0, 0, 18, 0),
                frequency: null
            };
        }

        function createAction() {
            /* Show a confirmation if a low frequency is set */
            if (vm.data.frequency.getHours() < 1 && vm.data.frequency.getMinutes() < 15) {
                confirmationModal.open({bodyMessage: 'PATROL_BULK_SCHEDULE_LOW_FREQUENCY_WARNING'}).result.then(
                    function () {
                        processCreateAction();
                    },
                    function () {
                        return false;
                    }
                );
            } else {
                processCreateAction();
            }
        }

        function processCreateAction() {
            /* Modify vm.data.daysOfWeek so the response is an array of integers instead of array of objects */
            angular.forEach(vm.selectedDaysOfWeek, function(value, key) {
                vm.data.daysOfWeek.push(value.label);
            });

            vm.data._links.serviceprovider = vm.spSelect.selected.object.getLink('serviceprovider');

            apiClient.create(patrolOccurrenceCollection.getLink('createMulti'), vm.data).then(function (response) {
                if(response) {
                    $state.go('dashboard.user.patrols.manage-patrols.details.schedules.list.details', {}, { reload: true }).then(function () {
                        messenger.success('PATROL_OCCURRENCE_CREATED');
                    });
                } else {
                    messenger.error('REQUEST_ERROR');
                }
            });
        }
    }
})();
