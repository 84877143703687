(function() {
    'use strict';

    angular
        .module('elogbooks.common.waste-treatment-types')
        .controller('WasteTreatmentTypesController', WasteTreatmentTypesController);

    WasteTreatmentTypesController.$inject = ['$state', '$stateParams', '$filter', 'siteTreatmentTypeCollectionResponse'];

    function WasteTreatmentTypesController ($state, $stateParams, $filter, siteTreatmentTypeCollectionResponse) {
        var vm = this;

        vm.treatmentTypes = siteTreatmentTypeCollectionResponse.treatmentTypes;
        vm.treatmentTypesCollectionResponse = siteTreatmentTypeCollectionResponse;

        vm.order = order;
        vm.search = search;

        var statuses = [
            {
                title: $filter('translate')('STATUS_ACTIVE'),
                value: 'true'
            },
            {
                title: $filter('translate')('STATUS_INACTIVE'),
                value: 'false'
            },
            {
                title: $filter('translate')('NONE_SELECTED'),
                value: null
            }
        ];

        vm.criteria = {
            wtId: { type: 'number', value: $stateParams.wtId ? parseInt($stateParams.wtId, 10) : null, title: 'ID', min: 1 },
            wtName: { type: 'text', value: $stateParams.wtName, title: 'NAME' },
            wtStatus: { type: 'options', title: 'IS_ACTIVE', value: $stateParams.wtStatus, options: statuses }
        };

        function search(params) {
            var override = {
                wtPage: 1
            };

            $state.go('.', angular.extend({}, params, override), {reload: $state.current});
        }

        function order(key) {
            var override = {
                wtOrder: key,
                wtPage: 1
            };

            $state.go('.', angular.extend($stateParams, override), {reload: $state.current});
        }
    }
})();
