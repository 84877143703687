(function() {
    'use strict';

    angular
        .module('elogbooks')
        .config(Translations);

    function Translations($translateProvider) {
        $translateProvider.translations('nl', {
            QUOTE_OPTION: 'Offerte optie',
            QUOTE_OPTIONS: 'Offerte opties',

            QUOTE_OPTION_LIST: 'Offerte opties',
            QUOTE_OPTION_VIEW: 'Offerte optie',

            QUOTE_OPTION_ADD: 'Voeg een nieuwe optie toe aan offerte',
            QUOTE_OPTION_EDIT: 'Wijzig optie offerte',

            QUOTE_OPTION_CREATED: 'Quote Option Created',
            QUOTE_OPTION_UPDATED: 'Quote Option Updated',
            QUOTE_OPTION_DELETED: 'Quote Option Deleted',

            QUOTE_OPTION_STATUS_ACCEPTED: 'Geaccepteerd',
            QUOTE_OPTION_STATUS_REJECTED: 'Afgewezen',
            QUOTE_OPTION_STATUS_DRAFT: 'Draft',
            QUOTE_OPTION_STATUS_SUBMITTED: 'Ingediend',

            PLEASE_SELECT_QUOTE_OPTIONS: 'Please select quote options',
            OPTION_REQUIRES_ESCLATION: 'Deze optie is buiten uw mandaat, er is een escalatie nodig',

            QUOTE_OPTION_LABOUR: 'Werkuren',
            QUOTE_OPTION_LABOURS: 'Werkzaamheden',

            QUOTE_OPTION_LABOUR_LIST: 'Werkuren',

            QUOTE_OPTION_LABOUR_ADD: 'Voeg een nieuwe regel met werkzaamheden toe',
            QUOTE_OPTION_LABOUR_EDIT: 'Wijzig',

            QUOTE_OPTION_LABOUR_CREATED: 'Regel werkuren aangemaakt',
            QUOTE_OPTION_LABOUR_UPDATED: 'Regel werkuren aangepast',
            QUOTE_OPTION_LABOUR_DELETED: 'Regel werkuren gemaakt',

            QUOTE_OPTION_LABOUR_LABOUR_HOURS: 'Werk uren',
            QUOTE_OPTION_LABOUR_TRAVEL_HOURS: 'Reis uren',
            QUOTE_OPTION_LABOUR_MILEAGE: 'Kilometerstand',
            QUOTE_OPTION_LABOUR_VALUE: 'Waarden',

            QUOTE_OPTION_MATERIAL: 'Materiaal',
            QUOTE_OPTION_MATERIALS: 'Materialen',

            QUOTE_OPTION_MATERIAL_LIST: 'Materialen',

            QUOTE_OPTION_MATERIAL_ADD: 'Voeg nieuw materiaal toe',
            QUOTE_OPTION_MATERIAL_EDIT: 'Wijzig',

            QUOTE_OPTION_MATERIAL_CREATED: 'Materiaal Toegevoegd',
            QUOTE_OPTION_MATERIAL_UPDATED: 'Materiaal Aangepast',
            QUOTE_OPTION_MATERIAL_DELETED: 'Materiaal Verwijderd',

            QUOTE_OPTION_MATERIAL_QUANTITY: 'Hoeveelheid',
            QUOTE_OPTION_MATERIAL_QUOTED_VALUE: 'Offertebedrag',
            QUOTE_OPTION_MATERIAL_QUOTED_TAX_VALUE: 'BTW offertebedrag',
            QUOTE_OPTION_MATERIAL_ACTUAL_VALUE: 'Kostprijs',
            QUOTE_OPTION_MATERIAL_ACTUAL_TAX_VALUE: 'BTW Kostprijs',
        });
    }
})();
