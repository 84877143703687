(function () {
    'use strict';

    angular
        .module('elogbooks.admin.sites')
        .controller('NotesController', NotesController);

    NotesController.$inject= ['siteResponse', 'noteCollectionResponse', 'siteNoteTypesCollectionResponse'];

    function NotesController (siteResponse, noteCollectionResponse, siteNoteTypesCollectionResponse) {
        var vm = this;
        vm.siteNoteTypes = siteNoteTypesCollectionResponse.sitenotetypes;
        vm.editable = noteCollectionResponse.canCreate;

        angular.forEach(vm.siteNoteTypes, function(siteNoteType, key) {
            siteNoteType.siteNote = null;
            angular.forEach(noteCollectionResponse.siteNotes, function(siteNote) {
                if (siteNoteType.getLink('self') == siteNote.getLink('siteNoteType')) {
                    siteNoteType.siteNote = siteNote;
                }
            });
        });
    }
})();
