(function () {
    'use strict'
    angular
        .module('elogbooks.admin.satisfactionsurveylines')
        .config(registerRoutes);
    function registerRoutes($stateProvider) {
        $stateProvider
            .state('dashboard.admin.satisfactionsurveylines', {
                url: '/satisfactionsurveylines',
                resolve: {
                    satisfactionSurveyLineCollectionResponse: function (apiClient, rootResourceResponse) {
                        return apiClient.getPage(rootResourceResponse.getLink('satisfactionsurveylines'), {
                            page: 1,
                            limit: 999999
                        });
                    }
                },
                templateUrl: '/modules/admin/satisfactionsurveylines/satisfactionsurveylines-list.html',
                controller: 'SatisfactionSurveyLinesController',
                controllerAs: 'vm',
                data: {
                    permissions: ['user_permission_view_satisfaction_survey_lines']
                }
            })
            .state('dashboard.admin.satisfactionsurveylines.add', {
                url: '/add',
                parent: 'dashboard.admin.satisfactionsurveylines',
                templateUrl: '/modules/admin/satisfactionsurveylines/add-edit/satisfactionsurveylines-add-edit.html',
                controller: 'SatisfactionSurveyLineAddController',
                controllerAs: 'vm'
            })
            .state('dashboard.admin.satisfactionsurveylines.edit', {
                url: '/edit/{resource}',
                parent: 'dashboard.admin.satisfactionsurveylines',
                resolve: {
                    satisfactionSurveyLineResponse: function (apiClient, base64, $stateParams) {
                        return apiClient.get(base64.decode($stateParams.resource));
                    }
                },
                templateUrl: '/modules/admin/satisfactionsurveylines/add-edit/satisfactionsurveylines-add-edit.html',
                controller: 'SatisfactionSurveyLineEditController',
                controllerAs: 'vm'
            });
    };
})();
