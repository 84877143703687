(function () {
    'use strict';

    angular
        .module('elogbooks.admin.vat-rates')
        .controller('VatRateInfoController', VatRateInfoController);

        VatRateInfoController.$inject = ['vatRateResponse'];

    function VatRateInfoController (vatRateResponse) {
        var vm = this;
        vm.vatRate = vatRateResponse;
    }
})();