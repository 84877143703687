(function() {
    'use strict';

    angular
        .module('elogbooks.common.finance')
        .controller('FinanceInvoiceEventFeedController', FinanceInvoiceEventFeedController);

    FinanceInvoiceEventFeedController.$inject = [
        'eventCollectionResponse',
        'messageCollectionResponse'
    ];

    function FinanceInvoiceEventFeedController(
        eventCollectionResponse,
        messageCollectionResponse
    ) {
        var vm = this;
        vm.feed = [];
        vm.eventCollectionResponse = eventCollectionResponse;
        vm.messageCollectionResponse = messageCollectionResponse;
    }
})();
