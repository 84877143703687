(function(){
    'use strict';

    angular
        .module('elogbooks.admin.service-providers')
        .controller('ServiceProviderDetailsPrimaryOperativesAddEditController', ServiceProviderDetailsPrimaryOperativesAddEditController);

    ServiceProviderDetailsPrimaryOperativesAddEditController.$inject = ['serviceProviderResponse', 'lodash', '$stateParams', '$state', 'serviceProviderService', 'apiClient', 'messenger', 'requestDataFactory'];

    function ServiceProviderDetailsPrimaryOperativesAddEditController(serviceProviderResponse, lodash, $stateParams, $state, serviceProviderService, apiClient, messenger, requestDataFactory) {
        var vm = this;
        vm.serviceProvider = serviceProviderResponse;
        vm.primaryOperatives = serviceProviderService.getPrimaryOperatives(vm.serviceProvider);
        vm.getOperativesSelect = getOperativesSelect;
        vm.update = updateAction;

        vm.operativesSelect = {
            job: getOperativesSelect('job'),
            escalation: getOperativesSelect('escalation'),
            quote: getOperativesSelect('quote'),
            quoteReviewer: getOperativesSelect('quoteReviewer'),
            patrol: getOperativesSelect('patrol'),
            survey: getOperativesSelect('survey'),
            audit: getOperativesSelect('audit'),
            healthsafety: getOperativesSelect('healthsafety')
        };

        function getOperativesSelect(type) {
            var operativesSelect = {
                link: vm.serviceProvider.getLink('operatives'),
                //linkParameters: { permission: 'operative_permission_receive_jobs' },
                responseKeyPath: 'operatives',
                required: false,
                itemHrefPath: '_links.self.href',
                itemValuePath: '_links.user.title'
            };

            switch(type) {
                case 'job':
                    operativesSelect.linkParameters = { permission: 'operative_permission_receive_jobs' };
                    break;
                case 'escalation':
                    operativesSelect.linkParameters = { canBeEscalationOperative: true };
                    break;
                case 'quote':
                    operativesSelect.linkParameters = { permission: 'operative_permission_view_quotes' };
                    break;
                case 'patrol':
                    operativesSelect.linkParameters = { permission: 'operative_permission_receive_patrol_instances' };
                    break;
                case 'survey':
                    operativesSelect.linkParameters = { permission: 'operative_permission_view_surveys' };
                    break;
                case 'audit':
                    operativesSelect.linkParameters = { permission: 'operative_permission_view_audits' };
                    break;
                case 'healthsafety':
                    operativesSelect.linkParameters = {};
                    break;
                case 'quoteReviewer':
                    operativesSelect.linkParameters = {};
                    break;
            }


            var operative = lodash.find(vm.primaryOperatives, {type: type});

            if (operative && operative.operative) {
                operativesSelect.selected = {
                    href: operative.operative.href,
                    object: operative.operative,
                    value: operative.operative.name,
                }
            }

            return operativesSelect;
        }

        function updateAction() {
            var data = requestDataFactory.createRequest({
                _links: {
                    'primary-job-operative': vm.operativesSelect.job.selected ? vm.operativesSelect.job.selected.href : null,
                    'primary-escalation-operative': vm.operativesSelect.escalation.selected ? vm.operativesSelect.escalation.selected.href : null,
                    'primary-quote-operative': vm.operativesSelect.quote.selected ? vm.operativesSelect.quote.selected.href : null,
                    'primary-patrol-operative': vm.operativesSelect.patrol.selected ? vm.operativesSelect.patrol.selected.href : null,
                    'primary-survey-operative': vm.operativesSelect.survey.selected ? vm.operativesSelect.survey.selected.href : null,
                    'primary-audit-operative': vm.operativesSelect.audit.selected ? vm.operativesSelect.audit.selected.href : null,
                    'primary-health-safety-operative': vm.operativesSelect.healthsafety.selected ? vm.operativesSelect.healthsafety.selected.href : null,
                    'primary-quote-reviewer-operative': vm.operativesSelect.quoteReviewer.selected ? vm.operativesSelect.quoteReviewer.selected.href : null,
                }
            });

            apiClient.update(vm.serviceProvider._links['edit-primary-operatives'].href, data).then(function (response) {
                if (response) {
                    $state.go('^', {}, { reload: $state.get('^.^') }).then(function () {
                        messenger.success('PRIMARY_OPERATIVES_UPDATED');
                    });
                } else {
                    messenger.error('REQUEST_ERROR');
                }
            });
        }
    }
})();
