(function() {
    'use strict';

    angular
        .module('elogbooks.admin.sites')
        .controller('NotesEditController', NotesEditController);

    NotesEditController.$inject = ['$state', 'noteCollectionResponse', 'siteNoteTypesCollectionResponse', 'siteResponse', 'locationResponse', 'messenger', 'apiClient', 'userManager', '$q', '$rootScope'];

    function NotesEditController($state, noteCollectionResponse, siteNoteTypesCollectionResponse, siteResponse, locationResponse, messenger, apiClient, userManager, $q, $rootScope) {
        var vm = this;
        vm.siteNoteTypes = siteNoteTypesCollectionResponse.sitenotetypes;
        vm.noteType = '';
        vm.options = {
            versionCheck: false,
            language: userManager.getPreferenceValue('locale'),
            allowedContent: false,
            extraAllowedContent: false,
            entities: false,
            toolbarGroups : [
                {name: 'basicstyles', groups: [ 'basicstyles', 'cleanup', 'list', 'indent', 'blocks', 'align', 'bidi', 'paragraph' ]}
            ],
            removeButtons : 'Underline,Subscript,Superscript,Paste,PasteText,PasteFromWord,Scayt,Anchor,Image,Styles,Format,About'
        };
        vm.updateButtonDisabled = false;
        $rootScope.forceDirtyConfirm = false;
        $rootScope.skipDirtyCheck = true;

        if (noteCollectionResponse.siteNotes !== undefined) {
            vm.notes = noteCollectionResponse.siteNotes;
            vm.noteType = 'site';
        } else if (noteCollectionResponse.locationNotes !== undefined) {
            vm.notes = noteCollectionResponse.locationNotes;
            vm.noteType = 'location';
        }

        // Loop through each Site Note Type
        angular.forEach(vm.siteNoteTypes, function(siteNoteType) {
            siteNoteType.siteNote = null;

            // Loop through each Note in order to link it up to a Site Note Type
            angular.forEach(vm.notes, function(note) {
                if (siteNoteType.id == note.siteNoteTypeId) {
                    siteNoteType.note = note;
                    siteNoteType.note.original = note.data;
                }
            });

            // No Note entered for this Type yet, set defaults
            if (siteNoteType.note === null || siteNoteType.note === undefined) {
                siteNoteType.note = {
                    data: '',
                    original: '',
                    _links:
                        {
                            site: {href: siteResponse.getLink('self')},
                            siteNoteType: {href: siteNoteType.getLink('self')}
                        }
                    };

                if (locationResponse._links !== undefined) {
                   siteNoteType.note._links.location = {
                       href: locationResponse.getLink('self')
                   };
                }
            }
        });


        vm.updateNote = function (note) {
            return apiClient.update(note.getLink('edit'), angular.copy(note));
        };

        vm.createNote = function (note) {
            if (vm.noteType === 'location') {
                delete note._links.site;
            }

            return apiClient.create(noteCollectionResponse.getLink('create'), angular.copy(note));
        };

        vm.updateNotes = function() {
            vm.updateButtonDisabled = true;
            var requests = [];

            vm.siteNoteTypes.forEach(function (noteType) {
                if (noteType.note.data.replace(/\n$/, '') !== noteType.note.original.replace(/\n$/, '')) {
                    if (noteType.note._links.hasOwnProperty('edit')) {
                        requests.push(vm.updateNote(noteType.note));
                    } else if (noteType.note.data.length > 0) {
                        requests.push(vm.createNote(noteType.note));
                    }
                }
            });

            $q.all(requests).then(function() {
                $rootScope.forceDirtyConfirm = false;
                $rootScope.skipDirtyCheck = true;
                $state.go('.^', {}, { reload: $state.get('^.^') }).then(function () {
                    messenger.success('ALL_SITE_NOTE_TYPES_UPDATED');
                    vm.updateDisabled = false;
                });
            }, function() {
                messenger.error('REQUEST_ERROR');
                vm.updateDisabled = false;
            });
        };

        var isNoteUpdated = function() {
            var stripHtmlTags = function(text) {
                var tag = document.createElement('span');
                tag.innerHTML = text;
                text = tag.textContent || tag.innerText || '';
                return text.trim().replace(/<\/?[^>]+(>|$)/g, '');
            };

            for (var i in siteNoteTypesCollectionResponse.sitenotetypes) {
                var noteType = siteNoteTypesCollectionResponse.sitenotetypes[i];
                var note = noteType.note;

                if (stripHtmlTags(note.data) !== stripHtmlTags(note.original)) {
                    isNoteUpdated = function () {
                        return true;
                    };

                    return true;
                }
            }
        };

        vm.changeData = function() {
            if (isNoteUpdated()) {
                $rootScope.forceDirtyConfirm = true;
                $rootScope.skipDirtyCheck = false;
            }
        };

        vm.checkboxChanged = function() {
            $rootScope.forceDirtyConfirm = true;
            $rootScope.skipDirtyCheck = false;
        };

        vm.cancel = function () {
            $state.go('.^', {}, { reload: $state.get('^.^') });
        };
    }
})();
