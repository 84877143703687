(function () {
    'use strict';

    /**
     * @desc frequency units directive to allow user to select a unit value for frequencies
     * @example <elb-recurrence-rules data-ng-model="MODEL" data-max-rules=1 "></elb-recurrence-rules>
     */
    angular
        .module('elogbooksDirectives')
        .directive('elbRecurrenceRules', recurrenceRules);

    function recurrenceRules() {
        var directive = {
            templateUrl: '/modules/directives/form-elements/recurrence-rules/recurrence-rules.html',
            restrict: 'AE',
            scope: {
                recurrenceRules: '=ngModel',
                required: '=?ngRequired',
                hideForm: '=',
                forceServiceRoutine: '=',
                limitedMode: '=',
                isStatutory: '@',
                statutoryTypes: '=',
                showForm: '=',
                maxRules: '@',
                showDescription: '<',
                showDocumentType: '<',
                hideMonthLastWeekDay: '<',
                descriptionNotRequired: '<'
            },
            require: ['ngModel'],
            transclude: true,
            controller: [
                '$scope',
                'lodash',
                'recurrenceRulesService',
                'apiClient',
                'calendarRuleService',
                'modulesService',
                function(
                    $scope,
                    lodash,
                    recurrenceRulesService,
                    apiClient,
                    calendarRuleService,
                    modulesService
            ) {
                $scope.validationError = false;
                $scope.modalFormSubmitted = false;
                $scope.changeFrequency = changeFrequency;
                $scope.changePrefix = changePrefix;
                $scope.addFrequency = addFrequency;
                $scope.removeFrequency = removeFrequency;
                $scope.editFrequency = editFrequency;
                $scope.clearFrequency = clearFrequency;
                $scope.toggleForm = toggleForm;
                $scope.editKey = false;
                $scope.showForm = $scope.showForm ? $scope.showForm : false;
                $scope.addFrequencyButton = $scope.addFrequencyButton ? $scope.addFrequencyButton : true;
                $scope.recurrenceRulesService = recurrenceRulesService;
                $scope.getRuleFrequencyAsString = recurrenceRulesService.getRuleFrequencyAsString;
                $scope.getRuleMonths = recurrenceRulesService.getRuleMonths;
                $scope.getRuleDayPrefixAsString = recurrenceRulesService.getRuleDayPrefixAsString;
                $scope.getRuleDayNameAsString = recurrenceRulesService.getRuleDayNameAsString;
                $scope.showDescription = !!$scope.showDescription;
                $scope.showDocumentType = !!$scope.showDocumentType;
                $scope.hideMonthLastWeekDay = !!$scope.hideMonthLastWeekDay;
                $scope.descriptionRequired = !!$scope.descriptionNotRequired;
                $scope.pointsModuleEnabled = modulesService.isEnabled('points_module');

                if (typeof $scope.recurrenceRules === 'undefined') {
                    $scope.recurrenceRules = [];
                }

                calendarRuleService.calculateTimeFromRules($scope.recurrenceRules);

                if (typeof $scope.statutoryTypes !== 'undefined') {
                    $scope.isStatutory = true;
                    $scope.isStatutoryConfirm = false;
                    $scope.statutoryTypesSelect = {
                        response : $scope.statutoryTypes,
                        link : $scope.statutoryTypes ? $scope.statutoryTypes.getLink('self') : null,
                        responseKeyPath: 'statutorytypes'
                    };
                }

                if ($scope.showDocumentType) {
                    apiClient.get('/documenttypes', {status: 'active', order: 'name'}).then(function (response) {
                        $scope.documentTypeCollectionResponse = response;

                        if ($scope.documentTypeCollectionResponse.count > 0) {
                            $scope.documentTypeModel = {
                                response: $scope.documentTypeCollectionResponse,
                                link: $scope.documentTypeCollectionResponse ? $scope.documentTypeCollectionResponse.getLink('self') : null,
                                linkParameters: {status: 'active', order: 'name'},
                                responseKeyPath: 'documentTypes'
                            };
                        }
                    });
                }

                /**
                 * Toggles form.
                 */
                function toggleForm(show, isFromEdit) {
                    $scope.showForm = show;

                    if (!isFromEdit) {
                        $scope.rule = {
                            frequency: null,
                            interval: null,
                            day: null,
                            dayNumber: null,
                            prefix: null,
                            month: null
                        };
                    }
                }

                /**
                 * Show and hide rows based on the frequency type.
                 */
                function changeFrequency() {
                    // clear all sub-selects on frequency change
                    $scope.rule.interval = null;
                    $scope.rule.day = null;
                    $scope.rule.dayNumber = null;
                    $scope.rule.month = null;
                    $scope.rule.prefix = null;

                    $scope.dayRow = false;
                    $scope.dayWithLastWeekDayRow = false;
                    $scope.dayNumberRow = false;
                    $scope.prefixRow = true;
                    $scope.monthRow = true;

                    if ($scope.rule.frequency === recurrenceRulesService.frequencies.weekly) {
                        //frequency is weekly, disable month and prefix selection
                        $scope.dayRow = true;
                        $scope.prefixRow = false;
                        $scope.rule.prefix = null;
                        $scope.rule.dayNumber = null;
                        $scope.monthRow = false;
                    } else if ($scope.rule.frequency === recurrenceRulesService.frequencies.monthly) {
                        $scope.prefixRow = true;
                        $scope.monthRow = false;
                    }
                }

                /**
                 * Show and hide day related rows based on the prefix.
                 */
                function changePrefix() {
                    $scope.rule.day = null;
                    if ($scope.rule.prefix === 0) {
                        $scope.dayNumberRow = true;
                        $scope.dayRow = false;
                        $scope.dayWithLastWeekDayRow = false;
                    } else if ( $scope.rule.prefix === -1) {
                        $scope.dayNumberRow = false;
                        $scope.dayRow = $scope.hideMonthLastWeekDay;
                        $scope.rule.dayNumber = null;
                        $scope.dayWithLastWeekDayRow = !$scope.hideMonthLastWeekDay;
                    } else {
                        $scope.rule.dayNumber = null;
                        $scope.dayNumberRow = false;
                        $scope.dayRow = true;
                        $scope.dayWithLastWeekDayRow = false;
                    }
                }

                /**
                 * Transform form frequency to api format and store in model
                 */
                function addFrequency() {
                    if (!$scope.rule.frequency) {
                        return;
                    }

                    //TRANSFORM BEFORE SAVING
                    var rule = {
                        description : $scope.rule.description,
                        frequency : $scope.rule.frequency,
                        interval : $scope.rule.interval,
                        from: $scope.rule.from,
                        generateRequestRuleId: $scope.rule.generateRequestRuleId,
                        serviceRoutineLine: $scope.rule.serviceRoutineLine,
                        _links : {

                        }
                    };

                    if ($scope.documentTypeModel && $scope.documentTypeModel.selected) {
                        rule._links.documentType = {
                            href : $scope.documentTypeModel.selected.href,
                            title : $scope.documentTypeModel.selected.value
                        };
                    }

                    if ($scope.isStatutoryConfirm) {
                        if($scope.statutoryTypesSelect.selected === null){
                            $scope.validationError = true;
                            return;
                        }
                        rule._links.statutorytype = $scope.statutoryTypesSelect.selected;
                    }

                    if (angular.isNumber($scope.rule.day) && $scope.rule.day !== -1) {
                        rule.byDay = [];
                        if ($scope.rule.prefix) {
                            rule.byDay.push([$scope.rule.prefix, $scope.rule.day]);
                        } else {
                            rule.byDay.push([0, $scope.rule.day]);
                        }
                    } else if (angular.isNumber($scope.rule.day) && $scope.rule.day === -1) {
                            rule.byDay = [[-1,1], [-1,2], [-1,3], [-1,4], [-1,5]];
                            rule.bySetPosition = [-1];
                    }

                    if ($scope.rule.month) {
                        rule.byMonth = [$scope.rule.month];
                    }

                    if (angular.isNumber($scope.rule.dayNumber)) {
                        rule.byMonthDay = [$scope.rule.dayNumber];
                    }

                    if ($scope.editKey !== false) {
                        $scope.recurrenceRules[$scope.editKey] = rule;
                    } else {
                        $scope.recurrenceRules.push(rule);
                    }

                    if ($scope.pointsModuleEnabled) {
                        rule.points = $scope.rule.points;
                        rule.hours = $scope.rule.hours;
                        rule.minutes = $scope.rule.minutes;
                    }

                    // Order rules
                    $scope.recurrenceRules = lodash.orderBy($scope.recurrenceRules, ['frequency', 'interval'], ['desc', 'asc']);
                    clearFrequency();
                    $scope.form.$setPristine();
                }

                /**
                 * Remove frequency
                 */
                function removeFrequency(key) {
                    $scope.recurrenceRules.splice(key, 1);
                    clearFrequency();
                }

                /**
                 * Remove frequency
                 */
                function editFrequency(key) {
                    clearFrequency(true);

                    $scope.editKey = key;
                    var tempFrequency = $scope.recurrenceRules[key];
                    $scope.rule.frequency = tempFrequency.frequency;
                    changeFrequency();
                    $scope.rule.interval = tempFrequency.interval;
                    $scope.rule.description = tempFrequency.description;
                    $scope.rule.from = tempFrequency.from;
                    $scope.rule.generateRequestRuleId = tempFrequency.generateRequestRuleId;

                    if ($scope.pointsModuleEnabled) {
                        $scope.rule.points = tempFrequency.points;
                        $scope.rule.hours = tempFrequency.hours;
                        $scope.rule.minutes = tempFrequency.minutes;
                    }

                    if (tempFrequency._links && tempFrequency._links.documentType && $scope.documentTypeModel) {
                        $scope.documentTypeModel.selected = {
                            href: tempFrequency._links.documentType.href,
                            object: lodash.find($scope.documentTypeCollectionResponse.documentTypes, {id:tempFrequency._links.documentType.id}),
                            title: tempFrequency._links.documentType.title
                        };
                    }

                    if (tempFrequency.serviceRoutineLine) {
                        $scope.rule.serviceRoutineLine = tempFrequency.serviceRoutineLine;
                    }

                    if (tempFrequency.byDay && tempFrequency.byDay.length > 0) {
                        if (tempFrequency.byDay[0][0]) {
                            $scope.rule.prefix = tempFrequency.byDay[0][0];
                            changePrefix();
                        }
                        $scope.rule.day = tempFrequency.byDay[0][1];
                    }

                    if (tempFrequency.bySetPosition && tempFrequency.bySetPosition.length > 0 && tempFrequency.byDay && tempFrequency.byDay.length === 5) {
                        if (tempFrequency.bySetPosition[0] === -1) {
                            $scope.rule.prefix = -1;
                            changePrefix();
                            $scope.rule.day = -1;
                        }
                    }

                    if (tempFrequency.byMonthDay && tempFrequency.byMonthDay.length > 0) {
                        if (tempFrequency.byMonthDay[0] >= 0) {
                            $scope.rule.dayNumber = tempFrequency.byMonthDay[0];
                            $scope.rule.prefix = 0;
                        }
                        changePrefix();
                    }

                    if (tempFrequency.byMonth && tempFrequency.byMonth.length > 0) {
                        $scope.rule.month = tempFrequency.byMonth[0];
                    }

                    if (tempFrequency._links && tempFrequency._links.statutorytype) {
                        $scope.statutoryTypesSelect.selected = tempFrequency._links.statutorytype;
                        $scope.isStatutoryConfirm = true;
                    }
                }

                /**
                 * Clear frequency and hide form rows
                 */
                function clearFrequency(isFromEdit) {
                    if ($scope.form) {
                        $scope.form.$setPristine();
                        $scope.form.$setUntouched();
                    }

                    $scope.modalFormSubmitted = false;

                    if ($scope.documentTypeModel) {
                        $scope.documentTypeModel.selected = null;
                    }

                    if (isFromEdit) {
                        $scope.rule = {
                            frequency: null,
                            interval: null,
                            day: null,
                            dayNumber: null,
                            prefix: null,
                            month: null
                        };
                    }

                    $scope.dayRow = false;
                    $scope.dayWithLastWeekDayRow = false;
                    $scope.dayNumberRow = false;
                    $scope.prefixRow = false;
                    $scope.monthRow = false;
                    $scope.validationError = false;

                    $scope.isStatutoryConfirm = false;
                    if (typeof $scope.statutoryTypes !== 'undefined') {
                        $scope.statutoryTypesSelect.selected = null;
                    }
                    $scope.editKey = false;
                    $scope.overMax = $scope.recurrenceRules.length >= $scope.maxRules;
                    $scope.showForm = false;
                }

                clearFrequency();
                if ($scope.$root.currentState === 'dashboard.user.sites.list.details.surveys.schedules.details.edit') {
                    // The index 0 is valid. It always takes the first frequency in the BE.
                    editFrequency(0);
                }
            }]
        };

        return directive;
    }
})();
