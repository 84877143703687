app.config(function ($translateProvider) {
    $translateProvider.translations('nl', {
        ASSET: 'Asset',
        ASSETS: 'Assets',
        ASSET_LIST: 'Assets',
        ASSET_ADD: 'Voeg een nieuwe Asset toe',
        ASSET_EDIT: 'Wijzig Asset',
        ASSET_CREATED: 'Asset gemaakt',
        ASSET_UPDATED: 'Asset gewijzigd',
        ASSET_DELETED: 'Asset verwijderd',
        ASSET_IMPORTS: 'Asset Import',
        MANAGE_ASSETS: 'Manage Assets',

        SUB_ASSET: 'Sub Asset',
        SUB_ASSETS: 'Sub Assets',
        SUB_ASSET_LIST: 'Sub Assets',
        SUB_ASSET_ADD: 'Voeg een nieuwe Sub Asset toe',
        SUB_ASSET_CREATED: 'Sub Asset gemaakt',
        SUB_ASSET_UPDATED: 'Sub Asset gewijzigd',
        SUB_ASSET_DELETED: 'Sub Asset verwijderd',
        SUB_ASSET_EDIT: 'Sub Asset wijzigen',

        MAKE: 'Fabrikaat',
        MODEL: 'Model',
        SERIAL: 'Serienummer',
        BUSINESS_CONTINUITY_RISK: 'Bedrijfscontinuiteit',
        REPLACEMENT_COST_LABOUR: 'Vervangingskosten arbeid',
        REPLACEMENT_COST_MATERIALS: 'Vervangingskosten materiaal',
        END_OF_LIFE: 'Einde levensduur',
        CHARGEABLE: 'Belastbaar',
        ASSET_TYPE: 'Type',
        JOB_GROUP: 'Opdrachtgroep',
        NUMBER: 'Nummer',
        STATUTORY_TYPE: 'Wettelijk voorgeschreven Type',
        SERVICE_ROUTINE: 'Service Routine',
        IS_STATUTORY: 'Is wettelijk voorgeschreven Type?'
    });
});
