(function () {
    'use strict';

    angular
        .module('elogbooks.user.sites')
        .controller('UserSitePatrolsController', UserSitePatrolsController);

    UserSitePatrolsController.$inject = ['sitePatrolsCollection', 'lodash', 'moment', 'patrolInstanceModal'];

    function UserSitePatrolsController(sitePatrolsCollection, lodash, moment, patrolInstanceModal) {
        var vm = this;
        vm.hours = lodash.range(24);
        vm.patrols = sitePatrolsCollection.sitePatrols;
        vm.entityCount = sitePatrolsCollection.count;

        angular.forEach(vm.patrols, function(patrol) {
            if (patrol.patrolInstances.length > 0) {
                patrol.sp = patrol.patrolInstances[0]._links.serviceprovider;
            }
            for (var idx = 0; idx < patrol.patrolInstances.length; idx++) {

                var instance = patrol.patrolInstances[idx];

                patrol.patrolInstances[idx]['workflowStatus'] = getWorkflowStatus(instance);
                patrol.patrolInstances[idx]['start'] = { time: moment(instance.plannedStartDate).format('HH:mm'), minutes: (moment(instance.plannedStartDate).hours() * 60) + moment(instance.plannedStartDate).minutes() }
                patrol.patrolInstances[idx]['finish'] = { time: moment(instance.plannedEndDate).format('HH:mm'), minutes: (moment(instance.plannedEndDate).hours() * 60) + moment(instance.plannedEndDate).minutes() };
            }
        });

        vm.viewPatrolPoint = function (element) {
            patrolInstanceModal.open(element.instance);

        };

        function getWorkflowStatus(instance) {
            if ( (instance.status === 'in_progress' && moment().isAfter(instance.plannedEndDate)) ||
                (instance.status === 'registered' && moment().isAfter(instance.plannedStartDate)) ) {
                return 'overdue';
            } else {
                return instance.status.replace('_', '');
            }
        }
    }

})();
