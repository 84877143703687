(function () {
    'use strict';

    angular
        .module('elogbooks.user.assets')
        .controller('CommonImportListController', ['importsCollectionResponse', '$state', '$stateParams', '$translate', CommonImportListController]);

    function CommonImportListController(importsCollectionResponse, $state, $stateParams, $translate) {
        var vm = this;
        vm.importsCollection = importsCollectionResponse;
        vm.imports = importsCollectionResponse.imports;
        vm.entityName = $translate.instant('IMPORTS');
        vm.importRoute = '.add';
        vm.isCurrentState = $state.current.name.indexOf('dashboard.admin.imports') > -1;
        vm.noBorder = false;

        if ($stateParams.type === null) {
            vm.noType = true;
        } else if (!vm.isCurrentState) {
            vm.noBorder = true;
        }

        // Hateoas serializes templates response as an object rather than array if there's only one object in the 'collection'.
        var imports = (typeof importsCollectionResponse._links.templates.length === 'undefined') ? [importsCollectionResponse._links.templates] : importsCollectionResponse._links.templates;

        var typeOptions = [
            {
                title: $translate.instant('NONE_SELECTED'),
                value: null
            }
        ];

        angular.forEach(imports, function(importEntity) {
            typeOptions.push({
                title: $translate.instant(importEntity.name.toUpperCase()),
                value: importEntity.name
            });
        });

        vm.search = searchAction;
        vm.criteria = {
            type: { type: 'options', title: 'TYPE', value: $stateParams.type, options: typeOptions }
        };

        function searchAction (params) {
            var override = {
                page: 1
            };
            $state.go('.', angular.extend({}, params, override), { reload: $state.current });
        }
    }
})();
