angular.module('elogbooksServices').service('allowNotApplicable', function($filter) {

    this.getAll = getAll;
    this.getName = getName;

    var options = [
        { value: true, name: $filter('translate')("ALLOW_NOT_APPLICABLE"), title: $filter('translate')("ALLOW_NOT_APPLICABLE") },
        { value: false, name: $filter('translate')("DISALLOW_NOT_APPLICABLE"), title: $filter('translate')("DISALLOW_NOT_APPLICABLE") },
    ];

    function getAll() {
        return options;
    }

    function getName(value) {
        if(value === true) {
            return options[0].name;
        }
        else if (value === false) {
            return options[1].name;
        }

        return null;
    }

    return this;
});
