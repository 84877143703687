(function () {
    'use strict';

    angular
        .module('elogbooks')
        .controller('CertificateTypeAddEditController', CertificateTypeAddEditController);

    CertificateTypeAddEditController.$inject = ['apiClient', 'rootResourceResponse', '$state', 'messenger', 'requestDataFactory', 'certificateTypeResponse', 'confirmationModal'];

    function CertificateTypeAddEditController (apiClient, rootResourceResponse, $state, messenger, requestDataFactory, certificateTypeResponse, confirmationModal) {
        var vm = this;
            vm.data = requestDataFactory.createRequest(certificateTypeResponse);
            vm.certificateTypeResponse = certificateTypeResponse;

        vm.create = createAction;
        vm.delete = deleteAction;
        vm.update = updateAction;

        function createAction () {
            apiClient.create(rootResourceResponse.getLink('certificatetypes'), vm.data).then(function (response) {
                if (response) {
                    $state.go('dashboard.admin.certificatetypes.list.details.info', { resource: response.getLink('self').encode() }, { reload: true }).then(function () {
                        messenger.success('CERTIFICATE_TYPE_CREATED');
                    });
                } else {
                    messenger.error('REQUEST_ERROR');
                }
            });
        }

        function updateAction () {
            apiClient.update(vm.certificateTypeResponse._links.self.href, vm.data).then(function (response) {
                if (response) {
                    $state.go('^', {}, { reload: true }).then(function () {
                        messenger.success('CERTIFICATE_TYPE_UPDATED');
                    });
                } else {
                    messenger.error('REQUEST_ERROR');
                }
            });
        }

        function deleteAction () {
            return confirmationModal.open().result.then(function() {
                return apiClient.delete(vm.certificateTypeResponse._links.delete.href).then(function (response) {
                    if (response) {
                        $state.go('dashboard.admin.certificatetypes.list', {}, { reload: 'dashboard.admin.certificatetypes.list' }).then(function () {
                            messenger.success('CERTIFICATE_TYPE_DELETED');
                        });
                    } else {
                        messenger.error('REQUEST_ERROR');
                    }
                })
            });
        }
    }
})();
