/**
 * LegendService
 */
angular.module('elogbooksServices').service('legendService', function (lodash) {

    this.structureLegend = structureLegend;

    /**
     * Return structured legend data
     */
    function structureLegend(legend) {
        var legendResponse = [];

        lodash.forIn(legend, function(meta, key) {
            var data = [];
            var parameters = [];

            data.field = key;

            //description
            if (meta.description) {
                data.description = meta.description;
            }

            //range requirements
            if (meta.range_requirements) {
                var rangeParams = [];
                lodash.forIn(meta.range_requirements, function(value, key) {
                    if (key === 'range_type') {
                        rangeParams.push({title: 'TYPE', value: value });
                    }

                    if (key === 'range_min') {
                        rangeParams.push({title: 'MIN_VALUE', value: value });
                    }

                    if (key === 'range_max') {
                        rangeParams.push({title: 'MAX_VALUE', value: value });
                    }
                });
                parameters.push(rangeParams);
            }

            //length requirements
            if (meta.length_requirements) {
                var lengthParams = [];
                lodash.forIn(meta.length_requirements, function(value, key) {
                    if (key === 'length_min') {
                        lengthParams.push({title: 'MIN_LENGTH', value: value });
                    }

                    if (key === 'length_max') {
                        lengthParams.push({title: 'MAX_LENGTH', value: value });
                    }
                });
                parameters.push(lengthParams);
            }

            //id requirements
            if (meta.id_requirements) {
                var idParams = [];
                lodash.forIn(meta.id_requirements, function(value, key) {
                    if (key === 'id_type') {
                        idParams.push({title: 'TYPE', value: value });
                        data.id = 'COMMA_SEPARATED';
                    }
                });
                parameters.push(idParams);
            }

            //choice requirements
            if (meta.choice_requirements) {
                var choiceParams = [];
                lodash.forIn(meta.choice_requirements, function(choice, key) {
                    if (key === 'choices') {
                        var choices = [];
                        lodash.forEach(choice, function(value) {
                            choices.push(value);
                        });
                        choiceParams.push({title: 'CHOICES', value: choices.join(',') });
                    }
                });
                parameters.push(choiceParams);
            }

            //field choice requirements
            if (meta.fieldChoice_requirements) {
                var fieldChoiceParams = [];
                lodash.forIn(meta.fieldChoice_requirements, function(choice, key) {
                    if (key === 'choices') {
                        var choices = [];
                        lodash.forEach(choice, function(value) {
                            choices.push(value);
                        });
                        fieldChoiceParams.push({title: 'CHOICES', value: choices.join('/') });
                    }
                });
                parameters.push(fieldChoiceParams);
            }

            //number field legend
            if (meta.numberLegend_requirements) {
                var numberLegendParams = [];
                lodash.forIn(meta.numberLegend_requirements, function(choice, key) {
                    if (key === 'choices') {
                        var choices = [];
                        lodash.forEach(choice, function(value) {
                            choices.push(value.join(' - '));
                        });
                        numberLegendParams.push({title: 'NUMBER_LEGEND', value: choices.join(',') });
                    }
                });
                parameters.push(numberLegendParams);
            }

            //user requirements
            if (meta.user_requirements) {
                var userParams = [];
                lodash.forIn(meta.user_requirements, function(value, key) {
                    if (key === 'approver') {
                        userParams.push({title: 'BE_APPROVER', value: value });
                    }
                });
                parameters.push(userParams);
            }

            //type requirements
            if (meta.type_requirements) {
                var typeParams = [];
                lodash.forIn(meta.type_requirements, function(value, key) {
                    if (key === 'type') {
                        typeParams.push({title: 'TYPE', value: value });
                    }
                });
                parameters.push(typeParams);
            }

            //dateTime requirements
            if (meta.datetime_requirements) {
                var datetimeParams = [];
                lodash.forIn(meta.datetime_requirements, function(value, key) {
                    if (key === 'required') {
                        data.required = value;
                    } else {
                        datetimeParams.push({title: 'DATE', value: value});
                    }
                });
                parameters.push(datetimeParams);
            }

            //date requirements
            if (meta.date_requirements) {
                var dateParams = [];
                lodash.forIn(meta.date_requirements, function(value, key) {
                    if (key === 'required') {
                        data.required = value;
                    } else {
                        dateParams.push({title: 'DATE', value: value});
                    }
                });
                parameters.push(dateParams);
            }

            //not blank/required
            if (meta.required) {
                var requiredParams = [];

                data.required = 'true';
            }

            //not blank/required when dependent field is blank
            if (meta.required_when) {
                data.required_when = meta.required_when['dependent'];
            }

            data.restrictions = parameters;
            legendResponse.push(data);

        });
        return legendResponse;
    }

});
