(function () {
    'use strict';

    angular
        .module('elogbooks.admin.settings')
        .controller('AdminQuoteExtraSettingsController', AdminQuoteExtraSettingsController);

    function AdminQuoteExtraSettingsController($scope, apiClient, messenger, quoteExtraSettings) {
        var vm = this;
            vm.settings = quoteExtraSettings;
    }
})();

