(function() {
    'use strict';

    angular
        .module('elogbooks.common.actions')
        .controller('CommonActionsListController', CommonActionsListController);

    CommonActionsListController.$inject = [
        '$window',
        '$state',
        '$stateParams',
        'actionCollectionResponse',
        'actionTypeCollectionResponse',
        'actionService',
        'modulesService',
        '$translate',
        'coreClientCollectionResponse',
        'selectedSiteResponse',
        'canBulkCancel',
        'lodash',
        'bulkCancelActionsModal',
        'siteResponse'

    ];

    function CommonActionsListController(
        $window,
        $state,
        $stateParams,
        actionCollectionResponse,
        actionTypeCollectionResponse,
        actionService,
        modulesService,
        $translate,
        coreClientCollectionResponse,
        selectedSiteResponse,
        canBulkCancel,
        lodash,
        bulkCancelActionsModal,
        siteResponse
    ) {
        var vm = this;
        vm.actionCollectionResponse = actionCollectionResponse;
        vm.canBulkCancel = canBulkCancel;
        vm.hasBulkActions = vm.canBulkCancel;
        vm.actionsSelected = 0;
        vm.isAllSelected = false;
        vm.cancelActions = cancelActions;
        vm.toggleAll = toggleAll;
        vm.countSelected = countSelected;
        vm.toggleSelected = toggleSelected;

        function cancelActions (selected) {
            var actions = setActions(selected);
            var bulkActionModal = bulkCancelActionsModal.open(
                actions,
                selected,
                selected ? vm.actionsSelected : vm.actionCollectionResponse.count,
                vm.actionCollectionResponse.getLink('bulk'),
                selectedSiteResponse || siteResponse
            );

            bulkActionModal.result.then(function () {
                clearActionSelection();
            });
        }

        function setActions () {
            return lodash.filter(vm.actionCollectionResponse.actions, {'selected': true});
        }

        function clearActionSelection () {
            lodash.forEach(vm.actionCollectionResponse.actions, function (action) {
                action.selected = false;
            });
            vm.actionsSelected = 0;
            vm.isAllSelected = false;
        }

        function toggleAll () {
            lodash.forEach(vm.actionCollectionResponse.actions, function (action) {
                action.selected = vm.isAllSelected;
            });
        }

        function toggleSelected (action) {
            return action.selected ? action.selected : false;
        }

        function countSelected (action) {
            if (action) {
                vm.actionsSelected = action.selected ? vm.actionsSelected += 1 : vm.actionsSelected -= 1;
                return;
            }
            vm.actionsSelected = vm.isAllSelected ? vm.actionCollectionResponse.actions.length : 0;
        }

        var actionTypeOptions = actionTypeCollectionResponse.actiontypes.map(function(actionType) {
            return {
                value: actionType.id.toString(), // String cast needed for search-criteria widget pre-select
                title: JSON.parse(actionType.fullPathString)
                    .map(function(stringPart) { return stringPart.name; })
                    .join(' / ')
            };
        });

        var remedialOptions = [
            {
                title: $translate.instant('NONE_SELECTED'),
                value: null
            },
            {
                title: $translate.instant('YES'),
                value: 'true'
            },
            {
                title: $translate.instant('NO'),
                value: 'false'
            }
        ];

        var siteStatusOptions = [
            {
                title: $translate.instant('ACTIVE'),
                value: 'active'
            },
            {
                title: $translate.instant('INACTIVE'),
                value: 'inactive'
            },
            {
                title: $translate.instant('ALL'),
                value: 'all'
            }
        ];

        if (!Array.isArray($stateParams.actionsStatus)) {
            $stateParams.actionsStatus = ['open'];
        }

        vm.criteria = {
            actionsId: { type: 'number', title: 'ID', value: $stateParams.actionsId ? parseInt($stateParams.actionsId, 10) : null },
            actionSummary: { type: 'text', title: 'SUMMARY', value: $stateParams.actionSummary ? $stateParams.actionSummary : null },
            actionsStatus:{ type: 'options', multiple: true, title: 'STATUS', value: $stateParams.actionsStatus, options: actionService.getStatusOptions() },
            actionTypes:{ type: 'options', multiple: true, title: 'ACTION_TYPE', value: $stateParams.actionTypes, options: actionTypeOptions },
            actionsDateDueStart: { type: 'date', value: $stateParams.actionsDateDueStart, title: 'DATE_DUE_START' },
            actionsDateDueEnd: { type: 'date', value: $stateParams.actionsDateDueEnd, title: 'DATE_DUE_END' },
            actionsCreatedAtStart: { type: 'date', value: $stateParams.actionsCreatedAtStart, title: 'CREATED_AT_START' },
            actionsCreatedAtEnd: { type: 'date', value: $stateParams.actionsCreatedAtEnd, title: 'CREATED_AT_END' },
            actionsPriorityName: { type: 'text', value: $stateParams.actionsPriorityName, title: 'PRIORITY' },
            actionsRemedial: { type: 'options', value: $stateParams.actionsRemedial, options: remedialOptions, title: 'REMEDIALS' }
        };

        if (coreClientCollectionResponse) {
            vm.coreClientSelect = {
                response : coreClientCollectionResponse,
                link : coreClientCollectionResponse ? coreClientCollectionResponse.getLink('self') : null,
                itemValuePath: 'id',
                itemTitlePath: 'name',
                responseKeyPath: 'coreclients'
            };

            vm.criteria.coreClient = { type: 'jsonselectwidget', title: 'CORE_CLIENT', value: JSON.parse($stateParams.coreClient), options: vm.coreClientSelect };
        }
        if ($state.is('dashboard.user.actions.manage-actions.list')) {
            vm.criteria.siteStatus = { type: 'options', value: $stateParams.siteStatus, options: siteStatusOptions, title: 'SITE_STATUS' }
        }

        var enabledThirdPartyIntegrations = modulesService.enabledModules(modulesService.thirdPartyIntegrations());

        if (enabledThirdPartyIntegrations.length > 0) {
            var externalSystemOptions = enabledThirdPartyIntegrations.map(function(integration) {
                return {
                    value: integration,
                    title: $translate.instant(integration.toUpperCase())
                };
            });

            vm.criteria.actionsExternalSystem = {
                type: 'options',
                multiple: true,
                title: 'EXTERNAL_SYSTEM',
                value: $stateParams.actionsExternalSystem,
                options: externalSystemOptions
            }
        }

        vm.search = searchAction;
        vm.order = orderAction;

        function searchAction(params) {
            var override = {
                actionsPage: 1
            };

            // Clear the elb-next-previous current index.
            $window.localStorage.setItem('list_current_item_actions', 0);

            $state.go('.', angular.extend({}, params, override), { reload: $state.current });
        }

        function orderAction(key) {
            $stateParams.actionsOrder = key;

            // Clear the elb-next-previous current index.
            $window.localStorage.setItem('list_current_item_actions', 0);

            $state.go('.', angular.extend({}, $stateParams), { reload: $state.current });
        }
    }
})();
