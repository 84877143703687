angular.module('elogbooksDirectives').directive('order', [function(){
    return {
        restrict: 'AE',
        scope: {
            key: '@key',
            callback: '=callback',
            orderParam: '@?'
        },
        transclude: true,
        templateUrl: '/modules/directives/order/order.html',
        controller: function ($scope, $stateParams) {
            if (typeof $scope.orderParam === 'undefined') {
                $scope.orderParam = 'order';
            }

            $scope.params = $stateParams;
            $scope.order = $stateParams[$scope.orderParam];
        },
        link: function (scope, element, attributes) {
            scope.update = function(key) {
                if (scope.order && scope.order === key) {
                    scope.order = '-'+key;
                } else {
                    scope.order = key;
                }

                scope.callback(scope.order);
            };

            element.on('click', function () {
                scope.update(scope.key);
            }).addClass('clickable');
        }
    }
}]);
