(function() {
    'use strict';

    angular
        .module('elogbooks.common.assets')
        .controller('UserAssetsHistoricalScoresController', UserAssetsHistoricalScoresController);

    UserAssetsHistoricalScoresController.$inject = [
        'historicalScoresCollectionResponse',
        'assetScoreTypesResponse',
        'assetResponse',
        '$state',
        '$stateParams',
        'paramConverterService'
    ];
    function UserAssetsHistoricalScoresController(
        historicalScoresCollectionResponse,
        assetScoreTypesResponse,
        assetResponse,
        $state,
        $stateParams,
        paramConverterService
        ) {
        var vm = this;
        vm.historicalScoresCollection = historicalScoresCollectionResponse;
        vm.historicalScores = historicalScoresCollectionResponse.historicalScores;
        vm.hasSubAssets = assetResponse.hasSubAssets && !$stateParams.subAssetId;
        vm.search = searchAction;
        vm.order = orderAction;
        vm.scoreTypeOptions = paramConverterService.formatResponse(assetScoreTypesResponse.scoreTypes);

        vm.criteria = {
            dateRange: {
                type: 'date-range',
                title: 'BETWEEN_DATES',
                value: {
                    startDate: ($stateParams.dateRange !== null && typeof $stateParams.dateRange === 'string') ? moment(new Date($stateParams.dateRange.split(',')[0])) : null,
                    endDate: ($stateParams.dateRange !== null && typeof $stateParams.dateRange === 'string') ? moment(new Date($stateParams.dateRange.split(',')[1])) : null,
                    endMaxDate: moment()
                },
                clearValue: {
                    startDate: null,
                    endDate: null
                }
            },
            assetScoreType: {
                type: 'options',
                title: 'ASSET_SCORE_TYPE',
                value: JSON.parse($stateParams.assetScoreType),
                options:vm.scoreTypeOptions }
        };

        function searchAction(params) {
            var override = {
                historicalScorePage: 1
            };

            $state.go('.', angular.extend({}, params, override), { reload: $state.current });
        }

        function orderAction(key) {
            var override = {
                historicalScorePage: 1,
                historicalScoreOrder: key
            };

            $state.go('.', angular.extend({}, $stateParams, override), { reload: $state.current });
        }
    }
})();