(function () {
    'use strict';

    angular
        .module('elogbooks.admin.serviceroutines')
        .controller('ServiceRoutineLinesController', ServiceRoutineLinesController);

    ServiceRoutineLinesController.$inject = [
        'serviceRoutineResponse',
        'serviceRoutineLineCollectionResponse',
        'sortableOptions',
        'requestDataFactory',
        'crudService',
        'calendarRuleService',
        'elbSettings'
    ];

    function ServiceRoutineLinesController (
        serviceRoutineResponse,
        serviceRoutineLineCollectionResponse,
        sortableOptions,
        requestDataFactory,
        crudService,
        calendarRuleService,
        elbSettings
    ) {
        var vm = this;

        vm.serviceRoutine = serviceRoutineResponse;
        vm.serviceRoutineLines = serviceRoutineLineCollectionResponse.serviceRoutineLines;
        vm.serviceRoutineLineCollectionResponse = serviceRoutineLineCollectionResponse;
        vm.pointsModuleEnabled = elbSettings.getSetting('points_module_enabled') ? elbSettings.getSetting('points_module_enabled').value : false;

        vm.reorderedServiceRoutineLines = angular.copy(vm.serviceRoutineLines);
        vm.data = requestDataFactory.createRequest(vm.serviceRoutine);
        calendarRuleService.calculateTimeFromRules(vm.reorderedServiceRoutineLines);

        vm.reset = resetAction;
        vm.update = updateAction;

        vm.sortableOptions = sortableOptions.addProperty({
            orderChanged: orderChanged
        });

        function updateAction () {
            vm.data._links.serviceRoutineLines = [];

            angular.forEach(vm.reorderedServiceRoutineLines, function (value, $index) {
                vm.data._links.serviceRoutineLines.push(value._links.self);
            });

            return crudService.update(
                vm.serviceRoutine.getLink('self'),
                vm.data,
                'dashboard.admin.service-routines.list.details.service-routine-lines',
                'dashboard.admin.service-routines.list.details.service-routine-lines',
                'SERVICE_ROUTINE_LINES'
            );
        }

        function resetAction () {
            vm.reorderedServiceRoutineLines = angular.copy(vm.serviceRoutineLines);
            vm.lineHaveChanged = false;
        }

        function orderChanged (event, a, b, c, d, e) {
            vm.lineHaveChanged = true;
        }
    }
})();
