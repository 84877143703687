(function() {
    'use strict';

    angular
        .module('elogbooks')
        .config(Translations);

    function Translations($translateProvider) {
        $translateProvider.translations('nl', {
            UPDATES_AVAILABLE_HEADER: 'Updates Beschikbaar',
            UPDATES_AVAILABLE_BODY: 'Er zijn updates beschikbaar voor Elogs CAFM, uw browser zal zichzelf herladen over 5 minuten om deze toe te passen. U kunt dit uitstellen en doorgaan met uw bezigheden, maar er bestaat dan de mogelijk dat u problemen ondervind.',
            UPDATE_NOW: 'Nu Updaten',
            UPDATE_POSTPONE: 'Update Uitstellen'
        });
    }
})();
