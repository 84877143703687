(function() {
    'use strict';

    angular
        .module('elogbooks.user.finance.sales-invoices')
        .controller('FinanceSalesInvoicesDetailsController', FinanceSalesInvoicesDetailsController);

    FinanceSalesInvoicesDetailsController.$inject = [];
    function FinanceSalesInvoicesDetailsController() {
        var vm = this;
    }
})();
