(function () {
    'use strict';

    angular
        .module('elogbooks.user.finance')
        .config(registerRoutes)

    function registerRoutes($stateProvider) {
        $stateProvider
            .state('dashboard.user.finance', {
                url: '/finance',
                abstract: true,
                template: '<bookerize></bookerize>',
                ncyBreadcrumb: {
                    label: '{{ ::"FINANCE_DASHBOARD" | translate }}'
                }
            });
    }
})();
