/**
 * Entity viewer
 * @description View a read only presentation of the entity, link titles are displayed for links. keys with a colon represent
 *              the type of property (automatically handles links, string, date, number, boolean)
 * @example <elb-entity-view></elb-entity-view>
 * @example <elb-entity-view data-keys="['site', 'speditable', 'rule:rule', 'createdat', 'updatedat']"></elb-entity-view>
 */
angular.module('elogbooksDirectives').directive('elbEntityView', ['$http', 'apiClient', '$state', 'lodash', 'moment', function ($http, apiClient, $state, lodash, moment) {
    return {
        restrict: 'AE',
        replace: true,
        scope: true,
        require: '^^panelEntity',
        templateUrl: '/modules/directives/layout/entity-view/entity-vew.html',
        link: function ($scope, $element, $attrs) {
            var panelScope = $scope.$parent.$parent.$parent.$parent,
                panel = panelScope.panel,
                props = [],
                keysRequired = $attrs.keys ? $scope.$eval($attrs.keys) : [],
                properties = [], // List of properties required
                propertyTypes = []; // List of types if not default

            // Extract out any requested types for the properties to display
            for (var i = 0; i < keysRequired.length; i++) {
                var requirements = keysRequired[i].split(':');
                properties.push(requirements[0]);
                propertyTypes.push(requirements.length === 2 ? requirements[1] : null);
            }

            $scope.form = panelScope.entityForm;
            $scope.entity = panel.entity;
            $scope.responseEntity = panel.responseEntity;
            $scope.panel = panel;
            $scope.noLinks = lodash.has($attrs, 'noLinks') ? true : false;

            lodash.forEach(panel.responseEntity._links, function (value, key) {
                // If the _link has a title, add a link
                if (lodash.has(value, 'title')) {
                    if (!keysRequired.length || properties.indexOf(key.toLowerCase()) > -1) {
                        props.push({
                            type: 'link',
                            property: key.toUpperCase(),
                            label: value.title,
                            value: value.href
                        });
                    }
                }
            }, this);

            lodash.forOwn(panel.responseEntity, function (value, key) {
                // If we have a set of required keys, check them

                var propertyIdx = properties.indexOf(key.toLowerCase()); // is this property required?
                if (!keysRequired.length || propertyIdx > -1) {
                    var prop = {
                        property: key.toUpperCase(),
                        value: value
                    };
                    var type = (keysRequired && propertyTypes[propertyIdx]) ? propertyTypes[propertyIdx] : typeof value;
                    switch (type) {
                        case 'number':
                            props.push(lodash.merge(prop, {
                                type: 'string'
                            }));
                            break;
                        case 'string':
                            // The string may be a date
                            props.push(lodash.merge(prop, {
                                type: (isNaN(value) && moment(value, moment.ISO_8601, true)) ? 'date' : 'string'
                            }));
                            break;
                        case 'boolean':
                            props.push(lodash.merge(prop, {
                                type: 'boolean'
                            }));
                            break;
                        default:
                            // Skip functions, and objects we don't handle
                            if (type !== 'object' && type !== 'function') {
                                props.push(lodash.merge(prop, {
                                    type: type
                                }));
                                break;
                            }
                    }
                }
            }, this);

            $scope.components = props;
        }
    };
}]);
