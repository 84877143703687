(function () {
    'use strict';

    angular
        .module('elogbooks.user.finance.sales-invoices')
        .controller('FinanceSalesInvoicesController', FinanceSalesInvoicesController);

        FinanceSalesInvoicesController.$inject = [
            '$filter',
            '$stateParams',
            'salesInvoicesCollectionResponse',
            '$state',
        ];

        function FinanceSalesInvoicesController(
            $filter,
            $stateParams,
            salesInvoicesCollectionResponse,
            $state
        ) {
            var vm = this;
            vm.siteSelect = {};

            vm.salesInvoicesCollectionResponse = salesInvoicesCollectionResponse;
            vm.salesInvoices = salesInvoicesCollectionResponse.invoices;
            vm.order = orderAction;
            vm.search = searchAction;

            var statusOptions = [
                {
                    title: $filter('translate')('INVOICE_STATUS_DRAFT'),
                    value: 'status_draft'
                }
            ];

            vm.criteria = {
                id: {
                    type: 'id',
                    title: 'ID',
                    value: $stateParams.id
                },
                invoiceDateRange: {
                    type: 'date-range',
                    title: 'INVOICE_DATE_BETWEEN',
                    value: {
                        startDate: ($stateParams.invoiceDateRange !== null && typeof $stateParams.invoiceDateRange === 'string') ?
                            moment(new Date($stateParams.invoiceDateRange.split(',')[0])) :
                            null,
                        endDate: ($stateParams.invoiceDateRange !== null && typeof $stateParams.invoiceDateRange === 'string') ?
                            moment(new Date($stateParams.invoiceDateRange.split(',')[1])) :
                            null
                    },
                    clearValue: {
                        startDate: null,
                        endDate: null
                    }
                },
                status: {
                    type: 'options',
                    title: 'STATUS',
                    multiple: true,
                    value: $stateParams.status,
                    options: statusOptions
                },
                billingSettingsRelation: {
                    type: 'text',
                    title: 'BILLING_SETTINGS_RELATION',
                    value: $stateParams.billingSettingsRelation,
                }
            }

            function orderAction(key) {
                $stateParams.salesInvoiceOrder = key;
                $state.go('.', angular.extend({}, $stateParams), { reload: $state.current });
            }

            function searchAction(params) {
                var override = {
                    salesInvoicePage: 1
                };

                $state.go('.', angular.extend({}, params, override), { reload: $state.current });
            }
        }
})();
