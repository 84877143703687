(function() {
    'use strict';

    angular
        .module('elogbooks')
        .config(Translations);

    function Translations($translateProvider) {
        $translateProvider.translations('zh-hk', {
            FREQUENCY_YEARLY: '{{ ::interval }} 每年',
            FREQUENCY_MONTHLY: '{{ ::interval }} 每月',
            FREQUENCY_WEEKLY: '{{ ::interval }} 每週',

            FREQUENCY_WEEKS: '{{ ::interval }} {{ interval == "1" ? "週" : "週" }}',
            FREQUENCY_DAYS: '{{ ::interval }} {{ interval == "1" ? "日" : "日" }}',
            FREQUENCY_HOURS: '{{ ::interval }} {{ interval == "1" ? "小時" : "小時" }}',
            FREQUENCY_MINUTES: '{{ ::interval }} {{ interval == "1" ? "分鐘" : "分鐘" }}',
            FREQUENCY_SECONDS: '{{ ::interval }} {{ interval == "1" ? "秒" : "秒" }}',
        });
    }
})();
