(function() {
    'use strict';

    angular
        .module('elogbooks')
        .config(Translations);

    function Translations($translateProvider) {
        $translateProvider.translations('zh-hk', {
            JOB_ESCALATED_ACTION: '<strong>{{ ::event._links["user"].title }}</strong> 上報工作.',
            JOB_ESCALATED_SYSTEM_ACTION: '系統自動上報工作.',
            JOB_DEESCALATED_ACTION: '<strong>{{ ::event._links["user"].title }}</strong> 將工作從回報表中移除.',
            JOB_DEESCALATED_SYSTEM_ACTION: '系統自動將工作從回報表中移除.',

            REPORTER_NAME: '回報者名稱',
            REPORTER_EMAIL: '回報者電子郵件',
            REPORTER_PHONE: '回報者電話號碼',
            REPORTER_RELATIONSHIP: '回報者關係',
            REPORTER_NOTIFY_ON_CREATE: '通知回報者已建立',
            REPORTER_NOTIFY_ON_COMPLETE: '通知回報者已完成',

            CONTACT_NAME: '聯繫人名稱',
            CONTACT_EMAIL: '聯繫人電子郵件',
            CONTACT_PHONE: '聯繫人電話號碼',
            CONTACT_RELATIONSHIP: '聯繫人關係',
            CONTACT_NOTIFY_ON_CREATE: '通知聯繫人已建立',
            CONTACT_NOTIFY_ON_COMPLETE: '通知聯繫人已完成',
        });
    }
})();
