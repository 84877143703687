(function () {
    'use strict';

    angular
        .module('elogbooksControllers')
        .controller('advancedTaskFiltersModalController', advancedTaskFiltersModalController);

    advancedTaskFiltersModalController.$inject = ['$state', '$stateParams', 'config', 'modulesService', '$uibModalInstance', '$rootScope', '$filter', '$scope', '$translate', 'lodash', 'moment', 'paramConverterService', 'apiClient', 'rootResource', 'userManager'];

    function advancedTaskFiltersModalController($state, $stateParams, config, modulesService, $uibModalInstance, $rootScope, $filter, $scope, $translate, lodash, moment, paramConverterService, apiClient, rootResource, userManager) {
        var vm = this;
        vm.config = config;
        vm.close = close;
        vm.update = update;
        vm.saveParams = saveParams;

        var statusOptions = [
            { title: $filter('translate')('ALL'), value: 'all' },
            { title: $filter('translate')('STATUS_ACTIVE'), value: 'active' },
            { title: $filter('translate')('STATUS_INACTIVE'), value: 'inactive' }
        ];

        var requestTypeOptions = [
            { title: $filter('translate')('ALL'), value: 'all' },
            { title: $filter('translate')('NEW'), value: 'new' },
            { title: $filter('translate')('EDITED'), value: 'edited' },
        ];

        var masterTaskOptions = [
            {
                title: $filter('translate')('NONE_SELECTED'),
                value: null
            },
            {
                title: $filter('translate')('YES'),
                value: '1'
            },
            {
                title: $filter('translate')('NO'),
                value: '0'
            }
        ];

        var serviceTypeOptions = [
            {
                title: $filter('translate')('NONE_SELECTED'),
                value: null
            },
            {
                title: $filter('translate')('SOFT_SERVICE'),
                value: '0'
            },
            {
                title: $filter('translate')('HARD_SERVICE'),
                value: '1'
            }
        ];

        var frequencyOptions = [
            {
                title: $filter('translate')('NONE_SELECTED'),
                value: null
            },
            {
                title: $filter('translate')('WEEKLY'),
                value: '4'
            },
            {
                title: $filter('translate')('MONTHLY'),
                value: '5'
            },
            {
                title: $filter('translate')('YEARLY'),
                value: '6'
            }
        ];

        var approvalStatusOptions = [
            {
                title: $filter('translate')('NONE_SELECTED'),
                value: null
            },
            {
                title: $filter('translate')('TASK_STATUS_REJECTED'),
                value: '0'
            },
            {
                title: $filter('translate')('TASK_STATUS_DRAFT'),
                value: '1'
            },
            {
                title: $filter('translate')('TASK_STATUS_PENDING_APPROVAL'),
                value: '2'
            },
            {
                title: $filter('translate')('TASK_STATUS_APPROVED'),
                value: '3'
            }
        ];

        vm.advancedCriteria = {
            taskName: { type: 'text', value: $stateParams.taskName, title: 'TASK' },
            taskStatus: { type: 'options', title: 'TASK_STATUS', value: $stateParams.taskStatus, options: statusOptions},
            taskServiceType: { type: 'options', title: 'SERVICE_TYPE', value: $stateParams.taskServiceType, options: serviceTypeOptions },
            taskMasterTask: { type: 'options', title: 'MASTER_TASK', value: $stateParams.taskMasterTask, options: masterTaskOptions },
            taskFrequency: { type: 'options', title: 'FREQUENCY', value: $stateParams.taskFrequency, options: frequencyOptions}
        };

        if ($rootScope.currentState == 'dashboard.user.tasks.generate-planner.awaiting-approval' && modulesService.isEnabled('advanced_ppm_planner')) {
            vm.advancedCriteria.taskRequestType = { type: 'options', title: 'NEW_EDITED', value: $stateParams.taskRequestType, options: requestTypeOptions};
        }
        if ($rootScope.currentState !== 'dashboard.user.tasks.planner-view') {
            vm.advancedCriteria.taskApprovalStatus = { type: 'options', title: 'TASK_APPROVAL_STATUS', value: $stateParams.taskApprovalStatus, options: approvalStatusOptions};
        }

        if (userManager.user.getLink('serviceproviders')) {
            vm.serviceProviderSelect = {
                link : userManager.user.getLink('serviceproviders'),
                responseKeyPath: 'serviceproviders',
                onRemove: vm.saveParams,
                onSelect: vm.saveParams,
                itemValuePath: 'name',
                itemHrefPath: 'id',
                mapValue: 'taskServiceProviderName'
            };

            if ($stateParams.taskServiceProviderName) {
                vm.serviceProviderSelect.selected = {name: $stateParams.taskServiceProviderName, value: $stateParams.taskServiceProviderName};
            }

            vm.advancedCriteria.taskServiceProviderName = { type: 'data_select', title: 'SERVICE_PROVIDER', value: $stateParams.taskServiceProviderName, options: vm.serviceProviderSelect };
        }

        //hides asset field in those states
        if ($rootScope.currentState !== "dashboard.user.assets.manage-assets.list.details.tasks" &&
            $rootScope.currentState !== "dashboard.user.sites.list.details.assets.manage-assets.list.details.tasks") {
            if (userManager.user.getLink('assets')) {
                vm.assetSelect = {
                    link : userManager.user.getLink('assets'),
                    responseKeyPath: 'assets',
                    itemValuePath: 'name',
                    itemHrefPath: 'id',
                    onRemove: vm.saveParams,
                    onSelect: vm.saveParams,
                    mapValue: 'taskAssetName'
                };

                if ($stateParams.taskAssetId) {
                    vm.assetSelect.selected = $stateParams.taskAssetId ? {
                        title: JSON.parse($stateParams.taskAssetId).n,
                        value: JSON.parse($stateParams.taskAssetId).v
                    } : null;
                }

                vm.advancedCriteria.taskAssetId = { type: 'jsonselectwidget', title: 'ASSET', value: $stateParams.taskAssetId, options: vm.assetSelect };
            }
        }

        if (userManager.user.getLink('assets')) {
            vm.assetSelect = {
                link : userManager.user.getLink('assets'),
                responseKeyPath: 'assets',
                itemValuePath: 'id',
                itemTitlePath: 'name',
                onRemove: vm.saveParams,
                onSelect: vm.saveParams
            };

            if ($stateParams.taskAssetId) {
                vm.assetSelect.selected = $stateParams.taskAssetId ? {
                    title: JSON.parse($stateParams.taskAssetId).n,
                    value: JSON.parse($stateParams.taskAssetId).v
                } : null;
            }

            vm.advancedCriteria.taskAssetId = { type: 'jsonselectwidget', title: 'ASSET', value: $stateParams.taskAssetId, options: vm.assetSelect };
        }

        if (rootResource.getLink('regions')) {
            vm.regionSelect = {
                link : rootResource.getLink('regions'),
                responseKeyPath: 'regions',
                itemValuePath: 'id',
                itemTitlePath: 'name',
                onRemove: vm.saveParams,
                onSelect: vm.saveParams
            };

            if ($stateParams.regionId) {
                vm.regionSelect.selected = $stateParams.regionId ? {
                    title: JSON.parse($stateParams.regionId).n,
                    value: JSON.parse($stateParams.regionId).v
                } : null;
            }

            vm.advancedCriteria.regionId = { type: 'jsonselectwidget', title: 'REGION', value: $stateParams.regionId, options: vm.regionSelect };
        }

        if (rootResource.getLink('operatives')) {
            vm.operativeSelect = {
                link : rootResource.getLink('operatives'),
                responseKeyPath: 'operatives',
                itemHrefPath: '_links.user.href',
                itemValuePath: '_links.user.name',
                onRemove: vm.saveParams,
                onSelect: vm.saveParams,
                itemTitlePath: 'name',
                mapValue: 'taskOperativeName'
            };

            if ($stateParams.taskOperativeName) {
                vm.operativeSelect.selected = {name: $stateParams.taskOperativeName, value: $stateParams.taskOperativeName};
            }

            vm.advancedCriteria.taskOperativeName = { type: 'data_select', title: 'OPERATIVE', value: $stateParams.taskOperativeName, options: vm.operativeSelect };
        }

        if (userManager.user.getLink('sites')) {
            vm.siteSelect = {
                link : userManager.user.getLink('sites'),
                responseKeyPath: 'sites',
                itemValuePath: 'name',
                itemHrefPath: 'id',
                onRemove: vm.saveParams,
                onSelect: vm.saveParams,
                mapValue: 'siteIds',
                autocompletemulti: true,
                advanced: true,
                disabled: config.siteLevel || $stateParams.selectedSiteResource
            };

            vm.advancedCriteria.siteIds = { type: 'autocompletemulti', multiple: true, title: 'SITE', value: $stateParams.siteIds, options: vm.siteSelect, param: 'siteIds', max: 50 };
        }

        if (rootResource.getLink('statutorytypes')) {
            vm.statutoryTypesSelect = {
                link: rootResource.getLink('statutorytypes'),
                responseKeyPath: 'statutorytypes',
                itemValuePath: 'name',
                itemHrefPath: 'id',
                onRemove: vm.saveParams,
                onSelect: vm.saveParams,
                mapValue: 'taskStatutoryType',
                autocompletemulti: true,
                advanced: true
            };

            vm.advancedCriteria.taskStatutoryType = { type: 'autocompletemulti', multiple: true, title: 'STATUTORY_TYPE', value: $stateParams.taskStatutoryType, options: vm.statutoryTypesSelect, param: 'taskStatutoryType', max: 50 };
        }

        if (rootResource.getLink('documenttypes')) {
            vm.documentTypeSelect = {
                link : rootResource.getLink('documenttypes'),
                linkParameters: { status: 'active', order: 'name' },
                responseKeyPath: 'documentTypes',
                onRemove: vm.saveParams,
                onSelect: vm.saveParams,
                mapValue: 'documentTypes',
                autocompletemulti: true,
                advanced: true,
                itemValuePath: 'name',
                itemHrefPath: 'id'
            };

            vm.advancedCriteria.documentTypes = {
                type: 'autocompletemulti',
                multiple: true,
                title: 'DOCUMENT_TYPE',
                options: vm.documentTypeSelect,
                param: 'documentTypes',
                value: $stateParams.documentTypes,
                max: 50
            };
        }

        vm.data = {};

        var params = {};

        function saveParams(selectModel) {

            if (selectModel.callback) {
                selectModel.callback(selectModel.selected ? selectModel.selected.object.id : null);
            }

            // mapValue is for data select widget selections
            if (selectModel.mapValue) {
                if (typeof selectModel.autocompletemulti !== 'undefined'
                    && selectModel.autocompletemulti) {
                    params[selectModel.mapValue] = selectModel.selectedItems.map(
                        function (item) {
                            return item.href;
                        }
                    );
                } else {
                    if (selectModel.selected === undefined) {
                        params[selectModel.mapValue] = null;
                    } else {
                        if (selectModel.itemHrefPath === '_links.self.href') {
                            params[selectModel.mapValue] = selectModel.selected.object[selectModel.itemValuePath ? selectModel.itemValuePath : 'id'];
                        } else {
                            params[selectModel.mapValue] = lodash.get(selectModel.selected.object, selectModel.itemValuePath);
                        }
                    }
                }
            } else {
                params = selectModel;
            }
        }

        function close() {
            if (!$scope.form.$pristine) {
                if (!($translate.instant('NAVIGATION_CONFIRMATION'))) {
                    return;
                }
            }
            $uibModalInstance.dismiss();
        }

        function update() {
            if (Object.keys(params).length > 0) {
                params.taskPage = '1';
                params.page = '1';
            }

            $rootScope.skipDirtyCheck = true;
            $state.go('.', angular.extend({}, params), { reload: $state.current.name });
            $uibModalInstance.dismiss();
        }
    }
})();
