app.config(function ($translateProvider) {
    $translateProvider.translations('nl', {
        SITE_NOTE_TYPE_INFORMATION: 'Object notities typeinformatie',

        ADD_NEW_SITE_NOTE_TYPE: 'Maak een nieuw type objectnotitie',
        EDIT_SITE_NOTE_TYPE: 'Wijzig type objectnotitie',

        ALLOW_NOT_APPLICABLE: 'niet van toepassing toestaan',
        ALLOW_HELPDESK: 'Zichtbaar voor Servicedesk',

        NOTE_VISIBILITY: 'Zichtbaarheid',
        VISIBILITY: 'Zichtbaarheid',
        VISIBILITY_PRIVATE: 'Persoonlijk',
        VISIBILITY_TENANT: 'Huurder',
        VISIBILITY_SERVICE_PROVIDER: 'Leverancier',

        SITE_NOTE_TYPE_LIST: 'Type objectnotities',
        SITE_NOTE_TYPE_ADD: 'Voeg een nieuw type objectnotitie toe',
        SITE_NOTE_TYPE_EDIT: 'Wijzig type objectnotitie',

        SITE_NOTE_TYPE_CREATED: 'Type objectnotitie gemaakt',
        SITE_NOTE_TYPE_UPDATED: 'Type objectnotitie aangepast',
        SITE_NOTE_TYPE_DELETED: 'Type objectnotitie verwijderd',

    });
});
