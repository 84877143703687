app.config(function ($translateProvider) {
    $translateProvider.translations('en-gb', {
        SPILLAGE_TYPE: 'Spillage Type',
        SPILLAGE_TYPES: 'Spillage Types',

        ADD_NEW_SPILLAGE_TYPE: 'Add a new Spillage Type',
        EDIT_SPILLAGE_TYPE: 'Edit Spillage Type',

        SPILLAGE_TYPE_LIST: 'Spillage Types',
        SPILLAGE_TYPE_ADD: 'Add a new Spillage Type',
        SPILLAGE_TYPE_EDIT: 'Edit Spillage Type',

        SPILLAGE_TYPE_CREATED: 'Spillage Type Created',
        SPILLAGE_TYPE_UPDATED: 'Spillage Type Updated',
        SPILLAGE_TYPE_DELETED: 'Spillage Type Deleted',
        SPILLAGE_TYPE_ADDED: 'Spillage Type Added',
    });
});
