app.config(function ($translateProvider) {
    $translateProvider.translations('en-gb', {
        SITE: 'Site',
        SITES: 'Sites',

        SITES_LIST: 'Site List',
        SITE_NAME: 'Site Name',
        SITE_REFERENCE: 'Site Reference',

        ADD_NEW_SITE: 'Add a new Site',
        EDIT_SITE: 'Edit Site',
        VIEW_SITE: 'View Site',
        ADD_SITE: 'Add Site',
        CREATED_AT_START: 'Created At Start',
        CREATED_AT_END: 'Created At End',

        ADDRESSES: 'Addresses',
        CITY: 'City',
        COUNTY_STATE_PROVINCE_REGION: 'County/Region',
        POSTAL_CODE_ZIP: 'Post Code',
        COUNTRY: 'Country',
        CONTACT_INFO_TYPE: 'Contact Type',
        CONTACT_ADDRESS_ADDED: 'Contact Address Added',
        CONTACT_ADDRESS_UPDATED: 'Contact Address Updated',
        CONTACT_ADDRESS_DELETED: 'Contact Address Deleted',

        MANAGE_SITES: 'Manage Sites',
        ALL_SITES: 'All Sites',
        SELECTED_SITES: 'Selected Sites',
        CONTACT_INFORMATION: 'Contact Information',
        CONTACT_INFORMATION_ADDRESS: 'Contact Information and Address',
        SITE_CONTACT: 'Site Contact',
        SITE_IMAGE: 'Site Image',

        SITE_MEMBERSHIP: 'Site Membership',
        SITE_MEMBERSHIPS: 'Site Memberships',
        SITE_MEMBERSHIP_LIST: 'Site Membership List',
        SITE_MEMBERSHIP_ADD: 'Add Site Membership',
        SITE_MEMBERSHIP_EDIT: 'Edit Site Membership',
        SITE_MEMBERSHIP_CREATED: 'Site Membership created successfully',
        SITE_MEMBERSHIP_UPDATED: 'Site Membership updated successfully',
        SITE_MEMBERSHIP_DELETED: 'Site Membership deleted',

        IMAGE_DELETED: 'Image deleted',

        REQUIRE_PURCHASE_ORDER: 'Require Purchase Order?',
        EXCLUDE_PCD: 'Exclude from PCD?',
        EXCLUDE_P2P: 'Exclude from P2P?',
        EXCLUDE_FROM_HELPDESK_MONITOR: 'Exclude from Helpdesk Monitor?',
        HELPDESK_MONITOR_EXCLUDED: 'Helpdesk Monitor Excluded',
        HELPDESK_DASHBOARD_EXCLUDED: 'Helpdesk Dasboard Excluded',
        EXCLUDE_FROM_HELPDESK_DASHBOARD: 'Exclude from Helpdesk Dashboard?',
        EXCLUDE_FROM_REQUIRING_DOCUMENT_TYPE: 'Exclude from requiring Document Type?',
        RISKWISE_SYSTEM_TYPE: 'Riskwise System Type',
        SITE_P2P_STARTED_AT_DATE: 'P2P Started At Date',
        AREA: 'Area',
        UNIT: 'Unit',
        SITE_IMAGE_UPDATED: 'Site Image Updated',
        SITE_IMAGE_ADDED: 'Site Image Added',
        SITE_IMAGE_DELETED: 'Site Image Deleted',
        MAIN_SITE_IMAGE_ADDED: 'Main Site Image Added',

        SITE_CONTACTS: 'Site Contacts',
        ADD_NEW_SITE_CONTACT: 'Add Site Contact',
        EDIT_CONTACT: 'Edit Site Contact',

        SITE_MANAGER: 'Site Manager',
        SITE_MANAGERS: 'Site Managers',

        SITE_LIST: 'Sites',
        SITE_ADD: 'Add a new Site',
        SITE_EDIT: 'Edit Site',

        SITE_CREATED: 'Site created successfully',
        SITE_UPDATED: 'Site updated successfully',
        SITE_DELETED: 'Site deleted',

        A_SITE_CONTACT_MUST_HAVE_CONTACT_INFORMATION: 'A Site Contact Must Have Contact Information',
        A_SITE_CONTACT_MUST_HAVE_A_VALID_NAME: 'A Site Contact Must Have A Valid Name',

        ADD_NEW_SITE_NOTE_TYPE: 'Add a new Site Note Type',
        SITE_NOTE_TYPE_TITLE: 'Title',
        ALLOW_REQUIRE_FM: 'Allow this to be marked as requiring FM approval',
        SITE_NOTE_TYPE: 'Site Note Type',
        SITE_NOTE_TYPES: 'Site Note Types',
        NOT_APPLICABLE: 'Not Applicable',
        REQUIRE_FM: 'Requires FM approval',
        SITE_REQUIRES_FM_APPROVAL: 'FM approval is required',
        NO_DATA: 'Data has not yet been entered for this field',
        SITE_CHOOSE_A_USER: 'Choose a User',
        SITE_ENTER_DETAILS_MANUALLY: 'Enter Details Manually',

        SITE_NOTES: 'Notes',
        SITE_SHARED_NOTES: 'Shared Site Notes',
        SITE_ACCESS: 'Site Access',
        SITE_ONE_ADDRESS: 'The site can have only one address',
        SEARCH_SITES_BY_NAME: 'Search sites by name',
        USER_VIEW: 'User View',

        SITE_INFORMATION: 'Site Information',

        ASSOCIATES_UPDATED: 'Site Associates Updated',
        ASSOCIATES: 'Associates',
        EDIT_ADDRESS: 'Edit Address',
        SITE_CONTACT_ADD: 'Add Site Contact',
        SITE_CONTACT_EDIT: 'Edit Site Contact',
        CONTACT_CONTACT_CREATED: 'Site Contact Created',
        CONTACT_CONTACT_UPDATED: 'Site Contact Updated',
        CONTACT_CONTACT_DELETED: 'Site Contact Deleted',
        ADD_LOCATION: 'Add Location',
        EDIT_LOCATION: 'Edit Location',
        SITE_NOTE_UPDATED: 'Site Note Updated',
        SITE_NOTE_CREATED: 'Site Note Created',
        SITE_THRESHOLD_DELETED: 'Site Threshold Deleted',
        SITE_THRESHOLD_VALUE_ADDED: 'Site Threshold Added',
        NO_CONTACT_INFORMATION_ASSIGNED: 'No contact information assigned',
        SITE_STATUS: 'Site Status',
        REFERENCE_PREFIX: 'Reference Prefix',
        SITE_USER_ALREADY_HAS_ASSOCIATION_WITH_SITE: 'User already has association with this site.',
        SITE_USER_HAS_NO_ASSOCIATION_WITH_SITE: 'User has no association with this site.',
        SITE_CANNOT_ADD_FOR_SITES_YOU_ARE_NOT_ASSOCIATED_WITH: 'You cannot add associations for sites you are not associated with.',
        KEYHOLDER: 'Keyholder',
        SITE_NOT_ACTIVE: 'Site not active',
        OCCUPANCY_HEADCOUNT: 'Occupancy Headcount',
        EDIT_OCCUPANCY_HEADCOUNT: 'Edit occupancy Headcount',
        EDIT_SITE_OCCUPANCY_HEADCOUNT: 'edit site\'s occupancy Headcount',
        NO_SITE_PASSED_OR_FOUND: 'Site not found or is not passed',

        SITE_DOCUMENT_COMPLIANCE_CREATED: 'Site Document Compliance Created',
        DUPLICATED_SITE_DOCUMENT_TYPE : 'Document Type(s) Duplicated For This Site',
        OSP_SITE_REFERENCE: 'Occupier Portal Reference',

        MANAGEMENT_TYPE: 'Management Type',

        NOTE_UPDATED_BY: '<strong>Last Updated:</strong> on <span>{{ ::note.updatedAt | date:\'shortDate\' }}</span> by <span>{{ ::note.updatedBy }}</span>',
        NUMBER_OF_SITES: 'No. of Sites',
        NUMBER_OF_LOCATIONS: 'No. of Locations'
    });
});
