(function () {
    'use strict';

    angular
        .module('elogbooks.admin.settings')
        .controller('AdminJobExtraSettingsController', AdminJobExtraSettingsController);

    function AdminJobExtraSettingsController($scope, apiClient, messenger, jobExtraSettings, elbSettings) {
        var vm = this;
            vm.settings = jobExtraSettings;
            vm.jobNotifySiteAssociateType = jobExtraSettings.getLinkAttribute('jobNotifySiteAssociateType','title') || null;
            vm.p2pEnabled = elbSettings.getSetting('p2p_enabled').value || elbSettings.getSetting('hexagon_p2p_enabled').value;
            vm.selectedDocumentType = vm.settings.requireDocumentTypeWhenRaisingReactiveJob;
    }
})();

