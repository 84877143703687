(function() {
    'use strict';

    angular
        .module('elogbooks.user.meter-suppliers')
        .controller('MeterSuppliersInfoController', MeterSuppliersInfoController);

    MeterSuppliersInfoController.$inject = ['$state', 'apiClient', 'confirmationModal', 'messenger', 'meterSupplierResponse'];
    function MeterSuppliersInfoController($state, apiClient, confirmationModal, messenger, meterSupplierResponse) {
        var vm = this;
        vm.meterSupplier = meterSupplierResponse;
    }
})();
