angular.module('elogbooksServices').service('notesModal', ['$uibModal', '$filter', function ($uibModal, $filter) {
    return {
        open: function (config) {
            return $uibModal.open({
                templateUrl: '/modules/helpdesk/jobs/add/modal/notes/notes.html',
                controller: 'NotesModalController',
                controllerAs: 'vm',
                resolve: {
                    config: function () {
                        var defaultConfig = {
                            titleMessage: 'SITE_NOTES',
                            primaryText: 'CONFIRM',
                            primaryClass: 'btn-success',
                            secondaryText: 'CANCEL',
                            secondaryClass: 'btn-default'
                        };

                        return angular.extend({}, defaultConfig, config);
                    }
                }
            });
        }
    };
}]);
