app.config(function ($translateProvider) {
    $translateProvider.translations('en-gb', {
        BUILDING: 'Building &amp;',
        BUILDINGS: 'Buildings',

        ADD_NEW_BUILDING: 'Add a new Building',
        EDIT_BUILDING: 'Edit Building',

        BUILDING_CREATED: 'Building created successfully',
        BUILDING_UPDATED: 'Building updated successfully',
        BUILDING_DELETED: 'Building deleted',

        NO_BUILDING_RECORDS: 'No building records',
    });
});
