(function() {
    'use strict';

    angular
        .module('elogbooks.user.timesheets')
        .controller('UserTimesheetsAddTimesheetController', UserTimesheetsAddTimesheetController);

    UserTimesheetsAddTimesheetController.$inject = [
        '$state',
        'user',
        'timesheetsCollectionResponse',
        'timesheetUserCollectionResponse',
        'userCollectionResponse',
        'userManager',
        'timesheetsService',
        '$scope',
        'requestDataFactory',
        'crudService'
    ];

    function UserTimesheetsAddTimesheetController(
        $state,
        user,
        timesheetsCollectionResponse,
        timesheetUserCollectionResponse,
        userCollectionResponse,
        userManager,
        timesheetsService,
        $scope,
        requestDataFactory,
        crudService
    ) {
        var vm = this;
        vm.user = user;
        vm.userSelectModel  = null;
        vm.timesheetsCollectionResponse = timesheetsCollectionResponse;
        vm.days = null;
        vm.data = requestDataFactory.createRequest(getDefaults());
        vm.create = createAction;

        $scope.$watch('vm.data.startDate', function(newVal) {
            if (newVal) {
                timesheetsService.setTimesheetWeek({
                    startWeekDate: moment(newVal).startOf('week')
                });
                vm.days = timesheetsService.getDays();
                vm.data.startWeekDate = moment(newVal).startOf('week');

                for (var day in vm.days) {
                    vm.days[day].selected = moment(newVal).isSame(moment(vm.days[day].day))
                }
            } else {
                vm.days = null;
            }
        });

        var selected = {
            href: vm.user._links.self,
            title: vm.user.name
        };

        vm.userSelectModel = {
            required: true,
            responseKeyPath: 'users',
            linkParameters: {status: 'active'},
            itemHrefPath: '_links.self.href',
            itemValuePath: 'name',
            isHierarchical: true,
            populateDisabled: true,
            selected: selected,
            onSelect: changeUser,
            onRemove: changeUser
        }

        // If timesheet management permission show all users
        if (userManager.hasPermission('user_permission_manage_timesheets') && userCollectionResponse.count > 0) {
            vm.userSelectModel =  angular.extend({}, vm.userSelectModel, {
                response: userCollectionResponse,
                link: userCollectionResponse.getLink('self'),
            });
        // If not timesheet management permission show only current user or users they are approver for
        } else if(timesheetUserCollectionResponse.count > 0) {
            vm.userSelectModel =  angular.extend({}, vm.userSelectModel, {
                response: timesheetUserCollectionResponse,
                link: timesheetUserCollectionResponse.getLink('self'),
                disabled: timesheetUserCollectionResponse.count === 1
            });
            // No results so default to current user
        } else {
            vm.userSelectModel =  angular.extend({}, vm.userSelectModel, {
                disabled: true
            });
        }

        function changeUser(userSelect) {
            if (userSelect.selected) {
                vm.data._links.user.href = userSelect.selected.href;
            } else {
                vm.data._links.user.href = null;
            }
        }

        function getDefaults() {
            return {
                _links: {
                    user: {
                        href: user._links.self.href
                    }
                },
                startWeekDate: null,
                startDate: null
            }
        }

        function createAction() {
            return crudService.create(
                vm.timesheetsCollectionResponse.getLink('create'),
                vm.data,
                $state.get('^.details'),
                'timesheetsResource',
                'TIMESHEET',
                false
            );
        }

    }
})();
