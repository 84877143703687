(function () {
    'use strict';

    angular
        .module('elogbooks.common.sites')
        .controller('CommonLocationDetailsController', CommonLocationDetailsController);

    CommonLocationDetailsController.$inject = [
        'apiClient',
        'messenger',
        '$state',
        '$stateParams',
        'locationResponse',
        'locationsCollectionResponse',
        'confirmationModal',
        'config',
        'user',
        '$filter'
    ];

    function CommonLocationDetailsController(
        apiClient,
        messenger,
        $state,
        $stateParams,
        locationResponse,
        locationsCollectionResponse,
        confirmationModal,
        config,
        user,
        $filter
    ) {
        var vm = this;
        vm.location = locationResponse;
        vm.locationsCollectionResponse = locationsCollectionResponse;
        vm.address = locationResponse.contactInformation.addresses[0] || null;
        vm.locations = locationsCollectionResponse.locations;
        vm.config = config;
        vm.editLink = ($state.current.name.indexOf('dashboard.user') > -1) ? '^.edit' : '^.^.edit';
        vm.addLink = ($state.current.name.indexOf('dashboard.user') > -1) ? '^.add' : '^.add';
        vm.delete = deleteAction;
        vm.order = order;
        vm.search = search;
        vm.noBorder = config.noBorder;
        vm.user = user;

        var statusOptions = [
            {
                title: $filter('translate')('NONE_SELECTED'),
                value: 'none'
            },
            {
                title: $filter('translate')('STATUS_ACTIVE'),
                value: 'yes'
            },
            {
                title: $filter('translate')('STATUS_INACTIVE'),
                value: 'no'
            }
        ];

        vm.criteria = {
            childLocationActive: {type: 'options', title: 'STATUS', value: $stateParams.childLocationActive, options: statusOptions, clearValue: 'none' },
        };

        function search(params) {
            var override = {
                page: 1
            };

            $state.go('.', angular.extend({}, params, override), {reload: $state.current});
        }

        if (user.permissions) {
            vm.billingTypes = user.permissions.indexOf('user_permission_manage_billing_types') > -1;
            vm.billingCost = user.permissions.indexOf('user_permission_manage_billing_types') > -1;
        } else if (user.permissionsInherited) {
            vm.billingTypes = user.permissionsInherited.indexOf('user_permission_manage_billing_types') > -1;
            vm.billingCost = user.permissionsInherited.indexOf('user_permission_manage_billing_types') > -1;
        }

        function order (key) {
            $stateParams.locationOrder = key;

            $state.go('.', angular.extend({}, $stateParams), { reload: $state.current.name });
        }

        function deleteAction() {
            return confirmationModal.open().result.then(function (response) {
                if (response.result) {
                    return apiClient.delete(vm.location.getLink('self')).then(function (response) {
                        if (response) {
                            $state.go('^', {}, { reload: $state.get('^.^') }).then(function () {
                                messenger.success('LOCATION_DELETED');
                            });
                        } else {
                            messenger.error('REQUEST_ERROR');
                        }
                    });
                }
            });
        }
    }
})();
