(function() {
    'use strict';

    angular
        .module('elogbooks.admin.client-billing')
        .controller('ClientBillingConfigEditSettingsController', ClientBillingConfigEditSettingsController);

    ClientBillingConfigEditSettingsController.$inject = [
        'clientBillingResponse',
        'contractManager',
        'recipientsCollection',
        'usersCollection',
        'apiClient',
        'messenger',
        '$state',
        '$scope',
        'billingTypesCollectionResponse',
        'confirmationModal',
        'clientBillingService'
    ];

    function ClientBillingConfigEditSettingsController(
        clientBillingResponse,
        contractManager,
        recipientsCollection,
        usersCollection,
        apiClient,
        messenger,
        $state,
        $scope,
        billingTypesCollectionResponse,
        confirmationModal,
        clientBillingService
    ) {
        var vm = this;

        vm.clientBilling = clientBillingResponse;
        vm.clientBillingService = clientBillingService;
        vm.recipients = null;
        vm.usersCollection = usersCollection;
        vm.firstReminderOptions = {};
        vm.secondReminderOptions = {};
        vm.recipientsSelect = null;
        vm.buttonText = 'UPDATE';

        var params = {
            'excludeUserIds[]': []
        };

        vm.currentDate = moment(new Date()).add(1, 'days');

        vm.billingTypes = billingTypesCollectionResponse.billingTypes;

        if (recipientsCollection) {
            vm.recipients = recipientsCollection.users;

            vm.recipients = vm.recipients.filter(function( obj ) {
                if (contractManager) {
                    return obj.id !== contractManager.id;
                }

                return true;
            });

            vm.recipientsSelect = {
                linkParameters: params,
                response: vm.usersCollection,
                link: vm.usersCollection.getLink('self'),
                responseKeyPath: 'users',
                searchKey: 'name'
            };

            vm.recipientsSelect.selectedItems = [];

            angular.forEach(vm.recipients, function(item) {
                item.value = item.name;
                item.href = item.getLink('self');

                if (contractManager) {
                    if (item.id !== contractManager.id) {
                        vm.recipientsSelect.selectedItems.push(item);
                    }
                } else {
                    vm.recipientsSelect.selectedItems.push(item);
                }
            });
        }

        vm.create = createAction;

        for (var i = 1; i <= 30; i++) {
            vm.firstReminderOptions[i] = { value: i, title: i }
        }

        $scope.$watch('vm.data.firstReminder', function(value) {
            vm.secondReminderOptions = {};
            for (var i = 1; i < value; i++) {
                vm.secondReminderOptions[i] = { value: i, title: i }
            }

            if (vm.data.firstReminder <= vm.data.secondReminder && vm.data.firstReminder !== 1) {
                vm.data.secondReminder = value - 1;
            }
        });

        vm.data = {
            applyPercentageUplift: vm.clientBilling.upliftPercentage ? true : false,
            applyMinimumContractValue: vm.clientBilling.minimumContractValue ? true : false,
            contractManager: contractManager ? contractManager.name : null,
            firstReminder: vm.clientBilling.firstReminder ? vm.clientBilling.firstReminder : 7,
            secondReminder: vm.clientBilling.secondReminder ? vm.clientBilling.secondReminder : 1,
            upliftPercentage: vm.clientBilling.upliftPercentage ? vm.clientBilling.upliftPercentage : null,
            applyAsOf: vm.clientBilling.applyAsOf ? new Date(vm.clientBilling.applyAsOf) : null,
            upliftReminder: vm.clientBilling.upliftReminder ? vm.clientBilling.upliftReminder : 90,
            minimumContractValue: vm.clientBilling.minimumContractValue ? vm.clientBilling.minimumContractValue : null
        };

        function createAction() {
            vm.data._links = {
                recipients: []
            };

            if (vm.recipientsSelect && vm.recipientsSelect.selectedItems.length) {
                angular.forEach(vm.recipientsSelect.selectedItems, function(item) {
                    vm.data._links.recipients.push({href: item.href});
                });
            }

            if (!vm.data.applyMinimumContractValue) {
                delete vm.data.minimumContractValue;
            }

            if (vm.data.minimumContractValue === null) {
                delete vm.data.applyMinimumContractValue;
            }

            if (vm.data.applyPercentageUplift) {
                return confirmationModal.open(
                    {
                        titleMessage: 'ARE_YOU_SURE',
                        bodyMessage: 'CLIENT_BILLING_CONFIRMATION_MODAL_MESSAGE',
                    }
                ).result.then(function (response) {
                    return apiClient.update(vm.clientBilling.getLink('edit-settings'), vm.data).then(function (response) {
                        if (response) {
                            $state.go('^', {}, { reload: $state.get('^.^') }).then(function () {
                                messenger.success('CLIENT_BILLING_SETTINGS_UPDATED');
                            });
                        } else {
                            messenger.error('REQUEST_ERROR');
                        }
                    });
                });
            } else {
                delete vm.data.upliftPercentage;
                delete vm.data.applyAsOf;
            }

            return apiClient.update(vm.clientBilling.getLink('edit-settings'), vm.data).then(function (response) {
                if (response) {
                    $state.go('^', {}, { reload: $state.get('^.^') }).then(function () {
                        messenger.success('CLIENT_BILLING_SETTINGS_UPDATED');
                    });
                } else {
                    messenger.error('REQUEST_ERROR');
                }
            });
        }

        $scope.$watch('vm.recipientsSelect.selectedItems', function(value) {
            params['excludeUserIds[]'] = [];
            angular.forEach(vm.recipientsSelect.selectedItems, function(recipient) {
                params['excludeUserIds[]'].push(recipient.id);
            });
        });
    }
})();
