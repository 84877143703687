
(function () {
    'use strict'

    angular
        .module('elogbooks.common.conversations')
        .config(registerRoutes);

    function registerRoutes($stateProvider) {
        $stateProvider
            .state('dashboard.conversations', {
                url: '/conversations?page&contains&entity&type',
                params: {
                    page: "1",
                    contains: null,
                    entity: null,
                    type: null
                },
                resolve: {
                    conversationCollectionResponse: function (apiClient, $stateParams, user) {
                        return apiClient.getPage(user._links.conversations.href, $stateParams);
                    },
                    userCollectionResponse: function ($stateParams, apiClient, rootResourceResponse) {
                        return apiClient.getPage(rootResourceResponse.getLink('users'), {limit:10});
                    }
                },
                templateUrl: '/modules/common/conversations/conversations.html',
                controller: 'ConversationsController',
                controllerAs: 'vm'
            })

            .state('dashboard.conversations.detail', {
                url: '/{resource}',
                resolve: {
                    conversationResponse: function (apiClient, $stateParams) {
                        return apiClient.get($stateParams.resource.decode());
                    }
                },
                templateUrl: '/modules/common/conversations/messages.html',
                controller: 'ConversationsMessagesController',
                controllerAs: 'vm'
            })

            .state('dashboard.conversations.compose', {
                url: '/compose',
                parent: 'dashboard.conversations',
                templateUrl: '/modules/common/conversations/compose.html',
                controller: 'ConversationsComposeController',
                controllerAs: 'vm'
            });
    };

})();
