(function () {
    'use strict';

    angular
        .module('elogbooks.user.bulk-actions')
        .controller('BulkActionDetailsController', ['$state', '$stateParams', '$filter', 'bulkActionResponse','bulkActionElementsCollectionResponse', BulkActionDetailsController]);

    BulkActionDetailsController.$inject = ['$state', '$stateParams', '$filter', 'bulkActionResponse','bulkActionElementsCollectionResponse'];

    function BulkActionDetailsController($state, $stateParams, $filter, bulkActionResponse, bulkActionElementsCollectionResponse) {
        var vm = this;
        vm.bulkAction = bulkActionResponse;
        vm.bulkActionElementsCollection = bulkActionElementsCollectionResponse;
        vm.bulkActions = bulkActionElementsCollectionResponse.bulkActionJobs ? bulkActionElementsCollectionResponse.bulkActionJobs : bulkActionElementsCollectionResponse.bulkActionActions;
        vm.pages = bulkActionElementsCollectionResponse.pages;
        vm.noBorder = false;
        vm.bulkActionType = bulkActionElementsCollectionResponse.bulkActionJobs ? 'jobLogs' : 'actionLogs'
        vm.search = search;

        var typeOptions = [
            { title: $filter('translate')('NONE_SELECTED'), value: null},
            { title: $filter('translate')('BULK_ACTION_LOG_CHANGE'), value: 'change' },
            { title: $filter('translate')('BULK_ACTION_LOG_ERROR'), value: 'error' },
            { title: $filter('translate')('BULK_ACTION_LOG_NO_CHANGE'), value: 'no_change' }
        ];

        vm.criteria = {
            logType: { type: 'options', title: 'BULK_ACTION_LOG_TYPE', value: $stateParams.logType, options: typeOptions}
        };

        /**
         * Search logs
         */
        function search(params) {
            var override = {
                page: 1
            };

            $state.go('.', angular.extend({}, params, override), { reload: $state.current });
        }
    }
})();
