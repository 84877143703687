angular.module('elogbooksDirectives').directive('elogbooksAuditSectionPath', ['lodash', function(lodash){
    return {
        restrict: 'AE',
        require: ['ngModel'],
        scope: {
            model: '=ngModel',
            route: '@',
            routeParams: '@',
            linkLast: '@'
        },
        templateUrl: '/modules/directives/audit-section-path/audit-section-path.html',
        controller: function ($scope, apiClient, $attrs) {
            $scope.getParams = getParams;
            if ($scope.model && typeof $scope.model.path === 'undefined') {
                var url = false;

                if (typeof $scope.model._links !== 'undefined') {
                    url = $scope.model._links.self.href;
                } else if (typeof $scope.model.href !== 'undefined') {
                    url = $scope.model.href;
                }

                if (url) {
                    apiClient.get(url).then(function (response) {
                        $scope.model = response;
                    });
                }
            }

            $scope.skipLast = (lodash.has($attrs, 'skipLast') ? true : false);
            $scope.linkAll = (lodash.has($attrs, 'linkAll') ? true : false);

            if ($scope.skipLast) {
             $scope.model.path.pop();
            }

            function getParams(section) {
                return angular.extend({}, angular.fromJson($scope.routeParams), { sectionResource: section._links.self.href.encode()});
            }
        }
    };
}]);
