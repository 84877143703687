(function() {
    'use strict';

    angular
        .module('elogbooks')
        .config(Translations);

    function Translations($translateProvider) {
        $translateProvider.translations('en-gb', {
            MERIDIAN_DOC_TYPE: 'Meridian Document Type',
            RISKWISE_DOC_TYPE: 'RiskWise Document Type',
            QCOMPLIANCE_DOC_TYPE: 'Q Compliance Document Type',
            FILE_SEND_TO_MERIDIAN_SUCCESS: 'The file has been sent to Meridian successfully',

            INSPECTIONDATE: 'Inspection Date',
            NEXTINSPECTIONDATE: 'Next Inspection Date',

            UPLOAD_TO_RISKWISE: 'Send to RiskWise',
            RISKWISE_UPLOAD_ADD: 'Send to RiskWise',
            PRIMARY: 'Primary',
            SECONDARY: 'Secondary',

            INVALID_RISKWISE_SYSTEM_TYPE: 'Please select a valid Riskwise System Type',
            UPLOAD_TO_OSP: 'Send to Occupier Portal',
            INTEGRATION_SEND_TO: 'Send to',

            RISKWISE_UPLOAD_PENDING: 'Riskwise upload pending',
            RISKWISE_UPLOADED: 'Uploaded to riskwise',
            RISKWISE_UPLOAD_ERROR: 'Riskwise upload error',

            OSP_ERROR: 'OSP is having issues. Please try again.',
            OSP_BUILDING_STAFF_OCCUPIERS: 'Staff + Occupier(s)',
            OSP_DOCUMENT_WHO_CAN_EDIT: 'Who can Edit',
            OSP_BUILDING_MANAGEMENT_AND_OCCUPIERS: 'Staff And Occupiers',
            OSP_FILE_ADDED_TO_QUEUE: 'File is queued for sending to Occupier Portal',
            OSP_NO_OCCUPIERS_SELECTED: 'No occupiers selected',
            OSP_DOCUMENT_NOT_SELECTED: 'Document is not selected',
            OSP_SEND_AS_NEW_DOCUMENT: 'Send As New Document',
            OSP_UPDATE_EXISTING_DOCUMENT: 'Update Existing Document',
            OSP_SEND_METHOD: 'Method',
            OSP_SELECT_DOCUMENT: 'Select Document',

            OSP_UPLOAD_PENDING: 'Pending Upload to Occupier Portal',
            OSP_UPLOADED: 'Uploaded to Occupier Portal',
            OSP_UPLOAD_ERROR: 'Occupier portal upload error',
            OSP_DOCUMENTS_NOT_FOUND: 'No documents found matching these filters',
            OSP_SEARCH_DOCUMENTS: 'Search Documents',
            OSP_DOCUMENT_EXPIRES_BEFORE: 'Expires Before',
            OSP_DOCUMENT_EXPIRES_AFTER: 'Expires After',
            OSP_OCCUPIER: 'Occupier',

            MERIDIAN_UPLOAD_PENDING: 'Meridian Upload Pending',
            MERIDIAN_UPLOADED: 'Meridian Upload Successful',
            MERIDIAN_UPLOAD_ERROR: 'Meridian Upload Failed',
            MERIDIAN_SYSTEM_LINK: 'Meridian System Link'
        });
    }
})();
