;(function() {
    'use strict';

    angular
        .module('elogbooks.common.jobs.workflow')
        .controller('JobValueActionController', JobValueActionController);

    JobValueActionController.$inject = ['$state', 'messenger', 'crudService', 'jobResponse', 'config'];

    function JobValueActionController($state, messenger, crudService, jobResponse, config) {
        var vm = this;
        vm.jobResponse = jobResponse;
        vm.data = {note: null, separateInvoice: false};
        vm.config = config;
        vm.closed = true;
        vm.totalValue = vm.jobResponse.getLinkAttribute('value-approve', 'valueLabour') + vm.jobResponse.getLinkAttribute('value-approve', 'valueMaterial');

        vm.submit = submitAction;

        function submitAction() {
            return crudService
                .create(
                    jobResponse.getLink('value-' + config.type),
                    vm.data,
                    null,
                    null,
                    null,
                    true
                ).then(function() {
                    messenger.success('JOB_VALUE_' + config.type.toUpperCase());
                    $state.go($state.get('^.^.^'), {}, {reload: $state.get('^.^.^.^')});
                })
        }
    }
})();
