app.config(function ($translateProvider) {
    $translateProvider.translations('nl', {
        IMPORT: 'Import',
        IMPORT_TYPE: 'Type',
        IMPORTS_LIST: 'Imports',
        IMPORT_DETAILS_LIST: 'Details',
        IMPORT_IS_TEST_RUN: 'Test run?',
        IMPORT_STARTED: 'Started at',
        IMPORT_ENDED: 'Ended at',
        IMPORT_TOTAL_ROWS: 'Total',
        IMPORT_VALID_ROWS: 'Valid',
        IMPORT_INVALID_ROWS: 'Invalid',
        IMPORT_TEST_RUN: 'Debug',
        IMPORTS: 'Imports',
        IMPORT_CSV_FILE: 'Import file (csv)',
        IMPORT_FILE: 'Import file',
        EXTENSION_XML: '.xml',
        EXTENSION_CSV: '.csv',
        IMPORT_ERRORS: 'Errors',
        IMPORT_ROW_NUMBER: 'Row',
        IMPORT_COLUMN_NAME: 'Key',
        IMPORT_LOGS: 'Import Logs',
        IMPORT_LOG_TYPE: 'Type',
        IMPORT_LOG_ALL: 'All',
        IMPORT_LOG_ERROR: 'Error',
        IMPORT_LOG_CHANGE: 'Change',
        IMPORT_LOG_NO_CHANGE: 'No Change',
        IMPORT_ERROR_MESSAGE: 'Error message',
        IMPORT_MESSAGE: 'Message',
        IMPORT_GET_TEMPLATE: 'Get Template',
        IMPORTS_NO_RESULTS: 'No results',
        USER_TASK: 'Task (user)',
        USER_ASSET: 'Asset (user)',
        USER_ASSET_TASK: 'Asset/Task (user)',
        USER_ASSET_TASK_WARNING: 'Please be aware that because you have the Manage Imports permission, the import is not limited to your site access.',
        COMMA_SEPARATED: '(comma separated)',

        SPREADSHEET_TITLE: 'Spreadsheet Title',
        VIEW_LEGEND: 'View Legend',
        DATA_REQUIREMENTS: 'Data requirements',

        IMPORT_USERS: 'Import Users',
        IMPORT_USERS_ADD: 'Import Users',
        IMPORT_ADD: 'New Import',
        IMPORT_LEGEND: 'Legend',

        DOWNLOAD_IMPORT_TEMPLATE: 'Download {{ entity }} import template',
        IMPORT_ERROR: 'Import Error',
        IMPORT_QUEUED: 'Import Queued',
        SITE_TENANT: 'Site Tenant',
        SITE_NOTE: 'Site Notes',

        PCD_IMPORTS: 'PCD Import',
        PCD_LIST: 'PCD Imports List',
        PCD_IMPORT_ADD: 'New PCD Import',
        PCD_IMPORT_CREATE_SITES: 'Create Sites',
        PCD_IMPORT_CREATE_SITE_GROUPS: 'Create Site Groups',
        PCD_IMPORT_CREATE_SECTORS: 'Create Sectors',
        PCD_IMPORT_CREATE_REGIONS: 'Create Regions',
        PCD_IMPORT_CREATE_USERS: 'Create Users',
        PCD_IMPORT_CREATE_CORE_CLIENTS: 'Create Core Clients',
        PCD_IMPORT_UPDATE_SITE_CONTACTS: 'Update Site Contacts',
        PCD_IMPORT_UPDATE_SITE_ACCESS: 'Update Site Access',
        PCD_IMPORT_UPDATE_SITE: 'Update Site',
        PCD_IMPORT_UPDATE_SITE_GROUP: 'Update Site Group',

        NO_PCD_IMPORT_FILE_ADDED: 'No PCD Import File Added',
        DOWNOLAD_ASSET_TYPES : 'Download Asset Types',
        DOWNOLAD_SERVICE_ROUTINES: 'Download Service Routines',
        DOWNOLAD_STATUTORY_TYPES: 'Download Statutory Types',

        CREATE_OPTIONS: 'Create Options',
        UPDATE_OPTIONS: 'Update Options',
        PCD: 'PCD',
        PCD_DISABLED_FOR_CLIENT: 'PCD Imports are not enabled'
    });
});
