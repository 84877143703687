/**
 * Service Provider Controller
 */
(function () {
    'use strict';

    angular
        .module('elogbooks.user.service-providers')
        .controller('ManageServiceProvidersListController', ManageServiceProvidersListController);

    ManageServiceProvidersListController.$inject = ['tabConfig', 'userManager'];

    function ManageServiceProvidersListController(tabConfig, userManager) {
        var vm = this;

        vm.config = tabConfig;
        vm.canSeeAllServiceProviders = userManager.hasPermission('site_permission_view_all_service_providers');
    }
})();
