(function () {
    'use strict';

    angular
        .module('elogbooks.admin.document-types-management')
        .controller('DocumentTypesListController', DocumentTypesListController);

    DocumentTypesListController.$inject = ['$state', '$stateParams', '$filter', 'documentTypeCollectionResponse'];

    function DocumentTypesListController($state, $stateParams, $filter, documentTypeCollectionResponse) {
        var vm = this;

        vm.documentTypes = documentTypeCollectionResponse.documentTypes;
        vm.documentTypeCollectionResponse = documentTypeCollectionResponse;

        vm.order = order;
        vm.search = search;

        var statuses = [
            {
                title: $filter('translate')('STATUS_ACTIVE'),
                value: 'active'
            },
            {
                title: $filter('translate')('STATUS_INACTIVE'),
                value: 'inactive'
            },
            {
                title: $filter('translate')('NONE_SELECTED'),
                value: null
            }
        ];

        var criticalityOptions = [
            { value: 'high', title: $filter('translate')('CRITICALITY_HIGH') },
            { value: 'medium', title: $filter('translate')('CRITICALITY_MEDIUM') },
            { value: 'low', title: $filter('translate')('CRITICALITY_LOW') },
        ];

        vm.criteria = {
            name: { type: 'text', value: $stateParams.name, title: 'NAME' },
            criticality: { type: 'options', multiple: true, title: 'CRITICALITY', value: $stateParams.criticality, options: criticalityOptions },
            status: { type: 'options', title: 'IS_ACTIVE', value: $stateParams.status, options: statuses }
        };

        function search(params) {
            var override = {
                page: 1
            };

            $state.go('.', angular.extend({}, params, override), {reload: $state.current});
        };

        function order(key) {
            $stateParams.order = key;

            $state.go('.', angular.extend({}, $stateParams), {reload: $state.current});
        };
    }
})();
