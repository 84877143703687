angular.module('elogbooksServices').service('jobStatuses', function(lodash, $translate) {

    var statuses = [
        'created',
        'approval_pending',
        'approval_rejected',
        'assignment_pending',
        'assignment_proposal',
        'assignment_rejected',
        'operative_assignment_rejected',
        'acknowledged',
        'commenced',
        'missed',
        'cancelled',
        'completed',
        'awaiting_paperwork',
        'paperwork_rejected',
        'extension_pending'
    ];

    var finalStatuses = [
        'approval_rejected',
        'missed',
        'cancelled',
        'completed',
    ];

    var nonFinalStatuses = [
        'created',
        'approval_pending',
        'assignment_pending',
        'assignment_proposal',
        'assignment_rejected',
        'operative_assignment_rejected',
        'acknowledged',
        'commenced',
        'awaiting_paperwork',
        'paperwork_rejected',
        'extension_pending'
    ];

    var approvalStatuses = [
        'approval_pending',
        'assignment_proposal',
        'assignment_rejected',
    ];

    var overdueAlarmStatuses = [
        'acknowledged',
        'commenced',
        'awaiting_paperwork',
        'paperwork_rejected',
        'extension_pending'
    ];

    var performanceStatisticStatuses = [
        'assignment_pending',
        'acknowledged',
        'commenced',
        'awaiting_paperwork',
        'extension_pending',
        'completed',
    ];

    var noPoRequiredStatuses = ['approval_pending', 'cancelled', 'approval_rejected'];

    this.getAll = function (statusType) {
        switch (statusType) {
            case 'final':
                return finalStatuses;
            case 'nonfinal':
                return nonFinalStatuses;
            case 'approval':
                return approvalStatuses;
            case 'overduealarm':
                return overdueAlarmStatuses;
            case 'performancestatistic':
                return performanceStatisticStatuses;
            case 'noPoRequired':
                return noPoRequiredStatuses;
            default:
                return statuses;
        }
    };

    this.getAllTranslated = function (statusType) {
        var _statuses = [];
        var statusesToLoop;

        switch (statusType) {
            case 'final':
                statusesToLoop = finalStatuses;
                break;
            case 'nonfinal':
                statusesToLoop = nonFinalStatuses;
                break;
            case 'approval':
                statusesToLoop = approvalStatuses;
                break;
            case 'overduealarm':
                statusesToLoop = overdueAlarmStatuses;
                break;
            case 'performancestatistic':
                statusesToLoop = performanceStatisticStatuses;
                break;
            default:
                statusesToLoop = statuses;
        }

        angular.forEach(statusesToLoop, function (value, index) {
            _statuses.push({
                value: value,
                title: $translate.instant('JOB_STATUS_' + value.toUpperCase())
            });

        });

        return _statuses;
    };

    this.getAllAsObjects = function () {
        var list = [];
        lodash.each(statuses, function (permission) {
            list.push({
                name: permission
            });
        });
        return list;
    };

    return this;
});
