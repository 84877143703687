(function () {
    'use strict';

    angular
        .module('elogbooks.admin.service-provider-groups')
        .controller('ServiceProviderGroupsDetailsController', ServiceProviderGroupsDetailsController);

    ServiceProviderGroupsDetailsController.$inject = ['serviceProviderGroupsResponse'];

    function ServiceProviderGroupsDetailsController (serviceProviderGroupsResponse) {
        var vm = this;
        vm.group = serviceProviderGroupsResponse;
    }
})();
