(function () {
    'use strict';

    angular
        .module('elogbooks.admin.imports')
        .controller('PcdImportDetailsController', ['$state', '$stateParams', '$filter', 'importResponse','importLogsCollectionResponse', '$translate', 'elbDownloadService', PcdImportDetailsController]);

    PcdImportDetailsController.$inject = ['$state', '$stateParams', '$filter', 'importResponse','importLogsCollectionResponse', '$translate', 'elbDownloadService'];

    function PcdImportDetailsController($state, $stateParams, $filter, importResponse, importLogsCollectionResponse, $translate, elbDownloadService) {
        var vm = this;
        vm.import = importResponse;
        vm.importLogsCollection = importLogsCollectionResponse;
        vm.importLogs = importLogsCollectionResponse.importLogs;
        vm.importLogs = importLogsCollectionResponse.importLogs;
        vm.noBorder = false;

        var typeOptions = [
            { title: $filter('translate')('IMPORT_LOG_ALL'), value: null},
            { title: $filter('translate')('IMPORT_LOG_ERROR'), value: 'error' },
            { title: $filter('translate')('IMPORT_LOG_WARN'), value: 'warn' },
            { title: $filter('translate')('IMPORT_LOG_CHANGE'), value: 'change' },
            { title: $filter('translate')('IMPORT_LOG_NO_CHANGE'), value: 'no_change' }
        ];

        var createOptions = [
            {
                value: 'create_sites',
                title: $translate.instant('PCD_IMPORT_CREATE_SITES')
            },
            {
                value: 'create_site_groups',
                title: $translate.instant('PCD_IMPORT_CREATE_SITE_GROUPS')
            },
            {
                value: 'create_sectors',
                title: $translate.instant('PCD_IMPORT_CREATE_SECTORS')
            },
            {
                value: 'create_regions',
                title: $translate.instant('PCD_IMPORT_CREATE_REGIONS')
            },
            {
                value: 'create_users',
                title: $translate.instant('PCD_IMPORT_CREATE_USERS')
            }
        ];

        var updateOptions = [
            {
                value: 'update_sites',
                title: $translate.instant('PCD_IMPORT_UPDATE_SITE')
            },
            {
                value: 'update_site_contacts',
                title: $translate.instant('PCD_IMPORT_UPDATE_SITE_CONTACTS')
            },
            {
                value: 'update_site_group',
                title: $translate.instant('PCD_IMPORT_UPDATE_SITE_GROUP')
            }
        ];

        vm.criteria = {
            logsRow: { type: 'number', title: 'ID', value: $stateParams.logsRow ? parseInt($stateParams.logsRow, 10) : null },
            logsType: { type: 'options', title: 'IMPORT_LOG_TYPE', value: $stateParams.logsType, options: typeOptions},
            logsCreateOptions: { type: 'options', title: 'CREATE_OPTIONS', multiple: true, value: $stateParams.logsCreateOptions, options: createOptions },
            logsUpdateOptions: { type: 'options', title: 'UPDATE_OPTIONS', multiple: true, value: $stateParams.logsUpdateOptions, options: updateOptions },
            logsMessage: { type: 'text', title: 'IMPORT_MESSAGE', value: $stateParams.logsMessage}
        };

        vm.search = search;
        vm.download = download;

        /**
         * Search tasks
         */
        function search(params) {
            var override = {
                logsPage: 1
            };

            $state.go('.', angular.extend({}, params, override), { reload: $state.current });
        }

        function download(file) {
            elbDownloadService.download(file);
        }
    }
})();
