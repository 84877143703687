(function() {
    'use strict';

    angular
        .module('elogbooks.user.finance.credit-notes')
        .controller('CreditNoteAddEditController', CreditNoteAddEditController);

    CreditNoteAddEditController.$inject = [
        'lodash',
        'apiClient',
        'creditNotesCollectionResponse',
        'creditNoteResponse',
        'rootResourceResponse',
        'requestDataFactory',
        'crudService',
        '$state'
    ];

    function CreditNoteAddEditController(
        lodash,
        apiClient,
        creditNotesCollectionResponse,
        creditNoteResponse,
        rootResourceResponse,
        requestDataFactory,
        crudService,
        $state
    ) {
        var vm = this;
        vm.creditNotesCollectionResponse = creditNotesCollectionResponse;
        vm.creditNoteResponse = creditNoteResponse;
        vm.creditNote =  vm.creditNoteResponse;
        vm.data = requestDataFactory.createRequest(getDefaults());

        vm.create = createAction;
        vm.update = updateAction;
        vm.cancel = cancelAction;
        vm.validateInvoiceAction = validateInvoiceAction;
        vm.validateInvoice = undefined;
        vm.loading = false;
        vm.salesInvoiceResponse  = undefined;

        fetchInvoiceDetails(vm.data.invoiceId);

        function validateInvoiceAction() {
            clearTimeout(vm.validateInvoice);

            vm.loading = !!vm.data.invoiceId.length;
            vm.salesInvoiceResponse = undefined;

                vm.validateInvoice = setTimeout(function() {
                    if (vm.data.invoiceId.length > 0 && !isNaN(vm.data.invoiceId) && vm.data.invoiceId >= 0) {
                        fetchInvoiceDetails(vm.data.invoiceId);
                    } else {
                        vm.loading = false;
                        vm.data._links.salesinvoice = undefined;
                    }
                }, 1000);
        }

        function fetchInvoiceDetails(invoiceId) {

            if (!invoiceId) {
                return;
            }

            vm.loading = true;
            vm.salesInvoiceResponse = undefined;

            const params = {
                limit: 1,
                id: vm.data.invoiceId,
                type: 'sales'
            };

            apiClient.get(rootResourceResponse.getLink('sales-invoices'), params).then(function (salesInvoiceResponse) {
                if (salesInvoiceResponse && salesInvoiceResponse.invoices.length) {
                    var salesInvoice = lodash.filter(salesInvoiceResponse.invoices, { id: parseInt(vm.data.invoiceId) });
                    if (salesInvoice.length) {
                        vm.salesInvoiceResponse = salesInvoice[0];
                        vm.data._links.salesinvoice = vm.salesInvoiceResponse.getLink('self');
                    }
                } else {
                    vm.data._links.salesinvoice = undefined;
                }

                vm.loading = false;
            });
        }

        function getDefaults() {
            return {
                id: vm.creditNote.id || null,
                _links: {
                    salesinvoice: vm.creditNote.invoiceId ?  vm.creditNote._links.self.href : null
                },
                invoiceId: vm.creditNote.invoiceId || null,
                amount: vm.creditNote.amount || null,
                summary: vm.creditNote.summary || null,

            }
        }

        function createAction() {
            return crudService.create(
                vm.creditNotesCollectionResponse.getLink('create'),
                vm.data,
                'dashboard.user.finance.credit-notes.list.details',
                'creditNoteResource',
                'CREDIT_NOTE'
            );
        }

        function updateAction() {
            return crudService.update(
                vm.creditNote.getLink('edit'),
                vm.data,
                'dashboard.user.finance.credit-notes.list.details',
                'dashboard.user.finance.credit-notes.list',
                'CREDIT_NOTE',
                false
            );
        }

        function cancelAction() {
            var cancelRoute = $state.get('^');

            if (vm.data.id) {
                cancelRoute = 'dashboard.user.finance.credit-notes.list.details'
            }

            $state.go(cancelRoute, {});
        }
    }
})();
