(function() {
    'use strict';

    angular
        .module('elogbooks.user.service-providers')
        .controller('UserServiceProviderPartnershipsController', UserServiceProviderPartnershipsController);

    UserServiceProviderPartnershipsController.$inject = ['$stateParams', '$scope', '$state', 'lodash', 'partnershipsResponse', '$translate', '$uibModal', 'base64', 'messenger', 'apiClient', '$location'];

    function UserServiceProviderPartnershipsController($stateParams, $scope, $state, lodash, partnershipsResponse, $translate, $uibModal, base64, messenger, apiClient, $location) {
        var vm = this;
        vm.partnerships = partnershipsResponse.partnerships;
        vm.partnershipsResponse = partnershipsResponse;
        vm.partnerships._links = partnershipsResponse._links;
        vm.entityCount = partnershipsResponse.count;
        vm.partnerships.canCreate = lodash.has(partnershipsResponse, '_links.createServiceProviderPartnership');
        vm.search = search;
        vm.order = order;
        vm.openShareModal = openShareModalAction;

        var partnershipStatusOptions = [
            {title: $translate.instant('ALL'), value: null},
            {title: $translate.instant('STATUS_ACTIVE'), value: '1'},
            {title: $translate.instant('STATUS_INACTIVE'), value: '0'}
        ];

        var siteStatusOptions = [
            {title: $translate.instant('NONE_SELECTED'), value: null},
            {title: $translate.instant('STATUS_ACTIVE'), value: 'active'},
            {title: $translate.instant('STATUS_INACTIVE'), value: 'inactive'}
        ];

        if ($stateParams.successShareSetup) {
            // todo make sure to put unique index on url and token on base webhook
            if ($stateParams.successShareSetup === 'false') {
                messenger.error('SHARE_SETUP_ERROR');
            } else {
                vm.payload = JSON.parse(base64.decode($stateParams.successShareSetup));

                vm.requestData = {
                    headers: vm.payload.headers,
                    url: vm.payload.url,
                    active: 1,
                    activeShare: 1,
                    isParentSystem: true,
                };

                apiClient.create(vm.payload.partnershipWebhooks, vm.requestData).then(function (response) {
                    if (response) {
                        messenger.success('SHARE_SETUP_SUCCESS');
                    } else {
                        messenger.error('REQUEST_ERROR');
                    }
                });
            }

            $scope.$on('$destroy', function () {
                $location.search('successShareSetup', null);
            });
        }

        vm.criteria = {
            site: {type: 'text', title: 'SITE_NAME', value: $stateParams.site},
            siteStatus: {
                type: 'options',
                title: 'SITE_STATUS',
                value: $stateParams.siteStatus,
                options: siteStatusOptions,
                clearValue: null
            },
            partnershipActive: {
                type: 'options',
                title: 'PARTNERSHIP_STATUS',
                value: $stateParams.partnershipActive,
                options: partnershipStatusOptions,
                clearValue: null
            }
        };

        function search(params) {
            var override = {
                partnershipPage: 1
            };

            $state.go('.', angular.extend({}, params, override), {reload: true});
        }

        function order(key) {
            $stateParams.partnershipOrder = key;

            var override = {
                partnershipPage: 1
            };

            $state.go('.', angular.extend({}, $stateParams, override), {reload: $state.current.name});
        }

        function openShareModalAction(partnership) {
            $location.search('successShareSetup', null);
            $uibModal.open({
                templateUrl: '/modules/common/modals/sharing-setup/sharing-setup-modal.html',
                controller: 'SharingSetupModalController',
                controllerAs: 'vm',
                resolve: {
                    config: function () {
                        var defaultConfig = {
                            titleMessage: 'ARE_YOU_SURE_HEADER',
                            bodyMessage: 'ARE_YOU_SURE',
                            primaryText: 'YES',
                            primaryClass: 'btn-danger',
                            secondaryText: 'NO',
                            secondaryClass: 'btn-default'
                        };

                        return angular.extend({}, defaultConfig);
                    },
                    instanceResponse: function() {
                        return partnership;
                    }
                }
            });
        }
    }
})();
