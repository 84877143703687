(function() {
    'use strict';

    angular
        .module('elogbooks')
        .config(Translations);

    function Translations($translateProvider) {
        $translateProvider.translations('zh-hk', {

            ESCALATE_OPERATION_SUCCESS: '已上報',
            ESCALATION_PROGRESS_SUCCESS: '上報已進行',
            ESCALATION_POSTPONE_SUCCESS: '上報已延遲',
            ESCALATION_CANCEL_SUCCESS: '上報已取消',

            ESCALATION_WORKFLOW_PROGRESS: '進行',
            ESCALATION_WORKFLOW_PROGRESS_SUBMIT: '進行',
            ESCALATION_WORKFLOW_CANCEL: '取消',
            ESCALATION_WORKFLOW_CANCEL_SUBMIT: '取消上報',
            ESCALATION_WORKFLOW_POSTPONE: '延遲',
            ESCALATION_WORKFLOW_POSTPONE_SUBMIT: '延遲',
        });
    }
})();
