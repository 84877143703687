(function() {
    'use strict';

    angular
        .module('elogbooks')
        .config(Translations);

    function Translations($translateProvider) {
        $translateProvider.translations('en-gb', {
            ESCALATION_CREATED_ACTION: '<strong>{{ ::event._links["user"].title }}</strong> raised the escalation.',

            ESCALATION_JOB_ADDED_ACTION: '<strong>{{ ::event._links["user"].title }}</strong> escalated a job.',
            ESCALATION_JOB_ADDED_SYSTEM_ACTION: 'The system automatically escalated a job.',
            ESCALATION_JOB_READDED_ACTION: '<strong>{{ ::event._links["user"].title }}</strong> re-escalated a job.',
            ESCALATION_JOB_READDED_SYSTEM_ACTION: 'The system automatically re-escalated a job.',
            ESCALATION_JOB_REMOVED_ACTION: '<strong>{{ ::event._links["user"].title }}</strong> removed a job.',
            ESCALATION_JOB_REMOVED_SYSTEM_ACTION: 'The system automatically removed a job.',

            ESCALATION_QUOTE_ADDED_ACTION: '<strong>{{ ::event._links["user"].title }}</strong> escalated a quote.',
            ESCALATION_QUOTE_ADDED_SYSTEM_ACTION: 'The system automatically escalated a quote.',
            ESCALATION_QUOTE_READDED_ACTION: '<strong>{{ ::event._links["user"].title }}</strong> re-escalated a quote.',
            ESCALATION_QUOTE_READDED_SYSTEM_ACTION: 'The system automatically re-escalated a quote.',
            ESCALATION_QUOTE_REMOVED_ACTION: '<strong>{{ ::event._links["user"].title }}</strong> removed a quote.',
            ESCALATION_QUOTE_REMOVED_SYSTEM_ACTION: 'The system automatically removed a quote.',

            ESCALATION_POSTPONED_ACTION: '<strong>{{ ::event._links["user"].title }}</strong> postponed the escalation.',
            ESCALATION_PROGRESSED_ACTION: '<strong>{{ ::event._links["user"].title }}</strong> progressed the escalation.',
            ESCALATION_CANCELLED_ACTION: '<strong>{{ ::event._links["user"].title }}</strong> cancelled the escalation.',

            ESCALATION_MESSAGE_ACTION: '<strong>{{ ::message._links["sender"].title }}</strong> provided an update.',
        });
    }
})();
