(function (){
    'use strict';

    angular
        .module('elogbooks.user.tasks')
        .controller('UserTaskEventModalController', UserTaskEventModalController);

    UserTaskEventModalController.$inject = ['$uibModalInstance', '$state', '$translate','$stateParams', 'apiClient', 'confirmationModal', 'confirmationModalNotes', 'messenger', 'task', 'taskEvent', 'planner', 'cancellingJob', 'statuses'];

    function UserTaskEventModalController($uibModalInstance, $state, $translate, $stateParams, apiClient, confirmationModal, confirmationModalNotes, messenger, task, taskEvent, planner, cancellingJob, statuses) {
        var vm = this;
        vm.task = task;
        vm.taskEvent = taskEvent;
        vm.mode = planner.mode;
        vm.cancellingJob = cancellingJob;
        vm.partnershipSelect = {
            required: true,
            disabled: true,
            onSelect: changeServiceProvider,
            onRemove: removeServiceProvider,
            responseKeyPath: 'partnerships',
            itemHrefPath: '_links.serviceprovider.href',
            itemValuePath: '_links.serviceprovider.title',
            linkParameters: {active:1}
        };
        vm.operativeSelect = {
            disabled: true,
            responseKeyPath: 'operatives',
            itemHrefPath: '_links.self.href',
            itemValuePath: '_links.user.name'
        };
        vm.statutoryTypeSelect = {
            responseKeyPath: 'statutorytypes',
            link : '/statutorytypes'
        };

        vm.create = createAction;
        vm.cancel = cancelAction;
        vm.update = updateAction;
        vm.delete = deleteAction;
        vm.sendTaskEvent = sendTaskEvent;
        vm.isApprovalRequested = isApprovalRequested;
        vm.isApproved = isApproved;
        vm.isGhostApprovalRequested = isGhostApprovalRequested;
        vm.isGhostApproved = isGhostApproved;
        vm.isDraft = isDraft;

        let pendingStatuses = statuses.getTaskEventStatuses(['PENDING_APPROVAL', 'GHOST_PENDING_APPROVAL']);
        let rejectedStatuses = statuses.getTaskEventStatuses(['REJECTED', 'GHOST_REJECTED']);

        vm.taskEvent.events = vm.taskEvent.events ? vm.taskEvent.events.filter(function (event) {
            return  pendingStatuses.includes(vm.taskEvent.status) || rejectedStatuses.includes(vm.taskEvent.status);
        }) : [] ;

        // Fill form data from task
        if (typeof vm.task._links.self !== 'undefined') {
            if (typeof vm.task._links.site !== 'undefined') {
                // Get site to set resource links in sub select forms
                apiClient.get(vm.task._links.site.href).then(function (response) {
                    if (response) {
                        if (typeof response._links.partnerships !== 'undefined') {
                            vm.partnershipSelect.link = response._links.partnerships.href;
                            vm.partnershipSelect.disabled = false;
                        }
                    }
                });
            }

            if (vm.taskEvent.id && typeof vm.taskEvent._links.serviceprovider !== 'undefined') {
                vm.partnershipSelect.selected = vm.taskEvent._links.serviceprovider;
                vm.partnershipSelect.disabled = false;
                changeServiceProvider();
            } else if (typeof vm.task._links.serviceprovider !== 'undefined') {
                vm.partnershipSelect.selected = vm.task._links.serviceprovider;
                vm.partnershipSelect.disabled = false;
                changeServiceProvider();
            }

            if (vm.taskEvent.id && typeof vm.taskEvent._links.operative !== 'undefined') {
                vm.operativeSelect.selected = vm.taskEvent._links.operative;
                vm.operativeSelect.disabled = false;
            } else if (typeof vm.task._links.operative !== 'undefined') {
                vm.operativeSelect.selected = vm.task._links.operative;
                vm.operativeSelect.disabled = false;
            }

            if (typeof vm.taskEvent._links !== 'undefined'
                && typeof vm.taskEvent._links.statutorytype !== 'undefined'
            ) {
                vm.statutoryTypeSelect.selected = vm.taskEvent._links.statutorytype;
            }
        }

        if (typeof vm.taskEvent._links == 'undefined') {
            vm.taskEvent._links = {};
        }

        if (vm.taskEvent.rule &&
            typeof vm.taskEvent._links.delete == 'undefined') {
            vm.taskEvent._links.delete = {href:vm.task.getLink('deletebyrule')};
        }

        /**
         * Load service provider and setup operative form element
         */
        function changeServiceProvider() {
            vm.operativeSelect.disabled = false;
            vm.operativeSelect.selected = null;

            if (typeof vm.partnershipSelect.selected.href !== 'undefined') {
                apiClient.get(vm.partnershipSelect.selected.href).then(function (response) {
                    if (response && typeof response._links.taskoperatives !== 'undefined') {
                        vm.operativeSelect.link = response._links.taskoperatives.href;
                    }
                });
            }
        }

        /**
         * Disable and clear operatives
         */
        function removeServiceProvider() {
            vm.operativeSelect.disabled = true;
            vm.operativeSelect.clear();
        }

        /**
         * Create task
         */
        function createAction() {
            vm.taskEvent._links = {};

            if (typeof vm.partnershipSelect.selected !== 'undefined') {
                vm.taskEvent._links.serviceprovider = vm.partnershipSelect.selected
            }

            if (typeof vm.operativeSelect.selected !== 'undefined') {
                vm.taskEvent._links.operative = vm.operativeSelect.selected
            }

            if (typeof vm.statutoryTypeSelect.selected !== 'undefined') {
                vm.taskEvent._links.statutorytype = vm.statutoryTypeSelect.selected
            }

            apiClient.create(vm.task.getLink('events'), vm.taskEvent).then(function (response) {
                if (response) {
                    vm.taskEvent = response;

                    messenger.success('ADD_SUCCESS');
                    $uibModalInstance.close(response);
                } else {
                    messenger.error('REQUEST_ERROR');
                }
            });
        }

        /**
         * Update task
         */
        function updateAction() {
            //Clear links
            var taskEventRequest = angular.copy(vm.taskEvent);

            taskEventRequest._links = {};
            if (typeof vm.partnershipSelect.selected !== 'undefined') {
                taskEventRequest._links.serviceprovider = vm.partnershipSelect.selected
            }

            if (typeof vm.operativeSelect.selected !== 'undefined') {
                taskEventRequest._links.operative = vm.operativeSelect.selected
            }

            if (typeof vm.statutoryTypeSelect.selected !== 'undefined') {
                taskEventRequest._links.statutorytype = vm.statutoryTypeSelect.selected
            }

            apiClient.update(vm.taskEvent.getLink('self'), taskEventRequest).then(function (response) {
                if (response) {
                    messenger.success('EDIT_SUCCESS');
                    taskEventRequest._links.self = vm.taskEvent._links.self;
                    taskEventRequest._links.delete = vm.taskEvent._links.delete;

                    $uibModalInstance.close(taskEventRequest);
                } else {
                    messenger.error('REQUEST_ERROR');
                }
            });
        }

        function deleteAction() {
            return confirmationModal.open().result.then(
                function () {
                    return apiClient.delete(vm.taskEvent._links.delete.href, {date:vm.taskEvent.date, rule:vm.taskEvent.rule}).then(function (){
                        messenger.success('DELETE_SUCCESS');
                        $uibModalInstance.close({ action: 'delete', event: vm.taskEvent });
                    });
                }
            );
        }

        function cancelAction() {
            $uibModalInstance.dismiss('cancel');
        }

        function isApprovalRequested(taskEventStatus) {
            return taskEventStatus == 2 || taskEventStatus == 12;
        }

        function isGhostApprovalRequested(taskEventStatus) {
            return taskEventStatus == 12;
        }

        function isApproved(taskEventStatus) {
            return taskEventStatus == 3 || taskEventStatus == 14;
        }

        function isGhostApproved(taskEventStatus) {
            return taskEventStatus == 14;
        }

        function isDraft(taskEventStatus) {
            return taskEventStatus == 1 || taskEventStatus == 10;
        }

        function sendTaskEvent(action) {
            var requestData = {
                _links : {
                    event : [

                    ]
                }
            };

            var url = null;

            switch (action) {
                case 'approve':
                    url = vm.taskEvent.getLink('approve');
                    break;
                case 'decline':
                    url = vm.taskEvent.getLink('decline');
                    break;
            }

            if (action == 'decline') {
                return confirmationModalNotes.open({bodyMessage: 'REASON_FOR_TASK_EVENT_REJECT', required:true}).result.then(
                    function (note) {
                        requestData.note = note;
                        requestData.year = $stateParams.year;
                        cancelAction();
                        return apiClient.create(url, requestData).then(function (){
                            messenger.success('ACTION_SUCCESS');
                            $state.go('.', $stateParams, {reload: $state.current});
                        });
                    }
                );
            }
            var confirmationModalType = confirmationModal;
            var modalOptions = {primaryClass: 'btn-success', htmlBodyMessage: ''};
            if (vm.taskEvent.getLink('statutorytype') && !vm.taskEvent.getLink('documentType')) {
                modalOptions.htmlBodyMessage = '<p class="alert alert-warning"><i class="fa fa-info-circle"></i> ' + $translate.instant('FREQUENCIES_MARKED_STATUTORY_NO_DOCUMENTTYPE') + '</p>';
            }

            if (vm.cancellingJob) {
                var notesHtml = '<label class="control-label">Notes</label><label class="control-label w-100">'+vm.task.name+'</label><span class="form-control disabled">'+(vm.taskEvent.note)+'</span>';
                modalOptions.htmlBodyMessage = modalOptions.htmlBodyMessage + '<div class="form-group">' + notesHtml + '</div>';
                angular.extend(modalOptions, { bodyMessage: null, label: 'CANCELLATION_NOTE', required: true, warningMessage: 'APPROVAL_WILL_CANCEL_JOBS' });
                confirmationModalType = confirmationModalNotes;
            }
            return confirmationModalType.open(modalOptions).result.then(
                function (note) {
                    cancelAction();
                    if (note && typeof note !== 'object') {
                        requestData.note = note;
                    }
                    return apiClient.create(url, requestData).then(function (){
                        messenger.success('ACTION_SUCCESS');
                        $state.go('.', $stateParams, {reload: $state.current});
                    });
                }
            );
        }
    }
})();
