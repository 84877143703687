app.config(function ($translateProvider) {
    $translateProvider.translations('nl', {
        GENERAL_SETTINGS: 'Algemeen',
        SETTINGS_UPDATED: 'Instellingen aangepast',
        EMAIL_SETTINGS: 'E-mail',
        PASSWORD_SETTINGS: 'Wachtwoord',
        JOB_SETTINGS: 'Opdrachten',
        JOB_SETTINGS_VIEW: 'Opdracht instellingen',
        JOB_CHASE_SETTINGS_VIEW: 'Instellingen Opdracht Opvolging',
        CHASE_SETTINGS_VIEW: 'Opvolg instellingen',
        QUOTE_SETTINGS: 'Offerte',
        QUOTE_SETTINGS_VIEW: 'Offerte Instellingen',
        CHASE_SETTINGS: 'Opvolging',
        SURVEY_SETTINGS: 'Tevredenheidsonderzoeken',
        SURVEY_SETTINGS_VIEW: 'Instellingen tevredenheidsonderzoeken',

        PERFORMANCE_REPORTING_SETTINGS: 'KPI score',
        PERFORMANCE_SETTINGS_VIEW: 'Instellingen KPI',
        FILE_SETTINGS: 'Bestanden',
        FILE_SETTINGS_VIEW: 'Instellingen bijlagen / bestanden',
        APP_SETTINGS: 'App',
        APP_SETTINGS_VIEW: 'App Instellingen',

        SETTINGS_EDIT: 'Wijzig instellingen',
        GENERAL_SETTINGS_VIEW: 'Algemene instellingen',
        GENERAL_LOGO: 'Systeem logo',
        GENERAL_OPERATING_HOURS: 'Werktijden',
        GENERAL_OPENING_TIME: 'Openings tijd servicedesk',
        GENERAL_CLOSING_TIME: 'Sluitings tijd servicedesk',
        GENERAL_TASKS_GENERATE_AHEAD: 'Aantal dagen waarop taken vooruit kunnen worden gepland',
        GENERAL_TASKS_WILL_ALWAYS_GENERATE_NEXT_SUNDAY: 'Opdracht wordt altijd op eerste zondag gezet',
        GENERAL_AUDITS_GENERATE_AHEAD: 'Dagen waarna Audit wordt gepland',

        ACTIONS_SITEASSOCIATETYPE: 'Eerste persoon om acties toe te wijzen',
        ACTION_SITE_ASSOCIATE_TYPE_SETTING_ASSOCIATE_TYPE_INVALID: 'Unable to Raise Paperwork action, Action Associate Type setting invalid',
        ACTION_ASSOCIATES_NO_ASSOCIATES_ASSIGNED_FOR_THIS_SITE:'Unable to Raise Paperwork action, no associates for this site for the action assignee associate type setting',

        EMAIL_SETTINGS_VIEW: 'E-mail Instellingen',
        EMAIL_HTML_SIGNATURE: 'E-mail Handtekening (Html)',
        EMAIL_TEXT_SIGNATURE: 'E-mail Handtekening (Tekst)',

        PASSWORD_SETTINGS_VIEW: 'Wachtwoord Instellingen',
        PASSWORD_DAYS_TILL_CHANGE_NOTIFICATION: 'Aantal dagen tussen wijzigen wachtwoord',
        PASSWORD_DISALLOW_PREVIOUS_PASSWORDS: 'Gebruikte wachtwoorden niet toegestaan',
        PASSWORD_FORCE_CHANGE: 'Aanpassing afdwingen',
        PASSWORD_MIN_CHARACTERS: 'Minimaal aantal tekens',
        PASSWORD_REQUIRE_NUMBER: 'Getal vereist',
        PASSWORD_REQUIRE_PUNCTUATION: 'Leesteken vereist',
        PASSWORD_REQUIRE_UPPERCASE: 'Hoofdletter vereist',
        PASSWORD_COMPLEXITY: 'Wachtwoord moeilijkheidsgraad',
        PASSWORD_CHANGE_FREQUENCY: 'Aanpassen wachtwoord over',

        JOB_GROUPS_REQUIRE: 'Opdracht groep verplicht',
        JOB_EXTRA_SETTINGS_EDIT: 'Extra opdracht instellingen',
        JOB_ACCEPTANCE_TERMS_TEXT: 'Algemene voorwaarden voor opdrachtbonnen',
        JOB_ACCEPTANCE_TERMS_FILE: 'Bijlage met algemene voorwaarden',
        JOB_SATISFACTION_SURVEY_FREQUENCY: 'Frequentie tevredenheidsonderzoek',
        JOB_TENANT_SELECTION_WHEN_LOGGING: 'Sta toe dat een huurder kan worden aangewezen als melder',
        JOB_APPROVAL_REMINDER: 'Herinnering voor goedkeuring',
        JOB_ACKNOWLEDGEMENT_REMINDER: 'Herinnering voor bevestiging',
        JOB_REASSIGNMENT_REMINDER: 'Reassignment Reminder',
        JOB_REACTIVE_COMMENCEMENT_REMINDER: 'Herinnering aanvang correctief onderhoud',
        JOB_REACTIVE_COMPLETION_REMINDER: 'Herinnering afronden correctief onderhoud',
        JOB_REACTIVE_ALLOW_SELF_APPROVAL: 'Allow secondary onward approvers to approve their own reactive jobs',
        JOB_PLANNED_COMMENCEMENT_REMINDER: 'Herinnering aanvang planmatig onderhoud',
        JOB_PLANNED_COMPLETION_REMINDER: 'Planned Completion Reminder',
        JOB_MINUTES_BEFORE_MUST_BE_APPROVED: 'Number of minutes before job must be approved',
        JOB_MINUTES_BEFORE_REASSIGNMENT_REMINDER: 'Number of minutes before reassignment reminder',
        JOB_MINUTES_BEFORE_ACKNOWLEDGEMENT_REMINDER: 'Number of minutes before acknowledgement reminder',
        JOB_MINUTES_BEFORE_REACTIVE_COMMENCEMENT_REMINDER: 'Number of minutes before reactive commencement reminder',
        JOB_MINUTES_BEFORE_REACTIVE_COMPLETION_REMINDER: 'Number of minutes before reactive completion reminder',
        JOB_MINUTES_BEFORE_PLANNED_COMMENCEMENT_REMINDER: 'Number of minutes before planned commencement reminder',
        JOB_MINUTES_BEFORE_PLANNED_COMPLETION_REMINDER: 'Number of minutes before planned completion reminder',
        REQUIREJOBGROUP: 'Opdracht groep verplicht',
        ALLOWTENANTSELECTION: 'Allow a Tenant to be selected when logging a job',
        REACTIVESELFAPPROVAL: 'Allow secondary onward approvers to approve their own reactive jobs',
        REASSIGNMENTREMINDER: 'Reassignment Reminder',
        APPROVALREMINDER: 'Herinnering voor goedkeuring',
        ACKNOWLEDGEMENTREMINDER: 'Herinnering voor bevestiging',
        REACTIVECOMMENCEMENTREMINDER: 'Herinnering aanvang correctief onderhoud',
        REACTIVECOMPLETIONREMINDER: 'Herinnering afronden correctief onderhoud',
        PLANNEDCOMMENCEMENTREMINDER: 'Herinnering aanvang planmatig onderhoud',
        PLANNEDCOMPLETIONREMINDER: 'Herinnering voor afronden planmatig onderhoud',

        CHASE_SETTINGS_REACTIVE: 'Correctief',
        CHASE_SETTINGS_REACTIVESTATUTORY: 'Correctief verplicht',
        CHASE_SETTINGS_PLANNED: 'Planmatig',
        CHASE_SETTINGS_PLANNEDSTATUTORY: 'Planmatig verplicht',
        CHASE_SETTINGS_TYPE0_EDIT: 'Aanpassen herinnering correctief',
        CHASE_SETTINGS_TYPE1_EDIT: 'Aanpassen herinnering verplicht correctief',
        CHASE_SETTINGS_TYPE2_EDIT: 'Aanpassen herinnering planmatig',
        CHASE_SETTINGS_TYPE3_EDIT: 'Aanpassen herinnering planmatig verplicht',
        CHASE_SETTINGS_TYPE4_EDIT: 'Herinnering offerte wijzigen',
        CHASE_SETTINGS_TYPE0_ADD: 'Voeg herinnering voor correctief toe',
        CHASE_SETTINGS_TYPE1_ADD: 'Voeg herinnering voor verplicht correctief toe',
        CHASE_SETTINGS_TYPE2_ADD: 'Voeg herinnering voor planmatig toe',
        CHASE_SETTINGS_TYPE3_ADD: 'Voeg herinnering voor verplicht planmatig toe',
        CHASE_SETTINGS_TYPE4_ADD: 'Herinnering offerte wijzigen',
        CHASE_SETTINGS_QUOTE: 'Offerte',
        CHASE_TARGET: 'Doel',
        CHASE_START_DATE: 'Start Datum',
        CHASE_EMAIL_BODY: 'Bericht',
        CHASE_SETTINGS_TYPE_ADD: 'Voeg herinnering instellingen toe',
        CHASE_SETTINGS_TYPE_EDIT: 'Wijzig instelling herinneringen',
        CHASE_SETTING_UPDATED: 'Herinnering instellingen aangepast',
        CHASE_SETTING_REMOVED: 'Herinnering instellingen verwijderd',
        CHASE_SETTINGS_REORDERED: 'Herinnerings instellingen opnieuw ingevoerd',
        CHASE_BODY: 'Inhoud',
        SITE_ASSOCIATE: 'Site Associate',

        BODY: 'Inhoud',
        TARGETSITE: 'Target site',
        TARGETOPERATIVE: 'Target operative',
        SEQUENCE: 'Sequence',
        AFTERDUEDATEPERIOD: 'After due date period',
        AFTERUPDATEPERIOD: 'After update period',
        WEEKENDSPUBLICHOLIDAYS: 'Include weekends & holidays?',

        QUOTE_TIMEOUT: 'Timeout',
        QUOTE_TIMEOUT_SUB_LABEL: 'Dagen nog geldig',
        QUOTE_TERMS_TEXT: 'Offerte voorwaarden en condities',
        QUOTE_TERMS_FILE: 'Offerte voorwaarden en condities bijlage',
        TERMS_DOWNLOAD_TITLE: 'Klik hier voor de huidige voorwaarden en condities',

        QUOTE_EXTRA_SETTINGS_EDIT: 'Extra',
        QUOTE_EXTENSION_APPROVAL: 'Goedkeuring voor verlengen offerte',
        QUOTE_REMINDER_AT: 'Offerte herinnering',

        SURVEY_TIMEOUT: 'Timeout',

        KPI_TIMEOUT: 'Timeout',
        KPI_TIMEOUT_SUB_LABEL: 'Aantal dagen tot automatische acceptatie van de survey',

        APP_TIMEOUT: 'Timeout',

        PERFORMANCE_INCLUDE_PATROL_SCORE: 'Inclusief Ronde score',
        PERFORMANCE_INCLUDE_SURVEY_SCORE: 'Inclusief Survey Scores',
        PERFORMANCE_PATROL_SCORE_WEIGHT: 'Weging Patrol Score',
        PERFORMANCE_QUOTE_SCORE_WEIGHT: 'Weging offerte Score',
        PERFORMANCE_SURVEY_SCORE_WEIGHT: 'Weging survey score',
        PERFORMANCE_SYSTEM_SCORE_WEIGHT: 'Weging systeem score',

        FILE_ALLOWED_TYPES: 'Toegestane File Types/Mime Types',
        FILE_DISALLOWED_TYPES: 'Niet toegestane File Types/Mime Types',
        FILE_RESTRICT: 'Niet teogestane bestands upload',
        FILE_TYPE_DISALLOWED: 'Deze bestandsextentie is neit toegestaan',
        FILE_SETTINGS_ALLOW_SUB_LABEL: 'If this field is empty it will allow all except disallowed',
        FILE_SETTINGS_DISALLOW_SUB_LABEL: 'If this field is empty it will disallow all except allowed',

        TASK_FORCE_SERVICE_ROUTINE: 'Force Service Routines for Hard Task ',
        TASK_SETTINGS_VIEW: 'Task Settings',
        GENERAL_DEFAULT_LOCALE: 'System locale',
        GENERAL_HELPDESK_NUMBER: 'Helpdesk telefoonnummer',
        GENERAL_HELPDESK_EMAIL: 'Helpdesk E-mail'
    });
});
