(function() {
    'use strict';

    angular
        .module('elogbooks.admin.charge-types')
        .config(registerRoutes)
        .config(registerRoutesList)
        .config(registerRoutesAdd)
        .config(registerRoutesDetails)
        .config(registerRoutesEdit);

    function registerRoutes($stateProvider) {
        $stateProvider
            .state('dashboard.admin.charge-types', {
                abstract: true,
                templateUrl: 'shared-templates/view.html',
                ncyBreadcrumb: {
                    skip: true
                },
                data: {
                    permissions: ['user_permission_manage_charge_types']
                }
            });
    }

    function registerRoutesList($stateProvider) {
        $stateProvider
            .state('dashboard.admin.charge-types.list', {
                url: '/charge-types?page&limit&order&id',
                parent: 'dashboard.admin.charge-types',
                data: {
                    permissions: ['user_permission_manage_charge_types']
                },
                views: {
                    '@dashboard.admin.charge-types': {
                        templateUrl: '/modules/admin/charge-types/charge-type-list.html',
                        controller: 'ChargeTypesController',
                        controllerAs: 'vm'
                    }
                },
                params: {
                    page: '1',
                    limit: '30',
                    order: 'id',
                    id: null
                },
                resolve: {
                    chargeTypesCollectionResponse: function($stateParams, apiClient, rootResourceResponse) {
                        return apiClient.get(rootResourceResponse.getLink('chargetypes'), $stateParams).then( function(response) {
                            return response || apiClient.noResourceFound();
                        });
                    }
                },
                ncyBreadcrumb: {
                    label: '{{ ::"CHARGE_TYPE_LIST" | translate }}'
                }
            });
    }

    function registerRoutesAdd($stateProvider) {
        $stateProvider
            .state('dashboard.admin.charge-types.list.add', {
                url: '/add',
                parent: 'dashboard.admin.charge-types.list',
                data: {
                    permissions: ['user_permission_manage_charge_types']
                },
                views: {
                    '@dashboard.admin.charge-types': {
                        templateUrl: '/modules/admin/charge-types/add-edit/charge-type-add-edit.html',
                        controller: 'ChargeTypesAddEditController',
                        controllerAs: 'vm'
                    }
                },
                resolve: {
                    chargeTypeResponse: function() {
                        return {};
                    }
                },
                ncyBreadcrumb: {
                    label: '{{ ::"ADD" | translate }}'
                }
            });
    }

    function registerRoutesDetails($stateProvider) {
        $stateProvider
            .state('dashboard.admin.charge-types.list.details', {
                url: '/details/{resource}',
                abstract: true,
                parent: 'dashboard.admin.charge-types.list',
                data: {
                    permissions: ['user_permission_manage_charge_types']
                },
                views: {
                    '@dashboard.admin.charge-types': {
                        templateUrl: '/modules/admin/charge-types/details/charge-type-details.html',
                        controller: 'ChargeTypesDetailsController',
                        controllerAs: 'vm'
                    }
                },
                resolve: {
                    chargeTypeResponse: function(apiClient, $stateParams) {
                        return apiClient.get($stateParams.resource.decode()).then( function(response) {
                            return response || apiClient.noResourceFound();
                        });
                    }
                },
                ncyBreadcrumb: {
                    label: '{{ ::$resolves().chargeTypeResponse.name }}'
                }
            })
             .state('dashboard.admin.charge-types.list.details.info', {
                url: '/info',
                parent: 'dashboard.admin.charge-types.list.details',
                data: {
                    permissions: ['user_permission_manage_charge_types']
                },
                views: {
                    '@dashboard.admin.charge-types.list.details': {
                        templateUrl: '/modules/admin/charge-types/details/info/charge-type-info.html',
                        controller: 'ChargeTypesInfoController',
                        controllerAs: 'vm'
                    }
                },
                ncyBreadcrumb: {
                    label: '{{ ::"INFO" | translate }}'
                }
            });
    }

    function registerRoutesEdit($stateProvider) {
        $stateProvider
            .state('dashboard.admin.charge-types.list.details.info.edit', {
                url: '/edit',
                parent: 'dashboard.admin.charge-types.list.details.info',
                data: {
                    permissions: ['user_permission_manage_charge_types']
                },
                views: {
                    '@dashboard.admin.charge-types.list.details': {
                        templateUrl: '/modules/admin/charge-types/add-edit/charge-type-add-edit.html',
                        controller: 'ChargeTypesAddEditController',
                        controllerAs: 'vm'
                    }
                },
                ncyBreadcrumb: {
                    label: '{{ ::"EDIT" | translate }}'
                }
            });
    }

})();
