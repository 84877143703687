(function () {
    'use strict';

    angular
        .module('elogbooks.common.files')
        .controller('RiskwiseFilesIntegrationDetailsController', RiskwiseFilesIntegrationDetailsController);

    RiskwiseFilesIntegrationDetailsController.$inject = [
        '$uibModalInstance',
        'requestDataFactory',
        'apiClient',
        'messenger',
        'file',
        'actionResponse',
        'integrationDocSystem',
        'integrationDocCats'
    ];

    function RiskwiseFilesIntegrationDetailsController (
        $uibModalInstance,
        requestDataFactory,
        apiClient,
        messenger,
        file,
        actionResponse,
        integrationDocSystem,
        integrationDocCats
    ) {
        var vm = this;
        vm.file = file;
        vm.actionResponse = actionResponse;
        vm.integrationDocSystem = integrationDocSystem;
        vm.integrationDocCats = integrationDocCats;
        vm.data = angular.extend({}, requestDataFactory.createRequest(), {
            integration: {'riskwise': null},
            _links: {
                'action': actionResponse._links.self,
                'file': file._links.self
            }
        });

        vm.create = createAction;
        vm.cancel = cancelAction;
        vm.resetIntegrationDoc = resetIntegrationDoc;

        function createAction () {
            apiClient.create(vm.file.getLink('integrate'), vm.data).then(function (response) {
                if (response) {
                    $uibModalInstance.close();
                } else {
                    messenger.error('REQUEST_ERROR');
                }
            });
        }

        function cancelAction() {
            $uibModalInstance.dismiss();
        }

        function resetIntegrationDoc(type) {
            if (vm.data.integration !== undefined) {
                vm.data.integration[type] = null;
                vm.integrationDocCat = null;
            }
        }
    }
})();