app.config(function ($translateProvider) {
    $translateProvider.translations('nl', {
        GO_TO_JOB: 'Opdracht',
        GO_TO_QUOTE: 'Offerte',
        SITE_QUICK_ACTIONS: 'Objecten',
        RAISE_JOB: 'Maak opdracht',
        VIEW_JOBS: 'Bekijk opdrachten',
        RAISE_QUOTE: 'Maak offerte',
        VIEW_QUOTES: 'Bekijk offertes',
        NO_JOB_FOUND: 'Geen opdrachten gevonden.',
        NO_QUOTE_FOUND: 'Geen offertes gevonden.'
    });
});
