angular.module('elogbooksServices').service('azureService', function ($rootScope, msalAuthenticationService, messenger, lodash, apiClient, $window, elbSettings, $state, userManager) {

    'use strict';

    var self = this;
    var scopes = ["openid"];
    self.login = login;
    self.authorise = authorise;
    self.forceUserToLinkAccount = forceUserToLinkAccount;
    self.renewToken = renewToken;

    function renewToken() {
        msalAuthenticationService.acquireTokenSilent(scopes).then(function() {
            // token valid, ignore.
        }, function() {
            userManager.logout();
            $state.go('login');
        });
    }

    function forceUserToLinkAccount(uri) {
        apiClient.get(uri).then(function(ssoResponse) {
            if (ssoResponse && ssoResponse.isSsoUser && !ssoResponse.isUserLinked) {
                $state.go('sso');
            }
        });
    }

    function authorise() {
        msalAuthenticationService.loginPopup(scopes).then(function (response) {
            apiClient.create('/sso/authorise', {
                accessToken: response,
                uid: msalAuthenticationService.getUser().userIdentifier
            }).then(function (response) {
                if (response) {
                    $state.go('dashboard.user.dashboard', {reload: false}).then(function () {
                        messenger.success('LINK_TO_AZURE_SUCCESSFUL');
                    });
                } else {
                    messenger.error('REQUEST_ERROR');
                }
            });
        }).catch(function (error) {
            console.log(error);
        });
    }

    function login() {
        msalAuthenticationService.loginPopup(scopes).then(function(accessToken) {
            apiClient.create('/sso/login', {
                uid: msalAuthenticationService.getUser().userIdentifier,
                accessToken: accessToken,
                source: 'sso'
            }).then(function (response) {
                if (response) {
                    $window.localStorage.setItem('token', response.token);
                    $window.localStorage.setItem('tokenExpiration', response.expiration*1000);
                    $window.localStorage.removeItem('recaptchaRequired');

                    userManager.getUserResourceAndTokenLink().then(function (response) {
                        if (response) {
                            $state.go('dashboard.user.dashboard', {}, {reload: true});
                        } else {
                            $window.localStorage.removeItem('token');
                            $window.localStorage.removeItem('tokenExpiration');
                        }
                    });
                } else {
                    messenger.error('REQUEST_ERROR');
                }
            });
        }).catch(function(error) {
            console.log(error);
        });
    }
});
