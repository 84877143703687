(function() {
    'use strict';

    var installation = window.location.hostname.substring(0, window.location.hostname.indexOf('.')),
        domain = window.location.hostname.substring(window.location.hostname.indexOf('.') + 1);

    angular
        .module('elogbooks')
        .constant('API_URL', window.location.protocol + '//' + installation + '-api.' + domain);
})();
