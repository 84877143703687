(function() {
    'use strict';

    angular
        .module('elogbooks')
        .config(Translations);

    function Translations($translateProvider) {
        $translateProvider.translations('en-gb', {

            ESCALATE_OPERATION_SUCCESS: 'Escalated',
            ESCALATION_PROGRESS_SUCCESS: 'Escalation Progressed',
            ESCALATION_POSTPONE_SUCCESS: 'Escalation Postponed',
            ESCALATION_CANCEL_SUCCESS: 'Escalation Cancelled',

            ESCALATION_WORKFLOW_PROGRESS: 'Progress',
            ESCALATION_WORKFLOW_PROGRESS_SUBMIT: 'Progress',
            ESCALATION_WORKFLOW_CANCEL: 'Cancel',
            ESCALATION_WORKFLOW_CANCEL_SUBMIT: 'Cancel Escalation',
            ESCALATION_WORKFLOW_POSTPONE: 'Postpone',
            ESCALATION_WORKFLOW_POSTPONE_SUBMIT: 'Postpone',
            NO_ESCALATION_OPERATIVE: 'No Primary Escalation Operative'
        });
    }
})();
