;(function() {
    'use strict';

    angular
        .module('elogbooks')
        .config(Translations);

    Translations.$inject = ['$translateProvider'];

    function Translations($translateProvider) {
        $translateProvider
            .translations('en-gb', {
                GENERIC_JOB_VALUES: 'Job Values',
                NOMINAL_LINE_MISSING: 'Nominal category missing.'
            });
    }
})();
