(function () {
    'use strict';

    angular
        .module('elogbooks.common.service-routines')
        .controller('CommonServiceRoutineLinesController', CommonServiceRoutineLinesController);

    CommonServiceRoutineLinesController.$inject = ['serviceRoutineLines', 'serviceRoutine'];

    function CommonServiceRoutineLinesController(serviceRoutineLines, serviceRoutine) {
        var vm = this;
        vm.serviceRoutine = serviceRoutine;
        vm.serviceRoutineLinesResponse = serviceRoutineLines;
        vm.serviceRoutineLines = serviceRoutineLines.serviceRoutineLines;
    }
})();
