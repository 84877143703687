(function () {
    'use strict';

    angular
        .module('elogbooks.user.sites.files')
        .config(registerRoutes)
        .config(registerRoutesAdd)
        .config(registerRoutesDetails)
        .config(registerRoutesDocumentCompliance);

    function registerRoutes ($stateProvider) {
        $stateProvider
            .state('dashboard.user.sites.list.details.files', {
                url: '',
                abstract: true,
                views: {
                    '@dashboard.user.sites.list.details': {
                        templateUrl: '/modules/common/files/manage-files/manage-files.html',
                        controller: 'CommonManageFilesController',
                        controllerAs: 'vm'
                    }
                },
                resolve: {
                    sectionResource: function(userManager, user, rootResourceResponse) {
                        return {
                            siteFilesSref: 'dashboard.user.sites.list.details.files.site-files-list',
                            jobAttachmentsSref: 'dashboard.user.sites.list.details.files.job-attachments-list',
                            auditReportsSref: rootResourceResponse.getLink('audits') ?
                                'dashboard.user.sites.list.details.files.audit-reports-list' : null,
                            quoteAttachmentsSref: 'dashboard.user.sites.list.details.files.quote-attachments-list',
                            allFilesSref: 'dashboard.user.sites.list.details.files.all-files-list',
                            documentComplianceSref: 'dashboard.user.sites.list.details.files.document-compliance-list'
                        };
                    }
                },
                ncyBreadcrumb: {
                    skip: true
                }
            });

            registerRoutesAbstract('all-files-list', 'all-files', {isJobFile: false}, 'ALL_FILES',{
                showTags: true,
                showStatutoryType: true,
                hideAddButton: true,
                noBorder: true,
                title:'ALL_FILES',
                groupByDocumentType: false
            }, 'list.html');

            registerRoutesAbstract('job-attachments-list', 'job-attachments', {entityType: 'Job'}, 'JOB_ATTACHMENTS', {
                isEntityAttachments: true,
                entitySummary: 'JOB_SUMMARY',
                entityId: 'ID_JOB',
                showJobFiles: false,
                hideAddButton: true,
                noBorder: true,
                title: 'JOB_ATTACHMENTS',
                groupByDocumentType: false
            }, 'list.html');

            registerRoutesAbstract('audit-reports-list', 'audit-reports', {entityType: 'AuditReportPdf'}, 'AUDIT_REPORTS', {
                isEntityAttachments: true,
                entitySummary: 'AUDIT_SUMMARY',
                entityId: 'ID_AUDIT',
                showJobFiles: false,
                hideAddButton: true,
                noBorder: true,
                title: 'AUDIT_REPORTS',
                groupByDocumentType: false,
                isAudit: true
            }, 'audit-list.html');

            registerRoutesAbstract('quote-attachments-list', 'quote-attachments', {entityType: 'Quote'}, 'QUOTE_ATTACHMENTS', {
                isEntityAttachments: true,
                entitySummary: 'QUOTE_SUMMARY',
                entityId: 'ID_QUOTE',
                showJobFiles: false,
                hideAddButton: true,
                noBorder: true,
                title: 'QUOTE_ATTACHMENTS',
                groupByDocumentType: false
            }, 'list.html');

            registerRoutesAbstract('site-files-list', 'site-files', {entityType: 'Site'}, 'SITE_FILES',{
                showJobFiles: true,
                noBorder: true,
                title:'SITE_FILES',
                groupByDocumentType: false
            }, 'list.html');

            function registerRoutesAbstract(state, url, collectionParams, translation, configParams, template) {
                $stateProvider
                    .state('dashboard.user.sites.list.details.files.' + state, {
                        url: '/' + url + '?filePage&fileLimit&fileOrder&fileTitle&fileUploadedBy&fileUploadedDateStart&fileUploadedDateEnd&fileExpiresDateStart&fileExpiresDateEnd&fileTags&fileIsStatutory',
                        parent: 'dashboard.user.sites.list.details.files',
                        params: {
                            fileId: null,
                            filePage: '1',
                            fileLimit: '30',
                            fileOrder: '-id',
                            fileSelectedSiteResource: null,
                            fileTitle: null,
                            fileUploadedBy: null,
                            fileUploadedDateStart: null,
                            fileUploadedDateEnd: null,
                            fileExpiresDateStart: null,
                            fileExpiresDateEnd: null,
                            fileTags: {array: true},
                            fileIsStatutory: null,
                            fileIsJobFile: null,
                            serviceProvider: null,
                            siteIds: {array: true},
                            showStatutoryType: true,
                            fileVisibility: null,
                            documentTypes: {array: true},
                            auditId: null,
                            auditSummary: null,
                            auditReference: null,
                            auditServiceProvider: null,
                            auditOperative: null,
                            documentDateStart: null,
                            documentDateEnd: null,
                            documentDateRange: null
                        },
                        views: {
                            'tab-panel@dashboard.user.sites.list.details.files': {
                                templateUrl: '/modules/common/files/manage-files/list/' + template,
                                controller: 'CommonFilesListController',
                                controllerAs: 'vm'
                            }
                        },
                        resolve: {
                            fileCollectionResponse: function (apiClient, user, siteResponse, $stateParams) {
                                //check if user can see job files

                                if (collectionParams.entityType === 'Site') {
                                    collectionParams.isJobFile = !user.getLinkAttribute('files', 'canSeeJobFiles') ? false : $stateParams.fileIsJobFile;
                                }

                                var params = angular.extend({}, $stateParams,
                                    {
                                        id: $stateParams.fileId,
                                        page: $stateParams.filePage,
                                        limit: $stateParams.fileLimit,
                                        order: $stateParams.fileOrder,
                                        title: $stateParams.fileTitle,
                                        status: $stateParams.fileStatus,
                                        uploadedBy: $stateParams.fileUploadedBy,
                                        uploadedDateStart: $stateParams.fileUploadedDateStart ? new Date($stateParams.fileUploadedDateStart).toISOString() : null,
                                        uploadedDateEnd: $stateParams.fileUploadedDateEnd ? new Date($stateParams.fileUploadedDateEnd).toISOString() : null,
                                        expiresDateStart: $stateParams.fileExpiresDateStart ? new Date($stateParams.fileExpiresDateStart).toISOString() : null,
                                        expiresDateEnd: $stateParams.fileExpiresDateEnd ? new Date($stateParams.fileExpiresDateEnd).toISOString() : null,
                                        tags: $stateParams.fileTags,
                                        documentTypes: $stateParams.documentTypes,
                                        documentType: $stateParams.documentTypes ? null : $stateParams.documentType ? JSON.parse($stateParams.documentType).v : null,
                                        isStatutory: $stateParams.fileIsStatutory,
                                        visibility: $stateParams.fileVisibility,
                                        auditServiceProvider: $stateParams.auditServiceProvider ? JSON.parse($stateParams.auditServiceProvider).v : null,
                                        auditOperative: $stateParams.auditOperative && $stateParams.auditServiceProvider ? JSON.parse($stateParams.auditOperative).v : null
                                    },
                                    collectionParams
                                );

                                if (typeof $stateParams.siteIds === 'undefined' ) {
                                        $stateParams.siteIds = [];
                                    }

                                if (siteResponse) {
                                    $stateParams.siteIds = [siteResponse.id];
                                }

                                params = angular.extend({}, params, {
                                    'siteIds[]': $stateParams.siteIds,
                                    'serviceProvider[]' : $stateParams.serviceProvider,
                                    'fileTags[]': $stateParams.fileTags,
                                    'documentTypes[]': $stateParams.documentTypes
                                });

                                if (siteResponse) {
                                    params.site = siteResponse.id;
                                }

                                return apiClient.get(user.getLink('files'), params).then(function (response) {
                                    return response || apiClient.noResourceFound();
                                });
                            },
                            tagCollectionResponse: function (apiClient, rootResourceResponse) {
                                return apiClient.get(rootResourceResponse.getLink('tags'), { limit: 30 }).then(function (response) {
                                    return response || apiClient.noResourceFound();
                                });
                            },
                            filesTotalSizeResponse: function (apiClient, fileCollectionResponse) {
                                if (fileCollectionResponse.getLink('files-total-size')) {
                                    return apiClient.get(fileCollectionResponse.getLink('files-total-size')).then(function(response) {
                                        if (response) {
                                            return response;
                                        }
                                    });
                                }
                            },
                            config: function(user, filesTotalSizeResponse) {
                                return angular.extend({},
                                    {
                                        showSite: false,
                                        hideAddButton: !user.getLinkAttribute('files', 'canCreateGlobalFiles' ),
                                        filesTotalSizeResponse: filesTotalSizeResponse
                                    },
                                    configParams
                                );
                            },
                            integrationDocTypesResponse: function()
                            {
                                return null;
                            },
                            documentTypeCollectionResponse : function (apiClient, rootResourceResponse) {
                                return apiClient.get(rootResourceResponse.getLink('documenttypes'), {status: 'active', order: 'name'})
                                    .then(function (response) {
                                        return response || apiClient.noResourceFound();
                                    });
                            },
                            jobResponse : function () {
                                return null;
                            },
                            serviceProviderResponse: function(apiClient, user) {
                                return apiClient.getPage(user.getLink('serviceproviders'));
                            },
                            operativeCollectionResponse: function(apiClient, $stateParams, serviceProviderResponse, lodash) {
                                if (!$stateParams.auditServiceProvider || !serviceProviderResponse) {
                                    return;
                                }

                                var serviceProviderId = JSON.parse($stateParams.auditServiceProvider).v;
                                var serviceProvider = lodash.find(
                                    serviceProviderResponse.serviceproviders,
                                    { id: serviceProviderId }
                                );

                                return serviceProvider && apiClient.get(serviceProvider.getLink('operatives')).then(function(response) {
                                    return response;
                                });
                            }
                        },
                        ncyBreadcrumb: {
                            label: '{{ ::"' + translation + '" | translate }}'
                        }
                    });
            }
    }

    function registerRoutesDetails ($stateProvider) {

        registerRoutesDetailsAbstract('all-files-list', {});
        registerRoutesDetailsAbstract('site-files-list', {entityType: 'Site', showStatutoryType: false});
        registerRoutesDetailsAbstract('job-attachments-list', {entityType: 'Job'});
        registerRoutesDetailsAbstract('audit-reports-list', {entityType: 'AuditReportPdf'});
        registerRoutesDetailsAbstract('quote-attachments-list', {entityType: 'Quote'});

        function registerRoutesDetailsAbstract(state, configParams) {
            $stateProvider
                .state('dashboard.user.sites.list.details.files.' + state + '.details', {
                    url: '/details/{fileResource}',
                    parent: 'dashboard.user.sites.list.details.files.' + state,
                    views: parseBook({
                        'tab-panel@dashboard.user.sites.list.details.files': {
                            templateUrl: '/modules/common/files/details/files-details.html',
                            controller: 'CommonFilesDetailsController',
                            controllerAs: 'vm',
                        }
                    }),
                    resolve: {
                        fileResponse: function (apiClient, $stateParams) {
                            return apiClient.get($stateParams.fileResource.decode()).then(function (response) {
                                return response || apiClient.noResourceFound();
                            });
                        },
                        config: function(config) {
                            return angular.extend(config, {
                                showStatutoryType: true,
                                showVisibility: true
                            });
                        }
                    },
                    ncyBreadcrumb: {
                        label: '{{ ::$resolves().file.title }}'
                    }
                })
                .state('dashboard.user.sites.list.details.files.' + state + '.details.edit', {
                    url: '/edit',
                    parent: 'dashboard.user.sites.list.details.files.' + state + '.details',
                    views: parseBook({
                        'tab-panel@dashboard.user.sites.list.details.files': {
                            templateUrl: '/modules/common/files/add-edit/files-add-edit.html',
                            controller: 'CommonFilesAddEditController',
                            controllerAs: 'vm',
                        }
                    }),
                    resolve: {
                        sitesCollectionResponse: function() {
                            return {};
                        },
                        config: function(config, rootResourceResponse, user) {
                            if (configParams.entityType === 'Site') {
                                configParams = angular.extend (configParams, {
                                    isJobFile: user.getLinkAttribute('files', 'canSeeJobFiles') ? true : false,
                                    canCreateJobFile: user.getLinkAttribute('files', 'canCreateJobFiles') ? true : false
                                });
                            }

                            return angular.extend(config, {
                                entitySite: null,
                                fileCreateLink: null,
                                showVisibility: true,
                                statutoryTypeAndTagsEnabled: true,
                                statutoryTypesLink: rootResourceResponse.getLink('statutorytypes')
                            }, configParams, {
                                showVisibility: true
                            });
                        }
                    },
                    ncyBreadcrumb: {
                        label: '{{ ::"EDIT" | translate }}'
                    }
                });
        }
    }

    function registerRoutesAdd ($stateProvider) {
        registerRoutesAddAbstract('site-files-list', {entityType: 'Site', showStatutoryType: false});

        function registerRoutesAddAbstract(state, configParams) {
            $stateProvider
                .state('dashboard.user.sites.list.details.files.' + state + '.add', {
                    url: '/add',
                    parent: 'dashboard.user.sites.list.details.files.' + state,
                    views: parseBook({
                        'tab-panel@dashboard.user.sites.list.details.files': {
                            templateUrl: '/modules/common/files/add-edit/files-add-edit.html',
                            controller: 'CommonFilesAddEditController',
                            controllerAs: 'vm'
                        }
                    }),
                    resolve: {
                        fileResponse: function () {
                            return {};
                        },
                        config: function(siteResponse, config, rootResourceResponse, user) {
                            if (configParams.entityType === 'Site') {
                                configParams = angular.extend (configParams, {
                                    isJobFile: user.getLinkAttribute('files', 'canSeeJobFiles') ? true : false,
                                    canCreateJobFile: user.getLinkAttribute('files', 'canCreateJobFiles') ? true : false
                                });
                            }

                            return angular.extend(config, {
                                entitySite: siteResponse,
                                fileCreateLink: siteResponse.getLink('files'),
                                showStatutoryType: true,
                                showVisibility: true,
                                statutoryTypeAndTagsEnabled: true,
                                statutoryTypesLink: rootResourceResponse.getLink('statutorytypes')
                            }, configParams);
                        }
                    },
                    ncyBreadcrumb: {
                        label: '{{ ::"ADD" | translate }}'
                    }
                });
        }
    }

    function registerRoutesDocumentCompliance($stateProvider) {
        $stateProvider
            .state('dashboard.user.sites.list.details.files.document-compliance-list', {
                url: '/document-compliance?documentPage&documentLimit&documentOrder&isExpired&statutoryType&order=documentTypeName',
                parent: 'dashboard.user.sites.list.details.files',
                params: {
                    documentPage: '1',
                    documentLimit: '30',
                    documentOrder: 'name',
                    isExpired: null,
                    name: null,
                    documentStatutoryType: {array: true}
                },
                views: {
                    'tab-panel@dashboard.user.sites.list.details.files': {
                        templateUrl: '/modules/common/files/manage-files/list/document-compliance/document-compliance-list.html',
                        controller: 'CommonDocumentComplianceController',
                        controllerAs: 'vm'
                    }
                },
                resolve: {
                    documentComplianceCollectionResponse: function (apiClient, siteResponse, $stateParams) {
                        var params = angular.extend({}, $stateParams,
                            {
                                page: $stateParams.documentPage,
                                limit: $stateParams.documentLimit,
                                order: $stateParams.documentOrder,
                                'documentStatutoryType[]': $stateParams.documentStatutoryType,
                                name: $stateParams.name,
                                isExpired: $stateParams.isExpired,
                                datagroup: 'list'
                            }
                        );
                        return apiClient.get(siteResponse.getLink('document-compliance'), params).then(function (response) {
                            return response || apiClient.noResourceFound();
                        });
                    },
                    statutoryTypeCollectionResponse: function(apiClient, rootResourceResponse) {
                        return apiClient.get(rootResourceResponse.getLink('statutorytypes')).then(function(response) {
                            return response || apiClient.noResourceFound();
                        });
                    },
                },
                ncyBreadcrumb: {
                    label: '{{ ::"DOCUMENT_COMPLIANCE" | translate }}'
                }
            })
            .state('dashboard.user.sites.list.details.files.document-compliance-list.add', {
                url: '/add-documentType',
                parent: 'dashboard.user.sites.list.details.files.document-compliance-list',
                views: {
                    'tab-panel@dashboard.user.sites.list.details.files': {
                        templateUrl: '/modules/common/files/manage-files/list/document-compliance/add-edit/document-type.add.html',
                        controller: 'DocumentTypeAddController',
                        controllerAs: 'vm'
                    }
                },
                resolve: {
                    documentTypeCollectionResponse : function (apiClient, rootResourceResponse, siteResponse) {
                        return apiClient.get(rootResourceResponse.getLink('documenttypes'), {status: 'active', excludeSiteTypes: true, site: siteResponse.id})
                            .then(function (response) {
                                return response || apiClient.noResourceFound();
                            });
                    },
                    siteDocumentTypeCollection: function (apiClient, siteResponse) {
                        var params = {
                            page: 1,
                            limit: 999999,
                            datagroup: 'search'
                        };
                        return apiClient.get(siteResponse.getLink('document-compliance'), params).then(function (response) {
                            return response || apiClient.noResourceFound();
                        });
                    },

                },
                ncyBreadcrumb: {
                    label: '{{ ::"DOCUMENT_TYPE_ADD" | translate }}'
                }
            });
    }
})();
