(function (){
    'use strict';

    angular
        .module('elogbooks.user.tasks')
        .controller('UserTaskListController', UserTaskListController);

    UserTaskListController.$inject = [
        '$state',
        '$stateParams',
        '$filter',
        'rootResourceResponse',
        'selectedSiteResponse',
        'serviceProviderCollection',
        'statutoryTypeCollectionResponse',
        'siteCollectionResponse',
        'taskCollectionResource',
        'documentTypesCollectionResponse'
];

    function UserTaskListController(
        $state,
        $stateParams,
        $filter,
        rootResourceResponse,
        selectedSiteResponse,
        serviceProviderCollection,
        statutoryTypeCollectionResponse,
        siteCollectionResponse,
        taskCollectionResource,
        documentTypesCollectionResponse
    ) {
        var vm = this;

        vm.tasks = taskCollectionResource ? taskCollectionResource.tasks : [];
        vm.tasksCollection = taskCollectionResource;
        vm.search = search;
        vm.order = order;

        vm.stateParams = $stateParams;
        vm.exportParams = formatParamsForExport(angular.copy($stateParams));

        var statusOptions = [
            { title: $filter('translate')('ALL'), value: 'all' },
            { title: $filter('translate')('STATUS_ACTIVE'), value: 'active' },
            { title: $filter('translate')('STATUS_INACTIVE'), value: 'inactive' }
        ];

        var serviceTypeOptions = [
            {
                title: $filter('translate')('NONE_SELECTED'),
                value: null
            },
            {
                title: $filter('translate')('SOFT_SERVICE'),
                value: '0'
            },
            {
                title: $filter('translate')('HARD_SERVICE'),
                value: '1'
            }
        ];

        vm.statutoryTypesSelect = {
            response: statutoryTypeCollectionResponse,
            link: statutoryTypeCollectionResponse.getLink('self'),
            responseKeyPath: 'statutorytypes',
            itemValuePath: 'name',
            itemHrefPath: 'id'
        };

        vm.siteSelect = {
            response : siteCollectionResponse,
            link : siteCollectionResponse ? siteCollectionResponse.getLink('self') : null,
            responseKeyPath: 'sites',
            itemValuePath: 'name',
            itemHrefPath: 'id',
            disabled: $stateParams.selectedSiteResource
        };

        if (selectedSiteResponse) {
            vm.siteSelect.cacheItems= [{value:selectedSiteResponse.name, href:selectedSiteResponse.id, object:selectedSiteResponse}];
        }

        vm.criteria = {
            siteIds: { type: 'autocompletemulti', multiple: true, title: 'SITE', value: $stateParams.siteIds, options: vm.siteSelect, param: 'siteIds', max: 50 },
            taskName: { type: 'text', value: $stateParams.taskName, title: 'TASK' },
            taskStatus: { type: 'options', title: 'TASK_STATUS', value: $stateParams.taskStatus, options: statusOptions},
            taskServiceType: { type: 'options', title: 'SERVICE_TYPE', value: $stateParams.taskServiceType, options: serviceTypeOptions },
            taskStatutoryType:{ type: 'autocompletemulti', multiple: true, title: 'STATUTORY_TYPE', value: $stateParams.taskStatutoryType, options: vm.statutoryTypesSelect, param: 'taskStatutoryType', max: 50 }
        };

        if (documentTypesCollectionResponse && documentTypesCollectionResponse.count > 0) {
            vm.documentTypeSelect = {
                link : documentTypesCollectionResponse.getLink('self'),
                linkParameters: { status: 'active', order: 'name' },
                responseKeyPath: 'documentTypes',
                itemValuePath: 'name',
                itemHrefPath: 'id'
            };

            vm.criteria.documentTypes = {
                type: 'autocompletemulti',
                multiple: true,
                title: 'DOCUMENT_TYPE',
                options: vm.documentTypeSelect,
                param: 'documentTypes',
                value: $stateParams.documentTypes,
                max: 50
            };
        }

        if (serviceProviderCollection) {
            vm.serviceProviderSelect = {
                response : serviceProviderCollection,
                link : serviceProviderCollection ? serviceProviderCollection.getLink('self') : null,
                responseKeyPath: 'serviceproviders'
            };

            vm.criteria.taskServiceProviderName = { type: 'selectwidget', title: 'SERVICE_PROVIDER', value: $stateParams.taskServiceProviderName, options: vm.serviceProviderSelect };
        }

        vm.resources = {
            'rootResource': rootResourceResponse,
            'stateParams': $stateParams,
            'root': rootResourceResponse
        };

        /**
         * Search tasks
         */
        function search(params) {
            var override = {
                page: 1
            };

            $state.go('.', angular.extend({}, params, override), { reload: $state.current });
        }

        /**
         * Order tasks
         */
        function order(key) {
            $stateParams.order = key;

            $state.go('.', angular.extend({}, $stateParams), { reload: $state.current });
        }

        function formatParamsForExport(exportParams) {
            if (exportParams.siteIds) {
                exportParams['siteIds[]'] = exportParams.siteIds;
            }
            if (exportParams.statutoryType) {
                exportParams['statutoryType[]'] = exportParams.taskStatutoryType;
                delete exportParams.statutoryType;
            }
            if (exportParams.regionId) {
                exportParams.regionId = JSON.parse(exportParams.regionId).v;
            }

            if (exportParams.assetId) {
                exportParams.assetId = JSON.parse(exportParams.taskAssetId).v;
            }

            return exportParams;
        }
    }
})();
