angular.module('elogbooksServices').service('reportTotalsService', function ($q, apiClient, lodash) {
    this.getTotalsData = getTotalsData;
    this.getCachedTotals = getCachedTotals;
    this.compareStateParams = compareStateParams;
    this.isCachedDataValid = isCachedDataValid;

    var totals = {};
    var previousStateParams = {};

    function isCachedDataValid($stateParams) {
        if (compareStateParams($stateParams)) {
            return false;
        }
        return true;
    }

    function getCachedTotals() {
        return totals;
    }

    function getTotalsData($stateParams, route) {
        if (lodash.isEmpty(totals) || compareStateParams($stateParams)) {
            previousStateParams = $stateParams;

            if (route === '/reports/sp-summary-totals') {
                return apiClient.get('/reports/sp-summary-totals', $stateParams).then(function (response) {
                    totals = response;
                    return response;
                });
            } else if(route === '/reports/sp-summary') {
                return apiClient.get('/reports/sp-summary', $stateParams).then(function (response) {
                    totals = response;
                    return response;
                });
            } else {
                return apiClient.get('/reports/kpi-monthly-average', $stateParams).then(function (response) {
                    totals = response;
                    return response;
                });
            }
        } else {
            return totals;
        }
    }

    function compareStateParams($stateParams) {
        //strip page number and showPercentages as it will invalidate the comparison
        var previous = angular.copy(previousStateParams);
        var current = angular.copy($stateParams);

        if (previous.page) {
            delete previous.page;
        }

        if (previous.showPercentages) {
            delete previous.showPercentages;
        }

        if (current.page) {
            delete current.page;
        }

        if (current.showPercentages) {
            delete current.showPercentages;
        }

        return JSON.stringify(previous) !== JSON.stringify(current);
    }
});
