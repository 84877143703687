angular.module('elogbooksServices').service('sitePermissions', function(filterPermissions) {

    var permissions = [
        { name: 'site_permission_view' },
        { name: 'site_permission_edit' },
        { name: 'site_permission_view_approvers' },
        { name: 'site_permission_edit_approvers' },
        { name: 'site_permission_view_locations' },
        { name: 'site_permission_edit_locations' },
        { name: 'site_permission_view_priorities' },
        { name: 'site_permission_edit_priorities' },
        { name: 'site_permission_view_associates' },
        { name: 'site_permission_edit_associates' },
        { name: 'site_permission_view_partnerships' },
        { name: 'site_permission_edit_partnerships' },
        { name: 'site_permission_view_contacts' },
        { name: 'site_permission_edit_contacts' },

        { name: 'site_permission_view_assets' },
        { name: 'site_permission_edit_assets' },
        { name: 'site_permission_create_assets' },
        { name: 'site_permission_view_meters' },
        { name: 'site_permission_edit_meters' },
        { name: 'site_permission_edit_meter_readings' },
        { name: 'site_permission_view_jobs' },
        { name: 'site_permission_edit_jobs' },
        { name: 'site_permission_create_jobs' },
        { name: 'site_permission_can_be_approver' },
        { name: 'site_permission_can_be_associate' },
        { name: 'site_permission_view_audits' },
        { name: 'site_permission_edit_audit_reference' },
        { name: 'site_permission_create_audits' },
        { name: 'site_permission_create_audit_templates' },

        { name: 'site_permission_view_contracts' },
        { name: 'site_permission_edit_contracts' },
        { name: 'site_permission_create_contracts' },

        { name: 'site_permission_tenant' },
        { name: 'site_permission_tenant_plus' },
        { name: 'site_permission_helpdesk' },
        { name: 'site_permission_can_approve_all_quotes' },
        { name: 'site_permission_can_approve_quote_extension_requests' },
        { name: 'site_permission_can_approve_all_jobs' },
        { name: 'site_permission_can_approve_job_extension_requests' },
        { name: 'site_permission_submit_p2p_job_po' },
        { name: 'site_permission_view_tenants' },
        { name: 'site_permission_edit_tenants' },
        { name: 'site_permission_view_patrols' },
        { name: 'site_permission_manage_patrols' },
        { name: 'site_permission_view_actions' },
        { name: 'site_permission_edit_actions' },
        { name: 'site_permission_create_actions' },
        { name: 'site_permission_view_quotes' },
        { name: 'site_permission_edit_quotes' },
        { name: 'site_permission_create_quotes' },
        { name: 'site_permission_view_tasks' },
        { name: 'site_permission_edit_tasks' },
        { name: 'site_permission_cancel_task_events' },
        { name: 'site_permission_create_tasks' },
        { name: 'site_permission_view_files' },
        { name: 'site_permission_edit_files' },
        { name: 'site_permission_download_files' },

        { name: 'site_permission_view_surveys' },
        { name: 'site_permission_edit_surveys' },
        { name: 'site_permission_create_surveys' },

        { name: 'site_permission_view_survey_schedules' },
        { name: 'site_permission_edit_survey_schedules' },
        { name: 'site_permission_create_survey_schedules' },

        { name: 'site_permission_view_patrol_instances' },
        { name: 'site_permission_edit_patrol_instances' },
        { name: 'site_permission_add_patrol_instances' },
        { name: 'site_permission_view_notes' },
        { name: 'site_permission_edit_notes' },

        { name: 'site_permission_email_tenants' },
        { name: 'site_permission_view_email_logs' },
        { name: 'site_permission_force_complete_audits' },
        { name: 'site_permission_p2p' },
        { name: 'site_permission_p2p_process_nominal_codings'},
        { name: 'site_permission_asset_user_imports' },
        { name: 'site_permission_action_user_imports' },
        { name: 'site_permission_task_user_imports' },
        { name: 'site_permission_asset_task_user_imports' },
        { name: 'site_permission_task_user_imports' },
        { name: 'site_permission_meter_readings_user_imports' },
        { name: 'site_permission_job_operative' },

        { name: 'site_permission_bulk_reassign_jobs' },
        { name: 'site_permission_bulk_reassign_operative_jobs' },
        { name: 'site_permission_bulk_cancel_jobs' },
        { name: 'site_permission_bulk_cancel_actions' },

        { name: 'site_permission_view_audit_templates' },
        { name: 'site_permission_create_public_audit_templates' },
        { name: 'site_permission_view_audit_schedules' },
        { name: 'site_permission_create_audit_schedules' },

        { name: 'site_permission_edit_meter_references' },

        { name: 'site_permission_write_nfc_tags' },

        { name: 'site_permission_activate_survey' },

        { name: 'site_permission_notify_updates' },

        { name: 'site_permission_view_link_jobs' },
        { name: 'site_permission_remove_link_jobs' },

        { name: 'site_permission_edit_score_types_on_asset' },
        { name: 'site_permission_open_close' },
        { name: 'site_permission_view_open_close' },

        { name: 'site_permission_create_spillages' },
        { name: 'site_permission_edit_spillages' },
        { name: 'site_permission_view_spillages' },
        { name: 'site_permission_can_cancel_patrols' },

        { name: 'site_permission_view_filter_bar' },

        { name: 'site_permission_bulk_notify_files' },
        { name: 'site_permission_view_job_files' },
        { name: 'site_permission_edit_job_files' },

        { name: 'site_permission_view_waste_entries' },
        { name: 'site_permission_create_waste_entries' },
        { name: 'site_permission_edit_waste_entries' },
        { name: 'site_permission_import_waste_entries' },
        { name: 'site_permission_create_audit_answer_options' },
        { name: 'site_permission_edit_audit_answer_options' },
        { name: 'site_permission_view_audit_answer_options' },
        { name: 'site_permission_create_webhooks' },
        { name: 'site_permission_edit_webhooks' },
        { name: 'site_permission_view_webhooks' },

        { name: 'site_permission_quote_value_approval' },
        { name: 'site_permission_approve_all_quotes_pending_value_approval' },

        { name: 'site_permission_cancel_any_job' },

        { name: 'site_permission_edit_po_number' },

        { name: 'site_permission_view_sales_invoice', module: 'finance' },
        { name: 'site_permission_edit_sales_invoice', module: 'finance' },
        { name: 'site_permission_create_sales_invoice', module: 'finance' },
        { name: 'site_permission_can_add_additional_operatives', module: 'secondary_operatives' },

        { name: 'site_permission_reopen_job' },
        { name: 'site_permission_view_all_service_providers' },
        { name: 'site_permission_receive_quote_submission_notifications' },
        { name: 'site_permission_receive_job_creation_notifications' }
    ];

    this.getAll = function () {
        return filterPermissions.filterPermissions(permissions, 'VALUE');
    };

    this.getAllTranslated = function () {
        return filterPermissions.filterPermissions(permissions, 'TRANSLATED');
    };

    return this;
});
