(function() {
    'use strict';

    angular
        .module('elogbooks')
        .config(Translations);

    function Translations($translateProvider) {
        $translateProvider.translations('nl', {
            FREQUENCY_YEARLY: '{{ ::interval }} Jaarlijks',
            FREQUENCY_MONTHLY: '{{ ::interval }} Maandelijks',
            FREQUENCY_WEEKLY: '{{ ::interval }} Weekelijks',

            FREQUENCY_WEEKS: '{{ ::interval }} {{ interval == "1" ? "Week" : "Weken" }}',
            FREQUENCY_DAYS: '{{ ::interval }} {{ interval == "1" ? "Dag" : "Dagen" }}',
            FREQUENCY_HOURS: '{{ ::interval }} {{ interval == "1" ? "Uur" : "Uren" }}',
            FREQUENCY_MINUTES: '{{ ::interval }} {{ interval == "1" ? "Minuut" : "Minuten" }}',
            FREQUENCY_SECONDS: '{{ ::interval }} {{ interval == "1" ? "Seconde" : "Seconden" }}',
        });
    }
})();
