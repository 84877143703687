(function() {
    'use strict';

    angular
        .module('elogbooks.common.webhooks')
        .controller('WebhooksAddEditController', WebhooksAddEditController);

    WebhooksAddEditController.$inject = [
        'config',
        '$scope',
        '$state',
        '$translate',
        'apiClient',
        'requestDataFactory',
        'messenger',
        'lodash',
        'webhookResponse',
        'webhookCreateLink'
    ];

    function WebhooksAddEditController(
        config,
        $scope,
        $state,
        $translate,
        apiClient,
        requestDataFactory,
        messenger,
        lodash,
        webhookResponse,
        webhookCreateLink
    ) {

        var vm = this;
        vm.webhook = webhookResponse;
        vm.config = config;
        vm.data = requestDataFactory.createRequest(getDefaults());
        vm.noBorder = vm.config.noBorder || false;

        vm.create = createAction;
        vm.update = updateAction;

        vm.urlPattern = "https?:\/\/(www\.)?[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\\b([-a-zA-Z0-9()@:%_\+.~#?&//=]*)";

        vm.completePeriodOptions = {
            disallowTimeOfDay: false
        };

        function getDefaults() {
            var defaults = {
                id: vm.webhook.id || null,
                url: vm.webhook.url || null,
                headers: vm.webhook.headers || [],
                active: vm.webhook.active
            };

            return defaults;
        }

        function createAction() {
            var postData = angular.copy(vm.data);

            apiClient.create(webhookCreateLink, postData).then(function (response) {
                if (response) {
                    $state.go('^', {}, { reload: $state.get('^.^') }).then(function () {
                        messenger.success('WEBHOOK_CREATED');
                    });
                } else {
                    messenger.error('REQUEST_ERROR');
                }
            });
        }

        function updateAction() {
            var postData = angular.copy(vm.data);

            apiClient.update(vm.webhook.getLink('edit'), postData).then(function (response) {
                if (response) {
                    $state.go('^', {}, { reload: $state.get('^.^.^') }).then(function () {
                        messenger.success('WEBHOOK_UPDATED');
                    });
                } else {
                    messenger.error('REQUEST_ERROR');
                }
            });
        }
    }
})();
