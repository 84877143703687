angular.module('elogbooksServices').service('ssoService', function (apiClient, oktaService, azureService, $window) {

    'use strict';

    var self = this;
    self.ssoService = null;
    self.authorise = authorise;
    self.login = login;
    self.renewToken = renewToken;
    self.forceUserToLinkAccount = forceUserToLinkAccount;
    self.isUserRequiredToLoginWithSso = isUserRequiredToLoginWithSso;
    var storedSsoParams = JSON.parse($window.localStorage.getItem('ssoParameters'));

    if (!storedSsoParams) {
        apiClient.get('/sso/params').then(function (ssoParams) {
            if (typeof ssoParams.type !== 'undefined') {
                initService(ssoParams);
            }
        });
    } else {
        initService(storedSsoParams);
    }

    function initService(params) {
        if (params.type === 'okta') {
            oktaService.setSsoParams(params);
            self.ssoService = oktaService;
        } else if (params.type === 'azure') {
            self.ssoService = azureService;
        }
    }

    function isUserRequiredToLoginWithSso() {
        return apiClient.get('/sso/sso-login-required');
    }

    function forceUserToLinkAccount(uri) {
        if (self.ssoService) {
            self.ssoService.forceUserToLinkAccount(uri);
        }
    }

    function authorise() {
        self.ssoService.authorise();
    }

    function login() {
        self.ssoService.login();
    }

    function renewToken() {
        self.ssoService.renewToken();
    }
});
