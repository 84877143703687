angular.module('elogbooksServices').service('bulkCancelTasksModal', ['apiClient', '$uibModal', function (apiClient, $uibModal) {
    return {
        open: function (requestData, url, taskCount) {
            return $uibModal.open({
                templateUrl: '/modules/common/modals/bulk-update/bulkaction-modal.html',
                controller: 'BulkActionCreateController',
                controllerAs: 'vm',
                resolve: {
                    config: function () {
                        var defaultConfig = {
                            titleMessage: 'CANCEL_TASKS',
                            updateMessage: 'TASKS_QUEUED_FOR_CANCELLATION',
                            bodyMessage: 'ARE_YOU_SURE',
                            selected: !requestData.allTasks,
                            bulkAsssigntype: 'cancel-tasks',
                            requestData: requestData,
                            url: url,
                            taskCount: taskCount,
                            noteRequired: true
                        };
                        return defaultConfig;
                    },
                    serviceProviderResponse:function() {
                        return null;
                    },
                    jobs: function() {
                        return null;
                    },
                    jobsCount: function() {
                        return null;
                    },
                    selectedSite: function() {
                        return null;
                    },
                    selectedSP: function() {
                        return null;
                    },
                    rootResourceResponse: function() {
                        return null;
                    },
                    user: function() {
                        return null;
                    },
                    canBulkReassignOperative: function() {
                        return null;
                    }
                }
            });
        }
    };
}]);
