/**
 * JobParamsService
 */
angular.module('elogbooksServices').service('jobParamsService', function () {

    this.getJobParams = getJobParams;

    /**
     * Format state params into expected job filter params for api
     *
     * @param {object} stateParams - State params
     * @returns {object} params
     */
    function getJobParams($stateParams, user, selectedSiteResponse) {

        // remove status from stateparams passedto jobparams
        var originalParams = angular.copy($stateParams);
        delete originalParams.status;

        var params = angular.extend(
            {},
            originalParams,
            {
                page: $stateParams.jobsPage,
                limit: $stateParams.jobsLimit,
                order: $stateParams.jobsOrder,

                id: $stateParams.jobId,
                type: $stateParams.jobsType,
                'sites[]': $stateParams.siteIds,
                'site': $stateParams.site,
                'status[]': $stateParams.jobStatus,
                'assetTypes[]': $stateParams.assetTypes,
                'statType[]': $stateParams.statType,
                'jobPriority[]': $stateParams.jobPriority,
                'jobApprover[]': $stateParams.approver,
                'siteRegion[]': $stateParams.jobSiteRegion,
                'serviceProviders[]': $stateParams.serviceProviders,
                'jobGroups[]': $stateParams.jobGroups,
                'siteGroups[]': $stateParams.jobSiteGroups,
                'sectors[]': $stateParams.sectors,
                chargeType: $stateParams.chargeType,
                overdueAttendance: $stateParams.jobsOverdueAttendance,
                overdueCompletion: $stateParams.jobsOverdueCompletion,
                statutory: $stateParams.jobsStatutory,
                attendanceDueAtBetween: $stateParams.jobsAttendanceDueAtBetween,
                completionDueAtBetween: $stateParams.jobsCompletionDueAtBetween,
                serviceProvider: $stateParams.jobsServiceProvider,
                reminderAtBetween: $stateParams.jobsReminderAtBetween,
                iAmTheCurrentApprover: $stateParams.iAmTheCurrentApprover,
                iAmTheExtensionApprover: $stateParams.iAmTheExtensionApprover,
                jobsReminderAtEnd: $stateParams.jobsReminderAtEnd,
                jobsAttendanceDueStart: $stateParams.jobsAttendanceDueStart,
                jobsAttendanceDueEnd: $stateParams.jobsAttendanceDueEnd,
                completionDueStart: $stateParams.completionDueStart,
                completionDueEnd: $stateParams.completionDueEnd,
                overdueAttendancePerformance: $stateParams.jobsOverdueAttendancePerformance,
                asset: $stateParams.asset,
                subAsset: $stateParams.subAsset,
                fromJob: $stateParams.fromJob,
                completedAtStart: $stateParams.completedAtStart,
                completedAtEnd: $stateParams.completedAtEnd,
                remedial: $stateParams.jobsRemedial,
                'documentTypes[]': $stateParams.documentTypes,
                hasDocumentType: $stateParams.jobsHasDocumentType,
                'prosureStatusAtCompletion[]': $stateParams.prosureStatusAtCompletion,
                'prosureStatusAtApproval[]': $stateParams.prosureStatusAtApproval
            }
        );

        //Set the approver as the current user when transferring from dashboard
        if ($stateParams.jobApprover) {
            if ($stateParams.jobApprover[0] === 'true') {
                params['jobApprover[]'] = user.id;
            }
        }

        // override site filter if selectedsite is set
        if (selectedSiteResponse) {
            params["sites[]"] = selectedSiteResponse.id;
            params.site = selectedSiteResponse.id;
        }

        return params;
    }
});
