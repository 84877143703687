/**
 * Survey versioning
 */
 angular.module('elogbooksDirectives').directive('versioning', [function () {
    return {
        restrict: 'AE',
        require: ['ngModel'],
        scope: {
            survey: '=ngModel',
            surveyVersionsResponse: '=',
            selectedVersion: '=',
            tabHeader: '@',
            versionDependent: '@',
            deleteAction: '=',
            onReset: '=',
            linesHaveChanged: '='
        },
        templateUrl:'/modules/directives/survey/versioning/versioning.html',
        controller: function ($scope, $state, apiClient, confirmationModal, messenger, base64) {
            $scope.createNewVersion = createNewVersion;
            $scope.activateVersion = activateVersion;
            $scope.deactivateVersion = deactivateVersion;
            $scope.loadVersion = loadVersion;

            if (angular.isUndefined($scope.versionDependent)) {
                $scope.versionDependent = false;
            }

            $scope.surveyHasSites = $scope.survey.hasOwnProperty('sites') && $scope.survey.sites.length > 0;

            function loadVersion(selectedVersion) {
                $state.go($state.current.name, {resource: base64.encode(selectedVersion.href)}, { reload: $state.current.parent });
            }

            function createNewVersion() {
                return confirmationModal.open().result.then(function(response) {
                    return apiClient.create($scope.survey.getLink('create-version'), {}).then(function(createResponse) {
                        if (createResponse) {
                            $state.go('.', {resource: createResponse.getLink('self').encode()}, {reload: $state.get('^.^')}).then(function() {
                                messenger.success('NEW_VERSION_CREATED');
                            });
                        } else {
                            messenger.error('REQUEST_ERROR');
                        }
                    });
                });
            }

            function activateVersion() {
               return confirmationModal.open().result.then(function(response) {
                    return apiClient.create($scope.survey.getLink('activate-version'), {}).then(function(activateResponse) {
                        if (activateResponse) {
                            $state.go('.', {resource: activateResponse.getLink('self').encode()}, {reload: $state.get('^.^')}).then(function() {
                                messenger.success('SURVEY_VERSION_ACTIVATED');
                            });
                        } else {
                            messenger.error('REQUEST_ERROR');
                        }
                    });
                });
            }

            function deactivateVersion() {
               return confirmationModal.open().result.then(function(response) {
                    return apiClient.create($scope.survey.getLink('deactivate-version'), {}).then(function(deactivateResponse) {
                        if (deactivateResponse) {
                            $state.go('.', {resource: deactivateResponse.getLink('self').encode()}, {reload: $state.get('^.^')}).then(function() {
                                messenger.success('SURVEY_VERSION_DEACTIVATED');
                            });
                        } else {
                            messenger.error('REQUEST_ERROR');
                        }
                    });
                });
            }
        }
    };
}]);