(function() {
    'use strict';

    angular
        .module('elogbooks.admin.billing-types')
        .controller('BillingTypesAddEditController', BillingTypesAddEditController);

    BillingTypesAddEditController.$inject = ['$state', 'apiClient', 'confirmationModal', 'messenger', 'requestDataFactory', 'billingTypeResponse', 'billingTypesCollectionResponse'];

    function BillingTypesAddEditController($state, apiClient, confirmationModal, messenger, requestDataFactory, billingTypeResponse, billingTypesCollectionResponse) {

        var vm = this;
        vm.billingType = billingTypeResponse;
        vm.data = requestDataFactory.createRequest(vm.billingType);
        vm.billingTypesCollectionResponse = billingTypesCollectionResponse;
        vm.create = createAction;
        vm.update = updateAction;
        vm.delete = deleteAction;

        function updateAction() {
            return apiClient.update(vm.billingType.getLink('edit'), vm.data).then(function(response) {
                if (response) {
                    $state.go('^', {}, {reload: true}).then(function() {
                        messenger.success('BILLING_TYPE_UPDATED');
                    });
                } else {
                    messenger.error('REQUEST_ERROR');
                }
            });
        }

        function createAction() {
            return apiClient.create(vm.billingTypesCollectionResponse.getLink('create'), vm.data).then(function(response) {
                if (response) {
                    $state.go('dashboard.admin.billing-types.list', {resource: response.getLink('self').encode()}, {reload: $state.current.parent}).then(function() {
                        messenger.success('BILLING_TYPE_CREATED');
                    });
                } else {
                    messenger.error('REQUEST_ERROR');
                }
            });
        }

        function deleteAction() {
            return confirmationModal.open().result.then(function(response) {
                if (response.result) {
                    apiClient.delete(vm.billingType.getLink('delete')).then(function(isSuccess) {
                        if (isSuccess) {
                            $state.go('dashboard.admin.billing-types.list', {page: 1}, {reload: 'dashboard.admin.billing-types.list'}).then(function() {
                                messenger.success('BILLING_TYPE_DELETED');
                            });
                        } else {
                            messenger.error('REQUEST_ERROR');
                        }
                    });
                }
            });
        }
    }
})();
