angular.module('elogbooksServices').service('jobModalService', function(
    $uibModal,
    $state,
    messenger,
    auditService,
    elbSettings
) {

    'use strict';

    this.raiseJob = raiseJobModal;

    function raiseJobModal($scope, viaAnswer, getJobRemedials) {
        var rootResourceResponse = $scope.model ? $scope.model.rootResource : $scope.vm.rootResourceResponse ;
        var siteResponse = $scope.model ? $scope.model.siteResponse : $scope.vm.siteResponse;
        var user = $scope.model ? $scope.model.user : $scope.vm.user;
        var config = $scope.model ? $scope.model.config : null;

        $uibModal.open({
            templateUrl: '/modules/common/jobs/modal/job-add-modal.html',
            controller: 'JobAddFormModalController',
            controllerAs: 'vm',
            resolve: {
                sourceJobAsset: function () {
                    return $scope.model && $scope.model.sourceJobAsset? $scope.model.sourceJobAsset : null;
                },
                jobGroupCollectionResponse: function (apiClient) {
                    return apiClient.get(rootResourceResponse.getLink('jobgroups'), { order: name, fetchHierarchy: false }).then(function (response) {
                        return response || apiClient.noResourceFound();
                    });
                },
                partnershipCollectionResponse: function (apiClient) {
                    var params = {active: 1};

                    if (elbSettings.getSetting('prosure_enabled').value) {
                        params = angular.extend({order: 'prosureStatus'}, params);
                    }

                    if (siteResponse.getLink('partnerships')) {
                        return apiClient.get(siteResponse.getLink('partnerships'), params).then(function (response) {
                            return response || apiClient.noResourceFound();
                        });
                    } else if (user.getLink('partnerships')) {
                        params = angular.extend({siteId: siteResponse.id}, params);

                        return apiClient.get(user.getLink('partnerships'), params).then(function (response) {
                            return response || apiClient.noResourceFound();
                        });
                    }
                },
                settingCollectionResponse: function (apiClient) {
                    return apiClient.get(rootResourceResponse.getLink('jobsettings')).then(function (response) {
                        return response || apiClient.noResourceFound();
                    });
                },
                settingsCollectionResponse: function (apiClient) {
                    return apiClient.get(rootResourceResponse.getLink('settings')).then(function (response) {
                        return response || apiClient.noResourceFound();
                    });
                },
                jobGroupRequired: function (apiClient) {
                    return apiClient.get(rootResourceResponse.getLink('jobsettings')).then(function (response) {
                        return response ? response.requireJobGroup : apiClient.noResourceFound();
                    });
                },
                siteResourceResponse: function () {
                    return siteResponse;
                },
                siteRelationshipResponse: function () {
                    return rootResourceResponse.getResource('siterelationships');
                },
                userResponse: function () {
                    return user;
                },
                questionResponse: function () {
                    return $scope.context === 'audit_question' ? $scope.question : null;
                },
                sectionResponse: function () {
                    if ($scope.context === 'audit_question' && $scope.section) {
                        return $scope.section;
                    } else if ($scope.context === 'audit_question' && $scope.vm.section) {
                        return $scope.vm.section;
                    } else {
                        return null;
                    }
                },
                locationCollectionResponse: function (apiClient) {
                    return apiClient.get(siteResponse.getLink('locations')).then(function (response) {
                        return response || apiClient.noResourceFound();
                    });
                },
                statutoryTypeCollectionResponse: function (apiClient) {
                    return apiClient.get(rootResourceResponse.getLink('statutorytypes')).then(function (response) {
                        return response || apiClient.noResourceFound();
                    });
                },
                assetsCollectionResponse: function (apiClient) {
                    if (siteResponse.getLink('assets')) {
                        return apiClient.get(siteResponse.getLink('assets'), {active:1}).then(function (response) {
                            return response;
                        });
                    }
                },
                raiseRemedialJob: function () {
                    return $scope.context === 'complete_job';
                },
                sourceJob: function () {
                    return $scope.context === 'complete_job' ? $scope.model.sourceJob : null;
                },
                config: function() {
                    if (config) {
                        return angular.extend(config, {
                            isHelpdesk: config.isHelpdesk,
                            isTenant: config.isTenant,
                            showSiteNotes: config.isHelpdesk,
                            addReporter: config.isHelpdesk,
                            link: null,
                            siteDisabled: (siteResponse)
                        });
                    }
                },
                documentTypesCollectionResponse: function (apiClient) {
                    if (rootResourceResponse.getLink('documenttypes')) {
                        return apiClient.get(rootResourceResponse.getLink('documenttypes'), {status: 'active', limit: 999}).then(function (response) {
                            return response;
                        });
                    }
                }
            }
        }).result.then(function () {
            if ($scope.context === 'audit_question' && viaAnswer) {
                auditService.sendAnswer($scope.question);
            } else {

                if (getJobRemedials) {
                    // find the function on the controller
                    $scope.$parent.$parent.$parent.$parent.vm.getJobRemedials(true, true).then(function(success) {
                        // this is so we don't have have to request the jobResponse again.
                        $scope.$parent.$parent.$parent.$parent.vm.remedialsCount += 1;
                    });
                }
                var state = $scope.context === 'complete_job' ? false : $state.current.parent;
                $state.go('.', {}, {reload: state}).then(function () {
                    messenger.success('JOB_CREATED');
                    if ($scope.context === 'audit_question') {
                        auditService.auditAnswerFlashRaiseJobs.splice(auditService.auditAnswerFlashRaiseJobs.indexOf($scope.question.getLink('self')));
                    }
                });
            }
        });
    }
});
