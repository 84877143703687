(function() {
    // 'use strict';

    angular
        .module('elogbooks.common.actions')
        .controller('ActionRejectJobPaperworkController', Controller('REJECT_JOB_PAPERWORK', 'reject-job-paperwork', true, false))
        .controller('ActionCancelController', Controller('CANCEL', 'cancel', true, false))
        .controller('ActionCompleteController', Controller('COMPLETE', 'complete', true, false))
        .controller('ActionReopenController', Controller('REOPEN', 'reopen', true, false))
        .controller('ActionReassignController', Controller('REASSIGN', 'reassign', true, 'reassign'))
    ;

    function Controller(translation, action, noteRequired, partial) {
        return function(
            apiClient,
            $state,
            messenger,
            user,
            actionResponse,
            requestDataFactory,
            $scope,
            scopeData,
            elbSettings,
            fileCollectionResponse,
            lodash,
            integrationMeridianDocTypesResponse,
            integrationsService
        ) {
            var vm = this;
            vm.actionResponse = actionResponse;
            vm.action = action;
            vm.translation = 'ACTION_WORKFLOW_' + translation;
            vm.newMeridianWorkflow = false;
            vm.showFiles = false;
            vm.fileCollectionResponse = fileCollectionResponse;
            vm.integration = {};
            vm.skipIntegration = {};
            vm.disableIntegration = {};
            vm.selectModel = {};
            vm.meridianDocType = {};
            vm.nextInspectionDate = {};
            vm.skipDocType = skipDocType;
            vm.loading = false;

            vm.siteIsMeridianEnabled = !!lodash.find(fileCollectionResponse.integrations, {type: 'meridian'});

            if (actionResponse.paperworkForReview && vm.action === 'complete') {
                var setting = elbSettings.getSetting('general_actions_paperwork_note_required');
                vm.noteRequired = setting.value;
                vm.newMeridianWorkflow = elbSettings.getSetting('general_meridian_workflow').value;
            } else {
                vm.noteRequired = noteRequired;
            }

            vm.partial = (typeof partial === 'undefined' ? vm.action : partial);
            vm.data = requestDataFactory.createRequest();
            vm.submit = submitAction;

            angular.extend(vm, scopeData);

            if (vm.selectedUserModel) {
                vm.selectedUserModel.onSelect = vm.selectedUserModel.onRemove = changeUser;

                function changeUser() {
                    if (vm.selectedUserModel.selected && vm.selectedUserModel.selected.object) {
                        vm.data._links.assignee = {href: vm.selectedUserModel.selected.href};
                    } else {
                        delete vm.data._links.assignee;
                    }
                }
            }

            if (vm.newMeridianWorkflow && vm.siteIsMeridianEnabled && integrationMeridianDocTypesResponse) {
                vm.integration = integrationMeridianDocTypesResponse;
                vm.showFiles = !!vm.integration;
                angular.forEach(vm.fileCollectionResponse.files, function(file){
                    if (file.integrations.length > 0 && file.integrations.some(integration => integration.hasOwnProperty('type') && integration.type === 'meridian')) {
                        vm.skipIntegration[file.id] = true;
                        vm.disableIntegration[file.id] = true;
                        vm.selectModel[file.id] = null;
                        vm.meridianDocType[file.id] = null;
                    }

                    vm.nextInspectionDate[file.id] = new Date(file.documentDate);

                    integrationsService.addMeridianDefaultDocTypes(vm.integration, vm.actionResponse);

                    if (vm.integration.defaultDocType && vm.integration.defaultDocType2) {
                        vm.selectModel[file.id] = vm.integration.defaultDocType;
                        vm.meridianDocType[file.id] = vm.integration.defaultDocType2;
                    }
                });

                vm.documentDateMaxDate = new Date();
            }

            function skipDocType(index) {
                vm.skipIntegration[index] = !vm.skipIntegration[index];
                vm.selectModel[index] = null;
                vm.meridianDocType[index] = null;
            }

            function submitAction() {
                vm.data.files = [];
                if (vm.showFiles) {
                    angular.forEach(vm.fileCollectionResponse.files, function(file){
                        if (!vm.skipIntegration[file.id]) {
                            file.integration = {meridian: vm.meridianDocType[file.id]};
                            let link = {
                                file: file._links.self,
                                action: vm.actionResponse._links.self
                            };
                            file.nextInspectionDate = vm.nextInspectionDate[file.id];
                            file.documentDate = vm.nextInspectionDate[file.id];
                            file._links = link;
                            vm.data.files.push(file);
                        }
                    });
                }

                return apiClient.create(vm.actionResponse.getLink(action), vm.data).then(function(response) {
                    if (response) {
                        $state.go('^', {}, { reload: $state.get('^.^.^.^') }).then(function() {
                            messenger.success(vm.translation + '_SUCCESS');
                        });
                    } else {
                        messenger.error('REQUEST_ERROR');
                    }
                });
            }
        }
    }
})();
