app.config( function($translateProvider) {
    $translateProvider.translations('en-gb', {
        METER_SUPPLY_INFORMATION: 'Meter Supply Information',

        ADD_NEW_METER_SUPPLY: 'Add a new Meter Supply',
        EDIT_METER_SUPPLY: 'Edit Meter Supply',

        METER_SUPPLY_LIST: 'Meter Supplies',
        METER_SUPPLIES_LIST: 'Meter Supplies',
        METER_SUPPLY_ADD: 'Add a new Meter Supply',
        METER_SUPPLY_EDIT: 'Edit Meter Supply',

        METER_SUPPLY_CREATED: 'Meter Supply created successfully',
        METER_SUPPLY_UPDATED: 'Meter Supply updated successfully',
        METER_SUPPLY_DELETED: 'Meter Supply deleted successfully',
    });
});
