/**
 * Contact Information : Site Address
 */
angular.module('elogbooksControllers').controller('SiteAddressController', function ($rootScope, $scope, $state, apiClient, confirmationModal, messenger, $timeout, contactInformationResponse) {

    $scope.key = $scope.$state.params.addressKey;

    var contactInformation = contactInformationResponse.getData();

    $scope.address = contactInformation.addresses ? contactInformation.addresses[$scope.key] : null;

    $scope.addAddress = function () {
        if ($scope.key) {
            contactInformation.addresses[$scope.key] = $scope.address;
        } else {
            if ('undefined' === typeof contactInformation.addresses) {
                 contactInformation.addresses = [];
            }
            contactInformation.addresses.push($scope.address);
        }
        var data = angular.copy(contactInformation);
        apiClient.update(contactInformationResponse.getLink('self'), data).then(function (response) {
            if (response) {
                $state.go($rootScope.previousState, $rootScope.previousStateParams, { reload: true }).then(function () {
                    messenger.success('CONTACT_ADDRESS_UPDATED');
                });
            } else {
                messenger.error('REQUEST_ERROR');
            }
        });
    };

    $scope.delete = function () {
        return confirmationModal.open().result.then(
            function () {
                contactInformation.addresses.splice($scope.key, 1);
                return apiClient.update(contactInformationResponse.getLink('self'), contactInformation).then(function (response) {
                    if (response) {
                        $state.go($rootScope.previousState, $rootScope.previousStateParams, {reload: true}).then(function() {
                            messenger.success('CONTACT_ADDRESS_DELETED');
                        });
                    } else {
                        messenger.error('REQUEST_ERROR');
                    }
                });
            },
            function () {
                return;
            }
        );
    };

});
