angular.module('elogbooksServices').service('showPercentagesService', function () {

    this.saveShowPercentageState = saveShowPercentageState;
    this.getShowPercentageState = getShowPercentageState;

    var showPercentages = null;

    function saveShowPercentageState($state) {
        showPercentages = $state;
    }

    function getShowPercentageState() {
        if (showPercentages === null) {
            showPercentages = false;
        }
        return showPercentages;
    }
});
