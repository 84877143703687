(function () {
    'use strict';

    angular
        .module('elogbooks.common.meter-meters')
        .controller('CommonMeterAddEditReadingController', ['$state', '$stateParams', 'messenger', 'apiClient', 'meterResponse', 'meterReadingResponse', CommonMeterAddEditReadingController]);

    CommonMeterAddEditReadingController.$inject = ['$state', '$stateParams', 'messenger', 'apiClient', 'meterResponse', 'meterReadingResponse'];

    function CommonMeterAddEditReadingController($state, $stateParams, messenger, apiClient, meterResponse, meterReadingResponse) {
        var vm = this;
        vm.meter = meterResponse.getData();
        vm.reading = meterReadingResponse;
        vm.reading.readAt = vm.reading.readAt ? new Date(vm.reading.readAt) : new Date();
        vm.reading.reading = parseFloat(vm.reading.reading, 10);
        vm.reading.type = (vm.reading.type || '').toString();
        vm.createReading = createReading;
        vm.updateReading = updateReading;
        vm.fileName = meterResponse.id + "_" + moment(new Date()).format("YYYY-MM-DD");
        if (vm.reading.getLink && vm.reading.getLink('resource')) {
            vm.uploadedImage = {
                _links: {
                    resource: {
                        href: vm.reading.getLink('resource')
                    }
                },
                title: vm.reading.getLinkAttribute('resource', 'title')
            };
        }

        function createReading() {
            if (! vm.meter.isDualRated) {
                vm.reading.type = 0;
            }

            if (vm.uploadedImage) {
                vm.reading._links = {
                    resource: {href: vm.uploadedImage._links.resource.href }
                };
            }

            apiClient.create(vm.meter.getLink('create-reading'), angular.copy(vm.reading)).then(function (response) {
                if (response) {
                    $state.go('^', $stateParams, {reload: $state.get('^.^.^')}).then(function () {
                        messenger.success('Meter Reading created');
                    });
                }
            });
        }

        function updateReading() {
            vm.data = angular.copy(vm.reading);
            delete vm.data._links;

            if (! vm.meter.isDualRated) {
                vm.data.type = 0;
            }

            if (vm.uploadedImage) {
                vm.data._links = { resource: { href: vm.uploadedImage._links.resource.href } };
            }
            apiClient.update(vm.reading.getLink('self'), angular.copy(vm.data)).then(function (response) {
                if (response) {
                    $state.go('^', $stateParams, {reload: $state.get('^.^.^')}).then(function () {
                        messenger.success('Meter Reading updated');
                    });
                } else {
                    messenger.error('There was a problem with your request, please try again later');
                }
            });
        }
    }
})();
