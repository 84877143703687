(function() {
    'use strict';

    angular
        .module('elogbooks.user.sharing-setup')
        .config(registerRoutes);

    function registerRoutes($stateProvider) {
        $stateProvider
            .state('dashboard.user.share-setup', {
                url: '/sharing-setup?payload',
                templateUrl: '/modules/user/sharing-setup/sharing-setup.html',
                controller: 'ShareSetupController',
                controllerAs: 'vm',
                resolve: {
                    siteCollectionResponse: function (user) {
                        return user.getResource('sites');
                    }
                },
                ncyBreadcrumb: {
                    label: '{{ ::"SETUP_SHARE" | translate }}'
                }
            });
    }
})();
