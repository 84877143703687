(function () {
    'use strict';

    angular
        .module('elogbooks.user.waste-management')
        .config(registerRoutes)
        .config(registerRoutesList)
        .config(registerRoutesAdd)
        .config(registerRoutesDetails)
        .config(registerRoutesEdit)
        .config(registerImportRoutes);

    function registerRoutes($stateProvider) {
        $stateProvider
            .state('dashboard.user.waste-management', {
                url: '/waste-management',
                abstract: true,
                template: '<bookerize></bookerize>',
                ncyBreadcrumb: {
                    label: '{{ ::"WASTE_MANAGEMENT" | translate }}'
                }
            })
            .state('dashboard.user.waste-management.entries', {
                url: '/entries',
                abstract: true,
                parent: 'dashboard.user.waste-management',
                views: parseBook({
                    "elogbooks-center@dashboard.user.waste-management": {
                        templateUrl: '/modules/common/waste-management/waste-management.html',
                        controller: 'UserWasteManagementController',
                        controllerAs: 'vm'
                    }
                }),
                resolve: {
                    sectionResource: function (user, apiClient, userManager) {
                        return apiClient.get(user.getLink('operatives'), {permission: 'operative_permission_import_waste_entries'}).then(function (response) {
                            var canImport = false;
                            if (response) {
                                canImport = response.count;
                            }
                            return {
                                canImport: canImport || userManager.hasPermission('site_permission_import_waste_entries'),
                                listSref: 'dashboard.user.waste-management.entries.list',
                                importSref: 'dashboard.user.waste-management.imports.list',
                                currentView:'elogbooks-right@dashboard.user.waste-management.entries'
                            };
                        });
                    }
                },
                ncyBreadcrumb: {
                    skip: true
                }
            });
    }

    function registerRoutesList($stateProvider) {
        var params = {
            wasteManagementPage: '1',
            wasteManagementLimit: '30',
            id: null,
            serviceProvider: null,
            wasteType: null,
            unit: null,
            dateStart: null,
            dateEnd: null,
            order: null,
        };
        $stateProvider
            .state('dashboard.user.waste-management.entries.list', {
                url: '/list?' + Object.keys(params).join('&'),
                parent: 'dashboard.user.waste-management.entries',
                params: params,
                views: parseBook({
                    'elogbooks-right@dashboard.user.waste-management.entries': {
                        templateUrl: '/modules/common/waste-management/waste-entries-list.html',
                        controller: 'WasteEntriesController',
                        controllerAs: 'vm'
                    }
                }),
                resolve: {
                    wasteEntryCollectionResponse: function ($stateParams, apiClient, user, selectedSiteResponse) {
                        var params = $stateParams;

                        params = angular.extend({}, params, {
                            serviceProvider: $stateParams.serviceProvider,
                            wasteType: $stateParams.wasteType,
                            unit: $stateParams.unit,
                            page: $stateParams.wasteManagementPage,
                            limit: $stateParams.wasteManagementLimit,
                            dateStart: $stateParams.dateStart,
                            dateEnd: $stateParams.dateEnd,
                        });

                        if ($stateParams.serviceProvider) {
                            params.serviceProvider = JSON.parse($stateParams.serviceProvider).v;
                        }

                        if ($stateParams.wasteType) {
                            params.wasteType = JSON.parse($stateParams.wasteType).v;
                        }

                        if ($stateParams.unit) {
                            params.unit = JSON.parse($stateParams.unit).v;
                        }

                        return apiClient.get(user.getLink('waste-entries'), params).then(function(response) {
                            return response;
                        });
                    },
                    wasteTypeCollectionResponse : function (apiClient, $stateParams, rootResourceResponse) {
                        return apiClient.get(rootResourceResponse.getLink('wastetypes'), $stateParams).then(function (response) {
                            return response;
                        });
                    },
                    serviceProviderCollectionResponse: function ($stateParams, apiClient, user) {
                        return apiClient.get(user.getLink('serviceproviders'), params).then(function (response) {
                            return response;
                        });
                    },
                    unitCollectionResponse: function (rootResourceResponse, apiClient) {
                        return apiClient.get(rootResourceResponse.getLink('units'));
                    },
                    config: function () {
                        return {
                            site_level: false
                        };
                    }
                },
                ncyBreadcrumb: {
                    label: '{{ ::"WASTE_ENTRIES" | translate }}'
                }
            });
    }

    function registerRoutesAdd($stateProvider) {
        $stateProvider
            .state('dashboard.user.waste-management.entries.list.add', {
                url: '/add',
                parent: 'dashboard.user.waste-management.entries.list',
                views: parseBook({
                    'elogbooks-right@dashboard.user.waste-management.entries': {
                        templateUrl: '/modules/common/waste-management/add-edit/waste-entries-form.html',
                        controller: 'WasteEntryAddEditController',
                        controllerAs: 'vm'
                    }
                }),
                resolve: {
                    siteCollectionResponse: function (user, apiClient) {
                        return apiClient.get(user.getLink('sites'));
                    },
                    serviceProviderCollectionResponse: function (user, apiClient) {
                        return apiClient.get(user.getLink('serviceproviders'));
                    },
                    unitCollectionResponse: function (rootResourceResponse, apiClient) {
                        return apiClient.get(rootResourceResponse.getLink('units'), {status: true});
                    },
                    wasteEntryResponse: function () {
                        return {};
                    },
                    treatmentTypeCollectionResponse : function (apiClient, rootResourceResponse) {
                        return apiClient.get(rootResourceResponse.getLink('treatmenttypes'), {status: true}).then(function (response) {
                            return response;
                        });
                    },
                    wasteTypeCollectionResponse : function (apiClient, rootResourceResponse) {
                        return apiClient.get(rootResourceResponse.getLink('wastetypes'), {status: true}).then(function (response) {
                            return response;
                        });
                    },
                    config: function () {
                        return {
                            site_level: false,
                            add: true,
                            noborder: true
                        };
                    }
                },
                ncyBreadcrumb: {
                    label: '{{ ::"ADD" | translate }}'
                }
            });
    }

    function registerRoutesDetails($stateProvider) {
        $stateProvider
            .state('dashboard.user.waste-management.entries.list.details', {
                url: '/details/{entryResource}',
                abstract: true,
                parent: 'dashboard.user.waste-management.entries.list',
                views: parseBook({
                    'elogbooks-right@dashboard.user.waste-management': {
                        templateUrl: '/modules/common/waste-management/details/waste-entry-details.html',
                        controller: 'WasteEntryDetailsController',
                        controllerAs: 'vm'
                    }
                }),
                resolve: {
                    wasteEntryResponse: function (apiClient, $stateParams) {
                        return apiClient.get($stateParams.entryResource.decode()).then(function (response) {
                                return response;
                        });
                    },
                    sectionResource: function () {
                        return {
                            infoSref: 'dashboard.user.waste-management.entries.list.details.info',
                            fileSref: 'dashboard.user.waste-management.entries.list.details.files',
                        };
                    }
                },
                ncyBreadcrumb: {
                    label: '{{ ::$resolves().wasteEntryResponse.id }}'
                }
            })
            .state('dashboard.user.waste-management.entries.list.details.info', {
                url: '/info',
                parent: 'dashboard.user.waste-management.entries.list.details',
                views: parseBook({
                    'elogbooks-right@dashboard.user.waste-management.entries.list.details': {
                        templateUrl: '/modules/common/waste-management/details/info/waste-entry-info.html',
                        controller: 'WasteEntryInfoController',
                        controllerAs: 'vm'
                    }
                }),
                ncyBreadcrumb: {
                    label: '{{ ::"INFO" | translate }}'
                }
            })
            .state('dashboard.user.waste-management.entries.list.details.files', {
                url: '/files',
                parent: 'dashboard.user.waste-management.entries.list.details',
                views: parseBook({
                    'elogbooks-right@dashboard.user.waste-management.entries.list.details': {
                        templateUrl: '/modules/common/files/files-list.html',
                        controller: 'CommonFilesListController',
                        controllerAs: 'vm'
                    }
                }),
                params: {
                    filePage: '1',
                    fileLimit: '30',
                    fileOrder: 'id',
                    fileSelectedSiteResource: null,
                    fileTitle: null,
                    fileUploadedBy: null,
                    fileUploadedDateStart: null,
                    fileUploadedDateEnd: null,
                    fileExpiresDateStart: null,
                    fileExpiresDateEnd: null,
                    fileTags: null,
                    fileIsStatutory: null,
                    documentType: null
                },
                resolve: {
                    fileCollectionResponse: function (apiClient, user, siteResponse, wasteEntryResponse, $stateParams) {
                        var params = angular.extend({}, $stateParams,
                            {
                                page: $stateParams.filePage,
                                limit: $stateParams.fileLimit,
                                order: $stateParams.fileOrder,
                                title: $stateParams.fileTitle,
                                uploadedBy: $stateParams.fileUploadedBy,
                                uploadedDateStart: $stateParams.fileUploadedDateStart ? new Date($stateParams.fileUploadedDateStart).toISOString() : null,
                                uploadedDateEnd: $stateParams.fileUploadedDateEnd ? new Date($stateParams.fileUploadedDateEnd).toISOString() : null,
                                expiresDateStart: $stateParams.fileExpiresDateStart ? new Date($stateParams.fileExpiresDateStart).toISOString() : null,
                                expiresDateEnd: $stateParams.fileExpiresDateEnd ? new Date($stateParams.fileExpiresDateEnd).toISOString() : null,
                                tags: $stateParams.fileTags,
                                isStatutory: $stateParams.fileIsStatutory,
                                documentType: $stateParams.documentType ? JSON.parse($stateParams.documentType).v : null
                            }
                        );

                        if (siteResponse) {
                            params.site = siteResponse.id;
                        }

                        return apiClient.get(wasteEntryResponse.getLink('files'), params).then(function (response) {
                            return response || apiClient.noResourceFound();
                        });
                    },
                    tagCollectionResponse: function (apiClient, rootResourceResponse) {
                        return apiClient.get(rootResourceResponse.getLink('tags'), {limit: 30}).then(function (response) {
                            return response || apiClient.noResourceFound();
                        });
                    },
                    config: function (fileCollectionResponse) {
                        return {
                            jobId: false,
                            showStatutoryType: false,
                            hideAddButton: !fileCollectionResponse.canCreate,
                            overrideRoute: 'dashboard.user.waste-management.entries.list.details.files.details',
                            widerNameColumn: true
                        };
                    },
                    integrationDocTypesResponse: function () {
                        return null;
                    },
                    documentTypeCollectionResponse : function (apiClient, rootResourceResponse) {
                        return apiClient.get(rootResourceResponse.getLink('documenttypes'), {status: 'active', order: 'name'})
                            .then(function (response) {
                                return response || apiClient.noResourceFound();
                            });
                    },
                    jobResponse: function () {
                        return null;
                    },
                    serviceProviderResponse: function () {
                        return null;
                    },
                    operativeCollectionResponse: function () {
                        return null;
                    }
                },
                ncyBreadcrumb: {
                    label: '{{ ::"FILES" | translate }}'
                }
            })
            .state('dashboard.user.waste-management.entries.list.details.files.details', {
                url: '/{fileResource}',
                parent: 'dashboard.user.waste-management.entries.list.details.files',
                views: parseBook({
                    'elogbooks-right@dashboard.user.waste-management.entries.list.details': {
                        templateUrl: '/modules/common/files/details/files-details.html',
                        controller: 'CommonFilesDetailsController',
                        controllerAs: 'vm'
                    }
                }),
                resolve: {
                    fileResponse: function(apiClient, $stateParams) {
                        return apiClient.get($stateParams.fileResource.decode(), {datagroup: 'details'}).then(function(response) {
                            return response || apiClient.noResourceFound();
                        });
                    },
                    config: function (config) {
                        return angular.extend(config, {
                            showVisibility: true
                        });
                    }
                },
                ncyBreadcrumb: {
                    label: '{{ ::$resolves().fileResponse.title }}'
                }
            })
            .state('dashboard.user.waste-management.entries.list.details.files.details.edit', {
                url: '/edit',
                parent: 'dashboard.user.waste-management.entries.list.details.files.details',
                views: parseBook({
                    'elogbooks-right@dashboard.user.waste-management.entries.list.details': {
                        templateUrl: '/modules/common/files/add-edit/files-add-edit.html',
                        controller: 'CommonFilesAddEditController',
                        controllerAs: 'vm'
                    }
                }),
                resolve: {
                    config: function (config) {
                        return angular.extend(config, {
                            showVisibility: true
                        });
                    }
                },
                ncyBreadcrumb: {
                    label: '{{ ::"FILE_EDIT" | translate }}'
                }
            })
            .state('dashboard.user.waste-management.entries.list.details.files.add', {
                url: '/add',
                parent: 'dashboard.user.waste-management.entries.list.details.files',
                views: parseBook({
                    'elogbooks-right@dashboard.user.waste-management.entries.list.details': {
                        templateUrl: '/modules/common/waste-management/details/files/add/files-add.html',
                        controller: 'CommonWasteEntryFileAddController',
                        controllerAs: 'vm'
                    }
                }),
                ncyBreadcrumb: {
                    label: '{{ ::"ADD" | translate }}'
                }
            });
    }

    function registerRoutesEdit($stateProvider) {
        $stateProvider
            .state('dashboard.user.waste-management.entries.list.details.info.edit', {
                url: '/edit',
                parent: 'dashboard.user.waste-management.entries.list.details.info',
                views: parseBook({
                    'elogbooks-right@dashboard.user.waste-management.entries.list.details': {
                        templateUrl: '/modules/common/waste-management/add-edit/waste-entries-form.html',
                        controller: 'WasteEntryAddEditController',
                        controllerAs: 'vm'
                    }
                }),
                resolve: {
                    unitCollectionResponse: function (rootResourceResponse, apiClient) {
                        return apiClient.get(rootResourceResponse.getLink('units'), {status: true});
                    },
                    config: function () {
                        return {
                            site_level: false,
                            add: false
                        };
                    },
                    wasteTypeCollectionResponse : function (apiClient, rootResourceResponse) {
                        return apiClient.get(rootResourceResponse.getLink('wastetypes'), {status: true}).then(function (response) {
                            return response;
                        });
                    },
                    treatmentTypeCollectionResponse : function (apiClient, $stateParams, rootResourceResponse) {
                        return apiClient.get(rootResourceResponse.getLink('treatmenttypes'), {status: true}).then(function (response) {
                            return response;
                        });
                    },
                    siteCollectionResponse: function (user, apiClient) {
                        return apiClient.get(user.getLink('sites'));
                    }
                },
                ncyBreadcrumb: {
                    label: '{{ ::"EDIT" | translate }}'
                }
            });
    }

    function registerImportRoutes($stateProvider) {
        $stateProvider
            .state('dashboard.user.waste-management.imports', {
                url: '/imports',
                parent:'dashboard.user.waste-management.entries',
                abstract: true,
                templateUrl: 'shared-templates/view.html',
                ncyBreadcrumb: {
                    skip: true
                }
            })
            .state('dashboard.user.waste-management.imports.list', {
                url: '/list?page&limit&order&type',
                parent: 'dashboard.user.waste-management.imports',
                views: parseBook({
                    'elogbooks-right@dashboard.user.waste-management.entries': {
                        templateUrl: '/modules/common/imports/list/list.html',
                        controller: 'CommonImportListController',
                        controllerAs: 'vm'
                    }
                }),
                resolve: {
                    importsCollectionResponse: function (apiClient, rootResourceResponse, $stateParams) {
                        return apiClient.get(rootResourceResponse.getLink('imports'), $stateParams);
                    }
                },
                params: {
                    page: '1',
                    limit: '30',
                    type: 'waste_entry'
                },
                ncyBreadcrumb: {
                    label: '{{ ::"ENTRIES_IMPORT" | translate }}'
                }
            })
            .state('dashboard.user.waste-management.imports.list.details', {
                url: '{importResource}/details?logsPage&logsLimit&logsOrder&logsType&logsMessage',
                parent: 'dashboard.user.waste-management.imports.list',
                views: parseBook({
                    'elogbooks-right@dashboard.user.waste-management': {
                        templateUrl: '/modules/common/imports/details/details.html',
                        controller : 'CommonImportDetailsController',
                        controllerAs: 'vm',
                    }
                }),
                params: {
                    logsPage: '1',
                    logsLimit: '30',
                    logsType: null,
                    logsMessage: null
                },
                resolve: {
                    importResponse: function (apiClient, $stateParams) {
                        return apiClient.get($stateParams.importResource.decode());
                    },
                    importLogsCollectionResponse: function (apiClient, importResponse, $stateParams) {
                        var params = {
                            page: $stateParams.logsPage,
                            limit: $stateParams.logsLimit,
                            type: $stateParams.logsType,
                            message: $stateParams.logsMessage
                        };
                        return apiClient.get(importResponse.getLink('logs'), params);
                    }
                },
                ncyBreadcrumb: {
                    label: '{{ ::"IMPORT_DETAILS_LIST" | translate }}'
                }
            })
            .state('dashboard.user.waste-management.imports.list.add', {
                url: '/add',
                parent: 'dashboard.user.waste-management.imports.list',
                views: parseBook({
                    'elogbooks-right@dashboard.user.waste-management': {
                        templateUrl: '/modules/common/imports/add/import-add.html',
                        controller: 'CommonAddImportController',
                        controllerAs: 'vm'
                    }
                }),
                resolve: {
                    sectionResource: function() {
                        return {
                            type: 'waste_entry',
                            name: 'waste_entry'
                        };
                    }
                },
                ncyBreadcrumb: {
                    label: '{{ ::"IMPORT_ADD" | translate }}'
                }
            });
    }
})();
