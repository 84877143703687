/**
 * Score Range directive
 */
angular.module('elogbooksDirectives').directive('scorerange', function(){
    return {
        restrict: 'A',
        require: ['ngModel'],
        scope: {
            scoreRange: '=ngModel'
        },
        templateUrl: '/modules/directives/form-elements/scorerange/scorerange.html',
        link: function (scope) {
            scope.options = [{
                value: '0to2',
                label: '0 to 2'
            },{
                value: '0to5',
                label: '0 to 5'
            },{
                value: 'yn',
                label: 'Yes/No'
            },{
                value: 'ny',
                label: 'No/Yes'
            }]
        }
    }
});
