(function() {
    'use strict';

    angular
        .module('elogbooks.common.actions')
        .controller('CommonActionAddController', CommonActionAddController);

    CommonActionAddController.$inject = ['$state', 'apiClient', 'confirmationModal', 'requestDataFactory', 'config', 'siteResponse', 'spillageResponseLink'];

    function CommonActionAddController($state, apiClient, confirmationModal, requestDataFactory, config, siteResponse, spillageResponseLink) {
        var vm = this;
        vm.data = requestDataFactory.createRequest({});
        vm.config = config;
        vm.selectedSite = null;
        vm.submitDisabled = false;
        vm.canNext = false;
        vm.uploading = false;
        vm.uploadedFile = null;
        vm.siteResponse = null;
        vm.redirectToStart = true;

        if (spillageResponseLink) {
            vm.data._links.fromSpillage = {href: spillageResponseLink};
        }

        if (siteResponse) {
            apiClient.get(siteResponse.getLink('actions'), {site: siteResponse.id, limit: 1}).then(function(response) {
                vm.config.createLink = response.getLink('create');
            });
        }

        vm.cancel = cancelAction;
        vm.getRoute = getRoute;

        function cancelAction() {
            return confirmationModal.open(config).result.then(function() {
                var reloadState = $state.current.name.substring(0, $state.current.name.lastIndexOf('.list') + 5);
                $state.go(reloadState, {}, {reload: false});
            });
        }

        function getRoute(route) {
            return $state.current.name.substring(0, $state.current.name.indexOf('.add') + 4) + '.' + route;
        }
    }
})();
