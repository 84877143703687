;(function() {
    'use strict';

    angular
        .module('elogbooks.common.jobs.workflow')
        .controller('JobFocActionController', JobFocActionController);

    JobFocActionController.$inject = ['$state', 'messenger', 'crudService', 'jobResponse', 'confirmationModal'];

    function JobFocActionController($state, messenger, crudService, jobResponse, confirmationModal) {
        var vm = this;
        vm.data = {note: null};
        vm.noteRequired = true;
        vm.translation = 'JOB_FOC';

        vm.submit = submitAction;

        function submitAction() {
            return confirmationModal.open().result.then(function (response) {
                if (response.result) {
                    return crudService.update(
                        jobResponse.getLink('value-foc'),
                        vm.data,
                        null,
                        null,
                        null,
                        true
                    ).then(function () {
                        messenger.success('JOB_FOC_CREATED');
                        $state.go($state.get('^.^'), {}, {reload: $state.get('^.^.^')});
                    });
                }
            });
        }
    }
})();