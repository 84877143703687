(function() {
    'use strict';
    angular
        .module('elogbooksControllers')
        .controller('AuditDashboardController', AuditDashboardController);

    AuditDashboardController.$inject = ['apiClient', 'user', 'auditDashboardResponse', 'recurrenceRulesService', 'selectedSiteResponse', '$translate', 'actionAuditTypeCollectionResponse', '$stateParams'];

    function AuditDashboardController(apiClient, user, auditDashboardResponse, recurrenceRulesService, selectedSiteResponse, $translate, actionAuditTypeCollectionResponse, $stateParams) {
        var vm = this;
        vm.statistics = {};
        vm.params = $stateParams;
        vm.customSettings = {};

        var actionSystemTypeId = actionAuditTypeCollectionResponse.actiontypes[0].id;

        if (selectedSiteResponse) {
            vm.params.site = selectedSiteResponse.id;
        }

        getStatistics();

        function getStatistics() {
            angular.forEach(auditDashboardResponse.statistics, function(statName) {
                if (auditDashboardResponse.getLink(statName)) {
                    setUpStatistics(statName);
                    apiClient.get(auditDashboardResponse.getLink(statName), vm.params).then(function(stats) {
                        if (!stats) { return; }
                        formatResponse(stats, statName);
                    });
                };
            });
        }

        function setUpStatistics(stat) {
            vm.statistics[stat] = {};

            vm.customSettings = {};

            switch (stat) {
                case 'last60Days':
                    vm.statistics[stat] = {
                        type: 'pieChart',
                        heading: 'AUDITS_RAISED_IN_LAST_60_DAYS',
                        route: 'dashboard.user.audits.list',
                        icon: 'fa-pie-chart'
                    };
                    break;
                case 'recentCompletedAudits':
                    vm.statistics[stat] = {
                        type: 'list',
                        heading: 'AUDITS_RECENTLY_COMPLETED',
                        parameters: {
                            auditStatus: 'audit_completed'
                        },
                        columns: [
                            {
                                heading: 'ID',
                                value: 'id',
                                width: 1
                            },
                            {
                                heading: 'AUDIT',
                                value: 'summary',
                                type: 'link',
                                link: 'self',
                                property: 'audit',
                                width: 5
                            },
                            {
                                heading: 'DATE_COMPLETE_AT',
                                type: 'date',
                                value:  'completedAt',
                                width: 4
                            }
                        ],
                        route: 'dashboard.user.audits.list',
                        icon: 'fa-list'
                    };
                    break;
                case 'nextUpcompingAudits':
                    vm.statistics[stat] = {
                        type: 'list',
                        heading: 'AUDITS_NEXT',
                        parameters: {
                            auditStatus: 'audit_created',
                            completionDueAt: getQueryString('completionDueAt', auditDashboardResponse.getLink(stat))
                        },
                        columns: [
                            {
                                heading: 'ID',
                                value: 'id',
                                width: 1
                            },
                            {
                                heading: 'AUDIT',
                                value: 'summary',
                                type: 'link',
                                link: 'self',
                                property: 'audit',
                                width: 5
                            },
                            {
                                heading: 'COMPLETION_DUE',
                                type: 'date',
                                value: 'completionDueAt',
                                width: 4
                            }
                        ],
                        route: 'dashboard.user.audits.list',
                        icon: 'fa-list'
                    };
                    break;
                case 'nextScheduledAudits':
                    vm.statistics[stat] = {
                            heading: 'AUDITS_NEXT_SCHEDULED',
                            parameters: {
                                auditStatus: 'audit_created',
                                generatedForDate: getQueryString('generatedForDate', auditDashboardResponse.getLink(stat))
                            },
                            columns: [
                                {
                                    heading: 'ID',
                                    value: 'id',
                                    width: 1
                                },
                                {
                                    heading: 'AUDIT',
                                    value: 'summary',
                                    type: 'link',
                                    link: 'self',
                                    property: 'audit',
                                    width: 5
                                },
                                {
                                    heading: 'AUDITS_GENERATED_FOR_DATE',
                                    type: 'date',
                                    value: 'generatedForDate',
                                    width: 4
                                }
                            ],
                            type: 'list',
                            route: 'dashboard.user.audits.list',
                            icon: 'fa-list'
                    };
                    break;
                case 'nextAuditSchedules':
                    vm.statistics[stat] = {
                        type: 'list',
                        heading: 'AUDITS_SCHEDULES',
                        width: 2,
                        route: 'dashboard.user.audits.list',
                        icon: 'fa-list',
                        parameters: {
                            auditType: 'schedule',
                            auditStatus: 'audit_schedule_active',
                            auditOrder: '-id'
                        },
                        columns: [
                            {
                                heading: 'SITES',
                                value: 'site',
                                width: 4
                            },
                            {
                                heading: 'AUDITS_SCHEDULE',
                                value: 'summary',
                                type: 'link',
                                link: 'self',
                                property: 'audit',
                                width: 5
                            },
                            {
                                heading: 'FREQUENCIES',
                                value: 'frequencies',
                                width: 4
                            }
                        ]
                    };
                    break;
                case 'auditsActionOpen':
                    vm.statistics[stat] = {
                        type: 'multiBarChart',
                        heading: 'AUDIT_OPEN_ACTION',
                        route: 'dashboard.user.actions.manage-actions.list',
                        icon: 'fa-bar-chart',
                        width: 2,
                        parameters: {
                            actionTypes: actionSystemTypeId,
                            actionsStatus: ['open']
                        },
                        dynamicParameters: {
                            'actionsDateDueStart': 'startDate',
                            'actionsDateDueEnd': 'endDate',
                            'actionsPriorityName': 'key'
                        }
                    };
                    break;
            }
        }

        function formatResponse(stat, key) {
            var data = {};
            switch (key) {
                case 'last60Days':
                    data = {
                        parameters: getLast60DaysParameters(stat.options, key),
                        options: getLast60DaysOptions(stat.options)
                    };
                    break;
                case 'recentCompletedAudits':
                case 'nextUpcompingAudits':
                case 'nextScheduledAudits':
                    data = { options: stat.audits };
                    break;
                case 'nextAuditSchedules':
                    data = {
                        options: sortScheduledAuditsData(stat.audits),
                    };
                    break;
                case 'auditsActionOpen':
                    data.options = stat.options;
            }

            vm.statistics[key] = angular.extend({}, vm.statistics[key], data);
        }

        function getLast60DaysParameters(stats, key) {
            var parameters = [];

            for (var stat in stats) {
                if (!stats.hasOwnProperty(stat)) {
                    return;
                }

                var params = {
                    createdAt: getQueryString('createdAt', auditDashboardResponse.getLink(key))
                };
                switch (stats[stat].key) {
                    case 'AUDIT_CREATED':
                        params.auditStatus = 'audit_created';
                        params.auditOrder = '-id';
                        break;
                    case 'AUDIT_STARTED':
                        params.auditStatus = 'audit_started';
                        break;
                    case 'AUDIT_COMPLETED':
                        params.auditStatus = 'audit_completed';
                        break;
                }
                parameters.push(params);
            }

            return parameters;
        }

        function getLast60DaysOptions(stats) {
            for (var stat in stats) {
                if (!stats.hasOwnProperty(stat)) {
                    return;
                }
                switch (stats[stat].key) {
                    case 'AUDIT_CREATED':
                        stats[stat].colour = '#d8d8d8';
                        break;
                    case 'AUDIT_STARTED':
                        stats[stat].colour = '#F9B454';
                        break;
                    case 'AUDIT_COMPLETED':
                        stats[stat].positive = true;
                        stats[stat].colour = '#1D9984';
                        break;
                }
            }

            return stats;
        }

        function sortScheduledAuditsData(stats) {
            for (var stat in stats) {
                if (!stats.hasOwnProperty(stat)) {
                    return;
                }
               stats[stat].site = stats[stat].sites[0].name + (stats[stat].sites.length > 1 ? '...' : '');
               stats[stat].frequencies = getFrequenciesAsString(stats[stat].recurrenceSet.rules);
            }

            return stats;
        }

        function getFrequenciesAsString(rules) {
            var ruleString = '';

            for (var rule in rules) {
                if (!rules.hasOwnProperty(rule)) {
                    return;
                }
                ruleString +=
                    rules[rule].interval +
                    recurrenceRulesService.getRuleFrequencyAsString(rules[rule].frequency, true)  +
                    ', ';
            }

            return ruleString.substring(0, ruleString.length - 2);
        }

        /**
         * Get the value of a querystring
         * @param  {String} field The field to get the value of
         * @param  {String} url   The URL to get the value from (optional)
         * @return {String}       The field value
         */
        function getQueryString( field, url ) {
            var href = url ? url : window.location.href;
            var reg = new RegExp( '[?&]' + field + '=([^&#]*)', 'i' );
            var string = reg.exec(href);
            return string ? string[1] : null;
        };
    };
})();
