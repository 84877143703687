angular.module('elogbooksServices').service('ServiceProviderFeedbackService', ['$uibModal', 'apiClient', function ($uibModal, apiClient) {
    //List of states where the sp feedback modal is allowed to appear
    let whiteList = ['dashboard.user.dashboard'];

    this.openSpFeedbackModal = function (url, dashboardName, rootResourceResponse) {
        if (whiteList.includes(dashboardName)) {
            let modalData = false;

            if (localStorage.getItem('sp-for-feedback')) {
                modalData = JSON.parse(localStorage.getItem('sp-for-feedback'));
            } else {
                apiClient.get(url).then(function (response) {
                    if (response && response.count > 0) {
                        localStorage.setItem('sp-for-feedback', JSON.stringify(response));
                        openModal(response, rootResourceResponse);
                    }
                });
            }

            if (modalData) {
                openModal(modalData, rootResourceResponse);
            }
        }
    };

    function openModal(modalData, rootResourceResponse) {
        return $uibModal.open({
            templateUrl: '/modules/common/modals/service-provider-feedback/service-provider-feedback.html',
            windowClass: 'feedback-modal',
            controller: 'ServiceProviderFeedbackController',
            controllerAs: 'vm',
            backdrop: 'static',
            keyboard  : false,
            resolve: {
                config: function (elbSettings) {
                    var bodyText = elbSettings.getSetting('sp_feedback_modal_body').value.replace(
                            '[SERVICE_PROVIDER]', '<span class="sp-name">' + '{{SERVICE_PROVIDER}}' + '</span>') +
                        '<span class="asterisk">*</span>';
                    var defaultConfig = {
                        title: elbSettings.getSetting('sp_feedback_modal_title').value,
                        bodyText: bodyText,
                        displayJobStats: elbSettings.getSetting('sp_feedback_display_job_statistics').value,
                        displayCommentBox: elbSettings.getSetting('sp_feedback_allow_comments').value
                    };

                    return angular.extend({}, defaultConfig);
                },
                modalData: modalData,
                rootResource: rootResourceResponse
            }
        });
    }
}]);
