(function () {
    'use strict';

    angular
        .module('elogbooks.user.reports')
        .controller('UserReportsAuditTrendController', UserReportsAuditTrendController);

    UserReportsAuditTrendController.$inject = ['$state', '$stateParams','lodash', 'apiClient','auditTemplateCollectionResponse', 'siteCollectionResponse', 'siteGroupsCollection', 'regionCollectionResponse', 'user', 'questionCollectionResponse', 'showPercentagesService'];

    function UserReportsAuditTrendController($state, $stateParams, lodash, apiClient, auditTemplateCollectionResponse, siteCollectionResponse, siteGroupsCollection, regionCollectionResponse, user, questionCollectionResponse, showPercentagesService) {
        var vm = this;

        vm.questions = questionCollectionResponse.report;
        vm.questionsCollection = questionCollectionResponse;
        vm.showPercentages = showPercentagesService.getShowPercentageState();

        vm.stateParams = $stateParams;
        vm.siteCollectionResponse = siteCollectionResponse;
        vm.searchAction = searchAction;
        vm.reloadSites = reloadSites;
        vm.updateShowPercentages = updateShowPercentages;

        vm.exportParams = angular.extend({}, $stateParams, {
            'siteIds[]': $stateParams.siteIds,
            'siteGroupIds[]': $stateParams.siteGroupIds,
            'siteRegionIds[]': $stateParams.siteRegionIds
        });

        var selectedVersion = $stateParams.audit;
        var versionLinkParameters = {'order': '-version'};

        vm.versionModel = {};

        if (auditTemplateCollectionResponse) {
            vm.auditModel = {
                response: auditTemplateCollectionResponse,
                link: auditTemplateCollectionResponse.getLink('self'),
                responseKeyPath: 'audits',
                itemValuePath: 'summary',
                required: true,
                selected: findSelectedById(auditTemplateCollectionResponse.audits, $stateParams.template),
                onSelect: loadVersions,
                onRemove: loadVersions,
            };

            loadVersions(true);
        }

        function loadVersions(loadFromParams) {
            vm.versionModel.disabled = true;

            if (vm.auditModel.selected !== undefined) {
                // when template is changed, pre-populate selected version with template active version
                if (typeof loadFromParams !== undefined && loadFromParams !== true) {
                    selectedVersion = vm.auditModel.selected.object.id;
                }

                apiClient.get(vm.auditModel.selected.object.getLink('versions'), versionLinkParameters).then(function(versionsResponse){
                    if ($stateParams.auditJson) {
                        var selected = JSON.parse($stateParams.auditJson);
                        versionsResponse.audits.push({version:selected.title, id: selected.value});
                    }

                    vm.versionModel = {
                        response: versionsResponse,
                        link: versionsResponse.getLink('self'),
                        linkParameters: versionLinkParameters,
                        responseKeyPath: 'audits',
                        itemValuePath: 'version',
                        searchKey: 'version',
                        required: true,
                        disabled: false,
                        selected: findSelectedById(versionsResponse.audits, selectedVersion, true)
                    };
                });
           }
        }

        vm.section = $stateParams.section;
        vm.summary = $stateParams.summary;

        // needs to be set here explicitly, as filters are cached on parent state and
        // when going '.list' state,autocompletemulti directive doesnt find cache with new state
        vm.cacheState = 'dashboard.user.reports.list.view.audit-trend.list';

        vm.siteModel = {
            type: 'autocompletemulti',
            title: 'SITE',
            param: 'siteIds',
            value: $stateParams.siteIds,
            options: {
                response : siteCollectionResponse,
                link: siteCollectionResponse ? siteCollectionResponse.getLink('self') : null,
                responseKeyPath: 'sites',
                itemValuePath: 'name',
                itemHrefPath: 'id',
                cacheState: vm.cacheState
            }
        };

        vm.siteGroupModel = {
            type: 'autocompletemulti',
            title: 'SITE_GROUP',
            param: 'siteGroupIds',
            value: $stateParams.siteGroupIds,
            options: {
                response: siteGroupsCollection,
                link: siteGroupsCollection ? siteGroupsCollection.getLink('self') : null,
                responseKeyPath: 'sitegroups',
                itemValuePath: 'name',
                itemHrefPath: 'id',
                cacheState: vm.cacheState
            },
            onSelect: reloadSites
        };

        vm.regionModel = {
            type: 'autocompletemulti',
            title: 'REGION',
            param: 'siteRegionIds',
            value: $stateParams.siteRegionIds,
            options: {
                response: regionCollectionResponse,
                link: regionCollectionResponse ? regionCollectionResponse.getLink('self') : null,
                responseKeyPath: 'regions',
                itemValuePath: 'name',
                itemHrefPath: 'id',
                cacheState: vm.cacheState
            },
            onSelect: reloadSites
        };

        vm.createdAt = {
            type: 'date-range',
            title: 'CREATED_AT',
            endMaxDate: moment().format('MM-DD-YYYY, 23:59:59'),
            value: {
                startDate: ($stateParams.createdAt !== null) ? moment(new Date($stateParams.createdAt.split(',')[0])) : null,
                endDate: ($stateParams.createdAt !== null) ? moment(new Date($stateParams.createdAt.split(',')[1])) : null
            },
            clearValue: {
                startDate: moment().subtract(30, 'days').startOf('day').format(),
                endDate: moment().endOf('day').format()
            }
        };

        vm.completedAt = {
            type: 'date-range',
            title: 'COMPLETED_AT',
            endMaxDate: moment().format('MM-DD-YYYY, 23:59:59'),
            value: {
                startDate: ($stateParams.completedAt !== null) ? moment(new Date($stateParams.completedAt.split(',')[0])) : null,
                endDate: ($stateParams.completedAt !== null) ? moment(new Date($stateParams.completedAt.split(',')[1])) : null

            },
            clearValue: {
                startDate: null,
                endDate: null
            }
        };

        function reloadSites(item) {
            var params = {};

            if (item.selected) {
                params[item.name] = item.selected.object.id;
                params.id = 1;
            }

            apiClient.get(user.getLink('sites'), params).then(function (response){
                vm.siteModel = {
                    type: 'autocompletemulti',
                    title: 'SITE',
                    response : response,
                    link : response ? response.getLink('self') : null,
                    responseKeyPath: 'sites',
                    itemValuePath: 'name',
                    itemHrefPath: 'id'
                };
            });
        }

        function findSelectedById(collection, id, showAsVersion) {
            if (!id) { return; }

            var selected = lodash.find(collection, function(item) {
                return item.id === parseInt(id);
            });

            var selectedTitle = selected.summary !== undefined ? selected.summary : selected.name;

            if (typeof showAsVersion !== undefined && showAsVersion === true) {
                selectedTitle = selected.version;
            }

            return selected ? {
                value: selected.id,
                title: selectedTitle,
                object: selected
            } : null;
        }

        function searchAction() {
            var params = {
                template: vm.auditModel.selected ? vm.auditModel.selected.object.id : null,
                audit: vm.versionModel.selected ? vm.versionModel.selected.object.id : null,
                auditJson: vm.versionModel.selected ? JSON.stringify({value:vm.versionModel.selected.object.id, title: vm.versionModel.selected.object.version}) : null,
                section: vm.section,
                summary: vm.summary,
                createdAt: vm.createdAt.value ? vm.createdAt.value.startDate.toISOString(true) + ',' + vm.createdAt.value.endDate.endOf('day').toISOString(true) : null,
                completedAt: vm.createdAt.value ? vm.completedAt.value.startDate.toISOString(true) + ',' + vm.completedAt.value.endDate.endOf('day').toISOString(true) : null
            };

            params.siteIds = getSelectedItems(vm.siteModel);
            params.siteGroupIds = getSelectedItems(vm.siteGroupModel);
            params.siteRegionIds = getSelectedItems(vm.regionModel);

            var override = {
                page: 1
            };

            $state.go('.', angular.extend({}, params, override));
        }

        function getSelectedItems(model) {
            if (model.options.selectedItems) {
                var selectedIds = [];
                angular.forEach(model.options.selectedItems, function (item) {
                    selectedIds.push(item.object.id);
                });

                return selectedIds;
            }
        }

        function updateShowPercentages() {
            vm.exportParams = angular.extend({}, $stateParams, {
                'siteIds[]': $stateParams.siteIds,
                'siteGroupIds[]': $stateParams.siteGroupIds,
                'siteRegionIds[]': $stateParams.siteRegionIds,
                showPercentages: !!vm.showPercentages
            });
            showPercentagesService.saveShowPercentageState(!!vm.showPercentages);
        }
    }
})();
