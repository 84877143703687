(function() {
    'use strict';

    angular
        .module('elogbooks.admin.settings')
        .controller('AdminAssetEditController', AdminAssetEditController);

    AdminAssetEditController.$inject = ['apiClient', '$state', 'settingsCollection', 'assetScoreTypesCollection', 'messenger', 'lodash'];

    function AdminAssetEditController(apiClient, $state, settingsCollection, assetScoreTypesCollection, messenger, lodash) {
        var vm = this;
        var payload = {};
        vm.create = updateAction;
        var selectedItems = lodash.find(settingsCollection.settings, { name : 'default_asset_score_types' });
        vm.max = 3;
        vm.labelProps = {max: vm.max};

        vm.assetScoreTypesSelect = {
            response : assetScoreTypesCollection,
            link : assetScoreTypesCollection._links.self.href,
            linkParameters: {'active': 1},
            responseKeyPath: 'scoreTypes'
        };

        // prepare selected
        vm.assetScoreTypesSelect.selectedItems = assetScoreTypesCollection.scoreTypes
            .filter(function (item) {
                if (selectedItems && selectedItems.value) {
                    return lodash.find(selectedItems.value, {'id': item.id});
                }
            })
            .map(function(item) {
                return {
                    value: item.name,
                    href: item.getLink('self'),
                    object: item
                }
            });

        function updateAction () {
            payload = {
                'settings': [
                    {
                        'name': 'default_asset_score_types',
                        'value': vm.assetScoreTypesSelect.selectedItems.map(function (item) {
                            return {name: item.value, id:item.object.id};
                        })
                    }
                ]
            };

            return apiClient.update(settingsCollection.getLink('edit'), payload).then(function (response) {
                if (response) {
                    $state.go('^', {}, {reload: true}).then(function () {
                        messenger.success('ASSET_SCORE_TYPE_UPDATED');
                    });
                } else {
                    messenger.error('ERROR');
                }
            });
        }
    }
})();
