
app.config(function ($translateProvider) {
    $translateProvider.translations('nl', {
        CONTRACT: 'Contract',
        CONTRACTS: 'Contracten',
        CONTRACT_AVAILABLE: 'Contract Beschikbaar?',
        CONTRACT_BOTH: 'Beide (Digitaal en Hardcopy)',
        CONTRACT_ELECTRONIC: 'Digitaal',
        CONTRACT_FORMAT: 'Contract format',
        CONTRACT_HARDCOPY: 'Hardcopy',
        CONTRACT_TYPE: 'Contract Type',
        NOTICE_PERIOD: 'Opzegtermijn',
        SCOPE_AVAILABLE: 'Scope Beschikbaar?',

        PENDING: 'In afwachting',
        ACCEPTED: 'Geaccepteerd',
        REJECTED: 'Afgewezen',

        CONTRACT_APPROVAL_REQUESTED: 'Contract verzoek',

        ADD_NEW_CONTRACT: 'Voeg een nieuw contract toe'
    });
});
