(function() {
    'use strict';

    angular
        .module('elogbooks.common.quote-requests')
        .controller('QuoteRequestRevisionController', QuoteRequestRevisionController);

        QuoteRequestRevisionController.$inject = [
        '$state',
        'apiClient',
        'messenger',
        'quoteResponse',
        'quoteOptionCollectionResponse'
    ];

    function QuoteRequestRevisionController(
        $state,
        apiClient,
        messenger,
        quoteResponse,
        quoteOptionCollectionResponse
    ) {
        var vm = this;

        vm.quoteResponse = quoteResponse;
        vm.form = null;

        vm.previouslyDueAt = new Date(vm.quoteResponse.completionDueAt);

        vm.setForm = (form) => vm.form = form;

        vm.quoteOptionCollectionResponse = quoteOptionCollectionResponse;

        vm.data = { options: quoteOptionCollectionResponse.quoteoptions };
        vm.data.completionDueAt = null;

        vm.submit = submitAction;
        vm.toggleForm = toggleForm;
        vm.toggleButton = toggleButton;
        vm.canSubmit = canSubmit;

        if (vm.data.options.length == 1) {
            vm.data.options[0].hideForm = false;
            vm.data.options[0].toggleButton = false;
            vm.data.options[0].note = '';
        } else {
            angular.forEach(vm.data.options, (option) => {
                option.hideForm = true;
                option.toggleButton = false;
                option.note = '';
            })
        }

        function toggleForm(index) {
            vm.data.options[index].hideForm = !vm.data.options[index].hideForm;
        }

        function toggleButton(index) {
            vm.data.options[index].toggleButton = !vm.data.options[index].toggleButton;
            if(vm.data.options[index].toggleButton) {
                vm.data.options[index].hideForm = false;
            }
        }

        function canSubmit() {
            var requested = false;

            angular.forEach(vm.data.options, function(option) {
                if (
                    option.toggleButton === true &&
                    (option.note !== '' && option.note !== undefined)
                ) {
                    requested = true;
                }
            });

            return requested;
        }

        function submitAction() {
            var data = {
                options: [],
                completionDueAt: vm.data.completionDueAt
             };

            angular.forEach(vm.data.options, function (value, index) {
                if(vm.data.options[index].hideForm && vm.form['note'+index].$invalid) {
                    vm.data.options[index].hideForm = false;
                }
                if (value.note && value.toggleButton === true) {
                    data.options.push({
                        summary: value.summary,
                        note: value.note,
                        _links: { option: { href: value.getLink('self')}}
                    });
                }
            });

            if (vm.form.$invalid) {
                return;
            }

            if (data.options.length > 0) {
                apiClient.create(quoteResponse.getLink('request-revision'), data).then(function (response) {
                    if (response) {
                        messenger.success('QUOTE_WORKFLOW_REQUEST_REVISION_SUCCESS');
                        $state.go('^', {}, {reload: $state.get('^.^.^.^')});
                    } else {
                        messenger.error('REQUEST_ERROR');
                    }
                });
            }
        }
    }
})();
