(function () {
    'use strict';

    angular
        .module('elogbooks.common.set-password')
        .controller('SetPasswordController', SetPasswordController);

    function SetPasswordController($state, apiClient, rootResource, config) {

        var vm = this;

        vm.password = {
            new: null,
            repeated: null
        };
        vm.passwordChanged = false;
        vm.loading = false;
        vm.passwordMatch = true; // set to true to hide error on init
        vm.resetPassword = resetPassword;
        vm.visiblePassword = false;
        vm.visibleRepeatedPassword = false;

        vm.passwordsMatch = function() {
            vm.passwordMatch = true;
            if(vm.password.new && vm.password.repeated && vm.password.new != vm.password.repeated) {
                vm.passwordMatch = false;
                return false;
            }
            return true;
        }

        vm.themePrefix = rootResource?.theme ?? 'elb';
        vm.themeLogo = `/${vm.themePrefix}-logo.svg`;

        function resetPassword() {
            vm.loading = true;
            vm.accountUnlock = config.accountUnlock

            apiClient.create(rootResource.getLink(vm.accountUnlock ? 'accountunlock' : 'passwordchange'), {
                password: vm.password.new,
                token: $state.params.token
            }).then(function (response) {
                if (response) {
                    vm.loading = false;
                    vm.passwordChanged = true;
                } else {
                    vm.loading = false;
                }
            });
        }

    }

})();
