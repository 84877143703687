 (function() {
     'use strict';

     angular
        .module('elogbooks.admin.client-billing')
        .controller('HistoricalReportsController', HistoricalReportsController);

    HistoricalReportsController.$inject = ['$stateParams', '$state', 'reportsCollection', 'elbDownloadService',];

    function HistoricalReportsController($stateParams, $state, reportsCollection, elbDownloadService) {
        var vm = this;
        vm.reportsCollection = reportsCollection;
        vm.download = elbDownloadService.download;

        vm.order = orderAction;

        function orderAction(key) {
            $stateParams.clientBillingReportOrder = key;
            $state.go('.', angular.extend({}, $stateParams), { reload: $state.current });
        }
    };
 })();
