app.config(function ($translateProvider) {
    $translateProvider.translations('nl', {
        SERVICE_TYPES: 'Onderhoudstype',
        SERVICE_TYPES_LIST: 'Onderhoudstypes',
        SERVICE_TYPES_ADD: 'Voeg onderhoudstype toe',
        SERVICE_TYPES_EDIT: 'Wijzig onderhoudstype',
        SERVICE_TYPES_UPDATED: 'Onderhoudstype gewijzigd',

        SERVICE_TYPE: 'Onderhoudstype',
        SERVICE_TYPE_LIST: 'Onderhoudstypes',
        SERVICE_TYPE_ADD: 'Voeg nieuw onderhoudstype toe',
        SERVICE_TYPE_EDIT: 'Wijzig onderhoudstype',
        SERVICE_TYPE_ADDED: 'Onderhoudstype toegevoegd',
        SERVICE_TYPE_UPDATED: 'Onderhoudstype aangepast',
        SERVICE_TYPE_DELETED: 'Onderhoudstype verwijderd'
    });
});
