(function() {
    'use strict';

    angular
        .module('elogbooks')
        .config(Translations);

    function Translations($translateProvider) {
        $translateProvider.translations('nl', {
            FILE_VISIBILITY_PUBLIC: 'Openbaar',
            FILE_VISIBILITY_PARTNERSHIPS: 'Samenwerkingen',
            FILE_VISIBILITY_OCCUPIERS: 'Huurders'
        });
    }
})();
