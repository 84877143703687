(function() {
    'use strict';

    angular
        .module('elogbooks.user.timesheets')
        .controller('UserTimesheetsSubmitRequestController', Controller('SUBMIT_APPROVAL', 'submit', false))
        .controller('UserTimesheetsApproveRequestController', Controller('ACCEPT', 'approve', false))
        .controller('UserTimesheetsRejectRequestController', Controller('REJECT', 'reject', true));


    function Controller (translation, action, noteRequired) {
        return function (apiClient, timesheetWeek, $state, messenger) {
            var vm = this;
            vm.submit = submitAction;
            vm.action = action;
            vm.translation = 'TIMESHEET_WORKFLOW_' + translation;
            vm.noteRequired = noteRequired;
            vm.hideNote = false;
            vm.data = {}
            vm.submitDisabled = false;

            function submitAction () {
                apiClient.create(timesheetWeek.getLink(action), vm.data).then(function(response) {
                    if (response) {
                        $state.go('^', {}, { reload: 'dashboard.user.timesheets.list' }).then(function () {
                            messenger.success(vm.translation + '_SUCCESS');
                        });
                    }
                })
            }
        }
    }
})();
