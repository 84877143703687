angular.module('elogbooksServices').service('iconService', function() {

    var iconMap = {
        ACTIONS: 'flag',
        AUDITS: 'clipboard',
        ASSOCIATES: 'handshake-o',
        ASSETS: 'cubes',
        CONTACTS: 'phone-square',
        FILES: 'file-o',
        FILES_JOB_FILES: 'briefcase',
        FORMS: 'file-text',
        INFO: 'info-circle',
        JOBS: 'briefcase',
        METERS: 'tachometer',
        MASTER_TASKS: 'calendar-check-o',
        OPERATIVE_PERMISSIONS: 'user',
        PATROLS: 'repeat',
        QUOTES: 'calculator',
        REPORT_PERMISSIONS: 'line-chart',
        ROLES: 'user',
        SERVICE_PROVIDERS: 'wrench',
        LABOUR: 'list-alt',
        OPERATIVES: 'users',
        SERVICES: 'cog',
        SITE_ACCESS: 'building',
        SITE_NOTES: 'pencil',
        SITE_PERMISSIONS: 'building',
        SURVEYS: 'check-square-o',
        TENANTS: 'users',
        USER_PERMISSIONS: 'user',
        USER_ROLES: 'user',
        PERFORMANCE_STATISTICS: 'line-chart',
        USER_TOKENS: 'ticket',
        CONTRACTS: 'file-text-o',
        TRADE_TYPES:'handshake-o',
        ACTIVITY_LOG:'book',
        SERVICE_TYPES: 'folder-open',
        WEBHOOKS: 'external-link',
        CORE_CLIENT_MARKUP: 'list-alt',
        BILLING_SETTINGS: 'cubes',
    };

    /**
     * Get the font awesome icon representing the key
     * - returns the key if not found in the icon map.
     * @param key
     * @returns {string}
     */
    this.getMap = function (key) {
        if (typeof iconMap[key] !== 'undefined') {
            return iconMap[key];
        } else {
            return key;
        }
    };

    this.getAll = function () {
        return iconMap;
    };

    return this;
});
