app.config( function($translateProvider) {
    $translateProvider.translations('nl', {
        SITE_RELATIONSHIPS: 'Relatie tot object',
        SITE_RELATIONSHIP_INFORMATION: 'Informatie over objectrelaties',

        ADD_NEW_SITE_RELATIONSHIP: 'Maak een nieuwe soort objectrelatie aan',
        EDIT_SITE_RELATIONSHIP: 'Wijzig objectrelatie',

        SITE_RELATIONSHIP_LIST: 'Objectrelaties',
        SITE_RELATIONSHIP_ADD: 'Voeg een nieuwe objectrelatie toe',
        SITE_RELATIONSHIP_EDIT: 'Wijzig objectrelatie',

        SITE_RELATIONSHIP_CREATED: 'Objectrelatie gemaakt',
        SITE_RELATIONSHIP_UPDATED: 'Objectrelatie aangepast',
        SITE_RELATIONSHIP_DELETED: 'Objectrelatie verwijderd',
    });
});
