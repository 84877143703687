(function () {
    'use strict';

    angular
        .module('elogbooks.common.audit')
        .controller('AuditCancelController', AuditCancelController);

    AuditCancelController.$inject = ['$uibModalInstance', 'apiClient'];

    function AuditCancelController ($uibModalInstance, apiClient) {
        var vm = this;
        vm.close = closeModal;
        vm.confirm = confirm;
        vm.data = {'cancelReason': ''};

        function closeModal() {
            $uibModalInstance.dismiss('cancel');
        };

        function confirm() {
            $uibModalInstance.close(vm.data);
        };
    }
})();
