(function() {
    'use strict';

    angular
        .module('elogbooks.admin.labour-types')
        .controller('LabourTypeDetailsController', LabourTypeDetailsController);

    LabourTypeDetailsController.$inject = ['labourTypeResponse'];
    function LabourTypeDetailsController(labourTypeResponse) {
        var vm = this;
        vm.labourType = labourTypeResponse;
    }
})();
