angular.module('elogbooksServices').service('bulkCancelJobsModal', ['apiClient', '$uibModal', function (apiClient, $uibModal) {
    return {
        open: function (jobs, rootResourceResponse, userResponse, selectedSiteResponse, jobsCount, config, selected) {
            return $uibModal.open({
                templateUrl: '/modules/common/modals/bulk-update/bulkaction-modal.html',
                controller: 'BulkActionCreateController',
                controllerAs: 'vm',
                resolve: {
                    config: function () {
                        var defaultConfig = {
                            titleMessage: 'CANCEL_JOBS',
                            updateMessage: 'JOBS_QUEUED_FOR_CANCELLATION',
                            bodyMessage: 'ARE_YOU_SURE',
                            selected: selected,
                            bulkAsssigntype: 'cancel',
                            noteRequired: true
                        };

                        return angular.extend({}, defaultConfig, config);
                    },
                    serviceProviderResponse:function() {
                        return null;
                    },
                    jobs: function() {
                        return jobs;
                    },
                    jobsCount: function() {
                        return jobsCount;
                    },
                    selectedSite: function() {
                        return selectedSiteResponse;
                    },
                    selectedSP: function() {
                        return null;
                    },
                    rootResourceResponse: function() {
                        return rootResourceResponse;
                    },
                    user: function() {
                        return userResponse;
                    },
                    canBulkReassignOperative: function() {
                        return null;
                    }
                }
            });
        }
    };
}]);
