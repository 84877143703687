angular.module('elogbooksControllers').controller('LayoutController', function ($scope, $rootScope, $state) {
    $scope.$state = $state;
    let vm = this;

    let faviconPostFix = '-favicon-32x32.png';
    vm.favicon = 'elb' + faviconPostFix;

    $scope.$watch(function() {
        return $rootScope.theme;
    }, function(newValue, oldValue) {
        if (newValue !== oldValue) {
            document.title = newValue === 'efs' ? 'Elogs Facilities Services' : 'Elogs CAFM';

            if (newValue + faviconPostFix !== vm.favicon) {
                vm.favicon = newValue + faviconPostFix;
            }
        }
    });

    // Loader indicator for when the dashboard is loading
    $scope.showLoader = true;
});
