(function () {
    'use strict';

    angular
        .module('elogbooks.common.service-types')
        .controller('CommonServiceTypesController', CommonServiceTypesController);

    CommonServiceTypesController.$inject = ['confirmationModal', 'serviceTypesCollectionResponse', 'lodash', 'crudService', '$state', 'messenger', 'config'];

    function CommonServiceTypesController(confirmationModal, serviceTypesCollectionResponse, lodash, crudService, $state, messenger, config) {
        var vm = this;
        vm.serviceTypes = serviceTypesCollectionResponse.serviceTypes;
        vm.serviceTypesCollectionResponse = serviceTypesCollectionResponse;
        vm.delete = deleteServiceType;
        vm.noBorder = config.noBorder;

        function deleteServiceType(serviceType) {
            vm.data = {
                _links: {
                    serviceTypes: []
                }
            };

            lodash.each(vm.serviceTypes, function (existingServiceType) {
                if (existingServiceType !== serviceType) {
                    vm.data._links.serviceTypes.push(existingServiceType._links.self);
                }
            });

            return confirmationModal.open().result
                .then(function (response) {
                    if (response.result) {
                        return crudService.update(
                            vm.serviceTypesCollectionResponse.getLink('edit'),
                            vm.data,
                            $state.get('.'),
                            $state.get('^.^'),
                            'SERVICE_TYPES'
                        );
                    }
                });
        }
    }
})();
