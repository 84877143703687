(function () {
    'use strict';

    angular
        .module('elogbooks.admin.service-provider-groups')
        .controller('ServiceProviderGroupsAddEditController', ServiceProviderGroupsAddEditController);

    ServiceProviderGroupsAddEditController.$inject = ['serviceProviderGroupsResponse', 'requestDataFactory', 'serviceProviderGroupCollectionResponse', 'crudService'];

    function ServiceProviderGroupsAddEditController (serviceProviderGroupsResponse, requestDataFactory, serviceProviderGroupCollectionResponse, crudService) {
        var vm = this;
            vm.group = serviceProviderGroupsResponse;
            vm.data = requestDataFactory.createRequest(getDefaults());

        vm.create = createAction;
        vm.update = updateAction;
        vm.delete = deleteAction;

        function getDefaults() {
            return {
                id: vm.group.id || null,
                name: vm.group.name || null,
            };
        }

        function createAction () {
            return crudService.create(
                serviceProviderGroupCollectionResponse.getLink('create'),
                vm.data,
                '^',
                '',
                'SERVICE_PROVIDER_GROUP'
            );
        }

        function updateAction () {
            return crudService.update(
                vm.group.getLink('self'),
                vm.data,
                'dashboard.admin.service-provider-groups.list.details.info',
                'dashboard.admin.service-provider-groups.list.details',
                'SERVICE_PROVIDER_GROUP'
            );
        }

        function deleteAction () {
            return crudService.delete(
                vm.group.getLink('delete'),
                'dashboard.admin.service-provider-groups.list',
                'SERVICE_PROVIDER_GROUP'
            );
        }
    }
})();
