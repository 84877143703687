(function() {
    'use strict';

    angular
        .module('elogbooks.common.quote-requests')
        .controller('CommonQuoteRequestAddSiteController', CommonQuoteRequestAddSiteController);

    CommonQuoteRequestAddSiteController.$inject = [
        '$rootScope',
        '$scope',
        '$stateParams',
        '$state',
        'siteResponse',
        'siteCollectionResponse',
        'apiClient',
        'confirmationModal',
        'quoteCreateService'
    ];

    function CommonQuoteRequestAddSiteController(
        $rootScope,
        $scope,
        $stateParams,
        $state,
        siteResponse,
        siteCollectionResponse,
        apiClient,
        confirmationModal,
        quoteCreateService
    ) {
        var vm = $scope.vm;
        vm.canNext = true;
        vm.canPrevious = false;
        vm.canSubmit = false;
        vm.title = 'QUOTE_REQUEST_ADD_SITE';

        vm.siteNoteCollectionResponse = [];

        vm.next = nextAction;
        vm.changeSite = changeSite;

        if (!$stateParams.siteResource && siteResponse) {
            $stateParams.siteResource = siteResponse.getLink('self').encode();
            $state.go('.', $stateParams, {notify: false});
        }

        vm.selectedSiteModel = {
            response: siteCollectionResponse,
            link: siteCollectionResponse ? siteCollectionResponse.getLink('self') : null,
            required: true,
            responseKeyPath: 'sites',
            itemHrefPath: '_links.self.href',
            itemValuePath: 'name',
            onSelect: vm.changeSite,
            disabled: vm.config.siteDisabled,
            populateDisabled: true
        };

        if (vm.selectedSite) {
            vm.selectedSiteModel.selected = {
                object: vm.selectedSite,
                href: vm.selectedSite.getLink('self'),
                value: vm.selectedSite.name
            };
        } else if (siteResponse) {
            vm.selectedSiteModel.selected = {
                object: siteResponse,
                href: siteResponse.getLink('self'),
                value: siteResponse.name
            };
            vm.selectedSite = vm.selectedSiteModel.selected.object;

            if (!$stateParams.siteResource) {
                changeSite();
            } else {
                apiClient.get(siteResponse.getLink('notes'), {allowHelpdesk: true}).then(function(response) {
                    vm.siteNoteCollectionResponse = response;
                    nextAction();
                })
            }
        }

        function changeSite() {
            quoteCreateService.changeSite(
                vm.selectedSiteModel,
                vm.selectedSite
            );
        }

        function nextAction() {
            var nextRoute = vm.config.addReporter && !$stateParams.budgetQuoteConversion ? vm.getRoute('contact') : vm.getRoute('details');

            if (vm.config.showSiteNotes && vm.siteNoteCollectionResponse.siteNotes && vm.siteNoteCollectionResponse.siteNotes.length) {
                var config = {
                    primaryClass: 'btn-primary',
                    primaryText: 'OK',
                    secondaryText: 'CANCEL',
                    titleMessage: 'SITE_NOTES',
                    template: '/modules/common/site/notes.html',
                    data: vm.siteNoteCollectionResponse.siteNotes
                };
                vm.siteNotesConfirmed = false;

                return confirmationModal.open(config).result
                    .then(function() {
                        vm.siteNotesConfirmed = true;
                        $state.go(nextRoute, {}, {reload: false});
                    });
            } else {
                vm.siteNotesConfirmed = true;
                $state.go(nextRoute, {siteResource: vm.selectedSite.getLink('self').encode()}, {reload: false});
            }
        }
    }
})();
