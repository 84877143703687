(function() {
    'use strict';

    angular
        .module('elogbooks')
        .config(Translations);

    function Translations($translateProvider) {
        $translateProvider.translations('zh-hk', {
            EXTEND_SESSION_HEADER: '延長會議',
            EXTEND_SESSION_BODY: '你將會自動登出於 <strong>{{ ::timeRemaining }}</strong>. 為將你的會議延長24小時,請點擊以下 \'Extend\' .',
            EXTEND_SESSION: '延長',
            UPDATE_POSTPONE: '延遲更新',
            LOGOUT: '登出'
        });
    }
})();
