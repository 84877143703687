(function() {
    'use strict';

    angular
        .module('elogbooks.common.jobs.workflow')
        .controller('JobEditController', JobEditController);

    JobEditController.$inject = ['apiClient', '$state', 'messenger', 'jobResponse', 'formData', 'scopeData',
        '$q', 'userCollectionResponse', 'siteResourceResponse', 'userManager', 'siteRelationshipResponse',
        'jobGroupCollectionResponse', 'jobGroupRequired', 'chargeTypesCollectionResponse',
        'chargeTypeResponse', 'jobSettings', 'documentTypesCollectionResponse', 'documentTypeService', 'lodash', 'confirmationModal',
        '$translate'];

    function JobEditController(
        apiClient, $state, messenger, jobResponse, formData, scopeData,
        $q, userCollectionResponse, siteResourceResponse, userManager, siteRelationshipResponse,
        jobGroupCollectionResponse, jobGroupRequired, chargeTypesCollectionResponse, chargeTypeResponse, jobSettings,
        documentTypesCollectionResponse, documentTypeService, lodash, confirmationModal, $translate
    ) {

        var vm = this;
        vm.job = jobResponse;
        angular.extend(vm, scopeData);
        vm.data = formData;

        vm.submitDisabled = false;
        vm.canSubmit = true;
        vm.userReporterResponse = {};
        vm.userContactResponse = {};
        vm.isStatutory = (jobResponse.getLink('statutorytype')) ? true : false;
        vm.poRequired = false;
        vm.hasChargeType = false;
        vm.isReporterTenant = (jobResponse.reporter) ? jobResponse.reporter.isTenant : false;
        vm.isContactTenant = (jobResponse.siteContact) ? jobResponse.siteContact.isTenant : false;
        vm.canSeeTenants = siteResourceResponse.getLink('tenantLogging') !== null;
        vm.jobGroupChildSelectMandatory = jobSettings.jobGroupChildSelectMandatory;
        vm.requiredDocumentType = documentTypesCollectionResponse;
        vm.selectedRequiredDocumentTypes = [];
        vm.requireDocumentTypeWhenRaisingReactiveJob = jobSettings.requireDocumentTypeWhenRaisingReactiveJob;
        vm.siteRequiredDocumentTypeRequired = siteResourceResponse.excludeFromRequiringDocumentType;

        angular.forEach(jobResponse.requiredDocumentTypes.documentTypes, function(docType) {
            vm.selectedRequiredDocumentTypes.push(docType);
        });

        vm.originalApprovalDocTypes = extractApprovalDocTypes(vm.selectedRequiredDocumentTypes);

        if (jobResponse.type == "reactive" && !jobResponse.reporter.email) {
            vm.data.reporter.declineEmail = true;
        }

        if (jobResponse.type == "reactive" && jobResponse.siteContact.email) {
            vm.data.siteContact.declineEmail = true;
        }

        vm.data.requiredDocumentTypes = {
            links: null
        }

        vm.submit = submitAction;
        vm.findPseudoUsers = findPseudoUsers;
        vm.unsetStatutory = unsetStatutory;
        vm.toggleDeclineEmail = toggleDeclineEmail;
        vm.changeReporterTenant = changeReporterTenant;
        vm.changeContactTenant = changeContactTenant;
        vm.removeDocumentType = removeDocumentType;

        vm.reporterChangeUser = reporterChangeUser;
        vm.reporterChangeRelationship = reporterChangeRelationship;
        vm.reporterPseudoUserSelected = reporterPseudoUserSelected;
        vm.selectAsOccupier = selectAsOccupier;

        vm.contactChangeUser = contactChangeUser;
        vm.contactChangeRelationship = contactChangeRelationship;
        vm.contactPseudoUserSelected = contactPseudoUserSelected;
        vm.changeChargeType = changeChargeType;
        vm.changeRequiredDocumentType = changeRequiredDocumentType;

        vm.selectedJobGroupModel = {
            response: jobGroupCollectionResponse,
            link: jobGroupCollectionResponse.getLink('self'),
            required: jobGroupRequired,
            responseKeyPath: 'jobgroups',
            itemHrefPath: '_links.self.href',
            itemValuePath: 'name',
            isHierarchical: true
        };
        if(jobResponse.type == "reactive" && jobResponse.getLink('jobgroup')) {
            vm.selectedJobGroupModel.selected = jobResponse._links.jobgroup;
            vm.data._links['jobgroup'] = jobResponse._links.jobgroup;
        }

        vm.selectedUserReporterModel = {
            response: userCollectionResponse,
            link: userManager.user.getLink('users'),
            linkParameters: { 'site': siteResourceResponse.getData().id, status: 'active' },
            required: false,
            responseKeyPath: 'users',
            itemHrefPath: '_links.self.href',
            itemValuePath: 'name',
            onSelect: vm.reporterChangeUser,
            onRemove: vm.reporterChangeUser,
            disabled: false
        };

        if (jobResponse.type == "reactive" && jobResponse.reporter._links && jobResponse.reporter.getLink('user')) {
            vm.selectedUserReporterModel.selected = jobResponse.reporter._links.user;
            vm.data._links['reporter'] = jobResponse.reporter._links.user;
        }

        vm.selectedReporterRelationshipModel = {
            response: siteRelationshipResponse,
            link: siteRelationshipResponse.getLink('self'),
            required: true,
            responseKeyPath: 'siterelationships',
            itemHrefPath: '_links.self.href',
            itemValuePath: 'name',
            onSelect: vm.reporterChangeRelationship,
            onRemove: vm.reporterChangeRelationship,
        };
        if (jobResponse.type == "reactive" && jobResponse.reporter._links && jobResponse.reporter.getLink('relationship')) {
            vm.selectedReporterRelationshipModel.selected = jobResponse.reporter._links.relationship;
            vm.data._links['reportersiterelationship'] = jobResponse.reporter._links.relationship;
        }

        vm.selectedUserContactModel = {
            response: userCollectionResponse,
            link: userManager.user.getLink('users'),
            linkParameters: { 'site': siteResourceResponse.getData().id, status: 'active' },
            required: false,
            responseKeyPath: 'users',
            itemHrefPath: '_links.self.href',
            itemValuePath: 'name',
            onSelect: vm.contactChangeUser,
            onRemove: vm.contactChangeUser,
            disabled: false
        };
        if (jobResponse.type == "reactive" && jobResponse.siteContact._links && jobResponse.siteContact.getLink('user')) {
            vm.selectedUserContactModel.selected = jobResponse.siteContact._links.user;
            vm.data._links['contact'] = jobResponse.siteContact._links.user;
        }

        vm.selectedTenantReporterModel = {
            response: userCollectionResponse,
            link: siteResourceResponse.getLink('tenantLogging'),
            linkParameters: {'site': siteResourceResponse.getData().id, 'order': 'name', status: 'active' },
            responseKeyPath: 'contacts',
            itemHrefPath: '_links.self.href',
            itemValuePath: 'nameAndCompany',
            onSelect: vm.changeReporterTenant,
            onRemove: vm.changeReporterTenant
        };

        if (jobResponse.type === "reactive" && vm.isReporterTenant && jobResponse.reporter._links) {
            vm.data.reporterTenant = jobResponse.reporter;

            vm.selectedTenantReporterModel.selected = {
                object: jobResponse.reporter,
                href: jobResponse.reporter.getLink('self'),
                value: jobResponse.reporter.name
            };

            vm.data.reporter = null;
            vm.data._links.reporterTenant = jobResponse.reporter.getLink('self');

            vm.selectAsOccupier('reporter');
        }

        vm.selectedTenantContactModel = {
            response: userCollectionResponse,
            link: siteResourceResponse.getLink('tenantLogging'),
            linkParameters: { 'site': siteResourceResponse.getData().id, 'order': 'name', status: 'active' },
            responseKeyPath: 'contacts',
            itemHrefPath: '_links.self.href',
            itemValuePath: 'nameAndCompany',
            onSelect: vm.changeContactTenant,
            onRemove: vm.changeContactTenant
        };

        if (jobResponse.type === "reactive" && vm.isContactTenant && jobResponse.siteContact._links ) {
            vm.data.contactTenant = jobResponse.siteContact;

            vm.selectedTenantContactModel.selected = {
                object: jobResponse.siteContact,
                href: jobResponse.siteContact.getLink('self'),
                value: jobResponse.siteContact.name
            };

            vm.data.siteContact = null;
            vm.data._links.contactTenant = jobResponse.siteContact.getLink('self');

            vm.selectAsOccupier('contact');
        }

        vm.selectedChargeTypeModel = {
            response: chargeTypesCollectionResponse,
            required: true,
            responseKeyPath: 'chargetypes',
            itemHrefPath: '_links.self.href',
            itemValuePath: 'name',
            onSelect: vm.changeChargeType,
            onRemove: vm.changeChargeType
        };
        if (chargeTypesCollectionResponse.chargetypes.length > 0) {
            vm.hasChargeType = true;
        }

        if (chargeTypeResponse) {
            vm.selectedChargeTypeModel.selected = {
                href : chargeTypeResponse._links.self,
                value : chargeTypeResponse.name
            };
            vm.changeChargeType();
        }

        vm.selectedContactRelationshipModel = {
            response: siteRelationshipResponse,
            link: siteRelationshipResponse.getLink('self'),
            required: true,
            responseKeyPath: 'siterelationships',
            itemHrefPath: '_links.self.href',
            itemValuePath: 'name',
            onSelect: vm.contactChangeRelationship,
            onRemove: vm.contactChangeRelationship
        };

        if (jobResponse.type == "reactive" && jobResponse.siteContact._links && jobResponse.siteContact.getLink('relationship')) {
            vm.selectedContactRelationshipModel.selected = jobResponse.siteContact._links.relationship;
            vm.data._links['sitecontactsiterelationship'] = jobResponse.siteContact._links.relationship;
        }

        vm.selectedRequiredDocumentTypeModel = {
            response: documentTypesCollectionResponse,
            required: false,
            responseKeyPath: 'documentTypes',
            itemHrefPath: '_links.self.href',
            itemValuePath: 'name',
            linkParameters: {status: 'active'},
            onSelect: changeRequiredDocumentType,
            onRemove: changeRequiredDocumentType,
        };

        if (jobResponse.getLink('required-document-type')) {
            vm.selectedRequiredDocumentTypeModel.selected = {
                href: jobResponse.getLink('required-document-type'),
                value: jobResponse.getLinkAttribute('required-document-type', 'title')
            };
        }

        function removeDocumentType(documentType) {
            documentTypeService.removeDocumentType(vm, documentType);
        }

        vm.certificateRequiredDocType = null;
        angular.forEach(vm.requiredDocumentType.documentTypes, function (value) {
            if (value.name === 'Certificate') {
                vm.certificateRequiredDocType = value;
            }
        });

        function changeRequiredDocumentType() {
            documentTypeService.changeRequiredDocumentType(vm);
        }


        function toggleDeclineEmail(jobCallerContact) {
            if (jobCallerContact.declineEmail) {
                jobCallerContact.email = null;
                jobCallerContact.notifyOnCreate = false;
                jobCallerContact.notifyOnComplete = false;
            }
        }

        function findPseudoUsers(name) {
            return apiClient.get(siteResourceResponse.getLink('pseudousers'), {name: name})
                .then(function(response) {
                    return response['site-pseudo-users'];
                }, function(error) {
                    console.log(error);
                });
        }


        function unsetStatutory() {
            delete vm.data._links.statutorytype;

            if (vm.certificateRequiredDocType) {
                removeDocumentType(vm.certificateRequiredDocType);
            }
        }

        function selectAsOccupier($type) {
            var model = null;
            switch ($type){
                case 'reporter':
                    model = vm.selectedReporterRelationshipModel;
                    break;
                case 'contact':
                    model = vm.selectedContactRelationshipModel;
                    break;
            }


            if (model) {
                model.selected = siteRelationshipResponse.siterelationships
                    .filter(function (item) {
                        return item.name === 'Occupier';
                    }).map(function(response){
                        return {
                            href:response._links.self.href,
                            obj:response,
                            value:response.name
                        };
                    })[0];
            }
        }

        function changeReporterTenant () {
            if (vm.selectedTenantReporterModel.selected && vm.selectedTenantReporterModel.selected.object) {
                vm.selectedTenantReporter = vm.selectedTenantReporterModel.selected.object;

                vm.submitDisabled = true;

                $q.all([
                    apiClient.get(vm.selectedTenantReporter.getLink('self')).then(function (response) {
                        if (typeof vm.data.reporterTenant === 'undefined' || vm.data.reporterTenant === null || vm.data.reporterTenant.id != response.id) {
                            vm.data.reporterTenant = vm.tenantReporterResponse = response;
                        }
                    })
                ]).then(function () {
                    vm.data._links.reporterTenant = vm.selectedTenantReporter.getLink('self');


                    if (vm.data.reporterTenant.contactInformation.fields.length > 0) {
                        var contactInformation = vm.data.reporterTenant.contactInformation.fields;
                        angular.forEach(contactInformation, function (contact) {
                            if (contact.type === 'email') {
                                vm.data.reporterTenant.email = contact.value;
                            }
                            if (contact.type === 'telephone') {
                                vm.data.reporterTenant.phone = contact.value;
                            }
                        });
                    } else {
                        vm.data.reporterTenant.email = vm.selectedTenantReporter.email;
                        vm.data.reporterTenant.phone = vm.selectedTenantReporter.phone;
                    }

                    vm.isReporterTenant = true;
                    vm.data.reporter = null;

                    vm.data.reporterTenant.name = vm.selectedTenantReporter.name;

                    delete vm.data._links.reporter;
                    vm.submitDisabled = false;

                    vm.selectAsOccupier('reporter');
                });
            } else {
                vm.isReporterTenant = false;
                if (vm.selectedReporterRelationshipModel) {
                    vm.selectedReporterRelationshipModel.selected = null;
                }
                delete vm.data._links.reporterTenant;
                vm.data.reporterTenant = null;
            }
        }

        function changeContactTenant () {
            if (vm.selectedTenantContactModel.selected && vm.selectedTenantContactModel.selected.object) {
                vm.selectedTenantContact = vm.selectedTenantContactModel.selected.object;

                vm.submitDisabled = true;

                $q.all([
                    apiClient.get(vm.selectedTenantContact.getLink('self')).then(function (response) {
                        if (typeof vm.data.contactTenant === 'undefined' || vm.data.contactTenant === null || vm.data.contactTenant.id != response.id) {
                            vm.data.contactTenant = vm.tenantContactResponse = response;
                        }
                    })
                ]).then(function () {
                    vm.data._links.contactTenant = vm.selectedTenantContact.getLink('self');

                    if (vm.data.contactTenant.contactInformation.fields.length > 0) {
                        var contactInformation = vm.data.contactTenant.contactInformation.fields;
                        angular.forEach(contactInformation, function (contact) {
                            if (contact.type === 'email') {
                                vm.data.contactTenant.email = contact.value;
                            }
                            if (contact.type === 'telephone') {
                                vm.data.contactTenant.phone = contact.value;
                            }
                        });
                    } else {
                        vm.data.contactTenant.email = vm.selectedTenantContact.email;
                        vm.data.contactTenant.phone = vm.selectedTenantContact.phone;
                    }

                    vm.isContactTenant = true;
                    vm.data.siteContact = null;

                    vm.data.contactTenant.name = vm.selectedTenantContact.name;

                    delete vm.data._links.siteContact;
                    vm.submitDisabled = false;

                    vm.selectAsOccupier('contact');
                });
            } else {
                vm.isContactTenant = false;
                if (vm.selectedContactRelationshipModel) {
                    vm.selectedContactRelationshipModel.selected = null;
                }
                delete vm.data._links.contactTenant;
                vm.data.contactTenant = null;
            }
        }

        function contactChangeUser() {
            if (vm.selectedUserContactModel.selected && vm.selectedUserContactModel.selected.object) {
                vm.selectedUserContact = vm.selectedUserContactModel.selected.object;

                $q.all([
                    apiClient.get(vm.selectedUserContact.getLink('self')).then(function(response) {
                        if (typeof vm.data.siteContact === 'undefined' || vm.data.siteContact === null || vm.data.siteContact.id != response.id) {
                            vm.data.siteContact = vm.userContactResponse = response;
                            vm.data.siteContact.phoneNumbers = [];
                            angular.forEach(response.contactInformation.fields, function(value, index) {
                                if (value.type === 'telephone') {
                                    vm.data.siteContact.phoneNumbers.push(value);
                                }
                            });
                        }
                    })
                ]).then(function() {
                    vm.data._links.contact = vm.selectedUserContact.getLink('self');
                    vm.data.siteContact.phone = vm.data.siteContact.telephone;

                    delete vm.data._links.contactTenant;
                });
            } else {
                vm.data._links.contact = vm.data.siteContact = null;
            }
        }

        function contactPseudoUserSelected(item) {
            vm.data.siteContact.name = item.name;
            vm.data.siteContact.email = item.email;
            vm.data.siteContact.phone = item.phone;
            vm.data.siteContact.notifyOnCreate = item.notifyOnCreation;
            vm.data.siteContact.notifyOnComplete = item.notifyOnCompletion;

            var pseudoUserSiteRelationship = siteRelationshipResponse.siterelationships.find(function(elem) {
                return elem.getLink('self') === item.getLink('site-relationship');
            });

            vm.selectedContactRelationshipModel.selected = {
                object: pseudoUserSiteRelationship,
                href: pseudoUserSiteRelationship.getLink('self'),
                value: pseudoUserSiteRelationship.name
            };

            contactChangeRelationship();
        }

        function contactChangeRelationship() {
            if (vm.selectedContactRelationshipModel.selected && vm.selectedContactRelationshipModel.selected.object) {
                vm.selectedContactRelationship = vm.selectedContactRelationshipModel.selected.object;
                vm.data._links.sitecontactsiterelationship = {href: vm.selectedContactRelationship.getLink('self')};
            } else {
                vm.data._links.sitecontactsiterelationship = null;
            }
        }

        function reporterChangeUser() {
            if (vm.selectedUserReporterModel.selected && vm.selectedUserReporterModel.selected.object) {
                vm.selectedUserReporter = vm.selectedUserReporterModel.selected.object;

                $q.all([
                    apiClient.get(vm.selectedUserReporter.getLink('self')).then(function(response) {
                        if (typeof vm.data.reporter === 'undefined' || vm.data.reporter === null || vm.data.reporter.id != response.id) {
                            vm.data.reporter = vm.userReporterResponse = response;
                            vm.data.reporter.phoneNumbers = [];
                            angular.forEach(response.contactInformation.fields, function(value, index) {
                                if (value.type === 'telephone') {
                                    vm.data.reporter.phoneNumbers.push(value);
                                }
                            });
                        }
                    })
                ]).then(function() {
                    vm.data._links.reporter = vm.selectedUserReporter.getLink('self');
                    vm.data.reporter.phone = vm.data.reporter.telephone; //JobCallerContact expects "phone"

                    delete vm.data._links.reporterTenant;
                });
            } else {
                vm.data._links.reporter = vm.data.reporter = null;
            }
        }

        function reporterPseudoUserSelected(item) {
            vm.data.reporter.name = item.name;
            vm.data.reporter.email = item.email;
            vm.data.reporter.phone = item.phone;
            vm.data.reporter.notifyOnCreate = item.notifyOnCreation;
            vm.data.reporter.notifyOnComplete = item.notifyOnCompletion;

            var pseudoUserSiteRelationship = siteRelationshipResponse.siterelationships.find(function(elem) {
                return elem.getLink('self') === item.getLink('site-relationship');
            });

            vm.selectedReporterRelationshipModel.selected = {
                object: pseudoUserSiteRelationship,
                href: pseudoUserSiteRelationship.getLink('self'),
                value: pseudoUserSiteRelationship.name
            };

            reporterChangeRelationship();
        }

        function reporterChangeRelationship() {
            if (vm.selectedReporterRelationshipModel.selected && vm.selectedReporterRelationshipModel.selected.object) {

                vm.selectedReporterRelationship = vm.selectedReporterRelationshipModel.selected.object;
                vm.data._links.reportersiterelationship = vm.selectedReporterRelationship.getLink('self');
            } else {
                vm.data._links.reportersiterelationship = null;
            }
        }

        function extractApprovalDocTypes(docTypes) {
            var result = [];
            angular.forEach(docTypes, function(docType) {
                if (docType.approvalRequired) {
                    result.push(docType.id);
                }
            });

            return result;
        }

        function setsAreEqual(set1, set2) {
            if (set1.length !== set2.length) {
                return false;
            }

            for (const documentType of set1) {
                if (!set2.includes(documentType)) {
                    return false;
                }
            }

            return true;
        }

        function submitAction() {
            var approvalDocTypes =  extractApprovalDocTypes(vm.selectedRequiredDocumentTypes);
            if (setsAreEqual(approvalDocTypes, vm.originalApprovalDocTypes)) {
                return doSubmit();
            }

            var config = {
                bodyMessage: null,
                htmlBodyMessage: $translate.instant('JOB_WORKFLOW_EDIT_JOB_DOCS_CONFIRMATION')
            }
            return confirmationModal.open(config).result.then(function() {
                doSubmit();
            });
        }

        function doSubmit() {
            vm.data.requiredDocumentTypes.links = [];
            angular.forEach(vm.selectedRequiredDocumentTypes, function(value) {
                vm.data.requiredDocumentTypes.links.push({
                    href: value._links.self.href
                });
            });

            if (vm.data._links.hasOwnProperty('statutorytype') && (!vm.data._links.statutorytype || vm.data._links.statutorytype.href === null)) {
                delete vm.data._links.statutorytype;
            }
            if (vm.data._links.hasOwnProperty('reporter') && (!vm.data._links.jobgroup || vm.data._links.jobgroup.href === null)) {
                delete vm.data._links.reporter;
            }
            if (vm.data._links.hasOwnProperty('contact') && (!vm.data._links.jobgroup || vm.data._links.jobgroup.href === null)) {
                delete vm.data._links.contact;
            }
            if (vm.data._links.hasOwnProperty('reporter') && vm.data._links.reporter == null) {
                delete vm.data._links.reporter;
            }
            if (vm.data._links.hasOwnProperty('contact') && vm.data._links.contact == null) {
                delete vm.data._links.contact;
            }

            if (lodash.has( vm.selectedJobGroupModel,'href')) {
                vm.data._links.jobgroup = vm.selectedJobGroupModel;
            } else if (lodash.has( vm.selectedJobGroupModel,'selected')) {
                vm.data._links.jobgroup = vm.selectedJobGroupModel.selected;
            } else {
                delete vm.data._links.jobgroup;
            }

            if (vm.selectedRequiredDocumentTypeModel.selected) {
                vm.data._links.requiredDocumentType = {href: vm.selectedRequiredDocumentTypeModel.selected.href};
            }

            angular.forEach(vm.data._links, function(value, key) {
                if (!angular.isObject(vm.data._links[key])) {
                    vm.data._links[key] = {
                        href: value
                    };
                }
            });

            return apiClient.update(vm.job.getLink('edit-job'), vm.data).then(function(response) {
                if (response) {
                    return $state.go('^.^', {}, {reload: $state.get('^.^.^')}).then(function() {
                        messenger.success(vm.translation + '_SUCCESS');
                    });
                }
            });
        }

        function changeChargeType() {
            if (vm.selectedChargeTypeModel.selected) {
                vm.data._links.chargetype = vm.selectedChargeTypeModel.selected.href;
            } else {
                vm.data._links.chargetype = null;
            }
        }

    }
})();
