(function () {
    'use strict';

    angular
        .module('elogbooksServices')
        .service('crudService', CrudService);

    CrudService.$inject = ['$rootScope', '$state', 'confirmationModal', 'apiClient', 'messenger', '$q'];

    /**
     * Inject in to controllers for an easy / reliable way to keep crud workflow consistent,
     * see usage below for individual methods
     */
    function CrudService ($rootScope, $state, confirmationModal, apiClient, messenger, $q) {
        var self = this;
            self.create = createAction;
            self.update = updateAction;
            self.delete = deleteAction;

        /**
         * Usage:
         *     <code>
         *         return crudService.create(
         *             vm.xCollectionResponse.getLink('create'),
         *             vm.data,
         *             'dashboard.some.route',
         *             'resource',
         *             'ENTITY_TRANSLATION_KEY',
         *             returnPromise = false
         *         );
         *
         *         return crudService.create(
         *             vm.xCollectionResponse.getLink('create'),
         *             vm.data,
         *             null, // Not required as we're returning a promise
         *             null, // Not required as we're returning a promise
         *             null, // Not required as we're returning a promise
         *             returnPromise = true
         *         ).then(function (response) {
         *            // Do something with the response object from apiClient.create();
         *         });
         *     </code>
         */
        function createAction (createLink, data, successRoute, successResourceName, entityName, returnPromise, reloadRoute) {
            return apiClient.create(createLink, data).then(function (response) {
                if (response) {

                    if (returnPromise) {
                        var deferred = $q.defer();
                            deferred.resolve(response);

                        return deferred.promise;
                    }

                    var params = {};
                    if (successResourceName) {
                        params[successResourceName] = response.getLink('self').encode();
                    }

                    $rootScope.skipDirtyCheck = true;
                    return $state.go(successRoute, params, { reload: reloadRoute ? reloadRoute : $state.current.parent }).then(function () {
                       messenger.success(entityName + '_CREATED');
                    });

                } else {
                    messenger.error('REQUEST_ERROR');
                }
            });
        }

        /**
         * Usage:
         *     <code>
         *         return crudService.update(
         *             vm.x.getLink('edit'),
         *             vm.data,
         *             'dashboard.some.route',
         *             'dashboard.some.route',
         *             'ENTITY_TRANSLATION_KEY',
         *             returnPromise = false
         *         );
         *
         *         return crudService.update(
         *             vm.x.getLink('edit'),
         *             vm.data,
         *             null, // Not required as we're returning a promise
         *             null, // Not required as we're returning a promise
         *             null, // Not required as we're returning a promise
         *             returnPromise = true
         *         ).then(function (response) {
         *            // Do something with the response object from apiClient.update();
         *         });
         *     </code>
         */
        function updateAction (updateLink, data, successRoute, reloadRoute, entityName, returnPromise) {
            return apiClient.update(updateLink, data).then(function (response) {
                if (response) {

                    if (returnPromise) {
                        var deferred = $q.defer();
                            deferred.resolve(response);

                        return deferred.promise;
                    }

                    $rootScope.skipDirtyCheck = true;
                    return $state.go(successRoute, {}, { reload: reloadRoute }).then(function() {
                       messenger.success(entityName + '_UPDATED');
                    });
                } else {
                    messenger.error('REQUEST_ERROR');
                }
            });
        }

        /**
         * Usage:
         *     <code>
         *         return crudService.delete(
         *             vm.x.getLink('delete'),
         *             'dashboard.some.route',
         *             'ENTITY_TRANSLATION_KEY',
         *             {}, // Config to pass to the confirmationModal
         *             returnPromise = false
         *         );
         *
         *         return crudService.delete(
         *             vm.x.getLink('delete'),
         *             null, // Not required as we're returning a promise
         *             null, // Not required as we're returning a promise
         *             null, // Not required as we're returning a promise
         *             returnPromise = true
         *         ).then(function (response) {
         *            // Do something with the response object from apiClient.delete();
         *         });
         *     </code>
         */
        function deleteAction (deleteLink, successRoute, entityName, config, returnPromise, reloadRoute) {
            var config = config || {};

            return confirmationModal.open(config).result.then(function () {
                return apiClient.delete(deleteLink).then(function (response) {

                    if (returnPromise) {
                        var deferred = $q.defer();
                            deferred.resolve(response);

                        return deferred.promise;
                    }

                    if (response) {
                        $rootScope.skipDirtyCheck = true;
                        return $state.go(successRoute, {}, { reload: reloadRoute ? reloadRoute : successRoute }).then(function () {
                            messenger.success(entityName + '_DELETED');
                        });
                    } else {
                        messenger.error('REQUEST_ERROR');
                    }
                });
            });
        }

        return this;
    }
})();
