angular.module('elogbooksDirectives').directive('meterreadings', ['elbDownloadService', function () {
    return {
        restrict: 'AE',
        require: ['ngModel'],
        scope: {
            meter: '=ngModel'
        },
        templateUrl: '/modules/directives/meter/readings/meterreadings.html',
        controller: function ($scope, elbDownloadService) {
            $scope.download = downloadImage;
            $scope.delete = $scope.$parent.vm.deleteReading;
            // Setting the value of the meter reading
            var read = $scope.meter._embedded.meterreading.meterreadings,
                l = read.length,
                i = 0;

            // compute the last reading consumption against the last matching type.
            function findLastMatchingType(rolled) {
                if (currentType === lastType) {
                    if (rolled) {
                        consumption = ($scope.meter.maxValue - lastReading) + currentReading;
                    } else {
                        consumption = currentReading - lastReading;
                    }
                } else {
                    incLast++;
                    // go back through each response
                    if (read.length - 1 >= incLast ) {
                        lastType = read[incLast].type;
                        lastReading = (read[incLast]) ? parseInt(read[incLast].reading.replace(/\,/g,'')) : 0;
                        findLastMatchingType(rolled);
                    } else {
                        // no responses left, reset
                        consumption = 0;
                    }
                }
            }

            for (i; i < l; i++) {
                var consumption,
                    last = i + 1,
                    incLast = last,
                    currentReading = parseInt(read[i].reading.replace(/\,/g,'')),
                    currentType = read[i].type,
                    lastType = (read[last]) ? read[last].type : null,
                    // Checking is the last meter reading exists
                    lastReading = (read[last]) ? parseInt(read[last].reading.replace(/\,/g,'')) : 0;

                if (!read[last]) {
                    consumption = 0;
                } else if (read[i].rolled) {
                    findLastMatchingType(true);
                } else {
                    findLastMatchingType();
                }
                $scope.meter._embedded.meterreading.meterreadings[i].consumption = consumption;
            }

            function downloadImage(file) {
                return elbDownloadService.downloadUrl(file.href, file.filename, file.type, true);
            }
        }
    };
}]);