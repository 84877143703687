angular.module('elogbooksServices').service('ResponseObjectDataToOptionsTransformer', function ($filter) {
    this.transform = function (data, key, value, excludeNoneSelectedOption) {

        var results = excludeNoneSelectedOption ? [] : [ { value: null, title: $filter('translate')('NONE_SELECTED') } ];

        angular.forEach(function (item) {
            results.push({ value: item[key].toString(), title: item[value] });
        });

        return results;
    };
});
