(function() {
    'use strict';

    angular
        .module('elogbooks.common.audit')
        .controller('AuditAddEditController', AuditAddEditController);

    AuditAddEditController.$inject = ['$scope', '$state', '$stateParams', 'auditResponse', 'auditTemplateResponse', 'siteResponse', 'apiClient', 'lodash', 'messenger', 'siteCollectionResponse', 'requestDataFactory', 'userManager', '$filter', 'confirmationModal', 'serviceProviderResponse','templateCollectionResponse', '$translate', 'rootResourceResponse'];

    function AuditAddEditController($scope, $state, $stateParams, auditResponse, auditTemplateResponse, siteResponse, apiClient, lodash, messenger, siteCollectionResponse, requestDataFactory, userManager, $filter, confirmationModal, serviceProviderResponse, templateCollectionResponse, $translate, rootResourceResponse) {

        var operativeParams = {permission: 'operative_permission_receive_audits', status:'active'};
        var templateParams = {type: 'template', site: null, status: 'AUDIT_TEMPLATE_ACTIVE'};
        var vm = this;
        vm.busy = false;
        vm.create = createAction;
        vm.update = updateAction;
        vm.delete = deleteAction;

        vm.data = requestDataFactory.createRequest(getDefaults(auditResponse));
        vm.auditResponse = auditResponse;
        vm.auditTemplateResponse = auditTemplateResponse;
        vm.siteCollection = siteCollectionResponse.sites;
        vm.sites = [];
        vm.siteParnerships = [];
        vm.serviceProviders = serviceProviderResponse;
        vm.templates = [];
        vm.operatives = [];

        vm.loadFromTemplate = loadFromTemplate;
        vm.templateOnly = false;
        vm.entityName = 'AUDIT';
        vm.changeIsSchedule = changeIsSchedule;

        vm.isAudit = $stateParams.auditType === 'audit';
        vm.isSchedule = $stateParams.auditType === 'schedule';
        vm.isTemplate = ($stateParams.auditType === 'template' || lodash.has(auditTemplateResponse, 'id'));

        vm.allTemplatesCollectionResponse = templateCollectionResponse;

        if(templateCollectionResponse.audits) {
            vm.allTemplatesCollectionResponse.audits = templateCollectionResponse.audits.filter(function(value) {
                return value.status === 'AUDIT_TEMPLATE_ACTIVE';
            });

            vm.filters = {
                status: 'audit_template_active'
            };
        }

        vm.multiSelect = {
            link: '/sites',
            responseKeyPath: 'sites'
        };

        var selectedSite = null;

        if (lodash.has(auditTemplateResponse, '_links.self')) {
            vm.data = requestDataFactory.createRequest(getDefaults(auditTemplateResponse));
            vm.data._links.originalaudit = auditTemplateResponse._links.self;
            vm.data.isTemplate = true;
            vm.data.id = null;

            if (lodash.has(auditTemplateResponse, '_links.site')) {
                selectedSite = auditTemplateResponse._links.site;
            }
        }

        if (lodash.has(auditResponse, '_links.site')) {
            selectedSite = auditResponse._links.site;
        } else if (!lodash.isEmpty(siteResponse)) {
            selectedSite = {'href' : siteResponse._links.self.href, 'title': siteResponse.name};
        }

        if (vm.isTemplate) {
            vm.entityName = 'TEMPLATE';
            vm.isTemplate = true;
            vm.data.isTemplate = true;
        }

        if (vm.isSchedule) {
            vm.data.sites = { site: [] };
            vm.entityName = 'SCHEDULE';
            vm.data.isSchedule = true;
        }

        vm.visibilityOptions = [{
            value: 'Private',
            label: $translate.instant('VISIBILITY_PRIVATE')
        },{
            value: 'Public',
            label: $translate.instant('VISIBILITY_PUBLIC')
        }];

        vm.siteSelect = {
            response: siteCollectionResponse,
            link: siteCollectionResponse ? siteCollectionResponse.getLink('self') : null,
            responseKeyPath: 'sites',
            required: true,
            onSelect: changeSite,
            onRemove: removeSite,
            selected: selectedSite,
            disabled: vm.data.id !== null && !vm.isSchedule /*|| !lodash.isEmpty(siteResponse)*/,
            populateDisabled: true,
            selectedItems: []
        };

        angular.forEach(auditResponse.sites, function (site) {
            vm.siteSelect.selectedItems.push({href: site.getLink('self'), value: site.name});
        });

        vm.templateSelect = {
            response: vm.allTemplatesCollectionResponse,
            responseKeyPath: 'audits',
            linkParameters: {
                scheduleName: null,
                status: 'audit_template_active'
            },
            itemHrefPath: '_links.self.href',
            itemValuePath: 'summary',
            searchKey: 'summary',
            onSelect: loadFromTemplate,
            onRemove: removeTemplate
        };
        vm.partnershipSelect = {
            disabled: true,
            required: vm.data.isSchedule,
            onSelect: changeServiceProvider,
            onRemove: removeServiceProvider,
            responseKeyPath: 'partnerships',
            itemHrefPath: '_links.serviceprovider.href',
            itemValuePath: '_links.serviceprovider.title'
        };
        vm.serviceProviderSelect = {
            response: serviceProviderResponse,
            onSelect: changeServiceProvider,
            onRemove: removeServiceProvider,
            responseKeyPath: 'serviceproviders'
        };
        vm.operativeSelect = {
            disabled: true,
            required: false,
            responseKeyPath: 'operatives',
            itemHrefPath: '_links.self.href',
            itemValuePath: '_links.user.name',
            refreshOnLinkChange: true
        };
        vm.siteAssociateTypeSelect = {
            response: rootResourceResponse,
            link: rootResourceResponse.getLink('siteassociatetypes'),
            disabled: false,
            required: false,
            loadOnOpen: true,
            openLink: rootResourceResponse.getLink('siteassociatetypes'),
            responseKeyPath: 'siteAssociateTypes',
            itemHrefPath: '_links.self.href',
            itemValuePath: 'name'
        };

        if (vm.siteSelect.selected) {
            vm.siteSelect.disabled = true;
            changeSite();
        }

        $scope.$watch('vm.siteSelect.selectedItems', function(newValue, oldValue) {
            if (typeof newValue !== 'undefined') {
                changeMultiSite();
            }
        });

        /**
         * Load Site and setup service provider, audits form elements
         */
        function changeMultiSite() {
            var sites = {
                _links: {}
            };

            if (vm.siteSelect.selectedItems && vm.siteSelect.selectedItems.length) {
                sites._links.site = vm.siteSelect.selectedItems;
            }
        }

        /**
         * Load Site and setup service provider, audits form elements
         */
        function changeSite() {
            if (typeof vm.siteSelect.selected.href !== 'undefined') {

                vm.data._links.site = vm.siteSelect.selected;

                apiClient.get(vm.siteSelect.selected.href).then(function (response) {
                    if (response) {
                        if (response.getLink('partnerships')) {
                            vm.partnershipSelect.disabled = false;
                            if (!vm.siteSelect.disabled) {
                                if (vm.partnershipSelect.clear) {
                                    vm.partnershipSelect.clear();
                                }
                            }

                            vm.partnershipSelect.link = response._links.partnerships.href;
                        }
                    }
                });
            }
        }

        function removeTemplate() {
            delete vm.data._links.template;
            delete vm.data.summary;
            delete vm.data.description;
            delete vm.data.instruction;
            vm.templateSelected = false;
        }

        /**
         * Clear all form fields that are site related.
         */
        function removeSite() {
            vm.partnershipSelect.disabled = true;
            if (typeof vm.partnershipSelect.clear === 'function') {
                vm.partnershipSelect.clear();
            }
            vm.operativeSelect.disabled = true;
            if (typeof vm.operativeSelect.clear === 'function') {
                vm.operativeSelect.clear();
            }
            vm.templateSelect.disabled = true;
            if (typeof vm.templateSelect.clear === 'function') {
                vm.templateSelect.clear();
            }
        }

        /**
         * Load service provider and setup operative form element
         */
        function changeServiceProvider() {
            var type;

            if (vm.isSchedule && typeof vm.serviceProviderSelect.selected !== 'undefined') {
                type = vm.serviceProviderSelect.selected;
            } else if (typeof vm.partnershipSelect.selected !== 'undefined') {
                type = vm.partnershipSelect.selected;
            }

            if (typeof type !== 'undefined' && type !== null) {
                vm.operativeSelect.disabled = true;

                apiClient.get(type.href).then(function (response) {
                    vm.operativeSelect.disabled = false;

                    //List operatives
                    if (response && typeof response._links.auditoperatives !== 'undefined') {
                        if (vm.operativeSelect.link !== response._links.auditoperatives.href) {
                            // we set the ui-select which gets the ops, we don't listen to this though? also doesn't have primary details
                            vm.operativeSelect.link = response._links.auditoperatives.href;
                            vm.operativeSelect.linkParameters = operativeParams;
                            vm.operativeSelect.selected = null;
                        }
                    }
                });
            }
        }

        /**
         * Disable and clear operatives
         */
        function removeServiceProvider() {
            vm.operativeSelect.disabled = true;
            vm.operativeSelect.clear();
        }

        // Fill form data on edit action
        if (lodash.has(auditResponse, '_links.site')) {
            vm.siteSelect.selected = auditResponse._links.site;
            // Get site to set resource links in sub select forms
            apiClient.get(vm.siteSelect.selected.href).then(function(response) {
                if (response) {
                    if (typeof response.getLink('partnerships') !== 'undefined') {
                        vm.partnershipSelect.link = response.getLink('partnerships');
                        vm.partnershipSelect.disabled = false;
                        changeServiceProvider();
                    }
                }
            });

            loadSiteAuditTemplates();
        }

        if (typeof auditResponse.recurrenceSet !== 'undefined') {
            vm.data.rules = auditResponse.recurrenceSet.rules;
        }

        if (lodash.has(auditResponse, '_links.serviceprovider')) {
            vm.partnershipSelect.selected = auditResponse._links.serviceprovider;
            vm.partnershipSelect.disabled = false;
            vm.serviceProviderSelect.selected = auditResponse._links.serviceprovider;
            vm.serviceProviderSelect.disabled = false;
        }

        if (lodash.has(auditResponse, '_links.operative')) {
            vm.operativeSelect.selected = auditResponse._links.operative;
            vm.operativeSelect.disabled = false;
        }

        if (lodash.has(auditTemplateResponse, '_links.serviceprovider')) {
            vm.partnershipSelect.selected = auditTemplateResponse._links.serviceprovider;
            vm.partnershipSelect.disabled = false;
        }

        if (lodash.has(auditTemplateResponse, '_links.operative')) {
            vm.operativeSelect.selected = auditTemplateResponse._links.operative;
            vm.operativeSelect.disabled = false;
        }

        if (lodash.has(auditTemplateResponse, '_links.siteassociatetype')) {
            vm.siteAssociateTypeSelect.selected = auditTemplateResponse._links.siteassociatetype;
            vm.siteAssociateTypeSelect.disabled = false;
        } else if (lodash.has(auditResponse, '_links.siteassociatetype')) {
            vm.siteAssociateTypeSelect.selected = auditResponse._links.siteassociatetype;
            vm.siteAssociateTypeSelect.disabled = false;
        }

        // loads operatives on edit schedule form for specific sp
        if (lodash.has(auditResponse, '_links.serviceprovider')) {
            if (typeof vm.serviceProviderSelect.selected !== 'undefined') {
                apiClient.get(vm.serviceProviderSelect.selected.href).then(function (response) {
                    if (response && typeof response._links.auditoperatives !== 'undefined') {
                        vm.operativeSelect.link = response._links.auditoperatives.href;
                        vm.operativeSelect.linkParameters = operativeParams;
                    }
                });
            }
        }

        /**
         *  If schedule is unset, reset sp and operative requirements
         */
        function changeIsSchedule() {
            if (vm.data.isSchedule === true) {
                vm.partnershipSelect.required = true;
                vm.operativeSelect.required = true;
            } else {
                vm.partnershipSelect.required = false;
                vm.operativeSelect.required = false;
                vm.data.scheduleStartAt = null;
            }
        }

        function loadSiteAuditTemplates() {
            if (vm.data._links.site !== null && vm.data.isTemplate === false && vm.data.id === null) {

                // get site Id from site href
                templateParams.site = lodash.replace(vm.data._links.site.href, '/sites/', '');

                if (vm.templateSelect.linkParameters !== undefined) {
                    vm.templateSelect.clear();
                }

                vm.templateSelect.disabled = false;
                vm.templateSelect.linkParameters = templateParams;
                vm.templateSelect.link = '/audits';
            }
        }

        function loadFromTemplate() {
            if (typeof vm.templateSelect.selected.href !== 'undefined') {
                vm.templateSelected = true;
                vm.data._links.template = vm.templateSelect.selected.object;

                // prefill fields from template, submitting to API will copy template sections/sub-sections/questions/answer options
                vm.data.summary = vm.data._links.template.summary;
                vm.data.reference = vm.data._links.template.reference;
                vm.data.description = vm.data._links.template.description;
                vm.data.instruction = vm.data._links.template.instruction;
                vm.siteAssociateTypeSelect.selected = vm.data._links.template._links.siteassociatetype;

                if (lodash.has(vm.data._links.template, '_links.image')) {
                    vm.data._links.image = vm.data._links.template._links.image;
                }

                if (lodash.has(vm.data._links.template, '_links.serviceprovider')) {
                    vm.partnershipSelect.selected = vm.data._links.template._links.serviceprovider;
                    vm.data._links.serviceprovider = vm.data._links.template._links.serviceprovider;
                    changeServiceProvider();
                }
            } else {
                vm.templateSelected = false;
            }
        }

        function createAction() {

            if (typeof vm.siteSelect.selected !== 'undefined') {
                vm.data._links.site = vm.siteSelect.selected;
            }

            if (vm.isSchedule && typeof vm.siteSelect.selectedItems !== 'undefined') {
                vm.data.sites.site = vm.siteSelect.selectedItems;
            }

            if (typeof vm.partnershipSelect.selected !== 'undefined') {
                vm.data._links.serviceprovider = vm.partnershipSelect.selected;
            }

            if (typeof vm.serviceProviderSelect.selected !== 'undefined') {
                vm.data._links.serviceprovider = vm.serviceProviderSelect.selected;
            }

            if (typeof vm.operativeSelect.selected !== 'undefined') {
                vm.data._links.operative = vm.operativeSelect.selected;
            }

            if (typeof vm.siteAssociateTypeSelect.selected !== 'undefined') {
                vm.data._links.siteAssociateType = vm.siteAssociateTypeSelect.selected;
            }

            if (vm.data.isTemplate === true) {
                if (!vm.data._links.template) {
                    vm.data._links.template = lodash.has(vm.data, '_links.template') ? vm.data._links.template : null;
                    vm.data._links.template = lodash.has(vm.data, '_links.template') ? vm.data._links.template : null;
                }
                delete vm.data.completionDueAt;
            }

            if (vm.data.scheduleStartAt) {
                vm.data.completionDueAt = null;
            }

            if (vm.auditTemplateResponse.hasOwnProperty('id')) {
                vm.data._links.originalaudit = auditTemplateResponse._links.self;
            }

            var auditCreateUrl = ((vm.data._links.site !== null && !vm.isTemplate) ? vm.data._links.site.href : '') + '/audits';

            // site is provided in url
            delete vm.data._links.site;
            vm.busy = true;
            apiClient.create(auditCreateUrl, vm.data).then(function(response) {
                if (response) {
                    var auditResourceLink = response;

                    // if audit was created from another audit, go back to that resource
                    if (lodash.has(auditTemplateResponse, 'id')
                        && lodash.has(auditTemplateResponse, '_links.site')
                    ) {
                        auditResourceLink = auditTemplateResponse;
                    }

                    $state.go('^.details.info', {auditResource: auditResourceLink.getLink('self').encode()}, {reload: $state.get('^.^.^')}).then(function() {
                        if (vm.isTemplate) {
                            messenger.success('AUDIT_TEMPLATE_CREATED');
                        } else if (vm.isSchedule) {
                            messenger.success('AUDIT_SCHEDULE_CREATED');
                        } else {
                            messenger.success('AUDIT_CREATED');
                        }
                    });
                } else {
                    vm.busy = false;
                    messenger.error('REQUEST_ERROR');
                }
            });
        }

        function updateAction() {
            // site and template cannot be changed
            delete vm.data._links.site;
            delete vm.data._links.template;

            if (typeof vm.serviceProviderSelect.selected !== 'undefined') {
                vm.data._links.serviceprovider = vm.serviceProviderSelect.selected;
            }

            if (typeof vm.partnershipSelect.selected !== 'undefined') {
                vm.data._links.serviceprovider = vm.partnershipSelect.selected
            }

            if (typeof vm.operativeSelect.selected !== 'undefined') {
                vm.data._links.operative = vm.operativeSelect.selected
            }

            if (typeof vm.siteAssociateTypeSelect.selected !== 'undefined') {
                vm.data._links.siteAssociateType = vm.siteAssociateTypeSelect.selected;
            }

            if (vm.data.isTemplate === true) {
                delete vm.data.completionDueAt;
            }

            if (vm.isSchedule && typeof vm.siteSelect.selectedItems !== 'undefined') {
                vm.data.sites.site = vm.siteSelect.selectedItems;
            }
            vm.busy = true;
            apiClient.update(vm.auditResponse._links.self.href, vm.data).then(function(response) {
                if (response) {
                    $state.go('^', {}, {reload: $state.get('^.^.^')}).then(function() {
                        messenger.success('AUDIT_UPDATED');
                    });
                } else {
                    vm.busy = false;
                    messenger.error('REQUEST_ERROR');
                }
            });
        }

        function deleteAction() {
            return confirmationModal.open().result.then(function() {
                vm.busy = true;
                return apiClient.delete(vm.auditResponse.getLink('delete')).then(function(response) {
                    if (response) {
                        $state.go('dashboard.user.audits.list', {}, {reload: $state.get('^.^.^')}).then(function() {
                            messenger.success('AUDIT_DELETED');
                        });
                    } else {
                        vm.busy = false;
                        messenger.error('REQUEST_ERROR');
                    }
                });
            });
        }

        function getDefaults(audit) {
            return {
                id: audit.id || null,
                summary: audit.summary || null,
                visibility: audit.visibility || 'Private',
                reference: audit.reference || null,
                scheduleName: audit.scheduleName || null,
                description: audit.description || null,
                instruction: audit.instruction || null,
                completionDueAt: audit.completionDueAt ? new Date(audit.completionDueAt) : null,
                scheduleStartAt: audit.scheduleStartAt ? new Date(audit.scheduleStartAt) : null,
                isTemplate: audit.isTemplate || false,
                isSchedule: audit.isSchedule || false,
                _links: {
                    site: lodash.has(audit, '_links.site') ? audit._links.site : null,
                    serviceprovider: lodash.has(audit, '_links.serviceprovider') ? audit._links.serviceprovider : null,
                    operative: lodash.has(audit, '_links.operative') ? audit._links.operative : null,
                    template: lodash.has(audit, '_links.template') ? audit._links.template : null,
                    image: lodash.has(audit, '_links.image') ? audit._links.image : null
                },
            };
        }
    }
})();
