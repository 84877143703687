(function(){
    'use strict';

    angular
        .module('elogbooks.user.contracts')
        .controller('ContractsController', ContractsController);

    ContractsController.$inject = ['contractCollectionResponse', 'siteCollectionResponse', 'serviceProviderCollectionResponse', '$stateParams', '$state', '$filter'];

    function ContractsController(contractCollectionResponse, siteCollectionResponse, serviceProviderCollectionResponse, $stateParams, $state, $filter) {
        var vm = this;
        vm.contractsCollection = contractCollectionResponse;
        vm.contracts = contractCollectionResponse.contracts;
        vm.contractsResponse = contractCollectionResponse;

        vm.search = search;
        vm.order = orderAction;

        var contractStatuses = [
            'status_draft',
            'status_approval_pending',
            'status_cancelled',
            'status_terminated',
            'status_draft_in_renewal',
            'status_active',
            'status_approval_rejected',
            'status_renewal_approval_pending',
            'status_renewal_approval_rejected',
            'status_expired'
        ];

        function getStatuses() {
            var translatedStatuses = [];

            angular.forEach(contractStatuses, function(value) {
                translatedStatuses.push({
                    title: $filter('translate')('CONTRACT_' + value.toUpperCase()),
                    value: value
                });
            });

            return translatedStatuses;
        }

        vm.criteria = {
            contractId: { type: 'text', title: 'ID', value: $stateParams.contractId},
            contractName: { type: 'text', title: 'NAME', value: $stateParams.contractName },
            contractExternalReference: { type: 'text', title: 'EXTERNAL_REFERENCE', value: $stateParams.contractExternalReference },
            contractStatus: {type: 'options', title: 'STATUS', value: $stateParams.contractStatus, multiple: true, options: getStatuses()},
            contractExpiresAt: {
                type: 'date-range',
                title: 'EXPIRES_AT',
                value: {
                    startDate: ($stateParams.contractExpiresAt !== null) ? moment(new Date($stateParams.contractExpiresAt.split(',')[0])) : null,
                    endDate: ($stateParams.contractExpiresAt !== null) ? moment(new Date($stateParams.contractExpiresAt.split(',')[1])) : null
                },
                clearValue: {
                    startDate: null,
                    endDate: null
                }
            }
        };

        if (siteCollectionResponse) {
            vm.siteSelect = {
                response : siteCollectionResponse,
                link : siteCollectionResponse ? siteCollectionResponse.getLink('self') : null,
                responseKeyPath: 'sites',
                itemValuePath: 'name',
                itemHrefPath: 'id',
                disabled: $stateParams.selectedSiteResource
            };

            vm.criteria.contractSiteIds = { type: 'autocompletemulti', multiple: true, title: 'SITE', value: $stateParams.contractSiteIds, options: vm.siteSelect, param: 'contractSiteIds', max: 50 };
        }

        if (serviceProviderCollectionResponse) {
            vm.serviceProviderSelect = {
                response : serviceProviderCollectionResponse,
                link : serviceProviderCollectionResponse ? serviceProviderCollectionResponse.getLink('self') : null,
                responseKeyPath: 'serviceproviders',
                itemValuePath: 'id',
                itemTitlePath: 'name'
            };

            vm.criteria.contractServiceProvider = { type: 'jsonselectwidget', title: 'SERVICEPROVIDER', value: JSON.parse($stateParams.contractServiceProvider), options: vm.serviceProviderSelect };
        }

        function search (params) {
            var override = {
                contractPage: 1
            };

            $state.go('.', angular.extend({}, params, override), { reload: true });
        }

        function orderAction(key) {
            $stateParams.contractOrder = key;

            $state.go('.', angular.extend({}, $stateParams), { reload: $state.current });
        }

    }
})();
