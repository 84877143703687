angular.module('elogbooksServices').service('statuses', function() {

    /**
     * @param array statuses
     * @returns array
     */
    this.getJobStatuses = function (statuses) {
        var statusMapping = {
            OPERATION_ACKNOWLEDGED: 7,
            OPERATION_COMMENCED: 8,
            OPERATION_MISSED: 9,
            OPERATION_CANCELLED: 10,
            OPERATION_COMPLETED: 11,
            OPERATION_AWAITING_PAPERWORK: 12,
            EXTENSION_PENDING : 13
        }

        var statusesMapped = [];

        statuses.forEach(status => {
            statusesMapped.push(statusMapping[status.toUpperCase()]);
        });

        return statusesMapped;
    }

    /**
     * @param array statuses
     * @returns array
     */
    this.getTaskEventStatuses = function (statuses) {
        var statusMapping = {
            REJECTED: 0,
            DRAFT: 1,
            PENDING_APPROVAL: 2,
            APPROVED: 3,
            GHOST_DRAFT: 10,
            GHOST_PENDING_APPROVAL: 12,
            GHOST_REJECTED: 13,
            GHOST_APPROVED: 14,
        }

        var statusesMapped = [];

        statuses.forEach(status => {
            statusesMapped.push(statusMapping[status.toUpperCase()]);
        });

        return statusesMapped;
    }

    return this;
});
