(function() {
    'use strict';
    angular
        .module('elogbooksControllers')
        .controller('DashboardController', DashboardController);

    DashboardController.$inject = ['user', '$q', 'lodash', 'userPreferences', 'dashboardService'];

    function DashboardController(user, $q, lodash, userPreferences, dashboard) {
        var vm = this;
        var requests = [];          

        vm.user = user;
        vm.dashboard = dashboard;
        vm.dashboard.reset();
        vm.dashboard.applyUserPreferences(userPreferences);
        dashboard.showStatistics = true;
        dashboard.getStatistics();
    }
})();
