(function () {
    'use strict';

    angular
        .module('elogbooks.user.tenants')
        .controller('TenantEmailListController', TenantEmailListController);

    TenantEmailListController.$inject = ['$state', 'config', '$stateParams', 'emailLogsResponse', 'moment'];

    function TenantEmailListController($state, config, $stateParams, emailLogsResponse, moment) {
        var vm = this;
        vm.config = config.config;
        vm.emailLogsResponse = emailLogsResponse;
        vm.emails = emailLogsResponse.emailLogs;
        vm.stateParams = $stateParams;
        vm.search = search;

        vm.criteria = {
            eSubject: {type: 'text', title: 'SUBJECT', value: $stateParams.eSubject},
            eRecipientName: {type: 'text', title: 'EMAIL_RECIPIENT_NAME', value: $stateParams.eRecipientName},
            eSendBetween: {
                type: 'date-range',
                title: 'SEND_BETWEEN_DATES',
                value: {
                    startDate: ($stateParams.eSendBetween !== null) ? moment(new Date($stateParams.eSendBetween.split(',')[0])) : null,
                    endDate: ($stateParams.eSendBetween !== null) ? moment(new Date($stateParams.eSendBetween.split(',')[1])) : null,
                    endMaxDate: moment().format('MM-DD-YYYY, 23:59:59')
                },
                clearValue: {
                    startDate: null,
                    endDate: null
                }
            }
        };

        /**
         * Search emails
         */
        function search(params) {
            var override = {
                ePage: 1
            };

            $state.go('.', angular.extend({}, params, override), {reload: $state.current});
        }
    }
})();
