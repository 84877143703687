;(function() {
    'use strict';

    angular
        .module('elogbooks.admin.forms')
        .controller('FormAddEditController', FormAddEditController);

    FormAddEditController.$inject = ['$translate', 'crudService', 'requestDataFactory', 'elbFormService', 'formCollectionResponse', 'formResponse'];

    function FormAddEditController($translate, crudService, requestDataFactory, elbFormService, formCollectionResponse, formResponse) {
        var vm = this;
        vm.formResponse = formResponse;
        vm.formService = elbFormService;
        vm.data = requestDataFactory.createRequest(formResponse);
        vm.formTypes = vm.formService.getFormTypes();

        vm.statusOptions = [
            { value: true, title: $translate.instant('STATUS_ACTIVE') },
            { value: false, title: $translate.instant('STATUS_INACTIVE') }
        ];

        vm.create = CreateForm;
        vm.update = UpdateForm;
        vm.delete = DeleteForm;

        function CreateForm() {
            return crudService.create(
                formCollectionResponse.getLink('create'),
                vm.data,
                'dashboard.admin.forms.list.details',
                'formResource',
                'FORM'
            );
        }

        function UpdateForm() {
            return crudService.update(
                formResponse.getLink('edit'),
                vm.data,
                'dashboard.admin.forms.list.details',
                'dashboard.admin.forms.list',
                'FORM'
            );
        }

        function DeleteForm() {
            return crudService.delete(
                formResponse.getLink('delete'),
                'dashboard.admin.forms.list',
                'FORM'
            );
        }
    }
})();
