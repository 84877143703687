app.config( function($translateProvider) {
    $translateProvider.translations('nl', {
        METER_SUPPLIER_INFORMATION: 'Meter leverancier informatie',

        ADD_NEW_METER_SUPPLIER: 'Voeg leverancier informatie toe',
        EDIT_METER_SUPPLIER: 'Wijzig leverancier informatie',

        METER_SUPPLIER_LIST: 'Meter Leverancier',
        METER_SUPPLIER_ADD: 'Voeg een nieuwe meterleverancier toe',
        METER_SUPPLIER_EDIT: 'Wijzig meterleverancier',

        METER_SUPPLIER_CREATED: 'Meterleverancier aangemaakt',
        METER_SUPPLIER_UPDATED: 'Meterleverancier aangepast',
        METER_SUPPLIER_DELETED: 'Meterleverancier verwijderd',
    });
});
