(function() {
    'use strict';

    angular
        .module('elogbooks.user.timesheets')
        .controller('UserTimesheetsInfoDetailsController', UserTimesheetsInfoDetailsController);

    UserTimesheetsInfoDetailsController.$inject = ['timesheetWeek'];

    function UserTimesheetsInfoDetailsController(timesheetWeek) {
        var vm = this;
        vm.timesheetWeek = timesheetWeek;

        vm.timesheetWeek.startDate = new Date(timesheetWeek.startWeekDate);
        vm.timesheetWeek.endDate = (new Date(timesheetWeek.startWeekDate))
        vm.timesheetWeek.endDate = vm.timesheetWeek.endDate.setDate(vm.timesheetWeek.endDate.getDate() + 6);
    }
})();
