(function() {
    'use strict';

    angular
        .module('elogbooks.common.actions')
        .controller('CommonActionDetailsWorkflowController', CommonActionDetailsWorkflowController);

    CommonActionDetailsWorkflowController.$inject = ['actionResponse', 'actionCollectionResponse'];

    function CommonActionDetailsWorkflowController(actionResponse, actionCollectionResponse) {
        var vm = this;
        vm.actionResponse = actionResponse;
        vm.actionCollectionResponse = actionCollectionResponse;
    }
})();
