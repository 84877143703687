(function() {
    'use strict';

    angular
        .module('elogbooks.admin.settings')
        .controller('AdminEscalationStagesController', AdminEscalationStagesController);

    AdminEscalationStagesController.$inject = ['$stateParams', '$state', 'escalationStagesCollectionResponse'];

    function AdminEscalationStagesController($stateParams, $state, escalationStagesCollectionResponse) {
        var vm = this;
        vm.escalationStagesCollectionResponse = escalationStagesCollectionResponse;

        vm.order = orderAction;
        vm.search = searchAction;

        vm.criteria = {
            stage: { type: 'number', title: 'STAGE', value: $stateParams.stage ? parseInt($stateParams.stage, 10) : null, min: 1 },
            name: { type: 'text', title: 'NAME', value: $stateParams.name },
        };

        function orderAction(key) {
            $stateParams.order = key;

            $state.go('.', angular.extend({}, $stateParams), { reload: $state.current });
        }

        function searchAction(params) {
            var override = {
                page: 1
            };

            $state.go('.', angular.extend({}, params, override), { reload: $state.current });
        }
    }
})();
