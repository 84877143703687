app.config(function ($translateProvider) {
    $translateProvider.translations('en-gb', {
        MAX: 'Max {{value}}',
        MIN: 'Min {{value}}',
        REQUIRED: 'Required',
        NOTES_MAX_LENGTH: 'Max length for notes field is 255',
        NAME_MAX_LENGTH: 'Max length for name field is 255',
        WASTE_ENTRY_VALUE_ERROR: 'Treatment types total value cannot be greater than waste entry value',
        REQUIRED_WHEN: 'Required when {{dependent}} is empty'
    });
});
