(function () {
    'use strict';

    angular
        .module('elogbooks.common.webhooks')
        .controller('WebhooksDetailsController', WebhooksDetailsController);

    WebhooksDetailsController.$inject = ['webhookResponse'];

    function WebhooksDetailsController (webhookResponse) {
        var vm = this;
        vm.webhook = webhookResponse;
    }
})();
