app.config(function ($translateProvider) {
    $translateProvider.translations('en-gb', {
        BULK_ACTIONS: 'Bulk Actions',
        BULK_ACTION_DETAILS_LIST:'Bulk Action Details',
        BULK_ACTION_ERROR_MESSAGE: 'Message',
        BULK_ACTION_FAILED: 'Failed',
        BULK_ACTION_LOG_ALL: 'All',
        BULK_ACTION_LOG_CHANGE: 'Success',
        BULK_ACTION_LOG_ERROR: 'Error',
        BULK_ACTION_LOG_NO_CHANGE: 'No Change',
        BULK_ACTION_LOG_STATUS: 'Status',
        BULK_ACTION_LOG_STATUS_DONE: 'Done',
        BULK_ACTION_LOG_STATUS_PENDING: 'Pending',
        BULK_ACTION_LOG_TYPE: 'Log Type',
        BULK_ACTION_LOGS: 'Bulk Action Logs',
        BULK_ACTION_MAX_FILTERED_ITEMS_EXCEEDED: 'Bulk Action not created - max jobs filtered exceeded',
        BULK_ACTION_MAX_ITEMS: 'Bulk Action not created - max jobs selected exceeded',
        BULK_ACTION_MUST_ONLY_BE_FILTERED_OR_SELECTED_JOBS: 'Bulk Action not created - Cannot reassign both selected and filtered jobs at same time',
        BULK_ACTION_NO_JOBS: 'Bulk Action not created - No jobs selected',
        BULK_ACTION_REASSIGN_USER_REQUIRED: 'Bulk Action Reassign - User Required',
        BULK_ACTION_REASSIGN_SERVICE_PROVIDER_REQUIRED: 'Bulk Action Reassign - Service Provider Required',
        BULK_ACTION_NO_JOBS_FOUND_THAT_MATCH_FILTERS: 'Bulk Action not created - No jobs found matching filtered criteria',
        BULK_ACTION_SUCCESSFUL: 'Successful',
        BULK_ACTIONS_LIST: 'Bulk Actions List',
        BULKACTIONS: 'Bulk Actions',
        CHANGE_OPERATIVE: 'Change Operative',
        STATUS_PAUSED: 'In-Progress (Paused)',
        STATUS_INITIALISING: 'Initialising Jobs',
        BULK_ACTION_NOTE_REQUIRED: 'Note field is required',
        BULK_ACTION_REASSIGN: 'Reassign',
        BULK_ACTION_CANCEL: 'Cancel',
        BULK_ACTION_REASSIGN_OPERATIVES: 'Reassign Operatives',
        BULK_ACTION_CANCEL_TASKS: 'Cancel From Tasks',
        BULK_ACTION_MAX_FILTERED_ACTIONS_EXCEEDED: 'Bulk Action not created - max actions filtered exceeded',
        BULK_ACTION_CANCEL_ACTIONS: 'Cancel From Actions',
        BULK_ACTION_NO_ACTIONS_FOUND_THAT_MATCH_FILTERS: 'Bulk Action not created - No actions found matching filtered criteria'
    });
});
