(function () {
    'use strict';

    angular
        .module('elogbooks.common.jobs')
        .controller('CommonJobDetailsJobFourDController', CommonJobDetailsJobFourDController);

        CommonJobDetailsJobFourDController.$inject = [
        '$sce',
        '$scope',
        '$interval',
        'jobResponse'
        ];

    function CommonJobDetailsJobFourDController ($sce, $scope, $interval, jobResponse) {
        var vm = this;
        vm.FourDUrl = jobResponse.attributes['four_d_url'] || null;
        vm.loading = true;
        vm.timer = null;

        $scope.trustSrc = function(src) {
            return $sce.trustAsResourceUrl(src);
        }

        $scope.iframeLoadedCallBack = function (element) {
            vm.loading = false;
            $scope.$apply();
        }
    }
})();
