(function () {
    'use strict';

    angular
        .module('elogbooks.admin.departments')
        .controller('DepartmentsDetailsController', DepartmentsDetailsController);

    DepartmentsDetailsController.$inject = ['departmentResponse'];

    function DepartmentsDetailsController (departmentResponse) {
        var vm = this;
        vm.departmentResponse = departmentResponse;
    }
})();
