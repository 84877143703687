(function () {
    'use strict';

    angular
        .module('elogbooks.user.duty-management')
        .controller('DutyManagementSitesController', DutyManagementSitesController);

    function DutyManagementSitesController ($state, $stateParams, apiClient, messenger, dutyManagementScheduleResponse, siteCollectionResponse) {
        var vm = this;
            vm.dutyManagementSchedule = dutyManagementScheduleResponse;
            vm.siteSelect = {
                response : siteCollectionResponse,
                link : siteCollectionResponse ? siteCollectionResponse.getLink('self') : '',
                responseKeyPath: 'sites'
            };
            vm.update = updateAction;

        function updateAction() {
            var dutyManagementForm = {
                _links : {}
            };

            if (vm.siteSelect.selectedItems.length) {
                dutyManagementForm._links.site = vm.siteSelect.selectedItems
            }

            apiClient.update(vm.dutyManagementSchedule.getLink('sites'), dutyManagementForm).then(function (response) {
                if (response) {
                    $state.go('^', $stateParams, { reload: true }).then(function () {
                        messenger.success('ADD_SUCCESS');
                    });
                } else {
                    messenger.error('REQUEST_ERROR');
                }
            });
        }
    }
})();
