/**
 * Quote Service
 *
 * - generateMaterialRequest()
 * - generateWorkRequiredRequest()
 * - generateAcceptQuoteRequest()
 * - generateQuoteEditRequest()
 * - action()
 *
 */
angular.module('elogbooksServices').service('quoteService', function (apiClient, $q, requestDataFactory, $filter, confirmationModal, confirmationModalNotes, lodash) {
    this.generateMaterialRequest = generateMaterialRequest;
    this.generateWorkRequiredRequest = generateWorkRequiredRequest;
    this.generateAcceptQuoteRequest = generateAcceptQuoteRequest;
    this.generateQuoteEditRequest = generateQuoteEditRequest;
    this.action = quoteAction;
    this.update = quoteUpdate;

    /**
     * Cancel a quote
     */
    this.cancel = function (quote) {
        var deferred = $q.defer();

        apiClient.create(quote.getLink('cancel')).then(function (isSuccess) {
            if (isSuccess) {
                return deferred.resolve();
            }

            return deferred.reject('Unable to cancel, please try later');
        });

        return deferred.promise;
    };

    /**
     * Accept a quote
     */
    this.accept = function (quote, form) {
        var deferred = $q.defer();

        apiClient.create(quote.getLink('accept'), form).then(function (isSuccess) {
            if (isSuccess) {
                return deferred.resolve();
            }

            return deferred.reject('Unable to accept, please try later');
        });

        return deferred.promise;
    };

    /**
     * Generate a material response
     *
     * @param materialResponse
     */
    function generateMaterialRequest(materialResponse) {
        var json = {
            reference: lodash.get(materialResponse, 'reference'),
            description: lodash.get(materialResponse, 'description'),
            quantity: lodash.get(materialResponse, 'quantity'),
            quotedAmount: lodash.get(materialResponse, 'quotedAmount')
        };

        if (materialResponse.id) {
            json.id = materialResponse.id;
        }

        return requestDataFactory.createRequest(json);
    }

    /**
     * Generate a work required response
     *
     * @param workRequiredResponse
     */
    function generateWorkRequiredRequest(workRequiredResponse) {
        var json = {
            description: lodash.get(workRequiredResponse, 'description'),
            hours: lodash.get(workRequiredResponse, 'hours'),
            travelHours: lodash.get(workRequiredResponse, 'travelHours'),
            mileage: lodash.get(workRequiredResponse, 'mileage'),
            rate: lodash.get(workRequiredResponse, 'rate'),
            labourCharge: lodash.get(workRequiredResponse, 'labourCharge')
        };

        if (workRequiredResponse.id) {
            json.id = workRequiredResponse.id;
        }

        return requestDataFactory.createRequest(json);
    }

    /**
     * Generate a quote edit response
     *
     * @param requestData quoteResponse
     * @return requestData
     */
    function generateQuoteEditRequest(quoteResponse) {
        var json = {
            value: lodash.get(quoteResponse, 'value'),
            files: lodash.get(quoteResponse, '.files.files', [])
        };

        return requestDataFactory.createRequest(json);
    }

    /**
     * Generate a response to accept a quote
     *
     * @param requestdata
     */
    function generateAcceptQuoteRequest() {
        var json = {
            description: null,
            attendanceDueAt: null,
            poNumber: null,
            completionDueAt: null
        };

        return requestDataFactory.createRequest(json);
    }

    /**
     * Pass the property through custom display formatting
     *
     * @param {string} property
     * @returns {string}
     */
    function display(entity, property) {
        var text = entity[property];
        switch (property) {
            case 'status':
                text = $filter('translate')("QUOTE_STATUS_" + text);
                break;
        }

        return text;
    }

    /**
     * Quote actions
     *  ..with confirmation
     *   - Accept
     *   - Reject
     *   - Submit
     *   - Pending
     *  ..with confirmation note
     *   - RFMI
     * @param {string} action
     * @returns {Promise}
     */
    function quoteAction(action, quoteResponse) {
        var deferred = $q.defer(),
            message = 'SUBMIT_' + action.toUpperCase() + '_QUOTE',
            actionHref = lodash.get(quoteResponse, '_links.' + action + '.href'),
            filesCollection = lodash.get(quoteResponse, 'files.files', []),
            modal = {modal: confirmationModalNotes, type: 'note', noteRequired: false};

        // The note is required when submitting with no files.
        if (!actionHref) {

            return deferred.reject('PERMISSION_DENIED');
        }

        modal.modal.open({bodyMessage: message}).result.then(
            function (response) {
                var requestData;

                if (modal.type === 'note') {
                    requestData = {note: response};
                }

                return apiClient.create(actionHref, requestData || {}).then(function (isSuccess) {
                    if (isSuccess) {

                        deferred.resolve(isSuccess);
                    } else {
                        deferred.reject();
                    }
                });
            },
            function () {
                deferred.reject();
            }
        );

        return deferred.promise;
    }

    /**
     * Update an existing quote
     *
     * @param {RequestData} quoteResponse The original quoteRequest
     * @pram {RequestData} data The user data to update
     * @returns {Promise}
     */
    function quoteUpdate(quoteResponse, data) {
        var deferred = $q.defer(),
            quoteHref = lodash.get(quoteResponse, '_links.self.href');

        apiClient.update(quoteHref,
            {
                value: data.value,
                files: {links: data.files}
            }
        ).then(
            function (isSuccess) {
                if (isSuccess) {
                    deferred.resolve();
                } else {
                    deferred.reject();
                }
            },
            function (response) {
                deferred.reject(response);
            }
        );

        return deferred.promise;
    }
});
