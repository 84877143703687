angular.module('elogbooksDirectives').directive('simpleCriteria', [function(){
    return {
        restrict: 'AE',
        require: ['ngModel'],
        scope: {
            filter: '=ngModel'
        },
        templateUrl: '/modules/directives/simple-criteria/simple-criteria.html',
        controller: function ($scope) {
            $scope.clearValue = function(filter) {
                if (filter.clearValue) {
                    filter.value.startDate = moment(filter.clearValue.startDate);
                    filter.value.endDate = moment(filter.clearValue.endDate);
                }
            };
        }
    };
}]);
