app.config(function ($translateProvider) {
    $translateProvider.translations('en-gb', {
        METER: 'Meter',
        METERS: 'Meters',
        METERS_LIST: 'Meters',
        METERS_ADD: 'Add',
        METER_ADD: 'Add a new Meter',
        METER_EDIT : 'Edit Meter',
        METER_DELETED : 'Meter Deleted',
        METER_READING_IMPORTS: 'Import Readings',
        MANAGE_METERS: 'Manage Meters',
        UTILITY_PROVIDER: 'Utility Provider',
        MANUFACTURER: 'Manufacturer',
        SUPPLY_TYPE: 'Supply Type',
        OCCUPANCY: 'Occupancy',
        AUTOMATIC_READINGS: 'Automatically Read',
        ACCOUNT_NUMBER: 'Account Number',
        MULTIPLIER: 'Multiplier',
        DUAL_RATED: 'Dual Rated',
        SERIAL_NUMBER: 'Serial Number',
        ADD_NEW_READING : 'Add Reading',
        ADD_NEW_SUBMETER : 'Add Submeter',
        DATE_READ : 'Date And Time',
        READING : 'Reading',
        READINGS : 'Readings',
        NIGHT_READINGS: 'Night readings',
        NIGHT_READING: 'Night reading',
        DAY_READINGS: 'Day readings',
        DAY_READING: 'Day reading',
        LAST_READING: 'Last Reading',
        READ_BY: 'Read by',
        NO_READINGS: 'No Readings',
        NO_SUBMETERS: 'No Submeters',
        SUBMETERS: 'Submeters',
        HISTORY: 'History',
        DATE_READING_DUE: 'Reading Due',
        GOTOPARENT: 'Go to parent',
        METER_FREQUENCY: 'Frequency',
        METER_MAX_VALUE: 'Maximum value',
        OVER: 'Rolled Over',
        CONSUMPTION: 'Consumption',
        TUR: 'Tenant Utility Re-charge (TUR)',
        SUBMETER: 'Submeter',

        HIDE_SUBMETERS: 'Hide Submeters',
        SHOW_SUBMETERS: 'Show Submeters',

        METERSUPPLIERS : 'Meter Suppliers',
        METERMANUFACTURER : 'Meter Manufacturer',
        METERTYPE : 'Meter Type',
        METERTYPESUPPLY : 'Meter Supply',
        METERTYPEOCCUPANCY : 'Meter Occupancy',

        METER_MANUFACTURERS: 'Manufacturers',
        METER_OCCUPANCYTYPES: 'Occupancy Types',
        METER_SUPPLIERS: 'Suppliers',
        METER_SUPPLYTYPES: 'Supply Types',
        METER_TYPES: 'Types',
        METER_UNITS: 'Units',

        EDIT_READING: 'Edit Reading',
        METER_ADDED: 'Meter Added',
        METER_READING_ADDED: 'Meter Reading Added',
        METER_READING_UPDATED: 'Meter Reading Updated',
        METER_READING_DELETED: 'Meter Reading Deleted',
        REFERENCE_TYPE: 'Reference Type',
        IMAGES: 'Images',

        ERROR_READING_EXCEEDS_MAXVALUE: 'Reading must not be higher then set maximum value of the meter',
        ERROR_READING_MUST_BE_GREATER: 'Reading must be greater or equal to the previous reading',
        ERROR_READING_MUST_BE_LOWER: 'Reading must be less than or equal to the next reading',
        ERROR_INVALID_MAX_VALUE: 'Maximum value must be greater than zero',
        ERROR_MAX_VALUE_MUST_BE_HIGHER: 'Maximum value should not be lower than reading',
        ERROR_NO_METER_EXISTS_FOR_SITE: 'No meter exists for site',
        ERROR_ROLLED_OVER_INCORRECT_FORMAT: 'Rolled over format is incorrect',
        ERROR_FILE_IS_REQUIRED_ON_METER_READING: 'File is required for readings on this meter',
        ERROR_READ_AT_DATE_CANNNOT_BE_IN_FUTURE: 'Read at date cannot be in the future',
        ERROR_READING_INCORRECT_FORMAT: 'Reading type format is incorrect',
        ERROR_READING_TYPE_FOR_DUAL_RATED_MUST_BE_EITHER_DAY_OR_NIGHT: 'Reading type for dual rated meters must be either day or night',
        ERROR_MAX_VALUE_IS_REQUIRED: 'Max value is required',
        ERROR_READING_EXCEEDS_MAX_VALUE: 'Reading exceeds max value',

        INSUFFICIENT_PERMISSIONS_CANNOT_UPDATE_REFERENCE: 'Insufficient permissions, cannot update reference.',
        UNABLE_TO_DELETE_METER_UNIT: 'Unable to delete meter unit as it\'s in use',
        UNABLE_TO_EDIT_METER_UNIT: 'Unable to edit meter unit as it\'s in use. Only status can be changed',
        REQUIRE_FILE_ON_METER_READINGS: 'Require File on Meter Readings',

        METER_EXPORT_READINGS: 'Export readings',
        METER_FORCE_STICK_TO_DATE: 'Force stick to date',
        METER_GRACE_TYPE: 'Meter grace type',

        METER_GRACE_PERIOD_WEEK_BEFORE: 'Grace period week - before',
        METER_GRACE_PERIOD_WEEK_AFTER: 'Grace period week - after',
        METER_GRACE_PERIOD_MONTH_BEFORE: 'Grace period month - before',
        METER_GRACE_PERIOD_MONTH_AFTER: 'Grace period month - after',
        METER_GRACE_PERIOD_YEAR_BEFORE: 'Grace period year - before',
        METER_GRACE_PERIOD_YEAR_AFTER: 'Grace period year - after',

        METER_GRACE_PERIOD_WEEK_BEFORE_SUB_LABEL: 'How many days early the user can add a meter reading that\'s due weekly',
        METER_GRACE_PERIOD_WEEK_AFTER_SUB_LABEL: 'How many days late the user can add a meter reading that\'s due weekly',
        METER_GRACE_PERIOD_MONTH_BEFORE_SUB_LABEL: 'How many days early the user can add a meter reading that\'s due monthly',
        METER_GRACE_PERIOD_MONTH_AFTER_SUB_LABEL: 'How many days late the user can add a meter reading that\'s due monthly',
        METER_GRACE_PERIOD_YEAR_BEFORE_SUB_LABEL: 'How many days early the user can add a meter reading that\'s due yearly',
        METER_GRACE_PERIOD_YEAR_AFTER_SUB_LABEL: 'How many days late the user can add a meter reading that\'s due yearly',
        METER_GENERATE_WEEK_DAY_SUB_LABEL: 'The day of the week the meter reading date is due for weekly recurrence',
        METER_GENERATE_MONTH_DAY_SUB_LABEL: 'The day of the month the meter reading date is due for monthly recurrence',
        METER_GENERATE_YEAR_MONTH_DAY_SUB_LABEL: 'The month the meter reading date is due for yearly recurrence',
        METER_GENERATE_YEAR_DAY_SUB_LABEL: 'The day of the month the meter reading date is due for yearly recurrence',

        METER_GENERATE: 'Meter generate',
        METER_GENERATE_WEEK_DAY: 'Stick to week day',
        METER_GENERATE_MONTH_DAY: 'Stick to month day',
        METER_GENERATE_YEAR_DAY: 'Stick to year - month day',
        METER_GENERATE_YEAR_MONTH: 'Stick to year - month',
        METER_SETTINGS_VIEW: 'Meter settings',

        CANNOT_ENTER_FUTURE_DATE: 'Cannot Enter Future Date',

        UTILITY_CONSULTANT: 'Utility Consultant',

        READING_STATUS: 'Reading Status',
        FUTURE_READING: 'Future',

        OCCUPANCY_TYPE: 'Occupancy'
    });
});
