angular.module('elogbooksServices').service('EditPoNumberModal', ['$uibModal', function ($uibModal) {
    return {
        open: function (config, jobResponse) {
            return $uibModal.open({
                templateUrl: '/modules/common/modals/edit-po-number/edit-po-number.html',
                controller: 'EditPoNumberModalController',
                controllerAs: 'vm',
                resolve: {
                    config: function () {
                        var defaultConfig = {
                            titleMessage: 'EDIT_PO_NUMBER',
                            poNumberLabel: 'PO_NUMBER',
                            noteLabel: 'PO_NOTE',
                            poNumber: jobResponse.poNumber
                        };

                        return angular.extend({}, defaultConfig, config);
                    },
                    jobResponse: jobResponse
                }
            });
        }
    };
}]);
