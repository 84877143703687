(function () {
    'use strict';

    angular
        .module('elogbooks.admin.serviceroutines')
        .controller('ServiceRoutineAddEditController', ServiceRoutineAddEditController);

    ServiceRoutineAddEditController.$inject = ['serviceRoutineResponse', 'serviceRoutineCollectionResponse', 'crudService', '$translate', 'requestDataFactory'];

    function ServiceRoutineAddEditController (serviceRoutineResponse, serviceRoutineCollectionResponse, crudService, $translate, requestDataFactory) {
        var vm = this;
            vm.serviceRoutine = serviceRoutineResponse;
            vm.data = requestDataFactory.createRequest(vm.serviceRoutine);

            vm.statusOptions = [
                { value: true, title: $translate.instant('STATUS_ACTIVE') },
                { value: false, title: $translate.instant('STATUS_INACTIVE') }
            ];

        vm.create = createAction;
        vm.update = updateAction;
        vm.delete = deleteAction;

        function createAction () {
            return crudService.create(
                serviceRoutineCollectionResponse.getLink('create'),
                vm.data,
                'dashboard.admin.service-routines.list.details.info',
                'resource',
                'SERVICE_ROUTINE'
            );
        }

        function updateAction() {
            return crudService.update(
                vm.serviceRoutine.getLink('edit'),
                vm.data,
                'dashboard.admin.service-routines.list.details.info',
                'dashboard.admin.service-routines.list',
                'SERVICE_ROUTINE'
            );
        }

        function deleteAction () {
            return crudService.delete(
                vm.serviceRoutine.getLink('delete'),
                'dashboard.admin.service-routines.list',
                'SERVICE_ROUTINE'
            );
        }
    }
})();
