/**
 * Add-Edit Survey Schedule Controller
 */
(function () {
    'use strict';

    angular
        .module('elogbooks.user.sites')
        .controller('AddEditSurveyScheduleController', AddEditSurveyScheduleController);

    AddEditSurveyScheduleController.$inject = ['$scope', 'apiClient', '$state', 'lodash', 'confirmationModal', 'messenger', 'requestDataFactory', 'siteResponse', 'scheduleResponse', 'partnershipsCollection', 'surveysCollection', 'surveyScheduleService', 'surveySchedulesCollectionResponse', 'rootResourceResponse'];

    function AddEditSurveyScheduleController($scope, apiClient, $state, lodash, confirmationModal, messenger, requestDataFactory, siteResponse, scheduleResponse, partnershipsCollection, surveysCollection, surveyScheduleService, surveySchedulesCollectionResponse, rootResourceResponse) {
        var vm = this;
        vm.update = updateSchedule;
        vm.create = createSchedule;
        vm.delete = deleteSchedule;
        vm.data = requestDataFactory.createRequest(getDefaults());
        vm.scheduleResponse = scheduleResponse;
        vm.partnershipsCollection = partnershipsCollection;
        vm.surveysCollection = surveysCollection;
        vm.setForm = setForm;
        vm.selectedPartnership = null;

        vm.spSelect = {
            response: partnershipsCollection,
            responseKeyPath: 'partnerships',
            itemHrefPath: '_links.serviceprovider.href',
            itemValuePath: '_links.serviceprovider.title',
            onSelect: changeSP,
            onRemove: removeSP,
            required: true,
            selected: scheduleResponse ? {
                'href': scheduleResponse._links.serviceprovider.href,
                'title': scheduleResponse._links.serviceprovider.title,
                'object': scheduleResponse._links.serviceprovider
            } : null
        };

        vm.surveySelect = {
            response: surveysCollection,
            responseKeyPath: 'surveys',
            itemHrefPath: '_links.self.href',
            itemValuePath: 'name',
            onSelect: function() {},
            onRemove: function() {},
            required: true,
            disabled: !vm.spSelect.selected,
            selected: scheduleResponse ? {
                'href': scheduleResponse._links.survey.href,
                'title': scheduleResponse._links.survey.title,
                'object': scheduleResponse._links.survey
            } : null
        };
        function changeSP() {
            var params = {
                status: 'active',
                site: siteResponse.id,
                partnership : vm.spSelect.selected.object.getLinkAttribute('surveys', 'id'),
                serviceProvider: vm.spSelect.selected.object.getLinkAttribute('serviceprovider', 'id')
            }
            vm.surveySelect.disabled = true;
            vm.surveySelect.selected = null;
            vm.surveySelect.linkParameters = params;

            apiClient.get(rootResourceResponse.getLink('surveys'), params).then(function(response){
                vm.surveySelect.response = response;
                vm.surveySelect.disabled = false;
            });
        }

        function removeSP() {
            vm.surveySelect.disabled = true;
            vm.surveySelect.selected = null;
        }


        function setForm(form) {
            vm.form = form;
        }

        function getDefaults() {
            var data = {
                _links: {
                    serviceprovider: {href: lodash.get(scheduleResponse, '_links.serviceprovider.href')},
                    survey: {href: lodash.get(scheduleResponse, '_links.survey.href')}
                },
                rule: lodash.has(scheduleResponse, 'rule') ? [scheduleResponse.rule] : [],
                id: lodash.get(scheduleResponse, 'id'),
                spEditable: lodash.get(scheduleResponse, 'spEditable', true),
                active: lodash.get(scheduleResponse, 'active')
            };

            return data;
        }

        function createSchedule() {
            if (this.data.rule.length < 1) {
                messenger.error('NO_RULES_ENTERED');
                return;
            }

            var data = angular.extend(vm.data, {
                '_links': {
                    'serviceprovider': vm.spSelect.selected.object.getLink('serviceprovider'),
                    'survey': vm.surveySelect.selected.object.getLink('self')
                }
            });

            surveyScheduleService.create(surveySchedulesCollectionResponse.getLink('create'), data).then(
                function () {
                    $state.go('^', {}, {reload: $state.get('^')}).then(
                        function () {
                            messenger.success('SURVEY_SCHEDULE_CREATED');
                        }
                    );
                }
            );
        }

        function updateSchedule() {
            if (this.data.rule.length < 1) {
                messenger.error('NO_RULES_ENTERED');

                return;
            }

            var data = angular.extend(vm.data, {
                '_links': {
                    'serviceprovider':
                        vm.spSelect.selected.object.href ?
                            vm.spSelect.selected.object.href :
                            vm.spSelect.selected.object.getLink('serviceprovider'),
                    'survey':
                        vm.surveySelect.selected.object.href ?
                            vm.surveySelect.selected.object.href :
                            vm.surveySelect.selected.object.getLink('self')
                }
            });

            surveyScheduleService.update(scheduleResponse.getLink('self'), data).then(
                function () {
                    $state.go('^', {}, {reload: $state.get('^.^.^')}).then(
                        function () {
                            messenger.success('SURVEY_SCHEDULE_UPDATED');
                        }
                    );
                }
            );
        }

        function deleteSchedule() {
            return confirmationModal.open().result.then(
                function () {
                    surveyScheduleService.delete(scheduleResponse.getLink('delete')).then(
                        function () {
                            $state.go('dashboard.user.sites.list.details.surveys.schedules', {}, {reload: $state.get('^.^.^')}).then(
                                function () {
                                    messenger.success('SURVEY_SCHEDULE_DELETED');
                                }
                            );
                        }
                    );
                }
            );
        }
    }

})();
