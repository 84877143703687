(function () {
    'use strict';

    angular
        .module('elogbooks.admin.roles')
        .controller('UserRolesController', UserRolesController);

    UserRolesController.$inject = ['$state', 'messenger', 'apiClient', 'lodash', 'userResponse', 'roleCollectionResponse', 'userManager', '$scope'];

    function UserRolesController ($state, messenger, apiClient, lodash, userResponse, roleCollectionResponse, userManager, $scope) {
        var vm = this;
        vm.user = userResponse;
        vm.isClientAdminUser = userManager.hasPermission('user_permission_client_admin_manage_users') && !userManager.hasPermission('user_permission_manage_users');

        vm.form = null;
        vm.formControll = null;

        vm.setForm = (form) => {
            vm.form = form;

            let findController = (scope) =>
                scope.hasOwnProperty('vm') ? scope.vm :
                    (scope.hasOwnProperty('$parent') ? findController(scope.$parent) : null);

            let controller = findController($scope.$parent);
            if (controller && controller.formControll) {
                vm.formControll = controller.formControll;
                controller.formControll.setForm(form);
            }
        }

        //fetch roles
        vm.selectedRoles = [];
        vm.roles = roleCollectionResponse.getData().roles;
        apiClient.get(vm.user.getLink('roles'), {limit: 100}).then(function(response){
            vm.selectedRoles = response.roles;

            // Remove from the list of available sites those user already has access to.
            lodash.each(vm.selectedRoles, function(selectedUser) {
                vm.roles = lodash.reject(vm.roles, function(user){
                    return user.id === selectedUser.id;
                });
            });
        });

        vm.revoke = function () {
            vm.selectedRoles = lodash.difference(vm.selectedRoles, vm.revokeAccess);
            vm.roles = lodash.union(vm.roles, vm.revokeAccess);
        };

        vm.grant = function () {
            vm.roles = lodash.difference(vm.roles, vm.grantAccess);
            vm.selectedRoles = lodash.union(vm.selectedRoles, vm.grantAccess);
        };

        vm.submit = function () {
            var data = {
                _links: {
                    role: [
                    ]
                }
            };

            vm.formControll && vm.formControll.setFormDirty();

            lodash.each(vm.selectedRoles, function(role){
                data._links.role.push(role._links.self);
            });

            apiClient.update(vm.user.getLink('roles'), data).then(function(response){
                vm.formControll && vm.formControll.setFormNotDirty();

                if (response) {
                    messenger.success('Updated successfully');
                }
            });
        };
    }

})();
