(function () {
    'use strict';

    angular
        .module('elogbooks.admin.users')
        .controller('UserDetailsController', UserDetailsController);

    UserDetailsController.$inject = ['$uibModal', 'userResponse', 'userManager', '$scope', '$rootScope'];

    function UserDetailsController ($uibModal, userResponse, userManager, $scope, $rootScope) {
        var vm = this;
            vm.enablePasswordResetModel = enablePasswordResetModel;
            vm.user = userResponse;
            vm.clientAdminAccess = userManager.hasPermission('user_permission_client_admin_manage_users') && !userManager.hasPermission('user_permission_manage_users');
            vm.form = null;

            vm.formControll = {
                setForm: (form) => {
                    vm.form = form;

                    vm.form.$$element.change(() => {
                        vm.formControll.setFormNotDirty();
                    });
                },
                setFormDirty: () => {
                    if (vm.form) {
                        vm.form.$dirty = true
                        vm.form.dirty = true;
                    }

                    $rootScope.checkDirtySubmittedForms = true;
                    $rootScope.skipDirtyCheck = false;
                    $rootScope.forceDirtyConfirm = true;
                    $rootScope.dirtyFormInView = true;
                },
                setFormNotDirty: () => {
                    if (vm.form) {
                        vm.form.$dirty = false;
                        vm.form.dirty = false;

                        for (let control of vm.form.$$controls) {
                            control.$dirty = false;
                        }
                    }

                    $rootScope.checkDirtySubmittedForms = false;
                    $rootScope.skipDirtyCheck = true;
                    $rootScope.forceDirtyConfirm = false;
                    $rootScope.dirtyFormInView = false;
                }
            };

            $rootScope.$on('$stateChangeStart', () => {
                if (vm.form && !vm.form.hasOwnProperty('dirty')) {
                    vm.formControll.setFormNotDirty();
                }
            });

            function enablePasswordResetModel() {
            var modalInstance = $uibModal.open({
                templateUrl: '/modules/admin/users/details/user-password-reset-modal.html',
                controller: 'UserPasswordResetModalController',
                controllerAs: 'vm',
                resolve: {
                    userResource: function () {
                        return userResponse;
                    }
                }
            });
        }
    }

})();
