angular.module('elogbooksDirectives').directive('elbActive', function($filter){
    return {
        // restrict to attributes only
        restrict: 'A',
        require: ['ngModel'],
        scope: {
            active: '=ngModel'
        },
        templateUrl: '/modules/directives/form-elements/active/active.html',
        link: function (scope) {
            scope.options = [{
                value: true,
                label: $filter('translate')('STATUS_ACTIVE')
            },{
                value: false,
                label: $filter('translate')('STATUS_INACTIVE')
            }];
        }
    }
});
