(function() {
    'use strict';

    angular
        .module('elogbooks.user.finance.purchase-invoices')
        .controller('FinancePurchaseInvoicesInfoDetailsController', FinancePurchaseInvoicesInfoDetailsController);

    FinancePurchaseInvoicesInfoDetailsController.$inject = [
        'purchaseInvoiceResponse',
        'supplierResponse'
    ];

    function FinancePurchaseInvoicesInfoDetailsController(
        purchaseInvoiceResponse,
        supplierResponse
    ) {
        var vm = this;
        vm.purchaseInvoice = purchaseInvoiceResponse;
        vm.supplier = supplierResponse;
        vm.showMismatchAlert = parseFloat(vm.purchaseInvoice.invoiceAmount) !== parseFloat(vm.purchaseInvoice.invoiceValue);
    }
})();
