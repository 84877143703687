(function () {
    'use strict';

    angular
        .module('elogbooks.admin.service-providers')
        .controller('ServiceProvidersAddEditController', ServiceProvidersAddEditController);

    ServiceProvidersAddEditController.$inject = [
        '$state',
        'apiClient',
        'modulesService',
        'confirmationModal',
        'serviceProviderResponse',
        'requestDataFactory',
        'messenger',
        'serviceProviderCollection',
        'lodash',
        '$scope',
        '$translate',
        'serviceProviderGroupsResponse'
    ];

    function ServiceProvidersAddEditController (
        $state,
        apiClient,
        modulesService,
        confirmationModal,
        serviceProviderResponse,
        requestDataFactory,
        messenger,
        serviceProviderCollection,
        lodash,
        $scope,
        $translate,
        serviceProviderGroupsResponse
    ) {
        var vm = this;

        vm.serviceProvider = serviceProviderResponse;

        vm.serviceProviderGroupsModel = {
            response: serviceProviderGroupsResponse,
            link: serviceProviderGroupsResponse.getLink('self'),
            responseKeyPath: 'groups',
            onSelect: onChangeServiceProviderGroups,
            onRemove: onChangeServiceProviderGroups
        };

        vm.data = requestDataFactory.createRequest(getDefaults());
        vm.hideTrainedAt = vm.serviceProvider.hasOwnProperty('trainedAt');
        vm.notesRequired = false;
        vm.p2pEnabled = modulesService.isEnabled('p2p');
        vm.safecontractorEnabled = modulesService.isEnabled('safecontractor');

        if (vm.serviceProvider.groups && vm.serviceProvider.groups.groups) {
            vm.selectedServiceProviderGroups = vm.serviceProvider.groups.groups;
        } else {
            vm.selectedServiceProviderGroups = [];
        }

        vm.contractorApprovedOptions = [
            {title: $translate.instant('YES'), value: true},
            {title: $translate.instant('NO'), value: false}
        ];

        vm.ppmReminderAlertOptions = [
            {title: $translate.instant('PPM_REMINER_DISABLED'), value: 'disabled'},
            {title: $translate.instant('PPM_REMINDER_STATUTORY_ONLY'), value: 'statutory'},
            {title: $translate.instant('PPM_REMINDER_ALL'), value: 'all'}
        ];

        $scope.$watch('vm.data.inactiveAt', function(newVal) {
            vm.notesRequired = (!vm.serviceProvider.hasOwnProperty('inactiveAt') && newVal !== null);
        });


        vm.displayMandatoryLabourLineOptionsSelect = !!vm.data.requireLabourLineOnJobComplete;
        vm.mandatoryLabourLineOptionsModel = null;

        vm.mandatoryLabourLineOptions = [
            {title: $translate.instant('SELECT_MANDATORY_OPTION'), value: null},
            {title: $translate.instant('MANDATORY_LABOUR_LINE_OPTION_START_DATE'), value: 'start_date'},
            {title: $translate.instant('MANDATORY_LABOUR_LINE_OPTION_TIME_WORKED'), value: 'time_worked'},
            {title: $translate.instant('MANDATORY_LABOUR_LINE_OPTION_TIME_TRAVELLED'), value: 'time_travelled'},
            {title: $translate.instant('MANDATORY_LABOUR_LINE_OPTION_MILEAGE'), value: 'mileage'},
            {title: $translate.instant('MANDATORY_LABOUR_LINE_OPTION_DESCRIPTION'), value: 'description'},
        ];

        vm.create = createAction;
        vm.update = updateAction;
        vm.delete = deleteAction;
        vm.removeServiceProviderGroup = removeServiceProviderGroup;

        function getDefaults() {
            return {
                id: vm.serviceProvider.id || null,
                name: vm.serviceProvider.name || null,
                reference: vm.serviceProvider.reference || null,
                trainedAt: vm.serviceProvider.trainedAt ? new Date(vm.serviceProvider.trainedAt) : null,
                notes: vm.serviceProvider.notes || null,
                shared: vm.serviceProvider.isShared || null,
                jobValuesAutoApprovedDays: vm.serviceProvider.jobValuesAutoApprovedDays || 0,
                requiresAssetsWhenLoggingReactiveJob: lodash.get(vm.serviceProvider, 'requiresAssetsWhenLoggingReactiveJob', false),
                requireSignature: vm.serviceProvider.requireSignature || null,
                jobApprovalDisabled: lodash.get(vm.serviceProvider, 'jobApprovalDisabled', false),
                activeAt: vm.serviceProvider.activeAt ? new Date(vm.serviceProvider.activeAt) : null,
                inactiveAt: vm.serviceProvider.inactiveAt ? new Date(vm.serviceProvider.inactiveAt) : null,
                p2pReference: vm.p2pEnabled ? vm.serviceProvider.p2pReference : vm.serviceProvider.p2pReference || null,
                contractorApproved: vm.serviceProvider.hasOwnProperty('contractorApproved') ? vm.serviceProvider.contractorApproved : null,
                requireLabourLineOnJobComplete: vm.serviceProvider.requireLabourLineOnJobComplete || null,
                mandatoryLabourLineOptions: ((options) => {
                    // start date always display
                    if (options.indexOf('start_date') < 0) {
                        options.push('start_date');
                    }

                    return options;
                })(vm.serviceProvider.mandatoryLabourLineOptions || []),
                markupLabour: +(parseFloat(vm.serviceProvider.markupLabour, 10).toFixed(2)) || 0,
                markupMaterials: +(parseFloat(vm.serviceProvider.markupMaterials, 10).toFixed(2)) || 0,
                markupQuotes: +(parseFloat(vm.serviceProvider.markupQuotes, 10).toFixed(2)) || 0,
                ppmReminderAlertOption : vm.serviceProvider.ppmReminderAlertOption || 'disabled',
                ppmReminderNotificationPeriod: typeof vm.serviceProvider.ppmReminderNotificationPeriod === 'undefined' ? 14 : vm.serviceProvider.ppmReminderNotificationPeriod,
                safeContractorId: vm.serviceProvider.safeContractorId || null
            };
        }

        function createAction() {
            vm.data.groups = {
                links: []
            };
            for (var group in vm.selectedServiceProviderGroups) {
                vm.data.groups.links.push({
                    href: vm.selectedServiceProviderGroups[group].getLink('self')
                });
            }

            return apiClient.create(serviceProviderCollection.getLink('create'), vm.data).then(function (response) {
                if (response) {
                    $state.go('dashboard.admin.service-providers.list.details.info', { resource: response.getLink('self').encode() }, { reload: $state.current.parent }).then(function () {
                        messenger.success('SERVICE_PROVIDER_CREATED');
                    });
                } else {
                    messenger.error('REQUEST_ERROR');
                }
            });
        }

        function updateAction() {
            vm.data.groups = {
                links: []
            };
            for (var group in vm.selectedServiceProviderGroups) {
                vm.data.groups.links.push({
                    href: vm.selectedServiceProviderGroups[group].getLink('self')
                });
            }
            return apiClient.update(vm.serviceProvider.getLink('edit'), vm.data).then(function (response) {
                if (response) {
                    $state.go('^', {},  { reload: 'dashboard.admin.service-providers.list' }).then(function () {
                        messenger.success('SERVICE_PROVIDER_UPDATED');
                    });
                } else {
                    messenger.error('REQUEST_ERROR');
                }
            });
        }

        function deleteAction() {
            return confirmationModal.open().result.then(function () {
                return apiClient.delete(vm.serviceProvider.getLink('delete')).then(function (response) {
                    if (response) {
                        $state.go('dashboard.admin.service-providers.list', {}, { reload: 'dashboard.admin.service-providers.list' }).then(function () {
                            messenger.success('SERVICE_PROVIDER_DELETED');
                        });
                    } else {
                        messenger.error('REQUEST_ERROR');
                    }
                });
            });
        }

        function onChangeServiceProviderGroups(group) {
            if (group.selected && group.selected.object) {
                var groupSelected = false;

                for (var selectedGroup in vm.selectedServiceProviderGroups) {
                    if (vm.selectedServiceProviderGroups[selectedGroup].id === group.selected.object.id) {
                        groupSelected = true;
                        break;
                    }
                }

                if (!groupSelected) {
                    vm.selectedServiceProviderGroups.push(group.selected.object);
                }

                vm.serviceProviderGroupsModel.selected = null;
            }
        }

        function removeServiceProviderGroup(serviceProviderGroup) {
            for (var group in vm.selectedServiceProviderGroups) {
                if (vm.selectedServiceProviderGroups[group].id === serviceProviderGroup.id) {
                    vm.selectedServiceProviderGroups.splice(group, 1);
                    break;
                }
            }
        }

        $scope.$watch('vm.data.requireLabourLineOnJobComplete', (value) => vm.displayMandatoryLabourLineOptionsSelect = !!value);

        let updateMandatoryLabourLineOptions = (() => {
            let mandatoryLabourLineOptionsCopy = Object.assign({}, vm.mandatoryLabourLineOptions);
            let updateDisplayedOptions = () => {
                let displayOptions = [];

                angular.forEach(mandatoryLabourLineOptionsCopy, (option) => {
                    let isSelected = vm.data.mandatoryLabourLineOptions.indexOf(option.value) >= 0;

                    if (!isSelected) {
                        displayOptions.push(option);
                    }
                });

                vm.mandatoryLabourLineOptions = displayOptions;
            }

            updateDisplayedOptions();
            return updateDisplayedOptions;
        })();

        $scope.$watch('vm.mandatoryLabourLineOptionsModel', (value) => {
            if (value) {
                vm.mandatoryLabourLineOptionsModel = null;

                if (vm.data.mandatoryLabourLineOptions.indexOf(value) < 0) {
                    vm.data.mandatoryLabourLineOptions.push(value);
                    updateMandatoryLabourLineOptions();
                }
            }
        });

        vm.removeMandatoryLabourLineOption = function(option) {
            let newValue = [];

            for (let i in vm.data.mandatoryLabourLineOptions) {
                let value = vm.data.mandatoryLabourLineOptions[i];

                if (value !== option) {
                    newValue.push(value);
                }
            }

            vm.data.mandatoryLabourLineOptions = newValue;
            updateMandatoryLabourLineOptions();
        }
    }

})();
