app.config(function ($translateProvider) {
    $translateProvider.translations('zh-hk', {
        ASSET_TYPE: '資產類別',
        ASSET_TYPES: '資產類別',

        ADD_NEW_ASSET_TYPE: '新增資產類別',
        EDIT_ASSET_TYPE: '修改資產類別',

        ASSET_TYPE_LIST: '資產類別',
        ASSET_TYPE_ADD: '新增資產類別',
        ASSET_TYPE_EDIT: '修改資產類別',

        ASSET_TYPE_CREATED: '資產類別已建立',
        ASSET_TYPE_UPDATED: '資產類別已更新',
        ASSET_TYPE_DELETED: '資產類別已刪除',
        ASSET_TYPE_ADDED: '資產類別已新增',
    });
});
