(function () {
    'use strict';

    angular
        .module('elogbooks.common.sites')
        .controller('CommonLocationsController', CommonLocationsController);

    CommonLocationsController.$inject= ['locationsCollectionResponse', '$stateParams', '$state', 'config', '$filter'];

    function CommonLocationsController (locationsCollectionResponse, $stateParams, $state, config, $filter) {
        var vm = this;

        vm.locationsCollectionResponse = locationsCollectionResponse;
        vm.locations = locationsCollectionResponse.locations;
        vm.config = config;

        var statusOptions = [
            {
                title: $filter('translate')('NONE_SELECTED'),
                value: 'none'
            },
            {
                title: $filter('translate')('STATUS_ACTIVE'),
                value: 'yes'
            },
            {
                title: $filter('translate')('STATUS_INACTIVE'),
                value: 'no'
            }
        ];

        vm.criteria = {
            locationActive: {type: 'options', title: 'STATUS', value: $stateParams.locationActive, options: statusOptions, clearValue: 'none' },
            locationName: { type: 'text', title: 'NAME', value: $stateParams.locationName }
        };

        vm.order = order;
        vm.search = search;

        function search(params) {
            var override = {
                locationPage: 1
            };

            $state.go('.', angular.extend({}, params, override), {reload: $state.current});
        };

        function order (key) {
            $stateParams.locationOrder = key;

            $state.go('.', angular.extend({}, $stateParams), { reload: $state.current.name });
        }
    }
})();
