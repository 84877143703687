angular.module('elogbooksDirectives').directive('priorityDeadlineForm', ['lodash', function (lodash) {
    return {
        restrict: 'AE',
        require: ['ngModel'],
        scope: {
            model: '=ngModel',
            required: '=ngRequired',
            options: '=?'
        },
        templateUrl: '/modules/directives/priority-deadline-form/deadline-form.html',
        link: function ($scope, $element, $attrs) {
            $scope.priorityType = $attrs.priorityType || 'attend';
            $scope.priorityFieldTime = $scope.priorityType + 'Time';
            $scope.priorityFieldUnit = $scope.priorityType + 'Unit';
            $scope.deadline = true;

            // See if we are in a panel entity, if so use it's form
            var idx = 0,
                panelScope = $scope.$parent;

            while (idx < 4 && !lodash.has(panelScope, 'panel')) {
                panelScope = panelScope.$parent;
                idx++;
            }
            if (lodash.has(panelScope, 'form')) {
                $scope.form = panelScope.form;
            }

            $scope.$watch('[value,unit]', function () {
                if (typeof $scope.value === 'undefined' || typeof $scope.unit === 'undefined') {
                    $scope.model.period = 0;
                } else {
                    $scope.model.period = $scope.value * $scope.unit;
                    $scope.model.frequency = getFrequency($scope.unit);
                    $scope.model.interval = $scope.value;
                }

                if (typeof $scope.unit !== 'undefined') {
                    // don't include deadline field if unit is set to minutes or hours
                    if ($scope.unit < 86400) {
                        $scope.deadline = false;
                        $scope.model.timeOfDay = null;
                    } else {
                        $scope.deadline = true;
                    }
                }
            });

            function getFrequency(unit) {
                switch (unit) {
                    case "60":
                        return 1;
                    case "3600":
                        return 2;
                    case "86400":
                        return 3;
                    case "604800":
                        return 4;
                }
            }
        }
    };
}]);
