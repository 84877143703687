(function () {
    'use strict';

    angular
        .module('elogbooks.admin.sitegroups')
        .controller('SiteGroupInfoController', SiteGroupInfoController);

    SiteGroupInfoController.$inject = ['siteGroupResponse'];

    function SiteGroupInfoController (siteGroupResponse) {
        var vm = this;
            vm.sitegroup = siteGroupResponse;
    }
})();
