(function() {
    'use strict';

    angular
        .module('elogbooks')
        .config(Translations);

    function Translations($translateProvider) {
        $translateProvider.translations('en-gb', {
            SCHEDULES: 'Schedules',
            SURVEY_SCHEDULES: 'Survey Schedules',
            SURVEY_SCHEDULE: 'Schedule',
            SURVEYSCHEDULE_ADD: 'Add Schedule',

            SURVEY_REQUEST_ADD: 'Add KPI Survey',
            ADD_NEW_SURVEY_REQUEST: 'Add a new KPI Survey',

            ACCEPT_SURVEY: 'Accept KPI Survey',
            ADD_NEW_SURVEY: 'Add new KPI Survey',
            ADD_SURVEY: 'Add KPI Survey',
            ADD_SURVEY_LINE: 'Add KPI Survey line',
            EDIT_SURVEY: 'Edit KPI Survey',
            FM_COMMENT: 'FM comment',
            FM_SCORE: 'FM score',
            CALCULATED_SCORE: 'Score',
            LAST_UPDATED: 'Last updated',
            MAXIMUM_SCORE: 'Maximum score',
            REPORTING_PERIOD_MONTH_YEAR: 'Reporting Period',
            RESET_LINES: 'Reset lines',
            SAVE_CHANGES: 'Save changes',
            SELECT_SCORE_RANGE: 'Score range',
            SP_COMMENT: 'SP comment',
            SP_SCORE: 'SP score',
            SPSCORE_FMSCORE: 'SP/FM score',
            SUBMIT_SURVEY: 'Submit KPI Survey',
            SURVEY_ID: 'KPI Survey ID',
            SURVEY_LINES: 'KPI Survey lines',
            SURVEY_SITES: 'KPI Survey Sites',
            SURVEY_SITES_ADD: 'Add Site',
            SURVEY_REQUESTS: 'KPI Survey Requests',
            WEIGHT_1_TO_5: 'Weight (1 to 5)',

            REPORTING_MONTH: 'Reporting Month',
            REPORTING_YEAR: 'Reporting Year',
            ALLOW_SP_TO_COMPLETE_THIS_SURVEY: 'Allow Service Provider Operatives to complete this KPI Survey',

            SCORE_LESS_THAN: 'Score Less Than %',

            NO_ACTIVE_SURVEYS_FOR_SERVICE_PROVIDER: 'There are currently no active KPI Surveys for this Service Provider',

            STATUS_IN_PROGRESS: 'In Progress',
            STATUS_PENDING: 'Pending',
            STATUS_COMPLETED: 'Completed',
            STATUS_DONE: 'Done',
            STATUS_DONE_WITH_ERRORS: 'Done (with Errors)',
            STATUS_INVALID_FILE: 'Invalid File',
            STATUS_INVALID_TYPE: 'Invalid Type',
            STATUS_AUTOCOMPLETED: 'Completed (auto)',

            STATUS_OPEN: 'Open (Active or Pending)',
            STATUS_CLOSED: 'Closed',

            LAST_RUN: 'Last Run',
            NEXT_RUN: 'Next Run',
            NO_SURVEYS_FOR_SERVICE_PROVIDER: 'There are currently no active KPI Surveys for this Service Provider',
            SP_EDITABLE: 'SP Editable',
            SERVICEPROVIDER: 'Service Provider',
            SERVICEPROVIDER_GROUP: 'Service Provider Group',
            SURVEY: 'Survey',
            SPEDITABLE: 'SP Editable',
            CREATEDAT: 'Created At',
            UPDATEDAT: 'Update At',
            RULE: 'Rule',
            SURVEYSCHEDULE_EDIT: 'Edit Survey Schedule',
            SURVEY_SCHEDULE_CREATED: 'Survey Schedule Created',
            SURVEY_SCHEDULE_UPDATED: 'Survey Schedule Updated',
            SURVEY_SCHEDULE_DELETED: 'Survey Schedule Deleted',
            SURVEY_SAVED: 'Survey Saved',
            SURVEY_SUBMITTED: 'Survey Submitted',
            SURVEY_ACCEPTED: 'Survey Accepted',
            SITES_ADD: 'Add Site to KPI Survey',

            ERROR_NOT_ALLOWED_TO_UPDATE_FM_AND_SP_SCORE: 'You are not allowed to update FM and SP score',
            ERROR_NOT_ALLOWED_TO_UPDATE_FM_SCORE: 'You are not allowed to update the FM score',
            ERROR_NOT_ALLOWED_TO_UPDATE_SP_SCORE: 'You are not allowed to update the SP score',

            PENDING_KPI_SURVEYS: 'Pending KPI Surveys',

            SURVEY_ACTIVE_STATE_CHANGED: 'Survey State Changed',

            QUESTION_ANSWERED_CANNOT_DELETE: 'Question Answered Cannot Delete',

            SURVEYS: 'KPI Surveys',

            SURVEY_LIST: 'KPI Surveys',
            SURVEY_VIEW: 'Info',
            SURVEY_ADD: 'Add a new KPI Survey',
            SURVEY_EDIT: 'Edit KPI Survey',
            CHANGE_SITE_SURVEY_ACCESS: 'Add KPI Survey to Sites',
            SITE_ADDED: 'KPI Survey Added',

            SURVEY_CREATED: 'KPI Survey Created',
            SURVEY_UPDATED: 'KPI Survey Updated',
            SURVEY_DELETED: 'KPI Survey Deleted',

            UNABLE_TO_DELETE_SURVEY: 'Unable to delete KPI Survey',
            CANNOT_CHANGE_SURVEY_NAME: 'Cannot change Survey Name',

            SURVEY_TO_SUBMIT: 'KPI Surveys To Submit',
            SURVEY_TO_APPROVE: 'KPI Surveys To Approve',

            OUTSTANDING_SURVEYS: 'Outstanding KPI Surveys',
            SURVEY_INACTIVE: 'Survey Inactive - Cannot Update',
            NO_RULES_ENTERED: 'No Rules Entered',

            AUTOMATED_STATS: 'Automated Stats',
            SELECT_STATS: 'Select Stats',
            STAT_TYPE: 'Stat Type',
            UPDATE_STATS: 'Update Stats',
            STATISTIC: 'Statistic',
            SURVEY_STATS_UPDATED: 'Survey Stats Updated',

            SITES_DELETED_FROM_SURVEY: 'Sites deleted from survey',

            KPI_SURVEY_SCHEDULES: 'KPI Survey Schedules',

            REOPEN: 'Reopen',
            REOPEN_FOR_SP: 'Reopen for Service Provider',
            REOPEN_FOR_KPI_APPROVER: 'Reopen for KPI Approver',
            SURVEY_REOPENED: 'Survey Reopened',

            SURVEY_VERSION_ACTIVATED: 'Survey Version has been Activated',
            SURVEY_VERSION_DEACTIVATED: 'Survey Version has been Deactivated',
            INVALID_SURVEY_VERSION: 'Invalid Survey Version',
            SURVEY_MUST_HAVE_SURVEY_LINES: 'Survey Must have Survey Lines',

            SURVEY_MUST_BE_ACTIVE: 'Survey must be active',
            AGREE_WITH_SP_SCORE: "Agree with SP Score",
            AGREE_WITH_ALL_SP_SCORES: 'Agree with All SP Scores',
            ALLOW_NA_RESPONSE: 'Allow N/A Response',
            ALLOW_NA: 'Allow N/A',
            ALLOW_NA_RESPONSE_IS_DISABLED: 'Allow N/A Response is Disabled',

            KPI_SURVEY_ALREADY_EXISTS: 'You cannot create this KPI as a KPI Survey already exists',
            SURVEY_DOES_NOT_HAVE_ANY_RELATIONSHIPS: 'Survey does not have any relationships'
        });
    }
})();
