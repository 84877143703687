angular.module('elogbooksDirectives').directive('currencyInput', [function () {
    return {
        restrict: 'AE',
        scope: {
            model: '=',
            maxlength: '@',
            name: '@',
            id: '@',
            currency: '='
        },

        templateUrl: '/modules/directives/currency-input/currency-input.html',
        controller: function ($scope) {
            switch ($scope.currency) {
                case 'fa fa-eur':
                    $scope.symbolAlign = 'right';
                    break;
                default:
                    $scope.symbolAlign = 'left'
            }
        }
    };
}]);
