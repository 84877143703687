(function(){
    'use strict';

    angular
        .module('elogbooks.user.contracts')
        .controller('CompareFilesController', CompareFilesController);

    CompareFilesController.$inject = ['fileResponse', 'oldFileResponse','supportingFileResponse', 'supportingOldFileResponse'];

    function CompareFilesController(fileResponse, oldFileResponse, supportingFileResponse, supportingOldFileResponse) {
        var vm = this;
        vm.supportingFiles = [];
        vm.contractFile = [];

        vm.contractFile.push(fileResponse, oldFileResponse);

        vm.supportingFiles.push(supportingFileResponse, supportingOldFileResponse);
    }
})();