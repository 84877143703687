(function() {
    'use strict';

    angular
        .module('elogbooks.user.sites')
        .controller('UserSitePartnershipDetailInfoController', UserSitePartnershipDetailInfoController);

    UserSitePartnershipDetailInfoController.$inject = ['$state', 'apiClient', 'messenger', 'partnershipResponse', 'serviceProviderResponse', 'confirmationModal'];

    function UserSitePartnershipDetailInfoController($state, apiClient, messenger, partnershipResponse, serviceProviderResponse, confirmationModal) {
        var vm = this;
        vm.partnershipResponse = partnershipResponse;
        vm.serviceProviderResponse = serviceProviderResponse;

        vm.delete = deleteAction;

        function deleteAction() {
            return confirmationModal.open().result.then(function(response) {
                return apiClient.delete(vm.partnershipResponse.getLink('delete')).then(function(response) {
                    if (response) {
                        $state.go('^.^', {}, { reload: $state.get('^.^') }).then(function() {
                            messenger.success('PARTNERSHIP_DELETED');
                        });
                    } else {
                        messenger.error('REQUEST_ERROR');
                    }
                })
            });
        }
    }
})();
