app.config(function ($translateProvider) {
    $translateProvider.translations('en-gb', {
       REQUEST_LOGS: 'Request Logs',
       REQUEST_LOGS_LIST: 'Request Logs',
       REQUEST_LOGS_INFORMATION: 'Request logs information',
       REQUEST_LOG: 'Request log',
       REQUEST_BODY: 'Request Body',
       RESPONSE_BODY: 'Response Body',
       RESPONSE_STATUS: 'Response Status',
       RESPONSES_LIST: 'Responses list',
       RESPONSES: 'Responses',
       RESPONSE: 'Response',
       NON_WEBHOOK: 'Non-Webhook',
       STATUS_CODE: 'Status code',
       WEBHOOK_TYPE: 'Webhook type',
       RESPONSE_INFORMATION: 'Response information'
    });
});
