(function () {
    'use strict';

    angular
        .module('elogbooks.user.service-providers')
        .controller('UserServiceProvidersDetailsContactsController', UserServiceProvidersDetailsContactsController);

    UserServiceProvidersDetailsContactsController.$inject = ['serviceProviderResponse', 'contactInformationResponse', 'lodash'];

    function UserServiceProvidersDetailsContactsController (serviceProviderResponse, contactInformationResponse, lodash) {
        var vm = this;
        vm.serviceProvider = serviceProviderResponse;
        vm.contactInformation = contactInformationResponse;
        vm.addresses = vm.contactInformation.addresses;
        vm.contactInfoCollection = [];

        angular.forEach(vm.contactInformation.fields, function(contact, key) {
            var siteContact = {'value' : contact.value};
            var type = lodash.find(vm.contactInfoCollection, function(element) { return element.type ===  contact.type; });

            if ( type !== undefined) {
                type.siteContacts.push(siteContact);
            }
            else {
                type = {'type' : contact.type, 'siteContacts' : []};
                type.siteContacts.push(siteContact);
                vm.contactInfoCollection.push(type);
            }
        });
    }
})();