(function () {
    'use strict';

    angular
        .module('elogbooks.user.patrols')
        .controller('PatrolActiveController', PatrolActiveController);

    PatrolActiveController.$inject = ['$state', 'patrolInstanceCollection'];

    function PatrolActiveController ($state, patrolInstanceCollection) {
        var vm = this;
        vm.patrolInstances = patrolInstanceCollection;
    }
})();
