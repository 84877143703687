(function(){
    'use strict';

    angular
        .module('elogbooks.user.surveys')
        .config(registerRoutes);

    function registerRoutes($stateProvider) {
        $stateProvider
            .state('dashboard.user.surveys', {
                abstract: true,
                template: '<bookerize></bookerize>',
                ncyBreadcrumb: { skip: true }
            })
            .state('dashboard.user.surveys.list', {
                url: '/surveys?page&limit&{serviceProviderName:any}&surveyName&surveyStatus&reportingMonth&reportingYear&scoreLessThan&siteGroup&serviceProviderGroup&siteRegions&coreClients',
                parent: 'dashboard.user.surveys',
                views: parseBook({
                    'elogbooks-left@dashboard.user.surveys': {
                        templateUrl: '/modules/common/survey/survey-requests/list/survey-requests.html',
                        controller: 'SurveyRequestsListController',
                        controllerAs: 'vm'
                    }
                }),
                resolve: {
                    serviceProviderGroupCollectionResponse: function($stateParams, apiClient, rootResourceResponse) {
                        return apiClient.get(rootResourceResponse.getLink('serviceprovidergroups')).then(function(response) {
                            return response || apiClient.noResourceFound();
                        });
                    },
                    siteGroupCollectionResponse: function(apiClient, rootResourceResponse) {
                        if (rootResourceResponse.getLink('sitegroups')) {
                            return apiClient.get(rootResourceResponse.getLink('sitegroups')).then(function (response) {
                                return response || apiClient.noResourceFound();
                            });
                        }
                    },
                    regionCollectionResponse: function(apiClient, rootResourceResponse) {
                        return apiClient.get(rootResourceResponse.getLink('regions')).then(function (response) {
                            return response || apiClient.noResourceFound();
                        });
                    },
                    coreClientCollectionResponse: function(apiClient, rootResourceResponse) {
                        return apiClient.get(rootResourceResponse.getLink('coreclients')).then(function (response) {
                            return response || apiClient.noResourceFound();
                        });
                    },
                    surveyRequestCollectionResponse: function (paramsService, $stateParams, apiClient, user, selectedSiteResponse) {
                        var params = {
                            site: selectedSiteResponse ? selectedSiteResponse.id : null,
                            'status[]': paramsService.surveyStatus,
                            'coreClients[]': paramsService.coreClients,
                            'siteRegions[]': paramsService.siteRegions,
                            reportingPeriod: paramsService.reportingPeriod ? moment(new Date(paramsService.reportingPeriod)).format('MM/YYYY') : null,
                            scoreLessThan: paramsService.scoreLessThan,
                            name: paramsService.surveyName,
                            siteGroup: $stateParams.siteGroup ? JSON.parse($stateParams.siteGroup).v : null,
                            serviceProviderGroup: $stateParams.serviceProviderGroup ? JSON.parse($stateParams.serviceProviderGroup).v : null
                        };

                        if (!params.site && paramsService.site) {
                            params.site = paramsService.site;
                        }

                        return apiClient.getPage(user.getLink('surveyrequests'), angular.extend({}, paramsService, params));
                    }
                },
                params: {
                    page: "1",
                    limit: "30",
                    order: "id",
                    surveyStatus: {array: true},
                    serviceProviderName: null,
                    reportingPeriod: null,
                    scoreLessThan: null,
                    surveyName: null,
                    coreClients: {array: true},
                    siteGroup: null,
                    siteRegions: {array: true},
                    serviceProviderGroup: null
                },
                ncyBreadcrumb: {
                    label: '{{ ::"SURVEYS" | translate }}'
                }
            })
            .state('dashboard.user.surveys.list.add', {
                url: '/add',
                parent: 'dashboard.user.surveys.list',
                views: parseBook({
                    'elogbooks-right@dashboard.user.surveys': {
                        templateUrl: '/modules/common/survey/survey-requests/add-edit/survey-request-form.html',
                        controller: 'SurveyRequestAddController',
                        controllerAs: 'vm'
                    }
                }),
                resolve: {
                    siteResponse: function () {
                        return {};
                    },
                    siteCollectionResponse: function(apiClient, user, globalFilterParamsService) {
                        var params = {
                            datagroup: 'search'
                        };

                        params = angular.extend({}, params, globalFilterParamsService.getLocalStorageFilters());

                        return apiClient.get(user.getLink('sites'), params, 'long').then(function(response) {
                            return response || apiClient.noResourceFound();
                        });
                    },
                    partnershipsResponse: function(apiClient, selectedSiteResponse) {

                        if (selectedSiteResponse) {
                            let params = {
                                active: true,
                                serviceProvidersStatus: 'active'
                            };

                            return apiClient.get(selectedSiteResponse.getLink('partnerships'), params);
                        } else {
                            return null;
                        }
                    }
                },
                ncyBreadcrumb: {
                    label: '{{ ::"ADD" | translate }}'
                }
            })
            .state('dashboard.user.surveys.list.details', {
                url: '/details/{surveyResource}',
                parent: 'dashboard.user.surveys.list',
                views: parseBook({
                    'elogbooks-right@dashboard.user.surveys': {
                        templateUrl: '/modules/common/survey/survey-requests/details/survey-request.details.html',
                        controller: 'SurveyRequestDetailsController',
                        controllerAs: 'vm'
                    }
                }),
                resolve: {
                    surveyRequestResponse: function ($stateParams, apiClient, base64) {
                        return apiClient.get(base64.decode($stateParams.surveyResource));
                    },
                    surveyRequestLinesResponse: function (surveyRequestResponse) {
                        return surveyRequestResponse.getResource('lines');
                    },
                    config: function(){
                        return {
                            site: false
                        }
                    }
                },
                ncyBreadcrumb: {
                    label: '{{ ::$resolves().surveyRequestResponse._links.survey.title }}'
                }
            })
            .state('dashboard.user.surveys.list.details.edit', {
                url: '/edit',
                parent: 'dashboard.user.surveys.list.details',
                views: parseBook({
                    'elogbooks-right@dashboard.user.surveys': {
                        templateUrl: '/modules/common/survey/survey-requests/add-edit/survey-request-edit-form.html',
                        controller: 'SurveyRequestEditController',
                        controllerAs: 'vm'
                    }
                }),
                ncyBreadcrumb: {
                    label: '{{ ::"EDIT" | translate }}'
                }
            });
    }
})();
