app.config(function($translateProvider) {
    $translateProvider.translations('en-gb', {
        BILLING_SETTINGS: 'Billing Settings',
        BILLING_ENTITY_NAME: 'Billing Entity Name',
        BILLING_ADDRESS: ' Billing Address',
        BILLING_EMAIL: 'Billing Email',
        BILLING_APPROVAL_CHASES: 'Billing Approval Chases',
        PO_REQUIRED: 'PO Required?',
        AUTO_INVOICE: 'Auto Invoice',
        BATCH_JOBS_ON_INVOICE: 'Batch Jobs on Invoice',
        RELATED_PRICING_SCHEDULE: 'Related Pricing Schedule',
        USE_CORE_CLIENT_BILLING_SETTINGS: 'Use Core Client Billing Settings?',

        BILLING_SETTINGS_EDIT: 'Edit Billing Settings',
        BILLING_SETTINGS_ADD: 'Add Billing Settings',
        BILLING_SETTINGS_CREATED: 'Billing Settings created successfully',
        BILLING_SETTINGS_UPDATED: 'Billing Settings updated successfully',

        COST_APPROVERS_LIST: 'Cost Approvers',
        CONTRACT_MANAGER_APPROVERS: 'Contract Managers',
        CLIENT_APPROVERS: 'Clients',

        BACK_TO_BILLING_SETTINGS: 'Back to Billing Settings',

        BILLING_SETTINGS_PO_TOLERANCE: 'Purchase Order Tolerance',
        BILLING_SETTINGS_SECONDARY_STAGE_APPROVAL_REQUIRED: 'Secondary stage approval required?'
    });
});