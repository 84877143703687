(function () {
    'use strict';

    angular
        .module('elogbooks.user.finance.purchase-orders')
        .controller('FinancePurchaseOrdersController', FinancePurchaseOrdersController);

        FinancePurchaseOrdersController.$inject = [
            '$translate',
            '$stateParams',
            'purchaseOrdersCollectionResponse',
            'suppliersCollectionResponse',
            '$state'
        ];

        function FinancePurchaseOrdersController(
            $translate,
            $stateParams,
            purchaseOrdersCollectionResponse,
            suppliersCollectionResponse,
            $state
        ) {
            var vm = this;

            vm.statuses = {
                0: $translate.instant('PURCHASE_ORDER_STATUS_DRAFT'),
                1: $translate.instant('PURCHASE_ORDER_STATUS_ORDERED'),
                2: $translate.instant('PURCHASE_ORDER_STATUS_CANCELLED'),
                3: $translate.instant('PURCHASE_ORDER_STATUS_PART_RECEIVED'),
                4: $translate.instant('PURCHASE_ORDER_STATUS_COMPLETED'),
            }

            var statusOptions = [
                {
                    title: $translate.instant('PURCHASE_ORDER_STATUS_DRAFT'),
                    value: '0'
                },
                {
                    title: $translate.instant('PURCHASE_ORDER_STATUS_ORDERED'),
                    value: '1'
                },
                {
                    title: $translate.instant('PURCHASE_ORDER_STATUS_CANCELLED'),
                    value: '2'
                },
                {
                    title: $translate.instant('PURCHASE_ORDER_STATUS_PART_RECEIVED'),
                    value: '3'
                },
                {
                    title: $translate.instant('PURCHASE_ORDER_STATUS_COMPLETED'),
                    value: '4'
                },
            ];

            vm.purchaseOrdersCollectionResponse = purchaseOrdersCollectionResponse;
            vm.purchaseOrders = purchaseOrdersCollectionResponse.purchaseOrders;
            vm.order = orderAction;
            vm.search = searchAction;
            vm.criteria = {
                dateRange: {
                    type: 'date-range',
                    title: 'BETWEEN_DATES',
                    value: {
                        startDate: ($stateParams.dateRange !== null) ? moment(new Date($stateParams.dateRange.split(',')[0])) : null,
                        endDate: ($stateParams.dateRange !== null) ? moment(new Date($stateParams.dateRange.split(',')[1])) : null
                    },
                    clearValue: {
                        startDate: null,
                        endDate: null
                    }
                },
                supplier: {
                    type: 'jsonselectwidget',
                    title: 'SUPPLIER',
                    value: JSON.parse($stateParams.supplier),
                    options: {
                        response: suppliersCollectionResponse,
                        link: suppliersCollectionResponse ? suppliersCollectionResponse.getLink('self') : null,
                        itemValuePath: 'id',
                        itemTitlePath: 'name',
                        responseKeyPath: 'suppliers'
                    }
                },
                statuses: {
                    type: 'options',
                    title: 'STATUS',
                    value: $stateParams.statuses,
                    options: statusOptions,
                    multiple: true
                },
            }

            function orderAction(key) {
                $stateParams.purchaseOrdersOrder = key;
                $state.go('.', angular.extend({}, $stateParams), { reload: $state.current });
            }

            function searchAction(params) {
                var override = {
                    purchaseOrdersPage: 1
                };

                $state.go('.', angular.extend({}, params, override), { reload: $state.current });
            }
        }
})();
