(function () {
    'use strict';

    angular
        .module('elogbooks.common.audit')
        .controller('AuditAnswerTemplateTabsController', AuditAnswerTemplateTabsController);

    AuditAnswerTemplateTabsController.$inject = [];

    function AuditAnswerTemplateTabsController() {
        var vm = this;
    }
})();
