(function (){
    'use strict';

    angular
        .module('elogbooks.user.tasks')
        .controller('UserManageTasksController', UserManageTasksController);

    UserManageTasksController.$inject = ['sectionResource'];

    function UserManageTasksController(sectionResource) {
        var vm = this;
        vm.sectionResource = sectionResource;
    }
})();
