(function() {
    'use strict';

    angular
        .module('elogbooks.user.meter-suppliers')
        .config(registerRoutes)
        .config(registerRoutesList)
        .config(registerRoutesAdd)
        .config(registerRoutesDetails)
        .config(registerRoutesEdit);

    function registerRoutes($stateProvider) {
        $stateProvider
            .state('dashboard.user.meters.suppliers', {
                abstract: true,
                template: '<bookerize></bookerize>',
                ncyBreadcrumb: {
                    skip: true
                }
            });
    }

    function registerRoutesList($stateProvider) {
        $stateProvider
            .state('dashboard.user.meters.suppliers.list', {
                url: '/meter-suppliers?page&limit&order&id&name',
                parent: 'dashboard.user.meters.suppliers',
                views: parseBook({
                    'elogbooks-left@dashboard.user.meters.suppliers': {
                        templateUrl: '/modules/user/meters/meter-suppliers/meter-supplier-list.html',
                        controller: 'MeterSuppliersController',
                        controllerAs: 'vm'
                    }
                }),
                params: {
                    page: '1',
                    limit: '30',
                    order: 'id',
                    id: null,
                    name: null
                },
                resolve: {
                    meterSuppliersCollectionResponse: function($stateParams, apiClient, rootResourceResponse) {
                        return apiClient.get(rootResourceResponse.getLink('metersuppliers'), $stateParams).then( function(response) {
                            return response || apiClient.noResourceFound();
                        });
                    }
                },
                ncyBreadcrumb: {
                    label: '{{ ::"METER_SUPPLIER_LIST" | translate }}'
                }
            });
    }

    function registerRoutesAdd($stateProvider) {
        $stateProvider
            .state('dashboard.user.meters.suppliers.list.add', {
                url: '/add',
                parent: 'dashboard.user.meters.suppliers.list',
                views: parseBook({
                    'elogbooks-right@dashboard.user.meters.suppliers': {
                        templateUrl: '/modules/user/meters/meter-suppliers/add-edit/meter-supplier-add-edit.html',
                        controller: 'MeterSuppliersAddEditController',
                        controllerAs: 'vm'
                    }
                }),
                resolve: {
                    meterSupplierResponse: function() {
                        return {};
                    }
                },
                ncyBreadcrumb: {
                    label: '{{ ::"ADD" | translate }}'
                }
            });
    }

    function registerRoutesDetails($stateProvider) {
        $stateProvider
            .state('dashboard.user.meters.suppliers.list.details', {
                url: '/details/{resource}',
                abstract: true,
                parent: 'dashboard.user.meters.suppliers.list',
                views: parseBook({
                    'elogbooks-right@dashboard.user.meters.suppliers': {
                        templateUrl: '/modules/user/meters/meter-suppliers/details/meter-supplier-details.html',
                        controller: 'MeterSuppliersDetailsController',
                        controllerAs: 'vm'
                    }
                }),
                resolve: {
                    meterSupplierResponse: function(apiClient, $stateParams) {
                        return apiClient.get($stateParams.resource.decode()).then( function(response) {
                            return response || apiClient.noResourceFound();
                        });
                    }
                },
                ncyBreadcrumb: {
                    label: '{{ ::$resolves().meterSupplierResponse.name }}'
                }
            })
             .state('dashboard.user.meters.suppliers.list.details.info', {
                url: '/info',
                parent: 'dashboard.user.meters.suppliers.list.details',
                views: parseBook({
                    'elogbooks-right@dashboard.user.meters.suppliers.list.details': {
                        templateUrl: '/modules/user/meters/meter-suppliers/details/info/meter-supplier-info.html',
                        controller: 'MeterSuppliersInfoController',
                        controllerAs: 'vm'
                    }
                }),
                ncyBreadcrumb: {
                    label: '{{ ::"INFO" | translate }}'
                }
            });
    }

    function registerRoutesEdit($stateProvider) {
        $stateProvider
            .state('dashboard.user.meters.suppliers.list.details.info.edit', {
                url: '/edit',
                parent: 'dashboard.user.meters.suppliers.list.details.info',
                views: parseBook({
                    'elogbooks-right@dashboard.user.meters.suppliers': {
                        templateUrl: '/modules/user/meters/meter-suppliers/add-edit/meter-supplier-add-edit.html',
                        controller: 'MeterSuppliersAddEditController',
                        controllerAs: 'vm'
                    }
                }),
                ncyBreadcrumb: {
                    label: '{{ ::"EDIT" | translate }}'
                }
            });
    }

})();
