app.config(function ($translateProvider) {
    $translateProvider.translations('en-gb', {
        ROLE: 'Role',
        ROLES: 'Roles',

        ROLE_EDIT: 'Edit Role',
        ROLE_LIST: 'Roles',
        ROLE_ADD: 'Add a Role',

        USER_ROLE: 'User Role',
        SITE_MEMBERSHIP_ROLE: 'Site Membership Role',
        OPERATIVE_ROLE: 'Operative Role',

        ROLE_LIST: 'Roles',
        ROLE_ADD: 'Add a new Role',
        ROLE_EDIT: 'Edit Role',

        ROLE_CREATED: 'Role Created',
        ROLE_UPDATED: 'Role Updated',
        ROLE_DELETED: 'Role Deleted',

        ROLE_PERMISSIONS: 'Role Permissions',
        ROLE_PERMISSIONS_UPDATED: 'Role Permissions Updated',
        MANAGE_USERS: 'Manage Users'
    });
});
