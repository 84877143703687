(function(){
    'use strict';

    angular
        .module('elogbooks.user.patrols')
        .controller('PatrolsListController', PatrolsListController);

    PatrolsListController.$inject = ['$stateParams', 'patrolCollectionResponse', '$state', 'siteCollectionResponse', 'selectedSiteResponse'];

    function PatrolsListController ($stateParams, patrolCollectionResponse, $state, siteCollectionResponse, selectedSiteResponse) {
        var vm = this;
        vm.patrolCollectionResponse = patrolCollectionResponse;
        vm.patrols = patrolCollectionResponse.patrolInstances;
        vm.filter = filterAction;
        vm.saveParams = saveParams;
        vm.search = search;
        vm.order = order;

        vm.siteSelect = {
            response : siteCollectionResponse,
            link : siteCollectionResponse ? siteCollectionResponse.getLink('self') : null,
            responseKeyPath: 'sites',
            itemValuePath: 'name',
            itemHrefPath: 'id',
            disabled: $stateParams.selectedSiteResource
        };

        if (selectedSiteResponse) {
            vm.siteSelect.cacheItems= [{value:selectedSiteResponse.name, href:selectedSiteResponse.id, object:selectedSiteResponse}];
        }

        vm.criteria = {
            siteIds: { type: 'autocompletemulti', multiple: true, title: 'SITE', value: $stateParams.siteIds, options: vm.siteSelect, param: 'siteIds', max: 50 },
            dateDue: {
                type: 'date-range',
                title: 'DATE_DUE',
                value: {
                    startDate: ($stateParams.dateDue !== null) ? moment(new Date($stateParams.dateDue.split(',')[0])) : null,
                    endDate: ($stateParams.dateDue !== null) ? moment(new Date($stateParams.dateDue.split(',')[1])) : null
                },
                clearValue: {
                    startDate: null,
                    endDate: null
                }
            },
            patrolName: { type: 'text', title: 'PATROL', value: $stateParams.patrolName },
            serviceProviderName: { type: 'text', title: 'SERVICE_PROVIDER', value: $stateParams.serviceProviderName }
        };

        function saveParams (selectModel) {
            if (selectModel.mapValue) {
                if (selectModel.selected === undefined) {
                    params[selectModel.mapValue] = null;
                } else {
                    $stateParams[selectModel.mapValue] = selectModel.selected.object.id
                }
            }

            vm.search();
        }

        function search(params) {
            var override = {
                page: 1
            };

            $state.go('.', angular.extend({}, params, override), { reload: $state.current });
        }

        function filterAction (params) {
            $state.go('.', angular.extend({}, params), { reload: $state.current.name });
        }

        function order (key) {
            $stateParams.order = key;

            var override = {
                page: 1
            };

            $state.go('.', angular.extend({}, $stateParams, override), { reload: true });
        };
    }
})();
