(function () {
    'use strict';

    angular
        .module('elogbooks.user.reports')
        .controller('UserReportsQuotesMissedController', UserReportsQuotesMissedController);

    UserReportsQuotesMissedController.$inject = ['quotesCollection', 'operativeResponse', 'quoteParams', '$translate', 'modalConfig'];

    function UserReportsQuotesMissedController(quotesCollection, operativeResponse, quoteParams, $translate, modalConfig) {
        var vm = this;

        vm.quotesCollection = quotesCollection;
        vm.quotes = quotesCollection.quotes;
        vm.op = operativeResponse;
        vm.modalConfig = modalConfig;
    
        vm.listTitle = vm.modalConfig.titlePrefix + " - " + (
            (quoteParams.overdueCompletionHistoric) ?
                $translate.instant('QUOTES_MISSED') :
                $translate.instant('QUOTES_COMPLETED')
        );
    }
})();