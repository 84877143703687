/**
 * Color picker
 */
angular.module('elogbooksDirectives').directive('colorPicker', function () {
    return {
        restrict: 'E',
        templateUrl: '/modules/directives/color-picker/color-picker.html',
        replace: true,
        controller: colorPickerDirectiveController,
        controllerAs: 'vm',
        bindToController: {
            color: '=',
            options: '=',
            onColorChanged: '&'
        }
    };

    function colorPickerDirectiveController() {
        var vm = this;

        vm.changeColor = function (option) {
            if(vm.color != option) {
                var old = vm.color;
                vm.color = option;
                vm.onColorChanged({newColor: option, oldColor: old});
            }
        }
    }
});