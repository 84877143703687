app.config(function ($translateProvider) {
    $translateProvider.translations('nl', {
        TENANT: 'Huurder',
        TENANTS: 'Huurders',

        TENANT_LIST: 'Huurders',

        TENANT_ADD: 'Nieuwe Huurder Toevoegen',
        ADD_NEW_TENANT: 'Huurder Toevoegen',
        TENANT_EDIT: 'Huurder Bewerken',
        TENANT_CREATED: 'Huurder Aangemaakt',
        TENANT_DELETED: 'Huurder Verwijderd',

        EMAIL_SELECTED_TENANTS: '<i class="fa fa-mail-forward"></i> E-mail Huurders ({{ ::selected }} geselecteerd)',
        EMAIL_FILTERED: 'E-mail Gefilterde',
        FILTERED_TENANTS: 'Gefilterde Huurders',
        EMAIL_TENANTS: 'E-mail Huurders',

        TENANT_CONTACT_CREATED: 'Huurder gemaakt',
        TENANT_CONTACT_UPDATED: 'Huurder gewijzigd',
        TENANT_CONTACT_DELETED: 'Huurder verwijderd',

        NOT_A_TENANT: 'Geen huurder',
    });
});
