(function() {
    'use strict';

    angular
        .module('elogbooks')
        .config(Translations);

    function Translations($translateProvider) {
        $translateProvider.translations('nl', {
            PERFORMANCE_STATISTICS: 'Performance statistieken',
            PERFORMANCE_SERVICE_PROVIDER: 'Uw prestatie / performance',
            PERFORMANCE_STATUS: 'Status',
            PERFORMANCE_SYSTEM: 'Gemiddelde prestatie',

            PERFORMANCE_TYPE_AVERAGE: 'Gemiddelde prestatie',
            PERFORMANCE_TYPE_REACTIVE_ATTENDANCE_ACHIEVED: 'Reactief aanwezigheid',
            PERFORMANCE_TYPE_REACTIVE_COMPLETION_ACHIEVED: 'Reactief afgerond',
            PERFORMANCE_TYPE_PLANNED_COMPLETION_ACHIEVED: 'Preventief behaald',
            PERFORMANCE_TYPE_QUOTE_SLA_ACHIEVED: 'SLA offertes behaald',
            PERFORMANCE_TYPE_KPI_SURVEYS: 'KPI rapporten',

            PERFORMANCE_DASHBOARD_TYPE_AVERAGE: 'Overall<br />Performance',
            PERFORMANCE_DASHBOARD_TYPE_REACTIVE_ATTENDANCE_ACHIEVED: 'Reactief SLA<br />Aanwezig',
            PERFORMANCE_DASHBOARD_TYPE_REACTIVE_COMPLETION_ACHIEVED: 'Reactief SLA<br />Afgerond',
            PERFORMANCE_DASHBOARD_TYPE_PLANNED_COMPLETION_ACHIEVED: 'Preventief<br />Compleet',
            PERFORMANCE_DASHBOARD_TYPE_QUOTE_SLA_ACHIEVED: 'Offerte SLA<br />Compleet',
            PERFORMANCE_DASHBOARD_TYPE_KPI_SURVEYS: 'KPI<br />Surveys',

            PERFORMANCE_INFORMATION_TITLE: 'Performance Informatie',
            PERFORMANCE_INFORMATION_DESCRIPTION: 'Deze statistieken zijn berekend als gemiddelde over de laatste 30 dagen.',
            PERFORMANCE_TYPE_AVERAGE_INFORMATION: 'Het gemiddelde van alle andere scores',
            PERFORMANCE_TYPE_REACTIVE_ATTENDANCE_ACHIEVED_INFORMATION: 'Het percentage van keren dat u binnen de tijd aanwezig was bij een reactieve opdracht.',
            PERFORMANCE_TYPE_REACTIVE_COMPLETION_ACHIEVED_INFORMATION: 'The percentage of times you have completed a reactive job within the SLA deadline.',
            PERFORMANCE_TYPE_PLANNED_COMPLETION_ACHIEVED_INFORMATION: 'The percentage of times you have completed a PPM job within the SLA deadline.',
            PERFORMANCE_TYPE_QUOTE_SLA_ACHIEVED_INFORMATION: 'The percentage of times you have submitted a quote within the SLA deadline.',
            PERFORMANCE_TYPE_KPI_SURVEYS_INFORMATION: 'Based on the average FM KPI score in the previous calendar month. Selecting the traffic light will display those KPIs with an FM score which is less than 100%.',
        });
    }
})();
