(function() {
    'use strict';

    angular
        .module('elogbooks.admin.core-clients')
        .controller('FinanceSupplierAddEditController', FinanceSupplierAddEditController);

    FinanceSupplierAddEditController.$inject = [
        '$state',
        'apiClient',
        'confirmationModal',
        'messenger',
        'supplierResponse',
        'suppliersCollectionResponse',
        'requestDataFactory',
        'serviceProviderCollectionResponse',
        'vatRatesCollectionResponse',
        'countries',
        'status'
    ];

    function FinanceSupplierAddEditController(
        $state,
        apiClient,
        confirmationModal,
        messenger,
        supplierResponse,
        suppliersCollectionResponse,
        requestDataFactory,
        serviceProviderCollectionResponse,
        vatRatesCollectionResponse,
        countries,
        status
    ) {
        var vm = this;
        vm.countries = [ ];
        vm.supplier = supplierResponse;
        vm.suppliersCollectionResponse = suppliersCollectionResponse;
        vm.vatRatesCollectionResponse = vatRatesCollectionResponse;
        vm.data = requestDataFactory.createRequest(vm.supplier);
        vm.vatRateSelect = {};
        vm.serviceProviderSelect = {};

        countries.getAll().then(function (list) {
            for(var key in list) {
                vm.countries.push({
                    value: list[key],
                    title: list[key]
                });
            }
        });

        vm.statusOptions = status.activeInactive();

        vm.create = createAction;
        vm.update = updateAction;
        vm.delete = deleteAction;

        if (serviceProviderCollectionResponse) {
            vm.serviceProviderSelect = {
                response : serviceProviderCollectionResponse,
                link : serviceProviderCollectionResponse.getLink('self'),
                responseKeyPath: 'serviceproviders',
                disabled: false,
            };
        }

        if (vatRatesCollectionResponse) {
            vm.vatRateSelect = {
                response : vm.vatRatesCollectionResponse,
                link : vm.vatRatesCollectionResponse.getLink('self'),
                responseKeyPath: 'vatRates',
                itemHrefPath: '_links.self.href',
                itemValuePath: 'nameAndRate'
            };
            if (!supplierResponse.id) {
                var defaultVatRate = vatRatesCollectionResponse.vatRates.filter(function (vatRate) {
                    return vatRate.isDefault;
                });
                if (defaultVatRate.length > 0) {
                    vm.vatRateSelect.selected = {
                        object: defaultVatRate[0],
                        href: defaultVatRate[0].getLink('self'),
                        value: defaultVatRate[0].nameAndRate
                    };
                }
            }
        }

        if (vm.supplier._links && typeof vm.supplier._links.serviceprovider !== 'undefined') {
            vm.serviceProviderSelect.selected = vm.supplier._links.serviceprovider;
        }

        if (vm.supplier._links && typeof vm.supplier._links.vatRate !== 'undefined') {
            vm.vatRateSelect.selected = vm.supplier._links.vatRate;
        }
        
        function updateAction() {
            if (!getFormData()._links || !getFormData()._links.vatRate) {
                return;
            }
            return apiClient.update(vm.supplier.getLink('edit'), getFormData()).then(function (response) {
                if (response) {
                    $state.go('^', {}, { reload: 'dashboard.user.finance.suppliers.list' }).then(function () {
                        messenger.success('SUPPLIER_UPDATED');
                    });
                } else {
                    messenger.error('REQUEST_ERROR');
                }
            });
        }

        function createAction() {
            if (!getFormData()._links || !getFormData()._links.vatRate) {
                return;
            }
            return apiClient.create(vm.suppliersCollectionResponse.getLink('create'), getFormData()).then(function (response) {
                if (response) {

                    $state.go('dashboard.user.finance.suppliers.list.details.info', { resource: response.getLink('self').encode() }, { reload: $state.current.parent }).then(function () {
                        messenger.success('SUPPLIER_CREATED');
                    });
                } else {
                    messenger.error('REQUEST_ERROR');
                }
            });
        }

        function deleteAction() {
            return confirmationModal.open().result.then(function(response) {
                if (response.result) {
                     apiClient.delete(vm.data.getLink('delete')).then(function(isSuccess) {
                        if (isSuccess) {
                            $state.go('dashboard.user.finance.suppliers.list', {}, { reload: 'dashboard.user.finance.suppliers.list' }).then(function (){
                                messenger.success('SUPPLIER_DELETED');
                            });
                        } else {
                            messenger.error('REQUEST_ERROR');
                        }
                    });
                }
            });
        }

        function processContactInformation(data) {
            var contactInformation = {
                fields: [],
                addresses: [],
            };

            contactInformation.addresses.push(data.address);

            contactInformation.fields.push({
                type: 'telephone',
                value: data.telephone,
            });

            contactInformation.fields.push({
                type: 'email',
                value: data.email,
            });

            return contactInformation;
        }

        function getFormData() {
            var data = angular.extend({}, vm.data);

            data.contactInformation = processContactInformation(data);
            data._links = {};
            if (vm.serviceProviderSelect.selected) {
                data._links.serviceprovider =  { href: vm.serviceProviderSelect.selected.href };
            } else {
                data._links.serviceprovider = undefined;
            }
            if (vm.vatRateSelect.selected) {
                data._links.vatRate = { href: vm.vatRateSelect.selected.href };
            } else {
                data._links.vatRate = undefined;
            }

            data.status = data.active;

            return data;
        }
    }
})();
