(function (){
    'use strict';

    angular
        .module('elogbooks.user.tenants')
        .controller('UserEmailRecipientsController', UserEmailRecipientsController);

    UserEmailRecipientsController.$inject = ['recipientCollectionResponse', '$state', 'config', '$stateParams', 'siteCollectionResponse', 'selectedSiteResponse','rootResourceResponse'];

    function UserEmailRecipientsController(recipientCollectionResponse, $state, config, $stateParams, siteCollectionResponse, selectedSiteResponse, rootResourceResponse) {
        var vm = this;
        vm.recipientCollectionResponse = recipientCollectionResponse;
        vm.recipients = recipientCollectionResponse.emailLogRecipients;
        vm.config = config.config;
        vm.stateParams = $stateParams;

        vm.search = search;

        vm.siteSelect = {
            response : siteCollectionResponse,
            link : siteCollectionResponse ? siteCollectionResponse.getLink('self') : null,
            responseKeyPath: 'sites',
            itemValuePath: 'id',
            itemTitlePath: 'name',
            disabled: $stateParams.selectedSiteResource
        };

        if (selectedSiteResponse) {
            vm.siteSelect.selected =  JSON.stringify({
                v: selectedSiteResponse.id,
                n: selectedSiteResponse.name
            });
        }

        var site = $stateParams.erSite ? JSON.parse($stateParams.erSite): null;

        vm.criteria = {
            erSite: { type: 'jsonselectwidget',
                title: 'SITE',
                value:  vm.siteSelect.selected ? JSON.parse(vm.siteSelect.selected) : site,
                options: vm.siteSelect
            },
            erName: {type: 'text', title: 'NAME', value: $stateParams.erName},
            erCompany: {type: 'text', title: 'COMPANY', value: $stateParams.erCompany},
            erEmail: {type: 'text', title: 'EMAIL', value: $stateParams.erEmail}
        };

        vm.resources = {
            'rootResource': rootResourceResponse,
            'stateParams': $stateParams,
            'root': rootResourceResponse
        };

        vm.emails = [];

        for (var i = 0; i < vm.recipients.length; i++){
            vm.emails[i] = vm.recipients[i].emails.split(',');
        }

        function search(params) {
            var override = {
                erPage: 1
            };

            $state.go('.', angular.extend({}, params, override), {reload: $state.current});
        }
    }
})();
