(function () {
    'use strict';

    angular
        .module('elogbooks.admin.client-billing')
        .config(registerRoutes)
        .config(registerRoutesList)
        .config(registerRoutesHistoricalReports)
        .config(registerRoutesConfig)
        .config(registerRoutesAddEdit);

    function registerRoutes($stateProvider) {
        $stateProvider
            .state('dashboard.admin.client-billing', {
                abstract: true,
                templateUrl: '/modules/admin/client-billing/client-billing.html',
                controller: 'ClientBillingTabsController',
                controllerAs: 'vm',
                resolve: {
                    billingTypesCollectionResponse: function (apiClient, rootResourceResponse) {
                        if (rootResourceResponse.getLink('billingtypes')) {
                            return apiClient.get(rootResourceResponse.getLink('billingtypes'), { limit: 999 }).then(function(response) {
                                return response || apiClient.noResourceFound();
                            });
                        }
                    },
                    clientBillingResponse: function(rootResourceResponse, apiClient) {
                        if (rootResourceResponse.getLink('clientbilling')) {
                            return apiClient.get(rootResourceResponse.getLink('clientbilling'), {}).then(function(response) {
                                return response || apiClient.noResourceFound();
                            });
                        } else {
                            return apiClient.noResourceFound();
                        }
                    },
                    settingsCollection : function (elbSettings) {
                        return elbSettings.getSettings(true) || [];
                    },
                    contractManager: function (rootResourceResponse, settingsCollection, apiClient) {
                        var contractManagerId = settingsCollection.settings ? settingsCollection.settings.filter(function (setting) {
                            return setting.name === 'general_contract_manager';
                        })[0].value : null;

                        if (typeof contractManagerId === 'number' && contractManagerId > 0) {
                            return apiClient.get(rootResourceResponse.getLink('users') + '/' + contractManagerId, {});
                        }

                        return null;
                    }
                },
                ncyBreadcrumb: {
                    label: '{{ ::"CLIENT_BILLING" | translate }}'
                },
                data: {
                    permissions: ['user_permission_view_client_billing', 'user_permission_manage_client_billing']
                }
            });
    }

    function registerRoutesList($stateProvider) {
        $stateProvider
            .state('dashboard.admin.client-billing.list', {
                url: '/client-billing',
                parent: 'dashboard.admin.client-billing',
                views: {
                    "@dashboard.admin.client-billing": {
                        templateUrl: '/modules/admin/client-billing/client-billing-list/client-billing-list.html',
                        controller: 'ClientBillingController',
                        controllerAs: 'vm'
                    }
                },
                params: {},
                ncyBreadcrumb: {
                    label: '{{ ::"CLIENT_BILLING" | translate }}'
                }
            });
    }

    function registerRoutesAddEdit($stateProvider) {
        $stateProvider
            .state('dashboard.admin.client-billing.list.addedit', {
                url: '/addEdit',
                parent: 'dashboard.admin.client-billing.list',
                views: {
                    "@dashboard.admin.client-billing": {
                        templateUrl: '/modules/admin/client-billing/client-billing-list/add-edit/client-billing-add-edit.html',
                        controller: 'ClientBillingAddEditController',
                        controllerAs: 'vm'
                    }
                },
                params: {},
                ncyBreadcrumb: {
                    label: '{{ ::"EDIT" | translate }}'
                }
            });
    }

    function registerRoutesHistoricalReports($stateProvider) {
        $stateProvider.state('dashboard.admin.client-billing.historical-reports', {
            url: '/historical-reports?clientBillingReportPage&clientBillingReportLimit&clientBillingReportOrder',
            parent: 'dashboard.admin.client-billing',
            views: {
                "@dashboard.admin.client-billing": {
                    templateUrl: '/modules/admin/client-billing/historical-reports/historical-reports.html',
                    controller: 'HistoricalReportsController',
                    controllerAs: 'vm'
                }
            },
            params: {
                clientBillingReportPage: '1',
                clientBillingReportLimit: '30',
                clientBillingReportOrder: '-created_at'
            },
            resolve: {
                reportsCollection: function(clientBillingResponse, apiClient, $stateParams) {
                    if (clientBillingResponse && clientBillingResponse.getLink('get-clientbillingreports') && clientBillingResponse.id) {
                        return apiClient.get(clientBillingResponse.getLink('get-clientbillingreports'), angular.extend({}, $stateParams, {
                            page: $stateParams.clientBillingReportPage,
                            limit: $stateParams.clientBillingReportLimit,
                            order: $stateParams.clientBillingReportOrder,
                        })
                        ).then(function(response) {
                            return response;
                        });
                    }
                },
            },
            ncyBreadcrumb: {
                label: '{{ ::"CLIENT_BILLING_HISTORICAL_REPORTS" | translate }}'
            },
        })
    }

    function registerRoutesConfig($stateProvider) {
        $stateProvider.state('dashboard.admin.client-billing.config', {
            url: '/config',
            parent: 'dashboard.admin.client-billing',
            views: {
                "@dashboard.admin.client-billing": {
                    templateUrl: '/modules/admin/client-billing/config/config.html',
                    controller: 'ClientBillingConfigController',
                    controllerAs: 'vm'
                },
                "feed@dashboard.admin.client-billing": {
                    templateUrl: '/modules/admin/client-billing/config/events/event-feed.html',
                    controller: 'FeedController',
                    controllerAs: 'vm'
                }
            },
            resolve: {
                recipientsCollection: function(clientBillingResponse, apiClient) {
                    if (clientBillingResponse.getLink('get-recipients') && clientBillingResponse.id) {
                        return apiClient.get(clientBillingResponse.getLink('get-recipients'), {}).then(function(response) {
                            return response;
                        });
                    }
                },
                usersCollection: function(rootResourceResponse, apiClient, contractManager, clientBillingResponse) {
                    var params = {
                        'category[]': 0,
                        status: 'active',
                        exclude: contractManager ? contractManager.username : null,
                    }

                    if (clientBillingResponse.id) {
                        return apiClient.get(rootResourceResponse.getLink('users'), params).then(function(response) {
                            return response;
                        });
                    }
                },
                eventsCollection: function (apiClient, clientBillingResponse) {
                    return apiClient.get(clientBillingResponse.getLink('events')).then(function(response) {
                        return response || apiClient.noResourceFound();
                    });
                },
                entityResponse: function (clientBillingResponse) {
                    return clientBillingResponse;
                }
            },
            params: {},
            ncyBreadcrumb: {
                label: '{{ ::"CLIENT_BILLING_CONFIG" | translate }}'
            }
        });

        $stateProvider.state('dashboard.admin.client-billing.config.edit', {
            url: '/edit-settings',
            parent: 'dashboard.admin.client-billing.config',
            views: {
                "@dashboard.admin.client-billing": {
                    templateUrl: '/modules/admin/client-billing/config/edit/client-billing-edit-settings.html',
                    controller: 'ClientBillingConfigEditSettingsController',
                    controllerAs: 'vm'
                }
            },
            params: {},
            ncyBreadcrumb: {
                label: '{{ ::"EDIT" | translate }}'
            }
        });

        $stateProvider.state('dashboard.admin.client-billing.trend-graph', {
            url: '/client-billing/trend-graph',
            parent: 'dashboard.admin.client-billing',
            views: {
                '@dashboard.admin.client-billing': {
                    templateUrl: '/modules/admin/client-billing/trend-graph/client-billing-trend-graph.html',
                    controller: 'ClientBillingTrendGraphController',
                    controllerAs: 'vm'
                }
            },
            resolve: {
                reportsCollection: function(clientBillingResponse, apiClient, $stateParams) {
                    if (clientBillingResponse && clientBillingResponse.getLink('get-clientbillingreports') && clientBillingResponse.id) {
                        return apiClient.get(clientBillingResponse.getLink('get-clientbillingreports'), angular.extend({}, $stateParams, {
                                page: $stateParams.clientBillingReportPage,
                                limit: $stateParams.clientBillingReportLimit,
                                order: $stateParams.clientBillingReportOrder,
                                trendGraph: true
                            })
                        ).then(function(response) {
                            return response;
                        });
                    }
                },
            },
            ncyBreadcrumb: {
                label: '{{ ::"CLIENT_BILLING_TREND_GRAPH" | translate }}'
            }
        });

        $stateProvider.state(
            'dashboard.admin.client-billing.portfolio-variations',
            {
                url: '/client-billing/portfolio-variations?month1&month2&page&limit',
                parent: 'dashboard.admin.client-billing',
                views: {
                    '@dashboard.admin.client-billing': {
                        templateUrl:
                            '/modules/admin/client-billing/portfolio-variations/client-billing-portfolio-variations.html',
                        controller:
                            'ClientBillingPortfolioVariationsController',
                        controllerAs: 'vm',
                    },
                },
                resolve: {
                    portfolioVariationsStartDate: function(clientBillingResponse, apiClient) {
                        if (localStorage.getItem('portfolioStartDate')) {
                            return JSON.parse(localStorage.getItem('portfolioStartDate'));
                        } else if (clientBillingResponse.getLink('portfolio-variations-oldest-entry') && clientBillingResponse.id) {
                            return apiClient.get(clientBillingResponse.getLink('portfolio-variations-oldest-entry')).then(function(response) {
                                if (response.year) {
                                    localStorage.setItem('portfolioStartDate', JSON.stringify(response));
                                }

                                return response;
                            });
                        }
                    },
                },
                params: {
                    month1: null,
                    month2: null,
                    page: '1',
                    limit: '30',
                    variationsResponse: null,
                },
                ncyBreadcrumb: {
                    label: '{{ ::"CLIENT_BILLING_PORTFOLIO_VARIATIONS" | translate }}',
                },
            }
        );
    }
})();
