(function () {
    'use strict';

    angular
        .module('elogbooks.admin.pcd-imports')
        .controller('PCDImportListController', ['importsCollectionResponse', '$state', '$stateParams', '$translate', PCDImportListController]);

    function PCDImportListController(importsCollectionResponse, $state, $stateParams, $translate) {
        var vm = this;
        vm.importsCollection = importsCollectionResponse;
        vm.imports = importsCollectionResponse.imports;
        vm.entityName = $translate.instant('PCD');
        vm.importRoute = '.new-pcd-import';
        vm.isCurrentState = $state.includes('dashboard.admin.pcd-imports');
        vm.currentState = 'dashboard.admin.pcd-imports';
        vm.noBorder = false;

        var typeOptions = [
            {
                title: $translate.instant('NONE_SELECTED'),
                value: null
            }
        ];

        vm.search = searchAction;
        vm.criteria = {
            type: { type: 'options', title: 'TYPE', value: $stateParams.type, options: typeOptions }
        };

        function searchAction (params) {
            var override = {
                page: 1
            };
            $state.go('.', angular.extend({}, params, override), { reload: $state.current });
        }
    }
})();
