(function () {
    'use strict';

    angular
        .module('elogbooks.user.patrols')
        .controller('ManagePatrolsDetailsController', ManagePatrolsDetailsController);

    ManagePatrolsDetailsController.$inject = ['patrolResponse'];

    function ManagePatrolsDetailsController (patrolResponse) {
        var vm = this;
        vm.patrol = patrolResponse;
    }
})();
