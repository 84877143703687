(function () {
    'use strict';

    angular
        .module('elogbooks.user.patrols')
        .controller('PatrolOccurrenceDetailController', PatrolOccurrenceDetailController);

    PatrolOccurrenceDetailController.$inject = ['$state', 'patrolOccurrenceResponse', 'patrolOccurrenceCollection', 'daysOfWeek'];

    function PatrolOccurrenceDetailController ($state, patrolOccurrenceResponse, patrolOccurrenceCollection, daysOfWeek) {
        var vm = this;
        vm.occurrence = patrolOccurrenceResponse;
        vm.occurrences = patrolOccurrenceCollection;
        vm.daysOfWeek = daysOfWeek;
    }
})();
