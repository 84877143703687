(function () {
    'use strict';

    angular
        .module('elogbooks.user.finance.suppliers')
        .controller('FinanceSuppliersController', FinanceSuppliersController);

        FinanceSuppliersController.$inject = [
            '$filter',
            '$translate',
            '$stateParams',
            'suppliersCollectionResponse',
            '$state',
            'messenger',
            'apiClient',
            'rootResourceResponse'
        ];

        function FinanceSuppliersController(
            $filter,
            $translate,
            $stateParams,
            suppliersCollectionResponse,
            $state,
            messenger,
            apiClient,
            rootResourceResponse
        ) {
            var vm = this;

            var activeOptions = [
                {
                    title: $filter('translate')('NONE_SELECTED'),
                    value: null
                },
                {
                    title: $filter('translate')('STATUS_ACTIVE'),
                    value: 'true'
                },
                {
                    title: $filter('translate')('STATUS_INACTIVE'),
                    value: 'false'
                }
            ];

            vm.suppliersCollectionResponse = suppliersCollectionResponse;
            vm.suppliers = suppliersCollectionResponse.suppliers;
            vm.order = orderAction;
            vm.search = searchAction;
            vm.criteria = {
                name: {
                    type: 'text',
                    title: 'NAME',
                    value: $stateParams.name
                },
                companyNumber: {
                    type: 'text',
                    title: 'COMPANY_NUMBER',
                    value: $stateParams.companyNumber
                },
                externalReference: {
                    type: 'text',
                    title: 'EXTERNAL_REFERENCE',
                    value: $stateParams.externalReference
                },
                active: {
                    type: 'options',
                    title: 'IS_ACTIVE',
                    value: $stateParams.active,
                    options: activeOptions
                }
            }

            function orderAction(key) {
                $stateParams.supplierOrder = key;
                $state.go('.', angular.extend({}, $stateParams), { reload: $state.current });
            }

            function searchAction(params) {
                var override = {
                    supplierPage: 1
                };

                $state.go('.', angular.extend({}, params, override), { reload: $state.current });
            }
        }
})();
