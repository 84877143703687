(function () {
    'use strict';

    angular
        .module('elogbooks.user.sites')
        .controller('UserNotesController', UserNotesController);

    UserNotesController.$inject= ['$state', 'noteCollectionResponse', 'siteNoteTypeCollectionResponse'];

    function UserNotesController ($state, noteCollectionResponse, siteNoteTypeCollectionResponse) {
        var vm = this;
        vm.siteNoteTypes = siteNoteTypeCollectionResponse.sitenotetypes;
        vm.editable = noteCollectionResponse.canCreate;
        vm.noBorder = $state.current.name.indexOf("location") !== -1;

        if (noteCollectionResponse.siteNotes !== undefined) {
            vm.notes = noteCollectionResponse.siteNotes;
        } else if (noteCollectionResponse.locationNotes !== undefined) {
            vm.notes = noteCollectionResponse.locationNotes;
        }

        angular.forEach(vm.siteNoteTypes, function(siteNoteType, key) {
            siteNoteType.note = null;
            angular.forEach(vm.notes, function(note) {
                if (siteNoteType._links.self.href === note._links.siteNoteType.href) {
                    siteNoteType.siteNote = note;
                }
            });
        });
    }
})();
