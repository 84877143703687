(function() {
    'use strict';

    angular
        .module('elogbooks.admin.job-groups')
        .controller('JobGroupsController', JobGroupsController);

    JobGroupsController.$inject = ['jobGroupCollectionResponse', 'NestedArrayTransformer', '$window'];

    function JobGroupsController (jobGroupCollectionResponse, NestedArrayTransformer, $window) {
        var vm = this;
            vm.jobGroupCollectionResponse = jobGroupCollectionResponse;
            vm.jobGroups = NestedArrayTransformer.transform(jobGroupCollectionResponse.jobgroups);

            vm.toggle = toggleAction;
            vm.isOpen = isOpen;

        function toggleAction (assetType) {
            var showChildren = JSON.parse($window.localStorage.getItem('admin-jobgroups.' + assetType.id));
            $window.localStorage.setItem('admin-jobgroups.' + assetType.id, JSON.stringify(! showChildren));
        }

        function isOpen (assetType) {
            return JSON.parse($window.localStorage.getItem('admin-jobgroups.' + assetType.id));
        }
    }

})();
