angular.module('elogbooksServices').service('countries', function($http) {

    this.getAll = function () {
        return $http.get('/data/countries.json').then(function(response){
            return response.data;
        });
    }

    return this;
});
