angular.module('elogbooksDirectives').directive('site', ['apiClient', function(apiClient){
    return {
        // restrict to attributes only
        restrict: 'A',
        require: ['ngModel'],
        scope: {
            site: '=ngModel',
            disabled: '=ngDisabled',
            change: '&ngChange'
        },
        templateUrl: '/modules/directives/form-elements/site/site.html',
        link: function (scope) {
            scope.options = {};

            if (typeof scope.change === 'function') {

                scope.$watch('site', function () {
                    scope.change();
                });
            }

            apiClient.get('/sites').then(function (Response) {
                scope.options = Response.getData().sites;
            });
        }
    }
}]);
