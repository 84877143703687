(function () {
    'use strict';

    angular
        .module('elogbooksDirectives')
        .directive('elbFileBlock', ElbFileBlock);

    ElbFileBlock.$inject = [];

    function ElbFileBlock () {
        return {
            restrict: 'AE',
            scope: {
                ngModel: '=',
                disallowClick: '&?',
                tags: '=',
                hideTags: '&?'
            },
            templateUrl: '/modules/directives/elb-files/elb-file-block/elb-file-block.html',
            controller: 'ElbFileBlockController',
            controllerAs: 'vm'
        };
    }
})();
