(function() {
    'use strict';

    angular
        .module('elogbooks.admin.asset-types')
        .controller('AssetTypesController', AssetTypesController);

    AssetTypesController.$inject = ['assetTypeCollectionResponse', 'NestedArrayTransformer', '$window'];

    function AssetTypesController (assetTypeCollectionResponse, NestedArrayTransformer, $window) {
        var vm = this;
            vm.assetTypeCollectionResponse = assetTypeCollectionResponse;
            vm.assetTypes = NestedArrayTransformer.transform(assetTypeCollectionResponse.assettypes);

            vm.toggle = toggleAction;
            vm.isOpen = isOpen;

        function toggleAction (assetType) {
            var showChildren = JSON.parse($window.localStorage.getItem('admin-assettypes.' + assetType.id));
            $window.localStorage.setItem('admin-assettypes.' + assetType.id, JSON.stringify(! showChildren));
        }

        function isOpen (assetType) {
            return JSON.parse($window.localStorage.getItem('admin-assettypes.' + assetType.id));
        }
    }
})();
