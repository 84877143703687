(function () {
    'use strict';

    angular
        .module('elogbooks.user.reports')
        .controller('UserReportsServiceProviderScoreFeedbackController', UserReportsServiceProviderScoreFeedbackController);

    UserReportsServiceProviderScoreFeedbackController.$inject = [
        '$filter',
        '$state',
        '$stateParams',
        'serviceProviderScoreDataResponse',
        'defaultValueResponse',
        'serviceProviderFeedbackScoreResponse',
        'serviceProviderCollection',
        'elbSettings'
    ];

    function UserReportsServiceProviderScoreFeedbackController(
        $filter,
        $state,
        $stateParams,
        serviceProviderScoreDataResponse,
        defaultValueResponse,
        serviceProviderFeedbackScoreResponse,
        serviceProviderCollection,
        elbSettings
    ) {
        var vm = this;

        vm.serviceProviderScoreData = serviceProviderScoreDataResponse.serviceProviderFeedbacks;
        vm.serviceProviderScoreDataResponse = serviceProviderScoreDataResponse;
        vm.scoringRangeDetailsOption = $stateParams.scoringRangeDetailsOption !== null ? $stateParams.scoringRangeDetailsOption : defaultValueResponse.defaultRangeOption;
        vm.serviceProviderFeedbackScoreResponse = serviceProviderFeedbackScoreResponse;
        vm.allowComments = elbSettings.getSetting('sp_feedback_allow_comments').value;
        vm.showSpSortColumn = $stateParams.serviceProvider !== null;

        vm.serviceProviderSelect = {
            response : serviceProviderFeedbackScoreResponse,
            link : serviceProviderFeedbackScoreResponse ? serviceProviderFeedbackScoreResponse.getLink('self') : null,
            responseKeyPath: 'serviceProviderFeedbacks'
        };

        vm.order = orderAction;
        vm.search = search;

        var ratingOptions = [
            {title: $filter('translate')('NONE_SELECTED'), value: 'noneSelected'},
            {title: '1', value: '1'},
            {title: '2', value: '2'},
            {title: '3', value: '3'},
            {title: '4', value: '4'},
            {title: '5', value: '5'}
        ];

        var dateRangeOptions = [
            {title: $filter('translate')('LAST_ONE_MONTH'), value: 1},
            {title: $filter('translate')('LAST_THREE_MONTHS'), value: 3},
            {title: $filter('translate')('LAST_TWELVE_MONTHS'), value: 12},
            {title: $filter('translate')('CUSTOM'), value: 0}
        ];

        var commentOptions = [
            {title: $filter('translate')('NONE_SELECTED'), value: 'noneSelected'},
            {title: $filter('translate')('YES'), value: 'yes'},
            {title: $filter('translate')('NO'), value: 'no'}
        ];

        vm.serviceProviderSelect = {
            response : serviceProviderCollection,
            link : serviceProviderCollection ? serviceProviderCollection.getLink('self') : null,
            itemValuePath: 'id',
            itemTitlePath: 'name',
            responseKeyPath: 'serviceproviders'
        };

        vm.criteria = {
            serviceProvider: {
                type: 'jsonselectwidget',
                title: 'SERVICE_PROVIDER',
                value: JSON.parse($stateParams.serviceProvider), options: vm.serviceProviderSelect,
                valueToShow: 'ALL'
            },

            rating: {
                type: 'options',
                title: 'RATING',
                value: $stateParams.rating,
                options: ratingOptions,
                isClear: $stateParams.rating === 'noneSelected',
                clearValue: 'noneSelected'
            },

            comments: {
                type: 'options',
                title: 'COMMENTS',
                value: $stateParams.comments,
                options: commentOptions,
                isClear: $stateParams.comments === 'noneSelected',
                clearValue: 'noneSelected'
            },

            scoringDateRangeDetails: {
                type: 'date-time-range',
                title: 'BETWEEN_DATES',
                startDateLabel: 'FEEDBACK_AT_RANGE_START_DATE_LABEL',
                endDateLabel: 'FEEDBACK_AT_RANGE_END_DATE_LABEL',
                value: $stateParams.scoringDateRangeDetails ? $stateParams.scoringDateRangeDetails : defaultValueResponse.clearDateRangeValue,
                startDateRequired: true,
                endDateRequired: true,
                options: dateRangeOptions,
                defaultOption: defaultValueResponse.defaultRangeOption,
                optionParam: 'scoringRangeDetailsOption',
                clearValue: defaultValueResponse.clearDateRangeValue,
                isClear: $stateParams.scoringDateRangeDetails === defaultValueResponse.clearDateRangeValue || $stateParams.scoringDateRangeDetails === null
            }
        };

        if (vm.allowComments === false) {
            delete vm.criteria.comments;
        }

        function orderAction(key) {
            $stateParams.detailsOrder = key;

            $state.go('.', $stateParams, { reload: $state.current });
        }

        function search(params) {
            const overrideParams = {
                spFeedbackReportDetailsPage: 1,
            };

            $state.go('.', angular.extend({}, params, overrideParams), {reload: $state.current});
        }
    }
})();