(function() {
    'use strict';

    angular
        .module('elogbooks.admin.vat-rates')
        .config(registerRoutes)
        .config(registerRoutesList)
        .config(registerRoutesAdd)
        .config(registerRoutesDetails)
        .config(registerRoutesEdit);

    function registerRoutes($stateProvider) {
        $stateProvider
            .state('dashboard.admin.vat-rates', {
                abstract: true,
                templateUrl: 'shared-templates/view.html',
                ncyBreadcrumb: {
                    skip: true
                }
            });
    }

    function registerRoutesList($stateProvider) {
        var vatRatesParams = {
            page: '1',
            limit: '30',
            order: 'id',
        };

        $stateProvider
            .state('dashboard.admin.vat-rates.list', {
                url: '/vat-rates?' + Object.keys(vatRatesParams).join('&'),
                parent: 'dashboard.admin.vat-rates',
                views: {
                    '@dashboard.admin.vat-rates': {
                        templateUrl: '/modules/admin/vat-rates/vat-rates-list.html',
                        controller: 'VatRatesAdminController',
                        controllerAs: 'vm'
                    }
                },
                params: vatRatesParams,
                resolve: {
                    vatRatesCollectionResponse: function($stateParams, apiClient, rootResourceResponse) {
                        return apiClient.get(rootResourceResponse.getLink('vat-rates'), $stateParams).then( function(response) {
                            return response || apiClient.noResourceFound();
                        });
                    }
                },
                ncyBreadcrumb: {
                    label: '{{ ::"VAT_RATES_LIST" | translate }}'
                }
            });
    }

    function registerRoutesAdd($stateProvider) {
        $stateProvider
            .state('dashboard.admin.vat-rates.list.add', {
                url: '/add',
                parent: 'dashboard.admin.vat-rates.list',
                views: {
                    '@dashboard.admin.vat-rates': {
                        templateUrl: '/modules/admin/vat-rates/add-edit/vat-rate-form.html',
                        controller: 'VatRateAddEditController',
                        controllerAs: 'vm'
                    }
                },
                resolve: {
                   vatRateResponse: function() {
                        return {};
                    },
                },
                ncyBreadcrumb: {
                    label: '{{ ::"ADD" | translate }}'
                }
            });
    }

    function registerRoutesDetails ($stateProvider) {
        $stateProvider
            .state('dashboard.admin.vat-rates.list.details', {
                url: '/details/{resource}',
                parent: 'dashboard.admin.vat-rates.list',
                abstract: true,
                views: {
                    '@dashboard.admin.vat-rates': {
                        templateUrl: '/modules/admin/vat-rates/details/vat-rate-details.html',
                        controller: 'VatRateDetailsController',
                        controllerAs: 'vm'
                    }
                },
                resolve: {
                    vatRateResponse: function (apiClient, $stateParams) {
                        return apiClient.get($stateParams.resource.decode()).then(function (response) {
                            return response || apiClient.noResourceFound();
                        });
                    }
                },
                ncyBreadcrumb: {
                    label: '{{ ::$resolves().vatRateResponse.name }}'
                }
            })
            .state('dashboard.admin.vat-rates.list.details.info', {
                url: '/info',
                parent: 'dashboard.admin.vat-rates.list.details',
                views: {
                    '@dashboard.admin.vat-rates.list.details': {
                        templateUrl: '/modules/admin/vat-rates/details/info/vat-rate-info.html',
                        controller: 'VatRateInfoController',
                        controllerAs: 'vm'
                    }
                },
                ncyBreadcrumb: {
                    label: '{{ ::"INFO" | translate }}'
                }
            });
    }

    function registerRoutesEdit($stateProvider) {
        $stateProvider
            .state('dashboard.admin.vat-rates.list.details.info.edit', {
                url: '/edit/{resource}',
                parent: 'dashboard.admin.vat-rates.list.details.info',
                views: {
                    '@dashboard.admin.vat-rates': {
                        templateUrl: '/modules/admin/vat-rates/add-edit/vat-rate-form.html',
                        controller: 'VatRateAddEditController',
                        controllerAs: 'vm'
                    }
                },
                ncyBreadcrumb: {
                    label: '{{ ::"EDIT" | translate }}'
                }
            });
    }

})();
