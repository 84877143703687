app.config(function ($translateProvider) {
    $translateProvider.translations('en-gb', {

        PURCHASE_ORDER_CREATED_ACTION: '<strong>{{ event._links["user"].title }}</strong> created the Purchase Order',
        PURCHASE_ORDER_ORDERED_ACTION: '<strong>{{ event._links["user"].title }}</strong> ordered the Purchase Order',
        PURCHASE_ORDER_CANCELLED_ACTION: '<strong>{{ event._links["user"].title }}</strong> cancelled the Purchase Order',
        PURCHASE_ORDER_MARKED_AS_PART_RECEIVED_ACTION: '<strong>{{ event._links["user"].title }}</strong> marked as Part Received the Purchase Order',
        PURCHASE_ORDER_COMPLETED_ACTION: '<strong>{{ event._links["user"].title }}</strong> completed the Purchase Order',
        PURCHASE_ORDER_REOPENED_ACTION: '<strong>{{ event._links["user"].title }}</strong> reopened the Purchase Order',

        PURCHASE_INVOICE_CREATED_ACTION: '<strong>{{ event._links["user"].title }}</strong> created the Purchase Invoice',
        PURCHASE_INVOICE_MARKED_AS_PAID_ACTION: '<strong>{{ event._links["user"].title }}</strong> updated the Purchase Invoice',

        SALES_INVOICE_CREATED_ACTION: '<strong>{{ event._links["user"].title }}</strong> created the Sales Invoice',
        SALES_INVOICE_MARKED_AS_PAID_ACTION: '<strong>{{ event._links["user"].title }}</strong> marked the Sales Invoice as paid',
        SALES_INVOICE_MARKED_AS_INVOICED_ACTION: '<strong>{{ event._links["user"].title }}</strong> invoiced the Sales Invoice',
        SALES_INVOICE_REOPENED_ACTION: '<strong>{{ event._links["user"].title }}</strong> reopened the Sales Invoice',

        INVOICE_CANNOT_BE_REOPENED: 'Cannot reopen the invoice',
        INVOICE_CANNOT_BE_MARKED_AS_INVOICED: 'The Invoice cannot be marked as invoiced',
        INVOICE_CANNOT_BE_MARKED_AS_PAID: 'The Invoice cannot be marked as paid',

        CREDIT_NOTE_CREATED_ACTION: '<strong>{{ event._links["user"].title }}</strong> created the Credit Note',
        CREDIT_NOTE_ISSUED_ACTION: '<strong>{{ event._links["user"].title }}</strong> issued the Credit Note',
        CREDIT_NOTE_REOPENED_ACTION: '<strong>{{ event._links["user"].title }}</strong> reopened the Credit Note',
        CREDIT_NOTE_CANCELLED_ACTION: '<strong>{{ event._links["user"].title }}</strong> cancelled the Credit Note',

        CREDIT_NOTE_CANNOT_BE_MARKED_AS_REOPENED: 'Cannot reopen the Credit Note',
        CREDIT_NOTE_CANNOT_BE_MARKED_AS_ISSUED: 'The Credit Note cannot be marked as issued',
        CREDIT_NOTE_CANNOT_BE_MARKED_AS_CANCELLED: 'The Credit Note cannot be marked as cancelled',
    });
});