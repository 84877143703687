(function () {
    'use strict';

    angular
        .module('elogbooks.admin.job-requisites')
        .controller('JobRequisitesPartnershipsEditController', JobRequisitesPartnershipsEditController);

    JobRequisitesPartnershipsEditController.$inject = ['$state', '$stateParams', 'messenger', 'apiClient', 'lodash', 'jobRequisiteResponse', 'selectedPartnershipCollectionResponse', 'siteCollectionResponse'];

    function JobRequisitesPartnershipsEditController ($state, $stateParams, messenger, apiClient, lodash, jobRequisiteResponse, selectedPartnershipCollectionResponse, siteCollectionResponse) {
        var vm = this;
        vm.jobRequisite = jobRequisiteResponse;
        vm.selectedItems = selectedPartnershipCollectionResponse ? selectedPartnershipCollectionResponse.partnerships : [];
        vm.siteLimitedSelectedItems = [];
        vm.items = siteCollectionResponse.sites;
        vm.serviceProviders = [];
        vm.loadServiceProviders = loadServiceProviders;
        vm.revoke = revokeAction;
        vm.grant = grantAction;
        vm.update = updateAction;
        vm.siteSelect = {
            link: siteCollectionResponse.getLink('self'),
            responseKeyPath: 'sites',
            items: vm.items,
            changeAction: loadServiceProviders,
            searchKey: 'siteName',
        };

        function loadServiceProviders() {
            if (vm.siteSelect.selectModel.length > 0) {
                var params = { serviceProvidersStatus: 'active'};

                apiClient.get(vm.siteSelect.selectModel[0].getLink('partnerships'), params).then(function(response){
                    vm.serviceProviders = [];
                    vm.siteLimitedSelectedItems = [];

                    lodash.each(response.partnerships, function(partnership) {
                        if (lodash.find(vm.selectModel, function(item) {return item._links.self.href == partnership._links.self.href;})) {
                            vm.siteLimitedSelectedItems.push(partnership);
                        } else {
                            vm.serviceProviders.push(partnership);
                        }
                    });
                });

                // Only allow one to be selected
                vm.siteSelect.selectModel = vm.siteSelect.selectModel.slice(0,1);

                //Compare with selected global
                vm.siteLimitedSelectedItems = [];
            }
        }

        function revokeAction(partnership) {
            vm.selectedItems = lodash.difference(vm.selectedItems, [partnership]);
            vm.siteLimitedSelectedItems = lodash.difference(vm.siteLimitedSelectedItems, [partnership]);
            vm.serviceProviders = lodash.union(vm.serviceProviders, [partnership]);
        }

        function grantAction() {
            vm.serviceProviders = lodash.difference(vm.serviceProviders, vm.grantAccess);
            vm.selectedItems = lodash.union(vm.selectedItems, vm.grantAccess);
            vm.siteLimitedSelectedItems = lodash.union(vm.siteLimitedSelectedItems, vm.grantAccess);
        }

        function updateAction() {
            var data = {
                _links: {
                    partnerships: [
                    ]
                }
            };

            lodash.each(vm.selectedItems, function(user){
                data._links.partnerships.push(user._links.self);
            });

            apiClient.create(vm.jobRequisite.getLink('partnerships'), data).then(function(response){
                if (response) {
                    $state.go('^', $stateParams, { reload: true }).then(function () {
                        messenger.success('JOB_REQUISITE_PARTNERSHIP_ADDED');
                    });
                } else {
                    messenger.error('REQUEST_ERROR');
                }
            });
        }
    }

})();
