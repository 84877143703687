(function () {
    'use strict';

    angular
        .module('elogbooks.user.absence-planners')
        .controller('AbsenceEntryController', AbsenceEntryController);

    AbsenceEntryController.$inject = ['absenceEntryResponse'];

    function AbsenceEntryController(absenceEntryResponse) {
        var vm = this;

        vm.absenceEntryResponse = absenceEntryResponse;
    }
})();
