(function(){
    "use strict";

    angular
        .module('elogbooksControllers')
        .controller(
            'UserChangeSiteAccessController',
            function (
                $state,
                messenger,
                apiClient,
                lodash,
                membershipsResponse,
                userResponse,
                $rootScope,
                userCollectionResponse,
                validationService
            ) {

            var vm = this;

            vm.validationService = validationService;

            vm.copyFromUserSelect = {
                response: userCollectionResponse,
                link: userCollectionResponse.getLink('self'),
                responseKeyPath: 'users',
                itemValuePath: 'name'
            };

            vm.copyFromUser = function() {
                apiClient.create(membershipsResponse.getLink('copy-from-user'), {
                    _links: {
                        user: {
                            href: userResponse.getLink('self')
                        },
                        sourceUser: {
                            href: vm.copyFromUserSelect.selected.object.getLink('self')
                        }
                    }
                }).then(function(response) {
                    if (response) {
                        $rootScope.skipDirtyCheck = true;
                        $state.go('dashboard.admin.users.list.details.site-access', {}, {reload: true}).then(function() {
                            $rootScope.skipDirtyCheck = false;
                        });
                    }
                });
            };

            vm.revokeAllSites = function () {
                $rootScope.skipDirtyCheck = true;
                var requestData = {
                    '_links': {
                        user: {href: userResponse.getLink('self')}
                    }
                };

                apiClient
                    .create(membershipsResponse.getLink('delete-memberships'), requestData)
                    .then(function(response) {
                        if (response) {
                            $state.go('dashboard.admin.users.list.details.site-access', {}, {reload: true}).then(function() {
                                $rootScope.skipDirtyCheck = false;
                                messenger.success('SITE_ACCESS_REMOVED');
                            });
                        }
                });

            };

            vm.grantAllActiveSites = function () {

                apiClient.create(membershipsResponse.getLink('create'), {
                    _links: {
                        user: [
                            {
                                href: userResponse.getLink('self')
                            }
                        ]
                    },
                    allSites: true
                }).then(function(response){
                    if (response) {
                        $rootScope.skipDirtyCheck = true;
                        $state.go('dashboard.admin.users.list.details.site-access', {}, {reload: true}).then(function() {
                            $rootScope.skipDirtyCheck = false;
                        });
                    }
                });
            };

    });

})();
