app.config(function($translateProvider) {
    $translateProvider.translations('nl', {
        CHARGE_TYPE: 'Kostensoort',
        CHARGE_TYPES: 'Kostensoorten',
        CHARGE_TYPE_INFORMATION: 'Kostensoort informatie',

        ADD_NEW_CHARGE_TYPE: 'Voeg een nieuwe kostensoort toe',
        EDIT_CHARGE_TYPE: 'Wijzig kostensoort',

        CHARGE_TYPE_LIST: 'Kostensoorten',
        CHARGE_TYPE_ADD: 'Voeg een nieuwe kostensoort toe',
        CHARGE_TYPE_EDIT: 'Wijzig kostensoort',

        CHARGE_TYPE_CREATED: 'Kostensoort gemaakt',
        CHARGE_TYPE_UPDATED: 'Kostensoort gewijzigd',
        CHARGE_TYPE_DELETED: 'Kostensoort verwijderd',

        CHARGE_TYPE_REQUIRED: 'Kostensoort is verplicht'
    });
});