(function () {
    'use strict';

    angular
        .module('elogbooks')
        .filter('objectFilter', ObjectFilter);

    function ObjectFilter () {
        return function (items, properties) {
            var matches = [];

            if (angular.isArray(items)) {
                var propertyKeys = Object.keys(properties);

                items.forEach(function (item) {
                    var itemMatches = false;

                    for (var i = 0; i < propertyKeys.length; i++) {
                        var property = propertyKeys[i],
                            text = properties[property].toLowerCase();

                        if (item[property].toString().toLowerCase().indexOf(text) !== -1) {
                            itemMatches = true;
                            break;
                        }
                    }

                    if (itemMatches) {
                        matches.push(item);
                    }
                });
            } else {
                matches = items;
            }

            return matches;
        };
    }
})();
