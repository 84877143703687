app.config(function ($translateProvider) {
    $translateProvider.translations('nl', {
        AUDIT: 'Inspectie',
        AUDITS: 'Inspecties',
        AUDIT_LIST: 'Lijst met inspecties',
        TEMPLATE_LIST: 'Template lijst',
        TEMPLATE_EDIT: 'Wijzig template',
        SCHEDULE_LIST: 'Planning lijst',

        AUDIT_ADD: 'Voeg een nieuwe inspectie toe',
        TEMPLATE_ADD: 'Maak nieuwe audit template',
        SCHEDULE_ADD: 'Voeg een nieuwe template toe',

        QUESTIONS: 'Vragen',
        QUESTION: 'Vraag',

        AUDIT_SUB_SECTIONS: 'Onderdelen',
        AUDIT_QUESTIONS: 'Vragen',

        START_AUDIT: 'Start inspectie',
        COMPLETE_AUDIT: 'Audit vastleggen',

        AUDIT_CONFIRM_START_INSTRUCTIONS: 'Bevestig start datum',
        NOT_ALL_QUESTIONS_ANSWERED_FORCE_COMPLETE : 'Niet alle antwoorden gegeven',
        AUDIT_FORCE_COMPLETE_REASON: 'Audit force complete reason',

        QUESTIONS_ANSWERED: 'Aantal vragen (Beantwoord)',
        COMPLETION: 'Vastleggen',

        NUMBER_OF_QUESTIONS: 'Aantal vragen',
        ANSWERED_QUESTIONS: 'Vragen beantwoord',

        NUMBER_OF_SECTIONS: 'Aantal onderdelen',
        NUMBER_OF_SUB_SECTIONS: 'Aantal sub-onderdelen',

        DEFAULT_BUTTON_OPTIONS: 'Standaard knop-opties',

        NOT_YET_ANSWERED: 'Niet beantwoord',

        CONFIRM_ANSWER_CHOICE: 'Bevestig antwoord',
        AUDIT_ANSWER_EXTRA_INFORMATION: 'Optionele Extra Informatie',

        AUDIT_ANSWER_OPTIONS_NO_DEFAULT: 'Geen standaard, handmatige setup',
        AUDIT_ANSWER_OPTIONS_NO_YES: 'Nee (0%), Ja (100%)',
        AUDIT_ANSWER_OPTIONS_YES_NO: 'Ja (0%), Nee (100%)',

        SCORE: 'Score',

        AUDIT_CREATED:  'Aangemaakt',
        AUDIT_STARTED: 'In bewerking',
        AUDIT_DRAFT: 'In ontwerp',
        AUDIT_CANCELLED:  'Geannuleerd',
        AUDIT_COMPLETED:  'Afgerond',
        AUDIT_TEMPLATES:  'Sjablonen',
        AUDIT_TEMPLATE:  'Sjabloon',
        AUDIT_TEMPLATE_ACTIVE:  'Active Template',
        AUDIT_TEMPLATE_INACTIVE:  'Inactive Template',
        AUDIT_SCHEDULE_ACTIVE:  'Active Schedule',
        AUDIT_SCHEDULE_CREATED:  'Audit Schedule Created',
        AUDIT_SCHEDULE_INACTIVE:  'Inactive Schedule',
        AUDIT_TEMPLATE_CREATED: 'Audit Template Created',
        AUDIT_TEMPLATE_SAVE: 'Save As Template',

        AUDIT_SCHEDULES: 'Schedules',

        NO_OPERATIVES_FOUND: 'No primary audit operatives found',

        QUESTION_TYPE: 'Vraag Type',
        COMMENT_TYPE: 'Alleen commentaar',
        BUTTONS_TYPE: 'Knop-opties',
        OPTIONS_TYPE: 'Keuzemenu-opties',

        ATTACHMENT_REQUIRED: 'Bijlage verplicht',

        WEIGHTING: 'Weging',

        CREATE_AS_TEMPLATE: 'Voeg toe als sjabloon',
        CREATE_FROM_TEMPLATE: 'Gebruik sjabloon',

        INSTRUCTION: 'Instructie',

        AUDIT_SECTION: 'Onderdeel',

        PDF_REPORT: 'PDF Report',
        ACTIVATE_AUDIT: 'Activeer',
        CANCEL_AUDIT: 'Annuleer',
        AUDIT_SECTIONS: 'Onderdeel',
        AUDIT_EDIT: 'Wijzig inspectie',
        SCHEDULE_EDIT: 'Edit Audit Schedule',
        AUDIT_UPDATED: 'Inspectie gewijzigd',
        AUDIT_DELETED: 'Inspectie verwijderd',
        AUDIT_CONFIRM_ACTIVATE_INSTRUCTIONS: 'Weet u het zeker?',
        AUDIT_CONFIRM_CANCEL_INSTRUCTIONS: 'Voeg hier uw opmerkingen toe',
        AUDIT_CANCEL_REASON: 'Reden van deze annulering',
        ADD_SECTION: 'Voeg onderdeel toe',
        EDIT_SECTION: 'Wijzig onderdeel',
        SECTION: 'onderdeel',
        ADD_SUB_SECTION: 'Voeg sub-onderdeel toe',
        ADD_QUESTION: 'Voeg vraag toe',
        SECTIONS: 'Onderdelen',
        AUDIT_SECTION_UPDATED: 'Inspectie onderdeel gewijzigd',
        AUDIT_UPDATED_TO_CANCELLED: 'Inspectie gewijzigd naar Geannuleerd',

        AUDIT_HAS_NO_ASSIGNED_OPERATIVE: 'Geen inspecteur toegewezen aan inspectie',
        AUDIT_HAS_NO_ASSIGNED_SERVICE_PROVIDER: 'Geen leverancier toegewezen aan inspectie',
        AUDIT_HAS_NO_QUESTIONS: 'Geen vragen in inspectie',
        AUDIT_SECTION_CREATED: 'Inspectie onderdeel aangemaakt',
        AUDIT_SECTION_DELETED: 'Inspectie onderdeel verwijderd',
        AUDIT_QUESTION_CREATED: 'Inspectie vraag aangemaakt',
        AUDIT_QUESTION_UPDATED: 'Inspectie vraag gewijzigd',
        AUDIT_QUESTION_DELETED: 'Inspectie vraag verwijderd',
        AUDIT_UPDATED_TO_ACTIVATED: 'Inspectie aangepast naar in bewerking',
        ADD_AUDIT_QUESTION: 'Voeg vraag toe',
        EDIT_AUDIT_QUESTION: 'Wijzig vraag',
        AUDIT_QUESTION_COMMENT: 'Commentaar',
        AUDIT_QUESTION_OPTIONS: 'Opties',
        AUDIT_QUESTION_BUTTONS: 'Knoppen',
        ANSWER: 'Antwoord',
        ANSWER_OPTIONS: 'Antwoordmogelijkheden',
        POINTS_VALUE: 'Punten',
        
        AUDIT_SECTION_ORDER_UPDATED:'Audit Section Order Updated',

        AUDIT_IMAGE: 'Audit Image',

        CREATE_AS_AUDIT_SCHEDULE: 'Create New Schedule',
        SCHEDULE_START_AT: 'Schedule Start At',

        FORCE_COMPLETE: 'Force Complete',
        AUDIT_COMPLETE_DATE: 'Audit Completed At',

        RESET_SECTIONS: 'Reset Order',
        RESET_ANSWER_OPTIONS_ORDER: 'Reset Options Order',
        ANSWER_OPTION_EDIT: 'Edit Answer Option',
        OUT_OF: '/',
        DATE_STARTED_AT: 'Started At',
        ANSWER_COMMENT: 'Comment',
        REVERT: 'Revert',
        QUESTION_ANSWERED: 'Answer Submitted',
        QUESTION_REVERTED: 'Answer Reverted',
        ANSWER_OPTION: 'Answer Option',
        SELECTED_ANSWER: 'Selected Answer',
        ANSWER_SCORE: 'Answer Score',
        ANSWERED: 'Answered',

        SHOW_ANSWER: 'Show',
        HIDE_ANSWER: 'Hide',

        AUDIT_INSTRUCTION: 'Audit Instruction',

        AUDIT_UPDATED_TO_COMPLETED: 'Audit Completed',
        FORCE_COMPLETED: 'Completed note',

        ADD_ANSWER_OPTION: 'Add answer option',
        AUDIT_ANSWER_OPTION_CREATED: 'Answer option created',
        ANSWER_OPTION_DELETED: 'Answer option deleted',
        ANSWER_OTPION_UPDATED: 'Answer option updated',
        AUDIT_ANSWER_OPTIONS_ORDER_UPDATED: 'Audit answer options order updated',

        AUDIT_UPDATED_TO_STARTED: 'Audit started',

        AUDIT_AT_LEAST_ONE_SECTION_HAS_NO_QUESTIONS: 'Cannot activate audit, at least one section has no questions',
        NOT_ALL_QUESTIONS_ANSWERED: 'Not all audit questions answered',
        NO_PERMISSION_TO_FORCE_COMPLETE: 'Dont have permission to force complete audit',

        EDIT_SUB_SECTION: 'Edit sub section',

        ANSWER_BELOW_100_REQUIRES_PRIORITY: 'Priority required',
        AUDIT_QUESTION_RAISE_ACTION_SELECT_PRIORITY: 'You have selected a sub 100% answer so an action will be raised, select a priority for this action',

        DATE_CANCELLED: 'Date Cancelled',
        CANCEL_REASON: 'Cancel Reason',

        SERVICE_PROVIDER_NO_MATCH: 'There is no match for the service provider and the site you have chosen',

        SITES_REQUIRED: 'Site/sites are required',

        CANNOT_CHANGE_FROM_PUBLIC: 'Cannot change from Public to Private'

    });
});
