(function () {
    'use strict';

    angular
        .module('elogbooks.user.finance.suppliers')
        .controller('FinanceSupplierDetailsController', FinanceSupplierDetailsController);
        
        FinanceSupplierDetailsController.$inject = [
            'supplierResponse'
        ];

        function FinanceSupplierDetailsController(
            supplierResponse
        ) {
            var vm = this;

            vm.supplier = supplierResponse;
        }
})();
