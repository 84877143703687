(function () {
    'use strict';

    angular
        .module('elogbooks.common.quote-requests')
        .controller('CommonQuoteRequestDetailsQuoteDetailsEventFeedController', CommonQuoteRequestDetailsQuoteDetailsEventFeedController);

    CommonQuoteRequestDetailsQuoteDetailsEventFeedController.$inject = ['eventCollectionResponse', 'messageCollectionResponse', 'fileCollectionResponse', 'config', 'quoteResponse'];

    function CommonQuoteRequestDetailsQuoteDetailsEventFeedController (eventCollectionResponse, messageCollectionResponse, fileCollectionResponse, config, quoteResponse) {
        var vm = this;
            vm.feed = [];
            vm.messageCollectionResponse = messageCollectionResponse;
            vm.eventCollectionResponse = eventCollectionResponse;
            vm.fileCollectionResponse = fileCollectionResponse;
            vm.config = config;
            vm.entityResponse = quoteResponse;
    }
})();
