(function () {
    'use strict';

    angular
        .module('elogbooksDirectives')
        .directive('userFilterCriteria', userFilterCriteria);

    function userFilterCriteria () {
        return {
            restrict: 'AE',
            scope: {
                filterUser: '='
            },
            templateUrl: '/modules/directives/saved-filters/user-filter-criteria.html',
            controller: 'userFilterCriteriaController'
        };
    }
})();
