app.config(function ($translateProvider) {
    $translateProvider.translations('nl', {
        OPERATIVE: 'Werkman',
        OPERATIVES: 'Werknemers',

        OPERATIVES_LIST: 'Werknemers',
        OPERATIVES_ADD: 'Voeg een werkman toe',

        REMOVE_OPERATIVE: 'Verwijder werkman',


    });
});
