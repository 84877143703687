(function () {
    'use strict';

    angular
        .module('elogbooks.user.manage-operatives')
        .config(registerRoutes)
        .config(registerRoutesLists)
        .config(registerRoutesTabs);

    function registerRoutes($stateProvider) {
        $stateProvider
            .state('dashboard.user.manage-operatives', {
                url: '/manage-operatives',
                abstract: true,
                templateUrl: '/modules/user/manage-operatives/view.html',
                controller: function ($scope, user) {
                    $scope.user = user;
                },
                ncyBreadcrumb: {
                    label: '{{ ::"MANAGE_OPERATIVES" | translate }}'
                }
            });
    }

    function registerRoutesLists($stateProvider) {
        $stateProvider
            .state('dashboard.user.manage-operatives.service-providers', {
                url: '/service-providers?serviceProviderPage&operativePage&operativeName&operativeUsername&operativeEmail&id&position&changed&serviceProvider',
                parent: 'dashboard.user.manage-operatives',
                views: {
                    '@dashboard.user.manage-operatives': {
                        templateUrl: '/modules/user/manage-operatives/service-providers/view.html',
                        controller: 'UserManageOperativeServiceProvidersController',
                        controllerAs: 'vm'
                    }
                },
                params: {
                    limit: '30',
                    order: 'name',
                    serviceProviderPage: '1',
                    operativePage: '1',
                    operativeName: null,
                    operativeUsername: null,
                    operativeEmail: null,
                    id: null,
                    position: null,
                    changed: null,
                    serviceProvider: null
                },
                resolve: {
                    serviceProviderCollection: function ($stateParams, apiClient, user, selectedSiteResponse) {
                        var params = angular.copy($stateParams);
                        if ($stateParams.serviceProvider) {
                            params.id = JSON.parse($stateParams.serviceProvider).v;
                        }

                        if (selectedSiteResponse) {
                            params.siteId = selectedSiteResponse.id;
                        }

                        params.page = $stateParams.serviceProviderPage;

                        return apiClient.get(user.getLink('serviceproviders'), params).then(function (response) {
                            return response || apiClient.noResourceFound();
                        });
                    },
                    serviceProvidersCollectionResponse: function ($stateParams, apiClient, user, selectedSiteResponse) {
                        var params = {};

                        if (selectedSiteResponse) {
                            params.siteId = selectedSiteResponse.id;
                        }

                        return apiClient.get(user.getLink('serviceproviders'), params).then(function (response) {
                            return response || apiClient.noResourceFound();
                        });

                    },
                    userResponse: function (user) {
                        return user;
                    }
                },
                ncyBreadcrumb: {
                    label: '{{ ::"SERVICE_PROVIDERS" | translate }}'
                }
            })
            .state('dashboard.user.manage-operatives.operatives', {
                url: '/operatives?name&username&email&serviceProvider&serviceProviderPage&operativePage&position&changed',
                parent: 'dashboard.user.manage-operatives',
                views: {
                    '@dashboard.user.manage-operatives': {
                        templateUrl: '/modules/user/manage-operatives/operatives/view.html',
                        controller: 'UserManageOperativeOperativesController',
                        controllerAs: 'vm'
                    }
                },
                params: {
                    serviceProviderPage: '1',
                    operativePage: '1',
                    limit: '30',
                    order: 'name',
                    serviceProvider: null,
                    username: null,
                    email: null,
                    name: null,
                    changed:null,
                    position: null
                },
                resolve: {
                    userResponse: function (user) {
                        return user;
                    },
                    serviceProviderCollectionResponse: function ($stateParams, apiClient, user, selectedSiteResponse) {
                        var params = angular.copy($stateParams);
                        if (selectedSiteResponse) {
                            params.siteId = selectedSiteResponse.id;
                        }

                        return apiClient.get(user.getLink('serviceproviders'), params);
                    },
                    operativeCollectionResponse: function (apiClient, user, rootResourceResponse, serviceProviderCollectionResponse, $stateParams, selectedSiteResponse) {
                        var params = angular.copy($stateParams);

                        params.page = $stateParams.operativePage;

                        if (selectedSiteResponse) {
                            params.siteId = selectedSiteResponse.id;
                        }

                        if (params.serviceProvider) {
                            params.serviceProvider = JSON.parse($stateParams.serviceProvider).v;
                        }

                        if ($stateParams.serviceProvider) {
                            return apiClient.get(user.getLink('serviceproviders'), {id: parseInt(params.serviceProvider)}).then(function (response) {
                                if (response.serviceproviders[0].getLink('operatives')) {
                                    return apiClient.get(response.serviceproviders[0].getLink('operatives'), params).then(function (response) {
                                        return response || apiClient.noResourceFound();
                                    });
                                }
                            });
                        } else {
                            if (rootResourceResponse.getLink('operatives')) {
                                return apiClient.get(rootResourceResponse.getLink('operatives'), params).then(function (response) {
                                    return response || apiClient.noResourceFound();
                                });
                            }
                        }
                    }
                },
                ncyBreadcrumb: {
                    label: '{{ ::"OPERATIVES" | translate }}'
                }

            })
            .state('dashboard.user.manage-operatives.labour', {
                url: '/labour',
                parent: 'dashboard.user.manage-operatives',
                views: {
                    '@dashboard.user.manage-operatives': {
                        templateUrl: '/modules/user/manage-operatives/labour/view.html',
                        controller: 'UserManageOperativeLabourController',
                        controllerAs: 'vm'
                    }
                },
                params: {
                    order: 'site',
                    serviceProvider: null,
                    operativePage: '1',
                    labourLinePage: '1',
                    labourLineLimit: '30',
                    operative: null,
                    'siteIds': [],
                    dateRange: moment(new Date()).add(-30, 'day').format() +','+ moment(new Date()).format()
                },
                resolve: {
                    jobLabourLinesCollectionResponse: function(apiClient, user, $stateParams, selectedSiteResponse) {
                        var params = angular.copy($stateParams)

                        params.page = $stateParams.labourLinePage;
                        params.limit = $stateParams.labourLineLimit;
                        params.order = $stateParams.order;
                        params['siteIds[]'] = $stateParams.siteIds;

                        if (selectedSiteResponse) {
                            params['siteIds[]'] = selectedSiteResponse.id;
                        }

                        if (params.serviceProvider) {
                            params.serviceProvider = JSON.parse($stateParams.serviceProvider).v;
                        }

                        if (params.operative) {
                            params.operative = JSON.parse($stateParams.operative).v;
                        }

                        return apiClient.get(user.getLink('labours'), params).then(function(response) {
                            if (response) {
                                return response || apiClient.noResourceFound();
                            }
                        });
                    },
                    serviceProviderCollectionResponse: function ($stateParams, apiClient, user, selectedSiteResponse) {
                        var params = angular.copy($stateParams);
                        params.order = 'name';

                        if (selectedSiteResponse) {
                            params.siteId = selectedSiteResponse.id;
                        }

                        return apiClient.get(user.getLink('serviceproviders'), params);
                    },
                    operativeCollectionResponse: function (apiClient, user, rootResourceResponse, serviceProviderCollectionResponse, $stateParams, selectedSiteResponse) {
                        var params = angular.copy($stateParams);

                        params.page = $stateParams.operativePage;
                        params.permission = 'operative_permission_receive_jobs';
                        params.order = 'name';

                        if (selectedSiteResponse) {
                            params.siteId = selectedSiteResponse.id;
                        }

                        if (params.serviceProvider) {
                            params.serviceProvider = JSON.parse($stateParams.serviceProvider).v;
                        }

                        if ($stateParams.serviceProvider) {
                            return apiClient.get(user.getLink('serviceproviders'), {id: parseInt(params.serviceProvider)}).then(function (response) {
                                if (response.serviceproviders[0].getLink('operatives')) {
                                    return apiClient.get(response.serviceproviders[0].getLink('operatives'), params).then(function (response) {
                                        return response || apiClient.noResourceFound();
                                    });
                                }
                            });
                        } else {
                            if (rootResourceResponse.getLink('operatives')) {
                                return apiClient.get(rootResourceResponse.getLink('operatives'), params).then(function (response) {
                                    return response || apiClient.noResourceFound();
                                });
                            }
                        }
                    },
                    siteCollectionResponse : function (apiClient, rootResourceResponse, user) {
                        return apiClient.get(rootResourceResponse.getLink('sites') || user.getLink('sites'), {order: ['name']})
                            .then(function (response) {
                                return response || apiClient.noResourceFound();
                            });
                    },
                    selectedSiteResponse: function (selectedSiteResponse) {
                        return selectedSiteResponse;
                    }
                },
                ncyBreadcrumb: {
                    label: '{{ ::"LABOUR" | translate }}'
                }
            })
        ;
    }
    function registerRoutesTabs($stateProvider) {
        $stateProvider
            .state('dashboard.user.manage-operatives.operatives.details', {
                url: '/{resource}',
                abstract: true,
                parent: 'dashboard.user.manage-operatives.operatives',
                views: {
                    '@dashboard.user.manage-operatives': {
                        templateUrl: '/modules/user/manage-operatives/operatives/details/details.html',
                        controller: 'UserManageOperativeDetailsController',
                        controllerAs: 'vm'
                    }
                },
                resolve: {
                    operativeResponse: function (apiClient, base64, $stateParams) {
                        return apiClient.get(base64.decode($stateParams.resource)).then(function (response) {
                            return response || apiClient.noResourceFound();
                        });
                    }
                },
                ncyBreadcrumb: {
                    label: '{{ ::$resolves().operativeResponse.name }}'
                }
            })
            .state('dashboard.user.manage-operatives.operatives.details.info', {
                url: '/info',
                parent: 'dashboard.user.manage-operatives.operatives.details',
                views: {
                    '@dashboard.user.manage-operatives.operatives.details': {
                        templateUrl: '/modules/user/manage-operatives/operatives/info/info.html',
                        controller: 'ManageOperativeInfoController',
                        controllerAs: 'vm'
                    }
                },
                ncyBreadcrumb: {
                    label: '{{ ::"INFO" | translate }}'
                }
            })
            .state('dashboard.user.manage-operatives.operatives.details.service-providers', {
                url: '/service-providers/{changed}?page&limit&sitesPage',
                parent: 'dashboard.user.manage-operatives.operatives.details',
                views: {
                    '@dashboard.user.manage-operatives.operatives.details': {
                        templateUrl: '/modules/user/manage-operatives/operatives/service-providers/service-providers.list.html',
                        controller: 'ManageOperativeServiceProvidersController',
                        controllerAs: 'vm'
                    }
                },
                params: {
                    page: '1',
                    limit: '30',
                    order: 'id',
                    sitesPage: '1'
                },
                resolve: {
                    serviceProviderCollection: function(apiClient, userResponse, operativeResponse, $stateParams) {
                        var params = angular.copy($stateParams);
                        delete params.name;
                        if ($stateParams.changed) {
                            delete params.position;
                        }
                        return apiClient.get(userResponse.getLink('serviceproviders'), angular.extend({}, params, {operativeId:operativeResponse.id})).then(function (response) {
                            return response;
                        });
                    }
                },
                ncyBreadcrumb: {
                    label: '{{ ::"SERVICE_PROVIDERS" | translate }}'
                }
            })
            .state('dashboard.user.manage-operatives.operatives.details.service-types', {
                url: '/service-types?serviceTypePage&serviceTypeLimit&serviceTypeOrder',
                parent: 'dashboard.user.manage-operatives.operatives.details',
                params: {
                    serviceTypePage: '1',
                    serviceTypeLimit: '30',
                    serviceTypeOrder: 'id'
                },
                views: {
                    '@dashboard.user.manage-operatives.operatives.details': {
                        templateUrl: '/modules/common/service-types/list/service-types.html',
                        controller: 'CommonServiceTypesController',
                        controllerAs: 'vm'
                    }
                },
                resolve: {
                    serviceTypesCollectionResponse: function (apiClient, operativeResponse, $stateParams) {
                        return apiClient.get(operativeResponse.getLink('servicetypes'), angular.extend({}, $stateParams, {
                            page: $stateParams.serviceTypePage,
                            limit: $stateParams.serviceTypeLimit,
                            order: $stateParams.serviceTypeOrder,
                        })).then(function(response) {
                            return response || apiClient.noResourceFound();
                        });
                    },
                    config: function() {
                        return {
                            noBorder: true
                        };
                    }
                },
                ncyBreadcrumb: {
                    label: '{{ ::"SERVICE_TYPES" | translate }}'
                }
            })
            .state('dashboard.user.manage-operatives.operatives.details.service-types.add', {
                url: '/add',
                parent: 'dashboard.user.manage-operatives.operatives.details.service-types',
                views: {
                    '@dashboard.user.manage-operatives.operatives.details': {
                        templateUrl: '/modules/common/service-types/add/service-type-add.html',
                        controller: 'CommonServiceTypeAddController',
                        controllerAs: 'vm'
                    }
                },
                resolve: {
                    serviceTypesRootResponse: function($stateParams, apiClient, rootResourceResponse) {
                        return apiClient.get(rootResourceResponse.getLink('servicetypes'), $stateParams).then(function(response) {
                            return response || apiClient.noResourceFound();
                        });
                    }
                },
                ncyBreadcrumb: {
                    label: '{{ ::"SERVICE_TYPE_ADD" | translate }}'
                }
            })
            .state('dashboard.user.manage-operatives.operatives.details.activity-log', {
                url: '/activity-log?dateRange&operative&activityOrder&activityLimit&activityType&activityId',
                parent: 'dashboard.user.manage-operatives.operatives.details',
                views: {
                    '@dashboard.user.manage-operatives.operatives.details': {
                        templateUrl: '/modules/user/manage-operatives/operatives/activity-log/view.html',
                        controller: 'ManageOperativeActivityLogController',
                        controllerAs: 'vm'
                    }
                },
                params: {
                    activityOrder: 'created_at',
                    dateRange: moment().subtract(1, 'year').startOf('day').format()+','+moment().endOf('day').format(),
                    operative: null,
                    activityLimit: '30',
                    activityType: null,
                    activityId: null
                },
                resolve: {
                    operativeActivityResponse: function($stateParams, apiClient, operativeResponse) {
                        return apiClient.get(operativeResponse.getLink('activity'), {
                            createdAtBetween: $stateParams.dateRange,
                            operative: $stateParams.operative ? JSON.parse($stateParams.operative).v : null,
                            order: $stateParams.activityOrder,
                            limit: $stateParams.activityLimit,
                            type: $stateParams.activityType,
                            entityId: $stateParams.activityId
                        });
                    },
                    serviceProviderCollection: function(apiClient, operativeResponse) {
                        return apiClient.get(operativeResponse.getLink('operatives'));
                    }
                },
                ncyBreadcrumb: {
                    label: '{{ ::"ACTIVITY_VIEW" | translate }}'
                }
            })
            .state('dashboard.user.manage-operatives.labour.edit', {
                url: '/{jobLabourResource}/edit',
                parent: 'dashboard.user.manage-operatives.labour',
                views: {
                    '@dashboard.user.manage-operatives': {
                        templateUrl: '/modules/common/labour-lines/add-edit/labour-lines-add-edit.html',
                        controller: 'CommonLabourLinesAddEditController',
                        controllerAs: 'vm'
                    }
                },
                resolve: {
                    userResponse: function(user) {
                        return user;
                    },
                    jobLabourResponse: function(apiClient, $stateParams) {
                        return apiClient.get($stateParams.jobLabourResource.decode()).then(function(response) {
                            return response || apiClient.noResourceFound();
                        });
                    },
                    serviceProviderResponse: function(apiClient, jobLabourResponse) {
                        return apiClient.get(jobLabourResponse.getLink('serviceprovider'), {datagroup: 'details'}).then(function(response) {
                            return response || apiClient.noResourceFound();
                        });
                    },
                    operativeCollectionResponse: function(apiClient, serviceProviderResponse, userResponse) {
                        if (serviceProviderResponse.isShared) {
                            if (userResponse.getLink('operatives')) {
                                return apiClient
                                    .get(userResponse.getLink('operatives'), { serviceProviderId: serviceProviderResponse.id, permission: 'operative_permission_receive_jobs' })
                                    .then(function(response) {
                                        return response || apiClient.noResourceFound();
                                    });
                            }
                        } else if (serviceProviderResponse.getLink('joboperatives')) {
                            return apiClient.get(serviceProviderResponse.getLink('joboperatives')).then(function (response) {
                                return response || apiClient.noResourceFound();
                            });
                        }

                        return [];
                    }
                },
                ncyBreadcrumb: {
                    label: '{{ ::"EDIT" | translate }}'
                }
            })
        ;
    }
})();
