app.config(function ($translateProvider) {
    $translateProvider.translations('en-gb', {
        REGION: 'Region',
        REGIONS: 'Regions',

        REGION_INFORMATION: 'Region Information',
        MANAGE_REGION_SITES: 'Sites in this Region',

        ADD_NEW_REGION: 'Add a new Region',
        EDIT_REGION: 'Edit Region',

        REGION_LIST: 'Regions',
        REGION_ADD: 'Add a new Region',
        REGION_EDIT: 'Edit Region',

        REGION_CREATED: 'Region Created',
        REGION_UPDATED: 'Region Updated',
        REGION_DELETED: 'Region Deleted',
        REGION_SITE_ADDED : 'Region Sites Updated',

        REGION_SITE_LIST: 'Sites',
        SITES_DELETED_FROM_REGION: 'Sites Deleted From Region'
    });
});
