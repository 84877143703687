(function () {
    'use strict';

    angular
        .module('elogbooks.user.reports')
        .controller('UserReportsKpiMonthlyDetailsController', UserReportsKpiMonthlyDetailsController);

    UserReportsKpiMonthlyDetailsController.$inject = [
        '$scope',
        '$timeout',
        '$filter',
        '$state',
        '$window',
        '$stateParams',
        '$translate',
        'apiClient',
        'lodash',
        'moment',
        'coreClientCollectionResponse',
        'serviceProviderGroupCollectionResponse',
        'serviceProviderCollectionResponse',
        'regionCollectionResponse',
        'surveysCollectionResponse',
        'reportDataResponse',
        'reportTotalsService',
        '$q',
        'paramConverterService',
        'siteCollectionResponse',
        'versionsResponse'
    ];

    function UserReportsKpiMonthlyDetailsController(
        $scope,
        $timeout,
        $filter,
        $state,
        $window,
        $stateParams,
        $translate,
        apiClient,
        lodash,
        moment,
        coreClientCollectionResponse,
        serviceProviderGroupCollectionResponse,
        serviceProviderCollectionResponse,
        regionCollectionResponse,
        surveysCollectionResponse,
        reportDataResponse,
        reportTotalsService,
        $q,
        paramConverterService,
        siteCollectionResponse,
        versionsResponse
    ) {
        var vm = this;
        var isLatestSurvey = null;
        vm.stateParams = $stateParams;
        vm.months = [];
        vm.avgTotal = '-';
        vm.rangeWarning = false;
        vm.versionWarning = false;
        vm.selectSurvey = !$stateParams.survey || false;
        vm.reportResponse = reportDataResponse;
        vm.reportData = reportDataResponse.surveyLines;
        vm.statData = reportDataResponse.stats;
        vm.search = search;

        var versions = [{
            title: $filter('translate')('NONE_SELECTED'),
            value: null
        }];
        if (versionsResponse && versionsResponse.surveys && versionsResponse.surveys.length) {
            isLatestSurvey = lodash.find(versionsResponse.surveys, {isLatest: true});

            versionsResponse.surveys.forEach(function(value) {
                versions.push({
                    title: value.version,
                    value: value.version
                });
            });
        }

        // Make sure max date range is 12 months
        if ($stateParams.dateRange !== null && typeof $stateParams.dateRange === 'string'
            && moment(new Date($stateParams.dateRange.split(',')[1])) > moment(new Date($stateParams.dateRange.split(',')[0])).add(11, 'month').endOf('month').add(1, 'day')) {
            vm.rangeWarning = true;
        } else if ($stateParams.dateRange !== null && typeof $stateParams.dateRange === 'string'
            && moment(new Date($stateParams.dateRange.split(',')[1])) < moment(new Date($stateParams.dateRange.split(',')[0]))) {
            $stateParams.dateRange = moment().subtract(1, 'month').startOf('month').startOf('day').format()+','+moment().subtract(1, 'month').startOf('month').add(11, 'month').endOf('month').endOf('day').format();
        }

        vm.startDate = ($stateParams.dateRange !== null && typeof $stateParams.dateRange === 'string') ? moment(new Date($stateParams.dateRange.split(',')[0])) : null;
        vm.endDate = ($stateParams.dateRange !== null && typeof $stateParams.dateRange === 'string') ? moment(new Date($stateParams.dateRange.split(',')[1])) : null;

        calculateMonthsBetween(vm.startDate, vm.endDate);
        calculateAvg();
        calculateAutomatedStatsAvg();

        // Filters
        vm.siteSelect = {
            response : siteCollectionResponse,
            link : siteCollectionResponse && siteCollectionResponse.count > 0 ? siteCollectionResponse.getLink('self') : null,
            responseKeyPath: 'sites',
            itemValuePath: 'id',
            itemTitlePath: 'name'
        };

        vm.surveySelect = {
            response : surveysCollectionResponse,
            link : surveysCollectionResponse ? surveysCollectionResponse.getLink('self') : null,
            responseKeyPath: 'surveys',
            itemValuePath: 'root',
            itemTitlePath: 'name'
        };
        vm.serviceProviderSelect = {
            response : serviceProviderCollectionResponse,
            link : serviceProviderCollectionResponse ? serviceProviderCollectionResponse.getLink('self') : null,
            responseKeyPath: 'serviceproviders',
            itemValuePath: 'id',
            itemTitlePath: 'name'
        };
        vm.coreClientSelect = {
            response : coreClientCollectionResponse,
            link : coreClientCollectionResponse ? coreClientCollectionResponse.getLink('self') : null,
            responseKeyPath: 'coreClients',
            itemValuePath: 'id',
            itemTitlePath: 'name'
        };
        vm.serviceProviderGroupSelect = {
            response: serviceProviderGroupCollectionResponse,
            link: serviceProviderGroupCollectionResponse.getLink('self'),
            responseKeyPath: 'groups',
            itemValuePath:'name',
            itemHrefPath:'id'
        };
        vm.regionSelect = {
            response: regionCollectionResponse,
            link: regionCollectionResponse.getLink('self'),
            responseKeyPath: 'regions',
            itemValuePath:'name',
            itemHrefPath:'id'
        };
        vm.versionSelect = {
            response : versionsResponse,
            link : versionsResponse && versionsResponse.surveys ? versionsResponse.getLink('self') : null,
            responseKeyPath: 'versions',
            itemValuePath: 'id',
            itemTitlePath: 'id'
        };

        if ($stateParams.survey !== null) {
            vm.versionWarning = true;
        }

        // Clean params for export
        vm.cleanParams = {
            'serviceProviderGroups[]': $stateParams.serviceProviderGroups ? $stateParams.serviceProviderGroups : null,
            'siteRegions[]': $stateParams.siteRegions ? $stateParams.siteRegions : null,
        };
        vm.cleanParams.dateRange = $stateParams.dateRange;
        vm.cleanParams.coreClient = $stateParams.coreClient ? JSON.parse($stateParams.coreClient).v : null;
        vm.cleanParams.site = $stateParams.site ? JSON.parse($stateParams.site).v : null;
        vm.cleanParams.siteGroup = $stateParams.siteGroup ? JSON.parse($stateParams.siteGroup).v : null;
        vm.cleanParams.serviceProvider = $stateParams.serviceProvider ? JSON.parse($stateParams.serviceProvider).v : null;
        vm.cleanParams.survey = $stateParams.survey ? JSON.parse($stateParams.survey).v : null;
        vm.cleanParams.version = $stateParams.version ? parseInt($stateParams.version, 10) : null;
        vm.cleanParams.page = $stateParams.monthlyPage;
        vm.cleanParams.limit = $stateParams.monthlyLimit;

        var params = angular.copy(vm.cleanParams);

        if (reportTotalsService.isCachedDataValid(params)) {
            vm.avg = reportTotalsService.getCachedTotals();
            if (vm.avg) {
                calculateAvgTotal(vm.avg);
            }
        } else {
            $q.all([reportTotalsService.getTotalsData(params, '/reports/kpi-monthly-average').then(function(response) {
                vm.avg = response;
                if (vm.avg) {
                    calculateAvgTotal(vm.avg);
                }
            })]);
        }

        function calculateMonthsBetween(startDate, endDate) {
            var date = moment(startDate).startOf('month');
            var months = [];

            while (date < endDate && months.length < 12) {
                months.push({'title': date.format('MMM').toUpperCase(), 'key': date.format('YYYYMM').toUpperCase(), 'total': null, 'questionQty': 0});
                date.add(1, 'month');
            }

            vm.months = months;
        }

        function getServiceProviderGroups() {
            return paramConverterService.formatResponse(serviceProviderGroupCollectionResponse.groups);
        }

        function getSiteRegions() {
            return paramConverterService.formatResponse(regionCollectionResponse.regions);
        }

        vm.searchCriteria = {
            survey: {
                type: 'jsonselectwidget',
                title: 'SURVEY',
                value: JSON.parse($stateParams.survey),
                options: vm.surveySelect
            },
            dateRange: {
                type: 'date-range',
                title: 'BETWEEN_DATES',
                value: {
                    startDate:  vm.startDate,
                    endDate: vm.endDate
                },
                clearValue: {
                    startDate: null,
                    endDate: null
                }
            },
            site: {
                type: 'jsonselectwidget',
                title: 'SITE',
                value: JSON.parse($stateParams.site), options: vm.siteSelect
            },
            serviceProviderGroups: {
                type: 'autocompletemulti',
                multiple: true,
                title: 'SERVICE_PROVIDER_GROUP',
                value: $stateParams.serviceProviderGroups,
                options: vm.serviceProviderGroupSelect,
                param: 'serviceProviderGroups'
            },
            serviceProvider: {
                type: 'jsonselectwidget',
                title: 'SERVICEPROVIDER',
                value: JSON.parse($stateParams.serviceProvider),
                options: vm.serviceProviderSelect
            },
            siteRegions: {
                type: 'autocompletemulti',
                title: 'REGION',
                multiple: true,
                value: $stateParams.siteRegions,
                options: vm.regionSelect,
                param: 'siteRegions'
            },
            version: {
                type: 'options',
                title: 'VERSION',
                value: checkVersion(),
                options: versions
            }
        };

        function checkVersion() {
            if (versionsResponse === null ||
                versionsResponse && versionsResponse.surveys.length === 0 ||
                isLatestSurvey && !isLatestSurvey.version && !$stateParams.version
            ) {
                return null;
            }

            if ($stateParams.version && isLatestSurvey && isLatestSurvey.version) {
                return Number($stateParams.version);
            }

            return $stateParams.version ? Number($stateParams.version) : isLatestSurvey && isLatestSurvey.version ? isLatestSurvey.version : null;
        }

        if (coreClientCollectionResponse.count) {
            vm.coreClientSelect = {
                response : coreClientCollectionResponse,
                link : coreClientCollectionResponse ? coreClientCollectionResponse.getLink('self') : null,
                responseKeyPath: 'coreclients',
                itemValuePath: 'id',
                itemTitlePath: 'name'
            };

            vm.searchCriteria.coreClient = { type: 'jsonselectwidget', title: 'CORE_CLIENT', value: JSON.parse($stateParams.coreClient), options: vm.coreClientSelect };
        }

        function search(params) {
            var override = {
                page: 1,
                monthlyPage: 1
            };

            $state.go('.', angular.extend($stateParams, params, override), {reload: $state.current.name});
        }

        function calculateAvgTotal(obj) {
            var values = [];

            Object.keys(obj).map(function (key) {
                if (obj[key] >= 0 && obj[key] !== '') {
                    values.push(obj[key]);
                }
            });

            if (values.length > 0) {
                vm.avgTotal = Math.round(values.reduce(function (acc, val) {
                    return acc + val;
                }, 0) / values.length) + '%';
            }
        }

        function calculateAvg() {
            lodash.each(vm.reportData, function (question){
                var divisor = 0;
                var avg = '-';
                question.avg = '-';

                lodash.forIn(question.data, function(value, key) {
                    if (!isNaN(value.score)) {
                        divisor++;
                        if (!isNaN(avg)) {
                            avg += value.score;
                        } else {
                            avg = value.score
                        }
                    }
                });

                if (!isNaN(avg)) {
                    question.avg = Math.round(avg / divisor);
                }
                divisor =  0;
                avg = '-';
            });
        }

        function calculateAutomatedStatsAvg() {
            lodash.each(vm.statData, function (stat){
                var divisor = 0;
                var avg = '-';
                stat.avg = '-';

                lodash.forIn(stat, function(value) {
                    if (!isNaN(value)) {
                        divisor++;
                        if (!isNaN(avg)) {
                            avg += value;
                        } else {
                            avg = value;
                        }
                    }
                });

                if (!isNaN(avg)) {
                    stat.avg = Math.round(avg / divisor);
                }
                divisor =  0;
                avg = '-';
            });
        }
    }
})();


