(function() {
    'use strict';

    angular.module('elogbooks.admin', [
        'elogbooks.admin.settings',
        'elogbooks.admin.asset-types',
        'elogbooks.admin.asset-score-types',
        'elogbooks.admin.action-types',
        'elogbooks.admin.charge-types',
        'elogbooks.admin.core-clients',
        'elogbooks.admin.sites',
        'elogbooks.admin.sitegroups',
        'elogbooks.admin.site-associate-types',
        'elogbooks.admin.sectors',
        'elogbooks.admin.regions',
        'elogbooks.admin.users',
        'elogbooks.admin.departments',
        'elogbooks.admin.job-groups',
        'elogbooks.admin.service-providers',
        'elogbooks.admin.service-provider-groups',
        'elogbooks.admin.serviceroutines',
        // 'elogbooks.admin.settings',
        'elogbooks.admin.trade-types',
        'elogbooks.admin.contract-types',
        'elogbooks.admin.roles',
        'elogbooks.admin.surveys',
        'elogbooks.admin.job-requisites',
        'elogbooks.admin.satisfactionsurveylines',
        'elogbooks.admin.site-note-types',
        'elogbooks.admin.priorities',
        'elogbooks.admin.service-types',
        'elogbooks.common.service-types',
        'elogbooks.admin.site-relationships',
        'elogbooks.admin.imports',
        'elogbooks.admin.pcd-imports',
        'elogbooks.admin.scd-imports',
        'elogbooks.admin.billing-types',
        'elogbooks.admin.spillage-types',
        'elogbooks.admin.timesheet-types',
        'elogbooks.admin.missed-patrol-reasons',
        'elogbooks.admin.forms',
        'elogbooks.admin.waste-management',
        'elogbooks.admin.sso-users',
        'elogbooks.admin.request-logs',
        'elogbooks.admin.document-types-management',
        'elogbooks.admin.labour-types',
        'elogbooks.admin.pricing-schedules',
        'elogbooks.admin.vat-rates',
        'elogbooks.admin.client-billing'
    ]);
})();
