(function() {
    'use strict';

    angular
        .module('elogbooks.admin.pricing-schedules')
        .controller('PricingSchedulesAdminController', PricingSchedulesAdminController);

    PricingSchedulesAdminController.$inject = [
        '$state',
        '$stateParams',
        'pricingSchedulesCollectionResponse',
        '$translate'
    ];

    function PricingSchedulesAdminController(
        $state,
        $stateParams,
        pricingSchedulesCollectionResponse,
        $translate
    ) {
        var vm = this;
        vm.pricingSchedules = pricingSchedulesCollectionResponse.pricingSchedules;
        vm.pricingSchedulesCollectionResponse = pricingSchedulesCollectionResponse;

        var notSelected = {
            title: $translate.instant('NONE_SELECTED'),
            value: null
        }

        var statusOptions = [
            notSelected,
            { title: $translate.instant('STATUS_ACTIVE'), value: 'active' },
            { title: $translate.instant('STATUS_INACTIVE'), value: 'inactive' },
            { title: $translate.instant('STATUS_DRAFT'), value: 'draft' }
        ];

        var defaultOptions = [
            notSelected,
            { title: $translate.instant('YES'), value: 'true' },
            { title: $translate.instant('NO'), value: 'false' }
        ];


        vm.criteria = {
            summary: { type: 'text', title: 'SUMMARY', value: $stateParams.summary },
            status: { type: 'options', title: 'STATUS', value: $stateParams.status, options: statusOptions },
            createdAtRange: {
                type: 'date-range',
                title: 'CREATED_BETWEEN',
                value: {
                    startDate: ($stateParams.createdAtRange !== null && typeof $stateParams.createdAtRange === 'string') ?
                        moment(new Date($stateParams.createdAtRange.split(',')[0])) :
                        null,
                    endDate: ($stateParams.createdAtRange !== null && typeof $stateParams.createdAtRange === 'string') ?
                        moment(new Date($stateParams.createdAtRange.split(',')[1])) :
                        null,
                    endMaxDate: moment()
                },
                clearValue: {
                    startDate: null,
                    endDate: null
                }
            },
            isDefault: { type: 'options', title: 'DEFAULT', value: $stateParams.isDefault, options: defaultOptions }
        };

        vm.search = searchAction;
        vm.order = order;

        function searchAction(params) {
            var override = {
                page: 1
            };

            $state.go('.', angular.extend({}, params, override), { reload: $state.current });
        }

        function order(key) {
            $stateParams.order = key;

            $state.go('.', angular.extend({}, $stateParams), {reload: $state.current});
        };
    }
})();
