/**
 * Trade Types list controller
 */
 (function() {
     'use strict';

     angular
        .module('elogbooks.admin.trade-types')
        .controller('TradeTypesController', TradeTypesController);

    TradeTypesController.$inject = ['$state', '$stateParams', 'tradeTypeCollectionResponse', 'lodash'];

    function TradeTypesController($state, $stateParams, tradeTypeCollectionResponse, lodash) {

        var vm = this;

        vm.tradeTypeCollectionResponse = tradeTypeCollectionResponse;
        vm.tradetypes = tradeTypeCollectionResponse.getData().tradetypes;
        vm.pages = tradeTypeCollectionResponse.getData().pages;
        vm.listVisible = true;

        vm.criteria = {
            id: { type: 'number', title: 'ID', value: $stateParams.id ? parseInt($stateParams.id, 10) : null },
            name: { type: 'text', title: 'NAME', value: $stateParams.name },
            description: { type: 'text', title: 'DESCRIPTION', value: $stateParams.description }
        };

        vm.search = function() {
            var params   = lodash.zipObject(lodash.keys(vm.criteria), lodash.map(vm.criteria, 'value'));
            var override = {
                page:       1
            };

            $state.go('.', angular.extend({}, params, override), { reload: true });
        };
    };

 })();
