(function() {
    'use strict';

    angular
        .module('elogbooks.user.service-providers')
        .controller('ServiceProviderPartnershipsAddController', ServiceProviderPartnershipsAddController);

    ServiceProviderPartnershipsAddController.$inject = ['$stateParams', '$state', 'messenger', 'apiClient', 'serviceProviderResponse', 'rootResourceResponse'];

    function ServiceProviderPartnershipsAddController($stateParams, $state, messenger, apiClient, serviceProviderResponse, rootResourceResponse) {
        var vm = this;
        vm.serviceProvider = serviceProviderResponse;
        vm.siteSelect = {
            link : rootResourceResponse ? rootResourceResponse.getLink('sites') : '',
            responseKeyPath: 'sites',
            searchKey: 'siteName',
            required: true
        };
        vm.create = createAction;
        vm.disableSubmit = false;

        function createAction() {
            if (!vm.siteSelect.selectedItems.length) {
                return;
            }

            var data = {
                _links: {}
            };

            if (vm.siteSelect.selectedItems.length) {
                data._links.site = vm.siteSelect.selectedItems;
            }
            vm.disableSubmit = true;
            apiClient.create(vm.serviceProvider.getLink('partnerships'), data).then(function(response) {
                if (response) {
                    $state.go('^', $stateParams, {reload: true}).then(function() {
                        messenger.success('SERVICE_PROVIDER_PARTNERSHIP_ADDED');
                    });
                } else {
                    vm.disableSubmit = false;
                    messenger.error('REQUEST_ERROR');
                }
            });
        };
    }
})();
