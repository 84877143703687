(function () {
    'use strict';

    angular
        .module('elogbooks.common.jobs')
        .controller('CommonLinksToController', CommonLinksToController);

    CommonLinksToController.$inject = [
        '$scope',
        'confirmationModal',
        '$rootScope',
        '$translate',
        '$stateParams',
        'siteResponse',
        'jobResponse',
        'apiClient',
        '$state',
        'messenger'
    ];

    function CommonLinksToController(
        $scope,
        confirmationModal,
        $rootScope,
        $translate,
        $stateParams,
        siteResponse,
        jobResponse,
        apiClient,
        $state,
        messenger
    ) {
        var vm = this;
        vm.linkType = 'job';
        vm.id = null;
        vm.relation = null;
        vm.site = siteResponse;
        vm.job = jobResponse;
        vm.loading = true;
        vm.jobQuoteLinksCollection = [];
        vm.jobLinksCollection = [];
        vm.jobActionLinksCollection = [];

        if ($scope.jobLinksCollection) {
            loadLinkCollections();
        }

        var jobCollectionWatch = $scope.$watch('jobLinksCollection', function(value) {
            if (value) {
                loadLinkCollections();

                //de-register listener
                jobCollectionWatch();
            }
        });

        vm.relationOptions = [
            {name: $translate.instant('JOB_LINK_WITH'), value: 2},
            {name: $translate.instant('JOB_LINK_FROM'), value: 1},
            {name: $translate.instant('JOB_LINK_TO'), value: 0}
        ];

        var params = {
            type: null,
            relation: null,
            sourceId: null,
            targetId: null
        };

        vm.delete = deleteAction;
        vm.add = addAction;
        vm.setRelationOptions = setRelationOptions;

        function loadLinkCollections() {
            const filterLinksByType = (links, type) => links.filter(link => link.type === type);
            const relationLinks = $scope.jobLinksCollection.relationLinks;

            vm.jobQuoteLinksCollection = filterLinksByType(relationLinks, 'job_quote');
            vm.jobLinksCollection = filterLinksByType(relationLinks, 'job_job');
            vm.jobActionLinksCollection = filterLinksByType(relationLinks, 'job_action');

            vm.loading = false;
        }

        function deleteAction(link) {
            return confirmationModal.open().result.then(
                function () {
                    return apiClient.delete(link).then(function (response) {
                        if (response) {
                            $rootScope.skipDirtyCheck = true;
                            $state.go('.', $stateParams, {reload: $state.get('^')}).then(function () {
                                messenger.success('DELETE_SUCCESS');
                            });
                        }
                    });
                }
            );
        }

        function addAction() {
            var link;
            params = {};
            params.relation = vm.relation.value;
            params.type = 'job_' + vm.linkType;
            params.sourceId = vm.job.id;
            params.targetId = vm.id;

            apiClient.create(vm.job.getLink('create-link'), params).then(function (response) {
                if (response) {
                    $rootScope.skipDirtyCheck = true;
                    $state.go('.', angular.extend({}, $stateParams), {reload: $state.get('^')}).then(function () {
                        messenger.success('ADD_SUCCESS');
                    });
                }
            });
        }

        function setRelationOptions(){
            if ( vm.linkType === 'action') {
                vm.relationOptions.shift();
            } else if (vm.relationOptions.length == 2){
                vm.relationOptions.splice(0, 0, {name: $translate.instant('JOB_LINK_WITH'), value: 2});
            }
        }
    }
})();
