angular.module('elogbooksDirectives').directive('risk', ['apiClient', function (apiClient) {
    return {
        require: ['ngModel'],
        scope: {
            risk: '=ngModel',
            required: '=ngRequired',
            disabled: '=ngDisabled'
        },
        templateUrl: '/modules/directives/form-elements/risk/risk.html',
        link: function(scope){
            scope.options = [];
            apiClient.get('/risktypes?page=1&limit=999999').then(function(response) {
                scope.options = response.getData().risktypes;
            });
        }
    }
}]);
