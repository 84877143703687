(function () {
    'use strict';

    angular
        .module('elogbooksControllers')
        .controller('BulkActionCreateController', BulkActionCreateController);

    BulkActionCreateController.$inject = [
        '$stateParams',
        'config',
        '$uibModalInstance',
        'jobs',
        'serviceProviderResponse',
        'rootResourceResponse',
        'jobsCount',
        'user',
        'selectedSite',
        'selectedSP',
        'apiClient',
        'lodash',
        'messenger',
        'jobParamsService',
        'confirmationModal',
        'canBulkReassignOperative'
    ];

    function BulkActionCreateController(
        $stateParams,
        config,
        $uibModalInstance,
        jobs,
        serviceProviderResponse,
        rootResourceResponse,
        jobsCount,
        user,
        selectedSite,
        selectedSP,
        apiClient,
        lodash,
        messenger,
        jobParamsService,
        confirmationModal,
        canBulkReassignOperative
    ) {
        var vm = this;
        vm.config = config;
        vm.data = {
            note: null,
            _links: []
        };
        vm.submitDisabled = false;
        vm.showLoader = false;
        vm.jobs = jobs;

        vm.canBulkReassignOperative = canBulkReassignOperative;
        var operativeParams = {permission: 'operative_permission_receive_jobs', order: 'name', status: 'active'};

        var maxFilteredJobs = 10000;
        vm.jobsCount = jobsCount;

        if (vm.config.bulkAsssigntype === 'cancel-tasks') {
            if (vm.config.requestData.allTasks) {
                vm.taskCount = vm.config.taskCount;
            } else {
                vm.tasks = vm.config.requestData._links.task.length;
            }
        }

        if (['reassign', 'reassign-operatives'].indexOf(vm.config.bulkAsssigntype) > -1) {
            vm.selectedSP = selectedSP.map(Number);
            vm.serviceProvidersResponse = serviceProviderResponse ? serviceProviderResponse : [];
            vm.selectedServiceProviderModel = {
                response: vm.serviceProvidersResponse,
                link: vm.serviceProvidersResponse ? vm.serviceProvidersResponse.getLink('self') : null,
                linkParameters: {active: 1},
                responseKeyPath: 'serviceproviders',
                itemHrefPath: '_links.self.href',
                itemValuePath: 'name',
                itemDisabledKey: 'contractorApproved',
                itemDisabledKeyValue: false,
                itemDisabledHint: 'NOT_APPROVED',
                onSelect: changeServiceProvider,
                onRemove: changeServiceProvider,
                required: vm.config.bulkAsssigntype === 'reassign'
            };
            vm.selectedOperativeModel = {
                response: null,
                required: vm.config.bulkAsssigntype === 'reassign-operatives',
                responseKeyPath: 'operatives',
                itemHrefPath: '_links.self.href',
                itemValuePath: '_links.user.title',
                onSelect: changeOperative,
                linkParameters: operativeParams,
                onRemove: changeOperative
            };

            if (vm.selectedSP.length === 1) {
                lodash.each(vm.serviceProvidersResponse.serviceproviders, function (serviceProvider) {
                    if (vm.selectedSP.indexOf(serviceProvider.id) >= 0) {
                        vm.selectedServiceProviderModel.selected = {
                           '_links.self.href' : serviceProvider.getLink('self'),
                          'value' : serviceProvider.name,
                          'object': serviceProvider
                        };
                    }
                });
            }

            getOperatives();
        }

        vm.confirm = confirm;
        vm.close = closeModal;

        function changeServiceProvider() {
            getOperatives();
            changeOperative();
        }

        function changeOperative() {
            if (vm.selectedOperativeModel.selected && vm.selectedOperativeModel.selected.object) {
                vm.data._links.operative = {href: vm.selectedOperativeModel.selected.href};
            } else {
                delete vm.data._links.operative;
            }
        }

        function getOperatives() {
            vm.selectedOperativeModel.clear;
            vm.selectedOperativeModel.link = null;
            vm.selectedOperativeModel.selected = null;
            vm.selectedOperativeModel.response = null;

            if (typeof vm.selectedServiceProviderModel.selected !== 'undefined') {
                apiClient.get(vm.selectedServiceProviderModel.selected.object.getLink('self')).then(function (serviceProviderResponse) {
                    vm.selectedServiceProviderModel.selected.object = serviceProviderResponse;
                    //List operatives
                    if (serviceProviderResponse && typeof serviceProviderResponse._links.operatives !== 'undefined') {
                        vm.selectedOperativeModel.link = serviceProviderResponse.getLink('operatives');
                        return;
                    }
                });
            } else if (vm.config.bulkAsssigntype === 'reassign-operatives') {
                // no sp selected, so load all operatives
                vm.selectedOperativeModel.link = rootResourceResponse.getLink('operatives');
            }
        }

        function closeModal() {
            $uibModalInstance.dismiss();
        }

        function confirm() {
            if (config.selected === true) {
                submitBulkAction();
            } else {
                // when applying to all selcted jobs, show confirmation
                return confirmationModal.open().result.then(function() {
                    submitBulkAction();
                });
            }
        }

        function closeModalAndDisplayNotification(response) {
            vm.submitDisabled = false;
            vm.showLoader = false;

            if (response) {
                messenger.success(vm.config.updateMessage);
                $uibModalInstance.close();
            }
        }

        function submitBulkAction() {
            vm.submitDisabled = true;
            vm.showLoader = true;

            if (vm.config.bulkAsssigntype === 'cancel-tasks') {
                vm.config.requestData.note = vm.data.note;

                // Map $stateParams for backend
                if ($stateParams.assetId) {
                    $stateParams = angular.extend({}, $stateParams, {
                        'assetId': JSON.parse($stateParams.assetId).v
                    });
                }
                if ($stateParams.regionId) {
                    $stateParams = angular.extend({}, $stateParams, {
                        'regionId': JSON.parse($stateParams.regionId).v
                    });
                }
                $stateParams = angular.extend({}, $stateParams, {
                    'siteIds[]': $stateParams.siteIds,
                    'statutoryType[]': $stateParams.statutoryType
                });

                return apiClient.create(vm.config.url, vm.config.requestData, angular.extend({}, vm.config.requestData, $stateParams.note)).then(function (response){
                    if (response) {
                        vm.submitDisabled = false;
                        vm.showLoader = false;
                        $uibModalInstance.close();
                    }
                });
            }

            if (vm.config.bulkAsssigntype === 'cancel-actions') {
                var params = {}
                vm.data.allFilteredActions = false;

                if (vm.config.selected) {
                    vm.data.actions = {};
                    vm.data.actions.actions = [];

                    lodash.forEach(vm.config.actions, function (action) {
                        vm.data.actions.actions.push({
                            href: action.getLink('self')
                        });
                    });
                } else {
                    params = {
                        page: $stateParams.actionsPage,
                        limit: $stateParams.actionsLimit,
                        order: $stateParams.actionsOrder,
                        id: $stateParams.actionsId,
                        'actionTypes[]': $stateParams.actionTypes,
                        'status[]': $stateParams.actionsStatus,
                        dateDueStart: $stateParams.actionsDateDueStart ? new Date($stateParams.actionsDateDueStart).toISOString() : null,
                        dateDueEnd: $stateParams.actionsDateDueEnd ? new Date($stateParams.actionsDateDueEnd).toISOString() : null,
                        priorityName: $stateParams.actionsPriorityName,
                        actionTypes: $stateParams.actionTypes,
                        'externalSystem[]': $stateParams.actionsExternalSystem,
                        coreClient: $stateParams.coreClient,
                        remedial: $stateParams.actionsRemedial,
                        'sites[]': null,
                        site: null,
                        actionsCreatedAtStart: $stateParams.actionsCreatedAtStart ? new Date($stateParams.actionsCreatedAtStart).toISOString() : null,
                        actionsCreatedAtEnd: $stateParams.actionsCreatedAtEnd ? new Date($stateParams.actionsCreatedAtEnd).toISOString() : null
                    }

                    if (selectedSite) {
                        params["sites[]"] = selectedSite.id;
                        params.site = selectedSite.id;
                    }

                    vm.data.allFilteredActions = true;
                }

                return apiClient.create(vm.config.link, vm.data, params).then(function (response) {
                    closeModalAndDisplayNotification(response);
                });
            }

            vm.data._links = {};
            var modalParams = {};

            if (config.selected === true) {
                vm.data.jobs = {};
                vm.data.jobs.jobs = [];

                lodash.forEach(vm.jobs, function(job) {
                    vm.data.jobs.jobs.push({
                        href: job.getLink('self')
                    });
                });
            } else {
                // pass job filter params to endpoint
                modalParams = jobParamsService.getJobParams($stateParams, user, selectedSite);

                if ($stateParams.serviceProvider) {
                    modalParams.serviceProvider = JSON.parse($stateParams.serviceProvider).v;
                }

                vm.data.allFilteredJobs = true;

                // check total jobs filtered limit
                if(jobsCount > maxFilteredJobs) {
                    vm.showLoader = false;
                    messenger.error('BULK_ACTION_MAX_FILTERED_ITEMS_EXCEEDED', null, maxFilteredJobs);

                    return false;
                }
            }

            if (vm.config.bulkAsssigntype === 'reassign') {
                vm.data._links.serviceProvider = {
                    href: vm.selectedServiceProviderModel.selected.object.getLink('self')
                };
            }

            if (['reassign', 'reassign-operatives'].indexOf(vm.config.bulkAsssigntype) > -1) {
                var selectedOperative = vm.selectedOperativeModel.selected ? vm.selectedOperativeModel.selected.object.getLink('user') : null;

                if (selectedOperative) {
                    vm.data._links.user = {
                        href: selectedOperative
                    };
                }
            }

            var bulkActionLink = (typeof rootResourceResponse._links.bulkaction.length === 'undefined') ? [rootResourceResponse._links.bulkaction] : rootResourceResponse._links.bulkaction;

            bulkActionLink = lodash.find(bulkActionLink, {name: vm.config.bulkAsssigntype}).href;

            return apiClient.create(bulkActionLink, vm.data, modalParams).then(function(response) {
                closeModalAndDisplayNotification(response);
            });
        }
    }
})();
