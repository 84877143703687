(function () {
    'use strict';

    angular
        .module('elogbooks.common.survey')
        .controller('SurveyRequestsListController', SurveyRequestsListController);

    SurveyRequestsListController.$inject = [
        '$state',
        '$stateParams',
        'surveyRequestCollectionResponse',
        'coreClientCollectionResponse',
        'regionCollectionResponse',
        'siteGroupCollectionResponse',
        'serviceProviderGroupCollectionResponse',
        '$translate'
    ];

    function SurveyRequestsListController(
        $state,
        $stateParams,
        surveyRequestCollectionResponse,
        coreClientCollectionResponse,
        regionCollectionResponse,
        siteGroupCollectionResponse,
        serviceProviderGroupCollectionResponse,
        $translate
    ) {
        var vm = this;
        vm.site = $state.params.siteId;
        vm.surveyRequests = surveyRequestCollectionResponse.surveyrequests;
        vm.surveyRequestResponse = surveyRequestCollectionResponse;
        vm.entityCount = surveyRequestCollectionResponse.count;
        vm.search = search;

        vm.siteRegionSelect = {
            response: regionCollectionResponse,
            link: regionCollectionResponse ? regionCollectionResponse.getLink('self') : null,
            responseKeyPath: 'regions',
            itemValuePath: 'name',
            itemHrefPath: 'id'
        };

        vm.coreClientSelect = {
            response: coreClientCollectionResponse,
            link: coreClientCollectionResponse ? coreClientCollectionResponse.getLink('self') : null,
            responseKeyPath: 'coreclients',
            itemValuePath: 'name',
            itemHrefPath: 'id'
        };

        vm.serviceProviderGroupSelect = {
            response: serviceProviderGroupCollectionResponse,
            link: serviceProviderGroupCollectionResponse ? serviceProviderGroupCollectionResponse.getLink('self') : null,
            responseKeyPath: 'groups',
            itemValuePath: 'id',
            itemTitlePath: 'name',
            loadmoreEnabled: true
        };

        vm.siteGroupSelect = {
            response : siteGroupCollectionResponse,
            link : siteGroupCollectionResponse ? siteGroupCollectionResponse.getLink('self') : null,
            responseKeyPath: 'sitegroups',
            itemValuePath: 'id',
            itemTitlePath: 'name',
            loadmoreEnabled: true
        };

        vm.pagePrefix = $stateParams.surveyPage ? 'survey' : null;

        if ($state.current.name === "dashboard.user.sites.list.details.surveys.active") {
            vm.title = $translate.instant('SURVEY_REQUESTS');
        } else {
            vm.title = $translate.instant('SURVEYS');
        }

        var statusOptions = [
            {
                title: $translate.instant('STATUS_ACTIVE'),
                value: 'active'
            },
            {
                title: $translate.instant('STATUS_INACTIVE'),
                value: 'inactive'
            },
            {
                title: $translate.instant('STATUS_PENDING'),
                value: 'pending'
            },
            {
                title: $translate.instant('STATUS_COMPLETED'),
                value: 'completed'
            },
            {
                title: $translate.instant('STATUS_AUTOCOMPLETED'),
                value: 'autocompleted'
            }
        ];

        vm.criteria = {
            siteRegions: {
                type: 'autocompletemulti',
                multiple: true,
                title: 'REGION',
                value: $stateParams.siteRegions,
                options: vm.siteRegionSelect,
                param: 'siteRegions'
            },
            serviceProviderGroup: {
                type: 'jsonselectwidget',
                title: 'SERVICE_PROVIDER_GROUP',
                value: JSON.parse($stateParams.serviceProviderGroup),
                options: vm.serviceProviderGroupSelect,
                param: 'groups'
            },
            siteGroup: {
                type: 'jsonselectwidget',
                title: 'SITE_GROUP',
                value: JSON.parse($stateParams.siteGroup),
                options: vm.siteGroupSelect,
                param: 'sitegroups'
            },
            coreClients: {
                type: 'autocompletemulti',
                multiple: true,
                title: 'CORE_CLIENT',
                value: $stateParams.coreClients,
                options: vm.coreClientSelect,
                param: 'coreClients'
            },

            serviceProviderName: { type: 'text', title: 'SERVICE_PROVIDER', value: $stateParams.serviceProviderName },
            surveyStatus: { type: 'options', multiple: true, title: 'STATUS', value: $stateParams.surveyStatus, options: statusOptions, clearValue: null},
            surveyName: { type: 'text', title: 'SURVEY', value: $stateParams.surveyName },
            scoreLessThan: { type: 'number', title: 'SCORE_LESS_THAN', value: parseInt($stateParams.scoreLessThan) || null, min: 1, max: 100, step: 1 },
            reportingPeriod: {
                type: 'month-year',
                title: 'REPORTING_PERIOD_MONTH_YEAR',
                value: $stateParams.reportingPeriod ? new Date($stateParams.reportingPeriod) : null,
                options: {
                    datepickerMode: 'month',
                    minMode: 'month',
                }
            }
        };

        function order (key) {
            $stateParams.order = key;

            $state.go('.', angular.extend({}, $stateParams), { reload: $state.current });
        }

        function search (params) {
            var override = {
                page: 1,
                surveyPage: 1
            };

            $state.go('.', angular.extend({}, params, override), { reload: $state.current });
        }

        function remove() {
            window.localStorage.removeItem('regionFilter');
        }

        function update() {
            window.localStorage.removeItem('regionFilter');
        }
    }
})();
