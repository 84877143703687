(function() {
    'use strict';

    angular
        .module('elogbooks.admin.surveys')
        .controller('SurveysController', SurveysController);

    SurveysController.$inject = ['surveyCollectionResponse', '$stateParams', '$state', '$filter'];

    function SurveysController(surveyCollectionResponse, $stateParams, $state, $filter)  {
        var vm = this;
            vm.surveys = surveyCollectionResponse.getData().surveys;
            vm.surveyCollectionResponse = surveyCollectionResponse;

        vm.order = order;
        vm.search = search;

        var statusOptions = [
            {
                title: $filter('translate')('NONE_SELECTED'),
                value: null
            },
            {
                title: $filter('translate')('STATUS_DRAFT'),
                value: 'draft'
            },
            {
                title: $filter('translate')('STATUS_ACTIVE'),
                value: 'active'
            },
            {
                title: $filter('translate')('STATUS_INACTIVE'),
                value: 'inactive'
            }
        ];

        vm.criteria = {
            id: { type: 'number', value: $stateParams.id ? parseInt($stateParams.id, 10) : null, title: 'ID', min: 1 },
            name: { type: 'text', value: $stateParams.name, title: 'NAME' },
            status: { type: 'options', title: 'IS_ACTIVE', value: $stateParams.status, options: statusOptions, clearValue: null }
        };

        function order (key) {
            $stateParams.order = key;

            $state.go('.', angular.extend({}, $stateParams), { reload: $state.current });
        }

        function search (params) {
            var override = {
                page: 1
            };

            $state.go('.', angular.extend({}, params, override), { reload: $state.current });
        }
    };
 })();
