(function () {
    'use strict';

    angular
        .module('elogbooks.user.assets')
        .controller('CommonAddImportController', ['$state', '$stateParams', 'apiClient', '$scope', 'messenger', 'importsCollectionResponse', 'rootResourceResponse', 'elbDownloadService', 'lodash', 'disableSubmitService', 'sectionResource', 'legendService', 'user', 'userManager', CommonAddImportController]);

    function CommonAddImportController($state, $stateParams, apiClient, $scope, messenger, importsCollectionResponse, rootResourceResponse, elbDownloadService, lodash, disableSubmitService, sectionResource, legendService, user, userManager) {
        var vm = this;
        vm.importFile = null;

        vm.import = {
            _links : {
                file : undefined
            },
            testRun: true
        };

        if (sectionResource) {
            vm.hasType = true;
            vm.import.type = sectionResource.type;
        }

        vm.noTemplateImports = ["sfg", "sfg-csv"];
        vm.legend = null;
        vm.showLegend = false;
        vm.noBorder = false;
        vm.create = create;
        vm.reload = reload;
        vm.downloadTemplate = downloadTemplate;
        vm.getLegend = getLegend;
        vm.exportResource = exportResource;
        vm.warnUser = warnUser;
        vm.user = user;
        vm.userManager = userManager;

        switch ($stateParams.type) {
            case 'user_asset_task':
                vm.noBorder = true;
                vm.exports = ['asset_types', 'statutory_types', 'document_types'];
                break;
            case 'waste_entry':
                vm.exports = ['waste_type', 'treatment_type', 'unit'];
                break;
            default:
                break;
        }

        // Hateoas serializes templates response as an object rather than array if there's only one object in the 'collection'.
        var imports = importsCollectionResponse;
        vm.imports = (typeof imports._links.templates.length === 'undefined') ? [imports._links.templates] : imports._links.templates;
        vm.importsLegends = (typeof imports._links.legends.length === 'undefined') ? [imports._links.legends] : imports._links.legends;
        setTimeout(function () {
            disableSubmitService.disableSubmit();
        }, 300);

        $scope.$watch('vm.import.type', function () {
            if (vm.showLegend === true) {
                getLegend();
            }
        });

        function create() {
            if (typeof vm.importFile._links === 'undefined') {
                return;
            }

            vm.import._links.file = vm.importFile._links.file;

            var importLink = (typeof rootResourceResponse._links.import.length === 'undefined') ? [rootResourceResponse._links.import] : rootResourceResponse._links.import;

            importLink = lodash.find(importLink, {name: vm.import.type}).href;

            apiClient.create(importLink, vm.import).then(function(response) {
                if (response) {
                    $state.go($state.current.parent, {}, { reload: $state.current.parent }).then(function() {
                        messenger.info('IMPORT_QUEUED');
                    });
                } else {
                    messenger.error('IMPORT_ERROR');
                    vm.import._links.file = undefined;
                }
            });
        }

        function getLegend() {
            vm.showLegend = !vm.showLegend;

            if (vm.showLegend === true) {
                vm.legend = null;
                var legend = lodash.find(vm.importsLegends, {name: vm.import.type});
                if (legend !== null) {
                    apiClient.get(legend.href).then(function(response) {
                        vm.legend = legendService.structureLegend(response.metaCollection);
                    });
                }
            }
        }

        function exportResource(type) {
            var exportLink = null;
            exportLink = imports.getLink('export-' + type.replace('_', '-'));

            if ($stateParams.type === 'waste_entry' && vm.exports) {
                return downloadExport(exportLink, type);
            }

            if (exportLink !== null) {
                return apiClient.get(exportLink).then(function(response) {
                    if (response.id) {
                        messenger.success('EXPORT_IN_PROCESS');
                    }
                });
            }
        }

        function reload() {
            $state.go($state.current, {}, {reload: $state.current});
        }

        function downloadExport(href, name) {
            elbDownloadService.downloadUrl(href, name + '_export.csv', 'text/csv');
        }

        function downloadTemplate() {
            var importTemplate = lodash.find(vm.imports, {name: vm.import.type});

            elbDownloadService.downloadUrl(importTemplate.href, importTemplate.name + '_import_template.csv', 'text/csv');
        }

        function warnUser() {
            var exportsToWarn = ['user_asset_task','subasset','sfg-csv'];

            return exportsToWarn.indexOf(vm.import.type) > -1 && vm.userManager.hasPermission('user_permission_manage_imports', user);
        }
    }
})();
