(function(){
    'use strict';

    angular
        .module('elogbooks.user.contracts')
        .controller('VersionListController', VersionListController);

    VersionListController.$inject = ['versionCollectionResponse', '$stateParams'];

    function VersionListController(versionCollectionResponse, $stateParams) {
        var vm = this;
        vm.versionResponse = versionCollectionResponse;
        vm.versions = versionCollectionResponse.events;

        vm.getVersionNumber = getVersionNumber;

        function getVersionNumber(index) {
            //these are fake version numbers that are ordered ascending from page to page
            return (versionCollectionResponse.count - (($stateParams.versionPage - 1) * $stateParams.versionLimit)) - index;
        }
    }
})();
