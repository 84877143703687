(function () {
    'use strict';

    angular
        .module('elogbooks.common.jobs')
        .controller('CommonExplicitAccessController', CommonExplicitAccessController);

    CommonExplicitAccessController.$inject = ['apiClient', '$uibModal', 'messenger', '$state', 'jobResponse', 'userCollectionResponse', 'explicitAccessCollectionResponse'];

    function CommonExplicitAccessController(apiClient, $uibModal, messenger, $state, jobResponse, userCollectionResponse, explicitAccessCollectionResponse) {
        var vm = this;
        vm.data = {_links:{}};
        vm.users = userCollectionResponse;
        vm.revoke = revoke;
        vm.explicitAccessCollection = explicitAccessCollectionResponse;

        function revoke(explicitAccessUser) {
            vm.data.expiresAt = new Date;
            vm.data._links.user = {href:explicitAccessUser.getLink('user')};

            $uibModal.open({
                templateUrl: '/modules/common/jobs/modal/revoke-note-modal.html',
                controller: ['$uibModalInstance', function($uibModalInstance) {
                    var vm = this;

                    vm.close = function() {
                        $uibModalInstance.dismiss('cancel');
                    };

                    vm.submit = function() {
                        $uibModalInstance.close(vm.note);
                    };
                }],
                controllerAs: 'vm',
                resolve: {}
            }).result.then(function (response) {
                vm.data.note = response;
                apiClient.update(jobResponse.getLink('explicit-revoke'), vm.data).then(function (response) {
                    if (response) {
                        $state.go('.', {}, { reload: $state.get('^.^') }).then(function () {
                            messenger.success('ACCESS_REVOKED');
                            apiClient.get(jobResponse.getLink('explicit-access')).then(function (response) {
                                vm.explicitAccessCollection = response;
                            });
                        });
                    } else {
                        messenger.error('REQUEST_ERROR');
                    }
                });
            });
        }
    }
})();
