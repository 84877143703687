(function() {
    'use strict';

    angular
        .module('elogbooks.user.absence-planners')
        .controller('UserAbsencePlannersListController',  UserAbsencePlannersListController);

    UserAbsencePlannersListController.$inject = [
        'absenceEntriesCollectionResponse',
        '$translate',
        '$stateParams',
        'departmentCollectionResponse',
        '$state',
        'user',
        'userManager'
    ];

    function UserAbsencePlannersListController(
        absenceEntriesCollectionResponse,
        $translate,
        $stateParams,
        departmentCollectionResponse,
        $state,
        user,
        userManager
    ) {
        var vm = this;
        vm.absenceEntriesCollection = absenceEntriesCollectionResponse;
        vm.pages = vm.absenceEntriesCollection.pages;
        vm.absenceEntries = vm.absenceEntriesCollection.absenceEntries;
        vm.userManager = userManager;
        vm.user = user;
        vm.hideAdd = departmentCollectionResponse.departments.length !== 0 ? false : true;
        vm.departmentSelect = null;
        vm.criteria = {};

        var statusOptions = [
            { value: 'pending_approval', title: $translate.instant('PENDING_APPROVAL') },
            { value: 'approved', title: $translate.instant('APPROVED') },
            { value: 'rejected', title: $translate.instant('REJECTED') }
        ];

        var typeOptions = [
            { value: 'type_holiday', title: $translate.instant('TYPE_HOLIDAY') },
            { value: 'type_sickness', title: $translate.instant('TYPE_SICKNESS') },
            { value: 'type_unauthorised', title: $translate.instant('TYPE_UNAUTHORISED') },
            { value: 'type_maternity_paternity_leave', title: $translate.instant('TYPE_MATERNITY_PATERNITY_LEAVE') },
            { value: 'type_compassionate', title: $translate.instant('TYPE_COMPASSIONATE') },
            { value: 'type_other', title: $translate.instant('TYPE_OTHER') }
        ];

        vm.search = searchAction;

        if (departmentCollectionResponse) {
            vm.departmentSelect = {
                response : departmentCollectionResponse,
                link: departmentCollectionResponse ? departmentCollectionResponse.getLink('self') : null,
                linkParameters: {'active': true},
                itemHrefPath: 'id',
                itemValuePath: 'name',
                responseKeyPath: 'departments'
            };

            vm.criteria.departments = {
                type: 'autocompletemulti',
                title: 'DEPARTMENT',
                multiple: true,
                value: $stateParams.departments,
                options: vm.departmentSelect,
                param: 'departments',
                max: 50
            };
        }

        vm.criteria.status = {
            type: 'options',
            title: 'STATUS',
            multiple: true,
            value: $stateParams.status,
            options: statusOptions
        };

        vm.criteria.type = {
            type: 'options',
            title: 'TYPE',
            multiple: true,
            value: $stateParams.type,
            options: typeOptions
        };

        vm.criteria.name = {
            type: 'text',
            title: 'NAME',
            value: $stateParams.name
        };

        function searchAction (params) {
            var override = {
                page: 1
            };
            $state.go('.', angular.extend({}, params, override), { reload: $state.current });
        }
    }
})();
