app.config( function($translateProvider) {
    $translateProvider.translations('nl', {
        METER_TYPE_INFORMATION: 'Meter type informatie',

        ADD_NEW_METER_TYPE: 'Voeg nieuwe metertype toe',
        EDIT_METER_TYPE: 'Wijzig metertype',

        METER_TYPE_LIST: 'Meter Types',
        METER_TYPES_LIST: 'Meter Types',
        METER_TYPE_ADD: 'Voeg nieuwe metertype toe',
        METER_TYPE_EDIT: 'Wijzig Meter Type',

        METER_TYPE_CREATED: 'Meter Type aangemaakt',
        METER_TYPE_UPDATED: 'Meter Type aangepast',
        METER_TYPE_DELETED: 'Meter Type verwijderd',

        METER_TYPE_METER: 'meter',
        METER_TYPE_SUPPLY: 'levering',
        METER_TYPE_OCCUPANCY: 'bezetting'
    });
});
