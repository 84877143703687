(function () {
    'use strict';

    angular
        .module('elogbooks.user.reports')
        .controller('UserReportsSiteAssociateExtensionRequestsQuotesController', UserReportsSiteAssociateExtensionRequestsQuotesController);

    UserReportsSiteAssociateExtensionRequestsQuotesController.$inject = ['$state', '$stateParams', 'quotesCollection', 'siteAssociateResponse'];

    function UserReportsSiteAssociateExtensionRequestsQuotesController($state, $stateParams, quotesCollection, siteAssociateResponse) {
        var vm = this;
        vm.quotesCollection = quotesCollection;
        vm.quotes = quotesCollection.quotes;
        vm.exportParams = {exportView: 'details'};
        vm.extensionRequestStatus = $stateParams.status;
    }
})();