(function () {
    'use strict';

    angular
        .module('elogbooks.admin.job-groups')
        .controller('AdminJobGroupAddEditController', AdminJobGroupAddEditController);

    AdminJobGroupAddEditController.$inject = ['$state', 'apiClient', 'messenger', 'jobGroupResponse', 'requestDataFactory', 'jobGroupCollectionResponse', '$translate', 'parentResponse', 'confirmationModal'];

    function AdminJobGroupAddEditController ($state, apiClient, messenger, jobGroupResponse, requestDataFactory, jobGroupCollectionResponse, $translate, parentResponse, confirmationModal) {
        var vm = this;
            vm.jobGroup = jobGroupResponse;
            vm.jobGroupCollectionResponse = jobGroupCollectionResponse;
            vm.data = requestDataFactory.createRequest(vm.jobGroup);
        delete vm.data._links.children;

        angular.forEach(vm.jobGroupCollectionResponse.jobgroups, function (jobGroup, $index) {
            jobGroup.value = jobGroup._links.self;
            jobGroup.title = jobGroup.name;
        });

        vm.jobGroupSelect = {
            response: jobGroupCollectionResponse,
            link: jobGroupCollectionResponse._links.self.href,
            required: false,
            responseKeyPath: 'jobgroups',
            itemHrefPath: '_links.self.href',
            itemValuePath: 'name',
            isHierarchical: true,
            onSelect: changeJobGroup,
            onRemove: changeJobGroup
        };

        vm.statusOptions = [
            { value: true, title: $translate.instant('STATUS_ACTIVE') },
            { value: false, title: $translate.instant('STATUS_INACTIVE') }
        ];

        vm.create = createAction;
        vm.update = updateAction;
        vm.delete = deleteAction;

        if (parentResponse) {
            vm.jobGroupSelect.selected = {
                href: parentResponse.getLink('self'),
                title: parentResponse.name,
                object: parentResponse
            };

            changeJobGroup();
        }

        function createAction () {
            return apiClient.create(jobGroupCollectionResponse.getLink('create'), vm.data).then(function (response) {
                if (response) {
                    $state.go('^', {}, { reload: $state.current.parent }).then(function () {
                        messenger.success('JOB_GROUP_ADDED');
                    });
                } else {
                    messenger.error('REQUEST_ERROR');
                }
            });
        }

        function updateAction () {
            return apiClient.update(vm.jobGroup.getLink('edit'), vm.data).then(function (response) {
                if (response) {
                    $state.go('^', {}, { reload: $state.current.parent }).then(function (response) {
                        messenger.success('JOB_GROUP_UPDATED');
                    });
                } else {
                    messenger.error('REQUEST_ERROR');
                }
            });
        }

        function deleteAction () {
            return confirmationModal.open().result.then(function (response) {
                return apiClient.delete(vm.jobGroup.getLink('delete')).then(function (response) {
                    if (response) {
                        $state.go('dashboard.admin.jobgroups.list', {}, { reload: 'dashboard.admin.jobgroups.list' }).then(function () {
                            messenger.success('JOB_GROUP_DELETED');
                        });
                    } else {
                        messenger.error('REQUEST_ERROR');
                    }
                });
            });
        }

        function changeJobGroup () {
            vm.data._links.parent = undefined;
            if (vm.jobGroupSelect.selected) {
                vm.data._links.parent = {href: vm.jobGroupSelect.selected.href};
            }
        }
    }
})();
