(function () {
    'use strict';

    angular
        .module('elogbooksDirectives')
        .directive('elbContractWorkflowButtons', elbContractWorkflowButtons);

    elbContractWorkflowButtons.$inject = [];

    function elbContractWorkflowButtons () {
        return {
            restrict: 'AE',
            scope: true,
            templateUrl: '/modules/directives/elb-contract-workflow-buttons/contract-workflow-buttons.html'
        };
    }
})();
