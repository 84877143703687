(function () {
    'use strict';

    angular
        .module('elogbooks.admin.serviceroutines')
        .controller('ServiceRoutineInfoController', ServiceRoutineInfoController);

    ServiceRoutineInfoController.$inject = ['serviceRoutineResponse', 'modalForm', 'apiClient', '$state', 'messenger', '$translate', 'userManager'];

    function ServiceRoutineInfoController (serviceRoutineResponse, modalForm, apiClient, $state, messenger, $translate, userManager) {
        var vm = this;
            vm.serviceRoutine = serviceRoutineResponse;
            vm.copyAction = copyAction;
            vm.showCopyButton = userManager.hasPermission('user_permission_manage_service_routines');
            vm.statusOptions = [
                { value: true, title: $translate.instant('STATUS_ACTIVE') },
                { value: false, title: $translate.instant('STATUS_INACTIVE') }
            ];
            vm.serviceRoutineCopy = {};

        function onCloseCallback() {
            return new Promise((resolve) => {
                apiClient.create(vm.serviceRoutine.getLink('copy'), vm.serviceRoutineCopy).then(function(copy) {
                    if (copy) {
                        $state.go('.', { resource: copy.getLink('self').encode()})
                        .then(function() {
                            messenger.success('SERVICE_ROUTINE_COPIED');
                            resolve({error: false});
                        });
                    } else {
                        messenger.error('REQUEST_ERROR');
                        resolve({error: true});
                    }
                });
            })
        }

        function copyAction() {
            vm.serviceRoutineCopy = angular.copy(vm.serviceRoutine);
            modalForm.open({
                entityName: 'SERVICE_ROUTINE',
                responseEntity: vm.serviceRoutineCopy,
                data: vm.serviceRoutineCopy,
                statusOptions: vm.statusOptions,
                formControlsTemplate: '/modules/admin/service-routines/details/modals/service-routine-copy-modal-form.html',
                title: 'COPY_SERVICE_ROUTINE',
                submitButtonText: 'COPY_SERVICE_ROUTINE',
                onClose: onCloseCallback
            })
        }
    }
})();
