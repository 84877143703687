(function  () {
    'use strict';

    angular
        .module('elogbooks')
        .config(registerRoutes)
        .config(registerRoutesList)
        // .config(registerRoutesAdd)
        .config(registerRoutesDetails);
        // .config(registerRoutesEdit);

    function registerRoutes ($stateProvider) {
        $stateProvider
            .state('dashboard.admin.certificatetypes', {
                abstract: true,
                templateUrl: '/shared-templates/view.html',
                ncyBreadcrumb: {
                    skip: true
                },
                data: {
                    permissions: ['user_permission_view_certificate_types']
                }
            });
    }

    function registerRoutesList ($stateProvider) {
        $stateProvider
            .state('dashboard.admin.certificatetypes.list', {
                url: '/certificatetypes?page&limit&order&id&name',
                parent: 'dashboard.admin.certificatetypes',
                data: {
                    permissions: ['user_permission_view_certificate_types']
                },
                params: {
                    page: '1',
                    limit: '30',
                    order: 'id',
                    id: null,
                    name: null,
                },
                views: {
                    '@dashboard': {
                        templateUrl: '/modules/admin/certificatetypes/certificatetype-list.html',
                        controller: 'CertificateTypesController',
                        controllerAs: 'vm'
                    }
                },
                resolve: {
                    certificateTypeCollectionResponse: function (apiClient, rootResourceResponse, $stateParams) {
                        return apiClient.get(rootResourceResponse.getLink('certificatetypes'), $stateParams).then(function (response) {
                            return response || apiClient.noResourceFound();
                        });
                    }
                },
                ncyBreadcrumb: {
                    label: '{{ ::"CERTIFICATE_TYPES" | translate }}'
                }
            });
    }

    // function registerRoutesAdd ($stateProvider) {
    //     $stateProvider
    //         .state('dashboard.admin.certificatetypes.list.add', {
    //             url: '/add',
    //             parent: 'dashboard.admin.certificatetypes.list',
    //             views: {
    //                 '@dashboard': {
    //                     templateUrl: '/modules/admin/certificatetypes/add-edit/certificatetype-form.html',
    //                     controller: 'CertificateTypeAddEditController',
    //                     controllerAs: 'vm'
    //                 }
    //             },
    //             resolve: {
    //                 certificateTypeResponse: function () {
    //                     return {};
    //                 }
    //             },
    //             ncyBreadcrumb: {
    //                 label: '{{ ::"ADD" | translate }}'
    //             }
    //         })
    // }

    function registerRoutesDetails ($stateProvider) {
        $stateProvider
            .state('dashboard.admin.certificatetypes.list.details', {
                url: '/details/{resource}',
                abstract: true,
                parent: 'dashboard.admin.certificatetypes.list',
                data: {
                    permissions: ['user_permission_view_certificate_types']
                },
                views: {
                    '@dashboard': {
                        templateUrl: '/modules/admin/certificatetypes/details/certificatetype-details.html',
                        controller: 'CertificateTypeDetailsController',
                        controllerAs: 'vm'
                    }
                },
                resolve: {
                    certificateTypeResponse: function (apiClient, $stateParams) {
                        return apiClient.get($stateParams.resource.decode());
                    }
                },
                ncyBreadcrumb: {
                    label: '{{ ::$resolves().certificateTypeResponse.name }}'
                }
            })
            .state('dashboard.admin.certificatetypes.list.details.info', {
                url: '/info',
                parent: 'dashboard.admin.certificatetypes.list.details',
                data: {
                    permissions: ['user_permission_view_certificate_types']
                },
                views: {
                    '@dashboard.admin.certificatetypes.list.details': {
                        templateUrl: '/modules/admin/certificatetypes/details/info/certificatetype-info.html',
                        controller: 'CertificateTypeInfoController',
                        controllerAs: 'vm'
                    }
                },
                ncyBreadcrumb: {
                    label: '{{ ::"INFO" | translate }} '
                }
            });
    }

    // function registerRoutesEdit ($stateProvider) {
    //     $stateProvider
    //         .state('dashboard.admin.certificatetypes.list.details.info.edit', {
    //             url: '/edit',
    //             parent: 'dashboard.admin.certificatetypes.list.details.info',
    //             views: {
    //                 '@dashboard.admin.certificatetypes.list.details': {
    //                     templateUrl: '/modules/admin/certificatetypes/add-edit/certificatetype-form.html',
    //                     controller: 'CertificateTypeAddEditController',
    //                     controllerAs: 'vm'
    //                 }
    //             },
    //             ncyBreadcrumb: {
    //                 label: '{{ ::"EDIT" | translate }}'
    //             }
    //         })
    // }
})();
