(function () {
    /**
     * @desc ui.select expanded widget
     * @example <elb-autocomplete ng-model="vm.model"></elb-autocomplete>
     */
    angular
        .module('elogbooksDirectives')
        .directive('elbAutocompleteMulti', ElbAutocompleteMulti);

    ElbAutocompleteMulti.$inject = ['$uibModal', '$timeout', '$window', 'messenger', 'apiClient', 'lodash'];

    function ElbAutocompleteMulti ($uibModal, $timeout, $window, messenger, apiClient, lodash) {
        return {
            restrict: 'AE',
            templateUrl: '/modules/directives/form-elements/autocomplete-multi/autocomplete.html',
            scope: {
                model: '=ngModel',
                searchCtrl: '@',
                searchCtrlTemplate: '@',
                multiple: '@',
                paramName: '@',
                max: '@',
                isFilter: '@'
            },
            require: ['ngModel'],
            link: function(scope, elem, attrs) {
                if('selectWidgetPlaceholder' in attrs) {
                    scope.placeholder = attrs.selectWidgetPlaceholder;
                }

                elem.bind('click', function () {
                    // Use timeout to wait for choices dropdown to render
                    $timeout(function() {
                        bindScroll();
                    }, 100);
                });

                function bindScroll() {
                    var dropdown = elem.find('.ui-select-dropdown');

                    dropdown.unbind('scroll');
                    dropdown.bind('scroll', function (e) {
                        var scrollMargin = 10;

                        if (e.target.scrollHeight - e.target.clientHeight - scrollMargin <= e.target.scrollTop) {
                            angular.element(e.target).scope().model.loadMore(e);
                        }
                    });
                }
            },
            controller: ['$scope', '$state', 'base64', '$stateParams', '$uibModal', '$timeout', '$window', 'messenger', 'apiClient', 'lodash',
                function ($scope, $state, base64, $stateParams, $uibModal, $timeout, $window, messenger, apiClient, lodash) {
                    $scope.placeholder = 'START_TYPING_TO_SEARCH';
                    $scope.cacheKey = 'multiselect_' + base64.encode(($scope.model.cacheState ? $scope.model.cacheState : $state.current.name) + $scope.paramName);
                    $scope.limitNotificationDisplayed = false;
                    $scope.maxNotificationDisplayed = false;
                    $scope.expandSearch = expandSearch;
                    $scope.removeSelectedElement = removeSelectedElement;
                    $scope.click = click;
                    $scope.multiple = true;
                    $scope.searched = false;
                    var defaultModel = {
                        response : null,
                        link : null,
                        linkParameters: {},
                        loading : false,
                        required : false,
                        disabled : false,
                        populateDisabled: false,
                        allowAdd : false,
                        multiple: false,
                        tagging: false,
                        items : [],
                        selectedItems: [],
                        refreshOnLinkChange: true,
                        responseKeyPath: 'data',
                        itemHrefPath: '_links.self.href',
                        itemValuePath: 'name',
                        isHierarchical: false,
                        index: null,
                        appendToBody: false,
                        initiated: false,
                        cacheKeyword: null,
                        map : function(keyPath, valuePath) {
                            return function (item) {
                                if (typeof item === 'undefined'){
                                    return;
                                }

                                return  {
                                    object: item,
                                    href :  lodash.get(item, keyPath),
                                    value : lodash.get(item, valuePath)
                                };
                            }
                        },
                        getResponseData : function (response) {
                            return lodash.get(response.getData(), this.responseKeyPath);
                        },
                        filterResponse : function (response) {
                            var responseData = this.getResponseData(response);

                            if (this.isHierarchical) {
                                var nofixed = this.processHierarchy(responseData, null),
                                    prefixed = [];

                                function processChildren(items, prefix) {

                                    prefix = prefix + ' / ';

                                    angular.forEach(items, function (value, index) {
                                        lodash.set(value, $scope.model.itemValuePath, prefix + lodash.get(value, $scope.model.itemValuePath));
                                        prefixed.push(value);

                                        if (value.children.length) {
                                            processChildren(value.children, lodash.get(value, $scope.model.itemValuePath));
                                        }
                                    });
                                }

                                angular.forEach(nofixed, function (value, index) {
                                    prefixed.push(value);
                                    if (value.children.length) {
                                        processChildren(value.children, lodash.get(value, $scope.model.itemValuePath));
                                    }
                                });

                                return prefixed.map(this.map(this.itemHrefPath, this.itemValuePath));
                            }

                            return responseData.map(this.map(this.itemHrefPath, this.itemValuePath));
                        },
                        buildItems : function (response) {
                            var items = this.filterResponse(response);
                            items = lodash.unionBy($scope.model.items, items, 'href');

                            this.items = lodash.differenceBy(items, $scope.model.selectedItems, 'href');
                        },
                        processHierarchy: function (collection, parent) {
                            var items = [], self = this;

                            angular.forEach(collection, function (value, index) {
                                if ((value.getLink('parent') === null && ! parent) || value.getLink('parent') === parent) {
                                    var children = self.processHierarchy(collection, value.getLink('self'));

                                    items.push(angular.extend({}, value, {children: children}));
                                }
                            });

                            return items;
                        },
                        search : function(keyword) {
                            if (keyword.length >= 1) {
                                $scope.searched = true;
                            }

                            // While searching we want to avoid duplicates, to do so we use union
                            // unfortunately we could end with all resources already being selected so
                            // we have to load until we will load 30 results or reach last page.
                            if ((this.oldSearchKeyword && keyword !== this.oldSearchKeyword && keyword.length === 0)
                                || (keyword.length >= 1 && this.link
                                || keyword === "" && this.link)
                                && this.cacheKeyword !== keyword
                            ) {
                                this.apiFetch(keyword);
                                this.cacheKeyword = keyword;
                            } else {
                                addElement(keyword);
                                this.loading = false;
                            }

                            this.oldSearchKeyword = keyword;
                        },
                        clear : function($event) {
                            if ($scope.model.disabled) {
                                return;
                            }

                            if (typeof $event !== 'undefined') {
                                $event.stopPropagation();
                            }

                            this.selected = undefined;
                            $scope.model.selected = undefined;
                            $scope.model.selectedItems = [];

                            if (this.response
                                && $scope.model.items.length === 0
                                && !$scope.multiple
                            ) {
                                this.buildItems(this.response);
                            }

                            if (typeof this.onRemove == 'function') {
                                this.onRemove($scope.model);
                            }

                            this.search('');
                        },
                        loadMore : function ($event) {
                            if (this.loading || !$scope.model.response) {
                                return;
                            }

                            if ($scope.model.response.page === $scope.model.response.pages
                                && !$scope.limitNotificationDisplayed
                            ) {
                                $scope.limitNotificationDisplayed = true;
                            }

                            if ($scope.model.response.page < $scope.model.response.pages) {
                                if ($event) {
                                    $event.stopPropagation();
                                    $event.preventDefault();
                                }

                                this.loading = true;

                                apiClient.get($scope.model.response.getLink('next'), this.linkParameters, 'short').then(function (response) {
                                    $scope.model.items = lodash.unionBy($scope.model.items, $scope.model.filterResponse(response), 'href');

                                    response[$scope.model.responseKeyPath] = lodash.merge($scope.model.response[$scope.model.responseKeyPath], response[$scope.model.responseKeyPath]);
                                    if (response.page < response.pages) {
                                        $scope.limitNotificationDisplayed = false;
                                    }
                                    $scope.model.response = response;
                                    $scope.model.loading = false;
                                });
                            }
                        },
                        apiFetch : function (keyword, next) {
                            this.loading = true;
                            var link = this.link;

                            if (!$scope.searched && keyword.length < 1 && $scope.model.response !== null) {
                                this.loading = false;
                                return;
                            }

                            if ($scope.model.response && next) {
                                link = $scope.model.response.getLink('next');
                            } else {
                                $scope.model.items = [];
                            }

                            apiClient.get(link, angular.extend({}, this.linkParameters, {name: keyword}), 'short').then(function (response) {
                                var items = $scope.model.filterResponse(response);
                                items = next === true ? lodash.unionBy($scope.model.items, items, 'href') : items;

                                $scope.model.items = lodash.differenceBy(items, $scope.model.selectedItems, 'href');

                                $scope.model.response = response;
                                $scope.model.keyword = keyword;
                                addElement(keyword);

                                if ($scope.model.items.length < parseInt(response.limit)
                                    && response.page < response.pages
                                ) {
                                    //load more
                                    $scope.model.apiFetch(keyword, true);
                                } else {
                                    $scope.model.loading = false;
                                }
                            });
                        }
                    };

                    function hasCache() {
                        return $window.localStorage.getItem($scope.cacheKey);
                    }

                    function cacheFetch() {
                        if (typeof $stateParams[$scope.paramName] === 'undefined' || $stateParams[$scope.paramName] === null) {
                            return [];
                        }

                        var urlItems = $stateParams[$scope.paramName];
                        var cacheItems = JSON.parse($window.localStorage.getItem($scope.cacheKey));
                        var items = [];
                        var missingItems = [];
                        var i;

                        if ($scope.model.cacheItems) {
                            cacheItems = cacheItems.concat($scope.model.cacheItems);
                        }

                        for (i = 0; i < urlItems.length; i++) {
                            var key = lodash.toNumber(urlItems[i]);
                            key = lodash.isNumber(key) ? key : urlItems[i];

                            var item = lodash.find(cacheItems, {href:key});

                            if (item) {
                                items.push(item);
                            }
                        }

                        return items;
                    }

                    function updateCache(items) {
                        $window.localStorage.setItem($scope.cacheKey, JSON.stringify(items));
                    }

                    function init () {
                        // Select items from local storage
                        if ($scope.model.selectedItems.length === 0
                            && hasCache()
                            && !$scope.model.initiated
                        ) {
                            $scope.model.selectedItems = cacheFetch();
                        }

                        $scope.model.initiated = true;
                        $scope.model.keyword = '';

                        if ($scope.model.selected) {
                            if ($scope.model.singleResult) {
                                $scope.model.selected.value = $scope.model.selected.title;
                            } else if ($scope.multiple) {
                                $scope.model.selectedItems = lodash.unionBy($scope.model.selectedItems, [$scope.model.selected], 'href');
                                // Clear selected value
                                this.selected = undefined;
                                $scope.model.selected = undefined;
                                // Remove from items
                                $scope.model.items = lodash.differenceBy($scope.model.items, $scope.model.selectedItems, 'href');
                            } else {
                                if (typeof $scope.model.selected.title !== 'undefined') {
                                    $scope.model.selected.value = $scope.model.selected.title;
                                }
                            }
                        }

                        if ($scope.model.items.length === 0 && $scope.model.link && !$scope.model.response) {
                            apiClient.get($scope.model.link, $scope.model.linkParameters, 'short').then(function (response) {
                                $scope.model.items = lodash.unionBy($scope.model.items, $scope.model.filterResponse(response), 'href');
                                $scope.model.response = response;
                            });
                        }

                        $scope.model.requiredOriginal = $scope.model.required;
                    }

                    $scope.$watch('model.response', function (newValue, oldValue) {
                        if (newValue != oldValue) {
                            init();
                        }
                    });

                    $scope.$watch('model.selected', function (newValue, oldValue) {
                        if (newValue != oldValue) {
                            init();
                        }
                    });

                    $scope.$watch('model.disabled', function(newValue) {
                        if (newValue && !$scope.model.populateDisabled) {
                            $scope.model.selected = undefined;
                        }

                        if ($scope.model.requiredOriginal) {
                            $scope.model.required = !newValue;
                        }
                    });

                    $scope.$watch('model.link', function(newValue, oldValue) {
                        if (newValue != oldValue && newValue && $scope.model.refreshOnLinkChange) {
                            $scope.model.loading = true;
                            return apiClient.get(newValue, $scope.model.linkParameters, 'short').then(function (itemsCollection) {
                                $scope.model.loading = false;
                                $scope.model.buildItems(itemsCollection);
                            });
                        }
                    });

                    $scope.$watch('model.selectedItems', function(newValue) {
                        if (Array.isArray(newValue)) {
                            if ($scope.max && $scope.model.selectedItems.length > $scope.max) {
                                $scope.model.selectedItems = $scope.model.selectedItems.slice(0, $scope.max);

                                if (!$scope.maxNotificationDisplayed) {
                                    messenger.error('MAX_NUMBER_SELECTED');
                                    $scope.maxNotificationDisplayed = true;
                                }

                                return;
                            } else if ($scope.max && $scope.model.selectedItems.length < $scope.max) {
                                $scope.maxNotificationDisplayed = false;
                            }

                            if (lodash.differenceBy($scope.model.items, newValue, 'href').length === 0) {
                                $scope.limitNotificationDisplayed = true;
                                $scope.model.loadMore();
                            }

                            updateCache($scope.model.selectedItems);
                        }
                    });

                    function checkIfExist(keyword) {
                        for (var item in $scope.model.items) {
                            if ($scope.model.items.hasOwnProperty(item)
                                && $scope.model.items[item].value.indexOf(keyword) > -1
                            ) {
                                return true;
                            }
                        }

                        return false;
                    }

                    function addElement(keyword) {
                        if (keyword.length >= 1
                            && $scope.model.allowAdd
                            && !checkIfExist(keyword)
                        ) {
                            $scope.model.items.unshift({value:keyword});
                        }
                    }

                    function click($event, $select) {
                        $select.close();
                        $event.stopPropagation();
                    }

                    function removeSelectedElement(object) {
                        $scope.model.selectedItems = lodash.differenceBy($scope.model.selectedItems, [object], 'href');
                        $scope.model.items = lodash.unionBy($scope.model.items, [object], 'href');
                    }

                    function getSearchTemplate() {
                        if ($scope.searchCtrlTemplate) {
                            return $scope.searchCtrlTemplate
                        }

                        switch ($scope.searchCtrl) {
                            case 'SearchSiteModalController':
                                return '/modules/common/modals/search/site/search-site-modal.html';
                        }
                    }

                    function expandSearch() {
                        if ($scope.searchCtrl) {
                            var modalInstance = $uibModal.open({
                                templateUrl: getSearchTemplate(),
                                controller: $scope.searchCtrl,
                                controllerAs: 'vm',
                                resolve : {
                                    restrictOne : false
                                }
                            });

                            modalInstance.result.then(function (items) {
                                if ($scope.multiple) {
                                    $scope.model.selectedItems = lodash.unionBy($scope.model.selectedItems, items, 'href');
                                    // Clear selected value
                                    $scope.model.clear();
                                    // Remove from items
                                    $scope.model.items = lodash.differenceBy($scope.model.items, $scope.model.selectedItems, 'href');
                                } else {
                                    var item = items[0];

                                    $scope.model.items = lodash.differenceBy($scope.model.selectedItems, [item], 'href');
                                    $scope.model.items.push(item);
                                    $scope.model.selected = item;
                                }
                            });

                            this.expandedSearch = !this.expandedSearch;

                            if (this.expandedSearch) {
                                this.keyword = null;

                                if (typeof this.onRemove == 'function') {
                                    this.onRemove();
                                }
                            }
                        }
                    }

                    //Merge with defaults
                    $scope.model = lodash.merge(defaultModel, $scope.model);
                    init();

                    // Only once on init (init function is executed multiple times by watches)
                    if ($scope.model.items.length === 0
                        && $scope.model.response
                    ) {
                        $scope.model.buildItems($scope.model.response);
                    }

                    if (typeof $scope.model.onSelect === 'function') {
                        $scope.model.onSelect($scope.model);
                    }
                }
            ]
        };
    }
})();
