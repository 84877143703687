(function() {
    'use strict';

    angular
        .module('elogbooks.user.buddies')
        .controller('UserBuddiesAddSiteController', UserBuddiesAddSiteController);

    UserBuddiesAddSiteController.$inject = ['$scope', '$stateParams', '$state', 'messenger', 'apiClient', 'buddyResponse', 'buddiesCollectionResource', 'lodash', 'siteCollectionResponse'];

    function UserBuddiesAddSiteController($scope, $stateParams, $state, messenger, apiClient, buddyResponse, buddiesCollectionResource, lodash, siteCollectionResponse) {
        var vm = this;
        vm.buddy = buddyResponse;
        vm.siteCollectionResponse = siteCollectionResponse;
        vm.siteSelect = {
            response : siteCollectionResponse,
            link : siteCollectionResponse.getLink('self'),
            responseKeyPath: 'sites',
            searchKey: 'siteName',
            required: true
        };
        vm.create = confirmCreate;

        $scope.$watch('vm.siteSelect.selectedItems', function() {
            checkSites();
        });

        function confirmCreate() {
            if (vm.duplicatedSites.length > 0 ) {
                return messenger.error('BUDDY_ALREADY_IN_USE_SITES');
            } else  {
                createAction();
            }
        }

        function createAction() {
            if (!vm.siteSelect.selectedItems.length) {
                return;
            }

            var data = {
                _links: {}
            };

            if (vm.siteSelect.selectedItems.length) {
                data._links.site = vm.siteSelect.selectedItems;
            }

            data.start = vm.buddy.recurrence.start;
            data.end = vm.buddy.recurrence.start;
            data._links.user = {href : vm.buddy._links.buddy.href}

            apiClient.update(vm.buddy.getLink('sites'), data).then(function (response) {
                if (response) {
                    $state.go('^', $stateParams, { reload: $state.$current.parent.parent.parent.self.name }).then(function () {
                        messenger.success('BUDDY_SITE_ADDED');
                    });
                } else {
                    messenger.error('REQUEST_ERROR');
                }
            });
        };

        function checkSites(){
            if (((vm.siteSelect.selectedItems && vm.siteSelect.selectedItems.length > 0))
                && vm.buddy.recurrence.start
                && vm.buddy.recurrence.end
            ) {
                vm.siteSelect.selectedItems.map(function(value){
                    value.id = value.object.id;
                    return value;
                });

                apiClient.get(buddiesCollectionResource.getLink('buddychecksites'), {
                    ids : lodash.map(vm.siteSelect.selectedItems, 'id').join(),
                    selectAllSites : false,
                    start : vm.buddy.recurrence.start,
                    end : vm.buddy.recurrence.end
                }).then(function (response) {
                    if (response) {
                        vm.duplicatedSites = [];
                        vm.duplicatedSitesCount = false;
                        for (var i=0; i < response.sites.length; i++) {
                            var site = lodash.find(vm.siteSelect.selectedItems, {id:parseInt(response.sites[i])});

                            if (site) {
                                vm.duplicatedSites.push(site);
                            }
                        }
                    } else {
                        messenger.error('REQUEST_ERROR');
                    }
                });
            } else {
                vm.duplicatedSites = [];
            }
        }
    }
})();
