(function() {
    'use strict';

    angular
        .module('elogbooks.admin.settings')
        .controller('AdminSettingsAddEditChaseEntityTypeSettingsController', AdminSettingsAddEditChaseEntityTypeSettingsController);

    function AdminSettingsAddEditChaseEntityTypeSettingsController($state, messenger, apiClient, chaseSettingResponse) {
        var vm = this;
        vm.setting = chaseSettingResponse;
        vm.update = updateAction;

        function updateAction() {
            apiClient.update(vm.setting._links.edit.href, angular.copy(vm.setting)).then(function(response) {
                if (response) {
                    $state.go('^', {}, {reload: $state.get('^.^')}).then(function() {
                        messenger.success('CHASE_ENTITY_SETTINGS_UPDATED');
                    });
                } else {
                    messenger.error('REQUEST_ERROR');
                }
            });
        }
    }
})();
