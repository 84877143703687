(function () {
    'use strict';

    angular
        .module('elogbooks.admin.document-types-management')
        .controller('DocumentSetAddDocumentTypeController', DocumentSetAddDocumentTypeController);

    DocumentSetAddDocumentTypeController.$inject = [
        '$scope',
        '$state',
        '$stateParams',
        'lodash',
        'apiClient',
        'crudService',
        'messenger',
        'documentSetResponse',
        'documentSetTypeResponse',
        'documentTypeCollectionResponse'
    ];

    function DocumentSetAddDocumentTypeController (
        $scope,
        $state,
        $stateParams,
        lodash,
        apiClient,
        crudService,
        messenger,
        documentSetResponse,
        documentSetTypeResponse,
        documentTypeCollectionResponse
    ) {
        var vm = this;
        vm.documentTypeCollectionResponse = documentTypeCollectionResponse;
        vm.documentSetTypeResponse = documentSetTypeResponse;

        vm.create = createAction;

        getDocumentSetTypeIds();

        if (documentTypeCollectionResponse) {
            vm.documentTypesSelect = {
                response: documentTypeCollectionResponse,
                link: documentTypeCollectionResponse.getLink('self'),
                responseKeyPath: 'documentTypes',
                itemValuePath: 'name',
            };
        }

        $scope.$watch('vm.documentTypesSelect.selectedItems', checkDocumentTypes);

        function createAction() {
            if (vm.duplicatedDocumentTypes.length > 0 ) {
                return messenger.error('DUPLICATE_DOCUMENT_TYPES_EXIST');
            }

            var data = {
                _links: {}
            };

            if (vm.documentTypesSelect.selectedItems.length > 0 ) {
                data._links.documenttype = vm.documentTypesSelect.selectedItems;
            }

            var items = {
                types:[]
            };

            lodash.forEach(vm.documentTypesSelect.selectedItems, function(item) {
                var data = {
                    _links: {
                        documenttype:{href: item.href}
                    },
                    isMandatory: item.isMandatory
                };
                items.types.push(data);
            });

            apiClient.create(vm.documentSetTypeResponse.getLink('add-type'), items).then(function (response) {
                if (response) {
                    $state.go('^', $stateParams, { reload: $state.$current.parent.parent.parent.self.name }).then(function () {
                        messenger.success('DOCUMENT_SET_UPDATED');
                    });
                } else {
                    messenger.error('REQUEST_ERROR');
                }
            });
        }

        // Check if selected document type has already been selected
        function checkDocumentTypes() {
            if (vm.documentTypesSelect.selectedItems) {
                vm.duplicatedDocumentTypes = [];
                lodash.forEach(vm.documenListTypeIds,
                    function(response){
                        var duplicate = lodash.find(
                            vm.documentTypesSelect.selectedItems,
                            {id:parseInt(response)}
                        );

                        if (duplicate) {
                            vm.duplicatedDocumentTypes.push(duplicate);
                        }
                    }
                );
            } else {
                vm.duplicatedDocumentTypes = [];
            }
        }

        // Get Array of type Ids currently against the document list, used for duplication check
        function getDocumentSetTypeIds() {
            vm.documenListTypeIds = [];
            var documentSetTypes = vm.documentSetTypeResponse.documentSetTypes;
            lodash.forEach(vm.documentSetTypeResponse.documentSetTypes,
                function(response) {
                     vm.documenListTypeIds.push(response._links.documentType.id);
                }
            );
        }
    }
})();
