/**
 * Panel title
 */
angular.module('elogbooksDirectives').directive('panelTitleSimple', function () {
    return {
        restrict: 'AE',
        replace: true,
        transclude: true,
        templateUrl: '/modules/directives/layout/panel-title-simple/panel-title-simple.html'
    };
});
