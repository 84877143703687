(function () {
    'use strict';

    angular
        .module('elogbooks.admin.sectors')
        .config(registerRoutes)
        .config(registerRoutesList)
        .config(registerRoutesAdd)
        .config(registerRoutesDetails)
        .config(registerRoutesEdit);

    function registerRoutes ($stateProvider) {
        $stateProvider
            .state('dashboard.admin.sectors', {
                parent: 'dashboard.admin',
                abstract: true,
                templateUrl: 'shared-templates/view.html',
                ncyBreadcrumb: {
                    skip: true
                },
                data: {
                    permissions: ['user_permission_view_sectors', 'user_permission_client_admin_manage_sectors']
                }
            });
    }

    function registerRoutesList ($stateProvider) {
        $stateProvider
            .state('dashboard.admin.sectors.list', {
                url: '/sectors?page&limit&order&id&name&active',
                parent: 'dashboard.admin.sectors',
                data: {
                    permissions: ['user_permission_view_sectors', 'user_permission_client_admin_manage_sectors']
                },
                views: {
                    '@dashboard.admin.sectors': {
                        templateUrl: '/modules/admin/sectors/sector-list.html',
                        controller: 'SectorsController',
                        controllerAs: 'vm'
                    }
                },
                resolve: {
                    sectorCollectionResponse: function ($stateParams, apiClient, rootResourceResponse) {
                        return apiClient.get(rootResourceResponse.getLink('sectors'), $stateParams).then(function (response) {
                            return response || apiClient.noResourceFound();
                        });
                    }
                },
                params: {
                    page: '1',
                    limit: '30',
                    order: 'id',
                    id: null,
                    name: null,
                    active: { value: null }
                },
                ncyBreadcrumb: {
                    label: '{{ ::"SECTORS" | translate }}'
                }
            });
    }

    function registerRoutesAdd ($stateProvider) {
        $stateProvider
            .state('dashboard.admin.sectors.list.add', {
                url: '/add',
                parent: 'dashboard.admin.sectors.list',
                data: {
                    permissions: ['user_permission_manage_sectors', 'user_permission_client_admin_manage_sectors']
                },
                views: {
                    '@dashboard.admin.sectors': {
                        templateUrl: '/modules/admin/sectors/add-edit/sector-form.html',
                        controller: 'SectorAddEditController',
                        controllerAs: 'vm'
                    }
                },
                resolve: {
                    sectorResponse: function () {
                        return {};
                    },
                    sectorCollectionResponse: function ($stateParams, apiClient, rootResourceResponse) {
                        return apiClient.getPage(rootResourceResponse.getLink('sectors'), $stateParams);
                    }
                },
                ncyBreadcrumb: {
                    label: '{{ ::"ADD" | translate }}'
                }
            });
    }

    function registerRoutesDetails ($stateProvider) {
        $stateProvider
            .state('dashboard.admin.sectors.list.details', {
                url: '/details/{resource}',
                abstract: true,
                parent: 'dashboard.admin.sectors.list',
                data: {
                    permissions: ['user_permission_view_sectors', 'user_permission_client_admin_manage_sectors']
                },
                views: {
                    '@dashboard.admin.sectors': {
                        templateUrl: '/modules/admin/sectors/details/sector-details.html',
                        controller: 'SectorDetailsController',
                        controllerAs: 'vm'
                    }
                },
                resolve: {
                    sectorResponse: function (apiClient, $stateParams) {
                        return apiClient.get($stateParams.resource.decode());
                    }
                },
                ncyBreadcrumb: {
                    label: '{{ ::$resolves().sectorResponse.name }}'
                }
            })
            .state('dashboard.admin.sectors.list.details.info', {
                url: '/info',
                parent: 'dashboard.admin.sectors.list.details',
                data: {
                    permissions: ['user_permission_view_sectors', 'user_permission_client_admin_manage_sectors']
                },
                views: {
                    '@dashboard.admin.sectors.list.details': {
                        templateUrl: '/modules/admin/sectors/details/info/sector-info.html',
                        controller: 'SectorInfoController',
                        controllerAs: 'vm'
                    }
                },
                ncyBreadcrumb: {
                    label: '{{ ::"INFO" | translate }}'
                }
            })
            .state('dashboard.admin.sectors.list.details.sites', {
                url: '/sites?sitePage&siteLimit&siteOrder&siteName',
                parent: 'dashboard.admin.sectors.list.details',
                data: {
                    permissions: ['user_permission_manage_sectors', 'user_permission_client_admin_manage_sectors'] // BE has manage sectors persmission for the cgetAction endpoint.
                },
                views: {
                    '@dashboard.admin.sectors.list.details': {
                        templateUrl: '/modules/admin/sectors/details/sites/sector-sites.html',
                        controller: 'SectorSitesController',
                        controllerAs: 'vm'
                    }
                },
                resolve: {
                    selectedSiteCollectionResponse: function ($stateParams, apiClient, sectorResponse) {
                        return apiClient.get(sectorResponse.getLink('sites'), angular.extend({}, $stateParams, {
                            page: $stateParams.sitePage,
                            limit: $stateParams.siteLimit,
                            order: $stateParams.siteOrder,
                            id: $stateParams.siteId,
                            name: $stateParams.siteName
                        }));
                    }
                },
                params: {
                    sitePage: '1',
                    siteLimit: '30',
                    siteOrder: 'id',
                    siteId: null,
                    siteName: null,
                },
                ncyBreadcrumb: {
                    label: '{{ ::"SITES" | translate }}'
                }
            })
            .state('dashboard.admin.sectors.list.details.sites.edit', {
                url: '/edit',
                parent: 'dashboard.admin.sectors.list.details.sites',
                data: {
                    permissions: ['user_permission_manage_sectors', 'user_permission_client_admin_manage_sectors']
                },
                views: {
                    '@dashboard.admin.sectors.list.details': {
                        templateUrl: '/modules/admin/sectors/details/sites/sector-sites-form.html',
                        controller: 'SectorSitesEditController',
                        controllerAs: 'vm'
                    }
                },
                resolve: {
                    siteCollectionResponse: function ($stateParams, rootResourceResponse, apiClient, user, userManager) {
                        var params = angular.extend({}, $stateParams, {'datagroup': 'search', 'page': 1});

                        if (userManager.hasPermission('user_permission_manage_sites')) {
                            return apiClient.getPage(rootResourceResponse.getLink('sites'), params);
                        }

                        return apiClient.getPage(user.getLink('sites'), params);
                    },
                    selectedSiteCollectionResponse: function(apiClient, sectorResponse) {
                        return apiClient.get(sectorResponse.getLink('sites'), { limit: 999999 }) || null;
                    },
                },
                ncyBreadcrumb: {
                    label: '{{ ::"EDIT" | translate }}'
                }
            })
            .state('dashboard.admin.sectors.list.details.document-sets', {
                url: '/document-sets?dlPage&dlLimit&dlOrder&dlId&dlName',
                parent: 'dashboard.admin.sectors.list.details',
                data: {
                    permissions: ['user_permission_view_sectors', 'user_permission_view_document_types', 'user_permission_client_admin_manage_sectors'] // Documents sets controller has document types permissions.
                },
                views: {
                    '@dashboard.admin.sectors.list.details': {
                        templateUrl: '/modules/common/document-sets/list/document-sets.html',
                        controller: 'CommonDocumentSetsController',
                        controllerAs: 'vm'
                    }
                },
                resolve: {
                    selectedDocumentSetCollectionResponse: function ($stateParams, apiClient, sectorResponse) {
                        if (sectorResponse.getLink('document-sets')) {
                            return apiClient.get(sectorResponse.getLink('document-sets'), angular.extend({}, $stateParams, {
                                page: $stateParams.dlPage,
                                limit: $stateParams.dlLimit,
                                order: $stateParams.dlOrder,
                                id: $stateParams.dlId,
                                name: $stateParams.dlName
                            })).then(function (response) {
                                return response || apiClient.noResourceFound();
                            });
                        } else {
                            return null;
                        }
                    },
                    config: function() {
                        return {
                            entityName: 'SECTOR_DOCUMENT_SETS',
                            addSref: 'dashboard.admin.sectors.list.details.document-sets.add'
                        };
                    },
                },
                params: {
                    dlPage: '1',
                    dlLimit: '30',
                    dlOrder: 'id',
                    dlId: null,
                    dlName: null
                },
                ncyBreadcrumb: {
                    label: '{{ ::"DOCUMENT_SETS" | translate }}'
                }
            })
            .state('dashboard.admin.sectors.list.details.document-sets.add', {
                url:'/add',
                parent: 'dashboard.admin.sectors.list.details.document-sets',
                data: {
                    permissions: ['user_permission_manage_sectors', 'user_permission_manage_document_types', 'user_permission_client_admin_manage_sectors'] // Documents sets controller has document types permissions.
                },
                views: {
                    '@dashboard.admin.sectors.list.details': {
                        templateUrl: '/modules/common/document-sets/add/document.set.form.html',
                        controller: 'CommonDocumentSetAddController',
                        controllerAs: 'vm'
                    }
                },
                resolve: {
                    documentSetCollectionResponse : function (apiClient, rootResourceResponse) {
                        return apiClient.get(rootResourceResponse.getLink('documentsets'), {status: 'active'})
                            .then(function (response) {
                                return response || apiClient.noResourceFound();
                            });
                    },
                    selectedDocumentSetCollectionResponse : function (apiClient, sectorResponse) {
                        return apiClient.get(sectorResponse.getLink('document-sets'))
                            .then(function (response) {
                                return response || apiClient.noResourceFound();
                            });
                    },
                },
                ncyBreadcrumb: {
                    label: '{{ ::"DOCUMENT_SET_ADD" | translate }}'
                }
            })
        ;
    }

    function registerRoutesEdit ($stateProvider) {
        $stateProvider
            .state('dashboard.admin.sectors.list.details.info.edit', {
                url: '/edit',
                parent: 'dashboard.admin.sectors.list.details.info',
                data: {
                    permissions: ['user_permission_manage_sectors', 'user_permission_client_admin_manage_sectors']
                },
                views: {
                    '@dashboard.admin.sectors.list.details': {
                        templateUrl: '/modules/admin/sectors/add-edit/sector-form.html',
                        controller: 'SectorAddEditController',
                        controllerAs: 'vm'
                    }
                },
                ncyBreadcrumb: {
                    label: '{{ ::"EDIT" | translate }}'
                }
            });
    }

})();
