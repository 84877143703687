(function() {
    'use strict';

    angular
        .module('elogbooksFilters')
        .filter('elbIntervalToReadable', ElogbooksIntervalToReadable);

    ElogbooksIntervalToReadable.$inject = ['$translate'];

    function ElogbooksIntervalToReadable($translate) {
        return function(value, type) {
            type = type || "minute";

            var secondsInA = {
                second: 1,
                minute: 60,
                hour: 3600,
                day: 86400,
                week: 604800
            };

            if (!secondsInA[type]) {
                return value;
            }

            // Longest frequency must go at the top in order that the
            // correct readable version can be produced
            // ie- 2 weeks 3 days 4 hours instead of 412 hours
            var durationMapping =
                [
                    { type: 'week', value: secondsInA.week },
                    { type: 'day', value: secondsInA.day },
                    { type: 'hour', value: secondsInA.hour },
                    { type: 'minute', value: secondsInA.minute },
                    { type: 'second', value: secondsInA.second },
                ],
                seconds = value * secondsInA[type],
                readable = [];

            angular.forEach(durationMapping, function(period, $index) {
                if (seconds >= period.value) {
                    var interval = Math.floor(seconds / period.value);
                    seconds -= interval * period.value;
                    readable.push($translate.instant('FREQUENCY_' + period.type.toUpperCase() + 'S', {'interval': interval}));
                }
            });

            return readable.join(' ');
        };
    }
})();
