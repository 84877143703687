(function(){
    'use strict';

    angular
        .module('elogbooks.common.set-password')
        .config(registerRoutes);

    function registerRoutes($stateProvider) {

        $stateProvider
            .state('set-password', {
                url: "/set-password?token",
                templateUrl: '/modules/common/set-password/set-password.html',
                controller: 'SetPasswordController',
                controllerAs: 'vm',
                resolve: {
                    rootResource: function (apiClient, $rootScope) {
                        return apiClient.get('/').then(function(response) {
                            $rootScope.theme = response?.theme;
                            return response;
                        });
                    },
                    config: function() {
                        return {}
                    }
                }
            })
            .state('account-unlock', {
                url: "/account-unlock?token",
                templateUrl: '/modules/common/set-password/set-password.html',
                controller: 'SetPasswordController',
                controllerAs: 'vm',
                resolve: {
                    rootResource: function (apiClient, $rootScope) {
                        return apiClient.get('/').then(function(response) {
                            $rootScope.theme = response?.theme;
                            return response;
                        });
                    },
                    config: function() {
                        return {
                            accountUnlock: true
                        }
                    }
                }
            });
    }
})();
