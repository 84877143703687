angular.module('elogbooksServices').service('bulkReassignJobsModal', ['apiClient', '$uibModal', function (apiClient, $uibModal) {
    return {
        open: function (jobs, rootResourceResponse, userResponse, selectedSiteResponse, jobsCount, selectedSP, config, selected, canBulkReassignOperative) {
            return $uibModal.open({
                templateUrl: '/modules/common/modals/bulk-update/bulkaction-modal.html',
                controller: 'BulkActionCreateController',
                controllerAs: 'vm',
                size: 'bulk-actions',
                resolve: {
                    config: function () {
                        var defaultConfig = {
                            titleMessage: 'REASSIGN',
                            bodyMessage: 'ARE_YOU_SURE',
                            updateMessage: 'JOBS_QUEUED_FOR_REASSIGNMENT',
                            selected: selected,
                            bulkAsssigntype: 'reassign'
                        };
                        return angular.extend({}, defaultConfig, config);
                    },
                    serviceProviderResponse: function(apiClient) {
                        var serviceProviderLink = (selectedSiteResponse === null) ?
                            userResponse.getLink('serviceproviders') : selectedSiteResponse.getLink('serviceproviders');

                        return apiClient.get(serviceProviderLink, {active: 1}).then(function(response) {
                            return response;
                        });
                    },
                    jobs: function() {
                        return jobs;
                    },
                    jobsCount: function() {
                        return jobsCount;
                    },
                    selectedSite: function() {
                        return selectedSiteResponse;
                    },
                    selectedSP: function() {
                        return selectedSP;
                    },
                    rootResourceResponse: function() {
                        return rootResourceResponse;
                    },
                    user: function() {
                        return userResponse;
                    },
                    canBulkReassignOperative: function() {
                        return canBulkReassignOperative;
                    }
                }
            });
        }
    };
}]);
