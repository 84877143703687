;(function() {
    'use strict';

    angular
        .module('elogbooks.admin.forms')
        .controller('FormDetailsController', FormDetailsController);

    FormDetailsController.$inject = ['formResponse'];

    function FormDetailsController(formResponse) {
        var vm = this;
        vm.formResponse = formResponse;
    }
})();
