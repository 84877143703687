(function() {
    'use strict';

    angular
        .module('elogbooks.admin.site-relationships')
        .config(registerRoutes)
        .config(registerRoutesList)
        .config(registerRoutesAdd)
        .config(registerRoutesDetails)
        .config(registerRoutesEdit);

    function registerRoutes($stateProvider) {
        $stateProvider
            .state('dashboard.admin.site-relationships', {
                abstract: true,
                templateUrl: 'shared-templates/view.html',
                ncyBreadcrumb: {
                    skip: true
                },
                data: {
                    permissions: ['user_permission_manage_site_relationships']
                }
            });
    }

    function registerRoutesList($stateProvider) {
        $stateProvider
            .state('dashboard.admin.site-relationships.list', {
                url: '/site-relationships?page&limit&order&id',
                parent: 'dashboard.admin.site-relationships',
                data: {
                    permissions: ['user_permission_manage_site_relationships']
                },
                views: {
                    '@dashboard.admin.site-relationships': {
                        templateUrl: '/modules/admin/site-relationships/site-relationship-list.html',
                        controller: 'SiteRelationshipsController',
                        controllerAs: 'vm'
                    }
                },
                params: {
                    page: '1',
                    limit: '30',
                    order: 'id',
                    id: null
                },
                resolve: {
                    siteRelationshipsCollectionResponse: function($stateParams, apiClient, rootResourceResponse) {
                        return apiClient.get(rootResourceResponse.getLink('siterelationships'), $stateParams).then( function(response) {
                            return response || apiClient.noResourceFound();
                        });
                    }
                },
                ncyBreadcrumb: {
                    label: '{{ ::"SITE_RELATIONSHIP_LIST" | translate }}'
                }
            });
    }

    function registerRoutesAdd($stateProvider) {
        $stateProvider
            .state('dashboard.admin.site-relationships.list.add', {
                url: '/add',
                parent: 'dashboard.admin.site-relationships.list',
                data: {
                    permissions: ['user_permission_manage_site_relationships']
                },
                views: {
                    '@dashboard.admin.site-relationships': {
                        templateUrl: '/modules/admin/site-relationships/add-edit/site-relationship-add-edit.html',
                        controller: 'SiteRelationshipsAddEditController',
                        controllerAs: 'vm'
                    }
                },
                resolve: {
                    siteRelationshipResponse: function() {
                        return {};
                    }
                },
                ncyBreadcrumb: {
                    label: '{{ ::"ADD" | translate }}'
                }
            });
    }

    function registerRoutesDetails($stateProvider) {
        $stateProvider
            .state('dashboard.admin.site-relationships.list.details', {
                url: '/details/{resource}',
                abstract: true,
                parent: 'dashboard.admin.site-relationships.list',
                data: {
                    permissions: ['user_permission_manage_site_relationships']
                },
                views: {
                    '@dashboard.admin.site-relationships': {
                        templateUrl: '/modules/admin/site-relationships/details/site-relationship-details.html',
                        controller: 'SiteRelationshipsDetailsController',
                        controllerAs: 'vm'
                    }
                },
                resolve: {
                    siteRelationshipResponse: function(apiClient, $stateParams) {
                        return apiClient.get($stateParams.resource.decode()).then( function(response) {
                            return response || apiClient.noResourceFound();
                        });
                    }
                },
                ncyBreadcrumb: {
                    label: '{{ ::$resolves().siteRelationshipResponse.name }}'
                }
            })
            .state('dashboard.admin.site-relationships.list.details.info', {
                url: '/info',
                parent: 'dashboard.admin.site-relationships.list.details',
                data: {
                    permissions: ['user_permission_manage_site_relationships']
                },
                views: {
                    '@dashboard.admin.site-relationships.list.details': {
                        templateUrl: '/modules/admin/site-relationships/details/info/site-relationship-info.html',
                        controller: 'SiteRelationshipsInfoController',
                        controllerAs: 'vm'
                    }
                },
                ncyBreadcrumb: {
                    label: '{{ ::"INFO" | translate }}'
                }
            });
    }

    function registerRoutesEdit($stateProvider) {
        $stateProvider
            .state('dashboard.admin.site-relationships.list.details.info.edit', {
                url: '/edit',
                parent: 'dashboard.admin.site-relationships.list.details.info',
                data: {
                    permissions: ['user_permission_manage_site_relationships']
                },
                views: {
                    '@dashboard.admin.site-relationships.list.details': {
                        templateUrl: '/modules/admin/site-relationships/add-edit/site-relationship-add-edit.html',
                        controller: 'SiteRelationshipsAddEditController',
                        controllerAs: 'vm'
                    }
                },
                ncyBreadcrumb: {
                    label: '{{ ::"EDIT" | translate }}'
                }
            });
    }

})();
