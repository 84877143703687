(function () {
    'use strict';

    angular
        .module('elogbooks.user.contracts')
        .controller('CommonContractDetailsEventFeedController', CommonContractDetailsEventFeedController);

    CommonContractDetailsEventFeedController.$inject = ['eventCollectionResponse', 'config', 'userManager', 'contractResponse'];

    function CommonContractDetailsEventFeedController (eventCollectionResponse, config, contractResponse) {
        var vm = this;
            vm.feed = [];
            vm.eventCollectionResponse = eventCollectionResponse;
            vm.messageCollectionResponse = {};
            vm.messageCollectionResponse.messages = [];
            vm.config = config;
            vm.entityResponse = contractResponse;
    }
})();
