(function () {
    'use strict';

    angular
        .module('elogbooks.common.audit')
        .controller('AuditDetailsController', AuditDetailsController);

    AuditDetailsController.$inject = [
        '$rootScope',
        'lodash',
        'auditResponse',
        'auditService',
        '$state',
        'messenger',
        'confirmationModal',
        'userManager',
        'apiClient'
    ];
    function AuditDetailsController(
        $rootScope,
        lodash,
        auditResponse,
        auditService,
        $state,
        messenger,
        confirmationModal,
        userManager,
        apiClient
    ) {
        var vm = this;
        vm.audit = auditResponse;
        vm.activateAudit = activateAudit;
        vm.cancelAudit = cancelAudit;
        vm.createNewVersion = createNewVersion;
        vm.activateTemplate = activateTemplate;
        vm.deActivateTemplate = deActivateTemplate;
        vm.userCanEdit = lodash.has(vm.audit, '_links.edit.href');

        vm.startAudit = startAudit;
        vm.editReference = editReference;
        vm.completeAudit = completeAudit;
        vm.queueReport = queueReport;
        vm.queueBlankForm = queueBlankForm;

        vm.canActivateAudit = vm.audit._links.activate && (((vm.audit.status === 'AUDIT_SCHEDULE_INACTIVE' || vm.audit.status === 'AUDIT_DRAFT') && vm.audit.questionCount > 0) ? true : false);
        vm.canSaveAsTemplate = userManager.hasPermission('site_permission_create_audit_templates') && !auditResponse.isTemplate;
        vm.canCopyTemplate = userManager.hasPermission('site_permission_create_audit_templates') && auditResponse.isTemplate;

        if (vm.audit.instruction
            && vm.audit.status == 'AUDIT_CREATED'
            && $rootScope.previousState != $state.current.name
            && !auditResponse.auditInstructionConfirmed) {
            auditInstructionPopup();
        }

        function queueReport() {
            return apiClient.get(auditResponse.getLink('pdf')).then(function (){
                messenger.success('REPORT_QUEUED');
            });
        }

        function queueBlankForm() {
            return apiClient.get(auditResponse.getLink('blankauditform')).then(function () {
                messenger.success('BLANK_AUDIT_FORM_PDF_QUEUED');
            });
        }

        function createNewVersion() {
           return confirmationModal.open().result.then(function(response) {
                return apiClient.create(auditResponse.getLink('create-version'), {}).then(function(createResponse) {
                    if (createResponse) {
                        $state.go('.', {auditResource: createResponse.getLink('self').encode()}, {reload: $state.get('^.^')}).then(function() {
                            messenger.success('AUDIT_TEMPLATE_NEW_VERSION_CREATED');
                        });
                    } else {
                        messenger.error('REQUEST_ERROR');
                    }
                });
            });
        }

        function activateTemplate() {
           return confirmationModal.open().result.then(function(response) {
                return apiClient.create(auditResponse.getLink('activate-version'), {}).then(function(activateResponse) {
                    if (activateResponse) {
                        $state.go('.', {auditResource: activateResponse.getLink('self').encode()}, {reload: $state.get('^.^')}).then(function() {
                            messenger.success('AUDIT_TEMPLATE_VERSION_ACTIVATED');
                        });
                    } else {
                        messenger.error('REQUEST_ERROR');
                    }
                });
            });
        }

        function deActivateTemplate() {
           return confirmationModal.open().result.then(function(response) {
                return apiClient.create(auditResponse.getLink('deactivate-version'), {}).then(function(activateResponse) {
                    if (activateResponse) {
                        $state.go('.', {auditResource: activateResponse.getLink('self').encode()}, {reload: $state.get('^.^')}).then(function() {
                            messenger.success('AUDIT_TEMPLATE_VERSION_DEACTIVATED');
                        });
                    } else {
                        messenger.error('REQUEST_ERROR');
                    }
                });
            });
        }

        function auditInstructionPopup() {
            confirmationModal.open({
                titleMessage: 'AUDIT_INSTRUCTION',
                htmlBodyMessage: vm.audit.instruction,
                primaryText: 'OK',
                primaryClass: 'btn-primary',
                secondaryText: ''
            })
            .result.then(function() {
                auditResponse.auditInstructionConfirmed = true;
            });
        }

        function activateAudit() {
            auditService.activate(vm.audit).then(function () {
                $state.go('.', {}, { reload: $state.get('^.^') }).then(function () {
                    messenger.success('AUDIT_UPDATED_TO_ACTIVATED');
                });
            }, function () {
                messenger.error('REQUEST_ERROR');
            });
        }

        function startAudit() {
            auditService.start(vm.audit).then(function () {
                $state.go('^.sections', {}, { reload: $state.get('^.^') }).then(function () {
                    messenger.success('AUDIT_UPDATED_TO_STARTED');
                });
            }, function () {
                messenger.error('REQUEST_ERROR');
            });
        }

        function editReference() {
            auditService.editReference(vm.audit).then(function () {
                $state.go('.', {}, { reload: $state.get('^.^') }).then(function () {
                    messenger.success('AUDIT_UPDATED');
                });
            }, function () {
                messenger.error('REQUEST_ERROR');
            });
        }

        function cancelAudit() {
            auditService.cancel(vm.audit).then(function () {
                $state.go('.', {}, { reload: $state.get('^.^') }).then(function () {
                    messenger.success('AUDIT_UPDATED_TO_CANCELLED');
                });
            }, function () {
                messenger.error('REQUEST_ERROR');
            });
        }

        function completeAudit() {
            var answersComplete = (vm.audit.answeredQuestionCount === vm.audit.questionCount);

            if (vm.audit.getLink('force-complete') || answersComplete === true) {
                auditService.complete(vm.audit).then(function () {
                    $state.go('.', {}, { reload: $state.get('^.^') }).then(function () {
                        messenger.success('AUDIT_UPDATED_TO_COMPLETED');
                    });
                }, function () {
                    messenger.error('REQUEST_ERROR');
                });
            }
            else if (answersComplete === false) {
                messenger.error('NOT_ALL_QUESTIONS_ANSWERED');
            }
        }
    }
})();
