(function () {
    'use strict';

    angular
        .module('elogbooks.user.patrols')
        .controller('PatrolPointsAddEditController', PatrolPointsAddEditController);

    PatrolPointsAddEditController.$inject = ['$state', 'apiClient', 'messenger', 'requestDataFactory', 'patrolPointResponse', 'patrolPointsCollection', 'patrolResponse', 'locationsCollection', 'patrolPointRequirementTypes', 'confirmationModal', 'genericPointCollectionResponse', '$filter'];

    function PatrolPointsAddEditController ($state, apiClient, messenger, requestDataFactory, patrolPointResponse, patrolPointsCollection, patrolResponse, locationsCollection, patrolPointRequirementTypes, confirmationModal, genericPointCollectionResponse, $filter) {
        var vm = this;
        vm.patrolPoint = patrolPointResponse;
        if (genericPointCollectionResponse) {
            vm.genericPointCollectionResponse = genericPointCollectionResponse;
            vm.genericPointCollectionResponse.patrolPoints = genericPointCollectionResponse.patrolPoints.filter(function (value) {
                return value.active;
            });
        }
        vm.locations = locationsCollection;
        vm.requirementTypes = patrolPointRequirementTypes.getAll();
        vm.status = [
            { value: true, title: $filter('translate')("STATUS_ACTIVE") },
            { value: false, title: $filter('translate')("STATUS_INACTIVE") }
        ];
        vm.data = requestDataFactory.createRequest(getDefaults());
        vm.create = createAction;
        vm.update = updateAction;
        vm.delete = deleteAction;
        vm.showRequirementId = showRequirementId;

        if (genericPointCollectionResponse) {
            vm.genericPointSelect = {
                response: vm.genericPointCollectionResponse,
                link: vm.genericPointCollectionResponse.getLink('self'),
                responseKeyPath: 'patrolPoints',
                itemValuePath: 'summary',
                searchKey: 'patrolPointSummary',
                onSelect: changePoint,
                onRemove: removePoint,
                required: false
            };
        }

        function showRequirementId() {
            return ['barcode', 'nfc'].indexOf(vm.data.requirementType) !== -1;
        }

        function changePoint() {
            if (typeof vm.genericPointSelect.selected !== 'undefined') {
                apiClient.get(vm.genericPointSelect.selected.href).then(function (response) {
                    vm.data._links.createdFromPoint = response._links.self;

                    vm.data.summary = response.summary;
                    vm.data.instruction = response.instruction;
                    vm.data.timeAllowed = response.timeAllowed;
                    vm.data.requirementType = response.requirementType;
                    vm.data.requirementId = response.requirementId;

                    vm.data._links.location = response._links.location;
                    vm.data._links.location.fullPathString = JSON.parse(response._links.location.fullPathString);
                });
            }
        }

        function removePoint() {
            delete vm.data._links.createdFromPoint;
            delete vm.data._links.location;
            vm.data.summary = null;
            vm.data.instruction = null;
            vm.data.timeAllowed = null;
            vm.data.requirementType = null;
            vm.data.requirementId = null;
        }

        function getDefaults() {
            return {
                _links: {
                    location: vm.patrolPoint._links ? vm.patrolPoint._links.location : null,
                    patrol: vm.patrolPoint._links ? vm.patrolPoint._links.patrol : patrolResponse._links.self,
                    createdFromPoint: vm.patrolPoint._links ? vm.patrolPoint._links.createdFromPoint : null
                },
                id: vm.patrolPoint.id || null,
                summary: vm.patrolPoint.summary || null,
                instruction: vm.patrolPoint.instruction || null,
                timeAllowed: vm.patrolPoint.timeAllowed || null,
                displayOrder: vm.patrolPoint.displayOrder || (patrolPointsCollection.count + 1),
                requirementType: vm.patrolPoint.requirementType || null,
                requirementId: vm.patrolPoint.requirementId || null,
                active: vm.patrolPoint.active
            };
        }

        function createAction() {
            apiClient.create(patrolPointsCollection.getLink('create'), vm.data).then(function (response) {
                if (response) {
                    $state.go('dashboard.user.patrols.manage-patrols.details.route.list', {}, { reload: true }).then(function () {
                        messenger.success('PATROLPOINT_CREATED');
                    });
                } else {
                    messenger.error('REQUEST_ERROR');
                }
            });
        }

        function updateAction() {
            apiClient.update(vm.patrolPoint._links.self.href, vm.data).then(function (response) {
                if (response) {
                    $state.go('dashboard.user.patrols.manage-patrols.details.route.list', {}, { reload: true }).then(function () {
                        messenger.success('PATROLPOINT_UPDATED');
                    });
                } else {
                    messenger.error('REQUEST_ERROR');
                }
            });
        }

        function deleteAction() {
            return confirmationModal.open().result.then(
                function () {
                    return apiClient.delete(vm.patrolPoint.getLink('delete')).then(function (response) {
                        if (response) {
                            $state.go('dashboard.user.patrols.manage-patrols.details.route.list', {}, { reload: true }).then(function () {
                                messenger.success('PATROLPOINT_DELETED');
                            });
                        } else {
                            messenger.error('REQUEST_ERROR');
                        }
                    });
                },
                function () {
                    return;
                }
            );
        }
    }
})();
