(function() {
    'use strict';

    angular
        .module('elogbooks.user.timesheets')
        .controller('UserTimesheetsAddLineEntryController', UserTimesheetsAddLineEntryController);

    UserTimesheetsAddLineEntryController.$inject = [
        '$state',
        'timesheetTypesCollectionResponse',
        'timesheetData',
        'timesheetWeek',
        'requestDataFactory',
        'crudService'
    ];

    function UserTimesheetsAddLineEntryController(
        $state,
        timesheetTypesCollectionResponse,
        timesheetData,
        timesheetWeek,
        requestDataFactory,
        crudService
    ) {
        var vm = this;

        vm.timesheetTypesCollectionResponse = timesheetTypesCollectionResponse;
        vm.timesheetData = timesheetData;
        vm.timesheetWeek = timesheetWeek;
        vm.timesheetTypeModel = {};
        vm.minutesHH = 0;
        vm.minutesMM = 0;
        vm.minDate =  moment(new Date(vm.timesheetWeek.startWeekDate)).format('MM-DD-YYYY');
        vm.maxDate =  moment(new Date(vm.timesheetWeek.startWeekDate)).add(6, 'days').format('MM-DD-YYYY');
        vm.data = requestDataFactory.createRequest(getDefaults());
        vm.data.date = vm.minDate;

        vm.create = createAction;
        vm.inputChange = inputChange;

        if (timesheetTypesCollectionResponse) {
            vm.timesheetTypeModel = {
                response: timesheetTypesCollectionResponse,
                link: timesheetTypesCollectionResponse.getLink('self'),
                responseKeyPath: 'timesheettypes',
                itemHrefPath: '_links.self.href',
                itemValuePath: 'name'
            };
        }

        function getDefaults() {
            return {
                _links: {
                    timesheetType: null,
                    user: null
                }
            }
        }

        function inputChange() {
            if (vm.minutesMM < 0) {
                vm.minutesMM = 0;
            }

            if (vm.minutesMM > 59) {
                vm.minutesMM = 59;
            }
        }

        function createAction() {
            if (!vm.timesheetTypeModel.selected) {
                return false;
            }

            vm.data._links = {};

            if (vm.timesheetTypeModel.selected) {
                vm.data._links.timesheetType =  { href: vm.timesheetTypeModel.selected.href };
            }

            if (vm.timesheetWeek._links.user.href) {
                vm.data._links.user = { href: vm.timesheetWeek._links.user.href };
            }

            vm.data.minutes = (vm.minutesHH * 60) + vm.minutesMM;

            return crudService.create(
                vm.timesheetData.getLink('create-entryline'),
                vm.data,
                $state.get('^'),
                null,
                'CREATE_ENTRY_LINE',
                false
            );
        }
    }
})();
