app.config(function ($translateProvider) {
    $translateProvider.translations('zh-hk', {
        SECTOR: '部門',
        SECTORS: '部門',

        SECTOR_INFORMATION: '部門資料',
        MANAGE_SECTOR_SITES: '此部門的場地',

        ADD_NEW_SECTOR: '新增部門',
        EDIT_SECTOR: '修改部門',

        SECTOR_LIST: '部門',
        SECTOR_ADD: '新增部門',
        SECTOR_EDIT: '修改部門',

        SECTOR_CREATED: '部門已建立',
        SECTOR_UPDATED: '部門已更新',
        SECTOR_DELETED: '部門已刪除',

        SECTOR_SITE_LIST: '場地',
        SECTOR_SITE_ADDED: '場地部門已增加',
        SITES_DELETED_FROM_SECTOR: '場地部門已刪除',
    });
});
