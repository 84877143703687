(function(){
    'use strict';

    angular
        .module('elogbooks.common.change-password')
        .config(registerRoutes);

    function registerRoutes($stateProvider) {

        $stateProvider
            .state('dashboard.change-password', {
                url: "/change-password",
                templateUrl: '/modules/common/change-password/change-password.html',
                controller: 'ChangePasswordController',
                controllerAs: 'vm',
                resolve: {
                    rootResource: function (apiClient, $rootScope) {
                        return apiClient.get('/').then(function(response) {
                            $rootScope.theme = response?.theme;
                            return response;
                        });
                    }
                },
                ncyBreadcrumb: {
                    skip: true
                }
            });

    }
})();
