(function() {
    'use strict';

    angular
        .module('elogbooks')
        .config(Translations);

    function Translations($translateProvider) {
        $translateProvider.translations('en-gb', {
            CONTRACT_CREATED_ACTION: '<strong>{{ event._links["user"].title }}</strong> created the Contract',
            CONTRACT_SUBMITTED_ACTION: '<strong>{{ event._links["user"].title }}</strong> submited the Contract',
            CONTRACT_CANCELLED_ACTION: '<strong>{{ event._links["user"].title }}</strong> cancelled the Contract',
            CONTRACT_TERMINATED_ACTION: '<strong>{{ event._links["user"].title }}</strong> terminated the Contract',
            CONTRACT_APPROVAL_REJECTED_ACTION: '<strong>{{ event._links["user"].title }}</strong> rejected the Contract',
            CONTRACT_REGRESSED_ACTION: '<strong>{{ event._links["user"].title }}</strong> regressed the Contract',
            CONTRACT_APPROVAL_ACCEPTED_ACTION: '<strong>{{ event._links["user"].title }}</strong> approved the Contract',
            CONTRACT_UPDATED_ACTION: '<strong>{{ event._links["user"].title }}</strong> updated the Contract',
            CONTRACT_RENEWED_DRAFT_ACTION: '<strong>{{ event._links["user"].title }}</strong> renewed the Contract',
            CONTRACT_RENEWED_APPROVED_ACTION: '<strong>{{ event._links["user"].title }}</strong> approved the Contract renewal',
            CONTRACT_RENEWED_REJECTED_ACTION: '<strong>{{ event._links["user"].title }}</strong> rejected the Contract renewal',
            CONTRACT_RENEWED_SUBMITTED_ACTION: '<strong>{{ event._links["user"].title }}</strong> submitted the Contract renewal',
            CONTRACT_EXPIRED_SYSTEM_ACTION: 'The system automatically expired the Contract',
            CONTRACT_OPERATION_APPROVAL_CHASED_SYSTEM_ACTION: 'Approval chase #{{ ::event.chaseSequence + 1 }} notification has been sent',
            CONTRACT_OPERATION_RENEWAL_APPROVAL_CHASED_SYSTEM_ACTION: 'Renewal Approval chase #{{ ::event.chaseSequence + 1 }} notification has been sent',
            CONTRACT_OPERATION_EXPIRE_CHASED_SYSTEM_ACTION: 'Expire chase #{{ ::event.chaseSequence + 1 }} notification has been sent',
            CONTRACT_REVIEW_NOTIFICATION_SYSTEM_ACTION: '<strong>{{ event.daysBefore }} day</strong> review notification has been sent',
            CONTRACT_EXPIRY_NOTIFICATION_SYSTEM_ACTION: '<strong>{{ event.daysBefore }} day</strong> expiry notification has been sent'
        });
    }
})();
