(function () {
    'use strict';

    angular
        .module('elogbooks.admin.sites')
        .controller('QuoteThresholdAddEditController', QuoteThresholdAddEditController);

    QuoteThresholdAddEditController.$inject = ['quoteThresholdResponse', 'quoteThresholdsCollectionResponse', 'requestDataFactory', 'confirmationModal', 'apiClient', '$state', 'messenger'];

    function QuoteThresholdAddEditController(quoteThresholdResponse, quoteThresholdsCollectionResponse, requestDataFactory, confirmationModal, apiClient, $state, messenger) {
        var vm = this;
        vm.threshold = quoteThresholdResponse;
        vm.data = requestDataFactory.createRequest(getDefaults());

        vm.create = createAction;
        vm.update = updateAction;
        vm.delete = deleteAction;

        function getDefaults() {
            return {
                value: vm.threshold.value || null,
            };
        }

        // can't use crudservice methods as we need a custom reload route: site details
        // this is required as thresholds are shown in associates panel on site info view

        function createAction () {
            apiClient.create(quoteThresholdsCollectionResponse.getLink('create'), vm.data).then(function (response) {
                if (response) {
                    $state.go('^', {}, {reload: $state.get('^.^.^.^')}).then(function () {
                        messenger.success('QUOTE_THRESHOLD_CREATED');
                    });
                } else {
                    messenger.error('REQUEST_ERROR');
                }
            });
        }

        function updateAction () {
            apiClient.update(vm.threshold.getLink('edit'), vm.data).then(function (response) {
                if (response) {
                    $state.go('^', {}, {reload: $state.get('^.^.^.^')}).then(function () {
                        messenger.success('QUOTE_THRESHOLD_UPDATED');
                    });
                } else {
                    messenger.error('REQUEST_ERROR');
                }
            });
        }

        function deleteAction () {
            return confirmationModal.open().result.then(function() {
                return apiClient.delete(vm.threshold.getLink('delete')).then(function (response) {
                    if (response) {
                        $state.go('^.^.^', {}, {reload: $state.get('^.^.^.^')}).then(function () {
                            messenger.success('QUOTE_THRESHOLD_DELETED');
                        });
                    } else {
                        messenger.error('REQUEST_ERROR');
                    }
                })
            });
        }
    }
})();
