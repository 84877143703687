(function(){
    'use strict';

    angular
        .module('elogbooks.helpdesk')
        .controller('HelpdeskLayoutController', HelpdeskLayoutController);

    function HelpdeskLayoutController($scope) {

    }
})();
