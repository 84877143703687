/**
 * Tentant Details Controller
 */
(function () {
    'use strict';

    angular
        .module('elogbooks.user.tenants')
        .controller('TenantDetailsController', TenantDetailsController);

    TenantDetailsController.$inject = ['lodash', 'contactResponse'];

    function TenantDetailsController(lodash, contactResponse) {
        var vm = this;
        vm.tenant = contactResponse;
    }

})();
