app.config(function ($translateProvider) {
    $translateProvider.translations('nl', {
        PARTNERSHIP: 'Samenwerking',
        PARTNERSHIPS: 'Samenwerkingen',

        ADD_NEW_PARTNERSHIP: 'Maak een nieuwe samenwerking',

        PARTNERSHIP_ADD: 'Voeg een nieuwe samenwerking toe',
        PARTNERSHIPS_ADD: 'Voeg nieuwe samenwerkingen toe',
        PARTNERSHIPS_LIST: 'Samenwerkingen',

        SERVICE_PROVIDER_PARTNERSHIP_ADD: 'Voeg een nieuwe samenwerking toe',

        PARTNERSHIP_LIST: 'Samenwerkingen',
        PARTNERSHIP_EDIT: 'Wijzig samenwerkingen',

        PARTNERSHIP_CREATED: 'Samenwerking gemaakt',
        PARTNERSHIP_UPDATED: 'Samenwerking gewijzigd',
        PARTNERSHIP_DELETED: 'Samenwerking verwijderd',

        PARTNERSHIP_SURVEYS_ADDED: 'Surveys toegevoegd',
        PARTNERSHIP_SURVEYS_DELETED: 'Surveys verwijderd',

        PARTNERSHIP_STATUS: 'Partnership Status',

        SURVEYS_ADDED: 'Surveys toegevoegd',
        SURVEYS_DELETED: 'Surveys verwijderd' 
    });
});
