(function () {
    'use strict';

    angular
        .module('elogbooks.common.audit')
        .controller('AuditAnswerOptionsController', AuditAnswerOptionsController);

    AuditAnswerOptionsController.$inject = [
        '$state',
        'lodash',
        '$stateParams',
        'apiClient',
        'auditResponse',
        'questionResponse',
        'answerOptionsResponse',
        'messenger',
        'sortableOptions',
        '$filter',
        'auditService'
    ];

    function AuditAnswerOptionsController(
        $state,
        lodash,
        $stateParams,
        apiClient,
        auditResponse,
        questionResponse,
        answerOptionsResponse,
        messenger,
        sortableOptions,
        $filter,
        auditService
    ) {
        var vm = this;
        vm.audit = auditResponse;
        vm.question = questionResponse;
        vm.answerOptions = answerOptionsResponse.answerOptions;
        vm.answerOptionsResponse = answerOptionsResponse;
        vm.haveAnswerOptionsChanged = false; // Order of sections has changed
        vm.submit = updateAnswerOptions;
        vm.resetAnswerOptions = resetAnswerOptions;
        vm.userCanEdit = lodash.has(vm.audit, '_links.edit.href');
        vm.noAnswerOptionsMsg = $filter('translate')('NO_ANSWER_OPTIONS');

        var originalAnswerOptions = angular.copy(vm.answerOptions);

        // ng-sortable parameters
        vm.sortableOptions = sortableOptions.addProperty({
            orderChanged: function(event) {
                vm.haveAnswerOptionsChanged = auditService.updatePoints(vm.answerOptions, vm.haveAnswerOptionsChanged);
            }
        });

        // Reset the order of the answer Options back to original order
        function resetAnswerOptions () {
            vm.answerOptions = angular.copy(originalAnswerOptions);
            vm.haveAnswerOptionsChanged = false;
        };

       function updateAnswerOptions () {
            var update = {
                _links: { answerOptions: [] },
            };

            // Build the record to be sent to the server
            angular.forEach(vm.answerOptions, function(value, key) {
               update._links.answerOptions.push(value._links.self);
            });

            apiClient.create(vm.answerOptionsResponse.getLink('create-answer-options-changeset'), update).then(function (isSuccess) {
                if (isSuccess) {
                    $state.go('.', {}, {reload: true}).then(function(){
                        messenger.success('AUDIT_ANSWER_OPTIONS_ORDER_UPDATED');
                    });
                } else {
                    messenger.error('REQUEST_ERROR');
                }
            });

        }

    }
})();
