;(function() {
    'use strict';

    angular
        .module('elogbooks.admin.forms')
        .config(registerRoutes)
        .config(registerRoutesList)
        .config(registerRoutesAdd)
        .config(registerRoutesDetails)
        .config(registerRoutesEdit);

    registerRoutes.$inject = ['$stateProvider'];
    registerRoutesList.$inject = ['$stateProvider'];
    registerRoutesAdd.$inject = ['$stateProvider'];
    registerRoutesDetails.$inject = ['$stateProvider'];
    registerRoutesEdit.$inject = ['$stateProvider'];

    function registerRoutes($stateProvider) {
        $stateProvider
            .state('dashboard.admin.forms', {
                abstract: true,
                templateUrl: 'shared-templates/view.html',
                parent: 'dashboard.admin',
                ncyBreadcrumb: {
                    skip: true
                },
                data: {
                    permissions: ['user_permission_view_forms', 'user_permission_client_admin_manage_forms']
                }
            });
    }

    function registerRoutesList($stateProvider) {
        $stateProvider
            .state('dashboard.admin.forms.list', {
                url: '/forms?page&limit&order&summary&description&type',
                parent: 'dashboard.admin.forms',
                data: {
                    permissions: ['user_permission_view_forms', 'user_permission_client_admin_manage_forms']
                },
                views: {
                    '@dashboard.admin.forms': {
                        templateUrl: '/modules/admin/forms/list/list.html',
                        controller: 'FormsController',
                        controllerAs: 'vm'
                    }
                },
                params: {
                    page: '1',
                    limit: '30',
                    order: 'summary',

                    summary: null,
                    description: null,
                    type: null
                },
                resolve: {
                    formCollectionResponse: function($stateParams, apiClient, rootResourceResponse) {
                        return apiClient
                            .get(rootResourceResponse.getLink('forms'), $stateParams)
                            .then(function(response) {
                                return response || apiClient.noResourceFound();
                            });
                    }
                },
                ncyBreadcrumb: {
                    label: '{{ ::"FORM_LIST" | translate }}'
                }
            });
    }

    function registerRoutesDetails($stateProvider) {
        $stateProvider
            .state('dashboard.admin.forms.list.details', {
                url: '/{formResource}?selectedVersion',
                parent: 'dashboard.admin.forms.list',
                data: {
                    permissions: ['user_permission_view_forms', 'user_permission_client_admin_manage_forms']
                },
                views: {
                    '@dashboard.admin.forms': {
                        templateUrl: '/modules/admin/forms/details/details.html',
                        controller: 'FormDetailsController',
                        controllerAs: 'vm'
                    },
                    'versions@dashboard.admin.forms.list.details': {
                        templateUrl: '/modules/admin/forms/details/versions.html',
                        controller: 'FormDetailsVersionController',
                        controllerAs: 'vm'
                    }
                },
                params: {
                    selectedVersion: null
                },
                resolve: {
                    formResponse: function($stateParams, apiClient) {
                        return apiClient
                            .get($stateParams.formResource.decode(), {datagroup: 'details'})
                            .then(function(response) {
                                return response || apiClient.noResourceFound();
                            });
                    },
                    formVersionCollectionResponse: function(apiClient, formResponse) {
                        return apiClient
                            .get(formResponse.getLink('versions'), {datagroup: 'details', limit: 30, order: '-id'})
                            .then(function(response) {
                                return response || apiClient.noResourceFound();
                            });
                    }
                },
                ncyBreadcrumb: {
                    label: '{{ ::$resolves().formResponse.summary }}'
                }
            });
    }

    function registerRoutesAdd($stateProvider) {
        $stateProvider
            .state('dashboard.admin.forms.list.add', {
                url: '/add',
                parent: 'dashboard.admin.forms.list',
                data: {
                    permissions: ['user_permission_manage_forms', 'user_permission_client_admin_manage_forms']
                },
                views: {
                    '@dashboard.admin.forms': {
                        templateUrl: '/modules/admin/forms/add-edit/form-form.html',
                        controller: 'FormAddEditController',
                        controllerAs: 'vm'
                    }
                },
                resolve: {
                    formResponse: function() {
                        return {};
                    }
                },
                ncyBreadcrumb: {
                    label: '{{ ::"ADD" | translate }}'
                }
            });
    }

    function registerRoutesEdit($stateProvider) {
        $stateProvider
            .state('dashboard.admin.forms.list.details.edit', {
                url: '/edit',
                parent: 'dashboard.admin.forms.list.details',
                data: {
                    permissions: ['user_permission_manage_forms', 'user_permission_client_admin_manage_forms']
                },
                views: {
                    '@dashboard.admin.forms': {
                        templateUrl: '/modules/admin/forms/add-edit/form-form.html',
                        controller: 'FormAddEditController',
                        controllerAs: 'vm'
                    },
                    'versions@dashboard.admin.forms.list.details': {
                        template: ''
                    }
                },
                ncyBreadcrumb: {
                    label: '{{ ::"EDIT" | translate }}'
                }
            })
            .state('dashboard.admin.forms.list.details.version', {
                url: '/{versionResource}',
                parent: 'dashboard.admin.forms.list.details',
                data: {
                    permissions: ['user_permission_manage_forms', 'user_permission_client_admin_manage_forms']
                },
                abstract: true,
                resolve: {
                    formVersionResponse: function($stateParams, apiClient) {
                        return apiClient
                            .get($stateParams.versionResource.decode(), {datagroup: 'details'})
                            .then(function(response) {
                                return response || apiClient.noResourceFound();
                            });
                    }
                },
                ncyBreadcrumb: {
                    label: "{{ ::'FORM_VERSION_NUMBER' | translate:{version: $resolves().formVersionResponse.version} }}"
                }
            })
            .state('dashboard.admin.forms.list.details.version.edit', {
                url: '/edit',
                parent: 'dashboard.admin.forms.list.details.version',
                data: {
                    permissions: ['user_permission_manage_forms', 'user_permission_client_admin_manage_forms']
                },
                views: {
                    'versions@dashboard.admin.forms.list.details': {
                        templateUrl: '/modules/admin/forms/details/versions/edit/form.html',
                        controller: 'FormDetailsVersionEditController',
                        controllerAs: 'vm'                    }
                },
                resolve: {
                    associatedEntities: function(formResponse) {
                        var entities = {};
                        entities[formResponse.type] = {};

                        return entities;
                    }
                },
                ncyBreadcrumb: {
                    label: '{{ ::"EDIT" | translate }}'
                }
            })
    }
})();
