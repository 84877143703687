(function () {
    'use strict';

    angular
        .module('elogbooksControllers')
        .controller('AnswerOptionTemplateAddModalController', AnswerOptionTemplateAddModalController);

    AnswerOptionTemplateAddModalController.$inject = [
        '$uibModalInstance',
        '$filter',
        'apiClient',
        'messenger',
        'answerTemplateCollectionResponse',
        'sortableOptions',
        'auditService',
        'optionType',
        '$translate'
    ];

    function AnswerOptionTemplateAddModalController(
        $uibModalInstance,
        $filter,
        apiClient,
        messenger,
        answerTemplateCollectionResponse,
        sortableOptions,
        auditService,
        optionType,
        $translate
    ) {
        var vm = this;
        vm.answerTemplateCollection = answerTemplateCollectionResponse;
        vm.data = {
            answerOptions: [],
            type: optionType
        };
        vm.addAnswerOptions = addAnswerOptions;
        vm.deleteAnswerOptions = deleteAnswerOptions;
        vm.create = createAction;
        vm.cancel = cancelAction;

        vm.visibilities = [
            {value: 'public', title: $filter('translate')('VISIBILITY_PUBLIC')},
            {value: 'private', title: $filter('translate')('VISIBILITY_PRIVATE')}
        ];

        // ng-sortable parameters
        vm.sortableOptions = sortableOptions.addProperty({
            orderChanged: function(event) {
                vm.haveAnswerOptionsChanged = auditService.updatePoints(vm.data.answerOptions, vm.haveAnswerOptionsChanged);
            }
        });

        function addAnswerOptions(option) {
            event.preventDefault();

            if (option) {
                if (optionType === 'buttons' && option.length > 10) {
                    return messenger.error($translate.instant('ANSWER_OPTION_TITLE_MAX_10_CHARACTERS'));
                }

                var answerOption = {
                    title: option,
                    pointsValue: 100,
                    displayOrder: 1
                };

                vm.data.answerOptions.push(answerOption);
                auditService.updatePoints(vm.data.answerOptions, vm.haveAnswerOptionsChanged);
                vm.data.answerOption = null;
            }
            return false;
        }

        function deleteAnswerOptions(key) {
            vm.data.answerOptions.splice(key, 1);
            auditService.updatePoints(vm.data.answerOptions, vm.haveAnswerOptionsChanged);
        }

        function createAction() {
            delete vm.data.answerOption;
            vm.data.status = 'active';
            return apiClient.create(vm.answerTemplateCollection.getLink('create'), vm.data).then(function(response) {
                if (response) {
                    $uibModalInstance.close(response);
                    messenger.success('ANSWER_OPTION_ADDED');
                } else {
                    messenger.error('REQUEST_ERROR');
                }
            });
        }

        function cancelAction() {
            $uibModalInstance.dismiss('cancel');
        }
    }
})();