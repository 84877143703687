(function() {
    'use strict';

    angular
        .module('elogbooks.admin.charge-types')
        .controller('ChargeTypesDetailsController', ChargeTypesDetailsController);

    ChargeTypesDetailsController.$inject = ['chargeTypeResponse'];
    function ChargeTypesDetailsController(chargeTypeResponse) {
        var vm = this;
        vm.chargeType = chargeTypeResponse;
    }
})();
