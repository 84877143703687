angular.module('elogbooksServices').service('bulkCancelActionsModal', ['apiClient', '$uibModal', function (apiClient, $uibModal) {
    return {
        open: function (actions, selected, actionsCount, link, selectedSiteResponse) {
            return $uibModal.open({
                templateUrl: '/modules/common/modals/bulk-update/bulkaction-modal.html',
                controller: 'BulkActionCreateController',
                controllerAs: 'vm',
                resolve: {
                    config: function () {
                        return defaultConfig = {
                            titleMessage: 'CANCEL_ACTIONS',
                            updateMessage: 'ACTIONS_QUEUED_FOR_CANCELLATION',
                            bodyMessage: 'ARE_YOU_SURE',
                            selected: selected,
                            bulkAsssigntype: 'cancel-actions',
                            actions: actions,
                            link: link,
                            actionsCount: actionsCount,
                            noteRequired: true
                        };
                    },
                    serviceProviderResponse:function() {
                        return null;
                    },
                    jobs: function() {
                        return null;
                    },
                    jobsCount: function() {
                        return null;
                    },
                    selectedSite: function() {
                        return selectedSiteResponse;
                    },
                    selectedSP: function() {
                        return null;
                    },
                    rootResourceResponse: function() {
                        return null;
                    },
                    user: function() {
                        return null;
                    },
                    canBulkReassignOperative: function() {
                        return null;
                    }
                }
            });
        }
    };
}]);
