(function () {
    'use strict';

    /**
     * @desc ui.select expanded widget
     * @example <div data-elogbooks-select-site-multiple ng-model="vm.model"></div>
     */
    angular
        .module('elogbooksDirectives')
        .directive('elbSelectAccess', function ($uibModal, apiClient, lodash) {
            return {
                restrict: 'A',
                templateUrl: '/modules/directives/form-elements/select-access/select-access.html',
                scope: {
                    model: '=ngModel'
                },
                require: ['ngModel'],
                controller: ['$scope', function($scope) {
                    var defaultModel = {
                        link : null,
                        loading : false,
                        responseKeyPath: 'data',
                        itemHrefPath: '_links.self.href',
                        itemValuePath: 'name',
                        allTitle: 'ALL_SITES',
                        selectedTitle: 'SELECTED_SITES',
                        keyword: null,
                        expandedSearch: false,
                        required : false,
                        selectedItems: [],
                        items: [],
                        searchKey: 'name',
                        map : map,
                        getResponseData : getResponseData,
                        filterResponse : filterResponse,
                        clear : clearAction,
                    };

                    $scope.add = addAction;
                    $scope.remove = removeAction;
                    $scope.searchDelayed = searchDelayed;

                    //Merge with defaults
                    $scope.model = lodash.merge(defaultModel, $scope.model);

                    function removeAction () {
                        $scope.model.selectedItems = lodash.difference($scope.model.selectedItems, $scope.selectedItemsSelect);
                        $scope.model.items = lodash.union($scope.model.items, $scope.selectedItemsSelect);
                    }

                    function addAction () {
                        $scope.model.items = lodash.difference($scope.model.items, $scope.itemsSelect);
                        $scope.model.selectedItems = lodash.unionBy($scope.model.selectedItems, $scope.itemsSelect, 'href');
                    }

                    function searchAction() {
                        $scope.model.loading = true;

                        var searchParams = {};
                        searchParams[$scope.model.searchKey] = $scope.model.keyword;

                        apiClient.get($scope.model.link, angular.extend({}, searchParams, $scope.model.searchParams)).then(function (response) {
                            $scope.model.items = lodash.differenceBy($scope.model.filterResponse(response), $scope.model.selectedItems, 'href');
                            $scope.model.loading = false;
                        });
                    }

                    function searchDelayed() {
                        clearTimeout($scope.timer);
                        $scope.timer = setTimeout(searchAction, 250);
                    }

                    function map(keyPath, valuePath) {
                        return function (item) {
                            if (typeof item === 'undefined'){
                                return;
                            }

                            return  {
                                href :  lodash.get(item, keyPath),
                                value : lodash.get(item, valuePath)
                            };
                        }
                    }

                    function getResponseData(response) {
                        return lodash.get(response.getData(), this.responseKeyPath);
                    }

                    function filterResponse(response) {
                        return this.getResponseData(response).map(
                            this.map(this.itemHrefPath, this.itemValuePath)
                        );
                    }

                    function clearAction($event) {
                        $event.stopPropagation();

                        this.selectedItems = null;
                        $scope.model.selectedItems = null;
                    }
                }]
            };
    })
})();
