/**
 * Add Survey Line Controller
 */
(function(){
    'use strict';

    angular
        .module('elogbooks.admin.satisfactionsurveylines')
        .controller('SatisfactionSurveyLineAddController', SatisfactionSurveyLineAddController)
        .controller('SatisfactionSurveyLineEditController', SatisfactionSurveyLineEditController);

    SatisfactionSurveyLineAddController.$inject = ['$scope', 'apiClient', 'messenger', 'satisfactionSurveyLineCollectionResponse'];
    SatisfactionSurveyLineEditController.$inject = ['$scope', 'apiClient', 'messenger', 'satisfactionSurveyLineResponse'];
    
    function SatisfactionSurveyLineAddController($scope, apiClient, messenger, satisfactionSurveyLineCollectionResponse) {
        var vm = this;

        var displayOrder = $scope.$parent.vm.satisfactionSurveyLines.length + 1;
        
        vm.satisfactionSurveyLine = {
            displayOrder: displayOrder
        };
        
        vm.submit = function () {
            var createLink = satisfactionSurveyLineCollectionResponse.getLink('create');
            apiClient.create(createLink, vm.satisfactionSurveyLine).then(function (isSuccess) {
                if (isSuccess) {
                    $scope.$state.go('^', {}, {reload: true}).then(function(){
                        messenger.success('Satisfaction Survey Question created successfully');
                    });
                } else {
                    messenger.error('Unable to process your request. Please try again later');
                }
            });
        };
    };
    
    function SatisfactionSurveyLineEditController($scope, apiClient, messenger, satisfactionSurveyLineResponse) {
        var vm = this;
        vm.satisfactionSurveyLine = satisfactionSurveyLineResponse.getData();
        
        vm.submit = function () {
            var updateLink = satisfactionSurveyLineResponse.getLink('self');
            apiClient.update(updateLink, vm.satisfactionSurveyLine).then(function (isSuccess) {
                if (isSuccess) {
                    $scope.$state.go('^', {}, {reload: true}).then(function(){
                        messenger.success('Satisfaction Survey Question ID ' + vm.satisfactionSurveyLine.id + ' updated successfully');
                    });
                } else {
                    messenger.error('Unable to process your request. Please try again later');
                }
            });
        };
        
    };

})();
