(function () {
    'use strict';

    angular
        .module('elogbooks.admin.imports')
        .controller('CommonImportDetailsController', ['$state', '$stateParams', '$filter', 'importResponse','importLogsCollectionResponse', 'elbDownloadService', CommonImportDetailsController]);

    CommonImportDetailsController.$inject = ['$state', '$stateParams', '$filter', 'importResponse','importLogsCollectionResponse', 'elbDownloadService'];

    function CommonImportDetailsController($state, $stateParams, $filter, importResponse, importLogsCollectionResponse, elbDownloadService) {
        var vm = this;
        vm.import = importResponse;
        vm.importLogsCollection = importLogsCollectionResponse;
        vm.importLogs = importLogsCollectionResponse.importLogs;
        vm.importLogs = importLogsCollectionResponse.importLogs;
        var typeOptions = [
            { title: $filter('translate')('IMPORT_LOG_ALL'), value: null},
            { title: $filter('translate')('IMPORT_LOG_ERROR'), value: 'error' },
            { title: $filter('translate')('IMPORT_LOG_WARN'), value: 'warn' },
            { title: $filter('translate')('IMPORT_LOG_CHANGE'), value: 'change' },
            { title: $filter('translate')('IMPORT_LOG_NO_CHANGE'), value: 'no_change' }
        ];
        vm.criteria = {
            logsMessage: { type: 'text', title: 'IMPORT_MESSAGE', value: $stateParams.logsMessage},
            logsType: { type: 'options', title: 'IMPORT_LOG_TYPE', value: $stateParams.logsType, options: typeOptions}
        };
        vm.noBorder = false;
        vm.search = search;
        vm.download = download;

        if ($stateParams.type === 'user_asset_task') {
            vm.noBorder = true;
        }

        /**
         * Search tasks
         */
        function search(params) {
            var override = {
                logsPage: 1
            };

            $state.go('.', angular.extend({}, params, override), { reload: $state.current });
        }

        function download(file) {
            elbDownloadService.download(file);
        }
    }
})();
