(function () {
    'use strict';

    /**
     * @desc file upload directive.
     * @example <file-upload ng-model="vm.file"></file-upload>
     */
    angular
        .module('elogbooksDirectives')
        .directive('multipleUpload', multipleUpload);

    multipleUpload.$inject = ['apiClient', 'lodash', 'disableSubmitService', '$q', 'messenger', 'validationService']

    function multipleUpload(apiClient, lodash, disableSubmitService, $q, messenger, validationService) {
        var directive = {
            restrict: 'A',
            templateUrl: '/modules/directives/form-elements/multiple-upload/multiple-upload.html',
            scope: {
                model: '=ngModel',
                existingFiles: '=ngExistingFiles',
                uploading: '=ngUploading'
            },
            require: ['ngModel'],
            controller: function ($scope) {
                $scope.files = [];
                $scope.file = {};

                init();

                $scope.delete = function(file) {
                    // Remove from current scope files
                    $scope.files.splice($scope.files.indexOf(file), 1);

                    // using loop instead of indexOf because file does not model contents of $scope.model
                    var deleteIndex = false;
                    for (var modelFile in $scope.model) {
                        if ($scope.model[modelFile].href === file.resource.href) {
                            deleteIndex = modelFile;
                            break;
                        }
                    }

                    if (deleteIndex !== false) {
                        $scope.model.splice(deleteIndex, 1);
                    }
                };

                $scope.upload = function() {
                    // Create form used for posting this file input
                    var form = document.createElement('form');
                    form.setAttribute('method', 'post');
                    form.setAttribute('enctype', 'multipart/form-data');
                    form.setAttribute('class', 'file-upload-form');
                    document.body.appendChild(form);

                    // Create the file input
                    var input = document.createElement('input');
                    input.setAttribute('name', 'file');
                    input.setAttribute('type', 'file');
                    input.setAttribute('multiple', 'multiple');
                    input.setAttribute('class', 'file-upload-input');
                    form.appendChild(input);

                    angular.element(input).bind('change', uploadAction);
                    // Open select file window
                    input.click();

                    function uploadAction(ce) {
                        if (ce.target.files.length > 10) {
                            return messenger.error('FILES_10_MAX');
                        }

                        for (var ceKey in ce.target.files) {
                            if (!validationService.isFileNameValid(ce.target.files[ceKey].name)) {
                                messenger.error('INVALID_FILE_NAME');
                                return;
                            }
                        }

                        for (var scopeKey in $scope.files) {
                            if ($scope.files[scopeKey].name == input.value.split(/(\\|\/)/g).pop()) {
                                return;
                            }
                        }

                        var requests = [];
                        var fileList = [];

                        disableSubmitService.disableSubmit();

                        for (var i = 0; i < input.files.length; i++) {
                            fileList.push(input.files[i]);
                        }

                        fileList.forEach(function (file, index) {
                            var request = {
                                title: file.name,
                                filename: file.name,
                                type: file.type
                            };

                            requests[index] =  apiClient.create('/files', request).then(function(data) {
                                $scope.model.push(data._links.resource);

                                var formData = new FormData();
                                var request = new XMLHttpRequest();

                                // Add new children
                                for(var name in data.policy) {
                                    formData.append(name, data.policy[name]);
                                }

                                $scope.file = {
                                    name : data._links.resource.title,
                                    resource : data._links.resource,
                                    uploaded: false
                                };
                                $scope.files.push($scope.file);

                                formData.append('file', file);
                                request.open("POST", data.getLink('remote'));
                                request.send(formData);
                            });
                        });
                        $q.all(requests).then(function() {
                            loaded();
                        });
                    }

                    function loaded() {
                        for (var key in $scope.files) {
                            $scope.files[key].uploaded = true;
                        }

                        $scope.file = {};
                        // Set uploading flag
                        $scope.uploading = false;
                        disableSubmitService.enableSubmit();
                    }
                }

                function init() {

                    lodash.each($scope.model, function (file) {
                        var existingFile = {
                            name : file._links ? file._links.resource.title : file.title,
                            resource : file._links ? file._links.resource : file.href,
                            href: file._links ? file._links.resource.href : file.href,
                            _links: file._links,
                            uploaded: true
                        };

                         //Add file to list
                        $scope.files.push(existingFile);

                        // re-factor file properties to match directive
                        file.href = file._links ? file._links.resource.href : file.href;
                        delete file.visibility;
                        delete file.filename;
                    });
                }
            }
        };

        return directive;
    }
})();
