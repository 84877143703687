(function () {
    'use strict';

    /**
     * @desc frequency units directive to allow user to select a unit value for frequencies
     * @example <elb-recurrence-rules-list data-ng-model="MODEL" data-max-rules=1 "></elb-recurrence-rules>
     */
    angular
        .module('elogbooksDirectives')
        .directive('elbRecurrenceRulesList', recurrenceRulesList);

    function recurrenceRulesList($uibModal, recurrenceRulesService) {
        var directive = {
            templateUrl: '/modules/directives/form-elements/recurrence-rules-with-modal/list/recurrence-rules-list.html',
            restrict: 'AE',
            scope: {
                recurrenceRules: '=ngModel',
                required: '=ngRequired',
                hideForm: '=',
                forceServiceRoutine: '=',
                limitedMode: '=',
                loading: '=',
                statutoryTypes: '=',
                hideMonthLastWeekDay: '<',
                maxRules: '@',
                title:'@'
            },
            require: ['ngModel'],
            transclude: true,
            controller: ['$scope', 'apiClient', 'modulesService', function($scope, apiClient, modulesService) {
                $scope.addEditFrequency = addEditFrequency;
                $scope.removeFrequency = removeFrequency;
                $scope.recurrenceRulesService = recurrenceRulesService;
                $scope.getRuleFrequencyAsString = recurrenceRulesService.getRuleFrequencyAsString;
                $scope.getRuleMonths = recurrenceRulesService.getRuleMonths;
                $scope.getRuleDayPrefixAsString = recurrenceRulesService.getRuleDayPrefixAsString;
                $scope.getRuleDayNameAsString = recurrenceRulesService.getRuleDayNameAsString;
                $scope.pointsModuleEnabled = modulesService.isEnabled('points_module');

                $scope.documentTypeCollectionResponse = apiClient.get('/documenttypes', {
                    status: 'active',
                    order: 'name'
                }).then(function (response) {
                    return response || apiClient.noResourceFound();
                });

                if (typeof $scope.recurrenceRules === 'undefined') {
                    $scope.recurrenceRules = [];
                }

                function updateScope() {
                    $scope.recurrenceRules.forEach(function(item) {
                        if ($scope.pointsModuleEnabled && !item.hours) {
                            item.hours = Math.floor(item.minutes / 60);
                            item.minutes = item.minutes % 60;
                        }

                        if (item._links && item._links.statutorytype && (item._links.statutorytype.value || item._links.statutorytype.title)) {
                            $scope.isStatutory = true;
                        }
                    });
                }

                function addEditFrequency(frequencyKey) {
                    $uibModal.open({
                        templateUrl: '/modules/common/modals/recurrence/recurrence-add-edit-modal.html',
                        controller: 'RecurrenceAddEditModalController',
                        controllerAs: 'vm',
                        resolve: {
                            hideMonthLastWeekDay: function () {
                                return !!$scope.hideMonthLastWeekDay;
                            },
                            frequencyKey: function () {
                                return frequencyKey;
                            },
                            recurrenceRules: function () {
                                return $scope.recurrenceRules;
                            },
                            statutoryTypes: function () {
                                return $scope.statutoryTypes;
                            },
                            maxRules: function () {
                                return $scope.maxRules;
                            },
                            documentTypeCollectionResponse: function () {
                               return $scope.documentTypeCollectionResponse;
                            }
                        }
                    }).result.then(function(freqResponse) {
                        $scope.recurrenceRules = freqResponse
                        updateScope();
                    });
                }

                function removeFrequency(key) {
                    $scope.recurrenceRules.splice(key, 1);
                }

                updateScope();
            }]
        };

        return directive;
    }
})();
