(function() {
    'use strict';

    angular
        .module('elogbooks.common.finance')
        .controller('PurchaseInvoicesAddEditController', PurchaseInvoicesAddEditController);

    PurchaseInvoicesAddEditController.$inject = [
        'purchaseOrderResponse',
        'purchaseInvoicesCollectionResponse',
        'purchaseInvoiceResponse',
        'suppliersCollectionResponse',
        'purchaseOrdersCollectionResponse',
        'requestDataFactory',
        'crudService',
        '$state'
    ];

    function PurchaseInvoicesAddEditController(
        purchaseOrderResponse,
        purchaseInvoicesCollectionResponse,
        purchaseInvoiceResponse,
        suppliersCollectionResponse,
        purchaseOrdersCollectionResponse,
        requestDataFactory,
        crudService,
        $state
     ) {
        var vm = this;
        vm.purchaseOrderResponse = purchaseOrderResponse;
        vm.purchaseInvoicesCollectionResponse = purchaseInvoicesCollectionResponse;
        vm.suppliersCollectionResponse = suppliersCollectionResponse;
        vm.purchaseInvoice= purchaseInvoiceResponse;
        vm.invoiceNumberPattern = "^[a-zA-Z0-9]+$";
        vm.data = requestDataFactory.createRequest(getDefaults());
        vm.data.files = {
            links: []
        };

        vm.supplierModel = {
            response: suppliersCollectionResponse,
            link:suppliersCollectionResponse.getLink('self'),
            linkParameters: {active: true},
            responseKeyPath: 'suppliers',
            itemHrefPath: '_links.self.href',
            itemValuePath: 'name',
            required: 'required',
            onSelect: changeSupplier,
            onRemove : changeSupplier
        };

        vm.purchaseOrderModel = {
            response: purchaseOrdersCollectionResponse,
            link:purchaseOrdersCollectionResponse.getLink('self'),
            responseKeyPath: 'purchaseOrders',
            itemHrefPath: '_links.self.href',
            itemValuePath: 'id',
            searchKey: 'id',
            onSelect: changePorder,
            onRemove: changePorder
        };

        if (vm.purchaseOrderResponse && vm.purchaseOrderResponse.id) {
            vm.purchaseOrderModel.selected = {
                href: vm.purchaseOrderResponse.getLink('purchase-order'),
                title: vm.purchaseOrderResponse.id,
            }
            vm.purchaseOrderModel.disabled = true;
            vm.purchaseOrderModel.populateDisabled = true;
            if (vm.purchaseOrderResponse.supplier) {
                vm.supplierModel.selected = {
                    href: vm.purchaseOrderResponse.supplier.getLink('self'),
                    title: vm.purchaseOrderResponse.supplier.name,
                    object: vm.purchaseOrderResponse.supplier
                };
            }
            setPurchaseOrder(vm.purchaseOrderResponse);
            setSupplierInfo(vm.purchaseOrderResponse.supplier);
        } else if (vm.data.id) {
            vm.supplierModel.selected = {
                href: vm.purchaseInvoice.supplier.getLink('self'),
                title: vm.purchaseInvoice.supplier.name,
                object: vm.purchaseInvoice.supplier
            }

            if (vm.purchaseInvoice.poNumber) {
                vm.purchaseOrderModel.selected = {
                    href: vm.purchaseInvoice.getLink('purchase-order'),
                    title: vm.purchaseInvoice.poNumber,
                }
            }
        }

        vm.changeSupplier = changeSupplier;
        vm.changePorder = changePorder;
        vm.create = createAction;
        vm.update = updateAction;
        vm.delete = deleteAction;
        vm.cancel = cancelAction;

        function getDefaults() {
            return {
                id: vm.purchaseInvoice.id || null,
                invoiceNumber: vm.purchaseInvoice.invoiceNumber,
                invoiceDate: vm.purchaseInvoice.invoiceDate ? new Date(vm.purchaseInvoice.invoiceDate) : null,
                invoiceAmount: vm.purchaseInvoice.invoiceAmount,
                invoiceValue: parseFloat(vm.purchaseInvoice.invoiceValue),
                _links: {
                    supplier: vm.purchaseInvoice.supplier ?  vm.purchaseInvoice._links['supplier'].href : null,
                    purchaseorder: vm.purchaseInvoice.poNumber ?  vm.purchaseInvoice._links['purchase-order'].href : null
                },
                contactName: vm.purchaseInvoice.supplier ? vm.purchaseInvoice.supplier.contactName : null,
                telephone: vm.purchaseInvoice.supplier ? vm.purchaseInvoice.supplier.telephone : null,
                email: vm.purchaseInvoice.supplier ? vm.purchaseInvoice.supplier.email: null,
                address: vm.purchaseInvoice.supplier ? vm.purchaseInvoice.supplier.flatAddress: null,
            }
        }

        function changeSupplier(supplier) {
            setSupplierInfo(null);
            if (vm.supplierModel.selected) {
                setSupplierInfo(supplier.selected.object)
            }
        }

        function changePorder(purchaseOrder) {
            vm.supplierModel.selected = undefined;
            setSupplierInfo(null);
            setPurchaseOrder(null);
            if (vm.purchaseOrderModel.selected) {
                var purchaseOrder = purchaseOrder.selected.object;
                setPurchaseOrder(purchaseOrder);
                vm.supplierModel.selected = {
                      href: purchaseOrder.supplier.getLink('self'),
                      title: purchaseOrder.supplier.name,
                      object: purchaseOrder.supplier  
                };
                setSupplierInfo(purchaseOrder.supplier);
            } else {
                vm.data.invoiceValue = null;
            }
        }
        
        function setSupplierInfo(supplier) {
            vm.data._links.supplier = supplier ? supplier.getLink('self') : {};
            vm.data.contactName = supplier ? supplier.contactName : undefined;
            vm.data.telephone = supplier ? supplier.telephone : undefined;
            vm.data.email = supplier ?supplier.email : undefined;
            vm.data.address = supplier ? supplier.flatAddress : undefined;
        }

        function setPurchaseOrder(purchaseOrder) {
            vm.data._links.purchaseorder = purchaseOrder ? purchaseOrder.getLink('self') : {};
            vm.data.invoiceValue = purchaseOrder ? parseFloat(purchaseOrder.amount) : null;
        }

        function createAction() {
            var successRoute = (vm.purchaseOrderResponse && vm.purchaseOrderResponse.id) ?
                $state.get('^') : 'dashboard.user.finance.purchase-invoices.list.details.items';

            return crudService.create(
                vm.purchaseInvoicesCollectionResponse.getLink('create'),
                vm.data,
                successRoute,
                'purchaseInvoicesResource',
                'PURCHASE_INVOICES'
            );
        }

        function updateAction() {
            return crudService.update(
                vm.purchaseInvoice.getLink('edit'),
                vm.data,
                'dashboard.user.finance.purchase-invoices.list.details.items',
                'dashboard.user.finance.purchase-invoices.list',
                'PURCHASE_INVOICES',
                false
            );
        }

        function deleteAction() {
            return crudService.delete(
                vm.purchaseInvoice.getLink('delete'),
                $state.get('^.^'),
                'PURCHASE_INVOICES'
            );
        }

        function cancelAction() {
            var cancelRoute = $state.get('^');

            if (vm.data.id) {
                cancelRoute = 'dashboard.user.finance.purchase-invoices.list.details.items'
            }
                $state.go(cancelRoute, {});
            }
        }
})();
