app.config(function ($translateProvider) {
    $translateProvider.translations('en-gb', {
        'en-gb': 'English',
        'fr': 'Francais',
        'es': 'Espanol',
        'nl': 'Nederlands',
        'zh-hk': '繁體中文',
        'de': 'German'
    });
});
