/**
 * Year picker directive
 */
angular.module('elogbooksDirectives').directive('years', function () {
    return {
        restrict: 'A',
        require: ['ngModel'],
        scope: {
            year: '=ngModel',
            required: '=ngRequired'
        },
        templateUrl: '/modules/directives/form-elements/years/years.html',
        link: function (scope) {

            var year = moment().year();

            scope.options = [];
            for(y = year - 1; y < year + 10; y++) {
                    scope.options.push({
                    value: y,
                    label: y
                 });
            }
        }
    }
});
