/**
 * Contact Information Directive
 * - entity.contactInformation or entity._embedded.contactinformation
 * - contactinformation._links.edit must exist to enable edit mode
 * - data-fields-only
 * @example <contact-information data-ng-model="vm.entity"></contact-information>
 */
angular.module('elogbooksDirectives').directive('contactInformation', function ($state, apiClient) {
    return {
        restrict: 'AE',
        require: ['ngModel'],
        scope: {
            entity: '=ngModel',
            hide: '='
        },
        templateUrl: '/modules/directives/contact-information/contact-information.html',
        link: link
    };

    function link(scope, $element, $attrs) {
        scope.editMode = false;

        // Get the contact information if it exists
        if (scope.entity.contactInformation) {
            scope.contactInformation = scope.entity.contactInformation;
        } else if (scope.entity._embedded && scope.entity._embedded.contactinformation) {
            scope.contactInformation = scope.entity._embedded.contactinformation
        } else {
            scope.contactInformation = null;
        }

        scope.editable = scope.entity._links && scope.entity._links['edit-contactinformation'];

        scope.left = function (key) {
            moveAddress(-1, key);
        };

        scope.right = function (key) {
            moveAddress(+1, key);
        };

        scope.edit = function (key) {
            $state.go('dashboard.contactInformation.address.userEdit', {
                contactInformationResource: scope.entity._links['edit-contactinformation'].href.encode(),
                addressKey: key
            });
        };

        scope.addAddress = function() {
            $state.go('dashboard.user.sites.list.contactInformation.address',{
                contactInformationResource: scope.entity._links.contactinformation.href.encode()});
        };

        /**
         * Move the address left (direction -1), or right (direction +1)
         */
        function moveAddress(direction, key) {
            var moveElement = scope.contactInformation.addresses[key];
            scope.contactInformation.addresses[key] = scope.contactInformation.addresses[key + direction];
            scope.contactInformation.addresses[key + direction] = moveElement;

            var data = angular.copy(scope.contactInformation);
            apiClient.update(scope.entity._links.contactinformation.href, data);
        }
    }

});
