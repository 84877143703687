(function() {
    'use strict';

    angular
        .module('elogbooks.admin.settings')
        .controller('AdminSettingsDisplayChaseSettingsController', AdminSettingsDisplayChaseSettingsController);

    function AdminSettingsDisplayChaseSettingsController($state, apiClient, messenger, confirmationModal, settingsFields, chaseSettingsResponse, siteAssociateTypesResponse) {
        var vm = this;

        vm.settings = loadSettings();
        vm.delete = deleteAction;
        vm.reorderListeners = {
            accept: function(sourceItemHandleScope, destSortableScope) {
                return (sourceItemHandleScope.itemScope.sortableScope.$id === destSortableScope.$id);
            },
            orderChanged: function(event) {
                if (event.dest.sortableScope.modelValue[event.dest.index]._links.reorder) {
                    apiClient.update(event.dest.sortableScope.modelValue[event.dest.index]._links.reorder.href, {sequence: event.dest.index}).then(function () {
                        messenger.success('CHASE_SETTINGS_REORDERED');
                    });
                }
            }
        };

        function loadSettings() {
            var settings = {};

            for (var i in settingsFields) {
                var chases = [];
                var chase = chaseSettingsResponse[settingsFields[i]];

                angular.forEach(chase, function (value, key) {
                    if (key === 'settings') {
                        //settings object needed for redirecting/displaying
                        //days until alarm/escalation
                        chases.settings = value;
                    } else {
                        chases.push(value);
                    }
                });

                settings[settingsFields[i]] = chases;
            }

            return settings;
        }

        function deleteAction(chase) {
            return confirmationModal.open({primaryClass: 'btn-success'}).result.then(
                function() {
                    return apiClient.delete(chase._links.delete.href).then(function() {
                        messenger.success('CHASE_SETTING_REMOVED');
                        $state.go('.', {}, {'reload': $state.get('^.^')});
                    });
                }
            );
        }
    }
})();
