app.config(function ($translateProvider) {
    $translateProvider.translations('en-gb', {
        PATROL: 'Patrol',
        PATROLS: 'Patrols',
        MANAGE_PATROLS: 'Manage Patrols',

        PATROL_ADD: 'Add a new Patrol',
        PATROL_EDIT: 'Edit Patrol',
        PATROL_LIST: 'Patrols',

        PATROL_CREATED: 'Patrol Created',
        PATROL_UPDATED: 'Patrol Updated',
        PATROL_DELETED: 'Patrol Deleted',

        ORDERED: 'Ordered',
        ORDERED_YES: 'Ordered',
        ORDERED_NO: 'Not Ordered',

        PATROL_POINT: 'Patrol Point',
        PATROL_POINTS: 'Patrol Points',
        PATROL_POINT_TAB: 'Patrol Points',
        PATROL_POINT_LIST: 'Patrol Points',
        PATROLPOINTS_COUNT: 'Points',
        PATROLPOINTS_TIME_COUNT:'Duration',
        PATROL_POINT_ADD: 'Add Point',
        PATROL_POINT_EDIT: 'Edit Point',
        TIMEALLOCATED: 'Time Allocated',
        REQUIREMENTTYPE: 'Requirement Type',
        REQUIREMENT: 'Requirement',
        PATROL_POINT_REQUIREMENT_NONE: 'None',
        PATROL_POINT_REQUIREMENT_BARCODE: 'Barcode',
        PATROL_POINT_REQUIREMENT_NFC: 'NFC',
        PATROL_POINT_REQUIREMENT_PHOTO: 'Photo',
        PATROL_POINT_REQUIREMENT_SIGNATURE: 'Signature',
        PATROL_POINT_REQUIREMENT_FILE: 'File',

        PATROL_POINT_REQUIREMENT_BARCODE_ID: 'Barcode',
        PATROL_POINT_REQUIREMENT_NFC_ID: 'NFC ID',

        PATROLPOINT_CREATED: 'Point Created',
        PATROLPOINT_UPDATED: 'Point Updated',
        PATROLPOINT_DELETED: 'Point Deleted',

        PATROL_TEMPLATE: 'Patrol Template',
        PATROL_TEMPLATE_LIST: 'Patrol Templates',
        PATROL_TEMPLATE_ADD: 'Add Template',
        PATROL_TEMPLATE_EDIT: 'Edit Template',

        PATROL_SCHEDULE: 'Patrol Schedule',
        PATROL_SCHEDULES: 'Patrol Schedules',
        PATROL_SCHEDULES_TAB: 'Patrol Schedules',
        PATROL_SCHEDULE_LIST: 'Patrol Schedules',
        PATROL_SCHEDULE_ADD: 'Add Schedule',
        PATROL_SCHEDULE_EDIT: 'Edit Schedule',
        DAYOFWEEK: 'Day Of Week',
        DAY_OF_WEEK: 'Day Of Week',
        START_TIME: 'Start Time',
        END_TIME: 'End Time',
        PATROL_SCHEDULE_CREATED: 'Schedule Created',
        PATROL_SCHEDULE_UPDATED: 'Schedule Updated',
        PATROL_SCHEDULE_DELETED: 'Schedule Deleted',

        PATROL_OCCURRENCE: 'Patrol Occurrence',
        PATROL_OCCURRENCES: 'Patrol Occurrences',
        PATROL_OCCURRENCE_CREATED: 'Occurrence Created',
        PATROL_OCCURRENCE_UPDATED: 'Occurrence Updated',
        PATROL_OCCURRENCE_DELETED: 'Occurrence Deleted',
        PATROL_OCCURRENCE_ADD: 'Add Occurrence',
        PATROL_OCCURRENCE_EDIT: 'Edit Occurrence',
        PATROL_OCCURRENCE_ADD_BUTTON: 'Add Occurrence',
        PATROL_OCCURRENCE_ADD_BUTTON_MULTIPLE: 'Add Multiple Occurrences',
        PATROL_OCCURRENCE_LIST: 'Patrol Occurrences',
        PATROL_OCCURRENCE_VIEW: 'Patrol Occurrence',
        OCCURRENCE: 'Occurrence',

        PATROL_NAME: 'Patrol Name',

        PATROL_STATUS_REGISTERED: 'Registered',
        PATROL_STATUS_SCHEDULED: 'Scheduled',
        PATROL_STATUS_IN_PROGRESS: 'In Progress',
        PATROL_STATUS_OVERDUE: 'Overdue',
        PATROL_STATUS_COMPLETED: 'Completed',
        PATROL_STATUS_CANCELLED: 'Cancelled',
        PATROL_STATUS_MISSED: 'Missed',
        PATROL_STATUS_AUTO_MISSED: 'Auto Missed',
        PATROL_STATUS_INCOMPLETE: 'Incomplete',

        PATROL_POINT_WAITING: 'Waiting',
        PATROL_POINT_MISSED: 'Missed',
        PATROL_POINT_DONE: 'Done',

        PATROL_TEMPLATE_CHANGE: 'Patrol Template Change',
        PATROL_TEMPLATE_CHANGE_TEXT: 'Are sure you want to change patrol template?',
        TEMPLATE_UPDATED: 'Template Updated',
        PATROL_ID: 'Patrol ID',
        PATROL_POINT_EXPORT: 'Export Points',
        CREATE_FROM_POINT: 'Create From Point',

        PATROL_INSTANCES: 'Patrol Instances',
        PATROL_STATISTICS: 'Patrol Statistics',
        PATROL_INSTANCE_LIST: 'Patrol Instance List',
        PATROL_INSTANCE_VIEW: 'Patrol Instance',
        INSTANCE: 'Instance',
        INSTRUCTIONS: 'Instructions',
        TIME_ALLOCATED: 'Time Allocated',
        ATTENDED_AT: 'Attended At',
        COMPLETED_AT: 'Completed At',

        PLANNED_START_DATE: 'Planned Start Date',
        PLANNED_END_DATE: 'Planned End Date',
        DATE_STARTED: 'Date Started',
        REQUIREMENT_STATUS: 'Requirement Status',
        REQUIREMENT_CONFIRMATION: 'Requirement Confirmation',
        DOWNLOAD_FILE: 'Download File',
        POINTS: 'Points',
        REASON: 'Reason',

        STARTED_ON_TIME: 'Started On Time',
        POINTS_COMPLETED: 'Points Completed',
        NOT_STARTED_EARLY: 'Not Started Early',
        POINTS_REQUIREMENTS_MET: 'Points Requirements Met',
        MISSED_OR_INCOMPLETE: 'Missed or Incomplete',
        COMPLETED_LATE: 'Completed Late',
        STARTED_LATE: 'Started Late',
        STARTED_EARLY: 'Started Early',
        POINTS_MISSED: 'Points Missed',
        POINTS_REQUIREMENTS_NOT_MET: 'Points Requirements Not Met',
        TOTAL_NUMBER_OF_PATROLS: 'Total Number Of Patrols',

        PATROL_PLANNER: 'Patrol Planner',
        PATROL_ACTIVE: 'Patrol Active',
        PATROL_DRAFT: 'Patrol Draft',

        PATROL_COMMENCED: 'Patrol commenced',
        PATROL_MISSED: 'Patrol missed',
        PATROL_COMPLETED: 'Patrol completed',
        PATROL_POINT_COMPLETED: 'Patrol point completed',
        PATROL_BULK_SCHEDULE_LOW_FREQUENCY_WARNING: 'The frequency is below recommended amount',

        PATROL_MISSED_REASON_PHOTO: 'Missed Reason Photo',

        PATROL_UNASSIGNED: 'Unassigned',
        FREQUENCY_ERROR: 'Frequency must be at least 1 minute'
    });
});
