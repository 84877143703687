(function () {
    'use strict';

    angular
        .module('elogbooks.user.reports')
        .controller('UserReportsEscalatedJobsController', UserReportsEscalatedJobsController);

    UserReportsEscalatedJobsController.$inject = ['$state', 'jobsCollection', 'operativeResponse', 'modalConfig'];

    function UserReportsEscalatedJobsController($state, jobsCollection, operativeResponse, modalConfig) {
        var vm = this;

        vm.jobsCollection = jobsCollection;
        vm.jobs = jobsCollection.jobs;
        vm.op = operativeResponse;

        vm.modalConfig = modalConfig;
    }
})();