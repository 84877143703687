angular.module('elogbooksDirectives').directive('scoreButton', function () {
    return {
        replace: true,
        scope: {
            option: '=option',
            answer: '=answer',
            question: '=question'
        },
        restrict: 'EA',
        template:
        '<label class="btn {{buttonClass}}" data-ng-class="{\'active\': answer.score == option.score, \'disabled\': question.answer}">' +
            '<input type="radio" name="answer" autocomplete="off"' + 'data-ng-value="option.score" data-ng-model="answer.score" required> {{option.value}}' +
        '</label>',

        link: function (scope) {

            if (scope.option.score === 0) {
                scope.buttonClass = 'btn-danger';
                return;
            }

            if (scope.option.score === 100) {
                scope.buttonClass = 'btn-primary';
                return;
            }

            scope.buttonClass = 'btn-warning';
        }
    };
});
