
// @ts-nocheck
(function (){
    'use strict';

    angular
        .module('elogbooks.user.tasks')
        .controller('UserTaskModalUpdateController', UserTaskModalUpdateController);

    function UserTaskModalUpdateController(
            $rootScope,
            $translate,
            $uibModalInstance,
            apiClient,
            messenger,
            lodash,
            elbSettings,
            siteResponse,
            taskResponse,
            taskService,
            rootResourceResponse,
            hasEvents,
            $q,
            calendarRuleService,
            $uibModal,
            $state,
            modulesService,
            serviceRoutineService,
            confirmationModal,
            $scope,
            nonVersionedServiceRoutinesCollectionResponse,
            versionedServiceRoutinesCollectionResponse
    ) {
        var vm = this;
        var forceServiceRoutine = elbSettings.getSetting('task_force_service_routine').value;
        vm.task = angular.copy(taskResponse);
        vm.hasEvents = hasEvents;
        vm.task.rules = vm.task.recurrenceSet.rules;
        var serviceProviderCollectionResponse;
        vm.nonVersionedServiceRoutinesCollectionResponse = nonVersionedServiceRoutinesCollectionResponse;
        vm.versionedServiceRoutinesCollectionResponse = versionedServiceRoutinesCollectionResponse;
        vm.selectedNonVersionedServiceRoutineModel = {};
        vm.selectedVersionedServiceRoutineModel = {};
        vm.task.serviceRoutineType = 'none';
        vm.serviceRoutineChanged = false;
        var locationCollectionResponse;
        var assetsCollectionResponse;
        vm.assetSelect = [];
        vm.selectedTab = 'edit_task';
        vm.rootResourceResponse = rootResourceResponse;
        vm.site = siteResponse;
        vm.hideForm = false;
        vm.showForm = false;
        vm.noRules = false;
        vm.cancel = cancel;
        vm.updateDisabled = false;
        vm.serviceRoutinesLoading = false;
        vm.statuses = [
            {
                label: $translate.instant('ACTIVE'),
                value: 1
            },
            {
                label: $translate.instant('INACTIVE'),
                value: 0
            }
        ];

        vm.selectedServiceRoutineOptions = [
            {label: 'NONE_SELECTED', type: 'none' },
            {label: 'NON_SFG20', type: 'non_versioned'},
            {label: 'SFG20', type: 'versioned'}
        ];

        $scope.$watch('vm.task.serviceRoutineType', function(newValue, oldValue) {
            if (newValue !== oldValue) {
                taskService.clearServiceRoutineLines(vm.forceServiceRoutine, vm.task);
                vm.selectedNonVersionedServiceRoutineModel.selected = null;
                vm.selectedVersionedServiceRoutineModel.selected = null;
                vm.serviceRoutineChanged = !!taskResponse.getLink('serviceroutine');
            }
        });

        vm.update = updateAction;
        vm.delete = deleteAction;

        $rootScope.$emit('taskModalLoaded', true);

        // moved some requests here to load the modal quicker
        apiClient.get(siteResponse.getLink('partnerships'), { active:1, serviceProvidersStatus: 'active' }).then(function(response){
            serviceProviderCollectionResponse = response;

            if (serviceProviderCollectionResponse.partnerships && serviceProviderCollectionResponse.partnerships.length > 0) {
                vm.partnershipSelect = {
                    response : serviceProviderCollectionResponse,
                    link : serviceProviderCollectionResponse ? serviceProviderCollectionResponse._links.self.href :  null,
                    required: true,
                    onSelect: changeServiceProvider,
                    onRemove: removeServiceProvider,
                    responseKeyPath: 'partnerships',
                    linkParameters: {active: 1},
                    selected: {
                        title: vm.task.getLinkAttribute('serviceprovider', 'title'),
                        href: vm.task.getLink('serviceprovider'),
                        object: vm.task._links.serviceprovider
                    },
                    itemHrefPath: '_links.serviceprovider.href',
                    itemValuePath: '_links.serviceprovider.title'
                };

                changeServiceProvider(true);

                if (typeof taskResponse._links.operative !== 'undefined') {
                    vm.operativeSelect.selected = taskResponse._links.operative;
                    vm.operativeSelect.disabled = false;
                }
            }
        });

        vm.locationSelect = {
            response: null,
            responseKeyPath: 'locations',
            selected: null,
            disabled: true
        };

        apiClient.get(siteResponse.getLink('locations')).then(function(response) {
            vm.locationCollectionResponse = locationCollectionResponse = response;
            vm.locationSelect.response = locationCollectionResponse;
            vm.locationSelect.disabled = false;
            if (taskResponse._links.location) {
                vm.locationSelect.selected = taskResponse._links.location.href;
            }
        });

        if (taskResponse._links.location) {
            vm.task.location = taskResponse._links.location;
            vm.locationSelect.selected = taskResponse._links.location.href;
            vm.locationSelect.disabled = false;
        }

        vm.location = taskResponse._links.location;


        var assetRequest = [];
        assetRequest.push(apiClient.get(siteResponse.getLink('assets'), {active:1}).then(function(response) {
            vm.assets = assetsCollectionResponse = response;

            if (vm.assets && vm.assets.assets && vm.assets.assets.length > 0) {

                if (vm.task.getLink('serviceroutine') && forceServiceRoutine) {
                    vm.forceServiceRoutine = true;
                }

                vm.assetSelect = {
                    response: vm.assets,
                    responseKeyPath: 'assets',
                    required: taskResponse.type,
                    linkParameters: {active: 1},
                    selected: vm.task._links.asset ? {
                        title: vm.task.getLinkAttribute('asset', 'title'),
                        href: vm.task.getLink('asset'),
                        object: vm.task._links.asset
                    } : null,
                    link : lodash.has(assetsCollectionResponse, '_links.self.href') ? assetsCollectionResponse._links.self.href : null,
                    onSelect: changeAsset,
                    onRemove: removeAsset,
                };
            }
        }));

        apiClient.get(rootResourceResponse.getLink('jobgroups'), { limit: 999999 }).then(function (jobGroupCollectionResponse) {
            vm.jobGroups = jobGroupCollectionResponse;
            vm.jobGroupSelect = {
                response: jobGroupCollectionResponse,
                responseKeyPath: 'jobgroups',
                selected: null,
                disabled: jobGroupCollectionResponse ? false : true,
                isHierarchical: true
            };

            if (vm.jobGroupSelect && typeof taskResponse._links.jobgroup !== 'undefined') {
                vm.jobGroupSelect.selected = taskResponse._links.jobgroup;
            }
        });

        vm.selectedNonVersionedServiceRoutineModel = {
            linkParams: {active: 1, isVersioned: 0},
            required: false,
            show: vm.nonVersionedServiceRoutinesCollectionResponse.routines.length === 0 ? false : true,
            responseKeyPath: 'routines',
            searchKey: 'nameReference',
            formatValue: serviceRoutineService.formatServiceRoutineValue,
            response: vm.nonVersionedServiceRoutinesCollectionResponse.count > 0 ? vm.nonVersionedServiceRoutinesCollectionResponse : null,
            link: vm.nonVersionedServiceRoutinesCollectionResponse.getLink('self'),
            onSelect: updateServiceRoutineLines,
            onRemove: updateServiceRoutineLines
        };

        vm.selectedVersionedServiceRoutineModel = {
            linkParams: {active: 1, isVersioned: 1},
            required: false,
            show: vm.versionedServiceRoutinesCollectionResponse.routines.length === 0 ? false : true,
            responseKeyPath: 'routines',
            searchKey: 'nameReference',
            formatValue: serviceRoutineService.formatServiceRoutineValue,
            response: vm.versionedServiceRoutinesCollectionResponse.count > 0 ? vm.versionedServiceRoutinesCollectionResponse : null,
            link: vm.versionedServiceRoutinesCollectionResponse.getLink('self'),
            onSelect: updateServiceRoutineLines,
            onRemove: updateServiceRoutineLines
        };

        $q.all(assetRequest).then(function () {
            // Fill asset form data
            if (vm.assetSelect && typeof taskResponse._links.asset !== 'undefined') {
                vm.assetSelect.selected = taskResponse._links.asset;
                changeAsset();
            }
        });

        updateServiceRoutineSelect();

        apiClient.get(rootResourceResponse.getLink('statutorytypes')).then(function (statutoryTypeCollectionResource) {
            vm.statutoryTypes = statutoryTypeCollectionResource;
            vm.statutoryTypesSelect = {
                response: statutoryTypeCollectionResource,
                link: statutoryTypeCollectionResource.getLink('self'),
                responseKeyPath: 'statutorytypes',
                itemValuePath: 'name',
                itemHrefPath: 'id'
            };
        });

        function cancel() {
            $uibModalInstance.close();
        }

        vm.operativeSelect = {
            disabled: false,
            responseKeyPath: 'operatives',
            itemHrefPath: '_links.self.href',
            itemValuePath: '_links.user.name'
        };

        function updateServiceRoutineLines(model) {
            if (model) {
                taskService.getServiceRoutineLines(model, vm.forceServiceRoutine, vm.task);
                vm.serviceRoutineChanged = taskResponse.getLink('serviceroutine') !== model.selected.href;
            } else {
                taskService.clearServiceRoutineLines(forceServiceRoutine, vm.task)
                vm.serviceRoutineChanged = !!taskResponse.getLink('serviceroutine');
            }
        }

        if (taskResponse._links && typeof taskResponse._links.self !== 'undefined') {
            taskResponse.status = taskResponse.status ? 1 : 0;
        }

        function removeAsset() {
            vm.assetSelect.selected = null;
            vm.forceServiceRoutine = false;
            vm.serviceRoutineSelect = getServiceRoutineSelectModel();

            if (vm.task.serviceRoutineType !== 'none') {
                vm.serviceRoutineSelect.selected = null;
            }
            taskService.loadServiceRoutines(null, vm.selectedVersionedServiceRoutineModel, vm.jobGroupSelect, vm.versionedServiceRoutinesCollectionResponse);
            taskService.loadServiceRoutines(null, vm.selectedNonVersionedServiceRoutineModel, vm.jobGroupSelect, vm.nonVersionedServiceRoutinesCollectionResponse);
        }


        function changeAsset() {
            vm.serviceRoutinesLoading = true;
            $q.all([
                taskService.loadServiceRoutines(vm.assetSelect, vm.selectedVersionedServiceRoutineModel, vm.jobGroupSelect, vm.versionedServiceRoutinesCollectionResponse),
                taskService.loadServiceRoutines(vm.assetSelect, vm.selectedNonVersionedServiceRoutineModel, vm.jobGroupSelect, vm.nonVersionedServiceRoutinesCollectionResponse)
            ]).then(function () {
                vm.serviceRoutinesLoading = false;
                updateServiceRoutineSelect();
            });
        }

        function updateServiceRoutineSelect() {
            if (taskResponse && taskResponse._links && taskResponse._links.serviceroutine) {
                if (taskResponse._links.serviceroutine.isVersioned) {
                    vm.task.serviceRoutineType = 'versioned'
                } else {
                    vm.task.serviceRoutineType = 'non_versioned';
                }

                vm.serviceRoutineSelect = getServiceRoutineSelectModel();
                vm.serviceRoutineSelect.selected = {
                    href: vm.task.getLink('serviceroutine'),
                    object: vm.task._links.serviceroutine
                };
            }
        }

        /**
         * Load service provider and setup operative form element
         */
        function changeServiceProvider(leaveOperative) {
            if (typeof vm.partnershipSelect.selected.href !== 'undefined') {
                if (typeof leaveOperative === 'object' || !leaveOperative) {
                    vm.operativeSelect.selected = null;
                }
                apiClient.get(vm.partnershipSelect.selected.href).then(function (response) {
                    //List operatives
                    if (response && typeof response._links.taskoperatives !== 'undefined') {
                        vm.operativeSelect.link = response._links.taskoperatives.href;
                        vm.operativeSelect.disabled = false;
                    }
                });
            }
        }

        /**
         * Disable and clear operatives
         */
        function removeServiceProvider() {
            vm.operativeSelect.disabled = true;
            vm.operativeSelect.clear();
            taskService.clearServiceRoutineLines(forceServiceRoutine, vm.task);
        }

        function updateAction() {
            if (vm.task.rules.length === 0) {
                vm.noRules = true;
                messenger.error('ONE_OR_MORE_RULES_MISSING');
                return false;
            }

            vm.task.recurrenceSet.rules = vm.task.rules;

            //Clear links
            var taskRequest = angular.copy(vm.task);

            taskRequest._links = {};
            let partnerShipSelected = null, assetSelected = null, serviceRoutineSelected = null;

            if (vm.partnershipSelect.selected) {
                taskRequest._links.serviceprovider = vm.partnershipSelect.selected;
                partnerShipSelected = vm.partnershipSelect.selected.href;
            }

            if (vm.assetSelect && vm.assetSelect.selected) {
                taskRequest._links.asset = vm.assetSelect.selected;
                taskRequest.type = 1;
                assetSelected = vm.assetSelect.selected.href;
            }

            if (vm.jobGroupSelect && vm.jobGroupSelect.selected) {
                taskRequest._links.jobgroup = vm.jobGroupSelect.selected;
            }

            if (vm.locationSelect.selected) {
                if (!vm.locationSelect.selected.href) {
                    taskRequest._links.location = { href: vm.locationSelect.selected };
                } else {
                    taskRequest._links.location = vm.locationSelect.selected;
                }
            }

            if (vm.operativeSelect.selected) {
                taskRequest._links.operative = vm.operativeSelect.selected;
            }

            vm.serviceRoutineSelect = getServiceRoutineSelectModel();

            if (vm.serviceRoutineSelect && vm.serviceRoutineSelect.selected) {
                taskRequest._links.serviceroutine = vm.serviceRoutineSelect.selected
                serviceRoutineSelected = vm.serviceRoutineSelect.selected.href;
            }

            calendarRuleService.ruleTimeInMinutes(taskRequest.rules);

            vm.updateDisabled = true;

            // Checks if Generation Frequency Dates Range popup should appear
            let [valueRequiresApproval, rulesToRegenerate] = (() => {
                let isTaskDetailsChanged = vm.task.name !== taskResponse.name ||
                        vm.task.getLink('serviceprovider') !== partnerShipSelected ||
                        vm.task.getLink('serviceroutine') !== serviceRoutineSelected ||
                        vm.task.getLink('asset') !== assetSelected,
                    forceRegenerateAllRules = isTaskDetailsChanged,
                    valueRequiresApproval = false;
                let isFrequencyRuleChanged = rule => !rule.id;

                if (taskResponse.recurrenceSet.rules.length !== vm.task.rules.length) {
                    valueRequiresApproval = true;
                }
                // The angular.copy removes bits that angular added which broke comparison checks.
                let rulesCopy = angular.copy(vm.task.rules);

                angular.forEach(rulesCopy, (taskCopy) => {
                    if (forceRegenerateAllRules || isFrequencyRuleChanged(taskCopy)) {
                        valueRequiresApproval = true;
                        taskCopy.reGenerate = true;
                    }
                });

                if (!valueRequiresApproval) {
                    rulesCopy = [];
                }

                return [valueRequiresApproval, rulesCopy];
            })();

            if (vm.showForm) {
                confirmationModal.open({
                    titleMessage: 'UNSAVED_FREQUENCY_WARNING_TITLE',
                    bodyMessage: false,
                    htmlBodyMessage: '<p class="alert alert-warning"><i class="fa fa-exclamation-triangle"></i>&nbsp;' + $translate.instant('UNSAVED_FREQUENCY_WARNING'),
                    primaryClass: 'btn-primary',
                    primaryText: 'OK',
                    secondaryText: false
                });

                vm.updateDisabled = false;

            } else if (vm.hasEvents && valueRequiresApproval && modulesService.isEnabled('advanced_ppm_planner') && taskRequest.status === 1) {
                $uibModal.open({
                    templateUrl: '/modules/user/tasks/generate/modal/advanced-task-generate.html',
                    controller: 'UserAdvancedTaskGenerateModalController',
                    controllerAs: 'vm',
                    size: 'lg',
                    resolve: {
                        config: function () {
                            return {
                                titleMessage: 'ARE_YOU_SURE_HEADER',
                                bodyMessage: 'ARE_YOU_SURE',
                                primaryText: 'YES',
                                primaryClass: 'btn-danger',
                                secondaryText: 'NO',
                                secondaryClass: 'btn-default'
                            }
                        },
                        rules: () => rulesToRegenerate
                    }
                }).result.then(
                    function (data) {
                        updateApiCall(angular.copy(data));
                    }, function () {
                        vm.updateDisabled = false;
                    }
                );
            } else {
                updateApiCall();
            }

            function updateApiCall(generateData) {
                if (generateData) {
                    for (var i=0; i < generateData.rules.length; i++) {
                        generateData.rules[i].start = moment.utc(moment(generateData.rules[i].start).format('YYYY-MM-DD HH:mm:ss'));
                        if (typeof generateData.rules[i].end !== 'undefined') {
                            generateData.rules[i].end = moment.utc(moment(generateData.rules[i].end).format('YYYY-MM-DD HH:mm:ss'));
                        }
                    }
                    taskRequest.rules = generateData.rules;
                    taskRequest.note = generateData.note;
                }

                apiClient.update(vm.task.getLink('self'), taskRequest).then(function (response) {
                    if (response) {
                        $state.go('.', {}, {'reload': $state.current}).then(function () {
                            messenger.success('ADD_SUCCESS');
                        });
                        $uibModalInstance.close({action:'update', task:vm.task});
                        vm.updateDisabled = false;
                        return;
                    }
                    vm.updateDisabled = false;
                });
            }
        }

        function deleteAction() {
                return apiClient.delete(vm.task.getLink('delete')).then(function (response) {
                    if (response) {
                        messenger.success('DELETE_SUCCESS');
                        $uibModalInstance.close({action:'delete', task:vm.task});
                    } else {
                        messenger.error('REQUEST_ERROR');
                    }
                });
        }

        function getServiceRoutineSelectModel() {
            switch (vm.task.serviceRoutineType) {
                case 'versioned':
                    return vm.selectedVersionedServiceRoutineModel;
                case 'non_versioned':
                    return vm.selectedNonVersionedServiceRoutineModel;
                default:
                    break;
            }
        }
    }
})();
