(function () {
    'use strict';

    angular
        .module('elogbooks.common.billing-settings')
        .controller('CommonBillingSettingsApproversEditController', CommonBillingSettingsApproversEditController);

    CommonBillingSettingsApproversEditController.$inject = [
        'userCollectionResponse',
        'approversResponse'
    ];
    function CommonBillingSettingsApproversEditController(
        userCollectionResponse,
        approversResponse
        ) {
        var vm = this;
        vm.userCollectionResponse = userCollectionResponse;
        vm.approversResponse = approversResponse;
        vm.linkParameters = { status: 'active', limit: 30 };

        vm.data = [];
        vm.approverTypes = {
            contractManagerApprovers: { title: 'CONTRACT_MANAGER_APPROVERS' },
            clientApprovers: { title: 'CLIENT_APPROVERS' }
        }
    }
})();
