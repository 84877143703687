/**
 * Departments directive
 */
angular.module('elogbooksDirectives').directive('department', ['apiClient', function (apiClient) {
    return {
        require: ['ngModel'],
        scope: {
            department: '=ngModel',
            required: '=ngRequired'
        },
        templateUrl: '/modules/directives/form-elements/department/department.html',
        link: function(scope){
            scope.options = [];
            apiClient.get('/departments?page=1&limit=999999').then(function(response) {
                scope.options = response.getData().departments;
            });
        }
    }
}]);
