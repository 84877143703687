/**
 * User Service Provider Module
 */
(function(){
    'use strict';

    angular.module('elogbooks.user.service-providers', [
        'elogbooks.user.service-providers.contracts'
    ]);
})();
