app.config(function ($translateProvider) {
    $translateProvider.translations('zh-hk', {
        ASSET: '資產',
        ASSETS: '資產',
        ASSET_LIST: '資產',
        ASSET_ADD: '新增資產',
        ASSET_EDIT: '修改資產',
        ASSET_CREATED: '資產已建立',
        ASSET_UPDATED: '資產已更新',
        ASSET_DELETED: '資產已刪除',

        SUB_ASSET: '子資產',
        SUB_ASSETS: '子資產',
        SUB_ASSET_LIST: '子資產',
        SUB_ASSET_ADD: '新增子資產',
        SUB_ASSET_CREATED: '子資產已建立',
        SUB_ASSET_UPDATED: '子資產已更新',
        SUB_ASSET_DELETED: '子資產已刪除',

        MAKE: '制造',
        MODEL: '型號',
        SERIAL: '序號',
        BUSINESS_CONTINUITY_RISK: '業務連續性',
        REPLACEMENT_COST_LABOUR: '勞動力替代成本',
        REPLACEMENT_COST_MATERIALS: '物料替代成本',
        END_OF_LIFE: '壽命',
        CHARGEABLE: '收費',
        ASSET_TYPE: '類別',
        JOB_GROUP: '工作組別',
        NUMBER: '號碼',
        STATUTORY_TYPE: '法定類別',
        SERVICE_ROUTINE: '服務程序',
        IS_STATUTORY: '是否法定類別?'
    });
});
