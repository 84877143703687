(function () {
    'use strict';

    angular
        .module('elogbooks.user.reports')
        .controller('UserReportsPpmMissedAttendanceController', UserReportsPpmMissedAttendanceController);

    UserReportsPpmMissedAttendanceController.$inject = ['jobParams', 'jobsCollection', 'operativeResponse', '$translate', 'modalConfig'];

    function UserReportsPpmMissedAttendanceController(jobParams, jobsCollection, operativeResponse, $translate, modalConfig) {
        var vm = this;

        vm.jobsCollection = jobsCollection;
        vm.jobs = jobsCollection.jobs;
        vm.op = operativeResponse;
        vm.exportParams = jobParams;
        vm.modalConfig = modalConfig;

        vm.listTitle = vm.modalConfig.titlePrefix + " - " + (
            (jobParams.overdueAttendanceHistoric) ?
                $translate.instant('PPM_MISSED_ATTENDANCE') :
                $translate.instant('PPM_ATTENDANCE')
        );
    }
})();
