app.config(function ($translateProvider) {
    $translateProvider.translations('en-gb', {

        TAG: 'Folder',
        TAGS: 'Folders',
        TAG_LIST: 'Folders',

        TAG_CREATED: 'Folder Created',
        TAG_UPDATED: 'Folder Updated',
        TAG_DELETED: 'Folder Deleted',

        TAG_ADD: 'Add a new Folder',

        TAG_ACTION: 'Action: {{ ::tag.entityId }}',
        TAG_CONTRACT: 'Contract: {{ ::tag.entityId }}',
        TAG_JOB: 'Job: {{ ::tag.entityId }}',
        TAG_ASSET: 'Asset: {{ ::tag.entityId }}',
        TAG_SUBASSET: 'Sub Asset: {{ ::tag.entityId }}',
        TAG_JOBREQUISITEANSWER: 'Job Requisite Answer',
        TAG_PLANNEDJOB: 'Planned Job: {{ ::tag.entityId }}',
        TAG_QUOTE: 'Quote: {{ ::tag.entityId }}',
        TAG_QUOTEOPTION: 'Quote Option',
        TAG_QUOTEREQUEST: 'Quote Request',
        TAG_REACTIVEJOB: 'Job: {{ ::tag.entityId }}',
        TAG_SITE: '{{ ::tag.name }}',
        TAG_SPILLAGE: 'Spillage: {{ ::tag.entityId }}',
        TAG_PURCHASEORDER: 'Purchase Order: {{ ::tag.entityId }}',
        TAG_PURCHASEINVOICE: 'Purchase Invoice: {{ ::tag.entityId }}',
        TAG_AUDIT: 'Audit: {{ ::tag.entityId }}',

        AND_MORE: '...and {{ ::number | number:0 }} more',
        TAG_EXPORT: 'Export',

        TAG_EXCEEDS_CHAR_LIMIT: 'Tag exceeds the character limit of 255 characters.',
        TAG_CHAR_MIN_LIMIT: 'Tag name should have at least 1 character',
        TAG_MISSING_NAME: 'Tag name is missing',
    });
});
