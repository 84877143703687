(function () {
    'use strict';

    angular
        .module('elogbooks.admin.client-billing')
        .controller('ClientBillingPortfolioVariationsController', ClientBillingPortfolioVariationsController);

    ClientBillingPortfolioVariationsController.$inject = [
        '$state',
        '$stateParams',
        '$q',
        'apiClient',
        'messenger',
        'clientBillingResponse',
        'portfolioVariationsStartDate',
        'moment'
    ];

    function ClientBillingPortfolioVariationsController(
        $state,
        $stateParams,
        $q,
        apiClient,
        messenger,
        clientBillingResponse,
        portfolioVariationsStartDate,
        moment
    ) {
        var vm = this;
        vm.loading = false;
        vm.variations = $stateParams.variationsResponse;
        vm.paginationLimit = $stateParams.limit;
        vm.siteCollectionResponse = null;
        vm.data = {
            month1: $stateParams.month1 ? new Date($stateParams.month1) : null,
            month2: $stateParams.month2 ? new Date($stateParams.month2) : null,
        };
        vm.submitPortfolioVariationsDates = submitPortfolioVariationsDates;
        vm.loadData = loadData;
        vm.resetData = resetData;

        var minDate = new Date();
        minDate.setDate(1);

        if (portfolioVariationsStartDate.year && portfolioVariationsStartDate.month) {
            minDate.setFullYear(portfolioVariationsStartDate.year);
            minDate.setMonth((portfolioVariationsStartDate.month -1));
        } else {
            minDate.setFullYear(minDate.getFullYear());
            minDate.setMonth(minDate.getMonth());
        }

        var maxDate = new Date();
        maxDate.setDate(1);

        vm.datepickerOptions = {
            minMode: 'month',
            minDate: minDate,
            maxDate: maxDate,
        };
        vm.loadData();

        function submitPortfolioVariationsDates(page) {
            if (
                vm.data.month1 &&
                vm.data.month2 &&
                clientBillingResponse.getLink('get-portfolio-variations')
            ) {
                if (vm.data.month1 >= vm.data.month2) {
                    messenger.error('PORTFOLIO_VARIATION_FORM_ERROR_MSG');

                    return;
                }

                // If the dates are the same, pass the variations response to the next state
                const variationsResponse = vm.data.month1.toDateString() === new Date($stateParams.month1).toDateString() &&
                                            vm.data.month2.toDateString() === new Date($stateParams.month2).toDateString()
                                            ? vm.variations : null;

                $state.go(
                    'dashboard.admin.client-billing.portfolio-variations',
                    angular.extend({}, $stateParams, {
                        month1: vm.data.month1.toISOString(),
                        month2: vm.data.month2.toISOString(),
                        page: page || $stateParams.page,
                        variationsResponse: variationsResponse,
                    }),
                    { reload: false }
                );
            }
        }

        function loadData() {
            if (
                vm.data.month1 &&
                vm.data.month2 &&
                clientBillingResponse.getLink('get-portfolio-variations')
            ) {
                if (vm.data.month1 >= vm.data.month2) {
                    messenger.error('PORTFOLIO_VARIATION_FORM_ERROR_MSG');

                    return;
                }

                vm.loading = true;
                const requests = {};
                vm.dataDate1 = moment(vm.data.month1, 'YYYYMM').format('MMMM YYYY');
                vm.dataDate2 = moment(vm.data.month2, 'YYYYMM').format('MMMM YYYY');

                if (!vm.variations) {
                    requests.portfolioVariationsRequest = apiClient.get(
                        clientBillingResponse.getLink('get-portfolio-variations'),
                        {
                            month1: vm.data.month1.toISOString(),
                            month2: vm.data.month2.toISOString(),
                        }
                    );
                }

                requests.portfolioVariationsSitesListRequest = apiClient.get(
                    clientBillingResponse.getLink('get-portfolio-variations-sites-list'),
                    {
                        portfolioVariationsList: vm.data.month1.getFullYear() + '-' + (vm.data.month1.getMonth()+1) + '-01' + ',' + vm.data.month2.getFullYear() + '-' + (vm.data.month2.getMonth()+1) + '-01',
                        page: $stateParams.page,
                        limit: $stateParams.limit,
                        order: '-lastStatusChange',
                        datagroup: 'list',
                    }
                );

                $q.all(requests).then(function (responses) {
                    if (responses.portfolioVariationsRequest) {
                        vm.variations = responses.portfolioVariationsRequest.portfolioVariations;
                    }

                    if (responses.portfolioVariationsSitesListRequest) {
                        vm.siteCollectionResponse = responses.portfolioVariationsSitesListRequest;
                    }

                    vm.loading = false;
                });
            }
        }

        function resetData() {
            vm.variations = vm.siteCollectionResponse = null;
        }
    }
})();
