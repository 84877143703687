(function () {
    'use strict';

    angular
        .module('elogbooksServices')
        .service('elbVisibilityService', ElbVisibilityService);

    ElbVisibilityService.$inject = ['$translate'];

    function ElbVisibilityService ($translate) {
        return {
            getVisibilities: function () {
                return ['public', 'partnerships', 'occupiers'];
            },
            getVisibilitiesAsObject: function () {
                return [
                    { title: $translate.instant('FILE_VISIBILITY_PUBLIC'), value: 'public' },
                    { title: $translate.instant('FILE_VISIBILITY_PRIVATE'), value: 'private' },
                    { title: $translate.instant('FILE_VISIBILITY_PARTNERSHIPS'), value: 'partnerships' },
                    { title: $translate.instant('FILE_VISIBILITY_OCCUPIERS'), value: 'occupiers' }
                ];
            }
        };
    }
})();
