(function() {
    'use strict';

    angular
        .module('elogbooks.common.contracts', []);
})();

/**
 * Note:
 *
 * This is a global function. It was intended to be a service that would then be injected
 * in to the routing files / functions, but it appears that services cannot be injected in
 * to the routing functions. An unknown provider error occurs.
 */
function elbContractsRouting () {
    var self = this,
        config = {},
        $stateProvider;

    this.addRoutes = addRoutes;

    function getView(view, viewObject) {
        var result = {};
            result[view] = viewObject;

        if (config.isBookable) {
            result = parseBook(result);
        }

        return result;
    }

    function getMultipleViews(views) {
        var result = {};

        angular.forEach(views, function(view, $index) {
            result[view.name] = view.value;
        });

        if (config.isBookable) {
            result = parseBook(result);
        }

        return result;
    }

    function getLabel(translationKey) {
        return { label: '{{ ::"' + translationKey + '" | translate }}' };
    }

    /**
     * Usage:
     *     elbContractsRouting().addRoutes(
     *         $stateProvider,
     *         'dashboard.user.contracts',
     *         true || false
     *     );
     *
     * @param {$stateProvider} $stateProvider
     * @param {String} baseRoute
     * @param {Boolean} isBookable
     */
    function addRoutes(_$stateProvider, baseRoute, isBookable) {

        config = {
            'isBookable': isBookable,
            'baseRoute': baseRoute,
            'baseView': '@' + baseRoute,
            'baseBookViewLeft': 'elogbooks-left@' + baseRoute,
            'baseBookViewRight': 'elogbooks-right@' + baseRoute,
            'workflowRoute': baseRoute + '.list.details.workflow'
        };

        if (!isBookable) {
            config.baseBookViewLeft = config.baseView;
            config.baseBookViewRight = config.baseView;
        }

        $stateProvider = _$stateProvider;

        registerRoutes();
        registerRoutesAdd();
        registerRoutesDetails();
        registerRoutesDetailsWorkflow();
        registerRoutesDetailsP2P();
        registerRoutesDetailsFiles();
        registerRoutesDetailsVersions();
        registerRoutesCompareVersions();
    }

    function registerRoutes() {
        var baseRouteObject = {
            abstract: true,
            templateUrl: '/shared-templates/view.html',
            ncyBreadcrumb: {
                skip: true
            }
        };

        if (config.isBookable) {
            delete baseRouteObject.templateUrl;
            baseRouteObject.template = '<bookerize></bookerize>';
        }

        $stateProvider
            .state(config.baseRoute, baseRouteObject)
            .state(config.baseRoute + '.list', {
                url: '/contracts?contractPage&contractLimit&contractOrder&contractSite&contractServiceProvider&contractId&contractName&contractExternalReference&contractSiteIds&contractStatus&beyondSLA&spResource',
                parent: config.baseRoute,
                views: getView(config.baseBookViewLeft, {
                    templateUrl: '/modules/common/contracts/contracts-list.html',
                    controller: 'ContractsController',
                    controllerAs: 'vm'
                }),
                params: {
                    contractPage: '1',
                    contractLimit: '30',
                    contractOrder: '-id',
                    contractId: null,
                    contractSite: null,
                    contractSiteIds: {array: true},
                    contractName: null,
                    contractExternalReference: null,
                    contractServiceProvider: null,
                    contractStatus: {array: true},
                    contractExpiresAt: null,
                    contractReviewBeforePeriodDate: null,
                    expiryBeforePeriodDate: null,
                    beyondSLA: null,
                    spResource: null,
                    actionParam: null
                },
                resolve: {
                    contractCollectionResponse: function ($stateParams, apiClient, user, selectedSiteResponse, siteResponse, globalFilterParamsService) {
                         var params = {
                            page: $stateParams.contractPage,
                            limit: $stateParams.contractLimit,
                            id: $stateParams.contractId,
                            order: $stateParams.contractOrder,
                            site: $stateParams.contractSite,
                            'siteIds[]': $stateParams.contractSiteIds,
                            serviceProvider: $stateParams.contractServiceProvider,
                            name: $stateParams.contractName,
                            externalReference: $stateParams.contractExternalReference,
                            'status[]': $stateParams.contractStatus,
                            expiresAt: $stateParams.contractExpiresAt,
                            reviewBeforePeriodDate: $stateParams.contractReviewBeforePeriodDate,
                            expiryBeforePeriodDate: $stateParams.expiryBeforePeriodDate,
                            beyondSLA: $stateParams.beyondSLA
                        };

                        if (selectedSiteResponse) {
                            params.site = selectedSiteResponse.getData().id;
                        } else {
                            params.site = null;
                        }

                        if (siteResponse.id !== undefined) {
                            params.site = siteResponse.id;
                        }

                        if ($stateParams.spResource !== null) {
                            params.serviceProvider = $stateParams.spResource;
                        }

                        if (params.serviceProvider && $stateParams.spResource === null) {
                            params.serviceProvider = JSON.parse($stateParams.contractServiceProvider).v;
                        }

                        params = angular.extend({}, params, globalFilterParamsService.getLocalStorageFilters());

                        if (user.getLink('contracts')) {
                            return apiClient.get(user.getLink('contracts'), params).then(function(response) {
                                return response || apiClient.noResourceFound();
                            });
                        }
                    },
                    siteCollectionResponse: function ($stateParams, apiClient, user) {
                        var params = angular.extend({}, $stateParams);
                        if (params.siteRegions) {
                            params['siteRegions[]'] = params.siteRegions;
                        }

                        if ($stateParams.siteResource) {
                            return null;
                        } else {
                            return apiClient.get(user.getLink('sites'), params).then(function (response) {
                                return response || apiClient.noResourceFound();
                            });
                        }
                    },
                    serviceProviderCollectionResponse: function (apiClient, user, $stateParams) {
                         if ($stateParams.spResource) {
                             return null;
                         } else {
                             if (user.getLink('serviceproviders')) {
                                 return apiClient.get(user.getLink('serviceproviders')).then(function (response) {
                                     return response || apiClient.noResourceFound();
                                 });
                             }
                         }
                    },
                    config: function(user, userManager) {
                        /**
                         * While unused, user is required as a dependency here to prevent race conditions against userManager.
                         * Do not remove it!
                         */
                        return {
                            isHelpdesk: userManager.hasPermission('site_permission_helpdesk')
                        };
                    }
                },
                ncyBreadcrumb: getLabel('CONTRACTS')
            });
    }

    function registerRoutesAdd() {
        $stateProvider
            .state(config.baseRoute + '.list.add', {
                url: '/add',
                parent: config.baseRoute + '.list',
                views: getView(config.baseBookViewRight, {
                    templateUrl: '/modules/common/contracts/add-edit/contract-form.html',
                    controller: 'ContractAddEditController',
                    controllerAs: 'vm'
                }),
                resolve: {
                    contractResponse: function () {
                        return {};
                    },
                    siteResponse: function(apiClient, selectedSiteResponse, $stateParams, siteResponse) {
                        if (siteResponse && siteResponse.id) {
                            return siteResponse;
                        } else if (selectedSiteResponse) {
                            return selectedSiteResponse;
                        } else if ($stateParams.siteResource) {
                            return apiClient.get($stateParams.siteResource.decode()).then(function(response) {
                                return response || apiClient.noResourceFound();
                            });
                        }

                        return;
                    },
                    partnershipCollectionResponse: function (apiClient, selectedSiteResponse) {
                        if (selectedSiteResponse) {
                            return apiClient.get(selectedSiteResponse.getLink('partnerships')).then(function (response) {
                                return response.getData().partnerships;
                            });
                        }

                        return [];
                    },
                    serviceProviderCollectionResponse: function () {
                        return {};
                    },
                    serviceTypesCollectionResponse: function (apiClient, rootResourceResponse) {
                        return apiClient.get(rootResourceResponse.getLink('servicetypes')).then(function (response) {
                            return response;
                        });
                    },
                    contactDocumentResponse: function () {
                        return {};
                    },
                    config: function(config, contractCollectionResponse) {
                        return angular.extend(config, {
                            editMode: false,
                            hexagonNominalCodesUrl: contractCollectionResponse.getLink('hexagon-nominal-codes')
                        });
                    },
                },
                ncyBreadcrumb: getLabel('ADD'),
                scroll: false
            });
    }

    function registerRoutesDetails() {
        $stateProvider
            .state(config.baseRoute + '.list.details', {
                abstract: true,
                url: '/{contractResource}',
                parent: config.baseRoute + '.list',
                views: getMultipleViews([
                    {
                        name: config.baseBookViewRight,
                        value: {
                             templateUrl: '/modules/common/contracts/details/contract-details.html',
                            controller: 'CommonContractDetailsController',
                            controllerAs: 'vm'
                        }
                    },
                    {
                        name: 'feed@' + config.baseRoute + '.list.details',
                        value: {
                            templateUrl: '/modules/common/contracts/details/event/contract-feed.html',
                            controller: 'CommonContractDetailsEventFeedController',
                            controllerAs: 'vm'
                        }
                    }
                ]),
                resolve: {
                   contractResponse: function (apiClient, $stateParams) {
                        return apiClient.get($stateParams.contractResource.decode(), { datagroup: 'details' }).then(function(response) {
                            return response || apiClient.noResourceFound();
                        });
                    },
                    eventCollectionResponse: function (apiClient, contractResponse) {
                        if (contractResponse.getLink('events')) {
                            return apiClient.get(contractResponse.getLink('events'), { datagroup: 'details' }).then(function(response) {
                                return response || apiClient.noResourceFound();
                            });
                        }
                    }
                },
                ncyBreadcrumb: {
                    label: '{{ $resolves().contractResponse.id }}'
                }
            });
    }

    function registerRoutesDetailsWorkflow() {
        $stateProvider
            .state(config.workflowRoute, {
                url: '/workflow',
                parent: config.baseRoute + '.list.details',
                views: getView('info@' + config.baseRoute + '.list.details', {
                    templateUrl: '/modules/common/contracts/details/workflow/contract-status.html',
                }),
                resolve: {
                    scopeData: function() {
                        return {};
                    }
                },
                ncyBreadcrumb: getLabel('STATUS')
            })
            .state(config.workflowRoute + '.edit-contract', {
                url: '/edit/{actionParam}',
                parent: config.workflowRoute,
                views: getView(config.baseBookViewRight, {
                    templateUrl: '/modules/common/contracts/add-edit/contract-form.html',
                    controller: 'ContractAddEditController',
                    controllerAs: 'vm'
                }),
                resolve: {
                    siteResponse: function(apiClient, contractResponse) {
                        return apiClient.get(contractResponse.getLink('site'), { datagroup: 'details' }).then(function(response) {
                                return response || apiClient.noResourceFound();
                        });
                    },
                    partnershipCollectionResponse: function () {
                        return {};
                    },
                    contactDocumentResponse: function (apiClient, contractResponse) {
                        if (contractResponse.getLink('contractdocumentfile')) {
                            return apiClient.get(contractResponse.getLink('contractdocumentfile'), { datagroup: 'details' }).then(function(response) {
                                return response || apiClient.noResourceFound();
                            });
                        }
                    },
                    serviceProviderCollectionResponse: function () {
                        return {};
                    },
                    serviceTypesCollectionResponse: function (apiClient, rootResourceResponse) {
                        return apiClient.get(rootResourceResponse.getLink('servicetypes')).then(function (response) {
                            return response;
                        });
                    },
                    config: function(config, $stateParams, contractResponse, contractCollectionResponse) {
                        return angular.extend(config, {
                            editMode: true,
                            contractResource: $stateParams.actionParam === 'renew' ? contractResponse.getLink('renew') : contractResponse.getLink('edit'),
                            hexagonNominalCodesUrl: contractCollectionResponse.getLink('hexagon-nominal-codes')
                        });
                    }
                },
                ncyBreadcrumb: getLabel('EDIT'),
                scroll: false
            })
            .state(config.workflowRoute + '.submit', {
                url: '/submit',
                parent: config.workflowRoute,
                views: getView(config.baseBookViewRight, {
                    templateUrl: '/modules/common/contracts/details/workflow/_wrapper.html',
                    controller: 'ContractSubmitController',
                    controllerAs: 'vm'
                }),
                ncyBreadcrumb: getLabel('CONTRACT_WORKFLOW_SUBMIT')
            })
            .state(config.workflowRoute + '.cancel', {
                url: '/cancel',
                parent: config.workflowRoute,
                views: getView(config.baseBookViewRight, {
                    templateUrl: '/modules/common/contracts/details/workflow/_wrapper.html',
                    controller: 'ContractCancelController',
                    controllerAs: 'vm'
                }),
                ncyBreadcrumb: getLabel('CONTRACT_WORKFLOW_CANCEL')
            })
            .state(config.workflowRoute + '.terminate', {
                url: '/terminate',
                parent: config.workflowRoute,
                views: getView(config.baseBookViewRight, {
                    templateUrl: '/modules/common/contracts/details/workflow/_wrapper.html',
                    controller: 'ContractTerminateController',
                    controllerAs: 'vm'
                }),
                ncyBreadcrumb: getLabel('CONTRACT_WORKFLOW_TERMINATE')
            })
            .state(config.workflowRoute + '.approve', {
                url: '/approve',
                parent: config.workflowRoute,
                views: getView(config.baseBookViewRight, {
                    templateUrl: '/modules/common/contracts/details/workflow/_wrapper.html',
                    controller: 'ContractApproveController',
                    controllerAs: 'vm'
                }),
                ncyBreadcrumb: getLabel('CONTRACT_WORKFLOW_APPROVE')
            })
            .state(config.workflowRoute + '.reject', {
                url: '/reject',
                parent: config.workflowRoute,
                views: getView(config.baseBookViewRight, {
                    templateUrl: '/modules/common/contracts/details/workflow/_wrapper.html',
                    controller: 'ContractRejectController',
                    controllerAs: 'vm'
                }),
                ncyBreadcrumb: getLabel('CONTRACT_WORKFLOW_REJECT')
            })
            .state(config.workflowRoute + '.regress', {
                url: '/regress',
                parent: config.workflowRoute,
                views: getView(config.baseBookViewRight, {
                    templateUrl: '/modules/common/contracts/details/workflow/_wrapper.html',
                    controller: 'ContractRegressController',
                    controllerAs: 'vm'
                }),
                ncyBreadcrumb: getLabel('CONTRACT_WORKFLOW_REGRESS')
            });
    }

    function registerRoutesDetailsFiles() {
        $stateProvider
            .state(config.baseRoute + '.list.details.files', {
                url: '/files',
                parent: config.baseRoute + '.list.details',
                views: getView('info@' + config.baseRoute + '.list.details', {
                    templateUrl: '/modules/common/files/files-list.html',
                    controller: 'CommonFilesListController',
                    controllerAs: 'vm'
                }),
                params: {
                    documentType: null
                },
                resolve: {
                    tagCollectionResponse: function (apiClient, rootResourceResponse) {
                        return apiClient.get(rootResourceResponse.getLink('tags'), { limit: 30 }).then(function (response) {
                            return response || apiClient.noResourceFound();
                        });
                    },
                    siteResponse: function (apiClient, contractResponse) {
                        return contractResponse.getResource('site') || apiClient.noResourceFound();
                    },
                    config: function(config) {
                        return angular.extend(config, {
                            showStatutoryType: false,
                            showIsContract: true
                        });
                    },
                    fileCollectionResponse: function(apiClient, contractResponse) {
                        return apiClient.get(contractResponse.getLink('files')).then(function (response) {
                            return response || apiClient.noResourceFound();
                        });
                    },
                    documentTypeCollectionResponse : function (apiClient, rootResourceResponse) {
                        return apiClient.get(rootResourceResponse.getLink('documenttypes'), {status: 'active', order: 'name'})
                            .then(function (response) {
                                return response || apiClient.noResourceFound();
                            });
                    },
                    jobResponse: function () {
                        return null;
                    },
                    serviceProviderResponse: function () {
                        return null;
                    },
                    operativeCollectionResponse: function () {
                        return null;
                    }
                },
                ncyBreadcrumb: getLabel('FILES'),
                scroll: false
            })
            .state(config.baseRoute + '.list.details.files.details', {
                url: '/details/{fileResource}',
                parent: config.baseRoute + '.list.details.files',
                views:  getView(
                    'files@' + config.baseRoute + '.list.details', {
                        templateUrl: '/modules/common/files/details/files-details.html',
                        controller: 'CommonFilesDetailsController',
                        controllerAs: 'vm'
                    }
                ),
                resolve: {
                    fileResponse: function (apiClient, $stateParams) {
                        return apiClient.get($stateParams.fileResource.decode()).then(function (response) {
                            return response || apiClient.noResourceFound();
                        });
                    },
                    config: function(config) {
                        return angular.extend(config, {
                            showVisibility: false,
                        });
                    }
                },
                ncyBreadcrumb: {
                    label: '{{ ::$resolves().fileResponse.title }}'
                }
            })
            .state(config.baseRoute + '.list.details.files.add', {
                url: '/add',
                parent: config.baseRoute + '.list.details.files',
                views: getView (
                    'info@' + config.baseRoute + '.list.details', {
                        templateUrl: '/modules/common/files/add-edit/files-add-edit.html',
                        controller: 'CommonFilesAddEditController',
                        controllerAs: 'vm'
                    }
                ),
                resolve: {
                    fileResponse: function() {
                        return {};
                    },
                    tagCollectionResponse: function(apiClient, rootResourceResponse) {
                        return apiClient.get(rootResourceResponse.getLink('tags'), { limit: 30 }).then(function(response) {
                            return response || apiClient.noResourceFound();
                        });
                    },
                    config: function(siteResponse, contractResponse, config) {
                        return angular.extend(config, {
                            entitySite: siteResponse,
                            associatedEntity: contractResponse,
                            fileCreateLink: contractResponse.getLink('files')
                        });
                    },
                    integrationDocTypesResponse: function() {
                        return null;
                    }
                },
                ncyBreadcrumb: {
                    label: '{{ ::"ADD" | translate }}'
                }
            })
            .state(config.baseRoute + '.list.details.files.details.edit', {
                url: '/edit',
                parent: config.baseRoute + '.list.details.files.details',
                views:  getView(
                    'files@' + config.baseRoute + '.list.details', {
                        templateUrl: '/modules/common/files/add-edit/files-add-edit.html',
                        controller: 'CommonFilesAddEditController',
                        controllerAs: 'vm'
                    }
                ),
                resolve: {
                    sitesCollectionResponse: function () {
                        return {};
                    },
                    integrationDocTypesResponse: function() {
                        return null;
                    }
                },
                ncyBreadcrumb: {
                    label: '{{ ::"EDIT" | translate }}'
                }
            });
    }

    function registerRoutesDetailsVersions() {
        $stateProvider
            .state(config.baseRoute + '.list.details.versions', {
                url: '/versions?versionPage&versionLimit',
                parent: config.baseRoute + '.list.details',
                views: getView('info@' + config.baseRoute + '.list.details', {
                    templateUrl: '/modules/common/contracts/details/versions/version-list.html',
                    controller: 'VersionListController',
                    controllerAs: 'vm'
                }),
                params: {
                    versionPage: '1',
                    versionLimit: '10'
                },
                resolve: {
                    versionCollectionResponse: function (apiClient, contractResponse, $stateParams) {
                        var params = {
                            page: $stateParams.versionPage,
                            limit: $stateParams.versionLimit
                        };

                        return apiClient.get(contractResponse.getLink('versions'), params).then(function (response) {
                            return response || apiClient.noResourceFound();
                        });
                    }
                },
                ncyBreadcrumb: getLabel('VERSIONS')
            });
    }

    function registerRoutesDetailsP2P() {
        $stateProvider
            .state(config.baseRoute + '.list.details.p2p', {
                url: '/p2p',
                parent: config.baseRoute + '.list.details',
                views: getView('info@' + config.baseRoute + '.list.details', {
                    templateUrl: '/modules/common/contracts/details/p2p/p2p.html',
                    controller: 'CommonContractDetailsP2PController',
                    controllerAs: 'vm'
                }),
                params: {
                    p2pPage: '1',
                    p2pLimit: '30'
                },
                resolve: {
                    nominalCodingsCollectionResponse: function (apiClient, contractResponse, $stateParams) {
                        var params = {
                            page: $stateParams.p2pPage,
                            limit: $stateParams.p2pLimit
                        };

                        return apiClient.get(contractResponse.getLink('nominalcodings'), params).then(function (response) {
                            return response || apiClient.noResourceFound();
                        });
                    }
                },
                ncyBreadcrumb: getLabel('P2P')
            });
    }

    function registerRoutesCompareVersions() {
        $stateProvider
            .state(config.baseRoute + '.list.details.compare', {
                url: '/compare/{versionNumber}/{fakeVersion}/{byDate}',
                abstract: true,
                parent: config.baseRoute + '.list.details',
                views: getView(config.baseView,{
                    templateUrl: '/modules/common/contracts/details/versions/compare/compare.html',
                    controller: 'CompareController',
                    controllerAs: 'vm'
                }),
                resolve: {
                    oldContractResponse: function (apiClient, contractResponse, $stateParams) {
                        var params = {
                            version: $stateParams.versionNumber,
                            datagroup: 'details'
                        };

                        return apiClient.get(contractResponse.getLink('self'), params).then(function(response) {
                            return response || apiClient.noResourceFound();
                        });
                    },
                    oldEventCollection: function (apiClient, oldContractResponse, $stateParams) {
                        var params = {
                            createdBefore: $stateParams.byDate,
                            datagroup: 'details'
                        };

                        if (oldContractResponse.getLink('events')) {
                            return apiClient.get(oldContractResponse.getLink('events'), params).then(function(response) {
                                return response || apiClient.noResourceFound();
                            });
                        }
                    }
                },
                ncyBreadcrumb: getLabel('COMPARE')
            })
            .state(config.baseRoute + '.list.details.compare.details', {
                url: '/details',
                abstract: true,
                parent: config.baseRoute + '.list.details.compare',
                ncyBreadcrumb: {
                    skip: true
                }
            })
            .state(config.baseRoute + '.list.details.compare.details.files', {
                url: '/files',
                views: getView('extras@' + config.baseRoute + '.list.details.compare', {
                    templateUrl: '/modules/common/contracts/details/versions/compare/files/files-list.html',
                    controller: 'CompareFilesController',
                    controllerAs: 'vm'
                }),
                params: {
                    filePage: '1',
                    fileLimit: '10',
                },
                resolve: {
                    fileResponse: function(apiClient, contractResponse) {
                        return apiClient.get(contractResponse.getLink('files'), {contractVersion: 'current'}).then(function (response) {
                            return response || apiClient.noResourceFound();
                        });
                    },
                    oldFileResponse: function(apiClient, contractResponse, $stateParams) {
                        return apiClient.get(contractResponse.getLink('files'), {contractVersion: $stateParams.versionNumber}).then(function (response) {
                            return response || apiClient.noResourceFound();
                        });
                    },
                    supportingFileResponse: function(apiClient, contractResponse, $stateParams) {
                        var params = {
                            excludeContract: true,
                            page: $stateParams.filePage,
                            limit: $stateParams.fileLimit
                        };
                        return apiClient.get(contractResponse.getLink('files'), params).then(function (response) {
                            return response || apiClient.noResourceFound();
                        });
                    },
                    supportingOldFileResponse: function(apiClient, contractResponse, $stateParams) {
                        var params = {
                            excludeContract: true,
                            createdBefore: $stateParams.byDate,
                            page: $stateParams.filePage,
                            limit: $stateParams.fileLimit
                        };
                        return apiClient.get(contractResponse.getLink('files'), params).then(function (response) {
                            return response || apiClient.noResourceFound();
                        });
                    }
                },
                parent: config.baseRoute + '.list.details.compare.details',
                ncyBreadcrumb: {
                    skip: true
                }
            })
            .state(config.baseRoute + '.list.details.compare.details.events', {
                url: '/events',
                views: getView('extras@' + config.baseRoute + '.list.details.compare', {
                    templateUrl: '/modules/common/contracts/details/versions/compare/compare-feed.html',
                    controllerAs: 'vm'
                }),
                parent: config.baseRoute + '.list.details.compare.details',
                ncyBreadcrumb: {
                    skip: true
                }
            });
    }

    return this;
}
