(function (){
    'use strict';

    angular
        .module('elogbooks.admin.imports')
        .config(registerRoutes);

    function registerRoutes($stateProvider) {
        $stateProvider
            .state('dashboard.admin.imports', {
                url: '/imports',
                abstract: true,
                templateUrl: 'shared-templates/view.html',
                ncyBreadcrumb: {
                    skip: true
                },
                data: {
                    permissions: ['user_permission_manage_imports', 'user_permission_import_sfg_xml', 'user_permission_client_admin_manage_imports']
                }
            })
            .state('dashboard.admin.imports.list', {
                url: '/list?page&limit&order&type',
                parent: 'dashboard.admin.imports',
                data: {
                    permissions: ['user_permission_manage_imports', 'user_permission_client_admin_manage_imports']
                },
                resolve: {
                    importsCollectionResponse: function (apiClient, rootResourceResponse, $stateParams) {
                        var params = angular.copy($stateParams);
                        params['excludeTypes[]'] = ['pcd_cbre', 'pcd_jll', 'scd'];
                        return apiClient.get(rootResourceResponse.getLink('imports'), params);
                    }
                },
                params: {
                    page: '1',
                    limit: '30',
                    type: null
                },
                views: {
                    '@dashboard': {
                        templateUrl: '/modules/common/imports/list/list.html',
                        controller : 'CommonImportListController',
                        controllerAs: 'vm',
                    }
                },
                ncyBreadcrumb: {
                    label: '{{ ::"IMPORTS" | translate }}'
                }
            })
            .state('dashboard.admin.imports.list.details', {
                url: '/{importResource}/details?logsPage&logsLimit&logsOrder&logsType&logsMessage',
                parent: 'dashboard.admin.imports.list',
                data: {
                    permissions: ['user_permission_manage_imports', 'user_permission_client_admin_manage_imports']
                },
                views: parseBook({
                    'elogbooks-right@dashboard': {
                        templateUrl: '/modules/common/imports/details/details.html',
                        controller : 'CommonImportDetailsController',
                        controllerAs: 'vm'
                    }
                }),
                params: {
                    logsPage: '1',
                    logsLimit: '30',
                    logsType: null,
                    logsMessage: null
                },
                resolve: {
                    importResponse: function (apiClient, $stateParams) {
                        return apiClient.get($stateParams.importResource.decode());
                    },
                    importLogsCollectionResponse: function (apiClient, importResponse, $stateParams) {
                        var params = {
                            page: $stateParams.logsPage,
                            limit: $stateParams.logsLimit,
                            type: $stateParams.logsType,
                            message: $stateParams.logsMessage
                        };

                        return apiClient.get(importResponse.getLink('logs'), params);
                    }
                },
                ncyBreadcrumb: {
                    label: '{{ ::"IMPORT_DETAILS_LIST" | translate }}'
                }
            })
            .state('dashboard.admin.imports.list.add', {
                url: '/add',
                parent: 'dashboard.admin.imports.list',
                data: {
                    permissions: ['user_permission_manage_imports', 'user_permission_client_admin_manage_imports']
                },
                views: {
                    '@dashboard': {
                        templateUrl: '/modules/common/imports/add/import-add.html',
                        controller: 'CommonAddImportController',
                        controllerAs: 'vm'
                    }
                },
                resolve: {
                    sectionResource: function() {
                        return;
                    }
                },
                ncyBreadcrumb: {
                    label: '{{ ::"IMPORT_ADD" | translate }}'
                }
            });
    }
})();
