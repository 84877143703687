/**
 * page Service
 */
angular.module('elogbooksServices').service('pageService', function () {
    this.calculatePageOnDelete = calculatePageOnDelete;

    /**
     * Calculate the page number when you delete an item
     *
     * @param {object} collection - Collection response
     * @returns int page
     */
    function calculatePageOnDelete(collection) {
        // @ts-ignore
        var page = parseInt((collection.count - 1) / collection.limit);

        if (((collection.count - 1) % collection.limit) !== 0) {
            page++;
        }

        return page || 1;
    }
});
