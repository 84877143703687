app.config( function($translateProvider) {
    $translateProvider.translations('nl', {
        METER_SUPPLY_INFORMATION: 'Leveringsinformatie',

        ADD_NEW_METER_SUPPLY: 'Voeg nieuwe leveringsinformatie toe',
        EDIT_METER_SUPPLY: 'Wijzig levering informatie',

        METER_SUPPLY_LIST: 'Soort levering meter',
        METER_SUPPLIES_LIST: 'Meter leveringen',
        METER_SUPPLY_ADD: 'Voeg nieuwe leveringsinformatie toe',
        METER_SUPPLY_EDIT: 'Wijzig leveringsinformatie',

        METER_SUPPLY_CREATED: 'Leveringsinformatie aangemaakt',
        METER_SUPPLY_UPDATED: 'Leveringsinformatie aangepast',
        METER_SUPPLY_DELETED: 'Leveringsinformatie verwijderd',
    });
});
