(function() {
    'use strict';

    angular
        .module('elogbooks')
        .config(Translations);

    function Translations($translateProvider) {
        $translateProvider.translations('en-gb', {
            PRICING_SCHEDULES: 'Pricing Schedules',
            PRICING_SCHEDULE: 'Pricing Schedule',
            PRICING_SCHEDULES_LIST: 'Pricing Schedules',
            PRICING_SCHEDULES_ADD: 'Add a new Pricing Schedule',
            PRICING_SCHEDULES_EDIT: 'Edit a Pricing Schedule',
            PRICING_SCHEDULES_CREATED: 'Pricing Schedule created',
            PRICING_SCHEDULES_UPDATED: 'Pricing Schedule updated',
            PRICING_SCHEDULES_DELETED: 'Pricing Schedule deleted',
            PRICING_SCHEDULE_ACTIVATED: 'Pricing Schedule activated',
            PRICING_SCHEDULE_DEACTIVATED: 'Pricing Schedule deactivated',
            PRICING_SCHEDULE_COPIED: 'Pricing Schedule copied',
            PRICING_SCHEDULES_ITEMS_LIST: 'Items',
            PRICING_SCHEDULES_COPY_CONFIRMATION_MODAL_BODY: 'This will create a new Pricing Schedule with a copy of the information from this Pricing Schedule Version.',

            PRICING_SCHEDULES_IN_HOURS: 'In Hours',
            PRICING_SCHEDULES_IS_DEFAULT: 'Is Default',
            PRICING_SCHEDULES_LABOUR_RATES: 'Labour Rates',
            PRICING_SCHEDULES_STANDARD_CHARGES: 'Standard Charges',
            PRICING_SCHEDULES_MARKUP: 'Markup',
            PRICING_SCHEDULES_ITEMS: 'Items',
            PRICING_SCHEDULES_ITEMS_ADD: 'Add new Purchase Invoice',

            PRICING_SCHEDULES_LABOUR_TYPE: 'Labour Type',
            PRICING_SCHEDULES_LABOUR_OVERHEAD: 'Overhead',
            PRICING_SCHEDULES_LABOUR_HOURLY_RATE: 'Hourly Rate',
            PRICING_SCHEDULES_LABOUR_MILEAGE: 'Mileage',
            PRICING_SCHEDULES_LABOUR_TRAVEL_TIME: 'Travel Time',
            PRICING_SCHEDULES_LABOUR_LIST: 'Items',
            PRICING_SCHEDULES_LABOUR_ADD: 'Add new Labour Rate',
            PRICING_SCHEDULES_LABOUR_EDIT: 'Edit a Labour Rate',
            PRICING_SCHEDULES_LABOUR_UPDATED: 'Labour Rate updated',
            PRICING_SCHEDULES_LABOUR_DELETED: 'Labour Rate deleted',
            PRICING_SCHEDULES_LABOUR_CREATED: 'Labour Rate created',

            PRICING_SCHEDULES_NEW_VERSION_CREATED: 'New Version Created',
            PRICING_SCHEDULE_ACTIVE_AT: 'Active At',

            PRICING_SCHEDULE_ACTIVATED_ACTION: '<strong>{{ ::event._links["user"].title }}</strong> has Activated Version',
            PRICING_SCHEDULE_DEACTIVATED_ACTION: '<strong>{{ ::event._links["user"].title }}</strong> has Deactivated Version',
            PRICING_SCHEDULE_NEW_VERSION_ACTION: '<strong>{{ ::event._links["user"].title }}</strong> has Created a New Version',
            PRICING_SCHEDULE_CREATED_ACTION: '<strong>{{ ::event._links["user"].title }}</strong> created the Pricing Schedule',
            PRICING_SCHEDULE_UPDATED_ACTION: '<strong>{{ ::event._links["user"].title }}</strong> updated the Pricing Schedule',
            PRICING_SCHEDULE_INVALID_ACTIVE_DATE: 'Invalid Active Date',
            PRICING_SCHEDULE_ACTIVE_VERSION: 'Active Version',

            PRICING_SCHEDULES_CHARGE_DESCRIPTION: 'Charge Description',
            PRICING_SCHEDULES_INCLUSIVE_HOURS: 'Inclusive Hours',
            PRICING_SCHEDULES_APPLIED_BY_DEFAULT: 'Applied by default',

            PRICING_SCHEDULE_MARKUP_EDIT: 'Edit Markup',
            PRICING_SCHEDULE_MARKUP_UPDATED: 'Markup Updated',
            PRICING_SCHEDULE_MATERIALMARKUP: 'Material Markup',

            PRICING_SCHEDULES_STANDARD_CHARGES_ADD: 'Add new Standard Charge',
            PRICING_SCHEDULES_STANDARD_CHARGES_EDIT: 'Edit Standard Charge',
            STANDARD_CHARGE_CREATED: 'Standard Charge Created',
            STANDARD_CHARGE_UPDATED: 'Standard Charge Updated',
            STANDARD_CHARGE_DELETED: 'Standard Charge Deleted',

            FINANCE_STATUS_IN_QUERY: 'In Query',
            FINANCE_STATUS_DRAFT: 'Draft',
            FINANCE_STATUS_ORDERED: 'Ordered',
            FINANCE_STATUS_CANCELLED: 'Cancelled',
            FINANCE_STATUS_RECEIVED: 'Received',
            FINANCE_STATUS_COMPLETE: 'Complete',
            FINANCE_STATUS_READY_FOR_PAYMENT: 'Ready For Payment',
        });
    }
})();
