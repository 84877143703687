(function() {
    'use strict';

    angular
        .module('elogbooks.common.waste-management')
        .controller('WasteEntryInfoController', WasteEntryInfoController);

    WasteEntryInfoController.$inject = ['wasteEntryResponse', 'config'];

    function WasteEntryInfoController(wasteEntryResponse, config) {
        var vm = this;
        vm.wasteEntry = wasteEntryResponse;
        vm.config = config;
    }
})();