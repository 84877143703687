(function() {
    'use strict';

    angular
        .module('elogbooks.admin.site-associate-types')
        .controller('SiteAssociateTypeDetailsController', SiteAssociateTypeDetailsController);

    SiteAssociateTypeDetailsController.$inject = ['siteAssociateTypeResponse'];

    function SiteAssociateTypeDetailsController (siteAssociateTypeResponse) {
        var vm = this;
            vm.siteAssociateType = siteAssociateTypeResponse;
    }
})();
