angular.module('elogbooksServices').service('moveAlarmsModal', ['$uibModal', function ($uibModal) {
    return {
        open: function (itemsCollection, itemsCount) {
            return $uibModal.open({
                templateUrl: '/modules/common/modals/bulk-alarm-update/bulk-alarm-update-modal.html',
                controller: 'BulkAlarmUpdateController',
                controllerAs: 'vm',
                resolve: {
                    itemsCollection: function() {
                        return itemsCollection;
                    },
                    itemsCount: function() {
                        return itemsCount;
                    }

                }
            });
        }
    };
}]);
