app.config( function($translateProvider) {
    $translateProvider.translations('zh-hk', {
        METER_SUPPLY_INFORMATION: '儀表供應率資料',

        ADD_NEW_METER_SUPPLY: '新增儀表供應率',
        EDIT_METER_SUPPLY: '修改儀表供應率',

        METER_SUPPLY_LIST: '儀表供應率',
        METER_SUPPLIES_LIST: '儀表供應率',
        METER_SUPPLY_ADD: '新增儀表供應率',
        METER_SUPPLY_EDIT: '修改儀表供應率',

        METER_SUPPLY_CREATED: '儀表供應率已建立',
        METER_SUPPLY_UPDATED: '儀表供應率已更新',
        METER_SUPPLY_DELETED: '儀表供應率已刪除',
    });
});
