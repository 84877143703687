(function() {
    'use strict';

    angular
        .module('elogbooks.common.quote-requests')
        .controller('CommonQuoteRequestDetailsController', CommonQuoteRequestDetailsController);

    CommonQuoteRequestDetailsController.$inject = ['quoteRequestResponse'];

    function CommonQuoteRequestDetailsController(quoteRequestResponse) {
        var vm = this;
        vm.quoteRequestResponse = quoteRequestResponse;
    }
})();
