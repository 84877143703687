(function() {
    'use strict';

    angular
        .module('elogbooks')
        .config(Translations);

    function Translations($translateProvider) {
        $translateProvider.translations('en-gb', {
            ACTION: 'Action',
            ACTIONS: 'Actions',
            ACTION_LIST: 'Actions',
            MANAGE_ACTIONS: 'Manage Actions',
            ACTION_IMPORTS: 'Action Imports',
            ACTION_ADD: 'Add a new Action',
            ACTION_EDIT: 'Edit Action',

            CANNOT_CREATE_ACTION_SITE_ASSOCIATE_NOT_CORRECTLY_DEFINED: 'Cannot create Action, Site Associates incorrectly defined',

            ACTION_CREATED: 'Action created',
            ACTION_UPDATED: 'Action updated',
            ACTION_DELETED: 'Action deleted',
            PRISM: 'Prism',

            ACTIONS_OPEN: 'Open Actions',

            ACTION_STATUS_OPEN: 'Open',
            ACTION_STATUS_COMPLETED: 'Completed',
            ACTION_STATUS_CANCELLED: 'Cancelled',
            ACTION_STATUS_PAPERWORK_REJECTED: 'Paperwork Rejected',

            ACTION_ADD_SITE: 'Site',
            ACTION_ADD_CONTACT_DETAILS: 'Contact Details',
            ACTION_ADD_DETAILS: 'Action Details',

            ACTION_ID: 'Action {{ id }}',
            ACTION_DETAILS: 'Action Details',
            ACTION_STATUS: 'Status',
            COPY_DETAILS_TO_JOB: 'Copy to Job',
            ACTION_FILES: 'Action Files',
            INCLUDE_ACTION_FILES: 'Include files from Action',

            ACTION_CHANGE_ASSIGNEE_TO_ME: 'Become Assignee',

            DATE_DUE_START: 'Date Due Start',
            DATE_DUE_END: 'Date Due End',

            PAPERWORK_FOR_CLIENT_REVIEW: 'Paperwork for Client Review',
            ESCALATED_TO_CLIENT: 'Escalated to Client',
            FROM_JOB: 'From Job',
            EXTERNAL_SYSTEM: 'External System',
            FROM_AUDIT: 'From Audit',
            FROM_AUDIT_QUESTION: 'From Audit Question',
            ACTION_SUMMARY: 'Action Summary',
            JOB_COMPLETION_DATE: 'Job Completion Date',
            ACTION_ASSIGNEE_TYPE: 'Action Assignee Type',
            ACTIONS_SELECTED: 'Actions Selected',
            ACTION_SELECTED: 'Action Selected',
            CANCEL_ACTIONS: 'Cancel Actions',
            ALL_FILTERED_ACTIONS: 'All Filtered Actions',
            ACTIONS_POINT_IN_TIME: 'Actions at this point in time match your search criteria',
            ACTION_POINT_IN_TIME: 'Action at this point in time match your search criteria',
            ACTIONS_QUEUED_FOR_CANCELLATION: 'Actions Queued for Cancellation',
            ACTION_CANCELLED_ON_FILE_DELETION: 'Action has been Cancelled due to removing file.',
            ACTION_FILE_DELETED: 'Action file has been deleted.',
            VIEW_ACTIONS: 'View Actions',
            RAISE_ACTION: 'Raise Action'
        });
    }
})();
