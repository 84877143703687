(function() {
    'use strict';

    angular
        .module('elogbooks.user.timesheets')
        .controller('UserTimesheetsDetailsStatusController', UserTimesheetsDetailsStatusController);

    UserTimesheetsDetailsStatusController.$inject = ['timesheetWeek', 'timesheetData', 'timesheetsService'];

    function UserTimesheetsDetailsStatusController(timesheetWeek, timesheetData, timesheetsService) {
        var vm = this;
        vm.timesheetWeek = timesheetWeek;
        vm.timesheetData = timesheetData;
        vm.isTimeSheetApproved = timesheetsService.isTimeSheetApproved();
        vm.totalHours = 0;

        var days = timesheetsService.getDays();

        for (var timesheet in days ) {
            vm.totalHours += days[timesheet].totalMinutesWorked + days[timesheet].totalMinutesTravelled + days[timesheet].totalMiscMinutes;
        }
        vm.canSubmit = timesheetsService.hasDays();
    }
})();
