app.config(function ($translateProvider) {
    $translateProvider.translations('zh-hk', {
        GENERAL_SETTINGS: '一般',
        SETTINGS_UPDATED: '已更新設定',
        EMAIL_SETTINGS: '電郵',
        PASSWORD_SETTINGS: '密碼',
        JOB_SETTINGS: '工作',
        JOB_SETTINGS_VIEW: '工作設定',
        JOB_CHASE_SETTINGS_VIEW: '追蹤工作設定',
        CHASE_SETTINGS_VIEW: '追蹤設定',
        QUOTE_SETTINGS: '引用',
        QUOTE_SETTINGS_VIEW: '引用設定',
        CHASE_SETTINGS: '追蹤',
        SURVEY_SETTINGS: '問卷調查',
        SURVEY_SETTINGS_VIEW: '問卷調查設定',

        PERFORMANCE_REPORTING_SETTINGS: '性能報告',
        PERFORMANCE_SETTINGS_VIEW: '性能報告設定',
        FILE_SETTINGS: '檔案',
        FILE_SETTINGS_VIEW: '檔案設定',
        APP_SETTINGS: '程式',
        APP_SETTINGS_VIEW: '程式設定',

        SETTINGS_EDIT: '更改設定',
        GENERAL_SETTINGS_VIEW: '一般設定',
        GENERAL_LOGO: '系統標誌',
        GENERAL_OPERATING_HOURS: '營業時間',
        GENERAL_OPENING_TIME: '開始時間',
        GENERAL_CLOSING_TIME: '結束時間',
        GENERAL_TASKS_GENERATE_AHEAD: '預先生成作業',

        EMAIL_SETTINGS_VIEW: '電郵設定',
        EMAIL_HTML_SIGNATURE: '電郵簽署 (Html)',
        EMAIL_TEXT_SIGNATURE: '電郵簽署 (文字)',

        PASSWORD_SETTINGS_VIEW: '密碼設定',
        PASSWORD_DAYS_TILL_CHANGE_NOTIFICATION: '數日內更改',
        PASSWORD_DISALLOW_PREVIOUS_PASSWORDS: '舊密碼無效',
        PASSWORD_FORCE_CHANGE: '強制重新設定',
        PASSWORD_MIN_CHARACTERS: '最少字數',
        PASSWORD_REQUIRE_NUMBER: '需包含數目字',
        PASSWORD_REQUIRE_PUNCTUATION: '需包含標點符號',
        PASSWORD_REQUIRE_UPPERCASE: '需包含大階英文字',
        PASSWORD_COMPLEXITY: '密碼複雜度',
        PASSWORD_CHANGE_FREQUENCY: '更改密碼間隔',

        JOB_GROUPS_REQUIRE: '需要工作群組',
        JOB_EXTRA_SETTINGS_EDIT: '額外工作設定',
        JOB_ACCEPTANCE_TERMS_TEXT: '接受工作條款和條例',
        JOB_ACCEPTANCE_TERMS_FILE: '接受工作條款和條㤡文件',
        JOB_SATISFACTION_SURVEY_FREQUENCY: '滿意度調查次數',
        JOB_APPROVAL_REMINDER: '批核提示',
        JOB_ACKNOWLEDGEMENT_REMINDER: '確認提示',
        JOB_REACTIVE_COMMENCEMENT_REMINDER: '重新啟動開始提示',
        JOB_REACTIVE_COMPLETION_REMINDER: '重新啟動結束提示',
        JOB_PLANNED_COMMENCEMENT_REMINDER: '預設開始提示',
        REQUIREJOBGROUP: '需要工作群組',
        APPROVALREMINDER: '批核提示',
        ACKNOWLEDGEMENTREMINDER: '確認提示',
        REACTIVECOMMENCEMENTREMINDER: '重新啟動開始提示',
        REACTIVECOMPLETIONREMINDER: '重新啟動結束提示',
        PLANNEDCOMMENCEMENTREMINDER: '預設開始提示',

        CHASE_SETTINGS_REACTIVE: '重新啟動',
        CHASE_SETTINGS_REACTIVESTATUTORY: '慣常重新啟動',
        CHASE_SETTINGS_PLANNED: '計劃',
        CHASE_SETTINGS_PLANNEDSTATUTORY: '慣常計劃',
        CHASE_SETTINGS_TYPE0_EDIT: '設定追蹤重新啟動',
        CHASE_SETTINGS_TYPE1_EDIT: '設定追蹤慣常重新啟動',
        CHASE_SETTINGS_TYPE2_EDIT: '設定追蹤計劃',
        CHASE_SETTINGS_TYPE3_EDIT: '設定追蹤慣常計劃',
        CHASE_SETTINGS_TYPE4_EDIT: '設定追蹤引用',
        CHASE_SETTINGS_TYPE0_ADD: '增加追蹤重新啟動',
        CHASE_SETTINGS_TYPE1_ADD: '增加追蹤慣常重新啟動',
        CHASE_SETTINGS_TYPE2_ADD: '增加追蹤計劃',
        CHASE_SETTINGS_TYPE3_ADD: '增加追蹤慣常重新啟動',
        CHASE_SETTINGS_TYPE4_ADD: '增加追蹤引用',
        CHASE_SETTINGS_QUOTE: '引用',
        CHASE_TARGET: '目標',
        CHASE_START_DATE: '開始日期',
        CHASE_EMAIL_BODY: '追蹤訊息',
        CHASE_SETTINGS_TYPE_ADD: '增加追蹤設定',
        CHASE_SETTINGS_TYPE_EDIT: '更改追蹤設定',
        CHASE_SETTING_UPDATED: '更新追蹤設定',
        CHASE_SETTING_REMOVED: '移除追蹤設定',
        CHASE_SETTINGS_REORDERED: '重新排列追蹤設定',
        CHASE_BODY: '內文',
        SITE_ASSOCIATE: '場地助理',

        BODY: '內文',
        TARGETSITE: '目標場地',
        TARGETOPERATIVE: '目標行動',
        SEQUENCE: '次序',
        AFTERDUEDATEPERIOD: '到期日後',
        AFTERUPDATEPERIOD: '更新期後',
        WEEKENDSPUBLICHOLIDAYS: '包括週末及公眾假期?',

        QUOTE_TIMEOUT: '逾時',
        QUOTE_TIMEOUT_SUB_LABEL: '到期日前日數',
        QUOTE_TERMS_TEXT: '引用條款和條例',
        QUOTE_TERMS_FILE: '引用條款和條例文件',
        TERMS_DOWNLOAD_TITLE: '按此瀏覽現時條款和條例',

        QUOTE_EXTRA_SETTINGS_EDIT: '額外',
        QUOTE_EXTENSION_APPROVAL: '引用外部批核',

        SURVEY_TIMEOUT: '逾時',

        KPI_TIMEOUT: '逾時',
        KPI_TIMEOUT_SUB_LABEL: '自動接受服務提供者提交的問卷之日數',

        APP_TIMEOUT: '逾時',

        PERFORMANCE_INCLUDE_PATROL_SCORE: '包括巡邏分數',
        PERFORMANCE_INCLUDE_SURVEY_SCORE: '包括問卷調查分數',
        PERFORMANCE_PATROL_SCORE_WEIGHT: '計算巡邏分數',
        PERFORMANCE_QUOTE_SCORE_WEIGHT: '計算引用分數',
        PERFORMANCE_SURVEY_SCORE_WEIGHT: '計算問卷調查分數',
        PERFORMANCE_SYSTEM_SCORE_WEIGHT: '計算系統分數',

        FILE_ALLOWED_TYPES: '允許文件類型/MIME類型',
        FILE_DISALLOWED_TYPES: '不允許文件類型/MIME類型',
        FILE_RESTRICT: '禁止文件上載',
        FILE_SETTINGS_ALLOW_SUB_LABEL: '如此欄留空 除不允許外其他都會允許',
        FILE_SETTINGS_DISALLOW_SUB_LABEL: '如此欄留空 除允許外其他都不允許'

    });
});
