(function () {
    'use strict';

    angular
        .module('elogbooks.user.sites')
        .controller('UserSiteSurveyScheduleListController', UserSiteSurveyScheduleListController);

    UserSiteSurveyScheduleListController.$inject = ['surveySchedulesCollectionResponse', '$stateParams', '$state', 'recurrenceRulesService'];

    function UserSiteSurveyScheduleListController(surveySchedulesCollectionResponse, $stateParams, $state, recurrenceRulesService) {
        var vm = this;
        vm.order = order;
        vm.schedules = surveySchedulesCollectionResponse.schedules;
        vm.schedulesResponse = surveySchedulesCollectionResponse;
        vm.recurrenceRulesService = recurrenceRulesService;
        vm.entityCount = surveySchedulesCollectionResponse.count;

        function order(key) {
            $stateParams.scheduleOrder = key;

            var override = {
                schedulePage: 1
            };

            $state.go('.', angular.extend({}, $stateParams, override), { reload: $state.current });
        }
    }

})();
