(function () {
    'use strict';

    angular
        .module('elogbooks.user.patrols')
        .controller('ManagePatrolsInfoController', ManagePatrolsInfoController);

    ManagePatrolsInfoController.$inject = ['$state', 'patrolResponse'];

    function ManagePatrolsInfoController ($state, patrolResponse) {
        var vm = this;
        vm.patrol = patrolResponse;
    }
})();
