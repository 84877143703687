(function() {
    'use strict';

    angular
        .module('elogbooks.admin.core-clients')
        .controller('DocumentSetsInfoController', DocumentSetsInfoController);

    DocumentSetsInfoController.$inject = [
        'apiClient',
        'messenger',
        '$state',
        '$stateParams',
        'confirmationModal',
        'lodash',
        'documentSetResponse',
        'documentSetTypeResponse'
    ];

    function DocumentSetsInfoController(
        apiClient,
        messenger,
        $state,
        $stateParams,
        confirmationModal,
        lodash,
        documentSetResponse,
        documentSetTypeResponse
    )
    {
        var vm = this;
        vm.documentSet = documentSetResponse;
        vm.documentSetTypeResponse = documentSetTypeResponse;
        vm.documentTypes = documentSetTypeResponse.documentSetTypes;

        vm.canEdit = lodash.has(vm.documentSet, '_links.edit.href');
        vm.canDelete = lodash.has(vm.documentSet, '_links.delete.href');
        vm.canAdd = lodash.has(vm.documentSetTypeResponse, '_links.add-type');

        vm.order = order;

        vm.update = updateAction;
        vm.delete = deleteAction;

        function order(key) {
            $stateParams.typesOrder = key;

            $state.go('.', angular.extend({}, $stateParams), {reload: $state.current});
        }

        function updateAction ($documentType) {
            return apiClient.update($documentType.getLink('edit'), $documentType).then(function (response) {
                if (response) {
                    $state.go('.', $stateParams, { reload: $state.$current.parent.self.parent })
                        .then(function () {
                            messenger.success('DOCUMENT_TYPE_UPDATED');
                        });
                } else {
                    messenger.error('REQUEST_ERROR');
                }
            });
        }

        function deleteAction ($documentType) {
            if (documentSetTypeResponse.count > 1) {
                return confirmationModal.open().result.then(function () {
                    return apiClient.delete($documentType.getLink('delete')).then(function (response) {
                        if (response) {
                            $state.go('.', $stateParams, { reload: $state.$current.parent.self.parent })
                                .then(function () {
                                    messenger.success('DOCUMENT_TYPE_DELETED');
                                });
                        } else {
                            messenger.error('REQUEST_ERROR');
                        }
                    });
                });
            } else {
                messenger.error('DOCUMENT_SET_MUST_HAVE_ATLEAST_ONE_DOCUMENT_TYPE');
            }
        }
    }
})();
