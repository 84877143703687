(function() {
    'use strict';

    angular
        .module('elogbooks.common.quote-requests')
        .controller('CommonQuoteRequestAddSubQuotesController', CommonQuoteRequestAddSubQuotesController);

    CommonQuoteRequestAddSubQuotesController.$inject = [
        '$state',
        'partnershipCollectionResponse',
        'quoteRequestResponse',
        'crudService',
        'serviceProviderService'
    ];

    function CommonQuoteRequestAddSubQuotesController(
        $state,
        partnershipCollectionResponse,
        quoteRequestResponse,
        crudService,
        serviceProviderService
    ) {
        var vm = this;
        vm.data = {
            _links: {},
        };

        vm.selectedServiceProviders = [];

        vm.removeServiceProvider = removeServiceProvider;
        vm.canSubmit = canSubmit;
        vm.submit = submit;

        vm.serviceProviderSelectModel = {
            response: partnershipCollectionResponse,
            link: partnershipCollectionResponse.getLink('self'),
            linkParameters: {active:1},
            required: false,
            responseKeyPath: 'partnerships',
            itemHrefPath: '_links.serviceprovider.href',
            itemValuePath: '_links.serviceprovider.title',
            formatValue: serviceProviderService.formatServiceProviderName,
            headers: serviceProviderService.isProsureEnabled ? serviceProviderService.prosureStatusHeaders : null,
            reorder: serviceProviderService.prosureGroupReorder,
            onSelect: changeServiceProvider,
            onRemove: changeServiceProvider
        };

        if (serviceProviderService.isProsureEnabled) {
            vm.serviceProviderSelectModel.loadmoreEnabled = true;
        }

        function changeServiceProvider() {
            if (vm.serviceProviderSelectModel.selected && vm.serviceProviderSelectModel.selected.object) {
                var isAlreadySelected = false;
                angular.forEach(vm.selectedServiceProviders, function(value) {
                    if (value.getLink('serviceprovider') === vm.serviceProviderSelectModel.selected.object.getLink('serviceprovider')) {
                        isAlreadySelected = true;
                    }
                });

                if (!isAlreadySelected) {
                    vm.selectedServiceProviders.push(vm.serviceProviderSelectModel.selected.object);
                }
            }
        }

        function removeServiceProvider(serviceProvider) {
            angular.forEach(vm.selectedServiceProviders, function(value, index) {
                if (value._links.self.href === serviceProvider._links.self.href) {
                    vm.selectedServiceProviders.splice(index, 1);
                }
            });
        }

        function canSubmit() {
            return vm.selectedServiceProviders.length > 0;
        }

        function submit() {
            vm.data.serviceProviders = {
                links: vm.selectedServiceProviders.map(function(serviceProvider) {
                    return {
                        href: serviceProvider.getLink('serviceprovider')
                    };
                })
            };

            crudService.create(
                quoteRequestResponse.getLink('add-sub-quotes'),
                vm.data,
                $state.current.name.substring(0, $state.current.name.lastIndexOf('.list') + 5),
                null,
                'QUOTE'
            );
        }
    }
})();
