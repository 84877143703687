(function () {
    'use strict';

    angular
        .module('elogbooksFilters')
        .filter('daysWeeksMonthsYears', DaysWeeksMonthsYears);

    DaysWeeksMonthsYears.$inject = ['$translate'];

    function DaysWeeksMonthsYears($translate) {
        return function(input) {
             switch (input) {
                case 'd':
                    return $translate.instant('DAYS');
                case 'w':
                    return $translate.instant('WEEKS');
                case 'm':
                    return $translate.instant('MONTHS');
                case 'y':
                    return $translate.instant('YEARS');
            }
        };
    }
})();
