(function() {
    'use strict';

    angular
        .module('elogbooks.admin.surveys')
        .controller('SurveySiteAddEditController', SurveySiteAddEditController);

    SurveySiteAddEditController.$inject = ['surveyResponse', 'SurveySitesCollectionResponse', 'sitesResponse', '$state', 'apiClient', 'messenger'];

    function SurveySiteAddEditController(surveyResponse, SurveySitesCollectionResponse, sitesResponse, $state, apiClient, messenger) {
        var vm = this;
        vm.survey = surveyResponse;

        // Sites attached to surveys
        vm.SurveySiteCollectionResponse = SurveySitesCollectionResponse;
        vm.surveySites = SurveySitesCollectionResponse.sites;

        // All sites
        vm.sites = sitesResponse;

        vm.sitesSelect = {
            response: sitesResponse,
            required: true,
            responseKeyPath: 'sites',
            itemValuePath: 'name'
        };

        vm.grantAllActiveSites = function () {
            apiClient.create(vm.survey.getLink('sites'), {
                survey: surveyResponse,
                allSites: true
            }).then(function(response){
                if (response) {
                    $state.go('dashboard.admin.surveys.list.details.survey-sites', {}, {reload: true});
                } else {
                    messenger.error('REQUEST_ERROR');
                }
            });
        };

        vm.siteSelect = {
            response : sitesResponse,
            link : sitesResponse ? sitesResponse.getLink('self') : '',
            responseKeyPath: 'sites'
        };

        vm.create = createAction;

        function createAction() {
            var data = {
                _links: {}
            };

            if (vm.siteSelect.selectedItems.length) {
                data._links.site = vm.siteSelect.selectedItems;

                apiClient.create(vm.survey.getLink('sites'), data).then(function (response) {
                    if (response) {
                        $state.go('dashboard.admin.surveys.list.details.survey-sites', {}, {reload: true}).then(function() {
                            messenger.success('SITE_ADDED');
                        });
                    } else {
                        messenger.error('REQUEST_ERROR');
                    }
                });
            }
        }
    }
})();
