(function () {
    'use strict';

    angular
        .module('elogbooks.common.jobs')
        .controller('CommonJobFilesController', CommonJobFilesController);

    CommonJobFilesController.$inject = ['fileCollectionResponse'];

    function CommonJobFilesController (fileCollectionResponse) {
        var vm = this;
            vm.fileCollectionResponse = fileCollectionResponse;
    }
})();
