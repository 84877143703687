(function(){
    'use strict';

    angular
        .module('elogbooks.admin.settings')
        .controller('AdminEditSettingsController', AdminEditSettingsController);

    function AdminEditSettingsController(
        $state,
        $scope,
        $translate,
        messenger,
        moment,
        lodash,
        apiClient,
        settingsCollection,
        settingsFields,
        siteAssociateTypesCollection,
        assetScoreTypesCollection,
        recurrenceRulesService,
        elogsUserCollection,
        contractManager,
        elbSettings,
        module,
        modulesService,
        meridianConfigurationCollectionResponse,
        confirmationModal
    ) {
        var vm = this;
        vm.meridianConfiguration = {
            invalid: false
        };

        if (module && !elbSettings.getSetting(module).value) {
            $state.go('dashboard.user.dashboard');
        }

        vm.monthDates = [
            {value: 1, title: 1},
            {value: 2, title: 2},
            {value: 3, title: 3},
            {value: 4, title: 4},
            {value: 5, title: 5},
            {value: 6, title: 6},
            {value: 7, title: 7},
            {value: 8, title: 8},
            {value: 9, title: 9},
            {value: 10, title: 10},
            {value: 11, title: 11},
            {value: 12, title: 12},
            {value: 13, title: 13},
            {value: 14, title: 14},
            {value: 15, title: 15},
            {value: 16, title: 16},
            {value: 17, title: 17},
            {value: 18, title: 18},
            {value: 19, title: 19},
            {value: 20, title: 20},
            {value: 21, title: 21},
            {value: 22, title: 22},
            {value: 23, title: 23},
            {value: 24, title: 24},
            {value: 25, title: 25},
            {value: 26, title: 26},
            {value: 27, title: 27},
            {value: 28, title: 28},
        ];
        vm.settingsCollection = settingsCollection;
        vm.elogsUserCollection = elogsUserCollection;
        vm.settingsCollection.id = 1;
        vm.settings = loadSettings(settingsCollection.settings);
        vm.settingFormTypes = getSettingsFormTypes();
        vm.settingsForm = buildSettingsForm(settingsCollection.settings);
        vm.skipFields = ['general_inactivate_users_enabled', 'general_inactivate_users_days', 'general_meridian_workflow'];
        vm.meridianEnabled = modulesService.isEnabled('meridian');
        if (meridianConfigurationCollectionResponse) {
            vm.meridianConfiguration.invalid = !(meridianConfigurationCollectionResponse.verifyConfig &&
                meridianConfigurationCollectionResponse.verifyConfig.hasOwnProperty('is_valid') &&
                meridianConfigurationCollectionResponse.verifyConfig.is_valid);
        }
        vm.meridianWorkflowWarning = false;

        vm.contractManagerSelected = Object.keys(contractManager).length > 0 ? {
            object: contractManager,
            href: contractManager._links.self.href,
            value: contractManager.name
        } : null;

        vm.contractManagerSelect = {
            response: vm.elogsUserCollection,
            link: Object.keys(vm.elogsUserCollection).length > 0 ? vm.elogsUserCollection._links.self.href : '',
            responseKeyPath: 'users',
            required: false,
            onSelect: updateContractManager,
            onRemove: removeContractManager,
            selected: vm.contractManagerSelected
        };

        // site associate select-widget
        if (lodash.has(siteAssociateTypesCollection, 'siteAssociateTypes')) {
            vm.siteAssociateTypesSelect = {
                response: siteAssociateTypesCollection,
                link: siteAssociateTypesCollection._links.self.href,
                responseKeyPath: 'siteAssociateTypes',
                required: true,
                onSelect: updateSiteAssociateType
            };

            var associateType;

            if (lodash.has(vm.settings.general_actionsiteassociatetype, 'value')) {
                associateType = vm.settings.general_actionsiteassociatetype;
            }

            if (lodash.has(vm.settings.spillage_escalation_siteassociate_type, 'value')) {
                associateType = vm.settings.spillage_escalation_siteassociate_type;
            }

            if (associateType) {
                // find the associate by id, from the associates collection
                var selectedAssociateType = lodash.find(siteAssociateTypesCollection.siteAssociateTypes, function(element) {
                    return element.id === associateType.value;
                });

                if (typeof selectedAssociateType !== 'undefined') {
                    // set select widget form to use selected associate
                    vm.siteAssociateTypesSelect.selected = {
                        object: selectedAssociateType,
                        href: selectedAssociateType._links.self.href,
                        value: selectedAssociateType.name
                    };
                }
            }

            //add 'None Selected' option to type collection
            siteAssociateTypesCollection.siteAssociateTypes.unshift({name:  $translate.instant('NONE_SELECTED'), id: null});

            vm.auditCompletionEmailAssociateTypesSelect = {
                response: siteAssociateTypesCollection,
                link: siteAssociateTypesCollection._links.self.href,
                responseKeyPath: 'siteAssociateTypes',
                onSelect: updateAuditCompletionEmailAssociateType,
                onRemove: clearAuditCompletionEmailAssociateType
            };

            if (lodash.has(vm.settings['general_auditcompletionemailassociatetype'], 'value')) {
                // find the associate by id, from the associates collection
                var selectedAuditCompletionAssociateType = lodash.find(siteAssociateTypesCollection.siteAssociateTypes, function(element) {
                    if (vm.settings['general_auditcompletionemailassociatetype'].value.length === 0) {
                        return element.id === null;
                    }

                    return element.id === vm.settings['general_auditcompletionemailassociatetype'].value;
                });

                if (typeof selectedAuditCompletionAssociateType !== 'undefined') {
                    // set select widget form to use selected associate
                    vm.auditCompletionEmailAssociateTypesSelect.selected = {
                        object: selectedAuditCompletionAssociateType,
                        value: selectedAuditCompletionAssociateType.name
                    };
                }
            }
        }

        if ($state.current.name === 'dashboard.admin.settings.general-settings.edit') {
            $scope.$watch('vm.settingsForm.general_inactivate_users_enabled', function(ticked) {
                if (!ticked) {
                    vm.settingsForm.general_inactivate_users_days = vm.settings['general_inactivate_users_days'].defaultValue;
                }
            });

            // Show dialog on update if multi meridian
            if (lodash.get(meridianConfigurationCollectionResponse, 'systemLinks', []).length > 0) {
                $scope.$watch('vm.settingsForm.general_meridian_workflow', function(newValue, oldValue) {
                    if (newValue !== oldValue) {
                        vm.meridianWorkflowWarning = true;
                    }
                });
            }
        }

        function updateContractManager() {
            if (lodash.has(vm.settings.general_contract_manager, 'value')) {
                vm.settingsForm.general_contract_manager = vm.contractManagerSelect.selected.id;
            }
        }

        function removeContractManager() {
            vm.settingsForm.general_contract_manager = null;
        }

        function updateSiteAssociateType() {
            // update the select field value
            associateType.value = parseInt(lodash.replace(vm.siteAssociateTypesSelect.selected.href, '/siteassociatetypes/', ''));

            // update the form value
            if (lodash.has(vm.settings.general_actionsiteassociatetype, 'value')) {
                vm.settingsForm.general_actionsiteassociatetype = associateType.value;
            }

            if (lodash.has(vm.settings.spillage_escalation_siteassociate_type, 'value')) {
                vm.settingsForm.spillage_escalation_siteassociate_type = associateType.value;
            }
        }

        function updateAuditCompletionEmailAssociateType() {
            // update the select field value
            vm.settings['general_auditcompletionemailassociatetype'].value = parseInt(lodash.replace(vm.auditCompletionEmailAssociateTypesSelect.selected.href, '/siteassociatetypes/', ''));

            // update the form value
            vm.settingsForm['general_auditcompletionemailassociatetype'] = vm.settings['general_auditcompletionemailassociatetype'].value;
        }

        function clearAuditCompletionEmailAssociateType() {
            vm.settingsForm['general_auditcompletionemailassociatetype'] = null;
        }

        vm.settingsForm.id = 1;
        vm.update = updateAction;

        function getSettingsFormTypes() {
            var formTypes =  {
                'general_logo' : {
                    type: 'image-file-property',
                    subLabel: 'RECOMMENDED_GENERAL_LOGO',
                    min: 1,
                    max:15
                },
                'general_opening_time'  : {
                    type: 'time'
                },
                'general_closing_time' : {
                    type: 'time'
                },
                'general_tasks_generate_ahead' : {
                    type: 'number'
                },
                'general_audits_generate_ahead' : {
                    type: 'number'
                },
                'general_default_locale' : {
                    type: 'select',
                    options: [
                        {value: 'en-gb', title: $translate.instant('en-gb')},
                        {value: 'fr', title: $translate.instant('fr')},
                        {value: 'es', title: $translate.instant('es')},
                        {value: 'nl', title: $translate.instant('nl')},
                        {value: 'zh-hk', title: $translate.instant('zh-hk')},
                        {value: 'de', title: $translate.instant('de')}
                    ]
                },
                'general_global_helpdesk_number' : {
                    type: 'text'
                },
                'general_helpdesk_number': {
                    type: 'text'
                },
                'general_training_link' : {
                    type: 'text',
                    validateURL: true
                },
                'general_helpdesk_email' : {
                    type: 'email',
                    notRequired: true
                },
                'general_tenant_emailer_reply_email' : {
                    type: 'email'
                },
                'general_actionsiteassociatetype' : {
                    type: 'select-widget',
                    model: vm.siteAssociateTypesSelect,
                    notRequired: true
                },
                'general_actions_paperwork_note_required' : {
                    type: 'checkbox'
                },
                'general_auditcompletionemailassociatetype' : {
                    type: 'select-widget',
                    model: vm.auditCompletionEmailAssociateTypesSelect,
                    notRequired: true
                },
                'general_meridian_workflow' : {
                    type: 'select',
                    options: [
                        {
                            title: $translate.instant('GENERAL_MERIDIAN_WORKFLOW_DISABLED'),
                            value: false
                        },
                        {
                            title: $translate.instant('GENERAL_MERIDIAN_WORKFLOW_ENABLED'),
                            value: true
                        },
                    ],
                    notRequired: true
                },
                'job_acceptance_terms_file' : {
                    type: 'file',
                    downloadTitle: 'TERMS_DOWNLOAD_TITLE'
                },
                'job_acceptance_terms_text' : {
                    type: 'cke-editor',
                    rows: 5
                },
                'job_satisfaction_survey_frequency': {
                    type: 'number'
                },
                'job_group_child_select_mandatory': {
                    type: 'checkbox'
                },
                'job_reporter_feedback': {
                    type: 'checkbox'
                },
                'job_complete_email_text': {
                    type: 'cke-editor',
                    rows: 5,
                    subLabel: 'ADDITIONAL_TEXT_JOB_COMPLETION_EMAILS'
                },
                'allow_secondary_approvers_to_reopen_jobs': {
                    type: 'checkbox'
                },
                'email_html_signature': {
                    type: 'cke-editor',
                    rows: 5
                },
                'email_text_signature': {
                    type: 'textarea',
                    rows: 5
                },
                'email_tenant_logo': {
                    type: 'image-file-property',
                    subLabel: 'EMAIL_LOGO_SUGGESTED_SIZE'
                },
                'app_timeout': {
                    type: 'number'
                },
                'password_days_till_change_notification': {
                    type: 'number'
                },
                'password_disallow_previous_passwords': {
                    type: 'checkbox',
                    notRequired: true
                },
                'password_force_change': {
                    type: 'checkbox',
                    notRequired: true
                },
                'password_min_characters': {
                    type: 'number'
                },
                'password_require_number': {
                    type: 'checkbox',
                    notRequired: true
                },
                'password_require_punctuation': {
                    type: 'checkbox',
                    notRequired: true
                },
                'password_require_uppercase': {
                    type: 'checkbox',
                    notRequired: true
                },
                'performance_include_patrol_score': {
                    type: 'checkbox',
                    notRequired: false
                },
                'performance_include_survey_score': {
                    type: 'checkbox',
                    notRequired: false
                },
                'performance_patrol_score_weight': {
                    type: 'number'
                },
                'performance_quote_score_weight': {
                    type: 'number'
                },
                'performance_survey_score_weight': {
                    type: 'number'
                },
                'performance_system_score_weight': {
                    type: 'number'
                },
                'fm_survey_submission_type': {
                    type: 'radio',
                    radioLabelsAndValues:
                        [
                            {value: 0, title: 'DAYS_AFTER_SP_SUBMISSION'},
                            {value: 1, title: 'DATE_OF_THE_MONTH'}
                        ]
                },
                'fm_submit_kpi_by_date': {
                    type : 'select',
                    notRequired: true,
                    options: vm.monthDates
                },
                'survey_timeout': {
                    type: 'number',
                    subLabel: 'KPI_TIMEOUT_SUB_LABEL',
                    min: 0,
                    notRequired: true
                },
                'survey_automated_stats_enabled': {
                    type: 'checkbox',
                    notRequired: false
                },
                'sp_auto_submit_kpi': {
                    type: 'checkbox',
                    notRequired: false
                },
                'sp_submit_kpi_by_date' : {
                    type : 'select',
                    options: vm.monthDates
                },
                'quote_terms_file': {
                    type: 'file',
                    downloadTitle: 'TERMS_DOWNLOAD_TITLE'
                },
                'quote_terms_text': {
                    type: 'cke-editor',
                    rows: 5
                },
                'quote_timeout': {
                    type: 'number',
                    subLabel: 'QUOTE_TIMEOUT_SUB_LABEL',
                    min: 0,
                    notRequired: true
                },
                'quote_require_labour_and_materials': {
                    type: 'checkbox',
                    subLabel: 'QUOTE_REQUIRE_LABOUR_AND_MATERIALS_ON_QUOTE_OPTIONS',
                    notRequired: false
                },
                'budget_quotes': {
                    type: 'checkbox',
                    notRequired: false
                },
                'file_restrict': {
                    type: 'checkbox',
                    notRequired: false
                },
                'file_allowed_types': {
                    type: 'select-multiple',
                    options: [
                        {value: 'csv', title: 'csv'},
                        {value: 'txt', title: 'txt'},
                        {value: 'msg', title: 'msg'},
                        {value: 'html', title: 'html'},
                        {value: 'xls', title: 'xls'},
                        {value: 'doc', title: 'doc'},
                        {value: 'docx', title: 'docx'},
                        {value: 'odt', title: 'odt'},
                        {value: 'ods', title: 'ods'},
                        {value: 'ppt', title: 'ppt'},
                        {value: 'pptx', title: 'pptx'},
                        {value: 'pdf', title: 'pdf'},
                        {value: 'xlsx', title: 'xlsx'},
                        {value: 'tiff', title: 'tiff'},
                        {value: 'bmp', title: 'bmp'},
                        {value: 'gif', title: 'gif'},
                        {value: 'jpeg', title: 'jpeg'},
                        {value: 'webp', title: 'webp'},
                        {value: 'png', title: 'png'}
                    ],
                    subLabel: 'FILE_SETTINGS_DISALLOW_SUB_LABEL'
                },
                'file_expiry_notification_time': {
                    type: 'number',
                    min: 1,
                    notRequired: true,
                    subLabel: 'FILE_EXPIRY_NOTIFICATION_TIME_SUB_LABEL'
                },
                'task_force_service_routine': {
                    type: 'checkbox'
                },
                'spillage_open_chase_freq_before': {
                    type: 'number'
                },
                'spillage_open_chase_freq_after': {
                    type: 'number'
                },
                'spillage_accepted_chase_freq_before': {
                    type: 'number'
                },
                'spillage_accepted_chase_freq_after': {
                    type: 'number'
                },
                'spillage_in_progress_chase_freq_before': {
                    type: 'number'
                },
                'spillage_in_progress_chase_freq_after': {
                    type: 'number'
                },
                'spillage_escalation_interval_repeat': {
                    type: 'number'
                },
                'spillage_escalation_interval': {
                    type: 'number'
                },
                'spillage_escalation_siteassociate_type': {
                    type: 'select-widget',
                    model: vm.siteAssociateTypesSelect
                },
                'meter_force_stick_to_date': {
                    type: 'checkbox'
                },
                'meter_generate_week_day':{
                    type: 'select',
                    options: recurrenceRulesService.ruleDayOptions,
                    notRequired: true,
                    subLabel: 'METER_GENERATE_WEEK_DAY_SUB_LABEL'
                },
                'meter_generate_month_day':{
                    type: 'number',
                    min: 1,
                    max: 31,
                    notRequired: true,
                    subLabel: 'METER_GENERATE_MONTH_DAY_SUB_LABEL'
                },
                'meter_generate_year_day':{
                    type: 'number',
                    min: 1,
                    max: 31,
                    notRequired: true,
                    subLabel: 'METER_GENERATE_YEAR_DAY_SUB_LABEL'
                },
                'meter_generate_year_month':{
                    type: 'select',
                    options: recurrenceRulesService.ruleMonthOptions,
                    notRequired: true,
                    subLabel: 'METER_GENERATE_YEAR_MONTH_DAY_SUB_LABEL'
                },
                'meter_grace_period_week_before':{
                    type: 'number',
                    min: 0,
                    max: 6,
                    notRequired: true,
                    subLabel: 'METER_GRACE_PERIOD_WEEK_BEFORE_SUB_LABEL'
                },
                'meter_grace_period_week_after':{
                    type: 'number',
                    min: 0,
                    max: 6,
                    notRequired: true,
                    subLabel: 'METER_GRACE_PERIOD_WEEK_AFTER_SUB_LABEL'
                },
                'meter_grace_period_month_before':{
                    type: 'number',
                    min: 0,
                    max: 27,
                    notRequired: true,
                    subLabel: 'METER_GRACE_PERIOD_MONTH_BEFORE_SUB_LABEL'
                },
                'meter_grace_period_month_after':{
                    type: 'number',
                    min: 0,
                    max: 27,
                    notRequired: true,
                    subLabel: 'METER_GRACE_PERIOD_MONTH_AFTER_SUB_LABEL'
                },
                'meter_grace_period_year_before':{
                    type: 'number',
                    min: 0,
                    max: 364,
                    notRequired: true,
                    subLabel: 'METER_GRACE_PERIOD_YEAR_BEFORE_SUB_LABEL'
                },
                'meter_grace_period_year_after':{
                    type: 'number',
                    min: 0,
                    max: 364,
                    notRequired: true,
                    subLabel: 'METER_GRACE_PERIOD_YEAR_AFTER_SUB_LABEL'
                },
                'general_non_priority_colour':{
                    type: 'colour-picker',
                    notRequired: false,
                    subLabel: 'NON_PRIORITY_COLOUR'
                },
                'general_p2p_started_at_date':{
                    type: 'datetime',
                    notRequired: false
                },
                'general_contract_manager':{
                    type: 'select-widget',
                    notRequired: true
                },
                'general_inactivate_users_enabled' :{
                    type: 'checkbox',
                },
                'general_inactivate_users_days' :{
                    type: 'number',
                    min: 90,
                    notRequired: true,
                    subLabel: 'GENERAL_INACTIVATE_USERS_DAYS_SUBLABEL'
                },
                'webhook_job' : {
                    type : 'checkbox',
                },
                'webhook_quote': {
                    type: 'checkbox',
                },
                'default_asset_score_types': {
                    type: 'select-widget-multiple',
                    subLabel: 'ASSET_SCORE_TYPES_TO_DISPLAY_IN_LIST_VIEW'
                },
                'default_yearly_entitlement': {
                    type: 'decimal',
                    min: 0,
                    max: 100,
                    step: 0.5,
                    notRequired: true,
                },
                'default_holiday_year_start_day': {
                    type: 'number',
                    min: 1,
                    max: 31,
                    notRequired: true,
                },
                'default_holiday_year_start_month': {
                    type: 'select',
                    options: recurrenceRulesService.ruleMonthOptions,
                    notRequired: true,
                },
                'client_admin_domains': {
                    type: 'textarea',
                    subLabel: 'CLIENT_ADMIN_DOMAIN_SUB_LABEL',
                    rows: 5,
                    validateDomain: true
                }
            };

            for (var type in formTypes) {
                if (formTypes[type].type === 'number') {
                    formTypes[type].options = { invalid: false };
                }
            }

            return formTypes;
        }

        function getElementValue(name)
        {
            var element = vm.settings[name];

            if (element) {
                return element.value;
            }

            return null;
        }

        function updateAction()
        {
            const updateActionMethod = function() {
                var requestData = {
                    settings: []
                };

                var settingsInvalid = false;

                if (vm.settingsForm['sp_auto_submit_kpi'] && (vm.settingsForm['sp_submit_kpi_by_date'] === 0 || !vm.settingsForm['sp_submit_kpi_by_date'])) {
                    messenger.error('SELECT_SP_SUBMIT_DATE');

                    return;
                }

                if (vm.settingsForm['fm_survey_submission_type'] === 1) {
                    vm.settingsForm['survey_timeout'] = parseInt(vm.settingsForm['survey_timeout']);

                    if (vm.settingsForm['fm_submit_kpi_by_date'].length === 0) {
                        messenger.error('SELECT_FM_SUBMIT_DATE');

                        return;
                    }
                }

                for (var key in vm.settingsForm) {
                    if (vm.settings[key]) {
                        var obj = vm.settingFormTypes[key];

                        if (obj.validateDomain && vm.settingsForm[key] !== undefined && vm.settingsForm[key] !== '') {
                            var validationRegex = /^[\w\-]+(\.+[\w\-.]+)(,\s*[\w\-]+\.+[\w\-.]+)*$/;
                            vm.settingsForm[key] = vm.settingsForm[key].trim();

                            if (validationRegex.exec(vm.settingsForm[key]) === null) {
                                messenger.error('The Data you have entered is invalid');

                                return;
                            }
                        }

                        if (obj.type === 'number') {
                            if (vm.settingsForm[key] % 1 !== 0 && vm.settingsForm[key] !== undefined) {
                                obj.options = { invalid: true };
                                settingsInvalid = true;
                                messenger.error('NO_DECIMAL_ALLOWED');
                                break;
                            }
                        }

                        if (obj.validateURL) {
                            var regex = /(http|https):\/\/(\w+:{0,1}\w*@)?(\S+)(:[0-9]+)?(\/|\/([\w#!:.?+=&%@!\-\/]))?/;
                            if (vm.settingsForm[key] !== undefined && vm.settingsForm[key] !== "" && regex.exec(vm.settingsForm[key]) === null) {
                                obj.options = { invalid: true };
                                settingsInvalid = true;
                                messenger.error('WRONG_URL_FORMAT');
                                break;
                            }
                        }

                        var setting = vm.settings[key];
                        var settingFormType = vm.settingFormTypes[setting.name];

                        if (settingFormType.type == 'time') {
                            if (vm.settingsForm[key] === null) {
                                setting.value = null;
                            } else {
                                setting.value = moment(vm.settingsForm[key]).format('HH:mm');
                            }
                        } else if (settingFormType.type == 'file' || settingFormType.type == 'image-file-property') {
                            setting.value = vm.settingsForm[key].href;
                        } else {
                            setting.value = vm.settingsForm[key];
                        }
                        requestData.settings.push({name: setting.name, value:setting.value});
                    }
                }

                if (settingsInvalid !== true) {
                    apiClient.update(vm.settingsCollection.getLink('edit'), requestData).then(function (response) {
                        if (response) {
                            $state.go('^', {}, {reload: true}).then(function () {
                                messenger.success('SETTINGS_UPDATED');
                            });
                        } else {
                            messenger.error('REQUEST_ERROR');
                        }
                    });
                }
            }

            if (vm.meridianWorkflowWarning) {
                confirmationModal.open(
                    {
                        titleMessage: 'GENERAL_MERIDIAN_WORKFLOW_CHANGE_WARNING_TITLE',
                        bodyMessage: null,
                        htmlBodyMessage: $translate.instant('GENERAL_MERIDIAN_WORKFLOW_CHANGE_WARNING_BODY'),
                        primaryText: 'Update',
                        primaryClass: 'btn-primary',
                        secondaryText: 'Cancel'
                    }
                ).result.then(updateActionMethod);
            } else {
                updateActionMethod();
            }
        }

        function loadSettings(settingsArray) {
            var settings = {};

            for (var i in settingsFields) {
                settings[settingsFields[i]] = angular.copy(lodash.find(settingsArray, { name: settingsFields[i] }));
            }

            return settings;
        }

        function buildSettingsForm() {
            var settingsForm = {};

            for (var i in settingsFields) {
                var settingFormType = vm.settingFormTypes[settingsFields[i]];
                var settingValue = getElementValue(settingsFields[i]);

                switch (settingFormType.type) {
                    case 'time':
                        settingsForm[settingsFields[i]] = buildTime(settingValue);
                        break;
                    case 'number':
                        settingsForm[settingsFields[i]] = parseInt(settingValue);
                        break;
                    case 'datetime':
                        settingsForm[settingsFields[i]] = (settingValue.length ? new Date(settingValue): null);
                        break;
                    case 'file':
                    case 'image-file-property':
                        if (settingValue) {
                            settingsForm[settingsFields[i]] = { href: settingValue };
                        }
                        break;
                    case 'email':
                        settingsForm[settingsFields[i]] = settingValue.toString();
                        break;
                    default:
                        settingsForm[settingsFields[i]] = settingValue;
                }

            }

            return settingsForm;
        }

        function buildTime(time) {
            var date = new Date();

            if ((lodash.isEmpty(time)) || isNaN(parseInt(time.substring(0, 2))) || isNaN(parseInt(time.substring(3, 5)))) {
                date = null;
            } else {
                if (time) {
                    date.setHours(parseInt(time.substring(0, 2)));
                    date.setMinutes(parseInt(time.substring(3, 5)));
                }
                date.setSeconds(0);
                date.setMilliseconds(0);
            }

            return date;
        }
    }
})();
