(function () {
    "use strict";

    angular
        .module("elogbooks.user.integrations")
        .controller("UserIntegrationsController", UserIntegrationsController);

    UserIntegrationsController.inject = ["user"];

    function UserIntegrationsController(user) {
        var vm = this;
    }
})();
