(function () {
    'use strict';

    angular
        .module('elogbooksDirectives')
        .directive('elbAssetScores', AssetScores);

    function AssetScores() {
        var directive = {
            templateUrl: '/modules/directives/form-elements/asset-scores/asset-scores.html',
            restrict: 'AE',
            scope: {
                historicalScores: '=ngModel',
                assetResponse: '='
            },
            require: ['ngModel', 'assetResponse', 'assetScoreTypes'],
            transclude: true,
            controller: ['$state', '$scope', 'lodash', 'apiClient', '$translate', '$q', function($state, $scope, lodash, apiClient, $translate, $q) {
                initScope();

                $scope.$on('assetSelected', function(event, asset) {
                        $scope.assetResponse = asset;

                        initScope();
                        initModel();
                    }
                );

                initModel();

                function initScope() {
                    $scope.historicalScores = [];
                    $scope.assetScoreTypesOptionsCollection = [];
                    $scope.assetScoreTypes = $scope.assetResponse ? lodash.get($scope.assetResponse, 'assetScoreTypes') || lodash.get($scope.assetResponse.subassets[0], 'assetScoreTypes') : null;
                    $scope.multiple = false;
                    $scope.subAssetSelected = false;
                    $scope.edit = false;
                    $scope.form = $scope.$parent.form;
                    $scope.addAssetScores = addAssetScores;
                    $scope.deleteAssetScores = deleteAssetScores;
                    $scope.editAssetScores = editAssetScores;
                    $scope.changeSubAsset = changeSubAsset;
                    $scope.checkRequiresRemedialWarning = checkRequiresRemedialWarning;

                    getAssetScoreOptions();
                    clearAssetScoreHolder();

                    if (!$scope.assetScoreTypes) {
                        return;
                    }
                }

                function initModel() {
                    if ($scope.assetResponse && $scope.assetResponse.subassets && $scope.assetResponse.count > 1) {
                        $scope.multiple = true;
                        $scope.subAssetModel = {
                            response: $scope.assetResponse,
                            responseKeyPath: 'subassets',
                            link: $scope.assetResponse.getLink('self'),
                            onSelect: changeSubAsset,
                            onRemove: changeSubAsset
                        };
                        $scope.assetScoresModel = [];
                    } else if ($scope.assetResponse) {
                        $scope.asset = $scope.assetResponse.subassets ? $scope.assetResponse.subassets[0] : $scope.assetResponse;
                        $scope.historicalScores[0] = angular.copy($scope.assetScoresHolder);
                        $scope.subAssetSelected = true;
                        if ($scope.assetResponse.subassets) {
                            $scope.historicalScores[0].subasset = { asset: { href: $scope.assetResponse.subassets[0].getLink('self') } };
                        } else {
                            $scope.historicalScores[0].asset = { asset: { href: $scope.assetResponse.getLink('self') } };
                            if ($scope.assetResponse.getLink('asset')) {
                                $scope.historicalScores[0].subasset = { asset: { href: $scope.assetResponse.getLink('self') } }
                                delete $scope.historicalScores[0].asset;
                            }
                        }
                    }
                }

                 // if exist in sub asset options don't show alert outside the sub asset table.
                 function checkRequiresRemedialWarning() {
                    var required = false;
                    var multiSubAssetRequiresRemedialAction = false;
                    if ($scope.$parent.vm.remedialsCount === 0) {
                        angular.forEach($scope.assetScoresHolder.options.options, function(element) {
                            if (element.requiresRemedial) {
                                required = true;
                            }
                        });
                        if ($scope.assetScoresModel && $scope.assetScoresModel.length > 0) { 
                                $scope.assetScoresModel.forEach(function(item) {
                                    if (item.options.options && !!lodash.find(item.options.options, ['requiresRemedial', true])) {
                                        multiSubAssetRequiresRemedialAction = item.requiresRemedialAction = true;
                                    }   
                                });
                            }
                    }

                    $scope.requiresRemedialAction = (!multiSubAssetRequiresRemedialAction && required);
                    $scope.$parent.vm.submitDisabled = multiSubAssetRequiresRemedialAction || required;
                }

                function changeSubAsset(subasset) {
                    checkRequiresRemedialWarning();

                    if (!subasset) {
                        delete $scope.subAssetModel.selected;
                        $scope.subAssetSelected = $scope.edit = false;
                        clearAssetScoreHolder();
                        return;
                    }
                    changeSelectedScores();

                    $scope.subAssetModel = subasset;
                    $scope.assetScoresHolder.asset = subasset.selected.object;

                    apiClient.get(subasset.selected.href).then(function(response) {
                        changeSelectedScores(response);
                    });
                    $scope.subAssetSelected = true;
                }

                function changeSelectedScores(asset, option) {
                    $scope.$parent.vm.submitDisabled = false;
                    angular.forEach($scope.assetScoreTypesOptionsCollection, function(score) {
                        if (!asset) {
                            delete score.selected;
                        } else {
                            var historicalScores = option ? option : asset.historicalScores;
                            angular.forEach(historicalScores, function(historicalScore) {
                                if (score.response.id === parseInt(historicalScore.scoreTypeId)) {
                                    // Returns to last score type options
                                    var selected = lodash.find(score.response.options, {id: parseInt(historicalScore.id)});
                                    score.selected = {
                                        value: selected.summary,
                                        href: selected._links.self.href,
                                        object: selected
                                    };
                                    setTimeout(function() {$scope.form[score.name].$setTouched(true);});
                                    changeScore(score);
                                }
                            });
                        }
                    });
                }

                function getAssetScoreOptions() {
                    var requests = {};
                    angular.forEach($scope.assetScoreTypes, function(score, index) {
                        if (score.getLink('options')) {
                            requests[index] = apiClient.get(score.getLink('options')).then(function(response) {
                                if (!response) {
                                    return;
                                }
                                score.options = response.options;
                            });
                        }
                    });

                    $q.all(requests).then(function() {
                        createOptionsCollection();
                        checkRequiresRemedialWarning();
                    });
                }

                function createOptionsCollection() {
                    // We need to loop through each asset score type and create the model with the options add
                    angular.forEach($scope.assetScoreTypes, function(score) {
                        $scope.assetScoreTypesOptionsCollection.push({
                            response: score,
                            id: score.id,
                            name: score.name,
                            link: score.link,
                            responseKeyPath: 'options',
                            required: true,
                            itemValuePath: 'summary',
                            selected: null,
                            onSelect: changeScore,
                            onRemove: changeScore
                        });
                    });

                    if (!$scope.multiple) {
                        changeSelectedScores($scope.asset);
                    }
                }

                function changeScore(option) {
                    $scope.validationError = false;

                    if (!option.selected) {
                        var indexKey = lodash.findIndex($scope.assetScoresHolder.options.options, function(score) {
                            return score.scoreTypeId === option.id;
                        });
                        $scope.assetScoresHolder.options.options.splice(indexKey, 1);
                        if (!$scope.multiple) {
                            $scope.historicalScores[0].options.options.splice(indexKey, 1);
                        }
                        return;
                    }

                    var scoreOption = option.selected.object;
                    scoreOption.scoreType = option.name;

                    var match = lodash.findIndex($scope.assetScoresHolder.options.options, function(score) {
                        return score.scoreTypeId === scoreOption.scoreTypeId;
                    });

                    if (match > -1) {
                        $scope.assetScoresHolder.options.options.splice(match, 1, scoreOption);
                        if (!$scope.multiple) {
                            $scope.historicalScores[0].options.options.splice(match, 1, { href: scoreOption.getLink('self') });
                        }
                    } else {
                        $scope.assetScoresHolder.options.options.push(scoreOption);
                        if (!$scope.multiple) {
                            $scope.historicalScores[0].options.options.push({ href: scoreOption.getLink('self') });
                        }
                    }

                    checkRequiresRemedialWarning();
                }

                $scope.$on('remedialCreated', function(event) {
                    $scope.requiresRemedialAction = false;
                });

                function addAssetScores() {
                    if ($scope.assetScoreTypes.length !== $scope.assetScoresHolder.options.options.length) {
                        $scope.validationError = true;
                        return;
                    }

                    var completedScore = angular.copy($scope.assetScoresHolder);
                    var scoreIndex = lodash.findIndex($scope.assetScoresModel, function(score) {
                        return score.asset.getLink('self') === completedScore.asset.getLink('self');
                    });
                    var modelScore = {
                        subasset: {asset: { href: completedScore.asset.getLink('self') }},
                        options: prepareScores(completedScore.options)
                    };

                    if (scoreIndex > -1) {
                        $scope.assetScoresModel.splice(scoreIndex, 1, completedScore);
                        $scope.historicalScores.splice(scoreIndex, 1, modelScore);
                    } else {
                        $scope.assetScoresModel.push(completedScore);
                        $scope.historicalScores.push(modelScore);
                    }
                    changeSubAsset();
                }

                function editAssetScores(key) {
                    $scope.edit = true;
                    var assetScore = $scope.assetScoresModel[key];
                    var asset = assetScore.asset;

                    $scope.subAssetModel.selected = {
                        object: asset,
                        value: asset.name,
                        href: asset.getLink('self')
                    };
                    $scope.assetScoresHolder.asset = asset;
                    $scope.subAssetSelected = true;

                    var options = assetScore.options.options;
                    changeSelectedScores(asset, options);
                }

                function deleteAssetScores(key) {
                    $scope.validationError = false;
                    $scope.assetScoresModel.splice(key, 1);
                    $scope.historicalScores.splice(key, 1);
                    checkRequiresRemedialWarning()
                }

                function prepareScores(historicalScores) {
                    var scores = {
                        options: []
                    };
                    var options = historicalScores.options;
                    for (var option in options) {
                        if (!options.hasOwnProperty(option)) {
                            continue;
                        }
                        scores.options.push({ href: options[option].getLink('self') });
                    }

                    return scores;
                }

                function clearAssetScoreHolder() {
                    $scope.assetScoresHolder = {
                        options: {options: []}
                    };
                }
            }]
        };

        return directive;
    }
})();
