angular.module('elogbooksDirectives').directive('assetScore', [function () {
    return {
        restrict: 'AE',
        scope: {
            model: '=ngModel',
            minified: '@'
        },
        templateUrl: '/modules/directives/assetScore/asset-score.html',
        controller: function ($scope, lodash, $stateParams, apiClient) {
            $scope.model.clearValue = clearAction;
            $scope.model.onSelect = loadScoreOptions;

            if (typeof $scope.model === 'undefined') {
                clearAction();
            }

            if ($stateParams.assetScore) {
                getScoreOptions($scope.model);
            }

            function clearAction() {
                $scope.model.selected = null;
            }

            function loadScoreOptions(assetScoreModel) {
                $scope.model.scoreOptions.selectedItems = [];
                getScoreOptions(assetScoreModel);
            }

            function getScoreOptions(assetScoreModel) {
                $scope.model.scoreOptions.items = [];
                var selected = $scope.model.selected;
                $scope.model.disabled = true;

                apiClient.get(assetScoreModel.selected.object._links.options.href).then(function (scoreOptionsCollectionResponse) {
                    if (scoreOptionsCollectionResponse) {
                        $scope.model.scoreOptions.response = scoreOptionsCollectionResponse;
                        $scope.model.scoreOptions.link = scoreOptionsCollectionResponse.getLink('self');
                        $scope.model.disabled = false;
                        $scope.model.selected = selected;
                        $scope.model.scoreOptions.buildItems(scoreOptionsCollectionResponse);
                    }
                });
            }
        }
    };
}]);


