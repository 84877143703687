(function(){
    'use strict';

    angular
        .module('elogbooks.common.audit')
        .controller('AuditAnswerTemplateListController', AuditAnswerTemplateListController);

    AuditAnswerTemplateListController.$inject= [
        'answerTemplateCollectionResponse',
        '$stateParams',
        '$state',
        '$filter'
    ];

    function AuditAnswerTemplateListController(
        answerTemplateCollectionResponse,
        $stateParams,
        $state,
        $filter
    ) {
        var vm = this;
        vm.auditAnswerTemplates = answerTemplateCollectionResponse.answerTemplates;
        vm.answerTemplateCollectionResponse = answerTemplateCollectionResponse;
        vm.loadedCollections = [answerTemplateCollectionResponse];

        vm.search = search;

        var visibilityOptions = [
            {value: 'all', title: $filter('translate')('ALL')},
            {value: 'public', title: $filter('translate')('VISIBILITY_PUBLIC')},
            {value: 'private', title: $filter('translate')('VISIBILITY_PRIVATE')}
        ];

        var statusOptions = [
            {value: 'all', title: $filter('translate')('ALL')},
            {value: 'active', title: $filter('translate')('ACTIVE')},
            {value: 'inactive', title: $filter('translate')('INACTIVE')}
        ];

        var typeOptions = [
            {value: 'all', title: $filter('translate')('ALL')},
            {value: 'options', title: $filter('translate')('OPTIONS')},
            {value: 'buttons', title: $filter('translate')('BUTTONS')}
        ];

        vm.criteria = {
            answerTemplateName: { type: 'text', title: 'Name', value: $stateParams.answerTemplateName },
            answerTemplateVisibility: { type: 'options', title: 'VISIBILITY', value: $stateParams.answerTemplateVisibility, options: visibilityOptions },
            answerTemplateStatus: { type: 'options', title: 'STATUS', value: $stateParams.answerTemplateStatus, options: statusOptions },
            answerTemplateType: { type: 'options', title: 'TYPE', value: $stateParams.answerTemplateType, options: typeOptions },
            answerTemplateOptionName: { type: 'text', title: 'Option / Buttons', value: $stateParams.answerTemplateOptionName },
        };

        function search (params) {
            var override = {
                answerTemplatePage: 1
            };

            $state.go('.', angular.extend({}, params, override), { reload: $state.current });
        }
    }
})();
