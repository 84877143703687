(function () {
    'use strict';

    angular
        .module('elogbooks.common.jobs.workflow')
        .controller('JobApprovalAcceptOrReassignController', JobApprovalAcceptOrReassignController);

    JobApprovalAcceptOrReassignController.$inject = [
        'apiClient',
        '$state',
        'messenger',
        'jobResponse',
        'formData',
        'scopeData',
        '$translate',
        '$q',
        'priorityService',
        'lodash',
        'confirmationModal',
        'operativeCollectionResponse',
        'jobSettings',
        'siteResponse',
        'jobRequisiteCollectionResponse',
        'userManager',
        'documentTypesCollectionResponse',
        'documentTypeService',
        'serviceProviderService',
        'elbSettings',
        'locationCollectionResponse'
    ];

    function JobApprovalAcceptOrReassignController (
        apiClient,
        $state,
        messenger,
        jobResponse,
        formData,
        scopeData,
        $translate,
        $q,
        priorityService,
        lodash,
        confirmationModal,
        operativeCollectionResponse,
        jobSettings,
        siteResponse,
        jobRequisiteCollectionResponse,
        userManager,
        documentTypesCollectionResponse,
        documentTypeService,
        serviceProviderService,
        elbSettings,
        locationCollectionResponse
    ) {
        var vm = this;

        vm.job = jobResponse;

        angular.extend(vm, scopeData);

        vm.submit = submitAction;
        //vm.selectedServiceProviderModel = vm.selectedPriorityModel = {};
        vm.changeServiceProvider = changeServiceProvider;
        vm.changePriority = changePriority;
        vm.changeChargeType = changeChargeType;
        vm.changeOperative = changeOperative;
        vm.changeRequiredDocumentType = changeRequiredDocumentType;
        vm.removeDocumentType = removeDocumentType;
        vm.selectedPriorityModel.onSelect = vm.selectedPriorityModel.onRemove = vm.changePriority;
        vm.jobGroupChildSelectMandatory = jobSettings.jobGroupChildSelectMandatory;
        vm.selectedJobRequisites = [];
        vm.selectedRequiredDocumentTypes = [];
        vm.removeJobRequisite = removeJobRequisite;
        vm.changeRequisiteRequiredCheckbox = changeRequisiteRequiredCheckbox;
        vm.selectedServiceProviderModel.onSelect = vm.selectedServiceProviderModel.onRemove = vm.changeServiceProvider;
        vm.selectedServiceProviderModel.itemDisabledKey = '_links.serviceprovider.contractorApproved';
        vm.selectedServiceProviderModel.itemDisabledKeyValue = false;
        vm.selectedServiceProviderModel.itemDisabledHint = 'NOT_APPROVED';
        vm.selectedServiceProviderModel.itemValuePath = '_links.serviceprovider.title';
        vm.selectedServiceProviderModel.formatValue = serviceProviderService.formatServiceProviderName;
        vm.selectedServiceProviderModel.headers = serviceProviderService.isProsureEnabled ? serviceProviderService.prosureStatusHeaders : null;
        vm.selectedServiceProviderModel.reorder = serviceProviderService.prosureGroupReorder;
        vm.selectedChargeTypeModel.onSelect = vm.selectedChargeTypeModel.onRemove = vm.changeChargeType;
        vm.requiredDocumentType = documentTypesCollectionResponse;
        vm.enforceConfirmationOfEmptyJobRequisites = jobSettings.enforceConfirmationOfEmptyJobRequisites;
        vm.noRequisiteRequired = jobResponse.noRequisiteRequired;
        vm.requiredDocumentType = documentTypesCollectionResponse;
        vm.prosureEnabled = elbSettings.getSetting('prosure_enabled').value;
        vm.isHelpdesk = userManager.hasPermission('site_permission_helpdesk');
        vm.approvers = [];
        vm.requireDocumentTypeWhenRaisingReactiveJob = jobSettings.requireDocumentTypeWhenRaisingReactiveJob;
        vm.siteRequiredDocumentTypeRequired = siteResponse.excludeFromRequiringDocumentType;

        if (vm.prosureEnabled) {
            vm.selectedServiceProviderModel.loadmoreEnabled = true;
        }

        vm.locationCollectionResponse = locationCollectionResponse;
        vm.locationRequired = siteResponse.locationRequired;
        vm.location = formData._links.location;

        var option = [];
        if (jobResponse.type === 'planned') {
            option.push('plannedJobOption');
        } else {
            option.push('reactiveJobOption');
        }

        function selectOperative(selectedOperative) {
            vm.selectedOperativeModel.selected = {
                'href': selectedOperative._links.self.href,
                'title': selectedOperative._links.self.title,
                'object': {
                    'serviceTypes': selectedOperative.serviceTypes
                }
            };
        }

        var operativeParams = {
            permission: 'operative_permission_receive_jobs',
            includeServiceTypes: true
        };

        if (siteResponse) {
            operativeParams.siteId = siteResponse.id;
        }

        vm.selectedOperativeModel = {
            response: operativeCollectionResponse ? operativeCollectionResponse : null,
            responseKeyPath: 'operatives',
            itemValuePath: '_links.self.title',
            onSelect: vm.changeOperative,
            onRemove: vm.changeOperative,
            includeEmbeddedData: 'serviceTypes',
            linkParameters: operativeParams
        };

        vm.selectedJobRequisiteModel = {
            linkParameters: {active: 1},
            required: false,
            responseKeyPath: 'jobRequisites',
            itemHrefPath: '_links.self.href',
            itemValuePath: 'name',
            disabled: vm.enforceConfirmationOfEmptyJobRequisites && vm.noRequisiteRequired,
            onSelect: changeJobRequisite,
            onRemove: changeJobRequisite
        };

        vm.selectedRequiredDocumentTypeModel = {
            response: documentTypesCollectionResponse,
            required: false,
            responseKeyPath: 'documentTypes',
            itemHrefPath: '_links.self.href',
            itemValuePath: 'name',
            linkParameters: {status: 'active'},
            onSelect: changeRequiredDocumentType,
            onRemove: changeRequiredDocumentType
        };

        angular.forEach(vm.job.optedInJobRequisites.jobRequisites, function(requisite) {
            vm.selectedJobRequisites.push(requisite);
        });

        angular.forEach(jobResponse.requiredDocumentTypes.documentTypes, function(docType) {
            vm.selectedRequiredDocumentTypes.push(docType);
        });

        if (jobResponse.getLink('subasset')) {
            vm.selectedAssetModel.selected = jobResponse._links.subasset;
            vm.selectedAssetModel.selected.createdFromSubasset = true;
        } else {
            vm.selectedAssetModel.selected = jobResponse._links.asset;
        }

        if (jobRequisiteCollectionResponse) {
            vm.selectedJobRequisiteModel.response = jobRequisiteCollectionResponse;
            vm.selectedJobRequisiteModel.link = jobRequisiteCollectionResponse.getLink('self');
        }

        if (vm.job._links.operative) {
            // find operative in operativeCollectionResponse, set including service types
            var selectedOperative = null;

            if (operativeCollectionResponse) {
                selectedOperative = lodash.find(operativeCollectionResponse.operatives, function (operative) {
                    return operative._links.self.href === vm.job._links.operative.href;
                });
            }

            if (!selectedOperative) {
                apiClient.get(vm.job._links.operative.href).then(function (response) {
                    if (response) {
                        selectedOperative = response;
                        selectOperative(selectedOperative);
                    }
                });
            } else {
                selectOperative(selectedOperative);
            }
        }

        vm.data = formData;

        vm.data.poNumber = vm.job.poNumber;
        vm.data.isByPlannedDate = ! (vm.data._links.priority && vm.data._links.priority.href);
        vm.data.isOnNextPlannedVisit = false;

        vm.data.assets = {
            asset: vm.job._links.asset,
            subasset: vm.job._links.subasset
        }

        vm.data.requiredDocumentTypes = {
            links: null
        }

        vm.hasChargeType = false;
        if (vm.selectedChargeTypeModel.response.chargetypes.length > 0) {
            vm.hasChargeType = true;
        }

        vm.certificateRequiredDocType = null;
        angular.forEach(vm.requiredDocumentType.documentTypes, function (value) {
            if (value.name === 'Certificate') {
                vm.certificateRequiredDocType = value;
            }
        });

        if (vm.isHelpdesk) {
            angular.forEach(siteResponse._embedded.approvers.reactiveJobApprovers.approvers.memberships, function(approver) {
                vm.approvers.push({
                    name: approver._links.user.title,
                    href: approver._links.user.href
                })
            });
            vm.data._links.approver = {href: vm.approvers[0].href};
        }

        function changeRequiredDocumentType() {
            documentTypeService.changeRequiredDocumentType(vm);
        }

        function removeDocumentType(documentType) {
            documentTypeService.removeDocumentType(vm, documentType);
        }

        function submitAction () {
            const action = () => {
                vm.data.requiredDocumentTypes.links = [];
                angular.forEach(vm.selectedRequiredDocumentTypes, function(value) {
                    vm.data.requiredDocumentTypes.links.push({
                        href: value._links.self.href
                    });
                });

                if (vm.data._links.priority) {
                    vm.data.attendanceDueAt = vm.data.completionDueAt = null;
                }

                if (lodash.has(vm.selectedJobGroupModel,'href')) {
                    vm.data._links.jobgroup = vm.selectedJobGroupModel
                } else if (lodash.has( vm.selectedJobGroupModel,'selected')) {
                    vm.data._links.jobgroup = vm.selectedJobGroupModel.selected;
                } else {
                    delete vm.data._links.jobgroup;
                }

                if (typeof vm.data.assets !== 'undefined') {
                    if (vm.data.assets.asset) {
                        vm.data._links.asset = vm.data.assets.asset;
                    }

                    if (vm.data.assets.subasset) {
                        vm.data._links.subasset = vm.data.assets.subasset;
                    }
                } else {
                    vm.data._links.asset = null;
                    vm.data._links.subasset = null;
                }

                angular.forEach(vm.selectedJobRequisites, function(requisite) {
                    vm.data.optedInJobRequisites.links.push(requisite._links.self);
                });

                if (!vm.data._links.statutorytype || !vm.data._links.statutorytype.href) {
                    delete vm.data._links.statutorytype;
                }

                vm.data.noRequisiteRequired = vm.noRequisiteRequired;

                return apiClient.create(vm.job.getLink(vm.action), vm.data).then(function (response) {
                    if (response) {
                        return $state.go('^.^', {}, { reload: $state.get('^.^.^') }).then(function () {
                            messenger.success(vm.translation + '_SUCCESS');
                        });
                    }
                });
            };

            if (vm.prosureEnabled && vm.prosureWarningMessage) {
                confirmationModal.open({
                    bodyMessage: vm.prosureWarningMessage,
                    titleMessage: 'ARE_YOU_SURE_HEADER',
                    primaryText: 'CONTINUE',
                    secondaryText: 'CANCEL'
                }).result.then(() => {
                  action();
                });
            } else {
                action();
            }
        }


        vm.spPartnership = {
            linkParameters: {
                siteId: siteResponse.id,
                active: true,
                entityTypes: 'entity_type_all,entity_type_job'
            }
        };

        if (vm.selectedServiceProviderModel.selected) {
            apiClient.get(vm.selectedServiceProviderModel.selected.object.getLink('partnerships'), vm.spPartnership.linkParameters).then(function (partnershipResponse) {
                setPartnershipPriorities(partnershipResponse.partnerships[0]);
                checkPermission();
            });
        }

        /**
         * If the service provider's partnership has priorities set for the SP, we display only partnership priorities in the Priority dropdown list
         * @param partnershipResponse
         * @param callback
         */
        function setPartnershipPriorities(partnershipResponse, callback) {
            apiClient.get(partnershipResponse.getLink('priorities')).then(function (response) {
                var partnershipPriorities = response.priorities.filter(function (p) {
                    return p.type === 'partnership-priority' && (p.entityType === 'entity_type_all' || p.entityType === 'entity_type_job');
                });

                if (partnershipPriorities.length >= 1) {
                    response.priorities = partnershipPriorities;
                    vm.selectedPriorityModel.response = priorityService.addExtraPriorities(response);
                    vm.selectedPriorityModel.link = vm.selectedServiceProvider.getLink('priorities');
                    callback(true);
                } else {
                    callback(false);
                }
            });
        }

        function addJobRequisites() {
            apiClient.get(userManager.user.getLink('job-requisites'), {
                active: 1,
                optIn: true,
                optOut: true,
                'option[]': option,
                site: siteResponse.id,
                serviceProvider: vm.selectedServiceProviderModel.selected.object.id || vm.selectedServiceProviderModel.selected.object.getLinkAttribute('serviceprovider', 'id')
            }).then(function(response) {
                vm.selectedJobRequisites = [];
                vm.selectedJobRequisiteModel.response = response;
                response.jobRequisites.forEach(function (jr) {
                    if (jr.reactiveJobOption === 3) { //opt_out
                        vm.selectedJobRequisites.push(jr);
                    }
                });
            });
        }

        if (vm.prosureEnabled && vm.selectedServiceProviderModel && vm.selectedServiceProviderModel.selected) {
            vm.prosureWarningMessage = serviceProviderService.setProsureWarningMessage(vm.selectedServiceProviderModel.selected);
        }

        function changeServiceProvider () {
            vm.selectedJobRequisites = [];
            vm.prosureWarningMessage = null;

            if (vm.selectedServiceProviderModel && vm.selectedServiceProviderModel.selected) {
                vm.submitDisabled = true;

                if (vm.prosureEnabled) {
                    vm.prosureWarningMessage = serviceProviderService.setProsureWarningMessage(vm.selectedServiceProviderModel.selected);
                }

                addJobRequisites();

                apiClient.get(vm.selectedServiceProviderModel.selected.href).then(function (response) {
                    if (response) {
                        vm.selectedServiceProvider = response;
                        vm.selectedPriorityModel.loading = true;
                        vm.data._links.serviceprovider = {href: vm.selectedServiceProvider.getLink('self')};
                        vm.selectedOperativeModel.selected = null;
                        vm.selectedPriorityModel.selected = null;
                        vm.changeOperative();
                        if (vm.selectedServiceProvider.getLink('joboperatives')) {
                            apiClient.get(vm.selectedServiceProvider.getLink('joboperatives'), operativeParams)
                                .then(function(response) {
                                    if (response.operatives) {
                                        vm.selectedOperativeModel.response = response;
                                        vm.selectedOperativeModel.link = vm.selectedServiceProvider.getLink('joboperatives');
                                    } else {
                                        vm.selectedOperativeModel.response = null;
                                    }
                                });
                        } else {
                          vm.selectedOperativeModel.response = null;
                        }

                        $q.all({
                            priorities: apiClient.get(vm.selectedServiceProvider.getLink('priorities'), vm.selectedPriorityModel.linkParameters),
                            partnershipResponse: apiClient.get(vm.selectedServiceProvider.getLink('partnerships'), vm.spPartnership.linkParameters),
                            /** @todo Service Provider Asset Route */
                            assets: apiClient.get(vm.siteResponse.getLink('assets'), { active: 1 })
                        }).then(function (promises) {
                            if (! promises.priorities || ! promises.assets) {
                                apiClient.noResourceFound();
                            }

                            setPartnershipPriorities(promises.partnershipResponse.partnerships[0], function (spHasPartnershipPriorities) {
                                if (!spHasPartnershipPriorities) {
                                    if (promises.priorities.priorities.length) {
                                        vm.selectedPriorityModel.response = priorityService.addExtraPriorities(promises.priorities);
                                        vm.selectedPriorityModel.link = vm.selectedServiceProvider.getLink('priorities');
                                    } else {
                                        vm.selectedPriorityModel.response = priorityService.addExtraPriorities(vm.priorities);
                                        vm.selectedPriorityModel.link = vm.priorities.getLink('self');
                                    }
                                }
                            });

                            checkPermission();

                            if (promises.assets.assets.length) {
                                vm.selectedAssetModel.response = promises.assets;
                                vm.selectedAssetModel.link = vm.siteResponse.getLink('assets');
                                vm.selectedAssetModel.required = vm.selectedServiceProvider.requiresAssetsWhenLoggingReactiveJob;
                            } else {
                                vm.data._links.asset = null;
                                vm.selectedAssetModel.response = null;
                                vm.selectedAssetModel.link = null;
                                vm.selectedAssetModel.required = false;
                            }

                            vm.submitDisabled = false;
                            vm.selectedPriorityModel.loading = false;
                        });

                        return;
                    }

                    return apiClient.noResourceFound();
                });
            } else {
                vm.selectedServiceProvider = null;
                vm.data._links.serviceprovider = null;
            }
        }

        function changePriority () {
            if (vm.selectedPriorityModel.selected) {

                vm.data.isByPlannedDate = vm.selectedPriorityModel.selected.value === $translate.instant('BY_PLANNED_DATE');
                vm.data.isOnNextPlannedVisit = vm.selectedPriorityModel.selected.value === $translate.instant('JOB_NEXT_PLANNED_VISIT');

                if(vm.selectedPriorityModel.selected.object.alertMessageEnabled) {
                    confirmationModal.open({
                        titleMessage: 'PRIORITY_MESSAGE',
                        bodyMessage: vm.selectedPriorityModel.selected.object.alertMessage,
                        primaryText: 'OK',
                        primaryClass: 'btn-primary',
                        secondaryText: ''
                    });
                }

                if (vm.data.isByPlannedDate || vm.data.isOnNextPlannedVisit) {
                    delete vm.data._links.priority;
                } else {
                    vm.data._links.priority = {href: vm.selectedPriorityModel.selected.href};
                }

            } else {
                delete vm.data._links.priority;
                vm.data.isByPlannedDate = vm.data.isOnNextPlannedVisit = false;
            }
        }

        function checkPermission () {
            if (vm.selectedPriorityModel.selected) {
                var valid = false;

                angular.forEach(vm.selectedPriorityModel.response.priorities, function (value, index) {
                    if (value._links.self.href === vm.selectedPriorityModel.selected.href) {
                        valid = true;
                    }
                });

                if (! valid) {
                    vm.selectedPriorityModel.selected = null;
                }
            }
        }

        function changeChargeType () {
            if (vm.selectedChargeTypeModel.selected) {
                vm.data._links.chargetype = {href: vm.selectedChargeTypeModel.selected.href};
            } else {
                vm.data._links.chargetype = null;
            }
        }

        function changeOperative () {
            if (
                vm.selectedOperativeModel.selected
                && typeof vm.selectedOperativeModel.selected !== 'undefined'
            ) {
                vm.data._links.operative = vm.selectedOperativeModel.selected;
            } else {
                vm.selectedOperativeModel.selected = null;
                delete vm.data._links.operative;
            }
        }

        function changeJobRequisite() {
            if (vm.selectedJobRequisiteModel.selected && vm.selectedJobRequisiteModel.selected.object) {
                var isAlreadySelected = false;

                angular.forEach(vm.selectedJobRequisites, function(value, index) {
                    if (value._links.self.href === vm.selectedJobRequisiteModel.selected.object._links.self.href) {
                        isAlreadySelected = true;
                    }
                });

                if (!isAlreadySelected) {
                    vm.selectedJobRequisites.push(vm.selectedJobRequisiteModel.selected.object);
                }

                vm.selectedJobRequisiteModel.selected = null;
            }
        }

        function removeJobRequisite(jobRequisite) {
            angular.forEach(vm.selectedJobRequisites, function(value, index) {
                if (value._links.self.href === jobRequisite._links.self.href) {
                    vm.selectedJobRequisites.splice(index, 1);
                }
            });
        }

        function changeRequisiteRequiredCheckbox(value) {
            if (value) {
                vm.selectedJobRequisiteModel.disabled = true;
            } else {
                vm.selectedJobRequisiteModel.disabled = false;
            }
        }
    }
})();
