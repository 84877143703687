(function() {
    'use strict';

    angular
        .module('elogbooks.admin.action-types')
        .controller('ActionTypesAddEditController', ActionTypesAddEditController);

    ActionTypesAddEditController.$inject = [
        '$state',
        'crudService',
        'actionTypeResponse',
        'actionTypeCollectionResponse',
        'parentResponse',
        'requestDataFactory',
        '$translate',
        'lodash'
    ];

    function ActionTypesAddEditController(
        $state,
        crudService,
        actionTypeResponse,
        actionTypeCollectionResponse,
        parentResponse,
        requestDataFactory,
        $translate,
        lodash
    ) {
        var vm = this;
        vm.actionTypeResponse = actionTypeResponse;
        vm.actionTypeCollectionResponse = actionTypeCollectionResponse;
        vm.data = requestDataFactory.createRequest(vm.actionTypeResponse);

        vm.actionTypeSelectModel = {
            disabled: vm.actionTypeResponse.systemType,
            response: actionTypeCollectionResponse,
            link: actionTypeCollectionResponse.getLink('self'),
            required: false,
            responseKeyPath: 'actiontypes',
            itemHrefPath: '_links.self.href',
            itemValuePath: 'summary',
            searchKey: 'summary',
            isHierarchical: true,
            onSelect: changeActionType,
            onRemove: changeActionType,
            getResponseData: function(response) {
                return lodash.get(response.getData(), this.responseKeyPath).filter(function(item) {
                    return item.id !== vm.data.id;
                });
            },
        };

        if (parentResponse.id) {
            vm.actionTypeSelectModel.selected = {
                href: parentResponse.getLink('self'),
                title: parentResponse.summary,
                object: parentResponse
            };

            changeActionType();
        }

        vm.statusOptions = [
            { value: true, title: $translate.instant('STATUS_ACTIVE') },
            { value: false, title: $translate.instant('STATUS_INACTIVE') }
        ];

        vm.create = createAction;
        vm.update = updateAction;
        vm.delete = deleteAction;

        function createAction() {
            return crudService.create(
                vm.actionTypeCollectionResponse.getLink('create'),
                vm.data,
                $state.get('^'),
                'resource',
                'ACTION_TYPE'
            );
        }

        function updateAction() {
            return crudService.update(
                vm.actionTypeResponse.getLink('edit'),
                vm.data,
                $state.get('^'),
                $state.get('^'),
                'ACTION_TYPE'
            );
        }

        function deleteAction() {
            return crudService.delete(
                vm.actionTypeResponse.getLink('delete'),
                $state.get('^'),
                'ACTION_TYPE'
            );
        }

        function changeActionType() {
            vm.data._links.parent = undefined;
            if (vm.actionTypeSelectModel.selected) {
                vm.data._links.parent = {href: vm.actionTypeSelectModel.selected.href};
            }
        }
    }
})();
