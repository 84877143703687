/**
 * Score Range enhanced displaay
 */
angular.module('elogbooksFilters').filter('scoreRange', function() {
    return function(value) {

        switch(value) {
            case 'yn':
                return 'Yes/No';
            case '0to2':
                return '0 to 2';
            case '0to5':
                return '0 to 5';
        }
        return value;
    };
});
