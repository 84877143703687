(function () {
    'use strict';

    angular
        .module('elogbooks.admin.request-logs')
        .controller('RequestLogResponsesController', RequestLogResponsesController);

    RequestLogResponsesController.$inject = ["requestResponsesResponse", "lodash", '$translate'];

    function RequestLogResponsesController(requestResponsesResponse, lodash, $translate) {
        var vm = this;
        vm.responsesCollection = requestResponsesResponse;
        vm.responses = requestResponsesResponse.requestlogresponses;

        vm.responses.forEach(function(el) {
            try {
                var response = JSON.parse(el.body);
                if (lodash.has(response, 'message')) {
                    el.message = response.message;
                }

                if (lodash.has(response, 'error.message')) {
                    el.message = response.error.message;
                    if (response.error.exception && response.error.exception[0].message) {
                        el.message += ' - ' + response.error.exception[0].message;
                    }
                }
            } catch (error) {
                if (el.status === 200) {
                    el.message = el.body;
                }
            }
        });
    }
})();