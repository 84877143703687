/**
 * Sites for a user
 */
angular.module('elogbooksDirectives').directive('siteUser', ['apiClient', 'userManager', function (apiClient, userManager) {
    return {
        // restrict to attributes only
        restrict: 'A',
        require: ['ngModel'],
        scope: {
            site: '=ngModel',
            disabled: '=ngDisabled',
            hasPartnership: '='
        },
        templateUrl: '/modules/directives/form-elements/site/site.html',
        link: function (scope) {

            scope.options = {};

            if (scope.$parent.selectedSite) {
                scope.disabled = 'disabled';
                scope.site = scope.$parent.selectedSite;
                scope.options = [{
                    _links: { self: { href: scope.site } },
                    name: scope.$parent.selectedSiteResponse.name
                }];
            } else {
                userManager.getUser().then(function (user) {
                    var params = {};
                    if (scope.hasPartnership) {
                        params['has-partnership'] = true;
                    }
                    apiClient.get(user._links.sites.href, params).then(function (response) {
                        scope.options = response.getData().sites;
                    })
                });
            }
        }
    }
}]);
