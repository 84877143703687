app.config(function($translateProvider) {
    $translateProvider.translations('en-gb', {
        PARTNERSHIP: 'Partnership',
        PARTNERSHIPS: 'Partnerships',

        ADD_NEW_PARTNERSHIP: 'Add a new Partnership',

        PARTNERSHIP_ADD: 'Add a new Partnership',
        PARTNERSHIPS_ADD: 'Add Partnerships',
        PARTNERSHIPS_LIST: 'Partnerships',

        SERVICE_PROVIDER_PARTNERSHIP_ADD: 'Add a new Partnership',

        PARTNERSHIP_LIST: 'Partnerships',
        PARTNERSHIP_EDIT: 'Edit Partnership',

        PARTNERSHIP_CREATED: 'Partnership Created',
        PARTNERSHIP_UPDATED: 'Partnership Updated',
        PARTNERSHIP_DELETED: 'Partnership Deleted',

        PARTNERSHIP_SURVEYS_ADDED: 'Surveys Added',
        PARTNERSHIP_SURVEYS_DELETED: 'Surveys Deleted',

        PARTNERSHIP_STATUS: 'Partnership Status',

        SURVEYS_ADDED: 'Surveys Added',
        SURVEYS_DELETED: 'Surveys Deleted',

        PARTNERSHIP_IN_USE: 'Partnership in use'
    });
});
