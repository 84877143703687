(function () {
    'use strict';

    /**
     * @desc ui.select expanded widget
     * @example <div elogbooks-select-multiple ng-model="vm.model"></div>
     */
    angular.module('elogbooksDirectives').directive('elogbooksSelectMultiple', function ($uibModal, apiClient, lodash, $q) {
        return {
            restrict: 'A',
            templateUrl: '/modules/directives/form-elements/select-multiple/select-multiple.html',
            scope: {
                model: '=ngModel'
            },
            require: ['ngModel'],
            controller: ['$scope', function($scope) {
                var defaultModel = {
                    link : null,
                    loading : false,
                    responseKeyPath: 'data',
                    itemHrefPath: '_links.self.href',
                    itemValuePath:'name',
                    keyword: null,
                    expandedSearch: false,
                    required : false,
                    disabled : false,
                    selectedItems: [],
                    items: [],
                    map : map,
                    getResponseData : getResponseData,
                    filterResponse : filterResponse,
                    clear : clearAction
                };

                var canceller = $q.defer();
                $scope.add = addAction;
                $scope.remove = removeAction;

                //Merge with defaults
                $scope.model = lodash.merge(defaultModel, $scope.model);

                //Prefill data
                if ($scope.model.response) {
                    $scope.model.items = lodash.differenceBy($scope.model.filterResponse($scope.model.response), $scope.model.selectedItems, 'href');
                }

                $scope.$watch('model.keyword', lodash.debounce(searchAction, 500));

                function removeAction () {
                    $scope.model.selectedItems = lodash.difference($scope.model.selectedItems, $scope.selectedItemsSelect);
                    $scope.model.items = lodash.union($scope.model.items, $scope.selectedItemsSelect);
                }

                function addAction () {
                    $scope.model.items = lodash.difference($scope.model.items, $scope.itemsSelect);
                    $scope.model.selectedItems = lodash.unionBy($scope.model.selectedItems, $scope.itemsSelect, 'href');
                }

                function searchAction(newVal, oldVal) {
                    if (newVal !== oldVal) {
                        canceller.resolve();
                        var params = {};
                        params[$scope.model.searchKey] = $scope.model.keyword;
                        params.timeout = canceller.promise;

                        $scope.model.loading = true;

                        canceller = $q.defer();

                        apiClient.get($scope.model.link, params).then(function (response) {
                            $scope.model.loading = false;
                            $scope.model.items = lodash.differenceBy($scope.model.filterResponse(response), $scope.model.selectedItems, 'href');
                        });

                    }
                }

                function map(keyPath, valuePath) {
                    return function (item) {
                        if (typeof item === 'undefined'){
                            return;
                        }

                        return  {
                            id :  lodash.get(item, 'id'),
                            href :  lodash.get(item, keyPath),
                            value : lodash.get(item, valuePath)
                        };
                    };
                }

                function getResponseData(response) {
                    return lodash.get(response.getData(), this.responseKeyPath);
                }

                function filterResponse(response) {
                    return this.getResponseData(response).map(
                        this.map(this.itemHrefPath, this.itemValuePath)
                    );
                }

                function clearAction($event) {
                    $event.stopPropagation();
                    this.selectedItems = null;
                    $scope.model.selectedItems = null;
                }
            }]
        };
    });
})();
