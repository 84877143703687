app.config(function ($translateProvider) {
    $translateProvider.translations('zh-hk', {
        AUDIT: '審計',
        AUDITS: '審計',
        AUDIT_LIST: '審計表',

        AUDIT_ADD: '新增審計',

        QUESTIONS: '問題',
        QUESTION: '問題',

        AUDIT_SUB_SECTIONS: '小節',
        AUDIT_QUESTIONS: '問題',

        START_AUDIT: '開始審計',
        COMPLETE_AUDIT: '完成審計',

        QUESTIONS_ANSWERED: '已回答問題的數量',
        COMPLETION: '完成',

        NUMBER_OF_QUESTIONS: '問題的數量',
        ANSWERED_QUESTIONS: '已回答問題',

        NUMBER_OF_SECTIONS: '章節的數量',
        NUMBER_OF_SUB_SECTIONS: '小節的數量',

        DEFAULT_BUTTON_OPTIONS: '預設按鈕選項',

        NOT_YET_ANSWERED: '未回答',

        CONFIRM_ANSWER_CHOICE: '確定回答選項',
        AUDIT_ANSWER_EXTRA_INFORMATION: '可選附加資訊',

        AUDIT_ANSWER_OPTIONS_NO_DEFAULT: '未預設請人手設定',
        AUDIT_ANSWER_OPTIONS_NO_YES: '否(0%), 是(100%)',
        AUDIT_ANSWER_OPTIONS_YES_NO: '否(0%), 是(100%)',

        SCORE: '分數',

        AUDIT_CREATED:  '建立',
        AUDIT_STARTED: '進行中',
        AUDIT_DRAFT: '草稿',
        AUDIT_CANCELLED:  '取消',
        AUDIT_COMPLETED:  '完成',
        AUDIT_TEMPLATES:  '樣板',
        AUDIT_TEMPLATE:  '樣板',
        AUDIT_TEMPLATE_CREATED: '審計樣板已建立',

        QUESTION_TYPE: '問題類別',
        COMMENT_TYPE: '只作評論',
        BUTTONS_TYPE: '按鈕選項',
        OPTIONS_TYPE: '下拉選項',

        ATTACHMENT_REQUIRED: '需要附件',

        WEIGHTING: '比重',

        CREATE_AS_TEMPLATE: '建立為樣板',
        CREATE_FROM_TEMPLATE: '從樣板建立',

        INSTRUCTION: '指示',

        AUDIT_SECTION: '章節',

        ACTIVATE_AUDIT: '啟用',
        CANCEL_AUDIT: '取消',
        AUDIT_SECTIONS: '章節',
        AUDIT_EDIT: '修改審計',
        AUDIT_UPDATED: '更新審計',
        AUDIT_DELETED: '刪除審計',
        AUDIT_CONFIRM_ACTIVATE_INSTRUCTIONS: '你是否確定?',
        AUDIT_CONFIRM_CANCEL_INSTRUCTIONS: '請輸入支援理據',
        AUDIT_CANCEL_REASON: '取消原因',
        ADD_SECTION: '新增章節',
        EDIT_SECTION: '修改章節',
        SECTION: '章節',
        ADD_SUB_SECTION: '新增小節',
        ADD_QUESTION: '新增問題',
        SECTIONS: '章節',
        AUDIT_SECTION_UPDATED: '審計章節已更新',
        AUDIT_UPDATED_TO_CANCELLED: '審核更新為取消',

        AUDIT_HAS_NO_ASSIGNED_OPERATIVE: '審計未分配操作',
        AUDIT_HAS_NO_ASSIGNED_SERVICE_PROVIDER: '審計未分配服務供應商',
        AUDIT_HAS_NO_QUESTIONS: '審計未有問題',
        AUDIT_SECTION_CREATED: '審計章節已建立',
        AUDIT_SECTION_DELETED: '審計章節已刪除',
        AUDIT_QUESTION_CREATED: '審計問題已建立',
        AUDIT_QUESTION_UPDATED: '審計問題已更新',
        AUDIT_QUESTION_DELETED: '審計問題已刪除',
        AUDIT_UPDATED_TO_ACTIVATED: '審核更新為啟用',
        ADD_AUDIT_QUESTION: '新增問題',
        EDIT_AUDIT_QUESTION: '修改問題',
        AUDIT_QUESTION_COMMENT: '評論',
        AUDIT_QUESTION_OPTIONS: '選項',
        AUDIT_QUESTION_BUTTONS: '按鈕',
        ANSWER: '答案',
        ANSWER_OPTIONS: '答案選項',
        POINTS_VALUE: '點',

        AUDIT_IMAGE: '審計圖片',

        AUDIT_GLOBAL_TEMPLATE: '審計全球樣板'
    });
});
