(function () {
    'use strict';

    angular
        .module('elogbooks.admin.serviceroutines')
        .controller('ServiceRoutineDetailsController', ServiceRoutineDetailsController);

    ServiceRoutineDetailsController.$inject = ['serviceRoutineResponse'];

    function ServiceRoutineDetailsController (serviceRoutineResponse) {
        var vm = this;
            vm.serviceRoutine = serviceRoutineResponse;
    }
})();
