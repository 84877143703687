(function () {
    'use strict';

    angular
        .module('elogbooks.user.manage-operatives')
        .controller('ManageOperativeInfoController', ManageOperativeInfoController);

    ManageOperativeInfoController.$inject = ['$location', 'operativeResponse'];

    function ManageOperativeInfoController($location, operativeResponse) {
        var vm = this;
        $location.search('position', null);
        vm.user = operativeResponse;
    }
})();
