(function () {
    'use strict';

    angular
        .module('elogbooksDirectives')
        .directive('elbPdfButton', ElogbooksPdfButton);

    function ElogbooksPdfButton() {
        return {
            restrict: 'E',
            scope: {
                link: '=',
                params: '=?',
                count: '@',
                countMax: '@',
                tidyTablesFilter: '=',
            },
            transclude: true,
            templateUrl: '/modules/directives/buttons/export/export.html',
            controller: function ($scope, apiClient, messenger, $translate, $stateParams, taskParamsService) {
                $scope.exportResources = exportResources;
                $scope.button = 'DOWNLOAD_PDF';

                function exportResources() {
                    var params = taskParamsService.getTaskParams($stateParams);
                    apiClient.create($scope.link, {
                        columns: Object.keys($scope.tidyTablesFilter.columns).filter(function(key){return $scope.tidyTablesFilter.columns[key]}),
                        filters: params
                    }).then(handleExportResponse);
                }

                function handleExportResponse(response) {
                    if (response.id) {
                        messenger.success('EXPORT_IN_PROCESS');
                    }
                }
            }
        };
    }

})();
