(function() {
    'use strict';

    angular
        .module('elogbooks')
        .config(Translations);

    function Translations($translateProvider) {
        $translateProvider.translations('nl', {
            REPORTS: 'Rapporten',
            REPORT_LIST: 'Rapporten',
            REPORT: 'Rapport',

            BETWEEN_DATES: 'Tussen datums in',
            SHOW_PERCENTAGES: 'Show Percentage',

            SP_SUMMARY_REPORT: 'Leverancier Rapport (samenvatting).',
            SP_NAME: 'Lev naam',
            REACTIVE_ATTENDANCE: 'Correctief op tijd aanwezig',
            REACTIVE_MISSED_ATTENDANCE: 'Correctief niet op tijd aanwezig',
            REACTIVE_COMPLETION: 'Correctief op tijd afgerond ',
            REACTIVE_MISSED_COMPLETION: 'Correctief niet op tijd afgerond',
            PPM_ATTENDANCE: 'Preventief op tijd aanwezig',
            PPM_MISSED_ATTENDANCE: 'Preventief niet op tijd aanwezi',
            PPM_COMPLETION: 'Preventief op tijd afgerond',
            PPM_MISSED_COMPLETION: 'Preventief niet op tijd afgerond',
            QUOTES: 'Offertes',
            EXTENSION_REQUEST: 'Verzoek om uitstel',
            REOPENED_JOBS: 'Heropende opdrachten',

            OVERDUE_PLANNED_ATTENDANCE: 'Preventief niet op tijd aanwezig',
            OVERDUE_PLANNED_COMPLETION: 'Preventied niet op tijd afgerond',
            OVERDUE_PLANNED: 'Overdue Preventief',

            COMPLETED_DUE: 'Completed Due',
            ATTEND_DUE: 'Attend Due',
            COMPLETED: 'Completed',
            ATTENDED: 'Attended',

            EXTENSION_REQUESTS: 'Extension Requests',
            JOB_ESCALATIONS: 'Job Escalations',
            QUOTE_ESCALATIONS: 'Quote Escalations',

            EXTENSION_FROM: 'Extension From',
            EXTENSION_TO: 'Extension To',
            EXTENSION_STATUS: 'Extension Status',

            QUOTES_COMPLETED: 'Quotes Completed',
            QUOTES_MISSED: 'Quotes Missed',

            LAST_REOPENED_AT: 'Last Reopened At',
            SERVICE_PROVIDER_SUMMARY: 'Service Provider Summary',

            FM_NAME: 'FM Name',
            APPROVER_NAME: 'Approver Name',
            PLANNED_ATTENDANCE: 'Planned Attendance',
            PLANNED_COMPLETION: 'Planned Completion',
            PLANNED_WORK: 'Planned Work',
            REACTIVE_WORK: 'Reactive Work',
            KPI_SURVEYS: 'KPI Surveys',
            LAST_LOGGED_IN: 'Last Logged In',
            SITES_WITH_PPM_REQUIRING_APPROVAL_CONCISE: 'Sites - PPM Approvals',
            JOBS_APPROVED: 'Jobs Approved',
            AVERAGE_TIME_TAKEN: 'Average Time Taken',
            AVERAGE_TIME_ACROSS_ENTIRE_SYSTEM: 'Average Time Across Entire System',
            QUOTES_APPROVED: 'Quotes Approved',
            SITES_WITH_QUOTES_REQUIRING_APPROVAL: 'Sites - Quote Approvals',
            LIVE_STAT: 'This is a live statistic. The Date Range filter does not apply to the following figures.',
            KPI_SURVEYS_OUTSTANDING: 'KPI Surveys Outstanding',
            CUSTOMER_GROUP: 'Customer Group',
            QUOTES_REQUIRING_APPROVAL: 'Quotes Requiring Approval',
            TASKS_REQUIRING_APPROVAL: 'Tasks Requiring Approval',
            SUBMITTED_DATE: 'Submitted Date',
            PPM_ON_TRACK: 'PPM On Track',
            PPM_REQUIRING_ATTENTION: 'PPM Requiring Attention',
            REACTIVE_ON_TRACK: 'Reactive On Track',
            REACTIVE_REQUIRING_ATTENTION: 'Reactive Requiring Attention',
            ACTIVITY_REPORT: 'Activity Report',
            REACTIVE_PAPERWORK_FOR_REVIEW: 'Reactive Paperwork for Review',
            PLANNED_PAPERWORK_FOR_REVIEW: 'Planned Paperwork for Review',
            PAPERWORK_FOR_REVIEW: 'Paperwork for Review',
            REACTIVE_ESCALATED_TO_CLIENT: 'Reactive Escalated to Client',
            PLANNED_ESCALATED_TO_CLIENT: 'Planned Escalated to Client',
            PPM_REQUIRING_APPROVAL: 'PPM Requiring Approval',
            OUTSTANDING_KPI_SURVEYS: 'Outstanding KPI Surveys',
            RAISED_DATE: 'Raised Date',
            INVALID_FM: 'Invalid FM',
            UNKNOWN: 'Unknown',
            REPORT_QUEUED: 'Report Queued',
            AVERAGE_TIME_ACROSS_SYSTEM: 'Average Time Across The Entire System',
            SITE_QUOTES_REQUIRING_APPROVAL: 'Sites With Quotes Requring approval',
            AGE_OF_APPROVALS: 'Age Of Approvals',
            AVERAGE: 'Average',


            // Summary report
            SUMMARY_REPORT: 'Summary',
            JOBS_BY_REGION: 'Jobs By Region',
            JOBS_BY_PRIORITY: 'Jobs By Priority',
            JOBS_BY_JOB_GROUP: 'Jobs By Job Group',
            JOBS_BY_SERVICEPROVIDER: 'Jobs By Service Provider',
            JOBS_BY_SITE_GROUP: 'Jobs By Site Group',
            JOBS_BY_SITE: 'Jobs By Site',
            QUOTES_BY_SITE: 'Quotes By Site',
            QUOTES_BY_SP: 'Quotes By SP',
            QUOTES_BY_SERVICEPROVIDER: 'Quotes By Service Provider',
            JOB_GROUP: 'Job Group',
            TOTAL_JOBS: 'Total Jobs',
            OPEN_JOBS: 'Open Jobs',
            COMPLETED_JOBS: 'Completed Jobs',
            MISSED_JOBS: 'Missed Jobs',
            ATTENDED_ON_TIME: 'Attended On Time',
            COMPLETED_ON_TIME: 'Completed On Time',
            TOTAL_QUOTES: 'Total Quotes',
            ACCEPTED_QUOTES: 'Accepted Quotes',
            AVERAGE_TIME_TO_QUOTE: 'Average time to quote',
            DECLINED_QUOTES: 'Declined Quotes',
            EXPIRED_QUOTES: 'Expired Quotes',
            REJECTED_QUOTES: 'Rejected Quotes',
            TOTAL_KPI: 'Total KPI Surveys',
            AVERAGE_SCORE: 'Average Score',
            ATTENDANCE_DUE_DATE: 'Attendance Due Date',
            COMPLETION_DUE_DATE: 'Completion Due Date',
            DATE_ATTENDED: 'Date Attended',
            DATE_COMPLETED: 'Date Completed',
            REPORTING_PERIOD: 'Reporting Period (m/Y)',
            SERVICEPROVIDER_SCORE: 'SP Score',
            CLIENT_SCORE: 'Client Score',
            TARGET_SCORE: 'Target Score',
        });
    }
})();
