(function () {
    'use strict';

    angular
        .module('elogbooks.admin.document-types-management')
        .controller('DocumentSetAddEditController', DocumentSetAddEditController);

    DocumentSetAddEditController.$inject = [
        '$state',
        '$scope',
        'config',
        'apiClient',
        'lodash',
        'confirmationModal',
        'documentSetResponse',
        'requestDataFactory',
        'messenger',
        'documentSetCollectionResponse',
        'documentTypeCollectionResponse',
        'siteCollectionResponse',
        '$translate'
    ];

    function DocumentSetAddEditController (
        $state,
        $scope,
        config,
        apiClient,
        lodash,
        confirmationModal,
        documentSetResponse,
        requestDataFactory,
        messenger,
        documentSetCollectionResponse,
        documentTypeCollectionResponse,
        siteCollectionResponse,
        $translate
    ) {
        var vm = this;
        vm.documentSet = documentSetResponse;
        vm.documentTypeCollectionResponse = documentTypeCollectionResponse;
        vm.siteCollectionResponse = siteCollectionResponse;
        vm.data = requestDataFactory.createRequest(getDefaults());
        vm.config = config;

        vm.edit = config.edit;

        vm.create = createAction;
        vm.update = updateAction;
        vm.delete = deleteAction;

        vm.statusOptions = [
            { value: true, title: $translate.instant('STATUS_ACTIVE') },
            { value: false, title: $translate.instant('STATUS_INACTIVE')},
        ];

        if (documentTypeCollectionResponse) {
            vm.documentTypesSelect = {
                response: documentTypeCollectionResponse,
                link: documentTypeCollectionResponse.getLink('self'),
                responseKeyPath: 'documentTypes',
                itemValuePath: 'name',
            };
        }

        if (siteCollectionResponse && !vm.edit){
            vm.sitesSelect = {
                response: siteCollectionResponse,
                link: siteCollectionResponse.getLink('self'),
                responseKeyPath: 'sites',
                searchKey: 'siteName'
            };
        }

        $scope.$watch('vm.data.selecAllDocumentTypes', function() {
           if (vm.data.selectAllDocumentTypes) {
               vm.documentTypesSelect.selectedItems = [];
           }
        });

        function getDefaults() {
            return {
                id: vm.documentSet.id || null,
                name: vm.documentSet.name || null,
                description: vm.documentSet.description || null,
                active: typeof vm.documentSet.active === 'undefined' || vm.documentSet.active === null ? true : vm.documentSet.active,
                selectAllDocumentTypes: false
            };
        }

        function createAction() {
            if (vm.documentTypesSelect.selectedItems.length === 0 && !vm.data.selectAllDocumentTypes) {
                messenger.error('SELECT_AT_LEAST_ONE_DOCUMENT_TYPE');
                return ;
            }
            vm.data._links = {
                site: []
            };

            if (vm.sitesSelect && vm.sitesSelect.selectedItems.length) {
                lodash.forEach(vm.sitesSelect.selectedItems, function(item) {
                    vm.data._links.site.push(item.href);
                });
            }

            if (vm.documentTypesSelect.selectedItems.length) {
                vm.data.types = [];

                lodash.forEach(vm.documentTypesSelect.selectedItems, function(item) {
                    var data = {
                        _links: {
                            documenttype:{href: item.href}
                        },
                        isMandatory: item.isMandatory
                    };
                    vm.data.types.push(data);
                });
            }

            return apiClient.create(documentSetCollectionResponse.getLink('create'), vm.data).then(function (response) {
                if (response) {
                    $state.go('^', {}, { reload: $state.current.parent }).then(function () {
                        messenger.success('DOCUMENT_SET_CREATED');
                    });
                } else {
                    messenger.error('REQUEST_ERROR');
                }
            });
        }

        function updateAction() {
            return apiClient.update(vm.documentSet.getLink('edit'), vm.data).then(function (response) {
                if (response) {
                    $state.go('^', {}, { reload: 'dashboard.admin.document-types-management.document-sets' }).then(function () {
                        messenger.success('DOCUMENT_SET_UPDATED');
                    });
                } else {
                    messenger.error('REQUEST_ERROR');
                }
            });
        }

        function deleteAction() {
            return confirmationModal.open().result.then(function () {
                return apiClient.delete(vm.documentSet.getLink('delete')).then(function (response) {
                    if (response) {
                        $state.go('dashboard.admin.document-types-management.document-sets', {}, { reload: 'dashboard.admin.document-types-management.document-sets' })
                            .then(function () {
                                messenger.success('DOCUMENT_SET_DELETED');
                            });
                    } else {
                        messenger.error('REQUEST_ERROR');
                    }
                });
            });
        }
    }
})();
