(function() {
    'use strict';

    angular
        .module('elogbooks.admin.settings')
        .controller('AdminSettingsDisplayReviewExpiryController', AdminSettingsDisplayReviewExpiryController);

    function AdminSettingsDisplayReviewExpiryController($state, apiClient, messenger, confirmationModal, config, settingsResponse) {
        var vm = this;

        vm.settings = settingsResponse.contractSettings;
        vm.settingsResponse = settingsResponse;
        vm.delete = deleteAction;
        vm.entity = config.entity;
        vm.reorderListeners = {
            accept: function(sourceItemHandleScope, destSortableScope) {
                return (sourceItemHandleScope.itemScope.sortableScope.$id === destSortableScope.$id);
            },
            orderChanged: function(event) {
                apiClient.update(event.dest.sortableScope.modelValue[event.dest.index].getLink('reorder'), {sequence: event.dest.index}).then(function() {
                    messenger.success(vm.entity + '_SETTINGS_REORDERED');
                });
            }
        };

        function deleteAction(chase) {
            return confirmationModal.open({primaryClass: 'btn-success'}).result.then(
                function() {
                    return apiClient.delete(chase._links.delete.href).then(function(response) {
                        if (response) {
                            return $state.go('.', {}, {'reload': $state.get('^.^')}).then(function () {
                                messenger.success(vm.entity + '_SETTING_REMOVED');
                            });
                        } else {
                            messenger.error('REQUEST_ERROR');
                        }
                    });
                }
            );
        }
    }
})();
