(function() {
    'use strict';

    angular
        .module('elogbooks')
        .config(Translations);

    function Translations($translateProvider) {
        $translateProvider.translations('zh-hk', {
            ACTION_WORKFLOW_CANCEL: '取消',
            ACTION_WORKFLOW_COMPLETE: '完成',
            ACTION_WORKFLOW_REOPEN: '重開',
            ACTION_WORKFLOW_REASSIGN: '重新分配',

            ACTION_WORKFLOW_CANCEL_SUBMIT: '取消',
            ACTION_WORKFLOW_COMPLETE_SUBMIT: '完成',
            ACTION_WORKFLOW_REOPEN_SUBMIT: '重開',
            ACTION_WORKFLOW_REASSIGN_SUBMIT: '重新分配',

            ACTION_WORKFLOW_CANCEL_SUCCESS: '指令已取消',
            ACTION_WORKFLOW_COMPLETE_SUCCESS: '指令已完成',
            ACTION_WORKFLOW_REOPEN_SUCCESS: '指令已重開',
            ACTION_WORKFLOW_REASSIGN_SUCCESS: '指令已重新分配',
        });
    }
})();
