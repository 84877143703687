(function () {
    "use strict";

    angular.module("elogbooks").config(Translations);

    function Translations($translateProvider) {
        $translateProvider.translations("en-gb", {
            CLIENT_ADMIN: "Client Admin",
            CLIENT_ADMIN_SETTINGS: "Client Admin",
            CLIENT_ADMIN_DOMAINS: "Domains: ",
            CLIENT_ADMIN_SETTINGS_VIEW: "Client Admin Settings",
            CLIENT_ADMIN_DOMAIN_SUB_LABEL:
                "Domains needs to be separated by commas: elogs.co.uk, gmail.com...",
        });
    }
})();
