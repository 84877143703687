/**
 * Departments directive
 */
angular.module('elogbooksDirectives').directive('survey', ['apiClient', function (apiClient) {
    return {
        require: ['ngModel'],
        scope: {
            survey: '=ngModel',
            required: '=ngRequired'
        },
        templateUrl: '/modules/directives/form-elements/survey/survey.html',
        link: function(scope){
            scope.options = [];
            apiClient.get('/surveys').then(function(response) {
                scope.options = response.getData().surveys;
            });
        }
    }
}]);
