(function () {
    'use strict';

    angular
        .module('elogbooks.user.reports')
        .controller('UserReportsSiteAssociateOverdueAttendancePlannedController', UserReportsSiteAssociateOverdueAttendancePlannedController);

    UserReportsSiteAssociateOverdueAttendancePlannedController.$inject = ['jobsCollection', 'siteAssociateResponse'];

    function UserReportsSiteAssociateOverdueAttendancePlannedController(jobsCollection, siteAssociateResponse) {
        var vm = this;
        vm.jobsCollection = jobsCollection;
        vm.associate = siteAssociateResponse;
    }
})();