angular.module('elogbooksDirectives').directive('jobModal', ['$state', '$uibModal', 'messenger', 'jobModalService', '$rootScope',
    function ($state, $uibModal, messenger, jobModalService, $rootScope) {
        return {
            restrict: 'AE',
            scope: {
                model: '=ngModel',
                context: '@',
                question: '=ngQuestion',
                section: '=ngSection',
                btnClass: '@',
                minified: '@'
            },
            templateUrl: '/modules/directives/job-modal/job-add-modal-button.html',
            controller: function ($scope) {
                $scope.model.loading = false;
                $scope.model.raiseJob = raiseJob;

                function raiseJob() {
                    $scope.model.loading = true;
                    jobModalService.raiseJob($scope, null, true);
                    $rootScope.$on('JobModalLoaded', function () {
                        $scope.model.loading = false;
                    });
                }
            }
        };
    }]
);
