(function () {
    'use strict';

    angular
        .module('elogbooksDirectives')
        .directive('elbFrequency', ElbFrequency);

    ElbFrequency.$inject = [];

    function ElbFrequency () {
        return {
            restrict: 'AE',
            scope: {
                ngModel: '='
            },
            templateUrl: '/modules/directives/elb-frequency/elb-frequency.html'
        }
    }
})();
