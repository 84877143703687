(function() {
    'use strict';

    angular
        .module('elogbooks')
        .config(Translations);

    function Translations($translateProvider) {
        $translateProvider.translations('en-gb', {
            P2PREFERENCE: 'P2P Reference',
            P2P:'P2P',
            P2P_CIS:'CIS',
            P2P_TAX_CODE:'Tax Code',
            P2P_COMMENT: 'Comment',
            P2P_DESCRIPTION: 'Description',
            P2P_TRANSACTION: 'Transaction Type',
            P2P_SUPPLIER: 'Supplier',
            P2P_SCHEDULE_NUMBER: 'Schedule Number',
            P2P_NOMINAL_LINES: 'Nominal lines',
            P2P_EXPENSE_CODE: 'Expense Code',
            P2P_TENANT_CODE: 'Tenant Code',
            P2P_RATIO: 'Ratio (% of costs)',
            P2P_PO_SUPPLIER: 'P2P Supplier',
            CANNOT_VALIDATE_ROLE:'Cannot validate a required role',
            P2P_VALUE:'Net Value',
            ADD_P2P_VALUE:'Add P2P Value',
            ADD_PO_NUMBER: 'Add PO Number',
            JOB_VALUE_UPDATED: 'Job value updated',
            JOB_PO_UPDATED: 'Job PO updated',

            ADD_LINE: 'Add line',
            REMOVE_LINE: 'Remove line',

            P2P_NOT_VALID_SITE: 'The site associated to this job does not have a valid P2P reference',
            P2P_NOT_VALID_USER: 'Your user does not have a valid P2P Email address',

            P2P_ERROR_QUOTE_PENDING: 'P2P Quote pending',

            P2P_ELEMENT3_SAVILLS: 'Cash Type',
            P2P_ELEMENT4_SAVILLS: 'Schedule',
            P2P_ELEMENT5_SAVILLS: 'GL Code',
            P2P_ELEMENT6_SAVILLS: 'Lease',
            P2P_ELEMENT7_SAVILLS: 'Unit',

            P2P_ELEMENT3_KF: 'Recoverability',
            P2P_ELEMENT4_KF: 'Schedule',
            P2P_ELEMENT5_KF: 'GL Code',
            P2P_ELEMENT6_KF: 'Unit',
            P2P_ELEMENT7_KF: 'Tenant',

            P2P_ELEMENT3_FSHC: 'Type',
            P2P_ELEMENT4_FSHC: 'GL Code',
            P2P_ELEMENT5_FSHC: 'Asset Category',
            P2P_ELEMENT6_FSHC: 'Asset Code',

            P2P_ELEMENT3_CEG: 'Type',
            P2P_ELEMENT4_CEG: 'Unit',
            P2P_ELEMENT5_CEG: 'Fund',
            P2P_ELEMENT6_CEG: 'Fund Heading',
            P2P_ELEMENT7_CEG: 'Tenant',

            P2P_ELEMENT3_COLLIERS: 'Transaction Type',
            P2P_ELEMENT4_COLLIERS: 'Schedule',
            P2P_ELEMENT5_COLLIERS: 'GL Code',
            P2P_ELEMENT6_COLLIERS: 'Unit/DrRef',

            P2P_HEXAGON_NOMINAL_LINES_LIST: 'Nominal Lines',
            P2P_HEXAGON_NOMINAL_ANALYSIS_CATEGORY: 'Nominal Category',
            P2P_HEXAGON_NOMINAL_ANALYSIS_FUND: 'Service Charge Fund',
            P2P_HEXAGON_NOMINAL_ANALYSIS_PERIOD: 'Service Charge Period',
            P2P_HEXAGON_NOMINAL_ANALYSIS_SCHEDULE: 'Schedule',
            P2P_HEXAGON_NOMINAL_ANALYSIS_EXPENSE_TYPE: 'Expense Type',
            P2P_HEXAGON_NOMINAL_ANALYSIS_LINE_VALUE: 'Line Value',
            P2P_HEXAGON_NOMINAL_ANALYSIS_PROCESSED: 'Processed',
            P2P_HEXAGON_NOMINAL_ANALYSIS_PROCESSED_YES: 'Yes',
            P2P_HEXAGON_NOMINAL_ANALYSIS_PROCESSED_NO: 'No',
            P2P_HEXAGON_NOMINAL_ANALYSIS_PROCESSED_ERROR: 'Error',
            P2P_HEXAGON_NOMINAL_ANALYSIS_PROCESSING_ERR: 'Something went wrong, please contact Hexagon'
        });
    }
})();
