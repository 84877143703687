(function () {
    'use strict';

    angular
        .module('elogbooks.common.files')
        .controller('CommonFilesAddEditController', CommonFilesAddEditController);

    CommonFilesAddEditController.$inject = [
        'apiClient',
        '$state',
        'messenger',
        'fileResponse',
        'requestDataFactory',
        'elbVisibilityService',
        'elbSettings',
        '$scope',
        'lodash',
        'tagCollectionResponse',
        'selectedSiteResponse',
        'userManager',
        'config',
        'confirmationModal',
        '$translate',
        'integrationDocTypesResponse',
        'modulesService',
        'documentTypeCollectionResponse',
        'fileDocumentTypeService',
        'dateTimeService',
        'moment',
        'integrationsService'
    ];

    function CommonFilesAddEditController (
           apiClient,
           $state,
           messenger,
           fileResponse,
           requestDataFactory,
           elbVisibilityService,
           elbSettings,
           $scope,
           lodash,
           tagCollectionResponse,
           selectedSiteResponse,
           userManager,
           config,
           confirmationModal,
           $translate,
           integrationDocTypesResponse,
           modulesService,
           documentTypeCollectionResponse,
           fileDocumentTypeService,
           dateTimeService,
           moment,
           integrationsService
    ) {
        var vm = this;
        vm.fileResponse = fileResponse;
        vm.tagsSelected = [];
        vm.data = requestDataFactory.createRequest(getDefaults());
        if (lodash.isEmpty(fileResponse)) {
            vm.data.visibility = null;
        }
        vm.visibilityService = elbVisibilityService;
        vm.visibilityServiceOptions = vm.visibilityService.getVisibilitiesAsObject();
        vm.uploading = false;
        vm.uploadedFile = null;
        vm.tags = tagCollectionResponse;
        vm.documentTypes = documentTypeCollectionResponse.documentTypes;
        vm.config = config;
        vm.integrations = integrationDocTypesResponse ? integrationDocTypesResponse.data : [];
        vm.hasIntegrations = false;
        vm.entityType = vm.fileResponse.entityType ? vm.fileResponse.entityType : null;
        vm.fileExpiryNotificationTime =  elbSettings.getSetting('file_expiry_notification_time').value;
        vm.advancedClosed = false;

        vm.documentDateMaxDate = moment().format('MM-DD-YYYY');
        vm.transformTag = transformTag;
        vm.changeStatutory = changeStatutory;
        vm.resetIntegrationDoc = resetIntegrationDoc;
        vm.displayStatutoryTypeAndCheckbox = displayStatutoryTypeAndCheckbox;
        vm.unsetStatutoryType = unsetStatutoryType;
        vm.searchTags = searchTags;
        vm.fileInputId = moment.utc().valueOf();
        var reloadRoute = config.reloadRoute || '^.^.^';
        vm.hasIntegrations = integrationsService.checkIntegrations(vm.integrations);

        vm.meridianEnabled = modulesService.isEnabled('meridian');

        var stateNameArray = $state.current.name.split('.');
        vm.addRoute = stateNameArray[stateNameArray.length-1] === 'add';

        if (config.associatedEntity) {
            vm.isStatutoryEntity = !!config.associatedEntity._links.statutorytype;
        }

        if (!lodash.isEmpty(fileResponse) &&
            fileResponse.getLink('documentType') &&
            fileResponse._links.documentType.frequency !== null
        ) {
            vm.data.disableExpiresAt = true;
        }

        if (!lodash.isEmpty(fileResponse.applicableTo)) {
            vm.applicableTo = fileResponse.applicableTo.length === 1 ? fileResponse.applicableTo[0] : 'both';
        } else {
            vm.applicableTo = null;
        }

        // on changing statutory option add/remove statutory user tag
        function changeStatutory(statutoryType, precedentStatutoryName) {
            var statutoryName = statutoryType ? statutoryType.title : null;

            if (precedentStatutoryName) {
                // visible selected tag dom elements
                lodash.remove(vm.tagsSelected, {name: precedentStatutoryName});

                // newly entered tags
                lodash.remove(vm.data.userTags, {name: precedentStatutoryName});

                // loaded tags
                lodash.remove(vm.data.tags.tags, {name: precedentStatutoryName});

                delete vm.data._links.statutoryType;
            }

            if (vm.data.isStatutory === true) {
                initializeIntegrations();
            }

            var tag = lodash.find(vm.tagsSelected, function (selectedTag) {
                return selectedTag.name === statutoryName;
            });

            if (tag === undefined && statutoryName) {
                var statutoryTag = transformTag(statutoryName);
                vm.tagsSelected.push(statutoryTag);
                vm.selectItem(statutoryTag);
            }

            statutoryType ? vm.data._links.statutoryType = statutoryType.href : false;
        }

        // files created from jobs or actions should have statutory specified
        if (lodash.has(vm.fileResponse, '.tags.tags')) {
            vm.fileTags = vm.fileResponse.tags.tags;

            for (var i = 0, len = vm.fileTags.length; i < len; i++) {
                if (
                    lodash.has(vm.fileTags[i], 'entityType')
                    &&
                    (
                        vm.fileTags[i].entityType === 'ReactiveJob'
                        || vm.fileTags[i].entityType === 'PlannedJob'
                        || vm.fileTags[i].entityType === 'Action'
                    )
                ) {
                    break;
                }
            }
        }

        vm.selectedSiteResponse = (config.entitySite ? config.entitySite : (selectedSiteResponse ? selectedSiteResponse : null));

        vm.siteSelect = {
            link : userManager.user.getLink('sites'),
            responseKeyPath: 'sites',
            required: true,
            linkParameters: { 'can-edit-files': true, 'datagroup': 'search' }
        };

        vm.selectedStatutoryTypeModel = {
            rootName: 'statutorytype',
            required: true,
            responseKeyPath: 'statutorytypes',
            loadOnOpen: true,
            selected: fileResponse._links && fileResponse._links.statutoryType ? {
                title: fileResponse._links.statutoryType.title,
                value: fileResponse._links.statutoryType.title,
                href: fileResponse._links.statutoryType.href
            } : null,
            openLink: config.statutoryTypesLink,
            onSelect: selectStatutoryType,
            onRemove: deselectStatutoryType
        };

        vm.jobDocTypes = null;

        if (documentTypeCollectionResponse.count > 0) {
            // fixes loadmore select widget overwriting the response which break the filter and the select when revisited after changing view
            if (vm.documentTypesCollection !== documentTypeCollectionResponse) {
                vm.documentTypesCollection = lodash.cloneDeep(documentTypeCollectionResponse);
            }
            var jobId = config.associatedEntity && ['planned', 'reactive'].includes(config.associatedEntity.type) ? config.associatedEntity.id : null;
            vm.documentTypeModel = {
                response: vm.documentTypesCollection,
                link: vm.documentTypesCollection ? vm.documentTypesCollection.getLink('self') : null,
                linkParameters: { status: 'active', order: 'name', job: jobId },
                responseKeyPath: 'documentTypes',
                selected: fileResponse._links && fileResponse._links.documentType ? {
                    title: fileResponse._links.documentType.title,
                    value: fileResponse._links.documentType.title,
                    href: fileResponse._links.documentType.href,
                    id: fileResponse._links.documentType.id,
                    object: {
                        frequency: fileResponse._links.documentType.frequency,
                        interval: fileResponse._links.documentType.interval
                    }
                } : null,
                onSelect: calculateExpiresAt,
                onRemove: calculateExpiresAt
            };

            if (config.associatedEntity
                    && config.associatedEntity.type
                    && config.associatedEntity.requiredDocumentTypes
                    && ['planned', 'reactive'].includes(config.associatedEntity.type) && config.associatedEntity.requiredDocumentTypes.documentTypes.length
            ){
                vm.jobDocTypes = config.associatedEntity.requiredDocumentTypes.documentTypes.map(function(element) {
                    return element.name;
                });
            }
        }

        vm.create = createAction;
        vm.update = updateAction;
        vm.delete = deleteAction;
        vm.selectItem = selectItem;
        vm.removeItem = removeItem;
        vm.checkIfNew = checkIfNew;

        vm.yesNo = [
            {
                value: null,
                label: $translate.instant('NONE_SELECTED')
            },
            {
                value: false,
                label: $translate.instant('NO')
            },
            {
                value: true,
                label: $translate.instant('YES')
            }
        ];

        vm.applicableToOptions = [
            {
                value: null,
                label: $translate.instant('NO')
            },
            {
                value: 'reactive',
                label: $translate.instant('REACTIVE')
            },
            {
                value: 'planned',
                label: $translate.instant('PLANNED')
            },
            {
                value: 'both',
                label: $translate.instant('BOTH')
            }
        ];

        function createAction() {
            delete vm.data._links.site;
            delete vm.data.disableExpiresAt;

            var createLink = null;

            if (config.fileCreateLink) {
                createLink = config.fileCreateLink;
            } else {
                createLink = vm.siteSelect.selected ? vm.siteSelect.selected.object.getLink('files') : vm.selectedSiteResponse.getLink('files');
            }

            if (vm.data.isStatutory === null) {
                vm.data.isStatutory = false;
            }

            if (vm.config.statutoryTypeAndTagsEnabled && vm.data.isStatutory && vm.selectedStatutoryTypeModel.selected !== null) {
                vm.data._links.statutoryType = { href: vm.selectedStatutoryTypeModel.selected.href };
                var tag = transformTag(vm.selectedStatutoryTypeModel.selected.value);
                vm.data.userTags.push(tag);
            }

            if (vm.documentTypeModel && vm.documentTypeModel.selected != null) {
                vm.data._links.documentType = { href: vm.documentTypeModel.selected.href };
            }

            if (!userManager.user.getLinkAttribute('files', 'canCreateJobFiles') || vm.applicableTo === null) {
                vm.data.isJobFile = false;
            }

            vm.data.applicableTo = transformApplicableTo();

            if (vm.data.expiresAt) {
                vm.data.expiresAt = dateTimeService.setEndOfDay(vm.data.expiresAt);
            }

            return apiClient.create(createLink, vm.data).then(function (response) {
                if (response) {
                    $state.go('^', {}, { reload: $state.get(reloadRoute) }).then(function () {
                        messenger.success('FILE_CREATED');
                    });
                } else {
                    messenger.error('REQUEST_ERROR');
                }
            });
        }

        function updateAction() {
            delete vm.data._links;
            delete vm.data.disableExpiresAt;
            vm.data._links = {};

            switch (true) {
                case config.associatedEntity && config.associatedEntity.type == 'reactive':
                    vm.data._links.job = {href: config.associatedEntity.getLink('self')};
                    break;
                case config.type === 'quote':
                    vm.data._links.quote = { href: config.associatedEntity.getLink('self') };
                    break;
                case config.type === 'quote-option':
                    vm.data._links.quoteOption = { href: config.associatedEntity.getLink('self') }
                    break;
            }

            if (vm.data.isStatutory === null) {
                vm.data.isStatutory = false;
            }

            if (vm.config.statutoryTypeAndTagsEnabled && vm.data.isStatutory && ($state.is('dashboard.user.files.list.details.edit') || $state.is('dashboard.user.sites.list.details.files.list.details.edit'))) {
                if (vm.fileResponse._links.statutoryType) {
                    var statutoryName = vm.fileResponse._links.statutoryType.title;
                    lodash.remove(vm.data.tags.tags, {name: statutoryName});
                }

                if (vm.selectedStatutoryTypeModel.selected !== null) {
                    vm.data._links.statutoryType = { href: vm.selectedStatutoryTypeModel.selected.href };
                    var tag = transformTag(vm.selectedStatutoryTypeModel.selected.value);
                    vm.data.userTags.push(tag);
                }
            }

            if (vm.documentTypeModel && vm.documentTypeModel.selected != null) {
                vm.data._links.documentType = { href: vm.documentTypeModel.selected.href };
            }

            vm.data.applicableTo = transformApplicableTo();

            if (vm.applicableTo === null) {
                vm.data.isJobFile = false;
            }

            if (vm.data.expiresAt) {
                vm.data.expiresAt = dateTimeService.setEndOfDay(vm.data.expiresAt);
            }

            // Checks if doc types are changing. If so, a confirmation modal is triggered.
            if (vm.documentTypeModel) {
                var currentlySelectedID = vm.documentTypeModel.selected ? vm.documentTypeModel.selected.id : null;
                var originalDocTypeID = vm.fileResponse._links.documentType ? vm.fileResponse._links.documentType.id : null;

                if (originalDocTypeID !== currentlySelectedID) {
                    var modalConfig = {
                        bodyMessage: null,
                        htmlBodyMessage: $translate.instant('JOB_WORKFLOW_EDIT_JOB_DOCS_CONFIRMATION')
                    }

                    return confirmationModal.open(modalConfig).result.then(function() {
                        return submitUpdateAction();
                    });
                }
            }

            return submitUpdateAction();
        }

        function submitUpdateAction() {
            return apiClient.update(vm.fileResponse.getLink('edit'), vm.data).then(function (response) {
                if (response) {
                    $state.go('^', {}, { reload: $state.get(reloadRoute) }).then(function () {
                        messenger.success('FILE_UPDATED');
                    });
                } else {
                    messenger.error('REQUEST_ERROR');
                }
            });
        }

        function deleteAction() {
            var modalConfig = {htmlBodyMessage: null, bodyMessage: 'ARE_YOU_SURE'};

            if (stateNameArray.includes('actions') && vm.fileResponse.jobId) {
                if (vm.fileResponse.actionSystemType && vm.fileResponse.actionSystemType === 2) { // paperwork action
                    modalConfig = {
                        titleMessage: 'UNABLE_TO_DELETE_ACTION_FILE_HEADER',
                        bodyMessage: '',
                        htmlBodyMessage: '<p class="alert alert-warning"><i class="fa fa-exclamation-triangle"></i>&nbsp;' + $translate.instant('UNABLE_TO_DELETE_ACTION_FILE', {
                            jobId: vm.fileResponse.jobId,
                            jobSummary: vm.fileResponse.jobSummary,
                        }) + '</p>',
                        primaryText: '',
                        secondaryText: 'OK'
                    }
                } else {
                    modalConfig = {
                        bodyMessage: '',
                        htmlBodyMessage: '<p class="alert alert-warning"><i class="fa fa-exclamation-triangle"></i>&nbsp;' + $translate.instant('DELETE_JOB_FILE', {
                            jobId: vm.fileResponse.jobId,
                            jobSummary: vm.fileResponse.jobSummary,
                        }) + '</p>'
                    }
                }
            } else if (stateNameArray.includes('jobs') && vm.fileResponse.actionId || (stateNameArray.includes('manage-files') && vm.fileResponse.actionId) ) {
                var htmlBodyTranslation = vm.fileResponse.actionSystemType === 2
                ? $translate.instant('DELETE_ACTION_FILE_CANCEL_JOB', {actionId: vm.fileResponse.actionId, actionSummary: vm.fileResponse.actionSummary})
                : $translate.instant('DELETE_ACTION_FILE_NO_CANCEL_JOB', {actionId: vm.fileResponse.actionId, actionSummary: vm.fileResponse.actionSummary});

                modalConfig = {
                    bodyMessage: '',
                    htmlBodyMessage: '<p class="alert alert-warning"><i class="fa fa-exclamation-triangle"></i>&nbsp;' + htmlBodyTranslation + '</p>'
                }
            }

            return confirmationModal.open(modalConfig).result.then(function (response) {
                if (response.result) {
                     apiClient.delete(vm.fileResponse.getLink('delete')).then(function (isSuccess) {
                        if (isSuccess) {
                            $state.go('^.^', {}, {reload: $state.get(reloadRoute)}).then(function(){
                                messenger.success('FILE_DELETED');
                            });
                        } else {
                            messenger.error('REQUEST_ERROR');
                        }
                    });
                }

                return;
            });
        }

        function getDefaults() {
            var documentDate = vm.fileResponse.documentDate ? new Date(vm.fileResponse.documentDate) :
                (vm.fileResponse.id ? null : new Date());

            if (documentDate) {
                documentDate.setHours(0, 0, 0, 0);
            }

            return {
                _links: {
                    site: lodash.has(vm.fileResponse, '_links.site') ? vm.fileResponse._links.site : null,
                    file: lodash.has(vm.fileResponse, '_links.file') ? vm.fileResponse._links.file : null,
                },
                tags: {
                    tags: getTags('links'),
                },
                id: vm.fileResponse.id || null,
                title: vm.fileResponse.title || null,
                visibility: vm.fileResponse.visibility || null,
                isContract: vm.fileResponse.isContract || null,
                documentDate: documentDate,
                expiresAt: vm.fileResponse.expiresAt ? new Date(vm.fileResponse.expiresAt) : null,
                isStatutory: lodash.has(vm.fileResponse, 'isStatutory') ? vm.fileResponse.isStatutory : null,
                userTags: [],
                notifyExpiry: vm.fileResponse.notifyExpiry || false,
                isJobFile: config.isJobFile || false,
                description: vm.fileResponse.description || null,
                disableExpiresAt: false
            };
        }

        function getTags(type) {
            var tagsSelected = [];

            if (vm.fileResponse.tags) {
                angular.forEach(vm.fileResponse.tags.tags, function (value, index) {
                    if (value.type !== 'inherited') {
                        vm.tagsSelected.push(value);

                        if (value._links) {
                            tagsSelected.push({href: value._links.self.href, name: value.name});
                        }
                    }
                });
            }

            return tagsSelected;
        }

        $scope.$watch('vm.uploadedFile', function (newValue) {
            vm.data._links.file = null;
            if (newValue) {
                if (!vm.data.title) {
                    vm.data.title = newValue.filename
                }
                vm.data._links.file = vm.uploadedFile._links.file;
            }
        });

        $scope.$watch('vm.documentTypeModel.selected', function (newValue, oldValue) {
            var precedentStatutoryName = null;

            if (oldValue && oldValue.object._links && oldValue.object._links.statutorytype) {
                precedentStatutoryName = oldValue.object._links.statutorytype.title;
            }

            if (newValue && newValue.object._links && newValue.object._links.statutorytype) {
                vm.data.isStatutory = true;
                changeStatutory(newValue.object._links.statutorytype, precedentStatutoryName);
            } else if (
                newValue === undefined ||
                (newValue && newValue.object._links && !newValue.object._links.statutorytype)
            ) {
                vm.data.isStatutory = false;
                changeStatutory(null, precedentStatutoryName);
            }
        });

        $scope.$watch('vm.data.documentDate', function (newValue, oldValue) {
            var maxDate = new Date();
            if (newValue && (newValue > maxDate)) {
                vm.data.documentDate = maxDate;
            }

            if (newValue !== oldValue){
                calculateExpiresAt();
            }
        });

        // Reset the tags selection list back to the original list
        $scope.$watch('vm.tagsSelected', function (newVal, oldVal) {
            if (newVal.length !== oldVal.length) {
                vm.tags = tagCollectionResponse;
            }
        });

        function transformTag (tag) {
            var item = {
                type: 'user',
                name: tag
            };

            return item;
        }

        function selectItem (item) {
            if (item._links) {
                lodash.remove(vm.tags.tags, {name: item.name});
                lodash.remove(vm.data.tags.tags, {href: item._links.self.href});
                vm.data.tags.tags.push({href: item._links.self.href});
            } else {
                //remove if tag is selected again
                if ((lodash.filter(vm.tagsSelected, {name: item.name})).length > 1) {
                    if (lodash.find(vm.tagsSelected, {name: item.name})) {
                        lodash.remove(vm.tagsSelected, {name: item.name});
                        vm.tagsSelected.push(item);
                    }
                }

                lodash.remove(vm.data.userTags, {name: item.name});
                vm.data.userTags.push({name: item.name});
            }
        }

        function removeItem (item) {
            if (item._links) {
                vm.tags.tags.push(item);
                lodash.remove(vm.data.tags.tags, {href: item._links.self.href});
            } else {
                lodash.remove(vm.tags.tags, {name: item.name});
                lodash.remove(vm.data.userTags, {name: item.name});
            }
        }

        function checkIfNew($tag) {
            if (!lodash.find(vm.tags.tags, {name: $tag}) &&
                !lodash.find(vm.tagsSelected, {name: $tag})) {
                return $tag + $translate.instant('NEW_TAG');
            }
        }

        function resetIntegrationDoc($integration, $index) {
            if (vm.data.integration !== undefined) {
                vm.data.integration[$integration] = null;
                vm.integrationDocCat[$index] = null;
            }
        }

        // Display statutory type check box and dropdown only when adding a new file or editing existing file where entity type is site
        // Hide when in nav->file->job files
        function displayStatutoryTypeAndCheckbox() {
            if (!vm.showStatutoryType) {
                return false;
            }

            return vm.config.statutoryTypeAndTagsEnabled && (vm.entityType === 'Site' || typeof vm.fileResponse.id === 'undefined');
        }

        function selectStatutoryType(item) {
            lodash.remove(vm.tagsSelected , { name: item.selected.value });
            lodash.remove(vm.tagsSelected , { stat: true });
            if (item.previouslySelected) {
                lodash.remove(vm.tagsSelected, { name: item.previouslySelected.value });
            }

            var transformedTag = transformTag(item.selected.value);
            transformedTag.stat = true;
            vm.tagsSelected.push(transformedTag);
        }

        function deselectStatutoryType(item) {
            if (item.removedItem) {
                lodash.remove(vm.tagsSelected , { name: item.removedItem.value });
            }
        }

        function calculateExpiresAt() {
            fileDocumentTypeService.calculateExpiresAt(vm.documentTypeModel, vm.data);
        }

        function unsetStatutoryType() {
            if (vm.selectedStatutoryTypeModel.selected) {
                lodash.remove(vm.tagsSelected , { name: vm.selectedStatutoryTypeModel.selected.value });
                lodash.remove(vm.data.tags.tags, { name: vm.selectedStatutoryTypeModel.selected.value });
            }

            vm.userTags = [];
            vm.selectedStatutoryTypeModel.selected = null;
        }

        function initializeIntegrations() {
            var docsLink = null;

            if (vm.config.hasOwnProperty('entitySite')) {
                var docsLink = lodash.get(vm.config.entitySite, '_links["integration-doc-types"].href', false);
            } else if (vm.config.hasOwnProperty('associatedEntity')) {
                var docsLink = lodash.get(vm.config.associatedEntity, '_links["integration-doc-types"].href', false);
            }

            if (vm.integrations.length === 0 && docsLink) {
                return apiClient.get(docsLink).then(function(integrationDocTypesResponse) {
                    vm.integrations = integrationDocTypesResponse ? integrationDocTypesResponse.data : [];
                    vm.hasIntegrations = integrationsService.checkIntegrations(vm.integrations);
                });
            }
        }

        function searchTags(keyword) {
            vm.loading = true;
            if (keyword.length >= 1 && tagCollectionResponse.getLink('self')) {
                apiClient.get(tagCollectionResponse.getLink('self'), {name: keyword})
                    .then(function (response) {
                        vm.loading = false;
                        if (response.tags.length !== 0 ){
                            vm.tags = response;
                        }
                    });
            } else {
                vm.loading = false;
            }
        }

        function transformApplicableTo() {
            var applicableTo = [];
            if (!lodash.isEmpty(vm.applicableTo)) {
                if (vm.applicableTo === 'both') {
                    applicableTo.push('reactive', 'planned');
                } else {
                    applicableTo.push(vm.applicableTo);
                }
            }

            return applicableTo;
        }
    }
})();
