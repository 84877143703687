app.config(function ($translateProvider) {
    $translateProvider.translations('nl', {

        ADD_NEW_JOB_REQUISITES: 'Voeg een nieuwe opdrachtvereiste toe',
        ADD_PARTNERSHIP: 'Voeg relatie toe',
        ALLOW_NOT_APPLICABLE: 'Toegestaan N/A',
        ANSWERED_NA: 'Beantwoord \'N/A\'',
        ANSWERED_YES: 'Beantwoord \'Yes\'',
        ATTACHED_FILES: 'Bijlagen',
        DAILOG_PARTNERSHIP_EDIT: 'Wijzig relaties',
        DELETE_PARTNERSHIP: 'Verwijder relaties',
        DELETE_SELECTED: 'Verwijder geselecteerd',
        DISALLOW_NOT_APPLICABLE: 'Niet toegestaan N/A',
        EDIT_JOB_REQUISITE: 'Wijzig taak vereisten',
        HASNOTAPPLICABLEOPTION: 'Sta N/A toe',
        HELPMESSAGE: 'Help Tekst',
        JOB_REQUISITE: 'Opdrachtvereisten',
        JOB_REQUISITE_ADD: 'Voeg een nieuwe opdrachtvereiste toe',
        JOB_REQUISITE_CREATED: 'Opdrachtvereiste aangemaakt',
        JOB_REQUISITE_DELETED: 'Opdrachtvereiste verwijderd',
        JOB_REQUISITE_EDIT: 'Opdrachtvereiste aanpassen',
        JOB_REQUISITE_LIST: 'Opdrachtvereisten',
        JOB_REQUISITE_PARTNERSHIP_ADDED: 'Opdrachtvereiste samenwerking toegevoegd',
        JOB_REQUISITE_PARTNERSHIP_EDIT: 'Opdrachtvereiste samenwerking wijzigen',
        JOB_REQUISITE_PARTNERSHIP_LIST: 'Samenwerkingen',
        JOB_REQUISITE_SERVICE_PROVIDER_LIST: 'Leveranciers',
        JOB_REQUISITE_SERVICEPROVIDER_ADDED: 'Opdrachtvereisten leverancier toegevoegd',
        JOB_REQUISITE_SERVICEPROVIDER_EDIT: 'Wijzig opdrachtvereisten leverancier',
        JOB_REQUISITE_SITE_ADDED: 'Opdrachtvereisten object toegevoegd',
        JOB_REQUISITE_SITE_EDIT: 'Wijzig Opdrachtvereisten object',
        JOB_REQUISITE_SITE_LIST: 'Objecten',
        JOB_REQUISITE_UPDATED: 'Opdrachtvereisten aangepast',
        JOB_REQUISITES: 'Opdrachtvereisten',
        JOB_REQUISITEUPDATED: 'Opdrachtvereisten aangepast',
        NO_ATTACHMENT: 'Geen bijlage',
        PARTNERSHIPS_DELETED_FROM_JOB_REQUISITE: 'Samenwerking opdrachtvereisten verwijderd',
        REQUEST_ATTACHMENT: 'Verzoek bijlage',
        REQUESTATTACHMENT: 'Verzoek bijlage',
        SERVICEPROVIDERS_DELETED_FROM_JOB_REQUISITE: 'Verwijderen opdrachtvereisten leveranciers',
        SITES_DELETED_FROM_JOB_REQUISITE: 'Verwijderen opdrachtvereisten object'

    });
});
