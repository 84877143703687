app.config(function ($translateProvider) {
    $translateProvider.translations('zh-hk', {
        DUTYMANAGEMENT: '職務管理',
        DUTY_MANAGEMENT: '職務管理',
        DUTY_MANAGEMENT_SITE_RELATION_REMOVED: '地點聯繫已被刪除',
        DUTY_MANAGEMENT_SCHEDULE_REMOVED: '時間表已被刪除',
        DUTY_MANAGEMENT_BACK: '返回',
        DUTY_MANAGEMENT_EDIT_USER: '修改用戶',
        DUTY_MANAGEMENT_SELECT_USER: '選擇用戶',
        DUTY_MANAGEMENT_SELECT_SITES: '選擇地點',
        DUTY_MANAGEMENT_SELECT_DAYS: '選擇日數',
        DUTY_MANAGEMENT_SELECTED_DAYS: '已選日數',
        DUTY_MANAGEMENT_SELECTED_SITES: '已選地點',
        DUTY_MANAGEMENT_ADD: '建立職務管理表',
        DUTY_MANAGEMENT_ALREADY_IN_USE_SITES: '地點已被使用'
    })
});
