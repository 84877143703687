app.config(function ($translateProvider) {
    $translateProvider.translations('zh-hk', {
        MASTERASSET: '資產總結',
        MASTER_TASK: '主任務',
        MASTER_TASKS: '主任務',
        MASTER_TASK_LIST: '主任務',

        MASTER_TASK_CREATED: '主任務已建立',
        MASTER_TASK_UPDATED: '主任務已更新',
        MASTER_TASK_DELETED: '主任務已刪除',

        MASTER_TASK_ADD: '新增主任務',
        MASTER_TASK_EDIT: '修改主任務',

        MASTER_TASK_IS_MANDATORY: '是強制性?',
        MASTER_TASK_SERVICE_TYPE: '服務類別',
        MASTER_TASK_ASSET_NAME: '資產名稱'
    });
});
