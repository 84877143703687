(function () {
    'use strict';

    angular
        .module('elogbooksDirectives')
        .directive('elbJobWorkflowButtons', ElogbooksJobWorkflowButtons);

    ElogbooksJobWorkflowButtons.$inject = [];

    function ElogbooksJobWorkflowButtons () {
        return {
            restrict: 'AE',
            scope: true,
            templateUrl: '/modules/directives/elb-job-workflow-buttons/job-workflow-buttons.html'
        };
    }
})();
