(function() {
    'use strict';

    angular
        .module('elogbooks')
        .config(Translations);

    function Translations($translateProvider) {
        $translateProvider.translations('nl', {
            JOB_ESCALATED_ACTION: '{{ ::event._links["user"] ? ("<strong>" + event._links["user"].title + "</strong> heeft") : "Het systeem heeft automatisch" }} de taak geescaleerd.',
            JOB_ESCALATED_SYSTEM_ACTION: 'automatisch geescaleerd door het systeem.',
            JOB_DEESCALATED_ACTION: '<strong>{{ ::event._links["user"].title }}</strong> verwijderd uit escalatie.',
            JOB_DEESCALATED_SYSTEM_ACTION: 'Automatisch verwijderd uit escalatie.',

            REPORTER_NAME: 'Naam melder',
            REPORTER_EMAIL: 'Email melder',
            REPORTER_PHONE: 'Telefoonnummer melder',
            REPORTER_RELATIONSHIP: 'Relatie met klacht',
            REPORTER_NOTIFY_ON_CREATE: 'Melden bij aanmaken',
            REPORTER_NOTIFY_ON_COMPLETE: 'Melden bij afronden',

            CONTACT_NAME: 'Naam contact',
            CONTACT_EMAIL: 'Email contact',
            CONTACT_PHONE: 'Telefoonnummer contact',
            CONTACT_RELATIONSHIP: 'Relatie met klacht',
            CONTACT_NOTIFY_ON_CREATE: 'Melden bij aanmaken',
            CONTACT_NOTIFY_ON_COMPLETE: 'Melden bij afronden',
        });
    }
})();
