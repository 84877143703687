
app.config(function ($translateProvider) {
    $translateProvider.translations('nl', {
        /* dashboard.helpdesk.satisfactionsurveys */
        SATISFACTIONSURVEYS: 'Huurderstevredenheid',
        SATISFACTIONSURVEY: 'Tevredenheids onderzoek',
        SATISFACTIONSURVEY_JOBID: 'Opdracht ID',
        SATISFACTIONSURVEY_ISCOMPLETE: 'Afgerond?',
        SATISFACTIONSURVEY_REFUSED: 'Onderzoek geweigerd?',

        /* dashboard.helpdesk.satisfactionsurvey.edit */
        SATISFACTIONSURVEY_EDIT: 'Wijzig tevredenheidsonderzoek',
        SATISFACTIONSURVEY_CONTACT_NAME: 'Naam contactpersoon',
        SATISFACTIONSURVEY_CONTACT_PHONE: 'Telefoon contactpersoon',
        SATISFACTIONSURVEY_CONTACT_EMAIL: 'E-mail  contactpersoon',
        SATISFACTIONSURVEY_COMMENTS: 'Extra commentaar of suggesties',
        //SATISFACTIONSURVEY_REFUSED: 'Survey Refused?', // Listed above
        SATISFACTIONSURVEY_QUESTION: 'Vraag',
        SATISFACTIONSURVEY_RESPONSE: 'Antwoord',

        /* dashboard.admin.satisfactionsurveylines */
        SATISFACTIONSURVEY_QUESTIONS: 'Vragen tevredenheidsonderzoek',
        SATISFACTIONSURVEY_BUTTON_ADD_QUESTION: 'Vraag toevoegen',

        /* dashboard.helpdesk.satisfactionsurveylines.addedit */
        SATISFACTIONSURVEY_ADD_QUESTION: 'Tevredenheidsonderzoek - Vraag toevoegen',
        SATISFACTIONSURVEY_EDIT_QUESTION: 'Tevredenheidsonderzoek - Vraag wijzigen',

        SATISFACTIONSURVEY_FILTER_INCOMPLETEREQUIRESATTENTION: 'Niet compleet - Graag aanvullen',
        SATISFACTIONSURVEY_FILTER_INCOMPLETENOATTENTION: 'Niet compleet - Nog in afwachting',
        SATISFACTIONSURVEY_FILTER_COMPLETE: 'Compleet'
    });
});
