/**
 * Edit Contract Type controller
 */
(function () {
    'use strict';

    angular
        .module('elogbooks.admin.contract-types')
        .controller('ContractTypesEditController', ContractTypesEditController);

    ContractTypesEditController.$inject = ['$scope', 'apiClient', 'messenger', 'contractTypeResponse', 'lodash'];

    function ContractTypesEditController($scope, apiClient, messenger, contractTypeResponse, lodash) {

        var vm = this;
        vm.contracttype = contractTypeResponse.getData();

        vm.delete = function () {
            apiClient.delete(vm.contracttype._links.delete.href).then(function (isSuccess) {
                if (isSuccess) {
                    $scope.$state.go('dashboard.admin.contracttypes', {}, {reload: true}).then(function () {
                        messenger.success('Contract Type deleted successfully');
                    });
                } else {
                    messenger.error('Unable to delete');
                }
            });
        };

        vm.submit = function () {
            apiClient.update(vm.contracttype._links.self.href, lodash.cloneDeep(vm.contracttype)).then(function (success) {

                if (success) {
                    $scope.$state.go('^', {}, {reload: true}).then(function (){
                        messenger.success('Updated successfully');
                    });
                } else {
                    messenger.error('Unable to process your request. Please try again later');
                }
            });
        };

    };

})();
