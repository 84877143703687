(function () {
    'use strict';

    angular
        .module('elogbooks.user.manage-operatives')
        .controller('ManageOperativeActivityLogController', ManageOperativeActivityLogController);

    ManageOperativeActivityLogController.$inject = ['$stateParams', 'base64', 'apiClient', 'operativeActivityResponse', 'serviceProviderCollection', '$state', '$window', '$translate'];

    function ManageOperativeActivityLogController($stateParams, base64, apiClient, operativeActivityResponse, serviceProviderCollection, $state, $window, $translate) {
        var vm = this;
        vm.search = searchAction;
        vm.order = orderAction;
        vm.activityLog = operativeActivityResponse.events;
        vm.activityLogResponse = operativeActivityResponse;

        vm.serviceProviderSelect = {
            response : serviceProviderCollection,
            link : serviceProviderCollection ? serviceProviderCollection.getLink('self') : null,
            responseKeyPath: 'operatives',
            itemValuePath: 'id',
            itemTitlePath: '_links.serviceprovider.title'
        };

        vm.criteria = {
            dateRange: {
                type: 'date-range',
                title: 'BETWEEN_DATES',
                value: {
                    startDate: ($stateParams.dateRange !== null && typeof $stateParams.dateRange === 'string') ? moment(new Date($stateParams.dateRange.split(',')[0])) : null,
                    endDate: ($stateParams.dateRange !== null && typeof $stateParams.dateRange === 'string') ? moment(new Date($stateParams.dateRange.split(',')[1])) : null
                },
                clearValue: {
                    startDate: null,
                    endDate: null
                }
            },
            operative: { type: 'jsonselectwidget', title: 'SERVICEPROVIDER', value: JSON.parse($stateParams.operative), options: vm.serviceProviderSelect },
            activityType: {
                type: 'options',
                title: 'SOURCE_TYPE',
                value: $stateParams.activityType,
                options: [{
                    title: $translate.instant('NONE_SELECTED'),
                    value: null
                }, {
                    title: $translate.instant('JOB'),
                    value: 'job'
                }, {
                    title: $translate.instant('QUOTE'),
                    value: 'quote'
                }, {
                    title: $translate.instant('PATROL'),
                    value: 'patrol'
                }]
            },
            activityId: {
                type: 'number', title: 'SOURCE_ID', value: $stateParams.activityId,
            }
        };

        vm.loadingMore = false;
        vm.endOfResults = false;

        $window.onscroll = function(ev) {
            if (($window.innerHeight + $window.pageYOffset) >= document.body.scrollHeight
            && !vm.loadingMore
            && !vm.endOfResults) {
                vm.loadingMore = true;

                apiClient.get(vm.activityLogResponse.getLink('next')).then(function(response) {
                    if (response.events.length === 0) {
                        vm.endOfResults = true;
                    }

                    vm.activityLogResponse = response;
                    vm.activityLog = vm.activityLog.concat(response.events);
                    vm.loadingMore = false;
                });
            }
        }

        function searchAction(params) {
            var override = {
                page: 1
            };
            $state.go('.', angular.extend({}, params, override), { reload: $state.current });
        }

        function orderAction(key) {
            $stateParams.activityOrder = key;

            $state.go('.', angular.extend({}, $stateParams), { reload: $state.current });
        }
    }
})();
