(function() {
    'use strict';

    angular
        .module('elogbooks.common.assets')
        // .module('elogbooks.user.sites.assets')
        .controller('UserAssetsAddEditController', UserAssetsAddEditController);

    UserAssetsAddEditController.$inject = [
        'apiClient',
        'messenger',
        '$state',
        'userManager',
        'siteResponse',
        'assetResponse',
        'confirmationModal',
        'siteCollectionResponse',
        'jobGroupsCollectionResponse',
        'assetTypesCollectionResponse',
        'nonVersionedServiceRoutinesCollectionResponse',
        'versionedServiceRoutinesCollectionResponse',
        'statutoryTypesCollectionResponse',
        'requestDataFactory',
        'assetScoreTypesResponse',
        'canEditScoreTypes',
        'sectionResource',
        '$translate',
        'assetServiceRoutinesCollectionResponse',
        '$scope',
        'moment',
        'lodash',
        'serviceRoutineService'
    ];

    function UserAssetsAddEditController (apiClient,
        messenger,
        $state,
        userManager,
        siteResponse,
        assetResponse,
        confirmationModal,
        siteCollectionResponse,
        jobGroupsCollectionResponse,
        assetTypesCollectionResponse,
        nonVersionedServiceRoutinesCollectionResponse,
        versionedServiceRoutinesCollectionResponse,
        statutoryTypesCollectionResponse,
        requestDataFactory,
        assetScoreTypesResponse,
        canEditScoreTypes,
        sectionResource,
        $translate,
        assetServiceRoutinesCollectionResponse,
        $scope,
        moment,
        lodash,
        serviceRoutineService
    ) {
        var vm = this;
        vm.data = requestDataFactory.createRequest(assetResponse);
        vm.data.isStatutory = false;
        vm.userManager = userManager;
        vm.assetResponse = assetResponse;
        vm.siteResponse = siteResponse;
        vm.sites = siteResponse.id ? {} : siteCollectionResponse.sites;
        vm.hasAssetTypes = assetTypesCollectionResponse && assetTypesCollectionResponse.assettypes.length;
        vm.assetTypesSelect = {};
        vm.sectionResource = sectionResource;
        vm.assetScoreTypes = assetScoreTypesResponse ? assetScoreTypesResponse.scoreTypes : [];
        vm.selectedServiceRoutines = [];
        vm.items = [];
        vm.selectedModel = {};
        vm.data.serviceRoutineType = 'none';
        vm.selectedScoreTypes = [];
        //remove SRs that are already on the asset so that drop down doesn't show duplicates
        nonVersionedServiceRoutinesCollectionResponse.routines = lodash.differenceBy(nonVersionedServiceRoutinesCollectionResponse.routines, assetServiceRoutinesCollectionResponse.routines, 'id');
        versionedServiceRoutinesCollectionResponse.routines = lodash.differenceBy(versionedServiceRoutinesCollectionResponse.routines, assetServiceRoutinesCollectionResponse.routines, 'id');

        // preload asset score types list
        if (vm.assetResponse.assetScoreTypes) {
            angular.forEach(vm.assetResponse.assetScoreTypes, function(value) {
                value.disabled = !canEditScoreTypes;
                vm.selectedScoreTypes.push(value);
            });
        } else if(lodash.isEmpty(vm.assetResponse)) {
            angular.forEach(vm.assetScoreTypes, function(value) {
                value.disabled = !canEditScoreTypes;
                vm.selectedScoreTypes.push(value);
            });
        }

        vm.selectedAssetScoreTypeModel = {
            response: assetScoreTypesResponse,
            link: assetScoreTypesResponse.getLink('self'),
            required: false,
            responseKeyPath: 'scoreTypes',
            disabled: !canEditScoreTypes,
            onSelect: function () {changeItem('assetScoreType')},
            onRemove: function () {changeItem('assetScoreType')}
        };

        vm.status = [
            {value: true, title: $translate.instant('STATUS_ACTIVE')},
            {value: false, title: $translate.instant('STATUS_INACTIVE')}
        ];

        vm.removeItem = removeItem;

        // preload SR list with only active
        angular.forEach(assetServiceRoutinesCollectionResponse.routines, function(value) {
            if (value.active) {
                vm.selectedServiceRoutines.push(value);
            }
        });

        vm.selectedNonVersionedServiceRoutineModel = {
            response: nonVersionedServiceRoutinesCollectionResponse,
            link: nonVersionedServiceRoutinesCollectionResponse.getLink('self'),
            linkParams: {active: 1, isVersioned: 0},
            required: false,
            responseKeyPath: 'routines',
            searchKey: 'nameReference',
            formatValue: serviceRoutineService.formatServiceRoutineValue,
            show: nonVersionedServiceRoutinesCollectionResponse.routines.length === 0 ? false : true,
            onSelect: function () {changeItem('serviceRoutine')},
            onRemove: function () {changeItem('serviceRoutine')}
        };

        vm.selectedVersionedServiceRoutineModel = {
            response: versionedServiceRoutinesCollectionResponse,
            link: versionedServiceRoutinesCollectionResponse.getLink('self'),
            linkParams: {active: 1, isVersioned: 1},
            required: false,
            responseKeyPath: 'routines',
            searchKey: 'nameReference',
            formatValue: serviceRoutineService.formatServiceRoutineValue,
            show: versionedServiceRoutinesCollectionResponse.routines.length === 0 ? false : true,
            onSelect: function () {changeItem('serviceRoutine')},
            onRemove: function () {changeItem('serviceRoutine')}
        };

        vm.selectedServiceRoutineOptions = [
            {label: 'NONE_SELECTED', type: 'none' },
            {label: 'NON_SFG20', type: 'non_versioned'},
            {label: 'SFG20', type: 'versioned'}
        ];

        vm.siteSelect = {
            response: siteCollectionResponse,
            link: siteCollectionResponse ? siteCollectionResponse.getLink('self') : null,
            responseKeyPath: 'sites',
            required: true,
            onSelect: changeSite,
            onRemove: removeSite,
            disabled: (typeof vm.data.id !== 'undefined'),
            populateDisabled: true
        };
        vm.jobGroupsSelect = {
            response: jobGroupsCollectionResponse,
            link: jobGroupsCollectionResponse ? jobGroupsCollectionResponse.getLink('self') : null,
            responseKeyPath: 'jobgroups',
            isHierarchical: true
        };
        vm.statutoryTypesSelect = {
            required: true,
            response: statutoryTypesCollectionResponse,
            link: statutoryTypesCollectionResponse ? statutoryTypesCollectionResponse.getLink('self') : null,
            responseKeyPath: 'statutorytypes'
        };
        if (vm.hasAssetTypes) {
            vm.assetTypesSelect = {
                response: assetTypesCollectionResponse,
                link: assetTypesCollectionResponse ? assetTypesCollectionResponse.getLink('self') : null,
                responseKeyPath: 'assettypes',
                itemValuePath: 'hierarchicalPath'
            };
        }
        vm.partnershipSelect = {
            disabled: true,
            required: false,
            responseKeyPath: 'partnerships',
            linkParameters: {permission: 'edit_assets', active: 1},
            itemHrefPath: '_links.serviceprovider.href',
            itemValuePath: '_links.serviceprovider.title'
        };
        vm.locationSelect = {
            selected: null,
            responseKeyPath: 'locations',
            disabled: true
        };

        vm.inputFuel = [
            {value: 'electricity', title: $translate.instant('ELECTRICITY')},
            {value: 'gas', title: $translate.instant('GAS')},
            {value: 'coal', title: $translate.instant('COAL')},
            {value: 'bioFuel', title: $translate.instant('BIOFUEL')},
            {value: 'steam', title: $translate.instant('STEAM')},
            {value: 'other', title: $translate.instant('OTHER')},
            {value: 'N/A', title: $translate.instant('N/A')}
        ];

        vm.create = createAction;
        vm.update = updateAction;
        vm.delete = deleteAction;

        // Fill form data on edit action
        if (typeof assetResponse._links !== 'undefined') {
            if (typeof assetResponse._links.site !== 'undefined') {
                vm.siteSelect.selected = assetResponse._links.site;
                // Get site to set resource links in sub select forms
                apiClient.get(vm.siteSelect.selected.href).then(function(response) {
                    if (response) {
                        vm.site = response;

                        if (typeof response.getLink('partnerships') !== 'undefined') {
                            vm.partnershipSelect.link = response.getLink('partnerships');
                            vm.partnershipSelect.disabled = false;
                        }
                        if (typeof response.getLink('locations') !== 'undefined') {
                            vm.locationSelect.link = response.getLink('locations');

                            apiClient.get(response.getLink('locations')).then(function(response) {
                                vm.locationSelect.response = response;
                            });
                        }
                    }
                });
            }

            if (typeof assetResponse._links.serviceprovider !== 'undefined') {
                vm.partnershipSelect.selected = assetResponse._links.serviceprovider;
                vm.partnershipSelect.disabled = false;
            }

            if (typeof assetResponse._links.location !== 'undefined') {
                vm.data.location = assetResponse._links.location;
                vm.locationSelect.selected = assetResponse._links.location.href;
                vm.locationSelect.disabled = false;
            }

            if (typeof assetResponse._links.type !== 'undefined') {
                vm.assetTypesSelect.selected = assetResponse._links.type;
            }

            if (typeof assetResponse._links.jobgroup !== 'undefined') {
                vm.jobGroupsSelect.selected = assetResponse._links.jobgroup;
            }

            if (typeof assetResponse._links.statutorytype !== 'undefined') {
                vm.statutoryTypesSelect.selected = assetResponse._links.statutorytype;
                vm.data.isStatutory = true;
            }

            vm.data.endOfLife = vm.assetResponse.endOfLife ? new Date(vm.assetResponse.endOfLife) : null;
            vm.data.installationDate = vm.assetResponse.installationDate ? new Date(vm.assetResponse.installationDate) : null;
            vm.data.warrantyStart = vm.assetResponse.warrantyStart ? new Date(vm.assetResponse.warrantyStart) : null;
            vm.data.warrantyExpiry = vm.assetResponse.warrantyExpiry ? new Date(vm.assetResponse.warrantyExpiry) : null;
        }

        /**
         * Load Site and setup service provider, location form elements
         */
        function changeSite(){
            if (typeof vm.data.id === 'undefined' && typeof vm.siteSelect.selected.href !== 'undefined') {
                apiClient.get(vm.siteSelect.selected.href).then(function (response) {
                    if (response) {
                        vm.site = response;

                        if (response.getLink('partnerships')) {
                            vm.partnershipSelect.disabled = false;
                            vm.partnershipSelect.clear();
                            vm.partnershipSelect.link = response._links.partnerships.href;
                        }
                        if (response.getLink('locations')) {
                            apiClient.get(response.getLink('locations')).then(function(response) {
                                vm.locationSelect.response = response;
                            });
                        }

                        if (response._links.assets.canEditWithoutSP) {
                            vm.partnershipSelect.required = false;
                        }
                    }
                });
            }
        }

        /**
         * Clear all form fields that are site related.
         */
        function removeSite() {
            vm.partnershipSelect.clear();
            vm.partnershipSelect.disabled = true;

            vm.locationSelect.disabled = true;
            vm.locationSelect.response = null;
            vm.data.location = null;
        }

        function prepareData() {
            vm.data._links = {};

            if (typeof vm.partnershipSelect.selected !== 'undefined') {
                vm.data._links.serviceprovider = vm.partnershipSelect.selected;
            }

            if (typeof vm.locationSelect.selected !== 'undefined' && vm.locationSelect.selected) {
                vm.data._links.location = vm.locationSelect.selected;
            }

            if (typeof vm.jobGroupsSelect.selected !== 'undefined') {
                vm.data._links.jobgroup = vm.jobGroupsSelect.selected;
            }

            if (typeof vm.assetTypesSelect.selected !== 'undefined') {
                vm.data._links.type = vm.assetTypesSelect.selected;
            }

            if (typeof vm.statutoryTypesSelect.selected !== 'undefined') {
                vm.data._links.statutorytype = vm.statutoryTypesSelect.selected;
            }

            if (!vm.data.isStatutory) {
                delete vm.data._links.statutorytype;
            }

            vm.data.serviceRoutines = {
                links: []
            };

            angular.forEach(vm.selectedServiceRoutines, function(value) {
                vm.data.serviceRoutines.links.push({
                    href: value.getLink('self')
                });
            });

            if (!vm.data.scoreTypes) {
                vm.data.scoreTypes = { scoretypes: []};
            }

            angular.forEach(vm.selectedScoreTypes, function(value) {
                vm.data.scoreTypes.scoretypes.push({
                    href: value.getLink('self')
                });
            });
        }

        function createAction() {
            prepareData();

            apiClient.create(vm.site.getLink('assets'), vm.data).then(function(response) {
                if (response) {
                    $state.go('^.details.info', {assetResource: response.getLink('self').encode()}, {reload: $state.current.parent}).then(function() {
                        messenger.success('ASSET_CREATED');
                    });
                } else {
                    messenger.error('REQUEST_ERROR');
                }
            });
        }

        function updateAction() {
            prepareData();

            apiClient.update(assetResponse.getLink('edit'), vm.data).then(function(response) {
                if (response) {
                    $state.go('^', {}, {reload: $state.get('^.^.^')}).then(function() {
                        messenger.success('ASSET_UPDATED');
                    });
                } else {
                    messenger.error('REQUEST_ERROR');

                    //In case of some SRs not deleted, reload them to show changes
                    apiClient.get(assetResponse.getLink('serviceroutines')).then(function(response) {
                        vm.selectedServiceRoutines = [];
                        angular.forEach(response.routines, function(value) {
                            vm.selectedServiceRoutines.push(value);
                        });
                    });
                }
            });
        }

        function deleteAction() {
            return confirmationModal.open().result.then(function() {
                return apiClient.delete(assetResponse.getLink('delete')).then(function(response) {
                    if (response) {
                        $state.go('^.^.^', {}, {reload: $state.get('^.^.^')}).then(function() {
                            messenger.success('ASSET_DELETED');
                        });
                    } else {
                        messenger.error('REQUEST_ERROR');
                    }
                });
            }, function() {
                return;
            });
        }

        function changeItem(type) {
            switch (type) {
                case 'serviceRoutine':
                    let response;
                    vm.items = vm.selectedServiceRoutines;
                    if (vm.data.serviceRoutineType === 'non_versioned') {
                        vm.selectedModel = vm.selectedNonVersionedServiceRoutineModel;
                        response = nonVersionedServiceRoutinesCollectionResponse;
                    } else if (vm.data.serviceRoutineType === 'versioned') {
                        vm.selectedModel = vm.selectedVersionedServiceRoutineModel;
                        response = versionedServiceRoutinesCollectionResponse;
                    }

                    vm.selectedModel.selectedItems = lodash.unionBy(vm.selectedModel.selectedItems, [vm.selectedModel.selected]);
                    vm.selectedModel.items = lodash.differenceBy(vm.selectedModel.items, vm.selectedModel.selectedItems, 'href');

                    if (vm.selectedModel.items.length === 0 && response.pages === 1) {
                        vm.selectedModel.show = false;
                    }

                    break;
                case 'assetScoreType':
                    vm.items = vm.selectedScoreTypes;
                    vm.selectedModel = vm.selectedAssetScoreTypeModel;
            }

            if (vm.selectedModel && vm.selectedModel.selected && vm.selectedModel.selected.object) {
                var isAlreadySelected = false;
                angular.forEach(vm.items, function(value, index) {
                    if (value.getLink('self') === vm.selectedModel.selected.object.getLink('self')) {
                        isAlreadySelected = true;
                    }
                });

                if (! isAlreadySelected) {
                    vm.items.push(vm.selectedModel.selected.object);
                }

                vm.selectedModel.selected = null;
            }
        }

        function removeItem(item, type) {
            switch (type) {
                case 'serviceRoutine':
                    vm.items = vm.selectedServiceRoutines;
                    if (!item.serviceRoutineVersionNumber) {
                        vm.selectedModel = vm.selectedNonVersionedServiceRoutineModel;
                    } else {
                        vm.selectedModel = vm.selectedVersionedServiceRoutineModel;
                    }

                    vm.selectedModel.show = true;
                    vm.selectedModel.selectedItems = lodash.differenceBy(vm.selectedModel.selectedItems, [item], 'id');
                    vm.selectedModel.items = lodash.unionBy(vm.selectedModel.items, [{object: item, id: item.id}], 'id');
                    vm.selectedModel.items = lodash.orderBy(vm.selectedModel.items, ['id'], ['asc']);

                    break;
                case 'assetScoreType':
                    vm.items = vm.selectedScoreTypes;
            }

            angular.forEach(vm.items, function(value, index) {
                if (value._links.self.href === item._links.self.href) {
                    vm.items.splice(index, 1);
                }
            });
        }

        $scope.$watch('vm.data.installationDate', function(newValue) {
            if (vm.data.installationDate) {
                var now = moment();
                newValue = moment(newValue);

                if (newValue > now) {
                    vm.data.age = 0;
                } else {
                    vm.data.age = now.diff(newValue, 'years');
                }

                calculateExpectedLife();
            } else {
                vm.data.age = null;
                vm.data.remainingLife = null;
            }
        });

        $scope.$watch('vm.data.expectedLife', calculateExpectedLife);

        function calculateExpectedLife() {
            if (vm.data.age >= 0 && vm.data.expectedLife >= 0) {
                vm.data.remainingLife = vm.data.expectedLife - vm.data.age >= 0 ? vm.data.expectedLife - vm.data.age : 0;
            }
        }
    }
})();
