(function () {
    'use strict';

    angular
        .module('elogbooks.common.priorities')
        .controller('PrioritiesDetailsInfoController', PrioritiesDetailsInfoController);

    PrioritiesDetailsInfoController.$inject = ['config', '$state', 'priorityResponse'];

    function PrioritiesDetailsInfoController (config, $state, priorityResponse) {
        var vm = this;
        vm.priority = priorityResponse;
        vm.config = config;

        vm.noBorder = vm.config.noBorder || false;
    }
})();
