app.config(function ($translateProvider) {
    $translateProvider.translations('en-gb', {
        ASSET_SCORE_TYPE: 'Asset Score Type',
        ASSET_SCORE_TYPES: 'Asset Score Types',
        ASSET_SCORE: 'Asset Score',
        SCORE_OPTIONS: 'Score Option',

        ASSET_SCORE_TYPE_ADD: 'Add a new Asset Score Type',
        ASSET_SCORE_TYPE_LIST: 'Asset Score Type',

        ASSET_SCORE_TYPE_VIEW: 'Info',

        ASSET_SCORE_TYPE_EDIT: 'Edit Asset Score Type',

        ASSET_SCORE_TYPE_CREATED: 'Asset Score Type Created',
        ASSET_SCORE_TYPE_SCORING_REQUIRED_CREATED: 'Asset Score Type Created. You will be notified when Assets are updated',
        ASSET_SCORE_TYPE_UPDATED: 'Asset Score Type Updated',
        ASSET_SCORE_TYPE_SCORING_REQUIRED_UPDATED: 'Asset Score Type Updated. You will be notified when Assets are updated',
        ASSET_SCORE_TYPE_DELETED: 'Asset Score Type Deleted',
        ASSET_SCORE_TYPE_ADDED: 'Asset Score Type Added',

        RANGE_WEIGHT: 'Range Weight',
        RANGE_LOWER_BETTER: 'Lower is Better',
        RANGE_HIGHER_BETTER: 'Higher is Better',
        COLOUR_FIELD_MUST_BE_IN_HEX_FORMAT: 'Colour field mush be in hex format',

        ASSET_SCORE_TYPE_OPTION: 'Asset Score Type Options',
        ASSET_SCORE_TYPE_OPTION_LIST: 'Asset Score Type Options',
        ASSET_SCORE_TYPE_OPTION_EDIT: 'Edit Asset Score Type Option',

        ASSET_SCORE_TYPE_OPTION_ADD: 'Add a new Asset Score Type Option',

        ASSET_SCORE_TYPE_OPTION_CREATED: 'Asset Score Type Option Created',
        ASSET_SCORE_TYPE_OPTION_UPDATED: 'Asset Score Type Option Updated',
        ASSET_SCORE_TYPE_OPTION_DELETED: 'Asset Score Type Option Deleted',

        ADD_ASSET_SCORES: 'Add asset scores',

        UNABLE_TO_DELETE_SCORE_TYPE: 'Unable to delete score type as it\'s in use',
        UNABLE_TO_EDIT_SCORE_TYPE: 'Unable to edit score type as it\'s in use',
        UNABLE_TO_DELETE_SCORE_TYPE_OPTION: 'Unable to delete score type option as it\'s in use',
        UNABLE_TO_EDIT_SCORE_TYPE_OPTION: 'Unable to edit score type option as it\'s in use',

        SCORING_REQUIRED: 'Apply scoring to all assets'
    });
});
