angular.module('elogbooksServices').service('apiClient', function ($http, $window, base64, API_URL, $q, lodash, $state, messenger, disableSubmitService) {

    var webServiceClient = this;
    webServiceClient.ResponseData = ResponseData;

    function checkIfInteger(value) {
        return typeof value === "number" &&
            isFinite(value) &&
            Math.floor(value) === value;
    }

    function convert(data) {
        angular.forEach(data, function (value, key) {
            if (key === '_links') {
                return;
            }

            if (angular.isObject(value) && value['_links']) {
                data[key] = new ResponseData(value);
            }

            if (angular.isArray(value)) {
                convert(value);
            }

            // Embedded collections should have access to getLink, getLinkAttribute, etc...
            if (key === '_embedded') {
                angular.forEach(value, function(_value, _key) {
                    if (angular.isObject(_value)) {
                        value[_key] = new ResponseData(_value);
                    }

                    if (angular.isArray(_value)) {
                        convert(_value);
                    }
                });
            }
        });
    }

    function ResponseData(responseData) {
        var self = this;
        var data = angular.extend(self, responseData);
        convert(self);

        data.getCollection = function (key) {
            if (!data[key]) {
                return null;
            }

            if (!data[key].length) {
                return null;
            }

            var collection = new Array;

            lodash.each(data[key], function (element) {
                collection.push(new ResponseData(element));
            });

            return collection;
        }

        this.getResource = function (link, force) {
            if (data._embedded && data._embedded[link] && !force) {
                return $q.when(new ResponseData(data._embedded[link]));
            } else if (data._links[link]) {
                // TODO implement for arrays
                if (data._links[link].length) {
                    console.log('array of resources, not currently implemented');
                    return $q.reject('array of resources, not currently implemented');
                }

                // Treat force as parameters if not boolean
                if (force && typeof force !== "boolean") {
                    return webServiceClient.get(data._links[link].href, force);
                }

                return webServiceClient.get(data._links[link].href);
            } else {
                //var errorMessage = 'property "' + link + '" does not exist';
                //console.log(errorMessage);
                return null;
            }
        }

        this.getLink = function (link) {
            if (data._links && data._links[link]) {
                return data._links[link].href;
            }

            return null;
        }

        this.getLinkAttribute = function (link, attribute) {
            if (data._links[link] && data._links[link].hasOwnProperty(attribute)) {
                return data._links[link][attribute];
            }

            return null;
        };

        this.getAttribute = function (attribute, defaultValue) {
            if (data.attributes) {
                var matchedAttribute = lodash.find(data.attributes || [], {key: attribute});

                return matchedAttribute ? matchedAttribute.value : defaultValue;
            }

            return defaultValue;
        };

        this.getData = function () {
            return data;
        }

        this.equals = function(relation) {
            var selfLink = self.getLink('self');
            if (!selfLink) {
                // If there's no self link, fall back to comparison by reference
                return self == relation;
            }

            if (angular.isObject(relation) && relation.hasOwnProperty('getLink')) {
                return selfLink == relation.getLink('self');
            }

            // Assume string
            return selfLink == relation;
        };

        this.more = function() {
            if (self.getLink('next')) {
                var deferred = $q.defer();

                webServiceClient.get(this.getLink('next')).then(function(response) {
                    for(var key in response) {
                        if (!key in data) {
                            continue;
                        }

                        // Overwrite links
                        if (key == '_links') {
                            data[key] = response[key];
                            continue;
                        }

                        // Append or merge everything else that isn't a function
                        if (angular.isArray(response[key])) {
                            data[key].push.apply(data[key], response[key]);
                        } else if (angular.isObject(response[key])) {
                            angular.merge(data[key], response[key]);
                        } else if (!angular.isFunction(response[key])) {
                            data[key] = response[key];
                        }
                    }

                    deferred.resolve(data);
                });

                return deferred.promise;
            }

            return $q.when(null);
        };

        return this;
    }

    function removeEmptyRelations(data) {
        if (data && typeof data._links !== 'undefined') {
            data._links = lodash.each(data._links, function (link, key) {
                if (!link) {
                    delete data._links[key];
                }
            });
        }
        return data;
    }

    function removeNonUpdatableLinks(data) {

        if (!data._links) {
            return data;
        }

        delete data._links.self;
        delete data._links.delete;
        delete data._links.create;
        delete data._links.edit;
        return data;
    }

    this.option = function (resource, params) {
        if (resource !== null) {
            return $http({
                url: API_URL + resource,
                method: "OPTIONS",
                params: params
            }).then(function (response) {
                return new ResponseData(response.data);
            }, function (response) {
                console.log('Error: method not allowed. ' + API_URL + resource );
                // console.log(JSON.stringify(response));
            });
        } else {
            // The resource does not exist
            console.log('null passed to apiClient.get');
            console.trace();
            var deferred = $q.defer();
            deferred.resolve(false);
            return deferred.promise;
        }
    };

    this.getCacheKey = function (resource, params) {
        return 'cache_' + base64.encode(JSON.stringify(angular.extend({apiUrl:resource}, params)))
    };

    this.hasCache = function (key) {
        return $window.localStorage.getItem(key);
    };

    this.getCache = function (key) {
        var cacheData = JSON.parse($window.localStorage.getItem(key));

        if (new Date(cacheData.expireAt) <= new Date()) {
            $window.localStorage.removeItem(key);
            return false;
        }

        return new ResponseData(cacheData.data);
    };

    /**
     * @param key
     * @param value
     * @param expireAfter in seconds
     */
    function setCache(key, value, expireAfter) {
        $window.localStorage.setItem(key, JSON.stringify({expireAt:new Date(Date.now() + expireAfter * 1000), data:value}));

        setTimeout(function () {
            $window.localStorage.removeItem(key);
        }, expireAfter * 1000);
    }

    /**
     * @param key
     */
    function clearCache(key) {
        $window.localStorage.removeItem(key);
    }

    function getCacheTime(cache) {
        if (cache === 'long') {
            // 10 min cache
            return 600;
        }

        if (cache === 'medium') {
            // 2 min cache
            return 120;
        }

        if (cache === 'short') {
            // 1 min cache
            return 60;
        }

        return checkIfInteger(cache) ? cache : (cache ? 120 : 2)
    }

    /**
     * Default cache all for 10 seconds
     * If cache param is set to true then for 2 minutes
     * If cache param is set to false then disable cache
     *
     *
     * @param resource
     * @param params
     * @param cache
     * @returns {*}
     */
    this.get = function (resource, params, cache) {
        var cacheKey = this.getCacheKey(resource, params);
        var secondsDelay = cache === false ? false : getCacheTime(cache);

        if (resource !== null) {
            if (resource !== '/' && secondsDelay && this.hasCache(cacheKey)) {
                var cacheData = this.getCache(cacheKey);

                if (cacheData) {
                    var deferred = $q.defer();
                    deferred.resolve(cacheData);

                    return deferred.promise;
                }
            }

            return $http({
                url: API_URL + resource,
                method: "GET",
                params: params
            }).then(function (response) {
                if (response && response.data) {
                    if (secondsDelay) {
                        setCache(cacheKey, response.data, secondsDelay);
                    }

                    return new ResponseData(response.data);
                }
            }, function (response) {
                console.log('Error: method not allowed. ' + API_URL + resource);
                // console.log(JSON.stringify(response));
            });

        } else {
            // The resource does not exist
            console.log('null passed to apiClient.get');
            console.trace();
            var deferred = $q.defer();
            deferred.resolve(false);
            return deferred.promise;
        }
    };

    this.noResourceFound = function (state, message) {
        var state = state || 'dashboard.user.dashboard';
        var message = message || 'NO_RESOURCE_FOUND';

        return $state.go(state, {}, { reload: true }).then(function () {
            messenger.error(message);
        });
    };

    this.getPage = function (resource, params) {
        return webServiceClient.get(resource, params);
    };

    this.create = function (resource, data, params, disableEnableSubmitButton) {
        data = removeEmptyRelations(data);
        var spinner = angular.element('i.loading-spinner');
        var element = disableSubmitService.disableSubmit(null, spinner);

        return $http({
            url: API_URL + resource,
            method: "POST",
            data: data,
            params: params
        }).then(function (response) {
            if (typeof disableEnableSubmitButton === 'undefined') {
                disableSubmitService.enableSubmit(element, spinner);
            }

            if (response && response.status == 201) {
                return new ResponseData(response.data);
            } else if (response && (response.status == 204 || response.status == 202)) {
                return true;
            } else if (response) {
                console.log('Server responded with invalid status code: ' + response.status);
            }

            return false;

        }, function (response) {
            disableSubmitService.enableSubmit(element, spinner);

            console.log('There was an error creating the entity.');
            return false;
        });
    };

    this.update = function (resource, data, invalidateCache) {
        // invalidate cache!
        if (invalidateCache && resource !== null) {
            var cacheKey = this.getCacheKey(resource);
            if (resource !== '/' && this.hasCache(cacheKey)) {
                clearCache(cacheKey);
            }
        }

        data = removeEmptyRelations(data);
        data = removeNonUpdatableLinks(data);
        var spinner = angular.element('i.loading-spinner');
        var element = disableSubmitService.disableSubmit(null, spinner);

        return $http.put(API_URL + resource, data).then(function (response) {
            disableSubmitService.enableSubmit(element, spinner);

            if ([204, 201].indexOf(response.status) === -1) {
                console.log('Server responded with invalid status code: ' + response.status);
            }
            return new ResponseData(response.data);
        }, function (response) {
            disableSubmitService.enableSubmit(element, spinner);

            console.log('There was an error updating the entity ' + API_URL + resource);
            console.log(JSON.stringify(response));
        });
    };

    this.delete = function (resource, params) {
        return $http.delete(API_URL + resource, {
            params: params
        }).then(function (response) {
            if (response.status == 201) {
                return new ResponseData(response.data);
            }

            return true;
        }, function (response) {
            console.log('Unable to delete');
            return false;
        });
    };

    return this;

});
