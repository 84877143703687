(function() {
    'use strict';

    angular
        .module('elogbooks.admin.users')
        .controller('UserAddEditController', UserAddEditController);

    UserAddEditController.$inject = [
        '$state',
        'userResponse',
        'apiClient',
        'messenger',
        'confirmationModalNotes',
        'confirmationModal',
        'rootResourceResponse',
        '$filter',
        'requestDataFactory',
        'password',
        'userTypesResponse',
        'userCategoriesResponse',
        'departmentsResponse',
        'selectedDepartment',
        '$translate',
        'elbSettings',
        'recurrenceRulesService',
        'userManager'
    ];

    function UserAddEditController(
        $state,
        userResponse,
        apiClient,
        messenger,
        confirmationModalNotes,
        confirmationModal,
        rootResourceResponse,
        $filter,
        requestDataFactory,
        password,
        userTypesResponse,
        userCategoriesResponse,
        departmentsResponse,
        selectedDepartment,
        $translate,
        elbSettings,
        recurrenceRulesService,
        userManager
    ) {
        var vm = this;
        vm.absenceModuleEnabled = elbSettings.getSetting('absence_planner_enabled') ? elbSettings.getSetting('absence_planner_enabled').value : false;
        vm.user = userResponse;
        vm.recurrenceRulesService = recurrenceRulesService;
        vm.departmentAutocompleteOptions = null;
        vm.clientAdminAccess = userManager.hasPermission('user_permission_client_admin_manage_users') && !userManager.hasPermission('user_permission_manage_users');

        if (vm.absenceModuleEnabled) {
            if (vm.user.getLink && vm.user.getLink('department') && departmentsResponse) {
                vm.user.department = selectedDepartment;
            }

            var defaultYearlyEntitlement = elbSettings.getSetting('default_yearly_entitlement').value;
            var defaultHolidayYearStartDay = elbSettings.getSetting('default_holiday_year_start_day').value;
            var defaultHolidayYearStartMonth = elbSettings.getSetting('default_holiday_year_start_month').value;

            vm.user.yearlyEntitlement = vm.user && vm.user.yearlyEntitlement ? vm.user.yearlyEntitlement : defaultYearlyEntitlement;
            vm.user.holidayYearStartDay = vm.user && vm.user.holidayYearStartDay ? vm.user.holidayYearStartDay : defaultHolidayYearStartDay;
            vm.user.holidayYearStartMonth = vm.user && vm.user.holidayYearStartMonth ? vm.user.holidayYearStartMonth : defaultHolidayYearStartMonth;

            if (departmentsResponse) {
                vm.departmentAutocompleteOptions = {
                    response: departmentsResponse,
                    responseKeyPath: 'departments',
                    queryParams: {
                        active: true
                    }
                };
            }
        }

        vm.data = requestDataFactory.createRequest(vm.user);
        vm.originalData = requestDataFactory.createRequest(vm.user);

        if (password) {
            vm.data.password = password;
        }

        vm.create = createAction;
        vm.update = updateAction;
        vm.delete = deleteAction;

        vm.statuses = [
            {
                title: $filter('userStatus')('draft'),
                value: 0
            },
            {
                title: $filter('userStatus')('training'),
                value: 1
            },
            {
                title: $filter('userStatus')('active'),
                value: 2
            },
            {
                title: $filter('userStatus')('inactive'),
                value: 3
            }
        ];

        vm.userTypes = Object.values(userTypesResponse)
            .filter(function(val) {
                return typeof val !== 'function';
            })
            .map(function(val) {
                if ('propertyplus' === val) {
                    return {
                        title: $translate.instant(val.toUpperCase()+'_LIGTAS'),
                        value: val
                    };
                }

                return {
                    title: $translate.instant(val.toUpperCase()),
                    value: val
                };
            });

        vm.userCategories = Object.values(userCategoriesResponse)
            .filter(function(val) {
                return typeof val !== 'function';
            })
            .map(function(val) {
                return {
                    title: $translate.instant('USER_CATEGORY_' + val.toUpperCase()),
                    value: val
                };
            });

        function createAction() {
            if (departmentsResponse && vm.data.department.formData) {
                vm.data._links.department = vm.data.department.formData[0];
            }

            return apiClient.create(rootResourceResponse.getLink('users'), vm.data).then(function (response) {
                if (response) {
                    $state.go('dashboard.admin.users.list.details.info', { resource: response.getLink('self').encode() }, { reload: 'dashboard.admin.users.list' }).then(function () {
                        messenger.success('USER_CREATED');
                    });
                } else {
                    messenger.error('REQUEST_ERROR');
                }
            });
        }

        /**
         * Save the user
         */
        function updateAction() {
            if (vm.data.status !== vm.originalData.status) {
                return confirmationModalNotes.open({bodyMessage: 'REASON_FOR_STATUS_CHANGE', required: true}).result.then(
                    function(note) {
                        updateForm(note);
                    });
            } else {
                updateForm();
            }
        }

        function updateForm (note) {
            if (departmentsResponse && vm.data.department.formData) {
                vm.data._links.department = vm.data.department.formData[0];
            } else {
                delete vm.data._links.department;
            }

            vm.data.statusNotes = note !== undefined ? note : null;
            return apiClient.update(vm.user.getLink('self'), vm.data).then(function (response) {
                if (response) {
                    const route = vm.user.name !== vm.data.name ? true : 'dashboard.admin.users.list';
                    $state.go('dashboard.admin.users.list.details.info', {}, { reload: route }).then(function () {
                        messenger.success('USER_UPDATED');
                    });
                } else {
                    messenger.error('REQUEST_ERROR');
                }
            });
        }

        /**
         * Remove a user
         *
         * @param user
         * @returns {*}
         */
        function deleteAction() {
            return confirmationModal.open().result.then(
                function() {
                    apiClient.delete(vm.user.getLink('delete')).then(function (response) {
                        if (response) {
                            $state.go('dashboard.admin.users.list', {}, { reload: 'dashboard.admin.users.list' }).then(function(){
                                messenger.success('USER_DELETED');
                            });
                        }
                    });
                }
            );
        }
    }
})();
