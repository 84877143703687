/**
 * AuditService
 */
angular.module('elogbooksServices').service('hierarchyService', function (lodash) {

    this.getJobGroupHierarchy = getJobGroupHierarchy;
    this.processHierarchy = processHierarchy;

    var itemValuePath = 'name';

    function getJobGroupHierarchy($collection, removePrefix = false) {

        var nofixed = processHierarchy($collection, null),
            prefixed = [];

        function processChildren(items, prefix) {

            prefix = prefix + ' / ';

            angular.forEach(items, function (value, index) {
                if(removePrefix) {
                    lodash.set(value, itemValuePath, lodash.get(value, itemValuePath));
                } else {
                    lodash.set(value, itemValuePath, prefix + lodash.get(value, itemValuePath));
                }

                prefixed.push(angular.extend({}, value, {isChild: true}));

                if (value.children.length) {
                    processChildren(value.children, lodash.get(value, itemValuePath));
                }
            });
        }

        angular.forEach(nofixed, function (value, index) {
            prefixed.push(value);
            if (value.children.length) {
                processChildren(value.children, lodash.get(value, itemValuePath));
            }
        });

        return prefixed;
    }

    function processHierarchy (collection, parent) {
        var items = [];

        angular.forEach(collection, function (value, index) {
            if ((value.getLink('parent') === null && ! parent) || value.getLink('parent') === parent) {
                var children = processHierarchy(collection, value.getLink('self'));

                items.push(angular.extend({}, value, {children: children}));
            }
        });

        return items;
    }

});