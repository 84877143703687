(function () {
    'use strict';

    angular
        .module('elogbooks.admin.document-types-management')
        .controller('DocumentSetsListController', DocumentSetsListController);

    DocumentSetsListController.$inject = ['$state', '$stateParams', '$filter', 'documentSetCollectionResponse'];

    function DocumentSetsListController($state, $stateParams, $filter, documentSetCollectionResponse) {
        var vm = this;

        vm.documentSets = documentSetCollectionResponse.documentSets;
        vm.documentSetCollectionResponse = documentSetCollectionResponse;

        vm.order = order;
        vm.search = search;

        var statuses = [
            {
                title: $filter('translate')('STATUS_ACTIVE'),
                value: 'active'
            },
            {
                title: $filter('translate')('STATUS_INACTIVE'),
                value: 'inactive'
            },
            {
                title: $filter('translate')('NONE_SELECTED'),
                value: null
            }
        ];

        vm.criteria = {
            name: { type: 'text', value: $stateParams.name, title: 'NAME' },
            description: { type: 'text', value: $stateParams.description, title: 'DESCRIPTION' },
            status: { type: 'options', title: 'IS_ACTIVE', value: $stateParams.status, options: statuses }
        };

        function search(params) {
            var override = {
                page: 1
            };

            $state.go('.', angular.extend({}, params, override), {reload: $state.current});
        };

        function order(key) {
            $stateParams.order = key;

            $state.go('.', angular.extend({}, $stateParams), {reload: $state.current});
        };
    }
})();
