app.config(function ($translateProvider) {
    $translateProvider.translations('nl', {
        ROLE: 'Rol',
        ROLES: 'Rollen',

        ROLE_EDIT: 'Wijzig rol',
        ROLE_LIST: 'Rollen',
        ROLE_ADD: 'Voeg een nieuwe rol toe',

        USER_ROLE: 'Gebruikersrol',
        SITE_MEMBERSHIP_ROLE: 'Object rol',
        OPERATIVE_ROLE: 'Opdrachtnemer rol',

        ROLE_LIST: 'Rollen',
        ROLE_ADD: 'Voeg een nieuwe rol toe',
        ROLE_EDIT: 'Wijzig rol',

        ROLE_CREATED: 'Rol aangemaakt',
        ROLE_UPDATED: 'Rol aangepast',
        ROLE_DELETED: 'Rol verwijderd',

        ROLE_PERMISSIONS: 'Rol authorisatie',
        ROLE_PERMISSIONS_UPDATED: 'Rol authorisatie aangepast',
        MANAGE_USERS: 'Gebruikersbeheer'
    });
});
