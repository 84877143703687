(function () {
    'use strict';

    angular
        .module('elogbooks.admin.job-requisites')
        .controller('JobRequisitesServiceProvidersController', JobRequisitesServiceProvidersController);

    JobRequisitesServiceProvidersController.$inject = ['jobRequisiteResponse', 'selectedServiceProvidersCollectionResponse', '$stateParams', '$state', 'lodash', 'confirmationModal', 'apiClient', 'messenger', 'userManager'];

    function JobRequisitesServiceProvidersController (jobRequisiteResponse, selectedServiceProvidersCollectionResponse, $stateParams, $state, lodash, confirmationModal, apiClient, messenger, userManager) {
        var vm = this;
            vm.selectedServiceProvidersCollectionResponse = selectedServiceProvidersCollectionResponse;
            vm.jobRequisite = jobRequisiteResponse;
            vm.serviceProviders = selectedServiceProvidersCollectionResponse.serviceproviders;
            vm.entityCount = selectedServiceProvidersCollectionResponse.count;
            vm.viewSP = userManager.hasPermission('user_permission_view_service_providers');
            vm.search = search;
            vm.order = order;
            vm.deleteSelected = deleteAction;

        vm.criteria = {
            serviceProviderId: { type: 'number', value: $stateParams.serviceProviderId, title: 'ID' },
            serviceProviderName: { type: 'text', value: $stateParams.serviceProviderName, title: 'SERVICE_PROVIDER' }
        }

        function search (params) {
            var override = {
                serviceProviderPage: 1
            };

            $state.go('.', angular.extend({}, params, override), { reload: true });
        }

        function order (key) {
            $stateParams.serviceProviderOrder = key;

            var override = {
                serviceProviderPage: 1
            };

            $state.go('.', angular.extend({}, $stateParams, override), { reload: true });
        }

        function deleteAction() {
            var toRemove = [];
            lodash.forEach(vm.checked, function(value, serviceProvider) {
                if(value === true) {
                    toRemove.push({"href": serviceProvider});
                }
            });

            if (toRemove.length > 0) {
                var data = {
                    _links: {}
                };

                data._links.serviceproviders = toRemove;

                // Show an alert asking the user to confirm
                return confirmationModal.open().result.then(function () {
                    return apiClient.update(vm.jobRequisite.getLink('serviceproviders'), data).then(function (response) {
                        if (response) {
                            $state.go('.', {}, { reload: $state.current.name }).then(function () {
                                messenger.success('SERVICEPROVIDERS_DELETED_FROM_JOB_REQUISITE');
                            });
                        } else {
                            messenger.error('REQUEST_ERROR');
                        }
                    });
                });
            } else {
                // Show an alert telling the user they need to select at least one serviceProvider
                return confirmationModal.open({
                    titleMessage: 'ZERO_OPTIONS_CHECKED_TITLE',
                    bodyMessage: 'ZERO_OPTIONS_CHECKED_MESSAGE',
                    primaryText: null,
                    secondaryText: 'OK'
                });
            }
        }
    }
})();
