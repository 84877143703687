(function(){
    'use strict';

    angular
        .module('elogbooks.admin.service-providers')
        .controller('ServiceProvidersListController', ServiceProvidersListController);

    ServiceProvidersListController.$inject = ['$state', '$stateParams', '$filter', 'serviceProviderCollection', '$translate'];

    function ServiceProvidersListController($state, $stateParams, $filter, serviceProviderCollection, $translate) {
        var vm = this;
            vm.serviceProviders = serviceProviderCollection.serviceproviders;
            vm.serviceProviderCollectionResponse = serviceProviderCollection;

        vm.order = order;
        vm.search = search;

        var statusOptions = [
            {
                title: $filter('translate')('NONE_SELECTED'),
                value: null
            },
            {
                title: $filter('translate')('STATUS_ACTIVE'),
                value: 'active'
            },
            {
                title: $filter('translate')('STATUS_INACTIVE'),
                value: 'inactive'
            },
            {
                title: $filter('translate')('STATUS_TRAINING'),
                value: 'training'
            }
        ];

        var contractorApprovedOptions = [
            {title: $translate.instant('NONE_SELECTED'), value: null},
            {title: $translate.instant('YES'), value: '1'},
            {title: $translate.instant('NO'), value: '0'}
        ];

        vm.criteria = {
            id: { type: 'number', title: 'ID', value: $stateParams.id ? parseInt($stateParams.id, 10) : null, min: 1 },
            name: { type: 'text', title: 'NAME', value: $stateParams.name },
            reference: { type: 'text', title: 'REFERENCE', value: $stateParams.reference },
            status: { type: 'options', title: 'STATUS', value: $stateParams.status ? $stateParams.status : null, options: statusOptions },
            contractorApproved: {
                type: 'options',
                title: 'CONTRACTOR_APPROVED',
                value: $stateParams.contractorApproved,
                options: contractorApprovedOptions,
                clearValue: null
            }
        };


        function order (key) {
            $stateParams.order = key;

            $state.go('.', angular.extend({}, $stateParams), { reload: true });
        }

        function search (params) {
            var override = {
                page: 1
            };

            $state.go('.', angular.extend({}, params, override), { reload: true });
        }

    }
})();
