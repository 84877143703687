(function() {
    'use strict';

    angular
        .module('elogbooks.user.spillages')
        .controller('UserSpillageAddEditController', UserSpillageAddEditController);

    UserSpillageAddEditController.$inject = ['apiClient', 'messenger', '$state', 'userManager', 'siteResponse', 'siteCollectionResponse', 'spillageTypeCollectionResponse', 'selectedSiteResponse', '$translate', 'lodash'];

    function UserSpillageAddEditController (apiClient, messenger, $state, userManager, siteResponse, siteCollectionResponse, spillageTypeCollectionResponse, selectedSiteResponse, $translate, lodash) {
        var vm = this;
        vm.userManager = userManager;
        vm.siteResponse = siteResponse;
        vm.sites = siteCollectionResponse.sites;
        vm.data = {};
        vm.locationSelect = {};
        vm.selectedSiteResponse = selectedSiteResponse;

        vm.siteSelect = {
            response: siteCollectionResponse,
            link: siteCollectionResponse ? siteCollectionResponse.getLink('self') : null,
            responseKeyPath: 'sites',
            required: true,
            onSelect: changeSite,
            onRemove: removeSite,
            disabled: selectedSiteResponse !== null,
            populateDisabled: true,
            selected: selectedSiteResponse ? {'href' : selectedSiteResponse._links.self.href, 'title': selectedSiteResponse.name} : null
        };

        vm.spillageTypeSelect = {
            response: spillageTypeCollectionResponse,
            link: spillageTypeCollectionResponse ? spillageTypeCollectionResponse.getLink('self') : null,
            responseKeyPath: 'spillagetypes',
            required: true,
            itemValuePath: 'name',
            populateDisabled: true,
            isHierarchical: true
        };

        vm.serviceProviderSelect = {
            responseKeyPath: 'partnerships',
            linkParameters: { permission: 'receive_spillages', includeCanCreateSpillagesOtherServiceProviders: true},
            required: true,
            itemHrefPath: '_links.serviceprovider.href',
            itemValuePath: '_links.serviceprovider.title',
            disabled: true,
            onSelect: changeServiceProvider,
            onRemove: changeServiceProvider
        };

        vm.reporterSiteContactSelect = {
            responseKeyPath:'contacts',
            required: true,
            disabled: true
        };

        vm.operativeSelect = {
            response: null,
            responseKeyPath: 'operatives',
            itemValuePath: '_links.self.title'
        };

        vm.create = createAction;

        if (selectedSiteResponse) {
            changeSite();
        }
        
        /**
         * Load Site and setup service provider, location, spillage type form elements
         */
        function changeSite() {
            if (typeof vm.siteSelect.selected.href !== 'undefined') {
                apiClient.get(vm.siteSelect.selected.href).then(function (response) {
                    if (response) {
                        vm.site = response;

                        if (response.getLink('partnerships')) {
                            vm.serviceProviderSelect.link = response._links.partnerships.href;
                            vm.serviceProviderSelect.disabled = false;
                        }

                        if (response.getLink('locations')) {
                            apiClient.get(response.getLink('locations')).then(function(response) {
                                vm.locationSelect = {
                                    responseKeyPath: 'locations',
                                    response: response
                                };
                            });
                        }

                        if (response.getLink('contacts')) {
                            apiClient.get(response.getLink('contacts')).then(function(response) {
                                vm.reporterSiteContactSelect.response = response;
                                vm.reporterSiteContactSelect.disabled = false;
                            });
                        }

                        if (response.getLink('spillagetypes')) {
                            apiClient.get(response.getLink('spillagetypes')).then(function(response) {
                                if (response.count > 0) {
                                    vm.spillageTypeSelect.link = response.getLink('self');
                                    vm.spillageTypeSelect.response = response;
                                } else {
                                    vm.spillageTypeSelect.link = spillageTypeCollectionResponse ? spillageTypeCollectionResponse.getLink('self') : null;
                                    vm.spillageTypeSelect.response = spillageTypeCollectionResponse;
                                }
                                vm.spillageTypeSelect.selected = null;
                            });
                        }
                    }
                });
            }
        }

        function changeServiceProvider(serviceProvider) {
            vm.operativeSelect.selected = null;
            vm.operativeSelect.response = null;
            vm.operative = null;
            vm.assignToMe = null;

            if (serviceProvider.selected) {
                apiClient.get(serviceProvider.selected.object.getLink('spillageoperatives'), {permission: 'operative_permission_receive_spillages'}).then(function (response) {
                    if (response && response.count > 0) {
                        var operativesResponse = response;
                        apiClient.get(serviceProvider.selected.object.getLink('spillageoperatives'), {
                            permission: 'operative_permission_receive_spillages',
                            id: userManager.user.id
                        }).then(function (response) {
                            if (response && response.count > 0) {
                                vm.operative = response.operatives[0];

                                var operativeIndex = lodash.findIndex(operativesResponse.operatives, function (operative) {
                                    return vm.operative.getLink('self') === operative.getLink('self');
                                });

                                if (operativeIndex > -1) {
                                    operativesResponse.operatives.splice(operativeIndex, 1);
                                    operativesResponse.count--;
                                }
                            }
                            vm.operativeSelect.response = operativesResponse;
                        });
                    }
                });
            }
        }

        /**
         * Clear all form fields that are site related.
         */
        function removeSite() {
            vm.locationSelect = null;
            vm.serviceProviderSelect.selected = null;
            vm.serviceProviderSelect.disabled = true;
            vm.operativeSelect.selected = null;
            vm.spillageTypeSelect.selected = null;
            vm.reporterSiteContactSelect.selected = null;
            vm.reporterSiteContactSelect.disabled = true;
        }

        function createAction() {
            vm.data._links = {
                serviceprovider: { href: vm.serviceProviderSelect.selected.href },
                spillagetype: { href: vm.spillageTypeSelect.selected.href }
            };

            if (typeof vm.locationSelect.selected !== 'undefined') {
                vm.data._links.location = { href: vm.locationSelect.selected.href };
            }

            if (vm.operativeSelect.selected && vm.operativeSelect.selected.href) {
                vm.data._links.operative =  { href: vm.operativeSelect.selected.href };
            }

            if (vm.reporterSiteContactSelect.selected && vm.reporterSiteContactSelect.selected.href) {
                vm.data._links.reporter =  { href: vm.reporterSiteContactSelect.selected.href };
            }

            if (!vm.data.notCloseBy) {
                if (vm.uploadedImage) {
                    vm.data._links.image = { href: vm.uploadedImage._links.resource.href };
                } else {
                    messenger.error('PHOTO_IS_REQUIRED');
                    return;
                }
            }

            apiClient.create(vm.site.getLink('create-spillages'), vm.data).then(function(response) {
                if (response) {
                    $state.go('^.details.status', {spillageResource: response.getLink('self').encode()}, {reload: $state.current.parent}).then(function() {
                        messenger.success('SPILLAGE_CREATED');
                    });
                } else {
                    messenger.error('REQUEST_ERROR');
                }
            });
        }
    }
})();
