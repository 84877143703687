/**
 * SurveyRequest score label
 */
angular.module('elogbooksDirectives').directive('scoreLabel', function () {
    return {
        restrict: 'AE',
        scope: {
            score: '=ngModel',
            range: '='
        },
        templateUrl: '/modules/directives/survey/survey-request/elements/score/score.html',
        link: link
    };

    function link(scope, element, attrs) {
        if (scope.range == 'yn') {
            scope.scoreLabel = (scope.score == 1) ? 'Yes' : 'No';
        } else {
            scope.scoreLabel = scope.score;
        }
    };
});
