(function(){
    'use strict';

    angular
        .module('elogbooks.common.jobs')
        .controller('JobsListModalController', JobsListModalController);

    JobsListModalController.$inject = ['jobsCollectionResponse', '$uibModalInstance'];

    function JobsListModalController(jobsCollectionResponse, $uibModalInstance) {

        var vm = this;
        vm.jobs = jobsCollectionResponse.jobs;

        vm.cancel = function() {
            $uibModalInstance.dismiss('cancel');
        };
    }
})();
