(function() {
    'use strict';

    angular
        .module('elogbooks')
        .config(Translations);

    function Translations($translateProvider) {
        $translateProvider.translations('zh-hk', {
            ESCALATION_STAGE: '上報階段',
            ESCALATION_STAGES: '上報階段',
            ESCALATION_STAGE_LIST: '上報階段',

            ESCALATION_STAGE_ADD: '新增上報階段',
            ESCALATION_STAGE_EDIT: '修改上報階段',

            ESCALATION_STAGE_CREATED: '上報階段已建立',
            ESCALATION_STAGE_UPDATED: '上報階段已更新',
            ESCALATION_STAGE_DELETED: '上報階段已刪除',
        });
    }
})();

app.config(function ($translateProvider) {
    $translateProvider.translations('zh-hk', {
        STAGE: '階段',
        EMAIL_CONTENT: '電子郵件內容',
        PRIMARY_CONTACT: '首要聯絡',
        ASSOCIATE_TYPES: '關聯類別',
        ASSOCIATE_TYPES_SELECT: '選擇關聯類別',
        OTHER_CONTACTS: '其他聯絡',
        OTHER_CONTACTS_SELECT: '選擇其他聯絡',
        REVIEW_AT: '檢閱於',
    });
});
