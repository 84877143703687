(function () {
    'use strict';

    angular
        .module('elogbooks.common.sites')
        .controller('FeedController', FeedController);

    FeedController.$inject = ['eventsCollection', 'entityResponse'];

    function FeedController (eventsCollection, entityResponse) {
        var vm = this;
        vm.feed = [];
        vm.settingTemplate = '';
        vm.eventCollectionResponse = eventsCollection;

        if (eventsCollection.events && eventsCollection.events.length > 0) {
            vm.settingTemplate = getSettingEventTemplate(eventsCollection.events[0].type);
        }

        vm.entityResponse = entityResponse;

        function getSettingEventTemplate(eventType) {
            if (eventType === 'service_provider_feedback_setting_updated') {
                return '/modules/admin/settings/events/service-provider-feedback';
            }
            return '';
        }
    }
})();
