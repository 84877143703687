app.config(function ($translateProvider) {
    $translateProvider.translations('en-gb', {
        FINANCE: 'Finance',
        FINANCE_DASHBOARD: 'Finance Dashboard',
        SUPPLIERS: 'Suppliers',
        SUPPLIER: 'Supplier',
        SUPPLIER_LIST: 'Suppliers',
        SUPPLIER_INFO: 'Supplier Information',
        SUPPLIERS_LIST: 'Suppliers',
        COMPANY_NUMBER: 'Company Number',
        CONTACT_NAME: 'Contact Name',
        VAT_NUMBER: 'VAT Number',
        SUPPLIER_EDIT: 'Supplier',
        SUPPLIER_ADD: 'Add Supplier',
        SUPPLIER_DELETED: 'Supplier Deleted',
        SUPPLIER_UPDATED: 'Supplier Updated',
        SUPPLIER_CREATED: 'Supplier Created',
        SUPPLIER_CONTACT_NAME: 'Supplier Contact Name',
        SUPPLIER_EMAIL: 'Supplier Email',
        SUPPLIER_TELEPHONE: 'Supplier Phone',
        SUPPLIER_ADDRESS: 'Supplier Address',
        SUPPLIER_INFORMATION: 'Supplier Information',

        PURCHASE_INVOICES: 'Purchase Invoices',
        PURCHASE_INVOICES_LIST: 'Purchase Invoices',
        PURCHASE_INVOICE: 'Purchase Invoice',
        INVOICES_INFO: 'Info',
        INVOICES: 'Invoices',

        PURCHASE_INVOICES_ADD: 'Add new Purchase Invoice',
        PURCHASE_INVOICES_EDIT: 'Edit Purchase Invoice',
        PURCHASE_INVOICES_CREATED: 'Purchase Invoice Created',
        PURCHASE_INVOICES_UPDATED: 'Purchase Invoice Updated',
        PURCHASE_INVOICES_DELETED: 'Purchase Invoice Deleted',

        PURCHASE_INVOICE_ITEMS_ADD: 'Add Items',
        PURCHASE_INVOICE_EDIT_ITEMS_ADD: 'Edit Items',
        PURCHASE_INVOICE_EDIT_ITEMS_LIST: 'Items',
        PURCHASE_INVOICE_ITEMS_LIST: 'Items',

        INVOICE_NUMBER: 'Invoice Number',
        AMOUNT: 'Amount',
        INVOICE_VALUE_MISMATCH: "The Invoice value does not match total value from Invoice Items",
        INVOICE_DATE: 'Invoice Date',
        INVOICE_DATE_BETWEEN: 'Invoice Date Between',
        CREATED_DATE: 'Created Date',
        UPDATED_DATE: 'Updated Date',

        INVOICE_STATUS_DRAFT: 'Draft',
        INVOICE_STATUS_IN_QUERY: 'In Query',
        INVOICE_STATUS_CANCELLED: 'Cancelled',
        INVOICE_STATUS_READY_FOR_PAYMENT: 'Ready For Payment',
        INVOICE_STATUS_UNPAID: 'Unpaid',
        INVOICE_STATUS_PAID: 'Paid',
        INVOICE_STATUS_INVOICED: 'Invoiced',

        PURCHASE_ORDERS: 'Purchase Orders',
        PURCHASE_ORDER: 'Purchase Order',
        PURCHASE_ORDER_LIST: 'Purchase Orders',

        PURCHASE_ORDER_STATUS_DRAFT: 'Draft',
        PURCHASE_ORDER_STATUS_ORDERED: 'Ordered',
        PURCHASE_ORDER_STATUS_CANCELLED: 'Cancelled',
        PURCHASE_ORDER_STATUS_PART_RECEIVED: 'Part Received',
        PURCHASE_ORDER_STATUS_COMPLETED: 'Completed',

        PURCHASE_ORDER_ADD: 'Add new Purchase Order',
        PURCHASE_ORDER_EDIT: 'Edit Purchase Order',
        PURCHASE_ORDER_CREATED: 'Purchase Order Created',
        PURCHASE_ORDER_UPDATED: 'Purchase Order Updated',
        PURCHASE_ORDER_DELETED: 'Purchase Order Deleted',

        ITEMS_LIST: 'Items',
        ITEM_CODE: 'Item Code',
        UNIT_PRICE: 'Unit Price',
        ITEM_ADD: 'Add Item',
        PURCHASE_ORDER_ITEMS_ADD: 'Add Items',
        PURCHASE_ORDER_EDIT_ITEMS_ADD: 'Edit Items',
        PURCHASE_ORDER_EDIT_ITEMS_LIST: 'Items',
        PURCHASE_ORDER_ITEMS_LIST: 'Items',
        ITEM_SUCCESSFULLY_CREATED: 'Item(s) Created Successfully',
        ITEM_SUCCESSFULLY_DELETED: 'Item Deleted Successfully',
        ITEM_SUCCESSFULLY_UPDATED: 'Item(s) Edited Successfully',

        TYPE_SUBCONTRACTOR: 'Subcontractor',
        TYPE_MATERIALS: 'Materials',
        TYPE_EQUIPMENT_HIRE: 'Equipment Hire',
        TYPE_OTHER_COST: 'Other Cost',

        PART_RECEIVED: 'Mark as Part Received',
        MARK_AS_PAID: 'Mark as Paid',
        MARK_AS_INVOICED: 'Send',
        MARK_AS_ISSUED: 'Issue Credit',
        MARK_AS_CANCELLED: 'Cancel',

        PURCHASE_ORDER_WORKFLOW_ORDER_SUCCESS: 'Purchase Order Ordered',
        PURCHASE_ORDER_WORKFLOW_CANCEL_SUCCESS: 'Purchase Order Cancelled',
        PURCHASE_ORDER_WORKFLOW_PART_RECEIVED_SUCCESS: 'Purchase Order Marked as Part Received',
        PURCHASE_ORDER_WORKFLOW_COMPLETE_SUCCESS: 'Purchase Order Completed',
        PURCHASE_ORDER_WORKFLOW_REOPEN_SUCCESS: 'Purchase Order Reopened',

        INVOICE_WORKFLOW_MARK_AS_PAID: 'Mark as Paid',
        INVOICE_WORKFLOW_MARK_AS_PAID_SUCCESS: 'Invoice Marked as Paid',
        INVOICE_WORKFLOW_MARK_AS_INVOICED_SUCCESS: 'Invoice Marked as invoiced',
        INVOICE_WORKFLOW_REOPEN_SUCCESS: 'Invoice Reopened',

        RAISE_PURCHASE_ORDER: 'Raise a Purchase Order',

        USER_PERMISSION_MANAGE_SALES_INVOICE: 'Manage Sales Invoices',
        OPERATIVE_PERMISSION_VIEW_SALES_INVOICE: 'View Sales Invoices',
        OPERATIVE_PERMISSION_CREATE_SALES_INVOICE: 'Create Sales Invoices',
        OPERATIVE_PERMISSION_EDIT_SALES_INVOICE: 'Edit Sales Invoices',
        SITE_PERMISSION_VIEW_SALES_INVOICE: 'View Sales Invoices',
        SITE_PERMISSION_CREATE_SALES_INVOICE: 'Create Sales Invoices',
        SITE_PERMISSION_EDIT_SALES_INVOICE: 'Edit Sales Invoices',
        SITE_PERMISSION_CREATE_SALES_INVOICE_DETAIL: 'The ability to create sales invoices',
        SITE_PERMISSION_EDIT_SALES_INVOICE_DETAIL: 'The ability to edit sales invoices',
        SITE_PERMISSION_VIEW_SALES_INVOICE_DETAIL: 'The ability to view sales invoices',
        USER_PERMISSION_MANAGE_SALES_INVOICE_DETAIL	: 'The ability to manage sales invoices',
        OPERATIVE_PERMISSION_CREATE_SALES_INVOICE_DETAIL: 'The ability to create sales invoices',
        OPERATIVE_PERMISSION_EDIT_SALES_INVOICE_DETAIL: 'The ability to edit sales invoices',
        OPERATIVE_PERMISSION_VIEW_SALES_INVOICE_DETAIL: 'The ability to view sales invoices',
        EXTERNAL_INVOICE_NUMBER: 'External Invoice Number',
        SALES_INVOICE: 'Sales Invoice',
        SALES_INVOICES: 'Sales Invoices',
        SALES_INVOICES_LIST: 'Sales Invoices',
        CLIENT_PURCHASE_ORDER: 'Client PO',
        BILLING_SETTINGS_NAME: 'Billing Entity Name',
        BILLING_SETTINGS_RELATION: 'Billing Entity Name',
        SALES_INVOICE_EDIT_ITEMS_ADD: 'Edit Items',
        SALES_INVOICE_EDIT_ITEMS_LIST: 'Items',

        CREDIT_NOTES: 'Credit Notes',
        CREDIT_NOTE: 'Credit Note',
        CREDIT_NOTES_LIST: 'Credit Notes',
        INVOICE: 'Invoice',
        CREDIT_NOTE_STATUS_DRAFT: 'Draft',
        CREDIT_NOTE_STATUS_ISSUED: 'Issued',
        CREDIT_NOTE_STATUS_CANCELLED: 'Cancelled',
        CREATED_DATE_BETWEEN: 'Created Date Between',
        SALES_INVOICE_ID: 'Sales Invoice ID',

        CREDIT_NOTE_WORKFLOW_MARK_AS_ISSUED_SUCCESS: 'Credit Note Issued',
        CREDIT_NOTE_WORKFLOW_MARK_AS_CANCELLED_SUCCESS: 'Credit Note Cancelled',
        CREDIT_NOTE_WORKFLOW_REOPEN_SUCCESS: 'Credit Note Reopened',

        USER_PERMISSION_CREATE_CREDIT_NOTES: 'Create Credit Notes',
        USER_PERMISSION_CREATE_CREDIT_NOTES_DETAIL: 'The ability to credit credit notes',
        USER_PERMISSION_EDIT_CREDIT_NOTES: 'Edit Credit Notes',
        USER_PERMISSION_EDIT_CREDIT_NOTES_DETAIL: 'The ability to edit credit notes',
        USER_PERMISSION_VIEW_CREDIT_NOTES: 'View Credit Notes',
        USER_PERMISSION_VIEW_CREDIT_NOTES_DETAIL: 'The ability to view credit notes',

        CREDIT_NOTE_EDIT: 'Edit Credit Note',
        CREDIT_NOTE_ADD: 'Add new Credit Note',

        CREDIT_NOTE_INVALID_SALES_INVOICE: 'This ID is not recognised',

        CREDIT_NOTES_ADD: 'Add new Credit Note',
        CREDIT_NOTE_UPDATED: 'Credit Note Updated',
        CREDIT_NOTE_CREATED: 'Credit Note Created',

        USER_PERMISSION_CAN_COMPLETE_UNMATCHED_PURCHASE_ORDERS: 'Can Complete Unmatched Purchase Orders',
        USER_PERMISSION_CAN_COMPLETE_UNMATCHED_PURCHASE_ORDERS_DETAIL: 'The ability to complete unmatched purchase orders',

        FINANCE_DASHBOARD_SALES: "Sales",
        FINANCE_DASHBOARD_WIP: "WIP",
        FINANCE_DASHBOARD_PAID_INVOICES: "Paid invoices",

        FINANCE_DASHBOARD_TOTAL_SALES: 'Total Sales',
        FINANCE_DASHBOARD_TOTAL_SENT: 'Total Invoices Sent',
        TOTAL_COUNT_OF_PAID: 'Total Invoices Paid',
        TOTAL_VALUE_OF_PAID: 'Total Value of Invoices Paid',
        TOTAL_COUNT_OF_SENT: 'Total Invoices Sent',
        TOTAL_VALUE_OF_SENT: 'Total Value of Invoices Sent',
        SALES_ANNUAL_TREND: 'Total Sales Annual Trend',
        FINANCE_STATISTICS_SALES_TOTALS_PAID: 'Total Sales',
        FINANCE_STATISTICS_SALES_TOTALS_SENT: 'Total Invoices Sent',
        FINANCE_STATISTICS_SALES_ANNUAL_TREND: "Total Sales Annual Trend",
        WIP: 'WIP',
        FINANCE_DASHBOARD_PURCHASING: "Purchasing",
    });
});
