(function (){
    'use strict';

    angular
        .module('elogbooks.user.tenants')
        .controller('UserEmailDetailsController', UserEmailDetailsController);

    UserEmailDetailsController.$inject = ['emailLogResponse'];

    function UserEmailDetailsController(emailLogResponse) {
        var vm = this;
    }
})();
