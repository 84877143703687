angular.module('elogbooksDirectives').directive('checkboxMultiSelect', ['lodash', '$document', '$timeout', function (lodash, $document, $timeout) {
    return {
        scope: {
            options: '=',
            selected: '=',
            placeholder: '@',
            disabled:'@?',
            field: '@?',
            orderBy: '@?',
            preselect: '@?'
        },
        templateUrl: '/modules/directives/form-elements/checkbox-multi-select/checkbox-multi-select.html',
        link: function(scope, element, attrs) {

            if(!scope.preselect) {
                angular.forEach(scope.options, function(value) {
                    if (value.checked) {
                        value.checked = false;
                    }
                });
            } else {
                angular.forEach(scope.options, function(option, key) {
                    if (lodash.find(scope.selected, {
                            name: option.name
                        })) {
                        scope.options[key].checked = true;
                    }
                });
            }

            //Events required on change
            scope.raiseEvents = lodash.has(attrs, 'raiseEvents');

            scope.visible = false;

            if (scope.field) {
                angular.forEach(scope.options, function (value, index) {
                    value.name = value[scope.field];
                });
            }

            scope.selectClick = function (option) {
                var selected = {
                    label: option.label || option.name,
                    name: option.name
                };

                if (option.id) {
                    selected.id = option.id;
                }

                if (option.checked) {
                    scope.selected.push(selected);
                } else {
                    scope.selected = lodash.reject(scope.selected, selected);
                }

                if (scope.raiseEvents) {
                    scope.$emit('checkbox-multi-select:select:click', scope.selected);
                }
            }

            $document.on('click', function () {
                if (element.attr('visible') == 'visible') {
                    scope.$apply(
                        function () {
                            scope.visible = false;
                        }
                    );
                    element.attr('visible','');
                }
            });

            scope.hide = function () {
                scope.visible = false;
                element.attr('visible','');
            }

            scope.show = function () {
                scope.visible = true;
                $timeout(function () {
                    element.attr('visible','visible');
                }, 100);

            }

            scope.inputClick = function (event) {
                scope.toggle(event);
                $timeout(function () {
                    document.getElementById('checkbox-input').focus();
                });
            }

            scope.toggle = function (event) {
                if (scope.visible == true) {
                    scope.hide(event);
                } else {
                    scope.show(event);
                }
            }

            scope.dropDownClick = function (event) {
                event.stopPropagation();
            }
        }
    }
}]);
