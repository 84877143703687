(function() {
    'use strict';

    angular
        .module('elogbooks.user.meter-types')
        .controller('MeterTypesDetailsController', MeterTypesDetailsController);

    MeterTypesDetailsController.$inject = ['meterTypeResponse'];
    function MeterTypesDetailsController(meterTypeResponse) {
        var vm = this;
        vm.meterType = meterTypeResponse;
    }
})();
