(function () {
    'use strict';

    angular
        .module('elogbooks.user.reports')
        .controller('SPUsageController', SPUsageController);

    SPUsageController.$inject = ['reportCollection'];

    function SPUsageController(reportCollection) {
        var vm = this;
        vm.reportCollection = reportCollection;
    }
})();