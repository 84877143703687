(function() {
    'use strict';

    angular
        .module('elogbooks.common.quote-requests')
        .controller('CommonQuoteRequestDetailsQuoteDetailsContactsController', CommonQuoteRequestDetailsQuoteDetailsContactsController);

    CommonQuoteRequestDetailsQuoteDetailsContactsController.$inject = ['quoteContactCollectionResponse'];

    function CommonQuoteRequestDetailsQuoteDetailsContactsController(quoteContactCollectionResponse) {
        var vm = this;
        vm.quoteContactCollectionResponse = quoteContactCollectionResponse;
    }
})();
