app.config(function ($translateProvider) {
    $translateProvider.translations('nl', {
        OPERATIVE_PERMISSIONS: 'Operative Permissions',

        ADD_OPERATIVE: 'Add Operative',

        OPERATIVE_PERMISSION_VIEW_ASSETS:	'Bekijk Assets',
        OPERATIVE_PERMISSION_CREATE_QUOTES:	'Maak offertes',
        OPERATIVE_PERMISSION_VIEW_QUOTES:   'Bekijk offertes',
        OPERATIVE_PERMISSION_EDIT_ASSETS:	'Wijzig assets',
        OPERATIVE_PERMISSION_VIEW_TASKS:	'Bekijk taken',
        OPERATIVE_PERMISSION_EDIT_TASKS:	'Wijzig taken',
        OPERATIVE_PERMISSION_CREATE_TASKS:	'Maak taken',
        OPERATIVE_PERMISSION_VIEW_FILES:	'Bekijk bestanden',
        OPERATIVE_PERMISSION_EDIT_FILES:	'Wijzig bestand',
        OPERATIVE_PERMISSION_VIEW_PATROL_INSTANCES:	'View Patrol Instances',
        OPERATIVE_PERMISSION_EDIT_PATROL_INSTANCES:	'Edit Patrol Instances',
        OPERATIVE_PERMISSION_RECEIVE_PATROL_INSTANCES:	'Receive Patrol Instances',
        OPERATIVE_PERMISSION_REASSIGN_JOB_OPERATIVE: 'Reassign Job Operative',

        OPERATIVE_PERMISSION_CREATE_REACTIVE_JOBS: 'Maak opdracht aan',
        OPERATIVE_PERMISSION_CREATE_REACTIVE_JOBS_FOR_OTHER_SERVICE_PROVIDERS: 'Maak opdracht voor andere leveranciers',
        OPERATIVE_PERMISSION_EDIT_CONTRACTS: 'Wijzig Contracten',
        OPERATIVE_PERMISSION_EDIT_JOBS: 'Wijzig taken',
        OPERATIVE_PERMISSION_EDIT_METER_READINGS: 'Wijzig Meter opnames',
        OPERATIVE_PERMISSION_EDIT_METERS: 'Wijzig Meters',
        OPERATIVE_PERMISSION_MANAGE_OPERATIVES: 'Manage Operatives',
        OPERATIVE_PERMISSION_VIEW_AUDITS: 'bekijk Audits',
        OPERATIVE_PERMISSION_RECEIVE_AUDITS: 'Ontvang Audits',
        OPERATIVE_PERMISSION_RECEIVE_ALL_EMAILS: 'Ontvang alle e-mail',
        OPERATIVE_PERMISSION_RECEIVE_CONTRACTS: 'Ontvang contracten',
        OPERATIVE_PERMISSION_RECEIVE_ESCALATIONS: 'Receive Escalations',
        OPERATIVE_PERMISSION_RECEIVE_JOBS: 'Ontvang taken',
        OPERATIVE_PERMISSION_REQUEST_PLANNED_JOBS: 'Vraag planmatig onderhoud op',
        OPERATIVE_PERMISSION_VIEW_CONTRACTS: 'Bekijk contracten',
        OPERATIVE_PERMISSION_VIEW_JOBS: 'Bekijk taken',
        OPERATIVE_PERMISSION_VIEW_METERS: 'Bekijk meters',
        OPERATIVE_PERMISSION_VIEW_SITES: 'Bekijk locaties',
        OPERATIVE_PERMISSION_VIEW_SURVEYS: 'Bekijk Surveys',
        OPERATIVE_PERMISSION_EDIT_SURVEYS: 'Wijzig Surveys',
        OPERATIVE_PERMISSION_CREATE_SURVEYS: 'Maak survey',
        OPERATIVE_PERMISSION_VIEW_CONTACTS: 'Bekijk Contacten',
        OPERATIVE_PERMISSION_VIEW_APPROVERS: 'Bekijk Object Approvers',
        OPERATIVE_PERMISSION_ASSET_USER_IMPORTS: 'Import Assets',
        OPERATIVE_PERMISSION_TASK_USER_IMPORTS: 'Import Taken',
        OPERATIVE_PERMISSION_ASSET_TASK_USER_IMPORTS: 'Import Planning',
        OPERATIVE_PERMISSION_FORCE_COMPLETE_AUDITS: 'Force Complete Audits',
        OPERATIVE_PERMISSION_FORCE_COMPLETE_AUDITS_DETAIL: 'The ability to Force Complete an Audit',

        OPERATIVE_PERMISSION_VIEW_ASSETS_DETAIL:	' ',
        OPERATIVE_PERMISSION_CREATE_QUOTES_DETAIL:	' ',
        OPERATIVE_PERMISSION_VIEW_QUOTES_DETAIL:  ' ',
        OPERATIVE_PERMISSION_EDIT_ASSETS_DETAIL:	' ',
        OPERATIVE_PERMISSION_VIEW_TASKS_DETAIL:	' ',
        OPERATIVE_PERMISSION_EDIT_TASKS_DETAIL:	' ',
        OPERATIVE_PERMISSION_CREATE_TASKS_DETAIL:	' ',
        OPERATIVE_PERMISSION_VIEW_FILES_DETAIL:	' ',
        OPERATIVE_PERMISSION_EDIT_FILES_DETAIL:	' ',
        OPERATIVE_PERMISSION_RECEIVE_AUDITS_DETAIL:	' ',
        OPERATIVE_PERMISSION_VIEW_AUDITS_DETAIL:	' ',
        OPERATIVE_PERMISSION_VIEW_PATROL_INSTANCES_DETAIL:	' ',
        OPERATIVE_PERMISSION_EDIT_PATROL_INSTANCES_DETAIL:	' ',
        OPERATIVE_PERMISSION_RECEIVE_PATROL_INSTANCES_DETAIL:	' ',
        OPERATIVE_PERMISSION_VIEW_APPROVERS_DETAIL: 'Bekijk Object Approvers',

        OPERATIVE_PERMISSION_CREATE_REACTIVE_JOBS_DETAIL: '',
        OPERATIVE_PERMISSION_CREATE_REACTIVE_JOBS_FOR_OTHER_SERVICE_PROVIDERS_DETAIL: '',
        OPERATIVE_PERMISSION_EDIT_CONTRACTS_DETAIL: '',
        OPERATIVE_PERMISSION_EDIT_JOBS_DETAIL: '',
        OPERATIVE_PERMISSION_EDIT_METER_READINGS_DETAIL: 'The ability to create, update and delete Meter Readings for Meters associated with this Service Provider',
        OPERATIVE_PERMISSION_EDIT_METERS_DETAIL: 'The ability to create, update and delete Meters associated with this Service Provider',
        OPERATIVE_PERMISSION_MANAGE_OPERATIVES_DETAIL: '',
        OPERATIVE_PERMISSION_RECEIVE_ALL_EMAILS_DETAIL: '',
        OPERATIVE_PERMISSION_RECEIVE_CONTRACTS_DETAIL: '',
        OPERATIVE_PERMISSION_RECEIVE_JOBS_DETAIL: '',
        OPERATIVE_PERMISSION_REQUEST_PLANNED_JOBS_DETAIL: '',
        OPERATIVE_PERMISSION_VIEW_CONTRACTS_DETAIL: '',
        OPERATIVE_PERMISSION_VIEW_JOBS_DETAIL: '',
        OPERATIVE_PERMISSION_VIEW_METERS_DETAIL: 'The ability to view Meters associated with this Service Provider',
        OPERATIVE_PERMISSION_VIEW_SITES_DETAIL: 'Inherit Site Access from this Service Provider',
        OPERATIVE_PERMISSION_VIEW_SURVEYS_DETAIL: '',
        OPERATIVE_PERMISSION_EDIT_SURVEYS_DETAIL: '',
        OPERATIVE_PERMISSION_CREATE_SURVEYS_DETAIL: '',
        OPERATIVE_PERMISSION_VIEW_CONTACTS_DETAIL: 'The ability to see a Site\'s Contacts\'',
        OPERATIVE_PERMISSION_P2P: 'P2P - Proactis',
        OPERATIVE_PERMISSION_P2P_DETAIL: '',
        OPERATIVE_PERMISSION_ASSET_USER_IMPORTS_DETAIL: 'Import Assets',
        OPERATIVE_PERMISSION_TASK_USER_IMPORTS_DETAIL: 'Import Taken',
        OPERATIVE_PERMISSION_ASSET_TASK_USER_IMPORTS_DETAIL: 'The ability to import tasks and assets',
        OPERATIVE_PERMISSION_REASSIGN_JOB_OPERATIVE_DETAIL: 'The ability to reassign Job Operative',
        OPERATIVE_PERMISSION_JOB_OPERATIVE: 'View Operatives',
        OPERATIVE_PERMISSION_JOB_OPERATIVE_DETAIL: 'Ability to select an operative on job',

        OPERATIVE_PERMISSION_BULK_REASSIGN_OPERATIVE_JOBS: 'Bulk Reassign Job Operatives',
        OPERATIVE_PERMISSION_BULK_REASSIGN_OPERATIVE_JOBS_DETAIL: 'Ability to bulk reassign job operatives.',
        OPERATIVE_PERMISSION_BULK_CANCEL_JOBS: 'Bulk Cancel Jobs',
        OPERATIVE_PERMISSION_BULK_CANCEL_JOBS_DETAIL: 'Ability to bulk cancel jobs.',

        OPERATIVE_PERMISSION_EDIT_METER_REFERENCES: 'Edit Meter References',

        OPERATIVE_PERMISSION_NOTIFY_UPDATES: 'Berichten aan opdrachtcontacten'
    });
});
