(function () {
    'use strict';

    angular
        .module('elogbooks.user.reports')
        .controller('UserReportsResidentialLocationController', UserReportsResidentialLocationController);

    UserReportsResidentialLocationController.$inject = ['$state', '$stateParams', 'lodash', 'sectorCollectionResponse', 'regionCollectionResponse', 'siteGroupsCollectionResponse'];

    function UserReportsResidentialLocationController($state, $stateParams, lodash, sectorCollectionResponse, regionCollectionResponse, siteGroupsCollectionResponse) {
        var vm = this;
        vm.stateParams = $stateParams;
        vm.exportParams = {};
        vm.updateParams = updateParams;

        vm.regionModel = {
            response: regionCollectionResponse,
            link: regionCollectionResponse.getLink('self'),
            responseKeyPath: 'regions',
            selected: findSelectedById(regionCollectionResponse.regions, $stateParams.regionId),
            onRemove: vm.updateParams,
            onSelect: vm.updateParams
        };

        vm.sectorModel = {
            response: sectorCollectionResponse,
            link: sectorCollectionResponse.getLink('self'),
            responseKeyPath: 'sectors',
            selected: findSelectedById(sectorCollectionResponse.sectors, $stateParams.sectorId),
            onRemove: vm.updateParams,
            onSelect: vm.updateParams
        };

        vm.siteGroupModel = {
            response: siteGroupsCollectionResponse,
            link: siteGroupsCollectionResponse.getLink('self'),
            responseKeyPath: 'sitegroups',
            selected: findSelectedById(siteGroupsCollectionResponse.sitegroups, $stateParams.groupId),
            onRemove: vm.updateParams,
            onSelect: vm.updateParams
        };

        function findSelectedById(collection, id) {
            if (!id) { return; }

            var selected = lodash.find(collection, function(item) {
                return item.id === parseInt(id);
            });

            return selected ? {
                value: selected.id,
                title: selected.name,
                object: selected
            } : null;
        }

        function updateParams(item) {
            vm.exportParams[item.name] = item.selected ? item.selected.object.id : null;
        }
    }
})();


