(function() {
    'use strict';

    angular
        .module('elogbooksControllers')
        .controller('ImageModalController', ImageModalController);

    ImageModalController.$inject = ['$state', '$scope', 'config', '$uibModalInstance', '$rootScope', 'itemResponse', 'filesCollectionResponse', 'apiClient', 'messenger', 'requestDataFactory', 'setDefaultImage', 'lodash', 'isSite', '$q'];

    function ImageModalController($state, $scope, config, $uibModalInstance, $rootScope, itemResponse, filesCollectionResponse, apiClient, messenger, requestDataFactory, setDefaultImage, lodash, isSite, $q) {
        var vm = this;
        var firstUploadDefaultImageSetter = false;
        var fileHref;
        var imageDeleting = false;
        var imageSaved = false;
        vm.config = config;
        vm.files = filesCollectionResponse;
        vm.file = {};
        vm.updateManager = {};
        vm.uploadedFile = null;
        vm.uploadDisabled = false;
        vm.defaultSiteModel = {id: ''};
        vm.deleting = false;
        vm.setDefaultImage = setDefaultImage;
        vm.close = close;
        vm.saveAll = saveAll;
        vm.checkFiles = checkFiles;
        vm.createRequest = createRequest;
        vm.cancelUpload = cancelUpload;
        vm.selectedIndex = selectedIndex;
        vm.add = addImage;
        vm.delete = deleteImage;
        vm.update = updateImage;

        // Modal closing at any point
        $scope.$on('modal.closing', function(event) {
            if (!$scope.form.$pristine || imageDeleting) {
                if (!confirm('Are you sure you want to leave this page? Any changes you\'ve made will be lost.')) {
                    event.preventDefault();
                }
                vm.saveAll(false);
            }
            // reload if something was saved or deleted
            if (typeof lodash.find(vm.files, 'deleted') !== 'undefined' || imageSaved) {
                $state.go($state.current, {}, {reload: true});
            }
        });

        // Checks if the files are larger than 5
        function checkFiles() {
            var deleteCount = 0;
            for (var key in vm.files) {
                if (!vm.files.hasOwnProperty(key)) {
                    continue;
                }
                if (vm.files[key].delete) {
                    deleteCount++;
                }
            }
            if ((vm.files.length - deleteCount) > 5) {
                vm.uploadDisabled = true;
            } else if ((vm.files.length - deleteCount) === 0) {
                firstUploadDefaultImageSetter = true;
            } else {
                vm.oneImageExists = vm.files.length === 1;
                vm.uploadDisabled = false;
            }
        };

        vm.checkFiles();

        function createRequest() {
            vm.file = requestDataFactory.createRequest({
                visibility: 'public',
                userTags: [],
                isImage: 1
            });
        };

        vm.createRequest();

        $scope.$watch('vm.uploadedFile', function(newValue) {
            if (newValue) {
                fileHref = newValue._links.resource; // put it here so don't upload with _link object
                vm.file._links.file = lodash.has(newValue, '_links.file') ? newValue._links.file : null;
                vm.file.title = newValue.title || vm.file.title;
                vm.file.caption = newValue.caption || vm.file.caption;
            }
        });

        function getNextDefaultImage() {
            for (var index = 0; index < vm.files.length; index++) {
                if (!vm.files[index]) {
                    continue;
                }
                if (vm.files[index].deleted || vm.files[index].delete) {
                    continue;
                }
                if (!vm.updateManager[index]) {
                    return index;
                }
                if (vm.updateManager[index].request !== 'delete') {
                    return index;
                }
            }
            return false;
        };

        function getCurrentDefaultImage() {
            for (var index = 0; index < vm.files.length; index++) {
                if (!vm.files[index]) {
                    continue;
                }
                if (vm.files[index].defaultSiteImage) {
                    return index;
                }
            }
            return false;
        }

        // Deletes the item from the table
        function deleteItem(index, save) {
            imageDeleting = true;
            var def = $q.defer();
            var link = 'editSiteImage';

            if ($state.current.name.indexOf('meters') >= 0) {
                link = 'delete';
            }

            apiClient.delete(vm.files[index].getLink(link)).then(function(response) {
                if (response) {
                    if (save) {
                        vm.files[index].deleted = true;
                        imageDeleting = false;
                    }
                    def.resolve();
                    vm.checkFiles();
                } else {
                    def.reject();
                }
            });
            return def.promise;
        }

        // Updates the item from the table
        function updateItem(index) {
            var def = $q.defer();

            apiClient.update(vm.files[index].getLink('editSiteImage'), vm.files[index]).then(function(response) {
                if (response) {
                    def.resolve();
                } else {
                    def.reject();
                }
            });

            return def.promise;
        }

        function saveAll(save) {
            var promises = [];
            // We new to update the backend so through the updateManager
            for (var index in vm.updateManager) {
                if (!vm.updateManager.hasOwnProperty(index)) {
                    continue;
                }
                if (!vm.files[index]) {
                    continue;
                }
                delete vm.files[index].tags.count;
                delete vm.files[index].tags._links;
                switch (vm.updateManager[index].request) {
                    case 'create':
                        if (!save) {
                            promises.push(deleteItem(index, save));
                            break;
                        }
                        break;
                    case 'delete':
                        if (!save) {
                            if (!vm.updateManager[index].status) {
                                delete vm.files[index].delete;
                                break;
                            }
                        }
                        promises.push(deleteItem(index, save));
                        break;
                    case 'update':
                        if (!save) {
                            if (vm.updateManager[index].status) {
                                promises.push(deleteItem(index, save));
                                break;
                            }
                        }
                        promises.push(updateItem(index));
                }
            }
            $q.all(promises).then(function() {
                imageSaved = true;
                messenger.success('IMAGES_UPDATED');
            }, function() {
                messenger.error('REQUEST_ERROR');
            });

            vm.updateManager = {};
            $scope.form.$pristine = true;
        };

        function cancelUpload() {
            vm.uploadedFile = null;
            vm.file = null;
            vm.createRequest();
        };

        angular.forEach(vm.files, function(value) {
            // needed for backend validation
            value._links.file = value._links.self;
            value.userTags = [];
            if (!setDefaultImage) {
                return;
            }
            if (value.defaultSiteImage) {
                vm.defaultSiteModel.id = value.id;
            }
        });

        function selectedIndex(index) {
            vm.files[index].defaultSiteImage = 1;
        };

        function addImage() {
            if (firstUploadDefaultImageSetter && setDefaultImage) {
                vm.file.defaultSiteImage = true;
            }
            if (vm.uploadedFile === null) {
                messenger.error('NO_IMAGE_SELECTED');
                return;
            }
            apiClient.create(itemResponse.getLink('images'), vm.file).then(function(response) {
                if (response) {
                    messenger.success('IMAGE_UPLOAD');
                    apiClient.get(vm.file._links.file.href).then(function(response) {
                        var index = vm.files.length;
                        vm.file = response;
                        vm.file._links.resource = fileHref;
                        vm.file.userTags = [];
                        vm.files.push(vm.file);
                        vm.updateManager[index] = {
                            request: 'create',
                            status: 'new'
                        };
                        if (firstUploadDefaultImageSetter) {
                            vm.defaultSiteModel.id = vm.file.id;
                            firstUploadDefaultImageSetter = false;
                        }
                        $scope.form.$pristine = false;
                        vm.cancelUpload();
                        vm.checkFiles();
                    });

                } else {
                    messenger.error('REQUEST_ERROR');
                }
            });
        };

        // Removes item from display and sets the item
        function deleteImage(index) {
            if (Object.keys(vm.files[index]).length) {
                if (!vm.updateManager[index]) {
                    vm.updateManager[index] = {};
                }
                vm.updateManager[index].request = 'delete';
                if (vm.files[index].defaultSiteImage && setDefaultImage) {
                    vm.files[index].defaultSiteImage = false;
                    var newDefault = getNextDefaultImage();
                    if (newDefault === false) {
                        firstUploadDefaultImageSetter = true;
                    } else {
                        vm.update(newDefault, true);
                    }
                }
                vm.files[index].delete = true;
                vm.checkFiles();
            }
            $scope.form.$pristine = false;
        };

        function updateImage(index, addDefault) {
            if (addDefault) {

                var currentDefault = getCurrentDefaultImage();
                if (currentDefault !== false) {
                    vm.files[currentDefault].defaultSiteImage = false;
                    if (!vm.updateManager[currentDefault]) {
                        if (!vm.updateManager[currentDefault]) {
                            vm.updateManager[currentDefault] = {};
                        }
                        vm.updateManager[currentDefault].request = 'update';
                    }
                }
                vm.files[index].defaultSiteImage = true;
                vm.defaultSiteModel.id = vm.files[index].id;
            }
            if (!vm.updateManager[index]) {
                vm.updateManager[index] = {};
            }
            vm.updateManager[index].request = 'update';
        };

        // Clicked the close button
        function close() {
            $uibModalInstance.dismiss();
        };
    }
})();