(function() {
    'use strict';

    angular
        .module('elogbooks.user.escalations')
        .controller('EscalationDetailsEventFeedController', EscalationDetailsEventFeedController);

    EscalationDetailsEventFeedController.$inject = ['eventCollectionResponse', 'messageCollectionResponse', 'config'];

    function EscalationDetailsEventFeedController (eventCollectionResponse, messageCollectionResponse, config) {
        var vm = this;
        vm.feed = [];
        vm.messageCollectionResponse = messageCollectionResponse;
        vm.eventCollectionResponse = eventCollectionResponse;
        vm.config = config;
    }
})();
