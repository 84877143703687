(function () {
    'use strict';

    angular
        .module('elogbooks.user.timesheets')
        .config(registerRoutes)
        .config(registerDetails)
        .config(registerAdd);

    var timesheetsParams = {
        page: '1',
        limit: '30',
        dateRange: null,
        user: null,
        status: { array: true },
        approver: null
    };

    function registerRoutes($stateProvider) {
        $stateProvider
            .state('dashboard.user.timesheets', {
                url: '/timesheets',
                abstract: true,
                template: '<bookerize></bookerize>',
                ncyBreadcrumb: {
                    skip: true
                }
            })
            .state('dashboard.user.timesheets.list', {
                url: '/list?' + Object.keys(timesheetsParams).join('&'),
                parent: 'dashboard.user.timesheets',
                views: parseBook({
                    'elogbooks-center@dashboard.user.timesheets': {
                        templateUrl: '/modules/user/timesheets/timesheets-list.html',
                        controller: 'UserTimesheetsListController',
                        controllerAs: 'vm'
                    }
                }),
                resolve: {
                    timesheetsCollectionResponse: function (apiClient, user, $stateParams) {
                        var params = angular.extend({}, $stateParams, {
                            'status[]': $stateParams.status,
                            user: $stateParams.user ? JSON.parse($stateParams.user).v : null,
                            approver: $stateParams.approver ? JSON.parse($stateParams.approver).v : null
                        });
                        return apiClient.get(user.getLink('timesheetweeks'), params).then(function (response) {
                            return response || apiClient.noResourceFound();
                        });
                    },
                    userCollectionResponse: function (apiClient, timesheetsCollectionResponse) {
                        return apiClient.get(timesheetsCollectionResponse.getLink('timesheetweek-users')).then(function (response) {
                            return response || apiClient.noResourceFound();
                        });
                    },
                    approverCollectionResponse: function (apiClient, timesheetsCollectionResponse) {
                        return apiClient.get(timesheetsCollectionResponse.getLink('timesheetweek-approvers')).then(function (response) {
                            return response || apiClient.noResourceFound();
                        });
                    }
                },
                params: timesheetsParams,
                ncyBreadcrumb: {
                    label: '{{ ::"TIMESHEETS" | translate }}'
                }
            });
    }

    function registerDetails($stateProvider) {
        $stateProvider
            .state('dashboard.user.timesheets.list.details', {
                url: '/details/{timesheetsResource}',
                parent: 'dashboard.user.timesheets.list',
                abstract: false,
                views: {
                    // Main frame
                    '@dashboard.user.timesheets': {
                        templateUrl: '/modules/user/timesheets/details/details.html',
                        controller: 'UserTimesheetsDetailsController',
                        controllerAs: 'vm'
                    },

                    // Details - left part
                    'details.info@dashboard.user.timesheets.list.details': {
                        templateUrl: '/modules/user/timesheets/details/details/details-info.html',
                        controller: 'UserTimesheetsInfoDetailsController',
                        controllerAs: 'vm'
                    },

                    // Status bar
                    'status@dashboard.user.timesheets.list.details': {
                        templateUrl: '/modules/user/timesheets/details/status/status.html',
                        controller: 'UserTimesheetsDetailsStatusController',
                        controllerAs: 'vm'
                    },

                    // Content - Items
                    'items@dashboard.user.timesheets.list.details': {
                        templateUrl: '/modules/user/timesheets/details/items/items.html',
                        controller: 'UserTimesheetsItemsDetailsController',
                        controllerAs: 'vm'
                    },

                    // Audit Trail
                    'feed@dashboard.user.timesheets.list.details': {
                        templateUrl: '/modules/user/timesheets/details/event/feed.html',
                        controller: 'UserTimesheetsEventFeedController',
                        controllerAs: 'vm'
                    },
                },
                resolve: {
                    config: function () {
                        return []
                    },
                    userResponse: function () {
                        return []
                    },
                    timesheetWeek: function ($stateParams, apiClient, base64, timesheetsService) {
                        return apiClient.get(base64.decode($stateParams.timesheetsResource)).then(function (response) {
                            timesheetsService.resetValues();
                            if (response) {
                                timesheetsService.setTimesheetWeek(response);
                            }
                            return response || apiClient.noResourceFound();
                        });
                    },
                    timesheetData: function (apiClient, timesheetWeek, moment, timesheetsService) {
                        var startDate = moment(new Date(timesheetWeek.startWeekDate));
                        var endDate = moment(new Date(timesheetWeek.startWeekDate)).add(6, 'days');
                        endDate.set({
                            hour:   23,
                            minute: 59,
                            second: 59
                        });

                        return apiClient.get(timesheetWeek.getLink('data'), {
                            dateRange: startDate.format()+','+endDate.format(),
                            operative: timesheetWeek._links.user.id,
                            order: 'site'
                        }).then(function (response) {
                            timesheetsService.resetValues();
                            if (response) {
                                timesheetsService.setTimesheetsDataCollection(response);
                            }
                            return response || apiClient.noResourceFound();
                        });
                    },
                    eventCollectionResponse: function (apiClient, timesheetWeek) {
                        if (timesheetWeek.getLink('events')) {
                            return apiClient.get(timesheetWeek.getLink('events'), { datagroup: 'list' }).then(function(response) {
                                return response || apiClient.noResourceFound();
                            });
                        }
                    },
                    labourTypeCollectionResponse: function (apiClient, rootResourceResponse) {
                        if (rootResourceResponse.getLink('labourtypes')) {
                            return apiClient.get(rootResourceResponse.getLink('labourtypes')).then( function(response) {
                                return response || null;
                            });
                        }
                    }
                },
                ncyBreadcrumb: {
                    label: "{{ ::($resolves().timesheetWeek._links.user.title) + ' [' + ($resolves().timesheetWeek.startDate | date: 'shortDate') + ' - ' + ($resolves().timesheetWeek.endDate | date: 'shortDate') + ']'  }}"
                }
            })
            .state('dashboard.user.timesheets.list.details.submit', {
                url: '/submit',
                parent: 'dashboard.user.timesheets.list.details',
                views: parseBook({
                    'elogbooks-center@dashboard.user.timesheets': {
                        templateUrl: '/modules/user/timesheets/details/workflow/wrapper.html',
                        controller: 'UserTimesheetsSubmitRequestController',
                        controllerAs: 'vm'
                    }
                }),
                ncyBreadcrumb: {
                    label: '{{ ::"SUBMIT" | translate }}'
                }
            })

            .state('dashboard.user.timesheets.list.details.approve', {
                url: '/approve',
                parent: 'dashboard.user.timesheets.list.details',
                views: parseBook({
                    'elogbooks-center@dashboard.user.timesheets': {
                        templateUrl: '/modules/user/timesheets/details/workflow/wrapper.html',
                        controller: 'UserTimesheetsApproveRequestController',
                        controllerAs: 'vm'
                    }
                }),
                ncyBreadcrumb: {
                    label: '{{ ::"APPROVE" | translate }}'
                }
            })
            .state('dashboard.user.timesheets.list.details.reject', {
                url: '/reject',
                parent: 'dashboard.user.timesheets.list.details',
                views: parseBook({
                    'elogbooks-center@dashboard.user.timesheets': {
                        templateUrl: '/modules/user/timesheets/details/workflow/wrapper.html',
                        controller: 'UserTimesheetsRejectRequestController',
                        controllerAs: 'vm'
                    }
                }),
                ncyBreadcrumb: {
                    label: '{{ ::"REJECT" | translate }}'
                }
            })
            .state('dashboard.user.timesheets.list.details.create-entry-line', {
                url: '/create-entry-line',
                parent: 'dashboard.user.timesheets.list.details',
                views: parseBook({
                    'elogbooks-center@dashboard.user.timesheets': {
                        templateUrl: '/modules/user/timesheets/details/workflow/add-line-entry/add-line-entry.html',
                        controller: 'UserTimesheetsAddLineEntryController',
                        controllerAs: 'vm'
                    }
                }),
                resolve: {
                    timesheetWeek : function(timesheetWeek) {
                        return timesheetWeek;
                    },
                    timesheetData: function(timesheetData) {
                        return timesheetData;
                    },
                    timesheetTypesCollectionResponse: function($stateParams, apiClient, rootResourceResponse) {
                        return apiClient.get(rootResourceResponse.getLink('timesheettypes'), $stateParams).then( function(response) {
                            return response || apiClient.noResourceFound();
                        });
                    }
                },
                ncyBreadcrumb: {
                    label: '{{ ::"CREATE_TIMESHEET_MISC_ENTRY" | translate }}'
                }
            })
    }

    function registerAdd($stateProvider) {
        $stateProvider
            .state('dashboard.user.timesheets.list.add', {
                url: '/add',
                parent: 'dashboard.user.timesheets.list',
                views: parseBook({
                    '@dashboard.user.timesheets': {
                        templateUrl: '/modules/user/timesheets/add-timesheet/add-timesheet.html',
                        controller: 'UserTimesheetsAddTimesheetController',
                        controllerAs: 'vm'
                    }
                }),
                resolve: {
                    timesheetsCollectionResponse : function(timesheetsCollectionResponse) {
                        return timesheetsCollectionResponse;
                    },
                    userCollectionResponse: function (apiClient, rootResourceResponse) {
                        return apiClient.getPage(rootResourceResponse.getLink('users'), {status: 'active'});
                    },
                    timesheetUserCollectionResponse: function (apiClient, timesheetsCollectionResponse) {
                        return apiClient.get(timesheetsCollectionResponse.getLink('timesheetweek-users'), { status: 'active' }).then(function (response) {
                            return response || apiClient.noResourceFound();
                        });
                    },
                },
                ncyBreadcrumb: {
                    label: '{{ ::"TIMESHEET_ADD" | translate }}'
                }
            })

    }
})();
