/**
 * Contract Types list controller
 */
 (function() {
     'use strict';

     angular
        .module('elogbooks.admin.contract-types')
        .controller('ContractTypesController', ContractTypesController);

    ContractTypesController.$inject = ['$state', '$stateParams', 'contractTypeCollectionResponse', 'lodash'];

    function ContractTypesController($state, $stateParams, contractTypeCollectionResponse, lodash) {

        var vm = this;

        vm.contracttypes = contractTypeCollectionResponse.getData().contracttypes;
        vm.pages = contractTypeCollectionResponse.getData().pages;
        vm.listVisible = true;

        vm.criteria = {
            id: { type: 'number', title: 'ID', value: $stateParams.id },
            name: { type: 'text', title: 'NAME', value: $stateParams.name },
            description: { type: 'text', title: 'DESCRIPTION', value: $stateParams.description }
        };

        vm.search = function() {
            var params   = lodash.zipObject(lodash.keys(vm.criteria), lodash.map(vm.criteria, 'value'));
            var override = {
                page:       1
            };

            $state.go('.', angular.extend({}, params, override), { reload: true });
        };
    };

 })();
