 (function() {
     'use strict';

     angular
        .module('elogbooks.admin.job-requisites')
        .controller('JobRequisitesController', JobRequisitesController);

    JobRequisitesController.$inject = ['$state', '$stateParams', '$filter', 'jobRequisiteCollectionResponse'];

    function JobRequisitesController($state, $stateParams, $filter, jobRequisiteCollectionResponse) {
        var vm = this;
            vm.jobRequisites = jobRequisiteCollectionResponse.jobRequisites;
            vm.jobRequisiteCollectionResponse = jobRequisiteCollectionResponse;

        vm.search = search;
        vm.order = order;

        vm.criteria = {
            id: { type: 'text', title: 'ID', value: $stateParams.id },
            name: { type: 'text', title: 'NAME', value: $stateParams.name },
            description: { type: 'text', title: 'DESCRIPTION', value: $stateParams.description }
        };

        function search (params) {
            var override = {
                page: 1
            };

            $state.go('.', angular.extend({}, params, override), { reload: true });
        }

        function order (key) {
            $stateParams.order = key;

            var override = {
                page: 1
            };

            $state.go('.', angular.extend({}, $stateParams, override), { reload: true });
        }
    }

 })();
