(function() {
    'use strict';

    angular
        .module('elogbooks.common.actions')
        .controller('CommonActionAddSiteController', CommonActionAddSiteController);

    CommonActionAddSiteController.$inject = ['$rootScope', '$scope', '$state', 'siteCollectionResponse', 'siteResponse', 'apiClient'];

    function CommonActionAddSiteController($rootScope, $scope, $state, siteCollectionResponse, siteResponse, apiClient) {
        var vm = $scope.vm;
        vm.canNext = true;
        vm.canSubmit = false;
        vm.title = 'ACTION_ADD_SITE';

        vm.next = nextAction;

        vm.selectedSiteModel = {
            response: siteCollectionResponse,
            link: siteCollectionResponse ? siteCollectionResponse.getLink('self') : null,
            required: true,
            responseKeyPath: 'sites',
            itemHrefPath: '_links.self.href',
            itemValuePath: 'name',
            onSelect: changeSite,
            onRemove: changeSite,
            disabled: vm.config.siteDisabled,
            populateDisabled: true
        };

        if (vm.selectedSite) {
            vm.selectedSiteModel.selected = {
                object: vm.selectedSite,
                href: vm.selectedSite.getLink('self'),
                value: vm.selectedSite.name
            };
        } else if (siteResponse) {
            vm.submitDisabled = true;
            vm.selectedSiteModel.selected = {
                object: siteResponse,
                href: siteResponse.getLink('self'),
                value: siteResponse.name
            };

            vm.redirectToStart = false;
            $state.go(vm.getRoute('details'), {siteResource: siteResponse.getLink('self').encode()}, {reload: false})
                .then(function () {
                    vm.submitDisabled = false;
                });
        }

        function changeSite() {
            if (vm.selectedSiteModel.selected && vm.selectedSiteModel.selected.object) {
                vm.selectedSite = vm.selectedSiteModel.selected.object;
            }
        }

        function nextAction() {
            vm.redirectToStart = false;
            $state.go(vm.getRoute('details'), {siteResource: vm.selectedSite.getLink('self').encode()}, {reload: false});
        }
    }
})();

