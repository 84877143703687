(function () {
    'use strict';
    
    /**
    * Tenant Service
    */
    angular.module('elogbooksServices').service('tenantService', function (apiClient, $q, $filter) {
        this.get = getTenant;
        this.create = createTenant;
        this.update = updateTenant;

        /**
         * get a tenant
         * @returns {promise}
         */
        function getTenant(link) {
            var deferred = $q.defer();

            apiClient.get(link).then(function (response) {
                if (response) {

                    return deferred.resolve(response);
                } else {

                    return deferred.reject($filter('translate')('UNABLE_TO_GET_TENANT'));
                }
            });

            return deferred.promise;
        };

        /**
         * Create tenant
         *  - contact fields sent if a name is present (user overrides contact fields)
         * 
         * @params {object} site
         * @params {string} name
         * @params {object} membership 
         * @params {string} notes
         * @params {array} contactFields [{type, value}]
         * @params {array} locations 
         * @returns {promise}
         */
        function createTenant(site, name, membership, notes, contactFields, locations) {
            var deferred = $q.defer();

            var requestObject = {
                type: 'tenant',
                notes: notes,
                locations: { locations: [] },
            };
            // Inlude user
            processUser(requestObject, name, membership);
            // Include locations
            processLocations(requestObject, locations);
            // Include contact fields if name supplied
            if (name) {
                processContactFields(requestObject, contactFields);
            }
            // Create the tenant
            apiClient.create(site.getLink('contacts'), requestObject)
                .then(function (response) {
                    if (response) {

                        return deferred.resolve(response);
                    } else {

                        return deferred.reject($filter('translate')('UNABLE_TO_CREATE_TENANT'));
                    }
                });

            return deferred.promise;
        }
        
       /**
         * Update tenant
         *  - contact fields sent if a name is present (user overrides contact fields)
         * 
         * @params {string} contactLink contact href
         * @params {string} name
         * @params {object} membership 
         * @params {string} notes
         * @params {array} contactFields [{type, value}]
         * @params {array} locations 
         * @returns {promise}
         */
        function updateTenant(contactLink, name, membership, notes, contactFields, locations) {
            var deferred = $q.defer();

            var requestObject = {
                notes: notes,
                locations: { locations: [] }
            };
            // Inlude user
            processUser(requestObject, name, membership);
            // Include locations
            processLocations(requestObject, locations);
            // Include contact fields if name supplied
            if (name) {
                processContactFields(requestObject, contactFields);
            }
            // Update the tenant
            apiClient.update(contactLink, requestObject)
                .then(function (response) {
                    if (response) {

                        return deferred.resolve(response);
                    } else {

                        return deferred.reject($filter('translate')('UNABLE_TO_UPDATE_TENANT'));
                    }
                });

            return deferred.promise;
        }

        function processContactFields(requestObject, contactFields) {
            if (contactFields.length === 0) {
                return;
            }
            
            requestObject.contactInformation = { fields: [] };
            
            for (var index = 0; index < contactFields.length; index++) {
                var element = angular.extend({}, { type: null, value: null }, contactFields[index]);
                // Only include contact fields with a type and element
                if (element.value && element.type) {
                    requestObject.contactInformation.fields.push(element);
                }
            }
            
            return requestObject;
        }

        function processLocations(requestObject, locations) {
            for (var index = 0; index < locations.length; index++) {
                var element = locations[index];
                requestObject.locations.locations.push(element._links.self);
            }
            
            return requestObject;
        }
        
        function processUser(requestObject, name, membership) {
            if (name) {
                requestObject.name = name;
            } else {
                requestObject._links = { user: { href:  membership._links.user.href }};
            }
  
            return requestObject;
        }

        return this;
    });
})();
