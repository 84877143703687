(function () {
    'use strict';

    angular
        .module('elogbooks.user.reports')
        .controller('MonthlyReportsAttendedMissedCompletionController', MonthlyReportsAttendedMissedCompletionController);

    MonthlyReportsAttendedMissedCompletionController.$inject = ['params', 'collection', '$translate', '$state', '$stateParams'];

    function MonthlyReportsAttendedMissedCompletionController(params, collection, $translate, $state, $stateParams) {
        var vm = this;
        vm.type = $stateParams.modalType;
        vm.key = vm.type === 'quotes' ? 'quotes' : 'jobs';
        vm.collection = collection;
        vm.items = collection[vm.key];
        vm.exportParams = params;
        vm.listTitle = '';
        vm.stateParams = $stateParams;

        switch ($stateParams.modalType) {
            case 'reactive_attendance':
                vm.listTitle = $state.current.name.indexOf('missed') > 0
                    ? $translate.instant('REACTIVE_MISSED_ATTENDANCE')
                    : $translate.instant('REACTIVE_ATTENDANCE');
                break;
            case 'reactive_completion':
                vm.listTitle = $state.current.name.indexOf('missed') > 0
                    ? $translate.instant('REACTIVE_MISSED_COMPLETION')
                    : $translate.instant('REACTIVE_COMPLETION');
                break;
            case 'planned_attendance':
                vm.listTitle = $state.current.name.indexOf('missed') > 0
                    ? $translate.instant('PPM_MISSED_ATTENDANCE')
                    : $translate.instant('PPM_ATTENDANCE');
                break;
            case 'planned_completion':
                vm.listTitle = $state.current.name.indexOf('missed') > 0
                    ? $translate.instant('PPM_MISSED_COMPLETION')
                    : $translate.instant('PPM_COMPLETION');
                break;
            case 'quotes':
                vm.listTitle = $state.current.name.indexOf('missed') > 0
                    ? $translate.instant('QUOTES_MISSED')
                    : $translate.instant('QUOTES_COMPLETION');
                break;
        }
    }
})();
