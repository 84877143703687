(function () {
    'use strict';

    angular
        .module('elogbooks.user.reports')
        .controller('UserReportsListController', UserReportsListController);

    UserReportsListController.$inject = ['$state', 'userManager', 'siteAssociateAlias', '$translate', 'modulesService'];

    function UserReportsListController($state, userManager, siteAssociateAlias, $translate, modulesService) {
        var vm = this;
        vm.userManager = userManager;
        vm.reports = [
            {
                name: 'SERVICE_PROVIDER_SUMMARY',
                route: 'dashboard.user.reports.list.view.sp-summary',
                permission: 'user_permission_report_sp_summary',
                params: {}
            },
            {
                name: siteAssociateAlias + ' ' + $translate.instant('ACTIVITY_REPORT'),
                route: 'dashboard.user.reports.list.view.site-associate-activity',
                permission: 'user_permission_report_site_associate_activity', 
                params: {}
            },
            {
                name: $translate.instant('AGE_OF_APPROVALS'),
                route: 'dashboard.user.reports.list.view.approval-age',
                permission: 'user_permission_report_approval_age',
                params: {}
            },
            {
                name: $translate.instant('SUMMARY_REPORT'),
                route: 'dashboard.user.reports.list.view.summary.details',
                permission: 'user_permission_report_summary',
                params: {}
            },
            {
                name: $translate.instant('PPM_GAP_ANALYSIS'),
                route: 'dashboard.user.reports.list.view.ppm-gap-analysis',
                permission: 'user_permission_report_ppm_gap_analysis',
                params: {}
            },
            {
                name: $translate.instant('OPEN_CLOSE'),
                route: 'dashboard.user.reports.list.view.open-close',
                permission: 'user_permission_report_open_close',
                params: {}
            },
            {
                name: $translate.instant('MONTHLY_REPORT'),
                route: 'dashboard.user.reports.list.view.monthly',
                permission: 'user_permission_report_monthly',
                params: {}
            },
            {
                name: $translate.instant('KPI_MONTHLY_REPORT'),
                route: 'dashboard.user.reports.list.view.kpi-monthly',
                permission: 'user_permission_report_kpi_monthly',
                params: {}
            },
            {
                name: $translate.instant('ENGINEER_RISK_REPORT'),
                route: 'dashboard.user.reports.list.view.engineering-risk',
                permission: 'user_permission_report_engineering_risk',
                params: {}
            },
            {
                name: $translate.instant('PORTFOLIO_COMPLIANCE_REPORT'),
                route: 'dashboard.user.reports.list.view.portfolio-compliance',
                permission: 'user_permission_report_portfolio_compliance',
                params: {}
            },
            {
                name: $translate.instant('AUDIT_TREND_REPORT'),
                route: 'dashboard.user.reports.list.view.audit-trend',
                permission: 'user_permission_report_audit_trend',
                params: {}
            },
            {
                name: $translate.instant('SP_USAGE'),
                route: 'dashboard.user.reports.list.view.sp-usage',
                permission: 'user_permission_report_sp_usage',
                params: {}
            },
            {
                name: $translate.instant('RESIDENTIAL_LOCATION'),
                route: 'dashboard.user.reports.list.view.residential-location',
                permission: 'user_permission_report_residential_location',
                params: {}
            },
            {
                name: $translate.instant('OPERATIVE_PERFORMANCE'),
                route: 'dashboard.user.reports.list.view.operative-performance',
                permission: 'user_permission_report_operative_performance',
                params: {}
            },
            {
                name: $translate.instant('SERVICE_PROVIDER_FEEDBACK_RATING'),
                route: 'dashboard.user.reports.list.view.service-provider-feedback',
                permission: 'user_permission_report_service_provider_feedback_ratings',
                module: 'sp_feedback',
                params: {}
            }
        ];

        vm.userReports = userReports(vm.reports);

        function userReports (reports) {
            var userReports = [];
            angular.forEach(reports, function(report) {
                const moduleAccess = report.hasOwnProperty('module') ? modulesService.isEnabled(report.module) : true;

                if(userManager.hasPermission(report.permission) && moduleAccess) {
                    userReports.push(report);
                }
            });

            return userReports;
        }
    }
})();

