(function () {
    'use strict';

    angular
        .module('elogbooks.common.webhooks')
        .controller('WebhooksListController', WebhooksListController);

    WebhooksListController.$inject = ['$state', '$stateParams', '$filter', 'webhooksCollection', 'lodash', 'confirmationModal', 'apiClient', 'messenger', 'webhookCreateType'];

    function WebhooksListController ($state, $stateParams, $filter, webhooksCollection, lodash, confirmationModal, apiClient, messenger, webhookCreateType) {
        var vm = this;
        vm.webhooksCollection = webhooksCollection;
        vm.webhooks = webhooksCollection.webhooks;
        vm.webhooks._links = webhooksCollection._links;
        vm.webhooks.canCreate = canCreateWebhooks();
        vm.entityCount = webhooksCollection.count;
        vm.order = order;
        vm.search = search;

        vm.criteria = {};

        function order (key) {
            $stateParams.webhookOrder = key;

            var override = {
                webhookPage: 1
            };

            $state.go('.', angular.extend({}, $stateParams, override), { reload: $state.current });
        }

        function search (params) {
            var override = {
                webhookPage: 1
            };

            $state.go('.', angular.extend({}, params, override), { reload: $state.current });
        }

        function canCreateWebhooks() {
            return !!vm.webhooksCollection.getLink(webhookCreateType);
        }
    }
})();
