(function () {
    'use strict';

    angular
        .module('elogbooks.admin.job-requisites')
        .controller('JobRequisitesPartnershipsController', JobRequisitesPartnershipsController);

    JobRequisitesPartnershipsController.$inject = ['jobRequisiteResponse', 'selectedPartnershipCollectionResponse', '$stateParams', '$state', 'lodash', 'confirmationModal', 'apiClient', 'messenger'];

    function JobRequisitesPartnershipsController (jobRequisiteResponse, selectedPartnershipCollectionResponse, $stateParams, $state, lodash, confirmationModal, apiClient, messenger) {
        var vm = this;
            vm.jobRequisite = jobRequisiteResponse;
            vm.selectedPartnershipCollectionResponse = selectedPartnershipCollectionResponse;
            vm.partnerships = selectedPartnershipCollectionResponse.partnerships;
            vm.entityCount = selectedPartnershipCollectionResponse.count;
            vm.search = search;
            vm.order = order;
            vm.deleteSelected = deleteAction;

        vm.criteria = {
            partnershipSite: { type: 'text', value: $stateParams.partnershipSite, title: 'SITE' },
            partnershipService: { type: 'text', value: $stateParams.partnershipService, title: 'SERVICE_PROVIDER' }
        }

        function search (params) {
            var override = {
                partnershipPage: 1
            };

            $state.go('.', angular.extend({}, params, override), { reload: true });
        }

        function order (key) {
            $stateParams.partnershipOrder = key;

            var override = {
                partnershipPage: 1
            };

            $state.go('.', angular.extend({}, $stateParams, override), { reload: true });
        }

        function deleteAction() {
            var toRemove = [];
            lodash.forEach(vm.checked, function(value, partnership) {
                if(value === true) {
                    toRemove.push({"href": partnership});
                }
            });

            if (toRemove.length > 0) {
                var data = {
                    _links: {}
                };

                data._links.partnerships = toRemove;

                // Show an alert asking the user to confirm
                return confirmationModal.open().result.then(function () {
                    return apiClient.update(vm.jobRequisite.getLink('partnerships'), data).then(function (response) {
                        if (response) {
                            $state.go('.', {}, { reload: $state.current.name }).then(function () {
                                messenger.success('PARTNERSHIPS_DELETED_FROM_JOB_REQUISITE');
                            });
                        } else {
                            messenger.error('REQUEST_ERROR');
                        }
                    });
                });
            } else {
                // Show an alert telling the user they need to select at least one site
                return confirmationModal.open({
                    titleMessage: 'ZERO_OPTIONS_CHECKED_TITLE',
                    bodyMessage: 'ZERO_OPTIONS_CHECKED_MESSAGE',
                    primaryText: null,
                    secondaryText: 'OK'
                });
            }
        }
    }

})();
