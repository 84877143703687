(function () {
    'use strict';

    angular
        .module('elogbooksDirectives')
        .directive('focusFirstField', function ($timeout, $parse) {
            return {
                restrict: 'A',
                link: function ($scope, $element, $attrs) {
                    if ($attrs.focusFirstField === "false") {
                        return false;
                    }

                    var mergedArray = [];
                    var selects;
                    var inputs;
                    var textareas;

                    mergeNodes();

                    var attemptsLeft = parseInt($attrs.focusFirstFieldTimeoutAttempts);
                    if (isNaN(attemptsLeft)) attemptsLeft = 5;

                    function mergeNodes() {
                        mergedArray = [];
                        selects = $element.find('select');
                        inputs = $element.find('input');
                        textareas = $element.find('textarea');

                        if (inputs.length) {
                            for (var input in inputs) {
                                if (inputs[input].localName && input !== 'context') {
                                    mergedArray.push(inputs[input]);
                                }
                            }
                        }
                        if (selects.length) {
                            for (var select in selects) {
                                if (selects[select].localName && select !== 'context') {
                                    mergedArray.push(selects[select]);
                                }
                            }
                        }
                        if (textareas.length) {
                            for (var textarea in textareas) {
                                if (textareas[textarea].localName && textarea !== 'context') {
                                    mergedArray.push(textareas[textarea]);
                                }
                            }
                        }
                    }

                    function go() {
                        var stop = false;

                        mergedArray.sort(function (a, b) {
                            //Because the result returned by compareDocumentPosition is a bitmask, the bitwise and operator has to be used for meaningful results.
                            if (a.compareDocumentPosition(b) & Node.DOCUMENT_POSITION_PRECEDING) {
                                return 1;
                            } else {
                                return -1;
                            }
                        });

                        angular.forEach(mergedArray, function (input) {
                            if (input.type !== 'search'
                                && input.type !== 'file'
                                && !input.disabled) {
                                if (!stop) {
                                    $timeout(function () {
                                        input.focus();
                                    });
                                    stop = true;
                                }
                            }
                        });
                    }

                    function attempt() {
                        $timeout(function () {
                            mergeNodes();
                            if (--attemptsLeft >= 0) {
                                check();
                            }
                        }, 100);
                    }

                    function check() {
                        if (inputs.length || selects.length || textareas.length) {
                            go();
                        }
                        else {
                            attempt();
                        }
                    }

                    if ($parse($attrs.focusFirstFieldTrigger).assign) {
                        $scope.$watch($attrs.focusFirstFieldTrigger, function (newValue, oldValue) {
                            if (newValue !== oldValue) {
                                //TODO bit unnecessary to keep doing this, find a way to not rescan all the time.
                                $timeout(function () {
                                    mergeNodes();
                                    check();
                                }, 100);
                            }
                        });
                    }
                    // should be else here, but adding trigger stops the check, and certain nginclude templates might use the trigger or not so...
                    $timeout(check);
                }
            };
        });
})();