app.config(function ($translateProvider) {
    $translateProvider.translations('nl', {
        JOB_CONTACT: 'Contactpersoon opdracht',
        JOB_CONTACTS: 'Contactpersonen opdracht',

        JOB_CONTACT_LIST: 'Contactpersonen opdracht',
        JOB_CONTACT_ADD: 'Toevoegen',

        JOB_CONTACT_CREATED: 'Contactpersoon aangemaakt',
        JOB_CONTACT_UPDATED: 'Contactpersoon aangepast',
        JOB_CONTACT_DELETED: 'Contactpersoon verwijderd',
    });
});
