/**
 * Material Rate types
 */
angular.module('elogbooksServices').service('workRequiredRateTypes', function() {
    this.getAllForSelect = getAllForSelect;

    var rateTypes = {
        in: "In Hours",
        out: "Out of Hours"
    };

    this.getAll = function () {
        return rateTypes;
    };

    function getAllForSelect() {
        return [
            {value: 'in', title: 'In Hours'},
            {value: 'out', title: 'Out of Hours'}
        ];
    }

    return this;
});
