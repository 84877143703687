app.config(function ($translateProvider) {
    $translateProvider.translations('en-gb', {
        THRESHOLD: 'Threshold',
        THRESHOLDS: 'Thresholds',
        THRESHOLD_LIST: 'Thresholds',

        QUOTE_THRESHOLD_CREATED: 'Quote Threshold Created',
        QUOTE_THRESHOLD_UPDATED: 'Quote Threshold Updated',
        QUOTE_THRESHOLD_DELETED: 'Quote Threshold Deleted',

        THRESHOLD_ADD: 'Add a new Threshold',
        THRESHOLD_EDIT: 'Edit Threshold',
    });
});
