angular
    .module('elogbooksDirectives')
    .directive('elbCallbackPagination', callbackPagination);

callbackPagination.$inject = [];


function callbackPagination() {
    return {
        restrict: 'AE',
        templateUrl: '/modules/directives/elb-callback-pagination/pagination.html',
        controller: controller,
        scope: {
            pageChangeCallback: '=',
            currentPage: '=',
            itemsCount: '=',
            limit: '='
        }
    };

    function controller ($scope) {
        $scope.pagination = {
            currentPage: $scope.currentPage
        };

        $scope.$watch('currentPage', function (newValue, oldValue) {
            if (newValue !== oldValue) {
                $scope.pagination.currentPage = newValue;
            }
        });

        $scope.pageChangedAction = function() {
            $scope.currentPage = $scope.pagination.currentPage;
            $scope.pageChangeCallback($scope.currentPage);
        }
    }
}
