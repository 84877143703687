app.config(function ($translateProvider) {
    $translateProvider.translations('en-gb', {

        SITE_GROUP: 'Site Group',
        SITE_GROUPS: 'Site Groups',

        SITE_GROUP_LIST: 'Site Groups',
        SITE_GROUP_ADD: 'Add a new Site Group',
        SITE_GROUP_EDIT: 'Edit Site Group',
        EDIT_SITE_GROUP: 'Edit Site Group',

        SITE_GROUP_SITE_LIST: 'Site Group Sites',

        ADD_NEW_SITE_GROUP: 'Add a new Site Group',

        MANAGE_SITE_GROUP_SITES: 'Manage Sites',
        SITE_GROUP_SITE_ADDED: 'Site added to Site Group',
        SITES_DELETED_FROM_SITE_GROUP: 'Site deleted from Site Group',

        SITE_GROUP_CREATED: 'Site Group Created',
        SITE_GROUP_UPDATED: 'Site Group Updated',
        SITE_GROUP_DELETED: 'Site Group Deleted',

        SITE_GROUP_DOCUMENT_SETS_LIST: 'Site Group Document Sets'
    });
});
