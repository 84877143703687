(function() {
    'use strict';

    angular
        .module('elogbooks.common.quote-requests')
        .controller('CommonQuoteRequestDetailsQuoteDetailsOptionDetailsLabourController', CommonQuoteRequestDetailsQuoteDetailsOptionDetailsLabourController);

    CommonQuoteRequestDetailsQuoteDetailsOptionDetailsLabourController.$inject = ['quoteOptionLabourCollectionResponse', 'modulesService'];

    function CommonQuoteRequestDetailsQuoteDetailsOptionDetailsLabourController(quoteOptionLabourCollectionResponse, modulesService) {
        var vm = this;
        vm.quoteOptionLabourCollectionResponse = quoteOptionLabourCollectionResponse;
        vm.financeEnabled = modulesService.isEnabled('finance');
    }
})();
