app.config(function ($translateProvider) {
    $translateProvider.translations('en-gb', {
        OPERATIVE: 'Operative',
        OPERATIVES: 'Operatives',

        OPERATIVES_LIST: 'Operatives',
        OPERATIVES_ADD: 'Add an Operative',

        REMOVE_OPERATIVE: 'Remove Operative',

        CREATE_NEW_OPERATIVE: 'Create New Operative',
        ADD_SERVICE_PROVIDER: 'Add Service Provider',
        MANAGE_OPERATIVES: 'Manage Operatives',
        OPERATIVE_INFORMATION:'Operative Information',
        CREATE_OPERATIVE: 'Create Operative',
        ACTIVITY_LOG: 'Activity Log',
        USER_IS_ALREADY_OPERATIVE: 'This user is already an operative',
        COPY_OPERATIVE: 'Copy from existing Operative',
        COPY: 'Copy',
        SERVICE_PROVIDER_REQUIRED: 'Service Provider is required',

        SOURCE_TYPE: 'Source Type',
        SOURCE_ID: 'Source ID',
        ACTIVITY_VIEW: 'Activity',

        LABOUR : 'Labour'

    });
});
