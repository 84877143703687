(function () {
    'use strict';

    angular
        .module('elogbooks.user.spillages')
        .controller('SpillageReassignController', SpillageReassignController);

    SpillageReassignController.$inject = ['apiClient', '$state', 'messenger', 'spillageResponse', 'operativeCollectionResponse', 'formData', 'scopeData', '$translate', '$q', 'lodash', 'user'];

    function SpillageReassignController (apiClient, $state, messenger, spillageResponse, operativeCollectionResponse, formData, scopeData, $translate, $q, lodash, user) {
        var vm = this;
        vm.spillage = spillageResponse;
        angular.extend(vm, scopeData);

        vm.submit = submitAction;
        vm.changeOperative = changeOperative;
        vm.data = formData;
        vm.operativeSelectModel.onSelect = vm.operativeSelectModel.onRemove = vm.changeOperative;

        function submitAction () {
            if (vm.data.currentOperative
                && vm.data.currentOperativeLink === vm.data._links.operative.href) {
                return;
            }

            var currentState = $state.current.name;
            var parentListState = currentState.substring(0, currentState.lastIndexOf('.list') + 5);
            var reloadState = parentListState;

            return apiClient.create(vm.spillage.getLink(vm.action), vm.data).then(function (response) {
                if (response) {
                    return $state.go('^.^', {}, { reload: reloadState }).then(function () {
                        messenger.success(vm.translation + '_SUCCESS');
                    });
                }

                messenger.error('REQUEST_ERROR');
            });
        }

        function changeOperative () {
            if (
                vm.operativeSelectModel.selected
                && typeof vm.operativeSelectModel.selected !== 'undefined'
            ) {
                vm.data._links.operative = vm.operativeSelectModel.selected;
            } else {
                vm.operativeSelectModel.selected = null;
                delete vm.data._links.operative;
            }
        }
    }
})();
