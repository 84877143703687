;(function() {
    'use strict';

    angular
        .module('elogbooks.common.jobs.workflow')
        .controller('JobValueController', JobValueController);

    JobValueController.$inject = ['$filter', '$state', 'crudService', 'jobResponse', 'elbSettings'];

    function JobValueController($filter, $state, crudService, jobResponse, elbSettings) {
        var vm = this;
        vm.data = {valueLabour: null, valueMaterial: null, invoiceDate: new Date};
        vm.jobResponse = jobResponse;
        vm.currency = 'fa fa-' + elbSettings.getSetting('currency').value.toLowerCase();
        vm.cisIsSet = typeof vm.jobResponse.attributes.value_cis !== 'undefined';

        vm.yesNo = [
            { value: true, title: $filter('translate')('YES') },
            { value: false, title: $filter('translate')('NO') }
        ];

        vm.submit = submitAction;

        function submitAction() {
            return crudService
                .update(
                    jobResponse.getLink('value-add'),
                    vm.data,
                    $state.get('^.^.^'),
                    $state.get('^.^.^.^.^'),
                    'JOB_VALUE'
                );
        }
    }
})();
