;(function() {
    'use strict';

    angular
        .module('elogbooks.common.jobs.workflow')
        .controller('JobManagementFeeActionController', JobManagementFeeActionController);

    JobManagementFeeActionController.$inject = ['$state', 'messenger', 'crudService', 'jobResponse', 'config'];

    function JobManagementFeeActionController($state, messenger, crudService, jobResponse, config) {
        var vm = this;
        vm.jobResponse = jobResponse;
        vm.data = {note: null};
        vm.config = config;
        vm.closed = true;
        vm.translation = config.type.toUpperCase() + '_MANAGEMENT_FEE';
        vm.noteRequired = true;

        vm.submit = submitAction;

        function submitAction() {
            return crudService
                .create(
                    jobResponse.getLink(config.type + '-management-fee'),
                    vm.data,
                    null,
                    null,
                    null,
                    true
                ).then(function() {
                    messenger.success('JOB_' + config.type.toUpperCase() + '_MANAGEMENT_FEE');
                    $state.go($state.get('^.^'), {}, {reload: $state.get('^.^.^')});
                });
        }
    }
})();
