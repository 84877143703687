(function () {
    'use strict';

    angular
        .module('elogbooks.admin.sites')
        .controller('PartnershipSurveyAddController', PartnershipSurveyAddController);

    PartnershipSurveyAddController.$inject = ['$state', 'apiClient', 'messenger', 'partnershipResponse', 'surveysResponse', 'surveysRootResponse', 'lodash', 'crudService'];

    function PartnershipSurveyAddController($state, apiClient, messenger, partnershipResponse, surveysResponse, surveysRootResponse, lodash, crudService) {
        var vm = this;
        vm.data = {};
        vm.survey = {};
        vm.survey.surveys = null;
        vm.surveyResponse = surveysResponse;
        vm.surveys = surveysResponse.surveys;

        vm.surveysAll = surveysRootResponse.surveys;
        vm.create = updatePartnershipSurveys;

        vm.surveyOptions = [];

        var existingLinks = [];

        // existing partnership survey links
        lodash.each(vm.surveys, function (existingSurvey) {
            existingLinks.push(existingSurvey._links.self.href);
        });

        // get all surveys, exclude existing partnership surveys
        lodash.each(vm.surveysAll, function (systemSurvey) {

            if(existingLinks.indexOf(systemSurvey._links.self.href) === -1) {
                vm.surveyOptions.push({ value: systemSurvey._links.self, title:systemSurvey.name });
            }
        });

        function updatePartnershipSurveys() {
            vm.data = {
                _links: {
                    surveys: []
                }
            };

            // included existing
            lodash.each(vm.surveys, function (existingSurvey) {
                vm.data._links.surveys.push(existingSurvey._links.self);
            });

            // add new
            vm.data._links.surveys.push({'href' : vm.survey.surveys.href});
            return apiClient.update(vm.surveyResponse.getLink('self'), vm.data).then(function (response) {
                if (response) {
                    $state.go('^', {}, { reload: $state.get('^.^') }).then(function (response) {
                        messenger.success('PARTNERSHIP_SURVEYS_ADDED');
                    });
                } else {
                    messenger.error('REQUEST_ERROR');
                }
            });
        };
    }
})();
