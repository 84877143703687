(function () {
    'use strict';

    angular
        .module('elogbooks.common.jobs')
        .controller('CommonPurchaseOrdersController', CommonPurchaseOrdersController);

    CommonPurchaseOrdersController.$inject = ['purchaseOrdersCollectionResponse'];

    function CommonPurchaseOrdersController(purchaseOrdersCollectionResponse) {
        var vm = this;
        vm.purchaseOrdersCollection = purchaseOrdersCollectionResponse;
        vm.purchaseOrders = purchaseOrdersCollectionResponse.purchaseOrders;
    }
})();
