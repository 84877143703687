(function() {
    'use strict';

    angular
        .module('elogbooks.common.contracts')
        .controller('CommonContractDetailsController', CommonContractDetailsController);

    CommonContractDetailsController.$inject = ['contractResponse', 'selectedSiteResponse', 'siteResponse'];

    function CommonContractDetailsController(contractResponse, selectedSiteResponse, siteResponse) {
        var vm = this;
        vm.contract = contractResponse.getData();
        vm.siteResponse = siteResponse;
        vm.showP2P = contractResponse.getLink('nominalcodings');
    }
})();
