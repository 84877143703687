(function() {
    'use strict';

    angular
        .module('elogbooks')
        .config(Translations);

    function Translations($translateProvider) {
        $translateProvider.translations('en-gb', {
            ACTION_WORKFLOW_REJECT_JOB_PAPERWORK: 'Reject Paperwork',
            ACTION_WORKFLOW_REJECT_JOB_PAPERWORK_SUBMIT: 'Reject Paperwork',
            ACTION_WORKFLOW_CANCEL: 'Cancel',
            ACTION_WORKFLOW_COMPLETE: 'Complete',
            ACTION_WORKFLOW_REOPEN: 'Reopen',
            ACTION_WORKFLOW_REASSIGN: 'Reassign',

            ACTION_WORKFLOW_REJECT_SUBMIT: 'Reject',
            ACTION_WORKFLOW_CANCEL_SUBMIT: 'Submit',
            ACTION_WORKFLOW_COMPLETE_SUBMIT: 'Complete',
            ACTION_WORKFLOW_REOPEN_SUBMIT: 'Reopen',
            ACTION_WORKFLOW_REASSIGN_SUBMIT: 'Reassign',

            ACTION_WORKFLOW_CANCEL_SUCCESS: 'Action Cancelled',
            ACTION_WORKFLOW_COMPLETE_SUCCESS: 'Action Completed',
            ACTION_WORKFLOW_REOPEN_SUCCESS: 'Action Reopened',
            ACTION_WORKFLOW_REASSIGN_SUCCESS: 'Action Reassigned',
            ACTION_WORKFLOW_REJECT_JOB_PAPERWORK_SUCCESS: 'Paperwork Rejected',
            SEND_TO_MERIDIAN: 'Send to Meridian',
            MERIDIAN_DOCUMENT_TYPE: 'Meridian Document Type',
            MERIDIAN_FILE_HAS_BEEN_SENT: 'This file has been sent to Meridian',
            MERIDIAN_FILE_HAS_BEEN_SENT_EDIT_DISABLED: 'This file has been sent to Meridian and is no longer editable',
            OBSERVATION: 'Observation',
            RECOMMENDATION: 'Recommendation',
            LOAD_SITE: 'Please wait we are checking site with Meridian',
            NO_PROPERTY_FOUND: 'There is no property found in Meridian with a reference: ',
            MORE_THAN_ONE_PROPERTY: 'There is more than one property found in Meridian with a reference: ',
            P1: 'P1 - Within 30 days',
            P2: 'P2 - Within 60 days',
            P3: 'P3 - Within 90 days',
            P4: 'P4 - Within 120 days'
        });
    }
})();
