(function() {
    'use strict';

    angular
        .module('elogbooks.user.reports')
        .controller('SPUsageTabsController', SPUsageTabsController);

    SPUsageTabsController.$inject = ['$state', '$stateParams', 'countCollectionResponse'];

    function SPUsageTabsController($state, $stateParams, countCollectionResponse) {
        var vm = this;
        vm.stateParams = $stateParams;
        vm.exportParams = {
            site: vm.stateParams.site,
            serviceProvider: vm.stateParams.serviceProvider,
            dateRange: vm.stateParams.dateRange
        };

        vm.countCollection = countCollectionResponse;
    }
})();