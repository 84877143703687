(function () {
    'use strict';

    angular
        .module('elogbooks.user.reports')
        .controller('PortfolioComplianceReportsModalController', PortfolioComplianceReportsModalController);

    PortfolioComplianceReportsModalController.$inject = ['serviceProviderGroupCollectionResponse', '$translate', '$state', '$stateParams', 'apiClient', '$uibModalInstance'];

    function PortfolioComplianceReportsModalController(serviceProviderGroupCollectionResponse, $translate, $state, $stateParams, apiClient, $uibModalInstance) {
        var vm = this;
        vm.modalInstance = $uibModalInstance;
        vm.months = Array.apply(0, new Array(12)).map(function(_,i){
            return {
                value: i+1,
                title: $translate.instant(moment().month(i).format('MMMM').toUpperCase())
            }
        });
        vm.serviceProviderGroupSelect = {
            response : serviceProviderGroupCollectionResponse,
            link : serviceProviderGroupCollectionResponse ? serviceProviderGroupCollectionResponse.getLink('self') : null,
            responseKeyPath: 'groups',
            required: true
        };
        vm.form = {
            year: parseInt(moment().format('YYYY')),
            month: parseInt(moment().format('M')),
            errors: 0,
            daysLate: 0
        };
        vm.submit = submit;
        vm.close = dismissModal;

        function dismissModal () {
            vm.modalInstance.close({reload:false});
        }

        function submit () {
            if (vm.serviceProviderGroupSelect.selected) {
                apiClient.create(vm.serviceProviderGroupSelect.selected.href + '/compliance', vm.form).then(function (response) {
                    if (response) {
                        vm.modalInstance.close({reload:true});
                    }
                });
            }
        }
    }
})();
