(function() {
    'use strict';

    angular
        .module('elogbooks.common.site-notes')
        .controller('CommonSiteNotesController', CommonSiteNotesController);

    CommonSiteNotesController.$inject = ['siteNoteCollectionResponse'];

    function CommonSiteNotesController(siteNoteCollectionResponse) {
        var vm = this;
        vm.notes = [];
        vm.shared = [];

        siteNoteCollectionResponse.siteNotes.forEach(function(note) {
            if (note.shared && note.data) {
                vm.shared.push(note);
            } else if (note.data) {
                vm.notes.push(note);
            }
        });
    }
})();
