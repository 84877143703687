angular.module('elogbooksServices').service('fileSizeFormatter', function () {
    return {
        format: function (size) {
            var unit = 'Mb';

            size = size / 1024 / 1024;

            if (size > 0 && size < 0.01) {
                size = 0.01;
            } else if (size > 1024) {
                size = size / 1024;
                unit = 'Gb';
            }

            return {
                size: size.toFixed(2),
                unit: unit
            };
        }
    };
});
