(function() {
    'use strict';

    angular
        .module('elogbooks.common.jobs.add')
        .controller('CommonJobAddContactController', CommonJobAddContactController);

    CommonJobAddContactController.$inject = ['$scope', '$rootScope', '$state', 'apiClient', 'userCollectionResponse', 'siteResourceResponse', 'siteRelationshipResponse', '$q', 'userManager', '$timeout', 'messenger'];

    function CommonJobAddContactController($scope, $rootScope, $state, apiClient, userCollectionResponse, siteResourceResponse, siteRelationshipResponse, $q, userManager, $timeout, messenger) {
        $scope.form.$setPristine();

        var vm = $scope.vm;
        var tenantDetails = {};
        vm.canNext = true;
        vm.canPrevious = true;
        vm.canSubmit = false;
        vm.title = 'JOB_ADD_CONTACT_DETAILS';
        $rootScope.skipDirtyCheck = false;
        vm.submitDisabled = false;
        vm.canSeeTenants = siteResourceResponse.getLink('tenantLogging') !== null;

        if (!vm.data.siteContact) {
            vm.data.siteContact = {};
            vm.data.siteContact.notifyOnCreate  = userManager.getPreference('notificationOnCreateJob').value === 'on';
            vm.data.siteContact.notifyOnComplete = userManager.getPreference('notificationOnCompleteReactiveJob').value === 'on';
            vm.data.siteContact.declineEmail = false;
        }

        if (!vm.data.contactTenant) {
            vm.data.contactTenant = {};
            vm.data.contactTenant.notifyOnCreate  = false;
            vm.data.contactTenant.notifyOnComplete = false;
        }

        $rootScope.forceDirtyConfirm = vm.canPrevious;

        vm.changeUser = changeUser;
        vm.changeTenant = changeTenant;
        vm.changeRelationship = changeRelationship;
        vm.next = nextAction;
        vm.previous = previousAction;
        vm.resetContact = resetContact;
        vm.resetTenantContact = resetTenantContact;
        vm.findPseudoUsers = findPseudoUsers;
        vm.pseudoUserSelected = pseudoUserSelected;
        vm.toggleDeclineEmail = toggleDeclineEmail;
        vm.rePopulateTenantDetails = rePopulateTenantDetails;
        vm.pseudoUserSetInactive = pseudoUserSetInactive;
        vm.toggleSuggestions = toggleSuggestions;

        vm.userContactResponse = {};

        vm.selectedUserContactModel = {
            response: userCollectionResponse,
            link: userManager.user.getLink('users'),
            linkParameters: { 'site': siteResourceResponse.getData().id, status: 'active' },
            required: true,
            responseKeyPath: 'users',
            itemHrefPath: '_links.self.href',
            itemValuePath: 'name',
            onSelect: vm.changeUser,
            onRemove: vm.changeUser,
            disabled: vm.enterContactDetailsManually
        };

        vm.selectedTenantContactModel = {
            response: userCollectionResponse,
            link: siteResourceResponse.getLink('tenantLogging'),
            linkParameters: {'site': siteResourceResponse.getData().id, 'active': 'yes', 'order': 'name'},
            responseKeyPath: 'contacts',
            itemHrefPath: '_links.self.href',
            itemValuePath: 'nameAndCompany',
            onSelect: vm.changeTenant,
            onRemove: vm.changeTenant
        };

        vm.selectedRelationshipModel = {
            response: siteRelationshipResponse,
            link: siteRelationshipResponse.getLink('self'),
            required: true,
            responseKeyPath: 'siterelationships',
            itemHrefPath: '_links.self.href',
            itemValuePath: 'name',
            onSelect: vm.changeRelationship,
            onRemove: vm.changeRelationship
        };

        if (vm.config.isTenant) {
            vm.selectedRelationshipModel.selected = siteRelationshipResponse.siterelationships
                .filter(function (item) {
                    return item.name === 'Occupier';
                }).map(function(response){
                    return {
                        href:response._links.self.href,
                        obj:response,
                        value:response.name
                    };
                })[0];
        }

        if (!vm.config.addReporter) {
            vm.enterContactDetailsManually = true;
        }

        if (!vm.siteNotesConfirmed) {
            $state.go(vm.getRoute('site'), {}, {reload: false});
        }

        if (typeof vm.selectedUserContact !== 'undefined') {
            vm.selectedUserContactModel.selected = {
                object: vm.selectedUserContact,
                href: vm.selectedUserContact.getLink('self'),
                value: vm.selectedUserContact.name
            };

            changeUser();
        }

        if (typeof vm.selectedTenantContact !== 'undefined') {
            vm.selectedTenantContactModel.selected = {
                object: vm.selectedTenantContact,
                href: vm.selectedTenantContact.getLink('self'),
                value: vm.selectedTenantContact.nameAndCompany
            };

            changeTenant();
        }

        if (userManager.hasPermission('site_permission_tenant')) {
            vm.data.siteContactSameAsTenant = true;
            vm.submitDisabled = true;
            userManager.getUser().then(
                function (user) {
                    tenantDetails = {
                        name: user.name,
                        email: user.email,
                        telephone: user.telephone
                    };
                    if (user.hasOwnProperty('email')) {
                        vm.data.siteContact.email = user.email;
                    }
                    if (user.hasOwnProperty('telephone')) {
                        vm.data.siteContact.phone = user.telephone;
                    }
                    if (user.hasOwnProperty('name')) {
                        vm.data.siteContact.name = user.name;
                    }
                    vm.submitDisabled = false;
                }
            );
        }

        function rePopulateTenantDetails() {
            if (vm.data.siteContactSameAsTenant) {
                vm.data.siteContact.name = tenantDetails.name;
                vm.data.siteContact.email = tenantDetails.email;
                vm.data.siteContact.phone = tenantDetails.telephone;
            } else {
                vm.data.siteContact.name = '';
                vm.data.siteContact.email = '';
                vm.data.siteContact.phone = '';
            }
        }

        function resetContact() {
            if (vm.selectedUserContactModel.disabled) {
                vm.selectedUserContactModel.disabled = vm.enterReporterDetailsManually;
                vm.selectedUserContactModel.required = true;
            } else {
                vm.selectedUserContactModel.disabled = true;
                vm.selectedUserContactModel.required = false;
            }

            if (vm.enterContactDetailsManually) {
                vm.data._links.contact = null;
                vm.data.siteContact = null;
            }
        }

        function resetTenantContact () {
            vm.submitDisabled = false;

            vm.selectedUserContactModel = {
                response: userCollectionResponse,
                link: userManager.user.getLink('users'),
                linkParameters: { 'site': siteResourceResponse.getData().id, status: 'active' },
                required: true,
                responseKeyPath: 'users',
                itemHrefPath: '_links.self.href',
                itemValuePath: 'name',
                onSelect: vm.changeUser,
                onRemove: vm.changeUser,
                disabled: vm.enterContactDetailsManually,
                selected: vm.selectedUserContactModel.selected,
                items: []
            };
        }

        function changeUser() {
            if (vm.selectedUserContactModel.selected && vm.selectedUserContactModel.selected.object) {
                vm.selectedUserContact = vm.selectedUserContactModel.selected.object;

                vm.submitDisabled = true;

                $q.all([
                    apiClient.get(vm.selectedUserContact.getLink('self')).then(function(response) {
                        if (typeof vm.data.siteContact === 'undefined' || vm.data.siteContact === null || vm.data.siteContact.id != response.id) {
                            vm.data.siteContact = vm.userContactResponse = response;
                            vm.data.siteContact.phoneNumbers = [];
                            angular.forEach(response.contactInformation.fields, function(value, index) {
                                if (value.type === 'telephone') {
                                    vm.data.siteContact.phoneNumbers.push(value);
                                }
                            });
                        }
                    })
                ]).then(function() {
                    vm.data._links.contact = vm.selectedUserContact.getLink('self');
                    vm.data.siteContact.phone = vm.data.siteContact.telephone;
                    vm.submitDisabled = false;
                });
            } else {
                vm.data._links.contact = vm.data.siteContact = null;
            }
        }

        function changeTenant () {
            if (vm.selectedTenantContactModel.selected && vm.selectedTenantContactModel.selected.object) {
                vm.selectedTenantContact = vm.selectedTenantContactModel.selected.object;

                vm.selectedRelationshipModel.selected = siteRelationshipResponse.siterelationships
                    .filter(function (item) {
                        return item.name === 'Occupier';
                    }).map(function(response){
                        return {
                            href:response._links.self.href,
                            object:response,
                            value:response.name
                        };
                    })[0];
                changeRelationship();

                vm.submitDisabled = true;

                $q.all([
                    apiClient.get(vm.selectedTenantContact.getLink('self')).then(function (response) {
                        if (typeof vm.data.contactTenant === 'undefined' || vm.data.contactTenant === null || vm.data.contactTenant.id != response.id) {
                            vm.data.contactTenant = vm.tenantContactResponse = response;
                            vm.data.contactTenant.phoneNumbers = [];
                            angular.forEach(response.contactInformation.fields, function (value, index) {
                                if (value.type === 'telephone') {
                                    vm.data.contactTenant.phoneNumbers.push(value);
                                }
                            });
                        }
                    })
                ]).then(function () {
                    vm.data._links.contactTenant = vm.selectedTenantContact.getLink('self');

                    if (vm.data.contactTenant.contactInformation.fields.length > 0) {
                        var contactInformation = vm.data.contactTenant.contactInformation.fields;
                        angular.forEach(contactInformation, function (contact) {
                            if (contact.type === 'email') {
                                vm.data.contactTenant.email = contact.value;
                            }
                            if (contact.type === 'telephone') {
                                vm.data.contactTenant.phone = contact.value;
                            }
                        });
                    } else {
                        vm.data.contactTenant.email = vm.selectedTenantContact.email;
                        vm.data.contactTenant.phone = vm.selectedTenantContact.phone;
                    }

                    vm.submitDisabled = false;
                });
            } else {
                vm.selectedRelationshipModel.selected = null;
                vm.data._links.contactTenant = vm.data.contactTenant = null;
            }
        }

        function toggleSuggestions(hide) {
            $timeout(function(){
                if (!vm.loading && vm.data.siteContact && vm.data.siteContact.name && vm.data.siteContact.name.length > 0) {
                    vm.hideSuggestions = hide;
                }
            }, 100);
        }

        function findPseudoUsers () {
            if (!vm.config.isHelpdesk) {
                return [];
            }

            vm.hideSuggestions = false;
            return apiClient.get(siteResourceResponse.getLink('pseudousers'), { search: true, name: vm.data.siteContact.name, inactive: false })
                .then(function(response) {
                    vm.pseudoUserResults = response['site-pseudo-users'];
                }, function(error) {
                    console.log(error);
                });
        }

        function pseudoUserSetInactive(index) {
            vm.loading = vm.pseudoUserResults[index].loading = true;
            vm.pseudoUserResults[index].loading = true;
            return apiClient.update(vm.pseudoUserResults[index].getLink('site-pseudouser-inactive'), {}).then(function (response) {
                if (response) {
                    vm.pseudoUserResults.splice(index, 1);
                    vm.loading = vm.pseudoUserResults[index].loading = false;
                } else {
                    messenger.error('REQUEST_ERROR');
                }
            });
        }

        function pseudoUserSelected (index) {
            var item = vm.pseudoUserResults[index];

            vm.data.siteContact.name = item.name;
            vm.data.siteContact.email = item.email;
            vm.data.siteContact.phone = item.phone;
            vm.data.siteContact.notifyOnCreate = item.notifyOnCreation;
            vm.data.siteContact.notifyOnComplete = item.notifyOnCompletion;

            var pseudoUserSiteRelationship = siteRelationshipResponse.siterelationships.find(function(elem) {
                return elem.getLink('self') === item.getLink('site-relationship');
            });

            if (pseudoUserSiteRelationship) {
                vm.selectedRelationshipModel.selected = {
                    object: pseudoUserSiteRelationship,
                    href: pseudoUserSiteRelationship.getLink('self'),
                    value: pseudoUserSiteRelationship.name
                };
            }

            changeRelationship();
            vm.pseudoUserResults = [];
        }

        function toggleDeclineEmail () {
            if (vm.data.siteContact.declineEmail) {
                vm.data.siteContact.email = null;
                vm.data.siteContact.notifyOnCreate = false;
                vm.data.siteContact.notifyOnComplete = false;
            }
        }

        function changeRelationship () {
            if (vm.selectedRelationshipModel.selected && vm.selectedRelationshipModel.selected.object) {
                vm.selectedRelationship = vm.selectedRelationshipModel.selected.object;
                vm.data._links.sitecontactsiterelationship = {href: vm.selectedRelationship.getLink('self')};
            } else {
                vm.data._links.sitecontactsiterelationship = null;
            }
        }

        function nextAction() {
            if (vm.data.siteContact && vm.data.siteContact.name && vm.data.siteContact.name.length > 255 ) {
                messenger.error('SITE_CONTACT_INVALID_LENGTH');
                return;
            }
            if (vm.data.keyholder && vm.data.keyholder.name && vm.data.keyholder.name.length > 255) {
                messenger.error('KEYHOLDER_INVALID_LENGTH');
                return;
            }

            if (vm.data.siteContact && vm.data.siteContact.email === '') {
                delete vm.data.siteContact.email;
            }

            $rootScope.skipDirtyCheck = true;
            $state.go(vm.getRoute('details'), {}, {reload: false})
                .then(function() {
                    $rootScope.skipDirtyCheck = false;
                })
        }

        function previousAction() {
            $rootScope.skipDirtyCheck = true;

            var route = vm.config.addReporter ? vm.getRoute('reporter') : vm.getRoute('site');

            $state.go(route, {}, {reload: false}).then(function() {
                $rootScope.skipDirtyCheck = false;
                vm.submitDisabled = false;
            });
        }
    }
})();
