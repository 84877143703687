(function (){
    'use strict';

    angular
        .module('elogbooks.common.meters')
        .controller('UserMetersController', UserMetersController);

    UserMetersController.$inject = ['sectionResource'];

    function UserMetersController(sectionResource) {
        var vm = this;
        vm.sectionResource = sectionResource;
    }
})();
