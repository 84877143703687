(function () {
    'use strict';

    angular
        .module('elogbooks.admin.regions')
        .controller('RegionsSitesEditController', RegionsSitesEditController);

    RegionsSitesEditController.$inject = ['$state', '$stateParams', 'messenger', 'apiClient', 'lodash', 'regionResponse', 'siteCollectionResponse', 'selectedSiteCollectionResponse'];

    function RegionsSitesEditController ($state, $stateParams, messenger, apiClient, lodash, regionResponse, siteCollectionResponse, selectedSiteCollectionResponse) {
        var vm = this;
        vm.region = regionResponse;

        vm.siteSelect = {
            response: siteCollectionResponse,
            link: siteCollectionResponse ? siteCollectionResponse.getLink('self') : null,
            responseKeyPath: 'sites',
            selectedItems: [],
        };

        vm.selectedSites = selectedSiteCollectionResponse.sites;

        if(vm.selectedSites) {
            angular.forEach(vm.selectedSites, function(site) {
                vm.siteSelect.selectedItems.push({href: site.getLink('self'), value: site.name});
            });
        }

        vm.update = updateAction;

        function updateAction() {
            var data = {
                _links: {
                    site: (vm.siteSelect.selectedItems.length) ? vm.siteSelect.selectedItems : []
                }
            };

            apiClient.create(vm.region.getLink('sites'), data).then(function (response) {
                if (response) {
                    $state.go('^', $stateParams, { reload: true }).then(function () {
                        messenger.success('REGION_SITE_ADDED');
                    });
                } else {
                    messenger.error('REQUEST_ERROR');
                }
            });
        }
    }

})();
