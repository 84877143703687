(function() {
    'use strict';

    angular
        .module('elogbooks.user.finance.purchase-invoices')
        .controller('FinanceInvoiceWorkflowAddNoteController', FinanceInvoiceWorkflowAddNoteController);

    FinanceInvoiceWorkflowAddNoteController.$inject = [
        'invoiceResponse',
        'lodash',
        '$state',
        'apiClient',
        'messenger',
        'config'
    ];

    function FinanceInvoiceWorkflowAddNoteController(
        invoiceResponse,
        lodash,
        $state,
        apiClient,
        messenger,
        config
    ) {
        var vm = this;

        vm.type = lodash.last($state.current.name.split('.'));
        vm.title = vm.type.toUpperCase().replaceAll('-', '_');
        vm.data = { note: null };
        vm.config = config;
        vm.invoiceResponse = invoiceResponse;

        vm.create = vm.update = function() {
            return apiClient.create(vm.invoiceResponse.getLink(vm.type), vm.data).then(function (response) {
                if (response) {
                    $state.go('^.^.items', {}, { reload: true }).then(function () {
                        messenger.success(
                            'INVOICE_WORKFLOW_'
                            + vm.title
                            + '_SUCCESS'
                        );
                    });
                } else {
                    messenger.error('REQUEST_ERROR');
                }
            });
        };
    }
})();
