(function () {
    'use strict';

    angular
        .module('elogbooksFilters')
        .filter('minutesToHours', MinutesToHours);

    function MinutesToHours() {
        return function (minutes) {
                var hour = Math.floor(minutes / 60).toString();
                var min = Math.round(minutes % 60) < 10 ? '0'+Math.round(minutes % 60).toString() : Math.round(minutes % 60).toString();
                return hour+':'+min;
            }
        };
})();
