/**
 * SurveyRequest Status label
 */
angular.module('elogbooksDirectives').directive('surveyRequestStatusLabel', ['$http', 'apiClient', '$state', function ($http, apiClient, $state) {
    return {
        restrict: 'AE',
        scope: {
            status: '=ngModel'
        },
        templateUrl: '/modules/directives/survey/survey-request/elements/status/status.html'
    };
}]);
