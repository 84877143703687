(function () {
    'use strict';

    angular
        .module('elogbooks.admin.imports')
        .controller('ScdImportDetailsController', ['$state', '$stateParams', 'importResponse','importLogsCollectionResponse', 'importLogsUniqueKeysResponse', '$translate', 'elbDownloadService', ScdImportDetailsController]);

    ScdImportDetailsController.$inject = ['$state', '$stateParams', 'importResponse','importLogsCollectionResponse', 'importLogsUniqueKeysResponse', '$translate', 'elbDownloadService'];

    function ScdImportDetailsController($state, $stateParams, importResponse, importLogsCollectionResponse, importLogsUniqueKeysResponse, $translate, elbDownloadService) {
        var vm = this;
        vm.import = importResponse;
        vm.importLogsCollection = importLogsCollectionResponse;
        vm.importLogsResponce = importLogsCollectionResponse;
        vm.importLogs = importLogsCollectionResponse.importLogs;
        vm.importLogUniqueKeys = importLogsUniqueKeysResponse.uniqueKeys;
        vm.noBorder = false;

        var typeOptions = [
            { title: $translate.instant('IMPORT_LOG_ALL'), value: null},
            { title: $translate.instant('IMPORT_LOG_ERROR'), value: 'error' },
            { title: $translate.instant('IMPORT_LOG_CHANGE'), value: 'change' },
            { title: $translate.instant('IMPORT_LOG_NO_CHANGE'), value: 'no_change' },
            { title: $translate.instant('IMPORT_LOG_WARN'), value: 'warn' }
        ];

        var createOptions = [
            {
                value: 'create_service_provider',
                title: $translate.instant('SCD_IMPORT_CREATE_SERVICE_PROVIDER')
            },
            {
                value: 'create_service_provider_group',
                title: $translate.instant('SCD_IMPORT_CREATE_SERVICE_PROVIDER_GROUP')
            },
            {
                value: 'create_partnership',
                title: $translate.instant('SCD_IMPORT_CREATE_PARTNERSHIP')
            }
        ];

        var updateOptions = [
            {
                value: 'update_service_provider',
                title: $translate.instant('SCD_IMPORT_UPDATE_SERVICE_PROVIDER')
            },
            {
                value: 'update_service_provider_group',
                title: $translate.instant('SCD_IMPORT_UPDATE_SERVICE_PROVIDER_GROUP')
            },
            {
                value: 'update_partnership',
                title: $translate.instant('SCD_IMPORT_UPDATE_PARTNERSHIP')
            }
        ];

        vm.criteria = {
            logsRow: { type: 'number', title: 'ID', value: $stateParams.logsRow ? parseInt($stateParams.logsRow, 10) : null },
            logsType: { type: 'options', title: 'IMPORT_LOG_TYPE', value: $stateParams.logsType, options: typeOptions},
            logsCreateOptions: { type: 'options', title: 'CREATE_OPTIONS', multiple: true, value: $stateParams.logsCreateOptions, options: createOptions },
            logsUpdateOptions: { type: 'options', title: 'UPDATE_OPTIONS', multiple: true, value: $stateParams.logsUpdateOptions, options: updateOptions },
            logsMessage: { type: 'text', title: 'IMPORT_MESSAGE', value: $stateParams.logsMessage}
        };


        if (vm.importLogUniqueKeys.length > 0) {
            var keyOptions = vm.importLogUniqueKeys.map(function(arr){ return {value: arr.name, title: arr.name} });
            vm.criteria.logsKeyOptions = { type: 'options', title: 'KEY_OPTIONS', multiple: true, value: $stateParams.logsKeyOptions, options: keyOptions }
        }

        vm.search = search;
        vm.download = download;

        /**
         * Search tasks
         */
        function search(params) {
            var override = {
                logsPage: 1
            };

            $state.go('.', angular.extend({}, params, override), { reload: $state.current });
        }

        function download(file) {
            elbDownloadService.download(file);
        }
    }
})();
