(function () {
    'use strict';

    angular
        .module('elogbooksDirectives')
        .directive('elbMultiSelect', ['$translate', 'lodash', 'dashboardService', ElogbooksMultiSelect]);

    function ElogbooksMultiSelect ($translate, lodash, dashboardService) {
        return {
            restrict: 'AE',
            scope: {
                elements: '=',
                title: '@',
                placeholder: '@',
                titleIcon: '@',
                appliedElements: '=',
                entityElements: '=',
                updateAction: '=',
                selectedElements: '=',
                entity: '@',
                editable: '@'
            },
            templateUrl: function() {
                return '/modules/directives/elb-select-multi/select-multi.html';
            },
            link: function ($scope, element, attr) {
                $scope.addElement = addElement;
                $scope.addElements = addElements;
                $scope.removeElement = removeElement;
                $scope.entity = attr.entity;
                $scope.appliedElements[attr.entity] = [];
                $scope.selectedElements[attr.entity] = [];
                $scope.entityElements = $scope.entityElements || [];

                angular.forEach($scope.entityElements, function (element) {
                    if(lodash.find($scope.elements, { label: element })) {
                        addElement(element);
                    }
                });

                function addElement (element) {
                    addElements([{ label: element }], true, false);
                }

                function addElements (elements, force, sendUpdate) {
                    angular.forEach(elements, function (element) {
                        var index = $scope.entityElements.indexOf(element.label);

                        if (index === -1 || force) {
                            var newElement = {
                                label: element.label,
                                translation: dashboardService.translateAssetScoreType(element.label),
                                description: dashboardService.translateAssetScoreType(element.label, 'detail')
                            };

                            if (index === -1) {
                                $scope.entityElements.push(newElement.label);
                            }

                            $scope.appliedElements[attr.entity].push(newElement);

                            angular.forEach($scope.elements, function (element, index) {
                                if (element.label === newElement.label) {
                                    $scope.elements.splice(index, 1);
                                }
                            });
                        }
                    });

                    // run page controller updateAction after initial element load
                    if(sendUpdate && elements.length) {
                        $scope.updateAction(attr.entity);
                    }
                }

                function removeElement (element) {
                    var index = $scope.entityElements.indexOf(element);

                    if (index > -1) {
                        $scope.entityElements.splice(index, 1);

                        angular.forEach($scope.appliedElements[attr.entity], function (appliedElement, index) {
                            if (appliedElement.label === element) {
                                $scope.appliedElements[attr.entity].splice(index, 1);
                            }
                        });

                        var oldElement = {
                            label: element,
                            name: dashboardService.translateAssetScoreType(element),
                            translation: dashboardService.translateAssetScoreType(element),
                            description: dashboardService.translateAssetScoreType(element, 'detail')
                        };

                        $scope.elements.push(oldElement);

                        // trigger updateAction
                        $scope.updateAction(attr.entity);
                    }
                }
            }
        };
    }
})();