app.config(function ($translateProvider) {
    $translateProvider.translations('nl', {
        MONTH: 'Maand',
        YEAR: 'Jaar',
        DAILY: 'Dagelijks',
        WEEKLY: 'Wekelijks',
        MONTHLY: 'Maandelijks',
        YEARLY: 'Jaarlijks',
        FIRST: 'Eerste',
        LAST: 'Laatste',
        DAY: 'Dag',
        DATE_STARTEDAT: 'Start',
        DATE_EXPIREDAT: 'Vervalt',
        DATE_EXPIRESAT: 'Vervalt',
        DATE_REVIEWEDAT: 'Herzien op',
        DATE_TENDEREDAT: 'Aanbesteed op',
        DATE_CREATEDAT: 'Gemaakt op',
        DATE_UPLOADDATE: 'Upload Datum',
        DATE_COMPLETIONAT: 'Klaar op',


        DATE_LAST_READ: 'Gelezen op',
        DATE_ACTIVEAT: 'Actief sinds',
        DATE_INACTIVEAT: 'Inactief sinds',

        TASK_PLANNER_DAILY: '{{interval}}D',
        TASK_PLANNER_WEEKLY: '{{interval}}W',
        TASK_PLANNER_MONTHLY: '{{interval}}M',
        TASK_PLANNER_ANNUALLY: '{{interval}}J',

        /* Generation */
        RELATIVE_STARTDATE: 'Relatieve startdatum',
        MONDAY: 'Maandag',
        TUESDAY: 'Dinsdag',
        WEDNESDAY: 'Woensdag',
        THURSDAY: 'Donderdag',
        FRIDAY: 'Vrijdag',
        SATURDAY: 'Zaterdag',
        SUNDAY: 'Zondag',

        BYDAY_MONDAY: 'Maandag',
        BYDAY_TUESDAY: 'Dinsdag',
        BYDAY_WEDNESDAY: 'Woensdag',
        BYDAY_THURSDAY: 'Donderdag',
        BYDAY_FRIDAY: 'Vrijdag',
        BYDAY_SATURDAY: 'Zaterdag',
        BYDAY_SUNDAY: 'Zondag',

        BYDAY_MONDAY_FIRST: 'Eerste maandag van de maand',
        BYDAY_TUESDAY_FIRST: 'Eerste dinsdag van de maand',
        BYDAY_WEDNESDAY_FIRST: 'Eerste woensdag van de maand',
        BYDAY_THURSDAY_FIRST: 'Eerste donderdag van de maand',
        BYDAY_FRIDAY_FIRST: 'Eerste vrijdag van de maand',
        BYDAY_SATURDAY_FIRST: 'Eerste zaterdag van de maand',
        BYDAY_SUNDAY_FIRST: 'Eerste zondag van de maand',

        BYDAY_MONDAY_LAST: 'Laatste maandag van de maand',
        BYDAY_TUESDAY_LAST: 'Laatste dinsdag van de maand',
        BYDAY_WEDNESDAY_LAST: 'Laatste woensdag van de maand',
        BYDAY_THURSDAY_LAST: 'Laatste donderdag van de maand',
        BYDAY_FRIDAY_LAST: 'Laatste vrijdag van de maand',
        BYDAY_SATURDAY_LAST: 'Laatste zaterdag van de maand',
        BYDAY_SUNDAY_LAST: 'Laatste zondag van de maand',

        SECONDS: 'Seconden',
        MINUTES: 'Minuten',
        HOURS: 'Uren',
        DAYS: 'Dagen',
        WEEKS: 'Weken',

        JANUARY: 'Januari',
        FEBRUARY: 'Februari',
        MARCH: 'Maart',
        APRIL: 'April',
        MAY: 'Mei',
        JUNE: 'Juni',
        JULY: 'Juli',
        AUGUST: 'Augustus',
        SEPTEMBER: 'September',
        OCTOBER: 'Oktober',
        NOVEMBER: 'November',
        DECEMBER: 'December',

        CAL_JANUARY: 'JANUARI',
        CAL_FEBRUARY: 'FEBRUARI',
        CAL_MARCH: 'MAART',
        CAL_APRIL: 'APRIL',
        CAL_MAY: 'MEI',
        CAL_JUNE: 'JUNI',
        CAL_JULY: 'JULI',
        CAL_AUGUST: 'AUGUSTUS',
        CAL_SEPTEMBER: 'SEPTEMBER',
        CAL_OCTOBER: 'OKTOBER',
        CAL_NOVEMBER: 'NOVEMBER',
        CAL_DECEMBER: 'DECEMBER',
        CAL_SUNDAY: 'Zondag',
        CAL_MONDAY: 'Maandag',
        CAL_TUESDAY: 'Dinsdag',
        CAL_WEDNESDAY: 'Woensdag',
        CAL_THURSDAY: 'Donderdag',
        CAL_FRIDAY: 'Vrijdag',
        CAL_SATURDAY: 'Zaterdag',
        CAL_SUNDAY_SHORT: 'Zo',
        CAL_MONDAY_SHORT: 'Ma',
        CAL_TUESDAY_SHORT: 'Di',
        CAL_WEDNESDAY_SHORT: 'Wo',
        CAL_THURSDAY_SHORT: 'Do',
        CAL_FRIDAY_SHORT: 'Vr',
        CAL_SATURDAY_SHORT: 'Za',

        EXPIRESAT: 'Vervalt op',

        DATE_SHORT: 'dd/mm/jjjj'
    });
});
