/**
 * Spillage Module
 */
(function (){
    'use strict';
    angular.module('elogbooks.user.spillages', []);
})();

/**
* Note:
*
* This is a global function. It was intended to be a service that would then be injected
* in to the routing files / functions, but it appears that services cannot be injected in
* to the routing functions. An unknown provider error occurs.
*/
function elbSpillageWorkflowRouting() {
    var self = this;
    this.addRoutes = addRoutes;

    function getView (view, viewObject) {
        var result = {};
        result[view] = viewObject;

        return result;
    }

    function getLabel (translationKey) {
        return { label: '{{ ::"' + translationKey + '" | translate }}' };
    }

    /**
     * Usage:
     *     elbJobWorkflowRoutine().addRoutes(
     *         $stateProvider,
     *         'dashboard.users.jobs.list.details.status', // Route to .status of the Job details, or other parent of .workflow
     *         true || false // Whether or not to include a bookerize view
     *     )
     */
    function addRoutes ($stateProvider, parent, isBookable) {

        var workflowRoute = parent + '.workflow',
            spillageRoute = parent.substring(0, parent.lastIndexOf('.list')),
            baseView = '@' + spillageRoute,
            baseBookView = 'elogbooks-right' + baseView,
            workflowView = baseView + '.list.details.status.workflow';

        var baseViewObject = {};
        if (isBookable) {
            baseViewObject[baseBookView] = {
                templateUrl: '/shared-templates/view.html'
            };

            baseViewObject = parseBook(baseViewObject);
        } else {
            baseViewObject[baseView] = {
                templateUrl: '/shared-templates/view.html'
            };
        }

        $stateProvider
            .state(workflowRoute, {
                parent: parent,
                views: baseViewObject,
                abstract: true,
                ncyBreadcrumb: {
                    skip: true
                }
            })
            .state(workflowRoute + '.reassign', {
                url: '/reassign',
                parent: workflowRoute,
                views: getView(workflowView, {
                    templateUrl: '/modules/user/spillages/details/workflow/_wrapper.html',
                    controller: 'SpillageReassignController',
                    controllerAs: 'vm'
                }),
                resolve: {
                    serviceProviderResponse: function(apiClient, spillageResponse) {
                        return spillageResponse.getResource('serviceprovider') || apiClient.noResourceFound();
                    },
                    operativeCollectionResponse: function(apiClient, serviceProviderResponse) {
                        if (serviceProviderResponse.getLink('spillageoperatives')) {
                            return apiClient.get(serviceProviderResponse.getLink('spillageoperatives'), {
                                permission: 'operative_permission_receive_spillages',
                                status: 'active'
                            }).then(function(response) {

                                return response || apiClient.noResourceFound();
                            });
                        }
                    },
                    formData: function(spillageResponse) {
                        return {
                            note: null,
                            currentOperativeLink: spillageResponse.getLink('operative'),
                            currentOperative: spillageResponse.getLinkAttribute('operative', 'title'),
                            _links: {
                                serviceprovider: spillageResponse._links.serviceprovider
                            }
                        };
                    },
                    scopeData: function(apiClient, $translate, siteResponse, operativeCollectionResponse) {
                        var returnObject = {
                            action: 'reassign',
                            translation: 'SPILLAGE_WORKFLOW_REASSIGN',
                            noteRequired: false,
                            partial: 'reassign',
                            siteResponse: siteResponse,
                            operativeSelectModel: {
                                link: operativeCollectionResponse.getLink('self'),
                                response: operativeCollectionResponse,
                                responseKeyPath: 'operatives',
                                required: true,
                                itemHrefPath: '_links.self.href',
                                itemValuePath: '_links.user.title'
                            }
                        };
                        return returnObject;
                    }
                },
                ncyBreadcrumb: getLabel('SPILLAGE_WORKFLOW_REASSIGN')
            })
            .state(workflowRoute + '.cancel', {
                url: '/cancel',
                parent: workflowRoute,
                views: getView(workflowView, {
                    templateUrl: '/modules/user/spillages/details/workflow/_wrapper.html',
                    controller: 'SpillageCancelController',
                    controllerAs: 'vm'
                }),
                resolve: {
                    scopeData: function(apiClient, $translate, siteResponse, operativeCollectionResponse) {
                        var returnObject = {
                            action: 'cancel',
                            translation: 'SPILLAGE_WORKFLOW_CANCEL',
                            noteRequired: true
                        };
                        return returnObject;
                    }
                },
                ncyBreadcrumb: getLabel('SPILLAGE_WORKFLOW_CANCEL')
            });
    }

    return this;
}



