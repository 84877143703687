(function () {
    'use strict';

    angular
        .module('elogbooks.user.reports')
        .controller('UserReportsEngineeringRiskDetailsController', UserReportsEngineeringRiskDetailsController);

    UserReportsEngineeringRiskDetailsController.$inject = ['$scope', '$timeout', '$filter', '$state', '$window', '$stateParams', '$translate', 'apiClient', 'siteCollectionResponse', 'lodash', 'moment', 'statutoryTypeCollectionResponse', 'coreClientCollectionResponse', 'siteGroupCollectionResponse', 'siteAssociatesCollectionRFMResponse', 'siteAssociateTypesCollectionResponse', 'serviceProviderGroupCollectionResponse', 'serviceProviderCollectionResponse'];

    function UserReportsEngineeringRiskDetailsController($scope, $timeout, $filter, $state, $window, $stateParams, $translate, apiClient, siteCollectionResponse, lodash, moment, statutoryTypeCollectionResponse, coreClientCollectionResponse, siteGroupCollectionResponse, siteAssociatesCollectionRFMResponse, siteAssociateTypesCollectionResponse, serviceProviderGroupCollectionResponse, serviceProviderCollectionResponse) {
        var vm = this;
        vm.stateParams = $stateParams;
        vm.orderDirection = 1;
        vm.offset = 0;
        vm.months = [];
        vm.reportData = [];
        vm.totalRowData = [];
        vm.chartData = [];
        vm.rangeWarning = false;
        vm.chartMonths = [];
        vm.range = lodash.range;

        vm.search = search;
        vm.openModal = openModal;
        vm.changePage = changePage;

        // Make sure max date range is 12 months
        if ($stateParams.dateRange !== null && typeof $stateParams.dateRange === 'string'
            && moment(new Date($stateParams.dateRange.split(',')[1])) > moment(new Date($stateParams.dateRange.split(',')[0])).add(23, 'month').endOf('month').add(1, 'day')) {
            vm.rangeWarning = true;
        } else if ($stateParams.dateRange !== null && typeof $stateParams.dateRange === 'string'
            && moment(new Date($stateParams.dateRange.split(',')[1])) < moment(new Date($stateParams.dateRange.split(',')[0]))) {
            $stateParams.dateRange = moment().subtract(1, 'month').startOf('month').startOf('day').format()+','+moment().subtract(1, 'month').startOf('month').add(23, 'month').endOf('month').endOf('day').format();
        }

        vm.startDate = ($stateParams.dateRange !== null && typeof $stateParams.dateRange === 'string') ? moment(new Date($stateParams.dateRange.split(',')[0])) : null;
        vm.endDate = ($stateParams.dateRange !== null && typeof $stateParams.dateRange === 'string') ? moment(new Date($stateParams.dateRange.split(',')[1])) : null;

        // Filters
        vm.siteSelect = {
            response : siteCollectionResponse,
            link : siteCollectionResponse && siteCollectionResponse.count > 0 ? siteCollectionResponse.getLink('self') : null,
            responseKeyPath: 'sites',
            itemValuePath: 'id',
            itemTitlePath: 'name'
        };
        vm.serviceProviderSelect = {
            response : serviceProviderCollectionResponse,
            link : serviceProviderCollectionResponse ? serviceProviderCollectionResponse.getLink('self') : null,
            responseKeyPath: 'serviceproviders',
            itemValuePath: 'id',
            itemTitlePath: 'name'
        };
        vm.siteAssociateTypeSelect = {
            response : siteAssociateTypesCollectionResponse,
            link : siteAssociateTypesCollectionResponse ? siteAssociateTypesCollectionResponse.getLink('self') : null,
            responseKeyPath: 'siteAssociateTypes',
            itemValuePath: 'id',
            itemTitlePath: 'name'
        };
        vm.siteGroupSelect = {
            response : siteGroupCollectionResponse,
            link : siteGroupCollectionResponse ? siteGroupCollectionResponse.getLink('self') : null,
            responseKeyPath: 'sitegroups',
            itemValuePath: 'id',
            itemTitlePath: 'name'
        };
        vm.serviceProviderGroupSelect = {
            response : serviceProviderGroupCollectionResponse,
            link : serviceProviderGroupCollectionResponse ? serviceProviderGroupCollectionResponse.getLink('self') : null,
            responseKeyPath: 'groups',
            itemValuePath: 'id',
            itemTitlePath: 'name'
        };

        // Clean params for export and table data loading
        vm.cleanParams = {};
        vm.cleanParams.dateRange = $stateParams.dateRange;
        vm.cleanParams.coreClient = $stateParams.coreClient ? JSON.parse($stateParams.coreClient).v : null;
        vm.cleanParams.site = $stateParams.site ? JSON.parse($stateParams.site).v : null;
        vm.cleanParams.siteExternalReference = $stateParams.externalReference;
        vm.cleanParams.siteGroup = $stateParams.siteGroup ? JSON.parse($stateParams.siteGroup).v : null;
        vm.cleanParams.serviceProviderGroup = $stateParams.serviceProviderGroup ? JSON.parse($stateParams.serviceProviderGroup).v : null;
        vm.cleanParams.serviceProvider = $stateParams.serviceProvider ? JSON.parse($stateParams.serviceProvider).v : null;
        vm.cleanParams.siteAssociateType = $stateParams.siteAssociateType ? JSON.parse($stateParams.siteAssociateType).v : null;
        vm.cleanParams.rfmId = $stateParams.rfmId ? JSON.parse($stateParams.rfmId).v : null;
        vm.siteAssociateTypeName = $stateParams.siteAssociateType ? JSON.parse($stateParams.siteAssociateType).n : null;

        vm.statutoryTypeColumns = [];
        vm.statutoryTypeColumns = vm.statutoryTypeColumns.concat(addStatutoryTypes(0, 'total'));
        if (statutoryTypeCollectionResponse) {
            lodash.each(statutoryTypeCollectionResponse.statutorytypes, function (value) {
                vm.statutoryTypeColumns = vm.statutoryTypeColumns.concat(addStatutoryTypes(value.id, 's'+value.id));
            });
        }
        function addStatutoryTypes(id, key) {
            return [
                {value: id, key: key+'compliant', type: 'compliant'},
                {value: id, key: key+'overdue', type: 'overdue'},
                {value: id, key: key+'upcoming', type: 'upcoming'}
            ]
        }

        function calculateMonthsBetween(startDate, endDate) {
            var date = moment(startDate).startOf('day');
            var months = [];

            while (date < endDate && months.length < 12) {
                months.push({'title': date.format('MMM').toUpperCase(), 'key': date.format('YYYYMM').toUpperCase()});
                date.add(1, 'month');
            }

            vm.months = months;
        }

        function loadData() {
            vm.loading = true;
            // vm.loading = false;
            calculateMonthsBetween(vm.startDate, vm.endDate);

            vm.statutoryTypesCollection = statutoryTypeCollectionResponse;

            apiClient.get('/reports/engineering-risk', vm.cleanParams).then(function (response) {
                vm.reportData = response;
                vm.loading = false;

                // total should be cut from the response data
                if (lodash.has(vm.reportData, 'data.length') && vm.reportData.data.length > 1) {
                    vm.totalRowData = vm.reportData.data.pop();
                }
            });
        }

        vm.searchCriteria = {
            dateRange: {
                type: 'date-range',
                title: 'BETWEEN_DATES',
                value: {
                    startDate:  vm.startDate,
                    endDate: vm.endDate
                },
                clearValue: {
                    startDate: null,
                    endDate: null
                }
            },
            site: { type: 'jsonselectwidget', title: 'SITE', value: JSON.parse($stateParams.site), options: vm.siteSelect },
            externalReference: { type: 'text', title: 'EXTERNAL_REFERENCE', value: $stateParams.externalReference },
            siteGroup: { type: 'jsonselectwidget', title: 'SITE_GROUP', value: JSON.parse($stateParams.siteGroup), options: vm.siteGroupSelect },
            serviceProviderGroup: { type: 'jsonselectwidget', title: 'SERVICE_PROVIDER_GROUP', value: JSON.parse($stateParams.serviceProviderGroup), options: vm.serviceProviderGroupSelect },
            serviceProvider: { type: 'jsonselectwidget', title: 'SERVICEPROVIDER', value: JSON.parse($stateParams.serviceProvider), options: vm.serviceProviderSelect },
            siteAssociateType: { type: 'jsonselectwidget', title: 'SITE_ASSOCIATE_TYPE', value: JSON.parse($stateParams.siteAssociateType), options: vm.siteAssociateTypeSelect },
        };

        if (coreClientCollectionResponse.count) {
            vm.coreClientSelect = {
                response : coreClientCollectionResponse,
                link : coreClientCollectionResponse ? coreClientCollectionResponse.getLink('self') : null,
                responseKeyPath: 'coreclients',
                itemValuePath: 'id',
                itemTitlePath: 'name'
            };

            vm.searchCriteria.coreClient = { type: 'jsonselectwidget', title: 'CORE_CLIENT', value: JSON.parse($stateParams.coreClient), options: vm.coreClientSelect };
        }

        if (siteAssociatesCollectionRFMResponse && siteAssociatesCollectionRFMResponse.count) {
            vm.rfmSelect = {
                response : siteAssociatesCollectionRFMResponse,
                link : siteAssociatesCollectionRFMResponse ? siteAssociatesCollectionRFMResponse.getLink('self') : null,
                responseKeyPath: 'associates',
                itemValuePath: '_links.user.id',
                itemTitlePath: '_links.sitemembership.title'
            };

            vm.searchCriteria.rfmId = { type: 'jsonselectwidget', title: 'RFM', value: JSON.parse($stateParams.rfmId), options: vm.rfmSelect };
        }

        function search(params) {
            var override = {
                page: 1
            };

            $state.go('.', angular.extend($stateParams, params, override), {reload: $state.current.name});
        }

        function openModal(type, site, statutoryType) {
            var params = {
                modalType: type, // total/overdue/compliant/upcoming
                modalSite: site,
                modalStatutoryType: statutoryType,
                modalDateRange: $stateParams.dateRange
            };

            $state.go('dashboard.user.reports.list.view.engineering-risk.modal.jobs', angular.extend({}, $stateParams, params), {reload:false, notify: false});
        }

        function changePage(page) {
            // Make sure to set page and adjust the offset after ordering
            vm.offset = (page - 1) * $stateParams.riskLimit;
            vm.stateParams.riskPage = page;
            $window.scrollTo(0, 0);
            $state.go('.', angular.extend({}, $stateParams, {riskPage: page}), { reload: $state.current.name, notify: false });
        }

        loadData();
    }
})();


