angular.module('elogbooksDirectives').directive('elbWeekDay', function(){
    return {
        // Restrict to attributes only.
        restrict: 'A',
        require: ['ngModel', 'name'],
        scope: {
            selectedDate: '=ngModel',
            required: '=required',
            empty: '=empty',
            disabled: '=disabled',
            name: '@name'
        },
        templateUrl: '/modules/directives/form-elements/week-day/week-day.html',
        controller: ['$scope', 'moment', function($scope, moment) {
            $scope.model = null;
            $scope.isoWeekStart = moment($scope.selectedDate).startOf('isoweek');

            if ($scope.empty === true) {
                $scope.model = null;
            } else {
                $scope.model = moment($scope.selectedDate).toISOString();
            }

            $scope.weekDates = [];
            $scope.updateDate = updateDate;
            $scope.compareDays = compareDays;

            generateWeekDays();

            function updateDate(date) {
                if ($scope.disabled) {
                    return;
                }

                $scope.model = date.toISOString();
                $scope.selectedDate = date.toISOString();
            }

            function compareDays(date1, date2) {
                return moment(date1).isoWeekday() == moment(date2).isoWeekday();
            }

            function generateWeekDays() {
                $scope.weekDates.push(new Date($scope.isoWeekStart));

                //Generate day array
                for (var i = 1; i <= 6; i++) {
                    $scope.isoWeekStart.add(1, 'days');
                    $scope.weekDates.push(new Date($scope.isoWeekStart));
                }
            }
        }]
    }
});
