(function() {
    'use strict';

    angular
        .module('elogbooks')
        .config(Translations);

    function Translations($translateProvider) {
        $translateProvider.translations('en-gb', {
            USER_PERMISSIONS: 'User Permissions',
            USER_PERMISSION_MANAGE_ASSET_TYPES: 'Manage Asset Types',
            USER_PERMISSION_MANAGE_ASSOCIATE_TYPES: 'Manage Associate Types',
            USER_PERMISSION_MANAGE_CERTIFICATE_TYPES: 'Manage Certificate Types',
            USER_PERMISSION_MANAGE_CHARGE_TYPES: 'Manage Charge Types',
            USER_PERMISSION_MANAGE_CONTRACT_TYPES: 'Manage Contract Types',
            USER_PERMISSION_MANAGE_JOB_REQUISITES: 'Manage Job Requisites',
            USER_PERMISSION_MANAGE_JOB_GROUPS: 'Manage Job Groups',
            USER_PERMISSION_MANAGE_LETTERS: 'Manage Letters',
            USER_PERMISSION_MANAGE_METER_INFO: 'Manage Meter Info',
            USER_PERMISSION_MANAGE_PRIORITIES: 'Manage Priorities',
            USER_PERMISSION_MANAGE_REGIONS: 'Manage Regions',
            USER_PERMISSION_MANAGE_ROLES: 'Manage Roles',
            USER_PERMISSION_MANAGE_SATISFACTION_SURVEYS: 'Manage Satisfaction Surveys',
            USER_PERMISSION_MANAGE_SECTORS: 'Manage Sectors',
            USER_PERMISSION_MANAGE_SERVICE_PROVIDERS: 'Manage Service Providers',
            USER_PERMISSION_MANAGE_SERVICE_PROVIDER_GROUPS: 'Manage Service Provider Groups',
            USER_PERMISSION_MANAGE_SERVICE_ROUTINES: 'Manage Service Routines',
            USER_PERMISSION_MANAGE_SETTINGS: 'Manage Settings',
            USER_PERMISSION_MANAGE_SITES: 'Manage Sites',
            USER_PERMISSION_MANAGE_SITE_GROUPS: 'Manage Site Groups',
            USER_PERMISSION_MANAGE_SITE_NOTE_TYPES: 'Manage Site Note Types',
            USER_PERMISSION_MANAGE_SITE_RELATIONSHIPS: 'Manage Site Relationships',
            USER_PERMISSION_MANAGE_SURVEYS: 'Manage Surveys',
            USER_PERMISSION_MANAGE_TAGS: 'Manage Tags',
            USER_PERMISSION_MANAGE_TRADE_TYPES: 'Manage Trade Types',
            USER_PERMISSION_MANAGE_USERS: 'Manage Users',
            USER_PERMISSION_MANAGE_DUTY_MANAGEMENT_SCHEDULES: 'Manage Duty Management Schedules',
            USER_PERMISSION_MANAGE_MASTER_TASKS: 'Manage Master Tasks',
            USER_PERMISSION_VIEW_ASSET_TYPES: 'View Asset Types',
            USER_PERMISSION_VIEW_ASSOCIATE_TYPES: 'View Associate Types',
            USER_PERMISSION_VIEW_CERTIFICATE_TYPES: 'View Certificate Types',
            USER_PERMISSION_VIEW_CHARGE_TYPES: 'View Charge Types',
            USER_PERMISSION_VIEW_CONTRACT_TYPES: 'View Contract Types',
            USER_PERMISSION_VIEW_JOB_REQUISITES: 'View Job Requisites',
            USER_PERMISSION_VIEW_JOB_GROUPS: 'View Job Groups',
            USER_PERMISSION_VIEW_LETTERS: 'View Letters',
            USER_PERMISSION_VIEW_METER_INFO: 'View Meter Info',
            USER_PERMISSION_VIEW_PRIORITIES: 'View Priorities',
            USER_PERMISSION_VIEW_REGIONS: 'View Regions',
            USER_PERMISSION_VIEW_ROLES: 'View Roles',
            USER_PERMISSION_VIEW_SATISFACTION_SURVEYS: 'View Satisfaction Surveys',
            USER_PERMISSION_VIEW_SECTORS: 'View Sectors',
            USER_PERMISSION_VIEW_SERVICE_PROVIDERS: 'View Service Providers',
            USER_PERMISSION_VIEW_SERVICE_PROVIDER_GROUPS: 'View Service Provider Groups',
            USER_PERMISSION_VIEW_SERVICE_ROUTINES: 'View Service Routines',
            USER_PERMISSION_VIEW_SETTINGS: 'View Settings',
            USER_PERMISSION_VIEW_SITES: 'View Sites',
            USER_PERMISSION_VIEW_SITE_GROUPS: 'View Site Groups',
            USER_PERMISSION_VIEW_SITE_NOTE_TYPES: 'View Site Note Types',
            USER_PERMISSION_VIEW_SURVEYS: 'View Surveys',
            USER_PERMISSION_VIEW_TAGS: 'View Tags',
            USER_PERMISSION_VIEW_TRADE_TYPES: 'View Trade Types',
            USER_PERMISSION_VIEW_USERS: 'View Users',
            USER_PERMISSION_VIEW_DUTY_MANAGEMENT_SCHEDULES: 'View Duty Management Schedules',
            USER_PERMISSION_CAN_RECEIVE_SUPPORT_MESSAGES: 'Can Receive Support Messages',
            USER_PERMISSION_CAN_CREATE_GLOBAL_AUDIT_TEMPLATES: 'Can Create Global Audit Templates',
            USER_PERMISSION_ACCESS_SERVICE_PROVIDER_APP: 'Access Service Provider App',
            USER_PERMISSION_ACCESS_CLIENT_APP: 'Access Client App',
            USER_PERMISSION_MANAGE_SERVICE_TYPES: 'Manage Service Types',
            USER_PERMISSION_VIEW_SERVICE_TYPES: 'View Service Types',
            USER_PERMISSION_MANAGE_IMPORTS: 'Manage Imports',
            USER_PERMISSION_MANAGE_EXPORTS: 'Manage Exports',
            USER_PERMISSION_MANAGE_CORE_CLIENTS: 'Manage Core Clients',
            USER_PERMISSION_KPI_TREND_EXPORT: 'KPI Trend Export',
            USER_PERMISSION_MANAGE_ASSET_SCORE_TYPES: 'Manage Asset Score Types',
            USER_PERMISSION_VIEW_ASSET_SCORE_TYPES: 'View Asset Score Types',
            USER_PERMISSION_MANAGE_WASTE_MANAGEMENT: 'Manage Waste Management',
            USER_PERMISSION_MANAGE_VIEW_LABOUR_LINES: 'Manage View Labour Lines',
            USER_PERMISSION_MANAGE_DEPARTMENTS: 'Manage Departments',

            USER_PERMISSION_MANAGE_ACTION_TYPES: 'Manage Action Types',
            USER_PERMISSION_VIEW_ACTION_TYPES: 'View Action Types',

            USER_PERMISSION_VIEW_FORMS: 'View Forms',
            USER_PERMISSION_MANAGE_FORMS: 'Manage Forms',

            USER_PERMISSION_MANAGE_METER_INFO_DETAIL: ' ',
            USER_PERMISSION_VIEW_METER_INFO_DETAIL: ' ',
            USER_PERMISSION_CAN_CREATE_GLOBAL_AUDIT_TEMPLATES_DETAIL: ' ',
            USER_PERMISSION_ACCESS_SERVICE_PROVIDER_APP_DETAIL: ' ',
            USER_PERMISSION_ACCESS_CLIENT_APP_DETAIL: ' ',
            USER_PERMISSION_MANAGE_BILLING_TYPES: 'Manage Billing Types',
            USER_PERMISSION_MANAGE_BILLING_COSTS: 'Manage Billing Costs',
            USER_PERMISSION_MANAGE_WEBHOOKS: 'Manage Webhooks',

            USER_PERMISSION_MANAGE_ACTION_TYPES_DETAIL: 'The ability to create, update and delete Action Types',
            USER_PERMISSION_MANAGE_ASSET_TYPES_DETAIL: 'The ability to create, update and delete Asset Types',
            USER_PERMISSION_MANAGE_ASSOCIATE_TYPES_DETAIL: 'The ability to create, update and delete Site Associate Types',
            USER_PERMISSION_MANAGE_CERTIFICATE_TYPES_DETAIL: 'The ability to create, update and delete Certificate Types',
            USER_PERMISSION_MANAGE_CHARGE_TYPES_DETAIL: 'The ability to create, update and delete Charge Types',
            USER_PERMISSION_MANAGE_CONTRACT_TYPES_DETAIL: 'The ability to create, update and delete Contract Types',
            USER_PERMISSION_MANAGE_JOB_REQUISITES_DETAIL: 'The ability to create, update and delete Job Requisites',
            USER_PERMISSION_MANAGE_JOB_GROUPS_DETAIL: 'The ability to create, update and delete Job Groups',
            USER_PERMISSION_MANAGE_LETTERS_DETAIL: 'The ability to create, update and delete Letter templates',
            USER_PERMISSION_MANAGE_METERINFO_DETAIL: 'The ability to create, update and delete Meter Manufacturers, Meter Suppliers, Meter Supply Types and Meter Types',
            USER_PERMISSION_MANAGE_PRIORITIES_DETAIL: 'The ability to create, update and delete System Priorities',
            USER_PERMISSION_MANAGE_REGIONS_DETAIL: 'The ability to create, update and delete Regions',
            USER_PERMISSION_MANAGE_ROLES_DETAIL: 'The ability to create, update and delete Roles',
            USER_PERMISSION_MANAGE_SATISFACTION_SURVEYS_DETAIL: 'The ability to create, update and delete Satisfaction Survey Questions',
            USER_PERMISSION_MANAGE_SECTORS_DETAIL: 'The ability to create, update and delete Sectors',
            USER_PERMISSION_MANAGE_SERVICE_PROVIDERS_DETAIL: 'The ability to create, update and delete Service Providers',
            USER_PERMISSION_MANAGE_SERVICE_PROVIDER_GROUPS_DETAIL: 'The ability to create, update and delete Service Provider Groups',
            USER_PERMISSION_MANAGE_SERVICE_ROUTINES_DETAIL: 'The ability to create, update and delete System Service Routines',
            USER_PERMISSION_MANAGE_SETTINGS_DETAIL: 'The ability to update System Settings',
            USER_PERMISSION_MANAGE_SITES_DETAIL: 'The ability to create, update and delete Sites',
            USER_PERMISSION_MANAGE_SITE_GROUPS_DETAIL: 'The ability to create, update and delete Site Groups',
            USER_PERMISSION_MANAGE_SITE_NOTE_TYPES_DETAIL: 'The ability to create, update and delete Site Note Types',
            USER_PERMISSION_MANAGE_SITE_RELATIONSHIPS_DETAIL: 'The ability to create, update and delete Site Relationships',
            USER_PERMISSION_MANAGE_SURVEYS_DETAIL: 'The ability to create, update and delete KPI Surveys',
            USER_PERMISSION_MANAGE_TAGS_DETAIL: 'The ability to create, update and delete Tags',
            USER_PERMISSION_MANAGE_TRADE_TYPES_DETAIL: 'The ability to create, update and delete Trade Types',
            USER_PERMISSION_MANAGE_USERS_DETAIL: 'The ability to create, update and delete Users',
            USER_PERMISSION_MANAGE_DUTY_MANAGEMENT_SCHEDULES_DETAIL: 'The ability to manage the Duty Management Schedules for the system',
            USER_PERMISSION_MANAGE_MASTER_TASKS_DETAIL: 'The ability to create, update and delete Master Tasks',
            USER_PERMISSION_MANAGE_CORE_CLIENTS_DETAIL: 'The ability to create, update and delete Core Clients',

            USER_PERMISSION_VIEW_ACTION_TYPES_DETAIL: 'The ability to view Action Types',
            USER_PERMISSION_VIEW_ASSET_TYPES_DETAIL: 'The ability to view Asset Types',
            USER_PERMISSION_VIEW_ASSOCIATE_TYPES_DETAIL: 'The ability to view Site Associate Types',
            USER_PERMISSION_VIEW_CERTIFICATE_TYPES_DETAIL: 'The ability to view Certificate Types',
            USER_PERMISSION_VIEW_CHARGE_TYPES_DETAIL: 'The ability to view Charge Types',
            USER_PERMISSION_VIEW_CONTRACT_TYPES_DETAIL: 'The ability to view Contract Types',
            USER_PERMISSION_VIEW_JOB_REQUISITES_DETAIL: 'The ability to view Job Requisites',
            USER_PERMISSION_VIEW_JOB_GROUPS_DETAIL: 'The ability to view Job Groups',
            USER_PERMISSION_VIEW_LETTERS_DETAIL: 'The ability to view Letter templates',
            USER_PERMISSION_VIEW_METERINFO_DETAIL: 'The ability to view Meter Manufacturers, Meter Suppliers, Meter Supply Types and Meter Types',
            USER_PERMISSION_VIEW_PRIORITIES_DETAIL: 'The ability to view System Priorities',
            USER_PERMISSION_VIEW_REGIONS_DETAIL: 'The ability to view Regions',
            USER_PERMISSION_VIEW_ROLES_DETAIL: 'The ability to view Roles',
            USER_PERMISSION_VIEW_SATISFACTION_SURVEYS_DETAIL: 'The ability to view Satisfaction Survey Questions',
            USER_PERMISSION_VIEW_SECTORS_DETAIL: 'The ability to view Sectors',
            USER_PERMISSION_VIEW_SERVICE_PROVIDERS_DETAIL: 'The ability to view Service Providers',
            USER_PERMISSION_VIEW_SERVICE_PROVIDER_GROUPS_DETAIL: 'The ability to view Service Provider Groups',
            USER_PERMISSION_VIEW_SERVICE_ROUTINES_DETAIL: 'The ability to view System Service Routines',
            USER_PERMISSION_VIEW_SETTINGS_DETAIL: 'The ability to view System Settings',
            USER_PERMISSION_VIEW_SITES_DETAIL: 'The ability to view Sites',
            USER_PERMISSION_VIEW_SITE_GROUPS_DETAIL: 'The ability to view Site Groups',
            USER_PERMISSION_VIEW_SITE_NOTE_TYPES_DETAIL: 'The ability to view Site Note Types',
            USER_PERMISSION_VIEW_SURVEYS_DETAIL: 'The ability to view KPI Surveys',
            USER_PERMISSION_VIEW_TAGS_DETAIL: 'The ability to view Tags',
            USER_PERMISSION_VIEW_TRADE_TYPES_DETAIL: 'The ability to view Trade Types',
            USER_PERMISSION_VIEW_USERS_DETAIL: 'The ability to view Users',
            USER_PERMISSION_VIEW_DUTY_MANAGEMENT_SCHEDULES_DETAIL: 'The ability to view the Duty Management Schedules for the system',
            USER_PERMISSION_CAN_RECEIVE_SUPPORT_MESSAGES_DETAIL: 'The ability to view and respond to support messages',

            USER_PERMISSION_MANAGE_SERVICE_TYPES_DETAIL: 'The ability to create, update and delete Service Types',
            USER_PERMISSION_VIEW_SERVICE_TYPES_DETAIL: 'The ability to view Service Types',
            USER_PERMISSION_MANAGE_IMPORTS_DETAIL: 'The ability to import data',
            USER_PERMISSION_MANAGE_EXPORTS_DETAIL: 'The ability to export data',
            USER_PERMISSION_MANAGE_BILLING_TYPES_DETAIL: 'The ability to create billing types',
            USER_PERMISSION_KPI_TREND_EXPORT_DETAIL: 'The ability to export KPI trends',
            USER_PERMISSION_MANAGE_ASSET_SCORE_TYPES_DETAIL: 'The ability to manages asset score types',
            USER_PERMISSION_VIEW_ASSET_SCORE_TYPES_DETAIL: 'The ability to view asset score types',
            USER_PERMISSION_OPEN_CLOSE_DETAIL: 'The ability to create Open Late/Early Close records',
            USER_PERMISSION_OPEN_CLOSE: 'The ability to create Open Late/Early Close records',

            USER_PERMISSION_MANAGE_SPILLAGE_TYPES: 'Manage Spillage Types',
            USER_PERMISSION_MANAGE_SPILLAGE_TYPES_DETAIL: 'The ability to manage spillage types',
            USER_PERMISSION_VIEW_SPILLAGE_TYPES: 'View Spillage Types',
            USER_PERMISSION_VIEW_SPILLAGE_TYPES_DETAIL: 'The ability to view spillage types',

            USER_PERMISSION_MANAGE_MISSED_PATROL_REASONS: 'Manage missed patrol reasons',
            USER_PERMISSION_MANAGE_MISSED_PATROL_REASONS_DETAIL: 'Manage missed patrol reasons',

            USER_PERMISSION_VIEW_FORMS_DETAIL: 'The ability to view Forms',
            USER_PERMISSION_MANAGE_FORMS_DETAIL: 'The ability to manage Forms',
            USER_PERMISSION_MANAGE_WASTE_MANAGEMENT_DETAIL: 'The ability to manage Waste Management',
            USER_PERMISSION_MANAGE_WEBHOOKS_DETAIL: 'The ability to manage webhooks',

            USER_PERMISSION_VIEW_REQUEST_LOGS: 'View Request Logs',
            USER_PERMISSION_VIEW_REQUEST_LOGS_DETAIL: 'The ability to view request logs',

            USER_PERMISSION_VIEW_DOCUMENT_TYPES: 'View Document Types',
            USER_PERMISSION_VIEW_DOCUMENT_TYPES_DETAIL: 'The ability to view Document Types',
            USER_PERMISSION_MANAGE_DOCUMENT_TYPES: 'Manage Document Types',
            USER_PERMISSION_MANAGE_DOCUMENT_TYPES_DETAIL: 'The ability to create, update and delete Document Types',

            USER_PERMISSION_MANAGE_LABOUR_TYPES: 'Manage Labour Types',
            USER_PERMISSION_MANAGE_LABOUR_TYPES_DETAIL: 'The ability to view, create, update and delete Labour Types',

            USER_PERMISSION_MANAGE_SUPPLIERS: 'Manage Suppliers',
            USER_PERMISSION_MANAGE_SUPPLIERS_DETAIL: 'The ability to view, create, update and delete Suppliers',

            USER_PERMISSION_MANAGE_PRICING_SCHEDULES: 'Manage Pricing Schedules',
            USER_PERMISSION_MANAGE_PRICING_SCHEDULES_DETAIL: 'The ability to view, create, update and delete Pricing Schedules',

            USER_PERMISSION_MANAGE_PURCHASE_ORDERS: 'Manage Purchase Orders',
            USER_PERMISSION_MANAGE_PURCHASE_ORDERS_DETAIL: 'The ability to view, create, update and delete Purchase Orders',

            USER_PERMISSION_MANAGE_DEPARTMENTS_DETAIL: 'The ability to manage Departments',

            USER_PERMISSION_MANAGE_BILLING_SETTINGS: 'Manage Billing Settings',
            USER_PERMISSION_MANAGE_BILLING_SETTINGS_DETAIL: 'The ability to manage Billing Settings',

            USER_PERMISSION_MANAGE_VAT_RATES: 'Manage VAT Rates',
            USER_PERMISSION_MANAGE_VAT_RATES_DETAIL: 'The ability to manage VAT Rates',

            USER_PERMISSION_MANAGE_FINANCE: 'Manage Finance',
            USER_PERMISSION_MANAGE_FINANCE_DETAIL: 'The ability to manage Finance',

            USER_PERMISSION_CAN_BOOK_HOLIDAYS: 'Can book holidays',
            USER_PERMISSION_CAN_BOOK_HOLIDAYS_DETAIL: 'The ability to book Holidays',

            USER_PERMISSION_ABSENCE_ADMIN: 'Absence Admin',
            USER_PERMISSION_ABSENCE_ADMIN_DETAIL: 'Absence Administration',

            USER_PERMISSION_MANAGE_TIMESHEETS: 'Manage Timesheets',
            USER_PERMISSION_MANAGE_TIMESHEETS_DETAIL: 'The ability to manage Timesheets',

            USER_PERMISSION_BULK_MOVE_ALARMS: 'Bulk Move Alarms',
            USER_PERMISSION_BULK_MOVE_ALARMS_DETAIL: 'The ability to move alarms as a bulk operation',
            USER_PERMISSION_MANAGE_TIMESHEET_TYPES: 'Manage Timesheet Types',
            USER_PERMISSION_MANAGE_TIMESHEET_TYPES_DETAIL: 'The ability to create, update and delete Timesheet Types',
            USER_PERMISSION_ADD_EDIT_JOB_REQUISITES: 'Add/Edit Job Requisites',
            USER_PERMISSION_ADD_EDIT_JOB_REQUISITES_DETAIL: 'The ability to add or edit Job Requisites',

            USER_PERMISSION_CLIENT_ADMIN_MANAGE_USERS: 'Client Admin Manage Users',
            USER_PERMISSION_CLIENT_ADMIN_MANAGE_USERS_DETAIL: 'Client Admin Permission to manage users',
            USER_PERMISSION_CLIENT_ADMIN_MANAGE_JOB_GROUPS: 'Client Admin Manage Job Groups',
            USER_PERMISSION_CLIENT_ADMIN_MANAGE_JOB_GROUPS_DETAIL: 'Client Admin Permission to manage job groups',
            USER_PERMISSION_CLIENT_ADMIN_MANAGE_JOB_REQUISITES: 'Client Admin Manage Job Requisites',
            USER_PERMISSION_CLIENT_ADMIN_MANAGE_JOB_REQUISITES_DETAIL: 'Client Admin Permission to manage job requisites',
            USER_PERMISSION_CLIENT_ADMIN_MANAGE_REGIONS: 'Client Admin Manage Regions',
            USER_PERMISSION_CLIENT_ADMIN_MANAGE_REGIONS_DETAIL: 'Client Admin Permission to manage regions',
            USER_PERMISSION_CLIENT_ADMIN_MANAGE_SECTORS: 'Client Admin Manage Sectors',
            USER_PERMISSION_CLIENT_ADMIN_MANAGE_SECTORS_DETAIL: 'Client Admin Permission to manage sectors',
            USER_PERMISSION_CLIENT_ADMIN_MANAGE_SURVEYS: 'Client Admin Manage Surveys',
            USER_PERMISSION_CLIENT_ADMIN_MANAGE_SURVEYS_DETAIL: 'Client Admin Permission to manage surveys',
            USER_PERMISSION_CLIENT_ADMIN_MANAGE_DOCUMENT_TYPES: 'Client Admin Manage Document Types',
            USER_PERMISSION_CLIENT_ADMIN_MANAGE_DOCUMENT_TYPES_DETAIL: 'Client Admin Permission to manage document types',
            USER_PERMISSION_CLIENT_ADMIN_MANAGE_FORMS: 'Client Admin Manage Forms',
            USER_PERMISSION_CLIENT_ADMIN_MANAGE_FORMS_DETAIL: 'Client Admin Permission to manage forms',
            USER_PERMISSION_CLIENT_ADMIN_MANAGE_SITES: 'Client Admin Manage Sites',
            USER_PERMISSION_CLIENT_ADMIN_MANAGE_SITES_DETAIL: 'Client Admin Permission to manage sites',
            USER_PERMISSION_CLIENT_ADMIN_EDIT_PARTNERSHIPS: 'Client Admin Edit Partnerships',
            USER_PERMISSION_CLIENT_ADMIN_EDIT_PARTNERSHIPS_DETAIL: 'Client Admin Permission to edit partnerships',
            USER_PERMISSION_CLIENT_ADMIN_EDIT_LOCATIONS: 'Client Admin Edit Locations',
            USER_PERMISSION_CLIENT_ADMIN_EDIT_LOCATIONS_DETAIL: 'Client Admin Permission to edit locations',
            USER_PERMISSION_VIEW_EFS_VALUES: 'View EFS Values',
            USER_PERMISSION_VIEW_EFS_VALUES_DETAIL: 'Permission to view EFS values',
            USER_PERMISSION_IMPORT_SFG_XML: 'Import SFG XML',
            USER_PERMISSION_IMPORT_SFG_XML_DETAIL: 'The ability to import SFG XML',

            USER_PERMISSION_VIEW_CLIENT_BILLING: 'View Client Billing',
            USER_PERMISSION_VIEW_CLIENT_BILLING_DETAIL: 'The Ability to View Client Billing',
            USER_PERMISSION_MANAGE_CLIENT_BILLING: 'Manage Client Billing',
            USER_PERMISSION_MANAGE_CLIENT_BILLING_DETAIL: 'The Ability to Manage Client Billing',

            USER_PERMISSION_CLIENT_ADMIN_MANAGE_IMPORTS: "Client Admin Manage Imports",
            USER_PERMISSION_CLIENT_ADMIN_MANAGE_IMPORTS_DETAIL: "Client Admin Permission to manage imports"
        });
    }
})();
