(function () {
    'use strict';

    angular
        .module('elogbooks.user.service-providers')
        .controller('CommonServiceProvidersDetailsPrimaryOperativesController', CommonServiceProvidersDetailsPrimaryOperativesController);

    CommonServiceProvidersDetailsPrimaryOperativesController.$inject = ['$state', 'serviceProviderResponse', 'serviceProviderService'];

    function CommonServiceProvidersDetailsPrimaryOperativesController ($state, serviceProviderResponse, serviceProviderService) {
        var vm = this;
        vm.serviceProvider = serviceProviderResponse;
        vm.primaryOperatives = serviceProviderService.getPrimaryOperatives(vm.serviceProvider);

        vm.admin = $state.current.name.indexOf('dashboard.admin.service-providers') === 0;
    }
})();
