(function () {
    'use strict';

    angular
        .module('elogbooks.helpdesk')
        .config(function ($stateProvider) {

            $stateProvider
                .state('dashboard.helpdesk', {
                    url: '/helpdesk',
                    abstract: true,
                    templateUrl: '/modules/helpdesk/helpdesk-layout.html',
                    controller: 'HelpdeskLayoutController',
                    ncyBreadcrumb: {
                        label: '{{ ::"HELPDESK" | translate }}'
                    },
                    resolve: {
                        selectedSiteResponse: function() {
                            return {};
                        }
                    }
                });
        });

})();
