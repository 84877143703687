(function () {
    'use strict';

    angular
        .module('elogbooks.admin.surveys')
        .controller('SurveyInfoController', SurveyInfoController);

    SurveyInfoController.$inject = ['surveyResponse', 'surveyVersionsResponse'];

    function SurveyInfoController (surveyResponse, surveyVersionsResponse)
    {
        var vm = this;
        vm.survey = surveyResponse;
        vm.surveyVersionsResponse = surveyVersionsResponse;
        vm.selectedVersion = vm.survey;
    }
})();
