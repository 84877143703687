angular
    .module('elogbooksDirectives')
    .directive('jobsDueBy', [function () {
    return {
        restrict: 'AE',
        scope: {
            model: '=ngModel',
            minified: '@'
        },
        templateUrl: '/modules/directives/jobsDueBy/jobs-due-by.html',
        controller: function ($scope, lodash) {
            if (typeof $scope.model === 'undefined') {
                clearAction();
            }

            $scope.model.clear = clearAction;
            $scope.reset = resetAction;

            function resetAction() {
                if (lodash.isEmpty($scope.model.type)) {
                    $scope.model.interval = 1;
                    $scope.model.frequency = null;
                }

                if (lodash.isEmpty($scope.model.frequency)) {
                    $scope.model.interval = 1;
                }
                $scope.model.selected = true;
            }

            function clearAction() {
                $scope.model.type = null;
                $scope.model.interval = null;
                $scope.model.frequency = null;
                $scope.model.selected = null;
            }
        }
    };
}]);
