(function() {
    'use strict';

    angular
        .module('elogbooks')
        .config(Translations);

    function Translations($translateProvider) {
        $translateProvider.translations('zh-hk', {
            REQUIRE_ASSET_WHEN_LOGGING_REACTIVE_JOB: '需要資產用於回覆工作'
        });
    }
})();

app.config(function ($translateProvider) {
    $translateProvider.translations('zh-hk', {
        SERVICE_PROVIDER: '服務供應商',
        SERVICE_PROVIDERS: '服務供應商',


        ADD_NEW_SERVICE_PROVIDER: '新增服務供應商',

        SERVICE_PROVIDER_LIST: '服務供應商',
        SERVICE_PROVIDER_ADD: '新增服務供應商',
        SERVICE_PROVIDER_EDIT: '修改服務供應商',

        SERVICE_PROVIDER_CREATED: '服務供應商已建立',
        SERVICE_PROVIDER_UPDATED: '服務供應商已更新',
        SERVICE_PROVIDER_DELETED: '服務供應商已刪除',

        SERVICE_PROVIDERS_SELECT: '選擇服務供應商',
        SERVICE_PROVIDERS_ADD: '新增服務供應商',
        SERVICE_PROVIDERS_UPDATE: '更新服務供應商',
        SERVICE_PROVIDERS_UPDATED: '服務供應商已更新',

        SERVICE_PROVIDER_PERMISSIONS_UPDATED: '服務供應商權限已更新',

        PRIMARY_OPERATIVES: '主要操作',
        PRIMARY_OPERATIVE_TYPE: '主要操作類別',

        PRIMARY_OPERATIVE_TYPES_EDIT: '修改主要操作',
        PRIMARY_OPERATIVES_UPDATED: '主要操作已更新',

        PRIMARY_JOB_OPERATIVE: '工作',
        PRIMARY_ESCALATION_OPERATIVE: '上報',
        PRIMARY_QUOTE_OPERATIVE: '報價',
        PRIMARY_PATROL_OPERATIVE: '巡邏',
        PRIMARY_SURVEY_OPERATIVE: '調查',
        ACTIVE_AT: '有效於',
        INACTIVE_AT: '無效於',
        TRAINED_AT: '訓練於',
        OPERATIVE_CREATED: '操作已建立',
        PERMISSIONS_UPDATED: '權限已更新',
        OPERATIVE_REMOVED: '操作已移除',
        SERVICE_PROVIDER_PARTNERSHIP_ADDED: '夥伴關係已新增',

        SERVICE_PROVIDER_STATUS: '服務提供商狀態',
    });
});
