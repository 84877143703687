(function () {
    'use strict';

    angular
        .module('elogbooks.admin.job-requisites')
        .controller('JobRequisitesInfoController', JobRequisitesInfoController);

    JobRequisitesInfoController.$inject = [
        'jobRequisiteResponse',
        'allowNotApplicable',
        'requestAttachment',
        'jobRequisites',
        'userManager'
    ];

    function JobRequisitesInfoController (
        jobRequisiteResponse,
        allowNotApplicable,
        requestAttachment,
        jobRequisites,
        userManager
    ) {
        var vm = this;
        vm.jobRequisite = jobRequisiteResponse;
        vm.allowNotApplicable = allowNotApplicable;
        vm.requestAttachment = requestAttachment;
        vm.jobRequisiteApprovalStrategies = jobRequisites.approvalStrategyOptions;

        vm.allowNotApplicable.getNameProxy = function(value) {
            return vm.allowNotApplicable.getName(value);
        };

        vm.requestAttachment.getNameProxy = function(value) {
            return vm.requestAttachment.getName(value);
        };

        vm.canApplyJobRequisite = !userManager.hasPermission('user_permission_add_edit_job_requisites') || userManager.hasPermission('user_permission_manage_job_requisites');
    }
})();
