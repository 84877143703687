(function() {
    'use strict';

    angular
        .module('elogbooks.admin.pricing-schedules')
        .controller('PricingSchedulesMarkupEditController', PricingSchedulesMarkupEditController);

    PricingSchedulesMarkupEditController.$inject = [
        'pricingScheduleMarkupResponse',
        '$state',
        'crudService',
        'requestDataFactory'
    ];

    function PricingSchedulesMarkupEditController(
        pricingScheduleMarkupResponse,
        $state,
        crudService,
        requestDataFactory
    ){
        var vm = this;
        vm.pricingScheduleMarkupResponse = pricingScheduleMarkupResponse;
        vm.pricingScheduleMarkup = pricingScheduleMarkupResponse.pricingschedulemarkup[0];
        vm.data = vm.data = requestDataFactory.createRequest(vm.pricingScheduleMarkup);

        vm.update = updateAction;

        function updateAction() {
            return crudService.update(
                vm.pricingScheduleMarkup.getLink('edit'),
                vm.data,
                $state.get('^'),
                $state.get('^'),
                'PRICING_SCHEDULE_MARKUP'
            );
        }
    }
})();