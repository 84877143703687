app.config( function($translateProvider) {
    $translateProvider.translations('en-gb', {
        METER_TYPE_INFORMATION: 'Meter Type Information',

        ADD_NEW_METER_TYPE: 'Add a new Meter Type',
        EDIT_METER_TYPE: 'Edit Meter Type',

        METER_TYPE_LIST: 'Meter Types',
        METER_TYPES_LIST: 'Meter Types',
        METER_TYPE_ADD: 'Add a new Meter Type',
        METER_TYPE_EDIT: 'Edit Meter Type',

        METER_TYPE_CREATED: 'Meter Type created successfully',
        METER_TYPE_UPDATED: 'Meter Type updated successfully',
        METER_TYPE_DELETED: 'Meter Type deleted successfully',

        METER_TYPE_METER: 'Meter',
        METER_TYPE_SUPPLY: 'Supply Type',
        METER_TYPE_OCCUPANCY: 'Occupancy'
    });
});
