(function() {
    'use strict';

    angular
        .module('elogbooks.user.finance.purchase-invoices')
        .controller('FinanceInvoiceWorkflowController', FinanceInvoiceWorkflowController);

    FinanceInvoiceWorkflowController.$inject = ['invoiceResponse', 'config'];

    function FinanceInvoiceWorkflowController(invoiceResponse, config) {
        var vm = this;
        vm.invoiceResponse = invoiceResponse;
        vm.config = config;
    }
})();