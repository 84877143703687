(function () {
    'use strict';

    angular
        .module('elogbooks.user.spillages')
        .config(registerRoutes)
        .config(registerRoutesList)
        .config(registerRoutesAdd)
        .config(registerRoutesDetails)
        .config(registerRoutesDetailsWorkflow)

    function registerRoutes($stateProvider) {
        $stateProvider
            .state('dashboard.user.spillages', {
                url: '',
                abstract: true,
                template: '<bookerize></bookerize>',
                ncyBreadcrumb: {
                    skip: true
                }
            });
    }

    function registerRoutesList($stateProvider) {
        var spillageParams = {
            site: null,
            operative: null,
            serviceProvider: null,
            createdAtStart: null,
            createdAtEnd: null,
            accidentOccurred: null,
            assignedToMe: null,
            spillagePage: '1',
            spillageLimit: '30',
            fileOrder: '-id',
        };
        $stateProvider
            .state('dashboard.user.spillages.list', {
                url: '/spillages?' + Object.keys(spillageParams).join('&'),
                parent: 'dashboard.user.spillages',
                params: spillageParams,
                views: parseBook({
                    'elogbooks-left@dashboard.user.spillages': {
                        templateUrl: '/modules/user/spillages/spillages-list.html',
                        controller: 'UserSpillageListController',
                        controllerAs: 'vm'
                    }
                }),
                resolve: {
                    config: function(user, userManager) {
                        /**
                         * While unused, user is required as a dependency here to prevent race conditions against userManager.
                         * Do not remove it!
                         */
                        return {
                            resourceName: 'resource',
                            isHelpdesk: userManager.hasPermission('site_permission_helpdesk')
                        };
                    },
                    spillageCollectionResponse: function ($stateParams, apiClient, user, selectedSiteResponse) {
                        var params = angular.copy($stateParams);

                        if ($stateParams.serviceProvider) {
                            params.serviceProvider = JSON.parse($stateParams.serviceProvider).v;
                        }

                        if ($stateParams.operative) {
                            params.operative = JSON.parse($stateParams.operative).v;
                        }

                        if (selectedSiteResponse) {
                            params.site = selectedSiteResponse.id;
                        }

                        params = angular.extend({}, params, {
                            page: $stateParams.spillagePage,
                            limit: $stateParams.spillageLimit
                        });

                        if (user.getLink('spillages')) {
                            return apiClient.get(user.getLink('spillages'), params).then(function(response) {
                                return response || apiClient.noResourceFound();
                            });
                        }
                    },
                    serviceProviderCollectionResponse: function($stateParams, apiClient, user, userManager) {
                        if (!user.getLink('serviceproviders')) {
                            return null;
                        }

                        var params = {};

                        if ($stateParams.serviceProvider) {
                            params.serviceProvider = JSON.parse($stateParams.serviceProvider).v;
                        }

                        if (user.getLink('serviceproviders')) {
                            return apiClient.get(user.getLink('serviceproviders'), params).then(function(response) {
                                return response || apiClient.noResourceFound();
                            });
                        }
                    },
                    operativeCollectionResponse: function(apiClient, rootResourceResponse, $stateParams) {
                        var params = {
                            permission: 'operative_permission_receive_spillages'
                        };

                        if ($stateParams.operative) {
                            params.id = JSON.parse($stateParams.operative).v;
                        }
                        return apiClient.get(rootResourceResponse.getLink('operatives'),params).then(function(response) {
                            return response;
                        });
                    },
                },
                ncyBreadcrumb: {
                    label: '{{ ::"SPILLAGES" | translate }}'
                }
            });
    }

    function registerRoutesAdd($stateProvider) {
        $stateProvider
            .state('dashboard.user.spillages.list.add', {
                url: '/add',
                parent: 'dashboard.user.spillages.list',
                resolve: {
                    siteCollectionResponse: function(user, apiClient, globalFilterParamsService) {
                        var params = {
                            datagroup: 'search'
                        };

                        params = angular.extend({}, params, globalFilterParamsService.getLocalStorageFilters());

                        return apiClient.get(user.getLink('sites'), params, 'long').then(function(response) {
                            return response || apiClient.noResourceFound();
                        });
                    },
                    spillageTypeCollectionResponse: function(rootResourceResponse, apiClient) {
                        return apiClient.get(rootResourceResponse.getLink('spillagetypes'), { active: true });
                    }
                },
                views: parseBook({
                    'elogbooks-right@dashboard.user.spillages': {
                        templateUrl: '/modules/user/spillages/add-edit/spillage-form.html',
                        controller: 'UserSpillageAddEditController',
                        controllerAs: 'vm'
                    }
                }),
                ncyBreadcrumb: {
                    label: '{{ ::"ADD" | translate }}'
                }
            });
    }


    function registerRoutesDetails($stateProvider) {
        $stateProvider
            .state('dashboard.user.spillages.list.details', {
                url: '/details/{spillageResource}',
                abstract: true,
                parent: 'dashboard.user.spillages.list',
                views: parseBook({
                    'elogbooks-right@dashboard.user.spillages': {
                        templateUrl: '/modules/user/spillages/details/spillages-details.html',
                        controller: 'UserSpillagesDetailsController',
                        controllerAs: 'vm'
                    },
                    'feed@dashboard.user.spillages.list.details': {
                        templateUrl: '/modules/user/spillages/details/event/feed.html',
                        controller: 'CommonSpillagesDetailsEventFeedController',
                        controllerAs: 'vm'
                    }
                }),
                resolve: {
                    spillageResponse: function (apiClient, $stateParams) {
                        return apiClient.get($stateParams.spillageResource.decode()).then(function(response) {
                            if (response) {
                                return response;
                            }
                        });
                    },
                    siteResponse: function (apiClient, spillageResponse) {
                        return spillageResponse.getResource('site') || apiClient.noResourceFound();
                    },
                    eventCollectionResponse: function (apiClient, spillageResponse) {
                        if (spillageResponse.getLink('events')) {
                            return apiClient.get(spillageResponse.getLink('events'), { datagroup: 'details' }).then(function(response) {
                                return response || apiClient.noResourceFound();
                            });
                        }
                    },
                    messageCollectionResponse: function (apiClient, spillageResponse) {
                        if (spillageResponse.getLink('messages')) {
                            return apiClient.get(spillageResponse.getLink('messages'), { datagroup: 'details' }).then(function(response) {
                                return response || apiClient.noResourceFound();
                            });
                        }
                    }
                },
                ncyBreadcrumb: {
                    label: '{{ ::$resolves().spillageResponse.summary }}'
                }
            })
            .state('dashboard.user.spillages.list.details.status', {
                url: '/status',
                parent: 'dashboard.user.spillages.list.details',
                views: {
                    'info@dashboard.user.spillages.list.details': {
                        templateUrl: '/modules/user/spillages/details/status/status.html'
                    }
                },
                ncyBreadcrumb: {
                    label: '{{ ::"STATUS" | translate }}'
                }
            })
            .state('dashboard.user.spillages.list.details.files', {
                url: '/files',
                parent: 'dashboard.user.spillages.list.details',
                views: {
                    'info@dashboard.user.spillages.list.details': {
                        templateUrl: '/modules/common/files/files-list.html',
                        controller: 'CommonFilesListController',
                        controllerAs: 'vm'
                    }
                },
                params: {
                    documentType: null
                },
                resolve: {
                    fileCollectionResponse: function(apiClient, spillageResponse, $stateParams) {
                        var params = {
                            order: $stateParams.fileOrder,
                            limit: 100
                        };
                        return apiClient.get(spillageResponse.getLink('files'), params).then(function(response) {
                            return response || apiClient.noResourceFound();
                        });
                    },
                    config: function(config, spillageResponse) {
                        return angular.extend(config, {
                            showStatutoryType: false,
                            hideAddButton: spillageResponse.status === 'SPILLAGE_COMPLETED' || spillageResponse.status === 'SPILLAGE_CANCELLED'
                        });
                    },
                    documentTypeCollectionResponse : function (apiClient, rootResourceResponse) {
                        return apiClient.get(rootResourceResponse.getLink('documenttypes'), {status: 'active', order: 'name'})
                            .then(function (response) {
                                return response || apiClient.noResourceFound();
                            });
                    },
                    jobResponse: function() {
                        return null;
                    },
                    serviceProviderResponse: function () {
                        return null;
                    },
                    operativeCollectionResponse: function () {
                        return null;
                    }
                },
                ncyBreadcrumb: {
                    label: '{{ ::"FILES" | translate }}'
                }
            })
            .state('dashboard.user.spillages.list.details.files.details', {
                url: '/details/{fileResource}',
                parent: 'dashboard.user.spillages.list.details.files',
                views: {
                    'files@dashboard.user.spillages.list.details': {
                        templateUrl: '/modules/common/files/details/files-details.html',
                        controller: 'CommonFilesDetailsController',
                        controllerAs: 'vm'
                    }
                },
                resolve: {
                    fileResponse: function(apiClient, $stateParams) {
                        return apiClient.get($stateParams.fileResource.decode()).then(function(response) {
                            return response || apiClient.noResourceFound();
                        });
                    }
                },
                ncyBreadcrumb: {
                    label: '{{ ::$resolves().fileResponse.title }}'
                }
            })
            .state('dashboard.user.spillages.list.details.files.details.edit', {
                url: '/edit',
                parent: 'dashboard.user.spillages.list.details.files.details',
                views: {
                    'files@dashboard.user.spillages.list.details': {
                        templateUrl: '/modules/common/files/add-edit/files-add-edit.html',
                        controller: 'CommonFilesAddEditController',
                        controllerAs: 'vm'
                    }
                },
                resolve: {
                    config: function(siteResponse, spillageResponse, config) {
                        return angular.extend(config, {
                            entitySite: siteResponse,
                            associatedEntity: spillageResponse,
                            fileCreateLink: spillageResponse.getLink('files')
                        });
                    },
                    tagCollectionResponse: function(apiClient, rootResourceResponse) {
                        return apiClient.get(rootResourceResponse.getLink('tags'), { limit: 30 }).then(function(response) {
                            return response || apiClient.noResourceFound();
                        });
                    },
                    integrationDocTypesResponse: function() {
                        return null;
                    }
                },
                ncyBreadcrumb: {
                    label: '{{ ::"EDIT" | translate }}'
                }
            })
            .state('dashboard.user.spillages.list.details.files.add', {
                url: '/add',
                parent: 'dashboard.user.spillages.list.details.files',
                views: {
                    'info@dashboard.user.spillages.list.details': {
                        templateUrl: '/modules/common/files/add-edit/files-add-edit.html',
                        controller: 'CommonFilesAddEditController',
                        controllerAs: 'vm'
                    }
                },
                resolve: {
                    fileResponse: function() {
                        return {};
                    },
                    tagCollectionResponse: function(apiClient, rootResourceResponse) {
                        return apiClient.get(rootResourceResponse.getLink('tags'), { limit: 30 }).then(function(response) {
                            return response || apiClient.noResourceFound();
                        });
                    },
                    config: function(siteResponse, spillageResponse, config) {
                        return angular.extend(config, {
                            entitySite: siteResponse,
                            associatedEntity: spillageResponse,
                            fileCreateLink: spillageResponse.getLink('files')
                        });
                    },
                    integrationDocTypesResponse: function() {
                        return null;
                    }
                },
                ncyBreadcrumb: {
                    label: '{{ ::"ADD" | translate }}'
                }
            });
    }

    function registerRoutesDetailsWorkflow($stateProvider) {
        elbSpillageWorkflowRouting().addRoutes($stateProvider, 'dashboard.user.spillages.list.details.status', true);
    }
})();
