(function() {
    'use strict';

    angular
        .module('elogbooks.user.escalations')
        .config(registerRoutes)
        .config(registerRoutesJobs)
        .config(registerRoutesQuotes)
        .config(registerRoutesWorkflow);

    function registerRoutes($stateProvider) {
        $stateProvider
            .state('dashboard.user.escalations', {
                abstract: true,
                template: '<bookerize></bookerize>',
                ncyBreadcrumb: {
                    skip: true
                }
            })
            .state('dashboard.user.escalations.list', {
                url: '/escalations?page&limit&order&id&escalationStage&region',
                parent: 'dashboard.user.escalations',
                resolve: {
                    escalationCollectionResponse: function (paramsService, $stateParams, apiClient, user, selectedSiteResponse) {
                        var params = {
                            active: true
                        };

                        if (selectedSiteResponse) {
                            params.site = selectedSiteResponse.id;
                        }

                        return apiClient.get(user.getLink('escalations'), angular.extend({}, paramsService, params)).then(function(response) {
                            return response || apiClient.noResourceFound();
                        });
                    },
                    escalationStageCollectionResponse: function(apiClient, rootResourceResponse) {
                        return apiClient.get(rootResourceResponse.getLink('escalationstages')).then(function(response) {
                            return response || apiClient.noResourceFound();
                        });
                    }
                },
                params: {
                    page: "1",
                    limit: "30",
                    order: "-escalationStage",
                    id: null,
                    escalationStage: null,
                    serviceProviderName: null,
                    escalated: 1,
                    region: null
                },
                views: parseBook({
                    'elogbooks-left@dashboard.user.escalations': {
                        templateUrl: '/modules/user/escalations/escalations.html',
                        controller: 'EscalationsController',
                        controllerAs: 'vm'
                    }
                }),
                ncyBreadcrumb: {
                    label: '{{ ::"ESCALATION_LIST" | translate }}'
                }
            })
            .state('dashboard.user.escalations.list.details', {
                url: '/{resource}',
                parent: 'dashboard.user.escalations.list',
                abstract: true,
                views: parseBook({
                    'elogbooks-right@dashboard.user.escalations': {
                        templateUrl: '/modules/user/escalations/details/escalation-details.html',
                        controller: 'EscalationDetailsController',
                        controllerAs: 'vm'
                    },
                    'feed@dashboard.user.escalations.list.details': {
                        templateUrl: '/modules/user/escalations/details/event/feed.html',
                        controller: 'EscalationDetailsEventFeedController',
                        controllerAs: 'vm'
                    }
                }),
                resolve: {
                    escalationResponse: function(apiClient, $stateParams, paramsService) {
                        var params = {
                            datagroup: 'details',
                            site: paramsService.site,
                            userId: paramsService.userId,
                            siteRegionId: paramsService.region,
                            siteAssociateType: paramsService.siteAssociateType
                        };

                        return apiClient.get($stateParams.resource.decode(), params).then(function(response) {
                            return response || apiClient.noResourceFound();
                        });
                    },
                    escalationStageResponse: function(apiClient, escalationResponse) {
                        return apiClient.get(escalationResponse.getLink('escalationstage')).then(function(response) {
                            return response || apiClient.noResourceFound();
                        });
                    },
                    // escalationStageSiteAssociateCollectionResponse: function(apiClient, escalationStageResponse) {
                    //     return apiClient.get(escalationStageResponse.getLink('siteassociates'))
                    // },
                    eventCollectionResponse: function(apiClient, escalationResponse) {
                        return apiClient.get(escalationResponse.getLink('events'), { datagroup: 'details' }).then(function(response) {
                            return response || apiClient.noResourceFound();
                        });
                    },
                    messageCollectionResponse: function(apiClient, escalationResponse)  {
                        return apiClient.get(escalationResponse.getLink('messages')).then(function(response) {
                            return response || apiClient.noResourceFound();
                        });
                    },

                    /**
                     * While unused, user is required as a dependency here to prevent race conditions against userManager.
                     * Do not remove it!
                    */
                    config: function(user, userManager) {
                        return {
                            isHelpdesk: userManager.hasPermission('site_permission_helpdesk')
                        };
                    }
                },
                ncyBreadcrumb: {
                    label: '{{ ::$resolves().escalationResponse.getLinkAttribute("serviceprovider", "title") }}'
                }
            });
    }

    function registerRoutesWorkflow($stateProvider) {
        $stateProvider
            .state('dashboard.user.escalations.list.details.workflow', {
                url: '/workflow',
                parent: 'dashboard.user.escalations.list.details',
                views: {
                    'info@dashboard.user.escalations.list.details': {
                        templateUrl: '/modules/user/escalations/details/status/status.html'
                    }
                },
                resolve: {
                    formData: function() {
                        return {};
                    }
                },
                ncyBreadcrumb: {
                    label: '{{ ::"ESCALATION_STATUS" | translate }}'
                },
                scroll: false
            })
            .state('dashboard.user.escalations.list.details.workflow.progress', {
                url: '/progress',
                parent: 'dashboard.user.escalations.list.details.workflow',
                views: {
                    'info@dashboard.user.escalations.list.details': {
                        templateUrl: '/modules/user/escalations/details/workflow/_wrapper.html',
                        controller: 'EscalationProgressController',
                        controllerAs: 'vm'
                    }
                },
                resolve: {
                    formData: function(apiClient, escalationResponse) {
                        return apiClient.get(escalationResponse.getLink('nextescalationstage')).then(function(response) {
                            if (!response) {
                                return apiClient.noResourceFound();
                            }

                            return {
                                alarmAt: new Date((+new Date) + (parseInt(response.reviewAt, 10) * 1000))
                            };
                        });
                    }
                },
                ncyBreadcrumb: {
                    label: '{{ ::"ESCALATION_PROGRESS" | translate }}'
                }
            })
            .state('dashboard.user.escalations.list.details.workflow.postpone', {
                url: '/postpone',
                parent: 'dashboard.user.escalations.list.details.workflow',
                views: {
                    'info@dashboard.user.escalations.list.details': {
                        templateUrl: '/modules/user/escalations/details/workflow/_wrapper.html',
                        controller: 'EscalationPostponeController',
                        controllerAs: 'vm'
                    }
                },
                resolve: {
                    formData: function(escalationResponse) {
                        return {
                            alarmAt: new Date(escalationResponse.alarmAt)
                        };
                    }
                },
                ncyBreadcrumb: {
                    label: '{{ ::"ESCALATION_POSTPONE" | translate }}'
                }
            })
            .state('dashboard.user.escalations.list.details.workflow.cancel', {
                url: '/cancel',
                parent: 'dashboard.user.escalations.list.details.workflow',
                views: {
                    'info@dashboard.user.escalations.list.details': {
                        templateUrl: '/modules/user/escalations/details/workflow/_wrapper.html',
                        controller: 'EscalationCancelController',
                        controllerAs: 'vm'
                    }
                },
                ncyBreadcrumb: {
                    label: '{{ ::"ESCALATION_CANCEL" | translate }}'
                }
            });
    }

    function registerRoutesJobs($stateProvider) {
        $stateProvider
            .state('dashboard.user.escalations.list.details.jobs', {
                url: '/jobs?escalationJobsPage&escalationJobsLimit&escalationJobsOrder&escalationJobsStatutory&updateToBeReviewed',
                parent: 'dashboard.user.escalations.list.details',
                views: {
                    'info@dashboard.user.escalations.list.details': {
                        templateUrl: '/modules/user/escalations/details/jobs/list.html',
                        controller: 'EscalationDetailsJobsController',
                        controllerAs: 'vm'
                    }
                },
                params: {
                    escalationJobsPage: '1',
                    escalationJobsLimit: '30',
                    escalationJobsOrder: 'id',
                    escalationJobsStatutory: null,
                    updateToBeReviewed: null
                },
                resolve: {
                    jobCollectionResponse: function(apiClient, escalationResponse, paramsService) {
                        return apiClient.get(escalationResponse.getLink('jobs'), {
                            page: paramsService.escalationJobsPage,
                            limit: paramsService.escalationJobsLimit,
                            order: paramsService.escalationJobsOrder,
                            datagroup: 'escalation',
                            statutory: paramsService.escalationJobsStatutory,
                            site: paramsService.site,
                            userId: paramsService.userId,
                            region: paramsService.region,
                            siteAssociateType: paramsService.siteAssociateType,
                            updateToBeReviewed: paramsService.updateToBeReviewed
                        }).then(function(response) {
                            return response || apiClient.noResourceFound();
                        });
                    }
                },
                ncyBreadcrumb: {
                    label: '{{ ::"JOBS" | translate }}'
                }
            });
    }

    function registerRoutesQuotes($stateProvider) {
        $stateProvider
            .state('dashboard.user.escalations.list.details.quotes', {
                url: '/quotes?escalationQuotesPage&escalationQuotesLimit&escalationQuotesOrder&updateToBeReviewed',
                parent: 'dashboard.user.escalations.list.details',
                views: {
                    'info@dashboard.user.escalations.list.details': {
                        templateUrl: '/modules/user/escalations/details/quotes/list.html',
                        controller: 'EscalationDetailsQuotesController',
                        controllerAs: 'vm'
                    }
                },
                params: {
                    escalationQuotesPage: '1',
                    escalationQuotesLimit: '30',
                    escalationQuotesOrder: 'id',
                    updateToBeReviewed: null
                },
                resolve: {
                    quoteCollectionResponse: function(apiClient, escalationResponse, $stateParams, paramsService) {
                        return apiClient.get(escalationResponse.getLink('quotes'), {
                            page: paramsService.escalationQuotesPage,
                            limit: paramsService.escalationQuotesLimit,
                            order: paramsService.escalationQuotesOrder,
                            datagroup: 'escalation',
                            userId: paramsService.userId,
                            site: paramsService.site,
                            region: paramsService.region,
                            siteAssociateType: paramsService.siteAssociateType,
                            updateToBeReviewed: paramsService.updateToBeReviewed
                        }).then(function(response) {
                            return response || apiClient.noResourceFound();
                        });
                    }
                },
                ncyBreadcrumb: {
                    label: '{{ ::"QUOTES" | translate }}'
                }
            });
    }
})();
