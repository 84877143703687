(function() {
    'use strict';

    angular
       .module('elogbooks.admin.client-billing')
       .controller('ClientBillingConfigController', ClientBillingConfigController);

    ClientBillingConfigController.$inject = [
        'clientBillingResponse',
        'contractManager',
        'recipientsCollection',
        'billingTypesCollectionResponse',
        'clientBillingService'
    ];

    function ClientBillingConfigController(
        clientBillingResponse,
        contractManager,
        recipientsCollection,
        billingTypesCollectionResponse,
        clientBillingService
    ) {
        var vm = this;

        vm.clientBilling = clientBillingResponse;
        vm.contractManager = contractManager;
        vm.recipients = null;
        vm.clientBillingService = clientBillingService;

        vm.billingTypes = billingTypesCollectionResponse.billingTypes;

        if (recipientsCollection) {
            vm.recipients = recipientsCollection.users;

            vm.recipients = vm.recipients.filter(function( obj ) {
                if (contractManager) {
                    return obj.id !== contractManager.id;
                }

                return true;
            });
        }
    };
})();