app.config( function($translateProvider) {
    $translateProvider.translations('en-gb', {
        METER_UNIT_INFORMATION: 'Meter Unit Information',
        SYMBOL: 'Unit Symbol',

        ADD_NEW_UNIT_TYPE: 'Add a new Unit Type',
        EDIT_METER_UNIT: 'Edit Meter Type',

        METER_UNIT: 'Meter Unit',
        METER_UNIT_LIST: 'Meter Units',
        METER_UNITS_LIST: 'Meter Units',
        METER_UNIT_ADD: 'Add a new Meter Unit',
        METER_UNIT_EDIT: 'Edit Meter Unit',

        METER_UNIT_CREATED: 'Meter Unit created successfully',
        METER_UNIT_UPDATED: 'Meter Unit updated successfully',
        METER_UNIT_DELETED: 'Meter Unit deleted successfully'
    });
});
