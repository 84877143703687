(function() {
    'use strict';

    angular
        .module('elogbooks.common.quote-requests')
        .controller('QuoteAlarmController', QuoteAlarmController);

    QuoteAlarmController.$inject = ['apiClient', '$state', 'messenger', 'quoteResponse', 'user', 'moment'];

    function QuoteAlarmController(apiClient, $state, messenger, quoteResponse, user, moment) {

        var vm = this;
        vm.quote = quoteResponse;
        vm.action = 'alarm';
        vm.translation = vm.quote.alarmAt ? 'QUOTE_WORKFLOW_ALARM' : 'QUOTE_WORKFLOW_ALARM_SETNEW';
        vm.noteRequired = true;
        vm.submit = submitAction;
        vm.partial = vm.action;

        vm.data = {};
        vm.data.alarmLock = quoteResponse.alarmLock;
        vm.data.alarmAt = quoteResponse.alarmAt ? new Date(quoteResponse.alarmAt) : null;

        function submitAction() {
            var currentState = $state.current.name;
            var parentListState = currentState.substring(0, currentState.lastIndexOf('.list') + 5);
            var reloadState = parentListState;

            return apiClient.update(vm.quote.getLink(vm.action), vm.data).then(function (response) {
                if (response) {
                    return $state.go('^', {}, { reload: reloadState }).then(function () {
                        messenger.success(vm.translation + '_SUCCESS');
                    });
                }

                messenger.error('REQUEST_ERROR');
            });
        }
    }
})();
