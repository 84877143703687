(function () {
    'use strict';

    angular
        .module('elogbooks.common.priorities')
        .controller('PrioritiesListController', PrioritiesListController);

    PrioritiesListController.$inject = ['$state', '$stateParams', '$filter', 'prioritiesCollection', 'lodash', 'confirmationModal', 'apiClient', 'messenger', 'priorityCreateType'];

    function PrioritiesListController ($state, $stateParams, $filter, prioritiesCollection, lodash, confirmationModal, apiClient, messenger, priorityCreateType) {
        var vm = this;
        vm.prioritiesCollection = prioritiesCollection;
        vm.priorities = prioritiesCollection.priorities;
        vm.priorities._links = prioritiesCollection._links;
        vm.priorities.canCreate = canCreatePriorities();
        vm.entityCount = prioritiesCollection.count;
        vm.order = order;
        vm.search = search;

        var statusOptions = [
            {
                title: $filter('translate')('NONE_SELECTED'),
                value: null
            },
            {
                title: $filter('translate')('STATUS_ACTIVE'),
                value: 'yes'
            },
            {
                title: $filter('translate')('STATUS_INACTIVE'),
                value: 'no'
            }
        ];

        vm.criteria = {
            priorityName: { type: 'text', value: $stateParams.priorityName, title: 'NAME' },
            priorityStatus: { type: 'options', value: $stateParams.priorityStatus, options: statusOptions, title: 'STATUS' },
        };

        function order (key) {
            $stateParams.priorityOrder = key;

            var override = {
                priorityPage: 1
            };

            $state.go('.', angular.extend({}, $stateParams, override), { reload: $state.current });
        }

        function search (params) {
            var override = {
                priorityPage: 1
            };

            $state.go('.', angular.extend({}, params, override), { reload: $state.current });
        }

        function canCreatePriorities() {
            return !!vm.prioritiesCollection.getLink(priorityCreateType);
        }
    }
})();
