(function () {
    'use strict';

    angular
        .module('elogbooks.admin.sites')
        .controller('QuoteThresholdInfoController', QuoteThresholdInfoController);

    QuoteThresholdInfoController.$inject = ['quoteThresholdResponse'];

    function QuoteThresholdInfoController(quoteThresholdResponse) {
        var vm = this;
        vm.threshold = quoteThresholdResponse;
    }
})();
