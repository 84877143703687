(function () {
    'use strict';

    angular
        .module('elogbooks.user.duty-management')
        .controller('DutyManagementController', DutyManagementController);

    function DutyManagementController($translate, $stateParams, $state, messenger, apiClient, rootResourceResponse, dutyManagementScheduleCollection) {
        var vm = this;
            vm.calendarTranslations = {
                monthNames: translateKeys(["JANUARY", "FEBRUARY", "MARCH", "APRIL", "MAY", "JUNE", "JULY", "AUGUST", "SEPTEMBER", "OCTOBER", "NOVEMBER", "DECEMBER"]),
                dayNames: translateKeys(["CAL_SUNDAY", "CAL_MONDAY", "CAL_TUESDAY", "CAL_WEDNESDAY", "CAL_THURSDAY", "CAL_FRIDAY", "CAL_SATURDAY"]),
                dayNamesShort: translateKeys(["CAL_SUNDAY_SHORT", "CAL_MONDAY_SHORT", "CAL_TUESDAY_SHORT", "CAL_WEDNESDAY_SHORT", "CAL_THURSDAY_SHORT", "CAL_FRIDAY_SHORT", "CAL_SATURDAY_SHORT"])
            };
            vm.criteria = {
                month: { type: 'options', title: 'MONTH', value: parseInt($stateParams.month), options: vm.calendarTranslations.monthNames.map(function(item, key){return {value:key, title:item}}) },
                year: { type: 'number', title: 'YEAR', value: parseInt($stateParams.year), min: 2000 }
            };
            vm.dutyManagementCollection = dutyManagementScheduleCollection;

            vm.eventSources = [
                dutyManagementScheduleCollection.dutyManagementSchedules.map(function (item) {
                    if (typeof item === 'undefined'){
                        return;
                    }

                    return  {
                        title: item._links.user.title,
                        href: item._links.self.href,
                        start: item.recurrence.start,
                        end: item.recurrence.end
                    }
                })
            ];

            vm.uiConfig = {
                calendar:{
                    defaultDate: moment([$stateParams.year, $stateParams.month, 1]),
                    height: 500,
                    editable: false,
                    header:{
                        left: '',
                        center: '',
                        right: ''
                    },
                    eventClick: eventClickAction
                },
                dayNames : vm.calendarTranslations.dayNames,
                dayNamesShort : vm.calendarTranslations.dayNamesShort
            };
            vm.filter = filterAction;

        function translateKeys(keys) {
            var translations = [];

            for (var key in keys) {
                translations.push($translate.instant(keys[key]));
            }

            return translations;
        }

        function filterAction (params) {
            $state.go('.', angular.extend({}, params), { reload: $state.current.name });
        }

        function eventClickAction(event){
            $state.go('dashboard.user.dutymanagement.details', {resource : event.href.encode()});
        }
    }
})();
