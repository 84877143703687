angular.module('elogbooksServices').service('fileAdvancedFiltersModal', ['$uibModal', 'advancedFilterService', function ($uibModal, advancedFilterService) {

    return {
        open: function (collections, resources, config) {
            return $uibModal.open({
                templateUrl: '/modules/common/modals/advancedFilters/modal-advanced-filters-modal.html',
                size: 'xl',
                controller: 'advancedFileFiltersModalController',
                controllerAs: 'vm',
                resolve: {
                    config: function () {
                        var defaultConfig = {
                            view: 'file',
                            titleMessage: 'ARE_YOU_SURE_HEADER',
                            bodyMessage: 'ARE_YOU_SURE'
                        };

                        return angular.extend({}, defaultConfig, config);
                    },
                    rootResource: resources.root
                }
            });
        }
    };
}]);

