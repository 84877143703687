(function() {
    'use strict';

    angular
        .module('elogbooks')
        .config(Translations);

    function Translations($translateProvider) {
        $translateProvider.translations('nl', {
            ACTION_TYPE: 'Actietype',
            ACTION_TYPES: 'Actietypes',
            ACTION_TYPE_LIST: 'Actietypes',

            ACTION_TYPE_ADD: 'Voeg een nieuwe Actietype toe',
            ACTION_TYPE_EDIT: 'Verander Actietype',

            ACTION_TYPE_CREATED: 'Actietype aangemaakt',
            ACTION_TYPE_UPDATED: 'Actietype aangepast',
            ACTION_TYPE_DELETED: 'Actietype verwijderd',
            CANNOT_USE_ACTION_TYPE_RESERVED_NAME: 'Kan deze naam niet gebruiken (gereserveerd)'
        });
    }
})();
