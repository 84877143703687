(function() {
    'use strict';

    angular
        .module('elogbooks.common.quote-requests')
        .controller('CommonQuoteRequestDetailsQuoteDetailsOptionsController', CommonQuoteRequestDetailsQuoteDetailsOptionsController);

    CommonQuoteRequestDetailsQuoteDetailsOptionsController.$inject = ['quoteOptionCollectionResponse', 'quoteResponse', '$state'];

    function CommonQuoteRequestDetailsQuoteDetailsOptionsController(quoteOptionCollectionResponse, quoteResponse, $state) {
        var vm = this;
        vm.quoteOptionCollectionResponse = quoteOptionCollectionResponse;
        vm.quoteResponse = quoteResponse;
        const baseRoute = 'dashboard.user.';
        vm.parentRoute =
            baseRoute +
            ($state.current.name.includes('sites.list.details')
                ? 'sites.list.details.quote-requests.list.details.quote'
                : 'quote-requests.list.details.quote');
        
    }
})();
