app.config(function ($translateProvider) {
    $translateProvider.translations('en-gb', {
        TASK: 'Task',
        TASKS: 'Tasks',
        TASKS_MANAGE_TASKS: 'Manage Tasks',
        TASKS_GENERATE_PLANNER: 'Generate Planner',
        TASK_GENERATE_PLANNER: 'Generate Planner',
        TASK_GENERATE_DATES: 'Generation Date Range',
        TASKS_VIEW_PLANNER: 'View Planner',
        TASKS_IMPORT: 'Import Tasks',
        TASKS_EXPORT_FREQUENCIES:'Export Task Frequencies',
        VIEW_PLANNER: 'View Planner',
        MASTER_TASKS_PLANNER: 'Master Task Planner',
        TASK_CREATE_PROCESS_CONTINUE: 'Continue',
        TASK_CREATE_PROCESS_CREATE_ASSET: 'Add Asset',
        TASK_CREATE_STEP_1: 'Create task',
        SERVICE_TYPE_TIP: 'Hard Services will require asset.',
        LIST_TASKS: 'Tasks',
        TASK_PLANNER: 'Planner',
        TASK_EVENT_ADD: 'Add Task Event',
        TASK_EVENT_EDIT: 'Edit Task Event',
        PLANNER: 'Planner',
        PPM_PLANNER: 'PPM Planner',
        TASK_ADD: 'Add Task',
        TASK_EDIT: 'Edit Task',
        TASK_GENERATE: 'Generate tasks',
        TASK_STATUS: 'Task status',
        TASKS_NO_RESULTS: 'No results',
        STATUS_ALL: 'All',
        SELECT_FREQUENCY: 'Select frequency',
        SELECT_INTERVAL: 'Select interval',
        SELECT_PREFIX: 'Select prefix',
        SELECT_DAY: 'Select day',
        SELECT_DATE: 'Select date',
        SELECT_DAY_NUMBER: 'Select day',
        SELECT_MONTH: 'Select month',
        SELECT_ALL_FIELDS_ERROR: 'Please select all fields',
        AT_LEAST_ONE_FREQUENCY_REQUIRED: 'At least one frequency is required',
        REJECT_SELECTED: 'Reject Selected ({{ count }})',
        REJECT_FILTERED: 'Reject All Filtered',
        APPROVE_SELECTED: 'Approve Selected ({{ count }})',
        APPROVE_FILTERED: 'Approve All Filtered',
        REMOVE_SELECTED: 'Remove Selected',
        REMOVE_FILTERED: 'Remove All Filtered',
        CLEAR_SELECTED: 'Clear Selected ({{ count }})',
        CLEAR_FILTERED: 'Clear All Filtered',
        GENERATE_SELECTED: 'Generate Selected ({{ count }})',
        GENERATE_FILTERED: 'Generate All Filtered',
        SEND_FOR_APPROVAL: 'Send for Approval',
        SEND_SELECTED_FOR_APPROVAL: 'Send Selected for Approval ({{ count }})',
        SEND_FILTERED_FOR_APPROVAL: 'Send All Filtered for Approval',
        INTERVAL: 'Interval',
        RULE: 'Rule',
        RULES: 'Rules',
        DESCRIPTION: 'Description',
        DOCUMENTTYPE: 'Document Type',
        OF: 'of',
        DATE: 'Date',
        DAY_OF_THE_WEEK: 'Day of the Week',
        PLEASE_ADD_FREQUENCY: 'Please add a frequency',
        ADD_FREQUENCY: 'Add Frequency',
        ADD_NEW_FREQUENCY: 'Add new Frequency',
        UPDATE_FREQUENCY: 'Update Frequency',
        UPDATE_TASK: 'Update task',
        MANAGE_FREQUENCIES: 'Manage Frequencies',
        EDIT_FREQUENCY: 'Edit Frequency',
        FREQUENCY: 'Frequency',
        FREQUENCIES: 'Frequencies',
        START: 'Start date',
        DATE_FROM: 'Start date',
        END: 'End date',
        DATE_TO: 'End date',
        TOTAL: 'Total',
        NO_ITEMS_SELECTED: 'No items selected',
        REASON_FOR_TASK_EVENT_REJECT: 'Please write a reason for rejection',
        TASK_GENERATION_INFO: 'task generation request(s) in queue. Please refresh planner after 1 minute.',
        TASK_APPROVAL_PENDING_INFO: '{{ count }} approval request{{ count > 1 ? "s" : "" }} pending.',
        ALLTASKS: 'Select all tasks',
        COPYFROMLASTYEAR: 'Copy from last year',
        TASK_SERVICE_PROVIDER_NOT_DEFINED: 'Service provider not defined',

        TASK_APPROVAL_STATUS: 'Task Approval Status',
        TASK_STATUS_DRAFT: 'Draft',
        TASK_STATUS_ADHOC_DRAFT: 'Ad Hoc Draft',
        TASK_STATUS_FUTURE_DRAFT: 'Future Draft',
        TASK_STATUS_FUTURE_PENDING_APPROVAL: 'Future Pending Approval',
        TASK_STATUS_PENDING_APPROVAL: 'Pending Approval',
        TASK_STATUS_REJECTED: 'Rejected',
        TASK_STATUS_APPROVED: 'Approved',
        TASK_STATUS_JOB_COMPLETED: 'Completed',
        TASK_STATUS_JOB_AWAITING_PAPERWORK: 'Awaiting Paperwork',
        TASK_STATUS_JOB_IN_PROGRESS: 'In Progress',
        TASK_STATUS_JOB_MISSED: 'Missed',
        TASK_STATUS_JOB_OVERDUE: 'Overdue',
        TASK_EVENT_STATUTORY_BASED_ON_FREQUENCY: 'Statutory based on frequency',
        TASK_STATUS_DISABLED: 'Disabled',
        TASK_EDITING_DISABLED: 'Task editing locked due to events pending approval',
        TASK_EVENT_REQUIRED_DOCUMENT_TYPE: 'Document (Type) Required',
        TASK_STATUS_SPLIT: 'Shows status underneath',
        TASK_STATUS_FUTURE_REJECTED: 'Future Rejected',
        TASK_STATUS_FUTURE_APPROVED: 'Future Approved',
        TASKS_AWAITING_APPROVAL: 'Awaiting Approval',
        TASK_STATUS_IGNORED: 'Ignored',
        TASK_IS_MANDATORY: 'Mandatory',
        SOFT_SERVICE: 'Soft Service',
        HARD_SERVICE: 'Hard Service',
        TASKS_MY_PLANNER: 'My Planner',
        SELECT_SITE_TO_CHOSE_SERVICE_PROVIDER: 'Please select site to pick service provider.',
        BULK_ASSIGN_SERVICE_PROVIDERS: 'Bulk assign Service Providers',
        TASK_SERVICE_ROUTINES_MUST_MATCH: 'The frequencies and intervals do not match asset\'s service routine.',
        TASKS_PLANNER_UPLOAD: 'Planner Imports',
        TASK_IMPORTS: 'Imports',
        CURRENT_USER_IS_NOT_A_VALID_APPROVER: 'You are not a valid PPM approver.',
        CANNOT_SEND_SO_MANY_TASKS: 'Cannot send so many tasks for approval. Limit is set to 1000. Please refine your filters',
        CANNOT_GENERATE_SO_MANY_TASKS: 'Cannot generate so many tasks. Limit is set to 1000. Please refine your filters',
        CANNOT_REMOVE_SO_MANY_TASKS: 'Cannot remove so many tasks. Limit is set to 1000. Please refine your filters',
        CANNOT_APPROVE_SO_MANY_TASKS: 'Cannot approve so many tasks. Limit is set to 1000. Please refine your filters',
        CANNOT_DECLINE_SO_MANY_TASKS: 'Cannot decline so many tasks. Limit is set to 1000. Please refine your filters',
        ONE_OR_MORE_RULES_MISSING: 'One or more frequency rules missing',
        CANCEL_SELECTED: 'Cancel Selected ({{ count }})',
        CANCEL_FILTERED: 'Cancel All Filtered',
        TASKS_SELECTED: 'Tasks selected',
        TASKS_POINT_IN_TIME: 'Tasks at this point in time match your search criteria',
        ALL_FILTERED_TASKS: 'All Filtered Tasks',
        CANCEL_TASKS: 'Cancel Tasks',
        TASKS_QUEUED_FOR_CANCELLATION: 'Tasks Queued For Cancellation',
        NO_ITEMS: 'No Items To Cancel',
        TASK_START_DATE_WARNING: 'Start date cannot be before  1st January {{ lastYear }}',
        START_DATE_ERROR: 'Start date cannot be after {{date | date:dd-MMM-yyyy}}',
        END_DATE_ERROR: 'End date cannot be before {{date | date:dd-MMM-yyyy}}',

        CANNOT_APPROVE_UNTIL_PENDING_APPROVED: 'Cannot approve, there are events pending approval',
        FOUND_AT_LEAST_ONE_PENDING: 'Action Success. Some items are pending approval',

        FREQUENCY_DESCRIPTION_100_OR_LESS: 'Frequency description must be 100 characters or less',

        GENERAL_INFORMATION: 'General Information',

        EDIT_OCCURRENCE_MODAL_TITLE: 'Would you like to edit:',
        SINGLE_OCCURRENCE: 'Just this occurrence',
        ALL_OCCURRENCES: 'All events in the frequency',
        DRAFT_EVENT_WARNING: 'Please be aware that changing all events in frequency will modify all future occurrences.',
        APPROVED_EVENT_WARNING: 'Please be aware that changing this occurrence or frequency will require approval.',
        UNSAVED_FREQUENCY_WARNING_TITLE: 'Unsaved Frequency',
        UNSAVED_FREQUENCY_WARNING: 'Please save or cancel the unsaved frequency to proceed.',
        END_DATE_MUST_BE_AFTER_START_DATE: 'End date must be after start date',
        ORIGINAL_START_DATE: 'Original Start Date:',

        DRAFT_MODE: 'Show Drafts',
        APPROVAL_WILL_CANCEL_JOBS: 'Approving this task will cancel existing jobs',
        JOBS_WILL_BE_CANCELLED_ON_APPROVAL: 'Once Approved, these changes will cancel some existing jobs',
        CANCELLATION_NOTE: 'Cancellation Note',
        VALUE_CHANGE_GENERATE_DRAFTS: 'A change you are making will require approval. The task events will be regenerated and need to be submitted for approval before they take effect',
        GENERATE: 'Generate',
        REVIEW_SERVICE_ROUTINE_CHANGES: 'Review updated frequencies in the \'Manage Frequencies\' tab',

        TASK_DUPLICATE_ERROR: 'Task with same details (name, site, service provider, asset, location, job group, operative) already exist',
        DUPLICATE_ENTITY_ERROR: 'Entity with same details already exist'
    });
});
