(function () {
    'use strict';

    angular
        .module('elogbooks.admin.sectors')
        .controller('SectorSitesEditController', SectorSitesEditController);

    SectorSitesEditController.$inject = ['$state', '$stateParams', 'messenger', 'apiClient', 'lodash', 'sectorResponse', 'siteCollectionResponse', 'selectedSiteCollectionResponse'];

    function SectorSitesEditController ($state, $stateParams, messenger, apiClient, lodash, sectorResponse, siteCollectionResponse, selectedSiteCollectionResponse ) {
        var vm = this;
        vm.sector = sectorResponse;

        vm.siteSelect = {
            response: siteCollectionResponse,
            link: siteCollectionResponse ? siteCollectionResponse.getLink('self') : null,
            responseKeyPath: 'sites',
            selectedItems: [],
        };


        vm.selectedSites = selectedSiteCollectionResponse.sites;

        if(vm.selectedSites) {
            angular.forEach(vm.selectedSites, function(site) {
                vm.siteSelect.selectedItems.push({href: site.getLink('self'), value: site.name});
            });
        }

        vm.update = updateAction;

        function updateAction() {
            var data = {
                _links: {}
            };

            if (vm.siteSelect.selectedItems.length) {
                data._links.site = vm.siteSelect.selectedItems;
            }

            apiClient.create(vm.sector.getLink('sites'), data).then(function (response) {
                if (response) {
                    $state.go('^', $stateParams, { reload: true }).then(function () {
                        messenger.success('SECTOR_SITE_ADDED');
                    });
                } else {
                    messenger.error('REQUEST_ERROR');
                }
            });
        }
    }

})();
