(function () {
    'use strict';

    angular
        .module('elogbooks.common.service-routines')
        .controller('ServiceRoutinesController', ServiceRoutinesController);

    ServiceRoutinesController.$inject = ['serviceRoutineCollectionResponse', '$stateParams', '$state', '$filter', 'assetResponse'];

    function ServiceRoutinesController(serviceRoutineCollectionResponse, $stateParams, $state, $filter, assetResponse) {
        var vm = this;
            vm.serviceRoutines = serviceRoutineCollectionResponse.routines;
            vm.serviceRoutineCollectionResponse = serviceRoutineCollectionResponse;
            vm.route = $state.current.name + '.details';
            vm.editAssetServiceRoutinesAvailable = assetResponse && assetResponse._links && !!assetResponse.getLink('edit');

        vm.order = orderAction;
        vm.search = searchAction;

        var statusOptions = [
            {
                title: $filter('translate')('STATUS_ACTIVE'),
                value: '1'
            },
            {
                title: $filter('translate')('STATUS_INACTIVE'),
                value: '0'
            },
            {
                title: $filter('translate')('NONE_SELECTED'),
                value: 'all'
            }
        ];
        var versionOptions = [
            {
                title: $filter('translate')('YES'),
                value: '1'
            },
            {
                title: $filter('translate')('NO'),
                value: '0'
            },
            {
                title: $filter('translate')('NONE_SELECTED'),
                value: null
            }
        ];

        vm.criteria = {
            serviceRoutineId: { type: 'number', title: 'ID', value: $stateParams.serviceRoutineId ? parseInt($stateParams.serviceRoutineId, 10) : null, min: 1 },
            serviceRoutineReference: { type: 'text', title: 'REFERENCE', value: $stateParams.serviceRoutineReference },
            serviceRoutineName: { type: 'text', title: 'NAME', value: $stateParams.serviceRoutineName },
            serviceRoutineDescription: { type: 'text', title: 'DESCRIPTION', value: $stateParams.serviceRoutineDescription },
            serviceRoutineStatus: { type: 'options', title: 'STATUS', value: $stateParams.serviceRoutineStatus, options: statusOptions },
            serviceRoutineVersion: { type: 'options', title: 'VERSIONED', value: $stateParams.serviceRoutineVersion, options: versionOptions }
        };

        function orderAction(key) {
            $stateParams.serviceRoutineOrder = key;

            $state.go('.', angular.extend({}, $stateParams), { reload: $state.current.name });
        }

        function searchAction (params) {
            var override = {
                serviceRoutinePage: 1
            };

            $state.go('.', angular.extend({}, params, override), { reload: $state.current.name });
        }
    };
})();
