(function() {
    angular
        .module('elogbooks.user.escalations')
        .controller('EscalationProgressController', Controller('PROGRESS', 'progress', true, 'progress'))
        .controller('EscalationCancelController', Controller('CANCEL', 'cancel', true, false))
        .controller('EscalationPostponeController', Controller('POSTPONE', 'postpone', true, 'postpone'))
    ;

    function Controller(translation, action, noteRequired, partial) {
        return function(apiClient, $state, messenger, user, escalationResponse, requestDataFactory, $scope, formData) {
            var vm = this;
            vm.escalationResponse = escalationResponse;
            vm.action = action;
            vm.translation = 'ESCALATION_WORKFLOW_' + translation;
            vm.noteRequired = noteRequired;
            vm.partial = (typeof partial === 'undefined' ? vm.action : partial);
            vm.data = requestDataFactory.createRequest(formData);

            vm.submit = submitAction;

            function submitAction () {
                return apiClient.create(vm.escalationResponse.getLink(action), vm.data).then(function (response) {
                    if (response) {
                        $state.go('^', {}, { reload: $state.get('^.^.^.^') }).then(function () {
                            messenger.success('ESCALATION_' + translation + '_SUCCESS');
                        });
                    } else {
                        messenger.error('REQUEST_ERROR');
                    }
                });
            }
        }
    }
})();
