
app.config(function ($translateProvider) {
    $translateProvider.translations('zh-hk', {
        CONTRACT: '合約',
        CONTRACTS: '合約',
        CONTRACT_AVAILABLE: '適用合約?',
        CONTRACT_BOTH: '電子及正本',
        CONTRACT_ELECTRONIC: '電子',
        CONTRACT_FORMAT: '合約格式',
        CONTRACT_HARDCOPY: '正本',
        CONTRACT_TYPE: '合約類別',
        NOTICE_PERIOD: '通知期',
        SCOPE_AVAILABLE: '適用範圍?',

        PENDING: '待批',
        ACCEPTED: '接受',
        REJECTED: '拒絕',

        CONTRACT_APPROVAL_REQUESTED: '合約要求',

        ADD_NEW_CONTRACT: '新增合約'
    });
});
