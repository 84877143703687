app.config(function ($translateProvider) {
    $translateProvider.translations('en-gb', {
        CERTIFICATE_TYPES: 'Certificate Types',
        CERTIFICATE_TYPE: 'Certificate Type',

        CERTIFICATE_TYPE_LIST: 'Certificate Types',
        CERTIFICATE_TYPE_ADD: 'Add a new Certificate Type',
        CERTIFICATE_TYPE_EDIT: 'Edit Certificate Type',

        CERTIFICATE_TYPE_CREATED: 'Certificate Type Created',
        CERTIFICATE_TYPE_UPDATED: 'Certificate Type Updated',
        CERTIFICATE_TYPE_DELETED: 'Certificate Type Deleted',

    });
});
