(function () {
    'use strict';

    angular
        .module('elogbooks.user.reports')
        .controller('EngineeringRiskModalController', EngineeringRiskModalController);

    EngineeringRiskModalController.$inject = ['params', 'collection', '$state', '$stateParams', 'jobGroupsCollectionResponse', 'paramConverterService', 'lodash'];

    function EngineeringRiskModalController(params, collection, $state, $stateParams, jobGroupsCollectionResponse, paramConverterService, lodash) {
        var vm = this;
        vm.collection = collection;
        vm.exportParams = params;
        vm.listTitle = '';
        vm.stateParams = $stateParams;
        vm.jobGroupSelect = {
            response: jobGroupsCollectionResponse,
            responseKeyPath: 'jobgroups',
            link: jobGroupsCollectionResponse ? jobGroupsCollectionResponse.getLink('self') : null,
            isHierarchical: true,
            criteriaValuePath: 'id'
        };
        vm.searchCriteria = {
            modalJobGroup: {
                type: 'jsonselectwidget',
                title: 'JOB_GROUP',
                value: $stateParams.modalJobGroup ? JSON.parse($stateParams.modalJobGroup) : null,
                options: vm.jobGroupSelect
            }
        };
        vm.search = search;

        function search(params) {
            var override = {
                modalPage: 1
            };

            $state.go('.', angular.extend($stateParams, params, override), {reload: $state.current.name});
        }
    }
})();
