angular.module('elogbooksDirectives').directive('elogbooksDateformat', [function(){
    return {
        // restrict to attributes only
        restrict: 'A',
        replace: true,
        require: ['ngModel'],
        scope: {
            datetime: '=ngModel'
        },
        templateUrl: function (elem, attr) {
            var filename = 'dateformat-absolute.html';

            switch (attr.format) {
                case 'relative':
                    filename = 'dateformat-relative.html';
                    break;
                case 'date':
                    filename = 'dateformat-date.html';
                    break;
                case 'fullDate':
                    filename = 'dateformat-fulldate.html';
                    break;
                case 'short':
                    filename = 'dateformat-short.html';
                    break;
                case 'datetime':
                    filename = 'dateformat-datetime.html';
                    break;
            }

            return '/modules/directives/date/dateformat/'+filename;
        }
    }
}]);
