(function () {
    'use strict';

    angular
        .module('elogbooksServices')
        .service('priorityService', PriorityService);

    PriorityService.$inject = ['lodash', '$translate', '$q', 'apiClient'];

    function PriorityService (lodash, $translate, $q, apiClient) {

        this.addExtraPriorities = addExtraPriorities;
        this.setupPriorities = setupPriorities;
        this.getEntityTypeOptions = getEntityTypeOptions;

        function addExtraPriorities (priorities, toBeAdded) {
            if (priorities.priorities) {
                priorities.priorities = lodash.sortBy(addExtraPriorities(priorities.priorities, toBeAdded), 'name');
                return priorities;
            }

            if (! toBeAdded) {
                toBeAdded = ['byPlannedDate', 'onNextPlannedVisit'];
            }

            var availablePriorities = {
                byPlannedDate: { _links: { self: { href: null }}, name: $translate.instant('BY_PLANNED_DATE') },
                onNextPlannedVisit: { _links: { self: { href: null }}, name: $translate.instant('JOB_NEXT_PLANNED_VISIT') }
            };

            angular.forEach(availablePriorities, function (value, index) {
                if (toBeAdded.indexOf(index) !== -1) {
                    if (! lodash.find(priorities, value)) {
                        priorities.push(value);
                    }
                }
            });

            return priorities;
        }

        function setupPriorities(partnership, serviceProvider, site, parameters) {

            var routes = {partnership: partnership, serviceProvider: serviceProvider, site: site, system: '/priorities'},
                deferred = $q.defer(),
                requests = {};

            angular.forEach(routes, function (route, index) {
                if (route === null) {
                    return;
                }

                requests[index] = apiClient.get(route, parameters);
            });

            $q.all(requests).then(function(promises) {
                if (promises.partnership && promises.partnership.count > 0) {
                    deferred.resolve(promises.partnership);
                } else if (promises.serviceProvider && promises.serviceProvider.count > 0) {
                    deferred.resolve(promises.serviceProvider);
                } else if (promises.site && promises.site.count > 0) {
                    deferred.resolve(promises.site);
                } else if (promises.system && promises.system.count > 0) {
                    deferred.resolve(promises.system);
                }
            });

            return deferred.promise;
        }

        function getEntityTypeOptions() {
            return [
                {value: 'entity_type_all', title: $translate.instant('ALL')},
                {value: 'entity_type_job', title: $translate.instant('JOBS')},
                {value: 'entity_type_quote', title: $translate.instant('QUOTES')},
                {value: 'entity_type_action', title: $translate.instant('ACTIONS')}
            ];
        }

        return this;
    }
})();
