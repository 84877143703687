(function (){
    'use strict';

    angular
        .module('elogbooks.user.tasks')
        .controller('UserTaskApprovePlannerController', UserTaskApprovePlannerController);

    UserTaskApprovePlannerController.$inject = [
        '$state',
        '$filter',
        'selectedSiteResponse',
        'rootResourceResponse',
        '$stateParams',
        'siteCollectionResponse',
        'serviceProviderCollection',
        'tasksCollectionResource',
        'statutoryTypeCollectionResponse',
        'selectedSiteFilterResponse',
        'tidyTablesService',
        'documentTypesCollectionResponse',
        'modulesService'
    ];

    function UserTaskApprovePlannerController(
        $state,
        $filter,
        selectedSiteResponse,
        rootResourceResponse,
        $stateParams,
        siteCollectionResponse,
        serviceProviderCollection,
        tasksCollectionResource,
        statutoryTypeCollectionResponse,
        selectedSiteFilterResponse,
        tidyTablesService,
        documentTypesCollectionResponse,
        modulesService
    ) {
        var vm = this;
        vm.year = $stateParams.taskYear;
        vm.tasks = tasksCollectionResource;
        vm.filter = filterAction;

        vm.tidyTables = {
            name: 'task',
            columns : {
                'SITE': true,
                'SERVICE_PROVIDER': true,
                'STATUS': false,
                'ASSET': false,
                'LOCATION': false,
                'STATUTORY': false
            }
        };

        var localTidyTable = tidyTablesService.getTidyTable(vm.tidyTables.name);

        if (localTidyTable) {
            vm.tidyTables = localTidyTable;
        }

        var statusOptions = [
            { title: $filter('translate')('NONE_SELECTED'), value: 'all' },
            { title: $filter('translate')('STATUS_ACTIVE'), value: 'active' },
            { title: $filter('translate')('STATUS_INACTIVE'), value: 'inactive' }
        ];

        var requestTypeOptions = [
            { title: $filter('translate')('ALL'), value: 'all' },
            { title: $filter('translate')('NEW'), value: 'new' },
            { title: $filter('translate')('EDITED'), value: 'edited' },
        ];

        var serviceTypeOptions = [
            {
                title: $filter('translate')('NONE_SELECTED'),
                value: null
            },
            {
                title: $filter('translate')('SOFT_SERVICE'),
                value: '0'
            },
            {
                title: $filter('translate')('HARD_SERVICE'),
                value: '1'
            }
        ];

        vm.statutoryTypesSelect = {
            response: statutoryTypeCollectionResponse,
            link: statutoryTypeCollectionResponse.getLink('self'),
            responseKeyPath: 'statutorytypes',
            itemValuePath: 'name',
            itemHrefPath: 'id'
        };

        vm.siteSelect = {
            response : siteCollectionResponse,
            link : siteCollectionResponse ? siteCollectionResponse.getLink('self') : null,
            responseKeyPath: 'sites',
            itemValuePath: 'name',
            itemHrefPath: 'id',
            disabled: $stateParams.selectedSiteResource
        };

        if (selectedSiteFilterResponse) {
           vm.siteSelect.selected = {title: selectedSiteFilterResponse.name};
           vm.siteSelect.singleResult = true;
           vm.siteSelect.selectedItems = [{value: selectedSiteFilterResponse.name, href: selectedSiteFilterResponse.id, object: selectedSiteFilterResponse}];
        }

        if (selectedSiteResponse) {
            vm.siteSelect.cacheItems= [{value:selectedSiteResponse.name, href:selectedSiteResponse.id, object:selectedSiteResponse}];
        }

        vm.criteria = {
            taskYear: { type: 'number', title: 'YEAR', value: parseInt($stateParams.taskYear), min: 2000 },
            siteIds: { type: 'autocompletemulti', multiple: true, title: 'SITE', value: $stateParams.siteIds, options: vm.siteSelect, param: 'siteIds', max: 50 },
            taskName: { type: 'text', value: $stateParams.taskName, title: 'TASK' },
            taskStatus: { type: 'options', title: 'TASK_STATUS', value: $stateParams.taskStatus, options: statusOptions},
            taskServiceType: { type: 'options', title: 'SERVICE_TYPE', value: $stateParams.taskServiceType, options: serviceTypeOptions },
            taskStatutoryType:{ type: 'autocompletemulti', multiple: true, title: 'STATUTORY_TYPE', value: $stateParams.taskStatutoryType, options: vm.statutoryTypesSelect, param: 'taskStatutoryType', max: 50 }
        };
        if (modulesService.isEnabled('advanced_ppm_planner')) {
            vm.criteria.taskRequestType = { type: 'options', title: 'NEW_EDITED', value: $stateParams.taskRequestType, options: requestTypeOptions};
        }
        if (documentTypesCollectionResponse && documentTypesCollectionResponse.count > 0) {
            vm.documentTypeSelect = {
                link : documentTypesCollectionResponse.getLink('self'),
                linkParameters: { status: 'active', order: 'name' },
                responseKeyPath: 'documentTypes',
                itemValuePath: 'name',
                itemHrefPath: 'id'
            };

            vm.criteria.documentTypes = {
                type: 'autocompletemulti',
                multiple: true,
                title: 'DOCUMENT_TYPE',
                options: vm.documentTypeSelect,
                param: 'documentTypes',
                value: $stateParams.documentTypes,
                max: 50
            };
        }

        if (serviceProviderCollection) {
            vm.serviceProviderSelect = {
                response : serviceProviderCollection,
                link : serviceProviderCollection ? serviceProviderCollection.getLink('self') : null,
                responseKeyPath: 'serviceproviders'
            };

            vm.criteria.taskServiceProviderName = { type: 'selectwidget', title: 'SERVICE_PROVIDER', value: $stateParams.taskServiceProviderName, options: vm.serviceProviderSelect };
        }

        vm.resources = {
            'rootResource': rootResourceResponse,
            'stateParams': $stateParams,
            'root': rootResourceResponse
        };

        function filterAction (params) {
            var override = {
                fromDashboard: false,
                taskPage: 1,
            };

            $state.go('.', angular.extend({}, params, override), { reload: $state.current.name });
        }
    }
})();
