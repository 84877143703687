angular.module('elogbooksServices').service('notificationsService', notificationsService);

function notificationsService(apiClient, $state, $q) {
    const typeCategories = (() => {
        const categories = {};

        const addCategory = (id, regex) => {
            categories[id] = {
                name: id.toUpperCase(),
                pattern: regex
            }
        };

        addCategory('NOTIFICATION_TYPE_JOB',  /^job_/i);
        addCategory('NOTIFICATION_TYPE_QUOTE', /^quote_/i);
        addCategory('NOTIFICATION_TYPE_SURVEY', /^survey/i);
        addCategory('NOTIFICATION_TYPE_ACTIONS',  /^action_/i);
        addCategory('NOTIFICATION_TYPE_ESCALATION', /^escalation_/i);
        addCategory('NOTIFICATION_TYPE_SERVICEPROVIDER', /^serviceprovider_/i);
        addCategory('NOTIFICATION_TYPE_TASK', /^(task|ppm)_/i);
        addCategory('NOTIFICATION_TYPE_EXPORT', /^export_/i);
        addCategory('NOTIFICATION_TYPE_IMPORT', /^import_/i);
        addCategory('NOTIFICATION_TYPE_SPILLAGE', /^spillage_/i);
        addCategory('NOTIFICATION_TYPE_CONTRACT', /^contract_/i);
        addCategory('NOTIFICATION_TYPE_ADMINISTRATION',  /^(password|user|forgot_username|report)_/i);
        addCategory('NOTIFICATION_TYPE_BULKACTION', /^bulkaction/i);
        addCategory('NOTIFICATION_TYPE_ASSETS', /^score_types/i);
        addCategory('NOTIFICATION_TYPE_AUDIT', /^audit_/i);
        addCategory('NOTIFICATION_TYPE_FILE', /^file_/i);

        return categories;
    })();

    return {
        escapeSummary: (notification) => notification.summary.trim().replace(/\\n/, ''),
        getTypeCategories: () => typeCategories,
        getTypeCategory: (notification) => {
            let type = notification.type;
            let text = '';

            for (let i in typeCategories) {
                if (typeCategories[i].pattern.test(type)) {
                    return typeCategories[i].name;
                }
            }

            // if a type is not recognized
            return 'NOTIFICATION_TYPE_' + type.toUpperCase();
        },
        markAsRead: (notification) => $q((resolve, reject) => typeof(notification.readAt) === 'undefined'
            ? apiClient.update(notification.getLink('markasread'), {}).then(resolve).catch(reject)
            : resolve()
        ),
        goToNotificationLink: (notification) => {
            var params = notification.params;

            if (notification.resourceName) {
                params[notification.resourceName] = notification._links.resource.href.encode();
            }

            if (notification.resourceStateName
                && notification.resourceStateName === 'dashboard.user.files.list.details'
                && notification.resourceName) {
                params['fileId'] = parseInt(notification._links.resource.href.replace('/files/', ''));
            }

            if (notification.type === 'file_expiry_today') {
                params['fileExpiresDateStart'] = notification.createdAt;
                params['fileExpiresDateEnd'] = notification.createdAt;
            }

            if (notification.type === 'bulkactioncancelled_finished') {
                params['jobStatus'] = ['cancelled'];
            }

            if (notification.type === 'file_expiry') {
                var expiryTime = elbSettings.getSetting('file_expiry_notification_time').value;
                params['fileExpiresDateStart'] = notification.createdAt;
                params['fileExpiresDateEnd'] =  moment(notification.createdAt).add(expiryTime, 'days').endOf('day').toISOString();
            }

            $state.go(notification.resourceStateName, params, { inherit: false });
        }
    }
}
