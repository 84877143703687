(function() {
    'use strict';

    angular
        .module('elogbooks.common.document-sets')
        .controller('CommonDocumentSetAddController', CommonDocumentSetAddController);

    CommonDocumentSetAddController.$inject = [
        '$state',
        'crudService',
        'messenger',
        'documentSetCollectionResponse',
        'selectedDocumentSetCollectionResponse'
    ];

    function CommonDocumentSetAddController(
        $state,
        crudService,
        messenger,
        documentSetCollectionResponse,
        selectedDocumentSetCollectionResponse

    ) {
        var vm = this;
        vm.documentSetCollectionResponse = documentSetCollectionResponse;
        vm.selectedDocumentSetCollectionResponse = selectedDocumentSetCollectionResponse;
        vm.documentSet = {
            required: true,
            linkParameters: { status: 'active' },
            responseKeyPath: 'documentSets',
            itemHrefPath: '_links.self.href'
        };

        vm.create = createDocumentSet;

        if (documentSetCollectionResponse.count > 0) {
            vm.documentSet.response = documentSetCollectionResponse;
            vm.documentSet.link = documentSetCollectionResponse ? documentSetCollectionResponse.getLink('self') : null;
        }

        function createDocumentSet() {
            if (!vm.documentSet.response) {
                return;
            }

            var data = {
                _links: {
                    documentSet: {
                        href: vm.documentSet.selected.href
                    }
                }
            };

            return crudService.create(
                vm.selectedDocumentSetCollectionResponse.getLink('create'),
                data,
                null,
                null,
                'DOCUMENT_SET',
                true
            ).then(function () {
                $state.go('^', {}, {reload: $state.get('^.^')}).then(function () {
                    messenger.success('DOCUMENT_SET_ADDED');
                });
            });

        }
    }
})();
