app.config(function ($translateProvider) {
    $translateProvider.translations('zh-hk', {
        GO_TO_JOB: '工作',
        GO_TO_QUOTE: '報價',
        SITE_QUICK_ACTIONS: '場地快速行動',
        RAISE_JOB: '上報工作',
        VIEW_JOBS: '查看工作',
        RAISE_QUOTE: '上報價單',
        VIEW_QUOTES: '查看價單',
        NO_JOB_FOUND: '找不到工作.',
        NO_QUOTE_FOUND: '找不到價單.'
    });
});