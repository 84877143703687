app.config(function ($translateProvider) {
    $translateProvider.translations('en-gb', {
        SERVICE_PROVIDER_GROUP: 'Service Provider Group',
        SERVICE_PROVIDER_GROUPS: 'Service Provider Groups',

        ADD_NEW_SERVICE_PROVIDER_GROUP: 'Add a new Service Provider Group',

        SERVICE_PROVIDER_GROUP_LIST: 'Service Provider Groups',
        SERVICE_PROVIDER_GROUP_ADD: 'Add a new Service Provider Group',
        SERVICE_PROVIDER_GROUP_EDIT: 'Edit Service Provider Group',

        SERVICE_PROVIDER_GROUP_CREATED: 'Service Provider Group Created',
        SERVICE_PROVIDER_GROUP_UPDATED: 'Service Provider Group Updated',
        SERVICE_PROVIDER_GROUP_DELETED: 'Service Provider Group Deleted',

        REQUIRESASSETSWHENLOGGINGREACTIVEJOB: 'Reactive Jobs Require Assets',
        JOBVALUESAUTOAPPROVEDDAYS: 'Number Of Days Before Job Values Are Automatically Approved',
        JOB_VALUES_AUTO_APPROVED_DAYS_WARNING:  'Please note: changing the value may automatically approve a backlog of items',
        REQUIRES_ASSETS_WHEN_LOGGING_REACTIVE_JOB: 'Require Asset',
        REQUIRES_ASSET: 'Requires asset',
        NOT_REQUIRES_ASSET: 'No asset required',

        TRAINEDAT: 'Trained At',
        ACTIVEAT: 'Active At',
        INACTIVEAT: 'Inactive At',
    });
});
