app.config(function ($translateProvider) {
   $translateProvider.translations('nl', {

        QUOTE_THRESHOLD: 'Offertebedrag Mandaten',
        QUOTE_THRESHOLDS: 'Offerte mandaat bedrag',

        QUOTE_THRESHOLD_ADD: 'Voeg een nieuwe offerte mandaat toe',
        QUOTE_THRESHOLD_EDIT: 'Wijzig offerte mandaat',
        QUOTE_THRESHOLD_LIST: 'Offerte mandaten',

        FEWER_APPROVERS_THAN_THRESHOLDS: 'Er zijn meer offerte goedkeurders nodig om alle mandaten af te handelen'
   })
});
