(function () {
    'use strict';

    angular
        .module('elogbooksDirectives')
        .directive('elbExportButton', ElogbooksExportButton);

    function ElogbooksExportButton() {
        return {
            restrict: 'E',
            scope: {
                link: '=',
                params: '=?',
                count: '@',
                countMax: '@',
                title: '@',
                modalOptions: '='
            },
            transclude: true,
            templateUrl: '/modules/directives/buttons/export/export.html',
            controller: function ($scope, apiClient, messenger, $translate, $uibModal) {
                $scope.exportResources = exportResources;
                $scope.button = 'EXPORT';
                $scope.loading = false;

                if (typeof $scope.title !== "undefined") {
                    $scope.button = $scope.title;
                }

                if(typeof $scope.params == "undefined") {
                    $scope.params = {};
                } else {
                    delete $scope.params.order;
                }

                function exportResources() {
                    $scope.loading = true;
                    if ($scope.modalOptions) {
                        var modal = $uibModal.open($scope.modalOptions);

                        modal.result.then(function (response) {
                            apiClient.get($scope.link, angular.extend($scope.params, response), false).then(handleExportResponse);
                        });
                    } else {
                        apiClient.get($scope.link, $scope.params, false).then(handleExportResponse);
                    }
                }

                function handleExportResponse(response) {
                    if (response && response.id) {
                        if ($scope.count > $scope.countMax) {
                            messenger.success($translate.instant('EXPORT_IN_PROCESS_WITH_LIMIT', {limit:$scope.countMax}));
                        } else {
                            messenger.success('EXPORT_IN_PROCESS');
                        }
                    }

                    $scope.loading = false;
                }
            }
        };
    }

})();
