/**
 * Conversations controller
 */
 (function() {
     'use strict';

     angular
        .module('elogbooks.common.conversations')
        .controller('ConversationsController', ConversationsController);

    ConversationsController.$inject = ['conversationCollectionResponse', '$stateParams', '$state', '$filter'];

    function ConversationsController(conversationCollectionResponse, $stateParams, $state, $filter) {

        var vm = this;

        vm.conversations = conversationCollectionResponse.getData().conversations;
        vm.pages = conversationCollectionResponse.getData().pages;
        vm.search = search;

        vm.criteria = {
            entity:    { type: 'text',    title: 'JOB_QUOTE_ID', value: $stateParams.entity },
            contains:  { type: 'text',    title: 'CONTAINS',     value: $stateParams.contains },
            type:      { type: 'options', title: 'TYPE',         value: $stateParams.type, options: [
                    {
                        title: $filter('translate')('NONE_SELECTED'),
                        value: null
                    },
                    {
                        title: $filter('translate')('JOB'),
                        value: 'job'
                    },
                    {
                        title: $filter('translate')('QUOTE'),
                        value: 'quote'
                    }
                ]
            }
        };

        function search(params) {
            var override = {
                page: 1
            };

            $state.go('.', angular.extend({}, params, override), { reload: true });
        }
    };

 })();
