app.config(function ($translateProvider) {
    $translateProvider.translations('en-gb', {

        SURVEY_LINE: 'Survey Line',
        SURVEY_LINES: 'Survey Lines',
        SURVEY_LINE_LIST: 'Survey Lines',

        SURVEY_LINE_ADD: 'Add a new Survey Line',
        SURVEY_LINE_EDIT: 'Edit Survey Line',

        SURVEY_LINE_CREATED: 'Survey Line Created',
        SURVEY_LINE_UPDATED: 'Survey Line Updated',
        SURVEY_LINE_DELETED: 'Survey Line Deleted',
        SURVEY_LINES_UPDATED: 'Survey Lines Updated',

        VALUE_MUST_BE_A_NUMBER_BETWEEN_1_AND_5: 'Value must be a number between 1 and 5',
        COMMENT_3000_OR_LESS: "Comment must be fewer than 3000 characters"
    });
});
