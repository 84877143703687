(function() {
    'use strict';

    angular
        .module('elogbooks.admin.pricing-schedules')
        .controller('PricingSchedulesInfoController', PricingSchedulesInfoController);

    PricingSchedulesInfoController.$inject = [
        'pricingScheduleResponse',
        'rootPricingScheduleResponse'
    ];

    function PricingSchedulesInfoController(
        pricingScheduleResponse,
        rootPricingScheduleResponse
    ) {
        var vm = this;
        vm.pricingSchedule = pricingScheduleResponse;
        vm.rootPricingScheduleResponse = rootPricingScheduleResponse;
        vm.editLink = 'dashboard.admin.pricing-schedules.list.details.edit';
    }
})();
