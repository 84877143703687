app.config(function ($translateProvider) {
    $translateProvider.translations('zh-hk', {
        OPERATIVE: '工作人員',
        OPERATIVES: '工作人員',

        OPERATIVES_LIST: '工作人員',
        OPERATIVES_ADD: '新增工作人員',

        REMOVE_OPERATIVE: '移除工作人員',


    });
});
