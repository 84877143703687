(function () {
    'use strict';

    angular
        .module('elogbooks.common.performance-statistics')
        .controller('CommonPerformanceStatisticsController', CommonPerformanceStatisticsController);

    CommonPerformanceStatisticsController.$inject = ['serviceProviderResponse','serviceProviderPerformanceStatisticsResponse', 'systemPerformanceStatisticsResponse', '$state', 'moment', 'jobStatuses', 'quoteRequestService'];

    function CommonPerformanceStatisticsController (serviceProviderResponse, serviceProviderPerformanceStatisticsResponse, systemPerformanceStatisticsResponse, $state, moment, jobStatuses, quoteRequestService) {
        var vm = this;
        vm.serviceProvider = serviceProviderResponse;
        vm.serviceProviderPerformanceStatisticsResponse = serviceProviderPerformanceStatisticsResponse;
        vm.systemPerformanceStatisticsResponse = systemPerformanceStatisticsResponse;
        vm.format = formatAction;
        vm.compare = compareAction;
        vm.createLink = createLink;
        vm.statistics = [];

        // Setup dates used for filtering
        var start = moment().subtract(30, 'days');
        var startDate = moment(new Date(start)).toISOString(true);
        var startDateMonth = start.format('M');
        var startDateYear = start.format('YYYY');
        var endDate = moment(new Date()).toISOString(true);

        var statisticTypes = [{
            name: 'reactiveUpdatesAchieved',
            link: '.jobs.list',
            parameters: {
                serviceProviders: vm.serviceProvider.id,
                jobsType: 'reactive',
                jobStatus: [
                    'assignment_pending',
                    'acknowledged',
                    'commenced',
                    'awaiting_paperwork'
                ],
                jobsAttendanceDueStart: startDate,
                jobsAttendanceDueEnd: endDate,
                jobsReminderAtEnd: endDate
            }
        }, {
            name: 'reactiveAttendanceAchieved',
            link: '.jobs.list',
            parameters: {
                serviceProviders: vm.serviceProvider.id,
                jobStatus: jobStatuses.getAll('performancestatistic'),
                jobsOverdueAttendancePerformance: true,
                jobsType: 'reactive',
                jobsAttendanceDueStart: startDate,
                jobsAttendanceDueEnd: endDate
            }
        }, {
            name: 'reactiveCompletionAchieved',
            link: '.jobs.list',
            parameters: {
                serviceProviders: vm.serviceProvider.id,
                jobStatus: jobStatuses.getAll('performancestatistic'),
                jobsOverdueCompletion: true,
                jobsType: 'reactive',
                completionDueStart: startDate,
                completionDueEnd: endDate
            }
        }, {
            name: 'plannedUpdatesAchieved',
            link: '.jobs.list',
            parameters: {
                serviceProviders: vm.serviceProvider.id,
                jobsType: 'planned',
                jobStatus: [
                    'assignment_pending',
                    'acknowledged',
                    'commenced',
                    'awaiting_paperwork'
                ],
                jobsAttendanceDueStart: startDate,
                jobsAttendanceDueEnd: endDate,
                jobsReminderAtEnd: endDate
            }
        }, {
            name: 'plannedCompletionAchieved',
            link: '.jobs.list',
            parameters: {
                serviceProviders: vm.serviceProvider.id,
                jobStatus: jobStatuses.getAll('performancestatistic'),
                jobsOverdueCompletion: true,
                jobsType: 'planned',
                completionDueStart: startDate,
                completionDueEnd: endDate
            }
        }, {
            name: 'quoteSlaAchieved',
            link: '.quote-requests.list',
            parameters: {
                serviceProviders: vm.serviceProvider.id,
                quoteStatus: quoteRequestService.getQuoteStatusesAsArray('performancestatistic'),
                completionDueStart: startDate,
                completionDueEnd: endDate,
                lastSubmittedAtStart: startDate,
                lastSubmittedAtEnd: endDate,
                overdue: true,
                quoteIncludeNeverSubmitted: true,
                fromPerformanceStatistics: true
            }
        }, {
            name: 'kpiSurveys',
            link: $state.current.name.indexOf('dashboard.user.sites') > -1 ? 'dashboard.user.sites.list.details.surveys.active' : '.surveys.list',
            parameters: {
                serviceProviderName: vm.serviceProvider.name,
                reportingMonth: startDateMonth,
                reportingYear: startDateYear,
                scoreLessThan: '100',
                surveyStatus: ['pending', 'completed']
            }
        }];

        var statePrefix = $state.current.name.indexOf('dashboard.user.sites') >-1 ? '^.^.^' : 'dashboard.user';

        angular.forEach(statisticTypes, function(statistic) {
            if (typeof vm.serviceProviderPerformanceStatisticsResponse[statistic.name] !== 'undefined') {
                vm.statistics.push(
                    {
                        title: 'PERFORMANCE_TYPE_' + statistic.name.replace(/([A-Z])/g, function($1){return "_"+$1.toLowerCase();}).toUpperCase(),
                        serviceProviderScore: vm.format(vm.serviceProviderPerformanceStatisticsResponse[statistic.name]),
                        systemScore: vm.format(vm.systemPerformanceStatisticsResponse[statistic.name]),
                        compare: vm.compare(vm.serviceProviderPerformanceStatisticsResponse[statistic.name], vm.systemPerformanceStatisticsResponse[statistic.name]),
                        link: vm.createLink(statistic, statePrefix),
                    }
                );
            }
        });

        function formatAction(percentage) {
            if (percentage === 'N/A') {
                return percentage;
            }
            return percentage + '%';
        }

        function compareAction(serviceProviderScore, systemScore) {
            if (serviceProviderScore === 'N/A') {
                return 'not-applicable';
            } else if (serviceProviderScore === 100 || serviceProviderScore > systemScore) {
                return 'positive';
            } else if (serviceProviderScore === 0 || serviceProviderScore < (systemScore - 2)) {
                return 'negative';
            } else {
                return 'neutral';
            }
        }

        function createLink(statistic, prefix) {
            var link = '';

            if (statistic.link.indexOf('.') === 0) {
                link = prefix + statistic.link;
            } else {
                link = statistic.link;
            }

            return $state.href($state.get(link), statistic.parameters);
        }
    }
})();
