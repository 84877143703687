(function(){
    'use strict';

    angular
        .module('elogbooks.common.site-contacts')
        .controller('CommonSiteContactsAddEditController', CommonSiteContactsAddEditController);

    CommonSiteContactsAddEditController.$inject = [
        '$scope',
        'lodash',
        'siteResponse',
        'config',
        'siteCollectionResponse',
        'contactResponse',
        'confirmationModal',
        'apiClient',
        '$state',
        'messenger',
        'requestDataFactory',
        'userCollectionResponse',
        'userManager',
        '$q',
        'contactsResponse',
        '$stateParams'
    ];

    function CommonSiteContactsAddEditController (
        $scope,
        lodash,
        siteResponse,
        config,
        siteCollectionResponse,
        contactResponse,
        confirmationModal,
        apiClient,
        $state,
        messenger,
        requestDataFactory,
        userCollectionResponse,
        userManager,
        $q,
        contactsResponse,
        $stateParams
    ) {
        var vm = this;
        vm.site = siteResponse;
        vm.selectedSite = vm.site;
        vm.contact = contactResponse;
        vm.create = createContact;
        vm.update = updateContact;
        vm.delete = deleteContact;
        vm.changeUser = changeUser;
        vm.resetUser = resetUser;
        vm.config = config;
        vm.hideLocation = vm.config.contactType === 'contact' ? true : false;
        vm.relativeListView = vm.config.detailRoute === '.details' ? '^.^' : '^.^.^';
        vm.goToStateAfterDelete = vm.config.stateAfterDelete ? vm.config.stateAfterDelete : '^.^';
        vm.contacts = contactsResponse;
        vm.isClientAdminUser = userManager.hasPermission('user_permission_client_admin_manage_users') && !userManager.hasPermission('user_permission_manage_users');

        if (vm.config.mode === 'edit' && !vm.contact.getLink('user')) {
            vm.enterUserDetailsManually = true;
        } else {
            vm.enterUserDetailsManually = false;
        }

        // Set the data object
        vm.data = requestDataFactory.createRequest({
            _links: {
                location: lodash.has(vm.contact, '_links.location') ? vm.contact._links.location : null
            },
            id: vm.contact.id || null,
            contactInformation : vm.contact.contactInformation || null,
            name: vm.contact.name || null,
            company: vm.contact.company || null,
            type: vm.config.contactType,
            notes: vm.contact.notes,
            role: vm.contact.role,
            active: (vm.contact.active !== null ? vm.contact.active : (vm.config.contactType === 'contact' ? true : null))
        });

        if (vm.data.contactInformation) {
            angular.forEach(vm.contact.contactInformation.fields, function (field) {
                if (field.type === 'email') {
                    vm.data.email = field.value;
                }
                if (field.type === 'telephone') {
                    vm.data.phone = field.value;
                }
            });
        }

        // Site Select Widget
        vm.siteSelect = {
            link: lodash.get(siteCollectionResponse, '_links.self.href', null),
            linkParameters: { datagroup: 'search' },
            responseKeyPath: 'sites',
            required: true,
            itemHrefPath: '_links.self.href',
            itemValuePath: 'name',
            disabled: vm.site !== null,
            populateDisabled: true,
            onSelect: updateSiteSelect
        };
        if (vm.site) {
            vm.siteSelect.selected = {
                href: vm.site._links.self.href,
                object: vm.site,
                value: vm.site.name
            };
        } else {
            vm.siteSelect.selected = null;
        }

        if (vm.config.contactType === 'contact') {
            vm.selectedUserModel = {
                response: userCollectionResponse,
                link: userManager.user.getLink('users'),
                linkParameters: {
                    site: siteResponse.getData().id,
                    status: 'active',
                    clientAdminAccess: vm.isClientAdminUser
                },
                required: true,
                responseKeyPath: 'users',
                itemHrefPath: '_links.self.href',
                itemValuePath: 'name',
                onSelect: vm.changeUser,
                onRemove: vm.changeUser,
                disabled: vm.enterUserDetailsManually
            };
        } else {
            vm.selectedUserModel = null;
            vm.enterUserDetailsManually = true;
            vm.data.id = false;
        }

        if (typeof vm.selectedUser !== 'undefined') {
            vm.selectedUserModel.selected = {
                object: vm.selectedUser,
                href: vm.selectedUser.getLink('self'),
                value: vm.selectedUser.name
            };

            changeUser();
        }

        if (userManager.hasPermission('user_permission_manage_users') || userManager.hasPermission('user_permission_client_admin_manage_users')) {
            vm.hasPermission = true;
        } else {
            vm.hasPermission = false;
            vm.enterUserDetailsManually = true;
        }

        function resetUser () {
            if (vm.selectedUserModel.disabled) {
                vm.selectedUserModel.disabled = vm.enterUserDetailsManually;
                vm.selectedUserModel.required = true;
            } else {
                vm.selectedUserModel.disabled = true;
                vm.selectedUserModel.required = false;
            }

            if (vm.enterUserDetailsManually) {
                vm.data._links.user = null;
                vm.data.name = vm.data.id = vm.data.phone = vm.data.email = vm.data.company = null;
            }
        }

        function changeUser () {
            if (vm.selectedUserModel.selected && vm.selectedUserModel.selected.object) {
                vm.selectedUser = vm.selectedUserModel.selected.object;

                vm.submitDisabled = true;

                $q.all([
                    apiClient.get(vm.selectedUser.getLink('self')).then(function (response) {
                        if (typeof vm.data === 'undefined' || vm.data === null || vm.data.id != response.id) {
                            vm.user = response;
                            vm.data._links.user = vm.user.getLink('self');
                            vm.data.id = vm.user.id;
                            vm.data.phone = vm.user.telephone;
                            vm.data.name = vm.user.name;
                            vm.data.email = vm.user.email;
                            vm.submitDisabled = false;
                        }
                    })
                ]);
            } else {
                vm.data._links.user = null;
                vm.data.name = vm.data.id = vm.data.phone = vm.data.email = vm.data.company = null;
            }
        }

        // Site OnSelect() function
        // Updates the Location Select
        function updateSiteSelect(maintainLocation) {
            if (vm.siteSelect.selected !== null) {

                if (!maintainLocation) {
                    vm.data._links.location = null;
                } else if (lodash.has(vm.data, '_links.location.path')) {
                    // delete path attribute on location as causes issues with location directive
                    delete(vm.data._links.location.path);
                }

                // get site response from selected site for links etc.
                apiClient.get(vm.siteSelect.selected.object.getLink('self')).then(function (selectedSiteResponse) {

                    vm.selectedSite = selectedSiteResponse;

                    // Update location options if we're displaying the location
                    if (!vm.hideLocation) {
                        apiClient.get(vm.selectedSite.getLink('locations')).then(function (response) {
                            vm.siteLocations = response;
                        });
                    }

                });
            }
        }

        updateSiteSelect(true);

        function createContact() {

            vm.data.contactInformation = {
                fields: []
            };

            if (!vm.data._links.user) {
                if (vm.data.phone) {
                    vm.data.contactInformation.fields.push({
                        type: 'telephone',
                        value: vm.data.phone,
                    });
                }

                if (vm.data.email) {
                    vm.data.contactInformation.fields.push({
                        type: 'email',
                        value: vm.data.email,
                    });
                }
            } else {
                vm.data.phone = null;
                vm.data.name = null;
                vm.data.email = null;
            }

            apiClient.create(vm.selectedSite.getLink('contacts'), vm.data).then(function (response) {
                if (response) {
                    $state.go('^'+vm.config.detailRoute, { contactResource: response.getLink('self').encode() }, { reload: $state.get('^') }).then(function () {
                        messenger.success(vm.config.contactType.toUpperCase()+'_CONTACT_CREATED');
                    });
                } else {
                    messenger.error('REQUEST_ERROR');
                }
            });
        };

        function updateContact() {

            vm.data._links.user = vm.contact.getLink('user');

            apiClient.update(vm.contact._links.edit.href, vm.data).then(function (response) {
                if (response) {
                    $state.go('^', {}, { reload: $state.get(vm.relativeListView) }).then(function () {
                        messenger.success(vm.config.contactType.toUpperCase()+'_CONTACT_UPDATED');
                    });
                } else {
                    messenger.error('REQUEST_ERROR');
                }
            });
        };

        function deleteContact() {
            return confirmationModal.open().result.then(function (response) {
                if (response.result) {
                    apiClient.delete(vm.contact.getLink('delete')).then(function (isSuccess) {
                        if (isSuccess) {
                            var currentContactPage = parseInt($stateParams.contactPage);
                            var lastContactPage = Math.ceil(parseInt(vm.contacts.count) / parseInt(vm.contacts.limit));

                            if (
                                currentContactPage !== 1 &&
                                (parseInt(vm.contacts.count - 1) % parseInt(vm.contacts.limit)) === 0 &&
                                lastContactPage === currentContactPage
                            ) {
                                currentContactPage--;
                            }

                            $state.go(vm.goToStateAfterDelete, {contactPage: currentContactPage}, {reload: $state.$current.parent.parent.name}).then(function () {
                                messenger.success(vm.config.contactType.toUpperCase() + '_CONTACT_DELETED');
                            });
                        } else {
                            messenger.error('REQUEST_ERROR');
                        }
                    });
                }
            });
        }
    }

})();
