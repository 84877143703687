(function () {
    'use strict';

    angular
        .module('elogbooks.user.tenants')
        .config(registerRoutes)
        .config(registerRoutesList)
        .config(registerRoutesAdd)
        .config(registerRoutesDetails)
        .config(registerRoutesEdit)
        .config(registerRoutesTenantEmailer)
        .config(registerRoutesEmails)
        .config(registerRoutesEmailDetails);


    function registerRoutes($stateProvider) {
        $stateProvider
            .state('dashboard.user.tenants', {
                url: '/tenants',
                abstract: true,
                template: '<bookerize></bookerize>',
                ncyBreadcrumb: {
                    skip: true
                }
            })
            .state('dashboard.user.tenants.manage-tenants', {
                url: '/manage-tenants',
                parent: 'dashboard.user.tenants',
                abstract: true,
                views: parseBook({
                'elogbooks-left@dashboard.user.tenants': {
                    templateUrl: '/modules/user/tenants/manage-tenants/manage-tenants.html',
                    controller: 'UserManageTenantsController',
                    controllerAs: 'vm'
                }
                }),
                resolve: {
                    siteResource: function(selectedSiteResponse) {
                        return selectedSiteResponse;
                    },
                    sectionResource: function(user, apiClient, userManager) {
                        return {
                            canSeeEmailsSent: userManager.hasPermission('site_permission_email_tenants') || userManager.hasPermission('site_permission_view_email_logs')
                        };
                    }
                },
                ncyBreadcrumb: {
                    label: '{{ ::"TENANTS" | translate }}'
                }
            });
    }

    function registerRoutesList($stateProvider) {
        $stateProvider
            .state('dashboard.user.tenants.list', {
                url: '/tenants?contactPage&contactLimit&contactOrder&contactName&siteIds&contactEmail&active&region&contactRole',
                parent: 'dashboard.user.tenants.manage-tenants',
                views: parseBook({
                    'manage-tenants': {
                        templateUrl: '/modules/common/site-contacts/contacts-list.html',
                        controller: 'CommonSiteContactsController',
                        controllerAs: 'vm'
                    }
                }),
                resolve: {
                    contactsResponse: function (apiClient, $stateParams, selectedSiteResponse, user) {
                        var params = $stateParams;

                        if (selectedSiteResponse) {
                            $stateParams.site = selectedSiteResponse.getData().id;
                        } else {
                            $stateParams.site = null;
                        }
                        var selectedRegion = JSON.parse($stateParams.region);
                        if (typeof selectedRegion === 'object') {
                            var params = angular.extend({}, $stateParams, {
                                'region': $stateParams.region ? JSON.parse($stateParams.region).v : null
                            });
                        }

                        return apiClient.get(user._links.tenants.href, angular.extend({}, params, {
                            page: $stateParams.contactPage,
                            limit: $stateParams.contactLimit,
                            order: $stateParams.contactOrder,
                            name: $stateParams.contactName,
                            email: $stateParams.contactEmail,
                            site: $stateParams.site,
                            'siteIds[]': $stateParams.siteIds,
                            company: $stateParams.contactCompany,
                            role: $stateParams.contactRole
                        }));
                    },
                    config: function($window) {
                        return {
                            contactType: 'tenant',
                            detailRoute: '.details.contacts',
                            label: 'TENANT',
                            tabTitle: 'TENANTS',
                            noBorder: true,
                            selectedRegionId: $window.localStorage.getItem('regionFilter')
                        };
                    },
                    regionCollectionResponse: function(apiClient, rootResourceResponse) {
                        return apiClient.get(rootResourceResponse.getLink('regions')).then(function (response) {
                            return response || apiClient.noResourceFound();
                        });
                    }
                },
                params: {
                    contactPage: '1',
                    contactLimit: '30',
                    contactOrder: 'name',
                    contactName: null,
                    siteIds: { array: true },
                    contactEmail: null,
                    active: { value: 'yes' },
                    contactCompany: null,
                    contactRole: null,
                    region: null
                },
                ncyBreadcrumb: {
                    label: '{{ ::"TENANTS" | translate }}'
                }
            });
    }

    function registerRoutesDetails($stateProvider) {
        $stateProvider
            .state('dashboard.user.tenants.list.details', {
                url: '/details/{contactResource}',
                parent: 'dashboard.user.tenants.list',
                abstract: true,
                views: parseBook({
                    'elogbooks-right@dashboard.user.tenants': {
                        templateUrl: '/modules/user/tenants/tenant-details.html',
                        controller: 'TenantDetailsController',
                        controllerAs: 'vm'
                    }
                }),
                resolve: {
                    contactResponse: function (apiClient, $stateParams) {
                        return apiClient.get($stateParams.contactResource.decode());
                    },
                    siteResponse: function (apiClient, contactResponse) {
                        return apiClient.get(contactResponse.getLink('site')).then(function (response) {
                            return response;
                        });
                    },
                    contactInformationResponse: function (apiClient, contactResponse) {
                        var contactInformationLink = null;

                        if (contactResponse.user) {
                            contactInformationLink = contactResponse.user.getLink('contactinformation');
                        } else {
                            contactInformationLink = contactResponse.getLink('contactinformation');
                        }

                        if (contactInformationLink === null) {
                            return apiClient.noResourceFound();
                        }

                        return apiClient.get(contactInformationLink).then(function (response) {
                            return response || apiClient.noResourceFound();
                        });
                    }
                },
                ncyBreadcrumb: {
                    label: '{{ ::$resolves().contactResponse.name }}'
                }
            })
            .state('dashboard.user.tenants.list.details.contacts', {
                url: '/contacts',
                parent: 'dashboard.user.tenants.list.details',
                views: parseBook({
                    'elogbooks-right@dashboard.user.tenants.list.details': {
                        templateUrl: '/modules/common/site-contacts/details/contacts-details.html',
                        controller: 'CommonSiteContactsDetailsController',
                        controllerAs: 'vm'
                    }
                }),
                resolve: {
                    contactInformationResponse: function (apiClient, contactResponse) {
                        return apiClient.get(contactResponse.getLink('contactinformation'));
                    }
                },
                ncyBreadcrumb: {
                    label: '{{ ::"INFO" | translate }}'
                }
            });
    }

    function registerRoutesAdd($stateProvider) {
        $stateProvider
            .state('dashboard.user.tenants.list.add', {
                url: '/add',
                parent: 'dashboard.user.tenants.list',
                views: parseBook({
                    'elogbooks-right@dashboard.user.tenants': {
                        templateUrl: '/modules/common/site-contacts/add-edit/contacts-addedit.html',
                        controller: 'CommonSiteContactsAddEditController',
                        controllerAs: 'vm'
                    }
                }),
                resolve: {
                    siteCollectionResponse: function(apiClient, user, globalFilterParamsService) {
                        var params = {
                            datagroup: 'search'
                        };

                        params = angular.extend({}, params, globalFilterParamsService.getLocalStorageFilters());

                        return apiClient.get(user.getLink('sites'), params, 'long').then(function(response) {
                            return response || apiClient.noResourceFound();
                        });
                    },
                    contactResponse: function () {
                        return {};
                    },
                    siteResponse: function (selectedSiteResponse) {
                        return selectedSiteResponse;
                    }
                },
                ncyBreadcrumb: {
                    label: '{{ ::"ADD" | translate }}'
                }
            });
    }

    function registerRoutesEdit($stateProvider) {
        $stateProvider
            .state('dashboard.user.tenants.list.details.contacts.edit', {
                url: '/edit',
                parent: 'dashboard.user.tenants.list.details.contacts',
                views: parseBook({
                    'elogbooks-right@dashboard.user.tenants': {
                        templateUrl: '/modules/common/site-contacts/add-edit/contacts-addedit.html',
                        controller: 'CommonSiteContactsAddEditController',
                        controllerAs: 'vm'
                    }
                }),
                resolve: {
                    config: function () {
                        return {
                            mode: 'edit',
                            contactType: 'tenant',
                            stateAfterDelete: '^.^.^'
                        };
                    },
                    siteCollectionResponse: function(apiClient, user) {
                        return {};
                    }
                },
                ncyBreadcrumb: {
                    label: '{{ ::"EDIT" | translate }}'
                }
            });
    }

    function registerRoutesTenantEmailer($stateProvider) {
        $stateProvider
            .state('dashboard.user.tenants.list.emailer', {
                url: '/tenant-emailer',
                parent: 'dashboard.user.tenants.list',
                views: parseBook({
                    'elogbooks-right@dashboard.user.tenants': {
                        templateUrl: '/modules/user/tenants/emailer/tenant-emailer.html',
                        controller: 'TenantEmailerController',
                        controllerAs: 'vm'
                    }
                }),
                resolve: {
                    modalInstance: function($uibModal) {
                        return $uibModal;
                    },
                    siteResponse: function (selectedSiteResponse) {
                        if (selectedSiteResponse) {
                            return selectedSiteResponse;
                        }

                        return null;
                    },
                    userPreferenceResponse: function(userManager) {
                        return userManager.getPreferences();
                    }
                },
                ncyBreadcrumb: {
                    label: '{{ ::"EMAIL_TENANTS" | translate }}'
                }
            })
            .state('dashboard.user.tenants.list.emailer.view-recipients', {
                url: '/view-recipients',
                parent: 'dashboard.user.tenants.list.emailer',
                resolve: {
                    modalInstance: function(modalInstance) {
                        return modalInstance;
                    },
                    contactsResponse: function (apiClient, $stateParams, selectedSiteResponse, user, tenantEmailerService) {
                        if(tenantEmailerService.tenantsResponse.contacts.length) {
                            return tenantEmailerService.tenantsResponse;
                        } else {
                            if (selectedSiteResponse) {
                                $stateParams.site = selectedSiteResponse.getData().id;
                            } else {
                                $stateParams.site = null;
                            }

                            return apiClient.get(user._links.tenants.href, angular.extend({}, $stateParams, {
                                page: $stateParams.contactPage,
                                limit: $stateParams.contactLimit,
                                order: $stateParams.contactOrder,
                                name: $stateParams.contactName,
                                email: $stateParams.contactEmail,
                                site: $stateParams.site
                            }));
                        }
                    }
                },
                ncyBreadcrumb: {
                    label: '{{ ::"VIEW_RECIPIENTS" | translate }}'
                },
                onEnter: ['$state', 'modalInstance', 'contactsResponse', function($state, modalInstance, contactsResponse) {
                    if(!modalGlob.closed) {
                        modalGlob.dismiss();
                    }

                    modalGlob = modalInstance.open({
                        templateUrl: '/modules/user/tenants/emailer/view-all-recipients.html',
                        controller: 'ViewAllRecipientsController',
                        controllerAs: 'vm',
                        size: 'lg',
                        resolve: {
                            contactsResponse: function() { return contactsResponse; }
                        }
                    })

                    modalGlob.result.then(null, backWeGo);

                    function backWeGo(source) {
                        if(source !== undefined) {
                            $state.go('^');
                        }
                    }

                    modalGlob.closed = false;
                }]
            });
    }

    function registerRoutesEmails($stateProvider) {
        $stateProvider
            .state('dashboard.user.tenants.emails', {
                url: '/emails?ePage&eLimit&eOrder&eRecipientName&eSendBetween&eSubject',
                parent: 'dashboard.user.tenants.manage-tenants',
                views: parseBook({
                    'manage-tenants': {
                        templateUrl: '/modules/user/tenants/emails/email-list.html',
                        controller: 'TenantEmailListController',
                        controllerAs: 'vm'
                    }
                }),
                resolve: {

                    siteResource: function(selectedSiteResponse) {
                        return selectedSiteResponse;
                    },
                    contactsResponse: function (apiClient, $stateParams, selectedSiteResponse, user) {
                        return apiClient.get(user._links.tenants.href, {}).then(function (response) {
                            return response || apiClient.noResourceFound();
                        });
                    },
                    emailLogsResponse: function ($stateParams, apiClient, contactsResponse) {
                        var params = {
                            page: $stateParams.ePage,
                            limit: $stateParams.eLimit,
                            order: $stateParams.eOrder,
                            recipientName: $stateParams.eRecipientName,
                            sendBetween: $stateParams.eSendBetween,
                            subject: $stateParams.eSubject,
                        };

                        return apiClient.get(contactsResponse.getLink('emaillogs'), params).then(function (response) {
                            return response || apiClient.noResourceFound();
                        });
                    },
                    config: function() {
                        return {
                            noBorder: true
                        };
                    },

                },
                params: {
                    ePage: '1',
                    eLimit: '30',
                    eOrder: '',
                    eSendBetween: moment().subtract(1, 'year').startOf('day').format()+','+moment().endOf('day').format()
                },
                ncyBreadcrumb: {
                    label: '{{ ::"EMAILS" | translate }}'
                }
            });
    }

    function registerRoutesEmailDetails($stateProvider) {
        $stateProvider
            .state('dashboard.user.tenants.emails.details', {
                url: '/details/{emailLogResource}',
                parent: 'dashboard.user.tenants.emails',
                abstract: true,
                resolve: {
                    emailLogResponse: function($stateParams, apiClient, base64) {
                        return apiClient.get(base64.decode($stateParams.emailLogResource));
                    }
                },
                views: parseBook({
                    'elogbooks-right@dashboard.user.tenants': {
                        templateUrl: '/modules/user/tenants/emails/details/email-details.html',
                        controller: 'UserEmailDetailsController',
                        controllerAs: 'vm'
                    }
                }),
                ncyBreadcrumb: {
                    label: '{{ ::$resolves().emailLogResponse.subject }}'
                }
            })
            .state('dashboard.user.tenants.emails.details.info', {
                url: '/info',
                parent: 'dashboard.user.tenants.emails.details',
                views: parseBook({
                    'email-details': {
                        templateUrl: '/modules/user/tenants/emails/details/info/email-info.html',
                        controller: 'UserEmailInfoController',
                        controllerAs: 'vm'
                    }
                }),
                ncyBreadcrumb: {
                    label: '{{ ::"INFO" | translate }}'
                }
            })
            .state('dashboard.user.tenants.emails.details.recipients', {
                url: '/recipients?erPage&erLimit&erOrder&erName&erCompany&erEmail&erSite',
                parent: 'dashboard.user.tenants.emails.details',
                resolve: {
                    recipientCollectionResponse: function($stateParams, apiClient, emailLogResponse, selectedSiteResponse) {
                        var params = {
                            page: $stateParams.erPage,
                            limit: $stateParams.erLimit,
                            order: $stateParams.erOrder,
                            name: $stateParams.erName,
                            company: $stateParams.erCompany,
                            site: $stateParams.erSite ? JSON.parse($stateParams.erSite).v : null,
                            email: $stateParams.erEmail,
                        };

                        if (selectedSiteResponse) {
                            params.site = selectedSiteResponse.id;
                        }

                        return apiClient.get(emailLogResponse.getLink('emaillogs_recipients'), params).then(function(response) {
                            return response || apiClient.noResourceFound();
                        });
                    }
                },
                params: {
                    erPage: '1',
                    erLimit: '30',
                    erOrder: 'name'
                },
                views: parseBook({
                    'email-details': {
                        templateUrl: '/modules/user/tenants/emails/details/recipients/email-recipients.html',
                        controller: 'UserEmailRecipientsController',
                        controllerAs: 'vm'
                    }
                }),
                ncyBreadcrumb: {
                    label: '{{ ::"RECIPIENTS" | translate }}'
                }
            })
            .state('dashboard.user.tenants.emails.details.files', {
                url: '/files?efPage&efLimit&efOrdef&efTitle',
                parent: 'dashboard.user.tenants.emails.details',
                views: parseBook({
                    'email-details': {
                        templateUrl: '/modules/user/tenants/emails/details/files/email-files.html',
                        controller: 'UserEmailFilesController',
                        controllerAs: 'vm'
                    }
                }),
                resolve: {
                    filesCollectionResponse: function($stateParams, apiClient, emailLogResponse) {
                        var params = {
                            page: $stateParams.efPage,
                            limit: $stateParams.efLimit,
                            order: $stateParams.efOrder,
                            title: $stateParams.efTitle
                        };

                        return apiClient.get(emailLogResponse.getLink('emaillogs_files'), params).then(function(response) {
                            return response || apiClient.noResourceFound();
                        });
                    }
                },
                params: {
                    efPage: '1',
                    efLimit: '30',
                    efOrder: 'title'
                },
                ncyBreadcrumb: {
                    label: '{{ ::"FILES" | translate }}'
                }
            })
        ;
    }

    var modalGlob = {closed: true};

})();
