(function () {
    'use strict';

    angular
        .module('elogbooks.admin.asset-types')
        .config(registerRoutes);

    function registerRoutes ($stateProvider) {
        $stateProvider
            .state('dashboard.admin.assettypes', {
                abstract: true,
                templateUrl: '/shared-templates/view.html',
                ncyBreadcrumb: {
                    skip: true
                },
                data: {
                    permissions: ['user_permission_view_asset_types']
                }
            })
            .state('dashboard.admin.assettypes.list', {
                url: '/assettypes',
                parent: 'dashboard.admin.assettypes',
                data: {
                    permissions: ['user_permission_view_asset_types']
                },
                resolve: {
                    assetTypeCollectionResponse: function (apiClient, rootResourceResponse) {
                        return apiClient.getPage(rootResourceResponse.getLink('assettypes'), { page: 1, limit: 999999 }).then(function (response) {
                            return response || apiClient.noResourceFound();
                        });
                    }
                },
                views: {
                    '@dashboard.admin.assettypes': {
                        templateUrl: '/modules/admin/asset-types/asset-types.html',
                        controller: 'AssetTypesController',
                        controllerAs: 'vm'
                    }
                },
                ncyBreadcrumb: {
                    label: '{{ ::"ASSET_TYPES" | translate }}'
                }
            })
            .state('dashboard.admin.assettypes.list.add', {
                url: '/add/{parentResource}',
                parent: 'dashboard.admin.assettypes.list',
                data: {
                    permissions: ['user_permission_manage_asset_types']
                },
                views: {
                    '@dashboard.admin.assettypes': {
                        templateUrl: '/modules/admin/asset-types/add-edit/asset-type-form.html',
                        controller: 'AssetTypeAddEditController',
                        controllerAs: 'vm'
                    }
                },
                resolve: {
                    parentResponse: function (apiClient, $stateParams) {
                        if ($stateParams.parentResource) {
                            return apiClient.get($stateParams.parentResource.decode()).then(function (response) {
                                return response || apiClient.noResourceFound();
                            });
                        }

                        return null;
                    },
                    assetTypeResponse: function () {
                        return {};
                    }
                },
                ncyBreadcrumb: {
                    label: '{{ ::"ADD" | translate }}'
                }
            })
            .state('dashboard.admin.assettypes.list.edit', {
                url: '/edit/{resource}',
                parent: 'dashboard.admin.assettypes.list',
                data: {
                    permissions: ['user_permission_manage_asset_types']
                },
                views: {
                    '@dashboard.admin.assettypes': {
                        templateUrl: '/modules/admin/asset-types/add-edit/asset-type-form.html',
                        controller: 'AssetTypeAddEditController',
                        controllerAs: 'vm'
                    }
                },
                resolve: {
                    assetTypeCollectionResponse: function (apiClient, rootResourceResponse, $stateParams) {
                        var resource = $stateParams.resource.decode();
                        var parts = resource.split('/');
                        var exclude = parts.pop();

                        return apiClient.getPage(rootResourceResponse.getLink('assettypes'), { page: 1, limit: 999999, active: 1, exclude: exclude}).then(function (response) {
                            return response || apiClient.noResourceFound();
                        });
                    },
                    assetTypeResponse: function (apiClient, base64, $stateParams) {
                        return apiClient.get($stateParams.resource.decode()).then(function (response) {
                            return response || apiClient.noResourceFound();
                        });
                    },
                    parentResponse: function (apiClient, assetTypeResponse) {
                        if (assetTypeResponse.getLink('parent')) {
                            return apiClient.get(assetTypeResponse.getLink('parent')).then(function (response) {
                                return response || apiClient.noResourceFound();
                            });
                        }

                        return null;
                    }
                },
                ncyBreadcrumb: {
                    label: '{{ ::"EDIT" | translate }}'
                }
            });
    }
})();
