app.config(function ($translateProvider) {
    $translateProvider.translations('en-gb', {
        JOB_GROUP: 'Job Group',
        JOB_GROUPS: 'Job Groups',

        ADD_NEW_JOB_GROUP: 'Add a new Job Group',
        EDIT_JOB_GROUP: 'Edit Job Group',

        JOB_GROUP_LIST: 'Job Groups',
        JOB_GROUP_ADD: 'Add a new Job Group',
        JOB_GROUP_EDIT: 'Edit Job Group',

        JOB_GROUP_CREATED: 'Job Group Created',
        JOB_GROUP_UPDATED: 'Job Group Updated',
        JOB_GROUP_DELETED: 'Job Group Deleted',
        JOB_GROUP_ADDED: 'Job Group Added',

        JOB_GROUP_ENTER: 'Enter a job group name...'
    });
});
