(function () {
    'use strict';

    angular
        .module('elogbooksFilters')
        .filter('userType', userType);

    function userType ($filter) {
        var types = {
            0: 'STANDARD',
            1: 'MERIDIAN',
            2: 'RISKWISE',
            3: 'ALCUMUS',
            4: 'OPTIMA',
            5: 'PROPERTYPLUS',
            6: 'OSP',
        };

        return function (input) {
            return $filter('translate')(types[input]);
        }
    };
})();
