angular.module('elogbooksServices').service('operativePermissions', function (lodash, $translate, filterPermissions) {
    var permissions = [
        { name: 'operative_permission_view_jobs' },
        { name: 'operative_permission_edit_jobs' },
        { name: 'operative_permission_receive_jobs' },
        { name: 'operative_permission_create_reactive_jobs' },
        { name: 'operative_permission_create_reactive_jobs_for_other_service_providers' },
        { name: 'operative_permission_request_planned_jobs' },
        { name: 'operative_permission_receive_all_emails' },
        { name: 'operative_permission_manage_operatives' },
        { name: 'operative_permission_view_sites' },
        { name: 'operative_permission_view_assets' },
        { name: 'operative_permission_create_quotes' },
        { name: 'operative_permission_view_quotes'},
        { name: 'operative_permission_view_meters'},
        { name: 'operative_permission_edit_meters'},
        { name: 'operative_permission_edit_meter_readings'},
        { name: 'operative_permission_edit_assets'},
        { name: 'operative_permission_view_tasks'},
        { name: 'operative_permission_edit_tasks'},
        { name: 'operative_permission_create_tasks'},
        { name: 'operative_permission_reassign_job_operative'},
        { name: 'operative_permission_view_files'},
        { name: 'operative_permission_edit_files'},
        { name: 'operative_permission_receive_audits'},
        { name: 'operative_permission_view_audits'},
        { name: 'operative_permission_edit_audit_reference'},
        { name: 'operative_permission_view_patrol_instances'},
        { name: 'operative_permission_edit_patrol_instances'},
        { name: 'operative_permission_receive_patrol_instances'},
        { name: 'operative_permission_view_surveys'},
        { name: 'operative_permission_edit_surveys'},
        { name: 'operative_permission_create_surveys'},
        { name: 'operative_permission_view_contacts'},
        { name: 'operative_permission_view_approvers'},
        { name: 'operative_permission_force_complete_audits'},
        { name: 'operative_permission_p2p'},
        { name: 'operative_permission_asset_user_imports'},
        { name: 'operative_permission_task_user_imports'},
        { name: 'operative_permission_job_operative'},
        { name: 'operative_permission_task_user_imports'},
        { name: 'operative_permission_asset_task_user_imports'},
        { name: 'operative_permission_bulk_reassign_operative_jobs'},
        { name: 'operative_permission_bulk_cancel_jobs'},
        { name: 'operative_permission_edit_meter_references'},
        { name: 'operative_permission_notify_updates'},
        { name: 'operative_permission_can_score_assets'},
        { name: 'operative_permission_edit_score_types_on_asset'},
        { name: 'operative_permission_open_close'},
        { name: 'operative_permission_view_labour_lines'},
        { name: 'operative_permission_edit_labour_lines'},
        { name: 'operative_permission_create_labour_lines'},
        { name: 'operative_permission_create_spillages_for_others'},
        { name: 'operative_permission_receive_spillages'},
        { name: 'operative_permission_create_spillages'},
        { name: 'operative_permission_edit_spillages'},
        { name: 'operative_permission_view_spillages'},
        { name: 'operative_permission_create_survey_schedules'},
        { name: 'operative_permission_edit_survey_schedules'},
        { name: 'operative_permission_reject_insist_jobs'},
        { name: 'operative_permission_view_link_jobs'},
        { name: 'operative_permission_remove_link_jobs'},

        { name: 'operative_permission_view_waste_entries'},
        { name: 'operative_permission_create_waste_entries'},
        { name: 'operative_permission_edit_waste_entries'},
        { name: 'operative_permission_import_waste_entries'},

        { name: 'operative_permission_waive_management_fee'},
        { name: 'operative_permission_create_webhooks'},
        { name: 'operative_permission_edit_webhooks'},
        { name: 'operative_permission_view_webhooks'},
        { name: 'operative_permission_view_actions'},
        { name: 'operative_permission_create_actions'},
        { name: 'operative_permission_edit_actions'},
        { name: 'operative_permission_manage_actions'},
        { name: 'operative_permission_view_purchase_orders', module: 'finance'},
        { name: 'operative_permission_create_purchase_orders', module: 'finance'},
        { name: 'operative_permission_edit_purchase_orders', module: 'finance'},
        { name: 'operative_permission_receive_quotes' },

        { name: 'operative_permission_create_sales_invoice', module: 'finance'},
        { name: 'operative_permission_edit_sales_invoice', module: 'finance'},
        { name: 'operative_permission_view_sales_invoice', module: 'finance'},
        { name: 'operative_permission_can_add_additional_operatives', module: 'secondary_operatives' },
    ];

    this.getAll = function () {
        return filterPermissions.filterPermissions(permissions, 'VALUE');
    };

    this.getAllTranslated = function () {
        return filterPermissions.filterPermissions(permissions, 'TRANSLATED');
    };

    return this;
});
