(function () {
    'use strict';

    angular
        .module('elogbooks.common.audit')
        .controller('AuditEditReferenceController', AuditEditReferenceController);

    AuditEditReferenceController.$inject = ['$uibModalInstance', 'apiClient', 'reference'];

    function AuditEditReferenceController ($uibModalInstance, apiClient, reference) {
        var vm = this;
        vm.close = closeModal;
        vm.confirm = confirm;
        vm.data = {
            reference: reference
        };

        function closeModal() {
            $uibModalInstance.dismiss('cancel');
        };

        function confirm() {
            $uibModalInstance.close(vm.data);
        };
    }
})();
