(function () {
    'use strict';

    angular
        .module('elogbooksServices')
        .controller('ElbFileModalController', ElbFileModalController);

    ElbFileModalController.$inject = ['$scope', 'apiClient', 'file', 'tags', '$uibModalInstance', 'elbDownloadService', 'requestDataFactory', 'confirmationModal', '$state', 'messenger', 'elbVisibilityService', 'lodash'];

    function ElbFileModalController ($scope, apiClient, file, tags, $uibModalInstance, elbDownloadService, requestDataFactory, confirmationModal, $state, messenger, elbVisibilityService, lodash) {
        var vm = this;
            vm.file = file;
            vm.tags = tags;
            vm.editing = false;
            vm.visibilityService = elbVisibilityService;
            vm.jobRequisiteAnswerFile = $state.current.name.endsWith('.requisites');

        vm.close = closeAction;
        vm.download = downloadAction;
        vm.delete = deleteAction;
        vm.save = saveAction;
        vm.transformTag = transformTag;
        vm.selectItem = selectItem;
        vm.removeItem = removeItem;
        vm.isModal = $uibModalInstance;
        vm.searchTags = searchTags;

        vm.updated = false;

        function closeAction () {
            if (vm.updated) {
                return $uibModalInstance.dismiss() && $state.go('.', {}, { reload: true });
            }

            return $uibModalInstance.dismiss();
        }

        function downloadAction () {
            return elbDownloadService.download(vm.file);
        }

        function saveAction () {
            return apiClient.update(vm.file.getLink('edit'), vm.data).then(function (response) {
                if (response) {
                    apiClient.get(vm.file.getLink('self')).then(function (response) {
                        vm.file = response;
                        vm.file.expiresAt = vm.file.expiresAt == null ? null : new Date(vm.file.expiresAt);
                    })
                    // angular.merge(vm.file, vm.data);
                    messenger.success('FILE_UPDATED');
                    vm.updated = true;
                    vm.editing = false;
                    if (! vm.isModal) {
                        $state.go('.', {}, { reload: $state.current.parent });
                    }
                } else {
                    messenger.error('REQUEST_ERROR');
                }
            });
        }

        function deleteAction () {
            return confirmationModal.open().result.then(function (response) {
                return apiClient.delete(vm.file.getLink('delete')).then(function (response) {
                    if (response) {
                        var state = vm.isModal ? '.' : $state.current.parent;
                        $state.go(state, {}, { reload: $state.current.name }).then(function () {
                            messenger.success('FILE_DELETED');
                        });
                    } else {
                        messenger.error('REQUEST_ERROR');
                    }
                });
            });
        }

        function transformTag (tag) {
            var item = {
                type: 'user',
                name: tag
            };

            return item;
        }

        function selectItem (item) {
            if (item._links) {
                lodash.remove(vm.data._links.tags, {href: item._links.self.href});
                vm.data._links.tags.push({href: item._links.self.href});
            } else {
                lodash.remove(vm.data.tags, {name: item.name});
                vm.data.tags.push({name: item.name});
            }
        }

        function removeItem (item) {
            if (item._links) {
                lodash.remove(vm.data._links.tags, {href: item._links.self.href});
            } else {
                lodash.remove(vm.data.tags, {name: item.name});
            }
        }

        $scope.$watch('vm.editing', function () {
            vm.data = requestDataFactory.createRequest(vm.file);
            setTags();
        });

        // Reset the tags selection list back to the original list
        $scope.$watch('vm.tagsSelected', function () {
            if (vm.tags !== tags){
                vm.tags = tags;
            }
        });

        function setTags() {
            vm.tagsSelected = [];
            vm.data.tags = [];
            vm.data._links.tags = [];
            angular.forEach(vm.file.tags.tags, function (value, index) {
                if (value.type !== 'inherited') {
                    vm.tagsSelected.push(value);

                    if (value._links) {
                        vm.data._links.tags.push({href: value._links.self.href});
                    } else {
                        vm.data.tags.push({name: value.name});
                    }
                }
            });
        }

        function searchTags(keyword) {
            vm.loading = true;
            if (keyword.length >= 1 && tags.getLink('self')) {
                apiClient.get(tags.getLink('self'), {name: keyword})
                    .then(function (response) {
                        vm.loading = false;
                        vm.tags = response;
                    });
            } else {
                vm.loading = false;
            }
        }
    }
})();
