(function () {
    'use strict';

    angular
        .module('elogbooks.user.reports')
        .controller('UserReportsExtensionRequestsQuotesController', UserReportsExtensionRequestsQuotesController);

    UserReportsExtensionRequestsQuotesController.$inject = ['$state', '$stateParams', 'quotesCollection', 'operativeResponse', 'quoteParams', 'modalConfig'];

    function UserReportsExtensionRequestsQuotesController($state, $stateParams, quotesCollection, operativeResponse, quoteParams, modalConfig) {
        var vm = this;

        vm.quotesCollection = quotesCollection;
        vm.quotes = quotesCollection.quotes;
        vm.op = operativeResponse;
        vm.extensionRequestStatus = $stateParams.status;

        quoteParams.reportType = 'sp_summary';
        vm.exportParams = quoteParams;

        vm.modalConfig = modalConfig;
    }
})();