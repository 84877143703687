/**
 * Panel Title Tabs
 */
angular.module('elogbooksDirectives').directive('elbSmallImageTabs', ['$http', 'apiClient', '$state', function ($http, apiClient, $state) {
    return {
        restrict: 'E',
        transclude: true,
        scope: true,
        replace: true,
        templateUrl: '/modules/directives/layout/small-image-tabs/small-image-tabs.html',
        link: function ($scope, $element, $attrs) {}
    };
}]);
