app.config(function ($translateProvider) {
    $translateProvider.translations('nl', {
        JOB_GROUP: 'Opdrachtgroep',
        JOB_GROUPS: 'Opdrachtgroepen',

        ADD_NEW_JOB_GROUP: 'voeg nieuwe opdrachtgroep toe',
        EDIT_JOB_GROUP: 'Wijzig opdrachtgroep',

        JOB_GROUP_LIST: 'Opdrachtgroepen',
        JOB_GROUP_ADD: 'voeg een nieuwe opdrachtgroep toe',
        JOB_GROUP_EDIT: 'Wijzig opdrachtgroep',

        JOB_GROUP_CREATED: 'Opdrachtgroep gemaakt',
        JOB_GROUP_UPDATED: 'Opdrachtgroep aangepast',
        JOB_GROUP_DELETED: 'Opdrachtgroep verwijderd',
        JOB_GROUP_ADDED: 'Opdrachtgroep toegevoegd',
    });
});
