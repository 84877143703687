/**
 * Add contract type controller
 */
(function () {
    'use strict';

    angular
        .module('elogbooks.admin.contract-types')
        .controller('ContractTypesAddController', ContractTypesAddController);

    ContractTypesAddController.$inject = ['$state', 'apiClient', 'messenger', 'rootResourceResponse'];

    function ContractTypesAddController($state, apiClient, messenger, rootResourceResponse) {

        var vm = this;
        vm.contracttype = {name: ''};

        vm.submit = function () {
            apiClient.get(rootResourceResponse.getLink('contracttypes')).then(function (response) {

                var createLink = response.getData()._links.create.href;

                apiClient.create(createLink, vm.contracttype).then(function (response) {
                    if (response) {
                        $state.go('^', {}, { reload: true }).then(function () {
                            messenger.success('Added successfully');
                        });
                    } else {
                        messenger.error('Unable to process your request. Please try again later');
                    }
                });

            });
        };
    };

})();

