(function () {
    'use strict';

    angular
        .module('elogbooks.admin.job-requisites')
        .controller('JobRequisitesSitesEditController', JobRequisitesSitesEditController);

    JobRequisitesSitesEditController.$inject = ['$stateParams', '$state', 'messenger', 'apiClient', 'lodash', 'jobRequisiteResponse', 'siteCollectionResponse', 'selectedSiteCollectionResponse'];

    function JobRequisitesSitesEditController ($stateParams, $state, messenger, apiClient, lodash, jobRequisiteResponse, siteCollectionResponse, selectedSiteCollectionResponse) {
        var vm = this;
        vm.jobRequisite = jobRequisiteResponse;
        vm.multiSelect = {
            link: '/sites',
            items: lodash.map(lodash.differenceBy(siteCollectionResponse.sites, selectedSiteCollectionResponse.sites, 'id'), function(site) {
                return {href: site.getLink('self'), value: site.name};
            }),
            selectedItems: [],
            responseKeyPath: 'sites',
            searchKey: 'siteName',
            searchParams: { status: 'active' }
        };

        vm.selectedSites = selectedSiteCollectionResponse.sites;
        if(vm.selectedSites) {
            angular.forEach(vm.selectedSites, function(site) {
                vm.multiSelect.selectedItems.push({href: site.getLink('self'), value: site.name});
            });
        }

        vm.pageTitle = 'JOB_REQUISITE_SITE_EDIT';
        vm.update = updateAction;

        function updateAction() {
            var data = {
                _links: {}
            };

            if (vm.multiSelect.selectedItems.length) {
                data._links.site = vm.multiSelect.selectedItems;
            }

            apiClient.create(vm.jobRequisite.getLink('sites'), data).then(function (response) {
                if (response) {
                    $state.go('^', $stateParams, { reload: true }).then(function () {
                        messenger.success('JOB_REQUISITE_SITE_ADDED');
                    });
                } else {
                    messenger.error('REQUEST_ERROR');
                }
            });
        }
    }

})();
