(function () {
    'use strict';

    angular
        .module('elogbooksDirectives')
        .controller('ElbFilesController', ElbFilesController);

    ElbFilesController.$inject = ['$scope', '$rootScope'];

    function ElbFilesController ($scope, $rootScope) {
        var vm = this;
            angular.extend(vm, $scope);
            $scope.bookerize = $rootScope.bookerize;
    }
})();
