/**
 * Client Billing list controller
 */
(function () {
    'use strict';

    angular
        .module('elogbooks.admin.client-billing')
        .controller('ClientBillingController', ClientBillingController);

    ClientBillingController.$inject = [
        'clientBillingResponse',
        'billingTypesCollectionResponse',
        'moment',
        'clientBillingService'
    ];

    function ClientBillingController(
        clientBillingResponse,
        billingTypesCollectionResponse,
        moment,
        clientBillingService
    ) {
        var vm = this;
        vm.moment = moment;
        vm.clientBilling = clientBillingResponse;
        vm.clientBillingService = clientBillingService;

        const [invoiceFrequencyOccurrence, invoicingMonths] = clientBillingService.getInvoiceFrequencyOccurrence(vm.clientBilling.invoiceFrequency, vm.clientBilling.startingMonth);
        vm.invoiceFrequencyOccurrence = invoiceFrequencyOccurrence;
        vm.invoicingMonths = invoicingMonths;

        vm.billingTypes = clientBillingService.parseBillingTypes(billingTypesCollectionResponse.billingTypes, vm.invoiceFrequencyOccurrence);
        vm.contractManagerName = vm.clientBilling.contractManagerName ? vm.clientBilling.contractManagerName : 'NONE_SELECTED';
        vm.cis = vm.clientBilling.cis ? vm.clientBilling.cis : null;
        vm.clientBilling.serviceType = vm.clientBilling.serviceType ? vm.clientBilling.serviceType : 'NONE_SELECTED';
        vm.clientBilling.invoiceFrequency = vm.clientBilling.invoiceFrequency ? vm.clientBilling.invoiceFrequency : 'NONE_SELECTED';
        vm.clientBilling.invoiceMode = vm.clientBilling.invoiceMode ? vm.clientBilling.invoiceMode : 'NONE_SELECTED';
        vm.clientBilling.anniversaryDateMonth = vm.clientBilling.anniversaryDateMonth && vm.clientBilling.contractStartedAt ? vm.clientBilling.anniversaryDateMonth : null;
        vm.clientBilling.anniversaryDateDay = vm.clientBilling.anniversaryDateDay ? vm.clientBilling.anniversaryDateDay : null;

        vm.anyLocation = anyLocation;

        // Format anniversary date.
        vm.clientBilling.anniversaryDateFormatted = vm.clientBilling.anniversaryDateDay && vm.clientBilling.anniversaryDateMonth
            ? `${vm.clientBilling.anniversaryDateDay}${vm.clientBillingService.getOrdinalNumber(vm.clientBilling.anniversaryDateDay)} ${moment.months(vm.clientBilling.anniversaryDateMonth-1)}`
            : null;

        vm.totalBilledSiteCount = clientBillingService.sum(vm.billingTypes, 'sitesCount');
        vm.totalYearlyCost = parseFloat(clientBillingService.sum(vm.billingTypes, 'yearlyCost')).toFixed(2);
        vm.totalCostPerFrequency = vm.invoiceFrequencyOccurrence ?
            parseFloat(clientBillingService.sum(vm.billingTypes, 'costPerFrequency')).toFixed(2) :
            'INVOICE_FREQUENCY_NOT_SET';

        vm.totalContractValuePerYear = vm.totalYearlyCost;
        vm.totalContractValuePerInvoiceFrequency = vm.totalCostPerFrequency;

        if (vm.clientBilling.clientBillingCharges) {
            updateTotals();

            clientBillingService.calculateSurchargeMinimumContract(
                vm.clientBilling.clientBillingCharges,
                vm.clientBilling.minimumContractValue,
                vm.totalContractValuePerYear
            );

            // Update the totals again after calculating the surcharge minimum contract value.
            updateTotals();

        }

        function updateTotals() {
            vm.totalAdditionnalChargesPerYear = (parseFloat(clientBillingService.totalRowsValue(vm.clientBilling.clientBillingCharges.clientbillingcharges, 'cost', 'quantity'))).toFixed(2);
            vm.totalAdditionnalChargesPerInvoiceFrequency = (parseFloat(vm.totalAdditionnalChargesPerYear) / parseFloat(vm.invoiceFrequencyOccurrence)).toFixed(2);
            vm.totalContractValuePerYear = (parseFloat(vm.totalYearlyCost) + clientBillingService.locationGetTotalPerType(vm.billingTypes, vm.invoiceFrequencyOccurrence, 'yearly') + parseFloat(vm.totalAdditionnalChargesPerYear)).toFixed(2);
            vm.totalContractValuePerInvoiceFrequency = (parseFloat(vm.totalCostPerFrequency) + clientBillingService.locationGetTotalPerType(vm.billingTypes, vm.invoiceFrequencyOccurrence, 'frequency') + parseFloat(vm.totalAdditionnalChargesPerInvoiceFrequency)).toFixed(2);
        }

        function anyLocation() {
            var isAnyLocation = false;

            for (var i = 0; i < vm.billingTypes.length; i++) {
                if (vm.billingTypes[i].locationsCount) {
                    isAnyLocation = true;
                    break;
                }
            }

            return isAnyLocation;
        }
    }
})();
