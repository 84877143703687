(function () {
    'use strict';

    angular
        .module('elogbooks.user.reports')
        .controller('AuditTrendReportModalController', AuditTrendReportModalController);

    AuditTrendReportModalController.$inject = ['answerCollectionResponse'];

    function AuditTrendReportModalController(answerCollectionResponse) {
        var vm = this;
        vm.answerCollection = answerCollectionResponse;
        vm.answers = answerCollectionResponse.answers;
    }
})();
