(function () {
    'use strict';

    angular
        .module('elogbooks.admin.roles')
        .controller('RoleUsersController', RoleUsersController);

    RoleUsersController.$inject = ['$state', 'messenger', 'apiClient', 'lodash', 'roleResponse', 'userCollectionResponse', 'rootResourceResponse'];

    function RoleUsersController ($state, messenger, apiClient, lodash, roleResponse, userCollectionResponse, rootResourceResponse) {
        var vm = this;
            vm.role = roleResponse;
            vm.keyword = null;

        //fetch users
        vm.selectedUsers = [];
        vm.users = userCollectionResponse.getData().users;
        apiClient.get(vm.role.getLink('users')).then(function(response){
            vm.selectedUsers = response.users;
            vm.users = vm.filterSelectedUsers(vm.users);
        });


        vm.revoke = function () {
            vm.selectedUsers = lodash.difference(vm.selectedUsers, vm.revokeAccess);
            vm.users = lodash.union(vm.users, vm.revokeAccess);
        };

        vm.grant = function () {
            vm.users = lodash.difference(vm.users, vm.grantAccess);
            vm.selectedUsers = lodash.union(vm.selectedUsers, vm.grantAccess);
        };

        vm.search = function () {
            if (vm.keyword !== null && vm.keyword.length > 0) {
                apiClient.get(rootResourceResponse.getLink('users'), {
                    name: vm.keyword,
                    status: 'active'
                }).then(function (response) {
                    vm.users = vm.filterSelectedUsers(response.users);
                });

            } else {
                var allUsers = userCollectionResponse.getData().users;
                vm.users = vm.filterSelectedUsers(allUsers);
            }
        };

        vm.filterSelectedUsers = function (usersToFilter) {
            var filteredUsers = usersToFilter;

            vm.selectedUsers.forEach(function(selectedUser) {
                 filteredUsers = filteredUsers.filter(function(user) {
                    return user.id !== selectedUser.id;
                });
            });

            return filteredUsers;
        };

        vm.submit = function () {
            var data = {
                _links: {
                    user: [
                    ]
                }
            };

            lodash.each(vm.selectedUsers, function(user){
                data._links.user.push(user._links.self);
            });

            apiClient.update(vm.role.getLink('users'), data).then(function(response){
                if (response) {
                    messenger.success('ROLE_UPDATED');
                } else {
                    $state.reload();
                }
            });
        };
    }
})();
