angular.module('elogbooksDirectives').directive('address', [function(){
    return {
        // restrict to attributes only
        restrict: 'AE',
        require: ['ngModel'],
        scope: {
            address: '=ngModel',
            inline: '=inline',
            linesOnly: '=linesOnly'
        },
        templateUrl: '/modules/directives/address/address.html',
        controller: function ($scope) {

            if (! $scope.address) {
                $scope.addressParts = [];

                return;
            }

            $scope.addressParts = $scope.address.lines || [];

            if (! $scope.linesOnly) {
                $scope.addressParts = $scope.addressParts
                    .concat([
                        $scope.address.town,
                        $scope.address.region,
                        $scope.address.postcode,
                        $scope.address.country
                    ]);
            }

            $scope.addressParts = $scope.addressParts.filter(function (item) {
                return typeof item !== 'undefined' && item != '';
            });
        }
    }
}]);
