(function () {
    'use strict';

    angular
        .module('elogbooks.user.reports')
        .controller('UserReportsSiteAssociateJobsApprovedController', UserReportsSiteAssociateJobsApprovedController);

    UserReportsSiteAssociateJobsApprovedController.$inject = ['jobsCollection'];

    function UserReportsSiteAssociateJobsApprovedController(jobsCollection) {
        var vm = this;

        vm.jobsCollection = jobsCollection;
        vm.jobs = jobsCollection.jobs;
    }
})();