(function () {
    'use strict';

    angular
        .module('elogbooks.user.reports')
        .controller('UserReportsKPISummaryModalController', UserReportsKPISummaryModalController);

    UserReportsKPISummaryModalController.$inject = ['titlePrefix', 'columnName', 'collection', '$stateParams'];

    function UserReportsKPISummaryModalController(titlePrefix, columnName, collection, $stateParams) {
        var vm = this;
        vm.titlePrefix = titlePrefix;
        vm.columnName = columnName;
        vm.collection = collection;
        vm.columnIn = columnIn;
        vm.showSp = $stateParams.tab === 'kpi_groups';

        function columnIn(array) {
            return array.indexOf(vm.columnName) !== -1;
        }
    }
})();