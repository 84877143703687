(function () {
    'use strict';

    angular
        .module('elogbooks.admin.asset-score-types')
        .controller('AssetScoreTypeDetailsController', AssetScoreTypeDetailsController);

    AssetScoreTypeDetailsController.$inject = ['assetScoreTypeResponse'];

    function AssetScoreTypeDetailsController (assetScoreTypeResponse) {
        var vm = this;
            vm.assetScoreType = assetScoreTypeResponse;
    }
})();
