angular.module('elogbooksServices').service('jobAdvancedFiltersModal', ['$uibModal', 'advancedFilterService', function ($uibModal, advancedFilterService) {
    return {
        open: function (collections, resources, config) {
            return $uibModal.open({
                templateUrl: '/modules/common/modals/advancedFilters/modal-advanced-filters-modal.html',
                size: 'xl',
                controller: 'advancedJobFiltersModalController',
                controllerAs: 'vm',
                resolve: {
                    config: function () {
                        var defaultConfig = {
                            view: 'job',
                            titleMessage: 'ARE_YOU_SURE_HEADER',
                            bodyMessage: 'ARE_YOU_SURE'
                        };

                        return angular.extend({}, defaultConfig, config);
                    },
                    rootResource: resources.root,
                    statutoryTypeCollectionResponse: collections[0],
                    priorityCollectionResponse: collections[1],
                    jobCollectionResponse: collections[2],
                    assetCollectionResponse: advancedFilterService.getFilterData('assets',resources),
                    chargeTypesCollectionResponse: advancedFilterService.getFilterData('chargeTypes',resources),
                    jobGroupCollectionResponse: advancedFilterService.getFilterData('jobGroup',resources),
                    operativeCollectionResponse: advancedFilterService.getFilterData('job-operatives',resources),
                    approversCollectionResponse: advancedFilterService.getFilterData('approvers',resources),
                    serviceProviderResponse: advancedFilterService.getFilterData('jobServiceProviders',resources),
                    regionCollectionResponse: advancedFilterService.getFilterData('job-regions',resources),
                    creatorsCollectionResponse: advancedFilterService.getFilterData('jobCreators',resources),
                    assetTypeCollectionResponse: advancedFilterService.getFilterData('assetTypes',resources),
                    siteGroupCollection: advancedFilterService.getFilterData('siteGroups',resources),
                    jobRequisiteCollectionResponse: advancedFilterService.getFilterData('requisites',resources),
                    sectorCollectionResponse: advancedFilterService.getFilterData('sectors',resources),
                    siteCollection: advancedFilterService.getFilterData('sites',resources),
                    coreClientsCollectionResponse: advancedFilterService.getFilterData('coreclients',resources),
                    serviceProviderGroupsResponse: advancedFilterService.getFilterData('serviceProviderGroups',resources),
                    siteResource: resources.selectedSite,
                }
            });
        }
    };
}]);
