/**
 * Add Edit Survey Controller
 */
(function(){
    'use strict';

    angular
        .module('elogbooks.admin.surveys')
        .controller('SurveyAddEditController', SurveyAddEditController);

    SurveyAddEditController.$inject = ['$state', 'apiClient', 'messenger', 'surveyResponse',  'surveyStatCollectionResponse', 'crudService', 'surveyCollectionResponse', 'requestDataFactory', 'lodash', '$translate', 'confirmationModal'];

    function SurveyAddEditController($state, apiClient, messenger, surveyResponse, surveyStatCollectionResponse, crudService, surveyCollectionResponse, requestDataFactory, lodash, $translate, confirmationModal) {
        var vm = this;
        vm.survey = surveyResponse;
        vm.data = requestDataFactory.createRequest(vm.survey);
        vm.surveyStats = surveyStatCollectionResponse ? getAllTranslated(surveyStatCollectionResponse.surveyStats) : null;
        vm.selectedStats = surveyResponse.surveyStats ? getAllTranslated(surveyResponse.surveyStats) : [];
        vm.surveyCollectionResponse = surveyCollectionResponse;

        vm.create = createAction;
        vm.update = updateAction;
        vm.delete = deleteAction;

        function getSelectedStats() {
            if (vm.selectedStats.length === 0) {
                return;
            }
            vm.data._links.surveyStats = [];

            for (var selectedStat in vm.selectedStats) {
                if (!vm.selectedStats.hasOwnProperty(selectedStat)) {
                    continue;
                }
                var stat = lodash.find(surveyStatCollectionResponse.surveyStats, {'id': vm.selectedStats[selectedStat].id});
                if (stat) {
                    vm.data._links.surveyStats.push(stat._links.self);
                }
            }
        }

        function getAllTranslated(data) {
            var translated = [];

            angular.forEach(data, function (value) {
                translated.push({
                    id: value.id,
                    label: value.name,
                    name: $translate.instant(value.name)
                });
            });
            return translated;
        }

        function createAction () {
            getSelectedStats();
            return crudService.create(
                surveyCollectionResponse.getLink('create'),
                vm.data,
                'dashboard.admin.surveys.list.details.info',
                'resource',
                'SURVEY'
            );
        }

        function updateAction() {
            getSelectedStats();
            return crudService.update(
                vm.survey.getLink('edit'),
                vm.data,
                'dashboard.admin.surveys.list.details.info',
                'dashboard.admin.surveys.list',
                'SURVEY'
            );
        }

        function deleteAction () {
                return crudService.delete(
                    vm.survey.getLink('delete'),
                    'dashboard.admin.surveys.list',
                    'SURVEY',
                    {},
                    false
                )
        }
    }
})();
