(function () {
    'use strict';

    angular
        .module('elogbooks.admin.users')
        .controller('UserUnlockModalController', UserUnlockModalController);

    UserUnlockModalController.$inject = ['$state', '$uibModalInstance', 'userResource', 'apiClient', 'messenger'];

    function UserUnlockModalController ($state, $uibModalInstance, userResource, apiClient, messenger) {
        var vm = this;
        vm.unlock = unlock;
        vm.close = close;

        function unlock() {
            apiClient.update(userResource.getLink('unlock'), {}).then(function (response) {
                if (response) {
                    $uibModalInstance.dismiss('cancel');
                    $state.go('.', {}, { reload: $state.get('^') }).then(function () {
                        messenger.success('ACCOUNT_UNLOCKED');
                    });
                } else {
                    $uibModalInstance.dismiss('cancel');
                    messenger.error('REQUEST_ERROR');
                }
            });
        }

       function close() {
            $uibModalInstance.dismiss('cancel');
        };
    }
})();
