(function() {
    'use strict';

    angular
        .module('elogbooks.user')
        .config(function($stateProvider) {

            $stateProvider
                .state('dashboard.user', {
                    url: '/user?selectedSiteResource',
                    abstract: true,
                    templateUrl: '/modules/user/user-layout.html',
                    resolve: {
                        selectedSiteResponse: function($window, $stateParams, apiClient, $rootScope) {
                            if ($stateParams.selectedSiteResource != null) {
                                $rootScope.selectedSiteResource = $stateParams.selectedSiteResource;
                            } else {
                                $rootScope.selectedSiteResource = $window.localStorage.getItem('selectedSiteResource');
                            }

                            if ($rootScope.selectedSiteResource !== null && $rootScope.selectedSiteResource !== 'null') {
                                $stateParams.selectedSiteResource = $rootScope.selectedSiteResource;

                                return apiClient.get($rootScope.selectedSiteResource.decode(), null, 'medium');
                            }

                            return null;
                        },
                        siteResponse: function() {
                            return {};
                        },
                        prioritiesResponse: function(apiClient, rootResourceResponse) {
                            const prioritiesUrl = rootResourceResponse.getLink('priorities');
                            if (prioritiesUrl) {
                                return apiClient.get(prioritiesUrl, {
                                    active: true,
                                    includeInDashboard: 1
                                }).then( function(response) {
                                    return response || apiClient.noResourceFound();
                                }).catch(error => {
                                    return null;
                                })
                            }

                            return null;
                        }
                    },
                    params: {
                        selectedSiteResource: null,
                        site: null,
                        region: null,
                        siteAssociateType: null,
                        userId: null
                    },
                    ncyBreadcrumb: {
                        skip: true
                    },
                    controller: 'UserLayoutController',
                    controllerAs: 'vm'
                })
                .state('dashboard.user.dashboard', {
                    url: '/dashboard',
                    templateUrl: '/modules/common/dashboard/dashboard.html',
                    controller: 'DashboardController',
                    controllerAs: 'vm',
                    ncyBreadcrumb: {
                        skip: true
                    }
                });

        });
})();
