(function() {
    'use strict';

    angular
        .module('elogbooks.common.quote-requests')
        .controller('CommonQuoteRequestAddContactController', CommonQuoteRequestAddContactController);

    CommonQuoteRequestAddContactController.$inject = [
        '$rootScope',
        '$scope',
        '$state',
        'apiClient',
        'userCollectionResponse',
        'siteResponse',
        'siteRelationshipResponse',
        '$q',
        'userManager',
        'quoteCreateService',
        'messenger'
    ];

    function CommonQuoteRequestAddContactController(
        $rootScope,
        $scope,
        $state,
        apiClient,
        userCollectionResponse,
        siteResponse,
        siteRelationshipResponse,
        $q,
        userManager,
        quoteCreateService,
        messenger
    ) {
        $scope.form.$setPristine();
        $rootScope.forceDirtyConfirm = true;

        var vm = $scope.vm;
        vm.canNext = true;
        vm.canPrevious = true;
        vm.canSubmit = false;
        vm.title = 'QUOTE_REQUEST_ADD_CONTACT_DETAILS';
        vm.isHelpdeskUser = userManager.hasPermission('site_permission_helpdesk');

        vm.data.callerContact = {};
        vm.enterCallerContactDetailsManually = false;

        vm.data.callerContact.notifyOnCreate = true;
        vm.data.callerContact.notifyOnComplete = true;
        vm.data.callerContact.declineEmail = false;

        vm.changeUser = changeUser;
        vm.changeRelationship = changeRelationship;
        vm.next = nextAction;
        vm.previous = previousAction;
        vm.resetCallerContact = resetCallerContact;
        vm.findPseudoUsers = findPseudoUsers;
        vm.pseudoUserSelected = pseudoUserSelected;
        vm.toggleDeclineEmail = toggleDeclineEmail;

        vm.userCallerContactResponse = {};

        vm.selectedUserCallerContactModel = {
            response: userCollectionResponse,
            link: userCollectionResponse.getLink('self'),
            required: true,
            responseKeyPath: 'memberships',
            itemHrefPath: '_links.self.href',
            itemValuePath: '_links.user.title',
            onSelect: vm.changeUser,
            onRemove: vm.changeUser
        };

        vm.selectedRelationshipModel = {
            response: siteRelationshipResponse,
            link: siteRelationshipResponse.getLink('self'),
            required: true,
            responseKeyPath: 'siterelationships',
            itemHrefPath: '_links.self.href',
            itemValuePath: 'name',
            onSelect: vm.changeRelationship,
            onRemove: vm.changeRelationship
        };

        if (! vm.siteNotesConfirmed) {
            $rootScope.skipDirtyCheck = true;
            $state.go(vm.getRoute('site'), {}, { reload: false });
        }

        if (typeof vm.selectedUserCallerContact !== 'undefined') {
            vm.selectedUserCallerContactModel.selected = {
                object: vm.selectedUserCallerContact,
                href: vm.selectedUserCallerContact.getLink('self'),
                value: vm.selectedUserCallerContact._links.user.title
            };

            changeUser();
        }

        function resetCallerContact() {
            vm.selectedUserCallerContactModel.disabled = vm.enterCallerContactDetailsManually;

            if (vm.enterCallerContactDetailsManually) {
                vm.data._links.caller = null;
            }
        }

        function changeUser() {
            quoteCreateService.changeUser(
                vm.selectedUserCallerContactModel,
                vm.selectedUserCallerContact,
                vm.data,
                vm.submitDisabled,
                vm.userCallerContactResponse,
                vm.selectedRelationshipModel
            );
        }

        function findPseudoUsers(name) {
            return apiClient.get(siteResponse.getLink('pseudousers'), { name: name })
                .then(function(response) {
                    return response['site-pseudo-users'];
                }, function(error) {
                    console.log(error);
                });
        }

        function pseudoUserSelected (item) {
            vm.data.callerContact.name = item.name;
            vm.data.callerContact.email = item.email;
            vm.data.callerContact.phone = item.phone;
            vm.data.callerContact.notifyOnCreate = item.notifyOnCreation;
            vm.data.callerContact.notifyOnComplete = item.notifyOnCompletion;

            var pseudoUserSiteRelationship = siteRelationshipResponse.siterelationships.find(function(elem) {
                return elem.getLink('self') === item.getLink('site-relationship');
            });

            vm.selectedRelationshipModel.selected = {
                object: pseudoUserSiteRelationship,
                href: pseudoUserSiteRelationship.getLink('self'),
                value: pseudoUserSiteRelationship.name
            };

            changeRelationship();
        }

        function toggleDeclineEmail () {
            if (vm.data.callerContact.declineEmail) {
                vm.data.callerContact.email = null;
                vm.data.callerContact.notifyOnCreate = false;
                vm.data.callerContact.notifyOnComplete = false;
            }
        }

        function changeRelationship() {
            quoteCreateService.changeRelationship(
                vm.selectedRelationshipModel,
                vm.selectedRelationship,
                vm.data
            );
        }

        function nextAction() {
            if (vm.data.callerContact && vm.data.callerContact.name && vm.data.callerContact.name.length > 255 ) {
                messenger.error('SITE_CONTACT_INVALID_LENGTH');
                return;
            }

            $state.go(vm.getRoute('details'), {}, {reload: false});
        }

        function previousAction() {
            $state.go('^', {}, {reload: false}).then(function () {
                vm.submitDisabled = false;
            });
        }

        $scope.$watch('vm.enterCallerContactDetailsManually', function(newValue, oldValue) {
            if (newValue === false && typeof vm.selectedUserCallerContactModel.clear !== 'undefined') {
                vm.selectedUserCallerContactModel.clear();
            }
        });
    }
})();
