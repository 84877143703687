(function() {
    'use strict';

    angular
        .module('elogbooks')
        .config(Translations);

    function Translations($translateProvider) {
        $translateProvider.translations('en-gb', {
            WASTE_MANAGEMENT: 'Waste Management',
            WASTE_TYPE_LIST: 'Waste Types',
            WASTE_ENTRY_LIST : 'Waste Entries',
            WASTE_TYPE: 'Waste Type',
            TREATMENT_TYPE: 'Treatment Type',
            TREATMENT_TYPE_STATUS: 'Treatment Type Status',
            DUPLICATED_TREATMENT_TYPE: 'Duplicated Treatment Type',
            TREATMENT_TYPE_LIST: 'Treatment Types',
            TREATMENT_TARGET_LIST: 'Treatment Types Targets',
            TREATMENT_TARGET_ADD: 'Add Treatment Type Target',
            SITE_TARGET: 'Site Target',
            WASTE_TREATMENT_TYPES: 'Waste Treatment Types',
            WASTE_TREATMENT_TARGETS: 'Waste Treatment Targets',
            WASTE_TREATMENT_TYPES_LIST: 'Waste Treatment Types',
            WASTE_TYPE_ADD: 'Add Waste Type',
            UNIT_ADD: 'Add Unit',
            TREATMENT_TYPE_ADD: 'Add Treatment Type',
            WASTE_TYPES: 'Waste Types',
            WASTETYPE: 'Waste Type',
            TREATMENT_TYPES: 'Treatment Types',
            CODE: 'Code',
            WASTE_TYPE_CREATED: 'Waste Type Created',
            WASTE_ENTRY_CREATED: 'Waste Entry Created',
            WASTE_TYPE_UPDATED: 'Waste Type Updated',
            WASTE_ENTRY_UPDATED: 'Waste Entry Updated',
            WASTE_TYPE_DELETED: 'Waste Type Deleted',
            WASTE_ENTRY_DELETED: 'Waste Entry Deleted',
            WASTE_ADD: 'Add Waste Type',
            WASTE_EDIT: 'Edit Waste Type',
            UNIT_EDIT: 'Edit Unit',
            TREATMENT_TYPE_EDIT: 'Edit Treatment Type',
            UNITS: 'Units',
            TREATMENT_TYPE_CREATED: 'Treatment Type Created',
            TREATMENT_TYPE_UPDATED: 'Treatment Type Updated',
            TREATMENT_TYPE_DELETED: 'Treatment Type Deleted',
            TARGET_DIRECTION: 'Target Direction',
            TARGET_DIRECTION_BELOW: 'Below target is positive',
            TARGET_DIRECTION_ABOVE: 'Above target is positive',
            TARGET_PERCENT: 'Target (%)',
            UNIT_UPDATED: 'Unit Type Updated',
            UNIT_DELETED: 'Unit Type Deleted',
            UNIT_CREATED: 'Unit Type Created',
            WASTE_MANAGEMENT_TARGETS: 'Waste Managements Target',
            UNIT_LIST: 'Units',
            WASTEMANAGEMENT: 'Waste Management',
            DATE_START: 'Date Start',
            DATE_END: 'Date End',
            ENTRYDATE: 'Collection Date',
            ENTRY_DATE: 'Collection Date',
            WASTE_ENTRY_ADD: 'Add Waste Entry',
            WASTE_ENTRY_EDIT: 'Edit Waste Entry',
            WASTE_ENTRIES: 'Waste Entries',
            ENTRIES: 'Entries',
            ENTRIES_IMPORT: 'Import Waste Entries',
            TAG_WASTEENTRY: 'Waste Entry',
            TREATMENT_TYPE_ADDED: 'Treatment Type Added',
            LAST_THREE_ENTRIES: 'Last 3 Waste Entries',

            DOWNOLAD_WASTE_TYPE: 'Download system {{  ::"WASTE_TYPES" | translate }}',
            DOWNOLAD_TREATMENT_TYPE: 'Download system {{  ::"TREATMENT_TYPES" | translate }}',
            DOWNOLAD_UNIT: 'Download system {{  ::"UNITS" | translate }}'
        });
    }
})();
