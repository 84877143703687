app.config(function ($translateProvider) {
    $translateProvider.translations('nl', {
        PRIORITY: 'Prioriteit',
        PRIORITIES: 'Prioriteiten',

        ADD_PRIORITY: 'Voeg een nieuwe prioriteit toe',

        PRIORITY_LIST: 'Prioriteit',
        PRIORITY_EDIT: 'Wijzig prioriteit',
        PRIORITY_ADD: 'Voeg een nieuwe prioriteit toe',

        PRIORITY_CREATED: 'Prioriteit aangemaakt',
        PRIORITY_UPDATED: 'Prioriteit gewijzigd',
        PRIORITY_DELETED: 'Prioriteit verwijderd',

        DEADLINE: 'Deadline',
        ATTEND_TIME: 'Aanwezig voor',

        ATTEND_DEADLINE: 'Deadline aanwezigheid',
        NON_WORKING_DAYS: 'Geen werkdagen',
        INCLUDE_NON_WORKING_DAYS: 'Inclusief weekenddagen',
        COMPLETE_TIME: 'Gereed op',
        COMPLETE_DEADLINE: 'Deadline afronding',
        APPROVAL_DELAY: 'Goedkeuring vertraging',
        ACKNOWLEDGE_DELAY: 'Bevestiging vertraging',
        ALARM_WHEN_OVERDUE: 'Waarschuwing bij overschreiding',
        ALERT_MESSAGE_ENABLED: 'Waarschuwings bericht AAN',
        ALERT_MESSAGE: 'Waarschuwingsbericht',
        ADD_NEW_PRIORITY: 'Voeg een nieuwe prioriteit toe',
        INTERVAL: 'Interval',
        ATTENDANCE_DUE: 'Aanwezigheid verplicht voor',
        COMPLETION_DUE: 'Afronding verplicht voor',

        PRIORITY_MESSAGE: 'Prioriteit bericht',

        HELPTEXT_DELAY: 'Lege waarde gebruikt standaard systeem instelling',

        ENTITY_TYPE: 'Type',
        ENTITY_TYPE_ALL: 'Alle',
        ENTITY_TYPE_JOB: 'Opdrachten',
        ENTITY_TYPE_QUOTE: 'Offerte',
        ENTITY_TYPE_ACTION: 'Actie',

        SELECT_PRIORITY: 'Selecteer prioriteit'
    });
});
