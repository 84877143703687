(function () {
    'use strict';

    angular
        .module('elogbooks.common.audit')
        .controller('AuditAnswerTemplateDetailsController', AuditAnswerTemplateDetailsController);

    AuditAnswerTemplateDetailsController.$inject = [
        'answerTemplateResponse',
        'answerOptionTemplateCollectionResponse'
    ];

    function AuditAnswerTemplateDetailsController(
        answerTemplateResponse,
        answerOptionTemplateCollectionResponse
        ) {

        var vm = this;
        vm.data = {};
        vm.answerTemplate = answerTemplateResponse;
        vm.data.answerOptions = answerOptionTemplateCollectionResponse.answerOptionTemplates;
    }
})();
