angular.module('elogbooksDirectives').directive('timeDropdown', function(){
    return {
        // Restrict to attributes only.
        restrict: 'A',
        require: ['ngModel'],
        scope: {
            user: '=ngModel'
        },
        templateUrl: '/modules/directives/form-elements/time-dropdown/time.html',
        link: function (scope) {
            scope.options = [];
            for (var i = 0; i < 24; i++) {
                var hour = ('0' + i).slice(-2);
                scope.options.push(hour + ':00');
                scope.options.push(hour + ':30');
            }
        }
    }
});
