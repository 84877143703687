(function () {
    'use strict';

    angular
        .module('elogbooksDirectives')
        .directive('elbJobStatus', ElbJobStatus);

    ElbJobStatus.$inject = [];

    function ElbJobStatus () {
        return {
            restrict: 'AE',
            scope: {
                status: '@model'
            },
            templateUrl: '/modules/directives/elb-job-status/job-status.html'
        }
    }
})();
