(function (){
    'use strict';

    angular
        .module('elogbooks.user.tasks')
        .controller('UserTaskGeneratePlannerController', UserTaskGeneratePlannerController);

    UserTaskGeneratePlannerController.$inject = ['$state', '$stateParams', 'sectionResource', 'userManager'];

    function UserTaskGeneratePlannerController($state, $stateParams, sectionResource, userManager) {
        var vm = this;
        vm.sectionResource = sectionResource;
        vm.user = userManager.user;
    }
})();
