(function() {
    'use strict';

    angular
        .module('elogbooks.user.meter-suppliers')
        .controller('MeterSuppliersAddEditController', MeterSuppliersAddEditController);

    MeterSuppliersAddEditController.$inject = ['$state', 'apiClient', 'confirmationModal', 'messenger', 'meterSupplierResponse', 'meterSuppliersCollectionResponse', 'requestDataFactory'];
    function MeterSuppliersAddEditController($state, apiClient, confirmationModal, messenger, meterSupplierResponse, meterSuppliersCollectionResponse, requestDataFactory) {
        var vm = this;
        vm.meterSupplier = meterSupplierResponse;
        vm.data = requestDataFactory.createRequest(meterSupplierResponse);
        vm.meterSuppliersCollectionResponse = meterSuppliersCollectionResponse;

        vm.create = createAction;
        vm.update = updateAction;
        vm.delete = deleteAction;

        function updateAction() {
            return apiClient.update(vm.meterSupplier.getLink('edit'), vm.data).then(function(response) {
                if (response) {
                    $state.go('^', {}, {reload: $state.get('^.^.^')}).then(function() {
                        messenger.success('METER_SUPPLIER_UPDATED');
                    });
                } else {
                    messenger.error('REQUEST_ERROR');
                }
            });
        }

        function createAction() {
            return apiClient.create(vm.meterSuppliersCollectionResponse.getLink('create'), vm.data).then(function(response) {
                if (response) {
                    $state.go('^.details.info', {resource: response.getLink('self').encode()}, { reload: $state.current.parent }).then(function() {
                        messenger.success('METER_SUPPLIER_CREATED');
                    });
                } else {
                    messenger.error('REQUEST_ERROR');
                }
            });
        }

        function deleteAction() {
            return confirmationModal.open().result.then(function(response) {
                if (response.result) {
                     apiClient.delete(vm.meterSupplier.getLink('delete')).then(function(isSuccess) {
                        if (isSuccess) {
                            $state.go('^.^.^', {}, {reload: $state.get('^.^.^')}).then(function(){
                                messenger.success('METER_SUPPLIER_DELETED');
                            });
                        } else {
                            messenger.error('REQUEST_ERROR');
                        }
                    });
                }
            });
        }
    }
})();
