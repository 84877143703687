/**
 * QuoteCall form
 */
angular.module('elogbooksDirectives').directive('surveyrequests', ['$http', 'apiClient', '$state', function ($http, apiClient, $state) {
    return {
        restrict: 'A',
        require: ['ngModel'],
        scope: {
            ngModel: '=',
            editable: '=',
            clickto: '='
        },
        templateUrl: '/modules/directives/survey/survey-request/list/list.html'
    };
}]);
