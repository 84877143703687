(function () {
    'use strict';

    angular
        .module('elogbooks.user.reports')
        .controller('UserReportsCommonQuotesModalController', UserReportsCommonQuotesModalController);

    UserReportsCommonQuotesModalController.$inject = ['titlePrefix', 'columnName', 'collection'];

    function UserReportsCommonQuotesModalController(titlePrefix, columnName, collection) {
        var vm = this;
        vm.titlePrefix = titlePrefix;
        vm.columnName = columnName;
        vm.collection = collection;
        vm.columnIn = columnIn;

        function columnIn(array) {
            return array.indexOf(vm.columnName) !== -1;
        }
    }
})();