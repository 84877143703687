(function () {
    'use strict';

    angular
        .module('elogbooksDirectives')
        .directive('stickyHeader', [stickyHeader]);
    function stickyHeader() {
        return {
            link: link,
            restrict: 'AE',
            replace:true
        };

        function link() {
            var positionY = false;
            var elements = [];
            var topOffset = null;
            var parentTable = null;
            var thHead = null;
            var ppmTable = angular.element(document.getElementsByClassName('ppm-table')[0]);
            var topNavigationBar = angular.element(document.getElementsByClassName('navbar')[0]);

            // Wait for render
            setTimeout(function () {
                var stickyHeaderElements = document.getElementsByClassName('sticky-header');

                if (stickyHeaderElements) {
                    for (var i = 0; i < stickyHeaderElements.length; i++) {
                        elements.push(angular.element(stickyHeaderElements[i]));
                    }
                    thHead = angular.element(elements[1].find('.table-head'));
                    parentTable = angular.element(elements[2].closest('table'));
                    parentTable.on('scroll', syncHorizontalScroll);

                    topOffset = topNavigationBar.prop('clientHeight');
                    positionY = elements[0].offset().top;
                }
            }, 1000);

            // Use timeout to update the DOM elements to avoid issues with scroll delay
            function onScroll() {
                if (elements.length) {
                    if (window.pageYOffset > positionY - topOffset) {
                        ppmTable.css('margin-top', elements[0].prop('clientHeight') + elements[1].prop('clientHeight'));
                        syncHorizontalScroll();

                        for (var i = 0; i < elements.length; i++) {
                            if (!elements[i].hasClass('sticky')) {
                                if (i === 1) {
                                    var width = 0;
                                    var thElements = thHead.find('th');
                                    for (var k = 0; k < thElements.length; k++) {
                                        var thElement1 = angular.element(thElements[k]);
                                        width += thElement1.prop('clientWidth');
                                    }
                                    for (var j = 0; j < thElements.length; j++) {
                                        var thElement = angular.element(thElements[j]);
                                        // ie hack for 11
                                        if (j === 0 && thElement.hasClass('side-checkbox')) {
                                            thElement.css('width', 33);
                                        } else if (j === 1 && thElement.hasClass('side-edit')) {
                                            thElement.css('width', 45);
                                        } else {
                                            thElement.css('width', width / (thElements.length -2));
                                        }
                                    }
                                }

                                elements[i].addClass('sticky');
                                elements[i].css('width', elements[i].prop('parentNode').clientWidth);
                                // -1 is a fix for firefox css rendering differences
                                elements[i].css('top', i > 0 ? elements[0].prop('clientHeight') + topOffset - 1 : topOffset);
                            }
                        }
                    } else {
                        ppmTable.css('margin-top', 0);
                        for (var i = 0; i < elements.length; i++) {
                            elements[i].removeClass('sticky');
                            elements[i].css('width', '');
                            elements[i].css('top', '');
                        }
                    }
                }
            }

            // Keep horizontal scroll in sync
            function syncHorizontalScroll() {
                elements[2].scrollLeft(parentTable.prop('scrollLeft'));
            }

            function onResize() {
                if (elements.length) {
                    ppmTable.css('margin-top', 0);

                    for (var i = 0; i < elements.length; i++) {
                        if (i === 1) {
                            var thElements = thHead.find('th');
                            for (var j = 0; j < thElements.length; j++) {
                                angular.element(thElements[j]).css('width', '');
                            }
                        }
                        // Reset all properties.
                        elements[i].removeClass('sticky');
                        elements[i].css('width', '');
                        elements[i].css('top', '');
                    }

                    topOffset = topNavigationBar.prop('clientHeight');
                    positionY = elements[0].offset().top;

                    onScroll();
                }
            }

            angular.element(window).on('scroll', onScroll);
            angular.element(window).on('wheel', onScroll);
            angular.element(window).on('resize', onResize);

            // Support touch events
            angular.element(window).on('touchmove', onScroll);
            angular.element(window).on('touchend', onScroll);
        }
    }
})();
