(function() {
    'use strict';

    angular
        .module('elogbooks')
        .config(Translations);

    function Translations($translateProvider) {
        $translateProvider.translations('en-gb', {
            ACTION_TYPE: 'Action Type',
            ACTION_TYPES: 'Action Types',
            ACTION_TYPE_LIST: 'Action Types',

            ACTION_TYPE_ADD: 'Add a new Action Type',
            ACTION_TYPE_EDIT: 'Edit Action Type',

            ACTION_TYPE_CREATED: 'Action Type created',
            ACTION_TYPE_UPDATED: 'Action Type updated',
            ACTION_TYPE_DELETED: 'Action Type deleted',
            CANNOT_USE_ACTION_TYPE_RESERVED_NAME: 'Cannot use action type reserved name',
            ALLOW_SERVICEPROVIDER_ACCESS: 'Allow service provider access'
        });
    }
})();
