/**
 * Month picker directive
 */
angular.module('elogbooksDirectives').directive('months', function () {
    return {
        restrict: 'A',
        require: ['ngModel'],
        scope: {
            month: '=ngModel',
            required: '=ngRequired'
        },
        templateUrl: '/modules/directives/form-elements/months/months.html',
        link: function (scope) {
            scope.options = [];
            angular.forEach(moment.months(), function(value, key) {
                 scope.options.push({
                    value: key + 1,
                    label: value
                 });

            });
        }
    }
});
