(function () {
    'use strict';

    /**
     * @desc Survey score
     * @example <data-survey-score ng-model="vm.score"></data-survey-score>
     */
    angular
        .module('elogbooksDirectives')
        .directive('surveyScore', ['apiClient', surveyScore]);

    function surveyScore(apiClient) {

        var directive = {
            link: link,
            templateUrl: '/modules/directives/form-elements/surveyscore/surveyscore.html',
            restrict: 'EA',
            scope: {
                score: '=ngModel',
                range: '=',
                elementName: '=',
                disabled: '=ngDisabled',
                editable: '=',
                form: '='
            },
            require: ['ngModel']
        };
        return directive;

        function link(scope, element, attrs) {

            if (typeof scope.elementName === 'undefined') {
                scope.elementName = 'score';
            }

            if (scope.editable) {
                switch (scope.range) {
                    case '0to5':
                        scope.options = [
                            {
                                value: 0,
                                label: '0 - None of the requirements have been achieved'
                            }, {
                                value: 1,
                                label: '1 - The majority of requirements have not been achieved'
                            }, {
                                value: 2,
                                label: '2 - Some of the requirements have not been achieved'
                            }, {
                                value: 3,
                                label: '3 - The majority of requirements have been achieved'
                            }, {
                                value: 4,
                                label: '4 - The vast majority of requirements have been achieved'
                            }, {
                                value: 5,
                                label: '5 - All requirements have been achieved satisfactorily'
                            }
                        ];
                        break;
                    case '0to2':
                        scope.options = [
                            {
                                value: 0,
                                label: '0 - None of the requirements have been achieved'
                            }, {
                                value: 1,
                                label: '1 - The majority of requirements have not been achieved'
                            }, {
                                value: 2,
                                label: '2 - All requirements have been achieved satisfactorily'
                            }
                        ];
                        break;
                    case 'yn':
                        scope.options = [
                            {
                                value: 1,
                                label: 'YES'
                            }, {
                                value: 0,
                                label: 'NO'
                            }
                        ];
                        break;
                }
            } else {
                if (scope.range === 'yn') {
                    scope.scoreLabel = (scope.score === 1) ? 'Yes' : 'No';
                } else {
                    scope.scoreLabel = (scope.score) ? scope.score : '-';
                }
            }

        }
    }
})();
