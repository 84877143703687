(function () {
    'use strict';

    angular
        .module('elogbooks.user.patrols')
        .controller('PatrolSchedulesDetailController', PatrolSchedulesDetailController);

    PatrolSchedulesDetailController.$inject = ['$state', '$stateParams', 'lodash', 'apiClient', 'patrolScheduleResponse', 'patrolOccurrenceCollection', 'partnershipsCollection', 'messenger', 'daysOfWeek', '$filter', 'bulkEditPatrolSchedulesModal', 'siteResponse'];

    function PatrolSchedulesDetailController($state, $stateParams, lodash, apiClient, patrolScheduleResponse, patrolOccurrenceCollection, partnershipsCollection, messenger, daysOfWeek, $filter, bulkEditPatrolSchedulesModal, siteResponse) {
        var vm = this;
        vm.order = newOrder;
        vm.search = search;
        vm.schedule = patrolScheduleResponse;
        vm.occurrences = patrolOccurrenceCollection ? patrolOccurrenceCollection.patrolOccurrences : {};
        vm.occurrences.checked = false;
        vm.occurrences._links = patrolOccurrenceCollection._links;
        vm.daysOfWeek = daysOfWeek;
        vm.checkClicked = checkClicked;
        vm.checkedBulkAction = checkedBulkAction;
        vm.checkedItemCount = 0; // Number of rows checked
        vm.checkedSelect = null; // What was selected from the checked bulk actions
        vm.serviceProviderOptions = [
            {title: $filter('translate')('NONE_SELECTED'), value: null},
            {title: $filter('translate')('NO_SERVICE_PROVIDER_ENTERED'), value: -999}
        ];
        vm.patrolOccurrenceCollection = patrolOccurrenceCollection;

        vm.entityCount = patrolOccurrenceCollection.count;

        var activeOptions = [
            {
                title: $filter('translate')('NONE_SELECTED'),
                value: null
            },
            {
                title: $filter('translate')('STATUS_ACTIVE'),
                value: 'active'
            },
            {
                title: $filter('translate')('STATUS_INACTIVE'),
                value: 'inactive'
            }
        ];

        vm.criteria = {
            occurrenceDayOfWeek: {
                type: 'options',
                title: 'DAY_OF_WEEK',
                value: parseInt($stateParams.occurrenceDayOfWeek) || null,
                options: daysOfWeek.getAllForSelect()
            },
            occurrenceServiceProvider: {
                type: 'options',
                title: 'SERVICE_PROVIDER',
                value: parseInt($stateParams.occurrenceServiceProvider) || null,
                options: vm.serviceProviderOptions
            },
            occurrenceStatus: {
                type: 'options',
                title: 'IS_ACTIVE',
                value: $stateParams.occurrenceStatus,
                options: activeOptions
            }
        };

        angular.forEach(partnershipsCollection.partnerships, function (value) {
            vm.serviceProviderOptions.push({
                title: value._links.serviceprovider.title,
                value: value._links.serviceprovider.id
            });
        });

        function search(params) {
            // Save state of occurrence params
            $stateParams.occurrenceDayOfWeek = params.occurrenceDayOfWeek;
            $stateParams.occurrenceServiceProvider = params.occurrenceServiceProvider;
            $stateParams.occurrenceStatus = params.occurrenceStatus;

            var override = {
                occurrencePage: 1
            };

            $state.go('.', angular.extend({}, $stateParams, override, {
                occurrenceOrder: $stateParams.occurrenceOrder,
                schedulePage: 1,
                dayOfWeek: params.occurrenceDayOfWeek,
                scheduleDayOfWeek: params.occurrenceDayOfWeek,
                serviceProvider: params.occurrenceServiceProvider,
                status: params.occurrenceStatus
            }), { reload: true });
        }

        function oldOrder(key) {
            $stateParams.occurrenceOrder = key;
            $stateParams.occurrencePage = 1;

            $state.go('.', angular.extend({}, $stateParams, override), {reload: false});
        }

        function newOrder(key) {

            $stateParams.occurrenceOrder = key;
            $stateParams.occurrencePage = 1;

            var params = angular.extend({}, $stateParams, {
                page: 1,
                order: key
            });

            apiClient.get(patrolScheduleResponse.getLink('schedules'), params).then(
                function(response) {
                    vm.occurrences = response.patrolSchedules;
                }
            );
        }

        /**
         * A checkbox was clicked on the table
         *
         * @param object selected
         */
        function checkClicked(selected) {
            if (selected) {
                // A row checkbox was clicked
                selected.checked = !selected.checked;
                vm.checkedItemCount = 0;
                lodash.forEach(vm.occurrences, function (value, key) {
                    if (value.checked) {
                        vm.checkedItemCount++;
                    }
                });
            } else {
                // The header checkbox was clicked
                vm.checkedItemCount = 0;
                // Invert the list checked status
                vm.occurrences.checked = !vm.occurrences.checked;
                lodash.forEach(vm.occurrences, function (value, key) {
                    value.checked = vm.occurrences.checked;
                    vm.checkedItemCount += vm.occurrences.checked;
                });
            }
        }

        /**
         * Run an action against the checked items
         */
        function checkedBulkAction(element) {
            switch (vm.checkedSelect) {
                case 'delete':
                    break;
                case 'edit':
                    editBulkAction();
                    vm.checkedSelect = '';
                    break;
            }

        }

        function editBulkAction() {
            bulkEditPatrolSchedulesModal.open(siteResponse, vm.checkedItemCount, vm).result.then(
                function (form) {
                    console.log('You altered the instance');

                    $state.go('.', angular.extend({}, $stateParams), {reload: true});
                }
            );
        }

        function updateRows() {
            console.log('update....');
            console.log(arguments[0]);
        }
    }
})();
