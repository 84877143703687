(function () {
    'use strict';

    angular
        .module('elogbooks.admin.settings')
        .controller('AdminEditQuoteExtraSettingsController', AdminEditQuoteExtraSettingsController);

    function AdminEditQuoteExtraSettingsController($scope, apiClient, messenger, quoteExtraSettings, lodash) {
        var vm = this;
            vm.settings = quoteExtraSettings
            vm.update = updateAction;

        function updateAction() {
            var requestData = {
                settings: []
            };

            lodash.forEach(vm.settings.settings, function (setting) {
                requestData.settings.push({
                    name: setting.name,
                    value: setting.value
                });
            });

            apiClient.update(quoteExtraSettings.getLink('edit'), requestData).then(function (response) {
                if (response) {
                    $scope.$state.go('^', {}, { reload: true }).then(function () {
                        messenger.success('SETTINGS_UPDATED');
                    });
                } else {
                    messenger.error('REQUEST_ERROR');
                }
            });
        }
    }
})();

