(function() {
    'use strict';

    angular
        .module('elogbooks')
        .controller('UpdatesAvailableController', UpdatesAvailableController);

    UpdatesAvailableController.$inject = ['$uibModalInstance', '$window', '$rootScope', '$timeout'];

    function UpdatesAvailableController($uibModalInstance, $window, $rootScope, $timeout) {
        var vm = this;
        vm.reminderAt = null;

        vm.reload = reloadAction;
        vm.postpone = postponeAction;
        vm.close = closeAction;

        function reloadAction() {
            $rootScope.skipDirtyCheck = true;
            $window.location.reload(true);
        }

        function postponeAction() {
            $timeout.cancel(automatedReload);
            $uibModalInstance.close(vm.reminderAt);
        }

        function closeAction() {
            $timeout.cancel(automatedReload);
            $uibModalInstance.dismiss(60000);
        }

        var automatedReload = $timeout(function() {
            $window.location.reload();
        }, 300000);
    }
})();
