(function() {
    'use strict';

    angular
        .module('elogbooks.admin.vat-rates')
        .controller('VatRatesAdminController', VatRatesAdminController);

        VatRatesAdminController.$inject = ['vatRatesCollectionResponse', '$stateParams', '$state'];

    function VatRatesAdminController(vatRatesCollectionResponse, $stateParams, $state) {
        var vm = this;
        vm.vatRates = vatRatesCollectionResponse.vatRates;
        vm.vatRatesCollectionResponse = vatRatesCollectionResponse;
        vm.order = order;

        function order(key) {
            $stateParams.order = key;

            $state.go('.', $stateParams, {reload: $state.current});
        }
    }
})();
