(function () {
    'use strict';

    angular
        .module('elogbooks.helpdesk.dashboard')
        .config(registerRoutes)
        .config(registerQuoteRoutes)
        .config(registerPlannedJobRoutes)
        .config(registerReactiveJobRoutes);

    function registerRoutes ($stateProvider) {
        $stateProvider
            .state('dashboard.helpdesk.dashboard', {
                url: '/dashboard',
                abstract: true,
                params: {
                    analytics: true
                },
                resolve: {
                    statsResponse: function(apiClient, rootResourceResponse) {
                        return apiClient.get(rootResourceResponse.getLink('dashboardstats'));
                    },
                    approversResponse: function (user, apiClient) {
                        return apiClient.get(user.getLink('users'), {
                            membershipPermission: 'site_permission_can_be_approver',
                            limit: 30,
                            exclude: true
                        }).then(function (response) {
                            return response || apiClient.noResourceFound();
                        });
                    }
                },
                views: {
                    '': {
                        templateUrl: '/modules/helpdesk/dashboard/dashboard.html',
                        controller: 'HelpdeskDashboardController',
                        controllerAs: 'vm'
                    }
                },
                ncyBreadcrumb: {
                    label: '{{ ::"HELPDESK_DASHBOARD" | translate }}'
                }
            })
            .state('dashboard.helpdesk.dashboard.awaiting-paperwork', {
                url: '/awaiting-paperwork?page&id&order&limit&{serviceProviderName:any}&siteName&approverName&escalationStatus&alarmAtDueRange',
                parent: 'dashboard.helpdesk.dashboard',
                params: {
                    page: '1',
                    limit: '30',
                    order: null,
                    approverName: null,
                    id: null,
                    escalationStatus: null,
                    alarmAtDueRange: null
                },
                resolve: {
                    jobCollectionResponse: function (apiClient, user, $stateParams) {
                        var params = angular.extend({}, $stateParams,
                            {
                                page: $stateParams.page,
                                limit: $stateParams.limit,
                                order: $stateParams.order,
                                id: $stateParams.id,
                                'status[]': ['awaiting_paperwork'],
                                reminderAt: true,
                                helpdeskDashboard: true,
                                alarmAtDueRange: $stateParams.alarmAtDueRange
                            }
                        );

                        return apiClient.get(user.getLink('jobs'), params).then(function (response) {
                            return response || apiClient.noResourceFound();
                        });
                    },
                    config: function() {
                        return {
                            alarmed: 'reminderAt',
                        };
                    }
                },
                views: {
                    '': {
                        templateUrl: '/modules/helpdesk/dashboard/jobs/jobs.html',
                        controller: 'HelpdeskDashboardJobsController',
                        controllerAs: 'vm'
                    }
                },
                ncyBreadcrumb: {
                    label: '{{ ::"HD_TOOLTIP_AWAITING_PAPERWORK" | translate }}'
                }
            });
    }

    function registerQuoteRoutes ($stateProvider) {
        $stateProvider
            .state('dashboard.helpdesk.dashboard.quotes', {
                url: '/quotes?page&id&order&limit&{serviceProviderName:any}&siteName&approverName&escalationStatus&alarmAtDueRange',
                parent: 'dashboard.helpdesk.dashboard',
                abstract: true,
                params: {
                    page: '1',
                    limit: '30',
                    order: 'alarmAt',
                    approverName: null,
                    id: null,
                    escalationStatus: null,
                    alarmAtDueRange: null
                },
                ncyBreadcrumb: {
                    label: '{{ ::"QUOTES" | translate }}'
                }
            })
            .state('dashboard.helpdesk.dashboard.quotes.chase', {
                url: '/chase',
                parent: 'dashboard.helpdesk.dashboard.quotes',
                resolve: {
                    quoteCollectionResponse: function ($stateParams, apiClient, user) {
                        var params = angular.extend({}, $stateParams,
                            {
                                chasing: true,
                                alarmed: true,
                                helpdeskDashboard: true,
                                alarmAtDueRange: $stateParams.alarmAtDueRange
                            }
                        );

                        return apiClient.getPage(user.getLink('quotes'), params).then(function(response) {
                            return response || apiClient.noResourceFound();
                        });
                    },
                    config: function() {
                        return {
                            alarmed: 'alarmAt',
                        };
                    }
                },
                views: {
                    '@dashboard.helpdesk.dashboard': {
                        templateUrl: '/modules/helpdesk/dashboard/quotes/quotes.html',
                        controller: 'HelpdeskDashboardQuotesController',
                        controllerAs: 'vm'
                    }
                },
                ncyBreadcrumb: {
                    label: '{{ ::"HD_TOOLTIP_QUOTES_CHASE" | translate }}'
                }
            })
            .state('dashboard.helpdesk.dashboard.quotes.reminder', {
                url: '/reminder',
                parent: 'dashboard.helpdesk.dashboard.quotes',
                resolve: {
                    quoteCollectionResponse: function ($stateParams, apiClient, user) {
                        var params = angular.extend({}, $stateParams,
                            {
                                alarmed: true,
                                chasing: false,
                                helpdeskDashboard: true,
                                alarmAtDueRange: $stateParams.alarmAtDueRange
                            }
                        );

                        return apiClient.getPage(user.getLink('quotes'), params).then(function(response) {
                            return response || apiClient.noResourceFound();
                        });
                    },
                    config: function() {
                        return {
                            alarmed: 'alarmAt',
                        };
                    }
                },
                views: {
                    '@dashboard.helpdesk.dashboard': {
                        templateUrl: '/modules/helpdesk/dashboard/quotes/quotes.html',
                        controller: 'HelpdeskDashboardQuotesController',
                        controllerAs: 'vm'
                    }
                },
                ncyBreadcrumb: {
                    label: '{{ ::"HD_TOOLTIP_QUOTES_REMINDER" | translate }}'
                }
            })
            .state('dashboard.helpdesk.dashboard.quotes.extension-approval', {
                url: '/extension-approval',
                parent: 'dashboard.helpdesk.dashboard.quotes',
                resolve: {
                    quoteCollectionResponse: function ($stateParams, apiClient, user) {
                        var params = angular.extend({}, $stateParams,
                            {
                                reminderPassed: true,
                                status: 'extension_requested',
                                helpdeskDashboard: true,
                                alarmAtDueRange: $stateParams.alarmAtDueRange
                            }
                        );

                        return apiClient.getPage(user.getLink('quotes'), params).then(function(response) {
                            return response || apiClient.noResourceFound();
                        });
                    },
                    config: function() {
                        return {
                            alarmed: 'reminderAt',
                        };
                    }
                },
                views: {
                    '@dashboard.helpdesk.dashboard': {
                        templateUrl: '/modules/helpdesk/dashboard/quotes/quotes.html',
                        controller: 'HelpdeskDashboardQuotesController',
                        controllerAs: 'vm'
                    }
                },
                ncyBreadcrumb: {
                    label: '{{ ::"HD_TOOLTIP_QUOTES_EXTENSION_APPROVAL" | translate }}'
                }
            });
    }

    function registerPlannedJobRoutes ($stateProvider) {
        $stateProvider
            .state('dashboard.helpdesk.dashboard.planned-jobs', {
                url: '/planned-jobs?page&id&order&limit&{serviceProviderName:any}&siteName&approverName&escalationStatus&alarmAtDueRang',
                parent: 'dashboard.helpdesk.dashboard',
                abstract: true,
                params: {
                    page: '1',
                    limit: '30',
                    order: 'alarmAt',
                    approverName: null,
                    id: null,
                    escalationStatus: null,
                    helpdeskDashboard: true,
                    alarmAtDueRange: null
                },
                ncyBreadcrumb: {
                    label: '{{ ::"HD_PLANNED" | translate }}'
                }
            })
            .state('dashboard.helpdesk.dashboard.planned-jobs.alarms', {
                url: '/chase',
                parent: 'dashboard.helpdesk.dashboard.planned-jobs',
                resolve: {
                    jobCollectionResponse: function (apiClient, user, $stateParams, jobStatuses) {
                        return apiClient.get(user.getLink('jobs'), angular.extend({}, $stateParams, {
                            type: 'planned',
                            chasing: true,
                            alarmed: true,
                            order: $stateParams.order,
                            helpdeskDashboard: true,
                            alarmAtDueRange: $stateParams.alarmAtDueRange
                        })).then(function (response) {
                            return response || apiClient.noResourceFound();
                        });
                    },
                    config: function() {
                        return {
                            alarmed: 'alarmAt',
                        };
                    }
                },
                views: {
                    '@dashboard.helpdesk.dashboard': {
                        templateUrl: '/modules/helpdesk/dashboard/jobs/jobs.html',
                        controller: 'HelpdeskDashboardJobsController',
                        controllerAs: 'vm'
                    }
                },
                ncyBreadcrumb: {
                    label: '{{ ::"HD_TOOLTIP_CHASE" | translate }}'
                }
            })
            .state('dashboard.helpdesk.dashboard.planned-jobs.overdue', {
                url: '/reminder',
                parent: 'dashboard.helpdesk.dashboard.planned-jobs',
                resolve: {
                    jobCollectionResponse: function (apiClient, user, $stateParams, jobStatuses) {
                        return apiClient.get(user.getLink('jobs'), angular.extend({}, $stateParams, {
                            type: 'planned',
                            alarmed: true,
                            chasing: false,
                            order: $stateParams.order,
                            helpdeskDashboard: true,
                            alarmAtDueRange: $stateParams.alarmAtDueRange
                        })).then(function (response) {
                            return response || apiClient.noResourceFound();
                        });
                    },
                    config: function() {
                        return {
                            alarmed: 'alarmAt',
                        };
                    }
                },
                views: {
                    '@dashboard.helpdesk.dashboard': {
                        templateUrl: '/modules/helpdesk/dashboard/jobs/jobs.html',
                        controller: 'HelpdeskDashboardJobsController',
                        controllerAs: 'vm'
                    }
                },
                ncyBreadcrumb: {
                    label: '{{ ::"HD_TOOLTIP_REMINDER" | translate }}'
                }
            });
    }

    function registerReactiveJobRoutes ($stateProvider) {
        $stateProvider
            .state('dashboard.helpdesk.dashboard.reactive-jobs', {
                url: '/reactive-jobs?page&id&order&limit&{serviceProviderName:any}&siteName&approverName&escalationStatus&alarmAtDueRange',
                parent: 'dashboard.helpdesk.dashboard',
                abstract: true,
                params: {
                    page: '1',
                    limit: '30',
                    approverName: null,
                    id: null,
                    order: null,
                    jobStatus: null,
                    escalationStatus: null,
                    helpdeskDashboard: true,
                    alarmAtDueRange: null
                },
                ncyBreadcrumb: {
                    label: '{{ ::"HD_REACTIVE" | translate }}'
                }
            })
            .state('dashboard.helpdesk.dashboard.reactive-jobs.alarms', {
                url: '/alarms',
                parent: 'dashboard.helpdesk.dashboard.reactive-jobs',
                resolve: {
                    jobCollectionResponse: function (apiClient, user, $stateParams) {
                        return apiClient.get(user.getLink('jobs'), angular.extend({}, $stateParams, {
                            type: 'reactive',
                            alarmed: true,
                            chasing: false,
                            order: $stateParams.order ? $stateParams.order : 'alarmAt',
                            helpdeskDashboard: true,
                            alarmAtDueRange: $stateParams.alarmAtDueRange
                        })).then(function (response) {
                            return response || apiClient.noResourceFound();
                        });
                    },
                    config: function() {
                        return {
                            alarmed: 'alarmAt',
                        };
                    }
                },
                views: {
                    '@dashboard.helpdesk.dashboard': {
                        templateUrl: '/modules/helpdesk/dashboard/jobs/jobs.html',
                        controller: 'HelpdeskDashboardJobsController',
                        controllerAs: 'vm'
                    }
                },
                ncyBreadcrumb: {
                    label: '{{ ::"HD_TOOLTIP_REACTIVE_ALARMS" | translate }}'
                }
            })
            .state('dashboard.helpdesk.dashboard.reactive-jobs.awaiting-approval', {
                url: '/awaiting-approval',
                parent: 'dashboard.helpdesk.dashboard.reactive-jobs',
                resolve: {
                    jobCollectionResponse: function (apiClient, user, $stateParams, jobStatuses) {
                        return apiClient.get(user.getLink('jobs'), angular.extend({}, $stateParams, {
                            type: 'reactive',
                            'status[]': $stateParams.jobStatus ? $stateParams.jobStatus  : jobStatuses.getAll('approval'),
                            reminderAt: true,
                            order: $stateParams.order ? $stateParams.order : 'reminderAt',
                            helpdeskDashboard: true,
                            alarmAtDueRange: $stateParams.alarmAtDueRange
                        })).then(function (response) {
                            return response || apiClient.noResourceFound();
                        });
                    },
                    config: function() {
                        return {
                            alarmed: 'reminderAt',
                        };
                    }
                },
                views: {
                    '@dashboard.helpdesk.dashboard': {
                        templateUrl: '/modules/helpdesk/dashboard/jobs/jobs.html',
                        controller: 'HelpdeskDashboardJobsController',
                        controllerAs: 'vm'
                    }
                },
                ncyBreadcrumb: {
                    label: '{{ ::"HD_TOOLTIP_REACTIVE_APPROVAL" | translate }}'
                }
            })
            .state('dashboard.helpdesk.dashboard.reactive-jobs.awaiting-extension-approval', {
                url: '/awaiting-extension-approval',
                parent: 'dashboard.helpdesk.dashboard.reactive-jobs',
                resolve: {
                    jobCollectionResponse: function (apiClient, user, $stateParams) {
                        return apiClient.get(user.getLink('jobs'), angular.extend({}, $stateParams, {
                            type: 'reactive',
                            'status[]': ['extension_pending'],
                            reminderAt: true,
                            order: $stateParams.order ? $stateParams.order : 'reminderAt',
                            helpdeskDashboard: true,
                            alarmAtDueRange: $stateParams.alarmAtDueRange
                        })).then(function (response) {
                            return response || apiClient.noResourceFound();
                        });
                    },
                    config: function() {
                        return {
                            alarmed: 'reminderAt',
                        };
                    }
                },
                views: {
                    '@dashboard.helpdesk.dashboard': {
                        templateUrl: '/modules/helpdesk/dashboard/jobs/jobs.html',
                        controller: 'HelpdeskDashboardJobsController',
                        controllerAs: 'vm'
                    }
                },
                ncyBreadcrumb: {
                    label: '{{ ::"HD_TOOLTIP_REACTIVE_EXTENSION_REQUEST" | translate }}'
                }
            })
            .state('dashboard.helpdesk.dashboard.reactive-jobs.pending-acknowledgement', {
                url: '/pending-acknowledgement',
                parent: 'dashboard.helpdesk.dashboard.reactive-jobs',
                resolve: {
                    jobCollectionResponse: function (apiClient, user, $stateParams, jobStatuses) {
                        return apiClient.get(user.getLink('jobs'), angular.extend({}, $stateParams, {
                            type: 'reactive',
                            'status[]': 'assignment_pending',
                            reminderAt: true,
                            order: $stateParams.order ? $stateParams.order : 'reminderAt',
                            helpdeskDashboard: true,
                            alarmAtDueRange: $stateParams.alarmAtDueRange
                        })).then(function (response) {
                            return response || apiClient.noResourceFound();
                        });
                    },
                    config: function() {
                        return {
                            alarmed: 'reminderAt',
                        };
                    }
                },
                views: {
                    '@dashboard.helpdesk.dashboard': {
                        templateUrl: '/modules/helpdesk/dashboard/jobs/jobs.html',
                        controller: 'HelpdeskDashboardJobsController',
                        controllerAs: 'vm'
                    }
                },
                ncyBreadcrumb: {
                    label: '{{ ::"HD_TOOLTIP_REACTIVE_ACKNOWLEDGE" | translate }}'
                }
            })
            .state('dashboard.helpdesk.dashboard.reactive-jobs.chase', {
                url: '/chase',
                parent: 'dashboard.helpdesk.dashboard.reactive-jobs',
                resolve: {
                    jobCollectionResponse: function (apiClient, user, $stateParams, jobStatuses) {
                        return apiClient.get(user.getLink('jobs'), angular.extend({}, $stateParams, {
                            type: 'reactive',
                            chasing: true,
                            alarmed: true,
                            order: $stateParams.order ? $stateParams.order : 'alarmAt',
                            helpdeskDashboard: true,
                            alarmAtDueRange: $stateParams.alarmAtDueRange
                        })).then(function (response) {
                            return response || apiClient.noResourceFound();
                        });
                    },
                    config: function() {
                        return {
                            alarmed: 'alarmAt',
                        };
                    }
                },
                views: {
                    '@dashboard.helpdesk.dashboard': {
                        templateUrl: '/modules/helpdesk/dashboard/jobs/jobs.html',
                        controller: 'HelpdeskDashboardJobsController',
                        controllerAs: 'vm'
                    }
                },
                ncyBreadcrumb: {
                    label: '{{ ::"HD_TOOLTIP_REACTIVE_CHASE" | translate }}'
                }
            })
            .state('dashboard.helpdesk.dashboard.reactive-jobs.priority-overdue', {
                url: '/priority-overdue',
                parent: 'dashboard.helpdesk.dashboard.reactive-jobs',
                scroll: null,
                resolve: {
                    jobCollectionResponse: function (apiClient, user, $stateParams, jobStatuses) {
                        return apiClient.get(user.getLink('jobs'), angular.extend({}, $stateParams, {
                            type: 'reactive',
                            priorityAlarm: true,
                            alarmed: true,
                            includeStatuses: true,
                            order: $stateParams.order ? $stateParams.order : 'attendanceDueAt',
                            helpdeskDashboard: true,
                            alarmAtDueRange: $stateParams.alarmAtDueRange
                        })).then(function (response) {
                            return response || apiClient.noResourceFound();
                        });
                    },
                    config: function() {
                        return {
                            alarmed: 'attendanceDueAt',
                        };
                    }
                },
                views: {
                    '@dashboard.helpdesk.dashboard': {
                        templateUrl: '/modules/helpdesk/dashboard/jobs/jobs.html',
                        controller: 'HelpdeskDashboardJobsController',
                        controllerAs: 'vm'
                    }
                },
                ncyBreadcrumb: {
                    label: '{{ ::"HD_TOOLTIP_REACTIVE_PRIORITY_OVERDUE" | translate }}'
                }
            })
            .state('dashboard.helpdesk.dashboard.escalations', {
                url: '/escalations?page&limit&order&id&{serviceProviderName:any}&alarmAtDueRange',
                parent: 'dashboard.helpdesk.dashboard',
                views: {
                    '': {
                        templateUrl: '/modules/helpdesk/dashboard/escalations/escalations.html',
                        controller: 'HelpdeskDashboardEscalationsController',
                        controllerAs: 'vm'
                    }
                },
                params: {
                    page: '1',
                    limit: '30',
                    order: '-alarmAt',

                    id: null,
                    serviceProviderName: null,
                    alarmAtDueRange: null
                },
                resolve: {
                    escalationCollectionResponse: function(apiClient, user, $stateParams) {

                        var params = {
                            page: $stateParams.page,
                            limit: $stateParams.limit,
                            order: $stateParams.order,
                            id: $stateParams.id,
                            serviceProviderName: $stateParams.serviceProviderName,
                            active: true,
                            alarmed: true,
                            helpdeskDashboard: true,
                            alarmAtDueRange: $stateParams.alarmAtDueRange
                        };

                        return apiClient.get(user.getLink('escalations'), params).then(function(response) {
                            return response || apiClient.noResourceFound();
                        });
                    },
                    config: function() {
                        return {
                            alarmed: 'alarmAt',
                        };
                    }
                },
                ncyBreadcrumb: {
                    label: '{{ ::"HD_TOOLTIP_ESCALATIONS" | translate }}'
                }
            })
            ;
    }
})();
