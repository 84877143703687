(function () {
    'use strict';

    angular
        .module('elogbooks.common.spillage-types')
        .controller('SpillageTypeAddEditController', SpillageTypeAddEditController);

    SpillageTypeAddEditController.$inject = ['$state', 'apiClient', 'messenger', 'spillageTypeResponse', 'requestDataFactory', 'spillageTypeCollectionResponse', 'spillageTypeCreateLink', '$translate', 'parentResponse', 'confirmationModal'];

    function SpillageTypeAddEditController ($state, apiClient, messenger, spillageTypeResponse, requestDataFactory, spillageTypeCollectionResponse, spillageTypeCreateLink, $translate, parentResponse, confirmationModal) {
        var vm = this;
            vm.spillageType = spillageTypeResponse;
            vm.spillageTypeCollectionResponse = spillageTypeCollectionResponse;
            vm.data = requestDataFactory.createRequest(vm.spillageType);

        angular.forEach(vm.spillageTypeCollectionResponse.spillagetypes, function (spillageType, $index) {
            spillageType.value = spillageType._links.self;
            spillageType.title = spillageType.name;
        });

        vm.spillageTypeSelect = {
            response: vm.spillageTypeCollectionResponse,
            link: spillageTypeCollectionResponse._links.self.href,
            required: false,
            responseKeyPath: 'spillagetypes',
            itemHrefPath: '_links.self.href',
            itemValuePath: 'name',
            isHierarchical: true,
            onSelect: changeSpillageType,
            onRemove: changeSpillageType
        };

        vm.statusOptions = [
            { value: true, title: $translate.instant('STATUS_ACTIVE') },
            { value: false, title: $translate.instant('STATUS_INACTIVE') }
        ];

        vm.create = createAction;
        vm.update = updateAction;
        vm.delete = deleteAction;

        if (parentResponse) {
            vm.spillageTypeSelect.selected = {
                href: parentResponse.getLink('self'),
                title: parentResponse.name,
                object: parentResponse
            };
            changeSpillageType();
        }

        function createAction () {
            return apiClient.create(spillageTypeCreateLink, vm.data).then(function (response) {
                if (response) {
                    $state.go('^', {}, { reload: $state.current.parent }).then(function () {
                        messenger.success('SPILLAGE_TYPE_ADDED');
                    });
                } else {
                    messenger.error('REQUEST_ERROR');
                }
            });
        }

        function updateAction () {
            return apiClient.update(vm.spillageType.getLink('edit'), vm.data).then(function (response) {
                if (response) {
                    $state.go('^', {}, { reload: $state.current.parent }).then(function (response) {
                        messenger.success('SPILLAGE_TYPE_UPDATED');
                    });
                } else {
                    messenger.error('REQUEST_ERROR');
                }
            });
        }

        function deleteAction () {
            return confirmationModal.open().result.then(function (response) {
                return apiClient.delete(vm.spillageType.getLink('delete')).then(function (response) {
                    if (response) {
                        $state.go('^', {}, { reload: $state.current.parent }).then(function () {
                            messenger.success('SPILLAGE_TYPE_DELETED');
                        });
                    } else {
                        messenger.error('REQUEST_ERROR');
                    }
                });
            });
        }

        function changeSpillageType () {
            vm.data._links.parent = undefined;
            if (vm.spillageTypeSelect.selected) {
                vm.data._links.parent = {href: vm.spillageTypeSelect.selected.href};
            }
        }
    }
})();
