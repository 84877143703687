angular.module('elogbooksServices').service('imageModal', ['$uibModal', 'apiClient', function ($uibModal) {
    return {
        open: function (filesCollectionResponse, itemResponse, setDefaultImage, isSite, config) {
            return $uibModal.open({
                templateUrl: '/modules/common/modals/galleryFileUpload/image.html',
                controller: 'ImageModalController',
                controllerAs: 'vm',
                resolve: {
                    config: function () {
                        var defaultConfig = {
                            titleMessage: 'ARE_YOU_SURE_HEADER',
                            bodyMessage: 'ARE_YOU_SURE',
                            primaryText: 'YES',
                            primaryClass: 'btn-danger',
                            secondaryText: 'NO',
                            secondaryClass: 'btn-default'
                        };

                        return angular.extend({}, defaultConfig, config);
                    },
                    filesCollectionResponse: function () {
                        return filesCollectionResponse;
                    },
                    itemResponse: function () {
                        return itemResponse;
                    },
                    setDefaultImage: function () {
                        return setDefaultImage;
                    },
                    isSite: function () {
                        return isSite;
                    }
                }
            });
        }
    };
}]);
