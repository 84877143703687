(function () {
    'use strict';

    angular
        .module('elogbooks.common.priorities')
        .controller('PrioritiesDetailsController', PrioritiesDetailsController);

    PrioritiesDetailsController.$inject = ['priorityResponse'];

    function PrioritiesDetailsController (priorityResponse) {
        var vm = this;
        vm.priority = priorityResponse;
    }
})();
