(function () {
    'use strict';

    angular
        .module('elogbooks.user.audits')
        .controller('AuditCompleteController', AuditCompleteController);

    AuditCompleteController.$inject = ['$uibModalInstance', 'answersComplete', 'apiClient'];

    function AuditCompleteController ($uibModalInstance, answersComplete, apiClient) {
        var vm = this;
        vm.close = closeModal;
        vm.confirm = confirm;
        vm.data = {'completedAt': new Date(), 'reason':null};

        // all questons not answred, so require a reason for forcing completion
        vm.answersComplete = answersComplete;

        function closeModal() {
            $uibModalInstance.dismiss('cancel');
        };

        function confirm() {

            $uibModalInstance.close(vm.data);
        };
    }
})();
