;(function() {
    'use strict';

    angular
        .module('elogbooks.common.forms')
        .controller('CommonFormsAddController', CommonFormsAddController);

    CommonFormsAddController.$inject = ['$state', 'messenger', 'crudService', 'requestDataFactory', 'formVersionInstanceCollectionResponse', 'formCollectionResponse'];

    function CommonFormsAddController($state, messenger, crudService, requestDataFactory, formVersionInstanceCollectionResponse, formCollectionResponse) {
        var vm = this;
        vm.formCollectionResponse = formCollectionResponse;
        vm.formVersionInstanceCollectionResponse = formVersionInstanceCollectionResponse;
        vm.data = requestDataFactory.createRequest({});

        vm.selectedFormModel = {
            response: vm.formCollectionResponse,
            link: vm.formCollectionResponse.getLink('self'),
            required: true,
            responseKeyPath: 'forms',
            itemHrefPath: '_links.self.href',
            itemValuePath: 'summary',
            searchKey: 'summary',
            onSelect: changeForm,
            onChange: changeForm
        };

        vm.create = createAction;

        function createAction() {
            return crudService.create(
                formVersionInstanceCollectionResponse.getLink('create'),
                vm.data,
                null,
                null,
                null,
                true
            ).then(function(response) {
                var jobsRoute = $state.current.name.substr(0, $state.current.name.indexOf('jobs.list.details') + 17);
                messenger.success('FORM_ASSOCIATION_CREATED');
                $state.go('^', {}, {reload: jobsRoute});
            });
        }

        function changeForm(formModel) {
            vm.data._links.form = undefined;

            if (formModel.selected) {
                vm.data._links.form = {href: formModel.selected.href};
            }
        }
    }
})();
