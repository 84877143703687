(function() {
    'use strict';

    angular
        .module('elogbooks.user.meter-types')
        .controller('MeterTypesInfoController', MeterTypesInfoController);

    MeterTypesInfoController.$inject = ['$state', 'apiClient', 'confirmationModal', 'messenger', 'meterTypeResponse'];
    function MeterTypesInfoController($state, apiClient, confirmationModal, messenger, meterTypeResponse) {
        var vm = this;
        vm.meterType = meterTypeResponse;
    }
})();
