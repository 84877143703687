app.config( function($translateProvider) {
    $translateProvider.translations('zh-hk', {
        METER_TYPE_INFORMATION: '儀表類別資料',

        ADD_NEW_METER_TYPE: '新增儀表類別',
        EDIT_METER_TYPE: '修改儀表類別',

        METER_TYPE_LIST: '儀表類別',
        METER_TYPES_LIST: '儀表類別',
        METER_TYPE_ADD: '新增儀表類別',
        METER_TYPE_EDIT: '修改儀表類別',

        METER_TYPE_CREATED: '儀表類別已建立',
        METER_TYPE_UPDATED: '儀表類別已更新',
        METER_TYPE_DELETED: '儀表類別已刪除',

        METER_TYPE_METER: '儀表',
        METER_TYPE_SUPPLY: '供應率',
        METER_TYPE_OCCUPANCY: '佔用率'
    });
});
