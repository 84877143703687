(function() {
    'use strict';

    angular
        .module('elogbooks.common.contacts')
        .controller('CommonContactsController', CommonContactsController);

    CommonContactsController.$inject = [
        'crudService',
        'messenger',
        'contactCollectionResponse',
        'entityResponse',
        'config',
        'requestDataFactory',
        'apiClient',
        '$state',
        'confirmationModal'
    ];

    function CommonContactsController(
        crudService,
        messenger,
        contactCollectionResponse,
        entityResponse,
        config,
        requestDataFactory,
        apiClient,
        $state,
        confirmationModal
    ) {
        var vm = this;
        vm.contactCollectionResponse = contactCollectionResponse;
        vm.config = config;
        vm.data = requestDataFactory.createRequest({});
        vm.entityResponse = entityResponse;
        vm.create = createAction;
        vm.delete = deleteAction;
        vm.email = emailAction;

        function createAction() {
            return crudService.create(
                vm.contactCollectionResponse.getLink('create'),
                vm.data,
                null,
                null,
                null,
                true
            ).then(function(response) {
                if (response) {
                    messenger.success(vm.config.entityName+'_CONTACT_CREATED');
                    vm.contactCollectionResponse.contacts.push(response);
                    vm.data = requestDataFactory.createRequest({});
                }
            });
        }

        function deleteAction(contact, $index) {
            return crudService.delete(
                contact.getLink('delete'),
                null,
                null,
                {},
                true
            ).then(function(response) {
                if (response) {
                    messenger.success(vm.config.entityName+'_CONTACT_DELETED');
                    vm.contactCollectionResponse.contacts.splice($index, 1);
                }
            });
        }

        function emailAction() {

            var data = {
                'contacts': []
            };

            // Get a list of all contacts that were checked
            angular.forEach(vm.contactCollectionResponse.contacts, function(contact) {
                if (contact.checked) {
                    data.contacts.push(contact);
                }

                contact.additionalEmail.forEach(email => {
                    if (email.checked) {
                        data.contacts.push({
                            email: email.value,
                            details: contact.details,
                            name: contact.name,
                            phone: contact.telephone
                        });
                    }
                });
            });

            // Do not allow the email to be sent with zero contacts selected
            if (data.contacts.length <= 0) {
                return;
            }

            return confirmationModal.open().result.then(function (response) {

                // quote event response is resolved two levels up
                var reloadState = (vm.config.entityName === 'QUOTE' ? '^.^' : '^');

                if (response.result) {
                    apiClient.create(vm.entityResponse.getLink('send-audit-trail'), data).then(function (isSuccess) {
                        if (isSuccess) {
                            return $state.go('.', {}, { reload: $state.get(reloadState) }).then(function () {
                                messenger.success(vm.config.entityName+'_AUDIT_TRAIL_SENT');
                            });
                        } else {
                            messenger.error('REQUEST_ERROR');
                        }
                    });
                }

                return;
            });
        }
    }
})();
