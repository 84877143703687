angular.module('elogbooksDirectives').directive('elbPatrolPlanner', function () {
    return {
        restrict: 'A',
        templateUrl: '/modules/directives/patrol-planner/planner.html',
        scope: {
            patrol: '=patrol',
            patrolCollection: '=ngModel',
            serviceProvidersCollection: '=serviceProviders',
            siteResource: '=',
            type: '@type',
            day: '=day',
            tidyTablesModel: '='
        },
        controller: ['$scope', '$state', '$stateParams', '$uibModal', 'messenger', 'lodash', 'confirmationModal', 'confirmationModalNotes', 'apiClient', '$window', 'base64', function ($scope, $state, $stateParams, $uibModal, messenger, lodash, confirmationModal, confirmationModalNotes, apiClient, $window, base64) {
            $scope.patrols = $scope.patrolCollection ? $scope.patrolCollection.patrols : [];
            $scope.tidyTables = $scope.tidyTablesModel.columns;
            $scope.range = lodash.range;
            $scope.earliestHour = 24;
            $scope.saveDisabled = true;
            $scope.planner = buildPlanner($scope.patrols);
            $scope.canEditTemplate = $scope.patrolCollection.getLink('updateTemplateEvents') !== null;
            $scope.getPatrolEventClass = getPatrolEventClass;
            $scope.findEvents = findEvents;
            $scope.moveEvent = moveEvent;
            $scope.toggleAll = toggleAll;
            $scope.calculateWidth = calculateWidth;
            $scope.calculateOffset = calculateOffset;
            $scope.calculateCompletionTime = calculateCompletionTime;
            $scope.sendEventUpdates = sendEventUpdates;
            $scope.goToInstance = goToInstance;

            var changedEvents = [];

            $scope.$watch("tidyTables", function(columns) {
                var count = 0;

                angular.forEach(columns, function(column) {
                    if(column === true) {
                        count++;
                    }
                });
                var tableClass = 'col-md-';
                var defaultCol = '5';
                var totalCols = '10';
                var leftColCount = 5;
                $scope.expandLeft = tableClass + defaultCol;
                $scope.expandRight = tableClass + defaultCol;
                var totalCount = Object.keys(columns).length;

                var half = totalCount / 2;

                if (count > half) {
                    leftColCount = totalCount + (count - half);
                } else if (count < half) {
                    leftColCount = totalCount - (half - count);
                }

                $scope.expandLeft = tableClass + leftColCount;
                $scope.expandRight = tableClass + (totalCols - leftColCount);

                setTimeout(function () {
                    $window.dispatchEvent(new Event("resize"));
                }, 0);
            }, true);

            if ($scope.serviceProvidersCollection) {
                $scope.serviceProviderSelect = {
                    response : $scope.serviceProvidersCollection,
                    link : $scope.serviceProvidersCollection ? $scope.serviceProvidersCollection.getLink('self') : null,
                    responseKeyPath: 'serviceproviders'
                };

                $scope.providers = lodash.transform($scope.serviceProvidersCollection.serviceproviders,
                    function (result, n) {
                        result.push(
                            {
                                value: n._links.self.href,
                                title: n.name
                            }
                        );
                    });
            }

            function getPatrolEventClass(patrolEvent, plannerType) {
                var classString = '';

                if (plannerType === 'draft') {
                    return 'scheduled';
                }

                if (patrolEvent){
                    switch (patrolEvent.status) {
                        case 1:
                            if (patrolEvent.plannedStartDate < moment(new Date())) {
                                classString += ' overdue';
                            } else {
                                classString += ' scheduled';
                            }

                            break;
                        case 2:
                            classString += ' in-progress';
                            break;
                        case 3:
                            classString += ' completed';
                            break;
                    }
                }

                return classString;
            }

            function buildPlanner(patrols) {
                var planner = {};

                for (var i = 0; i < patrols.length; i++) {
                    var patrolEvents = patrols[i].instances;

                    patrols[i].originalId = patrols[i].id;
                    patrols[i].id = new Date().valueOf() + Math.random();

                    var mappedEvents = {};

                    for (var j = 0; j < patrolEvents.length; j++) {
                        var patrolEvent = patrolEvents[j];

                        var startHour = moment(new Date(patrolEvent.plannedStartDate)).format("H");

                        //save earliest patrol hour for the scroll animation
                        if (parseInt(startHour) < $scope.earliestHour) {
                            $scope.earliestHour = parseInt(startHour);
                        }

                        patrolEvent.offset = calculateOffset(patrolEvent);
                        patrolEvent.hour = startHour;
                        patrolEvent.plannedStartDate = new Date(patrolEvent.plannedStartDate);

                        mappedEvents[patrolEvent.id] = patrolEvent;
                    }

                    planner[patrols[i].id] = mappedEvents;
                }

                return planner;
            }

            if ($scope.earliestHour) {
                var element = angular.element(document.querySelector('.table-hours'));
                element.animate({scrollLeft: ($scope.earliestHour) * 24}, 1000);
            }

            //calculates how long should be the event
            function calculateWidth(patrol) {
                //duration is in minutes
                if (patrol.patrolPointDuration) {
                    //return how much space (in %) event should take in a cell
                    return Math.round((patrol.patrolPointDuration / 60) * 100) + '%';
                }
            }

            //calculates the offset (minutes from full hour)
            function calculateOffset(patrolEvent) {
                if (patrolEvent.plannedStartDate) {
                    var offsetMinutes =  moment(patrolEvent.plannedStartDate, "HH:mm:ss").minute();

                    return Math.round((offsetMinutes / 60) * 100) + '%';
                }
            }

            function calculateCompletionTime(event) {
               return moment(event.plannedStartDate).add(event.patrolPointDuration, 'minutes').format('HH:mm');
            }

            function findEvents(patrolId, hour) {
                var events = [];

                angular.forEach($scope.planner[patrolId], function($event) {
                    if (parseInt($event.hour) === hour) {
                        events.push($event);
                    }
                });

                return events;
            }

            function sendEventUpdates() {
                var config = {
                    titleMessage: 'PATROL_TEMPLATE_CHANGE',
                    bodyMessage: 'PATROL_TEMPLATE_CHANGE_TEXT',
                    primaryText: 'YES',
                    primaryClass: 'btn-primary',
                    secondaryText: 'CANCEL'
                };

                confirmationModal.open(config).result.then(function (response) {
                    if (response && changedEvents.length > 0) {
                        return apiClient.create($scope.patrolCollection.getLink('updateTemplateEvents'), {events: changedEvents}).then(function (response) {
                            if (response) {
                                $state.go('.', {}, {reload: $state.get('^') }).then(function () {
                                    messenger.success('TEMPLATE_UPDATED');
                                });
                            } else {
                                messenger.error('REQUEST_ERROR');
                            }
                        });
                    }
                });
            }

            function moveEvent(patrolId, eventId, hourTo, minutesTo) {
                var patrolEvent = $scope.planner[patrolId][eventId];
                patrolEvent.patrolId = patrolId;

                var updateTime = moment(hourTo+':'+minutesTo, 'HH:mm');
                patrolEvent.plannedStartDate = new Date(moment(patrolEvent.plannedStartDate).set({h:updateTime.hour(), m:updateTime.minute()}));

                if (lodash.find(changedEvents, {'id': patrolEvent.id})) {
                    var index = lodash.findIndex(changedEvents, {'id': patrolEvent.id});
                    changedEvents.splice(index, 1, patrolEvent);
                } else {
                    changedEvents.push(patrolEvent);
                }

                if (changedEvents.length > 0) {
                    $scope.saveDisabled = false;
                }
            }

            function toggleAll() {
                if (this.allSelected) {
                    lodash.each($scope.tasks, function (task) {
                        task.checked = true;
                    });
                } else {
                    lodash.each($scope.tasks, function (task) {
                        task.checked = false;
                    });
                }
            }

            function goToInstance(patrolId, instanceId) {
                // Not worth the time to try and stuff a patrol instance link into the custom planner response, so unfortunately, it's hardcoded.
                $state.go(
                    'dashboard.user.patrols.list.details.info',
                    { resource: base64.encode('/patrols/'+patrolId+'/instances/'+instanceId) }
                );
            }
        }]
    };
});
