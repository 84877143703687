/**
 * Image Tab
 */
angular.module('elogbooksDirectives').directive('elbPanelTitleTab', ['$http', 'apiClient', '$state', '$rootScope', 'iconService', 'elbSettings', function ($http, apiClient, $state, $rootScope, iconService, elbSettings) {
    return {
        restrict: 'AE',
        replace: true,
        scope: {
            tabTitle: '@',
            tabIcon: '@',
            stateName: '@',
            uiSref: '@',
            otherSrefs: '<',
            direct: '@',
            moduleEnabled: '@'
        },
        templateUrl: '/modules/directives/layout/panel-title-tab/panel-title-tab.html',
        link: function($scope, $element, $attrs) {
            $scope.visible = $scope.moduleEnabled && elbSettings.getSetting($scope.moduleEnabled) ? elbSettings.getSetting($scope.moduleEnabled).value : true;
            // Associate font awesome CSS with the tab title if no icon provided
            if (typeof $attrs.tabIcon === 'undefined') {
                $attrs.tabIcon = iconService.getMap($attrs.tabTitle);
            }

            $scope.isActive = isActive;

            // Monitor state change so correct tab can be set active
            $rootScope.$on('$stateChangeSuccess', function() {
                $scope.isActive();
            });

            function isActive () {

                var srefComparison = $attrs.uiSref.indexOf('(') !== -1 ? $attrs.uiSref.substring(0, $attrs.uiSref.indexOf('(')) : $attrs.uiSref;

                if ($scope.otherSrefs) {
                    return $scope.otherSrefs.filter(function (value) {
                        return $scope.$root.currentState.indexOf(value) > -1;
                    })[0];
                }

                return $attrs.direct ?
                    $scope.$root.currentState === srefComparison :
                    $scope.$root.currentState.indexOf(srefComparison) > -1;
            }
        }
    }
}]);
