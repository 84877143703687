;(function() {
    'use strict';

    angular
        .module('elogbooks.common.files')
        .controller('CommonFilesJobFilesListController', CommonFilesJobFilesListController);

    CommonFilesJobFilesListController.$inject = ['fileCollectionResponse'];

    function CommonFilesJobFilesListController(fileCollectionResponse) {
        var vm = this;
        vm.fileCollectionResponse = fileCollectionResponse;
    }
})();
