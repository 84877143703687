angular.module('elogbooksServices').service('tidyTablesService', function ($window, lodash) {
    this.tidyTables = [];

    this.getTidyTable = function(name) {
        if (!$window.localStorage.getItem('tidyTables')) {
            return false;
        }
        var tidyTableModel = false;
        this.tidyTables = angular.fromJson($window.localStorage.getItem('tidyTables'));
        this.tidyTables.forEach(function(item) {
            if (item.name === name) {
                this.tidyTableModel = tidyTableModel = item;
            }
        });

        return tidyTableModel;
    };

    this.checkTidyTableColumn = function(name, column) {
        if (!$window.localStorage.getItem('tidyTables')) {
            return false;
        }
       
        var tidyTableModel = false;
        
        this.tidyTables.forEach(function(item) {
            if (item.name === name) {
                this.tidyTableModel = tidyTableModel = item;
            }
        });
        
        if (tidyTableModel) {
            return tidyTableModel.columns[column];
        }

        return false;
    };

    this.countSelectedColumns = function(name) {
        var columnCount = 0;
        if (!$window.localStorage.getItem('tidyTables')) {
            return columnCount;
        }
       
        var tidyTableModel = this.tidyTables.find(function(item) { return item.name === name});
        if(tidyTableModel) {
            columnCount = lodash.size(lodash.pickBy(tidyTableModel.columns, function(value, key) {
                return value;
            }));
        }
        return columnCount;
    };

    this.setTidyTable = function(tidyTablesModel) {
        this.match = false;

        if (this.tidyTables.length === 0) {
            this.tidyTables = [tidyTablesModel];
        } else {
            var match = false;
            
            this.tidyTables.forEach(function(item) {
                if (item.name === tidyTablesModel.name) {
                    item = tidyTablesModel;
                    match = true;
                }
            });

            if (match === false) {
                this.tidyTables.push(tidyTablesModel);
            }
        }

        $window.localStorage.setItem('tidyTables', angular.toJson(this.tidyTables));
    };
});
