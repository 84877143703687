/**
 * Panel title
 */
angular.module('elogbooksDirectives').directive('panelTitle', ['$http', 'apiClient', '$state', 'iconService', 'lodash', function ($http, apiClient, $state, iconService, lodash) {
    return {
        restrict: 'AE',
        replace: true,
        transclude: true,
        templateUrl: '/modules/directives/layout/panel-title/panel-title.html',
        link: function ($scope, $element, $attrs, panelCtrl, transclude) {
            $scope.editWithoutResource = false;
            // Are we inside panel-entity?
            if (typeof $scope.panel !== 'undefined') {
                switch ($scope.panel.type) {
                    case 'view':
                        // check for edit in the entity or response
                        if (!$scope.panel.editHide && (lodash.has($scope.panel.entity, '_links.edit.href') || lodash.has($scope.panel.responseEntity, '_links.edit.href'))) {
                            // Able to edit from the view
                            if (!$scope.panel.raiseEvents) {
                                $scope.editButtonSref = lodash.has($scope.panel.entity, '_links.edit.href') ? $scope.panel.entity._links.edit.href.encode() : $scope.panel.responseEntity._links.edit.href.encode();
                            } else {
                                $scope.raiseEvents = true;
                            }
                        }
                        break;
                    case 'list':
                        // Lists check the collection response for _links.create
                        if (!$scope.panel.addHide &&
                            (
                                lodash.has($scope.panel.responseEntity, '_links.create')
                                || lodash.get($scope.panel.responseEntity, 'canCreate')
                            )
                        ) {
                            $scope.addButton = '.add';
                        }
                        break;
                }
                $attrs.titleIcon = $scope.panel.icon;

                $scope.editWithoutResource = $scope.panel.editWithoutResource;
            } else {
                $scope.editButtonSref = null;
            }

            $scope.$watch('panel.responseEntity', function(newVal, oldVal){
                if ($scope.panel && !$scope.panel.addHide &&
                    (
                        lodash.has(newVal, '_links.create')
                        || lodash.get(newVal, 'canCreate')
                    )
                ) {
                    $scope.addButton = '.add';
                }
            })

            if (typeof $attrs.titleIcon !== 'undefined') {
                $scope.titleIcon = 'fa fa-' + iconService.getMap($attrs.titleIcon);
            }

            $scope.resourceType = $attrs.resourceType || 'resource';
            $scope.entityName = $attrs.entityName || null;
            $scope.additionalButtons = $scope.$eval($attrs.additionalButtons);

            $scope.disableButton = $scope.$eval($attrs.disableButton);
            $scope.disableTooltip = $scope.$eval($attrs.disableTooltip);
            $scope.tooltipMessage = $attrs.tooltipMessage;

            $scope.addLink = $attrs.addLink || 'add';
            $scope.editLink = $attrs.editLink || 'edit';

            $scope.hideTitle = $scope.$eval($attrs.hideTitle) || false;
            $scope.hideBorder = $attrs.hideBorder === 'hide' ? true : false;
            $scope.titleTemplate = $attrs.titleTemplate || null;

            $scope.itemCount = $attrs.itemCount || null;
            $scope.ignoreCount = $scope.$eval($attrs.ignoreCount) || false;

            $scope.highlightedSubtitle = $scope.$eval($attrs.highlightedSubtitle) || null;

            $attrs.$observe('itemCount', function(value){
                $scope.itemCount = value;
            });

            $attrs.$observe('addLink', function(value){
                $scope.addLink = value;
            });

            transclude($scope, function (clone) {
                $scope.hasTitle = false;
                if (clone.length) {
                    $scope.hasTitle = true;
                }
            });
        }
    };
}]);
