(function () {
    'use strict';

    angular
        .module('elogbooksDirectives')
        .directive('elbValidationErrors', ElbValidationErrors);

    ElbValidationErrors.$inject = ['$translate'];

    function ElbValidationErrors ($translate) {
        return {
            scope: {
                property: '=',
                validation: '='
            },
            restrict: 'AE',
            templateUrl: '/modules/directives/elb-validation-errors/validation-errors.html',
            controller: function($scope) {

                $scope.showErrors = false;

                $scope.$watch('property.$error', function() {
                    updateErrorMessage()
                }, true);

                $scope.$watch('validation', function() {
                    updateErrorMessage()
                }, true);

                function updateErrorMessage() {
                    if($scope.property && $scope.property.$dirty) {
                        var errorMessage = false;
                        angular.forEach($scope.property.$error, function(error, key) {
                            errorMessage = (errorMessage.length)
                                ? errorMessage.concat(', ' + $translate.instant(key.toUpperCase(), {value: ($scope.validation && $scope.validation[key]) ? $scope.validation[key] : null}))
                                : $translate.instant(key.toUpperCase(), {value: ($scope.validation && $scope.validation[key]) ? $scope.validation[key] : null});
                        })
                        if (errorMessage) {
                            $scope.showErrors = true;
                            $scope.errorMessage = errorMessage;
                        } else {
                            $scope.showErrors = false;
                        }
                    }
                }
            }
        };
    }
})();
