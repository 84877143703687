angular.module('elogbooksDirectives').directive('addressForm', ['$http', 'countries', '$timeout', function ($http, countries) {
    return {
        // restrict to attributes only
        restrict: 'A',
        require: ['ngModel'],
        scope: {
            ngModel: '=',
            form: '=form',
            required: '=required',
            ngDisabled: '=?',
        },
        templateUrl: '/modules/directives/address-form/address-form.html',
        link: function ($scope, element, attributes) {
            countries.getAll().then(function (list) {
                $scope.countries = list;
            });

            if (attributes.back) {
                $scope.displayBackButton = true;
            }
        }
    }
}]);
