app.config(function ($translateProvider) {
    $translateProvider.translations('nl', {
        TYPE: 'Type',
        MIN_VALUE: 'Min waarde',
        MAX_VALUE: 'Max waarde',
        MIN_LENGTH: 'Min Lengte',
        MAX_LENGTH: 'Max Lengte',
        CHOICES: 'Keuzes',
        BE_APPROVER: 'Moet een fiatteur zijn',
        REQUIRED: 'Benodigd',
        NUMBER_LEGEND: 'Number Legend',

        //Statuses
        DRAFT: 'Concept',
        TRAINING: 'Training',
        ACTIVE: 'Active',
        INACTIVE: 'Inactive',
        YES: 'Ja',
        NO: 'Nee',

        // general
        IMPORTS_ID: 'Id',
        IMPORTS_FULLNAME: 'Full name',
        IMPORTS_NAME: 'Name',
        IMPORTS_ADDRESS1: 'Address line 1',
        IMPORTS_ADDRESS2: 'Address line 2',
        IMPORTS_ADDRESS3: 'Address line 3',
        IMPORTS_TOWN: 'Address town',
        IMPORTS_REGION: 'Address region',
        IMPORTS_POSTCODE: 'Address postcode',
        IMPORTS_COUNTRY: 'Address country',
        IMPORTS_STATUS: 'Current status',
        IMPORTS_TELEPHONE: 'Telephone number',
        IMPORTS_WEBSITE: 'Website',
        IMPORTS_CITY: 'City',

        IMPORTS_SERVICE_PROVIDERS: 'Service provider names',
        IMPORTS_SERVICE_PROVIDER_IDS: 'Service provider Ids',
        IMPORTS_SERVICE_PROVIDER_ID: 'Service provider Id',
        IMPORTS_SERVICE_ROUTINE_IDS: 'Service routine Ids',
        IMPORTS_SERVICE_ROUTINE_ID: 'Service routine Id',
        IMPORTS_STATUTORY_ID: 'Statutory Id',
        IMPORTS_LOCATION: 'Location Name, ID Or New Location Name',
        IMPORTS_ROLES: 'Roles',
        IMPORTS_ROLE_IDS: 'Role Ids',
        IMPORTS_DESCRIPTION: 'Description',
        IMPORTS_SITE_IDS: 'Site ids',
        IMPORTS_SITE_ID: 'Site id',
        IMPORTS_JOB_GROUP_ID: 'Job group id',
        IMPORTS_ACTIVE: 'Active',
        IMPORTS_REFERENCE: 'Reference',
        IMPORTS_EXTERNAL_REFERENCE: 'External Reference',
        IMPORTS_TRAINED_AT: 'Trained at',
        IMPORTS_ACTIVE_AT: 'Active at',
        IMPORTS_INACTIVE_AT: 'Inactive at',
        IMPORTS_GROUP_ID: 'Group id',

        //User legend translations
        IMPORTS_NEW_CUSTOMER_ACCESS: 'New customer access',
        IMPORTS_USERNAME: 'Username',
        IMPORTS_EMAIL: 'Valid email address',
        IMPORTS_POSITION: 'Position in the company',
        IMPORTS_NOTES: 'Notes',

        //assets
        IMPORTS_MAKE: 'Make',
        IMPORTS_QUANTITY: 'Quantity',
        IMPORTS_MODEL: 'Model',
        IMPORTS_BARCODE: 'Barcode',
        IMPORTS_END_OF_LIFE: 'End of life',
        IMPORTS_CHARGEABLE: 'Chargable',
        IMPORTS_BUSINESS_CONTINUITY_RISK: 'Business continuity risk',
        IMPORTS_REPLACEMENT_COST_LABOUR: 'Replacement cost labour',
        IMPORTS_REPLACEMENT_COST_MATERIALS: 'Replacement cost materials',
        IMPORTS_ASSET_TYPE_ID: 'Asset type id',
        IMPORTS_SERIAL_NUMBER: 'Serial number',
        IMPORTS_ASSET_NFC_ID: 'NFC tag id',

        //service provider
        IMPORTS_PRIMARY_JOB_OPERATIVE_ID: 'Primary Job Operative Id',
        IMPORTS_PRIMARY_QUOTE_OPERATIVE_ID: 'Primary Quote Operative Id',
        IMPORTS_PRIMARY_OPERATIVE_ID: 'Primary Operative Id',
        IMPORTS_PRIMARY_ESCALATION_OPERATIVE_ID: 'Primary Escalation Operative Id',
        IMPORTS_OPERATIVE_IDS: 'Operative Ids',

        //site
        IMPORTS_AREA: 'Area',
        IMPORTS_AREA_UNITS: 'Area units',
        IMPORTS_SECTOR_ID: 'Sector id',
        IMPORTS_REGION_ID: 'Region id',
        IMPORTS_JOB_APPROVAL_REQUIRED: 'Job approval required',
        IMPORTS_JOB_APPROVERS_ID: 'Job approvers Id',
        IMPORTS_JOB_EXTENSIONS_APPROVAL_REQUIRED: 'Job extensions approval required',
        IMPORTS_JOB_EXTENSIONS_APPROVERS_ID: 'Job extensions approvers Id',
        IMPORTS_QUOTE_APPROVERS_ID: 'Quote approvers Id',
        IMPORTS_PPM_APPROVAL_REQUIRED: 'PPM approval required',
        IMPORTS_PPM_APPROVERS_ID: 'PPM Approvers Id',
        IMPORTS_PPM_EXTENSIONS_APPROVAL_REQUIRED: 'PPM extensions approval required',
        IMPORTS_PPM_EXTENSIONS_APPROVERS_ID: 'PPM extensions Approvers Id',
        IMPORTS_CONTACT_APPROVAL_REQUIRED: 'Contact approval required',
        IMPORTS_CONTACT_APPROVERS_ID: 'Contact approvers Id',
        IMPORTS_SURVEY_APPROVERS_ID: 'Survey approvers Id',
        IMPORTS_SURVEY_APPROVAL_REQUIRED: 'Survey approval required',
        IMPORTS_SURVEY_APPROVAL_APPROVERS_ID: 'Survey approvers Id',
        IMPORTS_SERVICE_PROVIDERS_ID: 'Service providers Id',

        //site tenant
        IMPORTS_COMPANY: 'Company',

        //meter
        IMPORTS_SUPPLY_TYPE_ID: 'Supply type id',
        IMPORTS_REFERENCE_TYPE: 'Reference type',
        IMPORTS_ACCOUNT_NUMBER: 'Account number',
        IMPORTS_MULTIPLIER: 'Multiplier',
        IMPORTS_MAX_VALUE: 'Max value',
        IMPORTS_FREQUENCY: 'Frequency',
        IMPORTS_INTERVAL: 'Interval',
        IMPORTS_IS_DUAL_RATED: 'Is dual rated',
        IMPORTS_IS_AUTOMATICALLY_READ: 'Automcatically read',
        IMPORTS_TYPE_ID: 'Type id',
        IMPORTS_MANUFACTURE_ID: 'Manufacture id',
        IMPORTS_UTILITY_PROVIDER_ID: 'Utility provider id',
        IMPORTS_OCCUPANCY_ID: 'Occupancy id',
        IMPORTS_READ_AT: 'Read at',
        IMPORTS_LAST_READING: 'Last reading',

        //task
        IMPORTS_TYPE: 'Type',
        IMPORTS_SUMMARY: 'Summary',
        IMPORTS_OPERATIVE_ID: 'Operative Id',
        IMPORTS_ASSET_ID: 'Asset id'

    });
});
