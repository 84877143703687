(function () {
    'use strict';

    angular
        .module('elogbooksControllers')
        .controller('ServiceProviderAddModalController', ServiceProviderAddModalController);

    ServiceProviderAddModalController.$inject = ['$uibModalInstance', '$state', 'apiClient', 'serviceProviderCollectionResponse', 'operativeResponse', 'messenger', 'config'];

    function ServiceProviderAddModalController($uibModalInstance, $state, apiClient, serviceProviderCollectionResponse, operativeResponse, messenger, config) {
        var vm = this;
        vm.confirm = confirm;
        vm.close = close;
        vm.submit = submit;

        vm.data = {
            _links: {},
            permissions: [],
            useDefaultManageOperativePermissions: config.useDefaultManageOperativePermissions
        };

        vm.serviceProviderSelect = {
            response: serviceProviderCollectionResponse,
            responseKeyPath: 'serviceproviders',
            required: true
        };

        function submit() {

            if (vm.serviceProviderSelect.selected) {
                vm.data._links = { serviceProvider : { href: vm.serviceProviderSelect.selected.object._links.self.href } };
            } else {
                vm.data._links = null;
            }

            if (operativeResponse.getLink('operatives')) {
                apiClient.create(operativeResponse.getLink('operatives'), vm.data).then(function (response) {
                    if (response) {
                        confirm();
                        $state.go('.', {}, { reload: true }).then(function() {
                            messenger.success('SERVICE_PROVIDERS_UPDATED');
                        });
                    }
                });
            }
        }
        
        function close() {
            $uibModalInstance.dismiss();
        }

        function confirm() {
            $uibModalInstance.close();
        }
    }
})();
