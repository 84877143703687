/**
 * Panel buttons
 */
angular.module('elogbooksDirectives').directive('panelButtons', ['$http', 'apiClient', '$state', 'lodash', function($http, apiClient, $state, lodash) {
    return {
        restrict: 'AE',
        replace: true,
        templateUrl: '/modules/directives/layout/panel-buttons/panel-buttons.html',
        link: function($scope, $element, $attrs) {

            var panel = lodash.has($scope, 'panel') ? $scope.panel : lodash.get($scope.$parent, 'panel');

            $scope.additionalButtons = $scope.$eval($attrs.additionalButtons);

            if (lodash.has($attrs, 'entity') && !$scope.responseEntity) {
                $scope.responseEntity = $scope.$eval($attrs.entity);
            }
            // The primary button is "CREATE" when no id on entity, "UPDATE" when there is an id, or custom text
            // through data-submit-button-text on the panel-entity
            $scope.submitButtonText = lodash.get(panel, 'submitButtonText') || ((lodash.has($scope.responseEntity, 'id') && $scope.responseEntity.id !== null) ? 'UPDATE' : 'CREATE');

            if (!lodash.has($scope.panel, 'entity')) {
                $scope.entity = $scope.$eval($attrs.entity);
            }

            if (lodash.has($scope.panel, 'responseEntity')) {
                $scope.deleteButtonLink = lodash.has($scope.panel.responseEntity, '_links.delete');
            } else {
                $scope.responseEntity = $scope.$eval($attrs.responseEntity);
                $scope.deleteButtonLink = lodash.has($attrs.responseEntity, '_links.delete');
            }

            // If the panels cancel property is a function, use this instead of state change
            if (lodash.has($scope.panel, 'cancel') && typeof $scope.panel.cancel === 'function') {
                $scope.cancelref = 'function';
                $scope.cancelfunc = $scope.panel.cancel;
            } else {
                // Pass "data-cancel-hide" as an attribute to hide the cancel button
                $scope.cancelref = ($attrs.hasOwnProperty('cancelHide')) ? false : $attrs.cancel || '^';
            }

            // Pass "data-delete-hide" as an attribute to hide the delete button
            if (lodash.has($scope.panel, 'deleteHide')) {
                $scope.deleteHide = $scope.panel.deleteHide;
            }

            // Pass "data-delete-disabled" as an attribute to disable the delete button
            if (lodash.has($scope.panel, 'deleteDisabled')) {
                $scope.deleteDisabled = $scope.panel.deleteDisabled;
            }

            // Pass "data-submit-disabled" as an attribute to disable the submit button
            if (lodash.has($scope.panel, 'submitDisabled')) {
                $scope.submitDisabled = $scope.panel.submitDisabled;
            }

            // Pass "data-submit-hide" as an attribute to hide the submit button
            if (lodash.has($scope.panel, 'submitHide')) {
                $scope.submitHide = $scope.panel.submitHide;
            }

            $attrs.$observe('submitDisabled', function(value){
                $scope.submitDisabled = $scope.$eval(value);
            });
        }
    };
}]);
