(function() {
    'use strict';

    angular
        .module('elogbooks.common.sites')
        .controller('LocationTabsController', LocationTabsController);

    LocationTabsController.$inject = ['locationResponse', 'config'];

    function LocationTabsController(locationResponse, config) {
        var vm = this;

        vm.location = locationResponse;
        vm.config = config;
    }
})();
