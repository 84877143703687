(function () {
    'use strict';

    angular
        .module('elogbooks.common.quote-requests')
        .controller('CommonQuoteRequestDetailsEventFeedController', CommonQuoteRequestDetailsEventFeedController);

    CommonQuoteRequestDetailsEventFeedController.$inject = ['eventCollectionResponse', 'config'];

    function CommonQuoteRequestDetailsEventFeedController (eventCollectionResponse, config) {
        var vm = this;
            vm.feed = [];
            vm.config = config;
            vm.eventCollectionResponse = eventCollectionResponse;
    }
})();
