/**
 * Quotes list controller
 */
 (function() {
    'use strict';

    angular
        .module('elogbooks.admin.satisfactionsurveylines')
        .controller('SatisfactionSurveyLinesController', SatisfactionSurveyLinesController);

    SatisfactionSurveyLinesController.$inject = ['satisfactionSurveyLineCollectionResponse'];

    function SatisfactionSurveyLinesController(satisfactionSurveyLineCollectionResponse) {

        var vm = this;

        vm.satisfactionSurveyLines = satisfactionSurveyLineCollectionResponse.getData().satisfactionsurveylines;

        vm.listVisible = true;
    };

 })();
