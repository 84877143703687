(function () {
    'use strict';

    angular
        .module('elogbooks.user.reports')
        .controller('UserReportsPpmMissedCompletionController', UserReportsPpmMissedCompletionController);

    UserReportsPpmMissedCompletionController.$inject = ['jobParams', 'jobsCollection', 'operativeResponse', '$translate', 'modalConfig'];

    function UserReportsPpmMissedCompletionController(jobParams, jobsCollection, operativeResponse, $translate, modalConfig) {
        var vm = this;

        vm.jobsCollection = jobsCollection;
        vm.jobs = jobsCollection.jobs;
        vm.op = operativeResponse;
        vm.exportParams = jobParams;
        vm.modalConfig = modalConfig;

        vm.listTitle = vm.modalConfig.titlePrefix + " - " + (
            (jobParams.overdueCompletionHistoric) ?
                $translate.instant('PPM_MISSED_COMPLETION') :
                $translate.instant('PPM_COMPLETION')
        );
    }
})();
