app.config(function ($translateProvider) {
    $translateProvider.translations("en-gb", {
        ADD_NEW_JOB_REQUISITES: "Add a new Job Requisite",
        ADD_PARTNERSHIP: "Add Partnership",
        ALLOW_NOT_APPLICABLE: "Allow N/A",
        ANSWERED_NA: "answered 'N/A'",
        ANSWERED_YES: "answered 'Yes'",
        ATTACHED_FILES: "attached file(s)",
        DAILOG_PARTNERSHIP_EDIT: "Edit Partnerships",
        DELETE_PARTNERSHIP: "Delete Partnership",
        DELETE_SELECTED: "Delete Selected",
        DISALLOW_NOT_APPLICABLE: "Disallow N/A",
        EDIT_JOB_REQUISITE: "Edit Job Requisite",
        HASNOTAPPLICABLEOPTION: "Allow N/A Option",
        HELPMESSAGE: "Help Text",
        JOB_REQUISITE: "Job Requisite",
        JOB_REQUISITE_ADD: "Add a new Job Requisite",
        JOB_REQUISITE_CREATED: "Job Requisite Created",
        JOB_REQUISITE_DELETED: "Job Requisite Deleted",
        JOB_REQUISITE_EDIT: "Edit Job Requisite",
        JOB_REQUISITE_LIST: "Job Requisites",
        JOB_REQUISITE_PARTNERSHIP_ADDED: "Job Requisite Partnership Added",
        JOB_REQUISITE_PARTNERSHIP_EDIT: "Edit Job Requisite Partnerships",
        JOB_REQUISITE_PARTNERSHIP_LIST: "Partnerships",
        JOB_REQUISITE_SERVICE_PROVIDER_LIST: "Service Providers",
        JOB_REQUISITE_SERVICEPROVIDER_ADDED:
            "Job Requisite Service Provider Added",
        JOB_REQUISITE_SERVICEPROVIDER_EDIT:
            "Edit Job Requisite Service Providers",
        JOB_REQUISITE_SITE_ADDED: "Job Requisite Site Added",
        JOB_REQUISITE_SITE_EDIT: "Edit Job Requisite Sites",
        JOB_REQUISITE_SITE_LIST: "Sites",
        JOB_REQUISITE_UPDATED: "Job Requisite Updated",
        JOB_REQUISITE_PENDING_APPROVAL: "Job Requisites Pending Approval",
        JOB_REQUISITEUPDATED: "Job Requisite Updated",
        NO_ATTACHMENT: "No Attachment",
        PARTNERSHIPS_DELETED_FROM_JOB_REQUISITE:
            "Job Requisite Partnerships Deleted",
        REQUEST_ATTACHMENT: "Request Attachment",
        REQUESTATTACHMENT: "Request Attachment",
        SERVICEPROVIDERS_DELETED_FROM_JOB_REQUISITE:
            "Job Requisite Service Providers Deleted",
        SITES_DELETED_FROM_JOB_REQUISITE: "Job Requisite Sites Deleted",
        SP_APPROVED: "SP Approved",
        APPROVER_APPROVED: "Approver Approved",
        JOBREQUISITE: "Job Requisite",
        JOB_REQUISITE_ANSWERED:
            "<strong>{{ jobRequisiteAnswer._links.user.title }}</strong> {{ jobRequisiteAnswer.answer | translate }} to {{ jobRequisiteAnswer._links.jobrequisite.title }}",
        JOB_REQUISITE_YET_TO_ANSWER:
            "<strong>{{ jobRequisiteAnswer._links.user.title }}</strong> is yet to answer {{ jobRequisiteAnswer._links.jobrequisite.title }}",
        JOB_REQUISITE_ANSWER_APPROVE: "Approve Job Requisite Answer",
        JOB_REQUISITE_ANSWER_APPROVE_SUBMIT: "Approve",
        JOB_REQUISITE_ANSWER_APPROVE_SUCCESS: "Job Requisite Answer Approved",
        JOB_REQUISITE_ANSWER_REJECT: "Reject Job Requisite Answer",
        JOB_REQUISITE_ANSWER_REJECT_SUBMIT: "Reject",
        JOB_REQUISITE_ANSWER_REJECT_SUCCESS: "Job Requisite Answer Rejected",
        JOB_REQUISITE_ESCALATE: "Escalate Job Requisite",
        JOB_REQUISITE_ANSWER_ESCALATE: "Escalate Job Requisite Answer",
        JOB_REQUISITE_ANSWER_ESCALATE_SUBMIT: "Escalate",
        JOB_REQUISITE_ANSWER_ESCALATE_SUCCESS:
            "Job Requisite Has Been Escalated",
        REQUISITES: "Requisites",
        OPT_IN: "Opt-In",
        OPT_OUT: "Opt-Out",
        APPROVAL_STRATEGY: "Approval Strategy",
        NO_APPROVAL_REQUIRED: "No Approval Required",
        APPROVER_APPROVAL_REQUIRED: "Approver Approval Required",
        PRIMARY_AND_APPROVER_APPROVAL_REQUIRED:
            "Primary Operative and Approver Approval Required",
        FORM: "Form",
        FORM_SUMMARY: "Form Summary",
        FORM_STATUS: "Form Status",
        BACK_TO_JOB_REQUISITES: "Back to Job Requisites",
        VIEW_FORM: "View Form",
        VIEW_POST_FORM: "View Post Form",
        VIEW_PRE_FORM: "View Pre Form",
        HAS_JOB_REQUISITE: "Has Job Requisite",
        PRIMARY_OPERATIVE: "Primary Operative",
        OPERATIVE_TYPE: "Operative Type",
        HEALTH_AND_SAFETY: "Health And Safety",
        ALLOW_ESCALATION: "Allow Escalation To Approver",
        QUOTED_WORKS: "Quoted works",
        PLANNED_JOB: "Planned Job",
        REACTIVE_JOB: "Reactive Job",
        PRE_REQUISITE_FORM: "Pre Requisite Form",
        POST_REQUISITE_FORM: "Post Requisite Form",
        NOT_APPLICABLE_TO_POST_REQUISITE_FORM:
            "Not applicable to post requisite form",
        PRE_AND_POST_REQUISITE_FORMS_CANNOT_BE_THE_SAME:
            "Pre and post requisite forms cannot be the same",
        ACTIVATE_REQUISITE_INFO:
            "Please contact the admin team on info@elogs.co.uk to assign this requisite.",
        JOB_REQUISITE_CREATED_BY: '<b>{{ ::name }}</b> created the Job Requisite',
        JOB_REQUISITE_UPDATED_BY: '<b>{{ ::name }}</b> updated the Job Requisite',
    });
});
