(function () {
    'use strict';

    angular
        .module('elogbooks.user.patrols')
        .controller('PatrolOccurrenceAddEditController', PatrolOccurrenceAddEditController);

    PatrolOccurrenceAddEditController.$inject = [
        'lodash', '$state', 'apiClient', 'messenger', 'requestDataFactory', 'patrolOccurrenceResponse',
        'patrolOccurrenceCollection', 'patrolScheduleResponse', 'partnershipsCollection',
        'confirmationModal', 'daysOfWeek'];

    function PatrolOccurrenceAddEditController (
       lodash, $state, apiClient, messenger, requestDataFactory, patrolOccurrenceResponse,
       patrolOccurrenceCollection, patrolScheduleResponse, partnershipsCollection,
       confirmationModal, daysOfWeek)
    {
        var vm = this;
        vm.occurence = patrolOccurrenceResponse;
        vm.serviceProviders = partnershipsCollection.partnerships;
        vm.daysOfWeek = daysOfWeek.getAllForSelect();
        vm.data = requestDataFactory.createRequest(getDefaults());
        vm.create = createAction;
        vm.update = updateAction;
        vm.delete = deleteAction;

        vm.spSelect = {
            response : partnershipsCollection,
            link : partnershipsCollection.getLink('self'),
            responseKeyPath: 'partnerships',
            itemHrefPath: '_links.serviceprovider.href',
            itemValuePath: '_links.serviceprovider.title',
            required: true,
            selected: !lodash.isEmpty(patrolOccurrenceResponse) ? {
                href: patrolOccurrenceResponse.getLink('serviceprovider'),
                title: patrolOccurrenceResponse._links.serviceprovider.name,
                object: patrolOccurrenceResponse._links.serviceprovider
            } : null
        };

        function getDefaults() {
            var startDate = new Date(0, 0, 0, 0, 0, 0);
            if (vm.occurence.startTime) {
                var startTimeHour = vm.occurence.startTime.substring(0, vm.occurence.startTime.indexOf(':'));
                var startTimeMin = vm.occurence.startTime.substring(vm.occurence.startTime.indexOf(':')+1);

                startDate.setHours(startTimeHour);
                startDate.setMinutes(startTimeMin);
            }


            return {
                _links: {
                    schedule: vm.occurence._links ? vm.occurence._links.schedule : patrolScheduleResponse._links.self,
                    serviceprovider: vm.occurence._links ? vm.occurence._links.serviceprovider : null
                },
                id: vm.occurence.id || null,
                dayOfWeek: angular.isNumber(vm.occurence.dayOfWeek) ? parseInt(vm.occurence.dayOfWeek) : null,
                startTime: startDate,
                active: vm.occurence.active === true || vm.occurence.active === false ? vm.occurence.active : null
            };
        }

        function createAction() {
            // copy original data json, so we can re-add tz offset as this field will always be UTC time.
            var data = angular.copy(vm.data);
            data.startTime = new Date(data.startTime.getTime() - (data.startTime.getTimezoneOffset() * 60000));

            if (vm.spSelect.selected) {
                data._links.serviceprovider = vm.spSelect.selected.object.getLink('serviceprovider');
            }

            apiClient.create(patrolOccurrenceCollection.getLink('create'), data).then(function (response) {
                if (response) {
                    $state.go('dashboard.user.patrols.manage-patrols.details.schedules.list.details', { patrolOccurrenceResource: response.getLink('self').encode() }, { reload: true }).then(function () {
                        messenger.success('PATROL_OCCURRENCE_CREATED');
                    });
                } else {
                    messenger.error('REQUEST_ERROR');
                }
            });
        }

        function updateAction() {
            // copy original data json, so we can re-add tz offset as this field will always be UTC time.
            var data = angular.copy(vm.data);
            data.startTime = new Date(data.startTime.getTime() - (data.startTime.getTimezoneOffset() * 60000));

            if (vm.spSelect.selected.object.active) {
                data._links.serviceprovider = vm.spSelect.selected.object.getLink('serviceprovider');
            }

            apiClient.update(vm.occurence._links.self.href, data).then(function (response) {
                if (response) {
                    $state.go('^', {}, { reload: true }).then(function () {
                        messenger.success('PATROL_OCCURRENCE_UPDATED');
                    });
                } else {
                    messenger.error('REQUEST_ERROR');
                }
            });
        }

        function deleteAction() {
            return confirmationModal.open().result.then(
                function () {
                    return apiClient.delete(vm.occurence.getLink('delete')).then(function (response) {
                        if (response) {
                            $state.go('dashboard.user.patrols.manage-patrols.details.schedules.list.details', {}, { reload: true }).then(function () {
                                messenger.success('PATROL_OCCURRENCE_DELETED');
                            });
                        } else {
                            messenger.error('REQUEST_ERROR');
                        }
                    });
                },
                function() {
                    return;
                }
            );
        };
    }
})();
