/**
 * QuoteCall form
 */
angular.module('elogbooksDirectives').directive('quoteDetails', ['$http', function ($http) {
    return {
        restrict: 'A',
        require: ['ngModel'],
        scope: {
            ngModel: '=',
            quote: '=quoteModel',
            quoteRequest: '=quoteRequestModel',
            form: '=',
        },
        templateUrl: '/modules/directives/quotes/details/quote.html',
        controller: function($scope, $element, $attrs) {

        }
    };
}]);
