/**
 * Panel form row for panel entities
 * These components interact with panel-entity model - DO NOT pass a model, use panelFormRow for these.
 */
angular.module('elogbooksDirectives').directive('panelEntityFormRow', ['$http', 'apiClient', '$state', 'lodash', 'userManager', function ($http, apiClient, $state, lodash, userManager) {
    return {
        restrict: 'E',
        replace: true,
        scope: {
            property: '@',
            listProperty: '@',
            type: '@',
            required: '@',
            disabled: '=',
            options: '=?',
            model: '=',
            rows: '@',
            label: '@',
            pattern: '=?',
            subLabel: '@',
            downloadTitle: '@',
            ngRequired: '=?',
            ngDisabled: '=?',
            maxRules: '@',
            onChange: '&',
            min: '@',
            max: '@',
            step: '@',
            autocompleteConfig: '=',
            removePleaseOption: '=',
            radioLabelsAndValues: '='
        },
        template: '<div data-ng-include="template"></div>',
        link: function ($scope, $element, $attrs) {

            // A soft limit of 4 parent levels to check for the existence of a panel.
            var idx = 0,
                panelScope = $scope.$parent,
                entityFormScope = $scope.$parent;

            while (idx < 4 && !lodash.has(panelScope, 'panel')) {
                panelScope = panelScope.$parent;
                idx++;
            }
            // Find the entityForm
            idx = 0;
            while (idx < 5 && !lodash.has(entityFormScope, 'entityForm')) {
                entityFormScope = entityFormScope.$parent;
                idx++;
            }

            var siteResponse = panelScope.panel.siteResponse;
            // Allow multiple selects if data-multiple exists
            $scope.multiple = lodash.has($attrs, 'multiple');
            $scope.form = entityFormScope.entityForm;
            $scope.entity = panelScope.panel.entity;
            $scope.responseEntity = panelScope.panel.responseEntity;
            $scope.panel = panelScope.panel;
            $scope.template = getTemplate($attrs.type);
            $scope.generateNewPassword = generateNewPassword;

            if ($attrs.type == 'cke-editor') {
                var removeMaximizeButton = $scope.ngDisabled ? ',Maximize' : '';
                $scope.options = {
                    versionCheck: false,
                    language: userManager.getPreferenceValue('locale'),
                    allowedContent: false,
                    extraAllowedContent: false,
                    entities: false,
                    toolbarGroups : [
                        { name: 'clipboard', groups: [ 'clipboard', 'undo' ] },
                        { name: 'editing', groups: [ 'find', 'selection', 'spellchecker', 'editing' ] },
                        { name: 'links', groups: [ 'links' ] },
                        { name: 'insert', groups: [ 'insert' ] },
                        { name: 'tools', groups: [ 'tools' ] },
                        { name: 'document', groups: [ 'mode', 'document', 'doctools' ] },
                        { name: 'others', groups: [ 'others' ] },
                        '/',
                        { name: 'basicstyles', groups: [ 'basicstyles', 'cleanup' ] },
                        { name: 'paragraph', groups: [ 'list', 'indent', 'blocks', 'align', 'bidi', 'paragraph' ] },
                        { name: 'styles', groups: [ 'styles' ] },
                        { name: 'colors', groups: [ 'colors' ] },
                        { name: 'about', groups: [ 'about' ] }
                    ],
                    removeButtons : 'Underline,Subscript,Superscript,Paste,PasteText,PasteFromWord,Scayt,Anchor,Image,Styles,Format,About' + removeMaximizeButton
                };
            }

            $scope.subLabel = lodash.get($attrs, 'subLabel');
            $scope.downloadTitle = lodash.get($attrs, 'downloadTitle');

            if ($attrs.required === 'required') {
                $scope.ngRequired = "true";
            }

            $scope.$watch('entity.fm_survey_submission_type', function(newValue) {
                if (newValue === 0 && $scope.label === 'SURVEY_TIMEOUT') {
                    $scope.hiddenField = false;
                    $scope.ngRequired = true;
                } else if (newValue === 1 && $scope.label === 'SURVEY_TIMEOUT'){
                    $scope.hiddenField = true;
                    $scope.ngRequired = false;
                }

                if (newValue === 0 && $scope.label === 'FM_SUBMIT_KPI_BY_DATE') {
                    $scope.hiddenField = true;
                    $scope.ngRequired = false;
                } else if (newValue === 1 && $scope.label === 'FM_SUBMIT_KPI_BY_DATE'){
                    $scope.hiddenField = false;
                    $scope.ngRequired = true;
                }
            });

            $scope.$watch('entity.sp_auto_submit_kpi', function(newVal) {
                if ($scope.label === 'SP_SUBMIT_KPI_BY_DATE') {
                    if (!newVal) {
                        $scope.ngDisabled = 'true';
                        $scope.entity.sp_submit_kpi_by_date = 0;
                    } else {
                        $scope.ngDisabled = false;
                    }
                }
            });

            if ($attrs.disabled === 'disabled') {
                $scope.ngDisabled = "true";
            }

            if ($attrs.type === 'multiple-file-upload') {
                $scope.entity.files = $scope.entity.files || [];
            }

            if ($attrs.type === 'elb-autocomplete') {
                var config = angular.extend({
                    response: null,
                    queryParams: {},
                    link: $scope.autocompleteConfig && $scope.autocompleteConfig.response
                        ? $scope.autocompleteConfig.response.getLink('self') : null,
                    responseKeyPath: null,
                    itemValuePath: 'name'
                }, $scope.autocompleteConfig);

                var generateFromData = function (selectedObject) {
                    return [{
                        href: selectedObject.getLink('self')
                    }];
                };

                $scope.autocompleteModel = {
                    response: config.response,
                    link: config.link,
                    linkParameters: config.queryParams,
                    responseKeyPath: config.responseKeyPath,
                    itemValuePath: config.itemValuePath,
                    onSelect: function (model) {
                        $scope.entity[$scope.property] = model.selected.object;
                        $scope.entity[$scope.property].formData = generateFromData(model.selected.object);
                    },
                    onRemove: function () {
                        $scope.entity[$scope.property] = {
                            formData: null,
                        }
                    },
                };

                if ($scope.entity[$scope.property]) {
                    var selected = $scope.entity[$scope.property];
                    selected.formData = generateFromData(selected);

                    $scope.autocompleteModel.selected = {
                        href: selected.getLink('self'),
                        id: selected.id,
                        object: selected,
                        value: selected.name
                    }
                } else {
                    $scope.entity[$scope.property] = {
                        formData: null
                    }
                }
            }

            switch ($attrs.type) {
                case 'site':
                    $scope.property = 'site';
                    $scope.site = lodash.get($scope, 'entity._links.site');
                    break;
                case 'location':
                    $scope.$on('location:select:clear', function () {
                        delete $scope.entity._links.location;
                    });
                    $scope.property = 'location';
                    var existingLocation = lodash.get($scope, 'entity._links.location');

                    if (existingLocation) {
                        // If there is a location, get the full record for the paths array
                        apiClient.get(existingLocation.href).then(
                            function (locationResponse) {
                                console.log('panel-entity-form-row: full location record received.');
                                $scope.location = locationResponse;
                            }
                        );
                    }

                    // Get the locations for the site if a site is defined
                    if (siteResponse) {
                        apiClient.get(siteResponse.getLink('locations')).then(
                            function (locationsResponse) {
                                $scope.siteLocations = locationsResponse.locations;
                                console.log('locations updated.');
                            }
                        );
                    }
                    break;
                case 'site-priority':
                    $scope.sitePriorities = [];
                    $scope.property = 'priority';

                    // Get the priorties for the site if a site is defined
                    if (siteResponse) {
                        apiClient.get(siteResponse.getLink('priorities'), { status: 'active' }).then(
                            function (prioritiesResponse) {
                                var sitePriorities = [];
                                lodash.forEach(prioritiesResponse.priorities,
                                    function (response) {
                                        sitePriorities.push({
                                            title: response.name,
                                            value: response.getLink('self')
                                        });
                                    }
                                );
                                $scope.sitePriorities = sitePriorities;
                            }
                        );
                    }
                    break;
                case 'priority-deadline':
                    $scope.priorityTypeEntity = $scope.property === 'attend' ? 'attendPriority' : 'completePriority';
                    $scope.disallowTimeOfDay = ($attrs.disallowTimeOfDay === "true" || false) === true;
                    break;
                case 'site-partnership':
                    $scope.sitePartnerships = [];
                    $scope.sitePartnershipsSelected = [];
                    $scope.$on('checkbox-multi-select:select:click', function (event, selected) {
                        console.log('broadcast trapped');
                        console.log(selected);
                        $scope.entity.serviceProviders = null;
                        if (selected.length) {
                            $scope.entity.serviceProviders = {links: []};
                            for (var idx = 0; idx < selected.length; idx++) {
                                $scope.entity.serviceProviders.links.push({href: selected[idx].label});
                            }
                        }

                    });

                    if (siteResponse) {
                        var sitePartnerships = [];
                        apiClient.get(siteResponse.getLink('partnerships')).then(
                            function (partnerships) {
                                lodash.forEach(partnerships.partnerships,
                                    function (response) {
                                        //multiple checkbox requires label for href
                                        sitePartnerships.push({
                                            name: response._links.serviceprovider.title,
                                            label: response._links.serviceprovider.href,
                                            value: response._links.serviceprovider.href
                                        });
                                    });
                                $scope.sitePartnerships = sitePartnerships;
                            }
                        );
                    }
                    break;
                case 'priority':
                    $scope.property = 'priority';
                    $scope.priorities = [];
                    //var existingPriority = lodash.get($scope, 'entity._links.priority');
                    panelScope.panel.getPriorities({type: $attrs.type, scope: $scope});
                    break;
            }

            if ($scope.property == null) {
                $scope.property = lodash.snakeCase($scope.type);
            }

            if (typeof $attrs.label === 'undefined') {
                $scope.label = $scope.property.toUpperCase();
            }
            panelScope.panel.fields.push({type: $attrs.type, scope: $scope});

            function generateNewPassword(property) {
                apiClient.get('/passwordgenerator').then(function (response) {
                    $scope.entity[property] = response.password;
                });
            }

            function getTemplate(type) {
                var templateElement;

                switch (type) {
                    case 'checkbox':
                    case 'cke-editor':
                    case 'date':
                    case 'datetime':
                    case 'file':
                    case 'frequency':
                    case 'image-file':
                    case 'image-file-property':
                    case 'location':
                    case 'multiple-file-upload':
                    case 'partnership-to-serviceprovider':
                    case 'priority':
                    case 'priority-deadline':
                    case 'recurrence-rules':
                    case 'select':
                    case 'select-multiple':
                    case 'select-widget':
                    case 'select-link': // Select into _links[property].href
                    case 'site':
                    case 'site-priority':
                    case 'site-partnership':
                    case 'textarea':
                    case 'pattern':
                    case 'password':
                    case 'elb-autocomplete':
                    case 'radio':
                        templateElement = type;
                        break;
                    case 'colour-picker':
                        templateElement = type;
                        $scope.options = [
                            '#001F3F',
                            '#0074D9',
                            '#7FDBFF',
                            '#39CCCC',
                            '#3D9970',
                            '#2ECC40',
                            '#01FF70',
                            '#FFDC00',
                            '#FF851B',
                            '#FF4136',
                            '#85144B',
                            '#F012BE',
                            '#B10DC9',
                            '#111111',
                            '#AAAAAA',
                            '#FFFFFF'
                        ];
                        break;
                    default:
                        templateElement = 'input';
                        break;
                }

                return '/modules/directives/layout/panel-entity-form-row/elements/' + templateElement + '.html';
            }
        }
    };
}]);
