(function () {
    'use strict';

    angular
        .module('elogbooksFilters')
        .filter('userStatus', UserStatus);

    function UserStatus ($filter) {
        var statuses = {
            0: 'STATUS_DRAFT',
            'draft': 'STATUS_DRAFT',
            1: 'STATUS_TRAINING',
            'training': 'STATUS_TRAINING',
            2: 'STATUS_ACTIVE',
            'active': 'STATUS_ACTIVE',
            3: 'STATUS_INACTIVE',
            'inactive': 'STATUS_INACTIVE'
        };

        return function (input) {
            return $filter('translate')(statuses[input]);
        }
    };
})();
