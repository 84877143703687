(function () {
    'use strict';

    angular
        .module('elogbooks.admin.waste-management')
        .controller('TreatmentTypeAddEditController', TreatmentTypeAddEditController);

    TreatmentTypeAddEditController.$inject = ['$state', 'apiClient', 'confirmationModal', 'treatmentTypeResponse', 'requestDataFactory', 'messenger', 'treatmentTypeCollectionResponse', '$translate'];

    function TreatmentTypeAddEditController ($state, apiClient, confirmationModal, treatmentTypeResponse, requestDataFactory, messenger, treatmentTypeCollectionResponse, $translate) {
        var vm = this;
            vm.treatmentType = treatmentTypeResponse;
            vm.data = requestDataFactory.createRequest(getDefaults());

        vm.create = createAction;
        vm.update = updateAction;
        vm.delete = deleteAction;

        vm.statusOptions = [
            { value: true, title: $translate.instant('STATUS_ACTIVE') },
            { value: false, title: $translate.instant('STATUS_INACTIVE') }
        ];

        vm.targetDirections = [
            { value: true, title: $translate.instant('TARGET_DIRECTION_ABOVE') },
            { value: false, title: $translate.instant('TARGET_DIRECTION_BELOW') }
        ];

        function getDefaults() {
            return {
                id: vm.treatmentType.id || null,
                name: vm.treatmentType.name || null,
                target: parseFloat(vm.treatmentType.target) || null,
                status: typeof vm.treatmentType.status !== 'undefined' ? !!vm.treatmentType.status : true,
                targetDirection: typeof vm.treatmentType.targetDirection !== 'undefined' ? !!vm.treatmentType.targetDirection : null
            };
        }

        function createAction () {
            return apiClient.create(treatmentTypeCollectionResponse.getLink('create'), vm.data).then(function (response) {
                if (response) {
                    $state.go('^', {}, { reload: $state.current.parent }).then(function () {
                        messenger.success('TREATMENT_TYPE_CREATED');
                    });
                } else {
                    messenger.error('REQUEST_ERROR');
                }
            });
        }

        function updateAction () {
            return apiClient.update(vm.treatmentType.getLink('edit'), vm.data).then(function (response) {
                if (response) {
                    $state.go('^', {}, { reload: 'dashboard.admin.waste-management.treatment-types' }).then(function () {
                        messenger.success('TREATMENT_TYPE_UPDATED');
                    });
                } else {
                    messenger.error('REQUEST_ERROR');
                }
            });
        }

        function deleteAction () {
            return confirmationModal.open().result.then(function () {
                return apiClient.delete(vm.treatmentType.getLink('delete')).then(function (response) {
                    if (response) {
                        $state.go('dashboard.admin.waste-management.treatment-types', {}, { reload: 'dashboard.admin.waste-management.treatment-types' }).then(function () {
                            messenger.success('TREATMENT_TYPE_DELETED');
                        });
                    } else {
                        messenger.error('REQUEST_ERROR');
                    }
                });
            });
        }
    };
})();
