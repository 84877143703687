(function() {
    'use strict';

    angular
        .module('elogbooks.helpdesk.dashboard')
        .controller('HelpdeskDashboardController', HelpdeskDashboardController);

    function HelpdeskDashboardController($state, $stateParams, statsResponse, $timeout, $scope, analyticsService) {
        var vm = this;
        vm.stats = statsResponse;
        vm.deregisterWatch = null;
        vm.criteria = {
            id: { type: 'number', title: 'ID', value: $stateParams.id },
            serviceProviderName: { type: 'text', title: 'SERVICE_PROVIDER', value: $stateParams.serviceProviderName },
            siteName: { type: 'text', title: 'SITE', value: $stateParams.siteName },
            priority: { type: 'number', title: 'PRIORITY', value: $stateParams.priority }
        };
        vm.search = search;

        if ($stateParams.analytics === true) {
            var deregisterWatch = $scope.$watch('vm.stats', function(value) {
                analyticsService.pageLoaded();
                deregisterWatch();
            });
        }

        //The following pretty much becomes an interval due to reloading the state and re-triggering.
        if (!$scope.refreshStatsTimeout) {
            $scope.refreshStatsTimeout = $timeout(refreshStats, 60000);
        }

        function refreshStats() {
            var stats = angular.extend({}, $stateParams, { analytics: false });
            $state.go($state.current, stats, {reload: 'dashboard.helpdesk.dashboard'});
        }

        function search(params) {
            var override = {
                page: 1
            };

            $state.go('.', angular.extend({}, params, override), { reload: $state.current });
        }
    }
})();
