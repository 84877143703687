(function () {
    'use strict';

    /**
     * @desc priority select directive to allow user to select a priority.
     * @example <priority-select ng-model="vm.priority" priority-source="vm.site._links['priorities'].href"></priority-select>
     */
    angular
        .module('elogbooksDirectives')
        .directive('prioritySelect', ['apiClient', '$q', prioritySelect]);

    function prioritySelect(apiClient, $q) {
        var directive = {
            link: link,
            templateUrl: '/modules/directives/form-elements/priority-select/priority-select.html',
            restrict: 'EA',
            scope: {
                priority: '=',
                attendanceDueAt: '=priorityAttendanceDueAt',
                completionDueAt: '=priorityCompletionDueAt',
                required: '=ngRequired',
                disabled: '=ngDisabled',
                source: '=prioritySource',
                form: '='
            },
            require: []
        };
        return directive;

        function link(scope, element, attrs) {
            scope.previous = {
                priority:        angular.copy(scope.priority),
                attendanceDueAt: angular.copy(scope.attendanceDueAt),
                completionDueAt: angular.copy(scope.completionDueAt)
            };

            scope.$watch('completionByDate', function(enabled) {
                if (enabled) {
                    scope.previous.priority = angular.copy(scope.priority);
                    scope.attendanceDueAt = scope.previous.attendanceDueAt;
                    scope.completionDueAt = scope.previous.completionDueAt;

                    scope.priority = null;
                } else {
                    scope.previous.attendanceDueAt = angular.copy(scope.attendanceDueAt);
                    scope.previous.completionDueAt = angular.copy(scope.completionDueAt);
                    scope.priority = scope.previous.priority;

                    scope.attendanceDueAt = null;
                    scope.completionDueAt = null;
                }
            });

            scope.completionByDate = scope.attendanceDueAt && scope.priority == null;

            scope.$watch('source', function() {
                scope.priorities = null;

                if (!scope.source) {
                    return;
                }

                var src = [].concat(scope.source).concat('/priorities').filter(function(e) {
                    return e;
                });

                fetch(src, 0, $q.defer()).then(function(response) {
                    scope.priorities = response.priorities;

                    // If a priority was previously selected (our ng-model), but no longer exists in the response
                    // then set the model to null. This is only an issue on initial loads (for example: where the
                    // priority is no longer available since the creation of a job to the approval of a job)
                    /*if (!scope.priority) {
                        return;
                    }
                    var found = false;
                    for (var i in scope.priorities) {
                        if (scope.priorities[i].equals(scope.priority)) {
                            found = true;
                        }
                    }
                    if (!found) {
                        scope.priority = null;
                    }*/
                });
            });

            function fetch(sources, index, deferred) {
                if (sources[index]) {
                    apiClient.get(sources[index]).then(function(response) {
                        if (!response.priorities || response.priorities.length == 0) {
                            return fetch(sources, index + 1, deferred);
                        }

                        deferred.resolve(response);
                    });
                } else {
                    deferred.reject();
                }

                return deferred.promise;
            }
        }
    }
})();
