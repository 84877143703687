(function () {
    'use strict';

    angular
        .module('elogbooks.user.master-tasks')
        .config(masterTaskList)
        .config(masterTaskAddEdit)
        .config(masterTaskDetails);

        function masterTaskList($stateProvider) {
            $stateProvider
                .state('dashboard.user.master-tasks', {
                    abstract: true,
                    template: '<bookerize></bookerize>',
                    ncyBreadcrumb: {
                        skip: true
                    }
                })
                .state('dashboard.user.master-tasks.list', {
                    url: '/master-tasks?page&limit&order&masterTaskName&masterTaskType&masterTaskStatus',
                    parent: 'dashboard.user.master-tasks',
                    params: {
                        page: '1',
                        limit: '30',
                        order: 'name',
                        masterTaskName: null,
                        masterTaskType: null,
                        masterTaskStatus: null
                    },
                    resolve: {
                        masterTaskCollectionResource: function ($stateParams, apiClient, rootResourceResponse) {
                            return apiClient.get(rootResourceResponse.getLink('mastertasks'), {
                                page: $stateParams.page,
                                limit: $stateParams.limit,
                                order: $stateParams.order,
                                name: $stateParams.masterTaskName,
                                status: $stateParams.masterTaskStatus,
                                type: $stateParams.masterTaskType
                            }).then(function(response) {
                                return response || apiClient.noResourceFound();
                            });
                        }
                    },
                    views: parseBook({
                        "elogbooks-left@dashboard.user.master-tasks": {
                            templateUrl: '/modules/user/master-tasks/list/list.html',
                            controller: 'UserMasterTaskListController',
                            controllerAs: 'vm'
                        }
                    }),
                    ncyBreadcrumb: {
                        label: '{{ ::"MASTER_TASKS" | translate }}'
                    }
                })
            ;
        }

        function masterTaskAddEdit($stateProvider) {
            $stateProvider
                .state('dashboard.user.master-tasks.list.add', {
                    url: '/add',
                    parent: 'dashboard.user.master-tasks.list',
                    resolve: {
                        masterTaskResponse : function () {
                            return {};
                        },
                        statutoryTypeCollectionResponse: function (rootResourceResponse) {
                            return rootResourceResponse.getResource('statutorytypes');
                        },
                        masterAssetCollectionResponse: function (rootResourceResponse) {
                            return rootResourceResponse.getResource('masterassets');
                        }
                    },
                    views: parseBook({
                        "elogbooks-right@dashboard.user.master-tasks": {
                            templateUrl: '/modules/user/master-tasks/add-edit/add-edit.html',
                            controller: 'UserMasterTaskAddEditController',
                            controllerAs: 'vm'
                        }
                    }),
                    ncyBreadcrumb: {
                        label: '{{ ::"MASTER_TASK_ADD" | translate }}'
                    }
                })
                .state('dashboard.user.master-tasks.list.details.edit', {
                    url: '/edit',
                    parent: 'dashboard.user.master-tasks.list.details',
                    resolve: {
                        masterTaskResponse : function ($stateParams, apiClient) {
                            return apiClient.get($stateParams.resource.decode());
                        },
                        statutoryTypeCollectionResponse: function (rootResourceResponse) {
                            return rootResourceResponse.getResource('statutorytypes');
                        },
                        masterAssetCollectionResponse: function (rootResourceResponse) {
                            return rootResourceResponse.getResource('masterassets');
                        }
                    },
                    views: parseBook({
                        "elogbooks-right@dashboard.user.master-tasks": {
                            templateUrl: '/modules/user/master-tasks/add-edit/add-edit.html',
                            controller: 'UserMasterTaskAddEditController',
                            controllerAs: 'vm'
                        }
                    }),
                    ncyBreadcrumb: {
                        label: '{{ ::"MASTER_TASK_EDIT" | translate }}'
                    }
                })
            ;
        }

        function masterTaskDetails($stateProvider) {
            $stateProvider
                .state('dashboard.user.master-tasks.list.details', {
                    url: '/{resource}',
                    parent: 'dashboard.user.master-tasks.list',
                    resolve: {
                        masterTaskResponse : function ($stateParams, apiClient) {
                            return apiClient.get($stateParams.resource.decode());
                        }
                    },
                    views: parseBook({
                        "elogbooks-right@dashboard.user.master-tasks": {
                            templateUrl: '/modules/user/master-tasks/details/details.html',
                            controller: 'UserMasterTaskDetailsController',
                            controllerAs: 'vm'
                        }
                    }),
                    ncyBreadcrumb: {
                        label: '{{ ::$resolves().masterTaskResponse.name }}'
                    }
                })
            ;
        }
})();
