(function(){
    'use strict';

    angular
        .module('elogbooks.user.buddies')
        .controller('CommonBuddyScheduleListController', ['$state', '$stateParams', 'buddiesCollectionResource', '$filter', 'config', CommonBuddyScheduleListController]);

    function CommonBuddyScheduleListController($state, $stateParams, buddiesCollectionResource, $filter, config) {
        var vm = this;
        vm.buddies = buddiesCollectionResource ? buddiesCollectionResource.buddies : [];
        vm.buddiesCollection = buddiesCollectionResource;
        vm.title = config.type;
        vm.addBuddy = '.add';
        vm.typePath = config.type.toLowerCase().replace("_", "-");
        vm.userName;

        if (config.type === 'MY_BUDDY') {
            vm.addHide = null;
            vm.filterType = 'BUDDY';
            vm.pathSchedule = 'dashboard.user.buddies.my-buddy.schedule';
            vm.pathHistory = 'dashboard.user.buddies.my-buddy.history';
            vm.userName = '::buddy._links.buddy.title';
        } else {
            vm.addHide = true;
            vm.filterType = 'ASSIGNED_BY';
            vm.pathSchedule = 'dashboard.user.buddies.buddying-for.schedule';
            vm.pathHistory = 'dashboard.user.buddies.buddying-for.history';
            vm.userName = '::buddy._links.user.title';
        }

        var statusOptions = [
            {
                title: $filter('translate')('NONE_SELECTED'),
                value: null
            },
            {
                title: $filter('translate')('STATUS_INACTIVE'),
                value: 'draft'
            },
            {
                title: $filter('translate')('STATUS_ACTIVE'),
                value: 'active'
            }
        ];

        vm.criteria = {
            buddy: { type: 'text', title: vm.filterType, value: $stateParams.buddy },
            status: { type: 'options', title: 'STATUS', value: $stateParams.status ? $stateParams.status : null, options: statusOptions },
            dateRange: {
                type: 'date-range',
                title: 'BUDDY_BETWEEN',
                value: {
                    startDate: ($stateParams.dateRange !== null && typeof $stateParams.dateRange === 'string') ? moment(new Date($stateParams.dateRange.split(',')[0])) : null,
                    endDate: ($stateParams.dateRange !== null && typeof $stateParams.dateRange === 'string') ? moment(new Date($stateParams.dateRange.split(',')[1])) : null,
                    endMaxDate: null
                },
                clearValue: {
                    startDate: null,
                    endDate: null
                }
            },
        };

        vm.search = search;
        vm.order = order;

        /**
         * Search buddies
         */
        function search(params) {
            var override = {
                buddyPage: 1
            };

            $state.go('.', angular.extend({}, params, override), { reload: $state.current.parent });
        }

        /**
         * Order buddies
         */
        function order(key) {
            $stateParams.order = key;

            $state.go('.', angular.extend({}, $stateParams), { reload: $state.current.parent });
        }
    }
})();
