(function() {
    'use strict';

    angular
        .module('elogbooks.admin.pricing-schedules')
        .controller('FinancePurchaseOrdersStatusController', FinancePurchaseOrdersStatusController);

    FinancePurchaseOrdersStatusController.$inject = [
        'purchaseOrderResponse',
    ];

    function FinancePurchaseOrdersStatusController(
        purchaseOrderResponse
    ) {
        var vm = this;
        vm.purchaseOrderResponse = purchaseOrderResponse;
    }
})();
