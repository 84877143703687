;(function() {
    'use strict';

    angular
        .module('elogbooks.common.forms')
        .controller('CommonFormsDetailsController', CommonFormsDetailsController);

    CommonFormsDetailsController.$inject = ['confirmationModal', '$state', 'apiClient', 'crudService', 'lodash', 'requestDataFactory', 'messenger', 'formVersionInstanceResponse', 'associatedEntities', 'config'];

    function CommonFormsDetailsController(confirmationModal, $state, apiClient, crudService, lodash, requestDataFactory, messenger, formVersionInstanceResponse, associatedEntities, config) {
        var vm = this;
        vm.formVersionInstanceResponse = formVersionInstanceResponse;
        vm.formVersionResponse = formVersionInstanceResponse._embedded.formVersion;
        vm.config = config;
        vm.associatedEntities = associatedEntities;

        vm.save = saveAction;
        vm.submit = submitAction;
        vm.cancel = cancelAction;

        vm.data = angular.extend({}, vm.formVersionResponse.data);
        angular.forEach(vm.formVersionInstanceResponse.data, function(value, index) {
            var data = lodash.find(vm.data.components, {id: value.id});

            if (data) {
                data.answer = value.answer;
                data.notApplicable = value.notApplicable;
            }
        });

        function saveAction() {
            return crudService
                .update(
                    formVersionInstanceResponse.getLink('edit'),
                    vm.data,
                    $state.get('^'),
                    $state.get('^.^'),
                    'FORM_INSTANCE'
                );
        }

        function submitAction() {

            return crudService
                .create(
                    formVersionInstanceResponse.getLink('submit'),
                    vm.data,
                    null,
                    null,
                    'FORM_INSTANCE',
                    true
                )
                .then(function() {
                    $state
                        .go('^.^', {}, {reload: $state.get('^.^.^')})
                        .then(function() {
                            messenger.success('FORM_INSTANCE_CREATED');
                        });
                });
        }

        function cancelAction() {
            return confirmationModal.open(config).result.then(function() {
                $state.go('^', {}, {reload: false});
            });
        }
    }
})();
