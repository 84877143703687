(function() {
    'use strict';

    angular
        .module('elogbooks.common.priorities')
        .controller('PrioritiesAddEditController', PrioritiesAddEditController);

    PrioritiesAddEditController.$inject = [
        'config',
        '$scope',
        '$state',
        '$translate',
        'apiClient',
        'requestDataFactory',
        'messenger',
        'lodash',
        'priorityResponse',
        'confirmationModal',
        'priorityCreateLink',
        'priorityService'
    ];

    function PrioritiesAddEditController(
        config,
        $scope,
        $state,
        $translate,
        apiClient,
        requestDataFactory,
        messenger,
        lodash,
        priorityResponse,
        confirmationModal,
        priorityCreateLink,
        priorityService
    ) {

        var vm = this;
        vm.priority = priorityResponse;
        vm.config = config;
        vm.data = requestDataFactory.createRequest(getDefaults());
        vm.noBorder = vm.config.noBorder || false;
        vm.dashboardLimitReached = vm.config.isIncludeDashboardLimitReached;

        if (vm.priority.includeInDashboard && vm.config.isEdit) {
            vm.dashboardLimitReached = false;
        }

        vm.checkDashboardLimit = checkDashboardLimit;
        vm.create = createAction;
        vm.update = updateAction;
        vm.delete = deleteAction;

        vm.frequencyOptions = {
            minutes: { value: 'minutes', title: $translate.instant('MINUTES'), multiplier: 1},
            hours: { value: 'hours', title: $translate.instant('HOURS'), multiplier: 60},
            days: { value: 'days', title: $translate.instant('DAYS'), multiplier: 1440},
            weeks: { value: 'weeks', title: $translate.instant('WEEKS'), multiplier: 10080}
        };
        vm.data.frequencyOptions = vm.frequencyOptions;

        vm.completePeriodOptions = {
            disallowTimeOfDay: false
        };

        vm.entityTypeOptions = priorityService.getEntityTypeOptions();

        function getDefaults() {
            var defaults =  {
                id: vm.priority.id || null,
                name: vm.priority.name || null,
                attendPeriod: {
                    period: lodash.has(vm.priority, 'attendPeriod.period') ? vm.priority.attendPeriod.period : null,
                    timeOfDay: lodash.has(vm.priority, 'attendPeriod.timeOfDay') ? vm.priority.attendPeriod.timeOfDay : null,
                    includeNonWorkingDays: lodash.has(vm.priority, 'attendPeriod.includeNonWorkingDays') ? vm.priority.attendPeriod.includeNonWorkingDays : false,
                    frequency : lodash.has(vm.priority, 'attendPeriod.frequency') ? vm.priority.attendPeriod.frequency : null,
                    interval : lodash.has(vm.priority, 'attendPeriod.interval') ? vm.priority.attendPeriod.interval : null
                },
                completePeriod: {
                    period: lodash.has(vm.priority, 'completePeriod.period') ? vm.priority.completePeriod.period : null,
                    timeOfDay: lodash.has(vm.priority, 'completePeriod.timeOfDay') ? vm.priority.completePeriod.timeOfDay : null,
                    includeNonWorkingDays: lodash.has(vm.priority, 'completePeriod.includeNonWorkingDays') ? vm.priority.completePeriod.includeNonWorkingDays : false,
                    frequency : lodash.has(vm.priority, 'completePeriod.frequency') ? vm.priority.completePeriod.frequency : null,
                    interval : lodash.has(vm.priority, 'completePeriod.interval') ? vm.priority.completePeriod.interval : null
                },
                includeNonWorkingDays: vm.priority.includeNonWorkingDays || 0,
                approvalDelay: vm.priority.approvalDelay || 0,
                acknowledgeDelay: vm.priority.acknowledgeDelay || 0,
                alarmWhenOverdue: vm.priority.alarmWhenOverdue || 0,
                alertMessageEnabled: vm.priority.alertMessageEnabled || 0,
                alertMessage: vm.priority.alertMessage || null,
                active: typeof vm.priority.active === 'boolean' ? vm.priority.active : null,
                entityType: vm.priority.entityType || null,
                colour: '#' + vm.priority.colour || null,
                includeInDashboard: vm.priority.includeInDashboard || 0
            };

            defaults.approvalDelayFreq = minsToFreq(defaults.approvalDelay);
            defaults.approvalDelay = minsToInterval(defaults.approvalDelay);
            defaults.acknowledgeDelayFreq = minsToFreq(defaults.acknowledgeDelay);
            defaults.acknowledgeDelay = minsToInterval(defaults.acknowledgeDelay);

            return defaults;
        }

        function createAction() {
            var postData = angular.copy(vm.data);
            postData.approvalDelay = (vm.data.approvalDelay || 0) * vm.frequencyOptions[vm.data.approvalDelayFreq].multiplier;
            postData.approvalDelay = Math.floor(postData.approvalDelay);
            postData.acknowledgeDelay = (vm.data.acknowledgeDelay || 0) * vm.frequencyOptions[vm.data.acknowledgeDelayFreq].multiplier;
            postData.acknowledgeDelay = Math.floor(postData.acknowledgeDelay);
            postData.alarmWhenOverdue = vm.data.alarmWhenOverdue || 0;
            postData.alertMessageEnabled = vm.data.alertMessageEnabled || 0;
            postData.alertMessage = vm.data.alertMessageEnabled ? vm.data.alertMessage : null;
            postData.colour = (vm.data.colour && vm.data.colour.length) > 6 ? vm.data.colour.substring(1) : vm.data.colour;

            apiClient.create(priorityCreateLink, postData).then(function (response) {
                if (response) {
                    $state.go('^', {}, { reload: $state.get('^.^') }).then(function () {
                        messenger.success('PRIORITY_CREATED');
                    });
                } else {
                    messenger.error('REQUEST_ERROR');
                }
            });
        }

        function updateAction() {
            var postData = angular.copy(vm.data);
            postData.approvalDelay = (vm.data.approvalDelay || 0) * vm.frequencyOptions[vm.data.approvalDelayFreq].multiplier;
            postData.approvalDelay = Math.floor(postData.approvalDelay);
            postData.acknowledgeDelay = (vm.data.acknowledgeDelay || 0) * vm.frequencyOptions[vm.data.acknowledgeDelayFreq].multiplier;
            postData.acknowledgeDelay = Math.floor(postData.acknowledgeDelay);
            postData.alarmWhenOverdue = vm.data.alarmWhenOverdue || 0;
            postData.alertMessageEnabled = vm.data.alertMessageEnabled || 0;
            postData.alertMessage = vm.data.alertMessageEnabled ? vm.data.alertMessage : null;
            postData.colour = (vm.data.colour && vm.data.colour.length) > 6 ? vm.data.colour.substring(1) : vm.data.colour;

            apiClient.update(vm.priority.getLink('edit'), postData).then(function (response) {
                if (response) {
                    $state.go('^', {}, { reload: $state.get('^.^.^') }).then(function() {
                        messenger.success('PRIORITY_UPDATED');
                    });
                } else {
                    messenger.error('REQUEST_ERROR');
                }
            });
        }

        function deleteAction() {
            return confirmationModal.open().result.then(
                function () {
                    return apiClient.delete(vm.priority.getLink('delete')).then(function (response) {
                        if (response) {
                            if (vm.config.goToStateAfterDelete) {
                                $state.go(vm.config.goToStateAfterDelete, {}, {reload: $state.get('^.^.^')}).then(function () {
                                    messenger.success('PRIORITY_DELETED');
                                });
                            } else {
                                $state.go('^.^.^', {}, {reload: $state.get('^.^.^')}).then(function () {
                                    messenger.success('PRIORITY_DELETED');
                                });
                            }
                        } else {
                            messenger.error('REQUEST_ERROR');
                        }
                    });
                },
                function () {
                    return;
                }
            );
        }

        function minsToFreq(value) {
            var frequency = "minutes";
            if(value >= 10080 && parseInt(value / 10080) == (value / 10080)) {
                frequency = "weeks";
            } else if(value >= 1440) {
                frequency = "days";
            } else if(value >= 60) {
                frequency = "hours";
            }
            return frequency;
        }

        function minsToInterval(value) {
            var interval = value;
            if(value >= 10080 && parseInt(value / 10080) == (value / 10080)) {
                interval /= 10080;
            } else if(value >= 1440) {
                interval /= 1440;
            } else if(value >= 60) {
                interval /= 60;
            }
            return interval;
        }

        $scope.$watch('vm.data.entityType', function(newValue, oldValue) {
            vm.completePeriodOptions.disallowTimeOfDay = newValue === 'entity_type_action';
        });

        function checkDashboardLimit() {
            if (vm.priority.includeInDashboard && vm.config.isEdit) {
                return false;
            }

            return vm.config.isIncludeDashboardLimitReached;
        }
    }
})();
