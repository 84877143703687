(function () {
    'use strict';

    angular
        .module('elogbooks.common.files')
        .controller('SendToMeridianForm', SendToMeridianForm);

    SendToMeridianForm.$inject = [
        '$uibModalInstance',
        '$state',
        'apiClient',
        'messenger',
        'fileResponse',
        'meridianDocTypes',
        'integrationsService',
        'moment'
    ];

    function SendToMeridianForm(
        $uibModalInstance,
        $state,
        apiClient,
        messenger,
        fileResponse,
        meridianDocTypes,
        integrationsService,
        moment
    ) {
        var vm = this;

        vm.meridianDocTypes = meridianDocTypes;
        vm.selectedDocType = null;
        vm.selectedDocType2 = null;
        vm.buttonDisabled = false;

        var integration = {
            docTypes: meridianDocTypes
        };

        integrationsService.addMeridianDefaultDocTypes(integration, fileResponse);

        if (integration.defaultDocType) {
            vm.selectedDocType = integration.defaultDocType;
            vm.selectedDocType2 = integration.defaultDocType2;
        }

        vm.cancel = () => $uibModalInstance.dismiss('cancel');

        vm.submit = vm.create = () => {
            apiClient.create(fileResponse.getLink('create-integration'), {
                documentDate: moment.utc(fileResponse.documentDate).format('YYYY-MM-DDT00:00:00+00:00'),
                integration: {meridian: vm.selectedDocType2},
                _links: {file: {href: fileResponse.getLink('self')}}
            }).then((response) => {
                if (response) {
                    $uibModalInstance.close(response);

                    $state.go('.', {}, {reload: $state.get('^.^')}).then(function () {
                        messenger.success('FILE_SEND_TO_MERIDIAN_SUCCESS');
                    });
                } else {
                    messenger.error('REQUEST_ERROR');
                    $uibModalInstance.close(false);
                }
            });
        };
    }
})();
