app.config(function ($translateProvider) {
    $translateProvider.translations('en-gb', {
        FORM_ASSOCIATIONS: 'Form',
        FORM_ASSOCIATIONS_LIST: 'Forms',

        FORM_ASSOCIATIONS_ADD: 'Add Form',

        FORM_ASSOCIATIONS_CREATED: 'Form Added',
        FORM_ASSOCIATIONS_DELETED: 'Form Removed',
    });
});
