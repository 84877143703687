app.config(function ($translateProvider) {
    $translateProvider.translations('nl', {

        TAG: 'Label',
        TAGS: 'Labels',
        TAG_LIST: 'Labels',

        TAG_CREATED: 'Label Aangemaakt',
        TAG_UPDATED: 'Label Bijgewerkt',
        TAG_DELETED: 'Label Verwijderd',

        TAG_ADD: 'Nieuw Label toevoegen',

        TAG_ACTION: 'Action: {{ ::tag.entityId }}',
        TAG_JOB: 'Opdracht: {{ ::tag.entityId }}',
        TAG_JOBREQUISITEANSWER: 'Job Requisite Answer',
        TAG_PLANNEDJOB: 'Planned Job: {{ ::tag.entityId }}',
        TAG_QUOTE: 'Offerte: {{ ::tag.entityId }}',
        TAG_QUOTEOPTION: 'Offerte Optie',
        TAG_QUOTEREQUEST: 'Offerteaanvraag',
        TAG_REACTIVEJOB: 'Opdracht: {{ ::tag.entityId }}',
        TAG_SITE: '{{ ::tag.name }}',
       
        AND_MORE: '...en {{ ::number | number:0 }} meer'

    });
});
