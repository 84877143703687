app.config(function ($translateProvider) {
    $translateProvider.translations('en-gb', {
        SERVICE_ROUTINE: 'Service Routine',
        SERVICE_ROUTINES: 'Service Routines',
        SERVICEROUTINES: 'Service Routines',
        ADD_NEW_SERVICE_ROUTINE: 'Add a new Service Routine',
        EDIT_SERVICE_ROUTINE:  'Edit Service Routine',
        SERVICE_ROUTINE_LINES:  'Service Routine Lines',
        ADD_SERVICE_ROUTINE_LINE:  'Add Service Routine Line',
        SERVICE_ROUTINE_ACTION:  'Action',
        SERVICE_ROUTINE_WITH_COUNT: 'Service Routine Lines ({{ ::count || 0 | number:0 }})',

        SERVICE_ROUTINE_LIST: 'Service Routines',
        SERVICE_ROUTINE_VIEW: 'Info',
        SERVICE_ROUTINE_ADD: 'Add a new Service Routine',
        SERVICE_ROUTINE_EDIT: 'Edit Service Routine',

        SERVICE_ROUTINE_CREATED: 'Service Routine Created',
        SERVICE_ROUTINE_UPDATED: 'Service Routine Updated',
        SERVICE_ROUTINE_DELETED: 'Service Routine Deleted',

        SERVICE_ROUTINE_MISSING_FREQUENCY: 'Please be aware, one or more lines for this Service Routine do not have a Frequency.',
        SERVICE_ROUTINE_COPY_CONFIRMATION_MODAL_BODY: 'This will copy the Service Routine and all its Service Routine Lines.',
        COPY_SERVICE_ROUTINE: 'Copy Service Routine',
        SERVICE_ROUTINE_COPIED: 'Service Routine Copied',
        SERVICE_ROUTINE_LINE_ACTION: 'Action',
        SERVICE_ROUTINE_LINE_NOTES: 'Notes',
        SERVICE_ROUTINE_LINE_FREQUENCY: 'Frequency',

        SERVICE_ROUTINE_LINE: 'Service Routine Line',
        VERSIONED: 'Versioned',
        VERSION_CREATED_AT: 'Version created at',
        REFERENCE_MUST_BE_UNIQUE: "Reference must be unique.",
        SERVICE_ROUTINE_LINE_LIST: 'Service Routine Lines',
        SERVICE_ROUTINE_LINE_ADD: 'Add a new Service Routine Line',
        SERVICE_ROUTINE_LINE_EDIT: 'Edit Service Routine Line',

        SERVICE_ROUTINE_LINE_CREATED: 'Service Routine Line Created',
        SERVICE_ROUTINE_LINE_UPDATED: 'Service Routine Line Updated',
        SERVICE_ROUTINE_LINES_UPDATED: 'Service Routine Lines Updated',
        SERVICE_ROUTINE_LINE_DELETED: 'Service Routine Line Deleted',
    });
});
