(function () {
    'use strict';

    /**
     * Location Service
     */
    angular.module('elogbooksServices').service('locationService', function (apiClient, $q, $filter) {
        this.get = getLocation;
        this.getAbsoluteName = getAbsoluteName;
        this.createLocation = createLocation;

        /**
         * get a location
         * @returns {promise}
         */
        function getLocation(link) {
            var deferred = $q.defer();

            apiClient.get(link).then(function (response) {
                if (response) {
                    response.absoluteName = getAbsoluteName(response);

                    return deferred.resolve(response);
                } else {

                    return deferred.reject($filter('translate')('UNABLE_TO_GET_LOCATION'));
                }
            });

            return deferred.promise;
        };

        /**
         * @returns {string}
         */
        function getAbsoluteName(response) {
            return response.name;
        }

        function createLocation(link, data) {
            var deferred = $q.defer();

            apiClient.create(link, data).then(function (response) {
                if (response) {
                    return deferred.resolve(response);
                } else {
                    return deferred.reject();
                }
            });

            return deferred.promise;
        }

        return this;
    });
})();
