(function() {
    'use strict';

    angular
        .module('elogbooks')
        .config(Translations);

    function Translations($translateProvider) {
        $translateProvider.translations('zh-hk', {

            LEGEND_JOB_REACTIVE_OVERDUE_ATTENDANCE: '逾期',
            LEGEND_JOB_REACTIVE_NOT_OVERDUE_ATTENDANCE: '開啟',

            LEGEND_JOB_PLANNED_OVERDUE_COMPLETION: '逾期',
            LEGEND_JOB_PLANNED_NOT_OVERDUE_COMPLETION: '開啟',

            LEGEND_ACTION_OPEN: '開啟',
            LEGEND_ACTION_COMPLETED: '完成'

        });
    }
})();
