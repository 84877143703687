;(function() {
    'use strict';
    angular.module('elogbooksDirectives')
        .directive('elbTableLoader', function () {
            return {
                restrict: 'AE',
                templateUrl: '/modules/directives/elb-table-loader/elb-table-loader.html',
                controller: TableLoader,
                replace: true,
                scope: {
                    rows: '@',
                    loading: '='
                }
            };

            function TableLoader($scope) {
                $scope.rows = $scope.rows || 3;
            }
        });
})();