app.config(function ($translateProvider) {
    $translateProvider.translations('en-gb', {
        TENANT: 'Tenant',
        TENANTS: 'Tenants',

        TENANT_LIST: 'Tenants',

        TENANT_ADD: 'Add a new Tenant',
        ADD_NEW_TENANT: 'Add Tenant',
        TENANT_EDIT: 'Edit Tenant',
        TENANT_CREATED: 'Tenant Created',
        TENANT_DELETED: 'Tenant Deleted',

        EMAIL_SELECTED_TENANTS: '<i class="fa fa-mail-forward"></i> Email Tenants ({{ ::selected }} selected)',
        EMAIL_FILTERED: 'Email Filtered',
        FILTERED_TENANTS: 'Filtered Tenants',
        EMAIL_TENANTS: 'Email Tenants',
        EMAIL_LOGO_SUGGESTED_SIZE: 'Suggested upload size : max-width = "393px", max-height = "91px"',

        TENANT_CONTACT_CREATED: 'Tenant Created',
        TENANT_CONTACT_UPDATED: 'Tenant Updated',
        TENANT_CONTACT_DELETED: 'Tenant Deleted',

        A_TENANT_CANNOT_BE_A_USER: 'A Tenant Cannot Be A user',
        A_TENANT_MUST_HAVE_A_VALID_NAME: 'A Tenant Must Have A Valid Name',

        NOT_A_TENANT: 'Not a Tenant',
        ROLE_MAX_LENGTH: 'Max length for role field is 100'
    });
});
