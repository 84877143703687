(function () {
    'use strict';

    angular
        .module('elogbooks.user.finance')
        .config(registerSupplierRoutes)
        .config(registerSuppliersRoutesList)
        .config(registerSuppliersRoutesAdd)
        .config(registerSuppliersRoutesDetails)
        .config(registerSuppliersRoutesEdit)

    function registerSupplierRoutes($stateProvider) {
        $stateProvider
            .state('dashboard.user.finance.suppliers', {
                abstract: true,
                parent: 'dashboard.user.finance',
                template: '<bookerize></bookerize>',
                ncyBreadcrumb: {
                    skip: true
                }
            });
    }

    function registerSuppliersRoutesList($stateProvider) {
        var supplierParams = {
            name: null,
            companyNumber: null,
            externalReference: null,
            active: null,
            supplierPage: '1',
            supplierLimit: '30',
            supplierOrder: 'id'
        }

        $stateProvider
            .state('dashboard.user.finance.suppliers.list', {
                url: '/suppliers?' + Object.keys(supplierParams).join('&'),
                parent: 'dashboard.user.finance.suppliers',
                resolve: {
                    suppliersCollectionResponse: function (apiClient, rootResourceResponse, $stateParams) {
                        var params = angular.copy($stateParams);
                        params = angular.extend({}, params, {
                            page:   $stateParams.supplierPage,
                            order:  $stateParams.supplierOrder,
                            limit:  $stateParams.supplierLimit
                        });
                        return apiClient.get(rootResourceResponse.getLink('suppliers'), params).then( function(response) {
                            return response || apiClient.noResourceFound();
                        });
                    }
                },
                params: supplierParams,
                views: parseBook({
                    'elogbooks-left@dashboard.user.finance': {
                        templateUrl: '/modules/user/finance/suppliers/suppliers-list.html',
                        controller: 'FinanceSuppliersController',
                        controllerAs: 'vm'
                    }
                }),
                ncyBreadcrumb: {
                    label: '{{ ::"SUPPLIERS_LIST" | translate }}'
                }
            });
    }

    function registerSuppliersRoutesAdd($stateProvider) {
        $stateProvider
            .state('dashboard.user.finance.suppliers.list.add', {
                url: '/add',
                parent: 'dashboard.user.finance.suppliers.list',
                views: parseBook({
                    'elogbooks-left@dashboard.user.finance': {
                        templateUrl: '/modules/user/finance/suppliers/add-edit/supplier-add-edit.html',
                        controller: 'FinanceSupplierAddEditController',
                        controllerAs: 'vm'
                    }
                }),
                resolve: {
                    supplierResponse: function(apiClient, $stateParams) {
                        return {}
                    },
                    serviceProviderCollectionResponse: function (apiClient, user, paramsService) {
                        return apiClient.get(user.getLink('serviceproviders')).then( function(response) {
                            return response || apiClient.noResourceFound();
                        });
                    },
                    vatRatesCollectionResponse: function(apiClient, rootResourceResponse) {
                        return apiClient.get(rootResourceResponse.getLink('vat-rates'), { active: true }).then( function(response) {
                            return response || apiClient.noResourceFound();
                        });
                    }
                },
                ncyBreadcrumb: {
                    label: '{{ ::"ADD" | translate }}'
                }
            })
    }


    function registerSuppliersRoutesDetails($stateProvider) {
        $stateProvider
            .state('dashboard.user.finance.suppliers.list.details', {
                url: '/details/{resource}',
                abstract: true,
                parent: 'dashboard.user.finance.suppliers.list',
                views: parseBook({
                    'elogbooks-left@dashboard.user.finance': {
                        templateUrl: '/modules/user/finance/suppliers/details/supplier-details.html',
                        controller: 'FinanceSupplierDetailsController',
                        controllerAs: 'vm'
                    }
                }),
                resolve: {
                    supplierResponse: function(apiClient, $stateParams) {
                        return apiClient.get($stateParams.resource.decode()).then( function(response) {
                            return response || apiClient.noResourceFound();
                        });
                    },
                    serviceProviderCollectionResponse: function (apiClient, user, paramsService) {
                        return apiClient.get(user.getLink('serviceproviders')).then( function(response) {
                            return response || apiClient.noResourceFound();
                        });
                    }
                },
                ncyBreadcrumb: {
                    label: '{{ ::$resolves().supplierResponse.name }}'
                }
            })
             .state('dashboard.user.finance.suppliers.list.details.info', {
                url: '/info',
                parent: 'dashboard.user.finance.suppliers.list.details',
                views: parseBook({
                    'elogbooks-right@dashboard.user.finance.suppliers.list.details': {
                        templateUrl: '/modules/user/finance/suppliers/details/info/supplier-info.html',
                        controller: 'FinanceSupplierDetailsInfoController',
                        controllerAs: 'vm'
                    }
                }),
                ncyBreadcrumb: {
                    label: '{{ ::"INFO" | translate }}'
                }
            });
    }


    function registerSuppliersRoutesEdit($stateProvider) {
        $stateProvider
            .state('dashboard.user.finance.suppliers.list.details.info.edit', {
                url: '/edit',
                parent: 'dashboard.user.finance.suppliers.list.details.info',
                views: parseBook({
                    'elogbooks-left@dashboard.user.finance': {
                        templateUrl: '/modules/user/finance/suppliers/add-edit/supplier-add-edit.html',
                        controller: 'FinanceSupplierAddEditController',
                        controllerAs: 'vm'
                    }
                }),
                resolve: {
                    supplierResponse: function(apiClient, $stateParams) {
                        return apiClient.get($stateParams.resource.decode()).then( function(response) {
                            return response || apiClient.noResourceFound();
                        });
                    },
                    vatRatesCollectionResponse: function(apiClient, rootResourceResponse) {
                        return apiClient.get(rootResourceResponse.getLink('vat-rates'), { active: true }).then( function(response) {
                            return response || apiClient.noResourceFound();
                        });
                    }
                },
                ncyBreadcrumb: {
                    label: '{{ ::"EDIT" | translate }}'
                }
            })
    }

})();
