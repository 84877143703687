(function() {
    'use strict';

    angular
        .module('elogbooks')
        .config(Translations);

    function Translations($translateProvider) {
        $translateProvider.translations('en-gb', {
            PERFORMANCE_STATISTICS: 'Performance Statistics',
            PERFORMANCE_SERVICE_PROVIDER: 'Your Performance',
            PERFORMANCE_STATUS: 'Status',
            PERFORMANCE_SYSTEM: 'Average Performance',

            PERFORMANCE_TYPE_AVERAGE: 'Overall Performance',
            PERFORMANCE_TYPE_REACTIVE_UPDATES_ACHIEVED: 'Reactive Updates Achieved',
            PERFORMANCE_TYPE_REACTIVE_ATTENDANCE_ACHIEVED: 'Reactive Attendance Achieved',
            PERFORMANCE_TYPE_REACTIVE_COMPLETION_ACHIEVED: 'Reactive Completion Achieved',
            PERFORMANCE_TYPE_PLANNED_UPDATES_ACHIEVED: 'PPM Updates Achieved',
            PERFORMANCE_TYPE_PLANNED_COMPLETION_ACHIEVED: 'PPM Complete',
            PERFORMANCE_TYPE_QUOTE_SLA_ACHIEVED: 'Quote SLAs Achieved',
            PERFORMANCE_TYPE_KPI_SURVEYS: 'KPI Surveys',

            PERFORMANCE_DASHBOARD_TYPE_AVERAGE: 'Overall<br />Performance',
            PERFORMANCE_DASHBOARD_TYPE_REACTIVE_UPDATES_ACHIEVED: 'Reactive<br />Updates',
            PERFORMANCE_DASHBOARD_TYPE_REACTIVE_ATTENDANCE_ACHIEVED: 'Reactive SLA<br />Attendance',
            PERFORMANCE_DASHBOARD_TYPE_REACTIVE_COMPLETION_ACHIEVED: 'Reactive SLA<br />Completion',
            PERFORMANCE_DASHBOARD_TYPE_PLANNED_COMPLETION_ACHIEVED: 'PPM<br />Complete',
            PERFORMANCE_DASHBOARD_TYPE_PLANNED_UPDATES_ACHIEVED: 'PPM<br />Updates',
            PERFORMANCE_DASHBOARD_TYPE_QUOTE_SLA_ACHIEVED: 'Quote SLA<br />Completion',
            PERFORMANCE_DASHBOARD_TYPE_KPI_SURVEYS: 'KPI<br />Surveys',

            PERFORMANCE_INFORMATION_TITLE: 'Performance Information',
            PERFORMANCE_INFORMATION_DESCRIPTION: 'These statistics are calculated as an average across all service providers for the previous 30 days.',
            PERFORMANCE_TYPE_AVERAGE_INFORMATION: 'The average of all the other percentages',
            PERFORMANCE_TYPE_REACTIVE_UPDATES_ACHIEVED_INFORMATION: 'Based on the percentage of outstanding reactive job reminders. Clicking on the status icon will show you outstanding reactive jobs that are due an update.',
            PERFORMANCE_TYPE_REACTIVE_ATTENDANCE_ACHIEVED_INFORMATION: 'The percentage of times you have attended a reactive job within the SLA deadline.',
            PERFORMANCE_TYPE_REACTIVE_COMPLETION_ACHIEVED_INFORMATION: 'The percentage of times you have completed a reactive job within the SLA deadline.',
            PERFORMANCE_TYPE_PLANNED_UPDATES_ACHIEVED_INFORMATION: 'Based on the percentage of outstanding ppm job reminders. Clicking on the status icon will show you outstanding planned jobs that are due an update.',
            PERFORMANCE_TYPE_PLANNED_COMPLETION_ACHIEVED_INFORMATION: 'The percentage of times you have completed a PPM job within the SLA deadline.',
            PERFORMANCE_TYPE_QUOTE_SLA_ACHIEVED_INFORMATION: 'The percentage of times you have submitted a quote within the SLA deadline.',
            PERFORMANCE_TYPE_KPI_SURVEYS_INFORMATION: 'Based on the average FM KPI score in the previous calendar month. Selecting the traffic light will display those KPIs with an FM score which is less than 100%.',
        });
    }
})();
