(function () {
    'use strict';

    angular
        .module('elogbooks.user.reports')
        .controller('UserReportsOpenCloseDetailsController', UserReportsOpenCloseDetailsController);

    UserReportsOpenCloseDetailsController.$inject = ['lightBoxModal', 'apiClient', '$filter', '$state', '$stateParams', 'tenantCollectionResponse', 'siteCollectionResponse', 'openCloseCollectionResponse', 'moment', '$scope', 'lodash', '$translate', 'paramConverterService'];

    function UserReportsOpenCloseDetailsController(lightBoxModal, apiClient, $filter, $state, $stateParams, tenantCollectionResponse, siteCollectionResponse, openCloseCollectionResponse, moment, $scope, lodash, $translate, paramConverterService) {
        var vm = this;
        vm.openClose = openCloseCollectionResponse.report ? openCloseCollectionResponse.report : null;
        vm.openCloseResponse = openCloseCollectionResponse;
        vm.stateParams = $stateParams;
        var selected;

        vm.search = search;
        vm.order = orderAction;
        vm.lightBox = lightBox;

        var findSelectedValue = function (collection, id) {
            if (!id) {
                return null;
            }
            selected = lodash.find(collection, function (item) {
                return item.id === parseInt(id);
            });

            return selected ? {
                value: selected.id,
                title: selected.name,
                self: selected._links.self
            } : null;
        };

        vm.siteSelect = {
            response: siteCollectionResponse,
            link: siteCollectionResponse ? siteCollectionResponse.getLink('self') : null,
            responseKeyPath: 'sites',
            itemValuePath: 'id',
            itemTitlePath: 'name',
            selected: findSelectedValue(siteCollectionResponse.sites, $stateParams.siteId)
        };

        vm.tenantSelect = {
            response: tenantCollectionResponse,
            responseKeyPath: 'contacts',
            itemValuePath: 'id',
            itemTitlePath: 'name',
            disabled: !tenantCollectionResponse
        };

        if (tenantCollectionResponse) {
            vm.tenantSelect.selected = findSelectedValue(tenantCollectionResponse.contacts, $stateParams.tenant);
        }

        var typeOptions = [
            {
                title: $translate.instant('NONE_SELECTED'),
                value: null
            },
            {
                title: $translate.instant('EARLY_OPEN'),
                value: 'open'
            },
            {
                title: $translate.instant('LATE_CLOSE'),
                value: 'close'
            }
        ];

        vm.criteria = {
            siteId: {
                type: 'selectwidget',
                title: 'SITE',
                options: vm.siteSelect
            },
            tenant: {
                type: 'selectwidget',
                title: 'TENANT',
                options: vm.tenantSelect
            },
            type: {
                type: 'options',
                title: 'TYPE',
                options: typeOptions,
                value: $stateParams.type
            },
            dateRange: {
                type: 'date-range',
                title: 'BETWEEN_DATES',
                value: {
                    startDate: ($stateParams.dateRange !== null) ? moment(new Date($stateParams.dateRange.split(',')[0])) : null,
                    endDate: ($stateParams.dateRange !== null) ? moment(new Date($stateParams.dateRange.split(',')[1])) : null,
                    endMaxDate: moment().format('MM-DD-YYYY, 23:59:59')
                },
                clearValue: {
                    startDate: null,
                    endDate: null
                }
            }
        };

        if ($stateParams.modalOpen) {
            lightBox(0);
        }
        function lightBox(index) {
            vm.modalData = {
                href: vm.openClose[index]._links.resource.href,
                title: vm.openClose[index]._links.resource.title
            };
            lightBoxModal.open(vm.modalData);
        }

        function search(params) {
            var override = {
                page: 1
            };

            $state.go('.', angular.extend({}, params, override), {reload: $state.current});
        }

        function orderAction(key) {
            $stateParams.order = key;
            $state.go('.', angular.extend({}, $stateParams), {reload: $state.current});
        }

    }
})();


