(function() {
    'use strict';

    angular
        .module('elogbooks.user.timesheets')
        .controller('UserTimesheetsDetailsController', UserTimesheetsDetailsController);

    UserTimesheetsDetailsController.$inject = [];
    function UserTimesheetsDetailsController() {
        var vm = this;
    }
})();
