(function () {
    'use strict';

    angular
        .module('elogbooks.user.reports')
        .controller('UserReportsSiteAssociateEscalatedToClientController', UserReportsSiteAssociateEscalatedToClientController);

    UserReportsSiteAssociateEscalatedToClientController.$inject = ['actionsCollection', 'siteAssociateResponse', '$translate', '$state'];

    function UserReportsSiteAssociateEscalatedToClientController(actionsCollection, siteAssociateResponse, $translate, $state) {
        var vm = this;
        vm.actionsCollection = actionsCollection;
        vm.actions = actionsCollection.actions;
        vm.associate = siteAssociateResponse;

        vm.listTitle = vm.associate._links.user.title + ' - ' + $translate.instant('ESCALATED_TO_CLIENT');
    }
})();
