(function () {
    'use strict';

    angular
        .module('elogbooks.admin.scd-imports')
        .controller('SCDImportController', ['$state', 'apiClient', 'messenger', 'rootResourceResponse', 'elbDownloadService', 'lodash', SCDImportController]);

    function SCDImportController($state, apiClient, messenger, rootResourceResponse, elbDownloadService, lodash) {
        var vm = this;
        vm.importFile = null;
        vm.import = {
            _links: {
                file: undefined
            },
            testRun: true,
            params: {}
        };
        vm.create = create;
        vm.reload = reload;
        vm.downloadTemplate = downloadTemplate;

        function create() {
            if (!vm.importFile) {
                messenger.error('NO_SCD_IMPORT_FILE_ADDED');
                return;
            }

            vm.import._links.file = vm.importFile._links.file;

            var importLink = lodash.find(
                rootResourceResponse._links.import,
                { name: 'scd' }
            ).href;

            apiClient.create(importLink, vm.import).then(function(response) {
                if (response) {
                    $state.go('dashboard.admin.scd-imports.list', {}, { reload: $state.current.parent }).then(function() {
                        messenger.info('IMPORT_QUEUED');
                    });
                } else {
                    messenger.error('IMPORT_ERROR');
                    vm.import._links.file = undefined;
                }
            });
        }

        function reload() {
            $state.go($state.current, {}, {reload: $state.current});
        }

        function downloadTemplate() {
            elbDownloadService.downloadUrl(vm.imports[vm.import.type].href, vm.imports[vm.import.type].name + '_import_template.csv', 'text/csv');
        }
    }
})();
