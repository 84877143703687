(function () {
    'use strict';

    angular
        .module('elogbooks.user.files')
        .controller('CommonJobFileAddEditController', CommonJobFileAddEditController);

    CommonJobFileAddEditController.$inject = ['apiClient', '$state', 'messenger', 'fileResponse', 'requestDataFactory', 'elbVisibilityService', '$scope', 'jobResponse', 'siteResponse'];

    function CommonJobFileAddEditController (apiClient, $state, messenger, fileResponse, requestDataFactory, elbVisibilityService, $scope, jobResponse, siteResponse) {
        var vm = this;
        vm.fileResponse = fileResponse;
        vm.data = requestDataFactory.createRequest(fileResponse);
        vm.visibilityService = elbVisibilityService;
        vm.visibilityServiceOptions = vm.visibilityService.getVisibilitiesAsObject();
        vm.uploading = false;
        vm.uploadedFile = null;
        vm.jobResponse = jobResponse;
        vm.siteResponse = siteResponse;

        if (vm.siteResponse) {
            vm.selectedSiteResponse = vm.siteResponse;
        }

        vm.create = createAction;

        function createAction () {
            delete vm.data._links.site;

            return apiClient.create(vm.jobResponse.getLink('files'), vm.data).then(function (response) {
                if (response) {
                    $state.go('^', {}, { reload: $state.get('^') }).then(function () {
                        messenger.success('FILE_CREATED');
                    });
                } else {
                    messenger.error('REQUEST_ERROR');
                }
            });
        }

        $scope.$watch('vm.uploadedFile', function (newValue) {
            vm.data._links.file = null;

            if (newValue) {
                vm.data._links.file = vm.uploadedFile._links.file;
            }
        });

        var siteCache = {};
        $scope.$watch('vm.data._links.site', function (newValue) {
            if (newValue) {

                if (siteCache[newValue.href]) {
                    vm.selectedSiteResponse = siteCache[newValue.href];
                    return;
                }

                apiClient.get(newValue.href).then(function (response) {
                    siteCache[newValue.href] = vm.selectedSiteResponse = response || apiClient.noResourceFound();
                });
            }
        });
    }
})();
