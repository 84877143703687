(function () {
    'use strict';

    angular
        .module('elogbooks.common.files')
        .controller('DocumentTypeAddController', DocumentTypeAddController);

    DocumentTypeAddController.$inject = [
        '$scope',
        '$state',
        '$stateParams',
        'lodash',
        'apiClient',
        'messenger',
        'documentTypeCollectionResponse',
        'siteDocumentTypeCollection',
        'siteResponse',
        'fileDocumentTypeService'
    ];

    function DocumentTypeAddController (
        $scope,
        $state,
        $stateParams,
        lodash,
        apiClient,
        messenger,
        documentTypeCollectionResponse,
        siteDocumentTypeCollection,
        siteResponse,
        fileDocumentTypeService
    ) {
        var vm = this;
        vm.documentTypeCollectionResponse = documentTypeCollectionResponse;
        vm.siteDocumentTypes = siteDocumentTypeCollection.documentTypes;
        vm.siteResponse = siteResponse;
        vm.create = createAction;

        vm.duplicatedDocumentTypes = [];

        if (documentTypeCollectionResponse) {
            vm.documentTypesSelect = {
                response: documentTypeCollectionResponse,
                link: documentTypeCollectionResponse.getLink('self'),
                linkParameters: {status: 'active', excludeSiteTypes: true, site: siteResponse.id},
                responseKeyPath: 'documentTypes',
                itemValuePath: 'name',
                required: true
            };
        }

        $scope.$watch('vm.documentTypesSelect.selectedItems', checkDocumentTypes);

        function createAction() {
            if (vm.duplicatedDocumentTypes.length > 0 ) {
                return messenger.error('DUPLICATE_DOCUMENT_TYPES_EXIST');
            }

            var data = {
                _links: {}
            };

            if (vm.documentTypesSelect.selectedItems.length > 0 ) {
                data._links.documenttype = vm.documentTypesSelect.selectedItems;
            }

            var items = {
                types:[]
            };

            lodash.forEach(vm.documentTypesSelect.selectedItems, function(item) {
                var data = {
                    _links: {
                        documenttype:{href: item.href}
                    }
                };
                items.types.push(data);
            });

            apiClient.create(vm.documentTypeCollectionResponse.getLink('create-document-compliance'), items).then(function (response) {
                if (response) {
                    $state.go('^', $stateParams, { reload: $state.$current.parent.parent.parent.self.name }).then(function () {
                        messenger.success('SITE_DOCUMENT_COMPLIANCE_CREATED');
                    });
                } else {
                    messenger.error('REQUEST_ERROR');
                }
            });
        }

        // Check if selected document type has already been selected
        function checkDocumentTypes() {
            if (vm.documentTypesSelect.selectedItems) {
                vm.duplicatedDocumentTypes = fileDocumentTypeService.checkForDuplicates(
                    vm.documentTypesSelect.selectedItems,
                    vm.siteDocumentTypes
                )
            }
        }
    }
})();
