(function () {
    'use strict';

    angular
        .module('elogbooks.admin.job-requisites')
        .controller('JobRequisitesServiceProvidersEditController', JobRequisitesServiceProvidersEditController);

    JobRequisitesServiceProvidersEditController.$inject = [
        '$state',
        '$stateParams',
        'messenger',
        'apiClient',
        'lodash',
        'jobRequisiteResponse',
        'selectedServiceProvidersCollectionResponse',
        'serviceProvidersCollectionResponse'
    ];

    function JobRequisitesServiceProvidersEditController (
        $state,
        $stateParams,
        messenger,
        apiClient,
        lodash,
        jobRequisiteResponse,
        selectedServiceProvidersCollectionResponse,
        serviceProvidersCollectionResponse
    ) {
        var vm = this;
        vm.jobRequisite = jobRequisiteResponse;
        vm.multiSelect = {
            link: '/serviceproviders',
            responseKeyPath: 'serviceproviders',
            items: lodash.map(lodash.differenceBy(serviceProvidersCollectionResponse.serviceproviders, selectedServiceProvidersCollectionResponse.serviceproviders, 'id'), function(serviceprovider) {
                return {href: serviceprovider.getLink('self'), value: serviceprovider.name};
            }),
            selectedItems: [],
            allTitle: 'ALL_SERVICE_PROVIDERS',
            selectedTitle: 'SELECTED_SERVICE_PROVIDERS',
            searchParams: { status: 'active' }
        };

        vm.selectedServiceProvider = selectedServiceProvidersCollectionResponse.serviceproviders;
        if(vm.selectedServiceProvider) {
            angular.forEach(vm.selectedServiceProvider, function(serviceProvider) {
                vm.multiSelect.selectedItems.push({href: serviceProvider.getLink('self'), value: serviceProvider.name});
            });
        }


        vm.pageTitle = 'JOB_REQUISITE_SERVICEPROVIDER_EDIT';
        vm.update = updateAction;

        function updateAction() {
            var data = {
                _links: {}
            };

            if (vm.multiSelect.selectedItems.length) {
                data._links.serviceproviders = vm.multiSelect.selectedItems;
            }

            apiClient.create(vm.jobRequisite.getLink('serviceproviders'), data).then(function(response){
                if (response) {
                    $state.go('^', $stateParams, { reload: true }).then(function () {
                        messenger.success('JOB_REQUISITE_SERVICEPROVIDER_ADDED');
                    });
                } else {
                    messenger.error('REQUEST_ERROR');
                }
            });
        }
    }

})();
