(function () {
    'use strict';

    angular
        .module('elogbooks.user.spillages')
        .controller('CommonSpillagesDetailsEventFeedController', CommonSpillagesDetailsEventFeedController);

    CommonSpillagesDetailsEventFeedController.$inject = ['eventCollectionResponse', 'messageCollectionResponse', 'config', 'userManager', 'spillageResponse'];

    function CommonSpillagesDetailsEventFeedController (eventCollectionResponse, messageCollectionResponse, config, userManager, spillageResponse) {
        var vm = this;
            vm.feed = [];
            vm.messageCollectionResponse = messageCollectionResponse;
            vm.eventCollectionResponse = eventCollectionResponse;
            vm.config = config;
            vm.entityResponse = spillageResponse;
    }
})();
