(function () {
    'use strict';

    angular
        .module('elogbooks.admin.document-types-management')
        .controller('DocumentTypeAddEditController', DocumentTypeAddEditController);

    DocumentTypeAddEditController.$inject = [
        '$state',
        'apiClient',
        'confirmationModal',
        'documentTypeResponse',
        'requestDataFactory',
        'messenger',
        'documentTypeCollectionResponse',
        'statutoryTypesCollectionResponse',
        '$translate'
    ];

    function DocumentTypeAddEditController (
        $state,
        apiClient,
        confirmationModal,
        documentTypeResponse,
        requestDataFactory,
        messenger,
        documentTypeCollectionResponse,
        statutoryTypesCollectionResponse,
        $translate
    ) {
        var vm = this;
        vm.documentType = documentTypeResponse;
        vm.statutoryTypes = statutoryTypesCollectionResponse.statutorytypes;
        vm.data = requestDataFactory.createRequest(getDefaults());

        if (typeof documentTypeResponse._links !== 'undefined') {
            vm.data._links.statutorytype = documentTypeResponse._links.statutorytype;
        }

        if (vm.data.frequency) {
            vm.data.frequency = vm.data.frequency.toLowerCase();
        }

        vm.frequencyOptions =   {
            weekly: {value: "weekly", title: $translate.instant("WEEKLY")},
            monthly: {value: "monthly", title: $translate.instant("MONTHLY")},
            yearly: {value: "yearly", title: $translate.instant("YEARLY")}
        };

        vm.create = createAction;
        vm.update = updateAction;
        vm.delete = deleteAction;

        vm.statusOptions = [
            { value: true, title: $translate.instant('STATUS_ACTIVE') },
            { value: false, title: $translate.instant('STATUS_INACTIVE')},
        ];

        vm.criticalityOptions = [
            { value: 'high', title: $translate.instant('CRITICALITY_HIGH') },
            { value: 'medium', title: $translate.instant('CRITICALITY_MEDIUM')},
            { value: 'low', title: $translate.instant('CRITICALITY_LOW')},
        ];

        function getDefaults() {
            return {
                id: vm.documentType.id || null,
                name: vm.documentType.name || null,
                description: vm.documentType.description || null,
                criticality: vm.documentType.criticality || null,
                active: typeof vm.documentType.active === 'undefined' ? true : vm.documentType.active,
                isStatutory: vm.documentType.isStatutory ||false,
                approvalRequired: vm.documentType.approvalRequired || false,
                interval: vm.documentType.interval || null,
                frequency: vm.documentType.frequency || null
            };
        }

        function createAction () {
            return apiClient.create(documentTypeCollectionResponse.getLink('create'), vm.data).then(function (response) {
                if (response) {
                    $state.go('^', {}, { reload: $state.current.parent }).then(function () {
                        messenger.success('DOCUMENT_TYPE_CREATED');
                    });
                } else {
                    messenger.error('REQUEST_ERROR');
                }
            });
        }

        function updateAction () {
            return apiClient.update(vm.documentType.getLink('edit'), vm.data).then(function (response) {
                if (response) {
                    $state.go('^', {}, { reload: 'dashboard.admin.document-types-management.document-types' }).then(function () {
                        messenger.success('DOCUMENT_TYPE_UPDATED');
                    });
                } else {
                    messenger.error('REQUEST_ERROR');
                }
            });
        }

        function deleteAction () {
            return confirmationModal.open().result.then(function () {
                return apiClient.delete(vm.documentType.getLink('delete')).then(function (response) {
                    if (response) {
                        $state.go('dashboard.admin.document-types-management.document-types', {}, { reload: 'dashboard.admin.document-types-management.document-types' })
                            .then(function () {
                            messenger.success('DOCUMENT_TYPE_DELETED');
                        });
                    } else {
                        messenger.error('REQUEST_ERROR');
                    }
                });
            });
        }
    }
})();
