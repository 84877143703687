(function () {
    'use strict';

    angular
        .module('elogbooks.common.jobs.workflow')
        .controller('JobOperationRequisitesController', JobOperationRequisitesController);

    JobOperationRequisitesController.$inject = ['apiClient', '$state', 'messenger', 'jobResponse', '$translate', 'lodash', 'jobRequisitesResponse', 'jobRequisiteAnswersResponse'];

    function JobOperationRequisitesController (apiClient, $state, messenger, jobResponse, $translate, lodash, jobRequisitesResponse, jobRequisiteAnswersResponse) {
        var vm = this;
        vm.job = jobResponse;
        vm.submit = submitAction;
        vm.partial = 'requisites';
        vm.jobRequisites = jobRequisitesResponse.jobRequisites;
        vm.jobRequisiteAnswers = jobRequisiteAnswersResponse["job-requisites-answers"];
        vm.uploadedFile = null;

        vm.data = {};
        vm.data.jobRequisiteAnswers = [];
        vm.formStatuses = [
            'OPEN',
            'STATUS_IN_PROGRESS',
            'STATUS_COMPLETED'
        ];

        lodash.each(vm.jobRequisites, function (jobRequisite) {

            var answer = null;

            // find existing answer, populate fields
            lodash.each(vm.jobRequisiteAnswers, function (existingAnswer) {
                if(existingAnswer._links.jobrequisite.href === jobRequisite._links.self.href) {
                    answer = existingAnswer;
                }
            });

            var jobRequisiteAnswer = {
                _links: {
                    jobRequisite: {href: jobRequisite._links.self.href}
                },
                files: {
                    links: lodash.has(answer, 'files.files') ? answer.files.files : [],
                },
                answeredYes: lodash.has(answer, 'answeredYes') ? answer.answeredYes : false,
                notApplicable: lodash.has(answer, 'notApplicable') ? answer.notApplicable : false,
            };

            if (lodash.has(answer, '_links.jobform')) {
                jobRequisiteAnswer._links.jobForm = answer._links.jobform;
            }

            vm.data.jobRequisiteAnswers.push(jobRequisiteAnswer);

        });

        vm.yesno = [{value: false, title: $translate.instant('NO'),}, {value: true, title: $translate.instant('YES'),}];

        function submitAction () {

            return apiClient.update(vm.job.getLink('edit-job-requisites-answers'), vm.data).then(function (response) {
                if (response) {
                    return $state.go('^.^', {}, { reload: $state.get('^.^.^') }).then(function () {
                        messenger.success('JOB_REQUISITES_UPDATED');
                    });
                }

                messenger.error('REQUEST_ERROR');
            });
        }
    }
})();
