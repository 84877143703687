(function () {
    'use strict';

    angular
        .module('elogbooksFilters')
        .filter('months', months);

    function months ($filter) {
        var months = {
            1: 'JANUARY',
            'January': 'JANUARY',
            2: 'FEBRUARY',
            'February': 'FEBRUARY',
            3: 'MARCH',
            'March': 'MARCH',
            4: 'APRIL',
            'April': 'APRIL',
            5: 'MAY',
            'May': 'MAY',
            6: 'JUNE',
            'June': 'JUNE',
            7: 'JULY',
            'July': 'JULY',
            8: 'AUGUST',
            'August': 'AUGUST',
            9: 'SEPTEMBER',
            'September': 'SEPTEMBER',
            10: 'OCTOBER',
            'October': 'OCTOBER',
            11: 'NOVEMBER',
            'November': 'NOVEMBER',
            12: 'DECEMBER',
            'December': 'DECEMBER',
        };

        return function (input) {
            return $filter('translate')(months[input]);
        }
    };
})();
