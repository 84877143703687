(function () {
    'use strict';

    angular
        .module('elogbooks.admin.users')
        .controller('UserTokenAddController', UserTokenAddController);

    UserTokenAddController.$inject = ['apiClient', 'messenger', 'userResponse', '$state'];

    function UserTokenAddController(apiClient, messenger, userResponse, $state) {
        var vm = this;
        vm.data = {};
        vm.create = createAction;

        function createAction () {
            apiClient.create(userResponse.getLink('token'), vm.data).then(function (response) {
                if (response) {
                    $state.go('^', { resource: userResponse.getLink('self').encode() }, { reload: $state.current.parent }).then(function () {
                        messenger.success('TOKEN_CREATED');
                    });
                } else {
                    messenger.error('REQUEST_ERROR');
                }
            });
        }
    }
})();
