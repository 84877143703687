angular.module('elogbooksDirectives').directive('jobGroup', ['apiClient', 'lodash', 'NestedArrayTransformer', function (apiClient, lodash, NestedArrayTransformer) {
    return {
        require: ['ngModel'],
        scope: {
            group: '=ngModel'
        },
        templateUrl: '/modules/directives/form-elements/job-group/job-group.html',
        link: function(scope){
            scope.options = [];
            apiClient.get('/jobgroups?page=1&limit=999999').then(function(Response) {
                var jobgroups = NestedArrayTransformer.transform(Response.getData().jobgroups);
                scope.options = extractOptions(jobgroups);
            });
            var extractOptions = function(grouplist, parentName) {
                var elements = [];
                if (parentName == undefined) {
                    parentName = '';
                }
                lodash.each(grouplist, function (element) {
                    element.name = parentName + element.name;
                    elements.push({
                        id: element.id,
                        name: element.name,
                        reference: element.reference,
                        href: element._links.self.href
                    });
                    if (element.children) {
                        elements = elements.concat(extractOptions(element.children, element.name + ' / '));
                    }
                })
                return elements;
            }
        }
    }
}]);
