(function (){
    'use strict';

    angular
        .module('elogbooks.common.audit')
        .controller('AuditQuestionAddEditController', AuditQuestionAddEditController);

    AuditQuestionAddEditController.$inject = [
        '$state',
        'questionResponse',
        'sectionResponse',
        'questionsCollectionResponse',
        'confirmationModal',
        'answerOptionsResponse',
        'apiClient',
        'messenger',
        'requestDataFactory',
        '$filter',
        '$scope',
        'rootResourceResponse',
        '$uibModal',
        'config'
    ];

    function AuditQuestionAddEditController(
        $state,
        questionResponse,
        sectionResponse,
        questionsCollectionResponse,
        confirmationModal,
        answerOptionsResponse,
        apiClient,
        messenger,
        requestDataFactory,
        $filter,
        $scope,
        rootResourceResponse,
        $uibModal,
        config
    ) {
        var vm = this;



        vm.create = createAction;
        vm.update = updateAction;
        vm.delete = deleteAction;
        vm.data = requestDataFactory.createRequest(getDefaults(questionResponse, sectionResponse));
        vm.questionResponse = questionResponse;
        vm.questionsCollectionResponse = questionsCollectionResponse;
        vm.section = sectionResponse;
        vm.answerOptionsResponse = answerOptionsResponse;
        vm.selectedAnswerTemplateModel = {};
        vm.config = config;
        vm.busy = false;
        vm.createAnswerOption = createAnswerOption;

        vm.changeAnswerTemplates = changeAnswerTemplates;

        $scope.$watch('vm.data.type', function() {
            vm.answerOptions = [];
            vm.selectedAnswerTemplateModel.selected = null;

            if (vm.data.type === 'buttons' || vm.data.type === 'options') {
                loadAnswerTemplates(vm.data.type);
            }
        });

        vm.questionTypes = [
                {value: 'comment', title: $filter('translate')('AUDIT_QUESTION_COMMENT')},
                {value: 'options', title: $filter('translate')('AUDIT_QUESTION_OPTIONS')},
                {value: 'buttons', title: $filter('translate')('AUDIT_QUESTION_BUTTONS')}
        ];

        vm.yesNo = [
                {value: false, title: $filter('translate')('NO')},
                {value: true, title: $filter('translate')('YES')}
        ];

        vm.forceRaiseOptions = [
            {value: 0, title: $filter('translate')('NONE')},
            {value: 1, title: $filter('translate')('REQUIRE_ACTION')},
            {value: 2, title: $filter('translate')('REQUIRE_JOB')},
            {value: 3, title: $filter('translate')('REQUIRE_EITHER')}
        ];

        vm.weightingOptions = [
                {value: 1, title: '1'},
                {value: 2, title: '2'},
                {value: 3, title: '3'},
                {value: 4, title: '4'},
                {value: 5, title: '5'},
        ];

        function createAction () {
            vm.busy = true;

            if (vm.selectedAnswerTemplateModel.selected) {
                vm.data._links.answerTemplate = vm.selectedAnswerTemplateModel.selected.object._links.self;
            }
            apiClient.create(vm.questionsCollectionResponse.getLink('create'), vm.data).then(function (response) {
                if (response) {
                    $state.go('^', { questionResource: response.getLink('self').encode() }, { reload: $state.get('^.^.^') }).then(function () {
                        messenger.success('AUDIT_QUESTION_CREATED');
                    });
                } else {
                    vm.busy = false;
                    messenger.error('REQUEST_ERROR');
                }
            });
        };

        function updateAction () {
            vm.busy = true;

            apiClient.update(vm.questionResponse._links.self.href, vm.data).then(function (response) {
                vm.busy = true;
                if (response) {
                    $state.go('^', {}, { reload: $state.get('^.^') }).then(function () {
                        messenger.success('AUDIT_QUESTION_UPDATED');
                    });
                } else {
                    vm.busy = false;
                    messenger.error('REQUEST_ERROR');
                }
            });
        };

        function deleteAction() {
            return confirmationModal.open().result.then(function () {
                return apiClient.delete(vm.questionResponse.getLink('delete')).then(function (response) {
                    if (response) {
                        $state.go('^.^', {}, { reload: $state.get('^.^.^.^') }).then(function () {
                            messenger.success('AUDIT_QUESTION_DELETED');
                        });
                    } else {
                        messenger.error('REQUEST_ERROR');
                    }
                });
            });
        }

        function getDefaults(entity, sectionResponse) {
            return {
                _links: {
                    section: sectionResponse._links.self.href
                },
                id: entity.id || null,
                summary: entity.summary || null,
                description: entity.description || null,
                type: entity.type || null,
                attachmentRequired: typeof entity.attachmentRequired === 'undefined' ? false : entity.attachmentRequired,
                allowNotApplicable: typeof entity.allowNotApplicable === 'undefined' ? true : entity.allowNotApplicable,
                defaultAnswerOptions: 'none',
                weighting: typeof entity.weighting === 'undefined' ? 1 : entity.weighting,
                forceRaise: typeof entity.forceRaise === 'undefined' ? 1 : entity.forceRaise
            };
        }

        function changeAnswerTemplates(answerTemplate) {
            if (answerTemplate.selected) {
                apiClient.get(answerTemplate.selected.object.getLink('answer-options')).then(function (response) {
                    if (response) {
                        vm.answerOptions = response.answerOptionTemplates;
                    }
                });
            } else {
                vm.answerOptions = [];
            }
        }

        function loadAnswerTemplates(type) {
            var params = {
                type: type,
                status: 'active',
                noEmpty: true
            };

            return apiClient.get(rootResourceResponse.getLink('audit-answer-templates'), params).then(function (response) {
                if (response) {
                    vm.selectedAnswerTemplateModel = {
                        response: response,
                        link: response.getLink('self'),
                        responseKeyPath: 'answerTemplates',
                        itemHrefPath: '_links.self.href',
                        itemValuePath: 'name',
                        onSelect: vm.changeAnswerTemplates,
                        onRemove: vm.changeAnswerTemplates
                    };
                }
            });
        }

        function createAnswerOption() {
            var params = {
                status: 'active',
                noEmpty: true
            };
            var modalInstance = $uibModal.open({
                templateUrl: '/modules/common/modals/audits/answer-option-template/answer-option-template-add-modal.html',
                controller: 'AnswerOptionTemplateAddModalController',
                controllerAs: 'vm',
                resolve: {
                    answerTemplateCollectionResponse: function () {
                        return apiClient.get(rootResourceResponse.getLink('audit-answer-templates'), params, {
                        });
                    },
                    optionType: function() {
                        return vm.data.type;
                    }
                },
            });
            modalInstance.result.then(function (response) {
                vm.selectedAnswerTemplateModel.selected = {
                    object: response,
                    value: response.name
                };
                changeAnswerTemplates(vm.selectedAnswerTemplateModel)
            });
        }
    }
})();
