angular.module('elogbooksDirectives').directive('jobReassignOperativeSelectAbsenceWarning', [function(){
    return {
        restrict: 'AE',
        require: ['ngModel'],
        scope: {
            selectedOperative: '=ngModel',
            priorityModel: '=',
        },
        templateUrl: '/modules/directives/job-reassign-operative-absence-warning/operative-select.html',
        controller: function ($scope) {
            $scope.absences = null;
            $scope.hideAbsences = false;

            var getAbsences = function(value) {
                var operatives = $scope.selectedOperative.response.operatives;
                var selectedLink = value.href;
                var operative = null;

                for (var i in operatives) {
                    var link = operatives[i]._links.self.href;

                    if (link === selectedLink) {
                        operative = operatives[i];
                        break;
                    }
                }

                if (!operative) {
                    return null;
                }

                if (operative.hasOwnProperty('operativeAbsences')
                    && operative.operativeAbsences.hasOwnProperty('absenceEntries')
                    && operative.operativeAbsences.absenceEntries.length) {
                    return operative.operativeAbsences.absenceEntries;
                } else {
                    return null;
                }
            };

            $scope.$watch('selectedOperative.selected', function(value) {
                $scope.absences = null;

                if (value && typeof value !== 'undefined' && value.href) {
                    $scope.absences = getAbsences(value);
                }
            });

            // Hide absences if priority is changed
            if ($scope.priorityModel) {
                var preSelectedPriority = $scope.priorityModel.selected && $scope.priorityModel.selected.href ?
                    $scope.priorityModel.selected.href : null;

                $scope.$watch('priorityModel.selected', function(value) {
                    var selected = value ? value.href : null;
                    $scope.hideAbsences = selected !== preSelectedPriority;
                });
            }
        }
    }
}]);
