(function() {
    'use strict';

    angular
        .module('elogbooks')
        .config(Translations);

    function Translations($translateProvider) {
        $translateProvider.translations('zh-hk', {
            ESCALATION: '上報',
            ESCALATIONS: '上報',
            ESCALATION_LIST: '上報',

            ESCALATION_ADD: '新增上報',
            ESCALATION_EDIT: '修改上報',

            ESCALATION_CREATED: '上報已建立',
            ESCALATION_UPDATED: '上報已更新',
            ESCALATION_DELETED: '上報已刪除',

            ESCALATE: '上報',
            ESCALATED: '上報',
            ESCALATED_AT: '上報於',

            ESCALATION_DETAILS: '上報 {{ id }} 詳細資料',

            ESCALATION_STATUS: '狀態',

            DEESCALATED: '上報已移除',
        });
    }
})();

app.config(function ($translateProvider) {
    $translateProvider.translations('zh-hk', {


        ESCALATION_SUCCESS: '上報成功',
    });
});
