(function() {
    'use strict';

    angular
        .module('elogbooks.admin.service-providers')
        .controller('ServiceProviderPartnershipsListController', ServiceProviderPartnershipsListController);

    ServiceProviderPartnershipsListController.$inject = [
        '$stateParams',
        '$state',
        'lodash',
        '$translate',
        'partnershipsResponse'
    ];

    function ServiceProviderPartnershipsListController(
        $stateParams,
        $state,
        lodash,
        $translate,
        partnershipsResponse
    ) {
        var vm = this;
        vm.partnerships = partnershipsResponse.partnerships;
        vm.partnershipsResponse = partnershipsResponse;
        vm.partnerships._links = partnershipsResponse._links;
        vm.entityCount = partnershipsResponse.count;
        vm.partnerships.canCreate = lodash.has(partnershipsResponse, '_links.createServiceProviderPartnership');

        var partnershipStatusOptions = [
            {title: $translate.instant('ALL'), value: null},
            {title: $translate.instant('STATUS_ACTIVE'), value: '1'},
            {title: $translate.instant('STATUS_INACTIVE'), value: '0'}
        ];

        var siteStatusOptions = [
            {title: $translate.instant('NONE_SELECTED'), value: null},
            {title: $translate.instant('STATUS_ACTIVE'), value: 'active'},
            {title: $translate.instant('STATUS_INACTIVE'), value: 'inactive'}
        ];

        vm.criteria = {
            site: {type: 'text', title: 'SITE_NAME', value: $stateParams.site},
            siteStatus: {
                type: 'options',
                title: 'SITE_STATUS',
                value: $stateParams.siteStatus,
                options: siteStatusOptions,
                clearValue: null
            },
            partnershipActive: {
                type: 'options',
                title: 'PARTNERSHIP_STATUS',
                value: $stateParams.partnershipActive,
                options: partnershipStatusOptions,
                clearValue: null
            }
        };

        vm.search = searchAction;
        vm.order = orderAction;

        function searchAction(params) {
            var override = {
                partnershipPage: 1
            };

            $state.go('.', angular.extend({}, params, override), { reload: $state.current });
        }

        function orderAction(key) {

            $stateParams.partnershipOrder = key;

            var override = {
                partnershipPage: 1
            };

            $state.go('.', angular.extend({}, $stateParams, override), { reload: $state.current });
        }
    }
})();
