(function () {
    'use strict';

    angular
        .module('elogbooks.common.sites')
        .controller('CommonSiteNotesEditController', CommonSiteNotesEditController);

    CommonSiteNotesEditController.$inject = [
        '$rootScope',
        '$state',
        'noteCollectionResponse',
        'siteNoteTypeCollectionResponse',
        'siteResponse',
        'apiClient',
        'messenger',
        'userManager',
        '$q'
    ];

    function CommonSiteNotesEditController (
        $rootScope,
        $state,
        noteCollectionResponse,
        siteNoteTypeCollectionResponse,
        siteResponse,
        apiClient,
        messenger,
        userManager,
        $q
    ) {
        var vm = this;
            vm.siteNoteTypeCollectionResponse = siteNoteTypeCollectionResponse;
            vm.noteCollectionResponse = noteCollectionResponse;
            vm.siteNoteTypes = vm.siteNoteTypeCollectionResponse.sitenotetypes;
            vm.options = {
                versionCheck: false,
                language: userManager.getPreferenceValue('locale'),
                allowedContent: false,
                extraAllowedContent: false,
                entities: false,
                toolbarGroups : [
                    {name: 'basicstyles', groups: [ 'basicstyles', 'cleanup', 'list', 'indent', 'blocks', 'align', 'bidi', 'paragraph' ]}
                ],
                removeButtons : 'Underline,Subscript,Superscript,Paste,PasteText,PasteFromWord,Scayt,Anchor,Image,Styles,Format,About'
            };

        vm.updateDisabled = false;
        $rootScope.forceDirtyConfirm = false;
        $rootScope.skipDirtyCheck = true;

        angular.forEach(vm.siteNoteTypeCollectionResponse.sitenotetypes, function (siteNoteType) {
            siteNoteType.siteNote = null;

            angular.forEach(vm.noteCollectionResponse.siteNotes, function (siteNote) {
               if (siteNoteType.getLink('self') === siteNote.getLink('siteNoteType')) {
                siteNoteType.siteNote = siteNote;
                   siteNoteType.siteNote.original = siteNote.data;
               }
            });

            if (siteNoteType.siteNote === null) {
                siteNoteType.siteNote = {
                    data: '',
                    original: '',
                    _links: {
                        site: { href: siteResponse.getLink('self') },
                        siteNoteType: { href: siteNoteType.getLink('self') }
                    }
                };
            }
        });

        function updateAction(siteNote) {
            return apiClient.update(siteNote.getLink('edit'), angular.copy(siteNote));
        }

        function createAction(siteNote) {
            return apiClient.create(noteCollectionResponse.getLink('create'), angular.copy(siteNote));
        }

        vm.updateAll = function () {
            vm.updateDisabled = true;
            var requests = [];

            for (var i in siteNoteTypeCollectionResponse.sitenotetypes) {
                if (!siteNoteTypeCollectionResponse.sitenotetypes.hasOwnProperty(i)) {
                    continue;
                }

                var noteType = siteNoteTypeCollectionResponse.sitenotetypes[i];
                var note = noteType.siteNote;

                if (note.data.replace(/\n$/, '') !== note.original.replace(/\n$/, '')) {
                    if (note.hasOwnProperty('id')) {
                        requests.push(updateAction(note));
                    } else if (note.hasOwnProperty('data') && note.data.trim()) {
                        requests.push(createAction(note));
                    }
                }
            }

            $q.all(requests).then(function() {
                $rootScope.forceDirtyConfirm = false;
                $rootScope.skipDirtyCheck = true;
                $state.go('.^', {}, { reload: $state.get('^.^') }).then(function () {
                    messenger.success('ALL_SITE_NOTE_TYPES_UPDATED');
                    vm.updateDisabled = false;
                });
            }, function() {
                messenger.error('REQUEST_ERROR');
                vm.updateDisabled = false;
            });
        };

        vm.cancel = function () {
            $state.go('.^', {}, { reload: $state.get('^.^') });
        };

        var isNoteUpdated = function() {
            var stripHtmlTags = function(text) {
                var tag = document.createElement('span');
                tag.innerHTML = text;
                text = tag.textContent || tag.innerText || '';
                return text.trim().replace(/<\/?[^>]+(>|$)/g, '');
            };

            for (var i in siteNoteTypeCollectionResponse.sitenotetypes) {
                var noteType = siteNoteTypeCollectionResponse.sitenotetypes[i];
                var note = noteType.siteNote;

                if (stripHtmlTags(note.data) !== stripHtmlTags(note.original)) {

                    isNoteUpdated = function () {
                        return true;
                    };

                    return true;
                }
            }
        };

        vm.changeData = function() {
            if (isNoteUpdated()) {
                $rootScope.forceDirtyConfirm = true;
                $rootScope.skipDirtyCheck = false;
                vm.changeData = null;
                vm.checkboxChanged = null;
            }
        };

        vm.checkboxChanged = function() {
            $rootScope.forceDirtyConfirm = true;
            $rootScope.skipDirtyCheck = false;
            vm.changeData = null;
            vm.checkboxChanged = null;
        };
    }
})();
