app.config(function ($translateProvider) {
   $translateProvider.translations('en-gb', {

        QUOTE_THRESHOLD: 'Quote Threshold',
        QUOTE_THRESHOLDS: 'Quote Thresholds',

        QUOTE_THRESHOLD_ADD: 'Add a new Quote Threshold',
        QUOTE_THRESHOLD_EDIT: 'Edit Quote Threshold',
        QUOTE_THRESHOLD_LIST: 'Quote Thresholds',

        FEWER_APPROVERS_THAN_THRESHOLDS: 'More Quote approvers are required to cover all Quote Thresholds'
   })
});
