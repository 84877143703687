(function () {
    'use strict';

    angular
        .module('elogbooks.admin.service-provider-groups')
        .config(registerRoutes)
        .config(registerRoutesList)
        .config(registerRoutesAdd)
        .config(registerRoutesDetails)
        .config(registerRoutesEdit);

    function registerRoutes ($stateProvider) {
        $stateProvider
            .state('dashboard.admin.service-provider-groups', {
                abstract: true,
                templateUrl: 'shared-templates/view.html',
                ncyBreadcrumb: {
                    skip: true
                },
                data: {
                    permissions: ['user_permission_view_service_provider_groups']
                }
            });
    }

    function registerRoutesList ($stateProvider) {
        $stateProvider
            .state('dashboard.admin.service-provider-groups.list', {
                url: '/service-provider-groups?page&limit&order&id&name',
                parent: 'dashboard.admin.service-provider-groups',
                data: {
                    permissions: ['user_permission_view_service_provider_groups']
                },
                views: {
                    '@dashboard.admin.service-provider-groups': {
                        templateUrl: '/modules/admin/service-provider-groups/service-provider-groups-list.html',
                        controller: 'ServiceProviderGroupsListController',
                        controllerAs: 'vm',
                    }
                },
                resolve: {
                    serviceProviderGroupCollectionResponse: function ($stateParams, apiClient, rootResourceResponse) {
                        return apiClient.get(rootResourceResponse.getLink('serviceprovidergroups'), $stateParams).then(function (response) {
                            return response || apiClient.noResourceFound();
                        });
                    }
                },
                params: {
                    page: '1',
                    limit: '30',
                    order: 'id',
                    id: null,
                    name: null,
                },
                ncyBreadcrumb: {
                    label: '{{ ::"SERVICE_PROVIDER_GROUPS" | translate }}'
                }
            });
    }

    function registerRoutesAdd ($stateProvider) {
        $stateProvider
            .state('dashboard.admin.service-provider-groups.list.add', {
                url: '/add',
                parent: 'dashboard.admin.service-provider-groups.list',
                data: {
                    permissions: ['user_permission_manage_service_provider_groups']
                },
                views: {
                    '@dashboard.admin.service-provider-groups': {
                        templateUrl: '/modules/admin/service-provider-groups/add-edit/service-provider-groups-form.html',
                        controller: 'ServiceProviderGroupsAddEditController',
                        controllerAs: 'vm',
                    }
                },
                resolve: {
                    serviceProviderGroupsResponse: function () {
                        return {};
                    }
                },
                ncyBreadcrumb: {
                    label: '{{ ::"ADD" | translate }}'
                }
            });
    }

    function registerRoutesDetails ($stateProvider) {
        $stateProvider
            .state('dashboard.admin.service-provider-groups.list.details', {
                url: '/details/{resource}',
                abstract: true,
                parent: 'dashboard.admin.service-provider-groups.list',
                data: {
                    permissions: ['user_permission_view_service_provider_groups']
                },
                views: {
                    '@dashboard.admin.service-provider-groups': {
                        templateUrl: '/modules/admin/service-provider-groups/details/service-provider-groups-details.html',
                        controller: 'ServiceProviderGroupsDetailsController',
                        controllerAs: 'vm',
                    }
                },
                resolve: {
                    serviceProviderGroupsResponse: function (apiClient, $stateParams) {
                        return apiClient.get($stateParams.resource.decode(), {"datagroup": "details"}).then(function (response) {
                            return response || apiClient.noResourceFound();
                        });
                    }
                },
                ncyBreadcrumb: {
                    label: '{{ ::$resolves().serviceProviderGroupsResponse.name }}'
                }
            })
            .state('dashboard.admin.service-provider-groups.list.details.info', {
                url: '/info',
                parent: 'dashboard.admin.service-provider-groups.list.details',
                data: {
                    permissions: ['user_permission_view_service_provider_groups']
                },
                views: {
                    '@dashboard.admin.service-provider-groups.list.details': {
                        templateUrl: '/modules/admin/service-provider-groups/details/info/service-provider-groups-info.html',
                        controller: 'ServiceProviderGroupsInfoController',
                        controllerAs: 'vm',
                    }
                },
                ncyBreadcrumb: {
                    label: '{{ ::"INFO" | translate }}'
                }
            })
            .state('dashboard.admin.service-provider-groups.list.details.service-providers', {
                url: '/service-providers',
                parent: 'dashboard.admin.service-provider-groups.list.details',
                data: {
                    permissions: ['user_permission_view_service_provider_groups']
                },
                views: {
                    '@dashboard.admin.service-provider-groups.list.details': {
                        templateUrl: '/modules/admin/service-provider-groups/details/service-providers/service-providers.html',
                        controller: 'AdminServiceProviderGroupServiceProvidersController',
                        controllerAs: 'vm',
                    }
                },
                resolve: {
                    serviceProvidersCollectionResponse: function ($stateParams, apiClient, serviceProviderGroupsResponse) {
                        return apiClient.get(serviceProviderGroupsResponse.getLink('serviceproviders'), $stateParams).then(function (response) {
                            return response || apiClient.noResourceFound();
                        });
                    },
                    rootServiceProvidersCollectionResponse: function ($stateParams, apiClient, rootResourceResponse, serviceProviderGroupsResponse) {
                        var params = angular.extend({}, $stateParams,
                            {
                                excludeServiceProviderGroupId: serviceProviderGroupsResponse.id,
                            }
                        );
                        return apiClient.get(rootResourceResponse.getLink('serviceproviders'), params).then(function (response) {
                            return response || apiClient.noResourceFound();
                        });
                    },
                },
                ncyBreadcrumb: {
                    label: '{{ ::"SERVICE_PROVIDERS" | translate }}'
                }
            })
            .state('dashboard.admin.service-provider-groups.list.details.service-providers.add', {
                url: '/add',
                parent: 'dashboard.admin.service-provider-groups.list.details.service-providers',
                data: {
                    permissions: ['user_permission_manage_service_provider_groups']
                },
                views: {
                    '@dashboard.admin.service-provider-groups.list.details': {
                        templateUrl: '/modules/admin/service-provider-groups/details/service-providers/add/service-providers-add.html',
                        controller: 'ServiceProviderAddController',
                        controllerAs: 'vm'
                    }
                },
                resolve: {
                    rootServiceProvidersCollectionResponse: function ($stateParams, apiClient, rootResourceResponse, serviceProviderGroupsResponse) {
                        var params = angular.extend({}, $stateParams,
                            {
                                excludeServiceProviderGroupId: serviceProviderGroupsResponse.id,
                            }
                        );
                        return apiClient.get(rootResourceResponse.getLink('serviceproviders'), params).then(function (response) {
                            return response || apiClient.noResourceFound();
                        });
                    },
                },
                ncyBreadcrumb: {
                    label: '{{ ::"ADD" | translate }}'
                }
            });
    }

    function registerRoutesEdit ($stateProvider) {
        $stateProvider
            .state('dashboard.admin.service-provider-groups.list.details.info.edit', {
                url: '/edit',
                parent: 'dashboard.admin.service-provider-groups.list.details.info',
                data: {
                    permissions: ['user_permission_manage_service_provider_groups']
                },
                views: {
                    '@dashboard.admin.service-provider-groups.list.details': {
                        templateUrl: '/modules/admin/service-provider-groups/add-edit/service-provider-groups-form.html',
                        controller: 'ServiceProviderGroupsAddEditController',
                        controllerAs: 'vm'
                    }
                },
                ncyBreadcrumb: {
                    label: '{{ ::"EDIT" | translate }}'
                }
            });
    }

})();
