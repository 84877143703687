app.config(function ($translateProvider) {
    $translateProvider.translations('zh-hk', {
        JOB_CONTACT: '工作聯繫人',
        JOB_CONTACTS: '工作聯繫人',

        JOB_CONTACT_LIST: '工作聯繫人',
        JOB_CONTACT_ADD: '新增',

        JOB_CONTACT_CREATED: '工作聯繫人已建立',
        JOB_CONTACT_UPDATED: '工作聯繫人已更新',
        JOB_CONTACT_DELETED: '工作聯繫人已刪除',
    });
});
