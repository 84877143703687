(function(){
    'use strict';

    angular
        .module('elogbooks.admin.service-providers')
        .controller('ServiceProviderOperativesDetailsController', ServiceProviderOperativesDetailsController);

    ServiceProviderOperativesDetailsController.$inject = ['apiClient', 'messenger', 'operativePermissions', 'operativeResponse', '$translate', '$state', 'confirmationModal', 'userManager', 'filterPermissions'];

    function ServiceProviderOperativesDetailsController(apiClient, messenger, operativePermissions, operativeResponse, $translate, $state, confirmationModal, userManager, filterPermissions) {
        var vm = this;
        vm.operative = operativeResponse;
        vm.operative.permissions = vm.operative.permissions ? filterPermissions.filterAllowedPermissions(vm.operative.permissions, operativePermissions.getAll()) : [];
        vm.permissions = operativePermissions.getAllTranslated();
        vm.selectedPermissions = [];
        vm.operativePermissions = [];
        vm.delete = deleteAction;
        angular.forEach(vm.operative.permissions, function (permission, index) {
            addPermission(permission);
        });

        vm.addPermissions = addPermissions;
        vm.removePermission = removePermission;
        vm.update = updateAction;

        function addPermission (permission) {
            addPermissions([{ label: permission }], true, false);
        }

        function addPermissions (permissions, force, showUpdate) {
            angular.forEach(permissions, function (permission, index) {
                var index = vm.operative.permissions.indexOf(permission.label);

                if (index === -1 || force) {

                    var newPermission = {
                        label: permission.label,
                        translation: $translate.instant(permission.label.toUpperCase()),
                        description: $translate.instant(permission.label.toUpperCase() + '_DETAIL')
                    };

                    if (index === -1) {
                        vm.operative.permissions.push(newPermission.label);
                    }

                    vm.operativePermissions.push(newPermission);

                    angular.forEach(vm.permissions, function (permission, index) {
                        if (permission.label === newPermission.label) {
                            vm.permissions.splice(index, 1);
                        }
                    });
                }
            });

            if(showUpdate && permissions.length) {
                updateAction();
            }
            vm.selectedPermissions = [];
        }

        function removePermission (permission) {
            var index = vm.operative.permissions.indexOf(permission);

            if (index > -1) {
                return confirmationModal.open().result.then(
                    function() {
                        // YES was clicked
                        vm.operativePermissions.splice(index, 1);
                        vm.operative.permissions.splice(index, 1);

                        var oldPermission = {
                            label: permission,
                            name: $translate.instant(permission.toUpperCase()),
                            translation: $translate.instant(permission.toUpperCase()),
                            description: $translate.instant(permission.toUpperCase() + '_DETAIL')
                        };
                        vm.permissions.push(oldPermission);
                        updateAction();
                    },
                    function() {
                        // NO was clicked
                    }
                );
            }
        }


        function updateAction () {

            var reloadView = false;

            // if updating the operative permissions for the current user, then reload page to update menu links
            if (vm.operative.getLink('user') === userManager.user.getLink('self')) {
                reloadView = true;
            }

            return apiClient.update(vm.operative.getLink('self'), angular.copy(vm.operative)).then(function (response) {
                if (response) {
                    $state.go('.', {}, { reload: reloadView }).then(function () {
                        messenger.success('PERMISSIONS_UPDATED');
                    });
                } else {
                    messneger.error('REQUEST_ERROR');
                }
            });
        };

        function deleteAction () {
            return confirmationModal.open().result.then(function () {
                return apiClient.delete(vm.operative.getLink('delete')).then(function (response) {
                    if (response) {
                        $state.go('dashboard.admin.service-providers.list.details.operatives', {}, { reload: 'dashboard.admin.service-providers.list.details.operatives' }).then(function () {
                            messenger.success('OPERATIVE_REMOVED');
                        });
                    } else {
                        messenger.error('REQUEST_ERROR');
                    }
                });
            });
        }
    }
})();
