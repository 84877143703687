(function () {
    'use strict';

    angular
        .module('elogbooks.admin.sectors')
        .controller('SectorDetailsController', SectorDetailsController);

    SectorDetailsController.$inject = ['sectorResponse'];

    function SectorDetailsController (sectorResponse) {
        var vm = this;
        vm.sector = sectorResponse;
    }
})();
