(function () {
    "use strict";

    angular
        .module("elogbooks.user.integrations")
        .controller("ProsureController", ProsureController);

    function ProsureController(
        $state,
        $stateParams,
        integrationsService,
        lodash,
        userManager,
        userOperativesCollection,
        userResponse,
        rootResourceResponse,
        elbSettings
    ) {
        var vm = this;
        vm.showLoader = true;
        vm.data = {
            sps: []
        };
        vm.prosureCompany = {};

        vm.userOperativesCollection = userOperativesCollection;
        vm.operatives = lodash.has(userOperativesCollection, "operatives")
            ? userOperativesCollection.operatives
            : [];

        angular.forEach(vm.operatives, function (operative) {
            vm.data.sps.push({
                id: operative._links.serviceprovider.id,
                title: operative._links.serviceprovider.title,
                href: operative._links.serviceprovider.href,
                selected: false
            });
        });

        vm.prosureLink =  elbSettings.getSetting('general_prosure_link').value;
        vm.prosureEnabled = elbSettings.getSetting('prosure_enabled').value;

        vm.cancel = integrationsService.cancel;
        vm.update = updateAction;
        vm.user = userResponse;
        vm.isvalidCompany = false;
        vm.data.encryptedCompanyData = $stateParams.company;

        vm.logout = processLogout;

        if (vm.prosureEnabled === true) {
            var companyDataLink = rootResourceResponse.getLink('prosure-company-data');
            var linkedSpsLink = rootResourceResponse.getLink('prosure-get-linked-sps');
            var putCompanyDataLink = rootResourceResponse.getLink('prosure-put-company-data');

            integrationsService
                .getCompanyDetails(companyDataLink, vm.data.encryptedCompanyData)
                .then(function (response) {
                    vm.prosureCompany = response;
                    vm.isvalidCompany = true;

                    return response;
                })
                .catch(function () {
                    vm.showLoader = false;
                });

            integrationsService
                .getExistingIntegrations(linkedSpsLink, vm.data.encryptedCompanyData)
                .then(function (response) {
                    vm.showLoader = false;

                    angular.forEach(response.integrations, function (integation) {
                        angular.forEach(vm.data.sps, function (sp) {
                            if (sp.id == integation.companyReferenceId) {
                                sp.selected = true;
                            }
                        });
                    });

                    return response;
                })
                .catch(function () {
                    vm.showLoader = false;
                });
        } else {
            vm.showLoader = false;
        }

        function processLogout() {
            userManager.logout();

            $state.go(
                "dashboard.user.integrations.abstract.prosure",
                {
                    company: vm.data.encryptedCompanyData,
                },
                { reload: true }
            );
        }

        function updateAction() {
            vm.showLoader = true;

            if (vm.isvalidCompany) {
                integrationsService
                    .update(putCompanyDataLink, vm.data)
                    .then( function (response) {
                        // do nothing
                    })
                    .catch(function () {
                        vm.showLoader = false;
                    });
            }

            vm.showLoader = false;
        }
    }
})();
