(function (){
    'use strict';

    angular
        .module('elogbooks.common.audit')
        .controller('AuditAnswerOptionAddEditController', AuditAnswerOptionAddEditController);

    AuditAnswerOptionAddEditController.$inject = ['$state', 'answerOptionResponse', 'sectionResponse', 'confirmationModal', 'answerOptionsResponse', 'apiClient', 'lodash', 'messenger', 'requestDataFactory', '$filter', 'questionResponse'];

    function AuditAnswerOptionAddEditController($state, answerOptionResponse, sectionResponse, confirmationModal, answerOptionsResponse, apiClient, lodash, messenger, requestDataFactory, $filter, questionResponse) {
        var vm = this;
        vm.create = createAction;
        vm.update = updateAction;
        vm.delete = deleteAction;
        vm.data = requestDataFactory.createRequest(getDefaults(answerOptionResponse, sectionResponse));
        vm.answerOptionResponse = answerOptionResponse;
        vm.answerOptionCollectionResponse = answerOptionsResponse;
        vm.questionResponse = questionResponse;

        vm.data.assistedComments = [];
        if (answerOptionResponse.assistedComments && answerOptionResponse.assistedComments.count > 0) {
            vm.data.assistedComments = answerOptionResponse.assistedComments.assistedComments;
        }

        function createAction () {
            var redirectRoute = '^';
            var redirectParams = {};

            apiClient.create(vm.answerOptionCollectionResponse.getLink('create'), vm.data).then(function (response) {
                if (response) {
                    $state.go(redirectRoute, redirectParams, { reload: $state.get('^') }).then(function () {
                        messenger.success('AUDIT_ANSWER_OPTION_CREATED');
                    });
                } else {
                    messenger.error('REQUEST_ERROR');
                }
            });
        };

        function updateAction () {
            apiClient.update(vm.answerOptionResponse._links.self.href, vm.data).then(function (response) {
                if (response) {
                    $state.go('^', {}, { reload: $state.get('^') }).then(function () {
                        messenger.success('ANSWER_OTPION_UPDATED');
                    });
                } else {
                    messenger.error('REQUEST_ERROR');
                }
            });
        };

        function deleteAction() {
            return confirmationModal.open().result.then(function () {
                return apiClient.delete(vm.answerOptionResponse.getLink('delete')).then(function (response) {
                    if (response) {
                        $state.go('^', {}, { reload: $state.get('^') }).then(function () {
                            messenger.success('ANSWER_OPTION_DELETED');
                        });
                    } else {
                        messenger.error('REQUEST_ERROR');
                    }
                });
            });
        }

        function getDefaults(entity, sectionResponse) {
            return {
                id: entity.id || null,
                title: entity.title || null
            };
        }
    }
})();
