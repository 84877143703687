(function() {
    'use strict';

    angular
        .module('elogbooks.common.quote-requests')
        .controller('CommonQuoteRequestDetailsQuoteDetailsOptionDetailsMaterialController', CommonQuoteRequestDetailsQuoteDetailsOptionDetailsMaterialController);

    CommonQuoteRequestDetailsQuoteDetailsOptionDetailsMaterialController.$inject = ['quoteOptionMaterialCollectionResponse'];

    function CommonQuoteRequestDetailsQuoteDetailsOptionDetailsMaterialController(quoteOptionMaterialCollectionResponse) {
        var vm = this;
        vm.quoteOptionMaterialCollectionResponse = quoteOptionMaterialCollectionResponse;
    }
})();
