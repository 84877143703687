/**
 * New Pagination
 */
angular
    .module('elogbooksDirectives')
    .directive('newPagination', newPagination);

function newPagination($state, $stateParams, lodash) {

    var directive = {
        scope: {
            links: '=',
            pages: '=',
            count: '=',
            limit: '=',
        },
        restrict: 'AE',
        templateUrl: '/modules/directives/new-pagination/pagination.html',
        link: link
    };
    return directive;

    function link(scope) {
        scope.limit = scope.limit != null ? parseInt(scope.limit) : scope.limit;
        scope.count = scope.count != null ? parseInt(scope.count) : scope.count;
        scope.pages = scope.pages != null ? parseInt(scope.pages) : scope.pages;

        // if current page not passed, get from the stateParams
        scope.current = parseInt($stateParams.page) ? parseInt($stateParams.page) : 1;
        scope.range = [];

        // Generate range of pagination buttons
        var pages = 11;
        var start = Math.min(Math.max(1, scope.current - Math.floor(pages / 2)), Math.max(1, scope.pages - pages + 1));
        var end = Math.min(start + pages - 1, scope.pages);

        scope.range = lodash.range(start, end);

        if (scope.limit && scope.count != null) {
            if (scope.count === 0) {
                scope.startItem = 0;
                scope.endItem = 0;
            } else {


                scope.startItem = 1 + (+scope.current - 1) * (+scope.limit);
                scope.endItem = Math.min((+scope.startItem) + (+scope.limit) - 1, +scope.count);
            }
        }
    }
}
