app.config(function ($translateProvider) {
    $translateProvider.translations('nl', {
        METER: 'Meter',
        METERS: 'Meters',
        METERS_LIST: 'Meters',
        METERS_ADD: 'Toevoegen',
        METER_ADD: 'Voeg een nieuwe meter toe',
        METER_EDIT : 'Wijzig Meter',
        UTILITY_PROVIDER: 'Leverancier',
        MANUFACTURER: 'Fabrikant',
        SUPPLY_TYPE: 'Leverings type',
        OCCUPANCY: 'Bezetting',
        AUTOMATIC_READINGS: 'Automatisch uitgelezen',
        ACCOUNT_NUMBER: 'Account Number',
        MULTIPLIER: 'Factor',
        DUAL_RATED: 'Dag en nachtstand',
        SERIAL_NUMBER: 'Serie nummer',
        ADD_NEW_READING : 'Voeg meting toe',
        ADD_NEW_SUBMETER : 'Voeg tussenmeter toe',
        DATE_READ : 'Datum en tijd',
        READING : 'Opname',
        READINGS : 'Opnames',
        NIGHT_READINGS: 'Nacht verbruiken',
        NIGHT_READING: 'Nacht verbruik',
        DAY_READINGS: 'Dag verbruik',
        DAY_READING: 'Dag verbruik',
        LAST_READING: 'Laatste stand',
        READ_BY: 'Uitgelezen door',
        NO_READINGS: 'Niet opgenomen',
        NO_SUBMETERS: 'Geen tussenmeters',
        SUBMETERS: 'Tussenmeters',
        HISTORY: 'Historie',
        DATE_READING_DUE: 'Opname verloopt',
        GOTOPARENT: 'Ga naar hoofdmeter',
        METER_FREQUENCY: 'Frequentie',
        METER_MAX_VALUE: 'Maximum value',
        OVER: 'Rolled Over',
        CONSUMPTION: 'Verbruik',

        METERSUPPLIERS : 'Meter Leverancier',
        METERMANUFACTURER : 'Meter Fabrikaat',
        METERTYPE : 'Meter Type',
        METERTYPESUPPLY : 'Meter levering',
        METERTYPEOCCUPANCY : 'Meter bezetting',

        METER_MANUFACTURERS: 'Fabrikanten',
        METER_OCCUPANCYTYPES: 'Type bezetting',
        METER_SUPPLIERS: 'Leveranciers',
        METER_SUPPLYTYPES: 'Levering Types',
        METER_TYPES: 'Types',

        EDIT_READING: 'Wijzig opname',
        METER_ADDED: 'Meter toegevoegd',
        METER_READING_ADDED: 'Meter opname toegevoegd',
        METER_READING_UPDATED: 'Meter opname aangepast',
        REFERENCE_TYPE: 'Reference Type',
        IMAGES: 'Afbeelding',

        ERROR_READING_EXCEEDS_MAXVALUE: 'Reading must not be higher then set maximum value of the meter',
        ERROR_READING_MUST_BE_GREATER: 'Reading must be greater or equal to the previous reading',
        ERROR_READING_MUST_BE_LOWER: 'Reading must be less than or equal to the next reading',
        ERROR_INVALID_MAX_VALUE: 'Maximum value must be greater than zero',
        ERROR_MAX_VALUE_MUST_BE_HIGHER: 'Maximum value should not be lower than reading',

        INSUFFICIENT_PERMISSIONS_CANNOT_UPDATE_REFERENCE: 'Insufficient permissions, cannot update reference.'
    });
});
