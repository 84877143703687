(function () {
    'use strict';

    angular
        .module('elogbooksFilters')
        .filter('creditNoteStatus', creditNoteStatus);

    function creditNoteStatus ($filter) {
        var statuses = {
            0: 'CREDIT_NOTE_STATUS_DRAFT',
            'status_draft': 'CREDIT_NOTE_STATUS_DRAFT',
            1: 'CREDIT_NOTE_STATUS_ISSUED',
            'status_issued': 'CREDIT_NOTE_STATUS_ISSUED',
            2: 'CREDIT_NOTE_STATUS_CANCELLED',
            'status_cancelled': 'CREDIT_NOTE_STATUS_CANCELLED',
        };

        return function (input) {
            return $filter('translate')(statuses[input]);
        }
    };
})();
