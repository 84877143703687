(function () {
    'use strict';

    angular
        .module('elogbooks.common.jobs')
        .controller('CommonExplicitAccessAddEditController', CommonExplicitAccessAddEditController);

    CommonExplicitAccessAddEditController.$inject = ['apiClient', 'config', 'requestDataFactory', 'messenger', '$state', 'userManager', 'jobResponse', 'userCollectionResponse', 'siteResponse', 'explicitAccessUserResponse'];

    function CommonExplicitAccessAddEditController(apiClient, config, requestDataFactory, messenger, $state, userManager, jobResponse, userCollectionResponse, siteResponse, explicitAccessUserResponse) {
        var vm = this;
        vm.data = requestDataFactory.createRequest(getDefaults());
        vm.users = userCollectionResponse;
        vm.create = createAction;
        vm.update = createAction;
        vm.required = !config.edit;
        vm.explicitAccessUserResponse = explicitAccessUserResponse;

        vm.selectedUserModel = {
            response: vm.users,
            link: userManager.user.getLink('users'),
            linkParameters: { 'site': siteResponse.id, status: 'active', explicitAccess:true, jobId: jobResponse.id },
            required: true,
            responseKeyPath: 'users',
            itemHrefPath: '_links.self.href',
            itemValuePath: 'name',
            onSelect: changeUser,
            onRemove: changeUser,
            disabled: config.edit,
            populateDisabled: true
        };

        function changeUser() {
            if (vm.selectedUserModel.selected && vm.selectedUserModel.selected.object) {
                vm.data._links.user = {href: vm.selectedUserModel.selected.href};
            } else {
                delete vm.data._links.user;
            }
        }

        if (explicitAccessUserResponse) {
            vm.selectedUserModel.selected = {
                href: explicitAccessUserResponse.getLink('user'),
                value: explicitAccessUserResponse.name,
                object: explicitAccessUserResponse
            };

            changeUser();
        }

        function createAction() {
            apiClient.create(jobResponse.getLink('explicit-access'), vm.data).then(function (response) {
                if (response) {
                    $state.go('^', {}, {reload: $state.get('^.^')}).then(function () {
                        messenger.success('ACCESS_GRANTED');
                    });
                } else {
                    messenger.error('REQUEST_ERROR');
                }
            });
        }

        function getDefaults(){
            return {
                _links: {},
                expiresAt: explicitAccessUserResponse.expiresAt ? new Date(explicitAccessUserResponse.expiresAt) : null
            };
        }
    }
})();
