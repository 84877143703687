(function (){
    'use strict';

    angular
        .module('elogbooks.user.bulk-actions')
        .config(registerRoutes);

    function registerRoutes($stateProvider) {
        $stateProvider
            .state('dashboard.user.bulk-actions', {
                url: '/bulk-actions',
                abstract: true,
                views: {
                    '@dashboard': {
                        template: '<div ui-view class="bulk-actions"></div>',
                    }
                },
                ncyBreadcrumb: {
                    skip: true
                }
            })
            .state('dashboard.user.bulk-actions.list', {
                url: '/list?bulkActionPage&bulkActionLimit&type',
                parent: 'dashboard.user.bulk-actions',
                views: {
                    '@dashboard.user.bulk-actions': {
                        templateUrl: '/modules/user/bulk-actions/bulk-actions-list.html',
                        controller : 'BulkActionsListController',
                        controllerAs: 'vm',
                    }
                },
                resolve: {
                    bulkActionsCollectionResponse: function (apiClient, $stateParams, rootResourceResponse) {
                        var params = $stateParams;

                        params = angular.extend({}, params, {
                            page: $stateParams.bulkActionPage,
                            limit: $stateParams.bulkActionLimit,
                        });

                        return apiClient.get(rootResourceResponse.getLink('bulkactions'), params).then(function (response) {
                            return response;
                        });
                    }
                },
                params: {
                    bulkActionPage: '1',
                    bulkActionLimit: '30',
                    type: null,
                },
                ncyBreadcrumb: {
                    label: '{{ ::"BULKACTIONS" | translate }}'
                }
            })
            .state('dashboard.user.bulk-actions.list.details', {
                url: '/{bulkActionResource}/details?page&limit&logType',
                parent: 'dashboard.user.bulk-actions.list',
                views: parseBook({
                    '@dashboard.user.bulk-actions': {
                        templateUrl: '/modules/user/bulk-actions/details/details.html',
                        controller : 'BulkActionDetailsController',
                        controllerAs: 'vm'
                    }
                }),
                params: {
                    page: '1',
                    limit: '30',
                    logType: null
                },
                resolve: {
                    bulkActionResponse: function (apiClient, $stateParams) {
                        return apiClient.get($stateParams.bulkActionResource.decode());
                    },
                    bulkActionElementsCollectionResponse: function (apiClient, bulkActionResponse, $stateParams) {
                        var params = {
                            page: $stateParams.page,
                            limit: $stateParams.limit,
                            type: $stateParams.logType
                        };

                        var link = bulkActionResponse.getLink('jobs') ? bulkActionResponse.getLink('jobs') : bulkActionResponse.getLink('actions');
                        return apiClient.get(link, params);
                    }
                },
                ncyBreadcrumb: {
                    label: '{{ ::"DETAILS" | translate }}'
                }
            });
    }
})();
