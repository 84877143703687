(function(){
    'use strict';

    angular
        .module('elogbooks.admin.document-types-management')
        .config(registerRoutes)
        .config(registerRoutesListDocumentTypes)
        .config(registerRoutesAddDocumentTypes)
        .config(registerRoutesDetailsDocumentTypes)
        .config(registerRoutesEditDocumentTypes)
        .config(registerRoutesListDocumentSets)
        .config(registerRoutesAddDocumentSets)
        .config(registerRoutesDetailsDocumentSets)
        .config(registerRoutesEditDocumentSets)
        .config(registerRoutesAddDocumentSetsTypes)
        .config(registerRoutesListSites)
        .config(registerRoutesAddSites)
    ;

    function registerRoutes($stateProvider) {
        $stateProvider
            .state('dashboard.admin.document-types-management', {
                url: '/document-types-management',
                abstract: true,
                templateUrl: '/modules/admin/document-types-management/document-types-management.html',
                ncyBreadcrumb: {
                    label: '{{ ::"DOCUMENT_TYPES_MANAGEMENT" | translate }}'
                },
                data: {
                    permissions: ['user_permission_view_document_types', 'user_permission_client_admin_manage_document_types']
                }
            });
    }

    function registerRoutesListDocumentTypes ($stateProvider) {
        $stateProvider
            .state('dashboard.admin.document-types-management.document-types', {
                parent: 'dashboard.admin.document-types-management',
                data: {
                    permissions: ['user_permission_view_document_types', 'user_permission_client_admin_manage_document_types']
                },
                url: '/document-types?page&limit&order&name&status&criticality',
                views: {
                    '@dashboard.admin.document-types-management': {
                        templateUrl: '/modules/admin/document-types-management/document-types/document-types-list.html',
                        controller: 'DocumentTypesListController',
                        controllerAs: 'vm'
                    }
                },
                resolve: {
                    documentTypeCollectionResponse : function (apiClient, $stateParams, rootResourceResponse) {
                        var params = angular.extend({}, $stateParams, {'shared': false, 'criticality[]': $stateParams.criticality});

                        return apiClient.get(rootResourceResponse.getLink('documenttypes'), params)
                            .then(function (response) {
                            return response || apiClient.noResourceFound();
                        });
                    }
                },
                params: {
                    page: '1',
                    limit: '30',
                    order: 'id',
                    name: null,
                    criticality: {array: true},
                    status: null
                },
                ncyBreadcrumb: {
                    label: '{{ ::"DOCUMENT_TYPES" | translate }}'
                }
            });
    }

    function registerRoutesAddDocumentTypes($stateProvider) {
        $stateProvider
            .state('dashboard.admin.document-types-management.document-types.add', {
                url: '/add',
                parent: 'dashboard.admin.document-types-management.document-types',
                data: {
                    permissions: ['user_permission_manage_document_types', 'user_permission_client_admin_manage_document_types']
                },
                views: {
                    '@dashboard.admin.document-types-management': {
                        templateUrl: '/modules/admin/document-types-management/document-types/add-edit/document-type-form.html',
                        controller: 'DocumentTypeAddEditController',
                        controllerAs: 'vm'
                    }
                },
                resolve: {
                    documentTypeResponse: function () {
                        return {};
                    },
                    statutoryTypesCollectionResponse: function(apiClient, rootResourceResponse) {
                        return apiClient.get(rootResourceResponse.getLink('statutorytypes'));
                    }
                },
                ncyBreadcrumb: {
                    label: '{{ ::"ADD" | translate }}'
                }
            });
    }

    function registerRoutesDetailsDocumentTypes($stateProvider) {
        $stateProvider
            .state('dashboard.admin.document-types-management.document-types.details', {
                url: '/details/{resource}',
                abstract: true,
                parent: 'dashboard.admin.document-types-management.document-types',
                data: {
                    permissions: ['user_permission_view_document_types', 'user_permission_client_admin_manage_document_types']
                },
                views: {
                    '@dashboard.admin.document-types-management': {
                        templateUrl: '/modules/admin/document-types-management/document-types/details/document-types-details.html',
                        controller: 'DocumentTypesDetailsController',
                        controllerAs: 'vm'
                    }
                },
                resolve: {
                    documentTypeResponse: function(apiClient, $stateParams) {
                        return apiClient.get($stateParams.resource.decode()).then( function(response) {
                            return response || apiClient.noResourceFound();
                        });
                    }
                },
                ncyBreadcrumb: {
                    label: '{{ ::$resolves().documentTypeResponse.name }}'
                }
            })
            .state('dashboard.admin.document-types-management.document-types.details.info', {
                url: '/info',
                parent: 'dashboard.admin.document-types-management.document-types.details',
                data: {
                    permissions: ['user_permission_view_document_types', 'user_permission_client_admin_manage_document_types']
                },
                views: {
                    '@dashboard.admin.document-types-management.document-types.details': {
                        templateUrl: '/modules/admin/document-types-management/document-types/details/info/document-types-info.html',
                        controller: 'DocumentTypesInfoController',
                        controllerAs: 'vm'
                    }
                },
                ncyBreadcrumb: {
                    label: '{{ ::"INFO" | translate }}'
                }
            });
    }

    function registerRoutesEditDocumentTypes($stateProvider) {
        $stateProvider
            .state('dashboard.admin.document-types-management.document-types.details.info.edit', {
                url: '/edit/{resource}',
                parent: 'dashboard.admin.document-types-management.document-types.details.info',
                data: {
                    permissions: ['user_permission_manage_document_types', 'user_permission_client_admin_manage_document_types']
                },
                views: {
                    '@dashboard.admin.document-types-management.document-types.details': {
                        templateUrl: '/modules/admin/document-types-management/document-types/add-edit/document-type-form.html',
                        controller: 'DocumentTypeAddEditController',
                        controllerAs: 'vm'
                    }
                },
                resolve: {
                    statutoryTypesCollectionResponse: function(apiClient, rootResourceResponse) {
                        return apiClient.get(rootResourceResponse.getLink('statutorytypes'));
                    }
                },
                ncyBreadcrumb: {
                    label: '{{ ::"EDIT" | translate }}'
                }
            });
    }

    function registerRoutesListDocumentSets ($stateProvider) {
        $stateProvider
            .state('dashboard.admin.document-types-management.document-sets', {
                parent: 'dashboard.admin.document-types-management',
                url: '/document-sets?page&limit&order&name&status&description',
                data: {
                    permissions: ['user_permission_view_document_types', 'user_permission_client_admin_manage_document_types']
                },
                views: {
                    '@dashboard.admin.document-types-management': {
                        templateUrl: '/modules/admin/document-types-management/document-sets/document-sets-list.html',
                        controller: 'DocumentSetsListController',
                        controllerAs: 'vm'
                    }
                },
                resolve: {
                    documentSetCollectionResponse : function (apiClient, $stateParams, rootResourceResponse) {
                        return apiClient.get(rootResourceResponse.getLink('documentsets'), $stateParams)
                            .then(function (response) {
                                return response || apiClient.noResourceFound();
                            });
                    }
                },
                params: {
                    page: '1',
                    limit: '30',
                    order: 'id',
                    name: null,
                    description: null,
                    status: null
                },
                ncyBreadcrumb: {
                    label: '{{ ::"DOCUMENT_SETS" | translate }}'
                }
            });
    }

    function registerRoutesAddDocumentSets($stateProvider) {
        $stateProvider
            .state('dashboard.admin.document-types-management.document-sets.add', {
                url: '/add',
                parent: 'dashboard.admin.document-types-management.document-sets',
                data: {
                    permissions: ['user_permission_manage_document_types', 'user_permission_client_admin_manage_document_types']
                },
                views: {
                    '@dashboard.admin.document-types-management': {
                        templateUrl: '/modules/admin/document-types-management/document-sets/add-edit/document-set-form.html',
                        controller: 'DocumentSetAddEditController',
                        controllerAs: 'vm'
                    }
                },
                resolve: {
                    documentSetResponse: function () {
                        return {};
                    },
                    documentTypeCollectionResponse : function (apiClient, rootResourceResponse) {
                        return apiClient.get(rootResourceResponse.getLink('documenttypes'), {status: 'active'})
                            .then(function (response) {
                                return response || apiClient.noResourceFound();
                            });
                    },
                    siteCollectionResponse : function (apiClient, rootResourceResponse, user) {
                        return apiClient.get(rootResourceResponse.getLink('sites') || user.getLink('sites'), {order: ['name']})
                            .then(function (response) {
                                return response || apiClient.noResourceFound();
                            });
                    },
                    config: function () {
                        return {};
                    },
                },
                ncyBreadcrumb: {
                    label: '{{ ::"ADD" | translate }}'
                }
            });
    }

    function registerRoutesDetailsDocumentSets($stateProvider) {
        $stateProvider
            .state('dashboard.admin.document-types-management.document-sets.details', {
                url: '/details/{resource}',
                abstract: true,
                parent: 'dashboard.admin.document-types-management.document-sets',
                data: {
                    permissions: ['user_permission_view_document_types', 'user_permission_client_admin_manage_document_types']
                },
                views: {
                    '@dashboard.admin.document-types-management': {
                        templateUrl: '/modules/admin/document-types-management/document-sets/details/document-sets-details.html',
                        controller: 'DocumentSetsDetailsController',
                        controllerAs: 'vm'
                    }
                },
                resolve: {
                    documentSetResponse: function(apiClient, $stateParams) {
                        return apiClient.get($stateParams.resource.decode()).then(function(response) {
                            return response || apiClient.noResourceFound();
                        });
                    }
                },
                ncyBreadcrumb: {
                    label: '{{ ::$resolves().documentSetResponse.name }}'
                }
            })
            .state('dashboard.admin.document-types-management.document-sets.details.info', {
                url: '/info',
                parent: 'dashboard.admin.document-types-management.document-sets.details',
                data: {
                    permissions: ['user_permission_view_document_types', 'user_permission_client_admin_manage_document_types']
                },
                views: {
                    '@dashboard.admin.document-types-management.document-sets.details': {
                        templateUrl: '/modules/admin/document-types-management/document-sets/details/info/document-sets-info.html',
                        controller: 'DocumentSetsInfoController',
                        controllerAs: 'vm'
                    }
                },
                resolve: {
                    documentSetTypeResponse: function($stateParams, apiClient, documentSetResponse) {
                        var params = {
                            page: $stateParams.typesPage,
                            limit: $stateParams.typesLimit,
                            order: $stateParams.typesOrder
                        };

                        return apiClient.get(documentSetResponse.getLink('documenttypes'), params)
                            .then(function(response) {
                                return response || apiClient.noResourceFound();
                            });
                    }
                },
                params: {
                    sitesPage: '1',
                    sitesLimit: '30',
                    sitesOrder: 'name'
                },
                ncyBreadcrumb: {
                    label: '{{ ::"INFO" | translate }}'
                }
            });
    }

    function registerRoutesListSites($stateProvider) {
        $stateProvider.state('dashboard.admin.document-types-management.document-sets.details.sites', {
            url: '/sites?sitesPage&sitesLimit&sitesOrder',
            parent: 'dashboard.admin.document-types-management.document-sets.details',
            data: {
                permissions: ['user_permission_view_document_types', 'user_permission_client_admin_manage_document_types']
            },
            views: {
                '@dashboard.admin.document-types-management.document-sets.details': {
                    templateUrl: '/modules/admin/document-types-management/document-sets/details/sites/document-sets-sites.html',
                    controller: 'DocumentSetsSitesController',
                    controllerAs: 'vm'
                }
            },
            resolve: {
                documentSetSitesResponse: function($stateParams, apiClient, documentSetResponse) {
                    var params = {
                        page: $stateParams.sitesPage,
                        limit: $stateParams.sitesLimit,
                        order: $stateParams.sitesOrder
                    };

                    return apiClient.get(documentSetResponse.getLink('sites'), params)
                        .then(function(response) {
                            return response || apiClient.noResourceFound();
                        });
                }
            },
            params: {
                sitesPage: '1',
                sitesLimit: '30',
                sitesOrder: 'name'
            },
            ncyBreadcrumb: {
                label: '{{ ::"SITES" | translate }}'
            }
        })
    }

    function registerRoutesAddSites($stateProvider) {
        $stateProvider.state('dashboard.admin.document-types-management.document-sets.details.sites.add-sites', {
            url: '/add-sites',
            parent: 'dashboard.admin.document-types-management.document-sets.details.sites',
            data: {
                permissions: ['user_permission_manage_document_types', 'user_permission_client_admin_manage_document_types']
            },
            views: {
                '@dashboard.admin.document-types-management.document-sets.details': {
                    templateUrl: '/modules/admin/document-types-management/document-sets/add-edit/document-set-add-sites.html',
                    controller: 'DocumentSetAddSiteController',
                    controllerAs: 'vm'
                }
            },
            resolve: {
                siteCollectionResponse : function (apiClient, rootResourceResponse, user) {
                    return apiClient.get(rootResourceResponse.getLink('sites') || user.getLink('sites'), {order: ['name']})
                        .then(function (response) {
                            return response || apiClient.noResourceFound();
                        });
                },
            },
            ncyBreadcrumb: {
                label: '{{ ::"DOCUMENT_SET_ADD_SITES" | translate }}'
            }
        })
    }
    function registerRoutesEditDocumentSets($stateProvider) {
        $stateProvider
            .state('dashboard.admin.document-types-management.document-sets.details.info.edit', {
                url: '/edit/{resource}',
                parent: 'dashboard.admin.document-types-management.document-sets.details.info',
                data: {
                    permissions: ['user_permission_manage_document_types', 'user_permission_client_admin_manage_document_types']
                },
                views: {
                    '@dashboard.admin.document-types-management.document-sets.details': {
                        templateUrl: '/modules/admin/document-types-management/document-sets/add-edit/document-set-form.html',
                        controller: 'DocumentSetAddEditController',
                        controllerAs: 'vm'
                    }
                },
                resolve: {
                    documentTypeCollectionResponse : function (apiClient, rootResourceResponse) {
                        return apiClient.get(rootResourceResponse.getLink('documenttypes'), {status: 'active'})
                            .then(function (response) {
                                return response || apiClient.noResourceFound();
                            });
                    },
                    siteCollectionResponse : function () {
                        return {}
                    },
                    config: function () {
                        return {
                            edit: true,
                        };
                    },
                },
                ncyBreadcrumb: {
                    label: '{{ ::"EDIT" | translate }}'
                }
            });
    }

    function registerRoutesAddDocumentSetsTypes($stateProvider) {
        $stateProvider
            .state('dashboard.admin.document-types-management.document-sets.details.info.add-documentType', {
                url: '/add-documentType',
                parent: 'dashboard.admin.document-types-management.document-sets.details.info',
                data: {
                    permissions: ['user_permission_manage_document_types', 'user_permission_client_admin_manage_document_types']
                },
                views: {
                    '@dashboard.admin.document-types-management.document-sets.details': {
                        templateUrl: '/modules/admin/document-types-management/document-sets/add-edit/document-set.add-documentType.html',
                        controller: 'DocumentSetAddDocumentTypeController',
                        controllerAs: 'vm'
                    }
                },
                resolve: {
                    documentTypeCollectionResponse : function (apiClient, rootResourceResponse) {
                        return apiClient.get(rootResourceResponse.getLink('documenttypes'), {status: 'active'})
                            .then(function (response) {
                                return response || apiClient.noResourceFound();
                            });
                    }
                },
                ncyBreadcrumb: {
                    label: '{{ ::"DOCUMENT_TYPE_ADD" | translate }}'
                }
            });
    }
})();
