(function () {
    'use strict';

    angular
        .module('elogbooks.admin.sites')
        .controller('PartnershipSurveysController', PartnershipSurveysController);

    PartnershipSurveysController.$inject = ['$state', 'apiClient', 'confirmationModal', 'partnershipResponse', 'surveysResponse', 'lodash', 'messenger', 'requestDataFactory', 'crudService'];

    function PartnershipSurveysController ($state, apiClient, confirmationModal, partnershipResponse, surveysResponse, lodash, messenger, requestDataFactory, crudService) {
        var vm = this;
        vm.partnership = partnershipResponse;
        vm.surveys = surveysResponse.surveys;
        vm.surveyResponse = surveysResponse;

        vm.delete = function (survey) {

            vm.data = {
                _links: {
                    surveys: []
                }
            };

            lodash.each(vm.surveys, function (existingSurvey) {
                if(existingSurvey !== survey) {
                    vm.data._links.surveys.push(existingSurvey._links.self);
                }
            });

            return confirmationModal.open().result.then(function (response) {
                return apiClient.update(vm.surveyResponse.getLink('self'), vm.data).then(function (response) {
                    if (response) {
                        $state.go('.', {}, {reload: $state.get('^') }).then(function () {
                            messenger.success('PARTNERSHIP_SURVEYS_DELETED');
                        });
                    } else {
                        messenger.error('REQUEST_ERROR');
                    }
                });

                return;
            });
        };
    }
})();
