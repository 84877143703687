/**
 * Conversations compose controller
 */
 (function() {
    'use strict';

    angular
        .module('elogbooks.common.conversations')
        .controller('ConversationsComposeController', ConversationsComposeController);

    ConversationsComposeController.$inject = ['user', 'userCollectionResponse', 'conversationCollectionResponse', 'apiClient', '$state', 'lodash'];

    function ConversationsComposeController(user, userCollectionResponse, conversationCollectionResponse, apiClient, $state, lodash) {

        var vm = this;
        vm.resourceLink = userCollectionResponse.getData()._links.self.href;

        vm.recipients = [];

        vm.buildItems = function (response) {
            return response.getData().users.map(
                function (item) {
                    if (typeof item === 'undefined'){
                        return;
                    }
                    return {
                        name: item.name,
                        href : item._links.self.href,
                        type: 'default'
                    };
                }
            );
        };

        vm.search = function(keyword) {
            vm.loading = true;

            if (keyword.length >= 1 && vm.resourceLink) {
                // Async function needs to access object properties from global scope
                apiClient.get(vm.resourceLink, {name: keyword}).then(function (response) {
                    vm.loading = false;
                    vm.users = vm.buildItems(response);
                    //remove dupes
                    lodash.each(vm.recipients, function(recipient) {
                        vm.users = lodash.reject(vm.users, function(user){
                            return user.href === recipient.href;
                        });
                    });
                });
            } else {
                vm.loading = false;
            }
        };

        vm.user = user;
        vm.users = vm.buildItems(userCollectionResponse);
        vm.subject = null;
        vm.message = null;
        vm.send = send;

        function send() {
            var request = {
                subject: vm.subject,
                targets: [ 'helpdesk' ],
                message: {
                    body: vm.message
                },
                _links: {
                    recipient : vm.recipients
                }
            };

            apiClient.create(conversationCollectionResponse.getData()._links.create.href, request)
                .then(function(response) {
                    var conversation = response.getData();

                    $state.go('^.detail', { resource: conversation._links.self.href.encode() });
                });
        }

    };

 })();
