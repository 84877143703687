(function() {
    'use strict';

    angular
        .module('elogbooks')
        .config(Translations);

    function Translations($translateProvider) {
        $translateProvider.translations('en-gb', {
            UPDATES_DUE: 'Updates Due',
            REACTIVE_UPDATES_DUE: 'Reactive Jobs',
            PLANNED_UPDATES_DUE: 'Planned Jobs',
            QUOTE_UPDATES_DUE: 'Quotes',
            SITES_WITH_PPM_REQUIRING_APPROVAL: 'Sites with PPM Requiring Approval',
            PAPERWORK_FOR_REVIEW: 'Paperwork for Review',
            AWAITING_CLIENT_REVIEW: 'Awaiting Client Review',
            OVERDUE_PAPERWORK: 'Jobs with Outstanding Paperwork',
            OVERDUE_PAPERWORK_OVERDUE: 'Not Started or Overdue Completion',
            OVERDUE_PAPERWORK_AWAITING: 'Completed Awaiting Paperwork',
            P2P_JOBS: 'P2P Jobs',
            P2P_AWAITING_VALUE: 'Awaiting Value',
            P2P_AWAITING_PO_NUMBER: 'Awaiting PO Number',
            JOB_REQUISITES_REQUIRING_APPROVAL: 'Job Requisites Requiring Approval',
            JOB_VALUES: 'Job Values',
            JOB_VALUES_PENDING_APPROVAL: 'Awaiting Approval',
            JOB_VALUE_REQUIRED: 'Value Required',
            OPEN_ACTIONS: 'Open Actions',
            OUTSTANDING_APPROVALS: 'Outstanding Approvals',
            PENDING_ACKNOWLEDGEMENT: 'Pending Acknowledgement',
            PLANNED_JOBS_REQUIRING_APPROVAL: 'Planned Jobs Requiring Approval',
            REACTIVE_JOBS_OVERDUE_ATTENDANCE: 'Reactive Jobs Overdue Attendance',
            PLANNED_JOBS_OVERDUE_COMPLETION: 'Planned Jobs Overdue Completion',
            REACTIVE_JOBS_OVERDUE_COMPLETION: 'Reactive Jobs Overdue Completion',
            OPEN_INTEGRATION_ACTIONS: 'Open Integration Actions',
            ASSET_SCORE_TYPE_1: 'Asset Score Type 1',
            ASSET_SCORE_TYPE_2: 'Asset Score Type 2',
            ASSET_SCORE_TYPE_3: 'Asset Score Type 3',
            PLANNED_JOBS_REQUIRING_APPROVAL_SP: 'Tasks Pending Approval',
            OUTSTANDING_EXTENSION_REQUESTS: 'Outstanding Extension Requests',

            OPEN_INTEGRATION_ACTIONS_DETAIL: 'Open Integration Actions',
            P2P_JOBS_DETAIL: 'P2P Jobs',
            JOB_VALUES_DETAIL: 'Job Values',
            AWAITING_CLIENT_REVIEW_DETAIL: 'Awaiting Client Review',
            CONTRACT_APPROVALS_DETAIL: 'Contract Approvals',
            CONTRACT_RENEWALS_DETAIL: 'Contract Renewals',
            CONTRACT_TIMELINES_DETAIL: 'Contract Timelines',
            ESCALATIONS_DETAIL: 'Escalations',
            JOB_REQUISITES_REQUIRING_APPROVAL_DETAIL: 'Job Requisites Requiring Approval',
            OPEN_ACTIONS_DETAIL: 'Open Actions',
            OUTSTANDING_APPROVALS_DETAIL: 'Outstanding Approvals',
            OUTSTANDING_EXTENSION_APPROVALS: 'Outstanding Extension Approvals',
            OUTSTANDING_EXTENSION_APPROVALS_DETAIL: 'Outstanding Extension Approvals',
            OUTSTANDING_KPI_SURVEYS_DETAIL: 'Outstanding KPI Surveys',
            PENDING_ACKNOWLEDGEMENT_DETAIL: 'Pending Acknowledgement',
            PLANNED_JOBS_OVERDUE_COMPLETION_DETAIL: 'Planned Jobs Overdue Completion',
            REACTIVE_JOBS_OVERDUE_ATTENDANCE_DETAIL: 'Reactive Jobs Overdue Attendance',
            REACTIVE_JOBS_OVERDUE_COMPLETION_DETAIL: 'Reactive Jobs Overdue Completion',
            PLANNED_JOBS_REQUIRING_APPROVAL_DETAIL: 'Planned Jobs Requiring Approval',
            PERFORMANCE_STATISTICS_DETAIL: 'Performance Statistics',
            OVERDUE_PAPERWORK_DETAIL: 'Jobs with Outstanding Paperwork',
            UPDATES_DUE_DETAIL: 'Updates Due',
            OUTSTANDING_REMEDIALS: 'Outstanding Remedials',
            OUTSTANDING_REMEDIALS_DETAIL: 'Outstanding Remedials',
            REACTIVE_JOBS_EXTENSION_APPROVALS: 'Reactive Job Extension Approvals',
            PLANNED_JOBS_EXTENSION_APPROVALS: 'Planned Extension Approvals',
            ASSET_SCORE_TYPE_1_DETAIL: 'Asset Score Type 1',
            ASSET_SCORE_TYPE_2_DETAIL: 'Asset Score Type 2',
            ASSET_SCORE_TYPE_3_DETAIL: 'Asset Score Type 3',
            PLANNED_JOBS_REQUIRING_APPROVAL_SP_DETAIL: 'Tasks Pending Approval',
            OUTSTANDING_EXTENSION_REQUESTS_DETAIL: 'Outstanding Extension Requests',
            JOB_EXTENSION_REQUESTS: 'Job Extension Requests',
            QUOTE_EXTENSION_REQUESTS: 'Quote Extension Requests',
            INCLUDE_IN_DASHBOARD_WIDGET: 'Include in Dashboard Widget',
            LIMIT_REACHED: 'Max number of priorities selected (4).',
            OPEN_FLAGGED_PRIORITY_JOBS: 'Flagged Priorities',

            OPEN_ACTIONS_1: 'Open {{ action_0 }} Actions',
            OPEN_ACTIONS_2: 'Open {{ action_0 }} & {{ action_1 }} Actions',
            OPEN_ACTIONS_3: 'Open {{ action_0 }}, {{ action_1 }} & {{ action_2 }} Actions',
            OPEN_ACTIONS_4: 'Open {{ action_0 }}, {{ action_1 }}, {{ action_2 }} & {{ action_3 }} Actions',
            OPEN_ACTIONS_5: 'Open {{ action_0 }}, {{ action_1 }}, {{ action_2 }}, {{ action_3 }} & {{ action_4 }} Actions',
            OPEN_ACTIONS_6: 'Open {{ action_0 }}, {{ action_1 }}, {{ action_2 }}, {{ action_3 }}, {{ action_4 }} & {{ action_5 }} Actions',

            WIDGETS: 'Widgets',
            MANDATORY_DASHBOARD_WIDGETS: 'Mandatory Dashboard Widgets',
            DASHBOARD_WIDGETS: 'Dashboard Widgets',
            SELECT_DASHBOARD_WIDGETS: 'Select Dashboard Widgets',
            DASHBOARD_WIDGETS_UPDATED: 'Dashboard Widgets Updated',
            MANDATORY_WIDGET: 'Mandatory Dashboard Widget, unable to remove',

            DASHBOARD_STATISTICS_PERFORMANCE_STATISTICS: 'Performance Statistics',
            DASHBOARD_STATISTICS_CONTRACT_TIMELINES: 'Contract Timelines',
            DASHBOARD_STATISTICS_CONTRACT_RENEWALS: 'Contract Renewals',
            DASHBOARD_STATISTICS_CONTRACT_APPROVALS: 'Contract Approvals',
            DASHBOARD_STATISTICS_OPEN_ACTIONS: 'Open Actions',
            DASHBOARD_STATISTICS_JOB_REQUISITES_REQUIRING_APPROVAL: 'Job Requisites Requiring Approval',
            DASHBOARD_STATISTICS_OPEN_INTEGRATION_ACTIONS: 'Open Integration Actions',
            DASHBOARD_STATISTICS_P_2_P_JOBS: 'P2P Jobs',
            DASHBOARD_STATISTICS_SURVEYS: 'Outstanding KPI Surveys',
            DASHBOARD_STATISTICS_APPROVALS_REQUIRED: 'Outstanding Approvals',
            DASHBOARD_STATISTICS_OUTSTANDING_EXTENSION_APPROVALS: 'Outstanding Extension Approvals',
            DASHBOARD_STATISTICS_PENDING_ACKNOWLEDGEMENT: 'Pending Acknowledgement',
            DASHBOARD_STATISTICS_UPDATES_DUE: 'Updates Due',
            DASHBOARD_STATISTICS_AWAITING_CLIENT_REVIEW: 'Awaiting Client Review',
            DASHBOARD_STATISTICS_PLANNED_OVERDUE_COMPLETION: 'Planned Jobs Overdue Completion',
            DASHBOARD_STATISTICS_REACTIVE_OVERDUE_COMPLETION: 'Reactive Jobs Overdue Completion',
            DASHBOARD_STATISTICS_REACTIVE_OVERDUE_ATTENDANCE: 'Reactive Jobs Overdue Attendance',
            DASHBOARD_STATISTICS_PPM_REQUIRING_APPROVAL: 'Planned Jobs Requiring Approval',
            DASHBOARD_STATISTICS_ESCALATIONS: 'Escalations',
            DASHBOARD_STATISTICS_JOB_VALUES: 'Job Values',
            DASHBOARD_STATISTICS_OUTSTANDING_REMEDIALS: 'Outstanding Remedials',
            DASHBOARD_STATISTICS_ASSET_SCORE_TYPE_1: 'Asset Score Type 1',
            DASHBOARD_STATISTICS_ASSET_SCORE_TYPE_2: 'Asset Score Type 2',
            DASHBOARD_STATISTICS_ASSET_SCORE_TYPE_3: 'Asset Score Type 3',
            DASHBOARD_STATISTICS_OVERDUE_PAPERWORK: 'Jobs with Outstanding Paperwork',
            DASHBOARD_STATISTICS_PPM_REQUIRING_APPROVAL_SP: 'Tasks Pending Approval',
            DASHBOARD_STATISTICS_OUTSTANDING_EXTENSION_REQUESTS: 'Outstanding Extension Requests',
            DASHBOARD_STATISTICS_OPEN_FLAGGED_PRIORITY_JOBS: 'Flagged Priorities',

            NO_MORE_THAN_4_PRIORITIES_INCLUDED_IN_DASHBOARD: 'No more than 4 priorities included in dashboard widget'
        });
    }
})();
