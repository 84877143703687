(function() {
    'use strict';

    angular
        .module('elogbooks.user.absence-planners')
        .controller('AbsenceEntryStatusController', AbsenceEntryStatusController);

    AbsenceEntryStatusController.$inject = ['absenceEntryResponse'];

    function AbsenceEntryStatusController(absenceEntryResponse) {
        var vm = this;

        vm.absenceEntryResponse = absenceEntryResponse;
    }
})();
