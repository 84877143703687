app.config( function($translateProvider) {
    $translateProvider.translations('nl', {
        METER_MANUFACTURER_INFORMATION: 'Meter Merk informatie',

        ADD_NEW_METER_MANUFACTURER: 'Voeg een nieuw merk toe',
        EDIT_METER_MANUFACTURER: 'Wijzig merk meter',

        METER_MANUFACTURER_LIST: 'Meter merken',
        METER_MANUFACTURER_ADD: 'Voeg een nieuw merk toe',
        METER_MANUFACTURER_EDIT: 'Wijzig merk',

        METER_MANUFACTURER_CREATED: 'Meter merk succesvol aangemaakt',
        METER_MANUFACTURER_UPDATED: 'Meter merk succesvol aangepast',
        METER_MANUFACTURER_DELETED: 'Meter merk succesvol verwijderd',
    });
});
