angular.module('elogbooksServices').service('localeManager', function (lodash, $translate, $window, tmhDynamicLocale, angularLoad, amMoment) {

    var options = [
        'en-gb',
        'fr',
        'es',
        'de',
        'nl',
        'zh-hk'
    ];

    this.getOptions = function () {
        return options;
    };

    this.getDropDownOptions = function () {
        var dropdownOptions = [];

        angular.forEach(options, function (value) {
            dropdownOptions.push({
                title: $translate.instant(value),
                value: value
            });

        });

        return dropdownOptions;
    };

    this.set = function (locale) {
        if(typeof locale === 'undefined' || !lodash.includes(options, locale)) {
            locale = 'en-gb';
        }
        tmhDynamicLocale.set(locale);

        angularLoad.loadScript('/moment/'+locale+'.js').then(function() {
            amMoment.changeLocale(locale);
        });
        
        $window.localStorage.setItem('elogbooks.persist.locale', locale);
        $translate.use(locale);
        $translate.preferredLanguage(locale);
    };

    this.get = function()
    {
        return $window.localStorage.getItem('elogbooks.persist.locale');
    }


    return this;
});
