(function () {
    'use strict';

    angular
        .module('elogbooks.admin.site-note-types')
        .controller('SiteNoteTypesAddEditController', SiteNoteTypesAddEditController);

    SiteNoteTypesAddEditController.$inject = ['$state', '$filter', 'apiClient', 'confirmationModal', 'siteNoteTypeResponse', 'requestDataFactory', 'messenger', 'siteNoteTypeCollectionResponse'];

    function SiteNoteTypesAddEditController ($state, $filter, apiClient, confirmationModal, siteNoteTypeResponse, requestDataFactory, messenger, siteNoteTypeCollectionResponse) {
        var vm = this;
            vm.siteNoteType = siteNoteTypeResponse;
            vm.siteNoteTypeCollectionResponse = siteNoteTypeCollectionResponse;

        vm.create = create;
        vm.update = update;
        vm.delete = deleteType;

        vm.data = requestDataFactory.createRequest({
            name: vm.siteNoteType.name || null,
            description: vm.siteNoteType.description || null,
            active: vm.siteNoteType.active !== null ? vm.siteNoteType.active : null,
            allowNotApplicable: vm.siteNoteType.allowNotApplicable === true ? true : false,
            allowRequireFm: vm.siteNoteType.allowRequireFm === true ? true : false,
            allowHelpdesk: vm.siteNoteType.allowHelpdesk === true ? true : false,
            visibility : vm.siteNoteType.visibility || 0,
            displayOrder: (vm.siteNoteTypeCollectionResponse.count || 0) + 1
        });

        vm.visibilities = [
            {
                title: $filter('translate')('VISIBILITY_PRIVATE'),
                value: 0
            },
            {
                title: $filter('translate')('VISIBILITY_PUBLIC'),
                value: 1
            },
            {
                title: $filter('translate')('VISIBILITY_TENANT'),
                value: 2
            },
            {
                title: $filter('translate')('VISIBILITY_SERVICE_PROVIDER'),
                value: 3
            }
        ];

        function update () {
            return apiClient.update(vm.siteNoteType.getLink('edit'), vm.data).then(function (response) {
                if (response) {
                    $state.go('^', {}, { reload: 'dashboard.admin.site-note-types.list' }).then(function () {
                        messenger.success('SITE_NOTE_TYPE_UPDATED');
                    });
                } else {
                    messenger.error('REQUEST_ERROR');
                }
            });
        }

        function create () {
            return apiClient.create(vm.siteNoteTypeCollectionResponse.getLink('create'), vm.data).then(function (response) {
                if (response) {
                    $state.go('^', {}, { reload: 'dashboard.admin.site-note-types.list' }).then(function () {
                        messenger.success('SITE_NOTE_TYPE_CREATED');
                    });
                } else {
                    messenger.error('REQUEST_ERROR');
                }
            });
        }

        function deleteType () {
            return confirmationModal.open().result.then(function (response) {
                if (response.result) {
                     apiClient.delete(vm.siteNoteType.getLink('delete')).then(function (isSuccess) {
                        if (isSuccess) {
                            $state.go('dashboard.admin.site-note-types.list', {}, { reload: 'dashboard.admin.site-note-types.list' }).then(function(){
                                messenger.success('SITE_NOTE_TYPE_DELETED');
                            });
                        } else {
                            messenger.error('REQUEST_ERROR');
                        }
                    });
                }

                return;
            });
        }
    };
})();
