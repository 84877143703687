angular.module('elogbooksDirectives').directive('elbPlannerSwitch', [
    '$compile',
    'modulesService',
    function ($compile, modulesService) {
        //TODO remove me when legacy support removed
        return {
            restrict: 'A',
            compile: function (element, attrs) {

                return function (scope, element) {
                    var planner = modulesService.isEnabled('advanced_ppm_planner') ?
                        'elb-planner-advanced' :
                        'elb-planner-legacy';

                    // This allows us to pass all our attributes without having to define them one-by-one in scope
                    var x = angular.element('<div ' + planner + '></div>');
                    angular.forEach(element.get(0).attributes, function (attr) {
                        // Prevent infinite recursion
                        if (attr.name === 'elb-planner-switch') {
                            return;
                        }
                        x.attr(attr.name, attr.value);
                    });
                    element.append(x);
                    $compile(x)(scope);
                };
            }
        };
    }
]);