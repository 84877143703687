(function () {
    'use strict';

    angular
        .module('elogbooksDirectives')
        .directive('elbSimpleTextSearchForm', ElbSimpleTextSearchForm);

    ElbSimpleTextSearchForm.$inject = [];

    function ElbSimpleTextSearchForm () {
        return {
            restrict: 'AE',
            scope: {
                onSearch: '=',
                searchText: '='
            },
            templateUrl: '/modules/directives/elb-simple-text-search-form/search-form.html',
            controller: function($scope, $state, $attrs) {
                $scope.placeholder = $attrs.placeholder || 'SEARCH';
                $scope.searching = false;
                $scope.isFiltered = !!$scope.searchText.length;

                $scope.submit = (searchText) => new Promise((resolve, reject) => {
                    $scope.searching = true;
                    const result = $scope.onSearch(searchText);

                    if (result && (typeof result?.then === 'function')) {
                        result.then(() => {
                            $scope.searching = false;
                            $scope.isFiltered = !!searchText?.length;
                            resolve();
                        });
                    } else {
                        $scope.searching = false;
                        $scope.isFiltered = !!searchText?.length;
                        resolve();
                    }
                });

                $scope.clear = () => {
                    $scope.searchText = '';

                    if ($scope.isFiltered) {
                        $scope.submit(null).then(() => {
                            $scope.isFiltered = false;
                        });
                    }
                }
            }
        };
    }
})();
