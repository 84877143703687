(function() {
    'use strict';

    angular
        .module('elogbooks.user.finance.purchase-invoices')
        .controller('FinancePurchaseInvoicesDetailsController', FinancePurchaseInvoicesDetailsController);

    FinancePurchaseInvoicesDetailsController.$inject = [];
    function FinancePurchaseInvoicesDetailsController() {
        var vm = this;
    }
})();
