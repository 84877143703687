(function (){
    'use strict';

    angular
        .module('elogbooks.user.tasks')
        .controller('UserTaskPlannerController', UserTaskPlannerController);

    UserTaskPlannerController.$inject = ['$state', '$stateParams', 'serviceProviderCollection', 'taskResponse', 'taskGenerateRequestsCollectionResource', 'tidyTablesService'];

    function UserTaskPlannerController($state, $stateParams, serviceProviderCollection, taskResponse, taskGenerateRequestsCollectionResource, tidyTablesService) {
        var vm = this;
        //To make planner directive working keep the structure.
        taskGenerateRequestsCollectionResource.tasks = taskGenerateRequestsCollectionResource.taskGenerateRequests;
        vm.tasks = taskGenerateRequestsCollectionResource;
        vm.task = taskResponse;
        vm.taskGenerateRequests = taskGenerateRequestsCollectionResource;
        vm.year = $stateParams.taskYear;
        vm.filter = filterAction;

        vm.serviceProviderSelect = {
            response : serviceProviderCollection,
            link : serviceProviderCollection.getLink('self'),
            responseKeyPath: 'serviceproviders'
        };

        vm.tidyTables = {
            name: 'task-planner',
            columns : {
                'DATE_FROM': true,
                'DATE_TO': true,
                'ACTIONS': true
            }
        };

        var localTidyTable = tidyTablesService.getTidyTable(vm.tidyTables.name);

        if (localTidyTable) {
            vm.tidyTables = localTidyTable;
        }

        vm.criteria = {
            year: { type: 'number', title: 'YEAR', value: parseInt($stateParams.year), min: 2000 }
        };

        function filterAction (params) {
            $state.go('.', angular.extend({}, params), { reload: $state.current.name });
        }
    }
})();
