(function () {
    'use strict';

    angular
        .module('elogbooks.user.timesheets')
        .controller('TimeSheetItemLabourModalController', TimeSheetItemLabourModalController);

    TimeSheetItemLabourModalController.$inject = [
        '$uibModalInstance',
        'requestDataFactory',
        'apiClient',
        'labourTypeCollectionResponse',
        'config',
        'messenger',
        'confirmationModal'
    ];

    function TimeSheetItemLabourModalController(
        $uibModalInstance,
        requestDataFactory,
        apiClient,
        labourTypeCollectionResponse,
        config,
        messenger,
        confirmationModal
    ) {
        var vm = this;
        vm.labourTypeCollectionResponse = labourTypeCollectionResponse;
        vm.cost = config.cost || {};
        if (vm.labourTypeCollectionResponse) {
            vm.labourTypeModel = {
                response: vm.labourTypeCollectionResponse,
                link: vm.labourTypeCollectionResponse.getLink('self'),
                responseKeyPath: 'labourTypes',
                itemHrefPath: '_links.self.href',
                itemValuePath: 'name'
            };
        }

        if (vm.cost.getLink('labourType') && vm.labourTypeModel) {
            var labourType = vm.cost._links.labourType
            vm.labourTypeModel.selected = {
                href: labourType.href,
                title: labourType.name,
                object: labourType
            };
        }

        vm.data = requestDataFactory.createRequest({
            startDate: new Date(vm.cost.startDate),
            mileage: vm.cost.mileage,
            description: vm.cost.description || '',
            _links: {
                labourType: vm.labourTypeModel && vm.labourTypeModel.selected ? vm.labourTypeModel.selected : null,
            }
        });

        vm.minutesWorkedHH = ((vm.cost.minutesWorked - vm.cost.minutesWorked % 60)) / 60;
        vm.minutesWorkedMM = vm.cost.minutesWorked % 60;
        vm.minutesTravelledHH = ((vm.cost.minutesTravelled - vm.cost.minutesTravelled % 60)) / 60;
        vm.minutesTravelledMM = vm.cost.minutesTravelled % 60;

        vm.cancel = function () {
            $uibModalInstance.close();
        }

        vm.update = function () {
            if (vm.labourTypeModel && vm.labourTypeModel.selected && vm.labourTypeModel.selected.href) {
                vm.data._links.labourType = vm.labourTypeModel.selected;
            }
            vm.data.minutesWorked = (vm.minutesWorkedHH || 0) * 60 + (vm.minutesWorkedMM || 0);
            vm.data.minutesTravelled = (vm.minutesTravelledHH || 0) * 60 + (vm.minutesTravelledMM || 0);
            apiClient.update(vm.cost.getLink('edit'), vm.data).then(function (response) {
                if (response) {
                    messenger.success('TIMESHEET_LABOUR_LINE_UPDATED');
                    $uibModalInstance.close({
                        action: {method: 'update', message: response.getData().message}
                    });
                } else {
                    messenger.error('REQUEST_ERROR');
                }
            })
        }

        vm.create = function () {
            return vm.update();
        }

        vm.delete = function () {
            confirmationModal.open().result.then(function (response) {
                if (response.result) {
                    apiClient.delete(vm.cost.getLink('delete')).then(function (response) {
                        if (response) {
                            messenger.success('TIMESHEET_LABOUR_LINE_DELETED');
                            $uibModalInstance.close({
                                action: {method: 'delete', message: response.getData().message}
                            });
                        } else {
                            messenger.error('REQUEST_ERROR');
                        }
                    })
                }
            });
        }

        vm.inputChange = function () {
            if (vm.minutesWorkedMM < 0) {
                vm.minutesWorkedMM = 0;
            }

            if (vm.minutesWorkedMM > 59) {
                vm.minutesWorkedMM = 59;
            }

            if (vm.minutesTravelledMM < 0) {
                vm.minutesTravelledMM = 0;
            }

            if (vm.minutesTravelledMM > 59) {
                vm.minutesTravelledMM = 59;
            }

            if (vm.minutesWorkedHH < 0) {
                vm.minutesWorkedHH = 0;
            }

            if (vm.minutesTravelledHH < 0) {
                vm.minutesTravelledHH = 0;
            }
        };
    }
})();
