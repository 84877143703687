app.config(function ($translateProvider) {
   $translateProvider.translations('nl', {

        SITE_THRESHOLD: 'Authorisatie grens',
        SITE_THRESHOLDS: 'Authorisatie grenzen',

        SITE_THRESHOLD_ADD: 'Maak een nieuwe authorisatie grens',
        SITE_THRESHOLD_LIST: 'Authorisatie grenzen',
   })
});
