/**
 * Panel
 */
angular.module('elogbooksDirectives').directive('panel', ['$http', 'apiClient', '$state', 'lodash', function ($http, apiClient, $state, lodash) {
    return {
        restrict: 'AE',
        replace: true,
        transclude: true,
        templateUrl: '/modules/directives/layout/panel/panel.html',
        link: function ($scope, $element, $attrs) {
            $scope.panel = {
                noBorder: lodash.get($attrs, 'noBorder') ? $scope.$eval($attrs.noBorder) : lodash.has($attrs, 'noBorder'),
                divider: lodash.get($attrs, 'divider') ? $scope.$eval($attrs.divider) : true
            }
        },
        controller: ['$scope', function ($scope) {
        }]
    };
}]);
