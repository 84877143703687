(function(){
    'use strict';

    angular
        .module('elogbooks.admin.service-types')
        .controller('AdminServiceTypesController', AdminServiceTypesController);

    function AdminServiceTypesController($state, $stateParams, serviceTypesCollectionResponse) {
        var vm = this;

        vm.serviceTypesCollectionResponse = serviceTypesCollectionResponse;
        vm.serviceTypes = serviceTypesCollectionResponse.serviceTypes;
        vm.order = order;
        vm.search = search;

        vm.criteria = {
            id: { type: 'number', title: 'ID', value: $stateParams.id ? parseInt($stateParams.id, 10) : null, min: 1 },
            name: { type: 'text', title: 'NAME', value: $stateParams.name },
        };

        function order (key) {
            $stateParams.order = key;

            $state.go('.', angular.extend({}, $stateParams), { reload: true });
        }

        function search (params) {
            var override = {
                page: 1
            };

            $state.go('.', angular.extend({}, params, override), { reload: true });
        }
    }
})();
