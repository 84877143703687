/**
 * Page Footer Directive
 */
angular.module('elogbooksDirectives').directive('pageFooter', [function () {
    return {
        restrict: 'E',
        replace: true,
        templateUrl: '/modules/directives/layout/page-footer/page-footer.html'
    };
}]);
