(function () {
    "use strict";

    angular.module("elogbooks").config(Translations);

    function Translations($translateProvider) {
        $translateProvider.translations("en-gb", {
            UPDATES_AVAILABLE_HEADER: "Updates Available",
            UPDATES_AVAILABLE_BODY:
                "There are updates available to Elogs CAFM and your browser will be reloaded in 5 minutes to apply them. You can postpone this update and continue with what you are doing; however, you may experience issues with Elogs CAFM.",
            UPDATE_NOW: "Update Now",
            UPDATE_POSTPONE: "Postpone Update",
        });
    }
})();
