(function () {
    'use strict';

    angular
        .module('elogbooks.common.webhooks')
        .controller('WebhooksDetailsInfoController', WebhooksDetailsInfoController);

    WebhooksDetailsInfoController.$inject = ['config', 'webhookResponse', 'serviceProviderResponse'];

    function WebhooksDetailsInfoController (config, webhookResponse, serviceProviderResponse) {
        var vm = this;
        vm.webhook = webhookResponse;
        vm.type = 'view';
        if (webhookResponse.id) {
            vm.webhook.hasResponse = true;
        } else {
            vm.type = 'list';
            vm.webhook.hasResponse = false;
            vm.webhook.canCreate = serviceProviderResponse.getLink('add-webhook');
        }

        vm.config = config;

        vm.noBorder = vm.config.noBorder || false;
    }
})();
