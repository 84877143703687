(function(){
    'use strict';

    angular
        .module('elogbooks.user.duty-management')
        .config(registerRoutes);

    function registerRoutes($stateProvider) {
        $stateProvider
            .state('dashboard.user.dutymanagement', {
                abstract: true,
                views: {
                    '@dashboard': {
                        template: '<div ui-view class="duty-management"></div>',
                    }
                },
                data: {
                    authorisations: ['user_permission_view_duty_management_schedules']
                },
                ncyBreadcrumb: {
                    skip: true
                }
            })
            .state('dashboard.user.dutymanagement.manager', {
                url: '/duty-management?year&month&group',
                parent: 'dashboard.user.dutymanagement',
                views: {
                    '@dashboard.user.dutymanagement': {
                        templateUrl: '/modules/user/duty-management/duty-management.html',
                        controller: 'DutyManagementController',
                        controllerAs: 'vm'
                    }
                },
                params: {
                    year : (new Date()).getFullYear().toString(),
                    month : (new Date()).getMonth().toString(),
                    group : 'true'
                },
                resolve: {
                    siteResource: function ($stateParams, user, apiClient) {
                        if($stateParams.selectedSiteResource) {
                            return apiClient.get($stateParams.selectedSiteResource.decode());
                        }

                        return null;
                    },
                    dutyManagementScheduleCollection : function ($stateParams, apiClient, rootResourceResponse, siteResource) {
                        var date = new Date(),
                            y = $stateParams.year ? $stateParams.year : date.getFullYear(),
                            m = $stateParams.month ? $stateParams.month :date.getMonth();

                        $stateParams = {
                            startDate : moment(new Date(y, m, 1)).format("YYYY-MM-DD"),
                            endDate : moment(new Date(y, m + 1, 0)).format("YYYY-MM-DD"),
                            group : true,
                        };

                        if (siteResource) {
                            $stateParams.site = siteResource.id;
                        }

                        return apiClient.get(rootResourceResponse.getLink('dutymanagementschedules'), $stateParams);
                    },
                    //userCollectionResponse : function (apiClient, rootResourceResponse) {
                    //    return apiClient.get(rootResourceResponse.getLink('users'), {membershipPermission: 'site_permission_can_be_associate'});
                    //}
                },
                ncyBreadcrumb: {
                    label: '{{ ::"DUTY_MANAGEMENT" | translate }}'
                }
            })
            .state('dashboard.user.dutymanagement.manager.add', {
                url: '/add',
                parent: 'dashboard.user.dutymanagement.manager',
                views: {
                    '@dashboard.user.dutymanagement': {
                        templateUrl: '/modules/user/duty-management/add/add.html',
                        controller: 'DutyManagementAddController',
                        controllerAs: 'vm'
                    }
                },
                resolve: {
                    siteCollectionResponse : function (apiClient, user) {
                        return apiClient.get(user.getLink('sites'));
                    },
                    userCollectionResponse : function (apiClient, rootResourceResponse) {
                        return apiClient.get(rootResourceResponse.getLink('users'), {membershipPermission: 'site_permission_can_be_associate', limit:10});
                    }
                },
                ncyBreadcrumb: {
                    label: '{{ ::"DUTY_MANAGEMENT_ADD" | translate }}'
                }
            })
            .state('dashboard.user.dutymanagement.details', {
                url: '/{resource}?page',
                parent: 'dashboard.user.dutymanagement.manager',
                views: {
                    '@dashboard.user.dutymanagement': {
                        templateUrl: '/modules/user/duty-management/details/duty-management-details.html',
                        controller: 'DutyManagementDetailsController',
                        controllerAs: 'vm'
                    }
                },
                params: {
                    page : '1',
                    name: { value: null, type: 'string' },
                    reference: { value: null, type: 'string' }
                },
                resolve: {
                    dutyManagementScheduleResponse : function (apiClient, $stateParams) {
                        return apiClient.get($stateParams.resource.decode());
                    },
                    siteCollectionResponse : function (apiClient, $stateParams, dutyManagementScheduleResponse) {
                        return apiClient.get(dutyManagementScheduleResponse.getLink('sites'), $stateParams);
                    }
                },
                ncyBreadcrumb: {
                    label: '{{ ::"DETAILS" | translate }}'
                }
            })
            .state('dashboard.user.dutymanagement.details.sites', {
                url: '/sites',
                parent: 'dashboard.user.dutymanagement.details',
                views: {
                    '@dashboard.user.dutymanagement': {
                        templateUrl: '/modules/user/duty-management/sites/duty-management-sites.html',
                        controller: 'DutyManagementSitesController',
                        controllerAs: 'vm'
                    }
                },
                resolve: {
                    dutyManagementScheduleResponse : function (apiClient, $stateParams) {
                        return apiClient.get($stateParams.resource.decode());
                    },
                    siteCollectionResponse : function (apiClient, user) {
                        return apiClient.get(user.getLink('sites'));
                    }
                },
                ncyBreadcrumb: {
                    label: '{{ ::"SITES" | translate }}'
                }
            })
            .state('dashboard.user.dutymanagement.details.user', {
                url: '/user',
                parent: 'dashboard.user.dutymanagement.details',
                views: {
                    '@dashboard.user.dutymanagement': {
                        templateUrl: '/modules/user/duty-management/user/duty-management-user.html',
                        controller: 'DutyManagementUserController',
                        controllerAs: 'vm'
                    }
                },
                resolve: {
                    dutyManagementScheduleResponse : function (apiClient, $stateParams) {
                        return apiClient.get($stateParams.resource.decode());
                    },
                    userCollectionResponse : function (apiClient, rootResourceResponse) {
                        return apiClient.get(rootResourceResponse.getLink('users'), {membershipPermission: 'site_permission_can_be_associate', limit:10});
                    }
                },
                ncyBreadcrumb: {
                    label: '{{ ::"USER" | translate }}'
                }
            });
    }
})();
