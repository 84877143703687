(function () {
    'use strict';

    angular
        .module('elogbooksControllers')
        .controller('OperativeAddModalController', OperativeAddModalController);

    OperativeAddModalController.$inject = ['$uibModalInstance', '$state', 'operativeCollectionResponse', 'apiClient', 'serviceProviderOperativeCollectionResponse', 'messenger', 'config'];

    function OperativeAddModalController($uibModalInstance, $state, operativeCollectionResponse, apiClient, serviceProviderOperativeCollectionResponse, messenger, config) {
        var vm = this;
        vm.confirm = confirm;
        vm.close = close;
        vm.submit = submit;

        vm.data = {
            _links: {},
            permissions: [],
            useDefaultManageOperativePermissions: config.useDefaultManageOperativePermissions
        };

        vm.operativeSelect = {
            response: operativeCollectionResponse,
            responseKeyPath: 'operatives',
            itemHrefPath: '_links.self.href',
            itemValuePath: '_links.user.name',
            required: true
        };

        function submit() {
            vm.data._links.user = {
                href: vm.operativeSelect.selected.object._links.user.href
            };
            if (serviceProviderOperativeCollectionResponse.getLink('create')) {
                apiClient.create(serviceProviderOperativeCollectionResponse.getLink('create'), vm.data).then(function (response) {
                    if (response) {
                        close();
                        $state.go('.', {}, { reload: true }).then(function() {
                            messenger.success('OPERATIVE_CREATED');
                        });
                    }
                });
            }
        }
        
        function close() {
            $uibModalInstance.dismiss(vm.instance);
        }

        function confirm() {
            $uibModalInstance.close(vm.instance);
        }
    }
})();
