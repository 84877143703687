(function () {
    'use strict';

    angular
        .module('elogbooks.common.billing-settings')
        .controller('CommonBillingSettingsAddEditController', CommonBillingSettingsAddEditController);

    CommonBillingSettingsAddEditController.$inject = [
        'billingSettingsResponse',
        'pricingSchedulesResponse',
        'relatedCoreClientBillingSettingsResponse',
        'lodash',
        'crudService',
        'requestDataFactory',
        'contactInformationService',
        '$state',
        '$scope'
    ];
    function CommonBillingSettingsAddEditController(
        billingSettingsResponse,
        pricingSchedulesResponse,
        relatedCoreClientBillingSettingsResponse,
        lodash,
        crudService,
        requestDataFactory,
        contactInformationService,
        $state,
        $scope
    ) {
        var vm = this;
        vm.billingSettingsResponse = billingSettingsResponse;
        vm.relatedCoreClientBillingSettings = relatedCoreClientBillingSettingsResponse;
        vm.pricingSchedulesResponse = pricingSchedulesResponse;

        vm.billingSettings = billingSettingsResponse.billingsettings.length ? lodash.first(billingSettingsResponse.billingsettings) : {};
        vm.type = $state.$current.parent.parent.self.parent.split('.')[2] || 'unknown';
        vm.showUseCoreClient = vm.type === 'sites' && vm.relatedCoreClientBillingSettings.id;

        vm.data = requestDataFactory.createRequest(getDefaults());

        vm.relatedPricingScheduleModel = {
            populateDisabled: true,
            response: vm.pricingSchedulesResponse,
            link: vm.pricingSchedulesResponse.getLink('self'),
            responseKeyPath: 'pricingSchedules',
            itemHrefPath: '_links.self.href',
            itemValuePath: 'summary',
            onSelect: changePricingSchedule,
            onRemove: changePricingSchedule,
            disabled:vm.data.useCoreClientSettings
        };

        if (vm.data.relatedPricingSchedule) {
            vm.relatedPricingScheduleModel.selected = {
                href: vm.billingSettingsResponse.getLink('self'),
                title: vm.data.relatedPricingSchedule,
                object: vm.data._links.relatedpricingschedule
            };
        }

        vm.create = createAction;
        vm.update = updateAction;

        $scope.$watch('vm.data.secondaryStageApproval', function(newValue, oldValue) {
            if (!newValue) {
                vm.data.poApprovalToleranceAmount = null;
            }
        });

        $scope.$watch('vm.data.useCoreClientSettings', function(newValue, oldValue) {
            if (newValue !== oldValue){
                var model = vm.data.useCoreClientSettings ? vm.relatedCoreClientBillingSettings : vm.billingSettings;

                angular.extend(vm.data, {
                    billingEntityName: model.billingEntityName ||  null,
                    address: model.address || {},
                    email: model.email || null,
                    billingApprovalChases: model.billingApprovalChases || null,
                    batchJobsOnInvoice: model.batchJobsOnInvoice || null,
                    externalReference: model.externalReference || null,
                    relatedPricingSchedule: model.relatedPricingSchedule || null,
                    poRequired: model.poRequired || false,
                    autoInvoice: model.autoInvoice || false,
                    poApprovalToleranceAmount: model.poApprovalToleranceAmount || null,
                    secondaryStageApproval: !!model.poApprovalToleranceAmount,
                    _links: {
                        relatedpricingschedule: model._links && model._links.relatedpricingschedule ? model._links.relatedpricingschedule.href : null
                    }
                });

                if (model.hasOwnProperty('relatedPricingSchedule')) {
                    vm.relatedPricingScheduleModel.selected = {
                        href: model.getLink('self'),
                        title: model.relatedPricingSchedule,
                        object: model._links.relatedpricingschedule
                    }
                } else  {
                    vm.relatedPricingScheduleModel.selected = null;
                }

                vm.relatedPricingScheduleModel.disabled = newValue
            }
        });

        /**
         * Update related pricing schedule link
         */
        function changePricingSchedule() {
            if (vm.relatedPricingScheduleModel.selected && vm.relatedPricingScheduleModel.selected.object) {
                vm.data._links.relatedpricingschedule = vm.relatedPricingScheduleModel.selected.object.getLink('self');
            } else {
                vm.data._links.relatedpricingschedule = null;
            }
        }

        /**
         * Adds processed contact information and amends payload if using core client billing settings
         * @param {*} data
         * @returns object
         */
         function processUseCoreClientSettingsData(data) {
            data.contactInformation = contactInformationService.processContactInformation(data);

            if (!data.secondaryStageApproval) {
                delete data.poApprovalToleranceAmount;
            }

            return data;
        }

        /**
         * Generates default data
         * @returns object
         */
        function getDefaults() {
            var useCoreClient = !vm.billingSettings.id && vm.relatedCoreClientBillingSettings.id ? true : vm.billingSettings.useCoreClientSettings || false;
            var model = useCoreClient ? vm.relatedCoreClientBillingSettings : vm.billingSettings;

            return {
                id: vm.billingSettings.id || null,
                useCoreClientSettings: useCoreClient,
                billingEntityName: model.billingEntityName || null,
                address: model.address || {},
                email: model.email || null,
                billingApprovalChases: model.billingApprovalChases || null,
                batchJobsOnInvoice: model.batchJobsOnInvoice || null,
                externalReference: model.externalReference || null,
                relatedPricingSchedule: model.relatedPricingSchedule || null,
                poRequired: model.poRequired || false,
                autoInvoice: model.autoInvoice || false,
                poApprovalToleranceAmount: model.poApprovalToleranceAmount || null,
                secondaryStageApproval: !!model.poApprovalToleranceAmount,
                _links: {
                    relatedpricingschedule: model._links && model._links.relatedpricingschedule ? model._links.relatedpricingschedule.href : null
                }
            }
        }

        function updateAction() {
            var data = processUseCoreClientSettingsData(vm.data);

            return crudService.update(
                vm.billingSettings.getLink('edit'),
                data,
                $state.$current.parent.self.name,
                $state.$current.parent.self.name,
                'BILLING_SETTINGS'
            );
        }

        function createAction() {
            var data = processUseCoreClientSettingsData(vm.data);

            return crudService.create(
                vm.billingSettingsResponse.getLink('create'),
                data,
                $state.$current.parent.self.name,
                null,
                'BILLING_SETTINGS'
            );
        }
    }
})();
