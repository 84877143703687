;(function() {
    'use strict';

    angular
        .module('elogbooks.common.files')
        .controller('CommonFilesAbstractController', CommonFilesAbstractController);

    CommonFilesAbstractController.$inject = ['abstractConfig'];

    function CommonFilesAbstractController(abstractConfig) {
        var vm = this;
        vm.abstractConfig = abstractConfig;
    }
})();
