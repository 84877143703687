(function () {
    'use strict';

    angular
        .module('elogbooksDirectives')
        .directive('auditMarkNaAnswers', AuditMarkNaAnswersDirective);

    function AuditMarkNaAnswersDirective() {
        return {
            restrict: 'E',
            scope: {
                auditSection: '=',
                audit: '=',
                callback: '='
            },
            transclude: true,
            templateUrl: '/modules/directives/audits/mark-na-answers/mark-na-answers.html',
            controller: function ($scope, apiClient, $translate, messenger) {
                $scope.loading = false;
                $scope.isAvailable = !$scope.audit.isTemplate && $scope.audit.status !== 'AUDIT_DRAFT';
                $scope.markNASectionLink = $scope.auditSection.getLink('mark-section-as-na');
                $scope.unmarkNASectionLink = $scope.auditSection.getLink('unmark-section-as-na');
                $scope.markSectionAsNaDisabled = !$scope.markNASectionLink && !$scope.unmarkNASectionLink;

                $scope.makeNaAnswers = function(link) {
                    if ($scope.loading) { // prevent double-click
                        return;
                    }

                    $scope.loading = true;

                    apiClient.create(link, {}).then(function(response) {
                        if (response) {
                            // start the audit
                            if (!$scope.audit.startedAt) {
                                $scope.audit.startedAt = new Date();
                            }

                            // update audit section with new data
                            $scope.auditSection = angular.extend($scope.auditSection, response);

                            var type = (link === $scope.markNASectionLink) ? 'mark-as-na' : 'unmark-as-na';

                            $scope.callback($scope.auditSection).then(function() {
                                if (type === 'mark-as-na') {
                                    messenger.success('MARKED_AS_NA_SUCCESS');
                                }

                                if (type === 'unmark-as-na') {
                                    messenger.success('UNMARKED_AS_NA_SUCCESS');
                                }
                            }).catch(function(error) {
                                console.error(error);
                            });

                        } else {
                            messenger.error('REQUEST_ERROR');
                        }
                    });
                };
            }
        };
    }

})();
