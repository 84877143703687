app.config(function ($translateProvider) {
    $translateProvider.translations('zh-hk', {
        FILE: '檔案',
        FILES: '檔案',

        FILE_ADD: '新增檔案',
        FILE_EDIT: '修改檔案',
        FILE_LIST: '檔案',

        FILE_CREATED: '檔案已建立',
        FILE_UPDATED: '檔案已更新',
        FILE_DELETED: '檔案已刪除',

        FILE_NAME: '檔案名稱',

        UPLOADED_BY: '上載者',
        UPLOADED_DATE_START: '開始上載日期',
        UPLOADED_DATE_END: '完成上載日期',
        EXPIRES_DATE_START: '開始到期日',
        EXPIRES_DATE_END: '完成到期日',

        FILE_DOWNLOAD: '下載',
    });
});
