(function() {
    'use strict';

    angular
        .module('elogbooks.user.finance.purchase-invoices')
        .controller('FinancePurchaseInvoicesStatusController', FinancePurchaseInvoicesStatusController);

    FinancePurchaseInvoicesStatusController.$inject = [
        'purchaseInvoiceResponse'
    ];

    function FinancePurchaseInvoicesStatusController(
        purchaseInvoiceResponse
    ) {
        var vm = this;
        vm.purchaseInvoice = purchaseInvoiceResponse;
    }
})();
