(function () {
    'use strict';

    angular
        .module('elogbooks.admin.sites')
        .controller('PartnershipsController', PartnershipsController);

    PartnershipsController.$inject = ['partnershipsResponse', 'apiClient', '$state', 'messenger', '$stateParams'];

    function PartnershipsController (partnershipsResponse, apiClient, $state, messenger, $stateParams) {
        var vm = this;
            vm.partnerships = partnershipsResponse.getData().partnerships;
            vm.partnershipsResponse = partnershipsResponse;
            vm.originalPartnerships = angular.copy(vm.partnerships);
            vm.partnershipsChanged = false;
            vm.order = order;

            vm.partnershipsResponse.canCreate = vm.partnershipsResponse.getLink('createSitePartnership') || vm.partnershipsResponse.getLink('createServiceProviderPartnership')

        vm.reset = function () {
            vm.partnerships = angular.copy(vm.originalPartnerships);
            vm.partnershipsChanged = false;
        };

        vm.remove = function (key) {
            vm.partnerships.splice(key, 1);
            vm.partnershipsChanged = true;
        };

        vm.update = function () {
            var data = {
                _links: {
                    partnerships: []
                }
            };

            angular.forEach(vm.partnerships, function (value, key) {
                data._links.partnerships.push(value._links.self);
            });

            apiClient.update(partnershipsResponse.getLink('self'), data).then(function (response) {
                if (response) {
                    $state.go('.', {}, { reload: 'dashboard.admin.sites.list.details.partnerships' }).then(function () {
                        messenger.success('PARTNERSHIPS_UPDATED');
                    });
                } else {
                    messenger.error('REQUEST_ERROR');
                }
            });
        };

        function order (key) {
            $stateParams.partnershipOrder = key;

            $state.go('.', angular.extend({}, $stateParams), { reload: $state.current.name });
        }
    };
})();
