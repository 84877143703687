angular.module('elogbooksServices').service('confirmationDateModal', ['$uibModal', function ($uibModal) {
    return {
        open: function (config) {
            return $uibModal.open({
                templateUrl: '/modules/common/modals/confirmation-date/confirmation.html',
                controller: 'ConfirmationDateModalController',
                controllerAs: 'vm',
                resolve: {
                    config: function () {
                        var defaultConfig = {
                            titleMessage: 'ARE_YOU_SURE_HEADER',
                            bodyMessage: 'ARE_YOU_SURE',
                            primaryText: 'SUBMIT',
                            primaryClass: 'btn-danger',
                            secondaryText: 'CANCEL',
                            secondaryClass: 'btn-default',
                            date: null,
                            minDate: null,
                            maxDate: null
                        };

                        return angular.extend({}, defaultConfig, config);
                    }
                }
            });
        }
    };
}]);
