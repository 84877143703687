;(function() {
    'use strict';

    angular
        .module('elogbooks.common.files')
        .controller('CommonManageFilesController', CommonManageFilesController);

    CommonManageFilesController.$inject = ['sectionResource'];

    function CommonManageFilesController(sectionResource) {
        var vm = this;
        vm.sectionResource = sectionResource;
    }
})();
