(function () {
    'use strict';

    angular
        .module('elogbooks.admin.sites')
        .controller('LocationAddEditController', LocationAddEditController);

    LocationAddEditController.$inject = [
        '$state',
        'messenger',
        'apiClient',
        'requestDataFactory',
        'confirmationModal',
        'locationResponse',
        'parentLocationResponse',
        'locationsCollectionResponse',
        'billingTypeCollectionResponse',
        'user'
    ];

    function LocationAddEditController (
        $state,
        messenger,
        apiClient,
        requestDataFactory,
        confirmationModal,
        locationResponse,
        parentLocationResponse,
        locationsCollectionResponse,
        billingTypeCollectionResponse,
        user
    ) {

        var vm = this;
            vm.location = locationResponse;
            vm.address = typeof locationResponse.contactInformation === 'undefined' ? null : locationResponse.contactInformation.addresses[0] || null;
            vm.create = createAction;
            vm.update = updateAction;
            vm.delete = deleteAction;
            vm.changeBillingType = changeBillingType;

        if (Object.keys(locationResponse).length !== 0) {
            vm.cancelRoute = '^.info' + "({locationResource: '" + locationResponse.getLink('self').encode() + "'})";
        } else if (Object.keys(parentLocationResponse).length !== 0) {
            vm.cancelRoute = '^.details.info' + "({locationResource: '" + parentLocationResponse.getLink('self').encode() + "'})";
        } else {
            vm.cancelRoute = '^';
        }

        vm.data = requestDataFactory.createRequest({
            _links: {
                billingtype: vm.location._links ? vm.location._links.billingtypes : null
            },
            name: vm.location.name || null,
            reference: vm.location.reference || null,
            description: vm.location.description || null,
            barcode: vm.location.barcode || null,
            active: typeof vm.location.active === 'boolean' ? vm.location.active : null,
            billingCost: parseFloat(vm.location.billingCost) !== null ? parseFloat(vm.location.billingCost) : null,
            addresses: []
        });

        vm.billingTypes = false;
        vm.billingCost = false;

        if (user.permissions) {
            vm.billingTypes = user.permissions.indexOf('user_permission_manage_billing_types') > -1;
            vm.billingCost = user.permissions.indexOf('user_permission_manage_billing_types') > -1;
        } else if (user.permissionsInherited) {
            vm.billingTypes = user.permissionsInherited.indexOf('user_permission_manage_billing_types') > -1;
            vm.billingCost = user.permissionsInherited.indexOf('user_permission_manage_billing_types') > -1;
        }

        if (billingTypeCollectionResponse) {
            vm.selectedBillingTypeModel = {
                response: billingTypeCollectionResponse,
                link: billingTypeCollectionResponse.getLink('self'),
                required: false,
                responseKeyPath: 'billingTypes',
                itemHrefPath: '_links.self.href',
                itemValuePath: 'name',
                onSelect: vm.changeBillingType,
                onRemove: vm.changeBillingType
            };
        }

        if (billingTypeCollectionResponse) {
            vm.selectedBillingTypeModel.selected = vm.data._links.billingtype;
        }

        function changeBillingType() {
            if (vm.selectedBillingTypeModel.selected && vm.selectedBillingTypeModel.selected.object) {
                vm.billingType = vm.selectedBillingTypeModel.selected.object;
                vm.data._links.billingtype = vm.billingType.getLink('self');
                vm.data.billingCost = vm.billingType.cost;
            } else {
                vm.data._links.billingtype = null;
            }
        }

        function createAction() {
            var redirectParams = {};

            if (vm.address) {
                vm.data.addresses = [vm.address];
            } else {
                delete vm.data.addresses;
            }

            if (typeof parentLocationResponse._links !== 'undefined'
                && typeof parentLocationResponse._links.self !== 'undefined'
            ) {
                vm.data._links.parent = parentLocationResponse._links.self;
                redirectParams = {locationResource : parentLocationResponse._links.self.href.encode()};
            }

            delete vm.data.billingCost;

            apiClient.create(locationsCollectionResponse.getLink('create'), vm.data).then(function (response) {
                if (response) {
                    if (Object.keys(redirectParams).length === 0) {
                        redirectParams = {locationResource : response.getLink('self').encode()};
                    }

                    $state.go('^.details.info', redirectParams, { reload: $state.current.parent }).then(function () {
                        messenger.success('LOCATION_CREATED');
                    });
                } else {
                    messenger.error('REQUEST_ERROR');
                }
            });
        }

        function updateAction() {
            if (vm.address) {
                vm.data.addresses = [vm.address];
            } else {
                delete vm.data.addresses;
            }

            delete vm.data.billingCost;

            apiClient.update(vm.location.getLink('edit'), vm.data).then(function (response) {
                if (response) {
                    $state.go('.^.info', {}, { reload: true }).then(function () {
                        messenger.success('LOCATION_UPDATED');
                    });
                } else {
                    messenger.error('REQUEST_ERROR');
                }
            });
        }

        function deleteAction() {
            return confirmationModal.open().result.then(function (response) {
                if (response.result) {
                    return apiClient.delete(vm.location.getLink('delete')).then(function (response) {
                        if (response) {
                            $state.go('^.^', {}, { reload: $state.get('^.^') }).then(function () {
                                messenger.success('LOCATION_DELETED');
                            });
                        }
                    });
                }

                return;
            });
        }

    }
})();
