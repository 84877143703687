angular.module('elogbooksServices').service('modalForm', ['$uibModal', function ($uibModal) {
    this.open = function(config) {
        return $uibModal.open({
            templateUrl: '/modules/common/modals/form-modal/form-modal.html',
            controller: 'FormModalController',
            controllerAs: 'vm',
            resolve: {
                config: angular.extend({
                    // Default config
                    entityName: 'ENTITY_NAME',
                    responseEntity: null,
                    data: null,
                    formControlsTemplate: null,
                    title: 'MODAL_FORM_TITLE',
                    submitButtonText: 'Submit'
                }, config)
            }
        });
    };
}]);
