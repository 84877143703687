app.config(function ($translateProvider) {
    $translateProvider.translations('nl', {
        SITE: 'Object',
        SITES: 'Objecten',

        SITES_LIST: 'Objectenlijst',

        ADD_NEW_SITE: 'Voeg een nieuw object toe',
        EDIT_SITE: 'Wijzig Object',
        VIEW_SITE: 'Bekijk Object',

        ADDRESSES: 'Adressen',
        CITY: 'Plaats',
        COUNTY_STATE_PROVINCE_REGION: 'Provincie / Regio',
        POSTAL_CODE_ZIP: 'Postcode',
        COUNTRY: 'Land',
        CONTACT_INFO_TYPE: 'Soort contact',
        CONTACT_ADDRESS_ADDED: 'Adres van contact toegevoegd',
        CONTACT_ADDRESS_UPDATED: 'Adres van contact aangepast',
        CONTACT_ADDRESS_DELETED: 'Adres van contact verwijders',

        MANAGE_SITES: 'Beheer objecten',
        ALL_SITES: 'Alle objecten',
        SELECTED_SITES: 'Geselecteerde objecten',
        CONTACT_INFORMATION: 'Contactinformatie',
        CONTACT_INFORMATION_ADDRESS: 'Contactinformatie en adres',
        SITE_CONTACT: 'Eereste contactpersoon van object',
        SITE_IMAGE: 'Afbeelding object',

        SITE_MEMBERSHIP: 'Object Authorisatie',
        SITE_MEMBERSHIPS: 'Object Authorisaties',
        SITE_MEMBERSHIP_LIST: 'Lijst met authorisaties',
        SITE_MEMBERSHIP_ADD: 'Voeg een object toe',
        SITE_MEMBERSHIP_EDIT: 'Wijzig toegang tot een object',
        SITE_MEMBERSHIP_CREATED: 'Toegang object gegeven',
        SITE_MEMBERSHIP_UPDATED: 'Toegang object aangepast',
        SITE_MEMBERSHIP_DELETED: 'Toegang object verwijderd',

        IMAGE_DELETED: 'Afbeelding verwijderd',

        REQUIRE_PURCHASE_ORDER: 'Opdrachtbon vereist?',
        EXCLUDE_PCD: 'Exclude from PCD?',
        AREA: 'Oppervlakte',
        UNIT: 'Eenheid',

        SITE_CONTACTS: 'Contacten voor object',
        ADD_NEW_SITE_CONTACT: 'Voeg nieuwe contact toe aan object',
        EDIT_CONTACT: 'Wijzig contact',

        SITE_MANAGER: 'Beheerder object',
        SITE_MANAGERS: 'Beheerders object',

        SITE_LIST: 'Objecten',
        SITE_ADD: 'Voeg nieuw object toe',
        SITE_EDIT: 'Wijzig object',

        SITE_CREATED: 'Object aangemaakt',
        SITE_UPDATED: 'Object aangepast',
        SITE_DELETED: 'Object verwijderd',

        ADD_NEW_SITE_NOTE_TYPE: 'Voeg een nieuw type object notiitie toe',
        SITE_NOTE_TYPE_TITLE: 'Titel',
        ALLOW_REQUIRE_FM: 'Sta toe dat deze tekst aangemerkt wordt als er een FM goedkeuring nodig is',
        SITE_NOTE_TYPE: 'Object notitietype',
        SITE_NOTE_TYPES: 'Object notitietypes',
        NOT_APPLICABLE: 'Niet van toepassing',
        REQUIRE_FM: 'FM goedkeuring nodig',
        SITE_REQUIRES_FM_APPROVAL: 'FM goedkeuring is verplicht',
        NO_DATA: 'Er is nog niets ingevuld in dit veld',

        SITE_NOTES: 'Notitie',
        SITE_ACCESS: 'Object toegang',
        SEARCH_SITES_BY_NAME: 'Zoek objecten op naam',

        SITE_INFORMATION: 'Objectinformatie',

        ASSOCIATES_UPDATED: 'Users voor dit object aangepast',
        ASSOCIATES: 'Partners',
        EDIT_ADDRESS: 'Wijzig adres',
        SITE_CONTACT_ADD: 'Voeg nieuwe contact toe voor dit object',
        SITE_CONTACT_EDIT: 'Wijzig contact van dit object',
        CONTACT_CREATED: 'Object contact gemaakt',
        CONTACT_UPDATED: 'Object contact aangepast',
        CONTACT_DELETED: 'Object contact verwijderd',
        ADD_LOCATION: 'Voeg locatie van object toe',
        EDIT_LOCATION: 'Wijzig locatie van object',
        SITE_NOTE_UPDATED: 'Objectnotitie aangepast',
        SITE_NOTE_CREATED: 'Objectnotitie gemaakt',
        SITE_THRESHOLD_DELETED: 'Object Threshold verwijderd',
        SITE_THRESHOLD_VALUE_ADDED: 'Object Threshold Toegevoegd',
        NO_CONTACT_INFORMATION_ASSIGNED: 'Geen contactinformatie aangewezen',
        SITE_STATUS: 'Object Status',
    });
});
