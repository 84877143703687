(function() {
    'use strict';

    angular
        .module('elogbooks.admin.pricing-schedules')
        .controller('FinancePurchaseOrdersWorkflowController', FinancePurchaseOrdersWorkflowController);

    FinancePurchaseOrdersWorkflowController.$inject = [
        'purchaseOrderResponse'
    ];

    function FinancePurchaseOrdersWorkflowController(purchaseOrderResponse) {
        var vm = this;
        vm.purchaseOrder = purchaseOrderResponse;
    }
})();
