(function () {
    'use strict';

    angular
        .module('elogbooks.user.tenants')
        .controller('ViewAllRecipientsController', ViewAllRecipientsController);

    ViewAllRecipientsController.$inject = ['$state', '$uibModalInstance', 'contactsResponse', '$rootScope'];

    function ViewAllRecipientsController ($state, $uibModalInstance, contactsResponse, $rootScope) {
        var vm = this;
        vm.editRecipients = editRecipients;
        vm.tenants = contactsResponse;

        function editRecipients() {
            $uibModalInstance.close();
            $rootScope.skipDirtyCheck = true;
            $state.go('^.^').then(function() {
                $rootScope.skipDirtyCheck = false;
            });
        }
    }
})();
