app.config(function ($stateProvider) {

    $stateProvider
        .state('dashboard.contactInformation', {
            url: '/contact-information/{contactInformationResource}',
            abstract: true,
            resolve: {
                contactInformationResponse: function ($stateParams, apiClient, base64) {
                    return apiClient.get(base64.decode($stateParams.contactInformationResource));
                }
            },
            ncyBreadcrumb: {
                label: '{{ ::"INFORMATION" | translate }}'
            },
            templateUrl: 'shared-templates/view.html'
        })
        .state('dashboard.user.sites.list.contactInformation', {
            data: {
                permissions: ['user_permission_manage_users']
            },
            url: '/contact-information/{contactInformationResource}',
            abstract: true,
            resolve: {
                contactInformationResponse: function ($stateParams, apiClient, base64) {
                    return apiClient.get(base64.decode($stateParams.contactInformationResource));
                }
            },
            views: parseBook({
                "elogbooks-right@dashboard.user.sites": {
                    templateUrl: 'shared-templates/view.html'
                }
            }),
            ncyBreadcrumb: {
                label: '{{ ::"INFORMATION" | translate }}'
            }
        })
        .state('dashboard.user.sites.list.contactInformation.address', {
            data: {
                permissions: ['user_permission_manage_users']
            },
            url: "/address/{addressKey}",
            views: parseBook({
                "elogbooks-right@dashboard.user.sites": {
                    templateUrl: '/modules/directives/contact-information/addresses/site-address-form.html',
                    controller: 'SiteAddressController',
                }
            }),
            ncyBreadcrumb: {
                label: '{{ ::"EDIT" | translate }}'
            }
        })
        .state('dashboard.contactInformation.address', {
            url: "/address/{addressKey}",
            data: {
                permissions: [
                    'user_permission_manage_service_providers',
                    'user_permission_client_admin_manage_users',
                    'site_permission_edit_tenants'
                ]
            },
            templateUrl: '/modules/directives/contact-information/addresses/site-address-form.html',
            controller: 'SiteAddressController',
            ncyBreadcrumb: {
                label: '{{ ::"ADDRESS" | translate }}'
            }
        })
        .state('dashboard.contactInformation.address.userEdit', {
            url: "/address/userEdit/{addressKey}",
            data: {
                permissions: ['user_permission_manage_service_providers']
            },
            templateUrl: '/modules/directives/contact-information/addresses/site-address-form.html',
            controller: 'SiteAddressController',
            ncyBreadcrumb: {
                label: '{{ ::"EDIT" | translate }}'
            }
        })
        .state('dashboard.contactInformation.contact-channels', {
            url: "/contact-channels",
            data: {
                permissions: ['user_permission_manage_service_providers']
            },
            abstract: true,
            templateUrl: '/modules/directives/contact-information/channels/contact-channels-form.html',
            controller: 'SiteContactChannelsController',
            ncyBreadcrumb: {
                label: '{{ ::"CONTACT" | translate }}'
            }
        })
        .state('dashboard.contactInformation.address.SPadd', {
            data: {
                permissions: ['user_permission_manage_service_providers']
            },
            url: "/address/spadd/{addressKey}",
            templateUrl: '/modules/directives/contact-information/addresses/site-address-form.html',
            controller: 'SiteAddressController',
            ncyBreadcrumb: {
                label: '{{ ::"ADD" | translate }}'
            }
        })
        .state('dashboard.contactInformation.address.SPedit', {
            data: {
                permissions: ['user_permission_manage_service_providers']
            },
            url: "/address/spedit/{addressKey}",
            templateUrl: '/modules/directives/contact-information/addresses/site-address-form.html',
            controller: 'SiteAddressController',
            ncyBreadcrumb: {
                label: '{{ ::"EDIT" | translate }}'
            }
        })
        .state('dashboard.contactInformation.contact-channels.SPedit', {
            data: {
                permissions: ['user_permission_manage_service_providers']
            },
            url: "/contact-channels/spedit",
            templateUrl: '/modules/directives/contact-information/channels/contact-channels-form.html',
            controller: 'SiteContactChannelsController',
            ncyBreadcrumb: {
                label: '{{ ::"EDIT" | translate }}'
            }
        })
        .state('dashboard.contactInformation.contact-channels.Useredit', {
            data: {
                permissions: ['user_permission_manage_service_providers']
            },
            url: "/contact-channels/useredit",
            templateUrl: '/modules/directives/contact-information/channels/contact-channels-form.html',
            controller: 'SiteContactChannelsController',
            ncyBreadcrumb: {
                label: '{{ ::"EDIT" | translate }}'
            }
        })
        .state('dashboard.contactInformation.address.SiteAdd', {
            data: {
                permissions: ['user_permission_manage_sites', 'user_permission_client_admin_manage_sites']
            },
            url: "/address/siteadd/{addressKey}",
            templateUrl: '/modules/directives/contact-information/addresses/site-address-form.html',
            controller: 'SiteAddressController',
            ncyBreadcrumb: {
                label: '{{ ::"ADD" | translate }}'
            }
        })
        .state('dashboard.contactInformation.address.SiteEdit', {
            data: {
                permissions: ['user_permission_manage_sites', 'user_permission_client_admin_manage_sites', 'site_permission_edit_tenants']
            },
            url: "/address/siteedit/{addressKey}",
            templateUrl: '/modules/directives/contact-information/addresses/site-address-form.html',
            controller: 'SiteAddressController',
            ncyBreadcrumb: {
                label: '{{ ::"EDIT" | translate }}'
            }
        })
        .state('dashboard.contactInformation.contact-channels.SiteEdit', {
            data: {
                permissions: ['user_permission_manage_sites', 'user_permission_client_admin_manage_sites', 'site_permission_edit_tenants']
            },
            url: "/contact-channels/siteedit",
            templateUrl: '/modules/directives/contact-information/channels/contact-channels-form.html',
            controller: 'SiteContactChannelsController',
            ncyBreadcrumb: {
                label: '{{ ::"EDIT" | translate }}'
            }
        });
});
