(function() {
    'use strict';

    angular
        .module('elogbooks')
        .config(Translations);

    function Translations($translateProvider) {
        $translateProvider.translations('nl', {
            ACTION_CREATED_ACTION: '<strong>{{ event._links["user"].title }}</strong> heeft deze actie aangemaakt',
            ACTION_COMPLETED_ACTION: '<strong>{{ event._links["user"].title }}</strong> heeft deze actie afgerond',
            ACTION_CANCELLED_ACTION: '<strong>{{ event._links["user"].title }}</strong> heeft deze acite geannuleerd',
            ACTION_REOPENED_ACTION: '<strong>{{ event._links["user"].title }}</strong> heeft deze actie heropend',
            ACTION_REASSIGNED_ACTION: '<strong>{{ event._links["user"].title }}</strong> heeft de actie doorverwezen naar <strong>{{ event._links["assignee"].title }}</strong>',

            ACTION_MESSAGE_ACTION: '<strong>{{ message._links["sender"].title }}</strong> heeft een bericht toegevoegd',
            ACTION_FILE_ADDED: '<strong>{{ file._links["author"].title }}</strong> heeft een bijlage toegevoegd',

            ACTION_JOB_CREATED_ACTION: '<strong>{{ event._links["user"].title }}</strong> heeft een taak aangemaakt',
            ACTION_QUOTE_REQUEST_CREATED_ACTION: '<strong>{{ event._links["user"].title }}</strong> heeft een offerteaanvraag aangemaakt',

            ACTION_CANCELLED_SYSTEM_ACTION: 'Het systeem heeft deze actie automatisch geannuleerd.',
        });
    }
})();
