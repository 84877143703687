app.config(function ($translateProvider) {
    $translateProvider.translations('zh-hk', {
        SERVICE_ROUTINE: '服務程序',
        SERVICE_ROUTINES: '服務程序',
        SERVICEROUTINES: '服務程序',
        ADD_NEW_SERVICE_ROUTINE: '新增服務程序',
        EDIT_SERVICE_ROUTINE:  '修改服務程序',
        SERVICE_ROUTINE_LINES:  '服務程序列行',
        ADD_SERVICE_ROUTINE_LINE:  '新增服務程序列行',
        SERVICE_ROUTINE_ACTION:  '行動',


        SERVICE_ROUTINE_LIST: '服務程序',
        SERVICE_ROUTINE_VIEW: '訊息',
        SERVICE_ROUTINE_ADD: '新增服務程序',
        SERVICE_ROUTINE_EDIT: '修改服務程序',

        SERVICE_ROUTINE_CREATED: '服務程序己建立',
        SERVICE_ROUTINE_UPDATED: '服務程序已更新',
        SERVICE_ROUTINE_DELETED: '服務程序已刪除',

        SERVICE_ROUTINE_LINE_ACTION: '行動',
        SERVICE_ROUTINE_LINE_NOTES: '記錄',
        SERVICE_ROUTINE_LINE_FREQUENCY: '次數',

        SERVICE_ROUTINE_LINE: '服務程序列行',
        SERVICE_ROUTINE_LINES: '服務程序列行',

        SERVICE_ROUTINE_LINE_LIST: '服務程序列行',
        SERVICE_ROUTINE_LINE_ADD: '新增服務程序列行',
        SERVICE_ROUTINE_LINE_EDIT: '修改服務程序列行',

        SERVICE_ROUTINE_LINE_CREATED: '服務程序列行已建立',
        SERVICE_ROUTINE_LINE_UPDATED: '服務程序列行已更新',
        SERVICE_ROUTINE_LINES_UPDATED: '服務程序列行已更新',
        SERVICE_ROUTINE_LINE_DELETED: '服務程序列行已刪除',
    });
});
