(function() {
    'use strict';

    angular
        .module('elogbooks.admin.charge-types')
        .controller('ChargeTypesAddEditController', ChargeTypesAddEditController);

    ChargeTypesAddEditController.$inject = ['$state', 'apiClient', 'confirmationModal', 'messenger', 'requestDataFactory', 'chargeTypeResponse', 'chargeTypesCollectionResponse'];
    function ChargeTypesAddEditController($state, apiClient, confirmationModal, messenger, requestDataFactory, chargeTypeResponse, chargeTypesCollectionResponse) {

        var vm = this;
        vm.chargeType = chargeTypeResponse;
        vm.data = requestDataFactory.createRequest(vm.chargeType);
        vm.chargeTypesCollectionResponse = chargeTypesCollectionResponse;
        vm.create = createAction;
        vm.update = updateAction;
        vm.delete = deleteAction;

        function updateAction() {
            return apiClient.update(vm.chargeType.getLink('edit'), vm.data).then(function(response) {
                if (response) {
                    $state.go('dashboard.admin.charge-types.list', {}, {reload: 'dashboard.admin.charge-types.list'}).then(function() {
                        messenger.success('CHARGE_TYPE_UPDATED');
                    });
                } else {
                    messenger.error('REQUEST_ERROR');
                }
            });
        }

        function createAction() {
            return apiClient.create(vm.chargeTypesCollectionResponse.getLink('create'), vm.data).then(function(response) {
                if (response) {
                    $state.go('dashboard.admin.charge-types.list', {resource: response.getLink('self').encode()}, {reload: $state.current.parent}).then(function() {
                        messenger.success('CHARGE_TYPE_CREATED');
                    });
                } else {
                    messenger.error('REQUEST_ERROR');
                }
            });
        }

        function deleteAction() {
            return confirmationModal.open().result.then(function(response) {
                if (response.result) {
                    apiClient.delete(vm.chargeType.getLink('delete')).then(function(isSuccess) {
                        if (isSuccess) {
                            $state.go('dashboard.admin.charge-types.list', {}, {reload: 'dashboard.admin.charge-types.list'}).then(function() {
                                messenger.success('CHARGE_TYPE_DELETED');
                            });
                        } else {
                            messenger.error('REQUEST_ERROR');
                        }
                    });
                }
            });
        }
    }
})();
