app.config(function ($translateProvider) {
    $translateProvider.translations('nl', {
        SERVICE_ROUTINE: 'Dienst Routine',
        SERVICE_ROUTINES: 'Dienst Routines',
        SERVICEROUTINES: 'Dienst Routines',
        ADD_NEW_SERVICE_ROUTINE: 'Routine Toevoegen',
        EDIT_SERVICE_ROUTINE:  'Routine Wijzigen',
        SERVICE_ROUTINE_LINES:  'Service Routine Lines',
        ADD_SERVICE_ROUTINE_LINE:  'Add Service Routine Line',
        SERVICE_ROUTINE_ACTION:  'Aktie',


        SERVICE_ROUTINE_LIST: 'Dienst Routines',
        SERVICE_ROUTINE_VIEW: 'Info',
        SERVICE_ROUTINE_ADD: 'Toevoegen dienst Routine',
        SERVICE_ROUTINE_EDIT: 'Wijzig dienst Routine',

        SERVICE_ROUTINE_CREATED: 'Dienst Routine Gemaakt',
        SERVICE_ROUTINE_UPDATED: 'Dienst Routine Aangepast',
        SERVICE_ROUTINE_DELETED: 'Dienst Routine Verwijderd',

        SERVICE_ROUTINE_LINE_ACTION: 'Aktie',
        SERVICE_ROUTINE_LINE_NOTES: 'Notitie',
        SERVICE_ROUTINE_LINE_FREQUENCY: 'Frequentie',

        SERVICE_ROUTINE_LINE: 'Dienst Routine Line',
        SERVICE_ROUTINE_LINES: 'Dienst Routine Lines',

        SERVICE_ROUTINE_LINE_LIST: 'Service Routine Lines',
        SERVICE_ROUTINE_LINE_ADD: 'Add a new Service Routine Line',
        SERVICE_ROUTINE_LINE_EDIT: 'Edit Service Routine Line',

        SERVICE_ROUTINE_LINE_CREATED: 'Service Routine Line Created',
        SERVICE_ROUTINE_LINE_UPDATED: 'Service Routine Line Updated',
        SERVICE_ROUTINE_LINES_UPDATED: 'Service Routine Lines Updated',
        SERVICE_ROUTINE_LINE_DELETED: 'Service Routine Line Deleted',
    });
});
