angular.module('elogbooksServices').service('NestedArrayTransformer', function(lodash) {

    var rewriteRecursively = function (array, parent) {
        var tree = [];
        lodash.each(array, function (element) {
            if (typeof element === 'undefined') {
                debugger;
            }
            if (element._links.parent.href === parent) {
                element.children = rewriteRecursively(array, element._links.self.href);
                tree.push(element);
            }
        });
        return tree;
    }

    this.transform = function (data, filterOnChildren) {
        lodash.each(data, function(element) {
            if (! element._links.parent || filterOnChildren) {
                element._links.parent = {
                    href: undefined
                }
            }
        });
        return rewriteRecursively(data);
    }

    return this;
});
