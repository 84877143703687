app.config(function ($translateProvider) {
    $translateProvider.translations('en-gb', {
        SERVICE_TYPES: 'Service Types',
        SERVICE_TYPES_LIST: 'Service Types',
        SERVICE_TYPES_ADD: 'Add a new Service Type',
        SERVICE_TYPES_EDIT: 'Edit Service Type',
        SERVICE_TYPES_UPDATED: 'Service Types Updated',

        SERVICE_TYPE: 'Service Type',
        SERVICE_TYPE_LIST: 'Service Types',
        SERVICE_TYPE_ADD: 'Add a new Service Type',
        SERVICE_TYPE_EDIT: 'Edit Service Type',
        SERVICE_TYPE_ADDED: 'Service Type Added',
        SERVICE_TYPE_UPDATED: 'Service Type Updated',
        SERVICE_TYPE_DELETED: 'Service Type Deleted'
    });
});
