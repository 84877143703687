(function() {
    'use strict';

    angular
        .module('elogbooks.admin.pricing-schedules')
        .controller('PricingSchedulesDetailsController', PricingSchedulesDetailsController);

    PricingSchedulesDetailsController.$inject = ['pricingScheduleResponse', 'rootPricingScheduleResponse'];
    function PricingSchedulesDetailsController(pricingScheduleResponse, rootPricingScheduleResponse) {
        var vm = this;
        vm.pricingSchedule = pricingScheduleResponse;
        vm.rootPricingScheduleResponse = rootPricingScheduleResponse;
    }
})();
