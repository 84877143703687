(function () {
    'use strict';

    angular
        .module('elogbooks.admin.roles')
        .controller('RoleDetailsController', RoleDetailsController);

    RoleDetailsController.$inject = ['roleResponse'];

    function RoleDetailsController (roleResponse) {
        var vm = this;
            vm.role = roleResponse;
    }

})();
