(function () {
    'use strict';

    angular
        .module('elogbooks.admin.pricing-schedules')
        .controller('PricingSchedulesStandardChargesAddEditController', PricingSchedulesStandardChargesAddEditController);

    PricingSchedulesStandardChargesAddEditController.$inject = [
        '$state',
        'apiClient',
        'confirmationModal',
        'standardChargesResponse',
        'requestDataFactory',
        'messenger',
        'standardChargesCollectionResponse',
        'pageService'
    ];

    function PricingSchedulesStandardChargesAddEditController (
        $state,
        apiClient,
        confirmationModal,
        standardChargesResponse,
        requestDataFactory,
        messenger,
        standardChargesCollectionResponse,
        pageService
    ) {
        var vm = this;
        vm.standardCharges = standardChargesResponse;
        vm.data = requestDataFactory.createRequest(getDefaults());

        vm.create = createAction;
        vm.update = updateAction;
        vm.delete = deleteAction;

        function getDefaults() {
            return {
                id: vm.standardCharges.id || null,
                description: vm.standardCharges.description,
                isDefault: !!vm.standardCharges.isDefault,
                amount: vm.standardCharges.amount,
                inclusiveHours: vm.standardCharges.inclusiveHours || 0
            };
        }

        function createAction() {
            return apiClient.create(standardChargesCollectionResponse.getLink('create'), vm.data).then(function (response) {
                if (response) {
                    $state.go('^', { pricingScheduleStandardChargesResource: response.getLink('self').encode() }, { reload: $state.current.parent }).then(function () {
                        messenger.success('STANDARD_CHARGE_CREATED');
                    });
                } else {
                    messenger.error('REQUEST_ERROR');
                }
            });
        }

        function updateAction() {
            return apiClient.update(vm.standardCharges.getLink('edit'), vm.data).then(function (response) {
                if (response) {
                    $state.go('^', {}, { reload: 'dashboard.admin.pricing-schedules.list.details.standardCharges' }).then(function () {
                        messenger.success('STANDARD_CHARGE_UPDATED');
                    });
                } else {
                    messenger.error('REQUEST_ERROR');
                }
            });
        }


        function deleteAction() {
            return confirmationModal.open().result.then(function() {
                return apiClient.delete(vm.standardCharges.getLink('delete')).then(function(response) {
                    if (response) {
                        $state.go('dashboard.admin.pricing-schedules.list.details.standardCharges', {
                            standardChargesPage: pageService.calculatePageOnDelete(standardChargesCollectionResponse)
                        }, { reload: 'dashboard.admin.pricing-schedules.list.details.standardCharges' })
                            .then(function() {
                                messenger.success('STANDARD_CHARGE_DELETED');
                        });
                    } else {
                        messenger.error('REQUEST_ERROR');
                    }
                });
            });
        }
    }
})();
