(function() {
    'use strict';

    angular
        .module('elogbooks.admin.job-groups')
        .config(registerRoutes)
        .config(registerRoutesAdd);

    function registerRoutes ($stateProvider) {
        $stateProvider
            .state('dashboard.admin.jobgroups', {
                abstract: true,
                templateUrl: 'shared-templates/view.html',
                ncyBreadcrumb: {
                    skip: true
                },
                data: {
                    permissions: ['user_permission_view_job_groups', 'user_permission_client_admin_manage_job_groups']
                }
            })
            .state('dashboard.admin.jobgroups.list', {
                url: '/jobgroups',
                parent: 'dashboard.admin.jobgroups',
                data: {
                    permissions: ['user_permission_view_job_groups', 'user_permission_client_admin_manage_job_groups']
                },
                resolve: {
                    jobGroupCollectionResponse: function (apiClient, rootResourceResponse) {
                        return apiClient.getPage(rootResourceResponse.getLink('jobgroups'), {
                            page: 1,
                            limit: 999999,
                            active: 0
                        });
                    }
                },
                views: {
                    '@dashboard.admin.jobgroups': {
                        templateUrl: '/modules/admin/job-groups/job-groups.html',
                        controller: 'JobGroupsController',
                        controllerAs: 'vm'
                    }
                },
                ncyBreadcrumb: {
                    label: '{{ ::"JOB_GROUPS" | translate }}'
                }
            });
    }

    function registerRoutesAdd ($stateProvider) {
        $stateProvider
            .state('dashboard.admin.jobgroups.list.add', {
                url: '/add/{parentResource}',
                parent: 'dashboard.admin.jobgroups.list',
                data: {
                    permissions: ['user_permission_manage_job_groups', 'user_permission_client_admin_manage_job_groups']
                },
                views: {
                    '@dashboard.admin.jobgroups': {
                        templateUrl: '/modules/admin/job-groups/add-edit/job-group-form.html',
                        controller: 'AdminJobGroupAddEditController',
                        controllerAs: 'vm'
                    }
                },
                resolve: {
                    parentResponse: function (apiClient, $stateParams) {
                        if ($stateParams.parentResource) {
                            return apiClient.get($stateParams.parentResource.decode()).then(function (response) {
                                return response || apiClient.noResourceFound();
                            });
                        }

                        return null;
                    },
                    jobGroupResponse: function () {
                        return {}
                    }
                },
                ncyBreadcrumb: {
                    label: '{{ ::"ADD" | translate }}'
                }
            })
            .state('dashboard.admin.jobgroups.list.edit', {
                url: '/edit/{resource}',
                parent: 'dashboard.admin.jobgroups.list',
                data: {
                    permissions: ['user_permission_manage_job_groups', 'user_permission_client_admin_manage_job_groups']
                },
                views: {
                    '@dashboard.admin.jobgroups': {
                        templateUrl: '/modules/admin/job-groups/add-edit/job-group-form.html',
                        controller: 'AdminJobGroupAddEditController',
                        controllerAs: 'vm'
                    }
                },
                resolve: {
                    jobGroupResponse: function (apiClient, $stateParams) {
                        return apiClient.get($stateParams.resource.decode()).then(function (response) {
                            return response || apiClient.noResourceFound();
                        });
                    },
                    parentResponse: function (apiClient, jobGroupResponse) {
                        if (jobGroupResponse.getLink('parent')) {
                            return apiClient.get(jobGroupResponse.getLink('parent')).then(function (response) {
                                return response || apiClient.noResourceFound();
                            });
                        }

                        return null;
                    }
                },
                ncyBreadcrumb: {
                    label: '{{ ::"EDIT" | translate }}'
                }
            });
    }
})();
