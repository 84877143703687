(function () {
    'use strict';

    angular
        .module('elogbooks.common.jobs')
        .controller('CommonJobsController', CommonJobsController);

    CommonJobsController.$inject = [
        'jobCollectionResponse',
        'statutoryTypeCollectionResponse',
        'priorityCollectionResponse',
        '$state',
        '$stateParams',
        '$scope',
        '$translate',
        'config',
        'jobStatuses',
        'rootResourceResponse',
        'selectedSiteResponse',
        'user',
        'userManager',
        'paramConverterService',
        'lodash',
        'bulkReassignJobOperativesModal',
        'bulkCancelJobsModal',
        'bulkReassignJobsModal',
        'canBulkReassignOperative',
        'canBulkCancel',
        'canBulkReassign',
        'serviceProviderCollectionResponse',
        'jobService',
        'documentTypesCollectionResponse',
        'analyticsService',
    ];

    function CommonJobsController(
        jobCollectionResponse,
        statutoryTypeCollectionResponse,
        priorityCollectionResponse,
        $state,
        $stateParams,
        $scope,
        $translate,
        config,
        jobStatuses,
        rootResourceResponse,
        selectedSiteResponse,
        user,
        userManager,
        paramConverterService,
        lodash,
        bulkReassignJobOperativesModal,
        bulkCancelJobsModal,
        bulkReassignJobsModal,
        canBulkReassignOperative,
        canBulkCancel,
        canBulkReassign,
        serviceProviderCollectionResponse,
        jobService,
        documentTypesCollectionResponse,
        analyticsService
    ) {
        var vm = this;
        vm.loading = true;
        vm.jobs = jobCollectionResponse.jobs;
        vm.jobCollectionResponse = jobCollectionResponse;
        vm.pages = jobCollectionResponse.pages;
        vm.config = config;
        vm.p2p = $state.params.hasOwnProperty('p2p') && ($state.params.p2p === '1' || $state.params.p2pAwaitingPoNumber === '1' || $state.params.p2pAwaitingValue === '1');

        vm.stateParams = $stateParams;
        vm.resources = {'rootResource': rootResourceResponse,
            'selectedSite': selectedSiteResponse,
            'stateParams': $stateParams,
            'root': rootResourceResponse,
            'user': user,
            'sites': $scope.vm.siteSelect};

        vm.canBulkReassignOperative = canBulkReassignOperative;
        vm.canBulkCancel = canBulkCancel;
        vm.canBulkReassign = canBulkReassign;

        vm.hasBulkActions = vm.canBulkReassignOperative || vm.canBulkCancel || vm.canBulkReassign;

        vm.isAllSelected = false;
        vm.jobsSelected = 0;

        vm.getViewRoute = getViewRoute;
        vm.toggleAll = toggleAll;
        vm.toggleSelected = toggleSelected;
        vm.countSelected = countSelected;
        vm.reassign = reassign;
        vm.reassignOperatives = reassignOperatives;
        vm.cancelJobs = cancelJobs;
        vm.isHelpdesk = userManager.hasPermission('site_permission_helpdesk');

        var deregisterWatch = $scope.$watch('vm.loading', function(value) {
            if (value === false) {
                analyticsService.pageLoaded();
                deregisterWatch();
            }
        });

        var typeOptions = [
            {
                title: $translate.instant('NONE_SELECTED'),
                value: null
            },
            {
                title: $translate.instant('JOB_TYPE_REACTIVE'),
                value: 'reactive'
            },
            {
                title: $translate.instant('JOB_TYPE_PLANNED'),
                value: 'planned'
            }
        ];

        vm.order = orderAction;
        vm.search = searchAction;
        vm.getPriorities = getPriorities;
        vm.getStatTypes = getStatTypes;
        vm.getDueBy = getDueBy;

        vm.loadedCollections = [statutoryTypeCollectionResponse, priorityCollectionResponse, jobCollectionResponse];

        // set all to de-selected on view load
        toggleAll();

        loadJobs();

        function loadJobs() {
            if (user.getLink('jobs')) {
                jobService.get(config.jobsLink || user.getLink('jobs'), selectedSiteResponse, 10).then(function(response) {
                    jobCollectionResponse = response;
                    vm.jobs = jobCollectionResponse.jobs;
                    vm.jobCollectionResponse = jobCollectionResponse;
                    vm.pages = jobCollectionResponse.pages;
                    vm.loadedCollections[2] = jobCollectionResponse;
                    vm.loading = false;
                });
            } else {
                vm.loading = false;
            }
        }

        function toggleAll() {
            lodash.forEach(vm.jobs, function(job) {
                job.selected = vm.isAllSelected;
            });
        }

        function toggleSelected(job) {
            return job.selected ? job.selected : false;
        }

        function countSelected(job) {
            if (job) {
                vm.jobsSelected = job.selected ? vm.jobsSelected += 1 : vm.jobsSelected -= 1;
                return;
            }
            vm.jobsSelected = vm.isAllSelected ? vm.jobs.length : 0;
        }

        function reassign(selected) {
            var jobs = setJobs(selected);
            var selectedSP = [];

            var bulkActionModal = bulkReassignJobsModal.open(
                jobs,
                rootResourceResponse,
                user,
                selectedSiteResponse,
                jobCollectionResponse.count,
                selectedSP,
                config,
                selected,
                canBulkReassignOperative
            );

            bulkActionModal.result.then(function () {
                clearJobSelection();
            });
        }

        function reassignOperatives(selected) {
            var jobs = setJobs(selected);
            var selectedSP = [];

            if ($stateParams.serviceProviders) {
                selectedSP = $stateParams.serviceProviders;
            }

            var bulkActionModal = bulkReassignJobOperativesModal.open(
                jobs,
                rootResourceResponse,
                user,
                selectedSiteResponse,
                jobCollectionResponse.count,
                selectedSP,
                config,
                selected
            );

            bulkActionModal.result.then(function () {
                clearJobSelection();
            });
        }

        function cancelJobs(selected) {
            var jobs = setJobs(selected);

            var bulkActionModal = bulkCancelJobsModal.open(
                jobs,
                rootResourceResponse,
                user,
                selectedSiteResponse,
                jobCollectionResponse.count,
                config,
                selected
            );

            bulkActionModal.result.then(function () {
                clearJobSelection();
            });
        }

        function clearJobSelection() {
            // clear current selection
            lodash.forEach(vm.jobs, function(job) {
                job.selected = false;
            });
            vm.jobsSelected = 0;
            vm.isAllSelected = false;
        }

        function setJobs(selected) {
            return lodash.filter(vm.jobs, { 'selected': true });
        }

        function getPriorities() {
            return paramConverterService.formatResponse(priorityCollectionResponse.priorities);
        }

        function getStatTypes() {
            return paramConverterService.formatResponse(statutoryTypeCollectionResponse.statutorytypes);
        }

        function getDueBy() {
            var options = {};
            if ($stateParams.jobsDueBy) {
                options = {
                    type: JSON.parse($stateParams.jobsDueBy).t,
                    frequency: JSON.parse($stateParams.jobsDueBy).f,
                    interval: JSON.parse($stateParams.jobsDueBy).i
                };
            }
            options.clear = vm.clear;

            if ($stateParams.completionDueStart !== null ||
                $stateParams.completionDueEnd !== null ||
                $stateParams.jobsAttendanceDueStart !== null ||
                $stateParams.jobsAttendanceDueEnd !== null
            ){
                options.isDisabled = true;
            } else {
                options.isDisabled = false;
            }

            return options;
        }

        vm.criteria = {
            jobSummary: { type: 'text', title: 'SUMMARY', value: $stateParams.jobSummary},
        };

        if(!vm.p2p){
            var statusQuickFilter = {
                filters: [
                    { title: 'JOB_STATUS_ALL', values: [] },
                    { title: 'JOB_STATUS_OPEN', values: jobStatuses.getAll('nonfinal') },
                    { title: 'JOB_STATUS_CLOSED', values: jobStatuses.getAll('final') },
                ]
            }
            vm.criteria.jobStatus = {
                type: 'options',
                title: 'STATUS',
                multiple: true,
                value: $stateParams.jobStatus === undefined ? [] : $stateParams.jobStatus,
                options: jobStatuses.getAllTranslated(),
                quickFilter: statusQuickFilter,
                clearValue: vm.isHelpdesk ? [] : jobStatuses.getAll('nonfinal'),
            },
            vm.criteria.jobStatus.isClear = lodash.isEqual(vm.criteria.jobStatus.value, vm.criteria.jobStatus.clearValue);
            vm.criteria.jobsType = { type: 'options', title: 'JOB_TYPE', value: $stateParams.jobsType, options: typeOptions };
        }

        if (statutoryTypeCollectionResponse) {
            vm.criteria.statType = { type: 'options', title: 'JOB_STAT_TYPE', multiple: true, value: paramConverterService.checkStateParams($stateParams.statType), options: getStatTypes() };
        }

        if (documentTypesCollectionResponse && documentTypesCollectionResponse.count > 0) {
            vm.documentTypeSelect = {
                link : documentTypesCollectionResponse.getLink('self'),
                linkParameters: { status: 'active', order: 'name' },
                responseKeyPath: 'documentTypes',
                itemValuePath: 'name',
                itemHrefPath: 'id'
            };

            vm.criteria.documentTypes = {
                type: 'autocompletemulti',
                multiple: true,
                title: 'DOCUMENT_TYPE',
                options: vm.documentTypeSelect,
                param: 'documentTypes',
                value: $stateParams.documentTypes,
                max: 50
            };
        }

        // Priority filter has like 2 filters in one, only one of them can be sent to the request
        if (priorityCollectionResponse) {
            vm.criteria.jobPriority = {
                type: 'options',
                title: 'SELECT_PRIORITY',
                multiple: true,
                inputName: true,
                valueFilterByName: $stateParams.jobSpecificSpPriority,
                valueToShow: $stateParams.jobSpecificSpPriority,
                value: paramConverterService.checkStateParams($stateParams.jobPriority),
                options: getPriorities()
            };
        }

        if (serviceProviderCollectionResponse) {
            vm.serviceProviderSelect = {
                response : serviceProviderCollectionResponse,
                link : serviceProviderCollectionResponse ? serviceProviderCollectionResponse.getLink('self') : null,
                itemValuePath: 'id',
                itemTitlePath: 'name',
                responseKeyPath: 'serviceproviders'
            };

            vm.criteria.serviceProvider = { type: 'jsonselectwidget', title: 'SERVICE_PROVIDER', value: JSON.parse($stateParams.serviceProvider), options: vm.serviceProviderSelect };
        }

        if ($stateParams.hasOwnProperty('jobsDueBy')) {
            vm.criteria.jobsDueBy = {type: 'jobsDueBy', title: 'JOBS_DUE_BY', value: JSON.parse($stateParams.jobsDueBy), options: getDueBy() };
        }

        function searchAction (params) {
            var override = {
                jobsPage: 1
            };
            $state.go('.', angular.extend({}, params, override), { reload: $state.current });
        }

        function orderAction (key) {
            $stateParams.jobsOrder = key;

            $state.go('.', angular.extend({}, $stateParams), { reload: $state.current });
        }

        function getViewRoute(job, route, parameters) {
            route = vm.config.route || route;

            if (!parameters) {
                parameters = vm.config.parameters || {};
            }

            parameters[vm.config.resourceName || ''] = job.getLink('self').encode();

            if (job.hasExplicitAccess) {
                parameters['hasExplicitAccess'] = true;
            }

            return route + '(' + JSON.stringify(vm.config.parameters || parameters) + ')';
        }
    }
})();
