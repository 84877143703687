(function () {
    'use strict';

    angular
        .module('elogbooks.common.jobs.workflow')
        .controller('JobPostponeReminderAtController', JobPostponeReminderAtController);

    JobPostponeReminderAtController.$inject = ['apiClient', '$state', 'messenger', 'jobResponse', 'user'];

    function JobPostponeReminderAtController(apiClient, $state, messenger, jobResponse, user) {
        var vm = this;
        vm.job = jobResponse;
        vm.action = 'postpone-reminder-at';
        vm.translation = 'JOB_WORKFLOW_CHANGE_REQUIRED_BY_DATE_OPERATION';
        vm.noteRequired = true;
        vm.submit = submitAction;
        vm.data = {};
        vm.partial = vm.action;

        vm.data.reminderAt = vm.job.reminderAt ? new Date(vm.job.reminderAt) : null;

        function submitAction () {
            return apiClient.create(vm.job.getLink(vm.action), vm.data).then(function (response) {
                if (response) {
                    return $state.go('^.^', {}, { reload: $state.get('^.^.^') }).then(function () {
                        messenger.success(vm.translation + '_SUCCESS');
                    });
                }

                messenger.error('REQUEST_ERROR');
            });
        }
    }
})();

