app.config(function ($translateProvider) {
    $translateProvider.translations('nl', {
        LOCATION: 'Locatie',
        LOCATIONS: 'Locaties',

        LOCATION_ADD: 'Voeg een nieuwe locatie toe',
        LOCATION_EDIT: 'Aanpassen locatie',
        LOCATION_LIST: 'Locaties',

        LOCATION_CREATED: 'Locatie gemaakt',
        LOCATION_UPDATED: 'Locatie aangepast',
        LOCATION_DELETED: 'Locatie verwijderd',

        PARENT_LOCATION: 'Hoofd Locatie',
        NEW_LOCATION: 'Nieuwe locatie',
        LOCATION_ADD_PARENT: 'Maak een nieuwe hoofd locatie',
        LOCATION_ADD_CHILD: 'Maak een nieuwe sub locatie',
    });
});
