(function () {
    'use strict';

    angular
        .module('elogbooks.common.waste-treatment-types')
        .controller('WasteTreatmentTypeAddEditController', WasteTreatmentTypeAddEditController);

    WasteTreatmentTypeAddEditController.$inject = ['$state', 'apiClient', 'messenger', 'requestDataFactory', 'confirmationModal', 'siteTreatmentTypeCollectionResponse', 'treatmentTypeCollectionResponse', 'treatmentTypeResponse'];

    function WasteTreatmentTypeAddEditController ($state, apiClient, messenger, requestDataFactory, confirmationModal, siteTreatmentTypeCollectionResponse, treatmentTypeCollectionResponse, treatmentTypeResponse) {
        var vm = this;
        vm.treatmentType = treatmentTypeResponse;
        vm.treatmentTypeCollectionResponse = treatmentTypeCollectionResponse;
        vm.data = requestDataFactory.createRequest({
            _links: vm.treatmentType ? vm.treatmentType._links : {},
            target: vm.treatmentType ? parseFloat(vm.treatmentType.target) : 0
        });

        vm.treatmentTypeSelect = {
            response: vm.treatmentTypeCollectionResponse,
            link: treatmentTypeCollectionResponse._links.self.href,
            required: true,
            disabled: vm.treatmentType && vm.treatmentType.id,
            populateDisabled: true,
            responseKeyPath: 'treatmentTypes',
            itemHrefPath: '_links.self.href',
            itemValuePath: 'name',
            onSelect: changeTreatmentType,
            onRemove: changeTreatmentType
        };

        if (vm.treatmentType && vm.treatmentType._links.treatmenttype) {
            vm.treatmentTypeSelect.selected = {
                object: vm.treatmentType,
                title:  vm.treatmentType.name,
                value: vm.treatmentType._links.treatmenttype.href
            };
        }

        vm.create = createAction;
        vm.update = updateAction;
        vm.delete = deleteAction;

        function changeTreatmentType () {
            vm.data._links.treatmenttype = undefined;
            if (vm.treatmentTypeSelect.selected) {
                vm.data._links.treatmenttype = {href: vm.treatmentTypeSelect.selected.href};
                vm.data.target = parseFloat(vm.treatmentTypeSelect.selected.object.target);
            }
        }

        function createAction () {
            return apiClient.create(siteTreatmentTypeCollectionResponse.getLink('create'), vm.data).then(function (response) {
                if (response) {
                    $state.go('^', {}, { reload: $state.current.parent }).then(function () {
                        messenger.success('TREATMENT_TYPE_ADDED');
                    });
                } else {
                    messenger.error('REQUEST_ERROR');
                }
            });
        }

        function updateAction () {
            return apiClient.update(vm.treatmentType.getLink('edit'), vm.data).then(function (response) {
                if (response) {
                    $state.go('^', {}, { reload: $state.current.parent }).then(function (response) {
                        messenger.success('TREATMENT_TYPE_UPDATED');
                    });
                } else {
                    messenger.error('REQUEST_ERROR');
                }
            });
        }

        function deleteAction () {
            return confirmationModal.open().result.then(function (response) {
                return apiClient.delete(vm.treatmentType.getLink('delete')).then(function (response) {
                    if (response) {
                        $state.go('^', {}, { reload: $state.current.parent }).then(function () {
                            messenger.success('TREATMENT_TYPE_DELETED');
                        });
                    } else {
                        messenger.error('REQUEST_ERROR');
                    }
                });
            });
        }
    }
})();
