(function() {
    'use strict';

    angular
        .module('elogbooksDirectives')
        .directive('elbCopyPasta', ElogbooksCopyPasta);

    ElogbooksCopyPasta.$inject = ['messenger'];

    function ElogbooksCopyPasta(messenger) {
        return {
            restrict: 'AE',
            scope: {
                text: '@'
            },
            templateUrl: '/modules/directives/elb-copypasta/copypasta.html',
            link: function($scope, element, attributes) {
                $scope.copyToClipboard = copyToClipboard;

                /**
                 * The reason this is the way it is: https://stackoverflow.com/a/30810322
                 * (See 'complex example')
                 */
                function copyToClipboard() {
                    // text area method
                    var textArea = document.createElement("textarea");
                    textArea.style.position = 'fixed';
                    textArea.style.top = 0;
                    textArea.style.left = 0;
                    textArea.style.width = '2em';
                    textArea.style.height = '2em';
                    textArea.style.padding = 0;
                    textArea.style.border = 'none';
                    textArea.style.outline = 'none';
                    textArea.style.boxShadow = 'none';
                    textArea.style.background = 'transparent';
                    textArea.value = $scope.text;

                    document.body.appendChild(textArea);

                    textArea.select();

                    try {
                        var successful = document.execCommand('copy');

                        if (successful) {
                            messenger.success('COPIED_TO_CLIPBOARD');
                        } else {
                            messenger.error('BROWSER_UNSUPPORTED');
                        }
                    } catch (err) {
                        messenger.error('BROWSER_UNSUPPORTED');
                    }

                    document.body.removeChild(textArea);

                    return false;
                }
            }
        };
    }
})();
