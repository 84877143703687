app.config(function ($translateProvider) {
    $translateProvider.translations('en-gb', {
        SITE_ASSOCIATE_TYPE: 'Site Associate Type',
        SITE_ASSOCIATE_USER: 'Site Associate User',
        SITE_ASSOCIATE_TYPES:           'Site Associate Types',
        SITE_ASSOCIATE_TYPE_INFORMATION:'Site Associate Type Information',
        ADD_NEW_SITE_ASSOCIATE_TYPE:    'Add a new Site Associate Type',
        EDIT_SITE_ASSOCIATE_TYPE:       'Edit Site Associate Type',

        SITE_ASSOCIATE_TYPE_LIST: 'Site Associate Types',
        SITE_ASSOCIATE_TYPE_ADD: 'Add a new Site Associate Type',
        SITE_ASSOCIATE_TYPE_EDIT: 'Edit Site Associate Type',

        SITE_ASSOCIATE_TYPE_CREATED: 'Site Associate Type Created',
        SITE_ASSOCIATE_TYPE_UPDATED: 'Site Associate Type Updated',
        SITE_ASSOCIATE_TYPE_DELETED: 'Site Associate Type Deleted',


        ASSOCIATES: 'Associates',
        SITE_ASSOCIATES: 'Site Associates',
        SITE_ASSOCIATE_LIST: 'Site Associates',

        NO_ASSOCIATES_ADDED: 'No Associates Selected',
        CANNOT_DELETE_TYPE: 'This Type Cannot Be Deleted'
    });
});
