(function (){
    'use strict';

    angular
        .module('elogbooks.admin.job-requisites')
        .controller('JobRequisitesDetailsController', JobRequisitesDetailsController);

    JobRequisitesDetailsController.$inject = ['jobRequisiteResponse', 'userManager'];

    function JobRequisitesDetailsController (jobRequisiteResponse, userManager) {
        var vm = this;
        vm.jobRequisite = jobRequisiteResponse.getData();
        vm.canApplyJobRequisite = !userManager.hasPermission('user_permission_add_edit_job_requisites') || userManager.hasPermission('user_permission_manage_job_requisites');
    }
})();
