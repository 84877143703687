(function () {
    'use strict';

    angular
        .module('elogbooksDirectives')
        .directive('elbSpillageWorkflowButtons', ElogbooksSpillageWorkflowButtons);

    ElogbooksSpillageWorkflowButtons.$inject = [];

    function ElogbooksSpillageWorkflowButtons () {
        return {
            restrict: 'AE',
            scope: true,
            templateUrl: '/modules/directives/elb-spillage-workflow-buttons/spillage-workflow-buttons.html'
        };
    }
})();
