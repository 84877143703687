(function() {
    'use strict';

    angular
        .module('elogbooks')
        .config(Translations);

    function Translations($translateProvider) {
        $translateProvider.translations('en-gb', {
            FILE_VISIBILITY_PUBLIC: 'Public',
            FILE_VISIBILITY_PARTNERSHIPS: 'Partnership',
            FILE_VISIBILITY_OCCUPIERS: 'Occupiers'
        });
    }
})();
