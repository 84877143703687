/**
 * Panel Title Tabs
 */
angular.module('elogbooksDirectives').directive('elbPanelTitleTabs', ['$http', 'apiClient', '$state', function ($http, apiClient, $state) {
    return {
        restrict: 'E',
        transclude: true,
        scope: true,
        replace: true,
        templateUrl: '/modules/directives/layout/panel-title-tabs/panel-title-tabs.html',
        link: function ($scope, $element, $attrs) {
            $scope.title = $attrs.title || '';
            $scope.headerCol = $attrs.headerCol || 'col-md-6';
            $scope.tabCol = $attrs.tabCol || 'col-md-6';
        }

    };
}]);
