angular.module('elogbooksServices').service('elbSettings', function ($window, $q, lodash, apiClient) {

    // NOTE: Cache should be cleared when the user logs out, should not need to handle that here
    'use strict';

    var self = this;
        this.settings = null;

    this.getSettings = function (reload, settingsUrl) {
        if (!$window.localStorage.getItem('settingsUrl') && !settingsUrl) {
            return;
        }

        if (!reload && $window.localStorage.getItem('settings')) {
            var settingsJson = $window.localStorage.getItem('settings');

            if (settingsJson) {
                try {
                    self.settings = angular.fromJson(settingsJson);
                    return $q.resolve(self.settings);
                } catch (e) {
                    console.error("Invalid settings in localStorage", e);
                    return $q.reject("Invalid settings format in localStorage.");
                }
            }
        }

        return apiClient.get(settingsUrl || $window.localStorage.getItem('settingsUrl'), null, !reload)
            .then(function (response) {
                self.settings = response.settings;
                $window.localStorage.setItem('settings', angular.toJson(self.settings));

                return response;
            }, function () {
                return $q.reject("Unable to retrieve settings from server.");
            });
    };

    this.getSetting = function (name, defaultValue = null) {
        // If settings are not loaded (init not called in time), return default value
        if (!self.settings) {
            console.warn(`Settings not loaded, returning default value for ${name}`);
            return { value: defaultValue };
        }

        const setting = lodash.find(self.settings, { name: name });
        if (!setting) {
            console.warn(`Setting "${name}" not found, returning default value`);
            return { value: defaultValue };
        }

        return setting;
    };

    this.init = function() {
        const settingsJson = $window.localStorage.getItem('settings');
        if (settingsJson) {
            try {
                self.settings = angular.fromJson(settingsJson);
            } catch (e) {
                console.error("Invalid settings in localStorage", e);
                self.settings = null;
            }
        }
    };

    this.init();

    /** @todo Load Settings */
    /** @todo Settings Form Fields Types */
    /** @todo Limit Settings */
});
