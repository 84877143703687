/**
 * Sharing Setup Controller
 */
(function () {
    'use strict';

    angular.module('elogbooks.user.sharing-setup')
        .controller('ShareSetupController', ShareSetupController);

    ShareSetupController.$inject = ['API_URL', '$window', '$state', '$stateParams', '$location', 'apiClient', 'userManager', 'messenger', 'base64', 'siteCollectionResponse', 'modulesService'];

    function ShareSetupController (API_URL, $window, $state, $stateParams, $location, apiClient, userManager, messenger, base64, siteCollectionResponse, modulesService) {
        var vm = this;
        vm.serviceProviderSelect = {};
        vm.loadServiceProviders = loadServiceProviders;
        vm.payload = JSON.parse(base64.decode($stateParams.payload));
        vm.requestData = {};
        vm.cancel = function () {
            $window.location.replace(vm.payload.redirect + '&successShareSetup=false');
        };

        if (!modulesService.isEnabled('sharing')) {
            $state.go('dashboard.user.dashboard').then(function() {
                messenger.error('SHARING_SETTING_DISABLED');
            });
        }

        vm.siteSelect = {
            response: siteCollectionResponse,
            link: siteCollectionResponse.getLink('self'),
            responseKeyPath: 'sites',
            itemHrefPath: '_links.self.href',
            itemValuePath: 'name',
            required: true,
            populateDisabled: true,
            onSelect: vm.loadServiceProviders,
            onRemove: vm.loadServiceProviders
        };

        function loadServiceProviders() {
            vm.serviceProviderSelect = {};

            if (vm.siteSelect.selected) {
                apiClient.get(vm.siteSelect.selected.object.getLink('partnerships')).then(function(response) {
                    vm.serviceProviderSelect = {
                        response: response,
                        link: response.getLink('self'),
                        responseKeyPath: 'partnerships',
                        itemValuePath: '_links.serviceprovider.title',
                        required: true,
                    };
                });
            }
        }

        vm.setup = function () {
            vm.requestData = {
                headers: vm.payload.headers,
                url: vm.payload.url,
                active: 1,
                activeShare: 1
            };

            if (vm.serviceProviderSelect.selected) {
                vm.requestData._links = {
                    partnership: {
                        href: vm.serviceProviderSelect.selected.object.getLink('self')
                    }
                };

                apiClient.create(vm.siteSelect.selected.object.getLink('create-webhook') + '?sharing=true', vm.requestData).then(function (response) {
                    if (response) {
                        $window.location.replace(vm.payload.redirect + '&successShareSetup=' + base64.encode(JSON.stringify({
                                headers: [
                                    {
                                        key: 'Authorization',
                                        value: 'Bearer ' + response.metadata.token
                                    }
                                ],
                                url: API_URL + response.metadata.url,
                                partnershipWebhooks: vm.payload.partnershipWebhooks
                            }))
                        );
                    } else {
                        messenger.error('REQUEST_ERROR');
                    }
                });
            }
        }
    }
})();
