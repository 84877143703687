(function () {
    'use strict';

    angular
        .module('elogbooks.user.reports')
        .controller('UserReportsExtensionRequestsPrioritiesController', UserReportsExtensionRequestsPrioritiesController);

    UserReportsExtensionRequestsPrioritiesController.$inject = ['$state', '$stateParams', 'prioritiesCollection', 'operativeResponse', 'modalConfig'];

    function UserReportsExtensionRequestsPrioritiesController($state, $stateParams, prioritiesCollection, operativeResponse, modalConfig) {
        var vm = this;

        vm.prioritiesCollection = prioritiesCollection;
        vm.priorities = prioritiesCollection.priorities;
        vm.op = operativeResponse;

        vm.exportParams = {limit: 30};

        vm.entityType = $state.current.name.indexOf('quote') > -1 ? 'quoteErs' : 'jobErs';

        vm.modalConfig = modalConfig;
        }
})();