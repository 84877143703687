/**
 * Panel body
 */
angular.module('elogbooksDirectives').directive('panelBody', ['$http', 'apiClient', '$state', function ($http, apiClient, $state) {
    return {
        restrict: 'AE',
        replace: true,
        transclude: true,
        templateUrl: '/modules/directives/layout/panel-body/panel-body.html'
    };
}]);
