app.config(function ($translateProvider) {
    $translateProvider.translations('zh-hk', {
        JOB_GROUP: '工作組別',
        JOB_GROUPS: '工作組別',

        ADD_NEW_JOB_GROUP: '新增工作組別',
        EDIT_JOB_GROUP: '修改工作組別',

        JOB_GROUP_LIST: '工作組別',
        JOB_GROUP_ADD: '新增工作組別',
        JOB_GROUP_EDIT: '修改工作組別',

        JOB_GROUP_CREATED: '工作組別已建立',
        JOB_GROUP_UPDATED: '工作組別已更新',
        JOB_GROUP_DELETED: '工作組別已刪除',
        JOB_GROUP_ADDED: '工作組別已增加',
    });
});
