/**
 * Yes/No filter from true/false
 */
angular.module('elogbooksFilters').filter('yesno', function() {
    return function(value) {
        if (value && value != 0 && value !== false) {
            return 'Yes';
        }
        return 'No';
    };
});
