(function () {
    'use strict';

    angular
        .module('elogbooks.user.patrols')
        .controller('PatrolDetailsController', PatrolDetailsController);

    PatrolDetailsController.$inject = ['patrolResponse'];

    function PatrolDetailsController (patrolResponse) {
        var vm = this;
        vm.patrol = patrolResponse;
    }
})();
