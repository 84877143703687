(function () {
    'use strict';

    angular
        .module('elogbooks.admin.site-associate-types')
        .config(registerRoutes)
        .config(registerRoutesList)
        .config(registerRoutesAdd)
        .config(registerRoutesDetails)
        .config(registerRoutesEdit);

    function registerRoutes($stateProvider) {
        $stateProvider
            .state('dashboard.admin.site-associate-types', {
                parent: 'dashboard.admin',
                abstract: true,
                templateUrl: '/shared-templates/view.html',
                ncyBreadcrumb: {
                    skip: true
                },
                data: {
                    permissions: ['user_permission_view_associate_types']
                }
            });
    }

    function registerRoutesList($stateProvider) {
        $stateProvider
            .state('dashboard.admin.site-associate-types.list', {
                url: '/site-associate-types?page&limit&order&id&name',
                parent: 'dashboard.admin.site-associate-types',
                data: {
                    permissions: ['user_permission_view_associate_types']
                },
                views: {
                    '@dashboard.admin.site-associate-types': {
                        templateUrl: '/modules/admin/site-associate-types/site-associate-types-list.html',
                        controller: 'SiteAssociateTypesController',
                        controllerAs: 'vm'
                    }
                },
                resolve: {
                    siteAssociateTypeCollectionResponse: function ($stateParams, apiClient, rootResourceResponse) {
                        return apiClient.get(rootResourceResponse.getLink('siteassociatetypes'), $stateParams).then(function (response) {
                            return response || apiClient.noResourceFound();
                        });
                    }
                },
                params: {
                    page: '1',
                    limit: '30',
                    order: 'id',
                    id: {value: null, type: 'int'},
                    name: {value: null, type: 'string'}
                },
                ncyBreadcrumb: {
                    label: '{{ ::"SITE_ASSOCIATE_TYPES" | translate }}'
                }
            });
    }

    function registerRoutesAdd($stateProvider) {
        $stateProvider
            .state('dashboard.admin.site-associate-types.list.add', {
                url: '/add',
                parent: 'dashboard.admin.site-associate-types.list',
                data: {
                    permissions: ['user_permission_manage_associate_types']
                },
                views: {
                    '@dashboard.admin.site-associate-types': {
                        templateUrl: '/modules/admin/site-associate-types/add-edit/site-associate-type-form.html',
                        controller: 'SiteAssociateTypeAddEditController',
                        controllerAs: 'vm'
                    }
                },
                resolve: {
                    siteAssociateTypeResponse: function () {
                        return {};
                    },
                    parentCollectionResponse: function (apiClient, rootResourceResponse) {
                        return apiClient.get(rootResourceResponse.getLink('siteassociatetypes'), {page: 1, limit: 30});
                    }
                },
                ncyBreadcrumb: {
                    label: '{{ ::"ADD" | translate }}'
                }
            });
    }

    function registerRoutesDetails($stateProvider) {
        $stateProvider
            .state('dashboard.admin.site-associate-types.list.details', {
                url: '/details/{resource}',
                abstract: true,
                parent: 'dashboard.admin.site-associate-types.list',
                data: {
                    permissions: ['user_permission_view_associate_types']
                },
                views: {
                    '@dashboard.admin.site-associate-types': {
                        templateUrl: '/modules/admin/site-associate-types/details/site-associate-type-details.html',
                        controller: 'SiteAssociateTypeDetailsController',
                        controllerAs: 'vm'
                    }
                },
                resolve: {
                    siteAssociateTypeResponse: function (apiClient, $stateParams) {
                        return apiClient.get($stateParams.resource.decode()).then(function (response) {
                            return response || apiClient.noResourceFound();
                        });
                    }
                },
                ncyBreadcrumb: {
                    label: '{{ ::$resolves().siteAssociateTypeResponse.name }}'
                }
            })
            .state('dashboard.admin.site-associate-types.list.details.info', {
                url: '/info',
                parent: 'dashboard.admin.site-associate-types.list.details',
                data: {
                    permissions: ['user_permission_view_associate_types']
                },
                views: {
                    '@dashboard.admin.site-associate-types.list.details': {
                        templateUrl: '/modules/admin/site-associate-types/details/info/site-associate-type-info.html',
                        controller: 'SiteAssociateTypeInfoController',
                        controllerAs: 'vm'
                    }
                },
                ncyBreadcrumb: {
                    label: '{{ ::"INFO" | translate }}'
                }
            });
    }

    function registerRoutesEdit($stateProvider) {
        $stateProvider
            .state('dashboard.admin.site-associate-types.list.details.info.edit', {
                url: '/edit',
                parent: 'dashboard.admin.site-associate-types.list.details.info',
                data: {
                    permissions: ['user_permission_manage_associate_types']
                },
                views: {
                    '@dashboard.admin.site-associate-types.list.details': {
                        templateUrl: '/modules/admin/site-associate-types/add-edit/site-associate-type-form.html',
                        controller: 'SiteAssociateTypeAddEditController',
                        controllerAs: 'vm'
                    }
                },
                resolve: {
                    parentCollectionResponse: function (apiClient, rootResourceResponse) {
                        return apiClient.get(rootResourceResponse.getLink('siteassociatetypes'), {page: 1, limit: 30});
                    }
                },
                ncyBreadcrumb: {
                    label: '{{ ::"EDIT" | translate }}'
                }
            });
    }
})();
