(function() {
    'use strict';

    angular
        .module('elogbooks.user.meter-types')
        .controller('MeterTypesAddEditController', MeterTypesAddEditController);

    MeterTypesAddEditController.$inject = ['$state', '$translate', 'apiClient', 'confirmationModal', 'messenger', 'meterTypeResponse', 'meterTypesCollectionResponse', 'requestDataFactory'];
    function MeterTypesAddEditController($state, $translate, apiClient, confirmationModal, messenger, meterTypeResponse, meterTypesCollectionResponse, requestDataFactory) {
        var vm = this;
        vm.meterType = meterTypeResponse;
        vm.data = requestDataFactory.createRequest(meterTypeResponse);
        vm.meterTypesCollectionResponse = meterTypesCollectionResponse;

        vm.create = createAction;
        vm.update = updateAction;
        vm.delete = deleteAction;

        function updateAction() {
            return apiClient.update(vm.meterType.getLink('edit'), vm.data).then(function(response) {
                if (response) {
                    $state.go('^', {}, {reload: $state.get('^.^.^')}).then(function() {
                        messenger.success('METER_TYPE_UPDATED');
                    });
                } else {
                    messenger.error('REQUEST_ERROR');
                }
            });
        }

        function createAction() {
            return apiClient.create(vm.meterTypesCollectionResponse.getLink('create'), vm.data).then(function(response) {
                if (response) {
                    $state.go('^.details.info', {resource: response.getLink('self').encode()}, { reload: $state.current.parent }).then(function() {
                        messenger.success('METER_TYPE_CREATED');
                    });
                } else {
                    messenger.error('REQUEST_ERROR');
                }
            });
        }

        function deleteAction() {
            return confirmationModal.open().result.then(function(response) {
                if (response.result) {
                     apiClient.delete(vm.meterType.getLink('delete')).then(function(isSuccess) {
                        if (isSuccess) {
                            $state.go('^.^.^', {}, {reload: $state.get('^.^.^')}).then(function(){
                                messenger.success('METER_TYPE_DELETED');
                            });
                        } else {
                            messenger.error('REQUEST_ERROR');
                        }
                    });
                }
            });
        }
    }
})();
