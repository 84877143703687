(function () {
    'use strict';

    angular
        .module('elogbooksDirectives')
        .directive('elbApproversEdit', approversEditDirective);

    approversEditDirective.$inject = [];

    function approversEditDirective() {
        return {
            restrict: 'AE',
            templateUrl: '/modules/directives/approvers/edit/approvers-edit.html',
            controllerAs: 'vm',
            controller: ApproversEditController,
            scope: {
                approverTypes: '=',
                approversResponse: '=',
                cancelButtonLabel: '@',
                approverCollectionResponse: '=',
                responseKeyPath: '@',
                itemValuePath: '@',
                linkParameters: '=',
                disableDrag: '=',
                title: '@'
            }
        };

        function ApproversEditController(
            $scope,
            $state,
            messenger,
            lodash,
            requestDataFactory,
            sortableOptions,
            apiClient
        ) {
            var vm = this;
            vm.approversResponse = $scope.approversResponse;
            vm.cancelButtonLabel = $scope.cancelButtonLabel ? $scope.cancelButtonLabel : 'CANCEL';
            vm.title = $scope.title ? $scope.title : 'APPROVERS';
            vm.disableDrag = $scope.disableDrag;

            vm.updateAction = updateAction;
            vm.cancel = cancelAction;
            vm.addApproverToAll = addApproverToAll;
            vm.removeApproverFromAll = removeApproverFromAll;

            vm.parseLinks = function (approvers) {
                if (typeof approvers.approvers === 'undefined'
                    || typeof approvers.approvers.approverUsers === 'undefined') {
                    return {
                        user: []
                    };
                }

                var user = [];
                lodash.each(approvers.approvers.approverUsers, function (approverUser) {
                    user.push({
                        href: approverUser._links.user.href,
                    });
                });

                return angular.extend({}, { user: user }, { approvers: approvers.approvers.approverUsers });
            };

            vm.approverSelect = [];
            vm.approvers = [];

            angular.forEach($scope.approverTypes, function (approverType, key) {
                vm.approverSelect[key] = {
                    response: $scope.approverCollectionResponse,
                    link: $scope.approverCollectionResponse.getLink('self'),
                    linkParameters: $scope.linkParameters ? $scope.linkParameters : {},
                    responseKeyPath: $scope.responseKeyPath,
                    itemValuePath: $scope.itemValuePath,
                    onSelect: function () { },
                    onRemove: function () { },
                    appendToBody: true,
                    selected: null
                };

                vm.approvers[key] = {
                    required: vm.approversResponse[key].required,
                    _links: vm.parseLinks(vm.approversResponse[key])
                }
            });

            vm.bulkSelect = {
                response: $scope.approverCollectionResponse,
                link: $scope.approverCollectionResponse.getLink('self'),
                linkParameters: $scope.linkParameters ? $scope.linkParameters : {},
                responseKeyPath: $scope.responseKeyPath,
                itemValuePath: $scope.itemValuePath,
                onSelect: function () { },
                onRemove: function () { },
                appendToBody: true,
                selected: null
            };

            vm.data = requestDataFactory.createRequest(vm.approvers);

            var watcher = undefined;

            startWatcher();

            function startWatcher() {
                watcher = $scope.$watch('vm.data', function (newValue, oldValue) {
                    if (newValue !== oldValue) {
                        updateAction();
                    }
                }, true);
            }

            function stopWatcher() {
                watcher();
                watcher = undefined;
            }

            vm.addApprover = function (approvers, selectedUser, key) {
                var approverExists = false;

                lodash.each(approvers.approvers, function (approver) {
                    if (lodash.isEqual(approver._links.user.href, selectedUser._links.self.href)) {
                        messenger.error(('USER_ALREADY_' + key).toUpperCase());
                        approverExists = true;
                    }
                });

                if (!approverExists) {
                    approvers.approvers.push({
                        _links: {
                            user: {
                                href: selectedUser._links.self.href,
                                id: selectedUser.id,
                                title: selectedUser.name
                            }
                        }
                    });
                    approvers.user.push({
                        href: selectedUser._links.self.href
                    });
                }

                vm.approverSelect[key].selected = null;
            };


            function addApproverToAll(generalApprover) {
                var approverExists;

                // stop watcher, so we process update once at end of loop
                stopWatcher();

                lodash.each(vm.data, function (approverType) {
                    approverExists = false;
                    if (lodash.has(approverType, '_links')) {
                        var approverLinks = approverType._links;

                        lodash.each(approverLinks.approvers, function (approver) {
                            if (lodash.isEqual(approver._links.user.href, generalApprover._links.self.href)) {
                                approverExists = true;
                            }
                        });
                        if (!approverExists) {
                            approverType._links.approvers.push({
                                _links: {
                                    user: {
                                        href: generalApprover._links.self.href,
                                        id: generalApprover.id,
                                        title: generalApprover.name
                                    }
                                }
                            });
                            approverType._links.user.push({ href: generalApprover._links.self.href });
                        }
                    }
                });

                vm.bulkSelect.selected = null;

                updateAction();
                startWatcher();
            }

            function removeApproverFromAll(generalApprover) {
                // stop watcher, so we process update once at end of loopsite-group.add-edit.controller.js line
                stopWatcher();

                lodash.each(vm.data, function (approverType) {
                    if (lodash.has(approverType, '_links')) {
                        var approverLinks = approverType._links;
                        var removedApprover = false;
                        for (var index = 0; index < approverLinks.approvers.length && !removedApprover ; index++) {
                            var approver = approverLinks.approvers[index];
                            if (lodash.isEqual(approver._links.user.href, generalApprover._links.self.href)) {
                                approverType._links.approvers.splice(index, 1)
                                approverType._links.user.splice(index,1)
                                removedApprover = true;
                            }
                        }
                    }
                });

                vm.bulkSelect.selected = null;

                updateAction();
                startWatcher();
            }

            function cancelAction() {
                $state.go('^.^', {}, { reload: $state.get('^.^') });
            }

            function updateAction() {
                apiClient.update(vm.approversResponse.getLink('edit'), vm.data).then(function (response) {
                    if (response) {
                        $state.go('.', {}, { reload: true}).then(function () {
                            messenger.success('APPROVERS_UPDATED');
                        });
                    } else {
                        messenger.error('REQUEST_ERROR');
                    }
                });
            }
        }
    }
})();