(function() {
    'use strict';

    angular
        .module('elogbooks.common.partnership')
        .controller('PartnershipAddController', PartnershipAddController);

    PartnershipAddController.$inject = ['$state', 'apiClient', 'messenger', 'siteResponse', 'sitePartnerships','userResponse'];

    function PartnershipAddController($state, apiClient, messenger, siteResponse, sitePartnerships, userResponse) {
        var vm = this;
        vm.site = siteResponse.getData();
        vm.rootResourceResponse = userResponse;
        vm.serviceProviders = sitePartnerships.serviceproviders.length > 0 ? sitePartnerships.serviceproviders : null;
        vm.selectedServiceProviders = [];
        vm.disableSubmit = false;

        vm.serviceProviderSelect = {
            response: sitePartnerships,
            link: sitePartnerships ? sitePartnerships.getLink('self') : null,
            required: true,
            responseKeyPath: 'serviceproviders',
            itemHrefPath: '_links.self.href'
        };

        vm.create = function() {
            if (!vm.serviceProviderSelect.response) {
                return;
            }

            var partnershipRecords = {
                _links: {
                    serviceprovider: []
                }
            };

            angular.forEach(vm.selectedServiceProviders, function(value) {
                partnershipRecords._links.serviceprovider.push({
                    href: value.href
                });
            })
            vm.disableSubmit = true;
            apiClient.create(siteResponse.getLink('partnerships'), partnershipRecords).then(function(response) {
                if (response) {
                    $state.go('^', {}, {reload: $state.get('^')}).then(function() {
                        messenger.success('PARTNERSHIP_CREATED');
                    });
                } else {
                    vm.disableSubmit = false;
                    messenger.error('REQUEST_ERROR');
                }
            });
        };
    }
})();
