app.config(function ($translateProvider) {
    $translateProvider.translations('en-gb', {
        SITE_NOTE_TYPE_INFORMATION: 'Site Note Type Information',

        ADD_NEW_SITE_NOTE_TYPE: 'Add a new Site Note Type',
        EDIT_SITE_NOTE_TYPE: 'Edit Site Note Type',

        ALLOW_NOT_APPLICABLE: 'Allow Not Applicable',
        ALLOW_HELPDESK: 'Allow Helpdesk to view',

        NOTE_VISIBILITY: 'Visibility',
        VISIBILITY: 'Visibility',
        VISIBILITY_PRIVATE: 'Private',
        VISIBILITY_TENANT: 'Tenant',
        VISIBILITY_SERVICE_PROVIDER: 'Service Provider',

        SITE_NOTE_TYPE_LIST: 'Site Note Types',
        SITE_NOTE_TYPE_ADD: 'Add a new Site Note Type',
        SITE_NOTE_TYPE_EDIT: 'Edit Site Note Type',

        SITE_NOTE_TYPE_CREATED: 'Site Note Type created successfully',
        SITE_NOTE_TYPE_UPDATED: 'Site Note Type updated successfully',
        SITE_NOTE_TYPE_DELETED: 'Site Note Type deleted successfully',

        ALL_SITE_NOTE_TYPES_UPDATED: 'All Site Note Types updated successfully',

        DISPLAY_ORDER: 'Display Order',

        SITE_NOTE_TYPES_ORDER_UPDATED: 'Site Note Types order updated successfully',

        CONFIRM_SITE_NOTE_TYPE_ORDER_SAVE: 'Please be aware any changes made will affect how Site Notes appear for all Sites',

        SITE_NOTE_TYPE_UNSAVED_CHANGES: 'You have unsaved changes'

    });
});
