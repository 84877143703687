;(function() {
    'use strict';

    angular
        .module('elogbooksDirectives')
        .directive('elbSlider', ElogbooksSliderDirective);

    ElogbooksSliderDirective.$inject = [];

    function ElogbooksSliderDirective() {
        return {
            restrict: 'E',
            scope: {
                model: '=',
                options: '=?',
            },
            templateUrl: '/modules/directives/elb-slider/slider.html',
            controller: ElogbooksSliderController,
            controllerAs: 'vm'
        };

        ElogbooksSliderController.$inject = ['$scope', '$timeout'];

        function ElogbooksSliderController($scope, $timeout) {
            var vm = this;
            vm.model = $scope.model;
            vm.options = $scope.options;

            vm.options.min = parseInt(vm.options.min || 0, 10);
            vm.options.max = parseInt(vm.options.max || 100, 10);
            vm.options.step = parseInt(vm.options.step || 1, 10);
            vm.model = parseInt(vm.model || vm.options.min, 10);

            $scope.$watch('vm.model', function(newValue, oldValue) {
                $scope.model = newValue;
            });
        }
    }
})();
