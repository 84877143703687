(function() {
    'use strict';

    angular
        .module('elogbooks.user.reports')
        .config(registerRoutes)
        .config(registerServiceProviderSummaryReportRoutes)
        .config(registerSiteAssociateActivityReportRoutes)
        .config(registerAgeOfApprovalsReportRoutes)
        .config(registerSummaryReportRoutes)
        .config(registerPpmGapAnalysisReportRoutes)
        .config(registerOpenCloseReportRoutes)
        .config(registerMonthlyReportRoutes)
        .config(registerEngineerRiskReportRoutes)
        .config(registerPortfolioComplianceReportRoutes)
        .config(registerAuditTrendReportRoutes)
        .config(registerSPUsageReportRoutes)
        .config(registerResidentialLocationReportRoutes)
        .config(registerOperativePerformanceReportRoutes)
        .config(registerKpiMonthlyReportRoutes)
        .config(registerServiceProviderFeedbackReportRoutes);

    var parentModal = ['$uibModal', '$state', '$stateParams', function ($uibModal, $state, $stateparams) {
        $uibModal.open({
            templateUrl: '/modules/user/reports/common/modals/sp-op/base.html',
            controller: ['$state', function($state) {
                var vm = this;
                vm.parentState = $state.$current.parent.parent.self.parent;
            }],
            size: 'xl',
            controllerAs: 'vm'
        }).result.finally(function() {
            $state.go('^.^.^');
        });
    }];

    var siteAssociateParentModal = ['$uibModal', '$state', '$stateParams', function ($uibModal, $state, $stateparams) {
        $uibModal.open({
            templateUrl: '/modules/user/reports/site-associate-activity/modals/base.html',
            size: 'xl',
        }).result.finally(function() {
            $state.go('^.^.^');
        });
    }];

    var dateRangeMinus1Day = moment().subtract(30, 'days').startOf('day').format()+','+moment().endOf('day').subtract(1, 'days').format();
    var monthlyReportDefault = moment().subtract(1, 'month').startOf('month').startOf('day').format()+','+moment().subtract(1, 'month').startOf('month').add(11, 'month').endOf('month').endOf('day').format();
    var KpiMonthlyReportDefault = moment().subtract(12, 'month').startOf('month').startOf('day').format()+','+moment().subtract(1, 'month').startOf('month').endOf('month').endOf('day').format();
    var engineeringRiskReportDefault = moment().subtract(12, 'month').startOf('month').startOf('day').format()+','+moment().startOf('month').add(11, 'month').endOf('month').endOf('day').format();

    function dismissModalOnBack(uibModalInstance) {
        var top = uibModalInstance.getTop();

        if (top) {
            uibModalInstance.dismiss(top.key);
        }
    }

    function registerRoutes($stateProvider) {
        $stateProvider
            .state('dashboard.user.reports', {
                abstract: true,
                template: '<bookerize></bookerize>',
                ncyBreadcrumb: {
                    skip: true
                }
            })
            .state('dashboard.user.reports.list', {
                url: '/reports',
                parent: 'dashboard.user.reports',
                views: parseBook({
                    'elogbooks-left@dashboard.user.reports': {
                        templateUrl: '/modules/user/reports/reports-list.html',
                        controller: 'UserReportsListController',
                        controllerAs: 'vm'
                    }
                }),
                resolve:{
                    rfmId: function() {
                        // This will be returned via the fixed site associate type id 1/2 (so it can be different on each installation)
                        return 'RFM';
                    },
                    siteAssociateAlias: function() {
                        // This will be returned via the fixed site associate type id 1/2 (so it can be different on each installation)
                        return 'FM';
                    }
                },
                ncyBreadcrumb: {
                    label: '{{ ::"REPORTS" | translate }}'
                }
            })
            .state('dashboard.user.reports.list.view', {
                url:  '/view?page&limit&order&{name:any}coreClient&coreClientName&priorities&serviceProviderGroup&serviceProviderGroupName&showPercentages&site&siteAssociate&siteAssociateId&siteAssociateName&siteAssociateType&siteAssociateTypeName&siteGroup&siteGroupName&siteName&siteRegion&siteRegionId&siteRegionName&rfmId&siteId&serviceProviderId&tenant&type&location&hours&serviceProvider',
                parent: 'dashboard.user.reports.list',
                abstract: true,
                params: {
                    page: '1',
                    limit: '10',
                    order: 'name',
                    name: null,
                    siteRegionName: null,
                    siteRegion: null,
                    siteRegions: {array: true},
                    siteRegionId: null,
                    site: null,
                    siteName: null,
                    siteGroup: null,
                    siteGroupName: null,
                    serviceProviderGroup: null,
                    serviceProviderGroups: {array: true},
                    serviceProviderGroupName: null,
                    serviceProviderId: null,
                    showPercentages: 'false',
                    siteAssociate: null,
                    siteAssociateName: null,
                    siteAssociateId: null,
                    survey: null,
                    tenant: null,
                    rfmId: null,
                    priorities: {array: true},
                    coreClient: null,
                    coreClientName: null,
                    siteAssociateType: null,
                    siteAssociateTypeName: null,
                    serviceProvider: null,
                    serviceProviderName: null,
                    location: null,
                    hours: '24'
                },
                ncyBreadcrumb: {
                    skip: true
                }
            });
    }

    function registerServiceProviderSummaryReportRoutes($stateProvider) {
        $stateProvider
            .state('dashboard.user.reports.list.view.sp-summary', {
                url: '/sp-summary?dateRange',
                parent: 'dashboard.user.reports.list.view',
                views: parseBook({
                    'elogbooks-right@dashboard.user.reports': {
                        templateUrl: '/modules/user/reports/sp-summary/sp-summary-details.html',
                        controller: 'UserReportsSpSummaryDetailsController',
                        controllerAs: 'vm'
                    }
                }),
                resolve: {
                    serviceProvidersCollection: function(apiClient, $stateParams) {
                        var params = angular.copy($stateParams);

                        if (params.site) {
                            params.site = JSON.parse(params.site).v
                        }

                        if (params.location) {
                            params.location = JSON.parse(params.location).v
                        }

                        if (params.coreClient) {
                            params.coreClient = JSON.parse(params.coreClient).v
                        }

                        return apiClient.get('/reports/sp-summary', params).then(function(response) {
                            return response || apiClient.noResourceFound();
                        });
                    },
                    siteGroupsCollection: function(apiClient, rootResourceResponse, $stateParams) {
                        var params = {};

                        if ($stateParams.siteGroup) {
                            params.id = $stateParams.siteGroup;
                        }

                        return apiClient.get(rootResourceResponse.getLink('sitegroups'), params);
                    },
                    serviceProviderGroupsCollection: function(apiClient, rootResourceResponse, $stateParams) {
                        var params = {};

                        if ($stateParams.serviceProviderGroup) {
                            params.id = $stateParams.serviceProviderGroup;
                        }

                        return apiClient.get(rootResourceResponse.getLink('serviceprovidergroups'), params);
                    },
                    siteCollectionResponse: function(apiClient, user) {
                        return apiClient.get(user.getLink('sites'));
                    },
                    locationCollectionResponse: function (apiClient, $stateParams, user) {
                        if ($stateParams.site !== null) {
                            var selectedSiteId = JSON.parse($stateParams.site).v;
                            var siteIds = [selectedSiteId];

                            // find the site response based on the selected site id
                            return apiClient.get(user.getLink('sites'), {'siteIds[]': siteIds}).then(function (filteredSiteCollectionResponse) {
                                if (filteredSiteCollectionResponse.count === 0) {
                                    return null;
                                }
                                var selectedSiteResponse = filteredSiteCollectionResponse.sites[0];
                                return apiClient.get(selectedSiteResponse.getLink('locations')).then(function (response) {
                                    return response;
                                });
                            });
                        }

                        return null;
                    },
                    coreClientsCollectionResponse: function (apiClient, rootResourceResponse, $stateParams) {
                        if (rootResourceResponse.getLink('coreclients')) {
                            return apiClient.get(rootResourceResponse.getLink('coreclients')).then( function(response) {
                                return response || {};
                            });
                        } else {
                            return {};
                        }
                    }
                },
                params: {
                    dateRange: dateRangeMinus1Day
                },
                ncyBreadcrumb: {
                    label: '{{ ::"SP_SUMMARY_REPORT" | translate }}'
                }
            })
            .state('dashboard.user.reports.list.view.sp-summary.details', {
                url: '/{spResource}',
                abstract: true,
                parent: 'dashboard.user.reports.list.view.sp-summary',
                resolve: {
                    siteAssociateResponse: function() {
                        return null;
                    },
                    serviceProviderResponse: function($stateParams, apiClient) {
                        return apiClient.get($stateParams.spResource.decode()).then(function(response) {
                            return response || apiClient.noResourceFound();
                        });
                    },
                    reactiveMissedStatuses: function() {
                        return ['acknowledged', 'commenced', 'awaiting_paperwork', 'extension_pending', 'completed'];
                    },
                    plannedMissedStatuses: function() {
                        return ['acknowledged', 'commenced', 'missed', 'extension_pending', 'completed'];
                    },
                    operativeResponse: function() {
                        return null;
                    },
                    modalConfig: function($state, serviceProviderResponse, operativeResponse) {
                        return {
                            titlePrefix: operativeResponse ? operativeResponse._links.user.name : serviceProviderResponse.name
                        };
                    }
                },
                ncyBreadcrumb: {
                    skip: true
                },
            })
            .state('dashboard.user.reports.list.view.sp-summary.reactive-attendance', {
                url: '/reactive-attendance',
                parent: 'dashboard.user.reports.list.view.sp-summary.details',
                abstract: true,
                ncyBreadcrumb: {
                    skip: true
                },
                onEnter: ['$uibModal', '$state', '$stateParams', function ($uibModal, $state, $stateParams) {
                    $uibModal.open({
                        templateUrl: '/modules/user/reports/common/modals/reactive-attendance/base.html',
                        size: 'xl',
                        controller: ['$state', function($state) {
                            var vm = this;
                            vm.selectedTab = $state.current.name.split('.')[$state.current.name.split('.').length - 1];
                            vm.parentState = $state.$current.parent.parent.self.parent;
                        }],
                        controllerAs: 'vm'
                    }).result.finally(function() {
                        $state.go('^.^.^');
                    });
                }],
                onExit: ['$uibModalStack', function($uibModalStack) {
                    dismissModalOnBack($uibModalStack);
                }]
            })
            .state('dashboard.user.reports.list.view.sp-summary.reactive-attendance.attended', {
                url: '/attended?modalPage',
                parent: 'dashboard.user.reports.list.view.sp-summary.reactive-attendance',
                params: {
                    modalPage: '1'
                },
                views: {
                    'attended@': {
                        templateUrl: '/modules/user/reports/common/modals/reactive-attendance/reactive-attendance.html',
                        controller: 'ReportsCommonReactiveMissedAttendanceController',
                        controllerAs: 'vm',
                    }
                },
                ncyBreadcrumb: {
                    skip: true
                },
                resolve: {
                    jobParams: function(serviceProviderResponse, $stateParams, reactiveMissedStatuses) {
                        return {
                            type: "reactive",
                            serviceProvider: serviceProviderResponse.id,
                            overdueAttendanceHistoric: false,
                            dateRange: $stateParams.dateRange,
                            'status[]': reactiveMissedStatuses,
                            siteName: $stateParams.siteName,
                            siteRegionName: $stateParams.siteRegionName,
                            siteGroupName: $stateParams.siteGroupName,
                            site: $stateParams.site ? JSON.parse($stateParams.site).v : null,
                            location: $stateParams.location ? JSON.parse($stateParams.location).v : null,
                            coreClient: $stateParams.coreClient ? JSON.parse($stateParams.coreClient).v : null
                        };
                    },
                    jobsCollection: function(apiClient, user, jobParams) {
                        return apiClient.get(user.getLink('jobs'), jobParams).then(function(response) {
                            return response || apiClient.noResourceFound();
                        });
                    }
                },
            })
            .state('dashboard.user.reports.list.view.sp-summary.reactive-attendance.missed', {
                url: '/missed?modalPage',
                parent: 'dashboard.user.reports.list.view.sp-summary.reactive-attendance',
                params: {
                    modalPage: '1'
                },
                views: {
                    'missed@': {
                        templateUrl: '/modules/user/reports/common/modals/reactive-attendance/reactive-attendance.html',
                        controller: 'ReportsCommonReactiveMissedAttendanceController',
                        controllerAs: 'vm',
                    }
                },
                ncyBreadcrumb: {
                    skip: true
                },
                resolve: {
                    jobParams: function(serviceProviderResponse, $stateParams, reactiveMissedStatuses) {
                        return {
                            type: "reactive",
                            serviceProvider: serviceProviderResponse.id,
                            overdueAttendanceHistoric: true,
                            dateRange: $stateParams.dateRange,
                            page: $stateParams.modalPage,
                            'status[]': reactiveMissedStatuses,
                            siteName: $stateParams.siteName,
                            siteRegionName: $stateParams.siteRegionName,
                            siteGroupName: $stateParams.siteGroupName,
                            site: $stateParams.site ? JSON.parse($stateParams.site).v : null,
                            location: $stateParams.location ? JSON.parse($stateParams.location).v : null,
                            coreClient: $stateParams.coreClient ? JSON.parse($stateParams.coreClient).v : null
                        };
                    },
                    jobsCollection: function(apiClient, user, jobParams) {
                        return apiClient.get(user.getLink('jobs'), jobParams).then(function(response) {
                            return response || apiClient.noResourceFound();
                        });
                    }
                },
            })
            .state('dashboard.user.reports.list.view.sp-summary.reactive-completion', {
                url: '/reactive-completion',
                parent: 'dashboard.user.reports.list.view.sp-summary.details',
                abstract: true,
                ncyBreadcrumb: {
                    skip: true
                },
                onEnter: ['$uibModal', '$state', '$stateParams', function ($uibModal, $state, $stateParams) {
                    $uibModal.open({
                        templateUrl: '/modules/user/reports/common/modals/reactive-completion/base.html',
                        size: 'xl',
                        controller: ['$state', function($state) {
                            var vm = this;
                            vm.selectedTab = $state.current.name.split('.')[$state.current.name.split('.').length - 1];
                            vm.parentState = $state.$current.parent.parent.self.parent;
                        }],
                        controllerAs: 'vm'
                    }).result.finally(function() {
                        $state.go('^.^.^');
                    });
                }],
                onExit: ['$uibModalStack', function($uibModalStack) {
                    dismissModalOnBack($uibModalStack);
                }]
            })
            .state('dashboard.user.reports.list.view.sp-summary.reactive-completion.completed', {
                url: '/completed?modalPage',
                parent: 'dashboard.user.reports.list.view.sp-summary.reactive-completion',
                params: {
                    modalPage: '1'
                },
                views: {
                    'completed@': {
                        templateUrl: '/modules/user/reports/common/modals/reactive-completion/reactive-completion.html',
                        controller: 'ReportsCommonReactiveMissedCompletionController',
                        controllerAs: 'vm',
                    }
                },
                ncyBreadcrumb: {
                    skip: true
                },
                resolve: {
                    jobParams: function(serviceProviderResponse, $stateParams, reactiveMissedStatuses) {
                        return {
                            type: "reactive",
                            serviceProvider: serviceProviderResponse.id,
                            overdueCompletionHistoric: false,
                            dateRange: $stateParams.dateRange,
                            'status[]': reactiveMissedStatuses,
                            page: $stateParams.modalPage,
                            siteName: $stateParams.siteName,
                            siteRegionName: $stateParams.siteRegionName,
                            siteGroupName: $stateParams.siteGroupName,
                            site: $stateParams.site ? JSON.parse($stateParams.site).v : null,
                            location: $stateParams.location ? JSON.parse($stateParams.location).v : null,
                            coreClient: $stateParams.coreClient ? JSON.parse($stateParams.coreClient).v : null
                        };
                    },
                    jobsCollection: function(apiClient, user, jobParams) {
                        return apiClient.get(user.getLink('jobs'), jobParams).then(function(response) {
                            return response || apiClient.noResourceFound();
                        });
                    }
                },
            })
            .state('dashboard.user.reports.list.view.sp-summary.reactive-completion.missed', {
                url: '/missed?modalPage',
                parent: 'dashboard.user.reports.list.view.sp-summary.reactive-completion',
                params: {
                    modalPage: '1'
                },
                views: {
                    'missed@': {
                        templateUrl: '/modules/user/reports/common/modals/reactive-completion/reactive-completion.html',
                        controller: 'ReportsCommonReactiveMissedCompletionController',
                        controllerAs: 'vm',
                    }
                },
                ncyBreadcrumb: {
                    skip: true
                },
                resolve: {
                    jobParams: function(serviceProviderResponse, $stateParams, reactiveMissedStatuses) {
                        return {
                            type: "reactive",
                            serviceProvider: serviceProviderResponse.id,
                            overdueCompletionHistoric: true,
                            dateRange: $stateParams.dateRange,
                            'status[]': reactiveMissedStatuses,
                            page: $stateParams.modalPage,
                            siteName: $stateParams.siteName,
                            siteRegionName: $stateParams.siteRegionName,
                            siteGroupName: $stateParams.siteGroupName,
                            site: $stateParams.site ? JSON.parse($stateParams.site).v : null,
                            location: $stateParams.location ? JSON.parse($stateParams.location).v : null,
                            coreClient: $stateParams.coreClient ? JSON.parse($stateParams.coreClient).v : null
                        };
                    },
                    jobsCollection: function(apiClient, user, jobParams) {
                        return apiClient.get(user.getLink('jobs'), jobParams).then(function(response) {
                            return response || apiClient.noResourceFound();
                        });
                    }
                }
            })
            .state('dashboard.user.reports.list.view.sp-summary.ppm-attendance', {
                url: '/ppm-attendance',
                parent: 'dashboard.user.reports.list.view.sp-summary.details',
                abstract: true,
                ncyBreadcrumb: {
                    skip: true
                },
                onEnter: ['$uibModal', '$state', '$stateParams', function ($uibModal, $state, $stateParams) {
                    $uibModal.open({
                        templateUrl: '/modules/user/reports/common/modals/sp-op/ppm-attendance/base.html',
                        size: 'xl',
                        controller: ['$state', function($state) {
                            var vm = this;
                            vm.selectedTab = $state.current.name.split('.')[$state.current.name.split('.').length - 1];
                            vm.parentState = $state.$current.parent.parent.self.parent;
                        }],
                        controllerAs: 'vm'
                    }).result.finally(function() {
                        $state.go('^.^.^');
                    });
                }],
                onExit: ['$uibModalStack', function($uibModalStack) {
                    dismissModalOnBack($uibModalStack);
                }]
            })
            .state('dashboard.user.reports.list.view.sp-summary.ppm-attendance.attended', {
                url: '/attended?modalPage',
                parent: 'dashboard.user.reports.list.view.sp-summary.ppm-attendance',
                params: {
                    modalPage: '1'
                },
                views: {
                    'attended@': {
                        templateUrl: '/modules/user/reports/common/modals/sp-op/ppm-attendance/ppm-attendance.html',
                        controller: 'UserReportsPpmMissedAttendanceController',
                        controllerAs: 'vm',
                    }
                },
                ncyBreadcrumb: {
                    skip: true
                },
                resolve: {
                    jobParams: function(serviceProviderResponse, $stateParams, plannedMissedStatuses) {
                        return {
                            type: "planned",
                            serviceProvider: serviceProviderResponse.id,
                            overdueAttendanceHistoric: false,
                            dateRange: $stateParams.dateRange,
                            'status[]': plannedMissedStatuses,
                            page: $stateParams.modalPage,
                            siteName: $stateParams.siteName,
                            siteRegionName: $stateParams.siteRegionName,
                            siteGroupName: $stateParams.siteGroupName,
                            site: $stateParams.site ? JSON.parse($stateParams.site).v : null,
                            location: $stateParams.location ? JSON.parse($stateParams.location).v : null,
                            coreClient: $stateParams.coreClient ? JSON.parse($stateParams.coreClient).v : null
                        };
                    },
                    jobsCollection: function(apiClient, user, jobParams) {
                        return apiClient.get(user.getLink('jobs'), jobParams).then(function(response) {
                            return response || apiClient.noResourceFound();
                        });
                    }
                },
            })
            .state('dashboard.user.reports.list.view.sp-summary.ppm-attendance.missed', {
                url: '/missed?modalPage',
                parent: 'dashboard.user.reports.list.view.sp-summary.ppm-attendance',
                params: {
                    modalPage: '1'
                },
                views: {
                    'missed@': {
                        templateUrl: '/modules/user/reports/common/modals/sp-op/ppm-attendance/ppm-attendance.html',
                        controller: 'UserReportsPpmMissedAttendanceController',
                        controllerAs: 'vm',
                    }
                },
                ncyBreadcrumb: {
                    skip: true
                },
                resolve: {
                    jobParams: function(serviceProviderResponse, $stateParams, plannedMissedStatuses) {
                        return {
                            type: "planned",
                            serviceProvider: serviceProviderResponse.id,
                            overdueAttendanceHistoric: true,
                            dateRange: $stateParams.dateRange,
                            'status[]': plannedMissedStatuses,
                            page: $stateParams.modalPage,
                            siteName: $stateParams.siteName,
                            siteRegionName: $stateParams.siteRegionName,
                            siteGroupName: $stateParams.siteGroupName,
                            site: $stateParams.site ? JSON.parse($stateParams.site).v : null,
                            location: $stateParams.location ? JSON.parse($stateParams.location).v : null,
                            coreClient: $stateParams.coreClient ? JSON.parse($stateParams.coreClient).v : null
                        };
                    },
                    jobsCollection: function(apiClient, user, jobParams) {
                        return apiClient.get(user.getLink('jobs'), jobParams).then(function(response) {
                            return response || apiClient.noResourceFound();
                        });
                    }
                },
            })
            .state('dashboard.user.reports.list.view.sp-summary.ppm-completion', {
                url: '/ppm-completion',
                parent: 'dashboard.user.reports.list.view.sp-summary.details',
                abstract: true,
                ncyBreadcrumb: {
                    skip: true
                },
                onEnter: ['$uibModal', '$state', '$stateParams', function ($uibModal, $state, $stateParams) {
                    $uibModal.open({
                        templateUrl: '/modules/user/reports/common/modals/sp-op/ppm-completion/base.html',
                        size: 'xl',
                        controller: ['$state', function($state) {
                            var vm = this;
                            vm.selectedTab = $state.current.name.split('.')[$state.current.name.split('.').length - 1];
                            vm.parentState = $state.$current.parent.parent.self.parent;
                        }],
                        controllerAs: 'vm'
                    }).result.finally(function() {
                        $state.go('^.^.^');
                    });
                }],
                onExit: ['$uibModalStack', function($uibModalStack) {
                    dismissModalOnBack($uibModalStack);
                }]
            })
            .state('dashboard.user.reports.list.view.sp-summary.ppm-completion.completed', {
                url: '/completed?modalPage',
                parent: 'dashboard.user.reports.list.view.sp-summary.ppm-completion',
                params: {
                    modalPage: '1'
                },
                views: {
                    'completed@': {
                        templateUrl: '/modules/user/reports/common/modals/sp-op/ppm-completion/ppm-completion.html',
                        controller: 'UserReportsPpmMissedCompletionController',
                        controllerAs: 'vm',
                    }
                },
                ncyBreadcrumb: {
                    skip: true
                },
                resolve: {
                    jobParams: function(serviceProviderResponse, $stateParams, plannedMissedStatuses) {
                        return {
                            type: "planned",
                            serviceProvider: serviceProviderResponse.id,
                            overdueCompletionHistoric: false,
                            dateRange: $stateParams.dateRange,
                            'status[]': plannedMissedStatuses,
                            page: $stateParams.modalPage,
                            siteName: $stateParams.siteName,
                            siteRegionName: $stateParams.siteRegionName,
                            siteGroupName: $stateParams.siteGroupName,
                            site: $stateParams.site ? JSON.parse($stateParams.site).v : null,
                            location: $stateParams.location ? JSON.parse($stateParams.location).v : null,
                            coreClient: $stateParams.coreClient ? JSON.parse($stateParams.coreClient).v : null
                        };
                    },
                    jobsCollection: function(apiClient, user, jobParams) {
                        return apiClient.get(user.getLink('jobs'), jobParams).then(function(response) {
                            return response || apiClient.noResourceFound();
                        });
                    }
                },
            })
            .state('dashboard.user.reports.list.view.sp-summary.ppm-completion.missed', {
                url: '/missed?modalPage',
                parent: 'dashboard.user.reports.list.view.sp-summary.ppm-completion',
                params: {
                    modalPage: '1'
                },
                views: {
                    'missed@': {
                        templateUrl: '/modules/user/reports/common/modals/sp-op/ppm-completion/ppm-completion.html',
                        controller: 'UserReportsPpmMissedCompletionController',
                        controllerAs: 'vm',
                    }
                },
                ncyBreadcrumb: {
                    skip: true
                },
                resolve: {
                    jobParams: function(serviceProviderResponse, $stateParams, plannedMissedStatuses) {
                        return {
                            type: "planned",
                            serviceProvider: serviceProviderResponse.id,
                            overdueCompletionHistoric: true,
                            dateRange: $stateParams.dateRange,
                            'status[]': plannedMissedStatuses,
                            page: $stateParams.modalPage,
                            siteName: $stateParams.siteName,
                            siteRegionName: $stateParams.siteRegionName,
                            siteGroupName: $stateParams.siteGroupName,
                            site: $stateParams.site ? JSON.parse($stateParams.site).v : null,
                            location: $stateParams.location ? JSON.parse($stateParams.location).v : null,
                            coreClient: $stateParams.coreClient ? JSON.parse($stateParams.coreClient).v : null
                        };
                    },
                    jobsCollection: function(apiClient, user, jobParams) {
                        return apiClient.get(user.getLink('jobs'), jobParams).then(function(response) {
                            return response || apiClient.noResourceFound();
                        });
                    }
                },
            })
            .state('dashboard.user.reports.list.view.sp-summary.quotes-missed', {
                url: '/quotes-missed',
                parent: 'dashboard.user.reports.list.view.sp-summary.details',
                abstract: true,
                ncyBreadcrumb: {
                    skip: true
                },
                onEnter: ['$uibModal', '$state', '$stateParams', function ($uibModal, $state, $stateParams) {
                    $uibModal.open({
                        templateUrl: '/modules/user/reports/common/modals/sp-op/quotes-missed/base.html',
                        size: 'xl',
                        controller: ['$state', function($state) {
                            var vm = this;
                            vm.selectedTab = $state.current.name.split('.')[$state.current.name.split('.').length - 1];
                            vm.parentState = $state.$current.parent.parent.self.parent;
                        }],
                        controllerAs: 'vm'
                    }).result.finally(function() {
                        $state.go('^.^.^');
                    });
                }],
                onExit: ['$uibModalStack', function($uibModalStack) {
                    dismissModalOnBack($uibModalStack);
                }]
            })
            .state('dashboard.user.reports.list.view.sp-summary.quotes-missed.completed', {
                url: '/completed?modalPage',
                parent: 'dashboard.user.reports.list.view.sp-summary.quotes-missed',
                params: {
                    modalPage: '1'
                },
                views: {
                    'completed@': {
                        templateUrl: '/modules/user/reports/common/modals/sp-op/quotes-missed/quotes-missed.html',
                        controller: 'UserReportsQuotesMissedController',
                        controllerAs: 'vm'
                    }
                },
                ncyBreadcrumb: {
                    skip: true
                },
                resolve: {
                    quoteParams: function(serviceProviderResponse, $stateParams) {
                        return {
                            serviceProvider: serviceProviderResponse.id,
                            dateRange: $stateParams.dateRange,
                            overdueCompletionHistoric: false,
                            siteName: $stateParams.siteName,
                            siteRegionName: $stateParams.siteRegionName,
                            siteGroupName: $stateParams.siteGroupName,
                            'excludedStatuses[]' : 'cancelled',
                            page: $stateParams.modalPage,
                            site: $stateParams.site ? JSON.parse($stateParams.site).v : null,
                            location: $stateParams.location ? JSON.parse($stateParams.location).v : null,
                            coreClient: $stateParams.coreClient ? JSON.parse($stateParams.coreClient).v : null
                        };
                    },
                    quotesCollection: function(apiClient, user, quoteParams) {
                        return apiClient.get(user.getLink('quotes'), quoteParams).then(function(response) {
                            return response || apiClient.noResourceFound();
                        });
                    }
                }
            })
            .state('dashboard.user.reports.list.view.sp-summary.quotes-missed.missed', {
                url: '/missed?modalPage',
                parent: 'dashboard.user.reports.list.view.sp-summary.quotes-missed',
                params: {
                    modalPage: '1'
                },
                views: {
                    'missed@': {
                        templateUrl: '/modules/user/reports/common/modals/sp-op/quotes-missed/quotes-missed.html',
                        controller: 'UserReportsQuotesMissedController',
                        controllerAs: 'vm'
                    }
                },
                ncyBreadcrumb: {
                    skip: true
                },
                resolve: {
                    quoteParams: function(serviceProviderResponse, $stateParams) {
                        return {
                            serviceProvider: serviceProviderResponse.id,
                            dateRange: $stateParams.dateRange,
                            overdueCompletionHistoric: true,
                            siteName: $stateParams.siteName,
                            siteRegionName: $stateParams.siteRegionName,
                            siteGroupName: $stateParams.siteGroupName,
                            'excludedStatuses[]' : 'cancelled',
                            page: $stateParams.modalPage,
                            site: $stateParams.site ? JSON.parse($stateParams.site).v : null,
                            location: $stateParams.location ? JSON.parse($stateParams.location).v : null,
                            coreClient: $stateParams.coreClient ? JSON.parse($stateParams.coreClient).v : null
                        };
                    },
                    quotesCollection: function(apiClient, user, quoteParams) {
                        return apiClient.get(user.getLink('quotes'), quoteParams).then(function(response) {
                            return response || apiClient.noResourceFound();
                        });
                    }
                }
            })
            .state('dashboard.user.reports.list.view.sp-summary.escalated', {
                url: '/escalated?escalationJobsPage&escalationJobsLimit&escalationQuotesPage&escalationQuotesLimit',
                parent: 'dashboard.user.reports.list.view.sp-summary.details',
                params: {
                    escalationJobsPage: '1',
                    escalationJobsLimit: '30',
                    escalationQuotesPage: '1',
                    escalationQuotesLimit: '30'
                },
                abstract: true,
                ncyBreadcrumb: {
                    skip: true
                },
                onEnter: ['$uibModal', '$state', '$stateParams', function ($uibModal, $state, $stateParams) {
                    $uibModal.open({
                        templateUrl: '/modules/user/reports/common/modals/sp-op/tabbed-base.html',
                        size: 'xl',
                        controller: ['$state', function($state) {
                            var vm = this;
                            vm.selectedTab = $state.current.name.split('.')[$state.current.name.split('.').length - 1];
                            vm.parentState = $state.$current.parent.parent.self.parent;
                            vm.tabs = [
                                {name:'Attend',view:'attend'},
                                {name:'Complete',view:'complete'}
                            ];
                        }],
                        controllerAs: 'vm'
                    }).result.finally(function() {
                        $state.go('^.^.^');
                    });
                }],
                onExit: ['$uibModalStack', function($uibModalStack) {
                    dismissModalOnBack($uibModalStack);
                }]
            })
            .state('dashboard.user.reports.list.view.sp-summary.escalated.jobs', {
                url: '/jobs?modalPage',
                parent: 'dashboard.user.reports.list.view.sp-summary.escalated',
                params: {
                    modalPage: '1'
                },
                views: {
                    'jobs@': {
                        templateUrl: '/modules/user/reports/common/modals/sp-op/escalated/jobs.html',
                        controller: 'UserReportsEscalatedJobsController',
                        controllerAs: 'vm'
                    }
                },
                ncyBreadcrumb: {
                    skip: true
                },
                resolve: {
                    jobParams: function(serviceProviderResponse, $stateParams) {
                        return {
                            page: $stateParams.escalationJobsPage,
                            limit: $stateParams.escalationJobsLimit,
                            datagroup: 'reports',
                            allEscalated: 1,
                            dateRange: $stateParams.dateRange,
                            serviceProvider: serviceProviderResponse.id,
                            siteName: $stateParams.siteName,
                            siteRegionName: $stateParams.siteRegionName,
                            siteGroupName: $stateParams.siteGroupName,
                            site: $stateParams.site ? JSON.parse($stateParams.site).v : null,
                            location: $stateParams.location ? JSON.parse($stateParams.location).v : null,
                            coreClient: $stateParams.coreClient ? JSON.parse($stateParams.coreClient).v : null
                        };
                    },
                    jobsCollection: function(apiClient, jobParams, user) {
                        return apiClient.get(user.getLink('jobs'), jobParams).then(function(response) {
                            return response || apiClient.noResourceFound();
                        });
                    }
                }
            })
            .state('dashboard.user.reports.list.view.sp-summary.escalated.quotes', {
                url: '/quotes?modalPage',
                parent: 'dashboard.user.reports.list.view.sp-summary.escalated',
                params: {
                    modalPage: '1'
                },
                views: {
                    'quotes@': {
                        templateUrl: '/modules/user/reports/common/modals/sp-op/escalated/quotes.html',
                        controller: 'UserReportsEscalatedQuotesController',
                        controllerAs: 'vm'
                    }
                },
                ncyBreadcrumb: {
                    skip: true
                },
                resolve: {
                    quoteParams: function(serviceProviderResponse, $stateParams) {
                        return {
                            page: $stateParams.escalationQuotesPage,
                            limit: $stateParams.escalationQuotesLimit,
                            datagroup: 'reports',
                            allEscalated: 1,
                            dateRange: $stateParams.dateRange,
                            serviceProvider: serviceProviderResponse.id,
                            siteName: $stateParams.siteName,
                            siteRegionName: $stateParams.siteRegionName,
                            siteGroupName: $stateParams.siteGroupName,
                            site: $stateParams.site ? JSON.parse($stateParams.site).v : null,
                            location: $stateParams.location ? JSON.parse($stateParams.location).v : null,
                            coreClient: $stateParams.coreClient ? JSON.parse($stateParams.coreClient).v : null
                        };
                    },
                    quotesCollection: function(apiClient, quoteParams, user) {
                        return apiClient.get(user.getLink('quotes'), quoteParams).then(function(response) {
                            return response || apiClient.noResourceFound();
                        });
                    }
                }
            })
            .state('dashboard.user.reports.list.view.sp-summary.extension-requests', {
                url: '/extension-requests',
                parent: 'dashboard.user.reports.list.view.sp-summary.details',
                abstract: true,
                ncyBreadcrumb: {
                    skip: true
                },
                onEnter: ['$uibModal', '$state', '$stateParams', function ($uibModal, $state, $stateParams) {
                    $uibModal.open({
                        templateUrl: '/modules/user/reports/common/modals/sp-op/tabbed-base.html',
                        size: 'xl',
                        controller: ['$state', function($state) {
                            var vm = this;
                            vm.selectedTab = $state.current.name.split('.')[$state.current.name.split('.').length - 1];
                            vm.parentState = $state.$current.parent.parent.self.parent;
                            vm.tabs = [
                                {name:'Jobs',view:'jobs'},
                                {name:'Quotes',view:'quotes'}
                            ];
                        }],
                        controllerAs: 'vm'
                    }).result.finally(function() {
                        $state.go('dashboard.user.reports.list.view.sp-summary');
                    });
                }],
                onExit: ['$uibModalStack', function($uibModalStack) {
                    dismissModalOnBack($uibModalStack);
                }],
            })
            .state('dashboard.user.reports.list.view.sp-summary.extension-requests.jobs', {
                url: '/jobs?modalPage&modalLimit&priority',
                parent: 'dashboard.user.reports.list.view.sp-summary.extension-requests',
                params: {
                    modalPage: '1',
                    modalLimit: '9999',
                    priority: null
                },
                views: {
                    'jobs@': {
                        templateUrl: '/modules/user/reports/common/modals/sp-op/extension-requests/priorities.html',
                        controller: 'UserReportsExtensionRequestsPrioritiesController',
                        controllerAs: 'vm',
                    }
                },
                ncyBreadcrumb: {
                    skip: true
                },
                resolve: {
                    prioritiesCollection: function(apiClient, $stateParams, serviceProviderResponse, serviceProvidersCollection) {
                        return apiClient.get(serviceProvidersCollection.getLink('priorities-by-sp'), {
                            datagroup: 'reports',
                            dateRange: $stateParams.dateRange,
                            serviceProvider: serviceProviderResponse.id,
                            siteGroupName: $stateParams.siteGroupName,
                            siteRegionName: $stateParams.siteRegionName,
                            entityType: 'jobs',
                            page: $stateParams.modalPage,
                            limit: $stateParams.modalLimit,
                            site: $stateParams.site ? JSON.parse($stateParams.site).v : null,
                            location: $stateParams.location ? JSON.parse($stateParams.location).v : null,
                            coreClient: $stateParams.coreClient ? JSON.parse($stateParams.coreClient).v : null
                        });
                    }
                }
            })
            .state('dashboard.user.reports.list.view.sp-summary.extension-requests.jobs.details', {
                url: '/{priorityResource}/{status}/details?entityModalPage&entityModalLimit',
                parent: 'dashboard.user.reports.list.view.sp-summary.extension-requests.jobs',
                params: {
                    entityModalPage: '1',
                    entityModalLimit: '10'
                },
                views: {
                    'details@': {
                        templateUrl: '/modules/user/reports/common/modals/sp-op/extension-requests/jobs.html',
                        controller: 'UserReportsExtensionRequestsJobsController',
                        controllerAs: 'vm',
                    }
                },
                ncyBreadcrumb: {
                    skip: true
                },
                resolve: {
                    jobParams: function(serviceProviderResponse, $stateParams) {
                        return {
                            page: $stateParams.entityModalPage,
                            limit: $stateParams.entityModalLimit,
                            datagroup: 'reports',
                            extensionRequests: $stateParams.status,
                            dateRange: $stateParams.dateRange,
                            serviceProvider: serviceProviderResponse.id,
                            siteName: $stateParams.siteName,
                            siteRegionName: $stateParams.siteRegionName,
                            siteGroupName: $stateParams.siteGroupName,
                            priority: $stateParams.priority,
                            'excludedStatuses[]': 'cancelled',
                            site: $stateParams.site ? JSON.parse($stateParams.site).v : null,
                            location: $stateParams.location ? JSON.parse($stateParams.location).v : null,
                            coreClient: $stateParams.coreClient ? JSON.parse($stateParams.coreClient).v : null
                        };
                    },
                    jobsCollection: function(apiClient, jobParams, user) {
                        return apiClient.get(user.getLink('jobs'), jobParams).then(function(response) {
                            return response || apiClient.noResourceFound();
                        });
                    }
                }
            })
            .state('dashboard.user.reports.list.view.sp-summary.extension-requests.jobs.total', {
                url: '/{priorityResource}/total?entityModalPage&entityModalLimit',
                parent: 'dashboard.user.reports.list.view.sp-summary.extension-requests.jobs',
                params: {
                    entityModalPage: '1',
                    entityModalLimit: '10'
                },
                views: {
                    'details@': {
                        templateUrl: '/modules/user/reports/common/modals/sp-op/extension-requests/jobs-total.html',
                        controller: 'UserReportsExtensionRequestsJobsController',
                        controllerAs: 'vm',
                    }
                },
                ncyBreadcrumb: {
                    skip: true
                },
                resolve: {
                    jobParams: function(serviceProviderResponse, $stateParams) {
                        return {
                            page: $stateParams.entityModalPage,
                            limit: $stateParams.entityModalLimit,
                            datagroup: 'reports',
                            extensionRequests: 'total',
                            dateRange: $stateParams.dateRange,
                            serviceProvider: serviceProviderResponse.id,
                            siteName: $stateParams.siteName,
                            siteRegionName: $stateParams.siteRegionName,
                            siteGroupName: $stateParams.siteGroupName,
                            priority: $stateParams.priority,
                            'excludedStatuses[]': 'cancelled',
                            site: $stateParams.site ? JSON.parse($stateParams.site).v : null,
                            location: $stateParams.location ? JSON.parse($stateParams.location).v : null,
                            coreClient: $stateParams.coreClient ? JSON.parse($stateParams.coreClient).v : null
                        };
                    },
                    jobsCollection: function(apiClient, jobParams, user) {
                        return apiClient.get(user.getLink('jobs'), jobParams).then(function(response) {
                            return response || apiClient.noResourceFound();
                        });
                    }
                }
            })
            .state('dashboard.user.reports.list.view.sp-summary.extension-requests.quotes', {
                url: '/quotes?modalPage&modalLimit&priority',
                parent: 'dashboard.user.reports.list.view.sp-summary.extension-requests',
                params: {
                    modalPage: '1',
                    modalLimit: '9999',
                    priority: null
                },
                ncyBreadcrumb: {
                    skip: true
                },
                views: {
                    'quotes@': {
                        templateUrl: '/modules/user/reports/common/modals/sp-op/extension-requests/priorities.html',
                        controller: 'UserReportsExtensionRequestsPrioritiesController',
                        controllerAs: 'vm',
                    }
                },
                resolve: {
                    prioritiesCollection: function(apiClient, $stateParams, serviceProviderResponse, serviceProvidersCollection) {
                        return apiClient.get(serviceProvidersCollection.getLink('priorities-by-sp'), {
                            datagroup: 'reports',
                            dateRange: $stateParams.dateRange,
                            serviceProvider: serviceProviderResponse.id,
                            entityType: 'quotes',
                            limit: $stateParams.modalLimit,
                            coreClient: $stateParams.coreClient ? JSON.parse($stateParams.coreClient).v : null
                        });
                    }
                }
            })
            .state('dashboard.user.reports.list.view.sp-summary.extension-requests.quotes.details', {
                url: '/{priorityResource}/{status}/details?entityModalPage&quotsModalLimit',
                parent: 'dashboard.user.reports.list.view.sp-summary.extension-requests.quotes',
                params: {
                    entityModalPage: '1',
                    entityModalLimit: '10'
                },
                views: {
                    'details@': {
                        templateUrl: '/modules/user/reports/common/modals/sp-op/extension-requests/quotes.html',
                        controller: 'UserReportsExtensionRequestsQuotesController',
                        controllerAs: 'vm',
                    }
                },
                ncyBreadcrumb: {
                    skip: true
                },
                resolve: {
                    quoteParams: function(serviceProviderResponse, $stateParams) {
                        return {
                            datagroup: 'reports',
                            serviceProvider: serviceProviderResponse.id,
                            dateRange: $stateParams.dateRange,
                            extensionRequests: $stateParams.status,
                            siteName: $stateParams.siteName,
                            siteRegionName: $stateParams.siteRegionName,
                            siteGroupName: $stateParams.siteGroupName,
                            priority: $stateParams.priority,
                            page: $stateParams.entityModalPage,
                            limit: $stateParams.entityModalLimit,
                            'excludedStatuses[]': 'cancelled',
                            site: $stateParams.site ? JSON.parse($stateParams.site).v : null,
                            location: $stateParams.location ? JSON.parse($stateParams.location).v : null,
                            coreClient: $stateParams.coreClient ? JSON.parse($stateParams.coreClient).v : null
                        };
                    },
                    quotesCollection: function(apiClient, user, quoteParams) {
                        return apiClient.get(user.getLink('quotes'), quoteParams).then(function(response) {
                            return response || apiClient.noResourceFound();
                        });
                    }
                }
            })
            .state('dashboard.user.reports.list.view.sp-summary.extension-requests.quotes.total', {
                url: '/{priorityResource}/{status}/total?entityModalPage&entityModalLimit',
                parent: 'dashboard.user.reports.list.view.sp-summary.extension-requests.quotes',
                params: {
                    entityModalPage: '1',
                    entityModalLimit: '10'
                },
                views: {
                    'details@': {
                        templateUrl: '/modules/user/reports/common/modals/sp-op/extension-requests/quotes-total.html',
                        controller: 'UserReportsExtensionRequestsQuotesController',
                        controllerAs: 'vm'
                    }
                },
                ncyBreadcrumb: {
                    skip: true
                },
                resolve: {
                    quoteParams: function(serviceProviderResponse, $stateParams) {
                        return {
                            datagroup: 'reports',
                            serviceProvider: serviceProviderResponse.id,
                            dateRange: $stateParams.dateRange,
                            extensionRequests: 'total',
                            siteName: $stateParams.siteName,
                            siteRegionName: $stateParams.siteRegionName,
                            siteGroupName: $stateParams.siteGroupName,
                            priority: $stateParams.priority,
                            page: $stateParams.entityModalPage,
                            limit: $stateParams.entityModalLimit,
                            'excludedStatuses[]': 'cancelled',
                            site: $stateParams.site ? JSON.parse($stateParams.site).v : null,
                            location: $stateParams.location ? JSON.parse($stateParams.location).v : null,
                            coreClient: $stateParams.coreClient ? JSON.parse($stateParams.coreClient).v : null
                        };
                    },
                    quotesCollection: function(apiClient, user, quoteParams) {
                        return apiClient.get(user.getLink('quotes'), quoteParams).then(function(response) {
                            return response || apiClient.noResourceFound();
                        });
                    }
                }
            })
            .state('dashboard.user.reports.list.view.sp-summary.reopened-jobs', {
                url: '/reopened-jobs',
                parent: 'dashboard.user.reports.list.view.sp-summary.details',
                abstract: true,
                ncyBreadcrumb: {
                    skip: true
                },
                onEnter: parentModal,
                onExit: ['$uibModalStack', function($uibModalStack) {
                    dismissModalOnBack($uibModalStack);
                }]
            })
            .state('dashboard.user.reports.list.view.sp-summary.reopened-jobs.modal', {
                url: '/view?modalPage',
                parent: 'dashboard.user.reports.list.view.sp-summary.reopened-jobs',
                params: {
                    modalPage: '1'
                },
                ncyBreadcrumb: {
                    skip: true
                },
                views: {
                    'report-modal@': {
                        templateUrl: '/modules/user/reports/common/modals/sp-op/jobs-reopened.html',
                        controller: 'UserReportsJobsReopenedController',
                        controllerAs: 'vm',
                    }
                },
                resolve: {
                    jobParams: function(serviceProviderResponse, $stateParams) {
                        return {
                            page: $stateParams.modalPage,
                            limit: $stateParams.modalLimit,
                            datagroup: 'reports',
                            dateRange: $stateParams.dateRange,
                            serviceProvider: serviceProviderResponse.id,
                            siteName: $stateParams.siteName,
                            siteRegionName: $stateParams.siteRegionName,
                            siteGroupName: $stateParams.siteGroupName,
                            reopened: 'true',
                            site: $stateParams.site ? JSON.parse($stateParams.site).v : null,
                            location: $stateParams.location ? JSON.parse($stateParams.location).v : null,
                            coreClient: $stateParams.coreClient ? JSON.parse($stateParams.coreClient).v : null
                        };
                    },
                    jobsCollection: function(apiClient, jobParams, user) {
                        return apiClient.get(user.getLink('jobs'), jobParams).then(function(response) {
                            return response || apiClient.noResourceFound();
                        });
                    }
               },
            })
            .state('dashboard.user.reports.list.view.sp-summary.paperwork-awaiting', {
                url: '/paperwork-awaiting',
                parent: 'dashboard.user.reports.list.view.sp-summary.details',
                abstract: true,
                ncyBreadcrumb: {
                    skip: true
                },
                onEnter: parentModal,
                onExit: ['$uibModalStack', function($uibModalStack) {
                    dismissModalOnBack($uibModalStack);
                }]
            })
            .state('dashboard.user.reports.list.view.sp-summary.paperwork-awaiting.modal', {
                url: '/view?modalPage',
                parent: 'dashboard.user.reports.list.view.sp-summary.paperwork-awaiting',
                params: {
                    modalPage: '1'
                },
                ncyBreadcrumb: {
                    skip: true
                },
                views: {
                    'report-modal@': {
                        templateUrl: '/modules/user/reports/common/modals/sp-op/paperwork-awaiting.html',
                        controller: 'UserReportsPaperworkAwaitingController',
                        controllerAs: 'vm',
                    }
                },
                resolve: {
                    jobParams: function(serviceProviderResponse, $stateParams) {
                        return {
                            page: $stateParams.modalPage,
                            limit: $stateParams.modalLimit,
                            datagroup: 'reports',
                            dateRange: $stateParams.dateRange,
                            serviceProvider: serviceProviderResponse.id,
                            siteName: $stateParams.siteName,
                            siteRegionName: $stateParams.siteRegionName,
                            siteGroupName: $stateParams.siteGroupName,
                            awaitingPaperwork: 1,
                            hours: $stateParams.hours,
                            site: $stateParams.site ? JSON.parse($stateParams.site).v : null,
                            location: $stateParams.location ? JSON.parse($stateParams.location).v : null,
                            coreClient: $stateParams.coreClient ? JSON.parse($stateParams.coreClient).v : null
                        };
                    },
                    jobsCollection: function(apiClient, jobParams, user) {
                        return apiClient.get(user.getLink('jobs'), jobParams).then(function(response) {
                            return response || apiClient.noResourceFound();
                        });
                    }
                },
            })
    }

    function registerSiteAssociateActivityReportRoutes($stateProvider) {
        $stateProvider
            .state('dashboard.user.reports.list.view.site-associate-activity', {
                url: '/site-associate-activity?dateRange',
                parent: 'dashboard.user.reports.list.view',
                views: parseBook({
                    'elogbooks-right@dashboard.user.reports': {
                        templateUrl: '/modules/user/reports/site-associate-activity/site-associate-activity-details.html',
                        controller: 'UserReportsSiteAssociateActivityDetailsController',
                        controllerAs: 'vm'
                    }
                }),
                params: {
                    dateRange: dateRangeMinus1Day
                },
                resolve: {
                    serviceProviderResponse: function() {
                        return null;
                    },
                    siteAssociatesCollection: function(apiClient, $stateParams) {
                        var params = angular.copy($stateParams);

                        if (params.coreClient) {
                            params.coreClient = JSON.parse(params.coreClient).v
                        }

                        return apiClient.get('/reports/site-associate-activity', params).then(function(response) {
                            return response || apiClient.noResourceFound();
                        });
                    },
                    siteAssociatesApprovalsCollection: function(apiClient, $stateParams) {
                        var params = angular.copy($stateParams);

                        if (params.coreClient) {
                            params.coreClient = JSON.parse(params.coreClient).v
                        }

                        return apiClient.get('/reports/site-associate-activity-approval', params).then(function(response) {
                            return response || apiClient.noResourceFound();
                        });
                    },
                    siteAssociatesCollectionFMResponse: function(apiClient, user) {
                        return apiClient.get(user.getLink('site-associates'),{'siteAssociateType':2, 'order':'name'}).then(function (response) {
                            return response || apiClient.noResourceFound();
                        });
                    },
                    siteAssociatesCollectionRFMResponse: function(apiClient, user) {
                        return apiClient.get(user.getLink('site-associates'),{'siteAssociateType':1, 'order':'name'}).then(function (response) {
                            return response || apiClient.noResourceFound();
                        });
                    },
                    regions: function(apiClient, rootResourceResponse) {
                        return apiClient.get(rootResourceResponse.getLink('regions')).then(function (response) {
                            return response || apiClient.noResourceFound();
                        });
                    },
                    coreClientsCollectionResponse: function (apiClient, rootResourceResponse, $stateParams) {
                        if (rootResourceResponse.getLink('coreclients')) {
                            return apiClient.get(rootResourceResponse.getLink('coreclients')).then( function(response) {
                                return response || {};
                            });
                        } else {
                            return {};
                        }
                    },
                    operativeResponse: function() {
                        return null;
                    }
                },
                ncyBreadcrumb: {
                    label: '{{ vm.siteAssociateAlias }} {{ "ACTIVITY_REPORT" | translate }}'
                }
            })
            .state('dashboard.user.reports.list.view.site-associate-activity.details', {
                url: '/{siteAssociateResource}',
                abstract: true,
                parent: 'dashboard.user.reports.list.view.site-associate-activity',
                resolve: {
                    siteAssociateResponse: function($stateParams, apiClient) {
                        return apiClient.get($stateParams.siteAssociateResource.decode()).then(function(response) {
                            return response || apiClient.noResourceFound();
                        });
                    },
                    reactiveMissedStatuses: function() {
                        return ['acknowledged', 'commenced', 'awaiting_paperwork', 'extension_pending', 'completed'];
                    },
                    plannedMissedStatuses: function() {
                        return ['acknowledged', 'commenced', 'missed', 'extension_pending', 'completed'];
                    },
                    overduePlannedMissedStatuses: function() {
                        return ['acknowledged', 'commenced', 'missed', 'awaiting_paperwork', 'completed'];
                    }
                },
                ncyBreadcrumb: {
                    skip: true
                },
            })
            .state('dashboard.user.reports.list.view.site-associate-activity.sites-ppm-approval', {
                url: '/sites-ppm-approval',
                parent: 'dashboard.user.reports.list.view.site-associate-activity.details',
                abstract: true,
                ncyBreadcrumb: {
                    skip: true
                },
                onEnter: siteAssociateParentModal,
                onExit: ['$uibModalStack', function($uibModalStack) {
                    dismissModalOnBack($uibModalStack);
                }]
            })
            .state('dashboard.user.reports.list.view.site-associate-activity.sites-ppm-approval.view', {
                url: '/view?modalPage&modalLimit',
                parent: 'dashboard.user.reports.list.view.site-associate-activity.sites-ppm-approval',
                params: {
                    modalPage: '1',
                    modalLimit: '30'
                },
                views: {
                    'report-modal@': {
                        templateUrl: '/modules/user/reports/site-associate-activity/modals/sites-ppm-approval.html',
                        controller: 'UserReportsSiteAssociateSitesController',
                        controllerAs: 'vm',
                    }
                },
                ncyBreadcrumb: {
                    skip: true
                },
                resolve: {
                    sitesCollection: function(apiClient, user, $stateParams, siteAssociateResponse) {
                        var params = angular.copy($stateParams);
                        params.page = $stateParams.modalPage;
                        params.limit = $stateParams.modalLimit;
                        params.ppmPendingApproval = true;
                        params.datagroup = 'reports';
                        params.fmUser = siteAssociateResponse.getLinkAttribute('user', 'id');
                        params['siteRegions[]'] = $stateParams.siteRegion;
                        params.coreClient = $stateParams.coreClient ? JSON.parse($stateParams.coreClient).v : null;

                        return apiClient.get(user.getLink('sites'), params).then(function(response) {
                            return response || apiClient.noResourceFound();
                        });
                    }
                }
            })
            .state('dashboard.user.reports.list.view.site-associate-activity.overdue-planned-attend', {
                url: '/overdue-planned-attend',
                parent: 'dashboard.user.reports.list.view.site-associate-activity.details',
                abstract: true,
                ncyBreadcrumb: {
                    skip: true
                },
                onEnter: ['$uibModal', '$state', '$stateParams', function ($uibModal, $state, $stateParams) {
                    $uibModal.open({
                        templateUrl: '/modules/user/reports/site-associate-activity/modals/tabbed-base.html',
                        size: 'xl',
                        controller: ['$state', function($state) {
                            var vm = this;
                            vm.selectedTab = $state.current.name.split('.')[$state.current.name.split('.').length - 1];
                            vm.tabs = [
                                {name:'Attend Missed',view:'attend-missed'},
                                {name:'Attend',view:'attend'}
                            ];
                        }],
                        controllerAs: 'vm'
                    }).result.finally(function() {
                        $state.go('dashboard.user.reports.list.view.site-associate-activity');
                    });
                }],
                onExit: ['$uibModalStack', function($uibModalStack) {
                    dismissModalOnBack($uibModalStack);
                }]
            })
            .state('dashboard.user.reports.list.view.site-associate-activity.overdue-planned-attend.attend-missed', {
                url: '/attend-missed?modalPage&modalLimit',
                parent: 'dashboard.user.reports.list.view.site-associate-activity.overdue-planned-attend',
                params: {
                    modalPage: '1',
                    modalLimit: '30'
                },
                views: {
                    'attend-missed@': {
                        templateUrl: '/modules/user/reports/site-associate-activity/modals/overdue-planned/overdue-planned-attend.html',
                        controller: 'UserReportsSiteAssociateOverdueAttendancePlannedController',
                        controllerAs: 'vm',
                    }
                },
                ncyBreadcrumb: {
                    skip: true
                },
                resolve: {
                    jobParams: function(siteAssociateResponse, $stateParams, overduePlannedMissedStatuses) {
                        return {
                            type: "planned",
                            fmUser: siteAssociateResponse.getLinkAttribute('user', 'id'),
                            overdueAttendanceHistoric: true,
                            page: $stateParams.modalPage,
                            dateRange: $stateParams.dateRange,
                            'status[]': overduePlannedMissedStatuses,
                            siteName: $stateParams.siteName,
                            siteRegionName: $stateParams.siteRegionName,
                            coreClient: $stateParams.coreClient ? JSON.parse($stateParams.coreClient).v : null
                        };
                    },
                    jobsCollection: function (apiClient, user, $stateParams, jobParams) {
                        return apiClient.get(user.getLink('jobs'), jobParams).then(function (response) {
                            return response || apiClient.noResourceFound();
                        });
                    }
                }
            })
            .state('dashboard.user.reports.list.view.site-associate-activity.overdue-planned-attend.attend', {
                url: '/attend?modalPage&modalLimit',
                parent: 'dashboard.user.reports.list.view.site-associate-activity.overdue-planned-attend',
                params: {
                    modalPage: '1',
                    modalLimit: '30'
                },
                views: {
                    'attend@': {
                        templateUrl: '/modules/user/reports/site-associate-activity/modals/overdue-planned/overdue-planned-attend.html',
                        controller: 'UserReportsSiteAssociateOverdueAttendancePlannedController',
                        controllerAs: 'vm',
                    }
                },
                ncyBreadcrumb: {
                    skip: true
                },
                resolve: {
                    jobParams: function(siteAssociateResponse, $stateParams, overduePlannedMissedStatuses) {
                        return {
                            type: "planned",
                            fmUser: siteAssociateResponse.getLinkAttribute('user', 'id'),
                            overdueAttendanceHistoric: false,
                            dateRange: $stateParams.dateRange,
                            page: $stateParams.modalPage,
                            'status[]': overduePlannedMissedStatuses,
                            siteName: $stateParams.siteName,
                            siteRegionName: $stateParams.siteRegionName,
                            coreClient: $stateParams.coreClient ? JSON.parse($stateParams.coreClient).v : null
                        };
                    },
                    jobsCollection: function (apiClient, user, $stateParams, jobParams) {
                        return apiClient.get(user.getLink('jobs'), jobParams).then(function (response) {
                            return response || apiClient.noResourceFound();
                        });
                    }
                }
            })
            .state('dashboard.user.reports.list.view.site-associate-activity.overdue-planned-complete', {
                url: '/overdue-planned-complete',
                parent: 'dashboard.user.reports.list.view.site-associate-activity.details',
                abstract: true,
                ncyBreadcrumb: {
                    skip: true
                },
                onEnter: ['$uibModal', '$state', '$stateParams', function ($uibModal, $state, $stateParams) {
                    $uibModal.open({
                        templateUrl: '/modules/user/reports/site-associate-activity/modals/tabbed-base.html',
                        size: 'xl',
                        controller: ['$state', function($state) {
                            var vm = this;
                            vm.selectedTab = $state.current.name.split('.')[$state.current.name.split('.').length - 1];
                            vm.tabs = [
                                {name:'Complete Missed',view:'complete-missed'},
                                {name:'Complete',view:'complete'}
                            ];
                        }],
                        controllerAs: 'vm'
                    }).result.finally(function() {
                        $state.go('dashboard.user.reports.list.view.site-associate-activity');
                    });
                }],
                onExit: ['$uibModalStack', function($uibModalStack) {
                    dismissModalOnBack($uibModalStack);
                }]
            })
            .state('dashboard.user.reports.list.view.site-associate-activity.overdue-planned-complete.complete-missed', {
                url: '/complete-missed?modalPage&modalLimit',
                parent: 'dashboard.user.reports.list.view.site-associate-activity.overdue-planned-complete',
                params: {
                    modalPage: '1',
                    modalLimit: '30'
                },
                views: {
                    'complete-missed@': {
                        templateUrl: '/modules/user/reports/site-associate-activity/modals/overdue-planned/overdue-planned-complete.html',
                        controller: 'UserReportsSiteAssociateOverdueAttendancePlannedController',
                        controllerAs: 'vm',
                    }
                },
                ncyBreadcrumb: {
                    skip: true
                },
                resolve: {
                    jobParams: function(siteAssociateResponse, $stateParams, overduePlannedMissedStatuses) {
                        return {
                            type: "planned",
                            fmUser: siteAssociateResponse.getLinkAttribute('user', 'id'),
                            overdueCompletionHistoric: true,
                            page: $stateParams.modalPage,
                            dateRange: $stateParams.dateRange,
                            'status[]': overduePlannedMissedStatuses,
                            siteName: $stateParams.siteName,
                            siteRegionName: $stateParams.siteRegionName,
                            coreClient: $stateParams.coreClient ? JSON.parse($stateParams.coreClient).v : null
                        };
                    },
                    jobsCollection: function (apiClient, user, $stateParams, jobParams) {
                        return apiClient.get(user.getLink('jobs'), jobParams).then(function (response) {
                            return response || apiClient.noResourceFound();
                        });
                    }
                }
            })
            .state('dashboard.user.reports.list.view.site-associate-activity.overdue-planned-complete.complete', {
                url: '/complete?modalPage&modalLimit',
                parent: 'dashboard.user.reports.list.view.site-associate-activity.overdue-planned-complete',
                params: {
                    modalPage: '1',
                    modalLimit: '30'
                },
                views: {
                    'complete@': {
                        templateUrl: '/modules/user/reports/site-associate-activity/modals/overdue-planned/overdue-planned-complete.html',
                        controller: 'UserReportsSiteAssociateOverdueAttendancePlannedController',
                        controllerAs: 'vm',
                    }
                },
                ncyBreadcrumb: {
                    skip: true
                },
                resolve: {
                    jobParams: function(siteAssociateResponse, $stateParams, overduePlannedMissedStatuses) {
                        return {
                            type: "planned",
                            fmUser: siteAssociateResponse.getLinkAttribute('user', 'id'),
                            overdueCompletionHistoric: false,
                            dateRange: $stateParams.dateRange,
                            page: $stateParams.modalPage,
                            'status[]': overduePlannedMissedStatuses,
                            siteName: $stateParams.siteName,
                            siteRegionName: $stateParams.siteRegionName,
                            coreClient: $stateParams.coreClient ? JSON.parse($stateParams.coreClient).v : null
                        };
                    },
                    jobsCollection: function (apiClient, user, $stateParams, jobParams) {
                        return apiClient.get(user.getLink('jobs'), jobParams).then(function (response) {
                            return response || apiClient.noResourceFound();
                        });
                    }
                }
            })
            .state('dashboard.user.reports.list.view.site-associate-activity.sites-kpi-survey', {
                url: '/kpi-surveys',
                parent: 'dashboard.user.reports.list.view.site-associate-activity.details',
                abstract: true,
                ncyBreadcrumb: {
                    skip: true
                },
                onEnter: siteAssociateParentModal,
                onExit: ['$uibModalStack', function($uibModalStack) {
                    dismissModalOnBack($uibModalStack);
                }]
            })
            .state('dashboard.user.reports.list.view.site-associate-activity.sites-kpi-survey.view', {
                url: '/view?modalPage&modalLimit',
                parent: 'dashboard.user.reports.list.view.site-associate-activity.sites-kpi-survey',
                params: {
                    modalPage: '1',
                    modalLimit: '30'
                },
                views: {
                    'report-modal@': {
                        templateUrl: '/modules/user/reports/site-associate-activity/modals/kpi-surveys.html',
                        controller: 'UserReportsSiteAssociateKPISurveysController',
                        controllerAs: 'vm'
                    }
                },
                ncyBreadcrumb: {
                    skip: true
                },
                resolve: {
                    kpiSurveysCollection:  function(apiClient, userManager, user, $stateParams, siteAssociateResponse) {
                        var params = angular.copy($stateParams);
                        params.page = $stateParams.modalPage;
                        params.limit = $stateParams.modalLimit;
                        params.fmUser = siteAssociateResponse.getLinkAttribute('user', 'id');
                        params.siteRegionId = $stateParams.siteRegion;
                        params.coreClient = $stateParams.coreClient ? JSON.parse($stateParams.coreClient).v : null;

                        return apiClient.get(user.getLink('surveyrequests'), params).then(function(response) {
                            return response || apiClient.noResourceFound();
                        });
                    }
                }
            })
            .state('dashboard.user.reports.list.view.site-associate-activity.reactive-attendance', {
                url: '/reactive-attendance',
                parent: 'dashboard.user.reports.list.view.site-associate-activity.details',
                abstract: true,
                ncyBreadcrumb: {
                    skip: true
                },
                onEnter: ['$uibModal', '$state', '$stateParams', function ($uibModal, $state, $stateParams) {
                    $uibModal.open({
                        templateUrl: '/modules/user/reports/common/modals/reactive-attendance/base.html',
                        size: 'xl',
                        controller: ['$state', function($state) {
                            var vm = this;
                            vm.selectedTab = $state.current.name.split('.')[$state.current.name.split('.').length - 1];
                            vm.parentState = $state.$current.parent.parent.self.parent;
                        }],
                        controllerAs: 'vm'
                    }).result.finally(function() {
                        $state.go('^.^.^');
                    });
                }],
                onExit: ['$uibModalStack', function($uibModalStack) {
                    dismissModalOnBack($uibModalStack);
                }]
            })
            .state('dashboard.user.reports.list.view.site-associate-activity.reactive-attendance.attended', {
                url: '/attended?modalPage',
                parent: 'dashboard.user.reports.list.view.site-associate-activity.reactive-attendance',
                params: {
                    modalPage: '1'
                },
                views: {
                    'attended@': {
                        templateUrl: '/modules/user/reports/common/modals/reactive-attendance/reactive-attendance.html',
                        controller: 'ReportsCommonReactiveMissedAttendanceController',
                        controllerAs: 'vm',
                    }
                },
                ncyBreadcrumb: {
                    skip: true
                },
                resolve: {
                    jobParams: function(siteAssociateResponse, $stateParams, reactiveMissedStatuses) {
                        return {
                            type: "reactive",
                            fmUser: siteAssociateResponse.getLinkAttribute('user', 'id'),
                            overdueAttendanceHistoric: false,
                            dateRange: $stateParams.dateRange,
                            page: $stateParams.modalPage,
                            'status[]': reactiveMissedStatuses,
                            siteName: $stateParams.siteName,
                            siteRegionName: $stateParams.siteRegionName,
                            coreClient: $stateParams.coreClient ? JSON.parse($stateParams.coreClient).v : null
                        };
                    },
                    jobsCollection: function(apiClient, user, jobParams) {
                        return apiClient.get(user.getLink('jobs'), jobParams).then(function(response) {
                            return response || apiClient.noResourceFound();
                        });
                    }
                },
            })
            .state('dashboard.user.reports.list.view.site-associate-activity.reactive-attendance.missed', {
                url: '/missed?modalPage',
                parent: 'dashboard.user.reports.list.view.site-associate-activity.reactive-attendance',
                params: {
                    modalPage: '1'
                },
                views: {
                    'missed@': {
                        templateUrl: '/modules/user/reports/common/modals/reactive-attendance/reactive-attendance.html',
                        controller: 'ReportsCommonReactiveMissedAttendanceController',
                        controllerAs: 'vm',
                    }
                },
                ncyBreadcrumb: {
                    skip: true
                },
                resolve: {
                    jobParams: function(siteAssociateResponse, $stateParams, reactiveMissedStatuses) {
                        return {
                            type: "reactive",
                            fmUser: siteAssociateResponse.getLinkAttribute('user', 'id'),
                            overdueAttendanceHistoric: true,
                            dateRange: $stateParams.dateRange,
                            'status[]': reactiveMissedStatuses,
                            siteName: $stateParams.siteName,
                            siteRegionName: $stateParams.siteRegionName,
                            coreClient: $stateParams.coreClient ? JSON.parse($stateParams.coreClient).v : null
                        };
                    },
                    jobsCollection: function(apiClient, user, jobParams) {
                        return apiClient.get(user.getLink('jobs'), jobParams).then(function(response) {
                            return response || apiClient.noResourceFound();
                        });
                    }
                },
            })
            .state('dashboard.user.reports.list.view.site-associate-activity.reactive-completion', {
                url: '/reactive-completion',
                parent: 'dashboard.user.reports.list.view.site-associate-activity.details',
                abstract: true,
                ncyBreadcrumb: {
                    skip: true
                },
                onEnter: ['$uibModal', '$state', '$stateParams', function ($uibModal, $state, $stateParams) {
                    $uibModal.open({
                        templateUrl: '/modules/user/reports/common/modals/reactive-completion/base.html',
                        size: 'xl',
                        controller: ['$state', function($state) {
                            var vm = this;
                            vm.selectedTab = $state.current.name.split('.')[$state.current.name.split('.').length - 1];
                            vm.parentState = $state.$current.parent.parent.self.parent;
                        }],
                        controllerAs: 'vm'
                    }).result.finally(function() {
                        $state.go('^.^.^');
                    });
                }],
                onExit: ['$uibModalStack', function($uibModalStack) {
                    dismissModalOnBack($uibModalStack);
                }]
            })
            .state('dashboard.user.reports.list.view.site-associate-activity.reactive-completion.completed', {
                url: '/completed?modalPage',
                parent: 'dashboard.user.reports.list.view.site-associate-activity.reactive-completion',
                params: {
                    modalPage: '1'
                },
                views: {
                    'completed@': {
                        templateUrl: '/modules/user/reports/common/modals/reactive-completion/reactive-completion.html',
                        controller: 'ReportsCommonReactiveMissedCompletionController',
                        controllerAs: 'vm',
                    }
                },
                ncyBreadcrumb: {
                    skip: true
                },
                resolve: {
                    jobParams: function(siteAssociateResponse, $stateParams, reactiveMissedStatuses) {
                        return {
                            type: "reactive",
                            fmUser: siteAssociateResponse.getLinkAttribute('user', 'id'),
                            overdueCompletionHistoric: false,
                            dateRange: $stateParams.dateRange,
                            'status[]': reactiveMissedStatuses,
                            page: $stateParams.modalPage,
                            siteName: $stateParams.siteName,
                            siteRegionName: $stateParams.siteRegionName,
                            coreClient: $stateParams.coreClient ? JSON.parse($stateParams.coreClient).v : null
                        };
                    },
                    jobsCollection: function(apiClient, user, jobParams) {
                        return apiClient.get(user.getLink('jobs'), jobParams).then(function(response) {
                            return response || apiClient.noResourceFound();
                        });
                    }
                },
            })
            .state('dashboard.user.reports.list.view.site-associate-activity.reactive-completion.missed', {
                url: '/missed?modalPage',
                parent: 'dashboard.user.reports.list.view.site-associate-activity.reactive-completion',
                params: {
                    modalPage: '1'
                },
                views: {
                    'missed@': {
                        templateUrl: '/modules/user/reports/common/modals/reactive-completion/reactive-completion.html',
                        controller: 'ReportsCommonReactiveMissedCompletionController',
                        controllerAs: 'vm',
                    }
                },
                ncyBreadcrumb: {
                    skip: true
                },
                resolve: {
                    jobParams: function(siteAssociateResponse, $stateParams, reactiveMissedStatuses) {
                        return {
                            type: "reactive",
                            fmUser: siteAssociateResponse.getLinkAttribute('user', 'id'),
                            overdueCompletionHistoric: true,
                            dateRange: $stateParams.dateRange,
                            'status[]': reactiveMissedStatuses,
                            page: $stateParams.modalPage,
                            siteName: $stateParams.siteName,
                            siteRegionName: $stateParams.siteRegionName,
                            coreClient: $stateParams.coreClient ? JSON.parse($stateParams.coreClient).v : null
                        };
                    },
                    jobsCollection: function(apiClient, user, jobParams) {
                        return apiClient.get(user.getLink('jobs'), jobParams).then(function(response) {
                            return response || apiClient.noResourceFound();
                        });
                    }
                }
            })
            .state('dashboard.user.reports.list.view.site-associate-activity.extension-requests', {
                url: '/extension-requests',
                parent: 'dashboard.user.reports.list.view.site-associate-activity.details',
                abstract: true,
                onEnter: ['$uibModal', '$state', '$stateParams', function ($uibModal, $state, $stateParams) {
                    $uibModal.open({
                        templateUrl: '/modules/user/reports/site-associate-activity/modals/tabbed-base.html',
                        size: 'xl',
                        controller: ['$state', function($state) {
                            var vm = this;
                            vm.selectedTab = $state.current.name.split('.')[$state.current.name.split('.').length - 1];
                            vm.tabs = [
                                {name:'Jobs',view:'jobs'},
                                {name:'Quotes',view:'quotes'}
                            ];
                        }],
                        controllerAs: 'vm'
                    }).result.finally(function() {
                        $state.go('dashboard.user.reports.list.view.site-associate-activity');
                    });
                }],
                ncyBreadcrumb: {
                    skip: true
                },
                onExit: ['$uibModalStack', function($uibModalStack) {
                    dismissModalOnBack($uibModalStack);
                }],
            })
            .state('dashboard.user.reports.list.view.site-associate-activity.extension-requests.jobs', {
                url: '/jobs?modalPage&modalLimit&priority',
                parent: 'dashboard.user.reports.list.view.site-associate-activity.extension-requests',
                params: {
                    modalPage: '1',
                    modalLimit: '30'
                },
                views: {
                    'jobs@': {
                        templateUrl: '/modules/user/reports/site-associate-activity/modals/extension-requests/service-providers.html',
                        controller: 'UserReportsSiteAssociateExtensionRequestsController',
                        controllerAs: 'vm',
                    }
                },
                ncyBreadcrumb: {
                    skip: true
                },
                resolve: {
                    serviceProviderCollection: function(apiClient, user, $stateParams, siteAssociateResponse) {
                        var params = angular.copy($stateParams);
                        params.page = $stateParams.modalPage;
                        params.limit = $stateParams.modalLimit;
                        params.order = null;
                        params.datagroup = 'reports';
                        params.entityType = 'jobs';
                        params.fmUser = siteAssociateResponse.getLinkAttribute('user', 'id');
                        params.coreClient = $stateParams.coreClient ? JSON.parse($stateParams.coreClient).v : null;

                        return apiClient.get(user.getLink('sa-serviceprovider-report'), params).then(function(response) {
                            return response || apiClient.noResourceFound();
                        });
                    }
                }
            })
            .state('dashboard.user.reports.list.view.site-associate-activity.extension-requests.jobs.details', {
                url: '/{spResource}/{status}/details?entityModalPage&entityModalLimit&serviceProviderId',
                parent: 'dashboard.user.reports.list.view.site-associate-activity.extension-requests.jobs',
                params: {
                    entityModalPage: '1',
                    entityModalLimit: '10'
                },
                views: {
                    'details@': {
                        templateUrl: '/modules/user/reports/site-associate-activity/modals/extension-requests/jobs.html',
                        controller: 'UserReportsSiteAssociateExtensionRequestsJobsController',
                        controllerAs: 'vm',
                    }
                },
                resolve: {
                    jobCollection: function(apiClient, $stateParams, user, siteAssociateResponse) {
                        var params = {
                            page: $stateParams.entityModalPage,
                            limit: $stateParams.entityModalLimit,
                            datagroup: 'reports',
                            extensionRequests: $stateParams.status,
                            dateRange: $stateParams.dateRange,
                            serviceProvider: $stateParams.serviceProviderId,
                            fmUser: siteAssociateResponse.getLinkAttribute('user', 'id'),
                            order: 'id',
                            'excludedStatuses[]': 'cancelled',
                            coreClient: $stateParams.coreClient ? JSON.parse($stateParams.coreClient).v : null
                        };
                        return apiClient.get(user.getLink('jobs'), params).then(function(response) {
                            return response || apiClient.noResourceFound();
                        });
                    }
                },
                ncyBreadcrumb: {
                    skip: true
                },
            })
            .state('dashboard.user.reports.list.view.site-associate-activity.extension-requests.quotes', {
                url: '/quotes?modalPage&modalLimit',
                parent: 'dashboard.user.reports.list.view.site-associate-activity.extension-requests',
                params: {
                    modalPage: '1',
                    modalLimit: '30'
                },
                views: {
                    'quotes@': {
                        templateUrl: '/modules/user/reports/site-associate-activity/modals/extension-requests/service-providers.html',
                        controller: 'UserReportsSiteAssociateExtensionRequestsController',
                        controllerAs: 'vm',
                    }
                },
                resolve: {
                    serviceProviderCollection: function(apiClient, $stateParams, user, siteAssociateResponse) {
                        var params = angular.copy($stateParams);
                        params.page = $stateParams.modalPage;
                        params.limit = $stateParams.modalLimit;
                        params.order = null;
                        params.datagroup = 'reports';
                        params.entityType = 'quotes';
                        params.fmUser = siteAssociateResponse.getLinkAttribute('user', 'id');
                        params.coreClient = $stateParams.coreClient ? JSON.parse($stateParams.coreClient).v : null;

                        return apiClient.get(user.getLink('sa-serviceprovider-report'), params).then(function(response) {
                            return response || apiClient.noResourceFound();
                        });
                    }
                },
                ncyBreadcrumb: {
                    skip: true
                },
            })
            .state('dashboard.user.reports.list.view.site-associate-activity.extension-requests.quotes.details', {
                url: '/{spResource}/{status}/details?entityModalPage&quotsModalLimit&serviceProviderId',
                parent: 'dashboard.user.reports.list.view.site-associate-activity.extension-requests.quotes',
                params: {
                    entityModalPage: '1',
                    entityModalLimit: '10'
                },
                views: {
                    'details@': {
                        templateUrl: '/modules/user/reports/site-associate-activity/modals/extension-requests/quotes.html',
                        controller: 'UserReportsSiteAssociateExtensionRequestsQuotesController',
                        controllerAs: 'vm',
                    }
                },
                resolve: {
                    quotesCollection: function(apiClient, $stateParams, user, siteAssociateResponse) {
                        var params = {
                            page: $stateParams.entityModalPage,
                            limit: $stateParams.entityModalLimit,
                            datagroup: 'reports',
                            extensionRequests: $stateParams.status,
                            dateRange: $stateParams.dateRange,
                            serviceProvider: $stateParams.serviceProviderId,
                            fmUser: siteAssociateResponse.getLinkAttribute('user', 'id'),
                            order: 'id',
                            'excludedStatuses[]': 'cancelled',
                            coreClient: $stateParams.coreClient ? JSON.parse($stateParams.coreClient).v : null
                        };
                        return apiClient.get(user.getLink('quotes'), params).then(function(response) {
                            return response || apiClient.noResourceFound();
                        });
                    }
                },
                ncyBreadcrumb: {
                    skip: true
                },
            })
            .state('dashboard.user.reports.list.view.site-associate-activity.paperwork-for-review', {
                url: '/paperwork-for-review',
                parent: 'dashboard.user.reports.list.view.site-associate-activity.details',
                abstract: true,
                ncyBreadcrumb: {
                    skip: true
                },
                onEnter: siteAssociateParentModal,
                onExit: ['$uibModalStack', function($uibModalStack) {
                    dismissModalOnBack($uibModalStack);
                }]
            })
            .state('dashboard.user.reports.list.view.site-associate-activity.paperwork-for-review.modal', {
                url: '/view?modalPage&modalLimit',
                parent: 'dashboard.user.reports.list.view.site-associate-activity.paperwork-for-review',
                params: {
                    modalPage: '1',
                    modalLimit: '30'
                },
                views: {
                    'report-modal@': {
                        templateUrl: '/modules/user/reports/site-associate-activity/modals/paperwork-for-review/for-review.html',
                        controller: 'UserReportsSiteAssociatePaperworkForReviewController',
                        controllerAs: 'vm',
                    }
                },
                ncyBreadcrumb: {
                    skip: true
                },
                resolve: {
                    actionsCollection: function(apiClient, user, $stateParams, siteAssociateResponse) {
                        var params = angular.copy($stateParams);
                        params.page = $stateParams.modalPage;
                        params.limit = $stateParams.modalLimit;
                        params.actionSystemType = 2;
                        params['status[]'] = ['open'];
                        params.order = null;
                        params.datagroup = 'reports';
                        params.fmUser = siteAssociateResponse.getLinkAttribute('user', 'id');
                        params.region = $stateParams.siteRegion;
                        params.coreClient = $stateParams.coreClient ? JSON.parse($stateParams.coreClient).v : null;

                        return apiClient.get(user.getLink('actions'), params).then(function(response) {
                            return response || apiClient.noResourceFound();
                        });
                    }
                }
            })
            .state('dashboard.user.reports.list.view.site-associate-activity.escalated-to-client', {
                url: '/escalated-to-client',
                parent: 'dashboard.user.reports.list.view.site-associate-activity.details',
                abstract: true,
                ncyBreadcrumb: {
                    skip: true
                },
                onEnter: siteAssociateParentModal,
                onExit: ['$uibModalStack', function($uibModalStack) {
                    dismissModalOnBack($uibModalStack);
                }]
            })
            .state('dashboard.user.reports.list.view.site-associate-activity.escalated-to-client.modal', {
                url: '/view?modalPage&modalLimit',
                parent: 'dashboard.user.reports.list.view.site-associate-activity.escalated-to-client',
                params: {
                    modalPage: '1',
                    modalLimit: '30'
                },
                views: {
                    'report-modal@': {
                        templateUrl: '/modules/user/reports/site-associate-activity/modals/escalated-to-client/for-review.html',
                        controller: 'UserReportsSiteAssociateEscalatedToClientController',
                        controllerAs: 'vm',
                    }
                },
                ncyBreadcrumb: {
                    skip: true
                },
                resolve: {
                    actionsCollection: function(apiClient, user, $stateParams, siteAssociateResponse) {
                        var params = angular.copy($stateParams);
                        params.page = $stateParams.modalPage;
                        params.limit = $stateParams.modalLimit;
                        params.actionSystemType = 1;
                        params['status[]'] = ['open'];
                        params.order = null;
                        params.datagroup = 'reports';
                        params.fmUser = siteAssociateResponse.getLinkAttribute('user', 'id');
                        params.region = $stateParams.siteRegion;
                        params.coreClient = $stateParams.coreClient ? JSON.parse($stateParams.coreClient).v : null;

                        return apiClient.get(user.getLink('actions'), params).then(function(response) {
                            return response || apiClient.noResourceFound();
                        });
                    }
                }
            })
            .state('dashboard.user.reports.list.view.site-associate-activity.sites', {
                url: '/sites',
                parent: 'dashboard.user.reports.list.view.site-associate-activity.details',
                abstract: true,
                ncyBreadcrumb: {
                    skip: true
                },
                onEnter: siteAssociateParentModal,
                onExit: ['$uibModalStack', function($uibModalStack) {
                    dismissModalOnBack($uibModalStack);
                }]
            })
            .state('dashboard.user.reports.list.view.site-associate-activity.sites.modal', {
                url: '/view?modalPage&modalLimit',
                parent: 'dashboard.user.reports.list.view.site-associate-activity.sites',
                params: {
                    modalPage: '1',
                    modalLimit: '30'
                },
                views: {
                    'report-modal@': {
                        templateUrl: '/modules/user/reports/site-associate-activity/modals/sites/sites.html',
                        controller: 'UserReportsSiteAssociateSitesController',
                        controllerAs: 'vm',
                    }
                },
                ncyBreadcrumb: {
                    skip: true
                },
                resolve: {
                    sitesCollection: function(apiClient, user, $stateParams, siteAssociateResponse) {
                        var params = angular.copy($stateParams);
                        params.page = $stateParams.modalPage;
                        params.limit = $stateParams.modalLimit;
                        params.order = null;
                        params.datagroup = 'reports';
                        params.fmUser = siteAssociateResponse.getLinkAttribute('user', 'id');
                        params['siteRegions[]'] = $stateParams.siteRegion;
                        params.coreClient = $stateParams.coreClient ? JSON.parse($stateParams.coreClient).v : null;

                        return apiClient.get(user.getLink('sites'), params).then(function(response) {
                            return response || apiClient.noResourceFound();
                        });
                    }
                }
            })
            .state('dashboard.user.reports.list.view.site-associate-activity.jobs-approved', {
                url: '/jobs-approved',
                parent: 'dashboard.user.reports.list.view.site-associate-activity.details',
                abstract: true,
                ncyBreadcrumb: {
                    skip: true
                },
                onEnter: siteAssociateParentModal,
                onExit: ['$uibModalStack', function($uibModalStack) {
                    dismissModalOnBack($uibModalStack);
                }]
            })
            .state('dashboard.user.reports.list.view.site-associate-activity.jobs-approved.modal', {
                url: '/view?modalPage',
                parent: 'dashboard.user.reports.list.view.site-associate-activity.jobs-approved',
                params: {
                    modalPage: '1',
                    modalLimit: '30'
                },
                views: {
                    'report-modal@': {
                        templateUrl: '/modules/user/reports/site-associate-activity/modals/approvals/jobs-approved.html',
                        controller: 'UserReportsSiteAssociateApprovalsController',
                        controllerAs: 'vm',
                    }
                },
                ncyBreadcrumb: {
                    skip: true
                },
                resolve: {
                    dataCollection: function(apiClient, user, $stateParams) {
                        var params = angular.copy($stateParams);
                        params.page = $stateParams.modalPage;
                        params.limit = $stateParams.modalLimit;
                        params.datagroup = 'reports';
                        params.order = null;
                        params.siteAssociate = $stateParams.siteAssociateId !== null ? $stateParams.siteAssociateId : true;
                        params.siteRegionId = $stateParams.siteRegion;
                        params.siteRegion = null;
                        params.coreClient = $stateParams.coreClient ? JSON.parse($stateParams.coreClient).v : null;

                        return apiClient.get(user.getLink('jobs'), params).then(function(response) {
                            return response || apiClient.noResourceFound();
                        });
                    }
                }
            })
            .state('dashboard.user.reports.list.view.site-associate-activity.quotes-approved', {
                url: '/quotes-approved',
                parent: 'dashboard.user.reports.list.view.site-associate-activity.details',
                abstract: true,
                ncyBreadcrumb: {
                    skip: true
                },
                onEnter: siteAssociateParentModal,
                onExit: ['$uibModalStack', function($uibModalStack) {
                    dismissModalOnBack($uibModalStack);
                }]
            })
            .state('dashboard.user.reports.list.view.site-associate-activity.quotes-approved.modal', {
                url: '/view?modalPage',
                parent: 'dashboard.user.reports.list.view.site-associate-activity.quotes-approved',
                params: {
                    modalPage: '1',
                    modalLimit: '30'
                },
                views: {
                    'report-modal@': {
                        templateUrl: '/modules/user/reports/site-associate-activity/modals/approvals/quotes-approved.html',
                        controller: 'UserReportsSiteAssociateApprovalsController',
                        controllerAs: 'vm',
                    }
                },
                ncyBreadcrumb: {
                    skip: true
                },
                resolve: {
                    dataCollection: function(apiClient, user, $stateParams) {
                        var params = angular.copy($stateParams);
                        params.page = $stateParams.modalPage;
                        params.limit = $stateParams.modalLimit;
                        params.datagroup = 'reports';
                        params.order = null;
                        params.siteAssociate = $stateParams.siteAssociateId !== null ? $stateParams.siteAssociateId : true;
                        params.siteRegionId = $stateParams.siteRegion;
                        params.coreClient = $stateParams.coreClient ? JSON.parse($stateParams.coreClient).v : null;

                        return apiClient.get(user.getLink('quotes'), params).then(function(response) {
                            return response || apiClient.noResourceFound();
                        });
                    }
                }
            });
        }

    function registerAgeOfApprovalsReportRoutes($stateProvider) {
        $stateProvider
            .state('dashboard.user.reports.list.view.approval-age', {
                url: '/approval-age?dateRange',
                parent: 'dashboard.user.reports.list.view',
                views: parseBook({
                    'elogbooks-right@dashboard.user.reports': {
                        templateUrl: '/modules/user/reports/approval-age/age-of-approvals.html',
                        controller: 'UserReportsAgeOfApprovalDetailsController',
                        controllerAs: 'vm'
                    }
                }),
                params: {
                    dateRange: dateRangeMinus1Day
                },
                resolve: {
                    siteAssociatesCollection: function (apiClient, $stateParams) {
                        var params = angular.copy($stateParams);
                        params['priorities[]'] = $stateParams.priorities;

                        return apiClient.get('/reports/approval-age', params).then(function (response) {
                            return response || apiClient.noResourceFound();
                        });
                    },
                    siteAssociatesCollectionFMResponse: function (apiClient, user) {
                        return apiClient.get(user.getLink('site-associates'), {'siteAssociateType': 2}).then(function (response) {
                            return response || apiClient.noResourceFound();
                        });
                    },
                    siteAssociatesCollectionRFMResponse: function (apiClient, user) {
                        return apiClient.get(user.getLink('site-associates'), {'siteAssociateType': 1}).then(function (response) {
                            return response || apiClient.noResourceFound();
                        });
                    },
                    regions: function (apiClient, rootResourceResponse) {
                        return apiClient.get(rootResourceResponse.getLink('regions')).then(function (response) {
                            return response || apiClient.noResourceFound();
                        });
                    },
                    priorityCollectionResponse: function(apiClient, rootResourceResponse, selectedSiteResponse, $stateParams, siteAssociatesCollection) {
                        var params = angular.copy($stateParams);
                        params.limit = 30;
                        params.page = 1;

                        if (rootResourceResponse.getLink('priorities')) {
                            return apiClient.get(siteAssociatesCollection.getLink('approval-age-priorities'), params).then(function(response) {
                                return response || apiClient.noResourceFound();
                            });
                        }
                    }
                },
                ncyBreadcrumb: {
                    label: '{{ "AGE_OF_APPROVALS" | translate }}'
                }
            });
    }

    function registerSummaryReportRoutes($stateProvider) {
        $stateProvider
            .state('dashboard.user.reports.list.view.summary', {
                url: '/summary?dateRange',
                parent: 'dashboard.user.reports.list.view',
                abstract: true,
                params: {
                    dateRange: dateRangeMinus1Day
                },
                ncyBreadcrumb: {
                    label: '{{ "SUMMARY_REPORT" | translate }}'
                },
                resolve: {
                    coreClientCollectionResponse: function (apiClient, rootResourceResponse) {
                        return apiClient.get(rootResourceResponse.getLink('coreclients')).then(function (response) {
                            return response || apiClient.noResourceFound();
                        });
                    },
                    siteGroupCollectionResponse: function (apiClient, rootResourceResponse) {
                        return apiClient.get(rootResourceResponse.getLink('sitegroups')).then(function (response) {
                            return response || apiClient.noResourceFound();
                        });
                    },
                    siteAssociateTypeCollectionResponse: function (apiClient, rootResourceResponse) {
                        return apiClient.get(rootResourceResponse.getLink('siteassociatetypes')).then(function (response) {
                            return response || apiClient.noResourceFound();
                        });
                    },
                    serviceProviderGroupCollectionResponse: function (apiClient, rootResourceResponse) {
                        return apiClient.get(rootResourceResponse.getLink('serviceprovidergroups')).then(function (response) {
                            return response || apiClient.noResourceFound();
                        });
                    },
                    serviceProviderCollectionResponse: function (apiClient, user) {
                        return apiClient.get(user.getLink('serviceproviders')).then(function (response) {
                            return response || apiClient.noResourceFound();
                        });
                    },
                    regionCollectionResponse: function (apiClient, rootResourceResponse) {
                        return apiClient.get(rootResourceResponse.getLink('regions')).then(function (response) {
                            return response || apiClient.noResourceFound();
                        });
                    }
                }
            })
            .state('dashboard.user.reports.list.view.summary.details', {
                url: '?reportOn&tab&srOrder&summaryLimit&summaryPage',
                params: {
                    reportOn: 'all',
                    tab: 'region',
                    srOrder: 'name',
                    summaryLimit: '50',
                    summaryPage: '1'
                },
                parent: 'dashboard.user.reports.list.view.summary',
                views: parseBook({
                    'elogbooks-right@dashboard.user.reports': {
                        templateUrl: '/modules/user/reports/summary/summary.html',
                        controller: 'UserReportsSummaryDetailsController',
                        controllerAs: 'vm'
                    }
                }),
                data: {
                    permissions: ['user_permission_report_summary']
                },
                resolve: {
                    siteAssociateCollectionResponse: function($stateParams, apiClient, user) {
                        if ($stateParams.siteAssociateType) {
                            return apiClient.get(user.getLink('users'), {associateType: JSON.parse($stateParams.siteAssociateType).v}).then(function(response) {
                                return response || apiClient.noResourceFound();
                            });
                        }

                        return null;
                    },
                    locationCollectionResponse: function (apiClient, $stateParams, user) {
                        if ($stateParams.site !== null) {
                            var selectedSiteId = JSON.parse($stateParams.site).v;
                            var siteIds = [selectedSiteId];

                            // find the site response based on the selected site id
                            return apiClient.get(user.getLink('sites'), {'siteIds[]': siteIds}).then(function (filteredSiteCollectionResponse) {
                                if (filteredSiteCollectionResponse.count === 0) {
                                    return null;
                                }
                                var selectedSiteResponse = filteredSiteCollectionResponse.sites[0];
                                return apiClient.get(selectedSiteResponse.getLink('locations')).then(function (response) {
                                    return response;
                                });
                            });
                        }

                        return null;
                    }
                },
                ncyBreadcrumb: {
                    skip: true
                },
            })
            .state('dashboard.user.reports.list.view.summary.details.modal', {
                url: '/modal',
                parent: 'dashboard.user.reports.list.view.summary.details',
                abstract: true,
                ncyBreadcrumb: {
                    skip: true
                },
                onEnter: ['$uibModal', '$state', '$stateParams', function ($uibModal, $state, $stateParams) {
                    $uibModal.open({
                        templateUrl: '/modules/user/reports/summary/modals/base.html',
                        size: 'xl',
                        controller: ['$state', function($state, $uibModalInstance) {
                            var vm = this;
                            vm.goToParent = function () {
                                $state.go('dashboard.user.reports.list.view.summary.details', $stateParams, {reload:false, notify:false});
                            };
                            // vm.selectedTab = $state.current.name.split('.')[$state.current.name.split('.').length - 1];
                            // vm.parentState = 'dashboard.user.reports.list.view.summary';
                        }],
                        controllerAs: 'vm'
                    }).result.finally(function() {
                        $state.go('dashboard.user.reports.list.view.summary.details', $stateParams, {reload:false, notify:false});
                    });
                }],
                onExit: ['$uibModalStack', function($uibModalStack) {
                    dismissModalOnBack($uibModalStack);
                }]
            })
            .state('dashboard.user.reports.list.view.summary.details.modal.jobs', {
                url: '/jobs?modalPage&modalLimit&titlePrefix&id&columnName&statutory&itemId',
                parent: 'dashboard.user.reports.list.view.summary.details.modal',
                params: {
                    modalPage: '1',
                    modalLimit: '30'
                },
                views: {
                    'details@': {
                        templateUrl: '/modules/user/reports/summary/modals/jobs/jobs.html',
                        controller: 'UserReportsJobSummaryModalController',
                        controllerAs: 'vm'
                    }
                },
                ncyBreadcrumb: {
                    skip: true
                },
                resolve: {
                    titlePrefix: function($stateParams) {
                        return $stateParams.titlePrefix;
                    },
                    columnName: function($stateParams) {
                        return $stateParams.columnName;
                    },
                    jobsCollection: function(apiClient, user, $stateParams) {
                        var params = angular.copy($stateParams);
                        delete params.columnName;

                        params.page = $stateParams.modalPage;
                        params.limit = $stateParams.modalLimit;
                        params.order = null;
                        params.datagroup = 'reports';
                        if ($stateParams.id > 0) {
                            params.groupByIdKey = $stateParams.tab;
                            params.groupById = $stateParams.id;
                        } else {
                            params.groupBy = $stateParams.tab;
                        }
                        params.coreClient = $stateParams.coreClient ? JSON.parse($stateParams.coreClient).v : null;
                        params.site = $stateParams.site ? JSON.parse($stateParams.site).v : null;
                        params.siteGroup = $stateParams.siteGroup ? JSON.parse($stateParams.siteGroup).v : null;
                        params.siteRegionId = $stateParams.siteRegionId ? JSON.parse($stateParams.siteRegionId).v : null;
                        params.siteAssociateType = $stateParams.siteAssociateType ? JSON.parse($stateParams.siteAssociateType).v : null;
                        params.siteAssociateId = $stateParams.siteAssociateId ? JSON.parse($stateParams.siteAssociateId).v : null;
                        params.serviceProviderGroup = $stateParams.serviceProviderGroup ? JSON.parse($stateParams.serviceProviderGroup).v : null;
                        params.serviceProvider = $stateParams.serviceProvider ? JSON.parse($stateParams.serviceProvider).v : null;
                        params['excludedStatuses[]'] = ['cancelled', 'approval_rejected'];
                        params.titlePrefix = $stateParams.titlePrefix;

                        switch ($stateParams.columnName.toLowerCase()) {
                            case 'open_jobs':
                                params.excludeFinalStatuses = true;
                                break;
                            case 'labour_lines':
                                params.hasLabourLines = true;
                                break;
                            case 'completed_jobs':
                                params['status[]'] = ['missed', 'completed'];
                                delete params['excludedStatuses[]'];
                                break;
                            case 'missed_jobs':
                                params['status[]'] = ['missed'];
                                delete params['excludedStatuses[]'];
                                break;
                            case 'attended_on_time':
                                params.attendedOnTime = true;
                                break;
                            case 'completed_on_time':
                                params.completedOnTime = true;
                                break;
                        }

                        if (!params.titlePrefix) {
                            var itemId = params.itemId || -1;
                        }

                        switch ($stateParams.tab) {
                            case 'job_group':
                                params.jobGroup = itemId;

                                if($stateParams.id > 0) {
                                    params.groupByIdKey = 'jobGroup';
                                } else {
                                    params.groupBy = 'jobGroup';
                                }

                                break;
                            case 'serviceprovider':
                                params.groupByIdKey = 'serviceProvider';
                                break;
                            case 'site_group':
                                params.groupByIdKey = 'siteGroup';
                                break;
                            case 'service_provider_group':
                                params.groupByIdKey = 'serviceProviderGroup';
                                break;
                            case 'priority':
                                params.priority = itemId;
                                break;
                            case 'region':
                                params.region = itemId;
                                break;
                        }

                        delete params.id;
                        delete params.srOrder;
                        delete params.tab;
                        delete params.allowPercentages;
                        delete params.modalPage;
                        delete params.modalLimit;
                        delete params.columnName;
                        delete params.showPercentages;
                        delete params.itemId;

                        return apiClient.get(user.getLink('jobs'), params).then(function(response) {
                            return response || apiClient.noResourceFound();
                        });
                    }
                }
            })
            .state('dashboard.user.reports.list.view.summary.details.modal.quotes', {
                url: '/quotes?modalPage&modalLimit&titlePrefix&id&columnName&statutory',
                parent: 'dashboard.user.reports.list.view.summary.details.modal',
                params: {
                    modalPage: '1',
                    modalLimit: '30'
                },
                views: {
                    'details@': {
                        templateUrl: '/modules/user/reports/summary/modals/quotes/quotes.html',
                        controller: 'UserReportsCommonQuotesModalController',
                        controllerAs: 'vm'
                    }
                },
                ncyBreadcrumb: {
                    skip: true
                },
                resolve: {
                    titlePrefix: function($stateParams) {
                        return $stateParams.titlePrefix;
                    },
                    columnName: function($stateParams) {
                        return $stateParams.columnName;
                    },
                    collection: function(apiClient, user, $stateParams) {
                        var params = angular.copy($stateParams);
                        delete params.columnName;

                        params.page = $stateParams.modalPage;
                        params.limit = $stateParams.modalLimit;
                        params.order = null;
                        params.datagroup = 'reports';
                        if ($stateParams.id) {
                            params.groupByIdKey = $stateParams.tab;
                            params.groupById = $stateParams.id;
                        } else {
                            params.groupBy = $stateParams.tab;
                        }
                        params.coreClient = $stateParams.coreClient ? JSON.parse($stateParams.coreClient).v : null;
                        params.site = $stateParams.site ? JSON.parse($stateParams.site).v : null;
                        params.siteGroup = $stateParams.siteGroup ? JSON.parse($stateParams.siteGroup).v : null;
                        params.siteRegionId = $stateParams.siteRegionId ? JSON.parse($stateParams.siteRegionId).v : null;
                        params.siteAssociateType = $stateParams.siteAssociateType ? JSON.parse($stateParams.siteAssociateType).v : null;
                        params.siteAssociateId = $stateParams.siteAssociateId ? JSON.parse($stateParams.siteAssociateId).v : null;
                        params.serviceProviderGroup = $stateParams.serviceProviderGroup ? JSON.parse($stateParams.serviceProviderGroup).v : null;
                        params.serviceProvider = $stateParams.serviceProvider ? JSON.parse($stateParams.serviceProvider).v : null;

                        switch ($stateParams.columnName.toLowerCase()) {
                            case 'accepted_quotes':
                                params['quoteStatuses[]'] = ['accepted'];
                                break;
                            case 'declined_quotes':
                                params['quoteStatuses[]'] = ['abstained'];
                                break;
                            case 'expired_quotes':
                                params['quoteStatuses[]'] = ['expired'];
                                break;
                            case 'rejected_quotes':
                                params['quoteStatuses[]'] = ['rejected'];
                                break;
                        }

                        switch ($stateParams.tab) {
                            case 'quotes_by_site':
                                if ($stateParams.id) {
                                    params.groupByIdKey = 'site';
                                } else {
                                    params.groupBy = 'site';
                                }
                                break;
                            case 'quotes_by_sp':
                                if ($stateParams.id) {
                                    params.groupByIdKey = 'serviceProvider';
                                } else {
                                    params.groupBy = 'serviceProvider';
                                }
                                break;
                        }

                        delete params.id;
                        delete params.srOrder;
                        delete params.allowPercentages;
                        delete params.modalPage;
                        delete params.modalLimit;
                        delete params.columnName;
                        delete params.titlePrefix;
                        delete params.showPercentages;
                        delete params.tab;

                        return apiClient.get(user.getLink('quotes'), params).then(function(response) {
                            return response || apiClient.noResourceFound();
                        });
                    }
                }
            })
            .state('dashboard.user.reports.list.view.summary.details.modal.kpi', {
                url: '/kpi?modalPage&modalLimit&titlePrefix&id&columnName',
                parent: 'dashboard.user.reports.list.view.summary.details.modal',
                params: {
                    modalPage: '1',
                    modalLimit: '30'
                },
                views: {
                    'details@': {
                        templateUrl: '/modules/user/reports/summary/modals/kpi/kpi.html',
                        controller: 'UserReportsKPISummaryModalController',
                        controllerAs: 'vm'
                    }
                },
                ncyBreadcrumb: {
                    skip: true
                },
                resolve: {
                    titlePrefix: function($stateParams) {
                        return $stateParams.titlePrefix;
                    },
                    columnName: function($stateParams) {
                        return $stateParams.columnName;
                    },
                    collection: function(apiClient, user, $stateParams) {
                        var params = angular.copy($stateParams);
                        delete params.columnName;

                        params.page = $stateParams.modalPage;
                        params.limit = $stateParams.modalLimit;
                        params.order = null;
                        params.datagroup = 'details';
                        if ($stateParams.id) {
                            params.groupByIdKey = $stateParams.tab;
                            params.groupById = $stateParams.id;
                        } else {
                            params.groupBy = $stateParams.tab;
                        }
                        params.coreClient = $stateParams.coreClient ? JSON.parse($stateParams.coreClient).v : null;
                        params.site = $stateParams.site ? JSON.parse($stateParams.site).v : null;
                        params.siteGroup = $stateParams.siteGroup ? JSON.parse($stateParams.siteGroup).v : null;
                        params.siteRegionId = $stateParams.siteRegionId ? JSON.parse($stateParams.siteRegionId).v : null;
                        params.siteAssociateType = $stateParams.siteAssociateType ? JSON.parse($stateParams.siteAssociateType).v : null;
                        params.siteAssociateId = $stateParams.siteAssociateId ? JSON.parse($stateParams.siteAssociateId).v : null;
                        params.serviceProviderGroup = $stateParams.serviceProviderGroup ? JSON.parse($stateParams.serviceProviderGroup).v : null;
                        params.serviceProvider = $stateParams.serviceProvider ? JSON.parse($stateParams.serviceProvider).v : null;

                        switch ($stateParams.tab) {
                            case 'kpi':
                                if ($stateParams.id) {
                                    params.groupByIdKey = 'serviceProvider';
                                } else {
                                    params.groupBy = 'serviceProvider';
                                }
                                break;
                            case 'kpi_groups':
                                if ($stateParams.id) {
                                    params.groupByIdKey = 'serviceProviderGroup';
                                } else {
                                    params.groupBy = 'serviceProviderGroup';
                                }
                                break;
                        }

                        delete params.id;
                        delete params.srOrder;
                        delete params.allowPercentages;
                        delete params.modalPage;
                        delete params.modalLimit;
                        delete params.columnName;
                        delete params.titlePrefix;
                        delete params.showPercentages;
                        delete params.tab;

                        return apiClient.get(user.getLink('surveyrequests'), params).then(function(response) {
                            return response || apiClient.noResourceFound();
                        });
                    }
                }
            })
        ;
    }

    function registerPpmGapAnalysisReportRoutes($stateProvider) {
        $stateProvider
            .state('dashboard.user.reports.list.view.ppm-gap-analysis', {
                url: '/ppm-gap-analysis',
                parent: 'dashboard.user.reports.list.view',
                views: parseBook({
                    'elogbooks-right@dashboard.user.reports': {
                        templateUrl: '/modules/user/reports/ppm-gap-analysis/ppm-gap-analysis.html',
                        controller: 'UserReportsPpmGapAnalysisDetailsController',
                        controllerAs: 'vm'
                    }
                }),
                params: {
                    serviceProviderId: null,
                    siteId: null,
                    siteRegionId: null
                },
                resolve: {
                    ppmGapAnalysisCollectionResponse: function (apiClient, $stateParams) {
                        var params = angular.copy($stateParams);

                        if ($stateParams.serviceProviderId) {
                            params.serviceProviderId = JSON.parse($stateParams.serviceProviderId).v;
                        }

                        return apiClient.get('/reports/ppm-gap-analysis', params).then(function (response) {
                            return response || apiClient.noResourceFound();
                        });
                    },
                    siteCollectionResponse: function (apiClient, user) {
                        return apiClient.get(user.getLink('sites')).then(function (response) {
                            return response || apiClient.noResourceFound();
                        });
                    },
                    serviceProviderCollectionResponse: function(apiClient, user) {
                        return apiClient.get(user.getLink('serviceproviders')).then(function(response) {
                            return response || apiClient.noResourceFound();
                        });
                    },
                    regionCollectionResponse: function(apiClient, user) {
                        return apiClient.get(user.getLink('regions')).then(function (response) {
                            return response || apiClient.noResourceFound();
                        });
                    },
                },
                ncyBreadcrumb: {
                    label: '{{ "PPM_GAP_ANALYSIS" | translate }}'
                }
            });
    }

    function registerOpenCloseReportRoutes($stateProvider) {
        $stateProvider
            .state('dashboard.user.reports.list.view.open-close', {
                url: '/open-close/{reportResource}?dateRange',
                parent: 'dashboard.user.reports.list.view',
                views: parseBook({
                    'elogbooks-right@dashboard.user.reports': {
                        templateUrl: '/modules/user/reports/open-close/open-close.html',
                        controller: 'UserReportsOpenCloseDetailsController',
                        controllerAs: 'vm'
                    }
                }),
                params: {
                    type: null,
                    siteId: null,
                    tenant: null,
                    dateRange: moment().subtract(30, 'days').startOf('day').format()+','+moment().endOf('day').format()
                },
                resolve: {
                    openCloseCollectionResponse: function (apiClient, $stateParams, base64) {
                        var params = angular.copy($stateParams);
                        params.limit = '30';
                        $stateParams.limit = '30';

                        var url = '/reports/open-close';

                        if ($stateParams.reportResource) {
                            url = url + base64.decode($stateParams.reportResource);
                            params = null;
                            $stateParams.modalOpen = true;
                        }

                        if (!params.siteId) {
                            params.tenant = null;
                        }

                        return apiClient.get(url, params).then(function (response) {
                            return response || apiClient.noResourceFound();
                        });
                    },
                    siteCollectionResponse: function (apiClient, user) {
                        return apiClient.get(user.getLink('sites'), {datagroup:'reports'}).then(function (response) {
                            return response || apiClient.noResourceFound();
                        });
                    },
                    tenantCollectionResponse: function ($stateParams, lodash, siteCollectionResponse, apiClient) {
                        if ($stateParams.siteId) {
                            var siteHref = lodash.find(siteCollectionResponse.sites, ['id', Number($stateParams.siteId)]);
                            if (siteHref.getLink('tenants')) {
                                return apiClient.get(siteHref.getLink('tenants'), {site: $stateParams.siteId}).then(function (response) {
                                    return response || apiClient.noResourceFound();
                                });
                            }
                        }
                    }
                },
                ncyBreadcrumb: {
                    label: '{{ "OPEN_CLOSE" | translate }}'
                }
            });
    }

    function registerMonthlyReportRoutes($stateProvider) {
        $stateProvider
            .state('dashboard.user.reports.list.view.monthly', {
                url: '/monthly?dateRange&tab&srOrder',
                parent: 'dashboard.user.reports.list.view',
                params: {
                    dateRange: monthlyReportDefault,
                    tab: 'data',
                    srOrder: 'name',
                    monthlyLimit: '50',
                    monthlyPage: '1'
                },
                views: parseBook({
                    'elogbooks-right@dashboard.user.reports': {
                        templateUrl: '/modules/user/reports/monthly/monthly.html',
                        controller: 'UserReportsMonthlyDetailsController',
                        controllerAs: 'vm'
                    }
                }),
                ncyBreadcrumb: {
                    label: '{{ "MONTHLY_REPORT" | translate }}'
                },
                resolve: {
                    coreClientCollectionResponse: function (apiClient, rootResourceResponse) {
                        return apiClient.get(rootResourceResponse.getLink('coreclients')).then(function (response) {
                            return response || apiClient.noResourceFound();
                        });
                    },
                    siteGroupCollectionResponse: function (apiClient, rootResourceResponse) {
                        return apiClient.get(rootResourceResponse.getLink('sitegroups')).then(function (response) {
                            return response || apiClient.noResourceFound();
                        });
                    },
                    serviceProviderGroupCollectionResponse: function (apiClient, rootResourceResponse) {
                        return apiClient.get(rootResourceResponse.getLink('serviceprovidergroups')).then(function (response) {
                            return response || apiClient.noResourceFound();
                        });
                    },
                    serviceProviderCollectionResponse: function (apiClient, user) {
                        return apiClient.get(user.getLink('serviceproviders')).then(function (response) {
                            return response || apiClient.noResourceFound();
                        });
                    },
                    regionCollectionResponse: function (apiClient, rootResourceResponse) {
                        return apiClient.get(rootResourceResponse.getLink('regions')).then(function (response) {
                            return response || apiClient.noResourceFound();
                        });
                    }
                }
            })
            .state('dashboard.user.reports.list.view.monthly.modal', {
                url: '/modal?modalPage&modalType&modalDateRange',
                parent: 'dashboard.user.reports.list.view.monthly',
                abstract: true,
                ncyBreadcrumb: {
                    skip: true
                },
                params: {
                    modalPage: '1'
                },
                resolve: {
                    reactiveMissedStatuses: function() {
                        return ['acknowledged', 'commenced', 'awaiting_paperwork', 'extension_pending', 'completed'];
                    },
                    plannedMissedStatuses: function() {
                        return ['acknowledged', 'commenced', 'missed', 'extension_pending', 'completed'];
                    }
                },
                onEnter: ['$uibModal', '$state', '$stateParams', function ($uibModal, $state, $stateParams) {
                    $uibModal.open({
                        templateUrl: '/modules/user/reports/monthly/modals/base.html',
                        size: 'xl',
                        controller: ['$state', '$stateParams', '$uibModalInstance', function($state, $stateParams, $uibModalInstance) {
                            var vm = this;
                            vm.selectedTab = $state.current.name.split('.')[$state.current.name.split('.').length - 1];
                            vm.type = $stateParams.modalType;
                            vm.modalInstance = $uibModalInstance;
                            vm.cancel = dismissModal;

                            function dismissModal () {
                                vm.modalInstance.dismiss();
                            }
                        }],
                        controllerAs: 'vm'
                    }).result.finally(function() {
                        $state.go('^.^');
                    });
                }],
                onExit: ['$uibModalStack', function($uibModalStack) {
                    dismissModalOnBack($uibModalStack);
                }]
            })
            .state('dashboard.user.reports.list.view.monthly.modal.attended-completed', {
                url: '/attended-completed',
                parent: 'dashboard.user.reports.list.view.monthly.modal',
                params: {
                    modalPage: '1'
                },
                views: {
                    'attended@': {
                        templateUrl: '/modules/user/reports/monthly/modals/list.html',
                        controller: 'MonthlyReportsAttendedMissedCompletionController',
                        controllerAs: 'vm'
                    }
                },
                ncyBreadcrumb: {
                    skip: true
                },
                resolve: {
                    params: function($stateParams, reactiveMissedStatuses, plannedMissedStatuses) {
                        var params = {};

                        params.coreClient = $stateParams.coreClient ? JSON.parse($stateParams.coreClient).v : null;
                        params.site = $stateParams.site ? JSON.parse($stateParams.site).v : null;
                        params.siteGroup = $stateParams.siteGroup ? JSON.parse($stateParams.siteGroup).v : null;
                        params.siteRegionId = $stateParams.siteRegionId ? JSON.parse($stateParams.siteRegionId).v : null;
                        params.siteAssociateType = $stateParams.siteAssociateType ? JSON.parse($stateParams.siteAssociateType).v : null;
                        params.siteAssociateId = $stateParams.siteAssociateId ? JSON.parse($stateParams.siteAssociateId).v : null;
                        params.serviceProviderGroup = $stateParams.serviceProviderGroup ? JSON.parse($stateParams.serviceProviderGroup).v : null;
                        params.serviceProvider = $stateParams.serviceProvider ? JSON.parse($stateParams.serviceProvider).v : null
                        params.page = $stateParams.modalPage;
                        params.dateRange = $stateParams.modalDateRange;

                        switch ($stateParams.modalType) {
                            case 'reactive_attendance':
                                params.type = "reactive";
                                params.overdueAttendanceHistoric = false;
                                params['status[]'] = reactiveMissedStatuses;
                                break;
                            case 'reactive_completion':
                                params.type = "reactive";
                                params.overdueCompletionHistoric = false;
                                params['status[]'] = reactiveMissedStatuses;
                                break;
                            case 'planned_attendance':
                                params.type = "planned";
                                params.overdueAttendanceHistoric = false;
                                params['status[]'] = plannedMissedStatuses;
                                break;
                            case 'planned_completion':
                                params.type = "planned";
                                params.overdueCompletionHistoric = false;
                                params['status[]'] = plannedMissedStatuses;
                                break;
                            case 'quotes':
                                params.overdueCompletionHistoric = false;
                                params['excludedStatuses[]'] = 'cancelled';
                                break;
                        }

                        return params;
                    },
                    collection: function(apiClient, user, params, $stateParams) {
                        return apiClient.get(
                            $stateParams.modalType === 'quotes' ? user.getLink('quotes') : user.getLink('jobs'),
                            params
                        ).then(function(response) {
                            return response || apiClient.noResourceFound();
                        });
                    }
                }
            })
            .state('dashboard.user.reports.list.view.monthly.modal.missed', {
                url: '/missed',
                parent: 'dashboard.user.reports.list.view.monthly.modal',
                params: {
                    modalPage: '1'
                },
                views: {
                    'missed@': {
                        templateUrl: '/modules/user/reports/monthly/modals/list.html',
                        controller: 'MonthlyReportsAttendedMissedCompletionController',
                        controllerAs: 'vm'
                    }
                },
                ncyBreadcrumb: {
                    skip: true
                },
                resolve: {
                    params: function($stateParams, reactiveMissedStatuses, plannedMissedStatuses) {
                        var params = {};

                        params.coreClient = $stateParams.coreClient ? JSON.parse($stateParams.coreClient).v : null;
                        params.site = $stateParams.site ? JSON.parse($stateParams.site).v : null;
                        params.siteGroup = $stateParams.siteGroup ? JSON.parse($stateParams.siteGroup).v : null;
                        params.siteRegionId = $stateParams.siteRegionId ? JSON.parse($stateParams.siteRegionId).v : null;
                        params.siteAssociateType = $stateParams.siteAssociateType ? JSON.parse($stateParams.siteAssociateType).v : null;
                        params.siteAssociateId = $stateParams.siteAssociateId ? JSON.parse($stateParams.siteAssociateId).v : null;
                        params.serviceProviderGroup = $stateParams.serviceProviderGroup ? JSON.parse($stateParams.serviceProviderGroup).v : null;
                        params.serviceProvider = $stateParams.serviceProvider ? JSON.parse($stateParams.serviceProvider).v : null;
                        params.page = $stateParams.modalPage;
                        params.dateRange = $stateParams.modalDateRange;

                        switch ($stateParams.modalType) {
                            case 'reactive_attendance':
                                params.type = "reactive";
                                params.overdueAttendanceHistoric = true;
                                params['status[]'] = reactiveMissedStatuses;
                                break;
                            case 'reactive_completion':
                                params.type = "reactive";
                                params.overdueCompletionHistoric = true;
                                params['status[]'] = reactiveMissedStatuses;
                                break;
                            case 'planned_attendance':
                                params.type = "planned";
                                params.overdueAttendanceHistoric = true;
                                params['status[]'] = plannedMissedStatuses;
                                break;
                            case 'planned_completion':
                                params.type = "planned";
                                params.overdueCompletionHistoric = true;
                                params['status[]'] = plannedMissedStatuses;
                                break;
                            case 'quotes':
                                params.overdueCompletionHistoric = true;
                                params['excludedStatuses[]'] = 'cancelled';
                                break;
                        }

                        return params;
                    },
                    collection: function(apiClient, user, params, $stateParams) {
                        return apiClient.get(
                            $stateParams.modalType === 'quotes' ? user.getLink('quotes') : user.getLink('jobs'),
                            params
                        ).then(function(response) {
                            return response || apiClient.noResourceFound();
                        });
                    }
                }
            })
        ;
    }

    function registerKpiMonthlyReportRoutes($stateProvider) {
        $stateProvider
            .state('dashboard.user.reports.list.view.kpi-monthly', {
                url: '/kpi-monthly?dateRange&tab&srOrder&survey&version',
                parent: 'dashboard.user.reports.list.view',
                params: {
                    dateRange: KpiMonthlyReportDefault,
                    srOrder: 'name',
                    monthlyLimit: '15',
                    monthlyPage: '1'
                },
                views: parseBook({
                    'elogbooks-right@dashboard.user.reports': {
                        templateUrl: '/modules/user/reports/kpi-monthly/kpi-monthly.html',
                        controller: 'UserReportsKpiMonthlyDetailsController',
                        controllerAs: 'vm'
                    }
                }),
                ncyBreadcrumb: {
                    label: '{{ "KPI_MONTHLY_REPORT" | translate }}'
                },
                resolve: {
                    reportDataResponse: function (apiClient, $stateParams) {
                        var params = {
                            'serviceProviderGroups[]': $stateParams.serviceProviderGroups,
                            'siteRegions[]': $stateParams.siteRegions
                        };

                        params.dateRange = $stateParams.dateRange;
                        params.coreClient = $stateParams.coreClient ? JSON.parse($stateParams.coreClient).v : null;
                        params.site = $stateParams.site ? JSON.parse($stateParams.site).v : null;
                        params.serviceProvider = $stateParams.serviceProvider ? JSON.parse($stateParams.serviceProvider).v : null;
                        params.survey = $stateParams.survey ? JSON.parse($stateParams.survey).v : null;
                        params.page = $stateParams.monthlyPage;
                        params.limit = $stateParams.monthlyLimit;
                        params.version = $stateParams.version || null;

                        if ($stateParams.survey) {
                            return apiClient.get('/reports/kpi-monthly', params).then(function (response) {
                                return response;
                            });
                        } else {
                            return { surveyLines: [] };
                        }
                    },
                    coreClientCollectionResponse: function (apiClient, rootResourceResponse) {
                        return apiClient.get(rootResourceResponse.getLink('coreclients')).then(function (response) {
                            return response || apiClient.noResourceFound();
                        });
                    },
                    serviceProviderGroupCollectionResponse: function (apiClient, rootResourceResponse) {
                        return apiClient.get(rootResourceResponse.getLink('serviceprovidergroups')).then(function (response) {
                            return response || apiClient.noResourceFound();
                        });
                    },
                    serviceProviderCollectionResponse: function (apiClient, user) {
                        return apiClient.get(user.getLink('serviceproviders')).then(function (response) {
                            return response || apiClient.noResourceFound();
                        });
                    },
                    regionCollectionResponse: function (apiClient, rootResourceResponse) {
                        return apiClient.get(rootResourceResponse.getLink('regions')).then(function (response) {
                            return response || apiClient.noResourceFound();
                        });
                    },
                    surveysCollectionResponse: function (apiClient, rootResourceResponse) {
                        return apiClient.get(rootResourceResponse.getLink('surveys'), { active: 'yes' }).then(function (response) {
                            return response;
                        });
                    },
                    versionsResponse: function(surveysCollectionResponse, apiClient, $stateParams, lodash) {
                        try {
                            var surveyId = JSON.parse($stateParams.survey).v;
                            if (surveyId) {
                                return apiClient.get(surveysCollectionResponse.getLink('self'), {'rootId': surveyId}).then(function (response) {
                                    if (response && response.surveys.length > 0) {
                                        var survey = response.surveys[0];
                                        return apiClient.get(survey.getLink('versions'), {'status': 'draft', 'excludeStatus': 1}).then(function (response) {
                                            return response || apiClient.noResourceFound();
                                        });
                                    }

                                    return [];
                                });
                            }
                        } catch (error) {
                           return null;
                        }
                    }
                }
            })
        ;
    }

    function registerPortfolioComplianceReportRoutes($stateProvider) {
        $stateProvider
            .state('dashboard.user.reports.list.view.portfolio-compliance', {
                url: '/portfolio-compliance?cPage&cLimit&cOrder&spGroupId&month&year',
                parent: 'dashboard.user.reports.list.view',
                params: {
                    cPage: '1',
                    cLimit: '30',
                    cOrder: 'year,month',
                    year: (new Date()).getFullYear().toString()
                },
                views: parseBook({
                    'elogbooks-right@dashboard.user.reports': {
                        templateUrl: '/modules/user/reports/portfolio-compliance/portfolio-compliance.html',
                        controller: 'UserReportsPortfolioComplianceDetailsController',
                        controllerAs: 'vm'
                    }
                }),
                ncyBreadcrumb: {
                    label: '{{ "PORTFOLIO_COMPLIANCE_REPORT" | translate }}'
                },
                resolve: {
                    serviceProviderGroupCollectionResponse: function (apiClient, rootResourceResponse) {
                        return apiClient.get(rootResourceResponse.getLink('serviceprovidergroups')).then(function (response) {
                            return response || apiClient.noResourceFound();
                        });
                    }
                }
            })
            .state('dashboard.user.reports.list.view.portfolio-compliance.modal', {
                url: '/modal',
                parent: 'dashboard.user.reports.list.view.portfolio-compliance',
                ncyBreadcrumb: {
                    skip: true
                },
                onEnter: ['$uibModal', '$state', '$stateParams', 'serviceProviderGroupCollectionResponse', function ($uibModal, $state, $stateParams, serviceProviderGroupCollectionResponse) {
                    $uibModal.open({
                        templateUrl: '/modules/user/reports/portfolio-compliance/modal/portfolio-compliance-modal.html',
                        size: 'md',
                        controller: 'PortfolioComplianceReportsModalController',
                        controllerAs: 'vm',
                        resolve: {
                            serviceProviderGroupCollectionResponse: serviceProviderGroupCollectionResponse
                        }
                    }).result.then(function(result) {
                        var reload = {};

                        if (result && result.reload) {
                            reload = {reload:'dashboard.user.reports.list.view.portfolio-compliance'};
                        }

                        $state.go('^', $stateParams, reload);
                    }, function() {
                        $state.go('^', $stateParams);
                    });
                }],
                onExit: ['$uibModalStack', function($uibModalStack) {
                    dismissModalOnBack($uibModalStack);
                }]
            });
    }

    function registerAuditTrendReportRoutes($stateProvider) {
        $stateProvider
            .state('dashboard.user.reports.list.view.audit-trend', {
                url: '/audit-trend?siteIds&auditJson&siteGroupIds&siteRegionIds&questionPage&questionLimit&questionOrder',
                parent: 'dashboard.user.reports.list.view',
                params: {
                    template: null,
                    audit: null,
                    auditJson: null,
                    section: null,
                    summary: null,
                    createdAt: moment().subtract(30, 'days').startOf('day').format()+','+moment().endOf('day').format(),
                    completedAt: moment().subtract(30, 'days').startOf('day').format()+','+moment().endOf('day').format(),
                    answerOption: null,
                    siteIds: {array: true},
                    siteGroupIds: {array: true},
                    siteRegionIds: {array: true},
                    questionPage: '1',
                    questionLimit: '30',
                    questionOrder: 'audit_section'
                },
                views: parseBook({
                    'elogbooks-right@dashboard.user.reports': {
                        templateUrl: '/modules/user/reports/audit-trend/audit-trend.html',
                        controller: 'UserReportsAuditTrendController',
                        controllerAs: 'vm'
                    }
                }),
                resolve: {
                    auditTemplateCollectionResponse: function (apiClient, user, rootResourceResponse) {
                        if (rootResourceResponse.getLink('audits')) {
                            return apiClient.get(rootResourceResponse.getLink('audits'), {
                                status: 'audit_template_active',
                                type: 'template',
                                datagroup: 'search',
                                limit: 100
                            }).then(function (response) {
                                return response || apiClient.noResourceFound();
                            });
                        } else {
                            return null;
                        }
                    },
                    siteCollectionResponse: function (apiClient, user, $stateParams) {
                        var params = angular.copy($stateParams);

                        params['siteIds[]'] = null;
                        params.siteIds = null;

                        return apiClient.get(user.getLink('sites'), params).then(function (response) {
                            return response || apiClient.noResourceFound();
                        });
                    },
                    siteGroupsCollection: function (apiClient, rootResourceResponse, $stateParams) {
                        return apiClient.get(rootResourceResponse.getLink('sitegroups'), $stateParams);
                    },
                    regionCollectionResponse: function ($stateParams, apiClient, rootResourceResponse) {
                        return apiClient.get(rootResourceResponse.getLink('regions'), $stateParams).then(function (response) {
                            return response || apiClient.noResourceFound();
                        });
                    },
                    questionCollectionResponse: function ($stateParams, apiClient) {
                        if ($stateParams.audit) {
                            var params = angular.copy($stateParams);
                            delete params.template;

                            params = angular.extend({}, params,
                                {
                                    'siteIds[]': $stateParams.siteIds,
                                    'siteGroupIds[]': $stateParams.siteGroupIds,
                                    'siteRegionIds[]': $stateParams.siteRegionIds
                                });

                            return apiClient.get('/reports/audit-trend', params).then(function (response) {
                                return response || apiClient.noResourceFound();
                            });
                        } else {
                            return {};
                        }
                    }
                },
                ncyBreadcrumb: {
                    label: '{{ "AUDIT_TREND_REPORT" | translate }}'
                }
            })
            .state('dashboard.user.reports.list.view.audit-trend.modal', {
                url: '/modal',
                parent: 'dashboard.user.reports.list.view.audit-trend',
                abstract: true,
                ncyBreadcrumb: {
                    skip: true
                },
                onEnter: ['$uibModal', '$state', '$stateParams', function ($uibModal) {
                    $uibModal.open({
                        templateUrl: '/modules/user/reports/audit-trend/modal/base.html',
                        controller: ['$state', '$stateParams', '$uibModalInstance', function($state, $stateParams, $uibModalInstance) {
                            var vm = this;
                            vm.cancel = dismissModal;

                            function dismissModal () {
                                $uibModalInstance.dismiss();
                                $state.go('^.^');
                            }
                        }],
                        size: 'xl',
                        backdrop: 'static',
                        controllerAs: 'vm'
                    });
                }],
                onExit: ['$uibModalStack', function($uibModalStack) {
                    dismissModalOnBack($uibModalStack);
                }]
            })
            .state('dashboard.user.reports.list.view.audit-trend.modal.questions', {
                url: '/questions/{answerOption}?modalPage',
                parent: 'dashboard.user.reports.list.view.audit-trend.modal',
                params: {
                    modalPage: '1',
                    modalLimit: '4'
                },
                views: {
                    'questions@': {
                        templateUrl: '/modules/user/reports/audit-trend/modal/audit-trend-modal.html',
                        controller: 'AuditTrendReportModalController',
                        controllerAs: 'vm'
                    }
                },
                ncyBreadcrumb: {
                    skip: true
                },
                resolve: {
                    answerCollectionResponse: function(apiClient, $stateParams) {
                        if ($stateParams.answerOption) {
                            var params = $stateParams;

                            params = angular.extend({}, params, {
                                'siteIds[]': $stateParams.siteIds,
                                'siteGroupIds[]': $stateParams.siteGroupIds,
                                'siteRegionIds[]': $stateParams.siteRegionIds
                            });

                            return apiClient.get('/reports/audit-trend/answers', params).then(function (response) {
                                return response || apiClient.noResourceFound();
                            });
                        }
                    }
                }
            });
    }

    function registerEngineerRiskReportRoutes($stateProvider) {
        $stateProvider
            .state('dashboard.user.reports.list.view.engineering-risk', {
                url: '/engineering-risk?&externalReference&dateRange&tab&srOrder&riskLimit&riskPage',
                parent: 'dashboard.user.reports.list.view',
                params: {
                    dateRange: engineeringRiskReportDefault,
                    srOrder: 'name',
                    riskLimit: '30',
                    riskPage: '1'
                },
                views: parseBook({
                    'elogbooks-right@dashboard.user.reports': {
                        templateUrl: '/modules/user/reports/engineering-risk/engineering-risk.html',
                        controller: 'UserReportsEngineeringRiskDetailsController',
                        controllerAs: 'vm'
                    }
                }),
                ncyBreadcrumb: {
                    label: '{{ "ENGINEER_RISK_REPORT" | translate }}'
                },
                resolve: {
                    statutoryTypeCollectionResponse: function (apiClient, rootResourceResponse) {
                        return apiClient.get(rootResourceResponse.getLink('statutorytypes'), {limit: 100}).then(function (response) {
                            return response || apiClient.noResourceFound();
                        });
                    },
                    coreClientCollectionResponse: function (apiClient, rootResourceResponse) {
                        return apiClient.get(rootResourceResponse.getLink('coreclients')).then(function (response) {
                            return response || apiClient.noResourceFound();
                        });
                    },
                    siteGroupCollectionResponse: function (apiClient, rootResourceResponse) {
                        return apiClient.get(rootResourceResponse.getLink('sitegroups')).then(function (response) {
                            return response || apiClient.noResourceFound();
                        });
                    },
                    siteAssociatesCollectionRFMResponse: function (apiClient, user) {
                        return apiClient.get(user.getLink('site-associates'), {'siteAssociateType': 1}).then(function (response) {
                            return response || apiClient.noResourceFound();
                        });
                    },
                    siteAssociateTypesCollectionResponse: function(apiClient, rootResourceResponse) {
                        return apiClient.get(rootResourceResponse.getLink('siteassociatetypes')).then(function(response) {
                            return response || apiClient.noResourceFound();
                        });
                    },
                    serviceProviderGroupCollectionResponse: function (apiClient, rootResourceResponse) {
                        return apiClient.get(rootResourceResponse.getLink('serviceprovidergroups')).then(function (response) {
                            return response || apiClient.noResourceFound();
                        });
                    },
                    serviceProviderCollectionResponse: function (apiClient, user) {
                        return apiClient.get(user.getLink('serviceproviders')).then(function (response) {
                            return response || apiClient.noResourceFound();
                        });
                    }
                }
            })
            .state('dashboard.user.reports.list.view.engineering-risk.modal', {
                url: '/modal?modalPage&modalType&modalSite&modalStatutoryType&modalDateRange&modalJobGroup',
                parent: 'dashboard.user.reports.list.view.engineering-risk',
                abstract: true,
                ncyBreadcrumb: {
                    skip: true
                },
                params: {
                    modalPage: '1'
                },
                onEnter: ['$uibModal', '$state', '$stateParams', function ($uibModal, $state, $stateParams) {
                    $uibModal.open({
                        templateUrl: '/modules/user/reports/engineering-risk/modals/base.html',
                        size: 'xl',
                        controller: ['$state', '$stateParams', '$uibModalInstance', function($state, $stateParams, $uibModalInstance) {
                            var vm = this;
                            vm.selectedTab = $state.current.name.split('.')[$state.current.name.split('.').length - 1];
                            vm.type = $stateParams.modalType;
                            vm.modalInstance = $uibModalInstance;
                            vm.cancel = dismissModal;

                            function dismissModal () {
                                vm.modalInstance.dismiss();
                            }
                        }],
                        controllerAs: 'vm'
                    }).result.finally(function() {
                        $state.go('^.^');
                    });
                }],
                onExit: ['$uibModalStack', function($uibModalStack) {
                    dismissModalOnBack($uibModalStack);
                }]
            })
            .state('dashboard.user.reports.list.view.engineering-risk.modal.jobs', {
                url: '/jobs',
                parent: 'dashboard.user.reports.list.view.engineering-risk.modal',
                params: {
                    modalPage: '1'
                },
                views: {
                    'details@': {
                        templateUrl: '/modules/user/reports/engineering-risk/modals/list.html',
                        controller: 'EngineeringRiskModalController',
                        controllerAs: 'vm'
                    }
                },
                ncyBreadcrumb: {
                    skip: true
                },
                resolve: {
                    jobGroupsCollectionResponse: function(apiClient, rootResourceResponse) {
                        return apiClient.get(rootResourceResponse.getLink('jobgroups'), {limit: 999999});
                    },
                    params: function($stateParams) {
                        var params = {};

                        params.datagroup = 'details';
                        params.completionDueAtBetween = $stateParams.dateRange;
                        params.coreClient = $stateParams.coreClient ? JSON.parse($stateParams.coreClient).v : null;
                        params.site = $stateParams.site ? JSON.parse($stateParams.site).v : null;
                        params.statutoryt = $stateParams.externalReference;
                        params.siteExternalReference = $stateParams.externalReference;
                        params.siteGroup = $stateParams.siteGroup ? JSON.parse($stateParams.siteGroup).v : null;
                        params.serviceProviderGroup = $stateParams.serviceProviderGroup ? JSON.parse($stateParams.serviceProviderGroup).v : null;
                        params.serviceProvider = $stateParams.serviceProvider ? JSON.parse($stateParams.serviceProvider).v : null;
                        params.rfmId = $stateParams.rfmId ? JSON.parse($stateParams.rfmId).v : null;
                        params.jobGroup = $stateParams.modalJobGroup ? JSON.parse($stateParams.modalJobGroup).v : null;

                        if ($stateParams.modalSite) {
                            params.site = $stateParams.modalSite;
                        }
                        if ($stateParams.modalStatutoryType && $stateParams.modalStatutoryType > 0) {
                            params.statutoryType = $stateParams.modalStatutoryType;
                        } else {
                            params.statutory = true;
                        }

                        params.page = $stateParams.modalPage;
                        params.type = 'planned';
                        params.riskReportType = $stateParams.modalType;

                        return params;
                    },
                    collection: function(apiClient, user, params, $stateParams) {
                        return apiClient.get(
                            user.getLink('jobs'),
                            params
                        ).then(function(response) {
                            return response || apiClient.noResourceFound();
                        });
                    }
                }
            })
        ;
    }

    function registerSPUsageReportRoutes($stateProvider) {
        $stateProvider
            .state('dashboard.user.reports.list.view.sp-usage', {
                url: '/sp-usage?reportType&dateRange&reportTab',
                parent: 'dashboard.user.reports.list.view',
                params: {
                    site: null,
                    serviceProvider: null,
                    dateRange: dateRangeMinus1Day,
                    reportType: 'online'
                },
                ncyBreadcrumb: {
                    label: '{{ "SP_USAGE_REPORT" | translate }}'
                },
                views: parseBook({
                    'elogbooks-right@dashboard.user.reports': {
                        templateUrl: '/modules/user/reports/sp-usage/sp-usage.html',
                        controller: 'UserReportsSPUsageController',
                        controllerAs: 'vm'
                    }
                }),
                resolve: {
                    serviceProviderCollectionResponse: function (apiClient, user) {
                        return apiClient.get(user.getLink('serviceproviders')).then(function (response) {
                            return response || apiClient.noResourceFound();
                        });
                    },
                    siteCollectionResponse: function (apiClient, user, $stateParams) {
                        return apiClient.get(user.getLink('sites'), $stateParams).then(function (response) {
                            return response || apiClient.noResourceFound();
                        });
                    }
                }

            })
            .state('dashboard.user.reports.list.view.sp-usage.online', {
                url: '/online?usagePage&usageLimit',
                parent: 'dashboard.user.reports.list.view.sp-usage',
                abstract: true,
                params: {
                    usagePage: '1',
                    usageLimit: '30'
                },
                resolve: {
                    countCollectionResponse: function(apiClient, $stateParams) {
                        return apiClient.get('/reports/sp-usage-totals-count', $stateParams).then(function (response) {
                            return response || apiClient.noResourceFound();
                        });
                    }
                },
                views: {
                    'elogbooks-right@dashboard.user.reports.list.view.sp-usage': {
                        templateUrl: '/modules/user/reports/sp-usage/online/tabs.html',
                        controller: 'SPUsageTabsController',
                        controllerAs: 'vm'
                    }
                },
                ncyBreadcrumb: {
                    label: '{{ "ONLINE" | translate }}'
                }
            })
            .state('dashboard.user.reports.list.view.sp-usage.online.missed-reactive', {
                url: '/missed-reactive',
                parent: 'dashboard.user.reports.list.view.sp-usage.online',
                params: {
                    reportTab: 'missed-reactive'
                },
                views: parseBook({
                    'missed-reactive': {
                        templateUrl: '/modules/user/reports/sp-usage/online/missed-reactive/missed-reactive.html',
                        controller: 'SPUsageController',
                        controllerAs: 'vm'
                    }
                }),
                resolve: {
                    reportCollection: function (apiClient, user, $stateParams) {
                        return apiClient.get('/reports/sp-usage', $stateParams).then(function (response) {
                            return response || apiClient.noResourceFound();
                        });
                    }
                },
                ncyBreadcrumb: {
                    label: '{{ ::"MISSED_REACTIVE_SLA" | translate }}'
                }
            })
            .state('dashboard.user.reports.list.view.sp-usage.online.missed-ppms', {
                url: '/missed-ppms',
                parent: 'dashboard.user.reports.list.view.sp-usage.online',
                params: {
                    reportTab: 'missed-ppms'
                },
                views: parseBook({
                    'missed-ppms': {
                        templateUrl: '/modules/user/reports/sp-usage/online/missed-ppms/missed-ppms.html',
                        controller: 'SPUsageController',
                        controllerAs: 'vm'
                    }
                }),
                resolve: {
                    reportCollection: function (apiClient, user, $stateParams) {
                        return apiClient.get('/reports/sp-usage', $stateParams).then(function (response) {
                            return response || apiClient.noResourceFound();
                        });
                    }
                },
                ncyBreadcrumb: {
                    label: '{{ ::"MISSED_PPMS" | translate }}'
                }
            })
            .state('dashboard.user.reports.list.view.sp-usage.online.late-quotes', {
                url: '/late-quotes',
                parent: 'dashboard.user.reports.list.view.sp-usage.online',
                params: {
                    reportTab: 'late-quotes'
                },
                views: parseBook({
                    'late-quotes': {
                        templateUrl: '/modules/user/reports/sp-usage/online/late-quotes/late-quotes.html',
                        controller: 'SPUsageController',
                        controllerAs: 'vm'
                    }
                }),
                resolve: {
                    reportCollection: function (apiClient, user, $stateParams) {
                        return apiClient.get('/reports/sp-usage', $stateParams).then(function (response) {
                            return response || apiClient.noResourceFound();
                        });
                    }
                },
                ncyBreadcrumb: {
                    label: '{{ ::"LATE_QUOTES" | translate }}'
                }
            })
            .state('dashboard.user.reports.list.view.sp-usage.online.escalated-jobs', {
                url: '/escalated-jobs',
                parent: 'dashboard.user.reports.list.view.sp-usage.online',
                params: {
                    reportTab: 'escalated-jobs'
                },
                views: parseBook({
                    'escalated-jobs': {
                        templateUrl: '/modules/user/reports/sp-usage/online/escalated-jobs/escalated-jobs.html',
                        controller: 'SPUsageController',
                        controllerAs: 'vm'
                    }
                }),
                resolve: {
                    reportCollection: function (apiClient, user, $stateParams) {
                        return apiClient.get('/reports/sp-usage', $stateParams).then(function (response) {
                            return response || apiClient.noResourceFound();
                        });
                    }
                },
                ncyBreadcrumb: {
                    label: '{{ ::"ESCALATED_JOBS" | translate }}'
                }
            })
            .state('dashboard.user.reports.list.view.sp-usage.online.reactive-jobs', {
                url: '/reactive-jobs',
                parent: 'dashboard.user.reports.list.view.sp-usage.online',
                params: {
                    reportTab: 'reactive-jobs'
                },
                views: parseBook({
                    'reactive-jobs': {
                        templateUrl: '/modules/user/reports/sp-usage/online/reactive-jobs/reactive-jobs.html',
                        controller: 'SPUsageController',
                        controllerAs: 'vm'
                    }
                }),
                resolve: {
                    reportCollection: function (apiClient, user, $stateParams) {
                        return apiClient.get('/reports/sp-usage', $stateParams).then(function (response) {
                            return response || apiClient.noResourceFound();
                        });
                    }
                },
                ncyBreadcrumb: {
                    label: '{{ ::"REACTIVE_JOBS" | translate }}'
                }
            });
    }

    function registerResidentialLocationReportRoutes($stateProvider) {
        $stateProvider
            .state('dashboard.user.reports.list.view.residential-location', {
                url: '/residential-location?sectorId&regionId&groupId',
                parent: 'dashboard.user.reports.list.view',
                params: {
                    sectorId: null,
                    regionId: null,
                    groupId: null
                },
                ncyBreadcrumb: {
                    label: '{{ "RESIDENTIAL_LOCATION" | translate }}'
                },
                views: parseBook({
                    'elogbooks-right@dashboard.user.reports': {
                        templateUrl: '/modules/user/reports/residential-location/residential-location.html',
                        controller: 'UserReportsResidentialLocationController',
                        controllerAs: 'vm'
                    }
                }),
                resolve: {
                    sectorCollectionResponse: function(apiClient, rootResourceResponse) {
                        return apiClient.get(rootResourceResponse.getLink('sectors')).then(function(response) {
                            return response || apiClient.noResourceFound();
                        });
                    },
                    regionCollectionResponse: function(apiClient, rootResourceResponse) {
                        return apiClient.get(rootResourceResponse.getLink('regions')).then(function(response) {
                            return response || apiClient.noResourceFound();
                        });
                    },
                    siteGroupsCollectionResponse: function(apiClient, rootResourceResponse) {
                        return apiClient.get(rootResourceResponse.getLink('sitegroups')).then(function(response) {
                            return response || apiClient.noResourceFound();
                        });
                    }
                }

            });
    }

    function registerOperativePerformanceReportRoutes($stateProvider) {
        $stateProvider
            .state('dashboard.user.reports.list.view.operative-performance', {
                url: '/operativePerformance?dateRange',
                parent: 'dashboard.user.reports.list.view',
                views: parseBook({
                    'elogbooks-right@dashboard.user.reports': {
                        templateUrl: '/modules/user/reports/operative-performance/operative-performance-details.html',
                        controller: 'UserReportsOperativePerformanceDetailsController',
                        controllerAs: 'vm'
                    }
                }),
                resolve: {
                    serviceProviderCollectionResponse: function($stateParams, apiClient, user, userManager, selectedSiteResponse) {
                        if (!user.getLink('serviceproviders')) {
                            return null;
                        }

                        var params = {};

                        if (selectedSiteResponse) {
                            params.siteId = selectedSiteResponse.id;
                        }

                        if (user.getLink('serviceproviders')) {
                            return apiClient.get(user.getLink('serviceproviders'), params).then(function(response) {
                                return response || apiClient.noResourceFound();
                            });
                        }
                    },
                    regionCollectionResponse: function (apiClient, rootResourceResponse) {
                        return apiClient.get(rootResourceResponse.getLink('regions')).then(function (response) {
                            return response || apiClient.noResourceFound();
                        });
                    },
                    siteCollectionResponse: function(apiClient, user) {
                        return apiClient.get(user.getLink('sites'));
                    },
                    locationCollectionResponse: function (apiClient, $stateParams, user) {
                        if ($stateParams.site !== null) {
                            var selectedSiteId = JSON.parse($stateParams.site).v;
                            var siteIds = [selectedSiteId];

                            // find the site response based on the selected site id
                            return apiClient.get(user.getLink('sites'), {'siteIds[]': siteIds}).then(function (filteredSiteCollectionResponse) {
                                if (filteredSiteCollectionResponse.count === 0) {
                                    return null;
                                }
                                var selectedSiteResponse = filteredSiteCollectionResponse.sites[0];
                                return apiClient.get(selectedSiteResponse.getLink('locations')).then(function (response) {
                                    return response;
                                });
                            });
                        }

                        return null;
                    },
                    siteGroupsCollectionResponse: function(apiClient, rootResourceResponse, $stateParams) {
                        var params = {};

                        if ($stateParams.siteGroup) {
                            params.id = $stateParams.siteGroup;
                        }

                        return apiClient.get(rootResourceResponse.getLink('sitegroups'), params);
                    },
                    operativesCollectionResponse: function(apiClient, $stateParams) {
                        if ($stateParams.serviceProvider) {
                            var params = angular.copy($stateParams);
                            params.serviceProviderId = JSON.parse(params.serviceProvider).v;

                            if (params.site) {
                                params.site = JSON.parse(params.site).v;
                            }

                            if (params.location) {
                                params.location = JSON.parse(params.location).v;
                            }

                            if (params.siteRegionId) {
                                params.siteRegionName = JSON.parse($stateParams.siteRegionId).n;
                            }

                            return apiClient.get('/reports/operative-performance', params).then(function (response) {
                                return response || apiClient.noResourceFound();
                            });
                        } else {
                            return [];
                        }
                    },
                },

                params: {
                    dateRange: dateRangeMinus1Day
                },
                ncyBreadcrumb: {
                    label: '{{ ::"OPERATIVE_PERFORMANCE_REPORT" | translate }}'
                }
            })
            .state('dashboard.user.reports.list.view.operative-performance.details', {
                url: '/{operativeResource}',
                abstract: true,
                parent: 'dashboard.user.reports.list.view.operative-performance',
                resolve: {
                    siteAssociateResponse: function() {
                        return null;
                    },
                    serviceProviderResponse: function() {
                        return null;
                    },
                    operativeResponse: function($stateParams, apiClient) {
                        return apiClient.get($stateParams.operativeResource.decode()).then(function(response) {
                            return response || apiClient.noResourceFound();
                        });
                    },
                    reactiveMissedStatuses: function() {
                        return ['acknowledged', 'commenced', 'awaiting_paperwork', 'extension_pending', 'completed'];
                    },
                    plannedMissedStatuses: function() {
                        return ['acknowledged', 'commenced', 'missed', 'extension_pending', 'completed'];
                    },
                    modalConfig: function($state, serviceProviderResponse, operativeResponse) {
                        return {
                            titlePrefix: operativeResponse ? operativeResponse._links.user.name : serviceProviderResponse.name
                        };
                    }
                },
                ncyBreadcrumb: {
                    skip: true
                },
            })
            .state('dashboard.user.reports.list.view.operative-performance.reactive-attendance', {
                url: '/reactive-attendance',
                parent: 'dashboard.user.reports.list.view.operative-performance.details',
                abstract: true,
                ncyBreadcrumb: {
                    skip: true
                },
                onEnter: ['$uibModal', '$state', '$stateParams', function ($uibModal, $state, $stateParams) {
                    $uibModal.open({
                        templateUrl: '/modules/user/reports/common/modals/reactive-attendance/base.html',
                        size: 'xl',
                        controller: ['$state', function($state) {
                            var vm = this;
                            vm.selectedTab = $state.current.name.split('.')[$state.current.name.split('.').length - 1];
                            vm.parentState = $state.$current.parent.parent.self.parent;
                        }],
                        controllerAs: 'vm'
                    }).result.finally(function() {
                        $state.go('^.^.^');
                    });
                }],
                onExit: ['$uibModalStack', function($uibModalStack) {
                    dismissModalOnBack($uibModalStack);
                }]
            })
            .state('dashboard.user.reports.list.view.operative-performance.reactive-attendance.attended', {
                url: '/attended?modalPage',
                parent: 'dashboard.user.reports.list.view.operative-performance.reactive-attendance',
                params: {
                    modalPage: '1'
                },
                views: {
                    'attended@': {
                        templateUrl: '/modules/user/reports/common/modals/reactive-attendance/reactive-attendance.html',
                        controller: 'ReportsCommonReactiveMissedAttendanceController',
                        controllerAs: 'vm',
                    }
                },
                ncyBreadcrumb: {
                    skip: true
                },
                resolve: {
                    jobParams: function($stateParams, reactiveMissedStatuses, operativeResponse) {
                        return {
                            type: "reactive",
                            overdueAttendanceHistoric: false,
                            serviceProvider: JSON.parse($stateParams.serviceProvider).v,
                            operative: operativeResponse._links.user.id,
                            dateRange: $stateParams.dateRange,
                            page: $stateParams.modalPage,
                            'status[]': reactiveMissedStatuses,
                            siteName: $stateParams.siteName,
                            siteRegionName: $stateParams.siteRegionName,
                            siteGroupName: $stateParams.siteGroupName,
                            site: $stateParams.site ? JSON.parse($stateParams.site).v : null,
                            location: $stateParams.location ? JSON.parse($stateParams.location).v : null
                        };
                    },
                    jobsCollection: function(apiClient, user, jobParams) {
                        return apiClient.get(user.getLink('jobs'), jobParams).then(function(response) {
                            return response || apiClient.noResourceFound();
                        });
                    }
                },
            })
            .state('dashboard.user.reports.list.view.operative-performance.reactive-attendance.missed', {
                url: '/missed?modalPage',
                parent: 'dashboard.user.reports.list.view.operative-performance.reactive-attendance',
                params: {
                    modalPage: '1'
                },
                views: {
                    'missed@': {
                        templateUrl: '/modules/user/reports/common/modals/reactive-attendance/reactive-attendance.html',
                        controller: 'ReportsCommonReactiveMissedAttendanceController',
                        controllerAs: 'vm',
                    }
                },
                ncyBreadcrumb: {
                    skip: true
                },
                resolve: {
                    jobParams: function($stateParams, reactiveMissedStatuses, operativeResponse) {
                        return {
                            type: "reactive",
                            overdueAttendanceHistoric: true,
                            serviceProvider: JSON.parse($stateParams.serviceProvider).v,
                            operative: operativeResponse._links.user.id,
                            dateRange: $stateParams.dateRange,
                            page: $stateParams.modalPage,
                            'status[]': reactiveMissedStatuses,
                            siteName: $stateParams.siteName,
                            siteRegionName: $stateParams.siteRegionName,
                            siteGroupName: $stateParams.siteGroupName,
                            site: $stateParams.site ? JSON.parse($stateParams.site).v : null,
                            location: $stateParams.location ? JSON.parse($stateParams.location).v : null
                        };
                    },
                    jobsCollection: function(apiClient, user, jobParams) {
                        return apiClient.get(user.getLink('jobs'), jobParams).then(function(response) {
                            return response || apiClient.noResourceFound();
                        });
                    }
                },
            })
            .state('dashboard.user.reports.list.view.operative-performance.reactive-completion', {
                url: '/reactive-completion',
                parent: 'dashboard.user.reports.list.view.operative-performance.details',
                abstract: true,
                ncyBreadcrumb: {
                    skip: true
                },
                onEnter: ['$uibModal', '$state', '$stateParams', function ($uibModal, $state, $stateParams) {
                    $uibModal.open({
                        templateUrl: '/modules/user/reports/common/modals/reactive-completion/base.html',
                        size: 'xl',
                        controller: ['$state', function($state) {
                            var vm = this;
                            vm.selectedTab = $state.current.name.split('.')[$state.current.name.split('.').length - 1];
                            vm.parentState = $state.$current.parent.parent.self.parent;
                        }],
                        controllerAs: 'vm'
                    }).result.finally(function() {
                        $state.go('^.^.^');
                    });
                }],
                onExit: ['$uibModalStack', function($uibModalStack) {
                    dismissModalOnBack($uibModalStack);
                }]
            })
            .state('dashboard.user.reports.list.view.operative-performance.reactive-completion.completed', {
                url: '/completed?modalPage',
                parent: 'dashboard.user.reports.list.view.operative-performance.reactive-completion',
                params: {
                    modalPage: '1'
                },
                views: {
                    'completed@': {
                        templateUrl: '/modules/user/reports/common/modals/reactive-completion/reactive-completion.html',
                        controller: 'ReportsCommonReactiveMissedCompletionController',
                        controllerAs: 'vm',
                    }
                },
                ncyBreadcrumb: {
                    skip: true
                },
                resolve: {
                    jobParams: function($stateParams, reactiveMissedStatuses, operativeResponse) {
                        return {
                            type: "reactive",
                            overdueCompletionHistoric: false,
                            serviceProvider: JSON.parse($stateParams.serviceProvider).v,
                            operative: operativeResponse._links.user.id,
                            dateRange: $stateParams.dateRange,
                            'status[]': reactiveMissedStatuses,
                            page: $stateParams.modalPage,
                            siteName: $stateParams.siteName,
                            siteRegionName: $stateParams.siteRegionName,
                            siteGroupName: $stateParams.siteGroupName,
                            site: $stateParams.site ? JSON.parse($stateParams.site).v : null,
                            location: $stateParams.location ? JSON.parse($stateParams.location).v : null
                        };
                    },
                    jobsCollection: function(apiClient, user, jobParams) {
                        return apiClient.get(user.getLink('jobs'), jobParams).then(function(response) {
                            return response || apiClient.noResourceFound();
                        });
                    }
                },
            })
            .state('dashboard.user.reports.list.view.operative-performance.reactive-completion.missed', {
                url: '/missed?modalPage',
                parent: 'dashboard.user.reports.list.view.operative-performance.reactive-completion',
                params: {
                    modalPage: '1'
                },
                views: {
                    'missed@': {
                        templateUrl: '/modules/user/reports/common/modals/reactive-completion/reactive-completion.html',
                        controller: 'ReportsCommonReactiveMissedCompletionController',
                        controllerAs: 'vm',
                    }
                },
                ncyBreadcrumb: {
                    skip: true
                },
                resolve: {
                    jobParams: function($stateParams, reactiveMissedStatuses, operativeResponse) {
                        return {
                            type: "reactive",
                            overdueCompletionHistoric: true,
                            serviceProvider: JSON.parse($stateParams.serviceProvider).v,
                            operative: operativeResponse._links.user.id,
                            dateRange: $stateParams.dateRange,
                            'status[]': reactiveMissedStatuses,
                            page: $stateParams.modalPage,
                            siteName: $stateParams.siteName,
                            siteRegionName: $stateParams.siteRegionName,
                            siteGroupName: $stateParams.siteGroupName,
                            site: $stateParams.site ? JSON.parse($stateParams.site).v : null,
                            location: $stateParams.location ? JSON.parse($stateParams.location).v : null
                        };
                    },
                    jobsCollection: function(apiClient, user, jobParams) {
                        return apiClient.get(user.getLink('jobs'), jobParams).then(function(response) {
                            return response || apiClient.noResourceFound();
                        });
                    }
                }
            })
            .state('dashboard.user.reports.list.view.operative-performance.ppm-attendance', {
                url: '/ppm-attendance',
                parent: 'dashboard.user.reports.list.view.operative-performance.details',
                abstract: true,
                ncyBreadcrumb: {
                    skip: true
                },
                onEnter: ['$uibModal', '$state', '$stateParams', function ($uibModal, $state, $stateParams) {
                    $uibModal.open({
                        templateUrl: '/modules/user/reports/common/modals/sp-op/ppm-attendance/base.html',
                        size: 'xl',
                        controller: ['$state', function($state) {
                            var vm = this;
                            vm.selectedTab = $state.current.name.split('.')[$state.current.name.split('.').length - 1];
                            vm.parentState = $state.$current.parent.parent.self.parent;
                        }],
                        controllerAs: 'vm'
                    }).result.finally(function() {
                        $state.go('^.^.^');
                    });
                }],
                onExit: ['$uibModalStack', function($uibModalStack) {
                    dismissModalOnBack($uibModalStack);
                }]
            })
            .state('dashboard.user.reports.list.view.operative-performance.ppm-attendance.attended', {
                url: '/attended?modalPage',
                parent: 'dashboard.user.reports.list.view.operative-performance.ppm-attendance',
                params: {
                    modalPage: '1'
                },
                views: {
                    'attended@': {
                        templateUrl: '/modules/user/reports/common/modals/sp-op/ppm-attendance/ppm-attendance.html',
                        controller: 'UserReportsPpmMissedAttendanceController',
                        controllerAs: 'vm',
                    }
                },
                ncyBreadcrumb: {
                    skip: true
                },
                resolve: {
                    jobParams: function(operativeResponse, $stateParams, plannedMissedStatuses) {
                        return {
                            type: "planned",
                            serviceProvider: JSON.parse($stateParams.serviceProvider).v,
                            operative: operativeResponse._links.user.id,
                            overdueAttendanceHistoric: false,
                            dateRange: $stateParams.dateRange,
                            'status[]': plannedMissedStatuses,
                            page: $stateParams.modalPage,
                            siteName: $stateParams.siteName,
                            siteRegionName: $stateParams.siteRegionName,
                            siteGroupName: $stateParams.siteGroupName,
                            site: $stateParams.site ? JSON.parse($stateParams.site).v : null,
                            location: $stateParams.location ? JSON.parse($stateParams.location).v : null
                        };
                    },
                    jobsCollection: function(apiClient, user, jobParams) {
                        return apiClient.get(user.getLink('jobs'), jobParams).then(function(response) {
                            return response || apiClient.noResourceFound();
                        });
                    }
                },
            })
            .state('dashboard.user.reports.list.view.operative-performance.ppm-attendance.missed', {
                url: '/missed?modalPage',
                parent: 'dashboard.user.reports.list.view.operative-performance.ppm-attendance',
                params: {
                    modalPage: '1'
                },
                views: {
                    'missed@': {
                        templateUrl: '/modules/user/reports/common/modals/sp-op/ppm-attendance/ppm-attendance.html',
                        controller: 'UserReportsPpmMissedAttendanceController',
                        controllerAs: 'vm',
                    }
                },
                ncyBreadcrumb: {
                    skip: true
                },
                resolve: {
                    jobParams: function(operativeResponse, $stateParams, plannedMissedStatuses) {
                        return {
                            type: "planned",
                            serviceProvider: JSON.parse($stateParams.serviceProvider).v,
                            operative: operativeResponse._links.user.id,
                            overdueAttendanceHistoric: true,
                            dateRange: $stateParams.dateRange,
                            'status[]': plannedMissedStatuses,
                            page: $stateParams.modalPage,
                            siteName: $stateParams.siteName,
                            siteRegionName: $stateParams.siteRegionName,
                            siteGroupName: $stateParams.siteGroupName,
                            site: $stateParams.site ? JSON.parse($stateParams.site).v : null,
                            location: $stateParams.location ? JSON.parse($stateParams.location).v : null
                        };
                    },
                    jobsCollection: function(apiClient, user, jobParams) {
                        return apiClient.get(user.getLink('jobs'), jobParams).then(function(response) {
                            return response || apiClient.noResourceFound();
                        });
                    }
                },
            })
            .state('dashboard.user.reports.list.view.operative-performance.ppm-completion', {
                url: '/ppm-completion',
                parent: 'dashboard.user.reports.list.view.operative-performance.details',
                abstract: true,
                ncyBreadcrumb: {
                    skip: true
                },
                onEnter: ['$uibModal', '$state', '$stateParams', function ($uibModal, $state, $stateParams) {
                    $uibModal.open({
                        templateUrl: '/modules/user/reports/common/modals/sp-op/ppm-completion/base.html',
                        size: 'xl',
                        controller: ['$state', function($state) {
                            var vm = this;
                            vm.selectedTab = $state.current.name.split('.')[$state.current.name.split('.').length - 1];
                            vm.parentState = $state.$current.parent.parent.self.parent;
                        }],
                        controllerAs: 'vm'
                    }).result.finally(function() {
                        $state.go('^.^.^');
                    });
                }],
                onExit: ['$uibModalStack', function($uibModalStack) {
                    dismissModalOnBack($uibModalStack);
                }]
            })
            .state('dashboard.user.reports.list.view.operative-performance.ppm-completion.completed', {
                url: '/completed?modalPage',
                parent: 'dashboard.user.reports.list.view.operative-performance.ppm-completion',
                params: {
                    modalPage: '1'
                },
                views: {
                    'completed@': {
                        templateUrl: '/modules/user/reports/common/modals/sp-op/ppm-completion/ppm-completion.html',
                        controller: 'UserReportsPpmMissedCompletionController',
                        controllerAs: 'vm',
                    }
                },
                ncyBreadcrumb: {
                    skip: true
                },
                resolve: {
                    jobParams: function(operativeResponse, $stateParams, plannedMissedStatuses) {
                        return {
                            type: "planned",
                            serviceProvider: JSON.parse($stateParams.serviceProvider).v,
                            operative: operativeResponse._links.user.id,
                            overdueCompletionHistoric: false,
                            dateRange: $stateParams.dateRange,
                            'status[]': plannedMissedStatuses,
                            page: $stateParams.modalPage,
                            siteName: $stateParams.siteName,
                            siteRegionName: $stateParams.siteRegionName,
                            siteGroupName: $stateParams.siteGroupName,
                            site: $stateParams.site ? JSON.parse($stateParams.site).v : null,
                            location: $stateParams.location ? JSON.parse($stateParams.location).v : null
                        };
                    },
                    jobsCollection: function(apiClient, user, jobParams) {
                        return apiClient.get(user.getLink('jobs'), jobParams).then(function(response) {
                            return response || apiClient.noResourceFound();
                        });
                    }
                },
            })
            .state('dashboard.user.reports.list.view.operative-performance.ppm-completion.missed', {
                url: '/missed?modalPage',
                parent: 'dashboard.user.reports.list.view.operative-performance.ppm-completion',
                params: {
                    modalPage: '1'
                },
                views: {
                    'missed@': {
                        templateUrl: '/modules/user/reports/common/modals/sp-op/ppm-completion/ppm-completion.html',
                        controller: 'UserReportsPpmMissedCompletionController',
                        controllerAs: 'vm',
                    }
                },
                ncyBreadcrumb: {
                    skip: true
                },
                resolve: {
                    jobParams: function(operativeResponse, $stateParams, plannedMissedStatuses) {
                        return {
                            type: "planned",
                            serviceProvider: JSON.parse($stateParams.serviceProvider).v,
                            operative: operativeResponse._links.user.id,
                            overdueCompletionHistoric: true,
                            dateRange: $stateParams.dateRange,
                            'status[]': plannedMissedStatuses,
                            page: $stateParams.modalPage,
                            siteName: $stateParams.siteName,
                            siteRegionName: $stateParams.siteRegionName,
                            siteGroupName: $stateParams.siteGroupName,
                            site: $stateParams.site ? JSON.parse($stateParams.site).v : null,
                            location: $stateParams.location ? JSON.parse($stateParams.location).v : null
                        };
                    },
                    jobsCollection: function(apiClient, user, jobParams) {
                        return apiClient.get(user.getLink('jobs'), jobParams).then(function(response) {
                            return response || apiClient.noResourceFound();
                        });
                    }
                },
            })
            .state('dashboard.user.reports.list.view.operative-performance.quotes-missed', {
                url: '/quotes-missed',
                parent: 'dashboard.user.reports.list.view.operative-performance.details',
                abstract: true,
                ncyBreadcrumb: {
                    skip: true
                },
                onEnter: ['$uibModal', '$state', '$stateParams', function ($uibModal, $state, $stateParams) {
                    $uibModal.open({
                        templateUrl: '/modules/user/reports/common/modals/sp-op/quotes-missed/base.html',
                        size: 'xl',
                        controller: ['$state', function($state) {
                            var vm = this;
                            vm.selectedTab = $state.current.name.split('.')[$state.current.name.split('.').length - 1];
                            vm.parentState = $state.$current.parent.parent.self.parent;
                        }],
                        controllerAs: 'vm'
                    }).result.finally(function() {
                        $state.go('^.^.^');
                    });
                }],
                onExit: ['$uibModalStack', function($uibModalStack) {
                    dismissModalOnBack($uibModalStack);
                }]
            })
            .state('dashboard.user.reports.list.view.operative-performance.quotes-missed.completed', {
                url: '/completed?modalPage',
                parent: 'dashboard.user.reports.list.view.operative-performance.quotes-missed',
                params: {
                    modalPage: '1'
                },
                views: {
                    'completed@': {
                        templateUrl: '/modules/user/reports/common/modals/sp-op/quotes-missed/quotes-missed.html',
                        controller: 'UserReportsQuotesMissedController',
                        controllerAs: 'vm'
                    }
                },
                ncyBreadcrumb: {
                    skip: true
                },
                resolve: {
                    quoteParams: function(operativeResponse, $stateParams) {
                        return {
                            serviceProvider: JSON.parse($stateParams.serviceProvider).v,
                            operative: operativeResponse._links.user.id,
                            dateRange: $stateParams.dateRange,
                            overdueCompletionHistoric: false,
                            siteName: $stateParams.siteName,
                            siteRegionName: $stateParams.siteRegionName,
                            siteGroupName: $stateParams.siteGroupName,
                            'excludedStatuses[]' : 'cancelled',
                            page: $stateParams.modalPage,
                            site: $stateParams.site ? JSON.parse($stateParams.site).v : null,
                            location: $stateParams.location ? JSON.parse($stateParams.location).v : null
                        };
                    },
                    quotesCollection: function(apiClient, user, quoteParams) {
                        return apiClient.get(user.getLink('quotes'), quoteParams).then(function(response) {
                            return response || apiClient.noResourceFound();
                        });
                    }
                }
            })
            .state('dashboard.user.reports.list.view.operative-performance.quotes-missed.missed', {
                url: '/missed?modalPage',
                parent: 'dashboard.user.reports.list.view.operative-performance.quotes-missed',
                params: {
                    modalPage: '1'
                },
                views: {
                    'missed@': {
                        templateUrl: '/modules/user/reports/common/modals/sp-op/quotes-missed/quotes-missed.html',
                        controller: 'UserReportsQuotesMissedController',
                        controllerAs: 'vm'
                    }
                },
                ncyBreadcrumb: {
                    skip: true
                },
                resolve: {
                    quoteParams: function(operativeResponse, $stateParams) {
                        return {
                            serviceProvider: JSON.parse($stateParams.serviceProvider).v,
                            operative: operativeResponse._links.user.id,
                            dateRange: $stateParams.dateRange,
                            overdueCompletionHistoric: true,
                            siteName: $stateParams.siteName,
                            siteRegionName: $stateParams.siteRegionName,
                            siteGroupName: $stateParams.siteGroupName,
                            'excludedStatuses[]' : 'cancelled',
                            page: $stateParams.modalPage,
                            site: $stateParams.site ? JSON.parse($stateParams.site).v : null,
                            location: $stateParams.location ? JSON.parse($stateParams.location).v : null
                        };
                    },
                    quotesCollection: function(apiClient, user, quoteParams) {
                        return apiClient.get(user.getLink('quotes'), quoteParams).then(function(response) {
                            return response || apiClient.noResourceFound();
                        });
                    }
                }
            })
            .state('dashboard.user.reports.list.view.operative-performance.escalated', {
                url: '/escalated?escalationJobsPage&escalationJobsLimit&escalationQuotesPage&escalationQuotesLimit',
                parent: 'dashboard.user.reports.list.view.operative-performance.details',
                params: {
                    escalationJobsPage: '1',
                    escalationJobsLimit: '30',
                    escalationQuotesPage: '1',
                    escalationQuotesLimit: '30'
                },
                abstract: true,
                ncyBreadcrumb: {
                    skip: true
                },
                onEnter: ['$uibModal', '$state', '$stateParams', function ($uibModal, $state, $stateParams) {
                    $uibModal.open({
                        templateUrl: '/modules/user/reports/common/modals/sp-op/tabbed-base.html',
                        size: 'xl',
                        controller: ['$state', function($state) {
                            var vm = this;
                            vm.selectedTab = $state.current.name.split('.')[$state.current.name.split('.').length - 1];
                            vm.parentState = $state.$current.parent.parent.self.parent;
                            vm.tabs = [
                                {name:'Attend',view:'attend'},
                                {name:'Complete',view:'complete'}
                            ];
                        }],
                        controllerAs: 'vm'
                    }).result.finally(function() {
                        $state.go('^.^.^');
                    });
                }],
                onExit: ['$uibModalStack', function($uibModalStack) {
                    dismissModalOnBack($uibModalStack);
                }]
            })
            .state('dashboard.user.reports.list.view.operative-performance.escalated.jobs', {
                url: '/jobs?modalPage',
                parent: 'dashboard.user.reports.list.view.operative-performance.escalated',
                params: {
                    modalPage: '1'
                },
                views: {
                    'jobs@': {
                        templateUrl: '/modules/user/reports/common/modals/sp-op/escalated/jobs.html',
                        controller: 'UserReportsEscalatedJobsController',
                        controllerAs: 'vm'
                    }
                },
                ncyBreadcrumb: {
                    skip: true
                },
                resolve: {
                    jobParams: function(operativeResponse, $stateParams) {
                        return {
                            page: $stateParams.escalationJobsPage,
                            limit: $stateParams.escalationJobsLimit,
                            datagroup: 'reports',
                            allEscalated: 1,
                            dateRange: $stateParams.dateRange,
                            serviceProvider: JSON.parse($stateParams.serviceProvider).v,
                            operative: operativeResponse._links.user.id,
                            siteName: $stateParams.siteName,
                            siteRegionName: $stateParams.siteRegionName,
                            siteGroupName: $stateParams.siteGroupName,
                            site: $stateParams.site ? JSON.parse($stateParams.site).v : null,
                            location: $stateParams.location ? JSON.parse($stateParams.location).v : null
                        };
                    },
                    jobsCollection: function(apiClient, jobParams, user) {
                        return apiClient.get(user.getLink('jobs'), jobParams).then(function(response) {
                            return response || apiClient.noResourceFound();
                        });
                    }
                }
            })
            .state('dashboard.user.reports.list.view.operative-performance.escalated.quotes', {
                url: '/quotes?modalPage',
                parent: 'dashboard.user.reports.list.view.operative-performance.escalated',
                params: {
                    modalPage: '1'
                },
                views: {
                    'quotes@': {
                        templateUrl: '/modules/user/reports/common/modals/sp-op/escalated/quotes.html',
                        controller: 'UserReportsEscalatedQuotesController',
                        controllerAs: 'vm'
                    }
                },
                ncyBreadcrumb: {
                    skip: true
                },
                resolve: {
                    quoteParams: function(operativeResponse, $stateParams) {
                        return {
                            page: $stateParams.escalationQuotesPage,
                            limit: $stateParams.escalationQuotesLimit,
                            datagroup: 'reports',
                            allEscalated: 1,
                            dateRange: $stateParams.dateRange,
                            serviceProvider: JSON.parse($stateParams.serviceProvider).v,
                            operative: operativeResponse._links.user.id,
                            siteName: $stateParams.siteName,
                            siteRegionName: $stateParams.siteRegionName,
                            siteGroupName: $stateParams.siteGroupName,
                            site: $stateParams.site ? JSON.parse($stateParams.site).v : null,
                            location: $stateParams.location ? JSON.parse($stateParams.location).v : null
                        };
                    },
                    quotesCollection: function(apiClient, quoteParams, user) {
                        return apiClient.get(user.getLink('quotes'), quoteParams).then(function(response) {
                            return response || apiClient.noResourceFound();
                        });
                    }
                }
            })
            .state('dashboard.user.reports.list.view.operative-performance.extension-requests', {
                url: '/extension-requests',
                parent: 'dashboard.user.reports.list.view.operative-performance.details',
                abstract: true,
                ncyBreadcrumb: {
                    skip: true
                },
                onEnter: ['$uibModal', '$state', '$stateParams', function ($uibModal, $state, $stateParams) {
                    $uibModal.open({
                        templateUrl: '/modules/user/reports/common/modals/sp-op/tabbed-base.html',
                        size: 'xl',
                        controller: ['$state', function($state) {
                            var vm = this;
                            vm.selectedTab = $state.current.name.split('.')[$state.current.name.split('.').length - 1];
                            vm.parentState = $state.$current.parent.parent.self.parent;
                            vm.tabs = [
                                {name:'Jobs',view:'jobs'},
                                {name:'Quotes',view:'quotes'}
                            ];
                        }],
                        controllerAs: 'vm'
                    }).result.finally(function() {
                        $state.go('dashboard.user.reports.list.view.operative-performance');
                    });
                }],
                onExit: ['$uibModalStack', function($uibModalStack) {
                    dismissModalOnBack($uibModalStack);
                }],
            })
            .state('dashboard.user.reports.list.view.operative-performance.extension-requests.jobs', {
                url: '/jobs?modalPage&modalLimit&priority',
                parent: 'dashboard.user.reports.list.view.operative-performance.extension-requests',
                params: {
                    modalPage: '1',
                    modalLimit: '9999',
                    priority: null
                },
                views: {
                    'jobs@': {
                        templateUrl: '/modules/user/reports/common/modals/sp-op/extension-requests/priorities.html',
                        controller: 'UserReportsExtensionRequestsPrioritiesController',
                        controllerAs: 'vm',
                    }
                },
                ncyBreadcrumb: {
                    skip: true
                },
                resolve: {
                    prioritiesCollection: function(apiClient, $stateParams, operativeResponse, operativesCollectionResponse) {
                        return apiClient.get(operativesCollectionResponse.getLink('priorities-by-operative'), {
                            datagroup: 'reports',
                            dateRange: $stateParams.dateRange,
                            serviceProvider: JSON.parse($stateParams.serviceProvider).v,
                            operative: operativeResponse._links.user.id,
                            siteGroupName: $stateParams.siteGroupName,
                            siteRegionName: $stateParams.siteRegionName,
                            entityType: 'jobs',
                            page: $stateParams.modalPage,
                            limit: $stateParams.modalLimit,
                            site: $stateParams.site ? JSON.parse($stateParams.site).v : null,
                            location: $stateParams.location ? JSON.parse($stateParams.location).v : null
                        });
                    }
                }
            })
            .state('dashboard.user.reports.list.view.operative-performance.extension-requests.jobs.details', {
                url: '/{priorityResource}/{status}/details?entityModalPage&entityModalLimit',
                parent: 'dashboard.user.reports.list.view.operative-performance.extension-requests.jobs',
                params: {
                    entityModalPage: '1',
                    entityModalLimit: '10'
                },
                views: {
                    'details@': {
                        templateUrl: '/modules/user/reports/common/modals/sp-op/extension-requests/jobs.html',
                        controller: 'UserReportsExtensionRequestsJobsController',
                        controllerAs: 'vm',
                    }
                },
                ncyBreadcrumb: {
                    skip: true
                },
                resolve: {
                    jobParams: function(operativeResponse, $stateParams) {
                        return {
                            page: $stateParams.entityModalPage,
                            limit: $stateParams.entityModalLimit,
                            datagroup: 'reports',
                            extensionRequests: $stateParams.status,
                            dateRange: $stateParams.dateRange,
                            serviceProvider: JSON.parse($stateParams.serviceProvider).v,
                            operative: operativeResponse._links.user.id,
                            siteName: $stateParams.siteName,
                            siteRegionName: $stateParams.siteRegionName,
                            siteGroupName: $stateParams.siteGroupName,
                            priority: $stateParams.priority,
                            'excludedStatuses[]': 'cancelled',
                            site: $stateParams.site ? JSON.parse($stateParams.site).v : null,
                            location: $stateParams.location ? JSON.parse($stateParams.location).v : null
                        };
                    },
                    jobsCollection: function(apiClient, jobParams, user) {
                        return apiClient.get(user.getLink('jobs'), jobParams).then(function(response) {
                            return response || apiClient.noResourceFound();
                        });
                    }
                }
            })
            .state('dashboard.user.reports.list.view.operative-performance.extension-requests.jobs.total', {
                url: '/{priorityResource}/total?entityModalPage&entityModalLimit',
                parent: 'dashboard.user.reports.list.view.operative-performance.extension-requests.jobs',
                params: {
                    entityModalPage: '1',
                    entityModalLimit: '10'
                },
                views: {
                    'details@': {
                        templateUrl: '/modules/user/reports/common/modals/sp-op/extension-requests/jobs-total.html',
                        controller: 'UserReportsExtensionRequestsJobsController',
                        controllerAs: 'vm',
                    }
                },
                ncyBreadcrumb: {
                    skip: true
                },
                resolve: {
                    jobParams: function(operativeResponse, $stateParams) {
                        return {
                            page: $stateParams.entityModalPage,
                            limit: $stateParams.entityModalLimit,
                            datagroup: 'reports',
                            extensionRequests: 'total',
                            dateRange: $stateParams.dateRange,
                            serviceProvider: JSON.parse($stateParams.serviceProvider).v,
                            operative: operativeResponse._links.user.id,
                            siteName: $stateParams.siteName,
                            siteRegionName: $stateParams.siteRegionName,
                            siteGroupName: $stateParams.siteGroupName,
                            priority: $stateParams.priority,
                            'excludedStatuses[]': 'cancelled',
                            site: $stateParams.site ? JSON.parse($stateParams.site).v : null,
                            location: $stateParams.location ? JSON.parse($stateParams.location).v : null
                        };
                    },
                    jobsCollection: function(apiClient, jobParams, user) {
                        return apiClient.get(user.getLink('jobs'), jobParams).then(function(response) {
                            return response || apiClient.noResourceFound();
                        });
                    }
                }
            })
            .state('dashboard.user.reports.list.view.operative-performance.extension-requests.quotes', {
                url: '/quotes?modalPage&modalLimit&priority',
                parent: 'dashboard.user.reports.list.view.operative-performance.extension-requests',
                params: {
                    modalPage: '1',
                    modalLimit: '9999',
                    priority: null
                },
                ncyBreadcrumb: {
                    skip: true
                },
                views: {
                    'quotes@': {
                        templateUrl: '/modules/user/reports/common/modals/sp-op/extension-requests/priorities.html',
                        controller: 'UserReportsExtensionRequestsPrioritiesController',
                        controllerAs: 'vm',
                    }
                },
                resolve: {
                    prioritiesCollection: function(apiClient, $stateParams, operativeResponse, operativesCollectionResponse) {
                        return apiClient.get(operativesCollectionResponse.getLink('priorities-by-operative'), {
                            datagroup: 'reports',
                            dateRange: $stateParams.dateRange,
                            serviceProvider: JSON.parse($stateParams.serviceProvider).v,
                            operative: operativeResponse._links.user.id,
                            entityType: 'quotes',
                            limit: $stateParams.modalLimit
                        });
                    }
                }
            })
            .state('dashboard.user.reports.list.view.operative-performance.extension-requests.quotes.details', {
                url: '/{priorityResource}/{status}/details?entityModalPage&quotsModalLimit',
                parent: 'dashboard.user.reports.list.view.operative-performance.extension-requests.quotes',
                params: {
                    entityModalPage: '1',
                    entityModalLimit: '10'
                },
                views: {
                    'details@': {
                        templateUrl: '/modules/user/reports/common/modals/sp-op/extension-requests/quotes.html',
                        controller: 'UserReportsExtensionRequestsQuotesController',
                        controllerAs: 'vm',
                    }
                },
                ncyBreadcrumb: {
                    skip: true
                },
                resolve: {
                    quoteParams: function(operativeResponse, $stateParams) {
                        return {
                            datagroup: 'reports',
                            serviceProvider: JSON.parse($stateParams.serviceProvider).v,
                            operative: operativeResponse._links.user.id,
                            dateRange: $stateParams.dateRange,
                            extensionRequests: $stateParams.status,
                            siteName: $stateParams.siteName,
                            siteRegionName: $stateParams.siteRegionName,
                            siteGroupName: $stateParams.siteGroupName,
                            priority: $stateParams.priority,
                            page: $stateParams.entityModalPage,
                            limit: $stateParams.entityModalLimit,
                            'excludedStatuses[]': 'cancelled',
                            site: $stateParams.site ? JSON.parse($stateParams.site).v : null,
                            location: $stateParams.location ? JSON.parse($stateParams.location).v : null
                        };
                    },
                    quotesCollection: function(apiClient, user, quoteParams) {
                        return apiClient.get(user.getLink('quotes'), quoteParams).then(function(response) {
                            return response || apiClient.noResourceFound();
                        });
                    }
                }
            })
            .state('dashboard.user.reports.list.view.operative-performance.extension-requests.quotes.total', {
                url: '/{priorityResource}/{status}/total?entityModalPage&entityModalLimit',
                parent: 'dashboard.user.reports.list.view.operative-performance.extension-requests.quotes',
                params: {
                    entityModalPage: '1',
                    entityModalLimit: '10'
                },
                views: {
                    'details@': {
                        templateUrl: '/modules/user/reports/common/modals/sp-op/extension-requests/quotes-total.html',
                        controller: 'UserReportsExtensionRequestsQuotesController',
                        controllerAs: 'vm'
                    }
                },
                ncyBreadcrumb: {
                    skip: true
                },
                resolve: {
                    quoteParams: function(operativeResponse, $stateParams) {
                        return {
                            datagroup: 'reports',
                            serviceProvider: JSON.parse($stateParams.serviceProvider).v,
                            operative: operativeResponse._links.user.id,
                            dateRange: $stateParams.dateRange,
                            extensionRequests: 'total',
                            siteName: $stateParams.siteName,
                            siteRegionName: $stateParams.siteRegionName,
                            siteGroupName: $stateParams.siteGroupName,
                            priority: $stateParams.priority,
                            page: $stateParams.entityModalPage,
                            limit: $stateParams.entityModalLimit,
                            'excludedStatuses[]': 'cancelled',
                            site: $stateParams.site ? JSON.parse($stateParams.site).v : null,
                            location: $stateParams.location ? JSON.parse($stateParams.location).v : null
                        };
                    },
                    quotesCollection: function(apiClient, user, quoteParams) {
                        return apiClient.get(user.getLink('quotes'), quoteParams).then(function(response) {
                            return response || apiClient.noResourceFound();
                        });
                    }
                }
            })
            .state('dashboard.user.reports.list.view.operative-performance.reopened-jobs', {
                url: '/reopened-jobs',
                parent: 'dashboard.user.reports.list.view.operative-performance.details',
                abstract: true,
                ncyBreadcrumb: {
                    skip: true
                },
                onEnter: ['$uibModal', '$state', '$stateParams', function ($uibModal, $state, $stateparams) {
                    $uibModal.open({
                        templateUrl: '/modules/user/reports/common/modals/sp-op/base.html',
                        controller: ['$state', function($state) {
                            var vm = this;
                            vm.parentState = $state.$current.parent.parent.self.parent;
                        }],
                        size: 'xl',
                        controllerAs: 'vm'
                    }).result.finally(function() {
                        $state.go('^.^.^');
                    });
                }],
                onExit: ['$uibModalStack', function($uibModalStack) {
                    dismissModalOnBack($uibModalStack);
                }]
            })
            .state('dashboard.user.reports.list.view.operative-performance.reopened-jobs.modal', {
                url: '/view?modalPage',
                parent: 'dashboard.user.reports.list.view.operative-performance.reopened-jobs',
                params: {
                    modalPage: '1'
                },
                ncyBreadcrumb: {
                    skip: true
                },
                views: {
                    'report-modal@': {
                        templateUrl: '/modules/user/reports/common/modals/sp-op/jobs-reopened.html',
                        controller: 'UserReportsJobsReopenedController',
                        controllerAs: 'vm',
                    }
                },
                resolve: {
                    jobParams: function(operativeResponse, $stateParams) {
                        return {
                            page: $stateParams.modalPage,
                            limit: $stateParams.modalLimit,
                            datagroup: 'reports',
                            dateRange: $stateParams.dateRange,
                            serviceProvider: JSON.parse($stateParams.serviceProvider).v,
                            operative: operativeResponse._links.user.id,
                            siteName: $stateParams.siteName,
                            siteRegionName: $stateParams.siteRegionName,
                            siteGroupName: $stateParams.siteGroupName,
                            reopened: 'true',
                            site: $stateParams.site ? JSON.parse($stateParams.site).v : null,
                            location: $stateParams.location ? JSON.parse($stateParams.location).v : null
                        };
                    },
                    jobsCollection: function(apiClient, jobParams, user) {
                        return apiClient.get(user.getLink('jobs'), jobParams).then(function(response) {
                            return response || apiClient.noResourceFound();
                        });
                    }
               },
            })
            .state('dashboard.user.reports.list.view.operative-performance.paperwork-awaiting', {
                url: '/paperwork-awaiting',
                parent: 'dashboard.user.reports.list.view.operative-performance.details',
                abstract: true,
                ncyBreadcrumb: {
                    skip: true
                },
                onEnter: ['$uibModal', '$state', '$stateParams', function ($uibModal, $state, $stateparams) {
                    $uibModal.open({
                        templateUrl: '/modules/user/reports/common/modals/sp-op/base.html',
                        controller: ['$state', function($state) {
                            var vm = this;
                            vm.parentState = $state.$current.parent.parent.self.parent;
                        }],
                        size: 'xl',
                        controllerAs: 'vm'
                    }).result.finally(function() {
                        $state.go('^.^.^');
                    });
                }],
                onExit: ['$uibModalStack', function($uibModalStack) {
                    dismissModalOnBack($uibModalStack);
                }]
            })
            .state('dashboard.user.reports.list.view.operative-performance.paperwork-awaiting.modal', {
                url: '/view?modalPage',
                parent: 'dashboard.user.reports.list.view.operative-performance.paperwork-awaiting',
                params: {
                    modalPage: '1'
                },
                ncyBreadcrumb: {
                    skip: true
                },
                views: {
                    'report-modal@': {
                        templateUrl: '/modules/user/reports/common/modals/sp-op/paperwork-awaiting.html',
                        controller: 'UserReportsPaperworkAwaitingController',
                        controllerAs: 'vm',
                    }
                },
                resolve: {
                    jobParams: function(operativeResponse, $stateParams) {
                        return {
                            page: $stateParams.modalPage,
                            limit: $stateParams.modalLimit,
                            datagroup: 'reports',
                            dateRange: $stateParams.dateRange,
                            serviceProvider: JSON.parse($stateParams.serviceProvider).v,
                            operative: operativeResponse._links.user.id,
                            siteName: $stateParams.siteName,
                            siteRegionName: $stateParams.siteRegionName,
                            siteGroupName: $stateParams.siteGroupName,
                            awaitingPaperwork: 1,
                            hours: $stateParams.hours,
                            site: $stateParams.site ? JSON.parse($stateParams.site).v : null,
                            location: $stateParams.location ? JSON.parse($stateParams.location).v : null
                        };
                    },
                    jobsCollection: function(apiClient, jobParams, user) {
                        return apiClient.get(user.getLink('jobs'), jobParams).then(function(response) {
                            return response || apiClient.noResourceFound();
                        });
                    }
                },
            });
    }

    function registerServiceProviderFeedbackReportRoutes($stateProvider) {
        $stateProvider
            .state('dashboard.user.reports.list.view.service-provider-feedback', {
                url: '/serviceProviderFeedback?spFeedbackReportPage&spFeedbackReportLimit&status&serviceProviderGroup&scoringDateRange&scoringRangeOption&ratingStatus',
                parent: 'dashboard.user.reports.list.view',
                views: parseBook({
                    'elogbooks-right@dashboard.user.reports': {
                        templateUrl: '/modules/user/reports/service-provider-feedback/service-provider-feedback.html',
                        controller: 'UserReportsServiceProviderFeedbackController',
                        controllerAs: 'vm'
                    }
                }),
                params: {
                    spFeedbackReportLimit: '30',
                    spFeedbackReportPage: '1',
                    listOrder: 'name',
                    status: 'active',
                    ratingStatus: 'has_ratings',
                    name: null,
                    serviceProviderGroups: [],
                    scoringDateRange: null,
                    scoringRangeOption: null,
                },
                data: {
                    permissions: ['user_permission_report_service_provider_feedback_ratings']
                },
                resolve: {
                    defaultValueResponse: function() {
                        const obj = {
                            defaultRangeOption: 3
                        };

                        const startDate = moment().subtract(obj.defaultRangeOption, 'months').startOf('day');
                        const endDate = moment().endOf('day');
                        obj.clearDateRangeValue = startDate.toISOString() + ',' + endDate.toISOString();

                        return obj;
                    },
                    serviceProviderFeedbackScoreResponse: function(apiClient, rootResourceResponse, $stateParams, defaultValueResponse) {
                        const params = {
                            page: $stateParams.spFeedbackReportPage,
                            limit: $stateParams.spFeedbackReportLimit,
                            datagroup: 'reports',
                            order: $stateParams.listOrder,
                            status: $stateParams.status,
                            ratingStatus: $stateParams.ratingStatus,
                            name: $stateParams.name,
                            serviceProviderGroup: $stateParams.serviceProviderGroups ? JSON.parse($stateParams.serviceProviderGroups).v : null,
                            scoringDateRange: $stateParams.scoringDateRange ? $stateParams.scoringDateRange : defaultValueResponse.clearDateRangeValue,
                            scoringRangeOption: $stateParams.scoringRangeOption ? $stateParams.scoringRangeOption : defaultValueResponse.defaultRangeOption
                        };

                        return apiClient.get(rootResourceResponse.getLink('service_provider_feedback_report'), params).then(function(response) {
                            return response || apiClient.noResourceFound();
                        });
                    },
                    serviceProviderGroupsResponse: function(apiClient, rootResourceResponse) {
                        return apiClient.get(rootResourceResponse.getLink('serviceprovidergroups')).then(function(response) {
                            return response || apiClient.noResourceFound();
                        });
                    },
                    serviceProviderCollection: function(apiClient, rootResourceResponse) {
                        return apiClient.get(rootResourceResponse.getLink('serviceproviders')).then(function(response) {
                            return response || apiClient.noResourceFound();
                        });
                    }
                },
                ncyBreadcrumb: {
                    label: '{{ ::"SERVICE_PROVIDER_FEEDBACK_RATING_LIST" | translate }}'
                }
            })
            .state('dashboard.user.reports.list.view.service-provider-feedback.details', {
                url: '/serviceProviderScoreFeedbackDetails?spFeedbackReportDetailsLimit&spFeedbackReportDetailsPage&detailsOrder&rating&comments&scoringDateRangeDetails&scoringRangeDetailsOption',
                parent: 'dashboard.user.reports.list.view.service-provider-feedback',
                views: parseBook({
                    'elogbooks-right@dashboard.user.reports': {
                        templateUrl: '/modules/user/reports/service-provider-feedback/service-provider-score-feedback.html',
                        controller: 'UserReportsServiceProviderScoreFeedbackController',
                        controllerAs: 'vm'
                    }
                }),
                params: {
                    spFeedbackReportDetailsLimit: '30',
                    spFeedbackReportDetailsPage: '1',
                    serviceProviderName: null,
                    rating: 'noneSelected',
                    serviceProviderId: null,
                    comments: 'noneSelected',
                    scoringDateRangeDetails: null,
                    scoringRangeDetailsOption: null,
                    detailsOrder: '-datetime'
                },
                data: {
                    permissions: ['user_permission_report_service_provider_feedback_ratings']
                },
                resolve: {
                    serviceProviderScoreDataResponse: function(apiClient, $stateParams, serviceProviderFeedbackScoreResponse, defaultValueResponse) {
                        if ($stateParams.serviceProviderId !== null) {
                            if ($stateParams.serviceProvider === null) {
                                $stateParams.serviceProvider = JSON.stringify({
                                    v: $stateParams.serviceProviderId,
                                    n: $stateParams.serviceProviderName
                                });
                            } else if (JSON.parse($stateParams.serviceProvider).v !== $stateParams.serviceProviderId) {
                                $stateParams.serviceProvider = JSON.stringify({
                                    v: $stateParams.serviceProviderId,
                                    n: $stateParams.serviceProviderName
                                });
                            }
                        }

                        $stateParams.serviceProviderId = null;
                        $stateParams.serviceProviderName = null;

                        var params = {
                            rating: $stateParams.rating,
                            serviceProviderId: $stateParams.serviceProvider ? JSON.parse($stateParams.serviceProvider).v : null,
                            comments: $stateParams.comments,
                            scoringDateDetailsRange: $stateParams.scoringDateRangeDetails,
                            scoringDateRange: $stateParams.scoringRangeDetailsOption ? $stateParams.scoringRangeDetailsOption : defaultValueResponse.clearDateRangeValue,
                            order: $stateParams.detailsOrder,
                            limit: $stateParams.spFeedbackReportDetailsLimit,
                            page: $stateParams.spFeedbackReportDetailsPage
                        };

                        return apiClient.get(serviceProviderFeedbackScoreResponse.getLink('sp-score'), params).then(function(response) {
                            return response || apiClient.noResourceFound();
                        });
                    }
                },
                ncyBreadcrumb: {
                    label: '{{ ::"DETAILS" | translate }}'
                }
            });
    }
})();
