(function () {
    'use strict';

    angular
        .module('elogbooks.admin.asset-score-types')
        .controller('AssetScoreTypesAddEditController', AssetScoreTypesAddEditController);

    AssetScoreTypesAddEditController.$inject = ['assetScoreTypeResponse', 'assetScoreTypesCollectionResponse', 'crudService', '$translate', 'requestDataFactory'];

    function AssetScoreTypesAddEditController (assetScoreTypeResponse, assetScoreTypesCollectionResponse, crudService, $translate, requestDataFactory) {
        var vm = this;

        vm.assetScoreTypes = assetScoreTypeResponse;
        vm.data = requestDataFactory.createRequest(vm.assetScoreTypes);

        vm.statusOptions = [
            { value: true, title: $translate.instant('STATUS_ACTIVE') },
            { value: false, title: $translate.instant('STATUS_INACTIVE') }
        ];

        vm.rangeWeightOptions = [
            { value: 0, title: $translate.instant('RANGE_HIGHER_BETTER')},
            { value: 1, title: $translate.instant('RANGE_LOWER_BETTER')}
        ];

        vm.create = createAction;
        vm.update = updateAction;
        vm.delete = deleteAction;

        $translate = 'ASSET_SCORE_TYPE'

        function createAction () {
            if (vm.data.scoringRequired) {
                $translate = 'ASSET_SCORE_TYPE_SCORING_REQUIRED'
            }
            return crudService.create(
                assetScoreTypesCollectionResponse.getLink('create'),
                vm.data,
                'dashboard.admin.asset-score-types.list.details.info',
                'resource',
                $translate
            );

        }

        function updateAction() {
            if (vm.data.scoringRequired) {
                $translate = 'ASSET_SCORE_TYPE_SCORING_REQUIRED'
            }
            return crudService.update(
                vm.assetScoreTypes.getLink('edit'),
                vm.data,
                'dashboard.admin.asset-score-types.list.details.info',
                'dashboard.admin.asset-score-types.list',
                $translate
            );
        }

        function deleteAction () {
            return crudService.delete(
                vm.assetScoreTypes.getLink('delete'),
                'dashboard.admin.asset-score-types.list',
                'ASSET_SCORE_TYPE'
            );
        }
    }
})();
