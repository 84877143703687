app.config(function ($translateProvider) {
    $translateProvider.translations('nl', {
        THRESHOLD: 'Mandaatbedrag',
        THRESHOLDS: 'Mandaatbedragen',
        THRESHOLD_LIST: 'Mandaten',

        QUOTE_THRESHOLD_CREATED: 'Offerte mandaat gemaakt',
        QUOTE_THRESHOLD_UPDATED: 'Offerte mandaat gewijzigd',
        QUOTE_THRESHOLD_DELETED: 'Offerte mandaat verwijderd',

        THRESHOLD_ADD: 'Voeg een nieuw mandaat bedrag toe',
        THRESHOLD_EDIT: 'Wijzig mandaatbedrag',
    });
});
