(function () {
    'use strict';

    angular
    .module('elogbooks.common.jobs')
        .controller('CommonJobMaterialAddEditController', CommonJobMaterialAddEditController);

    CommonJobMaterialAddEditController.$inject = [
        'supplierCollectionResponse',
        'materialLineCollectionResponse',
        '$filter',
        'apiClient',
        '$state',
        'messenger'
    ];

    function CommonJobMaterialAddEditController (
        supplierCollectionResponse,
        materialLineCollectionResponse,
        $filter,
        apiClient,
        $state,
        messenger
        ) {
        var vm = this;

        vm.supplierCollectionResponse = supplierCollectionResponse;
        vm.suppliers = vm.supplierCollectionResponse.suppliers;
        vm.collectionResponse = { count: 0 };
        vm.materialLineCollectionResponse = materialLineCollectionResponse;
        vm.entityName = 'purchaseOrderLines';
        vm.maxItems = 50;
        vm.totalCost = 0;
        vm.message = 'ITEM_SUCCESSFULLY';
        vm.data = {
            _links: {}
        };

        vm.changeSupplier = changeSupplier;
        vm.create = createAction;

        vm.supplierModel = {
            response: supplierCollectionResponse,
            link:supplierCollectionResponse.getLink('self'),
            linkParameters: {active: true},
            responseKeyPath: 'suppliers',
            itemHrefPath: '_links.self.href',
            itemValuePath: 'name',
            onSelect: changeSupplier,
            onRemove : changeSupplier
        };

        vm.fieldProperties = {
            description: {
                type: 'text',
                label: 'description',
                placeholder: 'description',
                variableName: 'description',
                maxLength: 255,
                required: true,
                model: null
            },
            costType: {
                type: 'select',
                label: 'type',
                options: [
                    {
                        title: $filter('financeCostTypes')('subcontractor'),
                        value: 0
                    },
                    {
                        title: $filter('financeCostTypes')('materials'),
                        value: 1
                    },
                    {
                        title: $filter('financeCostTypes')('equipment_hire'),
                        value: 2
                    },
                    {
                        title: $filter('financeCostTypes')('other_cost'),
                        value: 3
                    }
                ],
                variableName: 'costType',
                required: true,
                model: null
            },
            quantity: {
                type: 'number',
                label: 'quantity',
                placeholder: '0.00',
                variableName: 'quantity',
                min: '0',
                step: '0.01',
                required: true,
                model: null
            },
            unitPrice: {
                type: 'number',
                label: 'unit_price',
                placeholder: '0.00',
                variableName: 'unitPrice',
                min: '0',
                step: '0.01',
                required: true,
                model: null
            },
            amount: {
                type: 'no-edit',
                label: 'amount',
                variableName: 'amount',
                placeholder: '0.00',
                calculatedField: true,
                roundDecimal: true,
                fields: ['quantity', 'unitPrice'],
                operation: 'multiply',
                model: null
            }
        }

        function changeSupplier(supplier) {
            vm.data._links.supplier = {};
            if (vm.supplierModel.selected) {
                var supplier = supplier.selected.object;
                vm.data._links.supplier = supplier.getLink('self');
                vm.data.telephone = supplier.telephone;
                vm.data.email = supplier.email;
                vm.data.address = supplier.flatAddress;
            } else {
                vm.data.raisePurchaseOrder = false;
                vm.data.telephone = null;
                vm.data.email = null;
                vm.data.address = null;
                delete vm.data._links.supplier;
            }
        }

        function createAction () {
            if (vm.data._links.supplier) {
                vm.data._links.supplier = { href: vm.data._links.supplier };
            }

            return apiClient.update(vm.materialLineCollectionResponse.getLink('edit'), vm.data).then(function (response) {
                if (response) {
                    $state.go('^', {}, { reload: $state.current.parent }).then(function () {
                        messenger.success('JOB_MATERIALS_CREATED');
                    });
                } else {
                    messenger.error('REQUEST_ERROR');
                }
            });
        }
    }
})();