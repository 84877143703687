(function () {
    'use strict';

    angular
        .module('elogbooks.common.contracts')
        .controller('ContractSubmitController', new ContractsWorkflowController('SUBMIT', 'submit', false, false))
        .controller('ContractRejectController', new ContractsWorkflowController('REJECT', 'reject', true, false))
        .controller('ContractApproveController', new ContractsWorkflowController('APPROVE', 'approve', false, false))
        .controller('ContractCancelController', new ContractsWorkflowController('CANCEL', 'cancel', true, false))
        .controller('ContractTerminateController', new ContractsWorkflowController('TERMINATE', 'terminate', true, false))
        .controller('ContractRegressController', new ContractsWorkflowController('REGRESS', 'regress', true, false))
    ;

    function ContractsWorkflowController(translation, action, noteRequired, partial) {
        return function (apiClient, $state, messenger, user, contractResponse, requestDataFactory, scopeData, $scope, $translate) {
            var vm = this;
                vm.contract = contractResponse;
                vm.user = user;
                vm.translation = 'CONTRACT_WORKFLOW_' + translation;
                vm.action = action;
                vm.noteRequired = noteRequired;
                vm.partial = (typeof partial === 'undefined' ? vm.action : partial);
                vm.submit = submitAction;
                vm.data = requestDataFactory.createRequest();
                angular.extend(vm, scopeData);

            function submitAction () {
                return apiClient.create(vm.contract.getLink(action), vm.data).then(function (response) {
                    if (response) {
                        $state.go('^', {}, { reload: $state.get('^.^.^') }).then(function () {
                            messenger.success(vm.translation + '_SUCCESS');
                        });
                    }
                });
            }
        };
    }
})();
