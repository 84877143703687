(function(){
    'use strict';

    angular
        .module('elogbooks.admin.trade-types')
        .controller('TradeTypesDetailsController', TradeTypesDetailsController);

    function TradeTypesDetailsController($scope, tradeTypeResponse) {

        var vm = this;
        vm.tradetype = tradeTypeResponse.getData();

    }
})();
