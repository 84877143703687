app.config(function ($translateProvider) {
    $translateProvider.translations('nl', {
        BULK_ACTIONS: 'Massa acties',
        BULK_ACTION_DETAILS_LIST:'Massa actie details',
        BULK_ACTION_ERROR_MESSAGE: 'Bericht',
        BULK_ACTION_FAILED: 'Mislukt',
        BULK_ACTION_LOG_ALL: 'Allemaal',
        BULK_ACTION_LOG_CHANGE: 'Gelukt',
        BULK_ACTION_LOG_ERROR: 'Fout',
        BULK_ACTION_LOG_NO_CHANGE: 'Geen veranderingen',
        BULK_ACTION_LOG_STATUS: 'Status',
        BULK_ACTION_LOG_STATUS_DONE: 'Klaar',
        BULK_ACTION_LOG_STATUS_PENDING: 'In afwachting',
        BULK_ACTION_LOG_CHANGE: 'Succes',
        BULK_ACTION_LOG_TYPE: 'Log Type',
        BULK_ACTION_LOGS: 'Massa actie Logs',
        BULK_ACTION_MAX_FILTERED_ITEMS_EXCEEDED: 'Massa actie niet uitgevoerd - maximum opdrachten overschreden',
        BULK_ACTION_MAX_ITEMS: 'Massa actie niet gemaakt - maximum opdrachten overschreden',
        BULK_ACTION_MUST_ONLY_BE_FILTERED_OR_SELECTED_JOBS: 'Massa actie niet gemaakt - Kan opdrachten niet selecteren en filteren tegelijkertijd',
        BULK_ACTION_NO_JOBS: 'Massa actie niet gemaakt - geen opdrachten geselecteerd',
        BULK_ACTION_REASSIGN_USER_REQUIRED: 'Massa actie toewijzen - Gebruiker verplicht',
        BULK_ACTION_REASSIGN_SERVICE_PROVIDER_REQUIRED: 'Massa actie toewijzen - Leverancier verplicht',
        BULK_ACTION_SUCCESSFUL: 'Gelukt',
        BULK_ACTIONS_LIST: 'Massa actie lijst',
        BULKACTIONS: 'Massa actiess',
        CHANGE_OPERATIVE: 'Pas werkman aan',
        STATUS_PAUSED: 'Bezig (gepauseerd)',
        STATUS_INITIALISING: 'Initialiseren opdrachten',
        BULK_ACTION_NOTE_REQUIRED: 'Notitie veld verplicht',
        BULK_ACTION_REASSIGN: 'Opnieuw toewijzen',
        BULK_ACTION_CANCEL: 'Annuleren',
        BULK_ACTION_REASSIGN_OPERATIVES: 'Opnieuw toewijzen werkmannen'
    });
});
