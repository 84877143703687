(function () {
    'use strict';

    angular
        .module('elogbooksDirectives')
        .directive('elbMimeTypeIcon', ElbMimeTypeIcon);

    ElbMimeTypeIcon.$inject = [];

    function ElbMimeTypeIcon () {
        return {
            restrict: 'AE',
            scope: {
                ngModel: '='
            },
            template: '<img data-ng-src="{{ href }}" data-ng-if="isImage"><i class="fa fa-{{ typeIcon }}" style="font-size:50px;" data-ng-if="! isImage"></i>',
            link: function (scope, element, attrs) {
                scope.typeIcon = 'file-o';
                scope.isImage = false;

                switch (scope.ngModel.type) {
                    case 'image/jpeg':
                    case 'image/png':
                    case 'image/gif':
                        //check if link contains file with allowed extension
                        if (scope.ngModel.href.match(/\.(jpeg|jpg|png|gif)$/) !== null) {
                            scope.href = scope.ngModel.href + '&h=50';
                            scope.isImage = true;
                        }
                        
                        scope.typeIcon = 'file-picture-o';
                        break;
                    case 'text/plain':
                        scope.typeIcon = 'file-text-o';
                        break;
                    case 'application/pdf':
                        scope.typeIcon = 'file-pdf-o';
                        break;
                    case 'application/vnd.ms-excel':
                    case 'application/vnd.oasis.opendocument.spreadsheet-flat-xml':
                    case 'application/vnd.oasis.opendocument.spreadsheet':
                    case 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet':
                        scope.typeIcon = 'file-excel-o';
                        break;
                    case 'application/msword':
                    case 'application/vnd.openxmlformats-officedocument.wordprocessingml.document':
                    case 'application/vnd.oasis.opendocument.text':
                        scope.typeIcon = 'file-word-o';
                        break;
                    case 'application/vnd.ms-powerpoint':
                    case 'application/vnd.openxmlformats-officedocument.presentationml.presentation':
                        scope.typeIcon = 'file-powerpoint-o';
                        break;
                    case 'application/x-7z-compressed':
                    case 'application/x-rar-compressed':
                    case 'application/zip':
                        scope.typeIcon = 'file-archive-o';
                        break;

                }
            }
        };
    }
})();
