angular.module('elogbooksServices').service('quoteModalService', function($uibModal, $state) {

    'use strict';

    this.raiseQuote = raiseQuoteModal;

    function raiseQuoteModal($scope, getJobRemedials) {
        var rootResourceResponse = $scope.model ? $scope.model.rootResource : $scope.vm.rootResourceResponse ;
        var siteResponse = $scope.model ? $scope.model.siteResponse : $scope.vm.siteResponse;
        var serviceProviderResponse = $scope.model ? $scope.model.serviceProviderResponse : $scope.vm.serviceProviderResponse;
        var partnershipResponse = $scope.model ? $scope.model.partnershipResponse : $scope.vm.partnershipResponse;
        var priorityService = $scope.model ? $scope.model.priorityService : $scope.vm.priorityService;
        var user = $scope.model ? $scope.model.user : $scope.vm.user;
        var locationCollectionResponse = $scope.model ? $scope.model.locationCollectionResponse : $scope.vm.locationCollectionResponse;
        var config = $scope.model ? $scope.model.config : null;
        var sourceJob = $scope.context === 'complete_job' ? $scope.model.sourceJob : null;

       $uibModal.open({
            templateUrl: '/modules/common/quote-requests/modal/quote-add-modal.html',
            controller: 'QuoteAddModalController',
            controllerAs: 'vm',
            resolve: {
                serviceProviderResponse: function() {
                    return serviceProviderResponse;
                },
                siteResourceResponse: function () {
                    return siteResponse;
                },
                siteRelationshipResponse: function () {
                    return rootResourceResponse.getResource('siterelationships');
                },
                userResponse: function () {
                    return user;
                },
                questionResponse: function () {
                    return $scope.context === 'audit_question' ? $scope.question : null;
                },
                sectionResponse: function () {
                    if ($scope.context === 'audit_question' && $scope.section) {
                        return $scope.section;
                    } else if ($scope.context === 'audit_question' && $scope.vm.section) {
                        return $scope.vm.section;
                    } else {
                        return null;
                    }
                },
                locationCollectionResponse: function () {
                    return locationCollectionResponse;
                },
                raiseRemedialJob: function () {
                    return $scope.context === 'complete_job';
                },
                sourceJob: function () {
                    return sourceJob;
                },
                userOperativeCollectionResponse: function(apiClient) {
                    if ($scope.context === 'complete_job') {
                        return apiClient.get(user.getLink('operatives'), {limit: 999}).then(function(response) {
                            return response || apiClient.noResourceFound();
                        });
                    } else {
                        return {}
                    }
                },
                config: function() {
                    if (config) {
                        return angular.extend(config, {
                            isHelpdesk: config.isHelpdesk,
                            isTenant: config.isTenant,
                            showSiteNotes: config.isHelpdesk,
                            addReporter: config.isHelpdesk,
                            link: null,
                            siteDisabled: null
                        });
                    }
                },
                siteCollectionResponse: function(apiClient) {
                    if (config.siteDisabled) {
                        return config.siteDisabled;
                    }

                    return apiClient.get(user.getLink('sites'), {
                        permission: ['site_permission_create_quotes'],
                        datagroup: 'search'
                    }).then(function(response) {
                        return response || apiClient.noResourceFound();
                    });
                },
                priorityService: function(){
                    return priorityService;
                }
            }
        }).result.then(function () {
            if (getJobRemedials) {
                // find the function on the controller
                $scope.$parent.$parent.$parent.$parent.vm.getJobRemedials(true, true);
            }
            var state = $scope.context === 'complete_job' ? false : $state.current.parent;
            $state.go('.', {}, {reload: state}).then(function () {});
        });
    }
});
