(function(){
    'use strict';

    angular.module('elogbooks.user.sites', [
        'elogbooks.user.sites.files',
        'elogbooks.user.sites.jobs',
        'elogbooks.user.sites.quote-requests',
        'elogbooks.user.sites.master-tasks',
        'elogbooks.user.sites.actions',
        'elogbooks.user.sites.contracts'
    ]);
})();
