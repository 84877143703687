angular.module('elogbooksDirectives').directive('elbTooltip', [
    '$compile',
    '$templateRequest',
    '$timeout',
    '$window',
    'lodash',
    function ($compile, $templateRequest, $timeout, $window, lodash) {
        return {
            restrict: 'A',
            scope: {
                tooltipContent: '@',
                tooltipOptions: '<?',
                tooltipTemplateUrl: '@',
                tooltipData: '<',
            },
            link: function (scope, element, attrs) {
                const tooltipShowOnOverflow =
                    attrs.tooltipShowOnOverflow || false;
                let tippyInstance;

                function initializeTooltip(content) {
                    const options = angular.extend(
                        {
                            content: content,
                            theme: 'light',
                            zIndex: 1029,
                        },
                        scope.tooltipOptions || {}
                    );

                    if (tippyInstance) {
                        tippyInstance.setContent(content);
                    } else {
                        tippyInstance = tippy(element[0], options);
                    }
                }

                // Load template or use plain content
                function loadTooltipContent() {
                    if (scope.tooltipTemplateUrl) {
                        $templateRequest(scope.tooltipTemplateUrl).then(
                            function (template) {
                                const compiledTemplate =
                                    $compile(template)(scope);
                                initializeTooltip(compiledTemplate[0]);
                            }
                        );
                    } else {
                        initializeTooltip(scope.tooltipContent);
                    }
                }

                loadTooltipContent();

                function handleOverflow() {
                    const isOverflowing =
                        element[0].scrollWidth > element[0].offsetWidth;
                    if (tippyInstance) {
                        isOverflowing || !tooltipShowOnOverflow
                            ? tippyInstance.enable()
                            : tippyInstance.disable();
                    }
                }

                const debouncedResizeHandler = lodash.debounce(handleOverflow, 200);
                angular.element($window).on('resize', debouncedResizeHandler);

                scope.$on('$destroy', function () {
                    angular
                        .element($window)
                        .off('resize', debouncedResizeHandler);
                    if (tippyInstance) {
                        tippyInstance.destroy();
                    }
                });

                attrs.$observe('tooltipContent', loadTooltipContent);
                scope.$watch('tooltipData', loadTooltipContent, true);
                attrs.$observe('tooltipTemplateUrl', loadTooltipContent);

                $timeout(handleOverflow);
            },
        };
    },
]);
