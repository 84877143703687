(function (){
    'use strict';

    angular
        .module('elogbooks.common.audit')
        .controller('AuditAnswerOptionTemplateAddEditController', AuditAnswerOptionTemplateAddEditController);

    AuditAnswerOptionTemplateAddEditController.$inject = [
        '$state',
        'apiClient',
        'messenger',
        'confirmationModal',
        'answerTemplateResponse',
        'answerOptionTemplateCollectionResponse',
        'answerOptionTemplateResponse'
    ];

    function AuditAnswerOptionTemplateAddEditController(
        $state,
        apiClient,
        messenger,
        confirmationModal,
        answerTemplateResponse,
        answerOptionTemplateCollectionResponse,
        answerOptionTemplateResponse
        ) {
        var vm = this;

        vm.answerTemplate = answerTemplateResponse;
        vm.answerOptionTemplateCollection = answerOptionTemplateCollectionResponse;
        vm.answerOptionResponse = answerOptionTemplateResponse;
        vm.data = {};

        vm.create = createAction;
        vm.update = updateAction;
        vm.delete = deleteAction;

        if (vm.answerOptionResponse) {
            vm.data = {
                id: vm.answerOptionResponse.id,
                title: vm.answerOptionResponse.title
            }
        }

        function createAction () {
            var redirectRoute = '^';
            var redirectParams = {};

            apiClient.create(vm.answerOptionTemplateCollection.getLink('create'), vm.data).then(function (response) {
                if (response) {
                    $state.go(redirectRoute, redirectParams, { reload: $state.get('^.^') }).then(function () {
                        messenger.success('AUDIT_ANSWER_OPTION_CREATED');
                    });
                } else {
                    messenger.error('REQUEST_ERROR');
                }
            });
        };

        function updateAction () {
            apiClient.update(vm.answerOptionResponse.getLink('edit'), vm.data).then(function (response) {
                if (response) {
                    $state.go('^', {}, { reload: $state.get('^.^') }).then(function () {
                        messenger.success('ANSWER_OTPION_UPDATED');
                    });
                } else {
                    messenger.error('REQUEST_ERROR');
                }
            });
        };

        function deleteAction() {
            return confirmationModal.open().result.then(function () {
                return apiClient.delete(vm.answerOptionResponse.getLink('delete')).then(function (response) {
                    if (response) {
                        $state.go('^', {}, { reload: $state.get('^.^') }).then(function () {
                            messenger.success('ANSWER_OPTION_DELETED');
                        });
                    } else {
                        messenger.error('REQUEST_ERROR');
                    }
                });
            });
        }
    }
})();
