(function() {
    'use strict';

    angular
        .module('elogbooks.common.jobs.add')
        .controller('CommonJobAddSiteController', CommonJobAddSiteController);

    CommonJobAddSiteController.$inject = ['$rootScope', '$scope', '$stateParams', '$state', 'apiClient', 'siteCollectionResponse', 'siteResourceResponse', '$q', 'confirmationModal'];

    function CommonJobAddSiteController($rootScope, $scope, $stateParams, $state, apiClient, siteCollectionResponse, siteResourceResponse, $q, confirmationModal) {
        var vm = $scope.vm;
        vm.canNext = true;
        vm.canPrevious = false;
        vm.canSubmit = false;
        vm.sites = siteCollectionResponse ? siteCollectionResponse.sites : [];
        vm.title = 'JOB_ADD_SITE_DETAILS';
        vm.locationCollectionResponse = [];
        vm.siteNoteCollectionResponse = [];

        vm.next = nextAction;
        vm.changeSite = changeSite;
        vm.removeSite = removeSite;

        if (!$stateParams.siteResource && siteResourceResponse) {
            $stateParams.siteResource = siteResourceResponse.getLink('self').encode();
            $state.go('.', $stateParams, {notify: false});
        }

        vm.selectedSiteModel = {
            response: siteCollectionResponse,
            link: siteCollectionResponse ? siteCollectionResponse.getLink('self') : null,
            required: true,
            responseKeyPath: 'sites',
            itemHrefPath: '_links.self.href',
            itemValuePath: 'name',
            onSelect: vm.changeSite,
            onRemove: vm.removeSite,
            disabled: vm.config.siteDisabled,
            populateDisabled: true
        };

        if (vm.selectedSite) {
            vm.selectedSiteModel.selected = {
                object: vm.selectedSite,
                href: vm.selectedSite.getLink('self'),
                value: vm.selectedSite.name
            };
        } else if (siteResourceResponse && !vm.selectedSiteModel.selected) {
            vm.selectedSiteModel.selected = {
                object: siteResourceResponse,
                href: siteResourceResponse.getLink('self'),
                value: siteResourceResponse.name
            };
            changeSite();
        }

        function removeSite() {
            $scope.$emit('removeSite');
        }

        function changeSite() {
            if (vm.selectedSiteModel.selected.object) {
                vm.submitDisabled = true;
                vm.selectedSite = vm.selectedSiteModel.selected.object;

                $rootScope.skipDirtyCheck = true;

                if (siteResourceResponse) {
                    nextAction();
                } else {
                    $state.go('.', {siteResource: vm.selectedSite.getLink('self').encode()}, {reload: false})
                        .then(function() {
                            vm.submitDisabled = false;
                            $rootScope.skipDirtyCheck = false;
                    });
                }
            }
        }

        function getSiteNotes(requests) {
            requests.push(apiClient.get(vm.selectedSite.getLink('notes'), {allowHelpdesk: true})
                .then(function(response) {
                    vm.siteNoteCollectionResponse = response;
                }));

            return requests;
        }

        function nextAction() {
            // Link set by precedence (order is important): Tenant < Regular < Helpdesk
            if (vm.config.isHelpdesk) {
                vm.config.link = vm.selectedSite.getLink('create-reactive-job-helpdesk');
            } else if (vm.config.isTenant || vm.config.isTenantPlus) {
                vm.config.link = vm.selectedSite.getLink('create-reactive-tenant-job');
            } else if (vm.selectedSite.getLink('create-reactive-job')) {
                vm.config.link = vm.selectedSite.getLink('create-reactive-job');
            }
            var nextRoute = vm.config.addReporter ? vm.getRoute('reporter') : vm.getRoute('contact');
            vm.submitDisabled = false;
            $stateParams.siteResource = vm.selectedSite.getLink('self').encode();
            if (vm.config.showSiteNotes) {
                var requests = [];
                requests = getSiteNotes(requests);

                $q.all(requests).then(function() {
                    if (vm.siteNoteCollectionResponse.siteNotes.length) {
                        var config = {
                            primaryClass: 'btn-primary',
                            primaryText: 'OK',
                            secondaryText: 'CANCEL',
                            titleMessage: 'SITE_NOTES',
                            template: '/modules/common/site/notes.html',
                            data: vm.siteNoteCollectionResponse.siteNotes
                        };
                        vm.siteNotesConfirmed = false;

                        return confirmationModal.open(config)
                            .result.then(function() {
                                vm.siteNotesConfirmed = true;
                                $state.go(nextRoute, $stateParams, {reload: false});
                            });
                    } else {
                        vm.siteNotesConfirmed = true;
                        $state.go(nextRoute, $stateParams, {reload: false});
                    }
                });
            } else {
                vm.siteNotesConfirmed = true;
                $state.go(nextRoute, $stateParams, {reload: false});
            }
        }
    }
})();
