(function() {
    'use strict';

    angular
        .module('elogbooks')
        .config(Translations);

    function Translations($translateProvider) {
        $translateProvider.translations('zh-hk', {
            JOB_WORKFLOW_ESCALATE_OPERATION: '上報',
            JOB_WORKFLOW_ESCALATE_OPERATION_SUBMIT: '上報',
        });
    }
})();

app.config(function ($translateProvider) {
    $translateProvider.translations('zh-hk', {
        JOB_WORKFLOW_COMPLETE: '完成工作',
        JOB_WORKFLOW_COMPLETE_OPERATION: '完成工作',
        JOB_WORKFLOW_COMPLETE_OPERATION_SUBMIT: '完成工作',
        JOB_WORKFLOW_COMPLETE_OPERATION_SUCCESS: '工作已完成',

        JOB_WORKFLOW_REQUEST_EXTENSION: '請求延期',
        JOB_WORKFLOW_REQUEST_EXTENSION_SUBMIT: '請求',
        JOB_WORKFLOW_REQUEST_EXTENSION_SUCCESS: '工作延期已請求',

        JOB_WORKFLOW_ACCEPT_EXTENSION: '接受延期',
        JOB_WORKFLOW_ACCEPT_EXTENSION_SUBMIT: '接受',
        JOB_WORKFLOW_ACCEPT_EXTENSION_SUCCESS: '工作延期已接受',

        JOB_WORKFLOW_REJECT_EXTENSION: '拒絕延期',
        JOB_WORKFLOW_REJECT_EXTENSION_SUBMIT: '拒絕',
        JOB_WORKFLOW_REJECT_EXTENSION_SUCCESS: '工作延期已拒絕',

        JOB_WORKFLOW_CANCEL_OPERATION: '取消工作',
        JOB_WORKFLOW_CANCEL_OPERATION_SUBMIT: '遞交',
        JOB_WORKFLOW_CANCEL_OPERATION_SUCCESS: '工作已取消',

        JOB_WORKFLOW_ACCEPT_ASSIGNMENT: '確認',
        JOB_WORKFLOW_REQUEST_PROPOSAL: '要求新SLA',
        JOB_WORKFLOW_INSIST_ASSIGNMENT: '確定',

        JOB_WORKFLOW_ACCEPT_APPROVAL: '批准',
        JOB_WORKFLOW_ACCEPT_APPROVAL_SUBMIT: '批准',

        JOB_WORKFLOW_REOPEN: '重開',
        JOB_WORKFLOW_REOPEN_OPERATION: '重開',
        JOB_WORKFLOW_REOPEN_OPERATION_SUBMIT: '重開',
        JOB_WORKFLOW_REOPEN_OPERATION_SUCCESS: '工作已重開',

        JOB_WORKFLOW_ESCALATE_APPROVAL: '上報審批',

        JOB_WORKFLOW_REASSIGN_ASSIGNMENT: '重新分配職務',

        JOB_WORKFLOW_CHANGE_REQUIRED_BY_DATE_OPERATION: '延期工作',
        JOB_WORKFLOW_CHANGE_REQUIRED_BY_DATE_OPERATION_SUBMIT: '延期工作',
        JOB_WORKFLOW_CHANGE_REQUIRED_BY_DATE_OPERATION_SUCCESS: '工作已延期',

        JOB_WORKFLOW_EDIT_JOB: '修改工作',
        JOB_WORKFLOW_EDIT_JOB_SUBMIT: '儲存',
        JOB_WORKFLOW_EDIT_JOB_SUCCESS: '工作已修改',

    });
});
