app.config(function ($translateProvider) {
    $translateProvider.translations('zh-hk', {
        PRIORITY: '優先權',
        PRIORITIES: '優先權',

        ADD_PRIORITY: '新增優先權',

        PRIORITY_LIST: '優先權',
        PRIORITY_EDIT: '修改優先權',
        PRIORITY_ADD: '新增優先權',

        PRIORITY_CREATED: '優先權已建立',
        PRIORITY_UPDATED: '優先權已更新',
        PRIORITY_DELETED: '優先權已刪除',


        DEADLINE: '截止日期',
        ATTEND_TIME: '參與時間',

        ATTEND_DEADLINE: '參與截止日期',
        NON_WORKING_DAYS: '不工作日',
        INCLUDE_NON_WORKING_DAYS: '包括不工作日',
        COMPLETE_TIME: '完成於',
        COMPLETE_DEADLINE: '完成截止日期',
        APPROVAL_DELAY: '審批延遲',
        ACKNOWLEDGE_DELAY: '確認延遲',
        ALARM_WHEN_OVERDUE: '逾期警報',
        ALERT_MESSAGE_ENABLED: '開啟警報訊息',
        ALERT_MESSAGE: '警報訊息',
        ADD_NEW_PRIORITY: '新增優先權',
        INTERVAL: '間隔',
        ATTENDANCE_DUE: '出勤到期',
        COMPLETION_DUE: '完成到期',

        PRIORITY_MESSAGE: '優先權訊息',

        HELPTEXT_DELAY: '值為0將使用系統設置',

        ENTITY_TYPE: '類別',
        ENTITY_TYPE_ALL: '所有',
        ENTITY_TYPE_JOB: '工作',
        ENTITY_TYPE_QUOTE: '報價',
        ENTITY_TYPE_ACTION: '行動'
    });
});
