(function() {
    'use strict';

    angular
        .module('elogbooks.admin.action-types')
        .config(registerRoutes)
        .config(registerRoutesList)
        .config(registerRoutesAdd)
        .config(registerRoutesEdit);

    function registerRoutes($stateProvider) {
        $stateProvider
            .state('dashboard.admin.action-types', {
                abstract: true,
                templateUrl: 'shared-templates/view.html',
                ncyBreadcrumb: {
                    skip: true
                },
                data: {
                    permissions: ['user_permission_view_action_types']
                }
            });
    }

    function registerRoutesList($stateProvider) {
        $stateProvider
            .state('dashboard.admin.action-types.list', {
                url: '/action-types?page&limit&order&id',
                parent: 'dashboard.admin.action-types',
                views: {
                    '@dashboard.admin.action-types': {
                        templateUrl: '/modules/admin/action-types/action-type-list.html',
                        controller: 'ActionTypesController',
                        controllerAs: 'vm'
                    }
                },
                params: {
                    page: '1',
                    limit: '30',
                    order: 'id',
                    id: null
                },
                resolve: {
                    actionTypeCollectionResponse: function($stateParams, apiClient, rootResourceResponse) {
                        return apiClient.get(rootResourceResponse.getLink('actiontypes'), $stateParams).then( function(response) {
                            return response || apiClient.noResourceFound();
                        });
                    }
                },
                ncyBreadcrumb: {
                    label: '{{ ::"ACTION_TYPE_LIST" | translate }}'
                }
            });
    }

    function registerRoutesAdd($stateProvider) {
        $stateProvider
            .state('dashboard.admin.action-types.list.add', {
                url: '/add?parentResource',
                parent: 'dashboard.admin.action-types.list',
                params: {
                    parentResource: null
                },
                views: {
                    '@dashboard.admin.action-types': {
                        templateUrl: '/modules/admin/action-types/add-edit/action-type-form.html',
                        controller: 'ActionTypesAddEditController',
                        controllerAs: 'vm'
                    }
                },
                resolve: {
                    actionTypeResponse: function() {
                        return {};
                    },
                    parentResponse: function(apiClient, $stateParams) {
                        if ($stateParams.parentResource) {
                            return apiClient.get($stateParams.parentResource.decode()).then(function(response) {
                                return response || apiClient.noResourceFound();
                            });
                        }

                        return {};
                    }
                },
                ncyBreadcrumb: {
                    label: '{{ ::"ADD" | translate }}'
                }
            });
    }

    function registerRoutesEdit($stateProvider) {
        $stateProvider
            .state('dashboard.admin.action-types.list.edit', {
                url: '/edit/{resource}',
                parent: 'dashboard.admin.action-types.list',
                views: {
                    '@dashboard.admin.action-types': {
                        templateUrl: '/modules/admin/action-types/add-edit/action-type-form.html',
                        controller: 'ActionTypesAddEditController',
                        controllerAs: 'vm'
                    }
                },
                resolve: {
                    actionTypeResponse: function(apiClient, $stateParams) {
                        return apiClient.get($stateParams.resource.decode()).then(function(response) {
                            return response || apiClient.noResourceFound();
                        });
                    },
                    parentResponse: function(apiClient, $stateParams, actionTypeResponse) {
                        if (actionTypeResponse.getLink('parent')) {
                            return apiClient.get(actionTypeResponse.getLink('parent'), {datagroup: 'details'}).then(function(response) {
                                return response || apiClient.noResourceFound();
                            });
                        }

                        return {};
                    }
                },
                ncyBreadcrumb: {
                    label: '{{ ::"EDIT" | translate }}'
                }
            });
    }
})();
