(function () {
    'use strict';

    angular
        .module('elogbooks.admin.request-logs')
        .controller('RequestLogInfoController', RequestLogInfoController);

    RequestLogInfoController.$inject = ["requestLogResponse"];

    function RequestLogInfoController(requestLogResponse) {
        var vm = this;
        vm.requestLog = requestLogResponse;

        try {
            vm.requestLog.requestBody = JSON.stringify(JSON.parse(vm.requestLog.requestBody), null, 4);
            vm.requestLog.responseBody = JSON.stringify(JSON.parse(vm.requestLog.responseBody), null, 4);
        } catch (error) {
            return false;
        }
    }
})();