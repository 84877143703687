(function () {
    'use strict';

    angular
        .module('elogbooks.common.audit')
        .controller('AuditQuestionsController', AuditQuestionsController);

    AuditQuestionsController.$inject = ['$scope', '$state', 'lodash', '$stateParams', 'apiClient', 'auditResponse', 'sectionResponse', 'questionsCollectionResponse', 'messenger', '$uibModal', 'rootResourceResponse', 'siteResponse', 'auditService', 'user', 'sortableOptions', 'jobModalService'];

    function AuditQuestionsController($scope, $state, lodash, $stateParams, apiClient, auditResponse, sectionResponse, questionsCollectionResponse, messenger, $uibModal, rootResourceResponse, siteResponse, auditService, user, sortableOptions, jobModalService) {
        var vm = this;
        vm.siteResponse = siteResponse;
        vm.rootResourceResponse = rootResourceResponse;
        vm.audit = auditResponse;
        vm.questionsCollectionResponse = questionsCollectionResponse;
        vm.questions = questionsCollectionResponse ? questionsCollectionResponse.questions : null;
        vm.revert = revert;
        vm.userCanEdit = lodash.has(vm.audit, '_links.edit.href');
        vm.userCanAnswer = ((lodash.has(vm.audit, '_links.start') || lodash.has(vm.audit, '_links.complete')) ? true : false);
        vm.section = sectionResponse;
        vm.auditsFlash = auditService.auditAnswerFlashRaiseJobs;
        vm.site = siteResponse;
        vm.editAction = false;
        var canReorderStatuses = ['AUDIT_DRAFT', 'AUDIT_SCHEDULE_INACTIVE'];
        vm.canReorder = canReorderStatuses.indexOf(vm.audit.status) !== -1;

        vm.raiseAction = raiseAction;
        vm.jobsModal = jobsModal;
        vm.confirmationModal= confirmationModal;
        vm.submit = updateQuestions;

        vm.resetNotApplicable = resetNotApplicable;
        vm.submitAnswer = submitAnswer;
        vm.selectOption = selectOption;

        vm.editAnswer = editAnswer;
        vm.cancel = cancel;
        vm.update = update;
        vm.resetQuestions = resetQuestions;


        vm.files = [];
        vm.uploading = false;

        vm.hidePanel = false;
        vm.haveQuestionsChanged = false; // Order of sections has changed
        vm.raiseJobModel = {
            rootResource: rootResourceResponse,
            user: user,
            siteResponse: siteResponse,
            sourceJob: null,
            config: null
        };
        vm.user = user;
        $scope.context = 'audit_question';

        lodash.each(vm.questions, function (question) {
            question.showDetails = lodash.has(question, '_links.resetanswer') ? false : true;
            question = getDefaults(question);
        });


        // Attach already uploaded files so that they are showed when editing answer
        lodash.each(vm.questions, function (question) {
            if (lodash.has(question, "answerFiles")) {
                question.answer.files = question.answerFiles.files;
            } else {
                question.answer.files = [];
            }
        });

        vm.sortableOptions = sortableOptions.addProperty({
            orderChanged: function (event) {
                vm.haveQuestionsChanged = true;
            }
        });

        var originalQuestions = angular.copy(vm.questions);

        // Reset the order of the sections back to original order
        function resetQuestions() {
            vm.questions = angular.copy(originalQuestions);
            vm.haveQuestionsChanged = false;
        }


        /**
         * Set default empty answer for question answer form
         *
         * @param object question
         */
        function getDefaults(question) {
            question.answer = {
                comment: null,
                notApplicable: false,
                _links: {
                    answerOption: null,
                },
                files: {
                    links: []
                }
            };
        }

        /**
         * Revert's question answer
         * On success refresh grandparent resovles
         * to update audit section and info views
         *
         * @param object question
         */
        function revert(question) {
            apiClient.update(question.getLink('resetanswer'), question.answer).then(function (response) {
                if (response) {
                    $state.go('.', angular.extend({}, $stateParams), {reload: $state.get('^.^')}, false).then(function () {
                        messenger.success('QUESTION_REVERTED');
                    });
                } else {
                    messenger.error('REQUEST_ERROR');
                }
            });
        }

        /**
         * Reset N/A flag
         *
         * @param object question
         */
        function resetNotApplicable(question) {
            question.answer.notApplicable = false;
        }

        /**
         * Handle button option press to set selected answer option or N/A flag
         *
         * @param object question
         * @param object option
         */
        function selectOption(question, option) {
            if (typeof option !== 'undefined') {
                if (option === 'notApplicable') {
                    question.answer.notApplicable = true;
                    question.answer._links.answerOption = null;
                } else {
                    question.answer.notApplicable = false;
                    question.answer._links.answerOption = option;

                    if (option.assistedComments.count > 0) {
                        question.assistedComments = option.assistedComments.assistedComments;
                    } else {
                        question.assistedComments = null;
                    }
                }
            }
        }

        /**
         * Submit answer to audit question
         * If score is < 100, then an action is raised after a modal is shown to
         * select a priority
         *
         * @param object question
         */
        function submitAnswer(question) {
            if (question.answer.comment && question.answer.comment.length > 1000) {
                messenger.error('COMMENT_1000_OR_LESS');
                return false;
            }

            var pointsValue;

            if (lodash.has(question.answer, '_links.answerOption.pointsValue')) {
                pointsValue = question.answer._links.answerOption.pointsValue;
            }

            // check if a file is required
            if (
                question.answer.notApplicable !== true &&
                question.attachmentRequired &&
                question.answer.files.length < 1
            ) {
                messenger.error('FILES_REQUIRED');
                return false;
            }

            // check if an action is required to be raised
            if (
                question.answer.notApplicable !== true &&
                question.answer._links.answerOption !== null &&
                pointsValue < 100 &&
                question.forceRaise === 1
            ) {
                raiseAction(question, true);
            // check if a job is required to be raised
            } else if (
                question.answer.notApplicable !== true &&
                question.answer._links.answerOption !== null &&
                pointsValue < 100 &&
                question.forceRaise === 2
            ) {
                if (vm.site.getLink('create-reactive-job')) {
                    $scope.question = question;
                    jobModalService.raiseJob($scope, true);
                } else {
                    messenger.error('NO_PERMISSION_TO_RAISE_JOB');
                }
            } else if (
                question.answer.notApplicable !== true &&
                question.answer._links.answerOption !== null &&
                pointsValue < 100 &&
                question.forceRaise === 3
            ) {
                // either
                vm.confirmationModal(question);
            } else {
                // no priority / job select required
                auditService.sendAnswer(question);
            }
        }

        function confirmationModal(question) {
            $uibModal.open({
                templateUrl: '/modules/common/modals/audits/job-action.html',
                controller: ['$uibModalInstance', function($uibModalInstance) {
                    var vm = this;

                    vm.accept = function(type) {
                        vm.close = $uibModalInstance.close(type);
                    };

                    vm.close = $uibModalInstance.dismiss;
                }],
                controllerAs: 'vm',
                size: 'm'
            }).result.then(function(response) {
                if (response === 'job') {
                    $scope.question = question;
                    jobModalService.raiseJob($scope, true);
                }
                if (response === 'action') {
                    raiseAction(question, true)
                }
            });
        }

        function jobsModal(question) {

            var modalInstance = $uibModal.open({
                templateUrl: '/modules/common/jobs/modal/jobs-list-modal.html',
                controller: 'JobsListModalController',
                controllerAs: 'vm',
                size: 'xl',
                resolve: {
                    jobsCollectionResponse: function () {
                        return question.jobs;
                    }
                }
            });
        }

        function editAnswer(question) {
            question.editAnswer = true;
            question.answer.comment = question._links.answer.comment;
            question.answer._links.answerOption = { pointsValue: question.getLinkAttribute('answer', 'answerOption') };
            //if question has assisted comments then load and prefill form with comments
            if (question.hasAssistedComments === true) {
                apiClient.get(question.getLink('answerOption')).then(function (response) {
                    if (response) {
                        question.assistedComments = response.assistedComments.assistedComments;

                        apiClient.get(question.getLink('assistedComment'), question.answer).then(function (response) {
                            if (response) {
                                var assistedComment = {
                                    comment: response.comment,
                                    id: response.id,
                                    href: response._links.self.href,
                                    _links: {
                                        assistedComment: response._links.self.href,
                                        self: {
                                            href: response._links.self.href
                                        }
                                    }
                                };

                                question.answer.assistedComment = assistedComment;
                                question.answer._links.assistedComment.href = response._links.self.href;
                            } else {
                                messenger.error('REQUEST_ERROR');
                            }
                        });
                    } else {
                        messenger.error('REQUEST_ERROR');
                    }
                });
            }
        }

        function cancel(question) {
            question.editAnswer = false;
        }

        function update(question) {
            if (question.answer.comment && question.answer.comment.length > 1000) {
                messenger.error('COMMENT_1000_OR_LESS');
                return false;
            }

            // check if a file is required
            if (
                question.answer.notApplicable !== true &&
                question.attachmentRequired &&
                question.answer.files.length < 1
            ) {
                messenger.error('FILES_REQUIRED');
                return false;
            }

            var files = [];
            question.answer.files.forEach(function (file) {
                if (typeof file._links !== 'undefined') {
                    // existing file
                    files.push(file._links.resource);
                } else {
                    // new file
                    files.push(file);
                }
            });

            delete question.answer._links.answerOption;

            question.answer.files = {
                links: files
            };

            // // Only send resource to API
            if (question.answer.assistedComment) {
                question.answer._links.assistedComment = question.answer.assistedComment._links.self;
            }
            apiClient.update(question.getLink('editanswer'), question.answer).then(function (response) {
                if (response) {
                    $state.go('.', {}, {reload: $state.get('^.^')}, false).then(function () {
                        if (response.score < 100) {
                            auditService.auditAnswerFlashRaiseJobs.push(question.getLink('self'));
                        }
                        messenger.success('ANSWER_UPDATED');
                    });
                } else {
                    messenger.error('REQUEST_ERROR');
                }
            });
        }

        function updateQuestions() {
            var update = {
                _links: {questions: []},
            };

            // Build the record to be sent to the server
            angular.forEach(vm.questions, function (value, key) {
                update._links.questions.push(value._links.self);
            });

            apiClient.create(vm.questionsCollectionResponse.getLink('create-questions-changeset'), update).then(function (isSuccess) {
                if (isSuccess) {
                    $state.go('.', {}, {reload: true}).then(function () {
                        messenger.success('AUDIT_QUESTION_ORDER_UPDATED');

                    });
                } else {
                    messenger.error('REQUEST_ERROR');
                }
            });
        }

        function raiseAction(question, viaAnswer) {
            // load modal with priority select
            // on close add priority link resource to question.answer._links
            var priorityLinkParameters = {'active': true, 'entityTypes': 'entity_type_all,entity_type_action'};
            var modalInstance = $uibModal.open({
                templateUrl: '/modules/common/priorities/modal/priority-select-modal.html',
                backdrop: 'static',
                controller: 'PrioritySelectModalController',
                controllerAs: 'vm',
                resolve: {
                    priorityCollectionResponse: function(apiClient) {
                        return apiClient.get(vm.siteResponse.getLink('priorities'), priorityLinkParameters).then(function(response) {
                            if (response.priorities.length) {
                                return response;
                            }

                            return apiClient.get(vm.rootResourceResponse.getLink('priorities'), priorityLinkParameters).then(function(response) {
                                return response || apiClient.noResourceFound();
                            });
                        });
                    },
                    prioritySelectInstructions: function () {
                        return 'AUDIT_QUESTION_RAISE_ACTION_SELECT_PRIORITY';
                    },
                    entityTypes: function () {
                        return 'entity_type_all,entity_type_action';
                    }
                }
            }).result.then(function(response) {
                if (response.isByPlannedDate) {
                    question.answer.isByPlannedDate = response.isByPlannedDate;
                } else {
                    // on selected priority, add priority resource to question.answer._links.priority
                    question.answer._links.priority = {'href': response.priority.href};
                }

                // on selected priority, add priority resource to question.answer._links.priority
                question.answer.summary = response.summary;
                question.answer.description = response.description;

                var auditData = {
                    summary : question.answer.summary,
                    description: question.answer.description,
                    _links: {
                        priority : question.answer._links.priority,
                        question : question._links.self
                    }
                };

                if (viaAnswer) {
                    // creates action via backend
                    auditService.sendAnswer(question);
                } else {
                    apiClient.create(question.getLink('create-action'), auditData).then(function (response) {
                        if (response) {
                            $state.go('.', {}, {reload: $state.get('^.^')}, false).then(function () {
                                messenger.success('ACTION_RAISED');
                            });
                        } else {
                            messenger.error('REQUEST_ERROR');
                        }
                    });
                }
            });
        }
    }
})();
