(function() {
    'use strict';

    angular
        .module('elogbooks.admin.service-provider-groups')
        .controller('ServiceProviderAddController', ServiceProviderAddController);

        ServiceProviderAddController.$inject = ['$state', 'serviceProviderGroupsResponse', 'serviceProvidersCollectionResponse', 'rootServiceProvidersCollectionResponse', 'crudService', 'lodash'];

    function ServiceProviderAddController($state, serviceProviderGroupsResponse, serviceProvidersCollectionResponse, rootServiceProvidersCollectionResponse, crudService, lodash) {
        var vm = this;
        vm.groupServiceProviders = serviceProvidersCollectionResponse.serviceproviders;
        vm.serviceProviders = rootServiceProvidersCollectionResponse.serviceproviders;

        if (vm.serviceProviders.length > 0) {
            vm.serviceprovider = {
                response: rootServiceProvidersCollectionResponse,
                link: rootServiceProvidersCollectionResponse ? rootServiceProvidersCollectionResponse.getLink('self') : null,
                required: true,
                responseKeyPath: 'serviceproviders',
                itemHrefPath: '_links.self.href'
            };
        }

        vm.create = function() {
            if (!vm.serviceprovider.response) {
                return;
            }

            var serviceSelect = vm.serviceprovider.selected;
            var serviceProvider = lodash.find(serviceSelect, ['name', serviceSelect.object.name]);
            var groupServiceProvider = lodash.find(vm.groupServiceProviders, ['name', serviceSelect.object.name]);

            if (serviceProvider && !groupServiceProvider) {
                vm.groupServiceProviders.push(serviceProvider);
            }
            
            var data = {
                '_links': {
                    'serviceprovider': []
                }
            }
    
            angular.forEach(vm.groupServiceProviders, function (groupServiceProvider) {
                data._links.serviceprovider.push(groupServiceProvider._links.self);
            })

            crudService.update(serviceProvidersCollectionResponse._links.update.href, data, $state.current, false, 'SERVICE_PROVIDERS').then(function() {
                $state.go('^', {}, {reload: $state.get('^')});
            });
        }
    }
})();
