(function () {
    'use strict';

    angular
        .module('elogbooks.user.service-providers')
        .controller('UserServiceProvidersReporterFeedbackController', UserServiceProvidersReporterFeedbackController);

    UserServiceProvidersReporterFeedbackController.$inject = [
        '$state',
        '$stateParams',
        'moment',
        '$translate',
        'spReporterFeedbackCollectionResponse',
        'operativeCollectionResponse',
        'siteCollectionResponse'
    ];

    function UserServiceProvidersReporterFeedbackController (
        $state,
        $stateParams,
        moment,
        $translate,
        spReporterFeedbackCollectionResponse,
        operativeCollectionResponse,
        siteCollectionResponse
    ) {
        var vm = this;
        vm.reporterFeedback = spReporterFeedbackCollectionResponse.jobsReporterFeedback;
        vm.spReporterFeedbackCollectionResponse = spReporterFeedbackCollectionResponse;

        var yesNo = [
            {
                value: null,
                title: $translate.instant('PLEASE_SELECT')
            },
            {
                value: 'true',
                title: $translate.instant('YES')
            },
            {
                value: 'false',
                title: $translate.instant('NO')
            }
        ];

        var scoreOptions = [
            {
                value: null,
                title: $translate.instant('PLEASE_SELECT')
            },
            {
                value: 'true',
                title: $translate.instant('POSITIVE')
            },
            {
                value: 'false',
                title: $translate.instant('NEGATIVE')
            }
        ];

        vm.criteria = {
            job: { type: 'number', title: 'ID_JOB', value: $stateParams.job },
            score: { type: 'options', title: 'SCORE', value: $stateParams.score, options: scoreOptions },
            contactable: { type: 'options', title: 'CONTACTABLE', value: $stateParams.contactable, options: yesNo },
            dateRange: {
                type: 'date-range',
                title: 'BETWEEN_DATES',
                value: {
                    startDate: ($stateParams.dateRange !== null) ? moment(new Date($stateParams.dateRange.split(',')[0])) : null,
                    endDate: ($stateParams.dateRange !== null) ? moment(new Date($stateParams.dateRange.split(',')[1])) : null
                },
                clearValue: {
                    startDate: null,
                    endDate: null
                }
            }
        };

        if (operativeCollectionResponse) {
            vm.operativeSelect = {
                response : operativeCollectionResponse,
                link : operativeCollectionResponse ? operativeCollectionResponse.getLink('self') : null,
                itemValuePath: 'id',
                itemTitlePath: '_links.user.name',
                responseKeyPath: 'operatives'
            };

            vm.criteria.operative = {
                type: 'jsonselectwidget',
                title: 'OPERATIVE',
                value: JSON.parse($stateParams.operative),
                options: vm.operativeSelect
            };
        }

        if (siteCollectionResponse) {
            vm.siteSelect = {
                response : siteCollectionResponse,
                link : siteCollectionResponse ? siteCollectionResponse.getLink('self') : null,
                itemValuePath: 'id',
                itemTitlePath: 'name',
                responseKeyPath: 'sites'
            };

            vm.criteria.site = {
                type: 'jsonselectwidget',
                title: 'SITE',
                value: JSON.parse($stateParams.site),
                options: vm.siteSelect
            };
        }

        vm.search = searchAction;
        vm.order = orderAction;

        function searchAction(params) {
            var override = {
                feedbackPage: 1
            };

            $state.go('.', angular.extend({}, params, override), { reload: $state.current });
        }

        function orderAction(key) {
            $stateParams.feedbackOrder = key;

            var override = {
                feedbackPage: 1
            };

            $state.go('.', angular.extend({}, $stateParams, override), {reload: $state.current.name});
        }
    }
})();