app.config(function ($translateProvider) {
    $translateProvider.translations('zh-hk', {
        OPERATIVE_PERMISSIONS: '工作人員權限',

        ADD_OPERATIVE: '增加工作人員',

        OPERATIVE_PERMISSION_VIEW_ASSETS:	'查看資產',
        OPERATIVE_PERMISSION_CREATE_QUOTES:	'建立報價',
        OPERATIVE_PERMISSION_VIEW_QUOTES:   '查看報價',
        OPERATIVE_PERMISSION_EDIT_ASSETS:	'修改資產',
        OPERATIVE_PERMISSION_VIEW_TASKS:	'查看工作',
        OPERATIVE_PERMISSION_EDIT_TASKS:	'修改工作',
        OPERATIVE_PERMISSION_CREATE_TASKS:	'建立工作',
        OPERATIVE_PERMISSION_VIEW_FILES:	'查看檔案',
        OPERATIVE_PERMISSION_EDIT_FILES:	'修改檔案',
        OPERATIVE_PERMISSION_VIEW_PATROL_INSTANCES:	'查看巡邏實例',
        OPERATIVE_PERMISSION_EDIT_PATROL_INSTANCES:	'修改巡邏實例',
        OPERATIVE_PERMISSION_RECEIVE_PATROL_INSTANCES:	'接收巡邏實例',

        OPERATIVE_PERMISSION_CREATE_REACTIVE_JOBS: '建立重啟工作',
        OPERATIVE_PERMISSION_CREATE_REACTIVE_JOBS_FOR_OTHER_SERVICE_PROVIDERS: '建立重啟工作給其他服務提供商',
        OPERATIVE_PERMISSION_EDIT_CONTRACTS: '修改合約',
        OPERATIVE_PERMISSION_EDIT_JOBS: '修改工作',
        OPERATIVE_PERMISSION_EDIT_METER_READINGS: '修改儀表讀數',
        OPERATIVE_PERMISSION_EDIT_METERS: '修改儀表',
        OPERATIVE_PERMISSION_MANAGE_OPERATIVES: '管理工作人員',
        OPERATIVE_PERMISSION_VIEW_AUDITS: '查看審計',
        OPERATIVE_PERMISSION_RECEIVE_AUDITS: '接收審計',
        OPERATIVE_PERMISSION_RECEIVE_ALL_EMAILS: '接收所有電子郵件',
        OPERATIVE_PERMISSION_RECEIVE_CONTRACTS: '接收合約',
        OPERATIVE_PERMISSION_RECEIVE_ESCALATIONS: '接收上報',
        OPERATIVE_PERMISSION_RECEIVE_JOBS: '接收工作',
        OPERATIVE_PERMISSION_REQUEST_PLANNED_JOBS: '要求計劃的工作',
        OPERATIVE_PERMISSION_VIEW_CONTRACTS: '查看合約',
        OPERATIVE_PERMISSION_VIEW_JOBS: '查看工作',
        OPERATIVE_PERMISSION_VIEW_METERS: '查看儀表',
        OPERATIVE_PERMISSION_VIEW_SITES: '查看場地',
        OPERATIVE_PERMISSION_VIEW_SURVEYS: '查看調查',
        OPERATIVE_PERMISSION_EDIT_SURVEYS: '修改調查',
        OPERATIVE_PERMISSION_CREATE_SURVEYS: '建立調查',
        OPERATIVE_PERMISSION_VIEW_CONTACTS: '查看聯絡',

        OPERATIVE_PERMISSION_VIEW_ASSETS_DETAIL:	' ',
        OPERATIVE_PERMISSION_CREATE_QUOTES_DETAIL:	' ',
        OPERATIVE_PERMISSION_VIEW_QUOTES_DETAIL:  ' ',
        OPERATIVE_PERMISSION_EDIT_ASSETS_DETAIL:	' ',
        OPERATIVE_PERMISSION_VIEW_TASKS_DETAIL:	' ',
        OPERATIVE_PERMISSION_EDIT_TASKS_DETAIL:	' ',
        OPERATIVE_PERMISSION_CREATE_TASKS_DETAIL:	' ',
        OPERATIVE_PERMISSION_VIEW_FILES_DETAIL:	' ',
        OPERATIVE_PERMISSION_EDIT_FILES_DETAIL:	' ',
        OPERATIVE_PERMISSION_RECEIVE_AUDITS_DETAIL:	' ',
        OPERATIVE_PERMISSION_VIEW_AUDITS_DETAIL:	' ',
        OPERATIVE_PERMISSION_VIEW_PATROL_INSTANCES_DETAIL:	' ',
        OPERATIVE_PERMISSION_EDIT_PATROL_INSTANCES_DETAIL:	' ',
        OPERATIVE_PERMISSION_RECEIVE_PATROL_INSTANCES_DETAIL:	' ',

        OPERATIVE_PERMISSION_CREATE_REACTIVE_JOBS_DETAIL: '',
        OPERATIVE_PERMISSION_CREATE_REACTIVE_JOBS_FOR_OTHER_SERVICE_PROVIDERS_DETAIL: '',
        OPERATIVE_PERMISSION_EDIT_CONTRACTS_DETAIL: '',
        OPERATIVE_PERMISSION_EDIT_JOBS_DETAIL: '',
        OPERATIVE_PERMISSION_EDIT_METER_READINGS_DETAIL: '能夠建立，更新和刪除儀表讀數關於此服務供應商',
        OPERATIVE_PERMISSION_EDIT_METERS_DETAIL: '能夠建立，更新和刪除儀表關於此服務供應商',
        OPERATIVE_PERMISSION_MANAGE_OPERATIVES_DETAIL: '',
        OPERATIVE_PERMISSION_RECEIVE_ALL_EMAILS_DETAIL: '',
        OPERATIVE_PERMISSION_RECEIVE_CONTRACTS_DETAIL: '',
        OPERATIVE_PERMISSION_RECEIVE_JOBS_DETAIL: '',
        OPERATIVE_PERMISSION_REQUEST_PLANNED_JOBS_DETAIL: '',
        OPERATIVE_PERMISSION_VIEW_CONTRACTS_DETAIL: '',
        OPERATIVE_PERMISSION_VIEW_JOBS_DETAIL: '',
        OPERATIVE_PERMISSION_VIEW_METERS_DETAIL: '能夠查看儀表關於此服務供應商',
        OPERATIVE_PERMISSION_VIEW_SITES_DETAIL: '將此服務供應商的權限複印',
        OPERATIVE_PERMISSION_VIEW_SURVEYS_DETAIL: '',
        OPERATIVE_PERMISSION_EDIT_SURVEYS_DETAIL: '',
        OPERATIVE_PERMISSION_CREATE_SURVEYS_DETAIL: '',
        OPERATIVE_PERMISSION_VIEW_CONTACTS_DETAIL: '能夠查看場地聯絡人',
    });
});
