(function () {
    'use strict';

    angular
        .module('elogbooksControllers')
        .controller('BulkEditPatrolSchedulesModalController', BulkEditPatrolSchedulesModalController);

    BulkEditPatrolSchedulesModalController.$inject = ['$scope', 'apiClient', 'config', '$uibModalInstance', 'partnershipCollection', 'rowCount', 'callback', 'lodash', 'patrolService'];

    function BulkEditPatrolSchedulesModalController($scope, apiClient, config, $uibModalInstance, partnershipCollection, rowCount, callback, lodash, patrolService) {
        var vm = this;
        vm.update = update;
        vm.cancel = close;

        vm.config = config;
        vm.rowCount = rowCount;
        vm.form = { active : 'active', serviceprovider: null};
        vm.status = 'editing';

        vm.serviceProviderCollection = lodash.map(partnershipCollection.partnerships,
            function (partnership) {
                return {
                    value: partnership._links.serviceprovider.href,
                    title: partnership._links.serviceprovider.title
                };
            });

        /**
         * Bulk update the schedules
         */
        function update() {
            vm.status = 'updating';

            var selectedSchedules = [];
            for (var i = 0; i < callback.schedules.length; i++) {
                var schedule = callback.schedules[i];
                if (schedule.checked) {
                    selectedSchedules.push(schedule._links.self);
                }
            }

            var updateLink = callback.patrolSchedulesCollection._links.bulkupdate.href;

            patrolService.bulkUpdatePatrolSchedules(updateLink, this.form.serviceprovider, this.form.active, selectedSchedules)
                .then(
                    function() {
                        confirm();
                    }
                )
        }

        function close() {
            $uibModalInstance.dismiss();
        }

        function confirm() {
            $uibModalInstance.close();
        }
    }
})();
