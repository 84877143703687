(function () {
    'use strict';

    angular
        .module('elogbooksDirectives')
        .directive('elbActionWorkflowButtons', ElogbooksActionWorkflowButtons);

    ElogbooksActionWorkflowButtons.$inject = ['apiClient', 'messenger', 'modulesService', '$state', '$uibModal', '$translate'];

    function ElogbooksActionWorkflowButtons(apiClient, messenger, modulesService, $state, $uibModal, $translate) {
        return {
            restrict: 'AE',
            scope: {
                action: '=ngModel',
                routeBase: '@',
                routeParameters: '@'
            },
            templateUrl: '/modules/directives/elb-action-workflow-buttons/action-workflow-buttons.html',
            link: function ($scope, element, attributes) {
                $scope.getLink = getLink;
                $scope.sendToIntegration = sendToIntegration;

                function sendToIntegration(integration) {
                    $uibModal.open({
                        templateUrl: '/modules/common/actions/modal/' + integration + '.html',
                        controller: ['$uibModalInstance', function ($uibModalInstance) {
                            var vm = this;
                            vm.siteMapping = true;
                            vm.observation = $scope.action.summary;
                            vm.dueDate = new Date($scope.action.completionDueAt);
                            vm.integration = integration;
                            vm.priority = null;

                            vm.close = function () {
                                $uibModalInstance.close();
                            };

                            vm.submit = function () {
                                vm.data = {
                                    integration: vm.integration,
                                    observation: vm.observation,
                                    recommendation: vm.recommendation,
                                    priority: vm.priority,
                                    dueDate: vm.dueDate
                                };
                                postAction(vm.data, $uibModalInstance);
                            };

                            apiClient.get($scope.action.getLink('site-mapped')).then(function (response) {
                                if (!response) {
                                    $uibModalInstance.close();
                                    messenger.error('REQUEST_ERROR');
                                } else if (response.siteMapped) {
                                    vm.siteMapping = false;
                                } else if (!response.siteMapped && response.count === 0) {
                                    vm.errorMsg = $translate.instant('NO_PROPERTY_FOUND') + response.siteRef;
                                    vm.error = true;
                                } else if (!response.siteMapped && response.count > 1) {
                                    vm.errorMsg = $translate.instant('MORE_THAN_ONE_PROPERTY') + response.siteRef;
                                    vm.error = true;
                                } else{
                                    vm.errorMsg = $translate.instant('NO_PROPERTY_FOUND') + response.siteRef;
                                    vm.error = true;
                                }
                            });
                        }],
                        controllerAs: 'vm',
                        resolve: {}
                    });
                }

                function postAction(data, $uibModalInstance) {
                    apiClient.create($scope.action.getLink('integration'), data).then(function (response) {
                        if (response) {
                            $uibModalInstance.close();
                            $state.go('.', {}, {reload: true}).then(function () {
                                messenger.success('SUCCESS');
                            });
                        } else {
                            messenger.error('REQUEST_ERROR');
                        }
                    });
                }

                function getLink(route) {
                    if ($scope.routeBase) {
                        route = $scope.routeBase + route;
                    }

                    if ($scope.routeParameters) {
                        route = route + '(' + $scope.routeParameters + ')';
                    }

                    return route;
                }
            }
        };
    }
})();
