(function () {
    'use strict';

    angular
        .module('elogbooks.admin.sites')
        .controller( 'SiteDetailsEventFeedController', SiteDetailsEventFeedController);

        SiteDetailsEventFeedController.$inject = ['eventCollectionResponse'];

    function SiteDetailsEventFeedController (eventCollectionResponse) {
        var vm = this;
        vm.feed = [];
        vm.eventCollectionResponse = eventCollectionResponse;
    }
})();
