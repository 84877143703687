(function(){
    'use strict';

    angular
        .module('elogbooks')
        .config(registerRoutes);

    function registerRoutes($stateProvider) {

        $stateProvider
            .state('login', {
                url: "/login?destination",
                templateUrl: '/modules/common/login/login.html',
                controller: 'LoginController',
                controllerAs: 'vm',
                resolve: {
                    rootResource: function (apiClient, $rootScope) {
                        return apiClient.get('/').then(function(response) {
                            $rootScope.theme = response?.theme;
                            return response;
                        });
                    }
                }
            });

    }
})();
