(function(){
    'use strict';

    angular
        .module('elogbooks.user.patrols')
        .controller('PatrolSchedulesController', PatrolSchedulesController);

    PatrolSchedulesController.$inject = ['$state', '$stateParams', 'patrolSchedulesCollection'];

    function PatrolSchedulesController ($state, $stateParams, patrolSchedulesCollection) {
        var vm = this;
        vm.schedules = patrolSchedulesCollection.patrolSchedules;
        vm.schedules._links = patrolSchedulesCollection._links;
        vm.entityCount = patrolSchedulesCollection.count;
        vm.order = order;
        vm.search = search;

        vm.criteria = {
            scheduleName: { type: 'text', value: $stateParams.scheduleName, title: 'NAME' }
        }

        function search (params) {
            var override = {
                schedulePage: 1
            };

            $state.go('.', angular.extend({}, params, override), { reload: true });
        }

        function order (key) {
            $stateParams.scheduleOrder = key;

            var override = {
                schedulePage: 1
            };

            $state.go('.', angular.extend({}, $stateParams, override), { reload: true });
        }
    }
})();
