(function () {
    'use strict';

    angular
        .module('elogbooks.admin.sitegroups')
        .controller('SiteGroupSitesController', SiteGroupSitesController);

    SiteGroupSitesController.$inject = ['siteGroupResponse', 'selectedSiteCollectionResponse', '$stateParams', '$state', 'lodash', 'confirmationModal', 'messenger', 'apiClient'];

    function SiteGroupSitesController (siteGroupResponse, selectedSiteCollectionResponse, $stateParams, $state, lodash, confirmationModal, messenger, apiClient) {
        var vm = this;
        vm.siteGroup = siteGroupResponse;
        vm.sites = selectedSiteCollectionResponse.sites;
        vm.entityCount = selectedSiteCollectionResponse.count;
        vm.search = search;
        vm.order = order;
        vm.checked = {};
        vm.deleteSelected = deleteAction;

        vm.criteria = {
            siteId: { type: 'number', value: $stateParams.siteId ? parseInt($stateParams.siteId, 10) : null, title: 'ID' },
            siteName: { type: 'text', value: $stateParams.siteName, title: 'SITE' }
        }

        function search (params) {
            var override = {
                sitePage: 1
            };

            $state.go('.', angular.extend({}, params, override), { reload: true });
        }

        function order (key) {
            $stateParams.siteOrder = key;

            var override = {
                sitePage: 1
            };

            $state.go('.', angular.extend({}, $stateParams, override), { reload: true });
        }

        function deleteAction() {
            var toRemove = [];
            lodash.forEach(vm.checked, function(value, site) {
                if(value === true) {
                    toRemove.push({"href": site});
                }
            });

            if (toRemove.length > 0) {
                var data = {
                    _links: {}
                };

                data._links.site = toRemove;

                // Show an alert asking the user to confirm
                return confirmationModal.open().result.then(function () {
                    return apiClient.update(vm.siteGroup.getLink('sites'), data).then(function (response) {
                        if (response) {
                            $state.go('.', {}, { reload: $state.current.name }).then(function () {
                                messenger.success('SITES_DELETED_FROM_SITE_GROUP');
                            });
                        } else {
                            messenger.error('REQUEST_ERROR');
                        }
                    });
                });
            } else {
                // Show an alert telling the user they need to select at least one site
                return confirmationModal.open({
                    titleMessage: 'ZERO_OPTIONS_CHECKED_TITLE',
                    bodyMessage: 'ZERO_OPTIONS_CHECKED_MESSAGE',
                    primaryText: null,
                    secondaryText: 'OK'
                });
            }
        }
    }

})();
