/**
 * Patrol Service
 */
angular.module('elogbooksServices').service('patrolService', function (apiClient, $q) {
    this.bulkUpdatePatrolSchedules = bulkUpdatePatrolSchedulesAction;
    this.updatePatrolScheduleInstance = updatePatrolScheduleInstance;

    /**
     * bulkUpdatePatrolSchedules action
     *
     * @param {string} link - api link
     * @param {string} serviceprovider - The service provider link
     * @param {string} active - active|inactive
     * @param {array} schedules - the array of schedules link objects to change ([] = {href : })
     * @returns {object} promise
     */
    function bulkUpdatePatrolSchedulesAction(link, serviceprovider, active, schedules) {
        var deferred = $q.defer();

        var data = {
            _links: {
                schedules: schedules,
                serviceprovider: [ {href: serviceprovider }]
            },
            active: active
        };

        apiClient.update(link, data).then(function (response) {
            if (response) {
                return deferred.resolve(response);
            } else {
                return deferred.reject();
            }
        });

        return deferred.promise;
    }

    /**
     * Update the Patrol Schedule Instance
     *
     * @param api link
     * @param serviceprovider
     * @param operative
     * @returns {Promise}
     */
    function updatePatrolScheduleInstance (link, serviceprovider, operative) {
        var deferred = $q.defer();

        // Service provider is not optional
        var data = {
            _links: {
                serviceprovider: {href: serviceprovider }
            }
        };

        if (operative) {
            data._links.operative = {href: operative };
        }

        apiClient.update(link, data).then(function (response) {
            if (response) {
                return deferred.resolve(response);
            } else {
                return deferred.reject();
            }
        });

        return deferred.promise;
    }
});
