/**
 * Satisfaction Surveys list controller
 */
 (function() {
     'use strict';

     angular
        .module('elogbooks.helpdesk.satisfactionsurveys')
        .controller('SatisfactionSurveysController', SatisfactionSurveysController);

    SatisfactionSurveysController.$inject = ['$state', '$stateParams', '$filter', 'satisfactionSurveyCollectionResponse'];

    function SatisfactionSurveysController($state, $stateParams, $filter, satisfactionSurveyCollectionResponse) {

        var vm = this;
        vm.satisfactionSurveys = satisfactionSurveyCollectionResponse.getData().satisfactionsurveys;
        vm.listVisible = true;

        var statusOptions = [
            {
                title: $filter('translate')('NONE_SELECTED'),
                value: null
            },
            {
                title: $filter('translate')('SATISFACTIONSURVEY_FILTER_INCOMPLETEREQUIRESATTENTION'),
                value: 'incompleteattention'
            },
            {
                title: $filter('translate')('SATISFACTIONSURVEY_FILTER_INCOMPLETENOATTENTION'),
                value: 'incompletenoattention'
            },
            {
                title: $filter('translate')('SATISFACTIONSURVEY_FILTER_COMPLETE'),
                value: 'complete'
            }
        ];

        vm.criteria = {
            id: { type: 'number', title: 'ID', value: $stateParams.id ? parseInt($stateParams.id, 10) : null, min: 1 },
            job: { type: 'number', title: 'Job', value: $stateParams.job, min: 1 },
            status: { type: 'options', title: 'STATUS', value: $stateParams.status, options: statusOptions },
        };

        vm.search = function (params) {
            var override = {
                page: 1
            };

            $state.go('.', angular.extend({}, params, override), { reload: $state.current.name });
        };
    };

 })();
