app.config(function ($translateProvider) {
    $translateProvider.translations('nl', {
        SECTOR: 'Gebouwtype',
        SECTORS: 'Type Gebouw',

        SECTOR_INFORMATION: 'Informatie',
        MANAGE_SECTOR_SITES: 'Objecten van dit type',

        ADD_NEW_SECTOR: 'Type toevoegen',
        EDIT_SECTOR: 'Type wijzigen',

        SECTOR_LIST: 'Gebouwtype',
        SECTOR_ADD: 'Nieuw type toevoegen',
        SECTOR_EDIT: 'Type wijzigen',

        SECTOR_CREATED: 'Type gemaakt',
        SECTOR_UPDATED: 'Type gewijzigd',
        SECTOR_DELETED: 'Type verwijderd',

        SECTOR_SITE_LIST: 'Objecten',
        SECTOR_SITE_ADDED: 'Type objecten toegevoegd',
        SITES_DELETED_FROM_SECTOR: 'Type objecten verwijderd',
    });
});
