(function () {
    'use strict';

    angular
        .module('elogbooks.admin.users')
        .controller('UserInfoController', UserInfoController);

    UserInfoController.$inject = [
        'userResponse',
        'contactInformationResponse',
        '$uibModal',
        'userManager',
        'elbSettings',
        'recurrenceRulesService',
        'confirmationModal',
        'apiClient',
        '$state',
        'messenger',
        'config'
    ];

    function UserInfoController (
        userResponse,
        contactInformationResponse,
        $uibModal,
        userManager,
        elbSettings,
        recurrenceRulesService,
        confirmationModal,
        apiClient,
        $state,
        messenger,
        config
    ) {
        var vm = this;
        vm.user = userResponse;
        vm.config = config;
        vm.contactInformation = contactInformationResponse;
        vm.resetPassword = resetPassword;
        vm.unlock = unlock;
        vm.passwordResetEmail = passwordResetEmail;
        vm.usernameReminderEmail = usernameReminderEmail;
        vm.loginAsUser = loginAsUser;
        vm.showLoginAsUser = (vm.user.id != userManager.user.id);
        vm.originalUser = userManager.originalUser;
        vm.absenceModuleEnabled = elbSettings.getSetting('absence_planner_enabled') ? elbSettings.getSetting('absence_planner_enabled').value : false;
        vm.recurrenceRulesService = recurrenceRulesService;
        vm.clientAdminAccess = userManager.hasPermission('user_permission_client_admin_manage_users') && !userManager.hasPermission('user_permission_manage_users');

        vm.defaultYearlyEntitlement = elbSettings.getSetting('default_yearly_entitlement').value;
        vm.defaultHolidayYearStartDay = elbSettings.getSetting('default_holiday_year_start_day').value;
        vm.defaultHolidayYearStartMonth = elbSettings.getSetting('default_holiday_year_start_month').value;

        function resetPassword () {
            var modalInstance = $uibModal.open({
                templateUrl: '/modules/admin/users/details/user-password-reset-modal.html',
                controller: 'UserPasswordResetModalController',
                controllerAs: 'vm',
                resolve: {
                    userResource: function () {
                        return userResponse;
                    }
                }
            });
        }

        function unlock () {
            var modalInstance = $uibModal.open({
                templateUrl: '/modules/admin/users/details/user-unlock-modal.html',
                controller: 'UserUnlockModalController',
                controllerAs: 'vm',
                resolve: {
                    userResource: function () {
                        return userResponse;
                    }
                }
            });
        }

        function loginAsUser () {
            var config = {source: 'web-login-as'};
            userManager.loginAsUser(vm.user, config);
        }

        function passwordResetEmail() {
            return confirmationModal.open().result.then(function() {
                return apiClient.create(vm.config.passwordChangeRequestLink, {username: vm.user.username, from: 'userInfoController'}).then(function (response) {
                    if (response) {
                        $state.go('.', null, { reload: 'dashboard.admin.users.list.details' }).then(function () {
                            messenger.success('PASSWORD_RESET_EMAIL_SENT');
                        });
                    } else {
                        messenger.error('REQUEST_ERROR');
                    }
                })
            });
        }

        function usernameReminderEmail() {
            return confirmationModal.open().result.then(function() {
                return apiClient.create(vm.config.usernameReminderLink, {username: vm.user.username, from: 'userInfoController'}).then(function (response) {
                    if (response) {
                        $state.go('.', null, { reload: 'dashboard.admin.users.list.details' }).then(function () {
                            messenger.success('USERNAME_REMINDER_EMAIL_SENT');
                        });
                    } else {
                        messenger.error('REQUEST_ERROR');
                    }
                })
            });    
        }
    }
})();
