app.config(function ($translateProvider) {
    $translateProvider.translations('zh-hk', {

        ADD_NEW_JOB_REQUISITES: '新增工作需求',
        ADD_PARTNERSHIP: '新增夥伴關係',
        ALLOW_NOT_APPLICABLE: '容許不適用',
        ANSWERED_NA: '回答 \'N/A\'',
        ANSWERED_YES: '回答 \'Yes\'',
        ATTACHED_FILES: '附加的檔案',
        DAILOG_PARTNERSHIP_EDIT: '修改夥伴關係',
        DELETE_PARTNERSHIP: '刪除夥伴關係',
        DELETE_SELECTED: '刪除所選',
        DISALLOW_NOT_APPLICABLE: '不容許不適用',
        EDIT_JOB_REQUISITE: '修改工作需求',
        HASNOTAPPLICABLEOPTION: '容許不適用選項',
        HELPMESSAGE: '幫助文字',
        JOB_REQUISITE: '工作需求',
        JOB_REQUISITE_ADD: '新增工作需求',
        JOB_REQUISITE_CREATED: '工作需求已建立',
        JOB_REQUISITE_DELETED: '工作需求已刪除',
        JOB_REQUISITE_EDIT: '修改工作需求',
        JOB_REQUISITE_LIST: '工作需求',
        JOB_REQUISITE_PARTNERSHIP_ADDED: '工作需求夥伴關係已增加',
        JOB_REQUISITE_PARTNERSHIP_EDIT: '工作需求夥伴關係',
        JOB_REQUISITE_PARTNERSHIP_LIST: '夥伴關係',
        JOB_REQUISITE_SERVICE_PROVIDER_LIST: '服務供應商',
        JOB_REQUISITE_SERVICEPROVIDER_ADDED: '工作需求服務供應商已增加',
        JOB_REQUISITE_SERVICEPROVIDER_EDIT: '修改工作需求服務供應商',
        JOB_REQUISITE_SITE_ADDED: '工作需求地點已增加',
        JOB_REQUISITE_SITE_EDIT: '修改工作需求地點',
        JOB_REQUISITE_SITE_LIST: '地點',
        JOB_REQUISITE_UPDATED: '工作需求已更新',
        JOB_REQUISITES: '工作需求',
        JOB_REQUISITEUPDATED: '工作需求已更新',
        NO_ATTACHMENT: '沒有附件',
        PARTNERSHIPS_DELETED_FROM_JOB_REQUISITE: '工作需求夥伴關係已刪除',
        REQUEST_ATTACHMENT: '需要附件',
        REQUESTATTACHMENT: '需要附件',
        SERVICEPROVIDERS_DELETED_FROM_JOB_REQUISITE: '工作需求服務供應商已刪除',
        SITES_DELETED_FROM_JOB_REQUISITE: '工作需求地點已刪除'

    });
});
