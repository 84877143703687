/**
 * QuoteCall types
 */
angular.module('elogbooksServices').service('quoteCallTypes', function() {

    var types = {
        note: "Note",
        chase: "Chase",
        client: "Client",
        other: "Other",
        eng: "Engineer",
        manager: "Manager",
        subcon: "Sub Con",
        group: "Group"
    };

    this.getAll = function () {
        return types;
    };

    return this;
});
