(function () {
    'use strict';

    angular
        .module('elogbooks.common.labour-lines')
        .controller('CommonLabourLinesAddEditController', CommonLabourLinesAddEditController);

    CommonLabourLinesAddEditController.$inject = [
        'apiClient',
        '$state',
        'messenger',
        'serviceProviderResponse',
        'jobLabourResponse',
        'jobLabourLinesCollectionResponse',
        'operativeCollectionResponse',
        'requestDataFactory',
        'lodash',
        'confirmationModal',
        'labourTypeCollectionResponse'
    ];

    function CommonLabourLinesAddEditController(
        apiClient,
        $state,
        messenger,
        serviceProviderResponse,
        jobLabourResponse,
        jobLabourLinesCollectionResponse,
        operativeCollectionResponse,
        requestDataFactory,
        lodash,
        confirmationModal,
        labourTypeCollectionResponse
    ) {
        var vm = this;
        vm.jobLabourResponse = jobLabourResponse;

        vm.serviceProviderResponse = serviceProviderResponse;
        vm.labourTypeCollectionResponse = labourTypeCollectionResponse
        vm.minutesWorkedHH = ((vm.jobLabourResponse.minutesWorked - vm.jobLabourResponse.minutesWorked % 60)) / 60;
        vm.minutesWorkedMM = vm.jobLabourResponse.minutesWorked % 60;
        vm.minutesTravelledHH = ((vm.jobLabourResponse.minutesTravelled - vm.jobLabourResponse.minutesTravelled % 60)) / 60;
        vm.minutesTravelledMM = vm.jobLabourResponse.minutesTravelled % 60;
        vm.mileage = vm.jobLabourResponse.mileage || 0;
        vm.labourTypeModel = {}

        vm.create = createAction;
        vm.update = updateAction;
        vm.delete = deleteAction;
        vm.inputChange = inputChange;
        vm.form = null;
        vm.setForm = form => vm.form = form;

        let mandatoryFields = lodash.get(vm.serviceProviderResponse, 'mandatoryLabourLineOptions', []);
        vm.isMandatory = (field) => mandatoryFields.indexOf(field) >= 0;

        // pre select
        if (!lodash.isEmpty(jobLabourResponse)) {
            vm.selected = {
                'href': jobLabourResponse._links.operative.href,
                'title': jobLabourResponse._links.operative.title,
                'object': jobLabourResponse._links.operative
            };
        } else if (!lodash.isEmpty(operativeCollectionResponse) && operativeCollectionResponse.operatives.length > 0 && serviceProviderResponse) {
            operativeCollectionResponse.operatives.forEach(function (value) {
                if (value._links.serviceprovider.href === serviceProviderResponse._links.self.href) {
                    vm.selected = {
                        'href': value._links.self.href,
                        'title': value._links.self.title,
                        'object': value._links.self
                    };
                }
            });
        }

        vm.operativeSelect = {
            required: true,
            response: !lodash.isEmpty(operativeCollectionResponse) ? operativeCollectionResponse : null,
            link: operativeCollectionResponse ? operativeCollectionResponse.getLink('self') : null,
            responseKeyPath: 'operatives',
            itemHrefPath: '_links.self.href',
            itemValuePath: '_links.user.name',
            onSelect: function () {
                vm.data._links.operative = {
                    href: vm.operativeSelect.selected.href
                }
            },
            selected: vm.selected
        };

        if (labourTypeCollectionResponse) {
            vm.labourTypeModel = {
                response: labourTypeCollectionResponse,
                link: labourTypeCollectionResponse.getLink('self'),
                responseKeyPath: 'labourTypes',
                itemHrefPath: '_links.self.href',
                itemValuePath: 'name',
            }

            if (jobLabourResponse.id && jobLabourResponse.getLink('labourType')) {
                vm.labourTypeModel.selected = {
                    'href': jobLabourResponse._links.labourType ? jobLabourResponse._links.labourType.href : null,
                    'title': jobLabourResponse._links.labourType ? jobLabourResponse._links.labourType.name : null,
                    'object': jobLabourResponse._links.labourType ? jobLabourResponse._links.labourType : null
                }
            }
        }

        vm.data = requestDataFactory.createRequest(getDefaults());

        function inputChange() {
            if (vm.minutesWorkedMM < 0) {
                vm.minutesWorkedMM = 0;
            }

            if (vm.minutesWorkedMM > 59) {
                vm.minutesWorkedMM = 59;
            }

            if (vm.minutesTravelledMM < 0) {
                vm.minutesTravelledMM = 0;
            }

            if (vm.minutesTravelledMM > 59) {
                vm.minutesTravelledMM = 59;
            }
        }

        function createAction()
        {
            if (vm.labourTypeModel && vm.labourTypeModel.selected && vm.labourTypeModel.selected.href) {
                vm.data._links.labourType = vm.labourTypeModel.selected.href;
            } else {
                vm.data._links.labourType = null;
            }

            vm.data.minutesWorked = (vm.minutesWorkedHH || 0) * 60 + (vm.minutesWorkedMM || 0);
            vm.data.minutesTravelled = (vm.minutesTravelledHH || 0) * 60 + (vm.minutesTravelledMM || 0);

            return apiClient.create(jobLabourLinesCollectionResponse.getLink('create'), vm.data).then(function (response) {
                if (response) {
                    $state.go('^', {}, {reload: $state.get('^.^.^')}).then(function () {
                        messenger.success('JOB_LABOUR_CREATED');
                    });
                } else {
                    messenger.error('REQUEST_ERROR');
                }
            });
        }

        function updateAction()
        {
            if (vm.labourTypeModel && vm.labourTypeModel.selected && vm.labourTypeModel.selected.href) {
                vm.data._links.labourType = vm.labourTypeModel.selected.href;
            } else {
                vm.data._links.labourType = null;
            }

            vm.data.minutesWorked = (vm.minutesWorkedHH || 0) * 60 + (vm.minutesWorkedMM || 0);
            vm.data.minutesTravelled = (vm.minutesTravelledHH || 0) * 60 + (vm.minutesTravelledMM || 0);

            return apiClient.update(vm.jobLabourResponse.getLink('edit'), vm.data).then(function (response) {
                if (response) {
                    $state.go('^', {}, {reload: $state.get('^.^.^')}).then(function () {
                        messenger.success('JOB_LABOUR_UPDATED');
                    });
                } else {
                    messenger.error('REQUEST_ERROR');
                }
            });
        }

        function deleteAction() {
            return confirmationModal.open().result.then(function (response) {
                if (response.result) {
                    apiClient.delete(vm.jobLabourResponse.getLink('delete')).then(function (isSuccess) {
                        if (isSuccess) {
                            $state.go('^', {}, {reload: $state.get('^')}).then(function () {
                                messenger.success('JOB_LABOUR_DELETED');
                            });
                        } else {
                            messenger.error('REQUEST_ERROR');
                        }
                    });
                }

                return;
            });
        }

        function getDefaults() {
            return {
                description: vm.jobLabourResponse.description ? vm.jobLabourResponse.description : '',
                _links: {
                    operative: vm.selected ? vm.selected.href : null,
                    labourType: vm.labourTypeModel.selected ? vm.labourTypeModel.selected : null
                },
                startDate: vm.jobLabourResponse.startDate ? new Date(vm.jobLabourResponse.startDate) : null,
                mileage: vm.jobLabourResponse.mileage || null
            };
        }
    }
})();
