(function () {
    'use strict';

    angular
        .module('elogbooksDirectives')
        .controller('ElbFileBlockController', ElbFileBlockController);

    ElbFileBlockController.$inject = ['$scope', '$state', 'apiClient', 'elbFileModalService', '$rootScope'];

    function ElbFileBlockController ($scope, $state, apiClient, elbFileModalService, $rootScope) {
        var vm = this;
            vm.file = $scope.ngModel;
            vm.tags = $scope.tags;
            vm.hideTags = $scope.hideTags;
            vm.file.expiresAt = vm.file.expiresAt ? new Date(vm.file.expiresAt) : null;

            vm.disallowClick = $scope.disallowClick;
            vm.isExpired = vm.file.expiresAt !== null && vm.file.expiresAt < new Date();

            vm.show = showAction;

        function showAction () {
            if ($rootScope.bookerize.enabled) {
                var stateUrl = $state.current.name.substr(0, $state.current.name.lastIndexOf('.list'))+'.list';
                return $state.go(stateUrl + '.details', { fileResource: vm.file._links.self.href.encode() }, { reload: false });
            }

            return elbFileModalService.open(vm.file, vm.tags);
        }

        $scope.$watch('vm.file.expiresAt', function (newValue, oldValue) {
            if (newValue && newValue != oldValue) {
                vm.isExpired = newValue < new Date();
            }
        });
    }
})();
