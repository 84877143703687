(function () {
    'use strict';

    angular
        .module('elogbooks.common.assets')
        // .module('elogbooks.user.sites.assets')
        .controller('UserAssetsConditionsController', UserAssetsConditionsController);

    UserAssetsConditionsController.$inject = ['conditionCollectionResponse'];

    function UserAssetsConditionsController(conditionCollectionResponse) {
        var vm = this;
            vm.conditions = conditionCollectionResponse.conditions;
            vm.conditionCollectionResponse = conditionCollectionResponse;
            vm.pages = conditionCollectionResponse.pages;

    }
})();
