(function () {
    'use strict';

    angular
        .module('elogbooks.user.manage-operatives')
        .controller('ManageOperativeServiceProvidersController', ManageOperativeServiceProvidersController);

    ManageOperativeServiceProvidersController.$inject = ['$stateParams', '$state', 'serviceProviderCollection', 'apiClient', '$uibModal', 'operativeResponse', 'userResponse', 'messenger'];

    function ManageOperativeServiceProvidersController($stateParams, $state, serviceProviderCollection, apiClient, $uibModal, operativeResponse, userResponse, messenger) {
        var vm = this;

        vm.serviceProviders = serviceProviderCollection.serviceproviders;
        vm.operative = operativeResponse;
        var params = angular.copy($stateParams);

        // close expandable windows if main page changes
        if ($stateParams.changed === 'true') {
            $stateParams.position = null;
        }

        if ($stateParams.position > vm.serviceProviders.length) {
            $stateParams.position = null;
        }

        vm.serviceProviders = serviceProviderCollection.serviceproviders.map(function (value, index) {
            if (!$stateParams.position) {
                value.closed = true;
            } else if ($stateParams.position != index) {
                value.closed = true;
            }
            return value;
        });

        vm.serviceProviderCollection = serviceProviderCollection;
        vm.siteMemberships = [];

        vm.search = search;
        vm.order = order;
        vm.getSites = getSiteMemberships;
        vm.addSite = addSite;
        vm.addServiceProvider = addServiceProvider;
        vm.deleteMembership = deleteMembership;

        /**
         * get sites when expanding window
         * @param serviceProvider
         * @param $index
         * @param init
         */
        function getSiteMemberships(serviceProvider, $index, init) {
            $stateParams.changed = null;
            params.changed = null;
            if (serviceProvider.closed) {
                if (!init) {
                    params.position = $stateParams.position = $index;
                    params.page = $stateParams.sitesPage = '1';
                    // do this so above $stateParams are updated before list fetch
                    $state.go('.', params, {notify: false});
                }
            }

            function fetchMemberships() {
                if (userResponse.getLink('memberships')) {
                    vm.loading = true;

                    delete params.id;
                    delete params.order;
                    params.serviceProviderId = serviceProvider.id;
                    params.userId = vm.operative.id;
                    params.page = params.sitesPage;
                    apiClient.get(userResponse.getLink('memberships'), params).then(function (response) {
                        vm.siteMemberships[$index] = response;
                        vm.loading = false;
                    });
                }
            }

            // if refresh page and window open get operatives
            if ($stateParams.position === $index && init) {
                if (!vm.siteMemberships[$index]) {
                    fetchMemberships();
                }
            }

            if (!init) {
                // opening window
                if (serviceProvider.closed) {
                    fetchMemberships();
                    // close all, then open this one
                    vm.serviceProviders = serviceProviderCollection.serviceproviders.map(function (value) {
                        value.closed = true;
                        return value;
                    });
                    serviceProvider.closed = false;
                    // closing window
                } else {
                    serviceProvider.closed = true;
                    $stateParams.position = null;
                }
            }

            params.position = $stateParams.position;
        }

        if ($stateParams.position > 0 || $stateParams.position === '0') {
            if (vm.serviceProviders.length !== 0) {
                getSiteMemberships(vm.serviceProviders[$stateParams.position], $stateParams.position, true);
            }
        }

        /**
         * add the operative to the sp
         * @param serviceProvider
         */
        function addSite(serviceProvider) {
            var membershipParams = {
                excludeSiteMemberships: true,
                userId: vm.operative.id,
                serviceProviderId: serviceProvider.id
            };

            $uibModal.open({
                templateUrl: '/modules/common/service-provider/modal/site-membership-add-modal.html',
                controller: 'SiteMembershipAddModalController',
                controllerAs: 'vm',
                resolve: {
                    operativeResponse: vm.operative,
                    linkParams: membershipParams,
                    siteMemebershipCollectionResponse: function () {
                        return apiClient.get(userResponse.getLink('memberships'), membershipParams).then(function (response) {
                            return response;
                        });
                    }
                }
            });
        }

        function addServiceProvider() {
            $uibModal.open({
                templateUrl: '/modules/common/service-provider/modal/service-provider-add-modal.html',
                controller: 'ServiceProviderAddModalController',
                controllerAs: 'vm',
                resolve: {
                    operativeResponse: vm.operative,
                    serviceProviderCollectionResponse: function() {
                        var spParams = angular.copy($stateParams);
                        spParams.excludeOperative = vm.operative.id;
                        return apiClient.get(userResponse.getLink('serviceproviders'), spParams);
                    },
                    config: function() {
                        return {
                            useDefaultManageOperativePermissions: true
                        }
                    }
                }
            });
        }

        function deleteMembership(membership) {
            var params = {
                userId: vm.operative.id
            };
            apiClient.delete(membership.getLink('delete-membership'), params).then(function (response) {
                if (response) {
                    $state.go('.', {}, {reload: true});
                } else {
                    messenger.error('Unable to delete, please try again later');
                }
            });
        }

        function search(params) {
            var override = {
                operativePage: 1,
                position: null
            };

            $state.go('.', angular.extend({}, params, override), {reload: $state.current});
        }

        function order(key) {
            $stateParams.order = key;

            var override = {
                page: 1
            };

            $state.go('.', angular.extend({}, $stateParams, override), {reload: $state.current});
        }
    }
})();
