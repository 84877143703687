(function () {
    'use strict';

    angular
        .module('elogbooksControllers')
        .controller('PatrolInstanceModalController', PatrolInstanceModalController);

    PatrolInstanceModalController.$inject = ['$scope', '$state', 'messenger', 'apiClient', 'config', '$uibModalInstance', 'instanceResponse', 'lodash', 'patrolService', 'moment', 'requestDataFactory'];

    function PatrolInstanceModalController($scope, $state, messenger, apiClient, config, $uibModalInstance, instanceResponse, lodash, patrolService, moment, requestDataFactory) {
        var vm = this;
        vm.confirm = confirm;
        vm.close = close;
        vm.update = function () {
            // Do Update
            vm.view = 'details';
            confirm();
            patrolService.updatePatrolScheduleInstance(instanceResponse._links.edit.href, vm.data.serviceprovider, vm.data.operative).then(
                function () {
                    $state.go('.', {}, {reload: 'dashboard.user.sites.list.details.patrols'}).then(function(){
                        messenger.success('Patrol Instance updated successfully');
                    });
                }
            )
        };
        vm.cancel = function () {
            vm.view = 'details';
        };
        vm.config = config;
        vm.view = "details";
        vm.instance = instanceResponse;
        vm.data = requestDataFactory.createRequest(getDefaults());
        vm.operatives = [];
        vm.canEditInstance = false;

        vm.instance.serviceprovider = lodash.has(vm.instance, '_links.serviceprovider.href') ? vm.instance._links.serviceprovider.href : null;
        vm.instance.operative = lodash.has(vm.instance, '_links.operative.href') ? vm.instance._links.operative.href : null;
        vm.plannedStartOverdue = moment().isAfter(vm.instance.plannedStartDate) && vm.instance.status === 'registered';
        vm.plannedEndOverdue = moment().isAfter(vm.instance.plannedEndDate) && (vm.instance.status === 'in_progress' || vm.instance.status === 'registered');


        apiClient.get(vm.instance._links.points.href).then(
            function (response) {
                vm.points = response.patrolPointInstances;

                // Determine if the instance can be edited
                vm.canEditInstance = lodash.has(vm.instance, '_links.edit.href') && (vm.instance.status === 'in_progress' || vm.instance.status === 'registered');
                if (vm.canEditInstance) {
                    angular.forEach(vm.points, function(point) {
                        if (point.status !== 'WAITING') {
                            vm.canEditInstance = false;
                        }
                    });
                }
            }
        );


        $scope.$on('panel-entity:title:button:click', function () {
            vm.view = "edit";

            apiClient.get(instanceResponse._links.site.href).then(
                function (response) {
                    apiClient.get(response.getLink('partnerships')).then(
                        function (response) {
                            if (response) {
                                vm.providers = lodash.transform(response.partnerships,
                                    function (result, n) {
                                        result.push(
                                            {
                                                value: n._links.serviceprovider.href,
                                                title: n._links.serviceprovider.title
                                            }
                                        );
                                    });
                            }
                        }
                    );
                }
            );

            return;
        });

        $scope.$watch('vm.instance.serviceprovider', function() {
            vm.operatives = [];
            if (!vm.instance.serviceprovider) { return; }

            apiClient.get(vm.instance.serviceprovider).then(
                function (response) {
                    apiClient.get(response.getLink('operatives'), {'order': 'name'}).then(
                        function (response) {
                            if (response) {
                                vm.operatives = lodash.transform(response.operatives,
                                    function (result, n) {
                                        result.push(
                                            {
                                                value: n._links.self.href,
                                                title: n._links.user.title
                                            }
                                        );
                                    });
                            }
                        }
                    );
                }
            );
        });

        function close() {
            $uibModalInstance.dismiss(vm.instance);
        }

        function confirm() {
            $uibModalInstance.close(vm.instance);
        }

        function getDefaults() {
            return {
                _links: {
                    serviceprovider: lodash.has(vm.instance, '_links.serviceprovider.href') ? vm.instance._links.serviceprovider : null,
                    operative: lodash.has(vm.instance, '_links.operative.href') ? vm.instance._links.operative : null
                },
                serviceprovider: lodash.has(vm.instance, '_links.serviceprovider.href') ? vm.instance._links.serviceprovider.href : null,
                operative: lodash.has(vm.instance, '_links.operative.href') ? vm.instance._links.operative.href : null
            };
        }
    };
})();
