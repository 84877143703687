angular.module('elogbooksServices').service('paramConverterService', ['lodash', function(lodash) {

    this.formatResponse = formatResponse;
    this.checkStateParams = checkStateParams;
    this.checkNullAndConvert = checkNullAndConvert;
    this.getSelectedParam = getSelectedParam;
    this.getFromCollection = getFromCollection;
    this.saveParams = saveParams;

    function formatResponse($collection) {
        var collectionArray = [];
        $collection.forEach(function(item){
            collectionArray.push({title: item.name, value: item.id, isChild: item.isChild ? true : false});
        });
        return collectionArray;
    }

    function checkStateParams($stateParam) {
        var params = null;
        if ($stateParam && $stateParam.length > 0) {
            params = $stateParam.map(
                function(val) {
                    return parseInt(val);
                });
        } else {
            params = $stateParam;
        }

        return params;
    }

    function getFromCollection($collection) {
        var values = [];
        lodash.forEach($collection, function(value) {
            values.push(value._links.user);
        });

        return values;
    }

    function checkNullAndConvert($param) {
        var paramValue = null;

        if ($param !== null && !isNaN($param)) {
            paramValue = Number($param);
        }

        return paramValue;
    }

    function getSelectedParam($collection, $stateParam, searchObjectParams) {
        var selected = null;

        if (searchObjectParams) {
            var selectedResource = lodash.find($collection, searchObjectParams.searchObject);

            if (selectedResource) {
                selected = {
                    value: lodash.get(selectedResource, searchObjectParams.itemValuePath),
                    title: lodash.get(selectedResource, searchObjectParams.itemTitlePath)
                }
            }
        } else if ($stateParam) {
            var selectedResource = lodash.find($collection, {'id': parseInt($stateParam)});

            if (selectedResource) {
                selected = {
                    value: selectedResource.id,
                    title: selectedResource.name
                }
            }
        }

        return selected;
    }

    function saveParams(selectModel, params) {
        // mapValue is for data select widget selections
        if (selectModel && selectModel.mapValue) {
            if (typeof selectModel.autocompletemulti !== 'undefined' && selectModel.autocompletemulti) {
                params[selectModel.mapValue] = selectModel.selectedItems.map(
                    function (item) {
                        return item.href;
                    }
                );
                params['items'] = selectModel.selectedItems.map(
                    function (item) {
                        return item;
                    }
                );
            } else {
                if (selectModel.selected === undefined) {
                    params[selectModel.mapValue] = null;
                } else {
                    if (selectModel.itemHrefPath === '_links.self.href') {
                        params[selectModel.mapValue] = selectModel.selected.object[selectModel.itemValuePath ? selectModel.itemValuePath : 'id'];
                    } else {
                        params[selectModel.mapValue] = lodash.get(selectModel.selected.object, selectModel.itemValuePath);
                    }
                }
            }
        } else {
            params = selectModel;
        }

        return params;
    }
}]);
