angular.module('elogbooksServices').service('jobRequisites', function(lodash, $translate) {
    this.approvalStrategyOptions = [{
        value: 0,
        title: $translate.instant('NO_APPROVAL_REQUIRED')
    }, {
        value: 1,
        title: $translate.instant('APPROVER_APPROVAL_REQUIRED')
    }, {
        value: 2,
        title: $translate.instant('PRIMARY_AND_APPROVER_APPROVAL_REQUIRED')
    }, {
        value: 3,
        title: $translate.instant('PRIMARY_OPERATIVE')
    }];

    this.optInChoices = [{
        value: 0,
        title: $translate.instant('NOT_APPLICABLE')
    }, {
        value: 1,
        title: $translate.instant('MANDATORY')
    }, {
        value: 2,
        title: $translate.instant('OPT_IN')
    }, {
        value: 3,
        title: $translate.instant('OPT_OUT')
    }];

    return this;
});
