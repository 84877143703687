(function () {
    'use strict';

    angular
        .module('elogbooks.admin.billing-types')
        .config(registerRoutes)
        .config(registerRoutesList)
        .config(registerRoutesAdd)
        .config(registerRoutesDetails)
        .config(registerRoutesEdit);

    function registerRoutes ($stateProvider) {
        $stateProvider
            .state('dashboard.admin.billing-types', {
                abstract: true,
                templateUrl: 'shared-templates/view.html',
                ncyBreadcrumb: {
                    skip: true
                },
                data: {
                    permissions: ['user_permission_manage_billing_types']
                }
            });
    }

    function registerRoutesList ($stateProvider) {
        $stateProvider
            .state('dashboard.admin.billing-types.list', {
                url: '/billing-types?page&limit&order',
                parent: 'dashboard.admin.billing-types',
                data: {
                    permissions: ['user_permission_manage_billing_types']
                },
                views: {
                    '@dashboard.admin.billing-types': {
                        templateUrl: '/modules/admin/billing-types/billing-types-list.html',
                        controller: 'BillingTypesController',
                        controllerAs: 'vm'
                    }
                },
                params: {
                    page: '1',
                    limit: '30',
                    order: 'id',
                    id: null,
                    name: null
                },
                resolve: {
                    billingTypesCollectionResponse: function (apiClient, $stateParams, rootResourceResponse) {
                        return apiClient.get(rootResourceResponse.getLink('billingtypes'), $stateParams).then(function(response) {
                            return response || apiClient.noResourceFound();
                        });
                    }
                },
                ncyBreadcrumb: {
                    label: '{{ ::"BILLING_TYPES" | translate }}'
                }
            });
    }

    function registerRoutesAdd ($stateProvider) {
        $stateProvider
            .state('dashboard.admin.billing-types.list.add', {
                url: '/add',
                parent: 'dashboard.admin.billing-types.list',
                data: {
                    permissions: ['user_permission_manage_billing_types']
                },
                views: {
                    "@dashboard.admin.billing-types": {
                        templateUrl: '/modules/admin/billing-types/add-edit/billing-type-add-edit.html',
                        controller: 'BillingTypesAddEditController',
                        controllerAs: 'vm'
                    }
                },
                resolve: {
                    billingTypeResponse: function() {
                        return {};
                    }
                },
                ncyBreadcrumb: {
                    label: '{{ ::"ADD" | translate }}'
                }
            });
    }

    function registerRoutesDetails($stateProvider) {
        $stateProvider
            .state('dashboard.admin.billing-types.list.details', {
                url: '/details/{resource}',
                abstract: true,
                parent: 'dashboard.admin.billing-types.list',
                data: {
                    permissions: ['user_permission_manage_billing_types']
                },
                views: {
                    '@dashboard.admin.billing-types': {
                        templateUrl: '/modules/admin/billing-types/details/billing-type-details.html',
                        controller: 'BillingTypesDetailsController',
                        controllerAs: 'vm'
                    },
                    'feed@dashboard.admin.billing-types.list.details': {
                        templateUrl: '/modules/admin/billing-types/details/events/event-feed.html',
                        controller: 'FeedController',
                        controllerAs: 'vm'
                    }
                },
                resolve: {
                    billingTypeResponse: function(apiClient, $stateParams) {
                        return apiClient.get($stateParams.resource.decode()).then( function(response) {
                            return response || apiClient.noResourceFound();
                        });
                    },
                    eventsCollection: function (apiClient, billingTypeResponse) {
                        return apiClient.get(billingTypeResponse.getLink('events')).then(function(response) {
                            return response || apiClient.noResourceFound();
                        });
                    },
                    entityResponse: function (billingTypeResponse) {
                        return billingTypeResponse;
                    }
                },
                ncyBreadcrumb: {
                    label: '{{ ::$resolves().billingTypeResponse.name }}'
                }
            })
            .state('dashboard.admin.billing-types.list.details.info', {
                url: '/info',
                parent: 'dashboard.admin.billing-types.list.details',
                data: {
                    permissions: ['user_permission_manage_billing_types']
                },
                views: {
                    '@dashboard.admin.billing-types.list.details': {
                        templateUrl: '/modules/admin/billing-types/details/info/billing-type-info.html',
                        controller: 'BillingTypesInfoController',
                        controllerAs: 'vm'
                    }
                },
                ncyBreadcrumb: {
                    label: '{{ ::"INFO" | translate }}'
                }
            });
    }

    function registerRoutesEdit($stateProvider) {
        $stateProvider
            .state('dashboard.admin.billing-types.list.details.info.edit', {
                url: '/edit',
                parent: 'dashboard.admin.billing-types.list.details.info',
                data: {
                    permissions: ['user_permission_manage_billing_types']
                },
                views: {
                    '@dashboard.admin.billing-types.list.details': {
                        templateUrl: '/modules/admin/billing-types/add-edit/billing-type-add-edit.html',
                        controller: 'BillingTypesAddEditController',
                        controllerAs: 'vm'
                    }
                },
                ncyBreadcrumb: {
                    label: '{{ ::"EDIT" | translate }}'
                },
                params: {
                    ignoreEventPreventDefault: true
                },
            });
    }

})();
