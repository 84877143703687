(function() {
    'use strict';

    angular
        .module('elogbooks')
        .config(Translations);

    function Translations($translateProvider) {
        $translateProvider.translations('nl', {

            ESCALATE_OPERATION_SUCCESS: 'Geescaleerd',
            ESCALATION_PROGRESS_SUCCESS: 'Escalatie vooruitgang',
            ESCALATION_POSTPONE_SUCCESS: 'Escalatie uitgesteld',
            ESCALATION_CANCEL_SUCCESS: 'Escalatie geannuleerd',

            ESCALATION_WORKFLOW_PROGRESS: 'Vooruitgang',
            ESCALATION_WORKFLOW_PROGRESS_SUBMIT: 'Vooruitgang',
            ESCALATION_WORKFLOW_CANCEL: 'Annuleer',
            ESCALATION_WORKFLOW_CANCEL_SUBMIT: 'Annuleer escalatie',
            ESCALATION_WORKFLOW_POSTPONE: 'Uitstellen',
            ESCALATION_WORKFLOW_POSTPONE_SUBMIT: 'Uitstellen',
            NO_ESCALATION_OPERATIVE: 'Geen leverancier toegewezen voor escalatie'
        });
    }
})();
