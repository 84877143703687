(function () {
    'use strict';

    angular
        .module('elogbooks.user.reports')
        .controller('UserReportsSpSummaryDetailsController', UserReportsSpSummaryDetailsController);

    UserReportsSpSummaryDetailsController.$inject = ['$state', '$stateParams', 'serviceProvidersCollection', 'siteGroupsCollection', 'serviceProviderGroupsCollection', 'moment', 'reportTotalsService',
        '$q', 'showPercentagesService', 'locationCollectionResponse', 'siteCollectionResponse', 'coreClientsCollectionResponse', 'paramConverterService'];

    function UserReportsSpSummaryDetailsController($state, $stateParams, serviceProvidersCollection, siteGroupsCollection, serviceProviderGroupsCollection, moment, reportTotalsService,
            $q, showPercentagesService, locationCollectionResponse, siteCollectionResponse, coreClientsCollectionResponse, paramConverterService) {

        var vm = this;
        vm.serviceProvidersResponse = serviceProvidersCollection;
        vm.serviceProviders = serviceProvidersCollection.serviceProviders;
        vm.siteGroupsCollection = siteGroupsCollection;
        vm.serviceProviderGroupsCollection = serviceProviderGroupsCollection;
        vm.siteGroups = siteGroupsCollection.sitegroups;
        vm.stateParams = $stateParams;

        vm.showPercentages = showPercentagesService.getShowPercentageState();
        vm.exportParams = {showPercentages: vm.showPercentages};

        vm.search = search;
        vm.saveParams = saveParams;
        vm.order = orderAction;
        vm.updateShowPercentages = updateShowPercentages;

        if (vm.serviceProvidersResponse.count > 0) {
            var params = angular.copy($stateParams);

            if ($stateParams.site){
                params.site = JSON.parse($stateParams.site).v;
            }

            if ($stateParams.location){
                params.location = JSON.parse($stateParams.location).v;
            }

            if ($stateParams.coreClient){
                params.coreClient = JSON.parse($stateParams.coreClient).v;
            }

            if (reportTotalsService.isCachedDataValid(params)) {
                vm.totals = reportTotalsService.getCachedTotals();
            } else {
                $q.all([reportTotalsService.getTotalsData(params, '/reports/sp-summary-totals').then(function(response) {
                    vm.totals = response;
                })]);
            }
        }

        vm.siteSelect = {
            response : siteCollectionResponse,
            link : siteCollectionResponse && siteCollectionResponse.count > 0 ? siteCollectionResponse.getLink('self') : null,
            responseKeyPath: 'sites',
            itemValuePath: 'id',
            itemTitlePath: 'name',
        };

        vm.siteGroupSelect = {
            response: siteGroupsCollection,
            responseKeyPath: 'sitegroups'
        };

        vm.serviceProviderGroupSelect = {
            response: serviceProviderGroupsCollection,
            responseKeyPath: 'groups'
        };

        vm.locationSelect = {
            response : locationCollectionResponse,
            link : locationCollectionResponse && locationCollectionResponse.count > 0 ? locationCollectionResponse.getLink('self') : null,
            responseKeyPath: 'locations',
            itemValuePath: 'id',
            itemTitlePath: 'name',
            disabled: true,
            selected: null,
        };

        vm.criteria = {
            name: { type: 'text', title: 'NAME', value: $stateParams.name},
            hours: { type: 'number', title: 'AWAITING_PAPERWORK_SINCE', value: parseInt($stateParams.hours,10)  , min: 1, max: 500, step: 1},
            siteRegionName: { type: 'text', title: 'REGION', value: $stateParams.siteRegionName },
            site: { type: 'jsonselectwidget', title: 'SITE', value: JSON.parse($stateParams.site), options: vm.siteSelect },
            location: { type: 'jsonselectwidget', title: 'LOCATION', value: JSON.parse($stateParams.location), options: vm.locationSelect},
            siteGroupName: { type: 'selectwidget', title: 'SITE_GROUP', value: $stateParams.siteGroupName, options: vm.siteGroupSelect },
            serviceProviderGroupName: { type: 'selectwidget', title: 'SERVICE_PROVIDER_GROUP', value: $stateParams.serviceProviderGroupName, options: vm.serviceProviderGroupSelect },
            dateRange: {
                type: 'date-range',
                title: 'BETWEEN_DATES',
                value: {
                    startDate: ($stateParams.dateRange !== null) ? moment(new Date($stateParams.dateRange.split(',')[0])) : null,
                    endDate: ($stateParams.dateRange !== null) ? moment(new Date($stateParams.dateRange.split(',')[1])) : null,
                    endMaxDate: moment().add(-1, 'days').format('MM-DD-YYYY, 23:59:59')
                },
                clearValue: {
                    startDate: null,
                    endDate: null
                }
            }
        };

        if (coreClientsCollectionResponse.coreclients) {
            vm.criteria.coreClient = {
                type: 'jsonselectwidget',
                title: 'CORE_CLIENT',
                value: JSON.parse($stateParams.coreClient),
                options: {
                    response: coreClientsCollectionResponse,
                    link : coreClientsCollectionResponse && coreClientsCollectionResponse.count > 0 ? coreClientsCollectionResponse.getLink('self') : null,
                    responseKeyPath: 'coreclients',
                    itemValuePath: 'id',
                    itemTitlePath: 'name'
                }
            };
        }

        if (vm.criteria.site.value !== null) {
            vm.criteria.location.options.disabled = false;
        } else {
            vm.criteria.location.value = null;
        }

        function saveParams (selectModel) {
            if (selectModel.mapValue) {
                if (selectModel.selected === undefined) {
                    params[selectModel.mapValue] = null;
                } else {
                    $stateParams[selectModel.mapValue] = selectModel.selected.object.id
                }
            }

            vm.search();
        }

        function search(params) {
            var override = {
                page: 1
            };

            $state.go('.', angular.extend({}, params, override), { reload: $state.current });
        }

        function updateShowPercentages() {
            vm.exportParams = {showPercentages: !!vm.showPercentages};
            showPercentagesService.saveShowPercentageState(!!vm.showPercentages);
        }

        function orderAction (key) {
            $stateParams.order = key;
            $state.go('.', angular.extend({}, $stateParams), { reload: $state.current });
        }

    }
})();


