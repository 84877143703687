(function() {
    'use strict';

    angular.module('elogbooksDirectives').directive('elbSystemNotifications', ['systemNotifications', function (systemNotifications) {
        return {
            templateUrl: '/modules/common/system-notifications/system-notifications.html',
            controller: function($scope){
                $scope.notificationsModel = systemNotifications.getNotifications();

                $scope.openUrl = openUrl;
                $scope.validUrl = validUrl;
                $scope.close = close;

                function openUrl(notification) {
                    systemNotifications.openUrl(notification);
                }

                function validUrl(url) {
                    return url.indexOf('http://') == 0 || url.indexOf('https://') == 0;
                  }

                function close(notification) {
                    systemNotifications.clear(notification);
                }
            }
        }
    }]);
})();
