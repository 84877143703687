(function() {
    'use strict';

    angular
        .module('elogbooks.user.meter-suppliers')
        .controller('MeterSuppliersDetailsController', MeterSuppliersDetailsController);

    MeterSuppliersDetailsController.$inject = ['meterSupplierResponse'];
    function MeterSuppliersDetailsController(meterSupplierResponse) {
        var vm = this;
        vm.meterSupplier = meterSupplierResponse;
    }
})();
