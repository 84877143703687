(function() {
    'use strict';

    angular
        .module('elogbooks.user.meter-manufacturers')
        .controller('MeterManufacturersInfoController', MeterManufacturersInfoController);

    MeterManufacturersInfoController.$inject = ['$state', 'apiClient', 'confirmationModal', 'messenger', 'meterManufacturerResponse'];
    function MeterManufacturersInfoController($state, apiClient, confirmationModal, messenger, meterManufacturerResponse) {
        var vm = this;
        vm.meterManufacturer = meterManufacturerResponse;
    }
})();
