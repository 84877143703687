(function() {
    'use strict';

    angular
        .module('elogbooksServices')
        .service('calendarRuleService', CalendarRuleService);

    CalendarRuleService.$inject = ['moment'];

    function CalendarRuleService(moment)
    {
        var self = this;

        this.calculateExpiryDate = calculateExpiryDate;
        this.calculateTimeFromRules = calculateTimeFromRules;
        this.ruleTimeInMinutes = ruleTimeInMinutes;

        function calculateExpiryDate(ruleFrequency, ruleInterval, startDate) {
            if (ruleFrequency && ruleInterval && startDate) {
                var frequency = (ruleFrequency
                    .substring(0, ruleFrequency.lastIndexOf('LY')))
                    .toLowerCase() + 's';
                var expiresAt = moment(startDate.toISOString())
                    .add(ruleInterval, frequency).toString();

                return new Date(expiresAt);
            }

            return null;
        }

        function calculateTimeFromRules(rules) {
            angular.forEach(rules, function(rule) {
                if (rule.minutes) {
                    rule.hours = Math.floor(rule.minutes / 60);
                    rule.minutes = rule.minutes % 60;
                }
            });
        }

        function ruleTimeInMinutes(rules) {
            angular.forEach(rules, function(rule) {
                rule.minutes = (rule.hours * 60) + rule.minutes;
                delete rule.hours;
            });
        }

        return self;
    }
})();
