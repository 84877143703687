(function () {
    'use strict';

    angular
        .module('elogbooks.user.buddies')
        .controller('CommonBuddyDetailsController', [
            '$state',
            '$stateParams',
            'apiClient',
            'messenger',
            'confirmationModal',
            'buddyResponse',
            'siteCollectionResponse',
            'config',
            CommonBuddyDetailsController
        ]);

    function CommonBuddyDetailsController (
        $state,
        $stateParams,
        apiClient,
        messenger,
        confirmationModal,
        buddyResponse,
        siteCollectionResponse,
        config) {

        var vm = this;
        vm.buddy = buddyResponse;
        vm.sitesCollection = siteCollectionResponse;
        vm.pages = siteCollectionResponse.pages;
        vm.type = config.type;

        if (config.type === 'MY_BUDDY') {
            vm.filterType = 'BUDDY';
        } else {
            vm.filterType = 'ASSIGNED_BY';
        }

        vm.criteria = {
            name: { type: 'text', title: 'NAME', value: $stateParams.name },
            reference: { type: 'text', title: 'REFERENCE', value: $stateParams.reference }
        };

        if ($state.current.parent.indexOf('history') > 1) {
            vm.history = true;
        } else {
            vm.history = false;
        }

        vm.search = searchAction;
        vm.delete = deleteAction;
        vm.deleteSite = deleteSiteAction;
        vm.isBuddyActive = isBuddyActive;

        function searchAction(params) {
            var override = {
                page: 1,
                sitesPage: 1
            };

            $state.go('.', angular.extend({}, params, override), { reload: $state.current });
        }

        function deleteSiteAction(site) {
            if (vm.sitesCollection.sites.length === 1) {
                return messenger.error('SITES_EMPTY');
            }
            return confirmationModal.open().result.then(function () {
                apiClient.delete(vm.buddy.getLink('sites'), {'ids' : [site.id]}).then(function (response) {
                    if (response) {
                        $state.go('.', $stateParams, { reload: $state.$current.parent.parent.self }).then(function () {
                            messenger.success('BUDDY_SITE_RELATION_REMOVED');
                        });
                    } else {
                        messenger.error('REQUEST_ERROR');
                    }
                });
            });
        }

        function deleteAction() {
            return confirmationModal.open().result.then(function () {
                return apiClient.delete(vm.buddy.getLink('self')).then(function (response) {
                    if (response) {
                        $state.go('^', $stateParams, { reload: $state.get('^') }).then(function () {
                            if (isBuddyActive()) {
                                messenger.success('BUDDY_DEACTIVATED');
                            } else {
                                messenger.success('BUDDY_REMOVED');
                            }
                        });
                    } else {
                        messenger.error('REQUEST_ERROR');
                    }
                });
            });
        }

        function isBuddyActive() {
            return vm.buddy.status === 1;
        }
    }
})();
