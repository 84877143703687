(function () {
    'use strict';

    angular
        .module('elogbooks.user.patrols')
        .controller('PatrolSchedulesAddEditController', PatrolSchedulesAddEditController);

    PatrolSchedulesAddEditController.$inject = ['$state', 'apiClient', 'messenger', 'requestDataFactory', 'patrolScheduleResponse', 'patrolSchedulesCollection', 'patrolResponse', 'confirmationModal'];

    function PatrolSchedulesAddEditController ($state, apiClient, messenger, requestDataFactory, patrolScheduleResponse, patrolSchedulesCollection, patrolResponse, confirmationModal) {
        var vm = this;
        vm.schedule = patrolScheduleResponse;
        vm.data = requestDataFactory.createRequest(getDefaults());
        vm.create = createAction;
        vm.update = updateAction;
        vm.delete = deleteAction;
        function getDefaults() {
            return {
                _links: {
                    patrol: vm.schedule._links ? vm.schedule._links.patrol : patrolResponse._links.self,
                },
                id: vm.schedule.id || null,
                name: vm.schedule.name || null,
                description: vm.schedule.description || null,
                activeAt: (vm.schedule.activeAt ? new Date(vm.schedule.activeAt) : null),
                inactiveAt: (vm.schedule.inactiveAt ? new Date(vm.schedule.inactiveAt) : null)
            };
        }

        function createAction() {
            apiClient.create(patrolSchedulesCollection.getLink('create'), vm.data).then(function (response) {
                if (response) {
                    $state.go('dashboard.user.patrols.manage-patrols.details.schedules.list.details', { patrolScheduleResource: response.getLink('self').encode() }, { reload: true }).then(function () {
                        messenger.success('PATROL_SCHEDULE_CREATED');
                    });
                } else {
                    messenger.error('REQUEST_ERROR');
                }
            });
        }

        function updateAction() {
            apiClient.update(vm.schedule._links.self.href, vm.data).then(function (response) {
                if (response) {
                    $state.go('^', {}, { reload: true }).then(function () {
                        messenger.success('PATROL_SCHEDULE_UPDATED');
                    });
                } else {
                    messenger.error('REQUEST_ERROR');
                }
            });
        }

        function deleteAction() {
            return confirmationModal.open().result.then(
                function () {
                    return apiClient.delete(vm.schedule.getLink('delete')).then(function (response) {
                        if (response) {
                            $state.go('dashboard.user.patrols.manage-patrols.details.scheldules.list', {}, { reload: true }).then(function () {
                                messenger.success('PATROL_SCHEDULE_DELETED');
                            });
                        } else {
                            messenger.error('REQUEST_ERROR');
                        }
                    });
                },
                function () {
                    return;
                }
            );
        };
    }
})();
