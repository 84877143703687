app.config(function($translateProvider) {
    $translateProvider.translations('en-gb', {
        CHARGE_TYPE: 'Charge Type',
        CHARGE_TYPES: 'Charge Types',
        CHARGE_TYPE_INFORMATION: 'Charge Type Information',

        ADD_NEW_CHARGE_TYPE: 'Add a new Charge Type',
        EDIT_CHARGE_TYPE: 'Edit Charge Type',

        CHARGE_TYPE_LIST: 'Charge Types',
        CHARGE_TYPE_ADD: 'Add a new Charge Type',
        CHARGE_TYPE_EDIT: 'Edit Charge Type',

        CHARGE_TYPE_CREATED: 'Charge Type created successfully',
        CHARGE_TYPE_UPDATED: 'Charge Type updated successfully',
        CHARGE_TYPE_DELETED: 'Charge Type deleted successfully',

        CHARGE_TYPE_REQUIRED: 'Charge Type is required'
    });
});