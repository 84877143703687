app.config(function ($translateProvider) {
    $translateProvider.translations('nl', {
        HELPDESK: 'Servicedesk Savills',
        HELPDESK_DASHBOARD: 'Savills Servicedesk Dashboard',

        QUOTES_LIST: 'Offertes',
        ALARM_AT: 'Alarm op',
        ALARM_LOCK: 'Alarm Slot',
        ALARMED: 'Gealarmeerd',

        HD_NO_RESULTS: 'Geen resultaat',
        HD_QUOTES: 'Offertes',
        HD_STATUTORY: 'Statutair',
        HD_REACTIVE: 'Correctief onderhoud',
        HD_PLANNED: 'Preventief onderhoud',
        HD_ESCALATIONS: 'Escalaties',
        HD_SATISFACTION_SURVEYS: 'Klanttevredenheidsonderzoek',
        HD_AWAITING_PAPERWORK: 'Afwachting documenten',

        HD_TOOLTIP_ALARMS: 'Alarm',
        HD_TOOLTIP_AWAITING_APPROVAL: 'In afwachting van goedkeuring',
        HD_TOOLTIP_OVERDUE: 'Verlopen',
        HD_TOOLTIP_ESCALATIONS: 'Escalaties',
        HD_TOOLTIP_CHASE: 'Nabellen',
        HD_TOOLTIP_REMINDER: 'Herinnering',

        HD_TOOLTIP_REACTIVE_CHASE: 'Opvolgen',
        HD_TOOLTIP_REACTIVE_ACKNOWLEDGE: 'Nog Bevestigen',
        HD_TOOLTIP_REACTIVE_APPROVAL: 'Goedkeuring nodig',
        HD_TOOLTIP_REACTIVE_EXTENSION_APPROVAL: 'Goedkeuring uitstel',
        HD_TOOLTIP_REACTIVE_EXTENSION_REQUEST: 'Verlengingsverzoek',
        HD_TOOLTIP_JOB_REACTIVE_EXTENSION_REQUEST: 'Uitstel opdrachten',
        HD_TOOLTIP_QUOTE_REACTIVE_EXTENSION_REQUEST: 'Uitstel offertes',
        HD_TOOLTIP_REACTIVE_PRIORITY_OVERDUE: 'Escalatie',
        HD_TOOLTIP_REACTIVE_ALARMS: 'Herinnering',

        HD_TOOLTIP_QUOTES_CHASE: 'Opvolgen',
        HD_TOOLTIP_QUOTES_REMINDER: 'Herinnering',
        HD_TOOLTIP_QUOTES_EXTENSION_APPROVAL: 'Goedkeuring uitstel',

        HD_TOOLTIP_AWAITING_PAPERWORK: 'Afwachting documenten',

        HD_DAYS_SHORT: '{{days}}d',
        HD_HOURS_SHORT: '{{hours}}u',
        HD_MINUTES_SHORT: '{{minutes}}m',
        HD_SECONDS_SHORT: '{{seconds}}s'
    });
});
