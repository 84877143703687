(function(){
    'use strict';

    angular
        .module('elogbooks.admin.settings')
        .controller('AdminPublicHolidaysController', AdminPublicHolidaysController);

    function AdminPublicHolidaysController($state, $stateParams, apiClient, messenger, confirmationModal, publicHolidaysCollectionResponse) {
        var vm = this;

        vm.publicHolidaysCollectionResponse = publicHolidaysCollectionResponse;
        vm.publicHolidays = publicHolidaysCollectionResponse.publicHolidays;
        vm.order = order;
        vm.search = search;

        vm.criteria = {
            id: { type: 'number', title: 'ID', value: $stateParams.id ? parseInt($stateParams.id, 10) : null, min: 1 },
            name: { type: 'text', title: 'NAME', value: $stateParams.name },
            date: { type: 'date', title: 'DATE', value: $stateParams.date }
        };

        function order (key) {
            $stateParams.order = key;

            $state.go('.', angular.extend({}, $stateParams), { reload: true });
        }

        function search (params) {
            var override = {
                page: 1
            };

            $state.go('.', angular.extend({}, params, override), { reload: true });
        }
    }
})();
