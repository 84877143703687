(function(){
    'use strict';

    angular
        .module('elogbooks.user.sites')
        .controller('UserSiteListController', UserSiteListController);

    UserSiteListController.$inject = ['$stateParams', '$state', 'siteCollectionResponse', 'siteGroupCollectionResponse',
        'regionCollectionResponse', 'associateTypeCollectionResponse', 'siteAssociateCollectionResponse', '$filter',
        'rootResourceResponse', 'user', 'apiClient', 'paramConverterService', 'analyticsService', '$scope'];

    function UserSiteListController($stateParams, $state, siteCollectionResponse, siteGroupCollectionResponse,
             regionCollectionResponse, associateTypeCollectionResponse, siteAssociateCollectionResponse, $filter,
             rootResourceResponse, user, apiClient, paramConverterService, analyticsService, $scope) {

        var vm = this;
        vm.search = search;
        vm.collection = siteCollectionResponse;
        vm.sites = siteCollectionResponse.sites;
        vm.pages = siteCollectionResponse.pages;

        vm.loadedCollections = [siteGroupCollectionResponse, regionCollectionResponse, siteAssociateCollectionResponse, associateTypeCollectionResponse];

        var deregisterWatch = $scope.$watch('vm.sites', function(value) {
                analyticsService.pageLoaded();
                deregisterWatch();
        });

        vm.stateParams = $stateParams;
        vm.resources = {'rootResource': rootResourceResponse,
            'stateParams': $stateParams,
            'user': user};

        function getAssociateTypes() {
            return paramConverterService.formatResponse(associateTypeCollectionResponse.siteAssociateTypes);
        }

        function getRegions() {
            return paramConverterService.formatResponse(regionCollectionResponse.regions);
        }

        function getAssociates() {
            return paramConverterService.formatResponse(siteAssociateCollectionResponse.users);
        }

        vm.criteria = {
            siteName: { type: 'text', title: 'NAME', value: $stateParams.siteName },
            reference: { type: 'text', title: 'REFERENCE', value: $stateParams.reference },
            address: { type: 'text', title: 'ADDRESS', value: $stateParams.address }
        };

        if (siteGroupCollectionResponse) {
            vm.siteGroupSelect = {
                response : siteGroupCollectionResponse,
                link : siteGroupCollectionResponse ? siteGroupCollectionResponse.getLink('self') : null,
                itemValuePath: 'id',
                itemTitlePath: 'name',
                responseKeyPath: 'sitegroups'
            };
            vm.criteria.siteGroup = { type: 'jsonselectwidget', title: 'SITE_GROUPS', value: JSON.parse($stateParams.siteGroup), options: vm.siteGroupSelect };
        }

        if (regionCollectionResponse) {
            vm.criteria.siteRegions = { type: 'options', title: 'REGION', multiple: true, value: paramConverterService.checkStateParams($stateParams.siteRegions), options: getRegions()};
        }

        if (associateTypeCollectionResponse) {
            vm.criteria.associateType = { type: 'options', title: 'SITE_ASSOCIATE_TYPES', value: paramConverterService.checkNullAndConvert($stateParams.associateType), options: getAssociateTypes()};
        }

        if (siteAssociateCollectionResponse) {
            vm.criteria.associate  = { type: 'options', title: 'SITE_ASSOCIATES', value: paramConverterService.checkNullAndConvert($stateParams.associate), options: getAssociates()};
        }

        function search(params) {
            var override = {
                page:       1
            };

            $state.go('.', angular.extend({}, params, override), { reload: $state.current });
        }

    }
})();
