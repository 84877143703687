(function () {
    'use strict';

    angular
        .module('elogbooks.user.tenants')
        .controller('UserEmailFilesController', UserEmailFilesController);

    UserEmailFilesController.$inject = ['$state', 'config', '$stateParams', 'filesCollectionResponse', 'elbDownloadService'];

    function UserEmailFilesController ($state, config, $stateParams, filesCollectionResponse, elbDownloadService) {
        var vm = this;
        vm.files = filesCollectionResponse.files;
        vm.fileCollectionResponse = filesCollectionResponse;
        vm.config = config.config;
        vm.stateParams = $stateParams;
        vm.download = elbDownloadService.download;

        vm.search = search;

        vm.criteria = {
            efTitle: {type: 'text', title: 'TITLE', value: $stateParams.efTitle}
        };

        function search(params) {
            var override = {
                efPage: 1
            };

            $state.go('.', angular.extend({}, params, override), {reload: $state.current});
        }
    }
})();
