;(function() {
    'use strict';

    angular.module('elogbooksServices').service('jobService', [
        '$stateParams', 'userManager', 'apiClient', 'paramsService', 'jobParamsService',
        function($stateParams, userManager, apiClient, paramsService, jobParamsService) {

        this.get = function (url, selectedSiteResponse, cache) {
            return apiClient.get(url, this.buildParams(selectedSiteResponse), cache);
        };

        this.setLocation = function(location){
            this.data._links.location = {href: location };
        };

        this.setAction = function(action){
            this.data._links.action = {href: action };
        };

        this.reset = function() {
            this.data = {
                _links : {}
            };
        };

        this.buildParams = function (selectedSiteResponse) {
            var params = jobParamsService.getJobParams($stateParams, userManager.getUser(), selectedSiteResponse);

            if (paramsService.serviceProvider) {
                params.serviceProvider = JSON.parse(paramsService.serviceProvider).v;
            }

            if (paramsService.requisite) {
                params.requisite = JSON.parse(paramsService.requisite).v;
            }

            // Update the stateParams accordingly as done in job params
            if (paramsService.jobApprover) {
                if (paramsService.jobApprover[0] === 'true') {
                    paramsService.jobApprover = [userManager.getUser().id];
                }
            }
            if (selectedSiteResponse) {
                paramsService.sites = [];
            }

            if (paramsService.asset) {
                params.asset = JSON.parse(paramsService.asset).id;
            }

            if (paramsService.subAsset) {
                params.subAsset = JSON.parse(paramsService.subAsset).id;
            }

            if (paramsService.jobsDueBy) {
                var dueByType = JSON.parse(paramsService.jobsDueBy).t;
                var dueByFrequency = JSON.parse(paramsService.jobsDueBy).f;
                var dueByInterval = JSON.parse(paramsService.jobsDueBy).i;
                var startDate = moment().startOf('day');
                var endDate = moment().endOf('day').add(dueByInterval, dueByFrequency+'s');

                if (paramsService.jobsAttendanceDueStart === null &&
                    paramsService.jobsAttendanceDueEnd === null &&
                    paramsService.completionDueStart === null &&
                    paramsService.completionDueEnd === null
                ) {
                    if (dueByType === 'either') {
                        params.dueByEither = true;
                    }
                } else {
                    dueByType = null;
                }

                if ((dueByType === 'attendance' || params.dueByEither)
                    && (paramsService.jobsAttendanceDueStart === null && paramsService.jobsAttendanceDueEnd === null)
                ) {
                    params.jobsAttendanceDueStart = startDate.toString();
                    params.jobsAttendanceDueEnd = endDate.toString();
                }

                if ((dueByType === 'completion' || params.dueByEither)
                    && (paramsService.completionDueStart === null && paramsService.completionDueEnd === null)
                ) {
                    params.completionDueStart = startDate.toString();
                    params.completionDueEnd = endDate.toString();
                }
            }

            if ($stateParams.location) {
                params.location = $stateParams.location;
            }
            return params;
        }
        this.reset();

        return this;
    }]);

})();