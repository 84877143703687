(function () {
    'use strict';

    angular
        .module('elogbooks.user.patrols')
        .controller('PatrolPointsController', PatrolPointsController);

    PatrolPointsController.$inject = ['patrolResponse', 'patrolPointsCollectionResponse', 'elbDownloadService', '$translate'];

    function PatrolPointsController (patrolResponse, patrolPointsCollectionResponse, elbDownloadService, $translate) {
        var vm = this;
        vm.patrol = patrolResponse;
        vm.patrolPoints = patrolPointsCollectionResponse.patrolPointInstances;
        vm.patrolPointsCollectionResponse = patrolPointsCollectionResponse;
        vm.download = download;

        angular.forEach(vm.patrolPoints, function(point) {
            point.reason = '';

            if (point.getLink('reason')) {
                point.reason = point.getLinkAttribute('reason', 'title');

                if (point.otherReason) {
                    point.reason += ' - ' + point.otherReason;
                }
            } else if (point.otherReason) {
                point.reason = $translate.instant('OTHER') + ' - ' + point.otherReason;
            }
        });

        function download(file) {
            elbDownloadService.download(file);
        }
    }
})();
