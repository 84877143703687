(function () {
    'use strict';

    angular
        .module('elogbooksServices')
        .service('elbDownloadService', ElbDownloadService);

    ElbDownloadService.$inject = ['$http', 'API_URL'];

    function ElbDownloadService($http, API_URL) {

        function isValidFile (file) {
            return file._links && file._links.self;
        }


        return {
            getLink: function (file) {
                if (! isValidFile(file)) {
                    return;
                }

                return file._links.self.href;
            },
            download: function (file) {
                if (! isValidFile(file)) {
                    return;
                }

                $http.get(file._links.resource.href, { responseType: "arraybuffer" }).then(function (response) {
                    var arrayBufferView = new Uint8Array(response.data);
                    var blob = new Blob([arrayBufferView], { type: file._links.resource.type });

                    if (navigator.msSaveBlob) {
                        // Internet Explorer
                        navigator.msSaveBlob(blob, file.filename);
                    } else {
                        // Proper browsers
                        var anchor = angular.element('<a />');
                            anchor.css({display: 'none'});

                        angular.element(document.body).append(anchor);

                        var urlCreator = window.URL || window.webkitURL;
                        var url = urlCreator.createObjectURL(blob);

                        anchor.attr({
                            href: url,
                            download: file.filename
                        })[0].click();

                        anchor.remove();
                    }
                });
            },
            downloadUrl: function (url, fileName, fileType, fullPath) {
                if (!fullPath) {
                    url = API_URL + url;
                }

                $http.get(url, { responseType: "arraybuffer" }).then(function (response) {
                    var arrayBufferView = new Uint8Array(response.data);
                    var blob = new Blob([arrayBufferView], { type: fileType });

                    if (navigator.msSaveBlob) {
                        // Internet Explorer
                        navigator.msSaveBlob(blob, fileName);
                    } else {
                        var anchor = angular.element('<a />');
                            anchor.css({display: 'none'});

                        angular.element(document.body).append(anchor);

                        var urlCreator = window.URL || window.webkitURL;
                        var url = urlCreator.createObjectURL(blob);

                        anchor.attr({
                            href: url,
                            download: fileName
                        })[0].click();

                        anchor.remove();
                    }
                });
            }
        }
    }
})();
