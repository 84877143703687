(function () {
    'use strict';

    angular
        .module('elogbooks.admin.sectors')
        .controller('SectorsController', SectorsController);

    SectorsController.$inject = ['$state', '$stateParams', '$filter', 'sectorCollectionResponse'];

    function SectorsController ($state, $stateParams, $filter, sectorCollectionResponse) {
        var vm = this;
            vm.sectors = sectorCollectionResponse.sectors;
            vm.sectorCollectionResponse = sectorCollectionResponse;

        vm.order = order;
        vm.search = search;

        var activeOptions = [
            {
                title: $filter('translate')('NONE_SELECTED'),
                value: null
            },
            {
                title: $filter('translate')('STATUS_ACTIVE'),
                value: 'true'
            },
            {
                title: $filter('translate')('STATUS_INACTIVE'),
                value: 'false'
            }
        ];

        vm.criteria = {
            id: { type: 'number', value: $stateParams.id ? parseInt($stateParams.id, 10) : null, title: 'ID', min: 1 },
            name: { type: 'text', value: $stateParams.name, title: 'NAME' },
            active: { type: 'options', title: 'IS_ACTIVE', value: $stateParams.active, options: activeOptions },
        };

        function search (params) {
            var override = {
                page: 1
            };

            $state.go('.', angular.extend({}, params, override), { reload: true });
        };

        function order (key) {
            $stateParams.order = key;

            $state.go('.', angular.extend({}, $stateParams), { reload: true });
        };
    }
})();
