(function () {
    'use strict';

    angular
        .module('elogbooks.user.finance')
        .config(registerPurchaseOrdersRoutes)
        .config(registerPurchaseOrderRoutesList)
        .config(registerPurchaseOrderRoutesDetails)
        .config(registerPurchaseOrderRoutesAdd)
        .config(registerPurchaseOrderRoutesEdit)
        .config(registerPurchaseOrderRouteItemsTab)
        .config(registerPurchaseOrderRoutesDetailsTabsPurchaseInvoices)
        .config(registerPurchaseOrderRoutesDetailsTabsPurchaseInvoicesAdd)
        .config(registerPurchaseOrderRouteFilesTab)
        .config(registerWorkflow);

    function registerPurchaseOrdersRoutes($stateProvider) {
        $stateProvider
            .state('dashboard.user.finance.purchase-orders', {
                abstract: true,
                templateUrl: 'shared-templates/view.html',
                ncyBreadcrumb: {
                    skip: true
                }
            });
    }

    function registerPurchaseOrderRoutesList($stateProvider) {
        var purchaseOrdersParams = {
            statuses: {array: true},
            purchaseOrdersPage: '1',
            purchaseOrdersLimit: '30',
            purchaseOrdersOrder: 'id',
            supplier: null,
            dateRange: null,
        }

        $stateProvider
            .state('dashboard.user.finance.purchase-orders.list', {
                url: '/purchase-orders?' + Object.keys(purchaseOrdersParams).join('&'),
                parent: 'dashboard.user.finance.purchase-orders',
                resolve: {
                    purchaseOrdersCollectionResponse: function (apiClient, rootResourceResponse, $stateParams) {
                        var params = angular.copy($stateParams);
                        params = angular.extend({}, params, {
                            page: $stateParams.purchaseOrdersPage,
                            order: $stateParams.purchaseOrdersOrder,
                            limit: $stateParams.purchaseOrdersLimit,
                            'statuses[]': $stateParams.statuses,
                        });

                        if (params.supplier) {
                            params.supplier = JSON.parse(params.supplier).v;
                        }

                        return apiClient.get(rootResourceResponse.getLink('purchaseorders'), params).then(function(response) {
                            return response || apiClient.noResourceFound();
                        });
                    },
                    suppliersCollectionResponse: function (apiClient, rootResourceResponse) {
                        return apiClient.get(rootResourceResponse.getLink('suppliers'), {
                            active: true,
                            order: 'name'
                        }).then(function(response) {
                            return response || apiClient.noResourceFound();

                        });
                    }
                },
                params: purchaseOrdersParams,
                views: parseBook({
                    'elogbooks-left@dashboard.user.finance': {
                        templateUrl: '/modules/user/finance/purchase-orders/purchase-orders-list.html',
                        controller: 'FinancePurchaseOrdersController',
                        controllerAs: 'vm'
                    }
                }),
                ncyBreadcrumb: {
                    label: '{{ ::"PURCHASE_ORDERS" | translate }}'
                }
            });
    }

    function registerPurchaseOrderRoutesDetails($stateProvider) {
        $stateProvider
            .state('dashboard.user.finance.purchase-orders.list.details', {
                url: '/details/{resource}',
                abstract: true,
                parent: 'dashboard.user.finance.purchase-orders.list',
                resolve: {
                    purchaseOrderResponse: function (apiClient, base64, $stateParams) {
                        return apiClient.get(base64.decode($stateParams.resource));
                    },
                    eventCollectionResponse: function(apiClient, purchaseOrderResponse) {
                        return apiClient.get(purchaseOrderResponse.getLink('events'), { datagroup: 'details' }).then(function(response) {
                            return response || apiClient.noResourceFound();
                        })
                    },
                    messageCollectionResponse: function(apiClient, purchaseOrderResponse) {
                        return apiClient.get(purchaseOrderResponse.getLink('messages'), { datagroup: 'details' }).then(function(response) {
                            return response || apiClient.noResourceFound();
                        });
                    },
                },
                views: {
                    '@dashboard.user.finance': {
                        templateUrl: '/modules/user/finance/purchase-orders/details/purchase-orders-details.html',
                        controller: 'FinancePurchaseOrdersDetailsController',
                        controllerAs: 'vm',
                    },
                    'details.info@dashboard.user.finance.purchase-orders.list.details': {
                        templateUrl: '/modules/user/finance/purchase-orders/details/info/purchase-orders-details-info.html',
                        controller: 'FinancePurchaseOrdersDetailsInfoController',
                        controllerAs: 'vm'
                    },
                    'status@dashboard.user.finance.purchase-orders.list.details': {
                        templateUrl: '/modules/user/finance/purchase-orders/details/status/purchase-orders-status.html',
                        controller: 'FinancePurchaseOrdersStatusController',
                        controllerAs: 'vm'
                    },
                    'feed@dashboard.user.finance.purchase-orders.list.details': {
                        templateUrl: '/modules/user/finance/purchase-orders/details/event/feed.html',
                        controller: 'PurchaseOrderDetailsEventFeedController',
                        controllerAs: 'vm'
                    },
                    'workflow@dashboard.user.finance.purchase-orders.list.details': {
                        templateUrl: '/modules/user/finance/purchase-orders/details/workflow/workflow.html',
                        controller: 'FinancePurchaseOrdersWorkflowController',
                        controllerAs: 'vm'
                    }
                },
                ncyBreadcrumb: {
                    label: '{{ ::$resolves().purchaseOrderResponse.id }}'
                }
            });
    }

    function registerPurchaseOrderRoutesAdd($stateProvider) {
        $stateProvider
            .state('dashboard.user.finance.purchase-orders.list.add', {
                url: '/add',
                parent: 'dashboard.user.finance.purchase-orders.list',
                views: parseBook({
                    'elogbooks-left@dashboard.user.finance': {
                        templateUrl: '/modules/user/finance/purchase-orders/add-edit/add-edit.form.html',
                        controller: 'PurchaseOrderAddEditController',
                        controllerAs: 'vm'
                    }
                }),
                resolve: {
                    purchaseOrderResponse: function() {
                        return {};
                    },
                    config: function() {
                        return {};
                    }
                },
                ncyBreadcrumb: {
                    label: '{{ ::"ADD" | translate }}'
                }
            });
    }

    function registerPurchaseOrderRoutesEdit($stateProvider) {
        $stateProvider
            .state('dashboard.user.finance.purchase-orders.list.details.edit', {
                url: '/edit/{resource}',
                parent: 'dashboard.user.finance.purchase-orders.list.details',
                views: parseBook({
                    'elogbooks-left@dashboard.user.finance': {
                        templateUrl: '/modules/user/finance/purchase-orders/add-edit/add-edit.form.html',
                        controller: 'PurchaseOrderAddEditController',
                        controllerAs: 'vm'
                    }
                }),
                resolve: {
                    config: function() {
                        return {};
                    }
                },
                ncyBreadcrumb: {
                    label: '{{ ::"EDIT" | translate }}'
                }
            });
    }

    function registerPurchaseOrderRouteItemsTab($stateProvider) {
        $stateProvider
            .state('dashboard.user.finance.purchase-orders.list.details.items', {
                url: '/items',
                parent: 'dashboard.user.finance.purchase-orders.list.details',
                views: parseBook({
                    'items@dashboard.user.finance.purchase-orders.list.details': {
                        templateUrl: '/modules/common/finance/item/items-list.html',
                        controller: 'CommonFinanceItemListController',
                        controllerAs: 'vm'
                    }
                }),
                resolve: {
                    itemLinesCollectionResponse: function(apiClient, purchaseOrderResponse) {
                        return apiClient.get(purchaseOrderResponse.getLink('purchaseorder-lines'), {}).then(function(response) {
                            return response || apiClient.noResourceFound();
                        })
                    },
                    itemResponse: function (apiClient, purchaseOrderResponse) {
                        return purchaseOrderResponse || apiClient.noResourceFound();
                    },
                    vatRateCollectionResponse: function (apiClient, rootResourceResponse) {
                        if (rootResourceResponse.getLink('vat-rates')) {
                            return apiClient.get(rootResourceResponse.getLink('vat-rates'), {
                                active: true,
                                limit: 999999
                            });
                        }
                    },
                    config: function() {
                        return {
                            type: 'PURCHASE_ORDER'
                        };
                    }
                },
                ncyBreadcrumb: {
                    label: '{{ ::"ITEMS" | translate }}'
                }
            })
            .state('dashboard.user.finance.purchase-orders.list.details.items.add', {
                url: '/add',
                parent: 'dashboard.user.finance.purchase-orders.list.details.items',
                views: parseBook({
                    'items@dashboard.user.finance.purchase-orders.list.details': {
                        templateUrl: '/modules/common/finance/item/add-edit/items-add-edit.html',
                        controller: 'CommonFinanceItemListAddEditController',
                        controllerAs: 'vm'
                    }
                }),
                ncyBreadcrumb: {
                    label: '{{ ::"EDIT" | translate }}'
                }
            });
    }

    function registerPurchaseOrderRoutesDetailsTabsPurchaseInvoices($stateProvider) {
        $stateProvider
            .state('dashboard.user.finance.purchase-orders.list.details.invoices', {
                url: '/purchase-invoices&invoicesPage&invoicesLimit',
                parent: 'dashboard.user.finance.purchase-orders.list.details',
                params: {
                    invoicesPage: '1',
                    invoicesLimit: '30',
                },
                views: parseBook({
                    'items@dashboard.user.finance.purchase-orders.list.details': {
                        templateUrl: '/modules/user/finance/purchase-orders/details/tabs/invoices/purchase-orders-invoices.html',
                        controller: 'FinancePurchaseOrdersDetailsPurchaseInvoicesController',
                        controllerAs: 'vm'
                    }
                }),
                resolve: {
                    purchaseInvoicesCollectionResponse: function(apiClient, $stateParams, purchaseOrderResponse) {
                        return apiClient.getPage(purchaseOrderResponse.getLink('purchase-invoices'), angular.extend({}, $stateParams, {
                            page: $stateParams.invoicesPage,
                            limit: $stateParams.invoicesLimit
                        }));
                    }
                },
                ncyBreadcrumb: {
                    label: '{{ ::"INVOICES" | translate }}'
                }
            });
    }
    function registerPurchaseOrderRoutesDetailsTabsPurchaseInvoicesAdd($stateProvider) {
        $stateProvider
            .state('dashboard.user.finance.purchase-orders.list.details.invoices.add', {
                url: '/purchase-invoices/add&invoicesPage&invoicesLimit',
                parent: 'dashboard.user.finance.purchase-orders.list.details.invoices',
                views: parseBook({
                    'items@dashboard.user.finance.purchase-orders.list.details': {
                        templateUrl: '/modules/common/finance/purchase-invoices/add-edit/add-edit.form.html',
                        controller: 'PurchaseInvoicesAddEditController',
                        controllerAs: 'vm'
                    }
                }),
                resolve: {
                    purchaseInvoiceResponse: function() {
                        return {};
                    },
                    purchaseInvoicesCollectionResponse: function(purchaseInvoicesCollectionResponse) {
                        return purchaseInvoicesCollectionResponse;
                    }
                },
                ncyBreadcrumb: {
                    label: '{{ ::"ADD" | translate }}'
                }
            });
    }

    function registerWorkflow($stateProvider)
    {
        var workflowRute = 'dashboard.user.finance.purchase-orders.list.details.workflow';

        $stateProvider
            .state(workflowRute, {
                url: '/workflow',
                abstract: true,
                parent: 'dashboard.user.finance.purchase-orders.list.details',
                views: {
                    '@dashboard.user.finance': {
                        templateUrl: '/modules/user/finance/purchase-orders/details/workflow/workflow-add-note/workflow-add-note.html',
                        controller: 'FinancePurchaseOrdersWorkflowAddNoteController',
                        controllerAs: 'vm',
                    },
                },
                ncyBreadcrumb: {
                    label: '{{ ::"WORKFLOW" | translate }}'
                }
            });

        // Register workflows routing actions
        ['order', 'cancel', 'part-received', 'complete', 'reopen'].map(function(type) {
            $stateProvider
                .state(workflowRute + '.' + type, {
                    url: '/' + type,
                    abstract: false,
                    parent: workflowRute,
                    ncyBreadcrumb: {
                        label: '{{ ::"' + type.replaceAll('-', '_') + '" | uppercase | translate }}'
                    }
                });
        });
    }

    function registerPurchaseOrderRouteFilesTab($stateProvider) {
        $stateProvider
        .state('dashboard.user.finance.purchase-orders.list.details.files', {
            url: '/files',
                parent: 'dashboard.user.finance.purchase-orders.list.details',
                params: {
                    documentType: null,
                    fileOrder: '-id'
                },
                views: {
                    'items@dashboard.user.finance.purchase-orders.list.details': {
                        templateUrl: '/modules/common/files/files-list.html',
                        controller: 'CommonFilesListController',
                        controllerAs: 'vm'
                    }
                },
                resolve: {
                    fileCollectionResponse: function(apiClient, purchaseOrderResponse, $stateParams) {
                        var params = {
                            order: $stateParams.fileOrder,
                            limit: 100,
                            entityId: purchaseOrderResponse.id
                        };
                        return apiClient.get(purchaseOrderResponse.getLink('files'), params).then(function(response) {
                            return response || apiClient.noResourceFound();
                        });
                    },
                    config: function() {
                        return angular.extend({}, {
                            showVisibility: true,
                            showStatutoryType: false,
                            showSite: false
                        });
                    },
                    integrationDocTypesResponse: function()
                    {
                        return null;
                    },
                    documentTypeCollectionResponse : function (apiClient, rootResourceResponse) {
                        return apiClient.get(rootResourceResponse.getLink('documenttypes'), {status: 'active', order: 'name'})
                            .then(function (response) {
                                return response || apiClient.noResourceFound();
                            });
                    },
                    jobResponse: function() {
                        return null;
                    },
                    serviceProviderResponse: function () {
                        return null;
                    },
                    operativeCollectionResponse: function () {
                        return null;
                    }
                },
                ncyBreadcrumb: {
                    label: '{{ ::"FILES" | translate }}'
                }
            })
            .state('dashboard.user.finance.purchase-orders.list.details.files.details', {
                url: '/details/{fileResource}',
                parent: 'dashboard.user.finance.purchase-orders.list.details.files',
                views: {
                    'files@dashboard.user.finance.purchase-orders.list.details': {
                        templateUrl: '/modules/common/files/details/files-details.html',
                        controller: 'CommonFilesDetailsController',
                        controllerAs: 'vm',
                    }
                },
                resolve: {
                    fileResponse: function(apiClient, $stateParams) {
                        return apiClient.get($stateParams.fileResource.decode()).then(function(response) {
                            return response || apiClient.noResourceFound();
                        });
                    },
                    config: function () {
                        return angular.extend({
                            showVisibility: true,
                            showStatutoryType: false,
                            showSite: false
                        });
                    }
                },
                ncyBreadcrumb: {
                    label: '{{ ::$resolves().fileResponse.title }}'
                }
            })
            .state('dashboard.user.finance.purchase-orders.list.details.files.details.edit', {
                url: '/edit',
                parent: 'dashboard.user.finance.purchase-orders.list.details.files.details',
                views: {
                    'files@dashboard.user.finance.purchase-orders.list.details': {
                        templateUrl: '/modules/common/files/add-edit/files-add-edit.html',
                        controller: 'CommonFilesAddEditController',
                        controllerAs: 'vm'
                    }
                },
                resolve: {
                    config: function(siteResponse, purchaseOrderResponse) {
                        return angular.extend({
                            entitySite: siteResponse,
                            associatedEntity: purchaseOrderResponse,
                            fileCreateLink: purchaseOrderResponse.getLink('files'),
                            showVisibility: true,
                            showSite: false
                        });
                    },
                    tagCollectionResponse: function(apiClient, rootResourceResponse) {
                        return apiClient.get(rootResourceResponse.getLink('tags'), { limit: 30 }).then(function(response) {
                            return response || apiClient.noResourceFound();
                        });
                    },
                    integrationDocTypesResponse: function() {
                        return null;
                    }
                },
                ncyBreadcrumb: {
                    label: '{{ ::"EDIT" | translate }}'
                }
            })
            .state('dashboard.user.finance.purchase-orders.list.details.files.add', {
                url: '/add',
                parent: 'dashboard.user.finance.purchase-orders.list.details.files',
                views: {
                    'files@dashboard.user.finance.purchase-orders.list.details': {
                        templateUrl: '/modules/common/files/add-edit/files-add-edit.html',
                        controller: 'CommonFilesAddEditController',
                        controllerAs: 'vm'
                    }
                },
                resolve: {
                    fileResponse: function() {
                        return {};
                    },
                    tagCollectionResponse: function(apiClient, rootResourceResponse) {
                        return apiClient.get(rootResourceResponse.getLink('tags'), { limit: 30 }).then(function(response) {
                            return response || apiClient.noResourceFound();
                        });
                    },
                    config: function(siteResponse, purchaseOrderResponse) {
                        return angular.extend({
                            entitySite: siteResponse,
                            associatedEntity: purchaseOrderResponse,
                            fileCreateLink: purchaseOrderResponse.getLink('files'),
                            showVisibility: true,
                            showSite: false
                        });
                    },
                    integrationDocTypesResponse: function() {
                        return null;
                    }
                },
                ncyBreadcrumb: {
                    label: '{{ ::"ADD" | translate }}'
                }
            })
    }
})();
