(function () {
    'use strict';

    angular
        .module('elogbooks.admin.regions')
        .controller('RegionDetailsController', RegionDetailsController);

    RegionDetailsController.$inject = ['regionResponse'];

    function RegionDetailsController (regionResponse) {
        var vm = this;
        vm.region = regionResponse;
    }
})();
