(function () {
    'use strict';

    angular
        .module('elogbooks.admin.users')
        .controller('UserOperativeController', UserOperativeController);

    UserOperativeController.$inject = [
        'apiClient',
        'lodash',
        'confirmationModal',
        'operativesResponse',
        'userResponse',
        'operativePermissions',
        'rootResourceResponse',
        'messenger',
        'filterPermissions',
        'serviceProviderCollectionResponse',
        '$stateParams',
        '$scope'
    ];

    function UserOperativeController(
        apiClient,
        lodash,
        confirmationModal,
        operativesResponse,
        userResponse,
        operativePermissions,
        rootResourceResponse,
        messenger,
        filterPermissions,
        serviceProviderCollectionResponse,
        $stateParams,
        $scope
    ) {

        var vm = this;
        vm.loadOperatives = loadOperatives;
        vm.addOperative = addOperative;
        vm.deleteOne = deleteOne;
        vm.toggleAll = toggleAll;
        vm.toggleOne = toggleOne;
        vm.removeSinglePermission = removeSinglePermission;
        vm.addPermissions = addPermissions;
        vm.operatives = operativesResponse.getData().operatives;
        vm.pages = operativesResponse.getData().pages;
        vm.operativesResponse = operativesResponse;
        vm.rootResourceResponse = rootResourceResponse;
        vm.removePermissions = removePermissions;
        vm.permissions = operativePermissions.getAllTranslated();
        vm.selectedPermissions = [];
        vm.selectedOperatives = [];
        vm.allSelected;
        vm.serviceProviderCollectionResponse = serviceProviderCollectionResponse;
        vm.OriginalServiceProviderCollectionResponse = angular.copy(serviceProviderCollectionResponse);
        vm.linkParameters = {
            datagroup: 'dropdown',
            limit: 999
        }

        vm.form = null;
        vm.formControll = null;

        vm.setForm = (form) => {
            vm.form = form;

            let findController = (scope) =>
                scope.hasOwnProperty('vm') ? scope.vm :
                    (scope.hasOwnProperty('$parent') ? findController(scope.$parent) : null);

            let controller = findController($scope.$parent);
            if (controller && controller.formControll) {
                vm.formControll = controller.formControll;
                controller.formControll.setForm(form);
            }
        }

        angular.forEach(vm.operatives, function (value, index) {
            vm.operatives[index].permissions = filterPermissions.filterAllowedPermissions(vm.operatives[index].permissions, operativePermissions.getAll());
            vm.operatives[index].checked = false;
        });

        vm.serviceProviderCollectionResponse.serviceproviders = lodash.filter(vm.serviceProviderCollectionResponse.serviceproviders, function(serviceProvider) {
            return !vm.operatives.some(function(operative) {
                return serviceProvider.id === operative._links.serviceprovider.id;
            });
        });

        vm.serviceProviderSelect = {
            link : rootResourceResponse ? rootResourceResponse.getLink('serviceproviders') : '',
            response : serviceProviderCollectionResponse,
            responseKeyPath: 'serviceproviders',
            linkParameters: vm.linkParameters,
            searchKey: 'name',
            required: true,
            loadmoreEnabled: false,
            selectedItems: vm.operatives.map(function(operative) {
                return {
                    object: operative._links.serviceprovider,
                    href: operative._links.serviceprovider.href,
                    value: operative._links.serviceprovider.name,
                    id: operative._links.serviceprovider.id
                };
            })
        };

        function loadOperatives() {
            apiClient.getPage(operativesResponse.getLink('self'), vm.linkParameters).then(function (response) {
                vm.operatives = response.getData().operatives;
                vm.pages = response.getData().pages;
                vm.allSelected = false;

                vm.serviceProviderSelect.selectedItems = lodash.unionBy(vm.serviceProviderSelect.selectedItems, [vm.serviceProviderSelect.selected]);
                const { serviceproviders } = vm.OriginalServiceProviderCollectionResponse;
                const operativesLinks = vm.operatives.map(operative => operative._links.serviceprovider.href);

                vm.serviceProviderSelect.items = lodash.differenceBy(
                    serviceproviders.map(serviceProvider => ({
                        object: serviceProvider,
                        href: serviceProvider._links.self.href,
                        value: serviceProvider.name,
                        id: serviceProvider.id
                    })),
                    operativesLinks.map(href => ({ href })),
                    'href'
                );
            });
        }

        function addOperative() {
            var requestData = {
                _links: {
                    serviceProvider: {
                        href: vm.serviceProviderSelect.selected.object.getLink('self')
                    }
                }
            };

            vm.formControll && vm.formControll.setFormDirty();
            apiClient.create(userResponse.getLink('operatives'), requestData).then(function (response) {
                vm.formControll && vm.formControll.setFormNotDirty();

                if (response) {
                    vm.loadOperatives();
                    messenger.success('SERVICE_PROVIDERS_UPDATED');
                }
            });
        }

        function deleteOne(operative) {
            return confirmationModal.open().result.then(
                function() {
                    // YES was clicked
                    vm.formControll && vm.formControll.setFormDirty();

                    apiClient.delete(operative._links.delete.href).then(function (response) {
                        vm.formControll && vm.formControll.setFormNotDirty();

                        if (response) {
                            vm.loadOperatives();
                             messenger.success('SERVICE_PROVIDERS_UPDATED');
                        }
                    });
                },
                function() {
                    // NO was clicked
                }
            );
        }

        function toggleAll(allSelected) {
            vm.selectedOperatives = [];
            if (allSelected) {
                lodash.each(vm.operatives, function (operative) {
                    operative.checked = true;
                    vm.selectedOperatives.push(operative);
                });
            } else {
                lodash.each(vm.operatives, function (operative) {
                    operative.checked = false;
                });
            }
        }

        function toggleOne(operative) {
            if (operative.checked) {
                vm.selectedOperatives.push(operative);
            } else {
                vm.selectedOperatives = lodash.without(vm.selectedOperatives, operative);
            }
        }

        function removeSinglePermission(operative, permission) {
            operative.permissions = lodash.without(operative.permissions, permission);

            vm.formControll && vm.formControll.setFormDirty();
            apiClient.update(operative._links.self.href, { permissions: operative.permissions }).then(function (response) {
                vm.formControll && vm.formControll.setFormNotDirty();

                if (!response) {
                    operative.permissions.push(permission);
                }
                else {
                    messenger.success('SERVICE_PROVIDER_PERMISSIONS_UPDATED');
                }
            });
        }

        function addPermissions() {
            var requestData = {
                _links: {
                    operative: []
                },
                additions: []
            }, permissions = [];

            lodash.each(vm.selectedPermissions, function (permission) {
                permissions.push(permission.label);
            });

            requestData.additions = permissions;
            lodash.each(vm.selectedOperatives, function (operative) {
                requestData._links.operative.push(operative._links.self);
                operative.permissions = lodash.union(operative.permissions, permissions);
            });

            vm.formControll && vm.formControll.setFormDirty();

            // TODO use advertised link when EWV-425 is complete
            apiClient.create(userResponse.getLink('operatives') + '/permission-changeset', requestData).then(function (response) {
                vm.formControll && vm.formControll.setFormNotDirty();
                messenger.success('SERVICE_PROVIDER_PERMISSIONS_UPDATED');
            });
        };

        function removePermissions() {
            var requestData = {
                _links: {
                    operative: []
                },
                subtractions: []
            }, permissions = [];
            lodash.each(vm.selectedPermissions, function (permission) {
                permissions.push(permission.label);
            });
            requestData.subtractions = permissions;
            lodash.each(vm.selectedOperatives, function (operative) {
                requestData._links.operative.push(operative._links.self);
                operative.permissions = lodash.difference(operative.permissions, permissions);
            });

            vm.formControll && vm.formControll.setFormDirty();
            // TODO use advertised link when EWV-425 is complete
            apiClient.create(userResponse.getLink('operatives') + '/permission-changeset', requestData).then(function (response) {
                vm.formControll && vm.formControll.setFormNotDirty();
                messenger.success('SERVICE_PROVIDER_PERMISSIONS_UPDATED');
            });
        }
    }
})();
