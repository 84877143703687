(function() {
    'use strict';

    angular
        .module('elogbooksDirectives')
        .directive('elbDashboardCommonReport', elbDashboardCommonReport);

    elbDashboardCommonReport.$inject = ['$state', '$translate', 'lodash', '$timeout', 'moment', 'userManager', '$window', 'base64'];

    function elbDashboardCommonReport($state, $translate, lodash, $timeout, moment, userManager, $window, base64) {
        return {
            restrict: 'AE',
            scope: {
                model: '=ngModel'
            },
            templateUrl: '/modules/directives/elb-dashboard-common-report/common-report-directive.html',
            link: function($scope) {
                var reload = true;

                $scope.$watch('model.options', function() {
                    if ($scope.model.options !== null) {
                        setUpStat();
                    }
                });

                function setUpStat() {
                    if (!$scope.model.options) {
                        return;
                    }

                    $scope.model.total = $scope.model.options.length;
                    $scope.goToRoute = goToRoute;

                    $timeout(function() {
                        if ($scope.model.type === 'multiBarChart') {
                            var container = angular.element.find('.action-barchart');

                            if ($scope.options && container.length > 0) {
                                $scope.options.chart.width = container[0].clientWidth;
                            }
                        }
                    },300);

                    if ($scope.model.type === 'pieChart') {
                        var totalValue = 0;
                        var chartScore = 0;
                        var chartPercentage = 0;

                        lodash.each($scope.model.options, function (element) {
                            if (element.positive) {
                                chartScore += parseInt(element.value);
                            }
                            totalValue += parseInt(element.value);
                        });

                        // calculate percentage, round value
                        chartPercentage = Math.round(parseFloat(chartScore / totalValue * 100));

                        $scope.options = {
                            chart: {
                                type: $scope.model.type,
                                route: $scope.model.route,
                                x: function(data) { return $translate.instant('LEGEND_' + data.key.toUpperCase()); },
                                y: function(data) { return data.value; },
                                height: '220',
                                width: '240',
                                donut: true,
                                title: chartPercentage + '%',
                                noData: 'No Data!',
                                showLabels: false,
                                showLegend: false,
                                donutRatio: 0.6,
                                margin : {
                                    top: -5
                                },
                                color: function(data) {
                                    return data.colour;
                                },
                                valueFormat: function(value) { return d3.format(',.0d')(value); },
                                pie: {
                                    dispatch: {
                                        'elementClick': function(element) {
                                            goToRoute($scope.model.route, $scope.model.parameters[element.index]);
                                        }
                                    }
                                }

                            }
                        };
                    } else if ($scope.model.type === 'multiBarChart') {
                        $scope.options = {
                            chart: {
                                type: 'multiBarChart',
                                height: 230,
                                margin: {
                                    top: 20,
                                    right: 0,
                                    bottom: 50,
                                    left: 35
                                },
                                stacked: true,
                                showControls: false,
                                yAxis: {
                                    tickFormat: function(d) {
                                        return d3.format(',.0d')(d);
                                    }
                                },
                                xAxis: {
                                    tickFormat: function(label) {
                                        return moment(label, 'MM/YYYY').format('MMM');
                                    }
                                },
                                reduceXTicks: false,
                                showLegend: false,
                                multibar: {
                                    dispatch: {
                                        elementClick: function(element) {
                                            var startDate = moment(element.data.x, 'MM/YYYY').startOf('month').toDate().toISOString();
                                            var endDate = moment(element.data.x, 'MM/YYYY').endOf('month').toDate().toISOString();
                                            var params = {};

                                            for (var key in $scope.model.dynamicParameters) {
                                                if (!$scope.model.dynamicParameters.hasOwnProperty(key)) {
                                                    continue;
                                                }
                                                switch ($scope.model.dynamicParameters[key]) {
                                                    case 'startDate':
                                                        params[key] = startDate;
                                                        break;
                                                    case 'endDate':
                                                        params[key] = endDate;
                                                        break;
                                                    default:
                                                        params[key] = element.data[$scope.model.dynamicParameters[key]];
                                                }
                                            }

                                            $scope.model.parameters = angular.extend({}, $scope.model.parameters, params);
                                            goToRoute($scope.model.route, $scope.model.parameters);
                                        }
                                    }
                                }
                            }
                        };
                    }
                }

                function goToRoute(redirect, options) {
                    if ($window.localStorage.getItem('selectedSiteResource') === null) {
                        reload = false;
                    } else {
                        $window.localStorage.removeItem('selectedSiteResource');
                    }

                    $state.go(redirect, options);
                }
            }
        };
    }
})();
