(function() {
    'use strict';

    angular
        .module('elogbooks.common.partnership')
        .controller('CoreClientsSiteGroupsAddController', CoreClientsSiteGroupsAddController);

    CoreClientsSiteGroupsAddController.$inject = [
        '$state',
        'apiClient',
        'messenger',
        'siteGroupCollectionResponse',
        'coreClientResponse'
    ];

    function CoreClientsSiteGroupsAddController(
        $state,
        apiClient,
        messenger,
        siteGroupCollectionResponse,
        coreClientResponse
    ) {
        var vm = this;
        vm.siteGroups = siteGroupCollectionResponse?.sitegroups || null;
        vm.selectedSiteGroups = [];
        vm.disableSubmit = false;

        vm.siteGroupSelect = {
            response: siteGroupCollectionResponse,
            link: siteGroupCollectionResponse ? siteGroupCollectionResponse.getLink('self') : null,
            required: true,
            responseKeyPath: 'sitegroups',
            itemHrefPath: '_links.self.href',
            selected: null
        };

        vm.create = async function() {
            vm.disableSubmit = true;

            if (!vm.selectedSiteGroups.length) {
                $state.go('^', {});
                return;
            }

            var selected = {
                _links: {
                    sitegroup: []
                }
            };

            for (const group of vm.selectedSiteGroups) {
                selected._links.sitegroup.push({
                    href: group.href
                });
            }

            const response = await apiClient.update(coreClientResponse.getLink('attach-site-group'), selected);

            if (response) {
                $state.go('^', {sgName: ''}, {reload: $state.get('^')}).then(function() {
                    messenger.success('SITE_GROUPS_ADDED');
                });
            } else {
                messenger.error('REQUEST_ERROR');
            }

            vm.disableSubmit = false;
        };
    }
})();
