(function () {
    'use strict';

    angular
        .module('elogbooks.user.finance.credit-notes')
        .controller('FinanceCreditNotesController', FinanceCreditNotesController);

        FinanceCreditNotesController.$inject = [
            '$filter',
            '$stateParams',
            'creditNotesCollectionResponse',
            '$state'
        ];

        function FinanceCreditNotesController(
            $filter,
            $stateParams,
            creditNotesCollectionResponse,
            $state
        ) {
            var vm = this;

            var statusOptions = [
                {
                    title: $filter('translate')('CREDIT_NOTE_STATUS_DRAFT'),
                    value: 'status_draft'
                },
                {
                    title: $filter('translate')('CREDIT_NOTE_STATUS_ISSUED'),
                    value: 'status_issued'
                },
                {
                    title: $filter('translate')('CREDIT_NOTE_STATUS_CANCELLED'),
                    value: 'status_cancelled'
                }
            ];

            vm.creditNotesCollectionResponse = creditNotesCollectionResponse;
            vm.creditNotes = creditNotesCollectionResponse.creditNotes;
            vm.order = orderAction;
            vm.search = searchAction;

            vm.criteria = {
                status: {
                    type: 'options',
                    title: 'STATUS',
                    multiple: true,
                    value: $stateParams.status,
                    options: statusOptions
                },
                salesInvoiceId: {
                    type: 'number',
                    title: 'SALES_INVOICE_ID',
                    value: $stateParams.salesInvoiceId
                },
                createdDateRange: {
                    type: 'date-range',
                    title: 'CREATED_DATE_BETWEEN',
                    value: {
                        startDate: ($stateParams.createdDateRange !== null && typeof $stateParams.createdDateRange === 'string') ?
                            moment(new Date($stateParams.createdDateRange.split(',')[0])) :
                            null,
                        endDate: ($stateParams.createdDateRange !== null && typeof $stateParams.createdDateRange === 'string') ?
                            moment(new Date($stateParams.createdDateRange.split(',')[1])) :
                            null
                    },
                    clearValue: {
                        startDate: null,
                        endDate: null
                    }
                }
            }

            function orderAction(key) {
                $stateParams.creditNoteOrder = key;
                $state.go('.', angular.extend({}, $stateParams), { reload: $state.current });
            }

            function searchAction(params) {
                var override = {
                    creditNotePage: 1
                };

                $state.go('.', angular.extend({}, params, override), { reload: $state.current });
            }
        }
})();
