(function() {
    'use strict';

    angular
        .module('elogbooks.user.escalations')
        .controller('EscalationDetailsJobsController', EscalationDetailsJobsController);

    EscalationDetailsJobsController.$inject = [
        '$state',
        'messenger',
        'jobCollectionResponse',
        'lodash',
        'apiClient',
        'requestDataFactory',
        'confirmationModal',
        'config',
        '$translate',
        '$stateParams'
    ];

    function EscalationDetailsJobsController(
        $state,
        messenger,
        jobCollectionResponse,
        lodash,
        apiClient,
        requestDataFactory,
        confirmationModal,
        config,
        $translate,
        $stateParams
    ) {
        var vm = this;
        vm.jobCollectionResponse = jobCollectionResponse;
        vm.allChecked = false;
        vm.selectedItems = [];
        vm.data = requestDataFactory.createRequest();
        vm.isHelpdesk = config.isHelpdesk;

        vm.checkAll = checkAll;
        vm.toggleChecked = toggleChecked;

        vm.search = searchAction;
        vm.order = orderAction;

        vm.delete = deleteAction;

        function checkAll() {
            angular.forEach(jobCollectionResponse.jobs, function(job, $index) {
                job.checked = vm.allChecked;

                updateSelection(job);
            })
        }

        function toggleChecked(job) {
            updateSelection(job);
        }

        function updateSelection(job) {
            var index = lodash.findIndex(vm.selectedItems, {id: job.id});

            if (index === -1) {
                vm.selectedItems.push(job);
            } else {
                lodash.remove(vm.selectedItems, {id: job.id});
            }
        }

        function deleteAction() {

            vm.data._links = {jobs: []};
            angular.forEach(vm.selectedItems, function(value, $index) {
                vm.data._links.jobs.push({href: value.getLink('self')});
            });

            return confirmationModal.open().result.then(function() {
                return apiClient.create(jobCollectionResponse.getLink('deescalate'), vm.data).then(function(response) {
                    if (response) {
                        $state.go('.', {}, {reload: 'dashboard.user.escalations.list'}).then(function() {
                            messenger.success('DEESCALATED');
                        });
                    } else {
                        messenger.error('REQUEST_ERROR');
                    }
                });
            });
        }

        var options = [
            { title: $translate.instant('NONE_SELECTED'), value: null },
            { title: $translate.instant('YES'), value: 'on' },
            { title: $translate.instant('NO'), value: 'no' }
        ];

        vm.criteria = {
            escalationJobsStatutory: { type: 'options', title: 'STATUTORY', value: $stateParams.escalationJobsStatutory, options: options },
            updateToBeReviewed: { type: 'options', title: 'UPDATE_TO_BE_REVIEWED', value: $stateParams.updateToBeReviewed, options: options }
        };

        function searchAction (params) {
            var override = {
                escalationJobsPage: 1
            };
            $state.go('.', angular.extend({}, params, override), { reload: $state.current });
        }

        function orderAction (key) {
            $stateParams.escalationJobsOrder = key;

            $state.go('.', angular.extend({}, $stateParams), { reload: $state.current });
        }
    }
})();
