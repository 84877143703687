(function () {
    'use strict';

    angular
        .module('elogbooks.admin.sites')
        .controller('UnitsController', UnitsController);

    UnitsController.$inject = ['$state', '$stateParams', '$filter', 'unitCollectionResponse'];

    function UnitsController($state, $stateParams, $filter, unitCollectionResponse) {
        var vm = this;

        vm.units = unitCollectionResponse.units;
        vm.unitCollectionResponse = unitCollectionResponse;

        vm.order = order;
        vm.search = search;

        var statuses = [
            {
                title: $filter('translate')('STATUS_ACTIVE'),
                value: 'true'
            },
            {
                title: $filter('translate')('STATUS_INACTIVE'),
                value: 'false'
            },
            {
                title: $filter('translate')('NONE_SELECTED'),
                value: null
            }
        ];

        vm.criteria = {
            id: { type: 'number', value: $stateParams.id ? parseInt($stateParams.id, 10) : null, title: 'ID', min: 1 },
            name: { type: 'text', value: $stateParams.name, title: 'NAME' },
            symbol: { type: 'text', value: $stateParams.symbol, title: 'SYMBOL' },
            status: { type: 'options', title: 'IS_ACTIVE', value: $stateParams.status, options: statuses }
        };

        function search(params) {
            var override = {
                page: 1
            };

            $state.go('.', angular.extend({}, params, override), {reload: $state.current});
        };

        function order(key) {
            $stateParams.order = key;

            $state.go('.', angular.extend({}, $stateParams), {reload: $state.current});
        };
    }
})();
