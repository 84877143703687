app.config(function ($translateProvider) {
    $translateProvider.translations('nl', {
        FLOOR: 'Verdieping',
        FLOORS: 'Verdiepingen',

        ADD_NEW_FLOOR: 'Voeg een nieuwe verdieping toe',
        EDIT_FLOOR: 'Wijzig verdieping',

        FLOOR_CREATED: 'Verdieping gemaakt',
        FLOOR_UPDATED: 'Verdieping gewijzigd',
        FLOOR_DELETED: 'Verdieping verwijderd',

        NO_FLOOR_RECORDS: 'Geen verdieping vastgelegd'
    });
});
