(function () {
    'use strict';

    angular
        .module('elogbooksDirectives')
        .directive('bookerize', bookerize);

    function bookerize(apiClient) {
        var directive = {
            templateUrl: '/modules/directives/bookerize/bookerize.html',
            restrict: 'E',
            replace: true
        };

        return directive;
    }
})();
