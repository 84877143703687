app.config(function ($translateProvider) {
    $translateProvider.translations('nl', {
        ASSET_TYPE: 'Asset type',
        ASSET_TYPES: 'Asset types',

        ADD_NEW_ASSET_TYPE: 'Voeg een nieuwe Asset type toe',
        EDIT_ASSET_TYPE: 'Wijzig Asset type',

        ASSET_TYPE_LIST: 'Asset types',
        ASSET_TYPE_ADD: 'Voeg een nieuwe Asset type toe',
        ASSET_TYPE_EDIT: 'Wijzig Asset type',

        ASSET_TYPE_CREATED: 'Asset type gemaakt',
        ASSET_TYPE_UPDATED: 'Asset type gewijzigd',
        ASSET_TYPE_DELETED: 'Asset type verwijderd',
        ASSET_TYPE_ADDED: 'Asset type toegevoegd',
    });
});
