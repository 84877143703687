(function() {
    'use strict';

    angular
        .module('elogbooks.admin.billing-types')
        .controller('BillingTypesInfoController', BillingTypesInfoController);

    BillingTypesInfoController.$inject = ['$state', 'apiClient', 'confirmationModal', 'messenger', 'billingTypeResponse'];
    function BillingTypesInfoController($state, apiClient, confirmationModal, messenger, billingTypeResponse) {
        var vm = this;
        vm.billingType = billingTypeResponse;
    }
})();
