app.config(function($translateProvider) {
    $translateProvider.translations('en-gb', {
        TIMESHEET_TYPE: 'Timesheet Type',
        TIMESHEET_TYPES: 'Timesheet Types',
        TIMESHEET_TYPE_INFORMATION: 'Timesheet Type Information',

        ADD_NEW_TIMESHEET_TYPE: 'Add a new Timesheet Type',
        EDIT_TIMESHEET_TYPE: 'Edit Timesheet Type',

        TIMESHEET_TYPE_LIST: 'Timesheet Types',
        TIMESHEET_TYPE_ADD: 'Add a new Timesheet Type',
        TIMESHEET_TYPE_EDIT: 'Edit Timesheet Type',

        TIMESHEET_TYPE_CREATED: 'Timesheet Type created successfully',
        TIMESHEET_TYPE_UPDATED: 'Timesheet Type updated successfully',
        TIMESHEET_TYPE_DELETED: 'Timesheet Type deleted successfully',

        TIMESHEET_TYPE_REQUIRED: 'Timesheet Type is required'
    });
});