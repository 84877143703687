(function(){
    'use strict';

    angular
        .module('elogbooks.user.contracts')
        .controller('CompareController', CompareController);

    CompareController.$inject = ['contractResponse', 'oldContractResponse', 'config', 'eventCollectionResponse', 'oldEventCollection', '$stateParams', '$timeout', '$location', '$anchorScroll'];

    function CompareController(contractResponse, oldContractResponse, config, eventCollectionResponse, oldEventCollection, $stateParams, $timeout, $location, $anchorScroll) {
        var vm = this;
        vm.objects = [];

        var showBillingPractice = false;

        if (contractResponse.billingPractice || oldContractResponse.billingPractice) {
            showBillingPractice = true;
        }

        vm.objects.push(
            {
                contract:contractResponse,
                events: eventCollectionResponse,
                config: config,
                showBillingPractice: showBillingPractice
            },
            {
                contract:oldContractResponse,
                events: oldEventCollection,
                versionNumber: $stateParams.fakeVersion,
                config: config,
                showBillingPractice: showBillingPractice
            });

        $timeout(function() {
            $location.hash('top');
            $anchorScroll();
        }, 200);

    }
})();