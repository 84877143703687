(function () {
    angular
        .module('elogbooksDirectives')
        .directive('elbDragEvent', ElbDragEvent);

    ElbDragEvent.$inject = ['lodash', 'modulesService'];

    function ElbDragEvent (lodash, modulesService) {
        function animateToCell(weekTo, weekFrom, element) {
            element.attr('data-week', weekTo);

            element.animate({
                left: (weekTo - weekFrom) * 35
            }, 400);
        }

        function getPlannerScope(element) {
            var scope = element.scope();

            if (lodash.has(scope, '$parent.$parent.planner') || lodash.has(scope, '$parent.$parent.$parent.planner')) {
                return scope.$parent.$parent;
            }

            return false;
        }

        function resetElement(element, to) {
            element.removeClass('dropped');
            element.attr('data-week', to);
            element.animate({
                left: 0
            }, 400);
        }

        return {
            restrict: 'AE',
            link: function(scope, elem, attrs) {
                elem.draggable({
                    axis: "x",
                    // For performance we need to avoid using droppable on cells and fetch
                    // drop container via mouse position.,
                    start: function (event, ui) {
                        angular.element(event.target).attr('id', 'dragging');
                    },
                    stop: function( event, ui ) {
                        var draggedElement = angular.element(event.target);
                        var dropContainer = angular.element(document.elementFromPoint(event.clientX, event.clientY));
                        var scope = getPlannerScope(dropContainer);
                        var droppedOnAnotherRow = false;

                        // Required to remove extra css
                        draggedElement.attr('id', '');
                        draggedElement.addClass('dropped');

                        let mouseDropY = event.originalEvent.originalEvent.clientY;
                        let top = draggedElement[0].getBoundingClientRect().top;
                        let bottom = draggedElement[0].getBoundingClientRect().bottom;

                        if (mouseDropY < top || mouseDropY > bottom)
                        {
                            droppedOnAnotherRow = true;
                        }

                        // Validate drop container.
                        if (!dropContainer.hasClass('event-drop-container')
                            || !scope
                            || draggedElement.data('is-single') === '' && (dropContainer.hasClass('ui-draggable') || dropContainer.hasClass('approval-pending'))
                            || draggedElement[0].dataset.week === dropContainer[0].dataset.week
                            || !modulesService.isEnabled('advanced_ppm_planner') &&
                                dropContainer.hasClass('event-drop-container') &&
                               (dropContainer.hasClass('ghost-approved') || dropContainer.hasClass('approved') ||
                               dropContainer.hasClass('approval-pending'))
                            || droppedOnAnotherRow

                        ) {
                            // reverse if invalid container/reserved.
                            draggedElement.removeClass('dropped');

                            return animateToCell(
                                draggedElement.data('week'),
                                draggedElement.data('week'),
                                draggedElement
                            );
                        }

                        animateToCell(
                            dropContainer.data('week'),
                            draggedElement.data('week'),
                            draggedElement
                        );


                        scope.moveEvent(
                            draggedElement.data('task-id'),
                            draggedElement.data('week'),
                            dropContainer.data('week'),
                            draggedElement,
                            resetElement,
                            typeof scope.planner[draggedElement.data('task-id')][dropContainer.data('week')] !== "undefined"
                        );
                    }
                });
            }
        };
    }
})();