(function() {
    'use strict';

    angular
        .module('elogbooks.user.escalations')
        .controller('EscalationDetailsController', EscalationDetailsController);

    EscalationDetailsController.$inject = ['escalationResponse', 'escalationStageResponse'];

    function EscalationDetailsController(escalationResponse, escalationStageResponse) {
        var vm = this;
        vm.escalationResponse = escalationResponse;
        vm.escalationStageResponse = escalationStageResponse;
    }
})();
