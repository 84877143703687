(function() {
    'use strict';

    angular
        .module('elogbooks.admin.settings')
        .controller('AdminServiceProviderFeedbackEditController', AdminServiceProviderFeedbackEditController);

    AdminServiceProviderFeedbackEditController.$inject = ['apiClient', '$state', 'settingsCollection', 'messenger'];

    function AdminServiceProviderFeedbackEditController(apiClient, $state, settingsCollection, messenger) {
        var vm = this;
        vm.settings = settingsCollection;
        vm.findSettingValue = findSettingValue;
        vm.payload = {
            'sp_feedback_modal_title': vm.findSettingValue('sp_feedback_modal_title'),
            'sp_feedback_modal_body': vm.findSettingValue('sp_feedback_modal_body'),
            'sp_feedback_display_job_statistics': vm.findSettingValue('sp_feedback_display_job_statistics').toString(),
            'sp_feedback_allow_comments': vm.findSettingValue('sp_feedback_allow_comments').toString(),
            'sp_feedback_allowed_popups_per_week': vm.findSettingValue('sp_feedback_allowed_popups_per_week'),
            'sp_feedback_active': vm.findSettingValue('sp_feedback_active')
        };

        vm.validator = {
            'title': () => vm.payload.sp_feedback_modal_title === undefined ||
                vm.payload.sp_feedback_modal_title.length === 0 ||
                vm.payload.sp_feedback_modal_title.length > 255,
            'body': () => vm.payload.sp_feedback_modal_body === undefined ||
                vm.payload.sp_feedback_modal_body.length > 255 ||
                !vm.payload.sp_feedback_modal_body.includes('[SERVICE_PROVIDER]'),
            'allowedPopups': () => !vm.payload['sp_feedback_allowed_popups_per_week']
                || vm.payload.sp_feedback_allowed_popups_per_week === undefined ||
                parseInt(vm.payload.sp_feedback_allowed_popups_per_week) > 5 ||
                vm.payload.sp_feedback_allowed_popups_per_week % 1
        };

        vm.update = updateAction;

        function updateAction () {
            if (vm.payload['sp_feedback_modal_title'] === undefined ||
                !vm.payload['sp_feedback_modal_title'] ||
                vm.payload['sp_feedback_modal_title'].length === 0) {
                    messenger.error('SP_FEEDBACK_MODAL_TITLE_EMPTY_MESSAGE');

                    return;
            }

            if (vm.payload['sp_feedback_modal_title'].length > 255) {
                messenger.error('SP_FEEDBACK_MODAL_TITLE_INVALID_MESSAGE');

                return;
            }

            if (vm.payload['sp_feedback_modal_body'] === undefined ||
                !vm.payload['sp_feedback_modal_body'] ||
                vm.payload['sp_feedback_modal_body'].length === 0) {
                messenger.error('SP_FEEDBACK_MODAL_BODY_REQUIRED_MESSAGE');

                return;
            }

            if (vm.payload['sp_feedback_modal_body'].length > 255) {
                messenger.error('SP_FEEDBACK_MODAL_BODY_INVALID_MESSAGE');

                return;
            }

            if (!vm.payload['sp_feedback_modal_body'].includes('[SERVICE_PROVIDER]')) {
                messenger.error('SP_FEEDBACK_MODAL_BODY_MISSING_VARIABLE_MESSAGE');

                return;
            }

            if (!vm.payload['sp_feedback_allowed_popups_per_week'] ||
                vm.payload['sp_feedback_allowed_popups_per_week'] === undefined ||
                vm.payload['sp_feedback_allowed_popups_per_week'].length > 5 ||
                vm.payload['sp_feedback_allowed_popups_per_week'].length < 1
                ) {
                messenger.error('SP_FEEDBACK_ALLOWED_POPUPS_PER_WEEK_INVALID_MESSAGE');

                return;
            }

            if (vm.payload['sp_feedback_allowed_popups_per_week'] % 1) {
                messenger.error('SP_FEEDBACK_ALLOWED_POPUPS_PER_WEEK_NON_DECIMAL_ONLY');

                return;
            }

            var payload = { settings: []};

            Object.keys(vm.payload).forEach(key => {
                payload.settings.push({
                    name: key,
                    value: (key === 'sp_feedback_display_job_statistics' || key === 'sp_feedback_allow_comments') ? JSON.parse(vm.payload[key]) : vm.payload[key]
                });
            });

            return apiClient.update(settingsCollection.getLink('edit'), payload).then(function (response) {
                if (response) {
                    $state.go('^', {}, {reload: true}).then(function () {
                        messenger.success('SP_FEEDBACK_UPDATE_SUCCESS_MESSAGE');
                    });
                } else {
                    messenger.error('ERROR');
                }
            });
        }

        function findSettingValue(settingName) {
            return settingsCollection.settings.filter(setting => {
                return setting.name === settingName;
            })[0].value;
        }
    }
})();
