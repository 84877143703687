(function () {
    'use strict';

    angular
        .module('elogbooksControllers')
        .controller('LightBoxModalController', LightBoxController);

    LightBoxController.$inject = ['modalData', 'config', '$uibModalInstance', '$rootScope'];

    function LightBoxController( modalData, config, $uibModalInstance, $rootScope) {
        var vm = this;
        vm.config = config;
        vm.modalData = modalData;
        vm.close = close;
        
        if (!vm.config.forceDirtyConfirm) {
            $rootScope.skipDirtyCheck = true;
        }

        function close() {
            $rootScope.skipDirtyCheck = false;
            $uibModalInstance.dismiss();
        };
    }
})();
