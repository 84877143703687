(function() {
    'use strict';

    angular
        .module('elogbooks.common.waste-management')
        .controller('WasteEntryDetailsController', WasteEntryDetailsController);

    WasteEntryDetailsController.$inject = ['wasteEntryResponse', 'sectionResource'];

    function WasteEntryDetailsController(wasteEntryResponse, sectionResource) {
        var vm = this;
        vm.wasteEntry = wasteEntryResponse;
        vm.sectionResource = sectionResource;
    }
})();