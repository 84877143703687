(function() {
    'use strict';

    angular
        .module('elogbooks')
        .config(Translations);

    function Translations($translateProvider) {
        $translateProvider.translations('nl', {
            BILLING_TYPES: 'Facturatie soorten',
            BILLING_TYPE: 'Facturatie soort',
            BILLING_TYPES_LIST: 'Lijst met facturatiesoorten',
            BILLING_TYPES_ADD: 'Voeg nieuwe soort facturatie toe',
            BILLING_TYPES_EDIT: 'Wijzig facturatie soort',

            BILLING_TYPE_UPDATED: 'Facturatie soort aangepast',
            BILLING_TYPE_CREATED: 'Facturatie soort gemaakt',
            BILLING_TYPE_DELETED: 'Facturatie soort verwijderd',

            BILLING_TYPE_INFORMATION: 'Facturaties soort info',

            BILLING_COST: 'Facturatie kosten'
        });
    }
})();
