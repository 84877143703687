(function(){
    'use strict';

    angular
        .module('elogbooks.admin.service-providers')
        .controller('ServiceProviderPartnershipsAddController', ServiceProviderPartnershipsAddController);

    ServiceProviderPartnershipsAddController.$inject = ['$stateParams', '$state', 'messenger', 'apiClient', 'lodash', 'serviceProviderResponse', 'partnershipsResponse', 'siteCollection'];

    function ServiceProviderPartnershipsAddController($stateParams, $state, messenger, apiClient, lodash, serviceProviderResponse, partnershipsResponse, siteCollection) {
        var vm = this;
        vm.serviceProvider = serviceProviderResponse;
        vm.siteSelect = {
            response : siteCollection,
            link : siteCollection ? siteCollection.getLink('self') : '',
            responseKeyPath: 'sites'
        };

        vm.create = createAction;
        vm.disableSubmit = false;

        function createAction() {
            var data = {
                _links: {}
            };

            if (vm.siteSelect.selectedItems.length) {
                data._links.site = vm.siteSelect.selectedItems;
            }
            vm.disableSubmit = true;
            apiClient.create(vm.serviceProvider.getLink('partnerships'), data).then(function (response) {
                if (response) {
                    $state.go('^', $stateParams, { reload: true }).then(function () {
                        messenger.success('SERVICE_PROVIDER_PARTNERSHIP_ADDED');
                    });
                } else {
                    vm.disableSubmit = false;
                    messenger.error('REQUEST_ERROR');
                }
            });
        }
    }
})();
