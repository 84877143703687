(function() {
    'use strict';

    angular
        .module('elogbooks.admin.pricing-schedules')
        .controller('AbsenceEntryWorkflowController', AbsenceEntryWorkflowController);

    AbsenceEntryWorkflowController.$inject = ['absenceEntryResponse'];

    function AbsenceEntryWorkflowController(absenceEntryResponse) {
        var vm = this;

        vm.absenceEntry = absenceEntryResponse;
    }
})();
