(function (){
    'use strict';

    angular
        .module('elogbooks.user.master-tasks')
        .controller('UserMasterTaskListController', UserMasterTaskListController);

    UserMasterTaskListController.$inject = ['$state', '$stateParams', '$translate', 'masterTaskCollectionResource'];

    function UserMasterTaskListController($state, $stateParams, $translate, masterTaskCollectionResource) {
        var vm = this;
        vm.masterTasks = masterTaskCollectionResource ? masterTaskCollectionResource.masterTasks : [];
        vm.masterTasksCollection = masterTaskCollectionResource;
        vm.search = searchAction;
        vm.order = orderAction;

        var statusOptions = [
            { title: $translate.instant('NONE_SELECTED'), value: null },
            { title: $translate.instant('STATUS_ACTIVE'), value: 'active' },
            { title: $translate.instant('STATUS_INACTIVE'), value: 'inactive' }
        ];

        var serviceType = [
            { title: $translate.instant('NONE_SELECTED'), value: null },
            { title: $translate.instant('SOFT_SERVICE'), value: 'soft' },
            { title: $translate.instant('HARD_SERVICE'), value: 'hard' }
        ];

        vm.criteria = {
            masterTaskName: { type: 'text', value: $stateParams.masterTaskName, title: 'NAME' },
            masterTaskStatus: { type: 'options', title: 'STATUS', value: $stateParams.masterTaskStatus, options: statusOptions},
            masterTaskType: { type: 'options', title: 'MASTER_TASK_SERVICE_TYPE', value: $stateParams.masterTaskType, options: serviceType}
        };

        /**
         * Search tasks
         */
        function searchAction(params) {
            var override = {
                page: 1
            };

            $state.go('.', angular.extend({}, params, override), { reload: $state.current });
        }

        /**
         * Order tasks
         */
        function orderAction(key) {
            $stateParams.order = key;

            $state.go('.', angular.extend({}, $stateParams), { reload: $state.current });
        }
    }
})();
