(function () {
    'use strict';

    angular
        .module('elogbooksFilters')
        .filter('absencePlannerStatus', absencePlannerStatus);

    function absencePlannerStatus ($filter) {
        var statuses = {
            0: 'PENDING_APPROVAL',
            1: 'APPROVED',
            2: 'REJECTED',
        };

        return function (input) {
            return $filter('translate')(statuses[input]);
        }
    };
})();
