(function () {
    'use strict';

    angular
        .module('elogbooksFilters')
        .filter('userCategory', userCategory);

    function userCategory ($filter) {
        var categories = {
            0: 'USER_CATEGORY_ELOGS',
            1: 'USER_CATEGORY_CLIENT',
            2: 'USER_CATEGORY_SERVICE_PROVIDER',
            3: 'USER_CATEGORY_API_USER',
            4: 'USER_CATEGORY_SUBCONTRACTOR',
            99: 'USER_CATEGORY_OTHER',
            '-1': 'NONE',
        };

        return function (input) {
            return $filter('translate')(categories[input]);
        }
    };
})();
