angular.module('elogbooksServices').service('Statuses', function() {

    var statuses = [
        {
            value: 'draft',
            label: 'DRAFT'
        },
        {
            value: 'pending',
            label: 'PENDING'
        },
        {
            value: 'tender',
            label: 'TENDER'
        },
        {
            value: 'deleted',
            label: 'DELETED'
        }
    ];

    this.getAll = function () {
        return statuses;
    }

    return this;
});
