(function () {
    'use strict';

    angular
        .module('elogbooksControllers')
        .controller('SharingSetupModalController', SharingSetupModalController);

    SharingSetupModalController.$inject = ['$scope', '$state', '$window', 'messenger', 'apiClient', 'API_URL', 'config', 'base64', '$uibModalInstance', 'instanceResponse', 'lodash', 'moment', 'requestDataFactory'];

    function SharingSetupModalController($scope, $state, $window, messenger, apiClient, API_URL, config, base64, $uibModalInstance, instanceResponse, lodash, moment, requestDataFactory) {
        var vm = this;
        vm.setup = function () {
            apiClient.create(instanceResponse.getLink('setup-share'), {
                indefinite: true
            }).then(function(response) {
                // todo add link validation and striping
                $window.location.replace(vm.data.url + "#/user/sharing-setup?payload=" + base64.encode(JSON.stringify({
                    headers: [
                        {
                            key: 'Authorization',
                            value: 'Bearer ' + response.token
                        }
                    ],
                    url: API_URL + response.url,
                    redirect: $window.location.href,
                    partnershipWebhooks: instanceResponse.getLink('webhooks'),
                    activeShare: 1
                })));
                $uibModalInstance.close();
            });
        };
    };
})();
