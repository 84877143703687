(function() {
    'use strict';

    angular
        .module('elogbooks.admin.site-relationships')
        .controller('SiteRelationshipsDetailsController', SiteRelationshipsDetailsController);

    SiteRelationshipsDetailsController.$inject = ['siteRelationshipResponse'];
    function SiteRelationshipsDetailsController(siteRelationshipResponse) {
        var vm = this;
        vm.siteRelationship = siteRelationshipResponse;
    }
})();
