app.config(function ($translateProvider) {
    $translateProvider.translations('en-gb', {
        DUTYMANAGEMENT: 'Duty Management',
        DUTY_MANAGEMENT: 'Duty Management',
        DUTY_MANAGEMENT_SITE_RELATION_REMOVED: 'Site relation has been removed',
        DUTY_MANAGEMENT_SCHEDULE_REMOVED: 'Schedule has been removed',
        DUTY_MANAGEMENT_BACK: 'Back',
        DUTY_MANAGEMENT_EDIT_USER: 'Edit User',
        DUTY_MANAGEMENT_SELECT_USER: 'Select User',
        DUTY_MANAGEMENT_SELECT_SITES: 'Select Sites',
        DUTY_MANAGEMENT_SELECT_DAYS: 'Select Days',
        DUTY_MANAGEMENT_SELECTED_DAYS: 'Selected days',
        DUTY_MANAGEMENT_SELECTED_SITES: 'Selected sites',
        DUTY_MANAGEMENT_ADD: 'Create Duty Management Schedule',
        DUTY_MANAGEMENT_ALREADY_IN_USE_SITES: 'Sites already in use'
    })
});
