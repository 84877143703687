(function () {
    'use strict';

    angular
        .module('elogbooks.common.assets')
        // .module('elogbooks.user.sites.assets')
        .controller('UserAssetsFrequencyController', UserAssetsFrequencyController);

    UserAssetsFrequencyController.$inject = ['apiClient', '$state', 'messenger', 'confirmationModal', 'frequencyCollectionResponse'];

    function UserAssetsFrequencyController(apiClient, $state, messenger, confirmationModal, frequencyCollectionResponse) {
        var vm = this;
            vm.frequencyCollectionResponse = frequencyCollectionResponse;
            vm.frequencies = frequencyCollectionResponse.frequencies;
    }
})();
