(function() {
    'use strict';
    angular
        .module('elogbooksControllers')
        .controller('FinanceDashboardController', FinanceDashboardController);

    FinanceDashboardController.$inject = [
        'lodash',
        'dashboardService',
        '$window',
        'apiClient',
        'elbSettings',
        'moment',
        '$timeout',
        '$filter',
        '$parse'
    ];

    function FinanceDashboardController(
        lodash,
        dashboard,
        $window,
        apiClient,
        elbSettings,
        moment,
        $timeout,
        $filter,
        $parse
    ) {
        var vm = this;
        vm.dashboard = dashboard;
        var currency = elbSettings.getSetting('currency').value;
        var locale = elbSettings.getSetting('general_default_locale').value;

        $timeout(function() {
            var element = document.querySelectorAll('.finance-dashboard-widget-container .nvd3 svg .nv-x .tick:nth-of-type(' + (new Date().getMonth() - 2) + ') text');
            element.forEach(function(ele) {
                ele.classList.add('current');
            });
        }, 1000);

        function getDataValue(data, key) {
            return lodash.get(
                lodash.find(data, { key: key  }),
                'value',
                0
            );
        }

        vm.dashboard.applyFinanceFilters([
            {
                route: 'finance-statistics-sales-totals-paid',
                enabled: false,
                dashboardWidget: 'sales-paid',
                category: 'sales',
                date: new Date(),
                data: false,
                getValue: getDataValue,
            },
            {
                route: 'finance-statistics-sales-totals-sent',
                enabled: false,
                dashboardWidget: 'sales-sent',
                category: 'sales',
                date: new Date(),
                data: false,
                getValue: getDataValue,
            },
            {
                route: 'finance-statistics-sales-annual-trend',
                enabled: false,
                dashboardWidget: 'sales-annual-trend',
                category: 'sales',
                className: 'col-md-6',
                data: false,
                chartType: 'multiBarChart',
                tickFormatY : function(val) {
                    var output = '';
                    if (currency === 'NONE') {
                        val = parseFloat(val).toLocaleString(locale, {maximumFractionDigits:2});
                        output = $filter('translate')('CURRENCY_' + currency) + val;
                    } else {
                        output = parseFloat(val).toLocaleString(locale, {maximumFractionDigits:2, style: 'currency', currency: currency});
                    }

                    return output;
                },
                tickFormatX: function(val) {
                    return moment(val, 'MM/YYYY').format('MMM');
                },
                tooltipContentGenerator: function(e) {
                    var series = e.series[0];
                    if (series.value === null) return;

                    var salesText = $filter('translate')('FINANCE_DASHBOARD_SALES');
                    var paidText = $filter('translate')('FINANCE_DASHBOARD_PAID_INVOICES');

                    var data = {
                        sales: e.data.y
                    }

                    var rows =
                        "<tr>" +
                        "<td class='key'>" + salesText + ': ' + "</td>" +
                        "<td class='x-value'><strong>" + $parse('sales | elbCurrency')(data) + "</strong></td>" +
                        "</tr>" +
                        "<tr>" +
                        "<td class='key'>" + paidText + ': ' + "</td>" +
                        "<td class='x-value'><strong>" + e.data.count + "</strong></td>" +
                        "</tr>";

                    return "<table>" +
                        "<tbody>" +
                        rows +
                        "</tbody>" +
                        "</table>";
                }
            }
        ]);

        if ($window.localStorage.getItem('userPreferencesUrl')) {
            apiClient.get($window.localStorage.getItem('userPreferencesUrl'), null, false)
                .then(function (response) {
                    vm.dashboard.applyUserPreferences(response);
                    vm.dashboard.getStatistics();
                });
        } else {
            vm.dashboard.getStatistics();
        }

        vm.dashboardStats = vm.dashboard.dashboardStats;
        vm.showStatistics = true;

        vm.salesStatistics = lodash.filter(vm.dashboardStats, { category: 'sales' });
        vm.purchaseStatistics = lodash.filter(vm.dashboardStats, { category: 'purchase' });
        vm.wipStatistics = lodash.filter(vm.dashboardStats, { category: 'wip' });
        vm.lodash = lodash;
    }
})();
