(function () {
    'use strict';

    angular
        .module('elogbooks.user.manage-operatives')
        .controller('UserManageOperativeOperativesController', UserManageOperativeOperativesController);

    UserManageOperativeOperativesController.$inject = ['$stateParams', '$state', 'apiClient', 'userResponse', 'operativeCollectionResponse', 'serviceProviderCollectionResponse', '$uibModal', 'messenger'];

    function UserManageOperativeOperativesController($stateParams, $state, apiClient, userResponse, operativeCollectionResponse, serviceProviderCollectionResponse, $uibModal, messenger) {
        var vm = this;
        var params = angular.copy($stateParams);
        vm.operativeCollection = operativeCollectionResponse;

        // close expandable windows if main page changes
        if ($stateParams.changed === 'true') {
            $stateParams.position = null;
        }

        vm.operatives = operativeCollectionResponse.operatives.map(function (value, index) {
            if (!$stateParams.position && $stateParams.position !== '0') {
                value.closed = true;
            } else if ($stateParams.position != index) {
                value.closed = true;
            }
            return value;
        });

        vm.serviceProviders = [];

        vm.search = search;
        vm.order = order;
        vm.getServiceProviders = getServiceProviders;
        vm.addServiceProvider = addServiceProvider;
        vm.createOperative = createOperative;

        vm.serviceProviderSelect = {
            response : serviceProviderCollectionResponse,
            link : serviceProviderCollectionResponse ? serviceProviderCollectionResponse.getLink('self') : null,
            itemValuePath: 'id',
            itemTitlePath: 'name',
            responseKeyPath: 'serviceproviders'
        };

        vm.criteria = {
            name: {type: 'text', title: 'NAME', value: $stateParams.name},
            username: {type: 'text', title: 'USERNAME', value: $stateParams.username},
            email: {type: 'text', title: 'EMAIL', value: $stateParams.email},
            serviceProvider: { type: 'jsonselectwidget', title: 'SERVICE_PROVIDER', value: JSON.parse($stateParams.serviceProvider), options: vm.serviceProviderSelect }
        };

        function addServiceProvider(operative) {
            $uibModal.open({
                templateUrl: '/modules/common/service-provider/modal/service-provider-add-modal.html',
                controller: 'ServiceProviderAddModalController',
                controllerAs: 'vm',
                resolve: {
                    operativeResponse: function () {
                        // get the user so we can get the user/###/operative link to add SP
                        return apiClient.get(operative.getLink('user')).then(function (response) {
                            return response;
                        });
                    },
                    serviceProviderCollectionResponse: function () {
                        var spParams = angular.copy($stateParams);
                        spParams.excludeOperative = operative._links.user.id;
                        return apiClient.get(userResponse.getLink('serviceproviders'), spParams);
                    },
                    config: function() {
                        return {
                            useDefaultManageOperativePermissions: true
                        }
                    }
                }
            });
        }

        function createOperative() {
            $uibModal.open({
                templateUrl: '/modules/common/operatives/modal/operative-create-modal.html',
                controller: 'OperativeAddCreateModalController',
                controllerAs: 'vm',
                resolve: {
                    operativeUserCollection: function () {
                        //if we use post_operative we need the create-operative link from the correct operativeCollection
                        return apiClient.get(userResponse.getLink('operatives'), {limit:1}).then(function (response) {
                            return response;
                        });
                    },
                    operativeCollectionResponse: operativeCollectionResponse,
                    serviceProviderCollectionResponse: serviceProviderCollectionResponse
                }
            }).result.then(function () {
                $state.go('.', {}, { reload: $state.current });
            });
        }

        function getServiceProviders(operative, $index, init) {

            $stateParams.changed = null;
            params.changed = null;

            if (operative.closed) {
                if (!init) {
                    params.position = $stateParams.position = $index;
                    params.serviceProviderPage = $stateParams.serviceProviderPage = '1';
                    // do this so above $stateParams are updated before list fetch
                    $state.go('.', params, {notify: false});
                }
            }

            function fetchServiceProviders() {
                if (userResponse.getLink('serviceproviders')) {
                    vm.loading = true;

                    params.operativeId = operative._links.user.id;
                    params.operativePage = $stateParams.operativePage;
                    params.position = $stateParams.position;

                    if ($stateParams.serviceProvider) {
                        params.id = JSON.parse($stateParams.serviceProvider).v;
                    }
                    // remove operativeName when filtering serviceprovider
                    delete params.name;
                    delete params.page;

                    apiClient.get(userResponse.getLink('serviceproviders'), params).then(function (response) {
                        vm.serviceProviders[$index] = response;
                        vm.loading = false;
                    });
                }
            }

            // if refresh page and window open get operatives
            if ($stateParams.position === $index && init) {
                fetchServiceProviders();
            }

            if (!init) {
                // opening window
                if (operative.closed) {
                    $stateParams.position = $index;
                    fetchServiceProviders();
                    // close all, then open this one
                    vm.operatives = operativeCollectionResponse.operatives.map(function (value) {
                        value.closed = true;
                        return value;
                    });
                    operative.closed = false;
                } else {
                    operative.closed = true;
                    $stateParams.position = null;
                }
            }

            params.position = $stateParams.position;
        }

        if ($stateParams.position > 0 || $stateParams.position === '0') {
            if (vm.operatives.length !== 0) {
                getServiceProviders(vm.operatives[$stateParams.position], $stateParams.position, true);
            }
        }

        function search(params) {
            var override = {
                operativePage: 1,
                position: null
            };

            $state.go('.', angular.extend({}, params, override), {reload: $state.current});
        }

        function order(key) {
            $stateParams.order = key;

            var override = {
                page: 1
            };

            $state.go('.', angular.extend({}, $stateParams, override), {reload: $state.current});
        }
    }
})();
