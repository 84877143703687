angular.module('elogbooksDirectives').directive('quickFilter', [function() {
    return {
        restrict: 'AE',
        require: ['ngModel'],
        scope: {
            quickFilter: '=ngModel',
            ctrl: '=ctrl'
        },
        templateUrl: '/modules/directives/quick-filter/quick-filter.html',
        controller: function ($scope, lodash) {

            $scope.select = function (event, filter) {
                $scope.ctrl.value = filter.values;
                event.target.blur();
            }
            
            $scope.$watch(
                function(scope) { return scope.ctrl.value },
                function(newValue) {
                    $scope.quickFilter.selected = null;
                    lodash.each($scope.quickFilter.filters, function(filter) {
                        if (lodash.isEqual(normalise(filter.values), normalise(newValue))) {
                            $scope.quickFilter.selected = filter.title;
                        };
                    });
                }
            );

            function normalise(value) {
                if (value === undefined) {
                    return null;
                }
                if (Array.isArray(value) && value.length === 0) {
                    return null;
                }

                return value;
            }
        },
    };
}]);
