(function () {
    'use strict';

    angular
        .module('elogbooks.user.reports')
        .controller('UserReportsJobSummaryModalController', UserReportsJobSummaryModalController);

    UserReportsJobSummaryModalController.$inject = ['titlePrefix', 'columnName', 'jobsCollection'];

    function UserReportsJobSummaryModalController(titlePrefix, columnName, jobsCollection) {
        var vm = this;
        vm.titlePrefix = titlePrefix;
        vm.columnName = columnName;
        vm.jobsCollection = jobsCollection;
        vm.jobs = jobsCollection.jobs;
        vm.columnIn = columnIn;

        function columnIn(array) {
            return array.indexOf(vm.columnName) !== -1;
        }
    }
})();