(function() {
    'use strict';

    angular
        .module('elogbooksServices')
        .service('fileDocumentTypeService', FileDocumentTypeService);

    FileDocumentTypeService.$inject = ['calendarRuleService', 'lodash'];

    function FileDocumentTypeService (calendarRuleService, lodash)
    {
        var service = this;

        service.calculateExpiresAt = calculateExpiresAt;
        service.checkForDuplicates = checkForDuplicates;

        return service;

        function calculateExpiresAt(documentType, file) {
            file.disableExpiresAt  = false;

            if (documentType &&
                documentType.selected &&
                documentType.selected.object &&
                documentType.selected.object.frequency &&
                file.documentDate
            ) {
                file.expiresAt = calendarRuleService.calculateExpiryDate(
                    documentType.selected.object.frequency,
                    documentType.selected.object.interval,
                    file.documentDate
                );

                file.disableExpiresAt  = true;

            }
        }

        function  checkForDuplicates(selectedItems, types) {
            var duplicatedTypes = [];

            lodash.forEach(types, function(response){
                var duplicate = lodash.find(
                    selectedItems,
                    {id: response.id}
                );

                if (duplicate) {
                    duplicatedTypes.push(duplicate);
                }
            });

            return duplicatedTypes;
        }
    }
})();