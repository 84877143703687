(function () {
    'use strict';

    angular
        .module('elogbooks.user.reports')
        .controller('UserReportsServiceProviderFeedbackController', UserReportsServiceProviderFeedbackController);

    UserReportsServiceProviderFeedbackController.$inject = [
        '$state',
        '$stateParams',
        'serviceProviderFeedbackScoreResponse',
        'serviceProviderGroupsResponse',
        'defaultValueResponse',
        '$filter'
    ];

    function UserReportsServiceProviderFeedbackController(
        $state,
        $stateParams,
        serviceProviderFeedbackScoreResponse,
        serviceProviderGroupsResponse,
        defaultValueResponse,
        $filter
    ) {
        var vm = this;

        vm.currentRangeOption = $stateParams.scoringRangeOption !== null ? $stateParams.scoringRangeOption : defaultValueResponse.defaultRangeOption;
        vm.scoringDateRange = $stateParams.scoringDateRange !== null ? $stateParams.scoringDateRange : defaultValueResponse.clearDateRangeValue;
        vm.order = orderAction;
        vm.search = search;
        vm.serviceProviderFeedbackScoreResponse = serviceProviderFeedbackScoreResponse;
        vm.serviceProviderFeedbackScores = serviceProviderFeedbackScoreResponse.serviceProviderFeedbacks;

        vm.exportParams = {};

        var statusOptions = [
            {title: $filter('translate')('NONE_SELECTED'), value: 'all'},
            {title: $filter('translate')('STATUS_ACTIVE'), value: 'active'},
            {title: $filter('translate')('STATUS_INACTIVE'), value: 'inactive'},
            {title: $filter('translate')('STATUS_TRAINING'), value: 'training'}
        ];

        var ratingStatusOptions = [
            {title: $filter('translate')('ALL'), value: 'all'},
            {title: $filter('translate')('HAS_RATINGS'), value: 'has_ratings'},
            {title: $filter('translate')('NO_RATINGS'), value: 'no_ratings'}
        ];

        var dateRangeOptions = [
            {title: $filter('translate')('LAST_ONE_MONTH'), value: 1},
            {title: $filter('translate')('LAST_THREE_MONTHS'), value: 3},
            {title: $filter('translate')('LAST_TWELVE_MONTHS'), value: 12},
            {title: $filter('translate')('CUSTOM'), value: 0}
        ];

        var selectedServiceProviderGroups = ($stateParams.serviceProviderGroups && typeof $stateParams.serviceProviderGroups[0] === 'object') ? {
            title: JSON.parse($stateParams.serviceProviderGroups).n,
            value: JSON.parse($stateParams.serviceProviderGroups).v
        } : null;

        vm.criteria = {
            name: {
                type: 'text',
                title: 'NAME',
                value: $stateParams.name
            },
            status: {
                type: 'options',
                title: 'STATUS',
                value: $stateParams.status,
                options: statusOptions,
                isClear: $stateParams.status === 'active',
                clearValue: 'active'
            },
            serviceProviderGroups: {
                type: 'jsonselectwidget',
                title: 'SERVICE_PROVIDER_GROUP',
                value: $stateParams.serviceProviderGroups ? JSON.parse($stateParams.serviceProviderGroups) : null,
                options: {
                    mapValue: 'serviceProviderGroups',
                    response: serviceProviderGroupsResponse,
                    responseKeyPath: 'groups',
                    itemValuePath: 'id',
                    itemTitlePath: 'name',
                    selected: selectedServiceProviderGroups
                }
            },
            scoringDateRange: {
                type: 'date-time-range',
                title: 'BETWEEN_DATES',
                startDateLabel: 'FEEDBACK_AT_RANGE_START_DATE_LABEL',
                endDateLabel: 'FEEDBACK_AT_RANGE_END_DATE_LABEL',
                value: $stateParams.scoringDateRange ? $stateParams.scoringDateRange : defaultValueResponse.clearDateRangeValue,
                startDateRequired: true,
                endDateRequired: true,
                enableTime: false,
                options: dateRangeOptions,
                defaultOption: defaultValueResponse.defaultRangeOption,
                optionParam: 'scoringRangeOption',
                clearValue: defaultValueResponse.clearDateRangeValue,
                isClear: $stateParams.scoringDateRange === defaultValueResponse.clearDateRangeValue || $stateParams.scoringDateRange === null,
            },
            ratingStatus: {
                type: 'options',
                title: 'RATING_STATUS',
                value: $stateParams.ratingStatus,
                options: ratingStatusOptions,
                isClear: $stateParams.ratingStatus === 'has_ratings',
                clearValue: 'has_ratings'
            }
        };

        function orderAction (key) {
            $stateParams.listOrder = key;
            $stateParams.spFeedbackReportPage = 1;

            const override = {
                page: 1
            };

            $state.go('.', angular.extend({}, $stateParams, override), { reload: $state.current });
        }

        function search(params) {

            const overrideParams = {
                page: 1,
                spFeedbackReportPage: 1,
            };

            $state.go('.', angular.extend({}, params, overrideParams), {reload: $state.current});
        }
    }
})();