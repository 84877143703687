(function () {
    'use strict';

    angular
        .module('elogbooks.admin.users')
        .controller('UserTokensController', UserTokensController);

    UserTokensController.$inject = ['tokensCollectionResponse', '$state', '$stateParams', '$translate', 'confirmationModal', 'apiClient', 'messenger'];

    function UserTokensController(tokensCollectionResponse, $state, $stateParams, $translate, confirmationModal, apiClient, messenger) {
        var vm = this;
        vm.tokens = tokensCollectionResponse.userTokens;
        vm.tokensCollectionResponse = tokensCollectionResponse;

        var yesNoOptions = [
            {
                title: $translate.instant('NONE_SELECTED'),
                value: null
            },
            {
                title: $translate.instant('YES'),
                value: 'true'
            },
            {
                title: $translate.instant('NO'),
                value: 'false'
            }
        ];

        vm.search = searchAction;
        vm.revoke = revoke;
        vm.criteria = {
            tokensValid: { type: 'options', title: 'VALID', value: $stateParams.tokensValid, options: yesNoOptions},
        };

        function searchAction (params) {
            var override = {
                tokensPage: 1
            };

            $state.go('.', angular.extend({}, params, override), { reload: $state.current });
        }

        function revoke (token) {
            return confirmationModal.open().result.then(function (response) {
                if (response.result) {
                    return apiClient.update(token.getLink('revoke'), {token: token.token}).then(function (response) {
                        if (response) {
                            $state.go('.', {}, { reload: true }).then(function () {
                                messenger.success('TOKEN_REVOKED');
                            });
                        }
                    });
                }

                return;
            });
        }
    }
})();
