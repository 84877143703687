app.config(function ($translateProvider) {
    $translateProvider.translations('zh-hk', {
        // JOB_MESSAGE_ACTION: '<strong>{{ ::message.sender }}</strong> added a message.',
        JOB_MESSAGE_ACTION: '<strong>{{ ::message._links["sender"].title }}</strong> 已加入訊息.',
        JOB_ACTIONED_ACTION: '工作的截止日期被提前.',
        JOB_CREATED_ACTION: '<strong>{{ ::event._links["user"].title }}</strong> 已建立工作.',
        JOB_ALARM_UPDATED_ACTION: '<strong>{{ ::event._links["user"].title }}</strong> 已更新工作.',
        JOB_APPROVAL_REQUESTED_ACTION: '<strong>{{ ::event._links["user"].title }}</strong> 已發送審批要求.',
        JOB_APPROVAL_ACCEPTED_ACTION: '<strong>{{ ::event._links["user"].title }}</strong> 已審批工作.',
        JOB_APPROVAL_REJECTED_ACTION: '<strong>{{ ::event._links["user"].title }}</strong> 已拒絕工作審批.',
        JOB_APPROVAL_RESENT_ACTION: '<strong>{{ ::event._links["user"].title }}</strong> 重發送審批要求給 {{ ::event._links["approver"].title }}.',
        JOB_ASSIGNMENT_REQUESTED_ACTION: '<strong>{{ ::event._links["user"].title }}</strong> 發送職務要求給 {{ ::event._links["operative"].title }}.',
        JOB_ASSIGNMENT_REASSIGNED_ACTION: '<strong>{{ ::event._links["user"].title }}</strong> 重分配工作.',
        JOB_ASSIGNMENT_ACCEPTED_ACTION: '<strong>{{ ::event._links["user"].title }}</strong> 接受工作.',
        JOB_ASSIGNMENT_REJECTED_ACTION: '<strong>{{ ::event._links["user"].title }}</strong> 拒絕工作.',
        JOB_ASSIGNMENT_RESENT_ACTION: '<strong>{{ ::event._links["user"].title }}</strong> 重發送職務要求給 {{ ::event._links["operative"].title }}.',
        JOB_ASSIGNMENT_INSISTED_ACTION: '<strong>{{ ::event._links["user"].title }}</strong> 確定工作已接納.',
        JOB_PROPOSAL_REQUESTED_ACTION: '<strong>{{ ::event._links["user"].title }}</strong> 已發送議案.',
        JOB_PROPOSAL_ACCEPTED_ACTION: '<strong>{{ ::event._links["user"].title }}</strong> 已接受議案.',
        JOB_PROPOSAL_REJECTED_ACTION: '<strong>{{ ::event._links["user"].title }}</strong> 已拒絕議案.',
        JOB_EXTENSION_REQUESTED_ACTION: '<strong>{{ ::event._links["user"].title }}</strong> 已發送延伸要求.',
        JOB_EXTENSION_ACCEPTED_ACTION: '<strong>{{ ::event._links["user"].title }}</strong> 已接受延伸要求.',
        JOB_EXTENSION_REJECTED_ACTION: '<strong>{{ ::event._links["user"].title }}</strong> 已拒絕延伸要求.',
        JOB_OPERATION_ACKNOWLEDGED_ACTION: '<strong>{{ ::event._links["user"].title }}</strong> 確認工作.',
        JOB_OPERATION_COMMENCED_ACTION: '<strong>{{ ::event._links["user"].title }}</strong> 已開始工作.',
        JOB_OPERATION_COMPLETED_ACTION: '<strong>{{ ::event._links["user"].title }}</strong> 已完成工作.',
        JOB_POSTPONE_REMINDER_AT_ACTION: '<strong>{{ ::event._links["user"].title }}</strong> 將工作日期改為 {{ ::event.reminderAt | date:\'short\' }}.',
        JOB_OPERATION_MISSED_ACTION: '<strong>{{ ::event._links["user"].title }}</strong> 錯過了工作.',
        JOB_OPERATION_CHASED_ACTION: '工作 #{{ ::event.chaseSequence + 1 }} 通知已發送到 <strong>{{ ::event._links["user"].title }}</strong>.',
        JOB_OPERATION_REOPENED_ACTION: '<strong>{{ ::event._links["user"].title }}</strong> 再開啟工作.',
        JOB_OPERATION_CANCELLED_ACTION: '<strong>{{ ::event._links["user"].title }}</strong> 已取消工作.',
        JOB_APPROVAL_ESCALATED_ACTION: '<strong>{{ ::event._links["user"].title }}</strong> 上報工作審批.',
        JOB_AUDIT_TRAIL_SENT_ACTION: '<strong>{{ ::event._links["user"].title }}</strong> 已發送審計追踪.',
        JOB_EDITED_ACTION: '<strong>{{ ::event._links["user"].title }}</strong> 已修改工作.'
    });
});
