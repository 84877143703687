(function() {
    'use strict';

    angular
        .module('elogbooks')
        .config(Translations);

    function Translations($translateProvider) {
        $translateProvider.translations('zh-hk', {
            ACTION: '指令',
            ACTIONS: '指令',
            ACTION_LIST: '指令',

            ACTION_ADD: '新增指令',
            ACTION_EDIT: '修改指令',

            ACTION_CREATED: '指令已建立',
            ACTION_UPDATED: '指令已更新',
            ACTION_DELETED: '指令已删除',

            ACTIONS_OPEN: '指令',

            ACTION_STATUS_OPEN: '開啟',
            ACTION_STATUS_COMPLETED: '完成',
            ACTION_STATUS_CANCELLED: '取消',

            ACTION_ADD_SITE: '地點',
            ACTION_ADD_CONTACT_DETAILS: '聯絡方式',
            ACTION_ADD_DETAILS: '指令細節',

            ACTION_ID: '指令 {{ id }}',
            ACTION_DETAILS: '指令細節',
            ACTION_STATUS: '狀態',

            ACTION_CHANGE_ASSIGNEE_TO_ME: '更改自己為負責人',

            DATE_DUE_START: '開始日期',
            DATE_DUE_END: '完成日期'
        });
    }
})();
