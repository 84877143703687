(function () {
    'use strict';

    angular
        .module('elogbooks.admin.site-note-types')
        .controller('SiteNoteTypeDetailsController', SiteNoteTypeDetailsController);

    SiteNoteTypeDetailsController.$inject = ['$state', 'siteNoteTypeResponse', 'apiClient', 'messenger'];

    function SiteNoteTypeDetailsController ($state, siteNoteTypeResponse, apiClient, messenger) {
        var vm = this;
            vm.siteNoteType = siteNoteTypeResponse;
    }
})();
