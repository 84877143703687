angular.module('elogbooksDirectives').directive('messages', ['messenger', function (messenger) {
    return {
        templateUrl: '/modules/common/messenger/messenger.html',
        controller: function($scope){
            $scope.messages = messenger.getMessages();
            $scope.clear = function (message) {
                messenger.clear(message);
            }
        }
    }
}]);
