(function (){
    'use strict';

    angular
        .module('elogbooks.user.buddies')
        .controller('UserBuddiesAddEditController', [
            '$scope',
            '$state',
            '$stateParams',
            'lodash',
            'apiClient',
            'messenger',
            'confirmationModal',
            'user',
            'buddiesCollectionResource',
            'buddyResponse',
            'userCollectionResponse',
            'siteCollectionResponse',
            'buddySiteCollectionResponse',
            'config',
            UserBuddiesAddEditController
        ]);

    function UserBuddiesAddEditController(
        $scope,
        $state,
        $stateParams,
        lodash,
        apiClient,
        messenger,
        confirmationModal,
        user,
        buddiesCollectionResource,
        buddyResponse,
        userCollectionResponse,
        siteCollectionResponse,
        buddySiteCollectionResponse,
        config
    ) {
        var vm = this;
        vm.buddy = buddyResponse;
        vm.site = null;
        vm.duplicatedSites = [];
        vm.siteCollectionResponse = siteCollectionResponse;
        vm.buddySiteCollectionResponse = buddySiteCollectionResponse;
        vm.config = config;
        vm.criteria = {
            name: { type: 'text', title: 'NAME', value: $stateParams.name },
            reference: { type: 'text', title: 'REFERENCE', value: $stateParams.reference }
        };

        vm.create = confirmCreate;
        vm.update = confirmUpdate;
        vm.delete = deleteAction;
        vm.deleteSite = deleteSiteAction;
        vm.isBuddyActive = isBuddyActive;

        vm.userSelect = {
            response : userCollectionResponse,
            link : userCollectionResponse ? userCollectionResponse.getLink('self') : '',
            required : true,
            responseKeyPath: 'users',
            itemHrefPath: '_links.self.href',
            itemValuePath: 'name'
        };

        if (siteCollectionResponse) {
            vm.siteSelect = {
                response : siteCollectionResponse,
                link : siteCollectionResponse.getLink('self'),
                responseKeyPath: 'sites',
                required: true
            };
        } else {
            vm.siteSelect = false;
        }

        // Fill form data on edit action
        if (typeof buddyResponse._links.self !== 'undefined') {
            if (typeof buddyResponse._links.buddy !== 'undefined') {
                vm.userSelect.selected = buddyResponse._links.buddy;
            }

            vm.buddy.start = new Date(vm.buddy.recurrence.start);
            vm.buddy.end = new Date(vm.buddy.recurrence.end);
        }

        var currentDateTime = new Date();
        vm.dateTimePickerOptionsStartDate = {
            minDate: vm.buddy.start ? vm.buddy.recurrence.start : currentDateTime,
            maxDate: vm.buddy.end ? vm.buddy.recurrence.end : currentDateTime,
            showMeridian: false
        };
        vm.dateTimePickerOptionsEndDate = {
            minDate: vm.buddy.start ? vm.buddy.recurrence.start : currentDateTime,
            maxDate: vm.buddy.end ? vm.buddy.recurrence.end : currentDateTime,
            showMeridian: false
        }

        $scope.$watch('vm.siteSelect.selectedItems', function() {
            checkSites();
        });
        $scope.$watch('vm.buddy.start', function () {
            checkDates();
            checkSites();
            checkUpdateSites();
        });
        $scope.$watch('vm.buddy.end', function () {
            checkDates();
            checkSites();
            checkUpdateSites();
        });
        $scope.$watch('vm.buddy.selectAllSites', function () {
            if (vm.buddy.selectAllSites) {
                vm.siteSelect.selectedItems = [];
                checkSites();
            } else {
                vm.duplicatedSitesCount = false;
            }
        });

        function confirmCreate() {
            if (vm.duplicatedSites.length > 0 ) {
                return messenger.error('BUDDY_ALREADY_IN_USE_SITES');
            } else  {
                createAction();
            }
        }

        function confirmUpdate() {
            if (vm.duplicatedUpdateSites.length > 0 ) {
                return messenger.error('BUDDY_CONFLICTS');
            } else  {
                updateAction();
            }
        }

        function deleteSiteAction(site) {
            if (vm.buddySiteCollectionResponse.sites.length === 1) {
                return messenger.error('SITES_EMPTY');
            }
            return confirmationModal.open().result.then(function () {
                apiClient.delete(vm.buddy.getLink('sites'), {'ids' : [site.id]}).then(function (response) {
                    if (response) {
                        $state.go('.', $stateParams, { reload: $state.$current.parent.self.parent }).then(function () {
                            messenger.success('BUDDY_SITE_RELATION_REMOVED');
                        });
                    } else {
                        messenger.error('REQUEST_ERROR');
                    }
                });
            });
        }

        function deleteAction() {
            return confirmationModal.open().result.then(function () {
                if (isBuddyActive()) {
                    return apiClient.update(vm.buddy.getLink('deactivate'), {}).then(function (response) {
                        if (response) {
                            $state.go('^', {}, { reload: $state.$current.parent.parent.parent.self.name }).then(function () {
                                messenger.success('BUDDY_DEACTIVATED');
                            });
                        } else {
                            messenger.error('REQUEST_ERROR');
                        }
                    });
                }
                return apiClient.delete(vm.buddy.getLink('delete')).then(function (response) {
                    if (response) {
                        $state.go('^.^', $stateParams, { reload: $state.$current.parent.parent.parent.self.name }).then(function () {
                            messenger.success('BUDDY_REMOVED');
                        });
                    } else {
                        messenger.error('REQUEST_ERROR');
                    }
                });
            });
        }

        function isBuddyActive() {
            return vm.buddy.status === 1;
        }

        /**
         * Create buddy
         */
        function createAction() {
            if (vm.siteSelect.selectedItems.length === 0 && !vm.buddy.selectAllSites) {
                return;
            }

            if (vm.userSelect.selected) {
                vm.buddy._links.user = {href : vm.userSelect.selected.href}
            }

            if (vm.siteSelect.selectedItems.length) {
                vm.buddy._links.site = vm.siteSelect.selectedItems
            }

            apiClient.create(user.getLink('buddies'), vm.buddy).then(function (response) {
                if (response) {
                    $state.go('^', $stateParams, { reload: $state.$current.parent.self.parent }).then(function () {
                        messenger.success('BUDDY_ADD_SUCCESS');
                    });
                } else {
                    messenger.error('REQUEST_ERROR');
                }
            });
        }

        /**
         * Update buddy
         */
        function updateAction() {
            var buddyRequest = angular.copy(vm.buddy);

            buddyRequest._links = {};

            if (vm.siteSelect.response.sites.length) {
                vm.buddy._links.site = vm.siteSelect.response.sites
            }

            if (vm.userSelect.selected) {
                buddyRequest._links.user = {href : vm.userSelect.selected.href}
            }

            delete buddyRequest.recurrence;
            buddyRequest.start = vm.buddy.start;
            buddyRequest.end = vm.buddy.end;

            apiClient.update(vm.buddy.getLink('self'), buddyRequest).then(function (response) {
                if (response) {
                    $state.go('^', {}, {reload: $state.$current.parent.parent.parent.self.name}).then(function () {
                        messenger.success('BUDDY_UPDATED');
                    });
                } else {
                    messenger.error('REQUEST_ERROR');
                }
            });
        }

        function checkSites(){
            if (((vm.siteSelect.selectedItems && vm.siteSelect.selectedItems.length > 0)
                    || vm.buddy.selectAllSites)
                && vm.buddy.start
                && vm.buddy.end
            ) {
                vm.siteSelect.selectedItems.map(function(value){
                    value.id = value.object.id;
                    return value;
                });

                apiClient.get(buddiesCollectionResource.getLink('buddychecksites'), {
                    ids : !vm.buddy.selectAllSites ? lodash.map(vm.siteSelect.selectedItems, 'id').join() : null,
                    selectAllSites : vm.buddy.selectAllSites,
                    start : vm.buddy.start,
                    end : vm.buddy.end
                }).then(function (response) {
                    if (response) {
                        if (vm.buddy.selectAllSites) {
                            vm.duplicatedSites = [];
                            vm.duplicatedSitesCount = response.sites.length;
                        } else {
                            vm.duplicatedSites = [];
                            vm.duplicatedSitesCount = false;
                            for (var i=0; i < response.sites.length; i++) {
                                var site = lodash.find(vm.siteSelect.selectedItems, {id:parseInt(response.sites[i])});

                                if (site) {
                                    vm.duplicatedSites.push(site);
                                }
                            }
                        }
                    } else {
                        messenger.error('REQUEST_ERROR');
                    }
                });
            } else {
                vm.duplicatedSites = [];
            }
        }

        function checkUpdateSites() {
            if (vm.buddy.start && vm.buddy.end && vm.config.type) {
                apiClient.get(buddiesCollectionResource.getLink('buddychecksites'), {
                    ids : lodash.map(vm.siteCollectionResponse.sites, 'id').join(),
                    selectAllSites : false,
                    start : vm.buddy.start,
                    end : vm.buddy.end,
                    id: vm.buddy.id
                }).then(function (response) {
                    if (response) {
                        vm.duplicatedUpdateSites = [];
                        for (var i=0; i < response.sites.length; i++) {
                            var site = lodash.find(vm.siteCollectionResponse.sites, {id:parseInt(response.sites[i])});

                            if (site) {
                                vm.duplicatedUpdateSites.push(site);
                            }
                        }
                    } else {
                        messenger.error('REQUEST_ERROR');
                    }
                });
            }
        }

        function checkDates() {
            currentDateTime = new Date();

            if (vm.buddy.start) {
                vm.dateTimePickerOptionsEndDate = { minDate: vm.buddy.start, maxDate: null,  showMeridian: false }
            } else {
                vm.dateTimePickerOptionsEndDate = { minDate: currentDateTime, maxDate: null,  showMeridian: false };
            }

            if (vm.buddy.end) {
                vm.dateTimePickerOptionsStartDate = { minDate: currentDateTime, maxDate: vm.buddy.end, showMeridian: false };
            } else {
                vm.dateTimePickerOptionsStartDate = { minDate: currentDateTime, maxDate: null, showMeridian: false };
            }
        }
    }
})();
