(function() {
    'use strict';

    angular
        .module('elogbooks.common.audit')
        .controller('AuditAnswerTemplateAddEditController', AuditAnswerTemplateAddEditController);

    AuditAnswerTemplateAddEditController.$inject = [
        '$filter',
        'apiClient',
        'messenger',
        '$state',
        'confirmationModal',
        'answerTemplateCollectionResponse',
        'answerTemplateResponse'
    ];

    function AuditAnswerTemplateAddEditController(
        $filter,
        apiClient,
        messenger,
        $state,
        confirmationModal,
        answerTemplateCollectionResponse,
        answerTemplateResponse
    ) {
        var vm = this;

        vm.answerTemplateCollection = answerTemplateCollectionResponse;
        vm.answerTemplate = answerTemplateResponse;
        vm.data = {};

        vm.create = createAction;
        vm.update = updateAction;
        vm.delete = deleteAction;

        if (vm.answerTemplate) {
            vm.data = {
                id: vm.answerTemplate.id,
                name: vm.answerTemplate.name || null,
                type: vm.answerTemplate.type || null,
                visibility: vm.answerTemplate.visibility || null,
                status: vm.answerTemplate.status
            }
        }

        vm.visibilities = [
            {value: 'public', title: $filter('translate')('VISIBILITY_PUBLIC')},
            {value: 'private', title: $filter('translate')('VISIBILITY_PRIVATE')}
        ];
        vm.types = [
            {value: 'options', title: $filter('translate')('AUDIT_QUESTION_OPTIONS')},
            {value: 'buttons', title: $filter('translate')('AUDIT_QUESTION_BUTTONS')}
        ];
        vm.statuses = [
            {value: 'active', title: $filter('translate')('ACTIVE')},
            {value: 'inactive', title: $filter('translate')('INACTIVE')}
        ];

        function createAction() {
            return apiClient.create(vm.answerTemplateCollection.getLink('create'), vm.data).then(function(response) {
                if (response) {
                    var params = {};
                    params.answerTemplateResource = response.getLink('self').encode();

                    $state.go('dashboard.user.audits.answer-options-list.details.info', params, { reload: $state.current.parent }).then(function () {
                        messenger.success('ANSWER_OPTION_ADDED');
                    });
                } else {
                    messenger.error('REQUEST_ERROR');
                }
            });
        }

        function updateAction() {
            return apiClient.update(vm.answerTemplate.getLink('edit'), vm.data).then(function(response) {
                if (response) {
                    $state.go('^', {}, { reload: $state.$current.parent.parent.parent.self }).then(function () {
                        messenger.success('ANSWER_OPTION_UPDATED');
                    });
                } else {
                    messenger.error('REQUEST_ERROR');
                }
            });
        }

        function deleteAction() {
            return confirmationModal.open().result.then(function () {
                return apiClient.delete(vm.answerTemplate.getLink('delete')).then(function (response) {
                    if (response) {
                        $state.go('^.^.^', {}, { reload: $state.get('^.^.^') }).then(function () {
                            messenger.success('ANSWER_OPTION_DELETED');
                        });
                    } else {
                        messenger.error('REQUEST_ERROR');
                    }
                });
            });
        }
    }
})();
