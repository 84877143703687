(function(){
    'use strict';

    angular
        .module('elogbooks.helpdesk.sites')
        .controller('HelpdeskSiteListController', HelpdeskSiteListController);

    function HelpdeskSiteListController($scope, siteCollectionResponse) {

        $scope.sites = siteCollectionResponse.getData().sites;

        $scope.pages = siteCollectionResponse.getData().pages;

    }
})();
