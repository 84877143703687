app.config(function ($translateProvider) {
    $translateProvider.translations('zh-hk', {
        IMPORT: '匯入',
        IMPORT_TYPE: '類別',
        IMPORTS_LIST: '匯入',
        IMPORT_DETAILS_LIST: '詳細資料',
        IMPORT_IS_TEST_RUN: '測試運行?',
        IMPORT_STARTED: '開始於',
        IMPORT_ENDED: '結束於',
        IMPORT_TOTAL_ROWS: '總',
        IMPORT_VALID_ROWS: '有效',
        IMPORT_INVALID_ROWS: '無效',
        IMPORT_TEST_RUN: '調試',
        IMPORTS: '匯入',
        IMPORT_CSV_FILE: '匯入檔案(csv)',
        IMPORT_ERRORS: '錯誤',
        IMPORT_ROW_NUMBER: '行',
        IMPORT_COLUMN_NAME: '關鍵',
        IMPORT_ERROR_MESSAGE: '錯誤訊息',
        IMPORT_GET_TEMPLATE: '獲取樣板',
        IMPORTS_NO_RESULTS: '沒有結果',

        IMPORT_USERS: '匯入用戶',
        IMPORT_USERS_ADD: '匯入用戶',
        IMPORT_ADD: '新增匯入',

        DOWNLOAD_IMPORT_TEMPLATE: '下載 {{ entity }} 匯入樣板',
        IMPORT_DONE: '匯入成功完成',
        IMPORT_DONE_WITH_ERRORS: '匯入有些錯誤',
        IMPORT_ERROR: '匯入錯誤'
    });
});
