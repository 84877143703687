(function () {
    'use strict';

    angular
        .module('elogbooksControllers')
        .controller('UserModalController', UserModalController);

    UserModalController.$inject = ['userData', '$uibModalInstance'];

    function UserModalController (userData, $uibModalInstance) {
        var vm = this;
        vm.user = userData;
        vm.title = vm.user.type + '_DETAILS';

        vm.close = close;

        function close() {
            $uibModalInstance.dismiss();
        }

    };
})();
