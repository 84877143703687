(function () {
    'use strict';

    angular
        .module('elogbooks')
        .controller('ReporterFeedbackController', ReporterFeedbackController);

    ReporterFeedbackController.$inject = ['messenger', '$state', 'apiClient', '$translate', 'reporterFeedbackResponse'];

    function ReporterFeedbackController(messenger, $state, apiClient, $translate, reporterFeedbackResponse) {
        var vm = this;

        vm.reporterFeedback = reporterFeedbackResponse;
        vm.reporterFeedback.canContactBack = null;

        vm.yesNo = [
            {
                value: null,
                label: $translate.instant('PLEASE_SELECT')
            },
            {
                value: false,
                label: $translate.instant('NO')
            },
            {
                value: true,
                label: $translate.instant('YES')
            }
        ];

        vm.scoreOptions = [
            {
                value: '0',
                label: $translate.instant('NEGATIVE')
            },
            {
                value: '1',
                label: $translate.instant('POSITIVE')
            }
        ];

        vm.submit = submitAction;

        function submitAction() {
            apiClient.create('/jobreporterfeedback', vm.reporterFeedback).then(function (response) {
                if (response) {
                    $state.go('login', {}, {reload: true}).then (function () {
                        messenger.success('FEEDBACK_SUBMITTED');
                    });
                }
            });
        }
    }
})();
