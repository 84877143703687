(function () {
    'use strict';

    angular
        .module('elogbooks.admin.priorities')
        .config(registerRoutes);

    function registerRoutes ($stateProvider) {
        $stateProvider
            .state('dashboard.admin.priorities', {
                abstract: true,
                templateUrl: 'shared-templates/view.html',
                ncyBreadcrumb: {
                    skip: true
                },
                data: {
                    permissions: ['user_permission_view_priorities']
                }
            })
            .state('dashboard.admin.priorities.list', {
                url: '/priorities?priorityPage&priorityLimit&priorityOrder&priorityName&priorityStatus',
                parent: 'dashboard.admin.priorities',
                data: {
                    permissions: ['user_permission_view_priorities']
                },
                views: {
                    '@dashboard.admin.priorities': {
                        templateUrl: '/modules/common/priorities/list/priorities-list.html',
                        controller: 'PrioritiesListController',
                        controllerAs: 'vm'
                    }
                },
                resolve: {
                    prioritiesCollection: function (apiClient, rootResourceResponse, $stateParams) {
                        return apiClient.get(rootResourceResponse.getLink('priorities'), angular.extend({}, $stateParams, {
                            page: $stateParams.priorityPage,
                            limit: $stateParams.priorityLimit,
                            order: $stateParams.priorityOrder,
                            name: $stateParams.priorityName,
                            active: $stateParams.priorityStatus
                        }));
                    },
                    priorityCreateType: function() {
                        return 'create-global-priority';
                    }
                },
                params: {
                    priorityPage: '1',
                    priorityLimit: '30',
                    priorityOrder: 'name',
                    priorityName: null,
                    priorityStatus: null
                },
                ncyBreadcrumb: {
                    label: '{{ ::"PRIORITIES" | translate }}'
                }
            })
            .state('dashboard.admin.priorities.list.add', {
                url: '/add',
                parent: 'dashboard.admin.priorities.list',
                data: {
                    permissions: ['user_permission_manage_priorities']
                },
                views: {
                    '@dashboard.admin.priorities': {
                        templateUrl: '/modules/common/priorities/addedit/priorities-addedit.html',
                        controller: 'PrioritiesAddEditController',
                        controllerAs: 'vm',
                    }
                },
                resolve: {
                    priorityResponse: function() {
                        return {};
                    },
                    priorityCreateLink: function(prioritiesCollection) {
                        return prioritiesCollection.getLink('create-global-priority');
                    },
                    config: function(prioritiesCollection) {
                        return {
                            isIncludeDashboardLimitReached: prioritiesCollection.isIncludeInDashboardLimitReached,
                            isEdit: false,
                            noBorder: false
                        };
                    }
                },
                ncyBreadcrumb: {
                    label: '{{ ::"ADD" | translate }}'
                },
            })
            .state('dashboard.admin.priorities.list.details', {
                url: '/details/{priorityResource}',
                abstract: true,
                parent: 'dashboard.admin.priorities.list',
                data: {
                    permissions: ['user_permission_view_priorities']
                },
                views: {
                    '@dashboard.admin.priorities': {
                        templateUrl: '/modules/common/priorities/details/priorities-details.html',
                        controller: 'PrioritiesDetailsController',
                        controllerAs: 'vm'
                    }
                },
                resolve: {
                    priorityResponse: function (apiClient, $stateParams) {
                        return apiClient.get($stateParams.priorityResource.decode());
                    }
                },
                ncyBreadcrumb: {
                    label: '{{ ::$resolves().priorityResponse.name }}'
                }
            })
            .state('dashboard.admin.priorities.list.details.priorityinfo', {
                url: '/info',
                parent: 'dashboard.admin.priorities.list.details',
                data: {
                    permissions: ['user_permission_view_priorities']
                },
                views: {
                    '@dashboard.admin.priorities.list.details': {
                        templateUrl: '/modules/common/priorities/details/info/priorities-info.html',
                        controller: 'PrioritiesDetailsInfoController',
                        controllerAs: 'vm'
                    }
                },
                resolve: {
                    config: function () {
                        return {
                            noBorder: false
                        };
                    }
                },
                ncyBreadcrumb: {
                    label: '{{ ::"INFO" | translate }}'
                }
            })
            .state('dashboard.admin.priorities.list.details.priorityinfo.edit', {
                url: '/edit',
                parent: 'dashboard.admin.priorities.list.details.priorityinfo',
                data: {
                    permissions: ['user_permission_manage_priorities']
                },
                views: {
                    '@dashboard.admin.priorities.list.details': {
                        templateUrl: '/modules/common/priorities/addedit/priorities-addedit.html',
                        controller: 'PrioritiesAddEditController',
                        controllerAs: 'vm'
                    }
                },
                resolve: {
                    priorityCreateLink: function() {
                        return null;
                    },
                    config: function(prioritiesCollection) {
                        return {
                            isIncludeDashboardLimitReached: prioritiesCollection.isIncludeInDashboardLimitReached,
                            isEdit: true,
                            noBorder: false
                        };
                    }
                },
                ncyBreadcrumb: {
                    label: '{{ ::"EDIT" | translate }}'
                }
            });

    }

})();
