(function() {
    'use strict';

    angular
        .module('elogbooks.user.meter-manufacturers')
        .controller('MeterManufacturersController', MeterManufacturersController);

    MeterManufacturersController.$inject = ['$state', '$stateParams', 'meterManufacturersCollectionResponse'];
    function MeterManufacturersController($state, $stateParams, meterManufacturersCollectionResponse) {
        var vm = this;
        vm.meterManufacturers = meterManufacturersCollectionResponse.meterManufacturers;
        vm.meterManufacturersCollectionResponse = meterManufacturersCollectionResponse;
        vm.criteria = {
            id: { type: 'number', value: $stateParams.id ? parseInt($stateParams.id, 10) : null, title: 'ID', min: 1 },
            name: { type: 'text', title: 'NAME', value: $stateParams.name }
        };

        vm.order = orderAction;
        vm.search = searchAction;

        function searchAction(params) {
            var override = {
                page: 1
            };

            $state.go('.', angular.extend({}, params, override), { reload: $state.current });
        }

        function orderAction(key) {
            $stateParams.order = key;

            $state.go('.', angular.extend({}, $stateParams), { reload: $state.current });
        }
    }
})();
