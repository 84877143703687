(function () {
    'use strict';

    angular
        .module('elogbooksControllers')
        .controller('advancedFileFiltersModalController', advancedFileFiltersModalController);

    advancedFileFiltersModalController.$inject = ['$state', '$stateParams', 'config', '$uibModalInstance', '$rootScope', '$filter', '$scope', '$translate',
        'lodash', 'apiClient', 'elbVisibilityService', 'paramConverterService', 'userManager', 'rootResource'];

    function advancedFileFiltersModalController($state, $stateParams, config, $uibModalInstance, $rootScope, $filter, $scope, $translate,
            lodash, apiClient, elbVisibilityService, paramConverterService, userManager, rootResource) {
        var vm = this;
        vm.config = config;
        vm.close = close;
        vm.update = update;
        vm.saveParams = saveParams;

        var yesNoOptions = [
            {
                title: $translate.instant('NONE_SELECTED'),
                value: null
            },
            {
                title: $translate.instant('YES'),
                value: 'true'
            },
            {
                title: $translate.instant('NO'),
                value: 'false'
            }
        ];

        var activeInactiveOptions = [
            {
                title: $translate.instant('Active'),
                value: 'Active'
            },
            {
                title: $translate.instant('Inactive'),
                value: 'Inactive'
            }
        ];

        vm.data ={};
        vm.advancedCriteria = {};

        vm.advancedCriteria = {
            fileId: {
                type: 'number',
                value: $stateParams.fileId ? parseInt($stateParams.fileId, 10) : null,
                title: 'ID',
                min: 1
            },
            fileTitle: {
                type: 'text',
                value: $stateParams.fileTitle,
                title: 'TITLE'
            },
            fileUploadedBy: {
                type: 'text',
                value: $stateParams.fileUploadedBy,
                title: 'UPLOADED_BY'
            },
            fileUploadedDateStart: {
                type: 'date',
                value: $stateParams.fileUploadedDateStart,
                title: 'UPLOADED_DATE_START'
            },
            fileUploadedDateEnd: {
                type: 'date',
                value: $stateParams.fileUploadedDateEnd,
                title: 'UPLOADED_DATE_END'
            },
            fileExpiresDateStart: {
                type: 'date',
                value: $stateParams.fileExpiresDateStart,
                title: 'EXPIRES_DATE_START'
            },
            fileExpiresDateEnd: {
                type: 'date',
                value: $stateParams.fileExpiresDateEnd,
                title: 'EXPIRES_DATE_END'
            },
            fileVisibility: {
                type: 'options',
                title: 'VISIBILITY',
                value: $stateParams.fileVisibility,
                options: elbVisibilityService.getVisibilitiesAsObject()
            }
        };
        /* Commented out for now - Filter will not be usable until task EWV-3657 is done. Please uncomment when this is done.
        if (userManager.user.getLink('serviceproviders')) {
            vm.serviceProviderSelect = {
                link : userManager.user.getLink('serviceproviders'),
                responseKeyPath: 'serviceproviders',
                itemValuePath: 'name',
                itemHrefPath: 'id',
                onRemove: vm.saveParams,
                onSelect: vm.saveParams,
                mapValue: 'serviceProvider',
                autocompletemulti: true,
                advanced: true
            };

            vm.advancedCriteria.serviceProvider= {
                type: 'autocompletemulti',
                multiple: true,
                title: 'SERVICE_PROVIDER',
                value: $stateParams.serviceProvider,
                options: vm.serviceProviderSelect,
                param: 'serviceProvider',
                max: 50
            };
        }
        */

        if (rootResource.getLink('documenttypes')) {
            vm.documentTypeSelect= {
                link : rootResource.getLink('documenttypes'),
                linkParameters: { status: 'active', order: 'name', job: vm.job ? vm.job.id : null },
                responseKeyPath: 'documentTypes',
                onRemove: vm.saveParams,
                onSelect: vm.saveParams,
                mapValue: 'documentTypes',
                autocompletemulti: true,
                advanced: true,
                itemValuePath: 'name',
                itemHrefPath: 'id'
            };

            vm.advancedCriteria.documentTypes = {
                type: 'autocompletemulti',
                multiple: true,
                title: 'DOCUMENT_TYPE',
                options: vm.documentTypeSelect,
                param: 'documentTypes',
                value: $stateParams.documentTypes,
                max: 50
            };
        }

        if (userManager.user.getLink('sites') &&
            ($state.is('dashboard.user.files.list') || $state.is('dashboard.user.files.manage-files.site-files-list') ||
            $state.is('dashboard.user.files.manage-files.all-files-list') || vm.config.isEntityAttachments && vm.config.showSite != false)
        ){
            vm.siteSelect = {
                link : userManager.user.getLink('sites'),
                responseKeyPath: 'sites',
                itemValuePath: 'name',
                itemHrefPath: 'id',
                onRemove: vm.saveParams,
                onSelect: vm.saveParams,
                mapValue: 'siteIds',
                autocompletemulti: true,
                advanced: true,
                disabled: $stateParams.selectedSiteResource
            };

            vm.advancedCriteria.siteIds = {
                type: 'autocompletemulti',
                multiple: true,
                title: 'SITE',
                value: $stateParams.siteIds,
                options: vm.siteSelect,
                param: 'siteIds',
                max: 50
            };
        }

        if (config.showJobFiles && userManager.user.getLinkAttribute('files', 'canSeeJobFiles')) {
            vm.advancedCriteria.fileIsJobFile = {
                type: 'options',
                title: 'JOB_FILE',
                value: $stateParams.fileIsJobFile,
                options: yesNoOptions
            };
        }

        if (rootResource.getLink('tags')) {
            vm.tagSelect = {
                link : rootResource.getLink('tags'),
                responseKeyPath: 'tags',
                itemValuePath: 'name',
                itemHrefPath: 'id',
                onRemove: vm.saveParams,
                onSelect: vm.saveParams,
                mapValue: 'fileTags',
                autocompletemulti: true,
                advanced: true,
            }

            vm.advancedCriteria.fileTags = {
                type: 'autocompletemulti',
                multiple: true,
                value: $stateParams.fileTags,
                options: vm.tagSelect,
                title: 'TAGS',
                param: 'fileTags',
                max: 50
            };
        }

        if (config.showSiteStatusFilter) {
            vm.advancedCriteria.siteStatus = {
                type: 'options',
                title: 'SITE_STATUS',
                value: $stateParams.siteStatus === null ? 'Active' : $stateParams.siteStatus,
                options: activeInactiveOptions,
                clearValue: 'Active'
            };
        }

        if (config.showStatutoryType || vm.config.isEntityAttachments) {
            vm.advancedCriteria.fileIsStatutory = {
                type: 'options',
                title: 'IS_STATUTORY_FILE',
                value: $stateParams.fileIsStatutory,
                options: yesNoOptions
            };
        }

        var params = {};

        function saveParams(selectModel) {
            // mapValue is for data select widget selections
            if (selectModel && selectModel.mapValue) {
                if (typeof selectModel.autocompletemulti !== 'undefined' && selectModel.autocompletemulti) {
                    params[selectModel.mapValue] = selectModel.selectedItems.map(function (item) {
                        return item.href;
                    });
                } else {
                    if (selectModel.selected === undefined) {
                        params[selectModel.mapValue] = null;
                    } else {
                        if (selectModel.itemHrefPath === '_links.self.href') {
                            params[selectModel.mapValue] = selectModel.selected.object[selectModel.itemValuePath ? selectModel.itemValuePath : 'id'];
                        } else {
                            params[selectModel.mapValue] = lodash.get(selectModel.selected.object, selectModel.itemValuePath);
                        }
                    }
                }
            } else if (selectModel && !selectModel.mapValue) {
                params = selectModel;
            }
        }

        function close() {
            if (!$scope.form.$pristine && !confirm($translate.instant('NAVIGATION_CONFIRMATION'))) {
                return;
            }
            $uibModalInstance.dismiss();
        }

        function update() {
            $stateParams.filePage = '1';
            $rootScope.skipDirtyCheck = true;
            $state.go('.', angular.extend({}, params), { reload: true });
            $uibModalInstance.dismiss();
        }
    }
})();
