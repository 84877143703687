(function() {
    'use strict';

    angular
        .module('elogbooks')
        .config(Translations);

    function Translations($translateProvider) {
        $translateProvider.translations('nl', {
            QUOTE_REQUEST_ADD_SITE: 'Gebouw',
            QUOTE_REQUEST_ADD_CONTACT_DETAILS: 'Contactgegevens',
            QUOTE_REQUEST_ADD_DETAILS: 'Offertedetails',

            INCLUDE_NEVER_SUBMITTED: 'Inclusief nooit verstuurd' 
        });
    }
})();


app.config(function ($translateProvider) {
    $translateProvider.translations('nl', {
        QUOTE_REQUEST: 'Offerteaanvraag',
        QUOTE_REQUESTS: 'Offerteaanvragen',

        QUOTE_REQUEST_LIST: 'Offerteaanvraag',

        QUOTE_REQUEST_ADD: 'Offerteaanvraag toevoegen',

        QUOTE_REQUEST_CREATED: 'Offerteaanvraag aangemaakt',
        QUOTE_REQUEST_UPDATED: 'Offerteaanvraag gewijzigd',
        QUOTE_REQUEST_DELETED: 'Offerteaanvraag verwijderd',

        QUOTE_REQUEST_DETAILS: 'Details offerteaanvraag',
        QUOTE_REQUEST_CONTACTS: 'Contactgegevens voor deze offerte',

        QUOTE_REQUEST_STATUS_CREATED: 'Aangemaakt',
        QUOTE_REQUEST_STATUS_REQUESTED: 'Aangevraagd',
        QUOTE_REQUEST_STATUS_PART_QUOTED: 'Onderdeel geoffereerd',
        QUOTE_REQUEST_STATUS_QUOTED: 'Prijs afgegeven',
        QUOTE_REQUEST_STATUS_REJECTED: 'Afgewezen',
        QUOTE_REQUEST_STATUS_ACCEPTED: 'Geaccepteerd',
        QUOTE_REQUEST_STATUS_CANCELLED: 'Geannuleerd',
        QUOTE_REQUEST_STATUS_EXPIRED: 'Vervallen',
        QUOTE_REQUEST_STATUS_DECLINED: 'Geweigerd',
        CREATED_FROM_JOB: 'Met deze info aangemaakt',
        QUOTE_WORKFLOW_REOPEN_SUCCESS: 'Offerteverzoek heropend',
        QUOTE_WORKFLOW_CANCEL_SUCCESS: 'Offerteverzoek geannuleerd',
        NEW_COMPLETION_DATE_MUST_BE_AFTER: 'De nieuwe datum moet na de oude liggen.',
        SUPPORTING_DOCUMENT: 'Ondersteunende bestanden'


    });
});
