/**
 * SurveyRequest Service
 */
angular.module('elogbooksServices').service('surveyRequestService', function (apiClient, $q) {
    this.update = update;
    this.submit = submit;
    this.accept = accept;
    this.add = add;
    this.canSubmit = canSubmit;

    /**
     * Accept a survey request, marking Complete
     */
    function accept(surveyRequest) {

        var deferred = $q.defer();

        apiClient.create(surveyRequest.getLink('accept')).then(function (isSuccess) {
            if (isSuccess) {
                return deferred.resolve();
            } else {
                return deferred.reject();
            }
        });

        return deferred.promise;
    }

    /**
     * Submit a survey request, mark Pending
     */
    function submit(surveyRequest) {

        var deferred = $q.defer();

        apiClient.create(surveyRequest.getLink('submit')).then(function (isSuccess) {
            if (isSuccess) {
                return deferred.resolve();
            } else {
                return deferred.reject();
            }
        });

        return deferred.promise;
    }

    /**
     * Add a survey request
     */
    function add(surveyRequest, createLink) {

        var deferred = $q.defer();

        // Present a nicely formatted record to the API
        var surveyRequestRecord = {
            reportingMonth: surveyRequest.reportingMonth,
            reportingYear: surveyRequest.reportingYear,
            spEditable: surveyRequest.spEditable,
            _links: {
                serviceprovider: {
                    href: surveyRequest.serviceprovider
                },
                survey: {
                    href: surveyRequest.survey
                }
            }
        };

        apiClient.create(createLink, surveyRequestRecord).then(function (response) {
            if (response) {
                return deferred.resolve(response);
            } else {
                return deferred.reject();
            }
        });

        return deferred.promise;
    }

    /**
     * Are the lines ready for submission by the SP?
     */
    function canSubmit(surveyRequestLines) {
        var ok = true;
        // Check the sp score each line is ready for submission
        angular.forEach(surveyRequestLines, function (line) {
            if (!line.isSpExcluded) {
                // Has a score been selected?
                var spScoreInvalid = (typeof line.spScore === 'undefined' || line.spScore === null);
                // Have they achieved maximum score?
                var achievedMaxScore = line._embedded.surveyline.maxScore == line.spScore * line._embedded.surveyline.weighting;
                // Is there a comment if we're below the maximum score?
                var invalidComment = (!spScoreInvalid && !achievedMaxScore && line.spComment.length == 0);

                if (spScoreInvalid || invalidComment) {
                    ok = false;
                }
            } else if (line.spComment.length == 0) {
                ok = false;
            }
        });

        return ok;
    }

    /**
     * Are the lines ready for acceptance by the FM?
     */
    this.canAccept = function (surveyRequestLines, $isFM) {
        var ok = true;
        // Check the fm score on each line is ready for acceptance
        angular.forEach(surveyRequestLines, function (line) {
            if (!line.isExcluded) {
                // Has a score been selected?
                var scoreInvalid = (typeof line.score === 'undefined' || line.score === null);
                // Have they achieved maximum score?
                var achievedMaxScore = line._embedded.surveyline.maxScore == line.score * line._embedded.surveyline.weighting;
                // Is there a comment if we're below the maximum score?
                var invalidComment = (!scoreInvalid && !achievedMaxScore && line.comment.length == 0);

                if (scoreInvalid || invalidComment) {
                    ok = false;
                }
            } else if (line.comment.length == 0 && !$isFM) {
                ok = false;
            }
        });

        return ok;
    };

    /**
     * Discover the current SP and FM totals
     */
    this.totals = function (surveyRequestLines) {
        var totals = {
            sp: 0,
            fm: 0,
            calc: 0,
            max: 0,
            maxFM: 0,
            maxSP: 0
        };

        var lineSpScore = 0;
        var lineFMscore = 0;
        var lineCalcScore = 0;

        // Check each line
        angular.forEach(surveyRequestLines, function (line) {

            lineSpScore = (typeof line.spScore !== 'undefined' && line.spScore !== null) ?  line.spScore : null;
            lineFMscore = (typeof line.score !== 'undefined' && line.score !== null) ? line.score : null;

            if (line.isExcluded === false) {
                totals.fm += lineFMscore * line._embedded.surveyline.weighting;

                // any FM score if authoritative
                if (line.score !== null) {
                    totals.calc += lineFMscore * line._embedded.surveyline.weighting;
                }

                totals.maxFM += line._embedded.surveyline.maxScore;
            }

            if (line.isSpExcluded === false) {
                totals.sp += lineSpScore * line._embedded.surveyline.weighting;

                 // use the SP score, if no FM score or if FM has not set the line to be excluded
                if (!line.isExcluded && line.score === null && line.spScore !== null) {
                    totals.calc += lineSpScore * line._embedded.surveyline.weighting
                }

                 totals.maxSP += line._embedded.surveyline.maxScore;
            }

            // include line max score in target score, unless:
            // FM has set line to excluded or SP has set as excluded with the FM not answered
            if (line.isExcluded === false && !(line.isSpExcluded === true && line.score === null)) {
                totals.max += line._embedded.surveyline.maxScore;
            }
        });

        return totals;
    };

    /**
     * Update an existing survey request
     */
    function update(surveyRequest, surveyRequestLines) {
        var deferred = $q.defer();

       // create the formatted array lines for posting
        var lines = [];
        angular.forEach(surveyRequestLines, function (line) {
            lines.push({
                id: line.id,
                comment: line.comment,
                spComment: line.spComment,
                score: line.score,
                spScore: line.spScore,
                isExcluded: line.isExcluded,
                isSpExcluded: line.isSpExcluded
            });
        });

        /**
         * Update flags:
         * 0 - update both FM and SP scores
         * 1 - update FM score
         * 2 - update SP score
         */
        var updateFlag;
        var updateFMScore = surveyRequest.getLink('edit');
        var updateSPScore = surveyRequest.getLink('edit-sp-scores');

        if (updateFMScore && updateSPScore) {
            updateFlag = 0;
        } else if (updateFMScore) {
            updateFlag = 1;
        } else if (updateSPScore) {
            updateFlag = 2;
        }

        var surveyRequestUpdate = {
            reportingMonth: surveyRequest.reportingMonth,
            reportingYear: surveyRequest.reportingYear,
            spEditable: surveyRequest.editableSP,
            updateFlag: updateFlag,
            lines: lines
        };

        var requestLink;

        if (updateFMScore) {
            requestLink = updateFMScore;
        } else if (updateSPScore) {
            requestLink = updateSPScore;
        }

        // Update
        apiClient.update(requestLink, surveyRequestUpdate).then(function (isSuccess) {
            if (isSuccess) {
                 return deferred.resolve('SURVEY_SAVED');
            } else {
                return deferred.reject('REQUEST_ERROR');
            }
        });

        return deferred.promise;
    }

});
