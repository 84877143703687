app.config(function ($translateProvider) {
    $translateProvider.translations('nl', {
        PUBLIC_HOLIDAYS: 'Nationale feestdagen',
        PUBLIC_HOLIDAYS_LIST: 'Nationale feestdagen',
        PUBLIC_HOLIDAYS_ADD: 'Voeg nationale feestdag toe',
        PUBLIC_HOLIDAYS_EDIT: 'Wijzig nationale feestdag',

        PUBLIC_HOLIDAY: 'Nationale feestdag',
        PUBLIC_HOLIDAY_ADD: 'Voeg nationale feestdag toe',
        PUBLIC_HOLIDAY_EDIT: 'Wijzig nationale feestdag',
        PUBLIC_HOLIDAY_ADDED: 'Nationale feestdag toegevoegd',
        PUBLIC_HOLIDAY_UPDATED: 'Nationale feestdag gewijzigd',
        PUBLIC_HOLIDAY_DELETED: 'Nationale feestdag verwijderd',
    });
});
