(function(){
    'use strict';

    angular
        .module('elogbooks.user.meter-meters')
        .config(registerRoutes);

    function registerRoutes($stateProvider) {
        elbMetersRouting().addRoutes($stateProvider, 'dashboard.user.meters', true, true);
    }
})();
