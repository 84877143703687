(function (){
    'use strict';

    angular.module('elogbooks.user.sites').controller('UserSiteOccuapncyHeadcountModalController', UserSiteOccuapncyHeadcountModalController);

    UserSiteOccuapncyHeadcountModalController.$inject = ['site', 'apiClient', '$state', 'messenger', '$uibModalInstance'];

    function UserSiteOccuapncyHeadcountModalController(site, apiClient, $state, messenger, $uibModalInstance) {
        var vm = this;
        vm.site = site;
        vm.siteOccupancyHeadcount = site.occupancyHeadcount;
        vm.updateSiteOccupancyHeadcount = updateSiteOccupancyHeadcount;
        vm.cancel = cancel;

        function updateSiteOccupancyHeadcount() {
            return apiClient.update(vm.site.getLink('self') + '/occupancy-headcount', {occupancyHeadcount: vm.siteOccupancyHeadcount}).then(function (response) {
                if (response) {
                    $state.go('.', {}, {reload: $state.get('^') }).then(function () {
                        messenger.success('UPDATE_SUCCESS');
                        $uibModalInstance.close(response);
                    });
                } else {
                    messenger.error('REQUEST_ERROR');
                }
            });
        }

        function cancel() {
            $uibModalInstance.dismiss();
        }
    }
})();