app.config(function ($translateProvider) {
    $translateProvider.translations('en-gb', {
        ASSET: 'Asset',
        ASSETS: 'Assets',
        ASSET_LIST: 'Assets',
        ASSET_ADD: 'Add a new Asset',
        ASSET_ENTER: 'Enter an Asset',
        SUB_ASSET_ENTER: 'Enter a Sub Asset',
        ASSET_EDIT: 'Edit Asset...',
        ASSET_CREATED: 'Asset Created',
        ASSET_UPDATED: 'Asset Updated',
        ASSET_DELETED: 'Asset Deleted',
        ASSET_IMPORTS: 'Asset Imports',
        MANAGE_ASSETS: 'Manage Assets',
        EXPORT_ASSETS: 'Export Assets',

        SUB_ASSET: 'Sub Asset',
        SUB_ASSETS: 'Sub Assets',
        SUB_ASSET_LIST: 'Sub Assets',
        SUB_ASSET_ADD: 'Add a new Sub Asset',
        EXPORT_SUB_ASSETS: 'Export Sub Assets',

        SUB_ASSET_CREATED: 'Sub Asset Created',
        SUB_ASSET_UPDATED: 'Sub Asset Updated',
        SUB_ASSET_DELETED: 'Sub Asset Deleted',
        SUB_ASSET_EDIT: 'Sub Asset Edit',
        SUB_ASSETS_WITH_COUNT: 'Sub Assets ({{ ::count || 0 | number:0 }})',
        BACK_TO_SUB_ASSETS: 'Back to Sub Assets',

        MAKE: 'Make',
        MODEL: 'Model',
        SERIAL: 'Serial',
        BUSINESS_CONTINUITY_RISK: 'Business Continuity',
        REPLACEMENT_COST_LABOUR: 'Labour Replacement Cost',
        REPLACEMENT_COST_MATERIALS: 'Material Replacement Cost',
        END_OF_LIFE: 'End of Life',
        CHARGEABLE: 'Chargeable',
        ASSET_TYPE: 'Type',
        JOB_GROUP: 'Job Group',
        NUMBER: 'Number',
        STATUTORY_TYPE: 'Statutory Type',
        SERVICE_ROUTINE: 'Service Routine',
        IS_STATUTORY: 'Is Statutory Type?',
        ASSET_HISTORICAL_SCORES: 'Historical Scores',
        ASSET_HISTORICAL_SCORES_LIST: 'Historical Scores',
        COMPLETED_BY: 'Completed By',
        FREQUENCY_CANT_BE_DAILY: 'Selected service routine contains daily frequency. Please change or remove',
        DUTY: 'Duty',
        INPUT_FUEL: 'Input Fuel',
        ENERGY_EFFICIENCY_RATING: 'Energy Efficiency Rating',
        INSTALLATION_DATE: 'Installation Date',
        EXPECTED_LIFE_YEARS: 'Expected Life (Years)',
        AGE: 'Age (Years)',
        REMAINING_LIFE: 'Remaining Life (Years)',
        WARRANTY_START: 'Warranty Start',
        WARRANTY_EXPIRY: 'Warranty Expiry',
        INSTALLER_COMPANY_NAME: 'Installer Company Name',
        INSTALLER_COMPANY_CONTACT_EMAIL: 'Installer Company Contact Email',
        INSTALLER_COMPANY_CONTACT_PHONE: 'Installer Company Contact Phone',
        WARRANTY_COMPANY_NAME: 'Warranty Company Name',
        WARRANTY_COMPANY_CONTACT_EMAIL: 'Warranty Company Contact Email',
        WARRANTY_COMPANY_CONTACT_PHONE: 'Warranty Company Contact Phone',
        F_GAS: 'F-Gas',
        F_GAS_OPERATING_MEDIUM: 'Operating Medium',
        F_GAS_OPERATING_MEDIUM_CAPACITY: 'Operating Medium Capacity',
        F_GAS_CAPACITY: 'F-Gas Capacity',
        ELECTRICITY: 'Electricity',
        GAS: 'Gas',
        COAL: 'Coal',
        BIOFUEL: 'BioFuel',
        STEAM: 'Steam',

        SELECT_AN_ASSET: 'Select an Asset',
        SELECT_A_SUB_ASSET: 'Select a Sub Asset',

        ASSETS_UPDATED: 'Assets updated',
        ASSET_REQUIRES_REMEDIAL: 'Selected asset score requires remedial action to be raised',

        SFG20: 'SFG20',
        NON_SFG20: 'Non-SFG20',
        SERVICE_ROUTINE_TYPE: 'Service Routine Type',
        NO_MATCHING_SERVICE_ROUTINES: 'No service routines matching the selected type found',

        EDIT_ASSET_SERVICE_ROUTINES: 'Edit Asset Service Routines'
    });
});
