(function () {
    'use strict';

    /**
     * @desc Display a rule
     * @example <elb-recurrence-rule data-ng-model="MODEL"></elb-recurrence-rule>
     */
    angular
        .module('elogbooksDirectives')
        .directive('elbRecurrenceRule', recurrenceRule);

    function recurrenceRule() {
        var directive = {
            templateUrl: '/modules/directives/recurrence-rule/recurrence-rule.html',
            restrict: 'AE',
            scope: {
                rule: '=ngModel',
            },
            require: ['ngModel'],
            controller: ['$scope', 'lodash', 'recurrenceRulesService', function($scope, lodash, recurrenceRulesService) {
                $scope.recurrenceRulesService = recurrenceRulesService;
            }]
        };

        return directive;
    }
})();
