(function(){
    'use strict';

    angular
        .module('elogbooks.admin')
        .config(registerRoutes);

    function registerRoutes($stateProvider) {

        $stateProvider
            .state('dashboard.admin', {
                url: '/admin',
                abstract: true,
                templateUrl: 'shared-templates/view.html',
                ncyBreadcrumb: {
                    label: '{{ ::"ADMIN" | translate }}'
                }
            });
    };

})();
