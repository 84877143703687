(function () {
    'use strict';

    angular
        .module('elogbooks.admin.vat-rates')
        .controller('VatRateAddEditController', VatRateAddEditController);

        VatRateAddEditController.$inject = [
            '$state',
            'crudService',
            'requestDataFactory',
            'vatRateResponse',
            'vatRatesCollectionResponse'
        ];

    function VatRateAddEditController (
        $state,
        crudService,
        requestDataFactory,
        vatRateResponse,
        vatRatesCollectionResponse
        ) {
        var vm = this;
            vm.vatRate = vatRateResponse;
            vm.vatRatesCollectionResponse = vatRatesCollectionResponse;
            vm.data = requestDataFactory.createRequest(getDefaults());

        vm.create = createAction;
        vm.update = updateAction;
        vm.delete = deleteAction;

        function getDefaults() {
            return {
                id: vm.vatRate.id || null,
                name: vm.vatRate.name || null,
                percentageRate: vm.vatRate.percentageRate || null,
                externalReference: vm.vatRate.externalReference || null,
                active: vm.vatRate.active === true || vm.vatRate.active === false ? vm.vatRate.active : null,
                isDefault: vm.vatRate.isDefault
            };
        }

        function createAction () {
            return crudService.create(
                vm.vatRatesCollectionResponse.getLink('create'),
                vm.data,
                $state.get('^'),
                'resource',
                'VAT_RATES'
            );
        }

        function updateAction () {
            return crudService.update(
                vm.vatRate.getLink('edit'),
                vm.data,
                $state.get('^'),
                'dashboard.admin.vat-rates.list',
                'VAT_RATES',
                false
            );
        }

        function deleteAction () {
            return crudService.delete(
                vm.vatRate.getLink('delete'),
                'dashboard.admin.vat-rates.list',
                'VAT_RATES'
            );
        }
    };
})();
