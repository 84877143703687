(function(){
    'use strict';

    angular
        .module('elogbooksControllers')
        .controller('CreateLocationModalController', CreateLocationModalController);

    CreateLocationModalController.$inject = ['$uibModalInstance', 'locationService', 'parentLocation', 'createLink', 'messenger'];

    function CreateLocationModalController($uibModalInstance, locationService, parentLocation, createLink, messenger) {
        var vm = this;
        vm.close = closeAction;
        vm.submit = submitAction;
        vm.parentLocation =  angular.copy(parentLocation);
        vm.createLink = createLink;

        if (vm.parentLocation && vm.parentLocation._links) {
            vm.parentLocationPath = vm.parentLocation._links.self;
            vm.parentLocationPath.name = vm.parentLocation.name;
        }

        vm.data = {
            name: null,
            active: true
        };

        function submitAction() {
            if (vm.parentLocation && vm.parentLocation._links) {
                vm.data._links = {
                    parent: {
                        href: vm.parentLocation._links.self.href
                    }
                };
            }

            vm.location = locationService.createLocation(createLink, vm.data).then(function(response) {
                    messenger.success('LOCATION_CREATED');
                    return response;
                }
            );

            $uibModalInstance.close(vm.location);
        }

        function closeAction() {
            $uibModalInstance.dismiss('cancel');
        }
    }
})();
