(function() {
    'use strict';

    angular
        .module('elogbooks.user.escalations')
        .controller('EscalationsController', EscalationsController);

    EscalationsController.$inject = ['$state', '$stateParams', '$translate', 'escalationCollectionResponse', 'escalationStageCollectionResponse', 'user'];

    function EscalationsController($state, $stateParams, $translate, escalationCollectionResponse, escalationStageCollectionResponse, user) {
        var vm = this;

        vm.escalationCollectionResponse = escalationCollectionResponse;
        vm.user = user;

        vm.order = orderAction;
        vm.search = searchAction;
        vm.stateParams = $stateParams;

        vm.criteria = {
            escalationStage: { type: 'text', title: 'STAGE', value: $stateParams.escalationStage, min: 1 },
            serviceProviderName: {type: 'text', title: 'SERVICE_PROVIDER', value: $stateParams.serviceProviderName}
        };

        function orderAction(key) {
            $stateParams.order = key;

            $state.go('.', angular.extend({}, $stateParams), { reload: $state.current });
        }

        function searchAction(params) {
            var override = {
                page: 1
            };

            $state.go('.', angular.extend({}, params, override), { reload: $state.current });
        }
    }
})();
