(function () {
    'use strict';

    angular
        .module('elogbooks.user.patrols')
        .config(registerRoutes)
        .config(registerRoutesPatrols)
        .config(registerRoutesManagePatrols)
        .config(registerRoutesPatrolPoints)
        .config(registerRoutesPatrolPlanner);

    var dateRangeMinus1Day = moment().subtract(30, 'days').startOf('day').format()+','+moment().endOf('day').subtract(1, 'days').format();

    function registerRoutes($stateProvider) {
        $stateProvider
            .state('dashboard.user.patrols', {
                url: '/patrols',
                abstract: true,
                template: '<bookerize></bookerize>',
                ncyBreadcrumb: { skip: true }
            });
    }

    function registerRoutesManagePatrols ($stateProvider) {

        $stateProvider.state('dashboard.user.patrols.manage-patrols', {
                url: '?page&limit&order&name&status',
                parent: 'dashboard.user.patrols',
                views: {
                    '@dashboard.user.patrols': {
                        templateUrl: '/modules/user/patrols/manage-patrols/manage-patrols-list.html',
                        controller: 'ManagePatrolsListController',
                        controllerAs: 'vm'
                    }
                },
                resolve: {
                    patrolsCollection: function ($stateParams, apiClient, rootResourceResponse, selectedSiteResponse) {
                        var params = angular.extend({}, $stateParams);
                        if (selectedSiteResponse) {
                            params.siteId = selectedSiteResponse.id;
                        } else {
                            params.siteId = null;
                        }

                        params.status = $stateParams.status === 'all' ? null : $stateParams.status;

                        return apiClient.get(rootResourceResponse.getLink('patrols'), params);
                    }
                },
                params: {
                    page: '1',
                    limit: '30',
                    order: 'name',
                    selectedSiteResource: null,
                    active: null,
                    name: null,
                    status: 'active'
                },
                ncyBreadcrumb: {
                    label: '{{ ::"MANAGE_PATROLS" | translate }}'
                }
            })
            .state('dashboard.user.patrols.manage-patrols.add', {
                url: '/add',
                parent: 'dashboard.user.patrols.manage-patrols',
                views: {
                    '@dashboard.user.patrols': {
                        templateUrl: '/modules/user/patrols/manage-patrols/add-edit/patrol-form.html',
                        controller: 'ManagePatrolsAddEditController',
                        controllerAs: 'vm'
                    }
                },
                resolve: {
                    patrolResponse: function () {
                        return {};
                    },
                    sitesCollection: function (apiClient, user, globalFilterParamsService) {
                        var params = {
                            datagroup: 'search'
                        };

                        params = angular.extend({}, params, globalFilterParamsService.getLocalStorageFilters());

                        return apiClient.get(user.getLink('sites'), params, 'long').then(function(response) {
                            return response || apiClient.noResourceFound();
                        });
                    },
                    patrolsCollection: function ($stateParams, apiClient, rootResourceResponse) {
                        return apiClient.get(rootResourceResponse.getLink('patrols'), $stateParams);
                    },
                    siteResponse: function (selectedSiteResponse) {
                        return selectedSiteResponse;
                    }
                },
                ncyBreadcrumb: {
                    label: '{{ ::"ADD" | translate }}'
                }
            })
            .state('dashboard.user.patrols.manage-patrols.details', {
                url: 'details/{resource}',
                abstract: true,
                parent: 'dashboard.user.patrols.manage-patrols',
                views: {
                    '@dashboard.user.patrols': {
                        templateUrl: '/modules/user/patrols/manage-patrols/details/patrol-details.html',
                        controller: 'ManagePatrolsDetailsController',
                        controllerAs: 'vm'
                    }
                },
                resolve: {
                    patrolResponse: function (apiClient, $stateParams) {
                        return apiClient.get($stateParams.resource.decode());
                    }
                },
                ncyBreadcrumb: {
                    label: '{{ ::$resolves().patrolResponse.name }}'
                }
            })
            .state('dashboard.user.patrols.manage-patrols.details.info', {
                url: '/info',
                parent: 'dashboard.user.patrols.manage-patrols.details',
                views: {
                    '@dashboard.user.patrols.manage-patrols.details': {
                        templateUrl: '/modules/user/patrols/manage-patrols/details/info/patrol-info.html',
                        controller: 'ManagePatrolsInfoController',
                        controllerAs: 'vm'
                    }
                },
                ncyBreadcrumb: {
                    label: '{{ ::"INFO" | translate }}'
                }
            })
            .state('dashboard.user.patrols.manage-patrols.details.info.edit', {
                url: '/edit',
                parent: 'dashboard.user.patrols.manage-patrols.details.info',
                views: {
                    '@dashboard.user.patrols.manage-patrols.details': {
                        templateUrl: '/modules/user/patrols/manage-patrols/add-edit/patrol-form.html',
                        controller: 'ManagePatrolsAddEditController',
                        controllerAs: 'vm'
                    }
                },
                resolve: {
                    sitesCollection: function (apiClient, user) {
                        return apiClient.get(user.getLink('sites')).then(function (response) {
                            return response;
                        });
                    },
                    patrolsCollection: function ($stateParams, apiClient, rootResourceResponse) {
                        return apiClient.get(rootResourceResponse.getLink('patrols'), $stateParams);
                    },
                    siteResponse: function (selectedSiteResponse) {
                        return selectedSiteResponse;
                    }
                },
                ncyBreadcrumb: {
                    label: '{{ ::"EDIT" | translate }}'
                }
            })
            .state('dashboard.user.patrols.manage-patrols.details.schedules', {
                url: '/schedules?schedulePage&scheduleLimit&scheduleOrder&scheduleName',
                abstract: true,
                parent: 'dashboard.user.patrols.manage-patrols.details',
                resolve: {
                    patrolSchedulesCollection: function (apiClient, patrolResponse, $stateParams) {
                        return apiClient.get(patrolResponse.getLink('schedules'), angular.extend({}, $stateParams, {
                            page: $stateParams.schedulePage,
                            limit: $stateParams.scheduleLimit,
                            order: $stateParams.scheduleOrder,
                            name: $stateParams.scheduleName,
                            ignoreAdhocSchedules: $stateParams.ignoreAdhocSchedules
                        }));
                    }
                },
                params: {
                    schedulePage: '1',
                    scheduleLimit: '30',
                    scheduleOrder: 'name',
                    scheduleName: null,
                    ignoreAdhocSchedules: true
                },
                ncyBreadcrumb: { skip: true }
            })
            .state('dashboard.user.patrols.manage-patrols.details.schedules.list', {
                url: '/list',
                parent: 'dashboard.user.patrols.manage-patrols.details.schedules',
                views: {
                    '@dashboard.user.patrols.manage-patrols.details': {
                        templateUrl: '/modules/user/patrols/manage-patrols/details/schedules/patrol-schedules-list.html',
                        controller: 'PatrolSchedulesController',
                        controllerAs: 'vm'
                    }
                },
                ncyBreadcrumb: {
                    label: '{{ ::"PATROL_SCHEDULES" | translate }}'
                }
            })
            .state('dashboard.user.patrols.manage-patrols.details.schedules.list.add', {
                url: '/add',
                parent: 'dashboard.user.patrols.manage-patrols.details.schedules.list',
                views: {
                    '@dashboard.user.patrols.manage-patrols.details': {
                        templateUrl: '/modules/user/patrols/manage-patrols/details/schedules/add-edit/patrol-schedule-form.html',
                        controller: 'PatrolSchedulesAddEditController',
                        controllerAs: 'vm'
                    }
                },
                resolve: {
                    patrolScheduleResponse: function () {
                        return {};
                    },
                },
                ncyBreadcrumb: {
                    label: '{{ ::"ADD" | translate }}'
                }
            })
            .state('dashboard.user.patrols.manage-patrols.details.schedules.list.details', {
                url: '/details/{patrolScheduleResource}?occurrencePage&occurrenceLimit&occurrenceOrder&occurrenceDayOfWeek&occurrenceServiceProvider&occurrenceStatus',
                parent: 'dashboard.user.patrols.manage-patrols.details.schedules.list',
                views: {
                    '@dashboard.user.patrols.manage-patrols.details': {
                        templateUrl: '/modules/user/patrols/manage-patrols/details/schedules/patrol-schedule-detail.html',
                        controller: 'PatrolSchedulesDetailController',
                        controllerAs: 'vm'
                    },
                    'occurrences@dashboard.user.patrols.manage-patrols.details.schedules.list.details': {
                        templateUrl: '/modules/user/patrols/manage-patrols/details/schedules/occurrence/occurrence-list.html',
                        controller: 'PatrolSchedulesDetailController',
                        controllerAs: 'vm'
                    }
                },
                resolve: {
                    patrolScheduleResponse: function (apiClient, $stateParams) {
                        return apiClient.get($stateParams.patrolScheduleResource.decode());
                    },
                    patrolOccurrenceCollection: function ($stateParams, apiClient, patrolScheduleResponse) {
                        return apiClient.get(patrolScheduleResponse.getLink('occurrences'), angular.extend({}, $stateParams, {
                            page: $stateParams.occurrencePage,
                            limit: $stateParams.occurrenceLimit,
                            order: $stateParams.occurrenceOrder,
                            dayOfWeek: $stateParams.occurrenceDayOfWeek,
                            serviceProvider: $stateParams.occurrenceServiceProvider,
                            status: $stateParams.occurrenceStatus
                        }));
                    },
                    siteResponse: function ($stateParams, apiClient, patrolResponse) {
                        return apiClient.get(patrolResponse.getLink('site'), $stateParams);
                    },
                    partnershipsCollection: function (apiClient, siteResponse, $stateParams) {
                        return apiClient.get(siteResponse.getLink('partnerships'), angular.extend({}, {
                            page: '1',
                            limit: '30',
                            order: null
                        }));
                    }
                },
                params: {
                    occurrencePage: '1',
                    occurrenceLimit: '30',
                    occurrenceOrder: 'dayOfWeek',
                    occurrenceDayOfWeek: null,
                    occurrenceServiceProvider: null,
                    occurrenceStatus: null
                },
                ncyBreadcrumb: {
                    label: '{{ ::$resolves().patrolScheduleResponse.name }}'
                }
            })
            .state('dashboard.user.patrols.manage-patrols.details.schedules.list.details.edit', {
                url: '/edit',
                parent: 'dashboard.user.patrols.manage-patrols.details.schedules.list.details',
                views: {
                    '@dashboard.user.patrols.manage-patrols.details': {
                        templateUrl: '/modules/user/patrols/manage-patrols/details/schedules/add-edit/patrol-schedule-form.html',
                        controller: 'PatrolSchedulesAddEditController',
                        controllerAs: 'vm'
                    }
                },
                ncyBreadcrumb: {
                    label: '{{ ::"EDIT" | translate }}'
                }
            })
            .state('dashboard.user.patrols.manage-patrols.details.schedules.list.details.occurrence', {
                url: '/occurrence',
                abstract: true,
                parent: 'dashboard.user.patrols.manage-patrols.details.schedules.list.details',
                ncyBreadcrumb: {
                    label: '{{ ::"PATROL_OCCURRENCES" | translate }}'
                }
            })
            .state('dashboard.user.patrols.manage-patrols.details.schedules.list.details.occurrence.add', {
                url: '/add',
                parent: 'dashboard.user.patrols.manage-patrols.details.schedules.list.details.occurrence',
                views: {
                    '@dashboard.user.patrols.manage-patrols.details': {
                        templateUrl: '/modules/user/patrols/manage-patrols/details/schedules/occurrence/add-edit/patrol-occurrence-form.html',
                        controller: 'PatrolOccurrenceAddEditController',
                        controllerAs: 'vm'
                    }
                },
                resolve: {
                    patrolOccurrenceResponse: function () {
                        return {};
                    }
                },
                ncyBreadcrumb: {
                    label: '{{ ::"ADD" | translate }}'
                }
            })
            .state('dashboard.user.patrols.manage-patrols.details.schedules.list.details.occurrence.addblock', {
                url: '/addblock',
                parent: 'dashboard.user.patrols.manage-patrols.details.schedules.list.details.occurrence',
                views: {
                    '@dashboard.user.patrols.manage-patrols.details': {
                        templateUrl: '/modules/user/patrols/manage-patrols/details/schedules/occurrence/add-edit/patrol-occurrence-bulk-form.html',
                        controller: 'PatrolOccurrenceBulkAddController',
                        controllerAs: 'vm'
                    }
                },
                resolve: {
                    siteResponse: function ($stateParams, apiClient, patrolResponse) {
                        return apiClient.get(patrolResponse.getLink('site'), $stateParams);
                    },
                    partnershipsCollection: function (apiClient, siteResponse, $stateParams) {
                        return apiClient.get(siteResponse.getLink('partnerships'), angular.extend({}, $stateParams, {
                            page: '1',
                            limit: '30',
                            order: null
                        }));
                    }
                },
                ncyBreadcrumb: {
                    label: '{{ ::"ADD" | translate }}'
                }
            })
            .state('dashboard.user.patrols.manage-patrols.details.schedules.list.details.occurrence.details', {
                url: '/{patrolOccurrenceResource}',
                parent: 'dashboard.user.patrols.manage-patrols.details.schedules.list.details.occurrence',
                views: {
                    'occurrences@dashboard.user.patrols.manage-patrols.details.schedules.list.details': {
                        templateUrl: '/modules/user/patrols/manage-patrols/details/schedules/occurrence/patrol-occurrence-detail.html',
                        controller: 'PatrolOccurrenceDetailController',
                        controllerAs: 'vm'
                    }
                },
                resolve: {
                    patrolOccurrenceResponse: function (apiClient, $stateParams) {
                        return apiClient.get($stateParams.patrolOccurrenceResource.decode());
                    }
                },
                ncyBreadcrumb: {
                    label: '{{ ::"PATROL_OCCURRENCE" | translate }}'
                }
            })
            .state('dashboard.user.patrols.manage-patrols.details.schedules.list.details.occurrence.details.edit', {
                url: '/edit',
                parent: 'dashboard.user.patrols.manage-patrols.details.schedules.list.details.occurrence.details',
                views: {
                    '@dashboard.user.patrols.manage-patrols.details': {
                        templateUrl: '/modules/user/patrols/manage-patrols/details/schedules/occurrence/add-edit/patrol-occurrence-form.html',
                        controller: 'PatrolOccurrenceAddEditController',
                        controllerAs: 'vm'
                    }
                },
                resolve: {
                    siteResponse: function ($stateParams, apiClient, patrolResponse) {
                        return apiClient.get(patrolResponse.getLink('site'), $stateParams);
                    },
                    partnershipsCollection: function (apiClient, siteResponse, $stateParams) {
                        return apiClient.get(siteResponse.getLink('partnerships'), angular.extend({}, $stateParams, {
                            page: '1',
                            limit: '30',
                            order: null
                        }));
                    }
                },
                ncyBreadcrumb: {
                    label: '{{ ::"EDIT" | translate }}'
                }
            })
            .state('dashboard.user.patrols.manage-patrols.details.route', {
                url: '/route',
                abstract: true,
                parent: 'dashboard.user.patrols.manage-patrols.details',
                resolve: {
                    patrolPointsCollection: function (apiClient, patrolResponse, $stateParams) {
                        return apiClient.get(patrolResponse.getLink('points'), angular.extend({}, $stateParams, {
                            limit: $stateParams.patrolPointsLimit,
                            page: $stateParams.patrolPointsPage,
                            order: $stateParams.patrolPointsOrder
                        }));
                    }
                },
                params: {
                    patrolPointsPage: '1',
                    patrolPointsLimit: '30',
                    patrolPointsOrder: 'displayOrder'
                },
                ncyBreadcrumb: {
                    skip: true
                }
            })
            .state('dashboard.user.patrols.manage-patrols.details.route.list', {
                url: '/list',
                parent: 'dashboard.user.patrols.manage-patrols.details.route',
                views: {
                    '@dashboard.user.patrols.manage-patrols.details': {
                        templateUrl: '/modules/user/patrols/manage-patrols/details/route/patrol-route.html',
                        controller: 'PatrolRouteController',
                        controllerAs: 'vm'
                    }
                },
                ncyBreadcrumb: {
                    label: '{{ ::"PATROL_POINTS" | translate }}'
                }
            })
            .state('dashboard.user.patrols.manage-patrols.details.route.list.add', {
                url: '/add',
                parent: 'dashboard.user.patrols.manage-patrols.details.route.list',
                views: {
                    '@dashboard.user.patrols.manage-patrols.details': {
                        templateUrl: '/modules/user/patrols/manage-patrols/details/route/add-edit/patrol-point-form.html',
                        controller: 'PatrolPointsAddEditController',
                        controllerAs: 'vm'
                    }
                },
                resolve: {
                    patrolPointResponse: function () {
                        return {};
                    },
                    siteResponse: function ($stateParams, apiClient, patrolResponse) {
                        return apiClient.get(patrolResponse.getLink('site'), $stateParams);
                    },
                    locationsCollection: function ($stateParams, apiClient, siteResponse) {
                        return apiClient.get(siteResponse.getLink('locations'), $stateParams);
                    },
                    genericPointCollectionResponse: function ($stateParams, apiClient, patrolResponse) {
                        var params = {
                            patrolPointSiteId: patrolResponse.getLinkAttribute('site', 'id')
                        };

                        return apiClient.get(patrolResponse.getLink('generic-points'), params);
                    }
                },
                ncyBreadcrumb: {
                    label: '{{ ::"ADD" | translate }}'
                }
            })
            .state('dashboard.user.patrols.manage-patrols.details.route.list.details', {
                url: '/details/{patrolPointResource}',
                abstract: true,
                parent: 'dashboard.user.patrols.manage-patrols.details.route.list',
                resolve: {
                    patrolPointResponse: function ($stateParams, apiClient) {
                        return apiClient.get($stateParams.patrolPointResource.decode());
                    },
                    siteResponse: function ($stateParams, apiClient, patrolResponse) {
                        return apiClient.get(patrolResponse.getLink('site'), $stateParams);
                    },
                    locationsCollection: function ($stateParams, apiClient, siteResponse) {
                        return apiClient.get(siteResponse.getLink('locations'), $stateParams);
                    }
                },
                ncyBreadcrumb: {
                    label: '{{ ::"PATROL_POINT" | translate }}'
                }
            })
            .state('dashboard.user.patrols.manage-patrols.details.route.list.details.edit', {
                url: '/edit',
                parent: 'dashboard.user.patrols.manage-patrols.details.route.list.details',
                views: {
                    '@dashboard.user.patrols.manage-patrols.details': {
                        templateUrl: '/modules/user/patrols/manage-patrols/details/route/add-edit/patrol-point-form.html',
                        controller: 'PatrolPointsAddEditController',
                        controllerAs: 'vm'
                    }
                },
                resolve: {
                    genericPointCollectionResponse: function () {
                        return null;
                    }
                },
                ncyBreadcrumb: {
                    label: '{{ ::"EDIT" | translate }}'
                }
            });
    }

    function registerRoutesPatrolPoints ($stateProvider) {
        $stateProvider
            .state('dashboard.user.patrols.points.details.info', {
                url: '/info',
                parent: 'dashboard.user.patrols.points.details',
                views: {
                    '@dashboard.user.patrols.points.details': {
                        templateUrl: '/modules/user/patrols/generic-points/info.html',
                        controller: 'GenericPatrolPointsInfoController',
                        controllerAs: 'vm'
                    }
                },
                ncyBreadcrumb: {
                    label: '{{ ::"INFO" | translate }}'
                }
            })
            .state('dashboard.user.patrols.points.details.info.edit', {
                url: '/edit',
                parent: 'dashboard.user.patrols.points.details.info',
                views: {
                    '@dashboard.user.patrols.points.details': {
                        templateUrl: '/modules/user/patrols/generic-points/add-edit.html',
                        controller: 'GenericPatrolPointsAddEditController',
                        controllerAs: 'vm'
                    }
                },
                resolve: {
                    locationsCollectionResponse: function ($stateParams, apiClient, patrolPointResponse) {
                        if (patrolPointResponse.getLink('get-site-locations')) {
                            return apiClient.getPage(patrolPointResponse.getLink('get-site-locations'));
                        }

                        return null;
                    }
                },
                ncyBreadcrumb: {
                    label: '{{ ::"EDIT" | translate }}'
                }
            })
            .state('dashboard.user.patrols.points.details', {
                url: '/details/{resource}',
                abstract: true,
                parent: 'dashboard.user.patrols.points',
                views: {
                    '@dashboard.user.patrols': {
                        templateUrl: '/modules/user/patrols/generic-points/details.html',
                        controller: 'GenericPatrolPointsDetailsController',
                        controllerAs: 'vm'
                    }
                },
                resolve: {
                    patrolPointResponse: function ($stateParams, apiClient) {
                        return apiClient.get($stateParams.resource.decode());
                    }
                },
                ncyBreadcrumb: {
                    label: '{{ ::$resolves().patrolPointResponse.summary }}'
                }
            })
            .state('dashboard.user.patrols.points.add', {
                url: '/patrols/points/add',
                parent: 'dashboard.user.patrols.points',
                views: {
                    '@dashboard.user.patrols': {
                        templateUrl: '/modules/user/patrols/generic-points/add-edit.html',
                        controller: 'GenericPatrolPointsAddEditController',
                        controllerAs: 'vm'
                    }
                },
                resolve: {
                    siteCollectionResponse: function(user, apiClient, globalFilterParamsService) {
                        var params = {
                            datagroup: 'search'
                        };

                        params = angular.extend({}, params, globalFilterParamsService.getLocalStorageFilters());

                        return apiClient.get(user.getLink('sites'), params, 'long').then(function(response) {
                            return response || apiClient.noResourceFound();
                        });
                    },
                    patrolPointResponse: function() {
                        return null;
                    },
                    locationsCollectionResponse: function() {
                        return null;
                    }
                },
                ncyBreadcrumb: {
                    label: '{{ ::"ADD" | translate }}'
                }
            })
            .state('dashboard.user.patrols.points', {
                url: '/points?pointPage&pointLimit&pointOrder',
                parent: 'dashboard.user.patrols',
                views: {
                    '@dashboard.user.patrols': {
                        templateUrl: '/modules/user/patrols/generic-points/list.html',
                        controller: 'GenericPatrolPointsListController',
                        controllerAs: 'vm'
                    }
                },
                params: {
                    patrolPointSummary: null,
                    patrolPointType: null,
                    patrolPointSiteId: null,
                    patrolPointLocationId: null,
                    pointPage: '1',
                    pointLimit: '30',
                    pointOrder: 'name'
                },
                resolve: {
                    patrolPointsCollection: function ($stateParams, apiClient, rootResourceResponse, selectedSiteResponse) {
                        var params = angular.copy($stateParams);

                        if (selectedSiteResponse) {
                            params.patrolPointSiteId = selectedSiteResponse.id;
                            $stateParams.patrolPointSiteId = JSON.stringify({ v: selectedSiteResponse.id, n: selectedSiteResponse.name });
                        } else {
                            params.patrolPointSiteId = $stateParams.patrolPointSiteId ? JSON.parse($stateParams.patrolPointSiteId).v : null;
                        }

                        if (isNaN(params.patrolPointLocationId) || !params.patrolPointSiteId) {
                            params.patrolPointLocationId = null;
                        }

                        params.page = $stateParams.pointPage;
                        params.limit = $stateParams.pointLimit;
                        params.order = $stateParams.pointOrder;
                        params.datagroup = 'list';

                        return apiClient.get(rootResourceResponse.getLink('patrolpoints'), params);
                    },
                    siteCollectionResponse: function ($stateParams, apiClient, user) {
                        var params = angular.copy($stateParams);
                        params.patrolPointSiteId = params.patrolPointSiteId ? JSON.parse($stateParams.patrolPointSiteId).v : null;
                        params.datagroup = 'search';

                        return apiClient.getPage(user.getLink('sites'), params);
                    },
                    locationsCollectionResponse: function ($stateParams, apiClient, siteCollectionResponse, user) {
                        if ($stateParams.patrolPointSiteId) {
                            return apiClient.get(user.getLink('sites'), { id: JSON.parse($stateParams.patrolPointSiteId).v }).then(function (response) {
                                if (response && response.sites.length > 0) {
                                    return apiClient.get(response.sites[0].getLink('locations'), {fetchHierarchy: true, datagroup: 'details'}).then(function (response) {
                                        return response;
                                    });
                                }
                            });
                        }

                        return null;
                    }
                },
                ncyBreadcrumb: {
                    label: '{{ ::"PATROL_POINTS" | translate }}'
                }
            })
    }

    function registerRoutesPatrolPlanner($stateProvider) {
        $stateProvider
            .state('dashboard.user.patrols.patrol-planner', {
                url: '/patrol-planner',
                parent: 'dashboard.user.patrols',
                abstract: true,
                views: parseBook({
                    'elogbooks-center@dashboard.user.patrols': {
                        templateUrl: '/modules/user/patrols/patrol-planner/patrol-planner.html'
                    }
                }),
                ncyBreadcrumb: {
                    label: '{{ ::"PATROL_PLANNER" | translate }}'
                }
            })
            .state('dashboard.user.patrols.patrol-planner.patrol-active', {
                url: '/patrol-schedule?page&limit&order&siteId&dayOfWeek&siteIds&serviceProvider',
                parent: 'dashboard.user.patrols.patrol-planner',
                views: parseBook({
                        'patrol-planner': {
                        templateUrl: '/modules/user/patrols/patrol-planner/patrol-active/patrol-active.html',
                        controller: 'PatrolPlannerController',
                        controllerAs: 'vm'
                    }
                }),
                params: {
                    page: '1',
                    limit: '30',
                    order: 'name',
                    siteIds: {array: true},
                    serviceProvider: null,
                    patrolName: null,
                    dayOfWeek: (moment(new Date()).day()).toString(),
                    datagroup: 'details',
                    excludeInstanceStatuses: {array: true},
                    status: 'active'
                },
                resolve: {
                    siteResponse: function ($stateParams, apiClient, user, globalFilterParamsService) {
                        var params = {
                            datagroup: 'search'
                        };

                        params = angular.extend({}, params, globalFilterParamsService.getLocalStorageFilters());

                        return apiClient.get(user.getLink('sites'), params, 'long').then(function(response) {
                            return response || apiClient.noResourceFound();
                        });
                    },
                    serviceProviderResponse: function($stateParams, apiClient, user) {
                        var params = {
                            page: 1,
                            limit: 30
                        };
                        params = angular.extend({}, $stateParams, params);

                        return apiClient.get(user.getLink('serviceproviders'), params);
                    },
                    patrolResponse: function (apiClient, selectedSiteResponse, rootResourceResponse, $stateParams, globalFilterParamsService) {
                        var params = angular.copy($stateParams);
                        var selectedSiteResponseID = selectedSiteResponse ? [selectedSiteResponse.id] : null;
                        var siteIds = selectedSiteResponseID || $stateParams.siteIds;

                        if (siteIds && siteIds.length > 0) {
                            $stateParams.siteIds = siteIds;
                        }

                        params = angular.extend({}, params, {
                            'siteIds[]': siteIds,
                            'serviceProvider': $stateParams.serviceProvider ? JSON.parse($stateParams.serviceProvider).v : null,
                            'excludeInstanceStatuses[]': ['cancelled'],
                            name: $stateParams.patrolName
                        });

                        params = angular.extend({}, params, globalFilterParamsService.getLocalStorageFilters());

                        return apiClient.get(rootResourceResponse.getLink('plannerpatrolinstances'), params);
                    }
                },
                ncyBreadcrumb: {
                    label: '{{ ::"PATROL_ACTIVE" | translate }}'
                }
            })
            .state('dashboard.user.patrols.patrol-planner.patrol-draft', {
                url: '/patrol-template?page&limit&order&siteId&dayOfWeek&siteIds&serviceProvider&patrolName',
                parent: 'dashboard.user.patrols.patrol-planner',
                views: parseBook({
                    'patrol-planner': {
                        templateUrl: '/modules/user/patrols/patrol-planner/patrol-draft/patrol-draft.html',
                        controller: 'PatrolPlannerController',
                        controllerAs: 'vm'
                    }
                }),
                params: {
                    page: '1',
                    limit: '30',
                    order: 'name',
                    siteIds: {array: true},
                    serviceProvider: null,
                    patrolName: null,
                    dayOfWeek: (moment(new Date()).day()).toString(),
                    datagroup: 'details',
                    planner: 'draft'
                },
                resolve: {
                    siteResponse: function (apiClient, user) {
                        return apiClient.get(user.getLink('sites')).then(function (response) {
                            return response;
                        });
                    },
                    serviceProviderResponse: function($stateParams, apiClient, user) {
                        var params = {
                            page: 1,
                            limit: 30
                        };
                        params = angular.extend({}, $stateParams, params);

                        return apiClient.get(user.getLink('serviceproviders'), params);
                    },
                    patrolResponse: function (apiClient, selectedSiteResponse, rootResourceResponse, $stateParams) {
                        var params = angular.copy($stateParams);
                        var selectedSiteResponseID = selectedSiteResponse ? [selectedSiteResponse.id] : null;
                        var siteIds = selectedSiteResponseID || $stateParams.siteIds;

                        if (siteIds && siteIds.length > 0) {
                            $stateParams.siteIds = siteIds;
                        }

                        params = angular.extend({}, params, {
                            'siteIds[]': siteIds,
                            'serviceProvider': $stateParams.serviceProvider ? JSON.parse($stateParams.serviceProvider).v : null,
                            name: $stateParams.patrolName,
                            status: 'active'
                        });

                        return apiClient.get(rootResourceResponse.getLink('plannerpatrolinstances'), params);
                    }
                },
                ncyBreadcrumb: {
                    label: '{{ ::"PATROL_DRAFT" | translate }}'
                }
            });
    }
    function registerRoutesPatrols($stateProvider) {

        $stateProvider.state('dashboard.user.patrols.list', {
            url: '/patrol-instances?page&limit&order&serviceProviderName&dateDue&patrolName&siteIds',
            parent: 'dashboard.user.patrols',
            views: {
                '@dashboard.user.patrols': {
                    templateUrl: '/modules/user/patrols/patrols/patrols-list.html',
                    controller: 'PatrolsListController',
                    controllerAs: 'vm'
                }
            },
            params: {
                page: '1',
                limit: '30',
                order: null,
                siteIds: {array: true},
                serviceProviderName: null,
                patrolName: null,
                dateDue: moment().startOf('day').format() + ',' + moment().add(30, 'days').endOf('day').format()
            },
            resolve: {
                siteCollectionResponse: function(apiClient, user, globalFilterParamsService) {
                    var params = {
                        datagroup: 'search'
                    };

                    params = angular.extend({}, params, globalFilterParamsService.getLocalStorageFilters());

                    return apiClient.get(user.getLink('sites'), params, 'long').then(function(response) {
                        return response || apiClient.noResourceFound();
                    });
                },
                patrolCollectionResponse: function(apiClient, user, $stateParams, selectedSiteResponse, base64) {
                    var params = $stateParams;

                    if (typeof $stateParams.siteIds === 'undefined') {
                        $stateParams.siteIds = [];
                    }

                    if (selectedSiteResponse) {
                        $stateParams.siteIds = [selectedSiteResponse.id];
                        $stateParams.selectedSiteResource = base64.encode(selectedSiteResponse.getLink('self'));
                    }

                    params = angular.extend({}, params, {
                        page: $stateParams.page,
                        limit: $stateParams.limit,
                        order: $stateParams.order,
                        serviceProviderName: $stateParams.serviceProviderName,
                        patrolName: $stateParams.patrolName,
                        dateDue: $stateParams.dateDue,
                        'siteIds[]': $stateParams.siteIds,
                        userId: $stateParams.userId,
                        region: $stateParams.region
                    });

                    return apiClient.get(user.getLink('patrol-instances'), params);
                }
            },
            ncyBreadcrumb: {
                label: '{{ ::"PATROLS" | translate }}'
            }
        })
            .state('dashboard.user.patrols.list.details', {
                url: '/details/{resource}',
                parent: 'dashboard.user.patrols.list',
                abstract: true,
                views: {
                    '@dashboard.user.patrols': {
                        templateUrl: '/modules/user/patrols/patrols/details/patrol-details.html',
                        controller: 'PatrolDetailsController',
                        controllerAs: 'vm'
                    }
                },
                resolve: {
                    patrolResponse: function(apiClient, $stateParams) {
                        return apiClient.get($stateParams.resource.decode(), {datagroup: 'details'}).then(function(response) {
                            return response || apiClient.noResourceFound();
                        });
                    }
                },
                ncyBreadcrumb: {
                    label: '{{ ::$resolves().patrolResponse.name }}'
                }
            })
            .state('dashboard.user.patrols.list.details.info', {
                url: '/info',
                parent: 'dashboard.user.patrols.list.details',
                views: {
                    '@dashboard.user.patrols.list.details': {
                        templateUrl: '/modules/user/patrols/patrols/details/info/patrol-info.html',
                        controller: 'PatrolInfoController',
                        controllerAs: 'vm'
                    }
                },
                ncyBreadcrumb: {
                    label: '{{ ::"INFO" | translate }}'
                }
            })
            .state('dashboard.user.patrols.list.details.patrol-points', {
                url: '/patrol-points?pointsPage&pointsLimit',
                parent: 'dashboard.user.patrols.list.details',
                views: {
                    '@dashboard.user.patrols.list.details': {
                        templateUrl: '/modules/user/patrols/patrols/details/points/patrol-points.html',
                        controller: 'PatrolPointsController',
                        controllerAs: 'vm'
                    }
                },
                params: {
                    pointsPage: '1',
                    pointsLimit: '10'
                },
                resolve: {
                    patrolPointsCollectionResponse: function(apiClient, patrolResponse, $stateParams) {
                        var params = {
                            page: $stateParams.pointsPage,
                            limit: $stateParams.pointsLimit
                        }

                        return apiClient.get(patrolResponse.getLink('patrolInstancesPoints'), params);
                    }
                },
                ncyBreadcrumb: {
                    label: '{{ ::"POINTS" | translate }}'
                }
            })
            .state('dashboard.user.patrol-statistics', {
                url: '/patrol-statistics?page&limit&order&serviceProvider&dateDue&patrolName&siteIds',
                parent: 'dashboard.user.patrols',
                views: {
                    '@dashboard.user.patrols': {
                        templateUrl: '/modules/user/patrols/patrol-statistics/statistics.html',
                        controller: 'PatrolStatisticsController',
                        controllerAs: 'vm'
                    }
                },
                params: {
                    dateDue: dateRangeMinus1Day,
                    patrolSiteId: null,
                    patrolName: null,
                    serviceProvider: null,
                    operative: null,
                    siteIds: {array: true}
                },
                resolve: {
                    patrolStatistics: function(apiClient, user, $stateParams, selectedSiteResponse, base64) {
                        var params = $stateParams;

                        if (typeof $stateParams.siteIds === 'undefined') {
                            $stateParams.siteIds = [];
                        }

                        if (selectedSiteResponse) {
                            $stateParams.siteIds = [selectedSiteResponse.id];
                            $stateParams.selectedSiteResource = base64.encode(selectedSiteResponse.getLink('self'));
                        }

                        params = angular.extend({}, params, {
                            'siteIds[]': $stateParams.siteIds
                        });

                        if (isNaN(params.site)) {
                            params.site = null;
                        }

                        if ($stateParams.serviceProvider){
                            params.serviceProvider = JSON.parse($stateParams.serviceProvider).v;
                        }

                        if ($stateParams.operative){
                            params.operative = JSON.parse($stateParams.operative).v;
                        }

                        return apiClient.get(user.getLink('patrol-statistics'), params);
                    },
                    siteCollectionResponse: function (apiClient, $stateParams, user, globalFilterParamsService) {
                        var params = {
                            datagroup: 'search'
                        };

                        params = angular.extend({}, params, globalFilterParamsService.getLocalStorageFilters());

                        return apiClient.get(user.getLink('sites'), params, 'long').then(function(response) {
                            return response || apiClient.noResourceFound();
                        });
                    },
                    serviceProviderResponse: function($stateParams, apiClient, user) {
                        return apiClient.get(user.getLink('serviceproviders'), $stateParams);
                    },
                    operativeCollectionResponse: function (rootResourceResponse, apiClient) {
                        if (rootResourceResponse.getLink('operatives')) {
                            return apiClient.get(rootResourceResponse.getLink('operatives')).then(function (response) {
                                return response;
                            });
                        } else {
                            return null;
                        }
                    }
                },
                ncyBreadcrumb: {
                    label: '{{ ::"PATROL_STATISTICS" | translate }}'
                }
            });
    }
})();
