/**
 * AuditService
 */
angular.module('elogbooksServices').service('auditService', function (apiClient, $q, $uibModal, messenger, lodash, $state) {

    this.activate = activateAudit;
    this.cancel = cancelAudit;
    this.start = startAudit;
    this.editReference = editReference;
    this.complete = completeAudit;
    this.auditAnswerFlashRaiseJobs = [];
    this.updatePoints = updatePoints;
    this.sendAnswer = sendAnswer;

    /**
     * Activate an Audit
     *
     * @param {object} audit - Audit entity
     * @returns {object} promise
     */
    function activateAudit(audit) {
        var deferred = $q.defer();

        var modalInstance = $uibModal.open({
            templateUrl: '/modules/common/audit/workflow/activate.html',
            controller: 'AuditActivateController',
            controllerAs: 'vm'
        });

        modalInstance.result.then(function () {
            apiClient.update(audit.getLink('activate'), {}).then(function (isSuccess) {
                if (isSuccess) {
                    return deferred.resolve();
                } else {
                    return deferred.reject();
                }
            });
        });

        return deferred.promise;
    }

    /*
     * Cancel an Audit
     *
     * @param {object} audit - Audit entity
     * @returns {object} promise
     */
    function cancelAudit(audit) {
        var deferred = $q.defer();

        var modalInstance = $uibModal.open({
            templateUrl: '/modules/common/audit/workflow/cancel.html',
            controller: 'AuditCancelController',
            controllerAs: 'vm',
        });

        modalInstance.result.then(function (data) {
            apiClient.update(audit.getLink('cancel'),data).then(function(isSuccess){
               if (isSuccess) {
                    return deferred.resolve();
                } else {
                    return deferred.reject();
                }
            });
        });

        return deferred.promise;
    }

    /**
     * Start an Audit
     *
     * @param {object} audit - Audit entity
     * @returns {object} promise
     */
    function startAudit(audit) {
        var deferred = $q.defer();
        var reference = audit.reference;
        var referencePermission = (audit.getLink('start'));

        var modalInstance = $uibModal.open({
            templateUrl: '/modules/common/audit/workflow/start.html',
            controller: 'AuditStartController',
            controllerAs: 'vm',
            resolve: {
                reference: function () {
                    return reference;
                },
                referencePermission: function() {
                    return referencePermission;
                }
            }
        });

        modalInstance.result.then(function (data) {
            apiClient.update(audit.getLink('start'),data).then(function(isSuccess){
               if (isSuccess) {
                    return deferred.resolve();
                } else {
                    return deferred.reject();
                }
            });
        });

        return deferred.promise;
    }

    /**
     * Edit Audit Reference
     *
     * @param {object} audit - Audit entity
     * @returns {object} promise
     */
    function editReference(audit) {
        var deferred = $q.defer();
        var reference = audit.reference;

        var modalInstance = $uibModal.open({
            templateUrl: '/modules/common/audit/workflow/edit-reference.html',
            controller: 'AuditEditReferenceController',
            controllerAs: 'vm',
            resolve: {
                reference: function () {
                    return reference;
                }
            }
        });

        modalInstance.result.then(function (data) {
            apiClient.update(audit.getLink('reference'), data).then(function(isSuccess) {
                if (isSuccess) {
                    return deferred.resolve();
                } else {
                    return deferred.reject();
                }
            });
        });

        return deferred.promise;
    }

    /**
     * Complete an Audit
     *
     * @param {object} audit - Audit entity
     * @returns {object} promise
     */
    function completeAudit(audit) {

        var deferred = $q.defer();
        var answersComplete = ((audit.answeredQuestionCount === audit.questionCount) ? true : false);

        var modalInstance = $uibModal.open({
            templateUrl: '/modules/common/audit/workflow/complete.html',
            controller: 'AuditCompleteController',
            controllerAs: 'vm',
            resolve: {
                answersComplete: function () {
                    return answersComplete;
                }
            }
        });

        modalInstance.result.then(function (data) {
            apiClient.update(audit.getLink('complete'),data).then(function(isSuccess){
               if (isSuccess) {
                    return deferred.resolve();
                } else {
                    return deferred.reject();
                }
            });
        });

        return deferred.promise;
    }
    /**
     * Calculate answer option points
     *
     * @param {object} answerOptions - answerOption
     * @returns true
     */
    function updatePoints(answerOptions) {

        var $pointValues = [100];

        switch(answerOptions.length) {
            case 2:
                $pointValues = [0,100];
                break;
            case 3:
                $pointValues = [0,50,100];
                break;
            case 4:
                $pointValues = [0,33,66,100];
                break;
            case 5:
                $pointValues = [0,25,50,75,100];
                break;
            default:
                $pointValues = [100];
                break;
        }

        angular.forEach(answerOptions, function(value, key) {
           answerOptions[key].pointsValue = $pointValues[key];
        });

        return true;
    }

    /**
     * send answer to audit question
     *
     * @param question
     */
    function sendAnswer(question) {
        if (question.answer.comment && question.answer.comment.length > 1000) {
            messenger.error('COMMENT_1000_OR_LESS');
            return false;
        }

        question = angular.copy(question);

        // pass answer resource only to api
        if (lodash.has(question.answer, '_links.answerOption._links.self')) {
            question.answer._links.answerOption = question.answer._links.answerOption._links.self;
        }

        // Only send resource to API
        if (question.answer.assistedComment) {
            question.answer._links.assistedComment = question.answer.assistedComment._links.self;
        }

        // auditanswer listener will create an action
        var files = [];
        question.answer.files.forEach(function (file) {
            if (typeof file._links !== 'undefined') {
                // existing file
                files.push(file._links.resource);
            } else {
                // new file
                files.push(file);
            }
        });

        question.answer.files = {
            links: files
        };

        apiClient.create(question.getLink('createanswer'), question.answer).then(function (response) {
            if (response) {
                $state.go('.', {}, {reload: $state.get('^.^')}, false).then(function () {
                    if (response.score < 100 && question.forceRaise === 1) {
                        this.auditAnswerFlashRaiseJobs.push(question.getLink('self'));
                    }
                    messenger.success('QUESTION_ANSWERED');
                });
            } else {
                messenger.error('REQUEST_ERROR');
            }
        });
    }
});
