(function () {
    'use strict';

    angular
        .module('elogbooks.common.labour-lines')
        .controller('CommonLabourLinesListController', CommonLabourLinesListController);

    CommonLabourLinesListController.$inject = ['jobLabourLinesCollectionResponse', 'modulesService'];

    function CommonLabourLinesListController (jobLabourLinesCollectionResponse, modulesService) {
        var vm = this;
        vm.jobLabourLinesCollectionResponse = jobLabourLinesCollectionResponse;
        vm.financeEnabled = modulesService.isEnabled('finance');
    }
})();
