(function() {
    'use strict';

    angular
        .module('elogbooks')
        .config(Translations);

    function Translations($translateProvider) {
        $translateProvider.translations('zh-hk', {
            USER_PERMISSIONS: '用戶權限',
            USER_PERMISSION_MANAGE_ASSET_TYPES: '管理資產類別',
            USER_PERMISSION_MANAGE_ASSOCIATE_TYPES: '管理關聯類別',
            USER_PERMISSION_MANAGE_CERTIFICATE_TYPES: '管理證書類別',
            USER_PERMISSION_MANAGE_CONTRACT_TYPES: '管理合約類別',
            USER_PERMISSION_MANAGE_JOB_REQUISITES: '管理工作要求',
            USER_PERMISSION_MANAGE_JOB_GROUPS: '管理工作組別',
            USER_PERMISSION_MANAGE_LETTERS: '管理信件',
            USER_PERMISSION_MANAGE_METER_INFO: '管理儀表信息',
            USER_PERMISSION_MANAGE_PRIORITIES: '管理優先級別',
            USER_PERMISSION_MANAGE_REGIONS: '管理地區',
            USER_PERMISSION_MANAGE_ROLES: '管理角色',
            USER_PERMISSION_MANAGE_SATISFACTION_SURVEYS: '管理滿意度調查',
            USER_PERMISSION_MANAGE_SECTORS: '管理部門',
            USER_PERMISSION_MANAGE_SERVICE_PROVIDERS: '管理服務供應商',
            USER_PERMISSION_MANAGE_SERVICE_PROVIDER_GROUPS: '管理服務供應商組別',
            USER_PERMISSION_MANAGE_SERVICE_ROUTINES: '管理服務程序',
            USER_PERMISSION_MANAGE_SETTINGS: '管理設置',
            USER_PERMISSION_MANAGE_SITES: '管理場地',
            USER_PERMISSION_MANAGE_SITE_GROUPS: '管理場地組別',
            USER_PERMISSION_MANAGE_SITE_NOTE_TYPES: '管理場地記錄類別',
            USER_PERMISSION_MANAGE_SITE_RELATIONSHIPS: '管理場地關係',
            USER_PERMISSION_MANAGE_SURVEYS: '管理調查',
            USER_PERMISSION_MANAGE_TAGS: '管理標籤',
            USER_PERMISSION_MANAGE_TRADE_TYPES: '管理貿易類別',
            USER_PERMISSION_MANAGE_USERS: '管理用戶',
            USER_PERMISSION_MANAGE_DUTY_MANAGEMENT_SCHEDULES: '管理工作時間表',
            USER_PERMISSION_MANAGE_MASTER_TASKS: '管理主要工作',
            USER_PERMISSION_VIEW_ASSET_TYPES: '查看資產類別',
            USER_PERMISSION_VIEW_ASSOCIATE_TYPES: '查看關聯類別',
            USER_PERMISSION_VIEW_CERTIFICATE_TYPES: '查看證書類別',
            USER_PERMISSION_VIEW_CONTRACT_TYPES: '查看合約類別',
            USER_PERMISSION_VIEW_JOB_REQUISITES: '查看工作要求',
            USER_PERMISSION_VIEW_JOB_GROUPS: '查看工作組別',
            USER_PERMISSION_VIEW_LETTERS: '查看信件',
            USER_PERMISSION_VIEW_METER_INFO: '查看儀表信息',
            USER_PERMISSION_VIEW_PRIORITIES: '查看優先級別',
            USER_PERMISSION_VIEW_REGIONS: '查看地區',
            USER_PERMISSION_VIEW_ROLES: '查看角色',
            USER_PERMISSION_VIEW_SATISFACTION_SURVEYS: '查看滿意度調查',
            USER_PERMISSION_VIEW_SECTORS: '查看部門',
            USER_PERMISSION_VIEW_SERVICE_PROVIDERS: '查看服務供應商',
            USER_PERMISSION_VIEW_SERVICE_PROVIDER_GROUPS: '查看服務供應商組別',
            USER_PERMISSION_VIEW_SERVICE_ROUTINES: '查看服務程序',
            USER_PERMISSION_VIEW_SETTINGS: '查看設置',
            USER_PERMISSION_VIEW_SITES: '查看場地',
            USER_PERMISSION_VIEW_SITE_GROUPS: '查看場地組別',
            USER_PERMISSION_VIEW_SITE_NOTE_TYPES: '查看場地記錄類別',
            USER_PERMISSION_VIEW_SURVEYS: '查看調查',
            USER_PERMISSION_VIEW_TAGS: '查看標籤',
            USER_PERMISSION_VIEW_TRADE_TYPES: '查看貿易類別',
            USER_PERMISSION_VIEW_USERS: '查看用戶',
            USER_PERMISSION_VIEW_DUTY_MANAGEMENT_SCHEDULES: '查看工作時間表',
            USER_PERMISSION_CAN_RECEIVE_SUPPORT_MESSAGES: '能夠接收支援訊息',
            USER_PERMISSION_CAN_CREATE_GLOBAL_AUDIT_TEMPLATES:	'能夠建立全球審計樣板',
            USER_PERMISSION_ACCESS_SERVICE_PROVIDER_APP: '接駁服務供應商應用程序',
            USER_PERMISSION_ACCESS_CLIENT_APP: '接駁客戶應用程序',
            USER_PERMISSION_MANAGE_SERVICE_TYPES: '管理服務類別',
            USER_PERMISSION_VIEW_SERVICE_TYPES: '查看服務類別',
            USER_PERMISSION_MANAGE_IMPORTS: '管理匯入',
            USER_PERMISSION_MANAGE_EXPORTS: '管理匯出',

            USER_PERMISSION_MANAGE_ACTION_TYPES: '管理執行類別',

            USER_PERMISSION_VIEW_ACTION_TYPES: '查看執行類別',
            
            USER_PERMISSION_MANAGE_METER_INFO_DETAIL: ' ',
            USER_PERMISSION_VIEW_METER_INFO_DETAIL: ' ',
            USER_PERMISSION_CAN_CREATE_GLOBAL_AUDIT_TEMPLATES_DETAIL: ' ',
            USER_PERMISSION_ACCESS_SERVICE_PROVIDER_APP_DETAIL: ' ',
            USER_PERMISSION_ACCESS_CLIENT_APP_DETAIL: ' ',

            USER_PERMISSION_MANAGE_ACTION_TYPES_DETAIL: '能夠建立，更新和刪除執行類別',
            USER_PERMISSION_MANAGE_ASSET_TYPES_DETAIL: '能夠建立，更新和刪除資產類別',
            USER_PERMISSION_MANAGE_ASSOCIATE_TYPES_DETAIL: '能夠建立，更新和刪除關聯類別',
            USER_PERMISSION_MANAGE_CERTIFICATE_TYPES_DETAIL: '能夠建立，更新和刪除證書類別',
            USER_PERMISSION_MANAGE_CONTRACT_TYPES_DETAIL: '能夠建立，更新和刪除合約類別',
            USER_PERMISSION_MANAGE_JOB_REQUISITES_DETAIL: '能夠建立，更新和刪除工作要求',
            USER_PERMISSION_MANAGE_JOB_GROUPS_DETAIL: '能夠建立，更新和刪除工作組別',
            USER_PERMISSION_MANAGE_LETTERS_DETAIL: '能夠建立，更新和刪除信件樣版',
            USER_PERMISSION_MANAGE_METERINFO_DETAIL: '能夠建立，更新和刪除儀表製造商，儀表供應商，儀表供應類別和儀表類別',
            USER_PERMISSION_MANAGE_PRIORITIES_DETAIL: '能夠建立，更新和刪除系統優先級別',
            USER_PERMISSION_MANAGE_REGIONS_DETAIL: '能夠建立，更新和刪除地區',
            USER_PERMISSION_MANAGE_ROLES_DETAIL: '能夠建立，更新和刪除角色',
            USER_PERMISSION_MANAGE_SATISFACTION_SURVEYS_DETAIL: '能夠建立，更新和刪除滿意度調查問題',
            USER_PERMISSION_MANAGE_SECTORS_DETAIL: '能夠建立，更新和刪除部門',
            USER_PERMISSION_MANAGE_SERVICE_PROVIDERS_DETAIL: '能夠建立，更新和刪除服務供應商',
            USER_PERMISSION_MANAGE_SERVICE_PROVIDER_GROUPS_DETAIL: '能夠建立，更新和刪除服務供應商組別',
            USER_PERMISSION_MANAGE_SERVICE_ROUTINES_DETAIL: '能夠建立，更新和刪除系統服務程序',
            USER_PERMISSION_MANAGE_SETTINGS_DETAIL: '能夠建立，更新和刪除系統設定',
            USER_PERMISSION_MANAGE_SITES_DETAIL: '能夠建立，更新和刪除場地',
            USER_PERMISSION_MANAGE_SITE_GROUPS_DETAIL: '能夠建立，更新和刪除場地組別',
            USER_PERMISSION_MANAGE_SITE_NOTE_TYPES_DETAIL: '能夠建立，更新和刪除場地記錄類別',
            USER_PERMISSION_MANAGE_SITE_RELATIONSHIPS_DETAIL: '能夠建立，更新和刪除場地關係',
            USER_PERMISSION_MANAGE_SURVEYS_DETAIL: '能夠建立，更新和刪除KPI調查',
            USER_PERMISSION_MANAGE_TAGS_DETAIL: '能夠建立，更新和刪除標籤',
            USER_PERMISSION_MANAGE_TRADE_TYPES_DETAIL: '能夠建立，更新和刪除貿易類別',
            USER_PERMISSION_MANAGE_USERS_DETAIL: '能夠建立，更新和刪除用戶',
            USER_PERMISSION_MANAGE_DUTY_MANAGEMENT_SCHEDULES_DETAIL: '能夠管理系統工作時間表',
            USER_PERMISSION_MANAGE_MASTER_TASKS_DETAIL: '能夠建立，更新和刪除主要工作',

            USER_PERMISSION_VIEW_ACTION_TYPES_DETAIL: '能夠查看執行類別',
            USER_PERMISSION_VIEW_ASSET_TYPES_DETAIL: '能夠查看資產類別',
            USER_PERMISSION_VIEW_ASSOCIATE_TYPES_DETAIL: '能夠查看關聯類別',
            USER_PERMISSION_VIEW_CERTIFICATE_TYPES_DETAIL: '能夠查看證書類別',
            USER_PERMISSION_VIEW_CONTRACT_TYPES_DETAIL: '能夠查看合約類別',
            USER_PERMISSION_VIEW_JOB_REQUISITES_DETAIL: '能夠查看工作要求',
            USER_PERMISSION_VIEW_JOB_GROUPS_DETAIL: '能夠查看工作組別',
            USER_PERMISSION_VIEW_LETTERS_DETAIL: '能夠查看信件樣版',
            USER_PERMISSION_VIEW_METERINFO_DETAIL: '能夠查看儀表製造商，儀表供應商，儀表供應類別和儀表類別',
            USER_PERMISSION_VIEW_PRIORITIES_DETAIL: '能夠查看系統優先級別',
            USER_PERMISSION_VIEW_REGIONS_DETAIL: '能夠查看地區',
            USER_PERMISSION_VIEW_ROLES_DETAIL: '能夠查看角色',
            USER_PERMISSION_VIEW_SATISFACTION_SURVEYS_DETAIL: '能夠查看滿意度調查問題',
            USER_PERMISSION_VIEW_SECTORS_DETAIL: '能夠查看部門',
            USER_PERMISSION_VIEW_SERVICE_PROVIDERS_DETAIL: '能夠查看服務供應商',
            USER_PERMISSION_VIEW_SERVICE_PROVIDER_GROUPS_DETAIL: '能夠查看服務供應商組別',
            USER_PERMISSION_VIEW_SERVICE_ROUTINES_DETAIL: '能夠查看系統服務程序',
            USER_PERMISSION_VIEW_SETTINGS_DETAIL: '能夠查看系統設定',
            USER_PERMISSION_VIEW_SITES_DETAIL: '能夠查看場地',
            USER_PERMISSION_VIEW_SITE_GROUPS_DETAIL: '能夠查看場地組別',
            USER_PERMISSION_VIEW_SITE_NOTE_TYPES_DETAIL: '能夠查看場地記錄類別',
            USER_PERMISSION_VIEW_SURVEYS_DETAIL: '能夠查看KPI調查',
            USER_PERMISSION_VIEW_TAGS_DETAIL: '能夠查看標籤',
            USER_PERMISSION_VIEW_TRADE_TYPES_DETAIL: '能夠查看貿易類別',
            USER_PERMISSION_VIEW_USERS_DETAIL: '能夠查看用戶',
            USER_PERMISSION_VIEW_DUTY_MANAGEMENT_SCHEDULES_DETAIL: '能夠查看系統工作時間表',
            USER_PERMISSION_CAN_RECEIVE_SUPPORT_MESSAGES_DETAIL: '能夠查看及回應支援訊息',

            USER_PERMISSION_MANAGE_SERVICE_TYPES_DETAIL: '能夠建立，更新和刪除服務類別',
            USER_PERMISSION_VIEW_SERVICE_TYPES_DETAIL: '能夠查看服務類別',
            USER_PERMISSION_MANAGE_IMPORTS_DETAIL: '能夠匯入資料',
            USER_PERMISSION_MANAGE_EXPORTS_DETAIL: '能夠匯出資料'
        });
    }
})();
