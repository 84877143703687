(function() {
    'use strict';

    angular
        .module('elogbooks.common.assets')
        .controller('UserAssetsServiceRoutinesEditController', UserAssetsServiceRoutinesEditController);

    UserAssetsServiceRoutinesEditController.$inject = [
        'assetServiceRoutinesCollectionResponse',
        'nonVersionedServiceRoutinesCollectionResponse',
        'versionedServiceRoutinesCollectionResponse',
        'serviceRoutineService',
        'requestDataFactory',
        'assetResponse',
        'lodash',
        'apiClient',
        '$state',
        'messenger'
    ];

    function UserAssetsServiceRoutinesEditController (
        assetServiceRoutinesCollectionResponse,
        nonVersionedServiceRoutinesCollectionResponse,
        versionedServiceRoutinesCollectionResponse,
        serviceRoutineService,
        requestDataFactory,
        assetResponse,
        lodash,
        apiClient,
        $state,
        messenger
    ) {
        var vm = this;
        vm.selectedServiceRoutines = [];
        vm.selectedModel = {};
        vm.items = [];
        vm.data = requestDataFactory.createRequest(assetResponse);
        vm.data.serviceRoutineType = 'none';
        vm.removeItem = removeItem;
        vm.update = updateAction;

        // preload SR list with only active
        angular.forEach(assetServiceRoutinesCollectionResponse.routines, function(value) {
            if (value.active) {
                vm.selectedServiceRoutines.push(value);
            }
        });

        //remove SRs that are already on the asset so that drop down doesn't show duplicates
        nonVersionedServiceRoutinesCollectionResponse.routines = lodash.differenceBy(nonVersionedServiceRoutinesCollectionResponse.routines, assetServiceRoutinesCollectionResponse.routines, 'id');
        versionedServiceRoutinesCollectionResponse.routines = lodash.differenceBy(versionedServiceRoutinesCollectionResponse.routines, assetServiceRoutinesCollectionResponse.routines, 'id');

        vm.selectedNonVersionedServiceRoutineModel = {
            response: nonVersionedServiceRoutinesCollectionResponse,
            link: nonVersionedServiceRoutinesCollectionResponse.getLink('self'),
            linkParams: {active: 1, isVersioned: 0},
            required: false,
            responseKeyPath: 'routines',
            searchKey: 'nameReference',
            formatValue: serviceRoutineService.formatServiceRoutineValue,
            show: nonVersionedServiceRoutinesCollectionResponse.routines.length === 0 ? false : true,
            onSelect: function () {changeItem()},
            onRemove: function () {changeItem()}
        };

        vm.selectedVersionedServiceRoutineModel = {
            response: versionedServiceRoutinesCollectionResponse,
            link: versionedServiceRoutinesCollectionResponse.getLink('self'),
            linkParams: {active: 1, isVersioned: 1},
            required: false,
            responseKeyPath: 'routines',
            searchKey: 'nameReference',
            formatValue: serviceRoutineService.formatServiceRoutineValue,
            show: versionedServiceRoutinesCollectionResponse.routines.length === 0 ? false : true,
            onSelect: function () {changeItem()},
            onRemove: function () {changeItem()}
        };

        vm.selectedServiceRoutineOptions = [
            {label: 'NONE_SELECTED', type: 'none' },
            {label: 'NON_SFG20', type: 'non_versioned'},
            {label: 'SFG20', type: 'versioned'}
        ];

        function changeItem() {
            let response;
            vm.items = vm.selectedServiceRoutines;
            if (vm.data.serviceRoutineType === 'non_versioned') {
                vm.selectedModel = vm.selectedNonVersionedServiceRoutineModel;
                response = nonVersionedServiceRoutinesCollectionResponse;
            } else if (vm.data.serviceRoutineType === 'versioned') {
                vm.selectedModel = vm.selectedVersionedServiceRoutineModel;
                response = versionedServiceRoutinesCollectionResponse;
            }

            vm.selectedModel.selectedItems = lodash.unionBy(vm.selectedModel.selectedItems, [vm.selectedModel.selected]);
            vm.selectedModel.items = lodash.differenceBy(vm.selectedModel.items, vm.selectedModel.selectedItems, 'href');

            if (vm.selectedModel.items.length === 0 && response.pages === 1) {
                vm.selectedModel.show = false;
            }

            if (vm.selectedModel && vm.selectedModel.selected && vm.selectedModel.selected.object) {
                var isAlreadySelected = false;
                angular.forEach(vm.items, function(value, index) {
                    if (value.getLink('self') === vm.selectedModel.selected.object.getLink('self')) {
                        isAlreadySelected = true;
                    }
                });

                if (! isAlreadySelected) {
                    vm.items.push(vm.selectedModel.selected.object);
                }

                vm.selectedModel.selected = null;
            }
        }

        function removeItem(item) {
            vm.items = vm.selectedServiceRoutines;
            if (!item.serviceRoutineVersionNumber) {
                vm.selectedModel = vm.selectedNonVersionedServiceRoutineModel;
            } else {
                vm.selectedModel = vm.selectedVersionedServiceRoutineModel;
            }

            vm.selectedModel.show = true;
            vm.selectedModel.selectedItems = lodash.differenceBy(vm.selectedModel.selectedItems, [item], 'id');
            vm.selectedModel.items = lodash.unionBy(vm.selectedModel.items, [{object: item, id: item.id}], 'id');
            vm.selectedModel.items = lodash.orderBy(vm.selectedModel.items, ['id'], ['asc']);

            angular.forEach(vm.items, function(value, index) {
                if (value._links.self.href === item._links.self.href) {
                    vm.items.splice(index, 1);
                }
            });
        }

        function prepareData() {
            vm.data._links = {};

            vm.data.serviceRoutines = {
                links: []
            };

            angular.forEach(vm.selectedServiceRoutines, function(value) {
                vm.data.serviceRoutines.links.push({
                    href: value.getLink('self')
                });
            });
        }

        function updateAction() {
            prepareData();

            apiClient.update(assetResponse.getLink('edit'), vm.data).then(function(response) {
                if (response) {
                    $state.go('^', {}, {reload: $state.get('^.^.^')}).then(function() {
                        messenger.success('ASSET_UPDATED');
                    });
                } else {
                    messenger.error('REQUEST_ERROR');

                    //In case of some SRs not deleted, reload them to show changes
                    apiClient.get(assetResponse.getLink('serviceroutines')).then(function(response) {
                        vm.selectedServiceRoutines = [];
                        angular.forEach(response.routines, function(value) {
                            vm.selectedServiceRoutines.push(value);
                        });
                    });
                }
            });
        }
    }
})();
