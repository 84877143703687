(function () {
    'use strict';

    angular
        .module('elogbooks.admin.surveys')
        .controller('SurveySitesController', SurveySitesController);

    SurveySitesController.$inject = [
        'surveyResponse',
        'SurveySitesCollectionResponse',
        'lodash', 'confirmationModal',
        'apiClient',
        '$state',
        'messenger',
        'surveyVersionsResponse'
    ];

    function SurveySitesController (
        surveyResponse,
        SurveySitesCollectionResponse,
        lodash,
        confirmationModal,
        apiClient,
        $state,
        messenger,
        surveyVersionsResponse)
    {
        var vm = this;
        vm.survey = surveyResponse;
        vm.surveyVersionsResponse = surveyVersionsResponse;
        vm.surveySites = SurveySitesCollectionResponse.sites;
        vm.survey.sites = vm.surveySites;
        vm.surveySitesResponse = SurveySitesCollectionResponse;
        vm.checked = {};

        vm.deleteSelected = deleteAction;
        vm.selectedVersion = vm.survey;

        function deleteAction() {
            var toRemove = [];
            lodash.forEach(vm.checked, function(value, site) {
                if(value === true) {
                    toRemove.push({"href": site});
                }
            });

            if (toRemove.length > 0) {
                var data = {
                    _links: {}
                };

                data._links.site = toRemove;

                // Show an alert asking the user to confirm
                return confirmationModal.open().result.then(function () {
                    return apiClient.update(vm.survey.getLink('sites'), data).then(function (response) {
                        if (response) {
                            $state.go('.', {}, { reload: $state.current.name }).then(function () {
                                messenger.success('SITES_DELETED_FROM_SURVEY');
                            });
                        } else {
                            messenger.error('REQUEST_ERROR');
                        }
                    });
                });
            } else {
                // Show an alert telling the user they need to select at least one site
                return confirmationModal.open({
                    titleMessage: 'ZERO_OPTIONS_CHECKED_TITLE',
                    bodyMessage: 'ZERO_OPTIONS_CHECKED_MESSAGE',
                    primaryText: null,
                    secondaryText: 'OK'
                });
            }
        }
    }
})();
