(function () {
    'use strict';

    angular
        .module('elogbooks.user.finance')
        .config(registerRoutes)
        .config(registerRoutesList)
        .config(registerRoutesDetails)
        .config(registerRouteItemsTab)
        .config(registerWorkflow);

    function registerRoutes($stateProvider) {
        $stateProvider
            .state('dashboard.user.finance.sales-invoices', {
                abstract: true,
                parent: 'dashboard.user.finance',
                templateUrl: 'shared-templates/view.html',
                ncyBreadcrumb: {
                    skip: true,
                }
            });
    }

    function registerRoutesList($stateProvider) {
        var salesInvoiceParams = {
            id: null,
            invoiceDateRange: null,
            supplier: null,
            status: {array: true},
            salesInvoicePage: '1',
            salesInvoiceLimit: '30',
            salesInvoiceOrder: 'id',
            billingSettingsRelation: null,
        };

        $stateProvider
            .state('dashboard.user.finance.sales-invoices.list', {
                url: '/sales-invoices?' + Object.keys(salesInvoiceParams).join('&'),
                parent: 'dashboard.user.finance.sales-invoices',
                resolve: {
                    salesInvoicesCollectionResponse: function (apiClient, rootResourceResponse, $stateParams) {
                        var params = angular.copy($stateParams);
                        params = angular.extend({}, params, {
                            page:   $stateParams.salesInvoicePage,
                            order:  $stateParams.salesInvoiceOrder,
                            limit:  $stateParams.salesInvoiceLimit,
                            'status[]': $stateParams.status,
                            type: 'sales',
                            billingSettingsRelation: $stateParams.billingSettingsRelation,
                        });

                        return apiClient.get(rootResourceResponse.getLink('sales-invoices'), params).then( function(response) {
                            return response || apiClient.noResourceFound();
                        });
                    }
                },
                params: salesInvoiceParams,
                views: {
                    '@dashboard.user.finance.sales-invoices': {
                        templateUrl: '/modules/user/finance/sales-invoices/sales-invoices-list.html',
                        controller: 'FinanceSalesInvoicesController',
                        controllerAs: 'vm'
                    }
                },
                ncyBreadcrumb: {
                    label: '{{ ::"SALES_INVOICES_LIST" | translate }}'
                }
            });
    }

    function registerRoutesDetails($stateProvider) {
        $stateProvider
            .state('dashboard.user.finance.sales-invoices.list.details', {
                url: '/details/{salesInvoicesResource}',
                abstract: true,
                parent: 'dashboard.user.finance.sales-invoices.list',
                views: {
                    // Main frame
                    '@dashboard.user.finance.sales-invoices': {
                        templateUrl: '/modules/user/finance/sales-invoices/details/sales-invoices-details.html',
                        controller: 'FinanceSalesInvoicesDetailsController',
                        controllerAs: 'vm'
                    },

                    // Details - left part
                    'details.info@dashboard.user.finance.sales-invoices.list.details': {
                        templateUrl: '/modules/user/finance/sales-invoices/details/details/sales-invoices-details-info.html',
                        controller: 'FinanceSalesInvoicesDetailsInfoController',
                        controllerAs: 'vm'
                    },

                    // Status bar
                    'status@dashboard.user.finance.sales-invoices.list.details': {
                        templateUrl: '/modules/user/finance/sales-invoices/details/status/sales-invoices-status.html',
                        controller: 'FinanceSalesInvoicesStatusController',
                        controllerAs: 'vm'
                    },

                    // Audit Trail
                    'feed@dashboard.user.finance.sales-invoices.list.details': {
                        templateUrl: '/modules/common/finance/invoice/details/event/feed.html',
                        controller: 'FinanceInvoiceEventFeedController',
                        controllerAs: 'vm'
                    },

                    //Work Flow
                    'workflow@dashboard.user.finance.sales-invoices.list.details': {
                        templateUrl: '/modules/common/finance/invoice/details/workflow/workflow.html',
                        controller: 'FinanceInvoiceWorkflowController',
                        controllerAs: 'vm'
                    }
                },
                resolve: {
                    salesInvoiceResponse: function(apiClient, $stateParams) {
                        return apiClient.get($stateParams.salesInvoicesResource.decode()).then( function(response) {
                            return response || apiClient.noResourceFound();
                        });
                    },
                    invoiceResponse: function (salesInvoiceResponse) {
                        return salesInvoiceResponse
                    },
                    eventCollectionResponse: function(apiClient, salesInvoiceResponse) {
                        return apiClient.get(salesInvoiceResponse.getLink('events'), { datagroup: 'details' }).then(function(response) {
                            return response || apiClient.noResourceFound();
                        })
                    },
                    messageCollectionResponse: function(apiClient, salesInvoiceResponse) {
                        return apiClient.get(salesInvoiceResponse.getLink('messages'), { datagroup: 'details' }).then(function(response) {
                            return response || apiClient.noResourceFound();
                        });
                    },
                    config: function() {
                        return {
                            type: 'SALES_INVOICE'
                        };
                    }
                },
                ncyBreadcrumb: {
                    label: '{{ ::$resolves().salesInvoiceResponse.id }}'
                }
            });
    }

    function registerRouteItemsTab($stateProvider) {
        $stateProvider
            .state('dashboard.user.finance.sales-invoices.list.details.items', {
                url: '/items',
                parent: 'dashboard.user.finance.sales-invoices.list.details',
                views: parseBook({
                    'items@dashboard.user.finance.sales-invoices.list.details': {
                        templateUrl: '/modules/common/finance/item/items-list.html',
                        controller: 'CommonFinanceItemListController',
                        controllerAs: 'vm'
                    }
                }),
                resolve: {
                    itemLinesCollectionResponse: function(apiClient, salesInvoiceResponse) {
                        if (salesInvoiceResponse.getLink('invoice-lines')) {
                            return apiClient.get(salesInvoiceResponse.getLink('invoice-lines'), {type: 'sales'}).then(function(response) {
                                return response || apiClient.noResourceFound();
                            })
                        }
                    },
                    itemResponse: function (apiClient, salesInvoiceResponse) {
                        return salesInvoiceResponse || apiClient.noResourceFound();
                    },
                    vatRateCollectionResponse: function (apiClient, rootResourceResponse) {
                        if (rootResourceResponse.getLink('vat-rates')) {
                            return apiClient.get(rootResourceResponse.getLink('vat-rates'), {
                                active: true,
                                limit: 999999
                            });
                        }
                    },
                },
                ncyBreadcrumb: {
                    label: '{{ ::"ITEMS" | translate }}'
                }
            })
            .state('dashboard.user.finance.sales-invoices.list.details.items.add', {
                url: '/add',
                parent: 'dashboard.user.finance.sales-invoices.list.details.items',
                views: parseBook({
                    'items@dashboard.user.finance.sales-invoices.list.details': {
                        templateUrl: '/modules/common/finance/item/add-edit/items-add-edit.html',
                        controller: 'CommonFinanceItemListAddEditController',
                        controllerAs: 'vm'
                    }
                }),
                ncyBreadcrumb: {
                    label: '{{ ::"EDIT" | translate }}'
                }
            });
    }

    function registerWorkflow($stateProvider)
    {
        var workflowRoute = 'dashboard.user.finance.sales-invoices.list.details.workflow';

        $stateProvider
            .state(workflowRoute, {
                url: '/workflow',
                abstract: true,
                parent: 'dashboard.user.finance.sales-invoices.list.details',
                views: {
                    '@dashboard.user.finance': {
                        templateUrl: '/modules/common/finance/invoice/details/workflow/workflow-add-note/workflow-add-note.html',
                        controller: 'FinanceInvoiceWorkflowAddNoteController',
                        controllerAs: 'vm',
                    },
                },
                resolve: {
                    invoiceResponse : function(salesInvoiceResponse) {
                        return salesInvoiceResponse;
                    }
                },
                ncyBreadcrumb: {
                    label: '{{ ::"WORKFLOW" | translate }}'
                }
            });

        // Register workflows routing actions
        ['mark-as-invoiced', 'mark-as-paid', 'reopen' ].map(function(type) {
            $stateProvider
                .state(workflowRoute + '.' + type, {
                    url: '/' + type,
                    abstract: false,
                    parent: workflowRoute,
                    resolve: {
                        config : function(config) {
                            return angular.extend(config, {
                                reopen: {
                                    noteRequired: true
                                }
                            });
                        }
                    },
                    ncyBreadcrumb: {
                        label: '{{ ::"' + type.replaceAll('-', '_') + '" | uppercase | translate }}'
                    }
                });
        });
    }
})();
