(function() {
    'use strict';

    angular
        .module('elogbooks')
        .config(Translations);

    function Translations($translateProvider) {
        $translateProvider.translations('nl', {
            ESCALATION_STAGE: 'Escalatie Stadium',
            ESCALATION_STAGES: 'Escalatie Stadia',
            ESCALATION_STAGE_LIST: 'Escalatie Stadia',

            ESCALATION_STAGE_ADD: 'Voeg een nieuw Escalatie Stadium toe',
            ESCALATION_STAGE_EDIT: 'Wijzig Escalatie Stadium',

            ESCALATION_STAGE_CREATED: 'Escalatie stadium aangemaakt',
            ESCALATION_STAGE_UPDATED: 'Escalatie stadium gewijzigd',
            ESCALATION_STAGE_DELETED: 'Escalatie stadium verwijderd',
        });
    }
})();

app.config(function ($translateProvider) {
    $translateProvider.translations('nl', {
        STAGE: 'Stadium',
        EMAIL_CONTENT: 'E-mail inhoud',
        PRIMARY_CONTACT: 'Eerste Contact',
        ASSOCIATE_TYPES: 'Geassocieerde Types',
        ASSOCIATE_TYPES_SELECT: 'Selecteer geassocieerde Types',
        OTHER_CONTACTS: 'Overige contacten',
        OTHER_CONTACTS_SELECT: 'Selecteer overige contacten',
        REVIEW_AT: 'Herzien op',
    });
});
