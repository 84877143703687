/**
 * Usage:
 * It needs:
 * - an object with fieldProperties, specifying mandatory:
 *     type (no-edit type will be disabled and cannot be edited), label, variableName and model.
 *     Others attributes can be omitted like maxLength
 * - collectionResponse
 * - entityName
 * - Message when submit, edit and delete
 * - Max Items to display
 * - Total to show at the bottom. This field needs to be optional in the future
 *
 * If you have a calculatedField then you need to add:
 * -calculatedField: true
 * -fields: array with the fields involved on the calculation
 * -operation: what to do with the fields like sum, multiply etc
 *
 * type "no-edit" is only for show, it won't be part of the request
 *
 * This directive uses 2 arrays, one for the existing items and other for the new ones created pressing "add item" button
 *
 *                         var data = {
                            _links: {},
                            data: [], // New edited data
                            newRowsData: [] // Rows added manually
                        };
 * Send to the backend the links (data._links) for editing existing ones along with their new data (data.data)
 * For adding new one send data.newRowsData.
 *
 * If we specify a "model" means we manage the submit function in the controller, not here. We send back the data to the controller.
 * Using no-border and no-padding classes when the form is inside another form.
 *
 * If we set "allowEmptyRows" to true then we will ignore empty rows in the form and we will remove an existing item if we leave
 * the row empty
 *
 * Because this directive is incompleted feel free to modify this directive to adjust it to your needs
 */
(function() {
    'use strict';

    angular
        .module('elogbooksDirectives')
        .directive('elbAddEditTable', ElogbooksAddEditTable);

    ElogbooksAddEditTable.$inject = [
        '$state',
        'lodash',
        'apiClient',
        'messenger',
        'elbSettings',
        '$filter'
    ];

    function ElogbooksAddEditTable(
        $state,
        lodash,
        apiClient,
        messenger,
        elbSettings,
        $filter
    ) {
        return {
            restrict: 'AE',
            scope: {
                fieldProperties: '=',
                collectionResponse: '=',
                entityName: '=',
                message: '=',
                maxItems: '=',
                totalCost: '=',
                noBorder: '=',
                model: '=',
                allowEmptyRows: '='
            },
            templateUrl: '/modules/directives/elb-add-edit-table/add-edit-table.html',
            link: function($scope) {
                $scope.currency =  $filter('translate')('CURRENCY_' + elbSettings.getSetting('currency').value);
                // Calculate colGroup based in number of fieldProperties
                $scope.colGroup =  parseInt((11 / Object.keys($scope.fieldProperties).length + 1).toString());
                $scope.collectionLength = $scope.collectionResponse.count;
                $scope.entityCollection = angular.copy($scope.collectionResponse[$scope.entityName]);

                if ($scope.model) {
                    $scope.model.newRowsData = [];
                }

                $scope.addRow = function () {
                    var newRow = {};

                    lodash.each($scope.fieldProperties, function(input) {
                        newRow[input.variableName] = input.default != null ? input.default : null;
                    });

                    $scope.entityCollection.push(newRow);
                    $scope.collectionLength++;

                    // If we handle the form in controller, not here
                    if ($scope.model) {
                        $scope.model.newRowsData.push($scope.entityCollection[$scope.entityCollection.length - 1]);
                    }
                }

                // We need at least one row to show
                if ($scope.collectionLength === 0) {
                    $scope.entityCollection = [];
                    $scope.addRow();
                }

                $scope.cancel = function () {
                    $state.go('^');
                };

                // Edit all previous fieldProperties (not new one)
                $scope.save = function () {
                    // we need to send the row's self link and the data array associated with the row'
                    var data = {
                        _links: {},
                        data: [], // New edited data
                        newRowsData: [] // Rows added manually
                    };

                    // we cannot use es6 to use a variable as a property name like [$scope.entity]: []
                    lodash.set(data._links, $scope.entityName, []);

                    var inputValues = {};
                    var dataRow = {};

                    /**
                     * data sent to the backend: we have entities and their new edited data plus new rows added manually
                     * if we have marked a row "toRemove" then we will remove that in backend.
                     */
                    // Process editing
                    lodash.each($scope.entityCollection, function(entity) {
                        if ($scope.allowEmptyRows) {
                            if (isEmptyRow(entity)) {
                                // we have to mark an existing row "toRemove"
                                if (entity._links) {
                                    inputValues.toRemove = true;
                                    data._links[$scope.entityName].push(entity._links.self);
                                    data.data.push(inputValues);
                                    inputValues = {};
                                }

                                return;
                            }
                        }

                        if (entity._links) {
                            if (entity.toRemove) {
                                inputValues.toRemove = true;
                            } else {
                                lodash.each($scope.fieldProperties, function(fieldProperty) {
                                    if (fieldProperty.type !== 'no-edit') {
                                        inputValues[fieldProperty.variableName] = entity[fieldProperty.variableName];
                                    }
                                    inputValues.id = entity.id;
                                });
                            }

                            data._links[$scope.entityName].push(entity._links.self);
                            data.data.push(inputValues);
                            inputValues = {};
                        } else if (!entity.toRemove) {
                            // process new rows if any
                            lodash.each($scope.fieldProperties, function(fieldProperty) {
                                if (fieldProperty.type !== 'no-edit') {
                                    dataRow[fieldProperty.variableName] = entity[fieldProperty.variableName];
                                }
                            })

                            data.newRowsData.push(dataRow);
                            dataRow = {};
                        }
                    });

                    if (!$scope.model) {
                        return apiClient.update($scope.collectionResponse.getLink('edit'), data).then(function (response) {
                            if (response) {
                                var action = $scope.collectionLength === 1 ? '_CREATED' : '_UPDATED';
                                return $state.go($state.get('^'), {}, { reload: $state.get('^.^') }).then(function() {
                                    messenger.success($scope.message + action);
                                    });
                            } else {
                                messenger.error('REQUEST_ERROR');
                            }
                        });
                    }
                }

                // For delete an existing row
                $scope.deleteRow = function (index) {
                    $scope.entityCollection[index].toRemove = true;
                    if ($scope.entityCollection[index].amount !== null) {
                        $scope.totalCost -= parseFloat($scope.entityCollection[index].amount);
                    }
                    $scope.collectionLength--;
                    // We need at least one row to show
                    if ($scope.collectionLength === 0) {
                        $scope.addRow();
                    }

                    if ($scope.model) {
                        $scope.model.newRowsData.splice(index, 1);
                    }
                }

                // if we have a calculated field
                $scope.calculateField = function (indexRow) {
                    var entity = $scope.entityCollection[indexRow];
                    // check if there are properties that are calculated on the fly
                    lodash.each($scope.fieldProperties, function(fieldProperty) {
                        if (fieldProperty.calculatedField) {
                            // extract the amount from the totalCost first
                            var oldValue = parseFloat(entity[fieldProperty.variableName]);
                            if (!isNaN(oldValue)) {
                                $scope.totalCost -= oldValue;
                            }
                            // check what kind of operation
                            switch (fieldProperty.operation) {
                                case 'multiply':
                                    if (fieldProperty.fields.length === 0) {
                                        entity[fieldProperty.variableName] = 0;

                                    } else {
                                        entity[fieldProperty.variableName] = lodash.reduce(
                                            fieldProperty.fields,
                                            function (sum, fieldName) {
                                                return sum * (entity[fieldName] || 0);
                                            },
                                            1
                                        );
                                    }
                                    break;

                                case 'callback':
                                    // Defer to callback
                                    var operands = lodash.map(fieldProperty.fields, function (fieldName) {
                                        return entity[fieldName];
                                    });
                                    entity[fieldProperty.variableName] = fieldProperty.operator(operands);
                                    break;
                            }

                            var newValue = parseFloat(entity[fieldProperty.variableName]);
                            if (!isNaN(newValue)) {
                                // Round to 2 decimals
                                if (fieldProperty.roundDecimal === true) {
                                    newValue = roundDecimal(entity[fieldProperty.variableName]);
                                    entity[fieldProperty.variableName] = newValue;
                                }
                                // Update totalCost (it will be optional in future)
                                $scope.totalCost += newValue;
                            }
                        }
                    });
                };

                function roundDecimal(number) {
                    return parseFloat(number).toFixed(2);
                }

                function isEmptyRow(row) {
                    var empty = true;
                    lodash.each($scope.fieldProperties, function(input) {
                        if (input.type !== 'no-edit') {
                            if (row[input.variableName] !== null && row[input.variableName] !== undefined) {
                                empty = false;
                                return false;
                            }
                        }
                    });

                    return empty;
                }

                $scope.isRequired = function (input) {
                    return input.required;
                }

                // checking every input, if we allow empty rows we need to check the entire row as well
                $scope.isValid = function (input, index) {
                    if ($scope.allowEmptyRows && isEmptyRow($scope.entityCollection[index])) {
                        return true;
                    }
                    if ($scope.form[input] && $scope.form[input].$invalid && $scope.form.$submitted) {
                        return false;
                    }

                    return true;
                }

                // Checking the form before sending it, if we allow empty rows we need to check them too
                $scope.isFormValid = function () {
                    if ($scope.allowEmptyRows) {
                        var valid = true;
                        lodash.each($scope.entityCollection, function(entity, index) {
                            if (valid) {
                                lodash.each(Object.keys(entity), function(input) {
                                    if (!$scope.isValid(input + index, index)) {
                                        valid = false;
                                    };
                                });
                            }
                        });
                    }

                    return valid;
                }
            }
        };
    }
})();
