(function (){
    'use strict';

    angular
        .module('elogbooks.user.sites.master-tasks')
        .controller('UserSiteMasterTaskAddController', UserSiteMasterTaskAddController);

    UserSiteMasterTaskAddController.$inject = ['$state', '$stateParams', '$filter', 'messenger', 'lodash', 'apiClient', 'siteResponse', 'masterTaskCollectionResource'];

    function UserSiteMasterTaskAddController($state, $stateParams, $filter, messenger, lodash, apiClient, siteResponse, masterTaskCollectionResource) {
        var vm = this;
        vm.masterTasks = masterTaskCollectionResource ? masterTaskCollectionResource.masterTasks : [];
        vm.masterTasksCollection = masterTaskCollectionResource;
        vm.allSelected = false;
        vm.toggleAll = toggleAll;
        vm.bulkSend = bulkSend;

        vm.search = searchAction;
        vm.order = orderAction;

        var statusOptions = [
            { title: $filter('translate')('STATUS_ALL'), value: 'all' },
            { title: $filter('translate')('STATUS_ACTIVE'), value: '1' },
            { title: $filter('translate')('STATUS_INACTIVE'), value: '0' }
        ];

        var serviceType = [
            { title: $filter('translate')('NONE_SELECTED'), value: null },
            { title: $filter('translate')('SOFT_SERVICE'), value: 'soft' },
            { title: $filter('translate')('HARD_SERVICE'), value: 'hard' }
        ];

        vm.criteria = {
            mtName: { type: 'text', value: $stateParams.mtName, title: $filter('translate')('NAME') },
            mtType: { type: 'options', title: $filter('translate')('MASTER_TASK_SERVICE_TYPE'), value: $stateParams.mtType, options: serviceType}
        };

        lodash.each(vm.masterTasks, function (task) {
            if (task.mandatory) {
                task.checked = true;
            }
        });

        function toggleAll() {
            if (vm.allSelected) {
                lodash.each(vm.masterTasks, function (task) {
                    task.checked = true;
                });
            } else {
                lodash.each(vm.masterTasks, function (task) {
                    if (!task.mandatory) {
                        task.checked = false;
                    }
                });
            }
        }

        function bulkSend(action) {
            if (action == 'selected') {
                var requestData = {
                    'masterTasks' : {
                        '_links' :[]
                    }
                };

                lodash.each(vm.masterTasks, function (task) {
                    if (task.mandatory || task.checked) {
                        requestData.masterTasks._links.push({href: task.getLink('self')});
                    }
                });

                return apiClient.create(siteResponse.getLink('create-mastertasks'), requestData).then(function() {
                    $state.go('^', $stateParams, {'reload': $state.current.parent}).then(function() {
                        messenger.success('ACTION_SUCCESS');
                    });
                });
            }
        }

        /**
         * Search tasks
         */
        function searchAction(params) {
            var override = {
                mtPage: 1
            };

            $state.go('.', angular.extend({}, params, override), { reload: $state.current });
        }

        /**
         * Order tasks
         */
        function orderAction(key) {
            $stateParams.mtOrder = key;

            $state.go('.', angular.extend({}, $stateParams), { reload: $state.current });
        }
    }
})();
