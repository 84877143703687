(function() {
    'use strict';

    angular
        .module('elogbooks')
        .config(Translations);

    function Translations($translateProvider) {
        $translateProvider.translations('nl', {
            ESCALATION_CREATED_ACTION: '<strong>{{ ::event._links["user"].title }}</strong> Escalatie verzonden.',
            
            ESCALATION_JOB_ADDED_SYSTEM_ACTION: 'The system automatically escalated a job.',
            ESCALATION_JOB_ADDED_ACTION: '{{ ::event._links["user"] ? ("<strong>" + event._links["user"].title + "</strong>") : "The system automatically" }} taak geescaleerd.',
            ESCALATION_JOB_READDED_SYSTEM_ACTION: 'The system automatically re-escalated a job.',
            ESCALATION_JOB_READDED_ACTION: '{{ ::event._links["user"] ? ("<strong>" + event._links["user"].title + "</strong>") : "The system automatically" }} taak nogmaals geescaleerd.',
            ESCALATION_JOB_REMOVED_SYSTEM_ACTION: 'The system automatically removed a job.',
            ESCALATION_JOB_REMOVED_ACTION: '{{ ::event._links["user"] ? ("<strong>" + event._links["user"].title + "</strong>") : "The system automatically" }} taak verwijderd.',

            ESCALATION_QUOTE_ADDED_ACTION: '{{ ::event._links["user"] ? ("<strong>" + event._links["user"].title + "</strong>") : "The system automatically" }} Offerte geescaleerd.',
            ESCALATION_QUOTE_ADDED_SYSTEM_ACTION: 'The system automatically escalated a quote.',
            ESCALATION_QUOTE_READDED_ACTION: '{{ ::event._links["user"] ? ("<strong>" + event._links["user"].title + "</strong>") : "The system automatically" }} Offerte nogmaals geescaleerd.',
            ESCALATION_QUOTE_READDED_SYSTEM_ACTION: 'The system automatically re-escalated a quote.',
            ESCALATION_QUOTE_REMOVED_ACTION: '{{ ::event._links["user"] ? ("<strong>" + event._links["user"].title + "</strong>") : "The system automatically" }} Offerte verwijderd.',
            ESCALATION_QUOTE_REMOVED_SYSTEM_ACTION: 'The system automatically removed a quote.',

            ESCALATION_POSTPONED_ACTION: '<strong>{{ ::event._links["user"].title }}</strong> Escalatie uitgesteld.',
            ESCALATION_PROGRESSED_ACTION: '<strong>{{ ::event._links["user"].title }}</strong> Voortgang op de escalatie.',
            ESCALATION_CANCELLED_ACTION: '<strong>{{ ::event._links["user"].title }}</strong> Escalatie geannuleerd.',

            ESCALATION_MESSAGE_ACTION: '<strong>{{ ::message._links["sender"].title }}</strong> Bericht toegevoegd.',
        });
    }
})();
