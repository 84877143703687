/**
 * Departments list controller
 */
 (function() {
     'use strict';

     angular
        .module('elogbooks.admin.departments')
        .controller('DepartmentsController', DepartmentsController);

    DepartmentsController.$inject = ['departmentCollectionResponse', '$stateParams', '$state', '$translate'];

    function DepartmentsController(departmentCollectionResponse, $stateParams, $state, $translate) {

        var vm = this;

        vm.departmentCollectionResponse = departmentCollectionResponse;
        vm.departments = departmentCollectionResponse.getData().departments;

        var statusOptions = [
            {
                title: $translate.instant('NONE_SELECTED'),
                value: 'null'
            },
            {
                title: $translate.instant('STATUS_ACTIVE'),
                value: 'true'
            },
            {
                title: $translate.instant('STATUS_INACTIVE'),
                value: 'false'
            }
        ];

        vm.criteria = {
            name: { type: 'text', title: 'NAME', value: $stateParams.name },
            active: { type: 'options', title: 'IS_ACTIVE', value: $stateParams.active, options: statusOptions },
        };

        vm.search = function(params) {
            var override = {
                page: 1
            };

            $state.go('.', angular.extend({}, params, override), { reload: true });
        };

        vm.order = function order(key) {
            $stateParams.order = key;

            $state.go('.', angular.extend({}, $stateParams), {reload: $state.current});
        };

        vm.listVisible = true;

    };

 })();
