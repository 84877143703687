(function () {
    'use strict';

    angular
        .module('elogbooks.admin.vat-rates')
        .controller('VatRateDetailsController', VatRateDetailsController);

        VatRateDetailsController.$inject = ['vatRateResponse'];

    function VatRateDetailsController (vatRateResponse) {
        var vm = this;
        vm.vatRateResponse = vatRateResponse;
    }
})();