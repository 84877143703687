(function() {
    'use strict';

    angular.module('elogbooks.user.meters', [
        'elogbooks.user.meter-meters',
        'elogbooks.user.meter-manufacturers',
        'elogbooks.user.meter-occupancies',
        'elogbooks.user.meter-suppliers',
        'elogbooks.user.meter-supplies',
        'elogbooks.user.meter-types',
        'elogbooks.user.meter-units'
    ]);
})();
