app.config( function($translateProvider) {
    $translateProvider.translations('nl', {
        METER_OCCUPANCY_INFORMATION: 'Meter bezetting informatie',

        ADD_NEW_METER_OCCUPANCY: 'Voeg nieuwe bezetting toe',
        EDIT_METER_OCCUPANCY: 'Wijzig meter bezetting',

        METER_OCCUPANCY_LIST: 'Meter Bezetting',
        METER_OCCUPANCIES_LIST: 'Meter Bezettingen',
        METER_OCCUPANCY_ADD: 'Voeg een nieuwe meterbezetting toe',
        METER_OCCUPANCY_EDIT: 'Wijzig meter bezetting',

        METER_OCCUPANCY_CREATED: 'Meter bezetting gemaakt',
        METER_OCCUPANCY_UPDATED: 'Meter bezetting aangepast',
        METER_OCCUPANCY_DELETED: 'Meter bezetting verwijderd',

        METER_OCCUPANCY_METER: 'meter',
        METER_OCCUPANCY_SUPPLY: 'Levering',
        METER_OCCUPANCY_OCCUPANCY: 'bezetting'
    });
});
