(function(){
    'use strict';

    angular
        .module('elogbooksControllers')
        .controller('EditPoNumberModalController', EditPoNumberModalController);

    EditPoNumberModalController.$inject = ['config', '$uibModalInstance', 'jobResponse', 'jobStatuses'];

    function EditPoNumberModalController(config, $uibModalInstance, jobResponse, jobStatuses) {
        var vm = this;
        vm.config = config;
        vm.close = closeAction;
        vm.submit = submitAction;
        // PO on the modal becomes a required field, but only if the job is approved.
        vm.poRequired = jobResponse.poRequired && !jobStatuses.getAll('noPoRequired').includes(jobResponse.status);

        vm.data = {
            poNumber: jobResponse.poNumber ? jobResponse.poNumber : '',
            note: ''
        };

        function submitAction() {
            vm.data.poNumber = !vm.data.poNumber ? null : vm.data.poNumber;
            $uibModalInstance.close(vm.data);
        }

        function closeAction() {
            $uibModalInstance.dismiss('cancel');
        }
    }
})();
