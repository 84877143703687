(function () {
    'use strict';

    angular
        .module('elogbooks.user.sites.master-tasks')
        .config(masterTaskList);

        function masterTaskList($stateProvider) {
            $stateProvider
                .state('dashboard.user.sites.list.details.master-tasks', {
                    url: '/master-tasks?tPage&tYear&tLimit&tName&{tServiceProviderName:any}&tOrder',
                    parent: 'dashboard.user.sites.list.details',
                    params: {
                        tYear : (new Date()).getFullYear().toString(),
                        page : '1',
                        limit : '30'
                    },
                    resolve: {
                        serviceProviderCollection: function ($stateParams, apiClient, siteResponse) {
                            return apiClient.get(siteResponse.getLink('serviceproviders'), {});
                        },
                        taskCollectionResource: function ($stateParams, apiClient, user, siteResponse) {
                            var params = {
                                page : $stateParams.page,
                                limit : $stateParams.limit,
                                tOrder : $stateParams.tOrder,
                                masterTask : '1',
                                year : $stateParams.tYear,
                                name : $stateParams.tName,
                                serviceProviderName : $stateParams.tServiceProviderName,
                                datagroup: 'planner'
                            };

                            return apiClient.getPage(siteResponse.getLink('tasks'), params);
                        }
                    },
                    views: parseBook({
                        'elogbooks-right@dashboard.user.sites.list.details': {
                            templateUrl: '/modules/user/sites/details/master-tasks/list/task-list.html',
                            controller: 'UserSiteTaskListController',
                            controllerAs: 'vm'
                        }
                    }),
                    ncyBreadcrumb: {
                        label: '{{ ::"MASTER_TASKS" | translate }}'
                    }
                })
                .state('dashboard.user.sites.list.details.master-tasks.add', {
                    url: '/add?mtPage&mtLimit&mtName&mtType&mtOrder',
                    parent: 'dashboard.user.sites.list.details.master-tasks',
                    params: {
                        mtType : null,
                        mtPage : '1',
                        mtLimit : '30',
                        mtOrder: 'name',
                    },
                    resolve: {
                        masterTaskCollectionResource: function ($stateParams, apiClient, siteResponse, rootResourceResponse) {
                            var params = {
                                siteId: siteResponse.id,
                                page : $stateParams.mtPage,
                                limit : $stateParams.mtLimit,
                                order : $stateParams.mtOrder,
                                type : $stateParams.mtType,
                                status : '1',
                                name : $stateParams.mtName
                            };

                            return apiClient.get(siteResponse.getLink('mastertasks'), params).then(function(response) {
                                return response || apiClient.noResourceFound();
                            });
                        }
                    },
                    views: parseBook({
                        'elogbooks-right@dashboard.user.sites.list.details': {
                            templateUrl: '/modules/user/sites/details/master-tasks/add/add.html',
                            controller: 'UserSiteMasterTaskAddController',
                            controllerAs: 'vm'
                        }
                    }),
                    ncyBreadcrumb: {
                        label: '{{ ::"ADD" | translate }}'
                    }
                })
            ;
        }
})();
