(function () {
    'use strict';

    angular
        .module('elogbooks.admin.sites')
        .controller('AssociatesEditController', AssociatesEditController);

    AssociatesEditController.$inject = [    
        'apiClient',
        'messenger',
        'membershipsResponse',
        'associatesResponse',
        'requestDataFactory',
        'lodash',
        'sortableOptions',
        'siteAssociatesService',
        'associatesCollection',
        '$scope'
    ];

    function AssociatesEditController (
        apiClient,
        messenger,
        membershipsResponse,
        associatesResponse,
        requestDataFactory,
        lodash,
        sortableOptions,
        siteAssociatesService,
        associatesCollection,
        $scope
    ) {
        var vm = this;
        vm.memberships = membershipsResponse.getData().memberships;
        vm.update = updateAction;
        vm.addAssociate = addAssociate;
        vm.removeAssociate = removeAssociate;
        vm.loadMoreItems = loadMoreItems;
        vm.associatesCollection = associatesCollection;
        vm.loadingMore = false;
        vm.associateSelect = [];

        vm.sortableOptions = sortableOptions.addProperty({
            accept: function (sourceItemHandleScope, destSortableScope) {
                return sourceItemHandleScope.itemScope.sortableScope.$id === destSortableScope.$id;
            }
        });
        vm.getOptionsProxy = function (value) {
            return sortableOptions.addProperty({containment: '.draglist-'+value}, vm.sortableOptions);
        };

        angular.forEach(vm.associatesCollection.siteAssociateTypes, function(associatesCollection, key) {
            vm.associateSelect[key] = {
                response: membershipsResponse,
                link: membershipsResponse.getLink('self'),
                responseKeyPath: 'memberships',
                itemValuePath: '_links.user.title',
                linkParameters: {
                    permission: ['site_permission_can_be_associate']
                },
                onSelect: function() {},
                onRemove: function() {},
                appendToBody: true
            };
        });

        $scope.$watch('vm.associatesCollection', function(newValue, oldValue) {
            if (newValue !== oldValue && newValue.page === oldValue.page) {
                updateAction();
            }
        }, true);

        function loadMoreItems() {
            if (vm.loadingMore) {
                return;
            }

            if (vm.associatesCollection.page < vm.associatesCollection.pages) {
                vm.loadingMore = true;
                apiClient.get(vm.associatesCollection.getLink('next')).then(function (response) {
                    angular.forEach(response.siteAssociateTypes, function(associatesCollection, key) {
                        vm.associateSelect.push({
                            response: membershipsResponse,
                            link: membershipsResponse.getLink('self'),
                            responseKeyPath: 'memberships',
                            itemValuePath: '_links.user.title',
                            linkParameters: {
                                permission: ['site_permission_can_be_associate']
                            },
                            onSelect: function() {},
                            onRemove: function() {},
                            appendToBody: true
                        });
                    });

                    response = siteAssociatesService.buildSiteAssociateObject(associatesResponse, response);
                    vm.associatesCollection.siteAssociateTypes = vm.associatesCollection.siteAssociateTypes.concat(response.siteAssociateTypes);
                    vm.associatesCollection.page = response.page;
                    vm.associatesCollection.pages = response.pages;
                    vm.associatesCollection._links = response._links;
                    vm.associatesCollection.noMore = vm.associatesCollection.page === vm.associatesCollection.pages;
                    vm.loadingMore = false;
                });
            }
        }

        function removeAssociate(associateType, associateKey) {
            associateType.members.splice(associateKey, 1);
        }

        function addAssociate(associateType, membership, key) {
            // don't allow adding existing memberships to this type
            var findExisting = lodash.find(associateType.members, function(element) { return element.href === membership._links.self.href; });
            if (findExisting === undefined) {

                var addition = {
                    href: membership._links.self.href,
                    title: membership._links.user.title,
                    userHref: membership._links.user.href
                };
                associateType.members.push(addition);
            }
            vm.associateSelect[key].selected = null;
        }

        function updateAction() {
            var associates = [];

            angular.forEach(vm.associatesCollection.siteAssociateTypes, function(associateType, key) {

                var typeHref = associateType.href;

                angular.forEach(associateType.members, function(member, key) {

                    var associate = {
                        'associate' : {
                            'siteassociatetype': {
                                'href': typeHref
                            },
                            'sitemembership': {
                                'href': member.href
                            }
                        }
                    };
                    associates.push(associate);
                });
            });

            var associatesData = {};
            associatesData.associates = associates;
            associatesData = requestDataFactory.createRequest(associatesData);

            apiClient.update(associatesResponse.getLink('self'), associatesData).then(function (response) {
                if (response) {
                    messenger.success('ASSOCIATES_UPDATED');
                } else {
                    messenger.error('REQUEST_ERROR');
                }
            });
        }
    }
})();
