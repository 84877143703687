angular.module('elogbooksDirectives').directive('contactChannels', [function () {
    return {
        restrict: 'AE',
        require: ['ngModel'],
        scope: {
            fields: '=ngModel'
        },
        templateUrl: '/modules/directives/contact-channels/contact-channels.html'
    }
}]);
