(function () {
    'use strict';

    angular
        .module('elogbooksDirectives')
        .controller('saveFilterCriteriaController', saveFilterCriteriaController);

    saveFilterCriteriaController.$inject = ['$scope', '$uibModal'];

    function saveFilterCriteriaController($scope, $uibModal) {
        var user = $scope.filterUser;

        saveFilterModal(user);

        function saveFilterModal (user) {
            $scope.showSaveFiltersDirective.value = false;
            return $uibModal.open({
                templateUrl: '/modules/directives/saved-filters/save-filter.html',
                controller: 'saveFilterModalController',
                controllerAs: 'vm',
                resolve: {
                    user: function() {
                        return user;
                    }
                }
            });
        }
    }
})();
