(function(){
    'use strict';

    angular
        .module('elogbooksControllers')
        .controller('SiteSearchModalController', SiteSearchModalController);

    function SiteSearchModalController($uibModalInstance, apiClient, restrictOne, userManager) {
        var vm = this;
        vm.expandedSearchForm = {
            regionSelect : {
                link : '/regions',
                responseKeyPath: 'regions'
            },
            sectorSelect : {
                link : '/sectors',
                responseKeyPath: 'sectors'
            },
            name: '',
            reference: '',
            selected: null,
            restrictOne: restrictOne,
            items: null,
            expandedSearch: expandedSearchAction
        };
        vm.submit = submitAction;
        vm.close = closeAction;
        vm.select = onSelect;
        vm.userSiteLink = userManager.getUser();

        function submitAction() {
            $uibModalInstance.close(vm.expandedSearchForm.selected);
        }

        function closeAction() {
            $uibModalInstance.dismiss('cancel');
        }

        function onSelect() {
            if (vm.expandedSearchForm.restrictOne
                && vm.expandedSearchForm.selected
                && vm.expandedSearchForm.selected.length > 0) {
                vm.expandedSearchForm.selected = [vm.expandedSearchForm.selected[0]];
            }
        }

        function expandedSearchAction() {
            var searchFilter = {
                regionId: typeof this.regionSelect.selected !== 'undefined' ?
                    this.regionSelect.selected.object.id : null,
                sectorId: typeof this.sectorSelect.selected !== 'undefined' ?
                    this.sectorSelect.selected.object.id : null,
                name: this.name,
                reference: this.reference
            };

            vm.userSiteLink.then(function (user) {
                apiClient.get(user.getLink('sites'), searchFilter).then(function (response) {
                    vm.expandedSearchForm.items = response.sites.map(
                        function (item) {
                            if (typeof item === 'undefined'){
                                return;
                            }

                            return  {
                                href :  item._links.self.href,
                                value : item.name
                            };
                        }
                    );
                });
            });
        }
    }
})();
