(function() {
    'use strict';

    angular
        .module('elogbooks')
        .config(Translations);

    function Translations($translateProvider) {
        $translateProvider.translations('nl', {
            ACTION: 'Actie',
            ACTIONS: 'Acties',
            ACTION_LIST: 'Actielijst',

            ACTION_ADD: 'Voeg een nieuwe actie toe',
            ACTION_EDIT: 'Aanpassen actie',

            CANNOT_CREATE_ACTION_SITE_ASSOCIATE_NOT_CORRECTLY_DEFINED: 'Kan actie niet maken, Object gerelateerden niet juist ingesteld',

            ACTION_CREATED: 'Actie aangemaakt',
            ACTION_UPDATED: 'Actie aangepast',
            ACTION_DELETED: 'Actie verwijderd',

            ACTIONS_OPEN: 'Uitstaande acties',

            ACTION_STATUS_OPEN: 'Open',
            ACTION_STATUS_COMPLETED: 'Uitgevoerd',
            ACTION_STATUS_CANCELLED: 'Geannuleerd',

            ACTION_ADD_SITE: 'Locatie',
            ACTION_ADD_CONTACT_DETAILS: 'Contactgegevens',
            ACTION_ADD_DETAILS: 'Actiegegevens',

            ACTION_ID: 'Actie {{ id }}',
            ACTION_DETAILS: 'Actiegegevens',
            ACTION_STATUS: 'Status',

            ACTION_CHANGE_ASSIGNEE_TO_ME: 'Word eigenaar van deze actie',

            DATE_DUE_START: 'Laatste start datum',
            DATE_DUE_END: 'Laatste verval datum',

            PAPERWORK_FOR_CLIENT_REVIEW: 'Bijlagen voor controle naar Property Manager',
            ESCALATED_TO_CLIENT: 'Geescaleerd naar Property Manager',
            FROM_JOB: 'Vanuit werkbon',
            EXTERNAL_SYSTEM: 'Extern Systeem',
            FROM_AUDIT: 'Vanuit audit',
            FROM_AUDIT_QUESTION: 'Vanuit vraag audit',
            ACTION_SUMMARY: 'Samenvatting acties'
        });
    }
})();
