(function() {
    'use strict';

    angular
        .module('elogbooks.user.finance.purchase-orders')
        .controller('PurchaseOrderAddEditController', PurchaseOrderAddEditController);

    PurchaseOrderAddEditController.$inject = [
        'purchaseOrdersCollectionResponse',
        'purchaseOrderResponse',
        'suppliersCollectionResponse',
        'requestDataFactory',
        'crudService',
        '$state',
        'config'
    ];

    function PurchaseOrderAddEditController(
        purchaseOrdersCollectionResponse,
        purchaseOrderResponse,
        suppliersCollectionResponse,
        requestDataFactory,
        crudService,
        $state,
        config
     ) {
        var vm = this;
        vm.purchaseOrdersCollectionResponse = purchaseOrdersCollectionResponse;
        vm.suppliersCollectionResponse = suppliersCollectionResponse;
        vm.purchaseOrderResponse = purchaseOrderResponse;
        vm.purchaseOrder =  vm.purchaseOrderResponse;
        vm.config = config;
        vm.data = requestDataFactory.createRequest(getDefaults());

        vm.supplierModel = {
            response: suppliersCollectionResponse,
            link:suppliersCollectionResponse.getLink('self'),
            linkParameters: {active: true},
            responseKeyPath: 'suppliers',
            itemHrefPath: '_links.self.href',
            itemValuePath: 'name',
            onSelect: changeSupplier,
            onRemove : changeSupplier
        };

        if (vm.data.id) {
            vm.supplierModel.selected = {
                href: vm.purchaseOrder.supplier.getLink('self'),
                title: vm.purchaseOrder.supplier.name,
                object: vm.purchaseOrder.supplier
            }
        }

        vm.changeSupplier = changeSupplier;
        vm.create = createAction;
        vm.update = updateAction;
        vm.delete = deleteAction;
        vm.cancel = cancelAction;

        function getDefaults() {
            return {
                id: vm.purchaseOrder.id || null,
                _links: {
                    supplier: vm.purchaseOrder.supplier ?  vm.purchaseOrder.supplier._links.self.href : null
                },
                telephone: vm.purchaseOrder.supplier ? vm.purchaseOrder.supplier.telephone : null,
                contactName: vm.purchaseOrder.supplier ? vm.purchaseOrder.supplier.contactName : null,
                email: vm.purchaseOrder.supplier ? vm.purchaseOrder.supplier.email : null,
                address: vm.purchaseOrder.supplier ? vm.purchaseOrder.supplier.flatAddress : null,
                supplierReference: vm.purchaseOrder.supplierReference ? vm.purchaseOrder.supplierReference : null
            }
        }

        function changeSupplier(supplier) {
            vm.data._links.supplier = {};
            vm.data.contactName = null;
            vm.data.telephone = null;
            vm.data.email = null;
            vm.data.address = null;
            if (vm.supplierModel.selected) {
                var supplier = supplier.selected.object;
                vm.data._links.supplier = supplier.getLink('self');
                vm.data.contactName = supplier.contactName;
                vm.data.telephone = supplier.telephone;
                vm.data.email = supplier.email;
                vm.data.address = supplier.flatAddress;
            }
        }

        function createAction() {
            if (vm.config.job){
                vm.data._links.job = vm.config.job;
            }

            return crudService.create(
                vm.purchaseOrdersCollectionResponse.getLink('create'),
                vm.data,
                'dashboard.user.finance.purchase-orders.list.details.items',
                'resource',
                'PURCHASE_ORDER'
            );
        }

        function updateAction() {
            return crudService.update(
                vm.purchaseOrder.getLink('edit'),
                vm.data,
                'dashboard.user.finance.purchase-orders.list.details.items',
                'dashboard.user.finance.purchase-orders.list',
                'PURCHASE_ORDER',
                false
            );
        }

        function deleteAction() {
            return crudService.delete(
                vm.purchaseOrder.getLink('delete'),
                $state.get('^.^'),
                'PURCHASE_ORDER'
            );
        }

        function cancelAction() {
            var cancelRoute = $state.get('^');

            if (vm.data.id) {
                cancelRoute = 'dashboard.user.finance.purchase-orders.list.details.items'
            }
                $state.go(cancelRoute, {});
            }
        }
})();
