(function () {
    'use strict';

    angular
        .module('elogbooks.common.priorities')
        .controller('DepartmentsDetailsInfoController', DepartmentsDetailsInfoController);

    DepartmentsDetailsInfoController.$inject = ['departmentResponse'];

    function DepartmentsDetailsInfoController (departmentResponse) {
        var vm = this;
        vm.departmentResponse = departmentResponse;
    }
})();
