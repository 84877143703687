app.config(function ($translateProvider) {
    $translateProvider.translations('nl', {
        REGION: 'Regio',
        REGIONS: 'Regios',

        REGION_INFORMATION: 'Regioinformatie',
        MANAGE_REGION_SITES: 'Objecten in de regio',

        ADD_NEW_REGION: 'Voeg een nieuwe regio toe',
        EDIT_REGION: 'Wijzig regio',

        REGION_LIST: 'Lijst met regios',
        REGION_ADD: 'Voeg een nieuwe regio toe',
        REGION_EDIT: 'Wijzig regio',

        REGION_CREATED: 'Regio aangemaakt',
        REGION_UPDATED: 'Regio aangepast',
        REGION_DELETED: 'Regio verwijderd',

        REGION_SITE_LIST: 'Objecten',
    });
});
