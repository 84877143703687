angular.module('elogbooksServices').service('status', function($translate) {

    /**
     * Returns Translated active/inactive options
     * @returns Array of active/inactive
     */
    this.activeInactive = function () {
        return [
            { value: true, title: $translate.instant('STATUS_ACTIVE') },
            { value: false, title: $translate.instant('STATUS_INACTIVE') }
        ]
    }

    return this;
});
