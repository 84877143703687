(function () {
    'use strict';

    angular
        .module('elogbooks.admin.sites')
        .controller('ApproversEditController', ApproversEditController);

    ApproversEditController.$inject = ['$scope', 'apiClient', 'messenger', 'membershipsResponse', 'requestDataFactory', 'lodash', 'sortableOptions', 'approversResponse', 'quoteThresholdsCollectionResponse', 'thresholdService'];

    function ApproversEditController ($scope, apiClient, messenger, membershipsResponse, requestDataFactory, lodash, sortableOptions, approversResponse, quoteThresholdsCollectionResponse, thresholdService) {
        var vm = this;
        vm.approverTypes = {
            reactiveJobApprovers: { title: 'REACTIVE_JOB_APPROVERS', selected: null },
            reactiveJobExtensionsApprovers: { title: 'REACTIVE_JOB_EXTENSIONS_APPROVERS', selected: null },
            plannedJobApprovers: { title: 'PLANNED_JOB_APPROVERS', selected: null },
            plannedJobExtensionsApprovers: { title: 'PLANNED_JOB_EXTENSIONS_APPROVERS', selected: null },
            quoteApprovers: { title: 'QUOTE_APPROVERS', selected: null },
            contractApprovers: { title: 'CONTRACT_APPROVERS', selected: null },
            surveyApprovers: { title: 'SURVEY_APPROVERS', selected: null }
        };
        vm.memberships = membershipsResponse.memberships;

        vm.approvers = approversResponse;
        vm.quoteThresholds = quoteThresholdsCollectionResponse.quoteThresholds;

        thresholdService.initThresholds(vm.quoteThresholds);
        vm.getQuoteApproverThreshold = thresholdService.getQuoteApproverThreshold;

        vm.addApproverToAll = addApproverToAll;
        vm.removeApproverFromAll = removeApproverFromAll;

        vm.update = updateAction;

        vm.approverSelect = [];
        vm.addToAllDisabled = false;

        angular.forEach(vm.approverTypes, function(approverType, key) {
            vm.approverSelect[key] = {
                response: membershipsResponse,
                link: membershipsResponse.getLink('self'),
                responseKeyPath: 'memberships',
                itemValuePath: '_links.user.title',
                linkParameters: {
                    permission: ['site_permission_can_be_approver'],
                    datagroup: 'list',
                    userActive: true
                },
                onSelect: function() {},
                onRemove: function() {},
                getResponseData: function (response) {
                    return lodash.get(response.getData(), this.responseKeyPath).filter(function(membership) {
                        return membership._links.user.isActive === 2;
                    });
                },
                appendToBody: true
            };
        });

        vm.bulkSelect = {
            response: membershipsResponse,
            link: membershipsResponse.getLink('self'),
            responseKeyPath: 'memberships',
            itemValuePath: '_links.user.title',
            linkParameters: {
                permission: ['site_permission_can_be_approver'],
                datagroup: 'list',
                userActive: null
            },
            onSelect: function(membership) {
                vm.addToAllDisabled = membership.selected.object._links.user.isActive !== 2;
            },
            onRemove: function() {},
            appendToBody: true
        };

        /* Sortable Options */
        vm.sortableOptions = sortableOptions.addProperty({
            accept: function (sourceItemHandleScope, destSortableScope) {
                return sourceItemHandleScope.itemScope.sortableScope.$id === destSortableScope.$id;
            }
        });
        vm.getOptionsProxy = function (value) {
            return sortableOptions.addProperty({containment: '.draglist-'+value}, vm.sortableOptions);
        };

        vm.parseLinks = function (approvers) {
            if (typeof approvers.approvers === 'undefined'
                || typeof approvers.approvers.memberships === 'undefined') {
                return {
                    sitemembership: [],
                    user: []
                };
            }

            var sitemembership = [];
            lodash.each(approvers.approvers.memberships, function (membership) {
                sitemembership.push({
                    href: membership._links.self.href,
                    title: membership._links.user.title,
                    isActive: membership._links.user.isActive
                });
            });

            return angular.extend({}, {sitemembership: sitemembership}, {approvers: approvers.approvers.memberships});
        };

        vm.data = requestDataFactory.createRequest({
            reactiveJobApprovers: {
                required: vm.approvers.reactiveJobApprovers.required,
                _links: vm.parseLinks(vm.approvers.reactiveJobApprovers)
            },
            reactiveJobExtensionsApprovers: {
                required: vm.approvers.reactiveJobExtensionsApprovers.required,
                _links: vm.parseLinks(vm.approvers.reactiveJobExtensionsApprovers)
            },
            plannedJobApprovers: {
                required: vm.approvers.plannedJobApprovers.required,
                _links: vm.parseLinks(vm.approvers.plannedJobApprovers)
            },
            plannedJobExtensionsApprovers: {
                required: vm.approvers.plannedJobExtensionsApprovers.required,
                _links: vm.parseLinks(vm.approvers.plannedJobExtensionsApprovers)
            },
            quoteApprovers: {
                required: vm.approvers.quoteApprovers.required,
                _links: vm.parseLinks(vm.approvers.quoteApprovers)
            },
            contractApprovers: {
                required: vm.approvers.contractApprovers.required,
                _links: vm.parseLinks(vm.approvers.contractApprovers)
            },
            surveyApprovers: {
                required: vm.approvers.surveyApprovers.required,
                _links: vm.parseLinks(vm.approvers.surveyApprovers)
            }
        });

        var watcher = undefined;

        startWatcher();

        function startWatcher () {
            watcher = $scope.$watch('vm.data', function(newValue, oldValue) {
                if (newValue !== oldValue) {
                    updateAction();
                }
            }, true);
        }

        function stopWatcher () {
            watcher();
            watcher = undefined;
        }

        vm.addApprover = function (approvers, membership, key) {
            var approverExists = false;

            lodash.each(approvers.approvers, function(approver) {
                if (lodash.isEqual(approver._links.self.href, membership._links.self.href)) {
                    messenger.error('USER_ALREADY_APPROVER');
                    approverExists = true;
                }
            });

            if (!approverExists) {
                approvers.approvers.push(membership);
                approvers.sitemembership.push({ href: membership._links.self.href });
            }

            vm.approverSelect[key].selected = null;
        };

        function addApproverToAll(generalApprover) {
            var approverExists;

            // stop watcher, so we process update once at end of loop
            stopWatcher();

            lodash.each(vm.data, function(approverType) {
                approverExists = false;
                if (lodash.has(approverType, '_links')) {
                    var approverLinks = approverType._links;

                    lodash.each(approverLinks.approvers, function(approver) {
                        if (lodash.isEqual(approver._links.self.href, generalApprover._links.self.href)) {
                            approverExists = true;
                        }
                    });

                    if (!approverExists) {
                        approverLinks.approvers.push(generalApprover);
                        approverLinks.sitemembership.push({ href: generalApprover._links.self.href });
                    }
                }
            });

            vm.bulkSelect.selected = null;

            updateAction();
            startWatcher();
        }
        function removeApproverFromAll(generalApprover) {
            // stop watcher, so we process update once at end of loopsite-group.add-edit.controller.js line
            stopWatcher();

            lodash.each(vm.data, function(approverType) {
                if (lodash.has(approverType, '_links')) {
                    var approverLinks = approverType._links;
                    lodash.remove(approverLinks.approvers, function(approver) {
                        return generalApprover._links.self.href === approver._links.self.href;
                    });
                    lodash.remove(approverLinks.sitemembership, { href: generalApprover._links.self.href });
                }
            });

            vm.bulkSelect.selected = null;

            updateAction();
            startWatcher();
       }
        function updateAction() {
            apiClient.update(vm.approvers.getLink('self'), vm.data).then(function (response) {
                if (response) {
                    // update approversresponse
                    messenger.success('APPROVERS_UPDATED');

                    // update vm.approvers to keep site/info up to date
                    var approvers = [];
                    lodash.each(vm.approverTypes, function (approverType, approverTypeKey) {
                        approvers = [];

                        // loop ordered sitememberships for this approverType
                        lodash.each(vm.data[approverTypeKey]._links.sitemembership, function (sitemembership) {

                            // add approver matching this sitemembership
                            var approver = lodash.find(
                                vm.data[approverTypeKey]._links.approvers,
                                function(approverCheck) {
                                    return approverCheck._links.self.href === sitemembership.href;
                                }
                            );
                            if (approver !== undefined) {
                                 approvers.push(approver);
                            }
                        });

                        vm.approvers[approverTypeKey] = {
                            required: vm.data[approverTypeKey].required,
                            approvers: {
                                memberships: approvers,
                                count: vm.data[approverTypeKey]._links.approvers.length
                            }
                        };
                    });
                } else {
                    messenger.error('REQUEST_ERROR');
                }
            });
        }
    }
})();
