app.config(function ($translateProvider) {
    $translateProvider.translations('zh-hk', {

        APPROVER: '審批人',
        APPROVERS: '審批人',
        APPROVED_BY: '審批人',

        APPROVERS_UPDATED: '審批人更新',

        APPROVAL_REQUIRED: '需要審批',
        APPROVER_REQUIRED: '必須增加審批人在以下列表以作審批',
        APPROVER_NOT_ADDED: '未有增加審批人',
        APPROVER_ADDED_TO_ALL_APPROVER_TYPES: '從所有審批類別增加此審批人',
        APPROVER_REMOVED_FROM_ALL_APPROVER_TYPES: '從所有審批類別中移除此審批人',
        USER_ALREADY_APPROVER: '在此審批類型,此用戶已分配為審批人',

        APPROVAL_REQUIRED: '需要審批',

        ADD_REMOVE_USER_ALL_APPROVER_TYPES: '從所有審批類別中增加或人移除此用戶',

        REACTIVE_JOB_APPROVER: '重啟審批人',
        REACTIVE_JOB_APPROVERS: '重啟工作',
        PLANNED_JOB_APPROVER: '計劃審批人',
        PLANNED_JOB_APPROVERS: 'PPM',
        JOB_EXTENSION_APPROVER: '工作開拓審批人',
        JOB_EXTENSION_APPROVERS: '工作開拓',
        QUOTE_APPROVER: '報價審批人',
        QUOTE_APPROVERS: '報價',
        CONTRACT_APPROVER: '合約審批人',
        CONTRACT_APPROVERS: '合約',
        SURVEY_APPROVER: '調查審批人',
        SURVEY_APPROVERS: '調查',

        APPROVER_ADDED_BUT_NOT_REQUIRED: '審批人已增加,但審批要求選項還未選.',

        SITE_APPROVER_LIST: '在場審批人',
    });
});
