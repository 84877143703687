(function() {
    'use strict';

    angular
        .module('elogbooks')
        .config(Translations);

    function Translations($translateProvider) {
        $translateProvider.translations('en-gb', {
            REPORTS: 'Reports',
            REPORT_LIST: 'Reports',
            REPORT: 'Report',

            BETWEEN_DATES: 'Between Dates',
            SHOW_PERCENTAGES: 'Show Percentages',

            SP_SUMMARY_REPORT: 'Service Provider Summary Report',
            SP_NAME: 'SP Name',
            REACTIVE_ATTENDANCE: 'Reactive Attendance',
            REACTIVE_MISSED_ATTENDANCE: 'Reactive Missed Attendance',
            REACTIVE_COMPLETION: 'Reactive Completion',
            REACTIVE_MISSED_COMPLETION: 'Reactive Missed Completion',
            PPM_ATTENDANCE: 'PPM Attendance',
            PPM_MISSED_ATTENDANCE: 'PPM Missed Attendance',
            PPM_COMPLETION: 'PPM Completion',
            PPM_MISSED_COMPLETION: 'PPM Missed Completion',
            QUOTES: 'Quotes',
            EXTENSION_REQUEST: 'Extension Request',
            REOPENED_JOBS: 'Re-Opened Jobs',

            OVERDUE_PLANNED_ATTENDANCE: 'Overdue Planned Attendance',
            OVERDUE_PLANNED_COMPLETION: 'Overdue Planned Completion',
            OVERDUE_PLANNED: 'Overdue Planned',

            COMPLETED_DUE: 'Completed Due',
            ATTEND_DUE: 'Attend Due',
            COMPLETED: 'Completed',
            ATTENDED: 'Attended',

            EXTENSION_REQUESTS: 'Extension Requests',
            JOB_ESCALATIONS: 'Job Escalations',
            QUOTE_ESCALATIONS: 'Quote Escalations',

            EXTENSION_FROM: 'Extension From',
            EXTENSION_TO: 'Extension To',
            EXTENSION_STATUS: 'Extension Status',

            QUOTES_COMPLETED: 'Quotes Completed',
            QUOTES_MISSED: 'Quotes Missed',

            LAST_REOPENED_AT: 'Last Reopened At',
            SERVICE_PROVIDER_SUMMARY: 'Service Provider Summary',

            FM_NAME: 'FM Name',
            APPROVER_NAME: 'Approver Name',
            PLANNED_ATTENDANCE: 'Planned Attendance',
            PLANNED_COMPLETION: 'Planned Completion',
            PLANNED_WORK: 'Planned Work',
            REACTIVE_WORK: 'Reactive Work',
            KPI_SURVEYS: 'KPI Surveys',
            KPI_GROUPS: 'KPI - Groups',
            LAST_LOGGED_IN: 'Last Logged In',
            SITES_WITH_PPM_REQUIRING_APPROVAL_CONCISE: 'Sites - PPM Approvals',
            JOBS_APPROVED: 'Jobs Approved',
            AVERAGE_TIME_TAKEN: 'Average Time Taken',
            AVERAGE_TIME_ACROSS_ENTIRE_SYSTEM: 'Average Time Across Entire System',
            QUOTES_APPROVED: 'Quotes Approved',
            SITES_WITH_QUOTES_REQUIRING_APPROVAL: 'Sites - Quote Approvals',
            LIVE_STAT: 'This is a live statistic. The Date Range filter does not apply to the following figures.',
            KPI_SURVEYS_OUTSTANDING: 'KPI Surveys Outstanding',
            CUSTOMER_GROUP: 'Customer Group',
            QUOTES_REQUIRING_APPROVAL: 'Quotes Requiring Approval',
            TASKS_REQUIRING_APPROVAL: 'Tasks Requiring Approval',
            SUBMITTED_DATE: 'Submitted Date',
            PPM_ON_TRACK: 'PPM On Track',
            PPM_REQUIRING_ATTENTION: 'PPM Requiring Attention',
            REACTIVE_ON_TRACK: 'Reactive On Track',
            REACTIVE_REQUIRING_ATTENTION: 'Reactive Requiring Attention',
            ACTIVITY_REPORT: 'Activity Report',
            REACTIVE_PAPERWORK_FOR_REVIEW: 'Reactive Paperwork for Review',
            PLANNED_PAPERWORK_FOR_REVIEW: 'Planned Paperwork for Review',
            PAPERWORK_FOR_REVIEW: 'Paperwork for Review',
            REACTIVE_ESCALATED_TO_CLIENT: 'Reactive Escalated to Client',
            PLANNED_ESCALATED_TO_CLIENT: 'Planned Escalated to Client',
            PPM_REQUIRING_APPROVAL: 'PPM Requiring Approval',
            OUTSTANDING_KPI_SURVEYS: 'Outstanding KPI Surveys',
            RAISED_DATE: 'Raised Date',
            INVALID_FM: 'Invalid FM',
            UNKNOWN: 'Unknown',
            REPORT_QUEUED: 'Report Queued',
            AVERAGE_TIME_ACROSS_SYSTEM: 'Average Time Across The Entire System',
            SITE_QUOTES_REQUIRING_APPROVAL: 'Sites With Quotes Requring approval',
            AGE_OF_APPROVALS: 'Age Of Approvals',
            AVERAGE: 'Average',

            // Summary report
            SUMMARY_REPORT: 'Summary',
            JOBS_BY_REGION: 'Jobs By Region',
            JOBS_BY_PRIORITY: 'Jobs By Priority',
            JOBS_BY_JOB_GROUP: 'Jobs By Job Group',
            JOBS_BY_SERVICEPROVIDER: 'Jobs By Service Provider',
            JOBS_BY_SITE_GROUP: 'Jobs By Site Group',
            JOBS_BY_SITE: 'Jobs By Site',
            QUOTES_BY_SITE: 'Quotes By Site',
            QUOTES_BY_SP: 'Quotes By SP',
            QUOTES_BY_SERVICEPROVIDER: 'Quotes By Service Provider',
            JOB_GROUP: 'Job Group',
            JOBGROUP: 'Job Group',
            TOTAL_JOBS: 'Total Jobs',
            OPEN_JOBS: 'Open Jobs',
            COMPLETED_JOBS: 'Completed Jobs',
            MISSED_JOBS: 'Missed Jobs',
            ATTENDED_ON_TIME: 'Attended On Time',
            COMPLETED_ON_TIME: 'Completed On Time',
            TOTAL_QUOTES: 'Total Quotes',
            ACCEPTED_QUOTES: 'Accepted Quotes',
            AVERAGE_TIME_TO_QUOTE: 'Average time to quote',
            DECLINED_QUOTES: 'Declined Quotes',
            EXPIRED_QUOTES: 'Expired Quotes',
            REJECTED_QUOTES: 'Rejected Quotes',
            TOTAL_KPI: 'Total KPI Surveys',
            AVERAGE_SCORE: 'Average Score',
            ATTENDANCE_DUE_DATE: 'Attendance Due Date',
            COMPLETION_DUE_DATE: 'Completion Due Date',
            DATE_ATTENDED: 'Date Attended',
            DATE_COMPLETED: 'Date Completed',
            REPORTING_PERIOD: 'Reporting Period (m/Y)',
            SERVICEPROVIDER_SCORE: 'SP Score',
            CLIENT_SCORE: 'Client Score',
            TARGET_SCORE: 'Target Score',

            PPM_GAP_ANALYSIS: 'PPM Gap Analysis',
            PRIMARY_PPM_APPROVER: 'Primary PPM Approver',
            PRIMARY_FM: 'Primary FM',

            OPENCLOSE_OPEN: 'Late Open',
            OPENCLOSE_CLOSE: 'Early Close',
            OPEN_CLOSE: 'Late Open / Early Close',
            EARLY_OPEN: 'Late Open',
            LATE_CLOSE: 'Early Close',
            BOTH: 'Both',
            ENGINEER_RISK_REPORT: 'Engineering Risk Summary',
            ENGINEERING_RISK_REPORT_TOTALS: 'Totals',
            COMPLIANT: 'Compliant',
            UPCOMING: 'Upcoming',
            MONTHLY_REPORT: 'Monthly',
            KPI_MONTHLY_REPORT: 'KPI Monthly',
            COMPLIANCE_DUPLICATE: 'Record exist for selected group, month and year.',
            NO_ACTIVE_SITES: 'There are no active sites for selected group, month and year.',
            PORTFOLIO_COMPLIANCE_REPORT: 'Portfolio Compliance',
            PORTFOLIO_COMPLIANCE_REPORT_ADD_LINE: 'Portfolio Compliance Add Line',
            NUMBER_OF_ERRORS: 'No. Errors',
            SP_GROUP: 'SP Group',
            ACTIVE_SITES: 'Active Sites',
            ERRORS: 'Errors',
            DAYS_LATE: 'Days Late',
            QUOTES_COMPLETION: 'Quote Completed',
            MONTHLY_REPORT_DATA: 'Table',
            MONTHLY_REPORT_GRAPH: 'Graph',
            ENGINEERING_RISK_REPORT_MONTHS_LIMIT_WARNING: 'The selected date range covers over 24 months. The data table and export will be limited to 24 months.',
            MONTHLY_REPORT_MONTHS_LIMIT_WARNING: 'The selected date range covers over 12 months. The data table and graph will be limited to 12 months, but export will cover up to 36 months of data.',
            KPI_MONTHLY_REPORT_MONTHS_LIMIT_WARNING: 'The selected date range covers over 12 months. The data table will be limited to 12 months, but export will cover up to 36 months of data.',
            KPI_MONTHLY_REPORT_MONTHS_VERSION_WARNING: 'Activity on this version may not fall within the selected date range.',
            SELECT_SURVEY: 'Please select survey',
            AUDIT_TREND_REPORT: 'Audit Trend Report',
            QUESTION_SUMMARY: 'Question Summary',
            ANSWER_LIST: 'Answer List',
            AUDIT_ID: 'Audit ID',
            COMMENT_SUPPLIED: 'Comment Supplied',
            DATE_ANSWERED: 'Date Answered',
            AUDIT_REFERENCE: 'Audit Reference',
            AVE: 'Ave',

            SP_USAGE: 'SP Usage',
            SP_USAGE_REPORT: 'SP Usage Report',
            SHOW_RESULTS: 'Show Results',
            ONLINE: 'Online',

            MISSED_REACTIVE_SLA: 'Missed Reactive SLA',
            MISSED_PPMS: 'Missed PPMs',
            LATE_QUOTES: 'Late Quotes',
            ESCALATED_JOBS: 'Escalated Jobs',
            SP_OPERATIVE: 'SP Operative',
            SERVICE_PROVIDER: 'Service Provider',
            DATE_SUBMITTED: 'Date Submitted',
            DATE_FIRST_ESCALATED: 'Date First Escalated',
            SUBMISSION_DUE: 'Submission Due',
            REPORT_TYPE: 'Report Type',

            RESIDENTIAL_LOCATION: 'Residential Location',
            RESIDENTIAL_LOCATION_DETAILS: 'Residential Location',

            OPERATIVE_PERFORMANCE: 'Operative Performance',
            OPERATIVE_PERFORMANCE_REPORT: 'Operative Performance Report',
            OPERATIVE_PERFORMANCE_SP: 'Select a Service Provider',
            SERVICE_PROVIDER_FEEDBACK_RATING: 'Service Provider Feedback Ratings',
            SERVICE_PROVIDER_FEEDBACK_RATING_LIST: 'Service Provider Feedback Ratings',
            SERVICE_PROVIDER_FEEDBACK_RATING_DETAILS_LIST: 'Rating Details',
            OVERALL_RATING: 'Overall Rating',
            FIVE_STARS: '5 Stars',
            FOUR_STARS: '4 Stars',
            THREE_STARS: '3 Stars',
            TWO_STARS: '2 Stars',
            ONE_STARS: '1 Star',
            DATE_TIME: 'Date / Time',
            USER_ID: 'User ID',
            COMMENTS: 'Comments'
        });
    }
})();
