angular.module('elogbooksControllers').controller('SiteManageAddressesController', function ($scope, apiClient, base64, $state) {

    $scope.editMode = false;

    $scope.left = function (key) {
        var moveElement = $scope.contactInformation.addresses[key];
        $scope.contactInformation.addresses[key] = $scope.contactInformation.addresses[key - 1];
        $scope.contactInformation.addresses[key - 1] = moveElement;

        apiClient.update($scope.site._links.contactinformation.href, $scope.contactInformation);
    }

    $scope.right = function (key) {
        var moveElement = $scope.contactInformation.addresses[key];
        $scope.contactInformation.addresses[key] = $scope.contactInformation.addresses[key + 1];
        $scope.contactInformation.addresses[key + 1] = moveElement;

        apiClient.update($scope.site._links.contactinformation.href, $scope.contactInformation);
    }

    $scope.edit = function (key) {
        $state.go('dashboard.contactInformation.address', {
            contactInformationResource: base64.encode('/sites/1/contactinformation'),
            addressKey: key
        });
    }

});
