(function() {
    'use strict';

    angular
        .module('elogbooks')
        .config(Translations);

    function Translations($translateProvider) {
        $translateProvider.translations('en-gb', {
            ESCALATION: 'Escalation',
            ESCALATIONS: 'Escalations',
            ESCALATION_LIST: 'Escalations',

            ESCALATION_ADD: 'Add a new Escalation',
            ESCALATION_EDIT: 'Edit Escalation',

            ESCALATION_CREATED: 'Escalation Created',
            ESCALATION_UPDATED: 'Escalation Updated',
            ESCALATION_DELETED: 'Escalation Deleted',

            ESCALATE: 'Escalate',
            ESCALATED: 'Escalated',
            NOT_ESCALATED: 'Not Escalated',
            ESCALATED_AT: 'Escalated At',

            ESCALATION_DETAILS: 'Escalation {{ id }} details',

            ESCALATION_STATUS: 'Status',
            ESCALATION_STATUS_HELPDESK_FILTER: 'Escalation Status',

            DEESCALATED: 'Removed from Escalation',
            EXPORT_JOBS: 'Export Jobs',
            EXPORT_QUOTES: 'Export Quotes'
        });
    }
})();

app.config(function ($translateProvider) {
    $translateProvider.translations('en-gb', {


        ESCALATION_SUCCESS: 'Escalation successful',
    });
});
