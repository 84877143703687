(function(){
    'use strict';

    angular
        .module('elogbooks.common.site-contacts')
        .controller('CommonSiteContactsController', CommonSiteContactsController);

    CommonSiteContactsController.$inject = [
        '$scope',
        'contactsResponse',
        'lodash', 'config',
        '$stateParams',
        '$state',
        'selectedSiteResponse',
        '$filter',
        'userManager',
        'tenantEmailerService',
        'regionCollectionResponse'
    ];

    function CommonSiteContactsController (
        $scope,
        contactsResponse,
        lodash,
        config,
        $stateParams,
        $state,
        selectedSiteResponse,
        $filter,
        userManager,
        tenantEmailerService,
        regionCollectionResponse
    ) {
        var vm = this;
        vm.hasTenantEmailer = (userManager.hasPermission('site_permission_email_tenants') && $state.current.name.indexOf('.tenants') > -1);
        vm.canExportTenants = (userManager.hasPermission('user_permission_manage_exports') && $state.current.name.indexOf('.tenants') > -1);
        vm.contactResponse = contactsResponse;
        vm.contacts = vm.contactResponse.contacts;
        vm.contacts.selected = 0;
        vm.config = config;
        vm.noBorder = config.noBorder;
        vm.search = search;
        vm.order = order;
        vm.toggleAllContacts = toggleAllContacts;
        vm.selectAllContacts = false;
        vm.checkContactHasEmail = checkContactHasEmail;
        vm.showSite = vm.config.detailRoute !== '.details' && !selectedSiteResponse;
        vm.goToEmailerSelected = goToEmailerSelected;
        vm.goToEmailerFiltered = goToEmailerFiltered;

        $scope.$watch('vm.contacts', function(newVal) {
            var newSelected = 0;

            lodash.forEach(newVal, function(contact) {
                if(contact.selected) {
                    newSelected++;
                }
            });

            vm.contacts.selected = newSelected;
        }, true);

        setContactsSelected(vm.contacts, false);

        var activeOptions = [
            {
                title: $filter('translate')('NONE_SELECTED'),
                value: 'showall'
            },
            {
                title: $filter('translate')('STATUS_ACTIVE'),
                value: 'yes'
            },
            {
                title: $filter('translate')('STATUS_INACTIVE'),
                value: 'no'
            }
        ];

        vm.criteria = {};

        if ($state.current.name === 'dashboard.user.tenants.list') {
            vm.criteria.siteIds = {
                type: 'autocompletemulti',
                multiple: true,
                title: 'SITE',
                value: $stateParams.siteIds,
                options: {
                    link: userManager.user.getLink('sites'),
                    responseKeyPath: 'sites',
                    itemValuePath: 'name',
                    itemHrefPath: 'id',
                    autocompletemulti: true,
                    advanced: true,
                },
                param: 'siteIds',
                max: 50,
                isDisabled: config.siteLevel || selectedSiteResponse
            }
        }

        vm.criteria.contactName = { type: 'text', title: 'NAME', value: $stateParams.contactName };
        vm.criteria.contactEmail = { type: 'text', title: 'EMAIL_ADDRESS', value: $stateParams.contactEmail };
        vm.criteria.contactCompany = { type: 'text', title: 'COMPANY', value: $stateParams.contactCompany };

        if(vm.config.contactType !== 'contact') {
           vm.criteria.contactRole = { type: 'text', title: 'ROLE', value: $stateParams.contactRole };
           vm.criteria.active = { type: 'options', title: 'IS_ACTIVE', value: $stateParams.active, options: activeOptions, clearValue: 'showall' };
           if(vm.config.detailRoute === '.details.contacts') {
               vm.regionSelect = {
                   response: regionCollectionResponse,
                   link: regionCollectionResponse ? regionCollectionResponse.getLink('self') : null,
                   itemValuePath: 'id',
                   itemTitlePath: 'name',
                   responseKeyPath: 'regions',
                   selected: getFromLocalStorage('region')
               };

               if (vm.regionSelect.selected) {
                   vm.regionSelect.selected =  JSON.stringify({
                       v: getFromLocalStorage('region'),
                       n: window.localStorage.getItem('regionFilterName')
                   });
               }

               vm.criteria.region = {
                   type: 'jsonselectwidget',
                   title: 'REGION',
                   value: vm.regionSelect.selected ? JSON.parse(vm.regionSelect.selected) : JSON.parse($stateParams.region),
                   options: vm.regionSelect,
                   isDisabled: config.selectedRegionId !== null || selectedSiteResponse
               };
           }
        }

        function getFromLocalStorage(key) {
            return JSON.parse(window.localStorage.getItem(key + 'Filter'));
        }

        function search (params) {
            var override = {
                contactPage: 1
            };

            $state.go('.', angular.extend({}, params, override), { reload: $state.current });

        }

        function order (key) {
            $stateParams.contactOrder = key;

            var override = {
                contactPage: 1
            };

            $state.go('.', angular.extend({}, $stateParams, override), { reload: $state.current });
        }

        function setContactsSelected(contacts, val) {
            var val = (typeof val !== 'undefined') ?  val : false;

            lodash.forEach(contacts, function(contact) {
                contact.selected = checkContactHasEmail(contact) ? val : false;
            });
        }

        function toggleAllContacts () {
            setContactsSelected(vm.contacts, vm.selectAllContacts);
        }

        function checkContactHasEmail (contact) {
            var hasEmail = false;

            lodash.forEach(contact.contactInformation.fields, function(field) {
                if(field.type == 'email') {
                    hasEmail = true;
                }
            });

            return hasEmail;
        }

        function goToEmailerSelected() {
            tenantEmailerService.reset();
            tenantEmailerService.emailFiltered = false;
            lodash.forEach(vm.contacts, function(contact) {
                if(contact.selected) {
                    tenantEmailerService.tenantsResponse.contacts.push(contact);
                }
            });
            $state.go('.emailer');
        }

        function goToEmailerFiltered() {
            var params = $stateParams;

            if ($stateParams.region){
                params.region = JSON.parse($stateParams.region).v;
            }

            tenantEmailerService.reset();
            tenantEmailerService.emailFiltered = true;
            tenantEmailerService.tenantFilter = params;
            $state.go('.emailer');
        }
    }
})();
