app.config(function ($translateProvider) {
    $translateProvider.translations('en-gb', {
        GENERATE_NEW_PASSWORD: 'Generate New Password',
        RESET_PASSWORD: 'Reset Password',
        RESET_PASSWORD_INSTRUCTIONS: 'Click to generate a new password.',
        MODIFY_PASSWORD: 'Modify Password',
        CHANGE_PASSWORD: 'Change Password',
        NEW_PASSWORD: 'New Password',
        CONFIRM_PASSWORD: 'Confirm Password',
        NEW_PASSWORD_GENERATED: 'New password generated.',
        LOGIN_AS_USER: 'Login as this user',
        PASSWORD_IS_TOO_SHORT: 'Password is too short',
        PASSWORD_SHOULD_CONTAIN_ONE_UPPERCASE: 'Password should contain at least one uppercase',
        PASSWORD_SHOULD_CONTAIN_ONE_NUMBER: 'Password should contain at least one number',
        PASSWORD_SHOULD_CONTAIN_ONE_PUNCTUATION_CHAR: 'Password should contain at least one punctuation character',
        PASSWORD_SHOULD_BE_DIFFERENT_THAN_LAST_3: 'Password should be different than last 3 used',
        RETURN_TO_ORIGINAL_USER_FIRST: 'Return to original user first.'
    });
});
