/**
 * Survey Service
 */
angular.module('elogbooksServices').service('surveyService', function (apiClient, $q) {

    this.labelForScore = function (score, scoreRange) {
        var found = '-';
        angular.forEach(this.optionsForScoreRange(scoreRange), function(item) {
            if (item.value == score) {
                found = item.label;
            }
        });
        return found;
    };

    this.optionsForScoreRange = function (scoreRange) {

        var options = [];

        switch (scoreRange) {
            case '0to5':
                options = [
                    {
                        value: 0,
                        label: '0 - None of the requirements have been achieved'
                    }, {
                        value: 1,
                        label: '1 - The majority of requirements have not been achieved'
                    }, {
                        value: 2,
                        label: '2 - Some of the requirements have not been achieved'
                    }, {
                        value: 3,
                        label: '3 - The majority of requirements have been achieved'
                    }, {
                        value: 4,
                        label: '4 - The vast majority of requirements have been achieved'
                    }, {
                        value: 5,
                        label: '5 - All requirements have been achieved satisfactorily'
                    }
                ];
                break;
            case '0to2':
                options = [
                    {
                        value: 0,
                        label: '0 - None of the requirements have been achieved'
                    }, {
                        value: 1,
                        label: '1 - The majority of requirements have not been achieved'
                    }, {
                        value: 2,
                        label: '2 - All requirements have been achieved satisfactorily'
                    }
                ];
                break;
            case 'yn':
                options = [
                    {
                        value: 1,
                        label: 'YES'
                    }, {
                        value: 0,
                        label: 'NO'
                    }
                ];
                break;
            case 'ny':
                options = [
                    {
                        value: 1,
                        label: 'NO'
                    }, {
                        value: 0,
                        label: 'YES'
                    }
                ];
                break;
        }

        return options;
    };
});
