(function() {
    'use strict';

    angular
        .module('elogbooks.admin.core-clients')
        .controller('CoreClientsMarkupInfoController', CoreClientsMarkupInfoController);

    CoreClientsMarkupInfoController.$inject = [
        'coreClientMarkupResponse'
    ];
    
    function CoreClientsMarkupInfoController(coreClientMarkupResponse) {
        var vm = this;
        vm.markup = coreClientMarkupResponse;
        vm.markup.readOnly = vm.markup.inactiveAt && new Date(vm.markup.inactiveAt) <= new Date();
    }
})();
