(function() {
    'use strict';

    angular
        .module('elogbooks')
        .config(Translations);

    function Translations($translateProvider) {
        $translateProvider.translations('zh-hk', {
            ESCALATION_CREATED_ACTION: '<strong>{{ ::event._links["user"].title }}</strong> 上報',

            ESCALATION_JOB_ADDED_ACTION: '<strong>{{ ::event._links["user"].title }}</strong> 上報工作',
            ESCALATION_JOB_ADDED_SYSTEM_ACTION: '系統自動上報工作',
            ESCALATION_JOB_READDED_ACTION: '<strong>{{ ::event._links["user"].title }}</strong> 再上報工作',
            ESCALATION_JOB_READDED_SYSTEM_ACTION: '系統自動再上報工作',
            ESCALATION_JOB_REMOVED_ACTION: '<strong>{{ ::event._links["user"].title }}</strong> 移除工作',
            ESCALATION_JOB_REMOVED_SYSTEM_ACTION: '系統自動移除工作',

            ESCALATION_QUOTE_ADDED_ACTION: '<strong>{{ ::event._links["user"].title }}</strong> 上報價單',
            ESCALATION_QUOTE_ADDED_SYSTEM_ACTION: '系統自動上報價單',
            ESCALATION_QUOTE_READDED_ACTION: '<strong>{{ ::event._links["user"].title }}</strong> 再上報價單',
            ESCALATION_QUOTE_READDED_SYSTEM_ACTION: '系統自動再上報價單',
            ESCALATION_QUOTE_REMOVED_ACTION: '<strong>{{ ::event._links["user"].title }}</strong>移除價單',
            ESCALATION_QUOTE_REMOVED_SYSTEM_ACTION: '系統自動移除價單',

            ESCALATION_POSTPONED_ACTION: '<strong>{{ ::event._links["user"].title }}</strong> 已延遲上報',
            ESCALATION_PROGRESSED_ACTION: '<strong>{{ ::event._links["user"].title }}</strong> 已進行上報',
            ESCALATION_CANCELLED_ACTION: '<strong>{{ ::event._links["user"].title }}</strong> 已取消上報',

            ESCALATION_MESSAGE_ACTION: '<strong>{{ ::message._links["sender"].title }}</strong>已加訊息',
        });
    }
})();
