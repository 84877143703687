(function(){
    'use strict';

    angular
        .module('elogbooks.admin.sso-users')
        .config(registerRoutes)
        .config(registerRoutesList);

    function registerRoutes($stateProvider) {
        $stateProvider.state('dashboard.admin.sso-users', {
            abstract: true,
            templateUrl: 'shared-templates/view.html',
            ncyBreadcrumb: {
                skip: true
            }
        });
    }

    function registerRoutesList($stateProvider) {
        $stateProvider.state('dashboard.admin.sso-users.list', {
            parent: 'dashboard.admin.sso-users',
            url: '/sso-users?page&limit&order&domains',
            views: {
                '@dashboard.admin.sso-users': {
                    templateUrl: '/modules/admin/sso-users/list/list.html',
                    controller: 'SsoUsersController',
                    controllerAs: 'vm'
                }
            },
            resolve: {
                ssoUsersCollectionResponse: function ($stateParams, apiClient, rootResourceResponse) {
                    var params = angular.extend({}, $stateParams, {
                            'domains[]': $stateParams.domains,
                    });
                    return apiClient.get(rootResourceResponse.getLink('ssousers'), params).then(function (response) {
                        return response || apiClient.noResourceFound();
                    });
                },
                ssoDomainsResponse: function (apiClient, rootResourceResponse) {
                    return apiClient.get(rootResourceResponse.getLink('sso-domains')).then(function (response) {
                        return response || apiClient.noResourceFound();
                    });  
                }
            },
            params: {
                page: '1',
                limit: '30',
                order: 'id',
                domains: {array: true},
                id: null,
                name: null,
                type: null
            },
            ncyBreadcrumb: {
                label: '{{ ::"SSO_USERS" | translate }}'
            }
        });
    }
})();
