(function() {
    'use strict';

    angular
        .module('elogbooks.common.actions')
        .controller('CommonActionDetailsController', CommonActionDetailsController);

    CommonActionDetailsController.$inject = ['actionResponse', 'config', 'apiClient'];

    function CommonActionDetailsController(actionResponse, config, apiClient) {
        var vm = this;
        vm.actionResponse = actionResponse;
        vm.config = config;

        vm.fromJobCompletedAt = actionResponse.getLinkAttribute('from-job', 'completedAt');

        angular.extend(vm.actionResponse, vm.config);
    }
})();
