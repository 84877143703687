(function () {
    'use strict';

    angular
        .module('elogbooks.admin.site-note-types')
        .controller('SiteNoteTypesController', SiteNoteTypesController);

    SiteNoteTypesController.$inject = [
        '$state',
        '$stateParams',
        '$filter',
        '$scope',
        'siteNoteTypeCollectionResponse',
        'messenger',
        'apiClient',
        'confirmationModal',
        'sortableOptions'
    ];

    function SiteNoteTypesController (
        $state,
        $stateParams,
        $filter,
        $scope,
        siteNoteTypeCollectionResponse,
        messenger,
        apiClient,
        confirmationModal,
        sortableOptions
    ) {
        var vm = this;
            vm.siteNoteTypes = siteNoteTypeCollectionResponse.sitenotetypes;
            vm.siteNoteTypeCollectionResponse = siteNoteTypeCollectionResponse;
            vm.currentPage = vm.siteNoteTypeCollectionResponse.page;
            vm.itemsCount = vm.siteNoteTypeCollectionResponse.count;
            vm.limit = vm.siteNoteTypeCollectionResponse.limit;

            vm.sortableOptions = sortableOptions.addProperty({
                orderChanged: function(event) {
                    vm.hasOrderChanged = true;
                }
            });

           $scope.$on('$stateChangeStart', function(event, toState, toStateParams) {
                if (vm.hasOrderChanged) {
                    if (!confirm($filter('translate')('SITE_NOTE_TYPE_UNSAVED_CHANGES'))) {
                        event.preventDefault();
                    }
                }
            });

            vm.visibilities = {
                0: $filter('translate')('VISIBILITY_PRIVATE'),
                1: $filter('translate')('VISIBILITY_PUBLIC'),
                2: $filter('translate')('VISIBILITY_TENANT'),
                3: $filter('translate')('VISIBILITY_SERVICE_PROVIDER')
            };

            vm.order = order;
            vm.search = search;
            vm.updateAction = updateAction;
            vm.loadMore = loadMore;
            vm.loadingMore = false;
            vm.hasOrderChanged = false;

            vm.filtered = (
                $stateParams.id ||
                $stateParams.name ||
                $stateParams.active ||
                ''
            ).length;

            var activeOptions = [
                {
                    title: $filter('translate')('NONE_SELECTED'),
                    value: null
                },
                {
                    title: $filter('translate')('STATUS_ACTIVE'),
                    value: 'yes'
                },
                {
                    title: $filter('translate')('STATUS_INACTIVE'),
                    value: 'no'
                }
            ];

            vm.criteria = {
                id: { type: 'number', value: $stateParams.id ? parseInt($stateParams.id, 10) : null, title: 'ID', min: 1 },
                name: { type: 'text', value: $stateParams.name, title: 'NAME' },
                active: { type: 'options', title: 'IS_ACTIVE', value: $stateParams.active, options: activeOptions },
            };

        function search (params) {
            var override = {
                page: 1
            };

            $state.go('.', angular.extend({}, params, override), { reload: true });
        };

        function order (key) {
            $stateParams.order = key;

            $state.go('.', angular.extend({}, $stateParams), { reload: true });
        };

        function loadMore() {
            if (vm.loadingMore) {
                return;
            }

            if (vm.siteNoteTypeCollectionResponse.page < vm.siteNoteTypeCollectionResponse.pages) {
                vm.loadingMore = true;

                apiClient.get(vm.siteNoteTypeCollectionResponse.getLink('next')).then(function (response) {
                    vm.siteNoteTypeCollectionResponse.sitenotetypes = vm.siteNoteTypeCollectionResponse.sitenotetypes.concat(response.sitenotetypes);
                    vm.siteNoteTypeCollectionResponse.page = response.page;
                    vm.siteNoteTypeCollectionResponse.pages = response.pages;
                    vm.siteNoteTypeCollectionResponse._links = response._links;
                    vm.siteNoteTypeCollectionResponse.noMore = vm.siteNoteTypeCollectionResponse.page === vm.siteNoteTypeCollectionResponse.pages;
                    vm.siteNoteTypes = vm.siteNoteTypeCollectionResponse.sitenotetypes;
                    vm.siteNoteTypes._links = vm.siteNoteTypeCollectionResponse._links;
                    vm.currentPage = vm.siteNoteTypeCollectionResponse.page;

                    vm.loadingMore = false;
                });
            }
        }

        function updateAction() {

            confirmationModal.open(
                {
                    titleMessage: 'ARE_YOU_SURE',
                    bodyMessage: 'CONFIRM_SITE_NOTE_TYPE_ORDER_SAVE',
                    primaryText: 'OK',
                    primaryClass: 'btn-primary',
                    secondaryText: 'CANCEL'
                }).result.then(function (response) {
                    if (response.result) {
                        var update = {
                            _links: { sitenotetypes: [] }
                        };

                        // Build the record to be sent to the server
                        angular.forEach(vm.siteNoteTypes, function(value, key) {
                            update._links.sitenotetypes.push(value._links.self);
                        });

                        apiClient.update(vm.siteNoteTypeCollectionResponse._links.first.href, update).then(function (response) {
                            if (response) {
                                vm.hasOrderChanged = false;
                                $state.go('.', {}, { reload: true }).then(function () {
                                    messenger.success('SITE_NOTE_TYPES_ORDER_UPDATED');
                                });
                            } else {
                                messenger.error('REQUEST_ERROR');
                            }
                        });
                    }
                });
        }
    }
})();
