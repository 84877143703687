(function () {
    'use strict';

    angular
        .module('elogbooks.admin.serviceroutines')
        .config(registerRoutes)
        .config(registerRoutesAdd)
        .config(registerRoutesDetails)
        .config(registerRoutesEdit)
        .config(registerRoutesLines);

    function registerRoutes($stateProvider) {
        $stateProvider
            .state('dashboard.admin.service-routines', {
                abstract: true,
                templateUrl: 'shared-templates/view.html',
                ncyBreadcrumb: {
                    skip: true
                },
                data: {
                    permissions: ['user_permission_view_service_routines']
                }
            })
            .state('dashboard.admin.service-routines.list', {
                url: '/service-routines?page&limit&order&id&name&description&active&isVersioned',
                parent: 'dashboard.admin.service-routines',
                data: {
                    permissions: ['user_permission_view_service_routines']
                },
                views: {
                    '@dashboard.admin.service-routines': {
                        templateUrl: '/modules/admin/service-routines/service-routines-list.html',
                        controller: 'ServiceRoutinesController',
                        controllerAs: 'vm'
                    }
                },
                params: {
                    serviceRoutinePage: '1',
                    serviceRoutineLimit: '30',
                    serviceRoutineId: null,
                    serviceRoutineReference: null,
                    serviceRoutineName: null,
                    serviceRoutineDescription: null,
                    serviceRoutineOrder: null,
                    serviceRoutineStatus: '1',
                    serviceRoutineVersion: null
                },
                resolve: {
                    serviceRoutineCollectionResponse: function (apiClient, rootResourceResponse, $stateParams) {
                        var params = {
                            page: $stateParams.serviceRoutinePage,
                            limit: $stateParams.serviceRoutineLimit,
                            id: $stateParams.serviceRoutineId,
                            reference: $stateParams.serviceRoutineReference,
                            name: $stateParams.serviceRoutineName,
                            description: $stateParams.serviceRoutineDescription,
                            order: $stateParams.serviceRoutineOrder,
                            active: $stateParams.serviceRoutineStatus,
                            isVersioned: $stateParams.serviceRoutineVersion
                        };

                        return apiClient.get(rootResourceResponse.getLink('serviceroutines'), params).then(function (response) {
                            return response || apiClient.noResourceFound();
                        });
                    },
                    assetResponse: () => {}
                },
                ncyBreadcrumb: {
                    label: '{{ ::"SERVICE_ROUTINES" | translate }}'
                }
            });
    }

    function registerRoutesAdd ($stateProvider) {
        $stateProvider
            .state('dashboard.admin.service-routines.list.add', {
                url: '/add',
                parent: 'dashboard.admin.service-routines.list',
                data: {
                    permissions: ['user_permission_manage_service_routines']
                },
                views: {
                    '@dashboard.admin.service-routines': {
                        templateUrl: '/modules/admin/service-routines/add-edit/service-routine-form.html',
                        controller: 'ServiceRoutineAddEditController',
                        controllerAs: 'vm'
                    }
                },
                resolve: {
                    serviceRoutineResponse: function () {
                        return {};
                    }
                },
                ncyBreadcrumb: {
                    label: '{{ ::"ADD" | translate }}'
                }
            });
    }

    function registerRoutesDetails ($stateProvider) {
        $stateProvider
            .state('dashboard.admin.service-routines.list.details', {
                url: '/details/{resource}',
                parent: 'dashboard.admin.service-routines.list',
                data: {
                    permissions: ['user_permission_view_service_routines']
                },
                abstract: true,
                views: {
                    '@dashboard.admin.service-routines': {
                        templateUrl: '/modules/admin/service-routines/details/service-routine-details.html',
                        controller: 'ServiceRoutineDetailsController',
                        controllerAs: 'vm'
                    }
                },
                resolve: {
                    serviceRoutineResponse: function (apiClient, $stateParams) {
                        return apiClient.get($stateParams.resource.decode()).then(function (response) {
                            return response || apiClient.noResourceFound();
                        });
                    }
                },
                ncyBreadcrumb: {
                    label: '{{ ::$resolves().serviceRoutineResponse.name }}'
                }
            })
            .state('dashboard.admin.service-routines.list.details.info', {
                url: '/info',
                parent: 'dashboard.admin.service-routines.list.details',
                data: {
                    permissions: ['user_permission_view_service_routines']
                },
                views: {
                    '@dashboard.admin.service-routines.list.details': {
                        templateUrl: '/modules/admin/service-routines/details/info/service-routine-info.html',
                        controller: 'ServiceRoutineInfoController',
                        controllerAs: 'vm'
                    }
                },
                resolve: {
                    serviceRoutineResponse: function (apiClient, $stateParams) {
                        return apiClient.get($stateParams.resource.decode()).then(function (response) {
                            return response || apiClient.noResourceFound();
                        });
                    }
                },
                ncyBreadcrumb: {
                    label: '{{ ::"INFO" | translate }}'
                }
            });
    }

    function registerRoutesEdit ($stateProvider) {
        $stateProvider
            .state('dashboard.admin.service-routines.list.details.info.edit', {
                url: '/edit',
                parent: 'dashboard.admin.service-routines.list.details.info',
                data: {
                    permissions: ['user_permission_manage_service_routines']
                },
                views: {
                    '@dashboard.admin.service-routines.list.details': {
                        templateUrl: '/modules/admin/service-routines/add-edit/service-routine-form.html',
                        controller: 'ServiceRoutineAddEditController',
                        controllerAs: 'vm'
                    }
                },
                ncyBreadcrumb: {
                    label: '{{ ::"EDIT" | translate }}'
                }
            })
    }

    function registerRoutesLines ($stateProvider) {
        $stateProvider
            .state('dashboard.admin.service-routines.list.details.service-routine-lines', {
                url: '/service-routine-lines?serviceRoutineLinePage&serviceRoutineLineLimit',
                parent: 'dashboard.admin.service-routines.list.details',
                data: {
                    permissions: ['user_permission_view_service_routines']
                },
                views: {
                    '@dashboard.admin.service-routines.list.details': {
                        templateUrl: '/modules/admin/service-routines/details/lines/service-routine-lines-list.html',
                        controller: 'ServiceRoutineLinesController',
                        controllerAs: 'vm'
                    }
                },
                params: {
                    serviceRoutineLinePage: '1',
                    serviceRoutineLineLimit: '10'
                },
                resolve: {
                    serviceRoutineLineCollectionResponse: function (apiClient, $stateParams, serviceRoutineResponse) {
                        var params = angular.extend({}, $stateParams,
                            {
                                page: $stateParams.serviceRoutineLinePage,
                                limit: $stateParams.serviceRoutineLineLimit,
                                datagroup: 'list'
                            });
                        return apiClient.get(serviceRoutineResponse.getLink('lines'), params).then(function (response) {
                            return response || apiClient.noResourceFound();
                        });
                    }
                },
                ncyBreadcrumb: {
                    label: '{{ ::"SERVICE_ROUTINE_LINES" | translate }}'
                }
            })
            .state('dashboard.admin.service-routines.list.details.service-routine-lines.add', {
                url: '/add',
                parent: 'dashboard.admin.service-routines.list.details.service-routine-lines',
                data: {
                    permissions: ['user_permission_manage_service_routines']
                },
                views: {
                    '@dashboard.admin.service-routines': {
                        templateUrl: '/modules/admin/service-routines/details/lines/add-edit/service-routine-line-form.html',
                        controller: 'ServiceRoutineLineAddEditController',
                        controllerAs: 'vm'
                    }
                },
                resolve: {
                    serviceRoutineLineResponse: function () {
                        return {};
                    },
                    statutoryTypesCollectionResponse: function(apiClient, rootResourceResponse) {
                        return apiClient.get(rootResourceResponse.getLink('statutorytypes'));
                    }
                },
                ncyBreadcrumb: {
                    label: '{{ ::"ADD" | translate }}'
                }
            })
            .state('dashboard.admin.service-routines.list.details.service-routine-lines.edit', {
                url: '/edit/{serviceRoutineLineResource}',
                parent: 'dashboard.admin.service-routines.list.details.service-routine-lines',
                data: {
                    permissions: ['user_permission_manage_service_routines']
                },
                views: {
                    '@dashboard.admin.service-routines.list.details': {
                        templateUrl: '/modules/admin/service-routines/details/lines/add-edit/service-routine-line-form.html',
                        controller: 'ServiceRoutineLineAddEditController',
                        controllerAs: 'vm'
                    }
                },
                resolve: {
                    serviceRoutineLineResponse: function (apiClient, $stateParams) {
                        return apiClient.get($stateParams.serviceRoutineLineResource.decode()).then(function (response) {
                            return response || apiClient.noResourceFound();
                        });
                    },
                    statutoryTypesCollectionResponse: function(apiClient, rootResourceResponse) {
                        return apiClient.get(rootResourceResponse.getLink('statutorytypes'));
                    }
                },
                ncyBreadcrumb: {
                    label: '{{ ::$resolves().serviceRoutineLineResponse.name }}'
                }
            });
    }
})();
