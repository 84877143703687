app.config(function ($translateProvider) {
    $translateProvider.translations('nl', {

        SITE_GROUP: 'Objecteigenaren',
        SITE_GROUPS: 'Objecteigenaren',

        SITE_GROUP_LIST: 'Lijst met object entiteitsnamen',
        SITE_GROUP_ADD: 'Voeg een nieuwe entiteit toe',
        SITE_GROUP_EDIT: 'Wijzig entiteitsnamen',
        EDIT_SITE_GROUP: 'Wijzig entiteitsnamen',

        SITE_GROUP_SITE_LIST: 'Pagina met objecten',

        ADD_NEW_SITE_GROUP: 'Voeg een nieuwe groep toe',

        MANAGE_SITE_GROUP_SITES: 'Beheer objectgroepen',
        SITE_GROUP_SITE_ADDED: 'Objectgroep toegevoegd',
        SITES_DELETED_FROM_SITE_GROUP: 'Objectgroep verwijderd',

        SITE_GROUP_CREATED: 'Objectgroep gemaakt',
        SITE_GROUP_UPDATED: 'Objectgroep aangepast',
        SITE_GROUP_DELETED: 'Objectgroep verwijderd',
    });
});
