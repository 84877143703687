app.config(function ($translateProvider) {
    $translateProvider.translations('zh-hk', {
        ROLE: '角色',
        ROLES: '角色',

        ROLE_EDIT: '修改角色',
        ROLE_LIST: '角色',
        ROLE_ADD: '新增角色',

        USER_ROLE: '用戶角色',
        SITE_MEMBERSHIP_ROLE: '場地人員角色',
        OPERATIVE_ROLE: '操作人員角色',

        ROLE_LIST: '角色',
        ROLE_ADD: '新增角色',
        ROLE_EDIT: '修改角色',

        ROLE_CREATED: '角色已建立',
        ROLE_UPDATED: '角色已更新',
        ROLE_DELETED: '角色已刪除',

        ROLE_PERMISSIONS: '角色權限',
        ROLE_PERMISSIONS_UPDATED: '角色權限已更新',
        MANAGE_USERS: '管理用戶'
    });
});
