(function() {
    'use strict';

    angular
        .module('elogbooksServices')
        .service('dateTimeService', dateTimeService);

        dateTimeService.$inject = ['moment', 'lodash'];

    function dateTimeService(moment, lodash)
    {
        var self = this;

        this.setEndOfDay = setEndOfDay;
        this.calculateWorkingDays = calculateWorkingDays;

        function setEndOfDay(date) {
            date = moment(date).endOf('day').toDate();

            return date;
        }

        function calculateWorkingDays(startDate, endDate, publicHolidays, startDateMeridiem, endDateMeridiem){
            var newStartDate = new Date(startDate);
            var numberOfDays = 0;

            while (newStartDate < endDate) {
                if (isWorkingDay(newStartDate, publicHolidays)) {
                    numberOfDays++;
                }

                newStartDate = new Date(newStartDate.setDate(newStartDate.getDate() + 1));
            }

            var isStartDateWorkingDay = isWorkingDay(startDate, publicHolidays);
            var isEndDateWorkingDay = isWorkingDay(endDate, publicHolidays);

            if (isStartDateWorkingDay && isEndDateWorkingDay) {
                if (startDateMeridiem == 'AM' && endDateMeridiem == 'PM') {
                    numberOfDays += 0.5;
                } else if (startDateMeridiem == 'PM' && endDateMeridiem == 'AM') {
                    numberOfDays -= 0.5;
                }
            } else if (isStartDateWorkingDay && startDateMeridiem == 'PM' && !isEndDateWorkingDay) {
                numberOfDays -= 0.5;
            } else if (isEndDateWorkingDay && endDateMeridiem == 'PM' && !isStartDateWorkingDay) {
                numberOfDays += 0.5;
            }

            numberOfDays = numberOfDays < 0 ? 0 : numberOfDays;

            return numberOfDays;
        }

        function isWorkingDay(date, publicHolidays) {
            var isPublicHoliday = lodash.find(publicHolidays.publicHolidays, function(day) {
                return +date == +(new Date(day.date));
            });

            if (isPublicHoliday ||
                (date.getDay() === 6 || date.getDay() === 0)
            ) {
                return false;
            } else {
                return true;
            }
        }

        return self;
    }
})();
