(function(){
    'use strict';

    angular
        .module('elogbooks.admin.contract-types')
        .controller('ContractTypesDetailsController', ContractTypesDetailsController);

    function ContractTypesDetailsController(contractTypeResponse) {

        var vm = this;
        vm.contracttype = contractTypeResponse.getData();

    }
})();
