(function () {
    'use strict';

    angular
        .module('elogbooks.user.files')
        .controller('CommonWasteEntryFileAddController', CommonWasteEntryFileAddController);

    CommonWasteEntryFileAddController.$inject = ['apiClient', '$state', 'messenger', 'requestDataFactory', '$scope', 'wasteEntryResponse'];

    function CommonWasteEntryFileAddController (apiClient, $state, messenger, requestDataFactory, $scope, wasteEntryResponse) {
        var vm = this;
        vm.data = requestDataFactory.createRequest({});
        vm.uploading = false;
        vm.uploadedFile = null;
        vm.wasteEntry = wasteEntryResponse;

        vm.create = createAction;

        function createAction () {
            if (vm.uploadedFile == null) {
                messenger.error('FILE_REQUIRED');
                return;
            }

            delete vm.uploadedFile._links.resource;

            return apiClient.create(vm.wasteEntry.getLink('files'), vm.uploadedFile ).then(function (response) {
                if (response) {
                    $state.go('^', {}, { reload: $state.get('^') }).then(function () {
                        messenger.success('FILE_CREATED');
                    });
                } else {
                    messenger.error('REQUEST_ERROR');
                }
            });
        }

        $scope.$watch('vm.uploadedFile', function (newValue) {
            vm.data._links.file = null;

            if (newValue) {
                vm.data._links.file = vm.uploadedFile._links.file;
            }
        });
    }
})();
