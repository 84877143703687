app.config( function($translateProvider) {
    $translateProvider.translations('zh-hk', {
        METER_MANUFACTURER_INFORMATION: '儀表製造商資料',

        ADD_NEW_METER_MANUFACTURER: '新增儀表製造商',
        EDIT_METER_MANUFACTURER: '修改儀表製造商',

        METER_MANUFACTURER_LIST: '儀表製造商',
        METER_MANUFACTURER_ADD: '新增儀表製造商',
        METER_MANUFACTURER_EDIT: '修改儀表製造商',

        METER_MANUFACTURER_CREATED: '儀表製造商已建立',
        METER_MANUFACTURER_UPDATED: '儀表製造商已更新',
        METER_MANUFACTURER_DELETED: '儀表製造商已刪除',
    });
});
