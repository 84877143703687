(function() {
    'use strict';

    angular
        .module('elogbooks.common.quote-requests')
        .controller('CommonQuoteRequestDetailsQuotesController', CommonQuoteRequestDetailsQuotesController);

    CommonQuoteRequestDetailsQuotesController.$inject = ['quoteCollectionResponse'];

    function CommonQuoteRequestDetailsQuotesController(quoteCollectionResponse) {
        var vm = this;
        vm.quoteCollectionResponse = quoteCollectionResponse;
    }
})();
