(function() {
    'use strict';

    angular
        .module('elogbooks.user.absence-planners')
        .controller('AbsenceEntryEventFeedController', AbsenceEntryEventFeedController);

    AbsenceEntryEventFeedController.$inject = ['eventCollectionResponse', 'absenceEntryResponse'];

    function AbsenceEntryEventFeedController(eventCollectionResponse, absenceEntryResponse) {
        var vm = this;

        vm.eventCollectionResponse = eventCollectionResponse;
    }
})();
