(function() {
    'use strict';

    angular
        .module('elogbooks.admin.settings')
        .controller('AdminManageDashboardsController', AdminManageDashboardsController);

    AdminManageDashboardsController.$inject = ['dashboardService', 'apiClient', '$state', 'messenger', 'dashboardWidgetsCollection', 'lodash'];

    function AdminManageDashboardsController(dashboardService, apiClient, $state, messenger, dashboardWidgetsCollection, lodash) {
        var vm = this;
        vm.update = updateAction;
        vm.selectedDashboardWidgets = [];
        vm.appliedDashboardWidgets = [];
        vm.data = [];

        vm.dashboardWidgets = {
            'dashboard_widgets': dashboardService.getAllTranslated()
        };
        vm.data.dashboard_widgets = lodash.find(dashboardWidgetsCollection.settings, {'name': 'dashboard_widgets'}).value;
        vm.editable = dashboardWidgetsCollection.getLink('edit') ? true : null;

        angular.forEach(vm.dashboardWidgets.dashboard_widgets, function (value) {
            if(value.status && vm.data.dashboard_widgets.indexOf(value.label) > -1) {
                var index = vm.data.dashboard_widgets.indexOf(value.label);
                vm.data.dashboard_widgets.splice(index, 1);
            }

            if (value.label === 'open_flagged_priority_jobs') {
                value.disabled = true;
            }
        });

        vm.dashboardWidgets.dashboard_widgets = vm.dashboardWidgets.dashboard_widgets.filter(function( value ) {
            return value.status !== 'disabled';
        });

        function updateAction(entity) {
            var dashboardWidgets = {
                'settings': [
                    {
                        'name': entity,
                        'value': vm.data[entity]
                    }
                ]
            };

            return apiClient.update(dashboardWidgetsCollection.getLink('edit'), dashboardWidgets).then(function (response) {
                if (response) {
                    vm.selectedDashboardWidgets[entity] = [];
                    messenger.success((entity + '_UPDATED').toUpperCase());
                } else {
                    $state.reload();
                }
            });

        }
    }
})();