(function () {
    'use strict';

    /**
     * @desc ui.select expanded widget
     * @example <div elb-select-multiple-search ng-model="vm.model"></div>
     */
    angular
        .module('elogbooksDirectives')
        .directive('elbSelectMultipleSearch', function ($uibModal, apiClient, lodash) {
            return {
                restrict: 'A',
                templateUrl: '/modules/directives/form-elements/select-multiple-search/select-multiple-search.html',
                scope: {
                    model: '=ngModel'
                },
                require: ['ngModel'],
                controller: ['$scope', function($scope) {
                    var defaultModel = {
                        link : null,
                        loading : false,
                        responseKeyPath: 'data',
                        itemHrefPath: '_links.self.href',
                        itemValuePath: 'name',
                        searchKey: 'name',
                        keyword: null,
                        required : false,
                        selectedItems: [],
                        items: [],
                        map : map,
                        getResponseData : getResponseData,
                        filterResponse : filterResponse,
                        clear : clearAction,
                        changeAction : null,
                        selectModel: [],
                    };

                    //Merge with defaults
                    $scope.model = lodash.merge(defaultModel, $scope.model);
                    $scope.searchDelayed = searchDelayed;

                    function searchAction() {
                        $scope.model.loading = true;

                        var searchParams = {};
                        searchParams[$scope.model.searchKey] = $scope.model.keyword;

                        apiClient.get($scope.model.link, angular.extend({}, searchParams, $scope.model.searchParams)).then(function (response) {
                            $scope.model.items = lodash.differenceBy($scope.model.filterResponse(response), $scope.model.selectedItems, 'href');
                            $scope.model.loading = false;
                        });
                    }

                    function searchDelayed() {
                        clearTimeout($scope.timer);
                        $scope.timer = setTimeout(searchAction, 250);
                    }

                    function map(keyPath, valuePath) {
                        return function (item) {
                            if (typeof item === 'undefined'){
                                return;
                            }

                            return item;
                        }
                    }

                    function getResponseData(response) {
                        return lodash.get(response.getData(), this.responseKeyPath);
                    }

                    function filterResponse(response) {
                        return this.getResponseData(response).map(
                            this.map(this.itemHrefPath, this.itemValuePath)
                        );
                    }

                    function clearAction($event) {
                        $event.stopPropagation();

                        this.selectedItems = null;
                        $scope.model.selectedItems = null;
                    }
                }]
            };
    })
})();
