angular.module('elogbooksServices').service('filterPermissions', function (lodash, $translate, modulesService) {
    this.filterPermissions = filterPermissions;
    this.filterAllowedPermissions = filterAllowedPermissions;

    function filterPermissions(permissions, type) {
        var filteredPermissions = [];
        for (var i = 0, permissionsLength = permissions.length; i < permissionsLength; i++) {
            var permission = permissions[i];
            if (permission.module) {
                if (modulesService.isEnabled(permission.module)) {
                    filteredPermissions.push(formatReturnByType(permission, type));
                }
            } else {
                filteredPermissions.push(formatReturnByType(permission, type));
            }
        }

        return filteredPermissions;
    }

    function filterAllowedPermissions(permissions, allowedPermissions) {
        var rolePermissions = [];

        for (var i = 0, length = permissions.length; i < length; ++i) {
            if (allowedPermissions.indexOf(permissions[i]) !== -1) {
                rolePermissions[i] = permissions[i];
            }
        }

        return rolePermissions;
    }

    function formatReturnByType(permission, type) {
        switch(true) {
            case type === 'TRANSLATED':
                return {
                    label: permission.name,
                    translation: $translate.instant(permission.name.toUpperCase()),
                    description: $translate.instant(permission.name.toUpperCase() + '_DETAIL'),
                }
            case type === 'VALUE':
                return permission.name;
            default:
                return permission;
        }
    }

    return this;
});
