(function () {
    'use strict';

    angular
        .module('elogbooks.user.patrols')
        .controller('ManagePatrolsAddEditController', ManagePatrolsAddEditController);

    ManagePatrolsAddEditController.$inject = ['$state', 'apiClient', 'messenger', 'requestDataFactory', 'patrolResponse', 'sitesCollection', 'siteResponse', 'patrolsCollection', 'confirmationModal'];

    function ManagePatrolsAddEditController ($state, apiClient, messenger, requestDataFactory, patrolResponse, sitesCollection, siteResponse, patrolsCollection, confirmationModal) {

        var vm = this;
        vm.patrol = patrolResponse;
        vm.sites  = sitesCollection.sites;
        vm.data = requestDataFactory.createRequest(getDefaults());
        vm.create = createAction;
        vm.update = updateAction;
        vm.delete = deleteAction;

        vm.siteSelect = {
            response : sitesCollection,
            link : sitesCollection ? sitesCollection.getLink('self') : null,
            responseKeyPath: 'sites',
            required: true,
            disabled: siteResponse !== null,
            populateDisabled: true
        };

        if (siteResponse) {
            vm.siteSelect.selected = {
                href: siteResponse._links.self.href,
                object: siteResponse,
                value: siteResponse.name
            };
        }

        function getDefaults() {
            return {
                _links: {
                    site: vm.patrol._links ? vm.patrol._links.site : null
                },
                id: vm.patrol.id || null,
                name: vm.patrol.name || null,
                description: vm.patrol.description || null,
                ordered: vm.patrol.ordered === false ? false : true,
                active: vm.patrol.active === true || vm.patrol.active === false ? vm.patrol.active : null
            };
        }

        function createAction() {
            vm.data._links.site = vm.siteSelect.selected.object.getLink('self');
            apiClient.create(patrolsCollection.getLink('create'), vm.data).then(function (response) {
                if (response) {
                    $state.go('dashboard.user.patrols.manage-patrols.details.info', { resource: response.getLink('self').encode() }, { reload: true }).then(function () {
                        messenger.success('PATROL_CREATED');
                    });
                } else {
                     messenger.error('REQUEST_ERROR');
                }
            });
        }

        function updateAction() {
            apiClient.update(vm.patrol._links.self.href, vm.data).then(function (response) {
                if (response) {
                    $state.go('dashboard.user.patrols.manage-patrols.details.info', {}, { reload: true }).then(function () {
                        messenger.success('PATROL_UPDATED');
                    });
                } else {
                    messenger.error('REQUEST_ERROR');
                }
            });
        }

        function deleteAction() {
            return confirmationModal.open().result.then(
                function () {
                    return apiClient.delete(vm.patrol.getLink('delete')).then(function (response) {
                        if (response) {
                            $state.go('dashboard.user.patrols.manage-patrols', {}, { reload: true }).then(function () {
                                messenger.success('PATROL_DELETED');
                            });
                        } else {
                            messenger.error('REQUEST_ERROR');
                        }
                    });
                },
                function () {
                    return;
                }
            );
        }
    }
})();
