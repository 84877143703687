app.config(function ($translateProvider) {
    $translateProvider.translations('nl', {
        FILE: 'Bestand',
        FILES: 'Bestanden',

        FILE_ADD: 'Voeg een nieuw bestand toe',
        FILE_EDIT: 'Wijzig bestand',
        FILE_LIST: 'Bestanden',

        FILE_CREATED: 'Bestand aangemaakt',
        FILE_UPDATED: 'Bestand gewijzigd',
        FILE_DELETED: 'Bestand verwijderd',
        FILE_TITLE: 'Bestands naam:',
        CAPTION: 'Caption',
        UPLOAD_AND_EDIT: 'Upload en wijzig',
        FILE_NAME: 'Bestandsnaam',
        UPLOAD: 'Upload',
        DELETE_IMAGE: 'Verwijder afbeelding',
        
        UPLOADED_BY: 'Toegevoegd door',
        UPLOADED_DATE_START: 'Toegevoegd na',
        UPLOADED_DATE_END: 'Toegevoegd voor',
        EXPIRES_DATE_START: 'Vervalt na',
        EXPIRES_DATE_END: 'Vervalt voor',

        FILE_DOWNLOAD: 'Download',
        FILE_PROCESSING: 'File processing',
        INVALID_FILE: 'Onjuist bestand',

        IS_STATUTORY_FILE: 'Wettelijk',
        ISSTATUTORY: 'Wettelijk',

        FILES_REQUIRED: 'minimaal een bestand nodig',

        MAXIMUM_SIX_UPLOADS: 'Maximaal zes uploads',
        MAXIMUM_SIX_UPLOADS_REACHED: 'Maximaal zes uploads ',
        FILES_ADD: 'voeg bestanden toe'
    });
});
