(function () {
    'use strict';

    angular
        .module('elogbooks.common.form-associations')
        .controller('CommonFormAssociationsController', CommonFormAssociationsController);

    CommonFormAssociationsController.$inject = [
        '$state',
        '$stateParams',
        'formAssociationsCollectionResponse',
        'rootResourceResponse',
        'config',
        'crudService',
        'messenger'
    ];

    function CommonFormAssociationsController (
        $state,
        $stateParams,
        formAssociationsCollectionResponse,
        rootResourceResponse,
        config,
        crudService,
        messenger
    ) {
        var vm = this;
        vm.formAssociationsCollectionResponse = formAssociationsCollectionResponse;
        vm.config = config;
        vm.config.noBorder = vm.config.noBorder || false;
        vm.formAssociations = formAssociationsCollectionResponse.getData().formAssociations;
        vm.resources = {
            'rootResource': rootResourceResponse,
            'stateParams': $stateParams,
            'root': rootResourceResponse
        };

        vm.canCreate = formAssociationsCollectionResponse.canCreate;

        vm.delete = deleteAction;

        vm.detailsRoute= $state.current.name + '.details';

        function deleteAction(formAssociation, $index) {
            return crudService.delete(
                formAssociation.getLink('delete'),
                null,
                null,
                {},
                true
            ).then(function(response) {
                $state.go('.', {}, {reload: $state.current});
                messenger.success('FORM_ASSOCIATION_DELETED');
            });
        }
    }
})();
