(function() {
    'use strict';

    angular
        .module('elogbooks')
        .config(Translations);

    function Translations($translateProvider) {
        $translateProvider.translations('en-gb', {
            FREQUENCY_YEARLY: '{{ ::interval }} Yearly',
            FREQUENCY_MONTHLY: '{{ ::interval }} Monthly',
            FREQUENCY_WEEKLY: '{{ ::interval }} Weekly',

            FREQUENCY_WEEKS: '{{ ::interval }} {{ interval == "1" ? "Week" : "Weeks" }}',
            FREQUENCY_DAYS: '{{ ::interval }} {{ interval == "1" ? "Day" : "Days" }}',
            FREQUENCY_HOURS: '{{ ::interval }} {{ interval == "1" ? "Hour" : "Hours" }}',
            FREQUENCY_MINUTES: '{{ ::interval }} {{ interval == "1" ? "Minute" : "Minutes" }}',
            FREQUENCY_SECONDS: '{{ ::interval }} {{ interval == "1" ? "Second" : "Seconds" }}',

            FREQUENCIES_MARKED_STATUTORY_NO_DOCUMENTTYPE: 'Please be aware that one or more Frequencies have been marked as Statutory but have no Document Type assigned.'
        });
    }
})();
