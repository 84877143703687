(function () {
    'use strict';

    angular
        .module('elogbooks.common.meter-meters')
        .controller('CommonMeterExportReadingsController', CommonMeterExportReadingsController);

    CommonMeterExportReadingsController.$inject = [
        '$uibModalInstance'
    ];

    function CommonMeterExportReadingsController ($uibModalInstance) {
        var vm = this;

        vm.data = {
            readAfter: moment().subtract(30, 'days').startOf('day').toDate(),
            readBefore: moment().endOf('day').toDate(),
        };

        vm.submit = function() {
            $uibModalInstance.close(vm.data);
        };

        vm.cancel = function() {
            $uibModalInstance.dismiss();
        }
    }
})();