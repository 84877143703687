angular.module('elogbooksDirectives').directive('certificate', ['apiClient', function(apiClient){
    return {
        // Restrict to attributes only.
        restrict: 'A',
        require: ['ngModel'],
        scope: {
            certificate: '=ngModel'
        },
        templateUrl: '/modules/directives/form-elements/certificate/certificate.html',
        link: function (scope) {
            apiClient.get('/certificatetypes').then(function (Response) {
                scope.options = Response.getData().certificateTypes;
            });
        }
    }
}]);
