(function () {
    'use strict';

    angular
        .module('elogbooksControllers')
        .controller('UserAdvancedTaskGenerateModalController', UserAdvancedTaskGenerateModalController);

        UserAdvancedTaskGenerateModalController.$inject = ['$scope', 'moment', 'messenger', 'config', 'rules', '$uibModalInstance', 'recurrenceRulesService'];

    function UserAdvancedTaskGenerateModalController ($scope, moment, messenger, config, rules, $uibModalInstance, recurrenceRulesService) {
        var vm = this;
        vm.config = config;
        vm.rows = config.rows | 6;
        vm.create = create;
        vm.cancel = cancel;
        vm.toggle = toggle;
        vm.expandIfChecked = expandIfChecked;
        vm.lastYear = moment().year() - 1;
        vm.minDate = '01-01-' + vm.lastYear;
        vm.data = {
            rules: rules,
            note: null
        };
        vm.recurrenceRulesService = recurrenceRulesService;
        $scope.getRuleFrequencyAsString = recurrenceRulesService.getRuleFrequencyAsString;
        $scope.getRuleMonths = recurrenceRulesService.getRuleMonths;
        $scope.getRuleDayPrefixAsString = recurrenceRulesService.getRuleDayPrefixAsString;
        $scope.getRuleDayNameAsString = recurrenceRulesService.getRuleDayNameAsString;

        let minDateMoment = moment().set({
            year: vm.lastYear,
            date: 1,
            month: 1
        });


        for (var key in vm.data.rules) {
            let originalDate = vm.data.rules[key].from;
            if (originalDate && minDateMoment.isAfter(moment(originalDate))) {
                vm.minDate = moment(originalDate).format('DD-MM-yyyy');
            }

            vm.data.rules[key].start = !vm.data.rules[key].id ? new Date() : null;
            vm.data.rules[key].end = null;
            vm.data.rules[key].collapsed = !vm.data.rules[key].reGenerate;
            vm.data.rules[key].generate = vm.data.rules[key].reGenerate;
            vm.data.rules[key].from = vm.data.rules[key].from;

            $scope.$watch("vm.data.rules['" + key + "'].start", function (newDate) {
                if (newDate) {
                    if (moment(newDate).format('DD-MM-yyyy') !== moment(originalDate).format('DD-MM-yyyy')) {
                        if (moment(newDate).isBefore(vm.minDate, "day")) {
                            vm.data.rules[key].start = null;
                            messenger.error("TASK_START_DATE_WARNING", {
                                lastYear: vm.lastYear,
                            });
                        } else if (
                            vm.data.rules[key].end &&
                            moment(newDate).isAfter(vm.data.rules[key].end, "day")
                        ) {
                            vm.data.rules[key].start = null;
                            messenger.error("START_DATE_ERROR", {
                                date: vm.data.rules[key].end,
                            });
                        }
                    }
                }
            });

            $scope.$watch("vm.data.rules['" + key + "'].end", function (newDate) {
                if (
                    newDate &&
                    vm.data.rules[key].start &&
                    moment(newDate).isBefore(vm.data.rules[key].start, "day") &&
                    moment(newDate).format('DD-MM-yyyy') !== moment(originalDate).format('DD-MM-yyyy')
                ) {
                    vm.data.rules[key].end = null;
                    messenger.error("END_DATE_ERROR", {
                        date: vm.data.rules[key].start,
                    });
                }
            });
        }

        $scope.$on('panelEntityInvalid', function (event, data) {
            for (var key in vm.data.rules) {
                if(data['start_'+key] && data['start_'+key].$invalid){
                    vm.data.rules[key].collapsed = false;
                }
            }
        });

        function toggle (index) {
            vm.data.rules[index].collapsed = !vm.data.rules[index].collapsed;
        }

        function cancel () {
            $uibModalInstance.dismiss(vm.data);
        }

        function create () {
            $uibModalInstance.close(vm.data);
        }

        function expandIfChecked (index) {
            vm.data.rules[index].collapsed = !vm.data.rules[index].generate;
        }
    }
})();
