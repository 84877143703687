angular.module('elogbooksDirectives').directive('elbDraggable', [function(){
    return {
        restrict: 'E',
        replace: true,
        transclude: true,
        scope: true,
        templateUrl: '/modules/directives/drag-drop/drag-drop.html',
        link: function ($scope, $element, $attrs, $controller, $transclude) {

            $scope.dragDrop = {
                isDisabled: ($attrs.disableDrag === 'disable' ? true : false)
            };

        }
    }
}]);
