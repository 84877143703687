(function () {
    'use strict';

    angular
        .module('elogbooks.admin.roles')
        .config(registerRoutes)
        .config(registerRoutesList)
        .config(registerRoutesAdd)
        .config(registerRoutesDetails);

    function registerRoutes ($stateProvider) {
        $stateProvider
            .state('dashboard.admin.roles', {
                abstract: true,
                templateUrl: 'shared-templates/view.html',
                ncyBreadcrumb: {
                    skip: true
                },
                data: {
                    permissions: ['user_permission_view_roles']
                }
            });
    }

    function registerRoutesList ($stateProvider) {
        $stateProvider
            .state('dashboard.admin.roles.list', {
                url: '/roles?page&limit&order&id&name',
                parent: 'dashboard.admin.roles',
                data: {
                    permissions: ['user_permission_view_roles']
                },
                views: {
                    "@dashboard.admin.roles": {
                        templateUrl: '/modules/admin/roles/role-list.html',
                        controller: 'RolesController',
                        controllerAs: 'vm'
                    }
                },
                resolve: {
                    roleCollectionResponse: function (apiClient, $stateParams, rootResourceResponse) {
                        return apiClient.get(rootResourceResponse.getLink('roleaccesses'), $stateParams);
                    }
                },
                params: {
                    page: "1",
                    limit: "30",
                    order: "id",
                    id: null,
                    name: null
                },
                ncyBreadcrumb: {
                    label: '{{ ::"ROLES" | translate }}'
                }
            });
    }

    function registerRoutesAdd ($stateProvider) {
        $stateProvider
            .state('dashboard.admin.roles.list.add', {
                url: '/add',
                parent: 'dashboard.admin.roles.list',
                data: {
                    permissions: ['user_permission_manage_roles']
                },
                views: {
                    "@dashboard.admin.roles": {
                        templateUrl: '/modules/admin/roles/add-edit/role-form.html',
                        controller: 'RoleAddEditController',
                        controllerAs: 'vm'
                    }
                },
                resolve: {
                    roleResponse: function () {
                        return {};
                    }
                },
                ncyBreadcrumb: {
                    label: '{{ ::"ADD" | translate }}'
                }
            });
    }

    function registerRoutesDetails ($stateProvider) {
        $stateProvider
            .state('dashboard.admin.roles.details', {
                url: '/details/{resource}',
                abstract: true,
                parent: 'dashboard.admin.roles.list',
                data: {
                    permissions: ['user_permission_view_roles']
                },
                views: {
                    "@dashboard.admin.roles": {
                        templateUrl: '/modules/admin/roles/details/role-details.html',
                        controller: 'RoleDetailsController',
                        controllerAs: 'vm'
                    }
                },
                resolve: {
                    roleResponse: function (apiClient, $stateParams) {
                        return apiClient.get($stateParams.resource.decode()).then(function (response) {
                            return response || apiClient.noResourceFound();
                        });
                    }
                },
                ncyBreadcrumb: {
                    label: '{{ ::$resolves().roleResponse.name }}'
                }
            })
            .state('dashboard.admin.roles.details.info', {
                url: '/info',
                parent: 'dashboard.admin.roles.details',
                data: {
                    permissions: ['user_permission_view_roles']
                },
                views: {
                    '@dashboard.admin.roles.details': {
                        templateUrl: '/modules/admin/roles/details/info/role-info.html',
                        controller: 'RoleDetailsInfoController',
                        controllerAs: 'vm'
                    }
                },
                ncyBreadcrumb: {
                    label: '{{ ::"INFO" | translate }}'
                }
            })
            .state('dashboard.admin.roles.details.user-permissions', {
                url: '/user-permissions',
                parent: 'dashboard.admin.roles.details',
                data: {
                    permissions: ['user_permission_view_roles']
                },
                views: {
                    "@dashboard.admin.roles.details": {
                        templateUrl: '/modules/common/permissions/permissions.html',
                        controller: 'PermissionsController',
                        controllerAs: 'vm'
                    }
                },
                resolve: {
                    permissionService: function (userPermissions) {
                        return userPermissions;
                    },
                    permissionKey: function () {
                        return 'permissions';
                    },
                    entityResponse: function (roleResponse) {
                        return roleResponse;
                    },
                    entityName: function () {
                        return 'ROLE';
                    }
                },
                ncyBreadcrumb: {
                    label: '{{ ::"USER_PERMISSIONS" | translate }}'
                }
            })
            .state('dashboard.admin.roles.details.site-permissions', {
                url: '/site-permissions',
                parent: 'dashboard.admin.roles.details',
                data: {
                    permissions: ['user_permission_view_roles']
                },
                views: {
                    "@dashboard.admin.roles.details": {
                        templateUrl: '/modules/common/permissions/permissions.html',
                        controller: 'PermissionsController',
                        controllerAs: 'vm'
                    }
                },
                resolve: {
                    permissionService: function (sitePermissions) {
                        return sitePermissions;
                    },
                    permissionKey: function () {
                        return 'permissions';
                    },
                    entityResponse: function (roleResponse) {
                        return roleResponse;
                    },
                    entityName: function () {
                        return 'ROLE';
                    }
                },
                ncyBreadcrumb: {
                    label: '{{ ::"SITE_PERMISSIONS" | translate }}'
                }
            })
            .state('dashboard.admin.roles.details.operative-permissions', {
                url: '/operative-permissions',
                parent: 'dashboard.admin.roles.details',
                data: {
                    permissions: ['user_permission_view_roles']
                },
                views: {
                    "@dashboard.admin.roles.details": {
                        templateUrl: '/modules/common/permissions/permissions.html',
                        controller: 'PermissionsController',
                        controllerAs: 'vm'
                    }
                },
                resolve: {
                    permissionService: function (operativePermissions) {
                        return operativePermissions;
                    },
                    permissionKey: function () {
                        return 'permissions';
                    },
                    entityResponse: function (roleResponse) {
                        return roleResponse;
                    },
                    entityName: function () {
                        return 'ROLE';
                    }
                },
                ncyBreadcrumb: {
                    label: '{{ ::"OPERATIVE_PERMISSIONS" | translate }}'
                }
            })
            .state('dashboard.admin.roles.details.report-permissions', {
                url: '/report-permissions',
                parent: 'dashboard.admin.roles.details',
                data: {
                    permissions: ['user_permission_view_roles']
                },
                views: {
                    "@dashboard.admin.roles.details": {
                        templateUrl: '/modules/common/permissions/permissions.html',
                        controller: 'PermissionsController',
                        controllerAs: 'vm'
                    }
                },
                resolve: {
                    permissionService: function (reportPermissions) {
                        return reportPermissions;
                    },
                    permissionKey: function () {
                        return 'permissions';
                    },
                    entityResponse: function (roleResponse) {
                        return roleResponse;
                    },
                    entityName: function () {
                        return 'ROLE';
                    }
                },
                ncyBreadcrumb: {
                    label: '{{ ::"REPORT_PERMISSIONS" | translate }}'
                }
            })
            .state('dashboard.admin.roles.details.users', {
                url: '/users',
                parent: 'dashboard.admin.roles.details',
                data: {
                    permissions: ['user_permission_view_roles']
                },
                views: {
                    '@dashboard.admin.roles.details': {
                        templateUrl: '/modules/admin/roles/details/users/role-users.html',
                        controller: 'RoleUsersController',
                        controllerAs: 'vm',
                    }
                },
                resolve: {
                    userCollectionResponse: function ($stateParams, apiClient, rootResourceResponse) {
                        return apiClient.getPage(rootResourceResponse.getLink('users'), {status: 'active'});
                    }
                },
                ncyBreadcrumb: {
                    label: '{{ ::"USERS" | translate }}'
                }
            })
            .state('dashboard.admin.roles.details.info.edit', {
                url: '/edit',
                parent: 'dashboard.admin.roles.details.info',
                data: {
                    permissions: ['user_permission_manage_roles']
                },
                views: {
                    "@dashboard.admin.roles.details": {
                        templateUrl: '/modules/admin/roles/add-edit/role-form.html',
                        controller: 'RoleAddEditController',
                        controllerAs: 'vm'
                    }
                },
                ncyBreadcrumb: {
                    label: '{{ ::"EDIT" | translate }}'
                }
            });
    }
})();
