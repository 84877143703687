(function () {
    'use strict';

    angular
        .module('elogbooksFilters')
        .filter('absencePlannerTypes', absencePlannerTypes);

    function absencePlannerTypes ($filter) {
        var statuses = {
            0: 'TYPE_HOLIDAY',
            1: 'TYPE_SICKNESS',
            2: 'TYPE_UNAUTHORISED',
            3: 'TYPE_MATERNITY_PATERNITY_LEAVE',
            4: 'TYPE_COMPASSIONATE',
            5: 'TYPE_OTHER',
        };

        return function (input) {
            return $filter('translate')(statuses[input]);
        }
    };
})();
