(function() {
    'use strict';

    angular
        .module('elogbooks')
        .config(Translations);

    function Translations($translateProvider) {
        $translateProvider.translations('nl', {
            P2PREFERENCE: 'P2P referentie',
            P2P:'P2P - Proactis',
            P2P_CIS:'CIS',
            P2P_TAX_CODE:'BTW Code',
            P2P_COMMENT: 'Commentaar',
            P2P_DESCRIPTION: 'Omschrijving',
            P2P_TRANSACTION: 'Transaction Type',
            P2P_SUPPLIER: 'Leverancier',
            P2P_SCHEDULE_NUMBER: 'Verdeelsleutel',
            P2P_NOMINAL_LINES: 'Nominal lines',
            P2P_EXPENSE_CODE: 'Kosten code',
            P2P_TENANT_CODE: 'Huurder Id',
            P2P_RATIO: 'Ratio (% van kosten)',
            P2P_PO_SUPPLIER: 'P2P Leverancier',
            CANNOT_VALIDATE_ROLE:'Can benodigde rol niet valideren',
            P2P_VALUE:'Waarde',
            ADD_P2P_VALUE:'Voeg P2P waarde toe',
            ADD_PO_NUMBER: 'PO nummer',
            JOB_VALUE_UPDATED: 'Opdracht waarde aangepast',
            JOB_PO_UPDATED: 'Opdracht PO nummer aangepast',

            ADD_LINE: 'Voeg regel toe',
            REMOVE_LINE: 'Regel verwijderen',

            P2P_NOT_VALID_SITE: 'Geen geldige P2P object referentie gevonden.',
            P2P_NOT_VALID_USER: 'Gebruiker heeft geen geldig P2P Email addres',

            P2P_ERROR_QUOTE_PENDING: 'P2P Offerte in afwachting'

        });
    }
})();
