(function() {
    'use strict';

    angular
        .module('elogbooks.common.quote-requests')
        .controller('CommonQuoteRequestAddController', CommonQuoteRequestAddController);

    CommonQuoteRequestAddController.$inject = [
        '$scope',
        '$state',
        'apiClient',
        'confirmationModal',
        'requestDataFactory',
        'config',
        'siteResponse',
        'actionResponseLink',
        'spillageResponseLink',
        'elbSettings',
        '$stateParams'
    ];

    function CommonQuoteRequestAddController(
        $scope,
        $state,
        apiClient,
        confirmationModal,
        requestDataFactory,
        config,
        siteResponse,
        actionResponseLink,
        spillageResponseLink,
        elbSettings,
        $stateParams
    ) {
        var vm = this;
        vm.data = requestDataFactory.createRequest(
            {
                _links: {
                    location: null
                },
                serviceProviders: {
                    links: []
                },
                files: {
                    links: []
                }
            }
        );
        vm.config = config;
        vm.selectedSite = null;
        vm.submitDisabled = false;
        vm.canNext = false;
        vm.canPrevious = false;
        vm.uploading = false;
        vm.uploadedFile = null;
        vm.siteResponse = null;
        vm.budgetQuoteEnabled = elbSettings.getSetting('budget_quotes').value && !$stateParams.budgetQuoteConversion;

        if (actionResponseLink) {
            vm.data._links.action = {href: actionResponseLink};
        }

        if (spillageResponseLink) {
            vm.data._links.fromSpillage = {href: spillageResponseLink};
        }

        if (siteResponse) {
            vm.locationRequired = siteResponse.locationRequired;
            apiClient.get(siteResponse.getLink('quoterequests'), {limit: 1}).then(function(response) {
                vm.config.createLink = response.getLink('create');
            });
        }

        vm.cancel = cancelAction;
        vm.getRoute = getRoute;

        function cancelAction() {
            return confirmationModal.open(config).result.then(function () {
                var reloadState = $state.current.name.substring(0, $state.current.name.lastIndexOf('.list') + 5);
                $state.go(reloadState, {}, {reload: false});
            });
        }

        function getRoute(route) {
            return $state.current.name.substring(0, $state.current.name.indexOf('.add') + 4) + '.' + route;
        }

        $scope.$watch('vm.uploadedFile', function(newValue) {
            vm.data._links.file = null;
            if (newValue) {
                vm.data._links.file = vm.uploadedFile._links.file;
            }
        });
    }
})();
