(function () {
    'use strict';

    angular
        .module('elogbooks.common.priorities')
        .controller('PrioritySelectModalController', PrioritySelectModalController);

    function PrioritySelectModalController ($uibModalInstance, priorityCollectionResponse, prioritySelectInstructions, entityTypes, lodash, priorityService, elbSettings, $translate) {

        var vm = this;
        vm.prioritySelectInstructions = prioritySelectInstructions;
        vm.close = closeModal;
        vm.submit = submit;
        vm.entityTypes = entityTypes;

        var priorities  = priorityService.addExtraPriorities(priorityCollectionResponse, ['byPlannedDate']);

        lodash.each(priorities.priorities, function (priority) {
            if (priority.name === 'By Planned Date') {
                var colour = elbSettings.getSetting('general_non_priority_colour').value;

                if (typeof colour === 'string') {
                    priority.colour = colour.replace('#','');
                }
            }
        });

        vm.selectedPriorityModel = {
            response: priorities,
            link: null,
            linkParameters: { active: true, entityTypes: vm.entityTypes },
            required: true,
            responseKeyPath: 'priorities',
            itemHrefPath: '_links.self.href',
            itemValuePath: 'name',
            onSelect: changePriority,
            onRemove: changePriority
        };

        function changePriority() {
            vm.isByPlannedDate = vm.selectedPriorityModel.selected.value === $translate.instant('BY_PLANNED_DATE');
        }

        function submit () {
            $uibModalInstance.close({priority:vm.selectedPriorityModel.selected, summary:vm.summary, isByPlannedDate:vm.completionDueAt, description: vm.description});
        }

        function closeModal () {
            $uibModalInstance.dismiss('cancel');
        }
    }
})();
