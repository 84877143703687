angular.module('elogbooksServices').service('messenger', function (lodash, $interval, $translate) {

    var messages = {
        error: [],
        warning: [],
        info: [],
        success: []
    }, self = this;

    this.error = function (message, parameters, value) {
        self.generateMessage('error', message, parameters, value);
    };

    this.warning = function (message, parameters, value) {
        self.generateMessage('warning', message, parameters, value);
    };

    this.info = function (message, parameters, value) {
        self.generateMessage('info', message, parameters, value);
    };

    this.success = function (message, parameters, value) {
        self.generateMessage('success', message, parameters, value);
    };

    this.generateMessage = function(type, message, parameters, value){
        var translatedMessage = self.translate(message, parameters);

        var fullMessage = translatedMessage + (value ? ' ('+value+')' : '')

        if (type === 'error') {
            this.clear(fullMessage);
        }

        messages[type].push(fullMessage);

        $interval(function () {
            self.clear(fullMessage);
        }, 7500, 1);
    };

    messages.count = function () {
        return (
            messages.error.length +
            messages.warning.length +
            messages.info.length +
            messages.success.length
        );
    };

    this.getMessages = function () {
        return messages;
    };

    this.clear = function (acknowledgedMessage) {
        lodash.each(messages, function (type,key) {
           messages[key] = lodash.reject(type, function (message) {
                return message === acknowledgedMessage;
            });
        });
    };

    this.clearAll = function () {
        lodash.each(messages, function (type,key) {
            messages[key] = [];
        });
    };

    this.translate = function(message, parameters) {
        return $translate.instant(message, parameters);
    };

    return this;
});
