(function(){
    'use strict';

    angular
        .module('elogbooksControllers')
        .controller('RecurrenceAddEditModalController', RecurrenceAddEditModalController);

    RecurrenceAddEditModalController.$inject = [
        'frequencyKey',
        'recurrenceRules',
        'statutoryTypes',
        'maxRules',
        '$uibModalInstance',
        'lodash',
        'recurrenceRulesService',
        'documentTypeCollectionResponse',
        'hideMonthLastWeekDay',
        'modulesService'
    ];

    function RecurrenceAddEditModalController(
        frequencyKey,
        recurrenceRules,
        statutoryTypes,
        maxRules,
        $uibModalInstance,
        lodash,
        recurrenceRulesService,
        documentTypeCollectionResponse,
        hideMonthLastWeekDay,
        modulesService
    ) {

        var vm = this;

        vm.validationError = false;
        vm.changeFrequency = changeFrequency;
        vm.changePrefix = changePrefix;
        vm.addFrequency = addFrequency;
        vm.editFrequency = editFrequency;
        vm.clearFrequency = clearFrequency;
        vm.close = close;
        vm.editKey = false;
        vm.recurrenceRules = angular.copy(recurrenceRules);
        vm.recurrenceRulesService = recurrenceRulesService;
        vm.statutoryTypes = statutoryTypes;
        vm.maxRules = maxRules;
        vm.documentTypes = documentTypeCollectionResponse.documentTypes;
        vm.modalFormSubmitted = false;
        vm.hideMonthLastWeekDay = hideMonthLastWeekDay;
        vm.pointsModuleEnabled = modulesService.isEnabled('points_module');

        if (typeof vm.recurrenceRules === 'undefined') {
            vm.recurrenceRules = [];
        }

        if (documentTypeCollectionResponse.count > 0) {
            vm.documentTypeModel = {
                response: documentTypeCollectionResponse,
                link: documentTypeCollectionResponse ? documentTypeCollectionResponse.getLink('self') : null,
                linkParameters: {status: 'active', order: 'name'},
                responseKeyPath: 'documentTypes'
            };
        }

        if (typeof vm.statutoryTypes !== 'undefined') {
            vm.isStatutory = true;
            vm.isStatutoryConfirm = false;
            vm.statutoryTypesSelect = {
                response : vm.statutoryTypes,
                link : vm.statutoryTypes ? vm.statutoryTypes.getLink('self') : null,
                responseKeyPath: 'statutorytypes'
            };
        }

        function close() {
            $uibModalInstance.close(recurrenceRules);
        }

        /**
         * Show and hide rows based on the frequency type.
         */
        function changeFrequency() {
            // clear all sub-selects on frequency change
            vm.rule.interval = null;
            vm.rule.day = null;
            vm.rule.dayNumber = null;
            vm.rule.month = null;
            vm.rule.prefix = null;

            vm.dayRow = false;
            vm.dayNumberRow = false;
            vm.prefixRow = true;
            vm.monthRow = true;

            if (vm.rule.frequency === recurrenceRulesService.frequencies.weekly) {
                //frequency is weekly, disable month and prefix selection
                vm.dayRow = true;
                vm.prefixRow = false;
                vm.rule.prefix = null;
                vm.rule.dayNumber = null;
                vm.monthRow = false;
                vm.dayWithLastWeekDayRow = false;
            } else if (vm.rule.frequency === recurrenceRulesService.frequencies.monthly) {
                vm.prefixRow = true;
                vm.monthRow = false;
            }
        }

        /**
         * Show and hide day related rows based on the prefix.
         */
        function changePrefix() {
            vm.rule.day = null;
            if (vm.rule.prefix === 0) {
                vm.dayNumberRow = true;
                vm.dayRow = false;
                vm.dayWithLastWeekDayRow = false;
            } else if (vm.rule.prefix === -1) {
                vm.dayNumberRow = false;
                vm.dayRow = vm.hideMonthLastWeekDay;
                vm.rule.dayNumber = null;
                vm.dayWithLastWeekDayRow = !vm.hideMonthLastWeekDay;
            } else {
                vm.rule.dayNumber = null;
                vm.dayNumberRow = false;
                vm.dayRow = true;
                vm.dayWithLastWeekDayRow = false;
            }
        }

        /**
         * Transform form frequency to api format and store in model
         * Will also update edited rule
         */
        function addFrequency() {
            //TRANSFORM BEFORE SAVING
            var rule = {
                frequency : vm.rule.frequency,
                interval : vm.rule.interval,
                serviceRoutineLine: vm.rule.serviceRoutineLine,
                from: vm.rule.from,
                generateRequestRuleId: vm.rule.generateRequestRuleId,
                _links : {

                }
            };

            if (vm.isStatutoryConfirm) {
                if(vm.statutoryTypesSelect.selected === null){
                    vm.validationError = true;
                    return;
                }
                rule._links.statutorytype = vm.statutoryTypesSelect.selected;
            }

            if (angular.isNumber(vm.rule.day) && vm.rule.day !== -1) {
                rule.byDay = [];
                if (vm.rule.prefix) {
                    rule.byDay.push([vm.rule.prefix, vm.rule.day]);
                } else {
                    rule.byDay.push([0, vm.rule.day]);
                }
            } else if (angular.isNumber(vm.rule.day) && vm.rule.day === -1) {
                    rule.byDay = [[-1,1], [-1,2], [-1,3], [-1,4], [-1,5]];
                    rule.bySetPosition = [-1];
            }

            if (vm.rule.month) {
                rule.byMonth = [vm.rule.month];
            }

            if (angular.isNumber(vm.rule.dayNumber)) {
                rule.byMonthDay = [vm.rule.dayNumber];
            }

            if (vm.rule.description) {
                rule.description = vm.rule.description;
            }

            if (vm.documentTypeModel && vm.documentTypeModel.selected) {
                   rule._links.documentType = {
                       href : vm.documentTypeModel.selected.href,
                       title : vm.documentTypeModel.selected.value
                   };
            }

            if (vm.editKey !== false) {
                vm.recurrenceRules[vm.editKey] = rule;
            } else {
                vm.recurrenceRules.push(rule);
            }

            if (vm.pointsModuleEnabled) {
                rule.points = vm.rule.points;
                rule.hours = vm.rule.hours;
                rule.minutes = vm.rule.minutes;
            }

            // Order rules
            vm.recurrenceRules = lodash.orderBy(vm.recurrenceRules, ['frequency', 'interval'], ['desc', 'asc']);
            clearFrequency();
            $uibModalInstance.close(vm.recurrenceRules);
        }

        /**
         * should only be used on opening modal
         */
        function editFrequency(key) {
            vm.editKey = key;
            var tempFrequency = vm.recurrenceRules[key];
            vm.rule.frequency = tempFrequency.frequency;
            vm.rule.description = tempFrequency.description;
            vm.rule.from = tempFrequency.from;
            vm.rule.generateRequestRuleId = tempFrequency.generateRequestRuleId;

            if (vm.pointsModuleEnabled) {
                vm.rule.points = tempFrequency.points;
                vm.rule.hours = tempFrequency.hours;
                vm.rule.minutes = tempFrequency.minutes;
            }

            if (tempFrequency._links && tempFrequency._links.documentType) {
                vm.documentTypeModel.selected = tempFrequency._links.documentType;
            }

            changeFrequency();
            vm.rule.interval = tempFrequency.interval;

            if (tempFrequency.serviceRoutineLine) {
                vm.rule.serviceRoutineLine = tempFrequency.serviceRoutineLine;
            }

            if (tempFrequency.byDay && tempFrequency.byDay.length > 0) {
                if (tempFrequency.byDay[0][0]) {
                    vm.rule.prefix = tempFrequency.byDay[0][0];
                    changePrefix();
                }
                vm.rule.day = tempFrequency.byDay[0][1];
            }

            if (tempFrequency.bySetPosition && tempFrequency.bySetPosition.length > 0 && tempFrequency.byDay && tempFrequency.byDay.length === 5) {
                if (tempFrequency.bySetPosition[0] === -1) {
                    vm.rule.prefix = -1;
                    changePrefix();
                    vm.rule.day = -1;
                }


            }

            if (tempFrequency.byMonthDay && tempFrequency.byMonthDay.length > 0) {
                if (tempFrequency.byMonthDay[0] >= 0) {
                    vm.rule.dayNumber = tempFrequency.byMonthDay[0];
                    vm.rule.prefix = 0;
                }

                changePrefix();
            }

            if (tempFrequency.byMonth && tempFrequency.byMonth.length > 0) {
                vm.rule.month = parseInt(tempFrequency.byMonth[0]);
            }

            if (tempFrequency._links && tempFrequency._links.statutorytype) {
                vm.statutoryTypesSelect.selected = tempFrequency._links.statutorytype;
                vm.isStatutoryConfirm = true;
            }
        }

        /**
         * Clear frequency and setup rule object
         */
        function clearFrequency() {
            vm.rule = {
                frequency : null,
                interval : null,
                day : null,
                dayNumber : null,
                prefix : null,
                month : null
            };

            if (vm.pointsModuleEnabled) {
                vm.rule.points = null;
                vm.rule.hours = null;
                vm.rule.minutes = null;
            }

            vm.dayRow = false;
            vm.dayWithLastWeekDayRow = false;
            vm.dayNumberRow = false;
            vm.prefixRow = false;
            vm.monthRow = false;
            vm.validationError = false;

            vm.isStatutoryConfirm = false;
            if (typeof vm.statutoryTypes !== 'undefined') {
                vm.statutoryTypesSelect.selected = null;
            }
            vm.overMax = vm.recurrenceRules.length >= vm.maxRules;
        }

        clearFrequency();

        if (frequencyKey !== undefined && frequencyKey !== null) {
            editFrequency(frequencyKey)
            vm.title = "EDIT_FREQUENCY";
        } else {
            vm.title = "PLEASE_ADD_FREQUENCY";
        }
    }
})();
