app.config(function ($translateProvider) {
    $translateProvider.translations('nl', {
        GENERATE_NEW_PASSWORD: 'Maak automatisch wachtwoord',
        RESET_PASSWORD: 'Reset wachtwoord ',
        RESET_PASSWORD_INSTRUCTIONS: 'Maak automatisch wachtwoord',
        MODIFY_PASSWORD: 'Aanpassen wachtwoord',
        CHANGE_PASSWORD: 'Wijzigen wachtwoord',
        NEW_PASSWORD: 'Nieuw wachtwoord',
        CONFIRM_PASSWORD: 'Bevestig wachtwoord',
        NEW_PASSWORD_GENERATED: 'Nieuw wachtwoord aangemaakt',
        LOGIN_AS_USER: 'Login als deze gebruiker',
        RETURN_TO_ORIGINAL_USER_FIRST: 'Ga eerst terug naar originele gebruiker'
    });
});
