(function () {
    'use strict';

    angular
        .module('elogbooks.admin.request-logs')
        .config(registerRoutes)
        .config(registerRoutesDetails)
        ;

    function registerRoutes($stateProvider) {
        $stateProvider
            .state('dashboard.admin.request-logs', {
                abstract: true,
                templateUrl: 'shared-templates/view.html',
                ncyBreadcrumb: {
                    skip: true
                }
            })
            .state('dashboard.admin.request-logs.list', {
                parent: 'dashboard.admin.request-logs',
                url: '/request-log?page&limit&order&type&webhookType&responseStatusCode',
                views: {
                    '@dashboard.admin.request-logs': {
                        templateUrl: '/modules/admin/request-logs/list/list.html',
                        controller: 'RequestLogsListController',
                        controllerAs: 'vm'
                    }
                },
                params: {
                    page: '1',
                    limit: '30',
                    type: 'all',
                    webhookType: 'all',
                    responseStatusCode: null,
                    dateRange: moment().subtract(30, 'days').startOf('day').format()+','+moment().endOf('day').format()
                },
                resolve: {
                    requestLogsCollectionResponse: function ($stateParams, apiClient, rootResourceResponse) {
                        var params = angular.copy($stateParams);

                        return apiClient.get(rootResourceResponse.getLink('request-logs'), params).then(function (response) {
                            return response || apiClient.noResourceFound();
                        });
                    }
                },
                ncyBreadcrumb: {
                    label: '{{ ::"REQUEST_LOGS" | translate }}'
                }
            });
    }

    function registerRoutesDetails($stateProvider) {
        $stateProvider
            .state('dashboard.admin.request-logs.list.details', {
                url: '/details/{resource}',
                parent: 'dashboard.admin.request-logs.list',
                abstract: true,
                views: {
                    '@dashboard.admin.request-logs': {
                        templateUrl: '/modules/admin/request-logs/details/request-log-details.html',
                        controller: 'RequestLogDetailsController',
                        controllerAs: 'vm'
                    }
                },
                resolve: {
                    requestLogResponse: function (apiClient, $stateParams) {
                        return apiClient.get($stateParams.resource.decode()).then(function (response) {
                            return response || apiClient.noResourceFound();
                        });
                    }
                },
                ncyBreadcrumb: {
                    label: '{{ ::$resolves().requestLogResponse.id }}'
                }
            })
            .state('dashboard.admin.request-logs.list.details.info', {
                url: '/info',
                parent: 'dashboard.admin.request-logs.list.details',
                views: {
                    '@dashboard.admin.request-logs.list.details': {
                        templateUrl: '/modules/admin/request-logs/details/info/request-log-info.html',
                        controller: 'RequestLogInfoController',
                        controllerAs: 'vm'
                    }
                },
                ncyBreadcrumb: {
                    label: '{{ ::"INFO" | translate }}'
                }
            })
            .state('dashboard.admin.request-logs.list.details.responses', {
                url: '/responses?responsePage&responseLimit',
                parent: 'dashboard.admin.request-logs.list.details',
                views: {
                    '@dashboard.admin.request-logs.list.details': {
                        templateUrl: '/modules/admin/request-logs/details/responses/response-list.html',
                        controller: 'RequestLogResponsesController',
                        controllerAs: 'vm'
                    }
                },
                params: {
                    responsePage: '1',
                    responseLimit: '30',
                },
                resolve: {
                    requestResponsesResponse: function (apiClient, $stateParams, requestLogResponse) {
                        var params = angular.merge($stateParams);
                        params.page = $stateParams.responsePage;
                        params.limit = $stateParams.responseLimit;

                        delete params.type;
                        delete params.webhookType;
                        delete params.responseStatusCode;

                        if (requestLogResponse.getLink('responses')) {
                            return apiClient.get(requestLogResponse.getLink('responses'), params).then(function (response) {
                                return response || apiClient.noResourceFound();
                            });
                        }
                    }
                },
                ncyBreadcrumb: {
                    label: '{{ ::"RESPONSES" | translate }}'
                }
            })
            .state('dashboard.admin.request-logs.list.details.responses.details', {
                url: '/details/{responseResource}',
                parent: 'dashboard.admin.request-logs.list.details.responses',
                views: {
                    '@dashboard.admin.request-logs.list.details': {
                        templateUrl: '/modules/admin/request-logs/details/responses/details/response-detail.html',
                        controller: 'ResponseDetailsListController',
                        controllerAs: 'vm'
                    }
                },
                resolve: {
                    responseDetailsResponse: function (apiClient, $stateParams) {
                        return apiClient.get($stateParams.responseResource.decode()).then(function (response) {
                            return response || apiClient.noResourceFound();
                        });
                    }
                },
                ncyBreadcrumb: {
                    label: '{{ ::"RESPONSE" | translate }}'
                }
            });
    }
})();