(function () {
    'use strict';

    angular
        .module('elogbooks.user.manage-operatives')
        .controller('UserManageOperativeDetailsController', UserManageOperativeDetailsController);

    UserManageOperativeDetailsController.$inject = ['operativeResponse'];

    function UserManageOperativeDetailsController(operativeResponse) {
        var vm = this;
        vm.operativeName = operativeResponse.name;
    }
})();
