(function () {
    'use strict';

    angular
        .module('elogbooks.admin.serviceroutines')
        .controller('ServiceRoutineLineAddEditController', ServiceRoutineLineAddEditController);

    ServiceRoutineLineAddEditController.$inject = [
        'serviceRoutineLineCollectionResponse',
        'serviceRoutineLineResponse',
        'statutoryTypesCollectionResponse',
        'crudService',
        'requestDataFactory',
        '$translate',
        'calendarRuleService',
        'elbSettings'
    ];

    function ServiceRoutineLineAddEditController (
        serviceRoutineLineCollectionResponse,
        serviceRoutineLineResponse,
        statutoryTypesCollectionResponse,
        crudService,
        requestDataFactory,
        $translate,
        calendarRuleService,
        elbSettings
    ) {
        var vm = this;
        vm.serviceRoutineLine = serviceRoutineLineResponse;
        vm.serviceRoutineLineCollectionResponse = serviceRoutineLineCollectionResponse;
        vm.statutoryTypes = statutoryTypesCollectionResponse.statutorytypes;
        vm.data = requestDataFactory.createRequest(vm.serviceRoutineLine);
        vm.isStatutory = !!(typeof serviceRoutineLineResponse.getLink === "function" && serviceRoutineLineResponse.getLink('statutorytype'));
        vm.hours = vm.minutes = null;
        vm.pointsModuleEnabled = elbSettings.getSetting('points_module_enabled') ? elbSettings.getSetting('points_module_enabled').value : false;

        if (vm.serviceRoutineLine.minutes) {
            vm.hours = Math.floor(vm.serviceRoutineLine.minutes / 60);
            vm.minutes = vm.serviceRoutineLine.minutes % 60;
        }

        if (vm.data.frequency) {
            vm.data.frequency = vm.data.frequency.toLowerCase();
        }

        // specify frequency options, (no daily option)
        vm.frequencyOptions =   {
            weekly: {value: "weekly", title: $translate.instant("WEEKLY")},
            monthly: {value: "monthly", title: $translate.instant("MONTHLY")},
            yearly: {value: "yearly", title: $translate.instant("YEARLY")}
        };

        vm.create = createAction;
        vm.update = updateAction;
        vm.delete = deleteAction;

        function createAction () {
            if (!vm.isStatutory) {
                delete vm.data._links;
            }

            vm.data.minutes = (vm.hours * 60) + vm.minutes;

            return crudService.create(
                vm.serviceRoutineLineCollectionResponse.getLink('create'),
                vm.data,
                'dashboard.admin.service-routines.list.details.service-routine-lines',
                null,
                'SERVICE_ROUTINE_LINE'
            );
        }

        function updateAction () {
            if (!vm.isStatutory) {
                delete vm.data._links;
            }

            vm.data.minutes = (vm.hours * 60) + vm.minutes;

            return crudService.update(
                vm.serviceRoutineLine.getLink('edit'),
                vm.data,
                'dashboard.admin.service-routines.list.details.service-routine-lines',
                'dashboard.admin.service-routines.list.details.service-routine-lines',
                'SERVICE_ROUTINE_LINE'
            );
        }

        function deleteAction () {
            return crudService.delete(
                vm.serviceRoutineLine.getLink('delete'),
                'dashboard.admin.service-routines.list.details.service-routine-lines',
                'SERVICE_ROUTINE_LINE'
            );
        }
    }
})();
