(function() {
    'use strict';

    angular
        .module('elogbooks')
        .config(Translations);

    function Translations($translateProvider) {
        $translateProvider.translations('en-gb', {
            MESSAGE: 'Message',
            MESSAGES: 'Messages',

            UPDATE_ADD: 'Add Update',

            MESSAGE_VISIBILITY: 'Visibility',
            MESSAGE_VISIBILITY_PUBLIC: 'Public',
            MESSAGE_VISIBILITY_HELPDESK: 'Helpdesk',

            MESSAGE_DIRECTION: 'Direction',
            MESSAGE_DIRECTION_INBOUND: 'Inbound',
            MESSAGE_DIRECTION_OUTBOUND: 'Outbound',

            MESSAGE_WRITE_NEW: 'Write a Message',

            MESSAGE_SEND: 'Send'
        });
    }
})();
