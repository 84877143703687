(function () {
    'use strict';

    angular
        .module('elogbooksControllers')
        .controller('UserSiteAccessFormController', function (
            $state,
            apiClient,
            membershipsResponse,
            userResponse,
            $rootScope
        ) {
            var vm = this;

            vm.user = userResponse;
            vm.memberships = membershipsResponse.memberships;
            vm.submitDisabled = false;

            vm.create = createAction;

            // Remove from the list of available sites those user already has access to.
            var linkParameters = { excludeUserSites: true, userId: userResponse.id };

            vm.siteSelect = {
                link : '/sites',
                responseKeyPath: 'sites',
                searchKey: 'siteName',
                required: true,
                linkParameters: linkParameters,
                apiClientCache: false,
                formatValue: (object) => {
                    if (!object) {
                        return;
                    }
                    return object.isActive ? object.name : `${object.name} (inactive)`
                },
            };

            function createAction() {
                $rootScope.skipDirtyCheck = true;
                vm.submitDisabled = true;

                if (!vm.siteSelect.selectedItems.length) {
                    return;
                }

                var data = {
                    _links: {
                        user: [
                            vm.user._links.self
                        ]
                    }
                };

                if (vm.siteSelect.selectedItems.length) {
                    data._links.site = vm.siteSelect.selectedItems;
                }

                apiClient.create(membershipsResponse.getLink('create'), data).then(function(response) {
                    if (response) {
                        $state.go('dashboard.admin.users.list.details.site-access', {}, {reload: 'dashboard.admin.users.list.details'}).then(function() {
                            vm.submitDisabled = false;
                            $rootScope.skipDirtyCheck = false;
                        });
                    }
                });
            };
        });
})();
