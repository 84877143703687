(function() {
    'use strict';

    angular
        .module('elogbooks.admin.pricing-schedules')
        .controller('PricingSchedulesStandardChargesController', PricingSchedulesStandardChargesController);

    PricingSchedulesStandardChargesController.$inject = ['standardChargesCollectionResponse'];

    function PricingSchedulesStandardChargesController(standardChargesCollectionResponse) {
        var vm = this;
        vm.standardCharges = standardChargesCollectionResponse.standardCharges;
        vm.standardChargesCollectionResponse = standardChargesCollectionResponse;
        vm.pages = vm.standardChargesCollectionResponse.pages;
    }
})();
