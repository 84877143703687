(function () {
    "use strict";

    angular
        .module("elogbooks.user.integrations")
        .config(registerRoutes)
        .config(registerProsureRoutes);

    function registerRoutes($stateProvider) {
        $stateProvider
            .state("dashboard.user.integrations", {
                abstract: true,
                templateUrl: "/shared-templates/view.html",
                ncyBreadcrumb: {
                    skip: true,
                }
            })
            .state("dashboard.user.integrations.abstract", {
                url: "/integrations",
                abstract: true,
                parent: "dashboard.user.integrations",
                views: {
                    "@dashboard.user.integrations": {
                        templateUrl:
                            "/modules/user/integrations/integrations.html",
                        controller: "UserIntegrationsController",
                        controllerAs: "vm",
                    },
                },
                ncyBreadcrumb: {
                    label: '{{ ::"USER_INTEGRATIONS" | translate }}',
                }
            });
    }

    function registerProsureRoutes($stateProvider) {
        $stateProvider.state("dashboard.user.integrations.abstract.prosure", {
            url: "/prosure?company",
            parent: "dashboard.user.integrations.abstract",
            resolve: {
                userResponse: function (user) {
                    return user;
                },
                userOperativesCollection: function (user, apiClient) {
                    return apiClient
                        .get(user.getLink("operatives"))
                        .then(function (response) {
                            return response || apiClient.noResourceFound();
                        });
                }
            },
            views: {
                "@dashboard.user.integrations.abstract": {
                    templateUrl:
                        "/modules/user/integrations/prosure/prosure-setup.html",
                    controller: "ProsureController",
                    controllerAs: "vm",
                }
            },
            ncyBreadcrumb: {
                label: '{{ ::"USER_INTEGRATIONS_PROSURE" | translate }}',
            }
        });
    }
})();
