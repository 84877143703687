(function() {
    'use strict';

    angular
        .module('elogbooks')
        .config(Translations);

    function Translations($translateProvider) {
        $translateProvider.translations('zh-hk', {
            ACTION_TYPE: '指令類別',
            ACTION_TYPES: '指令類別',
            ACTION_TYPE_LIST: '指令類別',

            ACTION_TYPE_ADD: '新增指令類別',
            ACTION_TYPE_EDIT: '修改指令類別',

            ACTION_TYPE_CREATED: '指令類別已建立',
            ACTION_TYPE_UPDATED: '指令類別已更新',
            ACTION_TYPE_DELETED: '指令類別已刪除'
        });
    }
})();
