app.config( function($translateProvider) {
    $translateProvider.translations('en-gb', {
        SITE_RELATIONSHIPS: 'Site Relationships',
        SITE_RELATIONSHIP_INFORMATION: 'Site Relationship Information',

        ADD_NEW_SITE_RELATIONSHIP: 'Add a new Site Relationship',
        EDIT_SITE_RELATIONSHIP: 'Edit Site Relationship',

        SITE_RELATIONSHIP_LIST: 'Site Relationships',
        SITE_RELATIONSHIP_ADD: 'Add a new Site Relationship',
        SITE_RELATIONSHIP_EDIT: 'Edit Site Relationship',

        SITE_RELATIONSHIP_CREATED: 'Site Relationship created successfully',
        SITE_RELATIONSHIP_UPDATED: 'Site Relationship updated successfully',
        SITE_RELATIONSHIP_DELETED: 'Site Relationship deleted successfully',
    });
});
