(function () {
    'use strict';

    angular
        .module('elogbooks.common.jobs.workflow')
        .controller('JobOperationCompletePaperworkController', JobOperationCompletePaperworkController);

    JobOperationCompletePaperworkController.$inject = [
        '$scope',
        'apiClient',
        '$state',
        'messenger',
        'jobResponse',
        'scopeData',
        'priorityService',
        '$q',
        'user',
        '$translate',
        'elbVisibilityService',
        'tagCollectionResponse',
        'lodash',
        'config',
        'documentTypeCollectionResponse',
        'fileDocumentTypeService',
        'formVersionInstanceCollectionResponse',
        'dateTimeService',
        'jobFilesCollectionResponse',
        'integrationsService',
        'elbSettings',
        'modulesService',
        'moment'
    ];

    function JobOperationCompletePaperworkController(
        $scope,
        apiClient,
        $state,
        messenger,
        jobResponse,
        scopeData,
        priorityService,
        $q,
        user,
        $translate,
        elbVisibilityService,
        tagCollectionResponse,
        lodash,
        config,
        documentTypeCollectionResponse,
        fileDocumentTypeService,
        formVersionInstanceCollectionResponse,
        dateTimeService,
        jobFilesCollectionResponse,
        integrationsService,
        elbSettings,
        modulesService,
        moment
    ) {
        var vm = this;
        vm.job = jobResponse;
        vm.action = 'complete-paperwork';
        vm.translation = 'JOB_WORKFLOW_COMPLETE_PAPERWORK_OPERATION';
        vm.noteRequired = false;
        vm.data = {links_: {}};
        vm.partial = vm.action;
        vm.visibilityService = elbVisibilityService;
        vm.tags = tagCollectionResponse;
        vm.visibilityServiceOptions = [
            { title: $translate.instant('NONE_SELECTED'), value: null },
        ].concat(vm.visibilityService.getVisibilitiesAsObject());
        vm.fileInputId = moment.utc().valueOf();
        vm.fileExpiryNotificationTime = elbSettings.getSetting('file_expiry_notification_time').value;

        vm.config = config;
        vm.requiresPaperwork = vm.job.hasRequiredPaperwork;
        vm.isStatutory = true;
        vm.integrations = [];
        vm.integrationDocCat = {};
        vm.meridianDocType = {};

        vm.submit = submitAction;
        vm.removeFile = removeFile;
        vm.transformTag = transformTag;
        vm.changeStatutory = changeStatutory;
        vm.selectItem = selectItem;
        vm.removeItem = removeItem;
        vm.resetIntegrationDoc = resetIntegrationDoc;
        vm.searchTags = searchTags;
        vm.checkIfNew = checkIfNew;

        vm.showFile = true;
        vm.addFile = addFile;
        vm.addFormFile = addFormFile;
        vm.skipIntegrationFile = {};

        remainingRequiredDocTypes();

        var meridianWorkflowEnabled = elbSettings.getSetting('general_meridian_workflow').value;
        var meridianEnabled = modulesService.isEnabled('meridian');

        if (documentTypeCollectionResponse.count > 0) {
            vm.documentTypeModel = {
                response: documentTypeCollectionResponse,
                link: documentTypeCollectionResponse ? documentTypeCollectionResponse.getLink('self') : null,
                linkParameters: { status: 'active', order: 'name', job: vm.job.id },
                responseKeyPath: 'documentTypes'
            };
        }

        if (formVersionInstanceCollectionResponse.count > 0) {
            vm.formInstanceSheetOptions = [];
            lodash.each(formVersionInstanceCollectionResponse.formVersionInstances, function (formVersionInstance) {
                if (formVersionInstance.isCompleted) {
                    vm.formInstanceSheetOptions.push({
                        value: formVersionInstance.getLink('form-instance-sheet'),
                        title: formVersionInstance._embedded.form.summary
                    })
                }
            });
            sortFormInstanceSheetOptions();
            vm.originalformInstanceSheetOptions = angular.copy(vm.formInstanceSheetOptions);
        }

        // To avoid add time to documentDate in uib-datepicker
        var d = new Date();
        d.setHours(0);
        d.setMinutes(0);
        d.setSeconds(0);
        d.setMilliseconds(0);

        var emptyFile = {
            file: null,
            formFile: null,
            tags: {
                tags: [],
            },
            id: null,
            title: null,
            visibility: null,
            documentDate: meridianEnabled ? null : d,
            expiresAt: null,
            notifyExpiry: false,
            isStatutory: false,
            userTags: [],
            tagsSelected: [],
            tagsToSelect: angular.copy(vm.tags.tags),
            documentTypeModel: vm.documentTypeModel,
            disableExpiresAt: false,
            advancedClosed: false,
            _links: {}
        };

        vm.yesNo = [
            {
                value: false,
                label: $translate.instant('NO')
            },
            {
                value: true,
                label: $translate.instant('YES')
            }
        ];

        // files array
        vm.files = [];
        addFile();

        $scope.$watch('vm.files', function(newVal, oldVal) {
            if (!lodash.isEqual(newVal, oldVal)) {
                if (vm.files[vm.files.length-1].formFile !== null) {
                    addFormFile(vm.files.length-1);
                };

                if (vm.files[vm.files.length-1].file !== null) {
                    addFile();
                } else if (vm.files.length > 1) {
                    var maxDate = new Date();
                    if (vm.files[vm.files.length-2].documentDate && vm.files[vm.files.length-2].documentDate > maxDate) {
                        vm.files[vm.files.length-2].documentDate = maxDate;
                    }
                }

                for (var i = 0, len = newVal.length -1; i < len; i++) {
                    remainingRequiredDocTypes();

                    if (newVal[i]) {
                        if (newVal[i] && newVal[i].tagsSelected.length !== oldVal[i].tagsSelected.length) {
                            vm.files[i].tagsToSelect = emptyFile.tagsToSelect;
                        }

                        if (vm.files[i].title === null) {
                            vm.files[i].title = newVal[i].file.filename;
                        }

                        if (newVal[i] && (newVal[i].documentDate !== oldVal[i].documentDate ||
                                newVal[i].documentTypeModel !== oldVal[i].documentTypeModel)
                        ) {
                            fileDocumentTypeService.calculateExpiresAt(newVal[i].documentTypeModel, vm.files[i]);
                        }

                        var precedentStatutoryName = null;

                        if (oldVal[i] && oldVal[i].documentTypeModel.selected && oldVal[i].documentTypeModel.selected.object._links.statutorytype) {
                            precedentStatutoryName = oldVal[i].documentTypeModel.selected.object._links.statutorytype.title;
                        }

                        if (newVal[i].documentTypeModel.selected && newVal[i].documentTypeModel.selected.href) {
                            if (!oldVal[i].documentTypeModel.selected ||
                                (
                                    oldVal[i].documentTypeModel.selected &&
                                    oldVal[i].documentTypeModel.selected.href !== newVal[i].documentTypeModel.selected.href
                                )
                            ) {
                                oldVal = oldVal[i];
                                newVal = newVal[i];

                                if (newVal && newVal.documentTypeModel.selected && newVal.documentTypeModel.selected.object._links.statutorytype) {
                                    vm.files[i].isStatutory = true;
                                    changeStatutory(i, newVal.documentTypeModel.selected.object._links.statutorytype, precedentStatutoryName);
                                }
                            }
                        } else if (newVal[i].documentTypeModel.selected === undefined && precedentStatutoryName) {
                            vm.files[i].isStatutory = false;
                            changeStatutory(i, null, precedentStatutoryName);
                        }
                    }
                }
            }

        }, true);

        angular.extend(vm, scopeData);

        function sortFormInstanceSheetOptions() {
            vm.formInstanceSheetOptions.sort(function(a,b) {
                return (a.title < b.title ? -1 : (a.title > b.title ? 1 : 0))
            })
        }

        function addFile() {
            var newFile = angular.copy(emptyFile);
            if (vm.files.length) {
                vm.skipIntegrationFile[vm.files.length] = false;
            }
            vm.files.push(newFile);
        }

        function addFormFile(index) {
            var formInstanceSheetLink = vm.files[index].formFile;
            apiClient.get(formInstanceSheetLink, {'macroValue[]': []})
                .then(function (response) {
                    if (response) {
                        //remove selected form from options
                        vm.formInstanceSheetOptions = vm.formInstanceSheetOptions.filter(function(item){
                            return item.value !== formInstanceSheetLink
                        });

                        vm.files[index].file = {
                            _links: {
                                file: response._links.self,
                                resource: response._links.resource
                            },
                            title: response.title,
                            filename: response.filename,
                            type: response.type
                        };
                        vm.files[index].isStatutory = true;
                        changeStatutory(index);
                    } else {
                        messenger.error('REQUEST_ERROR');
                    }
            })
        }

        function remainingRequiredDocTypes() {
            vm.mandatoryDocTypes = [];
            vm.toRemove = [];
            vm.addedDocTypes = [];

            //Files with document type added to job before complete paperwork step
            lodash.each(jobFilesCollectionResponse.files, function (jobFile) {
                vm.addedDocTypes.push(jobFile.getLink('documentType'));
            });

            //Files with document type added on complete paperwork step
            lodash.each(vm.files, function (file) {
                if (file.documentTypeModel.selected) {
                    vm.addedDocTypes.push(file.documentTypeModel.selected.href);
                }
            });

            //Job's required document types
            lodash.each(vm.job.requiredDocumentTypes.documentTypes, function (jobDocType) {
                vm.mandatoryDocTypes.push({name: jobDocType.name, href: jobDocType._links.self.href});
            });

            vm.jobDocTypes = vm.mandatoryDocTypes;
            vm.mandatoryDocTypes = lodash.sortBy(vm.mandatoryDocTypes.filter(function(jobDocType) {
                return vm.addedDocTypes.indexOf(jobDocType.href) === -1;
            }), 'name');
        }

        function removeFile($index) {
            //add selected form back to options
            if (vm.files[$index].formFile) {
                vm.formInstanceSheetOptions.push(lodash.find(vm.originalformInstanceSheetOptions,
                    {value: vm.files[$index].formFile })
                );
                sortFormInstanceSheetOptions();
            }
            vm.files.splice($index, 1);
            remainingRequiredDocTypes();
        }

        function submitAction() {
            vm.data.files = angular.copy(vm.files);

            lodash.each(vm.data.files, function (file) {
                if (file.expiresAt) {
                    file.expiresAt = dateTimeService.setEndOfDay(file.expiresAt);
                }
            });

            remainingRequiredDocTypes();

            if (vm.mandatoryDocTypes.length) {
                var msg = $translate.instant('REQUIRED_DOCUMENT_TYPES_MISSING');
                angular.forEach(vm.mandatoryDocTypes, function (item, index) {
                    msg = msg + item.name;
                    msg = index !== (vm.mandatoryDocTypes.length - 1) ? msg + ' & ' : msg + '.';

                });
                messenger.error(msg);

                return;
            }

            // remove empty records
            lodash.remove(vm.data.files, {file: null});

            // refactor file link, tidy data
            lodash.each(vm.data.files, function(file){
                file._links.file = angular.copy(file.file._links.file);

                if (file.documentTypeModel.selected) {
                    file._links.documentType = { href: file.documentTypeModel.selected.href };
                    delete file.documentTypeModel.selected;
                }
                delete file.file;
                delete file.tagsSelected;
                delete file.disableExpiresAt;
            });

           apiClient.create(vm.job.getLink(vm.action), vm.data).then(function(response) {
               if (response) {
                   $state.go('^.^', {}, { reload: $state.get('^.^.^') }).then(function() {
                       messenger.success(vm.translation + '_SUCCESS');
                   });
               }
            }, function() {
                messenger.error('REQUEST_ERROR');
            });
        }

        function transformTag (tag) {
            var item = {
                type: 'user',
                name: tag
            };

            return item;
        }

        function selectItem (item, $index, fileObject) {
            if (item._links) {
                lodash.remove(fileObject.tagsToSelect, {name: item.name});
                lodash.remove(vm.files[$index].tags.tags, {href: item._links.self.href});
                vm.files[$index].tags.tags.push({href: item._links.self.href});
            } else {
                lodash.remove(vm.files[$index].userTags, {name: item.name});
                vm.files[$index].userTags.push({name: item.name});
            }
        }

        function removeItem (item, $index, fileObject) {
            if (item._links) {
                fileObject.tagsToSelect.push(item);
                lodash.remove(vm.files[$index].tags.tags, {href: item._links.self.href});
            } else {
                lodash.remove(vm.files[$index].userTags, {name: item.name});
                lodash.remove(fileObject.tagsToSelect, {name: item.name});
            }
        }

        function checkIfNew($tag, $index, fileObject) {
            if (!lodash.find(fileObject.tagsToSelect, {name: $tag}) &&
                !lodash.find(vm.files[$index].tagsSelected, {name: $tag})) {
                return $tag + $translate.instant('NEW_TAG');
            } else {
                return $tag;
            }
        }

        // on changing statutory option add/remove statutory user tag
        function changeStatutory($index, statutoryType, precedentStatutoryName) {
            var statutoryName = statutoryType ? statutoryType.title : null;

            if (precedentStatutoryName) {
                // visible selected tag dom elements
                lodash.remove(vm.files[$index].tagsSelected, {name: precedentStatutoryName});

                // newly entered tags
                lodash.remove(vm.files[$index].userTags, {name: precedentStatutoryName});

                // loaded tags
                lodash.remove(vm.files[$index].tags.tags, {name: precedentStatutoryName});

                delete vm.files[$index]._links.statutoryType;
            }

            if (vm.files[$index].isStatutory === true) {
                vm.files[$index].index =  $index;
                initializeIntegrations(vm.files[$index]);
            }

            var tag = lodash.find(vm.files[$index].tagsSelected, function (selectedTag) {
                return selectedTag.name === statutoryName;
            });

            if (tag === undefined && statutoryName) {
                var statutoryTag = transformTag(statutoryName);
                vm.files[$index].tagsSelected.push(statutoryTag);
                vm.selectItem(statutoryTag, $index);
            }

            statutoryType ? vm.files[$index]._links.statutoryType = statutoryType : false;
        }

        function resetIntegrationDoc($integration, $index) {
            if (vm.data.integration !== undefined) {
                vm.data.integration[$integration] = null;
                vm.integrationDocCat[$index] = null;
            }
            delete vm.files[$index].integration[$integration];
        }

        function initializeIntegrations(file) {
            var preselectCallback;

            if (!meridianWorkflowEnabled) {
                preselectCallback = function(integration) {
                    return integrationsService.preSelectDocumentType(integration, vm.integrationDocCat, file);
                }
            }

            if (vm.integrations.length === 0 && jobResponse.getLink('integration-doc-types')) {
                return apiClient.get(jobResponse.getLink('integration-doc-types')).then(function(integrationDocTypesResponse) {
                    vm.integrations = integrationDocTypesResponse ? integrationDocTypesResponse.data : [];
                    vm.hasIntegrations = integrationsService.checkIntegrations(vm.integrations, jobResponse, preselectCallback);
                });
            } else if (vm.integrations.length > 0) {
                vm.hasIntegrations = integrationsService.checkIntegrations(vm.integrations, jobResponse, preselectCallback);
            }
        }

        function searchTags(keyword, fileObject) {
            vm.loading = true;
            if (keyword.length >= 1 && tagCollectionResponse.getLink('self')) {
                apiClient.get(tagCollectionResponse.getLink('self'), {name: keyword})
                    .then(function (response) {
                        vm.loading = false;
                        if (response.tags.length !== 0 ){
                            fileObject.tagsToSelect =  angular.copy(response.tags);
                        }
                    });
            } else {
                vm.loading = false;
            }
        }
    }
})();
