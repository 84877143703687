(function() {
    'use strict';

    angular
        .module('elogbooks')
        .config(Translations);

    function Translations($translateProvider) {
        $translateProvider.translations('zh-hk', {
            PERFORMANCE_STATISTICS: '表現統計',
            PERFORMANCE_SERVICE_PROVIDER: '你的表現',
            PERFORMANCE_STATUS: '狀態',
            PERFORMANCE_SYSTEM: '平均表現',

            PERFORMANCE_TYPE_AVERAGE: '整體表現',
            PERFORMANCE_TYPE_REACTIVE_ATTENDANCE_ACHIEVED: '回覆行動',
            PERFORMANCE_TYPE_REACTIVE_COMPLETION_ACHIEVED: '回覆完成',
            PERFORMANCE_TYPE_PLANNED_COMPLETION_ACHIEVED: 'PPM完成',
            PERFORMANCE_TYPE_QUOTE_SLA_ACHIEVED: '報告SLAs完成',
            PERFORMANCE_TYPE_KPI_SURVEYS: 'KPI調查',

            PERFORMANCE_DASHBOARD_TYPE_AVERAGE: '整體<br />表現',
            PERFORMANCE_DASHBOARD_TYPE_REACTIVE_ATTENDANCE_ACHIEVED: 'SLA<br />回覆行動',
            PERFORMANCE_DASHBOARD_TYPE_REACTIVE_COMPLETION_ACHIEVED: 'SLA<br />回覆完成',
            PERFORMANCE_DASHBOARD_TYPE_PLANNED_COMPLETION_ACHIEVED: 'PPM<br />完成',
            PERFORMANCE_DASHBOARD_TYPE_QUOTE_SLA_ACHIEVED: '報告SLA<br />完成',
            PERFORMANCE_DASHBOARD_TYPE_KPI_SURVEYS: 'KPI<br />調查',

            PERFORMANCE_INFORMATION_TITLE: '表現資料',
            PERFORMANCE_INFORMATION_DESCRIPTION: '這些統計數據是過去30天內所有服務提供商的平均值.',
            PERFORMANCE_TYPE_AVERAGE_INFORMATION: '所有其他百分比的平均值',
            PERFORMANCE_TYPE_REACTIVE_ATTENDANCE_ACHIEVED_INFORMATION: '在SLA期限內參與回覆工作的次數的百分比.',
            PERFORMANCE_TYPE_REACTIVE_COMPLETION_ACHIEVED_INFORMATION: '在SLA期限內完成回覆工作的次數的百分比.',
            PERFORMANCE_TYPE_PLANNED_COMPLETION_ACHIEVED_INFORMATION: '在SLA期限內完成PPM工作的次數的百分比.',
            PERFORMANCE_TYPE_QUOTE_SLA_ACHIEVED_INFORMATION: '在SLA期限內遞交報價的次數的百分比.',
            PERFORMANCE_TYPE_KPI_SURVEYS_INFORMATION: '根據前一個月的FM KPI平均分. 選擇紅綠燈將顯示FM評分小於100％的KPI.',
        });
    }
})();
