(function () {
    'use strict';

    angular
        .module('elogbooks')
        .controller('CertificateTypeDetailsController', CertificateTypeDetailsController);

    CertificateTypeDetailsController.$inject = [];

    function CertificateTypeDetailsController () {

    }
})();
