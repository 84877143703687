(function() {
    'use strict';

    angular
        .module('elogbooks.common.assets')
        .controller('UserAssetsSubAssetAddEditController', UserAssetsSubAssetAddEditController);

    UserAssetsSubAssetAddEditController.$inject = [
        'apiClient',
        'messenger',
        '$state',
        'assetResponse',
        'subAssetResponse',
        'confirmationModal',
        'requestDataFactory',
        'locationsCollectionResponse',
        'assetTypesCollectionResponse',
        'statutoryTypesCollectionResponse',
        'moment',
        '$scope',
        '$translate'
    ];

    function UserAssetsSubAssetAddEditController(apiClient,
        messenger,
        $state,
        assetResponse,
        subAssetResponse,
        confirmationModal,
        requestDataFactory,
        locationsCollectionResponse,
        assetTypesCollectionResponse,
        statutoryTypesCollectionResponse,
        moment,
        $scope,
        $translate
    ) {
        var vm = this;
        vm.data = requestDataFactory.createRequest(subAssetResponse);
        vm.data.isStatutory = false;
        vm.assetResponse = assetResponse;
        vm.subAssetResponse = subAssetResponse;
        vm.hasAssetTypes = !!assetTypesCollectionResponse.assettypes.length;
        vm.assetTypesSelect = {};
        vm.serviceRoutinesSelect = {};

        vm.statutoryTypesSelect = {
            response : statutoryTypesCollectionResponse,
            link : statutoryTypesCollectionResponse ? statutoryTypesCollectionResponse.getLink('self') : null,
            responseKeyPath: 'statutorytypes'
        };
        if (vm.hasAssetTypes) {
            vm.assetTypesSelect = {
                response: assetTypesCollectionResponse,
                link: assetTypesCollectionResponse ? assetTypesCollectionResponse.getLink('self') : null,
                responseKeyPath: 'assettypes',
                isHierarchical: true
            };
        }
        vm.locationSelect = {
            response: locationsCollectionResponse ? locationsCollectionResponse : [],
            selected: null,
            responseKeyPath: 'locations'
        };

        vm.inputFuel = [
            {value: 'electricity', title: $translate.instant('ELECTRICITY')},
            {value: 'gas', title: $translate.instant('GAS')},
            {value: 'coal', title: $translate.instant('COAL')},
            {value: 'bioFuel', title: $translate.instant('BIOFUEL')},
            {value: 'steam', title: $translate.instant('STEAM')},
            {value: 'other', title: $translate.instant('OTHER')},
            {value: 'N/A', title: $translate.instant('N/A')}
        ];

        vm.create = createAction;
        vm.update = updateAction;
        vm.delete = deleteAction;

        // Fill form data on edit action
        if (typeof assetResponse._links !== 'undefined'
            && typeof assetResponse._links.self !== 'undefined'
        ) {
            var source = typeof subAssetResponse.id !== 'undefined' ? subAssetResponse : assetResponse;

            if (source.getLink('type')) {
                vm.assetTypesSelect.selected = source._links.type;
            }

            if (source.getLink('statutorytype')) {
                vm.statutoryTypesSelect.selected = source._links.statutorytype;
                vm.data.isStatutory = true;
            }

            if (typeof subAssetResponse.id !== 'undefined') {
                if (subAssetResponse.getLink('location')) {
                    vm.data.location = subAssetResponse._links.location;
                    vm.locationSelect.selected = subAssetResponse._links.location.href;
                    vm.locationSelect.disabled = false;
                }
                vm.data.endOfLife = vm.subAssetResponse.endOfLife ? new Date(vm.subAssetResponse.endOfLife) : null;
                vm.data.installationDate = vm.assetResponse.installationDate ? new Date(vm.assetResponse.installationDate) : null;
                vm.data.warrantyStart = vm.assetResponse.warrantyStart ? new Date(vm.assetResponse.warrantyStart) : null;
                vm.data.warrantyExpiry = vm.assetResponse.warrantyExpiry ? new Date(vm.assetResponse.warrantyExpiry) : null;
            }
        }


        function createAction() {
            vm.data._links = {};

            if (typeof vm.locationSelect.selected !== 'undefined') {
                vm.data._links.location = vm.locationSelect.selected;
            }

            if (typeof vm.assetTypesSelect.selected !== 'undefined') {
                vm.data._links.type = vm.assetTypesSelect.selected;
            }

            if (typeof vm.serviceRoutinesSelect.selected !== 'undefined') {
                vm.data._links.serviceroutine = vm.serviceRoutinesSelect.selected;
            }

            if (typeof vm.statutoryTypesSelect.selected !== 'undefined') {
                vm.data._links.statutorytype = vm.statutoryTypesSelect.selected;
            }

            if (!vm.data.isStatutory) {
                delete vm.data._links.statutorytype
            }

            apiClient.create(assetResponse.getLink('subassets'), vm.data).then(function(response) {
                if (response) {
                    $state.go('^', { subAssetResource: response.getLink('self').encode() }, { reload: $state.current.parent }).then(function() {
                        messenger.success('SUB_ASSET_CREATED');
                    });
                } else {
                    messenger.error('REQUEST_ERROR');
                }
            });
        }

        function updateAction() {
            vm.data._links = {};

            if (typeof vm.locationSelect.selected !== 'undefined') {
                vm.data._links.location = vm.locationSelect.selected
            }

            if (typeof vm.assetTypesSelect.selected !== 'undefined') {
                vm.data._links.type = vm.assetTypesSelect.selected
            }

            if (typeof vm.serviceRoutinesSelect.selected !== 'undefined') {
                vm.data._links.serviceroutine = vm.serviceRoutinesSelect.selected
            }

            if (typeof vm.statutoryTypesSelect.selected !== 'undefined') {
                vm.data._links.statutorytype = vm.statutoryTypesSelect.selected
            }

            if (!vm.data.isStatutory) {
                delete vm.data._links.statutorytype
            }

            apiClient.update(subAssetResponse.getLink('edit'), vm.data).then(function(response) {
                if (response) {
                    $state.go('^', {}, { reload: $state.get('^.^.^') }).then(function() {
                        messenger.success('SUB_ASSET_UPDATED');
                    });
                } else {
                    messenger.error('REQUEST_ERROR');
                }
            });
        }

        function deleteAction() {
            return confirmationModal.open().result.then(function() {
                return apiClient.delete(subAssetResponse.getLink('delete')).then(function(response) {
                    if (response) {
                        $state.go('^.^.^', {}, {reload: $state.get('^.^.^')}).then(function() {
                            messenger.success('SUB_ASSET_DELETED');
                        });
                    } else {
                        messenger.error('REQUEST_ERROR');
                    }
                });
            }, function() {
                return;
            });
        }

        $scope.$watch('vm.data.installationDate', function(newValue) {
            if (vm.data.installationDate) {
                var now = moment();
                newValue = moment(newValue);

                if (newValue > now) {
                    vm.data.age = 0;
                } else {
                    vm.data.age = now.diff(newValue, 'years');
                }

                calculateExpectedLife();
            } else {
                vm.data.age = null;
                vm.data.remainingLife = null;
            }
        });

        $scope.$watch('vm.data.expectedLife', calculateExpectedLife);

        function calculateExpectedLife() {
            if (vm.data.age >= 0 && vm.data.expectedLife >= 0) {
                vm.data.remainingLife = vm.data.expectedLife - vm.data.age >= 0 ? vm.data.expectedLife - vm.data.age : 0;
            }
        }
    }
})();
