(function () {
    'use strict';

    angular
        .module('elogbooks.common.finance')
        .controller('CommonFinanceItemListAddEditController', CommonFinanceItemListAddEditController);

        CommonFinanceItemListAddEditController.$inject = [
            'lodash',
            'vatRateCollectionResponse',
            'itemResponse',
            'itemLinesCollectionResponse',
            'config',
            '$filter'
        ];

    function CommonFinanceItemListAddEditController (
        lodash,
        vatRateCollectionResponse,
        itemResponse,
        itemLinesCollectionResponse,
        config,
        $filter
    ) {
        var vm = this;

        vm.itemResponse = itemResponse;
        vm.itemLinesCollectionResponse = itemLinesCollectionResponse;
        vm.entityName = Object.keys(itemLinesCollectionResponse)[4];
        vm.config = config;
        vm.maxItems = 50;
        vm.totalCost = vm.itemResponse.totalCostLines;
        vm.message = 'ITEM_SUCCESSFULLY';

        vm.fieldProperties = {
            description: {
                type: 'text',
                label: 'description',
                placeholder: 'description',
                variableName: 'description',
                maxLength: 255,
                required: true,
                model: null
            },
            itemCode: {
                type: 'text',
                label: 'item_code',
                placeholder: 'item_code',
                variableName: 'itemCode',
                maxLength: 20,
                required: false,
                model: null
            },
            costType: {
                type: 'select',
                label: 'cost_type',
                options: [
                    {
                        title: $filter('financeCostTypes')('subcontractor'),
                        value: 0
                    },
                    {
                        title: $filter('financeCostTypes')('materials'),
                        value: 1
                    },
                    {
                        title: $filter('financeCostTypes')('equipment_hire'),
                        value: 2
                    },
                    {
                        title: $filter('financeCostTypes')('other_cost'),
                        value: 3
                    }
                ],
                variableName: 'costType',
                required: true,
                model: null
            },
            quantity: {
                type: 'number',
                label: 'quantity',
                placeholder: '0.00',
                variableName: 'quantity',
                min: '0',
                step: '0.01',
                required: true,
                model: null
            },
            unitPrice: {
                type: 'number',
                label: 'unit_price',
                placeholder: '0.00',
                variableName: 'unitPrice',
                min: '0',
                step: '0.01',
                required: true,
                model: null
            },
            vatRate: {
                type: 'select',
                label: 'vat_rate',
                variableName: 'vatRate',
                maxLength: 255,
                required: true,
                model: null,
                default: lodash.get(
                    lodash.find(vatRateCollectionResponse.vatRates, function (thisRate) {
                        return thisRate.isDefault;
                    }),
                    'id'
                ),
                options: vatRateCollectionResponse.vatRates.map(function (x) {
                    return {
                        title: x.name + ' (' + x.percentageRate + '%)',
                        value: x.id
                    };
                })
            },
            amount: {
                type: 'no-edit',
                label: 'amount',
                variableName: 'amount',
                placeholder: '0.00',
                calculatedField: true,
                roundDecimal: true,
                fields: ['quantity', 'unitPrice', 'vatRate'],
                operation: 'callback',
                operator: function (operands) {
                    var quantity = operands[0] || 0;
                    var unitPrice = operands[1] || 0;
                    var total = quantity * unitPrice;
                    var rate = operands[2] == null ?
                        null :
                        lodash.find(vatRateCollectionResponse.vatRates, function (thisRate) {
                            return thisRate.id === operands[2];
                        });
                    if (!rate) {
                        return null;
                    }
                    total *= 1 + rate.percentageRate / 100;
                    return total;
                },
                model: null
            }
        };
    }
})();
