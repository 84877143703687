(function () {
    'use strict';

    angular
        .module('elogbooksServices')
        .service('elbFileModalService', ElbFileModalService);

    ElbFileModalService.$inject = ['$uibModal'];

    function ElbFileModalService ($uibModal) {
        return {
            open: function (file, tags) {
                return $uibModal.open({
                    templateUrl: '/modules/common/modals/file/file.html',
                    controller: 'ElbFileModalController',
                    controllerAs: 'vm',
                    resolve: {
                        file: function () {
                            return file;
                        },
                        tags: function () {
                            return tags;
                        }
                    }
                });
            }
        };
    }
})();
