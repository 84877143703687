app.config(function ($translateProvider) {
    $translateProvider.translations('en-gb', {
        BUDDY: 'Buddy',
        BUDDY_ADD: 'Add Buddy',
        BUDDY_EDIT: 'Edit Buddy',
        BUDDY_UPDATED: 'Buddy Updated',
        BUDDIES: 'Buddies',
        BUDDIES_NO_RESULTS: 'No Buddies',
        BUDDYING_FOR: 'Buddying For',
        BUDDY_SELECT_SITES: 'Selected Sites',
        BUDDY_SITE_RELATION_REMOVED: 'Site removed',
        BUDDY_REMOVED: 'Buddy removed',
        BUDDY_ALREADY_IN_USE_SITES: 'Already in use sites:',
        BUDDY_ALREADY_IN_USE: 'This buddy is not available for the dates selected',
        BUDDY_CONFLICTS: 'There are conflicts with other sites',
        BUDDY_DEACTIVATED: 'Buddy deactivated',
        MY_BUDDY: 'My Buddy',
        HISTORY: 'History',
        SCHEDULE: 'Schedule',
        MY_BUDDIES: 'My buddies',
        NO_BUDDIES_FOUND: 'No buddy set',
        BUDDY_BETWEEN: 'Buddy between',
        ASSIGNED_BY: 'Assigned By',
        BUDDY_ADD_SUCCESS: 'Buddy Added',
        BUDDY_SITE_ADDED: 'Site Added',
        SITES_EMPTY: 'Sites cannot be empty',
        CANNOT_EDIT_AN_ACTIVE_BUDDY: 'Unable to edit an active Buddy',
        START_DATE_MUST_BE_HIGHER_THAN_CURRENT_DATETIME: 'Start Date must be higher than current date time',
        END_DATE_MUST_BE_MINIMUM_20_MINUTES_FROM_START_DATE: 'End date must be 20 minutes from Start date minimum',
        END_DATE_MUST_BE_HIGHER_THAN_START_DATE: 'End date must be higher than start date'
    });
});
