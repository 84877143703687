(function() {
    'use strict';

    angular
        .module('elogbooks.user.meter-types')
        .controller('MeterTypesController', MeterTypesController);

    MeterTypesController.$inject = ['$state', '$stateParams', 'meterTypesCollectionResponse'];
    function MeterTypesController($state, $stateParams, meterTypesCollectionResponse) {
        var vm = this;
        vm.meterTypes = meterTypesCollectionResponse.meterTypes;
        vm.meterTypesCollectionResponse = meterTypesCollectionResponse;
        vm.criteria = {
            id: { type: 'number', value: $stateParams.id ? parseInt($stateParams.id, 10) : null, title: 'ID', min: 1 },
            name: { type: 'text', title: 'NAME', value: $stateParams.name }
        };

        vm.order = orderAction;
        vm.search = searchAction;

        function searchAction(params) {
            var override = {
                page: 1
            };

            $state.go('.', angular.extend({}, params, override), { reload: $state.current });
        }

        function orderAction(key) {
            $stateParams.order = key;

            $state.go('.', angular.extend({}, $stateParams), { reload: $state.current });
        }
    }
})();
