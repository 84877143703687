/**
 * Threshold Service
 */
angular.module('elogbooksServices').service('thresholdService', function (apiClient, $q) {

    var self = this;
    this.thresholds;
    this.initThresholds = initThresholds;
    this.getQuoteApproverThreshold = getQuoteApproverThreshold;

    /*
     * initialises thresholds
     *
     * @param array quoteThresholdsCollection
     * @returns array
     */
    function initThresholds(quoteThresholdsCollection) {
       self.thresholds = quoteThresholdsCollection;
    };

    /*
     * returns formatted threshold value based on approver index
     *
     * @param integer approverIndex
     * @returns {string}
     */
    function getQuoteApproverThreshold(approverIndex) {
        // skip first threshold as that is the default unlimited
        if ((approverIndex+1) <  self.thresholds.length) {
            return self.thresholds[self.thresholds.length - (approverIndex +1)].value;
        }

        // all other quote approvers are in the unlimited threshold
        return 'unlimited';
    };
});
