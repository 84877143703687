(function() {
    'use strict';

    angular
        .module('elogbooks.admin.site-associate-types')
        .controller('SiteAssociateTypeInfoController', SiteAssociateTypeInfoController);

    SiteAssociateTypeInfoController.$inject = ['siteAssociateTypeResponse'];

    function SiteAssociateTypeInfoController (siteAssociateTypeResponse) {
        var vm = this;
            vm.siteAssociateType = siteAssociateTypeResponse;
    }
})();
