(function() {
    'use strict';

    angular
        .module('elogbooks')
        .config(Translations);

    function Translations($translateProvider) {
        $translateProvider.translations('nl', {
            EXTEND_SESSION_HEADER: 'Verleng sessie',
            EXTEND_SESSION_BODY: 'U wordt automatisch uitgelogd over <strong>{{ ::timeRemaining }}</strong>. Klik hieronder om de sessie te verlengen met 24 uur.',
            EXTEND_SESSION: 'Verlengen',
            UPDATE_POSTPONE: 'Uitstellen update',
            LOGOUT: 'Uitloggen'
        });
    }
})();
