(function() {
    'use strict';

    angular
        .module('elogbooks.admin.action-types')
        .controller('ActionTypesController', ActionTypesController);

    ActionTypesController.$inject = ['$state', '$stateParams', 'actionTypeCollectionResponse', 'NestedArrayTransformer', '$window'];

    function ActionTypesController($state, $stateParams, actionTypeCollectionResponse, NestedArrayTransformer, $window) {
        var vm = this;
        var filterOnChildren = !!$stateParams.id;
        vm.actionTypeCollectionResponse = actionTypeCollectionResponse;
        vm.actionTypesNested = NestedArrayTransformer.transform(actionTypeCollectionResponse.actiontypes, filterOnChildren);
        vm.criteria = {
            id: { type: 'number', value: $stateParams.id ? parseInt($stateParams.id, 10) : null, title: 'ID', min: 1 },
        };

        vm.toggle = toggleAction;
        vm.isOpen = isOpen;

        vm.order = orderAction;
        vm.search = searchAction;

        function toggleAction(actionType) {
            var showChildren = JSON.parse($window.localStorage.getItem('admin-actiontypes.' + actionType.id));
            $window.localStorage.setItem('admin-actiontypes.' + actionType.id, JSON.stringify(! showChildren));
        }

        function isOpen(actionType) {
            return JSON.parse($window.localStorage.getItem('admin-actiontypes.' + actionType.id));
        }

        function searchAction(params) {
            var override = {
                page: 1
            };

            $state.go('.', angular.extend({}, params, override), { reload: $state.current });
        }

        function orderAction(key) {
            $stateParams.order = key;

            $state.go('.', angular.extend({}, $stateParams), { reload: $state.current });
        }
    }
})();
