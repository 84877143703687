(function() {
    'use strict';

    angular
        .module('elogbooks')
        .config(Translations);

    function Translations($translateProvider) {
        $translateProvider.translations('zh-hk', {
            QUOTE_OPTION: '報價選項',
            QUOTE_OPTIONS: '報價選項',

            QUOTE_OPTION_LIST: '報價選項',
            QUOTE_OPTION_VIEW: '報價選項',

            QUOTE_OPTION_ADD: '新增報價選項',
            QUOTE_OPTION_EDIT: '修改報價選項',

            QUOTE_OPTION_CREATED: '報價選項已建立',
            QUOTE_OPTION_UPDATED: '報價選項已更新',
            QUOTE_OPTION_DELETED: '報價選項已刪除',

            QUOTE_OPTION_STATUS_ACCEPTED: '已接受',
            QUOTE_OPTION_STATUS_REJECTED: '已拒絕',
            QUOTE_OPTION_STATUS_DRAFT: '開啟',
            QUOTE_OPTION_STATUS_SUBMITTED: '待定',

            PLEASE_SELECT_QUOTE_OPTIONS: '請選擇報價選項',
            OPTION_REQUIRES_ESCLATION: '此選項的價值超出你的審批門檻，需要上報',

            QUOTE_OPTION_LABOUR: '勞工',
            QUOTE_OPTION_LABOURS: '勞工',

            QUOTE_OPTION_LABOUR_LIST: '勞工',

            QUOTE_OPTION_LABOUR_ADD: '新增勞工線',
            QUOTE_OPTION_LABOUR_EDIT: '修改',

            QUOTE_OPTION_LABOUR_CREATED: '勞工線已建立',
            QUOTE_OPTION_LABOUR_UPDATED: '勞工線已更新',
            QUOTE_OPTION_LABOUR_DELETED: '勞工線已刪除',

            QUOTE_OPTION_LABOUR_LABOUR_HOURS: '勞工時間',
            QUOTE_OPTION_LABOUR_TRAVEL_HOURS: '行程時間',
            QUOTE_OPTION_LABOUR_MILEAGE: '里程',
            QUOTE_OPTION_LABOUR_VALUE: '值',

            QUOTE_OPTION_MATERIAL: '物料',
            QUOTE_OPTION_MATERIALS: '物料',

            QUOTE_OPTION_MATERIAL_LIST: '物料',

            QUOTE_OPTION_MATERIAL_ADD: '新增物料',
            QUOTE_OPTION_MATERIAL_EDIT: '修改',

            QUOTE_OPTION_MATERIAL_CREATED: '物料已建立',
            QUOTE_OPTION_MATERIAL_UPDATED: '物料已更新',
            QUOTE_OPTION_MATERIAL_DELETED: '物料已刪除',

            QUOTE_OPTION_MATERIAL_QUANTITY: '數量',
            QUOTE_OPTION_MATERIAL_QUOTED_VALUE: '報價值',
            QUOTE_OPTION_MATERIAL_QUOTED_TAX_VALUE: '報稅價值',
            QUOTE_OPTION_MATERIAL_ACTUAL_VALUE: '實際價值',
            QUOTE_OPTION_MATERIAL_ACTUAL_TAX_VALUE: '實際稅值',
        });
    }
})();
