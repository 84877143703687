/**
 * Service Provider Service
 */
angular.module('elogbooksServices').service('serviceProviderService', function (apiClient, $q, lodash, modulesService, $translate, elbSettings) {
    this.create = createServiceProvider;
    this.update = updateServiceProvider;
    this.getFromPartnershipForMultiCheckbox = getFromPartnershipForMultiCheckbox;
    this.getPrimaryOperatives = getPrimaryOperatives;
    this.formatServiceProviderName = formatServiceProviderName;
    this.prosureStatusHeaders = prosureStatusHeaders;
    this.prosureGroupReorder = prosureGroupReorder;
    this.setProsureWarningMessage = setProsureWarningMessage;

    this.isProsureEnabled = elbSettings.getSetting('prosure_enabled').value;
    this.prosure360StatusOptions = [
        {
            title: $translate.instant('PROSURE_REQUIREMENTS_SUCCESS'),
            value: 0
        },
        {
            title: $translate.instant('PROSURE_REQUIREMENTS_EXPIRING'),
            value: 1
        },
        {
            title: $translate.instant('PROSURE_REQUIREMENTS_BELOW'),
            value: 2
        },
        {
            title: $translate.instant('PROSURE_SUSPENDED'),
            value: 3
        },
        {
            title: 'N/A',
            value: 99
        }
    ];

    /**
     * Create a service provider
     */
    function createServiceProvider(sp, form) {

        var deferred = $q.defer();

        apiClient.create(sp.getLink('self'), form).then(function (isSuccess) {
            if (isSuccess) {
                return deferred.resolve();
            } else {
                return deferred.reject('Unable to create, please try later');
            }
        });

        return deferred.promise;
    }

    /**
     * Update a service provider
     */
    function updateServiceProvider(sp, form) {

        var deferred = $q.defer();

        apiClient.update(sp.getLink('self'), form).then(function (isSuccess) {
            if (isSuccess) {
                return deferred.resolve();
            } else {
                return deferred.reject('Unable to update, please try later');
            }
        });

        return deferred.promise;
    }

    /**
     *
     * @param {RequestData|string} siteResponse
     * @returns {Promise}
     */
    function getFromPartnershipForMultiCheckbox(siteResponse) {
        if (typeof siteResponse === 'string') {
            var deferred = $q.defer();

            apiClient.get(siteHref).then(
                function (siteResponse) {
                    getPartnerships(siteResponse).then(
                        function (response) {
                            deferred.resolve(response);
                        }
                    );
                }
            );

            return deferred.promise;
        }

        return getPartnerships(siteResponse);
    }

    function getPartnerships(siteResponse) {
        var deferred = $q.defer(),
            sitePartnerships = [];
        apiClient.get(site.getLink('partnerships')).then(
            function (response) {
                lodash.forEach(siteResponse.partnerships,
                    function (response) {
                        //multiple checkbox requires label for href
                        sitePartnerships.push({
                            name: response._links.serviceprovider.title,
                            label: response._links.serviceprovider.href,
                            value: response._links.serviceprovider.href
                        });
                    });

                deferred.resolve(sitePartnerships);
            }
        );

        return deferred.promise;
    }

    function getPrimaryOperatives(sp) {
        var primaryOperatives = [
            {
                type: 'job',
                operative: lodash.has(sp, '_links.primary-job-operative') ? sp._links['primary-job-operative'] : null,
                label: $translate.instant('PRIMARY_JOB_OPERATIVE'),
            },
            {
                type: 'escalation',
                operative: lodash.has(sp, '_links.primary-escalation-operative') ? sp._links['primary-escalation-operative'] : null,
                label: $translate.instant('PRIMARY_ESCALATION_OPERATIVE'),
            },
            {
                type: 'quote',
                operative: lodash.has(sp, '_links.primary-quote-operative') ? sp._links['primary-quote-operative'] : null,
                label: $translate.instant('PRIMARY_QUOTE_OPERATIVE'),
            },
            {
                type: 'quoteReviewer',
                operative: lodash.has(sp, '_links.primary-quote-reviewer-operative') ? sp._links['primary-quote-reviewer-operative'] : null,
                label: $translate.instant('PRIMARY_QUOTE_REVIEWER_OPERATIVE'),
            },
            {
                type: 'audit',
                operative: lodash.has(sp, '_links.primary-audit-operative') ? sp._links['primary-audit-operative'] : null,
                label: $translate.instant('PRIMARY_AUDIT_OPERATIVE'),
            },
            {
                type: 'healthsafety',
                operative: lodash.has(sp, '_links.primary-health-safety-operative') ? sp._links['primary-health-safety-operative'] : null,
                label: $translate.instant('PRIMARY_HEALTH_SAFETY_OPERATIVE'),
            },
        ];

        if (modulesService.getEnabled('patrols_enabled')) {
            primaryOperatives.push({
                type: 'patrol',
                operative: lodash.has(sp, '_links.primary-patrol-operative') ? sp._links['primary-patrol-operative'] : null,
                label: $translate.instant('PRIMARY_PATROL_OPERATIVE'),
            });
        }

        if (modulesService.getEnabled('surveys_enabled')) {
            primaryOperatives.push({
                type: 'survey',
                operative: lodash.has(sp, '_links.primary-survey-operative') ? sp._links['primary-survey-operative'] : null,
                label: $translate.instant('PRIMARY_SURVEY_OPERATIVE'),
            });
        }

        return primaryOperatives;
    }

    /**
     * @returns value string
     */
    function formatServiceProviderName(serviceProvider) {
        if (!serviceProvider) {
            return;
        }

        var value = serviceProvider.name || serviceProvider.getLinkAttribute('serviceprovider', 'title');

        return value;
    }

    function prosureStatusHeaders(serviceProvider) {
        if (elbSettings.getSetting('prosure_enabled').value) {
            switch (serviceProvider.object.meetsProsureRequirements) {
                case 'PROSURE_REQUIREMENTS_SUCCESS':
                    serviceProvider.header = $translate.instant('PROSURE_SP_REQUIREMENTS_SUCCESS');
                    break;
                case 'PROSURE_REQUIREMENTS_EXPIRING':
                    serviceProvider.header = $translate.instant('PROSURE_SP_REQUIREMENTS_EXPIRING');
                    break;
                case 'PROSURE_REQUIREMENTS_BELOW':
                    serviceProvider.header = $translate.instant('PROSURE_SP_REQUIREMENTS_BELOW');
                    break;
                case 'PROSURE_SUSPENDED':
                    serviceProvider.header = $translate.instant('PROSURE_SP_SUSPENDED');
                    break;
                default:
                    serviceProvider.header = $translate.instant('NO_PROSURE_SP');
            }
        }

        return serviceProvider;
    }

    function prosureGroupReorder(items) {
        var groups = [];

        if (elbSettings.getSetting('prosure_enabled').value) {
            angular.forEach(items, function (item) {
                switch (item.name) {
                    case $translate.instant('PROSURE_SP_REQUIREMENTS_SUCCESS'):
                        groups[0] = item;
                        break;
                    case $translate.instant('PROSURE_SP_REQUIREMENTS_EXPIRING'):
                        groups[1] = item;
                        break;
                    case $translate.instant('PROSURE_SP_REQUIREMENTS_BELOW'):
                        groups[2] = item;
                        break;
                    case $translate.instant('PROSURE_SP_SUSPENDED'):
                        groups[3] = item;
                        break;
                    case $translate.instant('NO_PROSURE_SP'):
                        groups[4] = item;
                        break;
                }
            });

            groups = groups.filter(function (group) {
                return group !== null;
            });
        }

        if (groups.length > 0) {
            return groups;
        } else {
            return items;
        }
    }

    function setProsureWarningMessage(serviceProvider) {
        var warningMessage = null;

        switch (serviceProvider.object.meetsProsureRequirements) {
            case 'PROSURE_REQUIREMENTS_SUCCESS':
                break;
            case 'PROSURE_REQUIREMENTS_EXPIRING':
                warningMessage = $translate.instant('PROSURE_REQUIREMENTS_EXPIRING_WARNING');
                break;
            case 'PROSURE_REQUIREMENTS_BELOW':
                warningMessage = $translate.instant('PROSURE_REQUIREMENTS_BELOW_WARNING');
                break;
            case 'PROSURE_SUSPENDED':
                warningMessage = $translate.instant('PROSURE_SUSPENDED_WARNING');
                break;
            default:
                warningMessage = $translate.instant('NOT_IN_MY_SUPPLY_CHAIN_WARNING');
                break;
        }

        return warningMessage;
    }
});
