
(function () {
    'use strict'

    angular
        .module('elogbooks.admin.contract-types')
        .config(registerRoutes);

    function registerRoutes($stateProvider) {

        $stateProvider
            .state('dashboard.admin.contracttypes', {
                url: '/contracttypes?id&name&description',
                resolve: {
                    contractTypeCollectionResponse: function ($stateParams, apiClient, rootResourceResponse) {
                        return apiClient.getPage(rootResourceResponse.getLink('contracttypes'), $stateParams);
                    }
                },
                params: {
                    page: "1",
                    limit: "30"
                },
                templateUrl: '/modules/admin/contract-types/contracttype-list.html',
                controller: 'ContractTypesController',
                controllerAs: 'vm'
            })
            .state('dashboard.admin.contracttypes.add', {
                url: '/add',
                parent: 'dashboard.admin.contracttypes',
                templateUrl: '/modules/admin/contract-types/add/contracttype-add.html',
                controller: 'ContractTypesAddController',
                controllerAs: 'vm'
            })
            .state('dashboard.admin.contracttypes.details', {
                url: '/details/{resource}',
                parent: 'dashboard.admin.contracttypes',
                templateUrl: '/modules/admin/contract-types/details/contracttype-details.html',
                controller: 'ContractTypesDetailsController',
                controllerAs: 'vm',
                resolve: {
                    contractTypeResponse: function (apiClient, base64, $stateParams) {
                        return apiClient.get($stateParams.resource.decode());
                    }
                }
            })
            .state('dashboard.admin.contracttypes.details.edit', {
                url: '/edit/',
                parent: 'dashboard.admin.contracttypes.details',
                views: {
                    "@dashboard": {
                        template: ""
                    },
                    "forms@dashboard": {
                        templateUrl: '/modules/admin/contract-types/details/edit/contracttype-edit.html',
                        controller: 'ContractTypesEditController',
                        controllerAs: 'vm',
                    }
                }
            });
    };

})();
