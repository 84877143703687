(function() {
    'use strict';

    angular
        .module('elogbooks.admin.pricing-schedules')
        .controller('PricingScheduleDetailsEventFeedController', PricingScheduleDetailsEventFeedController);

    PricingScheduleDetailsEventFeedController.$inject = [
        'eventCollectionResponse'
    ];

    function PricingScheduleDetailsEventFeedController(
        eventCollectionResponse
    ) {
        var vm = this;
        vm.feed = [];
        vm.eventCollectionResponse = eventCollectionResponse;
    }
})();
