(function() {
    'use strict';

    angular
        .module('elogbooks.common.jobs', [
            'elogbooks.common.jobs.add',
            'elogbooks.common.jobs.workflow'
        ]);
})();

/**
 * Note:
 *
 * This is a global function. It was intended to be a service that would then be injected
 * in to the routing files / functions, but it appears that services cannot be injected in
 * to the routing functions. An unknown provider error occurs.
 */
function elbJobWorkflowRouting () {
    var self = this;
    this.addRoutes = addRoutes;

    var priorityLinkParameters = {'active': true, 'entityTypes': 'entity_type_all,entity_type_job'};

    function getView (view, viewObject) {
        var result = {};
        result[view] = viewObject;

        return result;
    }

    function getLabel (translationKey) {
        return { label: '{{ ::"' + translationKey + '" | translate }}' };
    }

    // Check if workflow link exists else error and redirect to parent
    function checkLink($state, $stateParams, messenger, jobResponse, workflowLink) {
        if (workflowLink && !jobResponse.getLink(workflowLink)) {
            var toState = $state.next.name.substring(0, $state.next.name.indexOf('.workflow'));

            $state.go(toState, $stateParams, { reload: toState }).then(function () {
                messenger.error('WORKFLOW_ACTION_NOT_AVAILABLE');
            });

            return false;
        }

        return true;
    }


    /**
     * Usage:
     *     elbJobWorkflowRoutine().addRoutes(
     *         $stateProvider,
     *         'dashboard.users.jobs.list.details.status', // Route to .status of the Job details, or other parent of .workflow
     *         true || false // Whether or not to include a bookerize view
     *     )
     */
    function addRoutes ($stateProvider, parent, isBookable) {

        var workflowRoute = parent + '.workflow',
            jobsRoute = parent.substring(0, parent.lastIndexOf('.jobs')) + ".jobs",
            baseView = '@' + jobsRoute,
            baseBookView =  'elogbooks-right' + baseView,
            workflowView = baseView + (parent.indexOf('.list') > -1 ? '.list' : '') + '.details.status.workflow';

        var baseViewObject = {};
        if (isBookable) {
            baseViewObject[baseBookView] = {
                templateUrl: '/shared-templates/view.html'
            };

            baseViewObject = parseBook(baseViewObject);
        } else {
            baseViewObject[baseView] = {
                templateUrl: '/shared-templates/view.html'
            };
        }

        $stateProvider
            .state(workflowRoute, {
                abstract: true,
                views: baseViewObject,
                parent: parent,
                resolve: {
                    formData: function() { return null; },
                    scopeData: function() { return null; },
                    proposalEventResponse: function() { return null; },
                    integrationDocTypesResponse: function() { return null; },
                    hasWorkflowLink: function($state, $stateParams, messenger, jobResponse) {
                        if ($state.next.name.indexOf('.workflow.') > -1) {
                            var workflowLink = $state.next.name.split('.workflow.')[1];

                            // set workflow link to null if not set in response or to the actual link defined in response
                            switch (workflowLink) {
                                case 'requisites':
                                case 'requisites.view-form':
                                case 'requisites.view-form.edit':
                                case 'change-required-by-date':
                                case 'value':
                                    workflowLink = '';
                                    break;
                                case 'value.approve':
                                    workflowLink = 'value-approve';
                                    break;
                                case 'value.add':
                                    workflowLink = 'value-add';
                                    break;
                                case 'value.reject':
                                    workflowLink = 'value-reject';
                                    break;
                                case 'foc':
                                    workflowLink = 'value-foc';
                                    break;
                                case 'add-operative':
                                    workflowLink = 'add-additional-operative';
                                    break;
                            }

                            return checkLink($state, $stateParams, messenger, jobResponse, workflowLink);
                        }

                        return true;
                    }
                },
                ncyBreadcrumb: {
                    skip: true
                }
            })
            .state(workflowRoute + '.accept-assignment', {
                url: '/accept-assignment',
                parent: workflowRoute,
                views: getView(workflowView, {
                    templateUrl: '/modules/common/jobs/workflow/_wrapper.html',
                    controller: 'JobAssignmentAcceptController',
                    controllerAs: 'vm'
                }),
                ncyBreadcrumb: getLabel('JOB_WORKFLOW_ACCEPT_ASSIGNMENT')
            })
            .state(workflowRoute + '.reject-assignment', {
                url: '/reject-assignment',
                parent: workflowRoute,
                views: getView(workflowView, {
                    templateUrl: '/modules/common/jobs/workflow/_wrapper.html',
                    controller: 'JobAssignmentRejectController',
                    controllerAs: 'vm'
                }),
                ncyBreadcrumb: getLabel('JOB_WORKFLOW_REJECT_ASSIGNMENT')
            })
            .state(workflowRoute + '.accept-proposal', {
                url: '/accept-proposal',
                parent: workflowRoute,
                views: getView(workflowView, {
                    templateUrl: '/modules/common/jobs/workflow/_wrapper.html',
                    controller: 'JobProposalAcceptController',
                    controllerAs: 'vm'
                }),
                resolve: {
                    proposalEventResponse: function(apiClient, jobResponse) {
                        return apiClient.get(jobResponse.getLink('events'), { type: 'job_proposal_requested', datagroup: 'details' });
                    }
                },
                ncyBreadcrumb: getLabel('JOB_WORKFLOW_ACCEPT_PROPOSAL')
            })
            .state(workflowRoute + '.reject-proposal', {
                url: '/reject-proposal',
                parent: workflowRoute,
                views: getView(workflowView, {
                    templateUrl: '/modules/common/jobs/workflow/_wrapper.html',
                    controller: 'JobProposalRejectController',
                    controllerAs: 'vm'
                }),
                resolve: {
                    proposalEventResponse: function(apiClient, jobResponse) {
                        return apiClient.get(jobResponse.getLink('events'), { type: 'job_proposal_requested', datagroup: 'details' });
                    }
                },
                ncyBreadcrumb: getLabel('JOB_WORKFLOW_REJECT_PROPOSAL')
            })
            .state(workflowRoute + '.cancel-proposal', {
                url: '/cancel-proposal',
                parent: workflowRoute,
                views: getView(workflowView, {
                    templateUrl: '/modules/common/jobs/workflow/_wrapper.html',
                    controller: 'JobProposalCancelController',
                    controllerAs: 'vm'
                }),
                resolve: {
                    proposalEventResponse: function(apiClient, jobResponse) {
                        return apiClient.get(jobResponse.getLink('events'), { type: 'job_proposal_requested', datagroup: 'details' });
                    }
                },
                ncyBreadcrumb: getLabel('JOB_WORKFLOW_CANCEL_PROPOSAL')
            })

            .state(workflowRoute + '.request-extension', {
                url: '/extension/request',
                parent: workflowRoute,
                views: getView(workflowView, {
                    templateUrl: '/modules/common/jobs/workflow/_wrapper.html',
                    controller: 'JobExtensionRequestController',
                    controllerAs: 'vm'
                }),
                ncyBreadcrumb: getLabel('JOB_WORKFLOW_REQUEST_EXTENSION')
            })
            .state(workflowRoute + '.accept-extension', {
                url: '/extension/accept',
                parent: workflowRoute,
                views: getView(workflowView, {
                    templateUrl: '/modules/common/jobs/workflow/_wrapper.html',
                    controller: 'JobExtensionAcceptController',
                    controllerAs: 'vm'
                }),
                ncyBreadcrumb: getLabel('JOB_WORKFLOW_ACCEPT_EXTENSION')
            })
            .state(workflowRoute + '.reject-extension', {
                url: '/extension/reject',
                parent: workflowRoute,
                views: getView(workflowView, {
                    templateUrl: '/modules/common/jobs/workflow/_wrapper.html',
                    controller: 'JobExtensionRejectController',
                    controllerAs: 'vm'
                }),
                ncyBreadcrumb: getLabel('JOB_WORKFLOW_REJECT_EXTENSION')
            })
            .state(workflowRoute + '.cancel-extension', {
                url: '/extension/cancel',
                parent: workflowRoute,
                views: getView(workflowView, {
                    templateUrl: '/modules/common/jobs/workflow/_wrapper.html',
                    controller: 'JobExtensionCancelController',
                    controllerAs: 'vm'
                }),
                ncyBreadcrumb: getLabel('JOB_WORKFLOW_CANCEL_EXTENSION')
            })
            .state(workflowRoute + '.requisites', {
                url: '/requisites',
                parent: workflowRoute,
                views: getView(workflowView, {
                    templateUrl: '/modules/common/jobs/workflow/requisites.html',
                    controller: 'JobOperationRequisitesController',
                    controllerAs: 'vm'
                }),
                resolve: {
                    jobRequisitesResponse: function(apiClient, jobResponse) {
                        return apiClient.get(jobResponse.getLink('jobrequisites')) || apiClient.noResourceFound();
                    },
                    jobRequisiteAnswersResponse: function(apiClient, jobResponse) {
                        return apiClient.get(jobResponse.getLink('jobrequisitesanswers')) || apiClient.noResourceFound();
                    }
                },
                ncyBreadcrumb: getLabel('JOB_REQUISITES')
            })
            .state(workflowRoute + '.requisites.view-form', {
                url: '/view-form/{formVersionInstanceResource}',
                parent: workflowRoute + '.requisites',
                views: getView(workflowView, {
                    templateUrl: '/modules/common/forms/details/details.html',
                    controller: 'CommonFormsDetailsController',
                    controllerAs: 'vm'
                }),
                resolve: {
                    formVersionInstanceResponse: function($stateParams, apiClient) {
                        return apiClient
                            .get($stateParams.formVersionInstanceResource.decode(), {datagroup: 'details'})
                            .then(function(response) {
                                return response || apiClient.noResourceFound();
                            });
                    },
                    associatedEntities: function(jobResponse) {
                        return {
                            job: jobResponse,
                        };
                    },
                    config: function() {
                        return {
                            mode: 'render-preview',
                            jobRequisite: true
                        };
                    }
                },
                ncyBreadcrumb: {
                    label: '{{ ::$resolves().formVersionInstanceResponse._embedded.form.summary }}'
                }
            })
            .state(workflowRoute + '.requisites.view-form.edit', {
                url: '/edit',
                parent: workflowRoute + '.requisites.view-form',
                views: getView(workflowView, {
                    templateUrl: '/modules/common/forms/details/details.html',
                    controller: 'CommonFormsDetailsController',
                    controllerAs: 'vm',
                }),
                resolve: {
                    config: function() {
                        return {
                            mode: 'render'
                        };
                    },
                },
                ncyBreadcrumb: {
                    label: '{{ ::"EDIT" | translate }}',
                },
            })
            .state(workflowRoute + '.commence', {
                url: '/commence',
                parent: workflowRoute,
                views: getView(workflowView, {
                    templateUrl: '/modules/common/jobs/workflow/_wrapper.html',
                    controller: 'JobOperationCommencedController',
                    controllerAs: 'vm'
                }),
                ncyBreadcrumb: getLabel('JOB_WORKFLOW_COMMENCE')
            })
            .state(workflowRoute + '.complete', {
                url: '/complete?remedialsPage&remedialsLimit',
                parent: workflowRoute,
                views: getView(workflowView, {
                    templateUrl: '/modules/common/jobs/workflow/_wrapper.html',
                    controller: 'JobOperationCompleteController',
                    controllerAs: 'vm'
                }),
                params: {
                    remedialsPage: '1',
                    remedialsLimit: '10',
                    changed: null,
                },
                resolve: {
                    userResponse: function(user) {
                        return user;
                    },
                    siteResponse: function(apiClient, jobResponse) {
                        return apiClient.get(jobResponse.getLink('site')).then(function(response) {
                            return response || apiClient.noResourceFound();
                        });
                    },
                    labourLinesCollectionResponse: function(apiClient, jobResponse) {
                        if (jobResponse.getLink('job-labours')) {
                            return apiClient
                                .get(jobResponse.getLink('job-labours'), {limit: 0})
                                .then(function(response) {
                                    return response || apiClient.noResourceFound();
                                });
                        }
                    },
                    serviceProviderResponse: function(apiClient, jobResponse) {
                        return apiClient.get(jobResponse.getLink('serviceprovider'), {datagroup: 'details'}).then(function(response) {
                            return response || apiClient.noResourceFound();
                        });
                    },
                    jobOperativeResponse: function(apiClient, jobResponse) {
                        return apiClient.get(jobResponse.getLink('operative')).then(function(response) {
                            return response || apiClient.noResourceFound();
                        });
                    },
                    assetResponse: function(apiClient, jobResponse) {
                        var assetLink = null;
                        if (jobResponse.getLink('subasset')) {
                            assetLink = jobResponse.getLink('subasset')
                        } else if (jobResponse.getLink('subassets')) {
                            assetLink = jobResponse.getLink('subassets');
                        } else if (jobResponse.getLink('asset')) {
                            assetLink = jobResponse.getLink('asset');
                        } else {
                            return null;
                        }
                        return apiClient.get(assetLink).then(function(response) {
                            return response || null;
                        });
                    },
                    showLabourLines: function (apiClient, serviceProviderResponse, userResponse) {
                        const permissions = ['operative_permission_receive_jobs', 'operative_permission_create_labour_lines'];
                        const requestData = {
                            'permissions[]': permissions,
                            viaUser: serviceProviderResponse.isShared
                        };

                        return serviceProviderResponse.getLink('check-operative-permission') ?
                            apiClient.get(serviceProviderResponse.getLink('check-operative-permission'), requestData)
                                .then((response) => response || apiClient.noResourceFound()) :
                            [];
                    },
                    partnershipResponse: function(apiClient, serviceProviderResponse, siteResponse) {
                        return apiClient.get(siteResponse.getLink('partnerships'), {
                            serviceProviderId: serviceProviderResponse.id,
                            includeCanCreateJobsOtherServiceProviders: 'true',
                        }).then(function(response) {
                            return response.partnerships[0];
                        });
                    },
                    tagCollectionResponse: function(apiClient, rootResourceResponse) {
                        return apiClient.get(rootResourceResponse.getLink('tags'), { limit: 30 }).then(function(response) {
                            return response || apiClient.noResourceFound();
                        });
                    },
                    config: function(siteResponse, jobResponse, config) {
                        return angular.extend(config, {
                            associatedEntity: jobResponse,
                            mode: 'render'
                        });
                    },
                    integrationDocTypesResponse: function() {
                        return null;
                    },
                    locationCollectionResponse: function (apiClient, siteResponse) {
                        if (siteResponse.getLink('locations')) {
                            return apiClient.get(siteResponse.getLink('locations')).then(function (response) {
                                return response || apiClient.noResourceFound();
                            });
                        }
                    },
                    assetCollectionResponse: function(apiClient, siteResponse) {
                        if (siteResponse.getLink('assets')) {
                            return apiClient.get(siteResponse.getLink('assets'), {active: 1}).then(function(response) {
                                return response || apiClient.noResourceFound();
                            });
                        }
                    },
                    documentTypeCollectionResponse : function (apiClient, rootResourceResponse, jobResponse) {
                        return apiClient.get(rootResourceResponse.getLink('documenttypes'), {status: 'active', order: 'name', limit: 999, job: jobResponse.id})
                            .then(function (response) {
                                return response || apiClient.noResourceFound();
                            });
                    },
                    formVersionInstanceCollectionResponse: function(apiClient, jobResponse) {
                        return apiClient.get(jobResponse.getLink('forms'), { limit: 999999 })
                            .then(function(response) {
                                return response || apiClient.noResourceFound();
                            });
                    },
                    postFormVersionInstanceCollectionResponse: function(apiClient, jobResponse) {
                        return apiClient.get(jobResponse.getLink('forms'), {jobFormType: 'post_form'}).then(function(response) {
                                return response || apiClient.noResourceFound();
                            });

                    },
                    associatedEntities: function(jobResponse, assetResponse) {
                        return {
                            job: jobResponse,
                            asset: assetResponse
                        };
                    },
                    jobRequisitesResponse: function(apiClient, jobResponse) {
                        return apiClient.get(jobResponse.getLink('jobrequisites')) || apiClient.noResourceFound();
                    },
                    jobRemedialsCollection: function($stateParams, jobResponse, apiClient) {
                        var params = {
                            page: $stateParams.remedialsPage,
                            limit: $stateParams.remedialsLimit
                        };

                        if ($stateParams.changed && jobResponse.getLink('remedials')) {
                            return apiClient.get(jobResponse.getLink('remedials'), params).then(function(response) {
                                return response;
                            });
                        }

                        return {remedials: []};
                    },
                    jobFilesCollectionResponse : function (apiClient, jobResponse) {
                        return apiClient.get(jobResponse.getLink('files'), {hasDocumentType: true}).then(function (response) {
                            return response || apiClient.noResourceFound();
                        });
                    },
                },
                ncyBreadcrumb: getLabel('JOB_WORKFLOW_COMPLETE')
            })
            .state(workflowRoute + '.complete-paperwork', {
                url: '/complete-paperwork',
                parent: workflowRoute,
                views: getView(workflowView, {
                    templateUrl: '/modules/common/jobs/workflow/_wrapper.html',
                    controller: 'JobOperationCompletePaperworkController',
                    controllerAs: 'vm'
                }),
                resolve: {
                    tagCollectionResponse: function(apiClient, rootResourceResponse) {
                        return apiClient.get(rootResourceResponse.getLink('tags'), { limit: 30 }).then(function(response) {
                            return response || apiClient.noResourceFound();
                        });
                    },
                    documentTypeCollectionResponse : function (apiClient, rootResourceResponse, jobResponse) {
                        return apiClient.get(rootResourceResponse.getLink('documenttypes'), {status: 'active', order: 'name', limit: 999, job: jobResponse.id})
                            .then(function (response) {
                                return response || apiClient.noResourceFound();
                            });
                    },
                    jobFilesCollectionResponse : function (apiClient, jobResponse) {
                        return apiClient.get(jobResponse.getLink('files'), {hasDocumentType: true}).then(function (response) {
                                return response || apiClient.noResourceFound();
                            });
                    },
                    formVersionInstanceCollectionResponse: function(apiClient, jobResponse) {
                        return apiClient.get(jobResponse.getLink('forms'), { limit: 999999 })
                            .then(function(response) {
                                return response || apiClient.noResourceFound();
                            });
                    },
                    config: function(siteResponse, jobResponse, config) {
                        return angular.extend(config, {
                            associatedEntity: jobResponse
                        });
                    }
                },
                ncyBreadcrumb: getLabel('JOB_WORKFLOW_COMPLETE_PAPERWORK')
            })
            .state(workflowRoute + '.change-required-by-date', {
                url: '/change-required-by-date',
                parent: workflowRoute,
                views: getView(workflowView, {
                    templateUrl: '/modules/common/jobs/workflow/_wrapper.html',
                    controller: 'JobPostponeReminderAtController',
                    controllerAs: 'vm'
                }),
                ncyBreadcrumb: getLabel('JOB_WORKFLOW_CHANGED_REQUIRED_BY_DATE')
            })
            .state(workflowRoute + '.alarm', {
                url: '/alarm',
                parent: workflowRoute,
                views: getView(workflowView, {
                    templateUrl: '/modules/common/jobs/workflow/_wrapper.html',
                    controller: 'JobAlarmController',
                    controllerAs: 'vm'
                }),
                ncyBreadcrumb: {
                    label: "{{ ::($resolves().jobResponse.alarmAt ? 'JOB_WORKFLOW_ALARM' : 'JOB_WORKFLOW_ALARM_SETNEW') | translate }}"
                }
            })
            .state(workflowRoute + '.skip', {
                url: '/skip',
                parent: workflowRoute,
                views: getView(workflowView, {
                    templateUrl: '/modules/common/jobs/workflow/_wrapper.html',
                    controller: 'JobOperationSkipController',
                    controllerAs: 'vm'
                }),
                ncyBreadcrumb: getLabel('JOB_WORKFLOW_SKIP')
            })
            .state(workflowRoute + '.edit-job', {
                url: '/edit',
                parent: workflowRoute,
                views: getView(workflowView, {
                    templateUrl: '/modules/common/jobs/workflow/_wrapper.html',
                    controller: 'JobEditController',
                    controllerAs: 'vm'
                }),
                resolve: {
                    chargeTypeResponse: function(apiClient, jobResponse) {
                        return (jobResponse.getLink('chargetype') && (jobResponse.getResource('chargetype') || apiClient.noResourceFound()));
                    },
                    chargeTypesCollectionResponse: function(apiClient, rootResourceResponse) {
                        return apiClient.get(rootResourceResponse.getLink('chargetypes'), { limit: 999999 }).then( function(response) {
                            return response || apiClient.noResourceFound();
                        });
                    },
                    jobGroupCollectionResponse: function(apiClient, rootResourceResponse) {
                        return apiClient.get(rootResourceResponse.getLink('jobgroups'), { limit: 999999, fetchHierarchy: false }).then(function(response) {
                            return response || apiClient.noResourceFound();
                        })
                    },
                    jobGroupResponse: function(apiClient, jobResponse) {
                        return (jobResponse.getLink('jobgroup') && (jobResponse.getResource('jobgroup') || apiClient.noResourceFound()));
                    },
                    formData: function(jobResponse) {
                        return {
                            _links: {
                                jobgroup:        jobResponse._links['jobgroup'] ? jobResponse._links['jobgroup'] : null,
                                statutorytype:   jobResponse._links['statutorytype'] ? jobResponse._links['statutorytype'] : null,
                            },
                            reporter: (jobResponse.reporter) ? jobResponse.reporter : null,
                            siteContact: (jobResponse.siteContact) ? jobResponse.siteContact : null,
                            summary: jobResponse.summary,
                            description: jobResponse.description,
                            type: jobResponse.type,
                            poNumber: jobResponse.poNumber
                        };
                    },
                    jobGroupRequired: function(apiClient, rootResourceResponse) {
                        return apiClient.get(rootResourceResponse.getLink('jobsettings')).then(function(response) {
                            return response ? response.requireJobGroup : apiClient.noResourceFound();
                        });
                    },
                    statutoryTypesCollectionResponse: function(apiClient, rootResourceResponse) {
                        return apiClient.get(rootResourceResponse.getLink('statutorytypes')).then(function(response) {
                            return response || apiClient.noResourceFound();
                        });
                    },
                    siteResourceResponse: function (apiClient, jobResponse) {
                        return apiClient.get(jobResponse.getLink('site')).then(function(response) {
                            return response || apiClient.noResourceFound();
                        })
                    },
                    siteRelationshipResponse: function(rootResourceResponse) {
                        return rootResourceResponse.getResource('siterelationships');
                    },
                    userCollectionResponse: function(apiClient, user, siteResourceResponse) {
                        return null;
                    },
                    jobSettings: function(apiClient, rootResourceResponse) {
                        return apiClient.get(rootResourceResponse.getLink('jobsettings')).then(function(response) {
                            return response;
                        });
                    },
                    scopeData: function(apiClient, $translate, jobGroupCollectionResponse, lodash, jobGroupRequired,
                        jobGroupResponse, requestDataFactory, statutoryTypesCollectionResponse
                    ) {
                        return {
                            action: 'edit-job',
                            translation: 'JOB_WORKFLOW_EDIT_JOB',
                            partial: 'edit-job',
                            noteRequired: false,
                            jobGroupRequired: jobGroupRequired,
                            jobGroups: jobGroupCollectionResponse,
                            statutoryTypesCollectionResponse: statutoryTypesCollectionResponse
                        };
                    },
                    documentTypesCollectionResponse: function (apiClient, rootResourceResponse) {
                        if (rootResourceResponse.getLink('documenttypes')) {
                            return apiClient.get(rootResourceResponse.getLink('documenttypes'), {status: 'active', limit: 999}).then(function (response) {
                                return response;
                            });
                        }
                    },
                },
                ncyBreadcrumb: getLabel('JOB_WORKFLOW_EDIT_JOB')
            })
            .state(workflowRoute + '.reassign-operative', {
                url: '/reassign-operative',
                parent: workflowRoute,
                views: getView(workflowView, {
                    templateUrl: '/modules/common/jobs/workflow/_wrapper.html',
                    controller: 'JobReassignOperativeController',
                    controllerAs: 'vm'
                }),
                resolve: {
                    serviceProviderResponse: function(apiClient, jobResponse) {
                        return jobResponse.getResource('serviceprovider') || apiClient.noResourceFound();
                    },
                    operativeCollectionResponse: function(
                        apiClient,
                        serviceProviderResponse,
                        siteResponse,
                        jobResponse
                    ) {
                        var params = {
                            permission: 'operative_permission_receive_jobs',
                            includeServiceTypes: true
                        };

                        if (jobResponse.completionDueAt) {
                            params.jobCompletionDueAt = new Date(jobResponse.completionDueAt)
                        }

                        if (siteResponse) {
                            params.siteId = siteResponse.id;
                        }

                        if (serviceProviderResponse.getLink('operatives')) {
                            return apiClient.get(serviceProviderResponse.getLink('operatives'), params).then(function (response) {
                                return response || apiClient.noResourceFound();
                            });
                        }

                        return [];
                    },
                    formData: function(jobResponse) {
                        return {
                            note: null,
                            currentOperativeLink: jobResponse.getLink('operative'),
                            currentOperative: jobResponse.getLinkAttribute('operative', 'title'),
                            _links: {}
                        };
                    },
                    scopeData: function(apiClient, $translate, siteResponse, operativeCollectionResponse) {
                        var returnObject = {
                            action: 'reassign-operative',
                            translation: 'JOB_WORKFLOW_REASSIGN_OPERATIVE',
                            noteRequired: false,
                            partial: 'reassign-operative',
                            siteResponse: siteResponse,
                            operativeSelectModel: {
                                link: operativeCollectionResponse.getLink('self'),
                                response: operativeCollectionResponse,
                                responseKeyPath: 'operatives',
                                required: true,
                                itemHrefPath: '_links.self.href',
                                itemValuePath: '_links.user.title'
                            }
                        };

                        return returnObject;
                    }
                },
                ncyBreadcrumb: getLabel('JOB_WORKFLOW_REASSIGN_ASSIGNMENT')
            })
            .state(workflowRoute + '.reject-approval', {
                url: '/approval/reject',
                parent: workflowRoute,
                views: getView(workflowView, {
                    templateUrl: '/modules/common/jobs/workflow/_wrapper.html',
                    controller: 'JobApprovalRejectController',
                    controllerAs: 'vm'
                }),
                ncyBreadcrumb: getLabel('JOB_WORKFLOW_REJECT_APPROVAL')
            })
            .state(workflowRoute + '.escalate-approval', {
                url: '/approval/escalate',
                parent: workflowRoute,
                views: getView(workflowView, {
                    templateUrl: '/modules/common/jobs/workflow/_wrapper.html',
                    controller: 'JobApprovalEscalateController',
                    controllerAs: 'vm'
                }),
                ncyBreadcrumb: getLabel('JOB_WORKFLOW_ESCALATE_APPROVAL')
            })
            .state(workflowRoute + '.accept-approval', {
                url: '/approval/accept',
                parent: workflowRoute,
                views: getView(workflowView, {
                    templateUrl: '/modules/common/jobs/workflow/_wrapper.html',
                    controller: 'JobApprovalAcceptOrReassignController',
                    controllerAs: 'vm'
                }),
                resolve: {
                    serviceProviderResponse: function (apiClient, jobResponse) {
                        // get service provider if set, might not be at this stage as
                        // job may have been created by a tenant for example
                        if (jobResponse.getLink('serviceprovider')) {
                            return jobResponse.getResource('serviceprovider') || apiClient.noResourceFound();
                        }
                        return null;
                    },
                    operativeCollectionResponse: function (apiClient, serviceProviderResponse, siteResponse) {
                        var params = {
                            permission: 'operative_permission_receive_jobs',
                            includeServiceTypes: true
                        };

                        if (siteResponse) {
                            params.siteId = siteResponse.id;
                        }

                        if (serviceProviderResponse && serviceProviderResponse.getLink('joboperatives')) {
                            return apiClient.get(serviceProviderResponse.getLink('joboperatives'), params).then(function (response) {

                                return response || apiClient.noResourceFound();
                            });
                        }
                    },
                    partnershipCollectionResponse: function (apiClient, siteResponse, elbSettings) {
                        var params = {active: 1, includeCanCreateJobsOtherServiceProviders: 'true'};

                        if (elbSettings.getSetting('prosure_enabled').value) {
                            params = angular.extend({order: 'prosureStatus'}, params);
                        }

                        return apiClient.get(siteResponse.getLink('partnerships'), params).then(function (response) {
                            return response || apiClient.noResourceFound();
                        });
                    },
                    assetCollectionResponse: function (apiClient, siteResponse) {
                        return apiClient.get(siteResponse.getLink('assets'), {active: 1}).then(function (response) {
                            return response || apiClient.noResourceFound();
                        });
                    },
                    chargeTypesCollectionResponse: function (apiClient, rootResourceResponse) {
                        return apiClient.get(rootResourceResponse.getLink('chargetypes'), {limit: 999999}).then(function (response) {
                            return response || apiClient.noResourceFound();
                        });
                    },
                    jobGroupCollectionResponse: function (apiClient, rootResourceResponse) {
                        return apiClient.get(rootResourceResponse.getLink('jobgroups'), {
                            limit: 999999,
                            fetchHierarchy: false
                        }).then(function (response) {
                            return response || apiClient.noResourceFound();
                        })
                    },
                    jobGroupResponse: function (apiClient, jobResponse) {
                        return (jobResponse.getLink('jobgroup') && (jobResponse.getResource('jobgroup') || apiClient.noResourceFound()));
                    },
                    jobSettings: function (apiClient, rootResourceResponse) {
                        return apiClient.get(rootResourceResponse.getLink('jobsettings')).then(function (response) {
                            return response;
                        });
                    },
                    chargeTypeResponse: function (apiClient, jobResponse) {
                        return (jobResponse.getLink('chargetype') && (jobResponse.getResource('chargetype') || apiClient.noResourceFound()));
                    },
                    assetResponse: function (apiClient, jobResponse) {
                        return (jobResponse.getLink('asset') && (jobResponse.getResource('asset') || apiClient.noResourceFound()));
                    },
                    priorityResponse: function (apiClient, jobResponse) {
                        return (jobResponse.getLink('priority') && (jobResponse.getResource('priority', true) || apiClient.noResourceFound()));
                    },
                    priorityCollectionResponse: function (apiClient, rootResourceResponse, siteResponse) {
                        return apiClient.get(siteResponse.getLink('priorities'), priorityLinkParameters).then(function (response) {
                            if (response.priorities.length) {
                                return response;
                            }

                            return apiClient.get(rootResourceResponse.getLink('priorities'), priorityLinkParameters).then(function (response) {
                                return response || apiClient.noResourceFound();
                            });
                        });
                    },
                    serviceProviderPriorityCollectionResponse: function (apiClient, serviceProviderResponse) {
                        if (serviceProviderResponse) {
                            return apiClient.get(serviceProviderResponse.getLink('priorities'), priorityLinkParameters).then(function (response) {
                                return response || apiClient.noResourceFound();
                            });
                        }

                        return null;
                    },
                    formData: function (jobResponse, serviceProviderResponse) {
                        return {
                            _links: {
                                location: jobResponse._links['location'],
                                jobgroup: jobResponse._links['jobgroup'] ? jobResponse._links['jobgroup'] : null,
                                serviceprovider: serviceProviderResponse ? serviceProviderResponse._links['self'] : null,
                                priority: jobResponse._links['priority'],
                                statutorytype: jobResponse._links['statutorytype'] ? jobResponse._links['statutorytype'] : null,
                                asset: jobResponse._links['asset'] ? jobResponse._links['asset'] : null,
                                chargetype: jobResponse._links['chargetype'] ? jobResponse._links['chargetype'] : null
                            },
                            attendanceDueAt: jobResponse.attendanceDueAt ? new Date(jobResponse.attendanceDueAt) : null,
                            completionDueAt: jobResponse.completionDueAt ? new Date(jobResponse.completionDueAt) : null,
                            summary: jobResponse.summary,
                            description: jobResponse.description,
                            note: null,
                            isByPlannedDate: !!jobResponse._links.priority,
                            isOnNextPlannedVisit: false,
                            optedInJobRequisites: {links: []}
                        };
                    },
                    jobGroupRequired: function (apiClient, rootResourceResponse) {
                        return apiClient.get(rootResourceResponse.getLink('jobsettings')).then(function (response) {
                            return response ? response.requireJobGroup : apiClient.noResourceFound();
                        });
                    },
                    statutoryTypesCollectionResponse: function (apiClient, rootResourceResponse) {
                        return apiClient.get(rootResourceResponse.getLink('statutorytypes')).then(function (response) {
                            return response || apiClient.noResourceFound();
                        });
                    },
                    jobRequisiteCollectionResponse: function (siteResponse, serviceProviderResponse, user, userManager, apiClient, jobResponse) {
                        if (serviceProviderResponse) {
                            var option = [];
                            if (jobResponse.type === 'planned') {
                                option.push('plannedJobOption');
                            } else {
                                option.push('reactiveJobOption');
                            }
                            return apiClient.get(user.getLink('job-requisites'), {
                                active: 1,
                                optIn: true,
                                optOut: true,
                                'option[]': option,
                                site: siteResponse.id,
                                serviceProvider: serviceProviderResponse.id
                            });
                        }

                        return null;
                    },
                    documentTypesCollectionResponse: function (apiClient, rootResourceResponse) {
                        if (rootResourceResponse.getLink('documenttypes')) {
                            return apiClient.get(rootResourceResponse.getLink('documenttypes'), {
                                status: 'active',
                                limit: 999
                            }).then(function (response) {
                                return response;
                            });
                        }
                    },
                    siteResponse: function(apiClient, jobResponse) {
                        return apiClient.get(jobResponse.getLink('site')).then(function(response) {
                            return response || apiClient.noResourceFound();
                        });
                    },
                    locationCollectionResponse: function (apiClient, siteResponse) {
                        if (siteResponse.getLink('locations')) {
                            return apiClient.get(siteResponse.getLink('locations')).then(function (response) {
                                return response || apiClient.noResourceFound();
                            });
                        }
                    },
                    scopeData: function (
                        apiClient,
                        $translate,
                        siteResponse,
                        serviceProviderResponse,
                        partnershipCollectionResponse,
                        jobGroupCollectionResponse,
                        chargeTypeResponse,
                        chargeTypesCollectionResponse,
                        priorityCollectionResponse,
                        serviceProviderPriorityCollectionResponse,
                        assetResponse,
                        assetCollectionResponse,
                        priorityResponse,
                        lodash,
                        jobGroupRequired,
                        priorityService,
                        jobGroupResponse,
                        requestDataFactory,
                        statutoryTypesCollectionResponse,
                        jobSettings,
                        serviceProviderService,
                        locationCollectionResponse
                    ) {
                        function getPriorities() {
                            return serviceProviderPriorityCollectionResponse && serviceProviderPriorityCollectionResponse.priorities.length ? serviceProviderPriorityCollectionResponse : priorityCollectionResponse;
                        }

                        function getAssets() {
                            return assetCollectionResponse;
                        }

                        function getSelection(response, key, type) {
                            if (response) {
                                return {
                                    object: response,
                                    href: response.getLink('self'),
                                    value: response[key]
                                };
                            }

                            if (type === 'priority') {
                                return {
                                    object: {self: {href: null}},
                                    value: $translate.instant('BY_PLANNED_DATE')
                                };
                            }

                            return null;
                        }

                        var returnObject = {
                            action: 'accept-approval',
                            translation: 'JOB_WORKFLOW_ACCEPT_APPROVAL',
                            partial: 'accept-approval',
                            noteRequired: false,
                            jobGroupRequired: jobGroupRequired,
                            siteResponse: siteResponse,
                            partnerships: partnershipCollectionResponse,
                            jobGroups: jobGroupCollectionResponse,
                            assets: assetCollectionResponse,
                            priorities: priorityCollectionResponse,
                            statutoryTypesCollectionResponse: statutoryTypesCollectionResponse,
                            selectedChargeTypeModel: {
                                response: chargeTypesCollectionResponse,
                                link: chargeTypesCollectionResponse.getLink('self'),
                                required: true,
                                responseKeyPath: 'chargetypes',
                                itemHrefPath: '_links.self.href',
                                itemValuePath: 'name',
                                selected: getSelection(chargeTypeResponse, 'name', 'chargetype'),
                                refreshOnLinkChange: false
                            },
                            selectedJobGroupModel: {
                                response: jobGroupCollectionResponse,
                                link: jobGroupCollectionResponse.getLink('self'),
                                required: jobGroupRequired,
                                responseKeyPath: 'jobgroups',
                                itemHrefPath: '_links.self.href',
                                itemValuePath: 'name',
                                selected: getSelection(jobGroupResponse, 'name'),
                                isHierarchical: true
                            },
                            selectedPriorityModel: {
                                response: priorityService.addExtraPriorities(getPriorities()),
                                link: getPriorities().getLink('self'),
                                linkParameters: priorityLinkParameters,
                                required: true,
                                responseKeyPath: 'priorities',
                                itemHrefPath: '_links.self.href',
                                itemValuePath: 'name',
                                selected: getSelection(priorityResponse, 'name', 'priority'),
                                refreshOnLinkChange: false
                            },
                            selectedAssetModel: {
                                response: getAssets(),
                                link: getAssets() ? getAssets().getLink('self') : null,
                                required: (serviceProviderResponse && serviceProviderResponse.requiresAssetsWhenLoggingReactiveJob),
                                responseKeyPath: 'assets',
                                itemHrefPath: '_links.self.href',
                                itemValuePath: 'name',
                                selected: getSelection(assetResponse, 'name'),
                                refreshOnLinkChange: false,
                            },
                            selectedServiceProviderModel: {
                                response: partnershipCollectionResponse,
                                link: partnershipCollectionResponse.getLink('self'),
                                linkParameters: {active: 1},
                                required: true,
                                responseKeyPath: 'partnerships',
                                itemHrefPath: '_links.serviceprovider.href',
                                itemValuePath: '_links.serviceprovider.title',
                                selected: getSelection(serviceProviderResponse, 'name'),
                                formatValue: serviceProviderService.formatServiceProviderName,
                                headers: serviceProviderService.isProsureEnabled ? serviceProviderService.prosureStatusHeaders : null,
                                reorder: serviceProviderService.prosureGroupReorder
                            }
                        };

                        return returnObject;
                    }
                },
                ncyBreadcrumb: getLabel('JOB_WORKFLOW_ACCEPT_APPROVAL')
            })
            .state(workflowRoute + '.request-proposal', {
                url: '/proposal/request',
                parent: workflowRoute,
                views: getView(workflowView, {
                    templateUrl: '/modules/common/jobs/workflow/_wrapper.html',
                    controller: 'JobProposalRequestController',
                    controllerAs: 'vm'
                }),
                resolve: {
                    formData: function (jobResponse) {
                        return {
                            _links: {
                                priority: jobResponse._links['priority']
                            },
                            attendanceDueAt: jobResponse.attendanceDueAt ? new Date(jobResponse.attendanceDueAt) : null,
                            completionDueAt: jobResponse.completionDueAt ? new Date(jobResponse.completionDueAt) : null,
                            note: null,
                            isByPlannedDate: !! jobResponse._links.priority,
                            isOnNextPlannedVisit: false
                        };
                    },
                    serviceProviderResponse: function(apiClient, jobResponse) {
                        return apiClient.get(jobResponse.getLink('serviceprovider')).then(function(response) {
                            return response || apiClient.noResourceFound();
                        });
                    },
                    priorityCollectionResponse: function(apiClient, siteResponse, serviceProviderResponse, priorityService) {
                        return apiClient.get(siteResponse.getLink('partnerships'), {'serviceProviderId': serviceProviderResponse.id}).then(function(response) {
                            var partnershipPrioritiesLink = null;

                            if (response.partnerships.length) {
                                partnershipPrioritiesLink = response.partnerships[0].getLink('priorities');
                            }

                            return priorityService.setupPriorities(
                                partnershipPrioritiesLink,
                                serviceProviderResponse.getLink('priorities'),
                                siteResponse.getLink('priorities'),
                                {active:true, entityTypes: 'entity_type_all,entity_type_job'}
                            );
                        });
                    },
                    priorityResponse: function(apiClient, jobResponse) {
                        return (jobResponse.getLink('priority') && (jobResponse.getResource('priority', true) || apiClient.noResourceFound()));
                    },
                    scopeData: function(jobResponse, $translate, priorityCollectionResponse, priorityService, priorityResponse, formData, requestDataFactory) {
                        function getSelection (response, key, type) {
                            if (response) {
                                return {
                                    object: response,
                                    href: response.getLink('self'),
                                    value: response[key]
                                };
                            }

                            if (type === 'priority') {
                                return {
                                    object: {self: {href: null}},
                                    href: null,
                                    value: $translate.instant('BY_PLANNED_DATE')
                                };
                            }

                            return null;
                        }

                        return {
                            jobResponse: jobResponse,
                            priorityCollectionResponse: priorityCollectionResponse,
                            selectedPriorityModel: {
                                response: priorityService.addExtraPriorities(priorityCollectionResponse),
                                link: priorityCollectionResponse.getLink('self'),
                                linkParameters: priorityLinkParameters,
                                required: true,
                                responseKeyPath: 'priorities',
                                itemHrefPath: '_links.self.href',
                                itemValuePath: 'name',
                                selected: getSelection(priorityResponse, 'name', 'priority'),
                                refreshOnLinkChange: false
                            },
                            isByPlannedDate: !! jobResponse._links.priority,
                            isOnNextPlannedVisit: false
                        };
                    }
                },
                ncyBreadcrumb: getLabel('JOB_WORKFLOW_REQUEST_PROPOSAL')
            })
            .state(workflowRoute + '.reassign-assignment', {
                url: '/reassign-assignment',
                parent: workflowRoute,
                views: getView(workflowView, {
                    templateUrl: '/modules/common/jobs/workflow/_wrapper.html',
                    controller: 'JobReassignController',
                    controllerAs: 'vm'
                }),
                resolve: {
                    serviceProviderResponse: function(apiClient, jobResponse) {
                        return jobResponse.getResource('serviceprovider') || apiClient.noResourceFound();
                    },
                    operativeCollectionResponse: function(
                        apiClient,
                        serviceProviderResponse,
                        siteResponse,
                        jobResponse
                    ) {
                        if (serviceProviderResponse.getLink('joboperatives')) {
                            var params = {
                                permission: 'operative_permission_receive_jobs',
                                includeServiceTypes: true
                            };

                            if (jobResponse.completionDueAt) {
                                params.jobCompletionDueAt = new Date(jobResponse.completionDueAt)
                            }

                            if (siteResponse) {
                                params.siteId = siteResponse.id;
                            }

                            return apiClient.get(serviceProviderResponse.getLink('joboperatives'), params).then(function (response) {

                                return response || apiClient.noResourceFound();
                            });
                        }
                    },
                    partnershipCollectionResponse: function (apiClient, siteResponse, elbSettings) {
                        var params = {active: 1, includeCanCreateJobsOtherServiceProviders: 'true'};

                        if (elbSettings.getSetting('prosure_enabled').value) {
                            params = angular.extend({order: 'prosureStatus'}, params);
                        }

                        return apiClient.get(siteResponse.getLink('partnerships'), params).then(function (response) {
                            return response || apiClient.noResourceFound();
                        });
                    },
                    assetCollectionResponse: function(apiClient, siteResponse) {
                        return apiClient.get(siteResponse.getLink('assets'), {active: 1}).then(function(response) {
                            return response || apiClient.noResourceFound();
                        });
                    },
                    jobGroupCollectionResponse: function(apiClient, rootResourceResponse) {
                        return apiClient.get(rootResourceResponse.getLink('jobgroups'), { limit: 999999 }).then(function(response) {
                            return response || apiClient.noResourceFound();
                        })
                    },
                    jobGroupResponse: function(apiClient, jobResponse) {
                        return (jobResponse.getLink('jobgroup') && (jobResponse.getResource('jobgroup') || apiClient.noResourceFound()));
                    },
                    assetResponse: function(apiClient, jobResponse) {
                        return (jobResponse.getLink('asset') && (jobResponse.getResource('asset') || apiClient.noResourceFound()));
                    },
                    priorityResponse: function(apiClient, jobResponse) {
                        return (jobResponse.getLink('priority') && (jobResponse.getResource('priority', true) || apiClient.noResourceFound()));
                    },
                    priorityCollectionResponse: function(apiClient, rootResourceResponse, siteResponse) {
                        return apiClient.get(siteResponse.getLink('priorities'), priorityLinkParameters).then(function(response) {
                            if (response.priorities.length) {
                                return response;
                            }

                            return apiClient.get(rootResourceResponse.getLink('priorities'), priorityLinkParameters).then(function(response) {
                                return response || apiClient.noResourceFound();
                            });
                        });
                    },
                    serviceProviderPriorityCollectionResponse: function(apiClient, serviceProviderResponse) {
                        if (serviceProviderResponse) {
                            return apiClient.get(serviceProviderResponse.getLink('priorities'), priorityLinkParameters).then(function(response) {
                                return response || apiClient.noResourceFound();
                            });
                        }

                        return null;
                    },
                    formData: function(jobResponse, serviceProviderResponse) {
                        return {
                            _links: {
                                serviceprovider: serviceProviderResponse ? serviceProviderResponse._links['self'] : null,
                                priority:        jobResponse._links['priority'],
                                asset:           jobResponse._links['asset']
                            },
                            optedInJobRequisites: {
                                links: []
                            },
                            attendanceDueAt: jobResponse.attendanceDueAt ? new Date(jobResponse.attendanceDueAt) : null,
                            completionDueAt: jobResponse.completionDueAt ? new Date(jobResponse.completionDueAt) : null,
                            note: null,
                            isByPlannedDate: !! jobResponse._links.priority,
                            isOnNextPlannedVisit: false
                        };
                    },
                    jobGroupRequired: function(apiClient, rootResourceResponse) {
                        return apiClient.get(rootResourceResponse.getLink('jobsettings')).then(function(response) {
                            return response ? response.requireJobGroup : apiClient.noResourceFound();
                        });
                    },
                    statutoryTypesCollectionResponse: function(apiClient, rootResourceResponse) {
                        return apiClient.get(rootResourceResponse.getLink('statutorytypes')).then(function(response) {
                            return response || apiClient.noResourceFound();
                        });
                    },
                    scopeData: function(
                        apiClient,
                        $translate,
                        siteResponse,
                        serviceProviderResponse,
                        partnershipCollectionResponse,
                        jobGroupCollectionResponse,
                        priorityCollectionResponse,
                        serviceProviderPriorityCollectionResponse,
                        assetResponse,
                        assetCollectionResponse,
                        priorityResponse,
                        lodash,
                        jobGroupRequired,
                        priorityService,
                        jobGroupResponse,
                        requestDataFactory,
                        statutoryTypesCollectionResponse,
                        serviceProviderService
                    ) {
                        function getPriorities () {
                            return serviceProviderPriorityCollectionResponse && serviceProviderPriorityCollectionResponse.priorities.length ? serviceProviderPriorityCollectionResponse : priorityCollectionResponse;
                        }

                        function getAssets () {
                            return assetCollectionResponse;
                        }

                        function getSelection (response, key, type) {
                            if (response) {
                                return {
                                    object: response,
                                    href: response.getLink('self'),
                                    value: response[key]
                                };
                            }

                            if (type === 'priority') {
                                return {
                                    object: {self: {href: null}},
                                    value: $translate.instant('BY_PLANNED_DATE')
                                };
                            }

                            return null;
                        }

                        var returnObject = {
                            action: 'reassign-assignment',
                            translation: 'JOB_WORKFLOW_REASSIGN_ASSIGNMENT',
                            noteRequired: false,
                            partial: 'reassign-assignment',
                            siteResponse: siteResponse,
                            partnerships: partnershipCollectionResponse,
                            jobGroups: jobGroupCollectionResponse,
                            assets: assetCollectionResponse,
                            priorities: priorityCollectionResponse,
                            statutoryTypesCollectionResponse: statutoryTypesCollectionResponse,
                            selectedJobGroupModel: {
                                response: jobGroupCollectionResponse,
                                link: jobGroupCollectionResponse.getLink('self'),
                                required: jobGroupRequired,
                                responseKeyPath: 'jobgroups',
                                itemHrefPath: '_links.self.href',
                                itemValuePath: 'name',
                                selected: getSelection(jobGroupResponse, 'name'),
                                isHierarchical: true
                            },
                            selectedPriorityModel: {
                                response: priorityService.addExtraPriorities(getPriorities()),
                                link: getPriorities().getLink('self'),
                                linkParameters: priorityLinkParameters,
                                required: true,
                                responseKeyPath: 'priorities',
                                itemHrefPath: '_links.self.href',
                                itemValuePath: 'name',
                                selected: getSelection(priorityResponse, 'name', 'priority'),
                                refreshOnLinkChange: false
                            },
                            selectedAssetModel: {
                                response: getAssets(),
                                link: getAssets().getLink('self'),
                                required: (serviceProviderResponse && serviceProviderResponse.requiresAssetsWhenLoggingReactiveJob),
                                responseKeyPath: 'assets',
                                itemHrefPath: '_links.self.href',
                                itemValuePath: 'name',
                                selected: getSelection(assetResponse, 'name'),
                                refreshOnLinkChange: false,
                            },
                            selectedServiceProviderModel: {
                                response: partnershipCollectionResponse,
                                link: partnershipCollectionResponse.getLink('self'),
                                linkParameters: {active:1},
                                required: true,
                                responseKeyPath: 'partnerships',
                                itemHrefPath: '_links.serviceprovider.href',
                                itemValuePath: '_links.serviceprovider.title',
                                selected: getSelection(serviceProviderResponse, 'name'),
                                formatValue: serviceProviderService.formatServiceProviderName,
                                headers: serviceProviderService.isProsureEnabled ? serviceProviderService.prosureStatusHeaders : null,
                                reorder: serviceProviderService.prosureGroupReorder
                            }
                        };

                        return returnObject;
                    }
                },
                ncyBreadcrumb: getLabel('JOB_WORKFLOW_REASSIGN_ASSIGNMENT')
            })
            .state(workflowRoute + '.add-operative', {
                url: '/add-operative',
                parent: workflowRoute,
                views: getView(workflowView, {
                    templateUrl: '/modules/common/jobs/workflow/_wrapper.html',
                    controller: 'JobAddAditionalOperative',
                    controllerAs: 'vm'
                }),
                resolve: {
                    serviceProviderResponse: function(apiClient, jobResponse) {
                        return jobResponse.getResource('serviceprovider') || null;
                    },
                    operativeCollectionResponse: function(apiClient, lodash, jobResponse, serviceProviderResponse, siteResponse) {
                        if (serviceProviderResponse && serviceProviderResponse.getLink('joboperatives')) {
                            var params = {
                                permission: 'operative_permission_receive_jobs',
                                includeServiceTypes: true
                            };

                            if (siteResponse) {
                                params.siteId = siteResponse.id;
                            }

                            if (jobResponse) {
                                params.excludeAdditionalOperatives = jobResponse.id;
                            }

                            return apiClient.get(serviceProviderResponse.getLink('joboperatives'), params).then(function (response) {
                                // Exlude job operative
                                var operative = jobResponse.getLinkAttribute('operative', 'title');

                                if (operative) {
                                    lodash.remove(response.operatives, function (operativeObject) {
                                        return operativeObject.getLinkAttribute('user', 'name') === operative;
                                    });
                                }

                                return response || apiClient.noResourceFound();
                            });
                        }
                    },
                    partnershipCollectionResponse: function (apiClient, siteResponse) {
                        return apiClient.get(siteResponse.getLink('partnerships'), {
                            active: 1,
                            includeCanCreateJobsOtherServiceProviders: 'true',
                        }).then(function (response) {
                            return response || apiClient.noResourceFound();
                        });
                    },
                    formData: function() {
                        return {
                            _links: {},
                        };
                    },
                    scopeData: function($translate, jobResponse, serviceProviderResponse, partnershipCollectionResponse, siteResponse) {
                        function getSelection (response, key) {
                            if (response) {
                                return {
                                    object: response,
                                    href: response.getLink('self'),
                                    value: response[key]
                                };
                            }

                            return null;
                        }

                        return {
                            action: 'add-additional-operative',
                            translation: 'ADD_ADDTIONAL_OPERATIVE',
                            partial: 'add-additional-operative',
                            noteRequired: false,
                            siteResponse: siteResponse,
                            job: jobResponse,
                            selectedServiceProviderModel: {
                                response: partnershipCollectionResponse,
                                link: partnershipCollectionResponse.getLink('self'),
                                linkParameters: {active:1},
                                required: true,
                                responseKeyPath: 'partnerships',
                                itemHrefPath: '_links.serviceprovider.href',
                                itemValuePath: '_links.serviceprovider.title',
                                selected: getSelection(serviceProviderResponse, 'name')
                            }
                        };
                    }
                },
                ncyBreadcrumb: getLabel('JOB_WORKFLOW_ADD_OPERATIVE')
            })
            .state(workflowRoute + '.insist-assignment', {
                url: '/insist-assignment',
                parent: workflowRoute,
                views: getView(workflowView, {
                    templateUrl: '/modules/common/jobs/workflow/_wrapper.html',
                    controller: 'JobAssignmentInsistController',
                    controllerAs: 'vm'
                }),
                ncyBreadcrumb: getLabel('JOB_WORKFLOW_INSIST_ASSIGNMENT')
            })
            .state(workflowRoute + '.cancel', {
                url: '/cancel',
                parent: workflowRoute,
                views: getView(workflowView, {
                    templateUrl: '/modules/common/jobs/workflow/_wrapper.html',
                    controller: 'JobOperationCancelController',
                    controllerAs: 'vm'
                }),
                ncyBreadcrumb: getLabel('JOB_WORKFLOW_CANCEL')
            })
            .state(workflowRoute + '.reopen', {
                url: '/reopen',
                parent: workflowRoute,
                views: getView(workflowView, {
                    templateUrl: '/modules/common/jobs/workflow/_wrapper.html',
                    controller: 'JobOperationReopenController',
                    controllerAs: 'vm'
                }),
                ncyBreadcrumb: getLabel('JOB_WORKFLOW_REOPEN')
            })
            .state(workflowRoute + '.escalate', {
                url: '/escalate',
                parent: workflowRoute,
                views: getView(workflowView, {
                    templateUrl: '/modules/common/jobs/workflow/_wrapper.html',
                    controller: 'JobOperationEscalateController',
                    controllerAs: 'vm'
                }),
                ncyBreadcrumb: getLabel('JOB_WORKFLOW_ESCALATE')
            })
            .state(workflowRoute + '.p2p', {
                url: '/p2p',
                parent: workflowRoute,
                views: getView(workflowView, {
                    templateUrl: '/modules/common/jobs/workflow/p2p.html',
                    controller: 'JobP2PController',
                    controllerAs: 'vm'
                }),
                resolve: {
                    serviceProviderResponse: function(apiClient, jobResponse) {
                        return jobResponse.getResource('serviceprovider') || apiClient.noResourceFound();
                    },
                    jobGroupCollectionResponse: function(apiClient, rootResourceResponse) {
                        return apiClient.get(rootResourceResponse.getLink('jobgroups'), { limit: 999999 }) || apiClient.noResourceFound();
                    },
                    jobGroupRequired: function(apiClient, rootResourceResponse) {
                        return apiClient.get(rootResourceResponse.getLink('jobsettings')).then(function(response) {
                            return response ? response.requireJobGroup : apiClient.noResourceFound();
                        });
                    },
                    operativesResponse: function (apiClient, serviceProviderResponse) {
                        if (serviceProviderResponse.getLink('joboperatives')) {
                            return apiClient.get(serviceProviderResponse.getLink('joboperatives'), { permission: 'operative_permission_receive_jobs', includeServiceTypes: true });
                        }

                        return null;
                    },
                    accountingElementsResponse: function(apiClient, jobResponse, modulesService) {
                        if (modulesService.isEnabled('p2p') && jobResponse.getLink('p2p-accounting-elements')) {
                            return apiClient.get(jobResponse.getLink('p2p-accounting-elements'));
                        }

                        return null;
                    },
                    p2pResponse: function (apiClient, jobResponse, modulesService) {
                        if (modulesService.isEnabled('p2p') && jobResponse.getLink('p2p')) {
                            return apiClient.get(jobResponse.getLink('p2p'));
                        }

                        return null;
                    }
                },
                ncyBreadcrumb: getLabel('JOB_WORKFLOW_P2P')
            })
            .state(workflowRoute + '.value', {
                abstract: true,
                url: '/value',
                parent: workflowRoute,
                views: getView(workflowView, {
                    templateUrl: '/shared-templates/view.html',
                }),
                ncyBreadcrumb: getLabel('JOB_VALUE'),
            })
            .state(workflowRoute + '.value.add', {
                url: '/add',
                parent: workflowRoute + '.value',
                views: getView(workflowView + '.value', {
                    templateUrl: '/modules/common/jobs/workflow/value-add.html',
                    controller: 'JobValueController',
                    controllerAs: 'vm',
                }),
                ncyBreadcrumb: getLabel('ADD'),
            })
            .state(workflowRoute + '.value.approve', {
                url: '/approve',
                parent: workflowRoute + '.value',
                views: getView(workflowView + '.value', {
                    templateUrl: '/modules/common/jobs/workflow/value-action.html',
                    controller: 'JobValueActionController',
                    controllerAs: 'vm',
                }),
                resolve: {
                    config: function() {
                        return {
                            type: 'approve',
                        };
                    },
                },
                ncyBreadcrumb: getLabel('JOB_VALUE_APPROVE'),
            })
            .state(workflowRoute + '.value.reject', {
                url: '/reject',
                parent: workflowRoute + '.value',
                views: getView(workflowView + '.value', {
                    templateUrl: '/modules/common/jobs/workflow/value-action.html',
                    controller: 'JobValueActionController',
                    controllerAs: 'vm',
                }),
                resolve: {
                    config: function() {
                        return {
                            type: 'reject',
                        };
                    },
                },
                ncyBreadcrumb: getLabel('JOB_VALUE_REJECT'),
            })
            .state(workflowRoute + '.foc', {
                url: '/foc',
                parent: workflowRoute,
                views: getView(workflowView, {
                    templateUrl: '/modules/common/jobs/workflow/_wrapper.html',
                    controller: 'JobFocActionController',
                    controllerAs: 'vm',
                }),
                ncyBreadcrumb: getLabel('JOB_FOC_ADD'),
            })
            .state(workflowRoute + '.waive-management-fee', {
                url: '/waive-management-fee',
                parent: workflowRoute,
                views: getView(workflowView, {
                    templateUrl: '/modules/common/jobs/workflow/_wrapper.html',
                    controller: 'JobManagementFeeActionController',
                    controllerAs: 'vm',
                }),
                resolve: {
                    config: function() {
                        return {
                            type: 'waive'
                        };
                    },
                },
                ncyBreadcrumb: getLabel('WAIVE_MANAGEMENT_FEE'),
            })
            .state(workflowRoute + '.uphold-management-fee', {
                url: '/uphold-management-fee',
                parent: workflowRoute,
                views: getView(workflowView, {
                    templateUrl: '/modules/common/jobs/workflow/_wrapper.html',
                    controller: 'JobManagementFeeActionController',
                    controllerAs: 'vm',
                }),
                resolve: {
                    config: function() {
                        return {
                            type: 'uphold'
                        };
                    },
                },
                ncyBreadcrumb: getLabel('UPHOLD_MANAGEMENT_FEE'),
            });
    }

    return this;
}

/**
 * Note:
 *
 * This is a global function. It was intended to be a service that would then be injected
 * in to the routing files / functions, but it appears that services cannot be injected in
 * to the routing functions. An unknown provider error occurs.
 */
function elbJobDetailsRouting () {
    var self = this;
    this.addRoutes = addRoutes;

    var priorityLinkParameters = {'active': true, 'entityTypes': 'entity_type_all,entity_type_job'};

    function getView (view, viewObject) {
        var result = {};
        result[view] = viewObject;

        return result;
    }

    function getLabel (translationKey) {
        return { label: '{{ ::"' + translationKey + '" | translate }}' };
    }

    /**
     * Usage:
     *     elbJobWorkflowRoutine().addRoutes(
     *         $stateProvider,
     *         'dashboard.users.jobs.list.details.status', // Route to .status of the Job details, or other parent of .workflow
     *         true || false // Whether or not to include a bookerize view
     *     )
     */
    function addRoutes ($stateProvider, parent, isBookable) {

        var detailsRoute = parent + '.details',
            jobsRoute = parent !== "dashboard.user.jobs.list" ? 'dashboard.user.jobs' : 'dashboard.user.jobs.list',
            jobsUrl = parent !== "dashboard.user.jobs.list" ? '/jobs' : '',
            baseDetailsView = '@' + jobsRoute + '.details',
            baseBookView =  'elogbooks-right@dashboard.user.jobs';

        $stateProvider
            .state(detailsRoute, {
                url: jobsUrl + '/{resource}',
                parent: parent,
                abstract: true,
                views: parseBook(
                    angular.extend(
                        {},
                        getView(baseBookView, {
                            templateUrl: '/modules/common/jobs/details/job-details.html',
                            controller: 'CommonJobDetailsController',
                            controllerAs: 'vm'
                        }),
                        getView('feed' + baseDetailsView, {
                            templateUrl: '/modules/common/jobs/details/event/feed.html',
                            controller: 'CommonJobDetailsEventFeedController',
                            controllerAs: 'vm'
                        })
                    )
                ),
                params: {
                    hasExplicitAccess: null
                },
                resolve: {
                    jobResponse: function (apiClient, $stateParams) {
                        return apiClient.get($stateParams.resource.decode(), { datagroup: 'details' }).then(function(response) {
                            return response || apiClient.noResourceFound();
                        });
                    },
                    siteResponse: function (apiClient, jobResponse) {
                        if (typeof jobResponse.hasExplicitAccess !== 'undefined') {
                            return {}
                        } else {
                            return jobResponse.getResource('site') || apiClient.noResourceFound();
                        }
                    },
                    eventCollectionResponse: function (apiClient, jobResponse) {
                        if (jobResponse.getLink('events')) {
                            return apiClient.get(jobResponse.getLink('events'), { datagroup: 'details' }).then(function(response) {
                                return response || apiClient.noResourceFound();
                            });
                        }
                    },
                    messageCollectionResponse: function (jobResponse) {
                        return jobResponse.getResource('messages');
                    },
                    jobRequisitesResponse: function (apiClient, $stateParams) {
                        return apiClient.get($stateParams.resource.decode() + '/jobrequisites', { datagroup: 'list' }).then(function(response) {
                            return response || apiClient.noResourceFound();
                        });
                    },
                    jobLinksCollection: function(apiClient, $stateParams) {
                        return apiClient.get($stateParams.resource.decode() + '/links').then(function(response) {
                            return response || apiClient.noResourceFound();
                        });
                    },
                    canViewJobLinks: function(jobResponse) {
                        return jobResponse.getLink('create-link') ? true : false;
                    }
                },
                ncyBreadcrumb: {
                    parent: 'dashboard.user.jobs.list',
                    label: '{{ ::$resolves().jobResponse.summary }} '
                }
            })
            .state(detailsRoute + '.status', {
                url: '/status',
                parent: parent + '.details',
                views: getView('info' + baseDetailsView, {
                    templateUrl: '/modules/common/jobs/details/status/status.html'
                }),
                ncyBreadcrumb: {
                    label: '{{ ::"JOB_STATUS" | translate }}'
                }
            })
            .state(detailsRoute + '.files', {
                url: '/files?filePage&fileLimit',
                parent: parent + '.details',
                views: getView('info' + baseDetailsView, {
                    templateUrl: '/modules/common/files/files-list.html',
                    controller: 'CommonFilesListController',
                    controllerAs: 'vm'
                }),
                params: {
                    documentType: null,
                    filePage: '1',
                    fileLimit: '30',
                    fileOrder: 'id'
                },
                resolve: {
                    fileCollectionResponse: function(apiClient, jobResponse, $stateParams) {
                        var params = {
                            order: $stateParams.fileOrder,
                            limit: $stateParams.fileLimit,
                            page: $stateParams.filePage
                        };
                        return apiClient.get(jobResponse.getLink('files'), params).then(function(response) {
                            return response || apiClient.noResourceFound();
                        });
                    },
                    config: function(config) {
                        return angular.extend({}, config, {
                            showStatutoryType: true
                        });
                    },
                    documentTypeCollectionResponse : function (apiClient, rootResourceResponse, jobResponse) {
                        return apiClient.get(rootResourceResponse.getLink('documenttypes'), {status: 'active', order: 'name', job: jobResponse.id, limit: 999})
                            .then(function (response) {
                                return response || apiClient.noResourceFound();
                            });
                    },
                    serviceProviderResponse: function () {
                        return null;
                    },
                    operativeCollectionResponse: function () {
                        return null;
                    }
                },
                ncyBreadcrumb: {
                    label: '{{ ::"FILES" | translate }}'
                }
            })
            .state(detailsRoute + '.job-files', {
                url: '/job-files?filePage&fileLimit',
                parent: parent + '.details',
                views: getView('info' + baseDetailsView, {
                    templateUrl: '/modules/common/files/files-list.html',
                    controller: 'CommonFilesListController',
                    controllerAs: 'vm'
                }),
                params: {
                    documentType: null,
                    filePage: '1',
                    fileLimit: '30',
                    fileOrder: 'id'
                },
                resolve: {
                    fileCollectionResponse: function(apiClient, siteResponse, user, jobResponse, $stateParams) {
                        var params = {
                            site: jobResponse.getLinkAttribute('site', 'id'),
                            order: $stateParams.fileOrder,
                            limit: $stateParams.fileLimit,
                            page: $stateParams.filePage,
                            isJobFile: true,
                            'applicableTo[]': [jobResponse.type]
                        };

                        return apiClient.get(jobResponse.getLink('site-files'), params, false).then(function(response) {
                            return response || apiClient.noResourceFound();
                        });
                    },
                    config: function(config) {
                        return angular.extend({}, config, {
                            showStatutoryType: true,
                            showDownloadButton: true,
                            hideAddButton: true
                        });
                    },
                    documentTypeCollectionResponse : function (apiClient, rootResourceResponse) {
                        return apiClient.get(rootResourceResponse.getLink('documenttypes'), {status: 'active', order: 'name', limit: 999})
                            .then(function (response) {
                                return response || apiClient.noResourceFound();
                            });
                    },
                    serviceProviderResponse: function () {
                        return null;
                    },
                    operativeCollectionResponse: function () {
                        return null;
                    }
                },
                ncyBreadcrumb: {
                    label: '{{ ::"FILES_JOB_FILES" | translate }}'
                }
            })
            .state(detailsRoute + '.files.details', {
                url: '/details/{fileResource}',
                parent: parent + '.details.files',
                views: getView('files' + baseDetailsView, {
                    templateUrl: '/modules/common/files/details/files-details.html',
                    controller: 'CommonFilesDetailsController',
                    controllerAs: 'vm',
                }),
                resolve: {
                    fileResponse: function(apiClient, $stateParams) {
                        return apiClient.get($stateParams.fileResource.decode()).then(function(response) {
                            return response || apiClient.noResourceFound();
                        });
                    },
                    config: function (config) {
                        return angular.extend(config, {
                            showVisibility: true
                        });
                    }
                },
                ncyBreadcrumb: {
                    label: '{{ ::$resolves().fileResponse.title }}'
                }
            })
            .state(detailsRoute + '.files.details.edit', {
                url: '/edit',
                parent: parent + '.details.files.details',
                views: getView('files' + baseDetailsView, {
                    templateUrl: '/modules/common/files/add-edit/files-add-edit.html',
                    controller: 'CommonFilesAddEditController',
                    controllerAs: 'vm'
                }),
                resolve: {
                    config: function(siteResponse, jobResponse, config) {
                        return angular.extend(config, {
                            entitySite: siteResponse,
                            associatedEntity: jobResponse,
                            fileCreateLink: jobResponse.getLink('files'),
                            showVisibility: true
                        });
                    },
                    tagCollectionResponse: function(apiClient, rootResourceResponse) {
                        return apiClient.get(rootResourceResponse.getLink('tags'), { limit: 30 }).then(function(response) {
                            return response || apiClient.noResourceFound();
                        });
                    },
                    integrationDocTypesResponse: function() {
                        return null;
                    }
                },
                ncyBreadcrumb: {
                    label: '{{ ::"EDIT" | translate }}'
                }
            })
            .state(detailsRoute + '.files.add', {
                url: '/add',
                parent: parent + '.details.files',
                views: getView('info' + baseDetailsView, {
                    templateUrl: '/modules/common/files/add-edit/files-add-edit.html',
                    controller: 'CommonFilesAddEditController',
                    controllerAs: 'vm'
                }),
                resolve: {
                    fileResponse: function() {
                        return {};
                    },
                    tagCollectionResponse: function(apiClient, rootResourceResponse) {
                        return apiClient.get(rootResourceResponse.getLink('tags'), { limit: 30 }).then(function(response) {
                            return response || apiClient.noResourceFound();
                        });
                    },
                    config: function(siteResponse, jobResponse, config) {
                        return angular.extend(config, {
                            entitySite: siteResponse,
                            associatedEntity: jobResponse,
                            fileCreateLink: jobResponse.getLink('files'),
                            showVisibility: true
                        });
                    },
                    integrationDocTypesResponse: function() {
                        return null;
                    }
                },
                ncyBreadcrumb: {
                    label: '{{ ::"ADD" | translate }}'
                }
            })
            .state(detailsRoute + '.contacts', {
                url: '/contacts',
                parent: parent + '.details',
                views: getView('info' + baseDetailsView, {
                    templateUrl: '/modules/common/contacts/contacts.html',
                    controller: 'CommonContactsController',
                    controllerAs: 'vm'
                }),
                resolve: {
                    entityResponse: function (jobResponse) {
                        return jobResponse;
                    },
                    contactCollectionResponse: function(apiClient, jobResponse) {
                        return apiClient.get(jobResponse.getLink('contacts')).then(function(response) {
                            return response || (jobResponse.getLink('contacts') && apiClient.noResourceFound());
                        });
                    },
                    config: function(config) {
                        return angular.extend(config, {
                            entityName: 'JOB'
                        });
                    }
                },
                ncyBreadcrumb: {
                    label: '{{ ::"CONTACTS" | translate }}'
                }
            })
            .state(detailsRoute + '.site-notes', {
                url: '/site-notes',
                parent: parent + '.details',
                views: getView('info' + baseDetailsView, {
                    templateUrl: '/modules/common/site-notes/site-notes-list.html',
                    controller: 'CommonSiteNotesController',
                    controllerAs: 'vm'
                }),
                resolve: {
                    siteNoteCollectionResponse: function(apiClient, siteResponse) {
                        return apiClient.get(siteResponse.getLink('notes'), { shared: 'true' }).then(function(response) {
                            return response || apiClient.noResourceFound();
                        });
                    }
                },
                ncyBreadcrumb: {
                    label: '{{ ::"NOTES" | translate }}'
                }
            })
            .state(detailsRoute + '.requisites', {
                url: '/requisites',
                parent: parent + '.details',
                views: getView('info' + baseDetailsView, {
                    templateUrl: '/modules/common/jobs/details/requisites/requisites.html',
                    controller: 'CommonJobDetailsRequisitesController',
                    controllerAs: 'vm'
                }),
                resolve: {
                    jobRequisiteEventsResponse: function(apiClient, jobResponse) {
                        return apiClient.get(jobResponse.getLink('jobrequisitesanswers')).then(function(response) {
                            return response || apiClient.noResourceFound();
                        });
                    }
                },
                ncyBreadcrumb: {
                    label: '{{ ::"REQUISITES" | translate }}'
                }
            })
            .state(detailsRoute + '.requisites.view-form', {
                url: '/view-form/{formVersionInstanceResource}',
                parent: parent + '.details.requisites',
                views: getView('info' + baseDetailsView, {
                    templateUrl: '/modules/common/forms/details/details.html',
                    controller: 'CommonFormsDetailsController',
                    controllerAs: 'vm',
                }),
                resolve: {
                    formVersionInstanceResponse: function($stateParams, apiClient) {
                        return apiClient
                            .get($stateParams.formVersionInstanceResource.decode(), {datagroup: 'details'})
                            .then(function(response) {
                                return response || apiClient.noResourceFound();
                            });
                    },
                    associatedEntities: function(jobResponse) {
                        return {
                            job: jobResponse,
                        };
                    },
                    config: function() {
                        return {
                            mode: 'render-preview',
                            jobRequisite: true
                        };
                    }
                },
                ncyBreadcrumb: {
                    label: '{{ ::$resolves().formVersionInstanceResponse._embedded.form.summary }}'
                }
            })
            .state(detailsRoute + '.requisites.view-form.edit', {
                url: '/edit',
                parent: parent + '.details.requisites.view-form',
                views: getView('info' + baseDetailsView, {
                    templateUrl: '/modules/common/forms/details/details.html',
                    controller: 'CommonFormsDetailsController',
                    controllerAs: 'vm',
                }),
                resolve: {
                    config: function() {
                        return {
                            mode: 'render'
                        };
                    },
                },
                ncyBreadcrumb: {
                    label: '{{ ::"EDIT" | translate }}',
                },
            })
            .state(detailsRoute + '.forms', {
                url: '/forms?formsPage&formsLimit&formsOrder&formsSummary&formsDescription',
                parent: parent + '.details',
                params: {
                    formsPage: '1',
                    formsLimit: '10',
                    formsOrder: 'summary',
                    formsSummary: null,
                    formsDescription: null,
                },
                views: getView('info' + baseDetailsView, {
                    templateUrl: '/modules/common/forms/list/forms-list.html',
                    controller: 'CommonFormsListController',
                    controllerAs: 'vm'
                }),
                resolve: {
                    formVersionInstanceCollectionResponse: function($stateParams, apiClient, jobResponse) {
                        var params = {
                            page: $stateParams.formsPage,
                            limit: $stateParams.formsLimit,
                            order: $stateParams.formsOrder,

                            summary: $stateParams.formsSummary,
                            description: $stateParams.formsDescription,
                        };

                        return apiClient
                            .get(jobResponse.getLink('forms'), params)
                            .then(function(response) {
                                return response || apiClient.noResourceFound();
                            });
                    }
                },
                ncyBreadcrumb: {
                    label: '{{ ::"FORMS" | translate }}',
                }
            })
            .state(detailsRoute + '.forms.add', {
                url: '/add',
                parent: parent + '.details.forms',
                views: getView('info' + baseDetailsView, {
                    templateUrl: '/modules/common/forms/add/form-form.html',
                    controller: 'CommonFormsAddController',
                    controllerAs: 'vm'
                }),
                resolve: {
                    formCollectionResponse: function(apiClient, rootResourceResponse, jobResponse) {
                        var params = {
                            type: 'job',
                            active: true,
                            fvStatus: 1,
                            excludeJobId: jobResponse.id,
                        }
                        return apiClient.get(rootResourceResponse.getLink('forms'), params).then(function(response) {
                            return response || apiClient.noResourceFound();
                        });
                    }
                },
                ncyBreadcrumb: {
                    label: '{{ ::"ADD" | translate }}',
                }
            })
            .state(detailsRoute + '.forms.details', {
                url: '/{formVersionInstanceResource}',
                parent: parent + '.details.forms',
                views: getView('info' + baseDetailsView, {
                    templateUrl: '/modules/common/forms/details/details.html',
                    controller: 'CommonFormsDetailsController',
                    controllerAs: 'vm',
                }),
                resolve: {
                    formVersionInstanceResponse: function($stateParams, apiClient) {
                        return apiClient
                            .get($stateParams.formVersionInstanceResource.decode(), {datagroup: 'details'})
                            .then(function(response) {
                                return response || apiClient.noResourceFound();
                            });
                    },
                    assetResponse: function (apiClient, jobResponse) {
                        if (jobResponse.getLink('asset')) {
                            return apiClient.get(jobResponse.getLink('asset'), {active:1}).then(function (response) {
                                return response;
                            });
                        }

                        return {};
                    },
                    taskEventResponse: function (apiClient, jobResponse) {
                        if (jobResponse.getLink('taskevent')) {
                            return apiClient.get(jobResponse.getLink('taskevent'), {}).then(function (response) {
                                return response;
                            });
                        }

                        return null;
                    },
                    taskResponse: function (apiClient, taskEventResponse) {
                        if (taskEventResponse && taskEventResponse.getLink('task')) {
                            return apiClient.get(taskEventResponse.getLink('task'), {}).then(function (response) {
                                return response;
                            });
                        }

                        return {};
                    },
                    associatedEntities: function(jobResponse, assetResponse, taskResponse) {
                        return {
                            job: jobResponse,
                            asset: assetResponse,
                            task: taskResponse
                        };
                    },
                    config: function() {
                        return {
                            mode: 'render-preview'
                        };
                    }
                },
                ncyBreadcrumb: {
                    label: '{{ ::$resolves().formVersionInstanceResponse._embedded.form.summary }}'
                }
            })
            .state(detailsRoute + '.forms.details.edit', {
                url: '/edit',
                parent: parent + '.details.forms.details',
                views: getView('info' + baseDetailsView, {
                    templateUrl: '/modules/common/forms/details/details.html',
                    controller: 'CommonFormsDetailsController',
                    controllerAs: 'vm',
                }),
                resolve: {
                    config: function($state) {
                        return {
                            mode: 'render',
                        };
                    },
                },
                ncyBreadcrumb: {
                    label: '{{ ::"EDIT" | translate }}',
                },
            })
            .state(detailsRoute + '.requisites.approve', {
                url: '/{jobRequisiteResource}/approve',
                parent: parent + '.details.requisites',
                views: getView('info' + baseDetailsView, {
                    templateUrl: '/modules/common/jobs/details/requisites/workflow.html',
                    controller: 'CommonJobRequisitesWorkflowController',
                    controllerAs: 'vm'
                }),
                resolve: {
                    jobRequisiteAnswerResponse: function(apiClient, $stateParams, base64) {
                        return apiClient.get(base64.decode($stateParams.jobRequisiteResource));
                    }
                },
                ncyBreadcrumb: '{{ ::"JOB_REQUISITE_APPROVE" | translate }}'
            })
            .state(detailsRoute + '.requisites.reject', {
                url: '/{jobRequisiteResource}/reject',
                parent: parent + '.details.requisites',
                views: getView('info' + baseDetailsView, {
                    templateUrl: '/modules/common/jobs/details/requisites/workflow.html',
                    controller: 'CommonJobRequisitesWorkflowController',
                    controllerAs: 'vm'
                }),
                resolve: {
                    jobRequisiteAnswerResponse: function(apiClient, $stateParams, base64) {
                        return apiClient.get(base64.decode($stateParams.jobRequisiteResource));
                    }
                },
                ncyBreadcrumb: '{{ ::"JOB_REQUISITE_REJECT" | translate }}'
            })
            .state(detailsRoute + '.requisites.escalate', {
                url: '/{jobRequisiteResource}/escalate',
                parent: parent + '.details.requisites',
                views: getView('info' + baseDetailsView, {
                    templateUrl: '/modules/common/jobs/details/requisites/workflow.html',
                    controller: 'CommonJobRequisitesWorkflowController',
                    controllerAs: 'vm'
                }),
                resolve: {
                    jobRequisiteAnswerResponse: function(apiClient, $stateParams, base64) {
                        return apiClient.get(base64.decode($stateParams.jobRequisiteResource));
                    }
                },
                ncyBreadcrumb: '{{ ::"JOB_REQUISITE_ESCALATE" | translate }}'
            })
            .state(detailsRoute + '.service-routine-lines', {
                url: '/service-routine-lines?serviceRoutineLinePage&serviceRoutineLineLimit',
                parent: parent + '.details',
                views: getView('info' + baseDetailsView, {
                    templateUrl: '/modules/common/service-routines/lines/service-routine-lines-list.html',
                    controller: 'CommonServiceRoutineLinesController',
                    controllerAs: 'vm'
                }),
                params: {
                    serviceRoutineLinePage: '1',
                    serviceRoutineLineLimit: '30'
                },
                resolve: {
                    serviceRoutine: function(apiClient, jobResponse) {
                        if (typeof jobResponse._links.serviceroutine !== 'undefined') {
                            return apiClient.get(jobResponse.getLink('serviceroutine'));
                        }

                        return null;
                    },
                    serviceRoutineLines: function(apiClient, serviceRoutine, $stateParams) {
                        if (!serviceRoutine) {
                            return {count: 0};
                        }

                        if (typeof serviceRoutine._links.lines !== 'undefined') {

                            return apiClient.get(serviceRoutine.getLink('lines'), angular.extend({}, $stateParams, {
                                page: $stateParams.serviceRoutineLinePage,
                                limit: $stateParams.serviceRoutineLineLimit
                            })).then(function(response) {
                                if (response) {
                                    return response;
                                }
                            });
                        }

                        return {};
                    },
                    taskEvent: function(apiClient, jobResponse) {
                        if (typeof jobResponse._links.taskevent !== 'undefined') {
                            return apiClient.get(jobResponse.getLink('taskevent'));
                        }

                        return {};
                    }
                },
                ncyBreadcrumb: {
                    label: '{{ ::"SERVICE_ROUTINE_LINES" | translate }}'
                }
            })
            .state(detailsRoute + '.links-to', {
                url: '/links-to',
                parent: parent + '.details',
                views: getView('info' + baseDetailsView, {
                    templateUrl: '/modules/common/jobs/details/links-to/links-to.html',
                    controller: 'CommonLinksToController',
                    controllerAs: 'vm'
                }),
                resolve: {
                    canCreateJobs: function(siteResponse) {
                        return !!siteResponse.getLink('create-reactive-job');
                    }
                },
                ncyBreadcrumb: {
                    label: '{{ ::"LINKS_TO" | translate }}'
                }
            })
            .state(detailsRoute + '.remedials', {
                url: '/remedials',
                parent: parent + '.details',
                views: getView('info' + baseDetailsView, {
                    templateUrl: '/modules/common/jobs/details/remedials/remedials.html',
                    controller: 'CommonRemedialsController',
                    controllerAs: 'vm'
                }),
                params: {
                    remedialsPage: '1',
                    remedialsLimit: '10'
                },
                resolve: {
                    jobRemedialsCollection: function (apiClient, jobResponse, $stateParams) {
                        var params = {
                            page: $stateParams.remedialsPage,
                            limit: $stateParams.remedialsLimit
                        };
                        if (jobResponse.getLink('remedials')) {
                            return apiClient.get(jobResponse.getLink('remedials'), params).then(function(response) {
                                return response;
                            });
                        }
                    }
                },
                ncyBreadcrumb: {
                    label: '{{ ::"REMEDIALS" | translate }}'
                }
            })
            .state(detailsRoute + '.explicit-access', {
                url: '/explicit-access?explicitPage&explicitLimit',
                parent: parent + '.details',
                views: getView('info' + baseDetailsView, {
                    templateUrl: '/modules/common/jobs/details/explicit-access/explicit-access-list.html',
                    controller: 'CommonExplicitAccessController',
                    controllerAs: 'vm'
                }),
                params: {
                    explicitPage: '1',
                    explicitLimit: '10'
                },
                resolve: {
                    explicitAccessCollectionResponse: function (apiClient, jobResponse, $stateParams) {
                        var params = {
                            page: $stateParams.explicitPage,
                            limit: $stateParams.explicitLimit
                        };
                        return apiClient.getPage(jobResponse.getLink('explicit-access'), params);
                    }
                },
                ncyBreadcrumb: {
                    label: '{{ ::"EXPLICIT_ACCESS" | translate }}'
                }
            })
            .state(detailsRoute + '.explicit-access.add', {
                url: '/add',
                parent: parent + '.details.explicit-access',
                views: getView('info' + baseDetailsView, {
                    templateUrl: '/modules/common/jobs/details/explicit-access/explicit-access-add-edit.html',
                    controller: 'CommonExplicitAccessAddEditController',
                    controllerAs: 'vm'
                }),
                resolve: {
                    userCollectionResponse: function (apiClient, user, jobResponse, siteResponse) {
                        return apiClient.get(user.getLink('users'), { explicitAccess:true, status:'active', jobId:jobResponse.id });
                    },
                    explicitAccessUserResponse: function() {
                        return false;
                    },
                    config: function() {
                        return {
                            edit: false
                        }
                    }
                },
                ncyBreadcrumb: {
                    label: '{{ ::"EXPLICIT_ACCESS_ADD" | translate }}'
                }
            })
            .state(detailsRoute + '.explicit-access.edit', {
                url: '/{explicitResource}/edit',
                parent: parent + '.details.explicit-access',
                views: getView('info' + baseDetailsView, {
                    templateUrl: '/modules/common/jobs/details/explicit-access/explicit-access-add-edit.html',
                    controller: 'CommonExplicitAccessAddEditController',
                    controllerAs: 'vm'
                }),
                resolve: {
                    userCollectionResponse: function (apiClient, user) {
                        return apiClient.get(user.getLink('users'));
                    },
                    explicitAccessUserResponse: function(apiClient, base64, $stateParams) {
                        return apiClient.get(base64.decode($stateParams.explicitResource));
                    },
                    config: function() {
                        return {
                            edit: true
                        }
                    }
                },
                ncyBreadcrumb: {
                    label: '{{ ::"EXPLICIT_ACCESS_EDIT" | translate }}'
                }
            })
            .state(detailsRoute + '.labour-lines', {
                url: '/labour-lines?labourLinePage&labourLineLimit',
                parent: parent + '.details',
                views: getView('info' + baseDetailsView, {
                    templateUrl: '/modules/common/labour-lines/labour-lines-list.html',
                    controller: 'CommonLabourLinesListController',
                    controllerAs: 'vm'
                }),
                params: {
                    labourLinePage: '1',
                    labourLineLimit: '30'
                },
                resolve: {
                    jobLabourLinesCollectionResponse: function(apiClient, jobResponse, $stateParams) {
                        return apiClient.get(jobResponse.getLink('job-labours'), angular.extend({}, $stateParams, {
                            page: $stateParams.labourLinePage,
                            limit: $stateParams.labourLineLimit
                        })).then(function(response) {
                            if (response) {
                                return response;
                            }
                        });
                    },
                    labourTypeCollectionResponse: function (apiClient, rootResourceResponse) {
                        if (rootResourceResponse.getLink('labourtypes')) {
                            return apiClient.get(rootResourceResponse.getLink('labourtypes')).then( function(response) {
                                return response || null;
                            });
                        }
                    }
                },
                ncyBreadcrumb: {
                    label: '{{ ::"LABOUR_LINES" | translate }}'
                }
            })
            .state(detailsRoute + '.labour-lines.add', {
                url: '/add',
                parent: parent + '.details.labour-lines',
                views: getView('info' + baseDetailsView, {
                    templateUrl: '/modules/common/labour-lines/add-edit/labour-lines-add-edit.html',
                    controller: 'CommonLabourLinesAddEditController',
                    controllerAs: 'vm'
                }),
                resolve: {
                    userResponse: function(user) {
                        return user;
                    },
                    jobLabourResponse: function() {
                        return {};
                    },
                    serviceProviderResponse: function(apiClient, jobResponse) {
                        if (!jobResponse.getLink('serviceprovider')) {
                            return null;
                        }

                        return apiClient.get(jobResponse.getLink('serviceprovider'), {datagroup: 'details'}).then(function (response) {
                            return response || apiClient.noResourceFound();
                        });
                    },
                    operativeCollectionResponse: function(apiClient, serviceProviderResponse, userResponse, jobResponse) {
                        // Fetch operatives for additional operative
                        if (!serviceProviderResponse) {
                            if (userResponse.getLink('operatives')) {
                                return apiClient
                                    .get(userResponse.getLink('operatives'), { permission: 'operative_permission_receive_jobs', jobAdditionalOperatives: jobResponse.id })
                                    .then(function(response) {
                                        return response || apiClient.noResourceFound();
                                    });
                            }
                        } else if (serviceProviderResponse.isShared) {
                            if (userResponse.getLink('operatives')) {
                                return apiClient
                                    .get(userResponse.getLink('operatives'), { serviceProviderId: serviceProviderResponse.id, permission: 'operative_permission_receive_jobs' })
                                    .then(function(response) {
                                        return response || apiClient.noResourceFound();
                                    });
                            }
                        } else if (serviceProviderResponse.getLink('operatives')) {
                            return apiClient.get(serviceProviderResponse.getLink('operatives')).then(function (response) {
                                return response || apiClient.noResourceFound();
                            });
                        } else if (jobResponse.additionalOperatives.length && userResponse.getLink('operatives')) {
                            return apiClient
                                    .get(userResponse.getLink('operatives'), { permission: 'operative_permission_receive_jobs', jobAdditionalOperatives: jobResponse.id })
                                    .then(function(response) {
                                        return response || apiClient.noResourceFound();
                                    });
                        }

                        return [];
                    }
                },
                ncyBreadcrumb: {
                    label: '{{ ::"ADD" | translate }}'
                }
            })
            .state(detailsRoute + '.labour-lines.edit', {
                url: '/{jobLabourResource}/edit',
                parent: parent + '.details.labour-lines',
                views: getView('info' + baseDetailsView, {
                    templateUrl: '/modules/common/labour-lines/add-edit/labour-lines-add-edit.html',
                    controller: 'CommonLabourLinesAddEditController',
                    controllerAs: 'vm'
                }),
                resolve: {
                    userResponse: function(user) {
                        return user;
                    },
                    jobLabourResponse: function(apiClient, $stateParams) {
                        return apiClient.get($stateParams.jobLabourResource.decode()).then(function(response) {
                            return response || apiClient.noResourceFound();
                        });
                    },
                    serviceProviderResponse: function(apiClient, jobResponse) {
                        if (!jobResponse.getLink('serviceprovider')) {
                            return null;
                        }

                        return apiClient.get(jobResponse.getLink('serviceprovider'), {datagroup: 'details'}).then(function (response) {
                            return response || apiClient.noResourceFound();
                        });
                    },
                    operativeCollectionResponse: function(apiClient, serviceProviderResponse, userResponse, jobResponse) {
                        // Fetch operatives for additional operative
                        if (!serviceProviderResponse) {
                            if (userResponse.getLink('operatives')) {
                                return apiClient
                                    .get(userResponse.getLink('operatives'), { permission: 'operative_permission_receive_jobs', jobAdditionalOperatives: jobResponse.id })
                                    .then(function(response) {
                                        return response || apiClient.noResourceFound();
                                    });
                            }
                        } else if (serviceProviderResponse.isShared) {
                            if (userResponse.getLink('operatives')) {
                                return apiClient
                                    .get(userResponse.getLink('operatives'), { serviceProviderId: serviceProviderResponse.id, permission: 'operative_permission_receive_jobs' })
                                    .then(function(response) {
                                        return response || apiClient.noResourceFound();
                                    });
                            }
                        } else if (serviceProviderResponse.getLink('joboperatives')) {
                            return apiClient.get(serviceProviderResponse.getLink('joboperatives')).then(function (response) {
                                return response || apiClient.noResourceFound();
                            });
                        } else if (jobResponse.additionalOperatives.length && userResponse.getLink('operatives')) {
                            return apiClient
                                    .get(userResponse.getLink('operatives'), { permission: 'operative_permission_receive_jobs', jobAdditionalOperatives: jobResponse.id })
                                    .then(function(response) {
                                        return response || apiClient.noResourceFound();
                                    });
                        }

                        return [];
                    }
                },
                ncyBreadcrumb: {
                    label: '{{ ::"EDIT" | translate }}'
                }
            })
            .state(detailsRoute + '.sub-assets', {
                url: '/sub-assets?subAssetPage&subAssetLimit&subAssetOrder&subAssetName&subAssetReference',
                parent: parent + '.details',
                views: getView('info' + baseDetailsView, {
                    templateUrl: '/modules/common/asset/manage/details/sub-assets/sub-assets-list.html',
                    controller: 'UserAssetsSubAssetsController',
                    controllerAs: 'vm'
                }),
                params: {
                    subAssetPage: '1',
                    subAssetLimit: '30',
                    subAssetOrder: 'name',
                    subAssetName: null,
                    subAssetReference: null
                },
                resolve: {
                    assetResponse: function (apiClient, jobResponse) {
                        if (jobResponse.getLink('asset')) {
                            return apiClient.get(jobResponse.getLink('asset')).then(function (response) {
                                return response;
                            });
                        }

                        return {};
                    },
                    subAssetCollectionResponse: function (apiClient, assetResponse, $stateParams) {
                        return apiClient.get(assetResponse.getLink('subassets'), angular.extend({}, $stateParams, {
                            page: $stateParams.subAssetPage,
                            limit: $stateParams.subAssetLimit,
                            order: $stateParams.subAssetOrder,
                            name: $stateParams.subAssetName,
                            reference: $stateParams.subAssetReference
                        })).then(function (response) {
                            if (response) {
                                return response;
                            }
                        });
                    },
                    config: function() {
                        return {
                            entityName: 'JOB'
                        };
                    },
                },
                ncyBreadcrumb: {
                    label: '{{ ::"SUB_ASSETS" | translate }}'
                }
            })
            .state(detailsRoute + '.sub-assets.details', {
                url: '/details/{subAssetResource}',
                abstract: true,
                parent: parent + '.details.sub-assets',
                views: getView('info' + baseDetailsView,{
                    templateUrl: '/modules/common/asset/manage/details/sub-assets/details/sub-asset-details.html',
                    controller: 'UserAssetsSubAssetDetailsController',
                    controllerAs: 'vm'
                }),
                resolve: {
                    assetResponse: function (assetResponse) {
                        return assetResponse;
                    },
                    subAssetResponse: function (apiClient, $state, messenger, $stateParams) {
                        return apiClient.get($stateParams.subAssetResource.decode()).then(function (response) {
                            if (response) {
                                return response;
                            }
                        });
                    },
                    locationResponse: function (apiClient, subAssetResponse) {
                        if (typeof subAssetResponse._links.location != 'undefined') {
                            return apiClient.get(subAssetResponse.getLink('location'));
                        }

                        return {};
                    },
                    sectionResource: function() {
                        return {
                            infoSref: detailsRoute + '.sub-assets.details.info',
                            listSref: detailsRoute + '.sub-assets'
                        };
                    }
                },
                ncyBreadcrumb: {
                    label: '{{ ::$resolves().subAssetResponse.name }}'
                }
            })
            .state(detailsRoute + '.sub-assets.details.info', {
                url: '/info',
                parent: parent + '.details.sub-assets.details',
                views: getView('info' + baseDetailsView, {
                    templateUrl: '/modules/common/asset/manage/details/sub-assets/info/sub-asset-info.html',
                    controller: 'UserSubAssetsInfoController',
                    controllerAs: 'vm'
                }),
                ncyBreadcrumb: {
                    label: '{{ ::"INFO" | translate }}'
                }
            })
            .state(detailsRoute + '.materials', {
                url: '/materials?materialsPage&materialsLimit&materialsDescription&supplier&purchaseOrder&costType',
                parent: parent + '.details',
                views: getView('info' + baseDetailsView, {
                    templateUrl: '/modules/common/jobs/details/materials/materials.html',
                    controller: 'CommonJobDetailsMaterialsController',
                    controllerAs: 'vm'
                }),
                params: {
                    materialsPage: '1',
                    materialsLimit: '30',
                    materialsDescription: null,
                    supplier: null,
                    purchaseOrder:null,
                    costType: null
                },
                resolve: {
                    materialLineCollectionResponse: function(apiClient, jobResponse, $stateParams) {
                        var params = angular.extend({}, $stateParams,
                            {
                                page: $stateParams.materialsPage,
                                limit: $stateParams.materialsLimit,
                                datagroup: 'list',
                                description: $stateParams.materialsDescription,
                                supplier: $stateParams.supplier ? JSON.parse($stateParams.supplier).v : null
                            });

                        return apiClient.get(jobResponse.getLink('material-lines'), params).then( function(response) {
                            return response || apiClient.noResourceFound();
                        });
                    },
                    supplierCollectionResponse: function (apiClient, rootResourceResponse) {
                        return apiClient.get(rootResourceResponse.getLink('suppliers'), {
                            active: true,
                            order: 'name'
                        }).then( function(response) {
                            return response || apiClient.noResourceFound();
                        });
                    }
                },
                ncyBreadcrumb: {
                    label: '{{ ::"JOB_MATERIALS" | translate }}'
                }
            })
            .state(detailsRoute + '.materials.add', {
                url: '/add',
                parent: parent + '.details.materials',
                views: getView('info' + baseDetailsView, {
                    templateUrl: '/modules/common/jobs/details/materials/add/materials-add.html',
                    controller: 'CommonJobMaterialAddEditController',
                    controllerAs: 'vm'
                }),
                ncyBreadcrumb: {
                    label: '{{ ::"ADD" | translate }}'
                }
            })
            .state(detailsRoute + '.purchase-orders', {
                url: '/purchase-orders?purchaseOrdersPage&purchaseOrdersLimit',
                parent: parent + '.details',
                views: getView('info' + baseDetailsView, {
                    templateUrl: '/modules/common/jobs/details/purchase-orders/purchase-orders.html',
                    controller: 'CommonPurchaseOrdersController',
                    controllerAs: 'vm'
                }),
                params: {
                    purchaseOrdersPage: '1',
                    purchaseOrdersLimit: '30'
                },
                resolve: {
                    purchaseOrdersCollectionResponse: function(jobResponse, apiClient, $stateParams) {
                        var params = {
                            page: $stateParams.purchaseOrdersPage,
                            limit: $stateParams.purchaseOrdersLimit,
                            jobId: jobResponse.id
                        };

                        if (jobResponse.getLink('purchase-orders')) {
                            return apiClient.get(jobResponse.getLink('purchase-orders'), params).then(function (response) {
                                return response || apiClient.noResourceFound();
                            });
                        } else {
                            return apiClient.noResourceFound();
                        }
                    }
                },
                ncyBreadcrumb: {
                    label: '{{ ::"PURCHASE_ORDERS" | translate }}'
                }
            })
            .state(detailsRoute + '.purchase-orders.add', {
                url: '/add',
                parent: parent + '.details.purchase-orders',
                views: getView('info' + baseDetailsView, {
                    templateUrl: '/modules/user/finance/purchase-orders/add-edit/add-edit.form.html',
                    controller: 'PurchaseOrderAddEditController',
                    controllerAs: 'vm'
                }),
                resolve: {
                    purchaseOrderResponse: function() {
                        return {};
                    },
                    suppliersCollectionResponse: function (apiClient, rootResourceResponse) {
                        return apiClient.get(rootResourceResponse.getLink('suppliers'), {
                            active: true,
                            order: 'name'
                        }).then(function(response) {
                            return response || apiClient.noResourceFound();

                        });
                    },
                    config: function(jobResponse) {
                        return {
                            job: jobResponse.getLink('self')
                        };
                    }
                },
                ncyBreadcrumb: {
                    label: '{{ ::"ADD" | translate }}'
                }
            })
            .state(detailsRoute + '.job-costing', {
                url: '/job-costing',
                parent: parent + '.details',
                views: getView('info' + baseDetailsView, {
                    templateUrl: '/modules/common/jobs/details/job-costing/job-costing.html',
                    controller: 'CommonJobDetailsJobCostingController',
                    controllerAs: 'vm'
                }),
                resolve: {
                    jobResponse: function(jobResponse) {
                        return jobResponse;
                    }
                },
                ncyBreadcrumb: {
                    label: '{{ ::"JOB_COSTING" | translate }}'
                }
            })
            .state(detailsRoute + '.job-four-d', {
                url: '/job-four-d',
                parent: parent + '.details',
                views: getView('info' + baseDetailsView, {
                    templateUrl: '/modules/common/jobs/details/job-four-d/job-four-d.html',
                    controller: 'CommonJobDetailsJobFourDController',
                    controllerAs: 'vm'
                }),
                resolve: {
                    jobResponse: function(jobResponse) {
                        return jobResponse;
                    }
                },
                ncyBreadcrumb: {
                    label: '{{ ::"JOB_FOUR_D"| translate }}'
                }
            });

    }

    return this;
}
