app.config(function ($translateProvider) {
    $translateProvider.translations('nl', {
        BUILDING: 'Gebouw',
        BUILDINGS: 'Gebouwen',

        ADD_NEW_BUILDING: 'Voeg een nieuw gebouw toe',
        EDIT_BUILDING: 'Aanpassen gebouw',

        BUILDING_CREATED: 'Gebouw succesvol aangemaakt',
        BUILDING_UPDATED: 'Gebouw succesvol aangepast',
        BUILDING_DELETED: 'Gebouw succesvol verwijderd',

        NO_BUILDING_RECORDS: 'Gebouwgegevens niet aanwezig',
    });
});
