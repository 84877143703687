(function() {
    'use strict';

    angular
        .module('elogbooks')
        .config(Translations);

    function Translations($translateProvider) {
        $translateProvider.translations('nl', {
            ACTION_WORKFLOW_CANCEL: 'Annuleren',
            ACTION_WORKFLOW_COMPLETE: 'Afronden',
            ACTION_WORKFLOW_REOPEN: 'Opnieuw openen',
            ACTION_WORKFLOW_REASSIGN: 'Opnieuw toewijzen',

            ACTION_WORKFLOW_CANCEL_SUBMIT: 'Annuleren',
            ACTION_WORKFLOW_COMPLETE_SUBMIT: 'Afronden',
            ACTION_WORKFLOW_REOPEN_SUBMIT: 'Heropen',
            ACTION_WORKFLOW_REASSIGN_SUBMIT: 'Toewijzen',

            ACTION_WORKFLOW_CANCEL_SUCCESS: 'Actie Geannuleerd',
            ACTION_WORKFLOW_COMPLETE_SUCCESS: 'Actie voltooid',
            ACTION_WORKFLOW_REOPEN_SUCCESS: 'Actie Heropend',
            ACTION_WORKFLOW_REASSIGN_SUCCESS: 'Actie toegewezen',
        });
    }
})();
