(function() {
    'use strict';

    angular
        .module('elogbooks.common.assets')
        .controller('UserAssetInfoController', UserAssetInfoController);

    UserAssetInfoController.$inject = [
        'assetServiceRoutinesCollectionResponse',
        'assetResponse',
        'userManager',
        'lodash',
        'imageModal',
        'assetFilesCollectionResponse',
        'lightBoxModal'
    ];
    function UserAssetInfoController(
        assetServiceRoutinesCollectionResponse,
        assetResponse,
        userManager,
        lodash,
        imageModal,
        assetFilesCollectionResponse,
        lightBoxModal
    ) {
        var vm = this;
        vm.assetResponse = assetResponse;
        vm.serviceRoutines = assetServiceRoutinesCollectionResponse.routines;
        vm.files = [];
        vm.upload = upload;
        vm.lightBox = lightBox;
        vm.files_data = assetFilesCollectionResponse.files;

        angular.forEach(vm.assetResponse.assetScoreTypes, function (scoreType) {
            scoreType.latestScore = lodash.find(
                vm.assetResponse.historicalScores,
                { scoreTypeId: String(scoreType.id) }
            );
        });

        vm.asset = assetResponse;
        // for advanced filters
        vm.asset.selected = {
            href: vm.asset.getLink("self"),
            id: vm.asset.id,
        };

        vm.user = userManager.user;

        angular.forEach(vm.files_data, function (value) {
            vm.files.push({
                file_name: value._links.resource.title,
                href: value._links.resource.href,
                caption: value.caption,
            });
        });

        function lightBox(index) {
            vm.modalData = {
                href: vm.files[index].href,
                title: vm.files[index].title,
                caption: vm.files[index].caption,
            };
            lightBoxModal.open(vm.modalData);
        }

        function upload() {
            imageModal.open(vm.files_data, assetResponse, false, false);
        }
    }
})();