(function() {
    'use strict';

    angular
        .module('elogbooks.admin.sites')
        .controller('SitePartnershipsController', SitePartnershipsController);

    SitePartnershipsController.$inject = ['rootResourceResponse', 'user', 'partnershipsResponse', '$state', '$stateParams'];

    function SitePartnershipsController(rootResourceResponse, user, partnershipsResponse, $state, $stateParams) {
        var vm = this;

        vm.partnerships = partnershipsResponse.getData().partnerships;
        vm.partnershipsResponse = partnershipsResponse;
        vm.originalPartnerships = angular.copy(vm.partnerships);
        vm.partnershipsChanged = false;
        vm.partnershipsResponse.canCreate = vm.partnershipsResponse.getLink('createSitePartnership') || vm.partnershipsResponse.getLink('createServiceProviderPartnership');
        vm.loadedCollections = [partnershipsResponse];
        vm.canAddPartnerhip = rootResourceResponse.getLink('serviceproviders') !== null;

        vm.resources = {
            'rootResource': rootResourceResponse,
            'stateParams': $stateParams,
            'user': user
        };

        vm.criteria = {
            name: {type: 'text', title: 'NAME', value: $stateParams.name, clearValue: null},
            serviceProvidersTelephone: {type:'text', title: 'TELEPHONE', value: $stateParams.serviceProvidersTelephone, clearValue: null},
            serviceProvidersEmail: {type:'text', title: 'EMAIL', value: $stateParams.serviceProvidersEmail, clearValue: null},
            serviceProvidersAddress: {type:'text', title: 'ADDRESS', value: $stateParams.serviceProvidersAddress, clearValue: null},
        };

        vm.search = searchAction;
        vm.order = orderAction;

        function searchAction(params) {
            params.partnershipPage = 1;
            $state.go('.', params, {reload: $state.current});
        }

        function orderAction(key) {
            $stateParams.partnershipOrder = key;
            $state.go('.', $stateParams, {reload: $state.current});
        }
    };
})();
