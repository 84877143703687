(function() {
    'use strict';

    angular
        .module('elogbooks.admin.core-clients')
        .controller('CoreClientsAddEditController', CoreClientsAddEditController);

    CoreClientsAddEditController.$inject = ['$state', 'apiClient', 'confirmationModal', 'messenger', 'coreClientResponse', 'coreClientsCollectionResponse', 'requestDataFactory', '$translate'];

    function CoreClientsAddEditController($state, apiClient, confirmationModal, messenger, coreClientResponse, coreClientsCollectionResponse, requestDataFactory, $translate) {
        var vm = this;
        vm.coreClient = coreClientResponse;
        vm.coreClientsCollectionResponse = coreClientsCollectionResponse;
        vm.data = requestDataFactory.createRequest(vm.coreClient);

        vm.statusOptions = [
            { value: true, title: $translate.instant('STATUS_ACTIVE') },
            { value: false, title: $translate.instant('STATUS_INACTIVE') }
        ];

        vm.create = createAction;
        vm.update = updateAction;
        vm.delete = deleteAction;

        function updateAction() {
            return apiClient.update(vm.coreClient.getLink('edit'), vm.data).then(function (response) {
                if (response) {
                    $state.go('^', {}, { reload: 'dashboard.admin.core-clients.list' }).then(function () {
                        messenger.success('CORE_CLIENT_UPDATED');
                    });
                } else {
                    messenger.error('REQUEST_ERROR');
                }
            });
        }

        function createAction() {
            return apiClient.create(vm.coreClientsCollectionResponse.getLink('create'), vm.data).then(function (response) {
                if (response) {
                    $state.go('dashboard.admin.core-clients.list.details.info', { coreClientresource: response.getLink('self').encode() }, { reload: $state.current.parent }).then(function () {
                        messenger.success('CORE_CLIENT_CREATED');
                    });
                } else {
                    messenger.error('REQUEST_ERROR');
                }
            });
        }

        function deleteAction() {
            return confirmationModal.open().result.then(function(response) {
                if (response.result) {
                     apiClient.delete(vm.data.getLink('delete')).then(function(isSuccess) {
                        if (isSuccess) {
                            $state.go('dashboard.admin.core-clients.list', {}, { reload: 'dashboard.admin.core-clients.list' }).then(function (){
                                messenger.success('CORE_CLIENT_DELETED');
                            });
                        } else {
                            messenger.error('REQUEST_ERROR');
                        }
                    });
                }
            });
        }
    }
})();
