(function() {
    'use strict';

    angular
        .module('elogbooks.user.sites.contracts')
        .config(registerRoutes);

    function registerRoutes($stateProvider) {
        elbContractsRouting().addRoutes($stateProvider, 'dashboard.user.sites.list.details.contracts', false);
    }
})();
