(function() {
    'use strict';

    angular
        .module('elogbooks.admin.settings')
        .controller('AdminEscalationStageAddEditController', AdminEscalationStageAddEditController);

    AdminEscalationStageAddEditController.$inject = ['$scope', '$state', '$translate', 'requestDataFactory', 'escalationStagesCollectionResponse', 'escalationStageResponse', 'siteAssociateTypesCollectionResponse', 'userCollectionResponse', 'crudService', 'rootResourceResponse'];

    function AdminEscalationStageAddEditController($scope, $state, $translate, requestDataFactory, escalationStagesCollectionResponse, escalationStageResponse, siteAssociateTypesCollectionResponse, userCollectionResponse, crudService, rootResourceResponse) {

        var vm = this;

        vm.escalationStage = escalationStageResponse;
        vm.selectedAssociateTypes = [];
        vm.data = requestDataFactory.createRequest(vm.escalationStage);

        vm.userSelect = {
            response: userCollectionResponse,
            link: rootResourceResponse.getLink('users'),
            responseKeyPath: 'users',
            selectedItems: [],
            searchKey: 'name'
        };

        vm.associateTypes = siteAssociateTypesCollectionResponse.siteAssociateTypes.map(function(associateType, $index) {
            return {
                label: associateType.getLink('self'),
                name: associateType.name
            };
        });

        vm.reviewAtOptions = [
            {
                value: null,
                label: $translate.instant('NONE_SELECTED')
            },
            {
                value: 60,
                label: $translate.instant('MINUTES')
            },
            {
                value: 3600,
                label: $translate.instant('HOURS')
            },
            {
                value: 86400,
                label: $translate.instant('DAYS')
            }
        ];

        vm.reviewAtUnit = vm.reviewAtInterval = null;
        if (vm.data.id) {
            var reviewAtOptionsReversed = angular.extend([], vm.reviewAtOptions).reverse(),
                tempReviewAt = vm.data.reviewAt;


            angular.forEach(reviewAtOptionsReversed, function(value, $index) {
                if (tempReviewAt >= value.value && value.value !== null) {
                    vm.reviewAtUnit = value.value;
                    vm.reviewAtInterval = Math.ceil(tempReviewAt / value.value);

                    tempReviewAt -= (value.value * vm.reviewAtInterval);
                }
            });
        }

        if(vm.escalationStage.associateTypes) {
            angular.forEach(vm.escalationStage.associateTypes.siteAssociateTypes, function(associateType) {
                vm.selectedAssociateTypes.push({label: associateType.getLink('self'), name: associateType.name});
            });
        }

        if(vm.escalationStage.contacts) {
            angular.forEach(vm.escalationStage.contacts.users, function(contact) {
                vm.userSelect.selectedItems.push({href: contact.getLink('self'), value: contact.name});
            })
        }

        vm.create = createAction;
        vm.update = updateAction;
        vm.delete = deleteAction;

        function updateDataLinks() {
            vm.data._links.contacts = vm.userSelect.selectedItems.map(function(contact, $index) {
                return {href: contact.href};
            });

            vm.data._links.siteAssociateTypes = vm.selectedAssociateTypes.map(function(siteAssociateType, $index) {
                return {href: siteAssociateType.label};
            });

            return vm.data;
        }

        function createAction() {
            return crudService.create(
                escalationStagesCollectionResponse.getLink('create'),
                updateDataLinks(),
                $state.get('^'),
                $state.get('^'),
                'ESCALATION_STAGE',
                false
            );
        }

        function updateAction() {
            return crudService.update(
                escalationStageResponse.getLink('edit'),
                updateDataLinks(),
                $state.get('^'),
                $state.get('^'),
                'ESCALATION_STAGE',
                false
            );
        }

        function deleteAction() {
            return crudService.delete(
                escalationStageResponse.getLink('delete'),
                $state.get('^'),
                'ESCALATION_STAGE',
                {},
                false
            );
        }

        $scope.$watch('{reviewAtUnit: vm.reviewAtUnit, reviewAtInterval: vm.reviewAtInterval}', function (newValues, oldValues) {
            if (typeof newValues.reviewAtInterval === 'undefined' || typeof newValues.reviewAtUnit === 'undefined') {
                vm.data.reviewAt = 0;
            } else {
                vm.data.reviewAt = newValues.reviewAtUnit * newValues.reviewAtInterval;
            }
        });
    }
})();
