angular.module('elogbooksServices').service('validationService', function($rootScope, $state) {
    this.isFileNameValid = filename => {
        if (/[\/]/.test(filename)) {
            return false;
        }

        return true;
    };

    this.exitFormWithNoDirtyCheck = (goTo, reload = false) => {
      $rootScope.skipDirtyCheck = true;

      const reloadData = reload ? {reload: reload} : {};

      $state.go(goTo, {}, reloadData).then(() => {
         $rootScope.skipDirtyCheck = false;
      });
    };

    return this;
});
