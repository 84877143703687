(function() {
    'use strict';

    angular
        .module('elogbooks')
        .config(Translations);

    function Translations($translateProvider) {
        $translateProvider.translations('nl', {

            EVENT_FEED: 'Audit Trail',

            EVENT: 'Actie',
            EVENTS: 'Acties',

            UPDATE_NOTIFY: 'Update & Notificatie',
            NOTIFICATIONS_SENT_TO: 'Notificatie verzonden naar',
            NOTIFY: 'Notificatie',
            WRITE_YOUR_UPDATE: 'Noteer update'
        });
    }
})();
