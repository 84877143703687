(function (){
    'use strict';

    angular
        .module('elogbooks.common.meter-meters')
        .controller('CommonMetersListController', CommonMetersListController);

    CommonMetersListController.$inject = [
        '$state',
        '$stateParams',
        'meterCollectionResponse',
        'selectedSiteResponse',
        '$translate',
        'locationsCollectionResponse',
        'meterSupplyCollectionResponse',
        'siteCollectionResponse',
        'serviceProviderCollectionResponse',
        'tidyTablesService',
        'sectionResource',
        'meterOccupancyTypeCollectionResponse'
    ];

    function CommonMetersListController(
        $state,
        $stateParams,
        meterCollectionResponse,
        selectedSiteResponse,
        $translate,
        locationsCollectionResponse,
        meterSupplyCollectionResponse,
        siteCollectionResponse,
        serviceProviderCollectionResponse,
        tidyTablesService,
        sectionResource,
        meterOccupancyTypeCollectionResponse
    ) {
        var vm = this;
        vm.selectedSiteResponse = selectedSiteResponse;
        vm.meters = meterCollectionResponse.meters;
        vm.meters._links = meterCollectionResponse._links;
        vm.entityCount = meterCollectionResponse.count;
        vm.metersCollection = meterCollectionResponse;
        vm.tidyTablesService = tidyTablesService;

        vm.tidyTables = {
            name: 'meters',
            minCols: 2,
            maxCols: 12,
            columns: {
                'REFERENCE': true,
                'SERVICE_PROVIDER': true,
                'DATE_LAST_READ': false,
                'LAST_READING': true,
                'TYPE': true,
                'SERIAL_NUMBER': true,
                'LOCATION': false,
                'STATUS': true
            }
        };

        var localTidyTable = tidyTablesService.getTidyTable(vm.tidyTables.name);

        if (localTidyTable) {
            vm.tidyTables = localTidyTable;
        }

        var readingStatuses = [
            {
                title: $translate.instant('ALL'),
                value: null
            },
            {
                title: $translate.instant('OVERDUE'),
                value: 'overdue'
            },
            {
                title: $translate.instant('FUTURE_READING'),
                value: 'future'
            }
        ];

        var statusOptions = [
            {
                title: $translate.instant('NONE_SELECTED'),
                value: 'none'
            },
            {
                title: $translate.instant('STATUS_INACTIVE'),
                value: '0'
            },
            {
                title: $translate.instant('STATUS_ACTIVE'),
                value: '1'
            }
        ];

        vm.criteria = {
            meterReference: { type: 'text', title: 'REFERENCE', value: $stateParams.meterReference },
            meterSerialNumber: { type: 'text', title: 'SERIAL_NUMBER', value: $stateParams.meterSerialNumber },
            submeters: {
                type: 'options',
                title: 'SUBMETERS',
                value: $stateParams.submeters,
                options: [{ title: $translate.instant("SHOW_SUBMETERS"), value: "1" }, { title: $translate.instant("HIDE_SUBMETERS"), value: "0" }]
            },
            readingStatus: { type: 'options', title: 'READING_STATUS', value: $stateParams.readingStatus, options: readingStatuses },
            readingDue: {
                type: 'date-range',
                title: 'DATE_READING_DUE',
                value: {
                    startDate: (typeof $stateParams.readingDue === 'string') ? moment(new Date($stateParams.readingDue.split(',')[0])) : null,
                    endDate: (typeof $stateParams.readingDue === 'string') ? moment(new Date($stateParams.readingDue.split(',')[1])) : null
                },
                clearValue: {
                    startDate: null,
                    endDate: null
                }
            },
            active: { type: 'options', title: 'STATUS', value: $stateParams.active, options: statusOptions, clearValue: 'none' }
        };

        vm.sectionResource = sectionResource;

        if (meterSupplyCollectionResponse) {
            vm.meterSupplySelect = {
                response : meterSupplyCollectionResponse,
                link : meterSupplyCollectionResponse ? meterSupplyCollectionResponse.getLink('self') : null,
                itemValuePath: 'id',
                itemTitlePath: 'name',
                responseKeyPath: 'meterTypes'
            };

            vm.criteria.meterSupplyTypeId = {
                type: 'jsonselectwidget',
                title: 'METER_TYPE_SUPPLY',
                value: JSON.parse($stateParams.meterSupplyTypeId),
                options: vm.meterSupplySelect
            };
        }

        if (locationsCollectionResponse) {
            vm.locationSelect = {
                response : locationsCollectionResponse,
                link : locationsCollectionResponse ? locationsCollectionResponse.getLink('self') : null,
                itemValuePath: 'id',
                itemTitlePath: 'hierarchicalPath',
                responseKeyPath: 'locations',
            };

            vm.criteria.meterLocationId = {
                type: 'jsonselectwidget',
                title: 'LOCATION',
                value: JSON.parse($stateParams.meterLocationId),
                options: vm.locationSelect
            };
        } 

        if ($state.is('dashboard.user.meters.manage-meters.list')){
            vm.siteSelect = {
                response : siteCollectionResponse,
                link : siteCollectionResponse && siteCollectionResponse.count > 0 ? siteCollectionResponse.getLink('self') : null,
                responseKeyPath: 'sites',
                itemValuePath: 'id',
                itemTitlePath: 'name'
            };

            vm.criteria.site =  { type: 'jsonselectwidget', title: 'SITE', value: JSON.parse($stateParams.site), options: vm.siteSelect }
        }

        vm.serviceProviderSelect = {
            response : serviceProviderCollectionResponse,
            link : serviceProviderCollectionResponse && serviceProviderCollectionResponse.count > 0 ? serviceProviderCollectionResponse.getLink('self') : null,
            responseKeyPath: 'serviceproviders',
            itemValuePath: 'id',
            itemTitlePath: 'name'
        };

        vm.criteria.serviceProvider =  { type: 'jsonselectwidget', title: 'SERVICE_PROVIDER', value: JSON.parse($stateParams.serviceProvider), options: vm.serviceProviderSelect }

        if (meterOccupancyTypeCollectionResponse && meterOccupancyTypeCollectionResponse.count > 0) {
            vm.occupancyTypeOptions = {
                linkParameters: {},
                link : meterOccupancyTypeCollectionResponse.getLink('self'),
                itemValuePath: 'name',
                itemHrefPath: 'id',
                responseKeyPath: 'meterTypes'
            };

            vm.criteria.occupancyTypes = {
                type: 'autocompletemulti',
                title: 'OCCUPANCY_TYPE',
                value: $stateParams.occupancyTypes,
                multiple: true,
                options: vm.occupancyTypeOptions,
                param: 'occupancyTypes',
            };
        }              

        vm.search = search;
        function search(params) {
            var override = {
                meterPage: 1
            };

            $state.go('.', angular.extend({}, params, override), { reload: $state.current });
        }

        vm.readingsExportModalOptions = {
            templateUrl: '/modules/common/meters/meters/modal/export-readings.html',
            controller: 'CommonMeterExportReadingsController',
            controllerAs: 'vm'
        };
    }
})();
