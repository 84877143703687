(function () {
    'use strict';

    angular
        .module('elogbooks.user.reports')
        .controller('UserReportsPaperworkAwaitingController', UserReportsPaperworkAwaitingController);

    UserReportsPaperworkAwaitingController.$inject = ['jobsCollection', 'operativeResponse', 'moment', 'modalConfig'];

    function UserReportsPaperworkAwaitingController(jobsCollection, operativeResponse, moment, modalConfig) {
        var vm = this;

        vm.jobsCollection = jobsCollection;
        vm.jobs = jobsCollection.jobs;
        vm.op = operativeResponse;

        vm.modalConfig = modalConfig;
    }
})();
