app.config(function ($translateProvider) {
    $translateProvider.translations('en-gb', {
        ASSET_TYPE: 'Asset Type',
        ASSET_TYPES: 'Asset Types',

        ADD_NEW_ASSET_TYPE: 'Add a new Asset Type',
        EDIT_ASSET_TYPE: 'Edit Asset Type',

        ASSET_TYPE_LIST: 'Asset Types',
        ASSET_TYPE_ADD: 'Add a new Asset Type',
        ASSET_TYPE_EDIT: 'Edit Asset Type',

        ASSET_TYPE_CREATED: 'Asset Type Created',
        ASSET_TYPE_UPDATED: 'Asset Type Updated',
        ASSET_TYPE_DELETED: 'Asset Type Deleted',
        ASSET_TYPE_ADDED: 'Asset Type Added',
    });
});
