(function() {
    'use strict';

    angular
        .module('elogbooks.helpdesk.dashboard')
        .controller('HelpdeskDashboardEscalationsController', HelpdeskDashboardEscalationsController);

    HelpdeskDashboardEscalationsController.$inject = ['escalationCollectionResponse', '$state', '$stateParams', 'config'];

    function HelpdeskDashboardEscalationsController(escalationCollectionResponse, $state, $stateParams, config) {
        var vm = this;
        vm.escalationCollectionResponse = escalationCollectionResponse;
        vm.config = config;

        vm.criteria = {
            id: {type: 'number', title: 'ID', value: $stateParams.id ? parseInt($stateParams.id, 10) : null, min: 1},
            serviceProviderName: {type: 'text', title: 'SERVICE_PROVIDER', value: $stateParams.serviceProviderName},
            alarmAtDueRange: {
                type: 'date-time-range',
                title: 'ALARM_DUE_AT',
                startDateLabel: 'ALARM_AT_DUE_RANGE_START_DATE_LABEL',
                endDateLabel: 'ALARM_AT_DUE_RANGE_END_DATE_LABEL',
                value: $stateParams.alarmAtDueRange,
                startDateRequired: true,
                endDateRequired: true
            }
        };

        vm.search = searchAction;
        vm.order = orderAction;

        function searchAction(params) {
            var override = {
                escalationPage: 1
            };

            $state.go('.', angular.extend({}, params, override), {reload: $state.current});
        }

        function orderAction(key) {
            $stateParams.order = key;

            $state.go('.', angular.extend({}, $stateParams), {reload: $state.current});
        }
    }
})();
