(function() {
    'use strict';

    angular
        .module('elogbooks')
        .config(Translations);

    function Translations($translateProvider) {
        $translateProvider.translations('en-gb', {

            LEGEND_JOB_REACTIVE_OVERDUE_STATUTORY_ATTENDANCE: 'Overdue Statutory',
            LEGEND_JOB_REACTIVE_OVERDUE_NON_STATUTORY_ATTENDANCE: 'Overdue Non Statutory',
            LEGEND_JOB_REACTIVE_NOT_OVERDUE_ATTENDANCE: 'Open',

            LEGEND_JOB_REACTIVE_OVERDUE_STATUTORY_COMPLETION: 'Overdue Statutory',
            LEGEND_JOB_REACTIVE_OVERDUE_NON_STATUTORY_COMPLETION: 'Overdue Non Statutory',
            LEGEND_JOB_REACTIVE_NOT_OVERDUE_COMPLETION: 'Open',


            LEGEND_JOB_PLANNED_OVERDUE_STATUTORY_COMPLETION: 'Overdue Statutory',
            LEGEND_JOB_PLANNED_OVERDUE_NON_STATUTORY_COMPLETION: 'Overdue Non Statutory',
            LEGEND_JOB_PLANNED_OVERDUE_COMPLETION: 'Overdue',
            LEGEND_JOB_PLANNED_NOT_OVERDUE_COMPLETION: 'Open',

            LEGEND_ACTION_OPEN: 'Open',
            LEGEND_ACTION_COMPLETED: 'Completed',

            LEGEND_AUDIT_CREATED: 'Open',
            LEGEND_AUDIT_STARTED: 'In Progress',
            LEGEND_AUDIT_COMPLETED: 'Completed'

        });
    }
})();
