(function () {
    'use strict';

    angular
        .module('elogbooksDirectives')
        .directive('elbUserModal', ElbUserModel);

    function ElbUserModel() {
        return {
            restrict: 'E',
            scope: {
                name: '@',
                email: '@',
                phone: '@',
                type: '@'
            },
            transclude: true,
            templateUrl: function() {
                return '/modules/directives/elb-user-modal/user-modal.html';
            },
            controller: function ($scope, $uibModal) {
                var vm = this;
                vm.user  = {
                    name: $scope.name,
                    email: $scope.email,
                    phone: $scope.phone,
                    type: $scope.type
                };

                $scope.openModal = function() {
                    var modalInstance = $uibModal.open({
                        templateUrl: '/modules/common/modals/user/user-modal.html',
                        controller: 'UserModalController',
                        controllerAs: 'vm',
                        resolve: {
                            userData: function() {
                                return vm.user;
                            }
                        }
                    });
                };
            }
        };
    }
})();
