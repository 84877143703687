(function () {
    'use strict';

    angular
        .module('elogbooks.user.assets')
        .controller('UserAssetsModalAddController', UserAssetsModalAddController);

    UserAssetsModalAddController.$inject = ['$uibModalInstance', '$state', '$stateParams', 'messenger', 'requestDataFactory', 'apiClient', 'lodash', 'siteResponse', 'jobGroupsCollectionResponse', 'assetTypesCollectionResponse', 'statutoryTypesCollectionResponse', 'serviceProviderResponse', 'locationCollectionResponse', 'assetScoreTypesResponse', 'nonVersionedServiceRoutinesCollectionResponse', 'versionedServiceRoutinesCollectionResponse', 'serviceRoutineService'];

    function UserAssetsModalAddController ($uibModalInstance, $state, $stateParams, messenger, requestDataFactory, apiClient, lodash, siteResponse, jobGroupsCollectionResponse, assetTypesCollectionResponse, statutoryTypesCollectionResponse, serviceProviderResponse, locationCollectionResponse, assetScoreTypesResponse, nonVersionedServiceRoutinesCollectionResponse, versionedServiceRoutinesCollectionResponse, serviceRoutineService){
        var vm = this;
        vm.cancel = cancelAction;
        vm.data = requestDataFactory.createRequest();
        vm.site = siteResponse;
        vm.hasAssetTypes = assetTypesCollectionResponse && assetTypesCollectionResponse.assettypes.length;
        vm.selectedNonVersionedServiceRoutineModel = {};
        vm.selectedVersionedServiceRoutineModel = {};
        vm.nonVersionedServiceRoutinesCollectionResponse = nonVersionedServiceRoutinesCollectionResponse;
        vm.versionedServiceRoutinesCollectionResponse = versionedServiceRoutinesCollectionResponse;
        vm.assetTypesSelect = {};
        vm.selectedServiceRoutineModel = {};
        vm.statutoryTypes = statutoryTypesCollectionResponse;
        vm.assetScoreTypes = assetScoreTypesResponse ? assetScoreTypesResponse.scoreTypes :[];
        vm.selectedServiceRoutines = [];
        vm.data.serviceRoutineType = 'none';
        vm.removeServiceRoutine = removeServiceRoutine;

        vm.selectedScoreTypes = [];
        for (var scoreType in  vm.assetScoreTypes) {
            if (!vm.assetScoreTypes.hasOwnProperty(scoreType)) {
                continue;
            }
            var selected = {
                selected: true,
                href: vm.assetScoreTypes[scoreType].getLink('self'),
                name: vm.assetScoreTypes[scoreType].name
            };
            vm.selectedScoreTypes.push(selected);
        }

        vm.selectedServiceRoutineOptions = [
            {label: 'NONE_SELECTED', type: 'none' },
            {label: 'NON_SFG20', type: 'non_versioned'},
            {label: 'SFG20', type: 'versioned'}
        ];

        vm.jobGroupsSelect = {
            response : jobGroupsCollectionResponse,
            link : jobGroupsCollectionResponse ? jobGroupsCollectionResponse.getLink('self') : null,
            responseKeyPath: 'jobgroups',
            isHierarchical : true
        };

        vm.selectedNonVersionedServiceRoutineModel = {
            response: vm.nonVersionedServiceRoutinesCollectionResponse.count > 0 ? vm.nonVersionedServiceRoutinesCollectionResponse : null,
            link: vm.nonVersionedServiceRoutinesCollectionResponse.getLink('self'),
            linkParams: {active: 1, isVersioned: 0},
            required: false,
            responseKeyPath: 'routines',
            searchKey: 'nameReference',
            show: vm.nonVersionedServiceRoutinesCollectionResponse.routines.length === 0 ? false : true,
            formatValue: serviceRoutineService.formatServiceRoutineValue,
            onSelect: changeServiceRoutine,
            onRemove: changeServiceRoutine
        };

        vm.selectedVersionedServiceRoutineModel = {
            response: vm.versionedServiceRoutinesCollectionResponse.count > 0 ? vm.versionedServiceRoutinesCollectionResponse : null,
            link: vm.versionedServiceRoutinesCollectionResponse.getLink('self'),
            linkParams: {active: 1, isVersioned: 1},
            required: false,
            responseKeyPath: 'routines',
            searchKey: 'nameReference',
            show: vm.versionedServiceRoutinesCollectionResponse.routines.length === 0 ? false : true,
            formatValue: serviceRoutineService.formatServiceRoutineValue,
            onSelect: changeServiceRoutine,
            onRemove: changeServiceRoutine
        };

        if (vm.hasAssetTypes) {
            vm.assetTypesSelect = {
                response: assetTypesCollectionResponse,
                link: assetTypesCollectionResponse ? assetTypesCollectionResponse.getLink('self') : null,
                responseKeyPath: 'assettypes',
                isHierarchical: true
            };
        }
        vm.locationSelect = {
            response: locationCollectionResponse ? locationCollectionResponse : [],
            selected: null,
            responseKeyPath: 'locations'
        };
        vm.create = createAction;

        function createAction() {
            vm.data._links = {};

            if (typeof serviceProviderResponse !== 'undefined') {
                vm.data._links.serviceprovider = serviceProviderResponse.getLink('self')
            }

            if (typeof vm.locationSelect.selected !== 'undefined' && vm.locationSelect.selected) {
                vm.data._links.location = vm.locationSelect.selected
            }

            if (typeof vm.jobGroupsSelect.selected !== 'undefined') {
                vm.data._links.jobgroup = vm.jobGroupsSelect.selected
            }

            if (typeof vm.assetTypesSelect.selected !== 'undefined') {
                vm.data._links.type = vm.assetTypesSelect.selected
            }

            for (var scoreType in vm.selectedScoreTypes) {
                if (!vm.selectedScoreTypes.hasOwnProperty(scoreType) || !vm.selectedScoreTypes[scoreType].selected) {
                    continue;
                }
                var selectedScore = { href: vm.selectedScoreTypes[scoreType].href };
                if (!vm.data.scoreTypes) {
                    vm.data.scoreTypes = { scoretypes: []};
                }
                vm.data.scoreTypes.scoretypes.push(selectedScore);
            }

            vm.data.serviceRoutines = {
                links: []
            };

            angular.forEach(vm.selectedServiceRoutines, function(value) {
                vm.data.serviceRoutines.links.push({
                    href: value.getLink('self')
                });
            });

            apiClient.create(vm.site.getLink('assets'), vm.data).then(function (response) {
                if (response) {
                    $uibModalInstance.close(response);
                } else {
                    messenger.error('REQUEST_ERROR');
                }
            });
        }

        function cancelAction() {
            $uibModalInstance.dismiss('cancel');
        }

        function changeServiceRoutine() {
            let response;
            if (vm.data.serviceRoutineType === 'non_versioned') {
                vm.selectedServiceRoutineModel = vm.selectedNonVersionedServiceRoutineModel;
                response = nonVersionedServiceRoutinesCollectionResponse;
            } else if (vm.data.serviceRoutineType === 'versioned') {
                vm.selectedServiceRoutineModel = vm.selectedVersionedServiceRoutineModel;
                response = versionedServiceRoutinesCollectionResponse
            }

            vm.selectedServiceRoutineModel.selectedItems = lodash.unionBy(vm.selectedServiceRoutineModel.selectedItems, [vm.selectedServiceRoutineModel.selected]);
            vm.selectedServiceRoutineModel.items = lodash.differenceBy(vm.selectedServiceRoutineModel.items, vm.selectedServiceRoutineModel.selectedItems, 'href');

            if (vm.selectedServiceRoutineModel.items.length === 0 && response.pages === 1) {
                vm.selectedServiceRoutineModel.show = false;
            }

            if (vm.selectedServiceRoutineModel.selected && vm.selectedServiceRoutineModel.selected.object) {
                var isAlreadySelected = false;
                angular.forEach(vm.selectedServiceRoutines, function(value, index) {
                    if (value.getLink('self') === vm.selectedServiceRoutineModel.selected.object.getLink('self')) {
                        isAlreadySelected = true;
                    }
                });

                if (! isAlreadySelected) {
                    vm.selectedServiceRoutines.push(vm.selectedServiceRoutineModel.selected.object);
                }

                vm.selectedServiceRoutineModel.selected = null;
            }
        }

        function removeServiceRoutine(serviceRoutine) {
            if (!serviceRoutine.serviceRoutineVersionNumber) {
                vm.selectedServiceRoutineModel = vm.selectedNonVersionedServiceRoutineModel;
            } else {
                vm.selectedServiceRoutineModel = vm.selectedVersionedServiceRoutineModel;
            }

            vm.selectedServiceRoutineModel.show = true;
            vm.selectedServiceRoutineModel.selectedItems = lodash.differenceBy(vm.selectedServiceRoutineModel.selectedItems, [serviceRoutine], 'id');
            vm.selectedServiceRoutineModel.items = lodash.unionBy(vm.selectedServiceRoutineModel.items, [{object: serviceRoutine, id: serviceRoutine.id}], 'id');
            vm.selectedServiceRoutineModel.items = lodash.orderBy(vm.selectedServiceRoutineModel.items, ['id'], ['asc']);

            angular.forEach(vm.selectedServiceRoutines, function(value, index) {
                if (value._links.self.href === serviceRoutine._links.self.href) {
                    vm.selectedServiceRoutines.splice(index, 1);
                }
            });
        }
    }
})();
