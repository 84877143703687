(function (){
    'use strict';

    angular
        .module('elogbooks.admin.pcd-imports')
        .config(registerRoutes);

    function registerRoutes($stateProvider) {
        $stateProvider
            .state('dashboard.admin.pcd-imports', {
                url: '/pcd-imports',
                abstract: true,
                templateUrl: 'shared-templates/view.html',
                ncyBreadcrumb: {
                    label: '{{ ::"PCD_IMPORTS" | translate }}'
                },
                data: {
                    permissions: ['user_permission_manage_imports']
                }
            })
            .state('dashboard.admin.pcd-imports.list', {
                url: '/pcd-imports/list?page&limit&order',
                parent: 'dashboard.admin.pcd-imports',
                data: {
                    permissions: ['user_permission_manage_imports']
                },
                resolve: {
                    importsCollectionResponse: function (apiClient, rootResourceResponse, $stateParams) {
                        var params = Object.assign({}, $stateParams, { type: 'pcd' });
                        return apiClient.get(rootResourceResponse.getLink('imports'), params);
                    }
                },
                params: {
                    page: '1',
                    limit: '30'
                },
                views: {
                    '@dashboard': {
                        templateUrl: '/modules/common/imports/list/list.html',
                        controller : 'PCDImportListController',
                        controllerAs: 'vm',
                    }
                },
                ncyBreadcrumb: {
                    skip: true
                }
            })
            .state('dashboard.admin.pcd-imports.list.details', {
                url: '/{importResource}/details?logsPage&logsLimit&logsOrder&logsType&logsMessage&logsCreateOptions&logsUpdateOptions&logsRow',
                parent: 'dashboard.admin.pcd-imports.list',
                data: {
                    permissions: ['user_permission_manage_imports']
                },
                views: parseBook({
                    'elogbooks-right@dashboard': {
                        templateUrl: '/modules/common/imports/details/details.html',
                        controller : 'PcdImportDetailsController',
                        controllerAs: 'vm'
                    }
                }),
                params: {
                    logsPage: '1',
                    logsLimit: '30',
                    logsType: null,
                    logsRow: null,
                    logsMessage: null,
                    logsCreateOptions: {array: true},
                    logsUpdateOptions: {array: true}
                },
                resolve: {
                    importResponse: function (apiClient, $stateParams) {
                        return apiClient.get($stateParams.importResource.decode());
                    },
                    importLogsCollectionResponse: function (apiClient, importResponse, $stateParams) {
                        var params = {
                            page: $stateParams.logsPage,
                            limit: $stateParams.logsLimit,
                            type: $stateParams.logsType,
                            message: $stateParams.logsMessage,
                            'createOptions[]': $stateParams.logsCreateOptions,
                            'updateOptions[]': $stateParams.logsUpdateOptions,
                            row: $stateParams.logsRow
                        };

                        return apiClient.get(importResponse.getLink('logs'), params);
                    }
                },
                ncyBreadcrumb: {
                    label: '{{ ::"IMPORT_DETAILS_LIST" | translate }}'
                }
            })
            .state('dashboard.admin.pcd-imports.list.new-pcd-import', {
                url: '/new-pcd-import',
                parent: 'dashboard.admin.pcd-imports.list',
                data: {
                    permissions: ['user_permission_manage_imports']
                },
                views: {
                    '@dashboard': {
                        templateUrl: '/modules/admin/pcd-imports/add/new-pcd-import.html',
                        controller: 'PCDImportController',
                        controllerAs: 'vm',
                    }
                },
                resolve: {
                },
                ncyBreadcrumb: {
                    skip: true
                }
            });
    }
})();
