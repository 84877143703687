(function () {
    'use strict';

    angular
        .module('elogbooks.admin.regions')
        .controller('RegionsAddEditController', RegionsAddEditController);

    RegionsAddEditController.$inject = ['$state', 'apiClient',  'confirmationModal', 'regionResponse', 'requestDataFactory', 'messenger', 'regionCollectionResponse'];

    function RegionsAddEditController ($state, apiClient, confirmationModal, regionResponse, requestDataFactory, messenger, regionCollectionResponse) {
        var vm = this;
        vm.region = regionResponse;
        vm.data = requestDataFactory.createRequest(getDefaults());
        vm.create = createAction;
        vm.update = updateAction;
        vm.delete = deleteAction;

        function getDefaults() {
            return {
                id: vm.region.id || null,
                name: vm.region.name || null,
                description: vm.region.description || null,
                active: vm.region.active === true || vm.region.active === false ? vm.region.active : null
            };
        }

        function createAction () {
            apiClient.create(regionCollectionResponse.getLink('create'), vm.data).then(function (response) {
                if (response) {
                    $state.go('dashboard.admin.regions.list.details.info', { resource: response.getLink('self').encode() }, { reload: $state.current.parent }).then(function () {
                        messenger.success('REGION_CREATED');
                    });
                } else {
                    messenger.error('REQUEST_ERROR');
                }
            });
        }

        function updateAction () {
            apiClient.update(vm.region.getLink('edit'), vm.data).then(function (response) {
                if (response) {
                    $state.go('^', {}, { reload: 'dashboard.admin.regions.list' }).then(function () {
                        messenger.success('REGION_UPDATED');
                    });
                } else {
                    messenger.error('REQUEST_ERROR');
                }
            });
        }

        function deleteAction () {
            return confirmationModal.open().result.then(function () {
                return apiClient.delete(vm.region.getLink('delete')).then(function (response) {
                    if (response) {
                        $state.go('dashboard.admin.regions.list', {}, { reload: 'dashboard.admin.regions.list' }).then(function() {
                            messenger.success('REGION_DELETED');
                        });
                    } else {
                        messenger.error('REQUEST_ERROR');
                    }
                });
            });
        }
    }

})();
