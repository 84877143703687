(function () {
    'use strict';

    angular
        .module('elogbooks.admin.missed-patrol-reasons')
        .config(registerRoutes)
        .config(registerRoutesAdd)
        .config(registerRoutesEdit);

    function registerRoutes ($stateProvider) {
        $stateProvider
            .state('dashboard.admin.missed-patrol-reasons', {
                url: '/reasons?date&id&name&order',
                resolve: {
                    reasonCollectionResponse: function ($stateParams, apiClient, rootResourceResponse) {
                        if (rootResourceResponse.getLink('reasons')) {
                            return apiClient.get(rootResourceResponse.getLink('reasons'), $stateParams).then(function (response) {
                                return response || apiClient.noResourceFound();
                            });
                        }
                    }
                },
                views: {
                    '@dashboard.admin': {
                        templateUrl: '/modules/admin/missed-patrol-reasons/reasons-list.html',
                        controller: 'MissedPatrolReasonController',
                        controllerAs: 'vm'
                    }
                },
                params: {
                    'id':null,
                    'name':null,
                    'order':'id'
                },
                ncyBreadcrumb: {
                    label: '{{ ::"MISSED_PATROL_REASONS" | translate }}'
                },
                data: {
                    permissions: ['user_permission_manage_missed_patrol_reasons']
                }
            });
    }

    function registerRoutesAdd ($stateProvider) {
        $stateProvider
            .state('dashboard.admin.missed-patrol-reasons.add', {
                url: '/add',
                parent: 'dashboard.admin.missed-patrol-reasons',
                views: {
                    '@dashboard.admin': {
                        templateUrl: '/modules/admin/missed-patrol-reasons/add-edit/reason-form.html',
                        controller: 'ReasonAddEditController',
                        controllerAs: 'vm'
                    }
                },
                resolve: {
                    reasonResponse: function(rootResourceResponse) {
                        return rootResourceResponse;
                    }
                },
                ncyBreadcrumb: {
                    label: '{{ ::"ADD" | translate }}'
                }
            });
    }

    function registerRoutesEdit ($stateProvider) {
        $stateProvider
            .state('dashboard.admin.missed-patrol-reasons.edit', {
                url: '/edit/{resource}',
                parent: 'dashboard.admin.missed-patrol-reasons',
                views: {
                    '@dashboard.admin': {
                        templateUrl: '/modules/admin/missed-patrol-reasons/add-edit/reason-form.html',
                        controller: 'ReasonAddEditController',
                        controllerAs: 'vm'
                    }
                },
                resolve: {
                    reasonResponse: function(apiClient, $stateParams) {
                        return apiClient.get($stateParams.resource.decode()).then(function(response) {
                            return response;
                        });
                    }
                },
                ncyBreadcrumb: {
                    label: '{{ ::"EDIT" | translate }}'
                }
            });
    }
})();
