(function() {
    'use strict'
    angular
        .module('elogbooks.user.sites.quote-requests')
        .config(registerRoutes);

    function registerRoutes($stateProvider) {
        elbQuoteRequestRouting().addRoutes($stateProvider, 'dashboard.user.sites.list.details.quote-requests', false);
    }
})();
