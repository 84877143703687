angular
    .module('elogbooksDirectives')
    .directive('elbPagination', pagination);

pagination.$inject = ['$rootScope', '$state', '$stateParams', 'lodash'];


function pagination($rootScope, $state, $stateParams, lodash) {
    return {
        restrict: 'AE',
        templateUrl: '/modules/directives/pagination/pagination.html',
        link: link
    };

    function link ($scope, $element, $attrs) {
        var override = {};
        override.changed = false;
        var pageName = $attrs.pagePrefix ? $attrs.pagePrefix + 'Page' : 'page';
        var limitName = $attrs.pagePrefix ? $attrs.pagePrefix + 'Limit' : 'limit';
        var pageChanged = $attrs.pageChanged;
        var reload = $attrs.reload || false;

        var itemCount = $attrs.itemCount || $scope.$parent.vm.entityCount || 0;
        var itemsPerPage = $stateParams[limitName] || 30;
        var currentPageTo = ($stateParams[pageName] || 1) * itemsPerPage;
        var reloadState = $attrs.reloadState === 'false' ? false : true;

        if (currentPageTo > itemCount) {
            currentPageTo = itemCount;
        }

        $scope.pagination = {
            pageName: pageName,
            limitName: limitName,
            itemCount: itemCount,
            currentPage: ($stateParams[pageName] || 1),
            itemsPerPage: itemsPerPage,
            currentPageFrom: (($stateParams[pageName] || 1) * itemsPerPage) - itemsPerPage + 1,
            currentPageTo: currentPageTo,
            reloadState: reloadState
        };

        $scope.pageChangedAction = function() {
            override = {};
            if (pageChanged === 'true') {
                override.changed = true;
            } else {
                override.changed = null;
            }
            override[$scope.pagination.pageName] = $scope.pagination.currentPage;

            $stateParams[$scope.pagination.pageName] = $scope.pagination.currentPage;

            var state = $state.current;
            if ($rootScope.bookerize.enabled || reload === 'true') {
                state = true;
            }

            if (!$scope.pagination.reloadState) {
                state = false;
            }

            $state.go('.', angular.extend({}, $stateParams, override), { reload: state });
        };
    }
};
