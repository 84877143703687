(function() {
    'use strict';

    angular
        .module('elogbooks.common.meter-meters')
        .controller('CommonMeterAddEditController', CommonMeterAddEditController);

    CommonMeterAddEditController.$inject = ['confirmationModal', '$state', 'messenger', 'apiClient', 'selectedSiteResponse', 'requestDataFactory', 'siteCollectionResponse', 'locationCollectionResponse', 'locationResponse', 'meterResponse', 'meterTypeCollectionResponse', 'meterManufacturerCollectionResponse', 'meterSupplierCollectionResponse', 'meterSupplyTypeCollectionResponse', 'meterOccupancyTypeCollectionResponse', 'serviceProviderCollectionResponse', 'meterUnitCollectionResponse', 'user', 'parentResponse', 'siteResponse', '$translate'];

    function CommonMeterAddEditController(
        confirmationModal,
        $state,
        messenger,
        apiClient,
        selectedSiteResponse,
        requestDataFactory,
        siteCollectionResponse,
        locationCollectionResponse,
        locationResponse,
        meterResponse,
        meterTypeCollectionResponse,
        meterManufacturerCollectionResponse,
        meterSupplierCollectionResponse,
        meterSupplyTypeCollectionResponse,
        meterOccupancyTypeCollectionResponse,
        serviceProviderCollectionResponse,
        meterUnitCollectionResponse,
        user,
        parentResponse,
        siteResponse,
        $translate
        ) {
        var vm = this;

        vm.data = requestDataFactory.createRequest(meterResponse);
        vm.meterResponse = meterResponse;
        vm.parent = parentResponse;
        vm.siteCollectionResponse = siteCollectionResponse;
        vm.location = locationResponse;
        vm.locations = [];
        vm.meterManufacturerCollectionResponse = meterManufacturerCollectionResponse;
        vm.meterSupplierCollectionResponse = meterSupplierCollectionResponse;
        vm.meterSupplyTypeCollectionResponse = meterSupplyTypeCollectionResponse;
        vm.meterOccupancyTypeCollectionResponse = meterOccupancyTypeCollectionResponse;
        vm.serviceProviderCollectionResponse = serviceProviderCollectionResponse;
        vm.meterUnitCollectionResponse = meterUnitCollectionResponse;
        vm.data.site = vm.data.id ? vm.data._links.site.id : 0;
        vm.data.isDualRated = vm.data.isDualRated ? "1" : "0";
        vm.data.isAutomaticallyRead = vm.data.isAutomaticallyRead ? "1" : "0";
        vm.data.fileRequiredOnReadings = vm.data.fileRequiredOnReadings ? "1" : "0";
        vm.data.tur = vm.data.tur ? "1" : "0";
        vm.disabled = '';

        vm.status = [
            {
                value: true,
                label: $translate.instant('STATUS_ACTIVE')
            },
            {
                value: false,
                label: $translate.instant('STATUS_INACTIVE')
            },
        ];


        if (vm.data.frequency) {
            vm.data.frequency = vm.data.frequency.toLowerCase();
        }

        // specify frequency options, (no daily option for meters)
        vm.frequencyOptions =   {
            weekly: {value: "weekly", title: $translate.instant("WEEKLY")},
            monthly: {value: "monthly", title: $translate.instant("MONTHLY")},
            yearly: {value: "yearly", title: $translate.instant("YEARLY")},
        };

        vm.partnershipSelect = {
            responseKeyPath: 'partnerships',
            linkParameters: {active:1},
            itemHrefPath: '_links.serviceprovider.href',
            itemValuePath: '_links.serviceprovider.title',
        };

        vm.siteSelect = {
            response: siteCollectionResponse,
            link: siteCollectionResponse.getLink('self'),
            responseKeyPath: 'sites',
            onSelect: changeSite,
            onRemove: removeSite,
            required: true
        };

        vm.typeSelect = {
            response: meterTypeCollectionResponse,
            link: meterTypeCollectionResponse.getLink('self'),
            responseKeyPath: 'meterTypes',
            itemHrefPath: '_links.metertype.href',
            itemValuePath: 'name',
            selected: vm.data._links.metertype ? {
                href: vm.data._links.metertype.href,
                title: vm.data._links.metertype.title,
                object: vm.data._links.metertype
            } : null,
            onSelect: changeMeterType,
            required: true
        };

        vm.manufacturerSelect = {
            response: meterManufacturerCollectionResponse,
            link: meterManufacturerCollectionResponse.getLink('self'),
            responseKeyPath: 'meterManufacturers',
            itemHrefPath: '_links.metermanufacturer.href',
            itemValuePath: 'name',
            selected: vm.data._links.metermanufacturer ? {
                href: vm.data._links.metermanufacturer.href,
                title: vm.data._links.metermanufacturer.title,
                object: vm.data._links.metermanufacturer
            } : null,
            onSelect: changeManufacturer
        };

        vm.supplierSelect = {
            response: meterSupplierCollectionResponse,
            link: meterSupplierCollectionResponse.getLink('self'),
            responseKeyPath: 'meterSuppliers',
            itemHrefPath: '_links.metersupplier.href',
            itemValuePath: 'name',
            selected: vm.data._links.metersupplier ? {
                href: vm.data._links.metersupplier.href,
                title: vm.data._links.metersupplier.title,
                object: vm.data._links.metersupplier
            } : null,
            onSelect: changeSupplier,
            required: true
        };

        vm.supplySelect = {
            response: meterSupplyTypeCollectionResponse,
            link: meterSupplyTypeCollectionResponse.getLink('self'),
            responseKeyPath: 'meterTypes',
            itemHrefPath: '_links.metersupplytype.href',
            itemValuePath: 'name',
            selected: vm.data._links.metersupplytype ? {
                href: vm.data._links.metersupplytype.href,
                title: vm.data._links.metersupplytype.title,
                object: vm.data._links.metersupplytype
            } : null,
            onSelect: changeSupply,
            required: true
        };

        vm.unitSelect = {
            response: meterUnitCollectionResponse,
            link: meterUnitCollectionResponse.getLink('self'),
            responseKeyPath: 'meterUnits',
            itemHrefPath: '_links.meterunits.href',
            itemValuePath: 'symbol',
            selected: vm.data._links.meterunit ? {
                href: vm.data._links.meterunit.href,
                title: vm.data._links.meterunit.title,
                object: vm.data._links.meterunit
            } : null,
            onSelect: changeUnit,
            onRemove: removeUnit
        };

        vm.occupancySelect = {
            response: meterOccupancyTypeCollectionResponse,
            link: meterOccupancyTypeCollectionResponse.getLink('self'),
            responseKeyPath: 'meterTypes',
            itemHrefPath: '_links.meteroccupancytype.href',
            itemValuePath: 'name',
            selected: vm.data._links.meteroccupancytype ? {
                href: vm.data._links.meteroccupancytype.href,
                title: vm.data._links.meteroccupancytype.title,
                object: vm.data._links.meteroccupancytype
            } : null,
            onSelect: changeOccupancy
        };

        vm.changeSite = changeSite;
        vm.create = createAction;
        vm.update = updateAction;
        vm.delete = deleteAction;

        if (parentResponse) {
            /**
             * When creating a submeter load site form parent
             * and reset the meter because it will contain parent state resource
             */
            vm.data = requestDataFactory.createRequest(meterResponse);

            if (vm.data._links === undefined) {
                vm.data._links = {};
            }

            vm.data._links.meter = parentResponse._links.self;

            if (typeof parentResponse._links.site !== 'undefined') {
                vm.isSiteDefined = true;

                vm.siteSelect.selected = parentResponse._links.site;
                vm.changeSite();
            }
        } else {
            if (vm.data._links && typeof vm.data._links.site !== 'undefined') {
                vm.siteSelect.selected = vm.data._links.site;
                vm.changeSite();
            }

            if (siteResponse && typeof siteResponse._links !== 'undefined') {
                vm.isSiteDefined = true;

                /** Force site to be loaded */
                vm.data.site = siteResponse;
                vm.siteSelect.selected = siteResponse._links.self;
                vm.changeSite();
            }

            if (selectedSiteResponse !== null && (Object.keys(siteResponse).length === 0 && siteResponse.constructor === Object)) {

                vm.siteSelect.selected = {
                    object: selectedSiteResponse,
                    href: selectedSiteResponse.getLink('self'),
                    value: selectedSiteResponse.name
                };
                vm.siteSelect.disabled = true;
                vm.siteSelect.populateDisabled = true;
                vm.changeSite();
            }
        }

        /**
         * Clear all form fields that are site related.
         */
        function removeSite(){
            vm.partnershipSelect.clear();
            vm.partnershipSelect.disabled = true;
        }

        function removeUnit(){
            vm.data._links.meterunit = null;
        }

        function changeSite() {
            if (typeof vm.siteSelect.selected !== 'undefined') {

                vm.partnershipSelect.disabled = false;

                apiClient.get(vm.siteSelect.selected.href).then(function (response) {
                    vm.data.site = response;
                    vm.partnershipSelect.link = response._links.partnerships.href;
                    apiClient.get(vm.data.site.getLink('locations')).then(function (response) {
                        vm.locations = response;
                    });
                });
            }

            if (vm.data.id && typeof vm.data._links.serviceprovider !== 'undefined') {
                vm.partnershipSelect.selected = vm.data._links.serviceprovider;
            }

            if (vm.data.site && typeof vm.data.site._links !== 'undefined') {
                /** Load service providers who belong to a site */
                if (typeof vm.data.site._links.partnerships !== 'undefined') {
                    vm.partnershipSelect.disabled = false;
                    vm.partnershipSelect.link = vm.data.site._links.partnerships.href;
                }
            }
        }

        function changeMeterType() {
            vm.data._links['metertype'] = vm.typeSelect.selected.object.getLink('self');
        }

        function changeManufacturer() {
            vm.data._links['metermanufacturer'] = vm.manufacturerSelect.selected.object.getLink('self');
        }

        function changeSupplier() {
            vm.data._links['metersupplier'] = vm.supplierSelect.selected.object.getLink('self');
        }

        function changeSupply() {
            vm.data._links['metersupplytype'] = vm.supplySelect.selected.object.getLink('self');
        }

        function changeUnit() {
            vm.data._links['meterunit'] = vm.unitSelect.selected.object.getLink('self');
        }

        function changeOccupancy() {
            vm.data._links['meteroccupancytype'] = vm.occupancySelect.selected.object.getLink('self');
        }


        function updateAction() {
            delete vm.data._links['create-reading'];
            delete vm.data._links.site;
            delete vm.data._links.meterreading;
            delete vm.data._links.children;
            delete vm.data._links.images;
            delete vm.data._links['export-readings'];
            delete vm.data._links.parent;

            if (typeof vm.partnershipSelect.selected !== 'undefined') {
                vm.data._links.serviceprovider = vm.partnershipSelect.selected;
            }

            apiClient.update(vm.data.getLink('self'), vm.data).then(function (response) {
                if (response) {
                    $state.go('^', {}, {reload: $state.get('^.^.^.^')}).then(function () {
                        messenger.success('Meter successfully updated');
                    });
                } else {
                    messenger.error('There was an error with your request, please try again later');
                }
            });
        }

        function createAction () {
            var createLink = null;

            if (siteResponse && typeof siteResponse._links !== 'undefined') {
                createLink = siteResponse.getLink('meters');
            } else {
                createLink = vm.data.site.getLink('meters');
            }

            if (typeof vm.partnershipSelect.selected !== 'undefined') {
                vm.data._links.serviceprovider = vm.partnershipSelect.selected
            }

            apiClient.create(createLink, vm.data).then(function (response) {
                if (response) {
                    if (parentResponse) {
                        $state.go('^', {resource : parentResponse.getLink('self').encode()}, {reload: $state.get('^.^.^')}).then(function () {
                            messenger.success('Submeter successfully added');
                        });
                    } else {
                        $state.go('^', {}, {reload:  $state.get('^')}).then(function () {
                            messenger.success('Meter successfully added');
                        });
                    }
                } else {
                    messenger.error('There was an error with your request, please try again later');
                }
            });
        }

        function deleteAction() {
            return confirmationModal.open().result.then(function(response) {
                if (response.result) {
                    apiClient.delete(vm.meterResponse.getLink('delete')).then(function(isSuccess) {
                        if (isSuccess) {
                            $state.go('^.^.^', {}, {reload: $state.get('^.^.^')}).then(function(){
                                messenger.success('METER_DELETED');
                            });
                        } else {
                            messenger.error('REQUEST_ERROR');
                        }
                    });
                }
            });
        }
    }
})();

