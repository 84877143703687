(function() {
    'use strict';

    angular
        .module('elogbooks.user.meter-supplies')
        .config(registerRoutes)
        .config(registerRoutesList)
        .config(registerRoutesAdd)
        .config(registerRoutesDetails)
        .config(registerRoutesEdit);

    function registerRoutes($stateProvider) {
        $stateProvider
            .state('dashboard.user.meters.supplies', {
                abstract: true,
                template: '<bookerize></bookerize>',
                ncyBreadcrumb: {
                    skip: true
                }
            });
    }

    function registerRoutesList($stateProvider) {
        $stateProvider
            .state('dashboard.user.meters.supplies.list', {
                url: '/meter-supplies?page&limit&order&id&name',
                parent: 'dashboard.user.meters.supplies',
                views: parseBook({
                    'elogbooks-left@dashboard.user.meters.supplies': {
                        templateUrl: '/modules/user/meters/meter-supplies/meter-supplies-list.html',
                        controller: 'MeterTypesController',
                        controllerAs: 'vm'
                    }
                }),
                params: {
                    page: '1',
                    limit: '30',
                    order: 'id',
                    id: null,
                    name: null
                },
                resolve: {
                    meterTypesCollectionResponse: function($stateParams, apiClient, rootResourceResponse) {
                        $stateParams.type = 'supply_type';

                        return apiClient.get(rootResourceResponse.getLink('metertypes'), $stateParams).then( function(response) {
                            return response || apiClient.noResourceFound();
                        });
                    }
                },
                ncyBreadcrumb: {
                    label: '{{ ::"METER_SUPPLIES_LIST" | translate }}'
                }
            });
    }

    function registerRoutesAdd($stateProvider) {
        $stateProvider
            .state('dashboard.user.meters.supplies.list.add', {
                url: '/add',
                parent: 'dashboard.user.meters.supplies.list',
                views: parseBook({
                    'elogbooks-right@dashboard.user.meters.supplies': {
                        templateUrl: '/modules/user/meters/meter-supplies/add-edit/meter-supplies-add-edit.html',
                        controller: 'MeterTypesAddEditController',
                        controllerAs: 'vm'
                    }
                }),
                resolve: {
                    meterTypeResponse: function() {
                        return {
                            type : 'supply'
                        };
                    }
                },
                ncyBreadcrumb: {
                    label: '{{ ::"ADD" | translate }}'
                }
            });
    }

    function registerRoutesDetails($stateProvider) {
        $stateProvider
            .state('dashboard.user.meters.supplies.list.details', {
                url: '/details/{resource}',
                abstract: true,
                parent: 'dashboard.user.meters.supplies.list',
                views: parseBook({
                    'elogbooks-right@dashboard.user.meters.supplies': {
                        templateUrl: '/modules/user/meters/meter-supplies/details/meter-supplies-details.html',
                        controller: 'MeterTypesDetailsController',
                        controllerAs: 'vm'
                    }
                }),
                resolve: {
                    meterTypeResponse: function(apiClient, $stateParams) {
                        return apiClient.get($stateParams.resource.decode()).then( function(response) {
                            return response || apiClient.noResourceFound();
                        });
                    }
                },
                ncyBreadcrumb: {
                    label: '{{ ::$resolves().meterTypeResponse.name }}'
                }
            })
             .state('dashboard.user.meters.supplies.list.details.info', {
                url: '/info',
                parent: 'dashboard.user.meters.supplies.list.details',
                views: parseBook({
                    'elogbooks-right@dashboard.user.meters.supplies.list.details': {
                        templateUrl: '/modules/user/meters/meter-supplies/details/info/meter-supplies-info.html',
                        controller: 'MeterTypesInfoController',
                        controllerAs: 'vm'
                    }
                }),
                ncyBreadcrumb: {
                    label: '{{ ::"INFO" | translate }}'
                }
            });
    }

    function registerRoutesEdit($stateProvider) {
        $stateProvider
            .state('dashboard.user.meters.supplies.list.details.info.edit', {
                url: '/edit',
                parent: 'dashboard.user.meters.supplies.list.details.info',
                views: parseBook({
                    'elogbooks-right@dashboard.user.meters.supplies': {
                        templateUrl: '/modules/user/meters/meter-supplies/add-edit/meter-supplies-add-edit.html',
                        controller: 'MeterTypesAddEditController',
                        controllerAs: 'vm'
                    }
                }),
                ncyBreadcrumb: {
                    label: '{{ ::"EDIT" | translate }}'
                }
            });
    }

})();
