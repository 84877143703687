(function() {
    'use strict';

    angular
        .module('elogbooks')
        .config(Translations);

    function Translations($translateProvider) {
        $translateProvider.translations('en-gb', {
            DOCUMENT_TYPES_MANAGEMENT: 'Document Types Management',
            DOCUMENT_TYPE: 'Document Type',
            DOCUMENT_TYPES: 'Document Types',
            DOCUMENT_TYPE_LIST: 'Document Types',
            DOCUMENT_TYPE_INFORMATION: 'Document Type Information',

            DOCUMENT_TYPE_ADD: 'Add a new Document Type',
            DOCUMENT_TYPE_EDIT: 'Edit Document Type',

            DOCUMENT_TYPE_CREATED: 'Document Type created',
            DOCUMENT_TYPE_UPDATED: 'Document Type updated',
            DOCUMENT_TYPE_DELETED: 'Document Type deleted',

            CRITICALITY: 'Criticality',
            CRITICALITY_HIGH: 'High',
            CRITICALITY_MEDIUM: 'Medium',
            CRITICALITY_LOW: 'Low',

            RECURRENCE: 'Recurrence',

            DOCUMENT_SETS: 'Document Sets',
            DOCUMENT_SET: 'Document Set',
            DOCUMENT_SET_LIST: 'Document Sets',
            DOCUMENT_SET_INFORMATION: 'Document Set Information',

            DOCUMENT_SET_ADD: 'Add a new Document Set',
            DOCUMENT_SET_EDIT: 'Edit Document Set',
            DOCUMENT_SET_ADD_TYPE: 'Add Document Type',
            DOCUMENT_SET_ADD_SITES: 'Add Sites',

            DOCUMENT_SET_CREATED: 'Document Set created',
            DOCUMENT_SET_UPDATED: 'Document Set updated',
            DOCUMENT_SET_DELETED: 'Document Set deleted',

            SELECT_ALL_DOCUMENT_TYPES: 'Select All Document Types',
            IS_MANDATORY: 'Is Mandatory',

            SELECT_AT_LEAST_ONE_DOCUMENT_TYPE: 'Select At Least One Document Type',
            DOCUMENT_SET_MUST_HAVE_ATLEAST_ONE_DOCUMENT_TYPE: 'Document Set Must Have Atleast One Document Type',
            DUPLICATE_DOCUMENT_TYPES: 'Duplicate Document Types',
            DUPLICATE_DOCUMENT_TYPES_EXIST: 'Duplicate Document Types Exist',

            MANAGE_DOCUMENT_SETS: 'Manage Document Sets',
            ADD_DOCUMENT_SET: 'Add Document Set',
            DOCUMENT_SET_ADDED: 'Document Set Added',
            DOCUMENT_SET_REMOVED: 'Document Set Removed',

            DOCUMENT_COMPLIANCE_ADD_TYPE: 'Add Document Type',
            DOCUMENT_SET_SITES_ADD: 'Add Sites',
            DOCUMENT_SET_SITE_ADDED: 'Sites Added',
            SELECT_AT_LEAST_ONE_SITE: 'Select At Least One Site',
            REQUIRED_DOCUMENT_TYPE_CANNOT_BE_DEACTIVATED: 'This Document Type is currently required in order to raise a job and cannot be deactivated. Please amend this requirement in Job Advanced Settings',
            REQUIRED_DOCUMENT_TYPE_CANNOT_BE_DELETED: 'This Document Type is currently required in order to raise a job and cannot be deleted. Please amend this requirement in Job Advanced Settings'

        });
    }
})();