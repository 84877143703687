app.config(function ($translateProvider) {
    $translateProvider.translations('en-gb', {
        JOB_MATERIALS: 'Materials',
        JOB_MATERIALS_LIST: 'Materials',
        JOB_MATERIALS_ADD: 'Add new Materials',
        JOB_MATERIALS_CREATED: 'Job Materials created',

        JOB_COSTING: 'Job Costing',
        JOB_COSTING_LIST: 'Job Costing',

        JOB_PURCHASE_ORDER:'PO',

        SELECT_SUPPLIER_ALLOW_RAISE_PURCHASE_ORDER: 'Please select supplier to allow raising purchase order',

        NO_MATCHING_INVOICE: 'No matching invoice'
    });
});