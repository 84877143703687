app.config(function ($translateProvider) {
    $translateProvider.translations('nl', {
        DUTYMANAGEMENT: 'Taakbeheer',
        DUTY_MANAGEMENT: 'Taakbeheer',
        DUTY_MANAGEMENT_SITE_RELATION_REMOVED: 'Gebouw connectie verwijderd',
        DUTY_MANAGEMENT_SCHEDULE_REMOVED: 'Schema is verwijderd',
        DUTY_MANAGEMENT_BACK: 'Terug',
        DUTY_MANAGEMENT_EDIT_USER: 'Pas gebruiker aan',
        DUTY_MANAGEMENT_SELECT_USER: 'Selecteer gebruiker',
        DUTY_MANAGEMENT_SELECT_SITES: 'Selecteer gebouw',
        DUTY_MANAGEMENT_SELECT_DAYS: 'Selecteer dag',
        DUTY_MANAGEMENT_SELECTED_DAYS: 'Geselecteerde dagen',
        DUTY_MANAGEMENT_SELECTED_SITES: 'Geselecteerde gebouwen',
        DUTY_MANAGEMENT_ADD: 'Maak Taakbeheer schema',
        DUTY_MANAGEMENT_ALREADY_IN_USE_SITES: 'Gebouw is al gebruikt'
    })
});
