/**
 *  Display a field from the first address in contactinformation
 *  - default is line1 - [0].lines[0]
 * @example <contact-information-field data-ng-model="vm.contactInformation" data-field="postcode"></contact-information-field>
 */
angular.module('elogbooksDirectives').directive('contactInformationField', [function () {
    return {
        restrict: 'AE',
        require: ['ngModel'],
        scope: {
            contactinformation: '=ngModel',
            field: '='
        },
        templateUrl: '/modules/directives/contact-information/contact-information-field.html',
        controller: function ($scope) {
            var addresses = $scope.contactinformation ? $scope.contactinformation.addresses : [];
            var fields = $scope.contactinformation ? $scope.contactinformation.fields : [];

            $scope.category = 'default';

            var address = angular.extend({
                    lines: [],
                    postcode: '',
                    region: '',
                    town: ''
                }, addresses[0]);

            switch ($scope.field) {
                case 'postcode':
                case 'region':
                case 'town':
                    $scope.fieldValue = address[$scope.field];
                    break;
                case 'website':
                case 'email':
                    $scope.category = $scope.field;
                    $scope.fieldValue = getFirstField($scope.field);
                    break;
                case 'telephone':
                    $scope.category='telephone';
                    $scope.fieldValue = getFirstField($scope.field);
                    break;
                default:
                    $scope.fieldValue = address.lines[0];
            }

            function getFirstField(field) {
                for (var index = 0; index < fields.length; index++) {
                    var element = fields[index];
                    if (element.type === field) {
                        return element.value;
                    }
                }
            }
        }
    };
}]);
