/**
 * Edit Survey Request Controller
 */
(function () {
    'use strict';

    angular
        .module('elogbooks.common.survey')
        .controller('SurveyRequestEditController', SurveyRequestEditController);

    SurveyRequestEditController.$inject = [
        '$scope',
        '$state',
        'apiClient',
        'messenger',
        'userManager',
        'surveyRequestService',
        'surveyService',
        'surveyRequestResponse',
        'surveyRequestLinesResponse',
        'confirmationModal',
        '$rootScope',
        'lodash',
        'elbSettings'
    ];

    function SurveyRequestEditController(
        $scope,
        $state,
        apiClient,
        messenger,
        userManager,
        surveyRequestService,
        surveyService,
        surveyRequestResponse,
        surveyRequestLinesResponse,
        confirmationModal,
        $rootScope,
        lodash,
        elbSettings
    ) {
        var vm = this;

        vm.accept = accept;
        vm.save = save;
        vm.submit = submit;
        vm.delete = deleteAction;
        vm.changeActiveState = changeActiveState;
        vm.updateStats = updateStatsAction;
        vm.cancel = cancel;
        vm.agreeSpScore = agreeSpScore;
        vm.agreeAllSpScore = agreeAllSpScore;
        vm.toggleAgreeCheckboxes = toggleAgreeCheckboxes;

        vm.surveyRequestLines = [];
        vm.surveyRequestStats = [];
        vm.agreeAllSpScores = false;
        vm.showAgreeCheckBoxes = false;

        vm.surveyRequest = surveyRequestResponse.getData();

        for (var line in surveyRequestLinesResponse.getData().lines) {
            if (!surveyRequestLinesResponse.getData().lines.hasOwnProperty(line)) {
                continue;
            }
            var item = surveyRequestLinesResponse.getData().lines[line];

            if (item._embedded.surveyline) {
                var scoreRange = item._embedded.surveyline.scoreRange;

                item.scoreLabel = surveyService.labelForScore(item.score, scoreRange);
                item.spScoreLabel = surveyService.labelForScore(item.spScore, scoreRange);
                item.options = surveyService.optionsForScoreRange(scoreRange);
                item.orderBy = parseInt(item._embedded.surveyline.displayOrder);

                vm.surveyRequestLines.push(item);
            }

            if (item._embedded.surveystat) {
                vm.surveyRequestStats.push(item);
            }
        }

        vm.surveyRequestLines = lodash.orderBy(vm.surveyRequestLines, ['orderBy', 'asc'])
        vm.site = vm.surveyRequest._links.site;
        vm.isFM = userManager.hasPermission('site_permission_edit_surveys');
        vm.isSP = !vm.isFM;

        vm.canSubmit;
        vm.canAccept;
        vm.currentTotal = 0;
        vm.currentSPTotal = 0;
        vm.currentFMTotal = 0;
        vm.maxTotal = 0;
        vm.maxFMTotal = 0;
        vm.maxSPTotal = 0;
        vm.spAutoSubmitKpi = elbSettings.getSetting('sp_auto_submit_kpi').value;
        vm.autoSubmitDate = false;

        if (vm.spAutoSubmitKpi && !vm.isFM && vm.surveyRequest.status === 'active') {
            var todayDate = new Date();
            var kpiDate = new Date(vm.surveyRequest.reportingYear, (vm.surveyRequest.reportingMonth - 1), elbSettings.getSetting('sp_submit_kpi_by_date').value);
            kpiDate.setMonth(kpiDate.getMonth() + 1);

            if (todayDate < kpiDate) {
                vm.autoSubmitDate = kpiDate.toLocaleDateString('en-GB');
            }
        }

        $scope.$watch('vm.surveyRequestLines', function () {
            vm.canSubmit = vm.surveyRequest._links['submit'] && surveyRequestService.canSubmit(vm.surveyRequestLines);
            vm.canAccept = vm.surveyRequest._links['accept'] && surveyRequestService.canAccept(vm.surveyRequestLines, vm.isFM);
            var totals = surveyRequestService.totals(vm.surveyRequestLines);
            vm.currentTotal = vm.isFM ? totals.fm : totals.sp;
            vm.currentSPTotal = totals.sp;
            vm.currentFMTotal = totals.fm;
            vm.currentCalculatedTotal = totals.calc;
            vm.maxTotal = totals.max;
            vm.maxFMTotal = totals.maxFM;
            vm.maxSPTotal = totals.maxSP;
        }, true);

        angular.forEach(vm.surveyRequestLines, function(surveyRequestLine) {
            if (surveyRequestLine.isSpExcluded || surveyRequestLine.spScore) {
                vm.showAgreeCheckBoxes = true;
                return;
            }
        });

        /**
         * FM or SP save a SurveyRequest
         */
        function save() {
            var data = vm.surveyRequestLines.concat(vm.surveyRequestStats);

            surveyRequestService.update(vm.surveyRequest, data)
                .then(function (successMessage) { // Survey Request successfully updated
                    $state.go('^', {}, { reload: $state.get('^.^') }).then(function () {
                        messenger.success(successMessage);
                    });
                }, function (errorMessage) { // Survey Request failed to update
                    messenger.error(errorMessage);
                });
        }

        /**
         * SP Operative submits their scores, update SurveyRequest statusto "Pending"
         */
        function submit() {
            // Trap attempts to submit when completed
            if (vm.surveyRequest.status === 'completed') {
                messenger.error('SURVEY_ALREADY_SUBMITTED.');
                return;
            }

            // Must have a score for all the questions
            if (surveyRequestService.canSubmit(vm.surveyRequestLines)) {
                var data = vm.surveyRequestLines.concat(vm.surveyRequestStats);
                // Update the survey request
                surveyRequestService.update(vm.surveyRequest, data)
                    .then(function () {
                        // Submit this survey
                       surveyRequestService.submit(vm.surveyRequest)
                            .then(function () { // Survey Request successfully updated
                                $rootScope.skipDirtyCheck = true;
                                $state.go('^', {}, { reload: $state.get('^.^') }).then(function () {
                                    $rootScope.skipDirtyCheck = false;
                                    messenger.success('SURVEY_SUBMITTED');
                                });
                            });
                    });
            } else {
                messenger.error('SURVEY_NOT_ALL_SP_SCORES_SET');
            }
        }

        /**
         * Accept a SurveyRequest to "completed"
         */
        function accept() {
            // Must have a score for all the questions
            if (surveyRequestService.canAccept(vm.surveyRequestLines, vm.isFM)) {
                var data = vm.surveyRequestLines.concat(vm.surveyRequestStats);
                // Update the survey request
                surveyRequestService.update(vm.surveyRequest, data)
                    .then(function () {
                        // Accept this survey
                        surveyRequestService.accept(vm.surveyRequest)
                            .then(function () { // Survey Request successfully updated
                                $rootScope.skipDirtyCheck = true;
                                $state.go('^', {}, { reload: $state.get('^.^') }).then(function () {
                                    $rootScope.skipDirtyCheck = false;
                                    messenger.success('SURVEY_ACCEPTED');
                                });
                            }, function (errorMessage) { // Survey Request failed to update
                                messenger.error('REQUEST_ERROR');
                            });
                    });
            } else {
                messenger.error('SURVEY_NOT_ALL_FM_SCORES_SET');
                $scope.surveyRequestForm.$setValidity('outstanding scores', false);
            }
        }

        /**
         * Update Survey Stats
         */
        function updateStatsAction() {
            if (vm.surveyRequest.getLink('update-stats')) {
                apiClient.update(vm.surveyRequest.getLink('update-stats'), {}).then(function (response) {
                    if (response) {
                        messenger.success('SURVEY_STATS_UPDATED');
                        angular.forEach(vm.surveyRequestStats, function(stat) {
                            var updatedStat = lodash.find(response.lines, {id: stat.id});
                            stat.statistic = updatedStat.statistic;
                        });
                    }
                });
            }
        }

        /**
         * Delete a SurveyRequest
         */
        function deleteAction () {

            return confirmationModal.open().result.then(function (response) {
                return apiClient.delete(vm.surveyRequest.getLink('delete')).then(function (response) {
                    if (response) {
                        $state.go('^.^', {}, { reload: $state.get('^.^') }).then(function () {
                            messenger.success('SURVEY_DELETED');
                        });
                    } else {
                        messenger.error('REQUEST_ERROR');
                    }
                });
            });
        }

        function changeActiveState() {
            return confirmationModal.open().result.then(function (response) {
                return apiClient.update(surveyRequestResponse.getLink('activate'), {}).then(function(response) {
                    if (response) {
                        $state.go('.', {}, { reload: true }).then(function () {
                            messenger.success('SURVEY_ACTIVE_STATE_CHANGED');
                        });
                    } else {
                        messenger.success('REQUEST_ERROR');
                    }

                });
            });
        }
        function cancel($event) {
            $event.preventDefault();
            return confirmationModal.open().result.then(function (response) {
                if (response) {
                    $state.go('^', {}, { reload: $state.current.parent });
                }
            });
        }

        function agreeAllSpScore() {
            if (vm.agreeAllSpScores) {
                angular.forEach(vm.surveyRequestLines, function(surveyRequestLine) {
                    surveyRequestLine.agreeWithSpScore = vm.agreeAllSpScores;
                    if (surveyRequestLine.spScore >= 0) {
                        surveyRequestLine.score = surveyRequestLine.spScore;
                        surveyRequestLine.isExcluded = false;
                    } else {
                        surveyRequestLine.isExcluded = surveyRequestLine.isSpExcluded;
                    }
                });
            } else {
                angular.forEach(vm.surveyRequestLines, function(surveyRequestLine) {
                    surveyRequestLine.agreeWithSpScore = vm.agreeAllSpScores;
                    surveyRequestLine.score = null;
                    surveyRequestLine.isExcluded = false;
                });
            }
        };

        function agreeSpScore(surveyRequestLine) {
            if (surveyRequestLine.agreeWithSpScore) {
                surveyRequestLine.isExcluded = surveyRequestLine.isSpExcluded;

                if (surveyRequestLine.spScore >= 0) {
                    surveyRequestLine.score = surveyRequestLine.spScore;
                }

                checkAllAgreeBoxesTicked();
            } else {
                surveyRequestLine.score = null;
                surveyRequestLine.isExcluded = false;
                vm.agreeAllSpScores = false;
                checkAllAgreeBoxesTicked()
            }
        }

        function toggleAgreeCheckboxes() {
            if (!vm.surveyRequest.editableSP && vm.surveyRequest.status === 'pending') {
                angular.forEach(vm.surveyRequestLines, function(surveyRequestLine) {
                    surveyRequestLine.agreeWithSpScore = false;
                });

                vm.agreeAllSpScores = false;
            }
        }

        function checkAllAgreeBoxesTicked() {
            var all = true;
            angular.forEach(vm.surveyRequestLines, function(surveyRequestLine) {
                if (!surveyRequestLine.agreeWithSpScore) {
                    all = false;
                }
            });

            vm.agreeAllSpScores = all;
        }
    }
})();
