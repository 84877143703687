(function () {
    'use strict';

    angular
        .module('elogbooks.user.spillages')
        .controller('SpillageCancelController', SpillageCancelController);

    SpillageCancelController.$inject = ['apiClient', '$state', 'messenger', 'spillageResponse', 'scopeData'];

    function SpillageCancelController (apiClient, $state, messenger, spillageResponse, scopeData) {
        var vm = this;
        vm.spillage = spillageResponse;

        angular.extend(vm, scopeData);

        vm.submit = submitAction;
        vm.data = {
            note: null
        };

        function submitAction () {
            var currentState = $state.current.name;
            var reloadState = currentState.substring(0, currentState.lastIndexOf('.list') + 5);

            return apiClient.create(vm.spillage.getLink(vm.action), vm.data).then(function (response) {
                if (response) {
                    return $state.go('^.^', {}, { reload: reloadState }).then(function () {
                        messenger.success(vm.translation + '_SUCCESS');
                    });
                }

                messenger.error('REQUEST_ERROR');
            });
        }
    }
})();
