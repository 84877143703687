(function() {
    'use strict';

    angular
        .module('elogbooks')
        .config(Translations);

    function Translations($translateProvider) {
        $translateProvider.translations('en-gb', {
            VAT_RATES: 'VAT Rates',
            VAT_RATE: 'VAT Rate',
            VAT_RATES_LIST: 'VAT Rates',

            VAT_RATES_ADD : 'Add a new VAT Rate',
            VAT_RATES_EDIT : 'Edit VAT Rate',
            VAT_RATES_CREATED: 'VAT Rate created',
            VAT_RATES_UPDATED: 'VAT Rate updated',
            VAT_RATES_DELETED: 'VAT Rate deleted',
        });
    }
})();
