(function(){
    'use strict';

    angular
        .module('elogbooks.common.audit')
        .controller('AuditListController', AuditListController);

    AuditListController.$inject= [
        'auditCollectionResponse',
        'serviceProviderCollectionResponse',
        'operativeCollectionResponse',
        '$state',
        '$stateParams',
        'lodash',
        'siteCollectionResponse',
        'user',
        'userManager',
        '$translate',
        'rootResourceResponse'
    ];

    function AuditListController(
        auditCollectionResponse,
        serviceProviderCollectionResponse,
        operativeCollectionResponse,
        $state,
        $stateParams,
        lodash,
        siteCollectionResponse,
        user,
        userManager,
        $translate,
        rootResourceResponse
    ) {
        var vm = this;
        vm.audits = auditCollectionResponse.audits;
        vm.auditCollectionResponse = auditCollectionResponse;
        vm.serviceProviderCollectionResponse = serviceProviderCollectionResponse;
        vm.operativeCollectionResponse = operativeCollectionResponse;
        vm.sites = siteCollectionResponse.sites;
        vm.user = user;
        vm.type = $stateParams.auditType;
        vm.loadedCollections = [auditCollectionResponse];
        vm.resources = {
            'rootResource': rootResourceResponse,
            'selectedSite': siteCollectionResponse,
            'stateParams': $stateParams,
            'root': rootResourceResponse,
            'user': user
        };

        vm.auditTitle = vm.type ? vm.type.toUpperCase() : 'SCHEDULE';
        vm.entityCount = auditCollectionResponse.getData().count;
        vm.search = search;
        vm.order = order;
        var statusOptions = [];
        var defaultValues = [];

        vm.criteria = {
            auditId: { type: 'number', value: $stateParams.auditId ? parseInt($stateParams.auditId, 10) : null, title: 'ID', min: 1 },
            auditSummary:    { type: 'text',    title: 'SUMMARY',     value: $stateParams.auditSummary },
            auditReference:  { type: 'text',    title: 'REFERENCE',   value: $stateParams.auditReference },
        };

        if (vm.type === 'audit') {
            if (serviceProviderCollectionResponse) {
                vm.serviceProviderSelect = {
                    response : serviceProviderCollectionResponse,
                    link : serviceProviderCollectionResponse ? serviceProviderCollectionResponse.getLink('self') : null,
                    itemValuePath: 'id',
                    itemTitlePath: 'name',
                    responseKeyPath: 'serviceproviders'
                };

                vm.criteria.serviceProvider = { type: 'jsonselectwidget', title: 'SERVICE_PROVIDER', value: JSON.parse($stateParams.serviceProvider), options: vm.serviceProviderSelect };
            }

            if (operativeCollectionResponse) {
                vm.operativeSelect = {
                    response : operativeCollectionResponse,
                    link : operativeCollectionResponse ? operativeCollectionResponse.getLink('self') : null,
                    itemValuePath: '_links.user.id',
                    itemTitlePath: '_links.user.name',
                    responseKeyPath: 'operatives'
                };

                vm.criteria.operative = {
                    type: 'jsonselectwidget',
                    title: 'OPERATIVE',
                    value: JSON.parse($stateParams.operative),
                    options: vm.operativeSelect
                };
            }

            statusOptions = [
                {
                    value: 'audit_draft',
                    title: $translate.instant('AUDIT_DRAFT')
                },
                {
                    title: $translate.instant('AUDIT_CREATED'),
                    value: 'audit_created'
                },
                {
                    title: $translate.instant('AUDIT_STARTED'),
                    value: 'audit_started'
                },
                {
                    title: $translate.instant('AUDIT_COMPLETED'),
                    value: 'audit_completed'
                },
                {
                    title: $translate.instant('AUDIT_CANCELLED'),
                    value: 'audit_cancelled'
                }
            ];
            defaultValues = ['audit_draft', 'audit_created', 'audit_started'];

            if ($stateParams.auditStatus === 'all') {
                $stateParams.auditStatus = ['audit_draft', 'audit_created', 'audit_started'];
            }

        } else if (vm.type === 'template') {
            statusOptions = [
                {
                    value: 'all',
                    title: $translate.instant('ALL')
                },
                {
                    title: $translate.instant('AUDIT_TEMPLATE_ACTIVE'),
                    value: 'audit_template_active'
                },
                {
                    title: $translate.instant('AUDIT_TEMPLATE_INACTIVE'),
                    value: 'audit_template_inactive'
                },
                {
                    title: $translate.instant('AUDIT_DRAFT'),
                    value: 'audit_draft'
                }
            ];
            defaultValues = ['audit_template_active'];
        } else {
            statusOptions = [
                {
                    value: 'all',
                    title: $translate.instant('ALL')
                },
                {
                    title: $translate.instant('AUDIT_SCHEDULE_ACTIVE'),
                    value: 'audit_schedule_active'
                },
                {
                    title: $translate.instant('AUDIT_SCHEDULE_INACTIVE'),
                    value: 'audit_schedule_inactive'
                }
            ];
            defaultValues = ['audit_schedule_active'];

            vm.criteria.scheduleName = { type: 'text', title: 'SCHEDULE_NAME', value: $stateParams.scheduleName };
        }

        if (!Array.isArray($stateParams.auditStatus)) {
            $stateParams.auditStatus = [$stateParams.auditStatus];
        }

        vm.criteria.auditStatus = {
            type: 'options',
            title: 'STATUS',
            value: $stateParams.auditStatus,
            options: statusOptions,
            multiple: true,
            isClear: lodash.isEqual($stateParams.auditStatus, defaultValues),
            clearValue: defaultValues
        };

        function search (params) {
            var override = {
                auditPage: 1
            };

            $state.go('.', angular.extend({}, params, override), { reload: $state.current });
        }

        function order (key) {
            $stateParams.auditOrder = key;

            var override = {
                auditPage: 1
            };

            $state.go('.', angular.extend({}, $stateParams, override), { reload: $state.current });
        }
    }
})();
