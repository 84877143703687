app.directive('notCurrentUrl', function($q, $timeout) {
    return {
        require: 'ngModel',
        link: function(scope, elm, attrs, ctrl) {
        ctrl.$validators.notCurrentUrl = function(modelValue, viewValue) {
            if (modelValue === window.location.origin) {
                return false;
            }

            return true;
        };
      }
    };
  });