(function(){
    'use strict';

    angular
        .module('elogbooks.user.patrols')
        .controller('GenericPatrolPointsDetailsController', GenericPatrolPointsDetailsController);

    GenericPatrolPointsDetailsController.$inject = ['$state', '$stateParams', '$filter', 'patrolPointResponse'];

    function GenericPatrolPointsDetailsController ($state, $stateParams, $filter, patrolPointResponse) {
        var vm = this;

        vm.patrolPoint = patrolPointResponse;
    }
})();
