
angular.module('elogbooksServices').service('advancedFilterService', function (apiClient, lodash, $state, userManager) {

    /**
     * Cancel a quote
     */
    this.getFilterData = function (collection, resources) {
        switch (collection) {
            case 'audits':
                if (resources.user.getLink('audits')) {
                    var params = {};

                    return apiClient.get(resources.user.getLink('audits'), params);
                }
                break;
            case 'assets':
                if (resources.user.getLink('assets')) {
                    var params = {};

                    return apiClient.get(resources.user.getLink('assets'), params);
                }
                break;
            case 'requisites':
                if (resources.rootResource.getLink('jobrequisites')) {
                    var params = {
                        active: 1
                    };

                    return apiClient.get(resources.rootResource.getLink('jobrequisites'), params);
                }
                break;
            case 'chargeTypes':
                if (resources.rootResource.getLink('chargetypes')) {
                    var params = {}

                    if ($state.params.chargeType) {
                        params.id = $state.params.chargeType;
                    }

                    return apiClient.get(resources.rootResource.getLink('chargetypes'), params);
                }
                break;
            case 'coreclients':
                    var params = {
                        active: 'yes'
                    };

                    if (resources.rootResource.getLink('coreclients')) {
                        return apiClient.get(resources.rootResource.getLink('coreclients'), params).then( function(response) {
                            return response || {};
                        });
                    } else {
                        return {};
                    }
                break;
            case 'jobGroup':
                if (resources.rootResource.getLink('jobgroups')) {
                    return apiClient.get(resources.rootResource.getLink('jobgroups'), {limit: 999999});
                }
                break;
            case 'job-operatives':
            case 'quote-operatives':
            case 'audit-operatives':
                if (resources.rootResource.getLink('operatives')) {
                    var params = collection === 'audit-operatives'
                    ? {}
                    : {
                        permission: collection === 'job-operatives' ? 'operative_permission_receive_jobs' : 'operative_permission_view_quotes',
                        limit: 30
                    };

                    return apiClient.get(resources.rootResource.getLink('operatives'), params);
                }
                break;
            case 'approvers':
                if (resources.user.getLink('users')) {
                    var params = {
                        userMembershipPermission: 'site_permission_can_be_approver',
                        limit: 30
                    };

                    return apiClient.get(resources.user.getLink('users'), params);
                }
                break;
            case 'jobServiceProviders':
            case 'fileServiceProviders':
            case 'quoteServiceProviders':
            case 'auditServiceProviders':
                if (resources.user.getLink('serviceproviders')) {
                    var params = {};

                    if (resources.selectedSiteResponse) {
                        params.site = resources.selectedSiteResponse.id;
                    }

                    return apiClient.get(resources.user.getLink('serviceproviders'), params);
                }
                break;
            case 'job-regions':
            case 'quote-regions':
                if (resources.user.getLink('regions')) {
                    var params = {};

                    return apiClient.get(resources.user.getLink('regions'), params);
                }
                break;
            case 'jobCreators':
                if (resources.user.getLink('users')) {
                    var params = {
                        includeJobCreators: 'true'
                    };

                    return apiClient.get(resources.user.getLink('users'), params);
                }
                break;
            case 'quoteCreators':
                if (resources.user.getLink('users')) {
                    var params = {
                        includeQuoteCreators: 'true'
                    };

                    return apiClient.get(resources.user.getLink('users'), params);
                }
                break;
            case 'assetTypes':
                if (resources.rootResource.getLink('assettypes')) {
                    var params = {
                        active: 1,
                        datagroup: 'search'
                    };

                    return apiClient.get(resources.rootResource.getLink('assettypes'), params);
                }
                break;
            case 'sectors':
                if (resources.rootResource.getLink('sectors')) {
                    var params = {};

                    return apiClient.get(resources.rootResource.getLink('sectors'), params);
                }
                break;
            case 'siteGroups':
                if (resources.rootResource.getLink('sitegroups')) {
                    var params = {};

                    if ($state.params.siteGroups) {
                        params.id = $state.params.siteGroups;
                    }

                    return apiClient.get(resources.rootResource.getLink('sitegroups'), params);
                }
                break;
            case 'serviceProviderGroups':
                if (resources.rootResource.getLink('serviceprovidergroups')) {
                    var params = {
                        limit: 30
                    }
                    return apiClient.get(resources.rootResource.getLink('serviceprovidergroups'), params);
                }
                break;
            case 'sites':
                if (resources.rootResource.getLink('sites')) {
                    var params = {};

                    if ($state.params.siteIds) {
                        params["siteIds[]"] = $state.params.siteIds;
                    }

                    return apiClient.get(userManager.user.getLink('sites'), params);
                }
                break;
        }
    };
});
