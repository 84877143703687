app.config(function ($translateProvider) {
    $translateProvider.translations('nl', {
        SERVICE_PROVIDER_GROUP: 'Leveranciersgroep',
        SERVICE_PROVIDER_GROUPS: 'Leveranciersgroepen',

        ADD_NEW_SERVICE_PROVIDER_GROUP: 'Nieuwe leveranciersgroep toevoegen',

        SERVICE_PROVIDER_GROUP_LIST: 'Leveranciersgroepen',
        SERVICE_PROVIDER_GROUP_ADD: 'Nieuwe leveranciersgroep',
        SERVICE_PROVIDER_GROUP_EDIT: 'Leveranciersgroep wijzigen',

        SERVICE_PROVIDER_GROUP_CREATED: 'Leveranciersgroep gemaakt',
        SERVICE_PROVIDER_GROUP_UPDATED: 'Leveranciersgroep gewijzigd',
        SERVICE_PROVIDER_GROUP_DELETED: 'Leveranciersgroep verwijderd',

        REQUIRESASSETSWHENLOGGINGREACTIVEJOB: 'Asset vereist bij correctief onderhoud',
        REQUIRES_ASSETS_WHEN_LOGGING_REACTIVE_JOB: 'Asset vereist',
        REQUIRES_ASSET: 'Asset vereist',
        NOT_REQUIRES_ASSET: 'Geen asset vereist',

        TRAINEDAT: 'Training gehad op',
        ACTIVEAT: 'Actief sinds',
        INACTIVEAT: 'Niet meer actief sinds',
    });
});
