(function (){
    'use strict';

    angular
        .module('elogbooks.user.absence-planners')
        .config(registerAbsencePlannerRoutesList)
        .config(registerAbsencePlannerRoutesAdd)
        .config(registerAbsencePlannerRoutesEdit)
        .config(registerAbsencePlannerRoutesDetails)
        .config(registerAbsencePlannerRouteAbsenceEntry)
        .config(registerAbsencePlannerWorkflow);

    function registerAbsencePlannerRoutesList($stateProvider) {
        $stateProvider
            .state('dashboard.user.absence-planners', {
                url: '/absence-planners',
                abstract: true,
                template: '<bookerize></bookerize>',
                ncyBreadcrumb: {
                    label: '{{ ::"ABSENCE_PLANNER" | translate }}'
                }
            })
            .state('dashboard.user.absence-planners.list', {
                url: '/absence-planners?page&limit&type&status&departments&name',
                parent: 'dashboard.user.absence-planners',
                views: parseBook({
                    'elogbooks-center@dashboard.user.absence-planners': {
                        templateUrl: '/modules/user/absence-planner/list/absence-planner-list.html',
                        controller: 'UserAbsencePlannersListController',
                        controllerAs: 'vm'
                    }
                }),
                resolve: {
                    departmentCollectionResponse: function (apiClient, user) {
                        var params = { active: true, filterByUser: true };

                        if (user.getLink('departments')) {
                            return apiClient.get(user.getLink('departments'), params).then(function (response) {
                                return response;
                            });
                        } else {
                            return null;
                        }
                    },
                    absenceEntriesCollectionResponse: function (apiClient, $stateParams, user) {
                        var params = angular.extend({}, $stateParams, {
                            'type[]': $stateParams.type,
                            'status[]': $stateParams.status,
                            'departments[]': $stateParams.departments
                        });

                        return apiClient.get(user.getLink('absenceentries'), params).then(function (response) {
                            return response || null;
                        });
                    },
                    userCollectionResponse: function(apiClient, absenceEntriesCollectionResponse) {
                        return apiClient.get(absenceEntriesCollectionResponse.getLink('absenceentry-users', {})).then(function(response) {

                            return response || null;
                        });
                    },
                    publicHolidaysCollectionResponse: function(apiClient, absenceEntriesCollectionResponse, moment) {
                        var startDate = (moment().startOf('day').subtract(180, 'd')).toString();
                        var endDate = (moment().startOf('day').add(180, 'd')).toString();
                        var params = angular.extend({}, {
                            dateRange: startDate + ',' + endDate,
                            limit: 9999
                        });
                        return apiClient.get(absenceEntriesCollectionResponse.getLink('publicholidays'), params).then(function(response) {
                            return response || null;
                        });
                    },
                    departmentResponse: function (apiClient, user) {
                        if (user.getLink('department')) {
                            return apiClient.get(user.getLink('department'), { active: true}).then(function(response) {
                                return response || null;
                            });
                        } else {
                            return null;
                        }
                    }
                },
                params: {
                    page: '1',
                    limit: '30',
                    departments: {array: true},
                    name: null,
                    type: {array: true},
                    status: {array: true},
                    departmentIds: {array: true},
                    dateRange: null
                },
                ncyBreadcrumb: {
                    label: '{{ ::"LIST_VIEW" | translate }}'
                }
            });
    }

    function registerAbsencePlannerRoutesAdd($stateProvider) {
        $stateProvider
            .state('dashboard.user.absence-planners.list.add', {
                url: '/add',
                parent: 'dashboard.user.absence-planners.list',
                views: parseBook({
                    'elogbooks-right@dashboard.user.absence-planners': {
                        templateUrl: '/modules/user/absence-planner/add-edit/add-edit.form.html',
                        controller: 'UserAbsenceEntryAddEditController',
                        controllerAs: 'vm'
                    }
                }),
                resolve: {
                    absenceEntryResponse: function () {
                        return {};
                    }
                },
                ncyBreadcrumb: {
                    label: '{{ ::"ADD" | translate }}'
                }
            });
    }

    function registerAbsencePlannerRoutesDetails($stateProvider) {
        $stateProvider
            .state('dashboard.user.absence-planners.list.details', {
                url: '/details/{resource}',
                abstract: true,
                parent: 'dashboard.user.absence-planners.list',
                resolve: {
                    absenceEntryResponse: function (apiClient, base64, $stateParams) {
                        return apiClient.get(base64.decode($stateParams.resource));
                    },
                    eventCollectionResponse: function(apiClient, absenceEntryResponse) {
                        return apiClient.get(absenceEntryResponse.getLink('events'), { datagroup: 'list' }).then(function(response) {
                            return response || apiClient.noResourceFound();
                        })
                    }
                },
                views: {
                    '@dashboard.user.absence-planners': {
                        templateUrl: '/modules/user/absence-planner/details/absence-entry.html',
                        controller: 'AbsenceEntryController',
                        controllerAs: 'vm',
                    },
                    'info@dashboard.user.absence-planners.list.details': {
                        templateUrl: '/modules/user/absence-planner/details/info/absence-entry-info.html',
                        controller: 'AbsenceEntryInfoController',
                        controllerAs: 'vm'
                    },
                    'absence-entry@dashboard.user.absence-planners.list.details': {
                        templateUrl: '/modules/user/absence-planner/details/absence-entry/absence-entry-details.html',
                        controller: 'AbsenceEntryDetailsController',
                        controllerAs: 'vm'
                    },
                    'status@dashboard.user.absence-planners.list.details': {
                        templateUrl: '/modules/user/absence-planner/details/status/absence-entry-status.html',
                        controller: 'AbsenceEntryStatusController',
                        controllerAs: 'vm'
                    },
                    'feed@dashboard.user.absence-planners.list.details': {
                        templateUrl: '/modules/user/absence-planner/details/event/feed.html',
                        controller: 'AbsenceEntryEventFeedController',
                        controllerAs: 'vm'
                    },
                    'workflow@dashboard.user.absence-planners.list.details': {
                        templateUrl: '/modules/user/absence-planner/details/workflow/workflow.html',
                        controller: 'AbsenceEntryWorkflowController',
                        controllerAs: 'vm'
                    }
                },
                ncyBreadcrumb: {
                    label: '{{ ::$resolves().absenceEntryResponse.id }}'
                }
            });
    }

    function registerAbsencePlannerRouteAbsenceEntry($stateProvider) {
        $stateProvider
            .state('dashboard.user.absence-planners.list.details.absence-entry', {
                url: '/absence-entry',
                parent: 'dashboard.user.absence-planners.list.details',
                views: parseBook({
                    'absence-entry@dashboard.user.absence-planners.list.details': {
                        templateUrl: '/modules/user/absence-planner/details/absence-entry/absence-entry-details.html',
                        controller: 'AbsenceEntryDetailsController',
                        controllerAs: 'vm'
                    }
                }),
                ncyBreadcrumb: {
                    label: '{{ ::"INFO" | translate }}'
                }
            });
    }

    function registerAbsencePlannerRoutesEdit($stateProvider) {
        $stateProvider
            .state('dashboard.user.absence-planners.list.details.absence-entry.edit', {
                url: '/edit',
                parent: 'dashboard.user.absence-planners.list.details.absence-entry',
                views: parseBook({
                    'elogbooks-left@dashboard.user.absence-planners': {
                        templateUrl: '/modules/user/absence-planner/add-edit/add-edit.form.html',
                        controller: 'UserAbsenceEntryAddEditController',
                        controllerAs: 'vm'
                    }
                }),
                ncyBreadcrumb: {
                    label: '{{ ::"EDIT" | translate }}'
                }
            });
    }

    function registerAbsencePlannerWorkflow($stateProvider)
    {
        var workflowRute = 'dashboard.user.absence-planners.list.details.workflow';

        $stateProvider
            .state(workflowRute, {
                url: '/workflow',
                abstract: true,
                parent: 'dashboard.user.absence-planners.list.details',
                views: {
                    '@dashboard.user.absence-planners': {
                        templateUrl: '/modules/user/absence-planner/details/workflow/workflow-add-note/workflow-add-note.html',
                        controller: 'AbsenceEntryWorkflowAddNoteController',
                        controllerAs: 'vm',
                    },
                },
                ncyBreadcrumb: {
                    label: '{{ ::"WORKFLOW" | translate }}'
                }
            });

        // Register workflows routing actions
        ['approve', 'reject'].map(function(type) {
            $stateProvider
                .state(workflowRute + '.' + type, {
                    url: '/' + type,
                    abstract: false,
                    parent: workflowRute,
                    ncyBreadcrumb: {
                        label: '{{ ::"' + type.replaceAll('-', '_') + '" | uppercase | translate }}'
                    }
                });
        });
    }
})();
