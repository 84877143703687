(function() {
    'use strict';

    angular
        .module('elogbooks')
        .config(Translations);

    function Translations($translateProvider) {
        $translateProvider.translations('zh-hk', {
            MESSAGE: '訊息',
            MESSAGES: '訊息',

            MESSAGE_ADD: '新增訊息',

            MESSAGE_VISIBILITY: '可見性',
            MESSAGE_VISIBILITY_PUBLIC: '公用',
            MESSAGE_VISIBILITY_HELPDESK: '服務台',

            MESSAGE_DIRECTION: '方向',
            MESSAGE_DIRECTION_INBOUND: '內部',
            MESSAGE_DIRECTION_OUTBOUND: '外部',

            MESSAGE_WRITE_NEW: '寫下訊息',

            MESSAGE_SEND: '發送'
        });
    }
})();
