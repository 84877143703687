angular.module('elogbooksDirectives').directive('sitePermission', ['sitePermissions', function(sitePermissions) {
    return {
        // Restrict to attributes only.
        restrict: 'A',
        require: ['ngModel'],
        scope: {
            privilege: '=ngModel',
            disabled: '=ngDisabled'
        },
        templateUrl: '/modules/directives/form-elements/site-permission/site-permission.html',
        link: function (scope) {
            scope.privileges = sitePermissions.getAll();
        }
    }
}]);
