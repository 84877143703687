(function () {
    'use strict';

    angular
        .module('elogbooksDirectives')
        .directive('elogbooksSite', ElogbooksSite);

    function ElogbooksSite() {
        return {
            restrict: 'E',
            scope: {
                link: '='
            },
            transclude: true,
            templateUrl: '/modules/directives/links/site/site.html',
            controller: function ($scope, userManager) {

                $scope.type = null;

                switch (true) {
                    case userManager.hasPermission('user_permission_view_sites'):
                        $scope.type = 'admin';
                        break;
                    case true:
                        $scope.type = 'user';
                        break;
                }
            }
        };
    };

})();
