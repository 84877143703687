app.config(function ($translateProvider) {
    $translateProvider.translations('zh-hk', {
        CERTIFICATE_TYPES: '證書類別',
        CERTIFICATE_TYPE: '證書類別',

        CERTIFICATE_TYPE_LIST: '證書類別',
        CERTIFICATE_TYPE_ADD: '新增證書類別',
        CERTIFICATE_TYPE_EDIT: '修改證書類別',

        CERTIFICATE_TYPE_CREATED: '證書類別已建立',
        CERTIFICATE_TYPE_UPDATED: '證書類別已更新',
        CERTIFICATE_TYPE_DELETED: '證書類別已刪除',

    });
});
