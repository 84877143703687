angular.module('elogbooksServices').service('quoteModal', ['$uibModal', '$filter', 'apiClient', function ($uibModal, $filter, apiClient) {
    return {
        open: function (quoteResponse, config) {
            return $uibModal.open({
                templateUrl: '/modules/common/modals/quote/quote-modal.html',
                controller: 'QuoteModalController',
                controllerAs: 'vm',
                resolve: {
                    config: function () {
                        var defaultConfig = {
                            titleMessage: 'QUOTE',
                            bodyMessage: 'ARE_YOU_SURE',
                            primaryText: 'YES',
                            primaryClass: 'btn-danger',
                            secondaryText: 'NO',
                            secondaryClass: 'btn-default'
                        };

                        return angular.extend({}, defaultConfig, config);
                    },
                    quoteResponse: function() {

                        return quoteResponse;
                    },
                    quoteRequestResponse: function() {

                        return apiClient.get(quoteResponse._links.quoterequest.href).then(function (response) {
                            return response;
                        });
                    },
                    materialsResponse: function() {

                        return apiClient.get(quoteResponse._links.materials.href).then(function (response) {
                            return response;
                        });
                    },
                    workRequiredResponse: function() {

                        return apiClient.get(quoteResponse._links.workrequired.href).then(function (response) {
                            return response;
                        });
                    }
                }
            });
        }
    };
}]);
