(function () {
    'use strict';

    /**
     * @desc thumbnails an image.
     * @example <thumbnail width="100" height="100"></thumbnail>
     */
    angular
        .module('elogbooksDirectives')
        .directive('thumbnail', thumbnail);

    function thumbnail() {
        var directive = {
            link: link,
            template: '<img class="img-responsive" src="{{ src }}" style="width: {{ ::options.width }}px; max-height: {{ ::options.height }}px" /></div>',
            restrict: 'E',
        };

        return directive;

        function link(scope, element, attrs) {
            scope.$watch(attrs.src, function(url) {
                if (url && url.length > 0) {
                    scope.src = url;

                    if (attrs.width) {
                        scope.src += '&w=' + attrs.width;
                    }

                    if (attrs.height) {
                        scope.src +=  '&h=' + attrs.height;
                    }
                }

                scope.options = attrs;
            });
        }
    }
})();
