angular.module('elogbooksServices').service('oktaService', function (lodash, apiClient, messenger, $window, elbSettings, $state, userManager) {

    'use strict';

    var self = this;
        self.login = login;
        self.renewToken = renewToken;
        self.authorise = authorise;
        self.isUserlinkedToOkta = isUserlinkedToOkta;
        self.setSsoParams = setSsoParams;
        self.forceUserToLinkAccount = forceUserToLinkAccount;
        self.oktaConfig = null;
        self.authClient = null;

    function isUserlinkedToOkta(uri) {
        if (!elbSettings.getSetting('okta_sign_on_enabled').value) {
            return false;
        }

        return apiClient.get(uri);
    }

    function forceUserToLinkAccount(uri)
    {
        apiClient.get(uri).then(function(ssoResponse) {
            if (ssoResponse && ssoResponse.isSsoUser && !ssoResponse.isUserLinked) {
                $state.go('sso');
            }
        });
    }

    function authorise() {
        self.authClient = new OktaAuth(self.oktaConfig);
        self.authClient.token.getWithPopup({prompt: 'login'}).then(function (response) {
            apiClient.create('/sso/authorise', {
                accessToken: response.tokens.accessToken.value
            }).then(function (response) {
                if (response) {
                    $state.go('dashboard.user.dashboard', {reload: false}).then(function () {
                        messenger.success('LINK_TO_OKTA_SUCCESSFUL');
                    });
                } else {
                    messenger.error('REQUEST_ERROR');
                }
            });
        }).catch(function (error) {
            console.log(error);
        });
    }

    function renewToken() {
        self.authClient = new OktaAuth(self.oktaConfig);
        self.authClient.session.refresh().then(function () {
            apiClient.update('/sso/renew-token', {source: 'sso'}).then(function (response) {
                if (response) {
                    $window.localStorage.setItem('token', response.token);
                    $window.localStorage.setItem('tokenExpiration', response.expiration * 1000);
                } else {
                    messenger.error('REQUEST_ERROR');
                }
            });
        }).catch(function (error) {
            userManager.logout();
            $state.go('login');
            console.log(error);
        });
    }

    function login() {
        self.authClient = new OktaAuth(self.oktaConfig);
        self.authClient.token.getWithPopup({prompt: 'login'}).then(function (response) {
            apiClient.create('/sso/login', {
                uid: response.tokens.accessToken.claims.uid,
                accessToken: response.tokens.accessToken.value,
                source: 'sso'
            }).then(function (response) {
                if (response) {
                    $window.localStorage.setItem('token', response.token);
                    $window.localStorage.setItem('tokenExpiration', response.expiration*1000);
                    $window.localStorage.removeItem('recaptchaRequired');
                    userManager.getUserResourceAndTokenLink().then(function (response) {
                        if (response) {
                            $state.go('dashboard.user.dashboard');
                        } else {
                            $window.localStorage.removeItem('token');
                            $window.localStorage.removeItem('tokenExpiration');
                        }
                    });
                } else {
                    messenger.error('REQUEST_ERROR');
                }
            });
        }).catch(function (error) {
            console.log(error);
        });
    }

    function setSsoParams(ssoParams) {
        self.oktaConfig = {
            url: ssoParams.url,
            issuer: ssoParams.issuer,
            clientId: ssoParams.client_id,
            redirectUri: ssoParams.redirect_url
        };
    }
});
