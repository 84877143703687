(function () {
    'use strict';

    angular
        .module('elogbooks.user.reports')
        .controller('UserReportsSiteAssociatePpmMissedAttendanceController', UserReportsSiteAssociatePpmMissedAttendanceController);

    UserReportsSiteAssociatePpmMissedAttendanceController.$inject = ['jobParams', 'jobsCollection', 'siteAssociateResponse', '$translate'];

    function UserReportsSiteAssociatePpmMissedAttendanceController(jobParams, jobsCollection, siteAssociateResponse, $translate) {
        var vm = this;

        vm.jobsCollection = jobsCollection;
        vm.jobs = jobsCollection.jobs;
        vm.associate = siteAssociateResponse;
        vm.exportParams = jobParams;

        vm.listTitle = vm.associate.title + ' - ' + (
            (jobParams.overdueAttendanceHistoric) ?
                $translate.instant('PPM_MISSED_ATTENDANCE') :
                $translate.instant('PPM_ATTENDANCE')
        );
    }
})();
