(function () {
    'use strict';

    angular
        .module('elogbooksDirectives')
        .directive('autofocus', ['$timeout', autofocus]);

    function autofocus($timeout) {
        var directive = {
            restrict: 'A',
            link: link,
            scope: {
                autofocus: '='
            }
        };

        return directive;

        function link(scope, element, attrs) {
            scope.$watch('autofocus', function (focus) {
                if (focus) {
                    $timeout(function () {
                        element[0].focus();
                    });
                }
            });
        }
    }
})();
