(function () {
    'use strict';

    /**
     * @desc ui.select expanded widget
     * @example <elogbooks-select ng-model="vm.model"></elogbooks-select>
     */
    angular
        .module('elogbooksDirectives')
        .directive('elogbooksSelect', function (apiClient, lodash) {
            return {
                restrict: 'A',
                templateUrl: '/modules/directives/form-elements/select/select.html',
                scope: {
                    model: '=ngModel'
                },
                require: ['ngModel'],
                controller: ['$scope', function($scope) {

                    var defaultModel = {
                        response : null,
                        link : null,
                        loading : false,
                        required : false,
                        disabled : false,
                        allowAdd : false,
                        items : [],
                        responseKeyPath: 'data',
                        itemHrefPath: '_links.self.href',
                        itemValuePath: 'name',
                        expandedSearch: false,
                        getSelected: function() {
                            if (typeof this.selected !== 'undefined') {
                                return this.selected.href;
                            }

                            return null;
                        },
                        map : function(keyPath, valuePath) {
                            return function (item) {
                                if (typeof item === 'undefined'){
                                    return;
                                }

                                return  {
                                    href :  lodash.get(item, keyPath),
                                    value : lodash.get(item, valuePath)
                                };
                            }
                        },
                        getResponseData : function (response) {
                            return lodash.get(response.getData(), this.responseKeyPath);
                        },
                        filterResponse : function (response) {
                            return this.getResponseData(response).map(
                                this.map(this.itemHrefPath, this.itemValuePath)
                            );
                        },
                        buildItems : function (response) {
                            this.items = this.filterResponse(response)
                        },
                        search : function(keyword) {
                            this.loading = true;

                            if (keyword.length >= 1 && this.link) {
                                // Async function needs to access object properties from global scope
                                apiClient.get(this.link, {name: keyword}).then(function (response) {
                                    $scope.model.loading = false;

                                    $scope.model.items =  $scope.model.filterResponse(response);

                                    addElement(keyword);
                                });
                            } else {
                                addElement(keyword);

                                this.loading = false;
                            }
                        },
                        clear : function($event) {
                            $event.stopPropagation();

                            this.selected = undefined;
                            $scope.model.selected = undefined;

                            if (this.response){
                                this.buildItems(this.response);
                            }

                            if(typeof this.onRemove == 'function') {
                                this.onRemove();
                            }
                        }
                    };

                    //Merge with defaults
                    $scope.model = lodash.merge(defaultModel, $scope.model);

                    if ($scope.model.response){
                        $scope.model.buildItems($scope.model.response);
                    }

                    $scope.model.requiredOriginal = $scope.model.required;
                    $scope.$watch('model.disabled', function(newValue) {
                        if (newValue) {
                            $scope.model.selected = undefined;
                        }

                        if ($scope.model.requiredOriginal) {
                            $scope.model.required = !newValue;
                        }
                    });

                    function checkIfExist(keyword) {
                        for (var item in $scope.model.items) {
                            if ($scope.model.items.hasOwnProperty(item)
                                && $scope.model.items[item].value.indexOf(keyword) > -1
                            ) {
                                return true;
                            }
                        }

                        return false;
                    }

                    function addElement(keyword) {
                        if (keyword.length >= 1
                            && $scope.model.allowAdd
                            && !checkIfExist(keyword)
                        ) {
                            $scope.model.items.unshift({value:keyword});
                        }
                    }
                }]
            };
    })
})();
