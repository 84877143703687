app.config( function($translateProvider) {
    $translateProvider.translations('en-gb', {
        METER_OCCUPANCY_INFORMATION: 'Meter Occupancy Information',

        ADD_NEW_METER_OCCUPANCY: 'Add a new Meter Occupancy',
        EDIT_METER_OCCUPANCY: 'Edit Meter Occupancy',

        METER_OCCUPANCY_LIST: 'Meter Occupancies',
        METER_OCCUPANCIES_LIST: 'Meter Occupancies',
        METER_OCCUPANCY_ADD: 'Add a new Meter Occupancy',
        METER_OCCUPANCY_EDIT: 'Edit Meter Occupancy',

        METER_OCCUPANCY_CREATED: 'Meter Occupancy created successfully',
        METER_OCCUPANCY_UPDATED: 'Meter Occupancy updated successfully',
        METER_OCCUPANCY_DELETED: 'Meter Occupancy deleted successfully',

        METER_OCCUPANCY_METER: 'meter',
        METER_OCCUPANCY_SUPPLY: 'supply',
        METER_OCCUPANCY_OCCUPANCY: 'occupancy'
    });
});
