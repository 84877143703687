(function () {
    'use strict';

    angular
        .module('elogbooks.user.reports')
        .controller('UserReportsJobsReopenedController', UserReportsJobsReopenedController);

    UserReportsJobsReopenedController.$inject = ['jobsCollection', 'operativeResponse', 'modalConfig'];

    function UserReportsJobsReopenedController(jobsCollection, operativeResponse, modalConfig) {
        var vm = this;

        vm.jobsCollection = jobsCollection;
        vm.jobs = jobsCollection.jobs;
        vm.op = operativeResponse;

        vm.modalConfig = modalConfig;
    }
})();