(function () {
    'use strict';

    angular
        .module('elogbooks.admin.site-associate-types')
        .controller('SiteAssociateTypeAddEditController', SiteAssociateTypeAddEditController);

    SiteAssociateTypeAddEditController.$inject = ['requestDataFactory', 'siteAssociateTypeResponse', 'siteAssociateTypeCollectionResponse', 'parentCollectionResponse', 'crudService'];

    function SiteAssociateTypeAddEditController (requestDataFactory, siteAssociateTypeResponse, siteAssociateTypeCollectionResponse, parentCollectionResponse, crudService) {
        var vm = this;
            vm.siteAssociateType = siteAssociateTypeResponse;
            vm.data = requestDataFactory.createRequest(vm.siteAssociateType);
            vm.parentCollectionResponse = parentCollectionResponse;
            vm.parentSiteAssociateTypeOptions = [];

        vm.create = createAction;
        vm.update = updateAction;
        vm.delete = deleteAction;

        vm.parentSelect = {
            response: parentCollectionResponse,
            link: parentCollectionResponse.getLink('self'),
            responseKeyPath: 'siteAssociateTypes',
            itemHrefPath: '_links.parent.href',
            itemValuePath: 'name',
            selected: vm.data._links.parent ? {
                href: vm.data._links.parent.href,
                title: vm.data._links.parent.title,
                object: vm.data._links.parent
            } : null,
            onSelect: changeParent,
            onRemove: deleteParent
        };

        function changeParent() {
            vm.data._links['parent'] = vm.parentSelect.selected.object.getLink('self');
        }

        function deleteParent() {
            delete vm.data._links.parent;
        }

        angular.forEach(parentCollectionResponse.siteAssociateTypes, function(siteAssociateType, $index) {
            if (! vm.siteAssociateType._links || vm.siteAssociateType._links.self.href !== siteAssociateType.getLink('self')) {
                vm.parentSiteAssociateTypeOptions.push(siteAssociateType);
            }
        });

        function createAction() {
            return crudService.create(
                siteAssociateTypeCollectionResponse.getLink('create'),
                vm.data,
                'dashboard.admin.site-associate-types.list.details.info',
                'resource',
                'SITE_ASSOCIATE_TYPE'
            );
        }

        function updateAction() {
            return crudService.update(
                vm.siteAssociateType.getLink('edit'),
                vm.data,
                'dashboard.admin.site-associate-types.list.details.info',
                'dashboard.admin.site-associate-types.list',
                'SITE_ASSOCIATE_TYPE'
            );
        }

        function deleteAction() {
            return crudService.delete(
                vm.siteAssociateType.getLink('delete'),
                'dashboard.admin.site-associate-types.list',
                'SITE_ASSOCIATE_TYPE'
            );
        }
    }
})();
