app.config(function ($translateProvider) {
    $translateProvider.translations('zh-hk', {
        FLOOR: '樓層',
        FLOORS: '樓層',

        ADD_NEW_FLOOR: '新增樓層',
        EDIT_FLOOR: '修改樓層',

        FLOOR_CREATED: '樓層已建立',
        FLOOR_UPDATED: '樓層已更新',
        FLOOR_DELETED: '樓層已刪除',

        NO_FLOOR_RECORDS: '沒有樓層資料'
    });
});
