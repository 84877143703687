app.config(function ($translateProvider) {
    $translateProvider.translations('nl', {
        // JOB_MESSAGE_ACTION: '<strong>{{ ::message.sender }}</strong> added a message.',
        JOB_MESSAGE_ACTION: '<strong>{{ ::message._links["sender"].title }}</strong> heeft een bericht toegevoegd.',
        JOB_ACTIONED_ACTION: 'Actie nodig, deadline is overschreden.',
        JOB_CREATED_ACTION: '<strong>{{ ::event._links["user"].title }}</strong> heeft de opdracht aangemaakt.',
        JOB_ALARM_UPDATED_ACTION: '<strong>{{ ::event._links["user"].title }}</strong> heeft de opdracht bijgewerkt.',
        JOB_APPROVAL_REQUESTED_ACTION: '<strong>{{ ::event._links["user"].title }}</strong> heeft een verzoek tot goedkeuring verzonden.',
        JOB_APPROVAL_ACCEPTED_ACTION: '<strong>{{ ::event._links["user"].title }}</strong> heeft de opdracht goedgekeurd.',
        JOB_APPROVAL_REJECTED_ACTION: '<strong>{{ ::event._links["user"].title }}</strong> heeft de opdracht afgekeurd.',
        JOB_APPROVAL_RESENT_ACTION: '<strong>{{ ::event._links["user"].title }}</strong> heeft verzoek nogmaals verzonden aan {{ ::event._links["approver"].title }}.',
        JOB_ASSIGNMENT_REQUESTED_ACTION: '<strong>{{ ::event._links["user"].title }}</strong> een opdrachtverzoek verzonden naar {{ ::event._links["operative"].title }}.',
        JOB_ASSIGNMENT_REASSIGNED_ACTION: '<strong>{{ ::event._links["user"].title }}</strong> heeft de opdracht nogmaals toegewezen.',
        JOB_ASSIGNMENT_ACCEPTED_ACTION: '<strong>{{ ::event._links["user"].title }}</strong> heeft de opdracht geaccepteerd.',
        JOB_ASSIGNMENT_REJECTED_ACTION: '<strong>{{ ::event._links["user"].title }}</strong> heeft de opdracht gewijderd.',
        JOB_ASSIGNMENT_RESENT_ACTION: '<strong>{{ ::event._links["user"].title }}</strong> heeft een verzoek doorverwezen naar {{ ::event._links["operative"].title }}.',
        JOB_ASSIGNMENT_INSISTED_ACTION: '<strong>{{ ::event._links["user"].title }}</strong> staat erop dat de opdracht wordt geaccepteerd.',
        JOB_PROPOSAL_REQUESTED_ACTION: '<strong>{{ ::event._links["user"].title }}</strong> heeft een voorstel verzonden.',
        JOB_PROPOSAL_ACCEPTED_ACTION: '<strong>{{ ::event._links["user"].title }}</strong> heeft een voorstel geaccepteerd.',
        JOB_PROPOSAL_REJECTED_ACTION: '<strong>{{ ::event._links["user"].title }}</strong> heeft een voorstel geweigerd.',
        JOB_EXTENSION_REQUESTED_ACTION: '<strong>{{ ::event._links["user"].title }}</strong> heeft een verzoek tot uitstel gedaan.',
        JOB_EXTENSION_ACCEPTED_ACTION: '<strong>{{ ::event._links["user"].title }}</strong> heeft een verzoek tot uitstel geaccepteerd.',
        JOB_EXTENSION_REJECTED_ACTION: '<strong>{{ ::event._links["user"].title }}</strong> heeft een verzoek tot uitstel afgewezen.',
        JOB_OPERATION_ACKNOWLEDGED_ACTION: '<strong>{{ ::event._links["user"].title }}</strong> heeft de opdracht bevestigd.',
        JOB_OPERATION_COMMENCED_ACTION: '<strong>{{ ::event._links["user"].title }}</strong> is gestart met de opdracht.',
        JOB_OPERATION_COMPLETED_ACTION: '<strong>{{ ::event._links["user"].title }}</strong> heeft de opdracht afgerond.',
        JOB_POSTPONE_REMINDER_AT_ACTION: '<strong>{{ ::event._links["user"].title }}</strong> heeft de deadline gewijzigd naar {{ ::event.reminderAt | date:\'short\' }}.',
        JOB_OPERATION_MISSED_ACTION: '<strong>{{ ::event._links["user"].title }}</strong> heeft de opdracht gemist.',
        JOB_OPERATION_CHASED_ACTION: 'Een herinneringsbericht #{{ ::event.chaseSequence + 1 }} is verzonden naar <strong>{{ ::event._links["user"].title }}</strong>.',
        JOB_OPERATION_REOPENED_ACTION: '<strong>{{ ::event._links["user"].title }}</strong> heeft de opdracht heropend.',
        JOB_OPERATION_CANCELLED_ACTION: '<strong>{{ ::event._links["user"].title }}</strong> heeft de opdracht geannuleerd.',
        JOB_APPROVAL_ESCALATED_ACTION: '<strong>{{ ::event._links["user"].title }}</strong> Heeft de opdracht laten escaleren.',
        JOB_AUDIT_TRAIL_SENT_ACTION: '<strong>{{ ::event._links["user"].title }}</strong> heeft het logfile verzonden.',
        JOB_EDITED_ACTION: '<strong>{{ ::event._links["user"].title }}</strong> heeft de opdracht aangepast.',
        JOB_OPERATIVE_REASSIGNED_ACTION: '<strong>{{ ::event._links["user"].title }}</strong> Heeft opdrachtnemer aangepast.',
    });
});
