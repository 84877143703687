(function(){
    'use strict';

    angular
        .module('elogbooks.user.patrols')
        .controller('PatrolPlannerController', PatrolPlannerController);

    PatrolPlannerController.$inject = ['$stateParams', 'patrolResponse','rootResourceResponse', 'siteResponse', 'serviceProviderResponse', '$filter', '$state'];

    function PatrolPlannerController ($stateParams, patrolResponse, rootResourceResponse, siteResponse, serviceProviderResponse, $filter, $state) {
        var vm = this;
        vm.patrols = patrolResponse;
        vm.day = moment().day($stateParams.dayOfWeek).format('dddd');
        vm.filter = filterAction;

        vm.tidyTables = {
            name: 'patrols',
            columns : {
                'PATROL_NAME': true,
                'SERVICE_PROVIDER': true
            }
        };

        var dayOptions = [
            { value: '1', title: $filter('translate')("MONDAY") },
            { value: '2', title: $filter('translate')("TUESDAY") },
            { value: '3', title: $filter('translate')("WEDNESDAY") },
            { value: '4', title: $filter('translate')("THURSDAY") },
            { value: '5', title: $filter('translate')("FRIDAY") },
            { value: '6', title: $filter('translate')("SATURDAY") },
            { value: '7', title: $filter('translate')("SUNDAY") }
        ];

        vm.siteSelect = {
            response : siteResponse,
            link : siteResponse ? siteResponse.getLink('self') : null,
            responseKeyPath: 'sites',
            itemValuePath: 'name',
            itemHrefPath: 'id',
            disabled: !!$stateParams.selectedSiteResource
        };

        vm.criteria = {
            dayOfWeek: { type: 'options', title: 'DAY', value: $stateParams.dayOfWeek, options: dayOptions},
            siteIds: { type: 'autocompletemulti', multiple: true, title: 'SITE', value: $stateParams.siteIds, options: vm.siteSelect, param: 'siteIds', max: 50 },
            patrolName: { type: 'text', title: 'NAME', value: $stateParams.patrolName}
        };

        if (serviceProviderResponse) {
            vm.serviceProviderSelect = {
                response : serviceProviderResponse,
                link : serviceProviderResponse ? serviceProviderResponse.getLink('self') : null,
                itemValuePath: 'id',
                itemTitlePath: 'name',
                responseKeyPath: 'serviceproviders'
            };

            vm.criteria.serviceProvider = { type: 'jsonselectwidget', title: 'SERVICE_PROVIDER', value: JSON.parse($stateParams.serviceProvider), options: vm.serviceProviderSelect };
        }

        vm.resources = {
            'rootResource': rootResourceResponse,
            'stateParams': $stateParams,
            'root': rootResourceResponse
        };

        function filterAction (params) {
            $state.go('.', angular.extend({}, params), { reload: $state.current.name });
        }
    }
})();
