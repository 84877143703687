(function() {
    'use strict';

    angular
        .module('elogbooks.common.actions')
        .controller('CommonActionDetailsEventFeedController', CommonActionDetailsEventFeedController);

    CommonActionDetailsEventFeedController.$inject = ['eventCollectionResponse', 'messageCollectionResponse', 'fileCollectionResponse', 'config'];

    function CommonActionDetailsEventFeedController(eventCollectionResponse, messageCollectionResponse, fileCollectionResponse, config) {
        var vm = this;
        vm.feed = [];
        vm.messageCollectionResponse = messageCollectionResponse;
        vm.eventCollectionResponse = eventCollectionResponse;
        vm.fileCollectionResponse = fileCollectionResponse;
        vm.config = config;
    }
})();
