app.config(function ($translateProvider) {
    $translateProvider.translations('nl', {
        PATROL: 'Ronde',
        PATROLS: 'Rondes',


        PATROL_ADD: 'Voeg een nieuwe ronde toe',
        PATROL_EDIT: 'Wijzig ronde',
        PATROL_LIST: 'Rondes',

        PATROL_CREATED: 'Ronde gemaakt',
        PATROL_UPDATED: 'Ronde gewijzigd',
        PATROL_DELETED: 'Ronde verwijderd',

        ORDERED: 'Gecontracteerd',
        ORDERED_YES: 'Gecontracteerd',
        ORDERED_NO: 'Losse ronde',

        PATROL_POINT: 'Ronde punt',
        PATROL_POINTS: 'Ronde punten',
        PATROL_POINT_TAB: 'Ronde punten',
        PATROL_POINT_LIST: 'Ronde punten',
        PATROLPOINTS_COUNT: 'Punten',
        PATROLPOINTS_TIME_COUNT:'Duur',
        PATROL_POINT_ADD: 'Voeg punt toe',
        PATROL_POINT_EDIT: 'Wijzig punt',
        TIMEALLOCATED: 'Tijd gealloceerd',
        REQUIREMENTTYPE: 'Type vereiste',
        REQUIREMENT: 'Vereiste',
        PATROL_POINT_REQUIREMENT_NONE: 'Geen',
        PATROL_POINT_REQUIREMENT_BARCODE: 'Barcode',
        PATROL_POINT_REQUIREMENT_NFC: 'NFC',
        PATROL_POINT_REQUIREMENT_PHOTO: 'Foto',
        PATROL_POINT_REQUIREMENT_SIGNATURE: 'Handtekening',
        PATROL_POINT_REQUIREMENT_FILE: 'Bestand',
        PATROLPOINT_CREATED: 'Punt gemaakt',
        PATROLPOINT_UPDATED: 'Punt gewijzigd',
        PATROLPOINT_DELETED: 'Punt verwijderd',

        PATROL_TEMPLATE: 'Ronde Sjabloon',
        PATROL_TEMPLATES: 'Ronde Sjablonen',
        PATROL_TEMPLATES_TAB: 'Ronde sjabloon',
        PATROL_TEMPLATE_LIST: 'Ronde sjabloon',
        PATROL_TEMPLATE_ADD: 'Voeg sjabloon toe',
        PATROL_TEMPLATE_EDIT: 'Wijzig Sjabloon',

        PATROL_SCHEDULE: 'Ronde schema',
        PATROL_SCHEDULES: 'Ronde schemas',
        PATROL_SCHEDULE_ADD: 'Voeg schema toe',
        PATROL_SCHEDULE_EDIT: 'Wijzig schema',
        DAYOFWEEK: 'Dag van de week',
        DAY_OF_WEEK: 'Dag van de week',
        START_TIME: 'Start Tijd',
        END_TIME: 'Eind Tijd',
    });
});
