(function () {
    'use strict';

    angular
        .module('elogbooks.user.reports')
        .controller('UserReportsSiteAssociatePaperworkForReviewController', UserReportsSiteAssociatePaperworkForReviewController);

    UserReportsSiteAssociatePaperworkForReviewController.$inject = ['actionsCollection', 'siteAssociateResponse', '$translate', '$state'];

    function UserReportsSiteAssociatePaperworkForReviewController(actionsCollection, siteAssociateResponse, $translate, $state) {
        var vm = this;
        vm.actionsCollection = actionsCollection;
        vm.actions = actionsCollection.actions;
        vm.associate = siteAssociateResponse;

        vm.listTitle = vm.associate._links.user.title + ' - ' + $translate.instant('PAPERWORK_FOR_REVIEW');
    }
})();
