(function(){
    'use strict';

    angular
        .module('elogbooksControllers')
        .controller('SearchSiteModalController', SearchSiteModalController);

    SearchSiteModalController.$inject = ['$uibModalInstance', 'apiClient', 'restrictOne', 'searchUrl', 'lodash', 'linkParameters'];

    function SearchSiteModalController($uibModalInstance, apiClient, restrictOne, searchUrl, lodash, linkParameters) {
        var vm = this;
        vm.expandedSearchForm = {
            regionSelect : {
                link : '/regions',
                responseKeyPath: 'regions'
            },
            sectorSelect : {
                link : '/sectors',
                responseKeyPath: 'sectors'
            },
            siteGroupSelect : {
                link : '/sitegroups',
                responseKeyPath: 'sitegroups'
            },
            name: null,
            reference: null,
            selected: [],
            restrictOne: restrictOne,
            items: null,
            address: null,
            expandedSearch: expandedSearchAction
        };
        vm.submit = submitAction;
        vm.close = closeAction;
        vm.loadMore = loadMore;

        vm.loadedSiteCount = 0;
        vm.allSiteCount = 0;
        vm.sites = {};
        vm.loading = false;
        vm.noResults = false;
        vm.resultCount = false;


        function submitAction() {
            $uibModalInstance.close(vm.expandedSearchForm.selected);
        }

        function closeAction() {
            $uibModalInstance.dismiss('cancel');
        }

        function loadMore() {
            if (vm.loading) {
                return;
            }

            vm.loading = true;

            if (vm.loadedSiteCount < vm.allSiteCount) {
                apiClient.get(vm.sites.getLink('next'), linkParameters).then(function (response) {
                    vm.sites = response;
                    vm.loadedSiteCount += response.sites.length;
                    vm.expandedSearchForm.items = lodash.unionBy(vm.expandedSearchForm.items, response.sites.map(
                        function (item) {
                            if (typeof item === 'undefined'){
                                return;
                            }

                            return  {
                                object: item,
                                href :  item._links.self.href,
                                value : item.name
                            };
                        }
                    ), 'href');

                    vm.loading = false;
                });
            } else {
                vm.loading = false;
            }
        }


        function expandedSearchAction($scope) {
            vm.loading = true;
            vm.noResults = false;

            var searchFilter = {
                regionId: typeof this.regionSelect.selected !== 'undefined' ?
                    this.regionSelect.selected.object.id : null,
                sectorId: typeof this.sectorSelect.selected !== 'undefined' ?
                    this.sectorSelect.selected.object.id : null,
                groupId: typeof this.siteGroupSelect.selected !== 'undefined' ?
                    this.siteGroupSelect.selected.object.id : null,
                siteName: this.name && this.name !== '' ? this.name : null,
                address: this.address && this.address !== '' ? this.address : null,
                reference: this.reference && this.reference !== '' ? this.reference : null
                // status: 'active' Find a use case when that will not be a good idea?
            };

            apiClient.get(searchUrl, angular.extend({}, searchFilter, linkParameters)).then(function (response) {
                vm.allSiteCount = response.count;
                vm.loadedSiteCount = response.sites.length;

                if (response.sites.length === 0) {
                    vm.expandedSearchForm.items = null;
                    vm.resultCount = false;
                    vm.noResults = true;
                    vm.loading = false;
                    return;
                }

                vm.resultCount = true;
                vm.sites = response;
                vm.expandedSearchForm.items = response.sites.map(
                    function (item) {
                        if (typeof item === 'undefined'){
                            return;
                        }

                        return  {
                            object: item,
                            href :  item._links.self.href,
                            value : item.name
                        };
                    }
                );
                vm.loading = false;
            });
        }
    }
})();
