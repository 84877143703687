(function () {
    'use strict';

    angular
        .module('elogbooks.admin.waste-management')
        .controller('WasteTypesController', WasteTypesController);

    WasteTypesController.$inject = ['$state', '$stateParams', '$filter', 'wasteTypeCollectionResponse'];

    function WasteTypesController($state, $stateParams, $filter, wasteTypeCollectionResponse) {
        var vm = this;

        vm.wasteTypes = wasteTypeCollectionResponse.wasteTypes;
        vm.wasteTypesCollectionResponse = wasteTypeCollectionResponse;

        vm.order = order;
        vm.search = search;

        var statuses = [
            {
                title: $filter('translate')('STATUS_ACTIVE'),
                value: 'true'
            },
            {
                title: $filter('translate')('STATUS_INACTIVE'),
                value: 'false'
            },
            {
                title: $filter('translate')('NONE_SELECTED'),
                value: null
            }
        ];

        vm.criteria = {
            id: { type: 'number', value: $stateParams.id ? parseInt($stateParams.id, 10) : null, title: 'ID', min: 1 },
            name: { type: 'text', value: $stateParams.name, title: 'NAME' },
            code: { type: 'text', value: $stateParams.code, title: 'CODE' },
            status: { type: 'options', title: 'IS_ACTIVE', value: $stateParams.status, options: statuses }
        };

        function search(params) {
            var override = {
                page: 1
            };

            $state.go('.', angular.extend({}, params, override), {reload: $state.current});
        };

        function order(key) {
            $stateParams.order = key;

            $state.go('.', angular.extend({}, $stateParams), {reload: $state.current});
        };
    }
})();
