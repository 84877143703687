(function() {
    'use strict';

    angular
        .module('elogbooksDirectives')
        .directive('elbDashboardReport', ElbDashboardReport);

    ElbDashboardReport.$inject = ['$state', '$translate', 'lodash', '$timeout', 'moment', 'userManager', '$window', 'base64', 'modulesService', '$stateParams', 'dashboardService'];

    function ElbDashboardReport($state, $translate, lodash, $timeout, moment, userManager, $window, base64, modulesService, $stateParams, dashboard) {
        return {
            restrict: 'AE',
            scope: {
                model: '=ngModel',
                viewJobs: '=viewJobs',
                viewQuotes: '=viewQuotes',
                type: '=',
                colors: '=?'
            },
            templateUrl: '/modules/common/dashboard/report/_wrapper.html',
            link: function($scope, element) {
                $scope.setSiteSelectorAndRedirect = switchSites;
                $scope.getColor = getColor;
                $scope.loading = true;
                $scope.toggleStat = function() {
                    $scope.model.enabled = !$scope.model.enabled;
                    dashboard.statToggled($scope.model);
                }
                $scope.$watch('model.loading', function(newVal) {
                    if (newVal) {
                        setUpStat();
                    }
                });
                var reload = true;

                function switchSites(site, redirect, options) {
                    var localStorage = $window.localStorage;

                    if (site) {
                        var encodedSite = base64.encode(site);

                        if (localStorage.getItem('selectedSiteResource') === encodedSite) {
                            reload = false;
                        } else {
                            localStorage.setItem('selectedSiteResource', encodedSite);
                        }
                    } else {
                        if (localStorage.getItem('selectedSiteResource') === null) {
                            reload = false;
                        }
                    }

                    $state.go(redirect, angular.extend({}, $stateParams, options), { reload: reload });
                }

                $timeout(function(){
                    var container = angular.element.find('.action-barchart');
                    if ($scope.options && container.length > 0) {
                        $scope.options.chart.width = container[0].clientWidth;
                    }
                },300);

                function setUpStat() {
                    if ($scope.model.type === "escalations") {
                        $scope.overrideEscalationListView = (userManager.hasPermission('site_permission_helpdesk') || userManager.hasPermission('site_permission_can_be_approver'));
                        $scope.model.header = $scope.overrideEscalationListView ? "SERVICE_PROVIDERS_IN_ESCALATION" : "ESCALATIONS"
                    }

                    if ($scope.model.type === 'planned_requiring_approval') {
                        $scope.advancedPpmPlanner = modulesService.isEnabled('advanced_ppm_planner')
                    }

                    $scope.p2pAwaitingPoNumberRoute = {
                        p2pAwaitingPoNumber: '1',
                        jobsType: 'reactive',
                        jobStatus: ['completed']
                    }

                    $scope.p2pAwaitingValueRoute = {
                        p2pAwaitingValue: '1',
                        jobsType: 'reactive',
                        jobStatus: ['completed']
                    }

                    if (userManager.hasPermission('site_permission_p2p') || userManager.hasPermission('site_permission_submit_p2p_job_po')) {
                        $scope.p2pAwaitingPoNumberRoute.p2p = '1';
                    } else {
                        $scope.p2pAwaitingValueRoute.p2p = '1';
                    }

                    $scope.colors = $scope.colors || ['#F9B454', '#ea3324', '#1D9984'];

                    $scope.endDate = moment(moment(new Date()).format('MM/DD/YYYY 23:59:59'));

                    $scope.model.total = 0;
                    angular.forEach($scope.model.data, function(value, index) {
                        $scope.model.total += parseFloat(value.value, 10);
                    });

                    if ($scope.model.type === 'external_system_actions') {
                        $scope.enabledThirdPartyIntegrations = modulesService.enabledModules(modulesService.thirdPartyIntegrations());
                        var enabledThirdPartyIntegrationsTranslationParams = {};

                        $scope.enabledThirdPartyIntegrations.forEach(function(integration, index) {
                            enabledThirdPartyIntegrationsTranslationParams['action_'+index] = $translate.instant(integration.toUpperCase());
                        });

                        $scope.model.header = $translate.instant('OPEN_ACTIONS_'+Object.keys(enabledThirdPartyIntegrationsTranslationParams).length, enabledThirdPartyIntegrationsTranslationParams);
                    }
                    var typeMetadata = {
                        job_reactive_overdue_attendance: {
                            type: 'pieChart',
                            route: 'dashboard.user.jobs.list',
                            parameters: [
                                {
                                    jobsType: 'reactive',
                                    jobsOverdueAttendancePerformance: 'true',
                                    jobStatus: ['assignment_proposal', 'assignment_pending', 'assignment_rejected', 'acknowledged', 'extension_pending', 'operative_assignment_rejected'],
                                    jobsStatutory: 'false'
                                },
                                {
                                    jobsType: 'reactive',
                                    jobsOverdueAttendancePerformance: 'true',
                                    jobStatus: ['assignment_proposal', 'assignment_pending', 'assignment_rejected', 'acknowledged', 'extension_pending', 'operative_assignment_rejected'],
                                    jobsStatutory: 'true'
                                },
                                {
                                    jobsType: 'reactive',
                                    jobStatus: ['assignment_proposal', 'assignment_pending', 'assignment_rejected', 'acknowledged', 'extension_pending', 'operative_assignment_rejected'],
                                    jobsOverdueAttendancePerformance: 'false'
                                }
                            ],
                            options: {
                                valueFormat: function(value) { return d3.format(',.0d')(value); }
                            }
                        },
                        job_reactive_overdue_completion: {
                            type: 'pieChart',
                            route: 'dashboard.user.jobs.list',
                            parameters: [
                                {
                                    jobsType: 'reactive',
                                    jobsOverdueCompletion: 'true',
                                    jobStatus: ['assignment_pending', 'assignment_rejected', 'assignment_proposal', 'acknowledged', 'commenced', 'extension_pending'],
                                    jobsStatutory: 'false'
                                },
                                {
                                    jobsType: 'reactive',
                                    jobsOverdueCompletion: 'true',
                                    jobStatus: ['assignment_pending', 'assignment_rejected', 'assignment_proposal', 'acknowledged', 'commenced', 'extension_pending'],
                                    jobsStatutory: 'true'
                                },
                                {
                                    jobsType: 'reactive',
                                    jobsOverdueCompletion: 'false',
                                    jobStatus: ['assignment_pending', 'assignment_rejected', 'assignment_proposal', 'acknowledged', 'commenced', 'extension_pending'],
                                }
                            ],
                            options: {
                                valueFormat: function(value) { return d3.format(',.0d')(value); }
                            }
                        },
                        job_planned_overdue_completion: {
                            type: 'pieChart',
                            route: 'dashboard.user.jobs.list',
                            parameters: [
                                {
                                    jobsType: 'planned',
                                    jobsOverdueCompletion: 'true',
                                    jobStatus: ['acknowledged', 'commenced', 'extension_pending'],
                                    jobsStatutory: 'false'
                                },
                                {
                                    jobsType: 'planned',
                                    jobsOverdueCompletion: 'true',
                                    jobStatus: ['acknowledged', 'commenced', 'extension_pending'],
                                    jobsStatutory: 'true'
                                },
                                {
                                    jobsType: 'planned',
                                    jobsOverdueCompletion: 'false',
                                    jobStatus: ['acknowledged', 'commenced', 'extension_pending'],
                                }
                            ],
                            options: {
                                valueFormat: function(value) { return d3.format(',.0d')(value); }
                            }
                        },
                        action_open: {
                            type: 'stacked',
                            route: 'dashboard.user.actions.manage-actions.list',
                            parameters: [
                                {
                                    actionStatus: ['open']
                                },
                                {
                                    actionStatus: ['completed']
                                }
                            ],
                            options: {
                                valueFormat: function(value) { return d3.format(',.0d')(value); }
                            }
                        },
                        outstanding_remedials: {
                            type: 'multipleEntitiesChart',
                            options: {
                                valueFormat: function (value) {
                                    return d3.format(',.0d')(value);
                                }
                            }
                        },
                        asset_score_type: {
                            type: 'pieChart',
                            route: 'dashboard.user.assets.manage-assets.list',
                            options: {
                                valueFormat: function (value) {
                                    return d3.format(',.0d')(value);
                                }
                            }
                        }
                    },

                    selectedType = typeMetadata[$scope.model.type];

                    if (typeof selectedType !== 'undefined') {
                        if (selectedType.type === 'pieChart') {
                            var totalValue = 0;
                            var chartScore = 0;
                            var chartTitle = 0;

                            if ($scope.model.route.indexOf('dashboard-statistics-asset-score-type-') === 0) {
                                chartTitle = '';
                            } else {
                                lodash.each($scope.model.data, function (element) {
                                    if (element.key === 'job_reactive_overdue_non_statutory_attendance'
                                        || element.key === 'job_reactive_overdue_statutory_attendance'
                                        || element.key === 'job_planned_overdue_non_statutory_completion'
                                        || element.key === 'job_planned_overdue_statutory_completion'
                                        || element.key === 'job_reactive_overdue_statutory_completion'
                                        || element.key === 'job_reactive_overdue_non_statutory_completion'
                                    ) {
                                        chartScore += parseInt(element.value);
                                    }
                                    totalValue += parseInt(element.value);
                                });

                                // calculate percentage, round value
                                chartTitle = Math.round(parseFloat(chartScore / totalValue * 100)) + '%';
                            }

                            $scope.options = {
                                chart: {
                                    type: 'pieChart',
                                    x: function(data) {
                                        if ($scope.model.route.indexOf('dashboard-statistics-asset-score-type-') === 0) {
                                            return data.key;
                                        } else {
                                            return $translate.instant('LEGEND_' + data.key.toUpperCase());
                                        }
                                    },
                                    y: function(data) {
                                        return data.value;
                                    },
                                    height: 240,
                                    donut: true,
                                    title: chartTitle,
                                    noData: 'No Data!',
                                    showLabels: false,
                                    showLegend: false,
                                    donutRatio: 0.6,
                                    color: function(data, index) {
                                        if (data.hasOwnProperty('colour')) {
                                            return '#' + data.colour;
                                        } else {
                                            return $scope.colors[index];
                                        }
                                    },
                                    pie: {
                                        dispatch: {
                                            'elementClick': function (element) {
                                                if (selectedType.route.indexOf('dashboard.user.assets.manage-assets.list') > -1) {
                                                    var soIds = [];
                                                    soIds[0] = element.data.scoreOptionId;
                                                    var assetListParams = JSON.stringify({
                                                        st: { i: $scope.model.customData.scoreTypeId, v: $scope.model.customData.scoreTypeName },
                                                        so: { o: [{ value: element.data.key, href: element.data.scoreOptionId }], i: soIds }
                                                    });
                                                    switchSites(null, selectedType.route, {assetScore: assetListParams});
                                                } else {
                                                    switchSites(null, selectedType.route, selectedType.parameters[element.index]);
                                                }
                                            },
                                            renderEnd: function () {
                                                var wrapper = element.find('.pie-chart-wrapper');
                                                if (!wrapper.data('adjusted-height')) {
                                                    var height = wrapper.height(),
                                                        adjust = Math.ceil(height * 0.12);
                                                    wrapper
                                                        .css({
                                                            height: height - adjust + 'px',
                                                            'margin-top': '-' + adjust + 'px'
                                                        })
                                                        .data('adjusted-height', true);
                                                }
                                            }
                                        }
                                    }
                                }
                            };

                        } else if (selectedType.type === 'stacked') {
                            $scope.options = {
                                chart: {
                                    type: 'multiBarChart',
                                    height: 230,
                                    margin: {
                                        top: 20,
                                        right: 0,
                                        bottom: 50,
                                        left: 35
                                    },
                                    stacked: true,
                                    showControls: false,
                                    yAxis: {
                                        tickFormat: function(d) {
                                            return d3.format(',.0d')(d);
                                        }
                                    },
                                    xAxis: {
                                        tickFormat: function(label) {
                                            return moment(label, 'MM/YYYY').format('MMM');
                                        }
                                    },
                                    reduceXTicks: false,
                                    showLegend: false,
                                    multibar: {
                                        dispatch: {
                                            elementClick: function(element) {
                                                var startDate = moment(element.data.x, 'MM/YYYY').startOf('month').toDate().toISOString();
                                                var endDate = moment(element.data.x, 'MM/YYYY').endOf('month').toDate().toISOString();

                                                var params = {
                                                    actionsDateDueStart: startDate,
                                                    actionsDateDueEnd: endDate,
                                                    actionsPriorityName: element.data.key,
                                                    actionsStatus: ['open']
                                                };

                                                switchSites(null, 'dashboard.user.actions.manage-actions.list', params);
                                            },
                                            renderEnd: function () {
                                                var wrapper = element.find('.multi-bar-chart-wrapper');
                                                if (!wrapper.data('adjusted-height')) {
                                                    var height = wrapper.height(),
                                                        adjust = Math.ceil(height * 0.12);
                                                    wrapper
                                                        .css({
                                                            height: height - adjust + 'px'
                                                        })
                                                        .data('adjusted-height', true);
                                                }
                                            }
                                        }
                                    }
                                }
                            };
                        } else if (selectedType.type === 'multipleEntitiesChart') {
                            $scope.options = {
                                chart: {
                                    reduceXTicks: false,
                                    type: 'multiBarChart',
                                    height: 230,
                                    margin: {
                                        top: 20,
                                        right: 0,
                                        bottom: 50,
                                        left: 35
                                    },
                                    showLegend: false,
                                    showControls: false,
                                    yAxis: {
                                        tickFormat: function(d) {
                                            return d3.format(',.f')(d);
                                        }
                                    },
                                    xAxis: {
                                        tickFormat: function(label) {
                                            return label;
                                        }
                                    },
                                    multibar: {
                                        dispatch: {
                                            elementClick: function(element) {
                                                var params = null;
                                                if (element.data.x === 'Actions') {
                                                    params = {
                                                        actionsRemedial: true,
                                                        actionsStatus: ['open']
                                                    };

                                                    switchSites(null, 'dashboard.user.actions.manage-actions.list', params);
                                                } else if (element.data.x === 'Quotes') {
                                                    params = {
                                                        quoteRemedial: true,
                                                        quoteStatus: [
                                                            'requested',
                                                            'submitted',
                                                            'abstained',
                                                            'pending',
                                                            'information_requested',
                                                            'extension_requested',
                                                            'value_pending_approval'
                                                        ]
                                                    };

                                                    switchSites(null, 'dashboard.user.quote-requests.list', params);
                                                } else if (element.data.x === 'Jobs') {
                                                    params = {
                                                        jobsRemedial: true,
                                                        jobStatus: [
                                                            'created',
                                                            'approval_pending',
                                                            'assignment_pending',
                                                            'assignment_proposal',
                                                            'assignment_rejected',
                                                            'operative_assignment_rejected',
                                                            'acknowledged',
                                                            'commenced',
                                                            'awaiting_paperwork',
                                                            'paperwork_rejected',
                                                            'extension_pending'
                                                        ]
                                                    };

                                                    switchSites(null, 'dashboard.user.jobs.list', params);
                                                }
                                            },
                                            renderEnd: function () {
                                                var wrapper = element.find('.multi-bar-chart-wrapper');
                                                if (!wrapper.data('adjusted-height')) {
                                                    var height = wrapper.height(),
                                                        adjust = Math.ceil(height * 0.12);
                                                    wrapper
                                                        .css({
                                                            height: height - adjust + 'px'
                                                        })
                                                        .data('adjusted-height', true);
                                                }
                                            }
                                        }
                                    }
                                }
                            };
                        }

                        if (selectedType.type === 'pieChart') {
                           $scope.options.chart = angular.extend({}, $scope.options.chart, selectedType.options);
                        }
                    }

                    if ($scope.model.type === "open_flagged_priority_jobs" && $scope.model.data.length === 0) {
                        $scope.model.type = null;
                    }

                    $scope.loading = false;
                }

                /**
                 * Start at elogbooks green. Go from yellow to red.
                 *
                 * At 0, a normal green color is chosen
                 * At 1, an orange color is chosen
                 * Between 1 and 50 it transitions from yellow to red
                 * At 50 or higher, a red color is chosen
                 */
                function getColor(value) {
                    value = parseInt(value);

                    if (value === 0 || isNaN(value)) {
                        return 'hsl(166, 63%, 36%)';
                    }

                    // Ensure we only have a max value of 50
                    value = value > 50 ? 50 : value;

                    // Choose the two hues to gradient between
                    var color1 = 40;
                    var color2 = 0;

                    // Generate the hue depending on the value
                    var hue = ((value / 50) * (color2 - color1)) + color1;

                    // Return an HSL value
                    return 'hsl(' + hue + ', 50%, 50%)';
                }
            }
        };
    }
})();
