(function () {
    'use strict';

    angular
        .module('elogbooksServices')
        .service('eventFeed', EventFeed);

    EventFeed.$inject = ['$rootScope'];

    function EventFeed ($rootScope) {

        var self = this;
            self.events = [];
            self.messages = [];
            self.files = [];
            self.feed = [];

            self.messageCollectionResponse;
            self.eventCollectionResponse;
            self.fileCollectionResponse;

            self.objectWeights = { 'event': 10, 'message': 5, 'file': 15 };
            self.setEventCollectionResponse = setEventCollectionResponse;
            self.setMessageCollectionResponse = setMessageCollectionResponse;
            self.setFileCollectionResponse = setFileCollectionResponse;
            self.setObjectWeights = setObjectWeights;
            self.getFeed = getFeed;
            self.sortFeed = sortFeed;
            self.extract = extract;
            self.removeEvents = removeEvents;

        function setEventCollectionResponse (eventCollectionResponse) {
            if (eventCollectionResponse) {
                self.eventCollectionResponse = eventCollectionResponse;
                self.events = eventCollectionResponse.events;
            }
        }

        function setMessageCollectionResponse (messageCollectionResponse) {
            if (messageCollectionResponse) {
                self.messageCollectionResponse = messageCollectionResponse;
                self.messages = messageCollectionResponse.messages;
            }
        }

        function setFileCollectionResponse (fileCollectionResponse) {
            self.fileCollectionResponse = undefined;
            self.files = [];

            if (fileCollectionResponse) {
                self.fileCollectionResponse = fileCollectionResponse;
                self.files = fileCollectionResponse.files;
            }
        }

        function setObjectWeights (objectWeights) {
            self.objectWeights = objectWeights;
        }

        function getFeed(callback) {
            getRemainingEvents('eventCollectionResponse', callback);
            getRemainingEvents('messageCollectionResponse', callback);
        }

        function sortFeed () {
            self.feed = [];
            if (self.events) {
                for (var i = self.events.length - 1; i >= 0; i--) {
                    self.events[i].object = 'event';
                    // self.events[i].sequence = i;
                    self.feed.push(self.events[i]);
                }
            }
            if (self.messages) {
                for (var i = self.messages.length - 1; i >= 0; i--) {
                    self.messages[i].object = 'message';
                    self.messages[i].sequence = i;
                    self.feed.push(self.messages[i]);
                }
            }
            if (self.files) {
                for (var i = self.files.length - 1; i >= 0; i--) {
                    self.files[i].object = 'file';
                    self.files[i].sequence = i;
                    self.feed.push(self.files[i]);
                }
            }

            self.feed.sort(function (a, b) {
                // These checks must be in this order and cannot be condensed in to one if-elseif block
                // as the different conditions (date, weight, sequence) needs to be compared in order
                if (a.createdAt < b.createdAt) {
                    return 1;
                } else if (a.createdAt > b.createdAt) {
                    return -1;
                } else if (self.objectWeights[a.object] > self.objectWeights[b.object]) {
                    return -1;
                } else if (self.objectWeights[a.object] < self.objectWeights[b.object]) {
                    return 1;
                } else if (a.sequence > b.sequence) {
                    return -1;
                } else if (a.sequence < b.sequence) {
                    return 1;
                }

                return 0;
            });
        }

        function getRemainingEvents(entity, callback) {
            if (self[entity] && self[entity].count > 0) {
                var update = function () {
                    if (self[entity].page < self[entity].pages)
                    {
                        self.moreEventsToLoad = true;
                        self[entity].more().then(update);
                    } else {
                        $rootScope.$broadcast('eventsLoaded', null);
                        self.moreEventsToLoad = false;
                    }
                    self.sortFeed();
                    callback(self.feed, self.moreEventsToLoad);
                };
                update();
            } else {
                self.sortFeed();
                callback(self.feed, false);
            }
        }

        function extract (feed, type) {
            var extracted = [];

            angular.forEach(feed, function (value, index) {
                if (value.object === type) {
                    extracted.push(value);
                }
            });

            return extracted;
        }

        function removeEvents(feed) {
            var newFeed = [];
            angular.forEach(feed, function (value, index) {
                if (value.object === 'event' && value.type.indexOf('message_created') !== -1) {
                    // do nothing
                } else {
                    newFeed.push(value);
                }
            });

            self.feed = newFeed;

            return self.feed;
        }

        return this;
    }
})();
