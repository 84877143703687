(function () {
    'use strict';

    angular
        .module('elogbooks.admin.service-providers')
        .controller('ServiceProviderInfoController', ServiceProviderInfoController);

    ServiceProviderInfoController.$inject = ['serviceProviderResponse', 'modulesService'];

    function ServiceProviderInfoController (serviceProviderResponse, modulesService) {
        var vm = this;
        vm.serviceProvider = serviceProviderResponse;
        vm.p2pEnabled = modulesService.isEnabled('p2p');
        vm.safecontractorEnabled = modulesService.isEnabled('safecontractor');

        if (vm.serviceProvider.ppmReminderAlertOption === 'statutory') {
            vm.ppmReminderAlertText = 'PPM_REMINDER_STATUTORY_ONLY';
        } else if (vm.serviceProvider.ppmReminderAlertOption === 'all') {
            vm.ppmReminderAlertText = 'PPM_REMINDER_ALL';
        } else {
            vm.ppmReminderAlertText = 'PPM_REMINER_DISABLED';
        }
    }
})();
