(function() {
    'use strict';

    angular
        .module('elogbooks.admin.timesheet-types')
        .controller('TimesheetTypesController', TimesheetTypesController);

    TimesheetTypesController.$inject = ['$state', '$stateParams', 'timesheetTypesCollectionResponse'];

    function TimesheetTypesController($state, $stateParams, timesheetTypesCollectionResponse) {

        var vm = this;
        vm.timesheetTypes = timesheetTypesCollectionResponse.timesheettypes;
        vm.timesheetTypesCollectionResponse = timesheetTypesCollectionResponse;
        vm.criteria = {
            id: {type: 'number', value: $stateParams.id ? parseInt($stateParams.id, 10) : null, title: 'ID', min: 1},
            name: { type: 'text', value: $stateParams.name ? $stateParams.name : null, title: "NAME" }
        };
        vm.order = orderAction;
        vm.search = searchAction;

        function searchAction(params) {
            var override = {
                page: 1
            };

            $state.go('.', angular.extend({}, params, override), {reload: $state.current});
        }

        function orderAction(key) {
            $stateParams.order = key;

            $state.go('.', angular.extend({}, $stateParams), {reload: $state.current});
        }
    }
})();
