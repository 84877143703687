(function () {
    'use strict';

    angular
        .module('elogbooks.common.site-contacts')
        .controller('CommonSiteContactsDetailsController', CommonSiteContactsDetailsController);

    CommonSiteContactsDetailsController.$inject = ['contactResponse', 'apiClient', 'contactInformationResponse', 'lodash', 'config'];

    function CommonSiteContactsDetailsController (contactResponse, apiClient, contactInformationResponse, lodash, config) {
        var vm = this;
        vm.contact = contactResponse;
        vm.contactInformation = contactInformationResponse;
        vm.addresses = vm.contactInformation.addresses;
        vm.contactInfoCollection = [];
        vm.config = config;

        if (lodash.has(vm.contact, '_links.user')) {
            // Never allow a user's contactinformation to be edited from a contact page
            vm.editContact = false;
        } else if (lodash.has(vm.contact, '_links.edit-contactinformation')) {
            vm.editContact = vm.contact.getLink('edit-contactinformation');
        } else {
            vm.editContact = false;
        }

        angular.forEach(vm.contactInformation.fields, function(contact, key) {
            var siteContact = {
                'value': contact.value,
                'notes': contact.notes !== undefined ? contact.notes : null
            };
            var type = lodash.find(vm.contactInfoCollection, function(element) { return element.type ===  contact.type; });

            if ( type !== undefined) {
                type.siteContacts.push(siteContact);
            }
            else {
                type = {'type' : contact.type, 'siteContacts' : []};
                type.siteContacts.push(siteContact);
                vm.contactInfoCollection.push(type);
            }
        });
    }
})();
