(function() {
    'use strict';

    angular
        .module('elogbooks')
        .config(Translations);

    function Translations($translateProvider) {
        $translateProvider.translations('en-gb', {
            CONTRACT: 'Contract',
            CONTRACTS: 'Contracts',
            CONTRACTS_LIST: 'Contracts',
            CONTRACT_AVAILABLE: 'Contract Available?',
            CONTRACT_BOTH: 'Both (Electronic and Hardcopy)',
            CONTRACT_ELECTRONIC: 'Electronic',
            CONTRACT_FORMAT: 'Contract Format',
            CONTRACT_HARDCOPY: 'Hardcopy',
            CONTRACT_TYPE: 'Contract Type',
            NOTICE_PERIOD: 'Notice Period',
            SCOPE_AVAILABLE: 'Scope Available?',
            BILLING_FREQUENCY: 'Billing Frequency',
            BILLING_PRACTICE: 'Billing Practice',
            BILLING_PRACTICE_IN_ADVANCE: 'In Advance',
            BILLING_PRACTICE_IN_ARREARS: 'In Arrears',

            CONTRACT_CREATED: 'Contract created',
            CONTRACT_SUBMITTED_AND_CREATED: 'Contract created and submitted for approval',
            CONTRACT_SUBMITTED_AND_UPDATED: 'Contract updated and submitted for approval',

            PENDING: 'Pending',
            ACCEPTED: 'Accepted',
            REJECTED: 'Rejected',

            CONTRACT_APPROVAL_REQUESTED: 'Contract Request',

            CONTRACTS_ADD: 'Add Contract',
            CONTRACT_ADD: 'Add Contract',
            CONTRACT_EDIT: 'Edit Contract',
            CONTRACT_RENEW: 'Renew Contract',

            CONTRACT_UPDATED: 'Contract Updated',

            CONTRACT_VALUE: 'Value',
            LAST_TENDERED: 'Last Tendered',
            REVIEW_REMINDER: 'Review Reminder',
            REVIEW_REMINDERS: 'Review Reminders',
            EXPIRY_REMINDER: 'Expires reminder',

            CONTRACT_TYPE_CLIENT: 'Client',
            CONTRACT_TYPE_CONSULTANT: 'Consultant',
            CONTRACT_TYPE_FRAMEWORK: 'Framework',
            CONTRACT_TYPE_PSA: 'PSA',
            CONTRACT_TYPE_SUPPLIER: 'Supplier',
            CONTRACT_TYPE_OTHER: 'Other',

            CONTRACT_DETAILS_WITH_ID: 'Contract #{{ ::id }} details',
            CONTRACT_DETAILS: 'Contract Details',
            CONTRACT_STATUS: 'Status',

            CONTRACT_STATUS_DRAFT: 'Draft',
            CONTRACT_STATUS_APPROVAL_PENDING: 'Pending Approval',
            CONTRACT_STATUS_RENEWAL_APPROVAL_PENDING: 'Pending Approval Renewal',
            CONTRACT_STATUS_RENEWAL_APPROVAL_REJECTED: 'Rejected Renewal',
            CONTRACT_STATUS_CANCELLED: 'Cancelled',
            CONTRACT_STATUS_TERMINATED: 'Terminated',
            CONTRACT_STATUS_ACTIVE: 'Active',
            CONTRACT_STATUS_APPROVAL_REJECTED: 'Rejected',
            CONTRACT_STATUS_DRAFT_IN_RENEWAL: 'Draft In Renewal',
            CONTRACT_STATUS_EXPIRED: 'Expired',

            TERMINATE: 'Terminate',
            RENEW: 'Renew',
            REGRESS: 'Regress',

            IS_PDF: 'PDF Copy Of Contract',
            IS_CONTRACT: 'Is Contract',

            CONTRACT_WORKFLOW_SUBMIT: 'Submit',
            CONTRACT_WORKFLOW_SUBMIT_SUBMIT: 'Submit',
            CONTRACT_SUBMIT: 'Submit Contract',
            CONTRACT_WORKFLOW_SUBMIT_SUCCESS: 'Contract Submitted',

            CONTRACT_WORKFLOW_CANCEL: 'Cancel',
            CONTRACT_WORKFLOW_CANCEL_SUBMIT: 'Cancel',
            CONTRACT_CANCEL: 'Cancel Contract',
            CONTRACT_WORKFLOW_CANCEL_SUCCESS: 'Contract Cancelled',

            CONTRACT_WORKFLOW_TERMINATE: 'Terminate',
            CONTRACT_WORKFLOW_TERMINATE_SUBMIT: 'Terminate',
            CONTRACT_TERMINATE: 'Terminate Contract',
            CONTRACT_WORKFLOW_TERMINATE_SUCCESS: 'Contract Terminated',

            CONTRACT_WORKFLOW_APPROVE: 'Approve',
            CONTRACT_WORKFLOW_APPROVE_SUBMIT: 'Approve',
            CONTRACT_APPROVE: 'Approve Contract',
            CONTRACT_WORKFLOW_APPROVE_SUCCESS: 'Contract Approved',

            CONTRACT_WORKFLOW_REJECT: 'Reject',
            CONTRACT_WORKFLOW_REJECT_SUBMIT: 'Reject',
            CONTRACT_REJECT: 'Reject Contract',
            CONTRACT_WORKFLOW_REJECT_SUCCESS: 'Contract Rejected',

            CONTRACT_WORKFLOW_REGRESS: 'Regress',
            CONTRACT_WORKFLOW_REGRESS_SUBMIT: 'Regress',
            CONTRACT_REGRESS: 'Regress Contract',
            CONTRACT_WORKFLOW_REGRESS_SUCCESS: 'Regress Rejected',

            CONTRACT_APPROVER_REQUIRED_BUT_NO_APPROVERS: 'Contract Approval is required, but no Contract Approvers are currently assigned to the site',

            TERMINATED_AT: 'Terminated At',
            BEFOREPERIOD: 'Reminder (In days)',
            ASSOCIATETYPES: 'Site Associate Types',

            REVIEW: 'REVIEW',
            EXPIRY: 'EXPIRY',
            REVIEW_IN: 'Review In',
            EXPIRY_IN: 'Expires In',

            CONTRACT_SETTINGS_REVIEW_VIEW: 'Review Settings',
            CONTRACT_SETTINGS_EXPIRY_VIEW: 'Expiry Settings',
            REVIEW_SETTINGS_TYPE_REVIEW_ADD: 'Add New Review Setting',
            REVIEW_SETTINGS_TYPE_EXPIRY_ADD: 'Add New Expiry Setting',
            REVIEW_SETTINGS_TYPE_REVIEW_EDIT: 'Edit Review Setting',
            REVIEW_SETTINGS_TYPE_EXPIRY_EDIT: 'Edit Expiry Setting',

            REVIEW_SETTING_CREATED: 'Review Setting Created',
            EXPIRY_SETTING_CREATED: 'Expiry Setting Created',
            REVIEW_SETTING_REMOVED: 'Review Setting Removed',
            EXPIRY_SETTING_REMOVED: 'Expiry Setting Removed',
            REVIEW_SETTING_UPDATED: 'Review Setting Updated',
            EXPIRY_SETTING_UPDATED: 'Expiry Setting Updated',
            REVIEW_SETTINGS_REORDERED: 'Review Settings Reordered',
            EXPIRY_SETTINGS_REORDERED: 'Expiry Settings Reordered',

            CONTRACT_CHASE_SETTINGS_TYPE2_ADD: 'Expired Contract Chase Add',
            CONTRACT_CHASE_SETTINGS_TYPE3_ADD: 'Contract Approval Chase Add',
            CONTRACT_CHASE_SETTINGS_TYPE2_EDIT: 'Expired Contract Chase Edit',
            CONTRACT_CHASE_SETTINGS_TYPE3_EDIT: 'Contract Approval Chase Edit',

            CONTRACT_APPROVALS:'Contract Approvals',
            CONTRACT_RENEWALS:'Contract Renewals',
            CONTRACT_TIMELINES:'Contract Timelines',

            AWAITING_APPROVAL: 'Awaiting Approval',
            AWAITING_RENEWAL_APPROVAL: 'Awaiting Renewal App.',
            RENEWAL_REJECTED: 'Renewal Rejected',
            APPROVAL_REJECTED: 'Approval Rejected',
            APPROACHING_REVIEW: 'Approaching Review',
            APPROACHING_EXPIRY: 'Approaching Expiry',
            OVERDUE_APPROVAL: 'Overdue Approval',

            CONTRACT_DOCUMENT: 'Contract Document',
            CONTRACT_DOCUMENT_NAME: 'Contract Document Name',

            VERSIONS: 'Versions',
            COMPARE_VERSIONS_LIST: 'Compare versions',
            AUDIT_TRAIL: 'Audit Trail',
            VERSION_ID: 'Version ID',
            COMPARE: 'Compare',
            CURRENT_CONTRACT_VERSION: 'Current contract version',
            CONTRACT_VERSION_WITH_ID: 'Contract version #{{ ::id }}',
            CONTRACT_FILE_VIEW: 'Contract file',
            CONTRACT_SUPPORT_FILES_VIEW: 'Contract supporting files',

            CONTRACT_STARTED_AT: 'Contract Start'
        });
    }
})();
