app.config(function ($translateProvider) {
    $translateProvider.translations('zh-hk', {
        SERVICE_TYPES: '服務類別',
        SERVICE_TYPES_LIST: '服務類別',
        SERVICE_TYPES_ADD: '新增服務類別',
        SERVICE_TYPES_EDIT: '修改服務類別',
        SERVICE_TYPES_UPDATED: '服務類別已更新',

        SERVICE_TYPE: '服務類別',
        SERVICE_TYPE_LIST: '服務類別',
        SERVICE_TYPE_ADD: '新增服務類別',
        SERVICE_TYPE_EDIT: '修改服務類別',
        SERVICE_TYPE_ADDED: '服務類別已增加',
        SERVICE_TYPE_UPDATED: '服務類別已更新',
        SERVICE_TYPE_DELETED: '服務類別已刪除'
    });
});
