(function () {
    'use strict';

    angular
        .module('elogbooks.user.surveys')
        .controller('SurveyRequestDetailsController', SurveyRequestDetailsController);

    SurveyRequestDetailsController.$inject = [
        '$state',
        'surveyService',
        'surveyRequestResponse',
        'surveyRequestLinesResponse',
        'apiClient',
        'messenger',
        '$stateParams',
        'config',
        'lodash'
    ];

    function SurveyRequestDetailsController(
        $state,
        surveyService,
        surveyRequestResponse,
        surveyRequestLinesResponse,
        apiClient,
        messenger,
        $stateParams,
        config,
        lodash
    ) {
        var vm = this;
        vm.surveyRequest = surveyRequestResponse.getData();
        vm.surveyRequestLines = [];
        vm.surveyRequestStats = [];
        vm.reopen = reopen;

        for (var line in surveyRequestLinesResponse.getData().lines) {
            if (!surveyRequestLinesResponse.getData().lines.hasOwnProperty(line)) {
                continue;
            }
            var item = surveyRequestLinesResponse.getData().lines[line];

            if (item._embedded.surveyline) {
                var scoreRange = item._embedded.surveyline.scoreRange;

                item.scoreLabel = surveyService.labelForScore(item.score, scoreRange);
                item.spScoreLabel = surveyService.labelForScore(item.spScore, scoreRange);
                item.options = surveyService.optionsForScoreRange(scoreRange);
                item.orderBy = parseInt(item._embedded.surveyline.displayOrder);

                vm.surveyRequestLines.push(item);
            }

            if (item._embedded.surveystat) {
                vm.surveyRequestStats.push(item);
            }
        }

        vm.surveyRequestLines = lodash.orderBy(vm.surveyRequestLines, ['orderBy', 'asc'])

        vm.site = vm.surveyRequest._links.site;
        vm.currentTotal = ((vm.surveyRequest.weightedScore) ? parseInt(vm.surveyRequest.weightedScore) : 0);
        vm.maxTotal = parseInt(vm.surveyRequest.targetScore);

        vm.noBorder = (($state.current.name == "dashboard.user.sites.list.details.surveys.active.details") ? true : false);

        function reopen(status) {
            $stateParams.surveyStatus = ['active', 'pending'];

            if (vm.surveyRequest.getLink('reopen')) {
                apiClient.update(vm.surveyRequest.getLink('reopen'), {newStatus: status}).then(function (response) {
                    var route = 'dashboard.user.surveys.list';
                    if (config.site) {
                        route = 'dashboard.user.sites.list.details.surveys.active';
                    }

                    if (response) {
                        $state.go(route, $stateParams, { reload: true }).then(function () {
                            messenger.success('SURVEY_REOPENED');
                        });
                    }

                    $state.go(route, $stateParams, { reload: true }).then(function () {
                        messenger.error('REQUEST_ERROR');
                    });
                });
            }
        }
    }
})();
