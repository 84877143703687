(function () {
    'use strict';

    angular
        .module('elogbooks.user.sites')
        .controller('UserSitesAssociatesController', UserSitesAssociatesController);

    UserSitesAssociatesController.$inject = [
        'approversResponse',
        'lodash',
        'userManager',
        'quoteThresholdsCollectionResponse',
        'thresholdService',
        'siteAssociatesService',
        'siteResponse',
        'rootResourceResponse',
        '$scope'
    ];

    function UserSitesAssociatesController(
        approversResponse,
        lodash,
        userManager,
        quoteThresholdsCollectionResponse,
        thresholdService,
        siteAssociatesService,
        siteResponse,
        rootResourceResponse,
        $scope
    ) {
        var vm = this;
        vm.approvers = approversResponse;
        vm.associatesCollectionLoading = true;
        vm.associatesCollection = siteAssociatesService.getSiteAssociates(siteResponse, rootResourceResponse, {limit: 999});
        vm.canEditAssociates = userManager.hasPermission('SITE_PERMISSION_EDIT_ASSOCIATES');
        vm.canEditApprovers = userManager.hasPermission('SITE_PERMISSION_EDIT_APPROVERS');

        vm.quoteThresholds = [];

        if (lodash.has(quoteThresholdsCollectionResponse, 'quoteThresholds') ) {
            vm.quoteThresholds = quoteThresholdsCollectionResponse.quoteThresholds;
            thresholdService.initThresholds(vm.quoteThresholds);
            vm.getQuoteApproverThreshold = thresholdService.getQuoteApproverThreshold;
        }

        $scope.$watch('vm.associatesCollection.length', function(newVal, oldVal) {
            if(newVal !== oldVal && newVal !== 0) {
                vm.associatesCollectionLoading = false;
            }
        }, true);
    }
})();
