
app.config(function ($translateProvider) {
    $translateProvider.translations('zh-hk', {
        /* dashboard.helpdesk.satisfactionsurveys */
        SATISFACTIONSURVEYS: '滿意度調查',
        SATISFACTIONSURVEY: '滿意度調查',
        SATISFACTIONSURVEY_JOBID: '工作ID',
        SATISFACTIONSURVEY_ISCOMPLETE: '已完成?',
        SATISFACTIONSURVEY_REFUSED: '拒絕調查?',

        /* dashboard.helpdesk.satisfactionsurvey.edit */
        SATISFACTIONSURVEY_EDIT: '修改滿意度調查',
        SATISFACTIONSURVEY_CONTACT_NAME: '聯繫人姓名',
        SATISFACTIONSURVEY_CONTACT_PHONE: '聯繫電話',
        SATISFACTIONSURVEY_CONTACT_EMAIL: '聯繫電子郵件',
        SATISFACTIONSURVEY_COMMENTS: '附加評論/建議',
        //SATISFACTIONSURVEY_REFUSED: 'Survey Refused?', // Listed above
        SATISFACTIONSURVEY_QUESTION: '問題',
        SATISFACTIONSURVEY_RESPONSE: '回應',

        /* dashboard.admin.satisfactionsurveylines */
        SATISFACTIONSURVEY_QUESTIONS: '滿意度調查問題',
        SATISFACTIONSURVEY_BUTTON_ADD_QUESTION: '新增問題',

        /* dashboard.helpdesk.satisfactionsurveylines.addedit */
        SATISFACTIONSURVEY_ADD_QUESTION: '滿意度調查 - 新增問題',
        SATISFACTIONSURVEY_EDIT_QUESTION: '滿意度調查 - 修改問題',

        SATISFACTIONSURVEY_FILTER_INCOMPLETEREQUIRESATTENTION: '未完成 - 需要注意',
        SATISFACTIONSURVEY_FILTER_INCOMPLETENOATTENTION: '未完成 - 等待工作完成',
        SATISFACTIONSURVEY_FILTER_COMPLETE: '完成'
    });
});
