/**
 * Page title
 */
angular.module('elogbooksDirectives').directive('pageTitle', ['$http', 'apiClient', '$state', 'iconService', function ($http, apiClient, $state, iconService) {
    return {
        restrict: 'AE',
        replace: true,
        transclude: true,
        templateUrl: '/modules/directives/layout/page-title/page-title.html',
        link: function($scope, $element, $attrs) {
            if (typeof $attrs.titleIcon !== 'undefined') {
                $attrs.titleIcon = iconService.getMap($attrs.titleIcon);
            }
        }
    };
}]);
