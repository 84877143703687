
(function () {
    'use strict'

    angular
        .module('elogbooks.admin.trade-types')
        .config(registerRoutes);

    function registerRoutes($stateProvider) {

        $stateProvider
            .state('dashboard.admin.tradetypes', {
                url: '/tradetypes?id&name&description',
                resolve: {
                    tradeTypeCollectionResponse: function ($stateParams, apiClient, rootResourceResponse) {
                        return apiClient.getPage(rootResourceResponse.getLink('tradetypes'), $stateParams);
                    }
                },
                params: {
                    page: "1",
                    limit: "30"
                },
                templateUrl: '/modules/admin/trade-types/tradetype-list.html',
                controller: 'TradeTypesController',
                controllerAs: 'vm',
                ncyBreadcrumb: {
                    label: '{{ ::"TRADE_TYPES" | translate }}'
                },
                data: {
                    permissions: ['user_permission_view_tradetypes']
                }
            })
            .state('dashboard.admin.tradetypes.add', {
                url: '/add',
                parent: 'dashboard.admin.tradetypes',
                templateUrl: '/modules/admin/trade-types/add/tradetype-add.html',
                controller: 'TradeTypesAddController',
                controllerAs: 'vm',
                ncyBreadcrumb: {
                    label: '{{ ::"ADD" | translate }}'
                }
            })
            .state('dashboard.admin.tradetypes.details', {
                url: '/details/{resource}',
                parent: 'dashboard.admin.tradetypes',
                templateUrl: '/modules/admin/trade-types/details/tradetype-details.html',
                controller: 'TradeTypesDetailsController',
                controllerAs: 'vm',
                resolve: {
                    tradeTypeResponse: function (apiClient, base64, $stateParams) {
                        return apiClient.get($stateParams.resource.decode());
                    }
                },
                ncyBreadcrumb: {
                    label: '{{ ::"DETAILS" | translate }}'
                }
            })
            .state('dashboard.admin.tradetypes.details.edit', {
                url: '/edit/',
                parent: 'dashboard.admin.tradetypes.details',
                views: {
                    "@dashboard": {
                        template: ""
                    },
                    "forms@dashboard": {
                        templateUrl: '/modules/admin/trade-types/details/edit/tradetype-edit.html',
                        controller: 'TradeTypesEditController',
                        controllerAs: 'vm',
                    }
                },
                ncyBreadcrumb: {
                    label: '{{ ::"EDIT" | translate }}'
                }
            });
    };

})();
