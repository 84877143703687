angular.module('elogbooksServices').service('analyticsService', ['$location', '$rootScope', function ($location, $rootScope) {

    var origin = window.location.origin;

    this.env = !!origin.match('sandpit|prerelease') ?
        'staging' : !!origin.match('testing') ?
        'testing' : !!origin.match('local') ? 'development': 'production';

    function getTime() {
        return new Date().getTime();
    }

    function isAllowed() {
        return !!this.env.match('production');
    }

    function pageLoaded() {
        $rootScope.$broadcast('analytics.pageLoaded', {
            time: this.getTime()
        });
    }

    function trackEvent(additionalData = {}) {
        var data = angular.merge(this.getEventData(), additionalData);

        // So we only send one event for page speed tracking
        if (!data.pageLoadSpeed && this.isPageSpeedTracking()) {
            return;
        }

        if(!this.isPageSpeedTracking()) {
            data.pageLoadSpeed = null;
        }

        window.dataLayer = window.dataLayer || [];
        window.dataLayer.push(data);
    }

    function getEventData() {
        var pageTitle = $rootScope.currentState;

        return {
            'event': 'virtualPageView',
            'pageTitle': document.title + ' - ' + pageTitle,
            'pageUrl': $location.absUrl()
        };
    }

    function isPageSpeedTracking() {
        const pageSpeedTrackingUrls = [
            /\/helpdesk\/dashboard/,
            /\/user\/jobs$/,    
            /\/user\/quoterequests/,
            /\/user\/sites\/list$/,
            /\/user\/jobs\/[^\/]+\/links-to/,
            /\/admin\/users$/,
            /\/user\/service-providers\/service-providers$/,
            /^\/user\/files\//,
            /\/(files|job-attachments|quote-attachments|all-files|document-compliance|audit-reports)$/,
            /\/(site|job)-files$/
        ];

        return pageSpeedTrackingUrls.some(path => $location.path().match(path));
    }

    this.pageLoaded = pageLoaded;
    this.getTime = getTime;
    this.trackEvent = trackEvent;
    this.getEventData = getEventData;
    this.isPageSpeedTracking = isPageSpeedTracking;
    this.isAllowed = isAllowed;
}]);
