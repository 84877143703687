(function () {
    'use strict';

    angular
        .module('elogbooks.admin.service-providers')
        .controller('ServiceProviderDetailsController', ServiceProviderDetailsController);

    ServiceProviderDetailsController.$inject = ['serviceProviderResponse'];

    function ServiceProviderDetailsController (serviceProviderResponse) {
        var vm = this;
        vm.serviceProvider = serviceProviderResponse;
    }
})();
