(function() {
    'use strict';

    angular
        .module('elogbooks.admin.core-clients')
        .controller('PricingSchedulesLabourRatesAddEditController', PricingSchedulesLabourRatesAddEditController);

    PricingSchedulesLabourRatesAddEditController.$inject = [
        '$state',
        'apiClient',
        'confirmationModal',
        'messenger',
        'pricingScheduleLabourRateResponse',
        'pricingScheduleLabourRatesResponse',
        'labourTypesResponse',
        'requestDataFactory',
        'pageService',
        'crudService'
    ];

    function PricingSchedulesLabourRatesAddEditController(
        $state,
        apiClient,
        confirmationModal,
        messenger,
        pricingScheduleLabourRateResponse,
        pricingScheduleLabourRatesResponse,
        labourTypesResponse,
        requestDataFactory,
        pageService,
        crudService
    ) {
        var vm = this;
        vm.pricingScheduleLabourRatesResponse = pricingScheduleLabourRatesResponse;
        vm.labourTypesResponse = labourTypesResponse;
        vm.labourRate = pricingScheduleLabourRateResponse;

        vm.data = requestDataFactory.createRequest(vm.labourRate);

        vm.create = createAction;
        vm.update = updateAction;
        vm.delete = deleteAction;
        vm.changeLabourType = changeLabourType;

        vm.labourTypeModel = {
            required: true,
            response: vm.labourTypesResponse,
            link: vm.labourTypesResponse.getLink('self'),
            responseKeyPath: 'labourTypes',
            itemHrefPath: '_links.self.href',
            itemValuePath: 'name',
            onSelect: vm.changeLabourType,
            onRemove: vm.changeLabourType
        };

        if (vm.labourRate.id) {
            vm.labourTypeModel.selected = {
                href: vm.labourRate.getLink('self'),
                title: vm.labourRate.labourType,
                object: vm.labourRate
            };
        }

        function changeLabourType() {
            if (vm.labourTypeModel.selected && vm.labourTypeModel.selected.object) {
                vm.data._links.labourType = vm.labourTypeModel.selected.object.getLink('self');
            } else {
                vm.data._links.labourType = null;
            }
        }

        function updateAction() {
            return crudService.update(
                vm.labourRate.getLink('edit'),
                vm.data,
                'dashboard.admin.pricing-schedules.list.details.labourRates',
                'dashboard.admin.pricing-schedules.list',
                'PRICING_SCHEDULES_LABOUR'
            );
        }

        function createAction() {
            return crudService.create(
                vm.pricingScheduleLabourRatesResponse.getLink('create'),
                vm.data,
                'dashboard.admin.pricing-schedules.list.details.labourRates',
                'dashboard.admin.pricing-schedules.list',
                'PRICING_SCHEDULES_LABOUR'
            );
        }

        function deleteAction() {
            return crudService.delete(
                vm.data.getLink('delete'),
                'dashboard.admin.pricing-schedules.list.details.labourRates',
                'PRICING_SCHEDULES_LABOUR'
            );
        }
    }
})();
