(function () {
    'use strict';

    angular
        .module('elogbooks.admin.sites')
        .controller('QuoteThresholdDetailsController', QuoteThresholdDetailsController);

    QuoteThresholdDetailsController.$inject = ['thresholdResponse'];

    function QuoteThresholdDetailsController (thresholdResponse) {
        var vm = this;
    }
})();
