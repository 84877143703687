app.config(function ($translateProvider) {
    $translateProvider.translations('en-gb', {
        SECTOR: 'Sector',
        SECTORS: 'Sectors',

        SECTOR_INFORMATION: 'Sector Information',
        MANAGE_SECTOR_SITES: 'Sites in this Sector',

        ADD_NEW_SECTOR: 'Add a new Sector',
        EDIT_SECTOR: 'Edit Sector',

        SECTOR_LIST: 'Sectors',
        SECTOR_ADD: 'Add a new Sector',
        SECTOR_EDIT: 'Edit Sector',

        SECTOR_CREATED: 'Sector Created',
        SECTOR_UPDATED: 'Sector Updated',
        SECTOR_DELETED: 'Sector Deleted',

        SECTOR_SITE_LIST: 'Sites',
        SECTOR_SITE_ADDED: 'Sector Sites Updated',
        SITES_DELETED_FROM_SECTOR: 'Sector Sites Deleted',

        SECTOR_DOCUMENT_SETS_LIST: 'Sector Document Sets'
    });
});
