(function() {
    'use strict';

    angular
        .module('elogbooks.user.finance.credit-notes')
        .controller('FinanceCreditNoteDetailsController', FinanceCreditNoteDetailsController);

    FinanceCreditNoteDetailsController.$inject = [];
    function FinanceCreditNoteDetailsController() {
        var vm = this;
    }
})();
