(function () {
    'use strict';

    angular
        .module('elogbooks.admin.labour-types')
        .config(registerRoutes)
        .config(registerRoutesList)
        .config(registerRoutesDetails)
        .config(registerRoutesAdd)
        .config(registerRoutesEdit);

    function registerRoutes ($stateProvider) {
        $stateProvider
            .state('dashboard.admin.labour-types', {
                abstract: true,
                templateUrl: 'shared-templates/view.html',
                ncyBreadcrumb: {
                    skip: true
                },
                data: {
                    permissions: ['user_permission_manage_labour_types']
                }
            });
    }

    function registerRoutesList ($stateProvider) {
        $stateProvider
            .state('dashboard.admin.labour-types.list', {
                url: '/labour-types?page&limit&name',
                parent: 'dashboard.admin.labour-types',
                views: {
                    '@dashboard.admin.labour-types': {
                        templateUrl: '/modules/admin/labour-types/labour-types-list.html',
                        controller: 'LabourTypesController',
                        controllerAs: 'vm'
                    }
                },
                params: {
                    page: '1',
                    limit: '30',
                    name: null,
                },
                resolve: {
                    labourTypesCollectionResponse: function (apiClient, $stateParams, rootResourceResponse) {
                        return apiClient.get(rootResourceResponse.getLink('labourtypes'), $stateParams).then(function(response) {
                            return response || apiClient.noResourceFound();
                        });
                    }
                },
                ncyBreadcrumb: {
                    label: '{{ ::"LABOUR_TYPES" | translate }}'
                }
            });
    }

    function registerRoutesDetails($stateProvider) {
        $stateProvider
            .state('dashboard.admin.labour-types.list.details', {
                url: '/details/{resource}',
                parent: 'dashboard.admin.labour-types.list',
                views: {
                    '@dashboard.admin.labour-types': {
                        templateUrl: '/modules/admin/labour-types/details/labour-type-details.html',
                        controller: 'LabourTypeDetailsController',
                        controllerAs: 'vm'
                    }
                },
                resolve: {
                    labourTypeResponse: function(apiClient, $stateParams) {
                        return apiClient.get($stateParams.resource.decode()).then( function(response) {
                            return response || apiClient.noResourceFound();
                        });
                    }
                },
                ncyBreadcrumb: {
                    label: '{{ ::$resolves().labourTypeResponse.name }}'
                }
            });
    }

    function registerRoutesAdd($stateProvider) {
        $stateProvider
            .state('dashboard.admin.labour-types.list.add', {
                url: '/add',
                parent: 'dashboard.admin.labour-types.list',
                views: {
                    '@dashboard.admin.labour-types': {
                        templateUrl: '/modules/admin/labour-types/add-edit/labour-type-form.html',
                        controller: 'LabourTypeAddEditController',
                        controllerAs: 'vm'
                    }
                },
                resolve: {
                    labourTypeResponse: function () {
                        return {};
                    },
                },
                ncyBreadcrumb: {
                    label: '{{ ::"ADD" | translate }}'
                }
            });
    }

    function registerRoutesEdit($stateProvider) {
        $stateProvider
            .state('dashboard.admin.labour-types.list.details.edit', {
                url: '/edit/{resource}',
                parent: 'dashboard.admin.labour-types.list.details',
                views: {
                    '@dashboard.admin.labour-types': {
                        templateUrl: '/modules/admin/labour-types/add-edit/labour-type-form.html',
                        controller: 'LabourTypeAddEditController',
                        controllerAs: 'vm'
                    }
                },
                ncyBreadcrumb: {
                    label: '{{ ::"EDIT" | translate }}'
                }
            });
    }
})();
