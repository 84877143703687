(function () {
    'use strict';

    angular
        .module('elogbooksDirectives')
        .controller('userFilterCriteriaController', userFilterCriteriaController);

    userFilterCriteriaController.$inject = ['$scope'];

    function userFilterCriteriaController($scope) {
        $scope.showLoadFiltersDirective = false;
        $scope.showSaveFiltersDirective = {value: false};
        $scope.directiveLoaded = false;
        $scope.saveDirective = saveDirective;
        $scope.close = close;

        function saveDirective () {
            $scope.showSaveFiltersDirective.value = $scope.showSaveFiltersDirective.value ? false : true;
        }

        function close() {
            $scope.showLoadFiltersDirective = false;
        }
    }
})();
