(function () {
    'use strict';

    angular
        .module('elogbooksFilters')
        .filter('invoiceStatus', invoiceStatus);

    function invoiceStatus ($filter) {
        var statuses = {
            0: 'INVOICE_STATUS_DRAFT',
            'draft': 'INVOICE_STATUS_DRAFT',
            1: 'INVOICE_STATUS_IN_QUERY',
            'in_query': 'INVOICE_STATUS_IN_QUERY',
            2: 'INVOICE_STATUS_CANCELLED',
            'cancelled': 'INVOICE_STATUS_CANCELLED',
            3: 'INVOICE_STATUS_READY_FOR_PAYMENT',
            'ready_for_payment': 'INVOICE_STATUS_READY_FOR_PAYMENT',
            4: 'INVOICE_STATUS_UNPAID',
            'unpaid': 'INVOICE_STATUS_UNPAID',
            5: 'INVOICE_STATUS_PAID',
            'paid': 'INVOICE_STATUS_PAID',
            6: 'INVOICE_STATUS_INVOICED',
            'send': 'INVOICE_STATUS_INVOICED'
        }

        return function (input) {
            return $filter('translate')(statuses[input]);
        }
    }
})();
