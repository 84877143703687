(function () {
    'use strict';

    angular
        .module('elogbooks.common.jobs')
        .controller('CommonJobDetailsJobCostingController', CommonJobDetailsJobCostingController);

    CommonJobDetailsJobCostingController.$inject = [
        'jobResponse',
        '$q',
        'apiClient',
        'messenger'
    ];

    function CommonJobDetailsJobCostingController (
        jobResponse,
        $q,
        apiClient,
        messenger
    ) {
        var vm = this;
        var requests = [];

        vm.materialLineCollectionResponse = { count: 0 };
        vm.labourLinesCollectionResponse = { count: 0 };
        vm.loading = true;

        if (jobResponse.getLink('material-lines')) {
            requests.push(apiClient.get(jobResponse.getLink('material-lines'), {limit: 50}).then(function(response) {
                vm.materialLineCollectionResponse = response;
            }));
        }

        if (jobResponse.getLink('job-labours')) {
            requests.push(apiClient.get(jobResponse.getLink('job-labours'), {limit: 50}).then(function(response) {
                vm.labourLinesCollectionResponse = response;
            }));
        }

        $q.all(requests).then(function() {
            vm.loading = false;
        }, function() {
            messenger.error('REQUEST_ERROR');
        });
    }
})();
