(function() {
    'use strict';

    angular
        .module('elogbooks.user.actions')
        .config(registerRoutes);

    function registerRoutes($stateProvider) {
        elbActionRouting().addRoutes($stateProvider, 'dashboard.user.actions', true);
    }
})();
