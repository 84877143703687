(function() {
    'use strict';

    angular
        .module('elogbooks.admin.site-relationships')
        .controller('SiteRelationshipsInfoController', SiteRelationshipsInfoController);

    SiteRelationshipsInfoController.$inject = ['$state', 'apiClient', 'confirmationModal', 'messenger', 'siteRelationshipResponse'];
    function SiteRelationshipsInfoController($state, apiClient, confirmationModal, messenger, siteRelationshipResponse) {
        var vm = this;
        vm.siteRelationship = siteRelationshipResponse;
    }
})();
