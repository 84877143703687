(function(){
    'use strict';

    angular
        .module('elogbooks.admin.service-types')
        .config(registerServiceTypesRoutes)
    ;

    function registerServiceTypesRoutes($stateProvider) {
        $stateProvider

            .state('dashboard.admin.service-types', {
                url: '/service-types?date&id&name&order&page&limit',
                resolve: {
                    serviceTypesCollectionResponse: function(apiClient, rootResourceResponse, $stateParams) {
                        return apiClient.get(rootResourceResponse.getLink('servicetypes'), $stateParams).then(function(response) {
                            return response;
                        });
                    }
                },
                views: {
                    '@dashboard.admin': {
                        templateUrl: '/modules/admin/service-types/service-types.html',
                        controller: 'AdminServiceTypesController',
                        controllerAs: 'vm'
                    }
                },
                params: {
                    id:null,
                    name:null,
                    order:'id',
                    page: '1',
                    limit: '30'
                },
                ncyBreadcrumb: {
                    label: '{{ ::"SERVICE_TYPE_LIST" | translate }}'
                },
                data: {
                    permissions: ['user_permission_view_service_types']
                }
            })

            .state('dashboard.admin.service-types.add', {
                url: '/add',
                parent: 'dashboard.admin.service-types',
                data: {
                    permissions: ['user_permission_manage_service_types']
                },
                views: {
                    '@dashboard.admin': {
                        templateUrl: '/modules/admin/service-types/add-edit/service-type-form.html',
                        controller: 'AdminServiceTypeAddEditController',
                        controllerAs: 'vm'
                    }
                },
                resolve: {
                    serviceTypeResponse: function()
                    {
                        return {};
                    }
                },
                ncyBreadcrumb: {
                    label: '{{ ::"ADD" | translate }}'
                }
            })

            .state('dashboard.admin.service-types.edit', {
                url: '/edit/{resource}',
                parent: 'dashboard.admin.service-types',
                data: {
                    permissions: ['user_permission_manage_service_types']
                },
                views: {
                    '@dashboard.admin': {
                        templateUrl: '/modules/admin/service-types/add-edit/service-type-form.html',
                        controller: 'AdminServiceTypeAddEditController',
                        controllerAs: 'vm'
                    }
                },
                resolve: {
                    serviceTypeResponse: function(apiClient, $stateParams) {
                        return apiClient.get($stateParams.resource.decode()).then(function(response) {
                            return response;
                        });
                    }
                },
                ncyBreadcrumb: {
                    label: '{{ ::"EDIT" | translate }}'
                }
            });
    }

})();
