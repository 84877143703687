(function () {
    'use strict';

    angular
        .module('elogbooksControllers')
        .controller('UserTaskGenerateModalController', UserTaskGenerateModalController);

    UserTaskGenerateModalController.$inject = ['$scope', 'moment', 'messenger', 'config', 'type', 'startDate', '$uibModalInstance'];

    function UserTaskGenerateModalController ($scope, moment, messenger, config, type, startDate, $uibModalInstance) {
        var vm = this;
        vm.config = config;
        vm.taskGenerateRequest={};
        vm.rows = config.rows | 6;
        vm.create = create;
        vm.cancel = cancel;
        vm.lastYear = moment().year() - 1;
        vm.minDate = '01-01-' + vm.lastYear;
        vm.type = type;
        vm.buttonText = type == 'valueRequiresApproval' ? 'GENERATE' : 'CREATE';
        vm.taskGenerateRequest.start = startDate ? new Date(startDate) : null;

        $scope.$watch('vm.taskGenerateRequest.start', function(newDate){
            if (newDate) {
                if (moment(newDate).isBefore(vm.minDate, 'day')) {
                    messenger.error('TASK_START_DATE_WARNING', { lastYear : vm.lastYear});
                }
                if (vm.taskGenerateRequest.end && moment(newDate).isAfter(vm.taskGenerateRequest.end, 'day')) {
                    vm.taskGenerateRequest.start = null;
                    messenger.error('START_DATE_ERROR', { date : vm.taskGenerateRequest.end});
                }
            }
        });

        $scope.$watch('vm.taskGenerateRequest.end', function(newDate){
            if (newDate && vm.taskGenerateRequest.start && moment(newDate).isBefore(vm.taskGenerateRequest.start, 'day')) {
                vm.taskGenerateRequest.end = null;
                messenger.error('END_DATE_ERROR', { date :  vm.taskGenerateRequest.start});
            }
        });

        function cancel () {
            $uibModalInstance.dismiss(vm.taskGenerateRequest);
        }

        function create () {
            $uibModalInstance.close(vm.taskGenerateRequest);
        }
    }
})();
