(function () {
    'use strict';

    angular
        .module('elogbooks.admin.surveys')
        .controller('SurveyLinesController', SurveyLinesController);

    SurveyLinesController.$inject = [
        'surveyResponse',
        'surveyLineCollectionResponse',
        'sortableOptions',
        'crudService',
        'surveyVersionsResponse',
        'apiClient'
    ];

    function SurveyLinesController (
        surveyResponse,
        surveyLineCollectionResponse,
        sortableOptions,
        crudService,
        surveyVersionsResponse,
        apiClient
    )
    {
        var vm = this;
        vm.survey = surveyResponse;
        vm.surveyVersionsResponse = surveyVersionsResponse;
        vm.surveyLines = surveyLineCollectionResponse.surveylines;
        vm.surveyLineCollectionResponse = surveyLineCollectionResponse;
        vm.surveyLineCollectionResponse.noMore = vm.surveyLineCollectionResponse.page === vm.surveyLineCollectionResponse.pages || vm.surveyLineCollectionResponse.pages === 0;

        vm.currentPage = vm.surveyLineCollectionResponse.page;
        vm.itemsCount = vm.surveyLineCollectionResponse.count;
        vm.limit = vm.surveyLineCollectionResponse.limit;

        vm.reorderedLines = angular.copy(vm.surveyLines);
        vm.linesHaveChanged = false; // Order of lines have changed

        vm.reset = resetAction;
        vm.update = updateAction;
        vm.loadMore = loadMore;

        vm.sortableOptions = sortableOptions.addProperty({
            orderChanged: orderChanged
        });
        vm.selectedVersion = vm.survey;
        vm.loadingMore = false;

        function loadMore() {
            if (vm.loadingMore) {
                return;
            }
            if (vm.surveyLineCollectionResponse.page < vm.surveyLineCollectionResponse.pages) {
                vm.loadingMore = true;
                apiClient.get(vm.surveyLineCollectionResponse.getLink('next')).then(function (response) {
                    vm.surveyLineCollectionResponse.surveylines = vm.surveyLineCollectionResponse.surveylines.concat(response.surveylines);
                    vm.reorderedLines = vm.reorderedLines.concat(response.surveylines);
                    vm.surveyLineCollectionResponse.page = response.page;
                    vm.surveyLineCollectionResponse.pages = response.pages;
                    vm.surveyLineCollectionResponse._links = response._links;
                    vm.surveyLineCollectionResponse.noMore = vm.surveyLineCollectionResponse.page === vm.surveyLineCollectionResponse.pages;
                    vm.surveyLines = vm.surveyLineCollectionResponse.surveylines;
                    vm.currentPage = vm.surveyLineCollectionResponse.page;
                    vm.loadingMore = false;
                });
            }
        }


        function updateAction () {

            vm.update = {
                name: vm.survey.name,
                summary: vm.survey.summary,
                description: vm.survey.description,
                _links: { surveyLines: [] }
            };

            // Build the record to be sent to the server
            angular.forEach(vm.reorderedLines, function (value, $index) {
                vm.update._links.surveyLines.push(value._links.self);
            });

            return crudService.update(
                vm.survey.getLink('self'),
                vm.update,
                'dashboard.admin.surveys.list.details.survey-lines',
                'dashboard.admin.surveys.list.details.survey-lines',
                'SURVEY_LINES'
            );
        }

        function resetAction () {
            vm.reorderedLines = angular.copy(vm.surveyLines);
            vm.linesHaveChanged = false;

        }

        function orderChanged (event, a, b, c, d, e) {
            vm.linesHaveChanged = true;
        }
    }
})();
