(function() {
    'use strict';

    angular
        .module('elogbooks.admin.request-logs')
        .controller('ResponseDetailsListController', ResponseDetailsListController);

    ResponseDetailsListController.$inject = ["responseDetailsResponse"];

    function ResponseDetailsListController(responseDetailsResponse) {
        var vm = this;
        vm.response = responseDetailsResponse;

        try {
            vm.response.body = JSON.stringify(JSON.parse(vm.response.body), null, 4);
        } catch (error) {
            return false;
        }
    }
})();