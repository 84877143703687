(function () {
    'use strict';

    angular
        .module('elogbooks.admin.asset-score-types')
        .controller('AssetScoreTypeInfoController', AssetScoreTypeInfoController);

    AssetScoreTypeInfoController.$inject = ['assetScoreTypeResponse'];

    function AssetScoreTypeInfoController (assetScoreTypeResponse) {
        var vm = this;
        vm.assetScoreType = assetScoreTypeResponse;
    }
})();
