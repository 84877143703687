app.config(function ($translateProvider) {
    $translateProvider.translations('en-gb', {
        MISSED_PATROL_REASONS: 'Missed Patrol Reasons',
        MISSED_PATROL_REASONS_LIST: 'Missed Patrol Reasons',
        MISSED_PATROL_REASONS_ADD: 'Add New Reason',
        MISSED_PATROL_REASONS_EDIT: 'Edit Reason',

        MISSED_PATROL_REASON_ADDED: 'Missed Patrol Reason Added',
        MISSED_PATROL_REASON_UPDATED: 'Missed Patrol Reason Updated',
        MISSED_PATROL_REASON_DELETED: 'Missed Patrol Reason Deleted'
    });
});
