(function () {
    'use strict';

    angular
        .module('elogbooks.user.buddies')
        .config(buddyListMyBuddy)
        .config(buddyListBuddyingFor)
        .config(myBuddyDetails)
        .config(buddyingForDetails);
        function buddyListMyBuddy ($stateProvider) {
            $stateProvider
                .state('dashboard.user.buddies', {
                    url: '/buddies',
                    abstract: true,
                    templateUrl: 'shared-templates/view.html',
                    ncyBreadcrumb: {
                        label: '{{ ::"BUDDIES" | translate }}'
                    }
                })
                .state('dashboard.user.buddies.my-buddy', {
                    url: '/my-buddy?buddyPage&buddyLimit&buddy&siteName&order&status',
                    abstract: true,
                    parent: 'dashboard.user.buddies',
                    params: {
                        buddyPage: '1',
                        buddyLimit: '30',
                        status: null,
                        'excludedStatuses[]': null,
                        dateRange: null
                    },
                    resolve: {
                        buddiesCollectionResource: function ($stateParams, apiClient, user) {
                            var params = angular.copy($stateParams);
                            params = angular.extend({}, params, {
                                page: $stateParams.buddyPage,
                                limit: $stateParams.buddyLimit,
                                'excludedStatuses[]': 'done',
                                datagroup: 'list'
                            });

                            return apiClient.get(user.getLink('buddies'), params);
                        },
                        config: function () {
                            return {type: 'MY_BUDDY'};
                        }
                    },
                    ncyBreadcrumb: {
                        label: '{{ ::"MY_BUDDY" | translate }}'
                    }
                })
                .state('dashboard.user.buddies.my-buddy.schedule.add', {
                    url: '/add',
                    parent: 'dashboard.user.buddies.my-buddy.schedule',
                    resolve: {
                        buddyResponse : function () {
                            return {
                                _links : {
                                    site : null
                                }
                            };
                        },
                        buddySiteCollectionResponse: function () {
                            return null;
                        },
                        userCollectionResponse : function (apiClient, user) {
                            return apiClient.get(user.getLink('users'), {membershipPermission: 'site_permission_can_be_approver', limit:10, exclude:true, status:'active'});
                        },
                        siteCollectionResponse: function (apiClient, user) {
                            return apiClient.get(user.getLink('sites'), {permission: 'site_permission_can_be_approver', limit:30});
                        }
                    },
                    views: {
                        '@dashboard': {
                            templateUrl: '/modules/user/buddies/add-edit/add-edit.html',
                            controller: 'UserBuddiesAddEditController',
                            controllerAs: 'vm'
                        }
                    },
                    ncyBreadcrumb: {
                        label: '{{ ::"ADD" | translate }}'
                    }
                }).state('dashboard.user.buddies.my-buddy.schedule', {
                    url: '/schedule',
                    parent: 'dashboard.user.buddies.my-buddy',
                    views: {
                        '@dashboard': {
                            templateUrl: '/modules/common/buddies/list/list.html',
                            controller: 'CommonBuddyScheduleListController',
                            controllerAs: 'vm'
                        }
                    },
                    resolve: {
                        buddiesCollectionResource: function ($stateParams, apiClient, user, $rootScope) {
                            $stateParams.buddyPage = $rootScope.currentState === 'dashboard.user.buddies.my-buddy.history' ? '1' : $stateParams.buddyPage;
                            var params = angular.copy($stateParams);
                            params = angular.extend({}, params,
                                {
                                    page: $stateParams.buddyPage,
                                    limit: $stateParams.buddyLimit,
                                    'excludedStatuses[]': 'done',
                                    datagroup: 'list'
                                });

                            return apiClient.get(user.getLink('buddies'), params);
                    }
                },
                    ncyBreadcrumb: {
                        label: '{{ ::"SCHEDULE" | translate }}'
                    }
                })
                .state('dashboard.user.buddies.my-buddy.history', {
                    url: '/history',
                    parent: 'dashboard.user.buddies.my-buddy',
                    views: {
                        '@dashboard': {
                            templateUrl: '/modules/common/buddies/list/list.html',
                            controller: 'CommonBuddyHistoryListController',
                            controllerAs: 'vm'
                        }
                    },
                    resolve: {
                        buddiesCollectionResource: function ($stateParams, apiClient, user, $rootScope) {
                            $stateParams.buddyPage = $rootScope.currentState === 'dashboard.user.buddies.my-buddy.schedule' ? '1' : $stateParams.buddyPage;
                            var params = angular.extend({}, $stateParams,
                                {
                                    page : $stateParams.buddyPage,
                                    limit : $stateParams.buddyLimit,
                                    status: 'done',
                                    datagroup: 'list'
                                });
                            return apiClient.get(user.getLink('buddies'), params);
                        },
                        config: function () {
                            return { type: 'MY_BUDDY' };
                        }
                    },
                    ncyBreadcrumb: {
                        label: '{{ ::"HISTORY" | translate }}'
                    }
                })
            }

            function buddyListBuddyingFor ($stateProvider) {
                $stateProvider
                    .state('dashboard.user.buddies.buddying-for', {
                        url: '/buddying-for?buddyPage&buddyLimit&buddy&siteName&order&status',
                        abstract: true,
                        parent: 'dashboard.user.buddies',
                        params: {
                            buddyPage : '1',
                            buddyLimit : '30',
                            active: null,
                            dateRange: null
                        },
                        resolve: {
                            buddiesCollectionResource: function ($stateParams, apiClient, user) {
                                var params = angular.copy($stateParams);
                                params = angular.extend({}, params, {
                                        page : $stateParams.buddyPage,
                                        limit : $stateParams.buddyLimit,
                                        'excludedStatuses[]' : 'done',
                                        datagroup: 'list'
                                    });
                                return apiClient.get(user.getLink('buddying-for'), params);
                            },
                            config: function () {
                                return { type: 'BUDDYING_FOR' };
                            }
                        },
                        ncyBreadcrumb: {
                            label: '{{ ::"BUDDYING_FOR" | translate }}'
                        }
                    })
                    .state('dashboard.user.buddies.buddying-for.schedule', {
                        url: '/schedule',
                        parent: 'dashboard.user.buddies.buddying-for',
                        views: {
                            '@dashboard': {
                                templateUrl: '/modules/common/buddies/list/list.html',
                                controller: 'CommonBuddyScheduleListController',
                                controllerAs: 'vm'
                            }
                        },
                        resolve: {
                            buddiesCollectionResource: function ($stateParams, apiClient, user) {
                                var params = angular.copy($stateParams);
                                params = angular.extend({}, params, {
                                    page : $stateParams.buddyPage,
                                    limit : $stateParams.buddyLimit,
                                    'excludedStatuses[]' : 'done',
                                    datagroup: 'list'
                                });
                                return apiClient.get(user.getLink('buddying-for'), params);
                            }
                        },
                        ncyBreadcrumb: {
                            label: '{{ ::"SCHEDULE" | translate }}'
                        }
                    })
                    .state('dashboard.user.buddies.buddying-for.history', {
                        url: '/history',
                        parent: 'dashboard.user.buddies.buddying-for',
                        views: {
                            '@dashboard': {
                                templateUrl: '/modules/common/buddies/list/list.html',
                                controller: 'CommonBuddyHistoryListController',
                                controllerAs: 'vm'
                            }
                        },
                        resolve: {
                            buddiesCollectionResource: function ($stateParams, apiClient, user) {
                                var params = angular.copy($stateParams);
                                params = angular.extend({}, params,
                                    {
                                        page : $stateParams.buddyPage,
                                        limit : $stateParams.buddyLimit,
                                        status: 'done',
                                        datagroup: 'list'
                                    });
                                return apiClient.get(user.getLink('buddying-for'), params);
                            }
                        },
                        ncyBreadcrumb: {
                            label: '{{ ::"HISTORY" | translate }}'
                        }
                    })
                }

        function myBuddyDetails($stateProvider) {
            $stateProvider
                .state('dashboard.user.buddies.my-buddy.schedule.details', {
                    url: '/details/{resource}',
                    parent: 'dashboard.user.buddies.my-buddy.schedule',
                    views: {
                        '@dashboard': {
                            templateUrl: '/modules/common/buddies/details/details.html',
                            controller: 'CommonBuddyDetailsController',
                            controllerAs: 'vm'
                        }
                    },
                    params: {
                        page : '1',
                        name: { value: null, type: 'string' },
                        reference: { value: null, type: 'string' },
                        sitesPage: '1',
                        sitesLimit: '30'
                    },
                    resolve: {
                        buddyResponse : function (apiClient, $stateParams) {
                            return apiClient.get($stateParams.resource.decode());
                        },
                        siteCollectionResponse: function ($stateParams, apiClient, buddyResponse) {
                            var params = {
                                name: $stateParams.name,
                                reference: $stateParams.reference,
                                datagroup: 'search',
                                page: $stateParams.sitesPage,
                                limit: $stateParams.sitesLimit
                            }

                            return apiClient.getPage(buddyResponse.getLink('sites'), params);
                        },
                        config: function () {
                            return { type: 'MY_BUDDY' };
                        }
                    },
                    ncyBreadcrumb: {
                        label: '{{ ::"DETAILS" | translate }}'
                    }
                })
                .state('dashboard.user.buddies.my-buddy.history.details', {
                    url: '/details/{resource}',
                    parent: 'dashboard.user.buddies.my-buddy.history',
                    views: {
                        '@dashboard': {
                            templateUrl: '/modules/common/buddies/details/details.html',
                            controller: 'CommonBuddyDetailsController',
                            controllerAs: 'vm'
                        }
                    },
                    params: {
                        page : '1',
                        name: { value: null, type: 'string' },
                        reference: { value: null, type: 'string' },
                        sitesPage: '1',
                        sitesLimit: '30'
                    },
                    resolve: {
                        buddyResponse : function (apiClient, $stateParams) {
                            return apiClient.get($stateParams.resource.decode());
                        },
                        siteCollectionResponse: function ($stateParams, apiClient, buddyResponse) {
                            var params = {
                                name: $stateParams.name,
                                reference: $stateParams.reference,
                                datagroup: 'search',
                                page: $stateParams.sitesPage,
                                limit: $stateParams.sitesLimit
                            }
                            return apiClient.getPage(buddyResponse.getLink('sites'), params);
                        }
                    },
                    ncyBreadcrumb: {
                        label: '{{ ::"DETAILS" | translate }}'
                    }
                })
                .state('dashboard.user.buddies.my-buddy.schedule.details.edit', {
                    url: '/edit',
                    parent: 'dashboard.user.buddies.my-buddy.schedule.details',
                    views: {
                        '@dashboard': {
                            templateUrl: '/modules/user/buddies/add-edit/edit.html',
                            controller: 'UserBuddiesAddEditController',
                            controllerAs: 'vm'
                        }
                    },
                    resolve: {
                        userCollectionResponse : function (apiClient, user, userManager, lodash) {
                            return apiClient.get(user.getLink('users'), {membershipPermission: 'site_permission_can_be_associate', limit:10, status: 'active'}).then(function (response) {
                                var user = userManager.user;

                                //removes from collection currently logged in user
                                var users = lodash.filter(response.users, function (item) {
                                    return item.id !== user.id;
                                });

                                response.users = users;
                                return response;
                            });
                        },
                        buddySiteCollectionResponse: function ($stateParams, apiClient, buddyResponse) {
                            $stateParams.datagroup = 'search';
                            return apiClient.getPage(buddyResponse.getLink('sites'), $stateParams);
                        },
                        config: function () {
                            return { type: true };
                        }
                    },
                    ncyBreadcrumb: {
                        label: '{{ ::"EDIT" | translate }}'
                    }
                })
                .state('dashboard.user.buddies.my-buddy.schedule.details.add-site', {
                    url: '/add-site',
                    parent: 'dashboard.user.buddies.my-buddy.schedule.details',
                    views: {
                        '@dashboard': {
                            templateUrl: '/modules/user/buddies/add-edit/add-site.html',
                            controller: 'UserBuddiesAddSiteController',
                            controllerAs: 'vm'
                        }
                    },
                    resolve: {
                        siteCollectionResponse: function (apiClient, user) {
                            return apiClient.get(user.getLink('sites'), {permission: 'site_permission_can_be_approver', limit:30});
                        }
                    },
                    ncyBreadcrumb: {
                        label: '{{ ::"ADD_SITE" | translate }}'
                    }
                })
        }

        function buddyingForDetails($stateProvider) {
            $stateProvider
                .state('dashboard.user.buddies.buddying-for.schedule.details', {
                    url: '/details/{resource}',
                    parent: 'dashboard.user.buddies.buddying-for.schedule',
                    views: {
                        '@dashboard': {
                            templateUrl: '/modules/common/buddies/details/details.html',
                            controller: 'CommonBuddyDetailsController',
                            controllerAs: 'vm'
                        }
                    },
                    params: {
                        page : '1',
                        name: { value: null, type: 'string' },
                        reference: { value: null, type: 'string' },
                        sitesPage: '1',
                        sitesLimit: '30'
                    },
                    resolve: {
                        buddyResponse : function (apiClient, $stateParams) {
                            return apiClient.get($stateParams.resource.decode());
                        },
                        siteCollectionResponse: function ($stateParams, apiClient, buddyResponse) {
                            var params = {
                                name: $stateParams.name,
                                reference: $stateParams.reference,
                                datagroup: 'search',
                                page: $stateParams.sitesPage,
                                limit: $stateParams.sitesLimit
                            }
                            return apiClient.getPage(buddyResponse.getLink('sites'), params);
                        },
                        config: function () {
                            return { type: 'BUDDYING_FOR' };
                        }
                    },
                    ncyBreadcrumb: {
                        label: '{{ ::"DETAILS" | translate }}'
                    }
                })
                .state('dashboard.user.buddies.buddying-for.history.details', {
                    url: '/details/{resource}',
                    parent: 'dashboard.user.buddies.buddying-for.history',
                    views: {
                        '@dashboard': {
                            templateUrl: '/modules/common/buddies/details/details.html',
                            controller: 'CommonBuddyDetailsController',
                            controllerAs: 'vm'
                        }
                    },
                    params: {
                        page : '1',
                        name: { value: null, type: 'string' },
                        reference: { value: null, type: 'string' },
                        sitesPage: '1',
                        sitesLimit: '30'
                    },
                    resolve: {
                        buddyResponse : function (apiClient, $stateParams) {
                            return apiClient.get($stateParams.resource.decode());
                        },
                        siteCollectionResponse: function ($stateParams, apiClient, buddyResponse) {
                            var params = {
                                name: $stateParams.name,
                                reference: $stateParams.reference,
                                datagroup: 'search',
                                page: $stateParams.sitesPage,
                                limit: $stateParams.sitesLimit
                            }
                            return apiClient.getPage(buddyResponse.getLink('sites'), params);
                        }
                    },
                    ncyBreadcrumb: {
                        label: '{{ ::"DETAILS" | translate }}'
                    }
                })
        }

})();
