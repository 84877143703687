(function() {
    'use strict';

    angular
        .module('elogbooks')
        .config(Translations);

    function Translations($translateProvider) {
        $translateProvider.translations('zh-hk', {
            QUOTE_REQUEST_ADD_SITE: '場地',
            QUOTE_REQUEST_ADD_CONTACT_DETAILS: '場地聯絡詳細資料',
            QUOTE_REQUEST_ADD_DETAILS: '報價詳細資料',

            INCLUDE_NEVER_SUBMITTED: '包括從未遞交'
        });
    }
})();


app.config(function ($translateProvider) {
    $translateProvider.translations('zh-hk', {
        QUOTE_REQUEST: '報價請求',
        QUOTE_REQUESTS: '報價請求',

        QUOTE_REQUEST_LIST: '報價請求',

        QUOTE_REQUEST_ADD: '新增報價請求',

        QUOTE_REQUEST_CREATED: '報價請求已建立',
        QUOTE_REQUEST_UPDATED: '報價請求已更新',
        QUOTE_REQUEST_DELETED: '報價請求已刪除',

        QUOTE_REQUEST_DETAILS: '報價請求詳細資料',
        QUOTE_REQUEST_CONTACTS: '報價請求聯絡人',

        QUOTE_REQUEST_STATUS_CREATED: '已建立',
        QUOTE_REQUEST_STATUS_REQUESTED: '已發出',
        QUOTE_REQUEST_STATUS_PART_QUOTED: '部份已報價',
        QUOTE_REQUEST_STATUS_QUOTED: '已報價',
        QUOTE_REQUEST_STATUS_REJECTED: '已拒絕',
        QUOTE_REQUEST_STATUS_ACCEPTED: '已接受',
        QUOTE_REQUEST_STATUS_CANCELLED: '已取消',
        QUOTE_REQUEST_STATUS_EXPIRED: '已過期',
        QUOTE_REQUEST_STATUS_DECLINED: '已拒絕',
        CREATED_FROM_JOB: '從工作建立',

    });
});
