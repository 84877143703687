(function () {
    'use strict';

    angular
        .module('elogbooks.common.audit')
        .controller('AuditStartController', AuditStartController);

    AuditStartController.$inject = ['$uibModalInstance', 'apiClient', 'reference', 'referencePermission'];

    function AuditStartController ($uibModalInstance, apiClient, reference, referencePermission) {
        var vm = this;
        vm.close = closeModal;
        vm.confirm = confirm;
        vm.data = {
            startAt: new Date()
        };

        if (referencePermission) {
            vm.data.reference = reference;
        }

        function closeModal() {
            $uibModalInstance.dismiss('cancel');
        };

        function confirm() {
            $uibModalInstance.close(vm.data);
        };
    }
})();
