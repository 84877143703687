(function () {
    'use strict';

    angular
        .module('elogbooks.common.actions')
        .controller('CommonActionsFilesListController', CommonActionsFilesListController);

    CommonActionsFilesListController.$inject = [
        '$scope',
        '$controller',
        'fileCollectionResponse',
        'selectedSiteResponse',
        '$translate',
        'config',
        '$uibModal',
        'actionResponse',
        'integrationDocTypesResponse',
        'apiClient',
        'user',
        'lodash',
        'rootResourceResponse',
        'siteCollectionResponse',
        'fromJobResponse',
        'documentTypeCollectionResponse'
    ];

    function CommonActionsFilesListController (
        $scope,
        $controller,
        fileCollectionResponse,
        selectedSiteResponse,
        $translate,
        config,
        $uibModal,
        actionResponse,
        integrationDocTypesResponse,
        apiClient,
        user,
        lodash,
        rootResourceResponse,
        siteCollectionResponse,
        fromJobResponse,
        documentTypeCollectionResponse
    ) {
        var vm = this;
            vm.integrations = integrationDocTypesResponse ? integrationDocTypesResponse.data : [];
            vm.activeIntegrations = [];
            vm.integrationsLoading = false;
            vm.riskwiseIntegrationLoading = false;
            vm.ospIntegrationLoading = false;

        // inherit from CommonFilesListController and inject dependencies
        angular.extend(vm, $controller('CommonFilesListController', {
            $scope: $scope,
            fileCollectionResponse: fileCollectionResponse,
            selectedSiteResponse: selectedSiteResponse,
            config: config,
            user: user,
            rootResourceResponse: rootResourceResponse,
            siteCollectionResponse: siteCollectionResponse,
            documentTypeCollectionResponse: documentTypeCollectionResponse,
            jobResponse: null,
            serviceProviderResponse: null,
            operativeCollectionResponse: null
        }));

        fileCollectionResponse.integrations.forEach(function(integration) {
            initializeIntegrations(integration);
        });

        vm.integrate = integrate;
        vm.getIntegrationUploadStatus = getIntegrationUploadStatus;
        vm.hasActiveIntegration = hasActiveIntegration;

        function integrate (file, integration) {
            if(!vm.hasActiveIntegration(integration.type, file.integrations) && file.status > 0) {
                vm.file = file;
                vm.data = {_links: {}};
                vm.data._links.file = file.getLink('self');
                vm.data.documentDate =  new Date(file.documentDate);
                vm.documentDateMaxDate = moment().format('MM-DD-YYYY');

                switch(integration.type) {
                    case 'riskwise':
                        vm.modalInstance = $uibModal.open({
                            templateUrl: '/modules/common/files/modal/riskwise/files-integration-details-form.html',
                            controller: 'RiskwiseFilesIntegrationDetailsController',
                            controllerAs: 'vm',
                            resolve: {
                                file: function () {
                                    return file;
                                },
                                actionResponse: function() {
                                    return actionResponse;
                                },
                                integrationDocSystem: function() {
                                    return vm.integrationDocSystem;
                                },
                                integrationDocCats: function() {
                                    return vm.integrationDocCats;
                                }
                            }
                        }).result.then(function () {
                            file.integrations.push({'type': 'riskwise', 'status': 0});
                        });

                        break;
                    case 'osp':
                        vm.ospIntegrationLoading = true;

                        apiClient.get(actionResponse.getLink('osp-occupiers'), {}).then(function(response) {
                            if (response) {
                                vm.modalInstance = $uibModal.open({
                                    templateUrl: '/modules/common/files/modal/osp/files-integration-details-form.html',
                                    controller: 'OSPFilesIntegrationDetailsController',
                                    controllerAs: 'vm',
                                    resolve: {
                                        actionResponse: function () {
                                            return actionResponse;
                                        },
                                        file: function () {
                                            return file;
                                        },
                                        occupierResponse: function() {
                                            return response;
                                        }
                                    }
                                }).result.then(function () {
                                    file.integrations.push({'type': 'osp', 'status': 0});
                                });
                            }

                            vm.ospIntegrationLoading = false;
                        });

                        break;
                }
            }
        }

        function getIntegrationUploadStatus(integration) {
            var status = {};

            switch (integration.status) {
                case 0:
                    status = {icon: 'fa fa-clock-o', title: $translate.instant(integration.type.toUpperCase() + '_UPLOAD_PENDING')};
                    break;
                case 1:
                    status = {icon: 'fa fa-check', title: $translate.instant(integration.type.toUpperCase() + '_UPLOADED')};
                    break;
                case 2:
                    status = {icon: 'fa fa-exclamation-circle', title: $translate.instant(integration.type.toUpperCase() + '_UPLOAD_ERROR')};
                    break;
            }

            return status;
        }

        function initializeIntegrations(integration) {
            // if RiskWise integration is enabled and system setting riskwise_type is set to FM led (1)
            // and user is action assignee, show button
            if (actionResponse.paperworkForReview
                && integration.type === 'riskwise'
                && integration.riskwiseType === 1
                && fromJobResponse
                && vm.integrations.length === 0
                && fromJobResponse.getLink('integration-doc-types')) {
                vm.integrationsLoading = true;

                return apiClient.get(fromJobResponse.getLink('integration-doc-types')).then(function(integrationDocTypesResponse) {
                    vm.integrations = integrationDocTypesResponse ? integrationDocTypesResponse.data : [];

                    if (user.getLink('self') === actionResponse.getLink('assignee') || actionResponse.getLink('edit')) {
                        vm.showIntegrateButton = true;
                        var riskwiseIntegration = lodash.find(vm.integrations, {integration: 'riskwise'});
                        vm.integrationDocSystem = (riskwiseIntegration && riskwiseIntegration.integration) ? riskwiseIntegration.integration : null;
                        vm.integrationDocCats = (riskwiseIntegration && riskwiseIntegration.docTypes) ? riskwiseIntegration.docTypes : [];
                        vm.activeIntegrations.push({
                            'type': 'riskwise',
                            'label': $translate.instant('UPLOAD_TO_RISKWISE')
                        });
                    }

                    vm.integrationsLoading = false;
                });
            }

            if (integration.type === 'osp') {
                vm.showIntegrateButton = true;
                vm.activeIntegrations.push({
                    'type': 'osp',
                    'label': $translate.instant('UPLOAD_TO_OSP')
                });
            }
        }

        function hasActiveIntegration(type, integrations) {
            return lodash.find(integrations, {type: type}) || false;
        }
    }
})();
