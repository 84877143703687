(function() {
    'use strict';

    angular
        .module('elogbooksDirectives')
        .directive('elbEscalationWorkflowButtons', ElogbooksEscalationWorkflowButtons);

    ElogbooksEscalationWorkflowButtons.$inject = [];

    function ElogbooksEscalationWorkflowButtons() {
        return {
            restrict: 'AE',
            scope: {
                escalation: '=ngModel',
                routeBase: '@',
                routeParameters: '@'
            },
            templateUrl: '/modules/directives/elb-escalation-workflow-buttons/escalation-workflow-buttons.html',
            link: function($scope, element, attributes) {
                $scope.getLink = getLink;

                function getLink(route) {
                    if ($scope.routeBase) {
                        route = $scope.routeBase + route;
                    }

                    if ($scope.routeParameters) {
                        route = route + '(' + $scope.routeParameters + ')';
                    }

                    return route;
                }
            }
        };
    }
})();
