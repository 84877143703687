/**
 * Service Routine Service
 */
angular.module('elogbooksServices').service('serviceRoutineService', function (lodash) {
    
    this.formatServiceRoutineValue = function(serviceRoutine) {
        if (!serviceRoutine) {
            return;
        }
            return  (serviceRoutine.reference ? serviceRoutine.reference + ' - ': '') + serviceRoutine.name;
    }
});
