(function() {
    'use strict';

    angular
        .module('elogbooks.common.assets')
        .controller('UserAssetsController', UserAssetsController);

    UserAssetsController.$inject = ['sectionResource'];

    function UserAssetsController(sectionResource) {
        var vm = this;
        vm.sectionResource = sectionResource;
    }
})();