(function() {
    'use strict';

    angular
        .module('elogbooks.common.spillage-types')
        .controller('SpillageTypesController', SpillageTypesController);

    SpillageTypesController.$inject = ['spillageTypeCollectionResponse', 'NestedArrayTransformer', '$window'];

    function SpillageTypesController (spillageTypeCollectionResponse, NestedArrayTransformer, $window) {
        var vm = this;
            vm.spillageTypeCollectionResponse = spillageTypeCollectionResponse;
            vm.spillageTypes = NestedArrayTransformer.transform(spillageTypeCollectionResponse.spillagetypes);

            vm.toggle = toggleAction;
            vm.isOpen = isOpen;

        function toggleAction (spillageType) {
            var showChildren = JSON.parse($window.localStorage.getItem('common-spillagetypes.' + spillageType.id));
            $window.localStorage.setItem('common-spillagetypes.' + spillageType.id, JSON.stringify(! showChildren));
        }

        function isOpen (spillageType) {
            return JSON.parse($window.localStorage.getItem('common-spillagetypes.' + spillageType.id));
        }
    }
})();
