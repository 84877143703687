(function() {
    'use strict';

    angular
        .module('elogbooksServices')
        .service('globalFilterParamsService', GlobalFilterParamsService);

    GlobalFilterParamsService.$inject = [];

    function GlobalFilterParamsService() {
        var self = this;

        this.getLocalStorageFilters = getLocalStorageFilters;

        function getLocalStorageFilters() {
            var params = {};

            if (window.localStorage.getItem('userIdFilter')) {
                params.userId = window.localStorage.getItem('userIdFilter');
            }

            if (window.localStorage.getItem('regionFilter')) {
                params.regionId = window.localStorage.getItem('regionFilter');
                params.region = window.localStorage.getItem('regionFilter');
            }

            if (window.localStorage.getItem('siteFilter')) {
                params.site = window.localStorage.getItem('siteFilter');
            }

            if (window.localStorage.getItem('siteAssociateTypeFilter') && window.localStorage.getItem('userIdFilter')) {
                params.siteAssociateType = window.localStorage.getItem('siteAssociateTypeFilter');
                params.associate = window.localStorage.getItem('userIdFilter');
            }

            return params;
        }

        return this;
    }
})();
