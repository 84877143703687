(function () {
    'use strict';

    angular
        .module('elogbooks.common.audit')
        .controller('AuditQuestionDetailsController', AuditQuestionDetailsController);

    AuditQuestionDetailsController.$inject = ['$state', 'apiClient', 'lodash', 'auditResponse', 'questionAnswerResponse', 'questionResponse', 'sectionResponse', 'answerOptionsResponse', 'messenger', 'confirmationModalNotes', 'requestDataFactory'];

    function AuditQuestionDetailsController($state, apiClient, lodash, auditResponse, questionAnswerResponse, questionResponse, sectionResponse, answerOptionsResponse, messenger, confirmationModalNotes, requestDataFactory ) {
        var vm = this;
        vm.question = questionResponse;
        vm.answerOptions = answerOptionsResponse.answerOptions;
        vm.questionAnswerResponse = questionAnswerResponse;
        vm.section = sectionResponse;
        vm.audit = auditResponse;
        vm.userCanEdit = lodash.has(vm.audit, '_links.edit.href');
        vm.files = lodash.has(vm.questionAnswerResponse, 'files');
    }
})();
