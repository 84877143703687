(function() {
    'use strict';

    angular
        .module('elogbooks.user.finance.credit-notes')
        .controller('FinanceCreditNoteDetailsInfoController', FinanceCreditNoteDetailsInfoController);

    FinanceCreditNoteDetailsInfoController.$inject = [
        'creditNoteResponse'
    ];

    function FinanceCreditNoteDetailsInfoController(
        creditNoteResponse
    ) {
        var vm = this;
        vm.creditNote = creditNoteResponse;
    }
})();
