app.config(function ($translateProvider) {
    'use strict';

    $translateProvider.translations('nl', {
        USERNAME: 'Gebruikersnaam',
        PASSWORD: 'Wachtwoord',
        SIGN_IN: 'Aanmelden',
        PLEASE_LOGIN: 'Login',
        WELCOME_TO_ELOGBOOKS: 'Welkom in Elogs CAFM',
        INVALID_CREDENTIALS: 'Foutieve gegevens',
        FORGOT_YOUR_PASSWORD: 'Bent u uw wachtwoord vergeten?',
        PASSWORD_REQUEST_SUBMITTED: 'Als er een bestaande gebruiker is gevonden, krijgt u per e-mail een instructie om uw wachtwoord te resetten.',
        FORGOT_USERNAME_REQUEST_SUBMITTED: 'Als er een bestaande gebruiker is gevonden, krijgt u per e-mail uw gebruikersnaam.',
        CHOOSE_YOUR_NEW_PASSWORD: 'Kies een nieuw wachtwoord.',
        PASSWORDS_DO_NOT_MATCH: 'Ingevoerde wachtwoorden komen niet overeen',
        YOUR_PASSWORD_HAS_BEEN_CHANGED: 'Uw wachtwoord is gewijzigd.',
        BACK_TO_LOGIN_PAGE: 'Ga terug naar de inlog pagina',
        SET_MY_NEW_PASSWORD: 'Voer wijzigingen door',
        NEW_PASSWORD: 'Nieuw wachtwoord',
        CONFIRM_NEW_PASSWORD: 'Bevestig nieuw wachtwoord',
        USER_AUTH_TOO_MANY_ATTEMPTS: 'Uw profiel is geblokkeerd',
        USER_AUTH_BAD_CREDENTIALS: 'Verkeerde gegevens',
        USER_AUTH_FAIL: 'Er is een probleem om uw gegevens te identificeren, probeer het later nogmaals alstublieft.',
        USER_AUTH_RECAPTCHA_REQUIRED: 'The captcha is required.',
        USER_AUTH_RECAPTCHA_INVALID: 'The captcha is invalid.',
        USER_ACCOUNT_DISABLED: 'Deze account is inactief.'
    });
});
