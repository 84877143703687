(function() {
    'use strict';

    angular
    .module('elogbooksDirectives')
    .directive('elbApproversList', approversList);

    approversList.$inject = [];

    function approversList() {
        return {
            restrict: 'AE',
            templateUrl: '/modules/directives/approvers/list/approvers-list.html',
            scope: {
                approverTypes: '='
            }
        };
    }
})();