app.config( function($translateProvider) {
    $translateProvider.translations('zh-hk', {
        METER_OCCUPANCY_INFORMATION: '儀表佔用率資料',

        ADD_NEW_METER_OCCUPANCY: '新增儀表佔用率',
        EDIT_METER_OCCUPANCY: '修改儀表佔用率',

        METER_OCCUPANCY_LIST: '儀表佔用率',
        METER_OCCUPANCIES_LIST: '儀表佔用率',
        METER_OCCUPANCY_ADD: '新增儀表佔用率',
        METER_OCCUPANCY_EDIT: '修改儀表佔用率',

        METER_OCCUPANCY_CREATED: '儀表佔用率已建立',
        METER_OCCUPANCY_UPDATED: '儀表佔用率已更新',
        METER_OCCUPANCY_DELETED: '儀表佔用率已刪除',

        METER_OCCUPANCY_METER: '儀表',
        METER_OCCUPANCY_SUPPLY: '供應率',
        METER_OCCUPANCY_OCCUPANCY: '佔用率'
    });
});
