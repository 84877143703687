app.config(function ($translateProvider) {
    $translateProvider.translations('zh-hk', {
        BUDDY: '伙伴',
        BUDDY_ADD: '新增伙伴',
        BUDDY_EDIT: '修改伙伴',
        BUDDIES: '伙伴',
        BUDDIES_NO_RESULTS: '沒有伙伴',
        BUDDYING_FOR: '正在為',
        BUDDY_SELECT_SITES: '所選地點',
        BUDDY_SITE_RELATION_REMOVED: '地點已移除',
        BUDDY_REMOVED: '伙伴已移除',
        BUDDY_ALREADY_IN_USE_SITES: '已用的地點:',
        BUDDY_DEACTIVATED: '伙伴已停用'
    });
});
