(function() {
    'use strict';

    angular
        .module('elogbooks.user.sites')
        .controller('UserSitePartnershipDetailContactsController', UserSitePartnershipDetailContactsController);

    UserSitePartnershipDetailContactsController.$inject = ['contactInformationResponse', 'lodash'];

    function UserSitePartnershipDetailContactsController (contactInformationResponse, lodash) {

        var vm = this;
        vm.contactInformationResponse = contactInformationResponse;
        vm.contactInfoCollection = [];

        angular.forEach(vm.contactInformationResponse.fields, function(contact, key) {
            var siteContact = {'value': contact.value};
            var type = lodash.find(vm.contactInfoCollection, function(element) { return element.type === contact.type; });

            if (type !== undefined) {
                type.siteContacts.push(siteContact);
            } else {
                type = {'type': contact.type, 'siteContacts': []};
                type.siteContacts.push(siteContact);
                vm.contactInfoCollection.push(type);
            }
        });
    }
})();
