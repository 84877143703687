app.config(function($translateProvider) {
    $translateProvider.translations('nl', {
        CORE_CLIENT: 'Klant groep',
        CORE_CLIENTS: 'Klant roepen',
        CORE_CLIENT_INFORMATION: 'Klant groep infomatie',

        ADD_NEW_CORE_CLIENT: 'Voeg een nieuwe klant groep toe',
        EDIT_CORE_CLIENT: 'Wijzig klant groep',

        CORE_CLIENT_LIST: 'Klant groepen',
        CORE_CLIENT_ADD: 'Voeg een nieuwe klant groep toe',
        CORE_CLIENT_EDIT: 'Pas klant groep aan',

        CORE_CLIENT_CREATED: 'Klant groep Gemaakt',
        CORE_CLIENT_UPDATED: 'Klant groep aangepast',
        CORE_CLIENT_DELETED: 'Klant groep verwijderd',
    });
});
