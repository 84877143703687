(function() {
    'use strict';

    angular
        .module('elogbooks')
        .config(Translations);

    function Translations($translateProvider) {
        $translateProvider.translations('nl', {

            QUOTE: 'Offerte',
            QUOTES: 'Offertes',

            QUOTE_WITH_ID: 'Offerte {{ id }}',

            QUOTE_LIST: 'Offertes',
            QUOTE_VIEW: 'Offerte',

            QUOTE_SUMMARY: 'Samenvatting offerte',
            QUOTE_DESCRIPTION: 'Omschrijving offerte',
            QUOTE_PRIORITY: 'Prioriteit van offerte',

            QUOTE_ADD: 'Maak een nieuwe offerte',
            QUOTE_CREATED: 'Offerte gemaakt',
            QUOTE_UPDATED: 'Offerte aangepast',
            QUOTE_DELETED: 'Offerte verwijderd',

            QUOTE_ID: 'Offerte {{ id }}',
            QUOTE_DETAILS: 'Offerte gegevens',
            QUOTE_STATUS: 'Status',
            QUOTE_CONTACTS: 'Contactgegevens offerte',

            QUOTE_AUDIT_TRAIL_SENT: 'Audit Trail verzonden',

            QUOTE_CONTACT: 'Offerte Contact',

            QUOTE_CONTACT_LIST: 'Offerte Contacten',
            QUOTE_CONTACT_ADD: 'Toevoegen',

            QUOTE_CONTACT_CREATED: 'Offerte Contact gemaakt',
            QUOTE_CONTACT_UPDATED: 'Offerte Contact bijgewerkt',
            QUOTE_CONTACT_DELETED: 'Offerte Contact verwijderd',

            QUOTE_WORKFLOW_ABSTAIN: 'Offerte afwijzen',
            QUOTE_WORKFLOW_ACCEPT: 'Accepteren',
            QUOTE_WORKFLOW_CANCEL: 'Afwijzen',
            QUOTE_WORKFLOW_HOLD: 'Vasthouden',
            QUOTE_WORKFLOW_REJECT: 'Afkeuren',
            QUOTE_WORKFLOW_REQUEST_MORE_INFORMATION: 'Verzoek meer informatie',
            QUOTE_WORKFLOW_SUBMIT: 'Versturen',
            QUOTE_WORKFLOW_REOPEN: 'Heropenen',
            QUOTE_WORKFLOW_ESCALATE: 'Escaleren',

            QUOTE_WORKFLOW_ABSTAIN_SUBMIT: 'Afwijzen',
            QUOTE_WORKFLOW_ACCEPT_SUBMIT: 'Accepteer offerte',
            QUOTE_WORKFLOW_CANCEL_SUBMIT: 'Annuleer offerte',
            QUOTE_WORKFLOW_HOLD_SUBMIT: 'On hold',
            QUOTE_WORKFLOW_REJECT_SUBMIT: 'Afkeuren',
            QUOTE_WORKFLOW_REQUEST_MORE_INFORMATION_SUBMIT: 'Verzoek meer informatie',
            QUOTE_WORKFLOW_SUBMIT_SUBMIT: 'Versturen',
            QUOTE_WORKFLOW_REOPEN_SUBMIT: 'Heropenen',
            QUOTE_WORKFLOW_ESCALATE_SUBMIT: 'Escaleren',
            QUOTE_WORKFLOW_ESCALATE_APPROVAL_SUBMIT: 'Escaleren',

            QUOTE_WORKFLOW_SUBMIT_SUCCESS: 'Offerte ingediend',

            QUOTE_WORKFLOW_ESCALATE_SUCCESS:'Offerte geescaleerd',
        
            QUOTE_WORKFLOW_ALARM: 'Wijzig alarmering',
            QUOTE_WORKFLOW_ALARM_SUCCESS: 'Alarmering aangepast',
            QUOTE_WORKFLOW_ALARM_SUBMIT: 'Wijzig',

            QUOTE_WORKFLOW_ALARM_SETNEW: 'Activeer alarmering',
            QUOTE_WORKFLOW_ALARM_SETNEW_SUCCESS: 'Alarmering aangepast',
            QUOTE_WORKFLOW_ALARM_SETNEW_SUBMIT: 'Ingesteld',

            QUOTE_WORKFLOW_POSTPONE_REMINDER_AT: 'Uitstellen actie',
            QUOTE_WORKFLOW_POSTPONE_REMINDER_AT_SUBMIT: 'Uitstellen',
            QUOTE_WORKFLOW_POSTPONE_REMINDER_AT_SUCCESS: 'Offerte uitgesteld',

            QUOTE_WORKFLOW_REJECT_EXTENSION: 'Afwijzen verlenging',
            QUOTE_WORKFLOW_REJECT_EXTENSION_SUBMIT: 'Afwijzen',
            QUOTE_WORKFLOW_ACCEPT_EXTENSION: 'Accepteren verlenging',
            QUOTE_WORKFLOW_ACCEPT_EXTENSION_SUBMIT: 'Accepteren',
            QUOTE_WORKFLOW_REQUEST_EXTENSION: 'verlenging verzoeken',
            QUOTE_WORKFLOW_REQUEST_EXTENSION_SUBMIT: 'Doorgeven',
            QUOTE_WORKFLOW_ACCEPT_EXTENSION_SUCCESS: 'Verlenging geaccepteerd',
            QUOTE_WORKFLOW_REJECT_EXTENSION_SUCCESS: 'Verlenging afgewezen',
            QUOTE_WORKFLOW_REQUEST_EXTENSION_SUCCESS: 'Verlenging aangevraagd',
            REQUESTED_EXTENSION: 'Verlenging aangevraagd',
            QUOTE_WORKFLOW_ESCALATE_APPROVAL_SUCCESS: 'Offerte goedkeuring geescaleerd',
            QUOTE_WORKFLOW_REQUEST_MORE_INFORMATION_SUCCESS: 'Om meer informatie verzocht',
            NEW_COMPLETION_DATE_MUST_BE_AFTER_TODAY: 'Nieuwe datum moet in de toekomst liggen',
            NEW_COMPLETION_DATE_MUST_BE_AFTER_CURRENT_COMPLETION_DATE: 'Nieuwe datum moet na de huidige einddatum liggen',
            QUOTE_APPROVER_NOT_SET: 'Niemand om goed te keuren in gesteld voor offerte, kan niet worden verwerkt',

            QUOTE_STATUS_CREATED: 'Aangemaakt',
            QUOTE_STATUS_REQUESTED: 'Verzocht',
            QUOTE_STATUS_SUBMITTED: 'In afwachting goedkeuring',
            QUOTE_STATUS_ACCEPTED: 'Geaccepteerd',
            QUOTE_STATUS_REJECTED: 'Afgewezen',
            QUOTE_STATUS_CANCELLED: 'Geannuleerd',
            QUOTE_STATUS_EXPIRED: 'Verlopen',
            QUOTE_STATUS_ABSTAINED: 'Heeft het verzoek tot offerte afgewezen',
            QUOTE_STATUS_PENDING: 'On Hold',
            QUOTE_STATUS_INFORMATION_REQUESTED: 'Om meer informatie verzocht',
            QUOTE_STATUS_EXTENSION_REQUESTED: 'Verlenging verzocht',

            QUOTE_CREATED_ACTION: '<strong>{{ ::event._links["user"].title }}</strong> Heeft een offerteverzoek gedaan',
            QUOTE_SUBMITTED_ACTION: '<strong>{{ ::event._links["user"].title }}</strong> heeft een offerte doorgegeven.',
            QUOTE_MORE_INFORMATION_REQUESTED_ACTION: '<strong>{{ ::event._links["user"].title }}</strong> heeft om meer informatie verzocht.',
            QUOTE_ABSTAINED_ACTION: '<strong>{{ ::event._links["user"].title }}</strong> heeft het offerteverzoek afgewezen.',
            QUOTE_REOPENED_ACTION: '<strong>{{ ::event._links["user"].title }}</strong> heeft de offerte opnieuw geopend.',
            QUOTE_PUT_ON_HOLD_ACTION: '<strong>{{ ::event._links["user"].title }}</strong> heeft de offerte on hold gezet.',
            QUOTE_APPROVAL_RESENT_ACTION: '<strong>{{ ::event._links["user"].title }}</strong> heeft verzoek om goedkeuring verzonden {{ ::event._links["approver"].title }}.',
            QUOTE_APPROVAL_ESCALATED_ACTION: '<strong>{{ ::event._links["user"].title }}</strong> heeft goedkeuring geescaleerd.',
            QUOTE_POSTPONE_REMINDER_AT_ACTION: '<strong>{{ ::event._links["user"].title }}</strong> heeft actiedatum gewijzigd naar {{ ::event.reminderAt | date:\'short\' }}.',

            QUOTE_APPROVAL_RESENT: 'Verzoek om goedkeuring opnieuw verzenden.',
            QUOTE_WORKFLOW_RESEND_APPROVAL: 'Verzoek opnieuw verzenden',

            QUOTE_ESCALATION_ADDED_ACTION: '{{ ::event._links["user"] ? ("<strong>" + event._links["user"].title + "</strong>") : "Het systeem heeft automatisch"  }} deze offerte geescaleerd.',
            QUOTE_ESCALATION_ADDED_SYSTEM_ACTION: 'The system automatically escalated the quote.',
            QUOTE_ESCALATION_REMOVED_ACTION: '<strong>{{ ::event._links["user"].title }}</strong> heeft de escalering gestopt.',

            QUOTE_ESCALATED_ACTION: '{{ ::event._links["user"] ? ("<strong>" + event._links["user"].title + "</strong>") : "Het systeem heeft automatisch"  }} deze offerte geescaleerd.',
            QUOTE_ESCALATED_SYSTEM_ACTION: 'The system automatically escalated the quote.',
            QUOTE_DEESCALATED_ACTION: '{{ ::event._links["user"] ? ("<strong>" + event._links["user"].title + "</strong>") : "Het systeem heeft automatisch" }} de escalering gestopt.',
            QUOTE_DEESCALATED_SYSTEM_ACTION: 'The system automatically removed the quote from escalation.',
            QUOTE_ESCALATION_CANCELLED_ACTION: '<strong>{{ ::event._links["user"].title }}</strong> de escalering gestopt.',

            QUOTE_WORKFLOW_ESCALATE_APPROVAL: 'Escalate Approval',

            QUOTE_CHASED_ACTION: 'A chase #{{ ::event.chaseSequence + 1 }} notification has been sent to <strong>{{ ::event._links["user"].title }}</strong>.',

            QUOTE_ALARM_UPDATED_ACTION: '<strong>{{ ::event._links["user"].title }}</strong> alarmering geupdated.',

            QUOTE_MESSAGE_ACTION: '<strong>{{ ::message._links["sender"].title }}</strong> bericht toegevoegd.',

            QUOTE_EXTENSION_REQUESTED_ACTION: '<strong>{{ ::event._links["user"].title }}</strong> requested an extension.',
            QUOTE_EXTENSION_REJECTED_ACTION: '<strong>{{ ::event._links["user"].title }}</strong> rejected the extension request.',
            QUOTE_EXTENSION_ACCEPTED_ACTION: '<strong>{{ ::event._links["user"].title }}</strong> accepted the extension request.',

            QUOTE_AUDIT_TRAIL_SENT_ACTION: '<strong>{{ ::event._links["user"].title }}</strong> sent the audit trail.',

            QUOTE_APPROVAL_REQUIRED: 'Goedgekeurde offertes',
            REQUEST_MORE_INFORMATION_SUCCESS: 'Meer informatie verzocht',

            QUOTE_REQUEST_REPORTER: 'Contactgegevens van object',
            PLEASE_SELECT_QUOTE_OPTIONS: 'Selecteer opties voor offerte',

            QUOTE_ACCEPTED: 'Offerte geaccepteerd',
            CANCEL_SUCCESS: 'Offerte geannuleerd',
            REOPEN_SUCCESS: 'Offerte heropend',
            ABSTAIN_SUCCESS: 'Offerte afgewezen',
            SUBMIT_SUCCESS: 'Offerte verzonden',
            REJECT_SUCCESS: 'Offerte afgewezen',
            HOLD_SUCCESS: 'Offerte on hold',
            DATE_DUE: 'Vervaldatum',
            DATE_IN: 'Ontvangstdatum',
            NO_SERVICE_PROVIDERS_FOR_SITE: 'Geen leveranciers op dit object',
            QUANTITY: 'Aantal',
            PO_NUMBER: 'Purchase order nummer',

            JOBTO: 'Naar opdracht',

            QUOTE_ACCEPTED_ACTION: 'Offerte geaccepteerd door {{event._links.user.title}}',
            QUOTE_CANCELLED_ACTION: 'Offerte geannuleerd door {{event._links.user.title}}',
            QUOTE_EXPIRED_ACTION: 'Vervallen offerte.',
            QUOTE_HOLD_ACTION: 'Offerte on hold gezet door {{event._links.user.title}}',
            QUOTE_REJECTED_ACTION: 'Offerte afgewezen door {{event._links.user.title}}',
            QUOTE_RFMI_ACTION: 'Meer informatie verzocht door {{event._links.user.title}}',
            QUOTE_SUBMIT_ACTION: 'Offerte ingezonden door {{event._links.user.title}}',

            P2P: 'P2P - proactis',
            P2P_CIS: 'CIS',
            P2P_TAX_CODE: 'BTW code',
            P2P_COMMENT: 'Commentaar',
            P2P_DESCRIPTION: 'Omschrijving',
            P2P_TRANSACTION: 'Transaction Type',
            P2P_SUPPLIER: 'Leverancier',
            P2P_SCHEDULE_NUMBER: 'Schedule Number',
            P2P_NOMINAL_LINES: 'Kostenregels',
            P2P_EXPENSE_CODE: 'Kostencode',
            P2P_TENANT_CODE: 'Huurder ID',
            P2P_RATIO: 'Ratio (% of costs)',
            P2P_PO_SUPPLIER: 'P2P Leverancier',
            ADD_LINE: 'Regel toevoegen',
            REMOVE_LINE: 'Regel verwijderen',

            P2P_ERROR_QUOTE_PENDING: 'P2P offerte in afwachting',

            NO_SEARCH_PROVIDED: 'Geen zoekfunctie aangeleverd',
            MISSING_ARGUMENT: 'Missing argument',

            QUOTE_OPTIONS_TAB_FILES: 'Gebruik aub de offerte tab om bijlagen toe te voegen aan een offerteoptie'
        });
    }
})();
