(function() {
    'use strict';

    angular
        .module('elogbooks.user.meter-manufacturers')
        .controller('MeterManufacturersAddEditController', MeterManufacturersAddEditController);

    MeterManufacturersAddEditController.$inject = ['$state', 'apiClient', 'confirmationModal', 'messenger', 'meterManufacturerResponse', 'meterManufacturersCollectionResponse', 'requestDataFactory'];

    function MeterManufacturersAddEditController($state, apiClient, confirmationModal, messenger, meterManufacturerResponse, meterManufacturersCollectionResponse, requestDataFactory) {
        var vm = this;
        vm.meterManufacturer = meterManufacturerResponse;
        vm.data = requestDataFactory.createRequest(meterManufacturerResponse);
        vm.meterManufacturersCollectionResponse = meterManufacturersCollectionResponse;

        vm.create = createAction;
        vm.update = updateAction;
        vm.delete = deleteAction;

        function updateAction() {
            return apiClient.update(vm.meterManufacturer.getLink('edit'), vm.data).then(function(response) {
                if (response) {
                    $state.go('^', {}, {reload: $state.get('^.^.^')}).then(function() {
                        messenger.success('METER_MANUFACTURER_UPDATED');
                    });
                } else {
                    messenger.error('REQUEST_ERROR');
                }
            });
        }

        function createAction() {
            return apiClient.create(vm.meterManufacturersCollectionResponse.getLink('create'), vm.data).then(function(response) {
                if (response) {
                    $state.go('^.details.info', {resource: response.getLink('self').encode()}, { reload: $state.current.parent }).then(function() {
                        messenger.success('METER_MANUFACTURER_CREATED');
                    });
                } else {
                    messenger.error('REQUEST_ERROR');
                }
            });
        }

        function deleteAction() {
            return confirmationModal.open().result.then(function(response) {
                if (response.result) {
                     apiClient.delete(vm.meterManufacturer.getLink('delete')).then(function(isSuccess) {
                        if (isSuccess) {
                            $state.go('^.^.^', {}, {reload: $state.get('^.^.^')}).then(function(){
                                messenger.success('METER_MANUFACTURER_DELETED');
                            });
                        } else {
                            messenger.error('REQUEST_ERROR');
                        }
                    });
                }
            });
        }
    }
})();
