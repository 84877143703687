app.config(function ($translateProvider) {
    $translateProvider.translations('en-gb', {
        AUDIT: 'Audit',
        AUDITS: 'Audits',
        AUDIT_LIST: 'Audits List',
        TEMPLATE_LIST: 'Audit Templates List',
        TEMPLATE_EDIT: 'Edit Audit Template',
        SCHEDULE_LIST: 'Audit Schedule List',
        OPTIONS: 'Options',
        BUTTONS: 'Buttons',

        AUDIT_ADD: 'Add a new Audit',
        TEMPLATE_ADD: 'Add a new Audit Template',
        SCHEDULE_ADD: 'Add a new Audit Schedule',

        QUESTIONS: 'Questions',
        QUESTION: 'Question',
        QUESTION_MAX_255: 'Question (Max 255 characters)',

        AUDIT_SUB_SECTIONS: 'Sub Sections',
        AUDIT_QUESTIONS: 'Questions',

        START_AUDIT: 'Start Audit',
        COMPLETE_AUDIT: 'Complete Audit',
        EDIT_AUDIT_REFERENCE: 'Edit Reference',

        AUDIT_CONFIRM_START_INSTRUCTIONS: 'Confirm start date',
        NOT_ALL_QUESTIONS_ANSWERED_FORCE_COMPLETE : 'Not all questions answered, confirm why you are completing audit',
        AUDIT_FORCE_COMPLETE_REASON: 'Audit force complete reason',

        QUESTIONS_ANSWERED: 'Number of Questions (Answered)',
        COMPLETION: 'Completion',

        NUMBER_OF_QUESTIONS: 'Number of Questions',
        ANSWERED_QUESTIONS: 'Questions Answered',

        NUMBER_OF_SECTIONS: 'Number of Sections',
        NUMBER_OF_SUB_SECTIONS: 'Number of Sub Sections',

        DEFAULT_BUTTON_OPTIONS: 'Default Button Options',

        NOT_YET_ANSWERED: 'Not Answered',

        CONFIRM_ANSWER_CHOICE: 'Confirm Answer Choice',
        AUDIT_ANSWER_EXTRA_INFORMATION: 'Optional Extra Information',

        AUDIT_ANSWER_OPTIONS_NO_DEFAULT: 'No defaults, set up manually',
        AUDIT_ANSWER_OPTIONS_NO_YES: 'No(0%), Yes(100%)',
        AUDIT_ANSWER_OPTIONS_YES_NO: 'Yes(0%), No(100%)',
        ANSWER_OPTION_ADDED: 'Answer Option Added',
        ANSWER_OPTION_UPDATED: 'Answer Option Updated',
        ANSWER_OPTION_VIEW: 'Answer Option Details',
        ANSWER_OPTION_TITLE_MAX_10_CHARACTERS: 'Button title must be 10 characters or less.',

        AUDIT_ASSISTED_COMMENTS: 'Assisted Comments',

        SCORE: 'Score',

        AUDIT_CREATED:  'Created',
        AUDIT_STARTED: 'In Progress',
        AUDIT_DRAFT: 'In Draft',
        AUDIT_CANCELLED:  'Cancelled',
        AUDIT_COMPLETED:  'Completed',
        AUDIT_TEMPLATES:  'Templates',
        AUDIT_TEMPLATE:  'Audit Template',
        AUDIT_TEMPLATE_ACTIVE:  'Active Template',
        AUDIT_TEMPLATE_INACTIVE:  'Inactive Template',
        AUDIT_SCHEDULE_ACTIVE:  'Active Schedule',
        AUDIT_SCHEDULE_CREATED:  'Audit Schedule Created',
        AUDIT_SCHEDULE_INACTIVE:  'Inactive Schedule',
        AUDIT_TEMPLATE_CREATED: 'Audit Template Created',
        AUDIT_TEMPLATE_SAVE: 'Save As Template',
        AUDIT_COPY_TEMPLATE: 'Copy Template',
        AUDIT_TEMPLATE_SUMMARY: 'Template Summary',
        AUDIT_TEMPLATE_STATUS: 'Template Status',
        AUDIT_TEMPLATE_VER: 'Template Version',

        AUDIT_TEMPLATE_CREATE_VERSION: 'Create New Version',
        AUDIT_TEMPLATE_NEW_VERSION_CREATED: 'New Version Created',
        AUDIT_TEMPLATE_ACTIVATE_VERSION: 'Activate Version',
        AUDIT_TEMPLATE_VERSION_ACTIVATED: 'Activated Version',
        AUDIT_TEMPLATE_DEACTIVATE_VERSION: 'Deactivate Version',
        AUDIT_TEMPLATE_VERSION_DEACTIVATED: 'Deactivated Version',
        AUDIT_ANSWER_OPTIONS: 'Answer Options',
        OPTION_LIST: 'Answer Options',
        OPTION_ADD: 'Add New Answer Option',

        AUDIT_SCHEDULES: 'Schedules',

        NO_OPERATIVES_FOUND: 'No primary audit operatives found',

        QUESTION_TYPE: 'Question Type',
        COMMENT_TYPE: 'Comment Only',
        BUTTONS_TYPE: 'Button Options',
        OPTIONS_TYPE: 'Drop-down Options',

        ATTACHMENT_REQUIRED: 'Attachment Required',

        WEIGHTING: 'Weighting',

        CREATE_AS_TEMPLATE: 'Create as template',
        CREATE_FROM_TEMPLATE: 'Create from template',

        INSTRUCTION: 'Instruction',

        AUDIT_SECTION: 'Section',

        BLANK_AUDIT_FORM_PDF: 'Generate Blank PDF',
        BLANK_AUDIT_FORM_PDF_QUEUED: 'Blank PDF Queued',
        PDF_REPORT: 'Generate PDF Report',
        PDF: 'PDF Export',
        ACTIVATE_AUDIT: 'Activate',
        CANCEL_AUDIT: 'Cancel',
        AUDIT_SECTIONS: 'Sections',
        AUDIT_EDIT: 'Edit Audit',
        SCHEDULE_EDIT: 'Edit Audit Schedule',
        AUDIT_UPDATED: 'Audit Updated',
        AUDIT_DELETED: 'Audit Deleted',
        AUDIT_CONFIRM_ACTIVATE_INSTRUCTIONS: 'Are you sure?',
        AUDIT_CONFIRM_CANCEL_INSTRUCTIONS: 'Please enter supporting text',
        AUDIT_CANCEL_REASON: 'Reason for cancellation',
        ADD_SECTION: 'Add Section',
        EDIT_SECTION: 'Edit Section',
        SECTION: 'Section',
        ADD_SUB_SECTION: 'Add Sub Section',
        ADD_QUESTION: 'Add Question',
        SECTIONS: 'Sections',
        AUDIT_SECTION_UPDATED: 'Audit Section Updated',
        AUDIT_UPDATED_TO_CANCELLED: 'Audit cancelled',

        AUDIT_HAS_NO_ASSIGNED_OPERATIVE: 'Audit has no assigned operative',
        AUDIT_HAS_NO_ASSIGNED_SERVICE_PROVIDER: 'Audit has no assigned service provider',
        AUDIT_HAS_NO_QUESTIONS: 'Audit has no questions',
        AUDIT_SECTION_CREATED: 'Audit Section Created',
        AUDIT_SECTION_DELETED: 'Audit Section Deleted',
        AUDIT_QUESTION_CREATED: 'Audit Question Created',
        AUDIT_QUESTION_UPDATED: 'Audit Question Updated',
        AUDIT_QUESTION_DELETED: 'Audit Question Deleted',
        AUDIT_UPDATED_TO_ACTIVATED: 'Audit activated',
        ADD_AUDIT_QUESTION: 'Add Question',
        EDIT_AUDIT_QUESTION: 'Edit Question',
        EDIT_AUDIT_TEMPLATE: 'Edit Answer Option',
        ADD_AUDIT_TEMPLATE: 'Add Answer Option',
        AUDIT_QUESTION_COMMENT: 'Comment',
        AUDIT_QUESTION_OPTIONS: 'Options',
        AUDIT_QUESTION_BUTTONS: 'Buttons',
        ANSWER: 'Answer',
        ANSWER_OPTIONS: 'Answer Options',
        POINTS_VALUE: 'Points',

        AUDIT_SECTION_ORDER_UPDATED:'Audit Section Order Updated',
        AUDIT_QUESTION_ORDER_UPDATED:'Audit Question Order Updated',

        AUDIT_IMAGE: 'Audit Image',

        CREATE_AS_AUDIT_SCHEDULE: 'Create New Schedule',
        SCHEDULE_START_AT: 'Schedule Start At',

        FORCE_COMPLETE: 'Force Complete',
        AUDIT_COMPLETE_DATE: 'Audit Completed At',

        RESET_SECTIONS: 'Reset Order',
        RESET_QUESTIONS: 'Reset Order',
        RESET_ANSWER_OPTIONS_ORDER: 'Reset Options Order',
        ANSWER_OPTION_EDIT: 'Edit Answer Option',
        OUT_OF: '/',
        DATE_STARTED_AT: 'Started At',
        ANSWER_COMMENT: 'Comment',
        REVERT: 'Revert',
        QUESTION_ANSWERED: 'Answer Submitted',
        QUESTION_REVERTED: 'Answer Reverted',
        ANSWER_OPTION: 'Answer Option',
        SELECTED_ANSWER: 'Selected Answer',
        ANSWER_SCORE: 'Answer Score',
        ANSWERED: 'Answered',

        SHOW_ANSWER: 'Show',
        HIDE_ANSWER: 'Hide',

        AUDIT_INSTRUCTION: 'Audit Instruction',

        AUDIT_UPDATED_TO_COMPLETED: 'Audit Completed',
        FORCE_COMPLETED: 'Completed note',

        ADD_ANSWER_OPTION: 'Add answer option',
        AUDIT_ANSWER_OPTION_CREATED: 'Answer option created',
        ANSWER_OPTION_DELETED: 'Answer option deleted',
        ANSWER_OTPION_UPDATED: 'Answer option updated',
        AUDIT_ANSWER_OPTIONS_ORDER_UPDATED: 'Audit answer options order updated',

        AUDIT_UPDATED_TO_STARTED: 'Audit started',

        AUDIT_AT_LEAST_ONE_SECTION_HAS_NO_QUESTIONS: 'Cannot activate audit, at least one section has no questions',
        AUDIT_AT_LEAST_ONE_QUESTION_HAS_NO_ANSWER_OPTIONS: 'At least one question has no answer options',
        NOT_ALL_QUESTIONS_ANSWERED: 'Not all audit questions answered',
        NO_PERMISSION_TO_FORCE_COMPLETE: 'Dont have permission to force complete audit',
        NO_PERMISSION_TO_EDIT_AUDIT_REFERENCE: 'Don\'t have permission to edit audit reference',

        EDIT_SUB_SECTION: 'Edit sub section',

        ANSWER_BELOW_100_REQUIRES_PRIORITY: 'Priority required',
        AUDIT_QUESTION_RAISE_ACTION_SELECT_PRIORITY: 'You have selected an answer that requires an Action to be raised. Please provide the below information.',

        DATE_CANCELLED: 'Date Cancelled',
        CANCEL_REASON: 'Cancel Reason',

        SERVICE_PROVIDER_NO_MATCH: 'There is no match for the service provider and the site you have chosen',

        SITES_REQUIRED: 'Site/sites are required',

        CANNOT_CHANGE_FROM_PUBLIC: 'Cannot change from Public to Private',
        DUPLICATE_ANSWER_OPTION: 'Duplicates Are Not Allowed',

        AUDITS_DASHBOARD: 'Audits Dashboard',
        AUDITS_RAISED_IN_LAST_60_DAYS: 'Audits Raised in Last 60 Days',
        AUDITS_RECENTLY_COMPLETED: 'Recently Completed Audits',
        AUDITS_NEXT: 'Next Upcoming Audits',
        AUDITS_NEXT_SCHEDULED: 'Next Scheduled Audits',
        AUDITS_GENERATED_FOR_DATE: 'Generated for date',
        AUDITS_GENERATED_FOR_DATE_START: 'Generated For Date Start',
        AUDITS_GENERATED_FOR_DATE_END: 'Generated For Date End',
        AUDITS_SCHEDULES: 'Audit Schedules',
        AUDITS_SCHEDULE: 'Schedule',

        FORCE_RAISE: 'Force Raise',
        FORCE_RAISE_CHOICE: 'Please raise a Job or an Action',
        REQUIRE_ACTION: 'Require Action',
        REQUIRE_EITHER: 'Require Either',
        RAISE_ACTION: 'Raise Action',
        ACTION_RAISED: 'Action Raised',
        REQUIRE_JOB: 'Require Job',

        ACTION_HAS_QUESTION: 'There is already an action for this question',

        NO_PERMISSION_TO_RAISE_JOB: 'You do not have permission to raise a job, question cannot be answered',
        AUDIT_OPEN_ACTION: 'Open Audit Actions',
        ANSWER_UPDATED: 'Answer Updated',
        NEXT_OCCURENCE: 'Next Occurence',
        NEXT_OCCURENCE_START: 'Next Occurence Start',
        NEXT_OCCURENCE_END: 'Next Occurence End',
        COMPLETED_AT_START: 'Completed At Start',
        COMPLETED_AT_END: 'Completed At End',

        AUDIT_TEMPLATE_VERSION: 'Version',

        DISABLED_SUMMARY_FIELD: 'Unable to edit summary due to template being activated',
        COPY_FROM_ANSWER_OPTIONS: 'Copy from Answer Options',
        SCHEDULE_NAME: 'Schedule Name',
        TEMPLATE_LINK: 'Link to Template',

        SCHEDULE_INVALID_INVALID_LENGTH: 'Schedule Name is too long. It should have 100 characters or less.',
        MARK_SECTION_NA: 'Mark section as N/A',
        UNMARK_SECTION_NA: 'Unmark section as N/A',
        MARKED_AS_NA_SUCCESS: 'Section marked as N/A successfully',
        UNMARKED_AS_NA_SUCCESS: 'Section unmarked as N/A successfully',

        RAISE_AN_ACTION: 'Raise an Action'
    });
});
