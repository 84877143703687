(function () {
    'use strict';

    angular
        .module('elogbooks.common.jobs')
        .controller('CommonJobAddController', CommonJobAddController);

    CommonJobAddController.$inject = ['$state', 'requestDataFactory', 'confirmationModal', 'config', '$scope', 'actionResponse', 'siteResourceResponse', 'notesCollectionResponse', 'lodash', 'apiClient', 'user', '$q', 'spillageResponse'];

    function CommonJobAddController($state, requestDataFactory, confirmationModal, config, $scope, actionResponse, siteResourceResponse, notesCollectionResponse, lodash, apiClient, user, $q, spillageResponse) {

        // TODO notes is already being retrieved in comjobaddsite

        var vm = this;

        vm.data = requestDataFactory.createRequest(
            {
                _links: {
                    location: null
                },
                files: {
                    links: []
                },
                optedInJobRequisites: {
                    links: []
                }
            }
        );
        vm.config = config;
        vm.selectedSite = null;
        vm.submitDisabled = false;
        vm.uploading = false;
        vm.uploadedFile = null;
        vm.notes = false;
        vm.jobs = false;
        vm.contacts = false;
        vm.showLocationParentAdd = false

        var approversMapped = [];
        var associatesMapped = [];

        if (typeof notesCollectionResponse !== 'undefined') {
            vm.notes = notesCollectionResponse.siteNotes;
        }

        $scope.$on('removeSite', function (event, data) {
            vm.notes = false;
            vm.jobs = false;
            vm.contacts = false;
            vm.selectedSite = null;
        });

        vm.cancel = cancelAction;
        vm.getRoute = getRoute;
        vm.getSiteJobs = getSiteJobs;
        vm.getContacts = getContacts;
        vm.getViewRoute = getViewRoute;

        if (actionResponse) {
            vm.data._links.action = {href: actionResponse.getLink('self')};
        }

        if (spillageResponse) {
            vm.data._links.fromSpillage = {href: spillageResponse.getLink('self')};
        }

        function getSiteJobs() {
            var params = {
                order: 'updated',
                type: 'reactive',
                limit: 5
            };

            if (!vm.jobs && vm.selectedSite !== null) {
                vm.jobs = [];
                vm.jobs.loading = true;

                apiClient.get(siteResourceResponse.getLink('jobs'), params).then(function (response) {
                    vm.jobs = response.jobs;
                });
            }
        }

        function getContacts() {
            if (!vm.contacts && vm.selectedSite !== null) {
                vm.contacts = [];
                vm.contacts.loading = true;

                $q.all([
                    apiClient.get(vm.selectedSite.getLink('approvers')).then(function (response) {
                        vm.approvers = response;
                    }),
                    apiClient.get(vm.selectedSite.getLink('associates')).then(function (response) {
                        vm.associates = response.associates;
                    })
                ]).then(function () {
                    vm.contacts.loading = false;
                    for (var approver in vm.approvers) {
                        if (approver === 'reactiveJobApprovers' && vm.approvers[approver].hasOwnProperty('approvers')) {
                            if (vm.approvers[approver].approvers.hasOwnProperty('memberships')) {
                                vm.approvers[approver].approvers.memberships.forEach(function (value) {
                                    approversMapped.push({
                                        type: 'Approver',
                                        href: value._links.contact.href,
                                        name: value._links.contact.title,
                                        email: value._links.contact.email,
                                        phone: value._links.contact.phone
                                    });
                                });
                            }
                        }
                    }

                    associatesMapped = vm.associates.map(function (value) {
                        return {
                            type: value._links.type.title,
                            href: value._links.contact.href,
                            name: value._links.contact.title,
                            email: value._links.contact.email,
                            phone: value._links.contact.phone
                        };
                    });

                    if (approversMapped && associatesMapped) {
                        vm.contacts = approversMapped.concat(associatesMapped);
                    }
                });
            }
        }

        function cancelAction() {
            var config = {
                titleMessage: 'CANCEL_JOB',
                bodyMessage: 'CANCEL_JOB_ADD'
            };

            return confirmationModal.open(config).result.then(
                function () {
                    var reloadState = $state.current.name.substring(0, $state.current.name.lastIndexOf('.list')) + '.list';
                    $state.go(reloadState, {}, {reload: false});
                },
                function () {
                    //Cancel
                }
            );
        }

        function getRoute(route) {
            return $state.current.name.substring(0, $state.current.name.indexOf('.add')) + '.add.' + route;
        }

        function getViewRoute(job, parameters) {
            var route;

            if (!parameters) {
                parameters = vm.config.parameters || {};
            }

            parameters[vm.config.resourceName || ''] = job.getLink('self').encode();

            if ($state.current.name.indexOf('dashboard.user.jobs.list') > -1) {
                route = 'dashboard.user.jobs.details.status';
            } else {
                route = 'dashboard.user.sites.list.details.jobs.list.details.status';
            }

            return route + '(' + JSON.stringify(vm.config.parameters || parameters) + ')';
        }

        $scope.$watch('vm.uploadedFile', function (newValue) {
            vm.data._links.file = null;

            if (newValue) {
                vm.data._links.file = vm.uploadedFile._links.file;
            }
        });
    }
})();
