(function () {
    'use strict';

    angular
        .module('elogbooks.admin.sites')
        .controller('SiteAddEditController', SiteAddEditController);

    SiteAddEditController.$inject = ['$scope',
        '$state',
        'apiClient',
        'messenger',
        'siteCollectionResponse',
        'siteResponse',
        'requestDataFactory',
        'siteGroupsCollectionResponse',
        'regionsCollectionResponse',
        'sectorsCollectionResponse',
        'billingTypeCollectionResponse',
        'meridianConfigurationCollectionResponse',
        'confirmationModal',
        'modulesService',
        'user',
        'lodash',
        'confirmationModalNotes',
        'userManager'
    ];

    function SiteAddEditController (
        $scope,
        $state,
        apiClient,
        messenger,
        siteCollectionResponse,
        siteResponse,
        requestDataFactory,
        siteGroupsCollectionResponse,
        regionsCollectionResponse,
        sectorsCollectionResponse,
        billingTypeCollectionResponse,
        meridianConfigurationCollectionResponse,
        confirmationModal,
        modulesService,
        user,
        lodash,
        confirmationModalNotes,
        userManager
    ) {

        var vm = this;
        vm.site = siteResponse;
        vm.changeSiteGroup = changeSiteGroup;
        vm.changeRegion = changeRegion;
        vm.changeSector = changeSector;
        vm.changeBillingType = changeBillingType;
        vm.pcdEnabled = modulesService.isEnabled('pcd');
        vm.p2pEnabled = modulesService.isEnabled('p2p');
        vm.qComplianceTypeEnabled = modulesService.isEnabled('qcompliance');
        vm.ospEnabled = modulesService.isEnabled('osp');
        vm.riskwiseEnabled = modulesService.isEnabled('riskwise');
        vm.billingTypes = false;
        vm.billingCost = false;
        vm.meridianSystemLinksModel = lodash.get(meridianConfigurationCollectionResponse, 'systemLinks');
        vm.meridianPendingRequests = lodash.get(meridianConfigurationCollectionResponse, 'pendingRequests');
        vm.isClientAdmin = userManager.hasPermission('user_permission_client_admin_manage_sites') && !userManager.hasPermission('user_permission_manage_sites');

        if (lodash.includes(user.permissions,'user_permission_manage_billing_types') || lodash.includes(user.permissionsInherited,'user_permission_manage_billing_types')) {
            vm.billingTypes = true;
            vm.billingCost = true;
        }

        vm.selectedGroupModel = {
            response: siteGroupsCollectionResponse,
            link: siteGroupsCollectionResponse.getLink('self'),
            required: false,
            responseKeyPath: 'sitegroups',
            itemHrefPath: '_links.self.href',
            itemValuePath: 'name',
            onSelect: vm.changeSiteGroup,
            onRemove: vm.changeSiteGroup
        };

        vm.selectedRegionModel = {
            response: regionsCollectionResponse,
            link: regionsCollectionResponse.getLink('self'),
            required: false,
            responseKeyPath: 'regions',
            itemHrefPath: '_links.self.href',
            itemValuePath: 'name',
            onSelect: vm.changeRegion,
            onRemove: vm.changeRegion
        };

        if (billingTypeCollectionResponse) {
            vm.selectedBillingTypeModel = {
                response: billingTypeCollectionResponse,
                link: billingTypeCollectionResponse.getLink('self'),
                linkParameters: {order: 'default'},
                required: vm.billingTypes,
                responseKeyPath: 'billingTypes',
                itemHrefPath: '_links.self.href',
                itemValuePath: 'name',
                onSelect: vm.changeBillingType,
                onRemove: vm.changeBillingType
            };
        }

        vm.selectedSectorModel = {
            response: sectorsCollectionResponse,
            link: sectorsCollectionResponse.getLink('self'),
            required: false,
            responseKeyPath: 'sectors',
            itemHrefPath: '_links.self.href',
            itemValuePath: 'name',
            onSelect: vm.changeSector,
            onRemove: vm.changeSector
        };

        function changeSiteGroup() {
            if (vm.selectedGroupModel.selected && vm.selectedGroupModel.selected.object) {
                vm.selectedGroup = vm.selectedGroupModel.selected.object;
                vm.data._links.sitegroup = vm.selectedGroup.getLink('self');
            } else {
                vm.data._links.sitegroup = null;
            }
        }

        function changeRegion() {
            if (vm.selectedRegionModel.selected && vm.selectedRegionModel.selected.object) {
                vm.selectedRegion = vm.selectedRegionModel.selected.object;
                vm.data._links.region = vm.selectedRegion.getLink('self');
            } else {
                vm.data._links.region = null;
            }
        }

        function changeSector() {
            if (vm.selectedSectorModel.selected && vm.selectedSectorModel.selected.object) {
                vm.selectedSector = vm.selectedSectorModel.selected.object;
                vm.data._links.sector = vm.selectedSector.getLink('self');
            } else {
                vm.data._links.sector = null;
            }
        }

        function changeBillingType() {
            if (vm.selectedBillingTypeModel.selected && vm.selectedBillingTypeModel.selected.object) {
                vm.billingType = vm.selectedBillingTypeModel.selected.object;
                vm.data._links.billingtype = vm.billingType.getLink('self');
                vm.billingCostModel = vm.billingType.cost;
            } else {
                vm.data._links.billingtype = null;
            }
        }

        vm.data = requestDataFactory.createRequest({
            _links: {
                sitegroup: vm.site._links ? vm.site._links.sitegroup : null,
                region: vm.site._links ? vm.site._links.region : null,
                billingtype: vm.site._links ? vm.site._links.billingtypes : null,
                sector: vm.site._links ? vm.site._links.sector : null,
                image: vm.site._links ? vm.site._links.image : null
            },
            name: vm.site.name || null,
            reference: vm.site.reference || null,
            referencePrefix: vm.site.referencePrefix || null,
            externalReference: vm.site.externalReference || null,
            poRequired: vm.site.poRequired || false,
            area: vm.site.area || 0,
            areaUnits: vm.site.areaUnits || null,
            activeAt: vm.site.activeAt ? new Date(vm.site.activeAt) : null,
            inactiveAt: vm.site.inactiveAt ? new Date(vm.site.inactiveAt) : null,
            p2pStartAtDate: vm.site.p2pStartAtDate ? new Date(vm.site.p2pStartAtDate) : null,
            isLocationRequired: vm.site.locationRequired || false,
            excludeFromHelpdeskMonitor: vm.site.excludeFromHelpdeskMonitor || false,
            excludeFromHelpdeskDashboard: vm.site.excludeFromHelpdeskDashboard || false,
            excludeFromRequiringDocumentType: vm.site.excludeFromRequiringDocumentType || false,
            riskwiseSystemType: vm.site.riskwiseSystemType || null,
            occupancyHeadcount: vm.site.occupancyHeadcount ? vm.site.occupancyHeadcount : null,
            ospReference: vm.site.ospReference || null,
            meridianSystemLink: vm.site.meridianSystemLink || null,
        });

        vm.billingCostModel = parseFloat(vm.site.billingCost) !== null ? parseFloat(vm.site.billingCost) : null;

        if (vm.pcdEnabled) {
            vm.data.pcdExclude = vm.site.pcdExclude || false;
        }

        if (vm.qComplianceTypeEnabled) {
            vm.data.qComplianceSystemType = vm.site.qComplianceSystemType || null;
        }

        if (vm.p2pEnabled) {
            vm.data.p2pExclude = vm.site.p2pExclude || false;
        }

        // set defaults on page load
        vm.selectedGroupModel.selected = vm.data._links.sitegroup;
        vm.selectedRegionModel.selected = vm.data._links.region;
        vm.selectedSectorModel.selected = vm.data._links.sector;

        if (billingTypeCollectionResponse) {
            vm.selectedBillingTypeModel.selected = vm.data._links.billingtype;
        }

        vm.update = function () {
            if (vm.data.areaUnits === '') {
                delete vm.data.areaUnits;
            }

            if ($scope.siteForm.activeAtDate.$pristine && $scope.siteForm.inactiveAtDate.$pristine) {
                return apiClient.update(vm.site._links.self.href, vm.data).then(function (response) {
                    if (response) {
                        $state.go('dashboard.admin.sites.list.details.info', { skipNavigationConfirmation: true }, { reload: true }).then(function () {
                            messenger.success('SITE_UPDATED');
                        });
                    } else {
                        messenger.error('REQUEST_ERROR');
                    }
                });
            }

            confirmationModalNotes.open({titleMessage: 'ARE_YOU_SURE_HEADER', bodyMessage: 'REASON_FOR_STATUS_CHANGE', required: true}).result.then(function (note) {
                if (note) {
                    vm.data.note = note;
                    apiClient.update(vm.site._links.self.href, vm.data).then(function (response) {
                        if (response) {
                            $state.go('dashboard.admin.sites.list.details.info', { skipNavigationConfirmation: true }, { reload: true }).then(function () {
                                messenger.success('SITE_UPDATED');
                            });
                        } else {
                            messenger.error('REQUEST_ERROR');
                        }
                    });
                }
            });
        };

        vm.create = function () {
            if (vm.data.areaUnits === '') {
                delete vm.data.areaUnits;
            }

            apiClient.create(siteCollectionResponse.getLink('create'), vm.data).then(function (response) {
                if (response) {
                    $state.go('dashboard.admin.sites.list.details.info', { resource: response.getLink('self').encode(), skipNavigationConfirmation: true }, { reload: true }).then(function () {
                        messenger.success('SITE_CREATED');
                    });
                } else {
                    messenger.error('REQUEST_ERROR');
                }
            });
        };

        vm.delete = function () {
            return confirmationModal.open().result.then(function (response) {
                if (response.result) {
                    return apiClient.delete(vm.site.getLink('delete')).then(function (response) {
                        if (response) {
                            $state.go('^.^.^', {}, { reload: true }).then(function () {
                                messenger.success('SITE_DELETED');
                            });
                        }
                    });
                }

                return;
            });
        };
    }
})();
