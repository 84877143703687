(function () {
    'use strict';

    angular
        .module('elogbooksControllers')
        .controller('QuoteModalController', QuoteModalController);

    QuoteModalController.$inject = ['$scope', '$state', 'messenger', 'apiClient', 'config', '$uibModalInstance', 'quoteResponse', 'quoteRequestResponse', 'workRequiredResponse', 'materialsResponse', 'lodash', 'quoteService', 'moment', 'requestDataFactory', 'lodash'];

    function QuoteModalController($scope, $state, messenger, apiClient, config, $uibModalInstance, quoteResponse, quoteRequestResponse, workRequiredResponse, materialsResponse, quoteService, lodash) {
        var vm = this;
        vm.confirm = confirm;
        vm.close = close;
        vm.config = config;

        vm.quoteResponse = quoteResponse;
        vm.quoteResponse.description = quoteRequestResponse.description;
        // Force the lists to hide any interaction buttons (view etc)
        workRequiredResponse.hideButtons = true;
        workRequiredResponse.canCreate = false;
        materialsResponse.hideButtons = true;
        materialsResponse.canCreate = false;

        vm.workRequiredResponse = workRequiredResponse;
        vm.materialsResponse = materialsResponse;

        function close() {
            $uibModalInstance.dismiss(vm.instance);
        }

        function confirm() {
            $uibModalInstance.close(vm.instance);
        }
    }
})();
