(function () {
    'use strict';

    angular
        .module('elogbooks.user.reports')
        .controller('UserReportsEscalatedQuotesController', UserReportsEscalatedQuotesController);

    UserReportsEscalatedQuotesController.$inject = ['$state', 'quotesCollection', 'operativeResponse', 'modalConfig'];

    function UserReportsEscalatedQuotesController($state, quotesCollection, operativeResponse, modalConfig) {
        var vm = this;

        vm.quotesCollection = quotesCollection;
        vm.quotes = quotesCollection.quotes;
        vm.op = operativeResponse;

        vm.modalConfig = modalConfig;
    }
})();