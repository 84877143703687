(function() {
    'use strict';

    angular
        .module('elogbooks.user.meter-manufacturers')
        .config(registerRoutes)
        .config(registerRoutesList)
        .config(registerRoutesAdd)
        .config(registerRoutesDetails)
        .config(registerRoutesEdit);

    function registerRoutes($stateProvider) {
        $stateProvider
            .state('dashboard.user.meters.manufacturers', {
                abstract: true,
                template: '<bookerize></bookerize>',
                ncyBreadcrumb: {
                    skip: true
                }
            });
    }

    function registerRoutesList($stateProvider) {
        $stateProvider
            .state('dashboard.user.meters.manufacturers.list', {
                url: '/meter-manufacturers?page&limit&order&id&name',
                parent: 'dashboard.user.meters.manufacturers',
                views: parseBook({
                    'elogbooks-left@dashboard.user.meters.manufacturers': {
                        templateUrl: '/modules/user/meters/meter-manufacturers/meter-manufacturer-list.html',
                        controller: 'MeterManufacturersController',
                        controllerAs: 'vm'
                    }
                }),
                params: {
                    page: '1',
                    limit: '30',
                    order: 'id',
                    id: null,
                    name: null
                },
                resolve: {
                    meterManufacturersCollectionResponse: function($stateParams, apiClient, rootResourceResponse) {
                        return apiClient.get(rootResourceResponse.getLink('metermanufacturers'), $stateParams).then( function(response) {
                            return response || apiClient.noResourceFound();
                        });
                    }
                },
                ncyBreadcrumb: {
                    label: '{{ ::"METER_MANUFACTURER_LIST" | translate }}'
                }
            });
    }

    function registerRoutesAdd($stateProvider) {
        $stateProvider
            .state('dashboard.user.meters.manufacturers.list.add', {
                url: '/add',
                parent: 'dashboard.user.meters.manufacturers.list',
                views: parseBook({
                    'elogbooks-right@dashboard.user.meters.manufacturers': {
                        templateUrl: '/modules/user/meters/meter-manufacturers/add-edit/meter-manufacturer-add-edit.html',
                        controller: 'MeterManufacturersAddEditController',
                        controllerAs: 'vm'
                    }
                }),
                resolve: {
                    meterManufacturerResponse: function() {
                        return {};
                    }
                },
                ncyBreadcrumb: {
                    label: '{{ ::"ADD" | translate }}'
                }
            });
    }

    function registerRoutesDetails($stateProvider) {
        $stateProvider
            .state('dashboard.user.meters.manufacturers.list.details', {
                url: '/details/{resource}',
                abstract: true,
                parent: 'dashboard.user.meters.manufacturers.list',
                views: parseBook({
                    'elogbooks-right@dashboard.user.meters.manufacturers': {
                        templateUrl: '/modules/user/meters/meter-manufacturers/details/meter-manufacturer-details.html',
                        controller: 'MeterManufacturersDetailsController',
                        controllerAs: 'vm'
                    }
                }),
                resolve: {
                    meterManufacturerResponse: function(apiClient, $stateParams) {
                        return apiClient.get($stateParams.resource.decode()).then( function(response) {
                            return response || apiClient.noResourceFound();
                        });
                    }
                },
                ncyBreadcrumb: {
                    label: '{{ ::$resolves().meterManufacturerResponse.name }}'
                }
            })
             .state('dashboard.user.meters.manufacturers.list.details.info', {
                url: '/info',
                parent: 'dashboard.user.meters.manufacturers.list.details',
                views: parseBook({
                    'elogbooks-right@dashboard.user.meters.manufacturers.list.details': {
                        templateUrl: '/modules/user/meters/meter-manufacturers/details/info/meter-manufacturer-info.html',
                        controller: 'MeterManufacturersInfoController',
                        controllerAs: 'vm'
                    }
                }),
                ncyBreadcrumb: {
                    label: '{{ ::"INFO" | translate }}'
                }
            });
    }

    function registerRoutesEdit($stateProvider) {
        $stateProvider
            .state('dashboard.user.meters.manufacturers.list.details.info.edit', {
                url: '/edit',
                parent: 'dashboard.user.meters.manufacturers.list.details.info',
                views: parseBook({
                    'elogbooks-right@dashboard.user.meters.manufacturers': {
                        templateUrl: '/modules/user/meters/meter-manufacturers/add-edit/meter-manufacturer-add-edit.html',
                        controller: 'MeterManufacturersAddEditController',
                        controllerAs: 'vm'
                    }
                }),
                ncyBreadcrumb: {
                    label: '{{ ::"EDIT" | translate }}'
                }
            });
    }

})();
