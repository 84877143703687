(function() {
    'use strict';

    angular
        .module('elogbooks')
        .config(Translations);

    function Translations($translateProvider) {
        $translateProvider.translations('nl', {
            JOB: 'Opdracht',
            JOBS: 'Opdrachten',
            JOB_LIST: 'Opdrachtenlijst',

            JOB_REACTIVE_APPROVAL_REQUIRED:'Correctieve opdrachten voor goedkeuring',

            LOG_A_NEW_JOB: 'Maak een nieuwe opdracht',
            ADD_NEW_JOB: 'Voeg een nieuwe opdracht toe',
            JOB_ADD: 'Voeg een opdracht toe',
            ALL_JOBS: 'Opdrachten',

            CREATED_AT_START: 'Gemaakt',
            CREATED_AT_END: 'Gemaakt bij einde',
            ALARMED_AT_START: 'Alarm',
            ALARMED_AT_END: 'Gealarmeerd bij einde',
            REMINDER_AT_START: 'Herinnering',
            REMINDER_AT_END: 'Herinnering bij einde',
            ATTENDANCE_DUE_AT_START: 'Aanwezig',
            ATTENDANCE_DUE_AT_END: 'Aanwezig',
            COMPLETION_DUE_AT_START: 'Afgerond voor',
            COMPLETION_DUE_AT_END: 'Afgerond voor',
            LAST_SUBMITTED_AT: 'Doorgestuurd',
            LAST_SUBMITTED_AT_START: 'Doorgestuurd',
            LAST_SUBMITTED_AT_END: 'Doorgestuurd',

            // Job details screen
            JOB_ID: 'PO #{{ ::id }}',
            JOB_DETAILS: 'Opdrachtomschrijving',
            JOB_DETAILS_WITH_ID: 'PO #{{ ::id }} details',
            JOB_STATUS: 'Status',
            JOB_EVENTS: 'Gebeurtenissen',
            // JOB_MESSAGE_ACTION: '<strong>{{ ::message.sender }}</strong> added a message.',
            JOB_CREATED_ACTION: '<strong>{{ ::event._links["user"].title }}</strong> heeft een opdracht aangemaakt.',
            JOB_MESSAGE_ACTION: '<strong>{{ ::message._links["sender"].title }}</strong> heeft een bericht toegevoegd.',
            JOB_ACTIONED_ACTION: 'Actie nodig, deadline is verstreken.',
            JOB_ALARM_UPDATED_ACTION: '<strong>{{ ::event._links["user"].title }}</strong> heeft een opdracht aangepast.',
            JOB_APPROVAL_REQUESTED_ACTION: '<strong>{{ ::event._links["user"].title }}</strong> heeft een verzoek tot goedkeuring verzonden.',
            JOB_APPROVAL_ACCEPTED_ACTION: '<strong>{{ ::event._links["user"].title }}</strong> heeft een opdracht goedgekeurd.',
            JOB_APPROVAL_REJECTED_ACTION: '<strong>{{ ::event._links["user"].title }}</strong> heeft een opdracht afgekeurd.',
            JOB_APPROVAL_RESENT_ACTION: '<strong>{{ ::event._links["user"].title }}</strong> heeft een goedkeuringsverzoek nogmaals verzonden {{ ::event._links["approver"].title }}.',
            JOB_ASSIGNMENT_REQUESTED_ACTION: '<strong>{{ ::event._links["user"].title }}</strong> verzend opdrachtverzoek naar {{ ::event._links["operative"].title }}.',
            JOB_ASSIGNMENT_REASSIGNED_ACTION: '<strong>{{ ::event._links["user"].title }}</strong> heeft een opdracht nogmaals uitgegeven.',
            JOB_ASSIGNMENT_ACCEPTED_ACTION: '<strong>{{ ::event._links["user"].title }}</strong> heeft een opdracht geaccepteerd.',
            JOB_ASSIGNMENT_REJECTED_ACTION: '<strong>{{ ::event._links["user"].title }}</strong> heeft een opdracht afgewezen.',
            JOB_ASSIGNMENT_RESENT_ACTION: '<strong>{{ ::event._links["user"].title }}</strong> heeft een opdrachtverzoek nogmaals verzonden {{ ::event._links["operative"].title }}.',
            JOB_ASSIGNMENT_INSISTED_ACTION: '<strong>{{ ::event._links["user"].title }}</strong> staat er op dat de opdracht wordt geaccepteerd.',
            JOB_PROPOSAL_REQUESTED_ACTION: '<strong>{{ ::event._links["user"].title }}</strong> heeft een offerte gestuurd.',
            JOB_PROPOSAL_ACCEPTED_ACTION: '<strong>{{ ::event._links["user"].title }}</strong> heeft een offerte geaccepteerd.',
            JOB_PROPOSAL_REJECTED_ACTION: '<strong>{{ ::event._links["user"].title }}</strong> heeft de offerte afgewezen.',
            JOB_PROPOSAL_ACCEPTED_SYSTEM_ACTION: 'Verzoek om verlenging automatisch geaccepteerd.',
            JOB_EXTENSION_REQUESTED_ACTION: '<strong>{{ ::event._links["user"].title }}</strong> heeft een verzoek tot uitstel gedaan.',
            JOB_EXTENSION_ACCEPTED_ACTION: '<strong>{{ ::event._links["user"].title }}</strong> heeft een verzoek tot uitstel geaccepteerd.',
            JOB_EXTENSION_ACCEPTED_SYSTEM_ACTION: 'Verzoek om verlenging automatisch geaccepteerd.',
            JOB_EXTENSION_REJECTED_ACTION: '<strong>{{ ::event._links["user"].title }}</strong> heeft een verzoek tot uitstel afgewezen.',
            JOB_OPERATION_ACKNOWLEDGED_ACTION: '<strong>{{ ::event._links["user"].title }}</strong> heeft de opdracht bevestigd.',
            JOB_OPERATION_COMMENCED_ACTION: '<strong>{{ ::event._links["user"].title }}</strong> is begonnen met de opdracht.',
            JOB_OPERATION_COMPLETED_ACTION: '<strong>{{ ::event._links["user"].title }}</strong> heeft de opdracht afgerond.',
            JOB_OPERATION_COMPLETED_PAPERWORK_ACTION: '<strong>{{ ::event._links["user"].title }}</strong> heeft papierwerk toegevoegd aan de opdracht.',
            JOB_POSTPONE_REMINDER_AT_ACTION: '<strong>{{ ::event._links["user"].title }}</strong> heeft de uiterste datum gewijzigd naar {{ ::event.reminderAt | date:\'short\' }}.',
            JOB_OPERATION_MISSED_ACTION: '<strong>{{ ::event._links["user"].title }}</strong> heeft de opdracht niet uitgevoerd.',
            JOB_OPERATION_CHASED_ACTION: 'Een herinneringsbericht #{{ ::event.chaseSequence + 1 }} is verzonden naar <strong>{{ ::event._links["user"].title }}</strong>.',
            JOB_OPERATION_REOPENED_ACTION: '<strong>{{ ::event._links["user"].title }}</strong> heeft een opdracht heropend.',
            JOB_OPERATION_CANCELLED_ACTION: '<strong>{{ ::event._links["user"].title }}</strong> heeft een opdracht geannuleerd.',
            JOB_APPROVAL_ESCALATED_ACTION: '<strong>{{ ::event._links["user"].title }}</strong> heeft de opdracht geescaleerd.',
            JOB_AUDIT_TRAIL_SENT_ACTION: '<strong>{{ ::event._links["user"].title }}</strong> heeft audit trail verzonden.',
            JOB_ESCALATION_ADDED_ACTION: '<strong>{{ ::event._links["user"].title }}</strong> heeft de opdracht geescaleerd.',
            JOB_ESCALATION_REMOVED_ACTION: '<strong>{{ ::event._links["user"].title }}</strong> heeft de opdracht verwijderd.',
            JOB_ENTER_DETAILS_MANUALLY: 'Voeg gegevens toe',
            JOB_CHOOSE_A_USER: 'kies gebruiker',
            JOB_CHOOSE_A_TENANT: 'kies huurder',
            JOB_REPORTER_NAME: 'naam melder',
            JOB_CONTACT_NAME: 'naam contact',
            JOB_PHONE_NUMBER: 'Telefoonnummer',
            JOB_RELATIONSHIP_TO_THE_PROPERTY: 'Relatie tot het gebouw',
            JOB_NOTIFY_ON_CREATION: 'Geef bericht wanneer gemaakt',
            JOB_NOTIFY_ON_COMPLETION: 'Geef bericht wanneer afgerond',
            JOB_SKIP_REQUEST_APPROVAL_NOTIFICATION: 'Sla goedkeuringsbericht over',
            JOB_NEXT_STEP: 'Volgende stap',
            JOB_SAME_AS_CALLER: 'Overnemen van melder',
            JOB_SITE_CONTACT_MYSELF: 'Ik ben het contact op locatie',
            JOB_SITE_CONTACT_AVAILABLE: 'Gebouw contact beschikbaar',
            JOB_TO_MEET_THE_CONTRACTOR: 'om de leverancier te ontmoeten',
            JOB_KEYHOLDER_REQUIRED: 'Sleutelhouder nodig',
            JOB_SITE_PRIORITY: 'Prioriteiten van dit gebouw',
            JOB_STEP_DETAIL: 'Gegevens',
            JOB_SERVICE_PROVIDER_PRIORITY: 'Leverancier prioriteit',
            JOB_SYSTEM_PRIORITY: 'Systeem prioriteit',
            JOB_PHONE_NUMBERS: 'Telefoonnummers',
            JOB_KEYHOLDER_DETAILS_PASS: 'Gegevens sleutelhouder doorgegeven:',
            BY_PHONE: 'telefonisch',
            BY_MAIL: 'mail',
            SYSTEM_MAIL: 'automatisch verzonden mail',

            JOB_TYPE_REACTIVE: 'Correctief',
            JOB_TYPE_PLANNED: 'Planmatig',

            JOB_REPORTER: 'Melder',
            JOB_CONTACT: 'Contact',
            JOB_ADD_SITE_DETAILS: 'Kies een gebouw',
            JOB_ADD_REPORTER_DETAILS: 'Ga naar gegevens van de melder',
            JOB_ADD_CONTACT_DETAILS: 'Voeg gegevens gebouwcontact toe',
            JOB_ADD_DETAILS: 'Voer opdrachtgegevens in',

            JOB_WORKFLOW_COMPLETE: 'Opdracht afronden',
            JOB_WORKFLOW_COMPLETE_OPERATION: 'Opdracht afronden',
            JOB_WORKFLOW_COMPLETE_OPERATION_SUBMIT: 'afgeronde opdrachten',

            JOB_WORKFLOW_COMPLETE_PAPERWORK: 'Papierwerk afronden',
            JOB_WORKFLOW_COMPLETE_PAPERWORK_OPERATION: 'Papierwerk afronden',
            JOB_WORKFLOW_COMPLETE_PAPERWORK_OPERATION_SUBMIT: 'Papierwerk afronden',
            JOB_STATUS_AWAITING_PAPERWORK: 'In afwachting van papierwerk',
            COMPLETE_PAPERWORK: 'Papierwerk afronden',

            JOB_WORKFLOW_ACCEPT_ASSIGNMENT: 'bevestigen',
            JOB_WORKFLOW_REQUEST_PROPOSAL: 'Request New SLA',
            JOB_WORKFLOW_INSIST_ASSIGNMENT: 'Insist',

            JOB_WORKFLOW_ACCEPT_APPROVAL: 'Goedkeuren',
            JOB_WORKFLOW_ACCEPT_APPROVAL_SUBMIT: 'Goedkeuren',

            JOB_WORKFLOW_REOPEN: 'Heropenen',

            JOB_WORKFLOW_ESCALATE_APPROVAL: 'Escaleren goedkeuring',

            JOB_WORKFLOW_ALARM: 'Wijzigen Alarm',
            JOB_WORKFLOW_ALARM_SUBMIT: 'Aanpassen',
            JOB_WORKFLOW_ALARM_SUCCESS: 'Alarm Aangepast',

            JOB_WORKFLOW_ALARM_SETNEW: 'Alarm instellen',
            JOB_WORKFLOW_ALARM_SETNEW_SUBMIT: 'Instellen',
            JOB_WORKFLOW_ALARM_SETNEW_SUCCESS: 'Alarm ingesteld',

            JOB_WORKFLOW_REASSIGN_ASSIGNMENT: 'Opdracht opnieuw doorgeven',

            JOB_WORKFLOW_CHANGE_REQUIRED_BY_DATE_OPERATION: 'Uiterste datum opnieuw doorgeven',
            JOB_WORKFLOW_CHANGE_REQUIRED_BY_DATE_OPERATION_SUBMIT: 'Doorgeven',
            JOB_WORKFLOW_CHANGE_REQUIRED_BY_DATE_OPERATION_SUCCESS: 'Uiterste datum succesvol aangepast',

            JOB_WORKFLOW_ACCEPT_PROPOSAL: 'Nieuwe SLA Accepteren',
            JOB_WORKFLOW_ACCEPT_PROPOSAL_SUBMIT: 'Bevestigen',
            PROPOSED: 'Voorgesteld',

            JOB_WORKFLOW_REJECT_PROPOSAL: 'Nieuwe SLA weigeren',
            JOB_WORKFLOW_REJECT_PROPOSAL_SUBMIT: 'Bevestigen',

            JOBS_LIST: 'Opdrachten',
            JOBS_ADD: 'Voeg een nieuwe opdracht toe',
            JOBS_EDIT: 'Verander Opdracht',

            JOB_TYPE: 'Type opdrachten',
            JOB_STAT_TYPE: 'Wettelijk',
            JOB_SUMMARY: 'Werkomschrijving',
            JOB_PRIORITY: 'Prioriteit',

            JOB_STATUS_ACKNOWLEDGED: 'Bevestigd',

            JOB_QUOTE_REQUEST_FROM: 'Via offerte',
            JOB_SENT_TO_QUOTE: 'Offerte opmaken',

            JOB_STATUS_LEGEND: 'Status legenda',

            JOB_STATUS_CREATED: 'Gemaakt',
            JOB_STATUS_APPROVAL_PENDING: 'Inafwachting van goedkeuring',
            JOB_STATUS_APPROVAL_REJECTED: 'Opdracht afgewezen',

            JOB_STATUS_ASSIGNMENT_PENDING: 'In afwachting van bevestiging',
            JOB_STATUS_ASSIGNMENT_PROPOSAL: 'SLA voorstel',

            JOB_STATUS_ASSIGNMENT_REJECTED: 'Opdracht afgewezen',
            
            JOB_STATUS_OPERATION_ACKNOWLEDGED: 'Bevestigd',
            JOB_STATUS_COMMENCED: 'In behandeling',
            JOB_STATUS_OPERATION_MISSED: 'Gemist',
            JOB_STATUS_OPERATION_CANCELLED: 'Geannuleerd',
            JOB_STATUS_COMPLETED: 'Afgerond',
            JOB_STATUS_OPERATION_AWAITING_PAPERWORK: 'In afwachting van papierwerk',
            JOB_WORKFLOW_COMPLETE_PAPERWORK_OPERATION_SUCCESS: 'Papierwerk in bijlage',
            JOB_WORKFLOW_REASSIGN_OPERATIVE: 'Monteur opnieuw toewijzen',

            JOB_STATUS_EXTENSION_PENDING: 'Verzoek tot verlenging in afwachting',
            JOB_STATUS_EXTENSION_REJECTED: 'Verzoek tot verlenging afgewezen',

            JOB_STATUS_MISSED: 'Gemist',

            RESEND_APPROVAL_REQUEST: 'Verzoek tot goedkeuring opnieuw verzenden',
            RESEND_ASSIGNMENT_REQUEST: 'Opdracht verzoek opnieuw verzenden',
            REASSIGN_OPERATIVE: 'Monteur opnieuw toewijzen',

            SEND_TO_QUOTE: 'Versturen naar offerte',
            ESCALATE_TO_CLIENT: 'Escaleren naar Klant',
            EDIT_JOB: 'Aanpassen opdracht',

            FROM_JOB: 'Gemaakt vanuit opdracht',

            COMPLETION_DATE: 'Datum afronding',
            NEW_COMPLETION_DATE: 'Nieuwe afrond datum',
            ORIGINAL_COMPLETION_DATE: 'Originele afrond datum',
            REASSIGN_ASSIGNMENT: 'Opdracht opnieuw toewijzen',
            REASSIGN_ASSIGNMENT_SUBMIT: 'Opnieuw toewijzen',
            COMMENCE: 'Starten',
            COMPLETE: 'Afronden',
            SKIP: 'Overslaan',
            ALARM: 'Alarm',
            CANCEL_JOB: 'Weet u het zeker?',
            CANCEL_JOB_ADD: 'Weet u het zeker dat u wil doorgaan?',

            APPROVE: 'Goedkeuren',
            REJECT: 'Afwijzen',

            ACKNOWLEDGE: 'Bevestigen',
            REASSIGN: 'Opnieuw toewijzen',
            CANCEL: 'Annuleren',
            INSIST: 'Aandringen',

            REQUEST_PROPOSAL: 'Verzoek nieuwe SLA',
            ACCEPT_PROPOSAL: 'Accepteer nieuwe SLA',
            REJECT_PROPOSAL: 'Wijs nieuwe SLA af',

            REQUEST_EXTENSION: 'Verzoek uitstel',
            ACCEPT_EXTENSION: 'Accepteer verzoek uitstel',
            REJECT_EXTENSION: 'Weiger verzoek uitstel',

            CHANGE_ACTION_REQUIRED_BY: 'Verzet actie',

            REOPEN: 'Heropen',
            ESCALATE_APPROVAL: 'Escaleer goedkeuring',

            ACTION_REQUIRED: 'Actie nodig door',

            JOB_REQUISITES: 'Opdrachtbenodigdheden',
            REQUIREMENT_MET: 'Aan eis voldaan',
            JOB_REQUISITES_UPDATED: 'Opdrachtbenodigdheden aangepast',
            JOB_CONTACT_ADD: 'Toevoegen',

            JOB_DIALS_PLANNED_OVERDUE: 'Gepland verlopen',
            JOB_DIALS_REACTIVE_OVERDUE: 'Correctief verlopen',
            JOB_DIALS_PLANNED_REQUIRED_ATTENTION: 'Papierwerk naar klant voor beoordeling',
            JOB_DIALS_REACTIVE_REQUIRED_ATTENTION: 'Geescaleerd naar klant',

            JOB_APPROVAL_RESENT: 'Goedkeuring opnieuw verzenden',
            JOB_ASSIGNMENT_RESENT: 'Opdracht opnieuw verzonden',
            JOB_AUDIT_TRAIL_SENT: 'Audit log verzonden',

            JOB_WITH_ID: 'PO {{ id }}',

            JOBS_REACTIVE_OVERDUE_ATTENDANCE: 'Verlopen correctieve taken',
            JOBS_PLANNED_OVERDUE_COMPLETION: 'Verlopen planmatige taken',
            JOB_CURRENT_OPERATIVE: 'Huidige monteur',
            REASSIGN_TO: 'Hertoewijzen aan',

            JOBS_REACTIVE_UPDATES_DUE: 'Reactieve opdrachten update nodig',

            COMPLETE_TO_RAISE_ESCALATION: 'Vul dit veld in om te escaleren naar de Servicedesk.',
            CANNOT_COMPLETE_REQUIRES_FILE_WITH_STAT_TYPE_YES: 'Kan papierwerk niet afronden, file moet geupload worden.',

            OVERDUE: 'Verlopen',
            OVERDUE_ATTENDANCE: 'Te laat aanwezig',
            OVERDUE_COMPLETION: 'Afronding verlopen',
            ATTEND_AT: 'Aanwezig op',
            COMPLETE_AT: 'Afgerond op',
            JOB_SITE_CONTACT: 'Contact ter plaatse',
            JOB_APPROVER: 'Goedkeuring door',

            JOB_WORKFLOW_COMPLETE_OPERATION_SUCCESS: 'Taak afgerond',

            JOB_WORKFLOW_REQUEST_EXTENSION: 'Verzoek verlenging',
            JOB_WORKFLOW_REQUEST_EXTENSION_SUBMIT: 'Verzoek',
            JOB_WORKFLOW_REQUEST_EXTENSION_SUCCESS: 'Verzoek op taak verlenging',

            JOB_WORKFLOW_ACCEPT_EXTENSION: 'Accepteer verlenging',
            JOB_WORKFLOW_ACCEPT_EXTENSION_SUBMIT: 'Accepteren',
            JOB_WORKFLOW_ACCEPT_EXTENSION_SUCCESS: 'Verlenging geaccepteerd',

            JOB_WORKFLOW_REJECT_EXTENSION: 'Wijs verlenging af',
            JOB_WORKFLOW_REJECT_EXTENSION_SUBMIT: 'Afwijzen',
            JOB_WORKFLOW_REJECT_EXTENSION_SUCCESS: 'Verlenging afgewezen',

            JOB_WORKFLOW_CANCEL_OPERATION: 'Annuleer opdracht',
            JOB_WORKFLOW_CANCEL_OPERATION_SUBMIT: 'Doorgeven',
            JOB_WORKFLOW_CANCEL_OPERATION_SUCCESS: 'Opdracht geannuleerd',

            JOB_WORKFLOW_REOPEN_OPERATION: 'Heropenen',
            JOB_WORKFLOW_REOPEN_OPERATION_SUBMIT: 'Heropenen',
            JOB_WORKFLOW_REOPEN_OPERATION_SUCCESS: 'Opdracht heropend',

            JOB_CREATED: 'Opdracht aangemaakt',
            NO_PLANNED_JOBS: 'Er zijn geen geplande werkzaamheden op locatie, selecteer andere prioriteit',
            JOB_PO_REQUIRED: 'Een PO nummer is verplicht voor sit object',
            USER_IS_APPROVER_AND_TENANT: 'Gebruiker is Huurder',

            JOB_WORKFLOW_REJECT_APPROVAL: 'Goedkeuring afwijzen',
            JOB_WORKFLOW_COMMENCE_OPERATION: 'Beginnen',
            JOB_STATUS_CANCELLED: 'Geannuleerd',

            JOB_WORKFLOW_CANCEL: 'Annuleren',
            JOB_WORKFLOW_CHANGED_REQUIRED_BY_DATE: 'Actie uitstellen',
            JOB_WORKFLOW_REJECT_ASSIGNMENT: 'Opdracht afwijzen',

            CANCEL_OPERATION_SUCCESS: 'Opdracht afgewezen',
            REASSIGN_ASSIGNMENT_SUCCESS: 'Opdracht opnieuw toegewezen',
            JOB_WORKFLOW_REASSIGN_ASSIGNMENT_SUCCESS: 'Opdracht opnieuw toegewezen',
            JOB_WORKFLOW_REASSIGN_OPERATIVE_SUCCESS: 'Monteur opnieuw toegewezen',
            REOPEN_OPERATION_SUCCESS: 'Opdracht heropend',
            REJECT_APPROVAL_SUCCESS: 'Opdracht heropend',
            ESCALATE_APPROVAL_SUCCESS: 'Goedkeuring opdracht geescaleerd',
            JOB_WORKFLOW_ACCEPT_APPROVAL_SUCCESS: 'Opdracht goedgekeurd',
            REQUEST_PROPOSAL_SUCCESS: 'Verzocht op SLA voorstel',
            ACCEPT_PROPOSAL_SUCCESS: 'Voorstel geaccepteerd',
            REJECT_PROPOSAL_SUCCESS: 'Voorstel geweigerd',
            INSIST_ASSIGNMENT_SUCCESS: 'Op opdracht aangedrongen',
            ACCEPT_ASSIGNMENT_SUCCESS: 'Opdracht toegewezen',
            REJECT_ASSIGNMENT_SUCCESS: 'opdracht afgewezen',
            COMMENCE_OPERATION_SUCCESS: 'Werk aangevangen',
            REQUEST_EXTENSION_SUCCESS: 'Werk uitgesteld',
            REJECT_EXTENSION_SUCCESS: 'Uitstel werk afgewezen',
            ACCEPT_EXTENSION_SUCCESS: 'Uitstel werk geaccepteerd',

            JOB_WORKFLOW_REQUEST_PROPOSAL_SUBMIT: 'Verzoek',
            JOB_WORKFLOW_INSIST_ASSIGNMENT_SUBMIT: 'Aandringen',
            JOB_WORKFLOW_REJECT_APPROVAL_SUBMIT: 'Afwijzen',
            JOB_WORKFLOW_ESCALATE_APPROVAL_SUBMIT: 'Escaleren',
            JOB_WORKFLOW_ACCEPT_ASSIGNMENT_SUBMIT: 'Accepteren',
            JOB_WORKFLOW_COMMENCE_OPERATION_SUBMIT: 'Starten',
            JOB_WORKFLOW_REJECT_ASSIGNMENT_SUBMIT: 'Afwijzen',
            JOB_WORKFLOW_REASSIGN_ASSIGNMENT_SUBMIT: 'Opnieuw toewijzen',
            JOB_WORKFLOW_REASSIGN_OPERATIVE_SUBMIT: 'Opnieuw toewijzen',
            ACTION_REQUIRED_BETWEEN: 'Aktie nodig tussen',
            ATTENDANCE_DUE_AT_BETWEEN: 'Aanwezig tussen',
            COMPLETION_DUE_AT_BETWEEN: 'Afronding tussen',

            PREVIOUS_OPERATIVE: 'Voorgaande werkman',
            NEW_OPERATIVE: 'Nieuwe werkman',
            JOB_NUMBER: 'Opdrachtnummer',
            PLANNED: 'Planmatig',
            REACTIVE: 'Reactief',

            CANCEL_JOBS: 'Annuleer opdracht',
            JOBS_SELECTED: 'Opdrachten geselecteerd',
            ARE_YOU_SURE_YOU_WOULD_LIKE_TO_CANCEL_THESE_JOBS: 'Weet u zeker dat deze opdrachten moeten worden geannuleerd?',
            EMAIL_CANCELLATIONS_TO_ENGINEERS: 'Mail annulering naar monteur',
            JOBS_QUEUED_FOR_REASSIGNMENT: 'Opdrachtenlijst voor opnieuw toewijzen',
            JOBS_QUEUED_FOR_CANCELLATION: 'Opdrachtenlijst voor annuleren',

            ALL_FILTERED_JOBS: 'Alle gefilterde opdrachten',

            SELECT_SITE: 'Selecteer een object',
            LAST_FIVE_JOBS: 'Laatste 5 opdrachten',
            STATUTORY: 'Wettelijk',
            NOT_STATUTORY: 'Niet wettelijke',

            JOBS_POINT_IN_TIME: 'Jobs at this point in time match your search criteria'
        });
    }
})();
