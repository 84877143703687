(function() {
    'use strict';

    angular
        .module('elogbooks.user.notifications')
        .config(registerRoutes)
        .config(registerRoutesList);

    function registerRoutes($stateProvider) {
        $stateProvider
            .state('dashboard.user.notifications', {
                abstract: true,
                templateUrl: 'shared-templates/view.html',
                ncyBreadcrumb: {
                    skip: true
                },
                data: {
                    permissions: []
                }
            });
    }

    function registerRoutesList($stateProvider) {
        $stateProvider
            .state('dashboard.user.notifications.list', {
                url: '/notifications?page&limit&site&typePatterns&readStatus&dateRange&createdAtRangeOption',
                parent: 'dashboard.user.notifications',
                views: {
                    '@dashboard.user.notifications': {
                        templateUrl: '/modules/user/notifications/list/notifications-list.html',
                        controller: 'NotificationsListController',
                        controllerAs: 'vm'
                    }
                },
                params: {
                    page: '1',
                    limit: '30',
                    typePatterns: {array: true},
                    readStatus: null,
                    order: '-createdAt',
                    dateRange: null,
                    createdAtRangeOption: null
                },
                resolve: {
                    notificationsCollectionResponse: function ($stateParams, apiClient, user, notificationsService) {
                        let params = angular.extend({}, $stateParams, {
                            typePatterns: null,
                            'typePatterns[]': $stateParams.typePatterns
                        });

                        if (!params.dateRange) {
                            const startDate = moment().subtract(3, 'months').startOf('day');
                            const endDate = moment().endOf('day');

                            params.dateRange = startDate.toISOString() + ',' + endDate.toISOString();
                        }

                        return apiClient.get(user.getLink('notifications'), params).then(function (response) {
                            if (response) {
                                for (let n of response.notifications) {
                                    n.title = notificationsService.escapeSummary(n);
                                    n.typeText = notificationsService.getTypeCategory(n);
                                    n.isRead = !!n.readAt;
                                }

                                return response;
                            } else {
                                return apiClient.noResourceFound();
                            }
                        });
                    }
                },
                ncyBreadcrumb: {
                    label: '{{ ::"NOTIFICATIONS" | translate }}'
                }
            });
    }
})();
