(function () {
    'use strict';

    angular
        .module('elogbooks.user.duty-management')
        .controller('DutyManagementDetailsController', DutyManagementDetailsController);

    DutyManagementDetailsController.$inject = ['$state', '$stateParams', 'apiClient', 'messenger', 'confirmationModal', 'dutyManagementScheduleResponse', 'siteCollectionResponse'];

    function DutyManagementDetailsController ($state, $stateParams, apiClient, messenger, confirmationModal, dutyManagementScheduleResponse, siteCollectionResponse) {
        var vm = this;
            vm.dutyManagementSchedule = dutyManagementScheduleResponse;
            vm.sitesCollection = siteCollectionResponse;
            vm.pages = siteCollectionResponse.pages;
            vm.criteria = {
                name: { type: 'text', title: 'NAME', value: $stateParams.name },
                reference: { type: 'text', title: 'REFERENCE', value: $stateParams.reference }
            };
            vm.search = searchAction;
            vm.delete = deleteAction;
            vm.deleteSite = deleteSiteAction;

        function searchAction(params) {
            var override = {
                page: 1
            };

            $state.go('.', angular.extend({}, params, override), { reload: $state.current.name });
        }

        function deleteSiteAction(site) {
            apiClient.delete(vm.dutyManagementSchedule.getLink('sites'), {'ids' : [site.id]}).then(function (response) {
                if (response) {
                    $state.go('.', $stateParams, { reload: $state.current.name }).then(function () {
                        messenger.success('DUTY_MANAGEMENT_SITE_RELATION_REMOVED');
                    });
                } else {
                    messenger.error('REQUEST_ERROR');
                }
            });
        }

        function deleteAction() {
            return confirmationModal.open().result.then(function () {
                return apiClient.delete(vm.dutyManagementSchedule.getLink('self')).then(function (response) {
                    if (response) {
                        $state.go('^', $stateParams, { reload: $state.current.parent }).then(function () {
                            messenger.success('DUTY_MANAGEMENT_SCHEDULE_REMOVED');
                        });
                    } else {
                        messenger.error('REQUEST_ERROR');
                    }
                });
            });
        };
    }
})();
