(function() {
    'use strict';

    angular
        .module('elogbooks')
        .config(Translations);

    function Translations($translateProvider) {
        $translateProvider.translations('en-gb', {
            LABOUR_TYPES: 'Labour Types',
            LABOUR_TYPE_LIST: 'Labour Types',
            LABOUR_TYPE_DETAILS: 'Labour Type Details',

            LABOUR_TYPE_ADD: 'Add a new Labour Type',
            LABOUR_TYPE_EDIT: 'Edit Labour Type',

            LABOUR_TYPE_CREATED: 'Labour Type created',
            LABOUR_TYPE_UPDATED: 'Labour Type updated',
            LABOUR_TYPE_DELETED: 'Labour Type deleted',
        });
    }
})();
