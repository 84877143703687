app.config(function ($translateProvider) {
    $translateProvider.translations('nl', {
        BUDDY: 'Backup support',
        BUDDY_ADD: 'Toevoegen Backup support',
        BUDDY_EDIT: 'Aanpassen Backup support',
        BUDDIES: 'Backup support',
        BUDDIES_NO_RESULTS: 'Geen Backup support',
        BUDDYING_FOR: 'Backup Support voor',
        BUDDY_SELECT_SITES: 'Geselecteerde objecten',
        BUDDY_SITE_RELATION_REMOVED: 'Object verwijderd',
        BUDDY_REMOVED: 'Backup support verwijderd',
        BUDDY_ALREADY_IN_USE_SITES: 'Objecten reeds in gebruik',
        BUDDY_DEACTIVATED: 'Backup support uitgezet'
    });
});
