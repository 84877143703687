(function() {
    'use strict';

    angular
        .module('elogbooksServices')
        .service('timesheetsService', TimesheetsService);

    TimesheetsService.$inject = ['moment'];

    function TimesheetsDay(dayNumber, moment) {
        this.moment = moment;
        this.startDay = null;
        this.dayNumber = dayNumber;
        this.day = null;
        this.labourEntries = [];
        this.miscEntries = [];
        this.collapsed = true;
        this.totalMinutesTravelled = 0;
        this.totalMinutesWorked = 0;
        this.totalMiscMinutes = 0;
        this.totalMileage = 0
    }

    function getMappedDay(date) {
        return [6,0,1,2,3,4,5][date.getDay()];
    }

    TimesheetsDay.prototype.addData = function(data) {
        if (data.type === 'labour') {
            this.labourEntries.push(data);
            this.totalMinutesTravelled += data.minutesTravelled;
            this.totalMinutesWorked += data.minutesWorked;
            this.totalMileage += data.mileage;
        } else {
            this.miscEntries.push(data);
            this.totalMiscMinutes += data.minutesWorked;
        }
    }

    TimesheetsDay.prototype.getLabourEntries = function() {
        return this.labourEntries;
    }

    TimesheetsDay.prototype.getMiscEntries = function() {
        return this.miscEntries;
    }

    TimesheetsDay.prototype.hasLabourEntries = function() {
        return this.labourEntries.length > 0;
    }

    TimesheetsDay.prototype.hasMiscEntries = function() {
        return this.miscEntries.length > 0;
    }

    TimesheetsDay.prototype.hasEntries = function() {
        return this.hasLabourEntries() || this.hasMiscEntries();
    }

    TimesheetsDay.prototype.setStartWeekDate = function(startDay) {
        this.startDay = startDay;
        this.day = this.moment(this.startDay).add(this.dayNumber, 'days').toDate();
    }

    TimesheetsDay.prototype.getHeader = function() {
        return this.moment(this.day).format('dddd - Do MMMM');
    }

    TimesheetsDay.prototype.toggle = function() {
        this.collapsed = !this.collapsed;
    }


    function TimesheetsService(moment) {
        this.timesheetWeek = null;

        this.days = [
            new TimesheetsDay(0, moment), // Monday
            new TimesheetsDay(1, moment), // Tuesday
            new TimesheetsDay(2, moment), // Wednesday
            new TimesheetsDay(3, moment), // Thursday
            new TimesheetsDay(4, moment), // Friday
            new TimesheetsDay(5, moment), // Saturday
            new TimesheetsDay(6, moment), // Sunday
        ];
    }

    function processTimesheetsData(timesheetData) {
        for (var key in timesheetData) {
            if (!timesheetData.hasOwnProperty(key)) {
                continue;
            }

            var data = timesheetData[key];
            var date = new Date(data.startDate);

            this.days[getMappedDay(date)].addData(data);
        }
    }

    TimesheetsService.prototype.setTimesheetWeek = function(timesheetWeek) {
        this.timesheetWeek = timesheetWeek;

        for (var day in this.days) {
            if (this.days.hasOwnProperty(day)) {
                this.days[day].setStartWeekDate(new Date(timesheetWeek.startWeekDate));
            }
        }
    }

    TimesheetsService.prototype.setTimesheetsDataCollection = function(timesheetData) {
        processTimesheetsData.call(this, timesheetData.timesheetData);
    }

    TimesheetsService.prototype.getDays = function() {
        return this.days;
    }

    TimesheetsService.prototype.resetValues = function () {
        for (var day in this.days) {
            this.days[day].labourEntries = [];
            this.days[day].miscEntries = [];
            this.days[day].collapsed = true;
            this.days[day].totalMinutesTravelled = 0;
            this.days[day].totalMinutesWorked = 0;
            this.days[day].totalMiscMinutes = 0;
            this.days[day].totalMileage = 0
        }
    }

    TimesheetsService.prototype.hasDays = function () {
        for (var i = 0;i < 7;i++) {
            if (this.days[i].labourEntries.length > 0 || this.days[i].miscEntries.length > 0) {
                return true;
            }
        }

        return false;
    }

    TimesheetsService.prototype.isTimeSheetApproved = function() {
       return this.timesheetWeek.status === 'approved';
    }

    TimesheetsService.prototype.toggleAll = function(collapse) {
        for (var day in this.days) {
            if (this.days.hasOwnProperty(day)) {
                this.days[day].collapsed = collapse;
            }
        }
    }

})();
