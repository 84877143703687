(function() {
    'use strict';

    angular
        .module('elogbooks')
        .config(Translations);

    function Translations($translateProvider) {
        $translateProvider.translations('en-gb', {
            ESCALATION_STAGE: 'Escalation Stage',
            ESCALATION_STAGES: 'Escalation Stages',
            ESCALATION_STAGE_LIST: 'Escalation Stages',

            ESCALATION_STAGE_ADD: 'Add a new Escalation Stage',
            ESCALATION_STAGE_EDIT: 'Edit Escalation Stage',

            ESCALATION_STAGE_CREATED: 'Escalation Stage Created',
            ESCALATION_STAGE_UPDATED: 'Escalation Stage Updated',
            ESCALATION_STAGE_DELETED: 'Escalation Stage Deleted',
        });
    }
})();

app.config(function ($translateProvider) {
    $translateProvider.translations('en-gb', {
        STAGE: 'Stage',
        EMAIL_CONTENT: 'Email Content',
        PRIMARY_CONTACT: 'Primary Contact',
        ASSOCIATE_TYPES: 'Associate Types',
        ASSOCIATE_TYPES_SELECT: 'Select Associate Types',
        OTHER_CONTACTS: 'Other Contacts',
        OTHER_CONTACTS_SELECT: 'Select Other Contacts',
        REVIEW_AT: 'Review At',
    });
});
