(function () {
    'use strict';

    angular
        .module('elogbooks.user.tasks')
        .controller('UserTaskInfoController', UserTaskInfoController);

    UserTaskInfoController.$inject = ['taskResponse'];

    function UserTaskInfoController (taskResponse) {
        var vm = this;
        vm.task = taskResponse;

        vm.disableButton = false;
        vm.disableTooltip = true;
        vm.tooltipMessage = null;
        vm.taskLocation = taskResponse._links.location || taskResponse._links.assetlocation;

        if (taskResponse.statusPending) {
            vm.disableButton = true;
            vm.tooltipMessage = 'TASK_EDITING_DISABLED';
            vm.disableTooltip = false;
        }
    }
})();
