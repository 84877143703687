(function () {
    'use strict';

    angular
        .module('elogbooksDirectives')
        .directive('elogbooksServiceProvider', ElogbooksServiceProvider);

    function ElogbooksServiceProvider() {
        return {
            restrict: 'E',
            scope: {
                link: '='
            },
            transclude: true,
            templateUrl: '/modules/directives/links/service-provider/service-provider.html',
            controller: function ($scope, userManager) {

                $scope.type = null;

                switch (true) {
                    case userManager.hasPermission('user_permission_view_service_providers'):
                        $scope.type = 'admin';
                        break;
                    case true:
                        $scope.type = 'user';
                        break;
                }
            }
        };
    };

})();
