angular.module('elogbooksFilters').filter('dateRange', function(moment) {
    return function(items, options) {
        options = angular.extend({
            operation: '>',
            property: 'date',
            date: null
        }, options);

        return items.filter(function(item) {
            for (var i = 0, len = options.operation.length; i < len; i++) {
                switch (options.operation[i]) {
                    case '<':
                        if (moment(item[options.property]).isBefore(options.date)) {
                            return true;
                        }
                        break;

                    case '>':
                        if (moment(item[options.property]).isAfter(options.date)) {
                            return true;
                        }
                        break;

                    case '=':
                        if (moment(item[options.property]).isSame(options.date)) {
                            return true;
                        }
                        break;
                }
            }

            return false;
        });
    };
});
