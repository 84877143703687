app.config(function ($translateProvider) {
    $translateProvider.translations('en-gb', {
        TIMESHEETS: 'Timesheets',
        TIMESHEET_LIST: 'Timesheets',
        'DATE_FROM-TO': 'Date From - To',
        TOTAL_HOURS: 'Total Hours',
        TOTAL_MILEAGE: 'Total Mileage',
        TIMESHEETS_STATUS_DRAFT: 'Draft',
        TIMESHEETS_STATUS_PENDING_APPROVAL: 'Pending Approval',
        TIMESHEETS_STATUS_APPROVED: 'Approved',
        TIMESHEETS_STATUS_REJECTED: 'Rejected',
        DATE_RANGE: 'Date Range',
        TOTAL_HOURS_CLAIMED: 'Total Hours Claimed',
        CREATE_TIMESHEET_MISC_ENTRY: 'Add new Miscellaneous Entry',
        CREATE_ENTRY_LINE_ADD: 'Add new Miscellaneous Entry',
        TIMESHEETTYPE: 'Type',
        TIME: 'Time',
        DURATION: 'Duration',
        CREATE_ENTRY_LINE_CREATED: 'Non Job Related Entry created successfully',

        TIMESHEET_WORKFLOW_SUBMIT_APPROVAL: 'Submit Approval',
        TIMESHEET_WORKFLOW_SUBMIT_APPROVAL_SUBMIT: 'Submit',
        TIMESHEET_WORKFLOW_SUBMIT_APPROVAL_SUCCESS: 'Submit Successful',

        TIMESHEET_WORKFLOW_ACCEPT: 'Approve',
        TIMESHEET_WORKFLOW_ACCEPT_SUBMIT: 'Approve',
        TIMESHEET_WORKFLOW_ACCEPT_SUCCESS: 'Approve Successful',

        TIMESHEET_WORKFLOW_REJECT: 'Reject',
        TIMESHEET_WORKFLOW_REJECT_SUBMIT: 'Reject',
        TIMESHEET_WORKFLOW_REJECT_SUCCESS: 'Reject Successful',

        TIMESHEET_WEEK_SUBMITTED_ACTION: '<strong>{{ ::event._links["user"].title }}</strong> submitted the Timesheet.',
        TIMESHEET_WEEK_APPROVED_ACTION: '<strong>{{ ::event._links["user"].title }}</strong> approved the Timesheet.',
        TIMESHEET_WEEK_REJECTED_ACTION: '<strong>{{ ::event._links["user"].title }}</strong> rejected the Timesheet.',

        CANNOT_SUBMIT_TIMESHEET: 'Cannot submit Timesheet',
        CANNOT_APPROVE_TIMESHEET: 'Cannot approve Timesheet',
        CANNOT_REJECT_TIMESHEET: 'Cannot reject Timesheet',

        TIMESHEET_LABOUR_EDIT: 'Edit Timesheet Labour',
        TIMESHEET_LABOUR_LINE_UPDATED: "Labour line Updated Successfully",
        TIMESHEET_LABOUR_LINE_DELETED: "Labour line Deleted Successfully",
        MISC_ENTRIES: 'Miscellaneous Entries',
        TIMESHEET_CREATED: "Timesheet Created Successfully",
        TIMESHEET_ADD: 'Add a new Timesheet',
        DUPLICATE_TIMESHEET: "Timesheet already exists for period",
        PREVIEW: "Preview"
    });
});
