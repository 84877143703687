angular.module('elogbooksDirectives').directive('tidyTables', ['tidyTablesService','lodash', function(tidyTablesService, lodash) {
    return {
        // restrict to attributes only
        restrict: 'AE',
        require: ['tidyTablesModel'],
        scope: {
            tidyTablesModel: '='
        },
        templateUrl: '/modules/directives/tidy-tables/tidy-tables.html',
        controller: function ($scope) {
            $scope.originalTidyTables = angular.copy($scope.tidyTablesModel);
            $scope.tidyTables = $scope.tidyTablesModel.columns;

            $scope.canCheckMore = true;
            $scope.canUncheckMore = true;

            tidyTablesService.setTidyTable($scope.tidyTablesModel);

            $scope.$watch('tidyTables', function (columns) {
                var checkedCount = lodash.size(lodash.pickBy(columns,function (value, key) {
                        return value;
                }));

                if ($scope.tidyTablesModel.maxCols && $scope.tidyTablesModel.maxCols <= checkedCount) {
                    $scope.canCheckMore = false;
                } else {
                    $scope.canCheckMore = true;
                }

                if ($scope.tidyTablesModel.minCols && $scope.tidyTablesModel.minCols >= checkedCount) {
                    $scope.canUncheckMore = false;
                } else {
                    $scope.canUncheckMore = true;
                }
            }, true);

            $scope.$on('$destroy', function() {
                if ($scope.originalTidyTables === $scope.tidyTablesModel) {
                    return;
                }
                tidyTablesService.setTidyTable($scope.tidyTablesModel);
            });
        }
    }
}]);
