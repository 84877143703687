(function () {
    'use strict';

    angular
        .module('elogbooks.user.reports')
        .controller('UserReportsSiteAssociateApprovalsController', UserReportsSiteAssociateApprovalsController);

    UserReportsSiteAssociateApprovalsController.$inject = ['dataCollection'];

    function UserReportsSiteAssociateApprovalsController(dataCollection) {
        var vm = this;
        vm.dataCollection = dataCollection;

        if (dataCollection.jobs) {
            vm.jobs = dataCollection.jobs;
        }

        if (dataCollection.quotes) {
            vm.quotes = dataCollection.quotes;
        }
    }
})();
