(function () {
    'use strict';

    angular
        .module('elogbooks.user.reports')
        .controller('UserReportsSiteAssociateExtensionRequestsController', UserReportsSiteAssociateExtensionRequestsController);

    UserReportsSiteAssociateExtensionRequestsController.$inject = ['$state', '$stateParams', 'serviceProviderCollection', 'siteAssociateResponse'];
    function UserReportsSiteAssociateExtensionRequestsController($state, $stateParams, serviceProviderCollection, siteAssociateResponse) {
        var vm = this;
        vm.serviceProvidersCollection = serviceProviderCollection;
        vm.serviceProviders = serviceProviderCollection.serviceproviders;
        vm.associate = siteAssociateResponse._links.user;
        vm.currentState = $state.current.name.substr($state.current.name.lastIndexOf(".") + 1);
        vm.exportParams = {exportView: 'list'};
    }
})();