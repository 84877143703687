(function() {
    'use strict';

    angular
        .module('elogbooks')
        .config(Translations);

    function Translations($translateProvider) {
        $translateProvider.translations('en-gb', {
            SPILLAGES: 'Spillages',
            SPILLAGE_LIST: 'Spillages',
            SPILLAGE: 'Spillage',
            SPILLAGE_TYPE: 'Spillage Type',
            ACCIDENT_OCCURRED: 'Accident Occurred',
            SPILLAGES_ADD: 'Add Spillage',
            HAS_ACCIDENT_OCCURRED: 'Accident Occurred?',
            I_AM_REPORTER: 'I am the Reporter',
            REPORT_SPILLAGE: 'Report Spillage',
            REPORTER_NAME: 'Reporter Name',
            SELECT_LOCATION: 'Select Location',
            SPILLAGE_ADD: 'Log a new Spillage',
            SPILLAGE_CREATED: 'Spillage Created',
            SITE_LOCATION: 'Site Location',
            I_AM_NOT_NEAR_SPILLAGE: 'I am Not Near Spillage',
            ASSIGNED_TO_ME: 'Assigned To Me',
            SITE_REPORTER: 'Site Reporter',
            ASSIGN_TO_ME: 'Assign to Me',

            SPILLAGE_DETAILS_WITH_ID: 'Spillage #{{ ::id }} details',

            SPILLAGE_OPEN: 'Open',
            SPILLAGE_ACCEPTED: 'Accepted',
            SPILLAGE_OPEN_PENDING_PHOTO: 'Pending Photo',
            SPILLAGE_CANCELLED: 'Cancelled',
            SPILLAGE_IN_PROGRESS: 'In Progress',
            SPILLAGE_STARTED: 'Started',
            SPILLAGE_COMPLETED: 'Completed',

            SPILLAGE_CREATED_ACTION: '<strong>{{ ::event._links["user"].title }}</strong> created the spillage.',
            SPILLAGE_CREATED_SYSTEM_ACTION: '<strong>{{ ::event._links["user"].title }}</strong> created the spillage.',
            SPILLAGE_MESSAGE_ACTION: '<strong>{{ ::message._links["sender"].title }}</strong> provided an update.',
            SPILLAGE_REASSIGNED_ACTION: '<strong>{{ ::event._links["user"].title }}</strong> reassigned the spillage.',
            SPILLAGE_CANCELLED_ACTION: '<strong>{{ ::event._links["user"].title }}</strong> cancelled the spillage.',
            SPILLAGE_ACCEPTED_ACTION: '<strong>{{ ::event._links["user"].title }}</strong> accepted the spillage.',
            SPILLAGE_STARTED_ACTION: '<strong>{{ ::event._links["user"].title }}</strong> started the spillage.',
            SPILLAGE_COMPLETED_ACTION: '<strong>{{ ::event._links["user"].title }}</strong> completed the spillage.',
            SPILLAGE_CHASED_ACTION: 'A chase #{{ ::event.chaseSequence }} notification has been sent to <strong>{{ ::event._links["user"].title }}</strong>.',
            SPILLAGE_CHASED_SYSTEM_ACTION: 'A chase #{{ ::event.chaseSequence }} notification has been sent.',
            SPILLAGE_ESCALATION_CHASED_ACTION: 'An escalation chase #{{ ::event.chaseSequence }} notification has been sent to <strong>{{ ::event._links["user"].title }}</strong>.',
            SPILLAGE_ESCALATION_CHASED_SYSTEM_ACTION: 'An escalation chase #{{ ::event.chaseSequence }} notification has been sent.',
            SPILLAGE_WORKFLOW_REASSIGN: 'Reassign Spillage',
            SPILLAGE_WORKFLOW_REASSIGN_SUBMIT: 'Reassign',
            SPILLAGE_WORKFLOW_REASSIGN_SUCCESS: 'Spillage Reassigned',

            SPILLAGE_WORKFLOW_CANCEL: 'Cancel Spillage',
            SPILLAGE_WORKFLOW_CANCEL_SUBMIT: 'Cancel Spillage',
            SPILLAGE_WORKFLOW_CANCEL_SUCCESS: 'Spillage Cancelled',

            SPILLAGE_OPEN_CHASE_FREQ_BEFORE :'OPEN Chase frequency - First Chase (mins)',
            SPILLAGE_OPEN_CHASE_FREQ_AFTER: 'OPEN Chase frequency - Additional Chases (mins)',
            SPILLAGE_ACCEPTED_CHASE_FREQ_BEFORE: 'ACCEPTED Chase frequency - First Chase (mins)',
            SPILLAGE_ACCEPTED_CHASE_FREQ_AFTER: 'ACCEPTED Chase frequency - Additional Chases (mins)',
            SPILLAGE_IN_PROGRESS_CHASE_FREQ_BEFORE: 'IN PROGRESS Chase frequency - First Chase (mins)',
            SPILLAGE_IN_PROGRESS_CHASE_FREQ_AFTER: 'IN PROGRESS Chase frequency - Additional Chases (mins)',
            SPILLAGE_ESCALATION_INTERVAL_REPEAT: 'Escalation interval - Repeat (mins)',
            SPILLAGE_ESCALATION_INTERVAL: 'Escalation interval (mins)',
            SPILLAGE_ESCALATION_SITEASSOCIATE_TYPE: 'Escalation site associate type',
            SPILLAGE_SETTINGS_VIEW: 'Spillage settings',

            SPILLAGE_JOB_CREATED_ACTION: '<strong>{{ ::event._links["user"].title }}</strong> created a Job.',
            SPILLAGE_FILE_DELETED_ACTION: '<strong>{{ ::event._links["user"].title }}</strong> deleted a spillage file.',
            SPILLAGE_QUOTE_REQUEST_CREATED_ACTION: '<strong>{{ ::event._links["user"].title }}</strong> created a Quote Request.',
            SPILLAGE_ACTION_CREATED_ACTION: '<strong>{{ event._links["user"].title }}</strong> created the Action',
            FROM_SPILLAGE: 'From Spillage',

            ADD_AN_ACTION: 'Add an Action',
            ADD_A_JOB: 'Add a Job',
            ADD_A_QUOTE: 'Add a Quote'
        });
    }
})();
