(function() {
    'use strict';

    angular
        .module('elogbooks.admin.billing-types')
        .controller('BillingTypesDetailsController', BillingTypesDetailsController);

    BillingTypesDetailsController.$inject = ['billingTypeResponse'];
    function BillingTypesDetailsController(billingTypeResponse) {
        var vm = this;
        vm.billingType = billingTypeResponse;
    }
})();
