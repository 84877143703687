(function () {
    'use strict';

    angular
        .module('elogbooksDirectives')
        .directive('elbFiles', ElogbooksFiles);

    ElogbooksFiles.$inject = [];

    function ElogbooksFiles () {
        return {
            restrict: 'AE',
            scope: {
                fileCollectionResponse: '=',
                tagCollectionResponse: '='
            },
            templateUrl: '/modules/directives/elb-files/elb-files.html',
            controller: 'ElbFilesController',
            controllerAs: 'vm'
        };
    }
})();
