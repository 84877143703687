(function () {
    'use strict';

    angular
        .module('elogbooks.common.audit')
        .controller('AuditTabsController', AuditTabsController);

    AuditTabsController.$inject = [
        'apiClient',
        'auditResponse',
        'sectionsCollectionResponse',
        'auditTemplateVersionsResponse',
        '$filter',
        '$state',
        'base64'
    ];

    function AuditTabsController(
        apiClient,
        auditResponse,
        sectionsCollectionResponse,
        auditTemplateVersionsResponse,
        $filter,
        $state,
        base64
    ) {
        var vm = this;
        vm.audit = auditResponse;
        vm.sections = sectionsCollectionResponse.sections;
        vm.auditTitle = '';
        vm.auditTemplateVersionsResponse = auditTemplateVersionsResponse;

        vm.infoState = "dashboard.user.audits.list.details.info";
        vm.sectionState = "dashboard.user.audits.list.details.sections";

        vm.selectedVersion = vm.audit;
        vm.loadVersion = loadVersion;

        if (vm.audit.isTemplate) {
            vm.auditTitle = (vm.audit._links.site ?  $filter('translate')('AUDIT_TEMPLATE') :  $filter('translate')('AUDIT_TEMPLATE'));
        } else {
            vm.auditTitle =  $filter('translate')('AUDIT');
        }

        if ($state.current.name.substring(0, 48) == "dashboard.user.sites.list.details.audits.details") {
            vm.infoState = "dashboard.user.sites.list.details.audits.details.info";
            vm.sectionState = "dashboard.user.sites.list.details.audits.details.sections";
        }

        vm.subTabAutoscroll = false;
        vm.questionsTabAutoscroll = false;

        // scroll down to see in-line answer option edit
        if ($state.current.name == 'dashboard.user.audits.list.details.sections.details.question.answer-option'
            || $state.current.name == 'dashboard.user.sites.list.details.audits.details.sections.details.question.answer-option') {

            vm.subTabAutoscroll = false;
            vm.questionsTabAutoscroll = true;
        }

        // reload info state, with new resoruce loaded
        function loadVersion() {
            $state.go(vm.infoState, {auditResource: base64.encode(vm.selectedVersion.href)}, {reload: vm.infoState.slice(0, -5)});
        }
    }
})();
