(function() {
    'use strict';

    angular
        .module('elogbooksServices')
        .service('actionService', ActionService);

    ActionService.$inject = ['apiClient', '$q', '$translate'];

    function ActionService(apiClient, $q, $translate) {
        var self = this;

        this.getStatusOptions = getStatusOptions;

        function getStatusOptions(includeNoneSelected) {
            var statusOptions = [
                { value: 'open', title: $translate.instant('ACTION_STATUS_OPEN') },
                { value: 'completed', title: $translate.instant('ACTION_STATUS_COMPLETED') },
                { value: 'cancelled', title: $translate.instant('ACTION_STATUS_CANCELLED') },
                { value: 'paperwork_rejected', title: $translate.instant('ACTION_STATUS_PAPERWORK_REJECTED') },
            ];

            if (includeNoneSelected) {
                statusOptions.unshift({
                    value: null,
                    title: $translate.instant('NONE_SELECTED')
                });
            }

            statusOptions.sort(function(a, b) {
                return (a.title < b.title ? -1 : (a.title > b.title ? 1 : 0));
            });

            return statusOptions;
        }

        return this;
    }
})();
