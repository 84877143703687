(function (){
    'use strict';

    angular
        .module('elogbooks.user.tasks')
        .controller('UserTaskDetailsController', UserTaskDetailsController);

    UserTaskDetailsController.$inject = ['taskResponse'];

    function UserTaskDetailsController(taskResponse) {
        var vm = this;
        vm.task = taskResponse;
        vm.lastYear = moment().year() - 1;
        vm.minDate = '01-01-' + vm.lastYear;
    }
})();
