(function () {
    'use strict';

    angular
        .module('elogbooks.admin.site-note-types')
        .controller('SiteNoteTypeInfoController', SiteNoteTypeInfoController);

    SiteNoteTypeInfoController.$inject = ['$state', '$filter', 'siteNoteTypeResponse', 'apiClient', 'messenger'];

    function SiteNoteTypeInfoController ($state, $filter, siteNoteTypeResponse, apiClient, messenger) {
        var vm = this;
        vm.siteNoteType = siteNoteTypeResponse;
        vm.delete = doDelete;

        vm.visibilities = {
            0: $filter('translate')('VISIBILITY_PRIVATE'),
            1: $filter('translate')('VISIBILITY_PUBLIC'),
            2: $filter('translate')('VISIBILITY_TENANT'),
            3: $filter('translate')('VISIBILITY_SERVICE_PROVIDER')
        };

        function doDelete () {
            if (confirm('Are you sure?')) {
                apiClient.delete(siteNoteTypeResponse.getLink('delete')).then(function (response) {
                    if (response) {
                        $state.go('dashboard.admin.site-note-types.list', {}, { reload: true}).then(function () {
                            messenger.success('Site Note Type deleted successfully');
                        });
                    } else {
                        messenger.error('REQUEST_ERROR');
                    }
                });
            }

            return;
        }
    }
})();
