(function () {
    'use strict';

    angular
        .module('elogbooks.admin.pricing-schedules')
        .controller('PricingSchedulesAddEditController', PricingSchedulesAddEditController);

    PricingSchedulesAddEditController.$inject = [
       'pricingScheduleResponse',
       'rootPricingScheduleResponse',
       'pricingSchedulesCollectionResponse',
       'requestDataFactory',
       'crudService',
       '$state'
    ];

    function PricingSchedulesAddEditController(
        pricingScheduleResponse,
        rootPricingScheduleResponse,
        pricingSchedulesCollectionResponse,
        requestDataFactory,
        crudService,
        $state
    ) {
        var vm = this;
        vm.pricingSchedule = pricingScheduleResponse;
        vm.rootPricingScheduleResponse = rootPricingScheduleResponse;
        vm.pricingSchedulesCollectionResponse = pricingSchedulesCollectionResponse;
        vm.data = requestDataFactory.createRequest(vm.rootPricingScheduleResponse);

        vm.create = createAction;
        vm.update = updateAction;
        vm.delete = deleteAction;
        vm.cancel = cancelAction;

    function createAction() {
        return crudService.create(
            vm.pricingSchedulesCollectionResponse.getLink('create'),
            vm.data,
            'dashboard.admin.pricing-schedules.list.details.labourRates',
            'pricingScheduleResource',
            'PRICING_SCHEDULES'
        );
    }

    function updateAction() {
        return crudService.update(
            vm.pricingSchedule.getLink('edit'),
            vm.data,
            'dashboard.admin.pricing-schedules.list.details.labourRates',
            'dashboard.admin.pricing-schedules.list',
            'PRICING_SCHEDULES',
            false
        );
    }

    function deleteAction() {
        return crudService.delete(
            vm.pricingSchedule.getLink('delete'),
            $state.get('^.^'),
            'PRICING_SCHEDULES'
        );
    }

    function cancelAction() {
        var cancelRoute = $state.get('^');

        if (vm.data.id) {
            cancelRoute = 'dashboard.admin.pricing-schedules.list.details.labourRates'
        }
            $state.go(cancelRoute, {});
        }
    }
})();