(function () {
    'use strict';

    angular
        .module('elogbooksFilters')
        .filter('threshold', Threshold);

    Threshold.$inject = ['$translate', '$filter', 'elbSettings'];

    function Threshold($translate, $filter, elbSettings) {
        return function (input, type) {
            var output = '';
            var currency = elbSettings.getSetting('currency').value;
            var locale = elbSettings.getSetting('general_default_locale').value;

            type = type || 'number';

            output += ((input !== 'unlimited' && input !== undefined) ? (type === 'number' ? $filter('number')(input, 0) : currency === 'NONE' ? $filter('translate')('CURRENCY_' + currency) + parseFloat(input).toLocaleString(locale, {maximumFractionDigits:2}) : parseFloat(input).toLocaleString(locale, {maximumFractionDigits:2, style: 'currency', currency: currency})) : $translate.instant('UNLIMITED'));

            return output.trim();
        }
    };
})();
