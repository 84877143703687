(function() {
    'use strict';

    angular
        .module('elogbooks.user.finance.sales-invoices')
        .controller('FinanceSalesInvoicesStatusController', FinanceSalesInvoicesStatusController);

    FinanceSalesInvoicesStatusController.$inject = [
        'salesInvoiceResponse'
    ];

    function FinanceSalesInvoicesStatusController(
        salesInvoiceResponse
    ) {
        var vm = this;
        vm.salesInvoice = salesInvoiceResponse;
    }
})();
