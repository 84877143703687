(function () {
    'use strict';

    angular
        .module('elogbooks.user.service-providers')
        .controller('UserServiceProvidersDetailsController', UserServiceProvidersDetailsController);

    UserServiceProvidersDetailsController.$inject = ['serviceProviderResponse', 'userResponse', 'elbSettings'];

    function UserServiceProvidersDetailsController (serviceProviderResponse, userResponse, elbSettings) {
        var vm = this;
        vm.serviceProvider = serviceProviderResponse;
        vm.user = userResponse;
        vm.reporterFeedbackEnabled = elbSettings.getSetting('job_reporter_feedback').value;
    }
})();
