angular.module('elogbooksServices').service('patrolPointRequirementTypes', function(lodash, $filter) {

    this.getAll = getAll;
    this.getName = getName;

    var requirementTypes = [
        { value: "none", name: $filter('translate')("PATROL_POINT_REQUIREMENT_NONE"), title: $filter('translate')("PATROL_POINT_REQUIREMENT_NONE") },
        { value: "barcode", name: $filter('translate')("PATROL_POINT_REQUIREMENT_BARCODE"), title: $filter('translate')("PATROL_POINT_REQUIREMENT_BARCODE") },
        { value: "nfc", name: $filter('translate')("PATROL_POINT_REQUIREMENT_NFC"), title: $filter('translate')("PATROL_POINT_REQUIREMENT_NFC") },
        { value: "photo", name: $filter('translate')("PATROL_POINT_REQUIREMENT_PHOTO"), title: $filter('translate')("PATROL_POINT_REQUIREMENT_PHOTO") },
        { value: "signature", name: $filter('translate')("PATROL_POINT_REQUIREMENT_SIGNATURE"), title: $filter('translate')("PATROL_POINT_REQUIREMENT_SIGNATURE") },
        { value: "file", name: $filter('translate')("PATROL_POINT_REQUIREMENT_FILE"), title: $filter('translate')("PATROL_POINT_REQUIREMENT_FILE") }
    ];

    function getAll() {
        return requirementTypes;
    }

    function getName(key) {
        var found = lodash.find(requirementTypes, {value: key});
        if (typeof found !== 'undefined') {
            return found.name;
        }
        return key;
    }

    return this;
});
