(function () {
    'use strict';

    angular
        .module('elogbooks.common.audit')
        .controller('AuditSectionsController', AuditSectionsController);

    AuditSectionsController.$inject = [
        '$state',
        'lodash',
        'messenger',
        'apiClient',
        'auditResponse',
        'sectionsCollectionResponse',
        'sectionResponse',
        'sortableOptions',
        '$filter',
        '$stateParams'
    ];

    function AuditSectionsController(
        $state,
        lodash,
        messenger,
        apiClient,
        auditResponse,
        sectionsCollectionResponse,
        sectionResponse,
        sortableOptions,
        $filter,
        $stateParams
    ) {
        var vm = this;
        vm.audit = auditResponse;
        vm.sectionsCollectionResponse = sectionsCollectionResponse;
        vm.sections = sectionsCollectionResponse.sections;
        vm.sectionResponse = sectionResponse;
        vm.haveSectionsChanged = false; // Order of sections has changed
        vm.params = {};
        vm.hidePanel = false;
        vm.title = $filter('translate')('AUDIT_SECTIONS');
        vm.userCanEdit = lodash.has(vm.audit, '_links.edit.href');
        vm.noBorder = true;

        vm.submit = updateSections;
        vm.resetSections = resetSections;
        vm.calculatePercentage = calculatePercentage;
        vm.onMarkSectionAsNaClick = onMarkSectionAsNaClick;

        function onMarkSectionAsNaClick() {
            $state.go('dashboard.user.audits.list.details.info', $stateParams, {
                reload: 'dashboard.user.audits.list.details', notify: true
            });

            return $state.go('.', $stateParams, { reload: true });
        };

        function calculatePercentage(sections) {
            // calculate percentage completion for each section
            lodash.each(sections, function (section) {
                if (section.answeredQuestionCount > 0) {
                    section.completionPercentage = section.answeredQuestionCount /
                        section.questionCount * 100;
                } else {
                    section.completionPercentage = 0;
                }
            });
        }

        calculatePercentage(vm.sections);

        switch ($state.current.name) {
            case "dashboard.user.audits.list.details.sections.details":
            case "dashboard.user.sites.list.details.audits.details.sections.details":
                vm.viewRoute = ".";
                vm.sectionListType = "details";
                break;
           default:
                vm.viewRoute = ".details";
                vm.sectionListType = "root";
        }

        if (sectionResponse) {
            vm.params = { parent: sectionResponse.id }
        }

        if(lodash.has(vm.sectionResponse, '_links.self.href')) {
           vm.title = $filter('translate')('AUDIT_SUB_SECTIONS');
           vm.noBorder = false;

           // don't allow sub-sections to be added if there are already questions in this section
           if(vm.sectionResponse.sectionQuestionCount > 0) {
                vm.hidePanel = true;
           }

           // don't allow sub-sections to be added if we are already 3 levels down.
            if(vm.sectionResponse.path.length > 2) {
                vm.hidePanel = true;
           }
        }

        vm.originalSections = angular.copy(vm.sections);

        // Reset the order of the sections back to original order
        function resetSections () {
            vm.sections = angular.copy(vm.originalSections);
            vm.haveSectionsChanged = false;
        };

        // ng-sortable parameters
        vm.sortableOptions = sortableOptions.addProperty({
            orderChanged: function(event) {
                vm.haveSectionsChanged = true;
            }
        });

        function updateSections () {
            var update = {
                _links: { sections: [] },
            };

            // Build the record to be sent to the server
            angular.forEach(vm.sections, function(value, key) {
               update._links.sections.push(value._links.self);
            });

            apiClient.create(vm.sectionsCollectionResponse.getLink('create-sections-changeset'), update).then(function (isSuccess) {
                if (isSuccess) {
                    $state.go('.', {}, {reload: true}).then(function(){
                        messenger.success('AUDIT_SECTION_ORDER_UPDATED');
                    });
                } else {
                    messenger.error('REQUEST_ERROR');
                }
            });
        }
    }
})();
