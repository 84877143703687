(function() {
    'use strict';

    angular
        .module('elogbooks.admin.charge-types')
        .controller('ChargeTypesController', ChargeTypesController);

    ChargeTypesController.$inject = ['$state', '$stateParams', 'chargeTypesCollectionResponse'];

    function ChargeTypesController($state, $stateParams, chargeTypesCollectionResponse) {

        var vm = this;
        vm.chargeTypes = chargeTypesCollectionResponse.chargetypes;
        vm.chargeTypesCollectionResponse = chargeTypesCollectionResponse;
        vm.criteria = {
            id: {type: 'number', value: $stateParams.id ? parseInt($stateParams.id, 10) : null, title: 'ID', min: 1},
        };
        vm.order = orderAction;
        vm.search = searchAction;

        function searchAction(params) {
            var override = {
                page: 1
            };

            $state.go('.', angular.extend({}, params, override), {reload: $state.current});
        }

        function orderAction(key) {
            $stateParams.order = key;

            $state.go('.', angular.extend({}, $stateParams), {reload: $state.current});
        }
    }
})();
