(function() {
    'use strict';

    angular
        .module('elogbooks.user.meter-units')
        .controller('MeterUnitDetailsController', MeterUnitDetailsController);

    MeterUnitDetailsController.$inject = ['meterUnitResponse'];
    function MeterUnitDetailsController(meterUnitResponse) {
        var vm = this;
        vm.meterUnit = meterUnitResponse;
    }
})();
