(function() {
    'use strict';

    angular
        .module('elogbooks')
        .config(Translations);

    function Translations($translateProvider) {
        $translateProvider.translations('nl', {
            JOB_WORKFLOW_ESCALATE: 'Escaleren',
            JOB_WORKFLOW_ESCALATE_OPERATION: 'Escaleren',
            JOB_WORKFLOW_ESCALATE_OPERATION_SUBMIT: 'Escaleren',

            JOB_WORKFLOW_COMMENCE: 'Beginnen opdracht',

            JOB_WORKFLOW_COMPLETE: 'Opdracht afgerond',
            JOB_WORKFLOW_COMPLETE_OPERATION: 'Opdracht afronden',
            JOB_WORKFLOW_COMPLETE_OPERATION_SUBMIT: 'Opdracht afronden',
            JOB_WORKFLOW_COMPLETE_OPERATION_SUCCESS: 'Opdracht afgerond',

            JOB_WORKFLOW_REQUEST_EXTENSION: 'Verzoek om uitstel',
            JOB_WORKFLOW_REQUEST_EXTENSION_SUBMIT: 'Verzoek',
            JOB_WORKFLOW_REQUEST_EXTENSION_SUCCESS: 'Verzoek om uitstel gedaan',

            JOB_WORKFLOW_ACCEPT_EXTENSION: 'Accepteer uitstel',
            JOB_WORKFLOW_ACCEPT_EXTENSION_SUBMIT: 'Accepteer',
            JOB_WORKFLOW_ACCEPT_EXTENSION_SUCCESS: 'Uitstel geaccepteerd',

            JOB_WORKFLOW_REJECT_EXTENSION: 'Uitstel afwijzen',
            JOB_WORKFLOW_REJECT_EXTENSION_SUBMIT: 'Afwijzen',
            JOB_WORKFLOW_REJECT_EXTENSION_SUCCESS: 'Uitstel afgewezen',

            JOB_WORKFLOW_CANCEL_OPERATION: 'Werk annuleren',
            JOB_WORKFLOW_CANCEL_OPERATION_SUBMIT: 'Doorgeven',
            JOB_WORKFLOW_CANCEL_OPERATION_SUCCESS: 'Werk geannuleerd',
            JOB_WORKFLOW_CANCEL_ASSIGNMENT: 'Annuleer opdracht',   

            JOB_WORKFLOW_SKIP: 'Overslaan',
            JOB_WORKFLOW_SKIP_OPERATION: 'Overslaan',
            JOB_WORKFLOW_SKIP_OPERATION_SUBMIT: 'Indienen',

            JOB_WORKFLOW_ACCEPT_ASSIGNMENT: 'Bevestigen',
            JOB_WORKFLOW_REQUEST_PROPOSAL: 'Offerteverzoek',
            JOB_WORKFLOW_INSIST_ASSIGNMENT: 'Aandringen',

            JOB_WORKFLOW_ACCEPT_APPROVAL: 'Goedkeuren',
            JOB_WORKFLOW_ACCEPT_APPROVAL_SUBMIT: 'Goedkeuren',

            JOB_WORKFLOW_REOPEN: 'Heropenen',
            JOB_WORKFLOW_REOPEN_OPERATION: 'Heropenen',
            JOB_WORKFLOW_REOPEN_OPERATION_SUBMIT: 'Heropenen',
            JOB_WORKFLOW_REOPEN_OPERATION_SUCCESS: 'Opdracht heropend',

            JOB_WORKFLOW_ESCALATE_APPROVAL: 'Escaleren goedkeuring',

            JOB_WORKFLOW_REASSIGN_ASSIGNMENT: 'Opdracht opnieuw toewijzen',

            JOB_WORKFLOW_CHANGE_REQUIRED_BY_DATE_OPERATION: 'Actie uitstellen',
            JOB_WORKFLOW_CHANGE_REQUIRED_BY_DATE_OPERATION_SUBMIT: 'Actie uitstellen',
            JOB_WORKFLOW_CHANGE_REQUIRED_BY_DATE_OPERATION_SUCCESS: 'Actie uitgesteld',

            JOB_WORKFLOW_EDIT_JOB: 'Pas opdracht aan',
            JOB_WORKFLOW_EDIT_JOB_SUBMIT: 'Opslaan',
            JOB_WORKFLOW_EDIT_JOB_SUCCESS: 'Opdracht aangepast',

            JOB_WORKFLOW_P2P: 'P2P - Proactis',

            NEEDS_ONE_STATUTORY_FILE: 'Er moet een bestand wettelijk verplicht zijn',
            NO_FUTURE_JOBS: 'Geen opdrachten in de toekomst',
            INVALID_OPERATIVE_SELECTED: 'Geselecteerde leverancier is ongeldig',
            SELECTED_OPERATIVE_DOESNT_EXIST: 'Geselecteerde leverancier bestaat niet',
            OPERATIVE_REQUIRED_NO_PRIMARY_SET: 'Leveracnier nodig, er is geen eerste keus beschikbaar'
        });
    }
})();
