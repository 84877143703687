;(function() {
    'use strict';

    angular
        .module('elogbooks.admin.forms')
        .controller('FormDetailsVersionEditController', FormDetailsVersionEditController);

    FormDetailsVersionEditController.$inject = ['$state', '$scope', 'lodash', 'messenger', 'crudService', 'requestDataFactory', 'formVersionResponse', 'formResponse', 'associatedEntities'];

    function FormDetailsVersionEditController($state, $scope, lodash, messenger, crudService, requestDataFactory, formVersionResponse, formResponse, associatedEntities) {
        var vm = this;
        vm.formResponse = formResponse;
        vm.formVersionResponse = formVersionResponse;
        vm.data = requestDataFactory.createRequest(angular.extend({settings: [], components: []}, formVersionResponse.data));
        vm.associatedEntities = associatedEntities;

        vm.originalObject = angular.extend({}, formVersionResponse);

        vm.update = updateVersion;
        vm.delete = deleteVersion;

        function updateVersion() {
            return crudService.update(
                vm.formVersionResponse.getLink('edit'),
                vm.data,
                null,
                null,
                'FORM_VERSION',
                true
            ).then(function(response) {
                if (response) {
                    messenger.success('FORM_VERSION_UPDATED');
                    $state.go('^.^', {selectedVersion: response.version || formVersionResponse.version}, {reload: $state.get('^.^')});
                }
            });
        }

        function deleteVersion() {
            return crudService.delete(
                vm.formVersionResponse.getLink('delete'),
                $state.get('dashboard.admin.forms.list'),
                'FORM_VERSION'
            );
        }
    }
})();
