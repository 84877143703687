(function() {
    'use strict';

    angular
        .module('elogbooks.admin.timesheet-types')
        .config(registerRoutes)
        .config(registerRoutesList)
        .config(registerRoutesAdd)
        .config(registerRoutesDetails)
        .config(registerRoutesEdit);

    function registerRoutes($stateProvider) {
        $stateProvider
            .state('dashboard.admin.timesheet-types', {
                abstract: true,
                templateUrl: 'shared-templates/view.html',
                ncyBreadcrumb: {
                    skip: true
                },
                data: {
                    permissions: ['user_permission_view_timesheet_types']
                }
            });
    }

    function registerRoutesList($stateProvider) {
        $stateProvider
            .state('dashboard.admin.timesheet-types.list', {
                url: '/timesheet-types?page&limit&order&id&name',
                parent: 'dashboard.admin.timesheet-types',
                views: {
                    '@dashboard.admin.timesheet-types': {
                        templateUrl: '/modules/admin/timesheet-types/timesheet-type-list.html',
                        controller: 'TimesheetTypesController',
                        controllerAs: 'vm'
                    }
                },
                params: {
                    page: '1',
                    limit: '30',
                    order: 'id',
                    id: null,
                    name: null
                },
                resolve: {
                    timesheetTypesCollectionResponse: function($stateParams, apiClient, rootResourceResponse) {
                        return apiClient.get(rootResourceResponse.getLink('timesheettypes'), $stateParams).then( function(response) {
                            return response || apiClient.noResourceFound();
                        });
                    }
                },
                ncyBreadcrumb: {
                    label: '{{ ::"TIMESHEET_TYPE_LIST" | translate }}'
                }
            });
    }

    function registerRoutesAdd($stateProvider) {
        $stateProvider
            .state('dashboard.admin.timesheet-types.list.add', {
                url: '/add',
                parent: 'dashboard.admin.timesheet-types.list',
                views: {
                    '@dashboard.admin.timesheet-types': {
                        templateUrl: '/modules/admin/timesheet-types/add-edit/timesheet-type-add-edit.html',
                        controller: 'TimesheetTypesAddEditController',
                        controllerAs: 'vm'
                    }
                },
                resolve: {
                    timesheetTypeResponse: function() {
                        return {};
                    }
                },
                ncyBreadcrumb: {
                    label: '{{ ::"ADD" | translate }}'
                }
            });
    }

    function registerRoutesDetails($stateProvider) {
        $stateProvider
            .state('dashboard.admin.timesheet-types.list.details', {
                url: '/details/{resource}',
                abstract: true,
                parent: 'dashboard.admin.timesheet-types.list',
                views: {
                    '@dashboard.admin.timesheet-types': {
                        templateUrl: '/modules/admin/timesheet-types/details/timesheet-type-details.html',
                        controller: 'TimesheetTypesDetailsController',
                        controllerAs: 'vm'
                    }
                },
                resolve: {
                    timesheetTypeResponse: function(apiClient, $stateParams) {
                        return apiClient.get($stateParams.resource.decode()).then(function(response) {
                            return response || apiClient.noResourceFound();
                        });
                    }
                },
                ncyBreadcrumb: {
                    label: '{{ ::$resolves().timesheetTypeResponse.name }}'
                }
            })
             .state('dashboard.admin.timesheet-types.list.details.info', {
                url: '/info',
                parent: 'dashboard.admin.timesheet-types.list.details',
                views: {
                    '@dashboard.admin.timesheet-types.list.details': {
                        templateUrl: '/modules/admin/timesheet-types/details/info/timesheet-type-info.html',
                        controller: 'TimesheetTypesInfoController',
                        controllerAs: 'vm'
                    }
                },
                ncyBreadcrumb: {
                    label: '{{ ::"INFO" | translate }}'
                }
            });
    }

    function registerRoutesEdit($stateProvider) {
        $stateProvider
            .state('dashboard.admin.timesheet-types.list.details.info.edit', {
                url: '/edit',
                parent: 'dashboard.admin.timesheet-types.list.details.info',
                views: {
                    '@dashboard.admin.timesheet-types.list.details': {
                        templateUrl: '/modules/admin/timesheet-types/add-edit/timesheet-type-add-edit.html',
                        controller: 'TimesheetTypesAddEditController',
                        controllerAs: 'vm'
                    }
                },
                ncyBreadcrumb: {
                    label: '{{ ::"EDIT" | translate }}'
                }
            });
    }

})();
