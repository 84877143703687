(function() {
    'use strict';

    angular
        .module('elogbooks.user.sites')
        .config(registerRoutes)
        .config(registerRoutesAdd)
        .config(registerRoutesDetails)
        .config(registerRoutesEdit)
        .config(registerRoutesSubAssets)
        .config(registerRoutesSubAssetAdd)
        .config(registerRoutesSubAssetDetails)
        .config(registerRoutesSubAssetEdit)
        .config(registerRoutesSubAssetHitoricalScores)
        .config(registerRoutesServiceRoutines)
        .config(registerRoutesServiceRoutinesEdit)
        .config(registerRoutesConditions)
        .config(registerRoutesHistoricalScores)
        .config(registerRoutesTasks)
        .config(registerRoutesForms)
        .config(registerRoutesFiles)
        .config(registerRoutesAssetsFiles);

    function registerRoutes($stateProvider) {
        $stateProvider
            .state('dashboard.user.sites.list.details.assets', {
                url: '/assets',
                abstract: true,
                ncyBreadcrumb: {
                    label: '{{ ::"ASSETS" | translate }}'
                }
            })
            .state('dashboard.user.sites.list.details.assets.manage-assets', {
                url: '/manage',
                parent: 'dashboard.user.sites.list.details.assets',
                views: parseBook({
                    'elogbooks-right@dashboard.user.sites.list.details': {
                        templateUrl: '/modules/common/asset/assets.html',
                        controller: 'UserAssetsController',
                        controllerAs: 'vm'
                    }
                }),
                resolve: {
                    sectionResource: function(user, apiClient, userManager) {
                        return {
                            canImport: false,
                            listSref: 'dashboard.user.sites.list.details.assets.manage-assets.list',
                            importSref: 'dashboard.user.sites.list.details.assets.manage-assets.imports.list',
                            currentView: 'elogbooks-right@dashboard.user.sites.list.details.assets',
                            isSite: true
                        };
                    }
                },
                ncyBreadcrumb: {
                    skip: true
                }
            })
            .state('dashboard.user.sites.list.details.assets.manage-assets.list', {
                url: '/list?assetPage&assetLimit&assetOrder&assetType&serviceProvider&serviceRoutine&siteIds&active&assetScore',
                parent: 'dashboard.user.sites.list.details.assets.manage-assets',
                views: parseBook({
                    'elogbooks-right@dashboard.user.sites.list.details.assets': {
                        templateUrl: '/modules/common/asset/manage/list/assets-list.html',
                        controller: 'UserAssetsListController',
                        controllerAs: 'vm'
                    }
                }),
                resolve: {
                    assetCollectionResponse: function(apiClient, $stateParams, siteResponse, user) {
                        var params = $stateParams;
                        params.site = siteResponse.id;

                        if (typeof $stateParams.assetType === 'undefined') {
                            $stateParams.assetType = [];
                        }

                        if ($stateParams.active === null) {
                            $stateParams.active = '1';
                        }

                        params = angular.extend({}, params, {
                            'assetTypes[]': $stateParams.assetType,
                            'active': $stateParams.active,
                            page: $stateParams.assetPage,
                            limit: $stateParams.assetLimit,
                            order: $stateParams.assetOrder,
                            site: siteResponse.id
                        });

                        if ($stateParams.serviceProvider) {
                            params.serviceProvider = JSON.parse($stateParams.serviceProvider).v;
                        }

                        if ($stateParams.serviceRoutine) {
                            params.serviceRoutine = JSON.parse($stateParams.serviceRoutine).v;
                        }

                        return apiClient.get(user.getLink('assets'), params).then(function (response) {
                            return response;
                        });
                    },
                    assetTypesCollectionResponse: function(apiClient, rootResourceResponse) {
                        return apiClient.get(rootResourceResponse.getLink('assettypes'), { active: 1, datagroup: 'search' });
                    },
                    serviceProviderCollectionResponse: function($stateParams, apiClient, user) {
                        if (!user.getLink('serviceproviders')) {
                            return null;
                        }

                        var params = {};
                        if ($stateParams.serviceProviders) {
                            params.id = $stateParams.serviceProviders[0];
                        }

                        if (user.getLink('serviceproviders')) {
                            return apiClient.get(user.getLink('serviceproviders'), params).then(function(response) {
                                return response || apiClient.noResourceFound();
                            });
                        }
                    },
                    serviceRoutineCollectionResponse: function ($stateParams, apiClient, rootResourceResponse) {
                        if (!rootResourceResponse.getLink('serviceroutines')) {
                            return null;
                        }
                        var params = {};
                        if ($stateParams.serviceRoutines) {
                            params.id = $stateParams.serviceRoutines[0];
                        }
                        return apiClient.get(rootResourceResponse.getLink('serviceroutines'), params).then(function (response) {
                            return response;
                        });
                    },
                    assetScoreTypeCollectionResponse: function (apiClient) {
                        return apiClient.get('/assetscoretypes', {active: 1}).then(function (response) {
                            return response;
                        });
                    }
                },
                params: {
                    assetPage: '1',
                    assetLimit: '30',
                    assetOrder: 'name',
                    assetType: {array: true},
                    serviceProvider: null,
                    serviceRoutine: null,
                    active: '1',
                    name: null,
                    assetScore: null
                },
                ncyBreadcrumb: {
                    label: '{{ ::"MANAGE_ASSETS" | translate }}'
                }
            });
    }

    function registerRoutesAdd($stateProvider) {
        $stateProvider
            .state('dashboard.user.sites.list.details.assets.manage-assets.list.add', {
                url: '/add',
                parent: 'dashboard.user.sites.list.details.assets.manage-assets.list',
                views: parseBook({
                    'elogbooks-right@dashboard.user.sites': {
                        templateUrl: '/modules/common/asset/manage/add-edit/asset-form.html',
                        controller: 'UserAssetsAddEditController',
                        controllerAs: 'vm'
                    }
                }),
                resolve: {
                    assetResponse: function (siteResponse) {
                        if (typeof siteResponse.id !== 'undefined') {
                            return {
                                _links : {
                                    site : siteResponse ? { href: siteResponse.getLink('self'), title: siteResponse.name } : null,
                                }
                            };
                        }
                        return {};
                    },
                    siteCollectionResponse: function(user, apiClient) {
                        return apiClient.get(user.getLink('sites'), {datagroup: 'search'});
                    },
                    jobGroupsCollectionResponse: function(apiClient, rootResourceResponse) {
                        return apiClient.get(rootResourceResponse.getLink('jobgroups'));
                    },
                    assetTypesCollectionResponse: function(apiClient, rootResourceResponse) {
                        return apiClient.get(rootResourceResponse.getLink('assettypes'));
                    },
                    versionedServiceRoutinesCollectionResponse: function(apiClient, rootResourceResponse) {
                        return apiClient.get(rootResourceResponse.getLink('serviceroutines'), { active: 1, isVersioned: 1 });
                    },
                    nonVersionedServiceRoutinesCollectionResponse: function(apiClient, rootResourceResponse) {
                        return apiClient.get(rootResourceResponse.getLink('serviceroutines'), { active: 1, isVersioned: 0 });
                    },
                    statutoryTypesCollectionResponse: function(apiClient, rootResourceResponse) {
                        return apiClient.get(rootResourceResponse.getLink('statutorytypes'));
                    },
                    serviceProviderCollectionResponse: function(apiClient, siteResponse) {
                        return apiClient.get(siteResponse.getLink('serviceproviders'));
                    },
                    assetScoreTypesResponse: function(rootResourceResponse, apiClient) {
                        return apiClient.get(rootResourceResponse.getLink('assetscoretypes')).then(function(response) {
                            return response;
                        });
                    },
                    assetServiceRoutinesCollectionResponse: function () {
                        return {};
                    },
                    canEditScoreTypes: function() {
                        return true;
                    }
                },
                ncyBreadcrumb: {
                    label: '{{ ::"ADD" | translate }}'
                }
            });
    }

    function registerRoutesDetails($stateProvider) {
        $stateProvider
            .state('dashboard.user.sites.list.details.assets.manage-assets.list.details', {
                url: '/details/{assetResource}',
                abstract: true,
                parent: 'dashboard.user.sites.list.details.assets.manage-assets.list',
                views: parseBook({
                    'elogbooks-right@dashboard.user.sites': {
                        templateUrl: '/modules/common/asset/manage/details/asset-details.html',
                        controller: 'UserAssetDetailsController',
                        controllerAs: 'vm'
                    }
                }),
                resolve: {
                    assetResponse: function(apiClient, $stateParams) {
                        return apiClient.get($stateParams.assetResource.decode()).then(function(response) {
                            if (response) {
                                return response;
                            }
                        });
                    },
                    assetServiceRoutinesCollectionResponse: function(apiClient, assetResponse) {
                        return apiClient.get(assetResponse.getLink('serviceroutines'));
                    },
                    sectionResource: function() {
                        return {
                            infoSref: 'dashboard.user.sites.list.details.assets.manage-assets.list.details.info',
                            subSref: 'dashboard.user.sites.list.details.assets.manage-assets.list.details.sub-assets',
                            serviceRoutineSref: 'dashboard.user.sites.list.details.assets.manage-assets.list.details.service-routines',
                            historicalScoresSref: 'dashboard.user.sites.list.details.assets.manage-assets.list.details.historical-scores',
                            tasksSref: 'dashboard.user.sites.list.details.assets.manage-assets.list.details.tasks',
                            formsSref: 'dashboard.user.sites.list.details.assets.manage-assets.list.details.forms',
                            filesSref: 'dashboard.user.sites.list.details.assets.manage-assets.list.details.files',
                            assetFilesSref: 'dashboard.user.sites.list.details.assets.manage-assets.list.details.assets-files',
                        };
                    },
                    assetFilesCollectionResponse: function (apiClient, assetResponse) {
                        return apiClient.get(assetResponse.getLink('images')).then(function (response) {
                            return response || apiClient.noResourceFound();
                        });
                    },
                    jobResponse: function () {
                        return null;
                    }
                },
                ncyBreadcrumb: {
                    label: '{{ ::$resolves().assetResponse.name }}'
                }
            })
            .state('dashboard.user.sites.list.details.assets.manage-assets.list.details.info', {
                url: '/info',
                parent: 'dashboard.user.sites.list.details.assets.manage-assets.list.details',
                views: parseBook({
                    'elogbooks-right@dashboard.user.sites.list.details.assets.manage-assets.list.details': {
                        templateUrl: '/modules/common/asset/manage/details/info/asset-info.html',
                        controller: 'UserAssetInfoController',
                        controllerAs: 'vm'
                    }
                }),
                ncyBreadcrumb: {
                    label: '{{ ::"INFO" | translate }}'
                }
            });
    }

    function registerRoutesEdit($stateProvider) {
        $stateProvider
            .state('dashboard.user.sites.list.details.assets.manage-assets.list.details.info.edit', {
               url: '/edit',
               parent: 'dashboard.user.sites.list.details.assets.manage-assets.list.details.info',
               views: parseBook({
                   'elogbooks-right@dashboard.user.sites.list.details.assets.manage-assets.list.details': {
                       templateUrl: '/modules/common/asset/manage/add-edit/asset-form.html',
                       controller: 'UserAssetsAddEditController',
                       controllerAs: 'vm'
                   }
               }),
               resolve: {
                   siteResponse: function(assetResponse, apiClient) {
                       return apiClient.get(assetResponse.getLink('site'));
                   },
                   siteCollectionResponse: function(user, apiClient) {
                       return apiClient.get(user.getLink('sites'), {datagroup: 'search'});
                   },
                   jobGroupsCollectionResponse: function(apiClient, rootResourceResponse) {
                       return apiClient.get(rootResourceResponse.getLink('jobgroups'));
                   },
                   assetTypesCollectionResponse: function(apiClient, rootResourceResponse) {
                       return apiClient.get(rootResourceResponse.getLink('assettypes'));
                   },
                   versionedServiceRoutinesCollectionResponse: function(apiClient, rootResourceResponse) {
                       return apiClient.get(rootResourceResponse.getLink('serviceroutines'), { active: 1, isVersioned: 1 });
                   },
                   nonVersionedServiceRoutinesCollectionResponse: function(apiClient, rootResourceResponse) {
                       return apiClient.get(rootResourceResponse.getLink('serviceroutines'), { active: 1, isVersioned: 0 });
                   },
                   statutoryTypesCollectionResponse: function(apiClient, rootResourceResponse) {
                       return apiClient.get(rootResourceResponse.getLink('statutorytypes'));
                   },
                   serviceProviderCollectionResponse: function(apiClient, siteResponse) {
                       return [];
                   },
                   canEditScoreTypes: function (user, userManager, apiClient) {
                       if (userManager.hasPermission("site_permission_edit_score_types_on_asset", user)) {
                           return true;
                       }
                       if (!user.getLink('operatives')) {
                           return false;
                       }
                       return apiClient.get(
                           user.getLink('operatives'),
                           {permission: 'operative_permission_edit_score_types_on_asset'}
                       ).then(function(response) {
                           return response.count;
                       });
                   },
                   assetScoreTypesResponse: function(rootResourceResponse, apiClient) {
                       return apiClient.get(rootResourceResponse.getLink('assetscoretypes')).then(function(response) {
                           return response;
                       });
                   }
               },
                ncyBreadcrumb: {
                    label: '{{ ::"EDIT" | translate }}'
                }
            });
    }

    function registerRoutesSubAssets($stateProvider) {
        $stateProvider
            .state('dashboard.user.sites.list.details.assets.manage-assets.list.details.sub-assets', {
                url: '/sub-assets?subAssetPage&subAssetLimit&subAssetOrder&subAssetName&subAssetReference',
                parent: 'dashboard.user.sites.list.details.assets.manage-assets.list.details',
                views: parseBook({
                    'elogbooks-right@dashboard.user.sites.list.details.assets.manage-assets.list.details': {
                        templateUrl: '/modules/common/asset/manage/details/sub-assets/sub-assets-list.html',
                        controller: 'UserAssetsSubAssetsController',
                        controllerAs: 'vm'
                    }
                }),
                params: {
                    subAssetPage: '1',
                    subAssetLimit: '30',
                    subAssetOrder: 'name',
                    subAssetName: null,
                    subAssetReference: null,
                },
                resolve: {
                    subAssetCollectionResponse: function(apiClient, assetResponse, $stateParams, messenger, $state) {
                        return apiClient.get(assetResponse.getLink('subassets'), angular.extend({}, $stateParams, {
                            page: $stateParams.subAssetPage,
                            limit: $stateParams.subAssetLimit,
                            order: $stateParams.subAssetOrder,
                            name: $stateParams.subAssetName,
                            reference: $stateParams.subAssetReference
                        })).then(function(response) {
                            if (response) {
                                return response;
                            }
                        });
                    },
                    config: function() {
                        return {};
                    },
                },
                ncyBreadcrumb: {
                    label: '{{ ::"SUB_ASSETS" | translate }}'
                }
            });
    }

    function registerRoutesSubAssetAdd($stateProvider) {
        $stateProvider
            .state('dashboard.user.sites.list.details.assets.manage-assets.list.details.sub-assets.add', {
                url: '/add',
                parent: 'dashboard.user.sites.list.details.assets.manage-assets.list.details.sub-assets',
                views: parseBook({
                    'elogbooks-right@dashboard.user.sites.list.details.assets.manage-assets.list.details': {
                        templateUrl: '/modules/common/asset/manage/details/sub-assets/add-edit/sub-asset-form.html',
                        controller: 'UserAssetsSubAssetAddEditController',
                        controllerAs: 'vm'
                    }
                }),
                resolve: {
                    subAssetResponse: function() {
                        return {};
                    },
                    siteResponse: function(apiClient, assetResponse) {
                        return apiClient.get(assetResponse.getLink('site'));
                    },
                    locationsCollectionResponse: function(apiClient, siteResponse) {
                        return apiClient.get(siteResponse.getLink('locations'));
                    },
                    jobGroupsCollectionResponse: function(apiClient, rootResourceResponse) {
                        return apiClient.get(rootResourceResponse.getLink('jobgroups'));
                    },
                    assetTypesCollectionResponse: function(apiClient, rootResourceResponse) {
                        return apiClient.get(rootResourceResponse.getLink('assettypes'));
                    },
                    statutoryTypesCollectionResponse: function(apiClient, rootResourceResponse) {
                        return apiClient.get(rootResourceResponse.getLink('statutorytypes'));
                    }
                },
                ncyBreadcrumb: {
                    label: '{{ ::"ADD" | translate }}'
                }
            });
    }

    function registerRoutesSubAssetDetails($stateProvider) {
        $stateProvider
            .state('dashboard.user.sites.list.details.assets.manage-assets.list.details.sub-assets.details', {
                url: '/details/{subAssetResource}',
                abstract: true,
                parent: 'dashboard.user.sites.list.details.assets.manage-assets.list.details.sub-assets',
                views: parseBook({
                    'elogbooks-right@dashboard.user.sites': {
                        templateUrl: '/modules/common/asset/manage/details/sub-assets/details/sub-asset-details.html',
                        controller: 'UserAssetsSubAssetDetailsController',
                        controllerAs: 'vm'
                    }
                }),
                resolve: {
                    subAssetResponse: function(apiClient, $state, messenger, $stateParams) {
                        return apiClient.get($stateParams.subAssetResource.decode()).then(function(response) {
                            if (response) {
                                return response;
                            }
                        });
                    },
                    locationResponse: function(apiClient, subAssetResponse) {
                        if (typeof subAssetResponse._links.location != 'undefined') {
                            return apiClient.get(subAssetResponse.getLink('location'));
                        }
                        return {};
                    },
                    sectionResource: function() {
                        return {
                            infoSref: 'dashboard.user.sites.list.details.assets.manage-assets.list.details.sub-assets.details.info',
                            historicalScoresSref: 'dashboard.user.sites.list.details.assets.manage-assets.list.details.sub-assets.historical-scores',
                            tasksSref: 'dashboard.user.sites.list.details.tasks',
                            filesSref: 'dashboard.user.sites.list.details.assets.manage-assets.list.details.sub-assets.details.files',
                            assetFilesSref: 'dashboard.user.sites.list.details.assets.manage-assets.list.details.sub-assets.details.assets-files',
                        };
                    }
                },
                ncyBreadcrumb: {
                    label: '{{ ::$resolves().subAssetResponse.name }}'
                }
            })
            .state('dashboard.user.sites.list.details.assets.manage-assets.list.details.sub-assets.details.info', {
                url: '/info',
                parent: 'dashboard.user.sites.list.details.assets.manage-assets.list.details.sub-assets.details',
                views: parseBook({
                    'elogbooks-right@dashboard.user.sites.list.details.assets.manage-assets.list.details.sub-assets.details': {
                        templateUrl: '/modules/common/asset/manage/details/sub-assets/info/sub-asset-info.html',
                        controller: 'UserSubAssetsInfoController',
                        controllerAs: 'vm'
                    }
                }),
                ncyBreadcrumb: {
                    label: '{{ ::"INFO" | translate }}'
                }
            });
    }

    function registerRoutesSubAssetEdit($stateProvider) {
        $stateProvider
            .state('dashboard.user.sites.list.details.assets.manage-assets.list.details.sub-assets.details.info.edit', {
                url: '/edit',
                parent: 'dashboard.user.sites.list.details.assets.manage-assets.list.details.sub-assets.details.info',
                views: parseBook({
                    'elogbooks-right@dashboard.user.sites.list.details.assets.manage-assets.list.details.sub-assets.details': {
                        templateUrl: '/modules/common/asset/manage/details/sub-assets/add-edit/sub-asset-form.html',
                        controller: 'UserAssetsSubAssetAddEditController',
                        controllerAs: 'vm'
                    }
                }),
                resolve: {
                    siteResponse: function(apiClient, assetResponse) {
                        return apiClient.get(assetResponse.getLink('site'));
                    },
                    locationsCollectionResponse: function(apiClient, siteResponse) {
                        return apiClient.get(siteResponse.getLink('locations'));
                    },
                    jobGroupsCollectionResponse: function(apiClient, rootResourceResponse) {
                        return apiClient.get(rootResourceResponse.getLink('jobgroups'));
                    },
                    assetTypesCollectionResponse: function(apiClient, rootResourceResponse) {
                        return apiClient.get(rootResourceResponse.getLink('assettypes'));
                    },
                    statutoryTypesCollectionResponse: function(apiClient, rootResourceResponse) {
                        return apiClient.get(rootResourceResponse.getLink('statutorytypes'));
                    }
                },
                ncyBreadcrumb: {
                    label: '{{ ::"EDIT" | translate }}'
                }
            });
    }

    function registerRoutesSubAssetHitoricalScores($stateProvider) {
        $stateProvider
            .state('dashboard.user.sites.list.details.assets.manage-assets.list.details.sub-assets.historical-scores', {
                url: '/historical-scores?historicalScorePage&historicalScoreLimit&assetScoreType',
                parent: 'dashboard.user.sites.list.details.assets.manage-assets.list.details.sub-assets.details',
                views: parseBook({
                    'elogbooks-right@dashboard.user.sites.list.details.assets.manage-assets.list.details.sub-assets.details': {
                        templateUrl: '/modules/common/asset/manage/details/historical-scores/historical-scores.html',
                        controller: 'UserAssetsHistoricalScoresController',
                        controllerAs: 'vm'
                    }
                }),
                params: {
                    historicalScorePage: '1',
                    historicalScoreLimit: '30',
                    assetScoreType: null,
                    dateRange: null,
                    historicalScoreOrder: '-completedAt'
                },
                resolve: {
                    subAssetResponse: function(apiClient, $stateParams) {
                        return apiClient.get($stateParams.subAssetResource.decode()).then(function(response) {
                            if (response) {
                                return response;
                            }
                        });
                    },
                    historicalScoresCollectionResponse: function(assetResponse, subAssetResponse, apiClient, $stateParams) {
                        $stateParams.subAssetId = subAssetResponse.id;

                        return apiClient.get(subAssetResponse.getLink('historicalscores'), $stateParams).then(function(response) {
                            return response;
                        });
                    },
                    assetScoreTypesResponse: function(rootResourceResponse, apiClient) {
                        return apiClient.get(rootResourceResponse.getLink('assetscoretypes')).then(function(response) {
                            return response;
                        });
                    }
                },
                ncyBreadcrumb: {
                    label: '{{ ::"ASSET_HISTORICAL_SCORES" | translate }}'
                }
            });
    }

    function registerRoutesServiceRoutines($stateProvider) {
        $stateProvider
            .state('dashboard.user.sites.list.details.assets.manage-assets.list.details.service-routines', {
                url: '/service-routines?serviceRoutinePage&serviceRoutineLimit',
                parent: 'dashboard.user.sites.list.details.assets.manage-assets.list.details',
                views: parseBook({
                    'elogbooks-right@dashboard.user.sites.list.details.assets.manage-assets.list.details': {
                        templateUrl: '/modules/common/service-routines/service-routines-list.html',
                        controller: 'ServiceRoutinesController',
                        controllerAs: 'vm'
                    }
                }),
                params: {
                    serviceRoutinePage: '1',
                    serviceRoutineLimit: '30',
                    serviceRoutineId: null,
                    serviceRoutineName: null,
                    serviceRoutineDescription: null,
                    serviceRoutineOrder: null
                },
                resolve: {
                    serviceRoutineCollectionResponse: function (apiClient, assetResponse, $stateParams) {
                        var params = {
                            page: $stateParams.serviceRoutinePage,
                            limit: $stateParams.serviceRoutineLimit,
                            id: $stateParams.serviceRoutineId,
                            name: $stateParams.serviceRoutineName,
                            description: $stateParams.serviceRoutineDescription,
                            order: $stateParams.serviceRoutineOrder
                        };

                        if (assetResponse.getLink('serviceroutines')) {
                            return apiClient.get(assetResponse.getLink('serviceroutines'), params);
                        }

                        return {};
                    }
                },
                ncyBreadcrumb: {
                    label: '{{ ::"SERVICE_ROUTINES" | translate }}'
                }
             })
            .state('dashboard.user.sites.list.details.assets.manage-assets.list.details.service-routines.details', {
                    url: '/service-routine-lines/{serviceRoutineResource}?serviceRoutineLinePage&serviceRoutineLineLimit',
                    parent: 'dashboard.user.sites.list.details.assets.manage-assets.list.details.service-routines',
                    views: parseBook({
                        'elogbooks-right@dashboard.user.sites.list.details.assets.manage-assets.list.details': {
                            templateUrl: '/modules/common/service-routines/lines/service-routine-lines-list.html',
                            controller: 'CommonServiceRoutineLinesController',
                            controllerAs: 'vm'
                        }
                    }),
                    params: {
                        serviceRoutineLinePage: '1',
                        serviceRoutineLineLimit: '30'
                    },
                    resolve: {
                        serviceRoutine: function (apiClient, $stateParams) {
                            return apiClient.get($stateParams.serviceRoutineResource.decode()).then(function (response) {
                                return response || apiClient.noResourceFound();
                            });
                        },
                        serviceRoutineLines: function(apiClient, serviceRoutine, $stateParams) {
                            if (serviceRoutine.getLink('lines')) {
                                return apiClient.get(serviceRoutine.getLink('lines'), angular.extend({}, $stateParams, {
                                    page: $stateParams.serviceRoutineLinePage,
                                    limit: $stateParams.serviceRoutineLineLimit
                                })).then(function(response) {
                                    if (response) {
                                        return response;
                                    }
                                });
                            }

                            return {};
                        }
                    },
                    ncyBreadcrumb: {
                        label: '{{ ::"SERVICE_ROUTINE_LINES" | translate }}'
                    }
            });
    }

    function registerRoutesServiceRoutinesEdit($stateProvider) {
        $stateProvider
            .state('dashboard.user.sites.list.details.assets.manage-assets.list.details.service-routines.edit', {
                url: '/edit-service-routines',
                parent: 'dashboard.user.sites.list.details.assets.manage-assets.list.details.service-routines',
                views: parseBook({
                    'elogbooks-right@dashboard.user.sites.list.details.assets.manage-assets.list.details': {
                        templateUrl: '/modules/user/assets/manage/service-routines/edit/edit-form.html',
                        controller: 'UserAssetsServiceRoutinesEditController',
                        controllerAs: 'vm'
                    }
                }),
                resolve: {
                    versionedServiceRoutinesCollectionResponse: function(apiClient, rootResourceResponse) {
                        return apiClient.get(rootResourceResponse.getLink('serviceroutines'), { active: 1, isVersioned: 1 });
                    },
                    nonVersionedServiceRoutinesCollectionResponse: function(apiClient, rootResourceResponse) {
                        return apiClient.get(rootResourceResponse.getLink('serviceroutines'), { active: 1, isVersioned: 0 });
                    },
                },
                ncyBreadcrumb: {
                    label: '{{ ::"EDIT" | translate }}'
                }
            });
    }

    function registerRoutesConditions($stateProvider) {
        $stateProvider
            .state('dashboard.user.sites.list.details.assets.manage-assets.list.details.conditions', {
                url: '/conditions',
                parent: 'dashboard.user.sites.list.details.assets.manage-assets.list.details',
                views: parseBook({
                    'elogbooks-right@dashboard.user.sites.list.details.assets.manage-assets.list.details': {
                        templateUrl: '/modules/common/asset/manage/details/conditions/conditions-list.html',
                        controller: 'UserAssetsConditionsController',
                        controllerAs: 'vm'
                    }
                }),
                resolve: {
                    conditionCollectionResponse: function(apiClient, assetResponse, $state, messenger) {
                        return apiClient.get(assetResponse.getLink('conditions')).then(function(response) {
                            if (response) {
                                return response;
                            }
                        });
                    }
                },
                ncyBreadcrumb: {
                    label: '{{ ::"ASSET_CONDITIONS" | translate }}'
                }
            });
    }

    function registerRoutesHistoricalScores($stateProvider) {
        $stateProvider
            .state('dashboard.user.sites.list.details.assets.manage-assets.list.details.historical-scores', {
                url: '/historical-scores?historicalScorePage&historicalScoreLimit&assetScoreType',
                parent: 'dashboard.user.sites.list.details.assets.manage-assets.list.details',
                views: parseBook({
                    'elogbooks-right@dashboard.user.sites.list.details.assets.manage-assets.list.details': {
                        templateUrl: '/modules/common/asset/manage/details/historical-scores/historical-scores.html',
                        controller: 'UserAssetsHistoricalScoresController',
                        controllerAs: 'vm'
                    }
                }),
                params: {
                    historicalScorePage: '1',
                    historicalScoreLimit: '30',
                    assetScoreType: null,
                    dateRange: null,
                    historicalScoreOrder: null
                },
                resolve: {
                    historicalScoresCollectionResponse: function(assetResponse, apiClient, $stateParams) {
                        return apiClient.get(assetResponse.getLink('historicalscores'), $stateParams).then(function(response) {
                            return response;
                        });
                    },
                    assetScoreTypesResponse: function(rootResourceResponse, apiClient) {
                        return apiClient.get(rootResourceResponse.getLink('assetscoretypes')).then(function(response) {
                            return response;
                        });
                    }
                },
                ncyBreadcrumb: {
                    label: '{{ ::"ASSET_HISTORICAL_SCORES" | translate }}'
                }
            });
    }

    function registerRoutesTasks($stateProvider) {
        $stateProvider
            .state('dashboard.user.sites.list.details.assets.manage-assets.list.details.tasks', {
                url: '/tasks?name&{serviceProviderName:any}&order&masterTask&serviceType&statutoryType&operativeName&regionName&assetName&approvalStatus&taskFrequency',
                parent: 'dashboard.user.sites.list.details.assets.manage-assets.list.details',
                params: {
                    order: null,
                    taskServiceType: null,
                    taskStatus: null,
                    regionName: null,
                    taskOperativeName: null,
                    taskName: null,
                    taskServiceProviderName: null,
                    approvalStatus: null,
                    masterTask: null,
                    siteIds: {array: true},
                    taskStatutoryType: {array: true},
                    asset: null,
                    taskMasterTask: null,
                    taskFrequency: null,
                    taskApprovalStatus: null,
                    taskAssetId: null,
                    regionId: null
                },
                resolve: {
                    serviceProviderCollection: function($stateParams, apiClient, user, selectedSiteResponse) {
                        var params = {};
                        if (selectedSiteResponse) {
                            params.siteId = selectedSiteResponse.id;
                        }

                        return apiClient.get(user.getLink('serviceproviders'), params).then(function(response) {
                            return response || apiClient.noResourceFound();
                        });
                    },
                    statutoryTypeCollectionResponse: function(rootResourceResponse) {
                        return rootResourceResponse.getResource('statutorytypes');
                    },
                    taskCollectionResource: function($stateParams, selectedSiteResponse, apiClient, user, base64, assetResponse) {
                        var params = $stateParams;

                        if (typeof $stateParams.siteIds === 'undefined') {
                            $stateParams.siteIds = [];
                        }

                        if (selectedSiteResponse) {
                            $stateParams.siteIds = [selectedSiteResponse.id];
                            $stateParams.selectedSiteResource = base64.encode(selectedSiteResponse.getLink('self'));
                        }

                        if (typeof $stateParams.statutoryType === 'undefined') {
                            $stateParams.statutoryType = [];
                        }

                        params = angular.extend({}, params, {
                            'siteIds[]': $stateParams.siteIds,
                            'statutoryType[]': $stateParams.taskStatutoryType,
                            'asset': assetResponse.id,
                            name: $stateParams.taskName,
                            serviceType: $stateParams.taskServiceType,
                            serviceProviderName: $stateParams.taskServiceProviderName,
                            masterTask: $stateParams.taskMasterTask,
                            frequency: $stateParams.taskFrequency,
                            approvalStatus: $stateParams.taskApprovalStatus,
                            assetId: $stateParams.taskAssetId ? JSON.parse($stateParams.taskAssetId).v : null,
                            regionId : $stateParams.regionId ? JSON.parse($stateParams.regionId).v : null,
                            operativeName: $stateParams.taskOperativeName
                        });

                        params.status = 'all';

                        if (params.taskStatus) {
                            params.status = params.taskStatus
                        }

                        return apiClient.get(assetResponse.getLink('tasks'), params);
                    },
                    documentTypesCollectionResponse: function() {
                        return {};
                    }
                },
                views: parseBook({
                    "elogbooks-right@dashboard.user.sites.list.details.assets.manage-assets.list.details": {
                        templateUrl: '/modules/common/asset/manage/details/tasks/tasks.html',
                        controller: 'UserTaskListController',
                        controllerAs: 'vm'
                    }
                }),
                ncyBreadcrumb: {
                    label: '{{ ::"TASKS" | translate }}'
                }
            })
            .state('dashboard.user.sites.list.details.assets.manage-assets.list.details.tasks.add', {
            url: '/add',
            parent: 'dashboard.user.sites.list.details.assets.manage-assets.list.details.tasks',
            resolve: {
                siteResponse: function(assetResponse, apiClient) {
                    return apiClient.get(assetResponse.getLink('site'));
                },
                taskResponse: function() {
                    return {
                        type: 1
                    };
                },
                serviceProviderCollectionResponse: function(apiClient, siteResponse) {
                    return apiClient.get(siteResponse.getLink('partnerships'), { active:1, serviceProvidersStatus: 'active' });
                },
                locationCollectionResponse: function(apiClient, siteResponse) {
                    return apiClient.get(siteResponse.getLink('locations'));
                },
                statutoryTypeCollectionResponse: function(rootResourceResponse) {
                    return rootResourceResponse.getResource('statutorytypes');
                },
                assetsCollectionResponse: function(assetCollectionResponse) {
                    return assetCollectionResponse;
                },
                assetSelected: function (assetResponse) {
                    return assetResponse;
                },
                serviceRoutinesCollectionResponse: function(apiClient, rootResourceResponse) {
                    return apiClient.get(rootResourceResponse.getLink('serviceroutines'), { active: 1 });
                },
                jobGroupCollectionResponse: function(apiClient, rootResourceResponse) {
                    return apiClient.get(rootResourceResponse.getLink('jobgroups'), { limit: 999999 }) || apiClient.noResourceFound();
                },
                versionedServiceRoutinesCollectionResponse: function(apiClient, rootResourceResponse) {
                    return apiClient.get(rootResourceResponse.getLink('serviceroutines'), { active: 1, isVersioned: 1 });
                },
                nonVersionedServiceRoutinesCollectionResponse: function(apiClient, rootResourceResponse) {
                    return apiClient.get(rootResourceResponse.getLink('serviceroutines'), { active: 1, isVersioned: 0 });
                },
                hasEvents:  () =>  false
            },
            views: parseBook({
                'elogbooks-right@dashboard.user.sites': {
                    templateUrl: '/modules/user/tasks/create-process/step2/task-hard.html',
                    controller: 'UserTaskCreateStep2Controller',
                    controllerAs: 'vm'
                }
            }),
            ncyBreadcrumb: {
                label: '{{ ::"ADD" | translate }}'
            }
        })
    }

    function registerRoutesFiles($stateProvider) {
        $stateProvider
            .state('dashboard.user.sites.list.details.assets.manage-assets.list.details.files', {
                url: '/files?filePage&fileLimit&fileOrder&fileTitle&fileUploadedBy&fileUploadedDateStart&fileUploadedDateEnd&fileExpiresDateStart&fileExpiresDateEnd&fileTags&fileIsStatutory&documentType',
                parent: 'dashboard.user.sites.list.details.assets.manage-assets.list.details',
                views: parseBook({
                    'elogbooks-right@dashboard.user.sites.list.details.assets.manage-assets.list.details': {
                        templateUrl: '/modules/common/files/files-list.html',
                        controller: 'CommonFilesListController',
                        controllerAs: 'vm'
                    }
                }),
                params: {
                    fileId: null,
                    filePage: '1',
                    fileLimit: '30',
                    fileOrder: '-id',
                    fileSelectedSiteResource: null,
                    fileTitle: null,
                    fileUploadedBy: null,
                    fileUploadedDateStart: null,
                    fileUploadedDateEnd: null,
                    fileExpiresDateStart: null,
                    fileExpiresDateEnd: null,
                    fileTags: {array: true},
                    fileIsStatutory: null,
                    documentType: null
                },
                resolve: {
                    fileCollectionResponse: function (apiClient, assetResponse, $stateParams) {
                        var params = angular.extend({}, $stateParams,
                            {
                                id: $stateParams.fileId,
                                page: $stateParams.filePage,
                                limit: $stateParams.fileLimit,
                                order: $stateParams.fileOrder,
                                title: $stateParams.fileTitle,
                                uploadedBy: $stateParams.fileUploadedBy,
                                uploadedDateStart: $stateParams.fileUploadedDateStart ? new Date($stateParams.fileUploadedDateStart).toISOString() : null,
                                uploadedDateEnd: $stateParams.fileUploadedDateEnd ? new Date($stateParams.fileUploadedDateEnd).toISOString() : null,
                                expiresDateStart: $stateParams.fileExpiresDateStart ? new Date($stateParams.fileExpiresDateStart).toISOString() : null,
                                expiresDateEnd: $stateParams.fileExpiresDateEnd ? new Date($stateParams.fileExpiresDateEnd).toISOString() : null,
                                tags: $stateParams.fileTags,
                                isStatutory: $stateParams.fileIsStatutory,
                                documentType: $stateParams.documentType ? JSON.parse($stateParams.documentType).v : null
                            }
                        );

                        params = angular.extend({}, params, {
                            'fileTags[]': $stateParams.fileTags
                        });

                        return apiClient.get(assetResponse.getLink('files'), params).then(function (response) {
                            return response || apiClient.noResourceFound();
                        });
                    },
                    tagCollectionResponse: function (apiClient, rootResourceResponse) {
                        return apiClient.get(rootResourceResponse.getLink('tags'), {limit: 30}).then(function (response) {
                            return response || apiClient.noResourceFound();
                        });
                    },
                    config: function (fileCollectionResponse) {
                        return {
                            jobId: true,
                            hideAddButton: true,
                            widerNameColumn: true,
                            showSite: false,
                            hideAdvanced: true,
                            showStatutoryType: true
                        };
                    },
                    integrationDocTypesResponse: function () {
                        return null;
                    },
                    documentTypeCollectionResponse : function (apiClient, rootResourceResponse) {
                        return apiClient.get(rootResourceResponse.getLink('documenttypes'), {status: 'active', order: 'name'})
                            .then(function (response) {
                                return response || apiClient.noResourceFound();
                            });
                    },
                    serviceProviderResponse: function () {
                        return null;
                    },
                    operativeCollectionResponse: function () {
                        return null;
                    }
                },
                ncyBreadcrumb: {
                    label: '{{ ::"RELATED_FILES" | translate }}'
                }
            })
            .state('dashboard.user.sites.list.details.assets.manage-assets.list.details.files.details', {
                url: '/{fileResource}',
                parent: 'dashboard.user.sites.list.details.assets.manage-assets.list.details.files',
                views: parseBook({
                    'elogbooks-right@dashboard.user.sites.list.details.assets.manage-assets.list.details': {
                        templateUrl: '/modules/common/files/details/files-details.html',
                        controller: 'CommonFilesDetailsController',
                        controllerAs: 'vm'
                    }
                }),
                resolve: {
                    fileResponse: function(apiClient, $stateParams) {
                        return apiClient.get($stateParams.fileResource.decode(), {datagroup: 'details'}).then(function(response) {
                            return response || apiClient.noResourceFound();
                        });
                    },
                    config: function (config) {
                        return angular.extend(config, {
                            showVisibility: true,
                            hideEdit: true
                        });
                    }
                },
                ncyBreadcrumb: {
                    label: '{{ ::$resolves().fileResponse.title }}'
                }
            })
            .state('dashboard.user.sites.list.details.assets.manage-assets.list.details.sub-assets.details.files', {
                url: '/files?filePage&fileLimit&fileOrder&fileTitle&fileUploadedBy&fileUploadedDateStart&fileUploadedDateEnd&fileExpiresDateStart&fileExpiresDateEnd&fileTags&fileIsStatutory&documentType',
                parent: 'dashboard.user.sites.list.details.assets.manage-assets.list.details.sub-assets.details',
                views: parseBook({
                    'elogbooks-right@dashboard.user.sites.list.details.assets.manage-assets.list.details.sub-assets.details': {
                        templateUrl: '/modules/common/files/files-list.html',
                        controller: 'CommonFilesListController',
                        controllerAs: 'vm'
                    }
                }),
                params: {
                    fileId: null,
                    filePage: '1',
                    fileLimit: '30',
                    fileOrder: '-id',
                    fileSelectedSiteResource: null,
                    fileTitle: null,
                    fileUploadedBy: null,
                    fileUploadedDateStart: null,
                    fileUploadedDateEnd: null,
                    fileExpiresDateStart: null,
                    fileExpiresDateEnd: null,
                    fileTags: {array: true},
                    fileIsStatutory: null,
                    documentType: null
                },
                resolve: {
                    fileCollectionResponse: function (apiClient, subAssetResponse, $stateParams) {
                        var params = angular.extend({}, $stateParams,
                            {
                                id: $stateParams.fileId,
                                page: $stateParams.filePage,
                                limit: $stateParams.fileLimit,
                                order: $stateParams.fileOrder,
                                title: $stateParams.fileTitle,
                                uploadedBy: $stateParams.fileUploadedBy,
                                uploadedDateStart: $stateParams.fileUploadedDateStart ? new Date($stateParams.fileUploadedDateStart).toISOString() : null,
                                uploadedDateEnd: $stateParams.fileUploadedDateEnd ? new Date($stateParams.fileUploadedDateEnd).toISOString() : null,
                                expiresDateStart: $stateParams.fileExpiresDateStart ? new Date($stateParams.fileExpiresDateStart).toISOString() : null,
                                expiresDateEnd: $stateParams.fileExpiresDateEnd ? new Date($stateParams.fileExpiresDateEnd).toISOString() : null,
                                tags: $stateParams.fileTags,
                                isStatutory: $stateParams.fileIsStatutory,
                                documentType: $stateParams.documentType ? JSON.parse($stateParams.documentType).v : null
                            }
                        );

                        params = angular.extend({}, params, {
                            'fileTags[]': $stateParams.fileTags
                        });

                        return apiClient.get(subAssetResponse.getLink('files'), params).then(function (response) {
                            return response || apiClient.noResourceFound();
                        });
                    },
                    tagCollectionResponse: function (apiClient, rootResourceResponse) {
                        return apiClient.get(rootResourceResponse.getLink('tags'), {limit: 30}).then(function (response) {
                            return response || apiClient.noResourceFound();
                        });
                    },
                    config: function (fileCollectionResponse) {
                        return {
                            jobId: true,
                            hideAddButton: true,
                            widerNameColumn: true,
                            showSite: false,
                            hideAdvanced: true,
                            showStatutoryType: true
                        };
                    },
                    integrationDocTypesResponse: function () {
                        return null;
                    },
                    documentTypeCollectionResponse : function (apiClient, rootResourceResponse) {
                        return apiClient.get(rootResourceResponse.getLink('documenttypes'), {status: 'active', order: 'name'})
                            .then(function (response) {
                                return response || apiClient.noResourceFound();
                            });
                    },
                    serviceProviderResponse: function () {
                        return null;
                    },
                    operativeCollectionResponse: function () {
                        return null;
                    }
                },
                ncyBreadcrumb: {
                    label: '{{ ::"RELATED_FILES" | translate }}'
                }
            })
            .state('dashboard.user.sites.list.details.assets.manage-assets.list.details.sub-assets.details.files.details', {
                url: '/{fileResource}',
                parent: 'dashboard.user.sites.list.details.assets.manage-assets.list.details.sub-assets.details.files',
                views: parseBook({
                    'elogbooks-right@dashboard.user.sites.list.details.assets.manage-assets.list.details.sub-assets.details': {
                        templateUrl: '/modules/common/files/details/files-details.html',
                        controller: 'CommonFilesDetailsController',
                        controllerAs: 'vm'
                    }
                }),
                resolve: {
                    fileResponse: function(apiClient, $stateParams) {
                        return apiClient.get($stateParams.fileResource.decode(), {datagroup: 'details'}).then(function(response) {
                            return response || apiClient.noResourceFound();
                        });
                    },
                    config: function (config) {
                        return angular.extend(config, {
                            showVisibility: true,
                            hideEdit: true
                        });
                    }
                },
                ncyBreadcrumb: {
                    label: '{{ ::$resolves().fileResponse.title }}'
                }
            })
            ;
    }

    function registerRoutesAssetsFiles($stateProvider) {
        $stateProvider
            .state('dashboard.user.sites.list.details.assets.manage-assets.list.details.assets-files', {
                url: '/assets-files?fileId&filePage&fileLimit&fileOrder&fileTitle&fileExpiresDateStart&fileExpiresDateEnd&fileTags&fileIsStatutory&documentType',
                parent: 'dashboard.user.sites.list.details.assets.manage-assets.list.details',
                views: parseBook({
                    'elogbooks-right@dashboard.user.sites.list.details.assets.manage-assets.list.details': {
                        templateUrl: '/modules/common/files/files-list.html',
                        controller: 'CommonFilesListController',
                        controllerAs: 'vm'
                    }
                }),
                params: {
                    fileId: null,
                    filePage: '1',
                    fileLimit: '30',
                    fileOrder: '-id',
                    fileTitle: null,
                    fileExpiresDateStart: null,
                    fileExpiresDateEnd: null,
                    fileTags: {array: true},
                    fileIsStatutory: null,
                    documentTypes: {array: true}
                },
                resolve: {
                    fileCollectionResponse: function (apiClient, assetResponse, $stateParams) {
                        var params = angular.extend({}, $stateParams,
                            {
                                id: $stateParams.fileId,
                                page: $stateParams.filePage,
                                limit: $stateParams.fileLimit,
                                order: $stateParams.fileOrder,
                                title: $stateParams.fileTitle,
                                expiresDateStart: $stateParams.fileExpiresDateStart ? new Date($stateParams.fileExpiresDateStart).toISOString() : null,
                                expiresDateEnd: $stateParams.fileExpiresDateEnd ? new Date($stateParams.fileExpiresDateEnd).toISOString() : null,
                                tags: $stateParams.fileTags,
                                isStatutory: $stateParams.fileIsStatutory
                            }
                        );

                        params = angular.extend({}, params, {
                            'fileTags[]': $stateParams.fileTags,
                            'documentTypes[]': $stateParams.documentTypes
                        });

                        return apiClient.get(assetResponse.getLink('assetsfiles'), params).then(function (response) {
                            return response || apiClient.noResourceFound();
                        });
                    },
                    tagCollectionResponse: function (apiClient, rootResourceResponse) {
                        return apiClient.get(rootResourceResponse.getLink('tags'), {limit: 999999}).then(function (response) {
                            return response || apiClient.noResourceFound();
                        });
                    },
                    config: function (fileCollectionResponse) {
                        return {
                            hideAddButton: false,
                            widerNameColumn: true,
                            hideAdvanced: true,
                            showStatutoryType: true,
                            showSite: false,
                        };
                    },
                    integrationDocTypesResponse: function () {
                        return null;
                    },
                    documentTypeCollectionResponse : function (apiClient, rootResourceResponse) {
                        return apiClient.get(rootResourceResponse.getLink('documenttypes'), {status: 'active', order: 'name'})
                            .then(function (response) {
                                return response || apiClient.noResourceFound();
                            });
                    },
                    serviceProviderResponse: function () {
                        return null;
                    },
                    operativeCollectionResponse: function () {
                        return null;
                    }
                },
                ncyBreadcrumb: {
                    label: '{{ ::"ASSET_FILES" | translate }}'
                }
            })
            .state('dashboard.user.sites.list.details.assets.manage-assets.list.details.assets-files.details', {
                url: '/details/{fileResource}',
                parent: 'dashboard.user.sites.list.details.assets.manage-assets.list.details.assets-files',
                views: parseBook({
                    'elogbooks-right@dashboard.user.sites.list.details.assets.manage-assets.list.details': {
                        templateUrl: '/modules/common/files/details/files-details.html',
                        controller: 'CommonFilesDetailsController',
                        controllerAs: 'vm'
                    }
                }),
                resolve: {
                    fileResponse: function(apiClient, $stateParams) {
                        return apiClient.get($stateParams.fileResource.decode(), {datagroup: 'details'}).then(function(response) {
                            return response || apiClient.noResourceFound();
                        });
                    },
                    tagCollectionResponse: function (apiClient, rootResourceResponse) {
                        return apiClient.get(rootResourceResponse.getLink('tags'), {limit: 999999}).then(function (response) {
                            return response || apiClient.noResourceFound();
                        });
                    },
                    config: function (config) {
                        return angular.extend(config, {
                            showVisibility: true,
                            hideEdit: false
                        });
                    }
                },
                ncyBreadcrumb: {
                    label: '{{ ::$resolves().fileResponse.title }}'
                }
            })
            .state('dashboard.user.sites.list.details.assets.manage-assets.list.details.assets-files.add', {
                url: '/add',
                parent: 'dashboard.user.sites.list.details.assets.manage-assets.list.details.assets-files',
                views: parseBook({
                    'elogbooks-right@dashboard.user.sites.list.details.assets.manage-assets.list.details': {
                        templateUrl: '/modules/common/files/add-edit/files-add-edit.html',
                        controller: 'CommonFilesAddEditController',
                        controllerAs: 'vm'
                    }
                }),
                resolve: {
                    fileResponse: function () {
                        return {};
                    },
                    siteResponse: function (assetResponse, apiClient) {
                        return apiClient.get(assetResponse.getLink('site'));
                    },
                    tagCollectionResponse: function(apiClient, rootResourceResponse) {
                        return apiClient.get(rootResourceResponse.getLink('tags'), { limit: 999999 }).then(function(response) {
                            return response || apiClient.noResourceFound();
                        });
                    },
                    config: function(config, rootResourceResponse, fileCollectionResponse, siteResponse) {
                        return angular.extend(config, {
                            entitySite: siteResponse,
                            fileCreateLink: fileCollectionResponse.getLink('self'),
                            showStatutoryType: true,
                            showVisibility: true,
                            statutoryTypeAndTagsEnabled: true,
                            statutoryTypesLink: rootResourceResponse.getLink('statutorytypes')
                        });
                    }
                },
                ncyBreadcrumb: {
                    label: '{{ ::"ADD" | translate }}'
                }
            })
            .state('dashboard.user.sites.list.details.assets.manage-assets.list.details.assets-files.details.edit', {
                url: '/edit',
                parent: 'dashboard.user.sites.list.details.assets.manage-assets.list.details.assets-files.details',
                views: parseBook({
                    'elogbooks-right@dashboard.user.sites.list.details.assets.manage-assets.list.details': {
                        templateUrl: '/modules/common/files/add-edit/files-add-edit.html',
                        controller: 'CommonFilesAddEditController',
                        controllerAs: 'vm'
                    }
                }),
                resolve: {
                    siteResponse: function (assetResponse, apiClient) {
                        return apiClient.get(assetResponse.getLink('site'));
                    },
                    config: function(siteResponse, assetResponse, config) {
                        return angular.extend(config, {
                            entitySite: siteResponse,
                            associatedEntity: assetResponse,
                            fileCreateLink: assetResponse.getLink('files'),
                            showVisibility: true
                        });
                    },
                    tagCollectionResponse: function(apiClient, rootResourceResponse) {
                        return apiClient.get(rootResourceResponse.getLink('tags'), { limit: 999999 }).then(function(response) {
                            return response || apiClient.noResourceFound();
                        });
                    }
                },
                ncyBreadcrumb: {
                    label: '{{ ::"EDIT" | translate }}'
                }
            })

            .state('dashboard.user.sites.list.details.assets.manage-assets.list.details.sub-assets.details.assets-files', {
                url: '/assets-files?fileId&filePage&fileLimit&fileOrder&fileTitle&fileExpiresDateStart&fileExpiresDateEnd&fileTags&fileIsStatutory&documentType',
                parent: 'dashboard.user.sites.list.details.assets.manage-assets.list.details.sub-assets.details',
                views: parseBook({
                    'elogbooks-right@dashboard.user.sites.list.details.assets.manage-assets.list.details.sub-assets.details': {
                        templateUrl: '/modules/common/files/files-list.html',
                        controller: 'CommonFilesListController',
                        controllerAs: 'vm'
                    }
                }),
                params: {
                    fileId: null,
                    filePage: '1',
                    fileLimit: '30',
                    fileOrder: '-id',
                    fileTitle: null,
                    fileExpiresDateStart: null,
                    fileExpiresDateEnd: null,
                    fileTags: {array: true},
                    fileIsStatutory: null,
                    documentType: null
                },
                resolve: {
                    fileCollectionResponse: function (apiClient, subAssetResponse, $stateParams) {
                        var params = angular.extend({}, $stateParams,
                            {
                                id: $stateParams.fileId,
                                page: $stateParams.filePage,
                                limit: $stateParams.fileLimit,
                                order: $stateParams.fileOrder,
                                title: $stateParams.fileTitle,
                                expiresDateStart: $stateParams.fileExpiresDateStart ? new Date($stateParams.fileExpiresDateStart).toISOString() : null,
                                expiresDateEnd: $stateParams.fileExpiresDateEnd ? new Date($stateParams.fileExpiresDateEnd).toISOString() : null,
                                tags: $stateParams.fileTags,
                                isStatutory: $stateParams.fileIsStatutory,
                                documentType: $stateParams.documentType ? JSON.parse($stateParams.documentType).v : null
                            }
                        );

                        params = angular.extend({}, params, {
                            'fileTags[]': $stateParams.fileTags
                        });

                        return apiClient.get(subAssetResponse.getLink('assetsfiles'), params).then(function (response) {
                            return response || apiClient.noResourceFound();
                        });
                    },
                    tagCollectionResponse: function (apiClient, rootResourceResponse) {
                        return apiClient.get(rootResourceResponse.getLink('tags'), {limit: 999999}).then(function (response) {
                            return response || apiClient.noResourceFound();
                        });
                    },
                    config: function (fileCollectionResponse) {
                        return {
                            hideAddButton: false,
                            widerNameColumn: true,
                            hideAdvanced: true,
                            showStatutoryType: true,
                            showSite: false,
                        };
                    },
                    integrationDocTypesResponse: function () {
                        return null;
                    },
                    documentTypeCollectionResponse : function (apiClient, rootResourceResponse) {
                        return apiClient.get(rootResourceResponse.getLink('documenttypes'), {status: 'active', order: 'name'})
                            .then(function (response) {
                                return response || apiClient.noResourceFound();
                            });
                    },
                    serviceProviderResponse: function () {
                        return null;
                    },
                    operativeCollectionResponse: function () {
                        return null;
                    }
                },
                ncyBreadcrumb: {
                    label: '{{ ::"ASSET_FILES" | translate }}'
                }
            })
            .state('dashboard.user.sites.list.details.assets.manage-assets.list.details.sub-assets.details.assets-files.details', {
                url: '/details/{fileResource}',
                parent: 'dashboard.user.sites.list.details.assets.manage-assets.list.details.sub-assets.details.assets-files',
                views: parseBook({
                    'elogbooks-right@dashboard.user.sites.list.details.assets.manage-assets.list.details.sub-assets.details': {
                        templateUrl: '/modules/common/files/details/files-details.html',
                        controller: 'CommonFilesDetailsController',
                        controllerAs: 'vm'
                    }
                }),
                resolve: {
                    fileResponse: function(apiClient, $stateParams) {
                        return apiClient.get($stateParams.fileResource.decode(), {datagroup: 'details'}).then(function(response) {
                            return response || apiClient.noResourceFound();
                        });
                    },
                    tagCollectionResponse: function (apiClient, rootResourceResponse) {
                        return apiClient.get(rootResourceResponse.getLink('tags'), {limit: 999999}).then(function (response) {
                            return response || apiClient.noResourceFound();
                        });
                    },
                    config: function (config) {
                        return angular.extend(config, {
                            showVisibility: true,
                            hideEdit: false
                        });
                    }
                },
                ncyBreadcrumb: {
                    label: '{{ ::$resolves().fileResponse.title }}'
                }
        })
            .state('dashboard.user.sites.list.details.assets.manage-assets.list.details.sub-assets.details.assets-files.add', {
                url: '/add',
                parent: 'dashboard.user.sites.list.details.assets.manage-assets.list.details.sub-assets.details.assets-files',
                views: parseBook({
                    'elogbooks-right@dashboard.user.sites.list.details.assets.manage-assets.list.details.sub-assets.details': {
                        templateUrl: '/modules/common/files/add-edit/files-add-edit.html',
                        controller: 'CommonFilesAddEditController',
                        controllerAs: 'vm'
                    }
                }),
                resolve: {
                    fileResponse: function () {
                        return {};
                    },
                    siteResponse: function (assetResponse, apiClient) {
                        return apiClient.get(assetResponse.getLink('site'));
                    },
                    config: function(config, rootResourceResponse, fileCollectionResponse, siteResponse) {
                        return angular.extend(config, {
                            entitySite: siteResponse,
                            fileCreateLink: fileCollectionResponse.getLink('self'),
                            showStatutoryType: true,
                            showVisibility: true,
                            statutoryTypeAndTagsEnabled: true,
                            statutoryTypesLink: rootResourceResponse.getLink('statutorytypes')
                        });
                    }
                },
                ncyBreadcrumb: {
                    label: '{{ ::"ADD" | translate }}'
                }
        })
            .state('dashboard.user.sites.list.details.assets.manage-assets.list.details.sub-assets.details.assets-files.details.edit', {
                url: '/edit',
                parent: 'dashboard.user.sites.list.details.assets.manage-assets.list.details.sub-assets.details.assets-files.details',
                views: parseBook({
                    'elogbooks-right@dashboard.user.sites.list.details.assets.manage-assets.list.details.sub-assets.details': {
                        templateUrl: '/modules/common/files/add-edit/files-add-edit.html',
                        controller: 'CommonFilesAddEditController',
                        controllerAs: 'vm'
                    }
                }),
                resolve: {
                    siteResponse: function (assetResponse, apiClient) {
                        return apiClient.get(assetResponse.getLink('site'));
                    },
                    config: function(siteResponse, assetResponse, config) {
                        return angular.extend(config, {
                            entitySite: siteResponse,
                            associatedEntity: assetResponse,
                            fileCreateLink: assetResponse.getLink('files'),
                            showVisibility: true
                        });
                    },
                    tagCollectionResponse: function(apiClient, rootResourceResponse) {
                        return apiClient.get(rootResourceResponse.getLink('tags'), { limit: 999999 }).then(function(response) {
                            return response || apiClient.noResourceFound();
                        });
                    }
                },
                ncyBreadcrumb: {
                    label: '{{ ::"EDIT" | translate }}'
                }
            })
        ;
    }

    function registerRoutesForms($stateProvider) {
        $stateProvider
            .state('dashboard.user.sites.list.details.assets.manage-assets.list.details.forms', {
                url: '/forms',
                parent: 'dashboard.user.sites.list.details.assets.manage-assets.list.details',
                views: parseBook({
                    'elogbooks-right@dashboard.user.sites.list.details.assets.manage-assets.list.details': {
                        templateUrl: '/modules/common/form-associations/list/form-associations.html',
                        controller: 'CommonFormAssociationsController',
                        controllerAs: 'vm'
                    }
                }),
                params: {
                    formAssociationsPage: '1',
                    formAssociationsLimit: '30',
                },
                resolve: {
                    formAssociationsCollectionResponse: function ($stateParams, apiClient, assetResponse) {
                        var params = angular.extend({}, $stateParams, {
                            page: $stateParams.formAssociationsPage,
                            limit: $stateParams.formAssociationsLimit,
                            type: 'asset',
                            asset: assetResponse.id,
                        });
                        return apiClient.get(assetResponse.getLink('formassociations'), params).then(function (response) {
                            return response || apiClient.noResourceFound();
                        });
                    },
                    config: function() {
                        return {
                            type: 'asset'
                        };
                    }
                },
                ncyBreadcrumb: {
                    label: '{{ ::"FORMS" | translate }}'
                }
            })
            .state('dashboard.user.sites.list.details.assets.manage-assets.list.details.forms.add', {
                url: '/add',
                parent: 'dashboard.user.sites.list.details.assets.manage-assets.list.details.forms',
                views: parseBook({
                    'elogbooks-right@dashboard.user.sites.list.details.assets.manage-assets.list.details': {
                        templateUrl: '/modules/common/form-associations/add/form-association.add.html',
                        controller: 'FormAssociationAddController',
                        controllerAs: 'vm'
                    }
                }),
                resolve: {
                    formCollectionResponse: function (apiClient, rootResourceResponse, assetResponse) {
                        var params = {
                            type: 'asset',
                            faType: 'asset',
                            faEntityId: assetResponse.id,
                            faExclude: true,
                            active: true,
                            fvStatus: 1
                        };

                        return apiClient.get(rootResourceResponse.getLink('forms'), params).then(function (response) {
                            return response || apiClient.noResourceFound();
                        });
                    },
                    config: function(config, assetResponse) {
                        return angular.extend({}, config, {
                            entityId: assetResponse.id
                        });
                    }
                },
                ncyBreadcrumb: {
                    label: '{{ ::"ADD" | translate }}',
                }
            })
            .state('dashboard.user.sites.list.details.assets.manage-assets.list.details.forms.details', {
                url: '/{formAssociationsResource}',
                parent: 'dashboard.user.sites.list.details.assets.manage-assets.list.details.forms',
                views: parseBook({
                    'elogbooks-right@dashboard.user.sites.list.details.assets.manage-assets.list.details': {
                        templateUrl:'/modules/common/form-associations/details/details.html',
                        controller: 'CommonFormAssociationsDetailsController',
                        controllerAs: 'vm'
                    }
                }),
                resolve: {
                    formAssociationsResponse: function($stateParams, apiClient) {
                        return apiClient
                            .get($stateParams.formAssociationsResource.decode(), {})
                            .then(function(response) {
                                return response || apiClient.noResourceFound();
                            });
                    },
                    formVersionResponse: function($stateParams, apiClient, formAssociationsResponse){
                        if (formAssociationsResponse.getLink('published-version')) {
                            return apiClient.get(formAssociationsResponse.getLink('published-version'), {}).then(function (response) {
                                return response;
                            });
                        }
                        return {};
                    },
                    associatedEntities: function(assetResponse) {
                        return {
                            asset: assetResponse,
                        };
                    },
                    config: function() {
                        return {
                            mode: 'render-preview'
                        };
                    }
                },
                ncyBreadcrumb: {
                    label: '{{ ::$resolves().formAssociationsResponse.summary }}'
                }
            })
    }
})();
