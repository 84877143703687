angular.module('elogbooksServices').service('taskService', function (apiClient, messenger, modulesService) {
    var self = this;

    var assetSelected = [];
    self.selectedTab = 'edit_task';
    self.selectedServiceRoutines = [];
    self.hideForm = false;
    self.hideAssetButton = assetSelected ? true : false;
    self.noRules = false;
    self.getServiceRoutineLines = getServiceRoutineLines;
    self.clearServiceRoutineLines = clearServiceRoutineLines;
    self.loadServiceRoutines = loadServiceRoutines;

    var pointsModuleEnabled = modulesService.isEnabled('points_module');

    // loads asset related data for serviceroutine and jobgroup
    function loadServiceRoutines(assetSelect, serviceRoutineSelect, jobGroupSelect, systemServiceRoutinesCollection) {
        if (assetSelect && assetSelect.selected) {
            var assetUrl = (assetSelect.selected.href) ? assetSelect.selected.href : assetSelect.selected.object.getLink('self');
            return apiClient.get(assetUrl, {}, 1000).then(function (assetResponse) {
                if (assetResponse.getLink('serviceroutines')) {
                    // check we have SRs on the asset
                    return apiClient.get(assetResponse.getLink('serviceroutines'), { active: serviceRoutineSelect.linkParams.active, isVersioned: serviceRoutineSelect.linkParams.isVersioned }).then(function (serviceRoutineResponse) {
                        // if we do use them
                        if (serviceRoutineResponse.routines.length > 0) {
                            serviceRoutineSelect.response = serviceRoutineResponse;
                            serviceRoutineSelect.link = serviceRoutineResponse.getLink('self');
                            serviceRoutineSelect.items = [];
                            serviceRoutineSelect.selected = null;
                            serviceRoutineSelect.show = serviceRoutineResponse.routines.length > 0;
                        // else use system
                        } else if (systemServiceRoutinesCollection) {
                            serviceRoutineSelect.response = systemServiceRoutinesCollection;
                            serviceRoutineSelect.link = systemServiceRoutinesCollection.getLink('self');
                            serviceRoutineSelect.items = [];
                            serviceRoutineSelect.selected = null;
                            serviceRoutineSelect.show = systemServiceRoutinesCollection.routines.length > 0;
                        }
                    });
                }
                if (assetResponse.getLink('jobgroup')) {
                    return apiClient.get(assetResponse.getLink('jobgroup')).then(function (jobGroupResponse) {
                        jobGroupSelect.selected = {
                            title: jobGroupResponse.name,
                            href: jobGroupResponse.getLink('self'),
                            value: jobGroupResponse.id,
                            object: jobGroupResponse
                        };
                    });
                }
            });
        } else {
            //  use system's SR
            return new Promise(resolve => {
                serviceRoutineSelect.response = systemServiceRoutinesCollection;
                serviceRoutineSelect.link = systemServiceRoutinesCollection.getLink('self');
                serviceRoutineSelect.items = [];
                serviceRoutineSelect.selected = null;
                serviceRoutineSelect.show = systemServiceRoutinesCollection.routines.length > 0;
                resolve();
            });
        }
    }

    // updates the rules/frequencies on task from serviceroutines
    function getServiceRoutineLines(serviceRoutineSelect, forceServiceRoutine, task) {
        if (!serviceRoutineSelect.selected) {
            return;
        }
        task.serviceRoutineLinesLoading = true;
        apiClient.get(serviceRoutineSelect.selected.href).then(function (response) {
            if (!response || !response._links.lines) {
                task.serviceRoutineLinesLoading = false;
                return;
            }

            apiClient.get(response._links.lines.href).then(function (response) {
                task.serviceRoutineLinesLoading = false;
                if (!response || response.serviceRoutineLines.length === 0) {
                    clearServiceRoutineLines(forceServiceRoutine, task);
                    return;
                }

                clearServiceRoutineLines(forceServiceRoutine, task);
                forceServiceRoutine = true;

                angular.forEach(response.serviceRoutineLines, function (line) {
                    if (line.frequency === 'DAILY') {
                        serviceRoutineSelect.selected = null;
                        messenger.error('FREQUENCY_CANT_BE_DAILY');
                        return;
                    }

                    if (line.minutes && pointsModuleEnabled) {
                        line.hours = Math.floor(line.minutes / 60);
                        line.minutes = line.minutes % 60;
                    }

                    var rule = {
                        serviceRoutineLine: line.id,
                        description: line.name,
                        interval: line.interval,
                        _links: {}
                    };

                    if (pointsModuleEnabled) {
                        rule.points = line.points;
                        rule.hours = line.hours;
                        rule.minutes = line.minutes;
                    }

                    if (line.getLink('statutorytype')) {
                        rule._links.statutorytype = line._links.statutorytype;
                    }

                    switch (line.frequency) {
                        case 'WEEKLY':
                            rule.frequency = 4;
                            rule.byDay = [[0, 5]];
                            break;
                        case 'MONTHLY':
                            rule.frequency = 5;
                            rule.byDay = [[-1,1], [-1,2], [-1,3], [-1,4], [-1,5]];
                            rule.bySetPosition = [-1];
                            break;
                        case 'YEARLY':
                            rule.frequency = 6;
                            rule.byDay = [[-1,1], [-1,2], [-1,3], [-1,4], [-1,5]];
                            rule.bySetPosition = [-1];
                            break;
                    }

                    if (line.byMonth) {
                        rule.byMonth = line.byMonth;
                    }

                    task.rules.push(rule);
                });
            });
        });
    }

    function clearServiceRoutineLines(forceServiceRoutine, task) {
        if (forceServiceRoutine) {
            forceServiceRoutine = false;
        }

        for (var i = task.rules.length - 1; i >= 0; i--) {
            if (task.rules[i].serviceRoutineLine !== undefined) {
                task.rules.splice(i, 1);
            }
        }
    }
});
