(function () {
    'use strict';

    angular
        .module('elogbooks.user.finance')
        .config(registerRoutes)
        .config(registerRoutesList)
        .config(registerRoutesDetails)
        .config(registerPurchaseInvoiceRouteItemsTab)
        .config(registerRoutesAdd)
        .config(registerRoutesEdit)
        .config(registerWorkflow)
        .config(registerPurchaseInvoiceRouteFilesTab);

    function registerRoutes($stateProvider) {
        $stateProvider
            .state('dashboard.user.finance.purchase-invoices', {
                abstract: true,
                parent: 'dashboard.user.finance',
                templateUrl: 'shared-templates/view.html',
                ncyBreadcrumb: {
                    skip: true,
                }
            });
    }

    function registerRoutesList($stateProvider) {
        var purchaseInvoiceParams = {
            id: null,
            invoiceDateRange: null,
            supplier: null,
            status: {array: true},
            purchaseInvoicePage: '1',
            purchaseInvoiceLimit: '30',
            purchaseInvoiceOrder: 'id'
        };

        $stateProvider
            .state('dashboard.user.finance.purchase-invoices.list', {
                url: '/purchase-invoices?' + Object.keys(purchaseInvoiceParams).join('&'),
                parent: 'dashboard.user.finance.purchase-invoices',
                resolve: {
                    purchaseInvoicesCollectionResponse: function (apiClient, rootResourceResponse, $stateParams) {
                        var params = angular.copy($stateParams);
                        params = angular.extend({}, params, {
                            page:   $stateParams.purchaseInvoicePage,
                            order:  $stateParams.purchaseInvoiceOrder,
                            limit:  $stateParams.purchaseInvoiceLimit,
                            'status[]': $stateParams.status,
                            type: 'purchase'
                        });

                        if (params.supplier) {
                            params.supplier = JSON.parse(params.supplier).v;
                        }
                        return apiClient.get(rootResourceResponse.getLink('invoices'), params).then( function(response) {
                            return response || apiClient.noResourceFound();
                        });
                    },
                    suppliersCollectionResponse: function (apiClient, rootResourceResponse) {
                        return apiClient.get(rootResourceResponse.getLink('suppliers'), {
                            order: 'name'
                        }).then(function(response) {
                            return response || apiClient.noResourceFound();
                        });
                    },
                    purchaseOrdersCollectionResponse: function (apiClient, rootResourceResponse) {
                        return apiClient.get(rootResourceResponse.getLink('purchaseorders')).then(function(response) {
                            return response || apiClient.noResourceFound();
                        })
                    }
                },
                params: purchaseInvoiceParams,
                views: {
                    '@dashboard.user.finance.purchase-invoices': {
                        templateUrl: '/modules/user/finance/purchase-invoices/purchase-invoices-list.html',
                        controller: 'FinancePurchaseInvoicesController',
                        controllerAs: 'vm'
                    }
                },
                ncyBreadcrumb: {
                    label: '{{ ::"PURCHASE_INVOICES_LIST" | translate }}'
                }
            });
    }

    function registerRoutesDetails($stateProvider) {
        $stateProvider
            .state('dashboard.user.finance.purchase-invoices.list.details', {
                url: '/details/{purchaseInvoicesResource}',
                abstract: true,
                parent: 'dashboard.user.finance.purchase-invoices.list',
                views: {
                    // Main frame
                    '@dashboard.user.finance.purchase-invoices': {
                        templateUrl: '/modules/user/finance/purchase-invoices/details/purchase-invoices-details.html',
                        controller: 'FinancePurchaseInvoicesDetailsController',
                        controllerAs: 'vm'
                    },

                    // Details - left part
                    'details.info@dashboard.user.finance.purchase-invoices.list.details': {
                        templateUrl: '/modules/user/finance/purchase-invoices/details/details/purchase-invoices-details-info.html',
                        controller: 'FinancePurchaseInvoicesInfoDetailsController',
                        controllerAs: 'vm'
                    },

                    // Status bar
                    'status@dashboard.user.finance.purchase-invoices.list.details': {
                        templateUrl: '/modules/user/finance/purchase-invoices/details/status/purchase-invoices-status.html',
                        controller: 'FinancePurchaseInvoicesStatusController',
                        controllerAs: 'vm'
                    },

                    // Audit Trail
                    'feed@dashboard.user.finance.purchase-invoices.list.details': {
                        templateUrl: '/modules/common/finance/invoice/details/event/feed.html',
                        controller: 'FinanceInvoiceEventFeedController',
                        controllerAs: 'vm'
                    },

                    //Work Flow
                    'workflow@dashboard.user.finance.purchase-invoices.list.details': {
                        templateUrl: '/modules/common/finance/invoice/details/workflow/workflow.html',
                        controller: 'FinanceInvoiceWorkflowController',
                        controllerAs: 'vm'
                    }
                },
                resolve: {
                    purchaseInvoiceResponse: function(apiClient, $stateParams) {
                        return apiClient.get($stateParams.purchaseInvoicesResource.decode()).then( function(response) {
                            return response || apiClient.noResourceFound();
                        });
                    },
                    invoiceResponse: function (purchaseInvoiceResponse) {
                        return purchaseInvoiceResponse
                    },
                    supplierResponse: function (apiClient, purchaseInvoiceResponse) {
                        return apiClient.get(purchaseInvoiceResponse.getLink('supplier')).then( function(response) {
                            return response || apiClient.noResourceFound();
                        });
                    },
                    eventCollectionResponse: function(apiClient, purchaseInvoiceResponse) {
                        return apiClient.get(purchaseInvoiceResponse.getLink('events'), { datagroup: 'details' }).then(function(response) {
                            return response || apiClient.noResourceFound();
                        })
                    },
                    messageCollectionResponse: function(apiClient, purchaseInvoiceResponse) {
                        return apiClient.get(purchaseInvoiceResponse.getLink('messages'), { datagroup: 'details' }).then(function(response) {
                            return response || apiClient.noResourceFound();
                        });
                    },
                    config: function() {
                        return {
                            type: 'PURCHASE_INVOICE'
                        };
                    }
                },
                ncyBreadcrumb: {
                    label: '{{ ::$resolves().purchaseInvoiceResponse.id }}'
                }
            });
    }

    function registerPurchaseInvoiceRouteItemsTab($stateProvider) {
        $stateProvider
            .state('dashboard.user.finance.purchase-invoices.list.details.items', {
                url: '/items',
                parent: 'dashboard.user.finance.purchase-invoices.list.details',
                views: parseBook({
                    'items@dashboard.user.finance.purchase-invoices.list.details': {
                        templateUrl: '/modules/common/finance/item/items-list.html',
                        controller: 'CommonFinanceItemListController',
                        controllerAs: 'vm'
                    }
                }),
                resolve: {
                    itemLinesCollectionResponse: function(apiClient, purchaseInvoiceResponse) {
                        if (purchaseInvoiceResponse.getLink('invoice-lines')) {
                            return apiClient.get(purchaseInvoiceResponse.getLink('invoice-lines'), {type: 'purchase'}).then(function(response) {
                                return response || apiClient.noResourceFound();
                            })
                        }
                    },
                    itemResponse: function (apiClient, purchaseInvoiceResponse) {
                        return purchaseInvoiceResponse || apiClient.noResourceFound();
                    },
                    vatRateCollectionResponse: function (apiClient, rootResourceResponse) {
                        if (rootResourceResponse.getLink('vat-rates')) {
                            return apiClient.get(rootResourceResponse.getLink('vat-rates'), {
                                active: true,
                                limit: 999999
                            });
                        }
                    },
                },
                ncyBreadcrumb: {
                    label: '{{ ::"ITEMS" | translate }}'
                }
            })
            .state('dashboard.user.finance.purchase-invoices.list.details.items.add', {
                url: '/add',
                parent: 'dashboard.user.finance.purchase-invoices.list.details.items',
                views: parseBook({
                    'items@dashboard.user.finance.purchase-invoices.list.details': {
                        templateUrl: '/modules/common/finance/item/add-edit/items-add-edit.html',
                        controller: 'CommonFinanceItemListAddEditController',
                        controllerAs: 'vm'
                    }
                }),
                ncyBreadcrumb: {
                    label: '{{ ::"EDIT" | translate }}'
                }
            });
    }

    function registerRoutesAdd($stateProvider) {
        $stateProvider
            .state('dashboard.user.finance.purchase-invoices.list.add', {
                url: '/add',
                parent: 'dashboard.user.finance.purchase-invoices.list',
                views: parseBook({
                    'elogbooks-left@dashboard.user.finance': {
                        templateUrl: '/modules/common/finance/purchase-invoices/add-edit/add-edit.form.html',
                        controller: 'PurchaseInvoicesAddEditController',
                        controllerAs: 'vm'
                    }
                }),
                resolve: {
                    purchaseOrderResponse: function() {
                        return {};
                    },
                    purchaseInvoiceResponse: function() {
                        return {};
                    },
                    suppliersCollectionResponse: function (apiClient, rootResourceResponse) {
                        return apiClient.get(rootResourceResponse.getLink('suppliers'), {
                            active: true,
                            order: 'name'
                        }).then(function(response) {
                            return response || apiClient.noResourceFound();
                        });
                    },
                },
                ncyBreadcrumb: {
                    label: '{{ ::"ADD" | translate }}'
                }
            });
    }

    function registerRoutesEdit($stateProvider) {
        $stateProvider
            .state('dashboard.user.finance.purchase-invoices.list.details.edit', {
                url: '/edit/{purchaseInvoicesResource}',
                parent: 'dashboard.user.finance.purchase-invoices.list.details',
                views: parseBook({
                    'elogbooks-left@dashboard.user.finance': {
                        templateUrl: '/modules/common/finance/purchase-invoices/add-edit/add-edit.form.html',
                        controller: 'PurchaseInvoicesAddEditController',
                        controllerAs: 'vm'
                    }
                }),
                resolve: {
                    purchaseOrderResponse: function() {
                        return {};
                    },
                    suppliersCollectionResponse: function (apiClient, rootResourceResponse) {
                        return apiClient.get(rootResourceResponse.getLink('suppliers'), {
                            active: true,
                            order: 'name'
                        }).then(function(response) {
                            return response || apiClient.noResourceFound();
                        });
                    },
                },
                ncyBreadcrumb: {
                    label: '{{ ::"EDIT" | translate }}'
                }
            });
    }

    function registerWorkflow($stateProvider)
    {
        var workflowRoute = 'dashboard.user.finance.purchase-invoices.list.details.workflow';

        $stateProvider
            .state(workflowRoute, {
                url: '/workflow',
                abstract: true,
                parent: 'dashboard.user.finance.purchase-invoices.list.details',
                views: {
                    '@dashboard.user.finance': {
                        templateUrl: '/modules/common/finance/invoice/details/workflow/workflow-add-note/workflow-add-note.html',
                        controller: 'FinanceInvoiceWorkflowAddNoteController',
                        controllerAs: 'vm',
                    },
                },
                resolve: {
                    invoiceResponse : function(purchaseInvoiceResponse) {
                        return purchaseInvoiceResponse;
                    }
                },
                ncyBreadcrumb: {
                    label: '{{ ::"WORKFLOW" | translate }}'
                }
            });

        // Register workflows routing actions
        ['mark-as-paid'].map(function(type) {
            $stateProvider
                .state(workflowRoute + '.' + type, {
                    url: '/' + type,
                    abstract: false,
                    parent: workflowRoute,
                    ncyBreadcrumb: {
                        label: '{{ ::"' + type.replaceAll('-', '_') + '" | uppercase | translate }}'
                    }
                });
        });
    }

    function registerPurchaseInvoiceRouteFilesTab($stateProvider) {
        $stateProvider
            .state('dashboard.user.finance.purchase-invoices.list.details.files', {
                url: '/files',
                parent: 'dashboard.user.finance.purchase-invoices.list.details',
                views: parseBook({
                    'items@dashboard.user.finance.purchase-invoices.list.details': {
                        templateUrl: '/modules/common/files/files-list.html',
                        controller: 'CommonFilesListController',
                        controllerAs: 'vm'
                    }
                }),
                params: {
                    fileId: null,
                    filePage: '1',
                    fileLimit: '30',
                    fileOrder: '-id',
                    fileSelectedSiteResource: null,
                    fileTitle: null,
                    fileUploadedBy: null,
                    fileUploadedDateStart: null,
                    fileUploadedDateEnd: null,
                    fileExpiresDateStart: null,
                    fileExpiresDateEnd: null,
                    fileTags: {array: true},
                    fileIsStatutory: null,
                    documentType: null
                },
                resolve: {
                    fileCollectionResponse: function (apiClient, purchaseInvoiceResponse, $stateParams) {
                        var params = angular.extend({}, $stateParams,
                            {
                                id: $stateParams.fileId,
                                page: $stateParams.filePage,
                                limit: $stateParams.fileLimit,
                                order: $stateParams.fileOrder,
                                title: $stateParams.fileTitle,
                                expiresDateStart: $stateParams.fileExpiresDateStart ? new Date($stateParams.fileExpiresDateStart).toISOString() : null,
                                expiresDateEnd: $stateParams.fileExpiresDateEnd ? new Date($stateParams.fileExpiresDateEnd).toISOString() : null,
                                tags: $stateParams.fileTags,
                                isStatutory: $stateParams.fileIsStatutory,
                                documentType: $stateParams.documentType ? JSON.parse($stateParams.documentType).v : null
                            }
                        );

                        params = angular.extend({}, params, {
                            'fileTags[]': $stateParams.fileTags
                        });

                        return apiClient.get(purchaseInvoiceResponse.getLink('files'), params).then(function (response) {
                            return response || apiClient.noResourceFound();
                        });
                    },
                    tagCollectionResponse: function (apiClient, rootResourceResponse) {
                        return apiClient.get(rootResourceResponse.getLink('tags'), {limit: 999999}).then(function (response) {
                            return response || apiClient.noResourceFound();
                        });
                    },
                    config: function (fileCollectionResponse) {
                        return {
                            hideAddButton: false,
                            hideAdvanced: true,
                            showSite: false,
                        };
                    },
                    integrationDocTypesResponse: function () {
                        return null;
                    },
                    jobResponse: function () {
                        return null;
                    },
                    documentTypeCollectionResponse : function (apiClient, rootResourceResponse) {
                        return apiClient.get(rootResourceResponse.getLink('documenttypes'), {status: 'active', order: 'name'})
                            .then(function (response) {
                                return response || apiClient.noResourceFound();
                            });
                    },
                    serviceProviderResponse: function () {
                        return null;
                    },
                    operativeCollectionResponse: function () {
                        return null;
                    }
                },
                ncyBreadcrumb: {
                    label: '{{ ::"FILES" | translate }}'
                }
            })
            .state('dashboard.user.finance.purchase-invoices.list.details.files.details', {
                url: '/details/{fileResource}',
                parent: 'dashboard.user.finance.purchase-invoices.list.details.files',
                views: parseBook({
                    'files@dashboard.user.finance.purchase-invoices.list.details': {
                        templateUrl: '/modules/common/files/details/files-details.html',
                        controller: 'CommonFilesDetailsController',
                        controllerAs: 'vm'
                    }
                }),
                resolve: {
                    fileResponse: function(apiClient, $stateParams) {
                        return apiClient.get($stateParams.fileResource.decode(), {datagroup: 'details'}).then(function(response) {
                            return response || apiClient.noResourceFound();
                        });
                    },
                    tagCollectionResponse: function (apiClient, rootResourceResponse) {
                        return apiClient.get(rootResourceResponse.getLink('tags'), {limit: 999999}).then(function (response) {
                            return response || apiClient.noResourceFound();
                        });
                    },
                    config: function (config) {
                        return angular.extend(config, {
                            showVisibility: true,
                            hideEdit: false
                        });
                    }
                },
                ncyBreadcrumb: {
                    label: '{{ ::$resolves().fileResponse.title }}'
                }
            })
            .state('dashboard.user.finance.purchase-invoices.list.details.files.add', {
                url: '/add',
                parent: 'dashboard.user.finance.purchase-invoices.list.details.files',
                views: parseBook({
                    'files@dashboard.user.finance.purchase-invoices.list.details': {
                        templateUrl: '/modules/common/files/add-edit/files-add-edit.html',
                        controller: 'CommonFilesAddEditController',
                        controllerAs: 'vm'
                    }
                }),
                resolve: {
                    fileResponse: function () {
                        return {};
                    },
                    siteResponse: function () {
                        return null;
                    },
                    tagCollectionResponse: function(apiClient, rootResourceResponse) {
                        return apiClient.get(rootResourceResponse.getLink('tags'), { limit: 999999 }).then(function(response) {
                            return response || apiClient.noResourceFound();
                        });
                    },
                    config: function(config, rootResourceResponse, fileCollectionResponse) {
                        return angular.extend(config, {
                            fileCreateLink: fileCollectionResponse.getLink('self'),
                            showStatutoryType: false,
                            showVisibility: true,
                            statutoryTypeAndTagsEnabled: false,
                            showSite: false
                        });
                    }
                },
                ncyBreadcrumb: {
                    label: '{{ ::"ADD" | translate }}'
                }
            })
            .state('dashboard.user.finance.purchase-invoices.list.details.files.details.edit', {
                url: '/edit',
                parent: 'dashboard.user.finance.purchase-invoices.list.details.files.details',
                views: parseBook({
                    'files@dashboard.user.finance.purchase-invoices.list.details': {
                        templateUrl: '/modules/common/files/add-edit/files-add-edit.html',
                        controller: 'CommonFilesAddEditController',
                        controllerAs: 'vm'
                    }
                }),
                resolve: {
                    config: function(purchaseInvoiceResponse, config) {
                        return angular.extend(config, {
                            associatedEntity: purchaseInvoiceResponse,
                            fileCreateLink: purchaseInvoiceResponse.getLink('files'),
                            showVisibility: true
                        });
                    },
                    tagCollectionResponse: function(apiClient, rootResourceResponse) {
                        return apiClient.get(rootResourceResponse.getLink('tags'), { limit: 999999 }).then(function(response) {
                            return response || apiClient.noResourceFound();
                        });
                    }
                },
                ncyBreadcrumb: {
                    label: '{{ ::"EDIT" | translate }}'
                }
            });
    }

})();
