(function () {
    'use strict';

    angular
        .module('elogbooks.common.files')
        .controller('OSPFilesIntegrationDetailsController', OSPFilesIntegrationDetailsController);

    OSPFilesIntegrationDetailsController.$inject = [
        '$scope',
        '$uibModalInstance',
        'requestDataFactory',
        'apiClient',
        '$translate',
        'messenger',
        'actionResponse',
        'file',
        'occupierResponse'
    ];

    function OSPFilesIntegrationDetailsController (
        $scope,
        $uibModalInstance,
        requestDataFactory,
        apiClient,
        $translate,
        messenger,
        actionResponse,
        file,
        occupierResponse
    ) {
        var vm = this;
        vm.occupierResponse = occupierResponse;
        vm.actionResponse = actionResponse;
        vm.selectList = null;
        vm.staffVisibility = vm.editableBy = 'staff';
        vm.occupierDocuments = [];
        vm.data = angular.extend({}, requestDataFactory.createRequest(), {
            integration: {'osp': null},
            updateDocument: false,
            documentId: null,
            title: file.title,
            expiresAt: file.expiresAt,
            visibility: 'staff',
            allUserOccupiers: null,
            occupiers: [],
            _links: {
                site: {
                    title: file.getLinkAttribute('site', 'title'),
                    href: file.getLink('site')
                }
            }
        });
        vm.searchQuery = {
            page: 1,
            limit: 30
        }

        vm.create = createAction;
        vm.cancel = cancelAction;
        vm.checkViewTickBox = checkViewTickBox;
        vm.getOccupierDocuments = getOccupierDocuments;
        vm.selectDocument = selectDocumentAction;
        vm.clearFilters = clearFiltersAction;

        $scope.$watch('vm.data.updateDocument', function(newValue, oldValue) {
            var submitButton =  $("button[type=submit]").first();

            if (newValue) {
                submitButton.text($translate.instant('UPDATE'));
            } else {
                submitButton.text($translate.instant('SEND'));
            }
        });

        function createAction () {
            if (vm.selectList === 'list' && vm.data.occupiers.length === 0 && !vm.data.updateDocument) {
                //have to select at least one occupier
                messenger.error("OSP_NO_OCCUPIERS_SELECTED");
                return;
            }
            if (vm.data.updateDocument && !vm.data.documentId) {
                //have to select the document to update
                messenger.error("OSP_DOCUMENT_NOT_SELECTED");
                return;
            }

            if (vm.selectList === 'list' || vm.data.allUserOccupiers) {
                if (vm.editableBy === 'staffAndOccupiers') {
                    vm.data.visibility = 'occupier_all';
                }
                if (vm.editableBy === 'staff') {
                    vm.data.visibility = 'occupier_staff';
                }
            }

            if (vm.data.documentId) {
                vm.data.documentId = vm.data.documentId.id;
            }

            apiClient.create(file.getLink('integrate'), vm.data).then(function (response) {
                if (response) {
                    messenger.success('OSP_FILE_ADDED_TO_QUEUE');
                    $uibModalInstance.close();
                } else {
                    messenger.error('REQUEST_ERROR');
                }
            });
        }

        function cancelAction() {
            $uibModalInstance.dismiss('cancel');
        }

        function checkViewTickBox(selected) {
            switch (selected) {
                case 'staff':
                    vm.data.visibility = 'staff';
                    vm.staffVisibility = 'staff';
                    vm.data.allUserOccupiers = null;
                    vm.selectList = null;
                    break;

                case 'list':
                    vm.selectList = 'list';
                    vm.staffVisibility = null;
                    vm.data.allUserOccupiers = null;
                    break;

                case 'everyone':
                    vm.data.allUserOccupiers = 'occupiers';
                    vm.selectList = null;
                    vm.staffVisibility = null;
                    break;
            }
        }

        function getOccupierDocuments () {
            vm.searchLoading = true;
            vm.occupierDocuments = [];
            vm.data.documentId = null;

            vm.searchQuery.title = vm.documentTitle;
            vm.searchQuery.occupier = vm.occupier;
            vm.searchQuery.uploadedBy = vm.uploadedBy;
            vm.searchQuery.expiresBefore = vm.expiresBefore;
            vm.searchQuery.expiresAfter = vm.expiresAfter;

            apiClient.get(actionResponse.getLink('osp-documents'), vm.searchQuery).then(function(response) {
                if (response) {
                    vm.searchLoading = false;

                    if (response.documents.length === 0) {
                        return messenger.error("OSP_DOCUMENTS_NOT_FOUND");
                    }

                    vm.occupierDocuments = response.documents
                }
            });
        }

        function selectDocumentAction(document) {
            if (document && document.expiresAt) {
                vm.data.expiresAt = new Date(document.expiresAt);
            } else {
                vm.data.expiresAt = null;
            }
        }

        function clearFiltersAction() {
            vm.documentTitle = vm.occupier = vm.uploadedBy = vm.expiresBefore = vm.expiresAfter = null;
            vm.occupierDocuments = [];
        }
    }
})();