angular.module('elogbooksDirectives').directive('siteMembershipRole', ['apiClient', function(apiClient){
    return {
        // Restrict to attributes only.
        restrict: 'A',
        require: ['ngModel'],
        scope: {
            role: '=ngModel',
            required: '=ngRequired'
        },
        templateUrl: '/modules/directives/form-elements/site-membership-role/site-membership-role.html',
        link: function (scope) {
            apiClient.get('/sitemembershiproles').then(function (response) {
                scope.roles = response.getData().roles;
            });
        }
    }
}]);
