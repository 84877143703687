(function () {
    'use strict';

    angular
        .module('elogbooksControllers')
        .controller('BulkAlarmUpdateController', BulkAlarmUpdateController);

    BulkAlarmUpdateController.$inject = [
        'itemsCollection',
        'itemsCount',
        '$uibModalInstance',
        'apiClient',
        'lodash',
        'messenger'
    ];

    function BulkAlarmUpdateController(
        itemsCollection,
        itemsCount,
        $uibModalInstance,
        apiClient,
        lodash,
        messenger
    ) {
        var vm = this;
        vm.minDate = new Date();
        vm.datePickerOptions = {
            showWeeks: false,
            minDate: vm.minDate
        };

        vm.itemsCollection = itemsCollection;
        vm.itemsCount = itemsCount;
        vm.alarmType = vm.itemsCollection.quotes ? 'quote' : 'job';

        vm.titleMessage = 'BULK_MOVE_ALARMS',
        vm.updateMessage = 'ALARMS_QUEUED_FOR_MOVING';

        vm.data = {
            note: null,
            _links: []
        };
        vm.showLoader = false;
        vm.items = null;

        vm.close = closeModal;
        vm.submit = submitAction;

        setItems();

        function closeModal() {
            $uibModalInstance.dismiss();
        }

        function closeModalAndDisplayNotification(response) {
            vm.showLoader = false;

            if (response) {
                messenger.success(vm.updateMessage);
                $uibModalInstance.close();
            }
        }

        function setItems() {
            switch (vm.alarmType) {
                case 'quote':
                    vm.items = lodash.filter(vm.itemsCollection.quotes, {'selected': true});
                    break;
                case 'job':
                    vm.items = lodash.filter(vm.itemsCollection.jobs, {'selected': true});
                    break;
            }
        }

        function submitAction() {
            vm.showLoader = true;

            switch (vm.alarmType) {
                case 'job':
                    vm.data.jobs = {};
                    vm.data.jobs.jobs = [];
                    lodash.forEach(vm.items, function (item) {
                        vm.data.jobs.jobs.push({
                            href: item.getLink('self')
                        });
                    });

                    return apiClient.update(vm.itemsCollection.getLink('move-job-alarms'), vm.data).then(function (response) {
                        closeModalAndDisplayNotification(response);
                    });
                case 'quote':
                    vm.data.quotes = {};
                    vm.data.quotes.quotes = [];
                    lodash.forEach(vm.items, function (item) {
                        vm.data.quotes.quotes.push({
                            href: item.getLink('self')
                        });
                    });

                    return apiClient.update(vm.itemsCollection.getLink('move-quote-alarms'), vm.data).then(function (response) {
                        closeModalAndDisplayNotification(response);
                    });
            }
        }
    }
})();
