(function () {
    'use strict';

    angular
        .module('elogbooksFilters')
        .filter('fullPath', FullPath);

    function FullPath($translate) {
        return function(input, translateInput) {
            if (!input && translateInput) {
                return $translate.instant('NOT_APPLICABLE');
            }

            if (input) {
                return JSON.parse(input)
                           .map(function(stringPart) {return stringPart.name;})
                           .join(' / ');
            }
        };
    }
})();
