;(function() {
    'use strict';

    angular
        .module('elogbooks.admin.forms')
        .controller('FormDetailsVersionController', FormDetailsVersionController);

    FormDetailsVersionController.$inject = ['messenger', 'apiClient', '$state', '$stateParams', 'lodash', 'formResponse', 'formVersionCollectionResponse'];

    function FormDetailsVersionController(messenger, apiClient, $state, $stateParams, lodash, formResponse, formVersionCollectionResponse) {
        var vm = this;
        vm.formResponse = formResponse;
        vm.formVersionCollectionResponse = formVersionCollectionResponse;
        vm.selectedVersionId = $stateParams.selectedVersion || 1;
        vm.selectedVersion = lodash.find(formVersionCollectionResponse.formVersions, {version: parseInt(vm.selectedVersionId, 10)});

        vm.displayVersion = DisplayVersion;
        vm.publishVersion  = PublishVersion;

        function DisplayVersion() {
            $state.go('.', {selectedVersion: vm.selectedVersion.version}, {reload: $state.current.name});
        }

        function PublishVersion() {
            vm.data = {};
            return apiClient.create(vm.selectedVersion.getLink('publish-version'), vm.data).then(function (response) {
                if (response) {
                    return $state.go('.', {selectedVersion: vm.selectedVersion.version}, {reload: $state.current.name});
                }

                messenger.error('REQUEST_ERROR');
            });
        }
    }
})();
