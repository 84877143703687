(function () {
    'use strict';

    angular
        .module('elogbooks.user.reports')
        .controller('UserReportsAgeOfApprovalDetailsController', UserReportsAgeOfApprovalDetailsController);

    UserReportsAgeOfApprovalDetailsController.$inject = ['$filter', '$state', '$stateParams', 'regions', 'siteAssociatesCollectionFMResponse', 'siteAssociatesCollectionRFMResponse', 'priorityCollectionResponse', 'siteAssociateAlias', 'siteAssociatesCollection', '$uibModal', 'moment', '$scope', 'lodash', 'paramConverterService', '$translate'];

    function UserReportsAgeOfApprovalDetailsController($filter, $state, $stateParams, regions, siteAssociatesCollectionFMResponse, siteAssociatesCollectionRFMResponse, priorityCollectionResponse, siteAssociateAlias, siteAssociatesCollection, $uibModal, moment, $scope, lodash, paramConverterService, $translate) {
        var vm = this;
        vm.siteAssociatesResponse = siteAssociatesCollection;
        vm.approvals = siteAssociatesCollection.approvals;
        vm.siteAssociateAlias = siteAssociateAlias;
        vm.stateParams = $stateParams;

        vm.search = search;
        vm.order = orderAction;
        vm.getAllPriorites = getAllPriorities;
        vm.checkPriority = checkPriority;

        vm.priorities = getAllPriorities(vm.approvals);

        var fmOptions = siteAssociatesCollectionFMResponse.associates.map(function (associate) {
            return {
                title: associate.getLinkAttribute('sitemembership', 'title'),
                value: associate._links.user.id
            };
        });

        var rfmOptions = siteAssociatesCollectionRFMResponse.associates.map(function (associate) {
            return {
                title: associate.getLinkAttribute('sitemembership', 'title'),
                value: associate._links.user.id
            };
        });

        var regionOptions = regions.regions.map(function (region) {
            return {
                title: region.name,
                value: region.id
            };
        });

        fmOptions.unshift({
            title: $translate.instant('NONE_SELECTED'),
            value: null
        });

        rfmOptions.unshift({
            title: $translate.instant('NONE_SELECTED'),
            value: null
        });

        regionOptions.unshift({
            title: $translate.instant('NONE_SELECTED'),
            value: null
        });

        vm.criteria = {
            siteAssociateId: {
                type: 'options',
                title: siteAssociateAlias,
                value: parseInt($stateParams.siteAssociateId) || null,
                options: fmOptions
            },
            rfmId: {
                type: 'options',
                title: 'RFM',
                value: parseInt($stateParams.rfmId) || null,
                options: rfmOptions
            },
            siteRegion: {
                type: 'options',
                title: 'REGION',
                value: parseInt($stateParams.siteRegion) || null,
                options: regionOptions
            },
            priorities: {
                type: 'options',
                multiple: true,
                title: 'PRIORITIES',
                value: paramConverterService.checkStateParams($stateParams.priorities),
                options: paramConverterService.formatResponse(priorityCollectionResponse.approvalAgePriorities)
            },
            dateRange: {
                type: 'date-range',
                report: true, // pass this to set end-date back one day for reporting purposes
                title: 'BETWEEN_DATES',
                value: {
                    startDate: ($stateParams.dateRange !== null) ? moment(new Date($stateParams.dateRange.split(',')[0])) : null,
                    endDate: ($stateParams.dateRange !== null) ? moment(new Date($stateParams.dateRange.split(',')[1])) : null
                },
                clearValue: {
                    startDate: null,
                    endDate: null
                }
            }
        };

        function getAllPriorities($data) {
            var priorities = [];
            lodash.forEach($data, function(stats) {
                if (stats.data && stats.data[0].length > 0) {
                    lodash.forEach(stats.data[0], function(priority) {
                        if (!lodash.find(priorities, {id: priority.priorityId})) {
                            if (priority.prioritySp) {
                                priorities.push({id: priority.priorityId, name: priority.priorityName, sp: priority.prioritySp});
                            } else {
                                priorities.push({id: priority.priorityId, name: priority.priorityName});
                            }
                        }
                    });
                }
            });

            return lodash.sortBy(priorities, 'id');
        }

        function checkPriority($priority, $user) {
            var result = null;

            if (lodash.has($user, [0])) {
                lodash.forEach($user[0], function(userPriority) {
                    // check if user has the priority
                    if (userPriority.priorityId === $priority.id) {
                        result = moment.duration(parseInt(userPriority.approvalTime), "minutes").format("d[d] h[h] m[m]") + ' (' + userPriority.jobCount + ')';

                        return true;
                    }
                });
            }

            return result;
        }

        function search(params) {
            var override = {
                page: 1
            };

            $state.go('.', angular.extend({}, params, override), { reload: $state.current });
        }

        function orderAction (key) {
            $stateParams.order = key;
            $state.go('.', angular.extend({}, $stateParams), { reload: $state.current });
        }

    }
})();


