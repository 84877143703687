/**
 * Add trade type controller
 */
(function () {
    'use strict';

    angular
        .module('elogbooks.admin.trade-types')
        .controller('TradeTypesAddController', TradeTypesAddController);

    TradeTypesAddController.$inject = ['$scope', 'apiClient', 'messenger', 'rootResourceResponse'];

    function TradeTypesAddController($scope, apiClient, messenger, rootResourceResponse) {

        var vm = this;
        vm.tradetype = {name: ''};

        vm.submit = function () {
            apiClient.get(rootResourceResponse.getLink('tradetypes')).then(function (response) {

                var createLink = response.getData()._links.create.href;

                apiClient.create(createLink, vm.tradetype).then(function (response) {
                    if (response) {
                        $scope.$state.go('^', {}, { reload: true }).then(function () {
                            messenger.success('Added successfully');
                        });
                    } else {
                        messenger.error('Unable to process your request. Please try again later');
                    }
                });

            });
        };
    };

})();

