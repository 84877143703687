(function () {
    'use strict';

    angular
        .module('elogbooksServices')
        .controller('saveFilterModalController', saveFilterModalController);

    saveFilterModalController.$inject = ['$state', 'apiClient', 'user', 'messenger', '$uibModalInstance'];

    function saveFilterModalController($state, apiClient, user, messenger, $uibModalInstance) {
        var vm = this;
        var params = angular.copy($state.params);

        vm.save = save;
        vm.close = close;

        // Remove global site selected filter
        delete params.selectedSiteResource;

        if (params.jobStatus === undefined) {
            params.jobStatus = [];
        }

        vm.data = {
            route: $state.current.name,
            name: null,
            query: JSON.stringify(params),
        };

        function save() {
            apiClient.create(user.getLink('create-filter-criteria'), vm.data).then(function(response) {
                if (response) {
                    messenger.success('FILTER_SAVED');
                    $uibModalInstance.dismiss();
                    $state.go('.', params, {reload: $state.current.name});
                }
            });
        }

        function close() {
            $uibModalInstance.dismiss();
        }
    }
})();
