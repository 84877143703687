(function () {
    'use strict';

    angular
        .module('elogbooks.common.meter-meters')
        .controller('CommonMeterDetailsController', ['messenger', 'crudService', '$state', 'baseRoute', 'meterResponse', 'submetersCollectionResponse', 'meterFilesCollectionResponse', 'lightBoxModal', 'imageModal', 'locationsCollectionResponse', '$stateParams', 'paramConverterService', 'meterSupplyCollectionResponse', '$translate', CommonMeterDetailsController]);

    function CommonMeterDetailsController(messenger, crudService, $state, baseRoute, meterResponse, submetersCollectionResponse, meterFilesCollectionResponse, lightBoxModal, imageModal, locationsCollectionResponse, $stateParams, paramConverterService, meterSupplyCollectionResponse, $translate) {
        var vm = this;
        vm.activeTab = $state.params.activeTab ? parseInt($state.params.activeTab) : 1;
        vm.baseRoute = baseRoute;
        vm.files_data = meterFilesCollectionResponse.files;
        vm.files = [];
        vm.lightBox = lightBox;
        vm.meter = meterResponse.getData();
        vm.pages = submetersCollectionResponse.pages;
        vm.submeters = submetersCollectionResponse.meters;
        vm.submetersCollection = submetersCollectionResponse;
        vm.entityCount = submetersCollectionResponse.count;
        vm.reading = {
            readAt: null,
            reading: null,
            type: null
        };
        vm.upload = upload;
        vm.deleteReading = deleteReading;
        vm.criteria = {};

        var statusOptions = [
            {
                title: $translate.instant('NONE_SELECTED'),
                value: 'none'
            },
            {
                title: $translate.instant('STATUS_INACTIVE'),
                value: '0'
            },
            {
                title: $translate.instant('STATUS_ACTIVE'),
                value: '1'
            }
        ];

        if (meterSupplyCollectionResponse) {
            vm.meterSupplySelect = {
                response : meterSupplyCollectionResponse,
                link : meterSupplyCollectionResponse ? meterSupplyCollectionResponse.getLink('self') : null,
                itemValuePath: 'id',
                itemTitlePath: 'name',
                responseKeyPath: 'meterTypes'
            };

            vm.criteria.meterSupplyTypeId = {
                type: 'jsonselectwidget',
                title: 'METER_TYPE_SUPPLY',
                value: JSON.parse($stateParams.meterSupplyTypeId),
                options: vm.meterSupplySelect
            };
        }

        if (locationsCollectionResponse) {
            vm.locationSelect = {
                response : locationsCollectionResponse,
                link : locationsCollectionResponse ? locationsCollectionResponse.getLink('self') : null,
                itemValuePath: 'id',
                itemTitlePath: 'hierarchicalPath',
                responseKeyPath: 'locations',
            };

            vm.criteria.meterLocationId = {
                type: 'jsonselectwidget',
                title: 'LOCATION',
                value: JSON.parse($stateParams.meterLocationId),
                options: vm.locationSelect
            };
        }

        vm.readingsExportModalOptions = {
            templateUrl: '/modules/common/meters/meters/modal/export-readings.html',
            controller: 'CommonMeterExportReadingsController',
            controllerAs: 'vm'
        };

        vm.readingsExportParams = {
            id: vm.meter.id
        };

        vm.criteria.submeterActive = { type: 'options', title: 'STATUS', value: $stateParams.submeterActive, options: statusOptions, clearValue: 'none' };

        angular.forEach(vm.files_data, function(value){
            vm.files.push({
                file_name:value._links.resource.title,
                href:value._links.resource.href,
                caption:value.caption
            });
        });

        function lightBox(index) {
            vm.modalData ={
                href: vm.files[index].href,
                title:vm.files[index].title,
                caption: vm.files[index].caption
            };
            lightBoxModal.open(vm.modalData);
        }

        function upload() {
            imageModal.open(vm.files_data, meterResponse, false, false);
        }

        vm.search = search;
        function search(params) {
            var override = {
                meterPage: 1,
                submetersPage: 1,
                meterResource: $state.params.meterResource
            };
            $state.go('.', angular.extend({}, params, override), { reload: true });
        }

        function deleteReading(reading) {
            return crudService.delete(
                reading.getLink('delete'),
                null,
                null,
                {},
                true
            ).then(function(response) {
                if (response) {
                    $state.go('.', $stateParams, {reload: $state.get('^.^.^')}).then(function () {
                        messenger.success('METER_READING_DELETED');
                    });
                }
            });
        }
    }
})();
