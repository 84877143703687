angular.module('elogbooksServices').service('tenantEmailerService', function (apiClient, $q, requestDataFactory, $filter, confirmationModal, confirmationModalNotes, lodash) {
    var vm = this;

    var baseTenantsResponse = {
        contacts: []
    };

    this.tenantsResponse = angular.copy(baseTenantsResponse);
    this.tenantFilter = {};
    this.email = null;
    this.emailFiltered = false;

    this.reset = reset;
    this.isEmpty = isEmpty;

    function reset () {
        vm.tenantFilter = {};
        vm.tenantsResponse = angular.copy(baseTenantsResponse);
    }

    function isEmpty () {
        return (!vm.tenantsResponse.contacts.length && !Object.keys(vm.tenantFilter).length);
    }
});
