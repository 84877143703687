app.config(function ($translateProvider) {
    $translateProvider.translations('en-gb', {
        CLEAR_ALL: 'Clear Filters',
        CLEAR: 'Clear',
        ADVANCED_FILTERS: 'Advanced Filters',
        VIEW_COLUMNS: 'View Columns',
        SAVE_FILTER: 'Save Filter',
        FILTER_NAME: 'Filter Name',
        FILTER_SAVED: 'Filter Saved',
        TOOLTIP_SAVE_FILTER: 'Click to save the current set of filters',
        TOOLTIP_LOAD_SAVED_FILTER: 'Click to load your saved filters',
        FILTER_DELETED: 'Filter deleted successfully',
        SAVED_FILTERS_TITLE: 'Saved Filters'
    });
});
