(function () {
    'use strict';

    angular
        .module('elogbooks.common.form-associations')
        .controller('FormAssociationAddController', FormAssociationAddController);

    FormAssociationAddController.$inject = [
        '$state',
        'messenger',
        'crudService',
        'requestDataFactory',
        'formCollectionResponse',
        'formAssociationsCollectionResponse',
        'config'
    ];

    function FormAssociationAddController(
        $state,
        messenger,
        crudService,
        requestDataFactory,
        formCollectionResponse,
        formAssociationsCollectionResponse,
        config
    ) {
        var vm = this;
        vm.formCollectionResponse = formCollectionResponse;
        vm.formAssociationsCollectionResponse = formAssociationsCollectionResponse;
        vm.config = config;

        vm.data = requestDataFactory.createRequest({});

        vm.selectedFormModel = {
            response: vm.formCollectionResponse,
            link: vm.formCollectionResponse.getLink('self'),
            required: true,
            responseKeyPath: 'forms',
            itemHrefPath: '_links.self.href',
            itemValuePath: 'summary',
            searchKey: 'summary',
            onSelect: changeForm,
            onChange: changeForm
        };

        vm.create = createAction;

        function createAction() {
            vm.data.type = config.type;
            vm.data.entityId = config.entityId;

            return crudService.create(
                formAssociationsCollectionResponse.getLink('create'),
                vm.data,
                null,
                null,
                null,
                true
            ).then(function() {
                messenger.success('FORM_ASSOCIATION_CREATED');
                $state.go('^', {}, {reload: $state.current.parent});
            });
        }

        function changeForm(formModel) {
            vm.data._links = {};

            if (formModel.selected) {
                vm.data._links.form = {href: formModel.selected.href};
            }
        }
    }
})();
