angular.module('elogbooksServices').service('jobTypes', function() {

    var jobTypes = {
        planned: "Planned",
        reactive: "Reactive",
        comprehensive: "Comprehensive",
        additional: "Additional",
        management: "Management",
        patrol: "Patrol"
    };

    this.getAll = function () {
        return jobTypes;
    }

    return this;
});
