angular.module('elogbooksDirectives').directive('elogbooksDatetime', [function(){

    return {
        // restrict to attributes only
        restrict: 'AE',
        require: 'ngModel',
        scope: {
            date: '=ngModel',
            required: '=isRequired',
            hour: '=hour',
            disabled: '=ngDisabled',
            formName: '@formName',
            columns: '@?columns',
            minDate: '=minDate',
            maxDate: '=maxDate',
            prefillDate: '=prefillDate',
            excludeTime: '=?excludeTime',
            maxDate: '=maxDate',
        },

        templateUrl: '/modules/directives/date/datetime/datetime.html',
        controller: function ($scope, $attrs) {
            // Use == to test undefined and null.
            /*if ($scope.date == null) {
                $scope.date = new Date();

                if ($attrs.defaultTime === 'midnight') {
                    $scope.date.setMilliseconds(0);
                    $scope.date.setSeconds(0);
                    $scope.date.setMinutes(0);
                    $scope.date.setHours(0);
                }
            }*/

            if (!$scope.date && $scope.prefillDate === true) {
                $scope.date = new Date();
            } else if ($scope.date && $scope.date.toString() === 'Invalid Date') {
                $scope.date = null;
            }

            $scope.$watch('date', function(newVal, oldVal) {
                if(newVal instanceof Date && oldVal instanceof Date
                    && (oldVal.getDate() != newVal.getDate() ||
                        oldVal.getMonth() != newVal.getMonth() ||
                        oldVal.getFullYear() != newVal.getFullYear())
                ) {
                    $scope.date.setHours(oldVal.getHours());
                    $scope.date.setMinutes(oldVal.getMinutes());
                    $scope.date.setSeconds(oldVal.getSeconds());
                } else if(oldVal == null && newVal) {
                    $scope.date.setHours(newVal.getHours());
                    $scope.date.setMinutes(newVal.getMinutes());
                    $scope.date.setSeconds(newVal.getSeconds());
                }
            });

            if (!$scope.columns) {
                $scope.columns = 9;
            }

            $scope.columnsDate = parseInt($scope.columns, 10);
        },

        link: function(scope, element, attr, control) {
            control.$validators.elogbooksDirectives = function () {
                if (!scope.minDate && scope.date) {
                    return true;
                }

                var completionDate = new Date(scope.minDate);
                var requestExtensionDate = scope.date;

                if (requestExtensionDate === undefined) {
                    return true;
                }

                if (completionDate && requestExtensionDate) {
                    if (completionDate.getTime() > requestExtensionDate.getTime()) {
                        return false;
                    }
                }

                return true;
            };

            if (scope.excludeTime === true) {
                scope.columnsDate = 9;
            } else {
                scope.excludeTime = false;
            }

            if (scope.hour === true) {
                control.$validators.dateMaxOneHourFromNow = function () {
                    if (scope.date !== undefined) {
                        var date = new Date();
                        var maxDate = date.setMilliseconds(date.getMilliseconds() + (1000 * 60 * 60));
                        var inputDate = scope.date.getTime();

                        if (inputDate > maxDate) {
                            return false;
                        }
                    }

                    return true;
                };
            }
        }
    };
}]).constant('uiDatetimePickerConfig', {
    dateFormat: 'yyyy-MM-dd HH:mm',
    html5Types: {
        date: 'yyyy-MM-dd',
        'datetime-local': 'yyyy-MM-ddTHH:mm:ss.sss',
        'month': 'yyyy-MM'
    },
    initialPicker: 'date',
    reOpenDefault: false,
    defaultTime: '12:00:00',
    enableDate: true,
    enableTime: true,
    buttonBar: {
        show: true,
        now: {
            show: true,
            text: 'Now',
            cls: 'nowBtn btn-sm btn-default'
        },
        today: {
            show: true,
            text: 'Today',
            cls: 'todayBtn btn-sm btn-default'
        },
        clear: {
            show: true,
            text: 'Clear',
            cls: 'btn-sm btn-danger'
        },
        date: {
            show: true,
            text: 'Date',
            cls: 'btn-sm btn-default'
        },
        time: {
            show: true,
            text: 'Time',
            cls: 'btn-sm btn-default'
        },
        close: {
            show: true,
            text: 'Close',
            cls: 'closeBtn btn-sm btn-default'
        },
        cancel: {
            show: false,
            text: 'Cancel',
            cls: 'btn-sm btn-default'
        }
    },
    closeOnDateSelection: true,
    closeOnTimeNow: true,
    appendToBody: false,
    altInputFormats: [],
    ngModelOptions: {},
    saveAs: false,
    readAs: false
});
