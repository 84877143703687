(function () {
    'use strict';

    angular
        .module('elogbooks.admin.departments')
        .controller('DepartmentsAddEditController', DepartmentsAddEditController);

    DepartmentsAddEditController.$inject = [
        '$scope',
        'apiClient',
        'messenger',
        'departmentCollectionResponse',
        'userCollectionResponse',
        '$translate',
        'departmentResponse',
        'requestDataFactory',
        'lodash',
        'pageService',
        'confirmationModal'
    ];

    function DepartmentsAddEditController(
        $scope,
        apiClient,
        messenger,
        departmentCollectionResponse,
        userCollectionResponse,
        $translate,
        departmentResponse,
        requestDataFactory,
        lodash,
        pageService,
        confirmationModal
    ) {
        var vm = this;

        vm.departmentResponse = departmentResponse;
        vm.departmentCollectionResponse = departmentCollectionResponse;
        vm.userCollectionResponse = userCollectionResponse;
        vm.data = requestDataFactory.createRequest(getDefaults());
        vm.noBorder = false;

        vm.create = createAction;
        vm.update = updateAction;
        vm.delete = deleteAction;

        vm.absenceApproverSelectModel = {
            response: userCollectionResponse,
            link: userCollectionResponse.getLink('self'),
            required: true,
            responseKeyPath: 'users',
            itemHrefPath: '_links.self.href',
            itemValuePath: 'name',
            isHierarchical: true,
            isAbsenceApprover: true,
            selected: !lodash.isEmpty(vm.departmentResponse) ? {
                href: vm.departmentResponse.getLink('absence-approver'),
                title: vm.departmentResponse._links['absence-approver'].name,
                object: vm.departmentResponse._links['absence-approver']
            } : null,
            onSelect: changeApprover,
            onRemove: changeApprover
        };

        vm.timesheetApproverSelectModel = {
            response: userCollectionResponse,
            link: userCollectionResponse.getLink('self'),
            required: true,
            responseKeyPath: 'users',
            itemHrefPath: '_links.self.href',
            itemValuePath: 'name',
            isHierarchical: true,
            isTimesheetApprover: true,
            selected: !lodash.isEmpty(vm.departmentResponse) ? {
                href: vm.departmentResponse.getLink('timesheet-approver'),
                title: vm.departmentResponse._links['timesheet-approver'] ? vm.departmentResponse._links['timesheet-approver'].name : null,
                object: vm.departmentResponse._links['timesheet-approver']
            } : null,
            onSelect: changeApprover,
            onRemove: changeApprover
        };

        vm.statuses = [
            {
                value: true,
                label: $translate.instant('ACTIVE')
            },
            {
                value: false,
                label: $translate.instant('INACTIVE')
            }
        ];

        vm.changeApprover = changeApprover;

        function changeApprover(approver) {
            if (approver.selected) {
                if (approver.isAbsenceApprover) {
                    vm.data._links.absenceApprover.href = approver.selected.href;
                } else {
                    vm.data._links.timesheetApprover.href = approver.selected.href;
                }

            } else {
                if (approver.isAbsenceApprover) {
                    vm.data._links.absenceApprover.href = null;
                } else {
                    vm.data._links.timesheetApprover.href = null;
                }
            }
        }

        function createAction () {
            apiClient.create(departmentCollectionResponse.getLink('create'), vm.data).then(function (response) {
                if (response) {
                    $scope.$state.go('^', {}, { reload: 'dashboard.admin.departments.list' }).then(function () {
                        messenger.success('ADD_SUCCESS');
                    });
                } else {
                    messenger.error('REQUEST_ERROR');
                }
            });
        };

        function updateAction () {
            apiClient.update(vm.departmentResponse.getLink('edit'), vm.data).then(function (response) {
                if (response) {
                    $scope.$state.go('^', {}, { reload: 'dashboard.admin.departments.list' }).then(function () {
                        messenger.success('UPDATE_SUCCESS');
                    });
                } else {
                    messenger.error('REQUEST_ERROR');
                }
            });
        };

        function deleteAction () {
            return confirmationModal.open().result.then(function (response) {
                apiClient.delete(vm.departmentResponse.getLink('delete'), vm.data).then(function (response) {
                    if (response) {
                        $scope.$state.go(
                            'dashboard.admin.departments.list',
                            {page: pageService.calculatePageOnDelete(vm.departmentCollectionResponse)},
                            { reload: 'dashboard.admin.departments.list' }
                        ).then(function () {
                            messenger.success('DELETE_SUCCESS');
                        });
                    } else {
                        messenger.error('REQUEST_ERROR');
                    }
                });
            });
        };

        function getDefaults() {
            var defaults =  {
                id: vm.departmentResponse.id || null,
                name: vm.departmentResponse.name || null,
                active: vm.departmentResponse.active === true || vm.departmentResponse.active === false ? vm.departmentResponse.active : null,
                _links: {
                    absenceApprover: vm.departmentResponse.id ?  vm.departmentResponse._links['absence-approver'] : { href: null },
                    timesheetApprover: vm.departmentResponse.id ?  vm.departmentResponse._links['timesheet-approver'] : { href: null }
                }
            };

            return defaults;
        }
    };
})();
