(function() {
    'use strict';

    angular
        .module('elogbooks.common.quote-requests')
        .controller('CommonQuoteRequestDetailsQuoteDetailsOptionDetailsController', CommonQuoteRequestDetailsQuoteDetailsOptionDetailsController);

    CommonQuoteRequestDetailsQuoteDetailsOptionDetailsController.$inject = ['quoteRequestResponse', 'quoteResponse', 'quoteOptionResponse', 'apiClient', '$resolve', 'fileCollectionResponse'];

    function CommonQuoteRequestDetailsQuoteDetailsOptionDetailsController(quoteRequestResponse, quoteResponse, quoteOptionResponse, apiClient, $resolve, fileCollectionResponse) {
        var vm = this;
        vm.quoteRequestResponse = quoteRequestResponse;
        vm.quoteResponse = quoteResponse;
        vm.quoteOptionResponse = quoteOptionResponse;

        if (!$resolve.quoteOptionFileCollectionResponse) {
            $resolve.quoteOptionFileCollectionResponse = {};
        }

        apiClient.get(vm.quoteOptionResponse.getLink('files')).then(function(response) {
            $resolve.quoteOptionFileCollectionResponse[quoteOptionResponse.id] = response;
            vm.filesCount = response.count;
        });
    }
})();
