(function() {
    'use strict';

    angular
        .module('elogbooks.user.escalations')
        .controller('EscalationDetailsQuotesController', EscalationDetailsQuotesController);

    EscalationDetailsQuotesController.$inject = [
        '$state',
        'messenger',
        'quoteCollectionResponse',
        'lodash',
        'apiClient',
        'requestDataFactory',
        'confirmationModal',
        'config',
        '$stateParams',
        '$translate'
    ];

    function EscalationDetailsQuotesController(
        $state,
        messenger,
        quoteCollectionResponse,
        lodash,
        apiClient,
        requestDataFactory,
        confirmationModal,
        config,
        $stateParams,
        $translate
    ) {
        var vm = this;
        vm.quoteCollectionResponse = quoteCollectionResponse;
        vm.allChecked = false;
        vm.selectedItems = [];
        vm.data = requestDataFactory.createRequest();
        vm.isHelpdesk = config.isHelpdesk;

        vm.checkAll = checkAll;
        vm.toggleChecked = toggleChecked;
        vm.order = orderAction;
        vm.search = searchAction;
        vm.delete = deleteAction;

        var options = [
            { title: $translate.instant('NONE_SELECTED'), value: null },
            { title: $translate.instant('YES'), value: 'on' },
            { title: $translate.instant('NO'), value: 'no' }
        ];

        vm.criteria = {
            updateToBeReviewed: { type: 'options', title: 'UPDATE_TO_BE_REVIEWED', value: $stateParams.updateToBeReviewed, options: options }
        };

        function checkAll() {
            angular.forEach(quoteCollectionResponse.quotes, function(quote, $index) {
                quote.checked = vm.allChecked;

                updateSelection(quote);
            })
        }

        function toggleChecked(quote) {
            updateSelection(quote);
        }

        function updateSelection(quote) {
            var index = lodash.findIndex(vm.selectedItems, {id: quote.id});

            if (index === -1) {
                vm.selectedItems.push(quote);
            } else {
                lodash.remove(vm.selectedItems, {id: quote.id});
            }
        }

        function deleteAction() {

            vm.data._links = {quotes: []};
            angular.forEach(vm.selectedItems, function(value, $index) {
                vm.data._links.quotes.push({href: value.getLink('self')});
            });

            return confirmationModal.open().result.then(function() {
                return apiClient.create(quoteCollectionResponse.getLink('deescalate'), vm.data).then(function(response) {
                    if (response) {
                        $state.go('.', {}, {reload: 'dashboard.user.escalations.list.details'}).then(function() {
                            messenger.success('DEESCALATED');
                        });
                    } else {
                        messenger.error('REQUEST_ERROR');
                    }
                });
            });
        }

        function orderAction (key) {
            $stateParams.escalationQuotesOrder = key;

            $state.go('.', angular.extend({}, $stateParams), { reload: $state.current });
        }

        function searchAction (params) {
            var override = {
                escalationQuotesPage: 1
            };
            $state.go('.', angular.extend({}, params, override), { reload: $state.current });
        }
    }
})();
