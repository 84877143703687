(function() {
    'use strict';
    angular
        .module('elogbooks.common.assets')
        .controller('UserAssetsSubAssetDetailsController', UserAssetsSubAssetDetailsController);

    UserAssetsSubAssetDetailsController.$inject = ['subAssetResponse', 'locationResponse', 'userManager', 'assetResponse', 'sectionResource', 'config'];

    function UserAssetsSubAssetDetailsController(subAssetResponse, locationResponse, userManager, assetResponse, sectionResource, config) {
        var vm = this;
        vm.subAsset = subAssetResponse;
        vm.asset = assetResponse;
        vm.location = locationResponse;
        vm.sectionResource = sectionResource;
        vm.user = userManager.user;
        vm.config = config;
    }
})();
