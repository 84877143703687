/**
 * This common is for both Site Contacts and Tenants
 * including, but not limited to, the following:
 *
 * User > Sites > Contacts
 * User > Sites > Tenants
 * User > Tenants
 * Admin > Sites > Contacts
 */

(function(){
    'use strict';

    angular.module('elogbooks.common.site-contacts', []);
})();
