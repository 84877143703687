(function() {
    'use strict';

    angular
        .module('elogbooks.user.finance.credit-notes')
        .controller('FinanceCreditNoteWorkflowAddNoteController', FinanceCreditNoteWorkflowAddNoteController);

    FinanceCreditNoteWorkflowAddNoteController.$inject = [
        'creditNoteResponse',
        'lodash',
        '$state',
        'apiClient',
        'messenger'
    ];

    function FinanceCreditNoteWorkflowAddNoteController(
        creditNoteResponse,
        lodash,
        $state,
        apiClient,
        messenger
    ) {
        var vm = this;

        vm.type = lodash.last($state.current.name.split('.'));
        vm.title = vm.type.toUpperCase().replaceAll('-', '_');
        vm.data = { note: null };
        vm.config = {
            'mark-as-cancelled': {
                submitText: 'SUBMIT',
                noteRequired: true
            },
            'reopen': {
                noteRequired: true
            }
        };
        vm.creditNoteResponse = creditNoteResponse;

        vm.create = vm.update = function() {
            return apiClient.create(vm.creditNoteResponse.getLink(vm.type), vm.data).then(function (response) {
                if (response) {
                    $state.go('^.^', {}, { reload: true }).then(function () {
                        messenger.success(
                            'CREDIT_NOTE_WORKFLOW_'
                            + vm.title
                            + '_SUCCESS'
                        );
                    });
                } else {
                    messenger.error('REQUEST_ERROR');
                }
            });
        };
    }
})();
