app.config(function ($translateProvider) {
    $translateProvider.translations('nl', {

        SURVEY_LINE: 'Checklist-regel',
        SURVEY_LINES: 'Checklist-regels',
        SURVEY_LINE_LIST: 'Checklist-regels',

        SURVEY_LINE_ADD: 'Nieuwe Checklist-regel toevoegen',
        SURVEY_LINE_EDIT: 'Bewerk Checklist-regel',

        SURVEY_LINE_CREATED: 'Checklist-regel Aangemaakt',
        SURVEY_LINE_UPDATED: 'Checklist-regel Bijgewerkt',
        SURVEY_LINE_DELETED: 'Checklist-regel Verwijderd',

        VALUE_MUST_BE_A_NUMBER_BETWEEN_1_AND_5: 'Invoer moet een getal zijn tussen 1 en 5',
        COMMENT_3000_OR_LESS: "Maximaal 3000 karakters"
    });
});
