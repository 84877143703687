(function () {
    "use strict";

    angular.module("elogbooks").config(Translations);

    function Translations($translateProvider) {
        $translateProvider.translations("en-gb", {
            WEBHOOKS: "Webhooks",
            WEBHOOK_LIST: "Webhooks",
            WEBHOOK: "Webhook",
            WEBHOOK_ADD: "Add new Webhook",
            WEBHOOK_EDIT: "Edit Webhook",
            WEBHOOK_CREATED: "Webhook created",
            WEBHOOK_UPDATED: "Webhook updated",
            V2_SHARING: "V2 System Sharing",
            HEADERS: "Headers",
            UPDATED_BY: "Updated By",
            UPDATED_AT: "Updated At",
            URL: "URL",
            KEY: "Key",
            SETUP: "Setup",
            SHARE: "Share",
            SETUP_SHARE: "Setup Share",
            SHARE_SETUP_ERROR: "Share setup failed or was cancelled",
            SHARE_SETUP_SUCCESS: "Share setup was successful",
            V2_INSTANCE_URL: "Elogs CAFM V2 Installation Url",
            CANNOT_SET_UP_WEBHOOK_WITH_SAME_DOMAIN:
                "Cannot set up webhook with same domain",
            CANNOT_SET_UP_SHARE_LINK_WITH_SAME_DOMAIN:
                "Cannot set up share link with same domain",
            SHARING_SETUP_PARENT_INFO:
                "You will be redirected to your Elogs CAFM installation where you must select a site and service provider to initiate a share. Please ensure that you are logged in as a user with: create webhooks, create jobs and create quotes permissions as this user will be used for sharing.",
            SHARING_SETUP_CHILD_INFO:
                "Please ensure that you are logged in as a user with: create webhooks, create jobs and create quotes permissions as this user will be used for sharing.",
            INVALID_V2_URL:
                "The url is not a valid Elogs CAFM V2 installation url",
            DUPLICATE_V2_SITE_WEBHOOK:
                "Share has already been setup at this site",
            DUPLICATE_SHARING_URL: "This sharing url has already been added",
            WORKFLOW_ACTION_DISABLED_ON_SHARED_JOB:
                "Actions have been disabled because this job is shared",
            WORKFLOW_ACTION_DISABLED_ON_SHARED_QUOTE:
                "Actions have been disabled because this quote is shared",

            SHARED_OPERATIVE: "Shared Operative",
            SHARED_SITE_ASSOCIATE: "Shared Site Associate",
            SHARED_APPROVER: "Shared  Approver",
            SHARED_REACTIVE_JOB_APPROVER: "Shared Reactive Job Approver",
            SHARED_REACTIVE_JOB_EXTENSION_APPROVER:
                "Shared Reactive Job Extension Approver",

            NAME_CANNOT_BE_NULL: "Contact name cannot be null",
            UP_SHARE: "Up Share",
            DOWN_SHARE: "Down Share",
            CANNOT_REQUEST_APPROVAL: "Cannot request approval",

            SET_VALUE_DISABLED: "Set job value disabled",
            ADD_VALUE_DISABLED: "Add job value disabled",
            APPROVE_VALUE_DISABLED: "Approve job value disabled",
            REJECT_VALUE_DISABLED: "Reject job value disabled",
            WAIVE_MANAGEMENT_DISABLED: "Waive management disabled",
            UPHOLD_MANAGEMENT_DISABLED: "Uphold management disabled",
            WAIVE_MANAGEMENT_FEE_DISABLED: "Waive management disabled",
            UPHOLD_MANAGEMENT_FEE_DISABLED: "Uphold management disabled",
        });
    }
})();
