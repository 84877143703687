(function () {
    'use strict';

    angular
        .module('elogbooks.common.audit')
        .controller('AuditActivateController', AuditActivateController);

    AuditActivateController.$inject = ['$uibModalInstance', 'apiClient'];

    function AuditActivateController ($uibModalInstance, apiClient) {
        var vm = this;
        vm.close = closeModal;
        vm.confirm = confirm;
        vm.notes = '';

        function closeModal() {
            $uibModalInstance.dismiss('cancel');
        };

        function confirm() {
           $uibModalInstance.close();
        };
    }
})();
