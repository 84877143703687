(function () {
    'use strict';

    angular
        .module('elogbooks.user.reports')
        .controller('UserReportsOperativePerformanceDetailsController', UserReportsOperativePerformanceDetailsController);

    UserReportsOperativePerformanceDetailsController.$inject = [
        '$state',
        '$stateParams',
        'serviceProviderCollectionResponse',
        'regionCollectionResponse',
        'siteCollectionResponse',
        'locationCollectionResponse',
        'siteGroupsCollectionResponse',
        'operativesCollectionResponse',
        'moment',
        'reportTotalsService',
        '$q',
        'showPercentagesService'
    ];

    function UserReportsOperativePerformanceDetailsController(
        $state,
        $stateParams,
        serviceProviderCollectionResponse,
        regionCollectionResponse,
        siteCollectionResponse,
        locationCollectionResponse,
        siteGroupsCollectionResponse,
        operativesCollectionResponse,
        moment,
        reportTotalsService,
        $q,
        showPercentagesService
    ) {
        var vm = this;
        vm.saveParams = saveParams;
        vm.search = search;
        vm.order = orderAction;
        vm.operatives = operativesCollectionResponse.operatives;
        vm.operativesResponse = operativesCollectionResponse;
        vm.criteria = {};

        vm.filtersDisabled = true;

        vm.showPercentages = showPercentagesService.getShowPercentageState();
        vm.exportParams = {showPercentages: vm.showPercentages};
        vm.updateShowPercentages = updateShowPercentages;

        if (serviceProviderCollectionResponse) {
            vm.serviceProviderSelect = {
                response : serviceProviderCollectionResponse,
                link : serviceProviderCollectionResponse ? serviceProviderCollectionResponse.getLink('self') : null,
                itemValuePath: 'id',
                itemTitlePath: 'name',
                responseKeyPath: 'serviceproviders'
            };
        }

        vm.criteria.serviceProvider = { type: 'jsonselectwidget', title: 'SERVICE_PROVIDER', value: JSON.parse($stateParams.serviceProvider), options: vm.serviceProviderSelect };
        vm.filtersDisabled = $stateParams.serviceProvider  === null  ? true : false;

        if (!vm.filtersDisabled){
            vm.serviceProviderName = JSON.parse($stateParams.serviceProvider).n;

            var params = angular.copy($stateParams);

            params.name = vm.serviceProviderName;

            if ($stateParams.site){
                params.site = JSON.parse($stateParams.site).v;
            }

            if ($stateParams.location){
                params.location = JSON.parse($stateParams.location).v;
            }

            if ($stateParams.siteRegionId) {
                params.siteRegionName = JSON.parse($stateParams.siteRegionId).n;
            }

            delete params.order; // To avoid braking on pagination
            if (reportTotalsService.isCachedDataValid(params)) {
                vm.totals = reportTotalsService.getCachedTotals().serviceProviders[0];
            } else {
                $q.all([reportTotalsService.getTotalsData(params, '/reports/sp-summary').then(function(response) {
                    vm.totals = response.serviceProviders[0];
                })]);
            }
        }

        vm.criteria.name = {
            type:'text',
            title:'NAME',
            value:!vm.filtersDisabled ? $stateParams.name : null,
            isDisabled: vm.filtersDisabled
        };

        vm.criteria.hours = {
            type: 'number',
            title: 'AWAITING_PAPERWORK_SINCE',
            value: !vm.filtersDisabled ? parseInt($stateParams.hours, 10) : null,
            min: 1,
            max: 500,
            step: 1,
            isDisabled: vm.filtersDisabled
        };

        vm.siteRegionSelect = {
            response : regionCollectionResponse,
            link : regionCollectionResponse ? regionCollectionResponse.getLink('self') : null,
            responseKeyPath: 'regions',
            itemValuePath: 'id',
            itemTitlePath: 'name',
            disabled: vm.filtersDisabled
        };

        vm.criteria.siteRegionId = {
            type: 'jsonselectwidget',
            title: 'REGION',
            value: !vm.filtersDisabled ? JSON.parse($stateParams.siteRegionId) : null,
            options: vm.siteRegionSelect,
        };

        vm.siteSelect = {
            response: siteCollectionResponse,
            link: siteCollectionResponse && siteCollectionResponse.count > 0 ? siteCollectionResponse.getLink('self') : null,
            responseKeyPath: 'sites',
            itemValuePath: 'id',
            itemTitlePath: 'name',
            disabled: vm.filtersDisabled
        };

        vm.criteria.site = {
            type: 'jsonselectwidget',
            title: 'SITE',
            value: !vm.filtersDisabled ? JSON.parse($stateParams.site) : null,
            options: vm.siteSelect,
        };

        vm.locationSelect = {
            response: locationCollectionResponse,
            link: locationCollectionResponse && locationCollectionResponse.count > 0 ? locationCollectionResponse.getLink('self') : null,
            responseKeyPath: 'locations',
            itemValuePath: 'id',
            itemTitlePath: 'name',
            disabled: true,
            selected: null
        };

        vm.criteria.location = {
            type: 'jsonselectwidget',
            title: 'LOCATION',
            value: !vm.filtersDisabled ? JSON.parse($stateParams.location): null,
            options: vm.locationSelect
        };

        if (vm.criteria.site.value !== null ) {
            vm.criteria.location.options.disabled = false;
        } else {
            vm.criteria.location.value = null;
        }

        vm.siteGroupSelect = {
            response: siteGroupsCollectionResponse,
            responseKeyPath: 'sitegroups',
            disabled: vm.filtersDisabled
        };

        vm.criteria.siteGroupName= {
            type: 'selectwidget',
            title: 'SITE_GROUP',
            value: !vm.filtersDisabled ? $stateParams.siteGroupName : null,
            options: vm.siteGroupSelect,
            selected: vm.filtersDisabled
        };

        vm.criteria.dateRange = {
            type: 'date-range',
            title: 'BETWEEN_DATES',
            value: {
                startDate: ($stateParams.dateRange !== null) ? moment(new Date($stateParams.dateRange.split(',')[0])) : null,
                endDate: ($stateParams.dateRange !== null) ? moment(new Date($stateParams.dateRange.split(',')[1])) : null,
                endMaxDate: moment().add(-1, 'days').format('MM-DD-YYYY, 23:59:59')
            },
            clearValue: {
                startDate: null,
                endDate: null
            }
        };

        function updateShowPercentages() {
            vm.exportParams = {showPercentages: !!vm.showPercentages};
            showPercentagesService.saveShowPercentageState(!!vm.showPercentages);
        }

        function saveParams (selectModel) {
            if (selectModel.mapValue) {
                if (selectModel.selected === undefined) {
                    params[selectModel.mapValue] = null;
                } else {
                    $stateParams[selectModel.mapValue] = selectModel.selected.object.id;
                }
            }

            vm.search();
        }

        function search (params) {
            var override = {
                page: 1
            };

            $state.go('.', angular.extend({}, params, override), { reload: $state.current });
        }

        function orderAction (key) {
            $stateParams.order = key;
            $state.go('.', angular.extend({}, $stateParams), { reload: $state.current });

        }
    }
})();