(function() {
    'use strict';

    angular
        .module('elogbooks.user.absence-planners')
        .controller('UserAbsenceEntryAddEditController', UserAbsenceEntryAddEditController);

    UserAbsenceEntryAddEditController.$inject = [
        'userCollectionResponse',
        'absenceEntryResponse',
        'lodash',
        'requestDataFactory',
        '$translate',
        'absenceEntriesCollectionResponse',
        '$scope',
        'departmentResponse',
        'user',
        'publicHolidaysCollectionResponse',
        'dateTimeService',
        'crudService',
        '$state',
        'userManager',
        'moment'
    ];

    function UserAbsenceEntryAddEditController(
        userCollectionResponse,
        absenceEntryResponse,
        lodash,
        requestDataFactory,
        $translate,
        absenceEntriesCollectionResponse,
        $scope,
        departmentResponse,
        user,
        publicHolidaysCollectionResponse,
        dateTimeService,
        crudService,
        $state,
        userManager,
        moment
    ) {
        var vm = this;
        vm.userCollectionResponse = userCollectionResponse;
        vm.absenceEntryResponse = absenceEntryResponse;
        vm.departmentResponse = departmentResponse;
        vm.absenceEntriesCollectionResponse = absenceEntriesCollectionResponse;
        vm.publicHolidaysCollectionResponse = publicHolidaysCollectionResponse;
        vm.user = user;
        vm.userManager = userManager;
        vm.userSelectModel = null;
        vm.showUserField = true;
        vm.datePickerOptionsStartDate = {};
        vm.datePickerOptionsEndDate = {};
        vm.isEditView = vm.absenceEntryResponse.id ? true : false;
        vm.data = requestDataFactory.createRequest(getDefaults());

        vm.create = createAction;
        vm.update = updateAction;

        if (userCollectionResponse.count > 0) {
            vm.userSelectModel = {
                response: userCollectionResponse,
                link: userCollectionResponse.getLink('self'),
                required: true,
                responseKeyPath: 'users',
                itemHrefPath: '_links.self.href',
                itemValuePath: 'name',
                isHierarchical: true,
                disabled: vm.data.id ? true : false,
                populateDisabled: true,
                selected: !lodash.isEmpty(vm.absenceEntryResponse) ? {
                    href: vm.absenceEntryResponse.getLink('user'),
                    title: vm.absenceEntryResponse._links['user'].title,
                    object: vm.absenceEntryResponse._links['user'],
                    approver:{
                        href:vm.absenceEntryResponse._links['absence-approver'] ? vm.absenceEntryResponse._links['absence-approver'].href : null
                    }
                } : setSelectedToCurrentUser(),
                onSelect: changeUser,
                onRemove: changeUser
            };
        } else {
            vm.showUserField = false;
            vm.data._links.user = vm.user._links.self;
            vm.userSelectModel = { selected:setSelectedToCurrentUser() };
        }

        if (vm.userSelectModel.selected) {
            vm.data._links.user.href = vm.userSelectModel.selected.href;
        }

        vm.submitButtonText = getSubmitButtonText();

        vm.types = [
            {
                value: 0,
                title: $translate.instant('TYPE_HOLIDAY')
            },
            {
                value: 1,
                title: $translate.instant('TYPE_SICKNESS')
            },
            {
                value: 2,
                title: $translate.instant('TYPE_UNAUTHORISED')
            },
            {
                value: 3,
                title: $translate.instant('TYPE_MATERNITY_PATERNITY_LEAVE')
            },
            {
                value: 4,
                title: $translate.instant('TYPE_COMPASSIONATE')
            },
            {
                value: 5,
                title: $translate.instant('TYPE_OTHER')
            },
        ];

        $scope.$watch('vm.data.startDate', calculatePeriod);
        $scope.$watch('vm.data.endDate', calculatePeriod);
        $scope.$watch('vm.data.startDateMeridiem', calculatePeriod);
        $scope.$watch('vm.data.endDateMeridiem', calculatePeriod);

        function changeUser(userSelect) {
            if (userSelect.selected) {
                vm.userSelectModel.selected.approver = {
                    href: userSelect.selected.object.getLink('absenceApprover')
                }
                vm.data._links.user.href = userSelect.selected.href;
            } else {
                vm.data._links.user.href = null;
                vm.userSelectModel.selected = setSelectedToCurrentUser()
            }
            vm.submitButtonText = getSubmitButtonText();
        }

        function createAction() {
            var data = angular.copy(vm.data);
            data.startDate = moment(data.startDate).format('YYYY-MM-DD');
            data.endDate = moment(data.endDate).format('YYYY-MM-DD');

            return crudService.create(
                vm.absenceEntriesCollectionResponse.getLink('create'),
                data,
                $state.get('^.details.absence-entry'),
                'resource',
                'ABSENCE_PLANNER'
            );
        }

        function updateAction() {
            var data = angular.copy(vm.data);
            data.startDate = moment(data.startDate).format('YYYY-MM-DD');
            data.endDate = moment(data.endDate).format('YYYY-MM-DD');

            return crudService.update(
                vm.absenceEntryResponse.getLink('edit'),
                data,
                $state.get('^'),
                $state.get('^.^.^'),
                'ABSENCE_PLANNER',
                false
            );
        }

        function getDefaults() {
            return {
                id: vm.absenceEntryResponse.id || null,
                summary: vm.absenceEntryResponse.summary || null,
                type: vm.absenceEntryResponse.type > -1 ? vm.absenceEntryResponse.type : null,
                startDate: vm.absenceEntryResponse.startDate ? new Date(vm.absenceEntryResponse.startDate) : null,
                endDate: vm.absenceEntryResponse.endDate ? new Date(vm.absenceEntryResponse.endDate) : null,
                startDateMeridiem: vm.absenceEntryResponse.startDateMeridiem ? vm.absenceEntryResponse.startDateMeridiem : 'AM',
                endDateMeridiem: vm.absenceEntryResponse.endDateMeridiem ? vm.absenceEntryResponse.endDateMeridiem : 'AM',
                period: null,
                _links: {
                    user: vm.absenceEntryResponse.id ?  vm.absenceEntryResponse._links.user : { href: null }
                }
            };
        }

        function calculatePeriod() {
            var period = null;

            if (vm.data.startDate) {
                vm.datePickerOptionsEndDate = { minDate: vm.data.startDate };
            } else {
                vm.datePickerOptionsEndDate = {};
            }

            if (vm.data.endDate) {
                vm.datePickerOptionsStartDate = { maxDate: vm.data.endDate };
            } else {
                vm.datePickerOptionsStartDate = {};
            }


            if (vm.data.startDate && vm.data.endDate && vm.data.startDateMeridiem && vm.data.endDateMeridiem) {
                period = dateTimeService.calculateWorkingDays(
                    vm.data.startDate,
                    vm.data.endDate,
                    vm.publicHolidaysCollectionResponse,
                    vm.data.startDateMeridiem,
                    vm.data.endDateMeridiem
                )
            }

            vm.data.period = period;
        }

        function setSelectedToCurrentUser() {
            return vm.departmentResponse ? {
                href: vm.user.getLink('self'),
                title: vm.user.name,
                object: vm.user,
                approver: {
                    href: vm.departmentResponse.getLink('absence-approver')
                }
            } : null

        }

        function getSubmitButtonText () {
            var text = 'SUBMIT_FOR_APPROVAL';
            if (vm.userSelectModel.selected &&
                vm.userSelectModel.selected.approver &&
                vm.userSelectModel.selected.approver.href === vm.user._links.self.href ||
                vm.userManager.hasPermission('USER_PERMISSION_ABSENCE_ADMIN')) {
                text = 'SAVE';
            }

            return text;
        }
    }
})();
