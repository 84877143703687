(function() {
    'use strict';

    angular
        .module('elogbooksDirectives')
        .directive('elbFinanceDashboardWidget', ElbFinanceDashboardWidget);

    ElbFinanceDashboardWidget.$inject = ['dashboardService'];

    function ElbFinanceDashboardWidget(dashboard) {
        return {
            restrict: 'AE',
            scope: {
                model: '=ngModel'
            },
            templateUrl: '/modules/directives/elb-finance-dashboard-widget/elb-finance-dashboard-widget.directive.html',
            link: function($scope) {

                $scope.toggleStat = function() {
                    $scope.model.enabled = !$scope.model.enabled;
                    dashboard.statToggled($scope.model);
                }

                if ($scope.model.chartType && $scope.model.chartType === 'multiBarChart') {
                    $scope.options = {
                        chart: {
                            type: 'multiBarChart',
                            height: 210,
                            margin: {
                                top: 20,
                                right: 0,
                                bottom: 50,
                                left: 100
                            },
                            stacked: true,
                            showControls: false,
                            yAxis: {
                                tickFormat: $scope.model.tickFormatY
                            },
                            xAxis: {
                                tickFormat: $scope.model.tickFormatX
                            },
                            reduceXTicks: false,
                            showLegend: false,
                            tooltip: {
                                contentGenerator: $scope.model.tooltipContentGenerator
                            }
                        }
                    };
                }
            }
        }
    }
})();
