(function () {
    'use strict';

    angular
        .module('elogbooks.common.quote-requests')
        .controller('QuoteReassignOperativeController', QuoteReassignOperativeController);

    QuoteReassignOperativeController.$inject = [
        'apiClient',
        '$state',
        'messenger',
        'quoteResponse',
        'formData',
        'scopeData'
    ];

    function QuoteReassignOperativeController (
        apiClient,
        $state,
        messenger,
        quoteResponse,
        formData,
        scopeData
    ) {

        var vm = this;
        vm.quoteResponse = quoteResponse;

        angular.extend(vm, scopeData);

        vm.submit = submitAction;
        vm.changeOperative = changeOperative;
        vm.noteRequired = false;
        vm.data = formData;
        vm.operativeSelectModel.onSelect = vm.operativeSelectModel.onRemove = vm.changeOperative;

        function submitAction () {
            if (vm.data.currentOperative
                && vm.data.currentOperativeLink === vm.data._links.operative.href) {
                return;
            }

            var currentState = $state.current.name;
            var parentListState = currentState.lastIndexOf('.list') > -1 ? currentState.substring(0, currentState.lastIndexOf('.list') + 5) : true;
            var reloadState = parentListState;

            return apiClient.create(vm.quoteResponse.getLink(vm.action), vm.data).then(function (response) {
                if (response) {
                    return $state.go('^', {}, { reload: reloadState }).then(function () {
                        messenger.success(vm.translation + '_SUCCESS');
                    });
                }

                messenger.error('REQUEST_ERROR');
            });
        }

        function changeOperative () {
            if (
                vm.operativeSelectModel.selected
                && typeof vm.operativeSelectModel.selected !== 'undefined'
            ) {
                vm.data._links.operative = vm.operativeSelectModel.selected;
            } else {
                vm.operativeSelectModel.selected = null;
                delete vm.data._links.operative;
            }
        }
    }
})();
