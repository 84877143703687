(function () {
    'use strict';

    angular
        .module('elogbooks.user.sites')
        .config(registerRoutesAudits);

    function registerRoutesAudits($stateProvider) {
        $stateProvider
            .state('dashboard.user.sites.list.details.audits', {
                url: '/audits?auditPage&auditLimit&auditOrder&auditId&auditSummary&auditReference&auditType&auditStatus&createdAt&createdAtEnd&generatedForDate&generatedForDateEnd&completedAt&completedAtEnd&completionDueAt&completionDueAtEnd&nextOccurrence&nextOccurrenceEnd&serviceProvider&operative',
                parent: 'dashboard.user.sites.list.details',
                views: parseBook({
                    'elogbooks-right@dashboard.user.sites.list.details': {
                        templateUrl: '/modules/common/audit/list/audits.html',
                        controller: 'AuditListController',
                        controllerAs: 'vm'
                    }
                }),
                resolve: {
                    auditCollectionResponse: function ($stateParams, apiClient, siteResponse) {

                        var isAuditPage =  ($stateParams.auditType === 'audit' && ($stateParams.auditStatus === 'all' || $stateParams.auditStatus[0] === 'all'));

                        var serviceProvider = null;
                        if ($stateParams.serviceProvider){
                            serviceProvider = JSON.parse($stateParams.serviceProvider).v;
                        }

                        var operative = null;
                        if ($stateParams.operative){
                            operative = JSON.parse($stateParams.operative).v;
                        }

                        return apiClient.getPage(siteResponse.getLink('audits'), angular.extend({}, $stateParams, {
                            page: $stateParams.auditPage,
                            limit: $stateParams.auditLimit,
                            order: $stateParams.auditOrder,
                            id: $stateParams.auditId,
                            summary: $stateParams.auditSummary,
                            reference: $stateParams.auditReference,
                            'status[]':isAuditPage ? ['audit_draft', 'audit_created', 'audit_started'] : $stateParams.auditStatus,
                            type: $stateParams.auditType,
                            site: siteResponse.id,
                            createdAt: $stateParams.createdAt,
                            createdAtEnd: $stateParams.createdAtEnd,
                            generatedForDate: $stateParams.generatedForDate,
                            generatedForDateEnd: $stateParams.generatedForDateEnd,
                            completedAt: $stateParams.completedAt,
                            completedAtEnd: $stateParams.completedAtEnd,
                            completionDueAt: $stateParams.completionDueAt,
                            completionDueAtEnd: $stateParams.completionDueAtEnd,
                            nextOccurrence: $stateParams.nextOccurrence,
                            nextOccurrenceEnd: $stateParams.nextOccurrenceEnd,
                            serviceProvider: serviceProvider,
                            operative: operative
                        }));
                    },
                    sitesCollection: function (user) {
                        return user.getResource('sites');
                    },
                    serviceProviderCollectionResponse: function($stateParams, apiClient, user, userManager, selectedSiteResponse) {
                        if (!user.getLink('serviceproviders')) {
                            return null;
                        }

                        var params = {};
                        if ($stateParams.serviceProviders) {
                            params.id = $stateParams.serviceProviders[0];
                        }

                        if (selectedSiteResponse) {
                            params.siteId = selectedSiteResponse.id;
                        }

                        if (user.getLink('serviceproviders')) {
                            return apiClient.get(user.getLink('serviceproviders'), params, 'medium').then(function(response) {
                                return response || apiClient.noResourceFound();
                            });
                        }
                    },
                    operativeCollectionResponse: function (rootResourceResponse, apiClient) {
                        if (rootResourceResponse.getLink('operatives')) {
                            return apiClient.get(rootResourceResponse.getLink('operatives')).then(function (response) {
                                return response;
                            });
                        } else {
                            return null;
                        }
                    }
                },
                params: {
                    auditPage: '1',
                    auditLimit: '30',
                    options: null,
                    auditId: null,
                    auditSummary: null,
                    auditReference: null,
                    auditStatus: 'all',
                    auditType: 'audit',
                    auditOrder: 'summary',
                    createdAt: null,
                    createdAtEnd: null,
                    generatedForDate: null,
                    generatedForDateEnd: null,
                    completedAt: null,
                    completedAtEnd: null,
                    completionDueAt: null,
                    completionDueAtEnd: null,
                    nextOccurrence: null,
                    nextOccurrenceEnd: null,
                    scheduleName: null,
                    serviceProviders: {array: true},
                    serviceProvider: null,
                    operative: null,
                },
                ncyBreadcrumb: {
                    label: '{{ ::"AUDITS" | translate }}'
                }
            })
            .state('dashboard.user.sites.list.details.audits.add', {
                url: '/add/{auditResource}',
                views: parseBook({
                    'elogbooks-right@dashboard.user.sites.list.details': {
                        templateUrl: '/modules/common/audit/add-edit/audits-form.html',
                        controller: 'AuditAddEditController',
                        controllerAs: 'vm'
                    }
                }),
                resolve: {
                    siteCollectionResponse: function ($stateParams, apiClient, rootResourceResponse) {
                        return apiClient.getPage(rootResourceResponse.getLink('sites'), $stateParams);
                    },
                    serviceProviderResponse: function ($stateParams, apiClient, siteResponse) {
                        return apiClient.getPage(siteResponse.getLink('serviceproviders'));
                    },
                    auditResponse: function () {
                        return {};
                    },
                    auditTemplateResponse: function (apiClient, $stateParams) {
                        if ($stateParams.auditResource) {
                            return apiClient.get($stateParams.auditResource.decode());
                        }

                        return {};
                    },
                    templateCollectionResponse: function (rootResourceResponse, apiClient, $stateParams, siteResponse) {
                        var params = $stateParams;
                        params.site = siteResponse.getData().id;

                        return apiClient.getPage(rootResourceResponse.getLink('audits'), params);
                    },
                },
                ncyBreadcrumb: {
                    label: '{{ ::"ADD" | translate }}'
                }
            })
            .state('dashboard.user.sites.list.details.audits.details', {
                url: '/details/{auditResource}',
                parent: 'dashboard.user.sites.list.details.audits',
                abstract: true,
                views: parseBook({
                    'elogbooks-right@dashboard.user.sites.list.details': {
                        templateUrl: '/modules/common/audit/tabs.html',
                        controller: 'AuditTabsController',
                        controllerAs: 'vm'
                    }
                }),
                resolve: {
                    auditResponse: function (apiClient, $stateParams) {
                        return apiClient.get($stateParams.auditResource.decode());
                    },
                    auditTemplateVersionsResponse: function (apiClient, auditResponse) {
                        return {};
                    },
                    sectionsCollectionResponse: function (apiClient, auditResponse) {
                        return apiClient.get(auditResponse.getLink('sections')).then(function (response) {
                            return response;
                        });
                    },
                    sectionResponse: function (apiClient, $stateParams) {
                        return {}
                    },
                    locationResponse: function (apiClient, auditResponse) {
                        return apiClient.get(auditResponse._links.site.href+'/locations');
                    }
                },
                ncyBreadcrumb: {
                    label: '{{ ::$resolves().auditResponse.summary }}'
                }
            })
            .state('dashboard.user.sites.list.details.audits.details.info', {
                url: '/info',
                parent: 'dashboard.user.sites.list.details.audits.details',
                views: parseBook({
                    'tab-panel@dashboard.user.sites.list.details.audits.details': {
                        templateUrl: '/modules/common/audit/details/details.html',
                        controller: 'AuditDetailsController',
                        controllerAs: 'vm'
                    }
                }),
                ncyBreadcrumb: {
                    label: '{{ ::"INFO" | translate }}'
                }
            })
            .state('dashboard.user.sites.list.details.audits.details.info.edit', {
                url: '/edit',
                parent: 'dashboard.user.sites.list.details.audits.details.info',
                views: {
                    'tab-panel@dashboard.user.sites.list.details.audits.details': {
                        templateUrl: '/modules/common/audit/add-edit/audits-form.html',
                        controller: 'AuditAddEditController',
                        controllerAs: 'vm'
                    }
                },
                resolve: {
                    auditTemplateResponse: function () {
                        return {};
                    },
                    serviceProviderResponse: function ($stateParams, apiClient, siteResponse) {
                        return apiClient.getPage(siteResponse.getLink('serviceproviders'));
                    },
                    templateCollectionResponse: function (rootResourceResponse, apiClient, $stateParams, siteResponse) {
                        var params = $stateParams;
                        params.site = siteResponse.getData().id;

                        return apiClient.getPage(rootResourceResponse.getLink('audits'), params);
                    },
                },
                ncyBreadcrumb: {
                    label: '{{ ::"EDIT" | translate }}'
                }
            })
            .state('dashboard.user.sites.list.details.audits.details.sections', {
                url: '/sections',
                parent: 'dashboard.user.sites.list.details.audits.details.info',
                views: parseBook({
                    'tab-panel@dashboard.user.sites.list.details.audits.details': {
                        templateUrl: '/modules/common/audit/sections/list/sections.html',
                        controller: 'AuditSectionsController',
                        controllerAs: 'vm'
                    }
                }),
                ncyBreadcrumb: {
                    label: '{{ ::"AUDIT_SECTIONS" | translate }}'
                }
            })
            .state('dashboard.user.sites.list.details.audits.details.sections.add', {
                url: '/add',
                parent: 'dashboard.user.sites.list.details.audits.details.sections',
                views: parseBook({
                    'tab-panel@dashboard.user.sites.list.details.audits.details': {
                        templateUrl: '/modules/common/audit/sections/add-edit/section-form.html',
                        controller: 'AuditSectionAddEditController',
                        controllerAs: 'vm'
                    }
                }),
                resolve: {
                    parentSectionResponse: function () {
                        return {};
                    }
                },
                ncyBreadcrumb: {
                    label: '{{ ::"ADD" | translate }}'
                }
            })
            .state('dashboard.user.sites.list.details.audits.details.sections.details', {
                url: '/details/{sectionResource}',
                parent: 'dashboard.user.sites.list.details.audits.details.sections',
                views: parseBook({
                    'tab-panel@dashboard.user.sites.list.details.audits.details': {
                        templateUrl: '/modules/common/audit/sections/details/details.html',
                        controller: 'AuditSectionDetailsController',
                        controllerAs: 'vm'
                    },
                    'sub-tab-panel@dashboard.user.sites.list.details.audits.details': {
                        templateUrl: '/modules/common/audit/sections/list/sections.html',
                        controller: 'AuditSectionsController',
                        controllerAs: 'vm'
                    },
                    'questions-tab-panel@dashboard.user.sites.list.details.audits.details': {
                        templateUrl: '/modules/common/audit/questions/list/questions.html',
                        controller: 'AuditQuestionsController',
                        controllerAs: 'vm'
                    }
                }),
                resolve: {
                    sectionResponse: function (apiClient, $stateParams, auditService) {
                        auditService.auditAnswerFlashRaiseJobs = [];
                        return apiClient.get($stateParams.sectionResource.decode(), {datagroup: 'details'});
                    },
                    sectionsCollectionResponse: function (apiClient, sectionResponse) {
                        /* get child sections for this section */
                        return apiClient.get(sectionResponse.getLink('children')).then(function (response) {
                           return response;
                         });
                    },
                    questionsCollectionResponse: function (apiClient, sectionResponse) {
                         /* get questions for this section */
                        return apiClient.get(sectionResponse.getLink('questions')).then(function (response) {
                           return response;
                         });
                    }
                },
                ncyBreadcrumb: {
                    label: '{{ ::$resolves().sectionResponse.name }}'
                }
            })
            .state('dashboard.user.sites.list.details.audits.details.sections.add.subsection', {
                url: '/details/{parentSectionResponse}/add',
                parent: 'dashboard.user.sites.list.details.audits.details.sections',
                views: parseBook({
                    'tab-panel@dashboard.user.sites.list.details.audits.details': {
                        templateUrl: '/modules/common/audit/sections/add-edit/section-form.html',
                        controller: 'AuditSectionAddEditController',
                        controllerAs: 'vm'
                    },
                    'sub-tab-panel@dashboard.user.audits.list.details': {

                    }
                }),
                resolve: {
                    sectionResponse: function () {
                        return {};
                    },
                    parentSectionResponse: function (apiClient, $stateParams) {
                        return apiClient.get($stateParams.parentSectionResponse.decode());
                    }
                },
                ncyBreadcrumb: {
                    label: '{{ ::"ADD" | translate }}'
                }
            })
            .state('dashboard.user.sites.list.details.audits.details.sections.details.edit', {
                url: '/edit',
                parent: 'dashboard.user.sites.list.details.audits.details.sections.details',
                views: {
                    'tab-panel@dashboard.user.sites.list.details.audits.details': {
                        templateUrl: '/modules/common/audit/sections/add-edit/section-form.html',
                        controller: 'AuditSectionAddEditController',
                        controllerAs: 'vm'
                    }
                },
                resolve: {
                    parentSectionResponse: function (apiClient, sectionResponse) {
                        if (typeof sectionResponse._links.parent != 'undefined') {
                            return apiClient.get(sectionResponse.getLink('parent')).then(function (response) {
                                return response;
                            });
                        } else {
                            return {};
                        }
                    }
                },
                ncyBreadcrumb: {
                    label: '{{ ::"EDIT" | translate }}'
                }
            })
            .state('dashboard.user.sites.list.details.audits.details.sections.details.add', {
                url: '/add-question',
                parent: 'dashboard.user.sites.list.details.audits.details.sections.details',
                views: parseBook({
                    'tab-panel@dashboard.user.sites.list.details.audits.details': {
                         templateUrl: '/modules/common/audit/questions/add-edit/question-form.html',
                        controller: 'AuditQuestionAddEditController',
                        controllerAs: 'vm'
                    },
                    'questions-tab-panel@dashboard.user.sites.list.details.audits.details': {
                    }
                }),
                resolve: {
                    questionResponse: function (apiClient, $stateParams) {
                        return {};
                    },
                    answerOptionsResponse: function (apiClient, $stateParams) {
                        return {};
                    },
                    config: function (userManager) {
                        var can = {
                            canCreateAnswerOption: false,
                            canSeeAnswerOption: false
                        };

                        if (userManager.hasPermission('site_permission_create_audit_answer_options')) {
                            can.canCreateAnswerOption = true;
                        } else {
                            can.canCreateAnswerOption = false;
                        }

                        if (userManager.hasPermission('site_permission_view_audit_answer_options')) {
                            can.canSeeAnswerOption = true;
                        } else {
                            can.canSeeAnswerOption = false;
                        }
                        return can;
                    }
                },
                ncyBreadcrumb: {
                    label: '{{ ::"ADD" | translate }}'
                }
            })
            .state('dashboard.user.sites.list.details.audits.details.sections.details.question', {
                url: '/question/{questionResource}',
                parent: 'dashboard.user.sites.list.details.audits.details.sections.details',
                views: parseBook({
                    'tab-panel@dashboard.user.sites.list.details.audits.details': {
                        templateUrl: '/modules/common/audit/questions/details/details.html',
                        controller: 'AuditQuestionDetailsController',
                        controllerAs: 'vm'
                    },
                   'questions-tab-panel@dashboard.user.sites.list.details.audits.details': {
                        templateUrl: '/modules/common/audit/questions/answer-options/list/answer-options.html',
                        controller: 'AuditAnswerOptionsController',
                        controllerAs: 'vm'
                    }
                }),
                resolve: {
                    questionResponse: function (apiClient, $stateParams) {
                        return apiClient.get($stateParams.questionResource.decode());
                    },
                    questionAnswerResponse: function (apiClient, questionResponse) {
                        if(questionResponse._links.answer) {
                            return apiClient.get(questionResponse.getLink('answer')).then(function (response) {
                               return response;
                             });
                        }
                    },
                    answerOptionsResponse: function (apiClient, questionResponse) {
                         return apiClient.get(questionResponse.getLink('answerOptions')).then(function (response) {
                           return response;
                         });
                    }
                },
                ncyBreadcrumb: {
                    label: '{{ ::$resolves().questionResponse.summary }}'
                }
            })
            .state('dashboard.user.sites.list.details.audits.details.sections.details.question.add', {
                url: '/add-answer-option',
                parent: 'dashboard.user.sites.list.details.audits.details.sections.details.question',
                views: parseBook({
                    'questions-tab-panel@dashboard.user.sites.list.details.audits.details': {
                        templateUrl: '/modules/common/audit/questions/answer-options/add-edit/answer-option-form.html',
                        controller: 'AuditAnswerOptionAddEditController',
                        controllerAs: 'vm'
                    }
                }),
                resolve: {
                    answerOptionResponse: function (apiClient, $stateParams) {
                        return {};
                    },
                },
                ncyBreadcrumb: {
                    label: '{{ ::"ADD" | translate }}'
                }
            })
            .state('dashboard.user.sites.list.details.audits.details.sections.details.question.edit', {
                url: '/edit',
                parent: 'dashboard.user.sites.list.details.audits.details.sections.details.question',
                views: {
                    'tab-panel@dashboard.user.sites.list.details.audits.details': {
                        templateUrl: '/modules/common/audit/questions/add-edit/question-form.html',
                        controller: 'AuditQuestionAddEditController',
                        controllerAs: 'vm'
                    }
                },
                resolve: {
                    config: function (userManager) {
                        if (userManager.hasPermission('site_permission_create_audit_answer_options')) {
                            return {
                                canCreateAnswerOption: true
                            };
                        } else {
                            return {
                                canCreateAnswerOption: false
                            };
                        }
                    }
                },
                ncyBreadcrumb: {
                    label: '{{ ::"EDIT" | translate }}'
                }
            })
            .state('dashboard.user.sites.list.details.audits.details.sections.details.question.answer-option', {
                url: '/answer-option/{answerOptionResource}/edit',
                parent: 'dashboard.user.sites.list.details.audits.details.sections.details.question',
                views: {
                    'questions-tab-panel@dashboard.user.sites.list.details.audits.details': {
                        templateUrl: '/modules/common/audit/questions/answer-options/add-edit/answer-option-form.html',
                        controller: 'AuditAnswerOptionAddEditController',
                        controllerAs: 'vm'
                    }
                },
                resolve: {
                    answerOptionResponse: function (apiClient, $stateParams) {
                        return apiClient.get($stateParams.answerOptionResource.decode());
                    }
                },
                ncyBreadcrumb: {
                    label: '{{ ::"EDIT" | translate }}'
                }
            });
    };
})();
