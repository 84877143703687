angular.module('elogbooksServices').service('documentTypeService', function () {

    'use strict';

    var self = this;
    self.changeRequiredDocumentType = changeRequiredDocumentType;
    self.removeDocumentType = removeDocumentType;

    function changeRequiredDocumentType(vm) {
        var isAlreadySelected = false;

        if (vm.selectedRequiredDocumentTypeModel.selected && vm.selectedRequiredDocumentTypeModel.selected.object) {
            angular.forEach(vm.selectedRequiredDocumentTypes, function (value) {
                if (value._links.self.href === vm.selectedRequiredDocumentTypeModel.selected.object.getLink('self')) {
                    isAlreadySelected = true;
                }
            });

            if (!isAlreadySelected) {
                vm.selectedRequiredDocumentTypes.push(vm.selectedRequiredDocumentTypeModel.selected.object);
            }

            vm.selectedRequiredDocumentTypeModel.selected = null;
        } else {
            if (vm.certificateRequiredDocType) {
                angular.forEach(vm.selectedRequiredDocumentTypes, function (value) {
                    if (value.name === 'Certificate') {
                        isAlreadySelected = true;
                    }
                });
            }

            if (!isAlreadySelected) {
                vm.selectedRequiredDocumentTypes.push(vm.certificateRequiredDocType);
            }
        }
    }

    function removeDocumentType(vm, documentType) {
        angular.forEach(vm.selectedRequiredDocumentTypes, function (value, index) {
            if (value._links.self.href === documentType._links.self.href) {
                vm.selectedRequiredDocumentTypes.splice(index, 1);
            }
        });
    }
});
