/**
 * Asset and SubAsset Service
 */
angular.module('elogbooksServices').service('assetService', function (apiClient, $q) {
    this.update = updateAsset;
    this.create = createAsset;
    this.delete = deleteAsset;
    this.createSubAsset = createSubAsset;
        
    /**
     * Update existing asset
     * 
     * @param {object} asset - Asset entity
     * @returns {object} promise
     */
    function updateAsset(asset) {
        var deferred = $q.defer();
        var data = prepareAsset(asset);

        delete data._links.frequencies;
        delete data._links.yearlyplanner;
        delete data._links.conditions;
        delete data._links.subassets;
        delete data._links.serviceroutines;
        delete data._links.site; // Once set, the site cannot be changed
        
        apiClient.update(data._links.self.href, data).then(function (isSuccess) {
            if (isSuccess) {
                return deferred.resolve();
            } else {
                return deferred.reject();
            }
        });

        return deferred.promise;
    }

    /**
     * Create asset
     * 
     * @param {string} site - link to site 
     * @param {object} asset - Asset entity
     * @returns {object} promise
     */
    function createAsset(siteUrl, asset) {
        var deferred = $q.defer();
        var data = prepareAsset(asset);

        // Discover the create asset url for the site
        apiClient.get(siteUrl).then(function (response) {
            apiClient.get(response.getLink('assets')).then(function (response) {
                // Create the asset
                apiClient.create(response.getLink('create'), data).then(function (isSuccess) {
                    if (isSuccess) {
                        return deferred.resolve();
                    } else {
                        return deferred.reject();
                    }
                });
            });
        });

        return deferred.promise;
    }
    
    /**
     * Create SubAsset
     * 
     * @param {object} asset - Asset entity
     * @param {object} subasset - SubAsset entity 
     * @returns {object} promise
     */
    function createSubAsset(asset, subasset) {
        var deferred = $q.defer();
        var data = prepareAsset(subasset);

        apiClient.create(asset._links.subassets.href, data).then(function (isSuccess) {
            if (isSuccess) {
                return deferred.resolve();
            } else {
                return deferred.reject();
            }
        });

        return deferred.promise;
    }
    
    /**
     * Delete asset
     * 
     * @param {object} asset - Asset entity
     * @returns {object} promise
     */
    function deleteAsset(asset) {
        var deferred = $q.defer();

        apiClient.delete(asset._links.delete.href).then(function (isSuccess) {
            if (isSuccess) {
                return deferred.resolve();
            } else {
                return deferred.reject();
            }
        });

        return deferred.promise;
    }

    /**
     * Prepare the asset for updating 
     * @returns {object} Copy of the asset, dates converted.
     */
    function prepareAsset(asset) {
        var data = angular.copy(asset);

        if (null !== data.endOfLife) {
            data.endOfLife = data.endOfLife.toRFC3339String();
        }
        // For subassets strip out the asset link on updates
        if (data._links && data._links.asset) {
            delete data._links.asset;
        }

        // Clean up links (remove null href's and titles)
        angular.forEach(data._links, function (value, index) {
            if (data._links[index].href === null) {
                delete data._links[index];
            } else if (data._links[index].title) {
                delete data._links[index].title;
            }
        });

        return data;
    }

});
