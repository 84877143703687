/**
 * contactInformation Service
 */
angular.module('elogbooksServices').service('contactInformationService', function () {
    this.processContactInformation = processContactInformation;

    /**
     * process the contact information
     *
     * @param {object} data - Data
     * @returns object
     */
    function processContactInformation(data) {
        var contactInformation = {
            fields: [],
            addresses: [],
        };

        if (data.address) {
            contactInformation.addresses.push(data.address);
        }

        if (data.email) {
            contactInformation.fields.push({
                type: 'email',
                value: data.email,
            });
        }

        return contactInformation;
    }
});
