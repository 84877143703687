(function() {
    'use strict';

    angular
        .module('elogbooks.admin.core-clients')
        .controller('CoreClientsInfoController', CoreClientsInfoController);

    CoreClientsInfoController.$inject = ['$state', 'apiClient', 'confirmationModal', 'messenger', 'coreClientResponse'];
    function CoreClientsInfoController($state, apiClient, confirmationModal, messenger, coreClientResponse) {
        var vm = this;
        vm.coreClient = coreClientResponse;
    }
})();
