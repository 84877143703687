/**
 * Recurrence rules Service
 */
angular.module('elogbooksServices').service('recurrenceRulesService', function (lodash, $q, $filter) {
    var self = this;
    this.getRuleFrequencyAsString = getRuleFrequencyAsString;
    this.getRuleDayPrefixAsString = getRuleDayPrefixAsString;
    this.getRuleDayNameAsString = getRuleDayNameAsString;
    this.getRuleMonths = getRuleMonths;
    this.getRuleDescription = getRuleDescription;
    this.convertValue = convertValue;
    this.mapFrequency = mapFrequency;

    this.ruleUnitOptions = [
        { value: 4, title: $filter('translate')("WEEKLY") },
        { value: 5, title: $filter('translate')("MONTHLY") },
        { value: 6, title: $filter('translate')("YEARLY") }
    ];

    this.frequencies = {weekly : 4, monthly: 5, yearly: 6};
    this.ruleDayOptions = [];
    getRuleDayOptions(this.ruleDayOptions, false);
    this.ruleDayOptionsWithLastWeekDayOption = [];
    getRuleDayOptions(this.ruleDayOptionsWithLastWeekDayOption, true);

    function getRuleDayOptions(array, includeLastWeekDay) {
        array.push({ value: 1, title: $filter('translate')("MONDAY") });
        array.push({ value: 2, title: $filter('translate')("TUESDAY") });
        array.push({ value: 3, title: $filter('translate')("WEDNESDAY") });
        array.push({ value: 4, title: $filter('translate')("THURSDAY") });
        array.push({ value: 5, title: $filter('translate')("FRIDAY") });
        array.push({ value: 6, title: $filter('translate')("SATURDAY") });
        array.push({ value: 0, title: $filter('translate')("SUNDAY") });

        if (includeLastWeekDay) {
            array.push({ value: -1, title: $filter('translate')("LAST_WEEKDAY") });
        }
    }

    this.rulePrefixOptions = [
        { value: 1, title: $filter('translate')("FIRST"), subtitle: $filter('translate')("FIRST") },
        { value: 2, title: $filter('translate')("SECOND"), subtitle: $filter('translate')("SECOND") },
        { value: 3, title: $filter('translate')("THIRD"), subtitle: $filter('translate')("THIRD") },
        { value: 4, title: $filter('translate')("FOURTH"), subtitle: $filter('translate')("FOURTH") },
        { value: -1, title: $filter('translate')("LAST"), subtitle:$filter('translate')("LAST") },
        { value: 0, title: $filter('translate')("DATE"), subtitle: $filter('translate')("EACH") }
    ];

    this.ruleMonthOptions = [
        { value: 1, title: $filter('translate')("JANUARY") },
        { value: 2, title: $filter('translate')("FEBRUARY") },
        { value: 3, title: $filter('translate')("MARCH") },
        { value: 4, title: $filter('translate')("APRIL") },
        { value: 5, title: $filter('translate')("MAY") },
        { value: 6, title: $filter('translate')("JUNE") },
        { value: 7, title: $filter('translate')("JULY") },
        { value: 8, title: $filter('translate')("AUGUST") },
        { value: 9, title: $filter('translate')("SEPTEMBER") },
        { value: 10, title: $filter('translate')("OCTOBER") },
        { value: 11, title: $filter('translate')("NOVEMBER") },
        { value: 12, title: $filter('translate')("DECEMBER") }
    ];

    this.ruleDayNumberOptions = lodash.range(1, 32);
    this.ruleIntervalOptions = lodash.range(1, 53);

    function getRuleFrequencyAsString(frequency, firstLetter) {
        var option = lodash.find(self.ruleUnitOptions, {value: frequency});

        return option ? (firstLetter ? option.title.substring(0,1) : option.title) : '';
    }

    function getRuleDayPrefixAsString(dayPrefix) {
        var option = lodash.find(self.rulePrefixOptions, {value: dayPrefix});

        return option && dayPrefix != 0 ? option.title : '';
    }

    function getRuleDayNameAsString(dayNumber) {
        var option = lodash.find(self.ruleDayOptions, {value: dayNumber});

        return option ? option.title : '';
    }

    function getRuleMonths(byMonth) {
        var months = [];

        for (var i = 0; i < byMonth.length; i++) {
            var option = lodash.find(self.ruleMonthOptions, {value: parseInt(byMonth[i])});

            if (option) {
                months.push(option.title);
            }
        }

        return months;
    }

    /**
     * Return a string representation of the rule
     *  eg. "FIRST MONDAY" or "18 OF DECEMBER"
     * @param {object} rule
     * @returns {string}
     */
    function getRuleDescription(rule) {
        var string = '';
        if (rule.bySetPosition && rule.bySetPosition.length === 1 && rule.bySetPosition[0] === -1) {
            string += $filter('translate')("LAST_WEEKDAY");
        } else {
            for(i in rule.byDay) {
                var day = rule.byDay[i];
                string += getRuleDayPrefixAsString(day[0]) + ' ' + getRuleDayNameAsString(day[1]) + ' ';
            }
        }

        for(i in rule.byMonthDay) {
            var monthDay = rule.byMonthDay[i];
            string += monthDay + ' ';
        }
        if (rule.byMonth.length > 0) {
            string += ' '+$filter('translate')("OF")+' ';
            var months = getRuleMonths(rule.byMonth);
            for (i in months) {
                var month = months[i];
                string += month + ' ';
            }
        }

        return string;
    }

    function convertValue(options, num) {
        return lodash.find(options, ['value', num]).title;
    }

    function mapFrequency(frequency) {
        switch(frequency) {
            case 'DAILY':
                frequency = 'D';
                break;
            case 'WEEKLY':
                frequency = 'W';
                break;
            case 'MONTHLY':
                frequency = 'M';
                break;
            case 'YEARLY':
                frequency = 'Y';
                break;
        }

        return frequency;
    }

});
