angular.module('elogbooksDirectives').directive('elbHelpdeskAlarms', function ($rootScope) {
    return {
        restrict: 'E',
        templateUrl: '/modules/directives/helpdesk-alarms/helpdesk-alarms.html',
        scope: {
            stats: '=ngModel',
        },
        controller: ['$scope', function($scope) {
            $scope.stateName = $scope.$root.currentState;

            // The state to check to maker active
            $rootScope.$on('$stateChangeSuccess', function() {
                $scope.stateName = $scope.$root.currentState;
            });
        }]
    }
});
