angular.module('elogbooksDirectives').directive('elbRating', function () {
    return {
        restrict: 'E',
        templateUrl: '/modules/directives/elb-rating/elb-rating.html',
        scope: {
            score: '=',
            total: '@',
            details: '@',
            list: '@'
        },
        controller: function ($scope) {
            $scope.score = parseFloat($scope.score);
            $scope.stars = [];

            var res = 0;
            for (var i = 0; i < 5; i++) {
                res = parseFloat(($scope.score - i).toFixed(1));

                if (res <= 0.2) {
                    $scope.stars.push({style: ''});
                } else if (res > 0.2 && res <= 0.7) {
                    $scope.stars.push({style: 'half'});
                } else if (res <= $scope.score) {
                    $scope.stars.push({style: 'filled'});
                } else {
                    $scope.stars.push({style: ''});
                }
            }
        }
    };
});
