(function () {
    'use strict';

    angular
        .module('elogbooks.common.jobs')
        .controller('CommonRemedialsController', CommonRemedialsController);

    CommonRemedialsController.$inject = ['jobResponse', 'siteResponse', 'jobRemedialsCollection', '$state'];

    function CommonRemedialsController(jobResponse, siteResponse, jobRemedialsCollection, $state) {

        var vm = this;

        vm.canCreateJobs = !!siteResponse.getLink('create-reactive-job');
        vm.canCreateQuotes = !!siteResponse.getLink('create-quoterequest');
        vm.canCreateActions = !!siteResponse.getLink('create-actions');
        vm.jobRemedialsCollection = jobRemedialsCollection;
        vm.count = jobRemedialsCollection.count;
        vm.routeParams = {
            siteResource: siteResponse.getLink('self').encode(),
            isRemedial: 1,
            sourceJob: jobResponse.getLink('self').encode()
        };
        vm.routeParams.sourceJobAsset = jobResponse._links.asset ? jobResponse._links.asset.href.encode() : null;
        vm.routeParams.sourceJobSubasset = jobResponse._links.subasset ? jobResponse._links.subasset.href.encode() : null;

        if ($state.current.parent === 'dashboard.user.jobs.list.details') {
            vm.addJobRoute = 'dashboard.user.jobs.list.add.site';
            vm.addQuoteRoute = 'dashboard.user.quote-requests.list.add.site';
            vm.addActionRoute = 'dashboard.user.actions.manage-actions.list.add.site' ;
        } else {
            vm.addJobRoute = 'dashboard.user.sites.list.details.jobs.list.add.site';
            vm.addQuoteRoute = 'dashboard.user.sites.list.details.quote-requests.list.add.site' ;
            vm.addActionRoute = 'dashboard.user.sites.list.details.actions.manage-actions.list.add.details';
        }
    }
})();
