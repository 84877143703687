(function () {
    angular
        .module('elogbooksDirectives')
        .directive('elbPatrolDragEvent', elbPatrolDragEvent);

    elbPatrolDragEvent.$inject = ['lodash'];

    function elbPatrolDragEvent (lodash) {
        var offsetMinutes = 0;
        var offsetHours = 0;
        var draggedElement = null;
        var timeLabel = null;

        function getPlannerScope(element) {
            var scope = element.scope();

            if (lodash.has(scope, '$parent.$parent.patrol')) {
                return scope.$parent.$parent;
            }

            return false;
        }

        return {
            restrict: 'AE',
            link: function(scope, elem) {
                elem.draggable({
                    containment:'#patrolEvents',
                    axis: "x",
                    grid: [5, 0],
                    // For performance we need to avoid using droppable on cells and fetch
                    // drop container via mouse position
                    start: function (event) {
                        angular.element(event.target).attr('id', 'dragging');

                        draggedElement = angular.element(event.target);
                        timeLabel = angular.element(document.getElementById('timeLabel' + draggedElement.data().eventId));
                    },
                    drag: function(event) {
                        var patrolContainer = angular.element(document.getElementById('patrolEvents'));;

                        var dropContainer = angular.element(document.getElementsByClassName('event-drop-container'));

                        if (!getPlannerScope(dropContainer).canEditTemplate) {
                            patrolContainer.draggable('disable');
                        }

                        var dropContainerWidth = dropContainer.width();

                        //hide all tooltips so that they dont appear when dragging over
                        var tooltip = angular.element(document.getElementsByClassName('tooltip'));
                        tooltip.hide();

                        //calculate hours based on offset
                        offsetHours = Math.floor((draggedElement.offset().left - patrolContainer.offset().left) / dropContainerWidth);

                        //get the decimal percentage left from offset hour calculation
                        var minuteOffsetDecimalPercentage = ((draggedElement.offset().left - patrolContainer.offset().left) / dropContainerWidth) - offsetHours;

                        var offsetPercentage = Math.round(minuteOffsetDecimalPercentage * 100);

                        offsetMinutes = Math.round((offsetPercentage / 100) * 60);

                        //set the time label position to follow the drag container and chose the right position
                        if (draggedElement.offset().left > patrolContainer.width() / 2) {
                            //set to left
                            timeLabel.offset({left:draggedElement.offset().left - timeLabel.width()});
                        } else {
                            //set to right
                            timeLabel.offset({left:draggedElement.offset().left + draggedElement.width()});
                        }

                        timeLabel[0].innerText = moment(offsetHours + ':' + offsetMinutes, 'HH:mm').format('HH:mm');

                        timeLabel.show();
                    },
                    stop: function(event) {
                        var dropContainer = angular.element(document.getElementById(offsetHours));

                        if (!dropContainer) {
                            return false;
                        }
                        var scope = getPlannerScope(dropContainer);
                        draggedElement.attr('id', 'stopdragging');

                        timeLabel.hide();

                        if (draggedElement.data('patrol-id') && draggedElement.data('event-id') && offsetHours !== undefined && offsetMinutes !== undefined) {
                            //move element to full 5 min intervals
                            if (offsetMinutes % 5 > 0) {
                                angular.element(event.target).css({'left':event.target.offsetLeft - (offsetMinutes % 5)});
                                offsetMinutes -= (offsetMinutes % 5);
                            }

                            scope.moveEvent(
                                draggedElement.data('patrol-id'),
                                draggedElement.data('event-id'),
                                offsetHours,
                                offsetMinutes
                            );
                        }
                    }
                });
            }
        };
    }
})();