(function() {
    'use strict';

    angular
        .module('elogbooks.common.quote-requests')
        .controller('CommonQuoteRequestDetailsContactsController', CommonQuoteRequestDetailsContactsController);

    CommonQuoteRequestDetailsContactsController.$inject = ['quoteRequestContactCollectionResponse'];

    function CommonQuoteRequestDetailsContactsController(quoteRequestContactCollectionResponse) {
        var vm = this;
        vm.quoteRequestContactCollectionResponse = quoteRequestContactCollectionResponse;
    }
})();
