(function() {
    'use strict';

    angular
        .module('elogbooks')
        .config(Translations);

    function Translations($translateProvider) {
        $translateProvider.translations('nl', {
            USER_PERMISSIONS: 'Gebruikersbevoegdheden',
            USER_PERMISSION_MANAGE_ASSET_TYPES: 'Beheer Assettypes',
            USER_PERMISSION_MANAGE_ASSOCIATE_TYPES: 'Beheer Associate Types',
            USER_PERMISSION_MANAGE_CERTIFICATE_TYPES: 'Beheer Certificaattypes',
            USER_PERMISSION_MANAGE_CHARGE_TYPES: 'Beheer Charge Types',
            USER_PERMISSION_MANAGE_CONTRACT_TYPES: 'Beheer Contracttypes',
            USER_PERMISSION_MANAGE_JOB_REQUISITES: 'Beheer Opdrachtvereisten',
            USER_PERMISSION_MANAGE_JOB_GROUPS: 'Beheer Opdrachtgroepen',
            USER_PERMISSION_MANAGE_LETTERS: 'Beheer Brieven',
            USER_PERMISSION_MANAGE_METER_INFO: 'Beheer Meterinformatie',
            USER_PERMISSION_MANAGE_PRIORITIES: 'Beheer Prioriteiten',
            USER_PERMISSION_MANAGE_REGIONS: 'Beheer Regio\'s',
            USER_PERMISSION_MANAGE_ROLES: 'Beheer Rollen',
            USER_PERMISSION_MANAGE_SATISFACTION_SURVEYS: 'Beheer Tevredenheidsonderzoeken',
            USER_PERMISSION_MANAGE_SECTORS: 'Beheer Sectoren',
            USER_PERMISSION_MANAGE_SERVICE_PROVIDERS: 'Beheer Leveranciers',
            USER_PERMISSION_MANAGE_SERVICE_PROVIDER_GROUPS: 'Beheer Leveranciergroepen',
            USER_PERMISSION_MANAGE_SERVICE_ROUTINES: 'Beheer Onderhoudsroutines',
            USER_PERMISSION_MANAGE_SETTINGS: 'Beheer Instellingen',
            USER_PERMISSION_MANAGE_SITES: 'Beheer Objecten',
            USER_PERMISSION_MANAGE_SITE_GROUPS: 'Beheer Objectgroepen',
            USER_PERMISSION_MANAGE_SITE_NOTE_TYPES: 'Beheer Object-opmerkingentypes',
            USER_PERMISSION_MANAGE_SITE_RELATIONSHIPS: 'Beheer Objectrelaties',
            USER_PERMISSION_MANAGE_SURVEYS: 'Beheer Onderzoeken',
            USER_PERMISSION_MANAGE_TAGS: 'Beheer Labels',
            USER_PERMISSION_MANAGE_TRADE_TYPES: 'Beheer Handesltypen',
            USER_PERMISSION_MANAGE_USERS: 'Beheer Gebruikers',
            USER_PERMISSION_MANAGE_DUTY_MANAGEMENT_SCHEDULES: 'Beheer Taakbeheer Overzichten',
            USER_PERMISSION_MANAGE_MASTER_TASKS: 'Beheer Hoofdopdrachten',
            USER_PERMISSION_VIEW_ASSET_TYPES: 'Bekijk Assettypes',
            USER_PERMISSION_VIEW_ASSOCIATE_TYPES: 'Bekijk Associate Types',
            USER_PERMISSION_VIEW_CERTIFICATE_TYPES: 'Bekijk Certificaattypes',
            USER_PERMISSION_VIEW_CHARGE_TYPES: 'Bekijk Charge Types',
            USER_PERMISSION_VIEW_CONTRACT_TYPES: 'Bekijk Contracttypes',
            USER_PERMISSION_VIEW_JOB_REQUISITES: 'Bekijk Opdrachtvereisten',
            USER_PERMISSION_VIEW_JOB_GROUPS: 'Bekijk Opdrachtgroepen',
            USER_PERMISSION_VIEW_LETTERS: 'Bekijk Brieven',
            USER_PERMISSION_VIEW_METER_INFO: 'Bekijk Meterinformatie',
            USER_PERMISSION_VIEW_PRIORITIES: 'Bekijk Prioriteiten',
            USER_PERMISSION_VIEW_REGIONS: 'Bekijk Regio\'s',
            USER_PERMISSION_VIEW_ROLES: 'Bekijk Rollen',
            USER_PERMISSION_VIEW_SATISFACTION_SURVEYS: 'Bekijk Tevredenheidsonderzoeken',
            USER_PERMISSION_VIEW_SECTORS: 'Bekijk Sectoren',
            USER_PERMISSION_VIEW_SERVICE_PROVIDERS: 'Bekijk Leveranciers',
            USER_PERMISSION_VIEW_SERVICE_PROVIDER_GROUPS: 'Bekijk Leveranciergroepen',
            USER_PERMISSION_VIEW_SERVICE_ROUTINES: 'Bekijk Onderhoudsroutines',
            USER_PERMISSION_VIEW_SETTINGS: 'Bekijk Instellingen',
            USER_PERMISSION_VIEW_SITES: 'Bekijk Objecten',
            USER_PERMISSION_VIEW_SITE_GROUPS: 'Bekijk Objectgroepen',
            USER_PERMISSION_VIEW_SITE_NOTE_TYPES: 'Bekijk Object-opmerkingentypes',
            USER_PERMISSION_VIEW_SURVEYS: 'Bekijk Onderzoeken',
            USER_PERMISSION_VIEW_TAGS: 'Bekijk Labels',
            USER_PERMISSION_VIEW_TRADE_TYPES: 'Bekijk Handesltypen',
            USER_PERMISSION_VIEW_USERS: 'Bekijk Gebruikers',
            USER_PERMISSION_VIEW_DUTY_MANAGEMENT_SCHEDULES: 'Bekijk Duty Management Overzichten',
            USER_PERMISSION_CAN_RECEIVE_SUPPORT_MESSAGES: 'Kan ondersteuningsberichten ontvangen',
            USER_PERMISSION_CAN_CREATE_GLOBAL_AUDIT_TEMPLATES: 'Kan Globale Audit Templates aanmaken',
            USER_PERMISSION_ACCESS_SERVICE_PROVIDER_APP: 'Toegang leveranciersapp',
            USER_PERMISSION_ACCESS_CLIENT_APP: 'Toegang opdrachtgeversapp',
            USER_PERMISSION_MANAGE_SERVICE_TYPES: 'Beheer servicetypen',
            USER_PERMISSION_VIEW_SERVICE_TYPES: 'Bekijk servicetypen',
            USER_PERMISSION_MANAGE_IMPORTS: 'Manage Imports',
            USER_PERMISSION_MANAGE_EXPORTS: 'Manage Exports',
            USER_PERMISSION_MANAGE_CORE_CLIENTS: 'Manage Core Clients',
            USER_PERMISSION_KPI_TREND_EXPORT: 'KPI Trend Export',

            USER_PERMISSION_MANAGE_ACTION_TYPES: 'Manage Action Types',

            USER_PERMISSION_VIEW_ACTION_TYPES: 'View Action Types',

            USER_PERMISSION_MANAGE_METER_INFO_DETAIL: ' ',
            USER_PERMISSION_VIEW_METER_INFO_DETAIL: ' ',
            USER_PERMISSION_CAN_CREATE_GLOBAL_AUDIT_TEMPLATES_DETAIL: ' ',
            USER_PERMISSION_ACCESS_SERVICE_PROVIDER_APP_DETAIL: ' ',
            USER_PERMISSION_ACCESS_CLIENT_APP_DETAIL: ' ',
            USER_PERMISSION_MANAGE_BILLING_TYPES: 'Manage Billing Types',

            USER_PERMISSION_MANAGE_ACTION_TYPES_DETAIL: 'De mogelijkheid om actie types te maken, aanpassen en verwijderen',
            USER_PERMISSION_MANAGE_ASSET_TYPES_DETAIL: 'De mogelijkheid om asset types te maken, aanpassen en verwijderen',
            USER_PERMISSION_MANAGE_ASSOCIATE_TYPES_DETAIL: 'De mogelijkheid om Objectrelaties te maken, aanpassen en verwijderen',
            USER_PERMISSION_MANAGE_CERTIFICATE_TYPES_DETAIL: 'De mogelijkheid om certificaat types te maken, aanpassen en verwijderen',
            USER_PERMISSION_MANAGE_CHARGE_TYPES_DETAIL: 'The ability to create, update and delete Charge Types',
            USER_PERMISSION_MANAGE_CONTRACT_TYPES_DETAIL: 'De mogelijkheid om contract types te maken, aanpassen en verwijderen',
            USER_PERMISSION_MANAGE_JOB_REQUISITES_DETAIL: 'De mogelijkheid om opdrachtvereisten te maken, aanpassen en verwijderen',
            USER_PERMISSION_MANAGE_JOB_GROUPS_DETAIL: 'De mogelijkheid om opdracht groepen te maken, aanpassen en verwijderen',
            USER_PERMISSION_MANAGE_LETTERS_DETAIL: 'De mogelijkheid om briefsjablonen te maken, aanpassen en verwijderen',
            USER_PERMISSION_MANAGE_METERINFO_DETAIL: 'De mogelijkheid om zaken rond meter en meterstanden te maken, aanpassen en verwijderen',
            USER_PERMISSION_MANAGE_PRIORITIES_DETAIL: 'De mogelijkheid om systeem prioriteiten te maken, aanpassen en verwijderen',
            USER_PERMISSION_MANAGE_REGIONS_DETAIL: 'De mogelijkheid om regios te maken, aanpassen en verwijderen',
            USER_PERMISSION_MANAGE_ROLES_DETAIL: 'De mogelijkheid om gebruikersrollen te maken, aanpassen en verwijderen',
            USER_PERMISSION_MANAGE_SATISFACTION_SURVEYS_DETAIL: 'De mogelijkheid om tevredenheidsonderzoeken te maken, aanpassen en verwijderen',
            USER_PERMISSION_MANAGE_SECTORS_DETAIL: 'De mogelijkheid om sectoren te maken, aanpassen en verwijderen',
            USER_PERMISSION_MANAGE_SERVICE_PROVIDERS_DETAIL: 'De mogelijkheid om leveranciers te maken, aanpassen en verwijderen',
            USER_PERMISSION_MANAGE_SERVICE_PROVIDER_GROUPS_DETAIL: 'De mogelijkheid om leveranciersgroepen te maken, aanpassen en verwijderen',
            USER_PERMISSION_MANAGE_SERVICE_ROUTINES_DETAIL: 'De mogelijkheid om service routines te maken, aanpassen en verwijderen',
            USER_PERMISSION_MANAGE_SETTINGS_DETAIL: 'De mogelijkheid om systeem instellingen aan te passen',
            USER_PERMISSION_MANAGE_SITES_DETAIL: 'De mogelijkheid om objecten te maken, aanpassen en verwijderen',
            USER_PERMISSION_MANAGE_SITE_GROUPS_DETAIL: 'De mogelijkheid om objectgroepen te maken, aanpassen en verwijderen',
            USER_PERMISSION_MANAGE_SITE_NOTE_TYPES_DETAIL: 'De mogelijkheid om notitie types te maken, aanpassen en verwijderen',
            USER_PERMISSION_MANAGE_SITE_RELATIONSHIPS_DETAIL: 'De mogelijkheid om object relaties te maken, aanpassen en verwijderen',
            USER_PERMISSION_MANAGE_SURVEYS_DETAIL: 'De mogelijkheid om KPI checklijsten te maken, aanpassen en verwijderen',
            USER_PERMISSION_MANAGE_TAGS_DETAIL: 'De mogelijkheid om tags te maken, aanpassen en verwijderen',
            USER_PERMISSION_MANAGE_TRADE_TYPES_DETAIL: 'De mogelijkheid om handelstypen te maken, aanpassen en verwijderen',
            USER_PERMISSION_MANAGE_USERS_DETAIL: 'De mogelijkheid om gebruikers te maken, aanpassen en verwijderen',
            USER_PERMISSION_MANAGE_DUTY_MANAGEMENT_SCHEDULES_DETAIL: 'De mogelijkheid om uitvoerschemas te maken, aanpassen en verwijderen',
            USER_PERMISSION_MANAGE_MASTER_TASKS_DETAIL: 'De mogelijkheid om systeembeheerders zaken te maken, aanpassen en verwijderen',
            USER_PERMISSION_MANAGE_CORE_CLIENTS_DETAIL: 'The ability to create, update and delete Core Clients',

            USER_PERMISSION_VIEW_ACTION_TYPES_DETAIL: 'Mogelijkheid om actie types te bekijken',
            USER_PERMISSION_VIEW_ASSET_TYPES_DETAIL: 'Mogelijkheid om asset types te bekijken',
            USER_PERMISSION_VIEW_ASSOCIATE_TYPES_DETAIL: 'Mogelijkheid om objectrelaties te bekijken',
            USER_PERMISSION_VIEW_CERTIFICATE_TYPES_DETAIL: 'Mogelijkheid om certificaat types te bekijken',
            USER_PERMISSION_VIEW_CHARGE_TYPES_DETAIL: 'The ability to view Charge Types',
            USER_PERMISSION_VIEW_CONTRACT_TYPES_DETAIL: 'Mogelijkheid om contract types te bekijken',
            USER_PERMISSION_VIEW_JOB_REQUISITES_DETAIL: 'Mogelijkheid om opdrachtvereisten te bekijken',
            USER_PERMISSION_VIEW_JOB_GROUPS_DETAIL: 'Mogelijkheid om opdracht groepen te bekijken',
            USER_PERMISSION_VIEW_LETTERS_DETAIL: 'Mogelijkheid om brief sjablonen te bekijken',
            USER_PERMISSION_VIEW_METERINFO_DETAIL: 'Mogelijkheid om meters en meterstanden te bekijken',
            USER_PERMISSION_VIEW_PRIORITIES_DETAIL: 'Mogelijkheid om systeem prioriteiten te bekijken',
            USER_PERMISSION_VIEW_REGIONS_DETAIL: 'Mogelijkheid om regios te bekijken',
            USER_PERMISSION_VIEW_ROLES_DETAIL: 'Mogelijkheid om authorisaties te bekijken',
            USER_PERMISSION_VIEW_SATISFACTION_SURVEYS_DETAIL: 'Mogelijkheid om tevredenheidsonderzoeken te bekijken',
            USER_PERMISSION_VIEW_SECTORS_DETAIL: 'Mogelijkheid om sectoren te bekijken',
            USER_PERMISSION_VIEW_SERVICE_PROVIDERS_DETAIL: 'Mogelijkheid om leveranciers te bekijken',
            USER_PERMISSION_VIEW_SERVICE_PROVIDER_GROUPS_DETAIL: 'Mogelijkheid om leveranciersgroepen te bekijken',
            USER_PERMISSION_VIEW_SERVICE_ROUTINES_DETAIL: 'Mogelijkheid om service routines te bekijken',
            USER_PERMISSION_VIEW_SETTINGS_DETAIL: 'Mogelijkheid om systeeminstellingen te bekijken',
            USER_PERMISSION_VIEW_SITES_DETAIL: 'Mogelijkheid om objecten te bekijken',
            USER_PERMISSION_VIEW_SITE_GROUPS_DETAIL: 'Mogelijkheid om objectgroepen te bekijken',
            USER_PERMISSION_VIEW_SITE_NOTE_TYPES_DETAIL: 'Mogelijkheid om objectnotities te bekijken',
            USER_PERMISSION_VIEW_SURVEYS_DETAIL: 'Mogelijkheid om KPI checklijsten te bekijken',
            USER_PERMISSION_VIEW_TAGS_DETAIL: 'Mogelijkheid om tags te bekijken',
            USER_PERMISSION_VIEW_TRADE_TYPES_DETAIL: 'Mogelijkheid om handelstypen te bekijken',
            USER_PERMISSION_VIEW_USERS_DETAIL: 'Mogelijkheid om gebruikers te bekijken',
            USER_PERMISSION_VIEW_DUTY_MANAGEMENT_SCHEDULES_DETAIL: 'Mogelijkheid om uitvoerschemas te bekijken',
            USER_PERMISSION_CAN_RECEIVE_SUPPORT_MESSAGES_DETAIL: 'Mogelijkheid om berichten te bekijken en er op te reageren',

            USER_PERMISSION_MANAGE_SERVICE_TYPES_DETAIL: 'De mogelijkheid om types service te maken, aanpassen en verwijderen',
            USER_PERMISSION_VIEW_SERVICE_TYPES_DETAIL: 'De mogelijkheid om types service te bekijken',
            USER_PERMISSION_MANAGE_IMPORTS_DETAIL: 'Mogelijkheid om data te importeren',
            USER_PERMISSION_MANAGE_EXPORTS_DETAIL: 'Mogelijkheid om data te exporteren',
            USER_PERMISSION_MANAGE_BILLING_TYPES_DETAIL: 'De mogelijkheid om facturatie types te maken',
            USER_PERMISSION_KPI_TREND_EXPORT_DETAIL: 'De mogelijkheid om KPI rapproten te maken'
        });
    }
})();
