(function () {
    'use strict';

    angular
        .module('elogbooks.user.patrols')
        .controller('PatrolRouteController', PatrolRouteController);

    PatrolRouteController.$inject = ['$state', 'patrolPointsCollection', 'apiClient', 'messenger', 'patrolPointRequirementTypes', 'lodash', 'sortableOptions'];

    function PatrolRouteController ($state, patrolPointsCollection, apiClient, messenger, patrolPointRequirementTypes, lodash, sortableOptions) {
        var vm = this;
        vm.patrolPoints = patrolPointsCollection.patrolPoints;
        vm.patrolPoints._links = patrolPointsCollection._links;
        vm.patrolPointsCount = patrolPointsCollection.count;
        vm.patrolPointsPages = patrolPointsCollection.pages;
        vm.patrolPointsCollectionResponse = patrolPointsCollection;
        vm.patrolPointsCollectionResponse.noMore = vm.patrolPointsCollectionResponse.page === vm.patrolPointsCollectionResponse.pages || vm.patrolPointsCollectionResponse.pages === 0;

        vm.requirementTypes = patrolPointRequirementTypes;
        vm.userCanEdit = lodash.has(patrolPointsCollection, '_links.edit.href');
        vm.currentPage = vm.patrolPointsCollectionResponse.page;
        vm.itemsCount = vm.patrolPointsCollectionResponse.count;
        vm.limit = vm.patrolPointsCollectionResponse.limit;
        vm.update = updateAction;
        vm.patrolPointTime = 0;
        vm.haveLinesChanged = false; // Order of lines have changed
        vm.sortableOptions = sortableOptions.addProperty({
            orderChanged: function(event) {
                vm.haveLinesChanged = true;
            }
        });
        vm.loadMore = loadMore;
        vm.calculateTotals = calculateTotals;
        vm.loadingMore = false;

        vm.requirementTypes.getNameProxy = function(value) {
            return vm.requirementTypes.getName(value);
        };

        vm.calculateTotals();

        function updateAction() {

            var update = {
                _links: {points: []}
            };

            // Build the record to be sent to the server
            angular.forEach(vm.patrolPoints, function(value, key) {
                update._links.points.push(value._links.self);
            });

            apiClient.update(vm.patrolPoints._links.first.href, update).then(function (response) {
                if (response) {
                    $state.go('.', {}, { reload: true }).then(function () {
                        messenger.success('PATROLPOINT_UPDATED');
                    });
                } else {
                    messenger.error('REQUEST_ERROR');
                }
            });
        }

        function calculateTotals() {
            /* Calculate total time of the patrol from all points */
            angular.forEach(vm.patrolPoints, function (value, key) {
                if (value.active) {
                    vm.patrolPointTime += value.timeAllowed;
                }
            });
        }

        function loadMore() {
            if (vm.loadingMore) {
                return;
            }

            if (vm.patrolPointsCollectionResponse.page < vm.patrolPointsCollectionResponse.pages) {
                vm.loadingMore = true;

                apiClient.get(vm.patrolPointsCollectionResponse.getLink('next')).then(function (response) {
                    vm.patrolPointsCollectionResponse.patrolPoints = vm.patrolPointsCollectionResponse.patrolPoints.concat(response.patrolPoints);
                    vm.patrolPointsCollectionResponse.page = response.page;
                    vm.patrolPointsCollectionResponse.pages = response.pages;
                    vm.patrolPointsCollectionResponse._links = response._links;
                    vm.patrolPointsCollectionResponse.noMore = vm.patrolPointsCollectionResponse.page === vm.patrolPointsCollectionResponse.pages;
                    vm.patrolPoints = vm.patrolPointsCollectionResponse.patrolPoints;
                    vm.patrolPoints._links = vm.patrolPointsCollectionResponse._links;
                    vm.currentPage = vm.patrolPointsCollectionResponse.page;
                    vm.loadingMore = false;
                    vm.calculateTotals();
                });
            }
        }
    }
})();
