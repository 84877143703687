app.config(function ($translateProvider) {
    $translateProvider.translations('zh-hk', {
        REGION: '地區',
        REGIONS: '地區',

        REGION_INFORMATION: '地區資訊',
        MANAGE_REGION_SITES: '此地區的場地',

        ADD_NEW_REGION: '新增地區',
        EDIT_REGION: '修改地區',

        REGION_LIST: '地區',
        REGION_ADD: '新增地區',
        REGION_EDIT: '修改地區',

        REGION_CREATED: '地區已建立',
        REGION_UPDATED: '地區已更新',
        REGION_DELETED: '地區已刪除',

        REGION_SITE_LIST: '地區',
    });
});
