app.config(function ($translateProvider) {
    $translateProvider.translations('zh-hk', {
        HELPDESK: '服務台',
        HELPDESK_DASHBOARD: '服務台儀表板',

        QUOTES_LIST: '報價',
        ALARM_AT: '警報於',
        ALARM_LOCK: '警報鎖定',
        ALARMED: '警報',

        HD_NO_RESULTS: '沒有結果',
        HD_QUOTES: '報價',
        HD_STATUTORY: '法定',
        HD_REACTIVE: '重新開啟',
        HD_PLANNED: '計劃',
        HD_ESCALATIONS: '上報',
        HD_SATISFACTION_SURVEYS: '滿意度調查',

        HD_TOOLTIP_ALARMS: '警報',
        HD_TOOLTIP_AWAITING_APPROVAL: '等待審批',
        HD_TOOLTIP_OVERDUE: '過期',
        HD_TOOLTIP_ESCALATIONS: '上報',
        HD_TOOLTIP_CHASE: '跟進',
        HD_TOOLTIP_REMINDER: '提醒',

        HD_TOOLTIP_REACTIVE_CHASE: '跟進',
        HD_TOOLTIP_REACTIVE_ACKNOWLEDGE: '確認',
        HD_TOOLTIP_REACTIVE_APPROVAL: '審批',
        HD_TOOLTIP_REACTIVE_PRIORITY_OVERDUE: '跟進出席率',
        HD_TOOLTIP_REACTIVE_ALARMS: '提醒',

        HD_TOOLTIP_QUOTES_CHASE: '跟進',
        HD_TOOLTIP_QUOTES_REMINDER: '提醒',
        HD_TOOLTIP_QUOTES_EXTENSION_APPROVAL: '延長審批',

        HD_DAYS_SHORT: '{{days}}日',
        HD_HOURS_SHORT: '{{hours}時',
        HD_MINUTES_SHORT: '{{minutes}}分',
    });
});
