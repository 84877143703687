(function() {
    'use strict';

    angular
        .module('elogbooks')
        .config(Translations);

    function Translations($translateProvider) {
        $translateProvider.translations('en-gb', {
            NESTED_PATH_STRING_SERVICE_FULL_PATH_STRING_METHOD_NOT_EXIST: 'Passed Entity class does not haves `getFullPathString` method',
            NESTED_PATH_STRING_SERVICE_JSON_INVALID: 'Json full path string is incorrect.',
        });
    }
})();
