(function() {
    'use strict';
    angular
        .module('elogbooks.helpdesk.dashboard')
        .controller('HelpdeskDashboardJobsController', HelpdeskDashboardJobsController);

    function HelpdeskDashboardJobsController(
        $state,
        $stateParams,
        jobCollectionResponse,
        config,
        approversResponse,
        jobStatuses,
        $timeout,
        $scope,
        lodash,
        moveAlarmsModal,
        userManager,
        $translate
    ) {
        var vm = this;
        vm.jobs = jobCollectionResponse.jobs;
        vm.jobCollection = jobCollectionResponse;
        vm.config = config;
        vm.approversResponse = approversResponse;
        vm.approvalView = false;
        vm.jobsSelected = 0;
        vm.canBulkMoveAlarms = userManager.hasPermission('user_permission_bulk_move_alarms');

        vm.criteria = {
            id: { type: 'number', title: 'ID', value: $stateParams.id ? parseInt($stateParams.id, 10) : null },
            serviceProviderName: { type: 'text', title: 'SERVICE_PROVIDER', value: $stateParams.serviceProviderName },
            siteName: { type: 'text', title: 'SITE', value: $stateParams.siteName },
            approverName: { type: 'selectwidget', title: 'APPROVER', value: $stateParams.approverName,
                options: {
                    response : vm.approversResponse,
                    link : vm.approversResponse ? vm.approversResponse.getLink('self') : null,
                    responseKeyPath: 'users'
                }
            },
            escalationStatus: {
                type: 'options',
                title: 'ESCALATION_STATUS_HELPDESK_FILTER',
                multiple: false,
                value: $stateParams.escalationStatus,
                options: [
                    {title: $translate.instant('NONE_SELECTED'), value: null},
                    {title: $translate.instant('ESCALATED'), value: 'escalated'},
                    {title: $translate.instant('NOT_ESCALATED'), value: 'not_escalated'}
                ]
            },
            alarmAtDueRange: {
                type: 'date-time-range',
                title: 'ALARM_DUE_AT',
                startDateLabel: 'ALARM_AT_DUE_RANGE_START_DATE_LABEL',
                endDateLabel: 'ALARM_AT_DUE_RANGE_END_DATE_LABEL',
                value: $stateParams.alarmAtDueRange,
                startDateRequired: true,
                endDateRequired: true
            }
        };

        if ($state.current.name === 'dashboard.helpdesk.dashboard.reactive-jobs.awaiting-approval') {
            vm.approvalView = true;
            vm.criteria.jobStatus = { type: 'options', title: 'STATUS', multiple: true, value: $stateParams.jobStatus, options: jobStatuses.getAllTranslated('approval')};
        }

        vm.search = search;
        vm.order = orderAction;
        vm.countSelected = countSelected;
        vm.toggleSelected = toggleSelected;
        vm.moveAlarms = moveAlarms;

        function orderAction(key) {
            $stateParams.order = key;

            $state.go('.', angular.extend({}, $stateParams), { reload: $state.current });
        }

        function search(params) {
            var override = {
                page: 1,
                'jobApprover[]': params.jobApprover
            };

            $state.go('.', angular.extend({}, params, override), { reload: $state.current });
        }

        function toggleSelected(job) {
            if ($scope.refreshStatsTimeout) {
                $timeout.cancel($scope.refreshStatsTimeout);
                $scope.refreshStatsTimeout = null;
            }

            return job.selected ? job.selected : false;
        }

        function countSelected(job) {
            if (job) {
                vm.jobsSelected = job.selected ? vm.jobsSelected += 1 : vm.jobsSelected -= 1;
                if (vm.jobsSelected === 0) {
                    $state.go($state.current, $stateParams, {reload: 'dashboard.helpdesk.dashboard'});
                }

                return;
            }
        }

        function moveAlarms() {
            var moveAlarms = moveAlarmsModal.open(
                vm.jobCollection,
                vm.jobsSelected
            );
            moveAlarms.result.then(function () {
                clearActionSelection();
            });
        }

        function clearActionSelection() {
            lodash.forEach(vm.jobCollection.jobs, function (job) {
                job.selected = false;
            });
            vm.jobsSelected = 0;
            $state.go($state.current, $stateParams, {reload: 'dashboard.helpdesk.dashboard'});
        }
    }
})();
