(function () {
    'use strict';

    angular
        .module('elogbooksDirectives')
        .directive('siteSelect', ['apiClient', siteSelect]);

    function siteSelect(apiClient) {
        var directive = {
            link: link,
            templateUrl: '/modules/directives/form-elements/site-select/site-select.html',
            restrict: 'EA',
            scope: {
                site: '=ngModel',
                required: '=ngRequired',
                disabled: '=ngDisabled',
                source: '=siteSource',
                params: '=siteSourceParams',
                exclude: '=',
                form: '='
            },
            require: ['ngModel']
        };
        return directive;

        function link(scope, element, attrs) {
            scope.$watchGroup(['source', 'params'], function() {
                scope.sites = null;

                if (!scope.source) {
                    return;
                }

                apiClient.get(scope.source, scope.params).then(function(response) {
                    var temp = response.sites, result = [];

                    if (typeof scope.exclude === 'object') {
                        angular.forEach(temp, function (value, key) {
                            if (scope.exclude.indexOf(value.getLink('self')) === -1) {
                                result.push(value);
                            }
                        });
                    }

                    scope.sites = result;
                });
            });
        }
    }
})();
