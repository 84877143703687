(function() {
    'use strict';

    angular
        .module('elogbooks.admin.pricing-schedules')
        .controller('FinancePurchaseOrdersWorkflowAddNoteController', FinancePurchaseOrdersWorkflowAddNoteController);

    FinancePurchaseOrdersWorkflowAddNoteController.$inject = [
        'purchaseOrderResponse',
        'lodash',
        '$state',
        'apiClient',
        'messenger',
        'userManager'
    ];

    function FinancePurchaseOrdersWorkflowAddNoteController(
        purchaseOrderResponse,
        lodash,
        $state,
        apiClient,
        messenger,
        userManager
    ) {
        var vm = this;

        vm.type = lodash.last($state.current.name.split('.'));
        vm.data = { note: null }

        vm.config = {
            'part-received': {
                title: 'PART_RECEIVED',
                submitText: 'PART_RECEIVED'
            },
            cancel: {
                noteRequired: true,
                submitText: 'SUBMIT'
            },
            complete: {
                noteRequired: userManager.hasPermission('user_permission_can_complete_unmatched_purchase_orders') || false
            }
        }

        vm.create = vm.update = function() {
            return apiClient.create(purchaseOrderResponse.getLink('workflow-' + vm.type), vm.data).then(function (response) {
                if (response) {
                    $state.go('^.^.items', {}, { reload: true }).then(function () {
                        messenger.success(
                            'PURCHASE_ORDER_WORKFLOW_'
                            + vm.type.toUpperCase().replaceAll('-', '_')
                            + '_SUCCESS'
                        );
                    });
                } else {
                    messenger.error('REQUEST_ERROR');
                }
            });
        };
    }
})();
