(function() {
    'use strict';

    angular
        .module('elogbooks.common.quote-requests')
        .controller('CommonQuoteRequestDetailsQuoteDetailsOptionsAddEditController', CommonQuoteRequestDetailsQuoteDetailsOptionsAddEditController);

    CommonQuoteRequestDetailsQuoteDetailsOptionsAddEditController.$inject = ['$state', 'quoteOptionResponse', 'quoteResponse', 'requestDataFactory', 'crudService', 'messenger', 'elbSettings'];

    function CommonQuoteRequestDetailsQuoteDetailsOptionsAddEditController($state, quoteOptionResponse, quoteResponse, requestDataFactory, crudService, messenger, elbSettings) {
        var vm = this;
        vm.highlightedSubtitle = quoteResponse.budgetQuote ? 'BUDGET_QUOTE' : null;
        vm.quoteOptionResponse = quoteOptionResponse;
        vm.data = requestDataFactory.createRequest(vm.quoteOptionResponse);
        vm.requireLabourAndMaterial = elbSettings.getSetting('quote_require_labour_and_materials').value;
        vm.data.value = parseFloat(vm.data.value, 10) || null;
        vm.data.files = {
            links: []
        };

        vm.create = createAction;
        vm.update = updateAction;
        vm.delete = deleteAction;

        function createAction() {
            if (!vm.requireLabourAndMaterial && vm.data.value < 0.01) {
                messenger.error('QUOTE_OPTION_VALUE_TOO_LOW');
                return;
            }

            return crudService.create(
                quoteResponse.getLink('create-options'),
                vm.data,
                null,
                null,
                null,
                true
            ).then(function(response) {
                $state.go($state.get('^'), { quoteOptionResource: response.getLink('self').encode() }, { reload: $state.get('^.^.^.^') }).then(function() {
                    messenger.success('QUOTE_OPTION_CREATED');
                });
            });
        }

        function updateAction() {
            if (!vm.requireLabourAndMaterial && vm.data.value < 0.01) {
                messenger.error('QUOTE_OPTION_VALUE_TOO_LOW');
                return;
            }

            return crudService.update(
                vm.quoteOptionResponse.getLink('edit'),
                vm.data,
                $state.get('^'),
                $state.get('^.^.^.^.^'),
                'QUOTE_OPTION'
            );
        }

        function deleteAction() {
            return crudService.delete(
                vm.quoteOptionResponse.getLink('delete'),
                null,
                null,
                null,
                true
            ).then(function(response) {
                $state.go($state.get('^.^.^'), {}, { reload: $state.get('^.^.^.^.^') }).then(function() {
                    messenger.success('QUOTE_OPTION_DELETED');
                });
            });
        }
    }
})();
