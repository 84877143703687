(function () {
    'use strict';

    angular
        .module('elogbooks.admin.sites')
        .controller('CommonServiceTypeAddController', CommonServiceTypeAddController);

    CommonServiceTypeAddController.$inject = ['serviceTypesRootResponse', 'serviceTypesCollectionResponse', 'lodash', 'crudService', '$state', 'messenger', 'config'];

    function CommonServiceTypeAddController(serviceTypesRootResponse, serviceTypesCollectionResponse, lodash, crudService, $state, messenger, config) {
        var vm = this;
        vm.form = {};
        vm.serviceTypes = serviceTypesRootResponse.serviceTypes;
        vm.serviceTypesCollectionResponse = serviceTypesCollectionResponse;
        vm.serviceTypeOptions = [];
        vm.create = createServiceType;
        vm.noBorder = config.noBorder;

        var options = lodash.differenceBy(serviceTypesRootResponse.serviceTypes, serviceTypesCollectionResponse.serviceTypes, 'id');
        lodash.each(options, function (serviceType) {
            vm.serviceTypeOptions.push({value: serviceType._links.self.href, title: serviceType.name});
        });

        function createServiceType() {
            vm.data = {
                _links: {
                    serviceType: {
                        href: vm.form.selected
                    }
                }
            };

            return crudService.create(
                vm.serviceTypesCollectionResponse.getLink('create'),
                vm.data,
                null,
                null,
                'SERVICE_TYPES',
                true
            ).then(function () {
                $state.go('^', {}, {reload: $state.get('^.^')}).then(function () {
                    messenger.success('SERVICE_TYPE_ADDED');
                });
            });
        }
    }
})();
