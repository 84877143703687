(function() {
    'use strict';

    angular
        .module('elogbooks.common.assets')
        .controller('UserAssetDetailsController', UserAssetDetailsController);

    UserAssetDetailsController.$inject = ['assetResponse', 'sectionResource'];

    function UserAssetDetailsController(assetResponse, sectionResource) {
        var vm = this;
        vm.asset = assetResponse;
        vm.sectionResource = sectionResource;
    }
})();