(function () {
    'use strict';

    angular
        .module('elogbooks')
        .controller('CertificateTypesController', CertificateTypesController);

    CertificateTypesController.$inject = ['$state', '$stateParams', 'certificateTypeCollectionResponse', 'userManager'];

    function CertificateTypesController ($state, $stateParams, certificateTypeCollectionResponse, userManager) {
        var vm = this;
            vm.certificateTypes = certificateTypeCollectionResponse.certificateTypes;
            vm.certificateTypeCollectionResponse = certificateTypeCollectionResponse;
            vm.criteria = {
                id: { type: 'text', title: 'ID', value: $stateParams.id },
                name: { type: 'text', title: 'NAME', value: $stateParams.name }
            };
            vm.pages = vm.certificateTypeCollectionResponse.pages;

        vm.order = order;
        vm.search = search;
        vm.userManager = userManager;

        function order (key) {
            $stateParams.order = key;

            $state.go('.', angular.extend({}, $stateParams), { reload: true });
        }

        function search (params) {
            var override = {
                page: 1
            };

            $state.go('.', angular.extend({}, params, override), { reload: true });
        }

    }
})();
