app.config(function ($translateProvider) {
    $translateProvider.translations('en-gb', {
        FLOOR: 'Floor',
        FLOORS: 'Floors',

        ADD_NEW_FLOOR: 'Add a new Floor',
        EDIT_FLOOR: 'Edit Floor',

        FLOOR_CREATED: 'Floor created successfully',
        FLOOR_UPDATED: 'Floor updated successfully',
        FLOOR_DELETED: 'Floor deleted',

        NO_FLOOR_RECORDS: 'No floor records'
    });
});
