angular.module('elogbooksServices').service('auditsAdvancedFiltersModal', ['$uibModal', 'advancedFilterService', function ($uibModal, advancedFilterService) {
    return {
        open: function (collections, resources, config) {
            return $uibModal.open({
                templateUrl: '/modules/common/modals/advancedFilters/modal-advanced-filters-modal.html',
                size: 'xl',
                controller: 'advancedAuditFiltersModalController',
                controllerAs: 'vm',
                resolve: {
                    config: function () {
                        var defaultConfig = {
                            view: 'audit',
                            titleMessage: 'ARE_YOU_SURE_HEADER',
                            bodyMessage: 'ARE_YOU_SURE'
                        };

                        return angular.extend({}, defaultConfig, config);
                    },
                    rootResource: resources,
                    auditCollectionResponse: advancedFilterService.getFilterData('audits',resources),
                    serviceProviderResponse: advancedFilterService.getFilterData('auditServiceProviders',resources),
                    operativeCollectionResponse: advancedFilterService.getFilterData('audit-operatives',resources),
                }
            });
        }
    };
}]);
