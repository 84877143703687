app.config(function ($translateProvider) {
    $translateProvider.translations('zh-hk', {
        METER: '儀表',
        METERS: '儀表',
        METERS_LIST: '儀表',
        METERS_ADD: '增加',
        METER_ADD: '新增儀表',
        METER_EDIT: '修改儀表',
        UTILITY_PROVIDER: '公用事業提供者',
        MANUFACTURER: '製造商',
        SUPPLY_TYPE: '供應類別',
        OCCUPANCY: '佔用',
        AUTOMATIC_READINGS: '自動讀數',
        ACCOUNT_NUMBER: '帳號',
        MULTIPLIER: '倍增器',
        DUAL_RATED: '雙倍率',
        SERIAL_NUMBER: '序號',
        ADD_NEW_READING : '增加讀數',
        ADD_NEW_SUBMETER: '增加子儀表',
        DATE_READ : '日期及時間',
        READING: '讀數',
        READINGS: '讀數',
        NIGHT_READINGS: '晚上讀數',
        NIGHT_READING: '晚上讀數',
        DAY_READINGS: '早上讀數',
        DAY_READING: '早上讀數',
        LAST_READING: '最後讀數',
        READ_BY: '讀數者',
        NO_READINGS: '沒有讀數',
        NO_SUBMETERS: '沒有子儀表',
        SUBMETERS: '子儀表',
        HISTORY: '歷史',
        DATE_READING_DUE: '讀數到期',
        GOTOPARENT: '去上一層',
        METER_FREQUENCY: '頻率',

        METERSUPPLIERS: '儀表供應商',
        METERMANUFACTURER: '儀表製造商',
        METERTYPE: '儀表類別',
        METERTYPESUPPLY: '儀表供應率',
        METERTYPEOCCUPANCY: '儀表佔用率',

        METER_MANUFACTURERS: '製造商',
        METER_OCCUPANCYTYPES: '佔用類別',
        METER_SUPPLIERS: '供應商',
        METER_SUPPLYTYPES: '供應類別',
        METER_TYPES: '類別',

        EDIT_READING: '修改讀數',
        METER_ADDED: '儀表已增加',
        METER_READING_ADDED: '儀表讀數已增加',
        METER_READING_UPDATED: '儀表讀數已更新',
        REFERENCE_TYPE: '參考類別'

    });
});
