(function () {
    'use strict';

    angular
        .module('elogbooks.admin.document-types-management')
        .controller('DocumentSetAddSiteController', DocumentSetAddSiteController);

    DocumentSetAddSiteController.$inject = [
        '$state',
        '$stateParams',
        'apiClient',
        'messenger',
        'siteCollectionResponse',
        'documentSetResponse'
    ];

    function DocumentSetAddSiteController (
        $state,
        $stateParams,
        apiClient,
        messenger,
        siteCollectionResponse,
        documentSetResponse
    ) {
        var vm = this;
        vm.documentSetResponse = documentSetResponse;

        vm.siteSelect = {
            response : siteCollectionResponse,
            link : siteCollectionResponse ? siteCollectionResponse.getLink('self') : '',
            responseKeyPath: 'sites',
            searchKey: 'siteName'
        };

        vm.create = createAction;

        function createAction() {
            var data = {
                _links: {}
            };

            if (vm.siteSelect.selectedItems.length) {
                data._links.site = vm.siteSelect.selectedItems;
            } else {
                messenger.error('SELECT_AT_LEAST_ONE_SITE');
                return;
            }

            apiClient.create(vm.documentSetResponse.getLink('add-sites'), data).then(function (response) {
                if (response) {
                    $state.go('^', $stateParams, { reload: $state.$current.parent.parent.parent.self.name }).then(function () {
                        messenger.success('DOCUMENT_SET_SITE_ADDED');
                    });
                } else {
                    messenger.error('REQUEST_ERROR');
                }
            });
        }
    }
})();
