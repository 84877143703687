(function () {
    'use strict';

    angular
        .module('elogbooks.user.finance.suppliers')
        .controller('FinanceSupplierDetailsInfoController', FinanceSupplierDetailsInfoController);
        
        FinanceSupplierDetailsInfoController.$inject = [
            'supplierResponse'
        ];

        function FinanceSupplierDetailsInfoController(
            supplierResponse
        ) {
            var vm = this;
            vm.supplier = supplierResponse;

        }
})();
