angular.module('elogbooksServices').service('sortableOptions', function($window, $document) {

    this.getDefaultOptions = getDefaultOptions;
    this.addProperty = addProperty;

    var sortableOptions = {
        containment: '.agile-list',
        additionalPlaceholderClass: 'dark-placeholder',
        tolerance: "pointer",
        containerPositioning: 'relative',
        dragMove: function (itemPosition, containment, eventObj) {
            // https://github.com/a5hik/ng-sortable#scroll-page-after-reaching-end-of-visible-area
            // Scroll the page as the item is being dragged
            if (eventObj) {
                var targetY = eventObj.pageY - ($window.pageYOffset || $document[0].documentElement.scrollTop);
                var offset = parseInt($window.innerHeight * .07);

                if (targetY < offset) {
                    $window.scrollBy(0, -20);
                } else if (targetY > ($window.innerHeight - offset)) {
                    $window.scrollBy(0, 20);
                }
            }
        }
    };

    function getDefaultOptions() {
        return sortableOptions;
    }

    function addProperty(property, options) {
        if (typeof options === 'undefined') {
            options = sortableOptions;
        }
        return angular.extend({}, options, property);
    }

    return this;
});
