app.config(function ($translateProvider) {
    $translateProvider.translations('en-gb', {
        // Dashboard
        LOG_OUT: 'Log out',
        YOUR_DASHBOARD: 'My Dashboard',
        LOGGED_IN_AS: 'Logged in as <strong>{{::name}}</strong>',
        RETURN_TO_USER: 'Return to user <strong>{{::name}}</strong>',

        // Login
        LOGIN: 'Log In',
        RESET_PASSWORD: 'Reset Password',
        RESET_PASSWORD_EXPLANATION: 'Enter your username below and we\'ll email you a link to reset your password.',
        PASSWORD_CHANGED : 'Password changed',
        FORGOTTEN_USERNAME: 'Forgotten Username',
        FORGOTTEN_USERNAME_EXPLANATION: 'Enter your email below and we\'ll <br>email your username.',
        FORGOTTEN_USERNAME_ERROR: 'You have entered an invalid email address.',

        // Admin Settings
        SETTINGS: 'Settings',
        SETTINGS_DESCRIPTION: 'System settings to customise your Elogs CAFM installation.',

        // Reporter Feedback
        FEEDBACK: 'Feedback Score',
        COMMENT: 'Comment',
        CONTACT_BACK: 'Are you happy to be contacted in relation to this comment?',
        NEGATIVE: 'Negative',
        POSITIVE: 'Positive',
        FEEDBACK_SUBMITTED: 'Feedback Submitted',
        REPORTER_UNSUBSCRIBE: 'You have been successfully removed from the subscriber list and won\'t receive any further reporter feedback emails.',
        REPORTER_UNSUBSCRIBE_ERROR: 'Unable to process request. You have already unsubscribed from receiving feedback requests.',
        FEEDBACK_ALREADY_SUBMITTED: 'Feedback Already Submitted'
    })
    .fallbackLanguage('en-gb')
    .useSanitizeValueStrategy('escaped');
});
