(function() {
    'use strict';

    angular
        .module('elogbooks.admin.document-types-management')
        .controller('DocumentSetsDetailsController', DocumentSetsDetailsController);

    DocumentSetsDetailsController.$inject = ['documentSetResponse'];
    function DocumentSetsDetailsController(documentSetResponse) {
        var vm = this;
        vm.documentSet = documentSetResponse;
    }
})();
