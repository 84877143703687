(function() {
    'use strict';

    angular
        .module('elogbooks')
        .controller('ExtendSessionController', ExtendSessionController);

    ExtendSessionController.$inject = ['$uibModalInstance', '$window', '$timeout', 'userManager', '$state', '$interval', '$rootScope'];

    function ExtendSessionController($uibModalInstance, $window, $timeout, userManager, $state, $interval, $rootScope) {
        var vm = this;

        vm.extend = extendAction;
        vm.logout = logoutAction;

        vm.timeRemaining = '';
        var timeRemaining = (parseInt($window.localStorage.getItem('tokenExpiration')) - new Date().getTime()) / 1000;

        updateTimeRemaining();
        $interval(updateTimeRemaining, 1000);

        function extendAction() {
            $timeout.cancel(autoLogout);
            $uibModalInstance.close();
        }

        function logoutAction() {
            $timeout.cancel(autoLogout);
            $uibModalInstance.dismiss();
            userManager.logout();

            $rootScope.skipDirtyCheck = true;
            $state.go('login').then(function() {
                $rootScope.skipDirtyCheck = false;
            });
        }

        function updateTimeRemaining() {
            timeRemaining = (parseInt($window.localStorage.getItem('tokenExpiration')) - new Date().getTime()) / 1000;

            if (timeRemaining < 0) {
                logoutAction();
            }

            var minutes = Math.floor(timeRemaining / 60);
            var seconds = Math.round(timeRemaining - (minutes * 60));

            minutes = minutes.toString();
            seconds = seconds.toString();

            // Add leading 0's for a nice countdown timer - 04:02 rather than 4:2
            if (minutes.length < 2) { minutes = '0' + minutes; }
            if (seconds.length < 2) { seconds = '0' + seconds; }

            vm.timeRemaining = minutes + ':' + seconds;
        }

        var autoLogout = $timeout(logoutAction, timeRemaining * 1000);
    }
})();
