(function () {
    'use strict';

    angular
        .module('elogbooks.common.change-password')
        .controller('ChangePasswordController', ChangePasswordController);

    ChangePasswordController.$inject = [
        '$window',
        '$state',
        'apiClient',
        'userManager',
        'user',
        'messenger',
        'elbSettings',
        '$scope',
        'rootResource'
    ];

    function ChangePasswordController(
        $window,
        $state,
        apiClient,
        userManager,
        user,
        messenger,
        elbSettings,
        $scope,
        rootResource
    ) {
        var vm = this;
        vm.password = {
            new: null,
            repeated: null
        };
        vm.passwordChanged = false;
        vm.loading = false;
        vm.passwordMatch = true;
        vm.forcePasswordChange = elbSettings.getSetting('password_force_change')
            ? elbSettings.getSetting('password_force_change').value && user.shouldChangePassword
            : false;
        vm.passwordsMatch = passwordsMatch;
        vm.changePassword = changePassword;
        vm.skip = skip;

        vm.themePrefix = rootResource?.theme ?? 'elb';
        vm.themeLogo = `/${vm.themePrefix}-logo.svg`;

        userManager.skippedChangePassword = true;

        // Prevent clicking away from password change unless logout
        $scope.$on('$stateChangeStart', function(event) {
            if (!event.currentScope.vm.passwordChanged &&
                event.currentScope.vm.forcePasswordChange &&
                $state.current.name !== $state.next.name &&
                $state.next.name !== 'login'
            ) {
                event.preventDefault();
                $state.go($state.current, {}, {reload: true});
            }
        });

        function passwordsMatch() {
            vm.passwordMatch = true;
            if(vm.password.new && vm.password.repeated && vm.password.new != vm.password.repeated) {
                vm.passwordMatch = false;
                return false;
            }
            return true;
        }

        function changePassword() {
            vm.loading = true;
            apiClient.update(user.getLink('change-password'), {
                password: vm.password.new,
            }).then(function (response) {
                if (response) {
                    vm.loading = false;
                    vm.passwordChanged = true;
                    $window.localStorage.setItem('token', response.token);
                    $state.go('dashboard.user.dashboard', {}, { reload: false });
                } else {
                    vm.loading = false;
                }
            });
        }

        function skip() {
            $state.go('dashboard.user.dashboard', {}, { reload: false });
        }
    }

})();
