(function () {
    'use strict';

    angular
        .module('elogbooks.common.quote-requests')
        .controller(
            'CommonQuoteRequestDetailsQuoteDetailsOptionDetailsLabourAddEditController',
            CommonQuoteRequestDetailsQuoteDetailsOptionDetailsLabourAddEditController
        );

    CommonQuoteRequestDetailsQuoteDetailsOptionDetailsLabourAddEditController.$inject = [
        '$state',
        'quoteOptionLabourCollectionResponse',
        'quoteOptionLabourResponse',
        'crudService',
        'requestDataFactory',
        'elbSettings',
        'labourTypeCollectionResponse',
        'labourRatesCollectionResponse',
        'lodash',
        '$scope'
    ];

    function CommonQuoteRequestDetailsQuoteDetailsOptionDetailsLabourAddEditController(
        $state,
        quoteOptionLabourCollectionResponse,
        quoteOptionLabourResponse,
        crudService,
        requestDataFactory,
        elbSettings,
        labourTypeCollectionResponse,
        labourRatesCollectionResponse,
        lodash,
        $scope
    ) {
        var vm = this;
        // When quote require labour and materials setting is true we need to reload the quote on create/update
        var reloadRoute = elbSettings.getSetting('quote_require_labour_and_materials').value ? $state.get('^.^.^.^.^') : $state.get('^');
        vm.quoteOptionLabourResponse = quoteOptionLabourResponse;
        vm.quoteOptionLabourCollectionResponse = quoteOptionLabourCollectionResponse;
        vm.labourTypeCollectionResponse = labourTypeCollectionResponse;
        vm.updateValue = updateValue;

        if (labourRatesCollectionResponse) {
            vm.pricingScheduleLabourRates = labourRatesCollectionResponse.pricingschedulelabourrates;
        }

        vm.labourTypeModel = {};

        if (labourTypeCollectionResponse) {
            vm.labourTypeModel = {
                response: labourTypeCollectionResponse,
                link: labourTypeCollectionResponse.getLink('self'),
                responseKeyPath: 'labourTypes',
                itemHrefPath: '_links.self.href',
                itemValuePath: 'name'
            };

            if (quoteOptionLabourResponse.id && quoteOptionLabourResponse.getLink('labourType')) {
                vm.labourTypeModel.selected = {
                    'href': quoteOptionLabourResponse._links.labourType ? quoteOptionLabourResponse._links.labourType.href : null,
                    'title': quoteOptionLabourResponse._links.labourType ? quoteOptionLabourResponse._links.labourType.name : null,
                    'object': quoteOptionLabourResponse._links.labourType ? quoteOptionLabourResponse._links.labourType : null,
                    'id': quoteOptionLabourResponse._links.labourType ? quoteOptionLabourResponse._links.labourType.id : null
                };

                if (vm.pricingScheduleLabourRates) {
                    if (lodash.find(vm.pricingScheduleLabourRates, { labourTypeId: quoteOptionLabourResponse._links.labourType.id })) {
                        vm.isValueDisabled = true;
                    }
                }
            }
        }

        vm.data = requestDataFactory.createRequest(angular.extend({}, quoteOptionLabourResponse));

        vm.data.labourHours = vm.data.labourHours ? parseFloat(vm.data.labourHours, 10) : null;
        vm.data.travelHours = vm.data.travelHours ? parseFloat(vm.data.travelHours, 10) : null;
        vm.data.mileage = vm.data.mileage ? parseFloat(vm.data.mileage, 10) : null;
        vm.data.value = vm.data.value ? parseFloat(vm.data.value, 10) : null;

        setDefaults();

        vm.create = createAction;
        vm.update = updateAction;
        vm.delete = deleteAction;

        function createAction() {
            vm.data._links = {};

            if (vm.labourTypeModel && vm.labourTypeModel.selected && vm.labourTypeModel.selected.href) {
                vm.data._links.labourType = vm.labourTypeModel.selected.href;
            } else if (!vm.labourTypeModel.selected && vm.labourTypeCollectionResponse && vm.labourTypeCollectionResponse.labourTypes.length > 0) {
                return;
            }

            return crudService.create(
                quoteOptionLabourCollectionResponse.getLink('create'),
                vm.data,
                $state.get('^'),
                null,
                'QUOTE_OPTION_LABOUR',
                false,
                reloadRoute
            );
        }

        function updateAction() {
            vm.data._links = {};
            if (vm.labourTypeModel && vm.labourTypeModel.selected && vm.labourTypeModel.selected.href) {
                vm.data._links.labourType = vm.labourTypeModel.selected.href;
            } else if (!vm.labourTypeModel.selected && vm.labourTypeCollectionResponse && vm.labourTypeCollectionResponse.labourTypes.length > 0) {
                return;
            }

            return crudService.update(
                quoteOptionLabourResponse.getLink('edit'),
                vm.data,
                $state.get('^'),
                reloadRoute,
                'QUOTE_OPTION_LABOUR'
            );
        }

        function deleteAction() {
            return crudService.delete(
                quoteOptionLabourResponse.getLink('delete'),
                $state.get('^'),
                'QUOTE_OPTION_LABOUR',
                null,
                null,
                reloadRoute
            );
        }

        function setDefaults() {
            vm.data.labourHours = typeof vm.data.labourHours === 'number' ? vm.data.labourHours : 0;
            vm.data.travelHours = typeof vm.data.travelHours === 'number' ? vm.data.travelHours : 0;
            vm.data.mileage = typeof vm.data.mileage === 'number' ? vm.data.mileage : 0;
            vm.data.value = typeof vm.data.value === 'number' ? vm.data.value : 0;
        }

        $scope.$watch('vm.data', function() {
            updateValue();
        }, true);

        $scope.$watch('vm.labourTypeModel.selected', function(newValue, oldValue) {
            if (newValue !== oldValue) {
                updateValue();
            }
        }, true);

        function updateValue() {
            vm.isValueDisabled = false;
            if (vm.pricingScheduleLabourRates && vm.labourTypeModel.selected) {
                var pricingScheduleRates = lodash.find(vm.pricingScheduleLabourRates, {
                    labourTypeId: vm.labourTypeModel.selected.id
                });

                if (pricingScheduleRates) {
                    vm.data.labourHours = vm.data.labourHours || 0;
                    vm.data.travelHours = vm.data.travelHours || 0;
                    vm.data.mileage = vm.data.mileage || 0;
                    vm.isValueDisabled = true;

                    vm.data.value = vm.data.labourHours * pricingScheduleRates.hourlyRate +
                        vm.data.travelHours * pricingScheduleRates.travelTime +
                        vm.data.mileage * pricingScheduleRates.mileage;
                }
            }
        }
    }
})();
