app.config(function ($translateProvider) {
    $translateProvider.translations('en-gb', {
        PUBLIC_HOLIDAYS: 'Public Holidays',
        PUBLIC_HOLIDAYS_LIST: 'Public Holidays',
        PUBLIC_HOLIDAYS_ADD: 'Add Public Holiday',
        PUBLIC_HOLIDAYS_EDIT: 'Edit Public Holiday',

        PUBLIC_HOLIDAY: 'Public Holiday',
        PUBLIC_HOLIDAY_ADD: 'Add Public Holiday',
        PUBLIC_HOLIDAY_EDIT: 'Edit Public Holiday',
        PUBLIC_HOLIDAY_ADDED: 'Public Holiday Added',
        PUBLIC_HOLIDAY_UPDATED: 'Public Holiday Updated',
        PUBLIC_HOLIDAY_DELETED: 'Public Holiday Deleted',
    });
});
