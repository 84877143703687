/**
 * Timesheets list controller
 */
(function() {
    'use strict';

    angular
        .module('elogbooks.user.timesheets')
        .controller('UserTimesheetsListController', UserTimesheetsListController);

    UserTimesheetsListController.$inject = [
        'timesheetsCollectionResponse',
        '$state',
        'moment',
        '$stateParams',
        '$translate',
        'userCollectionResponse',
        'approverCollectionResponse'
    ];

    function UserTimesheetsListController(
        timesheetsCollectionResponse,
        $state,
        moment,
        $stateParams,
        $translate,
        userCollectionResponse,
        approverCollectionResponse
    ) {
        var vm = this;

        vm.timesheetsCollectionResponse = timesheetsCollectionResponse;
        vm.timesheetWeeks = formatFromToDateColumn(vm.timesheetsCollectionResponse.timesheetWeeks);

        vm.search = search;

        var statusOptions = [
            { value: 'draft', title: $translate.instant('TIMESHEETS_STATUS_DRAFT') },
            { value: 'submitted', title: $translate.instant('TIMESHEETS_STATUS_PENDING_APPROVAL') },
            { value: 'approved', title: $translate.instant('TIMESHEETS_STATUS_APPROVED') },
            { value: 'rejected', title: $translate.instant('TIMESHEETS_STATUS_REJECTED') }
        ];

        vm.criteria = {
            dateRange: {
                type: 'date-range',
                title: 'DATE_RANGE',
                value: {
                    startDate: ($stateParams.dateRange !== null && typeof $stateParams.dateRange === 'string') ? moment(new Date($stateParams.dateRange.split(',')[0])) : null,
                    endDate: ($stateParams.dateRange !== null && typeof $stateParams.dateRange === 'string') ? moment(new Date($stateParams.dateRange.split(',')[1])) : null,
                }
            },
        };

        if (userCollectionResponse) {
            vm.userSelect = {
                response: userCollectionResponse,
                link: userCollectionResponse.getLink('self'),
                responseKeyPath: 'users',
                itemValuePath: 'id',
                itemTitlePath: 'name'
            };

            vm.criteria.user = {
                type: 'jsonselectwidget',
                title: 'USER',
                value: JSON.parse($stateParams.user),
                options: {
                    response: userCollectionResponse,
                    link: userCollectionResponse ? userCollectionResponse.getLink('self') : null,
                    itemValuePath: 'id',
                    itemTitlePath: 'name',
                    responseKeyPath: 'users'
                }
            };
        }

        vm.criteria.status = {type: 'options', title: 'STATUS', multiple: true, value: $stateParams.status, options: statusOptions};

        if (approverCollectionResponse) {
            vm.approverSelect = {
                response: approverCollectionResponse,
                link: approverCollectionResponse.getLink('self'),
                responseKeyPath: 'users',
                itemValuePath: 'id',
                itemTitlePath: 'name'
            };

            vm.criteria.approver = {
                type: 'jsonselectwidget',
                title: 'APPROVER',
                value: JSON.parse($stateParams.approver),
                options: vm.approverSelect
            };
        }

        function search(params) {
            var override = {
                page: 1
            };

            $state.go('.', angular.extend({}, params, override), {reload: 'dashboard.user.timesheets.list'});
        }

        function formatFromToDateColumn(timesheets) {
            var startDate;
            var endDate;
            angular.forEach(timesheets, function(timesheet) {
                startDate = new Date(timesheet.startWeekDate);
                endDate = new Date(timesheet.startWeekDate);
                endDate.setDate(endDate.getDate() + 6);
                timesheet.startDate = startDate;
                timesheet.endDate = endDate;
            });

            return timesheets;
        }
    }
})();
