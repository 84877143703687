(function () {
    'use strict';

    angular
        .module('elogbooksDirectives')
        .directive('elbEventFeed', ElogbooksEventFeed);

    ElogbooksEventFeed.$inject = [];

    function ElogbooksEventFeed () {
        return {
            restrict: 'AE',
            require: ['templatePath'],
            scope: {
                messageCollectionResponse: '=',
                eventCollectionResponse: '=',
                fileCollectionResponse: '=?',
                templatePath: '@',
                isHelpdesk: '=',
                contactsLink: '='
            },
            templateUrl: '/modules/directives/elb-event-feed/event-feed.html',
            controller: ElogbooksEventFeedController,
            controllerAs: 'vm'
        };
    }

    ElogbooksEventFeedController.$inject = ['$scope', 'eventFeed', 'apiClient', 'messenger', '$state', '$uibModal', 'elbDownloadService', '$sce'];

    function ElogbooksEventFeedController ($scope, eventFeed, apiClient, messenger, $state, $uibModal, elbDownloadService, $sce) {
        var vm = this;
        vm.messageCollectionResponse = $scope.messageCollectionResponse;
        vm.eventCollectionResponse = $scope.eventCollectionResponse;
        vm.fileCollectionResponse = $scope.fileCollectionResponse;
        vm.templatePath = $scope.templatePath;
        vm.isHelpdesk = $scope.isHelpdesk;
        vm.contactsLink = $scope.contactsLink;
        vm.isCollapsed = true;
        vm.sending = false;
        vm.data = { body: null };
        vm.messageForm = null;
        vm.download = elbDownloadService;
        vm.$sce = $sce;

        vm.submit = submitAction;
        vm.translatedEventString = translatedEventString;

        eventFeed.setMessageCollectionResponse(vm.messageCollectionResponse);
        eventFeed.setEventCollectionResponse(vm.eventCollectionResponse);
        eventFeed.setFileCollectionResponse(vm.fileCollectionResponse);
        eventFeed.getFeed(function(feed, more) {
            if (more !== undefined && !more) {
                vm.events = eventFeed.extract(feed, 'event');
                vm.messages = eventFeed.extract(feed, 'message');
                vm.files = eventFeed.extract(feed, 'file');
                vm.feed = eventFeed.removeEvents(feed);
            }
        });

        function submitAction (form, notify) {
            vm.sending = true;
            vm.messageForm = form;

            if (typeof notify === 'undefined') {
                notify = false;
            } else if (notify === true) {
                var contactsModal = $uibModal.open({
                    templateUrl: '/modules/common/contacts/contacts.html',
                    controller: ['contactsCollectionResponse', '$uibModalInstance', 'lodash', '$translate', 'elbSettings', 'entityName', 'userManager', function(contactsCollectionResponse, $uibModalInstance, lodash, $translate, elbSettings, entityName, userManager) {
                        var vm = this;
                        vm.config = {
                            entityName: entityName,
                            notifyUpdate: true
                        };
                        vm.contactCollectionResponse = contactsCollectionResponse;
                        vm.contacts = contactsCollectionResponse.contacts;

                        if(userManager.hasPermission('site_permission_notify_updates')) {
                            vm.contactCollectionResponse.contacts.push({
                                name: $translate.instant('HELPDESK'),
                                email: elbSettings.getSetting('general_helpdesk_email').value,
                                deletable: false,
                                translatable: true,
                                type: 'HELPDESK',
                                shared: false,
                            });
                        }

                        vm.notifyContacts = function() {
                            const contacts = [];

                            vm.contactCollectionResponse.contacts.forEach(contact => {
                                const existingContact = contacts.filter(c => c.email === contact.email);

                                if (contact.checked && !existingContact.length) {
                                    contacts.push({
                                        name: contact.name,
                                        email: contact.email,
                                        shared: contact.isShared,
                                        type: contact.type
                                    });
                                }

                                if (contact.additionalEmail && contact.additionalEmail.length > 0) {
                                    contact.additionalEmail.forEach(email => {
                                        const existingAddress = contacts.filter(c => c.email === email.value);

                                        if (email.value !== contact.email && email.checked && !existingAddress.length) {
                                            contacts.push({
                                                name: contact.name,
                                                email: email.value,
                                                shared: email.isShared,
                                                type: contact.type
                                            });
                                        }
                                    });
                                }
                            });

                            $uibModalInstance.close(contacts);
                        };

                        vm.close = $uibModalInstance.dismiss;
                    }],
                    controllerAs: 'vm',
                    size: 'lg',
                    resolve: {
                        contactsCollectionResponse: function() {
                            return apiClient.get(vm.contactsLink).then(function(response) {
                                return response;
                            });
                        },
                        entityName: function() {
                            return vm.templatePath.indexOf('job') > -1 ? 'JOB' : 'QUOTE';
                        }
                    }
                });

                contactsModal.result.then(function (contacts) {
                    vm.data.updateRecipients = contacts;

                    return apiClient.create(vm.messageCollectionResponse.getLink('create'), vm.data).then(function (response) {
                        vm.sending = false;

                        if (response) {
                            $state.reload();
                        } else {
                            messenger.error('REQUEST_ERROR');
                        }
                    });
                });

                contactsModal.closed.then(function() {
                    vm.sending = false;
                });
            }

            if (!notify) {
                return apiClient.create(vm.messageCollectionResponse.getLink('create'), vm.data).then(function (response) {
                    vm.sending = false;

                    if (response) {
                        $state.reload();
                    } else {
                        messenger.error('REQUEST_ERROR');
                    }
                });
            }
        }

        function translatedEventString(item) {
            let eventKey;

            if (item.metadata && item.metadata.system_message) {
                eventKey = item.metadata.system_message;
            } else if (item._links['buddiedForUser']) {
                eventKey = item.type + '_buddied';
            } else {
                eventKey = item.type;
            }
        
            if (!item._links['user']) {
                eventKey += '_system';
            }
        
            eventKey += '_action';
        
            return eventKey.toUpperCase();
        }
    }
})();
