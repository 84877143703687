(function () {
    'use strict';

    angular
        .module('elogbooks.admin.asset-score-types')
        .controller('AssetScoreTypesController', AssetScoreTypesController);

    AssetScoreTypesController.$inject = ['assetScoreTypesCollectionResponse', 'sortableOptions', '$stateParams', '$state'];

    function AssetScoreTypesController(assetScoreTypesCollectionResponse, sortableOptions, $stateParams, $state) {
        var vm = this;
        vm.assetScoreTypes = assetScoreTypesCollectionResponse.scoreTypes;
        vm.assetScoreTypesCollectionResponse = assetScoreTypesCollectionResponse;

        vm.criteria = {
            id: { type: 'number', title: 'ID', value: $stateParams.id ? parseInt($stateParams.id, 10) : null, min: 1 },
            name: { type: 'text', title: 'NAME', value: $stateParams.name },
            description: { type: 'text', title: 'DESCRIPTION', value: $stateParams.description }
        };

        vm.order = orderAction;
        vm.search = searchAction;

        function orderAction (key) {
            $stateParams.order = key;

            $state.go('.', $stateParams, { reload: $state.current.name });
        }

        function searchAction (params) {
            var override = {
                page: 1
            };

            $state.go('.', angular.extend({}, params, override), { reload: $state.current.name });
        }
    };
})();
