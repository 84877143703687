(function () {
    'use strict';

    angular
        .module('elogbooksFilters')
        .filter('purchaseOrderStatus', purchaseOrderStatus);

    function purchaseOrderStatus ($filter) {
        var statuses = {
            0: 'PURCHASE_ORDER_STATUS_DRAFT',
            'draft': 'PURCHASE_ORDER_STATUS_DRAFT',
            1: 'PURCHASE_ORDER_STATUS_ORDERED',
            'ordered': 'PURCHASE_ORDER_STATUS_ORDERED',
            2: 'PURCHASE_ORDER_STATUS_CANCELLED',
            'cancelled': 'PURCHASE_ORDER_STATUS_CANCELLED',
            3: 'PURCHASE_ORDER_STATUS_PART_RECEIVED',
            'part_received': 'PURCHASE_ORDER_STATUS_PART_RECEIVED',
            4: 'PURCHASE_ORDER_STATUS_COMPLETED',
            'completed': 'PURCHASE_ORDER_STATUS_COMPLETED'
        };

        return function (input) {
            return $filter('translate')(statuses[input]);
        }
    };
})();
