app.config(function ($translateProvider) {
    $translateProvider.translations('en-gb', {
        HELPDESK: 'Helpdesk',
        HELPDESK_DASHBOARD: 'Helpdesk Dashboard',

        QUOTES_LIST: 'Quotes',
        ALARM_AT: 'Alarm At',
        ALARM_DUE_AT: 'Alarm due At',
        ALARM_LOCK: 'Alarm Locked',
        ALARMED: 'Alarmed',

        ALARM_AT_DUE_RANGE_START_DATE_LABEL: 'Alarm at Start',
        ALARM_AT_DUE_RANGE_END_DATE_LABEL: 'Alarm at End',

        HD_NO_RESULTS: 'No Results',
        HD_QUOTES: 'Quotes',
        HD_STATUTORY: 'Statutory',
        HD_REACTIVE: 'Reactive',
        HD_PLANNED: 'Planned',
        HD_ESCALATIONS: 'Escalations',
        HD_SATISFACTION_SURVEYS: 'Satisfaction Surveys',
        HD_AWAITING_PAPERWORK: 'Awaiting Paperwork',

        HD_TOOLTIP_ALARMS: 'Alarms',
        HD_TOOLTIP_AWAITING_APPROVAL: 'Awaiting Approvals',
        HD_TOOLTIP_OVERDUE: 'Overdue',
        HD_TOOLTIP_ESCALATIONS: 'Escalations',
        HD_TOOLTIP_CHASE: 'Chase',
        HD_TOOLTIP_REMINDER: 'Reminder',

        HD_TOOLTIP_REACTIVE_CHASE: 'Chase',
        HD_TOOLTIP_REACTIVE_ACKNOWLEDGE: 'Acknowledgement',
        HD_TOOLTIP_REACTIVE_APPROVAL: 'Approval',
        HD_TOOLTIP_REACTIVE_EXTENSION_APPROVAL: 'Extension Approval',
        HD_TOOLTIP_REACTIVE_EXTENSION_REQUEST: 'Extension Request',
        HD_TOOLTIP_JOB_REACTIVE_EXTENSION_REQUEST: 'Job Extension Request',
        HD_TOOLTIP_QUOTE_REACTIVE_EXTENSION_REQUEST: 'Quote Extension Request',
        HD_TOOLTIP_REACTIVE_PRIORITY_OVERDUE: 'Chase Attendance',
        HD_TOOLTIP_REACTIVE_ALARMS: 'Reminder',

        HD_TOOLTIP_QUOTES_CHASE: 'Chase',
        HD_TOOLTIP_QUOTES_REMINDER: 'Reminder',
        HD_TOOLTIP_QUOTES_EXTENSION_APPROVAL: 'Extension Approval',

        HD_TOOLTIP_AWAITING_PAPERWORK: 'Awaiting Paperwork',

        HD_DAYS_SHORT: '{{days}}d',
        HD_HOURS_SHORT: '{{hours}}h',
        HD_MINUTES_SHORT: '{{minutes}}m',
        HD_SECONDS_SHORT: '{{seconds}}s',

        MOVE_ALARM: 'Move Alarm',
        BULK_MOVE_ALARMS: 'Move Alarms',
        ALARMS_QUEUED_FOR_MOVING: 'Alarms Moved',
        PLEASE_BE_AWARE_THAT_THIS_ACTION_WILL_MOVE_QUOTE_ALARMS: '<i class="fa fa-exclamation-triangle"></i>&nbsp;Please be aware that this action will move the alarm of {{ count }} quote(s) to the time below',
        PLEASE_BE_AWARE_THAT_THIS_ACTION_WILL_MOVE_JOB_ALARMS: '<i class="fa fa-exclamation-triangle"></i>&nbsp;Please be aware that this action will move the alarm of {{ count }} job(s) to the time below'
    });
});
