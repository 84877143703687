(function () {
    'use strict';

    angular
        .module('elogbooks.user.tenants')
        .controller('TenantEmailerController', TenantEmailerController);

    TenantEmailerController.$inject = [
        'lodash',
        'apiClient',
        '$state',
        '$stateParams',
        'messenger',
        'contactsResponse',
        'tenantEmailerService',
        '$rootScope',
        'siteResponse',
        'userPreferenceResponse',
        'elbSettings',
        'userManager'
    ];

    function TenantEmailerController(
        lodash,
        apiClient,
        $state,
        $stateParams,
        messenger,
        contactsResponse,
        tenantEmailerService,
        $rootScope,
        siteResponse,
        userPreferenceResponse,
        elbSettings,
        userManager
    ) {
        var vm = this;
        vm.tenants = tenantEmailerService.tenantsResponse.contacts.length ? tenantEmailerService.tenantsResponse : contactsResponse;
        vm.emailsSent = false;
        vm.emailTenants = emailTenants;
        vm.editRecipients = editRecipients;
        vm.viewAllRecipients = viewAllRecipients;
        vm.cancel = cancel;
        vm.previewRecipients = [];

        vm.replyToAddresses = [];

        if (!lodash.isEmpty(elbSettings.getSetting('general_tenant_emailer_reply_email').value)){
            vm.replyToAddresses.push({
                title: elbSettings.getSetting('general_tenant_emailer_reply_email').value + ' (default)',
                value: elbSettings.getSetting('general_tenant_emailer_reply_email').value
            });
        }

        if (!lodash.isEmpty(elbSettings.getSetting('general_helpdesk_email').value)){
            vm.replyToAddresses.push({
                title: elbSettings.getSetting('general_helpdesk_email').value,
                value: elbSettings.getSetting('general_helpdesk_email').value
            });
        }

        vm.replyToAddresses.push({
         title: userManager.user.email,
         value: userManager.user.email
        });

        // Back to the tenants list if user arrived from a page refresh
        if (tenantEmailerService.isEmpty()) {
            $state.go('^');
        }

        if (tenantEmailerService.email) {
            vm.email = tenantEmailerService.email;
        } else {
            vm.email = {
                files: {
                    links: []
                },
                recipients: {
                    siteContacts: []
                }
            };
        }

        if (lodash.find(userPreferenceResponse, {'name': 'emailTextSignature'})) {
            vm.emailSignature = lodash.find(userPreferenceResponse, {'name': 'emailTextSignature'}).value;
        }

        lodash.forEach(vm.tenants.contacts, function(tenant) {
            lodash.forEach(tenant.contactInformation.fields, function(contactField) {
                if (contactField.type == 'email') {
                    if (vm.previewRecipients.length < 5) {
                        vm.previewRecipients.push(contactField.value);
                    }
                }
            });
        });

        if (tenantEmailerService.tenantFilter) {
            var tenFil = tenantEmailerService.tenantFilter;
            vm.email.filter = {
                name: tenFil.contactName,
                email: tenFil.contactEmail,
                active: tenFil.active,
                company: tenFil.contactCompany,
                region: tenFil.region,
                siteIds: tenFil.siteIds
            };
        } else {
            vm.email.filter = null;
        }

        function viewAllRecipients () {
            tenantEmailerService.email = vm.email;
            tenantEmailerService.email.selectedTenants = [];
            tenantEmailerService.email.recipients = {
                siteContacts: []
            };
            $stateParams.contactPage = 1;
            $state.go('.view-recipients');
        }

        function editRecipients() {
            tenantEmailerService.email = vm.email;
            tenantEmailerService.email.selectedTenants = [];
            tenantEmailerService.email.recipients = {
                siteContacts: []
            };
            $rootScope.skipDirtyCheck = true;
            $state.go('^').then(function() {
                $rootScope.skipDirtyCheck = false;
            });
        }

        function emailTenants() {
            // Used to disable the submit button/prevent multiple deliveries
            vm.emailsSent = true;

            if(siteResponse) {
                if(vm.email.hasOwnProperty('filter')) {
                    vm.email.filter.site = siteResponse.getData().id;
                } else {
                    vm.email.filter = {
                        site: siteResponse.getData().id
                    }
                }
            }

            if(tenantEmailerService.emailFiltered) {
                vm.email.recipients.siteContacts = [];
            } else {
                lodash.forEach(vm.tenants.contacts, function(tenant) {
                    vm.email.recipients.siteContacts.push({href: tenant.getLink('self')});
                });
            }

            if (vm.signature === true) {
                vm.email.emailSignature = vm.emailSignature;
            }

            apiClient.create(contactsResponse.getLink('email-tenants'), vm.email).then(function (isSuccess) {
                if (isSuccess) {
                    tenantEmailerService.reset();
                    tenantEmailerService.email = null;
                    $state.go('^').then(function() {
                        messenger.success('EMAIL_S_SENT');
                    });
                } else {
                    messenger.error('REQUEST_ERROR');
                }
            });
        }

        function cancel () {
            tenantEmailerService.email = null;
            $state.go('^');
        }
    }

})();
