angular.module('elogbooksControllers').controller('SiteContactChannelsController', function ($rootScope, $scope, $state, apiClient, messenger, lodash, contactInformationResponse) {

    // This controller works on a copy of contact information fields
    //  so the front-end won't get updated until the user will press Update
    $scope.contactInformation = contactInformationResponse.getData();
    $scope.contactInformationFields = lodash.map( $scope.contactInformation.fields, lodash.clone);

    $scope.newRow = function () {
        $scope.contactInformationFields.push({
            type: "",
            value: "",
            notes: ""
        })
    }

    $scope.delete = function (key) {
        $scope.contactInformationFields.splice(key, 1);
    }

    $scope.$watch('[contactInformationFields,contactInformation]', function () {
        if (! lodash.isEqual($scope.contactInformationFields,  $scope.contactInformation.fields)) {
            $scope.allowUpdate = true;
        } else {
            $scope.allowUpdate = false;
        }
    }, true);

    $scope.save = function () {
        $scope.contactInformation.fields = lodash.map($scope.contactInformationFields, lodash.clone);

        apiClient.update(contactInformationResponse.getLink('self'), angular.copy($scope.contactInformation)).then(function (response) {
            if (!response) {
                messenger.error('There was a problem with your request');
            } else if ($rootScope.previousState !== '') {
                $state.go($rootScope.previousState, $rootScope.previousStateParams).then(function () {
                    messenger.success('Contact Information updated');
                });
            }
        });
    }
});
