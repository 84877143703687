angular.module('elogbooksServices').service('userPermissions', function(filterPermissions) {

    // 'manage_assets',
    // 'manage_statutory_types',

    var permissions = [
        { name: 'user_permission_manage_action_types' },
        { name: 'user_permission_manage_asset_types' },
        { name: 'user_permission_manage_associate_types' },
        { name: 'user_permission_manage_certificate_types' },
        { name: 'user_permission_manage_contract_types' },
        { name: 'user_permission_manage_job_requisites' },
        { name: 'user_permission_manage_job_groups' },
        { name: 'user_permission_manage_letters' },
        { name: 'user_permission_manage_meter_info' },
        { name: 'user_permission_manage_priorities' },
        { name: 'user_permission_manage_regions' },
        { name: 'user_permission_manage_roles' },
        { name: 'user_permission_manage_satisfaction_surveys' },
        { name: 'user_permission_manage_sectors' },
        { name: 'user_permission_manage_service_providers' },
        { name: 'user_permission_manage_service_provider_groups' },
        { name: 'user_permission_manage_service_routines' },
        { name: 'user_permission_manage_settings' },
        { name: 'user_permission_manage_sites' },
        { name: 'user_permission_manage_site_groups' },
        { name: 'user_permission_manage_site_note_types' },
        { name: 'user_permission_manage_site_relationships' },
        { name: 'user_permission_manage_surveys' },
        { name: 'user_permission_manage_tags' },
        { name: 'user_permission_manage_trade_types' },
        { name: 'user_permission_manage_users' },
        { name: 'user_permission_manage_duty_management_schedules' },
        { name: 'user_permission_manage_master_tasks' },
        { name: 'user_permission_manage_charge_types' },
        { name: 'user_permission_manage_asset_score_types' },
        { name: 'user_permission_view_action_types' },
        { name: 'user_permission_view_asset_types' },
        { name: 'user_permission_view_associate_types' },
        { name: 'user_permission_view_certificate_types' },
        { name: 'user_permission_view_contract_types' },
        { name: 'user_permission_view_job_requisites' },
        { name: 'user_permission_view_job_groups' },
        { name: 'user_permission_view_letters' },
        { name: 'user_permission_view_meter_info' },
        { name: 'user_permission_view_priorities' },
        { name: 'user_permission_view_regions' },
        { name: 'user_permission_view_roles' },
        { name: 'user_permission_view_satisfaction_surveys' },
        { name: 'user_permission_view_sectors' },
        { name: 'user_permission_view_service_providers' },
        { name: 'user_permission_view_service_provider_groups' },
        { name: 'user_permission_view_service_routines' },
        { name: 'user_permission_view_settings' },
        { name: 'user_permission_view_sites' },
        { name: 'user_permission_view_site_groups' },
        { name: 'user_permission_view_site_note_types' },
        { name: 'user_permission_view_surveys' },
        { name: 'user_permission_view_tags' },
        { name: 'user_permission_view_trade_types' },
        { name: 'user_permission_view_users' },
        { name: 'user_permission_view_duty_management_schedules' },
        { name: 'user_permission_view_charge_types' },
        { name: 'user_permission_view_asset_score_types' },
        { name: 'user_permission_can_receive_support_messages' },
        { name: 'user_permission_can_create_global_audit_templates' },
        { name: 'user_permission_access_service_provider_app' },
        { name: 'user_permission_access_client_app' },
        { name: 'user_permission_manage_service_types' },
        { name: 'user_permission_view_service_types' },
        { name: 'user_permission_manage_imports' },
        { name: 'user_permission_manage_exports' },
        { name: 'user_permission_manage_core_clients' },
        { name: 'user_permission_manage_billing_types' },
        { name: 'user_permission_kpi_trend_export' },
        { name: 'user_permission_open_close' },
        { name: 'user_permission_manage_spillage_types' },
        { name: 'user_permission_view_spillage_types' },
        { name: 'user_permission_manage_missed_patrol_reasons' },
        { name: 'user_permission_view_forms' },
        { name: 'user_permission_manage_forms' },
        { name: 'user_permission_manage_waste_management' },
        { name: 'user_permission_manage_webhooks' },
        { name: 'user_permission_view_request_logs' },
        { name: 'user_permission_manage_document_types' },
        { name: 'user_permission_view_document_types' },
        { name: 'user_permission_add_edit_job_requisites'},
        { name: 'user_permission_manage_labour_types', module: 'finance' },
        { name: 'user_permission_manage_suppliers', module: 'finance' },
        { name: 'user_permission_manage_pricing_schedules', module: 'finance' },
        { name: 'user_permission_manage_purchase_orders', module: 'finance' },
        { name: 'user_permission_manage_departments', module: 'absence_planner' },
        { name: 'user_permission_manage_billing_settings', module: 'finance' },
        { name: 'user_permission_manage_vat_rates', module: 'finance' },
        { name: 'user_permission_manage_sales_invoice', module: 'finance' },
        { name: 'user_permission_manage_finance', module: 'finance' },
        { name: 'user_permission_can_book_holidays', module: 'absence_planner' },
        { name: 'user_permission_absence_admin', module: 'absence_planner' },
        { name: 'user_permission_manage_timesheets', module: 'timesheets' },
        { name: 'user_permission_manage_timesheet_types', module: 'timesheets' },
        { name: 'user_permission_view_credit_notes', module: 'finance' },
        { name: 'user_permission_edit_credit_notes', module: 'finance' },
        { name: 'user_permission_create_credit_notes', module: 'finance' },
        { name: 'user_permission_can_complete_unmatched_purchase_orders', module: 'finance' },
        { name: 'user_permission_bulk_move_alarms' },
        { name: 'user_permission_client_admin_manage_users', module: 'client_admin_user' },
        { name: 'user_permission_client_admin_manage_job_groups', module: 'client_admin_user' },
        { name: 'user_permission_client_admin_manage_regions', module: 'client_admin_user' },
        { name: 'user_permission_client_admin_manage_sectors', module: 'client_admin_user' },
        { name: 'user_permission_client_admin_manage_surveys', module: 'client_admin_user' },
        { name: 'user_permission_client_admin_manage_document_types', module: 'client_admin_user' },
        { name: 'user_permission_client_admin_manage_job_requisites', module: 'client_admin_user' },
        { name: 'user_permission_client_admin_manage_sites', module: 'client_admin_user' },
        { name: 'user_permission_client_admin_manage_forms', module: 'client_admin_user' },
        { name: 'user_permission_client_admin_edit_partnerships', module: 'client_admin_user' },
        { name: 'user_permission_client_admin_edit_locations', module: 'client_admin_user' },
        { name: 'user_permission_view_efs_values' },
        { name: 'user_permission_import_sfg_xml' },
        { name: 'user_permission_view_client_billing' },
        { name: 'user_permission_manage_client_billing' },
        { name: 'user_permission_client_admin_manage_imports', module: 'client_admin_user' }
    ];

    this.getAll = function () {
        return filterPermissions.filterPermissions(permissions, 'VALUE');
    };

    this.getAllTranslated = function () {
        return filterPermissions.filterPermissions(permissions, 'TRANSLATED');
    };

    return this;
});
