(function () {
    'use strict';

    angular
        .module('elogbooks.user.duty-management')
        .controller('DutyManagementUserController', DutyManagementUserController);

    DutyManagementUserController.$inject = ['$state', '$stateParams', 'apiClient', 'messenger', 'dutyManagementScheduleResponse', 'userCollectionResponse'];

    function DutyManagementUserController ($state, $stateParams, apiClient, messenger, dutyManagementScheduleResponse, userCollectionResponse) {
        var vm = this;
            vm.dutyManagementSchedule = dutyManagementScheduleResponse;
            vm.userSelect = {
                response : userCollectionResponse,
                link : userCollectionResponse.getData()._links.self.href,
                required : true,
                selected : {
                    href :  vm.dutyManagementSchedule._links.user.href,
                    value : vm.dutyManagementSchedule._links.user.title
                },
                responseKeyPath: 'users',
                itemHrefPath: '_links.self.href',
                itemValuePath: 'name'
            };
            vm.update = updateAction;

        function updateAction() {
            var dutyManagementForm = {
                _links : {}
            };

            if (vm.userSelect.selected) {
                dutyManagementForm._links.user = {
                    href : vm.userSelect.selected.href
                }
            }

            apiClient.update(vm.dutyManagementSchedule.getLink('self'), dutyManagementForm).then(function (response) {
                if (response) {
                    $state.go('^', $stateParams, { reload: true }).then(function () {
                        messenger.success('EDIT_SUCCESS');
                    });
                } else {
                    messenger.error('REQUEST_ERROR');
                }
            });
        }
    }
})();
