(function () {
    'use strict';

    angular
        .module('elogbooks.user.finance')
        .config(registerCreditNotesRoutes)
        .config(registerCreditNotesRoutesList)
        .config(registerCreditNotesRoutesDetails)
        .config(registerCreditNotesWorkflow)
        .config(registerCreditNotesRoutesAdd)
        .config(registerCreditNotesRoutesEdit);

    function registerCreditNotesRoutes($stateProvider) {
        $stateProvider
            .state('dashboard.user.finance.credit-notes', {
                abstract: true,
                parent: 'dashboard.user.finance',
                template: '<bookerize></bookerize>',
                ncyBreadcrumb: {
                    skip: true
                }
            });
    }

    function registerCreditNotesRoutesList($stateProvider) {
        var creditNotesParams = {
            creditNotePage: '1',
            creditNoteLimit: '30',
            creditNoteOrder: 'id',
            status: {array: true},
            createdDateRange: null,
            salesInvoiceId: null
        }

        $stateProvider
            .state('dashboard.user.finance.credit-notes.list', {
                url: '/credit-notes?' + Object.keys(creditNotesParams).join('&'),
                parent: 'dashboard.user.finance.credit-notes',
                resolve: {
                    userResponse: function(user) {
                        return user;
                    },
                    creditNotesCollectionResponse: function (apiClient, userResponse, $stateParams) {
                        var params = angular.copy($stateParams);
                        params = angular.extend({}, params, {
                            page:   $stateParams.creditNotePage,
                            order:  $stateParams.creditNoteOrder,
                            limit:  $stateParams.creditNoteLimit,
                            'status[]': $stateParams.status,
                        });
                        return apiClient.get(userResponse.getLink('creditnotes'), params).then(function(response) {
                            return response || apiClient.noResourceFound();
                        });
                    }
                },
                params: creditNotesParams,
                views: parseBook({
                    'elogbooks-left@dashboard.user.finance': {
                        templateUrl: '/modules/user/finance/credit-notes/credit-notes-list.html',
                        controller: 'FinanceCreditNotesController',
                        controllerAs: 'vm'
                    }
                }),
                ncyBreadcrumb: {
                    label: '{{ ::"CREDIT_NOTES_LIST" | translate }}'
                }
            });
    }

    function registerCreditNotesRoutesDetails($stateProvider) {
            $stateProvider
                .state('dashboard.user.finance.credit-notes.list.details', {
                    url: '/details/{creditNoteResource}',
                    parent: 'dashboard.user.finance.credit-notes.list',
                    views:  {
                        '@dashboard.user.finance': {
                            templateUrl: '/modules/user/finance/credit-notes/details/credit-note-details.html',
                            controller: 'FinanceCreditNoteDetailsController',
                            controllerAs: 'vm',
                        },
                        'details.info@dashboard.user.finance.credit-notes.list.details': {
                            templateUrl: '/modules/user/finance/credit-notes/details/details/credit-note-details-info.html',
                            controller: 'FinanceCreditNoteDetailsInfoController',
                            controllerAs: 'vm'
                        },
                        'status@dashboard.user.finance.credit-notes.list.details': {
                            templateUrl: '/modules/user/finance/credit-notes/details/status/credit-note-status.html',
                            controller: 'FinanceCreditNoteStatusController',
                            controllerAs: 'vm'
                        },
                        'feed@dashboard.user.finance.credit-notes.list.details': {
                            templateUrl: '/modules/user/finance/credit-notes/details/event/credit-note-feed.html',
                            controller: 'FinanceCreditNoteEventFeedController',
                            controllerAs: 'vm'
                        },
                        'workflow@dashboard.user.finance.credit-notes.list.details': {
                            templateUrl: '/modules/user/finance/credit-notes/details/workflow/workflow.html',
                            controller: 'FinanceCreditNoteWorkflowController',
                            controllerAs: 'vm'
                        }
                    },
                    resolve: {
                        creditNoteResponse: function(apiClient, $stateParams) {
                            return apiClient.get($stateParams.creditNoteResource.decode()).then(function(response) {
                                return response || apiClient.noResourceFound();
                            });
                        },
                        eventCollectionResponse: function(apiClient, creditNoteResponse) {
                            return apiClient.get(creditNoteResponse.getLink('events'), { datagroup: 'list' }).then(function(response) {
                                return response || apiClient.noResourceFound();
                            })
                        }
                    },
                    ncyBreadcrumb: {
                        label: '{{ ::$resolves().creditNoteResponse.id }}'
                    }
                })
        }

        function registerCreditNotesWorkflow($stateProvider)
        {
            var workflowRoute = 'dashboard.user.finance.credit-notes.list.details.workflow';

            $stateProvider
                .state(workflowRoute, {
                    url: '/workflow',
                    abstract: true,
                    parent: 'dashboard.user.finance.credit-notes.list.details',
                    views: {
                        '@dashboard.user.finance': {
                            templateUrl: '/modules/user/finance/credit-notes/details/workflow/workflow-add-note/workflow-add-note.html',
                            controller: 'FinanceCreditNoteWorkflowAddNoteController',
                            controllerAs: 'vm',
                        },
                    },
                    resolve: {
                        creditNoteResponse : function(creditNoteResponse) {
                            return creditNoteResponse;
                        }
                    },
                    ncyBreadcrumb: {
                        label: '{{ ::"WORKFLOW" | translate }}'
                    }
                });

            // Register workflows routing actions
            ['mark-as-issued', 'mark-as-cancelled', 'reopen'].map(function(type) {
                $stateProvider
                    .state(workflowRoute + '.' + type, {
                        url: '/' + type,
                        abstract: false,
                        parent: workflowRoute,
                        ncyBreadcrumb: {
                            label: '{{ ::"' + type.replaceAll('-', '_') + '" | uppercase | translate }}'
                        }
                    });
            });
        }

        function registerCreditNotesRoutesAdd($stateProvider) {
            $stateProvider
                .state('dashboard.user.finance.credit-notes.list.add', {
                    url: '/add',
                    parent: 'dashboard.user.finance.credit-notes.list',
                    views: {
                        '@dashboard.user.finance': {
                            templateUrl: '/modules/user/finance/credit-notes/add-edit/add-edit.form.html',
                            controller: 'CreditNoteAddEditController',
                            controllerAs: 'vm'
                        }
                    },
                    resolve: {
                        creditNoteResponse: function() {
                            return {};
                        }
                    },
                    ncyBreadcrumb: {
                        label: '{{ ::"ADD" | translate }}'
                    }
                });
        }

        function registerCreditNotesRoutesEdit($stateProvider) {
            $stateProvider
                .state('dashboard.user.finance.credit-notes.list.details.edit', {
                    url: '/edit/{resource}',
                    parent: 'dashboard.user.finance.credit-notes.list.details',
                    views: {
                        '@dashboard.user.finance': {
                            templateUrl: '/modules/user/finance/credit-notes/add-edit/add-edit.form.html',
                            controller: 'CreditNoteAddEditController',
                            controllerAs: 'vm'
                        }
                    },
                    ncyBreadcrumb: {
                        label: '{{ ::"EDIT" | translate }}'
                    }
                });
        }
})();
