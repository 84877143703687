/**
 * QuoteParamsService
 */
angular.module('elogbooksServices').service('quoteParamsService', function () {

    this.getQuoteParams = getQuoteParams;

    /**
     * Format state params into expected quote filter params for api
     *
     * @param {object} stateParams - State params
     * @returns {object} params
     */
    function getQuoteParams($stateParams, user, selectedSiteResponse) {
        var params = {
            page: $stateParams.quoteRequestsPage,
            limit: $stateParams.quoteRequestsLimit,
            order: $stateParams.quoteRequestsOrder,
            id: $stateParams.quoteId,
            'quoteStatus[]': $stateParams.quoteStatus,
            'quotePriority[]': $stateParams.quotePriority,
            'sites[]': $stateParams.sites,
            'approver[]': $stateParams.approver,
            'siteRegion[]': $stateParams.siteRegion,
            'serviceProviders[]': $stateParams.serviceProviders,
            'siteGroups[]': $stateParams.siteGroups,
            overdue: $stateParams.overdue,
            completionDueAtBetween: $stateParams.quoteCompletionDueAtBetween,
            lastSubmittedAtBetween: $stateParams.quoteLastSubmittedAtBetween,
            includeNeverSubmitted: $stateParams.quoteIncludeNeverSubmitted,
            iAmTheCurrentApprover: $stateParams.iAmTheCurrentApprover,
            reminderAtStart: $stateParams.reminderAtStart,
            reminderAtEnd: $stateParams.reminderAtEnd,
            completionDueStart: $stateParams.completionDueStart,
            completionDueEnd: $stateParams.completionDueEnd,
            lastSubmittedAtStart: $stateParams.lastSubmittedAtStart,
            lastSubmittedAtEnd: $stateParams.lastSubmittedAtEnd,
            serviceProviderName: $stateParams.serviceProviderName,
            serviceProvider: $stateParams.serviceProvider,
            remedial: $stateParams.quoteRemedial,
        };

        //Set the approver as the current user when transferring from dashboard
        if (user && $stateParams.approver) {
            if ($stateParams.approver[0] === 'true') {
                $stateParams.approver = [user.id];
                params['approver[]'] = user.id;
            }
        }

        // add filter for site, if passed via selectedSite or viewing quotes within a site
        if (selectedSiteResponse) {
            $stateParams.sites = [];
            params["sites[]"] = selectedSiteResponse.id;
        }

        return params;
    }
});
