(function() {
    'use strict';

    angular
        .module('elogbooksServices')
        .service('disableSubmitService', DisableSubmitService);

    DisableSubmitService.$inject = [];

    function DisableSubmitService() {
        this.disableSubmit = disableSubmit;
        this.enableSubmit = enableSubmit;

        function disableSubmit(element, spinner) {
            if (spinner instanceof jQuery) {
                spinner.toggleClass('fa-spinner fa-pulse', true);
            }

            return toggleSubmit(element, true);
        }

        function enableSubmit(element, spinner) {
            if (spinner instanceof jQuery) {
                spinner.toggleClass('fa-spinner fa-pulse', false);
            }
            return toggleSubmit(element, false);
        }

        function toggleSubmit(element, disable) {
            if (!element || typeof element === 'undefined'
                || !(element instanceof angular.element)
            ) {
                element = angular.element('button[type="submit"]');
            }

            if (disable && element.length > 0) {
                element.attr('disabled', 'true');
            } else if(element.length > 0) {
                element.removeAttr('disabled');
            }

            return element;
        }

        return this;
    }
})();
