(function () {
    'use strict';

    angular
        .module('elogbooks.admin.regions')
        .controller('RegionInfoController', RegionInfoController);

    RegionInfoController.$inject = ['regionResponse'];

    function RegionInfoController (regionResponse) {
        var vm = this;
        vm.region = regionResponse;
    }
})();
