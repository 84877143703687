(function() {
    'use strict';

    angular
        .module('elogbooks.admin.core-clients')
        .controller('CoreClientsSiteGroupsController', CoreClientsSiteGroupsController);

    CoreClientsSiteGroupsController.$inject = [
        'coreClientResponse',
        'siteGroupCollectionResponse',
        '$state',
        'apiClient',
        'validationService',
        'messenger',
        '$rootScope'
    ];

    function CoreClientsSiteGroupsController(
        coreClientResponse,
        siteGroupCollectionResponse,
        $state,
        apiClient,
        validationService,
        messenger,
        $rootScope
    ) {
        var vm = this;

        vm.coreClient = coreClientResponse;
        vm.siteGroupCount = siteGroupCollectionResponse ? siteGroupCollectionResponse.count : 0;
        vm.siteGroups = siteGroupCollectionResponse?.sitegroups || [];
        vm.siteGroupCollectionResponse = siteGroupCollectionResponse;
        vm.searchQuery = $state.params.sgName || '';
        vm.isFiltered = vm.searchQuery.length > 0;
        vm.delete = deleteGroup;
        vm.validationService = validationService;

        vm.searchGroup = (searchQuery) => {
            vm.searchQuery = searchQuery;
            vm.isFiltered = true;

            if (($state.params.sgName || '') === searchQuery) {
                return true;
            }

            return $state.go('.', { sgName: searchQuery, sgPage: 1 });
        }

        function deleteGroup(group) {
            if (group.hasOwnProperty('removal')) {
                return;
            }

            group.removal = true;

            apiClient.delete(coreClientResponse.getLink('remove-site-group'), {
                siteGroupId: group.id
            }).then(response => {
                if (response) {
                    for (const i in vm.siteGroups) {
                        if (vm.siteGroups[i].id === group.id) {
                            vm.siteGroups.splice(i, 1);
                            break;
                        }
                    }

                    vm.siteGroupCount--;
                    vm.siteGroupCollectionResponse.count = vm.siteGroupCount;
                    messenger.success('SITE_GROUP_REMOVED', {...group});
                } else {
                    messenger.error('UNABLE_TO_REMOVE_SITE_GROUP', {...group});
                }
            }).catch(error => messenger.error('UNABLE_TO_REMOVE_SITE_GROUP', {...group}));
        }
    }
})();

