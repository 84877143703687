app.config(function ($translateProvider) {
    $translateProvider.translations('zh-hk', {
        LOCATION: '地點',
        LOCATIONS: '地點',

        LOCATION_ADD: '新增地點',
        LOCATION_EDIT: '修改地點',
        LOCATION_LIST: '地點',

        LOCATION_CREATED: '地點已建立',
        LOCATION_UPDATED: '地點已更新',
        LOCATION_DELETED: '地點已刪除',

    });
});
