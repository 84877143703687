(function () {
    'use strict';

    angular
        .module('elogbooks.user.manage-operatives')
        .controller('ManageOperativeServiceTypeController', ManageOperativeServiceTypeController);

    ManageOperativeServiceTypeController.$inject = ['$stateParams', 'base64', 'apiClient'];

    function ManageOperativeServiceTypeController($stateParams, base64, apiClient) {
        var vm = this;
    }
})();
