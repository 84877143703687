(function() {
    'use strict';

    angular
        .module('elogbooks.admin.pricing-schedules')
        .controller('PricingSchedulesStatusController', PricingSchedulesStatusController);

    PricingSchedulesStatusController.$inject = [
        'pricingScheduleResponse',
        '$state',
        'pricingScheduleVersionsResponse',
        'confirmationDateModal',
        'apiClient',
        'messenger',
        'confirmationModal',
        'modalForm'
    ];

    function PricingSchedulesStatusController(
        pricingScheduleResponse,
        $state,
        pricingScheduleVersionsResponse,
        confirmationDateModal,
        apiClient,
        messenger,
        confirmationModal,
        modalForm
    ) {
        var vm = this;
        vm.pricingSchedule = pricingScheduleResponse;
        vm.pricingScheduleVersionsResponse = pricingScheduleVersionsResponse;
        vm.selectedVersion = vm.pricingSchedule
        // ESPL-427 hide deactivate button
        vm.deactivateButton = false;

        if (vm.selectedVersion.status !== 'INACTIVE') {
            vm.activeFromDate = vm.selectedVersion.activeFromDate;
        }

        vm.loadVersion = loadVersion;
        vm.createNewVersion = createNewVersion;
        vm.activateVersion = activateVersion;
        vm.deactivateVersion = deactivateAction;
        vm.copyAction = copyAction;

        function loadVersion() {
            $state.go($state.current.name,
                { pricingScheduleResource: vm.selectedVersion.href.encode(), changeVersion: true },
                { reload: true }
            );
        }

        function createNewVersion() {
            return confirmationModal.open().result.then(function (createResponse) {
                return apiClient.create(vm.pricingSchedule.getLink('versions'), {}).then(function(createResponse) {
                    if (createResponse) {
                        $state.go('dashboard.admin.pricing-schedules.list.details.labourRates',
                            {pricingScheduleResource: createResponse.getLink('self').encode()},
                            {reload: $state.get('^.^')}
                        ).then(function() {
                            messenger.success('PRICING_SCHEDULES_NEW_VERSION_CREATED');
                        });
                    } else {
                        messenger.error('REQUEST_ERROR');
                    }
                });
            });
        }

        function activateVersion() {
            return confirmationDateModal.open({
                dateLabel: 'ACTIVE_FROM',
                minDate: new Date(),
                submit: function(config) {
                    return apiClient.update(vm.selectedVersion.getLink('activate'), { activeFromDate: config.date }).then(function (isSuccess) {
                        if(isSuccess) {
                            $state.go('.', {resource: vm.selectedVersion.getLink('self').encode()}, {reload: $state.get('^.^')}).then(function() {
                                messenger.success('PRICING_SCHEDULE_ACTIVATED');
                            });
                        } else {
                            messenger.error('REQUEST_ERROR');
                        }
                    });
                }
            })
        }

        function deactivateAction() {
            return confirmationModal.open().result.then(function() {
                apiClient.update(vm.selectedVersion.getLink('deactivate'), {}).then(function(isSuccess) {
                    if (isSuccess) {
                        $state.go('.', {resource: vm.selectedVersion.getLink('self').encode()}, {reload: $state.get('^.^')}).then(function() {
                            messenger.success('PRICING_SCHEDULE_DEACTIVATED');
                        });
                    } else {
                        messenger.error('REQUEST_ERROR');
                    }
                });
            });
        }

        function copyAction() {
            modalForm.open({
                entityName: 'PRICING_SCHEDULES',
                responseEntity: vm.selectedVersion,
                data: {}, // no pre-population - keep empty form
                formControlsTemplate: '/modules/admin/pricing-schedules/details/modals/copy-modal-form.html',
                title: 'COPY_SCHEDULE',
                submitButtonText: 'COPY_SCHEDULE',
            }).result.then(function(formData) {
                apiClient.create(vm.selectedVersion.getLink('copy'), { summary: formData.summary }).then(function(copy) {
                    if (copy) {
                        $state.go('^.labourRates', {
                            pricingScheduleResource: copy.getLink('self').encode(),
                            reload: $state.get('^.^')
                        }).then(function() {
                            messenger.success('PRICING_SCHEDULE_COPIED');
                        });
                    } else {
                        messenger.error('REQUEST_ERROR');
                    }
                });
            });
        }
    }
})();
