/**
 * Required parameters
 * @collectionResponse The collection response with the links to load the next page.
 * @arrayItems The array used in the loop to list all items (vm.sections, vm.patrolPoints, etc).
 * @entity The name of entity to iterate (sections, patrolPoints, etd).
 *
 * Optional parameters
 * @originalItems This is needed when we have to reset the list (example when reset order in audit sections).
 * @callback This is a function if you need to post process the new items.
 * @params If you need to add params to the api request.
 */
angular.module('elogbooksDirectives').directive('loadMore', [function (apiClient, messenger) {
    return {
        restrict: 'AE',
        scope: {
            collectionResponse: '=',
            arrayItems: '=',
            entity: '=',
            originalItems: '=',
            callback: '=',
            params: '='
        },
        templateUrl: '/modules/directives/load-more/load-more.html',
        controller: function ($scope, apiClient) {
            $scope.currentPage = $scope.collectionResponse.page;
            $scope.limit = $scope.collectionResponse.limit;
            $scope.loadingMore = false;

            $scope.loadMoreItems = function(){
                if ($scope.loadingMore) {
                    return;
                }

                if ($scope.collectionResponse.page < $scope.collectionResponse.pages) {
                    $scope.loadingMore = true;

                    apiClient.get($scope.collectionResponse.getLink('next'), $scope.params).then(function (response) {
                        $scope.collectionResponse[$scope.entity] = $scope.collectionResponse[$scope.entity].concat(response[$scope.entity]);
                        $scope.collectionResponse.page = response.page;
                        $scope.collectionResponse.pages = response.pages;
                        $scope.collectionResponse._links = response._links;
                        $scope.collectionResponse.noMore = $scope.collectionResponse.page === $scope.collectionResponse.pages;
                        $scope.arrayItems = $scope.collectionResponse[$scope.entity];
                        $scope.currentPage = $scope.collectionResponse.page;
                        $scope.loadingMore = false;

                        if ($scope.originalItems) {
                            $scope.originalItems = $scope.originalItems.concat(response[$scope.entity]);
                        }

                        if ($scope.callback) {
                            $scope.callback($scope.arrayItems);
                        }
                    });
                }
            };

            $scope.calculatePageItems = function() {
                return $scope.currentPage * $scope.limit > $scope.collectionResponse.count ? $scope.collectionResponse.count : $scope.currentPage * $scope.limit;
            }
        }
    }
}]);
