(function() {
    'use strict';

    angular
        .module('elogbooks.common.jobs.add')
        .controller('CommonJobAddReporterController', CommonJobAddReporterController);

    CommonJobAddReporterController.$inject = ['$rootScope', '$scope', '$state', 'apiClient', 'userCollectionResponse', 'siteResourceResponse', 'siteRelationshipResponse', '$q', 'userManager', '$timeout', 'messenger'];

    function CommonJobAddReporterController ($rootScope, $scope, $state, apiClient, userCollectionResponse, siteResourceResponse, siteRelationshipResponse, $q, userManager, $timeout, messenger) {
        $scope.form.$setPristine();
        var vm = $scope.vm;
        vm.canNext = true;
        vm.canPrevious = true;
        vm.canSubmit = false;
        vm.title = 'JOB_ADD_REPORTER_DETAILS';
        $rootScope.skipDirtyCheck = false;
        vm.canSeeTenants = siteResourceResponse.getLink('tenantLogging') !== null;

        if (!vm.data.reporter) {
            vm.data.reporter = {};
            vm.data.reporter.notifyOnCreate = true;
            vm.data.reporter.notifyOnComplete = true;
            vm.data.reporter.declineEmail = false;
        }
        if (!vm.data.reporterTenant) {
            vm.data.reporterTenant= {};
            vm.data.reporterTenant.notifyOnCreate = false;
            vm.data.reporterTenant.notifyOnComplete = false;
        }

        vm.changeUser = changeUser;
        vm.changeTenant = changeTenant;
        vm.changeRelationship = changeRelationship;
        vm.next = nextAction;
        vm.previous = previousAction;
        vm.resetReporter = resetReporter;
        vm.resetTenantReporter = resetTenantReporter;
        vm.findPseudoUsers = findPseudoUsers;
        vm.pseudoUserSelected = pseudoUserSelected;
        vm.toggleDeclineEmail = toggleDeclineEmail;
        vm.pseudoUserSetInactive = pseudoUserSetInactive;
        vm.toggleSuggestions = toggleSuggestions;

        vm.userReporterResponse = {};

        vm.selectedUserReporterModel = {
            response: userCollectionResponse,
            link: userManager.user.getLink('users'),
            linkParameters: { 'site': siteResourceResponse.getData().id, status: 'active' },
            required: true,
            responseKeyPath: 'users',
            itemHrefPath: '_links.self.href',
            itemValuePath: 'name',
            onSelect: vm.changeUser,
            onRemove: vm.changeUser,
            disabled: vm.enterReporterDetailsManually
        };

        vm.selectedTenantReporterModel = {
            response: userCollectionResponse,
            link: siteResourceResponse.getLink('tenantLogging'),
            linkParameters: {'site': siteResourceResponse.getData().id, 'active': 'yes', 'order': 'name'},
            required: true,
            responseKeyPath: 'contacts',
            itemHrefPath: '_links.self.href',
            itemValuePath: 'nameAndCompany',
            onSelect: vm.changeTenant,
            onRemove: vm.changeTenant
        };

        vm.selectedRelationshipModel = {
            response: siteRelationshipResponse,
            link: siteRelationshipResponse.getLink('self'),
            required: true,
            responseKeyPath: 'siterelationships',
            itemHrefPath: '_links.self.href',
            itemValuePath: 'name',
            onSelect: vm.changeRelationship,
            onRemove: vm.changeRelationship
        };

        if (! vm.siteNotesConfirmed) {
            $state.go(vm.getRoute('site'), {}, { reload: false });
        }

        if (typeof vm.selectedUserReporter !== 'undefined') {
            vm.selectedUserReporterModel.selected = {
                object: vm.selectedUserReporter,
                href: vm.selectedUserReporter.getLink('self'),
                value: vm.selectedUserReporter.name
            };

            changeUser();
        }

        if (typeof vm.selectedTenantReporter !== 'undefined') {
            vm.selectedTenantReporterModel.selected = {
                object: vm.selectedTenantReporter,
                href: vm.selectedTenantReporter.getLink('self'),
                value: vm.selectedTenantReporter.nameAndCompany
            };

            changeTenant();
        }

        function resetReporter () {
            if (vm.selectedUserReporterModel.disabled) {
                vm.selectedUserReporterModel.disabled = vm.enterReporterDetailsManually;
                vm.selectedUserReporterModel.required = true;
            } else {
                vm.selectedUserReporterModel.disabled = true;
                vm.selectedUserReporterModel.required = false;
            }

            if (vm.enterReporterDetailsManually) {
                vm.data._links.reporter = null;
                vm.data.reporter = null;
            }
        }

        function resetTenantReporter () {
            vm.submitDisabled = false;
        }

        function changeUser () {
            if (vm.selectedUserReporterModel.selected && vm.selectedUserReporterModel.selected.object) {
                vm.selectedUserReporter = vm.selectedUserReporterModel.selected.object;

                vm.submitDisabled = true;

                $q.all([
                    apiClient.get(vm.selectedUserReporter.getLink('self')).then(function (response) {
                        if (typeof vm.data.reporter === 'undefined' || vm.data.reporter === null || vm.data.reporter.id != response.id) {
                            vm.data.reporter = vm.userReporterResponse = response;
                            vm.data.reporter.phoneNumbers = [];
                            angular.forEach(response.contactInformation.fields, function (value, index) {
                                if (value.type === 'telephone') {
                                    vm.data.reporter.phoneNumbers.push(value);
                                }
                            });
                        }
                    })
                ]).then(function () {
                    vm.data._links.reporter = vm.selectedUserReporter.getLink('self');
                    vm.data.reporter.phone = vm.data.reporter.telephone; //JobCallerContact expects "phone"
                    vm.submitDisabled = false;
                });
            } else {
                vm.data._links.reporter = vm.data.reporter = null;
            }
        }

        function changeTenant () {
            if (vm.selectedTenantReporterModel.selected && vm.selectedTenantReporterModel.selected.object) {
                vm.selectedTenantReporter = vm.selectedTenantReporterModel.selected.object;

                vm.selectedRelationshipModel.selected = siteRelationshipResponse.siterelationships
                    .filter(function (item) {
                        return item.name === 'Occupier';
                    }).map(function(response){
                        return {
                            href:response._links.self.href,
                            object:response,
                            value:response.name
                        };
                    })[0];
                changeRelationship();

                vm.submitDisabled = true;

                $q.all([
                    apiClient.get(vm.selectedTenantReporter.getLink('self')).then(function (response) {
                        if (typeof vm.data.reporterTenant === 'undefined' || vm.data.reporterTenant === null || vm.data.reporterTenant.id != response.id) {
                            vm.data.reporterTenant = vm.tenantReporterResponse = response;
                            vm.data.reporterTenant.phoneNumbers = [];
                            angular.forEach(response.contactInformation.fields, function (value, index) {
                                if (value.type === 'telephone') {
                                    vm.data.reporterTenant.phoneNumbers.push(value);
                                }
                            });
                        }
                    })
                ]).then(function () {
                    vm.data._links.reporterTenant = vm.selectedTenantReporter.getLink('self');

                    if (vm.data.reporterTenant.contactInformation.fields.length > 0) {
                        var reporterInformation = vm.data.reporterTenant.contactInformation.fields;
                        angular.forEach(reporterInformation, function (contact) {
                            if (contact.type === 'email') {
                                vm.data.reporterTenant.email = contact.value;
                            }
                            if (contact.type === 'telephone') {
                                vm.data.reporterTenant.phone = contact.value;
                            }
                        });
                    } else {
                        vm.data.reporterTenant.email = vm.selectedTenantContact.email;
                        vm.data.reporterTenant.phone = vm.selectedTenantContact.phone;
                    }

                    delete vm.data._links.reporter;
                    vm.submitDisabled = false;
                });
            } else {
                vm.selectedRelationshipModel.selected = null;
                vm.data._links.reporterTenant = vm.data.reporterTenant = null;
            }
        }

        function toggleSuggestions(hide) {
            $timeout(function(){
                if (!vm.loading && vm.data.reporter && vm.data.reporter.name && vm.data.reporter.name.length > 0) {
                    vm.hideSuggestions = hide;
                }
            }, 100);
        }

        function findPseudoUsers () {
            vm.hideSuggestions = false;
            return apiClient.get(siteResourceResponse.getLink('pseudousers'), { search: true, name: vm.data.reporter.name, inactive: false })
                .then(function(response) {
                    vm.pseudoUserResults = response['site-pseudo-users'];
                }, function(error) {
                    console.log(error);
                });
        }

        function pseudoUserSetInactive(index) {
            vm.loading = vm.pseudoUserResults[index].loading = true;
            vm.pseudoUserResults[index].loading = true;
            return apiClient.update(vm.pseudoUserResults[index].getLink('site-pseudouser-inactive'), {}).then(function (response) {
                if (response) {
                    vm.pseudoUserResults.splice(index, 1);
                    vm.loading = vm.pseudoUserResults[index].loading = false;
                } else {
                    messenger.error('REQUEST_ERROR');
                }
            });
        }

        function pseudoUserSelected (index) {
            var item = vm.pseudoUserResults[index];
            vm.data.reporter.name = item.name;
            vm.data.reporter.email = item.email;
            vm.data.reporter.phone = item.phone;
            vm.data.reporter.notifyOnCreate = item.notifyOnCreation;
            vm.data.reporter.notifyOnComplete = item.notifyOnCompletion;

            var pseudoUserSiteRelationship = siteRelationshipResponse.siterelationships.find(function(elem) {
                return elem.getLink('self') === item.getLink('site-relationship');
            });

            if (pseudoUserSiteRelationship) {
                vm.selectedRelationshipModel.selected = {
                    object: pseudoUserSiteRelationship,
                    href: pseudoUserSiteRelationship.getLink('self'),
                    value: pseudoUserSiteRelationship.name
                };
            }
            
            changeRelationship();
            vm.pseudoUserResults = [];
        }

        function toggleDeclineEmail () {
            if (vm.data.reporter.declineEmail) {
                vm.data.reporter.email = null;
                vm.data.reporter.notifyOnCreate = false;
                vm.data.reporter.notifyOnComplete = false;
            }
        }

        function changeRelationship() {
            if (vm.selectedRelationshipModel.selected && vm.selectedRelationshipModel.selected.object) {
                vm.selectedRelationship = vm.selectedRelationshipModel.selected.object;
                vm.data._links.reportersiterelationship = {href: vm.selectedRelationship.getLink('self')};
            } else {
                vm.data._links.reportersiterelationship = null;
            }
        }

        function nextAction() {
            if (vm.data.reporter && vm.data.reporter.email === '') {
                delete vm.data.reporter.email;
            }

            if (vm.data.reporter && vm.data.reporter.name && vm.data.reporter.name.length > 255 ) {
                messenger.error('REPORTER_NAME_INVALID_LENGTH');
                return;
            }

            $state.go(vm.getRoute('contact'), {}, {reload: false});
        }

        function previousAction() {
            $rootScope.skipDirtyCheck = true;
            $state.go(vm.getRoute('site'), {}, {reload: false}).then(function() {
                $rootScope.skipDirtyCheck = false;
                vm.submitDisabled = false;
            });
        }
    }
})();
