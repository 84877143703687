(function () {
    'use strict';

    angular
        .module('elogbooks.user.duty-management')
        .controller('DutyManagementAddController', DutyManagementAddController);

    function DutyManagementAddController ($state, $stateParams, apiClient, messenger, rootResourceResponse, user, siteCollectionResponse, userCollectionResponse) {
        var vm = this;
            vm.dutyManagement = {
                _links : {}
            };

            vm.userSelect = {
                response : userCollectionResponse,
                link : userCollectionResponse ? userCollectionResponse.getLink('self') : '',
                required : true,
                responseKeyPath: 'users',
                itemHrefPath: '_links.self.href',
                itemValuePath: 'name'
            };
            vm.siteSelect = {
                response : siteCollectionResponse,
                link : siteCollectionResponse ? siteCollectionResponse.getLink('self') : '',
                responseKeyPath: 'sites'
            };
            vm.create = createAction;

        function createAction() {
            if (vm.userSelect.selected) {
                vm.dutyManagement._links.user = {href : vm.userSelect.selected.href}
            }

            if (vm.siteSelect.selectedItems.length) {
                vm.dutyManagement._links.site = vm.siteSelect.selectedItems
            }

            apiClient.create(rootResourceResponse.getLink('dutymanagementschedules'), vm.dutyManagement).then(function (response) {
                if (response) {
                    $state.go('^', $stateParams, { reload: $state.get('^') }).then(function () {
                        messenger.success('ADD_SUCCESS');
                    });
                } else {
                    messenger.error('REQUEST_ERROR');
                }
            });
        }
    }
})();
