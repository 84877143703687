(function () {
    'use strict';

    angular
        .module('elogbooks.admin.roles')
        .controller('RoleDetailsInfoController', RoleDetailsInfoController);

    RoleDetailsInfoController.$inject = ['roleResponse', 'elbSettings'];

    function RoleDetailsInfoController (roleResponse, elbSettings) {
        var vm = this;
            vm.role = roleResponse;

        vm.clientAdminUserModuleEnabled = elbSettings.getSetting('client_admin_user_enabled') ? elbSettings.getSetting('client_admin_user_enabled').value : false;
    }
})();
