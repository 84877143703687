angular.module('elogbooksDirectives').directive('autocompleteCheckboxMultiSelect', ['lodash', '$document', '$timeout', 'apiClient', function (lodash, $document, $timeout, apiClient) {
    return {
        scope: {
            model: '=ngModel',
            options: '=',
            selected: '=',
            placeholder: '@',
            disabled:'@?',
            field: '@?',
            orderBy: '@?',
            preselect: '@?'
        },
        templateUrl: '/modules/directives/form-elements/autocomplete-checkbox-multi-select/autocomplete-checkbox-multi-select.html',
        link: function(scope, element, attrs) {
            scope.model.searched = false;
            scope.model.keyword = '';
            scope.model.searchKey = 'name';
            scope.model.linkParameters = {};
            scope.model.allowAdd = false;
            scope.model.queryString = "";

            if(!scope.preselect) {
                angular.forEach(scope.model.items, function(value) {
                    if (value.checked) {
                        value.checked = false;
                    }
                });
            } else {
                angular.forEach(scope.model.items, function(option, key) {
                    if (lodash.find(scope.selected, {
                            name: option.name
                        })) {
                        scope.options[key].checked = true;
                    }
                });
            }
            var dropdown = element.find('.drop-down').find('ul');

            element.on('click', function () {
                // Use timeout to wait for choices dropdown to render
                $timeout(function() {
                    bindScroll();
                }, 100);
            });

            function bindScroll() {

                dropdown.off('scroll');
                dropdown.on('scroll', function (e) {
                    var scrollMargin = 10;

                    if (e.target.scrollHeight - e.target.clientHeight - scrollMargin <= e.target.scrollTop) {
                        angular.element(e.target).scope().loadMore(e);
                    }
                });
            }
            //Events required on change
            scope.raiseEvents = lodash.has(attrs, 'raiseEvents');

            scope.visible = false;

            if (scope.field) {
                angular.forEach(scope.options, function (value, index) {
                    value.name = value[scope.field];
                });
            }

            scope.selectClick = function (option) {
                var selected = {
                    label: option.label || option.name,
                    name: option.name
                 }

                if (option._links) {
                    selected.href = option._links.self.href
                }

                if (option.id) {
                    selected.id = option.id;
                }

                if (option.checked) {
                    scope.selected.push(selected);
                } else {
                    scope.selected = lodash.reject(scope.selected, selected);
                }

                if (scope.raiseEvents) {
                    scope.$emit('checkbox-multi-select:select:click', scope.selected);
                }
            }

            $document.on('click', function () {
                if (element.attr('visible') == 'visible') {
                    scope.$apply(
                        function () {
                            scope.visible = false;
                        }
                    );
                    element.attr('visible','');
                }
            });

            scope.hide = function () {
                scope.visible = false;
                element.attr('visible','');
            }

            scope.show = function () {
                scope.visible = true;
                $timeout(function () {
                    element.attr('visible','visible');
                }, 100);

            }

            scope.inputClick = function (event) {
                scope.toggle(event);
                $timeout(function () {
                    document.getElementById('checkbox-input').focus();
                });
            }

            scope.toggle = function (event) {
                if (scope.visible == true) {
                    scope.hide(event);
                } else {
                    scope.show(event);
                }
            }

            scope.$watch('model.queryString', function(newValue, oldValue) {
                if (newValue != oldValue) {
                    scope.search(newValue)
                }
            });

            scope.map = function(keyPath, valuePath) {
                return function (item) {
                    if (typeof item === 'undefined'){
                        return;
                    }

                    var highlighted = [];

                    if (scope.highlightedItems) {
                        highlighted = JSON.parse(scope.highlightedItems).filter(function(element) {
                            return element === item.name;
                        });
                    }

                    return  {
                        object: item,
                        href :  lodash.get(item, keyPath),
                        value : lodash.get(item, valuePath),
                        id :  lodash.get(item, 'id'),
                        isMandatory :  lodash.get(item, 'isMandatory') ? lodash.get(item, 'isMandatory') : false,
                        highlighted: highlighted.length > 0
                    };
                }
            }

            scope.dropDownClick = function (event) {
                event.stopPropagation();
            }

            scope.getResponseData = function (response) {
                return lodash.get(response.getData(), scope.model.responseKeyPath);
            }

            scope.filterResponse = function (response) {
                var responseData = scope.getResponseData(response);
                return responseData.map(scope.map(scope.model.itemHrefPath, scope.model.itemValuePath));
            }

            scope.search = function (keyword) {
                scope.model.loading = true;

                if (!scope.model.searched && keyword.length < 1) {
                    scope.model.loading = false;
                    return;
                }

                if (keyword.length >= 1) {
                    scope.model.searched = true;
                }

                if ((scope.model.oldSearchKeyword && keyword !== scope.model.oldSearchKeyword && keyword.length === 0)
                    || keyword.length >= 1 && scope.model.link) {
                    var searchParams = {};
                    searchParams[scope.model.searchKey] = keyword;
                    // Async function needs to access object properties from global scope
                    apiClient.get(scope.model.link, angular.extend({}, scope.model.linkParameters, searchParams), 'short').then(function (response) {
                        scope.model.loading = false;
                        scope.model.limitNotificationDisplayed = false;
                        var filtered = scope.filterResponse(response).filter(function (item) {
                            return !lodash.find(scope.model.selectedItems, {'href': item.href});
                        });
                        scope.model.items = filtered
                        scope.model.response = response;
                        scope.model.keyword = keyword;
                        scope.addElement(keyword);
                    });
                } else {
                    scope.addElement(keyword);
                    scope.model.loading = false;
                    scope.model.limitNotificationDisplayed = false;
                }

                scope.model.oldSearchKeyword = keyword;
            }
            scope.addElement = function(keyword) {
                if (keyword.length >= 1
                    && scope.model.allowAdd
                    && !scope.checkIfExist(keyword)
                ) {
                    scope.model.items.unshift({value:keyword});
                }
            }

            scope.checkIfExist = function(keyword) {
                for (var item in scope.model.items) {
                    if (scope.model.items.hasOwnProperty(item)
                        && scope.model.items[item].value.indexOf(keyword) > -1
                    ) {
                        return true;
                    }
                }

                return false;
            }

            scope.loadMore = function ($event) {
                $event.stopPropagation();
                $event.preventDefault();

                if (scope.model.loading) {
                    return;
                }

                if (scope.model.response.page === scope.model.response.pages) {
                    scope.model.limitNotificationDisplayed = true;
                    return;
                }

                if (scope.model.response.page < scope.model.response.pages) {
                    scope.model.loading = true;
                    scope.model.limitNotificationDisplayed = false;
                    apiClient.get(scope.model.response.getLink('next'), {}, 'short').then(function (response) {
                        scope.model.items = lodash.unionBy(scope.model.items, scope.filterResponse(response), 'href');

                        angular.forEach(response[scope.model.responseKeyPath], function(option) {
                            scope.model.response[scope.model.responseKeyPath].push(option);
                        });

                        response[scope.model.responseKeyPath] = scope.model.response[scope.model.responseKeyPath];
                        scope.model.response = response;
                        scope.model.loading = false;
                    });
                }
            }
        }
    }
}]);
