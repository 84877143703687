(function() {
    'use strict';

    angular
        .module('elogbooks.admin.surveys')
        .controller('SurveyLineAddEditController', SurveyLineAddEditController)

    SurveyLineAddEditController.$inject = ['surveyLineResponse', 'surveyLineCollectionResponse', 'requestDataFactory', 'crudService', '$translate'];

    function SurveyLineAddEditController(surveyLineResponse, surveyLineCollectionResponse, requestDataFactory, crudService, $translate) {
        var vm = this;
        vm.surveyLine = surveyLineResponse;
        vm.surveyLineCollectionResponse = surveyLineCollectionResponse;
        vm.data = requestDataFactory.createRequest(vm.surveyLine);

        vm.data.allowNA = vm.surveyLine.allowNA === false ? false : true;

        vm.allowNAOptions = [
            { title: $translate.instant('NO'), value: false},
            { title: $translate.instant('YES'), value: true}
        ];

        vm.create = createAction;
        vm.update = updateAction;
        vm.delete = deleteAction;

        function createAction () {
            return crudService.create(
                vm.surveyLineCollectionResponse.getLink('create'),
                vm.data,
                'dashboard.admin.surveys.list.details.survey-lines',
                null,
                'SURVEY_LINE',
                false,
                'dashboard.admin.surveys.list.details'
            );
        }

        function updateAction () {
            return crudService.update(
                vm.surveyLine.getLink('edit'),
                vm.data,
                'dashboard.admin.surveys.list.details.survey-lines',
                'dashboard.admin.surveys.list.details.survey-lines',
                'SURVEY_LINE'
            );
        }

        function deleteAction () {
            return crudService.delete(
                vm.surveyLine.getLink('delete'),
                'dashboard.admin.surveys.list.details.survey-lines',
                'SURVEY_LINE',
                {},
                false,
                'dashboard.admin.surveys.list.details'
            );
        }
    }
})();
