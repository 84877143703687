(function() {
    'use strict';

    angular
        .module('elogbooks.admin.pricing-schedules')
        .controller('PricingSchedulesLabourRatesController', PricingSchedulesLabourRatesController);

    PricingSchedulesLabourRatesController.$inject = [
        'pricingScheduleLabourRatesResponse'
    ];

    function PricingSchedulesLabourRatesController(pricingScheduleLabourRatesResponse) {
        var vm = this;
        vm.pricingScheduleLabourRatesResponse = pricingScheduleLabourRatesResponse;
        vm.labourRates = pricingScheduleLabourRatesResponse.labourrates;
    }
})();
