(function () {
    'use strict';

    angular
        .module('elogbooks')
        .filter('elbTruncate', ElbTruncate);

    function ElbTruncate () {
        return function (input, length, wholeWordsOnly, suffix) {
            if (! input) {
                return;
            }

            if (isNaN(length)) {
                length = 30;
            } else {
                length = parseInt(length, 10);
            }

            suffix = suffix || '...';

            if (input.length <= length || input.length - suffix.length <= length) {
                return input;
            } else {
                var output =  input.substring(0, length - suffix.length);

                if (wholeWordsOnly && input.charAt(output.length) !== ' ') {
                    var lastSpace = output.lastIndexOf(' ');

                    if (lastSpace !== -1) {
                        while (output.charAt(lastSpace - 1) === '.' || output.charAt(lastSpace - 1) === ',') {
                            lastSpace--;
                        }

                        output = output.substring(0, lastSpace);
                    }
                }

                return output + suffix;
            }
        };
    }
})();
