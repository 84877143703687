(function () {
    'use strict';

    angular
        .module('elogbooks.admin.waste-management')
        .controller('WasteEntryAddEditController', WasteEntryAddEditController);

    WasteEntryAddEditController.$inject = ['$scope', '$state', 'apiClient', 'confirmationModal', 'requestDataFactory', 'messenger', 'wasteTypeCollectionResponse', 'siteCollectionResponse', 'unitCollectionResponse', 'lodash', 'wasteEntryResponse', 'siteResponse', 'config', 'treatmentTypeCollectionResponse'];

    function WasteEntryAddEditController ($scope, $state, apiClient, confirmationModal, requestDataFactory, messenger, wasteTypeCollectionResponse, siteCollectionResponse, unitCollectionResponse, lodash, wasteEntryResponse, siteResponse, config, treatmentTypeCollectionResponse) {
        var vm = this;
        vm.treatmentTypes = treatmentTypeCollectionResponse;
        vm.wasteEntry = wasteEntryResponse;
        vm.wasteEntries = null;
        vm.targetsTransformed = null;
        vm.targets = [];
        vm.uploadedFile = null;
        vm.uploading = false;
        vm.config = config;
        vm.symbol = null;
        vm.closed = false;
        vm.newRow = newRow;
        vm.deleteOldRow = deleteOldRow;
        vm.deleteNewRow = deleteNewRow;

        var firstTime = true;

        if (vm.config.site_level) {
            vm.site = siteResponse;
        } else {
            vm.sites = siteCollectionResponse.sites;
        }

        vm.data = requestDataFactory.createRequest({
            value: parseFloat(wasteEntryResponse.value) || null,
            entryDate: wasteEntryResponse.entryDate ? new Date(wasteEntryResponse.entryDate) : null,
            _links: {},
            files: {
                links: []
            }
        });

        vm.create = createAction;
        vm.update = updateAction;
        vm.delete = deleteAction;

        vm.siteSelect = {
            response: siteCollectionResponse,
            link: siteCollectionResponse ? siteCollectionResponse.getLink('self') : null,
            responseKeyPath: 'sites',
            required: true,
            onSelect: changeSite,
            onRemove: removeSite,
            disabled: vm.config.site_level === true,
            populateDisabled: true
        };

        vm.partnershipSelect = {
            disabled: true,
            required: true,
            responseKeyPath: 'partnerships',
            linkParameters: {permission: 'edit_assets', active: 1},
            itemHrefPath: '_links.serviceprovider.href',
            itemValuePath: '_links.serviceprovider.title'
        };

        vm.wasteTypeSelect = {
            response: wasteTypeCollectionResponse,
            link: wasteTypeCollectionResponse ? wasteTypeCollectionResponse.getLink('self') : null,
            linkParameters: { "status": true },
            responseKeyPath: 'wasteTypes',
            required: true,
            itemValuePath: 'nameAndCode',
            itemTitlePath: 'nameAndCode',
            onSelect: changeWasteType,
            onRemove: changeWasteType
        };

        vm.unitSelect = {
            response: unitCollectionResponse,
            link: unitCollectionResponse ? unitCollectionResponse.getLink('self') : null,
            linkParameters: { "status": true },
            responseKeyPath: 'units',
            required: true,
            onSelect: changeUnit,
            onRemove: changeUnit,
        };

        // Fill form data on edit action
        if (wasteEntryResponse) {
            if (typeof wasteEntryResponse._links !== 'undefined') {
                vm.siteSelect.selected = wasteEntryResponse._links.site;
                //
                if (typeof wasteEntryResponse._links.serviceprovider !== 'undefined') {
                    vm.partnershipSelect.selected = wasteEntryResponse._links.serviceprovider;
                    vm.partnershipSelect.disabled = false;
                }

                if (typeof wasteEntryResponse._links.unit !== 'undefined') {
                    vm.unitSelect.selected = wasteEntryResponse._links.unit;
                    vm.symbol = wasteEntryResponse._links.unit.symbol;
                }

                if (typeof wasteEntryResponse._links.wastetype !== 'undefined') {
                    vm.wasteTypeSelect.selected = wasteEntryResponse._links.wastetype;
                    vm.wasteTypeSelect.selected.title = wasteEntryResponse.wasteType
                }
            }
        }
        if (vm.config.site_level) {
            vm.siteSelect.selected = {
                href: vm.site.getLink('self'),
                id: vm.site.id,
                object: vm.site,
                value: vm.site.name
            };

            changeSite();
        }
        if (vm.config.add === false && vm.config.site_level !== true) {
            changeSite();
        }

        function newRow() {
            vm.targets.push({
                response: treatmentTypeCollectionResponse,
                link: treatmentTypeCollectionResponse.getLink('self'),
                required: false,
                responseKeyPath: 'treatmentTypes',
                itemHrefPath: '_links.self.href',
                itemValuePath: 'name',
            });
        }

        function deleteOldRow (key) {
            vm.wasteEntry.wasteTreatments.splice(key, 1);
        }

        function deleteNewRow (key) {
            vm.targets.splice(key, 1);
        }

        function prepareData() {
            vm.data._links = {};

            if (typeof vm.partnershipSelect.selected !== 'undefined') {
                vm.data._links.serviceprovider = vm.partnershipSelect.selected;
            }

            if (typeof vm.siteSelect.selected !== 'undefined') {
                vm.data._links.site = vm.siteSelect.selected;
            }

            if (typeof vm.partnershipSelect.selected !== 'undefined') {
                vm.data._links.serviceprovider = vm.partnershipSelect.selected;
            }

            if (typeof vm.unitSelect.selected !== 'undefined') {
                vm.data._links.unit = vm.unitSelect.selected;
            }

            if (typeof vm.wasteTypeSelect.selected !== 'undefined') {
                vm.data._links.wastetype = vm.wasteTypeSelect.selected;
            }
        }

        function changeSite() {
            if (vm.partnershipSelect) {
                vm.partnershipSelect.selected = null;
                vm.partnershipSelect.disabled = vm.config.site_level ? false : true;
            }

            vm.targets = [];
            apiClient.get(vm.siteSelect.selected.href).then(function (response) {
                if (response) {
                    vm.site = response;

                    if (response.getLink('partnerships')) {
                        vm.partnershipSelect.disabled = false;
                        vm.partnershipSelect.link = response._links.partnerships.href;
                    }

                    if (response.getLink('treatmenttypes')) {
                        apiClient.get(response.getLink('treatmenttypes')).then(function (response) {
                            vm.treatmentTypes = response;
                        });
                    }

                    if (vm.config.add === false && firstTime) {
                        vm.partnershipSelect.selected = {
                            href: vm.wasteEntry._links.serviceprovider.href,
                            value: vm.wasteEntry._links.serviceprovider.title
                        };
                        firstTime = false;
                    }

                    getWasteEntries();
                }
            });
        }

        function changeWasteType() {
            vm.data._links.wasteType = vm.wasteTypeSelect.selected ? vm.wasteTypeSelect.selected.object._links.self : null;
            getWasteEntries();
        }

        function changeUnit() {
            if (vm.unitSelect.selected) {
                vm.symbol = vm.unitSelect.selected.object.symbol;
                vm.data._links.unit = vm.unitSelect.selected.object._links.self;
            } else {
                vm.symbol = null;
                vm.data._links.unit = null;
            }
        }

        function transformWasteTypeCollectionResponse(collection) {
            lodash.forEach(collection.wasteTypes, function (value, key) {
                if(typeof value.temp === 'undefined') {
                    value.temp = value.name;
                }

                value.name = value.temp + ' ' + value.code;
            });

            return collection
        }

        function removeSite() {
            vm.targets = [];
            vm.partnershipSelect.clear();
            vm.partnershipSelect.disabled = vm.config.site_level ? false : true;
        }

        function validateWasteEntryValue() {
            var treatmentTypeTotal = null;
            angular.forEach(vm.data.fields, function(field, index) {
               if (treatmentTypeTotal !== null) {
                   treatmentTypeTotal += field.value;
               } else {
                   treatmentTypeTotal = field.value;
               }
            });

            if (treatmentTypeTotal > vm.data.value) {
                return false;
            }

            return true;
        }

        function createAction () {
            prepareData();
            vm.data.fields = transformTargets();

            if (!vm.data._links.serviceprovider || vm.partnershipSelect.loading) {
                return;
            }
            
            if (!validateWasteEntryValue()) {
                messenger.error('WASTE_ENTRY_VALUE_ERROR');
                return;
            }

            return apiClient.create(vm.site.getLink('create-wasteentry'), vm.data).then(function (response) {
                if (response) {
                    $state.go('^', {}, { reload: $state.current.parent }).then(function () {
                        messenger.success('WASTE_ENTRY_CREATED');
                    });
                } else {
                    messenger.error('REQUEST_ERROR');
                }
            });
        }

        function updateAction () {
            prepareData();
            vm.data.fields = transformTargets();

            if (!vm.data._links.serviceprovider || vm.partnershipSelect.loading) {
                return;
            }

            if (!validateWasteEntryValue()) {
                messenger.error('WASTE_ENTRY_VALUE_ERROR');
                return;
            }

            return apiClient.update(wasteEntryResponse.getLink('edit'), vm.data).then(function (response) {
                if (response) {
                    $state.go('^', {}, { reload: $state.$current.parent.parent.parent.self.name }).then(function () {
                        messenger.success('WASTE_ENTRY_UPDATED');
                    });
                } else {
                    messenger.error('REQUEST_ERROR');
                }
            });
        }

        function deleteAction () {
            return confirmationModal.open().result.then(function () {
                return apiClient.delete(wasteEntryResponse.getLink('delete')).then(function (response) {
                    if (response) {
                        $state.go($state.$current.parent.parent.parent.self.name, {}, { reload: $state.$current.parent.parent.parent.self.name }).then(function () {
                            messenger.success('WASTE_ENTRY_DELETED');
                        });
                    } else {
                        messenger.error('REQUEST_ERROR');
                    }
                });
            });
        }

        function transformTargets() {
            var targets = [];

            lodash.forEach(vm.targets, function (target) {
                targets.push(
                    {
                        _links: { treatmenttype: target.selected.object._links.self },
                        value: target.target
                    }
                )
            });

            lodash.forEach(vm.wasteEntry.wasteTreatments, function (target) {
                targets.push(
                    {
                        _links: { treatmenttype: target.treatmentType._links.self },
                        value: target.value
                    }
                )
            });

            return targets;
        }

        function getWasteEntries() {
            if (vm.site.getLink('waste-entries')) {
                var wasteType = vm.wasteTypeSelect.selected ? vm.wasteTypeSelect.selected.object.id : null;

                apiClient.get(vm.site.getLink('waste-entries'), { limit: 3, order: '-entryDate', wasteType: wasteType }).then(function (response) {
                    vm.wasteEntries = response.wasteEntries;
                });
            }
        }

        $scope.$watch('vm.uploadedFile', function(newValue) {
            vm.data._links.file = null;
            if (newValue) {
                vm.data._links.file = vm.uploadedFile._links.file;
            }
        });
    }
})();
