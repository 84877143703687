(function () {
    'use strict';

    angular
        .module('elogbooksDirectives')
        .directive('elbNextPrevious', ElbNextPrevious);

    ElbNextPrevious.$inject = [];

    function ElbNextPrevious () {
        return {
            restrict: 'AE',
            scope: {
                collection: '=ngCollection',
                entity: '=ngEntity',
                type: '@',
                resource: '@'
            },
            templateUrl: '/modules/directives/elb-next-previous/next-previous.html',
            controller: ['$scope', '$state', 'lodash', 'apiClient', '$window', function($scope, $state, lodash, apiClient, $window) {
                var collectionIndex = lodash.findIndex($scope.collection[$scope.type], function(entity) {
                    return entity.id == $scope.entity.id;
                });

                var currentIndex = collectionIndex;

                if (currentIndex < 0) {
                    //Check if currentIndex is in local storage by $scope.type
                    var current = parseInt($window.localStorage.getItem('list_current_item_' + $scope.type), 10);
                    currentIndex = current ? current : 0;
                } else {
                    $window.localStorage.setItem('list_current_item_' + $scope.type, currentIndex);
                }

                var resourceType = $scope.resource;
                var page = null;

                angular.forEach($state.params, function (item, key) {
                    if (key.toLowerCase().indexOf('page') !== -1) {
                        page = key;
                    }
                });

                $scope.backToList = $state.$current.parent.parent.self.name;
                toggleLoading(false);

                $scope.next = function() {
                    // If item was removed from filtered list then next is always the current.
                    toggle(collectionIndex < 0 ? 0 : 1);
                };

                $scope.prev = function() {
                    toggle(-1);
                };

                function toggleLoading(value) {
                    $scope.loading = value;
                    $scope.disablePrevious = value || ($scope.collection.page === 1 && currentIndex === 0);
                    $scope.disableNext = value || ($scope.collection.pages === $scope.collection.page && currentIndex === $scope.collection[$scope.type].length - 1) ;
                }

                function toggle(direction) {
                    if ($scope.loading) {
                        return;
                    }

                    toggleLoading(true);

                    currentIndex += direction;

                    var resource = null;

                    if (typeof $scope.collection[$scope.type][currentIndex] !== "undefined") {
                        resource = $scope.collection[$scope.type][currentIndex].getLink('self');
                    }

                    var url = direction < 0 ? $scope.collection.getLink('previous') : $scope.collection.getLink('next');
                    var index = direction < 0 ? null : 0;

                    return loadEntity(url, index, resource);
                }

                function loadEntity(nextPrevious, index, tempResource) {
                    // if (currentIndex === $scope.collection[$scope.type].length || (index === null && currentIndex === 0) && nextPrevious !== null) {
                    if (nextPrevious !== null && tempResource === null) {
                        // Change the page
                        apiClient.get(nextPrevious).then(function (response) {
                            currentIndex = index !== null ? index : response[$scope.type].length - 1;

                            var params = getResource(response[$scope.type][currentIndex].getLink('self'), resourceType);
                            params[page] = response.page;

                            $state.go('.', angular.extend({}, $state.params, params)).then(function(){toggleLoading(false);});
                        });
                    } else {
                        // Load next or previous on same page
                        $state.go('.', angular.extend({}, $state.params, getResource(tempResource, resourceType))).then(function(){toggleLoading(false);});
                    }
                }

                function getResource(resource, resourceType) {
                    var entityResource = {};
                    entityResource[resourceType] = resource.encode();

                    return entityResource;
                }
            }]
        };
    }
})();
