(function() {
    'use strict';

    angular
        .module('elogbooks.user.timesheets')
        .controller('UserTimesheetsItemsDetailsController', UserTimesheetsItemsDetailsController);

    UserTimesheetsItemsDetailsController.$inject = ['timesheetsService', '$uibModal', 'labourTypeCollectionResponse', '$state', 'lodash'];

    function UserTimesheetsItemsDetailsController(timesheetsService, $uibModal, labourTypeCollectionResponse, $state, lodash) {
        var vm = this;
        vm.days = timesheetsService.getDays();
        vm.hasDays = timesheetsService.hasDays();
        vm.isTimeSheetApproved = timesheetsService.isTimeSheetApproved();
        vm.toggleAll = toggleAll;
        vm.toggle = toggle;
        vm.canCollapseAll = false;
        vm.canExpandAll = true;

        function toggleAll(collapse) {
            timesheetsService.toggleAll(collapse);
            vm.canCollapseAll = !collapse;
            vm.canExpandAll = collapse;
        }

        function toggle(index) {
            vm.days[index].toggle();
            var collapsed = lodash.filter(vm.days, function (day) {
                return day.collapsed && day.hasEntries();
            });
            var expanded = lodash.filter(vm.days, function (day) {
                return !day.collapsed && day.hasEntries();
            });

            if (collapsed.length === 0) {
                vm.canExpandAll = false;
            } else {
                vm.canExpandAll = true;
            }

            if(expanded.length === 0) {
                vm.canCollapseAll = false;
            } else {
                vm.canCollapseAll = true;
            }
        }

        vm.openEditLabourCostModal = function(props) {
            $uibModal.open({
                templateUrl: '/modules/user/timesheets/details/items/modal/labour-modal.html',
                controller: 'TimeSheetItemLabourModalController',
                controllerAs: 'vm',
                size: 'lg',
                resolve: {
                    labourTypeCollectionResponse: labourTypeCollectionResponse,
                    config: props
                }
            }).result.then(function(result) {
                if (lodash.get(result, 'action.message') === 'exists') {
                    $state.go('.',{}, { reload: $state.current });
                } else if (lodash.get(result, 'action.message') === 'empty') {
                    $state.go('^',{}, { reload: $state.current.parent });
                }

            });
        }
    }
})();