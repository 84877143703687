(function () {
    'use strict';

    angular
        .module('elogbooksDirectives')
        .directive('elbNoResultsFound', ElbNoResultsFound);

    ElbNoResultsFound.$inject = [];

    function ElbNoResultsFound() {
        return {
            restrict: 'E',
            template: '<div class="text-center text-warning">{{ ::"NO_RESULTS_FOUND" | translate }}</div>'
        };
    }
})();
