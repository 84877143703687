(function() {
    'use strict';

    angular
        .module('elogbooks.user.spillages')
        .controller('UserSpillagesDetailsController', UserSpillagesDetailsController);

    UserSpillagesDetailsController.$inject = ['spillageResponse'];

    function UserSpillagesDetailsController(spillageResponse) {
        var vm = this;
        vm.spillage = spillageResponse;
    }
})();