(function () {
    'use strict';

    angular
        .module('elogbooks.common.finance')
        .controller('CommonFinanceItemListController', CommonFinanceItemListController);

    CommonFinanceItemListController.$inject = [
        'itemResponse',
        'itemLinesCollectionResponse',
        'config',
        '$filter',
        'elbSettings'
    ];

    function CommonFinanceItemListController (
        itemResponse,
        itemLinesCollectionResponse,
        config,
        $filter,
        elbSettings
    ) {
        var vm = this;

        vm.itemResponse = itemResponse;
        vm.itemLinesCollectionResponse = itemLinesCollectionResponse;
        vm.itemLinesCollectionResponse.canCreate = vm.itemLinesCollectionResponse.getLink('edit') ? true : false;
        vm.itemLines = itemLinesCollectionResponse.costLines;
        vm.config = config;
        vm.title = itemLinesCollectionResponse.count === 0 ? config.type + '_ITEMS' : config.type + '_EDIT_ITEMS';
        vm.totalCost = vm.itemResponse.totalCostLines;
        vm.currency =  $filter('translate')('CURRENCY_' + elbSettings.getSetting('currency').value);
    }
})();
