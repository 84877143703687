(function() {
    'use strict';

    angular
        .module('elogbooks')
        .config(Translations);

    function Translations($translateProvider) {
        $translateProvider.translations('zh-hk', {
            JOB: '工作',
            JOBS: '工作',
            JOB_LIST: '工作',



        });
    }
})();

app.config(function ($translateProvider) {
    $translateProvider.translations('zh-hk', {


        JOB_REACTIVE_APPROVAL_REQUIRED:'重啟工作審批',

        LOG_A_NEW_JOB: '記錄新工作',
        ADD_NEW_JOB: '新增工作',
        JOB_ADD: '新增工作',
        ALL_JOBS: '工作',

        CREATED_AT: '建立於',

        // Job details screen
        JOB_ID: '工作 #{{ ::id }}',
        JOB_DETAILS: '工作詳細資料',
        JOB_DETAILS_WITH_ID: '工作 #{{ ::id }} 詳細資料',
        JOB_STATUS: '狀態',
        JOB_EVENTS: '事件',
        // JOB_MESSAGE_ACTION: '<strong>{{ ::message.sender }}</strong> added a message.',
        JOB_CREATED_ACTION: '<strong>{{ ::event._links["user"].title }}</strong> 已建立工作.',
        JOB_MESSAGE_ACTION: '<strong>{{ ::message._links["sender"].title }}</strong> 已增加工作.',
        JOB_ACTIONED_ACTION: '工作的截止日期被提前.',
        JOB_ALARM_UPDATED_ACTION: '<strong>{{ ::event._links["user"].title }}</strong> 已更新工作.',
        JOB_APPROVAL_REQUESTED_ACTION: '<strong>{{ ::event._links["user"].title }}</strong> 已發送審批要求.',
        JOB_APPROVAL_ACCEPTED_ACTION: '<strong>{{ ::event._links["user"].title }}</strong> 已審批工作.',
        JOB_APPROVAL_REJECTED_ACTION: '<strong>{{ ::event._links["user"].title }}</strong> 已拒絕工作審批.',
        JOB_APPROVAL_RESENT_ACTION: '<strong>{{ ::event._links["user"].title }}</strong> 重發送審批要求給 {{ ::event._links["approver"].title }}.',
        JOB_ASSIGNMENT_REQUESTED_ACTION: '<strong>{{ ::event._links["user"].title }}</strong> 發送職務要求給 {{ ::event._links["operative"].title }}.',
        JOB_ASSIGNMENT_REASSIGNED_ACTION: '<strong>{{ ::event._links["user"].title }}</strong> 重分配工作.',
        JOB_ASSIGNMENT_ACCEPTED_ACTION: '<strong>{{ ::event._links["user"].title }}</strong> 接受工作.',
        JOB_ASSIGNMENT_REJECTED_ACTION: '<strong>{{ ::event._links["user"].title }}</strong> 拒絕工作.',
        JOB_ASSIGNMENT_RESENT_ACTION: '<strong>{{ ::event._links["user"].title }}</strong> 重發送職務要求給 {{ ::event._links["operative"].title }}.',
        JOB_ASSIGNMENT_INSISTED_ACTION: '<strong>{{ ::event._links["user"].title }}</strong> 確定工作已接納.',
        JOB_PROPOSAL_REQUESTED_ACTION: '<strong>{{ ::event._links["user"].title }}</strong> 已發送議案.',
        JOB_PROPOSAL_ACCEPTED_ACTION: '<strong>{{ ::event._links["user"].title }}</strong> 已接受議案.',
        JOB_PROPOSAL_REJECTED_ACTION: '<strong>{{ ::event._links["user"].title }}</strong> 已拒絕議案.',
        JOB_EXTENSION_REQUESTED_ACTION: '<strong>{{ ::event._links["user"].title }}</strong> 已發送延伸要求.',
        JOB_EXTENSION_ACCEPTED_ACTION: '<strong>{{ ::event._links["user"].title }}</strong> 已接受延伸要求.',
        JOB_EXTENSION_REJECTED_ACTION: '<strong>{{ ::event._links["user"].title }}</strong> 已拒絕延伸要求.',
        JOB_OPERATION_ACKNOWLEDGED_ACTION: '<strong>{{ ::event._links["user"].title }}</strong> 確認工作.',
        JOB_OPERATION_COMMENCED_ACTION: '<strong>{{ ::event._links["user"].title }}</strong> 已開始工作.',
        JOB_OPERATION_COMPLETED_ACTION: '<strong>{{ ::event._links["user"].title }}</strong> 已完成工作.',
        JOB_POSTPONE_REMINDER_AT_ACTION: '<strong>{{ ::event._links["user"].title }}</strong> 將工作日期改為 {{ ::event.reminderAt | date:\'short\' }}.',
        JOB_OPERATION_MISSED_ACTION: '<strong>{{ ::event._links["user"].title }}</strong> 錯過了工作.',
        JOB_OPERATION_CHASED_ACTION: '工作 #{{ ::event.chaseSequence + 1 }} 通知已發送到 <strong>{{ ::event._links["user"].title }}</strong>.',
        JOB_OPERATION_REOPENED_ACTION: '<strong>{{ ::event._links["user"].title }}</strong> 再開啟工作.',
        JOB_OPERATION_CANCELLED_ACTION: '<strong>{{ ::event._links["user"].title }}</strong> 已取消工作.',
        JOB_APPROVAL_ESCALATED_ACTION: '<strong>{{ ::event._links["user"].title }}</strong> 上報工作審批.',
        JOB_AUDIT_TRAIL_SENT_ACTION: '<strong>{{ ::event._links["user"].title }}</strong> 已發送審計追踪.',
        JOB_ESCALATION_ADDED_ACTION: '<strong>{{ ::event._links["user"].title }}</strong> 上報工作.',
        JOB_ESCALATION_REMOVED_ACTION: '<strong>{{ ::event._links["user"].title }}</strong> 將工作從回報表中移除.',
        JOB_ENTER_DETAILS_MANUALLY: '輸入詳細資料',
        JOB_CHOOSE_A_USER: '選擇用戶',
        JOB_REPORTER_NAME: '回報者名稱',
        JOB_CONTACT_NAME: '聯繫人名稱',
        JOB_PHONE_NUMBER: '電話號碼',
        JOB_RELATIONSHIP_TO_THE_PROPERTY: '與物業的關係',
        JOB_NOTIFY_ON_CREATION: '通知已建立',
        JOB_NOTIFY_ON_COMPLETION: '通知已完成',
        JOB_NEXT_STEP: '下一步',
        JOB_SAME_AS_CALLER: '與來電者相同',
        JOB_SITE_CONTACT_MYSELF: '我是此場地聯絡人',
        JOB_SITE_CONTACT_AVAILABLE: '場地聯絡方式',
        JOB_TO_MEET_THE_CONTRACTOR: '約見承包商',
        JOB_KEYHOLDER_REQUIRED: '需要鎖匙持有人',
        JOB_SITE_PRIORITY: '場地優先權',
        JOB_STEP_DETAIL: '詳細資料',
        JOB_SERVICE_PROVIDER_PRIORITY: '服務供應商優先權',
        JOB_SYSTEM_PRIORITY: '系統優先權',
        JOB_PHONE_NUMBERS: '電話號碼',
        JOB_KEYHOLDER_DETAILS_PASS: '鎖匙持有人詳細資料:',
        BY_PHONE: '電話',
        BY_MAIL: '郵件',
        SYSTEM_MAIL: '自動郵件',

        JOB_TYPE_REACTIVE: '重啟',
        JOB_TYPE_PLANNED: '計劃',

        JOB_REPORTER: '回報者',
        JOB_CONTACT: '聯絡',
        JOB_ADD_SITE_DETAILS: '選擇場地',
        JOB_ADD_REPORTER_DETAILS: '輸入回報詳細資料',
        JOB_ADD_CONTACT_DETAILS: '輸入場地聯絡詳細資料',
        JOB_ADD_DETAILS: '輸入工作詳細資料',

        JOB_WORKFLOW_COMPLETE: '完成工作',
        JOB_WORKFLOW_COMPLETE_OPERATION: '完成工作',
        JOB_WORKFLOW_COMPLETE_OPERATION_SUBMIT: '完成工作',

        JOB_WORKFLOW_COMPLETE_PAPERWORK: '完成證件',
        JOB_WORKFLOW_COMPLETE_PAPERWORK_OPERATION: '完成證件',
        JOB_WORKFLOW_COMPLETE_PAPERWORK_OPERATION_SUBMIT: '完成證件',
        JOB_STATUS_AWAITING_PAPERWORK: '等待證件',
        COMPLETE_PAPERWORK: '完成證件',

        JOB_WORKFLOW_ACCEPT_ASSIGNMENT: '確認',
        JOB_WORKFLOW_REQUEST_PROPOSAL: '要求新SLA',
        JOB_WORKFLOW_INSIST_ASSIGNMENT: '確定',

        JOB_WORKFLOW_ACCEPT_APPROVAL: '批准',
        JOB_WORKFLOW_ACCEPT_APPROVAL_SUBMIT: '批准',

        JOB_WORKFLOW_REOPEN: '重開',

        JOB_WORKFLOW_ESCALATE_APPROVAL: '上報審批',

        JOB_WORKFLOW_ALARM: '修改警報',
        JOB_WORKFLOW_ALARM_SUBMIT: '更新',
        JOB_WORKFLOW_ALARM_SUCCESS: '警報已更新',

        JOB_WORKFLOW_ALARM_SETNEW: '設定警報',
        JOB_WORKFLOW_ALARM_SETNEW_SUBMIT: '設定',
        JOB_WORKFLOW_ALARM_SETNEW_SUCCESS: '警報已設定',

        JOB_WORKFLOW_REASSIGN_ASSIGNMENT: '重新分配職務',

        JOB_WORKFLOW_CHANGE_REQUIRED_BY_DATE_OPERATION: '按日期更改操作',
        JOB_WORKFLOW_CHANGE_REQUIRED_BY_DATE_OPERATION_SUBMIT: '遞交',
        JOB_WORKFLOW_CHANGE_REQUIRED_BY_DATE_OPERATION_SUCCESS: '按日期操作已更改',

        JOB_WORKFLOW_ACCEPT_PROPOSAL: '接受新SLA',
        JOB_WORKFLOW_ACCEPT_PROPOSAL_SUBMIT: '確認',
        PROPOSED: '建議',

        JOB_WORKFLOW_REJECT_PROPOSAL: '拒絕新SLA',
        JOB_WORKFLOW_REJECT_PROPOSAL_SUBMIT: '遞交',

        JOBS_LIST: '工作',
        JOBS_ADD: '新增工作',
        JOBS_EDIT: '修改工作',

        JOB_TYPE: '工作類別',

        JOB_STATUS_ACKNOWLEDGED: '確認',

        JOB_QUOTE_REQUEST_FROM: '從報價',

        JOB_STATUS_LEGEND: '工作狀態圖例',

        JOB_STATUS_CREATED: '已建立',
        JOB_STATUS_APPROVAL_PENDING: '審批待定',
        JOB_STATUS_APPROVAL_REJECTED: '審批已拒絕',

        JOB_STATUS_ASSIGNMENT_PENDING: '確認待定',
        JOB_STATUS_ASSIGNMENT_PROPOSAL: 'SLA提案待定',

        JOB_STATUS_ASSIGNMENT_REJECTED: '職務已拒絕',

        JOB_STATUS_OPERATION_ACKNOWLEDGED: '確認',
        JOB_STATUS_COMMENCED: '進行中',
        JOB_STATUS_OPERATION_MISSED: '未完成',
        JOB_STATUS_OPERATION_CANCELLED: '已取消',
        JOB_STATUS_COMPLETED: '已完成',
        JOB_STATUS_OPERATION_AWAITING_PAPERWORK: '等待證件',
        JOB_WORKFLOW_COMPLETE_PAPERWORK_OPERATION_SUCCESS: '附上證件',

        JOB_STATUS_EXTENSION_PENDING: '延伸請求待定',
        JOB_STATUS_EXTENSION_REJECTED: '延伸請求已拒絕',

        JOB_STATUS_MISSED: '未完成',

        RESEND_APPROVAL_REQUEST: '重發審批要求',
        RESEND_ASSIGNMENT_REQUEST: '重發職務要求',

        SEND_TO_QUOTE: '發送報價',
        ESCALATE_TO_CLIENT: '上報客戶',
        EDIT_JOB: '修改工作',

        COMPLETION_DATE: '完成日期',
        REASSIGN_ASSIGNMENT: '重新分配職務',
        REASSIGN_ASSIGNMENT_SUBMIT: '重新分配職務',

        COMMENCE: '開始',
        COMPLETE: '完成',
        SKIP: '跳過',
        ALARM: '警報',
        CANCEL_JOB: '你是否確定?',
        CANCEL_JOB_ADD: '你是否確定執行此操作?',

        APPROVE: '批准',
        REJECT: '拒絕',

        ACKNOWLEDGE: '確認',
        REASSIGN: '重新分配',
        CANCEL: '取消',
        INSIST: '確定',

        REQUEST_PROPOSAL: '要求新SLA',
        ACCEPT_PROPOSAL: '接受新SLA',
        REJECT_PROPOSAL: '拒絕新SLA',

        REQUEST_EXTENSION: '要求延伸',
        ACCEPT_EXTENSION: '接受要求延伸',
        REJECT_EXTENSION: '拒絕要求延伸',

        CHANGE_ACTION_REQUIRED_BY: '延遲工作',

        REOPEN: '重開',
        ESCALATE_APPROVAL: '上報審批',

        ACTION_REQUIRED: '需求者',

        JOB_REQUISITES: '工作需求',
        REQUIREMENT_MET: '需求',
        JOB_REQUISITES_UPDATED: '工作需求已更新',
        JOB_CONTACT_ADD: '增加',

        JOB_DIALS_PLANNED_OVERDUE: '逾期計劃',
        JOB_DIALS_REACTIVE_OVERDUE: '逾期重啟',
        JOB_DIALS_PLANNED_REQUIRED_ATTENTION: '客戶審核文書',
        JOB_DIALS_REACTIVE_REQUIRED_ATTENTION: '上報客戶',

        JOB_APPROVAL_RESENT: '工作審批已發送',
        JOB_ASSIGNMENT_RESENT: '工作職務已發送',
        JOB_AUDIT_TRAIL_SENT: '審批追踪已發送',

        JOB_WITH_ID: '工作 {{ id }}',

        JOBS_REACTIVE_OVERDUE_ATTENDANCE: '重啟工作逾期出席率',
        JOBS_PLANNED_OVERDUE_COMPLETION: '計劃工作逾期完成',

        JOBS_REACTIVE_UPDATES_DUE: '重啟工作逾期更新',

        COMPLETE_TO_RAISE_ESCALATION: '完成此方面會上報給FM.',

        OVERDUE: '逾期',
        OVERDUE_ATTENDANCE: '逾期出席率',
        OVERDUE_COMPLETION: '逾期完成',
        ATTEND_AT: '參與於',
        COMPLETE_AT: '完成於',
        JOB_SITE_CONTACT: '工作場地聯絡',
        JOB_APPROVER: '工作審批者',

        JOB_WORKFLOW_COMPLETE_OPERATION_SUCCESS: '工作已完成',

        JOB_WORKFLOW_REQUEST_EXTENSION: '請求延期',
        JOB_WORKFLOW_REQUEST_EXTENSION_SUBMIT: '請求',
        JOB_WORKFLOW_REQUEST_EXTENSION_SUCCESS: '工作延期已請求',

        JOB_WORKFLOW_ACCEPT_EXTENSION: '接受延期',
        JOB_WORKFLOW_ACCEPT_EXTENSION_SUBMIT: '接受',
        JOB_WORKFLOW_ACCEPT_EXTENSION_SUCCESS: '工作延期已接受',

        JOB_WORKFLOW_REJECT_EXTENSION: '拒絕延期',
        JOB_WORKFLOW_REJECT_EXTENSION_SUBMIT: '拒絕',
        JOB_WORKFLOW_REJECT_EXTENSION_SUCCESS: '工作延期已拒絕',

        JOB_WORKFLOW_CANCEL_OPERATION: '取消工作',
        JOB_WORKFLOW_CANCEL_OPERATION_SUBMIT: '遞交',
        JOB_WORKFLOW_CANCEL_OPERATION_SUCCESS: '工作已取消',

        JOB_WORKFLOW_REOPEN_OPERATION: '重開',
        JOB_WORKFLOW_REOPEN_OPERATION_SUBMIT: '重開',
        JOB_WORKFLOW_REOPEN_OPERATION_SUCCESS: '工作已重開',

        JOB_CREATED: '工作已建立',

        JOB_WORKFLOW_REJECT_APPROVAL: '拒絕審批',
        JOB_WORKFLOW_COMMENCE_OPERATION: '開始',
        JOB_STATUS_CANCELLED: '已取消',

        JOB_WORKFLOW_CANCEL: '取消',
        JOB_WORKFLOW_CHANGED_REQUIRED_BY_DATE: '延遲工作',
        JOB_WORKFLOW_REJECT_ASSIGNMENT: '拒絕職務',

        CANCEL_OPERATION_SUCCESS: '工作已取消',
        REASSIGN_ASSIGNMENT_SUCCESS: '重新分配工作',
        JOB_WORKFLOW_REASSIGN_ASSIGNMENT_SUCCESS: '重新分配工作',
        REOPEN_OPERATION_SUCCESS: '工作已重開',
        REJECT_APPROVAL_SUCCESS: '工作已批准',
        ESCALATE_APPROVAL_SUCCESS: '工作審批已上報',
        JOB_WORKFLOW_ACCEPT_APPROVAL_SUCCESS: '工作已接受',
        REQUEST_PROPOSAL_SUCCESS: '新SLA方案要求',
        ACCEPT_PROPOSAL_SUCCESS: '新SLA已接受',
        REJECT_PROPOSAL_SUCCESS: '新SLA已拒絕',
        INSIST_ASSIGNMENT_SUCCESS: '工作已確定',
        ACCEPT_ASSIGNMENT_SUCCESS: '工作已分配',
        REJECT_ASSIGNMENT_SUCCESS: '工作已拒絕',
        COMMENCE_OPERATION_SUCCESS: '工作已開始',
        REQUEST_EXTENSION_SUCCESS: '工作已延遲',
        REJECT_EXTENSION_SUCCESS: '工作延遲已拒絕',
        ACCEPT_EXTENSION_SUCCESS: '工作延遲已接受',

        JOB_WORKFLOW_REQUEST_PROPOSAL_SUBMIT: '請求',
        JOB_WORKFLOW_INSIST_ASSIGNMENT_SUBMIT: '確定',
        JOB_WORKFLOW_REJECT_APPROVAL_SUBMIT: '拒絕',
        JOB_WORKFLOW_ESCALATE_APPROVAL_SUBMIT: '上報',
        JOB_WORKFLOW_ACCEPT_ASSIGNMENT_SUBMIT: '接受',
        JOB_WORKFLOW_COMMENCE_OPERATION_SUBMIT: '開始',
        JOB_WORKFLOW_REJECT_ASSIGNMENT_SUBMIT: '拒絕',
        JOB_WORKFLOW_REASSIGN_ASSIGNMENT_SUBMIT: '重新分配',

        ACTION_REQUIRED_BETWEEN: '工作執行於'
    });
});
