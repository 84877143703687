(function () {
    'use strict';

    angular
        .module('elogbooks.admin.job-requisites')
        .controller('JobRequisitesAddEditController', JobRequisitesAddEditController);

    JobRequisitesAddEditController.$inject = [
        '$scope',
        '$state',
        'apiClient',
        'messenger',
        'jobRequisiteCollectionResponse',
        'jobRequisiteResponse',
        'requestDataFactory',
        'allowNotApplicable',
        'requestAttachment',
        'confirmationModal',
        '$translate',
        'jobRequisites',
        'formCollectionResponse',
        'lodash',
        'userManager'
    ];

    function JobRequisitesAddEditController(
        $scope,
        $state,
        apiClient,
        messenger,
        jobRequisiteCollectionResponse,
        jobRequisiteResponse,
        requestDataFactory,
        allowNotApplicable,
        requestAttachment,
        confirmationModal,
        $translate,
        jobRequisites,
        formCollectionResponse,
        lodash,
        userManager
        ) {
        var vm = this;

        vm.jobRequisite = jobRequisiteResponse;
        vm.formCollectionResponse = formCollectionResponse;
        vm.data = requestDataFactory.createRequest(getDefaults());

        vm.create = createAction;
        vm.update = updateAction;
        vm.delete = deleteAction;

        vm.allowNotApplicableOptions = allowNotApplicable.getAll();
        vm.requestAttachmentOptions = requestAttachment.getAll();

        vm.approvalStrategyOptions = jobRequisites.approvalStrategyOptions;
        vm.optInChoices = jobRequisites.optInChoices;
        vm.canDeleteJr = userManager.hasPermission('user_permission_manage_job_requisites');

        var selectedJobForm, selectedJobPostForm = null;

        vm.optInPlannedJobChoices = [
            {
                title: $translate.instant('NOT_APPLICABLE'),
                value: 0
            },
            {
                title: $translate.instant('MANDATORY'),
                value: 1
            },
        ]

        vm.operativeTypes = [
            {
                title: $translate.instant('JOB'),
                value: 0
            },
            {
                title: $translate.instant('ESCALATION'),
                value: 1
            },
            {
                title: $translate.instant('QUOTE'),
                value: 2
            },
            {
                title: $translate.instant('AUDIT'),
                value: 3
            },
            {
                title: $translate.instant('HEALTH_AND_SAFETY'),
                value: 4
            },
            {
                title: $translate.instant('PATROL'),
                value: 5
            },
            {
                title: $translate.instant('SURVEY'),
                value: 6
            }
        ];

        // keep "form" name instead of "preForm" name to avoid change name in backend
        if (vm.jobRequisite.getLink && vm.jobRequisite.getLink('form')) {
            selectedJobForm = lodash.find(formCollectionResponse.forms, ['_links.self.href', vm.jobRequisite.getLink('form')]);
            if (selectedJobForm) {
                selectedJobForm.formType = 'postFormsModel';
                selectedJobForm.formRemoved = 'postFormRemoved';
            }
        }

        if (vm.jobRequisite.getLink && vm.jobRequisite.getLink('postForm')) {
            selectedJobPostForm = lodash.find(formCollectionResponse.forms, ['_links.self.href', vm.jobRequisite.getLink('postForm')]);
            if (selectedJobPostForm) {
                selectedJobPostForm.formType = 'preFormsModel';
                selectedJobPostForm.formRemoved = 'preFormRemoved';
            }
        }

        if (formCollectionResponse && formCollectionResponse.forms.length > 0) {
            vm.preFormsModel = {
                response: selectedJobForm ? getFormWithoutSelected(selectedJobForm) : formCollectionResponse,
                link: formCollectionResponse.getLink('self'),
                required: false,
                responseKeyPath: 'forms',
                itemHrefPath: '_links.self.href',
                itemValuePath: 'summary',
                selected: selectedJobForm ? {
                    title: selectedJobForm.summary,
                    href: selectedJobForm.getLink('self'),
                    object: selectedJobForm
                } : null,
                formType: 'postFormsModel',
                formLink: 'form',
                formRemoved: 'postFormRemoved',
                onSelect: function (form) {changeForm(form, 'pre')},
                onRemove: function (form) {changeForm(form, 'pre')}
            };
            vm.postFormsModel = {
                response: selectedJobPostForm ? getFormWithoutSelected(selectedJobPostForm) : formCollectionResponse,
                link: formCollectionResponse.getLink('self'),
                required: false,
                responseKeyPath: 'forms',
                itemHrefPath: '_links.self.href',
                itemValuePath: 'summary',
                selected: selectedJobPostForm ? {
                    title: selectedJobPostForm.summary,
                    href: selectedJobPostForm.getLink('self'),
                    object: selectedJobPostForm
                } : null,
                formType: 'preFormsModel',
                formLink: 'postForm',
                formRemoved: 'preFormRemoved',
                onSelect: function (form) {changeForm(form, 'post')},
                onRemove: function (form) {changeForm(form, 'post')}
            };
        } else {
            vm.preFormsModel = null;
            vm.postFormsModel = null;
        }

        var watchPreForms = $scope.$watch('vm.preFormsModel.items', function(newVal) {
            if (newVal && selectedJobForm) {
                removeForm(selectedJobForm);
                watchPreForms();
            }
        });

        var watchPostForms = $scope.$watch('vm.postFormsModel.items', function(newVal) {
            if (newVal && selectedJobPostForm) {
                removeForm(selectedJobPostForm);
                watchPostForms();
            }
        });

        function getDefaults() {
            return {
                id: vm.jobRequisite.id || null,
                name: vm.jobRequisite.name || null,
                description: vm.jobRequisite.description || null,
                helpMessage: vm.jobRequisite.helpMessage || null,
                hasNotApplicableOption: (typeof vm.jobRequisite.hasNotApplicableOption === 'boolean') ? vm.jobRequisite.hasNotApplicableOption : null,
                requestAttachment: (typeof vm.jobRequisite.requestAttachment === 'boolean') ? vm.jobRequisite.requestAttachment : null,
                active: vm.jobRequisite.active === true || vm.jobRequisite.active === false ? vm.jobRequisite.active : null,
                optIn: typeof vm.jobRequisite.optIn === 'boolean' ? vm.jobRequisite.optIn : null,
                allowEscalation: typeof vm.jobRequisite.allowEscalation === 'boolean' ? vm.jobRequisite.allowEscalation : null,
                operativeType: typeof vm.jobRequisite.operativeType !== 'undefined' ? vm.jobRequisite.operativeType : null,
                approvalStrategy: vm.jobRequisite.approvalStrategy !== null ? vm.jobRequisite.approvalStrategy : null,
                quotedWorksOption: vm.jobRequisite.quotedWorksOption !== null ? vm.jobRequisite.quotedWorksOption : null,
                reactiveJobOption: vm.jobRequisite.reactiveJobOption !== null ? vm.jobRequisite.reactiveJobOption : null,
                plannedJobOption: vm.jobRequisite.plannedJobOption !== null ? vm.jobRequisite.plannedJobOption : null
            };
        }

        function createAction() {
            apiClient.create(jobRequisiteCollectionResponse.getLink('create'), vm.data).then(function (response) {
                if (response) {
                    $state.go('^', {}, { reload: $state.current.parent }).then(function () {
                        messenger.success('JOB_REQUISITE_CREATED');
                    });
                } else {
                    messenger.error('REQUEST_ERROR');
                }
            });
        }

        function updateAction() {
            if (vm.data.approvalStrategy !== 3 && vm.data.approvalStrategy !== 2) {
                vm.data.operativeType = null;
            }

            if (vm.data.approvalStrategy !== 3) {
                vm.data.allowEscalation = false;
            }

            if (vm.preFormsModel.selected) {
                vm.data._links.form = { href: vm.preFormsModel.selected.object._links.self.href }
            }

            if (vm.postFormsModel.selected) {
                vm.data._links.postForm = { href: vm.postFormsModel.selected.object._links.self.href }
            }

            apiClient.update(vm.jobRequisite._links.self.href, vm.data).then(function (response) {
                if (response) {
                    $state.go('^', {}, { reload: 'dashboard.admin.job-requisites.list' }).then(function () {
                        messenger.success('JOB_REQUISITE_UPDATED');
                    });
                } else {
                    messenger.error('REQUEST_ERROR');
                }
            });
        }

        function deleteAction() {
            return confirmationModal.open().result.then(function () {
                return apiClient.delete(vm.jobRequisite.getLink('delete')).then(function (response) {
                    if (response) {
                        $state.go('dashboard.admin.job-requisites.list', {}, { reload: 'dashboard.admin.job-requisites.list' }).then(function () {
                            messenger.success('JOB_REQUISITE_DELETED');
                        });
                    } else {
                        messenger.error('REQUEST_ERROR');
                    }
                });
            });
        }

        function changeForm(form, from) {
            if (!form) {
                form = {
                    formType: from === 'pre' ? 'postFormsModel' : 'preFormsModel',
                    formLink: from === 'pre' ? 'form' : 'postForm',
                    formRemoved: from === 'pre' ? 'postFormRemoved' : 'preFormRemoved'
                };
            }

            if (form && form.selected) {
                removeForm(form);
                vm.data._links[form.formLink] = { href: form.selected.href };
                vm.data.requestAttachment = null;
            } else {
                if (form.formLink && vm.data._links[form.formLink]) {
                    delete vm.data._links[form.formLink];
                }
                vm[form.formType].items.push(vm[form.formRemoved]);
                vm[form.formRemoved] = null;
                vm[form.formType].linkParameters = {excludeId: null};
            }
        }

        function removeForm(formToRemove) {
            var items = [];
            var formRemoved;
            var href = formToRemove.selected ? formToRemove.selected.href : formToRemove._links.self.href;
            var id = formToRemove.selected ? formToRemove.selected.id : formToRemove.id;
            items = vm[formToRemove.formType].items.filter(function (form) {
                if (form.href === href) {
                    formRemoved = form;
                }
                return form.href !== href;
            });

            vm[formToRemove.formType].items = items;

            if (vm[formToRemove.formRemoved]) {
                vm[formToRemove.formType].items.push(vm[formToRemove.formRemoved]);
            }

            vm[formToRemove.formRemoved] = formRemoved;
            vm[formToRemove.formType].linkParameters = {excludeId: id};
        }

        function getFormWithoutSelected(selectedForm) {
            var formCollection = angular.copy(vm.formCollectionResponse);
            formCollection.forms = formCollection.forms.filter(function(form) {
                return form.href !== selectedForm.href;
            });
        }
    }

})();
