(function() {
    'use strict';

    angular
        .module('elogbooks.admin.timesheet-types')
        .controller('TimesheetTypesDetailsController', TimesheetTypesDetailsController);

    TimesheetTypesDetailsController.$inject = ['timesheetTypeResponse'];
    function TimesheetTypesDetailsController(timesheetTypeResponse) {
        var vm = this;
        vm.timesheetType = timesheetTypeResponse;
    }
})();
