;(function() {
    'use strict';

    angular
        .module('elogbooksDirectives')
        .directive('elbInputSelectList', ElogbooksInputSelectList);

    ElogbooksInputSelectList.$inject = [];

    function ElogbooksInputSelectList() {
        return {
            restrict: 'E',
            scope: {
                model: '=',
                collection: '=',
                collectionAttribute: '@',
                collectionValuePath: '@',
                selectedValues: '=',
                apiSearchEnabled: '=',
                onChange: '=?',
            },
            templateUrl: '/modules/directives/elb-select-list/list.html',
            controller: ElogbooksInputSelectListController,
            controllerAs: 'vm'
        };

        ElogbooksInputSelectListController.$inject = [
            'lodash',
            'apiClient',
            '$scope'
        ];

        function ElogbooksInputSelectListController(
            lodash,
            apiClient,
            $scope
        ) {
            var vm = this;
            vm.selectOptions = [];
            vm.previousSelected = [];
            vm.model = $scope.model;
            vm.collection = $scope.collection;
            vm.collectionAttribute = $scope.collectionAttribute;
            vm.collectionValuePath = $scope.collectionValuePath;
            vm.selectedValues = $scope.selectedValues;
            vm.apiSearchEnabled = $scope.apiSearchEnabled ? $scope.apiSearchEnabled : true;
            vm.onChange = $scope.onChange;

            vm.add = Add;
            vm.addAll = AddAll;
            vm.remove = Remove;
            vm.removeAll = RemoveAll;
            vm.search = SearchAction;

            if (vm.collection && vm.collectionAttribute) {
                var collection = vm.collection[vm.collectionAttribute];
                CreateCollection(collection);
            }

            if (vm.selectedValues) {
                vm.selectModel = vm.selectedValues;
                Add();
            }

            $scope.$watch('vm.collection', function(newValue, oldValue) {
                if (newValue && newValue !== oldValue) {
                    var collection = vm.collection[vm.collectionAttribute];
                    vm.selected = [];
                    CreateCollection(collection);
                }
            }, true);

            function CreateCollection(collection) {
                vm.selectOptions = [];

                angular.forEach(collection, function(item, index) {
                    var collectionPathValue = item;

                    angular.forEach(vm.collectionValuePath.split("."), function(value) {
                        collectionPathValue = collectionPathValue[value];
                    });

                    vm.selectOptions.push({
                        'name': item.name,
                        'href': collectionPathValue.href
                    });
                });
            }

            function Add() {
                vm.selectOptions = lodash.differenceBy(vm.selectOptions, vm.selectModel, 'href');
                vm.selected = lodash.union(vm.selected, vm.selectModel);

                //remove duplicates
                vm.selected = lodash.uniqBy(vm.selected, 'href');
                $scope.model = vm.selected;
                vm.previousSelected = vm.selectOptions;

                if (vm.onChange) {
                    vm.onChange(vm.model);
                }
            }

            function AddAll() {
                angular.forEach(vm.selectOptions, function(selection) {
                    if (lodash.find(vm.model, {href: selection.href}) === undefined) {
                        if (vm.selected) {
                            vm.selected = lodash.union(vm.selected, vm.selectOptions);

                            //remove duplicates
                            vm.selected = lodash.uniqBy(vm.selected, 'href');
                        } else {
                            vm.selected = vm.selectOptions;
                        }

                        $scope.model = vm.selected;
                        vm.previousSelected = vm.selectOptions;

                        vm.selectOptions = [];
                    }
                });

                if (vm.onChange) {
                    vm.onChange(vm.model);
                }
            }

            function Remove() {
                vm.selected = lodash.difference(vm.selected, vm.newModel);
                vm.selectOptions = lodash.union(vm.selectOptions, vm.newModel);

                //remove duplicates
                vm.selectOptions = lodash.uniqBy(vm.selectOptions, 'href');
                $scope.model = vm.selected;

                if (vm.onChange) {
                    vm.onChange(vm.model);
                }
            }

            function RemoveAll() {
                if (vm.selectOptions) {
                    vm.selectOptions = lodash.union(vm.selectOptions, vm.selected);
                } else {
                    vm.selectOptions = vm.selected;
                }

                //remove duplicates
                vm.selectOptions = lodash.uniqBy(vm.selectOptions, 'href');

                $scope.model = vm.selected = [];

                if (vm.onChange) {
                    vm.onChange(vm.model);
                }
            }

            function SearchAction() {
                if (vm.apiSearchEnabled && vm.collection && vm.collection.getLink('self')) {
                    var params = {
                        name: vm.searchInput
                    };

                    apiClient
                        .get(vm.collection.getLink('self'), params)
                        .then(function(response) {
                            CreateCollection(response[vm.collectionAttribute]);
                        });
                }
            }
        }
    }
})();
