(function () {
    'use strict';

    angular
        .module('elogbooks.admin.users')
        .config(registerRoutes)
        .config(registerRoutesList)
        .config(registerRoutesDetails)
        .config(registerRoutesAdd)
        .config(registerRoutesOther);

    function registerRoutes ($stateProvider) {
        $stateProvider
            .state('dashboard.admin.users', {
                abstract: true,
                templateUrl: 'shared-templates/view.html',
                ncyBreadcrumb: {
                    skip: true
                },
                data: {
                    permissions: ['user_permission_view_users']
                }
            });
    }

    function registerRoutesList ($stateProvider) {
        $stateProvider
            .state('dashboard.admin.users.list', {
                url: '/users?page&limit&order&adminUserId&userName&userCategory&userUsername&userEmail&userServiceProviderName$userStatus',
                parent: 'dashboard.admin.users',
                data: {
                    permissions: ['user_permission_view_users']
                },
                views: {
                    '@dashboard.admin.users': {
                        templateUrl: '/modules/admin/users/user-list.html',
                        controller: 'UsersController',
                        controllerAs: 'vm'
                    }
                },
                params: {
                    page: '1',
                    limit: '30',
                    order: 'id',

                    adminUserId: null,
                    userName: null,
                    userCategory: {array: true},
                    userUsername: null,
                    userEmail: null,
                    userServiceProviderName: null,
                    userStatus: null,
                    userId: null,
                    excludeUserSites: null
                },
                resolve: {
                    userCollectionResponse: function ($stateParams, apiClient, rootResourceResponse, userManager, user) {
                        var params = {
                            page: $stateParams.page,
                            limit: $stateParams.limit,
                            order: $stateParams.order,
                            id: $stateParams.adminUserId,
                            name: $stateParams.userName,
                            'category[]': $stateParams.userCategory,
                            username: $stateParams.userUsername,
                            email: $stateParams.userEmail,
                            serviceProviderName: $stateParams.userServiceProviderName,
                            status: $stateParams.userStatus,
                            clientAdminAccess: userManager.hasPermission('user_permission_client_admin_manage_users') && !userManager.hasPermission('user_permission_manage_users')
                        };

                        return apiClient.get(rootResourceResponse.getLink('users'), params).then(function (response) {
                            return response || apiClient.noResourceFound();
                        });
                    },
                    userTypesResponse: function (apiClient, rootResourceResponse) {
                        return apiClient.get(rootResourceResponse.getLink('usertypes')).then(function(response) {
                            return response || apiClient.noResourceFound();
                        });
                    },
                    userCategoriesResponse: function (apiClient, rootResourceResponse) {
                        return apiClient.get(rootResourceResponse.getLink('usercategories')).then(function(response) {
                            return response || apiClient.noResourceFound();
                        });
                    }
                },
                ncyBreadcrumb: {
                    label: '{{ ::"USERS" | translate }}'
                }
            });
    }

    function registerRoutesAdd ($stateProvider) {
        $stateProvider
            .state('dashboard.admin.users.list.add', {
                url: '/add',
                parent: 'dashboard.admin.users.list',
                data: {
                    permissions: ['user_permission_manage_users']
                },
                views: {
                    "@dashboard.admin.users": {
                        templateUrl: '/modules/admin/users/add-edit/user-form.html',
                        controller: 'UserAddEditController',
                        controllerAs: 'vm'
                    }
                },
                resolve: {
                    userResponse: function () {
                        return {
                            type: 'standard'
                        };
                    },
                    password: function(apiClient, rootResourceResponse) {
                        return apiClient.get(rootResourceResponse.getLink('passwordgenerator')).then(function(response) {
                            return response.password;
                        });
                    },
                    departmentsResponse: function(apiClient, userCollectionResponse) {
                        if (userCollectionResponse.getLink('departments')) {
                            return apiClient.get(userCollectionResponse.getLink('departments'), { active: true }).then(function(response) {
                                return response;
                            });
                        }
                    },
                    selectedDepartment: function() {
                        return null;
                    },
                },
                ncyBreadcrumb: {
                    label: '{{ ::"ADD" | translate }}'
                }
            });
    }

    function registerRoutesDetails ($stateProvider) {
        $stateProvider
            .state('dashboard.admin.users.list.details', {
                url: '/details/{resource}',
                abstract: true,
                parent: 'dashboard.admin.users.list',
                data: {
                    permissions: ['user_permission_view_users']
                },
                views: {
                    "@dashboard.admin.users": {
                        templateUrl: '/modules/admin/users/details/user-details.html',
                        controller: 'UserDetailsController',
                        controllerAs: 'vm'
                    }
                },
                resolve: {
                    userResponse: function (apiClient, $stateParams) {
                        return apiClient.get($stateParams.resource.decode()).then(function (response) {
                            return response || apiClient.noResourceFound();
                        });
                    }
                },
                ncyBreadcrumb: {
                    label: '{{ ::$resolves().userResponse.name }}'
                }
            })
            .state('dashboard.admin.users.list.details.info', {
                url: '/info',
                parent: 'dashboard.admin.users.list.details',
                data: {
                    permissions: ['user_permission_view_users']
                },
                views: {
                    "@dashboard.admin.users.list.details": {
                        templateUrl: '/modules/admin/users/details/info/user-info.html',
                        controller: 'UserInfoController',
                        controllerAs: 'vm'
                    },
                    'feed@dashboard.admin.users.list.details.info': {
                        templateUrl: '/modules/admin/users/details/event/feed.html',
                        controller: 'UserDetailsEventFeedController',
                        controllerAs: 'vm'
                    }
                },
                resolve: {
                    contactInformationResponse: function (apiClient, userResponse) {
                        return userResponse.getResource('contactinformation').then(function (response) {
                            return response || apiClient.noResourceFound();
                        });
                    },
                    eventCollectionResponse: function (apiClient, userResponse) {
                        if (userResponse.getLink('events')) {
                            return apiClient.get(userResponse.getLink('events'), { datagroup: 'details' }).then(function(response) {
                                return response || apiClient.noResourceFound();
                            });
                        }
                    },
                    config: function (userManager, rootResourceResponse) {
                        return {
                            isHelpdesk: userManager.hasPermission('site_permission_helpdesk'),
                            passwordChangeRequestLink: rootResourceResponse.getLink('passwordchangerequests'),
                            usernameReminderLink: rootResourceResponse.getLink('usernamereminder')
                        };
                    }
                },
                ncyBreadcrumb: {
                    label: '{{ ::"INFO" | translate }}'
                }
            })
            .state('dashboard.admin.users.list.details.info.edit', {
                url: '/edit',
                parent: 'dashboard.admin.users.list.details.info',
                data: {
                    permissions: ['user_permission_manage_users', 'user_permission_client_admin_manage_users']
                },
                views: {
                    '@dashboard.admin.users.list.details': {
                        templateUrl: '/modules/admin/users/add-edit/user-form.html',
                        controller: 'UserAddEditController',
                        controllerAs: 'vm'
                    }
                },
                resolve: {
                    password: function() {
                        return {};
                    },
                    departmentsResponse: function(apiClient, userResponse) {
                        if (userResponse.getLink('departments')) {
                            return apiClient.get(userResponse.getLink('departments'), { active: true }).then(function(response) {
                                return response;
                            });
                        }
                    },
                    selectedDepartment: function(apiClient, userCollectionResponse, userResponse, departmentsResponse) {
                        if (userResponse && userResponse.getLink('department') && departmentsResponse) {
                            var departmentId = parseInt(userResponse._links.department.id),
                                department = departmentsResponse.departments.find(function(d) {
                                    return d.id === departmentId;
                                });

                            if (department) {
                                return department;
                            } else if (userResponse.getLink('department')) {
                                return apiClient.get(
                                    userCollectionResponse.getLink('departments'),
                                    { active: true, id: userResponse._links.department.id }
                                ).then(function(response) {
                                    return response && response.departments && response.departments.length ?
                                        response.departments[0] : null;
                                });
                            } else {
                                return null;
                            }
                        }
                    },
                },
                ncyBreadcrumb: {
                    label: '{{ ::"EDIT" | translate }}'
                }
            })
            .state('dashboard.admin.users.list.details.site-access', {
                url: '/site-access?sitesPage&name',
                parent: 'dashboard.admin.users.list.details',
                data: {
                    permissions: ['user_permission_manage_users', 'user_permission_client_admin_manage_users'] // BE requires manage users permission for /users/{user}/membership
                },
                params: {
                    sitesPage: '1',
                    name: ''
                },
                views: {
                    "@dashboard.admin.users.list.details": {
                        templateUrl: '/modules/admin/users/details/site-access/site-access.html',
                        controller: 'UserSiteAccessController',
                        controllerAs: 'vm'
                    }
                },
                ncyBreadcrumb: {
                    label: '{{ ::"SITE_ACCESS" | translate }}'
                },
                resolve: {
                    membershipsResponse: function (apiClient, $stateParams, userResponse) {
                        var params = {
                            page: $stateParams.sitesPage,
                            name: $stateParams.name
                        };

                        params = angular.extend({}, $stateParams, params);

                        return apiClient.get(userResponse.getLink('memberships'), params).then(function (response) {
                            return response || apiClient.noResourceFound();
                        });
                    }
                }
            })
            .state('dashboard.admin.users.list.details.site-access.change', {
                url: '/change',
                data: {
                    permissions: ['user_permission_manage_users', 'user_permission_client_admin_manage_users']
                },
                views: {
                    "@dashboard.admin.users.list.details": {
                        templateUrl: '/modules/admin/users/details/site-access/change/change-access.html',
                        controller: 'UserChangeSiteAccessController',
                        controllerAs: 'vm'
                    }
                },
                resolve: {
                    userCollectionResponse: function (apiClient, rootResourceResponse) {
                        return apiClient.get(rootResourceResponse.getLink('users')).then(function (response) {
                            return response || apiClient.noResourceFound();
                        });
                    }
                },
                params: {
                    ignoreEventPreventDefault: true
                },
                ncyBreadcrumb: {
                    label: '{{ ::"EDIT" | translate }}'
                }
            })
            .state('dashboard.admin.users.list.details.site-permissions', {
                url: '/site-permissions',
                parent: 'dashboard.admin.users.list.details',
                data: {
                    permissions: ['user_permission_view_users']
                },
                views: {
                    '@dashboard.admin.users.list.details': {
                        templateUrl: '/modules/common/permissions/permissions.html',
                        controller: 'PermissionsController',
                        controllerAs: 'vm'
                    }
                },
                resolve: {
                    permissionService: function (sitePermissions) {
                        return sitePermissions;
                    },
                    permissionKey: function () {
                        return 'defaultSiteMembershipPermissions';
                    },
                    entityResponse: function (userResponse) {
                        return userResponse;
                    },
                    entityName: function () {
                        return 'SITE';
                    }
                },
                ncyBreadcrumb: {
                    label: '{{ ::"SITE_PERMISSIONS" | translate }}'
                }
            })
            .state('dashboard.admin.users.list.details.user-permissions', {
                url: '/user-permissions',
                parent: 'dashboard.admin.users.list.details',
                data: {
                    permissions: ['user_permission_view_users']
                },
                views: {
                    '@dashboard.admin.users.list.details': {
                        templateUrl: '/modules/common/permissions/permissions.html',
                        controller: 'PermissionsController',
                        controllerAs: 'vm'
                    }
                },
                resolve: {
                    permissionService: function (userPermissions) {
                        return userPermissions;
                    },
                    permissionKey: function () {
                        return 'permissions';
                    },
                    entityResponse: function (userResponse) {
                        return userResponse;
                    },
                    entityName: function () {
                        return 'USER';
                    }
                },
                ncyBreadcrumb: {
                    label: '{{ ::"USER_PERMISSIONS" | translate }}'
                }
            })
            .state('dashboard.admin.users.list.details.report-permissions', {
                url: '/report-permissions',
                parent: 'dashboard.admin.users.list.details',
                data: {
                    permissions: ['user_permission_view_users']
                },
                views: {
                    '@dashboard.admin.users.list.details': {
                        templateUrl: '/modules/common/permissions/permissions.html',
                        controller: 'PermissionsController',
                        controllerAs: 'vm'
                    }
                },
                resolve: {
                    permissionService: function (reportPermissions) {
                        return reportPermissions;
                    },
                    permissionKey: function () {
                        return 'permissions';
                    },
                    entityResponse: function (userResponse) {
                        return userResponse;
                    },
                    entityName: function () {
                        return 'USER';
                    }
                },
                ncyBreadcrumb: {
                    label: '{{ ::"REPORT_PERMISSIONS" | translate }}'
                }
            })
            .state('dashboard.admin.users.list.details.operatives', {
                url: '/operatives?operativesPage&operativesLimit&operativesOrder',
                templateUrl: '/modules/admin/users/details/operatives/operatives.html',
                controller: 'UserOperativeController',
                controllerAs: 'vm',
                data: {
                    permissions: ['user_permission_view_users']
                },
                params:{
                    operativesPage: '1',
                    operativesLimit: '999',
                    operativesOrder: 'name'
                },
                resolve: {
                    operativesResponse: function (apiClient, userResponse, $stateParams) {
                        var params = {
                            page:  $stateParams.operativesPage,
                            limit: $stateParams.operativesLimit,
                            order: $stateParams.operativesOrder
                        };
                        return apiClient.get(userResponse.getLink('operatives'), params).then(function (response) {
                            return response || apiClient.noResourceFound();
                        });
                    },
                    serviceProviderCollectionResponse: function (apiClient, rootResourceResponse, $stateParams) {
                        return apiClient.get(rootResourceResponse.getLink('serviceproviders'), { datagroup: 'dropdown', limit: 999 }).then(function (response) {
                            return response || apiClient.noResourceFound();
                        });
                    }
                },
                ncyBreadcrumb: {
                    label: '{{ ::"SERVICE_PROVIDERS" | translate }}'
                }
            })
            .state('dashboard.admin.users.list.details.roles', {
                url: '/roles',
                parent: 'dashboard.admin.users.list.details',
                data: {
                    permissions: ['user_permission_view_users']
                },
                views: {
                    "@dashboard.admin.users.list.details": {
                        templateUrl: '/modules/admin/users/details/roles/user-roles.html',
                        controller: 'UserRolesController',
                        controllerAs: 'vm'
                    }
                },
                resolve: {
                    roleCollectionResponse: function (apiClient, rootResourceResponse) {
                        return apiClient.get(rootResourceResponse.getLink('roles'), {limit: 100}).then(function (response) {
                            return response || apiClient.noResourceFound();
                        });
                    }
                },
                ncyBreadcrumb: {
                    label: '{{ ::"ROLES" | translate }}'
                }
            })
            .state('dashboard.admin.users.list.details.tokens', {
                url: '/tokens?tokensPage&tokensLimit&tokensValid',
                parent: 'dashboard.admin.users.list.details',
                data: {
                    permissions: ['user_permission_manage_users'] // BE /users/{user}/tokens requires manage users permission.
                },
                params: {
                    tokensPage: '1',
                    tokensLimit: '30',
                    tokensValid: null
                },
                views: {
                    '@dashboard.admin.users.list.details': {
                        templateUrl: '/modules/admin/users/details/tokens/tokens-list.html',
                        controller: 'UserTokensController',
                        controllerAs: 'vm'
                    }
                },
                resolve: {
                    tokensCollectionResponse: function (apiClient, $stateParams, userResponse) {
                        var params = angular.extend({}, $stateParams, {
                            page: $stateParams.tokensPage,
                            limit: $stateParams.tokensLimit,
                            valid: $stateParams.tokensValid
                        });

                        return apiClient.get(userResponse.getLink('tokens'), params).then(function (response) {
                            return response || apiClient.noResourceFound();
                        });
                    }
                },
                ncyBreadcrumb: {
                    label: '{{ ::"TOKENS" | translate }}'
                }
            })
            .state('dashboard.admin.users.list.details.tokens.add', {
                url: '/add',
                parent: 'dashboard.admin.users.list.details.tokens',
                data: {
                    permissions: ['user_permission_manage_users']
                },
                views: {
                    '@dashboard.admin.users.list.details': {
                        templateUrl: '/modules/admin/users/details/tokens/token-add.html',
                        controller: 'UserTokenAddController',
                        controllerAs: 'vm'
                    }
                },
                ncyBreadcrumb: {
                    label: '{{ ::"TOKEN_ADD" | translate }}'
                }
            })
            .state('dashboard.admin.users.list.details.service-types', {
                url: '/service-types?serviceTypePage&serviceTypeLimit',
                parent: 'dashboard.admin.users.list.details',
                data: {
                    permissions: ['user_permission_manage_users'] // BE /users/{user}/servicetypes requires manage users permission.
                },
                params: {
                    serviceTypePage: '1',
                    serviceTypeLimit: '30'
                },
                views: {
                    '@dashboard.admin.users.list.details': {
                        templateUrl: '/modules/common/service-types/list/service-types.html',
                        controller: 'CommonServiceTypesController',
                        controllerAs: 'vm'
                    }
                },
                resolve: {
                    serviceTypesCollectionResponse: function (apiClient, userResponse, $stateParams, $state) {
                        return apiClient.get(userResponse.getLink('servicetypes'), angular.extend({}, $stateParams, {
                            page: $stateParams.serviceTypePage,
                            limit: $stateParams.serviceTypeLimit,
                        })).then(function(response) {
                            return response || apiClient.noResourceFound();
                        });
                    },
                    config: function() {
                        return {
                            noBorder: false
                        };
                    }
                },
                ncyBreadcrumb: {
                    label: '{{ ::"SERVICE_TYPES" | translate }}'
                }
            })
            .state('dashboard.admin.users.list.details.service-types.add', {
                url: '/add',
                parent: 'dashboard.admin.users.list.details.service-types',
                data: {
                    permissions: ['user_permission_manage_users']
                },
                views: {
                    '@dashboard.admin.users.list.details': {
                        templateUrl: '/modules/common/service-types/add/service-type-add.html',
                        controller: 'CommonServiceTypeAddController',
                        controllerAs: 'vm'
                    }
                },
                resolve: {
                    serviceTypesRootResponse: function($stateParams, apiClient, rootResourceResponse) {
                        return apiClient.get(rootResourceResponse.getLink('servicetypes'), $stateParams).then(function(response) {
                            return response || apiClient.noResourceFound();
                        });
                    }
                },
                ncyBreadcrumb: {
                    label: '{{ ::"SERVICE_TYPE_ADD" | translate }}'
                }
            });
    }

    function registerRoutesOther ($stateProvider) {
        $stateProvider
            .state('dashboard.admin.users.list.details.site-access.form', {
                url: '/form',
                data: {
                    permissions: ['user_permission_manage_users', 'user_permission_client_admin_manage_users']
                },
                resolve: {
                    sitesResponse: function ($stateParams, apiClient, rootResourceResponse) {
                        return apiClient.get(rootResourceResponse.getLink('sites'), { order: ['name'] }).then(function (response) {
                            return response || apiClient.noResourceFound();
                        });
                    }
                },
                views: {
                    "@dashboard.admin.users.list.details": {
                        templateUrl: '/modules/admin/users/details/site-access/form/user-site-access-form.html',
                        controller: 'UserSiteAccessFormController',
                        controllerAs: 'vm'
                    }
                },
                params: {
                    userId: null,
                    excludeUserSites: null,
                    ignoreEventPreventDefault: true
                },
                ncyBreadcrumb: {
                    label: '{{ ::"ADD" | translate }}'
                }
            });
    }
})();
