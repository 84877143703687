(function() {
    'use strict';

    angular
        .module('elogbooks.admin.site-relationships')
        .controller('SiteRelationshipsAddEditController', SiteRelationshipsAddEditController);

    SiteRelationshipsAddEditController.$inject = ['$state', 'apiClient', 'confirmationModal', 'messenger', 'siteRelationshipResponse', 'siteRelationshipsCollectionResponse', 'requestDataFactory'];
    function SiteRelationshipsAddEditController($state, apiClient, confirmationModal, messenger, siteRelationshipResponse, siteRelationshipsCollectionResponse, requestDataFactory) {
        var vm = this;
        vm.siteRelationship = siteRelationshipResponse;
        vm.siteRelationshipsCollectionResponse = siteRelationshipsCollectionResponse;
        vm.data = requestDataFactory.createRequest(getDefaults());

        vm.create = createAction;
        vm.update = updateAction;
        vm.delete = deleteAction;

        function getDefaults() {
            return {
                id: vm.siteRelationship.id || null,
                name: vm.siteRelationship.name || null
            };
        }

        function updateAction() {
            return apiClient.update(vm.siteRelationship.getLink('edit'), vm.data).then(function(response) {
                if (response) {
                    $state.go('^', {}, {reload: 'dashboard.admin.site-relationships.list'}).then(function() {
                        messenger.success('SITE_RELATIONSHIP_UPDATED');
                    });
                } else {
                    messenger.error('REQUEST_ERROR');
                }
            });
        }

        function createAction() {
            return apiClient.create(vm.siteRelationshipsCollectionResponse.getLink('create'), vm.data).then(function(response) {
                if (response) {
                    $state.go('dashboard.admin.site-relationships.list.details.info', {resource: response.getLink('self').encode()}, { reload: $state.current.parent }).then(function() {
                        messenger.success('SITE_RELATIONSHIP_CREATED');
                    });
                } else {
                    messenger.error('REQUEST_ERROR');
                }
            });
        }

        function deleteAction() {
            return confirmationModal.open().result.then(function(response) {
                if (response.result) {
                     apiClient.delete(vm.siteRelationship.getLink('delete')).then(function(isSuccess) {
                        if (isSuccess) {
                            $state.go('dashboard.admin.site-relationships.list', {}, {reload: 'dashboard.admin.site-relationships.list'}).then(function(){
                                messenger.success('SITE_RELATIONSHIP_DELETED');
                            });
                        } else {
                            messenger.error('REQUEST_ERROR');
                        }
                    });
                }
            });
        }
    }
})();
