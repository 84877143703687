(function() {
    'use strict';

    angular
        .module('elogbooks.user.finance.credit-notes')
        .controller('FinanceCreditNoteWorkflowController', FinanceCreditNoteWorkflowController);

    FinanceCreditNoteWorkflowController.$inject = ['creditNoteResponse'];

    function FinanceCreditNoteWorkflowController(creditNoteResponse) {
        var vm = this;
        vm.creditNoteResponse = creditNoteResponse;
    }
})();