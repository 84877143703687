app.config(function ($translateProvider) {
    $translateProvider.translations('zh-hk', {
        PATROL: '巡邏',
        PATROLS: '巡邏',


        PATROL_ADD: '新增巡邏',
        PATROL_EDIT: '修改巡邏',
        PATROL_LIST: '巡邏',

        PATROL_CREATED: '巡邏已建立',
        PATROL_UPDATED: '巡邏已更新',
        PATROL_DELETED: '巡邏已刪除',

        ORDERED: '指令',
        ORDERED_YES: '有指令',
        ORDERED_NO: '無指令',

        PATROL_POINT: '巡邏點',
        PATROL_POINTS: '巡邏點',
        PATROL_POINT_TAB: '巡邏點',
        PATROL_POINT_LIST: '巡邏點',
        PATROLPOINTS_COUNT: '巡邏點',
        PATROLPOINTS_TIME_COUNT:'時間',
        PATROL_POINT_ADD: '增加巡邏點',
        PATROL_POINT_EDIT: '修改巡邏點',
        TIMEALLOCATED: '時間分配',
        REQUIREMENTTYPE: '需求類別',
        REQUIREMENT: '需求',
        PATROL_POINT_REQUIREMENT_NONE: '無',
        PATROL_POINT_REQUIREMENT_BARCODE: '條碼',
        PATROL_POINT_REQUIREMENT_NFC: 'NFC',
        PATROL_POINT_REQUIREMENT_PHOTO: '相片',
        PATROL_POINT_REQUIREMENT_SIGNATURE: '簽名',
        PATROL_POINT_REQUIREMENT_FILE: '檔案',
        PATROLPOINT_CREATED: '巡邏點已建立',
        PATROLPOINT_UPDATED: '巡邏點已更新',
        PATROLPOINT_DELETED: '巡邏點已刪除',

        PATROL_TEMPLATE: '巡邏樣板',
        PATROL_TEMPLATE_LIST: '巡邏樣板',
        PATROL_TEMPLATE_ADD: '增加樣板',
        PATROL_TEMPLATE_EDIT: '修改樣板',

        PATROL_SCHEDULE: '巡邏時間表',
        PATROL_SCHEDULES: '巡邏時間表',
        PATROL_SCHEDULE_ADD: '增加時間表',
        PATROL_SCHEDULE_EDIT: '修改時間表',
        DAYOFWEEK: '星期',
        DAY_OF_WEEK: '星期',
        START_TIME: '開始時間',
        END_TIME: '結束時間',
    });
});
