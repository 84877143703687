(function() {
    'use strict';

    angular
        .module('elogbooks.admin.core-clients')
        .controller('DocumentSetsSitesController', DocumentSetsSitesController);

    DocumentSetsSitesController.$inject = [
        'apiClient',
        'messenger',
        '$state',
        '$stateParams',
        'confirmationModal',
        'documentSetResponse',
        'documentSetSitesResponse'
    ];

    function DocumentSetsSitesController(
        apiClient,
        messenger,
        $state,
        $stateParams,
        confirmationModal,
        documentSetResponse,
        documentSetSitesResponse
    )
    {
        var vm = this;
        vm.documentSetResponse = documentSetResponse;
        vm.documentSetSitesResponse = documentSetSitesResponse;
        vm.sites = documentSetSitesResponse.sites;

        vm.canAdd = vm.documentSetResponse.getLink('add-sites');
        vm.order = order;
        vm.delete = deleteAction;

        function order(key) {
            $stateParams.typesOrder = key;

            $state.go('.', angular.extend({}, $stateParams), {reload: $state.current});
        }

        function deleteAction (site) {
            return confirmationModal.open().result.then(function () {
                return apiClient.delete(site.getLink('delete')).then(function (response) {
                    if (response) {
                        $state.go('.', $stateParams, { reload: $state.$current.parent.self.parent })
                            .then(function () {
                                messenger.success('SITE_DELETED');
                            });
                    } else {
                        messenger.error('REQUEST_ERROR');
                    }
                });
            });
        }
    }
})();
