(function () {
    'use strict';

    angular
        .module('elogbooksDirectives')
        .directive('serviceProviderSelect', ['apiClient', serviceProviderSelect]);

    function serviceProviderSelect(apiClient) {
        var directive = {
            link: link,
            templateUrl: '/modules/directives/form-elements/service-provider-select/service-provider-select.html',
            restrict: 'EA',
            scope: {
                provider: '=ngModel',
                required: '=ngRequired',
                disabled: '=ngDisabled',
                source: '=serviceProviderSource',
                params: '=serviceProviderSourceParams',
                exclude: '=',
                form: '='
            },
            require: ['ngModel']
        };
        return directive;

        function link(scope, element, attrs) {
            scope.$watchGroup(['source', 'params'], function() {
                scope.serviceproviders = null;

                if (!scope.source) {
                    return;
                }

                apiClient.get(scope.source, scope.params).then(function(response) {
                    var temp = response.serviceproviders, result = [];

                    if (typeof scope.exclude === 'object') {
                        angular.forEach(temp, function (value, key) {
                            if (scope.exclude.indexOf(value.getLink('self')) === -1) {
                                result.push(value);
                            }
                        });
                    } else {
                        result = temp;
                    }

                    scope.serviceproviders = result;
                });
            });
        }
    }
})();
