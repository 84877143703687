(function () {
    'use strict';

    angular
        .module('elogbooks.admin.service-provider-groups')
        .controller('AdminServiceProviderGroupServiceProvidersController', AdminServiceProviderGroupServiceProvidersController);

    AdminServiceProviderGroupServiceProvidersController.$inject = ['$state', 'serviceProvidersCollectionResponse', 'rootServiceProvidersCollectionResponse', 'crudService', 'lodash'];

    function AdminServiceProviderGroupServiceProvidersController($state, serviceProvidersCollectionResponse, rootServiceProvidersCollectionResponse, crudService, lodash) {
        var vm = this;
        vm.groupServiceProviders = serviceProvidersCollectionResponse.serviceproviders;
        vm.serviceProviders = rootServiceProvidersCollectionResponse.serviceproviders;

        vm.remove = removeServiceProvider;

        function updateServiceProviders() {
            var data = {
                '_links': {
                    'serviceprovider': []
                }
            }

            angular.forEach(vm.groupServiceProviders, function (groupServiceProvider) {
                data._links.serviceprovider.push(groupServiceProvider._links.self);
            })

            crudService.update(serviceProvidersCollectionResponse._links.update.href, data, $state.current, false, 'SERVICE_PROVIDERS');
        }

        function removeServiceProvider(serviceProvider) {
            lodash.remove(vm.groupServiceProviders, function (groupServiceProvider) {
                return groupServiceProvider.name == serviceProvider.name;
            });

            updateServiceProviders();
        }
    }
})();
