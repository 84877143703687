(function () {
    'use strict';

    angular
        .module('elogbooksFilters')
        .filter('elbCurrency', elbCurrency);

    elbCurrency.$inject = ['$translate', '$filter', 'elbSettings'];

    function elbCurrency($translate, $filter, elbSettings) {
        return function (input) {
            if (undefined !== input) {
                var output = '';
                var currency = elbSettings.getSetting('currency').value;
                var locale = elbSettings.getSetting('general_default_locale').value;

                if (currency === 'NONE') {
                    input = parseFloat(input).toLocaleString(locale, {maximumFractionDigits: 2});
                    output = $filter('translate')('CURRENCY_' + currency) + input;
                } else {
                    output = parseFloat(input).toLocaleString(locale, {
                        maximumFractionDigits: 2,
                        style: 'currency',
                        currency: currency
                    });
                }

                return output.trim();
            }
        };
    }
})();
