(function () {
    'use strict';

    angular
        .module('elogbooks.admin.settings')
        .controller('AdminPublicHolidayAddEditController', AdminPublicHolidayAddEditController);

    function AdminPublicHolidayAddEditController($state, apiClient, messenger, confirmationModal, publicHolidaysCollectionResponse, requestDataFactory, publicHolidayResponse) {

        var vm = this;

        vm.publicHoliday = publicHolidayResponse;
        vm.data = requestDataFactory.createRequest(vm.publicHoliday);

        if (vm.publicHoliday) {
            vm.data.date = new Date(vm.data.date);
        }

        vm.create = createAction;
        vm.update = updateAction;
        vm.delete = deleteAction;


        function createAction() {
            return apiClient.create(publicHolidaysCollectionResponse.getLink('create'), vm.data).then(function (response) {
                if (response) {
                    $state.go('^', {}, {reload: $state.current.parent}).then(function () {
                        messenger.success('PUBLIC_HOLIDAY_ADDED');
                    });
                } else {
                    messenger.error('REQUEST_ERROR');
                }
            });
        }

        function updateAction() {
            return apiClient.update(vm.publicHoliday.getLink('edit'), vm.data).then(function (response) {
                if (response) {
                    $state.go('^', {}, {reload: $state.current.parent}).then(function (response) {
                        messenger.success('PUBLIC_HOLIDAY_UPDATED');
                    });
                } else {
                    messenger.error('REQUEST_ERROR');
                }
            });
        }

        function deleteAction() {
            return confirmationModal.open().result.then(function (response) {
                return apiClient.delete(vm.publicHoliday.getLink('delete')).then(function (response) {
                    if (response) {
                        $state.go('dashboard.admin.settings.public-holidays', {}, {reload: $state.current.parent}).then(function () {
                            messenger.success('PUBLIC_HOLIDAY_DELETED');
                        });
                    } else {
                        messenger.error('REQUEST_ERROR');
                    }
                });
            });
        }
    }
})();
