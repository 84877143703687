(function () {
    'use strict';

    angular
        .module('elogbooks.admin.regions')
        .controller('RegionSitesController', RegionSitesController);

    RegionSitesController.$inject = ['regionResponse', 'selectedSiteCollectionResponse', '$stateParams', '$state', 'lodash', 'confirmationModal', 'messenger', 'apiClient', '$filter'];

    function RegionSitesController (regionResponse, selectedSiteCollectionResponse, $stateParams, $state, lodash, confirmationModal, messenger, apiClient, $filter) {
        var vm = this;
        vm.region = regionResponse;
        vm.sites = selectedSiteCollectionResponse.sites;
        vm.entityCount = selectedSiteCollectionResponse.count;
        vm.search = search;
        vm.order = order;
        vm.checked = {};
        vm.deleteSelected = deleteAction;
        vm.isActive = isActive;


        var statusOptions = [
            {
                title: $filter('translate')('NONE_SELECTED'),
                value: null
            },
            {
                title: $filter('translate')('STATUS_ACTIVE'),
                value: 'active'
            },
            {
                title: $filter('translate')('STATUS_INACTIVE'),
                value: 'inactive'
            }
        ];

        vm.criteria = {
            siteId: { type: 'number', value: $stateParams.siteId ? parseInt($stateParams.siteId, 10) : null, title: 'ID' },
            siteName: { type: 'text', value: $stateParams.siteName, title: 'SITE' },
            siteStatus: {type: 'options', title: 'STATUS', value: $stateParams.siteStatus, options: statusOptions},
        }

        function search (params) {
            var override = {
                sitePage: 1
            };

            $state.go('.', angular.extend({}, params, override), {reload: $state.current});
        };

        function order (key) {
            $stateParams.siteOrder = key;

            var override = {
                sitePage: 1
            };

            $state.go('.', angular.extend({}, $stateParams, override), { reload: true });
        }

        function deleteAction() {
            var toRemove = [];
            lodash.forEach(vm.checked, function(value, site) {
                if(value === true) {
                    toRemove.push({"href": site});
                }
            });

            if (toRemove.length > 0) {
                var data = {
                    _links: {}
                };

                data._links.site = toRemove;

                // Show an alert asking the user to confirm
                return confirmationModal.open().result.then(function () {
                    return apiClient.update(vm.region.getLink('sites'), data).then(function (response) {
                        if (response) {
                            $state.go('.', {}, { reload: $state.current.name }).then(function () {
                                messenger.success('SITES_DELETED_FROM_REGION');
                            });
                        } else {
                            messenger.error('REQUEST_ERROR');
                        }
                    });
                });
            } else {
                // Show an alert telling the user they need to select at least one site
                return confirmationModal.open({
                    titleMessage: 'ZERO_OPTIONS_CHECKED_TITLE',
                    bodyMessage: 'ZERO_OPTIONS_CHECKED_MESSAGE',
                    primaryText: null,
                    secondaryText: 'OK'
                });
            }
        }


        function isActive(site) {
            var currentDate = new Date().toRFC3339String();
            return ((typeof site.activeAt === 'undefined' || site.activeAt < currentDate) && (typeof site.inactiveAt === 'undefined' || site.inactiveAt > currentDate));
        };
    }

})();
