/**
 * Image Tabs
 */
angular.module('elogbooksDirectives').directive('imageTabs', ['$http', 'apiClient', '$state', function ($http, apiClient, $state) {
    return {
        restrict: 'AE',
        transclude: true,
        scope: true,
        // scope: {
        //     bookerize: '@'
        // },
        replace: true,
        templateUrl: '/modules/directives/layout/image-tabs/image-tabs.html',
        link: function($scope, $element, $attrs) {
            $scope.usingBookerize = false;

            if (typeof $attrs.bookerize !== 'undefined') {
                $scope.usingBookerize = true;
            }
        }
    };
}]);
