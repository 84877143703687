(function () {
    'use strict';

    angular
        .module('elogbooks.user.reports')
        .controller('UserReportsSiteAssociateExtensionRequestsJobsController', UserReportsSiteAssociateExtensionRequestsJobsController);

    UserReportsSiteAssociateExtensionRequestsJobsController.$inject = ['$state', '$stateParams', 'jobCollection', 'siteAssociateResponse'];
    function UserReportsSiteAssociateExtensionRequestsJobsController($state, $stateParams, jobCollection, siteAssociateResponse) {
        var vm = this;
        vm.jobsCollection = jobCollection;
        vm.jobs = jobCollection.jobs;
        vm.exportParams = {exportView: 'details'};
        vm.extensionRequestStatus = $stateParams.status;
    }
})();