(function(){
    'use strict';

    angular
        .module('elogbooks.helpdesk.sites.details')
        .config(registerRoutes);

    function registerRoutes($stateProvider) {

        // Now set up the states
        $stateProvider
            .state('dashboard.helpdesk.sites.details', {
                url: "{resource}",
                resolve: {
                    siteResponse: function (apiClient, $stateParams) {
                        return apiClient.get($stateParams.resource.decode());
                    },
                    reactiveJobsResponse: function (apiClient, siteResponse) {
                        return apiClient.get(siteResponse.getLink('jobs') + '?type=reactive&order=-updated&limit=10');
                    }
                },
                views: {
                    '@dashboard': {
                        'template': ''
                    },
                    'details@dashboard': {
                        templateUrl: '/modules/helpdesk/sites/details/details.html',
                        controller: 'HelpdeskSiteDetailsController'
                    }
                },
                ncyBreadcrumb: {
                    label: '{{ ::"DETAILS" | translate }}'
                }
            });
    }

})();
