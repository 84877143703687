(function() {
    'use strict';

    angular
        .module('elogbooks.user.sites')
        .controller('SurveyScheduleDetailsController', SurveyScheduleDetailsController);

    function SurveyScheduleDetailsController($state, messenger, lodash, apiClient, base64, scheduleResponse) {
        var vm = this;
        vm.data = scheduleResponse.getData();
        vm.response = scheduleResponse;
    }
})();
