(function() {
    'use strict';

    angular
        .module('elogbooks')
        .config(Translations);

    function Translations($translateProvider) {
        $translateProvider.translations('en-gb', {
            ACTION_FILE_SENT_ACTION: '<strong>{{ event._links["user"].title }}</strong> sent a file to Riskwise',
            ACTION_FILE_SENT_TO_OSP_ACTION: '<strong>{{ event._links["user"].title }}</strong> sent a file to Occupier Portal',
            ACTION_FILE_DELETED_ACTION: '<strong>{{ event._links["user"].title }}</strong> deleted an Action file',
            ACTION_CREATED_ACTION: '<strong>{{ event._links["user"].title }}</strong> created the Action',
            ACTION_COMPLETED_ACTION: '<strong>{{ event._links["user"].title }}</strong> completed the Action',
            ACTION_CANCELLED_ACTION: '<strong>{{ event._links["user"].title }}</strong> cancelled the Action',
            ACTION_REOPENED_ACTION: '<strong>{{ event._links["user"].title }}</strong> reopened the Action',
            ACTION_REASSIGNED_ACTION: '<strong>{{ event._links["user"].title }}</strong> reassigned the Action to <strong>{{ event._links["assignee"].title }}</strong>',
            ACTION_JOB_PAPERWORK_REJECTED_ACTION: '<strong>{{ event._links["user"].title }}</strong> rejected job paperwork',
            ACTION_JOB_COMPLETED_ACTION: '<strong>{{ ::event._links["user"].title }}</strong> completed the job.',
            ACTION_JOB_CANCELLED_ACTION: '<strong>{{ ::event._links["user"].title }}</strong> cancelled the job.',
            ACTION_QUOTE_REQUEST_ACCEPTED_ACTION: 'Quote accepted by <strong>{{event._links.user.title}}</strong>',
            ACTION_QUOTE_REQUEST_CANCELLED_ACTION: 'Quote cancelled by <strong>{{event._links.user.title}}</strong>',

            ACTION_MESSAGE_ACTION: '<strong>{{ message._links["sender"].title }}</strong> provided an update',
            ACTION_FILE_ADDED: '<strong>{{ file._links["author"].title }}</strong> added a file',

            ACTION_JOB_CREATED_ACTION: '<strong>{{ event._links["user"].title }}</strong> created a Job',
            ACTION_QUOTE_REQUEST_CREATED_ACTION: '<strong>{{ event._links["user"].title }}</strong> created a Quote Request',

            ACTION_CANCELLED_SYSTEM_ACTION: 'The system automatically cancelled the Action.',
            ACTION_CLOSE_FAILED_SYSTEM_ACTION: 'Action closing failed',
        });
    }
})();
