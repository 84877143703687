(function () {
    'use strict';

    angular
        .module('elogbooks.user.finance')
        .config(registerDashboardRoutes);

    function registerDashboardRoutes($stateProvider) {
        $stateProvider
            .state('dashboard.user.finance.dashboard', {
                url: '/dashboard',
                parent: 'dashboard.user.finance',
                templateUrl: '/modules/user/finance/dashboard/dashboard.html',
                controller: 'FinanceDashboardController',
                controllerAs: 'vm',
                ncyBreadcrumb: {
                    skip: true
                }
            });
    }
})();
