(function () {
    'use strict';

    angular
        .module('elogbooks.user.reports')
        .controller('UserReportsSiteAssociateSitesController', UserReportsSiteAssociateSitesController);

    UserReportsSiteAssociateSitesController.$inject = ['sitesCollection', 'siteAssociateResponse'];

    function UserReportsSiteAssociateSitesController(sitesCollection, siteAssociateResponse) {
        var vm = this;
        vm.sitesCollection = sitesCollection;
        vm.sites = sitesCollection.sites;
        vm.associate = siteAssociateResponse;

        vm.getAddress = getAddress;

        function getAddress(site) {
            if (site.contactInformation.addresses[0]) {
                if (site.contactInformation.addresses[0].lines[0] && site.contactInformation.addresses[0].postcode) {
                    return site.contactInformation.addresses[0].lines[0] + " , " + site.contactInformation.addresses[0].postcode;
                }
                if (site.contactInformation.addresses[0].lines[0] && !site.contactInformation.addresses[0].postcode) {
                    return site.contactInformation.addresses[0].lines[0];
                }
                if (!site.contactInformation.addresses[0].lines[0] && site.contactInformation.addresses[0].postcode) {
                    return site.contactInformation.addresses[0].postcode;
                }
            }
            return null;
        }
    }
})();
