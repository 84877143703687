(function () {
    'use strict';

    angular
        .module('elogbooks.common.files')
        .controller('CommonDocumentComplianceController', CommonDocumentComplianceController);

    CommonDocumentComplianceController.$inject = [
        '$stateParams',
        '$state',
        'documentComplianceCollectionResponse',
        '$translate',
        'elbDownloadService',
        'rootResourceResponse',
        'user',
        'statutoryTypeCollectionResponse',
        'paramConverterService',
        'moment',
        'recurrenceRulesService',
        'analyticsService',
        '$scope'
    ];

    function CommonDocumentComplianceController (
        $stateParams,
        $state,
        documentComplianceCollectionResponse,
        $translate,
        elbDownloadService,
        rootResourceResponse,
        user,
        statutoryTypeCollectionResponse,
        paramConverterService,
        moment,
        recurrenceRulesService,
        analyticsService,
        $scope
    ) {
        var vm = this;
        vm.documentCollectionResponse = documentComplianceCollectionResponse;
        vm.entityCount = documentComplianceCollectionResponse.count;
        vm.download = elbDownloadService.download;
        vm.moment = moment;
        vm.recurrenceRulesService = recurrenceRulesService;
        vm.stateParams = $stateParams;

        var deregisterWatch = $scope.$watch('vm.documentCollectionResponse', function(value) {
            analyticsService.pageLoaded();
            deregisterWatch();
        });

        vm.search = searchAction;
        vm.order = orderAction;

        vm.resources = {
            'rootResource': rootResourceResponse,
            'stateParams': $stateParams,
            'root':rootResourceResponse,
            'user': user
        };

        vm.tabTitle = $translate.instant('DOCUMENT_COMPLIANCE');

        var yesNoOptions = [
            {
                title: $translate.instant('NONE_SELECTED'),
                value: null
            },
            {
                title: $translate.instant('YES'),
                value: 'true'
            },
            {
                title: $translate.instant('NO'),
                value: 'false'
            }
        ];

        vm.criteria = {
            name: { type: 'name', value: $stateParams.name, title: 'NAME'},
            isExpired: { type: 'options', title: 'EXPIRED', value: $stateParams.isExpired, options: yesNoOptions }
        };

        vm.dateNow = moment().set({hour:0,minute:0,second:0,millisecond:0});

        if (statutoryTypeCollectionResponse) {
            vm.criteria.documentStatutoryType = {
                type: 'options',
                title: 'STATUTORY_TYPE',
                multiple: true,
                value: paramConverterService.checkStateParams($stateParams.documentStatutoryType), options: getStatTypes()
            };
        }

        function searchAction (params) {
            var override = {
                documentPage: 1
            };

            $state.go('.', angular.extend({}, params, override), { reload: $state.current });
        }

        function orderAction(key) {
            $stateParams.documentOrder = key;

            $state.go('.', angular.extend({}, $stateParams), { reload: $state.current });
        }

        function getStatTypes() {
            return paramConverterService.formatResponse(statutoryTypeCollectionResponse.statutorytypes);
        }
    }
})();
