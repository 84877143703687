(function(){
    'use strict';

    angular
        .module('elogbooks.user.buddies')
        .controller('CommonBuddyHistoryListController', ['$state', '$stateParams', 'buddiesCollectionResource', 'config', CommonBuddyHistoryListController]);

    function CommonBuddyHistoryListController($state, $stateParams, buddiesCollectionResource, config) {
        var vm = this;
        vm.buddies = buddiesCollectionResource ? buddiesCollectionResource.buddies : [];
        vm.buddiesCollection = buddiesCollectionResource;
        vm.title = config.type;
        vm.addBuddy = '.add';
        vm.addHide = true;
        vm.from = 'history';
        vm.typePath = config.type.toLowerCase().replace("_", "-");

        if (config.type === 'MY_BUDDY') {
            vm.filterType = 'BUDDY';
        } else {
            vm.filterType = 'ASSIGNED_BY';
        }

        vm.criteria = {
            buddy: { type: 'text', title: vm.filterType, value: $stateParams.buddy },
            dateRange: {
                type: 'date-range',
                title: 'BUDDY_BETWEEN',
                value: {
                    startDate: ($stateParams.dateRange !== null && typeof $stateParams.dateRange === 'string') ? moment(new Date($stateParams.dateRange.split(',')[0])) : null,
                    endDate: ($stateParams.dateRange !== null && typeof $stateParams.dateRange === 'string') ? moment(new Date($stateParams.dateRange.split(',')[1])) : null,
                    endMaxDate: null
                },
                clearValue: {
                    startDate: null,
                    endDate: null
                }
            },
        };

        vm.search = search;
        vm.order = order;

        /**
         * Search buddies
         */
        function search(params) {
            var override = {
                buddyPage: 1
            };

            $state.go('.', angular.extend({}, params, override), { reload: $state.current.parent });
        }

        /**
         * Order buddies
         */
        function order(key) {
            $stateParams.order = key;

            $state.go('.', angular.extend({}, $stateParams), { reload: $state.current.parent });
        }
    }
})();
