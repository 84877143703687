app.config(function ($translateProvider) {
    $translateProvider.translations('en-gb', {
        PRIORITY: 'Priority',
        PRIORITIES: 'Priorities',
        SELECT_PRIORITY: 'Select Priority',
        ADD_PRIORITY: 'Add a new Priority',

        PRIORITY_LIST: 'Priorities',
        PRIORITY_EDIT: 'Edit Priority',
        PRIORITY_ADD: 'Add a new Priority',

        PRIORITY_CREATED: 'Priority created successfully',
        PRIORITY_UPDATED: 'Priority updated successfully',
        PRIORITY_DELETED: 'Priority deleted',

        DEADLINE: 'Deadline',
        ATTEND_TIME: 'Attend within',

        ATTEND_DEADLINE: 'Attend deadline',
        NON_WORKING_DAYS: 'Not working days',
        INCLUDE_NON_WORKING_DAYS: 'Include not working days',
        COMPLETE_TIME: 'Complete at',
        COMPLETE_DEADLINE: 'Complete Deadline',
        APPROVAL_DELAY: 'Approval Delay',
        ACKNOWLEDGE_DELAY: 'Acknowledge Delay',
        ALARM_WHEN_OVERDUE: 'Alarm When Overdue',
        ALERT_MESSAGE_ENABLED: 'Alert Message Enabled',
        ALERT_MESSAGE: 'Alert Message',
        ADD_NEW_PRIORITY: 'Add new priority',
        INTERVAL: 'Interval',
        ATTENDANCE_DUE: 'Attendance Due',
        COMPLETION_DUE: 'Completion Due',

        PRIORITY_MESSAGE: 'Priority Message',

        HELPTEXT_DELAY: 'Values of 0 will use system settings',

        ENTITY_TYPE: 'Type',
        ENTITY_TYPE_ALL: 'All',
        ENTITY_TYPE_JOB: 'Jobs',
        ENTITY_TYPE_QUOTE: 'Quotes',
        ENTITY_TYPE_ACTION: 'Actions',
        INACTIVE_PRIORITY: 'Inactive Priority'
    });
});
