(function () {
    'use strict';

    angular
        .module('elogbooks.user.finance.purchase-orders')
        .controller('FinancePurchaseOrdersDetailsInfoController', FinancePurchaseOrdersDetailsInfoController);

    FinancePurchaseOrdersDetailsInfoController.$inject = ['purchaseOrderResponse'];

    function FinancePurchaseOrdersDetailsInfoController (purchaseOrderResponse) {
        var vm = this;

        vm.purchaseOrderResponse = purchaseOrderResponse;
        vm.supplier = vm.purchaseOrderResponse.supplier;
    }
})();
