app.config(function ($translateProvider) {
    "use strict";

    $translateProvider.translations("en-gb", {
        USERNAME: "Username",
        PASSWORD: "Password",
        SHOW_PASSWORD: 'Show password',
        HIDE_PASSWORD: "Hide password",
        SIGN_IN: "Sign in",
        PLEASE_LOGIN: "Please login",
        WELCOME_TO_ELOGBOOKS: "Welcome to Elogs CAFM",
        INVALID_CREDENTIALS: "Invalid credentials.",
        FORGOT_YOUR_PASSWORD: "Forgot your password?",
        PASSWORD_REQUEST_SUBMITTED: "If a matching user exists in our system, you will receive an email shortly with instructions for resetting your password.",
        FORGOT_USERNAME_REQUEST_SUBMITTED: "If a matching user exists in our system, you will receive an email shortly containing your username.",
        PASSWORD_CHANGE_REQUIRED: 'Password Change Frequency settings require you to set a new password.',
        CHOOSE_YOUR_NEW_PASSWORD: "Choose your new password.",
        PASSWORDS_DO_NOT_MATCH: "Passwords provided do not match.",
        YOUR_PASSWORD_HAS_BEEN_CHANGED: "Your password has been changed.",
        BACK_TO_LOGIN_PAGE: "Go back to the login page",
        SET_MY_NEW_PASSWORD: "Set New Password",
        NEW_PASSWORD: "New Password",
        CONFIRM_NEW_PASSWORD: "Confirm Password",
        USER_AUTH_TOO_MANY_ATTEMPTS: "Account Locked.",
        USER_AUTH_BAD_CREDENTIALS: "Invalid Credentials.",
        USER_AUTH_FAIL: "There was a problem authenticating your account. Please try again.",
        USER_AUTH_RECAPTCHA_REQUIRED: "The captcha is required.",
        USER_AUTH_RECAPTCHA_INVALID: "The captcha is invalid.",
        USER_ACCOUNT_DISABLED: "User account is Inactive.<br>Contact the Elogs Administration team.",
        USER_IN_TRAINING: "You must complete your Elogs CAFM training before you are able to login. Please use the button below to access the training portal.",
        LINK_EXPIRED: "Link has expired, please use Forgotten Password to reset your password",
        IS_HOME_TO: 'is home to',
        AND: 'and'

    });
});
