(function() {
    'use strict';

    angular
        .module('elogbooks')
        .config(Translations);

    function Translations($translateProvider) {
        $translateProvider.translations('nl', {
            REQUIRE_ASSET_WHEN_LOGGING_REACTIVE_JOB: 'Asset benodigd voor correctief onderhoud'
        });
    }
})();

app.config(function ($translateProvider) {
    $translateProvider.translations('nl', {
        SERVICE_PROVIDER: 'Leverancier',
        SERVICE_PROVIDERS: 'Leveranciers',


        ADD_NEW_SERVICE_PROVIDER: 'Voeg nieuwe leverancier toe',

        SERVICE_PROVIDER_LIST: 'Leveranciers',
        SERVICE_PROVIDER_ADD: 'Voeg nieuwe leverancier toe',
        SERVICE_PROVIDER_EDIT: 'Wijzig leverancier',

        SERVICE_PROVIDER_CREATED: 'Leverancier aangemaakt',
        SERVICE_PROVIDER_UPDATED: 'Leverancier gewijzigd',
        SERVICE_PROVIDER_DELETED: 'Leverancier verwijderd',

        SERVICE_PROVIDERS_SELECT: 'Selecteer leverancier',
        SERVICE_PROVIDERS_ADD: 'Leveranciers toevoegen',
        SERVICE_PROVIDERS_UPDATE: 'Leveranciers aanpassen',
        SERVICE_PROVIDERS_UPDATED: 'Leveranciers gewijzigd',

        SERVICE_PROVIDER_PERMISSIONS_UPDATED: 'Authorisatie leverancier aangepast',
        SERVICE_PROVIDERS_IN_ESCALATION: 'Leveranciers in escalatie',

        PRIMARY_OPERATIVES: 'Eerste Contact',
        PRIMARY_OPERATIVE_TYPE: 'Eerste contact bij',

        PRIMARY_OPERATIVE_TYPES_EDIT: 'Wijzig contacten',
        PRIMARY_OPERATIVES_UPDATED: 'Contacten aangepast',

        PRIMARY_JOB_OPERATIVE: 'Opdracht',
        PRIMARY_ESCALATION_OPERATIVE: 'Escalatie',
        PRIMARY_QUOTE_OPERATIVE: 'Offerte',
        PRIMARY_PATROL_OPERATIVE: 'Ronde',
        PRIMARY_SURVEY_OPERATIVE: 'Tevredenheidsonderzoek',
        PRIMARY_AUDIT_OPERATIVE: 'Audit',
        ACTIVE_AT: 'Actief sinds',
        INACTIVE_AT: 'Niet meer actief sinds',
        TRAINED_AT: 'Training gehad op',
        OPERATIVE_CREATED: 'Contact aangemaakt',
        PERMISSIONS_UPDATED: 'Authorisatie aangepast',
        OPERATIVE_REMOVED: 'Contact verwijderd',
        SERVICE_PROVIDER_PARTNERSHIP_ADDED: 'Objecten',

        SERVICE_PROVIDER_STATUS: 'Leverancier Status',
    });
});
