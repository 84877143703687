(function () {
    'use strict';

    angular
        .module('elogbooks.admin.site-note-types')
        .config(registerRoutes)
        .config(registerRoutesList)
        .config(registerRoutesAdd)
        .config(registerRoutesDetails)
        .config(registerRoutesEdit);

    function registerRoutes ($stateProvider) {
        $stateProvider
            .state('dashboard.admin.site-note-types', {
                abstract: true,
                templateUrl: 'shared-templates/view.html',
                ncyBreadcrumb: {
                    skip: true
                },
                data: {
                    permissions: ['user_permission_view_site_note_types']
                }
            });
    }

    function registerRoutesList ($stateProvider) {
        $stateProvider
            .state('dashboard.admin.site-note-types.list', {
                url: '/site-note-types?page&limit&order&id&name&active',
                parent: 'dashboard.admin.site-note-types',
                data: {
                    permissions: ['user_permission_view_site_note_types']
                },
                views: {
                    '@dashboard.admin.site-note-types': {
                        templateUrl: '/modules/admin/site-note-types/site-note-type-list.html',
                        controller: 'SiteNoteTypesController',
                        controllerAs: 'vm'
                    }
                },
                params: {
                    page: '1',
                    limit: '30',
                    order: 'displayOrder',
                    id: null,
                    name: null,
                    active: { value: null }
                },
                resolve: {
                    siteNoteTypeCollectionResponse: function ($stateParams, apiClient, rootResourceResponse) {
                        return apiClient.get(rootResourceResponse.getLink('sitenotetypes'), $stateParams).then(function (response) {
                            return response || apiClient.noResourceFound();
                        });
                    }
                },
                ncyBreadcrumb: {
                    label: '{{ ::"SITE_NOTE_TYPES" | translate }}'
                }
            });
    }

    function registerRoutesAdd ($stateProvider) {
        $stateProvider
            .state('dashboard.admin.site-note-types.list.add', {
                url: '/add',
                parent: 'dashboard.admin.site-note-types.list',
                data: {
                    permissions: ['user_permission_manage_site_note_types']
                },
                views: {
                    '@dashboard.admin.site-note-types': {
                        templateUrl: '/modules/admin/site-note-types/add-edit/site-note-type-form.html',
                        controller: 'SiteNoteTypesAddEditController',
                        controllerAs: 'vm'
                    }
                },
                resolve: {
                    siteNoteTypeResponse: function () {
                        return {};
                    },
                    siteNoteTypeCollectionResponse: function ($stateParams, apiClient, rootResourceResponse) {
                        return apiClient.get(rootResourceResponse.getLink('sitenotetypes'), $stateParams).then(function (response) {
                            return response || apiClient.noResourceFound();
                        });
                    }
                },
                ncyBreadcrumb: {
                    label: '{{ ::"ADD" | translate }}'
                }
            });
    }

    function registerRoutesDetails ($stateProvider) {
        $stateProvider
            .state('dashboard.admin.site-note-types.list.details', {
                url: '/details/{resource}',
                abstract: true,
                parent: 'dashboard.admin.site-note-types.list',
                data: {
                    permissions: ['user_permission_view_site_note_types']
                },
                views: {
                    '@dashboard.admin.site-note-types': {
                        templateUrl: '/modules/admin/site-note-types/details/site-note-type-details.html',
                        controller: 'SiteNoteTypeDetailsController',
                        controllerAs: 'vm'
                    }
                },
                resolve: {
                    siteNoteTypeResponse: function (apiClient, $stateParams) {
                        return apiClient.get($stateParams.resource.decode()).then(function (response) {
                            return response || apiClient.noResourceFound();
                        });
                    }
                },
                ncyBreadcrumb: {
                    label: '{{ ::$resolves().siteNoteTypeResponse.name }}'
                }
            })
            .state('dashboard.admin.site-note-types.list.details.info', {
                url: '/info',
                parent: 'dashboard.admin.site-note-types.list.details',
                data: {
                    permissions: ['user_permission_view_site_note_types']
                },
                views: {
                    '@dashboard.admin.site-note-types.list.details': {
                        templateUrl: '/modules/admin/site-note-types/details/info/site-note-type-info.html',
                        controller: 'SiteNoteTypeInfoController',
                        controllerAs: 'vm'
                    }
                },
                ncyBreadcrumb: {
                    label: '{{ ::"INFO" | translate }}'
                }
            });
    }

    function registerRoutesEdit ($stateProvider) {
        $stateProvider
            .state('dashboard.admin.site-note-types.list.details.info.edit', {
                url: '/edit',
                parent: 'dashboard.admin.site-note-types.list.details.info',
                data: {
                    permissions: ['user_permission_manage_site_note_types']
                },
                views: {
                    '@dashboard.admin.site-note-types.list.details': {
                        templateUrl: '/modules/admin/site-note-types/add-edit/site-note-type-form.html',
                        controller: 'SiteNoteTypesAddEditController',
                        controllerAs: 'vm'
                    }
                },
                ncyBreadcrumb: {
                    label: '{{ ::"EDIT" | translate }}'
                }
            });
    }

})();
