(function () {
    'use strict';

    angular
        .module('elogbooks.admin.asset-score-types')
        .controller('AssetScoreTypeOptionsAddEditController', AssetScoreTypeOptionsAddEditController);

    AssetScoreTypeOptionsAddEditController.$inject = ['assetScoreTypeOptionsCollectionResponse', 'assetScoreTypeOptionResponse', 'crudService', 'requestDataFactory'];

    function AssetScoreTypeOptionsAddEditController (assetScoreTypeOptionsCollectionResponse, assetScoreTypeOptionResponse, crudService, requestDataFactory) {
        var vm = this;
        vm.assetScoreTypeOption = assetScoreTypeOptionResponse;
        vm.assetScoreTypeOptionsCollectionResponse = assetScoreTypeOptionsCollectionResponse;
        vm.data = requestDataFactory.createRequest(vm.assetScoreTypeOption);
        vm.data.colour = '#' + (vm.data.colour ? vm.data.colour : '000000');
        vm.create = createAction;
        vm.update = updateAction;
        vm.delete = deleteAction;

        function createAction () {
            var data = angular.copy(vm.data);
            data.colour = data.colour.substr(1);

            return crudService.create(
                vm.assetScoreTypeOptionsCollectionResponse.getLink('create'),
                data,
                'dashboard.admin.asset-score-types.list.details.asset-score-type-options',
                null,
                'ASSET_SCORE_TYPE_OPTION'
            );
        }

        function updateAction () {
            var data = angular.copy(vm.data);
            data.colour = data.colour.substr(1);

            return crudService.update(
                vm.assetScoreTypeOption.getLink('edit'),
                data,
                'dashboard.admin.asset-score-types.list.details.asset-score-type-options',
                'dashboard.admin.asset-score-types.list.details.asset-score-type-options',
                'ASSET_SCORE_TYPE_OPTION'
            );
        }

        function deleteAction () {
            return crudService.delete(
                vm.assetScoreTypeOption.getLink('delete'),
                'dashboard.admin.asset-score-types.list.details.asset-score-type-options',
                'ASSET_SCORE_TYPE_OPTION'
            );
        }
    }
})();
