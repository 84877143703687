(function() {
    'use strict';

    angular
        .module('elogbooks')
        .config(Translations);

    function Translations($translateProvider) {
        $translateProvider.translations('nl', {
            REPORT_PERMISSIONS: 'Rapport Authorisaties',
            REPORT_PERMISSIONS_UPDATED: 'Rapport Authorisaties aangepast',

            USER_PERMISSION_REPORT_SP_SUMMARY: 'Leveranciers samenvatting',
            USER_PERMISSION_REPORT_SP_SUMMARY_DETAIL: 'Mogelijkheid om rapport te draaien',
            USER_PERMISSION_REPORT_SITE_ASSOCIATE_ACTIVITY:	'Site partners activiteiten',
            USER_PERMISSION_REPORT_SITE_ASSOCIATE_ACTIVITY_DETAIL: 'Mogelijkheid om rapport te draaien',
            USER_PERMISSION_REPORT_APPROVAL_AGE: 'Leeftijd van goedkeuringen',
            USER_PERMISSION_REPORT_APPROVAL_AGE_DETAIL: 'Mogelijkheid om rapport te draaien',
            USER_PERMISSION_REPORT_SUMMARY: 'Samenvatting',
            USER_PERMISSION_REPORT_SUMMARY_DETAIL: 'Mogelijkheid om rapport te draaien'
        });
    }
})();
