(function () {
    'use strict';

    angular
        .module('elogbooksControllers')
        .controller('ConfirmationDateModalController', ConfirmationDateModalController);

    ConfirmationDateModalController.$inject = [
        'config',
        '$uibModalInstance',
        '$rootScope'
    ];

    function ConfirmationDateModalController (
        config,
        $uibModalInstance,
        $rootScope
    ) {
        var vm = this;
            vm.config = config;

        vm.datePickerOptions = {
            minDate: config.minDate ? config.minDate : null,
            maxDate: config.maxDate ? config.maxDate : null
        };

        if(!vm.config.forceDirtyConfirm) {
            $rootScope.skipDirtyCheck = true;
        }

        vm.close = function () {
            vm.config.date = null
            $rootScope.skipDirtyCheck = false;
            $uibModalInstance.close({result: false});
        };

        vm.submit = function () {
            if (vm.config.submit) {
                var response = vm.config.submit(config);
                if (response) {
                    $uibModalInstance.dismiss();
                } else {
                    $uibModalInstance.close({result: false});
                }
            }
        };
    };
})();
