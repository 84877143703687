(function(){
    'use strict';

    angular
        .module('elogbooks.user.preferences')
        .controller('UserPreferencesGeneralController', UserPreferencesGeneralController);

    function UserPreferencesGeneralController(apiClient, confirmationModal, messenger, rootResourceResponse, userManager, userResponse, userPreferencesCollection, ssoResponse, ssoService) {
        var vm = this;
            vm.user = userResponse;
            vm.userPreferences = userPreferencesCollection.userPreferences;
            vm.userPreferencesCollection = userPreferencesCollection;
            vm.getPreference = userManager.getPreference;
            vm.resetPasswordRequest = resetPasswordRequest;
            vm.isSsoUser = ssoResponse.isSsoUser;
            vm.linkSsoAccount = linkSsoAccount;
            vm.linkedToSsoAccount = ssoResponse.isUserLinked;

        function linkSsoAccount() {
            if (!ssoResponse.isUserLinked) {
                ssoService.authorise();
            }
        }

        function resetPasswordRequest() {
            return confirmationModal.open({primaryClass: 'btn-success'}).result.then(
                function () {
                    apiClient.create(rootResourceResponse.getLink('passwordchangerequests'), {
                        username: vm.user.username
                    }).then(function (response) {
                        if (response) {
                            messenger.success('PASSWORD_RESET_REQUEST_SUCCESSFUL');
                        } else {
                            messenger.success('REQUEST_ERROR');
                        }
                    });
                }
            );
        }
    }
})();
