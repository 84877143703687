(function() {
    'use strict';

    angular
        .module('elogbooks.admin.core-clients')
        .controller('CoreClientsMarkupAddEditController', CoreClientsMarkupAddEditController);

    CoreClientsMarkupAddEditController.$inject = [
        '$state',
        'apiClient',
        'confirmationModal',
        'messenger',
        'coreClientMarkupResponse',
        'coreClientMarkupCollectionResponse',
        'requestDataFactory'
    ];

    function CoreClientsMarkupAddEditController(
        $state,
        apiClient,
        confirmationModal,
        messenger,
        coreClientMarkupResponse,
        coreClientMarkupCollectionResponse,
        requestDataFactory
    ) {
        var vm = this;
        vm.markup = coreClientMarkupResponse;
        vm.coreClientMarkupCollectionResponse = coreClientMarkupCollectionResponse;
        vm.data = requestDataFactory.createRequest(vm.markup);
        vm.data.activeAt = vm.data.activeAt ? new Date(vm.data.activeAt) : null;
        // if active at but no inactive at (active markup) or inactive at is in the future - so we don't allow updating active at
        if (
            vm.data.activeAt && !vm.data.inactiveAt && vm.data.activeAt <= new Date() ||
            (vm.data.activeAt && vm.data.inactiveAt) && new Date(vm.data.inactiveAt) > new Date()
        ) {
            delete vm.data.activeAt;
        } 
        vm.data.shouldShowDate = !vm.data.id || vm.data.activeAt >= new Date();

        vm.create = createAction;
        vm.update = updateAction;
        vm.delete = deleteAction;

        function updateAction() {
            return apiClient.update(vm.markup.getLink('edit'), vm.data).then(function (response) {
                if (response) {
                    $state.go('^', {}, { reload: 'dashboard.admin.core-clients.list' }).then(function () {
                        messenger.success('CORE_CLIENT_MARKUP_UPDATED');
                    });
                } else {
                    messenger.error('REQUEST_ERROR');
                }
            });
        }

        function createAction() {
            return apiClient.create(vm.coreClientMarkupCollectionResponse.getLink('create'), vm.data).then(function (response) {
                if (response) {
                    $state.go('dashboard.admin.core-clients.list.details.markup.details.info', { coreClientMarkupresource: response.getLink('self').encode() }, { reload: $state.current.parent }).then(function () {
                        messenger.success('CORE_CLIENT_MARKUP_CREATED');
                    });
                } else {
                    messenger.error('REQUEST_ERROR');
                }
            });
        }

        function deleteAction() {
            return confirmationModal.open().result.then(function(response) {
                if (response.result) {
                     apiClient.delete(vm.data.getLink('delete')).then(function(isSuccess) {
                        if (isSuccess) {
                            $state.go('dashboard.admin.core-clients.list.details.markup', {}, { reload: 'dashboard.admin.core-clients.list.details.markup' }).then(function (){
                                messenger.success('CORE_CLIENT_MARKUP_DELETED');
                            });
                        } else {
                            messenger.error('REQUEST_ERROR');
                        }
                    });
                }
            });
        }
    }
})();
