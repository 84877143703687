/**
 * Panel form row
 */
angular.module('elogbooksDirectives').directive('panelFormRow', ['$http', 'apiClient', '$state', 'lodash', function ($http, apiClient, $state, lodash) {
    return {
        restrict: 'AE',
        replace: true,
        scope: {
            property: '@',
            type: '@',
            required: '@',
            disabled: '@',
            options: '=',
            model: '=',
            collection: '=',
            rows: '@',
            label: '@',
            subLabel: '@',
            labelProps: '<',
            placeholder: '@',
            dateFormat: '@',
            pattern: '=?',
            ngRequired: '=?',
            ngDisabled: '=?',
            ngModel: '=?',
            min: '=',
            max: '=',
            step: '=',
            multiple: '=',
            searchCtrl: '@',
            searchUrl: '@',
            maxLength: '@',
            displayIsMandatoryCheckbox: '@',
            locationParentAdd:'=?',
            highlightedItems: '@?',
            rowDepth: '@',
            noneSelected: '=',
            tooltip: '@',
            tooltipPlacement: '@'
        },
        templateUrl:
            function($element, $attrs) {
                var templateElement;
                switch ($attrs.type) {
                    case 'checkbox':
                    case 'textarea':
                    case 'select':
                    case 'select-widget':
                    case 'select-widget-multiple':
                    case 'select-site-multiple-widget':
                    case 'site':
                    case 'location':
                    case 'file-upload':
                    case 'multiple-file-upload':
                    case 'date':
                    case 'date-alt':
                    case 'datetime':
                    case 'site-priority':
                    case 'recurrence-rules':
                    case 'frequency':
                    case 'priority-deadline':
                    case 'status-active':
                    case 'currency':
                    case 'number':
                    case 'yesno':
                    case 'range':
                    case 'colour':
                    case 'address':
                    case 'multiple-radio':
                        templateElement = $attrs.type;
                        break;
                    default:
                        templateElement = 'input';
                        break;
                }
                return '/modules/directives/layout/panel-form-row/elements/' + templateElement + '.html';
            },
        link: function($scope, $element, $attrs) {
            // It is possible that the panel and entityForm are on different scopes.
            // A soft limit of 3 parent levels has been set based on old code's
            // .$parent.$parent.$parent

            // TODO: seems a redundant code . need to be removed ?
            var idx = 0, panelScope = $scope;
            while (idx < 3 && ! lodash.has(panelScope, 'panel')) {
                panelScope = panelScope.$parent;
                idx++;
            }

            var idx = 0, entityFormScope = $scope;
            var depth = $attrs.rowDepth || 3
            while (idx < depth && ! lodash.has(entityFormScope, 'entityForm')) {
                entityFormScope = entityFormScope.$parent;
                idx++;
            }

            var panelScope = $scope.$parent.$parent;
            if (!lodash.has(panelScope, 'panel')) {
                // Panel can also be another level back dependent on view type
                panelScope = panelScope.$parent;
            }

            if (entityFormScope.entityForm) {
                $scope.form = entityFormScope.entityForm;
            }

            if (panelScope.panel) {
                $scope.entity = panelScope.panel.entity;
                $scope.responseEntity = panelScope.panel.responseEntity;
                $scope.panel = panelScope.panel;
                if ($attrs.type === 'multiple-file-upload') {
                    $scope.entity.files = $scope.entity.files || [];
                }

            }

            $scope.uploading = false;

            if ($scope.property == null) {
                $attrs.property = lodash.snakeCase($scope.type);
            }

            $scope.properties = [];
            if ($scope.property && $scope.property.indexOf(',') !== -1) {
                $scope.properties = $scope.property.split(',');
            }

            if ($attrs.type == 'frequency') {
                $scope.validation = {
                    'min': 1
                };

                $scope.$watch('entity[properties[0]]', function(newVal) {
                    switch(newVal) {
                        case 'minutes':
                            $scope.validation.max = 90;
                            break;
                        case 'hours':
                            $scope.validation.max = 24;
                            break;
                        case 'days':
                            $scope.validation.max = 30;
                            break;
                        case 'weeks':
                            $scope.validation.max = 52;
                            break;
                        case 'months':
                            $scope.validation.max = 12;
                            break;
                        default:
                            // no max required if not one of the above
                            delete $scope.validation.max;
                            break;
                    }
                })
            }

            if ($attrs.required === 'required') {
                $scope.ngRequired = "true";
            }

            if ($attrs.disabled === 'disabled') {
                $scope.ngDisabled = "true";
            }

            if (typeof $attrs.label === 'undefined') {
                $scope.label = $attrs.property.toUpperCase();
            }

            if (typeof $attrs.placeholder === 'undefined') {
                $scope.placeholder = $scope.label;
            }

            if (typeof $attrs.dateFormat === 'undefined') {
                $scope.dateFormat = 'mediumDate';
            }

            if (['date', 'date-alt'].includes($attrs.type) && ($attrs.minDate || $attrs.maxDate)) {
                $scope.datePickerOptions = {};

                if ($attrs.minDate) {
                    $scope.datePickerOptions.minDate = new Date($attrs.minDate);
                }

                if ($attrs.maxDate) {
                    $scope.datePickerOptions.maxDate = new Date($attrs.maxDate);
                }
            }

            $attrs.$observe('minDate', function(newDate){
                if($scope.datePickerOptions) {
                    $scope.datePickerOptions.minDate = newDate ? new Date(newDate) : null;
                }
            });

            $attrs.$observe('maxDate', function(newDate){
                if($scope.datePickerOptions) {
                    $scope.datePickerOptions.maxDate = newDate ? new Date(newDate) : null;
                }
            });

            $attrs.$observe('required', function(newVal){
                if (newVal === 'required') {
                    $scope.ngRequired = true;
                } else {
                    $scope.ngRequired = false;
                }
            });

            switch ($attrs.type) {
                case 'site-priority':
                    $scope.sitePriorities = [];
                    break;
                case 'priority-deadline':
                    $scope.priorityTypeEntity = $scope.property === 'attend' ? 'attendPriority' : 'completePriority';
            }

            if (panelScope.panel) {
                panelScope.panel.fields.push({type: $attrs.type, scope: $scope});
            }

            if (typeof $scope.locationParentAdd === 'undefined') {
                $scope.locationParentAdd = true;
            }
        }
    };
}]);
