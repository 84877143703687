(function() {
    'use strict';

    angular
        .module('elogbooks.admin.document-types-management')
        .controller('DocumentTypesDetailsController', DocumentTypesDetailsController);

    DocumentTypesDetailsController.$inject = ['documentTypeResponse'];
    function DocumentTypesDetailsController(documentTypeResponse) {
        var vm = this;
        vm.documentType = documentTypeResponse;
    }
})();
