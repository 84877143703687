(function () {
    'use strict';

    angular
        .module('elogbooksServices')
        .service('filterCriteriaService', filterCriteriaService);

    filterCriteriaService.$inject = ['$state'];

    function filterCriteriaService($state) {
        this.selected = null;

        this.setSelectedFilter = function (filter) {
            this.selected = filter;
        };

        this.getSelectedFilter = function () {
            if (this.selected && $state.current.name !== this.selected.route) {
                this.selected = null;
            }

            return this.selected;
        };

        this.setAll = function setAll(obj, val) {
            Object.keys(obj).forEach(function(index) {
                obj[index] = val
            });

            return obj;
        }

        this.checkFilterChanges = function checkFilterChanges(params) {
            var isChanged = false;
            angular.forEach(params, function(value, key) {
                if (!isChanged && key.indexOf('page') !== 0) {
                    switch (typeof value) {
                        case 'object':
                            if (Array.isArray($state.params[key])) {
                                isChanged = notSameArrayValues(value, $state.params[key]);
                                break;
                            }

                            var date = moment($state.params[key]);
                            if (date.isValid()) {
                                isChanged = !moment($state.params[key]).isSame(value);
                                break;
                            }

                            if (typeof $state.params[key] === 'string' && $state.params[key] != value) {
                                isChanged = true;
                            }

                            break;
                        default:
                            if ($state.params[key] != value) {
                                isChanged = true;
                            }
                    }
                }
            });

            return isChanged;
        }

        function notSameArrayValues(_arr1, _arr2) {
            if (
              !Array.isArray(_arr1)
              || !Array.isArray(_arr2)
              || _arr1.length !== _arr2.length
              ) {
                return true;
              }

            // .concat() to not mutate arguments
            var arr1 = _arr1.concat().sort();
            var arr2 = _arr2.concat().sort();

            for (var i = 0; i < arr1.length; i++) {
                if (arr1[i] != arr2[i]) {
                    return true;
                 }
            }

            return false;
        }

        return this;
    }
})();
