(function() {
    'use strict';

    angular
        .module('elogbooks.admin.sites')
        .controller('PartnershipEditController', PartnershipEditController);

    PartnershipEditController.$inject = ['$state', 'apiClient', 'messenger', 'partnershipResponse', 'userManager', 'confirmationModal'];

    function PartnershipEditController($state, apiClient, messenger, partnershipResponse, userManager, confirmationModal) {

        var vm = this;
        vm.data = {};
        vm.partnershipResponse = partnershipResponse;
        vm.isClientAdmin = userManager.hasPermission('user_permission_client_admin_edit_partnerships') && !userManager.hasPermission('user_permission_manage_service_providers');

        if (vm.partnershipResponse) {
            vm.data.site = partnershipResponse._links.site.title;
            vm.data.serviceProvider = partnershipResponse._links.serviceprovider.title;
            vm.data.jobApprovalDisabled = partnershipResponse.jobApprovalDisabled;
            vm.data.active = partnershipResponse.active;
        }

        vm.canUpdateStatus = canUpdateStatus;
        vm.update = updateAction;
        vm.delete = deleteAction;

        function updateAction() {
            return apiClient.update(partnershipResponse.getLink('edit'), vm.data).then(function(response) {
                if (response) {
                    $state.go('^', {}, {reload: $state.get('^.^.^')}).then(function() {
                        messenger.success('PARTNERSHIP_UPDATED');
                    });
                } else {
                    messenger.error('REQUEST_ERROR');
                }
            });
        }

        function deleteAction() {
            return confirmationModal.open().result.then(function() {
                return apiClient.delete(partnershipResponse.getLink('delete')).then(function(response) {
                    if (response) {
                        $state.go('^.^.^', {}, {reload: $state.get('^.^.^')}).then(function() {
                            messenger.success('PARTNERSHIP_DELETED');
                        });
                    } else {
                        messenger.error('REQUEST_ERROR');
                    }
                });
            });
        }

        function canUpdateStatus() {
            if (partnershipResponse.getLink('edit')
                && $state.current.name.indexOf('dashboard.admin') > -1) {
                return true;
            }
            return false;
        }
    }
})();
