app.config(function ($translateProvider) {
    $translateProvider.translations('zh-hk', {
        PUBLIC_HOLIDAYS: '公共假期',
        PUBLIC_HOLIDAYS_LIST: '公共假期',
        PUBLIC_HOLIDAYS_ADD: '新增公共假期',
        PUBLIC_HOLIDAYS_EDIT: '修改公共假期',

        PUBLIC_HOLIDAY: '公共假期',
        PUBLIC_HOLIDAY_ADD: '新增公共假期',
        PUBLIC_HOLIDAY_EDIT: '修改公共假期',
        PUBLIC_HOLIDAY_ADDED: '公共假期',
        PUBLIC_HOLIDAY_UPDATED: '公共假期已更新',
        PUBLIC_HOLIDAY_DELETED: '公共假期已刪除',
    });
});
