;(function() {
    'use strict';

    angular
        .module('elogbooks.admin.forms')
        .controller('FormsController', FormsController);

    FormsController.$inject = ['$state', '$stateParams', 'elbFormService', 'formCollectionResponse'];

    function FormsController($state, $stateParams, elbFormService, formCollectionResponse) {
        var vm = this;
        vm.formCollectionResponse = formCollectionResponse;

        vm.criteria = {
            summary: {type: 'text', title: 'SUMMARY', value: $stateParams.summary, },
            description: {type: 'text', title: 'DESCRIPTION', value: $stateParams.description, },
            type: {type: 'options', title: 'TYPE', value: $stateParams.type, options: elbFormService.getFormTypes(true), },
        };

        vm.search = search;
        vm.order = order;

        function search(params) {
            var override = {
                page: 1
            };

            $state.go('.', angular.extend({}, params, override), {reload: 'dashboard.admin.forms'});
        }

        function order(key) {
            $stateParams.order = key;

            var override = {
                page: 1
            };

            $state.go('.', angular.extend({}, $stateParams, override), {reload: 'dashboard.admin.forms'});
        }
    }
})();
