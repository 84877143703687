app.config(function ($translateProvider) {
    $translateProvider.translations('nl', {
        MASTERASSET: 'Lijst met assets',
        MASTER_TASK: 'Master Task',
        MASTER_TASKS: 'Master Tasks',
        MASTER_TASK_LIST: 'Master Tasks',

        MASTER_TASK_CREATED: 'Master Task Gemaakt',
        MASTER_TASK_UPDATED: 'Master Task Aangepast',
        MASTER_TASK_DELETED: 'Master Task Verwijderd',

        MASTER_TASK_ADD: 'Voeg een nieuwe Master Task toe',
        MASTER_TASK_EDIT: 'Wijzig Master Task',

        MASTER_TASK_IS_MANDATORY: 'Is verplicht?',
        MASTER_TASK_SERVICE_TYPE: 'Service Type',
        MASTER_TASK_ASSET_NAME: 'Asset Name'
    });
});
