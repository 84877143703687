angular.module('elogbooksServices').service('modulesService', function ($http) {

    var self = this;

    this.getAll = function () {
       return $http.get('/data/modules.json');
    };

    this.getEnabled = function (module) {
        if (! module) {
            return self.enabled;
        }

        return self.enabled[module];
    };

    this.isEnabled = function (module) {
        var mod = module + '_enabled';

        if (self.enabled.hasOwnProperty(mod)) {
            return self.enabled[mod];
        }

        return false;
    };

    this.oneOfEnabled = function (modules) {
        var result = false;
        angular.forEach(modules, function(module) {
            var mod = module + '_enabled';
            if (self.enabled.hasOwnProperty(mod) && self.enabled[mod] === true) {
                result = true;
            }
        });

        return result;
    };

    this.thirdPartyIntegrations = function() {
        var integrations = ['riskwise', 'meridian', 'alcumus', 'qcompliance', 'propertyplus', 'prism'];

        return integrations.map(function(integration) {
            if (!self.enabled.hasOwnProperty(integration + '_enabled')) {
                return null;
            }

            return integration;
        }).filter(Object);
    }

    this.enabledModules = function(modules) {
        return modules.filter(self.isEnabled);
    }

    this.checkIntegrationsEnable = function() {
        return this.oneOfEnabled(
            [
                'riskwise',
                'meridian',
                'alcumus',
                'qcompliance',
                'propertyplus',
                'prism'
            ]);
    }

    return this;
});
