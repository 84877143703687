(function () {
    'use strict';

    angular
        .module('elogbooksServices')
        .service('p2pService', P2PService);

    P2PService.$inject = ['apiClient'];

    function P2PService(apiClient) {
        this.updateCISdata = updateCISdata;
        this.updateTaxCodeData = updateTaxCodeData;
        this.updateTransactionTypeData = updateTransactionTypeData;
        this.updateScheduleNumberData = updateScheduleNumberData;
        this.updateExpenseCodeData = updateExpenseCodeData;
        this.updateTenantCodeData = updateTenantCodeData;
        this.updateSupplierData = updateSupplierData;
        this.updateSelectedScheduleNumberModel = updateSelectedScheduleNumberModel;
        this.updateSelectedExpenseCodeModel = updateSelectedExpenseCodeModel;
        this.updateSelectedTenantCodeModel = updateSelectedTenantCodeModel;
        this.updateSelectedSupplierModel = updateSelectedSupplierModel;

        function updateSelectedSupplierModel(selectedP2PSupplierModel, supplierSearchModel, quoteResponse) {
            selectedP2PSupplierModel.loading = true;
            selectedP2PSupplierModel.disabled = true;

            apiClient.get(quoteResponse.getLink('p2p-suppliers'), {
                supplier: supplierSearchModel
            }).then(
                function (response) {
                    selectedP2PSupplierModel.response = response;
                    if (!response.suppliers) {
                        selectedP2PSupplierModel.items = [];
                    }
                    selectedP2PSupplierModel.disabled = false;
                    selectedP2PSupplierModel.required = true; //force true because set to false for some unknown reason
                }
            );

            return selectedP2PSupplierModel;
        }

        function updateCISdata(CISModel, data) {
            if (CISModel && CISModel.selected && CISModel.selected.object) {
                data.options[CISModel.index].p2pInformation.CIS = CISModel.selected.value;
            }
            return data;
        }

        function updateTaxCodeData(taxCodeModel, data, parentIndexNotRequired) {
            parentIndexNotRequired = typeof parentIndexNotRequired !== 'undefined';
            var customIndex;
            if (taxCodeModel && taxCodeModel.selected && taxCodeModel.selected.object) {
                if (parentIndexNotRequired) {
                    customIndex = data;
                } else {
                    customIndex = data.options[taxCodeModel.parentIndex].p2pInformation;
                }
                customIndex.nominalLines[taxCodeModel.index].taxCode = taxCodeModel.selected.object.ID;
            }
            return data;
        }

        function updateTransactionTypeData(transactionTypeModel, data, parentIndexNotRequired) {
            parentIndexNotRequired = typeof parentIndexNotRequired !== 'undefined';
            var customIndex;
            if (transactionTypeModel && transactionTypeModel.selected && transactionTypeModel.selected.object) {
                if (parentIndexNotRequired) {
                    customIndex = data;
                } else {
                    customIndex = data.options[transactionTypeModel.parentIndex].p2pInformation;
                }
                customIndex.nominalLines[transactionTypeModel.index].transactionType = transactionTypeModel.selected.object.ID;

            }
            return data;
        }

        function updateSelectedScheduleNumberModel(transactionTypeModel, selectedScheduleNumberModel, quoteResponse, parentIndexNotRequired) {
            parentIndexNotRequired = typeof parentIndexNotRequired !== 'undefined';
            if (transactionTypeModel && transactionTypeModel.selected && transactionTypeModel.selected.object) {
                var scheduleMember = parentIndexNotRequired
                    ? selectedScheduleNumberModel[transactionTypeModel.index]
                    : selectedScheduleNumberModel[transactionTypeModel.parentIndex][transactionTypeModel.index];

                scheduleMember.loading = true;
                scheduleMember.disabled = true;

                apiClient.get(quoteResponse.getLink('p2p-schedule-numbers'), {
                    transactionType: transactionTypeModel.selected.object.ID
                }).then(
                    function (response) {
                        scheduleMember.response = response;
                    }
                );
            }
            return selectedScheduleNumberModel;
        }

        function updateScheduleNumberData(scheduleNumberModel, data, parentIndexNotRequired) {
            parentIndexNotRequired = typeof parentIndexNotRequired !== 'undefined';
            var customIndex;
            if (scheduleNumberModel && scheduleNumberModel.selected && scheduleNumberModel.selected.object) {
                if (parentIndexNotRequired) {
                    customIndex = data;
                } else {
                    customIndex = data.options[scheduleNumberModel.parentIndex].p2pInformation;
                }
                customIndex.nominalLines[scheduleNumberModel.index].scheduleNumber = scheduleNumberModel.selected.object.ID;
            }
            return data;
        }

        function updateSelectedExpenseCodeModel(scheduleNumberModel, selectedExpenseCodeModel, selectedTransactionTypeModel, quoteResponse, parentIndexNotRequired) {
            parentIndexNotRequired = typeof parentIndexNotRequired !== 'undefined';
            if (scheduleNumberModel && scheduleNumberModel.selected && scheduleNumberModel.selected.object) {
                var expenseMember;
                var transactionType;
                if (parentIndexNotRequired) {
                    expenseMember = selectedExpenseCodeModel[scheduleNumberModel.index];
                    transactionType = selectedTransactionTypeModel[scheduleNumberModel.index];
                } else {
                    expenseMember = selectedExpenseCodeModel[scheduleNumberModel.parentIndex][scheduleNumberModel.index];
                    transactionType = selectedTransactionTypeModel[scheduleNumberModel.parentIndex][scheduleNumberModel.index];
                }

                expenseMember.loading = true;
                expenseMember.disabled = true;

                apiClient.get(quoteResponse.getLink('p2p-expense-codes'), {
                    transactionType: transactionType.selected.object.ID,
                    scheduleNumber: scheduleNumberModel.selected.object.ID
                }).then(
                    function (response) {
                        expenseMember.response = response;
                    }
                );
            }
            return selectedExpenseCodeModel;
        }

        function updateExpenseCodeData(expenseCodeModel, data, parentIndexNotRequired) {
            parentIndexNotRequired = typeof parentIndexNotRequired !== 'undefined';
            var customIndex;
            if (expenseCodeModel && expenseCodeModel.selected && expenseCodeModel.selected.object) {
                if (parentIndexNotRequired) {
                    customIndex = data;
                } else {
                    customIndex = data.options[expenseCodeModel.parentIndex].p2pInformation;
                }
                customIndex.nominalLines[expenseCodeModel.index].expenseCode = expenseCodeModel.selected.object.ID;
            }
            return data;
        }

        function updateSelectedTenantCodeModel(expenseCodeModel, selectedTenantCodeModel, selectedTransactionTypeModel, selectedScheduleNumberModel, quoteResponse, parentIndexNotRequired) {
            parentIndexNotRequired = typeof parentIndexNotRequired !== 'undefined';
            if (expenseCodeModel && expenseCodeModel.selected && expenseCodeModel.selected.object) {
                var transactionTypeMember;
                var selectedNumber;
                var tenantMember = selectedTenantCodeModel[expenseCodeModel.parentIndex][expenseCodeModel.index];

                if (parentIndexNotRequired) {
                    tenantMember = selectedTenantCodeModel[expenseCodeModel.index];
                    transactionTypeMember = selectedTransactionTypeModel[expenseCodeModel.index];
                    selectedNumber = selectedScheduleNumberModel[expenseCodeModel.index];
                } else {
                    var tenantMember = selectedTenantCodeModel[expenseCodeModel.parentIndex][expenseCodeModel.index];

                    transactionTypeMember = selectedTransactionTypeModel[expenseCodeModel.parentIndex][expenseCodeModel.index];
                    selectedNumber = selectedScheduleNumberModel[expenseCodeModel.parentIndex][expenseCodeModel.index];
                }

                tenantMember.loading = true;
                tenantMember.disabled = true;

                apiClient.get(quoteResponse.getLink('p2p-tenant-codes'), {
                    transactionType: transactionTypeMember.selected.object.ID,
                    scheduleNumber: selectedNumber.selected.object.ID,
                    expenseCode: expenseCodeModel.selected.object.ID
                }).then(
                    function (response) {
                        tenantMember.response = response;
                    }
                );
            }
            return selectedTenantCodeModel;
        }

        function updateTenantCodeData(tenantCodeModel, data, parentIndexNotRequired) {
            parentIndexNotRequired = typeof parentIndexNotRequired !== 'undefined';
            var customIndex;
            if (tenantCodeModel && tenantCodeModel.selected && tenantCodeModel.selected.object) {
                if (parentIndexNotRequired) {
                    customIndex = data;
                } else {
                    customIndex = data.options[tenantCodeModel.parentIndex].p2pInformation;
                }
                customIndex.nominalLines[tenantCodeModel.index].tenantCode = tenantCodeModel.selected.object.ID;
            }
            return data;
        }

        function updateSupplierData(supplierModel, data) {
            if (supplierModel && supplierModel.selected && supplierModel.selected.object) {
                data.p2pSupplier = supplierModel.selected.object.Code;
            }
            return data;
        }

        return this;
    }
})();
