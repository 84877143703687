(function () {
    'use strict';

    angular
        .module('elogbooks.user.absence-planners')
        .controller('AbsenceEntryDetailsController', AbsenceEntryDetailsController);

    AbsenceEntryDetailsController.$inject = ['absenceEntryResponse', 'elbSettings'];

    function AbsenceEntryDetailsController(absenceEntryResponse, elbSettings) {
        var vm = this;

        vm.absenceEntryResponse = absenceEntryResponse;
        vm.yearlyEntitlement = null;

        if (vm.absenceEntryResponse.yearlyEntitlement) {
            vm.yearlyEntitlement = vm.absenceEntryResponse.yearlyEntitlement;
        } else {
            vm.yearlyEntitlement = elbSettings.getSetting('default_yearly_entitlement').value;
        }

        vm.period = vm.absenceEntryResponse.period;
        vm.taken = vm.absenceEntryResponse._links.user.holidaysTaken || 0;
        vm.remaining = vm.yearlyEntitlement - vm.taken;
    }
})();
