(function(){
    'use strict';

    angular
        .module('elogbooks.user.patrols')
        .controller('GenericPatrolPointsListController', GenericPatrolPointsListController);

    GenericPatrolPointsListController.$inject = ['$state', '$stateParams', '$filter', 'patrolPointsCollection', 'siteCollectionResponse', 'patrolPointRequirementTypes', 'paramConverterService', 'locationsCollectionResponse'];

    function GenericPatrolPointsListController ($state, $stateParams, $filter, patrolPointsCollection, siteCollectionResponse, patrolPointRequirementTypes, paramConverterService, locationsCollectionResponse) {
        var vm = this;

        vm.patrolPointsCollectionResponse = patrolPointsCollection;
        vm.patrolPoints = patrolPointsCollection.patrolPoints;

        vm.siteSelect = {
            response : siteCollectionResponse,
            link : siteCollectionResponse ? siteCollectionResponse.getLink('self') : null,
            itemValuePath: 'id',
            itemTitlePath: 'name',
            responseKeyPath: 'sites'
        };

        vm.criteria = {
            patrolPointSummary: {
                type: 'text',
                title: 'SUMMARY',
                value: $stateParams.patrolPointSummary
            },
            patrolPointType: {
                type: 'options',
                title: 'REQUIREMENTTYPE',
                value: $stateParams.patrolPointType,
                options: patrolPointRequirementTypes.getAll()
            },
            patrolPointSiteId: {
                type: 'jsonselectwidget',
                title: 'SITE',
                value: JSON.parse($stateParams.patrolPointSiteId),
                options: vm.siteSelect
            },
            patrolPointLocationId: {
                type: 'options',
                title: 'LOCATION',
                value: parseInt($stateParams.patrolPointLocationId),
                isDisabled: true
            }
        };

        if (locationsCollectionResponse) {
            vm.criteria.patrolPointLocationId.options = locationsCollectionResponse.locations.map(function(location) {
                var title;

                if (location.fullPathString) {
                    var path = JSON.parse(location.fullPathString);
                    title = path.map(function(location) {
                        return location.name
                    }).join(' / ');
                } else {
                    title = location.name;
                }

                return {
                    value: location.id,
                    title: title
                }
            });

            vm.criteria.patrolPointLocationId.isDisabled = false;
        }

        vm.search = search;

        function search (params) {
            var override = {
                pointPage: params.page
            };

            $state.go('.', angular.extend({}, params, override), { reload: $state.current });
        }
    }
})();
