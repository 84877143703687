angular.module('elogbooksDirectives').directive('quoteModal', [
    'quoteModalService',
    '$rootScope',
    function (quoteModalService, $rootScope) {
        return {
            restrict: 'AE',
            scope: {
                model: '=ngModel',
                context: '@',
                question: '=ngQuestion',
                section: '=ngSection',
                btnClass: '@',
                minified: '@'
            },
            templateUrl: '/modules/directives/quote-modal/quote-add-modal-button.html',
            controller: function ($scope) {
                $scope.model.raiseQuote = raiseQuote;
                $scope.model.loading = false;

                function  raiseQuote() {
                    $scope.model.loading = true;
                    quoteModalService.raiseQuote($scope, true);
                    $rootScope.$on('QuoteModalLoaded', function () {
                        $scope.model.loading = false;
                    });
                }
            }
        };
    }]
);
