app.config(function ($translateProvider) {
    $translateProvider.translations('nl', {
        USER: 'Gebruiker',
        USERS: 'Gebruikers',

        USER_EDIT: 'Wijzig gebruiker',
        USER_LIST: 'Gebruikers',
        USER_ADD: 'Maak een nieuwe gebruiker',
        ADD_NEW_USER: 'Maak een gebruiker',
        NEW_SITE_ACCESS: 'Geef toegang voor nieuwe objecten',
        USER_CREATED: 'Gebruiker aangemaakt',
        NEW_ROW: 'Nieuwe regel',
        USER_UPDATED: 'Gebruiker aangepast',

        USER_ROLES: 'Gebruikers Rollen',
        ALL_ROLES: 'Alle rollen',
        SELECTED_ROLES: 'Geselecteerde rollen',
        CHANGE_PASSWORD: 'Wijzig wachtwoord',
        PASSWORD_RESET_REQUEST_SUCCESSFUL: 'Bekijk uw e-mail voor instructies om het wachtwoord te wijzigen.',
        USER_PREFERENCES_VIEW: 'Gebruikersinstellingen',
        USER_PREFERENCES_EDIT: 'Wijzig gebruikersinstellingen',
        USER_PREFERENCES: 'Gebruikersinstellingen',
        USER_NOTIFICATION_TYPE: 'Methode voor berichten',
        NOTIFICATIONMETHOD: 'Methode voor berichten',
        EMAILCONTENTTYPE: 'Type voor inhoud e-mail',
        EMAIL_CONTENT_TYPE: 'Type voor inhoud e-mail',
        USER_EMAIL_CONTENT_TYPE: 'E-mail inhoud type',
        USER_PREFERENCE_ALL: 'alle',
        USER_PREFERENCE_NOTIFICATION: 'notificaties',
        USER_PREFERENCE_EMAIL: 'e-mail',
        USER_PREFERENCE_TEXT: 'tekst',
        USER_PREFERENCE_HTML: 'html',
        PREFERENCES_UPDATED: 'Gebruikersinstellingen zijn aangepast',
        USER_PREFERENCE_LOCALE: 'Taal',
        USER_PREFERENCE_NOTIFY_ON_CREATE_JOB: 'Bericht bij aanmaken opdracht',
        USER_PREFERENCE_NOTIFY_ON_COMPLETE_JOB: 'Bericht bij afronden opdracht',
        USER_PREFERENCE_NOTIFY_ON_CREATE_QUOTE: 'Bericht bij maken offerte',
        USER_PREFERENCE_NOTIFY_ON_ACCEPT_QUOTE: 'Bericht bij accepteren offerte',

        THIS_USER_HAS_NO_SITES: 'Deze gebruiker heeft geen authorisatie tot objecten',
        GIVE_ACCESS: 'Geef authorisatie',
        CHANGE_SITE_ACCESS: 'Pas authorisatie voor object aan',
        GRANT_ACCESS: 'Geef authorisatie',
        REVOKE_ACCESS: 'Authorisatie intrekken',
        TO_ALL_ACTIVE_SITES: 'Voor alle actieve objecten',
        SELECT_SPECIFIC_SITES: 'Seleccteer specifieke objecten',
        FROM_ALL_SITES_ON_THE_SYSTEM: 'Vanaf alle objecten in het systeem',
        CONFIRM_AND_FINISH: 'Bevestigen en afronden',
        CONTINUE_AND_CHOOSE_SITES: 'Ga verder en kies object',
        AVAILABLE_SITES: 'Beschikbare objecten',
        GRANT_ACCESS_TO: 'Geef authorisatie voor objecten',
        MODIFY: 'Aanpassen',

        SITE_PERMISSIONS: 'Rechten voor objecten',
        SELECT_PERMISSIONS: 'Selecteer rechten',
        ADD_PERMISSIONS: 'Voeg rechten toe',
        UPDATE_PERMISSIONS: 'Pas rechten aan',
        EXPLICIT_PERMISSIONS: 'Specifieke rechten',
        USER_PERMISSIONS: 'Rechten gebruiker',
        PERMISSIONS: 'Rechten',
        PERMISSION: 'toegang',
        REMOVE: 'Verwijderen',

        NO_OPERATIVES_ASSIGNED: 'Geen leverancier toegewezen',

        SITE_PERMISSIONS_UPDATED: 'Object rechten aangepast',
        USER_PERMISSIONS_UPDATED: 'Rechten gebruikers aangepast',
        USER_TOKENS: 'Tokens',
        TOKEN: 'Token',
        TOKENS_LIST: 'Tokens List',
        EXPIRATION_DATE: 'Expiration Date',
        TOKENS_ADD: 'Create a New Token',
        USER_TOKEN_ADD: 'Create Token',
        TOKENS: 'Tokens',
        TOKEN_ADD: 'Create a New Token',
        TOKEN_CREATED: 'Token Created',
        TOKEN_REVOKED: 'Token Revoked',
        STANDARD: 'Standard',
        MERIDIAN: 'Meridian',
        RISKWISE: 'Riskwise',
        ALCUMUS: 'Alcumus',
        PROPERTYPLUS: 'Property Plus',
        PROPERTYPLUS_LIGTAS: 'Property Plus (Ligtas)',
        USED_TO_SET_API_USER_TYPE: 'Used to set API User type',
        INVALID_USER_TYPE: 'Invalid User Type. You may be attempting to use a user type for a disabled module.',
        USER_TYPE: 'Type',
        INVALID_TOKEN: 'Invalid Token',
        ROLES_TO_MANY_MEMBERSHIPS: 'A user has more than 100 site memberships, so please only add or remove 1 role per request.',
        SITE_ACCESS_REMOVED: 'Object toegang verwijderd',
        CONTACT_HAS_NO_EMAIL: 'Users must have at least 1 email address, please add an email address'
    });
});
