app.config(function ($translateProvider) {
    $translateProvider.translations('nl', {
        TASK: 'Werk',
        TASKS_MANAGE_TASKS: 'Beheer Werkzaamheden',
        TASKS_GENERATE_PLANNER: 'Genereer Planner',
        TASK_GENERATE_PLANNER: 'Genereer Planner',
        TASK_GENERATE_DATES: 'Datumreeks voor Genereren',
        TASKS_VIEW_PLANNER: 'Bekijk Planner',
        TASKS_IMPORT: 'Importeer taken',
        VIEW_PLANNER: 'Bekijk Planner',
        MASTER_TASKS_PLANNER: 'Hoofd-werkzaamhedenplanner',
        TASK_CREATE_PROCESS_CONTINUE: 'Doorgaan',
        TASK_CREATE_PROCESS_CREATE_ASSET: 'Asset Toevoegen',
        TASK_CREATE_STEP_1: 'Werk Aanmaken',
        SERVICE_TYPE_TIP: 'Hard Services behoeven een asset.',
        LIST_TASKS: 'Werkzaamheden',
        TASK_PLANNER: 'Planner',
        TASKS: 'Werkzaamheden',
        TASK_EVENT_ADD: 'Werkgebeurtenis Toevoegen',
        TASK_PLANNER: 'Planner',
        PLANNER: 'Planner',
        PPM_PLANNER: 'PPM Planner',
        TASK_ADD: 'Werk Toevoegen',
        TASK_EDIT: 'Werk Bewerken',
        TASK_GENERATE: 'Genereer Werk',
        TASK_STATUS: 'Werkstatus',
        TASKS_NO_RESULTS: 'Geen resultaten',
        STATUS_ALL: 'Alles',
        SELECT_FREQUENCY: 'Selecteer frequentie',
        SELECT_INTERVAL: 'Selecteer interval',
        SELECT_PREFIX: 'Selecteer voorvoegsel',
        SELECT_DAY: 'Selecteer dag',
        SELECT_DAY_NUMBER: 'Selecteer dag',
        SELECT_MONTH: 'Selecteer maand',
        SELECT_ALL_FIELDS_ERROR: 'Selecteer a.u.b. alle velden',
        AT_LEAST_ONE_FREQUENCY_REQUIRED: 'Minimaal één frequentie is verplicht',
        DECLINE_SELECTED: 'Selectie Afwijzen',
        DECLINE_FILTERED: 'Alle Gefilterde Afwijzen',
        APPROVE_SELECTED: 'Selectie Goedkeuren ({{ count }})',
        APPROVE_FILTERED: 'Alle Gefilterde Goedkeuren',
        REMOVE_SELECTED: 'Selectie Verwijderen',
        REMOVE_FILTERED: 'Alle Gefilterde Verwijderen',
        GENERATE_SELECTED: 'Selectie Genereren',
        GENERATE_FILTERED: 'Alle Gefilterde Genereren',
        SEND_FOR_APPROVAL: 'Opsturen ter goedkeuring',
        SEND_FILTERED_FOR_APPROVAL: 'Alle Gefilterde opsturen ter goedkeuring',
        INTERVAL: 'Interval',
        RULE: 'Regel',
        RULES: 'Regels',
        OF: 'van',
        DATE: 'Datum',
        DAY_OF_THE_WEEK: 'Dag van de week',
        FREQUENCY: 'Frequentie',
        FREQUENCIES: 'Frequenties',
        START: 'Startdatum',
        DATE_FROM: 'Startdatum',
        END: 'Einddatum',
        DATE_TO: 'Einddatum',
        TOTAL: 'Totaal',
        NO_ITEMS_SELECTED: 'Geen items geselecteerd',
        REASON_FOR_TASK_EVENT_REJECT: 'Geef a.u.b. een reden voor de afwijzing',
        TASK_GENERATION_INFO: 'werkgenereerverzoek(en) in de wachtrij. Ververs de planner a.u.b. na één minuut.',
        TASK_APPROVAL_PENDING_INFO: '{{ count }} goedkeuringsverzoek{{ count > 1 ? "en" : "" }} in afwachting.',
        ALLTASKS: 'Selecteer alle werkzaamheden',
        COPYFROMLASTYEAR: 'Kopiëer van vorig jaar',
        TASK_SERVICE_PROVIDER_NOT_DEFINED: 'Leverancier niet gedefinieerd',

        TASK_STATUS_DRAFT: 'Concept',
        TASK_STATUS_PENDING_APPROVAL: 'Goedkeuring in Afwachting',
        TASK_STATUS_REJECTED: 'Afgewezen',
        TASK_STATUS_APPROVED: 'Goedgekeurd',
        TASK_STATUS_JOB_COMPLETED: 'Afgerond',
        TASK_STATUS_JOB_AWAITING_PAPERWORK: 'In afwachting papierwerk',
        TASK_STATUS_JOB_IN_PROGRESS: 'In behandeling',
        TASK_STATUS_JOB_MISSED: 'Gemist',
        TASK_STATUS_JOB_OVERDUE: 'Verlopen',
        TASK_STATUS_FUTURE_DRAFT: 'Toekomstig Concept',
        TASK_STATUS_FUTURE_PENDING_APPROVAL: 'Toekomstige goedkeuring in Afwachting',
        TASK_EVENT_STATUTORY_BASED_ON_FREQUENCY: 'Wettelijk onderhoud op bais van frequentie',
        TASK_STATUS_FUTURE_REJECTED: 'Toekomstig Afgewezen',
        TASK_STATUS_FUTURE_APPROVED: 'Toekomstig Goedgekeurd',
        TASK_STATUS_IGNORED: 'Genegeerd',
        TASKS_AWAITING_APPROVAL: 'In afwachting van Goedkeuring',
        TASK_IS_MANDATORY: 'Verplicht',
        SOFT_SERVICE: 'Soft Service',
        HARD_SERVICE: 'Hard Service',
        TASKS_MY_PLANNER: 'Mijn Planner',
        SELECT_SITE_TO_CHOSE_SERVICE_PROVIDER: 'Selecteer a.u.b. een object om een leverancier te kiezen.',
        BULK_ASSIGN_SERVICE_PROVIDERS: 'Leveranciers toewijzen in bulk',
        TASK_SERVICE_ROUTINES_MUST_MATCH: 'De frequenties en intervallen matchen niet met de routine van de asset.',
        TASKS_PLANNER_UPLOAD: 'Planner Imports',
        TASK_IMPORTS: 'Imports',
        CURRENT_USER_IS_NOT_A_VALID_APPROVER: 'U bent geen geldige functionaris om dit goed te keuren.',
        CANNOT_SEND_SO_MANY_TASKS: 'Maximaal 1000 taken verzenden',
        CANNOT_GENERATE_SO_MANY_TASKS: 'Maximaal 1000 taken genereren',
        CANNOT_REMOVE_SO_MANY_TASKS: 'Maximaal 1000 taken verwijderen',
        CANNOT_APPROVE_SO_MANY_TASKS: 'Maximaal 1000 taken goedkeuren',
        CANNOT_DECLINE_SO_MANY_TASKS: 'Maximaal 1000 taken afwijzen'
    });
});
