;(function() {
    'use strict';

    angular
        .module('elogbooksServices')
        .service('elbFormService', ELBFormService);

    ELBFormService.$inject = ['$translate', '$filter', 'lodash'];

    function ELBFormService($translate, $filter, lodash) {
        var service = this,
            formTypes = [
                {
                    value: 'job',
                    title: $translate.instant('FORM_TYPE_JOB'),
                },
                {
                    value: 'asset',
                    title: $translate.instant('FORM_TYPE_ASSET'),
                },
                {
                    value: 'task',
                    title: $translate.instant('FORM_TYPE_TASK'),
                }
            ];

        service.getFormTypes = getFormTypes;
        service.getFormMacroOptions = getFormMacroOptions;
        service.getMacroValue = getMacroValue;

        return service;

        function getFormTypes(includeNoneSelected) {
            var types = formTypes.slice();

            if (includeNoneSelected) {
                types.unshift({
                    value: null,
                    title: $translate.instant('NONE_SELECTED'),
                });
            }

            return types;
        }

        function getFormMacroOptions(type) {
            var macroOptions = {
                job: [
                    {
                        value: 'summary',
                        title: $translate.instant('FORM_MACRO_JOB_SUMMARY'),
                        attribute: 'summary',
                    },
                    {
                        value: 'description',
                        title: $translate.instant('FORM_MACRO_JOB_DESCRIPTION'),
                        attribute: 'description',
                    },
                    {
                        value: 'site_name',
                        title: $translate.instant('FORM_MACRO_JOB_SITE_NAME'),
                        attribute: '_links.site.title',
                    },
                    {
                        value: 'service_provider_name',
                        title: $translate.instant('FORM_MACRO_JOB_SERVICE_PROVIDER_NAME'),
                        attribute: '_links.serviceprovider.title',
                    },
                    {
                        value: 'operative_name',
                        title: $translate.instant('FORM_MACRO_JOB_OPERATIVE_NAME'),
                        attribute: '_links.operative.title',
                    },
                    {
                        value: 'attendance_due_at',
                        title: $translate.instant('FORM_MACRO_JOB_ATTENDANCE_DUE_AT'),
                        attribute: 'attendanceDueAt',
                        type: 'datetime',
                    }
                ],
                asset: [
                    {
                        value: 'name',
                        title: $translate.instant('FORM_MACRO_ASSET_NAME '),
                        attribute: 'name',
                    },
                    {
                        value: 'reference',
                        title: $translate.instant('FORM_MACRO_ASSET_REFERENCE'),
                        attribute: 'reference',

                    },
                    {
                        value: 'make',
                        title: $translate.instant('FORM_MACRO_ASSET_MAKE'),
                        attribute: 'make',
                    },
                    {
                        value: 'model',
                        title: $translate.instant('FORM_MACRO_ASSET_MODEL'),
                        attribute: 'model',
                    },
                    {
                        value: 'barcode',
                        title: $translate.instant('FORM_MACRO_ASSET_BARCODE'),
                        attribute: 'barcode',
                    },
                    {
                        value: 'serial_number',
                        title: $translate.instant('FORM_MACRO_ASSET_SERIAL_NUMBER'),
                        attribute: 'serial',
                    },
                    {
                        value: 'location_title',
                        title: $translate.instant('FORM_MACRO_ASSET_LOCATION_TITLE'),
                        attribute: '_links.location.title',
                    }
                ],
                task: [
                    {
                        value: 'name',
                        title: $translate.instant('FORM_MACRO_TASK_NAME'),
                        attribute: 'name',
                    },
                    {
                        value: 'location_title',
                        title: $translate.instant('FORM_MACRO_TASK_LOCATION_TITLE'),
                        attribute:'_links.location.title',
                    },
                    {
                        value: 'asset_name',
                        title: $translate.instant('FORM_MACRO_TASK_ASSET_NAME '),
                        attribute: 'name',
                        entity: 'asset',
                    },
                    {
                        value: 'asset_reference',
                        title: $translate.instant('FORM_MACRO_TASK_ASSET_REFERENCE'),
                        attribute: 'reference',
                        entity: 'asset'
                    },
                    {
                        value: 'asset_make',
                        title: $translate.instant('FORM_MACRO_TASK_ASSET_MAKE'),
                        attribute: 'make',
                        entity: 'asset',
                    },
                    {
                        value: 'asset_model',
                        title: $translate.instant('FORM_MACRO_TASK_ASSET_MODEL'),
                        attribute: 'model',
                        entity: 'asset',
                    },
                    {
                        value: 'asset_barcode',
                        title: $translate.instant('FORM_MACRO_TASK_ASSET_BARCODE'),
                        attribute: 'barcode',
                        entity: 'asset',
                    },
                    {
                        value: 'asset_serial_number',
                        title: $translate.instant('FORM_MACRO_TASK_ASSET_SERIAL_NUMBER'),
                        attribute: 'serial',
                        entity: 'asset',
                    },
                    {
                        value: 'asset_location_title',
                        title: $translate.instant('FORM_MACRO_TASK_ASSET_LOCATION_TITLE'),
                        attribute: '_links.location.title',
                        entity: 'asset',
                    }
                ]
            };
            return macroOptions[type];
        }

        function getMacroValue(form, associatedEntities, macro) {
            var macroOptions = getFormMacroOptions(form.type),
                macroOption = lodash.find(macroOptions, {value: macro}),
                entity = associatedEntities[form.type] || {},
                value = lodash.get(entity, macroOption.attribute);

            if (macroOption.entity) {
                entity = associatedEntities[macroOption.entity] || {};
                value = lodash.get(entity, macroOption.attribute);
            }

            if (value && value.match(/^[0-9]{4}-[0-9]{2}-[0-9]{2}T[0-9]{2}:[0-9]{2}:[0-9]{2}/)) {
                value = moment(value).format('L HH:mm');
            }

            return value || $translate.instant('FORM_MACRO_VALUE_NOT_FOUND');
        }
    }
})();
