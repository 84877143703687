(function () {
    'use strict';

    angular
        .module('elogbooks.common.kpi-surveys')
        .controller('KPISurveyListController', KPISurveyListController);

    KPISurveyListController.$inject = ['$state', 'apiClient', 'confirmationModal', 'surveysResponse', 'lodash', 'messenger', 'requestDataFactory', 'crudService'];

    function KPISurveyListController ($state, apiClient, confirmationModal, surveysResponse, lodash, messenger, requestDataFactory, crudService) {
        var vm = this;
        vm.surveys = surveysResponse.surveys;
        vm.surveyResponse = surveysResponse;

        vm.delete = function (survey) {
            vm.data = {
                _links: {
                    surveys: []
                }
            };

            lodash.each(vm.surveys, function (existingSurvey) {
                if (existingSurvey.id !== survey.id) {
                    vm.data._links.surveys.push(existingSurvey._links.self);
                }
            });

            return confirmationModal.open().result.then(function (response) {
                return apiClient.update(vm.surveyResponse.getLink('self'), vm.data).then(function (response) {
                    if (response) {
                        $state.go('.', {}, {reload: $state.get('^') }).then(function () {
                            messenger.success('SURVEYS_DELETED');
                        });
                    } else {
                        messenger.error('REQUEST_ERROR');
                    }
                });
            });
        };
    }
})();
