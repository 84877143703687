/**
 * SurveySchedule Service
 */
angular.module('elogbooksServices').service('surveyScheduleService', function (apiClient, $q) {
    this.create = createAction;
    this.update = updateAction;
    this.delete = deleteAction;

    /**
     * Create a survey schedule
     */
    function createAction(link, surveyRequest) {

        var deferred = $q.defer();

        apiClient.create(link, prepareData(surveyRequest)).then(function (isSuccess) {
            if (isSuccess) {
                return deferred.resolve();
            }

            return deferred.reject();
        });

        return deferred.promise;
    }

    /**
     * Update a survey schedule
     */
    function updateAction(link, surveyRequest) {

        var deferred = $q.defer();

        apiClient.update(link, prepareData(surveyRequest)).then(function (isSuccess) {
            if (isSuccess) {
                return deferred.resolve();
            }

            return deferred.reject();
        });

        return deferred.promise;
    }

    /**
     * Delete a survey schedule
     */
    function deleteAction(link) {

        var deferred = $q.defer();

        apiClient.delete(link).then(function (isSuccess) {
            if (isSuccess) {
                return deferred.resolve();
            }

            return deferred.reject();
        });

        return deferred.promise;
    }

    /**
     * Convert data ready for api
     *  - Send the rule as one rule, not array of rules
     * @param {object} data
     * @returns {object}
     */
    function prepareData(data) {
        var cloned = angular.copy(data);
        cloned.rule = Array.isArray(cloned.rule) ? cloned.rule[0] : cloned.rule;

        return cloned;
    }
});
