(function (){
    'use strict';

    angular
        .module('elogbooks.common.audit')
        .controller('AuditSectionAddEditController', AuditSectionAddEditController);

    AuditSectionAddEditController.$inject = ['$state', 'auditResponse', 'sectionResponse', 'sectionsCollectionResponse', 'confirmationModal', 'locationResponse', 'parentSectionResponse', 'apiClient', 'lodash', 'messenger', 'requestDataFactory'];

    function AuditSectionAddEditController($state, auditResponse, sectionResponse, sectionsCollectionResponse, confirmationModal, locationResponse, parentSectionResponse, apiClient, lodash, messenger, requestDataFactory) {

        var vm = this;
        vm.create = createAction;
        vm.update = updateAction;
        vm.delete = deleteAction;
        vm.data = requestDataFactory.createRequest(getDefaults(sectionResponse));
        vm.auditResponse = auditResponse;
        vm.audit = auditResponse;
        vm.sectionResponse = sectionResponse;
        vm.sectionsCollectionResponse = sectionsCollectionResponse;
        vm.parentSectionResponse = parentSectionResponse;
        vm.cancel = cancel;
        vm.siteLocations = locationResponse;
        vm.locationSection = lodash.has(sectionResponse, '_links.location');

        if(vm.data.id) {
            vm.panelTitle = 'EDIT_';
        } else {
            vm.panelTitle = 'ADD_';
        }

        vm.panelTitle += ((vm.parentSectionResponse._links ||  lodash.has(vm.sectionResponse, '_links.parent')) ? 'SUB_SECTION' : 'SECTION');

        function createAction () {

            if (vm.parentSectionResponse._links) {
                vm.data._links.parent = vm.parentSectionResponse._links.self;
            }

            if(vm.locationSection) {
                delete vm.data.name;
            }

             if(!vm.locationSection) {
                delete vm.data._links.location;
            }

            apiClient.create(vm.sectionsCollectionResponse.getLink('create'), vm.data).then(function (response) {
                if (response) {
                    $state.go('^.details', { sectionResource: response.getLink('self').encode() }, { reload: true }).then(function () {
                        messenger.success('AUDIT_SECTION_CREATED');
                    });
                } else {
                    messenger.error('REQUEST_ERROR');
                }
            });
        };

        function cancel () {

            var redirectRoute = '^';
            var redirectParams = {};

            if (vm.data.id === null && vm.parentSectionResponse._links) {
                redirectParams = {sectionResource : parentSectionResponse._links.self.href.encode()};
            } else if (vm.data.id !== null) {
                redirectParams = {sectionResource : sectionResponse._links.self.href.encode()};
            }

            $state.go(redirectRoute, redirectParams, { reload: false });
        }

        function updateAction () {
            if(vm.locationSection) {
                delete vm.data.name;
            }

             if(!vm.locationSection) {
                delete vm.data._links.location;
            }

            apiClient.update(vm.sectionResponse._links.self.href, vm.data).then(function (response) {
                if (response) {
                    $state.go('^', {}, { reload: true }).then(function () {
                        messenger.success('AUDIT_SECTION_UPDATED');
                    });
                } else {
                    messenger.error('REQUEST_ERROR');
                }
            });
        };

        function deleteAction() {
            return confirmationModal.open().result.then(function () {
                var redirectParams = {};
                var goToState = '^.^';
                var reloadState = '^.^.^.^';
                var redirectParams = {auditResource : auditResponse._links.self.href.encode()};

                // if sub-section, reload parent state with parent resource
                if (vm.parentSectionResponse._links) {
                    goToState = '^';
                    reloadState = '^.^';
                    redirectParams = {sectionResource : parentSectionResponse._links.self.href.encode()};
                }

                return apiClient.delete(vm.sectionResponse.getLink('delete')).then(function (response) {
                    if (response) {
                        $state.go(goToState, redirectParams, { reload: $state.get(reloadState) }).then(function () {
                            messenger.success('AUDIT_SECTION_DELETED');
                        });
                    } else {
                        messenger.error('REQUEST_ERROR');
                    }
                });
            });
        }

        function getDefaults(section) {
            return {
                id: section.id || null,
                name: section.name || null,
               _links: {
                    location: lodash.has(section, '_links.location') ? section._links.location : null
                },
            };
        }
    }
})();
