(function () {
    'use strict';

    angular
        .module('elogbooks.user.sites')
        .config(registerRoutesList)
        .config(registerRoutesDetails)
        .config(registerRoutesAdd)
        .config(registerRoutesEdit)
        .config(registerRoutesTenantEmailer);

    function registerRoutesList($stateProvider) {
        $stateProvider
            .state('dashboard.user.sites.list.details.tenants', {
                url: '/tenants?contactPage&contactLimit&contactOrder&contactName&siteIds&active&contactRole',
                parent: 'dashboard.user.sites.list.details',
                resolve: {
                    contactsResponse: function (apiClient, $stateParams, siteResponse, user) {
                        return apiClient.get(user._links.tenants.href, angular.extend({}, $stateParams, {
                            page: $stateParams.contactPage,
                            limit: $stateParams.contactLimit,
                            order: $stateParams.contactOrder,
                            name: $stateParams.contactName,
                            email: $stateParams.contactEmail,
                            role: $stateParams.contactRole,
                            site: siteResponse.getData().id,
                            company: $stateParams.contactCompany
                        }));
                    },
                    config: function() {
                        return {
                            contactType: 'tenant',
                            detailRoute: '.details',
                            label: 'TENANT',
                            tabTitle: 'TENANTS',
                            noBorder: false,
                        };
                    },
                    regionCollectionResponse: function() {
                        return null;
                    }
                },
                views: parseBook({
                    'elogbooks-right@dashboard.user.sites.list.details': {
                        templateUrl: '/modules/common/site-contacts/contacts-list.html',
                        controller: 'CommonSiteContactsController',
                        controllerAs: 'vm'
                    }
                }),
                params: {
                    contactPage: '1',
                    contactLimit: '10',
                    contactOrder: 'name',
                    contactName: null,
                    contactRole: null,
                    contactEmail: null,
                    active: { value: 'yes' },
                    contactCompany: null
                },
                ncyBreadcrumb: {
                    label: 'TENANTS'
                }
            });
    }

    function registerRoutesDetails($stateProvider) {
        $stateProvider
            .state('dashboard.user.sites.list.details.tenants.details', {
                url: '/details/{contactResource}',
                parent: 'dashboard.user.sites.list.details.tenants',
                views: parseBook({
                    'elogbooks-right@dashboard.user.sites.list.details': {
                        templateUrl: '/modules/common/site-contacts/details/contacts-details.html',
                        controller: 'CommonSiteContactsDetailsController',
                        controllerAs: 'vm'
                    }
                }),
                resolve: {
                    contactResponse: function (apiClient, $stateParams) {
                        return apiClient.get($stateParams.contactResource.decode());
                    },
                    contactInformationResponse: function (apiClient, contactResponse) {
                        var contactInformationLink = null;

                        if (contactResponse.user) {
                            contactInformationLink = contactResponse.user.getLink('contactinformation');
                        } else {
                            contactInformationLink = contactResponse.getLink('contactinformation');
                        }

                        if (contactInformationLink === null) {
                            return apiClient.noResourceFound();
                        }

                        return apiClient.get(contactInformationLink).then(function (response) {
                            return response || apiClient.noResourceFound();
                        });
                    }
                },
                ncyBreadcrumb: {
                    label: '{{ ::"CONTACT_INFORMATION_ADDRESS" | translate }}'
                }
            });
    }

    function registerRoutesAdd($stateProvider) {
        $stateProvider
            .state('dashboard.user.sites.list.details.tenants.add', {
                url: '/add',
                parent: 'dashboard.user.sites.list.details.tenants',
                views: parseBook({
                    'elogbooks-right@dashboard.user.sites.list.details': {
                        templateUrl: '/modules/common/site-contacts/add-edit/contacts-addedit.html',
                        controller: 'CommonSiteContactsAddEditController',
                        controllerAs: 'vm'
                    }
                }),
                resolve: {
                    contactResponse: function () {
                        return {};
                    }
                },
                ncyBreadcrumb: {
                    label: '{{ ::"ADD" | translate }}'
                }
            });
    }

    function registerRoutesEdit($stateProvider) {
        $stateProvider
            .state('dashboard.user.sites.list.details.tenants.details.edit', {
                url: '/edit',
                parent: 'dashboard.user.sites.list.details.tenants.details',
                views: parseBook({
                    'elogbooks-right@dashboard.user.sites.list.details': {
                        templateUrl: '/modules/common/site-contacts/add-edit/contacts-addedit.html',
                        controller: 'CommonSiteContactsAddEditController',
                        controllerAs: 'vm'
                    }
                }),
                resolve: {
                    config: function () {
                        return {
                            mode: 'edit',
                            contactType: 'tenant',
                            stateAfterDelete: '^.^'
                        };
                    }
                },
                ncyBreadcrumb: {
                    label: '{{ ::"EDIT" | translate }}'
                }
        });
    }

    function registerRoutesTenantEmailer($stateProvider) {
        $stateProvider
            .state('dashboard.user.sites.list.details.tenants.emailer', {
                url: '/tenant-emailer',
                parent: 'dashboard.user.sites.list.details.tenants',
                views: parseBook({
                    'elogbooks-right@dashboard.user.sites.list.details': {
                        templateUrl: '/modules/user/tenants/emailer/tenant-emailer.html',
                        controller: 'TenantEmailerController',
                        controllerAs: 'vm'
                    }
                }),
                resolve: {
                    modalInstance: function($uibModal) {
                        return $uibModal;
                    },
                    siteResponse: function(siteResponse) {
                        return siteResponse;
                    },
                    userPreferenceResponse: function(userManager) {
                        return userManager.getPreferences();
                    }
                },
                ncyBreadcrumb: {
                    label: '{{ ::"EMAIL_TENANTS" | translate }}'
                }
            })
            .state('dashboard.user.sites.list.details.tenants.emailer.view-recipients', {
                url: '/view-recipients',
                parent: 'dashboard.user.sites.list.details.tenants.emailer',
                resolve: {
                    modalInstance: function(modalInstance) {
                        return modalInstance;
                    },
                    contactsResponse: function (apiClient, $stateParams, siteResponse, user, tenantEmailerService) {
                        if(tenantEmailerService.tenantsResponse.contacts.length) {
                            return tenantEmailerService.tenantsResponse;
                        } else {
                            if (siteResponse) {
                                $stateParams.site = siteResponse.getData().id;
                            } else {
                                $stateParams.site = null;
                            }

                            return apiClient.get(user._links.tenants.href, angular.extend({}, $stateParams, {
                                page: $stateParams.contactPage,
                                limit: $stateParams.contactLimit,
                                order: $stateParams.contactOrder,
                                name: $stateParams.contactName,
                                email: $stateParams.contactEmail,
                                site: $stateParams.site
                            }));
                        }
                    }
                },
                ncyBreadcrumb: {
                    label: '{{ ::"VIEW_RECIPIENTS" | translate }}'
                },
                onEnter: ['$state', 'modalInstance', 'contactsResponse', function($state, modalInstance, contactsResponse) {
                    if(!modalGlob.closed) {
                        modalGlob.dismiss();
                    }

                    modalGlob = modalInstance.open({
                        templateUrl: '/modules/user/tenants/emailer/view-all-recipients.html',
                        controller: 'ViewAllRecipientsController',
                        controllerAs: 'vm',
                        size: 'lg',
                        resolve: {
                            contactsResponse: function() { return contactsResponse; }
                        }
                    })

                    modalGlob.result.then(null, backWeGo);

                    function backWeGo(source) {
                        if(source !== undefined) {
                            $state.go('^');
                        }
                    }

                    modalGlob.closed = false;
                }]
            });
    }

    var modalGlob = {closed: true};

})();
