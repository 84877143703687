(function() {
    'use strict';

    angular.module('elogbooksServices').service('systemNotifications', ['$window', 'lodash', 'apiClient', function ($window, lodash, apiClient) {
        var notificationsModel = {
            notifications: []
        };

        this.setNotifications = function (notifications) {
            notificationsModel.notifications = notifications;
        };

        this.getNotifications = function () {
            return notificationsModel;
        };

        this.clear = function (notification) {
            apiClient.create(notification.getLink('mark-read')).then(function(){
                notificationsModel.notifications = lodash.differenceBy(notificationsModel.notifications, [notification], 'id');
            });
        };

        this.openUrl = function (notification) {
            if (lodash.has(notification, 'link')) {
                $window.open(notification.link);
            }

            this.clear(notification);
        };

        this.count = function () {
            return notificationsModel.notifications.length;
        };

        this.clean = function () {
            notificationsModel.notifications = [];
        };

        return this;
    }]);
})();
