app.config(function($translateProvider) {
    $translateProvider.translations('zh-hk', {
        PARTNERSHIP: '合夥',
        PARTNERSHIPS: '合夥',

        ADD_NEW_PARTNERSHIP: '新增合夥',

        PARTNERSHIP_ADD: '新增合夥',
        PARTNERSHIPS_ADD: '新增合夥',
        PARTNERSHIPS_LIST: '合夥',

        SERVICE_PROVIDER_PARTNERSHIP_ADD: '新增合夥',

        PARTNERSHIP_LIST: '合夥',
        PARTNERSHIP_EDIT: '合夥',

        PARTNERSHIP_CREATED: '合夥已建立',
        PARTNERSHIP_UPDATED: '合夥已更新',
        PARTNERSHIP_DELETED: '合夥已刪除',

        PARTNERSHIP_SURVEYS_ADDED: '調查已增加',
        PARTNERSHIP_SURVEYS_DELETED: '調查已刪除',

        PARTNERSHIP_STATUS: '合夥狀態',
    });
});
