(function() {
    'use strict';

    angular
        .module('elogbooks')
        .config(Translations);

    function Translations($translateProvider) {
        $translateProvider.translations('en-gb', {
            JOB_ESCALATED_ACTION: '<strong>{{ ::event._links["user"].title }}</strong> escalated the job.',
            JOB_ESCALATED_SYSTEM_ACTION: 'The system automatically escalated the job.',
            JOB_ESCALATION_CANCELLED_ACTION: '<strong>{{ ::event._links["user"].title }}</strong> cancelled the escalation.',
            JOB_DEESCALATED_ACTION: '<strong>{{ ::event._links["user"].title }}</strong> de-escalated the job.',
            JOB_DEESCALATED_SYSTEM_ACTION: 'The system automatically cancelled the escalation.',
            JOB_REQUISITE_ANSWER_APPROVED_ACTION: '<strong>{{ ::event._links["user"].title }}</strong> approved a job requisite answer.',
            JOB_REQUISITE_ANSWER_REJECTED_ACTION: '<strong>{{ ::event._links["user"].title }}</strong> rejected a job requisite answer.',
            JOB_REQUISITE_ANSWERED_ACTION: '<strong>{{ ::event._links["user"].title }}</strong> answered a job requisite.',
            JOB_REQUISITE_ANSWER_ESCALATED_ACTION: '<strong>{{ ::event._links["user"].title }}</strong> escalated job requisite.',
            JOB_REPORTER_FEEDBACK_SYSTEM_ACTION: '<strong>{{ ::event.feedbackReporterName }}</strong> added Feedback',
            JOB_MISSING_DOCUMENT_TYPE_SYSTEM_ACTION: 'Document Missing - Please upload the required Document Type.',
            JOB_OPERATION_CANCELLED_SYSTEM_ACTION: 'The system cancelled the job',
            JOB_ALARM_UPDATED_SYSTEM_ACTION: 'The system updated the job alarm.',
            FEEDBACK_SCORE: 'Score',

            REPORTER_NAME: 'Reporter Name',
            REPORTER_EMAIL: 'Reporter Email',
            REPORTER_PHONE: 'Reporter Phone',
            REPORTER_RELATIONSHIP: 'Reporter Relationship',
            REPORTER_NOTIFY_ON_CREATE: 'Reporter Notify On Create',
            REPORTER_NOTIFY_ON_COMPLETE: 'Reporter Notify On Complete',

            CONTACT_NAME: 'Contact Name',
            CONTACT_EMAIL: 'Contact Email',
            CONTACT_PHONE: 'Contact Phone',
            CONTACT_RELATIONSHIP: 'Contact Relationship',
            CONTACT_NOTIFY_ON_CREATE: 'Contact Notify On Create',
            CONTACT_NOTIFY_ON_COMPLETE: 'Contact Notify On Complete'
        });
    }
})();
