(function () {
    'use strict';

    /**
     * @desc frequency units directive to allow user to select a unit value for frequencies
     * @example <elb-master-task-frequencies data-ng-model="MODEL" data-max-rules="1" data-statutory-types="vm.statutoryTypes"></elb-master-task-frequencies>
     */
    angular
        .module('elogbooksDirectives')
        .directive('elbMasterTaskFrequencies', masterTaskFrequencies);

    function masterTaskFrequencies() {
        var directive = {
            templateUrl: '/modules/directives/form-elements/master-task-frequencies/frequencies.html',
            restrict: 'AE',
            scope: {
                frequencies: '=ngModel',
                required: '=ngRequired',
                hideForm: '=',
                isStatutory: '@',
                statutoryTypes: '=',
                maxRules: '@',
            },
            require: ['ngModel'],
            transclude: true,
            controller: ['$scope', 'lodash', 'recurrenceRulesService', function($scope, lodash, recurrenceRulesService) {
                $scope.validationError = false;
                $scope.addFrequency = addFrequency;
                $scope.removeFrequency = removeFrequency;
                $scope.recurrenceRulesService = recurrenceRulesService;
                $scope.getRuleFrequencyAsString = recurrenceRulesService.getRuleFrequencyAsString;

                if (typeof $scope.frequencies === 'undefined') {
                    $scope.frequencies = [];
                }

                if (typeof $scope.statutoryTypes !== 'undefined') {
                    $scope.isStatutory = true;
                    $scope.isStatutoryConfirm = false;
                    $scope.statutoryTypesSelect = {
                        response : $scope.statutoryTypes,
                        link : $scope.statutoryTypes ? $scope.statutoryTypes.getLink('self') : null,
                        responseKeyPath: 'statutorytypes'
                    };
                }
                /**
                 * Transform form frequency to api format and store in model
                 */
                function addFrequency() {
                    // Validate widget
                    if ( !$scope.rule.frequency
                        || !$scope.rule.interval
                    ) {
                        $scope.validationError = true;

                        return;
                    }

                    //TRANSFORM BEFORE SAVING
                    var rule = {
                        frequency : $scope.rule.frequency,
                        interval : $scope.rule.interval,
                        _links : {

                        }
                    };

                    if ($scope.isStatutoryConfirm) {
                        rule._links.statutorytype = $scope.statutoryTypesSelect.selected;
                        if($scope.statutoryTypesSelect.selected === null){
                            $scope.validationError = true;
                            return;
                        }
                    }

                    // Search for duplicated
                    if (typeof lodash.find($scope.frequencies, rule) === 'undefined') {
                        $scope.frequencies.push(rule);
                    }

                    // Order rules
                    $scope.frequencies = lodash.orderBy($scope.frequencies, ['frequency', 'interval'], ['asc', 'asc']);
                    clearFrequency();
                }

                /**
                 * Remove frequency
                 */
                function removeFrequency(key) {
                    $scope.frequencies.splice(key, 1);
                }

                /**
                 * Clear frequency and hide form rows
                 */
                function clearFrequency() {
                    $scope.rule = {
                        frequency : null,
                        interval : null,
                    };

                    $scope.validationError = false;

                    if (typeof $scope.statutoryTypes !== 'undefined') {
                        $scope.statutoryTypesSelect.selected = null;
                        $scope.isStatutoryConfirm = false;
                    }
                }

                clearFrequency();
            }]
        };

        return directive;
    }
})();
