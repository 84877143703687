(function() {
    'use strict';

    angular
        .module('elogbooks.common.quote-requests')
        .controller('CommonQuoteRequestDetailsQuoteDetialsOptionDetailsInfoController', CommonQuoteRequestDetailsQuoteDetialsOptionDetailsInfoController);

    CommonQuoteRequestDetailsQuoteDetialsOptionDetailsInfoController.$inject = [
        'quoteOptionResponse',
        'quoteResponse',
        'apiClient',
        'elbDownloadService',
        'messenger'
    ];

    function CommonQuoteRequestDetailsQuoteDetialsOptionDetailsInfoController(
        quoteOptionResponse,
        quoteResponse,
        apiClient,
        elbDownloadService,
        messenger
    ) {
        var vm = this;
        vm.quoteOptionResponse = quoteOptionResponse;
        vm.quoteResponse = quoteResponse;
        vm.downloadPdf = downloadPdf;

        function downloadPdf() {
            apiClient.get(vm.quoteOptionResponse.getLink('pdf')).then(function (response) {
                if (response) {
                    elbDownloadService.download(response);
                } else {
                    messenger.error('REQUEST_ERROR');
                }
            });
        }
    }
})();
