(function() {
    'use strict';

    angular
        .module('elogbooks.user.finance.credit-notes')
        .controller('FinanceCreditNoteEventFeedController', FinanceCreditNoteEventFeedController);

    FinanceCreditNoteEventFeedController.$inject = [
        'eventCollectionResponse'
    ];

    function FinanceCreditNoteEventFeedController(eventCollectionResponse) {
        var vm = this;
        vm.feed = [];
        vm.eventCollectionResponse = eventCollectionResponse;
    }
})();
