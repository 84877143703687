(function() {
    'use strict';

    angular
        .module('elogbooks')
        .config(Translations);

    function Translations($translateProvider) {
        $translateProvider.translations('nl', {
            MERIDIAN_DOC_TYPE: 'Meridian Document Type',
            RISKWISE_DOC_TYPE: 'Riskwise Document Type',

            INSPECTIONDATE: 'Inspectie datum',
            NEXTINSPECTIONDATE: 'Volgende Inspectie Datum'
        });
    }
})();
