app.config(function ($translateProvider) {
    $translateProvider.translations('zh-hk', {
        MONTH: '月',
        YEAR: '年',
        DAILY: '每日',
        WEEKLY: '每週',
        MONTHLY: '每月',
        YEARLY: '每年',
        FIRST: '首',
        LAST: '尾',
        DAY: '日',
        DATE_STARTEDAT: '開始',
        DATE_EXPIREDAT: '結束',
        DATE_EXPIRESAT: '結束',
        DATE_REVIEWEDAT: '檢閱於',
        DATE_TENDEREDAT: '投標於',
        DATE_CREATEDAT: '建立於',
        DATE_COMPLETIONAT: '完成到期於',

        DATE_CREATEDAT: '建立於',

        DATE_LAST_READ: '檢閱於',
        DATE_ACTIVEAT: '啟用於',
        DATE_INACTIVEAT: '停用於',

        TASK_PLANNER_DAILY: '{{interval}}日',
        TASK_PLANNER_WEEKLY: '{{interval}}週',
        TASK_PLANNER_MONTHLY: '{{interval}}月',
        TASK_PLANNER_ANNUALLY: '{{interval}}年',

        /* Generation */
        RELATIVE_STARTDATE: '相對開始日期',
        MONDAY: '星期一',
        TUESDAY: '星期二',
        WEDNESDAY: '星期三',
        THURSDAY: '星期四',
        FRIDAY: '星期五',
        SATURDAY: '星期六',
        SUNDAY: '星期日',

        BYDAY_MONDAY: '星期一',
        BYDAY_TUESDAY: '星期二',
        BYDAY_WEDNESDAY: '星期三',
        BYDAY_THURSDAY: '星期四',
        BYDAY_FRIDAY: '星期五',
        BYDAY_SATURDAY: '星期六',
        BYDAY_SUNDAY: '星期日',

        BYDAY_MONDAY_FIRST: '每月第一個星期一',
        BYDAY_TUESDAY_FIRST: '每月第一個星期二',
        BYDAY_WEDNESDAY_FIRST: '每月第一個星期三',
        BYDAY_THURSDAY_FIRST: '每月第一個星期四',
        BYDAY_FRIDAY_FIRST: '每月第一個星期五',
        BYDAY_SATURDAY_FIRST: '每月第一個星期六',
        BYDAY_SUNDAY_FIRST: '每月第一個星期日',

        BYDAY_MONDAY_LAST: '每月最後一個星期一',
        BYDAY_TUESDAY_LAST: '每月最後一個星期二',
        BYDAY_WEDNESDAY_LAST: '每月最後一個星期三',
        BYDAY_THURSDAY_LAST: '每月最後一個星期四',
        BYDAY_FRIDAY_LAST: '每月最後一個星期五',
        BYDAY_SATURDAY_LAST: '每月最後一個星期六',
        BYDAY_SUNDAY_LAST: '每月最後一個星期日',

        SECONDS: '秒',
        MINUTES: '分',
        HOURS: '時',
        DAYS: '日',
        WEEKS: '週',

        JANUARY: '一月',
        FEBRUARY: '二月',
        MARCH: '三月',
        APRIL: '四月',
        MAY: '五月',
        JUNE: '六月',
        JULY: '七月',
        AUGUST: '八月',
        SEPTEMBER: '九月',
        OCTOBER: '十月',
        NOVEMBER: '十一月',
        DECEMBER: '十二月',

        CAL_JANUARY: '一月',
        CAL_FEBRUARY: '二月',
        CAL_MARCH: '三月',
        CAL_APRIL: '四月',
        CAL_MAY: '五月',
        CAL_JUNE: '六月',
        CAL_JULY: '七月',
        CAL_AUGUST: '八月',
        CAL_SEPTEMBER: '九月',
        CAL_OCTOBER: '十月',
        CAL_NOVEMBER: '十一月',
        CAL_DECEMBER: '十二月',
        CAL_SUNDAY: '星期日',
        CAL_MONDAY: '星期一',
        CAL_TUESDAY: '星期二',
        CAL_WEDNESDAY: '星期三',
        CAL_THURSDAY: '星期四',
        CAL_FRIDAY: '星期五',
        CAL_SATURDAY: '星期六',
        CAL_SUNDAY_SHORT: '日',
        CAL_MONDAY_SHORT: '一',
        CAL_TUESDAY_SHORT: '二',
        CAL_WEDNESDAY_SHORT: '三',
        CAL_THURSDAY_SHORT: '四',
        CAL_FRIDAY_SHORT: '五',
        CAL_SATURDAY_SHORT: '六',

        EXPIRESAT: '到期時間於',

        DATE_SHORT: '日日/月月/年年年年'
    });
});
