(function() {
    'use strict';

    angular
        .module('elogbooks.admin.settings')
        .controller('AdminManagePermissionsController', AdminManagePermissionsController);

    AdminManagePermissionsController.$inject = ['apiClient', '$state', 'sitePermissions', 'userPermissions', 'reportPermissions', 'operativePermissions', 'permissionCollection', 'requestDataFactory', 'messenger', 'userManager', 'lodash'];

    function AdminManagePermissionsController(apiClient, $state, sitePermissions, userPermissions, reportPermissions, operativePermissions, permissionCollection, requestDataFactory, messenger, userManager, lodash) {
        var vm = this;
        vm.selectedPermissions = [];
        vm.appliedPermissions = [];
        vm.data = [];
        vm.update = updateAction;
        vm.editable = permissionCollection.getLink('edit') ? true : null;

        vm.permissions = {
            'site': sitePermissions.getAllTranslated(),
            'user': userPermissions.getAllTranslated(),
            'report': reportPermissions.getAllTranslated(),
            'operative': operativePermissions.getAllTranslated()
        };

        vm.data.site = lodash.find(permissionCollection.settings, {'name': 'site_permissions'}).value;
        vm.data.user = lodash.find(permissionCollection.settings, {'name': 'user_permissions'}).value;
        vm.data.report = lodash.find(permissionCollection.settings, {'name': 'report_permissions'}).value;
        vm.data.operative = lodash.find(permissionCollection.settings, {'name': 'operative_permissions'}).value;

        function updateAction (entity) {
            var permissions = {
                'settings': [
                    {
                        'name': entity + '_permissions',
                        'value': vm.data[entity]
                    }
                ]
            };

            return apiClient.update(permissionCollection.getLink('edit'), permissions).then(function (response) {
                if (response) {
                    vm.selectedPermissions[entity] = [];
                    messenger.success((entity + '_PERMISSIONS_UPDATED').toUpperCase());
                } else {
                    $state.reload();
                }
            });
        }
    }
})();