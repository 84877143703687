angular.module('elogbooksDirectives').directive('prosure', [function () {
    return {
        restrict: 'AE',
        scope: {
            prosureStatus: '=',
            reasons: '=',
            position: '='
        },
        templateUrl: '/modules/directives/prosure/prosure-columns.html',
        controller: function ($scope) {
            switch ($scope.prosureStatus) {
                case 'PROSURE_REQUIREMENTS_SUCCESS':
                    $scope.className = 'prosure-meets-requirements';
                    break;
                case 'PROSURE_REQUIREMENTS_EXPIRING':
                    $scope.className = 'prosure-expiring-soon';
                    break;
                case 'PROSURE_REQUIREMENTS_BELOW':
                    $scope.className = 'prosure-does-not-meet-requirements';
                    break;
                case 'PROSURE_SUSPENDED':
                    $scope.className = 'prosure-suspended';
                    break;
                default:
                    $scope.prosureStatus = 'N/A';
                    $scope.className = '';
            }
        }
    }
}]);
