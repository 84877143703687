app.config(function ($translateProvider) {
    $translateProvider.translations('zh-hk', {
        SITE: '場地',
        SITES: '多個場地',

        SITES_LIST: '場地列表',

        ADD_NEW_SITE: '增加新場地',
        EDIT_SITE: '編輯場地',
        VIEW_SITE: '瀏覽場地',

        ADDRESSES: '地址',
        CITY: '城市',
        COUNTY_STATE_PROVINCE_REGION: '縣/區',
        POSTAL_CODE_ZIP: '郵政編碼',
        COUNTRY: '國家',
        CONTACT_INFO_TYPE: '聯絡方法',
        CONTACT_ADDRESS_ADDED: '增加聯絡地址',
        CONTACT_ADDRESS_UPDATED: '更𣂺聯絡地址',
        CONTACT_ADDRESS_DELETED: '刪除聯絡地址',

        MANAGE_SITES: '管理場地',
        ALL_SITES: '全部場地',
        SELECTED_SITES: '已選擇場地',
        CONTACT_INFORMATION: '聯絡資訊',
        SITE_CONTACT: '場地聯絡方法',
        SITE_IMAGE: '場地圖片',

        SITE_MEMBERSHIP: '場地會員',
        SITE_MEMBERSHIPS: '多個場地會員',
        SITE_MEMBERSHIP_LIST: '場地會員列表',
        SITE_MEMBERSHIP_ADD: '增加場地會員',
        SITE_MEMBERSHIP_EDIT: '編輯場地會員',
        SITE_MEMBERSHIP_CREATED: '場地會員建立成功',
        SITE_MEMBERSHIP_UPDATED: '場地會員更新成功',
        SITE_MEMBERSHIP_DELETED: '場地會員已刪除',

        REQUIRE_PURCHASE_ORDER: '需要採購訂單?',
        AREA: '地區',
        UNIT: '單位',

        SITE_CONTACTS: '場地聯絡',
        ADD_NEW_SITE_CONTACT: '增加場地聯絡',
        EDIT_CONTACT: '編輯場地聯絡',

        SITE_MANAGER: '場地主管',
        SITE_MANAGERS: '場地主管',

        SITE_LIST: '多個場地',
        SITE_ADD: '增加新場地',
        SITE_EDIT: '編輯場地',

        SITE_CREATED: '成功建立場地',
        SITE_UPDATED: '成功更新場地',
        SITE_DELETED: '場地已刪除',

        ADD_NEW_SITE_NOTE_TYPE: '增加新場地註釋類型',
        SITE_NOTE_TYPE_TITLE: '標題',
        ALLOW_REQUIRE_FM: '容許標籤為FM批核',
        SITE_NOTE_TYPE: '場地註釋類型',
        SITE_NOTE_TYPES: '場地註釋類型',
        NOT_APPLICABLE: '不適用',
        REQUIRE_FM: '需要FM批核',
        SITE_REQUIRES_FM_APPROVAL: '需要FM批核d',
        NO_DATA: '此欄還未填寫日期',

        SITE_NOTES: '注釋',
        SITE_ACCESS: '訪問場地',
        SEARCH_SITES_BY_NAME: '以名稱搜尋',

        SITE_INFORMATION: '場地資訊',

        ASSOCIATES_UPDATED: '更新場地聯絡人',
        EDIT_ADDRESS: '編輯地址',
        SITE_CONTACT_ADD: '增加場地聯絡方法',
        SITE_CONTACT_EDIT: '編輯場地聯絡方法',
        CONTACT_CREATED: '已建立場地聯絡方法',
        CONTACT_UPDATED: '已更新場地聯絡方法',
        CONTACT_DELETED: '已刪除場地聯絡方法',
        ADD_LOCATION: '增加位置',
        EDIT_LOCATION: '編輯位置',
        SITE_NOTE_UPDATED: '更新場地註釋',
        SITE_NOTE_CREATED: '建立場地註釋',
        SITE_THRESHOLD_DELETED: '已刪除場地下限',
        SITE_THRESHOLD_VALUE_ADDED: '已增加場地下限',
        NO_CONTACT_INFORMATION_ASSIGNED: '沒有指定聯絡資訊',
        SITE_STATUS: '場地狀態',
    });
});
