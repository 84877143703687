(function () {
    'use strict';

    angular
        .module('elogbooks.user.patrols')
        .controller('PatrolInfoController', PatrolInfoController);

    PatrolInfoController.$inject = ['patrolResponse', 'confirmationModal', 'apiClient', '$state', '$translate'];

    function PatrolInfoController (patrolResponse, confirmationModal, apiClient, $state, $translate) {
        var vm = this;
        vm.patrol = patrolResponse;
        vm.cancelPatrol = cancelPatrol;

        vm.reason = '';

        if (vm.patrol.getLink('reason')) {
            vm.reason = vm.patrol.getLinkAttribute('reason', 'title');

            if (vm.patrol.otherReason) {
                vm.reason += ' - ' + vm.patrol.otherReason;
            }
        } else if (vm.patrol.otherReason) {
            vm.reason = $translate.instant('OTHER') + ' - ' + vm.patrol.otherReason;
        }

        function cancelPatrol() {
            var modal = confirmationModal.open();

            modal.result.then(function(response) {
                apiClient.create(vm.patrol.getLink('cancel'), {}).then(function() {
                    $state.reload();
                })
            });
        }
    }
})();
