app.config(function ($translateProvider) {
    $translateProvider.translations('nl', {


        SITE_PERMISSION_EDIT_KPIS:	'Wijzig Kpis',
        SITE_PERMISSION_VIEW_FILES:	'Bekijk bestanden',
        SITE_PERMISSION_EDIT_FILES:	'Wijzig bestanden',
        SITE_PERMISSION_VIEW_NOTES:	'Bekijk notities ',
        SITE_PERMISSION_EDIT_NOTES:	'Wijzig notities',

        SITE_PERMISSION: 'Object authorisatie',
        SITE_PERMISSION_CAN_BE_APPROVER: 'Kan goedkeuren',
        SITE_PERMISSION_CAN_BE_ASSOCIATE: 'Kan een objectpartner zijn',
        SITE_PERMISSION_CREATE_JOBS: 'Opdrachten maken',
        SITE_PERMISSION_CREATE_ACTION: 'Acties maken',
        SITE_PERMISSION_CREATE_ASSETS: 'Gebouwassets maken',
        SITE_PERMISSION_CREATE_AUDIT_TEMPLATES: 'Maak audit sjabloon',
        SITE_PERMISSION_CREATE_AUDITS: 'Maak audit',
        SITE_PERMISSION_CREATE_KPIS: 'Maak Kpis',
        SITE_PERMISSION_CREATE_QUOTES: 'Offertes maken',
        SITE_PERMISSION_CREATE_TASKS: 'Taken maken',
        SITE_PERMISSION_CREATE_ACTIONS: 'Acties maken',
        SITE_PERMISSION_EDIT: 'Wijzig object',
        SITE_PERMISSION_EDIT_ACTIONS: 'Wijzig acties',
        SITE_PERMISSION_EDIT_APPROVERS: 'Wijzig Fiatteurs',
        SITE_PERMISSION_EDIT_ASSETS: 'Wijzig assets',
        SITE_PERMISSION_EDIT_ASSOCIATES: 'Wijzig objectpartners',
        SITE_PERMISSION_EDIT_CONTACTS: 'Wijzig contacten',
        SITE_PERMISSION_EDIT_CONTRACTS: 'Wijzig contracten',
        SITE_PERMISSION_EDIT_JOBS: 'Wijzig opdrachten',
        SITE_PERMISSION_EDIT_LOCATIONS: 'Wijzig locatie',
        SITE_PERMISSION_EDIT_METER_READINGS: 'Wijzig meteropname',
        SITE_PERMISSION_EDIT_METERINFO: 'Wijzig informatie meters',
        SITE_PERMISSION_EDIT_METERS: 'Wijzig Meters',
        SITE_PERMISSION_EDIT_PARTNERSHIPS: 'Wijzig samenwerkingen',
        SITE_PERMISSION_EDIT_PATROL_INSTANCES: 'Wijzig patrol voorwaarden',
        SITE_PERMISSION_EDIT_PRIORITIES: 'Wijzig prioriteiten',
        SITE_PERMISSION_EDIT_QUOTES: 'Wijzig offertes',
        SITE_PERMISSION_EDIT_TASKS: 'Wijzig taken',
        SITE_PERMISSION_EDIT_TENANTS: 'Wijzig huurders',
        SITE_PERMISSION_HELPDESK: 'Servicedesk',
        SITE_PERMISSION_MANAGE_PATROLS: 'Beheer Patrols',
        SITE_PERMISSION_TENANT: 'Huurder',
        SITE_PERMISSION_VIEW: 'Bekijk objecten',
        SITE_PERMISSION_VIEW_AUDITS: 'Bekijk Audits',
        SITE_PERMISSION_VIEW_KPIS: 'bekijk Kpis',
        SITE_PERMISSION_VIEW_PATROLS: 'Bekijk Patrols',
        SITE_PERMISSION_VIEW_ACTIONS: 'Bekijk Actions',
        SITE_PERMISSION_VIEW_APPROVERS: 'Bekijk Fiatteurs',
        SITE_PERMISSION_VIEW_ASSETS: 'Bekijk Assets',
        SITE_PERMISSION_VIEW_ASSOCIATES: 'Bekijk Associates',
        SITE_PERMISSION_VIEW_CONTACTS: 'Bekijk Contacten',
        SITE_PERMISSION_VIEW_CONTRACTS: 'Bekijk Contracten',
        SITE_PERMISSION_VIEW_JOBS: 'Bekijk opdrachten',
        SITE_PERMISSION_VIEW_LOCATIONS: 'Bekijk locaties',
        SITE_PERMISSION_VIEW_METERINFO: 'Bekijk informatie meters',
        SITE_PERMISSION_VIEW_METERS: 'Bekijk Meters',
        SITE_PERMISSION_VIEW_PARTNERSHIPS: 'Bekijk samenwerkingen',
        SITE_PERMISSION_VIEW_PATROL_INSTANCES: 'Bekijk Patrol voorwaarden',
        SITE_PERMISSION_VIEW_PRIORITIES: 'Bekijk prioriteiten',
        SITE_PERMISSION_VIEW_QUOTES: 'Bekijk offertes',
        SITE_PERMISSION_VIEW_TASKS: 'Bekijk taken',
        SITE_PERMISSION_VIEW_TENANTS: 'Bekijk huurders',
        SITE_PERMISSION_VIEW_SURVEYS: 'Bekijk Checklisten',
        SITE_PERMISSION_EDIT_SURVEYS: 'Wijzig checklisten',
        SITE_PERMISSION_CREATE_SURVEYS: 'Maak checklisten',
        SITE_PERMISSION_VIEW_SURVEY_SCHEDULES: 'Bekijk geplande checklisten',
        SITE_PERMISSION_EDIT_SURVEY_SCHEDULES: 'Wijzig planning checklisten',
        SITE_PERMISSION_CREATE_SURVEY_SCHEDULES: 'Maak nieuwe checklist planning',
        SITE_PERMISSION_FORCE_COMPLETE_AUDITS: 'Force Complete Audits',
        SITE_PERMISSION_ASSET_USER_IMPORTS: 'Import Assets',
        SITE_PERMISSION_TASK_USER_IMPORTS: 'Import Tasks',
        SITE_PERMISSION_ASSET_TASK_USER_IMPORTS: 'Planner Import',

        SITE_PERMISSION_VIEW_FILES_DETAIL:	' ',
        SITE_PERMISSION_EDIT_FILES_DETAIL:	' ',
        SITE_PERMISSION_VIEW_NOTES_DETAIL:	' ',
        SITE_PERMISSION_EDIT_NOTES_DETAIL:	' ',

        SITE_PERMISSION_CAN_BE_APPROVER_DETAIL: 'Kan goedkeuren',
        SITE_PERMISSION_CAN_BE_ASSOCIATE_DETAIL: 'Kan objectpartner zijn',
        SITE_PERMISSION_CREATE_JOBS_DETAIL: 'Maken opdrachten',
        SITE_PERMISSION_CREATE_ACTION_DETAIL: 'Maken Actie',
        SITE_PERMISSION_CREATE_ASSETS_DETAIL: 'Maken Assets',
        SITE_PERMISSION_CREATE_AUDIT_TEMPLATES_DETAIL: 'Maken Audit Templates',
        SITE_PERMISSION_CREATE_AUDITS_DETAIL: 'Maken Audits',
        SITE_PERMISSION_CREATE_KPIS_DETAIL: 'Maken Kpis',
        SITE_PERMISSION_CREATE_QUOTES_DETAIL: 'Maken offertes',
        SITE_PERMISSION_CREATE_TASKS_DETAIL: 'Maken taken',
        SITE_PERMISSION_CREATE_ACTIONS_DETAIL: 'Maken Acties',
        SITE_PERMISSION_EDIT_DETAIL: 'Wijzigen object',
        SITE_PERMISSION_EDIT_ACTIONS_DETAIL: 'Wijzigen acties',
        SITE_PERMISSION_EDIT_APPROVERS_DETAIL: 'Wijzigen Fiatteurs',
        SITE_PERMISSION_EDIT_ASSETS_DETAIL: 'Wijzigen Assets',
        SITE_PERMISSION_EDIT_ASSOCIATES_DETAIL: 'Wijzigen objectpartners',
        SITE_PERMISSION_EDIT_CONTACTS_DETAIL: 'Wijzigen contacten',
        SITE_PERMISSION_EDIT_CONTRACTS_DETAIL: 'Wijzigen contracten',
        SITE_PERMISSION_EDIT_JOBS_DETAIL: 'Wijzigen opdrachten',
        SITE_PERMISSION_EDIT_LOCATIONS_DETAIL: 'Wijzigen locaties',
        SITE_PERMISSION_EDIT_METER_READINGS_DETAIL: 'Wijzigen meteropname',
        SITE_PERMISSION_EDIT_METERINFO_DETAIL: 'Wijzigen meterinformatie',
        SITE_PERMISSION_EDIT_METERS_DETAIL: 'Wijzigen Meters',
        SITE_PERMISSION_EDIT_PARTNERSHIPS_DETAIL: 'Wijzigen Samenwerkingen',
        SITE_PERMISSION_EDIT_PATROL_INSTANCES_DETAIL: 'Wijzigen Patrol voorwaarden',
        SITE_PERMISSION_EDIT_PRIORITIES_DETAIL: 'Wijzigen prioriteiten',
        SITE_PERMISSION_EDIT_QUOTES_DETAIL: 'Wijzigen offertes',
        SITE_PERMISSION_EDIT_TASKS_DETAIL: 'Wijzigen taken',
        SITE_PERMISSION_EDIT_TENANTS_DETAIL: 'Wijzigen huurders',
        SITE_PERMISSION_HELPDESK_DETAIL: 'Servicedesk',
        SITE_PERMISSION_MANAGE_PATROLS_DETAIL: 'Beheer Patrols',
        SITE_PERMISSION_TENANT_DETAIL: 'Huurder',
        SITE_PERMISSION_TENANT_PLUS_DETAIL: 'Huurder plus',
        SITE_PERMISSION_VIEW_DETAIL: 'Bekijken Objecten',
        SITE_PERMISSION_VIEW_AUDITS_DETAIL: 'Bekijken Audits',
        SITE_PERMISSION_VIEW_KPIS_DETAIL: 'Bekijken Kpis',
        SITE_PERMISSION_VIEW_PATROLS_DETAIL: 'Bekijken Patrols',
        SITE_PERMISSION_VIEW_ACTIONS_DETAIL: 'Bekijken Acties',
        SITE_PERMISSION_VIEW_APPROVERS_DETAIL: 'Bekijken Fiateurs',
        SITE_PERMISSION_VIEW_ASSETS_DETAIL: 'Bekijken Assets',
        SITE_PERMISSION_VIEW_ASSOCIATES_DETAIL: 'Bekijken partners',
        SITE_PERMISSION_VIEW_CONTACTS_DETAIL: 'Bekijken Contacten',
        SITE_PERMISSION_VIEW_CONTRACTS_DETAIL: 'Bekijken Contracten',
        SITE_PERMISSION_VIEW_JOBS_DETAIL: 'Bekijken opdrachten',
        SITE_PERMISSION_VIEW_LOCATIONS_DETAIL: 'Bekijken locaties',
        SITE_PERMISSION_VIEW_METERINFO_DETAIL: 'Bekijken Meter Informatie',
        SITE_PERMISSION_VIEW_METERS_DETAIL: 'Bekijken Meters',
        SITE_PERMISSION_VIEW_PARTNERSHIPS_DETAIL: 'Bekijken Samenwerkingen',
        SITE_PERMISSION_VIEW_PATROL_INSTANCES_DETAIL: 'Bekijken Patrol Instances',
        SITE_PERMISSION_VIEW_PRIORITIES_DETAIL: 'Bekijken priotiteiten',
        SITE_PERMISSION_VIEW_QUOTES_DETAIL: 'Bekijken offertes',
        SITE_PERMISSION_VIEW_TASKS_DETAIL: 'Bekijken Taken',
        SITE_PERMISSION_VIEW_TENANTS_DETAIL: 'Bekijken huurders',
        SITE_PERMISSION_VIEW_SURVEYS_DETAIL: 'Bekijken checklists',
        SITE_PERMISSION_EDIT_SURVEYS_DETAIL: 'Wijzigen checklists',
        SITE_PERMISSION_CREATE_SURVEYS_DETAIL: 'Maken checklists',
        SITE_PERMISSION_VIEW_SURVEY_SCHEDULES_DETAIL: 'Bekijen checklist planning',
        SITE_PERMISSION_EDIT_SURVEY_SCHEDULES_DETAIL: 'Wijzigen checklist planning',
        SITE_PERMISSION_CREATE_SURVEY_SCHEDULES_DETAIL: 'Maken checklist planning',

        SITE_PERMISSION_EMAIL_TENANTS: 'E-mail huurder optie',
        SITE_PERMISSION_EMAIL_TENANTS_DETAIL: 'Kan optie om huurders e-mail te sturen gebruiken',
        SITE_PERMISSION_FORCE_COMPLETE_AUDITS_DETAIL: 'Forceren om af te sluiten zonder volledig antwoord',

        SITE_PERMISSION_P2P: 'P2P - Proactis',
        SITE_PERMISSION_P2P_DETAIL: '',

        SITE_PERMISSION_ASSET_USER_IMPORTS_DETAIL: 'Import Assets',
        SITE_PERMISSION_TASK_USER_IMPORTS_DETAIL: 'Import Tasks',
        SITE_PERMISSION_ASSET_TASK_USER_IMPORTS_DETAIL: 'The ability to import tasks and assets',

        SITE_PERMISSION_JOB_OPERATIVE: 'Bekijk Uitvoerende leverancier',
        SITE_PERMISSION_JOB_OPERATIVE_DETAIL: 'Mogelijkheid om een Uitvoerende aan te wijzen voor een opdracht',

        SITE_PERMISSION_BULK_REASSIGN_JOBS: 'Bulk Reassign Jobs',
        SITE_PERMISSION_BULK_REASSIGN_JOBS_DETAIL: 'Ability to bulk reassign jobs.',
        SITE_PERMISSION_BULK_REASSIGN_OPERATIVE_JOBS: 'Bulk Reassign Job Operatives',
        SITE_PERMISSION_BULK_REASSIGN_OPERATIVE_JOBS_DETAIL: 'Ability to bulk reassign job operatives.',
        SITE_PERMISSION_BULK_CANCEL_JOBS: 'Bulk Cancel Jobs',
        SITE_PERMISSION_BULK_CANCEL_JOBS_DETAIL: 'Ability to bulk cancel jobs.',

        USER_STILL_AN_APPROVER: 'Cannot remove "Can Be Approver". This user is still an approver for at least one site.',
        AT_LEAST_ONE_USER_STILL_AN_APPROVER: 'At least one user is still an approver for a site.',
        CANNOT_REMOVE_APPROVER_PERMISSION: 'Cannot remove approver permission. User(s) with this role are currently approvers.',

        SITE_PERMISSION_VIEW_AUDIT_TEMPLATES: 'View Audit Templates',
        SITE_PERMISSION_CREATE_PUBLIC_AUDIT_TEMPLATES: 'Create Public Audit Templates',
        SITE_PERMISSION_VIEW_AUDIT_SCHEDULES: 'View Audit Schedules',
        SITE_PERMISSION_CREATE_AUDIT_SCHEDULES: 'Create Audit Schedules',

        SITE_PERMISSION_VIEW_AUDIT_TEMPLATES_DETAIL: 'View Audit Templates',
        SITE_PERMISSION_CREATE_PUBLIC_AUDIT_TEMPLATES_DETAIL: 'Create Public Audit Templates',
        SITE_PERMISSION_VIEW_AUDIT_SCHEDULES_DETAIL: 'View Audit Schedules',
        SITE_PERMISSION_CREATE_AUDIT_SCHEDULES_DETAIL: 'Create Audit Schedules',

        SITE_PERMISSION_EDIT_METER_REFERENCES: 'Edit Meter References',
        SITE_PERMISSION_EDIT_METER_REFERENCES_DETAIL: 'Edit Meter References',

        SITE_PERMISSION_WRITE_NFC_TAGS: 'Write NFC Tags',
        SITE_PERMISSION_WRITE_NFC_TAGS_DETAIL: 'Write NFC Tags',

        SITE_PERMISSION_ACTIVATE_SURVEY: 'Activate KPI Survey',
        SITE_PERMISSION_ACTIVATE_SURVEY_DETAIL: 'Activate KPI Survey',

        SITE_PERMISSION_NOTIFY_UPDATES: 'Notify Job/Quote Contacts',
        SITE_PERMISSION_NOTIFY_UPDATES_DETAIL: 'Notify Job/Quote Contacts of Updates'







    });
});
