(function() {
    'use strict';

    angular
        .module('elogbooks.common.form-associations')
        .controller('CommonFormAssociationsDetailsController', CommonFormAssociationsDetailsController);

    CommonFormAssociationsDetailsController.inject = [
        'formAssociationsResponse',
        'formVersionResponse',
        'associatedEntities',
        'config',
        'lodash'
    ];

    function CommonFormAssociationsDetailsController (
        formAssociationsResponse,
        formVersionResponse,
        associatedEntities,
        config,
        lodash
    ) {
        var vm = this;
        vm.formAssociationsResponse = formAssociationsResponse;
        vm.associatedEntities = associatedEntities;
        vm.formVersionResponse = formVersionResponse;
        vm.config = config;

        vm.data = angular.extend({}, vm.formVersionResponse.data);
        angular.forEach(vm.formAssociationsResponse.data, function(value, index) {
            var data = lodash.find(vm.data.components, {id: value.id});
            data.answer = value.answer;
            data.notApplicable = value.notApplicable;
        });
    }
})();
