app.config(function ($translateProvider) {
    $translateProvider.translations('en-gb', {

        APPROVER: 'Approver',
        APPROVERS: 'Approvers',
        APPROVED_BY: 'Approved By',

        APPROVERS_UPDATED: 'Approvers updated',

        APPROVAL_REQUIRED: 'Approval Required',
        APPROVER_REQUIRED: 'An approver should be added to this list as approval is required',
        APPROVER_NOT_ADDED: 'No approvers have been added',
        APPROVER_ADDED_TO_ALL_APPROVER_TYPES: 'Approver added to all Approver types for this site',
        APPROVER_REMOVED_FROM_ALL_APPROVER_TYPES: 'Approver removed from all Approver types for this site',
        USER_ALREADY_APPROVER: 'User is already assigned as an Approver for this type for this site',

        ADD_REMOVE_USER_ALL_APPROVER_TYPES: 'Add or Remove User from all Approver types',

        REACTIVE_JOB_APPROVER: 'Reactive Approver',
        REACTIVE_JOB_APPROVERS: 'Reactive Job',
        REACTIVE_JOB_EXTENSIONS_APPROVERS: 'Reactive Job Extensions',
        PLANNED_JOB_APPROVER: 'Planner Approver',
        PLANNED_JOB_APPROVERS: 'PPM',
        PLANNED_JOB_EXTENSIONS_APPROVERS: 'PPM Extensions',
        JOB_EXTENSION_APPROVER: 'Job Extension Approver',
        JOB_EXTENSION_APPROVERS: 'Job Extension',
        QUOTE_APPROVER: 'Quote Approver',
        QUOTE_APPROVERS: 'Quote',
        CONTRACT_APPROVER: 'Contract Approver',
        CONTRACT_APPROVERS: 'Contract',
        SURVEY_APPROVER: 'Survey Approver',
        SURVEY_APPROVERS: 'Survey',
        EXTENSION_APPROVER: 'Extension Approver',

        APPROVER_ADDED_BUT_NOT_REQUIRED: 'An approver has been added, but the Approval Required option has not been ticked.',

        SITE_APPROVER_LIST: 'Site Approvers',

        USER_ALREADY_CONTRACTMANAGERAPPROVERS: 'User is already assigned as a Contract Manager Approver',
        USER_ALREADY_CLIENTAPPROVERS: 'User is already assigned as a Client Approver',
    });
});
