/**
 * taskParamsService
 */
 angular.module('elogbooksServices').service('taskParamsService', function () {

    this.getTaskParams = getTaskParams;

    /**
     * Format state params into expected task filter params for api
     *
     * @param {object} $stateParams - State params
     * @returns {object} params
     */
     function getTaskParams($stateParams) {

        var originalParams = angular.copy($stateParams);
        delete originalParams.status;

        var params = angular.extend(
            {},
            originalParams,
            {
                'siteIds[]': $stateParams.siteIds,
                'statutoryType[]': $stateParams.taskStatutoryType,
                'regionId': $stateParams.regionId ? JSON.parse($stateParams.regionId).v : null,
                'assetId': $stateParams.taskAssetId ? JSON.parse($stateParams.taskAssetId).v : null,
                'year': $stateParams.taskYear,
                'draftMode': $stateParams.draftMode,
                'serviceType': $stateParams.taskServiceType,
                'status': $stateParams.taskStatus,
                'operativeName': $stateParams.taskOperativeName,
                'serviceProviderName': $stateParams.taskServiceProviderName,
                'frequency': $stateParams.taskFrequency,
                'approvalStatus': $stateParams.taskApprovalStatus,
                'masterTask': $stateParams.taskMasterTask,
                'order': $stateParams.taskOrder,
                'approved': $stateParams.taskApproved,
                'name': $stateParams.taskName,
                'page': $stateParams.taskPage,
                'limit': $stateParams.taskLimit,
                'documentTypes[]': $stateParams.documentTypes,
            }
        );

        return params;
    }
});
