app.config(function ($translateProvider) {
    $translateProvider.translations('nl', {
        CERTIFICATE_TYPES: 'Certificaat types',
        CERTIFICATE_TYPE: 'Certificaat type',

        CERTIFICATE_TYPE_LIST: 'Certificaat types',
        CERTIFICATE_TYPE_ADD: 'Voeg een nieuw Certificaat Type toe',
        CERTIFICATE_TYPE_EDIT: 'Pas Certificaat Type aan',

        CERTIFICATE_TYPE_CREATED: 'Certificaat Type aangemaakt',
        CERTIFICATE_TYPE_UPDATED: 'Certificaat Type aangepast',
        CERTIFICATE_TYPE_DELETED: 'Certificaat Type verwijderd',

    });
});
