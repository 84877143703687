(function() {
    'use strict';

    angular
        .module('elogbooks.common.assets')
        .controller('UserAssetsListController',  UserAssetsListController);

    UserAssetsListController.$inject = [
        '$state',
        '$stateParams',
        'assetCollectionResponse',
        'sectionResource',
        'assetTypesCollectionResponse',
        'serviceProviderCollectionResponse',
        'serviceRoutineCollectionResponse',
        'siteCollectionResponse',
        '$translate',
        'assetScoreTypeCollectionResponse',
        'elbSettings',
        'lodash',
        'serviceRoutineService'
    ];

    function  UserAssetsListController(
        $state,
        $stateParams,
        assetCollectionResponse,
        sectionResource,
        assetTypesCollectionResponse,
        serviceProviderCollectionResponse,
        serviceRoutineCollectionResponse,
        siteCollectionResponse,
        $translate,
        assetScoreTypeCollectionResponse,
        elbSettings,
        lodash,
        serviceRoutineService
    ) {
        var vm = this;
        vm.assetCollectionResponse = assetCollectionResponse;
        vm.pages = vm.assetCollectionResponse.pages;
        vm.assets = vm.assetCollectionResponse.assets;
        vm.sectionResource = sectionResource;
        vm.getHistoricalScoreIndex = getHistoricalScoreIndex;
        vm.formatHistoricalScore = formatHistoricalScore;
        var settings = elbSettings.getSetting('default_asset_score_types').value;

        vm.scoreTypes = assetScoreTypeCollectionResponse.scoreTypes.filter(function(item) {
            return lodash.find(settings, ['name', item.name]);
        });

        vm.criteria = {};
        vm.criteria.id = {type: 'number', title: 'ID', value: $stateParams.id ? parseInt($stateParams.id, 10) : null, min: 1},
        vm.criteria.name = {
            type: 'text',
            title: 'NAME',
            value: $stateParams.name
        };

        if (!sectionResource.isSite && siteCollectionResponse) {
            vm.siteSelect = {
                response : siteCollectionResponse,
                link : siteCollectionResponse.getLink('self'),
                responseKeyPath: 'sites',
                itemValuePath: 'name',
                itemHrefPath: 'id'
            };

            vm.criteria.siteIds = {
                type: 'autocompletemulti',
                multiple: true,
                title: 'SITE',
                value: $stateParams.siteIds,
                options: vm.siteSelect ? vm.siteSelect : null,
                param: 'siteIds',
                max: 50
            };
        }

        if (assetTypesCollectionResponse) {
            vm.assetTypesSelect = {
                response: assetTypesCollectionResponse,
                link: assetTypesCollectionResponse.getLink('self'),
                responseKeyPath: 'assettypes',
                itemValuePath: 'hierarchicalPath',
                itemHrefPath: 'id'
            };

            vm.criteria.assetType = {
                type: 'autocompletemulti',
                multiple: true,
                title: 'ASSET_TYPE',
                value: $stateParams.assetType,
                options: vm.assetTypesSelect,
                param: 'assetType',
                max: 50
            };
        }

        function formatHistoricalScore(scoreType, hscores)
        {
            return hscores[getHistoricalScoreIndex(scoreType, hscores)].score + ' - '
             + hscores[getHistoricalScoreIndex(scoreType, hscores)].summary;
        }

        function getSelectedScoreType(id) {
            return assetScoreTypeCollectionResponse.scoreTypes.filter(function (item) {
                return item.id === id;
            }).map(function(item) {
                return {
                    title: item.name,
                    href: item._links.self.href,
                    object: item
                };
            });
        }

        function getHistoricalScoreIndex(scoreType, hscores)
        {
            return lodash.findKey(hscores, ['scoreType', scoreType.name]);
        }

        if (assetScoreTypeCollectionResponse) {
            var assetScoreTypesSelect = {
                response: assetScoreTypeCollectionResponse,
                link: assetScoreTypeCollectionResponse.getLink('self'),
                responseKeyPath: 'scoreTypes',
                itemHrefPath: '_links.self.href',
                itemValuePath: 'id',
                itemTitlePath: 'name',
                selected: $stateParams.assetScore ? getSelectedScoreType(JSON.parse($stateParams.assetScore).st.i)[0] : null,
                scoreOptions : {
                    response: null,
                    link: null,
                    responseKeyPath: 'options',
                    itemValuePath: 'summary',
                    itemHrefPath: 'id',
                    selectedItems: $stateParams.assetScore &&  JSON.parse($stateParams.assetScore).so ? JSON.parse($stateParams.assetScore).so.o: []
                }
            };

            vm.criteria.assetScore = {
                type: 'assetScore',
                title: 'ASSET_SCORE',
                options: assetScoreTypesSelect,
                value: $stateParams.assetScore,
                param: 'assetScore'
            };
        }

        if (serviceProviderCollectionResponse) {
            vm.serviceProviderSelect = {
                response : serviceProviderCollectionResponse,
                link : serviceProviderCollectionResponse.getLink('self'),
                responseKeyPath: 'serviceproviders',
                itemValuePath: 'id',
                itemTitlePath: 'name'
            };

            vm.criteria.serviceProvider = {
                type: 'jsonselectwidget',
                title: 'SERVICE_PROVIDER',
                value: JSON.parse($stateParams.serviceProvider),
                options: vm.serviceProviderSelect,
                param: 'serviceProvider'
            };
        }

        if (serviceRoutineCollectionResponse) {
            vm.serviceRoutineSelect = {
                response : serviceRoutineCollectionResponse,
                link : serviceRoutineCollectionResponse.getLink('self'),
                responseKeyPath: 'routines',
                itemValuePath: 'id',
                itemTitlePath: 'name',
                searchKey: 'nameReference',
                formatValue: serviceRoutineService.formatServiceRoutineValue
            };

            vm.criteria.serviceRoutine = {
                type: 'jsonselectwidget',
                title: 'SERVICE_ROUTINE',
                value: JSON.parse($stateParams.serviceRoutine),
                options: vm.serviceRoutineSelect,
                param: 'serviceRoutine'
            };
        }

        vm.criteria.active = {
            type: 'options',
            title: 'IS_ACTIVE',
            value: $stateParams.active,
            options: [
                {title: $translate.instant('STATUS_ALL'), value: 'all'},
                {title: $translate.instant('STATUS_ACTIVE'), value: '1'},
                {title: $translate.instant('STATUS_INACTIVE'), value: '0'}
            ],
            param: 'active'
        };

        vm.criteria.reference = {
            type: 'text',
            title: 'REFERENCE',
            value: $stateParams.reference
        };

        vm.search = searchAction;
        vm.order = orderAction;
        vm.getPaginationColspan = getPaginationColspan;

        function searchAction(params) {
            var override = {
                assetPage: 1
            };

            $state.go('.', angular.extend({}, params, override), { reload: $state.current });
        }

        function orderAction(key) {
            $stateParams.assetOrder = key;

            $state.go('.', angular.extend({}, $stateParams), { reload: $state.current });
        }

        function getPaginationColspan() {
            var scoreTypes = vm.scoreTypes.length >= 3 ? 3 : vm.scoreTypes.length;

            return 4 + !vm.sectionResource.isSite + vm.scoreTypes.length + scoreTypes;
        }

    }
})();
