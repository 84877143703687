(function () {
    'use strict';

    angular
        .module('elogbooks.admin.roles')
        .controller('RoleAddEditController', RoleAddEditController);

    RoleAddEditController.$inject = ['roleResponse', 'apiClient', 'messenger', 'roleCollectionResponse', '$state', 'confirmationModal', 'elbSettings'];

    function RoleAddEditController(roleResponse, apiClient, messenger, roleCollectionResponse, $state,  confirmationModal, elbSettings) {
        var vm = this;
            vm.role = roleResponse;

        vm.create = createAction;
        vm.update = updateAction;
        vm.delete = deleteAction;
        vm.cancel = cancelAction;
        vm.clientAdminUserModuleEnabled =  elbSettings.getSetting('client_admin_user_enabled') ? elbSettings.getSetting('client_admin_user_enabled').value : false;

       function createAction () {
            return apiClient.create(roleCollectionResponse.getLink('create'), angular.copy(vm.role)).then(function (response) {
                if (response) {
                    $state.go('dashboard.admin.roles.details.info', {resource: response.getLink('self').encode()}, { reload: $state.current.parent }).then(function () {
                        messenger.success('ROLE_CREATED');
                    });
                } else {
                    messenger.error('REQUEST_ERROR');
                }
            });
        }

        function updateAction () {
            return apiClient.update(vm.role.getLink('edit'), angular.copy(vm.role)).then(function (response) {
                if (response) {
                    $state.go('^', {resource: vm.role.getLink('self').encode()}, { reload: 'dashboard.admin.roles.list' }).then(function () {
                        messenger.success('ROLE_UPDATED');
                    });
                } else {
                    messenger.error('REQUEST_ERROR');
                }
            });
        }

        function deleteAction () {
            return confirmationModal.open().result.then(function () {
                return apiClient.delete(vm.role.getLink('delete')).then(function (response) {
                    if (response) {
                        $state.go('dashboard.admin.roles.list', {}, { reload: 'dashboard.admin.roles.list' }).then(function () {
                            messenger.success('ROLE_DELETED');
                        });
                    } else {
                        messenger.error('REQUEST_ERROR');
                    }
                });
            })
        }

        function cancelAction() {
            $state.go('^', {resource: vm.role.getLink ? vm.role.getLink('self').encode() : null}, { reload: 'dashboard.admin.roles.list' });
        }
    }
})();
