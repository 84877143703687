(function () {
    'use strict';

    angular
        .module('elogbooks.admin.settings')
        .controller('AdminEditJobExtraSettingsController', AdminEditJobExtraSettingsController);

    function AdminEditJobExtraSettingsController(
        $scope,
        apiClient,
        messenger,
        jobExtraSettings,
        $translate,
        lodash,
        siteAssociateTypesResponse,
        documentTypeResponse,
        elbSettings
    ) {
        var vm = this;
            vm.settings = jobExtraSettings;
            vm.settings.id = 1;
            vm.update = updateAction;
            vm.siteAssociateTypesResponse = siteAssociateTypesResponse;
            vm.p2pEnabled = elbSettings.getSetting('p2p_enabled').value || elbSettings.getSetting('hexagon_p2p_enabled').value;
            vm.settings.showDocumentType = vm.settings.requireDocumentTypeWhenRaisingReactiveJob.value ? true : false;
            vm.selectedDocumentType = vm.settings.requireDocumentTypeWhenRaisingReactiveJob.value ? vm.settings.requireDocumentTypeWhenRaisingReactiveJob : null;
            vm.data = {};

        vm.extensionOptions = [
            {
                value: 'autoApprove',
                title: $translate.instant('AUTO_APPROVE')
            },
            {
                value: 'off',
                title: $translate.instant('OFF')
            },
            {
                value: 'on',
                title: $translate.instant('ON')
            }
        ];

        if (vm.settings.timeDelay === undefined) {
            vm.settings.timeDelay = 2;
        }

        vm.jobNotifySiteAssociateTypeModel = {
            response: siteAssociateTypesResponse,
            link: siteAssociateTypesResponse ? siteAssociateTypesResponse.getLink('self') : null,
            required: false,
            responseKeyPath: 'siteAssociateTypes',
            itemHrefPath: '_links.self.href',
            selected : lodash.get(vm.settings,'_links.jobNotifySiteAssociateType',null),
            onSelect: changeJobNotifySiteAssociateType,
            onRemove: changeJobNotifySiteAssociateType
        };

        vm.documentTypeModel = {
            response: documentTypeResponse,
            link: documentTypeResponse ? documentTypeResponse.getLink('self') : null,
            required: true,
            loadmoreEnabled: true,
            linkParameters: { status: 'active' },
            responseKeyPath: 'documentTypes',
            itemHrefPath: '_links.self.href',
            selected: vm.selectedDocumentType,
            onSelect: changeDocumentType,
            onRemove: changeDocumentType
        };

        $scope.$watch('vm.settings.showDocumentType', function(newVal, oldVal) {
            if(!newVal && oldVal) {
                vm.documentTypeModel.selected = null;
            }
        });

        function changeDocumentType(documentTypeModel) {
            if (documentTypeModel.selected) {
                vm.settings.requireDocumentTypeWhenRaisingReactiveJob = {
                    title: documentTypeModel.selected.title,
                    name: documentTypeModel.selected.title,
                    value: documentTypeModel.selected.value,
                    object: documentTypeModel.selected.object,
                    id: documentTypeModel.selected.object.id,
                    _links: documentTypeModel.selected.object._links
                };
            } else {
                vm.selectedDocumentType = null;
            }
        }

        function changeJobNotifySiteAssociateType(jobNotifySiteAssociateTypeModel) {
            if (jobNotifySiteAssociateTypeModel.selected ) {
                vm.settings._links.jobNotifySiteAssociateType = { href: jobNotifySiteAssociateTypeModel.selected.href };
            } else {
                delete vm.settings._links.jobNotifySiteAssociateType;
            }
        }

        function updateAction() {
            if (vm.settings.requireJobGroup === false) {
                vm.settings.jobGroupChildSelectMandatory = false;
            }

            if (!vm.settings.showDocumentType) {
                vm.settings.requireDocumentTypeWhenRaisingReactiveJob = {};
            }
            // Copy the data before adjusting so UI is not affected
            vm.data = lodash.clone(vm.settings);

            switch (vm.data.jobCompletionDueSlaExtension){
                case 'off':
                    vm.data.jobCompletionDueSlaExtension = 0;
                break;
                case 'on':
                    vm.data.jobCompletionDueSlaExtension = -1;
                break;
                default:
                    vm.data.jobCompletionDueSlaExtension = vm.settings.timeDelay;
            }

            apiClient.update(jobExtraSettings.getLink('edit'), vm.data).then(function (response) {
                if (response) {
                    $scope.$state.go('^', {}, { reload: true }).then(function () {
                        messenger.success('SETTINGS_UPDATED');
                    });
                } else {
                    messenger.error('REQUEST_ERROR');
                }
            });
        }
    }
})();

