(function() {
    'use strict';

    angular
        .module('elogbooks')
        .config(Translations);

    function Translations($translateProvider) {
        $translateProvider.translations('zh-hk', {
            UPDATES_DUE: '更新到期',
            REACTIVE_UPDATES_DUE: '重開工作',
            PLANNED_UPDATES_DUE: '計劃工作',
            QUOTE_UPDATES_DUE: '報價'
        });
    }
})();
