(function () {
    'use strict';

    angular
        .module('elogbooks.user.absence-planners')
        .controller('AbsenceEntryInfoController', AbsenceEntryInfoController);

    AbsenceEntryInfoController.$inject = ['absenceEntryResponse'];

    function AbsenceEntryInfoController (absenceEntryResponse) {
        var vm = this;

        vm.absenceEntryResponse = absenceEntryResponse;
    }
})();
