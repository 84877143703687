angular.module('elogbooksDirectives').directive('elbTooltipButton', function () {
    return {
        restrict: 'E',
        replace: true,
        transclude: true,
        templateUrl: '/modules/directives/elb-tooltip-button/tooltip-button.html',
        scope: {
            disableTooltip: '<',
            tooltipMessage: '@'
        }
    };
});