(function () {
    'use strict';

    angular
        .module('elogbooks.admin.pcd-imports')
        .controller('PCDImportController', ['$state', 'apiClient', 'messenger', 'rootResourceResponse', 'elbDownloadService', 'lodash', PCDImportController]);

    function PCDImportController($state, apiClient, messenger, rootResourceResponse, elbDownloadService, lodash) {
        var vm = this;
        vm.importFile = null;
        vm.import = {
            _links: {
                file: undefined
            },
            testRun: true,
            params: {
                updateSiteContacts: false,
                updateSite: false,
                updateSiteGroup: false,
                createCoreClients: false,
                createUsers: false,
                createRegions: false,
                createSectors: false,
                createSiteGroups: false,
                createSites: false
            }
        };
        vm.create = create;
        vm.reload = reload;
        vm.downloadTemplate = downloadTemplate;
        vm.handleOptionChange = handleOptionChange;

        function handleOptionChange(option) {
            var type = option.includes('update') ? 'update' : 'create';

            // Handle 'Select All' checkbox changes
            if (option.includes('All')) {
                Object.keys(vm.import.params).forEach(key => {
                    if (key.includes(type)) {
                        vm.import.params[key] = vm.import[option];
                    }
                });
            } else {
                // Handle the rest of the checkbox changes
                vm.import[type + 'All'] = vm.getUncheckedOptionsByType(type).length > 0 ? false : true;
            }
        }

        vm.getUncheckedOptionsByType = type => {
            return Object.keys(vm.import.params).filter(key => {
                return key.includes(type) && vm.import.params[key] === false;
            });
        };

        function create() {
            if (!vm.importFile) {
                messenger.error('NO_PCD_IMPORT_FILE_ADDED');
                return;
            }

            vm.import._links.file = vm.importFile._links.file;

            var importLink = lodash.find(
                rootResourceResponse._links.import,
                { name: 'pcd' }
            ).href;

            apiClient.create(importLink, vm.import).then(function(response) {
                if (response) {
                    $state.go('dashboard.admin.pcd-imports.list', {}, { reload: $state.current.parent }).then(function() {
                        messenger.info('IMPORT_QUEUED');
                    });
                } else {
                    messenger.error('IMPORT_ERROR');
                    vm.import._links.file = undefined;
                }
            });
        }

        function reload() {
            $state.go($state.current, {}, {reload: $state.current});
        }

        function downloadTemplate() {
            elbDownloadService.downloadUrl(vm.imports[vm.import.type].href, vm.imports[vm.import.type].name + '_import_template.csv', 'text/csv');
        }
    }
})();
