(function () {
    'use strict';

    angular
        .module('elogbooksDirectives')
        .directive('slimscroll', ['$parse', slimscroll]);

    function slimscroll($parse) {
        return {
            link: link,
            restrict: 'A'
        };

        function link(scope, element, attrs) {
          var scrolling = scope.$eval(attrs.slimscrolling);
          var scrolled  = scope.$eval(attrs.slimscrolled);

          attrs.$observe('slimscroll', function(options) {
            $(element).slimScroll({ destroy: true });
            $(element).slimScroll(scope.$eval(options));

            $(element).bind('slimscrolling', scrolling);
            $(element).bind('slimscroll', scrolled);
          });

          scope.$on('$destroy', function() {
            $(element).unbind('slimscrolling', scrolling);
            $(element).unbind('slimscroll', scrolled);

            $(element).slimScroll({ destroy: true });
          });

          /*function slimscrolling(value) {
            scope.slimscrollingValue = value;

            scope.$eval(attrs.slimscrolling);
          }

          function slimscrolled(direction) {
            scope.slimscrolledDirection = direction;

            scope.$eval(attrs.slimscrolled);
          }*/
        }
    }
})();


/*angular.module('ui.slimscroll', []).directive('slimscroll', function () {
  'use strict';

  return {
    restrict: 'A',
    link: function ($scope, $elem, $attr) {
      var off = [];
      var option = {};

      var refresh = function () {
         if ($attr.slimscroll) {
           option = $scope.$eval($attr.slimscroll);
         } else if ($attr.slimscrollOption) {
           option = $scope.$eval($attr.slimscrollOption);
         }

        $($elem).slimScroll({ destroy: true });

         $($elem).slimScroll(option);
      };

      var registerWatch = function () {
        if ($attr.slimscroll && !option.noWatch) {
          off.push($scope.$watchCollection($attr.slimscroll, refresh));
        }

        if ($attr.slimscrollWatch) {
          off.push($scope.$watchCollection($attr.slimscrollWatch, refresh));
        }

        if ($attr.slimscrolllistento) {
          off.push($scope.$on($attr.slimscrolllistento, refresh));
        }
      };

      var destructor = function () {
        $($elem).slimScroll({ destroy: true });
        off.forEach(function (unbind) {
          unbind();
        });
        off = null;
      };

      off.push($scope.$on('$destroy', destructor));

      registerWatch();
    }
  };
});
*/
