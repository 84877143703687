(function() {
    'use strict';

    angular
        .module('elogbooks')
        .config(Translations);

    function Translations($translateProvider) {
        $translateProvider.translations('en-gb', {

            EVENT_FEED: 'Audit Trail',

            EVENT: 'Event',
            EVENTS: 'Events',

            UPDATE_NOTIFY: 'Update & Notify',
            NOTIFICATIONS_SENT_TO: 'Notifications Sent To',
            NOTIFY: 'Notify',
            WRITE_YOUR_UPDATE: 'Write your Update'
        });
    }
})();
