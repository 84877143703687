(function () {
    'use strict';

    angular
        .module('elogbooks.admin.asset-score-types')
        .config(registerRoutes)
        .config(registerRoutesAdd)
        .config(registerRoutesDetails)
        .config(registerRoutesEdit)
        .config(registerRoutesOptions);

    function registerRoutes($stateProvider) {
        $stateProvider
            .state('dashboard.admin.asset-score-types', {
                abstract: true,
                templateUrl: 'shared-templates/view.html',
                ncyBreadcrumb: {
                    skip: true
                },
                data: {
                    permissions: ['user_permission_view_asset_score_types']
                }
            })
            .state('dashboard.admin.asset-score-types.list', {
                url: '/asset-score-types?page&limit&order&id&name&description',
                parent: 'dashboard.admin.asset-score-types',
                data: {
                    permissions: ['user_permission_view_asset_score_types']
                },
                views: {
                    '@dashboard.admin.asset-score-types': {
                        templateUrl: '/modules/admin/asset-score-types/asset-score-types-list.html',
                        controller: 'AssetScoreTypesController',
                        controllerAs: 'vm'
                    }
                },
                resolve: {
                    assetScoreTypesCollectionResponse: function (apiClient, rootResourceResponse, $stateParams) {
                        return apiClient.get(rootResourceResponse.getLink('assetscoretypes'), $stateParams).then(function (response) {
                            return response || apiClient.noResourceFound();
                        });
                    }
                },
                params: {
                    page: '1',
                    limit: '30',
                    order: 'id',
                    id: null,
                    name: null,
                    description: null
                },
                ncyBreadcrumb: {
                    label: '{{ ::"ASSET_SCORE_TYPES" | translate }}'
                }
            });
    }

    function registerRoutesAdd ($stateProvider) {
        $stateProvider
            .state('dashboard.admin.asset-score-types.list.add', {
                url: '/add',
                parent: 'dashboard.admin.asset-score-types.list',
                data: {
                    permissions: ['user_permission_manage_asset_score_types']
                },
                views: {
                    '@dashboard.admin.asset-score-types': {
                        templateUrl: '/modules/admin/asset-score-types/add-edit/asset-score-type-form.html',
                        controller: 'AssetScoreTypesAddEditController',
                        controllerAs: 'vm'
                    }
                },
                resolve: {
                    assetScoreTypeResponse: function () {
                        return {};
                    }
                },
                ncyBreadcrumb: {
                    label: '{{ ::"ADD" | translate }}'
                }
            });
    }

    function registerRoutesDetails ($stateProvider) {
        $stateProvider
            .state('dashboard.admin.asset-score-types.list.details', {
                url: '/details/{resource}',
                parent: 'dashboard.admin.asset-score-types.list',
                data: {
                    permissions: ['user_permission_view_asset_score_types']
                },
                abstract: true,
                views: {
                    '@dashboard.admin.asset-score-types': {
                        templateUrl: '/modules/admin/asset-score-types/details/asset-score-type-details.html',
                        controller: 'AssetScoreTypeDetailsController',
                        controllerAs: 'vm'
                    }
                },
                resolve: {
                    assetScoreTypeResponse: function (apiClient, $stateParams) {
                        return apiClient.get($stateParams.resource.decode()).then(function (response) {
                            return response || apiClient.noResourceFound();
                        });
                    }
                },
                ncyBreadcrumb: {
                    label: '{{ ::$resolves().assetScoreTypeResponse.name }}'
                }
            })
            .state('dashboard.admin.asset-score-types.list.details.info', {
                url: '/info',
                parent: 'dashboard.admin.asset-score-types.list.details',
                data: {
                    permissions: ['user_permission_view_asset_score_types']
                },
                views: {
                    '@dashboard.admin.asset-score-types.list.details': {
                        templateUrl: '/modules/admin/asset-score-types/details/info/asset-score-type-info.html',
                        controller: 'AssetScoreTypeInfoController',
                        controllerAs: 'vm'
                    }
                },
                ncyBreadcrumb: {
                    label: '{{ ::"INFO" | translate }}'
                }
            });
    }

    function registerRoutesEdit ($stateProvider) {
        $stateProvider
            .state('dashboard.admin.asset-score-types.list.details.info.edit', {
                url: '/edit',
                parent: 'dashboard.admin.asset-score-types.list.details.info',
                data: {
                    permissions: ['user_permission_manage_asset_score_types']
                },
                views: {
                    '@dashboard.admin.asset-score-types.list.details': {
                        templateUrl: '/modules/admin/asset-score-types/add-edit/asset-score-type-form.html',
                        controller: 'AssetScoreTypesAddEditController',
                        controllerAs: 'vm'
                    }
                },
                ncyBreadcrumb: {
                    label: '{{ ::"EDIT" | translate }}'
                }
            });
    }

    function registerRoutesOptions ($stateProvider) {
        $stateProvider
            .state('dashboard.admin.asset-score-types.list.details.asset-score-type-options', {
                url: '/asset-score-type-options?scoreTypeOptionsPage&scoreTypeOptionsLimit',
                parent: 'dashboard.admin.asset-score-types.list.details',
                data: {
                    permissions: ['user_permission_view_asset_score_types']
                },
                views: {
                    '@dashboard.admin.asset-score-types.list.details': {
                        templateUrl: '/modules/admin/asset-score-types/details/options/asset-score-type-options-list.html',
                        controller: 'AssetScoreTypeOptionsController',
                        controllerAs: 'vm'
                    }
                },
                params: {
                    scoreTypeOptionsPage: '1',
                    scoreTypeOptionsLimit: '10'
                },
                resolve: {
                    assetScoreTypeOptionsCollectionResponse: function (apiClient, $stateParams, assetScoreTypeResponse) {
                        var params = angular.extend({}, $stateParams,
                            {
                                page: $stateParams.scoreTypeOptionsPage,
                                limit: $stateParams.scoreTypeOptionsLimit,
                                datagroup: 'list'
                            });
                        return apiClient.get(assetScoreTypeResponse.getLink('options'), params).then(function (response) {
                            return response || apiClient.noResourceFound();
                        });
                    }
                },
                ncyBreadcrumb: {
                    label: '{{ ::"ASSET_SCORE_TYPE_OPTION" | translate }}'
                }
            })
            .state('dashboard.admin.asset-score-types.list.details.asset-score-type-options.add', {
                url: '/add',
                parent: 'dashboard.admin.asset-score-types.list.details.asset-score-type-options',
                data: {
                    permissions: ['user_permission_manage_asset_score_types']
                },
                views: {
                    '@dashboard.admin.asset-score-types.list.details': {
                        templateUrl: '/modules/admin/asset-score-types/details/options/add-edit/asset-score-type-options-form.html',
                        controller: 'AssetScoreTypeOptionsAddEditController',
                        controllerAs: 'vm'
                    }
                },
                resolve: {
                    assetScoreTypeOptionResponse: function () {
                        return {};
                    }
                },
                ncyBreadcrumb: {
                    label: '{{ ::"ADD" | translate }}'
                }
            })
            .state('dashboard.admin.asset-score-types.list.details.asset-score-type-options.edit', {
                url: '/edit/{scoreTypeOptionResource}',
                parent: 'dashboard.admin.asset-score-types.list.details.asset-score-type-options',
                data: {
                    permissions: ['user_permission_manage_asset_score_types']
                },
                views: {
                    '@dashboard.admin.asset-score-types.list.details': {
                        templateUrl: '/modules/admin/asset-score-types/details/options/add-edit/asset-score-type-options-form.html',
                        controller: 'AssetScoreTypeOptionsAddEditController',
                        controllerAs: 'vm'
                    }
                },
                resolve: {
                    assetScoreTypeOptionResponse: function (apiClient, $stateParams) {
                        return apiClient.get($stateParams.scoreTypeOptionResource.decode()).then(function (response) {
                            return response || apiClient.noResourceFound();
                        });
                    }
                },
                ncyBreadcrumb: {
                    label: '{{ ::$resolves().assetScoreTypeResponse.name }}'
                }
            });
    }
})();
