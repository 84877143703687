(function () {
    'use strict';
    angular
        .module('elogbooks.common.quote-requests')
        .controller('QuoteAddModalController', QuoteAddModalController);

    function QuoteAddModalController(
        requestDataFactory,
        siteResourceResponse,
        siteRelationshipResponse,
        priorityService,
        $translate,
        userResponse,
        $uibModalInstance,
        questionResponse,
        sectionResponse,
        locationCollectionResponse,
        raiseRemedialJob,
        sourceJob,
        quoteCreateService,
        $scope,
        userManager,
        config,
        $stateParams,
        crudService,
        messenger,
        confirmationModal,
        $rootScope,
        apiClient,
        serviceProviderResponse,
        userOperativeCollectionResponse,
        lodash,
        serviceProviderService
    ) {
        var vm = this;
        angular.extend(vm, $scope.vm);

        $uibModalInstance.rendered.then(function () {
            $rootScope.$emit('QuoteModalLoaded', true);
        });

        vm.serviceProviderService = serviceProviderService;

        vm.data = requestDataFactory.createRequest(
            {
                _links: {
                    location: !raiseRemedialJob ? sectionResponse.getLink('location') : null,
                    sourceJob: raiseRemedialJob ? sourceJob : null
                },
                files: {
                    links: []
                },
                siteContact: {
                    notifyOnCreate: true,
                    notifyOnComplete: true,
                    declineEmail: false
                },
                siteContactSameAsReporter: false,
                notifyOnComplete: userResponse.notifyOnComplete,
                notifyOnCreate: userResponse.notifyOnCreate,
                summary: !raiseRemedialJob ? questionResponse.summary : '',
                description: !raiseRemedialJob ? questionResponse.description : '',
                isRemedial: raiseRemedialJob
            }
        );

        vm.canSubmit = true;
        vm.showAssignToSelf = false;
        vm.isHelpdeskUser = userManager.hasPermission('site_permission_helpdesk');
        vm.selectedRelationshipModel = {};
        vm.data.serviceProviders = {};
        vm.cancel = cancel;
        vm.data.isByPlannedDate = quoteCreateService.isByPlannedDate;
        vm.noteConfig = config;
        vm.locationCollectionResponse = locationCollectionResponse;
        vm.selectedServiceProviders = [];
        vm.locationNotesConfirmed = [];
        vm.submit = submitAction;
        vm.selectedUserCallerContactModel = {};
        vm.enterCallerContactDetailsManually = false;
        vm.changeSite = quoteCreateService.changeSite;
        vm.config = config;
        vm.removeServiceProvider = removeServiceProvider;
        vm.selectedSite = siteResourceResponse;
        vm.isModal = true;
        vm.showLocationParentAdd = false;
        vm.locationRequired = siteResourceResponse.locationRequired;
        vm.changeUser = changeUser;
        vm.findPseudoUsers = findPseudoUsers;
        vm.pseudoUserSelected = pseudoUserSelected;
        vm.resetCallerContact = resetCallerContact;

        vm.partnershipCollectionResponse = {};
        vm.priorityCollectionResponse = {};
        vm.userOperativeCollectionResponse = userOperativeCollectionResponse;

        if (siteResourceResponse.getLink('partnerships')) {
            apiClient.get(siteResourceResponse.getLink('partnerships'), {active: 1}).then(function (response) {
                vm.partnershipCollectionResponse = response;
                getPriorities(response);
            });
        } else if (userResponse.getLink('partnerships')) {
            apiClient.get(userResponse.getLink('partnerships'), {active: 1, siteId: siteResourceResponse.id}).then(function (response) {
                vm.partnershipCollectionResponse = response;
                getPriorities(response);
            });
        }


        function getPriorities(partnershipResponse) {
            // accepting a quote creates a job, so job priorities are required at this stage

            var partnership = partnershipResponse['partnerships'][0];
            if (partnership) {
                priorityService.setupPriorities(partnership.getLink('priorities'), serviceProviderResponse.getLink('priorities'), siteResourceResponse.getLink('priorities'), quoteCreateService.priorityLinkParameters).then(function (response) {
                    vm.priorityCollectionResponse = priorityService.addExtraPriorities(response,['byPlannedDate']);
                    vm.selectedPriorityModel = {
                        response: vm.priorityCollectionResponse,
                        link: null,
                        linkParameters: quoteCreateService.priorityLinkParameters,
                        required: true,
                        responseKeyPath: 'priorities',
                        itemHrefPath: '_links.self.href',
                        itemValuePath: 'name',
                        onSelect: changePriority,
                        onRemove: changePriority
                    };

                    vm.selectedServiceProviderModel = {
                        response: vm.partnershipCollectionResponse,
                        link: vm.partnershipCollectionResponse.getLink('self'),
                        linkParameters: {active: 1},
                        required: false,
                        responseKeyPath: 'partnerships',
                        itemHrefPath: '_links.serviceprovider.href',
                        itemValuePath: '_links.serviceprovider.title',
                        onSelect: changeServiceProvider,
                        onRemove: changeServiceProvider,
                        formatValue: serviceProviderService.formatServiceProviderName,
                        headers: serviceProviderService.isProsureEnabled ? serviceProviderService.prosureStatusHeaders : null,
                        reorder: serviceProviderService.prosureGroupReorder
                    };

                });
            }
        }

        vm.userCollectionResponse = {};

        if (vm.isHelpdeskUser) {
            apiClient.get(siteResourceResponse.getLink('memberships')).then(function (userCollectionResponse) {
                vm.userCollectionResponse = userCollectionResponse;
                vm.data.callerContact = {};
                vm.selectedUserCallerContactModel = {
                    response: userCollectionResponse,
                    link: userCollectionResponse.getLink('self'),
                    required: true,
                    responseKeyPath: 'memberships',
                    itemHrefPath: '_links.self.href',
                    itemValuePath: '_links.user.title',
                    onSelect: vm.changeUser,
                    onRemove: vm.changeUser
                };

            });
        }

        vm.selectedRelationshipModel = {
            response: siteRelationshipResponse,
            link: siteRelationshipResponse.getLink('self'),
            required: true,
            responseKeyPath: 'siterelationships',
            itemHrefPath: '_links.self.href',
            itemValuePath: 'name',
            onSelect: changeRelationship,
            onRemove: changeRelationship
        };

        if (typeof vm.selectedUserCallerContact !== 'undefined') {
            vm.selectedUserCallerContactModel.selected = {
                object: vm.selectedUserCallerContact,
                href: vm.selectedUserCallerContact.getLink('self'),
                value: vm.selectedUserCallerContact._links.user.title
            };
            changeUser();
        }

        function changeUser() {
            quoteCreateService.changeUser(
                vm.selectedUserCallerContactModel,
                vm.selectedUserCallerContact,
                vm.data,
                vm.submitDisabled,
                vm.userCallerContactResponse,
                vm.selectedRelationshipModel
            );
        }

        function findPseudoUsers(name) {
            return apiClient.get(siteResourceResponse.getLink('pseudousers'), {name: name})
                .then(function (response) {
                    return response['site-pseudo-users'];
                }, function (error) {
                    console.log(error);
                });
        }

        function pseudoUserSelected(item) {
            vm.data.callerContact.name = item.name;
            vm.data.callerContact.email = item.email;
            vm.data.callerContact.phone = item.phone;
            vm.data.callerContact.notifyOnCreate = item.notifyOnCreation;
            vm.data.callerContact.notifyOnComplete = item.notifyOnCompletion;

            var pseudoUserSiteRelationship = siteRelationshipResponse.siterelationships.find(function (elem) {
                return elem.getLink('self') === item.getLink('site-relationship');
            });

            vm.selectedRelationshipModel.selected = {
                object: pseudoUserSiteRelationship,
                href: pseudoUserSiteRelationship.getLink('self'),
                value: pseudoUserSiteRelationship.name
            };

            changeRelationship();
        }

        function resetCallerContact() {
            vm.selectedUserCallerContactModel.disabled = vm.enterCallerContactDetailsManually;

            if (vm.enterCallerContactDetailsManually) {
                vm.data._links.caller = null;
            }
        }

        function changeRelationship() {
            quoteCreateService.changeRelationship(
                vm.selectedRelationshipModel,
                vm.selectedRelationship,
                vm.data
            );
        }

        function changeServiceProvider() {
            quoteCreateService.changeServiceProvider(
                vm.selectedServiceProviderModel,
                vm.selectedServiceProviders
            );
            checkAssignToSelf();
        }


        function changePriority() {
            quoteCreateService.changePriority(
                vm.selectedPriorityModel,
                vm.data,
                $translate.instant('BY_PLANNED_DATE'),
                confirmationModal
            );
        }

        function submitAction() {
            vm.data.serviceProviders.links = [];
            angular.forEach(vm.selectedServiceProviders, function (value, index) {
                vm.data.serviceProviders.links.push({
                    href: value.getLink('serviceprovider')
                });
            });

            if ($stateParams.fromJob !== null) {
                vm.data._links.jobFrom = {
                    href: $stateParams.fromJob.decode()
                };
            }

            if ($stateParams.isRemedial !== null) {
                vm.data.isRemedial = $stateParams.isRemedial;
            }

            if ($stateParams.sourceJob !== null) {
                vm.data._links.sourceJob = {href: $stateParams.sourceJob.decode()};
            }

            if (vm.data.serviceProviders.links.length > 0) {
                return crudService.create(
                    siteResourceResponse.getLink('create-quoterequest'),
                    vm.data,
                    null,
                    null,
                    null,
                    true
                ).then(function (response) {
                    if (response) {
                        $uibModalInstance.close();
                        messenger.success('QUOTE_REQUEST_CREATED');
                    }
                });
            }
        }

        function removeServiceProvider(serviceProvider) {
            quoteCreateService.removeServiceProvider(
                serviceProvider,
                vm.selectedServiceProviders
            );
            checkAssignToSelf();
        }

        function checkAssignToSelf() {
            if (
                raiseRemedialJob &&
                vm.selectedServiceProviders.length > 0 &&
                vm.userOperativeCollectionResponse.operatives &&
                vm.userOperativeCollectionResponse.operatives.length
            ) {
                var showAssignToSelf = true;
                angular.forEach(vm.selectedServiceProviders, function (serviceProvider) {
                    var serviceProviderOperative = lodash.find(vm.userOperativeCollectionResponse.operatives, function (operative) {
                        return serviceProvider.getLink('serviceprovider') === operative.getLink('serviceprovider')
                    });
                    if (serviceProviderOperative) {
                        var permissions = lodash.concat(
                            serviceProviderOperative.permissionDependencies,
                            serviceProviderOperative.permissions,
                            serviceProviderOperative.permissionsInherited,
                            serviceProviderOperative.permissionsInheritedDependencies
                        );
                        if (!permissions.includes('operative_permission_receive_quotes')) {
                            showAssignToSelf = false;
                        }
                    } else {
                        showAssignToSelf = false;
                    }
                });
                if (showAssignToSelf) {
                    vm.showAssignToSelf = true;
                    vm.data.assignToMe = true;
                } else {
                    vm.showAssignToSelf = false;
                    delete vm.data.assignToMe;
                }
            } else {
                vm.showAssignToSelf = false;
                delete vm.data.assignToMe;
            }
        }

        function cancel() {
            $uibModalInstance.dismiss('cancel');
        }

        $scope.$watch('vm.enterCallerContactDetailsManually', function (newValue) {
            if (newValue === false && typeof vm.selectedUserCallerContactModel.clear !== 'undefined') {
                vm.selectedUserCallerContactModel.clear();
            }
        });

    }
})();
