
(function () {
    'use strict'

    angular
        .module('elogbooks.admin.departments')
        .config(registerRoutes);

    function registerRoutes($stateProvider) {
        var departmentParams = {
            page: "1",
            limit: "30",
            name: null,
            active: 'true',
            order:'id'
        }

        $stateProvider
            .state('dashboard.admin.departments', {
                abstract: true,
                templateUrl: 'shared-templates/view.html',
                ncyBreadcrumb: {
                    skip: true
                },
                data: {
                    permissions: ['user_permission_manage_departments']
                }
            })
            .state('dashboard.admin.departments.list', {
                url: '/departments?' + Object.keys(departmentParams).join('&'),
                parent: 'dashboard.admin.departments',
                resolve: {
                    departmentCollectionResponse: function (apiClient, user, $stateParams) {
                        var params = $stateParams;

                        return apiClient.get(user.getLink('departments'), params).then (function(response){
                            return response || apiClient.noResourceFound();
                        });
                    }
                },
                params: departmentParams,
                templateUrl: '/modules/admin/departments/department-list.html',
                controller: 'DepartmentsController',
                controllerAs: 'vm',
                ncyBreadcrumb: {
                    label: '{{ ::"DEPARTMENTS" | translate }}'
                }
            })
            .state('dashboard.admin.departments.list.add', {
                url: '/add',
                parent: 'dashboard.admin.departments.list',
                views: {
                    '@dashboard.admin.departments': {
                        templateUrl: '/modules/admin/departments/addEdit/department-add-edit.html',
                        controller: 'DepartmentsAddEditController',
                        controllerAs: 'vm',
                    }
                },
                resolve: {
                    departmentResponse: function (apiClient, base64, $stateParams) {
                        return {};
                    },
                    userCollectionResponse: function (apiClient, rootResourceResponse) {
                        return apiClient.getPage(rootResourceResponse.getLink('users'), {});
                    }
                },
                ncyBreadcrumb: {
                    label: '{{ ::"ADD" | translate }}'
                }
            })
            .state('dashboard.admin.departments.list.details', {
                url: '/details/{resource}',
                abstract: true,
                parent: 'dashboard.admin.departments.list',
                resolve: {
                    departmentResponse: function (apiClient, base64, $stateParams) {
                        return apiClient.get(base64.decode($stateParams.resource));
                    }
                },
                views: {
                    '@dashboard.admin.departments': {
                        templateUrl: '/modules/admin/departments/details/departments-details.html',
                        controller: 'DepartmentsDetailsController',
                        controllerAs: 'vm',
                    }
                },
                ncyBreadcrumb: {
                    label: '{{ ::$resolves().departmentResponse.name }}'
                }
            })
            .state('dashboard.admin.departments.list.details.info', {
                url: '/info',
                parent: 'dashboard.admin.departments.list.details',
                views: {
                    '@dashboard.admin.departments.list.details': {
                        templateUrl: '/modules/admin/departments/details/info/departments-info.html',
                        controller: 'DepartmentsDetailsInfoController',
                        controllerAs: 'vm'
                    }
                },
                ncyBreadcrumb: {
                    label: '{{ ::"INFO" | translate }}'
                }
            })
            .state('dashboard.admin.departments.list.details.info.edit', {
                url: '/edit/{resource}',
                parent: 'dashboard.admin.departments.list.details.info',
                views: {
                    '@dashboard.admin.departments.list.details': {
                        templateUrl: '/modules/admin/departments/addEdit/department-add-edit.html',
                        controller: 'DepartmentsAddEditController',
                        controllerAs: 'vm'
                    }
                },
                resolve: {
                    departmentResponse: function (apiClient, base64, $stateParams) {
                        return apiClient.get(base64.decode($stateParams.resource));
                    },
                    userCollectionResponse: function (apiClient, rootResourceResponse) {
                        return apiClient.getPage(rootResourceResponse.getLink('users'), {});
                    }
                },
                ncyBreadcrumb: {
                    label: '{{ ::"EDIT" | translate }}'
                }
            });
    };

})();
