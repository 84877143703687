(function() {
    'use strict';

    angular
        .module('elogbooks.user.meter-manufacturers')
        .controller('MeterManufacturersDetailsController', MeterManufacturersDetailsController);

    MeterManufacturersDetailsController.$inject = ['meterManufacturerResponse'];
    function MeterManufacturersDetailsController(meterManufacturerResponse) {
        var vm = this;
        vm.meterManufacturer = meterManufacturerResponse;
    }
})();
