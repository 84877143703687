angular.module('elogbooksDirectives')
    .directive('elbCount', function () {
        return {
            restrict: 'E',
            templateUrl: '/modules/directives/elb-count/elb-count.html',
            controller: elbCount,
            replace: true,
            scope: {
                response: '<',
                link: '@'
            }
        };

        function elbCount($scope, apiClient) {
            $scope.loading = true;

            if ($scope.response.getLink($scope.link)) {
                apiClient.get($scope.response.getLink($scope.link)).then(function(response) {
                    $scope.response = response.count;
                    $scope.loading = false;
                });
            } else {
                $scope.loading = false;
            }
        }
    });