app.config(function ($translateProvider) {
    $translateProvider.translations('en-gb', {
        MASTERASSET: 'Asset Summary',
        MASTER_TASK: 'Master Task',
        MASTER_TASKS: 'Master Tasks',
        MASTER_TASK_LIST: 'Master Tasks',

        MASTER_TASK_CREATED: 'Master Task Created',
        MASTER_TASK_UPDATED: 'Master Task Updated',
        MASTER_TASK_DELETED: 'Master Task Deleted',

        MASTER_TASK_ADD: 'Add a new Master Task',
        MASTER_TASK_EDIT: 'Edit Master Task',

        MASTER_TASK_IS_MANDATORY: 'Is Mandatory?',
        MASTER_TASK_SERVICE_TYPE: 'Service Type',
        MASTER_TASK_ASSET_NAME: 'Asset Name'
    });
});
