(function() {
    'use strict';

    angular
        .module('elogbooks.common.quote-requests')
        .controller('CommonQuoteRequestDetailsQuoteDetailsOptionDetailsOtherController', CommonQuoteRequestDetailsQuoteDetailsOptionDetailsOtherController);

    CommonQuoteRequestDetailsQuoteDetailsOptionDetailsOtherController.$inject = ['quoteOptionOtherCollectionResponse'];

    function CommonQuoteRequestDetailsQuoteDetailsOptionDetailsOtherController(quoteOptionOtherCollectionResponse) {
        var vm = this;
        vm.quoteOptionOtherCollectionResponse = quoteOptionOtherCollectionResponse;
    }
})();
