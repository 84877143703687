app.config(function($translateProvider) {
    $translateProvider.translations('en-gb', {
        CORE_CLIENT: 'Core Client',
        CORE_CLIENT_REFERENCE: 'Core Client Reference',
        CORE_CLIENTS: 'Core Clients',
        CORE_CLIENT_INFORMATION: 'Core Client Information',
        SHARING_QUOTE_MARKUP: 'Sharing Quote Markup (%)',

        CORE_CLIENT_MARKUP: 'Markups',
        CORE_CLIENT_MARKUP_LIST: 'Markups',
        CORE_CLIENT_MARKUP_INFORMATION: 'Core Client Markup Information',
        CORE_CLIENT_MARKUP_ADD: 'Add a new Markup',
        CORE_CLIENT_MARKUP_EDIT: 'Edit Markup',
        LABOURMARKUP: 'Labour',
        MATERIALMARKUP: 'Material',
        QUOTEMARKUP: 'Quotes',
        MARKUP_ACTIVE_AT: 'Active At',
        MARKUP_INACTIVE_AT: 'Inactive At',
        CORE_CLIENT_MARKUP_CREATED: 'Core Client Markup created successfully',
        CORE_CLIENT_MARKUP_UPDATED: 'Core Client Markup updated successfully',
        CORE_CLIENT_MARKUP_DELETED: 'Core Client Markup deleted successfully',
        ACTIVE_AT_SHOULD_NOT_BE_BLANK: 'Active at should not be blank',
        ACTIVE_AT_DATE_MUST_BE_IN_FUTURE: 'Active at must be in the future',
        ONLY_ONE_FUTURE_MARKUP_ALLOWED: 'Only one future markup is allowed',
        CANT_EDIT_ACTIVE_MARKUP: 'You can\'t update a currently active markup',

        ADD_NEW_CORE_CLIENT: 'Add a new Core Client',
        EDIT_CORE_CLIENT: 'Edit Core Client',

        CORE_CLIENT_LIST: 'Core Clients',
        CORE_CLIENT_ADD: 'Add a new Core Client',
        CORE_CLIENT_EDIT: 'Edit Core Client',

        CORE_CLIENT_CREATED: 'Core Client created successfully',
        CORE_CLIENT_UPDATED: 'Core Client updated successfully',
        CORE_CLIENT_DELETED: 'Core Client deleted successfully',

        SEARCH_SITE_GROUP_BY_NAME: 'Search Site Group by name',
        SITE_GROUP_INACTIVE: 'Site Group Inactive',
        UNABLE_TO_REMOVE_SITE_GROUP: 'Unable to remove \'{{ name }}\' Site Group, please try again later',
        SITE_GROUP_REMOVED: 'Site Group \'{{ name }}\' Removed',
        CORE_CLIENT_NO_SITE_GROUPS: 'Core Client has no Site Groups',
        SELECT_SITE_GROUP: 'Select Site Group',
        SITE_GROUPS_ADDED: 'Site Groups updated',
        CREATE_NEW_SITE_GROUP: 'Create new Site Group',
        SITE_GROUPS_LIST: 'Site Groups',
        SITE_GROUPS_ADD: 'Add Site Group'
    });
});
