(function () {
    'use strict';

    angular
        .module('elogbooks.user.reports')
        .controller('UserReportsPortfolioComplianceDetailsController', UserReportsPortfolioComplianceDetailsController);

    UserReportsPortfolioComplianceDetailsController.$inject = ['$state', '$stateParams', '$translate', 'apiClient', 'messenger', 'moment', 'confirmationModal', 'serviceProviderGroupCollectionResponse'];

    function UserReportsPortfolioComplianceDetailsController($state, $stateParams, $translate, apiClient, messenger, moment, confirmationModal, serviceProviderGroupCollectionResponse) {
        var vm = this;
        vm.stateParams = $stateParams;
        vm.orderDirection = 1;
        vm.offset = 0;
        vm.reportData = {
            canCreate: true
        };
        vm.months = [{value: null, title: $translate.instant('ALL')}].concat(
            Array.apply(0, new Array(12)).map(function(_,i) {
                return { value: i+1, title: $translate.instant(moment().month(i).format('MMMM').toUpperCase())}
            })
        );
        vm.serviceProviderGroupSelect = {
            response : serviceProviderGroupCollectionResponse,
            link : serviceProviderGroupCollectionResponse ? serviceProviderGroupCollectionResponse.getLink('self') : null,
            responseKeyPath: 'groups',
            itemValuePath: 'id',
            itemTitlePath: 'name'
        };
        // Clean params for export and table data loading
        vm.cleanParams = {
            month: $stateParams.month,
            year: $stateParams.year,
            order: $stateParams.cOrder,
            limit: $stateParams.cLimit,
            page: $stateParams.cPage
        };
        vm.cleanParams.spGroupId = $stateParams.serviceProviderGroup ? JSON.parse($stateParams.serviceProviderGroup).v : null;
        vm.searchCriteria = {
            serviceProviderGroup: { type: 'jsonselectwidget', title: 'SERVICE_PROVIDER_GROUP', value: JSON.parse($stateParams.serviceProviderGroup), options: vm.serviceProviderGroupSelect },
            year: { type: 'number', title: 'YEAR', value: $stateParams.year ? parseInt($stateParams.year) : null, min: 1990 },
            month: { type: 'options', title: 'MONTH', value: $stateParams.month ? parseInt($stateParams.month) : null, options: vm.months }
        };
        vm.search = search;
        vm.delete = deleteAction;
        vm.order = orderAction;

        function loadData() {
            vm.loading = true;

            apiClient.get('/serviceprovidergroups/compliance', vm.cleanParams).then(function (response) {
                vm.reportData = response;
                vm.loading = false;
            });
        }

        function search(params) {
            var override = {
                cPage: 1
            };

            $state.go('.', angular.extend($stateParams, params, override), {reload: $state.current.name});
        }

        function deleteAction(complianceItem) {
            return confirmationModal.open().result.then(function () {
                return apiClient.delete(complianceItem._links.delete.href).then(function (response) {
                    if (response) {
                        messenger.success('DELETED');
                        loadData();
                    }
                });
            });
        }

        function orderAction(key) {
            $stateParams.cOrder = key;
            $stateParams.cPage = 1;
            $state.go('.', $stateParams, {reload: $state.current.name});
        }

        loadData();
    }
})();


