app.config( function($translateProvider) {
    $translateProvider.translations('en-gb', {
        METER_MANUFACTURER_INFORMATION: 'Meter Manufacturer Information',

        ADD_NEW_METER_MANUFACTURER: 'Add a new Meter Manufacturer',
        EDIT_METER_MANUFACTURER: 'Edit Meter Manufacturer',

        METER_MANUFACTURER_LIST: 'Meter Manufacturers',
        METER_MANUFACTURER_ADD: 'Add a new Meter Manufacturer',
        METER_MANUFACTURER_EDIT: 'Edit Meter Manufacturer',

        METER_MANUFACTURER_CREATED: 'Meter Manufacturer created successfully',
        METER_MANUFACTURER_UPDATED: 'Meter Manufacturer updated successfully',
        METER_MANUFACTURER_DELETED: 'Meter Manufacturer deleted successfully',
    });
});
