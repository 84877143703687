(function () {
    'use strict';

    angular
        .module('elogbooks.user.files')
        .config(registerRoutes)
        .config(registerRoutesAdd)
        .config(registerRoutesDetails);

    function registerRoutes ($stateProvider) {
        $stateProvider
            .state('dashboard.user.files', {
                parent: 'dashboard.user',
                abstract: true,
                template: '<bookerize></bookerize>',
                ncyBreadcrumb: {
                    skip: true
                }
            })
            .state('dashboard.user.files.abstract', {
                parent: 'dashboard.user',
                abstract: true,
                views: {
                    '@dashboard.user': {
                        templateUrl: '/modules/common/files/abstract.html',
                        controller: 'CommonFilesAbstractController',
                        controllerAs: 'vm'
                    }
                },
                resolve: {
                    abstractConfig: function(userManager, user) {
                        return {
                            canSeeJobFiles: user.getLinkAttribute('files', 'canSeeJobFiles'),
                            canSeeTabs: false // forced to hide tabs so as to disable the old view click throughs
                        };
                    }
                },
                ncyBreadcrumb: {
                    skip: true
                }
            })
            .state('dashboard.user.files.manage-files', {
                url:'/files',
                abstract: true,
                parent: 'dashboard.user.files',
                views: parseBook({
                    "elogbooks-center@dashboard.user.files": {
                        templateUrl: '/modules/common/files/manage-files/manage-files.html',
                        controller: 'CommonManageFilesController',
                        controllerAs: 'vm'
                    }
                }),
                resolve: {
                    sectionResource: function(userManager, user) {
                        return {
                            canSeeJobFiles: user.getLinkAttribute('files', 'canSeeJobFiles'),
                            siteFilesSref: 'dashboard.user.files.manage-files.site-files-list',
                            jobAttachmentsSref: 'dashboard.user.files.manage-files.job-attachments-list',
                            auditReportsSref: null,
                            quoteAttachmentsSref: 'dashboard.user.files.manage-files.quote-attachments-list',
                            allFilesSref: 'dashboard.user.files.manage-files.all-files-list',
                            documentComplianceSref: null,
                            currentView: 'elogbooks-right@dashboard.user.files.manage-files'
                        };
                    }
                },
                ncyBreadcrumb: {
                    label: '{{ ::"FILES" | translate }}'
                }
            })
            .state('dashboard.user.files.manage-files.export', {
                url: '/export-files/{resource}?filePage&fileLimit&fileOrder',
                parent: 'dashboard.user.files',
                params: {
                    filePage: '1',
                    fileLimit: '30',
                    fileOrder: 'id',
                },
                views: parseBook({
                    'elogbooks-right@dashboard.user.files': {
                        templateUrl: '/modules/common/files/manage-files/list/files-export-list.html',
                        controller: 'CommonFilesListController',
                        controllerAs: 'vm'
                    }
                }),
                resolve: {
                    fileCollectionResponse: function (apiClient, $stateParams) {
                        var params = angular.extend({}, $stateParams,
                            {
                                page: $stateParams.filePage,
                                limit: $stateParams.fileLimit,
                                order: $stateParams.fileOrder,
                            }
                        );

                        return apiClient.get($stateParams.resource.decode(), params, false).then(function (response) {
                            return response || apiClient.noResourceFound();
                        });
                    },
                    config: function() {
                        return {};
                    },
                    tagCollectionResponse: function() {
                        return null;
                    },
                    siteCollectionResponse: function() {
                        return null;
                    },
                    integrationDocTypesResponse: function() {
                        return null;
                    },
                    documentTypeCollectionResponse : function () {
                        return null;
                    },
                    jobResponse : function () {
                        return null;
                    },
                    serviceProviderResponse: function () {
                        return null;
                    },
                    operativeCollectionResponse: function () {
                        return null;
                    }
                },
                ncyBreadcrumb: {
                    label: '{{ ::"EXPORT_DOWNLOAD_FILES_LIST" | translate }}'
                }
            });

        registerRoutesManageFilesListAbstract('all-files-list',  'all-files',  {isJobFile: false}, 'ALL_FILES', {
            showTags: true,
            showStatutoryType: true,
            hideAddButton: true,
            noBorder: true,
            title:'ALL_FILES'
        });

        registerRoutesManageFilesListAbstract('job-attachments-list', 'job-attachments', {entityType: 'Job'}, 'JOB_ATTACHMENTS', {
            isEntityAttachments: true,
            entitySummary: 'JOB_SUMMARY',
            entityId: 'ID_JOB',
            showJobFiles: false,
            hideAddButton: true,
            noBorder: true,
            title: 'JOB_ATTACHMENTS'
        });

        registerRoutesManageFilesListAbstract('quote-attachments-list', 'quote-attachments', {entityType: 'Quote'}, 'QUOTE_ATTACHMENTS', {
            isEntityAttachments: true,
            entitySummary: 'QUOTE_SUMMARY',
            entityId: 'ID_QUOTE',
            showJobFiles: false,
            hideAddButton: true,
            noBorder: true,
            title: 'QUOTE_ATTACHMENTS'
        });

        registerRoutesManageFilesListAbstract('site-files-list', 'site-files', {entityType: 'Site'}, 'SITE_FILES', {
            showJobFiles: true,
            noBorder: true,
            title: 'SITE_FILES'
        });

        function registerRoutesManageFilesListAbstract(state, url, collectionParams, translation, configParams) {
            $stateProvider
                .state('dashboard.user.files.manage-files.' + state, {
                    url: '/' + url + '?filePage&fileLimit&fileOrder&fileTitle&fileUploadedBy&fileUploadedDateStart&fileUploadedDateEnd&fileExpiresDateStart&fileExpiresDateEnd&fileTags&fileIsStatutory&documentTypes&siteStatus',
                    parent: 'dashboard.user.files.manage-files',
                    params: {
                        fileId: null,
                        filePage: '1',
                        fileLimit: '30',
                        fileOrder: '-id',
                        fileSelectedSiteResource: null,
                        fileTitle: null,
                        fileUploadedBy: null,
                        fileUploadedDateStart: null,
                        fileUploadedDateEnd: null,
                        fileExpiresDateStart: null,
                        fileExpiresDateEnd: null,
                        fileTags: {array: true},
                        fileIsStatutory: null,
                        fileIsJobFile: null,
                        serviceProvider: {array: true},
                        siteIds: {array: true},
                        showStatutoryType: true,
                        fileVisibility: null,
                        documentTypes: {array: true},
                        siteStatus: 'Active'
                    },
                    views: parseBook({
                        'elogbooks-right@dashboard.user.files.manage-files': {
                            templateUrl: '/modules/common/files/manage-files/list/list.html',
                            controller: 'CommonFilesListController',
                            controllerAs: 'vm'
                        }
                    }),
                    resolve: {
                        siteCollectionResponse: function(apiClient, user) {
                            return apiClient.get(user.getLink('sites'), { datagroup: 'search' }, 'long').then(function(response) {
                                return response || apiClient.noResourceFound();
                            });
                        },
                        fileCollectionResponse: function (apiClient, user, selectedSiteResponse, $stateParams) {
                            //check if user can see job files
                            if (collectionParams.entityType === 'Site') {
                                collectionParams.isJobFile = !user.getLinkAttribute('files', 'canSeeJobFiles') ? false : $stateParams.fileIsJobFile;
                            }

                            var params = angular.extend({}, $stateParams,
                                {
                                    id: $stateParams.fileId,
                                    page: $stateParams.filePage,
                                    limit: $stateParams.fileLimit,
                                    order: $stateParams.fileOrder,
                                    title: $stateParams.fileTitle,
                                    uploadedBy: $stateParams.fileUploadedBy,
                                    uploadedDateStart: $stateParams.fileUploadedDateStart ? new Date($stateParams.fileUploadedDateStart).toISOString() : null,
                                    uploadedDateEnd: $stateParams.fileUploadedDateEnd ? new Date($stateParams.fileUploadedDateEnd).toISOString() : null,
                                    expiresDateStart: $stateParams.fileExpiresDateStart ? new Date($stateParams.fileExpiresDateStart).toISOString() : null,
                                    expiresDateEnd: $stateParams.fileExpiresDateEnd ? new Date($stateParams.fileExpiresDateEnd).toISOString() : null,
                                    tags: $stateParams.fileTags,
                                    isStatutory: $stateParams.fileIsStatutory,
                                    visibility: $stateParams.fileVisibility,
                                    documentTypes: $stateParams.documentTypes,
                                    siteStatus: $stateParams.siteStatus
                                },
                                collectionParams
                            );

                            if (typeof $stateParams.siteIds === 'undefined' ) {
                                $stateParams.siteIds = [];
                            }

                            if (selectedSiteResponse) {
                                $stateParams.siteIds = [selectedSiteResponse.id];
                            }

                            params = angular.extend({}, params, {
                                'siteIds[]': $stateParams.siteIds,
                                'serviceProvider[]' : $stateParams.serviceProvider,
                                'fileTags[]': $stateParams.fileTags,
                                'documentTypes[]': $stateParams.documentTypes
                            });

                            if (selectedSiteResponse) {
                                params.site = selectedSiteResponse.id;
                            }

                            return apiClient.get(user.getLink('files'), params, false).then(function (response) {
                                return response || apiClient.noResourceFound();
                            });
                        },
                        tagCollectionResponse: function (apiClient, rootResourceResponse) {
                            return apiClient.get(rootResourceResponse.getLink('tags'), { limit: 30 }).then(function (response) {
                                return response || apiClient.noResourceFound();
                            });
                        },
                        config: function(user) {
                            return angular.extend({},
                                {
                                    hideAddButton: !user.getLinkAttribute('files', 'canCreateGlobalFiles'),
                                    showSiteStatusFilter: true
                                },
                                configParams
                            );
                        },
                        integrationDocTypesResponse: function() {
                            return null;
                        },
                        documentTypeCollectionResponse : function (apiClient, rootResourceResponse) {
                            return apiClient.get(rootResourceResponse.getLink('documenttypes'), {status: 'active', order: 'name'})
                                .then(function (response) {
                                    return response || apiClient.noResourceFound();
                                });
                        },
                        jobResponse: function () {
                            return null;
                        },
                        serviceProviderResponse: function () {
                            return null;
                        },
                        operativeCollectionResponse: function () {
                            return null;
                        }
                    },
                    ncyBreadcrumb: {
                        label: '{{ ::"' + translation + '" | translate }}'
                    }
                });
        }

        registerRoutesListAbstract('list', 'files', {}, 'ALL_FILES');
        registerRoutesListAbstract('job-files-list', 'job-files', {isJobFile: true}, 'FILES_JOB_FILES');

        function registerRoutesListAbstract(state, url, collectionParams, translation) {
            $stateProvider
                .state('dashboard.user.files.' + state, {
                    url: '/' + url + '?filePage&fileLimit&fileOrder&fileTitle&fileUploadedBy&fileUploadedDateStart&fileUploadedDateEnd&fileExpiresDateStart&fileExpiresDateEnd&fileTags&fileIsStatutory&documentType',
                    parent: 'dashboard.user.files.abstract',
                    params: {
                        fileId: null,
                        filePage: '1',
                        fileLimit: '30',
                        fileOrder: '-id',
                        fileSelectedSiteResource: null,
                        fileTitle: null,
                        fileUploadedBy: null,
                        fileUploadedDateStart: null,
                        fileUploadedDateEnd: null,
                        fileExpiresDateStart: null,
                        fileExpiresDateEnd: null,
                        fileTags: {array: true},
                        fileIsStatutory: null,
                        serviceProvider: {array: true},
                        siteIds: {array: true},
                        showStatutoryType: true,
                        fileVisibility: null,
                        documentType: null
                    },
                    views: parseBook({
                        'elogbooks-left@dashboard.user.files.abstract': {
                            templateUrl: '/modules/common/files/files-list.html',
                            controller: 'CommonFilesListController',
                            controllerAs: 'vm'
                        }
                    }),
                    resolve: {
                        siteCollectionResponse: function(apiClient, user) {
                            return apiClient.get(user.getLink('sites'), { datagroup: 'search' }, 'long').then(function(response) {
                                return response || apiClient.noResourceFound();
                            });
                        },
                        fileCollectionResponse: function (apiClient, user, selectedSiteResponse, $stateParams, paramsService) {
                            var params = angular.extend({}, $stateParams,
                                {
                                    id: $stateParams.fileId,
                                    page: $stateParams.filePage,
                                    limit: $stateParams.fileLimit,
                                    order: $stateParams.fileOrder,
                                    title: $stateParams.fileTitle,
                                    uploadedBy: $stateParams.fileUploadedBy,
                                    uploadedDateStart: $stateParams.fileUploadedDateStart ? new Date($stateParams.fileUploadedDateStart).toISOString() : null,
                                    uploadedDateEnd: $stateParams.fileUploadedDateEnd ? new Date($stateParams.fileUploadedDateEnd).toISOString() : null,
                                    expiresDateStart: $stateParams.fileExpiresDateStart ? new Date($stateParams.fileExpiresDateStart).toISOString() : null,
                                    expiresDateEnd: $stateParams.fileExpiresDateEnd ? new Date($stateParams.fileExpiresDateEnd).toISOString() : null,
                                    tags: $stateParams.fileTags,
                                    isStatutory: $stateParams.fileIsStatutory,
                                    visibility: $stateParams.fileVisibility
                                },
                                collectionParams
                            );

                            // Some documentType names have a "tab" inside, we need to parse that properly
                            if ($stateParams.documentType) {
                                $stateParams.documentType = $stateParams.documentType.replace('\t','\\t');
                                params.documentType = JSON.parse($stateParams.documentType).v || null;
                            }

                            if (typeof $stateParams.siteIds === 'undefined' ) {
                                $stateParams.siteIds = [];
                            }

                            if (selectedSiteResponse) {
                                $stateParams.siteIds = [selectedSiteResponse.id];
                            }

                            params = angular.extend({}, params, {
                                'siteIds[]': $stateParams.siteIds,
                                'serviceProvider[]' : $stateParams.serviceProvider,
                                'fileTags[]': $stateParams.fileTags,
                            });

                            if (selectedSiteResponse) {
                                params.site = selectedSiteResponse.id;
                            }

                            params = angular.extend({}, params, paramsService);

                            return apiClient.get(user.getLink('files'), params, false).then(function (response) {
                                return response || apiClient.noResourceFound();
                            });
                        },
                        tagCollectionResponse: function (apiClient, rootResourceResponse) {
                            return apiClient.get(rootResourceResponse.getLink('tags'), { limit: 30 }).then(function (response) {
                                return response || apiClient.noResourceFound();
                            });
                        },
                        config: function(user) {
                            return {
                                showStatutoryType: true,
                                hideAddButton: !user.getLinkAttribute('files', 'canCreateGlobalFiles')
                            };
                        },
                        integrationDocTypesResponse: function() {
                            return null;
                        },
                        documentTypeCollectionResponse : function (apiClient, rootResourceResponse) {
                            return apiClient.get(rootResourceResponse.getLink('documenttypes'), {status: 'active', order: 'name'})
                                .then(function (response) {
                                    return response || apiClient.noResourceFound();
                                });
                        },
                        jobResponse : function () {
                            return null;
                        },
                        serviceProviderResponse: function () {
                            return null;
                        },
                        operativeCollectionResponse: function () {
                            return null;
                        }
                    },
                    ncyBreadcrumb: {
                        label: '{{ ::"' + translation + '" | translate }}'
                    }
                });
        }
    }

    function registerRoutesDetails ($stateProvider) {
        registerRoutesManageFilesDetailsAbstract('all-files-list', {});
        registerRoutesManageFilesDetailsAbstract('site-files-list', {entityType: 'Site', showStatutoryType: false});
        registerRoutesManageFilesDetailsAbstract('job-attachments-list', {entityType: 'Job'});
        registerRoutesManageFilesDetailsAbstract('quote-attachments-list', {entityType: 'Quote'});

        function registerRoutesManageFilesDetailsAbstract(state, configParams) {
            $stateProvider
                .state('dashboard.user.files.manage-files.' + state + '.details', {
                    url: '/details/{fileResource}',
                    parent: 'dashboard.user.files.manage-files.' + state,
                    views: parseBook({
                        'elogbooks-right@dashboard.user.files.manage-files': {
                            templateUrl: '/modules/common/files/details/files-details.html',
                            controller: 'CommonFilesDetailsController',
                            controllerAs: 'vm',
                        }
                    }),
                    resolve: {
                        fileResponse: function (apiClient, $stateParams) {
                            return apiClient.get($stateParams.fileResource.decode()).then(function (response) {
                                return response || apiClient.noResourceFound();
                            });
                        },
                        config: function(config) {
                            return angular.extend(config, {
                                showStatutoryType: true
                            });
                        }
                    },
                    ncyBreadcrumb: {
                        label: '{{ ::$resolves().fileResponse.title }}'
                    }
                })
                .state('dashboard.user.files.manage-files.' + state + '.details.edit', {
                    url: '/edit',
                    parent: 'dashboard.user.files.manage-files.' + state + '.details',
                    views: parseBook({
                        'elogbooks-right@dashboard.user.files.manage-files': {
                            templateUrl: '/modules/common/files/add-edit/files-add-edit.html',
                            controller: 'CommonFilesAddEditController',
                            controllerAs: 'vm'
                        }
                    }),
                    resolve: {
                        sitesCollectionResponse: function () {
                            return {};
                        },
                        config: function (config, rootResourceResponse, user) {
                            if (configParams.entityType === 'Site') {
                                configParams = angular.extend (configParams, {
                                    isJobFile: user.getLinkAttribute('files', 'canSeeJobFiles') ? true : false,
                                    canCreateJobFile: user.getLinkAttribute('files', 'canCreateJobFiles') ? true : false
                                });
                            }

                            return angular.extend(config, {
                                statutoryTypeAndTagsEnabled: true,
                                statutoryTypesLink: rootResourceResponse.getLink('statutorytypes'),
                                entitySite: null,
                                fileCreateLink: null
                            }, configParams);
                        }
                    },
                    ncyBreadcrumb: {
                        label: '{{ ::"EDIT" | translate }}'
                    }
                });
        }

        registerRoutesDetailsAbstract('list', {});
        registerRoutesDetailsAbstract('job-files-list', {isJobFile: true});

        function registerRoutesDetailsAbstract(state, configParams) {
            $stateProvider
                .state('dashboard.user.files.' + state + '.details', {
                    url: '/details/{fileResource}',
                    parent: 'dashboard.user.files.' + state,
                    views: parseBook({
                        'elogbooks-right@dashboard.user.files.abstract': {
                            templateUrl: '/modules/common/files/details/files-details.html',
                            controller: 'CommonFilesDetailsController',
                            controllerAs: 'vm',
                        }
                    }),
                    resolve: {
                        fileResponse: function (apiClient, $stateParams, messenger, $state) {
                            return apiClient.get($stateParams.fileResource.decode()).then(function (response) {
                                if (response && response.status === 4) {
                                    return $state.go('dashboard.user.dashboard', $stateParams, { reload: true }).then(function () {
                                        messenger.error('FILE_EXPIRED_DELETED');
                                    });
                                }

                                return response || apiClient.noResourceFound();
                            });
                        },
                        config: function(config) {
                            return angular.extend(config, {
                                showStatutoryType: true
                            });
                        }
                    },
                    ncyBreadcrumb: {
                        skip: true
                    }
                })
                .state('dashboard.user.files.' + state + '.details.edit', {
                    url: '/edit',
                    parent: 'dashboard.user.files.' + state + '.details',
                    views: parseBook({
                        'elogbooks-right@dashboard.user.files.abstract': {
                            templateUrl: '/modules/common/files/add-edit/files-add-edit.html',
                            controller: 'CommonFilesAddEditController',
                            controllerAs: 'vm'
                        }
                    }),
                    resolve: {
                        sitesCollectionResponse: function () {
                            return {};
                        },
                        config: function(config, rootResourceResponse) {
                            return angular.extend(config, {
                                statutoryTypeAndTagsEnabled: true,
                                statutoryTypesLink: rootResourceResponse.getLink('statutorytypes'),
                                entitySite: null,
                                fileCreateLink: null
                            }, configParams);
                        }
                    },
                    ncyBreadcrumb: {
                        label: '{{ ::"EDIT" | translate }}'
                    }
                });
        }
    }

    function registerRoutesAdd ($stateProvider) {
        registerRoutesManageFilesAddAbstract('site-files-list', {entityType: 'Site', showStatutoryType: false});

        function registerRoutesManageFilesAddAbstract(state, configParams) {
            $stateProvider
                .state('dashboard.user.files.manage-files.' + state + '.add', {
                    url: '/add',
                    parent: 'dashboard.user.files.manage-files.' + state,
                    views: parseBook({
                        'elogbooks-right@dashboard.user.files.manage-files': {
                            templateUrl: '/modules/common/files/add-edit/files-add-edit.html',
                            controller: 'CommonFilesAddEditController',
                            controllerAs: 'vm'
                        }
                    }),
                    resolve: {
                        fileResponse: function () {
                            return {};
                        },
                        config: function(config, rootResourceResponse, user) {
                            if (configParams.entityType === 'Site') {
                                configParams = angular.extend (configParams, {
                                    isJobFile: user.getLinkAttribute('files', 'canSeeJobFiles') ? true : false,
                                    canCreateJobFile: user.getLinkAttribute('files', 'canCreateJobFiles') ? true : false
                                });
                            }

                            return angular.extend(config, {
                                entitySite: null,
                                fileCreateLink: null,
                                showStatutoryType: true,
                                showVisibility: true,
                                statutoryTypeAndTagsEnabled: true,
                                statutoryTypesLink: rootResourceResponse.getLink('statutorytypes')
                            }, configParams);
                        }
                    },
                    ncyBreadcrumb: {
                        label: '{{ ::"ADD" | translate }}'
                    }
                });
        }
        registerRoutesAddAbstract('list', {});
        registerRoutesAddAbstract('job-files-list', {isJobFile: true});

        function registerRoutesAddAbstract(state, configParams) {
            $stateProvider
                .state('dashboard.user.files.' + state + '.add', {
                    url: '/add',
                    parent: 'dashboard.user.files.' + state,
                    views: parseBook({
                        'elogbooks-right@dashboard.user.files.abstract': {
                            templateUrl: '/modules/common/files/add-edit/files-add-edit.html',
                            controller: 'CommonFilesAddEditController',
                            controllerAs: 'vm'
                        }
                    }),
                    resolve: {
                        fileResponse: function () {
                            return {};
                        },
                        config: function(config, rootResourceResponse) {
                            return angular.extend(config, {
                                entitySite: null,
                                fileCreateLink: null,
                                showStatutoryType: true,
                                showVisibility: true,
                                statutoryTypeAndTagsEnabled: true,
                                statutoryTypesLink: rootResourceResponse.getLink('statutorytypes')
                            }, configParams);
                        }
                    },
                    ncyBreadcrumb: {
                        label: '{{ ::"ADD" | translate }}'
                    }
                });
        }
    }
})();
