/**
 * Conversations controller
 */
 (function() {
     'use strict';

     angular
        .module('elogbooks.common.conversations')
        .controller('ConversationsMessagesController', ConversationsMessagesController);

    ConversationsMessagesController.$inject = ['$scope', 'apiClient', 'lodash', 'userCollectionResponse', 'conversationResponse', '$interval', '$state', '$filter', 'moment'];

    function ConversationsMessagesController($scope, apiClient, lodash, userCollectionResponse, conversationResponse, $interval, $state, $filter, moment) {

        var vm = this;
        vm.resourceLink = userCollectionResponse.getData()._links.self.href;

        vm.buildItems = function (response) {
            return response.getData().users.map(
                function (item) {
                    if (typeof item === 'undefined'){
                        return;
                    }
                    return {
                        name: item.name,
                        href : item._links.self.href,
                        type: 'default'
                    };
                }
            );
        };

        vm.search = function(keyword) {
            vm.loading = true;

            if (keyword.length >= 1 && vm.resourceLink) {
                // Async function needs to access object properties from global scope
                apiClient.get(vm.resourceLink, {name: keyword}).then(function (response) {
                    vm.loading = false;
                    vm.users = vm.buildItems(response);
                    //remove dupes
                    lodash.each(vm.recipients, function(recipient) {
                        vm.users = lodash.reject(vm.users, function(user){
                            return user.href === recipient.href;
                        });
                    });
                });
            } else {
                vm.loading = false;
            }
        };
        var token = null;

        vm.conversation = conversationResponse.getData();
        vm.recipients = vm.conversation._links.recipient.map(
            function (item) {
                return {
                    name: item.title,
                    href : item.href,
                    type: 'default'
                };
            }
        );

        vm.users = vm.buildItems(userCollectionResponse);
        vm.messages = [];
        vm.body = null;
        vm.sendDisabled = false;
        vm.send = send;
        vm.unsubscribe = unsubscribe;

        checkMessages();

        function send() {
            vm.sendDisabled = true;

            var updateRecipients = {
                _links: {
                    recipient : vm.recipients
                }
            };
            apiClient.update(vm.conversation._links.recipients.href, updateRecipients)
                .then(function(response) {
                    debugger;
                });


            apiClient.create(vm.conversation._links.messages.href, {
                body: vm.body
            }).then(function() {
                // Reset comment body
                vm.body = null;

                // Enable send
                vm.sendDisabled = false;

                vm.composeForm.$setPristine();
                vm.composeForm.$setUntouched();

                // Check for our own message
                checkMessages();
            });
        }

        function unsubscribe() {
            // Only load messages from the last recieved message date
            apiClient.delete(vm.conversation._links.delete.href, {})
                .then(function() {
                    $state.go('^', {}, { reload: true });
                });
        }

        function checkMessages() {
            $interval.cancel(token);

            if (vm.conversation === null) {
                return;
            }

            var since = 0;
            if (vm.messages.length > 0) {
                since = moment.utc(vm.messages[0].createdAt).format('YYYY-MM-DD HH:mm:ss');
            }

            // Only load messages from the last recieved message date
            apiClient.get(vm.conversation._links.messages.href, { since: since })
                .then(function(messageData) {
                    vm.messages = messageData.getData().messages.concat(vm.messages);

                    apiClient.get(vm.conversation._links.self.href)
                        .then(function(conversationData) {
                            var ignoreParticipantReaders = angular.equals(vm.conversation.participants, conversationData.getData().participants);

                            angular.extend(vm.conversation, conversationData.getData());

                            // If the participants array has not changed, then don't update the read
                            // dates.
                            if (ignoreParticipantReaders && since !== 0) {
                                return false;
                            }

                            for (var m in vm.messages) {
                                vm.messages[m].readers = $filter('dateRange')(vm.conversation.participants, {
                                    operation: '>=',
                                    property:  'readAt',
                                    date:      vm.messages[m].createdAt
                                }).map(function (participant) {
                                    return participant._links.user.title;
                                }).join(', ');
                            }
                        });

                    token = $interval(checkMessages, 3000);
                });
        }

        $scope.$on('$destroy', function( event ) {
            if (token != null) {
                $interval.cancel(token);
            }
        });

    };

 })();
