(function() {
    'use strict';

    angular
        .module('elogbooks.common.quote-requests')
        .controller('CommonQuoteRequestDetailsQuoteDetailsOptionDetailsOtherAddEditController', CommonQuoteRequestDetailsQuoteDetailsOptionDetailsOtherAddEditController);

    CommonQuoteRequestDetailsQuoteDetailsOptionDetailsOtherAddEditController.$inject = [
        '$state',
        'quoteOptionOtherCollectionResponse',
        'quoteOptionOtherResponse',
        'crudService',
        'requestDataFactory',
        'elbSettings'
    ];

    function CommonQuoteRequestDetailsQuoteDetailsOptionDetailsOtherAddEditController(
        $state,
        quoteOptionOtherCollectionResponse,
        quoteOptionOtherResponse,
        crudService,
        requestDataFactory,
        elbSettings
    ) {
        var vm = this;
        // When quote require labour or materials or other setting is true we need to reload the quote on create/update
        var reloadRoute = elbSettings.getSetting('quote_require_labour_and_materials').value ? $state.get('^.^.^.^.^') : $state.get('^');
        vm.quoteOptionOtherResponse = quoteOptionOtherResponse;
        vm.quoteOptionOtherCollectionResponse = quoteOptionOtherCollectionResponse;
        vm.data = requestDataFactory.createRequest(angular.extend({}, quoteOptionOtherResponse));

        vm.data.value = parseFloat(vm.data.value ? vm.data.value : 0, 10);

        setDefaults();

        vm.create = createAction;
        vm.update = updateAction;
        vm.delete = deleteAction;

        function createAction() {
            return crudService.create(
                quoteOptionOtherCollectionResponse.getLink('create'),
                vm.data,
                $state.get('^'),
                null,
                'QUOTE_OPTION_OTHER',
                false,
                reloadRoute
            );
        }

        function updateAction() {
            return crudService.update(
                quoteOptionOtherResponse.getLink('edit'),
                vm.data,
                $state.get('^'),
                reloadRoute,
                'QUOTE_OPTION_OTHER'
            );
        }

        function deleteAction() {
            return crudService.delete(
                quoteOptionOtherResponse.getLink('delete'),
                $state.get('^'),
                'QUOTE_OPTION_OTHER',
                null,
                null,
                reloadRoute
            );
        }

        function setDefaults() {
            vm.data.value = typeof vm.data.value === 'number' ? vm.data.value : 0;
        }
    }
})();