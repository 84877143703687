/* eslint-disable quotes */
/**
 * link directive
 * - Handles: site, location, serviceprovider, quoterequest
 * @example
 * <elb-link href="/sites/1" property="site"></elb-link>
 */
(function () {
    'use strict';

    angular
        .module('elogbooksDirectives')
        .directive('elbLink', ElbLink);

    function ElbLink() {
        return {
            restrict: 'E',
            scope: {
                href: '=',
                property: '@'
            },
            transclude: true,
            templateUrl: function ($element, $attrs) {

                return '/modules/directives/links/link.html';
            },
            controller: function ($scope, userManager, base64) {

                $scope.type = 'user';
                $scope.sref = '';

                if (!$scope.href
                    || (typeof $scope.$parent.$parent.$parent.vm !== 'undefined'
                    &&  typeof $scope.$parent.$parent.$parent.vm.job !== 'undefined'
                    && typeof $scope.$parent.$parent.$parent.vm.job.hasExplicitAccess !== 'undefined')
                    ) {
                    return;
                }

                if ($scope.property.toLowerCase() !== 'sites') {
                    var parsedLink = parseLink($scope.href);
                }

                $scope.stateLink = null;
                switch ($scope.property.toLowerCase()) {
                    /* case 'location': */
                    case 'user':
                        if (userManager.hasPermission(['user_permission_manage_users', 'user_permission_client_admin_manage_users'])) {
                            $scope.stateLink = "dashboard.admin.users.list.details.info({resource: '" + base64.encode($scope.href) + "'})";
                        }
                        break;
                    case 'site':
                        if (userManager.hasPermission('user_permission_view_sites')) {
                            $scope.stateLink = "dashboard.admin.sites.list.details.info({resource: '" + base64.encode($scope.href) + "'})";
                        }
                        else {
                            $scope.stateLink = "dashboard.user.sites.list.details.info({resource: '" + base64.encode($scope.href) + "'})";
                        }
                        break;
                    case 'sites':
                        if (userManager.hasPermission('user_permission_view_sites') || userManager.hasPermission('site_permission_view_sites')) {
                            $scope.stateLink = "dashboard.admin.sites.list({status: 'active', billingTypeId: '" + $scope.href + "'})";
                        }
                        break;
                    case 'serviceprovider':
                        if (userManager.hasPermission('user_permission_view_service_providers')) {
                            $scope.stateLink = "dashboard.admin.service-providers.list.details.info({resource: '" + base64.encode($scope.href) + "'})";
                        }
                        else {
                            $scope.stateLink = "dashboard.user.service-providers.list.details.info({resource: '" + base64.encode($scope.href) + "'})";
                        }
                        break;
                    case 'action':
                        $scope.stateLink = "dashboard.user.sites.list.details.actions.manage-actions.list.details.workflow({resource: '"+base64.encode(parsedLink[0])+"', actionResource: '" + base64.encode($scope.href) + "'})";
                        break;
                    case 'auditquestion':
                        $scope.stateLink = "dashboard.user.audits.list.details.sections.details.question({auditResource: '"+base64.encode(parsedLink[0])+"', sectionResource: '" + base64.encode(parsedLink[1]) + "', questionResource: '" + base64.encode(parsedLink[2]) + "'})";
                        break;
                    case 'audit':
                        $scope.stateLink = "dashboard.user.audits.list.details.info({auditResource: '" + base64.encode($scope.href) + "'})";
                        break;
                    case 'spillage':
                        $scope.stateLink = "dashboard.user.spillages.list.details.status({spillageResource: '" + base64.encode($scope.href) + "'})";
                        break;
                    case 'job':
                    case 'jobto':
                    case 'jobfrom':
                        $scope.stateLink = "dashboard.user.jobs.details.status({resource: '" + base64.encode($scope.href) + "'})";
                        break;
                    case 'quoterequest':
                        $scope.stateLink = "dashboard.user.quote-requests.list.details({quoteRequestResource: '" + base64.encode($scope.href) + "'})";
                        break;
                    case 'quote':
                        $scope.stateLink = "dashboard.user.quote-requests.list.details.quote.workflow({quoteRequestResource: '" + base64.encode(parsedLink[1]) + "', quoteResource: '" + base64.encode(parsedLink[2]) + "'})";
                        break;
                    case 'quoteoption':
                        $scope.stateLink = "dashboard.user.quote-requests.list.details.quote.options.details.info({quoteRequestResource: '" + base64.encode(parsedLink[1]) + "', quoteResource: '" + base64.encode(parsedLink[2]) + "', quoteOptionResource: '" + base64.encode(parsedLink[3]) + "'})";
                        break;
                    case 'system-priority':
                        if (userManager.hasPermission('user_permission_view_priorities')) {
                            $scope.stateLink = "dashboard.admin.priorities.list.details.info({priorityResource: '"+base64.encode(parsedLink[0])+"'})";
                        }
                        break;
                    case 'site-priority':
                        if (userManager.hasPermission('user_permission_view_sites')) {
                            $scope.stateLink = "dashboard.admin.sites.list.details.priorities.details.info({resource: '"+base64.encode(parsedLink[0])+"', priorityResource: '"+base64.encode(parsedLink[1])+"'})";
                        }
                        break;
                    case 'subasset':
                        $scope.stateLink = "dashboard.user.assets.manage-assets.list.details.sub-assets.details.info({subAssetResource: '" + base64.encode($scope.href) + "'})";
                        break;
                    case 'asset':
                        if ($scope.href.indexOf('subassets') !== -1) {
                            $scope.stateLink = "dashboard.user.sites.list.details.assets.manage-assets.list.details.sub-assets.details.info({resource: '"+base64.encode(parsedLink[0])+"', assetResource: '"+base64.encode(parsedLink[1])+"', subAssetResource: '" + base64.encode($scope.href) + "'})";
                        } else {
                            $scope.stateLink = "dashboard.user.sites.list.details.assets.manage-assets.list.details.info({resource: '"+base64.encode(parsedLink[0])+"', assetResource: '" + base64.encode($scope.href) + "'})";
                        }
                        break;
                    case 'audittemplate':
                        $scope.stateLink = "dashboard.user.audits.list.details.info({auditResource: '" + base64.encode($scope.href) + "', auditType: 'template'})";
                        break;
                    case 'pricingschedule':
                        $scope.stateLink = "dashboard.admin.pricing-schedules.list.details.labourRates({pricingScheduleResource: '" + base64.encode($scope.href) + "', isActiveVersion: true})";
                        break;
                    case 'salesinvoice':
                        $scope.stateLink = "dashboard.user.finance.sales-invoices.list.details.items({salesInvoicesResource: '" + base64.encode($scope.href) + "'})";
                        break;
                    default:
                        console.log("Invalid directive parameters");
                        break;
                }
            }
        };
    }

    function parseLink(link) {
        var linkSegments = link.split('/');

        var endpoints = [];

        for(var i = 0; i < linkSegments.length; i++) {
            if(!isNaN(linkSegments[i])) {
                endpoints.push(linkSegments.slice(0, i+1).join('/'));
            }
        }

        endpoints = endpoints.filter(function(ele, i) {
            if(ele.length) {
                return true;
            } else {
                return false;
            }
        });

        return endpoints;
    }

})();
