(function () {
    'use strict';

    angular
        .module('elogbooksDirectives')
        .directive('elbTag', ElbTag);

    ElbTag.$inject = [];

    function ElbTag () {
        return {
            restrict: 'AE',
            scope: {
                tag: '=ngModel'
            },
            templateUrl: '/modules/directives/elb-tag/tag.html',
            controller: function($scope, $state) {
                if ($scope.tag._links) {
                    $scope.clickable =
                        $scope.tag._links.quote ||
                        $scope.tag._links.site ||
                        $scope.tag._links.action ||
                        $scope.tag._links.asset ||
                        $scope.tag._links.subasset ||
                        $scope.tag._links.job;
                }

                $scope.goToLink = function() {
                    if ($scope.clickable) {
                        if ($scope.tag._links.quote) {
                            $scope.clickable = true;
                            $state.go('dashboard.user.quote-requests.list.details.quote.workflow', {
                                quoteRequestResource: $scope.tag._links.quoterequest.href.encode(),
                                quoteResource: $scope.tag._links.quote.href.encode()
                            });
                        }
                        if ($scope.tag._links.action) {
                            $scope.clickable = true;
                            $state.go('dashboard.user.actions.manage-actions.list.details.workflow', {actionResource: $scope.tag._links.action.href.encode()});
                        }
                        if ($scope.tag._links.job) {
                            $scope.clickable = true;
                            $state.go('dashboard.user.jobs.details.status', {resource: $scope.tag._links.job.href.encode()});
                        }
                        if ($scope.tag._links.asset && !$scope.tag._links.subasset) {
                            $scope.clickable = true;
                            $state.go('dashboard.user.assets.manage-assets.list.details.info', {assetResource: $scope.tag._links.asset.href.encode()});
                        }
                        if ($scope.tag._links.subasset) {
                            $scope.clickable = true;
                            $state.go(
                                'dashboard.user.assets.manage-assets.list.details.sub-assets.details.info',
                                {
                                    assetResource: $scope.tag._links.asset.href.encode(),
                                    subAssetResource: $scope.tag._links.subasset.href.encode()
                                }
                            );
                        }
                        if ($scope.tag._links.site) {
                            $scope.clickable = true;
                            $state.go('dashboard.user.sites.list.details.info', {resource: $scope.tag._links.site.href.encode()});
                        }
                    }
                }
            }
        };
    }
})();
