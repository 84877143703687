
app.config(function ($translateProvider) {
    $translateProvider.translations('en-gb', {
        /* dashboard.helpdesk.satisfactionsurveys */
        SATISFACTIONSURVEYS: 'Satisfaction Surveys',
        SATISFACTIONSURVEY: 'Satisfaction Survey',
        SATISFACTIONSURVEY_JOBID: 'Job ID',
        SATISFACTIONSURVEY_ISCOMPLETE: 'Is Complete?',
        SATISFACTIONSURVEY_REFUSED: 'Survey Refused?',

        /* dashboard.helpdesk.satisfactionsurvey.edit */
        SATISFACTIONSURVEY_EDIT: 'Edit Satisfaction Survey',
        SATISFACTIONSURVEY_CONTACT_NAME: 'Contact Name',
        SATISFACTIONSURVEY_CONTACT_PHONE: 'Contact Phone',
        SATISFACTIONSURVEY_CONTACT_EMAIL: 'Contact Email',
        SATISFACTIONSURVEY_COMMENTS: 'Additional Comments / Suggestions',
        //SATISFACTIONSURVEY_REFUSED: 'Survey Refused?', // Listed above
        SATISFACTIONSURVEY_QUESTION: 'Question',
        SATISFACTIONSURVEY_RESPONSE: 'Response',

        /* dashboard.admin.satisfactionsurveylines */
        SATISFACTIONSURVEY_QUESTIONS: 'Satisfaction Survey Questions',
        SATISFACTIONSURVEY_BUTTON_ADD_QUESTION: 'Add a Question',

        /* dashboard.helpdesk.satisfactionsurveylines.addedit */
        SATISFACTIONSURVEY_ADD_QUESTION: 'Satisfaction Survey - Add a Question',
        SATISFACTIONSURVEY_EDIT_QUESTION: 'Satisfaction Survey - Edit a Question',

        SATISFACTIONSURVEY_FILTER_INCOMPLETEREQUIRESATTENTION: 'Incomplete - Requires Attention',
        SATISFACTIONSURVEY_FILTER_INCOMPLETENOATTENTION: 'Incomplete - Awaiting Job Completion',
        SATISFACTIONSURVEY_FILTER_COMPLETE: 'Complete'
    });
});
