app.config(function ($translateProvider) {
    $translateProvider.translations('en-gb', {
        GENERAL_SETTINGS: 'General',
        SETTINGS_UPDATED: 'Settings updated',
        EMAIL_SETTINGS: 'Email',
        PASSWORD_SETTINGS: 'Password',
        JOB_SETTINGS: 'Job',
        JOB_SETTINGS_VIEW: 'Job Settings',
        JOB_CHASE_SETTINGS_VIEW: 'Job Chase Settings',
        CHASE_SETTINGS_VIEW: 'Chase Settings',
        QUOTE_SETTINGS: 'Quote',
        QUOTE_SETTINGS_VIEW: 'Quote Settings',
        CHASE_SETTINGS: 'Chase',
        SURVEY_SETTINGS: 'Surveys',
        SURVEY_SETTINGS_VIEW: 'Survey Settings',
        WEBHOOK_SETTINGS_VIEW: 'Webhooks Settings',
        WEBHOOK_SETTINGS: 'Webhooks',
        ASSET_SETTINGS_VIEW: 'Asset Settings',
        ASSET_SETTINGS: 'Assets',
        MAXIMUM_OF: 'Maximum of {{::max}}',
        DEFAULT_ASSET_SCORE_TYPES: 'Default Asset Score Types',
        DEFAULT_ASSET_SCORE_TYPES_VIEW: 'Default Asset Score Types Settings',
        ASSET_SCORE_TYPES_TO_DISPLAY_IN_LIST_VIEW: 'Asset Score Types to display in list view, max {{ ::max }}',

        PERFORMANCE_REPORTING_SETTINGS: 'Performance Reporting',
        PERFORMANCE_SETTINGS_VIEW: 'Performance Reporting Settings',
        FILE_SETTINGS: 'Files',
        FILE_SETTINGS_VIEW: 'Files Settings',
        APP_SETTINGS: 'App',
        APP_SETTINGS_VIEW: 'App Settings',

        SETTINGS_EDIT: 'Settings Edit',
        SETTINGS_EDIT_ADD: 'Settings Edit',
        GENERAL_SETTINGS_VIEW: 'General Settings',
        GENERAL_LOGO: 'System logo',
        RECOMMENDED_GENERAL_LOGO: 'Recommended Size 300 x 100',
        GENERAL_OPERATING_HOURS: 'Operating hours',
        GENERAL_OPENING_TIME: 'Opening time',
        GENERAL_CLOSING_TIME: 'Closing time',
        GENERAL_TASKS_GENERATE_AHEAD: 'Generate tasks a number of days ahead',
        GENERAL_TASKS_WILL_ALWAYS_GENERATE_NEXT_SUNDAY: 'Tasks will always generate to the next Sunday',
        GENERAL_AUDITS_GENERATE_AHEAD: 'Generate audits a number of days ahead',
        GENERAL_ACTIONSITEASSOCIATETYPE: 'Action assignee associate type',
        GENERAL_ACTIONS_PAPERWORK_NOTE_REQUIRED: 'Note required when completing Paperwork for Review actions',
        GENERAL_AUDITCOMPLETIONEMAILASSOCIATETYPE: 'Audit Completion Email Associate Type',

        ACTION_SITE_ASSOCIATE_TYPE_SETTING_ASSOCIATE_TYPE_INVALID: 'Unable to Raise Paperwork action, Action Associate Type setting invalid',
        ACTION_ASSOCIATES_NO_ASSOCIATES_ASSIGNED_FOR_THIS_SITE:'Unable to Raise Paperwork action, no associates for this site for the action assignee associate type setting',

        EMAIL_SETTINGS_VIEW: 'Email Settings',
        EMAIL_HTML_SIGNATURE: 'Email Signature (Html)',
        EMAIL_TEXT_SIGNATURE: 'Email Signature (Text)',
        EMAIL_TENANT_LOGO: 'Tenant Emailer Logo',

        PASSWORD_SETTINGS_VIEW: 'Password Settings',
        PASSWORD_DAYS_TILL_CHANGE_NOTIFICATION: 'Number of days between prompts',
        PASSWORD_DISALLOW_PREVIOUS_PASSWORDS: 'Disallow previous passwords',
        PASSWORD_FORCE_CHANGE: 'Force change',
        PASSWORD_MIN_CHARACTERS: 'Minimum number of characters',
        PASSWORD_REQUIRE_NUMBER: 'Require number',
        PASSWORD_REQUIRE_PUNCTUATION: 'Require punctuation',
        PASSWORD_REQUIRE_UPPERCASE: 'Require uppercase',
        PASSWORD_COMPLEXITY: 'Password Complexity',
        PASSWORD_CHANGE_FREQUENCY: 'Password Change Frequency',

        JOB_GROUPS_REQUIRE: 'Require Job Group',
        JOB_GROUP_CHILD_SELECT_MANDATORY: 'Job Group Child Select Mandatory',
        JOB_EXTRA_SETTINGS_EDIT: 'Job Extra Settings',
        JOB_ACCEPTANCE_TERMS_TEXT: 'Job Acceptance Terms & Conditions',
        JOB_ACCEPTANCE_TERMS_FILE: 'Job Acceptance Terms & Conditions Document',
        JOB_NOTIFY_SITE_ASSOCIATE_TYPE: 'Associate Type to Notify on Approval or Completion of the Job',
        JOB_VALUE_CHASE_ENABLED: 'Job Value Chase Enabled',
        JOB_VALUE_FINAL_CHASE_EMAIL: 'Job Value Final Chase Email',
        P2P_CODING_REQUIRED_JOB_EMAIL: 'Send P2P Coding required for job email',
        JOB_SATISFACTION_SURVEY_FREQUENCY: 'Satisfaction Survey Frequency',
        JOB_TENANT_SELECTION_WHEN_LOGGING: 'Allow a Tenant to be selected when logging a job',
        JOB_APPROVAL_REMINDER: 'Approval Reminder',
        JOB_REASSIGNMENT_REMINDER: 'Reassignment Reminder',
        JOB_ACKNOWLEDGEMENT_REMINDER: 'Acknowledgement Reminder',
        JOB_REACTIVE_COMMENCEMENT_REMINDER: 'Reactive Commencement Reminder',
        JOB_REACTIVE_COMPLETION_REMINDER: 'Reactive Completion Reminder',
        JOB_REACTIVE_ALLOW_SELF_APPROVAL: 'Allow secondary onward approvers to approve their own reactive jobs',
        JOB_PLANNED_COMMENCEMENT_REMINDER: 'Planned Commencement Reminder',
        JOB_PLANNED_COMPLETION_REMINDER: 'Planned Completion Reminder',
        JOB_MINUTES_BEFORE_MUST_BE_APPROVED: 'Number of minutes before job must be approved',
        JOB_MINUTES_BEFORE_REASSIGNMENT_REMINDER: 'Number of minutes before reassignment reminder',
        JOB_MINUTES_BEFORE_ACKNOWLEDGEMENT_REMINDER: 'Number of minutes before acknowledgement reminder',
        JOB_MINUTES_BEFORE_REACTIVE_COMMENCEMENT_REMINDER: 'Number of minutes before reactive commencement reminder',
        JOB_MINUTES_BEFORE_REACTIVE_COMPLETION_REMINDER: 'Number of minutes before reactive completion reminder',
        JOB_MINUTES_BEFORE_PLANNED_COMMENCEMENT_REMINDER: 'Number of minutes before planned commencement reminder',
        JOB_MINUTES_BEFORE_PLANNED_COMPLETION_REMINDER: 'Number of minutes before planned completion reminder',
        JOB_ALLOW_SECONDARY_APPROVERS_TO_REOPEN_JOBS: 'Allow secondary onward approvers to Reopen Reactive and Planned Jobs',
        JOB_ALLOW_SECONDARY_APPROVERS_TO_REASSIGN_JOBS: 'Allow Secondary Onward Approvers to Reassign Reactive Jobs',
        REQUIRE_DOCUMENT_TYPE_WHEN_RAISING_REACTIVE_JOB: 'Require Document Type when raising a Reactive job',
        MANDATORY_DOCUMENT_TYPE: 'Mandatory Document Type',
        JOB_COMPLETION_DUE_SLA_EXTENSION: 'Completion Due SLA extension',
        JOB_ENFORCE_CONFIRMATION_OF_EMPTY_JOB_REQUISITES: 'Enforce Confirmation Of Empty Job Requisites',
        AUTO_APPROVE: 'Auto Approve',
        OFF: 'Off',
        ON: 'On',
        REQUIREJOBGROUP: 'Require Job Group',
        ALLOWTENANTSELECTION: 'Allow a Tenant to be selected when logging a job',
        REACTIVESELFAPPROVAL: 'Allow secondary onward approvers to approve their own reactive jobs',
        REASSIGNMENTREMINDER: 'Reassignment Reminder',
        APPROVALREMINDER: 'Approval Reminder',
        ACKNOWLEDGEMENTREMINDER: 'Acknowledgement Reminder',
        REACTIVECOMMENCEMENTREMINDER: 'Reactive Commencement Reminder',
        REACTIVECOMPLETIONREMINDER: 'Reactive Completion Reminder',
        PLANNEDCOMMENCEMENTREMINDER: 'Planned Commencement Reminder',
        PLANNEDCOMPLETIONREMINDER: 'Planned Completion Reminder',
        JOBGROUPCHILDSELECTMANDATORY: 'Job Group Child Select Mandatory',
        ALLOWSECONDARYAPPROVERTOREOPENJOBS: 'Allow secondary onward approvers to Reopen Reactive and Planned Jobs',
        REQUIREDOCUMENTTYPEWHENRAISINGREACTIVEJOB: 'Require Document Type when raising a Reactive job',
        ALLOWSECONDARYAPPROVERTOREASSIGNJOBS: 'Allow Secondary Onward Approvers to Reassign Reactive Jobs',
        JOBCOMPLETIONDUESLAEXTENSION: 'Completion Due SLA Extension',
        TIMEDELAY: 'Auto Approve Time Delay (days)',
        TIMEDELAY_SUB_LABEL: 'Auto approve time delay must be between 2 and 30 days',
        AUTOAPPROVE: 'Auto Approve after {{ ::timeDelay || 2 | number:0 }} (days)',
        ENFORCECONFIRMATIONOFEMPTYJOBREQUISITES: 'Enforce Confirmation Of Empty Job Requisites',
        JOB_REPORTER_FEEDBACK: 'Reporter Feedback',
        JOB_COMPLETE_EMAIL_TEXT: 'Job Complete Email Text',
        ADDITIONAL_TEXT_JOB_COMPLETION_EMAILS: 'Additional text to appear on all Job completion emails',

        CHASE_SETTINGS_REACTIVE: 'Reactive',
        CHASE_SETTINGS_REACTIVESTATUTORY: 'Reactive Statutory',
        CHASE_SETTINGS_PLANNED: 'Planned',
        CHASE_SETTINGS_PLANNEDSTATUTORY: 'Planned Statutory',
        CHASE_SETTINGS_TYPE0_EDIT: 'Reactive Chase Edit',
        CHASE_SETTINGS_TYPE1_EDIT: 'Reactive Statutory Chase Edit',
        CHASE_SETTINGS_TYPE2_EDIT: 'Planned Chase Edit',
        CHASE_SETTINGS_TYPE3_EDIT: 'Planned Statutory Chase Edit',
        CHASE_SETTINGS_TYPE4_EDIT: 'Quote Chase Edit',
        CHASE_SETTINGS_TYPE0_ADD: 'Reactive Chase Add',
        CHASE_SETTINGS_TYPE1_ADD: 'Reactive Statutory Chase Add',
        CHASE_SETTINGS_TYPE2_ADD: 'Planned Chase Add',
        CHASE_SETTINGS_TYPE3_ADD: 'Planned Statutory Chase Add',
        CHASE_SETTINGS_TYPE4_ADD: 'Quote Chase Add',
        CHASE_SETTINGS_QUOTE: 'Quote',
        CHASE_SETTINGS_CONTRACTAPPROVAL: 'Approval',
        CHASE_SETTINGS_CONTRACTEXPIRED: 'Expired',
        CHASE_TARGET: 'Target',
        CHASE_START_DATE: 'Start Date',
        CHASE_EMAIL_BODY: 'Chase message',
        CHASE_SETTINGS_TYPE_ADD: 'Add Chase Setting',
        CHASE_SETTINGS_TYPE_EDIT: 'Edit Chase Setting',
        CHASE_SETTING_UPDATED: 'Chase Setting Updated',
        CHASE_SETTING_REMOVED: 'Chase Setting Removed',
        CHASE_SETTINGS_REORDERED: 'Chase Settings Reordered',
        CHASE_BODY: 'Body',
        SITE_ASSOCIATE: 'Site Associate',

        BODY: 'Body',
        TARGETSITE: 'Target site',
        TARGETOPERATIVE: 'Target operative',
        SEQUENCE: 'Sequence',
        AFTERDUEDATEPERIOD: 'After due date period',
        AFTERUPDATEPERIOD: 'After update period',
        WEEKENDSPUBLICHOLIDAYS: 'Include weekends & holidays?',
        TARGETAPPROVER: 'Target Approver',
        TARGETSUBMITTER: 'Target Submitter',

        QUOTE_TIMEOUT: 'Timeout',
        QUOTE_TIMEOUT_SUB_LABEL: 'Amount of days until expiry',
        QUOTE_TERMS_TEXT: 'Quote Terms & Conditions',
        QUOTE_TERMS_FILE: 'Quote Terms & Conditions Document',
        QUOTE_REQUIRE_LABOUR_AND_MATERIALS: 'Quote Require Labour, Materials or Other',
        QUOTE_REQUIRE_LABOUR_AND_MATERIALS_ON_QUOTE_OPTIONS: 'Require labour, materials or other on Quote options',
        TERMS_DOWNLOAD_TITLE: 'Click here to see current Terms & Conditions',
        BUDGET_QUOTES: 'Budget Quotes',

        QUOTE_EXTRA_SETTINGS_EDIT: 'Extra',
        QUOTE_EXTENSION_APPROVAL: 'Quote Extension Approval',
        QUOTE_REMINDER_AT: 'Quote Commencement Reminder',
        QUOTE_DISABLE_P2P_ON_ACCEPTANCE: 'Disable P2P On Acceptance Of Quote',
        QUOTE_ALLOW_APPROVERS_IGNORE_THRESHOLDS: 'Allow Approvers to Decline Any Quote Value',

        SURVEY_TIMEOUT: 'FM Completion Deadline',
        SURVEY_AUTOMATED_STATS_ENABLED: 'Automated stats enabled',
        SP_SUBMIT_KPI_BY_DATE: 'SP Submit by (Date)',
        SP_AUTO_SUBMIT_KPI: 'SP Auto Submit',
        AUTO_SUBMIT_KPI_WARNING_MSG: 'This KPI Survey will Auto-Submit on ',
        SELECT_SP_SUBMIT_DATE: 'Please select a value for SP Submit By (Date).',
        FM_SURVEY_SUBMISSION_TYPE: 'FM Auto Submit on',
        FM_SUBMIT_KPI_BY_DATE: 'FM Submit by (Date)',
        DAYS_AFTER_SP_SUBMISSION: 'Days after SP submission',
        DATE_OF_THE_MONTH: 'Date of the month',
        SELECT_FM_SUBMIT_DATE: 'Please select a value for FM Submit By (Date).',

        KPI_TIMEOUT: 'Timeout',
        KPI_TIMEOUT_SUB_LABEL: 'Number of days after being Submitted by a Service Provider that the Survey is automatically accepted',

        APP_TIMEOUT: 'Timeout',

        PERFORMANCE_INCLUDE_PATROL_SCORE: 'Include Patrol Scores',
        PERFORMANCE_INCLUDE_SURVEY_SCORE: 'Include Survey Scores',
        PERFORMANCE_PATROL_SCORE_WEIGHT: 'Patrol Score Weighting',
        PERFORMANCE_QUOTE_SCORE_WEIGHT: 'Quote Score Weighting',
        PERFORMANCE_SURVEY_SCORE_WEIGHT: 'Survey Score Weighting',
        PERFORMANCE_SYSTEM_SCORE_WEIGHT: 'System Score Weighting',

        FILE_ALLOWED_TYPES: 'Allow File Types/Mime Types',
        FILE_DISALLOWED_TYPES: 'Disallow File Types/Mime Types',
        FILE_RESTRICT: 'Restricted File Uploads',
        FILE_TYPE_DISALLOWED: 'This file type is not allowed',
        FILE_SETTINGS_ALLOW_SUB_LABEL: 'If this field is empty it will allow all except disallowed',
        FILE_SETTINGS_DISALLOW_SUB_LABEL: 'If this field is empty it will disallow all except allowed',
        FILE_EXPIRY_NOTIFICATION_TIME: 'Number of days ahead of file expiry to generate a notification',
        FILE_EXPIRY_NOTIFICATION_TIME_SUB_LABEL:'Minimum is 1 day',

        TASK_FORCE_SERVICE_ROUTINE: 'Force Service Routines for Hard Task ',
        TASK_SETTINGS_VIEW: 'Task Settings',
        GENERAL_DEFAULT_LOCALE: 'System locale',
        GENERAL_HELPDESK_NUMBER: 'Helpdesk Phone Number',
        GENERAL_HELPDESK_EMAIL: 'Helpdesk Email Address',
        GENERAL_TENANT_EMAILER_REPLY_EMAIL: 'Tenant Emailer Reply Email Address',
        GENERAL_GLOBAL_HELPDESK_NUMBER: 'Global Helpdesk Phone Number',
        GENERAL_TRAINING_LINK: 'Training Link',
        GENERAL_NON_PRIORITY_COLOUR: '\'By Planned Date\' Action Priority Colour',
        GENERAL_P2P_STARTED_AT_DATE: 'Global P2P Started At Date',
        GENERAL_INACTIVATE_USERS_ENABLED: 'Make Users Inactive',
        GENERAL_INACTIVATE_USERS_DAYS: 'Number of Days',
        GENERAL_INACTIVATE_USERS_DAYS_SUBLABEL: 'Minimum 90 days',
        GENERAL_MERIDIAN_WORKFLOW: 'Meridian Workflow',
        GENERAL_MERIDIAN_WORKFLOW_ENABLED: 'Paperwork approval required for sending',
        GENERAL_MERIDIAN_WORKFLOW_DISABLED: 'Files send on Job Completion',
        GENERAL_MERIDIAN_WORKFLOW_CHANGE_WARNING_TITLE:'Meridian Workflow',
        GENERAL_MERIDIAN_WORKFLOW_CHANGE_WARNING_BODY: 'There are multiple Merdian configurations, if you change this workflow all configurations will adopt this workflow.<br /><br />Are you sure you want to proceed?',
        MERIDIAN_CONFIGURATION_INVALID: 'Meridian Details are missing or improperly configured, please contact the Development Team',
        MERIDIAN_PENDING_CRONS: 'Unable to change this setting until all requests are processed ({{ ::pending }} pending)',

        DAYS_UNTIL_ALARM_ESCALATION: 'Days Until Alarm/Escalation',
        INCLUDE_WEEKEND_HOLIDAYS: 'Including Weekends/Public Holidays',
        EXCLUDE_WEEKEND_HOLIDAYS: 'Excluding Weekends/Public Holidays',
        CHASE_ENTITY_TYPE_SETTINGS_PLANNED_EDIT: 'Edit Planned Job Chase Delays',
        CHASE_ENTITY_TYPE_SETTINGS_PLANNED_STATUTORY_EDIT: 'Edit Planned (Statutory) Job Chase Delays',
        CHASE_ENTITY_TYPE_SETTINGS_REACTIVE_EDIT: 'Edit Reactive Job Chase Delays',
        CHASE_ENTITY_TYPE_SETTINGS_REACTIVE_STATUTORY_EDIT: 'Edit Reactive (Statutory) Job Chase Delays',
        CHASE_ENTITY_TYPE_SETTINGS_QUOTE_EDIT: 'Edit Quote Chase Delays',
        CHASE_ENTITY_SETTINGS_UPDATED: 'Updated Chase Delay Settings',
        AFTERDAYS: 'Number of Days to Delay',
        INCLUDEWEEKENDSPUBLICHOLIDAYS: 'Include Weekends/Public Holidays?',
        SUBMITTER: 'Submitter',
        NO_PERMISSIONS_ADDED: 'No Permissions Added',
        WRONG_URL_FORMAT: 'Wrong URL Format',

        SHARING_SETTING_DISABLED: 'Sharing Setting is disabled on this system, please contact admin team',
        NO_ELEMENTS_ADDED: 'No Elements Added',
        WEBHOOK_JOB: 'Job Webhooks',
        WEBHOOK_QUOTE: 'Quote Webhooks',
        WEBHOOK_JOB_ENABLED: 'Job Webhooks Enabled',
        WEBHOOK_QUOTE_ENABLED: 'Quote Webhooks Enabled',

        ABSENCE_SETTINGS: 'Absence',
        ABSENCE_SETTINGS_VIEW: 'Absence Settings',
        DEFAULT_YEARLY_ENTITLEMENT: 'Yearly Entitlement',
        DEFAULT_HOLIDAY_YEAR_START_DAY: 'Holiday Start Day',
        DEFAULT_HOLIDAY_YEAR_START_MONTH: 'Holiday Start Month',
        ENTITLEMENT: 'Entitlement',

        GENERAL_CONTRACT_MANAGER: 'Contract Manager',
        CIS: 'Contract Number (CIS)',

        SP_FEEDBACK_SETTINGS_VIEW: 'Service Provider Feedback Settings',
        SP_FEEDBACK_SETTINGS: 'Service Provider Feedback',
        SP_FEEDBACK_DISPLAY_JOB_STATISTICS: 'Display Job Statistics',
        SP_FEEDBACK_ALLOW_COMMENTS: 'Allow Comments',
        SP_FEEDBACK_ALLOWED_POPUPS_PER_WEEK: 'Allowed Number of Popups per Week',
        SP_FEEDBACK_MODAL_BODY: 'Body Text',
        SP_FEEDBACK_MODAL_TITLE: 'Title',
        SP_FEEDBACK_MODAL_BODY_INVALID_MESSAGE: 'Body Text cannot be over 255 chars.',
        SP_FEEDBACK_MODAL_BODY_REQUIRED_MESSAGE: 'Body Text cannot be empty.',
        SP_FEEDBACK_MODAL_BODY_MISSING_VARIABLE_MESSAGE: 'Body Text must include the placeholder.',
        SP_FEEDBACK_MODAL_BODY_VARIABLE_MESSAGE: 'Include the [SERVICE_PROVIDER] string as placeholder.',
        SP_FEEDBACK_MODAL_TITLE_INVALID_MESSAGE: 'Title cannot be over 255 chars.',
        SP_FEEDBACK_MODAL_TITLE_EMPTY_MESSAGE: 'Title cannot be empty.',
        SP_FEEDBACK_ALLOWED_POPUPS_PER_WEEK_INVALID_MESSAGE: 'Popup number must be between 1 and 5.',
        SP_FEEDBACK_ALLOWED_POPUPS_PER_WEEK_NON_DECIMAL_ONLY: 'Popup number cannot be decimal.',
        SP_FEEDBACK_UPDATE_SUCCESS_MESSAGE: 'Service Provider Feedback configuration updated.',
        SP_FEEDBACK_UPDATED_BY: '<b>{{ ::name }}</b> updated Service Provider Feedback Settings',
        SP_FEEDBACK_ACTIVE: 'Service Provider Feedback Active'
    });
});
