app.config(function ($translateProvider) {
    $translateProvider.translations('en-gb', {
        JOB_CONTACT: 'Job Contact',
        JOB_CONTACTS: 'Job Contacts',

        JOB_CONTACT_LIST: 'Job Contacts',
        JOB_CONTACT_ADD: 'Add',

        JOB_CONTACT_CREATED: 'Job Contact Created',
        JOB_CONTACT_UPDATED: 'Job Contact Updated',
        JOB_CONTACT_DELETED: 'Job Contact Deleted',
    });
});
