(function() {
    'use strict';

    angular
        .module('elogbooks.common.quote-requests')
        .controller('CommonQuoteRequestDetailsQuoteDetailsOptionDetailsMaterialAddEditController', CommonQuoteRequestDetailsQuoteDetailsOptionDetailsMaterialAddEditController);

    CommonQuoteRequestDetailsQuoteDetailsOptionDetailsMaterialAddEditController.$inject = ['$state', 'quoteOptionMaterialCollectionResponse', 'quoteOptionMaterialResponse', 'crudService', 'requestDataFactory', 'elbSettings'];

    function CommonQuoteRequestDetailsQuoteDetailsOptionDetailsMaterialAddEditController($state, quoteOptionMaterialCollectionResponse, quoteOptionMaterialResponse, crudService, requestDataFactory, elbSettings) {
        var vm = this;
        // When quote require labour and material setting is true we need to reload the quote on create/update
        var reloadRoute = elbSettings.getSetting('quote_require_labour_and_materials').value ? $state.get('^.^.^.^.^') : $state.get('^');
        vm.quoteOptionMaterialResponse = quoteOptionMaterialResponse;
        vm.quoteOptionMaterialCollectionResponse = quoteOptionMaterialCollectionResponse;
        vm.data = requestDataFactory.createRequest(angular.extend({}, quoteOptionMaterialResponse));

        vm.data.quantity = vm.data.quantity ? parseInt(vm.data.quantity, 10) : null;
        vm.data.quotedValue = vm.data.quotedValue ? parseFloat(vm.data.quotedValue, 10) : null;
        vm.data.quotedTaxValue = vm.data.quotedTaxValue ? parseFloat(vm.data.quotedTaxValue, 10) : null;
        vm.data.actualValue = vm.data.actualValue ? parseFloat(vm.data.actualValue, 10) : null;
        vm.data.actualTaxValue = vm.data.actualTaxValue ? parseFloat(vm.data.actualTaxValue, 10) : null;

        setDefaults();

        vm.create = createAction;
        vm.update = updateAction;
        vm.delete = deleteAction;

        function createAction() {
            return crudService.create(
                quoteOptionMaterialCollectionResponse.getLink('create'),
                vm.data,
                $state.get('^'),
                null,
                'QUOTE_OPTION_MATERIAL',
                null,
                reloadRoute
            );
        }

        function updateAction() {
            return crudService.update(
                quoteOptionMaterialResponse.getLink('edit'),
                vm.data,
                $state.get('^'),
                reloadRoute,
                'QUOTE_OPTION_MATERIAL'
            );
        }

        function deleteAction() {
            return crudService.delete(
                quoteOptionMaterialResponse.getLink('delete'),
                $state.get('^'),
                'QUOTE_OPTION_MATERIAL',
                null,
                null,
                reloadRoute
            );
        }

        function setDefaults() {
            vm.data.quantity = typeof vm.data.quantity === 'number' ? vm.data.quantity : 1;
            vm.data.quotedValue = typeof vm.data.quotedValue === 'number' ? vm.data.quotedValue : 0;
            vm.data.quotedTaxValue = typeof vm.data.quotedTaxValue === 'number' ? vm.data.quotedTaxValue : 0;
            vm.data.actualValue = typeof vm.data.actualValue === 'number' ? vm.data.actualValue : 0;
            vm.data.actualTaxValue = typeof vm.data.actualTaxValue === 'number' ? vm.data.actualTaxValue : 0;
        }
    }
})();
