(function () {
    'use strict';

    angular
        .module('elogbooksFilters')
        .filter('daysHoursMinutes', DaysHoursMinutes);

    DaysHoursMinutes.$inject = ['$translate'];

    function DaysHoursMinutes($translate) {
        return function (input, seconds, showSeconds) {
            if (input !== null && input !== undefined) {
                if (typeof showSeconds === 'undefined') {
                    showSeconds = true;
                }

                if (seconds) {
                    // make sure input is in minutes.
                    input = input / 60;
                }

                // Setup variables, ensure minutes is positive
                var days = 0, hours = 0, minutes = 0, seconds = 0;
                var output = '';
                minutes = (input < 0) ? input * -1 : input;

                // Convert minutes to hours
                while (minutes >= 60) {
                    minutes -= 60;
                    hours++;
                }

                // Convert hours to days
                while (hours >= 24) {
                    hours -= 24;
                    days++;
                }

                // Determine the output
                if (days > 0) {
                    output += $translate.instant('HD_DAYS_SHORT', {days: days}) + ' ';
                }

                if (hours > 0 || output != '') {
                    output += $translate.instant('HD_HOURS_SHORT', {hours: hours}) + ' ';
                }

                if (minutes <= 1 && showSeconds) {
                    seconds = parseInt(minutes * 60);
                    output += $translate.instant('HD_SECONDS_SHORT', {seconds: seconds}) + ' ';
                } else {
                    minutes = minutes.toFixed(0);
                    output += $translate.instant('HD_MINUTES_SHORT', {minutes: minutes}) + ' ';
                }

                // Return the output
                return output.trim();
            }
        };
    }
})();
