;(function() {
    'use strict';

    angular
        .module('elogbooksDirectives')
        .directive('elbForm', ElogbooksFormDirective);

    ElogbooksFormDirective.$inject = [];

    function ElogbooksFormDirective() {
        return {
            restrict: 'E',
            scope: {
                model: '=',
                mode: '@',
                form: '=',
                associatedEntities: '=',
                instance: '=',
                save: '=',
                submit: '=',
                domForm: '=',
                cancel: '=',
                formName: '@',
                index: '='
            },
            templateUrl: '/modules/directives/elb-form/form.html',
            controller: ElogbooksFormController,
            controllerAs: 'vm',
            bindToController: true
        };

        ElogbooksFormController.$inject = ['apiClient', 'messenger', '$scope', '$timeout', 'lodash', 'sortableOptions', 'elbFormService', 'elbDownloadService'];

        function ElogbooksFormController(apiClient, messenger, $scope, $timeout, lodash, sortableOptions, elbFormService, elbDownloadService) {
            var vm = this;
            vm.model = $scope.model;
            vm.form = $scope.form;
            vm.instance = $scope.instance;
            vm.associatedEntities = $scope.associatedEntities || {};
            vm.save = $scope.save;
            vm.submit = $scope.submit;
            vm.cancel = $scope.cancel;
            vm.domForm = $scope.domForm;
            vm.formName = $scope.formName ? $scope.formName : 'elbForm';
            vm.undoHistory = [];
            vm.redoHistory = [];
            vm.fieldHasError = {};
            vm.macro = [];
            vm.index = $scope.index;

            vm.currentPage = 1;
            vm.totalPages = 1;
            vm.renderPage = 1;
            vm.componentPages = {};
            vm.loading = false;

            vm.addComponent = addComponent;
            vm.copyComponent = copyComponent;
            vm.removeComponent = removeComponent;
            vm.isDisabledComponent = isDisabledComponent;
            vm.formHasComponent = formHasComponent;
            vm.removeChoice = removeChoice;
            vm.displayMode = displayMode;
            vm.getMacroValue = getMacroValue;
            vm.undo = undoAction;
            vm.redo = redoAction;
            vm.printForm = printForm;

            vm.saveAction = saveAction;
            vm.submitAction = submitAction;
            vm.canSaveSubmit = canSaveSubmit;
            vm.cancelAction = cancelAction;
            vm.download = downloadAction;

            vm.hasFormChangedSignificantly = hasFormChangedSignificantly;
            vm.isComponentVisibleByCondition = isComponentVisibleByCondition;
            vm.isComponentCollapsible = isComponentCollapsible;
            vm.getComponentById = getComponentById;
            vm.getDatePickerOptions = getDatePickerOptions;
            vm.getChoiceOptionsByTableColumns = getChoiceOptionsByTableColumns;
            vm.selectConditionElementStart = selectConditionElementStart;
            vm.selectConditionElementStop = selectConditionElementStop;
            vm.selectConditionElement = selectConditionElement;
            vm.checkBetweenCondition = checkBetweenCondition;
            vm.hasErrorCheck = hasErrorCheck;

            vm.sortableOptions = sortableOptions.addProperty({
                containment: '.sortable-list'
            });

            $timeout(function() {
                vm.controls = getControls();
                vm.macroOptions = elbFormService.getFormMacroOptions(vm.form.type);

                vm.model = angular.merge({
                    settings: {
                        header: {
                            fontColour: '#000000',
                            fontSize: 16,
                            text: null,
                            textAlignment: 'left'
                        },
                        horizontalrule: {
                            colour: '#000000',
                            lineThickness: 2,
                            width: 100
                        },
                        question: {
                            textAlignment: 'auto',
                            choiceLabelAlignment: 'right',
                        },
                        footer: {
                            text: null
                        }
                    }
                }, vm.model);

                CorrectInitialModelTypecast(vm.model);
                updateFormMetadata();

                vm.originalMode = vm.mode;
                vm.originalModel = angular.merge({}, vm.model);

                if (['preview', 'render-preview'].indexOf(vm.mode) !== -1) {
                    vm.mode = 'render';
                }

                $scope.$watch('vm.model', function(newValue, oldValue) {
                    if (vm.skipModelWatch) {
                        vm.skipModelWatch = false;
                        return;
                    }

                    if (!lodash.isEqual(newValue, oldValue)) {

                        var oldComponents = oldValue.components,
                            newComponents = newValue.components;

                        if (newComponents.length != oldComponents.length) {
                            vm.undoHistory.push(angular.extend({}, oldValue));

                            if (vm.undoHistory.length > 20) {
                                vm.undoHistory.shift();
                            }
                        }
                    }

                    if (vm.originalMode === 'build') {
                        // Fix temporary answer so that multiple select doesn't break
                        // single select option switching between build and render
                        var questions = lodash.filter(vm.model.components, {options: {type: 'choice-select'}});
                        angular.forEach(questions, function(question) {
                            if (question.options.allowMultiple && !angular.isArray(question.answer)) {
                                delete question.answer;
                            } else if (!question.options.allowMultiple && angular.isArray(question.answer)) {
                                delete question.answer;
                            }
                        });
                    }
                }, true);

                if (vm.originalMode === 'render') {
                    $scope.$broadcast('reCalcViewDimensions');
                }
            });

            function hasErrorCheck() {
                return "{'has-error': " + vm.formName + "[component.id].$dirty && " + vm.formName + "[component.id].$invalid, 'required': component.options.required && !component.notApplicable}";
            }

            function displayMode(type) {
                vm.renderPage = 1;

                switch (type) {
                    case 'settings':
                    case 'build':
                        vm.mode = type;
                        break;

                    case 'web':
                    case 'app':
                        vm.mode = 'render';
                        break;
                }

                vm.simulateApp = vm.mode === 'render' && type === 'app';
            }

            function addComponent(type) {
                if (isDisabledComponent(type)) {
                    return;
                }

                var component = getCleanComponent(type);

                vm.model.components.push(component);
                vm.redoHistory = [];

                updateFormMetadata();
            }

            function removeComponent(component) {
                lodash.remove(vm.model.components, {id: component.id});
                vm.redoHistory = [];

                updateFormMetadata();
            }

            function copyComponent(component, $event) {
                $event.preventDefault();
                $event.stopPropagation();

                var componentIndex = lodash.findIndex(vm.model.components, {id: component.id}),
                    newComponent = angular.merge({}, component, {id: generateUniqueComponentId()});

                vm.model.components.splice(componentIndex + 1, 0, newComponent);

                $timeout(function() {
                    angular.element('#' + newComponent.id)[0].scrollIntoView();
                    document.getElementById(newComponent.id).classList.add('highlighting');
                });
            }

            function updateFormMetadata() {
                vm.totalPages = lodash.filter(vm.model.components, {type: 'pagebreak'}).length + 1;
            }

            function isDisabledComponent(type) {
                var control = getControl(type);
                if (control.max) {
                    var matches = getComponentsOfType(type);

                    return matches.length >= control.max;
                }

                return false;
            }

            function formHasComponent(type) {
                var components = getComponentsOfType(type);

                return components.length > 0;
            }

            function getComponentsOfType(type, model) {
                return lodash.filter(model || vm.model.components, {type: type})
            }

            function getControl(type) {
                return angular.extend({}, vm.controls[type]);
            }

            function getCleanComponent(type) {
                var control = getControl(type);

                delete control.max;
                delete control.icon;

                control.id = generateUniqueComponentId();

                return control;
            }

            function generateUniqueComponentId() {
                var id = 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function(character) {
                    var rand = Math.random() * 16 | 0,
                        value = character == 'x' ? rand : (rand & 0x3 | 0x8);

                    return value.toString(16);
                });

                var uniquenessTest = lodash.find(vm.model.components, {id: id});
                if (uniquenessTest) {
                    id = generateUniqueComponentId();
                }

                return id;
            }

            function getControls() {
                var controls = {
                    text: {
                        type: 'text',
                        icon: 'font',
                        collapsible: true
                    },
                    question: {
                        type: 'question',
                        icon: 'question-circle-o',
                        collapsible: true
                    },
                    horizontalrule: {
                        type: 'horizontalrule',
                        icon: 'minus',
                        collapsible: false
                    },
                    pagebreak: {
                        type: 'pagebreak',
                        icon: 'window-restore',
                        collapsible: false
                    }
                };

                switch (vm.form.type) {
                    case 'asset':
                    case 'task':
                    case 'job':
                        controls = angular.extend({}, controls, {
                            macro: {
                                type: 'macro',
                                icon: 'tag',
                                collapsible: true,
                            }
                        });
                        break;

                }

                return controls;
            }

            function undoAction() {
                vm.skipModelWatch = true;
                vm.redoHistory.unshift(angular.extend({}, vm.model));
                vm.model = angular.extend({}, vm.undoHistory.pop());

                if (vm.redoHistory.length > 20) {
                    vm.redoHistory.pop();
                }
            }

            function redoAction() {
                vm.skipModelWatch = true;
                vm.undoHistory.push(angular.extend({}, vm.model));
                vm.model = angular.extend({}, vm.redoHistory.shift());
            }

            function printForm() {
                vm.loading = true;
                apiClient.get(vm.instance.getLink('form-instance-sheet'), {'macroValue[]': vm.macro}).then(function (response) {
                    if (response) {
                        elbDownloadService.download(response);
                    } else {
                        messenger.error('REQUEST_ERROR');
                    }
                    vm.loading = false;
                });
            }

            function getDatePickerOptions(options) {
                return {
                    minDate: typeof options.minDateOffsetUnrestricted === 'undefined' || options.minDateOffsetUnrestricted === true ? null : (new Date(+new Date() + (options.minDateOffset * 86400000))),
                    maxDate: typeof options.maxDateOffsetUnrestricted === 'undefined' || options.maxDateOffsetUnrestricted === true ? null : (new Date(+new Date() + (options.maxDateOffset * 86400000)))
                };
            }

            function selectConditionElementStart(component) {
                vm.selectingElement = true;
                vm.selectingElementComponent = component;
                component.options.condition = component.options.condition || {};
            }

            function selectConditionElementStop() {
                vm.selectingElement = false;
                vm.selectingElementComponent = null;
            }

            function selectConditionElement() {
                vm.selectingElement = false;
                vm.selectingElementComponent.options.condition.element = vm.selectingElementHover;
                vm.selectingElementHover = null;

                $timeout(function() {
                    angular.element('#' + vm.selectingElementComponent.id)[0].scrollIntoView();
                });
            }

            function getComponentById(id) {
                return lodash.find(vm.model.components, {id: id});
            }

            function isComponentVisibleByCondition(component) {
                if (!component.options ||
                    !component.options.condition ||
                    !component.options.condition.element) {
                    return true;
                }

                var conditionComponent = angular.extend({}, getComponentById(component.options.condition.element));

                if ([undefined, null].indexOf(conditionComponent.answer) !== -1 ||
                    !isComponentVisibleByCondition(conditionComponent)) {
                    return false;
                }

                switch (component.options.condition.matchType) {
                    case 'contains':
                        if (typeof conditionComponent.answer === 'object') {
                            for (var propName in conditionComponent.answer) {
                                if (typeof conditionComponent.answer[propName] !== "boolean") {
                                    var index = propName;
                                    propName = conditionComponent.answer[propName];
                                }

                                if ((propName === conditionComponent.answer[index || propName] || conditionComponent.answer[index || propName] === true)
                                    &&
                                    propName.toString().toLocaleLowerCase().indexOf(component.options.condition.valueLowerLimit.toString().toLocaleLowerCase()) !== -1
                                ) {
                                    return true;
                                }
                            }

                            return false;
                        }

                        return conditionComponent.answer.toString().toLocaleLowerCase().indexOf(component.options.condition.valueLowerLimit.toString().toLowerCase()) !== -1;
                    case 'not-contains':
                        if (typeof conditionComponent.answer === 'object') {
                            for (var propName in conditionComponent.answer) {
                                if (typeof conditionComponent.answer[propName] !== "boolean") {
                                    var index = propName;
                                    propName = conditionComponent.answer[propName];
                                }

                                if ((propName === conditionComponent.answer[index || propName] || conditionComponent.answer[index || propName] === true)
                                    &&
                                    propName.toString().toLocaleLowerCase().indexOf(component.options.condition.valueLowerLimit.toString().toLocaleLowerCase()) === -1
                                ) {
                                    return true;
                                }
                            }

                            return false;
                        }

                        return conditionComponent.answer.toString().toLocaleLowerCase().indexOf(component.options.condition.valueLowerLimit.toString().toLowerCase()) === -1;
                    case 'begins':
                        if (typeof conditionComponent.answer === 'object') {
                            for(var propName in conditionComponent.answer) {
                                if (typeof conditionComponent.answer[propName] !== "boolean") {
                                    var index = propName;
                                    propName = conditionComponent.answer[propName];
                                }

                                if ((propName === conditionComponent.answer[index || propName] || conditionComponent.answer[index || propName] === true)
                                    &&
                                    propName.toString().toLowerCase().startsWith(component.options.condition.valueLowerLimit.toString().toLowerCase())) {
                                    return true;
                                }
                            }

                            return false;
                        }

                        return conditionComponent.answer.toString().toLowerCase().startsWith(component.options.condition.valueLowerLimit.toString().toLowerCase());
                    case 'ends':
                        if (typeof conditionComponent.answer === 'object') {
                            for(var propName in conditionComponent.answer) {
                                if (typeof conditionComponent.answer[propName] !== "boolean") {
                                    var index = propName;
                                    propName = conditionComponent.answer[propName];
                                }

                                if ((propName === conditionComponent.answer[index || propName] || conditionComponent.answer[index || propName] === true)
                                    &&
                                    propName.toString().toLowerCase().endsWith(component.options.condition.valueLowerLimit.toString().toLowerCase())) {
                                    return true;
                                }
                            }

                            return false;
                        }

                        return conditionComponent.answer.toString().toLocaleLowerCase().toLowerCase().endsWith(component.options.condition.valueLowerLimit.toString().toLowerCase());
                    case 'equals':
                        if (typeof conditionComponent.answer === 'object') {
                            for(var propName in conditionComponent.answer) {
                                if (typeof conditionComponent.answer[propName] !== "boolean") {
                                    var index = propName;
                                    propName = conditionComponent.answer[propName];
                                }

                                if ((propName === conditionComponent.answer[index || propName] || conditionComponent.answer[index || propName] === true)
                                    &&
                                    component.options.condition.valueLowerLimit.toString().toLowerCase() === propName.toString().toLowerCase()) {
                                    return true;
                                }
                            }

                            return false;
                        }

                        return conditionComponent.answer.toString().toLowerCase() === component.options.condition.valueLowerLimit.toString().toLowerCase();
                    case 'not-equals':
                        if (typeof conditionComponent.answer === 'object') {
                            for(var propName in conditionComponent.answer) {
                                if (typeof conditionComponent.answer[propName] !== "boolean") {
                                    var index = propName;
                                    propName = conditionComponent.answer[propName];
                                }

                                if ((propName === conditionComponent.answer[index || propName] || conditionComponent.answer[index || propName] === true)
                                    &&
                                    component.options.condition.valueLowerLimit.toString().toLowerCase() !==  propName.toString().toLowerCase()) {
                                    return true;
                                }
                            }

                            return false;
                        }

                        return conditionComponent.answer.toString().toLowerCase() !== component.options.condition.valueLowerLimit.toString().toLowerCase();
                    case 'greater-than':
                        if (typeof conditionComponent.answer === 'object') {
                            for(var propName in conditionComponent.answer) {
                                if (typeof conditionComponent.answer[propName] !== "boolean") {
                                    var index = propName;
                                    propName = conditionComponent.answer[propName];
                                }

                                if ((propName === conditionComponent.answer[index || propName] || conditionComponent.answer[index || propName] === true)
                                    &&
                                    component.options.condition.valueLowerLimit < parseInt(propName, 10)) {
                                    return true;
                                }
                            }

                            return false;
                        }

                        return conditionComponent.answer > component.options.condition.valueLowerLimit;
                    case 'less-than':
                        if (typeof conditionComponent.answer === 'object') {
                            for(var propName in conditionComponent.answer) {
                                if (typeof conditionComponent.answer[propName] !== "boolean") {
                                    var index = propName;
                                    propName = conditionComponent.answer[propName];
                                }

                                if ((propName === conditionComponent.answer[index || propName] || conditionComponent.answer[index || propName] === true)
                                    &&
                                    component.options.condition.valueLowerLimit > parseInt(propName, 10)) {
                                    return true;
                                }
                            }

                            return false;
                        }

                        return conditionComponent.answer < component.options.condition.valueLowerLimit;
                    case 'between':
                        if (typeof conditionComponent.answer === 'object') {
                            for(var propName in conditionComponent.answer) {
                                if (typeof conditionComponent.answer[propName] !== "boolean") {
                                    var index = propName;
                                    propName = conditionComponent.answer[propName];
                                }

                                if ((propName === conditionComponent.answer[index || propName] || conditionComponent.answer[index || propName] === true)
                                    &&
                                    (component.options.condition.valueUpperLimit > parseInt(propName, 10) && component.options.condition.valueLowerLimit < parseInt(propName, 10))
                                ) {
                                    return true;
                                }
                            }

                            return false;
                        }

                        return conditionComponent.answer > component.options.condition.valueLowerLimit &&
                            conditionComponent.answer < component.options.condition.valueUpperLimit;
                }
            }

            function hasFormChangedSignificantly() {
                if (typeof vm.originalModel === "undefined" ||
                    typeof vm.originalModel.components === "undefined" ||
                    typeof vm.model === "undefined" ||
                    typeof vm.model.components === "undefined") {
                    return false;
                }

                var originalQuestions = getComponentsOfType('question', vm.originalModel.components),
                    currentQuestions = getComponentsOfType('question', vm.model.components);

                if (originalQuestions.length !== currentQuestions.length) {
                    return true;
                }

                var questionsMatch = true;
                angular.forEach(originalQuestions, function(originalQuestion) {
                    var currentQuestion = lodash.find(currentQuestions, {id: originalQuestion.id});

                    if (!currentQuestion ||
                        originalQuestion.options.type !== currentQuestion.options.type ||
                        originalQuestion.options.minLength < currentQuestion.options.minLength ||
                        originalQuestion.options.maxLength > currentQuestion.options.maxLength ||

                        ([false, null, undefined].indexOf(originalQuestion.options.required) !== -1 && originalQuestion.options.required !== currentQuestion.options.required)
                    ) {
                        return questionsMatch = false;
                    }
                });

                if (!questionsMatch) {
                    return true;
                }

                return false;
            }

            function isComponentCollapsible(component) {
                return getControls()[component.type].collapsible;
            }

            function CorrectInitialModelTypecast(model) {
                angular.forEach(model, function(value, index) {
                    if (angular.isArray(value) || typeof value === 'object') {
                        CorrectInitialModelTypecast(model[index]);

                        return;
                    }

                    if (!isNaN(parseFloat(value)) && isFinite(value)) {
                        if (parseInt(value, 10) === parseFloat(value, 10)) {
                            model[index] = parseInt(value, 10);

                            return;
                        }

                        model[index] = parseFloat(value, 10);

                        return;
                    }

                    if (typeof value === 'string') {
                        var tempMoment = moment.utc(value);
                        if (tempMoment.isValid() && tempMoment.toISOString() === value) {
                            model[index] = new Date(value);

                            return;
                        }
                    }
                });
            }

            function getChoiceOptionsByTableColumns(component) {
                var tableColumns = component.options.tableColumns || 1,
                    options = component.options.choices.slice();

                if (component.options.sortAlphabetically) {
                    options = options.sort();
                }

                return lodash.chunk(options, tableColumns);
            }

            function getMacroValue(macro) {
                vm.macro.push(elbFormService.getMacroValue(vm.form, vm.associatedEntities || {}, macro ));
                return elbFormService.getMacroValue(vm.form, vm.associatedEntities || {}, macro);
            }

            function saveAction() {
                typeof vm.save !== 'undefined' && vm.save();
            }

            function submitAction() {
                typeof vm.submit !== 'undefined' && vm.submit();
            }

            function cancelAction() {
                typeof vm.cancel !== 'undefined' && vm.cancel();
            }

            function canSaveSubmit(form, strict) {
                if (form.$valid) {
                    return true;
                } else if (strict) {
                    return false;
                }

                var returnValue = true;
                if (form.$error) {
                    angular.forEach(form.$error, function(requirement, index) {
                        angular.forEach(requirement, function(error, idx) {
                            if (!returnValue) {
                                return;
                            }

                            if (error.$dirty && error.$invalid) {
                                returnValue = false;
                            }
                        });
                    });
                }

                return returnValue;
            }

            function downloadAction(file) {
                elbDownloadService.download(file);
            }

            function removeChoice(choice, component, $index, key) {
                var component = lodash.find(vm.model.components, {id: component.id});

                if (component.options.choices[$index] === choice) {
                    component.options.choices.splice($index, 1);

                    return;
                }

                var index = component.options.choices.indexOf(choice);
                if (index !== -1) {
                    component.options.choices.splice(index, 1);
                }
            }

            function checkBetweenCondition(lowerLimit, upperLimit, $componentId) {
                if (upperLimit !== null && lowerLimit >= upperLimit){
                    messenger.error('VIOLATION_FORM_CONDITION_UPPER_LIMIT_LESS_THAN_LOWER_LIMIT');
                    vm.fieldHasError[$componentId] = true;
                } else {
                    vm.fieldHasError[$componentId] = false;
                }
            }
        }
    }
})();
