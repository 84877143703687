(function () {
    'use strict';

    angular
        .module('elogbooks.admin.spillage-types')
        .config(registerRoutes);

    function registerRoutes ($stateProvider) {
        $stateProvider
            .state('dashboard.admin.spillage-types', {
                abstract: true,
                templateUrl: '/shared-templates/view.html',
                ncyBreadcrumb: {
                    skip: true
                }
            })
            .state('dashboard.admin.spillage-types.list', {
                url: '/spillage-types',
                parent: 'dashboard.admin.spillage-types',
                resolve: {
                    spillageTypeCollectionResponse: function (apiClient, rootResourceResponse) {
                        if (rootResourceResponse.getLink('spillagetypes')) {
                            return apiClient.getPage(rootResourceResponse.getLink('spillagetypes'), { page: 1, limit: 999999 }).then(function (response) {
                                return response || apiClient.noResourceFound();
                            });
                        }
                        return null;
                    }
                },
                views: {
                    '@dashboard.admin.spillage-types': {
                        templateUrl: '/modules/common/spillage-types/spillage-types.html',
                        controller: 'SpillageTypesController',
                        controllerAs: 'vm'
                    }
                },
                ncyBreadcrumb: {
                    label: '{{ ::"SPILLAGE_TYPES" | translate }}'
                }
            })
            .state('dashboard.admin.spillage-types.list.add', {
                url: '/add/{parentResource}',
                parent: 'dashboard.admin.spillage-types.list',
                views: {
                    '@dashboard.admin.spillage-types': {
                        templateUrl: '/modules/common/spillage-types/add-edit/spillage-type-form.html',
                        controller: 'SpillageTypeAddEditController',
                        controllerAs: 'vm'
                    }
                },
                resolve: {
                    parentResponse: function (apiClient, $stateParams) {
                        if ($stateParams.parentResource) {
                            return apiClient.get($stateParams.parentResource.decode()).then(function (response) {
                                return response || apiClient.noResourceFound();
                            });
                        }

                        return null;
                    },
                    spillageTypeResponse: function () {
                        return {};
                    },
                    spillageTypeCreateLink: function(spillageTypeCollectionResponse) {
                        return spillageTypeCollectionResponse.getLink('create');
                    }
                },
                ncyBreadcrumb: {
                    label: '{{ ::"ADD" | translate }}'
                }
            })
            .state('dashboard.admin.spillage-types.list.edit', {
                url: '/edit/{spillageTypeResource}',
                parent: 'dashboard.admin.spillage-types.list',
                views: {
                    '@dashboard.admin.spillage-types': {
                        templateUrl: '/modules/common/spillage-types/add-edit/spillage-type-form.html',
                        controller: 'SpillageTypeAddEditController',
                        controllerAs: 'vm'
                    }
                },
                resolve: {
                    spillageTypeCollectionResponse: function (apiClient, rootResourceResponse, $stateParams) {
                        var resource = $stateParams.spillageTypeResource.decode();
                        var parts = resource.split('/');
                        var exclude = parts.pop();
                        if (rootResourceResponse.getLink('spillagetypes')) {
                            return apiClient.getPage(rootResourceResponse.getLink('spillagetypes'), { page: 1, limit: 999999, active: 1, exclude: exclude}).then(function (response) {
                                return response || apiClient.noResourceFound();
                            });
                        }
                        return null;
                    },
                    spillageTypeResponse: function (apiClient, base64, $stateParams) {
                        return apiClient.get($stateParams.spillageTypeResource.decode()).then(function (response) {
                            return response || apiClient.noResourceFound();
                        });
                    },
                    parentResponse: function (apiClient, spillageTypeResponse) {
                        if (spillageTypeResponse.getLink('parent')) {
                            return apiClient.get(spillageTypeResponse.getLink('parent')).then(function (response) {
                                return response || apiClient.noResourceFound();
                            });
                        }

                        return null;
                    },
                    spillageTypeCreateLink: function(spillageTypeCollectionResponse) {
                        return null;
                    }
                },
                ncyBreadcrumb: {
                    label: '{{ ::"EDIT" | translate }}'
                }
            });
    }
})();
