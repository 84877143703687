(function () {
    'use strict';

    angular
        .module('elogbooks.common.jobs.workflow')
        .controller('JobReassignController', JobReassignController);

    JobReassignController.$inject = [
        'userManager',
        'apiClient',
        '$state',
        'messenger',
        'jobResponse',
        'formData',
        'scopeData',
        '$translate',
        '$q',
        'priorityService',
        'operativeCollectionResponse',
        'siteResponse',
        'serviceProviderService',
        'elbSettings',
        'confirmationModal'
    ];

    function JobReassignController (
        userManager,
        apiClient,
        $state,
        messenger,
        jobResponse,
        formData,
        scopeData,
        $translate,
        $q,
        priorityService,
        operativeCollectionResponse,
        siteResponse,
        serviceProviderService,
        elbSettings,
        confirmationModal
    ) {

        var vm = this;
        vm.job = jobResponse;
        angular.extend(vm, scopeData);

        vm.prosureEnabled = elbSettings.getSetting('prosure_enabled').value;
        vm.submit = submitAction;

        vm.changeServiceProvider = changeServiceProvider;
        vm.changePriority = changePriority;
        vm.changeOperative = changeOperative;
        vm.removeJobRequisite = removeJobRequisite;
        vm.selectedPriorityModel.onSelect = vm.selectedPriorityModel.onRemove = vm.changePriority;

        vm.selectedServiceProviderModel.onSelect = vm.selectedServiceProviderModel.onRemove = vm.changeServiceProvider;
        vm.selectedServiceProviderModel.itemDisabledKey = '_links.serviceprovider.contractorApproved';
        vm.selectedServiceProviderModel.itemDisabledKeyValue = false;
        vm.selectedServiceProviderModel.itemDisabledHint = 'NOT_APPROVED';
        vm.selectedServiceProviderModel.itemValuePath = '_links.serviceprovider.title';
        vm.selectedServiceProviderModel.formatValue = serviceProviderService.formatServiceProviderName;
        vm.selectedServiceProviderModel.headers = serviceProviderService.isProsureEnabled ? serviceProviderService.prosureStatusHeaders : null;
        vm.selectedServiceProviderModel.reorder = serviceProviderService.prosureGroupReorder;

        if (vm.prosureEnabled) {
            vm.selectedServiceProviderModel.loadmoreEnabled = true;
        }

        vm.selectedJobRequisites = [];

        vm.selectedOperativeModel = {
            response: operativeCollectionResponse ? operativeCollectionResponse : null,
            responseKeyPath: 'operatives',
            itemValuePath:'_links.self.title',
            onSelect: vm.changeOperative,
            onRemove: vm.changeOperative,
            selected: vm.job._links.operative
        };

        vm.selectedJobRequisiteModel = {
            linkParameters: {active:1},
            required: false,
            responseKeyPath: 'jobRequisites',
            itemHrefPath: '_links.self.href',
            itemValuePath: 'name',
            onSelect: changeJobRequisite,
            onRemove: changeJobRequisite
        };

        vm.data = formData;

        vm.data.isByPlannedDate = ! (vm.data._links.priority && vm.data._links.priority.href);
        vm.data.isOnNextPlannedVisit = false;

        vm.data.assets = {
            asset: vm.job._links.asset,
            subasset: vm.job._links.subasset
        }

        if (jobResponse.getLink('subasset')) {
            vm.selectedAssetModel.selected = jobResponse._links.subasset;
            vm.selectedAssetModel.selected.createdFromSubasset = true;
        } else {
            vm.selectedAssetModel.selected = jobResponse._links.asset;
        }

        function getJobRequisites() {
            apiClient.get(userManager.user.getLink('job-requisites'), {
                active: 1,
                optIn: true,
                optOut: true,
                'option[]': ['reactiveJobOption', 'plannedJobOption'],
                site: siteResponse.id,
                serviceProvider: vm.selectedServiceProviderModel.selected.object.getLinkAttribute('serviceprovider', 'id')
            }).then(function(response) {
                vm.selectedJobRequisites = [];
                vm.selectedJobRequisiteModel.response = response;
                response.jobRequisites.forEach(function (jr) {
                    if (jr.reactiveJobOption === 3) { //opt_out
                        vm.selectedJobRequisites.push(jr);
                    }
                });
            });
        }

        function submitAction () {
            const action = () => {
                if (vm.data._links.priority) {
                    vm.data.attendanceDueAt = vm.data.completionDueAt = null;
                }

                if (typeof vm.data.assets !== 'undefined') {
                    if (vm.data.assets.asset) {
                        vm.data._links.asset = vm.data.assets.asset;
                    }

                    if (vm.data.assets.subasset) {
                        vm.data._links.subasset = vm.data.assets.subasset;
                    }
                } else {
                    vm.data._links.asset = null;
                    vm.data._links.subasset = null;
                }

                angular.forEach(vm.selectedJobRequisites, function(jobRequisite) {
                    vm.data.optedInJobRequisites.links.push({  // todo update link
                        href: jobRequisite.getLink('self')
                    });
                });

                return apiClient.create(vm.job.getLink(vm.action), vm.data).then(function (response) {
                    if (response) {
                        return $state.go('^.^', {}, { reload: $state.get('^.^.^') }).then(function () {
                            messenger.success(vm.translation + '_SUCCESS');
                        });
                    }

                    messenger.error('REQUEST_ERROR');
                });
            };

            if (vm.prosureEnabled && vm.prosureWarningMessage) {
                confirmationModal.open({
                    bodyMessage: vm.prosureWarningMessage,
                    titleMessage: 'ARE_YOU_SURE_HEADER',
                    primaryText: 'CONTINUE',
                    secondaryText: 'CANCEL'
                }).result.then(() => {
                  action();
                });
            } else {
                action();
            }
        }

        function changeJobGroup () {
            vm.data._links.jobgroup = vm.selectedJobGroupModel.selected.object ? { href: vm.selectedJobGroupModel.selected.href} : null;
        }

        vm.spPartnership = {
            linkParameters: {
                siteId: siteResponse.id,
                active: true,
                entityTypes: 'entity_type_all,entity_type_job'
            }
        };

        if (vm.selectedServiceProviderModel.selected) {
            apiClient.get(vm.selectedServiceProviderModel.selected.object.getLink('partnerships'), vm.spPartnership.linkParameters).then(function (partnershipResponse) {
                setPartnershipPriorities(partnershipResponse.partnerships[0]);
            });
        }

        /**
         * If the service provider's partnership has priorities set for the SP, we display only partnership priorities in the Priority dropdown list
         * @param partnershipResponse
         * @param callback
         */
        function setPartnershipPriorities(partnershipResponse, callback) {
            apiClient.get(partnershipResponse.getLink('priorities')).then(function (response) {
                var partnershipPriorities = response.priorities.filter(function (p) {
                    return p.type === 'partnership-priority' && (p.entityType === 'entity_type_all' || p.entityType === 'entity_type_job');
                });

                if (partnershipPriorities.length >= 1) {
                    response.priorities = partnershipPriorities;
                    vm.selectedPriorityModel.response = priorityService.addExtraPriorities(response);
                    vm.selectedPriorityModel.link = vm.selectedServiceProvider.getLink('priorities');
                    callback(true);
                } else {
                    callback(false);
                }
            });
        }

        if (vm.prosureEnabled && vm.selectedServiceProviderModel && vm.selectedServiceProviderModel.selected) {
            vm.prosureWarningMessage = serviceProviderService.setProsureWarningMessage(vm.selectedServiceProviderModel.selected);
        }

        function changeServiceProvider () {
            vm.prosureWarningMessage = null;

            if (vm.selectedServiceProviderModel && vm.selectedServiceProviderModel.selected) {
                vm.submitDisabled = true;
                vm.selectedJobRequisites = [];

                if (vm.prosureEnabled) {
                    vm.prosureWarningMessage = serviceProviderService.setProsureWarningMessage(vm.selectedServiceProviderModel.selected);
                }

                getJobRequisites();

                apiClient.get(vm.selectedServiceProviderModel.selected.href).then(function (response) {
                    if (response) {
                        vm.selectedServiceProvider = response;
                        vm.selectedPriorityModel.loading = true;
                        vm.data._links.serviceprovider = {href: vm.selectedServiceProvider.getLink('self')};
                        vm.selectedOperativeModel.selected = null;
                        vm.selectedPriorityModel.selected = null;
                        vm.selectedOperativeModel.onSelect();
                        vm.changeOperative();
                        vm.selectedAssetModel.required = vm.selectedAssetModel.response.count > 0
                            && vm.selectedServiceProvider.requiresAssetsWhenLoggingReactiveJob;

                        if (vm.selectedServiceProvider.getLink('joboperatives')) {
                            var params = {
                                permission: 'operative_permission_receive_jobs',
                                includeServiceTypes: true
                            };

                            if (jobResponse.completionDueAt) {
                                params.jobCompletionDueAt = new Date(jobResponse.completionDueAt)
                            }

                            if (siteResponse) {
                                params.siteId = siteResponse.id;
                            }

                            apiClient.get(vm.selectedServiceProvider.getLink('joboperatives'), params)
                                .then(function(response) {
                                    if (response.operatives) {
                                        vm.selectedOperativeModel.response = response;
                                    } else {
                                        vm.selectedOperativeModel.response = null;
                                    }
                                });
                        } else {
                          vm.selectedOperativeModel.response = null;
                        }

                        $q.all({
                            priorities: apiClient.get(vm.selectedServiceProvider.getLink('priorities'), vm.selectedPriorityModel.linkParameters),
                            partnershipResponse: apiClient.get(vm.selectedServiceProvider.getLink('partnerships'), vm.spPartnership.linkParameters)
                        }).then(function (promises) {
                            if (!promises.priorities) {
                                apiClient.noResourceFound();
                            }

                            setPartnershipPriorities(promises.partnershipResponse.partnerships[0], function (spHasPartnershipPriorities) {
                                if (!spHasPartnershipPriorities) {
                                    if (promises.priorities.priorities.length) {
                                        vm.selectedPriorityModel.response = priorityService.addExtraPriorities(promises.priorities);
                                        vm.selectedPriorityModel.link = vm.selectedServiceProvider.getLink('priorities');
                                    } else {
                                        vm.selectedPriorityModel.response = priorityService.addExtraPriorities(vm.priorities);
                                        vm.selectedPriorityModel.link = vm.priorities.getLink('self');
                                    }
                                }
                            });

                            checkPermission();

                            vm.submitDisabled = false;
                            vm.selectedPriorityModel.loading = false;
                        });

                        return;
                    }

                    return apiClient.noResourceFound();
                });
            } else {
                vm.selectedServiceProvider = null;
                vm.selectedOperativeModel.selected = null;
                vm.selectedOperativeModel.onSelect();
                changeOperative();
                vm.data._links.serviceprovider = null;
            }
        }

        function changePriority () {
            if (vm.selectedPriorityModel && vm.selectedPriorityModel.selected) {
                vm.data.isByPlannedDate = vm.selectedPriorityModel.selected.value === $translate.instant('BY_PLANNED_DATE');
                vm.data.isOnNextPlannedVisit = vm.selectedPriorityModel.selected.value === $translate.instant('JOB_NEXT_PLANNED_VISIT');

                if (vm.data.isByPlannedDate || vm.data.isOnNextPlannedVisit) {
                    delete vm.data._links.priority;
                } else {
                    vm.data._links.priority = {href: vm.selectedPriorityModel.selected.href};
                }
            } else {
                delete vm.data._links.priority;
                vm.data.isByPlannedDate = vm.data.isOnNextPlannedVisit = false;
            }
        }

        function checkPermission () {
            if (vm.selectedPriorityModel.selected) {
                var valid = false;

                angular.forEach(vm.selectedPriorityModel.response.priorities, function (value, index) {
                    if (value._links.self.href === vm.selectedPriorityModel.selected.href) {
                        valid = true;
                    }
                });

                if (! valid) {
                    vm.selectedPriorityModel.selected = null;
                }
            }
        }

        function changeOperative () {
            if (
                vm.selectedOperativeModel.selected
                && typeof vm.selectedOperativeModel.selected !== 'undefined'
            ) {
                vm.data._links.operative = vm.selectedOperativeModel.selected;
            } else {
                vm.selectedOperativeModel.selected = null;
                delete vm.data._links.operative;
            }
        }

        function changeJobRequisite() {
            if (vm.selectedJobRequisiteModel.selected && vm.selectedJobRequisiteModel.selected.object) {
                var isAlreadySelected = false;

                angular.forEach(vm.selectedJobRequisites, function(value, index) {
                    if (value.getLink('self') === vm.selectedJobRequisiteModel.selected.object.getLink('self')) {
                        isAlreadySelected = true;
                    }
                });

                if (!isAlreadySelected) {
                    vm.selectedJobRequisites.push(vm.selectedJobRequisiteModel.selected.object);
                }

                vm.selectedJobRequisiteModel.selected = null;
            }
        }

        function removeJobRequisite(jobRequisite) {
            angular.forEach(vm.selectedJobRequisites, function(value, index) {
                if (value.getLink('self') === jobRequisite.getLink('self')) {
                    vm.selectedJobRequisites.splice(index, 1);
                }
            });
        }

        getJobRequisites();
    }
})();
