(function () {
    'use strict';

    angular
        .module('elogbooksFilters')
        .filter('operativeTypes', OperativeTypes);

    function OperativeTypes ($filter) {
        var operativeTypes = {
            0: 'JOB',
            1: 'ESCALATION',
            2: 'QUOTE',
            3: 'AUDIT',
            4: 'HEALTH_AND_SAFETY',
            5: 'PATROL',
            6: 'SURVEY'
        };

        return function (input) {
            return $filter('translate')(operativeTypes[input]);
        }
    };
})();
