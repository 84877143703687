(function() {
    'use strict';

    angular
        .module('elogbooks')
        .config(Translations);

    function Translations($translateProvider) {
        $translateProvider.translations('en-gb', {
            EXTEND_SESSION_HEADER: 'Extend Session',
            EXTEND_SESSION_BODY: 'You will be automatically logged out in <strong>{{ ::timeRemaining }}</strong>. To extend your session by 24 hours, click \'Extend\' below.',
            EXTEND_SESSION: 'Extend',
            UPDATE_POSTPONE: 'Postpone Update',
            LOGOUT: 'Logout'
        });
    }
})();
