(function() {
    'use strict';

    angular
        .module('elogbooks.admin.charge-types')
        .controller('ChargeTypesInfoController', ChargeTypesInfoController);

    ChargeTypesInfoController.$inject = ['$state', 'apiClient', 'confirmationModal', 'messenger', 'chargeTypeResponse'];
    function ChargeTypesInfoController($state, apiClient, confirmationModal, messenger, chargeTypeResponse) {
        var vm = this;
        vm.chargeType = chargeTypeResponse;
    }
})();
