angular.module('elogbooksDirectives').directive('elbPlannerLegacy', function () {
    //TODO refactor and make it simple
    return {
        restrict: 'A',
        templateUrl:
            function ($element, $attrs) {
                switch ($attrs.type) {
                    case 'generation-request-planner':
                        return '/modules/directives/planner/generation-requests-planner.html';
                    case 'master-tasks':
                        return '/modules/directives/planner/master-tasks.html';
                }
                return '/modules/directives/planner/planner.html';
            },
        scope: {
            task: '=task',
            tasksCollection: '=ngModel',
            serviceProvidersCollection: '=serviceProviders',
            siteResource: '=',
            rootResourceResponse: '<',
            year: '=year',
            mode: '@mode',
            type: '@type',
            tidyTablesModel: '=',
            pagePrefix: '@pagePrefix'
        },
        controller: [
            '$scope',
            '$state',
            '$stateParams',
            '$uibModal', 'messenger',
            'lodash',
            'confirmationModal',
            'confirmationModalNotes',
            'apiClient',
            '$window',
            'bulkCancelTasksModal',
            '$rootScope',
            '$translate',
            'moment',
            function (
                $scope,
                $state,
                $stateParams,
                $uibModal,
                messenger,
                lodash,
                confirmationModal,
                confirmationModalNotes,
                apiClient,
                $window,
                bulkCancelTasksModal,
                $rootScope,
                $translate,
                moment
            ) {
            $scope.tasks = $scope.tasksCollection ? $scope.tasksCollection.tasks : [];
            $scope.year = $scope.year ? $scope.year : (new Date()).getFullYear();
            $scope.months = getMonths($scope.year);
            $scope.week = (new Date()).getFullYear() == $scope.year ? moment(new Date()).isoWeek() : null;
            $scope.weeks = moment(new Date($scope.year, 0, 1)).isoWeeksInYear();
            $scope.range = lodash.range;
            $scope.allSelected = false;
            $scope.tidyTables = $scope.tidyTablesModel.columns;
            $scope.selectedTasks = [];
            $scope.planner = buildPlanner($scope.tasks);
            $scope.pagePrefix = $scope.pagePrefix ? $scope.pagePrefix : 'task';
            $scope.getTaskEventClass = getTaskEventClass;
            $scope.findEvent = findEvent;
            $scope.moveEvent = moveEvent;
            $scope.openModal = openModal;
            $scope.editTaskModal = editTaskModal;
            $scope.toggleAll = toggleAll;
            $scope.bulkSend = bulkSend;
            $scope.deleteRequest = deleteRequest;
            $scope.getTaskEventRRule = getTaskEventRRule;
            $scope.onServiceProviderChange = onServiceProviderChange;
            $scope.onServiceProviderBulkChange = onServiceProviderBulkChange;
            $scope.order = orderAction;
            $scope.rootResourceResponse = $scope.rootResourceResponse;
            $scope.selectedTasks = 0;

            $scope.advancedPPmPlanner = false;

            if ($scope.tasks.length > 0) {
                $scope.taskEdit = !!$scope.tasks[0].getLink('edit');
            }

            if ($scope.type == 'generation-request-planner') {
                $scope.totalRow = buildTotalRow($scope.tasksCollection.events);

                for (var i = 0; i < $scope.tasks.length; i++) {
                    //Check approval status of generation request
                    if ($scope.tasks[i].approvalStatus <= 1) {
                        $scope.planner.actions = true;
                    }
                }
            }

            if ($scope.tasksCollection.getLink('canceltaskevents')) {
                $scope.cancelTask = true;
            } else {
                $scope.cancelTask = false;
            }

            $scope.$watch("tidyTables", function(columns) {
                var count = 0;

                angular.forEach(columns, function(column) {
                    if(column === true) {
                        count++;
                    }
                });
                var tableClass = 'col-md-';
                var defaultCol = '5';
                var totalCols = '10';
                var leftColCount = 5;
                $scope.expandLeft = tableClass + defaultCol;
                $scope.expandRight = tableClass + defaultCol;
                var totalCount = Object.keys(columns).length;

                $scope.visColCount = 1 + count; // total visible columns plus title column
                $scope.visColCount += ~~($scope.taskEdit && ($scope.mode!='view' && $scope.mode!='approval')); // checkbox column
                $scope.visColCount += ~~(($scope.planner.actions && $scope.mode!='view') || ($scope.cancelTask && $scope.mode=='view')); // edit column

                var half = totalCount / 2;

                if (count > half) {
                    leftColCount = totalCount + (count - half);
                } else if (count < half) {
                    leftColCount = totalCount - (half - count);
                }

                $scope.expandLeft = tableClass + leftColCount;
                $scope.expandRight = tableClass + (totalCols - leftColCount);

                setTimeout(function () {
                    $window.dispatchEvent(new Event("resize"));
                }, 0);
            }, true);

            $rootScope.$on('taskModalLoaded', function () {
                for (var i = 0; i < $scope.tasks.length; i++) {
                    $scope.tasks[i].loading = false;
                }
            });

            if ($scope.serviceProvidersCollection) {
                $scope.serviceProviderSelect = {
                    response : $scope.serviceProvidersCollection,
                    link : $scope.serviceProvidersCollection ? $scope.serviceProvidersCollection.getLink('self') : null,
                    responseKeyPath: 'serviceproviders'
                };

                $scope.providers = lodash.transform($scope.serviceProvidersCollection.serviceproviders,
                    function (result, n) {
                        result.push(
                            {
                                value: n._links.self.href,
                                title: n.name
                            }
                        );
                    });
            }

            // Hide action buttons on MY Planner when user have no action links
            if ($scope.mode != 'approval'
                && !$scope.tasksCollection.getLink('deletetaskevents')
                && !$scope.tasksCollection.getLink('requestapprove')
            ) {
                $scope.planner.actions = false;
            }

            if ($scope.mode != 'approval'
                && $scope.tasksCollection.getLink('tasksgenerate')
            ) {
                $scope.planner.actions = true;
            }

            if ($scope.week) {
                var element = angular.element(document.querySelector('.ppm-table-months'));
                element.animate({scrollLeft: ($scope.week - 1) * 35}, 1000);
            }

            $scope.$watch("tasks", function(tasks) {
                $scope.selectedTasks = lodash.filter(tasks, function(task) {
                    return task.checked;
                }).length;
            }, true);

            function getTaskEventClass(taskEvent, weekNr, excludeClass) {
                var classString = $scope.week > weekNr ? 'grey' : '';

                if (taskEvent === false || taskEvent === undefined) {
                    return classString;
                }

                // quick solution EWV-8319 hide ignored event
                if (taskEvent.ignored && $scope.mode == 'view') {
                    return classString;
                }

                if (taskEvent) {
                    classString += taskEvent.ignored ? ' ignored' : '';

                    if (taskEvent){
                        switch (taskEvent.status) {
                            case 0:
                                classString += ' rejected';
                                break;
                            case 1:
                                classString += ' draft';
                                break;
                            case 2:
                                classString += ' approval-pending';
                                break;
                            case 3:
                                classString += ' approved';
                                break;
                            case 10:
                                classString += ' ghost';
                                break;
                            case 11:
                                classString += ' ghost-ignored';
                                break;
                            case 12:
                                classString += ' ghost-approval-pending';
                                break;
                            case 13:
                                classString += ' ghost-rejected';
                                break;
                            case 14:
                                classString += ' ghost-approved';
                                break;
                        }
                    }
                }

                if ($scope.mode == 'view'
                    && typeof taskEvent.jobStatus !== 'undefined'
                ) {
                    classString = "";

                    switch (taskEvent.jobStatus) {
                        case 7:
                            if (new Date(taskEvent.jobCompletionDueAt) < new Date() ||
                                new Date(taskEvent.jobAttendenceDueAt) < new Date()) {
                                classString += ' job-overdue';
                            } else {
                                classString += ' job-approved';
                            }
                            break;
                        case 8:
                            classString += ' job-in-progress';

                            if (new Date(taskEvent.jobCompletionDueAt) < new Date()) {
                                classString += ' job-overdue';
                            }

                            break;
                        case 9:
                            classString += ' job-missed';
                            break;
                        case 11:
                            classString += ' job-completed';
                            break;
                        case 12:
                        case 16:
                            classString += ' job-awaiting-paperwork';
                            break;
                        default:
                            if (new Date(taskEvent.date) < new Date()) {
                                classString += ' job-overdue';
                            }
                    }
                }

                if (taskEvent) { // stop errors from occuring in console
                    if (taskEvent._links !== undefined) {
                        if (taskEvent._links.statutorytype && !excludeClass) {
                            classString += ' statutory-job';
                        }
                    }

                    if (taskEvent.documentTypes && taskEvent.documentTypes.length && !excludeClass) {
                        classString += ' document-types-required';
                    }
                }

                return classString;
            }

            function getTaskEventRRule(taskEvent) {
                if (taskEvent === false || taskEvent === undefined) {
                    return '';
                }
                // quick solution EWV-8319 hide ignored event
                if (taskEvent.ignored  && $scope.mode == 'view') {
                    return '';
                }

                if (taskEvent){
                    var frequency = '';
                    var interval = angular.isNumber(taskEvent.interval) && taskEvent.interval > 0 ? taskEvent.interval : '';

                    switch(taskEvent.frequency) {
                        case 3:
                            frequency = 'D';
                            break;
                        case 4:
                            frequency = 'W';
                            break;
                        case 5:
                            frequency = 'M';
                            break;
                        case 6:
                            frequency = 'A';
                            break;
                    }

                    return interval + ' ' + frequency;
                }

                return '';
            }

            function buildPlanner(tasks) {
                var planner = {
                    actions : true,
                    approvalPending : 0
                };

                /**
                 * 2 = pending_approval
                 * 12 = future pending_approval
                 */
                var approvalStatusMap = [2,12];

                for (var i = 0; i < tasks.length; i++) {
                    tasks[i].loading = false;
                    tasks[i].actions = !!$scope.tasksCollection.getLink('tasksgenerate');
                    var taskEvents = tasks[i].events;

                    var mappedEvents = {};
                    //set planner.actions
                    for (var j = 0; j < taskEvents.length; j++) {
                        //Show only approved and future approved)
                        if ($scope.mode == 'view' && !(taskEvents[j].status == 3 || taskEvents[j].status == 14)) {
                            continue;
                        }

                        //Skip all not ready for approval
                        if ($scope.mode == 'approval' && approvalStatusMap.indexOf(taskEvents[j].status) === -1) {
                            continue;
                        }

                        if ($scope.mode != 'approval') {
                            if (taskEvents[j].status == 2) {
                                planner.approvalPending += 1;
                            }
                            if (taskEvents[j].status < 2) {
                                tasks[i].actions = true;
                                planner.actions = true;
                            }
                        } else if ($scope.mode == 'approval'){
                            if (taskEvents[j].status == 2 || taskEvents[j].status == 12) {
                                planner.approvalPending += 1;
                                //Skip all not ready for approval
                                if (typeof tasks[i]._links.approve !== 'undefined') {
                                    tasks[i].actions = true;
                                    planner.actions = true;
                                }
                            }
                        }

                        mappedEvents[moment(taskEvents[j].date).isoWeek()] = taskEvents[j];
                    }

                    planner[tasks[i].id] = mappedEvents;
                }

                return planner;
            }

            function buildTotalRow(events) {
                var taskEvents = events;

                var mappedEvents = {};

                for (var j = 0; j < taskEvents.length; j++) {
                    mappedEvents[moment(taskEvents[j].date).isoWeek()] = taskEvents[j];
                }

                return mappedEvents;
            }

            function findEvent(taskId, weekNr) {
                return typeof $scope.planner[taskId][weekNr] !== "undefined" ? $scope.planner[taskId][weekNr] : false;
            }

            function moveEvent(taskId, weekFrom, weekTo, element) {
                var taskEvent = $scope.planner[taskId][weekFrom];
                var url = typeof taskEvent.getLink === 'function' ? taskEvent.getLink('self') : false;
                var requestData = {
                    date: moment(taskEvent.date).subtract(weekFrom-weekTo, 'week'),
                    originalDate: taskEvent.date,
                    task: taskId,
                    rule: taskEvent.rule
                };

                if (!url) {
                    url = '/tasks/events';
                }

                apiClient.update(url, requestData).then(function (response) {
                    if (response) {
                        $scope.planner[taskId][weekTo] = response;
                        delete $scope.planner[taskId][weekFrom];

                        setTimeout(function () {
                            element.removeClass('dropped')
                        }, 500)
                    } else {
                        $state.go('.', $stateParams, {reload:$state.current}).then(function () {
                            messenger.error('REQUEST_ERROR')
                        });
                    }
                });
            }

            function editTaskModal(task, index) {
                task.loading = true;
                $uibModal.open({
                    templateUrl: '/modules/user/tasks/modal/task-modal.html',
                    controller: 'UserTaskModalUpdateController',
                    controllerAs: 'vm',
                    size: 'lg',
                    backdrop: 'static',
                    resolve : {
                        taskResponse: function () {
                            return apiClient.get(task.getLink('self'));
                        },
                        siteResponse: function() {
                            return apiClient.get(task.getLink('site'));
                        },
                        rootResourceResponse: function() {
                            return $scope.rootResourceResponse;
                        },
                        planner: {mode : $scope.mode},
                        hasEvents: function () {
                            return !!task.events.length;
                        },
                        nonVersionedServiceRoutinesCollectionResponse : function() {
                            return apiClient.get($scope.rootResourceResponse.getLink('serviceroutines'), { active: 1, isVersioned: 0 });
                        },
                        versionedServiceRoutinesCollectionResponse: function() {
                            return apiClient.get($scope.rootResourceResponse.getLink('serviceroutines'), { active: 1, isVersioned: 1 });
                        },
                    }
                })
                .result.then(function (response) {
                    if (response.action === 'delete') {
                        removeTask(response.task);
                    } else  if (response.action === 'update') {
                        refreshTaskRow(response.task, index);
                    }
                });
            }

            /**
             * @param task
             * @param weekNr
             */
            function openModal(task, weekNr) {
                var taskEvent = null;

                if (task == 'total') {
                    task = $scope.task;
                    taskEvent = typeof $scope.totalRow[weekNr] !== 'undefined' ? $scope.totalRow[weekNr] : false;
                } else {
                    taskEvent = findEvent(task.id, weekNr);
                }

                if (!taskEvent) {
                    if ($scope.mode == 'approval' || !$scope.tasksCollection.canGenerate) {
                        return;
                    }

                    taskEvent = {
                        _links: {},
                        date : new Date(moment.utc($scope.year + weekNr, "YYYYW").startOf('isoweek')).toISOString()
                    };
                }

                var eventModal = $uibModal.open({
                    templateUrl: '/modules/user/tasks/modal/task-event-modal.html',
                    controller: 'UserTaskEventModalController',
                    controllerAs: 'vm',
                    resolve : {
                        task : function () {
                            return apiClient.get(task.getLink('self'));
                        },
                        taskEvent : function (apiClient) {
                            if (taskEvent
                                && typeof taskEvent._links !== 'undefined'
                                && typeof taskEvent._links.self !== 'undefined'
                            ) {
                                return apiClient.get(taskEvent.getLink('self'));
                            }

                            return taskEvent
                        },
                        planner: {mode : $scope.mode},
                        cancellingJob: false
                    }
                });

                eventModal.result.then(function (response) {
                    if (typeof response.action !== 'undefined' && response.action == 'delete') {
                        if ($scope.type == 'generation-request-planner') {
                            $state.go($state.current, $stateParams, {'reload': $state.current});
                        } else {
                            //remove event from task events
                            removeTaskEvent(task, weekNr);
                        }
                    } else {
                        if ($scope.type == 'generation-request-planner') {
                            $scope.totalRow[weekNr] = response;
                        } else {
                            $scope.planner[task.id][weekNr] = response;
                        }
                    }
                });
            }

            function removeTask(task) {
                delete $scope.planner[task.id];
                $scope.tasks.splice(lodash.findKey($scope.tasks, ['id', task.id]), 1);
                $scope.planner = buildPlanner($scope.tasks);
            }

            /**
             * Remove task event and refresh planner
             *
             * @param task
             * @param weekNr
             *
             * @returns null
             */
            function removeTaskEvent(task, weekNr) {
                delete $scope.planner[task.id][weekNr];

                var taskEvents = $scope.planner[task.id];
                task.actions = false;

                for (var key in taskEvents) {
                    if ($scope.mode == 'approval' && !task.getLink('approve') && taskEvents[key].status != 3) {
                        continue;
                    }

                    if ($scope.mode != 'approval') {
                        if (taskEvents[key].status < 2
                            || (taskEvents[key].status == 2 && $scope.tasksCollection.getLink('tasksgenerate'))
                            || (taskEvents[key].status == 12 && $scope.tasksCollection.getLink('tasksgenerate'))
                            || taskEvents[key].status == 10 //draft
                            || taskEvents[key].status == 13
                        ) {
                            task.actions = true;
                        }
                    }
                }

                refreshPlanner();
            }

            function refreshTaskRow(task, index) {
                if (task) {
                    apiClient.get(task.getLink('self')).then(function (response){
                        var events = $scope.tasks[index].events;
                        $scope.tasks[index] = response;
                        $scope.tasks[index].events = events;
                        $scope.planner = buildPlanner($scope.tasks);
                    });
                }
            }

            function refreshPlanner() {
                for (var i = 0; i < $scope.tasks.length; i++) {
                    $scope.planner.actions = false;

                    if ($scope.tasks[i].actions) {
                        $scope.planner.actions = true;
                    }
                }
            }

            /**
             * @param year
             *
             * @returns {array}
             */
            function getMonths(year) {
                var months = [
                    {name:'JAN'},
                    {name:'FEB'},
                    {name:'MAR'},
                    {name:'APR'},
                    {name:'MAY'},
                    {name:'JUN'},
                    {name:'JUL'},
                    {name:'AUG'},
                    {name:'SEP'},
                    {name:'OCT'},
                    {name:'NOV'},
                    {name:'DEC'}
                ];

                var date = new Date(year, 0, 1);

                while (date.getFullYear() == year) {
                    var tempDate = new Date(+date);
                    tempDate.setHours(0,0,0);
                    //Get the Thursday in the week
                    tempDate.setDate(tempDate.getDate() + 4 - (tempDate.getDay()||7));

                    if (tempDate.getFullYear() == year) {
                        if (typeof months[tempDate.getMonth()].weeks === 'undefined') {
                            months[tempDate.getMonth()].weeks = 0;
                        }

                        months[tempDate.getMonth()].weeks += 1;
                    }

                    //Move date to next week
                    date.setDate(date.getDate()+7);
                }

                return months;
            }

            function toggleAll() {
                if (this.allSelected) {
                    lodash.each($scope.tasks, function (task) {
                        task.checked = true;
                    });
                } else {
                    lodash.each($scope.tasks, function (task) {
                        task.checked = false;
                    });
                }
            }

            function bulkSend(action) {
                var params = {
                    'siteIds': $stateParams.siteIds,
                    'statutoryType': $stateParams.taskStatutoryType,
                    'regionId': $stateParams.regionId ? JSON.parse($stateParams.regionId).v : null,
                    'assetId': $stateParams.taskAssetId ? JSON.parse($stateParams.taskAssetId).v : null,
                    'year': $stateParams.taskYear,
                    'serviceType': $stateParams.taskServiceType,
                    'status': $stateParams.taskStatus,
                    'operativeName': $stateParams.taskOperativeName,
                    'serviceProviderName': $stateParams.taskServiceProviderName,
                    'frequency': $stateParams.taskFrequency,
                    'approvalStatus': $stateParams.taskApprovalStatus,
                    'masterTask': $stateParams.taskMasterTask,
                    'order': $stateParams.taskOrder,
                    'name': $stateParams.taskName,
                    'page': $stateParams.taskPage,
                    'limit': $stateParams.taskLimit,
                    'ppmPlanner': $stateParams.ppmPlanner,
                    'datagroup': $stateParams.datagroup,
                    'fromDashboard' : $stateParams.fromDashboard
                };
                var requestData = {
                    _links : {
                        task : [

                        ]
                    },
                    year : $scope.year
                };

                if ($scope.type == 'generation-request-planner') {
                    requestData._links.task.push({href:$scope.task.getLink('self')});
                } else {
                    for (var i = 0; i < $scope.tasks.length; i++) {
                        if ($scope.tasks[i].checked) {
                            requestData._links.task.push({href:$scope.tasks[i].getLink('self')});
                        }
                    }
                }

                if (requestData._links.task.length > 0
                    || action.indexOf('filtered') !== -1) {
                    var url = null;

                    switch (action) {
                        case 'approve':
                        case 'approve-filtered':
                            url = $scope.tasksCollection.getLink('approve');

                            break;
                        case 'approve-request':
                        case 'approve-request-filtered':
                            url = $scope.tasksCollection.getLink('requestapprove');
                            break;
                        case 'delete-events':
                        case 'delete-events-filtered':
                            url = $scope.tasksCollection.getLink('deletetaskevents');
                            break;
                        case 'cancel-events':
                        case 'cancel-events-filtered':
                            url = $scope.tasksCollection.getLink('canceltaskevents');
                            break;
                        case 'decline':
                        case 'decline-filtered':
                            url = $scope.tasksCollection.getLink('decline');
                            break;
                        case 'generate':
                        case 'generate-filtered':
                            url = $scope.tasksCollection.getLink('tasksgenerate');
                            break;
                    }

                    switch (action) {
                        case 'approve-filtered':
                        case 'decline-filtered':
                        case 'approve-request-filtered':
                        case 'delete-events-filtered':
                        case 'generate-filtered':
                        case 'cancel-events-filtered':
                            requestData = angular.copy(params);
                            requestData.allTasks = true;
                            break;
                    }
                    // Open modal with notes
                    if (action == 'decline' || action == 'decline-filtered') {
                        return confirmationModalNotes.open({bodyMessage: 'REASON_FOR_TASK_EVENT_REJECT', required:true}).result.then(
                            function (note) {
                                requestData.note = note;

                                return apiClient.create(url, requestData).then(function (response){
                                    if (response) {
                                        $state.go('.', $stateParams, {'reload': $state.current}).then(function (){
                                            messenger.success('ACTION_SUCCESS');
                                        });
                                    }
                                });
                            }
                        );
                    }

                    // Open modal with dates
                    if (action == 'generate' || action == 'generate-filtered') {
                        return $uibModal.open({
                            templateUrl: '/modules/user/tasks/generate/modal/task-generate.html',
                            controller: 'UserTaskGenerateModalController',
                            controllerAs: 'vm',
                            resolve: {
                                config: function () {
                                    return {
                                        titleMessage: 'ARE_YOU_SURE_HEADER',
                                        bodyMessage: 'ARE_YOU_SURE',
                                        primaryText: 'YES',
                                        primaryClass: 'btn-danger',
                                        secondaryText: 'NO',
                                        secondaryClass: 'btn-default'
                                    }
                                },
                                type: function () {
                                    return '';
                                },
                                startDate: function () {
                                    return null
                                }
                            }
                        }).result.then(
                            function (dates) {
                                requestData.start = moment.utc(moment(dates.start).format('YYYY-MM-DD HH:mm:ss'));

                                if (typeof dates.end !== 'undefined') {
                                    requestData.end = moment.utc(moment(dates.end).format('YYYY-MM-DD HH:mm:ss'));
                                }

                                apiClient.create(url, requestData).then(function (response) {
                                    if (response) {
                                        $state.go('.', {}, {'reload': $state.current}).then(function () {
                                            messenger.success('ADD_SUCCESS');
                                        });
                                    }
                                });
                            }
                        );
                    }

                    if (action == 'delete-events' || action == 'delete-events-filtered') {
                        return confirmationModal.open({primaryClass: 'btn-danger'}).result.then(
                            function () {
                                return apiClient.create(url, requestData).then(function (response){
                                    if (response) {
                                        $state.go('.', $stateParams, {'reload': $state.current}).then(function (){
                                            messenger.success('ACTION_SUCCESS');
                                        });
                                    }
                                });
                            }
                        );
                    }

                    if (action == 'cancel-events' || action == 'cancel-events-filtered') {
                       if ($scope.tasksCollection.count === 0) {
                           return messenger.error('NO_ITEMS');
                        }
                        if (action == 'cancel-events-filtered') {
                            requestData.allTasks = true;
                            delete requestData._links;
                        }
                        //To avoid checkbox when press submit
                        $rootScope.skipDirtyCheck = true;
                        var bulkActionModal = bulkCancelTasksModal.open(
                            requestData,
                            url,
                            $scope.tasksCollection.count
                        );

                        return bulkActionModal.result.then(function () {
                            $state.go('.', $stateParams, {'reload': $state.current}).then(function (){
                                messenger.success('ACTION_SUCCESS');
                                $rootScope.skipDirtyCheck = false;
                            });
                        });
                    }

                    var modalOptions = {primaryClass: 'btn-success'}
                    var warningMsg = '<p class="alert alert-warning"><i class="fa fa-info-circle"></i> ' + $translate.instant('FREQUENCIES_MARKED_STATUTORY_NO_DOCUMENTTYPE') + '</p>';

                    if (requestData.allTasks) {
                        var displayWarningMsg = lodash.find(
                            $scope.tasksCollection.tasks,
                            {statFreqAndNoDocType: true}
                        );

                        if (displayWarningMsg) {
                            modalOptions.htmlBodyMessage = warningMsg;
                        }
                    } else {
                        angular.forEach(requestData._links.task, function (taskHref) {
                            var task = lodash.find(
                                $scope.tasksCollection.tasks,
                                {_links: {self: {href: taskHref.href}}}
                            );

                            if (task.statFreqAndNoDocType) {
                                modalOptions.htmlBodyMessage = warningMsg;
                            }
                        });
                    }

                    return confirmationModal.open(modalOptions).result.then(
                        function () {
                            return apiClient.create(url, requestData).then(function (response){
                                if (response) {
                                    $state.go('.', $stateParams, {'reload': $state.current}).then(function (){
                                        messenger.success('ACTION_SUCCESS');
                                    });
                                }
                            });
                        }
                    );
                } else {
                    messenger.error('NO_ITEMS_SELECTED');
                }

            }

            function deleteRequest(link) {
                return confirmationModal.open().result.then(
                    function () {
                        return apiClient.delete(link).then(function (response){
                            if (response) {
                                messenger.success('DELETE_SUCCESS');
                                $state.go($state.current, $stateParams, {'reload': $state.current});
                            }
                        });
                    }
                );
            }

            function onServiceProviderChange(task) {
                var requestData = {
                    '_links': {
                        serviceprovider: {href: task.getLink('serviceprovider')}
                    },
                    'tasks': {
                        '_links': [
                            {href: task.getLink('self')}
                        ]
                    }
                };

                if (! requestData._links.serviceprovider.href) {
                    delete requestData._links.serviceprovider;
                }

                apiClient.update($scope.siteResource.getLink('serviceprovidertasks'), requestData).then(function (){
                    messenger.success('ACTION_SUCCESS');
                });
            }

            function onServiceProviderBulkChange() {
                var requestData = {
                    '_links': {
                        serviceprovider: { href: this.bulkServiceProvider }
                    },
                    'tasks': {
                        '_links': []
                    }
                };

                for (var i = 0; i < $scope.tasks.length; i++) {
                    if ($scope.tasks[i].checked) {
                        requestData.tasks._links.push($scope.tasks[i]._links.self);
                    }
                }

                if (requestData.tasks._links.length > 0) {
                    apiClient.update($scope.siteResource.getLink('serviceprovidertasks'), requestData).then(function (){
                        $state.go('.', $stateParams, {'reload': $state.current}).then(function(){
                            messenger.success('ACTION_SUCCESS');
                        });
                    });
                } else {
                    this.bulkServiceProvider = null;
                    messenger.error('NO_ITEMS_SELECTED');
                }
            }

            function orderAction(key) {
                $stateParams.taskOrder = key;

                $state.go('.', angular.extend({}, $stateParams), { reload: $state.current });
            }
        }]
    }
});
