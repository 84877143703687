angular.module('elogbooksServices').service('fileModal', ['$uibModal','apiClient', function ($uibModal) {
    return {
        open: function (siteFilesCollectionResponse, siteResponse, config) {
            return $uibModal.open({
                templateUrl: '/modules/common/modals/galleryFileUpload/file.html',
                controller: 'FileModalController',
                controllerAs: 'vm',
                resolve: {
                    config: function () {
                        var defaultConfig = {
                            titleMessage: 'ARE_YOU_SURE_HEADER',
                            bodyMessage: 'ARE_YOU_SURE',
                            primaryText: 'YES',
                            primaryClass: 'btn-danger',
                            secondaryText: 'NO',
                            secondaryClass: 'btn-default'
                        };

                        return angular.extend({}, defaultConfig, config);
                    },
                    siteFilesCollectionResponse: function () {
                        return siteFilesCollectionResponse;
                    },
                    siteResponse: function () {
                        return siteResponse;
                    }
                }
            });
        }
    };
}]);
