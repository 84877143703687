(function () {
    'use strict';

    angular
        .module('elogbooks.common.audit')
        .controller('AuditSectionDetailsController', AuditSectionDetailsController);

    AuditSectionDetailsController.$inject = ['apiClient', 'lodash', 'auditResponse', 'sectionResponse', 'sectionsCollectionResponse'];

    function AuditSectionDetailsController(apiClient, lodash, auditResponse, sectionResponse, sectionsCollectionResponse) {
        var vm = this;
        vm.audit = auditResponse;
        vm.section = sectionResponse;
        vm.userCanEdit = lodash.has(vm.audit, '_links.edit.href');

        vm.sectionsCollectionResponse = sectionsCollectionResponse;
        vm.sections = sectionsCollectionResponse.sections;

        vm.sectionProgressPercentage = (vm.section.answeredQuestionCount > 0) ? vm.section.answeredQuestionCount / vm.section.questionCount * 100 : 0;
    }
})();
