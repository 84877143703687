(function () {
    'use strict';

    angular
        .module('elogbooksServices')
        .service('quoteCreateService', QuoteCreateService);

    QuoteCreateService.$inject = [
        '$rootScope',
        '$state',
        'apiClient'
    ];

    function QuoteCreateService(
        $rootScope,
        $state,
        apiClient
    ) {
        this.callerContact = {};
        this.enterCallerContactDetailsManually = false;
        this.callerContact.notifyOnCreate = true;
        this.callerContact.notifyOnComplete = true;
        this.callerContact.declineEmail = false;
        this.userCallerContactResponse = {};
        this.isByPlannedDate = false;
        this.priorityLinkParameters = {'active': true, 'entityTypes': 'entity_type_all,entity_type_quote'};

        this.changeSite = function(selectedSiteModel, selectedSite) {
            if (selectedSiteModel.selected && selectedSiteModel.selected.object) {
                selectedSite = selectedSiteModel.selected.object;
                $rootScope.skipDirtyCheck = true;
                $state.go('.', {siteResource: selectedSite.getLink('self').encode()}, {reload: false})
                    .then(function() {
                        $rootScope.skipDirtyCheck = false;
                    });
            }
        }

        this.changeUser = function(
            selectedUserCallerContactModel,
            selectedUserCallerContact,
            data,
            submitDisabled,
            userCallerContactResponse,
            selectedRelationshipModel
        ) {
            if (selectedUserCallerContactModel.selected && selectedUserCallerContactModel.selected.object) {
                selectedUserCallerContact = selectedUserCallerContactModel.selected.object;
                submitDisabled = true;
                apiClient.get(selectedUserCallerContact.getLink('user')).then(function (response) {
                    if (typeof data.callerContact === 'undefined' ||
                        data.callerContact === null ||
                        (data.callerContact && data.callerContact.id != response.id)) {
                        data.callerContact = userCallerContactResponse = response;
                        data.callerContact.phoneNumbers = [];
                        angular.forEach(response.contactInformation.fields, function (value, index) {
                            if (value.type === 'telephone') {
                                data.callerContact.phoneNumbers.push(value);
                            }
                        });
                    }
                    data._links.callerContactSiteRelationship = null;
                    selectedRelationshipModel.selected = null;
                    data._links.caller = selectedUserCallerContact.getLink('user');
                    submitDisabled = false;
                });
            } else {
                data._links.callerContactSiteRelationship = data._links.caller = data.callerContact = null;
            }
        }

         this.changeRelationship = function(selectedRelationshipModel, selectedRelationship, data) {
            if (selectedRelationshipModel.selected && selectedRelationshipModel.selected.object) {
                selectedRelationship = selectedRelationshipModel.selected.object;
                data._links.callerContactSiteRelationship = selectedRelationship.getLink('self');
            } else {
                data._links.callerContactSiteRelationship = null;
            }
        }

        this.removeServiceProvider = function(serviceProvider, selectedServiceProviders) {
            angular.forEach(selectedServiceProviders, function(value, index) {
                if (value._links.self.href === serviceProvider._links.self.href) {
                    selectedServiceProviders.splice(index, 1);
                }
            });
        }

        this.changeServiceProvider = function(selectedServiceProviderModel,selectedServiceProviders) {
            if (selectedServiceProviderModel.selected && selectedServiceProviderModel.selected.object) {
                var isAlreadySelected = false;
                angular.forEach(selectedServiceProviders, function(value, index) {
                    if (value.getLink('serviceprovider') === selectedServiceProviderModel.selected.object.getLink('serviceprovider')) {
                        isAlreadySelected = true;
                    }
                });

                if (!isAlreadySelected) {
                    selectedServiceProviders.push(selectedServiceProviderModel.selected.object);
                }

                selectedServiceProviderModel.selected = null;
            }
        }

        this.changePriority = function(selectedPriorityModel, data, plannedDate, confirmationModal) {
            if (selectedPriorityModel.selected && selectedPriorityModel.selected.object) {
                data._links.priority = {href: selectedPriorityModel.selected.href};
                data.isByPlannedDate = selectedPriorityModel.selected.value === plannedDate;

                if (selectedPriorityModel.selected.object.alertMessageEnabled) {
                    confirmationModal.open({
                        titleMessage: 'PRIORITY_MESSAGE',
                        bodyMessage: selectedPriorityModel.selected.object.alertMessage,
                        primaryText: 'OK',
                        primaryClass: 'btn-primary',
                        secondaryText: ''
                    });
                }

                if (data.isByPlannedDate) {
                    delete data._links.priority;
                } else if (!data.isByPlannedDate) {
                    delete data.attendanceDueAt;
                    delete data.completionDueAt;
                }
            } else {
                delete data._links.priority;
                data.isByPlannedDate = false;
            }
        }

        return this;
    }
})();
