(function (){
    'use strict';

    angular
        .module('elogbooks.user.tenants')
        .controller('UserManageTenantsController', UserManageTenantsController);

    UserManageTenantsController.$inject = ['sectionResource'];

    function UserManageTenantsController(sectionResource) {
        var vm = this;
        vm.sectionResource = sectionResource;
    }
})();
