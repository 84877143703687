(function() {
    'use strict';

    angular
        .module('elogbooks')
        .config(Translations);

     function Translations($translateProvider) {
        $translateProvider.translations('nl', {
        SCHEDULES: 'Planning',
        SURVEY_SCHEDULES: 'Survey Planning',
        SURVEY_SCHEDULE: 'Planning',
        SURVEYSCHEDULE_ADD: 'Planning toevoegen',

        SURVEY_REQUEST_ADD: 'KPI Checklist toevoegen',
        ADD_NEW_SURVEY_REQUEST: 'Nieuw KPI Checklist toevoegen',

        ACCEPT_SURVEY: 'Accepteer KPI Checklist',
        ADD_NEW_SURVEY: 'Nieuw KPI Checklist toevoegen',
        ADD_SURVEY: 'KPI Checklist toevoegen',
        ADD_SURVEY_LINE: 'Regel toevoegen in KPI Checklist',
        EDIT_SURVEY: 'Bewerk KPI Checklist',
        FM_COMMENT: 'Opmerking FM',
        FM_SCORE: 'Score FM',
        CALCULATED_SCORE: 'Score',
        LAST_UPDATED: 'Laatst bijgewerkt',
        MAXIMUM_SCORE: 'Maximale score',
        REPORTING_PERIOD: 'Rapportageperiode',
        RESET_LINES: 'Herstel Checklist-regels',
        SAVE_CHANGES: 'Wijzigingen opslaan',
        SELECT_SCORE_RANGE: 'Scorebereik',
        SP_COMMENT: 'Opmerking SP',
        SP_SCORE: 'Score SP',
        SPSCORE_FMSCORE: 'Score SP/FM',
        SUBMIT_SURVEY: 'KPI Checklist indienen',
        SURVEY_ID: 'KPI Checklist ID',
        SURVEY_LINES: 'KPI Checklist-regels',
        SURVEY_REQUESTS: 'KPI Checklist Aanvragen',
        WEIGHT_1_TO_5: 'Weging (1 tot 5)',

        REPORTING_MONTH: 'Rapportagemaand',
        REPORTING_YEAR: 'Rapportagejaar',
        ALLOW_SP_TO_COMPLETE_THIS_SURVEY: 'Leverancier toestaan om dit KPI Checklist af te ronden',

        SCORE_LESS_THAN: 'Score Lager Dan',

        NO_ACTIVE_SURVEYS_FOR_SERVICE_PROVIDER: 'Er zijn momenteel geen actieve KPI Checklists voor deze leverancier',

        STATUS_IN_PROGRESS: 'In Progress',
        STATUS_PENDING: 'In afwachting',
        STATUS_COMPLETED: 'Afgerond',
        STATUS_DONE: 'Gereed',
        STATUS_DONE_WITH_ERRORS: 'Gereed (met fouten)',
        STATUS_INVALID_FILE: 'Ongeldig bestand',
        STATUS_INVALID_TYPE: 'Ongeldug type',

        STATUS_OPEN: 'Open (Actief of In afwachting)',
        STATUS_CLOSED: 'Gesloten',

        LAST_RUN: 'Laatste Ronde',
        NEXT_RUN: 'Volgende Ronde',
        NO_SURVEYS_FOR_SERVICE_PROVIDER: 'Er zijn momenteel geen KPI Checklists voor deze leverancier',
        SP_EDITABLE: 'SP Bewerkbaar',
        SERVICEPROVIDER: 'Leverancier',
        SPEDITABLE: 'SP Bewerkbaar',
        CREATEDAT: 'Bewerkt Op',
        UPDATEDAT: 'Bijgewerkt Op',
        RULE: 'Regel',
        SURVEYSCHEDULE_EDIT: 'Bewerk Checklist Planning',
        SURVEY_SCHEDULE_CREATED: 'Checklist Planning Aangemaakt',
        SURVEY_SCHEDULE_UPDATED: 'Checklist Planning Bijgewerkt',
        SURVEY_SCHEDULE_DELETED: 'Checklist Planning Verwijderd',
        SURVEY_SAVED: 'Checklist Opgeslagen',
        SURVEY: 'KPI Checklist',
        SURVEYS: 'KPI Checklists',

        ERROR_NOT_ALLOWED_TO_UPDATE_FM_AND_SP_SCORE: 'U bent niet bevoegd scores aan te passen',
        ERROR_NOT_ALLOWED_TO_UPDATE_FM_SCORE: 'U bent niet bevoegd scores aan te passen',
        ERROR_NOT_ALLOWED_TO_UPDATE_SP_SCORE: 'U bent niet bevoegd scores aan te passen',

        PENDING_KPI_SURVEYS: 'Openstaande KPI Surveys',

        SURVEY_ACTIVE_STATE_CHANGED: 'Survey Status veranderd',

        QUESTION_ANSWERED_CANNOT_DELETE: 'Al beantwoord, kan neit worden verwijderd',

        SURVEY_LIST: 'KPI Checklists',
        SURVEY_VIEW: 'Info',
        SURVEY_ADD: 'Een nieuw KPI Checklist toevoegen',
        SURVEY_EDIT: 'Bewerk KPI Checklist',

        SURVEY_CREATED: 'KPI Checklist Aangemaakt',
        SURVEY_UPDATED: 'KPI Checklist Bijgewerkt',
        SURVEY_DELETED: 'KPI Checklist Verwijderd',

        SURVEY_TO_SUBMIT: 'In te vullen KPI Checklists',
        SURVEY_TO_APPROVE: 'Goed te keuren KPI Checklists',

        OUTSTANDING_SURVEYS: 'Openstaande KPI Surveys',
        SURVEY_INACTIVE: 'Survey Inactive - Cannot Update'
        });
     }
})();