(function() {
    'use strict';

    angular
        .module('elogbooksDirectives')
        .directive('contactInfoPanel', ContactInfoPanel);

    ContactInfoPanel.$inject = ['crudService', 'messenger'];

    function ContactInfoPanel(crudService, messenger, $state) {
        return {
            restrict: 'AE',
            transclude: true,
            scope: {
                config: '=',
                contact: '=',
                contacts: '=',
                deleteContact: '=',
                index: '='
            },
            templateUrl: '/modules/directives/contact-info-panel/contact-info-panel.html',
            link: function($scope, $state) {
                $scope.delete = deleteAction;

                $scope.contacts = $scope.contacts.map(function(contact) {
                   contact.accordionOpened = false;
                   contact.additionalEmail = [];
                   contact.additionalTelephoneNumbers = [];
                   contact.webAddresses = [];

                   if(contact.hasOwnProperty('fields')) {
                        contact.fields.forEach(function(field) {
                            if (field.value === contact.telephone) {
                                contact.mainTelephoneNotes = field.notes;
                            }

                            if (field.value === contact.email) {
                                contact.mainEmailNotes = field.notes;
                            }

                            if (field.type === 'telephone') {
                                contact.additionalTelephoneNumbers.push(field);
                            }

                            if (field.type === 'email') {
                                contact.additionalEmail.push(field);
                            }

                            if (field.type === 'website') {
                                contact.webAddresses.push(field);
                            }
                       });
                   }

                   return contact;
                });

                $scope.oneAccordionAtATime = true;

                $scope.handleBoxChecks = emailAddress => {
                    if (emailAddress !== $scope.contact.email) {
                        const emailsChecked = $scope.contact.additionalEmail.filter(email => {
                           return email.checked === true;
                        });

                        $scope.contact.isDisabled = emailsChecked.length > 0;
                    }
                };

                function deleteAction(contact, $index) {
                    return crudService.delete(
                        contact.getLink('delete'),
                        null,
                        null,
                        {},
                        true
                    ).then(function(response) {
                        if (response) {
                            messenger.success($scope.config.entityName+'_CONTACT_DELETED');
                            $scope.contacts.splice($index, 1);
                        }
                    });
                }
            }
        };
    }
})();
