angular.module('elogbooksServices').service('reportPermissions', function(filterPermissions) {

    var permissions = [
        {name: 'user_permission_report_sp_summary'},
        {name: 'user_permission_report_site_associate_activity'},
        {name: 'user_permission_report_approval_age'},
        {name: 'user_permission_report_summary'},
        {name: 'user_permission_report_ppm_gap_analysis'},
        {name: 'user_permission_report_open_close'},
        {name: 'user_permission_report_monthly'},
        {name: 'user_permission_report_kpi_monthly'},
        {name: 'user_permission_report_engineering_risk'},
        {name: 'user_permission_report_portfolio_compliance'},
        {name: 'user_permission_report_audit_trend'},
        {name: 'user_permission_report_sp_usage'},
        {name: 'user_permission_report_residential_location'},
        {name: 'user_permission_report_operative_performance'},
        {name: 'user_permission_report_service_provider_feedback_ratings', module: 'sp_feedback'}
    ];

    this.getAll = function () {
        return filterPermissions.filterPermissions(permissions, 'VALUE');
    };

    this.getAllTranslated = function () {
        return filterPermissions.filterPermissions(permissions, 'TRANSLATED');
    };

    this.getAllAsObjects = function () {
        return permissions;
    };

    return this;
});
