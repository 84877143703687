(function () {
    'use strict';

    angular
        .module('elogbooks.admin.sites')
        .controller('QuoteThresholdsListController', QuoteThresholdsListController);

    QuoteThresholdsListController.$inject = ['$scope', 'quoteThresholdsCollectionResponse'];

    function QuoteThresholdsListController ($scope, quoteThresholdsCollectionResponse) {
        var vm = this;
        vm.quoteThresholdsCollectionResponse = quoteThresholdsCollectionResponse;
        vm.thresholds = quoteThresholdsCollectionResponse.quoteThresholds;
    }
})();
