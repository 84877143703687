(function (){
    'use strict';

    angular
        .module('elogbooks.user.tenants')
        .controller('UserEmailInfoController', UserEmailInfoController);

    UserEmailInfoController.$inject = ['emailLogResponse'];

    function UserEmailInfoController(emailLogResponse) {
        var vm = this;
        vm.emailLog = emailLogResponse;
    }
})();
