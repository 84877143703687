(function () {
    'use strict';

    angular
        .module('elogbooks.admin.asset-score-types')
        .controller('AssetScoreTypeOptionsController', AssetScoreTypeOptionsController);

    AssetScoreTypeOptionsController.$inject = ['assetScoreTypeResponse', 'assetScoreTypeOptionsCollectionResponse', 'sortableOptions', 'requestDataFactory', 'crudService'];

    function AssetScoreTypeOptionsController (assetScoreTypeResponse, assetScoreTypeOptionsCollectionResponse, sortableOptions, requestDataFactory, crudService) {
        var vm = this;

        vm.AssetScoreType = assetScoreTypeResponse;
        vm.AssetScoreTypeOptions = assetScoreTypeOptionsCollectionResponse.options;
        vm.AssetScoreTypeOptionCollectionResponse = assetScoreTypeOptionsCollectionResponse;
        vm.reorderedAssetScoreTypeOptions = angular.copy(vm.AssetScoreTypeOptions);
        vm.data = requestDataFactory.createRequest(vm.AssetScoreType);
        vm.reset = resetAction;
        vm.update = updateAction;

        angular.forEach(vm.reorderedAssetScoreTypeOptions, function(score) {
            if (score.score === -1) {
                delete vm.reorderedAssetScoreTypeOptions.score;
            }
        });

        vm.sortableOptions = sortableOptions.addProperty({
            orderChanged: orderChanged
        });

        function updateAction () {
            vm.data._links.assetScoreTypeOptions = [];

            angular.forEach(vm.reorderedAssetScoreTypeOptions, function (value, $index) {
                vm.data._links.assetScoreTypeOptions.push(value._links.self);
            });

            return crudService.update(
                vm.AssetScoreType.getLink('self'),
                vm.data,
                'dashboard.admin.asset-score-types.list.details.asset-score-type-options',
                'dashboard.admin.asset-score-types.list.details.asset-score-type-options',
                'ASSET_SCORE_TYPE_OPTION'
            );
        }

        function resetAction () {
            vm.reorderedAssetScoreTypeOptions = angular.copy(vm.AssetScoreTypeOptions);
            vm.optionsHaveChanged = false;
        }

        function orderChanged (event, a, b, c, d, e) {
            vm.optionsHaveChanged = true;
        }
    }
})();
