app.config(function ($translateProvider) {
    $translateProvider.translations('en-gb', {
        FILE: 'File',
        FILES: 'Files',
        FILES_JOB_FILES: 'Job Files',
        FILES_SITE_FILES: 'Site Files',
        RELATED_FILES: 'Related Files',
        ASSET_FILES: 'Asset Files',
        FILE_ADVANCED: 'Advanced Options',

        FILE_ADD: 'Add a new File',
        FILE_EDIT: 'Edit',
        FILE_LIST: 'Files',

        FILE_CREATED: 'File Created',
        FILE_UPDATED: 'File Updated',
        FILE_DELETED: 'File Deleted',
        FILE_TITLE: 'File Title:',
        CAPTION: 'Caption',
        UPLOAD_AND_EDIT: 'Upload and Edit',
        FILE_NAME: 'File Name',
        UPLOAD: 'Upload',
        DELETE_IMAGE: 'Delete Image',

        UPLOADED_BY: 'Uploaded By',
        UPLOADED_DATE_START: 'Uploaded Date Start',
        UPLOADED_DATE_END: 'Uploaded Date End',
        EXPIRES_DATE_START: 'Expiry Date Start',
        EXPIRES_DATE_END: 'Expiry Date End',
        EXPORT_DATE: 'Export Date',
        FILES_NOTIFY_EXPIRY: 'Notify {{ ::expiryTime }} day(s) ahead of expiry',
        FILES_NOTIFY_EXPIRY_UPDATED: 'Expiry reminders updated successfully',
        FILES_NOTIFY_EXPIRY_SELECTED: 'Update expiry reminders',
        FILES_NOTIFY_EXPIRY_FILTERED: 'Send expiry reminder on filtered',
        FILES_NOTIFY_EXPIRY_TOGGLE_TOOLTIP: 'Set expiry reminders',
        FILES_SELECTED: 'Files Selected',

        FILE_DOWNLOAD: 'Download',
        FILE_PROCESSING: 'File processing',
        INVALID_FILE: 'Invalid File',
        INVALID_FILE_NAME: 'Invalid File Name',

        IS_STATUTORY_FILE: 'Statutory File',
        ISSTATUTORY: 'Statutory File',

        FILES_REQUIRED: 'At least one file is required',
        FILE_REQUIRED: 'File is required',

        MAXIMUM_SIX_UPLOADS: 'Maximum Amount of Six Uploads',
        MAXIMUM_SIX_UPLOADS_REACHED: 'Maximum Amount of Six Uploads Reached',
        FILES_10_MAX: 'Maximum 10 files',
        FILES_ADD: 'Add Files',

        INTEGRATION_UPLOAD_STATUS: 'Upload Status',
        UPLOAD_PENDING: 'Upload Pending',
        UPLOADED: 'Uploaded',
        UPLOAD_ERROR: 'Upload Error',
        INTEGRATIONS: 'Integrations',
        ERROR_IMAGE_IS_REQUIRED: 'Image is required',
        FILE_TYPE_IMAGE_REQUIRED: 'File is not an image',
        INVALID_UPLOADER: 'Uploader is invalid',
        IMAGE: 'Image',

        FILES_APPLICABLE_TO: 'Give service providers access to this document when carrying out Jobs',
        JOB_FILE: 'Job File',
        SITE_FILES: 'Site Files',
        JOB_ATTACHMENTS: 'Job Attachments',
        QUOTE_ATTACHMENTS: 'Quote Attachments',
        ALL_FILES: 'All Files',
        DOCUMENT_COMPLIANCE: 'Document Compliance',
        LAST_UPLOADED: 'Last Uploaded',
        NEXT_DUE_DATE: 'Next Due Date',
        EXPORT_FILTERED: 'Export Filtered',
        EXPORT_FILES_MODAL_MESSAGE: 'You have selected to download {{ count }} files ({{ sizeOfFiles }}{{ unit }}). You will receive an email containing links to the zip files which will be deleted after one week. Please confirm.',
        EXPORT_FILES_MODAL_TITLE: 'Export Filtered Files',
        FILE_EXPIRED_DELETED: 'File has expired and no longer exists',
        EXPORT_DOWNLOAD_FILES_LIST: 'Download Files Export',
        DELETE_ACTION_FILE_CANCEL_JOB: 'This file is in use by action ID: {{actionId}} ({{actionSummary}}). Removing this file will remove the action file association and the action will be Cancelled.',
        DELETE_ACTION_FILE_NO_CANCEL_JOB: 'This file is in use by action ID: {{actionId}} ({{actionSummary}}). Removing this file will remove the action file association and the action will not be Cancelled.',
        DELETE_JOB_FILE: 'This file is in use by job ID: {{jobId}} ({{jobSummary}}). Removing this file will remove the job file association and the action will not be Cancelled.',
        UNABLE_TO_DELETE_ACTION_FILE: 'This file cannot be deleted because it is currently in use by job ID: {{jobId}} ({{jobSummary}}). <br><br>If you wish to remove this file, then it should be deleted from the job, which will result in this Action being cancelled.',
        UNABLE_TO_DELETE_ACTION_FILE_HEADER: 'File cannot be deleted.',
        AUDIT_REPORTS: 'Audit Reports',
        ID_AUDIT: 'Audit ID',
        AUDIT_SUMMARY: 'Summary',
        FILE_ID: 'File ID',
        DOCUMENT_DATE_START: 'Document Date Start',
        DOCUMENT_DATE_END: 'Document Date End',
        FILE_VISIBILITY_PRIVATE: 'Private',
        FROM_AND_TO: 'From and To'
    });
});
