(function (){
    'use strict';

    angular
        .module('elogbooks.user.tasks')
        .controller('UserTaskCreateStep1Controller', UserTaskCreateStep1Controller);

    UserTaskCreateStep1Controller.$inject = ['$state', '$filter', 'siteResponse', 'siteCollectionResponse'];

    function UserTaskCreateStep1Controller($state, $filter, siteResponse, siteCollectionResponse) {
        var vm = this;
        vm.data = {
            type : null
        };
        vm.siteSelect = {
            response : siteCollectionResponse,
            link : siteCollectionResponse._links.self.href,
            responseKeyPath: 'sites',
            required: true
        };
        vm.serviceType = [
            {value: 'soft', title: $filter('translate')('SOFT_SERVICE')},
            {value: 'hard', title: $filter('translate')('HARD_SERVICE')}
        ];
        vm.submit = submitAction;

        //Preselect site when global site filter is selected
        if (siteResponse && typeof siteResponse._links !== 'undefined') {
            vm.siteSelect.selected = {href:siteResponse._links.self.href, title:siteResponse.name};
        }

        function submitAction() {
            $state.go('.'+vm.data.type, {siteResource : vm.siteSelect.selected.href.encode()})
        }
    }
})();