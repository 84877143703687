(function(){
    'use strict';

    angular
        .module('elogbooks.user.patrols')
        .controller('PatrolStatisticsController', PatrolStatisticsController);

    PatrolStatisticsController.$inject = ['$stateParams', 'patrolStatistics', '$state', 'siteCollectionResponse', 'selectedSiteResponse', 'lodash', 'user', 'apiClient', 'moment', 'serviceProviderResponse', 'operativeCollectionResponse', 'globalFilterParamsService'];

    function PatrolStatisticsController ($stateParams, patrolStatistics, $state, siteCollectionResponse, selectedSiteResponse, lodash, user, apiClient, moment, serviceProviderResponse, operativeCollectionResponse, globalFilterParamsService) {
        var vm = this;

        //convert string values to integer
        vm.stats = lodash.mapValues(patrolStatistics.data[0], function (v) { return parseInt(v); });

        vm.section = lodash.mapValues(lodash.clone(vm.stats), function (v) { return true; });
        vm.stateParams = $stateParams;

        vm.items = [];
        vm.total = vm.stats.total;
        delete vm.stats.total;

        for(var prop in vm.stats){
            var obj = {};
            obj.section = prop;
            obj.value = vm.stats[prop];
            obj.closed = true;
            obj.patrolCollectionResponse = null;
            vm.items.push(obj);
        }

        vm.search = search;
        vm.snakeToConstantCase = snakeToConstantCase;
        vm.getPatrols = getPatrols;
        vm.fetchData = fetchData;
        vm.changePage = changePage;

        function changePage(page) {
            var params = angular.extend({}, fetchParams(), {page: page});
            resetParams(params);
            params[$stateParams.section] = true;

            apiClient.get(user.getLink('patrol-instances'), params).then(function (response) {
               var index = lodash.findIndex(vm.items, ['section', $stateParams.section]);

               vm.items[index].patrolCollectionResponse = response;
            });
        }

        function snakeToConstantCase(text){
            var result = text.replace( /([A-Z])/g, "_$1" ).toUpperCase();
            return result.charAt(0).toUpperCase() + result.slice(1);
        }

        function getPatrols(item) {
            $stateParams.page = 1;

            if (item.closed === true) {
                vm.items = vm.items.map(function (value) {
                    value.closed = true;
                    return value;
                });

                item.closed = false;
                fetchData(item);

                return true;
            }
            item.closed = true;
        }

        function fetchData(item) {
            if (item.value !== 0) {
                vm.loading = true;

                var params = fetchParams()
                params[item.section] = true;

                apiClient.get(user.getLink('patrol-instances'), params).then(function (response) {
                    item.patrolCollectionResponse = response;
                    $stateParams.section = item.section;
                    vm.loading = false;
                });
            }
        }

        function fetchParams() {
            var params = {
                dateDue: $stateParams.dateDue,
                patrolSiteId: $stateParams.patrolSiteId,
                patrolName: $stateParams.patrolName,
                serviceProvider: $stateParams.serviceProvider ? JSON.parse($stateParams.serviceProvider).v : null,
                operative: $stateParams.operative ? JSON.parse($stateParams.operative).v : null,
                siteIds: $stateParams.siteIds,
                page: $stateParams.page
            };

            params = angular.extend({}, params, {
                'siteIds[]': $stateParams.siteIds
            });

            params = angular.extend({}, params, globalFilterParamsService.getLocalStorageFilters());

            return params;
        }

        function resetParams(params) {
            delete params.pointsMissed;
            delete params.startedEarly;
            delete params.startedLate;
            delete params.completedLate;
            delete params.missedOrIncomplete;
        }

        vm.siteSelect = {
            response : siteCollectionResponse,
            link : siteCollectionResponse ? siteCollectionResponse.getLink('self') : null,
            responseKeyPath: 'sites',
            itemValuePath: 'name',
            itemHrefPath: 'id',
            disabled: $stateParams.selectedSiteResource
        };

        vm.criteria = {
            patrolName: { type: 'text', title: 'NAME', value: $stateParams.patrolName },
            siteIds: { type: 'autocompletemulti', multiple: true, title: 'SITE', value: $stateParams.siteIds, options: vm.siteSelect, param: 'siteIds', max: 50 },
            dateDue: {
                type: 'date-range',
                title: 'BETWEEN_DATES',
                value: {
                    startDate: ($stateParams.dateDue !== null) ? moment(new Date($stateParams.dateDue.split(',')[0])) : null,
                    endDate: ($stateParams.dateDue !== null) ? moment(new Date($stateParams.dateDue.split(',')[1])) : null,
                    endMaxDate: moment().add(-1, 'days').format('MM-DD-YYYY, 23:59:59')
                },
                clearValue: {
                    startDate: null,
                    endDate: null
                }
            }
        };

        if (serviceProviderResponse) {
            vm.serviceProviderSelect = {
                response : serviceProviderResponse,
                link : serviceProviderResponse ? serviceProviderResponse.getLink('self') : null,
                itemValuePath: 'id',
                itemTitlePath: 'name',
                responseKeyPath: 'serviceproviders'
            };

            vm.criteria.serviceProvider = { type: 'jsonselectwidget', title: 'SERVICE_PROVIDER', value: JSON.parse($stateParams.serviceProvider), options: vm.serviceProviderSelect };
        }

        if (operativeCollectionResponse) {
            vm.operativeSelect = {
                response : operativeCollectionResponse,
                link : operativeCollectionResponse ? operativeCollectionResponse.getLink('self') : null,
                itemValuePath: '_links.user.id',
                itemTitlePath: '_links.user.name',
                responseKeyPath: 'operatives'
            };

            vm.criteria.operative = { type: 'jsonselectwidget', title: 'OPERATIVE', value: JSON.parse($stateParams.operative), options: vm.operativeSelect };
        }

        if (selectedSiteResponse) {
            vm.siteSelect.cacheItems= [{value:selectedSiteResponse.name, href:selectedSiteResponse.id, object:selectedSiteResponse}];
        }

        function search(params) {
            var override = {
                page: 1
            };

            $state.go('.', angular.extend({}, params, override), { reload: $state.current });
        }
    }
})();
