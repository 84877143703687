(function () {
    'use strict';

    angular
        .module('elogbooks.user.manage-operatives')
        .controller('UserManageOperativeServiceProvidersController', UserManageOperativeServiceProvidersController);

    UserManageOperativeServiceProvidersController.$inject = ['$stateParams', '$state', 'serviceProviderCollection', 'serviceProvidersCollectionResponse', 'apiClient', '$uibModal', 'rootResourceResponse'];

    function UserManageOperativeServiceProvidersController($stateParams, $state, serviceProviderCollection, serviceProvidersCollectionResponse, apiClient, $uibModal, rootResourceResponse) {
        var vm = this;
        var params = angular.copy($stateParams);
        vm.serviceProviders = serviceProviderCollection.serviceproviders;
        vm.addOperative = addOperative;

        // close expandable windows if main page changes
        if ($stateParams.changed === 'true') {
            $stateParams.position = null;
        }
        vm.serviceProviders = serviceProviderCollection.serviceproviders.map(function (value, index) {
            if (!$stateParams.position) {
                value.closed = true;
            } else if ($stateParams.position != index) {
                value.closed = true;
            }
            return value;
        });

        vm.serviceProviderCollection = serviceProviderCollection;
        vm.operatives = [];

        vm.search = search;
        vm.order = order;
        vm.getOperatives = getOperatives;

        vm.serviceProviderSelect = {
            response : serviceProvidersCollectionResponse,
            link : serviceProvidersCollectionResponse ? serviceProvidersCollectionResponse.getLink('self') : null,
            itemValuePath: 'id',
            itemTitlePath: 'name',
            responseKeyPath: 'serviceproviders'
        };

        vm.criteria = {
            serviceProvider: { type: 'jsonselectwidget', title: 'SERVICE_PROVIDER', value: JSON.parse($stateParams.serviceProvider), options: vm.serviceProviderSelect },
            operativeName: {type: 'text', title: 'NAME', value: $stateParams.operativeName},
            operativeUsername: {type: 'text', title: 'USERNAME', value: $stateParams.operativeUsername},
            operativeEmail: {type: 'text', title: 'EMAIL', value: $stateParams.operativeEmail}
        };

        /**
         * add the operative to the sp
         * @param serviceProvider
         */
        function addOperative(serviceProvider) {
            $uibModal.open({
                templateUrl: '/modules/common/service-provider/modal/operative-add-modal.html',
                controller: 'OperativeAddModalController',
                controllerAs: 'vm',
                resolve: {
                    operativeCollectionResponse: function () {
                        return apiClient.get(rootResourceResponse.getLink('operatives')).then(function (response) {
                            return response;
                        });
                    },
                    serviceProviderOperativeCollectionResponse: function () {
                        // this is for the create link only
                        return apiClient.get(serviceProvider.getLink('operatives'), {limit:1}).then(function (response) {
                            return response;
                        });
                    },
                    config: function() {
                        return {
                            useDefaultManageOperativePermissions: true
                        }
                    }
                }
            });
        }

        /**
         * get operatives when expanding window
         * @param serviceProvider
         * @param $index
         * @param init
         */
        function getOperatives(serviceProvider, $index, init) {
            $stateParams.changed = null;
            params.changed = null;

            if (serviceProvider.closed) {
                if (!init) {
                    params.position = $stateParams.position = $index;
                    params.operativePage = $stateParams.operativePage = '1';
                    // do this so above $stateParams are updated before list fetch
                    $state.go('.', params, {notify: false});
                }
            }

            function fetchOperatives() {
                if (serviceProvider.getLink('operatives')) {
                    vm.loading = true;

                    delete params.id;
                    params.operativePage = $stateParams.operativePage;
                    params.name = $stateParams.operativeName;
                    params.username = $stateParams.operativeUsername;
                    params.email = $stateParams.operativeEmail;

                    apiClient.get(serviceProvider.getLink('operatives'), params).then(function (response) {
                        vm.operatives[$index] = response;
                        vm.loading = false;
                    });
                }
            }

            // if refresh page and window open get operatives
            if ($stateParams.position === $index && init) {
                if (!vm.operatives[$index]) {
                    fetchOperatives();
                }
            }

            if (!init) {
                // opening window
                if (serviceProvider.closed) {
                    fetchOperatives();
                    // close all, then open this one
                    vm.serviceProviders = serviceProviderCollection.serviceproviders.map(function (value) {
                        value.closed = true;
                        return value;
                    });
                    serviceProvider.closed = false;
                // closing window
                } else {
                    serviceProvider.closed = true;
                    $stateParams.position = null;
                }
            }

            params.position = $stateParams.position;
        }

        if ($stateParams.position > 0 || $stateParams.position === '0') {
            if (vm.serviceProviders.length !== 0) {
                getOperatives(vm.serviceProviders[$stateParams.position], $stateParams.position, true);
            }
        }

        function search(params) {
            var override = {
                serviceProviderPage: 1,
                position: null
            };

            $state.go('.', angular.extend({}, params, override), {reload: $state.current});
        }

        function order(key) {
            $stateParams.order = key;

            var override = {
                page: 1
            };

            $state.go('.', angular.extend({}, $stateParams, override), {reload: $state.current});
        }
    }
})();
